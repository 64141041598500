import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Event, FilterList, SettingsOutlined } from '@material-ui/icons';
import { SearchField } from '../../../../common/inputs/SearchField';
import { ToggleButton } from '../../../../common/buttons/ToggleButton';
import { RosterContext } from '../bulk-roster/RosterContext';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

export enum RosterViewTypeEnum {
  SINGLE = 'singler',
  BULK = 'bulk',
}

interface RosterToolbarProps {
  title: string;
  onView: () => void;
  isFiltering: boolean;
  setIsFiltering: (value: boolean) => void;
  onSetting: () => void;
}

export function RosterToolbar(props: RosterToolbarProps) {
  const { isFiltering, title, onView, setIsFiltering, onSetting } = props;
  const { searchValue, setSearchValue } = React.useContext(RosterContext);
  const { theme } = useCampfireTheme();

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' paddingTop='18px' paddingBottom='18px'>
      <Box display='flex'>
        <Typography
          variant='h3'
          style={{ display: 'flex', fontWeight: 900, fontSize: 20, color: theme.color.grey.neutralBrand800 }}
        >
          {title}
        </Typography>
      </Box>
      <Box display={'flex'} alignItems='center'>
        <Box style={{ marginRight: '10px' }}>
          <SearchField
            data-track='rosterCnlFilter-SearchInput'
            placeholder='Search Rosters'
            growLeft
            value={searchValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              setSearchValue(event.target.value)
            }
          />
        </Box>
        <ToggleButton onClick={onSetting}>
          <SettingsOutlined />
          <Typography>Settings</Typography>
        </ToggleButton>
        <ToggleButton selected={isFiltering} onClick={() => setIsFiltering(!isFiltering)}>
          <FilterList />
          <Typography>Filters</Typography>
        </ToggleButton>
        <ToggleButton onClick={onView}>
          <Event />
          <Typography>Single View</Typography>
        </ToggleButton>
      </Box>
    </Box>
  );
}
