import React from 'react';
import { getDurationHoursMin, unpackToTime } from '@campfire/hot-date';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, CardContent, Typography } from '@material-ui/core';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { CheckBox, CheckBoxOutlineBlank, ThumbDown, ThumbUp } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import { FileUploadGallery } from '@campfire/file-upload-gallery/lib';
import { assertNever } from '../../../../../../../common/functions/assert-never';
import { useUser } from '../../../../../../../global/auth/useUser';
import { Avatar } from '../../../../../../general/user-profile/components/Avatar';
import { ProfileHoverBlock } from '../../../../../../general/user-profile/hover-text/ProfileHoverBlock';
import { VolunteerDialogHoverBlock } from '../../../../../volunteer-common-profile/VolunteerDialogHoverBlock';
import { ActivityTimelinePastSessionReport } from '../../../__generated__/ActivityTimelinePastSessionReport';
import { SessionReportFormHeading } from '../SessionReportFormHeading';

type SessionReportFieldValueProps = {
  name: string;
  description: string;
  children?: React.ReactNode;
} & GridProps;

const SessionReportSubmissionField = (props: SessionReportFieldValueProps) => {
  const { name, description, children } = props;

  return (
    <Grid container style={{ paddingTop: 8, paddingBottom: 8, alignContent: 'baseline', alignItems: 'baseline' }}>
      <Grid item md={6} style={{ marginRight: 16, textAlign: 'right' }}>
        <TitularTooltip title={name} description={description}>
          <Typography
            style={{
              marginBottom: 4,
              lineHeight: 1.1,
              display: 'inline-block',
            }}
            variant='body2'
          >
            {name}
            {':'}
          </Typography>
        </TitularTooltip>
      </Grid>
      <Grid
        item
        xs
        // style={{ paddingTop: 16 }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

interface Props {
  sessionReport: ActivityTimelinePastSessionReport;
  activityId: string;
  programId: string;
}

const SessionReportSubmission = (props: Props) => {
  const { sessionReport, activityId, programId } = props;
  const startTimeDateTime = unpackToTime(sessionReport.startTime);
  const endTimeDateTime = unpackToTime(sessionReport.endTime);
  const duration = getDurationHoursMin(startTimeDateTime, endTimeDateTime);

  const {
    user: { userIdentityService },
  } = useUser();
  const isAdmin = userIdentityService.isVmAdmin;
  const isProgramManager = userIdentityService.isManagerOfProgram(programId);
  const canViewAllDetails = isProgramManager || isAdmin || userIdentityService.isLeaderOfActivity(activityId);

  return (
    <>
      <CardContent>
        <SessionReportSubmissionField name='Time' description='Reported start and end times from this session'>
          <Typography>
            {`${startTimeDateTime.toFormat('h:mm a').toLowerCase()} - ${endTimeDateTime
              .toFormat('h:mm a')
              .toLowerCase()}`}
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {duration}
          </Typography>
        </SessionReportSubmissionField>

        <SessionReportSubmissionField name='Attendees' description='Attendance from this session'>
          {sessionReport.attendances.length === 0 ? (
            <Typography color='error' variant='body2'>
              {'No attendees recorded.'}
            </Typography>
          ) : null}

          <Box>
            {sessionReport.attendances.map((attendance) => {
              return attendance.__typename === 'VOLUNTEER_OtherAttendanceType' ? (
                <Box
                  style={{ paddingTop: 3, paddingBottom: 3 }}
                  display='flex'
                  alignContent='center'
                  alignItems='center'
                  key={attendance.attendanceId}
                >
                  <Avatar size={32} />
                  <Typography style={{ paddingLeft: 8 }} variant='body2'>
                    {attendance.name}
                  </Typography>
                </Box>
              ) : attendance.__typename === 'VOLUNTEER_VolunteerAttendanceType' ? (
                <Box
                  style={{ paddingTop: 3, paddingBottom: 3 }}
                  display='flex'
                  alignContent='center'
                  alignItems='center'
                  key={attendance.attendanceId}
                >
                  {canViewAllDetails ? (
                    <VolunteerDialogHoverBlock
                      avatarUrl={attendance.volunteer.profile.avatarUrl}
                      profileId={attendance.volunteer.profile.profileId}
                      userId={attendance.volunteer.profile.userId}
                      preferredName={attendance.volunteer.profile.preferredName}
                      lastName={attendance.volunteer.profile.lastName}
                      hoverTextProps={{
                        hoverColor: 'primary',
                        disableUnderline: true,
                      }}
                    />
                  ) : (
                    <ProfileHoverBlock
                      avatarUrl={attendance.volunteer.profile.avatarUrl}
                      profileId={attendance.volunteer.profile.profileId}
                      userId={attendance.volunteer.profile.profileId}
                      preferredName={attendance.volunteer.profile.preferredName}
                      lastName={attendance.volunteer.profile.lastName}
                      hoverTextProps={{
                        hoverColor: 'primary',
                        disableUnderline: true,
                      }}
                    />
                  )}
                </Box>
              ) : null;
            })}
          </Box>
        </SessionReportSubmissionField>

        <SessionReportSubmissionField name='Journal' description='Comments, stories, and notes from this session'>
          {sessionReport.comments ? (
            <Typography variant='body2'>{sessionReport.comments}</Typography>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              {'No entry.'}
            </Typography>
          )}
        </SessionReportSubmissionField>

        {sessionReport.session.reportType?.items
          .sort((a, b) => a.order - b.order)
          .map((reportTypeItem) => {
            if (reportTypeItem.__typename === 'VOLUNTEER_ReportTypeHeadingType') {
              return (
                <Box textAlign='center' paddingTop={4} paddingBottom={1} key={reportTypeItem.reportTypeItemId}>
                  <SessionReportFormHeading heading={reportTypeItem.heading} />
                </Box>
              );
            }
            if (reportTypeItem.__typename === 'VOLUNTEER_ReportTypeFieldType') {
              const fieldValue = sessionReport.fieldValues.find(
                (x) => x.field.fieldId === reportTypeItem.field.fieldId
              );

              const SessionReportSubmissionFieldBaseProps: SessionReportFieldValueProps = {
                key: reportTypeItem.reportTypeItemId,
                name: reportTypeItem.field.name,
                description: reportTypeItem.field.description,
              };

              if (reportTypeItem.field.__typename === 'VOLUNTEER_BooleanFieldType')
                return (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    {!fieldValue || fieldValue.__typename !== 'VOLUNTEER_BooleanFieldValueType' ? (
                      <Typography>{'-'}</Typography>
                    ) : fieldValue.boolValue ? (
                      <TitularTooltip title='Thumbs Up'>
                        <ThumbUp />
                      </TitularTooltip>
                    ) : (
                      <TitularTooltip title='Thumbs Down'>
                        <ThumbDown />
                      </TitularTooltip>
                    )}
                  </SessionReportSubmissionField>
                );
              if (reportTypeItem.field.__typename === 'VOLUNTEER_CheckboxFieldType')
                return (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    {!fieldValue || fieldValue.__typename !== 'VOLUNTEER_CheckboxFieldValueType' ? (
                      <Typography>{'-'}</Typography>
                    ) : fieldValue.checked ? (
                      <TitularTooltip title='Checklist Ticked'>
                        <CheckBox />
                      </TitularTooltip>
                    ) : (
                      <TitularTooltip title='Checklist Unticked'>
                        <CheckBoxOutlineBlank />
                      </TitularTooltip>
                    )}
                  </SessionReportSubmissionField>
                );
              if (reportTypeItem.field.__typename === 'VOLUNTEER_DropdownFieldType')
                return !fieldValue || fieldValue.__typename !== 'VOLUNTEER_DropdownFieldValueType' ? (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Typography>{'-'}</Typography>
                  </SessionReportSubmissionField>
                ) : (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Typography variant='body1'>
                      {fieldValue.dropdownFieldOptions
                        .map((dropdownFieldOption) => dropdownFieldOption.name)
                        .join(', ')}
                    </Typography>
                  </SessionReportSubmissionField>
                );
              if (reportTypeItem.field.__typename === 'VOLUNTEER_NumericFieldType')
                return !fieldValue || fieldValue.__typename !== 'VOLUNTEER_NumericFieldValueType' ? (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Typography>{'-'}</Typography>
                  </SessionReportSubmissionField>
                ) : (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Typography variant='body1'>{fieldValue.numericValue}</Typography>
                  </SessionReportSubmissionField>
                );
              if (reportTypeItem.field.__typename === 'VOLUNTEER_RatingFieldType')
                return !fieldValue || fieldValue.__typename !== 'VOLUNTEER_RatingFieldValueType' ? (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Typography>{'-'}</Typography>
                  </SessionReportSubmissionField>
                ) : (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <TitularTooltip title={`${fieldValue.ratingValue} Stars`}>
                      <Box display='inline-block  '>
                        <Rating readOnly value={fieldValue.ratingValue} />
                      </Box>
                    </TitularTooltip>
                  </SessionReportSubmissionField>
                );
              if (reportTypeItem.field.__typename === 'VOLUNTEER_TextFieldType')
                return !fieldValue || fieldValue.__typename !== 'VOLUNTEER_TextFieldValueType' ? (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Typography>{'-'}</Typography>
                  </SessionReportSubmissionField>
                ) : (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Typography variant='body1'>{fieldValue.textValue}</Typography>
                  </SessionReportSubmissionField>
                );
              if (reportTypeItem.field.__typename === 'VOLUNTEER_TimeFieldType')
                return !fieldValue || fieldValue.__typename !== 'VOLUNTEER_TimeFieldValueType' ? (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Typography>{'-'}</Typography>
                  </SessionReportSubmissionField>
                ) : (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Typography variant='body1'>
                      {unpackToTime(fieldValue.timeValue)
                        .toFormat('h:mm a')
                        .toLowerCase()}
                    </Typography>
                  </SessionReportSubmissionField>
                );

              if (reportTypeItem.field.__typename === 'VOLUNTEER_AttachmentFieldType')
                return !fieldValue || fieldValue.__typename !== 'VOLUNTEER_AttachmentFieldValueType' ? (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Typography>{'-'}</Typography>
                  </SessionReportSubmissionField>
                ) : (
                  <SessionReportSubmissionField {...SessionReportSubmissionFieldBaseProps}>
                    <Grid container alignItems='center'>
                      <FileUploadGallery
                        allowMultiple
                        viewOnly
                        uploadedFiles={fieldValue.attachments.map((item) => ({
                          fileId: item.attachmentId,
                          url: item.url,
                          name: item.name,
                        }))}
                      />
                    </Grid>
                  </SessionReportSubmissionField>
                );
              return assertNever(reportTypeItem.field.__typename);
            }
            return assertNever(reportTypeItem);
          })}
      </CardContent>
    </>
  );
};

export { SessionReportSubmission };
