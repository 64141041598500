export const getOrdinal = (num: number) => {
  const onesColumn = num % 10;
  const lowerTwoDigits = num % 100;
  if (onesColumn === 1 && lowerTwoDigits !== 11) {
    return `${num}st`;
  }
  if (onesColumn === 2 && lowerTwoDigits !== 12) {
    return `${num}nd`;
  }
  if (onesColumn === 3 && lowerTwoDigits !== 13) {
    return `${num}rd`;
  }
  return `${num}th`;
};
