import { HoverLink } from '@campfire/hover-link';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Typography, List, ListItemText, ListItem, ListItemIcon, Box, Divider } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import React, { memo } from 'react';
import { SectionCollapsible } from '../common/SectionCollapsible';
import { SectionHeader } from '../common/SectionHeader';
import { VolunteerCommonProfileActivityWaitlisting } from '../__generated__/VolunteerCommonProfileActivityWaitlisting';
import { useVolunteerCommonProfileContext } from '../VolunteerCommonProfileContext';
import { SectionInstruction } from '../common/SectionInstruction';

export const VolunteerActivityWaitlistingsSection = memo(
  ({ activityWaitlistings }: { activityWaitlistings: VolunteerCommonProfileActivityWaitlisting[] }) => {
    const {
      volunteerNameOptions: { preferredName },
    } = useVolunteerCommonProfileContext();
    return (
      <SectionCollapsible
        sectionHeader={<SectionHeader title='Activity Waitlistings' />}
        instruction={
          <SectionInstruction>{`All activities that ${preferredName} is on the waitlist`}</SectionInstruction>
        }
        collapseContent={
          <>
            <Typography variant='caption'>{`Activities that ${preferredName} is on the waitlist`}</Typography>
            <List dense>
              {activityWaitlistings
                .filter((activityWaitlisting) => activityWaitlisting.activityWaitlistingId !== null)
                .map((activityWaitlisting, idx) => (
                  <HoverLink
                    data-track='fs-volPrf-activity-waitlisting-item'
                    key={activityWaitlisting.activityWaitlistingId}
                    hoverColor='primary'
                    to={`/management/activity-management?activityId=${activityWaitlisting.activity.activityId}&programId=${activityWaitlisting.activity.program.programId}`}
                    disableUnderline
                  >
                    <TitularTooltip description='Open Activity Overview'>
                      <Box key={activityWaitlisting.activityWaitlistingId}>
                        <ListItem key={activityWaitlisting.activityWaitlistingId} button>
                          <ListItemIcon>
                            <LinkIcon color='disabled' />
                          </ListItemIcon>
                          <ListItemText
                            primary={activityWaitlisting.activity.name}
                            secondary={activityWaitlisting.activity.program.name}
                          />
                        </ListItem>
                        {idx !== activityWaitlistings.length - 1 && <Divider style={{ marginTop: '8px' }} />}
                      </Box>
                    </TitularTooltip>
                  </HoverLink>
                ))}
            </List>
          </>
        }
        expandText='View Activity Waitlistings'
        idStub='activity-waitlistings'
      />
    );
  }
);
