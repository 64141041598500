import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { unpackToDate } from '@campfire/hot-date';
import { OutstandingReportsList } from './OutstandingReportsList';
import { IncomingVolunteersList } from './IncomingVolunteersList';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { VOLUNTEER_ApplicationStatusEnumType as ApplicationStatusType } from '../../../../__generated__/globalTypes';
import {
  ManagementDashboardIncomingVolunteersData_vm_incomingVolunteers_incomingVolunteers as IncomingVolunteersType,
  ManagementDashboardIncomingVolunteersData_vm_incomingVolunteers_incomingVolunteers_application as ApplicantType,
  ManagementDashboardIncomingVolunteersData_vm_incomingVolunteers_incomingVolunteers_profile as ProfileType,
} from '../__generated__/ManagementDashboardIncomingVolunteersData';

export type IncompleteReportDate = {
  activityId: string;
  name: string;
  date: string;
  startTime: string;
  endTime: string;
};

interface Props {
  temporaryDisabled?: boolean;
  incomingVolunteers: IncomingVolunteersType[];
  allIncompleteReportDates: IncompleteReportDate[];
  incomingVolunteersIsLoading: boolean;
  outstandingReportsIsLoading: boolean;
}

export const ManagementOverview = (props: Props) => {
  const {
    temporaryDisabled,
    incomingVolunteers,
    incomingVolunteersIsLoading,
    outstandingReportsIsLoading,
    allIncompleteReportDates,
  } = props;
  const { isMd, isMobile } = useCampfireTheme();

  const applicationIsPendingReview = (volunteer: ApplicantType | null) =>
    volunteer?.status === ApplicationStatusType.pending;
  const hasTaskPendingReview = (volunteer: ProfileType | null) =>
    !!volunteer?.completedTasks?.find((t) => t.status === ApplicationStatusType.pending);
  const isPendingApproval = (volunteer: ProfileType | null) => {
    const completedTasks =
      volunteer?.completedTasks.filter((x) => x.task.cake.cakeType === 'required' && x.status === 'approved') ?? [];

    const requiredTasks = volunteer?.application?.relevantTasks.filter((x) => x.cake.cakeType === 'required') ?? [];

    const requiredCompletedTasks =
      requiredTasks.filter(
        (requiredTask) => completedTasks.find((ct) => ct.task.taskId === requiredTask.taskId) !== undefined
      ) ?? [];

    return (
      requiredCompletedTasks.length === requiredTasks.length &&
      !!requiredTasks.length &&
      !!requiredCompletedTasks.length
    );
  };

  const filteredVolunteers = useMemo(
    () =>
      incomingVolunteers.filter((v) =>
        v.source === 'Application'
          ? applicationIsPendingReview(v.application)
          : v.source === 'Profile'
          ? hasTaskPendingReview(v.profile) || isPendingApproval(v.profile)
          : true
      ),
    [incomingVolunteers]
  );

  const sortedVolunteers = useMemo(
    () =>
      filteredVolunteers
        .sort((a, b) => {
          const dateA = a?.lastUpdated;
          const dateB = b?.lastUpdated;
          return unpackToDate(dateA) < unpackToDate(dateB) ? -1 : 1;
        })
        .slice(0, 5),
    [filteredVolunteers]
  );

  return (
    <>
      <Box width={'100%'} marginRight={isMobile ? 0 : isMd ? '40px' : 0}>
        {!temporaryDisabled && (
          <IncomingVolunteersList volunteers={sortedVolunteers} isLoading={incomingVolunteersIsLoading} />
        )}
      </Box>
      <Box width={'100%'}>
        <OutstandingReportsList reports={allIncompleteReportDates} isLoading={outstandingReportsIsLoading} />
      </Box>
    </>
  );
};
