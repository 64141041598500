import { UserIdentity } from './User';

type PrivLevelString = 'admin' | 'programManager' | 'activityLeader' | 'volunteer' | 'applicant';

type IActivityWaitlist = {
  activeActivityWaitlistings: Array<{ volunteer: { volunteerId: string }}>
}
export interface IUserIdentityService {
  privLevel: PrivLevelString;
  isVolunteerId: (volunteerId: string) => boolean;
  isUserId: (userId: string) => boolean;
  isVolunteer: boolean;
  isApplicant: boolean;
  isActivityLeader: boolean;
  isManagerOfProgram: (programId: string) => boolean;
  isProgramManager: boolean;
  isOnWaitlist: (activity: IActivityWaitlist, volunteerId?: string | undefined) => boolean;
  isVmAdmin: boolean;
  isImAdmin: boolean;
  isApplicantOrVolunteer: boolean;
  isLeaderOfActivity: (activityId: string) => boolean;
  isInActivity: (activityId: string) => boolean;
  hasRuleForProgram: (programId: string, rule: string) => boolean;
  hasVmGlobalRule: (rule: string) => boolean;
  hasImGlobalRule: (rule: string) => boolean;
  isInProgram: (programId: string) => boolean;
  isVolunteerInProgram: (programId: string) => boolean;
}

export const getUserIdentityService = (userIdentity: UserIdentity): IUserIdentityService => {
  const isVolunteerId = (volunteerId: string): boolean => {
    return (
      !!userIdentity.vm &&
      !!userIdentity.vm.volunteerIdentity &&
      userIdentity.vm.volunteerIdentity.volunteerId === volunteerId
    );
  };

  const isUserId = (userId: string): boolean => {
    return userIdentity.userId === userId;
  };

  const isVolunteer = !!userIdentity.vm && !!userIdentity.vm.volunteerIdentity;

  const isApplicant = !!userIdentity.vm && !!userIdentity.vm.applicantIdentity;

  const isActivityLeader =
    !!userIdentity.vm &&
    !!userIdentity.vm.volunteerIdentity &&
    !!userIdentity.vm.volunteerIdentity.programIdentities.find(
      (programIdentity) => programIdentity.ledActivityIds.length > 0
    );

  const isProgramManager =
    !!userIdentity.vm &&
    !!userIdentity.vm.managerIdentity &&
    userIdentity.vm.managerIdentity.programManagerIdentities.length > 0;

  const isVmAdmin = !!userIdentity.vm && userIdentity.vm.globalRoles.length > 0;

  const isImAdmin = !!userIdentity.im && userIdentity.im.globalRoles.length > 0;

  const isApplicantOrVolunteer = isApplicant || isVolunteer;

  const isLeaderOfActivity = (activityId: string): boolean => {
    return (
      !!userIdentity.vm &&
      !!userIdentity.vm.volunteerIdentity &&
      !!userIdentity.vm.volunteerIdentity.programIdentities.find((programIdentity) => {
        return programIdentity.ledActivityIds.includes(activityId);
      })
    );
  };

  const isInActivity = (activityId: string): boolean => {
    return (
      !!userIdentity.vm &&
      !!userIdentity.vm.volunteerIdentity &&
      !!userIdentity.vm.volunteerIdentity.programIdentities.find((programIdentity) => {
        return programIdentity.activityIds.includes(activityId);
      })
    );
  };

  const isOnWaitlist = (activity: IActivityWaitlist, volunteerId?: string): boolean =>
    !!activity.activeActivityWaitlistings.find((waitlist) => waitlist.volunteer.volunteerId === volunteerId);

  const hasRuleForProgram = (programId: string, rule: string): boolean => {
    return (
      !!userIdentity.vm &&
      !!userIdentity.vm.managerIdentity &&
      (hasVmGlobalRule(rule) ||
        !!userIdentity.vm.managerIdentity.programManagerIdentities.find((programManagerIdentity) => {
          return programManagerIdentity.programId === programId && programManagerIdentity.role.ruleKeys.includes(rule);
        }))
    );
  };

  const hasVmGlobalRule = (rule: string): boolean => {
    return !!userIdentity.vm && !!userIdentity.vm.globalRoles.find((role) => role.ruleKeys.includes(rule));
  };

  const hasImGlobalRule = (rule: string): boolean => {
    return !!userIdentity.im && !!userIdentity.im.globalRoles.find((role) => role.ruleKeys.includes(rule));
  };

  const isInProgram = (programId: string): boolean => {
    return isVolunteerInProgram(programId) || isManagerOfProgram(programId);
  };

  const isVolunteerInProgram = (programId: string): boolean => {
    return (
      !!userIdentity.vm &&
      !!userIdentity.vm.volunteerIdentity &&
      !!userIdentity.vm.volunteerIdentity.programIdentities.find(
        (programIdentity) => programIdentity.programId === programId
      )
    );
  };

  const isManagerOfProgram = (programId: string): boolean => {
    return (
      !!userIdentity.vm &&
      !!userIdentity.vm.managerIdentity &&
      !!userIdentity.vm.managerIdentity.programManagerIdentities.find(
        (programManagerIdentity) => programManagerIdentity.programId === programId
      )
    );
  };

  const privLevel: PrivLevelString = isVmAdmin
    ? 'admin'
    : isProgramManager
    ? 'programManager'
    : isActivityLeader
    ? 'activityLeader'
    : isVolunteer
    ? 'volunteer'
    : 'applicant';

  return {
    privLevel,
    isVolunteerId,
    isUserId,
    isVolunteer,
    isApplicant,
    isActivityLeader,
    isManagerOfProgram,
    isProgramManager,
    isOnWaitlist,
    isVmAdmin,
    isImAdmin,
    isApplicantOrVolunteer,
    isLeaderOfActivity,
    isInActivity,
    hasRuleForProgram,
    hasVmGlobalRule,
    hasImGlobalRule,
    isInProgram,
    isVolunteerInProgram,
  };
};
