import { merge } from 'lodash';
import { GetRosterInfo_vm_activity as RosterType } from './__generated__/GetRosterInfo';

export type SessionRostering = {
  session: {
    sessionId: string;
  };
};

export type ParsedSessions = {
  [key: string]: boolean;
};

export type ParsedRoster = {
  initialDraftRoster: string[];
  draftRoster: string[];
  publishedRoster: string[];
  activityUnavailabilityId?: string;
  availabilities: string[];
  volunteerId: string;
  enrolmentId?: string;
  enrolled: boolean;
  isConflicting: boolean;
  profile: {
    __typename: 'VOLUNTEER_ProfileType';
    profileId: string;
    preferredName: string;
    lastName: string;
    avatarUrl: string | null;
    userId: string;
  };
  publishedSessionIds: string[];
  draftedSessionIds: string[];
  unavailable: boolean;
  attending: boolean | null;
  availability: {
    sessionAvailabilities?: {
      session: { sessionId: string };
    }[];
  } | null;
};

export type RosterByDateType = {
  volunteers: { [volunteerId: string]: ParsedRoster };
  isPublished: boolean;
  cancelledActivityId?: string;
  activityDate: string;
  rosterNotes?: string | null;
  publishedRoster: any;
};

export const keyToBooleanObject = (keys: string[]) => keys.reduce((keyMap, key) => ({ ...keyMap, [key]: true }), {});
export const mergeAllObject = (objects: any[]) =>
  objects.reduce((objectMap, object) => ({ ...objectMap, ...object }), {});

export function parseRosterByVolunteerId(
  roster?: RosterType | null
): {
  [key: string]: ParsedRoster;
} {
  if (!roster) {
    return {};
  }

  const { draftRoster, publishedRoster, activityEnrolments: enrolments } = roster;

  const publishedRosterByVolunteerIds = mergeAllObject(
    (publishedRoster?.activeRosterings || []).map((rostering) => {
      const matchedEnrolment = enrolments.find((e) => e.volunteer.volunteerId === rostering.volunteer.volunteerId);
      const matchedDraftRostering = draftRoster?.draftRosterings.find(
        (dr) => dr.volunteer.volunteerId === rostering.volunteer.volunteerId
      );

      return {
        [rostering.volunteer.volunteerId]: {
          ...rostering.volunteer,
          publishedRoster: rostering.sessionRosterings
            .map((sessionRostering) => sessionRostering.session.sessionId)
            .sort(),
          attending: rostering.attending,
          enrolled: !!matchedEnrolment,
          publishedSessionIds: rostering.sessionRosterings.map((sr) => sr.session.sessionId),
          draftedSessionIds: matchedDraftRostering
            ? matchedDraftRostering.sessionRosterings.map((sr) => sr.session.sessionId)
            : [],
          unavailable: !!matchedEnrolment?.unavailability ?? false,
          availability: matchedEnrolment?.availability ?? null,
          isConflicting: false
        },
      };
    })
  );

  const draftRosterByVolunteerIds = mergeAllObject(
    (draftRoster?.draftRosterings || []).map((rostering) => {
      const matchedEnrolment = enrolments.find((e) => e.volunteer.volunteerId === rostering.volunteer.volunteerId);

      return {
        [rostering.volunteer.volunteerId]: {
          ...rostering.volunteer,
          initialDraftRoster: rostering.sessionRosterings
            .map((sessionRostering) => sessionRostering.session.sessionId)
            .sort(),
          draftRoster: rostering.sessionRosterings.map((sessionRostering) => sessionRostering.session.sessionId).sort(),
          enrolled: !!matchedEnrolment,
          publishedSessionIds: [],
          draftedSessionIds: rostering.sessionRosterings.map((sr) => sr.session.sessionId),
          unavailable: !!matchedEnrolment?.unavailability ?? false,
          availability: matchedEnrolment?.availability ?? null,
          attending: null,
          isConflicting: false
        },
      };
    })
  );

  const enrolmentByVolunteerIds = mergeAllObject(
    (enrolments || []).map((enrolment) => ({
      [enrolment.volunteer.volunteerId]: {
        ...enrolment.volunteer,
        enrolmentId: enrolment.activityEnrolmentId,
        unavailable: Boolean(enrolment.unavailability && !enrolment.unavailability.dateRemoved),
        activityUnavailabilityId: enrolment.unavailability?.activityUnavailabilityId,
        availabilities: (enrolment.availability?.sessionAvailabilities || [])
          .filter((sessionAvailability) => !sessionAvailability.dateRemoved)
          .map((availability) => availability.session.sessionId),
        enrolled: true,
        publishedSessionIds: [],
        draftedSessionIds: [],
        availability: enrolment.availability,
        attending: null,
        activityEnrolmentId: enrolment.activityEnrolmentId,
        isConflicting: false
      },
    }))
  );

  return merge(publishedRosterByVolunteerIds, draftRosterByVolunteerIds, enrolmentByVolunteerIds);
}
