import { RosterGridDataRow } from './activity-roster-model';

export const ACTIVITY_ROSTER_DESKTOP_VOLUNTEER_GRID_FIXED_COLUMN_WIDTH = 3;

export interface ActivityRole {
  activityRoleId: string;
  name: string;
}

export interface VolunteerActivityRole {
  activityRoleId: string;
}

export interface GetActivityRolesArgs {
  activityRoles: ActivityRole[];
  volunteerActivityRoles: VolunteerActivityRole[]
}

export const getActivityRolesText = ({
  activityRoles,
  volunteerActivityRoles,
}: GetActivityRolesArgs) => {
  return activityRoles
    .filter((x) => !!volunteerActivityRoles.find((y) => y.activityRoleId === x.activityRoleId))
    .map((x) => x.name)
    .join(', ');
};

export const capRosterGridDataRow = (row: RosterGridDataRow): RosterGridDataRow => {
  return {
    ...row,
    rosterSessions: row.rosterSessions.map((rosterSession, index) => {
      if (rosterSession.activeStatus === undefined) {
        return {
          ...rosterSession,
          first: true,
          last: true,
        };
      }

      if (row.rosterSessions.length === 1) {
        return rosterSession;
      }

      if (index === 0) {
        return {
          ...rosterSession,
          first: true,
          last: row.rosterSessions[index + 1].activeStatus !== rosterSession.activeStatus,
        };
      }

      if (index === row.rosterSessions.length - 1) {
        return {
          ...rosterSession,
          first: row.rosterSessions[index - 1].activeStatus !== rosterSession.activeStatus,
          last: true,
        };
      }

      return {
        ...rosterSession,
        first: row.rosterSessions[index - 1].activeStatus !== rosterSession.activeStatus,
        last: row.rosterSessions[index + 1].activeStatus !== rosterSession.activeStatus,
      };
    }),
  };
};

export const replaceArrayItem = <T>(array: Array<T>, index: number, item: T): Array<T> => {
  return [...array.slice(0, index), item, ...array.slice(index + 1)];
};
