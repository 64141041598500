import { Field } from '@campfire/field';
import { TabletButton } from '@campfire/tablet-button';
import { cloneDeep } from 'lodash';
import { object as YupObject, string as YupString } from 'yup';
import { Formik, Form } from 'formik';
import { Dialog, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';
import React from 'react';
import {
  IncidentCategoryGroupType,
  AllIncidentCategoryType,
  findParentCategoryOfDetail,
  IncidentDetailType,
  IncidentCategoryType,
  findParentGroup,
  findParentOfCategory,
} from '../../admin-console-incident-report-model';
import { useAdminConsoleActions } from '../../../../admin-console-actions';

type Props = {
  childToEdit: AllIncidentCategoryType;
  onClose: () => void;
  incidentCategoriesRoot: IncidentCategoryGroupType;
  handleSuccess: () => void;
};

const validationSchema = YupObject().shape({
  label: YupString()
    .trim()
    .required(`Name can't be empty`),
});

const EditCategoryDialog = (props: Props) => {
  const { childToEdit, onClose, incidentCategoriesRoot, handleSuccess } = props;
  const { runSaveIncidentCategories, runSaveIncidentCategoriesLoading } = useAdminConsoleActions();

  function handleEditIncidentCategoryGroup(incidentCategoryGroup: IncidentCategoryGroupType) {
    if (!incidentCategoryGroup.incidentCategoryGroupId) return;
    const clonedRoot = cloneDeep(incidentCategoriesRoot);
    const foundParentGroup = findParentGroup(clonedRoot, incidentCategoryGroup.incidentCategoryGroupId);
    if (foundParentGroup) {
      const indexOfChildToEdit = foundParentGroup.childGroups.findIndex(
        (childGroup) => childGroup.incidentCategoryGroupId === incidentCategoryGroup.incidentCategoryGroupId
      );
      foundParentGroup.childGroups[indexOfChildToEdit] = incidentCategoryGroup;
    }
    runSaveIncidentCategories({ root: clonedRoot, handleSuccess });
  }

  function handleEditIncidentCategory(incidentCategory: IncidentCategoryType) {
    if (!incidentCategory.incidentCategoryId) return;
    const clonedRoot = cloneDeep(incidentCategoriesRoot);
    const foundParentOfCategory = findParentOfCategory(clonedRoot, incidentCategory.incidentCategoryId);
    if (foundParentOfCategory) {
      const indexOfChildToEdit = foundParentOfCategory.categories.findIndex(
        (category) => category.incidentCategoryId === incidentCategory.incidentCategoryId
      );
      foundParentOfCategory.categories[indexOfChildToEdit] = incidentCategory;
    }
    runSaveIncidentCategories({ root: clonedRoot, handleSuccess });
  }

  function handleEditIncidentDetail(incidentDetail: IncidentDetailType) {
    if (!incidentDetail.incidentDetailId) return;
    const clonedRoot = cloneDeep(incidentCategoriesRoot);
    const foundParentCategoryOfDetail = findParentCategoryOfDetail(clonedRoot, incidentDetail.incidentDetailId);
    if (foundParentCategoryOfDetail) {
      const indexOfChildToEdit = foundParentCategoryOfDetail.details.findIndex(
        (detail) => detail.incidentDetailId === incidentDetail.incidentDetailId
      );
      foundParentCategoryOfDetail.details[indexOfChildToEdit] = incidentDetail;
    }
    runSaveIncidentCategories({ root: clonedRoot, handleSuccess });
  }

  return (
    <Dialog open maxWidth='sm' fullWidth>
      <Formik
        initialValues={{
          label: childToEdit.label,
        }}
        validationSchema={validationSchema}
        onSubmit={(vals) => {
          const updatedChildToEdit = { ...childToEdit, label: vals.label };

          if (updatedChildToEdit.__typename === 'VOLUNTEER_IncidentCategoryGroupType') {
            handleEditIncidentCategoryGroup(updatedChildToEdit);
          }
          if (updatedChildToEdit.__typename === 'VOLUNTEER_IncidentCategoryType') {
            handleEditIncidentCategory(updatedChildToEdit);
          }
          if (updatedChildToEdit.__typename === 'VOLUNTEER_IncidentDetailType') {
            handleEditIncidentDetail(updatedChildToEdit);
          }
        }}
      >
        {() => (
          <Form>
            <DialogContent>
              <Field required autoFocus name='label' label='Detail Name' variant='outlined' margin='normal' fullWidth />
            </DialogContent>
            <DialogActions>
              <TabletButton variant='text' onClick={() => onClose()}>
                {'Cancel'}
              </TabletButton>
              <TabletButton variant='contained' color='primary' type='submit'>
                {runSaveIncidentCategoriesLoading ? <CircularProgress size={20} /> : 'Save'}
              </TabletButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export { EditCategoryDialog };
