import { capitalize } from 'lodash';
import { Tabs, Tab, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { ReportManagementTabValue } from '../report-management-types';
import { REPORT_MANAGEMENT_TAB_OPTIONS } from '../ReportManagementConsts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabRoot: {
      color: theme.color.grey.neutralBrand800,
      textTransform: 'capitalize',
      fontWeight: 800,
      fontSize: '20px',
      letterSpacing: '-0.02em',
      minWidth: 'unset',
    },
    selected: {
      color: `${theme.color.grey.neutralBrand800} !important`,
    },
    tabsFlexContainer: {
      alignContent: 'space-between',
      borderBottom: '2px solid',
      borderBottomColor: '#EFEFEF',
      height: 'calc(100% - 2px)',
    },
    root: {
      flex: 1,
      marginLeft: 60,
      marginRight: 60,
      marginTop: '1rem',
    },
  })
);

interface Props {
  children?: React.ReactNode;
}

export const ReportManagementDesktopTabs = ({ children }: Props) => {
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const classes = useStyles();

  useEffect(() => {
    if (!query.tab) setQuery({ ...query, tab: 'report-types' });
  }, [query.tab]);

  const handleChange = (_unused: any, newValue: ReportManagementTabValue) => {
    setQuery({ ...query, tab: newValue });
  };

  return (
    <Tabs
      value={query.tab}
      onChange={handleChange}
      indicatorColor='primary'
      variant='standard'
      aria-label='report management tabs'
      classes={{
        flexContainer: classes.tabsFlexContainer,
      }}
    >
      {REPORT_MANAGEMENT_TAB_OPTIONS.map((option) => (
        <Tab
          key={option}
          disableRipple
          disableTouchRipple
          label={capitalize(option.replace('-', ' '))}
          classes={{ selected: classes.selected, root: classes.tabRoot }}
          value={option}
        />
      ))}
      {children}
    </Tabs>
  );
};
