import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useTaskItemFieldStyle = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '2px',
      gap: '5px',
      '& .type': {
        fontWeight: 400,
        fontSize: 14,
        color: '#00000099',
      },
    },
  })
);

export function TaskItemFieldType({ icon, type, dynamic }: { icon: React.ReactNode; type: string; dynamic: boolean }) {
  const classes = useTaskItemFieldStyle();
  return (
    <Box className={classes.container}>
      {icon}
      <Typography className='type'>
        {type}
        {dynamic ? ' (Dynamic)' : ''}
      </Typography>
    </Box>
  );
}
