import React, { memo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { ActivitiesExplore } from '../../general/activities-explore/ActivitiesExplore';
import { ActivityTimelineMobile } from './activity-timeline-mobile/ActivityTimelineMobile';
import { ActivityTimeline } from './activity-timeline/ActivityTimeline';
import { ActivityTimelineProvider } from './activity-timeline/ActivityTimelineContext';

const ActivityMap = memo(({ managementPath, activityIds }: { managementPath: string; activityIds: string[] }) => {
  const [selectedActivityId] = useQueryParam('activityId', StringParam);
  const location = useLocation();
  const routeMatch = useRouteMatch();

  const { isMobile } = useCampfireTheme();

  const returnSlugUrlParams = new URLSearchParams(location.search);
  returnSlugUrlParams.delete('activityId');

  return selectedActivityId ? (
    <ActivityTimelineProvider>
      {isMobile ? (
        <ActivityTimelineMobile
          activities={[{ activityId: selectedActivityId }]}
          returnSlug={`${routeMatch?.path ? `${routeMatch?.path}?${returnSlugUrlParams.toString()}` : ''}`}
        />
      ) : (
        <ActivityTimeline
          activities={[{ activityId: selectedActivityId }]}
          returnSlug={`${routeMatch?.path ? `${routeMatch?.path}?${returnSlugUrlParams.toString()}` : ''}`}
        />
      )}
    </ActivityTimelineProvider>
  ) : (
    <ActivitiesExplore isManagementView managementPath={managementPath} activityIds={activityIds} />
  );
});

export { ActivityMap };
