import { gql } from '@apollo/client';

export const GET_TABLE_WIDGET_CANCELLED_ACTIVITIES = gql`
  query GetTableWidgetCancelledActivities(
    $programIds: [String!]
    $activityIds: [String!]
    $range: String
    $widgetType: String
    $startDate: Date
    $endDate: Date
  ) {
    vm {
      cancelledActivities(
        programIds: $programIds
        activityIds: $activityIds
        range: $range
        widgetType: $widgetType
        startDate: $startDate
        endDate: $endDate
      ) {
        cancelledActivityId
        activityDate
        activity {
          name
          program {
            name
          }
        }
        activityCancellationReason {
          label
        }
        cancelledDate
        cancelledBy {
          preferredName
          lastName
        }
      }
    }
  }
`;
