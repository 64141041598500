import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import { useApiUrl } from '../../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../../global/network/useCampfireFetch';
import { ReactComponent as MailchimpIcon } from '../../../../../../../../assets/icons/mailchimp.svg';
import { useStyles } from '../styles';

export function MailchimpAuthorizationRequest() {
  const [mailchimpUrl, setMailchimpUrl] = React.useState<string | undefined>();
  const campfireFetch = useCampfireFetch<{ url: string }>({ defer: true });
  const apiUrl = useApiUrl();

  React.useEffect(() => {
    campfireFetch
      .run({
        url: `${apiUrl}/wm/auth/uri?app=mailchimp`,
        method: 'get',
      })
      .then((response) => response.data)
      .then((data) => setMailchimpUrl(data.data.url));
  }, []);

  const classes = useStyles();

  if (mailchimpUrl) {
    return (
      <a className={classes.button} href={mailchimpUrl} style={{ padding: '7px 12px' }}>
        <MailchimpIcon />
        <Divider orientation='vertical' style={{ height: '1.5rem', marginLeft: '4px', backgroundColor: '#fff' }} />
        <Typography
          style={{
            marginLeft: '4px',
            fontWeight: 600,
            fontSize: '13px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          Login with Mailchimp
        </Typography>
      </a>
    );
  }

  return null;
}
