import { gql } from '@apollo/client';

const ADMIN_CONSOLE_ROLE_FRAGMENT =  gql`
  fragment AdminConsoleRole on VOLUNTEER_ActivityRoleType {
    activityRoleId
    name
    icon
    description
  }
`;

export const ADMIN_CONSOLE_ROLE_QUERY = gql `
  query GetAdminConsoleRoles {
    vm {
      activityRoles {
        ...AdminConsoleRole
      }
    }
  }
  ${ADMIN_CONSOLE_ROLE_FRAGMENT}
`
