import { gql } from '@apollo/client';

export const ActivitySessionFragment = gql`
  fragment AvailabilityCenterSession on VOLUNTEER_SessionType {
    sessionId
    name
    minVolunteers
    maxVolunteers
    startTime
    endTime
    dateRemoved
  }
`;
