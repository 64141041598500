import React from 'react';
import { Box, Button, Checkbox, ListItemText, MenuItem, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { FullscreenDialog } from '../../common/dialogs/FullscreenDialog';
import { AlertCard, getAlertCardColors } from '../../common/cards/alert-card/AlertCard';

interface ProgramType {
  programId: string;
  name: string;
}

interface ProgramFiltersProps {
  open: boolean;
  fullScreen: boolean;
  onClose: () => void;
  programs: ProgramType[];
  selectedPrograms: string[];
  setSelectedPrograms: (selectedPrograms: string[]) => void;
  onCancel: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      textTransform: 'none',
    },
  })
);

const ALL_ITEMS = [
  {
    label: `All programs`,
    value: 'all',
  },
  {
    label: `None of programs`,
    value: 'none',
  },
];

export function ProgramFilters(props: ProgramFiltersProps) {
  const { open, onClose, fullScreen, programs, selectedPrograms, setSelectedPrograms, onCancel } = props;
  const [stateSelectPrograms, setStateSelectPrograms] = React.useState(selectedPrograms);
  const classes = useStyles();
  const { lightTextColor } = getAlertCardColors('info');

  const onSetPrograms = () => {
    setSelectedPrograms(stateSelectPrograms.filter((programId) => programId !== 'all'));
    onClose();
  };

  const onSelectPrograms = (event: React.ChangeEvent<{ value: unknown }>) => {
    const values = event.target.value as string[];
    if (values.includes('all') && !values.includes('none')) {
      if (stateSelectPrograms.includes('all')) {
        setStateSelectPrograms(
          programs.filter((program) => !values.includes(program.programId)).map((program) => program.programId)
        );
      } else {
        setStateSelectPrograms(['all']);
      }
    } else if (values.includes('none')) {
      setStateSelectPrograms([]);
    } else {
      setStateSelectPrograms(event.target.value as string[]);
    }
  };

  const DialogActions = () => (
    <React.Fragment>
      <Button onClick={onSetPrograms} className={classes.button} variant='contained' color='primary'>
        Continue
      </Button>
    </React.Fragment>
  );

  const sortedPrograms = programs.sort((a, b) => a.name.localeCompare(b.name));
  const programOptions = ALL_ITEMS.concat(
    sortedPrograms.map((program) => ({ label: program.name, value: program.programId })) || []
  );

  return (
    <FullscreenDialog
      title='Select program/s'
      open={open}
      close={onCancel}
      fullScreen={fullScreen}
      dialogActions={DialogActions}
    >
      <Box>
        <Box paddingBottom={'10px'}>
          <AlertCard title='Program Filter' variant='info'>
            <Typography style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
              Select one or many programs below to manage the relevant activities and rosters. Selecting fewer programs
              at a time will increase load speed.
            </Typography>
          </AlertCard>
        </Box>
        <TextField
          style={{ marginTop: '1rem' }}
          fullWidth
          variant='outlined'
          select
          InputLabelProps={{ shrink: true }}
          SelectProps={{
            multiple: true,
            displayEmpty: true,
            renderValue: () => {
              if (stateSelectPrograms.length === 0 || stateSelectPrograms.includes('all')) {
                return 'All programs';
              }
              if (stateSelectPrograms.length >= 3) {
                return `${stateSelectPrograms.length} programs`;
              }
              return stateSelectPrograms
                .map((programId) => programs.find((program) => program.programId === programId))
                .filter((program) => !!program)
                .map((program) => program?.name)
                .join(', ');
            },
          }}
          onChange={onSelectPrograms}
          value={stateSelectPrograms}
          label='Programs'
        >
          {programOptions.map((item) => {
            if (item.value === 'all') {
              return (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary='Select All' />
                </MenuItem>
              );
            }
            if (item.value === 'none') {
              return (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary='Deselect All' />
                </MenuItem>
              );
            }
            return (
              <MenuItem key={item.value} value={item.value}>
                <Checkbox
                  color='primary'
                  value={item.value}
                  checked={stateSelectPrograms.includes(item.value) || stateSelectPrograms.includes('all')}
                />
                <ListItemText primary={item.label} />
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
    </FullscreenDialog>
  );
}
