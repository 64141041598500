import { Box } from '@material-ui/core';
import React from 'react';
import { SwitchField } from '../admin-console-volunteer-profile/form-builder/dialogs/field-task-item-dialog/additional-field-type-fields/SwitchField';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { GET_SESSION_REPORT_VISIBILITY } from './session-report-visibility.gql';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { GetSessionReportVisibility } from './__generated__/GetSessionReportVisibility';

export function SessionReportVisibility() {
  const [querySessionReportVisibility] = useCampfireLazyQuery<GetSessionReportVisibility, {}>(
    GET_SESSION_REPORT_VISIBILITY
  );
  const [sessionReportVisibility, setSessionReportVisibility] = React.useState<boolean | undefined>();
  const { setSnackbar } = useSnackbar();

  React.useEffect(() => {
    querySessionReportVisibility({}).then((response) =>
      setSessionReportVisibility(
        response.data?.vm.configItem?.defaultValue || response.data?.vm.configItem?.configItemValue?.flagValue
      )
    );
  }, []);

  const saveSessionReportVisibility = useCampfireFetch({ defer: true });

  const apiUrl = useApiUrl();
  const onSave = () => {
    saveSessionReportVisibility
      .run({
        url: `${apiUrl}/vm/admin-console/operations/session-report-visibility`,
        method: 'post',
        data: {
          value: !sessionReportVisibility,
        },
      })
      .then(() => {
        setSnackbar({
          open: true,
          message: 'Successfully updated',
          variant: 'success',
        });
      });
    setSessionReportVisibility(!sessionReportVisibility);
  };

  return (
    <Box marginBottom='1rem'>
      <SwitchField
        title='Strict Session Reporting'
        description={
          'Completed session reports will only be visible to volunteers who have been rostered onto a session, completed or edited the report. Program Managers and Admins will be able to view and edit reports as normal.'
        }
        checked={Boolean(sessionReportVisibility)}
        onChange={onSave}
      />
    </Box>
  );
}
