import { gql } from '@apollo/client';

export const GET_MY_NEWS_DATA = gql`
  query GetMyNewsData($volunteerId: String!, $bulletinView: String = "all") {
    vm {
      bulletins(view: $bulletinView) {
        ...MyNewsBulletin
      }
    }
  }
  fragment MyNewsBulletin on VOLUNTEER_BulletinType {
    bulletinId
    title
    content
    pinned
    dateCreated
    isCommentable
    createdBy {
      profile {
        profileId
        preferredName
        lastName
      }
    }
    dateUpdated
    lastUpdatedBy {
      profile {
        profileId
        preferredName
        lastName
      }
    }
    hasVolunteerViewedBulletin(volunteerId: $volunteerId)
  }
`;

export const GET_MY_UNREAD_NEWS_NUMBER_TAB_DATA = gql`
  query GetMyUnreadNewsNumberTabData($volunteerId: String!) {
    vm {
      bulletins {
        ...MyUnreadNewsNumberBulletin
      }
    }
  }
  fragment MyUnreadNewsNumberBulletin on VOLUNTEER_BulletinType {
    bulletinId
    hasVolunteerViewedBulletin(volunteerId: $volunteerId)
  }
`;

export const ALLOW_COMMENT_CONFIG_ITEM_QUERY = gql`
  query GetAllowCommentConfigItem {
    vm {
      configItem(key: "bulletin-allow-comment") {
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;

