import { unpackToDateTime } from '@campfire/hot-date';
import { isEqual } from 'lodash';
import { Box, ClickAwayListener, IconButton, Tooltip, Typography } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColumns, GridSortModel } from '@material-ui/data-grid';
import { DeleteOutline, Edit, MoreVertOutlined } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import React, { useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';
import { SearchField } from '../../../../../common/inputs/SearchField';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { PROGRAM_MANAGEMENT_GET_VOLUNTEER_APPLICATIONS } from '../../program-management.gql';
import { useProgramManagementContext } from '../../ProgramManagementContext';
import {
  ProgramManagementGetVolunteerApplications,
  ProgramManagementGetVolunteerApplicationsVariables,
} from '../../__generated__/ProgramManagementGetVolunteerApplications';
import { VolunteerProfile } from '../../../../../common/AvatarName';
import { tooltipStyles } from '../../../../../common/styles/Tooltip';
import { ProgramApplication } from '../../__generated__/ProgramApplication';

const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      display: 'flex',
      padding: '0.5rem',
      borderRadius: '4px',
      cursor: 'pointer',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.75,
      },
    },
    menuItemText: {
      marginLeft: '0.75rem',
      fontSize: '14px',
    },
  })
);

const useTableStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '500px',
      '& .MuiDataGrid-columnsContainer': {
        justifyContent: 'center',
        backgroundColor: '#F9F9F9',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-selectedRowCount': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-columnHeader--moving': {
        background: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
  })
);

export interface ProgramManagementVol {
  volunteerId: string;
  profile: {
    profileId: string;
    preferredName: string;
    lastName: string;
    avatarUrl: string | null;
  };
}

export type ApplicationActions = 'add' | 'withdraw';

export function ApplicationActionMenu({
  onAction,
  bulk,
}: {
  onAction: (action: ApplicationActions, handleSuccess?: () => void) => void;
  bulk: boolean;
}) {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.menuItem} onClick={() => onAction('add')}>
        <Edit fontSize='small' />
        <Typography className={classes.menuItemText}>{bulk ? 'Add all to team' : 'Add to team'}</Typography>
      </Box>
      <Box className={classes.menuItem} onClick={() => onAction('withdraw')}>
        <DeleteOutline fontSize='small' />
        <Typography className={classes.menuItemText}>{bulk ? 'Withdraw all' : 'Withdraw'}</Typography>
      </Box>
    </Box>
  );
}

export function ApplicationActionMenuButton({
  onAction,
  bulk,
}: {
  onAction: (action: ApplicationActions, handleSuccess?: () => void) => void;
  bulk: boolean;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const onCloseMenu = (e: any) => {
    e.stopPropagation();
    setMenuOpen(false);
  };
  const onOpenMenu = (e: any) => {
    e.stopPropagation();
    setMenuOpen(true);
  };

  const classes = tooltipStyles();

  return (
    <ClickAwayListener onClickAway={onCloseMenu}>
      <Tooltip
        arrow
        interactive
        title={<ApplicationActionMenu onAction={onAction} bulk={bulk} />}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement='bottom-end'
        open={menuOpen}
        onClose={onCloseMenu}
        onOpen={onOpenMenu}
        classes={classes}
      >
        <IconButton onClick={onOpenMenu} style={{ color: '#0000008A', padding: '0.5rem' }}>
          <MoreVertOutlined />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

const ProgramApplicationSectionDescription = () => (
  <Typography variant='body2' color='textSecondary' style={{ marginBottom: 8 }}>
    All Volunteer applications for this Program
  </Typography>
);

export const ProgramApplicationSection = () => {
  const {
    getActiveVolunteersRefetch,
    runAddToTeam,
    runWithdrawProgramApplication,
    runAddBulkVolunteersToTeam,
    runWithdrawBulkApplications,
    compact,
  } = useProgramManagementContext();

  const [selectedProgramId] = useQueryParam('pid', StringParam);
  const [selectedApplicationIds, setSelectedApplicationIds] = useState<(string | number)[]>([]);
  const [searchInput, setSearchInput] = useState<string>();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'volunteerName',
      sort: 'asc',
    },
  ]);
  const tableClasses = useTableStyles();

  const { data: getProgramApplicationsData, refetch: refetchProgramApplications } = useCampfireQuery<
    ProgramManagementGetVolunteerApplications,
    ProgramManagementGetVolunteerApplicationsVariables
  >(PROGRAM_MANAGEMENT_GET_VOLUNTEER_APPLICATIONS, {
    options: {
      variables: {
        programId: selectedProgramId,
      },
    },
  });

  const programApplications =
    getProgramApplicationsData?.vm.program?.programApplications
      .filter(
        (application) =>
          !application.dateActioned &&
          !application.dateWithdrawn &&
          !application.volunteer.dateDeactivated &&
          !application.volunteer.flagging?.dateFlagged
      )
      .sort((a, b) => (a.volunteer.profile.lastName > b.volunteer.profile.lastName ? 1 : -1))
      .map((application: ProgramApplication) => ({
        id: application.programApplicationId,
        profile: application.volunteer.profile,
        dateApplied: unpackToDateTime(application.dateApplied),
      })) || [];

  const filteredProgramApplications = useMemo(
    () =>
      programApplications?.filter((application: any) =>
        searchInput ? application.volunteerName.toLowerCase().includes(searchInput.toLowerCase()) : true
      ),
    [searchInput, programApplications]
  );

  const onSortChange = (model: GridSortModel) => {
    if (!isEqual(model, sortModel)) {
      setSortModel(model);
    }
  };

  const handleSuccess = (action: ApplicationActions) => {
    if (refetchProgramApplications) {
      setTimeout(refetchProgramApplications, 0);
    }
    if (getActiveVolunteersRefetch && action === 'add') {
      setTimeout(getActiveVolunteersRefetch, 0);
    }
  };

  const handleBulkSuccess = (action: ApplicationActions) => {
    if (refetchProgramApplications) {
      setTimeout(refetchProgramApplications, 0);
      setSelectedApplicationIds([]);
    }
    if (getActiveVolunteersRefetch && action === 'add') {
      setTimeout(getActiveVolunteersRefetch, 0);
    }
  };

  const onActionBulk = (action: ApplicationActions) => {
    switch (action) {
      case 'add':
        if (selectedProgramId) {
          runAddBulkVolunteersToTeam(selectedApplicationIds, selectedProgramId, () => handleBulkSuccess(action));
        }
        break;

      case 'withdraw':
        if (selectedProgramId) {
          runWithdrawBulkApplications(selectedApplicationIds, selectedProgramId, () => handleBulkSuccess(action));
        }
        break;

      default:
        break;
    }
  };

  const onAction = (action: ApplicationActions, id: string) => {
    switch (action) {
      case 'add':
        runAddToTeam(id, () => handleSuccess(action));
        break;

      case 'withdraw':
        runWithdrawProgramApplication(id, () => handleSuccess(action));
        break;

      default:
        break;
    }
  };

  const columns: GridColumns = [
    {
      field: 'volunteerName',
      headerName: 'Volunteer',
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <VolunteerProfile
            inTable
            noTrim
            {...row.profile}
            name={`${row.profile.preferredName} ${row.profile.lastName}`}
          />
        );
      },
    },
    {
      field: 'dateApplied',
      headerName: 'Date Applied',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: ({ row }: any) => row.dateApplied.toFormat('dd/MM/y'),
    },
    {
      field: '',
      disableColumnMenu: true,
      headerName: '',
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <ApplicationActionMenuButton
            onAction={(action: ApplicationActions) => onAction(action, row.id)}
            bulk={false}
          />
        );
      },
    },
  ];

  return (
    <FormSectionV2
      title='Applications'
      description={<ProgramApplicationSectionDescription />}
      search={
        <SearchField
          placeholder='Search Applications'
          onChange={(e) => setSearchInput(e.target.value)}
          fullWidth={compact}
        />
      }
    >
      {programApplications.length === 0 ? (
        <Typography variant='subtitle2' color='textSecondary' style={{ paddingTop: '6px' }}>
          No applications
        </Typography>
      ) : (
        <>
          {selectedApplicationIds.length > 0 && (
            <Box display='flex' alignItems='center' justifyContent='flex-end' paddingRight='15px'>
              <Typography style={{ fontSize: '15px', paddingRight: '3px' }}>
                <strong>{selectedApplicationIds.length}</strong> selected
              </Typography>
              <ApplicationActionMenuButton onAction={onActionBulk} bulk />
            </Box>
          )}
          <DataGrid
            columns={columns}
            rows={filteredProgramApplications}
            classes={tableClasses}
            onSelectionModelChange={setSelectedApplicationIds}
            sortModel={sortModel}
            onSortModelChange={(model) => onSortChange(model)}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            disableSelectionOnClick
          />
        </>
      )}
    </FormSectionV2>
  );
};
