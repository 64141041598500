import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, createStyles } from '@material-ui/styles';
import { CollapsibleSideBar } from '../../../common/CollapsibleSideBar';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { ActivityPendingApplications } from './ActivitySidebar/ActivityPendingApplications';
import { ActivityWaitlistings } from './ActivitySidebar/ActivityWaitlistings';
import { ActivityApplication } from './ActivitySidebar/ActivityApplication';
import {
  ActivitiesGetMyPendingApplications,
  ActivitiesGetMyPendingApplicationsVariables,
  ActivitiesGetMyPendingApplications_vm_volunteer_activityApplications as PendingApplications,
} from './ActivityQueries/__generated__/ActivitiesGetMyPendingApplications';
import {
  ActivitiesGetMyWaitlistings,
  ActivitiesGetMyWaitlistingsVariables,
  ActivitiesGetMyWaitlistings_vm_volunteer_activityWaitlistings as WaitlistingApplications,
} from './ActivityQueries/__generated__/ActivitiesGetMyWaitlistings';

import { GET_MY_PENDING_APPLICATIONS, GET_MY_WAITLISTING_APPLICATIONS } from './ActivityQueries/get-my-shifts.gql';
import { useCampfireFetch } from '../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../global/config/useApiUrl';
import { TemplateSnackbar, SnackBarOptions } from '../../../common/snackbars/TemplateSnackbar';

interface Props {
  volunteerId: string;
}

type ActivityApplicationType<T> = T & {
  activityDate: string;
  startTime: string;
  endTime: string;
  name: string;
  activityId: string;
};

export function getUpcomingApplications(application: any) {
  return {
    ...application.activity,
    ...application,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('lg')]: {
        marginLeft: -16,
        marginRight: -16,
      },
    },
  })
);

interface ActivityMyshiftSidebarProps {
  children: React.ReactNode;
}

export function ActivityMyshiftSidebar({ children }: ActivityMyshiftSidebarProps) {
  const classes = useStyles();
  return (
    <CollapsibleSideBar>
      <Box className={classes.container}>{children}</Box>
    </CollapsibleSideBar>
  );
}

export function ActivityMyApplications({ volunteerId }: Props) {
  const {
    data: pendingApplications,
    loading: pendingApplicationLoading,
    refetch: refetchApplication,
  } = useCampfireQuery<ActivitiesGetMyPendingApplications, ActivitiesGetMyPendingApplicationsVariables>(
    GET_MY_PENDING_APPLICATIONS,
    {
      options: {
        variables: {
          volunteerId,
        },
      },
    }
  );

  const {
    data: activityWaitlistings,
    loading: activityWaitlistingsLoading,
    refetch: refetchWaitlisting,
  } = useCampfireQuery<ActivitiesGetMyWaitlistings, ActivitiesGetMyWaitlistingsVariables>(
    GET_MY_WAITLISTING_APPLICATIONS,
    {
      options: {
        variables: {
          volunteerId,
        },
      },
    }
  );

  const activePendingAppications = pendingApplications?.vm.volunteer?.activityApplications
    .filter((activityApplication) => !activityApplication.dateWithdrawn)
    .map(getUpcomingApplications);

  const activeWaitlistings = activityWaitlistings?.vm.volunteer?.activityWaitlistings
    .filter((activityWaitlisting) => !activityWaitlisting.dateRemoved)
    .map(getUpcomingApplications);

  const apiUrl = useApiUrl();

  const withdrawWaitlisting = useCampfireFetch({ defer: true });

  const [snackBar, setSnackBar] = React.useState<SnackBarOptions>();

  const onWithdrawWaitlisting = (activityWaitlistingId: string) =>
    withdrawWaitlisting
      .run({
        url: `${apiUrl}/vm/activity/waitlist/remove`,
        method: 'POST',
        data: { activityWaitlistingId },
      })
      .then(() => {
        if (refetchWaitlisting) {
          setTimeout(refetchWaitlisting, 0);
        }
        setSnackBar({
          open: true,
          variant: 'success',
          message: 'Withdraw from waitlist successfully',
        });
      });

  const withdrawApplication = useCampfireFetch({ defer: true });
  const onWithdrawApplication = (activityApplicationId: string, activityId: string, activityDates: string[]) =>
    withdrawApplication
      .run({
        url: `${apiUrl}/vm/activity/application/withdraw`,
        method: 'POST',
        data: {
          activityId,
          activityDates,
          activityApplicationId,
        },
      })
      .then(() => {
        if (refetchApplication) {
          setTimeout(refetchApplication, 0);
        }
        setSnackBar({
          open: true,
          variant: 'success',
          message: 'Withdraw an application successfully',
        });
      });

  return (
    <React.Fragment>
      <Box width={1}>
        <ActivityPendingApplications>
          {pendingApplicationLoading ? (
            <Skeleton width='100%' height={50} style={{ marginTop: '1rem' }} />
          ) : activePendingAppications?.length ? (
            activePendingAppications?.map((activity: ActivityApplicationType<PendingApplications>) => (
              <ActivityApplication
                key={`${activity.activityId}-${activity.activityDate}`}
                {...activity}
                activityDate={activity.dateApplied}
                onWithdraw={() =>
                  onWithdrawApplication(activity.activityApplicationId, activity.activityId, [activity.activityDate])
                }
              />
            ))
          ) : (
            <Typography style={{ marginTop: '1rem' }}>No pending applications</Typography>
          )}
        </ActivityPendingApplications>
      </Box>
      <Box marginTop='2rem'>
        <ActivityWaitlistings>
          {activityWaitlistingsLoading ? (
            <Skeleton width='100%' height={50} style={{ marginTop: '1rem' }} />
          ) : activeWaitlistings?.length ? (
            activeWaitlistings?.map((activity: ActivityApplicationType<WaitlistingApplications>) => (
              <ActivityApplication
                key={`${activity.activityId}-${activity.activityDate}`}
                {...activity}
                activityDate={activity.dateAdded}
                onWithdraw={() => onWithdrawWaitlisting(activity.activityWaitlistingId)}
              />
            ))
          ) : (
            <Typography style={{ marginTop: '1rem' }}>No waitlisting applications</Typography>
          )}
        </ActivityWaitlistings>
      </Box>
      {snackBar && <TemplateSnackbar {...snackBar} onClose={() => setSnackBar(undefined)} />}
    </React.Fragment>
  );
}
