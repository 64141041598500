import { Redirect, Route, withRouter } from 'react-router';
import React, { useState } from 'react';
import { RostersScreen } from './RostersScreen';
import { CampfireSwitch } from '../../../content-blocks/common/CampfireSwitch';
import { RosterTemplateScreen } from './roster-templates-screen/RosterTemplateScreen';
import { EditRosterTemplateScreen } from './roster-templates-screen/EditRosterTemplateScreen';
import { ProgramFiltersWrapper } from '../../../global/program-filters/ProgramFiltersWrapper';
import { BulkRosterScreen } from './bulk-roster/BulkRosterScreen';
import { RostersTutorialDialog } from './RostersTutorialDialog';
import { Page } from '../../../global/components';

export const RostersScreenRouter = withRouter(({ match }) => {
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      <ProgramFiltersWrapper managementView>
        <CampfireSwitch>
          <Route exact path={match.path} render={() => <RostersScreen />} />
          <Route exact path={`${match.path}/bulk-roster`} render={() => <BulkRosterScreen />} />
          <Route exact path={`${match.path}/create-roster-template`} render={() => <RosterTemplateScreen />} />
          <Route exact path={`${match.path}/edit-roster-template`} render={() => <EditRosterTemplateScreen />} />
          <Route path={`${match.path}/*`} render={() => <Redirect to={match.path} />} />
        </CampfireSwitch>
      </ProgramFiltersWrapper>
      <RostersTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
        }}
      />
    </Page>
  );
});
