import React from 'react';
import { List } from '@material-ui/core';
import { RosterListItem } from '../main-section/roster/roster-types';
import { RosterItem } from './RosterItem';

interface RosterListProps {
  rosters: RosterListItem[];
}

export const RostersList = (props: RosterListProps) => {
  const { rosters } = props;
  return (
    <List>
      {rosters.map((r) => (
        <RosterItem roster={r} />
      ))}
    </List>
  );
};
