import { Box, Typography } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import React, { memo } from 'react';
import { getDisplayTimeSchedule } from '../../../../common/functions/activity-display-helpers';
import { IconsTextRowLayout } from '../../../../common/icons/IconsTextRowLayout';
import { useUser } from '../../../../global/auth/useUser';
import { ActivitiesExploreActivity } from '../__generated__/ActivitiesExploreActivity';
import { getDayTimeIndicator } from './activity-display-utils';
import { ActivityBody } from './ActivityBody';
import { ActivityHeader } from './ActivityHeader';

type ActivityInfoProps = {
  activity: ActivitiesExploreActivity;
  bodyDisplayState?: 'withAllActivityDetail' | 'withMinActivityDetail';
};

const ActivityInfo = memo(({ activity, bodyDisplayState }: ActivityInfoProps) => {
  const {
    name,
    startTime,
    endTime,
    startDate,
    endDate,
    description,
    activityLocation,
    program: { programId },
    isEnrollable,
    activityId,
  } = activity;

  const humanReadableAddress = activityLocation?.placesAddress?.description;

  const {
    user: { userIdentityService },
  } = useUser();
  const isAdmin = userIdentityService.privLevel === 'admin';
  const isProgramManager = userIdentityService.isManagerOfProgram(programId);
  const userIsManagerOfProgram = isProgramManager || isAdmin;
  const createLocation = (location: any, remoteLocationDetails?: string | null) => {
    if (location) {
      return humanReadableAddress;
    }
    return (
      <Box marginTop={2}>
        <IconsTextRowLayout leftIcon={<Room color='action' />} text={'This activity is remote.'} />
        {remoteLocationDetails && (
          <Typography variant='body2' style={{ paddingLeft: 48 }}>
            {remoteLocationDetails}
          </Typography>
        )}
      </Box>
    );
  };

  const activityOccurence =
    activity.__typename === 'VOLUNTEER_RecurringActivityType'
      ? `${activity.schedule.recurrences.flatMap((recurrence) => recurrence.humanReadable).join(', ')}`
      : 'Flexible';

  const activityTime = getDisplayTimeSchedule(startTime, endTime);

  const isActivitySingularlyRecurring =
    activity.__typename === 'VOLUNTEER_RecurringActivityType'
      ? !!activity.schedule.recurrences.find((recurrence) => recurrence.__typename === 'SingleRecurrenceType')
      : false;
  return (
    <Box display='flex' flexDirection='column' width={1}>
      <ActivityHeader
        activityName={name}
        activityDescription={description}
        activityDayTimeIndicator={getDayTimeIndicator(startTime)}
        activityId={activityId}
        isEditable={userIsManagerOfProgram || isAdmin}
      />
      {bodyDisplayState && bodyDisplayState === 'withAllActivityDetail' && (
        <ActivityBody
          activityTime={activityTime}
          activityLocationAddress={createLocation(activity.activityLocation)}
          activityIsEnrollable={isEnrollable}
          activityOccurence={activityOccurence}
          activityStartDate={startDate}
          activityEndDate={endDate}
          isActivitySingularlyRecurring={isActivitySingularlyRecurring}
        />
      )}
      {bodyDisplayState && bodyDisplayState === 'withMinActivityDetail' && (
        <ActivityBody
          activityLocationAddress={createLocation(activity.activityLocation, activity.activityRemoteLocation?.details)}
        />
      )}
    </Box>
  );
});

export { ActivityInfo };
