import React from 'react';
import { Grid, TextField, Typography, MenuItem, Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { HoverText } from '@campfire/hover-link';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

import { DaySelect, DayOptions } from './DaySelect';
import { ActivityTagSelect } from './ActivityTagSelect';
import { sharedStyles } from '../ActivityStyles/shared';
import { GetAllActivityActivityTags_vm_activityTags as ActivityTagType } from '../ActivityQueries/__generated__/GetAllActivityActivityTags';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.color.white.neutral50,
      borderRadius: '6px',
    },
    daySelectContainer: {
      width: 265,
      flex: 1,
      marginTop: '12px',
      [theme.breakpoints.down('sm')]: {
        width: 'unset',
      },
    },
    daySelectRoot: {
      alignSelf: 'start',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'unset',
      },
    },
    label: {
      textTransform: 'uppercase',
      color: theme.color.grey.neutral300,
      fontWeight: 700,
      fontSize: '0.75rem',
    },
    itemWithBorder: {
      borderTop: 'solid 1px',
      borderTopColor: theme.color.grey.neutralBrand200,
    },
    timeBorder: {
      borderBottom: 'solid 1px',
      borderBottomColor: theme.color.grey.neutralBrand200,
    },
  })
);

interface Options {
  label: string;
  value: string | number;
}

interface Props {
  values: {
    program: string;
    activityType: string;
    duration: number;
    location: string;
    day: { [key: number]: boolean };
    startTime: string;
    endTime: string;
    activityStatus: string;
    rosterType: string;
    activityTag?: { [key: string]: boolean };
  };
  onChange: (arg: any) => void;
  programOptions: Options[];
  activityOptions: Options[];
  durationOptions: Options[];
  locationOptions: Options[];
  dayOptions: DayOptions[];
  activityStatusesOptions: Options[];
  rosterTypesOptions: Options[];
  activityTagOptions?: ActivityTagType[];
}

export function ActivityFilter({
  values,
  onChange,
  programOptions,
  activityOptions,
  durationOptions,
  locationOptions,
  dayOptions,
  activityStatusesOptions,
  rosterTypesOptions,
  activityTagOptions = [],
}: Props) {
  const classes = useStyles();
  const { isSm } = useCampfireTheme();

  const onSelect = (e: any) => {
    onChange({ [e.target.name]: e.target.value });
  };

  const onSelectDay = (dayValue: number) =>
    onChange({
      day: {
        ...values.day,
        [dayValue]: !values.day[dayValue],
      },
    });

  const onSelectActivityTag = (tagIds: string[]) => {
    const newValue = tagIds.reduce((pv, av) => ({ ...pv, [av]: true }), {});
    onChange({
      activityTag: {
        ...newValue,
      },
    });
  };

  const onClearTime = () => {
    onChange({
      ...values,
      startTime: '',
      endTime: '',
    });
  };

  const inputClasses = sharedStyles.textField();

  return (
    <Box flexGrow={1} className={classes.container}>
      <Grid container spacing={isSm ? 2 : 3} style={{ width: 'unset', margin: 0 }}>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <Typography className={classes.label}>Programs</Typography>
          <TextField
            name='program'
            value={values.program}
            select
            variant='outlined'
            fullWidth
            onChange={onSelect}
            InputProps={{ classes: inputClasses }}
          >
            {programOptions.map(({ value, label }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <Typography className={classes.label}>Activity Types</Typography>
          <TextField
            name='activityType'
            value={values.activityType}
            select
            variant='outlined'
            fullWidth
            onChange={onSelect}
            InputProps={{ classes: inputClasses }}
          >
            {activityOptions.map(({ value, label }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <Typography className={classes.label}>Duration</Typography>
          <TextField
            name='duration'
            value={values.duration}
            select
            variant='outlined'
            fullWidth
            onChange={onSelect}
            InputProps={{ classes: inputClasses }}
          >
            {durationOptions.map(({ value, label }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <Typography className={classes.label}>Locations</Typography>
          <TextField
            name='location'
            value={values.location}
            select
            variant='outlined'
            fullWidth
            onChange={onSelect}
            InputProps={{ classes: inputClasses }}
          >
            {locationOptions.map(({ value, label }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <Typography className={classes.label}>Activity Status</Typography>
          <TextField
            name='activityStatus'
            value={values.activityStatus}
            select
            variant='outlined'
            fullWidth
            onChange={onSelect}
            InputProps={{ classes: inputClasses }}
          >
            {activityStatusesOptions.map(({ value, label }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <Typography className={classes.label}>Roster Types</Typography>
          <TextField
            name='rosterType'
            value={values.rosterType}
            select
            variant='outlined'
            fullWidth
            onChange={onSelect}
            InputProps={{ classes: inputClasses }}
          >
            {rosterTypesOptions.map(({ value, label }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <Box width={'100%'}>
            <Typography className={classes.label}>Activity Tags</Typography>
            <ActivityTagSelect
              selectedTagIds={values.activityTag || {}}
              activityTags={activityTagOptions}
              onChange={onSelectActivityTag}
            />
          </Box>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Box display='flex' justifyContent='space-between' className={classes.timeBorder}>
            <Typography className={classes.label}>Time</Typography>
            <HoverText color='primary' variant='body2' style={{ fontSize: 12 }} onClick={onClearTime}>
              Clear
            </HoverText>
          </Box>
          <Grid container spacing={isSm ? 2 : 3} style={{ marginTop: '4px' }}>
            <Grid xs={6} item>
              <TextField
                variant='outlined'
                type='time'
                label='Start Time'
                fullWidth
                name='startTime'
                onChange={onSelect}
                value={values.startTime}
                InputLabelProps={{ shrink: true }}
                InputProps={{ classes: inputClasses }}
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                variant='outlined'
                type='time'
                label='End Time'
                fullWidth
                name='endTime'
                onChange={onSelect}
                value={values.endTime}
                InputLabelProps={{ shrink: true }}
                InputProps={{ classes: inputClasses }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <Box className={classes.daySelectRoot}>
            <Box className={classes.timeBorder}>
              <Typography className={classes.label}>Day</Typography>
            </Box>
            <DaySelect
              days={dayOptions}
              daysSelected={values.day}
              onSelectDay={onSelectDay}
              className={classes.daySelectContainer}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
