import { TabletButton } from '@campfire/tablet-button';
import { Menu, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { REPORT_MANAGEMENT_TAB_OPTIONS } from '../ReportManagementConsts';

export const ReportManagementMobileTabMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  useEffect(() => {
    if (!query.tab) setQuery({ ...query, tab: 'report-types' });
  }, [query.tab]);

  const handleOpenTabDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTabDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TabletButton
        size='small'
        variant='outlined'
        color='primary'
        aria-controls='report-management-tabs-menu'
        aria-haspopup='true'
        onClick={handleOpenTabDropdown}
        endIcon={<ExpandMore />}
        style={{
          border: '1px solid #9e9e9e',
          boxSizing: 'border-box',
          borderRadius: '4px',
          padding: '7px 12px',
          color: '#444444',
          marginRight: '8px',
          minWidth: '100px',
          fontSize: '16px',
          fontWeight: 900,
        }}
      >
        {query.tab
          ? capitalize(REPORT_MANAGEMENT_TAB_OPTIONS.find((option) => option === query.tab)?.replace('-', ' '))
          : null}
      </TabletButton>
      <Menu
        id='report-management-tabs-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseTabDropdown}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {REPORT_MANAGEMENT_TAB_OPTIONS.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              setQuery({ tab: option });
              handleCloseTabDropdown();
            }}
          >
            {capitalize(option.replace('-', ' '))}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
