import { Box, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React, { memo, useEffect, useState } from 'react';
import { ArrayParam, useQueryParam } from 'use-query-params';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { BorderSelectInput, useFilterStyles } from '../../../../../common/filter-fields/FilterClasses';
import { dayFilters, DAY_FILTER_URL_PARAM_KEY, Filters } from '../../../../../common/filter-fields/filters';

type DayFilterSelectFieldProps = {
  selectedFilters: Filters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
};

const DayFilterSelectField = memo(({ selectedFilters, setSelectedFilters }: DayFilterSelectFieldProps) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const { selectedDayFilters } = selectedFilters;
  const [urlParam, setUrlParam] = useQueryParam<Array<string> | undefined>(DAY_FILTER_URL_PARAM_KEY, ArrayParam);

  const isDaySelected = (value: string) => selectedDayFilters.value.indexOf(value) > -1;
  const isActiveFilter = selectedDayFilters.value.length > 0 && selectedDayFilters.value.length < 7;

  const handleChangeDays = (value: Array<string>) => {
    setSelectedFilters((prev) => ({
      ...prev,
      selectedDayFilters: {
        isActive: value.length > 0,
        urlParamKey: DAY_FILTER_URL_PARAM_KEY,
        value,
      },
    }));
  };

  useEffect(() => {
    if (!urlParam) {
      handleChangeDays([]);
      return;
    }
    handleChangeDays(urlParam);
  }, [urlParam]);

  return (
    <Grid item className={classes.subheaderGridItemNew}>
      <Select
        data-track={`actCnlMapFilters-DayFilterSelectField`}
        multiple
        displayEmpty
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        input={
          <BorderSelectInput
            status={isActiveFilter ? 'active' : undefined}
            onClick={popoverOpen ? undefined : () => setPopoverOpen(true)}
            style={{ cursor: 'pointer' }}
          />
        }
        MenuProps={{
          MenuListProps: {
            style: {
              display: 'flex',
              flexDirection: 'row',
              padding: 16,
            },
          },
        }}
        IconComponent={() => (
          <Box display='flex' alignItems='center' onClick={() => setPopoverOpen(true)} style={{ cursor: 'pointer' }}>
            <ArrowDropDown style={{ fill: isActiveFilter ? theme.palette.primary.main : 'rgba(0,0,0, 0.54)' }} />
          </Box>
        )}
        renderValue={(selected: any) => {
          if (selected.length > 0 && selected.length < 7) {
            return (
              selected &&
              selected
                .map((val: string) => dayFilters.find((dayFilter) => dayFilter.value === val)?.selectDisplay)
                .join(', ')
            );
          }
          return 'Any day';
        }}
        onChange={(event) => {
          setUrlParam(event.target.value as Array<string>);
        }}
        value={selectedDayFilters.value}
      >
        {dayFilters.map((filter) => (
          <MenuItem
            key={filter.value}
            value={filter.value}
            className={classes.roundBox}
            style={{
              background: isDaySelected(filter.value) ? '#0288d1' : theme.palette.grey[200],
              color: isDaySelected(filter.value) ? 'white' : 'inherit',
            }}
          >
            <Typography
              variant='body2'
              style={isDaySelected(filter.value) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
            >
              {filter.label.charAt(0)}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
});

export { DayFilterSelectField };
