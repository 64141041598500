import { Field } from '@campfire/field';
import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Collapse, Grid, Switch, Typography } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';
import { FieldArray, useFormikContext } from 'formik';
import React, { memo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { FormSection } from '../common/FormSection';
import { ManageReportTypeActivity } from './__generated__/ManageReportTypeActivity';
import { ManageReportTypeBaseReportType } from './__generated__/ManageReportTypeBaseReportType';
import { EditFieldDialog } from './edit-field-dialog/EditFieldDialog';
import { ReportTypeFormValues, ReportTypeItemFieldValue } from './manage-report-type-model';
import { ReportTypeFieldsEditor } from './ReportTypeFieldsEditor';
import { ActivityStatusChip } from '../../../../common/chips/ActivityStatusChip';

const BasicInfoSection = memo(() => {
  const [showDescription, setShowDescription] = useState(false);
  const formik = useFormikContext<ReportTypeFormValues>();
  const hasDescription = !!formik.values?.reportTypeDescription;
  const collapseModeIn = showDescription || hasDescription;
  return (
    <FormSection
      title='Basics'
      description={
        <Typography variant='body2' display='inline' color='textSecondary'>
          {`Choose a unique and descriptive name for the Report Type.`}
        </Typography>
      }
    >
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12}>
          <Field required name='reportTypeName' label='Report Type Name' variant='outlined' helper fullWidth />
        </Grid>
        <Grid item>
          <Collapse in={collapseModeIn} timeout={0}>
            <Field
              name='reportTypeDescription'
              multiline
              rows={6}
              variant='outlined'
              label='Description (Optional)'
              margin='normal'
              fullWidth
              helper
            />
          </Collapse>
          {!collapseModeIn ? (
            <HoverText variant='body2' color='primary' onClick={() => setShowDescription(true)}>
              {'Add a description'}
            </HoverText>
          ) : null}
        </Grid>
      </Grid>
    </FormSection>
  );
});

const FieldsSection = memo(() => {
  const formik = useFormikContext<ReportTypeFormValues>();
  const [openEditFieldDialog, setOpenEditFieldDialog] = useState(false);
  const [selectedField, setSelectedField] = useState<ReportTypeItemFieldValue>();

  return (
    <FieldArray name='items'>
      {(arrayHelpers) => (
        <FormSection
          title='Fields'
          description={
            <>
              <Typography variant='body2' display='block' color='textSecondary'>
                {`Customise the fields for this Report Type. Click and drag the tabs on the left to reorder the fields and headers.`}
              </Typography>
            </>
          }
        >
          <ReportTypeFieldsEditor
            setOpenEditFieldDialog={setOpenEditFieldDialog}
            setSelectedField={setSelectedField}
            arrayHelpers={arrayHelpers}
          />

          <Box marginTop={3} display='flex' alignItems='center'>
            <TabletButton
              variant='contained'
              color='primary'
              size='medium'
              endIcon={<PostAdd />}
              onClick={() => {
                setSelectedField(undefined);
                setOpenEditFieldDialog(true);
              }}
            >
              {`Add Field`}
            </TabletButton>
            <HoverText
              style={{ marginLeft: 16 }}
              variant='body2'
              color='primary'
              onClick={() => {
                const updatedItems = formik.values.items;
                updatedItems.push({
                  __typename: 'VOLUNTEER_ReportTypeHeadingType',
                  heading: 'New Heading',
                  order: 0,
                  listId: uuidv4(),
                });
                formik.setFieldValue('items', updatedItems);
              }}
            >
              {`Add Heading`}
            </HoverText>
          </Box>

          <EditFieldDialog
            isOpen={openEditFieldDialog}
            close={() => setOpenEditFieldDialog(false)}
            fieldData={selectedField}
            delete={() => {
              arrayHelpers.remove(
                formik.values.items.findIndex((item) => item.reportTypeItemId === selectedField?.reportTypeItemId)
              );
            }}
            onSubmit={(values) => {
              if (selectedField?.reportTypeItemId) {
                arrayHelpers.replace(
                  formik.values.items.findIndex((item) => item.reportTypeItemId === selectedField.reportTypeItemId),
                  values
                );
                setSelectedField(undefined);
                return;
              }

              arrayHelpers.push(values);
              setSelectedField(undefined);
            }}
          />
        </FormSection>
      )}
    </FieldArray>
  );
});

interface UsedInSectionProps {
  activitiesUsingReportType?: ManageReportTypeActivity[];
}
const UsedInSection = memo((props: UsedInSectionProps) => {
  const { activitiesUsingReportType } = props;
  const { theme } = useCampfireTheme();
  return (
    <FormSection
      title='Used In'
      description={
        <Typography variant='caption' color='textSecondary'>
          {`List of activities that uses this report type`}
        </Typography>
      }
    >
      <Grid container direction='column' alignItems='center'>
        {activitiesUsingReportType?.length ? (
          <Grid container direction='column' spacing={1}>
            <Grid item xs={12} style={{ borderBottom: `1px solid ${theme.palette.grey[200]}` }}>
              <Typography variant='h6'>{`${activitiesUsingReportType.length} Activities`} </Typography>
            </Grid>
            {activitiesUsingReportType.map((activity) => (
              <Grid
                item
                container
                direction='column'
                xs={12}
                style={{ padding: 8, paddingLeft: 16, borderBottom: `1px solid ${theme.palette.grey[200]}` }}
                key={activity.activityId}
              >
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant='subtitle2' display='inline'>
                      {activity.name}
                    </Typography>
                  </Grid>
                  {activity.isSuspended || activity.closedActivity ? (
                    <Grid item>
                      <ActivityStatusChip
                        status={activity.closedActivity ? 'CLOSED' : activity.isSuspended ? 'SUSPENDED' : undefined}
                      />
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption' display='inline'>{`${activity.sessions.length} ${
                    activity.sessions.length === 1 ? 'session' : 'sessions'
                  }`}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant='caption'>{`There are no activities currently using this report type`}</Typography>
        )}
      </Grid>
    </FormSection>
  );
});

const StatusSection = memo(() => {
  const formik = useFormikContext<ReportTypeFormValues>();
  return (
    <FormSection
      title='Status'
      description={
        <Typography variant='caption' color='textSecondary'>{`Toggle the status of this report type`}</Typography>
      }
    >
      <Grid container spacing={2}>
        <Grid item>
          <Switch
            color='primary'
            checked={!formik.values.active || false}
            onChange={() => formik.setFieldValue('active', !formik.values.active)}
          />
        </Grid>
        <Grid item xs container direction='column'>
          <Typography variant='subtitle2' display='inline'>
            {`Archive this report?`}
          </Typography>
          <Typography variant='caption'>{`Archiving this report will hide it by default, meaning that it won't be a visible option when creating sessions`}</Typography>
        </Grid>
      </Grid>
    </FormSection>
  );
});

interface ReportTypeFormProps {
  selectedReportType?: ManageReportTypeBaseReportType;
  forceActive?: boolean;
}
const ReportTypeForm = memo((props: ReportTypeFormProps) => {
  const { selectedReportType, forceActive } = props;
  const formik = useFormikContext<ReportTypeFormValues>();
  return (
    <Grid container>
      <Grid item xs={12} style={{ padding: 16 }}>
        <Typography variant='h4' component='h1' display='inline' style={{ marginBottom: 16 }}>
          {formik.values?.reportTypeName || ''}
        </Typography>
        <BasicInfoSection />
        <FieldsSection />
        {formik.values.reportTypeId ? (
          <UsedInSection activitiesUsingReportType={selectedReportType?.activities} />
        ) : null}
        {forceActive ? null : <StatusSection />}
      </Grid>
    </Grid>
  );
});

export { ReportTypeForm };
