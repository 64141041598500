import { QueryLazyOptions } from '@apollo/client';
import React from 'react';
import { useUser } from '../auth/useUser';
import { useCampfireLazyQuery } from '../network/useCampfireLazyQuery';
import { GET_PROGRAM_ACTIVITIES } from './program-activites.gql';
import {
  GetProgramActivities,
  GetProgramActivitiesVariables,
  GetProgramActivities_vm_programs as VmProgramType,
} from './__generated__/GetProgramActivities';
import { VOLUNTEER_VolunteerManagementPrivilegeRulesEnumType } from '../../__generated__/globalTypes';

export type ProgramType = Pick<VmProgramType, 'name' | 'programId'>;

export interface ProgramsContextInterface {
  programs?: ProgramType[];
  queryPrograms: (options?: QueryLazyOptions<{}> | undefined) => void;
  getAuthorizedPrograms: (isManagementView?: boolean) => VmProgramType[];
  selectedPrograms: string[];
  setSelectedPrograms: React.Dispatch<React.SetStateAction<string[]>>;
  refetchProgramsContext: () => void;
}

export const ProgramsContext = React.createContext<ProgramsContextInterface>({
  programs: [],
  queryPrograms: () => {},
  getAuthorizedPrograms: () => [],
  selectedPrograms: [],
  setSelectedPrograms: () => {},
  refetchProgramsContext: () => {},
});

export function ProgramsContextProvider({
  children,
  rule,
}: {
  children: React.ReactElement;
  rule?: VOLUNTEER_VolunteerManagementPrivilegeRulesEnumType;
}) {
  const {
    userIdentity,
    user: { userIdentityService },
  } = useUser();

  const [
    queryPrograms,
    { data: vmProgramActivities, setPollInterval: setQueryPollInterval, refetch: refetchProgramsContext },
  ] = useCampfireLazyQuery<GetProgramActivities, GetProgramActivitiesVariables>(GET_PROGRAM_ACTIVITIES);

  const [selectedPrograms, setSelectedPrograms] = React.useState<string[]>([]);

  const programs = React.useMemo(() => vmProgramActivities?.vm.programs || [], [vmProgramActivities]);

  const getAuthorizedPrograms = React.useCallback(
    (isManagementView?: boolean) => {
      const { isVmAdmin, isActivityLeader } = userIdentityService;

      if (isVmAdmin) return programs ?? [];

      const activityLeaderManagedProgramIds =
        userIdentity.vm?.volunteerIdentity?.programIdentities.map((programIdentity) => programIdentity.programId) ?? [];

      if (isActivityLeader) {
        return programs.filter((program) => activityLeaderManagedProgramIds.includes(program.programId)) ?? [];
      }

      const managedProgramIds =
        userIdentity.vm?.managerIdentity?.programManagerIdentities.map(
          (programManagerIdentity) => programManagerIdentity.programId
        ) ?? [];

      if (isManagementView) {
        return programs.filter((program) => managedProgramIds.includes(program.programId)) ?? [];
      }
      return programs;
    },
    [programs, userIdentity, userIdentityService]
  );

  React.useEffect(() => {
    queryPrograms({ variables: { rule } });
    setQueryPollInterval(600000);
  }, []);

  return (
    <ProgramsContext.Provider
      value={{
        programs,
        queryPrograms,
        getAuthorizedPrograms,
        selectedPrograms,
        setSelectedPrograms,
        refetchProgramsContext,
      }}
    >
      {children}
    </ProgramsContext.Provider>
  );
}
