import { gql } from '@apollo/client';
import { AcitivityDetailsFragments } from '../ActivitySidebar';

export const GET_ACTIVITY_DETAILS = gql`
  query GetActivityDetails($activityId: String!, $activityDate: Date, $startDate: Date, $endDate: Date) {
    orgName
    vm {
      activity(activityId: $activityId) {
        activityId
        isActive
        isEnrollable
        isRestrictedActivity
        name
        startTime
        endTime
        startDate
        isSuspended
        isHidden
        hasOpenRoster
        program {
          programId
          name
        }
        ...ActivityDescription
        publishedRoster(activityDate: $activityDate) {
          ...PublishedRoster
        }
        rosterTemplate(rosterDate: $activityDate) {
          ...ActivityRosterTemplate
        }
        activityLocation {
          ...ActivityLocation
        }
        activityRemoteLocation {
          ...ActivityRemoteLocation
        }
        activityEnrolments {
          ...ActivityTeamInformation
        }
        sessions {
          ...ActivitySession
        }
        activityAttachments {
          ...ActivitiesActivityAttachments
        }
        cancelledActivities {
          ...CancelledActivities
        }
        nextUntil(until: $endDate)
        occurrencesBetween(from: $startDate, until: $endDate)
        draftRoster(activityDate: $activityDate) {
          ...DraftRoster
        }
      }
    }
  }
  ${AcitivityDetailsFragments.cancelledActivities}
  ${AcitivityDetailsFragments.publishedRoster}
  ${AcitivityDetailsFragments.session}
  ${AcitivityDetailsFragments.team}
  ${AcitivityDetailsFragments.upcomingRoster}
  ${AcitivityDetailsFragments.location}
  ${AcitivityDetailsFragments.attachment}
  ${AcitivityDetailsFragments.description}
  ${AcitivityDetailsFragments.rosterTemplate}
  ${AcitivityDetailsFragments.draftRoster}
`;
