import { Box, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import { TitularTooltip } from '@campfire/titular-tooltip';

type Props = { color: string; message: string; tooltipDescription?: string };

const StatusLabelInner = memo(({ color, message }: Props) => {
  return (
    <Box
      display='inline-block'
      bgcolor={color}
      borderRadius={2}
      style={{
        paddingLeft: 8,
        paddingRight: 8,
      }}
    >
      <Typography
        variant='caption'
        display='inline'
        component='span'
        style={{ fontWeight: 'bold', color: 'white', cursor: 'default' }}
      >
        {message}
      </Typography>
    </Box>
  );
});

export const ActivityTimelineSidebarItemStatusLabel = memo((props: Props) => {
  const { tooltipDescription } = props;

  return tooltipDescription ? (
    <TitularTooltip description={tooltipDescription}>
      <Box display='inline-block' marginX={'4px'}>
        <StatusLabelInner {...props} />
      </Box>
    </TitularTooltip>
  ) : (
    <StatusLabelInner {...props} />
  );
});
