import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';

export const useResendInviteEmailFetch = () => {
  return useEndpointFetch<{ inviteeId: string }>('/vm/invitee/email/resend');
};
export const useRevokeInviteFetch = () => {
  return useEndpointFetch<{ inviteeId: string }>('/vm/invitee/revoke');
};
export const useApproveApplicationFetch = () => {
  return useEndpointFetch<{ applicationId: string; status: 'approved' | 'bypassed' }>('/vm/application/update-status');
};

export const useRemoveApplicantFetch = () => {
  return useEndpointFetch<{ applicationId: string; status: 'removed' }>('/vm/application/update-status');
};

export const useIncomingVolunteerActions = () => {
  const resendInviteEmail = useResendInviteEmailFetch();
  const revokeInvite = useRevokeInviteFetch();
  const approveApplication = useApproveApplicationFetch();
  const removeApplicant = useRemoveApplicantFetch();

  const { setSnackbar } = useSnackbar();

  const runResendInviteEmail = ({ inviteeId }: { inviteeId: string }) => {
    return resendInviteEmail
      .run({
        inviteeId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to resend invite email',
            variant: 'error',
          });
          throw res;
        }

        setSnackbar({
          open: true,
          message: 'Invite email resent',
          variant: 'success',
        });
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to resend invite email',
          variant: 'error',
        })
      );
  };

  const runRevokeInvite = ({ inviteeId }: { inviteeId: string }) => {
    return revokeInvite
      .run({
        inviteeId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to revoke invite',
            variant: 'error',
          });
          throw res;
        }

        setSnackbar({
          open: true,
          message: 'Invite revoked',
          variant: 'success',
        });
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to revoke invite',
          variant: 'error',
        })
      );
  };

  const runApproveApplication = ({ applicationId, status }: { applicationId: string; status: 'approved' | 'bypassed' }) => {
    return approveApplication
      .run({
        applicationId,
        status
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to approve application',
            variant: 'error',
          });
          throw res;
        }

        setSnackbar({
          open: true,
          message: 'Application approved',
          variant: 'success',
        });
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to approve application',
          variant: 'error',
        })
      );
  };

  const runRemoveApplicant = ({ applicationId, status }: { applicationId: string; status: 'removed' }) => {
    return removeApplicant
      .run({
        applicationId,
        status,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to remove applicant',
            variant: 'error',
          });
          throw res;
        }

        setSnackbar({
          open: true,
          message: 'Applicant removed',
          variant: 'success',
        });
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to remove applicant',
          variant: 'error',
        })
      );
  };

  return {
    runResendInviteEmail,
    runResendInviteEmailLoading: resendInviteEmail.isLoading,
    runRevokeInvite,
    runRevokeInviteLoading: revokeInvite.isLoading,
    runApproveApplication,
    runApproveApplicationLoading: approveApplication.isLoading,
    runRemoveApplicant,
    runRemoveApplicantLoading: removeApplicant.isLoading,
  };
};
