import { Typography, Box, List, ListItem, ListItemText, ListItemIcon, Checkbox } from '@material-ui/core';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from '@material-ui/icons';
import React from 'react';
import { useFilterStyles } from '../../../../../../../common/filter-fields/FilterClasses';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { IncomingVolsFilterProgram } from '../../../__generated__/IncomingVolsFilterProgram';
import { DEFAULT_PROGRAM_FILTER, filterHelperTexts, IncomingVolunteersSelectFieldProps } from '../filters';

interface ProgramFilterMobileProps extends IncomingVolunteersSelectFieldProps {
  programs?: IncomingVolsFilterProgram[];
}

const ProgramFilterMobile = ({ setSelectedFilters, selectedFilters, programs }: ProgramFilterMobileProps) => {
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const isProgramSelected = (value: string) => selectedFilters.selectedProgramFilter === value;
  const handleProgramChange = (value: string) =>
    setSelectedFilters({
      ...selectedFilters,
      selectedProgramFilter: value,
    });

  return (
    <Box py={2}>
      <Typography color='textSecondary' variant='body1' gutterBottom>
        {filterHelperTexts.programFilterHelperText}
      </Typography>

      <List disablePadding>
        <ListItem
          key={'all'}
          onClick={() => handleProgramChange(DEFAULT_PROGRAM_FILTER)}
          disableGutters
          className={classes.listItem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <Checkbox
              checked={isProgramSelected(DEFAULT_PROGRAM_FILTER)}
              className={classes.checkBox}
              checkedIcon={<CheckBoxIcon color='primary' />}
              size='small'
              icon={<CheckBoxOutlineBlank />}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant='body1'
              color='textSecondary'
              style={isProgramSelected(DEFAULT_PROGRAM_FILTER) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
            >
              {DEFAULT_PROGRAM_FILTER}
            </Typography>
          </ListItemText>
        </ListItem>

        {programs?.map((program) => (
          <ListItem
            key={program.programId}
            onClick={() => handleProgramChange(program.programId)}
            disableGutters
            className={classes.listItem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                checked={isProgramSelected(program.programId)}
                className={classes.checkBox}
                checkedIcon={<CheckBoxIcon color='primary' />}
                size='small'
                icon={<CheckBoxOutlineBlank />}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant='body1'
                color='textSecondary'
                style={isProgramSelected(program.programId) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
              >
                {program.name}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export { ProgramFilterMobile };
