import React from 'react';
import { TaskItemsDeserialize } from './utils';

export const TaskListItemContext = React.createContext({
  setFieldValue: (_unusedfield: string, _unusedvalue: any, _unusedshouldValidate?: boolean | undefined) => {},
  draggingId: '',
  setDraggingId: (_unusednewDraggingId: string) => {},
  taskItems: {
    allIds: [] as Array<string>,
    byId: {},
    byTrigger: {},
    byTriggerOption: {},
  } as TaskItemsDeserialize,
});

interface TaskListItemContextProviderProps {
  children: React.ReactElement;
  taskItems: TaskItemsDeserialize;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const TaskListItemContextProvider = ({
  children,
  taskItems,
  setFieldValue,
}: TaskListItemContextProviderProps) => {
  const [draggingId, setDraggingId] = React.useState('');

  return (
    <TaskListItemContext.Provider
      value={{
        draggingId,
        setDraggingId,
        taskItems,
        setFieldValue,
      }}
    >
      {children}
    </TaskListItemContext.Provider>
  );
};
