import React from 'react';
import { Theme, Box, BoxProps } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

interface Props extends BoxProps {
  isSelected?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '2rem',
      height: '2rem',
      borderRadius: '50%',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: ({ isSelected }: Props) =>
        isSelected ? theme.color.grey.neutralBrand300 : theme.color.grey.neutralBrand200,
      color: ({ isSelected }: Props) => (isSelected ? 'white' : theme.color.grey.neutralBrand300),
      [theme.breakpoints.down('sm')]: {
        width: '20px',
        height: '20px',
      },
    },
  })
);

export function DayItem(props: Props) {
  const classes = useStyles(props);
  const { isSelected: _unused, ...rest } = props;
  return <Box className={classes.container} {...rest} />;
}

export interface DayOptions {
  label: string;
  value: number;
}

export interface DaySelectProps extends BoxProps {
  days: DayOptions[];
  daysSelected: { [key: number]: boolean };
  onSelectDay: (dayValue: number) => void;
}

export interface DaySelectArrayTypeProps extends BoxProps {
  days: DayOptions[];
  daysSelected: number[];
  onSelectDay: (dayValue: number) => void;
}

export function DaySelect({ days, daysSelected, onSelectDay, ...cssProps }: DaySelectProps) {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' {...cssProps}>
      {days.map(({ label, value }) => (
        <DayItem key={value} onClick={() => onSelectDay(value)} isSelected={Boolean(daysSelected[value])}>
          {label}
        </DayItem>
      ))}
    </Box>
  );
}

export function DaySelectArrayType({ days, daysSelected, onSelectDay, ...cssProps }: DaySelectArrayTypeProps) {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' {...cssProps}>
      {days.map(({ label, value }) => (
        <DayItem key={value} onClick={() => onSelectDay(value)} isSelected={daysSelected.includes(value)}>
          {label}
        </DayItem>
      ))}
    </Box>
  );
}
