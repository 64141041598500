import { Field } from '@campfire/field';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { TabletButton } from '@campfire/tablet-button';
import classNames from 'classnames';
import { Grid, Switch, Typography, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { EditorState, convertToRaw } from 'draft-js';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { memo, useMemo, useState } from 'react';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';
import { useProgramManagementContext } from '../../ProgramManagementContext';
import { ProgramManagementSingleProgramBasicInfo } from '../../__generated__/ProgramManagementSingleProgramBasicInfo';
import { convertToEditorState } from '../../../../general/activities-v2/AllPrograms/AllProgramsHelpers';
import { ConfirmationDialog } from '../../../../../common/dialogs/ConfirmationDialog';
import { useProgramsContext } from '../../../../../global/programs-sell/useProgramsContext';

export const useStyles = makeStyles(() =>
  createStyles({
    switchText: {
      fontSize: '0.875rem',
      color: '#000000',
      opacity: 0.6,
    },
    switchHeading: {
      fontWeight: 700,
      fontSize: '0.75rem',
    },
    switchBody: {
      fontWeight: 400,
    },
  })
);

export const ProgramBasicInfoSectionForm = memo(
  ({ programBasicInfo }: { programBasicInfo: ProgramManagementSingleProgramBasicInfo | undefined }) => {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const { runSaveProgram, saveProgramIsLoading } = useProgramManagementContext();
    const { refetchProgramsContext } = useProgramsContext();
    const classes = useStyles();

    const initialValues = useMemo(
      () => ({
        programName: programBasicInfo?.name ?? '',
        programDescription: programBasicInfo?.description
          ? convertToEditorState(programBasicInfo.description)
          : EditorState.createEmpty(),
        isHidden: programBasicInfo?.isHidden ?? false,
        isRestrictedProgram: programBasicInfo?.isRestrictedProgram ?? false,
      }),
      [programBasicInfo]
    );

    return (
      <FormSectionV2
        title='Basic Info'
        description={
          <Typography variant='body2' display='block' color='textSecondary'>
            Give your Program a unique, memorable name. Add a description to help Volunteers and other Managers to
            understand the purpose of the Program.
          </Typography>
        }
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(vals) => {
            runSaveProgram(
              {
                name: vals.programName,
                description: JSON.stringify(convertToRaw(vals.programDescription.getCurrentContent())),
                programId: programBasicInfo?.programId,
                active: programBasicInfo?.dateSuspended === null,
                isHidden: vals.isHidden,
                isRestrictedProgram: vals.isRestrictedProgram,
              },
              refetchProgramsContext
            );
          }}
        >
          {({ handleReset, values, setFieldValue }) => {
            const fieldsUnchanged: boolean = useMemo(
              () =>
                values.programName === initialValues.programName &&
                isEqual(
                  convertToRaw(values.programDescription.getCurrentContent()),
                  convertToRaw(initialValues.programDescription.getCurrentContent())
                ) &&
                values.isHidden === initialValues.isHidden &&
                values.isRestrictedProgram === initialValues.isRestrictedProgram,
              [values.programName, values.programDescription, values.isHidden, values.isRestrictedProgram]
            );
            return (
              <Form>
                <Grid container direction='column' spacing={2}>
                  <Grid item xs={12}>
                    <Field required name='programName' label='Program name' variant='outlined' helper fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <CampfireRichEditor
                      placeholder='Description (Optional)'
                      editorState={values.programDescription}
                      setEditorState={(eState) => {
                        setFieldValue('programDescription', eState);
                      }}
                    />
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={12} sm={6}>
                      <Box display='flex' alignItems='flex-start'>
                        <Switch
                          checked={values.isRestrictedProgram}
                          onChange={() => {
                            if (values.isRestrictedProgram) {
                              setShowConfirmationDialog(true);
                              return;
                            }
                            setFieldValue('isRestrictedProgram', !values.isRestrictedProgram);
                          }}
                          color='primary'
                          name='pinned'
                          inputProps={{ 'aria-label': 'program hidden switch' }}
                        />
                        <Box ml={0.4}>
                          <Typography className={classNames(classes.switchText, classes.switchHeading)}>
                            VOLUNTEERS MUST APPLY
                          </Typography>
                          <Typography className={classNames(classes.switchText, classes.switchBody)}>
                            Volunteers will be unable to automatically join this program, and instead must apply and be
                            approved by a program manager or admin
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display='flex' alignItems='flex-start'>
                        <Switch
                          checked={values.isHidden}
                          onChange={() => setFieldValue('isHidden', !values.isHidden)}
                          color='primary'
                          name='pinned'
                          inputProps={{ 'aria-label': 'program restriction switch' }}
                        />
                        <Box ml={0.4}>
                          <Typography className={classNames(classes.switchText, classes.switchHeading)}>
                            HIDDEN PROGRAM
                          </Typography>
                          <Typography className={classNames(classes.switchText, classes.switchBody)}>
                            Volunteers who are not part of this program will be unable to see or join it
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1} xs={12} alignItems='center' justify='flex-end'>
                    <Grid item>
                      {programBasicInfo ? (
                        <TabletButton variant='text' onClick={handleReset} disabled={fieldsUnchanged}>
                          Discard Changes
                        </TabletButton>
                      ) : null}
                    </Grid>
                    <Grid item>
                      <TabletButton
                        size='medium'
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={saveProgramIsLoading || fieldsUnchanged}
                      >
                        Save Basic Info
                      </TabletButton>
                    </Grid>
                  </Grid>
                </Grid>

                {showConfirmationDialog ? (
                  <ConfirmationDialog
                    title='Allow volunteer to automatically join this program?'
                    body={
                      <>
                        <Typography variant='body2' gutterBottom>
                          Volunteers will be able to automatically join this program and current applications will be
                          approved.
                        </Typography>
                      </>
                    }
                    open
                    closeActionText='Cancel'
                    confirmActionText='Confirm'
                    handleCloseClick={() => setShowConfirmationDialog(false)}
                    handleConfirmClick={() => {
                      setFieldValue('isRestrictedProgram', !values.isRestrictedProgram);
                      setShowConfirmationDialog(false);
                    }}
                  />
                ) : null}
              </Form>
            );
          }}
        </Formik>
      </FormSectionV2>
    );
  }
);
