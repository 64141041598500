import { Field } from '@campfire/field';
import { Select } from '@campfire/select';
import { TabletButton } from '@campfire/tablet-button';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Form, Formik, useFormikContext } from 'formik';
import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { useAdminConsoleActions } from '../../admin-console-actions';
import { ScreeningQuestionsFormValues, validationSchema } from './admin-console-applicant-screening-model';

type Props = {
  open: boolean;
  onClose: () => void;
  refetchReasons?: () => void;
};

export const AddScreeningQuestionDialog = ({ open, onClose, refetchReasons }: Props) => {
  const formik = useFormikContext<ScreeningQuestionsFormValues>();
  const { screeningQuestions } = formik.values;

  const { runSaveScreeningQuestions, runSaveScreeningQuestionsLoading } = useAdminConsoleActions();

  const handleSuccess = () => {
    onClose();
    if (refetchReasons) refetchReasons();
  };

  const nextOrder = useMemo(() => {
    const order = screeningQuestions.reduce((previous, current) => {
      return previous > current.order ? previous : current.order;
    }, 0);

    return order;
  }, [screeningQuestions]);

  const possibleAnswers = ['yes', 'no', 'either'];

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid item container xs={12} direction='column'>
          <Typography display='inline' variant='h6'>
            New Screening Question
          </Typography>
        </Grid>
      </DialogTitle>

      <Formik
        initialValues={{ screeningQuestionId: undefined, label: '', correctAnswer: 'yes', order: nextOrder }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const { correctAnswer } = values;
          runSaveScreeningQuestions({
            screeningQuestions: [
              { ...values, correctAnswer: correctAnswer === 'either' ? '' : correctAnswer },
              ...screeningQuestions,
            ],
            handleSuccess,
          });
        }}
      >
        {() => (
          <Form>
            <DialogContent>
              <Grid container spacing={2} direction='row'>
                <Grid item xs={8}>
                  <Field required autoFocus name='label' label='Question Name' variant='outlined' fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    required
                    fullWidth
                    native
                    name='correctAnswer'
                    label='Correct Answer'
                    variant='outlined'
                    items={possibleAnswers}
                    renderFunction={(value) => capitalize(value)}
                    valueFunction={(item) => item}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <TabletButton variant='text' onClick={() => onClose()}>
                {'Cancel'}
              </TabletButton>
              <TabletButton variant='contained' color='primary' type='submit'>
                {runSaveScreeningQuestionsLoading ? <CircularProgress size={20} /> : 'Add Question'}
              </TabletButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
