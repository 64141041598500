import { Field } from '@campfire/field';
import { TabletButton } from '@campfire/tablet-button';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Form, Formik, useFormikContext } from 'formik';
import React from 'react';
import { useAdminConsoleActions } from '../../admin-console-actions';
import { ActivityReasonType, ReasonTypeFormValues, validationSchema } from './admin-console-operations-model';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  type: ActivityReasonType;
  refetchReasons?: () => void;
};

export const AddReasonDialog = ({ open, onClose, title, type, refetchReasons }: Props) => {
  const formik = useFormikContext<ReasonTypeFormValues>();
  const { reasons } = formik.values;

  const {
    runSaveActivityCancellationReasons,
    runSaveActivityCancellationReasonsLoading,
    runSaveActivityClosureReasons,
    runSaveActivityClosureReasonsLoading,
  } = useAdminConsoleActions();

  const handleSuccess = () => {
    onClose();
    if (refetchReasons) refetchReasons();
  };

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid item container xs={12} direction='column' style={{}}>
          <Typography display='inline' variant='h6'>
            {title}
          </Typography>
        </Grid>
      </DialogTitle>
      <Formik
        initialValues={{ reason: '' }}
        validationSchema={validationSchema}
        onSubmit={(vals) => {
          const newReason = { label: vals.reason };
          if (type === ActivityReasonType.cancellation) {
            runSaveActivityCancellationReasons({ reasons: [...reasons, newReason], handleSuccess });
          }
          if (type === ActivityReasonType.closure) {
            runSaveActivityClosureReasons({ reasons: [...reasons, newReason], handleSuccess });
          }
        }}
      >
        {() => (
          <Form>
            <DialogContent>
              <Field required autoFocus name='reason' label='Reason' variant='outlined' fullWidth />
            </DialogContent>
            <DialogActions>
              <TabletButton variant='text' onClick={() => onClose()}>
                {'Cancel'}
              </TabletButton>
              <TabletButton variant='contained' color='primary' type='submit'>
                {runSaveActivityCancellationReasonsLoading || runSaveActivityClosureReasonsLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  'Done'
                )}
              </TabletButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
