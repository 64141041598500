import { gql } from '@apollo/client';

export const GET_PROFILE_HOVER_CARD = gql`
  query GetProfileHoverCard($profileId: String!) {
    vm {
      volunteer(profileId: $profileId) {
        activityRoles {
          name
        }
      }
      profile(profileId: $profileId) {
        contactNumber
        email
        avatarUrl
        statusEmoji
        statusText
        aboutMe
        dateCreated
        
      }
    }
  }
`;
