/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum FileTypeEnumType {
  external = "external",
  image = "image",
  pdf = "pdf",
}

export enum RecurrenceEnum {
  FR = "FR",
  MO = "MO",
  SA = "SA",
  SU = "SU",
  TH = "TH",
  TU = "TU",
  WE = "WE",
}

export enum VOLUNTEER_ActivityEnrolmentPositionEnumType {
  al = "al",
  gv = "gv",
}

export enum VOLUNTEER_ApplicationStatusEnumType {
  approved = "approved",
  bypassed = "bypassed",
  pending = "pending",
  rejected = "rejected",
  removed = "removed",
}

export enum VOLUNTEER_CakeTypeEnumType {
  extra = "extra",
  required = "required",
}

export enum VOLUNTEER_EmojiEnumType {
  clap = "clap",
  cry = "cry",
  grinning = "grinning",
  heart = "heart",
  heart_eyes = "heart_eyes",
  open_mouth = "open_mouth",
  sunglasses = "sunglasses",
}

export enum VOLUNTEER_IncidentStatusEnumType {
  archived = "archived",
  closed = "closed",
  new = "new",
  working = "working",
}

export enum VOLUNTEER_PermissionLevelEnumType {
  admin = "admin",
  al = "al",
  gv = "gv",
  pm = "pm",
}

export enum VOLUNTEER_RosterViewEnumType {
  bulk = "bulk",
  individual = "individual",
}

export enum VOLUNTEER_VolunteerManagementPrivilegeRulesEnumType {
  AddVolunteerToTeam = "AddVolunteerToTeam",
  ArchiveIncident = "ArchiveIncident",
  CancelActivity = "CancelActivity",
  CancelSessionReport = "CancelSessionReport",
  CloseActivity = "CloseActivity",
  CloseIncident = "CloseIncident",
  CreateProgramManager = "CreateProgramManager",
  CreateRoster = "CreateRoster",
  CreateSessionReport = "CreateSessionReport",
  DeleteIncident = "DeleteIncident",
  EditSessionReport = "EditSessionReport",
  FlagVolunteer = "FlagVolunteer",
  InviteVolunteer = "InviteVolunteer",
  ManageActivities = "ManageActivities",
  ManageIncidentConfig = "ManageIncidentConfig",
  ManagePrograms = "ManagePrograms",
  ManageReportTypes = "ManageReportTypes",
  ManageScreeningQuestions = "ManageScreeningQuestions",
  ManageTasks = "ManageTasks",
  ReadActivityReport = "ReadActivityReport",
  ReadAllActivities = "ReadAllActivities",
  ReadAllVolunteers = "ReadAllVolunteers",
  ReadIncident = "ReadIncident",
  ReadIncomingVolunteers = "ReadIncomingVolunteers",
  ReadRoster = "ReadRoster",
  ReadVolunteerAddress = "ReadVolunteerAddress",
  ReadVolunteerComments = "ReadVolunteerComments",
  ReadVolunteerContact = "ReadVolunteerContact",
  ReadVolunteerDob = "ReadVolunteerDob",
  ReadVolunteerEmergencyContact = "ReadVolunteerEmergencyContact",
  ReadVolunteerFlagging = "ReadVolunteerFlagging",
  ReadVolunteerGender = "ReadVolunteerGender",
  ReadVolunteerHealth = "ReadVolunteerHealth",
  ReadVolunteerOnboarding = "ReadVolunteerOnboarding",
  ReadVolunteerUniformSize = "ReadVolunteerUniformSize",
  RemoveVolunteerFromTeam = "RemoveVolunteerFromTeam",
  RetireVolunteer = "RetireVolunteer",
  RevokeInvite = "RevokeInvite",
  SuspendActivity = "SuspendActivity",
  UnretireVolunteer = "UnretireVolunteer",
  UpdateConfigItems = "UpdateConfigItems",
  UpdateIncomingVolunteers = "UpdateIncomingVolunteers",
  UpdateProgramManager = "UpdateProgramManager",
  UpdateSupportResource = "UpdateSupportResource",
  UpdateVolunteerActivityRoles = "UpdateVolunteerActivityRoles",
  UpdateVolunteerComments = "UpdateVolunteerComments",
  UseZapier = "UseZapier",
  WorkIncident = "WorkIncident",
}

export interface PaginationInputType {
  offset?: number | null;
  limit?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
