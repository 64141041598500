import React from 'react';
import { FieldArray, useFormikContext } from 'formik';

import { FormikTaskBuilderValues } from '../../admin-console-volunteer-profile-model';
import { ContentTaskItemDialog } from '../dialogs/ContentTaskItemDialog';
import { FieldTaskItemDialog } from '../dialogs/field-task-item-dialog/FieldTaskItemDialog';
import { HeadingTaskItemDialog } from '../dialogs/HeadingTaskItemDialog';
import { MediaTaskItemDialog } from '../dialogs/MediaTaskItemDialog';
import { useFormBuilderDialogContext } from '../FormBuilderDialogContext';
import { TaskListItemContextProvider } from '../TaskListItemsContext';
import { TaskItemList } from './TaskItemList';

export function TaskListItemsForm() {
  const { values, setFieldValue } = useFormikContext<FormikTaskBuilderValues>();

  const {
    openHeadingDialogState,
    closeHeadingDialog,
    openContentDialogState,
    closeContentDialog,
    openFieldDialogState,
    closeFieldDialog,
    openMediaDialogState,
    closeMediaDialog,
  } = useFormBuilderDialogContext();

  return (
    <FieldArray name='taskItems'>
      {(arrayHelpers) => (
        <>
          <TaskListItemContextProvider taskItems={values.taskItems} setFieldValue={setFieldValue}>
            <TaskItemList />
          </TaskListItemContextProvider>
          <HeadingTaskItemDialog
            open={openHeadingDialogState.open}
            close={closeHeadingDialog}
            arrayHelpers={arrayHelpers}
            data={openHeadingDialogState.data}
          />
          <ContentTaskItemDialog
            open={openContentDialogState.open}
            close={closeContentDialog}
            arrayHelpers={arrayHelpers}
            data={openContentDialogState.data}
          />
          <FieldTaskItemDialog
            open={openFieldDialogState.open}
            close={closeFieldDialog}
            arrayHelpers={arrayHelpers}
            data={openFieldDialogState.data}
          />
          <MediaTaskItemDialog
            open={openMediaDialogState.open}
            close={closeMediaDialog}
            arrayHelpers={arrayHelpers}
            data={openMediaDialogState.data}
          />
        </>
      )}
    </FieldArray>
  );
}
