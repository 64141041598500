import { Grid, TextField } from '@material-ui/core';
import React from 'react';

export interface AddressManualFieldsType {
  lineOne: string;
  lineTwo: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
}

interface Props {
  value: AddressManualFieldsType;
  onChange: (value: AddressManualFieldsType) => void;
  required?: boolean;
}

const AddressManualFields = (props: Props) => {
  const { value, onChange, required } = props;
  return (
    <Grid container spacing={2} style={{ paddingTop: 16, paddingLeft: 8 }}>
      <Grid item xs={12}>
        <TextField
          onChange={(event) => onChange({ ...value, lineOne: event.target.value })}
          value={value.lineOne}
          variant='outlined'
          label='Address Line 1'
          fullWidth
          required={required}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          onChange={(event) => onChange({ ...value, lineTwo: event.target.value })}
          value={value.lineTwo}
          variant='outlined'
          label='Address Line 2'
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          onChange={(event) => onChange({ ...value, suburb: event.target.value })}
          value={value.suburb}
          variant='outlined'
          label='Suburb'
          fullWidth
          required={required}
        />
      </Grid>

      <Grid item sm={6}>
        <TextField
          onChange={(event) => onChange({ ...value, state: event.target.value })}
          value={value.state}
          variant='outlined'
          label='State'
          fullWidth
          required={required}
        />
      </Grid>

      <Grid item sm={6}>
        <TextField
          onChange={(event) => onChange({ ...value, postcode: event.target.value })}
          value={value.postcode}
          variant='outlined'
          label='Postcode'
          fullWidth
          required={required}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          onChange={(event) => onChange({ ...value, country: event.target.value })}
          value={value.country}
          variant='outlined'
          label='Country'
          fullWidth
          required={required}
        />
      </Grid>
    </Grid>
  );
};

export { AddressManualFields };
