import React from 'react';
import { Task_taskItems as TaskItemType } from '../../../__generated__/Task';
import { ContentTaskItem } from './ContentTaskItem';
import { FieldTaskItem } from './FieldTaskItem';
import { HeadingTaskItem } from './HeadingTaskItem';
import { MediaTaskItemMedia } from './MediaTaskItem';

interface TaskItemProps {
  data: TaskItemType;
}

export function TaskItem({ data }: TaskItemProps) {
  switch (data.__typename) {
    case 'VOLUNTEER_TaskItemHeadingType':
      return <HeadingTaskItem data={data} />;

    case 'VOLUNTEER_TaskItemMediaType':
      return <MediaTaskItemMedia data={data} />;

    case 'VOLUNTEER_TaskItemContentType':
      return <ContentTaskItem data={data} />;

    case 'VOLUNTEER_TaskItemFieldType':
      return <FieldTaskItem data={data} />;

    default:
      return null;
  }
}
