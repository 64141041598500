import { gql } from '@apollo/client'

const DASHBOARD_INCOMING_APPLICANT_FRAGMENT = gql`
  fragment DashboardIncomingApplication on VOLUNTEER_ApplicationType {
    __typename
    applicationId
    status
    name
    dateSubmitted
  }
`;

const DASHBOARD_INCOMING_VOLUNTEER_PROFILE_FRAGMENT = gql`
  fragment DashboardIncomingVolunteerProfile on VOLUNTEER_ProfileType {
    __typename
    profileId
    preferredName
    lastName
    avatarUrl
    isCompleted
    isProfileTaskApproved
    percentageComplete
    dateLastUpdated
    completedTasks {
      status
      task {
        taskId
        cake {
          cakeType
        }
      }
    }
    application {
      relevantTasks {
        taskId
        cake {
          cakeType
        }
      }
    }
  }
`;

export const MANAGEMENT_DASHBOARD_DATA = gql`
  query ManagementDashboardData($volunteerId: String!, $from: Date, $until: Date) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        activityEnrolments {
            activity {
                activityId
                name
                program {
                    name
                }
            }
            dateRemoved
            dateEnrolled
        }
        rosterings {
          publishedRosteringId
          publishedRoster {
            activityDate
            activity {
              activityId
              name
              startTime
              endTime
              program {
                name
                dateDeleted
                dateSuspended
              }
              sessions {
                sessionId
              }
              activityReports {
                activityDate
                activity {
                    activityId
                }
                sessionReports {
                  sessionReportId
                }
                cancelledSessions {
                  sessionId
                }
              }
              cancelledActivities {
               activityDate
              }
            }
          }
        }
      }
      activities {
        activityId
        name
        startTime
        endTime
        lastX(x: 12)
        sessions {
          sessionId
        }
        program {
          programId
          name
          dateDeleted
          dateSuspended
        }
        cancelledActivities {
            activityDate
        }
        occurrencesBetween(from: $from, until: $until)
        activityReports {
          activityReportId
          activityDate
          sessionReports {
            sessionReportId
            submissionDate
          }
          cancelledSessions {
            sessionId
          }
        }
      }
    }
  }
`;


export const MANAGEMENT_DASHBOARD_INCOMING_VOLUNTEERS_DATA = gql`
  query ManagementDashboardIncomingVolunteersData {
    vm {
      incomingVolunteers {
        incomingVolunteers {
          source
          incomingVolunteerId
          email
          name
          lastUpdated
          isInvited
          application {
            ...DashboardIncomingApplication
          }
          profile {
            ...DashboardIncomingVolunteerProfile
          }
        }
        pagination {
          offset
          limit
          total
        }
      }
    }
  }
  ${DASHBOARD_INCOMING_APPLICANT_FRAGMENT}
  ${DASHBOARD_INCOMING_VOLUNTEER_PROFILE_FRAGMENT}
`;
