import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { StatusChip } from '../../../../common/chips/StatusChip';

export const InfoBlock = ({
  value,
  label,
  valueAsNode,
  labelAsChip,
  bgcolor,
}: {
  value: ReactNode;
  valueAsNode?: boolean;
  label: ReactNode;
  labelAsChip?: boolean;
  bgcolor?: string;
}) => (
  <Box py={1}>
    {labelAsChip && bgcolor ? (
      <StatusChip status={label} bgcolor={bgcolor} />
    ) : (
      <Typography variant='caption' display='block' color='inherit'>
        {label}
      </Typography>
    )}
    {valueAsNode ? (
      value
    ) : (
      <Typography variant='body1' display='block'>
        {value}
      </Typography>
    )}
  </Box>
);
