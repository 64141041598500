import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, Box, Chip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { capitalize } from 'lodash';
import React, { useState, useEffect } from 'react';
import { VolunteerCommonActivityRole } from '../../__generated__/VolunteerCommonActivityRole';
import { useVolunteerCommonProfileContext } from '../../VolunteerCommonProfileContext';
import { useVolunteerProfileActions } from '../../volunteer-profile-actions';
import { InfoBlock } from '../../../volunteers/incoming-volunteer/components/IncomingVolunteerInfoBlock';
import { RoleIcon } from '../../../../../common/icons/RoleIconList';

const useStyles = makeStyles(() =>
  createStyles({
    roleSelectInput: {
      borderColor: 'rgba(0,0,0,0.2)',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 4,
    },
    chips: {
      paddingLeft: 6,
    },
  })
);

export const VolunteerRoles = ({
  volunteerId,
  volunteerActivityRoles,
  editable,
}: {
  volunteerId: string;
  volunteerActivityRoles: VolunteerCommonActivityRole[];
  editable?: boolean;
}) => {
  const [rolesSelectOpen, setRolesSelectOpen] = useState(false);
  const [volunteerActivityRoleIds, setVolunteerActivityRoleIds] = useState<string[]>([]);
  const classes = useStyles();
  const { availableRoles, updateParentVolunteerActions } = useVolunteerCommonProfileContext();
  const handleSuccess = (roleIds: string[]) => {
    setVolunteerActivityRoleIds(roleIds);
    if (updateParentVolunteerActions) {
      const { updateVolunteerWithSavedActivityRoles } = updateParentVolunteerActions;
      if (updateVolunteerWithSavedActivityRoles) updateVolunteerWithSavedActivityRoles(roleIds);
    }
  };
  const { runSaveRoles, runSaveRolesLoading } = useVolunteerProfileActions();
  const showRolesSelectField = !!availableRoles.length && editable;

  useEffect(() => {
    if (!volunteerActivityRoles.length) return;
    const activityRoles = volunteerActivityRoles.map((role) => {
      return role.activityRoleId;
    });
    setVolunteerActivityRoleIds(activityRoles);
  }, [volunteerActivityRoles]);

  return showRolesSelectField ? (
    <>
      <CircularProgressOverlay isLoading={runSaveRolesLoading} />
      <Box py={1}>
        <FormControl fullWidth>
          <InputLabel
            htmlFor='select-volunteer-roles'
            style={{ paddingLeft: 8, paddingTop: 4 }}
          >{`Volunteer Roles`}</InputLabel>
          <Select
            fullWidth
            open={rolesSelectOpen}
            onOpen={() => {
              setRolesSelectOpen(true);
            }}
            onClose={() => {
              setRolesSelectOpen(false);
            }}
            multiple
            variant='filled'
            value={volunteerActivityRoleIds}
            onChange={(event) => {
              const selectedRoles = (event.target.value as string[]).filter((x) => !!x);
              runSaveRoles({
                volunteerId: volunteerId,
                activityRoleIds: selectedRoles,
                handleSuccess,
              });
            }}
            renderValue={(roleIds) => (
              <Grid container spacing={1} className={classes.chips}>
                {(roleIds as Array<string>).map((roleId) => {
                  const selectedRole = availableRoles.find((x) => x.activityRoleId === roleId);
                  return selectedRole ? (
                    <Grid item key={selectedRole.activityRoleId}>
                      {selectedRole.icon ? (
                        <Chip
                          icon={
                            <RoleIcon
                              iconName={selectedRole.icon}
                              style={{ width: '18px', height: '18px', marginRight: '-8px', marginLeft: '8px' }}
                            />
                          }
                          color='primary'
                          label={capitalize(selectedRole.name)}
                        />
                      ) : (
                        <Chip color='primary' label={capitalize(selectedRole.name)} />
                      )}
                    </Grid>
                  ) : null;
                })}
              </Grid>
            )}
          >
            {availableRoles
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((availableRole) => {
                return (
                  <MenuItem key={availableRole.activityRoleId} value={availableRole.activityRoleId}>
                    <Checkbox
                      color='primary'
                      checked={!!volunteerActivityRoleIds.find((roleId) => roleId === availableRole.activityRoleId)}
                    />
                    {availableRole.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>
    </>
  ) : volunteerActivityRoleIds.length ? (
    <InfoBlock
      label='Roles'
      value={volunteerActivityRoles.map((activityRole) => capitalize(activityRole.name)).join(', ')}
    />
  ) : null;
};
