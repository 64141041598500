import React from 'react';
import { Box, Popover, Typography } from '@material-ui/core';
import { Help as HelpIcon, Clear as ClearIcon } from '@material-ui/icons';
import QueryString from 'qs';
import { rangeParsed } from './NumberWidget';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { NumberWidgetProps, getEndDateFromRange, getStartDateFromRange, useWidgetStyles } from '../shared';
import { CustomMultiplationNumberConfiguration } from '../../dashboard-widget-setting/__generated__/CustomMultiplationNumberConfiguration';
import { useApiUrl } from '../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../global/network/useCampfireFetch';

export const CustomMultiplationHelpInfo = ({
  metricName,
  range,
  multiplier,
}: {
  metricName: string;
  range: string;
  multiplier: number;
}) => {
  const classes = useWidgetStyles();
  const { theme } = useCampfireTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <Box mr={2} mt='4px'>
      <HelpIcon
        onClick={(e: any) => setAnchorEl(e.target)}
        style={{ fontSize: '14px', color: theme.color.grey.neutral300, marginRight: '8px', cursor: 'pointer' }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box width='400px' height='240px' p={2}>
          <Box>
            <Typography className={classes.title}>Calculation: {metricName}</Typography>
            <Typography className={classes.subtitle}>{'Breakdown & Variables'}</Typography>
          </Box>
          <Box display='flex' mt={2}>
            <Box m={2}>
              <Box className={classes.variablesBox}>{'$'}</Box>
              <Box mt={1} display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
                <Typography
                  style={{
                    fontSize: '12px',
                    fontWeight: 700,
                    color: theme.color.grey.neutral400,
                  }}
                >
                  Dollar
                </Typography>
                <Typography style={{ fontSize: '12px', color: theme.color.grey.neutral400 }}>Value Type</Typography>
              </Box>
            </Box>
            <Box m={2}>
              <Box className={classes.variablesBox}>365</Box>
              <Box mt={1} display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
                <Typography
                  style={{
                    fontSize: '12px',
                    fontWeight: 700,
                    color: theme.color.grey.neutral400,
                  }}
                >
                  Washes
                </Typography>
                <Typography style={{ fontSize: '12px', color: theme.color.grey.neutral400 }}>Report Field</Typography>
                <Typography
                  style={{ fontSize: '12px', color: theme.color.grey.neutral400, textTransform: 'capitalize' }}
                >
                  {rangeParsed(range)}
                </Typography>
              </Box>
            </Box>
            <Box>
              <ClearIcon style={{ color: theme.color.grey.neutral500, marginTop: '30px' }} />
            </Box>
            <Box m={2}>
              <Box className={classes.variablesBox}>{multiplier}</Box>
              <Box mt={1} display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
                <Typography
                  style={{
                    fontSize: '12px',
                    fontWeight: 700,
                    color: theme.color.grey.neutral400,
                  }}
                >
                  Custom Value
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

type CustomMultiplierNumberWidgetProps = NumberWidgetProps & {
  configuration: CustomMultiplationNumberConfiguration;
};

export const CustomMultiplationNumberWidget = (props: CustomMultiplierNumberWidgetProps) => {
  const { tag, configuration, name, filters } = props;
  const classes = useWidgetStyles();
  const [value, setValue] = React.useState(0);
  const { range, multiplier } = configuration;
  const apiUrl = useApiUrl();
  const campfireQuery = useCampfireFetch<{ count: number }>({ defer: true });
  const startDate = React.useMemo(() => getStartDateFromRange(range), [range]);
  const endDate = React.useMemo(() => getEndDateFromRange(range), [range]);

  React.useEffect(() => {
    campfireQuery
      .run({
        url: `${apiUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/total`,
        params: {
          startDate: startDate,
          endDate: endDate,
          filters: JSON.parse(filters),
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, { arrayFormat: 'brackets', encode: false });
        },
      })
      .then((response) => response?.data?.data && setValue(response.data.data.count * multiplier));
  }, [startDate, endDate, filters]);

  return <Box className={classes.valueText}>{value}</Box>;
};
