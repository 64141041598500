import { Box, Checkbox, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { ArrowDropDown, Check } from '@material-ui/icons';
import React, { useState } from 'react';
import { BorderSelectInput, useFilterStyles } from '../../../../../../../common/filter-fields/FilterClasses';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { IncomingVolsFilterProgram } from '../../../__generated__/IncomingVolsFilterProgram';
import { DEFAULT_PROGRAM_FILTER, IncomingVolunteersSelectFieldProps } from '../filters';

interface ProgramFilterSelectFieldProps extends IncomingVolunteersSelectFieldProps {
  programs?: IncomingVolsFilterProgram[];
}

const ProgramFilterSelectField = ({ setSelectedFilters, selectedFilters, programs }: ProgramFilterSelectFieldProps) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const selectedProgram = programs?.find((program) => program.programId === selectedFilters.selectedProgramFilter);
  const isProgramSelected = (value: string) => selectedFilters.selectedProgramFilter === value;
  const handleProgramChange = (event: EventTarget & any) =>
    setSelectedFilters({
      ...selectedFilters,
      selectedProgramFilter: event.target.value,
    });

  return (
    <Grid item className={classes.subheaderGridItem}>
      <Select
        open={popoverOpen}
        input={
          <BorderSelectInput
            status={selectedProgram ? 'active' : undefined}
            onClick={() => setPopoverOpen(!popoverOpen)}
            style={{ cursor: 'pointer' }}
          />
        }
        IconComponent={() => (
          <Box display='flex' alignItems='center' onClick={() => setPopoverOpen(true)} style={{ cursor: 'pointer' }}>
            <ArrowDropDown style={{ fill: selectedProgram ? theme.palette.primary.main : 'rgba(0,0,0, 0.54)' }} />
          </Box>
        )}
        value={selectedProgram ? selectedProgram.programId : DEFAULT_PROGRAM_FILTER}
        renderValue={() => selectedProgram?.name ?? DEFAULT_PROGRAM_FILTER}
        onChange={handleProgramChange}
      >
        <MenuItem value={DEFAULT_PROGRAM_FILTER} className={classes.menuItem}>
          <Grid container alignItems='center' justify='space-between'>
            <Grid item>
              <Typography
                variant='body2'
                style={!selectedProgram?.programId ? { fontWeight: 600 } : { fontWeight: 'normal' }}
              >
                {DEFAULT_PROGRAM_FILTER}
              </Typography>
            </Grid>
            <Grid item>
              <Checkbox
                checked={!selectedProgram?.programId}
                className={classes.checkBoxSelectField}
                checkedIcon={<Check color='primary' width={12} />}
                icon={<span className={classes.icon} />}
                disableRipple
              />
            </Grid>
          </Grid>
        </MenuItem>

        {programs?.map((program) => (
          <MenuItem key={program.programId} value={program.programId} className={classes.menuItem}>
            <Box
              display='flex'
              flex='1 0 auto'
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
            >
              <Typography
                variant='body2'
                style={isProgramSelected(program.programId) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
              >
                {program.name}
              </Typography>

              <Checkbox
                edge='end'
                checked={isProgramSelected(program.programId)}
                className={classes.checkBoxSelectField}
                checkedIcon={<Check color='primary' width={12} />}
                icon={<span className={classes.icon} />}
                disableRipple
              />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export { ProgramFilterSelectField };
