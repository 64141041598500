import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useIncidentDetailHeaderActions } from '../incident-detail-header-actions';

interface ArchiveIncidentDialogProps {
  archiveIncidentDialogOpen: boolean;
  handleClose: () => void;
  incidentId: string;
  getIncidentsRefetch: () => void;
}

export const ArchiveIncidentDialog = (props: ArchiveIncidentDialogProps) => {
  const [query, setQuery] = useQueryParams({
    incidentId: StringParam,
  });
  const { archiveIncidentDialogOpen, handleClose, incidentId, getIncidentsRefetch } = props;
  const { runMoveIncidentToArchive, runMoveIncidentToArchiveIsLoading } = useIncidentDetailHeaderActions();
  const { theme, isMobile } = useCampfireTheme();

  return (
    <Dialog
      open={archiveIncidentDialogOpen}
      onClose={handleClose}
      aria-labelledby='archive-incident-dialog-title'
      aria-describedby='archive-incident-dialog-description'
    >
      <DialogTitle id='archive-incident-dialog-title'>Delete Incident Report?</DialogTitle>
      <DialogContent>
        <DialogContentText id='archive-incident-dialog-description'>
          Incident report will be permanently archived. This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={runMoveIncidentToArchiveIsLoading}
          onClick={() => {
            runMoveIncidentToArchive({
              incidentId,
              handleSuccess: () => {
                handleClose();
                if (getIncidentsRefetch) getIncidentsRefetch();
                if (isMobile) setQuery({ ...query, incidentId: undefined });
              },
            });
          }}
          style={{
            color: theme.palette.error.main,
          }}
          autoFocus
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};
