import React from 'react';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface SessionReportOptionProps {
  onOmit?: () => void;
  onUnOmit?: () => void;
}

export function SessionReportOption({ onOmit, onUnOmit }: SessionReportOptionProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const { theme } = useCampfireTheme();

  return (
    <Box>
      <TabletButton
        // data-track='actCnl-overview-status-dropdown'
        size='small'
        variant='outlined'
        color='primary'
        aria-controls='task-status-menu'
        aria-haspopup='true'
        onClick={handleOpen}
        endIcon={<ExpandMore />}
        style={{
          border: '1px solid #9e9e9e',
          boxSizing: 'border-box',
          borderRadius: '4px',
          padding: '2px 7px',
          color: theme.color.grey.neutral300,
          marginRight: '8px',
          marginTop: '5px',
        }}
      >
        {'Options'}
      </TabletButton>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={onClose}
        style={{
          zIndex: 1500,
        }}
      >
        {onOmit ? (
          <MenuItem onClick={onOmit}>Omit Report</MenuItem>
        ) : (
          <MenuItem onClick={onUnOmit}>Un-Omit Report</MenuItem>
        )}
      </Menu>
    </Box>
  );
}
