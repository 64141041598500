import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Pane } from '@campfire/pane';
import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { AlertCard, getAlertCardColors } from '../../common/cards/alert-card/AlertCard';
import { CompletedTaskViewer } from '../../common/form/task-form/CompletedTaskViewer';
import { getVolunteerProfileHardcodedFieldValues } from '../../common/form/task-form/model/get-volunteer-profile-hardcoded-field-values';
import { GET_ALL_COMPLETED_TASKS } from '../../common/form/task-form/model/task-items.gql';
import {
  GetAllCompletedTasks,
  GetAllCompletedTasksVariables,
} from '../../common/form/task-form/model/__generated__/GetAllCompletedTasks';
import { TaskForm } from '../../common/form/task-form/TaskForm';
import { deserializeTaskItems } from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';
import { useUser } from '../auth/useUser';
import { useSnackbar } from '../config/useSnackbar';
import { useCampfireQuery } from '../network/useCampfireQuery';
import { useEndpointFetch } from '../network/useEndpointFetch';
import { SUYP_GET_INDIVIDUAL_TASK } from './model/suyp-individual-task-query.gql';
import { getTaskFieldValues } from './model/suyp-task-submission-handler';
import { SUYPGetIndividualTask, SUYPGetIndividualTaskVariables } from './model/__generated__/SUYPGetIndividualTask';
import { SUYPTaskScaffold } from './SUYPTaskScaffold';

export type CompletedTaskSubmissionData = {
  completedTaskId?: string;
  revoked?: boolean;
  taskId: string;
  taskFieldValues?: any[];
};

export type BypassTaskSubmissionData = {
  profileId: string;
  taskId: string;
  status: string;
};

const SUYPTask = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const { user } = useUser();
  const { setSnackbar } = useSnackbar();
  const history = useHistory();

  const { data: individualTaskData, loading: individualTaskIsLoading } = useCampfireQuery<
    SUYPGetIndividualTask,
    SUYPGetIndividualTaskVariables
  >(SUYP_GET_INDIVIDUAL_TASK, { options: { variables: { taskId } } });

  const { data: completedTaskData, loading: completedTaskIsLoading, refetch: refetchCompletedTasks } = useCampfireQuery<
    GetAllCompletedTasks,
    GetAllCompletedTasksVariables
  >(GET_ALL_COMPLETED_TASKS, {
    options: {
      variables: {
        userId: user.userId,
      },
    },
  });

  const saveCompletedTask = useEndpointFetch<CompletedTaskSubmissionData>('/vm/volunteer/profile/completed-task/save');

  const revokeCompletedTask = useEndpointFetch<{ completedTaskId: string; revoked: boolean }>(
    '/vm/volunteer/profile/completed-task/revoke'
  );

  const submitTaskForm = (data: CompletedTaskSubmissionData) => {
    saveCompletedTask.run(data).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to save task',
          variant: 'error',
        });
        return;
      }

      setSnackbar({
        open: true,
        message: 'Task saved',
        variant: 'success',
      });
      if (refetchCompletedTasks) refetchCompletedTasks();
      history.push('/');
    });
  };

  const taskHeaderDetails = useMemo(() => {
    if (!individualTaskData?.vm.task) return undefined;
    return {
      title: individualTaskData.vm.task.title,
      description: individualTaskData.vm.task.description,
      cake: individualTaskData.vm.task.cake.title,
    };
  }, [individualTaskData]);

  const taskItems = useMemo(() => {
    if (!individualTaskData?.vm.task) return [];
    return individualTaskData.vm.task.taskItems.sort((a, b) => (a.order > b.order ? 1 : -1));
  }, [individualTaskData]);

  const selectedCompletedTask = useMemo(
    () => completedTaskData?.vm.profile?.completedTasks.find((completedTask) => completedTask.task.taskId === taskId),
    [completedTaskData]
  );

  const taskItemValues = useMemo(() => {
    if (!selectedCompletedTask) return [];
    return selectedCompletedTask.taskFieldValues.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.field.taskFieldId]: curr,
      }),
      {}
    );
  }, [selectedCompletedTask]);

  const volunteerProfileHardCodedFieldValues = useMemo(
    () =>
      completedTaskData?.vm.profile ? getVolunteerProfileHardcodedFieldValues(completedTaskData.vm.profile) : undefined,
    [completedTaskData]
  );

  const isTaskZero = selectedCompletedTask?.task.order === -1;
  const parsedTaskItems = deserializeTaskItems(taskItems);
  const { lightTextColor } = getAlertCardColors('info');

  return (
    <Pane style={{ width: '100%', height: '100%', backgroundColor: '#f1f1f1' }}>
      <LinearProgressOverlay
        isLoading={individualTaskIsLoading || completedTaskIsLoading || saveCompletedTask.isLoading}
      />
      {taskHeaderDetails && selectedCompletedTask ? (
        <SUYPTaskScaffold allowBack taskHeaderDetails={taskHeaderDetails}>
          <Box margin='auto' paddingBottom={'2em'}>
            {selectedCompletedTask.managerUpdatedBy?.profile.userId !== user.userId && (
              <AlertCard variant='info'>
                <Typography style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                  This task has been completed by a member of your organisation for you.
                </Typography>
              </AlertCard>
            )}
          </Box>
          <CompletedTaskViewer
            taskItems={parsedTaskItems}
            taskItemValues={taskItemValues}
            volunteerProfileValues={isTaskZero ? volunteerProfileHardCodedFieldValues : undefined}
            taskHeaderDetails={taskHeaderDetails}
            selectedCompletedTask={selectedCompletedTask}
            isTaskZero={isTaskZero}
            revoke={() => {
              if (selectedCompletedTask) {
                revokeCompletedTask
                  .run({
                    completedTaskId: selectedCompletedTask.completedTaskId,
                    revoked: true,
                  })
                  .then(() => {
                    if (refetchCompletedTasks) {
                      refetchCompletedTasks();
                    }
                  });
              }
            }}
          />
        </SUYPTaskScaffold>
      ) : (
        <SUYPTaskScaffold allowBack taskHeaderDetails={taskHeaderDetails}>
          <TaskForm
            onSubmit={async (values) => {
              const taskFieldValues = await getTaskFieldValues(parsedTaskItems, values);
              const data = {
                taskId,
                userId: user.userId,
                taskFieldValues,
              };
              submitTaskForm(data);
            }}
            taskItems={taskItems}
          />
        </SUYPTaskScaffold>
      )}
    </Pane>
  );
};

export { SUYPTask };
