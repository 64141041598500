import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { AlertCard, getAlertCardColors } from '../../../../../../../common/cards/alert-card/AlertCard';
import { useUser } from '../../../../../../../global/auth/useUser';
import { SaveTaskValues, TaskFormBuilderRole } from '../../admin-console-volunteer-profile-model';

type Props = {
  roleOptions: Array<TaskFormBuilderRole>;
};

const SELECT_ALL_VALUE = 'all';
const SELECT_NONE_VALUE = 'none';

const WarningAlertCard = ({ additionalText }: { additionalText: string }) => {
  const { lightTextColor } = getAlertCardColors('info');

  return (
    <AlertCard variant='info'>
      <Typography
        variant='subtitle2'
        display='inline'
        style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
      >
        This task will be available to all volunteers as no roles have been created.{' '}
      </Typography>
      {additionalText && (
        <Typography
          variant='subtitle2'
          display='inline'
          style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
        >
          {additionalText}
        </Typography>
      )}
    </AlertCard>
  );
};

export const TaskPermissionLevelSelectionSection = ({ roleOptions }: Props) => {
  const [selectedUserRoles, setSelectedUserRoles] = useState<string[]>([]);
  const { values, errors, setFieldValue } = useFormikContext<SaveTaskValues>();
  const {
    user: { userIdentityService },
  } = useUser();
  const isAdmin = userIdentityService.privLevel === 'admin';
  const hasError = !!errors.roleIds;

  useEffect(() => {
    if (values.roleIds) {
      setSelectedUserRoles(values.roleIds);
    } else {
      setSelectedUserRoles([]);
    }
  }, [values.roleIds]);

  const handleRoleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    if (value.includes(SELECT_NONE_VALUE)) {
      setFieldValue('roleIds', []);
      return;
    }
    if (value.includes(SELECT_ALL_VALUE)) {
      setFieldValue(
        'roleIds',
        roleOptions.flatMap((role) => role.activityRoleId)
      );
      return;
    }
    setFieldValue('roleIds', value);
  };

  return (
    <>
      <Typography style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '16px' }}>
        ROLES
      </Typography>

      {roleOptions.length ? (
        <FormControl fullWidth variant='outlined' error={hasError} required>
          <InputLabel variant='outlined' id='program-multi-select-dropdown' shrink style={{ minWidth: 400 }}>
            Select role/s
          </InputLabel>
          <Select
            multiple
            name='roleIds'
            displayEmpty
            value={selectedUserRoles}
            onChange={handleRoleChange}
            input={<OutlinedInput notched label={'Select role/s'} />}
            renderValue={(selected) => {
              const selectedIds = selected as Array<string>;
              if (roleOptions.length > 0 && selectedUserRoles?.length === roleOptions.length) return 'All roles';
              if (selectedUserRoles?.length === 0) return 'No roles (Visible to All)';

              return roleOptions
                .filter((x: TaskFormBuilderRole) => selectedIds?.includes(x.activityRoleId))
                .map((x: TaskFormBuilderRole) => x.name)
                .join(', ');
            }}
          >
            <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
              <ListItemText primary={'Select All'} />
            </MenuItem>

            <MenuItem key={SELECT_NONE_VALUE} value={SELECT_NONE_VALUE}>
              <ListItemText primary={'Deselect All'} />
            </MenuItem>
            {roleOptions.map((role: TaskFormBuilderRole) => (
              <MenuItem key={role.activityRoleId} value={role.activityRoleId}>
                <ListItemIcon>
                  <Checkbox color='primary' checked={selectedUserRoles?.indexOf(role.activityRoleId) > -1} />
                </ListItemIcon>
                <ListItemText
                  primary={role.name}
                  primaryTypographyProps={{ style: { whiteSpace: 'normal', paddingRight: '8px' } }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : isAdmin ? (
        <WarningAlertCard additionalText={'Go to Admin Console > Roles to create a new role.'} />
      ) : (
        <WarningAlertCard additionalText={'Contact your Volaby admin to create roles.'} />
      )}
    </>
  );
};
