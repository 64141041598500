import { gql } from '@apollo/client';

export const CONFIG_ITEM_FRAGMENT = gql`
  fragment ConfigItem on ConfigItemType {
      __typename
      configItemId
      key
      description
      defaultValue
      configItemValue {
        configItemValueId
        flagValue
      }
   }
`;

export type GlobalConfigItems = {
  vm: {};
  im: {};
};
