import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/styles';
import pluralize from 'pluralize';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { convertFromRaw, EditorState } from 'draft-js';
import { TagItemMenuButton } from './TagItemMenu';

import { AdminConsoleTag } from '../__generated__/AdminConsoleTag';

export interface ExtendTag extends AdminConsoleTag {
  activityCount: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '&:not(:first-child)': {
        marginTop: '1.25rem',
      },
      '&:not(:last-child)': {
        borderBottomColor: '#EFEFEF',
        borderBottom: '1px solid',
        paddingBottom: '1.25rem',
      },
      paddingRight: '1rem',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    name: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 900,
      fontSize: '1rem',
    },
    parenthese: {
      fontWeight: 400,
      color: theme.color.grey.neutralBrand900,
      marginLeft: '0.75rem',
    },
    activity: {
      fontWeight: 400,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
        color: lighten(theme.palette.primary.main, 0.3),
      },
    },
    description: {
      fontSize: '0.875rem',
      color: theme.color.grey.neutralBrand200,
    },
  })
);

interface Props {
  tag: ExtendTag;
  isLoading: boolean;
  onEdit: (tag: ExtendTag) => void;
  onDelete: (tag: ExtendTag) => void;
  onShowActivities: (tag: ExtendTag) => void;
}

export function TagItem({ tag, onEdit, onDelete, onShowActivities, isLoading = false }: Props) {
  const classes = useStyles();
  const { name, description, activityCount } = tag;
  const editorState = React.useMemo(
    () => (description ? EditorState.createWithContent(convertFromRaw(JSON.parse(description))) : null),
    [tag.description]
  );

  const onAction = (action: string) => {
    switch (action) {
      case 'edit':
        onEdit(tag);
        break;
      case 'delete':
        onDelete(tag);
        break;
      default:
        break;
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography variant='h5' className={classes.name}>
          {name}&nbsp;
          {!isLoading && (
            <Typography component='span' className={classes.parenthese}>
              (&nbsp;
              <Typography component='span' className={classes.activity} onClick={() => onShowActivities(tag)}>
                {pluralize('Activity', activityCount, true)}
              </Typography>
              &nbsp;)
            </Typography>
          )}
        </Typography>
        <TagItemMenuButton onAction={onAction} />
      </Box>
      {editorState && <CampfireRichEditor readOnly editorState={editorState} />}
    </Box>
  );
}
