import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, LinearProgress, Paper, Portal, Slide, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useMemo, useState, memo } from 'react';
import { Avatar } from '../../../../../general/user-profile/components/Avatar';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

interface RosterActionsFooterProps {
  open: boolean;
  hasActiveCellChanges: boolean;
  hasPublishedRoster: boolean;
  changedVolunteers: Array<{
    volunteerId: string;
    profile: { preferredName: string; lastName: string; avatarUrl: string | null };
  }>;
  onPublishRosterClick: () => Promise<any>;
  onSaveDraftClick: () => Promise<any>;
  onDiscardChangesClick: () => void;
  isSubmitting: boolean;
  suppressDrafts: boolean;
}
const VOL_AVATAR_LIMIT = 8;

const RosterActionsFooter = memo((props: RosterActionsFooterProps) => {
  const {
    open,
    hasActiveCellChanges,
    hasPublishedRoster,
    changedVolunteers,
    onPublishRosterClick,
    onDiscardChangesClick,
    onSaveDraftClick,
    isSubmitting,
    suppressDrafts,
  } = props;

  const classes = useStyles();
  const { isSm, theme } = useCampfireTheme();

  const [showFooter, setShowFooter] = useState(open);

  useEffect(() => {
    if (open) setShowFooter(true);
  }, [open]);

  const tooltipDescription = useMemo(
    () =>
      changedVolunteers
        .map((volunteer) => `${volunteer.profile.preferredName} ${volunteer.profile.lastName}`)
        .join('\n'),
    [changedVolunteers]
  );

  const excessVolsCount =
    changedVolunteers.length > VOL_AVATAR_LIMIT ? `+${changedVolunteers.length - VOL_AVATAR_LIMIT} others` : '';

  if (!showFooter) return null;

  return (
    <Portal>
      <Box
        position='absolute'
        bottom={0}
        display='flex'
        left={0}
        right={0}
        margin='auto'
        maxHeight={isSm ? 130 : 90}
        maxWidth={1008}
        zIndex={theme.zIndex.drawer}
      >
        <Slide
          direction='up'
          in={open}
          onExited={() => {
            setShowFooter(false);
          }}
          timeout={100}
        >
          <Paper
            elevation={8}
            square
            style={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              position: 'relative',
              boxSizing: 'border-box',
              width: '100%',
              paddingBottom: 24,
              paddingTop: 24,
              paddingLeft: 32,
              paddingRight: 16,
            }}
          >
            {isSubmitting ? <LinearProgress /> : null}

            <Box
              display='flex'
              flexDirection={isSm ? 'column' : 'row'}
              flex='1 1 auto'
              alignContent='center'
              alignItems='center'
              justifyContent='space-between'
            >
              <Box>
                <Typography variant='body2' style={{ fontWeight: 'bold', textAlign: isSm ? 'center' : 'left' }}>
                  {hasActiveCellChanges ? 'Unsaved Changes' : 'Unpublished Changes'}
                </Typography>

                {changedVolunteers.length > 0 ? (
                  <Box display='flex'>
                    <TitularTooltip description={tooltipDescription}>
                      <Box display='flex' marginRight={1}>
                        {changedVolunteers.slice(0, VOL_AVATAR_LIMIT).map((x) => (
                          <Avatar
                            key={x.volunteerId}
                            preferredName={x.profile.preferredName}
                            lastName={x.profile.lastName}
                            avatarUrl={x.profile.avatarUrl}
                            size={22}
                            style={{ marginLeft: -2, marginRight: -2 }}
                          />
                        ))}
                      </Box>
                    </TitularTooltip>

                    <Typography variant='body2'>
                      {`${excessVolsCount} will be notified when you publish this roster`}
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant='body2'>Changes have been made to the draft roster</Typography>
                )}
              </Box>

              <Box display='flex' flex='1 1 auto' justifyContent='flex-end' paddingTop={isSm ? '8px' : '0'}>
                <TabletButton
                  disabled={!hasActiveCellChanges || isSubmitting}
                  className={classes.button}
                  data-track='actCnl-roster-header-discard-changes'
                  size='small'
                  onClick={onDiscardChangesClick}
                >
                  Discard Changes
                </TabletButton>

                <Box display='flex'>
                  {suppressDrafts ? null : (
                    <TabletButton
                      disabled={isSubmitting || !hasActiveCellChanges}
                      className={classes.button}
                      data-track='actCnl-roster-header-save-draft'
                      size='small'
                      variant='outlined'
                      color='primary'
                      onClick={onSaveDraftClick}
                    >
                      Save Changes
                    </TabletButton>
                  )}

                  {changedVolunteers.length > 0 ? (
                    <TabletButton
                      disabled={isSubmitting}
                      className={classes.button}
                      data-track='actCnl-roster-header-publish-roster'
                      style={{ marginRight: 8 }}
                      size='medium'
                      variant='contained'
                      color='primary'
                      onClick={onPublishRosterClick}
                    >
                      {`Publish  ${hasPublishedRoster ? 'Changes' : 'Roster'}`}
                    </TabletButton>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Paper>
        </Slide>
      </Box>
    </Portal>
  );
});

export { RosterActionsFooter };

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      marginRight: 8,
    },
  })
);
