import { gql } from '@apollo/client';

export const GET_ACTIVITIES_EXPLORE_ACTIVITIES = gql`
  query GetActivitiesExploreActivities($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        programs {
          activities {
            activityId
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITIES_EXPLORE = gql`
  query GetActivitiesExplore($volunteerId: String!, $activityIds: [String!]!) {
    orgName
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        profile {
          preferredName
        }
        activityApplications {
          ...ActivitiesExploreActivityApplication
        }
        activityEnrolments {
          ...ActivitiesExploreActivityEnrolment
        }
      }
      programs {
        name
        programId
        dateSuspended
        dateDeleted
        activities {
          activityId
          isSuspended
        }
      }
      activities(activityIds: $activityIds) {
        ...ActivitiesExploreActivity
      }
    }
  }
  fragment ActivitiesExploreActivityEnrolment on VOLUNTEER_ActivityEnrolmentType {
    activityEnrolmentId
    volunteer {
      volunteerId
      rosterings {
        publishedRosteringId
        publishedRoster {
          activity {
            activityId
          }
        }
      }
    }
    availabilities(pagination: { limit: 1 }) {
      activityAvailabilityId
    }
  }
  fragment ActivitiesExploreActivity on VOLUNTEER_ActivityType {
    activityId
    name
    description
    startTime
    startDate
    endDate
    endTime
    nextOccurrence
    nextX(x: 3)
    ... on VOLUNTEER_NonRecurringActivityType {
      __typename
    }
    ... on VOLUNTEER_RecurringActivityType {
      __typename
      schedule {
        recurrences {
          humanReadable
          ...ActivitiesExploreActivityRecurrenceType
        }
      }
    }
    isEnrollable
    isActive
    isSuspended
    isRestrictedActivity
    isHidden
    hasOpenRoster
    activityEnrolments {
      ...ActivitiesExploreActivityEnrolment
    }
    activeActivityApplications {
      activityApplicationId
      volunteer {
        volunteerId
      }
    }
    activityLocation {
      activityLocationId
      placesAddress {
        description
        latLong {
          lat
          long
        }
      }
    }
    activityRemoteLocation {
      details
    }
    sessions {
      ...ActivitiesExploreActivitySession
    }
    cancelledActivities {
      ...ActivitiesExploreActivityCancelledActivity
    }
    closedActivity {
      ...ActivitiesExploreActivityClosedActivity
    }
    program {
      ...ActivitiesExploreProgram
    }
    activeActivityWaitlistings {
      ...ActivitiesExploreActivityWaitlisting
    }
  }
  fragment ActivitiesExploreProgram on VOLUNTEER_ProgramType {
    programId
    name
    dateSuspended
    dateDeleted
    activities {
      activityId
      isSuspended
    }
  }
  fragment ActivitiesExploreActivitySession on VOLUNTEER_SessionType {
    sessionId
    name
    startTime
    endTime
  }
  fragment ActivitiesExploreActivityCancelledActivity on VOLUNTEER_CancelledActivityType {
    cancelledActivityId
    activityDate
  }
  fragment ActivitiesExploreActivityClosedActivity on VOLUNTEER_ClosedActivityType {
    closedActivityId
  }
  fragment ActivitiesExploreActivityApplication on VOLUNTEER_ActivityApplicationType {
    activityApplicationId
    activity {
      activityId
    }
    dateActioned
  }
  fragment ActivitiesExploreActivityRecurrenceType on RecurrenceType {
    ... on SingleRecurrenceType {
      startDate
      endDate
      humanReadable
    }
    ... on DailyRecurrenceType {
      startDate
      endDate
    }
    ... on WeeklyRecurrenceType {
      startDate
      endDate
      days
    }
    ... on MonthlyNthDayRecurrenceType {
      startDate
      endDate
      day
    }
    ... on MonthlyByMonthDayRecurrenceType {
      startDate
      endDate
    }
    ... on YearlyRecurrenceType {
      startDate
      endDate
    }
  }
  fragment ActivitiesExploreActivityWaitlisting on VOLUNTEER_ActivityWaitlistingType {
    activityWaitlistingId
    activity {
      activityId
    }
    volunteer {
      volunteerId
    }
  }
`;
