import { Box, ListItem, Typography } from '@material-ui/core';
import { ListItemProps } from '@material-ui/core/ListItem';
import { DoneRounded } from '@material-ui/icons';
import React, { Fragment, useCallback, useState } from 'react';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { Avatar } from '../../../../../general/user-profile/components/Avatar';
import { RosterGridDataRow } from '../roster/activity-roster-model';
import { useRunSaveUnavailability } from '../roster/use-run-save-unavailability';
import { SingleVolunteerAvailablityDialog } from './SingleVolunteerAvailablityDialog';
import { VolunteerRosterSessionStatusDot } from './VolunteerRosterSessionStatusDot';

type Props = {
  activityDate: string;
  primaryText: string;
  secondaryText?: string;
  statusText: string;
  runRefetch: () => void;
  updateRosterRow: (volunteerId: string, updatedRosterGridDataRow: RosterGridDataRow) => void;
  rosterGridDataRow: RosterGridDataRow;
  isNew: boolean;
  style?: React.CSSProperties;
} & ListItemProps;

export const BaseVolunteerListItem = React.memo((props: Props) => {
  const {
    activityDate,
    runRefetch,
    primaryText,
    secondaryText,
    statusText,
    rosterGridDataRow,
    updateRosterRow,
    isNew,
    style,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const runSaveUnavailability = useRunSaveUnavailability(runRefetch);
  const { volunteer } =
    rosterGridDataRow.__typename === 'enrolled' ? rosterGridDataRow.activityEnrolment : rosterGridDataRow;
  const activityEnrolment =
    rosterGridDataRow.__typename === 'enrolled' ? rosterGridDataRow.activityEnrolment : undefined;
  const activityEnrolmentId = activityEnrolment?.activityEnrolmentId;

  function handleOpenDialog() {
    setDialogOpen(true);
  }

  const { theme } = useCampfireTheme();

  const hasExistingRosterings = !!rosterGridDataRow.rosterSessions.find(
    (rosterSession) => rosterSession.activeStatus === 'publishedSelected'
  );

  const onMakeUnavailable = useCallback(
    activityEnrolmentId ? () => runSaveUnavailability(activityEnrolmentId, activityDate) : () => {},
    [activityEnrolmentId]
  );

  return (
    <Fragment key={activityEnrolmentId ?? volunteer.volunteerId}>
      <ListItem divider button onClick={handleOpenDialog} style={style}>
        <Avatar
          size={32}
          preferredName={volunteer.profile.preferredName}
          lastName={volunteer.profile.lastName}
          avatarUrl={volunteer.profile.avatarUrl}
        />

        <Box
          width={1}
          display='flex'
          flex='1 1 auto'
          alignContent='center'
          alignItems='center'
          justifyContent='space-between'
          marginLeft={'8px'}
          paddingY={1}
        >
          <Box display='flex' flex='1 1 auto' flexDirection='column'>
            <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
              <Typography variant='body2' gutterBottom={false}>
                {primaryText}
              </Typography>

              {isNew ? (
                <Typography
                  variant='caption'
                  gutterBottom={false}
                  style={{ marginLeft: 8, color: '#2196f3', fontWeight: 'bold', fontSize: 9 }}
                >
                  {'NEW'}
                </Typography>
              ) : null}
            </Box>

            {secondaryText ? (
              <Typography color='textSecondary' variant='body2' gutterBottom={false}>
                {secondaryText}
              </Typography>
            ) : null}

            <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
              <Box display='flex' marginLeft='4px' marginRight='8px'>
                {rosterGridDataRow.rosterSessions.map((rosterSession) => (
                  <VolunteerRosterSessionStatusDot
                    key={`${rosterSession.session.sessionId}-${rosterSession.activeStatus}`}
                    session={rosterSession}
                  />
                ))}
              </Box>

              <Typography variant='body2' gutterBottom={false}>
                {statusText}
              </Typography>
            </Box>
          </Box>

          <Box display='flex' flex='0 0 auto' alignContent='center' alignItems='center' justifyContent='space-between'>
            {hasExistingRosterings ? (
              <DoneRounded
                style={{
                  color: theme.color.rosters.rostered,
                }}
              />
            ) : null}
          </Box>
        </Box>
      </ListItem>

      {dialogOpen ? (
        <SingleVolunteerAvailablityDialog
          open
          onClose={() => {
            setDialogOpen(false);
          }}
          rosterGridDataRow={rosterGridDataRow}
          volunteerName={`${volunteer.profile.preferredName} ${volunteer.profile.lastName}`}
          onMakeUnavailable={onMakeUnavailable}
          onSubmit={(sessionRosterings: RosterGridDataRow) => {
            updateRosterRow(volunteer.volunteerId, sessionRosterings);
          }}
        />
      ) : null}
    </Fragment>
  );
});
