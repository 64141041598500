import { unpackToDateTime } from '@campfire/hot-date';
import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid';
import { CommonRequiringActionWidgetProps } from '../../../shared';
import { VolunteerProfile } from '../../../../../../../../../common/AvatarName';
import { useCampfireQuery } from '../../../../../../../../../global/network/useCampfireQuery';
import { ActivityNameAndPriority } from '../../../../../../../../program-manager/volunteers/common/ActivityInfo';
import { WidgetTypes } from '../../../../dashboard-widget-setting/dashboard-widget-model';
import {
  GetTableWidgetActivityWaitlistings,
  GetTableWidgetActivityWaitlistingsVariables,
} from './__generated__/GetTableWidgetActivityWaitlistings';
import { GET_TABLE_WIDGET_ACTIVITY_WAITLISTINGS } from './activity-waitlisting-table-widget-model.gql';

export const ActivityWaitlistingTableWidget = (props: CommonRequiringActionWidgetProps) => {
  const { filters, range } = props;
  const { programIds, activityIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };

  const { data, loading } = useCampfireQuery<
    GetTableWidgetActivityWaitlistings,
    GetTableWidgetActivityWaitlistingsVariables
  >(GET_TABLE_WIDGET_ACTIVITY_WAITLISTINGS, {
    options: {
      variables: {
        programIds: programIds.length > 0 ? programIds : null,
        activityIds: activityIds.length > 0 ? activityIds : null,
        range: range,
        widgetType: WidgetTypes.TABLE,
      },
    },
  });

  const waitlistings =
    data?.vm.activityWaitlistings
      .filter(
        (waitlist) =>
          waitlist.activity.isActive &&
          !waitlist.activity.program.dateDeleted &&
          !waitlist.activity.program.dateSuspended &&
          !waitlist.volunteer.dateDeactivated &&
          !waitlist.volunteer.flagging?.dateFlagged
      )
      .map((waitlist) => ({
        id: waitlist.activityWaitlistingId,
        program: waitlist.activity.program.name,
        profile: waitlist.volunteer.profile,
        activityName: waitlist.activity.name,
        activityPriority: waitlist.activity.priority,
        dateApplied: unpackToDateTime(waitlist.dateAdded).toFormat('dd/MM/y'),
        dateRemoved: waitlist.dateRemoved ? unpackToDateTime(waitlist.dateRemoved).toFormat('dd/MM/y') : '',
      })) || [];

  const columns: GridColumns = [
    {
      field: 'volunteerName',
      headerName: 'Volunteer',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 180,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <VolunteerProfile inTable {...row.profile} name={`${row.profile.preferredName} ${row.profile.lastName}`} />
        );
      },
    },
    {
      field: 'program',
      headerName: 'Program',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: ({ row }: any) => {
        return (
          <Tooltip title={row.program}>
            <Typography noWrap style={{ fontSize: 'inherit' }}>
              {row.program}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'activityName',
      headerName: 'Activity',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: ({ row }: any) => {
        return <ActivityNameAndPriority name={row.activityName} priority={row.activityPriority} />;
      },
    },
    {
      field: 'dateApplied',
      headerName: 'Date Applied',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'dateRemoved',
      headerName: 'Date Removed',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 170,
    },
  ];

  return (
    <Box width='100%' height='100%'>
      <DataGrid columns={columns} rows={waitlistings} loading={loading} autoPageSize disableSelectionOnClick />
    </Box>
  );
};
