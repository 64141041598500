import { TabletButton } from '@campfire/tablet-button';
import { Menu, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

const activitiesMenuOptions = [
  { label: 'All Programs', value: 'programs' },
  { label: 'All Activities', value: 'all' },
  { label: 'My Rosters', value: 'mine' },
];

export const ActivitiesMobileMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const handleOpenProgramsMenuOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProgramsMenuOptions = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TabletButton
        size='small'
        variant='outlined'
        color='primary'
        aria-controls='incident-status-menu'
        aria-haspopup='true'
        onClick={handleOpenProgramsMenuOptions}
        endIcon={<ExpandMore />}
        style={{
          border: '1px solid #9e9e9e',
          boxSizing: 'border-box',
          borderRadius: '4px',
          padding: '7px 12px',
          color: '#444444',
          marginRight: '8px',
          minWidth: '100px',
          fontSize: '16px',
          fontWeight: 900,
        }}
      >
        {query.tab ? activitiesMenuOptions.find((option) => option.value === query.tab)?.label : null}
      </TabletButton>
      <Menu
        id='activities-mobile-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseProgramsMenuOptions}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {activitiesMenuOptions.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              setQuery({ tab: option.value });
              handleCloseProgramsMenuOptions();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
