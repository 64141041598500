import React from 'react';
import { Divider, Typography } from '@material-ui/core';

export const SectionHeader = ({ title, divider }: { title: string; divider?: boolean }) => (
  <>
    <Typography variant='h6' style={{ fontWeight: 600 }}>
      {title}
    </Typography>
    {divider && <Divider style={{ marginBottom: 8 }} />}
  </>
);

export const SectionSubheader = ({ title }: { title: string }) => (
  <Typography
    // color='textSecondary'
    variant='body2'
    // gutterBottom
  >
    {title}
  </Typography>
);
