const PrivilegeRules = {
  // Roster
  addVolunteerToTeam: 'add-volunteer-to-team',
  removeVolunteerFromTeam: 'remove-volunteer-from-team',
  readRoster: 'read-roster',
  createRoster: 'create-roster',

  // Support Resource
  updateSupportResource: 'update-support-resource',

  // Incident Management
  readIncident: 'read-incident',
  deleteIncident: 'delete-incident',
  closeIncident: 'close-incident',
  archiveIncident: 'archive-incident',
  workIncident: 'work-incident',

  // Volunteer Management
  readAllVolunteers: 'read-all-volunteers',
  readVolunteerContact: 'read-volunteer-contact',
  readVolunteerGender: 'read-volunteer-gender',
  readVolunteerDob: 'read-volunteer-dob',
  readVolunteerUniformSize: 'read-volunteer-uniform-size',
  readVolunteerAddress: 'read-volunteer-address',
  readVolunteerEmergencyContact: 'read-volunteer-emergency-contact',
  readVolunteerHealth: 'read-volunteer-health',
  readVolunteerFlagging: 'read-volunteer-flagging',
  readVolunteerOnboarding: 'read-volunteer-onboarding',
  flagVolunteer: 'flag-volunteer',
  retireVolunteer: 'retire-volunteer',
  unretireVolunteer: 'unretire-volunteer',
  readVolunteerComments: 'read-volunteer-comments',
  updateVolunteerComments: 'update-volunteer-comments',
  updateVolunteerActivityRoles: 'update-volunteer-activity-roles',
  readIncomingVolunteers: 'read-incoming-volunteers',
  updateIncomingVolunteers: 'update-incoming-volunteers',
  createVolunteers: 'create-volunteers',

  // Program Manager Management
  createProgramManager: 'create-program-manager',
  updateProgramManager: 'update-program-manager',

  // Program Management
  managePrograms: 'manage-programs',

  // Report Type Management
  manageReportTypes: 'manage-report-types',

  // Activity Management
  manageActivities: 'manage-activities',
  readAllActivities: 'read-all-activities',
  readActivityReport: 'read-activity-report',
  createSessionReport: 'create-session-report',
  editSessionReport: 'edit-session-report',
  cancelSessionReport: 'cancel-session-report',
  cancelActivity: 'cancel-activity',
  closeActivity: 'close-activity',
  suspendActivity: 'suspend-activity',
};

export default PrivilegeRules;
