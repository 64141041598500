import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

export const tooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      borderRadius: '12px',
      paddingTop: '0.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '0.5rem',
      backgroundColor: theme.color?.tooltip?.background,
    },
    arrow: {
      '&::before': {
        backgroundColor: theme.color?.tooltip?.background,
      },
    },
  })
);
