import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useEffect, useState, useMemo } from 'react';
import { SaveTaskValues, TaskFormBuilderActivity } from '../../admin-console-volunteer-profile-model';

const SELECT_ALL_VALUE = 'all';
const SELECT_NONE_VALUE = 'none';

type Props = {
  activityOptions: Array<TaskFormBuilderActivity>;
};
export const TaskActivitySelectionSection = ({ activityOptions }: Props) => {
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const { values, errors, setFieldValue } = useFormikContext<SaveTaskValues>();
  const hasError = !!errors.activityIds;

  useEffect(() => {
    if (values.activityIds) {
      setSelectedActivities(values.activityIds);
    } else {
      setSelectedActivities([]);
    }
  }, [values.activityIds]);

  const handleActivityChange = (event: any) => {
    const {
      target: { value },
    } = event;
    if (value.includes(SELECT_NONE_VALUE)) {
      setFieldValue('activityIds', []);
      return;
    }
    if (value.includes(SELECT_ALL_VALUE)) {
      setFieldValue(
        'activityIds',
        activityOptions.flatMap((activity) => activity.activityId)
      );
      return;
    }
    setFieldValue('activityIds', value);
  };

  const activityOptionsFiltered = useMemo(() => {
    if (values.programIds?.includes(SELECT_ALL_VALUE)) {
      return [];
    }
    return values.programIds
      ? activityOptions.filter((activity) => values.programIds?.includes(activity.programId))
      : [];
  }, [values.programIds, activityOptions]);

  if (
    !values.programIds ||
    values.programIds.length === 0 ||
    values.programIds.includes(SELECT_NONE_VALUE) ||
    values.programIds.includes(SELECT_ALL_VALUE)
  )
    return null;

  return (
    <>
      <Typography style={{ fontSize: '11px', fontWeight: 500, color: '#000000b0', paddingBottom: '16px' }}>
        ACTIVITIES
      </Typography>

      <FormControl fullWidth variant='outlined' error={hasError} required>
        <InputLabel variant='outlined' id='program-multi-select-dropdown' shrink style={{ minWidth: 400 }}>
          Select activity/s
        </InputLabel>
        <Select
          multiple
          name='activityIds'
          displayEmpty
          value={selectedActivities}
          onChange={handleActivityChange}
          input={<OutlinedInput notched label={'Select activity/s'} />}
          renderValue={(selected) => {
            const selectedIds = selected as Array<string>;
            if (activityOptionsFiltered.length > 0 && selectedActivities?.length === activityOptionsFiltered.length)
              return 'All activities';
            if (selectedActivities?.length === 0) return 'No activity (Visible to All)';

            return activityOptionsFiltered
              .filter((x: TaskFormBuilderActivity) => selectedIds?.includes(x.activityId))
              .map((x: TaskFormBuilderActivity) => x.name)
              .join(', ');
          }}
        >
          <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
            <ListItemText primary={'Select All'} />
          </MenuItem>

          <MenuItem key={SELECT_NONE_VALUE} value={SELECT_NONE_VALUE}>
            <ListItemText primary={'Deselect All'} />
          </MenuItem>
          {activityOptionsFiltered.length &&
            activityOptionsFiltered.map((role: TaskFormBuilderActivity) => (
              <MenuItem key={role.activityId} value={role.activityId}>
                <ListItemIcon>
                  <Checkbox color='primary' checked={selectedActivities?.indexOf(role.activityId) > -1} />
                </ListItemIcon>
                <ListItemText
                  primary={role.name}
                  primaryTypographyProps={{ style: { whiteSpace: 'normal', paddingRight: '8px' } }}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};
