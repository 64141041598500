import React from 'react';
import { Box, ListItem, List, Typography, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { unpackToDate } from '@campfire/hot-date';
import { Link } from 'react-router-dom';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { ManagementOverviewHeading } from './ManagementOverviewHeading';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { getDisplayTimeSchedule } from '../../../../common/functions/activity-display-helpers';
import { IncompleteReportDate } from './ManagementOverview';

const OUTSTANDING_REPORTS_PATH = '/volunteering/activity-report';

interface Props {
  reports: IncompleteReportDate[];
  isLoading: boolean;
}

export const OutstandingReportsList = (props: Props) => {
  const { reports, isLoading } = props;
  const { theme } = useCampfireTheme();

  return (
    <Box display={'grid'}>
      <ManagementOverviewHeading heading={'Outstanding Reports'} viewAllPath={OUTSTANDING_REPORTS_PATH} />
      <List style={{ width: '100%', overflow: 'hidden' }}>
        {isLoading ? (
          Array.from(Array(3)).map(() => (
            <ListItem key={uuidv4()}>
              <OutstandingReportsListItemSkeleton />
            </ListItem>
          ))
        ) : !isEmpty(reports) ? (
          reports.map((r) => (
            <ListItem key={`${r.activityId}-${r.date}`} disableGutters>
              <OutstandingReportsListItem
                activityId={r.activityId}
                date={r.date}
                theme={theme}
                name={r.name}
                startTime={r.startTime}
                endTime={r.endTime}
              />
            </ListItem>
          ))
        ) : (
          <Typography
            style={{ textAlign: 'center', color: theme.color.grey.neutral400, fontSize: '15px', paddingTop: '12px' }}
          >
            <span role='img' aria-label='Congratulations!'>
              🎉
            </span>{' '}
            You&#39;re all up to date!
          </Typography>
        )}
      </List>
    </Box>
  );
};

interface OutstandingReportsListItemProps {
  activityId: string;
  date: string;
  theme: Theme;
  name: string;
  startTime: string;
  endTime: string;
}

const OutstandingReportsListItem = ({
  activityId,
  date,
  theme,
  name,
  startTime,
  endTime,
}: OutstandingReportsListItemProps) => (
  <Link
    to={{
      pathname: `${OUTSTANDING_REPORTS_PATH}/submit`,
      search: `?activityId=${activityId}&activityDate=${date}&tab=incomplete`,
    }}
    style={{ textDecoration: 'none', width: '100%', color: '#000' }}
  >
    <Box
      display={'flex'}
      height={'80px'}
      width={'100%'}
      borderRadius={'8px'}
      boxSizing={'border-box'}
      border={`1px solid ${theme.color.grey.border}`}
      bgcolor={theme.color.grey.neutralBrand100}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        minWidth={'64px'}
        borderRadius={'8px 0px 0px 8px'}
      >
        <Typography variant={'h5'} style={{ color: theme.color.grey.neutral300, fontWeight: 900, fontSize: '30px' }}>
          {unpackToDate(date).day}
        </Typography>
        <Typography
          variant={'body2'}
          style={{ color: theme.color.grey.neutral200, fontSize: '14px', fontWeight: 'bold' }}
        >
          {unpackToDate(date).monthShort}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        flexGrow={1}
        alignItems={'flex-start'}
        justifyContent={'center'}
        style={{ padding: '0px 12px' }}
        borderRadius={'0px 8px 8px 0px'}
        bgcolor={theme.color.white.neutral50}
        overflow={'hidden'}
      >
        <TitularTooltip title={name}>
          <Typography
            variant={'h6'}
            noWrap
            style={{ color: theme.color.grey.neutral400, fontWeight: 800, width: '100%', letterSpacing: '-0.02em' }}
          >
            {name}
          </Typography>
        </TitularTooltip>

        <Typography
          variant={'caption'}
          style={{ color: theme.color.grey.neutral200, paddingTop: '6px' }}
        >{`${getDisplayTimeSchedule(startTime, endTime)}`}</Typography>
      </Box>
    </Box>
  </Link>
);

const OutstandingReportsListItemSkeleton = () => (
  <Skeleton variant={'rect'} width={'100%'} height={'80px'} style={{ borderRadius: '8px' }} />
);
