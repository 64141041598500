import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

type FloatingPageProps = {
  header?: React.ReactNode;
};

const FloatingPage: FunctionComponent<FloatingPageProps> = (props) => {
  const { children, header } = props;
  const { isMobile } = useCampfireTheme();

  return (
    <Box
      //
      className='Page'
      height={'100vh'}
      display='flex'
      flexDirection='column'
      justifyContent={isMobile ? undefined : 'center'}
      style={{
        backgroundColor: '#f6f8f9',
      }}
    >
      <Box flexGrow={isMobile ? 1 : undefined} display='flex' flexDirection='column'>
        <Box width={1} className='PageHeaderContainer' paddingBottom={6}>
          {header}
        </Box>
        <Box className='PageContentContainer' display='flex' flex='1 0 auto' width={1}>
          <Box
            className='PageLayoutContainer'
            marginTop={-4}
            display='flex'
            flex='1 0 auto'
            style={{
              overflowX: 'auto',
              overflowY: 'hidden',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { FloatingPage };
