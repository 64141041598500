import React from 'react';
import { useFormikContext } from 'formik';
import { CreatWidgetFormValues, StepsEnum } from '../dashboard-widget-model';
import { SelectWidgetDialog } from './SelectWidgetDialog';
import { SelectNumberTypeDialog } from './SelectNumberTypeDialog';
import { SelectChartTypeDialog } from './SelectChartTypeDialog';
import { SettingsWidgetFormDialog } from './SettingsWidgetFormDialog';
import { SelectValueDialog } from './SelectValueDialog';

export function WidgetForm({ onClose }: { onClose: () => void }) {
  const { values } = useFormikContext<CreatWidgetFormValues>();
  switch (values.selectStep) {
    case StepsEnum.SELECT_WIDGET:
      return <SelectWidgetDialog onClose={onClose} />;
    case StepsEnum.SELECT_NUMBER_TYPE:
      return <SelectNumberTypeDialog onClose={onClose} />;
    case StepsEnum.SELECT_CHART_TYPE:
      return <SelectChartTypeDialog onClose={onClose} />;
    case StepsEnum.SETTINGS:
      return <SettingsWidgetFormDialog onClose={onClose} />;
    case StepsEnum.ADD_VALUE:
      return <SelectValueDialog onClose={onClose} />;
    default:
      return null;
  }
}
