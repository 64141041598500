import React, { useState, useEffect } from 'react';
import { Field } from '@campfire/field';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { CampfireRadioGroup } from '@campfire/radio-group';
import { MultiSelect } from '@campfire/multi-select';
import { Select } from '@campfire/select';
import { HoverText } from '@campfire/hover-link';
import { unpackToDateTime } from '@campfire/hot-date';
import {
  Box,
  Typography,
  Divider,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
  Button,
} from '@material-ui/core';
import { SendRounded, KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import { isUndefined } from 'lodash';

import {
  BULLETIN_IS_COMMENTABLE,
  MANAGEMENT_VOLUNTEER_PROFILE_BULLETIN_GET_ALL_PROGRAMS,
  MANAGEMENT_VOLUNTEER_PROFILE_BULLETIN_GET_ALL_ROLES,
} from './admin-console-bulletins-model.gql';
import { ManagementVolunteerProfileBulletinGetAllPrograms } from './__generated__/ManagementVolunteerProfileBulletinGetAllPrograms';
import { ManagementVolunteerProfileBulletinGetAllRoles } from './__generated__/ManagementVolunteerProfileBulletinGetAllRoles';
import { GetAdminConsoleBulletinSettings } from '../../admin/admin-console/admin-console-content-pages/admin-console-bulletin-settings/__generated__/GetAdminConsoleBulletinSettings';
import { GET_ADMIN_CONSOLE_BULLETIN_SETTINGS } from '../../admin/admin-console/admin-console-content-pages/admin-console-bulletin-settings/admin-console-bulletin-settings.gql';
import { BulletinManagement } from './__generated__/BulletinManagement';
import { valuesToSubmit } from './BulletinManagementScreen';
import { SemanticTime } from '../../../common/dates/SemanticTime';
import { useContainerStyles } from './bulletinStyles';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { ProgramsContext } from '../../../global/programs-sell/ProgramsContext';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { useUser } from '../../../global/auth/useUser';
import { GetBulletinIsCommentable } from './__generated__/GetBulletinIsCommentable';

interface BulletinAddEditProps {
  cancel: () => void;
  submitBulletin: (bulletin: valuesToSubmit) => Promise<any>;
  deleteBulletin: (bulletinId: string) => Promise<any>;
  activeBulletin: BulletinManagement | null;
  isLoading: boolean;
}

const SaveBulletinManagement = ({
  cancel,
  submitBulletin,
  activeBulletin,
  deleteBulletin,
  isLoading,
}: BulletinAddEditProps) => {
  const containerStyles = useContainerStyles();
  const {
    user: { userIdentityService },
  } = useUser();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [showAdvanced, setShowAdvanced] = React.useState(false);
  const [content, setContent] = useState(EditorState.createEmpty());
  const { getAuthorizedPrograms } = React.useContext(ProgramsContext);
  const { isVmAdmin } = userIdentityService;
  const { theme } = useCampfireTheme();
  const openConfirmDialog = () => setDisplayDialog(true);
  const closeConfirmDialog = () => setDisplayDialog(false);
  const closeDialogAndDelete = (bulletinId: string) => {
    closeConfirmDialog();
    deleteBulletin(bulletinId);
  };

  const { data: vmProgram } = useCampfireQuery<ManagementVolunteerProfileBulletinGetAllPrograms, {}>(
    MANAGEMENT_VOLUNTEER_PROFILE_BULLETIN_GET_ALL_PROGRAMS
  );

  const { data: bulletinCanCommentData } = useCampfireQuery<GetBulletinIsCommentable, {}>(BULLETIN_IS_COMMENTABLE, {});
  const bulletinCanComment = !isUndefined(bulletinCanCommentData?.vm.configItem?.configItemValue?.flagValue)
    ? Boolean(bulletinCanCommentData?.vm.configItem?.configItemValue?.flagValue)
    : Boolean(bulletinCanCommentData?.vm.configItem?.defaultValue);

  const { data: vmActivityRole } = useCampfireQuery<ManagementVolunteerProfileBulletinGetAllRoles, {}>(
    MANAGEMENT_VOLUNTEER_PROFILE_BULLETIN_GET_ALL_ROLES
  );

  const { data: bulletinSettings } = useCampfireQuery<GetAdminConsoleBulletinSettings, undefined>(
    GET_ADMIN_CONSOLE_BULLETIN_SETTINGS
  );

  const canUserAddEdit =
    userIdentityService.isVmAdmin ||
    (userIdentityService.isProgramManager && bulletinSettings?.vm.bulletinAllowManagerAction);

  const allItems = (key: string) => [
    {
      label: `All ${key}`,
      value: 'all',
    },
    {
      label: `None of ${key}`,
      value: 'none',
    },
  ];

  const authorizedProgramIds = getAuthorizedPrograms(true).map((item) => item.programId);
  const filteredAuthPrograms =
    vmProgram?.vm.programs
      .filter((item) => authorizedProgramIds.includes(item.programId))
      .filter((program) => !program.dateDeleted) || [];

  const defaultValues = {
    title: '',
    content: '',
    pinned: false,
    isCommentable: true,
    notifyVolunteers: 'notify',
    programIds: isVmAdmin ? ([] as string[]) : authorizedProgramIds,
    activityIds: [] as string[],
    roleIds: [] as string[],
    permissionLevel: 'none',
  };

  const initialValues = activeBulletin
    ? {
        ...activeBulletin,
        programIds: activeBulletin.programs.map((program) => program.programId),
        roleIds: activeBulletin.activityRoles.map((role) => role.activityRoleId),
        activityIds: activeBulletin.activities.map((activity) => activity.activityId),
      }
    : defaultValues;

  const permissionLevelOptions = [
    { label: 'All Permission Levels', value: 'none' },
    { label: 'General Volunteer', value: 'gv' },
    { label: 'Activity Leader', value: 'al' },
    { label: 'Program Manager', value: 'pm' },
    { label: 'Admin', value: 'admin' },
  ];

  useEffect(() => {
    if (activeBulletin?.content) {
      const raw = convertFromRaw(JSON.parse(activeBulletin?.content));
      setContent(EditorState.createWithContent(raw));
    }
  }, [activeBulletin]);

  const prepareValuesAndSubmit = (values: valuesToSubmit) => {
    const programIds = values.programIds.includes('all') ? [] : values.programIds;
    const activityIds = values.activityIds.includes('all') ? [] : values.activityIds;
    const roleIds = values.roleIds.includes('all')
      ? (vmActivityRole?.vm.activityRoles || []).map((role) => role.activityRoleId)
      : values.roleIds;
    const permissionLevel = values.permissionLevel === 'none' ? undefined : values.permissionLevel;
    const coreValues = {
      ...values,
      notifyVolunteers: values.notifyVolunteers === 'notify',
      programIds,
      activityIds,
      roleIds,
      permissionLevel,
      contentHtml: stateToHTML(content.getCurrentContent()),
      content: JSON.stringify(convertToRaw(content.getCurrentContent())),
    };
    const submissionValues = activeBulletin ? { ...coreValues, bulletinId: activeBulletin.bulletinId } : coreValues;
    setIsSubmitting(true);
    submitBulletin(submissionValues).finally(() => {
      setIsSubmitting(false);
    });
  };

  useEffect(() => {
    if (activeBulletin?.content) {
      const raw = convertFromRaw(JSON.parse(activeBulletin?.content));
      setContent(EditorState.createWithContent(raw));
    }
  }, [activeBulletin]);

  return (
    <Box position='relative' display='flex' justifyContent='center' className={containerStyles.container} pb={2}>
      <LinearProgressOverlay isLoading={isLoading} />
      <Box mt={6} width='100%'>
        <Box pb={2}>
          <Typography variant='h4' display='inline' style={{ color: '#3e3229', fontWeight: 550, fontSize: '24px' }}>
            {activeBulletin ? 'Edit Bulletin' : 'Add New Bulletin'}
          </Typography>
          {activeBulletin && (
            <Box mt={1}>
              <Typography color='textSecondary' variant='body2'>
                {activeBulletin.lastUpdatedBy && activeBulletin.dateUpdated ? (
                  <>
                    {`Last updated by ${activeBulletin.lastUpdatedBy?.profile.preferredName} ${activeBulletin.lastUpdatedBy?.profile.lastName}, `}
                    <SemanticTime
                      dateTime={unpackToDateTime(activeBulletin.dateUpdated)}
                      typographyProps={{ variant: 'body2', color: 'textSecondary' }}
                      displayYear
                    />
                  </>
                ) : (
                  <>
                    {`Created by ${activeBulletin?.createdBy?.profile.preferredName} ${activeBulletin?.createdBy?.profile.lastName}, `}
                    <SemanticTime
                      dateTime={unpackToDateTime(activeBulletin.dateCreated)}
                      typographyProps={{ variant: 'body2', color: 'textSecondary' }}
                      displayYear
                    />
                  </>
                )}
              </Typography>
            </Box>
          )}
        </Box>
        <Divider />
        <Box mt={2}>
          <Typography style={{ fontSize: '14px', color: '#818181' }}>
            Bulletins are useful for contacting either segments or the entirety of your volunteer database at once. Use
            them for announcements, updates, and other important information that you want to have recorded.
          </Typography>
        </Box>
        <Box mt={3}>
          <Formik initialValues={initialValues} onSubmit={(values) => prepareValuesAndSubmit(values)}>
            {({ setFieldValue, values }) => {
              if (!isVmAdmin && values.programIds.includes('all')) {
                setFieldValue('programIds', authorizedProgramIds);
              }

              const activityOptions =
                filteredAuthPrograms
                  .filter(
                    (program) => values.programIds.includes('all') || values.programIds.includes(program.programId)
                  )
                  .flatMap((program) =>
                    program.activities.map((activity) => ({
                      label: activity.name,
                      value: activity.activityId,
                    }))
                  ) || [];

              const programOptions =
                filteredAuthPrograms.map((program) => ({ label: program.name, value: program.programId })) || [];
              const roleOptions =
                vmActivityRole?.vm.activityRoles.map((activityRole) => ({
                  label: activityRole.name,
                  value: activityRole.activityRoleId,
                })) || [];

              return (
                <Form>
                  <Field
                    required
                    autoFocus
                    name='title'
                    label='Bulletin Title'
                    margin='normal'
                    fullWidth
                    variant='outlined'
                  />
                  <CampfireRichEditor
                    editorState={content}
                    setEditorState={setContent}
                    placeholder='Add content and information to this bulletin'
                  />
                  <Box display='flex' alignItems='center' mt={4}>
                    <Switch
                      checked={values.pinned}
                      onChange={() => setFieldValue('pinned', !values.pinned)}
                      color='primary'
                      name='pinned'
                      inputProps={{ 'aria-label': 'pinned checkbox' }}
                    />
                    <Box ml={0.4}>
                      <Typography
                        style={{ fontSize: '13px', color: '#000000b0', fontWeight: 500, textTransform: 'uppercase' }}
                      >
                        Pinned bulletin
                      </Typography>
                      <Typography style={{ fontSize: '12px', color: '#808080' }}>
                        Pinned bulletins will always appear at the top of the bulletin list that volunteers see.
                      </Typography>
                    </Box>
                  </Box>
                  {bulletinCanComment && (
                    <Box display='flex' alignItems='center' mt={2}>
                      <Switch
                        checked={values.isCommentable}
                        onChange={() => setFieldValue('isCommentable', !values.isCommentable)}
                        color='primary'
                        name='isCommentable'
                        inputProps={{ 'aria-label': 'isCommentable checkbox' }}
                      />
                      <Box ml={0.4}>
                        <Typography
                          style={{ fontSize: '13px', color: '#000000b0', fontWeight: 500, textTransform: 'uppercase' }}
                        >
                          Volunteers can comment
                        </Typography>
                        <Typography style={{ fontSize: '12px', color: '#808080' }}>
                          Allow volunteers to comment on this bulletin
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box mt={4} paddingLeft={'22px'}>
                    <CampfireRadioGroup
                      required
                      name={'notifyVolunteers'}
                      label={''}
                      options={[
                        {
                          value: 'doNotNotify',
                          label: (
                            <Box ml={1}>
                              <Typography
                                style={{
                                  fontSize: '13px',
                                  color: '#000000b0',
                                  fontWeight: 500,
                                  textTransform: 'uppercase',
                                }}
                              >{`Don't notify volunteers`}</Typography>
                              <Typography style={{ fontSize: '12px', color: '#808080' }}>
                                Bulletin will only be seen when volunteers login to Volaby
                              </Typography>
                            </Box>
                          ),
                        },
                        {
                          value: 'notify',
                          label: (
                            <Box ml={1} mt={1.5}>
                              <Typography
                                style={{
                                  fontSize: '13px',
                                  color: '#000000b0',
                                  fontWeight: 500,
                                  textTransform: 'uppercase',
                                }}
                              >{`Notify volunteers`}</Typography>
                              <Typography style={{ fontSize: '12px', color: '#808080' }}>
                                Bulletin will be emailed to volunteers
                              </Typography>
                            </Box>
                          ),
                        },
                      ]}
                    />
                  </Box>
                  <Grid item xs={12} style={{ padding: '30px 0' }}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: '16px', fontWeight: 700, color: '#666666' }}>
                      Who can see this bulletin?
                    </Typography>
                    <Typography style={{ fontSize: '14px', fontWeight: 400, color: '#9E9E9E', paddingBottom: '5px' }}>
                      Select programs, activities and roles below to segment and display the task to certain volunteers
                      only. <br />
                      Default settings will display the task to all volunteers.
                    </Typography>
                  </Grid>
                  <Box>
                    <Box mt={4}>
                      <Typography
                        style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '10px' }}
                      >
                        PROGRAMS
                      </Typography>
                      <MultiSelect
                        required={!isVmAdmin}
                        variant='outlined'
                        name='programIds'
                        label='Select program/s'
                        placeholder='All programs'
                        items={allItems('Programs').concat(programOptions)}
                      />
                    </Box>
                    <Box mt={4}>
                      <Typography
                        style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '10px' }}
                      >
                        ACTIVITIES
                      </Typography>
                      <MultiSelect
                        variant='outlined'
                        name='activityIds'
                        label='Select activity/activities'
                        placeholder='All activities'
                        items={allItems('Activities').concat(activityOptions)}
                      />
                    </Box>
                    <Box mt={4}>
                      <Typography
                        style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '10px' }}
                      >
                        ACTIVITY ROLES
                      </Typography>
                      <MultiSelect
                        variant='outlined'
                        name='roleIds'
                        placeholder='No roles (Visible to all)'
                        label='Select role/s'
                        items={allItems('Activity Roles').concat(roleOptions)}
                      />
                    </Box>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingTop: '0 !important',
                      paddingBottom: '0 !important',
                      marginTop: '16px',
                    }}
                  >
                    <Button
                      variant='text'
                      endIcon={showAdvanced ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      onClick={() => setShowAdvanced(!showAdvanced)}
                      style={{
                        fontSize: 10,
                        textDecoration: 'none',
                        color: theme.color.grey.neutral300,
                        textTransform: 'none',
                        padding: 0,
                      }}
                    >
                      Advanced
                    </Button>
                  </Grid>
                  {showAdvanced ? (
                    <Grid item xs={12}>
                      <Box mt={3}>
                        <Typography
                          style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '10px' }}
                        >
                          PERMISSION LEVEL
                        </Typography>
                        <Select
                          fullWidth
                          variant='outlined'
                          name='permissionLevel'
                          placeholder='No permission level (Visible to all)'
                          label='Select permission level'
                          items={permissionLevelOptions}
                          renderFunction={(item) => item.label}
                          valueFunction={(item) => item.value}
                        />
                      </Box>
                    </Grid>
                  ) : null}
                  <Box display='flex' justifyContent='space-between' mt={3}>
                    <Box display='inline-flex' alignItems='center'>
                      {activeBulletin && isVmAdmin && (
                        <>
                          <Box display='inline'>
                            <HoverText variant='body2' color='error' onClick={openConfirmDialog}>
                              Delete Bulletin
                            </HoverText>
                          </Box>
                          <Dialog
                            open={displayDialog}
                            onClose={closeConfirmDialog}
                            aria-labelledby='confirm-delete-title'
                            aria-describedby='confirm-delete-description'
                          >
                            <DialogTitle id='confirm-delete-title'>Delete this bulletin?</DialogTitle>
                            <DialogContent>
                              <DialogContentText id='confirm-delete-description'>
                                This will delete the bulletin <strong>permanently</strong>. Please confirm that you
                                would like to delete this bulletin.
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <TabletButton onClick={closeConfirmDialog} color='error'>
                                Cancel
                              </TabletButton>
                              <TabletButton
                                onClick={() => closeDialogAndDelete(activeBulletin.bulletinId)}
                                color='error'
                                autoFocus
                                variant='contained'
                              >
                                Delete Bulletin
                              </TabletButton>
                            </DialogActions>
                          </Dialog>
                        </>
                      )}
                    </Box>
                    <Box display='inline-flex' alignItems='center'>
                      <Box mr={2}>
                        <HoverText variant='body2' color='primary' onClick={cancel}>
                          Cancel
                        </HoverText>
                      </Box>
                      <TabletButton
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={isSubmitting || !canUserAddEdit}
                        size='large'
                        endIcon={<SendRounded />}
                      >
                        {activeBulletin ? 'Update Bulletin' : 'Post Bulletin'}
                      </TabletButton>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export { SaveBulletinManagement };
