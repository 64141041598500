import React, { useState } from 'react';
import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Collapse,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { unpackToDate } from '@campfire/hot-date';
import { createStyles, makeStyles } from '@material-ui/styles';

import { TimeFilters } from './TimeFilters';
import { DateFilters } from './DateFilters';
import { ActivityTypeEnum, ActivityStatusEnum } from '../roster/roster-types';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { FilterItem } from '../../../../../common/filter-fields/FilterItem';
import { ProgramsContext } from '../../../../../global/programs-sell/ProgramsContext';
import { ActivityTagSelect } from '../../../../general/activities-v2/ActivityFilter/ActivityTagSelect';
import { DaySelect } from '../../../../general/activities-v2/ActivityFilter/DaySelect';
import * as ActivitiesConsts from '../../../../general/activities-v2/ActivityConst';
import { sharedStyles } from '../../../../general/activities-v2/ActivityStyles/shared';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetAllActivityActivityTags } from '../../../../general/activities-v2/ActivityQueries/__generated__/GetAllActivityActivityTags';
import { GET_ALL_ACTIVITY_ACTIVITY_TAGS } from '../../../../general/activities-v2/ActivityQueries/get-activities-timeline.gql';
import { LARGE_ORG_DAYS_LIMIT, SMALL_ORG_DAYS_LIMIT } from '../../IndividualRosterScreen';
import { SessionContext } from '../../../../../global/auth/SessionContext';
// import {
//   ActivityFilterGetAllActivities,
//   ActivityFilterGetAllActivitiesVariables,
// } from './__generated__/ActivityFilterGetAllActivities';
// import { ACTIVITY_FILTER_GET_ALL_ACTIVITIES } from './roster-filters-model.gql';
// import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';

interface Props {
  showFilters: boolean;
  onSetQuery: (arg: any) => void;
  query: any;
}

const activityTypes = [
  {
    id: 'all',
    name: 'All activity types',
  },
  {
    id: 'flexible',
    name: 'Flexible Activities',
  },
  {
    id: 'regular',
    name: 'Regular Activities',
  },
];

const activityStatuses = [
  {
    id: 'all',
    name: 'All activities',
  },
  {
    id: 'active',
    name: 'Active activities',
  },
  {
    id: 'cancelled',
    name: 'Cancelled activities',
  },
  {
    id: 'closed',
    name: 'Closed activities',
  },
  {
    id: 'completed',
    name: 'Completed activities',
  },
  {
    id: 'ended',
    name: 'Ended activities',
  },
  {
    id: 'suspended',
    name: 'Suspended activities',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.color.white.neutral50,
      borderRadius: '6px',
    },
    daySelectContainer: {
      width: 265,
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        width: 'unset',
      },
    },
    daySelectRoot: {
      alignSelf: 'start',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'unset',
      },
    },
    label: {
      textTransform: 'uppercase',
      color: theme.color.grey.neutral300,
      fontWeight: 700,
      fontSize: '0.75rem',
    },
    itemWithBorder: {
      borderTop: 'solid 1px',
      borderTopColor: theme.color.grey.neutralBrand200,
    },
    timeBorder: {
      borderBottom: 'solid 1px',
      borderBottomColor: theme.color.grey.neutralBrand200,
    },
  })
);

export type RosterFiltersState = {
  programId: string;
  activityFilterId: string;
  activityType: ActivityTypeEnum;
  activityStatus: ActivityStatusEnum;
  volunteersNeeded: number;
  startTime?: string;
  endTime?: string;
  startDate?: string;
  endDate?: string;
  rosterType?: string;
  tags?: string[];
  days?: string[];
};

export const initFilters = (isLargeOrg?: boolean): RosterFiltersState => {
  return {
    programId: 'all',
    activityFilterId: 'all',
    activityType: 'all',
    activityStatus: 'all',
    rosterType: 'all',
    volunteersNeeded: 0,
    startTime: undefined,
    endTime: undefined,
    startDate: DateTime.local().toISODate(),
    endDate: DateTime.local()
      .plus({ days: isLargeOrg ? LARGE_ORG_DAYS_LIMIT : SMALL_ORG_DAYS_LIMIT })
      .toISODate(),
  };
};

export const RosterFilters = (props: Props) => {
  const { showFilters, onSetQuery, query } = props;
  const session = React.useContext(SessionContext);
  const isLargeOrg = session?.user?.orgInfo.isLargeOrg;
  const [rosterFilters, setRosterFilters] = useState<RosterFiltersState>({
    ...initFilters(isLargeOrg),
    ...query,
  });

  const { isMobile, theme, isMd } = useCampfireTheme();
  // This media query below is a quick hack to fix the filters overlapping layout for laptop screen size.
  // For long term, we might have to handle these breakpoints like others or do a dynamic width calculation of the parent container
  // and adjust layout accordingly
  const isXl = !useMediaQuery('(max-width:1565px)');

  const { getAuthorizedPrograms } = React.useContext(ProgramsContext);
  const programs = getAuthorizedPrograms();

  const handleChange = (filter: any) => {
    setRosterFilters((prev) => ({
      ...prev,
      ...filter,
    }));
  };

  const clearFilters = () => {
    setRosterFilters(initFilters(isLargeOrg));
    applyFilters(initFilters(isLargeOrg));
  };

  const applyFilters = (filters: RosterFiltersState) => {
    onSetQuery({
      ...filters,
      activityDate: query.activityDate,
    });
  };

  const onSelectDay = (dayValue: number) => {
    const dayString = dayValue.toString();
    setRosterFilters((prev) => ({
      ...prev,
      days: prev.days?.includes(dayString)
        ? (prev.days || []).filter((day) => day !== dayString)
        : (prev.days || []).concat(dayString),
    }));
  };

  const onSelectActivityTag = (tagIds: string[]) => {
    setRosterFilters({
      ...rosterFilters,
      tags: tagIds,
    });
  };

  // const [queryActivities, { data: allActivitiesData, loading: allActivitiesLoading }] = useCampfireLazyQuery<
  //   ActivityFilterGetAllActivities,
  //   ActivityFilterGetAllActivitiesVariables
  // >(ACTIVITY_FILTER_GET_ALL_ACTIVITIES);

  // useEffect(() => {
  //   queryActivities({
  //     variables: {
  //       programIds:
  //         programs.length > 0 && rosterFilters.programId === 'all'
  //           ? programs.flatMap((program) => program.programId)
  //           : rosterFilters.programId
  //           ? [rosterFilters.programId]
  //           : undefined,
  //     },
  //   });
  // }, [programs, rosterFilters.programId]);

  // const allActivities = allActivitiesData?.vm.programs
  //   .flatMap((program) => program.activities)
  //   .sort((a, b) => a.name.localeCompare(b.name));

  const { data: tagsData } = useCampfireQuery<GetAllActivityActivityTags, {}>(GET_ALL_ACTIVITY_ACTIVITY_TAGS);

  const activityTagOptions = React.useMemo(() => {
    return tagsData?.vm.activityTags ?? [];
  }, [tagsData]).sort((a, b) => a.name.localeCompare(b.name));

  const inputClasses = sharedStyles.textField();
  const classes = useStyles();

  return (
    <Collapse
      in={showFilters}
      style={{ marginBottom: showFilters ? '20px' : '0px', marginRight: isMd ? '0px' : '30px' }}
    >
      <Grid
        container
        direction={'column'}
        style={{
          backgroundColor: theme.color.white.neutral50,
          padding: '0px 10px',
        }}
      >
        <Grid container>
          <Grid item xs={isMobile ? 12 : isXl ? 3 : 6}>
            <FilterItem title='Program'>
              <Select
                style={{ width: '100%' }}
                value={rosterFilters.programId || 'all'}
                onChange={(e) => handleChange({ programId: e.target.value, activityFilterId: 'all' })}
                input={<OutlinedInput margin='dense' />}
              >
                <MenuItem value={'all'}>{'All programs'}</MenuItem>
                {programs
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((p) => (
                    <MenuItem value={p.programId}>{p.name}</MenuItem>
                  ))}
              </Select>
            </FilterItem>
          </Grid>
          {/* <Grid item xs={isMobile ? 12 : isXl ? 3 : 6}>
            <FilterItem title='Activity'>
              <Select
                style={{ width: '100%', position: 'relative' }}
                value={rosterFilters.activityFilterId || 'all'}
                onChange={(e) => handleChange({ activityFilterId: e.target.value })}
                input={<OutlinedInput margin='dense' />}
              >
                <MenuItem value='all'>All activities</MenuItem>
                {allActivitiesLoading ? (
                  <Box width={1} display='flex' justifyContent='center' py={2}>
                    <CircularProgress size={20} style={{ alignSelf: 'center' }} />
                  </Box>
                ) : (
                  allActivities?.map((a) => <MenuItem value={a.activityId}>{a.name}</MenuItem>)
                )}
              </Select>
            </FilterItem>
          </Grid> */}
          <Grid item xs={isMobile ? 12 : isXl ? 3 : 6}>
            <FilterItem title='Activity Types'>
              <Select
                style={{ width: '100%' }}
                value={rosterFilters.activityType || 'all'}
                onChange={(e) => handleChange({ activityType: e.target.value })}
                input={<OutlinedInput margin='dense' />}
              >
                {activityTypes?.map((a) => (
                  <MenuItem value={a.id}>{a.name}</MenuItem>
                ))}
              </Select>
            </FilterItem>
          </Grid>
          <Grid item xs={isMobile ? 12 : isXl ? 3 : 6}>
            <FilterItem title='Activity Status'>
              <Select
                style={{ width: '100%' }}
                value={rosterFilters.activityStatus || 'all'}
                onChange={(e) => handleChange({ activityStatus: e.target.value })}
                variant={'outlined'}
                input={<OutlinedInput margin='dense' />}
              >
                {activityStatuses?.map((as) => (
                  <MenuItem value={as.id}>{as.name}</MenuItem>
                ))}
              </Select>
            </FilterItem>
          </Grid>
          <Grid item xs={isMobile ? 12 : isXl ? 3 : 6}>
            <FilterItem title='Volunteers Need'>
              <TextField
                id='volunteers-needed'
                type={'number'}
                value={rosterFilters.volunteersNeeded || 0}
                variant='outlined'
                size='small'
                fullWidth
                onChange={(e) => handleChange({ volunteersNeeded: e.target.value })}
              />
            </FilterItem>
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <FilterItem title='Roster Types'>
              <TextField
                name='rosterType'
                value={rosterFilters.rosterType || 'all'}
                select
                variant='outlined'
                fullWidth
                onChange={(e) => handleChange({ rosterType: e.target.value })}
                InputProps={{ classes: inputClasses }}
              >
                {ActivitiesConsts.ROSTERTYPES_OPTIONS.map(({ value, label }) => (
                  <MenuItem key={label} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </FilterItem>
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
            <FilterItem title='Activity Tags'>
              <ActivityTagSelect
                selectedTagIds={
                  rosterFilters.tags?.reduce(
                    (acc, tagId) => ({ ...acc, [tagId]: true }),
                    {} as { [key: string]: boolean }
                  ) || {}
                }
                activityTags={activityTagOptions}
                onChange={onSelectActivityTag}
              />
            </FilterItem>
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
            <FilterItem title='Day'>
              <DaySelect
                days={ActivitiesConsts.DAY_OPTIONS}
                daysSelected={
                  rosterFilters.days?.reduce(
                    (acc, day) => ({ ...acc, [day]: true }),
                    {} as { [key: string]: boolean }
                  ) || {}
                }
                onSelectDay={onSelectDay}
                className={classes.daySelectContainer}
              />
            </FilterItem>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={isXl ? 6 : 12}>
            <FilterItem title='Time' underline>
              <TimeFilters
                startTime={rosterFilters.startTime}
                endTime={rosterFilters.endTime}
                handleChange={handleChange}
              />
            </FilterItem>
          </Grid>
          <Grid item xs={isXl ? 6 : 12}>
            <FilterItem title='Date' underline>
              <DateFilters
                startDate={rosterFilters.startDate ? unpackToDate(rosterFilters.startDate).toJSDate() : undefined}
                endDate={rosterFilters.endDate ? unpackToDate(rosterFilters.endDate).toJSDate() : undefined}
                handleChange={handleChange}
              />
            </FilterItem>
          </Grid>
        </Grid>
        <Box display={'flex'} justifyContent={isMobile ? 'center' : 'flex-start'} p={'16px'} pl={'10px'} pb={'30px'}>
          <TabletButton
            variant='contained'
            size='small'
            style={{
              backgroundColor: '#7047EB',
              color: '#ffffff',
              boxSizing: 'border-box',
              borderRadius: '4px',
              textTransform: 'none',
              marginRight: '8px',
              minWidth: '40px',
              padding: '7px 12px',
            }}
            onClick={() => applyFilters(rosterFilters)}
          >
            {'SHOW THE RESULTS'}
          </TabletButton>
          <TabletButton
            variant='contained'
            style={{
              backgroundColor: '#e6e5fc',
              color: theme.color.secondary.main900,
              boxSizing: 'border-box',
              borderRadius: '4px',
              textTransform: 'none',
              minWidth: '40px',
              padding: '7px 12px',
            }}
            onClick={clearFilters}
          >
            {'CLEAR'}
          </TabletButton>
        </Box>
      </Grid>
    </Collapse>
  );
};
