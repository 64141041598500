import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Theme, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { unpackToDate } from '@campfire/hot-date';
import { Check, Close, Undo } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';

import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { GetCompletedTask, GetCompletedTaskVariables } from './__generated__/GetCompletedTask';
import { TASK_GET_COMPLETED_TASK } from './task-completed-tasks.gql';
import { isTaskItemFieldBreak } from '../../../../common/form/task-form/TaskFormFieldList';
import { CompletedTaskItem } from '../../../../common/form/task-form/CompletedTaskViewer';
import { deserializeTaskItems } from '../../admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface SubmissionData {
  completedTaskId: string;
  status: 'approved' | 'pending' | 'rejected';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      minWidth: (props: { isSm: boolean; isMobile: boolean }) => (props.isSm ? 'auto' : '750px'),
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: (props: { isSm: boolean; isMobile: boolean }) => (props.isMobile ? 'column' : 'row'),
      justifyContent: 'space-between',
      '& .container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      '& .title': {
        color: theme.color.grey.neutral400,
        fontSize: 20,
        fontWeight: 900,
        flex: 1,
      },
      '& .buttonGroup': {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      },
      '& .button': {
        height: 30,
        minWidth: 'unset',
        textTransform: 'none',
      },
      '& .close': {
        color: theme.color.error[900],
        borderColor: theme.color.error[900],
      },
      '& .approve': {
        backgroundColor: theme.color.success[500],
        color: 'white',
      },
    },
    subTitle: {
      color: theme.color.grey.neutral400,
      fontSize: 14,
      fontWeight: 400,
    },
  })
);

interface VolunteerSubmitValuesProps {
  completedTaskId: string;
  onClose: () => void;
  onUpdate?: () => void;
}
export function VolunteerSubmitValues({ completedTaskId, onClose, onUpdate }: VolunteerSubmitValuesProps) {
  const { data, loading } = useCampfireQuery<GetCompletedTask, GetCompletedTaskVariables>(TASK_GET_COMPLETED_TASK, {
    options: {
      variables: {
        completedTaskId,
      },
    },
  });

  const taskItems = deserializeTaskItems(data?.vm.completedTask?.task.taskItems || []);
  const taskItemValues = (data?.vm.completedTask?.taskFieldValues || []).reduce(
    (acc, curr) => ({
      ...acc,
      [curr.field.taskFieldId]: curr,
    }),
    {}
  );

  const updateCompletedTaskStatus = useEndpointFetch<SubmissionData>(
    '/vm/volunteer/manage/update-completed-task-status'
  );

  const revertCompletedTask = useEndpointFetch<{ completedTaskId: string }>('/vm/volunteer/manage/revert');

  const onApprove = () => {
    updateCompletedTaskStatus
      .run({
        completedTaskId,
        status: 'approved',
      })
      .then(() => {
        onClose();
        if (onUpdate) {
          onUpdate();
        }
      });
  };

  const onRevert = () => {
    revertCompletedTask
      .run({
        completedTaskId,
      })
      .then(() => {
        onClose();
        if (onUpdate) {
          onUpdate();
        }
      });
  };

  const onButtonClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClose();
  };

  const { isMobile, isSm } = useCampfireTheme();
  const classes = useStyles({ isMobile, isSm });

  const profile = data?.vm.completedTask?.profile;

  const subTitle = `Updated on ${unpackToDate(data?.vm.completedTask?.dateSubmitted).toFormat('dd LLL yyyy')} by ${
    profile?.preferredName
  } ${profile?.lastName}`;
  return (
    <Dialog open onClose={onClose} maxWidth='lg' style={{ zIndex: 1301 }}>
      <DialogTitle>
        <Box>
          <Box className={classes.heading}>
            <Typography className='title'>
              {loading ? <Skeleton width='10rem' /> : data?.vm.completedTask?.task.title}
            </Typography>
            <Box className='buttonGroup'>
              <Button
                className='button'
                startIcon={<Undo />}
                variant='outlined'
                disableElevation
                color='secondary'
                onClick={onRevert}
              >
                Revert
              </Button>
              <Button
                className='button approve'
                startIcon={<Check />}
                variant='contained'
                disableElevation
                onClick={onApprove}
              >
                Approve
              </Button>
              <Divider orientation='vertical' style={{ height: '36px' }} />
              <Button className='button close' variant='outlined' disableElevation onClick={onButtonClose}>
                <Close fontSize='small' />
                <Typography
                  style={{
                    marginLeft: '8px',
                    display: isSm ? 'none' : 'inherit',
                  }}
                >
                  Close
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Typography className={classes.subTitle}>{loading ? <Skeleton width='20rem' /> : subTitle}</Typography>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <React.Fragment>
            <Skeleton height='10rem' />
            <Skeleton height='10rem' />
            <Skeleton height='10rem' />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {taskItems.allIds
              .filter((itemId) => !taskItems.byTriggerOption[itemId])
              .map((itemId, itemIndex, filteredItems) => {
                const isFieldBreak = isTaskItemFieldBreak(
                  taskItems.byId[itemId],
                  taskItems.byId[filteredItems[itemIndex - 1]]
                );
                return (
                  <CompletedTaskItem
                    key={itemId}
                    item={taskItems.byId[itemId]}
                    isFieldBreak={isFieldBreak}
                    taskItems={taskItems}
                    taskItemValues={taskItemValues}
                  />
                );
              })}
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
}
