import { Chip, Grid, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { capitalize } from 'lodash';
import React, { memo } from 'react';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { ActivityDayTimeIndicatorIcon } from './Activity/ActivityDayTimeIndicator';

interface MarkerProps {
  lat: number;
  lng: number;
  name: string;
  duration: string | null;
  id: string;
  isHovered: boolean;
  dayTimeIndicator?: string;
  disabled: boolean;
  setHoveredActivityId: (id: string) => void;
  onClick: () => void;
  onMouseOver: () => void;
  onFocus: () => void;
}

type StyleProps = {
  bgcolor: string;
  zIndex: number;
  color: string;
};

const useStyles = makeStyles({
  markerChip: (props: StyleProps) => ({
    boxShadow: `0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)`,
    height: 50,
    borderRadius: 50,
    background: props.bgcolor,
    color: props.color,
    maxWidth: 245,
    zIndex: props.zIndex,
    'label + &': {
      padding: '24px 16px',
    },
    '&:hover': {
      background: 'black',
      color: 'white',
    },
  }),
  arrowIcon: (props: StyleProps) => ({
    fill: props.bgcolor,
    fontSize: 30,
    marginTop: '-0.8rem',
    zIndex: props.zIndex,
  }),
});

const zIndexStylesCreator = makeStyles({
  root: (isHovered: boolean) => ({
    zIndex: isHovered ? 500 : 1,
    position: 'relative',
  }),
});

const getMarkerColors = (hovered: boolean, disabled: boolean) => {
  const { theme } = useCampfireTheme();
  if (hovered) return { bgcolor: 'black', zIndex: 100, color: '#fff' };
  if (disabled) return { bgcolor: theme.palette.grey[200], zIndex: 0, color: theme.palette.grey[900] };
  return { bgcolor: 'white', zIndex: 0, color: '#FF664D' };
};

function compareActivitiesMapMarkerProps(prev: MarkerProps, next: MarkerProps) {
  return prev.id === next.id && prev.name === next.name && prev.isHovered === next.isHovered;
}

const ActivitiesMapMarker = memo((props: MarkerProps) => {
  const { id, duration, name, isHovered, dayTimeIndicator, disabled, onClick, onMouseOver, onFocus } = props;
  const { theme } = useCampfireTheme();
  const classes = useStyles(getMarkerColors(isHovered, disabled));
  const { root: zIndexStyles } = zIndexStylesCreator(isHovered);

  return (
    <div className={zIndexStyles}>
      <Grid container data-track={`actCnlMap-MapMarker-${id}`}>
        <Grid
          item
          xs
          container
          justify='center'
          alignItems='center'
          style={{ position: 'absolute', transform: 'translate(0%, -100%)' }}
        >
          <Chip
            className={classes.markerChip}
            style={{ pointerEvents: 'all' }}
            onClick={() => onClick()}
            onMouseOver={() => onMouseOver()}
            onFocus={() => onFocus()}
            label={
              <Grid container direction='row' wrap='nowrap' spacing={0}>
                <Grid
                  item
                  container
                  alignItems='center'
                  spacing={0}
                  style={{ maxWidth: 190, paddingLeft: 8, paddingRight: 8 }}
                >
                  <Typography variant='body2' display='block' noWrap style={{ fontWeight: 500 }}>
                    {name}
                  </Typography>
                  <Typography
                    variant='body2'
                    style={{ color: isHovered ? 'white' : theme.palette.grey[800] }}
                    display='block'
                  >
                    {duration ? capitalize(duration) : null}
                  </Typography>
                </Grid>
                <Grid item container alignItems='center' style={{ paddingLeft: 4, paddingRight: 4 }}>
                  <ActivityDayTimeIndicatorIcon indicator={dayTimeIndicator} />
                </Grid>
              </Grid>
            }
          />
          <ArrowDropDown className={classes.arrowIcon} />
        </Grid>
      </Grid>
    </div>
  );
}, compareActivitiesMapMarkerProps);

const useSkinnyStyles = makeStyles(() =>
  createStyles({
    markerChip: {
      boxShadow: `0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)`,
      height: 32,
      borderRadius: 32,
      background: '#ffffff',
      color: '#282828',
      zIndex: 1,
      maxWidth: 200,
      'label + &': {
        padding: '24px 16px',
      },
      '&:hover': {
        background: '#e9e9e9',
      },
    },
    arrowIcon: {
      fill: '#ffffff',
      fontSize: 30,
      marginTop: '-0.8rem',
      zIndex: 1,
    },
  })
);

interface ActivitiesSkinnyMapMarkerProps {
  lat: number;
  lng: number;
  name: string;
  id: string;
  onClick: () => void;
}

const ActivitiesSkinnyMapMarker = memo((props: ActivitiesSkinnyMapMarkerProps) => {
  const { name, id, onClick } = props;
  const classes = useSkinnyStyles();

  return (
    <Grid container data-track={`actCnlMap-MapMarker-${id}`}>
      <Grid
        item
        xs
        container
        justify='center'
        alignItems='center'
        style={{ position: 'absolute', transform: 'translate(0%, -100%)' }}
      >
        <Chip className={classes.markerChip} style={{ pointerEvents: 'all' }} onClick={() => onClick()} label={name} />

        <ArrowDropDown className={classes.arrowIcon} />
      </Grid>
    </Grid>
  );
});

export { ActivitiesMapMarker, ActivitiesSkinnyMapMarker };
