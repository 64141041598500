import { HoverLink, HoverText, HoverTextProps } from '@campfire/hover-link';
import { Box, Card, CardActionArea, CardContent, Chip, Theme, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { unpackToDate } from '@campfire/hot-date';
import React, { memo, ReactElement, ReactNode, useEffect } from 'react';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { Avatar, AvatarProps as AvatarPropsInterface } from '../components/Avatar';
import { ProfileStatusBrick } from '../profile-status/ProfileStatusBrick';
import { GET_PROFILE_HOVER_CARD } from './profile-hover-card-model.gql';
import {
  GetProfileHoverCard,
  GetProfileHoverCardVariables,
  GetProfileHoverCard_vm_volunteer_activityRoles as GetProfileHoverCardActivityRoles,
} from './__generated__/GetProfileHoverCard';

const useTooltipStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      backgroundColor: 'transparent',
      width: 256,
    },
  })
);

const useBioStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      maxHeight: '3.75rem',
      overflow: 'hidden',
      '&::after': {
        content: '""',
        textAlign: 'right',
        position: 'absolute',
        bottom: '0',
        right: '0',
        width: '50%',
        height: '1.25rem',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%)',
      },
    },
  })
);

const useNameOverlayStyles = makeStyles((_unusedtheme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      paddingLeft: 16,
      paddingBottom: 12,
      margin: 0,
      width: '100%',
      background: 'linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 1) 85%)',
    },
    nameText: {
      fontWeight: 'bold',
      color: '#ffffff',
    },
    roleText: {
      marginTop: -4,
      fontWeight: 'bold',
      color: '#ffffff',
    },
  })
);

type HoverCardProps = {
  aboutMe?: string;
  contactNumber?: string;
  email?: string;
  activityRoles?: Array<GetProfileHoverCardActivityRoles>;
  emoji?: string;
  hasRunQuery: boolean;
  isLoading: boolean;
  runQuery: () => void;
  text?: string;
  dateCreated?: string;
} & ProfileHoverBlockProps;

const HoverCard = (props: HoverCardProps) => {
  const {
    aboutMe,
    avatarUrl,
    contactNumber,
    email,
    emoji,
    onClick,
    hasRunQuery,
    activityRoles,
    isLoading,
    lastName,
    preferredName,
    runQuery,
    text,
    dateCreated,
  } = props;
  const displayText = `${preferredName} ${lastName}`;
  const bioClasses = useBioStyles();
  const nameOverlayClasses = useNameOverlayStyles();

  useEffect(() => {
    if (!hasRunQuery) runQuery();
  }, []);

  return (
    <Card elevation={8} style={{ position: 'relative', minHeight: 256 }}>
      <CardActionArea disabled={onClick === undefined} onTouchStart={onClick} onMouseDown={onClick}>
        <Avatar
          size={196}
          preferredName={preferredName}
          lastName={lastName}
          avatarUrl={avatarUrl}
          style={{ borderRadius: 0, width: 256, height: 196 }}
        />
        <div className={nameOverlayClasses.container}>
          <Typography variant='h6' className={nameOverlayClasses.nameText} component='h2'>
            {displayText}
          </Typography>
          <Typography variant='body2' className={nameOverlayClasses.roleText} component='h2'>
            {'Volunteer'}
          </Typography>
        </div>
      </CardActionArea>

      <CardContent style={{ position: 'relative' }}>
        {dateCreated ? (
          <Typography variant='body2'>{`Joined ${unpackToDate(dateCreated).toFormat('dd MMM yyyy')}`}</Typography>
        ) : null}

        {isLoading ? (
          <Box>
            <Skeleton height={21} width='75%' />
          </Box>
        ) : null}

        <HoverLink external to={`mailto:${email}`} color='primary' hoverColor='primary'>
          <Typography variant='body2'>{email}</Typography>
        </HoverLink>
        <Typography variant='body2'>{contactNumber}</Typography>

        {activityRoles ? (
          <Box marginTop={2} marginBottom={2}>
            <Typography variant='caption' color='textSecondary'>
              Roles
            </Typography>
            <Box>
              {activityRoles.map((a) => {
                return (
                  <Chip
                    label={a.name}
                    style={{
                      borderRadius: '5px',
                      fontSize: '11px',
                      color: '#545454',
                      marginRight: '3px',
                      marginBottom: '3px',
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        ) : (
          <Box marginTop={1} marginBottom={1}>
            <Typography variant='caption' color='textSecondary'>
              {isLoading ? null : 'No status set'}
            </Typography>
          </Box>
        )}

        {emoji || text ? (
          <Box marginTop={2} marginBottom={2}>
            <Typography variant='caption' color='textSecondary'>
              Status
            </Typography>
            <ProfileStatusBrick emoji={emoji} text={text} />
          </Box>
        ) : (
          <Box marginTop={1} marginBottom={1}>
            <Typography variant='caption' color='textSecondary'>
              {isLoading ? null : 'No status set'}
            </Typography>
          </Box>
        )}

        {isLoading ? (
          <Box>
            <Skeleton height={21} />
            <Skeleton height={21} width={'70%'} />
          </Box>
        ) : (
          <Box className={bioClasses.container}>
            <Typography variant='body2' style={{ margin: 0 }}>
              {aboutMe}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

type HoverCardTooltipProps = {
  children: ReactElement;
  runQuery: () => void;
  isLoading: boolean;
  isCalled: boolean;
  data: GetProfileHoverCard | undefined;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
} & ProfileHoverBlockProps;

export const HoverCardTooltip = (props: HoverCardTooltipProps) => {
  const { children, runQuery, isLoading, isCalled, data, open, onOpen, onClose, ...rest } = props;
  const classes = useTooltipStyles();

  const statusText = data?.vm.profile?.statusText ?? undefined;
  const statusEmoji = data?.vm.profile?.statusEmoji ?? undefined;
  const aboutMe = data?.vm.profile?.aboutMe ?? undefined;
  const activityRoles = data?.vm.volunteer?.activityRoles;

  return (
    <Tooltip
      interactive
      enterDelay={400}
      enterNextDelay={400}
      leaveDelay={100}
      disableTouchListener
      TransitionProps={{ timeout: { enter: 100, exit: 0 } }}
      placement='top'
      classes={classes}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title={
        <HoverCard
          aboutMe={isLoading ? undefined : aboutMe}
          emoji={isLoading ? undefined : statusEmoji}
          text={isLoading ? undefined : statusText}
          contactNumber={data?.vm.profile?.contactNumber ?? undefined}
          email={data?.vm.profile?.email ?? undefined}
          activityRoles={isLoading ? undefined : activityRoles}
          dateCreated={data?.vm.profile?.dateCreated ?? undefined}
          hasRunQuery={isCalled}
          isLoading={isLoading}
          runQuery={runQuery}
          {...rest}
        />
      }
    >
      {children}
    </Tooltip>
  );
};

type ProfileHoverAvatarProps = {
  AvatarProps?: AvatarPropsInterface;
} & ProfileHoverBlockProps;

const ProfileHoverAvatar = (props: ProfileHoverAvatarProps) => {
  const [runHoverCardQuery, hoverCardQuery] = useCampfireLazyQuery<GetProfileHoverCard, GetProfileHoverCardVariables>(
    GET_PROFILE_HOVER_CARD
  );

  function runQuery() {
    runHoverCardQuery({ variables: { profileId: props.profileId } });
  }
  return (
    <HoverCardTooltip
      isLoading={hoverCardQuery.loading}
      isCalled={props.alwaysReload ? false : hoverCardQuery.called}
      data={hoverCardQuery.data}
      runQuery={runQuery}
      {...props}
    >
      <div>
        <Avatar
          size={32}
          preferredName={props.preferredName}
          lastName={props.lastName}
          avatarUrl={props.avatarUrl}
          {...props.AvatarProps}
        />
      </div>
    </HoverCardTooltip>
  );
};

export interface ProfileHoverBlockProps {
  avatarUrl?: string | null;
  hoverTextProps?: HoverTextProps;
  lastName: string;
  preferredName: string;
  profileId: string;
  userId: string;
  onClick?: () => void;
  secondaryText?: ReactNode;
  alwaysReload?: boolean;
}

const ProfileHoverBlock = memo((props: ProfileHoverBlockProps) => {
  const { alwaysReload, onClick, hoverTextProps, lastName, preferredName, secondaryText } = props;
  const displayText = `${preferredName} ${lastName}`;

  const [runHoverCardQuery, hoverCardQuery] = useCampfireLazyQuery<GetProfileHoverCard, GetProfileHoverCardVariables>(
    GET_PROFILE_HOVER_CARD
  );

  function runQuery() {
    runHoverCardQuery({ variables: { profileId: props.profileId } });
  }

  return (
    <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center' overflow={'hidden'}>
      <Box marginRight={2}>
        <HoverCardTooltip
          isLoading={hoverCardQuery.loading}
          isCalled={alwaysReload ? false : hoverCardQuery.called}
          data={hoverCardQuery.data}
          runQuery={runQuery}
          {...props}
        >
          <div>
            <Avatar
              size={32}
              preferredName={props.preferredName}
              lastName={props.lastName}
              avatarUrl={props.avatarUrl}
            />
          </div>
        </HoverCardTooltip>
      </Box>

      <Box overflow='hidden'>
        <HoverCardTooltip
          isLoading={hoverCardQuery.loading}
          isCalled={hoverCardQuery.called}
          data={hoverCardQuery.data}
          runQuery={runQuery}
          {...props}
        >
          <div style={{ overflow: 'hidden', width: '100%' }}>
            <HoverText onClick={onClick} variant='body1' {...hoverTextProps}>
              {displayText}
            </HoverText>
          </div>
        </HoverCardTooltip>

        <Typography variant='body2' color='textSecondary'>
          {secondaryText}
        </Typography>
      </Box>
    </Box>
  );
});

export { ProfileHoverBlock, ProfileHoverAvatar };
