import React, { useMemo } from 'react';
import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Dialog, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { Close, ExpandMore } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import { initCompletedTaskFormFormikValues } from '../../../../../common/form/task-form/model/task-form-init';
import { TaskFormFieldList } from '../../../../../common/form/task-form/TaskFormFieldList';
import { getTaskFieldValues } from '../../../../../global/applicant-shell/model/suyp-task-submission-handler';
import { BypassTaskSubmissionData, CompletedTaskSubmissionData } from '../../../../../global/applicant-shell/SUYPTask';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useEndpointFetch } from '../../../../../global/network/useEndpointFetch';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { deserializeTaskItems } from '../../../../admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';
import { GET_VOLUNTEER_ADD_TASKS_SECTION_TASK } from './volunteer-add-tasks-section-model.gql';
import {
  GetVolunteerAddTasksSectionTask,
  GetVolunteerAddTasksSectionTaskVariables,
} from './__generated__/GetVolunteerAddTasksSectionTask';
import { useSharedVolunteerAdditionalTasksSectionState } from './VolunteerAdditionalTasksSection';

interface VolunteerTaskAddDialogProps {
  taskId: string;
  userId: string;
  profileId: string;
  open: boolean;
  onClose: () => void;
  refetch?: () => void;
}

export const VolunteerTaskAddDialog = (props: VolunteerTaskAddDialogProps) => {
  const { taskId, profileId, userId, open, onClose, refetch } = props;
  const { setSnackbar } = useSnackbar();
  const { theme } = useCampfireTheme();
  const { setSelectedAdditionalTask, setRefetchAdditionalTasks } = useSharedVolunteerAdditionalTasksSectionState();
  const [taskStatusMenuAnchorEl, setTaskStatusMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleCloseTaskStatusMenu = () => {
    setTaskStatusMenuAnchorEl(null);
  };

  const handleOpenTaskStatusMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTaskStatusMenuAnchorEl(event.currentTarget);
  };

  const tasksQuery = useCampfireQuery<GetVolunteerAddTasksSectionTask, GetVolunteerAddTasksSectionTaskVariables>(
    GET_VOLUNTEER_ADD_TASKS_SECTION_TASK,
    {
      options: {
        variables: {
          taskId,
          profileId,
          userId,
        },
      },
    }
  );

  const saveCompletedTask = useEndpointFetch<CompletedTaskSubmissionData>(
    '/vm/volunteer/profile/completed-task/update'
  );
  const bypassCompletedTask = useEndpointFetch<BypassTaskSubmissionData>('/vm/volunteer/profile/completed-task/bypass');

  const profile = useMemo(() => {
    return tasksQuery.data?.vm.profile ?? undefined;
  }, [tasksQuery.data]);

  const taskItems = useMemo(() => {
    if (!tasksQuery.data) return [];
    return tasksQuery.data.vm.task?.taskItems ?? [];
  }, [tasksQuery.data]);

  const task = useMemo(() => {
    if (!tasksQuery.data) return undefined;
    return tasksQuery.data.vm.task;
  }, [tasksQuery.data]);

  const submitTaskForm = (data: CompletedTaskSubmissionData) => {
    saveCompletedTask.run(data).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to save task',
          variant: 'error',
        });
        return;
      }

      setSnackbar({
        open: true,
        message: 'Task saved',
        variant: 'success',
      });
      if (tasksQuery.refetch) tasksQuery.refetch();
      if (refetch) {
        refetch();
      }

      // onClose();
      setRefetchAdditionalTasks(true);
      setSelectedAdditionalTask(undefined);
    });
  };

  const bypassTask = (data: BypassTaskSubmissionData) => {
    bypassCompletedTask.run(data).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to bypass task',
          variant: 'error',
        });
        return;
      }

      setSnackbar({
        open: true,
        message: 'Task successfully bypassed',
        variant: 'success',
      });
      if (tasksQuery.refetch) tasksQuery.refetch();
      if (refetch) {
        refetch();
      }
      // onClose();
      setRefetchAdditionalTasks(true);
      setSelectedAdditionalTask(undefined);
    });
  };

  const initialFormikValues = useMemo(() => {
    if (taskItems.length === 0) return undefined;
    return initCompletedTaskFormFormikValues(taskItems, []);
  }, [taskItems, null]);

  const parsedTaskItems = deserializeTaskItems(taskItems);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Box
        paddingX={2}
        paddingY={2}
        bgcolor='#f6f8f9'
        maxWidth={720}
        flex='1 1 auto'
        minHeight={480}
        position='relative'
      >
        <CircularProgressOverlay isLoading={tasksQuery.loading} />
        {task ? (
          <Grid container>
            <Grid item xs={8}>
              <Typography variant='h5' style={{ fontWeight: 'bolder' }}>
                {task.title}
              </Typography>
              <Typography variant='subtitle1' color='textSecondary'>
                {task.description}
              </Typography>
            </Grid>
            <Grid container item xs={4} justify='flex-end'>
              <Grid item>
                <TabletButton
                  // data-track='actCnl-overview-status-dropdown'
                  size='small'
                  variant='outlined'
                  color='primary'
                  aria-controls='task-status-menu'
                  aria-haspopup='true'
                  onClick={handleOpenTaskStatusMenu}
                  endIcon={<ExpandMore />}
                  style={{
                    border: '1px solid #9e9e9e',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    padding: '2px 7px',
                    color: theme.color.grey.neutral300,
                    marginRight: '8px',
                    marginTop: '5px',
                  }}
                >
                  {'Options'}
                </TabletButton>
                <TabletButton
                  // data-track='actCnl-overview-status-dropdown'
                  size='small'
                  variant='outlined'
                  color='primary'
                  aria-controls='task-status-menu'
                  aria-haspopup='true'
                  onClick={() => onClose()}
                  startIcon={<Close />}
                  style={{
                    border: '1px solid #d93a00',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    padding: '2px 7px',
                    color: theme.color.error[900],
                    marginRight: '8px',
                    marginTop: '5px',
                  }}
                >
                  {'Close'}
                </TabletButton>
                <Menu
                  id='task-status-menu'
                  anchorEl={taskStatusMenuAnchorEl}
                  keepMounted
                  open={Boolean(taskStatusMenuAnchorEl)}
                  onClose={handleCloseTaskStatusMenu}
                >
                  <MenuItem
                    // data-track='actCnl-overview-status-dropdown-item-suspend'
                    onClick={() => {
                      bypassTask({
                        profileId: profileId,
                        taskId: task.taskId,
                        status: 'not-applicable',
                      });
                    }}
                  >
                    {'Mark as Not Applicable'}
                  </MenuItem>
                  <MenuItem
                    // data-track='actCnl-overview-status-dropdown-item-close'
                    onClick={() => {
                      bypassTask({
                        profileId: profileId,
                        taskId: task.taskId,
                        status: 'admin-approved',
                      });
                    }}
                  >
                    {'Mark as Complete'}
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        <br />

        {initialFormikValues ? (
          <Box marginBottom={2}>
            <Formik
              initialValues={initialFormikValues}
              onSubmit={async (values) => {
                const taskFieldValues = await getTaskFieldValues(parsedTaskItems, values);
                const data = {
                  userId: profile?.userId ?? '',
                  taskId: task?.taskId ?? '',
                  taskFieldValues,
                };

                submitTaskForm(data);
              }}
            >
              {({ values }) => (
                <Form>
                  <TaskFormFieldList taskItems={parsedTaskItems} values={values} />
                  <Box width={1} display='flex' justifyContent='flex-end'>
                    <Box width={720} display='flex' justifyContent='flex-end'>
                      <TabletButton variant='text' onClick={onClose}>
                        Cancel
                      </TabletButton>
                      <TabletButton color='primary' variant='contained' type='submit'>
                        Submit
                      </TabletButton>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        ) : null}
      </Box>
    </Dialog>
  );
};
