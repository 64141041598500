import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { isEqual } from 'lodash';
import { connect, Form, Formik, FormikConfig, FormikFormProps, FormikErrors, FormikProps, FormikHelpers } from 'formik';
import { SnackbarContextProps } from '../../global/config/SnackbarContext';

interface LowerFormConfig {
  serverFormikErrors: FormikErrors<any> | undefined;
  onSubmit: (values: any, formikActions: FormikHelpers<any>) => void;
}
type LowerFormProps = LowerFormConfig & FormikFormProps;

const LowerForm = connect<LowerFormProps>(
  ({
    serverFormikErrors,
    onSubmit,
    children,
    formik: {
      setTouched,
      errors,
      values,
      setStatus,
      setErrors,
      setSubmitting,
      setValues,
      setFieldValue,
      setFieldError,
      setFieldTouched,
      validateForm,
      validateField,
      resetForm,
      submitForm,
      setFormikState,
    },
  }) => {
    useEffect(() => {
      setTouched({});
      if (!serverFormikErrors) return;
      setTouched(serverFormikErrors);
    }, [serverFormikErrors]);

    return (
      <Form
        onSubmitCapture={() => {
          /**
           * Here we are forcing a submit to run in the event that there are ONLY postFormFormikErrors
           * on the form. The _.isEqual(...) comparison will fail if Formik has placed resolved any
           * additional errors i.e. schemaValidation errors
           */
          if (!serverFormikErrors || !isEqual(serverFormikErrors, errors)) return;
          onSubmit(values, {
            setStatus,
            setErrors,
            setSubmitting,
            setTouched,
            setValues,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            validateForm,
            validateField,
            resetForm,
            submitForm,
            setFormikState,
          });
        }}
      >
        {children}
      </Form>
    );
  }
);

interface AutoFormikConfig {
  serverFormikErrors: FormikErrors<any> | undefined;
  children: (props: FormikProps<any>) => any;
}

type AutoFormikProps = AutoFormikConfig & FormikConfig<any>;

export const AutoFormik = ({
  serverFormikErrors,
  children,
  initialValues,
  onSubmit,
  validationSchema,
}: AutoFormikProps) => {
  return (
    <>
      <Formik
        validate={serverFormikErrors ? () => serverFormikErrors : undefined}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<typeof initialValues>) => {
          return (
            <LowerForm onSubmit={onSubmit} serverFormikErrors={serverFormikErrors}>
              {children(props)}
            </LowerForm>
          );
        }}
      </Formik>
    </>
  );
};

interface AutoFormikButtonConfig {
  serverFormikErrors?: any;
  setSnackbar: Dispatch<SetStateAction<SnackbarContextProps | undefined>>;
}
type AutoFormikButtonProps = AutoFormikButtonConfig;

export const AutoFormikButton = connect<AutoFormikButtonProps>(
  ({ serverFormikErrors, setSnackbar, formik: { errors }, children }) => {
    useEffect(() => {
      if (!serverFormikErrors) return;
      setSnackbar({
        open: true,
        message: 'Application form is incomplete',
        variant: 'error',
      });
    }, [serverFormikErrors]);
    return (
      <div
        onClick={() => {
          if (serverFormikErrors && serverFormikErrors !== {}) return;
          if (!errors || isEqual(errors, {})) return;
          setSnackbar({
            open: true,
            message: 'Application form is incomplete',
            variant: 'error',
          });
        }}
      >
        {children}
      </div>
    );
  }
);
