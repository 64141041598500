import { gql } from '@apollo/client';
import { TASK_FORM_ITEM_FRAGMENT } from '../../../../common/form/task-form/model/task-items.gql';

export const TASK_GET_COMPLETED_TASKS = gql`
  query GetCompletedTasks($taskId: String, $limit: Int, $offset: Int, $query: String) {
    vm {
      task(taskId: $taskId) {
        taskId
        title
        pendingApprovals(limit: $limit, offset: $offset, query: $query) {
          dateRemoved
          completedTaskId
          dateLastUpdated
          profile {
            profileId
            lastName
            preferredName
            avatarUrl
            application {
              applicationId
            }
          }
        }
        metadata(query: $query) {
          totalPendingApprovals
        }
      }
    }
  }
`;

export const TASK_GET_COMPLETED_TASK = gql`
  query GetCompletedTask($completedTaskId: String) {
    vm {
      completedTask(completedTaskId: $completedTaskId) {
        task {
          taskId
          title
          description
          cake {
            title
          }
          taskItems {
            ...TaskFormItem
          }
        }
        profile {
          preferredName
          lastName
        }
        dateSubmitted
        taskFieldValues {
          __typename
          taskFieldValueId
          field {
            taskFieldId
          }
          ... on VOLUNTEER_AddressTaskFieldValueType {
            address {
              placesAddress {
                placesId
                formatted
                description
                latLong {
                  lat
                  long
                }
              }
              manualAddress {
                lineOne
                lineTwo
                suburb
                state
                postcode
                country
                humanReadable
              }
            }
          }
          ... on VOLUNTEER_AttachmentTaskFieldValueType {
            attachments {
              attachmentId
              fileType
              url
              name
            }
          }
          ... on VOLUNTEER_CheckboxTaskFieldValueType {
            otherText
            checkboxTaskFieldOptions {
              checkboxFieldOptionId
              name
              allowText
              order
            }
          }
          ... on VOLUNTEER_DropdownTaskFieldValueType {
            otherText
            dropdownTaskFieldOption {
              dropdownFieldOptionId
              name
              allowText
              order
            }
          }
          ... on VOLUNTEER_PhoneTaskFieldValueType {
            contactNumber
          }
          ... on VOLUNTEER_ShortTextTaskFieldValueType {
            textValue
          }
          ... on VOLUNTEER_DateTaskFieldValueType {
            dateValue
          }
          ... on VOLUNTEER_MultipleChoiceTaskFieldValueType {
            otherText
            multipleChoiceTaskFieldOption {
              multipleChoiceFieldOptionId
              name
              allowText
              order
            }
          }
          ... on VOLUNTEER_QuizTaskFieldValueType {
            otherText
            quizTaskFieldOption {
              quizTaskFieldOptionId
              name
              allowText
              order
            }
          }

          ... on VOLUNTEER_EmailTaskFieldValueType {
            email
          }

          ... on VOLUNTEER_LongTextTaskFieldValueType {
            textValue
          }
          ... on VOLUNTEER_NumberTaskFieldValueType {
            numericValue
          }
          ... on VOLUNTEER_SignatureTaskFieldValueType {
            signatureUrl
          }
        }
      }
    }
  }
  ${TASK_FORM_ITEM_FRAGMENT}
`;