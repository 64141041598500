import { encodeDate, unpackToDate } from '@campfire/hot-date';
import { Grid } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useUser } from '../../../../../global/auth/useUser';
import { GET_USER_PAGE_INTERACTIONS } from '../../../../../global/interactions/interactions-model.gql';
import { recordInteraction } from '../../../../../global/interactions/recordInteraction';
import {
  GetUserPageInteractions,
  GetUserPageInteractionsVariables,
} from '../../../../../global/interactions/__generated__/GetUserPageInteractions';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { useOnboardingContext } from '../../../../../global/onboarding/useOnboardingContext';
import { useScreenControl } from '../../../../../global/screen-control/useScreenControlContext';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { MyActivitiesTutorialDialog } from '../../../my-activities/MyActivitiesTutorialDialog';
import { WeeklyDatePicker } from '../../WeeklyDatePicker';
import {
  GET_MY_ELEMENTS_UPCOMING_ROSTERS,
  KeyValuedUpcomingRosters,
  MyElementsActivityReport,
  MyElementsAttendance,
  MyElementsUpcomingRosterUnpackedDate,
} from './my-elements-upcoming-rosters-model.gql';
import { MyElementsUpcomingRostersLegend } from './MyElementsUpcomingRostersLegend';
import { MyElementsUpcomingRostersList } from './MyElementsUpcomingRostersList';
import {
  GetMyElementsUpcomingRosters,
  GetMyElementsUpcomingRostersVariables,
} from './__generated__/GetMyElementsUpcomingRosters';

export const MY_ACTIVITIES_PAGE = 'my-activities';

export const UPCOMING_ROSTERS_VIEW_TUTORIAL_ACTION = 'upcoming-rosters-viewed-tutorial';

interface FilterUpcomingRostersProps {
  iterations: number;
  startDate: DateTime;
}

export const MyElementsUpcomingRostersCard = ({ activityId }: { activityId?: string }) => {
  // ------------------------------------------------------------------------------------------------------------------------------
  // Data
  // ------------------------------------------------------------------------------------------------------------------------------

  const { getVolunteerIdentity } = useUser();
  const volunteerIdentity = getVolunteerIdentity();

  const [fetchRosters, { data: upcomingRostersQuery, refetch, loading }] = useCampfireLazyQuery<
    GetMyElementsUpcomingRosters,
    GetMyElementsUpcomingRostersVariables
  >(GET_MY_ELEMENTS_UPCOMING_ROSTERS);

  const [
    run,
    {
      data: upcomingRostersInteractionsQuery,
      refetch: refetchUpcomingRostersInteractions,
      loading: upcomingRostersInteractionsLoading,
      refetchLoading: upcomingRostersInteractionsRefetchLoading,
    },
  ] = useCampfireLazyQuery<GetUserPageInteractions, GetUserPageInteractionsVariables>(GET_USER_PAGE_INTERACTIONS);

  // this is a massive hack to prevent the rosters component from shrinking while loading, to maintain scroll position
  const rootRef = useRef<HTMLInputElement>(null);
  const [savedHeight, setSavedHeight] = useState<number | undefined>(undefined);
  useLayoutEffect(() => {
    if (!loading && rootRef?.current) {
      const measuredHeight = rootRef?.current.getBoundingClientRect().height;
      setSavedHeight(measuredHeight);
    }
  });

  const { userIdentity } = useUser();
  const { isMobile } = useCampfireTheme();
  const { currentOnboardingStep } = useOnboardingContext();
  const { screenInUse } = useScreenControl();
  const { postInteraction } = recordInteraction('vm');

  const [date, setDate] = useState(DateTime.local());

  function goToNextDatePeriod() {
    setDate(date.endOf('month').plus({ days: 1 }));
  }

  function goToPreviousDatePeriod() {
    setDate(date.startOf('month').minus({ months: 1 }));
  }

  // ------------------------------------------------------------------------------------------------------------------------------
  // Model
  // ------------------------------------------------------------------------------------------------------------------------------

  const monthStart = useMemo(() => {
    const start = date.startOf('month');
    start.minus({ days: 7 });
    return start;
  }, [date]);

  const monthEnd = useMemo(() => {
    const end = date.endOf('month');
    return end.plus({ days: 7 });
  }, [date]);

  const isInteractionsLoading = upcomingRostersInteractionsLoading || upcomingRostersInteractionsRefetchLoading;

  const userInteractions = useMemo(() => {
    if (!upcomingRostersInteractionsQuery || isInteractionsLoading) {
      return undefined;
    }
    return upcomingRostersInteractionsQuery.vm.userInteractions;
  }, [upcomingRostersInteractionsQuery, isInteractionsLoading]);

  const isPreApplicationOnboardingChecklistStep = currentOnboardingStep <= 1;

  const hasViewedTutorial = useMemo(() => {
    if (!userInteractions || isPreApplicationOnboardingChecklistStep) return true;
    return !!userInteractions.find(({ action }) => action === UPCOMING_ROSTERS_VIEW_TUTORIAL_ACTION);
  }, [userInteractions, isPreApplicationOnboardingChecklistStep]);

  const [tutorialDialogOpen, setTutorialDialogOpen] = useState(false);

  useEffect(() => {
    if (screenInUse || isInteractionsLoading) {
      setTutorialDialogOpen(false);
      return;
    }
    setTutorialDialogOpen(!hasViewedTutorial && currentOnboardingStep >= 3);
  }, [screenInUse, isInteractionsLoading, hasViewedTutorial, currentOnboardingStep]);

  useEffect(() => {
    if (screenInUse) return;
    if (!upcomingRostersInteractionsQuery) {
      run({
        variables: {
          userId: userIdentity.userId,
          page: MY_ACTIVITIES_PAGE,
        },
      });
    }
  }, [screenInUse]);

  useEffect(() => {
    fetchRosters({
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
        dateBegin: encodeDate(monthStart),
        dateEnd: encodeDate(monthEnd),
      },
    });
  }, [monthStart.month, monthEnd.month]);

  const attendances: MyElementsAttendance[] = useMemo(() => {
    if (!upcomingRostersQuery?.vm?.volunteer?.attendances) return [];
    return upcomingRostersQuery.vm.volunteer.attendances.map((attendance) => {
      return {
        activityId: attendance.sessionReport.activityReport.activity.activityId,
        activityDate: unpackToDate(attendance.sessionReport.activityReport.activityDate),
      };
    });
  }, [upcomingRostersQuery]);

  const activityReports: MyElementsActivityReport[] = useMemo(() => {
    if (!upcomingRostersQuery?.vm.volunteer?.activityEnrolments || !upcomingRostersQuery.vm.volunteer.rosterings)
      return [];

    const reportsFromEnrolments = upcomingRostersQuery.vm.volunteer.activityEnrolments.flatMap((enrolment) => {
      const reports = enrolment.activity.activityReports;
      return reports.map((activityReport) => ({
        activityId: enrolment.activity.activityId,
        activityDate: unpackToDate(activityReport.activityDate),
        completed:
          activityReport.sessionReports.length !== 0 &&
          activityReport.sessionReports.length ===
            enrolment.activity.sessions.length - activityReport.cancelledSessions.length,
      }));
    });

    const reportsFromRosterings = upcomingRostersQuery.vm.volunteer.rosterings.flatMap((rostering) => {
      const reports = rostering.publishedRoster.activity.activityReports;
      return reports
        .filter((activityReport) => rostering.publishedRoster.activityDate === activityReport.activityDate)
        .map((activityReport) => ({
          activityId: rostering.publishedRoster.activity.activityId,
          activityDate: unpackToDate(activityReport.activityDate),
          completed:
            activityReport.sessionReports.length !== 0 &&
            activityReport.sessionReports.length ===
              rostering.publishedRoster.activity.sessions.length - activityReport.cancelledSessions.length,
        }));
    });

    return [...reportsFromEnrolments, ...reportsFromRosterings];
  }, [upcomingRostersQuery?.vm.volunteer?.activityEnrolments, upcomingRostersQuery?.vm.volunteer?.rosterings]);

  const unsortedUpcomingRosters: Array<MyElementsUpcomingRosterUnpackedDate> = useMemo(() => {
    if (!upcomingRostersQuery?.vm.volunteer?.upcomingRosters) return [];

    return upcomingRostersQuery?.vm.volunteer?.upcomingRosters
      .filter((upcomingRoster) => (activityId ? upcomingRoster.activity.activityId === activityId : upcomingRoster))
      .filter(
        (upcomingRoster) =>
          upcomingRoster.activityDate.substr(0, 10) >= encodeDate(monthStart) &&
          upcomingRoster.activityDate.substr(0, 10) <= encodeDate(monthEnd)
      )
      .map((upcomingRoster) => {
        return {
          ...upcomingRoster,
          activityDate: unpackToDate(upcomingRoster.activityDate),
        };
      });
  }, [monthStart, monthEnd, upcomingRostersQuery]);

  const monthlyUpcomingRosters: KeyValuedUpcomingRosters | undefined = useMemo(() => {
    return filterUpcomingRosters({
      iterations: 45,
      startDate: monthStart,
    });
  }, [unsortedUpcomingRosters]);

  const viewableUpcomingRosters = useMemo(() => {
    return monthlyUpcomingRosters;
  }, [unsortedUpcomingRosters]);

  function filterUpcomingRosters(props: FilterUpcomingRostersProps): KeyValuedUpcomingRosters | undefined {
    if (!upcomingRostersQuery) {
      return undefined;
    }

    const { iterations, startDate } = props;
    const dates: KeyValuedUpcomingRosters = {};

    for (let i = 0; i < iterations; i++) {
      const incrementedDate = startDate.plus({ days: i });
      const incrementedDateStamp = encodeDate(incrementedDate);
      if (!incrementedDate.isValid) continue;
      dates[incrementedDateStamp] = unsortedUpcomingRosters
        .filter((upcomingRoster) => incrementedDateStamp === encodeDate(upcomingRoster.activityDate))
        // TODO(BS): if this is correct ? test
        .sort((a, b) => (a.activity.startTime ? a.activity.startTime.localeCompare(b.activity.startTime) : 0));
    }

    return dates;
  }

  // ------------------------------------------------------------------------------------------------------------------------------
  // Presentation (inc. Actions)
  // ------------------------------------------------------------------------------------------------------------------------------

  const firstVisitTooltipAnchorElement = useRef<HTMLDivElement>(null);

  function handleInteractionPosts() {
    if (!hasViewedTutorial && currentOnboardingStep >= 3) {
      postInteraction(MY_ACTIVITIES_PAGE, UPCOMING_ROSTERS_VIEW_TUTORIAL_ACTION).finally(() => {
        if (refetchUpcomingRostersInteractions !== undefined) refetchUpcomingRostersInteractions();
      });
    }
  }

  const [clickedDate, setClickedDate] = useState<DateTime | undefined>();
  const [upcomingRosterDayDialogOpen, setUpcomingRosterDayDialogOpen] = useState(false);
  const [activityApplicationEditDialogOpen, setActivityApplicationEditDialogOpen] = useState(false);

  function handleDayDotDialogClose() {
    setUpcomingRosterDayDialogOpen(false);
    setClickedDate(undefined);
  }

  useEffect(() => {
    if (activityId !== undefined) handleDayDotDialogClose();
  }, [activityId]);

  return (
    <>
      <MyActivitiesTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
          handleInteractionPosts();
        }}
      />
      <div ref={rootRef} style={savedHeight ? { minHeight: savedHeight } : undefined}>
        <Grid container spacing={2}>
          <Grid container ref={firstVisitTooltipAnchorElement}>
            <Grid
              item
              container
              direction={'row'}
              justify={isMobile ? 'center' : 'flex-start'}
              spacing={2}
              style={{ marginTop: 16 }}
            >
              <WeeklyDatePicker
                date={date}
                setDate={setDate}
                datesThisWeek={monthlyUpcomingRosters}
                setClickedDate={setClickedDate}
                setUpcomingRosterDayDialogOpen={setUpcomingRosterDayDialogOpen}
              />
              <MyElementsUpcomingRostersLegend />
            </Grid>
          </Grid>
          <Grid container item>
            <MyElementsUpcomingRostersList
              loading={loading}
              datesThisWeek={viewableUpcomingRosters}
              refetch={refetch}
              date={date}
              goToNextDatePeriod={goToNextDatePeriod}
              goToPreviousDatePeriod={goToPreviousDatePeriod}
              activityView={!!activityId}
              activityApplicationEditDialogOpen={activityApplicationEditDialogOpen}
              setActivityApplicationEditDialogOpen={setActivityApplicationEditDialogOpen}
              upcomingRosterDayDialogOpen={upcomingRosterDayDialogOpen}
              handleDayDotDialogClose={handleDayDotDialogClose}
              clickedDate={clickedDate}
              attendances={attendances}
              activityReports={activityReports}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
