import React from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { Grid, Typography, Box } from '@material-ui/core';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../../common/cards/alert-card/AlertCard';

interface RemindAllVolunteersDialogProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (value: { message: string }) => void;
}

export const RemindAllVolunteersDialog = (props: RemindAllVolunteersDialogProps) => {
  const { open, handleClose, onSubmit } = props;
  const { isMobile } = useCampfireTheme();
  const { darkTextColor, lightTextColor } = getAlertCardColors('warning');
  const [value, setValue] = React.useState(EditorState.createEmpty());

  return (
    <FullscreenDialog open={open} close={handleClose} title={'Remind Volunteers'} fullScreen={isMobile}>
      <Grid container spacing={2} style={{ paddingBottom: 10 }}>
        <Box mt={1}>
          <AlertCard variant='warning' title={'Application Reminder'}>
            <AlertCardBody>
              <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                This will remind <span style={{ fontWeight: 'bold', color: darkTextColor }}>all</span> incoming
                volunteers that they have incomplete tasks. You can add a message below to be included with the
                reminder.
              </Typography>
            </AlertCardBody>
          </AlertCard>
        </Box>
        <Grid item xs={12} style={{ padding: '8px 0' }}>
          <Box mt='1.25rem'>
            <CampfireRichEditor
              editorState={value}
              setEditorState={setValue}
              placeholder='Include a message to the volunteer/s about their application'
            />
          </Box>
        </Grid>
        <Grid container item justify='flex-end' direction='row' style={{ padding: '8px 0' }}>
          <Grid item style={{ marginRight: '8px' }}>
            <TabletButton onClick={handleClose} color='default' variant='contained' size='large'>
              Cancel
            </TabletButton>
          </Grid>
          <Grid item>
            <TabletButton
              color='primary'
              variant='contained'
              size='large'
              onClick={() => onSubmit({ message: stateToHTML(value.getCurrentContent()) })}
            >
              Remind
            </TabletButton>
          </Grid>
        </Grid>
      </Grid>
    </FullscreenDialog>
  );
};
