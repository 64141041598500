import React from 'react';
import ActivitiesConsoleScreen from '../../screens/program-manager/activities/ActivitiesConsoleScreen';
import MiniRoute from '../common/MiniRoute';
import { useRoutesMap } from '../common/useRoutesMaps';
import { ActivityManagementScreen } from '../../screens/program-manager/activity-management/ActivityManagementScreen';
import { RostersScreenRouter } from '../../screens/program-manager/rosters/RostersScreenRouter';

export const getActivityLeaderSubContentBlock = () => {
  const {
    management: { route, isAuthorized },
  } = useRoutesMap();

  return [
    <MiniRoute
      //
      key='activities'
      path={`/${route}/activities`}
      component={ActivitiesConsoleScreen}
      isAuthorized={isAuthorized}
    />,
    <MiniRoute
      //
      key='activity-management'
      path={`/${route}/activity-management`}
      component={ActivityManagementScreen}
      isAuthorized={isAuthorized}
    />,
    <MiniRoute
      //
      key='rosters'
      path={`/${route}/rosters`}
      component={RostersScreenRouter}
      isAuthorized={isAuthorized}
    />,
  ];
};
