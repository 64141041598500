import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { goBackBySlashes } from '@campfire/world-wide-web/lib';
import { useHistory, useRouteMatch } from 'react-router';
import { useSupportContext } from '../SupportContext';

interface Props {
  open: boolean;
  close: () => void;
  supportCategoryId: string;
}
const DeleteCategoryDialog = (props: Props) => {
  const { open, close, supportCategoryId } = props;
  const { runDeleteCategory, deleteCategoryIsLoading, setInEditCategoryMode } = useSupportContext();
  const history = useHistory();
  const match = useRouteMatch();

  const handleDelete = () => {
    runDeleteCategory(supportCategoryId).then((res) => {
      if (res.ok && match) {
        goBackBySlashes(history, match);
        setInEditCategoryMode(false);
        close();
      }
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{'Delete this Category?'}</DialogTitle>
      <DialogContent>
        <Typography variant='body2'>
          {
            'Deleting this category will delete all resources within it. Once deleted, this category and its resources will not be retrievable.'
          }
        </Typography>
      </DialogContent>
      <DialogActions>
        <TabletButton data-track='resources-delete-category-dialog-go-back-button' onClick={close}>
          {'Go Back'}
        </TabletButton>
        <TabletButton
          data-track='resources-delete-category-dialog-delete-button'
          color='error'
          variant='outlined'
          disabled={deleteCategoryIsLoading}
          onClick={handleDelete}
        >
          {'Delete Category'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { DeleteCategoryDialog };
