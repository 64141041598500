import React from 'react';
import { DateTime, Duration } from 'luxon';
import { StandardFilterSelectField } from './StandardFilterSelectField';
import { StandardFilterRadioSelection } from './StandardFilterRadioSelection';

export const DEFAULT_PROGRAM_FILTER = 'All programs';

export const DAY_FILTER_URL_PARAM_KEY = 'days';

export const dayFilters = [
  {
    label: 'Monday',
    value: 'MO',
    selectDisplay: 'Mon',
  },
  { label: 'Tuesday', value: 'TU', selectDisplay: 'Tues' },
  { label: 'Wednesday', value: 'WE', selectDisplay: 'Wed' },
  { label: 'Thursday', value: 'TH', selectDisplay: 'Thu' },
  { label: 'Friday', value: 'FR', selectDisplay: 'Fri' },
  { label: 'Saturday', value: 'SA', selectDisplay: 'Sat' },
  { label: 'Sunday', value: 'SU', selectDisplay: 'Sun' },
];

// export type DurationFilterType = {
//   label: string;
//   value: Duration;
//   urlParamKey: string;
// };

export type StandardTypeFilterType = {
  label: string;
  value: string;
  urlParamKey: string;
  isActive: boolean;
};

export const TIME_FILTER_START_TIME_URL_PARAM_KEY = 'startTime';
export const TIME_FILTER_END_TIME_URL_PARAM_KEY = 'endTime';

export const DATE_FILTER_START_DATE_URL_PARAM_KEY = 'startDate';
export const DATE_FILTER_END_DATE_URL_PARAM_KEY = 'endDate';

export const PROGRAM_FILTER_URL_PARAM_KEY = 'programId';

export const getInitialTimeFilterValues = () => ({
  startTime: DateTime.local()
    .startOf('day')
    .toJSDate(),
  endTime: DateTime.local()
    .endOf('day')
    .toJSDate(),
});

export const getInitialDateFilterValues = () => ({
  startDate: '',
  endDate: '',
});

export const getInitialProgramFilterValue = () => ({
  programId: 'all',
});

type TimeFilter = { startTime: Date; endTime: Date };
type DateFilter = { startDate: Date | string; endDate: Date | string };
type ProgramFilter = { programId: string };

export const areInitialTimeFiltersDefault = (filters: TimeFilter) => {
  return (
    filters.startTime.getHours() === 0 &&
    filters.startTime.getMinutes() === 0 &&
    filters.endTime.getHours() === 23 &&
    filters.endTime.getMinutes() === 59
  );
};

export const areInitialDateFiltersDefault = (filters: DateFilter) => {
  return !filters.startDate && !filters.endDate;
};
export const isInitialProgramFilterDefault = (filter: ProgramFilter) => {
  return filter.programId === DEFAULT_PROGRAM_FILTER;
};

export type Filters = {
  selectedStatusFilter: StandardTypeFilterType;
  selectedDurationFilter: StandardTypeFilterType;
  selectedActivityTypeFilter: StandardTypeFilterType;
  selectedRosterTypeFilter: StandardTypeFilterType;
  selectedLocationFilter: StandardTypeFilterType;
  selectedDayFilters: {
    urlParamKey: string;
    isActive: boolean;
    value: Array<string>;
  };
  selectedTimeFilter: TimeFilter;
  selectedDateFilter: DateFilter;
  searchInput?: string;
  selectedProgramFilter: ProgramFilter;
};

export const filterHelperTexts = {
  management: {
    dayFiltersHelperText: 'Select day(s)',
    programFiltersHelperText: 'Select program',
    activityTypeHelperText: 'Filter by activity type',
  },
  volunteer: {
    dayFiltersHelperText: 'Which days are you most available for?',
    programFiltersHelperText: 'Which programs would you like to volunteer for?',
    activityTypeHelperText: 'Would you prefer a recurring or non-recurring activity?',
  },
};

export type FilterDataType = {
  filterName: string;
  urlParamKey: string;
  options: Array<{ label: string; value: string }>;
  defaultOption: number;
  helperText: {
    manager: string;
    volunteer: string;
  };
};

export const statusFilterData: FilterDataType = {
  filterName: 'Status',
  urlParamKey: 'status',
  options: [
    {
      label: 'All activities',
      value: 'all',
    },
    {
      label: 'Active activities',
      value: 'active',
    },
    {
      label: 'Closed activities',
      value: 'closed',
    },
    {
      label: 'Ended activities',
      value: 'ended',
    },
    {
      label: 'Suspended activities',
      value: 'suspended',
    },
  ],
  defaultOption: 1,
  helperText: {
    manager: 'Filter by activity status',
    volunteer: 'Filter by activity status',
  },
};

export const durationFilterData: FilterDataType = {
  filterName: 'Duration',
  urlParamKey: 'duration',
  options: [
    {
      label: 'Any duration',
      value: 'any',
    },
    {
      label: 'Up to 1 hour',
      value: '1h',
    },
    {
      label: 'Up to 2 hours',
      value: '2h',
    },
    {
      label: 'Up to 3 hours',
      value: '3h',
    },
    {
      label: 'Up to 4 hours',
      value: '4h',
    },
    {
      label: 'Up to 5 hours',
      value: '5h',
    },
    {
      label: 'More than 5 hours',
      value: '5plus',
    },
  ],
  defaultOption: 0,
  helperText: {
    manager: 'Filter by duration',
    volunteer: 'How long would you like to volunteer for?',
  },
};

export const getDurationFromFilterValue = (value: string): undefined | Duration => {
  if (value === 'any') return Duration.fromObject({ hours: 0 });
  if (value === '1h') return Duration.fromObject({ hours: 1 });
  if (value === '2h') return Duration.fromObject({ hours: 2 });
  if (value === '3h') return Duration.fromObject({ hours: 3 });
  if (value === '4h') return Duration.fromObject({ hours: 4 });
  if (value === '5h') return Duration.fromObject({ hours: 5 });
  if (value === '5plus') return Duration.fromObject({ hours: 5 });
  return undefined;
};

export const activityTypeFilterData: FilterDataType = {
  filterName: 'ActivityType',
  urlParamKey: 'type',
  options: [
    {
      label: 'All activity types',
      value: 'all',
    },
    {
      label: 'Flexible activities',
      value: 'flexible',
    },
    {
      label: 'Regular activities',
      value: 'regular',
    },
  ],
  defaultOption: 0,
  helperText: {
    manager: 'Filter by activity type',
    volunteer: 'Filter by activity type',
  },
};

export const rosterTypeFilterData: FilterDataType = {
  filterName: 'RosterType',
  urlParamKey: 'roster',
  options: [
    {
      label: 'All roster types',
      value: 'all',
    },
    {
      label: 'Managed rosters',
      value: 'managed',
    },
    {
      label: 'Open rosters',
      value: 'open',
    },
  ],
  defaultOption: 0,
  helperText: {
    manager: 'Filter by roster type',
    volunteer: 'Filter by roster type',
  },
};

export const locationFilterData: FilterDataType = {
  filterName: 'Location',
  urlParamKey: 'location',
  options: [
    {
      label: 'All activity locations',
      value: 'all',
    },
    {
      label: 'Local activities',
      value: 'local',
    },
    {
      label: 'Remote activities',
      value: 'remote',
    },
  ],
  defaultOption: 0,
  helperText: {
    manager: 'Filter by activity location',
    volunteer: 'Would you like a physical activity or a remote activity (e.g. Zoom)?',
  },
};

export const initialFilters: Filters = {
  selectedDayFilters: { value: [], isActive: false, urlParamKey: DAY_FILTER_URL_PARAM_KEY },
  selectedDurationFilter: {
    ...durationFilterData.options[durationFilterData.defaultOption],
    isActive: false,
    urlParamKey: durationFilterData.urlParamKey,
  },
  selectedActivityTypeFilter: {
    ...activityTypeFilterData.options[activityTypeFilterData.defaultOption],
    isActive: false,
    urlParamKey: activityTypeFilterData.urlParamKey,
  },
  selectedRosterTypeFilter: {
    ...rosterTypeFilterData.options[rosterTypeFilterData.defaultOption],
    isActive: false,
    urlParamKey: rosterTypeFilterData.urlParamKey,
  },
  selectedTimeFilter: getInitialTimeFilterValues(),
  selectedDateFilter: getInitialDateFilterValues(),
  selectedStatusFilter: {
    ...statusFilterData.options[statusFilterData.defaultOption],
    isActive: false,
    urlParamKey: statusFilterData.urlParamKey,
  },
  selectedLocationFilter: {
    ...locationFilterData.options[locationFilterData.defaultOption],
    isActive: false,
    urlParamKey: locationFilterData.urlParamKey,
  },
  selectedProgramFilter: getInitialProgramFilterValue(),
};

type standardFilterHOCProps = {
  selectedFilters: Filters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
};

export const StatusFiltersSelect = (props: standardFilterHOCProps) => (
  <StandardFilterSelectField {...props} filterData={statusFilterData} />
);

export const DurationFiltersSelect = (props: standardFilterHOCProps) => (
  <StandardFilterSelectField {...props} filterData={durationFilterData} />
);

export const ActivityTypeFiltersSelect = (props: standardFilterHOCProps) => (
  <StandardFilterSelectField {...props} filterData={activityTypeFilterData} />
);

export const RosterTypeFiltersSelect = (props: standardFilterHOCProps) => (
  <StandardFilterSelectField {...props} filterData={rosterTypeFilterData} />
);

export const LocationFiltersSelect = (props: standardFilterHOCProps) => (
  <StandardFilterSelectField {...props} filterData={locationFilterData} />
);

type standardFilterRadioHOC = {
  selectedFilters: Filters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  isManagementView: boolean;
};

export const StatusFiltersRadio = (props: standardFilterRadioHOC) => (
  <StandardFilterRadioSelection {...props} filterData={statusFilterData} />
);

export const DurationFiltersRadio = (props: standardFilterRadioHOC) => (
  <StandardFilterRadioSelection {...props} filterData={durationFilterData} />
);

export const ActivityTypeFiltersRadio = (props: standardFilterRadioHOC) => (
  <StandardFilterRadioSelection {...props} filterData={activityTypeFilterData} />
);

export const RosterTypeFiltersRadio = (props: standardFilterRadioHOC) => (
  <StandardFilterRadioSelection {...props} filterData={rosterTypeFilterData} />
);

export const LocationFiltersRadio = (props: standardFilterRadioHOC) => (
  <StandardFilterRadioSelection {...props} filterData={locationFilterData} />
);
