import React from 'react';
import { Box } from '@material-ui/core';
import { ActivityHeader } from './ActivityHeader';

interface Props {
  children?: React.ReactNode;
}

export function ActivityPendingApplications({ children }: Props) {
  return (
    <Box marginTop='2rem'>
      <ActivityHeader title='Pending Applications' />
      {children}
    </Box>
  );
}
