import { Box, Chip, Typography } from '@material-ui/core';
import React from 'react';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

export const StatusChipContent = ({ status, statusColor }: { status: string; statusColor: string }) => {
  const { theme } = useCampfireTheme();

  return (
    <Box display='flex' alignItems='center'>
      <Box height='10px' width='10px' borderRadius='50%' marginRight='8px' bgcolor={statusColor} />
      <Typography
        variant='caption'
        style={{
          fontSize: '12px',
          fontWeight: 700,
          color: theme.color.grey.neutral400,
          textTransform: 'uppercase',
          lineHeight: '14px',
        }}
      >
        {status}
      </Typography>
    </Box>
  );
};

export const StatusChip = ({ status, statusColor }: { status: string; statusColor: string }) => {
  return (
    <Chip
      size='small'
      label={<StatusChipContent status={status} statusColor={statusColor} />}
      style={{
        border: '1px solid #e9e9e9',
        color: 'white',
        background: 'transparent',
        fontWeight: 600,
      }}
    />
  );
};

export const ReportTypeStatusChip = ({ isDeactivated }: { isDeactivated: boolean }) => {
  const { theme } = useCampfireTheme();
  return isDeactivated ? (
    <StatusChip status='Archived' statusColor={theme.color.activities.status.ended.primary} />
  ) : // <StatusChip status='Active' statusColor={theme.color.activities.status.active.primary} />
  null;
};
