import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { StringParam, useQueryParams, NumberParam, BooleanParam, ArrayParam } from 'use-query-params';
import { encodeDate, unpackToDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router';
import { pick, orderBy, merge } from 'lodash';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { CollapsibleSideBar } from '../../../common/CollapsibleSideBar';
import { CommonScreenSkeleton } from '../../../common/CommonScreenSkeleton';
import { RostersSideBar } from './sidebar/RostersSideBar';
import { RosterMainSection } from './main-section/RosterMainSection';
import { RosterListItem } from './main-section/roster/roster-types';
import {
  rostersFilteredByEndDate,
  rostersFilteredByEndTime,
  rostersFilteredByProgram,
  rostersFilteredBySearch,
  rostersFilteredByStartDate,
  rostersFilteredByStartTime,
  rostersFilteredByStatus,
  rostersFilteredByType,
  rostersFilteredByVolunteersNeeded,
  isRosterCompleted,
  rosterFilteredByTag,
  rosterFilteredByDay,
  rosterFilteredByRosterTypes,
  rostersFilteredByActivity,
} from './main-section/filters/roster-filter-functions';
import { getActivityStatus, useCancelActivityFetch } from './main-section/roster/roster-functions';
import { GET_ACTIVITY_IDS, GET_ROSTERS_SIDEBAR_DATA } from './rosters-sidebar-model.gql';
import { GetRostersSidebarData, GetRostersSidebarDataVariables } from './__generated__/GetRostersSidebarData';
import { SelectActivityCancellationReasonDialog } from '../activities/activity-timeline/common/SelectActivityCancellationReasonDialog';
import { useSnackbar } from '../../../global/config/useSnackbar';
import { MobileIndividualRosterScreen } from './MobileIndividualRosterScreen';
import { useUser } from '../../../global/auth/useUser';
import { TabletRoster } from './tablet-roster/TabletRoster';
import { useSharedRosterData } from './state';
import { useDeepEffect } from '../../../hooks/useDeepEffect';
import { RosterSettingDialog } from './settings/RosterSettingDialog';
import { RosterToolbar } from './single-roster/RosterToolbar';
import { ProgramsContext } from '../../../global/programs-sell/ProgramsContext';
import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';
import { RostersGetActivityIds, RostersGetActivityIdsVariables } from './__generated__/RostersGetActivityIds';
import { initFilters } from './main-section/filters/RosterFilters';
import { useSharedSessionResults } from './main-section/roster/RosterHeader';
import { SessionContext } from '../../../global/auth/SessionContext';

export const SMALL_ORG_DAYS_LIMIT = 30;
export const LARGE_ORG_DAYS_LIMIT = 14;

export const IndividualRosterScreen = () => {
  const { isMd, isMobile } = useCampfireTheme();
  const [showFilters, setShowFilters] = useState(false);
  const [showCancelActivityDialog, setShowCancelActivityDialog] = useState(false);
  const [historyViewSet, setHistoryViewSet] = useState(false);
  const [isOpenSetting, setIsOpenSetting] = useState(false);
  const { setRosterData } = useSharedRosterData();
  const history = useHistory();
  const { setSnackbar } = useSnackbar();
  const cancelActivity = useCancelActivityFetch();
  const session = React.useContext(SessionContext);
  const isLargeOrg = session?.user?.orgInfo.isLargeOrg;
  const { selectedPrograms } = React.useContext(ProgramsContext);
  const { setIsExpanded } = useSharedSessionResults();
  const [query, setQuery] = useQueryParams({
    activityDate: StringParam,
    activityId: StringParam,
    activityFilterId: StringParam,
    programId: StringParam,
    activityType: StringParam,
    activityStatus: StringParam,
    volunteersNeeded: NumberParam,
    startTime: StringParam,
    endTime: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    onHistory: BooleanParam,
    q: StringParam,
    view: StringParam,
    rosterType: StringParam,
    days: ArrayParam,
    tags: ArrayParam,
  });

  const defaultStartDate = React.useRef(encodeDate(DateTime.local()));
  const defaultEndDate = React.useMemo(() => {
    const numOfDays = isLargeOrg ? LARGE_ORG_DAYS_LIMIT : SMALL_ORG_DAYS_LIMIT;
    return encodeDate(DateTime.local().plus({ days: numOfDays }));
  }, [isLargeOrg]);
  const numberOfDays = React.useMemo(() => {
    return isLargeOrg ? LARGE_ORG_DAYS_LIMIT : SMALL_ORG_DAYS_LIMIT;
  }, [isLargeOrg]);

  React.useEffect(() => {
    if (!query.startDate) {
      setQuery({
        startDate: defaultStartDate.current,
      });
    }
  }, [query.startDate]);

  React.useEffect(() => {
    if (!query.endDate) {
      setQuery({
        endDate: defaultEndDate,
      });
    }
  }, [query.endDate]);

  const { programId: selectedProgramId, activityId: selectedActivityId } = query;

  const isActive = query.activityStatus !== 'active';

  const [queryActivityIds, vmActivityIds] = useCampfireLazyQuery<RostersGetActivityIds, RostersGetActivityIdsVariables>(
    GET_ACTIVITY_IDS
  );

  const [getRostersSidebarData, { data, loading, refetch }] = useCampfireLazyQuery<
    GetRostersSidebarData,
    GetRostersSidebarDataVariables
  >(GET_ROSTERS_SIDEBAR_DATA);

  React.useEffect(() => {
    queryActivityIds({
      variables: {
        isActive,
        programIds: selectedPrograms.length > 0 ? selectedPrograms : undefined,
      },
    });
  }, []);

  React.useEffect(() => {
    if (selectedPrograms.length === 1) {
      const defaultQuery = merge(initFilters(isLargeOrg), query);
      setQuery({
        ...defaultQuery,
        programId: selectedPrograms[0],
      });
    }
  }, []);

  useDeepEffect(() => {
    if (selectedPrograms.length > 0 && selectedActivityId !== 'all') {
      queryActivityIds({
        variables: {
          isActive,
          programIds: selectedProgramId === 'all' || selectedProgramId === undefined ? undefined : [selectedProgramId],
        },
      });
    }
  }, [isActive, selectedProgramId]);

  const setSearchFilter = (value: string) =>
    setQuery({
      ...query,
      q: value,
    });

  useDeepEffect(() => {
    if (vmActivityIds.data) {
      getRostersSidebarData({
        variables: {
          startDate: query.startDate,
          endDate: query.endDate,
          activityIds: vmActivityIds.data?.vm.programs.flatMap((program) =>
            program.activities.map((activity) => activity.activityId)
          ),
        },
      });
    }
  }, [query.startDate, query.endDate, vmActivityIds.data]);

  const {
    user: { userIdentityService },
  } = useUser();
  const userIsAdmin = userIdentityService.isVmAdmin;
  const userIsActivityLeader = (activityId: string) => userIdentityService.isLeaderOfActivity(activityId);
  const userIsPmOrAdmin = (programId: string) => userIsAdmin || userIdentityService.isManagerOfProgram(programId);

  const rosters: RosterListItem[] | undefined = useMemo(() => {
    return data?.vm.activities
      .filter((a) => !a.program.dateSuspended && !a.program.dateDeleted)
      .filter((a) => userIsPmOrAdmin(a.program.programId) || userIsActivityLeader(a.activityId))
      .flatMap((a) => {
        const dates = a.occurrencesBetween
          .concat(a.historicalOccurrences.filter((item) => a.occurrencesBetween.indexOf(item) < 0))
          .filter((r) => {
            const publishedRoster = a.publishedRosters.find((item) => item.activityDate === r);
            return !(publishedRoster && publishedRoster?.dateRemoved);
          });

        if (a.__typename === 'VOLUNTEER_RecurringActivityType') {
          return dates?.map((o) => ({
            activityId: a.activityId,
            activityName: a.name,
            startTime: a.startTime,
            endTime: a.endTime,
            activityDate: o,
            programId: a.program.programId,
            activityType: a.__typename === 'VOLUNTEER_RecurringActivityType' ? 'regular' : 'flexible',
            activityStatus: getActivityStatus(a, o),
            volsNeeded: a.activityEnrolments.filter((e) => !e.dateRemoved).length - a.minVolunteers - 1,
            hasOpenRoster: a.hasOpenRoster,
            activityTags: a.activityTags,
          }));
        }
        if (a.__typename === 'VOLUNTEER_NonRecurringActivityType') {
          return dates?.map((o) => ({
            activityId: a.activityId,
            activityName: a.name,
            startTime: a.startTime,
            endTime: a.endTime,
            activityDate: o,
            programId: a.program.programId,
            activityType: 'flexible',
            activityStatus: getActivityStatus(a, o),
            volsNeeded: a.activityEnrolments.filter((e) => !e.dateRemoved).length - a.minVolunteers - 1,
            hasOpenRoster: a.hasOpenRoster,
            activityTags: a.activityTags,
          }));
        }
        return [];
      });
  }, [data]);

  const selectedActivity = useMemo(() => data?.vm.activities?.find((a) => a.activityId === query.activityId), [
    data,
    query,
  ]);

  const filteredRostersList = useMemo(() => {
    const filteredBySearch = rostersFilteredBySearch(rosters || [], query.q);
    const filteredByProgram = rostersFilteredByProgram(filteredBySearch, query.programId);
    const filteredByActivity = rostersFilteredByActivity(filteredByProgram, query.activityFilterId);
    const filteredByType = rostersFilteredByType(filteredByActivity, query.activityType);
    const filteredByStatus = rostersFilteredByStatus(filteredByType, query.activityStatus);
    const filteredByStartTime = rostersFilteredByStartTime(filteredByStatus, query.startTime);
    const filteredByEndTime = rostersFilteredByEndTime(filteredByStartTime, query.endTime);
    const filteredByStartDate = rostersFilteredByStartDate(filteredByEndTime, query.startDate);
    const filteredByEndDate = rostersFilteredByEndDate(filteredByStartDate, query.endDate);
    const filteredByVolsNeeded = rostersFilteredByVolunteersNeeded(filteredByEndDate, query.volunteersNeeded);
    const filteredByTag = rosterFilteredByTag(filteredByVolsNeeded, query.tags || []);
    const filteredByDay = rosterFilteredByDay(filteredByTag, query.days || []);
    const filteredByRosterType = rosterFilteredByRosterTypes(filteredByDay, query.rosterType || 'all');

    const dateOrder = query.onHistory ? 'desc' : 'asc';

    return orderBy(filteredByRosterType, ['activityDate', 'startTime', 'activityName'], [dateOrder, dateOrder, 'asc']);
  }, [rosters, query]);

  useDeepEffect(() => {
    if (isMobile || isMd || filteredRostersList.length === 0) return;
    if (!loading && onHistory && query.activityId !== filteredRostersList[0].activityId && !historyViewSet) {
      setQuery({
        ...pick(filteredRostersList[0], ['activityId', 'activityDate']),
      });
      setHistoryViewSet(true);
    }
    if (!loading && Object.values(query).every((queryParams) => !queryParams) && !query.onHistory) {
      setQuery({
        ...pick(filteredRostersList[0], ['activityId', 'activityDate']),
      });
    }
  }, [filteredRostersList, query]);

  useDeepEffect(() => {
    const isRosterInList = filteredRostersList.some(
      ({ activityId, activityDate }) => activityId === query.activityId && activityDate === query.activityDate
    );

    if (isMobile || isMd || isRosterInList) return;
    setQuery({
      ...pick(filteredRostersList[0], ['activityId', 'activityDate']),
    });
  }, [filteredRostersList, query]);

  const selectedRoster = useMemo(() => {
    const roster = filteredRostersList.find(
      (r) => r.activityId === query.activityId && r.activityDate === query.activityDate
    );
    if (isMobile || isMd) {
      return roster;
    }
    return roster || filteredRostersList[0];
  }, [query, filteredRostersList, isMobile, isMd]);

  const rosterTemplate = useMemo(() => {
    return selectedActivity?.rosterTemplates?.find((rt) => {
      return (
        unpackToDate(rt.rosterDate).toFormat('yyyy-MM-dd') ===
        unpackToDate(query.activityDate ?? '').toFormat('yyyy-MM-dd')
      );
    });
  }, [selectedActivity, query]);

  useEffect(() => {
    setRosterData(data);
  }, [data]);

  const handleEditRosterTemplate = () => {
    if (!rosterTemplate) return;
    history.push(`/management/rosters/edit-roster-template?rosterTemplateId=${rosterTemplate.rosterTemplateId}`);
  };

  const onHistoryClick = () => {
    if (query.onHistory) {
      history.goBack();
    } else {
      setQuery({ activityDate: undefined, activityId: undefined });
      setQuery(
        {
          activityStatus: 'completed',
          startDate: encodeDate(DateTime.local().minus({ days: numberOfDays })),
          endDate: encodeDate(DateTime.local()),
          onHistory: true,
        },
        'push'
      );
      setHistoryViewSet(false);
    }
  };

  const onLoadNext = () => {
    if (query.endDate) {
      setQuery({
        ...query,
        startDate: query.endDate,
        endDate: encodeDate(
          unpackToDate(query.endDate).plus({
            days: numberOfDays,
          })
        ),
      });
    }
  };

  const onLoadPrev = () => {
    if (query.startDate) {
      setQuery({
        ...query,
        startDate: encodeDate(
          unpackToDate(query.startDate).minus({
            days: numberOfDays,
          })
        ),
        endDate: query.startDate,
      });
    }
  };

  const { onHistory } = query;

  useEffect(() => {
    if (onHistory) {
      setShowFilters(false);
    }
  }, [onHistory]);

  const onSetShowFilterClick = () => {
    if (!showFilters) {
      setQuery({ ...query, onHistory: false });
    }
    setShowFilters(!showFilters);
  };

  const handleOpenSetting = () => {
    setIsOpenSetting(true);
  };
  const handleCloseSetting = () => {
    setIsOpenSetting(false);
  };

  return (
    <>
      {loading || vmActivityIds.loading ? (
        <CommonScreenSkeleton />
      ) : isMobile ? (
        <MobileIndividualRosterScreen
          selectedRoster={selectedRoster}
          loading={loading}
          rosterTemplate={rosterTemplate}
          rosters={filteredRostersList}
          handleEditRosterTemplate={handleEditRosterTemplate}
          cancelActivity={() => setShowCancelActivityDialog(true)}
          setSearchFilter={setSearchFilter}
          handleReactivateActivityRefetch={refetch}
          query={query}
          onSetQuery={setQuery}
          isRosterCompleted={isRosterCompleted(selectedRoster)}
          onHistoryClick={onHistoryClick}
          showFilters={showFilters}
          setShowFilters={onSetShowFilterClick}
          loadPrev={onLoadPrev}
          loadNext={onLoadNext}
        />
      ) : isMd ? (
        <TabletRoster
          loading={loading}
          onHistoryClick={onHistoryClick}
          selectedRoster={selectedRoster}
          setSearchFilter={setSearchFilter}
          showFilters={showFilters}
          setShowFilters={onSetShowFilterClick}
          handleEditRosterTemplate={handleEditRosterTemplate}
          setShowCancelActivityDialog={setShowCancelActivityDialog}
          rosters={filteredRostersList}
          rosterTemplate={rosterTemplate}
          handleReactivateActivityRefetch={refetch}
          query={query}
          onSetQuery={setQuery}
          isRosterCompleted={isRosterCompleted(selectedRoster)}
          loadPrev={onLoadPrev}
          loadNext={onLoadNext}
        />
      ) : (
        <Box width={'100%'} display={'flex'} flexDirection={'column'} height={'100%'} overflow={'hidden'}>
          <RosterToolbar
            title={'Rosters'}
            query={query.q || ''}
            setQueryValue={setSearchFilter}
            isFiltering={showFilters}
            setFiltering={onSetShowFilterClick}
            isHistory={Boolean(query.onHistory)}
            setHistory={onHistoryClick}
            onSettings={handleOpenSetting}
            onTemplate={handleEditRosterTemplate}
            onCancel={() => setShowCancelActivityDialog(true)}
            onView={() => setQuery({ view: 'bulk' })}
            showCancel={false}
            showTemplate={Boolean(rosterTemplate)}
          />
          <Box
            position={'relative'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-end'}
            overflow={'hidden'}
            flex={1}
            alignItems='stretch'
            padding={'0px 0px 0px 60px'}
          >
            <RosterMainSection
              loading={loading}
              showFilters={showFilters}
              query={query}
              onSetQuery={setQuery}
              rosterTemplate={rosterTemplate}
              handleEditRosterTemplate={handleEditRosterTemplate}
              hasRosters={!!(rosters && rosters.length >= 1)}
              handleReactivateActivityRefetch={refetch}
              isRosterCompleted={isRosterCompleted(selectedRoster)}
              activityStatus={selectedRoster?.activityStatus}
              setShowCancelActivityDialog={setShowCancelActivityDialog}
            />
            <CollapsibleSideBar setSiblingExpanded={setIsExpanded}>
              <RostersSideBar rosters={filteredRostersList} loadNext={onLoadNext} loadPrev={onLoadPrev} />
            </CollapsibleSideBar>
          </Box>
        </Box>
      )}
      {selectedActivity?.name && query.activityDate && query.activityId ? (
        <SelectActivityCancellationReasonDialog
          open={showCancelActivityDialog}
          handleClose={() => setShowCancelActivityDialog(false)}
          handleSelection={({
            activityCancellationReasonId,
            description,
            addedAttachments,
            attachmentCreationTokens,
          }) => {
            cancelActivity
              .run({
                activityId: query.activityId ?? '',
                activityDate: query.activityDate ?? '',
                activityCancellationReasonId,
                description: JSON.stringify(description),
                addedAttachments,
                attachmentCreationTokens,
              })
              .then((res) => {
                if (!res.ok) {
                  setSnackbar({
                    open: true,
                    message: 'Unable to cancel activity',
                    variant: 'error',
                  });
                  return;
                }

                setSnackbar({
                  open: true,
                  message: 'Activity cancelled',
                  variant: 'success',
                });

                if (refetch) refetch();
                setShowCancelActivityDialog(false);
              })
              .catch(() =>
                setSnackbar({
                  open: true,
                  message: 'Unable to cancel activity',
                  variant: 'error',
                })
              );
          }}
          activityName={selectedActivity.name}
          activityDate={query.activityDate}
        />
      ) : null}
      {isOpenSetting && <RosterSettingDialog open={isOpenSetting} onClose={handleCloseSetting} />}
    </>
  );
};
