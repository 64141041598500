import { gql } from '@apollo/client';

export const GET_ACTIVITY_IDS = gql`
  query RostersGetActivityIds($programIds: [String!], $isActive: Boolean) {
    isLargeOrg
    vm {
      programs(programIds: $programIds) {
        activities(includeInactiveActivities: $isActive) {
          activityId
        }
      }
    }
  }
`;

export const GET_ROSTERS_SIDEBAR_DATA = gql`
  query GetRostersSidebarData($startDate: Date, $endDate: Date, $activityIds: [String!]) {
    vm {
      activities(activityIds: $activityIds) {
        ...RostersScreenSidebarActivity
      }
    }
  }

  fragment RostersScreenSidebarActivity on VOLUNTEER_ActivityType {
    activityId
    name
    startTime
    endTime
    startDate
    endDate
    isActive
    minVolunteers
    hasOpenRoster
    occurrencesBetween(from: $startDate, until: $endDate)
    ... on VOLUNTEER_NonRecurringActivityType {
      __typename
    }
    historicalOccurrences(from: $startDate, until: $endDate)
    ... on VOLUNTEER_RecurringActivityType {
      __typename
      schedule {
        recurrences {
          humanReadable
        }
      }
    }
    draftRosters(startDate: $startDate, endDate: $endDate) {
      ...RostersScreenSidebarDraftRoster
    }
    cancelledActivities(startDate: $startDate, endDate: $endDate) {
      ...RostersScreenSidebarCancelledActivity
    }
    closedActivity {
      ...RostersScreenSidebarClosedActivity
    }
    isSuspended
    sessions {
      sessionId
    }
    program {
      programId
      name
      dateDeleted
      dateSuspended
    }
    rosterTemplates {
      rosterTemplateId
      rosterNotes
      rosterDate
    }
    activityEnrolments {
      dateRemoved
    }
    publishedRosters {
      activityDate
      dateRemoved
    }
    activityReports {
      activityDate
      sessionReports {
        sessionReportId
      }
    }
    activityTags {
      activityTagId
      name
      description
    }
  }

  fragment RostersScreenSidebarDraftRoster on VOLUNTEER_DraftRosterType {
    draftRosterId
    activityDate
    publishedRoster {
      rosterId
      dateRemoved
    }
  }

  fragment RostersScreenSidebarCancelledActivity on VOLUNTEER_CancelledActivityType {
    cancelledActivityId
    activityDate
  }

  fragment RostersScreenSidebarClosedActivity on VOLUNTEER_ClosedActivityType {
    closedActivityId
  }
`;
