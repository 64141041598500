import React, { useState } from 'react';
import { Page } from '../../../global/components';
import { ImpactDashboard } from './ImpactDashboard';
import { ImpactDashboardTutorialDialog } from './ImpactDashboardTutorialDialog';

const ImpactDashboardScreen = () => {
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      <ImpactDashboard />
      <ImpactDashboardTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
        }}
      />
    </Page>
  );
};

export { ImpactDashboardScreen };
