import { SaveReportFieldValues } from './report-field-types';
import { SaveReportFieldType } from './report-fields-detail/report-field-detail-header/report-field-detail-header-actions';
import { ReportTypeFieldsReportType } from './__generated__/ReportTypeFieldsReportType';

export const getReportTypesUsingField = (
  reportTypes: Array<ReportTypeFieldsReportType>,
  fieldId: string | undefined
) => {
  return reportTypes
    .filter((reportType) =>
      reportType.items.some(
        (item) => item.__typename === 'VOLUNTEER_ReportTypeFieldType' && item.field.fieldId === fieldId
      )
    )
    .map((reportType) => {
      return {
        reportTypeId: reportType.reportTypeId,
        name: reportType.name,
        dateDeactivated: reportType.dateDeactivated,
        activities: reportType.activities,
      };
    });
};

export const parseReportFieldValues = (fieldValues: SaveReportFieldValues): SaveReportFieldType => {
  if (fieldValues.__typename === 'VOLUNTEER_DropdownFieldType') {
    return {
      field: {
        __typename: 'VOLUNTEER_DropdownFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        dropdownFieldOptions:
          fieldValues.dropdownOptions?.map((option, optionOrder) => ({
            ...option,
            order: optionOrder,
          })) ?? [],
        allowMultiple: fieldValues.isAllowMultiple ?? false,
        hero: fieldValues.isHero ?? false,
      },
    };
  }

  if (fieldValues.__typename === 'VOLUNTEER_NumericFieldType') {
    return {
      field: {
        __typename: 'VOLUNTEER_NumericFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        hero: fieldValues.isHero ?? false,
      },
    };
  }
  if (fieldValues.__typename === 'VOLUNTEER_TextFieldType') {
    return {
      field: {
        __typename: 'VOLUNTEER_TextFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        hero: fieldValues.isHero ?? false,
        isLongText: fieldValues.isLongText ?? false,
      },
    };
  }
  return {
    field: {
      __typename: fieldValues.__typename,
      description: fieldValues.description,
      name: fieldValues.name,
      fieldId: fieldValues.fieldId,
      hero: fieldValues.isHero ?? false,
    },
  };
};

export const filterByStatus = (status: string) => (reportField: any) =>
  (reportField.dateDeactivated && status === 'archived') ||
  (!reportField.dateDeactivated && status === 'active') ||
  status === 'all';

export const filterBySearch = (value: string) => (reportField: any) =>
  reportField.name.toLowerCase().includes(value.toLowerCase().trim());
