import { useContext } from 'react';
import { OnboardingContext, OnboardingInterface } from './OnboardingContext';

const useOnboardingContext = (): OnboardingInterface => {
  const onboardingContext = useContext(OnboardingContext);

  if (!onboardingContext) throw new Error('useOnboardingContext must be initiated within an OnboardingProvider');
  return onboardingContext;
};

export { useOnboardingContext };
