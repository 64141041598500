import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Avatar } from '../../../../general/user-profile/components/Avatar';
import { RosterSmsConfirmationPublishedRosterRostering } from './__generated__/RosterSmsConfirmationPublishedRosterRostering';

interface Props {
  rosterings: RosterSmsConfirmationPublishedRosterRostering[];
}
export const RosteringsAvatars = (props: Props) => {
  const { rosterings } = props;
  return (
    <Box display='flex' minHeight={24} alignItems={'center'}>
      <Box minWidth={'80px'}>
        <Typography variant={'caption'}>{`Rostered (${rosterings.length})`}</Typography>
      </Box>
      <Box display={'flex'} flexWrap={'wrap'} padding={'4px 10px'} flexDirection={'row'} overflow={'hidden'}>
        {rosterings?.map((r) => {
          return (
            <TitularTooltip description={`${r.volunteer.profile.preferredName} ${r.volunteer.profile.lastName}`}>
              <Box>
                <Avatar
                  size={24}
                  style={{ marginLeft: -3, marginRight: -3 }}
                  preferredName={r.volunteer.profile.preferredName}
                  lastName={r.volunteer.profile.lastName}
                  avatarUrl={r.volunteer.profile.avatarUrl}
                />
              </Box>
            </TitularTooltip>
          );
        })}
      </Box>
    </Box>
  );
};
