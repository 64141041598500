import { TitularTooltip } from '@campfire/titular-tooltip';
import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import { Box, BoxProps, ClickAwayListener, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { CheckCircle, Cancel, Help } from '@material-ui/icons';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { Avatar } from '../../../../../general/user-profile/components/Avatar';
import { RosterGridData } from './activity-roster-model';
import { ActivityTimelineFutureDraftRosterPublishedRoster } from '../../__generated__/ActivityTimelineFutureDraftRosterPublishedRoster';

type Props = {
  rosterGridData?: RosterGridData;
  hasOpenRoster: boolean;
  publishedRoster: ActivityTimelineFutureDraftRosterPublishedRoster | null;
} & BoxProps;

const ActivityRosterSubheaderBox = (props: Props) => {
  const { rosterGridData, hasOpenRoster, publishedRoster, ...rest } = props;
  const [controlledVolunteersTooltipOpen, setControlledVolunteersTooltipOpen] = useState(false);
  const { isMobile } = useCampfireTheme();

  const publishedByVolunteer = !publishedRoster?.publishedByManager;
  const publishedBy = useMemo(
    () =>
      publishedRoster?.publishedByManager
        ? `${publishedRoster.publishedByManager.profile.preferredName} ${publishedRoster.publishedByManager.profile.lastName}`
        : publishedRoster?.publishedByVolunteer
        ? `${publishedRoster.publishedByVolunteer.profile.preferredName} ${publishedRoster.publishedByVolunteer.profile.lastName}`
        : null,
    [publishedRoster?.publishedByManager, publishedRoster?.publishedByVolunteer]
  );

  const publishedDate = useMemo(
    () => (publishedRoster?.datePublished ? formatDistance(unpackToDateTime(publishedRoster?.datePublished)) : ''),
    [publishedRoster?.datePublished]
  );

  const rosteredBois = useMemo(
    () =>
      rosterGridData?.filter((x) =>
        x.rosterSessions.find((rosterSession) => rosterSession.activeStatus === 'publishedSelected')
      ),
    [rosterGridData]
  );

  const smsLogConfirmationsSent = useMemo(
    () => publishedRoster?.rosterings.filter((r) => !!r.smsLogs.find((l) => l.direction === false)).length ?? 0,
    [publishedRoster]
  );

  const attendingConfirmations = useMemo(
    () =>
      rosterGridData?.filter(
        (x) =>
          x.rosterSessions.find((rosterSession) => rosterSession.activeStatus === 'publishedSelected') && x.attending
      ),
    [rosterGridData]
  );

  const removedAttendingConfirmations = useMemo(
    () =>
      rosterGridData?.filter(
        (x) => x.rosterSessions.find((rosterSession) => rosterSession.activeStatus === undefined) && x.attending
      ),
    [rosterGridData]
  );

  const notAttendingConfirmations = useMemo(
    () =>
      rosterGridData?.filter(
        (x) =>
          x.rosterSessions.find((rosterSession) => rosterSession.activeStatus === 'publishedSelected') &&
          x.attending === false
      ),
    [rosterGridData]
  );

  const nullAttendingConfirmations = useMemo(
    () =>
      rosterGridData?.filter(
        (x) =>
          x.rosterSessions.find((rosterSession) => rosterSession.activeStatus === 'publishedSelected') &&
          x.attending === null
      ),
    [rosterGridData]
  );

  const tooltipDescription = useMemo(
    () => rosteredBois?.map((x) => `${x.volunteer.profile.preferredName} ${x.volunteer.profile.lastName}`).join('\n'),
    [rosteredBois]
  );

  const rosterStatusText = useMemo(
    () =>
      `This activity uses ${hasOpenRoster ? 'open' : 'managed'} rosters${
        publishedBy
          ? `, last ${publishedByVolunteer ? 'update was' : 'published by'} ${publishedBy} ${publishedDate}`
          : ''
      }`,
    [hasOpenRoster, publishedBy]
  );

  const rosterSmsLogsConfirmationsSentText = useMemo(
    () => `${smsLogConfirmationsSent > 0 ? smsLogConfirmationsSent : 'No'} attendance confirmations sent`,
    [smsLogConfirmationsSent]
  );

  return (
    <Box display='flex' flexDirection='column' height='auto' {...rest}>
      <Box paddingLeft='16px' paddingTop='8px'>
        <Typography variant='body2' color='textSecondary'>
          {rosterStatusText}
        </Typography>
      </Box>
      <Box paddingLeft='16px' paddingTop='8px'>
        <Typography variant='body2' color='textSecondary'>
          {rosterSmsLogsConfirmationsSentText}
        </Typography>
      </Box>
      <Box paddingX={2} paddingY={1} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
        <TitularTooltip description={'Attending'} placement={'right'}>
          <Box paddingRight={0.5} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
            <Typography variant='body2' color='textSecondary'>
              {attendingConfirmations ? `${attendingConfirmations?.length}` : ''}
            </Typography>
            {attendingConfirmations ? (
              <CheckCircle fontSize='small' htmlColor='#7DD268' style={{ fontSize: 15, marginLeft: 4 }} />
            ) : (
              ''
            )}
          </Box>
        </TitularTooltip>
        <TitularTooltip description={'Not attending'} placement={'right'}>
          <Box paddingX={0.5} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
            <Typography variant='body2' color='textSecondary'>
              {notAttendingConfirmations ? `${notAttendingConfirmations?.length}` : ''}
            </Typography>
            {notAttendingConfirmations ? (
              <Cancel fontSize='small' color='error' style={{ fontSize: 15, marginLeft: 4 }} />
            ) : (
              ''
            )}
          </Box>
        </TitularTooltip>
        <TitularTooltip description={'Unconfirmed'} placement={'right'}>
          <Box paddingX={0.5} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
            <Typography variant='body2' color='textSecondary'>
              {nullAttendingConfirmations ? `${nullAttendingConfirmations?.length}` : ''}
            </Typography>
            {nullAttendingConfirmations ? (
              <Help fontSize='small' htmlColor='#00000024' style={{ fontSize: 15, marginLeft: 4 }} />
            ) : (
              ''
            )}
          </Box>
        </TitularTooltip>
        <TitularTooltip description={'Confirmed but removed from roster'} placement={'right'}>
          <Box paddingX={0.5} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
            <Typography variant='body2' color='textSecondary'>
              {removedAttendingConfirmations ? `${removedAttendingConfirmations?.length}` : ''}
            </Typography>
            {removedAttendingConfirmations ? (
              <CheckCircle fontSize='small' htmlColor='#00000024' style={{ fontSize: 15, marginLeft: 4 }} />
            ) : (
              ''
            )}
          </Box>
        </TitularTooltip>
      </Box>
      <Box paddingX={2} paddingY={0} display='flex' alignContent='center' alignItems='center'>
        {rosteredBois?.length ? (
          <ClickAwayListener onClickAway={() => (isMobile ? setControlledVolunteersTooltipOpen(false) : undefined)}>
            <TitularTooltip
              open={isMobile ? controlledVolunteersTooltipOpen : undefined}
              onClose={() => isMobile && setControlledVolunteersTooltipOpen(false)}
              disableHoverListener={isMobile}
              disableFocusListener={isMobile}
              disableTouchListener={isMobile}
              description={tooltipDescription}
            >
              <Typography
                color='textSecondary'
                variant='body2'
                onClick={() => isMobile && setControlledVolunteersTooltipOpen(true)}
              >
                {`Rostered (${rosteredBois?.length})`}
              </Typography>
            </TitularTooltip>
          </ClickAwayListener>
        ) : (
          <Typography color='textSecondary' variant='body2'>
            {'Nobody is rostered'}
          </Typography>
        )}

        {isMobile ? null : rosteredBois?.length ? (
          <Box display='flex' minHeight={24} paddingLeft={1}>
            {rosteredBois?.map((rosteredBoi) => {
              const volunteer =
                rosteredBoi.__typename === 'enrolled' ? rosteredBoi.activityEnrolment.volunteer : rosteredBoi.volunteer;

              return (
                <Box key={volunteer.volunteerId}>
                  <TitularTooltip description={`${volunteer.profile.preferredName} ${volunteer.profile.lastName}`}>
                    <Box>
                      <Avatar
                        size={24}
                        style={{ marginLeft: -3, marginRight: -3 }}
                        preferredName={volunteer.profile.preferredName}
                        lastName={volunteer.profile.lastName}
                        avatarUrl={volunteer.profile.avatarUrl}
                      />
                    </Box>
                  </TitularTooltip>
                </Box>
              );
            })}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export { ActivityRosterSubheaderBox };
