import { useApiUrl } from '../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../global/network/useCampfireFetch';

export const useDeleteWidget = () => {
  const uri = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  return ({
    dashboardComponentId
  }: {
    dashboardComponentId: string;
  }) =>
  campfireMutation.run({
    url: `${uri}/vm/preferences/dashboard-component/delete`,
    method: 'POST',
    data: {
      dashboardComponentId
    },
  });
};
