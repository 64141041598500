import { useState } from 'react';
import { useBetween } from 'use-between';

export const PAGINATION_BOUNDARY = 100;

const usePagination = () => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(PAGINATION_BOUNDARY);

  return {
    page,
    perPage,
    setPage,
    setPerPage
  }
}

/** There's no way to key shared state, so create other */
const useBulkPagination = () => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(PAGINATION_BOUNDARY);

  return {
    page,
    perPage,
    setPage,
    setPerPage
  }
}

export const useSharedPagination = () => useBetween(usePagination);
export const useBulkSharedPagination = () => useBetween(useBulkPagination);