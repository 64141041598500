import { Theme } from '@material-ui/core/styles';
import { ActivityTimelineFutureDraftRoster } from '../../__generated__/ActivityTimelineFutureDraftRoster';
import { ActivityTimelineFutureDraftRosterActivityEnrolment } from '../../__generated__/ActivityTimelineFutureDraftRosterActivityEnrolment';
import { ActivityTimelineFutureDraftRosterActivitySession } from '../../__generated__/ActivityTimelineFutureDraftRosterActivitySession';
import { ActivityTimelineFutureDraftRosterDraftRostering } from '../../__generated__/ActivityTimelineFutureDraftRosterDraftRostering';
import { ActivityTimelineFutureDraftRosterPublishedRostering } from '../../__generated__/ActivityTimelineFutureDraftRosterPublishedRostering';
import { ActivityTimelineFutureRosterVolunteer } from '../../__generated__/ActivityTimelineFutureRosterVolunteer';
import { GetActivityTimelineFutureDraftRoster } from '../../__generated__/GetActivityTimelineFutureDraftRoster';
import { capRosterGridDataRow } from './activity-roster-helpers';

export const ROSTER_SESSION_STATUS_TEXT = {
  publishedSelected: 'Rostered',
  available: 'Available',
  noIndication: 'No indication',
  unavailable: 'Unavailable',
  selected: 'To be rostered',
  removed: 'To be removed',
};

export const getRosterSessionSessionText = (session: RosterGridDataSession) => {
  if (session.activeStatus === undefined) return ROSTER_SESSION_STATUS_TEXT[session.originalStatus];
  return ROSTER_SESSION_STATUS_TEXT[session.activeStatus];
};

export const ROSTER_CELL_ACTIVE_STATUS: { [key: string]: RosterCellActiveStatus } = {
  selected: 'selected',
  publishedSelected: 'publishedSelected',
  removed: 'removed',
};

type RosterCellOriginalStatus = 'unavailable' | 'noIndication' | 'available';

type RosterCellActiveStatus = undefined | 'selected' | 'publishedSelected' | 'removed';

export type RosterGridData = Array<RosterGridDataRow>;

export type RosterGridDataRow = RosterGridDataActivityEnrolment | RosterGridDataVolunteer;

export type RosterGridDataActivityEnrolment = {
  __typename: 'enrolled';
  volunteerId: string;
  volunteer: ActivityTimelineFutureRosterVolunteer;
  activityEnrolment: ActivityTimelineFutureDraftRosterActivityEnrolment;
  isUnavailable: boolean;
  rosterSessions: Array<RosterGridDataSession>;
  hasActiveChanges: boolean;
  attending: boolean | null;
};

export type RosterGridDataVolunteer = {
  __typename: 'volunteer';
  volunteerId: string;
  volunteer: ActivityTimelineFutureRosterVolunteer;
  rosterSessions: Array<RosterGridDataSession>;
  hasActiveChanges: boolean;
  attending: boolean | null;
};

export type RosterGridDataSession = {
  session: ActivityTimelineFutureDraftRosterActivitySession;
  originalStatus: RosterCellOriginalStatus;
  activeStatus: RosterCellActiveStatus;
  hasActiveChange: boolean;
  first: boolean;
  last: boolean;
};

const getOriginalStatus = (
  activityEnrolment: ActivityTimelineFutureDraftRosterActivityEnrolment,
  sessionId: string
): RosterCellOriginalStatus => {
  if (!activityEnrolment.availability && !activityEnrolment.unavailability) return 'noIndication';

  const matchingSessionAvailability = activityEnrolment.availability?.sessionAvailabilities.find(
    (sessionAvailability) => sessionAvailability.session.sessionId === sessionId
  );

  if (!matchingSessionAvailability) return 'unavailable';
  return 'available';
};

const getActiveStatus = (
  sessionId: string,
  draftRostering?: ActivityTimelineFutureDraftRosterDraftRostering,
  publishedRostering?: ActivityTimelineFutureDraftRosterPublishedRostering
): RosterCellActiveStatus => {
  const draftSessionRostering = draftRostering?.sessionRosterings.find((x) => x.session.sessionId === sessionId);
  const publishedSessionRostering = publishedRostering?.sessionRosterings.find(
    (x) => x.session.sessionId === sessionId
  );

  if (publishedSessionRostering && draftSessionRostering) return 'publishedSelected';
  if (publishedSessionRostering && !draftSessionRostering) return 'removed';
  if (!publishedSessionRostering && draftSessionRostering) return 'selected';
  return undefined;
};

export const getRosterGridData = (data: GetActivityTimelineFutureDraftRoster): RosterGridData => {
  const { activity, draftRoster } = data.vm;

  if (!activity) return [];

  const { sessions, activityEnrolments } = activity;
  const { activeVolunteers } = activity.program;
  const sessionIdsOrdered = activity.sessions.map((x) => x.sessionId);

  // get program volunteers
  const activityEnrolmentVolunteerIds = activityEnrolments.map((x) => x.volunteer.volunteerId);
  const nonEnrolledVolunteers = activeVolunteers.filter((x) => !activityEnrolmentVolunteerIds.includes(x.volunteerId));

  // handle enrolled volunteers
  const enrolledVolunteersGridData: RosterGridData = activityEnrolments
    .map((activityEnrolment) =>
      getRosterGridDataRow({ draftRoster, volunteer: activityEnrolment.volunteer, sessions, activityEnrolment })
    )
    .sort((a, b) => sortActivityEnrolments(a, b, sessionIdsOrdered));

  // handle program volunteers
  const programVolunteersGridData: RosterGridData = nonEnrolledVolunteers
    .map((volunteer) => getRosterGridDataRow({ draftRoster, volunteer, sessions }))
    .sort((a, b) => sortActivityEnrolments(a, b, sessionIdsOrdered));

  return [...enrolledVolunteersGridData, ...programVolunteersGridData];
};

const getRosterGridDataRow = (args: {
  draftRoster: ActivityTimelineFutureDraftRoster | null;
  volunteer: ActivityTimelineFutureRosterVolunteer;
  sessions: Array<ActivityTimelineFutureDraftRosterActivitySession>;
  activityEnrolment?: ActivityTimelineFutureDraftRosterActivityEnrolment;
}): RosterGridDataRow => {
  const { draftRoster, volunteer, sessions, activityEnrolment } = args;
  const { volunteerId } = volunteer;
  const draftRostering = draftRoster?.draftRosterings.find((x) => x.volunteer.volunteerId === volunteerId);
  const publishedRostering = draftRoster?.publishedRoster?.activeRosterings.find((x) => x.volunteer.volunteerId === volunteerId);
  const rostering = draftRoster?.publishedRoster?.rosterings.find((x) => x.volunteer.volunteerId === volunteerId);
  const attending = rostering?.attending ?? null;


  const sessionAvailabilityGridData: Array<RosterGridDataSession> = sessions.map((session) => {
    const originalStatus: RosterCellOriginalStatus = activityEnrolment
      ? getOriginalStatus(activityEnrolment, session.sessionId)
      : 'noIndication';
    const activeStatus: RosterCellActiveStatus = getActiveStatus(session.sessionId, draftRostering, publishedRostering);

    const value: RosterGridDataSession = {
      session,
      originalStatus,
      activeStatus,
      hasActiveChange: activeStatus === 'selected' || activeStatus === 'removed',
      first: true,
      last: true,
    };

    return value;
  });

  const hasActiveChanges = !!sessionAvailabilityGridData.find((x) => x.hasActiveChange);

  return activityEnrolment
    ? capRosterGridDataRow({
        __typename: 'enrolled',
        volunteerId,
        volunteer,
        activityEnrolment,
        isUnavailable: !!activityEnrolment.unavailability,
        hasActiveChanges,
        rosterSessions: sessionAvailabilityGridData,
        attending: attending
      })
    : capRosterGridDataRow({
        __typename: 'volunteer',
        volunteerId,
        volunteer,
        hasActiveChanges,
        rosterSessions: sessionAvailabilityGridData,
        attending: attending
      });
};

export const getUpdatedRosterCellActiveStatus = (value: RosterCellActiveStatus): RosterCellActiveStatus => {
  if (value === 'selected') return undefined;
  if (value === 'removed') return 'publishedSelected';
  if (value === undefined) return 'selected';
  if (value === 'publishedSelected') return 'removed';
  return undefined;
};

const sortActivityEnrolments = (a: RosterGridDataRow, b: RosterGridDataRow, sessionIdsOrdered: Array<string>) => {
  return (
    getActivityEnrolmentSortingWeight(b, sessionIdsOrdered) - getActivityEnrolmentSortingWeight(a, sessionIdsOrdered)
  );
};

/**
 * Return the weighting of a single row from RosterGridData as calculated accross two dimensions: 1) status and 2) the sessions
 *
 * 1) The statuses are weighted by publishedSelected > available > noIndication > unavailable
 * 2) Within any row that has publishSelected present, we give the priority to rosterings who's sessions are first in order within the activity
 *
 * @param row The modelled data for a single row from the RosterGridData
 * @param sessionIdsOrdered The activity's session IDs ordered
 */
const getActivityEnrolmentSortingWeight = (row: RosterGridDataRow, sessionIdsOrdered: Array<string>): number => {
  const baseWeight = 1 / sessionIdsOrdered.length;

  const sessionWeighting = row.rosterSessions.reduce((prev, current) => {
    const index =
      current.activeStatus === 'publishedSelected' || current.activeStatus === 'selected'
        ? 1 / (sessionIdsOrdered.findIndex((x) => x === current.session.sessionId) + 1)
        : 0;
    return index > prev ? index : prev;
  }, baseWeight);

  const isRostered = !!row.rosterSessions.find(
    (x) => x.activeStatus === 'publishedSelected' || x.activeStatus === 'selected'
  );
  if (row.__typename === 'volunteer') return isRostered ? 3 + 1 * sessionWeighting : -1;

  const isAvailable = !!row.activityEnrolment.availability;
  const isUnavailable = !!row.activityEnrolment.unavailability;

  if (isRostered) return 3 + 1 * sessionWeighting;
  if (isUnavailable) return 0;
  if (!isAvailable && !isUnavailable) return 1;
  return 2;
};

export const getOriginalStatusColor = (session: RosterGridDataSession) => {
  if (session.originalStatus === 'unavailable') return '#8A8A8A';
  return '#D2D2D2';
};

export const getActiveStatusColor = (session: RosterGridDataSession, theme: Theme) => {
  if (session.activeStatus === 'publishedSelected') return theme.color.rosters.rostered;
  if (session.activeStatus === 'selected') return theme.color.rosters.selected;
  if (session.activeStatus === 'removed') return theme.color.rosters.removed;
  return theme.color.rosters.empty;
};
