import React, { useMemo } from 'react';
import { Box, Typography, Switch } from '@material-ui/core';
import { Field } from '@campfire/field';
import { Form, Formik } from 'formik';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { TabletButton } from '@campfire/tablet-button';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { AdminConsoleTag } from './__generated__/AdminConsoleTag';

interface Props {
  tag?: AdminConsoleTag;
  onClose: () => void;
  onSuccess: (message: string) => void;
}

interface TagsFormValues {
  activityTagId?: string;
  name: string;
  description: EditorState;
  isHidden: boolean;
  isPublic: boolean;
}

export function FormTagDialog({ tag, onClose, onSuccess }: Props) {
  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });

  const initialValues = useMemo(
    () => ({
      name: tag?.name ?? '',
      description: tag?.description
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(tag.description || '{}')))
        : EditorState.createEmpty(),
      isHidden: tag?.isHidden ?? false,
      isPublic: tag?.isPublic ?? false,
    }),
    [tag]
  );

  const handleSubmit = (vals: TagsFormValues) => {
    if (tag?.activityTagId) {
      const activityTagId = tag?.activityTagId ?? '';
      updateTag({ ...vals, activityTagId });
    } else {
      createTag(vals);
    }
  };

  const createTag = (data: TagsFormValues) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity/tag/save`,
        method: 'post',
        data: {
          ...data,
          description: JSON.stringify(convertToRaw(data.description.getCurrentContent())),
        },
      })
      .then(() => {
        onSuccess('A new tag have been created!');
      });
  };

  const updateTag = (data: TagsFormValues) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity/tag/save`,
        method: 'post',
        data: {
          ...data,
          description: JSON.stringify(convertToRaw(data.description.getCurrentContent())),
        },
      })
      .then(() => {
        onSuccess('Updated tag!');
      });
  };

  return (
    <FullscreenDialog open close={onClose} title='Add Tag' maxWidth='xs' fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(vals) => {
          handleSubmit(vals);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box mt='20px'>
              <Field variant='outlined' label='Tag Title' name='name' required autoFocus />
              <Box marginTop='1.25rem'>
                <CampfireRichEditor
                  placeholder='Add a description for this tag (eg. location, tasks, etc.)…'
                  editorState={values.description}
                  setEditorState={(eState) => {
                    setFieldValue('description', eState);
                  }}
                />
              </Box>
              <Box display='flex' mt='18px' pt='10px'>
                <Switch
                  name='isHidden'
                  checked={values.isHidden}
                  onChange={() => setFieldValue('isHidden', !values.isHidden)}
                  inputProps={{ 'aria-label': 'bulk view checkbox' }}
                  color='primary'
                />
                <Box>
                  <Typography
                    style={{ fontSize: '13px', color: '#000000b0', fontWeight: 500, textTransform: 'uppercase' }}
                  >
                    {`Hidden from Volunteers`}
                  </Typography>
                  <Typography style={{ fontSize: '12px', color: '#808080' }}>
                    {`This tag will be hidden from volunteers, and only visible to program managers and admins.`}
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' mt='5px' pt='10px'>
                <Switch
                  name='isPublic'
                  checked={values.isPublic}
                  onChange={() => setFieldValue('isPublic', !values.isPublic)}
                  inputProps={{ 'aria-label': 'bulk view checkbox' }}
                  color='primary'
                />
                <Box>
                  <Typography
                    style={{ fontSize: '13px', color: '#000000b0', fontWeight: 500, textTransform: 'uppercase' }}
                  >
                    {`Publicly Visible`}
                  </Typography>
                  <Typography style={{ fontSize: '12px', color: '#808080' }}>
                    {`This tag will be publicly visible through Volaby integrations. These will not display if your organisation is not using any of these widgets or integrations.`}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display='flex' marginTop='1.25rem' width='100%' justifyContent='flex-end'>
              <TabletButton onClick={onClose} style={{ marginRight: '8px' }}>
                Cancel
              </TabletButton>
              <TabletButton variant='contained' type='submit' color='primary'>
                Done
              </TabletButton>
            </Box>
          </Form>
        )}
      </Formik>
    </FullscreenDialog>
  );
}
