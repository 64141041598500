import React from 'react';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { getSemanticTime } from '@campfire/hot-date';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { DateTime } from 'luxon';

interface SemanticTimeProps {
  dateTime: DateTime;
  typographyProps?: TypographyProps;
  displayYear?: boolean;
}

const SemanticTime = ({ dateTime, typographyProps, displayYear }: SemanticTimeProps) => {
  const regularDate = dateTime.toLocaleString({ weekday: 'short', day: 'numeric', month: 'short' }).replace(',', '');
  return (
    <TitularTooltip description={regularDate}>
      <Typography {...typographyProps} component='span'>
        {getSemanticTime(dateTime, displayYear)}
      </Typography>
    </TitularTooltip>
  );
};

export { SemanticTime };
