import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { VolunteerDatabaseBaseVolunteer } from './__generated__/VolunteerDatabaseBaseVolunteer';
import { VolunteerDatabaseActions } from './volunteer-database-model.gql';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useApiUrl } from '../../../../global/config/useApiUrl';

export const VolunteerDatabaseRetireDialog = ({
  open,
  onClose,
  volunteer,
  retireVolunteer,
  reloadDatabase,
}: {
  open: boolean;
  onClose: () => void;
  volunteer: VolunteerDatabaseBaseVolunteer;
  retireVolunteer: VolunteerDatabaseActions['retireVolunteer'];
  reloadDatabase?: () => void;
}) => {
  const apiUrl = useApiUrl();
  const { isLoading, response, run } = useCampfireFetch({ defer: true });

  const runRetireVolunteer = () => {
    run({
      url: `${apiUrl}/vm/volunteer/manage/retire`,
      method: 'post',
      data: {
        volunteerId: volunteer.volunteerId,
      },
    });
  };

  useEffect(() => {
    if (response && response.ok) {
      retireVolunteer(volunteer);
      if (reloadDatabase) reloadDatabase();
      onClose();
    }
  }, [response]);

  const { theme } = useCampfireTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='retire-volunteer-dialog-title'
      aria-describedby='retire-volunteer-dialog-description'
    >
      <DialogTitle id='retire-volunteer-dialog-title'>{`Retire Volunteer?`}</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Box paddingLeft={2} paddingRight={2}>
          <DialogContentText>{`${volunteer.profile.preferredName} ${volunteer.profile.lastName}`}</DialogContentText>
          <DialogContentText id='retire-volunteer-dialog-description'>
            {`While retired a volunteer cannot access Volaby. Volunteers can be reactivated at any time.`}
          </DialogContentText>
        </Box>
        {isLoading ? <LinearProgressOverlay isLoading /> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{`Cancel`}</Button>
        <Button
          onClick={runRetireVolunteer}
          autoFocus
          style={{
            color: theme.palette.error.main,
          }}
        >{`Retire Volunteer`}</Button>
      </DialogActions>
    </Dialog>
  );
};
