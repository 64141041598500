import { Box, Grid, Typography } from '@material-ui/core';
import { HoverText } from '@campfire/hover-link';
import React from 'react';
import { useHistory } from 'react-router';
import { ViewAllIncompleteReportsBlock } from './ViewIncompleteReportsBlock';

interface Props {
  hasRosterings: boolean;
}

export const ReportsAllCompleteBlock = (props: Props) => {
  const history = useHistory();
  return (
    <Box maxWidth='500px' width={1}>
      <Grid container item xs alignItems='center' alignContent='center' justify='center' direction='column' spacing={2}>
        <Grid item xs style={{ paddingTop: 64, paddingBottom: 64 }}>
          <Typography variant='h2' style={{ fontWeight: 500 }}>
            Start a report
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>{`🎉 Well done! Your reports for the last month are all up to date.`}</Typography>
        </Grid>
        <Grid item xs>
          <Typography component='span'>
            {`Check out your personal impact profile on `}
            <HoverText
              color='primary'
              style={{ display: 'inline' }}
              onClick={() => history.push('/warmth/my-elements')}
            >
              My Elements
            </HoverText>
            {` `}
          </Typography>
        </Grid>
        <ViewAllIncompleteReportsBlock hasRosterings={props.hasRosterings} />
      </Grid>
    </Box>
  );
};
