import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const IncomingVolunteersPageWrapper: FunctionComponent = ({ children }) => {
  return (
    <Grid
      container
      style={{
        overflow: 'hidden',
        flexWrap: 'nowrap',
        flex: 1,
      }}
    >
      {children}
    </Grid>
  );
};

export { IncomingVolunteersPageWrapper };
