import React from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Typography } from '@material-ui/core';
import { Close, WarningRounded } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { PopUpWindow } from '../../../../../../common/PopUpWindow';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

interface Props {
  onCancel: () => void;
  onOK: () => void;
  onTick: (tick: boolean) => void;
}

export const MaxVolunteersPrompt = (props: Props) => {
  const { onCancel, onOK, onTick } = props;
  const { isMobile, isMd } = useCampfireTheme();

  const useCheckboxStyles = makeStyles(() =>
    createStyles({
      checkbox: {
        '&.Mui-checked': {
          color: '#7570EA',
        },
      },
    })
  );

  const checkboxClasses = useCheckboxStyles();

  return (
    <PopUpWindow fixed transparent center>
      <Box
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 2.752269744873047px 13.761348724365234px 0px #00000029',
          width: '600px',
          borderRadius: '8px',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          padding={'20px 25px'}
          alignItems={'center'}
          borderBottom={'1px solid #e3e3e3'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <WarningRounded style={{ color: '#FBBF24' }} />
            <Typography style={{ fontWeight: 700, fontSize: '20px', paddingLeft: '15px' }}>
              Maximum Volunteers Reached
            </Typography>
          </Box>
          <IconButton onClick={onCancel}>
            <Close style={{ opacity: '54%' }} />
          </IconButton>
        </Box>
        <Box padding={'20px 25px'} textAlign={'center'}>
          <Typography>
            You have gone over your maximum volunteer limit for this session, do you still want to add this volunteer
            into the roster?
          </Typography>
          <Box>
            <FormGroup style={{ display: 'block', paddingTop: '25px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={checkboxClasses.checkbox}
                    value='hide'
                    onChange={(e) => {
                      onTick(e.target.value === 'hide');
                    }}
                  />
                }
                style={{ color: '#00000080' }}
                label="Don't show this message for this activity date"
              />
            </FormGroup>
          </Box>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} padding={'0px 25px 30px 25px'}>
          <Button
            style={{
              boxSizing: 'border-box',
              borderRadius: '100px',
              textTransform: 'none',
              color: '#646464',
              marginRight: '10px',
              padding: !isMobile || !isMd ? '7px 12px' : '7px 3px',
              minWidth: '45px',
              width: '25%',
              backgroundColor: '#DCDCDC',
            }}
            onClick={onCancel}
          >
            <Typography
              style={{
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '19px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                padding: '5px',
                display: isMobile || isMd ? 'none' : 'inherit',
              }}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            style={{
              boxSizing: 'border-box',
              borderRadius: '100px',
              textTransform: 'none',
              color: '#ffffff',
              marginRight: '10px',
              padding: !isMobile || !isMd ? '7px 12px' : '7px 3px',
              minWidth: '45px',
              width: '65%',
              backgroundColor: '#796FFF',
            }}
            onClick={onOK}
          >
            <Typography
              style={{
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '19px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                padding: '5px',
                display: isMobile || isMd ? 'none' : 'inherit',
              }}
            >
              Continue and add to roster
            </Typography>
          </Button>
        </Box>
      </Box>
    </PopUpWindow>
  );
};
