import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  Box,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogActions,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  Button,
  Badge,
} from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { encodeDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { ChevronRight, Close as CloseIcon, ErrorRounded, WarningRounded } from '@material-ui/icons';
import { SearchField } from '../inputs/SearchField';
import { useOrgInfo } from '../../global/auth/use-org-info/use-org-info';
import { useCampfireLazyQuery } from '../../global/network/useCampfireLazyQuery';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import {
  GET_SELECT_VOLUNTEERS_BY_ACTIVITY,
  GET_SELECT_VOLUNTEERS_BY_ORG,
  GET_SELECT_VOLUNTEERS_BY_PROGRAM,
} from './get-select-volunteers.gql';
import { Avatar } from '../../screens/general/user-profile/components/Avatar';
import { GetSelectVolunteerFragment } from './__generated__/GetSelectVolunteerFragment';
import {
  GetSelectVolunteersByActivity,
  GetSelectVolunteersByActivityVariables,
} from './__generated__/GetSelectVolunteersByActivity';
import { GetSelectVolunteersByOrg, GetSelectVolunteersByOrgVariables } from './__generated__/GetSelectVolunteersByOrg';
import {
  GetSelectVolunteersByProgram,
  GetSelectVolunteersByProgramVariables,
} from './__generated__/GetSelectVolunteersByProgram';
import { ConfirmSelectMaxVolunteer } from './ConfirmSelectMaxVolunteer';

const SELECT_GROUP = 1;
const ADD_VOLUNTEER = 2;
type GroupType = 'activity' | 'program' | 'org';

type ActivityActionType = {
  activityId: string;
  name: string;
  program: {
    programId: string;
    name: string;
  };
};

export const SelectVolunteersDialog = ({
  open,
  activity,
  ignoredVolunteerIds,
  handleClose,
  handleAdd,
  enableGroupByActivity = true,
  dialogContentText,
  maxSelected,
}: {
  open: boolean;
  enableGroupByActivity?: boolean;
  ignoredVolunteerIds: string[];
  activity: ActivityActionType;
  dialogContentText?: string;
  maxSelected?: number | null;
  handleClose: () => void;
  handleAdd: (vols: GetSelectVolunteerFragment[]) => void;
}) => {
  const { theme } = useCampfireTheme();
  const { name } = useOrgInfo() || {};
  const [step, setStep] = useState(SELECT_GROUP);
  const [selectedGroup, setSelectedGroup] = useState<GroupType>('activity');
  const [searchFilter, setSearchFilter] = useState<string>();
  const [selectedVolunteers, setSelectedVolunteers] = useState<Array<GetSelectVolunteerFragment>>([]);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleSelectGroup = (group: GroupType) => {
    setSelectedGroup(group);
    setStep(ADD_VOLUNTEER);
  };

  const onClickAdd = () => {
    if (isExceeded) {
      setOpenConfirm(true);
    } else {
      handleAdd(selectedVolunteers);
      onClose();
    }
  };

  const onClose = () => {
    setStep(SELECT_GROUP);
    setSearchFilter(undefined);
    handleClose();
  };

  const onBack = () => {
    setSearchFilter('');
    setSelectedGroup('activity');
    setStep(SELECT_GROUP);
  };

  const hasValidMaxSelected = maxSelected !== null && maxSelected !== undefined;
  const isWarning = hasValidMaxSelected && selectedVolunteers.length === maxSelected;
  const isExceeded = hasValidMaxSelected && selectedVolunteers.length > maxSelected!;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid container justify='space-between'>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <Typography
                style={{ fontSize: '24px', fontWeight: 500, marginRight: '16px', color: theme.color.grey.neutral500 }}
              >{`Add Volunteers`}</Typography>
              {isWarning ? <MaxWarning warning /> : null}
              {isExceeded ? <MaxWarning exceeded /> : null}
            </Box>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              {step === ADD_VOLUNTEER && selectedGroup !== 'org' && (
                <SearchField
                  placeholder='Search Volunteers'
                  growLeft
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              )}
              <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
                <CloseIcon color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box pr={6}>
          <Typography style={{ fontSize: 14 }} color='textSecondary'>
            {dialogContentText ||
              `Select one or more volunteers to add to the program. Hit 'Cancel' to close this window.`}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers id='dialog-content' style={{ padding: 0 }}>
        {step === SELECT_GROUP && (
          <Box>
            <List
              dense
              style={{
                width: '100%',
              }}
            >
              {enableGroupByActivity && (
                <>
                  <ListItem
                    button
                    onClick={() => handleSelectGroup('activity')}
                    style={{ paddingTop: '12px', paddingBottom: '12px' }}
                  >
                    <ListItemText
                      primary={<Typography style={{ fontWeight: 500 }}>{activity.name}</Typography>}
                      secondary={'Activity Team'}
                      style={{ color: theme.color.grey.neutral500, padding: '0px 18px' }}
                    />
                    <ListItemSecondaryAction>
                      <ChevronRight style={{ color: theme.color.grey.neutral300, paddingRight: '18px' }} />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider component='li' style={{ margin: '0px 16px' }} />
                </>
              )}

              <ListItem
                button
                onClick={() => handleSelectGroup('program')}
                style={{ paddingTop: '12px', paddingBottom: '12px' }}
              >
                <ListItemText
                  primary={<Typography style={{ fontWeight: 500 }}>{activity.program.name}</Typography>}
                  secondary={'Program Volunteers'}
                  style={{ color: theme.color.grey.neutral500, padding: '0px 18px' }}
                />
                <ListItemSecondaryAction>
                  <ChevronRight style={{ color: theme.color.grey.neutral300, paddingRight: '18px' }} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider component='li' style={{ margin: '0 16px' }} />
              <ListItem
                button
                onClick={() => handleSelectGroup('org')}
                style={{ paddingTop: '12px', paddingBottom: '12px' }}
              >
                <ListItemText
                  primary={<Typography style={{ fontWeight: 500 }}>{name}</Typography>}
                  secondary={'Organisation Volunteers'}
                  style={{ color: theme.color.grey.neutral500, padding: '0px 18px' }}
                />
                <ListItemSecondaryAction>
                  <ChevronRight style={{ color: theme.color.grey.neutral300, paddingRight: '18px' }} />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Box>
        )}

        {step === ADD_VOLUNTEER && (
          <SelectVolunterByGroup
            enableGroupByActivity={enableGroupByActivity}
            group={selectedGroup}
            activityId={activity.activityId}
            searchFilter={searchFilter}
            ignoredVolunteerIds={ignoredVolunteerIds}
            selectedVolunteers={selectedVolunteers}
            setSelectedVolunteers={setSelectedVolunteers}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box padding={2}>
          {step === SELECT_GROUP ? (
            <TabletButton variant='text' color='error' onClick={onClose} style={{ marginRight: 8 }}>
              {'Cancel'}
            </TabletButton>
          ) : (
            <TabletButton variant='text' color='error' onClick={onBack} style={{ marginRight: 8 }}>
              {'Back'}
            </TabletButton>
          )}
          <TabletButton
            data-track='actCnl-add-volunteers'
            variant='contained'
            disabled={step === SELECT_GROUP}
            color='primary'
            type='submit'
            onClick={onClickAdd}
          >
            {'Add'}
          </TabletButton>
        </Box>
      </DialogActions>
      {openConfirm && (
        <ConfirmSelectMaxVolunteer
          onCancel={() => setOpenConfirm(false)}
          onOK={() => {
            handleAdd(selectedVolunteers);
            onClose();
          }}
        />
      )}
    </Dialog>
  );
};

const SelectVolunterByGroup = ({
  enableGroupByActivity,
  group,
  activityId,
  searchFilter,
  ignoredVolunteerIds,
  selectedVolunteers,
  setSelectedVolunteers,
}: {
  enableGroupByActivity: boolean;
  group: GroupType;
  activityId: string;
  ignoredVolunteerIds: string[];
  searchFilter?: string;
  selectedVolunteers: GetSelectVolunteerFragment[];
  setSelectedVolunteers: (vols: GetSelectVolunteerFragment[]) => void;
}) => {
  const [searchVolunteerInput, setSearchVolunteerInput] = useState<string>();
  const [getVolunteersByActivity, { loading: loadingGetVolunteersByActivity }] = useCampfireLazyQuery<
    GetSelectVolunteersByActivity,
    GetSelectVolunteersByActivityVariables
  >(GET_SELECT_VOLUNTEERS_BY_ACTIVITY);

  const [getVolunteersByProgram, { loading: loadingGetVolunteersByProgram }] = useCampfireLazyQuery<
    GetSelectVolunteersByProgram,
    GetSelectVolunteersByProgramVariables
  >(GET_SELECT_VOLUNTEERS_BY_PROGRAM);

  const [getVolunteersByOrg, { loading: loadingGetVolunteersByOrg }] = useCampfireLazyQuery<
    GetSelectVolunteersByOrg,
    GetSelectVolunteersByOrgVariables
  >(GET_SELECT_VOLUNTEERS_BY_ORG);

  const [volunteers, setVolunteers] = useState<GetSelectVolunteerFragment[]>([]);

  useEffect(() => forceCheck(), [searchFilter]);

  useEffect(() => {
    if (group === 'activity' && enableGroupByActivity) {
      getVolunteersByActivity({
        variables: {
          activityId,
        },
      }).then((response) => {
        const activityEnrolments = response.data?.vm.activity?.activityEnrolments;
        setVolunteers(
          activityEnrolments
            ?.map((item) => item.volunteer)
            ?.filter((v) => !ignoredVolunteerIds.includes(v.volunteerId)) || []
        );
      });
    }
    if (group === 'program') {
      getVolunteersByProgram({
        variables: {
          activityId,
        },
      }).then((response) => {
        const activeVolunteers = response.data?.vm.activity?.program.activeVolunteers;
        setVolunteers(activeVolunteers?.filter((v) => !ignoredVolunteerIds.includes(v.volunteerId)) || []);
      });
    }
  }, [group, enableGroupByActivity]);

  function handleToggle(volunteer: GetSelectVolunteerFragment) {
    const selectedVolunteer = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
    if (!selectedVolunteer) {
      setSelectedVolunteers([...selectedVolunteers, volunteer]);
      return;
    }
    setSelectedVolunteers(selectedVolunteers.filter((x) => x.volunteerId !== volunteer.volunteerId));
  }

  const handleSearchOrg = () => {
    if (group === 'org') {
      if (searchVolunteerInput) {
        getVolunteersByOrg({
          variables: {
            keywordsFilter: searchVolunteerInput,
            startDate: encodeDate(DateTime.fromObject({ year: 1970, month: 1, day: 1 })),
            endDate: encodeDate(DateTime.local()),
          },
        }).then((response) => {
          setVolunteers(
            response.data?.vm.programActiveVolunteersConnection.edges
              .map((edge) => edge.node)
              .filter((v) => !ignoredVolunteerIds.includes(v.volunteerId)) || []
          );
        });
      } else {
        setVolunteers([]);
      }
    }
  };
  const isLoading =
    (group === 'activity' && loadingGetVolunteersByActivity) ||
    (group === 'program' && loadingGetVolunteersByProgram) ||
    (group === 'org' && loadingGetVolunteersByOrg);

  const filteredVolunteers = volunteers.filter((volunteer) =>
    searchFilter
      ? volunteer.profile.preferredName
          .concat(` ${volunteer.profile.lastName}`)
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
      : true
  );

  return (
    <Box>
      {group === 'org' && (
        <Box px={3} mt={2} display='flex' alignItems='center'>
          <Box width={'280px'}>
            <SearchField
              placeholder='Search by name, email'
              onChange={(e) => setSearchVolunteerInput(e.target.value)}
              handleEnterPress={handleSearchOrg}
              fullWidth
              growLeft
            />
          </Box>
          <Button variant={'text'} color='primary' onClick={handleSearchOrg}>
            Search
          </Button>
        </Box>
      )}
      {isLoading ? (
        <Box p={4}>{'Loading...'}</Box>
      ) : filteredVolunteers.length > 0 ? (
        <List style={{ width: '100%' }}>
          {filteredVolunteers.map((volunteer) => {
            const isSelected = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
            return (
              <LazyLoad
                key={volunteer.volunteerId}
                scrollContainer={'#dialog-content'}
                height={66}
                offset={400}
                overflow
              >
                <VolunteerListItem isSelected={!!isSelected} volunteer={volunteer} handleToggle={handleToggle} />
              </LazyLoad>
            );
          })}
        </List>
      ) : (
        <Box p={4}>{group === 'org' ? '' : 'No volunteers.'}</Box>
      )}
    </Box>
  );
};

const VolunteerListItem = React.memo(
  (props: {
    volunteer: GetSelectVolunteerFragment;
    isSelected: boolean;
    handleToggle: (volunteer: GetSelectVolunteerFragment) => void;
  }) => {
    const { isSelected, volunteer, handleToggle } = props;
    const {
      profile: { preferredName, lastName, avatarUrl },
    } = volunteer;
    const volunteerName = `${preferredName} ${lastName}`;
    return (
      <ListItem
        button
        onClick={() => handleToggle(volunteer)}
        style={{
          paddingLeft: 24,
          paddingRight: 32,
        }}
      >
        <Box
          component='li'
          display='flex'
          alignContent='center'
          alignItems='center'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <FormControlLabel label='' control={<Checkbox checked={isSelected} color='primary' />} />
          <Avatar preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} size={32} />
          <Typography style={{ marginLeft: 8 }}>{volunteerName}</Typography>
        </Box>
      </ListItem>
    );
  }
);

const MaxWarning = ({ warning, exceeded }: { warning?: boolean; exceeded?: boolean }) => {
  const backgroundColor = warning ? '#efb31a' : exceeded ? '#D93A00' : '';
  const message = warning ? 'Max Reached' : exceeded ? 'Max Exceeded' : '';
  return (
    <Badge
      style={{
        backgroundColor: backgroundColor,
        color: '#ffffff',
        padding: '6px',
        borderRadius: '3px',
        height: 'fit-content',
      }}
    >
      {warning ? (
        <WarningRounded style={{ fontSize: '16px' }} />
      ) : exceeded ? (
        <ErrorRounded style={{ fontSize: '16px' }} />
      ) : (
        ''
      )}
      <Typography style={{ fontSize: '12px', paddingLeft: '4px', fontWeight: 500, paddingRight: '2px' }}>
        {message}
      </Typography>
    </Badge>
  );
};
