import { gql } from '@apollo/client';

export const GET_ROSTER_SMS_CONFIRMATION_DATA = gql`
  query GetRosterSmsConfirmationData($activityId: String!, $activityDate: Date!) {
    vm {
      draftRoster(activityId: $activityId, activityDate: $activityDate) {
        ...RosterSmsConfirmationDraftRoster
      }
    }
  }

  fragment RosterSmsConfirmationDraftRoster on VOLUNTEER_DraftRosterType {
    draftRosterId
    publishedRoster {
      rosterId
      datePublished
      publishedByManager {
        profile {
          preferredName
          lastName
        }
      }
      publishedByVolunteer {
        profile {
          preferredName
          lastName
        }
      }
      rosterings {
        ...RosterSmsConfirmationPublishedRosterRostering
      }
      activeRosterings {
        ...RosterSmsConfirmationPublishedRosterRostering
      }
    }
  }


  fragment RosterSmsConfirmationPublishedRosterRostering on VOLUNTEER_PublishedRosteringType {
    publishedRosteringId
    attending
    volunteer {
      volunteerId
      profile {
        profileId
        preferredName
        lastName
        avatarUrl
      }
    }
    sessionRosterings {
      publishedSessionRosteringId
      session {
        sessionId
      }
    }
  }
`;
