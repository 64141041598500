import { getDurationHoursMin, unpackToTime } from '@campfire/hot-date';
import GoogleMapReact, { Bounds, Coords, Size } from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import { DateTime } from 'luxon';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useRegionCodes } from '../../../common/auto-location-field/use-region-codes/use-region-codes';
import { ActivitiesMapMarker, ActivitiesSkinnyMapMarker } from './ActivitiesMapMarkers';
import { getDayTimeIndicator } from './Activity/activity-display-utils';

type MapActivity = {
  activityId: string;
  name: string;
  startTime: string;
  endTime: string;
  isSuspended: boolean;
  isActive: boolean;
  activityLocation: {
    placesAddress: {
      latLong: { lat: number; long: number };
    };
  } | null;
};
const MINOR_ZOOM_THRESHOLD = 14;
const MAP_EDGE_OFFSET = 0.15;
const AU_OFFSET = 1;

const DEFAULT_AU_ZOOM = 5;
const DEFAULT_NZ_ZOOM = 6;
const DEFAULT_NORTH_AMERICA_ZOOM = 4;
const DEFAULT_GB_ZOOM = 6;

const DEFAULT_AU_CENTER = { lat: -25.7349, lng: 134.4896 };
const DEFAULT_NZ_CENTER = { lat: -41.284566, lng: 174.775461 };
const DEFAULT_NORTH_AMERICA_CENTER = { lat: 38.575631, lng: -98.36957 };
const DEFAULT_GB_CENTER = { lat: 53.73613, lng: -3.988229 };

const INIT_SIZE = {
  width: 50,
  height: 50,
};

function getDefaultAuCenter() {
  return {
    nw: { lat: DEFAULT_AU_CENTER.lat - AU_OFFSET, lng: DEFAULT_AU_CENTER.lng - AU_OFFSET },
    se: { lat: DEFAULT_AU_CENTER.lat + AU_OFFSET, lng: DEFAULT_AU_CENTER.lng + AU_OFFSET },
  };
}

function getDefaultNzCenter() {
  return {
    nw: { lat: DEFAULT_NZ_CENTER.lat - AU_OFFSET, lng: DEFAULT_NZ_CENTER.lng - AU_OFFSET },
    se: { lat: DEFAULT_NZ_CENTER.lat + AU_OFFSET, lng: DEFAULT_NZ_CENTER.lng + AU_OFFSET },
  };
}

function getDefaultNorthAmericaCenter() {
  return {
    nw: { lat: DEFAULT_NORTH_AMERICA_CENTER.lat - AU_OFFSET, lng: DEFAULT_NORTH_AMERICA_CENTER.lng - AU_OFFSET },
    se: { lat: DEFAULT_NORTH_AMERICA_CENTER.lat + AU_OFFSET, lng: DEFAULT_NORTH_AMERICA_CENTER.lng + AU_OFFSET },
  };
}

function getDefaultGBCenter() {
  return {
    nw: { lat: DEFAULT_GB_CENTER.lat - AU_OFFSET, lng: DEFAULT_GB_CENTER.lng - AU_OFFSET },
    se: { lat: DEFAULT_GB_CENTER.lat + AU_OFFSET, lng: DEFAULT_GB_CENTER.lng + AU_OFFSET },
  };
}

function getActivityLocationsBounds(activities: MapActivity[]) {
  const lats: Array<number> = [];
  const lngs: Array<number> = [];
  activities.forEach((activity) => {
    if (activity.activityLocation) {
      lats.push(activity.activityLocation.placesAddress.latLong.lat);
      lngs.push(activity.activityLocation.placesAddress.latLong.long);
    }
  });
  const maxLat = Math.max(...lats);
  const minLat = Math.min(...lats);
  const maxLng = Math.max(...lngs);
  const minLng = Math.min(...lngs);

  return {
    nw: { lat: maxLat - MAP_EDGE_OFFSET, lng: minLng - MAP_EDGE_OFFSET },
    se: { lat: minLat + MAP_EDGE_OFFSET, lng: maxLng + MAP_EDGE_OFFSET },
  };
}

type ActivityMapInnerProps = ActivitiesMapProps & { initialCentre: Omit<Bounds, 'ne' | 'sw'>; initialZoom: number };

const ActivitiesMapInner = memo((props: ActivityMapInnerProps) => {
  const {
    showSkinnyMarkers,
    activities,
    initialCentre,
    initialZoom,
    hoveredActivityId,
    selectedActivityId,
    targetedActivityId,
    setHoveredActivityId,
    setSelectedActivityId,
  } = props;

  const [defaultCenter, setDefaultCenter] = useState<Coords>();
  const [defaultZoom, setDefaultZoom] = useState<number>(MINOR_ZOOM_THRESHOLD);
  const [mapCenter, setMapCenter] = useState<Coords>();
  const [mapZoom, setMapZoom] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  function recenterMap(recenterBounds: Partial<Bounds>, recenterSize: Size, recenterActivities: MapActivity[]) {
    const { center, zoom } = fitBounds(recenterBounds, recenterSize);
    const normalizedZoomMax = zoom > MINOR_ZOOM_THRESHOLD ? MINOR_ZOOM_THRESHOLD : zoom;
    const normalizedZoomMin = normalizedZoomMax <= 0 ? 1 : normalizedZoomMax;
    const finalZoom = recenterActivities.length ? normalizedZoomMin : initialZoom;
    setDefaultCenter(center);
    setMapCenter(center);
    setDefaultZoom(finalZoom);
    setMapZoom(finalZoom);
  }

  function updateCenter(centerActivities: MapActivity[], centerActivityId?: string) {
    if (!centerActivityId) {
      resetZoomCenter();
      return;
    }

    const targetActivity = centerActivities.find((activity) => activity.activityId === centerActivityId);
    const center =
      targetActivity && targetActivity.activityLocation
        ? {
            lat: targetActivity.activityLocation.placesAddress.latLong.lat,
            lng: targetActivity.activityLocation.placesAddress.latLong.long,
          }
        : mapCenter;
    setMapCenter(center);

    if (selectedActivityId) {
      setMapZoom(MINOR_ZOOM_THRESHOLD);
      return;
    }
    setMapZoom(defaultZoom >= MINOR_ZOOM_THRESHOLD ? defaultZoom + 0.5 : defaultZoom + 2);
  }

  function resetZoomCenter() {
    setMapZoom(defaultZoom);
    setMapCenter(defaultCenter);
  }

  const targetActivity = (activityId?: string) => {
    if (!activityId) return;
    updateCenter(activities, activityId);
  };

  const handleMapBoundsUpdate = (mapActivities: MapActivity[]) => {
    if (!activities.length) {
      recenterMap(initialCentre, INIT_SIZE, []);
      return;
    }
    if (ref.current !== null) {
      const mapSize = {
        width: ref.current.getBoundingClientRect().width - 10,
        height: ref.current.getBoundingClientRect().height - 10,
      };
      const mapBounds = getActivityLocationsBounds(mapActivities);
      recenterMap(mapBounds, mapSize, mapActivities);
      if (selectedActivityId) targetActivity(selectedActivityId);
    }
  };

  useEffect(() => handleMapBoundsUpdate(activities), [activities]);
  useEffect(() => targetActivity(selectedActivityId), [selectedActivityId]);
  useEffect(() => targetActivity(targetedActivityId), [targetedActivityId]);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={ref}>
      {mapCenter && mapZoom ? (
        <GoogleMapReact
          // todo also don't forget to lock down the Maps API for prod
          bootstrapURLKeys={{ key: 'AIzaSyAtLoNynuMVQAXXvaaxXG5PFB2IXja6f2E' }}
          center={mapCenter}
          zoom={mapZoom}
          yesIWantToUseGoogleMapApiInternals
          options={{
            clickableIcons: false,
          }}
        >
          {showSkinnyMarkers
            ? activities.map((activity) => {
                // this is needed to make typescript happy that we can destructure the location
                if (!activity.activityLocation) {
                  return false;
                }

                const {
                  activityLocation: {
                    placesAddress: {
                      latLong: { lat: activityLocationLat, long: activityLocationLong },
                    },
                  },
                  name,
                  activityId,
                } = activity;

                return (
                  <ActivitiesSkinnyMapMarker
                    lat={activityLocationLat}
                    lng={activityLocationLong}
                    name={name}
                    key={activityId}
                    id={activityId}
                    onClick={() => setSelectedActivityId(activityId)}
                  />
                );
              })
            : activities.map((activity) => {
                // this is needed to make typescript happy that we can destructure the location
                if (!activity.activityLocation) {
                  return false;
                }

                const {
                  activityLocation: {
                    placesAddress: {
                      latLong: { lat: activityLocationLat, long: activityLocationLong },
                    },
                  },
                  name,
                  activityId,
                  startTime,
                  endTime,
                  isSuspended,
                  isActive,
                } = activity;

                const hasTimes = startTime && endTime;

                return (
                  <ActivitiesMapMarker
                    lat={activityLocationLat}
                    lng={activityLocationLong}
                    name={name}
                    duration={
                      hasTimes
                        ? `${getDurationHoursMin(
                            DateTime.fromISO(startTime),
                            DateTime.fromISO(endTime)
                          )} (${unpackToTime(startTime)
                            .toFormat('h:mm a')
                            .toLowerCase()})`
                        : null
                    }
                    key={activityId}
                    id={activityId}
                    isHovered={hoveredActivityId === activity.activityId}
                    setHoveredActivityId={setHoveredActivityId}
                    dayTimeIndicator={getDayTimeIndicator(startTime)}
                    onClick={() => setSelectedActivityId(activityId)}
                    onMouseOver={() => setHoveredActivityId(activityId)}
                    onFocus={() => setHoveredActivityId(activityId)}
                    disabled={isSuspended || !isActive}
                  />
                );
              })}
        </GoogleMapReact>
      ) : null}
    </div>
  );
});

interface ActivitiesMapProps {
  showSkinnyMarkers: boolean;
  hoveredActivityId?: string;
  selectedActivityId?: string;
  targetedActivityId?: string;
  activities: MapActivity[];
  setSelectedActivityId: (id: string) => void;
  setHoveredActivityId: (id: string) => void;
}

const ActivitiesMap = memo((props: ActivitiesMapProps) => {
  const {
    showSkinnyMarkers,
    activities,
    hoveredActivityId,
    selectedActivityId,
    targetedActivityId,
    setHoveredActivityId,
    setSelectedActivityId,
  } = props;

  const { primaryRegion } = useRegionCodes();

  const defaultBounds = useMemo(() => {
    if (!primaryRegion) return undefined;
    if (primaryRegion === 'nz') return getDefaultNzCenter();
    if (primaryRegion === 'us') return getDefaultNorthAmericaCenter();
    if (primaryRegion === 'gb') return getDefaultGBCenter();
    return getDefaultAuCenter();
  }, [primaryRegion]);

  const defaultZoom = useMemo(() => {
    if (primaryRegion === 'nz') return DEFAULT_NZ_ZOOM;
    if (primaryRegion === 'us') return DEFAULT_NORTH_AMERICA_ZOOM;
    if (primaryRegion === 'gb') return DEFAULT_GB_ZOOM;
    return DEFAULT_AU_ZOOM;
  }, [primaryRegion]);

  if (!defaultBounds) return null;

  return (
    <ActivitiesMapInner
      showSkinnyMarkers={showSkinnyMarkers}
      activities={activities}
      initialCentre={defaultBounds}
      initialZoom={defaultZoom}
      hoveredActivityId={hoveredActivityId}
      selectedActivityId={selectedActivityId}
      targetedActivityId={targetedActivityId}
      setHoveredActivityId={setHoveredActivityId}
      setSelectedActivityId={setSelectedActivityId}
    />
  );
});

export { ActivitiesMap };
