import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { ErrorOutline } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { uniqBy } from 'lodash';
import React, { memo, useMemo } from 'react';
import { FullPageMessage } from '../../../common/FullPageMessage';
import { useUser } from '../../../global/auth/useUser';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { GET_ACTIVITY_MAP_ACTIVITIES } from './activity-timeline/activity-timeline-model.gql';
import {
  GetActivityMapActivities,
  GetActivityMapActivitiesVariables,
} from './activity-timeline/__generated__/GetActivityMapActivities';
import { ActivityMap } from './ActivityMap';

export const ManagerActivityMap = memo((props: { managementPath: string }) => {
  const { managementPath } = props;
  const { maybeVolunteerIdentity } = useUser();
  const { data, loading, error } = useCampfireQuery<GetActivityMapActivities, GetActivityMapActivitiesVariables>(
    GET_ACTIVITY_MAP_ACTIVITIES,
    {
      options: {
        variables: {
          volunteerId: maybeVolunteerIdentity?.volunteerId ?? '',
        },
      },
    }
  );

  const activities = useMemo(() => {
    const managedActivities = data?.vm.programs.flatMap((program) => program.activities) ?? [];
    const ledActivities = data?.vm.volunteer?.activityEnrolments.map((enrolment) => enrolment.activity) ?? [];
    return uniqBy([...managedActivities, ...ledActivities], 'activityId');
  }, [data]);

  return (
    <Box display={'flex'} flex={'1'}>
      <CircularProgressOverlay isLoading={loading} />
      {error && !loading ? (
        <FullPageMessage title={'Network Error'} subtitle={'Unable to load timeline.'} Icon={ErrorOutline} />
      ) : (
        <ActivityMap activityIds={activities.map((x) => x.activityId)} managementPath={managementPath} />
      )}
    </Box>
  );
});
