import React from 'react';
import { Route } from 'react-router';
import { CampfireSwitch } from '../../../../content-blocks/common/CampfireSwitch';
import { useAdminConsoleRoutesMap } from '../admin-console-side-nav/admin-console-routes-map';

export const AdminConsoleContentBlock = ({ route }: { route: string }) => {
  const { routeGroups } = useAdminConsoleRoutesMap(route);

  return (
    <CampfireSwitch>
      {routeGroups.map((group) => {
        return group.routeOptions
          .filter((option) => option.enabled !== false)
          .map((option) => {
            const { route: matchRoute, component: Component } = option;
            return (
              <Route
                key={matchRoute}
                path={matchRoute}
                render={(props) => (Component ? <Component {...props} /> : null)}
              />
            );
          });
      })}
    </CampfireSwitch>
  );
};

export default AdminConsoleContentBlock;
