import { gql } from '@apollo/client';

export const GET_WAITLIST_VOLUNTEERS = gql`
  query GetWaitlistVolunteers {
    vm {
      activityWaitlistings {
        activityWaitlistingId
        dateAdded
        dateRemoved
        activity {
          activityId
          name
          isActive
          priority
          program {
            name
            programId
            dateSuspended
            dateDeleted
          }
          maxTeam
          totalEnrolments
        }
        volunteer {
          volunteerId
          profile {
            profileId
            userId
            firstName
            lastName
            avatarUrl
            preferredName
            email
            contactNumber
          }
          dateDeactivated
          flagging {
            dateFlagged
          }
        }
      }
    }
  }
`;
