import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

const SectionInstruction = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <Typography variant='body2' color='textSecondary'>
      {children}
    </Typography>
  );
};

export { SectionInstruction };
