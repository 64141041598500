import { gql } from '@apollo/client';

export const GET_MY_ELEMENTS_SIDEBAR_INCOMPLETE_REPORTS_DATA = gql`
  query GetMyElementsSideBarIncompleteReportsData($volunteerId: String!, $from: Date!, $until: Date!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        activityEnrolments {
          activity {
            ...SideBarIncompleteReportsActivity
          }
        }
        rosterings {
          publishedRosteringId
          publishedRoster {
            activityDate
            activity {
              ...SideBarIncompleteReportsActivity
            }
          }
        }
        isCheckedIn
        checkedIn {
          CICOId
          checkIn
          checkOut
          sessionReport {
            session {
              sessionId
            }
            activityReport {
              activityDate
                activity {
                  __typename
                  activityId
                  startTime
                  endTime
                  name
                  hasOpenRoster
                  activityRemoteLocation {
                    activityRemoteLocationId
                  }
                  activityLocation {
                    placesAddress {
                        placesId
                        description
                        formatted
                    }
                  }
                  program {
                    programId
                    name
                  }
                  sessions {
                    sessionId
                    name
                    startTime
                    endTime
                    description
                    activityLocation {
                      placesAddress {
                        placesId
                        description
                        formatted
                      }
                    }
                  }
                }
            }
          }
        }
      }
    }
  }
  fragment SideBarIncompleteReportsActivity on VOLUNTEER_ActivityType {
    activityId
    program {
      dateDeleted
      dateSuspended
    }
    occurrencesBetween(from: $from, until: $until)
    sessions {
      sessionId
    }
    activityReports {
      activityDate
      activity {
        activityId
      }
      sessionReports {
        sessionReportId
      }
      cancelledSessions {
        sessionId
      }
    }
    cancelledActivities {
      activityDate
    }
  }
`;
