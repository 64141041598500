import { Box, Dialog, DialogTitle, Grid, Typography, IconButton, DialogContent } from '@material-ui/core';
import { DateTime } from 'luxon';
import {
  PersonAdd,
  Close as CloseIcon,
  Loop as LoopIcon,
  CalendarToday as CalendarIcon,
  AccessTime as AccessTimeIcon,
} from '@material-ui/icons';
import React, { useMemo } from 'react';
import { unpackToTime, unpackToDateTime } from '@campfire/hot-date';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { VolunteerCommonProfileActivityEnrolment } from '../../__generated__/VolunteerCommonProfileActivityEnrolment';
import { useVolunteerCommonProfileContext } from '../../VolunteerCommonProfileContext';
import AddedDateIcon from '../../../../../assets/icons/added-date-icon.svg';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GET_VOLUNTEER_COMMON_ACTIVITY_ENROLMENT } from './activity-enrolment-section-model.gql';
import { EnrolmentForecast } from './EnrolmentForecast';
import { ActivityEnrolmentTimeline } from './ActivityEnrolmentTimeline';
import {
  GetVolunteerCommonActivityEnrolmentVariables,
  GetVolunteerCommonActivityEnrolment,
} from './__generated__/GetVolunteerCommonActivityEnrolment';

export const ActivityEnrolmentDialog = ({
  open,
  handleClose,
  activityEnrolment,
  volunteerId,
  profileId,
}: {
  open: boolean;
  handleClose: () => void;
  activityEnrolment: VolunteerCommonProfileActivityEnrolment;
  volunteerId: string;
  profileId: string;
}) => {
  const { theme } = useCampfireTheme();
  const {
    volunteerNameOptions: { preferredName },
  } = useVolunteerCommonProfileContext();

  const { data: allEnrolmentsData, loading } = useCampfireQuery<
    GetVolunteerCommonActivityEnrolment,
    GetVolunteerCommonActivityEnrolmentVariables
  >(GET_VOLUNTEER_COMMON_ACTIVITY_ENROLMENT, {
    options: {
      variables: {
        volunteerId,
        activityId: activityEnrolment.activity.activityId,
      },
    },
  });

  const allEnrolmentsSorted =
    allEnrolmentsData?.vm.volunteer?.allActivityEnrolmentsForActivity.sort((a, b) =>
      unpackToDateTime(a.dateEnrolled) < unpackToDateTime(b.dateEnrolled) ? -1 : 1
    ) || [];

  const recurrenceHumanReadable =
    activityEnrolment.activity.__typename === 'VOLUNTEER_RecurringActivityType'
      ? activityEnrolment.activity.schedule.recurrences[0].humanReadable
      : 'Flexible';

  const activityStartTime = unpackToTime(activityEnrolment.activity.startTime).toFormat('h:mm a');
  const activityEndTime = unpackToTime(activityEnrolment.activity.endTime).toFormat('h:mm a');

  const time = useMemo(() => {
    const { startDate, endDate } = activityEnrolment.activity;
    if (!startDate && !endDate) {
      return '';
    }

    const diffNow = DateTime.fromISO(startDate)
      .diffNow()
      .as('days');
    if (diffNow > 0) {
      return `Starts ${DateTime.fromISO(startDate).toFormat('dd')} ${DateTime.fromISO(startDate).toFormat('LLLL, y')}`;
    }
    if (diffNow <= 0 && endDate) {
      return `Ends ${DateTime.fromISO(endDate).toFormat('dd')} ${DateTime.fromISO(endDate).toFormat('LLLL, y')}`;
    }
    return '';
  }, [activityEnrolment]);

  const currentTime = useMemo(() => {
    const currentEnrolment = allEnrolmentsSorted.find((item) => !item.dateRemoved);
    const dateAdded = currentEnrolment?.dateEnrolled;
    if (dateAdded) {
      const diffNow = DateTime.fromISO(dateAdded)
        .diffNow(['years', 'months', 'days'])
        .toObject();

      const diffNowYears = +Math.abs(diffNow.years || 0).toFixed();
      const diffNowMonths = +Math.abs(diffNow.months || 0).toFixed();
      const diffNowDays = +Math.abs(diffNow.days || 0).toFixed();

      return `Currently enrolled for ${diffNowYears ? `${diffNowYears} years` : ''}${
        diffNowYears && (diffNowMonths || diffNowDays) ? ', ' : ''
      }${diffNowMonths ? `${diffNowMonths} months` : ''}${diffNowMonths && diffNowDays ? ', ' : ''}${
        diffNowDays ? `${diffNowDays} days` : ''
      }`;
    }
    return '';
  }, [allEnrolmentsSorted]);

  const totalTime = useMemo(() => {
    const total = allEnrolmentsSorted.reduce((result, next) => {
      if (next.dateRemoved && next.dateEnrolled) {
        const diff = DateTime.fromISO(next.dateEnrolled)
          .diff(DateTime.fromISO(next.dateRemoved), ['years', 'months', 'days'])
          .toObject();
        return result.plus({ years: diff.years, months: diff.months, days: diff.days });
      }

      if (!next.dateRemoved && next.dateEnrolled) {
        const diffNow = DateTime.fromISO(next.dateEnrolled)
          .diffNow(['years', 'months', 'days'])
          .toObject();
        return result.plus({ years: diffNow.years, months: diffNow.months, days: diffNow.days });
      }

      return result;
    }, DateTime.local().diffNow(['years', 'months', 'days']));

    const totalYears = +Math.abs(total.years || 0).toFixed();
    const totalMonths = +Math.abs(total.months || 0).toFixed();
    const totalDays = +Math.abs(total.days || 0).toFixed();

    return `Total of ${totalYears ? `${totalYears} years` : ''}${totalYears && (totalMonths || totalDays) ? ', ' : ''}${
      totalMonths ? `${totalMonths} months` : ''
    }${totalMonths && totalDays ? ', ' : ''}${totalDays ? `${totalDays} days` : ''}`;
  }, [allEnrolmentsSorted]);

  const addedDate = useMemo(() => {
    if (allEnrolmentsSorted.length === 0) return '';
    return unpackToTime(allEnrolmentsSorted[0].dateEnrolled).toFormat('dd LLLL, yyyy');
  }, [allEnrolmentsSorted]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      PaperProps={{ style: { backgroundColor: '#F9F9F9' } }}
    >
      <DialogTitle>
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs>
            <Typography
              variant='h6'
              style={{ color: theme.color.grey.neutral500, fontWeight: 'bold', fontSize: '20px' }}
            >
              {`${activityEnrolment.activity.name}: ${preferredName}`}
            </Typography>
            <Typography
              variant='subtitle1'
              color='textSecondary'
              style={{ color: theme.color.grey.neutral200, fontSize: '14px' }}
            >
              {activityEnrolment.activity.program.name}
            </Typography>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <IconButton key='close' aria-label='Close' color='inherit' onClick={handleClose}>
                <CloseIcon color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          style={{
            borderTop: `0.5px solid ${theme.color.grey.neutral200}`,
            borderBottom: `0.5px solid ${theme.color.grey.neutral200}`,
            padding: '16px',
          }}
        >
          <Grid container>
            <Grid
              item
              xs={6}
              style={{
                borderRight: `0.5px solid ${theme.color.grey.neutral200}`,
              }}
            >
              <Box>
                <Box display='flex' alignItems='center' mb={1}>
                  {recurrenceHumanReadable && (
                    <LoopIcon
                      style={{
                        width: '17px',
                        height: '17px',
                        paddingRight: '2px',
                        marginLeft: '-2px',
                        color: theme.color.grey.neutral200,
                      }}
                    />
                  )}
                  <Typography
                    style={{
                      fontSize: '12px',
                      color: theme.color.grey.neutral200,
                    }}
                  >
                    {recurrenceHumanReadable}
                    {activityEnrolment.activity.startTime && activityEnrolment.activity.endTime
                      ? `, ${activityStartTime} - ${activityEndTime}`
                      : ''}
                  </Typography>
                </Box>
                {time && (
                  <Box display='flex' alignItems='center'>
                    <CalendarIcon style={{ color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }} />
                    <Typography style={{ fontSize: 11, color: theme.color.grey.neutral200, marginTop: '1px' }}>
                      {time}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box pl={2}>
                <Box display='flex' alignItems='center' mb={1}>
                  <img src={AddedDateIcon} alt='' style={{ width: '15px', marginRight: '6px' }} />
                  <Typography
                    style={{
                      fontSize: '12px',
                      color: theme.color.grey.neutral200,
                    }}
                  >
                    Added on {addedDate}
                  </Typography>
                </Box>
                {activityEnrolment.enrolledBy || !activityEnrolment.activity.isRestrictedActivity ? (
                  <Box display='flex' alignItems='center' mb={1}>
                    <PersonAdd style={{ color: theme.color.grey.neutral200, width: '15px', marginRight: '6px' }} />
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: theme.color.grey.neutral200,
                      }}
                    >
                      {activityEnrolment.enrolledBy && activityEnrolment.enrolledBy.profile.profileId === profileId
                        ? 'Added by themselves'
                        : activityEnrolment.enrolledBy && activityEnrolment.enrolledBy.profile.profileId !== profileId
                        ? `Added by ${activityEnrolment.enrolledBy.profile.preferredName} ${activityEnrolment.enrolledBy?.profile.lastName}`
                        : null}
                    </Typography>
                  </Box>
                ) : null}
                <Box display='flex' alignItems='center' mb={1}>
                  <AccessTimeIcon style={{ color: theme.color.grey.neutral200, width: '15px', marginRight: '6px' }} />
                  <Box>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: theme.color.grey.neutral200,
                      }}
                    >
                      {currentTime}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: theme.color.grey.neutral200,
                      }}
                    >
                      {totalTime}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {activityEnrolment.activity.nextX.length > 0 && (
          <Box mt={2}>
            <Box mb={2}>
              <Typography
                style={{ color: theme.color.grey.neutral500, fontWeight: 700, fontSize: '16px', marginBottom: '8px' }}
              >
                {`Upcoming`}
              </Typography>
              <Typography color='textSecondary' style={{ color: theme.color.grey.neutral200, fontSize: '12px' }}>
                {`See an overview of ${preferredName}’s roster activity below. Click a date to see more information`}
              </Typography>
            </Box>
            <Box>
              <EnrolmentForecast volunteerId={volunteerId} activityEnrolment={activityEnrolment} isLargeDialog />
            </Box>
          </Box>
        )}

        <Box mt={2}>
          <Typography
            style={{ color: theme.color.grey.neutral500, fontWeight: 700, fontSize: '16px', marginBottom: '8px' }}
          >
            {`History`}
          </Typography>
          <Box my={1}>
            <ActivityEnrolmentTimeline
              allEnrolmentsSorted={allEnrolmentsSorted}
              loading={loading}
              profileId={profileId}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
