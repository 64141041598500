import React, { useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
  Switch,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import { AlertCard } from '../../../../common/cards/alert-card/AlertCard';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';
import { VOLUNTEER_RosterViewEnumType as RosterViewEnum } from '../../../../__generated__/globalTypes';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useUser } from '../../../../global/auth/useUser';
import { useSession } from '../../../../global/auth/useSession';
import { useSnackbar } from '../../../../global/config/useSnackbar';

import { GetProfileSetting, GetProfileSettingVariables } from './__generated__/GetProfileSetting';
import { GET_PROFILE_SETTING } from './roster-settings.gql';

interface UserPreferenceSpec {
  profileId: string;
  rosterView: RosterViewEnum;
}
export interface RosterSettingDialogProps {
  open: boolean;
  isLoading?: boolean;
  onClose: () => void;
}

export const RosterSettingDialog = (props: RosterSettingDialogProps) => {
  const { open, onClose } = props;
  const { updateUserPreference } = useSession();
  const {
    user: { profileId },
  } = useUser();
  const { setSnackbar } = useSnackbar();
  const updateUserPreferenceFetch = useEndpointFetch<UserPreferenceSpec>('/vm/volunteer/preferences/update');
  const { data, loading, refetch } = useCampfireQuery<GetProfileSetting, GetProfileSettingVariables>(
    GET_PROFILE_SETTING,
    {
      options: {
        variables: {
          profileId,
        },
      },
    }
  );

  const profile = data?.vm.profile;

  const somethingWentWrong = (message?: string) => {
    setSnackbar({
      message: message ?? 'Unable to setting roster',
      variant: 'error',
      open: true,
    });
  };

  useEffect(() => {
    if (profile) {
      updateUserPreference(profile?.userPreference);
    }
  }, [profile]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid item container xs={12} direction='column'>
          <Typography display='inline' variant='h6'>
            Roster Settings
          </Typography>
        </Grid>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{ isDefaultBulkView: profile?.userPreference?.rosterView === 'bulk' }}
        onSubmit={(values) => {
          const { isDefaultBulkView } = values;
          const rosterView = isDefaultBulkView ? RosterViewEnum.bulk : RosterViewEnum.individual;
          updateUserPreferenceFetch
            .run({
              profileId,
              rosterView,
            })
            .then((response) => {
              if (response && response.ok) {
                if (refetch) refetch();
                return;
              }
              somethingWentWrong();
            })
            .catch(() => {
              somethingWentWrong();
            })
            .finally(() => {
              onClose();
            });
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <DialogContent style={{ padding: 0 }} id='dialog-content'>
              {loading ? (
                <Grid container direction='column' style={{ width: '100%', padding: '16px' }}>
                  <Grid item>
                    <Skeleton variant='rect' height={50} style={{ width: '100%', marginBottom: '16px' }} />
                  </Grid>
                  <Grid item>
                    <Skeleton variant='rect' height={100} style={{ width: '100%' }} />
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction='column' style={{ width: '100%', padding: '0 16px' }}>
                  <Grid item>
                    <Box padding='0 8px'>
                      <AlertCard variant='info'>
                        <Typography variant='subtitle2' style={{ fontSize: '14px', color: '#1E40AF' }}>
                          {`You can alter the default load configuration for the rosters page here`}
                        </Typography>
                      </AlertCard>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display='flex' mt='8px'>
                      <Switch
                        name='isDefaultBulkView'
                        checked={values.isDefaultBulkView}
                        onChange={() => setFieldValue('isDefaultBulkView', !values.isDefaultBulkView)}
                        inputProps={{ 'aria-label': 'bulk view checkbox' }}
                        color='primary'
                      />
                      <Box>
                        <Typography style={{ fontSize: '16px', fontWeight: 600, lineHeight: '36px' }}>
                          Default to Bulk Roster View
                        </Typography>
                        <Typography style={{ fontSize: '14px' }}>
                          Toggling this on will mean that the &apos;Bulk Roster&apos; view will be loaded by default.
                          This feature is only available on desktop, and the Single View will load on mobile devices,
                          regardless of your selection.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Box padding={2}>
                <Button
                  variant='text'
                  onClick={onClose}
                  style={{ marginRight: '8px', textTransform: 'none', padding: '6px 26px' }}
                >
                  {'Cancel'}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  style={{ textTransform: 'none', padding: '6px 26px' }}
                >
                  {'Save'}
                </Button>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
