import React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: '1 1 0',
      minHeight: 0,
      overflow: 'hidden',
      marginBottom: '4rem',
      backgroundColor: 'white',
      borderRadius: '8px',
      marginTop: 20,
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

interface ContentProps {
  children: React.ReactNode;
}

export function Content({ children }: ContentProps) {
  const classes = useStyles();

  return <Box className={classes.root}>{children}</Box>;
}
