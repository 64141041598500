import React from 'react';
import { StatusChip, StatusType } from './StatusChip';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

type Status = 'SUSPENDED' | 'CLOSED';

const getActivityStatus = (status?: Status): StatusType | undefined => {
  const { theme } = useCampfireTheme();
  const statuses = {
    SUSPENDED: { status: 'Suspended', bgcolor: theme.status.amber.light },
    CLOSED: { status: 'Closed', bgcolor: theme.status.red.light },
  };
  return status ? statuses[status] : undefined;
};

interface Props {
  status?: Status;
}

const ActivityStatusChip = (props: Props) => {
  const activityStatus = getActivityStatus(props.status);
  return activityStatus ? <StatusChip status={activityStatus.status} bgcolor={activityStatus.bgcolor} /> : null;
};

export { ActivityStatusChip };
