import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../../../../common/recurrence/recurrence-model.gql';

export const GET_ACTIVITIES_CONSOLE_ACTIVITIES = gql`
  query GetActivitiesConsoleActivities($volunteerId: String!, $programIds: [String!], $isActive: Boolean) {
    vm {
      programs(hasRule: { rule: CreateRoster }, programIds: $programIds) {
        activities(includeInactiveActivities: $isActive) {
          activityId
        }
      }
      volunteer(volunteerId: $volunteerId) {
        activityEnrolments(position: al) {
          activity {
            activityId
          }
        }
      }
    }
  }
`;

export const ActivitiesConsoleExploreProgram = gql`
fragment ActivitiesConsoleExploreProgram on VOLUNTEER_ProgramType {
  programId
  name
  dateSuspended
  dateDeleted
}`;

export const ActivitiesConsoleExploreActivityCancelledActivity = gql`
fragment ActivitiesConsoleExploreActivityCancelledActivity on VOLUNTEER_CancelledActivityType {
  cancelledActivityId
  activityDate
}`;

export const ActivitiesConsoleExploreActivityClosedActivity = gql`
fragment ActivitiesConsoleExploreActivityClosedActivity on VOLUNTEER_ClosedActivityType {
  closedActivityId
  endDate
}`;
export const ActivitiesConsoleExploreActivityRecurrenceType = gql`
fragment ActivitiesConsoleExploreActivityRecurrenceType on RecurrenceType {
  ... on SingleRecurrenceType {
    startDate
    endDate
    humanReadable
  }
  ... on DailyRecurrenceType {
    startDate
    endDate
  }
  ... on WeeklyRecurrenceType {
    startDate
    endDate
    days
  }
  ... on MonthlyNthDayRecurrenceType {
    startDate
    endDate
    day
  }
  ... on MonthlyByMonthDayRecurrenceType {
    startDate
    endDate
  }
  ... on YearlyRecurrenceType {
    startDate
    endDate
  }
}`;

export const ActivitiesConsoleExploreActivitySession = gql`
fragment ActivitiesConsoleExploreActivitySession on VOLUNTEER_SessionType {
  sessionId
  name
  startTime
  endTime
  minVolunteers
  maxVolunteers
  description
  reportType {
    reportTypeId
    name
  }
  activityLocation {
    activityLocationId
    comments
    timeZone
    placesAddress {
      description
      formatted
      latLong {
        lat
        long
      }
      placesId
    }
  }
  autoReport
}`;

export const ActivitiesConsoleExploreActivityLocation = gql`
fragment ActivitiesConsoleExploreActivityLocation on VOLUNTEER_ActivityLocationType {
  activityLocationId
  comments
  timeZone
  placesAddress {
    placesId
    description
    formatted
    latLong {
      lat
      long
    }
  }
}`;

const ActivitiesConsoleCardExploreActivity = gql`
fragment ActivitiesConsoleCardExploreActivity on VOLUNTEER_ActivityType {
  activityId
  name
  description
  startTime
  startDate
  endDate
  endTime
  nextX(x: 1)
  nextOccurrence
  ... on VOLUNTEER_NonRecurringActivityType {
    __typename
  }
  ... on VOLUNTEER_RecurringActivityType {
    __typename
      recurrences {
        humanReadable
        ...ActivitiesConsoleExploreActivityRecurrenceType
    }
  }
  isEnrollable
  isActive
  isSuspended
  isRestrictedActivity
  isHidden
  maxTeam
  hasOpenRoster
  allowCICO
  priority
  activityLocation {
    ...ActivitiesConsoleExploreActivityLocation
  }
  cancelledActivities {
    ...ActivitiesConsoleExploreActivityCancelledActivity
  }
  closedActivity {
    ...ActivitiesConsoleExploreActivityClosedActivity
  }
  program {
    ...ActivitiesConsoleExploreProgram
  }
  minVolunteers
  maxVolunteers
  activityRemoteLocation {
    details
  }
  sessions {
    ...ActivitiesConsoleExploreActivitySession
  }
  activityEnrolments {
    activityEnrolmentId
  }
  ... on VOLUNTEER_RecurringActivityType {
    schedule {
      recurrences {
        ...Recurrence
      }
    }
  }
  activityTags {
    activityTagId
    name
    description
  }
  activityTagged {
    activityTaggedId
    dateRemoved
    activityTag {
      activityTagId
    }
  }
}
${ActivitiesConsoleExploreProgram}
${ActivitiesConsoleExploreActivitySession}
${RECURRENCE_FRAGMENT}
${ActivitiesConsoleExploreActivityRecurrenceType}
${ActivitiesConsoleExploreActivityLocation}
${ActivitiesConsoleExploreActivityCancelledActivity}
${ActivitiesConsoleExploreActivityClosedActivity}`;

export const GET_ACTIVITIES_CARD_DATA = gql`
query GetActivitiesConsoleCardData($activityIds: [String!]!) {
    orgName
    vm {
      activities(activityIds: $activityIds) {
        ...ActivitiesConsoleCardExploreActivity
      }
    }
  }
  ${ActivitiesConsoleCardExploreActivity}
  ${RECURRENCE_FRAGMENT}
`;


export const GET_ACTIVITY_CARD_DATA = gql`
query GetActivityConsoleCardData($activityId: String) {
    orgName
    vm {
      activity(activityId: $activityId) {
        ...ActivitiesConsoleCardExploreActivity
      }
    }
  }
  ${ActivitiesConsoleCardExploreActivity}
  ${RECURRENCE_FRAGMENT}
`;
