import React, { ReactNode, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { useCampfireTheme } from '../theme/useCampfireTheme';

const ExpandCollapseButton = ({ handleClick, rightAlignment }: { handleClick: Function; rightAlignment: string }) => {
  return (
    <Box
      onClick={() => handleClick()}
      position={'absolute'}
      boxShadow={'0px 0px 4px 0px #00000040'}
      borderRadius={'50%'}
      width={'30px'}
      height={'30px'}
      right={rightAlignment}
      top={'237px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      style={{ background: '#fff', cursor: 'pointer' }}
    >
      <ChevronLeft style={{ width: '15px', height: '15px' }} />
      <ChevronRight style={{ width: '15px', height: '15px' }} />
    </Box>
  );
};

interface Props {
  children: ReactNode;
  expandHide?: boolean;
  setSiblingExpanded?: (x: boolean) => void;
  style?: React.CSSProperties;
}

export const CollapsibleSideBar = (props: Props) => {
  const { children, expandHide = false, setSiblingExpanded } = props;
  const resize = window.dispatchEvent(new Event('resize'));
  const [expandOverview, setExpandOverview] = useState(true);
  const { theme, isMd, isSm, isMobile } = useCampfireTheme();

  React.useEffect(() => {
    if (setSiblingExpanded) {
      setSiblingExpanded(!expandOverview);
    }
  }, [expandOverview]);

  const dynamicWidth = useMemo(
    () =>
      !isMd
        ? expandOverview
          ? '520px'
          : '120px'
        : expandHide
        ? expandOverview
          ? '100%'
          : '0px'
        : expandOverview
        ? '350px'
        : '85px',
    [expandOverview]
  );
  const dynamicWidthInner = useMemo(
    () => (!isMd ? (expandOverview ? '400px' : '0px') : expandOverview ? '230px' : '0px'),
    [expandOverview]
  );
  const rightAlignment = useMemo(
    () => (!isMd ? (expandOverview ? '505px' : '105px') : expandOverview ? '335px' : '75px'),
    [expandOverview]
  );

  return (
    <Box
      width={dynamicWidth}
      bgcolor={theme.color.grey.background}
      position={'sticky'}
      top={'0px'}
      height={'inherit'}
      boxSizing={'border-box'}
      display='flex'
      flexDirection={'column'}
      justifyContent={'flex-start'}
      style={props.style}
    >
      {!expandHide ? (
        <ExpandCollapseButton
          handleClick={() => [setExpandOverview(!expandOverview), resize]}
          rightAlignment={rightAlignment}
        />
      ) : (
        ''
      )}
      <Box
        style={{ overflowX: 'hidden', flexFlow: expandHide ? 'wrap' : 'inherit', ...props.style }}
        flexGrow={1}
        height={'100%'}
        bgcolor={theme.color.grey.background}
        padding={isMd ? '20px 20px' : '20px 60px'}
        boxSizing={'border-box'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={isMobile ? 'flex-start' : isSm ? 'space-between' : 'flex-start'}
        minWidth={dynamicWidth}
      >
        {children}
        <Box minWidth={dynamicWidthInner}></Box>
      </Box>
    </Box>
  );
};
