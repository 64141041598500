import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.color.white.neutral50,
      borderRadius: '6px',
      paddingBottom: 18,
    },
    label: {
      textTransform: 'uppercase',
      color: theme.color.grey.neutral300,
      fontWeight: 700,
      fontSize: '0.75rem',
      marginBottom: '8px',
    },
    actionContainer: {
      display: 'flex',
      marginTop: 22,
      marginLeft: 16,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        justifyContent: 'center',
      },
    },
    button: {
      fontSize: 13,
      fontWeight: 500,
      borderRadius: 6,
    },
    showButton: {
      backgroundColor: theme.color.secondary.main900,
      color: 'white',
      '&:hover': {
        opacity: 0.8,
      },
    },
    clearButton: {
      backgroundColor: theme.color.secondary.main50,
      color: theme.color.secondary.main700,
      marginLeft: 8,
      '&:hover': {
        opacity: 0.8,
      },
    },
  })
);
