import { gql } from '@apollo/client';

const DASHBOARD_CALENDAR_RECURRING_ACTIVITIES_FRAGMENT = gql`
  fragment DashboardCalendarRecurringActivities on VOLUNTEER_ActivityType {
    __typename
    activityId
    name
    isActive
    isSuspended
    startTime
    endTime
    startDate
    endDate
    program {
        programId
        dateDeleted
        dateSuspended
    }
    cancelledActivities {
        activityDate
    }
    publishedRosters {
        activityDate
    }
  }
`;

const DASHBOARD_CALENDAR_NONRECURRING_ACTIVITIES_FRAGMENT = gql`
  fragment DashboardCalendarNonRecurringActivities on VOLUNTEER_ActivityType {
    __typename
    activityId
    name
    isActive
    isSuspended
    startTime
    endTime
    startDate
    program {
        programId
        dateDeleted
        dateSuspended
    }
    cancelledActivities {
        activityDate
    }
    publishedRosters {
        activityDate
    }
  }
`;

export const MANAGEMENT_DASHBOARD_CALENDAR_DATA = gql`
  query ManagementDashboardCalendarData($dateFrom: Date, $dateUntil: Date) {
    vm {
      activities {
        minVolunteers
        activityEnrolments {
          dateRemoved
        }
        occurrencesBetween(from: $dateFrom, until: $dateUntil)
          ... on VOLUNTEER_RecurringActivityType {
            ...DashboardCalendarRecurringActivities
          }
          ... on VOLUNTEER_NonRecurringActivityType {
            ...DashboardCalendarNonRecurringActivities
          }
      }
    }
  }

  ${DASHBOARD_CALENDAR_RECURRING_ACTIVITIES_FRAGMENT}
  ${DASHBOARD_CALENDAR_NONRECURRING_ACTIVITIES_FRAGMENT}
`;