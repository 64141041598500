import { gql } from '@apollo/client';

export const GET_SESSION_REPORT_VISIBILITY = gql `
  query GetSessionReportVisibility {
    vm {
      configItem(key: "session-report-visibility") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;
