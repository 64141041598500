import { gql } from '@apollo/client';

export const GET_ACTIVITY_REPORT = gql`
  query ActivityReportGetActivityReport($activityId: String, $activityDate: Date) {
    vm {
      activityReport(activityId: $activityId, activityDate: $activityDate) {
        activityReportId
        sessionReports {
          sessionReportId
          submittedBy {
            volunteerId
            profile {
              profileId
              preferredName
              lastName
              avatarUrl
              email
            }
          }
          submissionDate
          isHidden
          allowCICO
          CICOs {
            volunteer {
              volunteerId
              profile {
                profileId
                preferredName
                lastName
                avatarUrl
                email
              }
            }
            checkIn
            checkOut
          }
          session {
            sessionId
            name
            startTime
            endTime
            dateRemoved
            autoReport
            activity {
              activityId
              allowCICO
              program {
                programId
              }
            }
            reportType {
              reportTypeId
              name
              items {
                ... on VOLUNTEER_ReportTypeFieldType {
                  reportTypeItemId
                  order
                  optional

                  field {
                    fieldId
                    name
                    description
                    isHero
                    ... on VOLUNTEER_DropdownFieldType {
                      isAllowMultiple
                      dropdownOptions {
                        dropdownFieldOptionId
                        name
                        order
                      }
                    }
                    ... on VOLUNTEER_TextFieldType {
                      isLongText
                    }
                  }
                }
                ... on VOLUNTEER_ReportTypeHeadingType {
                  reportTypeItemId
                  order
                  heading
                }
              }
            }
          }
          startTime
          endTime
          comments
          attendances {
            attendanceId
            activityRoles {
              activityRoleId
            }
            ... on VOLUNTEER_VolunteerAttendanceType {
              volunteer {
                volunteerId
                profile {
                  userId
                  profileId
                  preferredName
                  lastName
                  avatarUrl
                }
              }
            }
            ... on VOLUNTEER_OtherAttendanceType {
              name
            }
          }
          fieldValues {
            fieldValueId
            field {
              fieldId
              name
              description
            }

            ... on VOLUNTEER_RatingFieldValueType {
              ratingValue
            }
            ... on VOLUNTEER_BooleanFieldValueType {
              boolValue
            }

            ... on VOLUNTEER_TextFieldValueType {
              textValue
            }

            ... on VOLUNTEER_TimeFieldValueType {
              timeValue
            }

            ... on VOLUNTEER_NumericFieldValueType {
              numericValue
            }

            ... on VOLUNTEER_CheckboxFieldValueType {
              checked
            }

            ... on VOLUNTEER_DropdownFieldValueType {
              dropdownFieldOptions {
                dropdownFieldOptionId
                name
              }
            }
            ... on VOLUNTEER_AttachmentFieldValueType {
              attachments {
                attachmentId
                fileType
                url
                name
              }
            }
          }
        }
        cancelledSessions {
          sessionId
        }
      }
      activity(activityId: $activityId) {
        name
        program {
          name
        }
        allowCICO
        allSessions {
          sessionId
          name
          description
          startTime
          endTime
          dateRemoved
          autoReport
          activity {
            activityId
            name
            allowCICO
            program {
              programId
              name
            }
          }
          reportType {
            reportTypeId
            name
            items {
              ... on VOLUNTEER_ReportTypeFieldType {
                reportTypeItemId
                order
                optional

                field {
                  fieldId
                  name
                  description
                  isHero
                  ... on VOLUNTEER_DropdownFieldType {
                    isAllowMultiple
                    dropdownOptions {
                      dropdownFieldOptionId
                      name
                      order
                    }
                  }
                  ... on VOLUNTEER_TextFieldType {
                    isLongText
                  }
                }
              }
              ... on VOLUNTEER_ReportTypeHeadingType {
                reportTypeItemId
                order
                heading
              }
            }
          }
        }
        publishedRoster(activityDate: $activityDate) {
          rosterId
          datePublished
          publishedByManager {
            profile {
              profileId
              preferredName
              lastName
              avatarUrl
            }
          }
          publishedByVolunteer {
            volunteerId
            profile {
              profileId
              preferredName
              lastName
              avatarUrl
            }
          }
          rosterings {
            publishedRosteringId
            sessionRosterings {
              publishedSessionRosteringId
              session {
                sessionId
              }
            }
            volunteer {
              volunteerId
              profile {
                profileId
                preferredName
                lastName
                avatarUrl
              }
            }
          }
          activityDate
        }
      }
    }
  }
`;
