import React from 'react';
import { Button, Box } from '@material-ui/core';
import { PeopleOutlined, KeyboardArrowDownRounded } from '@material-ui/icons';
import { useRosterStatusStyles } from './RosterTableStyles';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

interface Props {
  color?: string;
  text: string;
  onClick: (arg: any) => void;
  disabled?: boolean;
}

export function RosterStatus({ text, onClick, color = 'tomato', disabled }: Props) {
  const classes = useRosterStatusStyles({ color });
  const { isMobile } = useCampfireTheme();
  return (
    <Button
      onClick={onClick}
      variant='outlined'
      startIcon={
        <PeopleOutlined
          style={{
            fontSize: '18px',
          }}
        />
      }
      disabled={disabled}
      endIcon={<KeyboardArrowDownRounded style={{ fontSize: '18px' }} />}
      classes={{ root: isMobile ? classes.statusContainerMobile : classes.statusContainer }}
      style={{ color: text === 'Unavailable' ? '#9e9e9e' : '' }}
    >
      {text}
    </Button>
  );
}

export function RosterCompletedStatus({ color = '#ABDC40' }: { color?: string }) {
  return <Box bgcolor={color} width='1rem' height='1rem' borderRadius='50%' margin='auto' />;
}
