import { Pane, PaneScrollable, PaneWrapper } from '@campfire/pane';
import { Box, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';
import { BooleanParam, useQueryParams } from 'use-query-params';
import { boolean as YupBool, object as YupObject, string as YupString } from 'yup';
import { StickyHeader } from '../../../../../common/StickyHeader';
import { ReportTypeFormSections } from '../../common/report-type-form-sections/ReportTypeFormSections';
import { DEFAULT_RETURN_SLUG } from '../../ReportManagementConsts';
import { useReportTypeActions } from './report-type-form-actions';

const ValidationSchema = YupObject().shape({
  reportTypeId: YupString(),
  reportTypeName: YupString().required('Please provide a name'),
  active: YupBool().required(),
});

export const CreateReportTypeForm = () => {
  const [query, setQuery] = useQueryParams({
    createReportType: BooleanParam,
  });

  const history = useHistory();
  const { runSaveReportType, runSaveReportTypeLoading } = useReportTypeActions();

  const handleSaveReportSuccess = (newReportTypeId: string) => {
    history.replace(`${DEFAULT_RETURN_SLUG}?tab=report-types&reportTypeId=${newReportTypeId}`);
  };

  return (
    <Formik
      initialValues={{
        reportTypeId: '',
        reportTypeName: '',
        reportTypeDescription: '',
        items: [],
        active: true,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => runSaveReportType({ values, handleSuccess: handleSaveReportSuccess })}
    >
      {() => {
        return (
          <PaneWrapper>
            <Pane>
              <Form style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', height: 'auto' }}>
                <StickyHeader
                  handleDiscard={() => setQuery({ ...query, createReportType: undefined })}
                  saveButtonName='Save Report Type'
                  closeButtonName='Cancel'
                  saveButtonProps={{
                    variant: 'contained',
                    color: 'primary',
                    disabled: runSaveReportTypeLoading,
                  }}
                />
                <PaneScrollable>
                  <Box display='flex' flex='1 1 auto' flexDirection='column' padding={4} height={1}>
                    <Typography variant='h4' component='h1' display='inline'>
                      Report Type
                    </Typography>
                    <ReportTypeFormSections />
                  </Box>
                </PaneScrollable>
              </Form>
            </Pane>
          </PaneWrapper>
        );
      }}
    </Formik>
  );
};
