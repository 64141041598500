import React, { ReactNode } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import {
  Cancel,
  CheckCircle,
  ChevronRight,
  RemoveCircle,
  WatchLaterSharp,
  Close as CloseIcon,
  EditOutlined,
} from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { darken } from '@material-ui/core/styles';
import { unpackToDate, unpackToTime } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import {
  ActivityReportGetActivityReport_vm_activityReport_sessionReports as SessionReportType,
  ActivityReportGetActivityReport_vm_activity_allSessions as SessionType,
} from '../__generated__/ActivityReportGetActivityReport';
import { FullscreenDialog } from '../../../../common/dialogs/FullscreenDialog';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ActivityReport } from '../../../program-manager/rosters/main-section/roster/roster-table/ActivityReport';
import { SessionReportForm } from '../../../program-manager/activities/activity-timeline/past/report/form/SessionReportForm';
import { SessionReportFormV2 } from '../../../program-manager/activities/activity-timeline/past/report/form/SessionReportFormV2';
import { SessionReportOption } from './SessionReportOption';
import {
  useCancelSessionReportFetch,
  useUnOmitSessionReportFetch,
} from '../../../program-manager/activities/activity-timeline/activity-timeline-actions';
import { useSnackbar } from '../../../../global/config/useSnackbar';

const useStyles = (color: string) => {
  const { bgColor, textColor, iconColor, buttonColor } = (() => {
    switch (color) {
      case 'completed':
        return { bgColor: '#ECFDF5', textColor: '#23735C', iconColor: '#34d399', buttonColor: '#76ddac' };
      case 'incomplete':
        return { bgColor: '#FEF2F2', textColor: '#991B1B', iconColor: '#F87171', buttonColor: '#ed9b9b' };
      default:
        return { bgColor: '#F2F2F2', textColor: '#545454', iconColor: '#646464', buttonColor: '#9e9e9e' };
    }
  })();

  return makeStyles(() =>
    createStyles({
      container: {
        display: 'flex',
        paddingLeft: 22,
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 11,
        alignItems: 'center',
        backgroundColor: bgColor,
        cursor: 'pointer',
      },
      icon: {
        color: iconColor,
        fontSize: '1.5rem',
      },
      textContainer: {
        marginLeft: 12,
        flex: 1,
        '&>h5': {
          color: textColor,
          fontWeight: 600,
          fontSize: '1rem',
          lineHeight: 1.5,
        },
        '&>p': {
          color: textColor,
          fontWeight: 400,
          fontSize: 12,
          lineHeight: '10px',
        },
      },
      button: {
        backgroundColor: buttonColor,
        color: 'white',
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: '1.5rem',
        borderRadius: 6,
        '&:hover': {
          backgroundColor: darken(buttonColor, 0.1),
        },
        alignSelf: 'center',
      },
    })
  )();
};

interface CampfireAccordionProps {
  icon: React.ReactElement;
  title: string;
  subTitle: string;
  onClick: () => void;
  color: string;
  disabled?: boolean;
}
export function CampfireAccordion({ icon, title, subTitle, onClick, color, disabled }: CampfireAccordionProps) {
  const classes = useStyles(color);
  const { isMobile } = useCampfireTheme();

  return (
    <Box className={classes.container} onClick={onClick} style={{ cursor: !disabled ? 'pointer' : 'inherit' }}>
      {React.cloneElement(icon, { className: classes.icon })}
      <Box className={classes.textContainer}>
        <Typography variant='h5'>{title}</Typography>
        <Typography variant='body1' style={{ lineHeight: isMobile ? '18px' : 'inherit' }}>
          {subTitle}
        </Typography>
      </Box>
      {!disabled ? (
        <IconButton className={classes.button} onClick={onClick}>
          <ChevronRight />
        </IconButton>
      ) : null}
    </Box>
  );
}

export const ActivityReportDetailDialog = ({
  session,
  activityName,
  activityDate,
  publishedRoster,
  sessionReport,
  onEdit,
  dialogOpen,
  onDialogClose,
  isEditting,
  setIsEditting,
  dialogHeaderOption,
}: {
  dialogOpen: boolean;
  onDialogClose: () => void;
  onEdit: () => void;
  session: SessionType;
  sessionReport?: SessionReportType;
  activityDate: string;
  activityName: string;
  publishedRoster: any;
  isEditting: boolean;
  setIsEditting: (isEditting: boolean) => void;
  dialogHeaderOption?: ReactNode;
}) => {
  const { isMobile, theme } = useCampfireTheme();
  const handleCancel = () => {
    onDialogClose();
    setIsEditting(false);
  };
  const handleClose = () => {
    onDialogClose();
    setIsEditting(false);
  };

  const onSuccess = () => {
    onEdit();
    onDialogClose();
    setIsEditting(false);
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} fullScreen={isMobile} fullWidth maxWidth='sm'>
      <DialogTitle>
        <Grid
          container
          justify={'space-between'}
          style={{
            paddingBottom: '16px',
            paddingLeft: '8px',
            borderBottom: `1px solid ${theme.color.grey.neutralBrand200}`,
          }}
        >
          <Grid item xs>
            <Box>
              <Typography
                style={{
                  color: theme.color.grey.neutral500,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                }}
              >
                {activityName}
              </Typography>
              <Typography
                style={{
                  color: theme.color.grey.neutral500,
                  fontSize: '24px',
                  fontWeight: 600,
                  lineHeight: '30px',
                }}
              >
                {session.name}
              </Typography>
              <Typography style={{ color: theme.color.grey.neutral500, fontSize: '14px', paddingTop: '8px' }}>
                {`${unpackToDate(activityDate).toFormat('EEEE, DD')} - Scheduled: ${unpackToTime(
                  session.startTime
                ).toFormat('h:mm a')} - ${unpackToTime(session.endTime).toFormat('h:mm a')}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              {!isEditting && (
                <TabletButton
                  variant='outlined'
                  color='primary'
                  startIcon={<EditOutlined style={{ fontSize: 14 }} />}
                  style={{ boxSizing: 'border-box', borderRadius: '4px', padding: '2px 8px', fontWeight: 600 }}
                  onClick={() => setIsEditting(true)}
                >
                  Edit Report
                </TabletButton>
              )}
              <Box>{dialogHeaderOption}</Box>
              <IconButton key='close' aria-label='Close' color='inherit' onClick={handleClose}>
                <CloseIcon color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        {isEditting ? (
          <Box>
            {
              <SessionReportFormV2
                session={session}
                publishedRoster={publishedRoster}
                activityDate={activityDate}
                onCancelClick={handleCancel}
                onSubmitSuccess={onSuccess}
                sessionReport={sessionReport}
              />
            }
          </Box>
        ) : (
          <ActivityReport session={{ reports: [sessionReport] }} />
        )}
      </DialogContent>
    </Dialog>
  );
};

interface CompletedSessionReportProps {
  session: SessionType;
  sessionReport: SessionReportType;
  onEdit: () => void;
  activityDate: string;
  activityName: string;
  publishedRoster: any;
}
export function CompletedSessionReport({
  session,
  sessionReport,
  onEdit,
  activityDate,
  activityName,
  publishedRoster,
}: CompletedSessionReportProps) {
  const { profile } = sessionReport.submittedBy;
  const submitssionName = `${profile.preferredName} ${profile.lastName}`;
  const submittedDate = unpackToDate(sessionReport.submissionDate);
  const [isEditting, setIsEditting] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const onDialogClose = () => {
    setDialogOpen(false);
  };
  const subTitle =
    session?.reportType === null && session?.autoReport && profile?.email === 'support@volaby.org'
      ? `Automatically generated on ${submittedDate.toFormat('dd LLLL yyyy')}`
      : `Completed by ${submitssionName}, on ${submittedDate.toFormat('dd LLLL yyyy')}`;

  return (
    <React.Fragment>
      <CampfireAccordion
        title={session.name}
        subTitle={subTitle}
        icon={<CheckCircle />}
        onClick={() => setDialogOpen(true)}
        color='completed'
      />
      {dialogOpen && (
        <ActivityReportDetailDialog
          session={session}
          activityName={activityName}
          activityDate={activityDate}
          publishedRoster={publishedRoster}
          sessionReport={sessionReport}
          isEditting={isEditting}
          setIsEditting={setIsEditting}
          onEdit={onEdit}
          dialogOpen={dialogOpen}
          onDialogClose={onDialogClose}
        />
      )}
    </React.Fragment>
  );
}

interface IncompleteReportProps {
  session: SessionType;
  activityDate: string;
  activityName: string;
  publishedRoster: any;
  sessionReport: any;
  onEdit?: any;
}

export const IncompletedSessionReport = React.memo(function IncompletedSessionReportComponent({
  session,
  activityDate,
  activityName,
  publishedRoster,
  sessionReport,
  onEdit,
}: IncompleteReportProps) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const onDialogClose = () => setDialogOpen(false);
  const omitSessionReport = useCancelSessionReportFetch();

  const { setSnackbar } = useSnackbar();

  const onOmit = () => {
    omitSessionReport
      .run({
        sessionId: session.sessionId,
        activityDate: activityDate,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to omit session report',
            variant: 'error',
          });
        } else {
          setSnackbar({
            open: true,
            message: 'Omit session report success',
            variant: 'success',
          });
          onEdit();
        }
      });
  };

  return (
    <React.Fragment>
      <CampfireAccordion
        title={session.name}
        subTitle={'Incomplete'}
        icon={<Cancel />}
        onClick={() => setDialogOpen(true)}
        color='incomplete'
      />
      {dialogOpen && (
        <ActivityReportDetailDialog
          session={session}
          activityName={activityName}
          activityDate={activityDate}
          publishedRoster={publishedRoster}
          sessionReport={sessionReport}
          isEditting
          setIsEditting={() => {}}
          onEdit={onEdit}
          dialogOpen={dialogOpen}
          onDialogClose={onDialogClose}
          dialogHeaderOption={<SessionReportOption onOmit={onOmit} />}
        />
      )}
    </React.Fragment>
  );
});

interface CancelledReportProps {
  session: SessionType;
  activityDate: string;
  publishedRoster: any;
  onEdit?: any;
}

export function CancelledSessionReport({ session, activityDate, publishedRoster, onEdit }: CancelledReportProps) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const onDialogClose = () => setDialogOpen(false);
  const { isMobile } = useCampfireTheme();

  const unOmitSessionReport = useUnOmitSessionReportFetch();
  const { setSnackbar } = useSnackbar();

  const onUnOmit = () => {
    unOmitSessionReport
      .run({
        sessionId: session.sessionId,
        activityDate,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to remove session report omission',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Un-Omitted session report',
          variant: 'success',
        });
        onEdit();
      });
  };

  return (
    <>
      <CampfireAccordion
        title={session.name}
        subTitle={'Omitted'}
        icon={<RemoveCircle />}
        onClick={() => setDialogOpen(true)}
        color='cancelled'
      />
      <FullscreenDialog
        title={session.name}
        fullScreen={isMobile}
        open={dialogOpen}
        close={onDialogClose}
        dialogHeaderOption={<SessionReportOption onUnOmit={onUnOmit} />}
      >
        <Box>
          <SessionReportForm
            session={session}
            publishedRoster={publishedRoster}
            activityDate={activityDate}
            onCancelClick={onDialogClose}
            onSubmitSuccess={onDialogClose}
          />
        </Box>
      </FullscreenDialog>
    </>
  );
}

export function NulledSessionReport({ session, activityDate, publishedRoster, onEdit }: CancelledReportProps) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const onDialogClose = () => setDialogOpen(false);
  const { isMobile } = useCampfireTheme();
  const omitSessionReport = useCancelSessionReportFetch();

  const { setSnackbar } = useSnackbar();

  const onOmit = () => {
    omitSessionReport
      .run({
        sessionId: session.sessionId,
        activityDate: activityDate,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to omit session report',
            variant: 'error',
          });
        } else {
          setSnackbar({
            open: true,
            message: 'Omit session report success',
            variant: 'success',
          });
          onEdit();
        }
      });
  };

  return (
    <>
      <CampfireAccordion
        title={session.name}
        subTitle={publishedRoster ? (session.autoReport ? 'Report Generating...' : 'No Report Required') : 'Incomplete'}
        icon={publishedRoster ? session.autoReport ? <WatchLaterSharp /> : <RemoveCircle /> : <Cancel />}
        onClick={() => (!publishedRoster ? setDialogOpen(true) : null)}
        color={publishedRoster ? 'cancelled' : 'incomplete'}
        disabled={publishedRoster}
      />
      <FullscreenDialog
        title={session.name}
        fullScreen={isMobile}
        open={dialogOpen}
        close={onDialogClose}
        dialogHeaderOption={<SessionReportOption onOmit={onOmit} />}
        style={{ zIndex: 100 }}
      >
        <Box>
          <SessionReportForm
            session={session}
            publishedRoster={publishedRoster}
            activityDate={activityDate}
            onCancelClick={onDialogClose}
            onSubmitSuccess={onEdit}
          />
        </Box>
      </FullscreenDialog>
    </>
  );
}

export function NulledCICOSessionReport({
  activityName,
  sessionReport,
  session,
  activityDate,
  publishedRoster,
  onEdit,
}: CompletedSessionReportProps) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const onDialogClose = () => setDialogOpen(false);
  const [isEditting, setIsEditting] = React.useState(false);

  return (
    <>
      <CampfireAccordion
        title={session.name}
        subTitle={publishedRoster ? 'Check-Ins Completed' : 'Check-Outs Missing'}
        icon={<CheckCircle />}
        onClick={() => setDialogOpen(true)}
        color='completed'
      />
      {dialogOpen && (
        <ActivityReportDetailDialog
          session={session}
          activityName={activityName}
          activityDate={activityDate}
          publishedRoster={publishedRoster}
          sessionReport={sessionReport}
          isEditting={isEditting}
          setIsEditting={setIsEditting}
          onEdit={onEdit}
          dialogOpen={dialogOpen}
          onDialogClose={onDialogClose}
        />
      )}
    </>
  );
}

type SessionReportProps = {
  variant: 'completed' | 'incomplete' | 'cancelled' | 'null' | 'null-cico';
  session: SessionType;
  sessionReport?: SessionReportType;
  activityDate: string;
  activityName?: string;
  publishedRoster: any;
  onEdit?: any;
};
export function SessionReport({ variant, ...remain }: SessionReportProps) {
  switch (variant) {
    case 'completed':
      return <CompletedSessionReport {...(remain as CompletedSessionReportProps)} />;
    case 'incomplete':
      return <IncompletedSessionReport {...(remain as IncompleteReportProps)} />;
    case 'null':
      return <NulledSessionReport {...(remain as IncompleteReportProps)} />;
    case 'null-cico':
      return <NulledCICOSessionReport {...(remain as CompletedSessionReportProps)} />;
    default:
      return <CancelledSessionReport {...(remain as CancelledReportProps)} />;
  }
}

export { SessionReportType };
