import { Grid } from '@material-ui/core';
import React from 'react';
import { ReportTypeFormBasicInfoSection } from './ReportTypeFormBasicInfoSection';
import { ReportTypeFormItemsSection } from './ReportTypeFormItemsSection';
import { ReportTypeFormStatusSection } from './ReportTypeFormStatusSection';

export const ReportTypeFormSections = ({ forceActive }: { forceActive?: boolean }) => {
  return (
    <Grid container>
      <Grid item xs={12} style={{ padding: 16 }}>
        <ReportTypeFormBasicInfoSection />
        <ReportTypeFormItemsSection />
        {forceActive ? null : <ReportTypeFormStatusSection />}
      </Grid>
    </Grid>
  );
};
