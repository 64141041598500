import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { MyTasks } from '../../../../user-profile/tasks/MyTasks';
import { useVolDashboardMyElementsContext } from '../../VolDashboardMyElementsContext';

export const MyTasksTabContent = () => {
  const { theme } = useCampfireTheme();
  const { getSideBarMyTasksDataRefetch, viewMode } = useVolDashboardMyElementsContext();

  return (
    <Box padding='24px 38px 0'>
      <Typography style={{ color: theme.color.grey.neutral200 }}>
        These tasks below have been assigned for you to complete.
      </Typography>
      <Box marginTop='30px'>
        <MyTasks refetchParentTasks={getSideBarMyTasksDataRefetch} viewMode={viewMode} />
      </Box>
    </Box>
  );
};
