import { useState } from 'react';
import { useBetween } from 'use-between';


const useCalendar = () => {
  const [selectedActionFilters, setSelectedActionFilters] = useState({
    rostersNeeded: true,
    notEnoughVolunteers: true,
    allActivities: false,
  });

  return {
    selectedActionFilters,
    setSelectedActionFilters
  };
}

export const useSharedCalendar = () => useBetween(useCalendar);
