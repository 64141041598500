import React from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { DATE_FORMAT } from './helper';

interface Props {
  value: string[];
  onChange: (newValues: string[]) => void;
  multiple?: boolean;
  startDate?: string;
  occurrences: string[];
  availabilities: string[];
  unavailabilities: string[];
}

export function DateSelect({
  value,
  onChange,
  multiple,
  startDate,
  occurrences,
  availabilities,
  unavailabilities,
}: Props) {
  const calendarValue = (() => {
    if (!value) {
      return [];
    }
    return value.map((datum) => new DateObject({ date: datum, format: DATE_FORMAT }));
  })();

  const minDate = startDate ? new DateObject({ date: startDate, format: DATE_FORMAT }) : new DateObject();

  return (
    <Calendar
      multiple={multiple}
      format={'DD MMM YY'}
      mapDays={({ date }) => {
        const dateStr = date.format(DATE_FORMAT);
        let style = {} as React.CSSProperties;

        if (availabilities.includes(dateStr)) {
          style = {
            backgroundColor: '#0374D533',
            color: '#8899AC',
          };
        }

        if (unavailabilities.includes(dateStr)) {
          style = {
            backgroundColor: '#788B9D40',
            color: '#8899AC',
          };
        }

        const disabled =
          !occurrences.includes(dateStr) || availabilities.includes(dateStr) || unavailabilities.includes(dateStr);

        return {
          disabled,
          style,
        };
      }}
      currentDate={calendarValue[0]}
      minDate={minDate}
      value={calendarValue}
      onChange={(selectDates: DateObject[]) => {
        onChange(selectDates?.map((selectDate) => selectDate.format(DATE_FORMAT)));
      }}
      className='multi-date-picker'
      plugins={[<DatePanel position='right' sort='date' />]}
    />
  );
}
