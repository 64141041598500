import React, { memo, useMemo, ReactNode } from 'react';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { Grid, Typography, Box } from '@material-ui/core';
import { unpackToDateTime } from '@campfire/hot-date';
import { convertFromRaw, EditorState } from 'draft-js';
import { ActivityDayTimeIndicator } from './ActivityDayTimeIndicator';
import { EditLink } from '../../../../common/buttons/EditLink';
import { SemanticTime } from '../../../../common/dates/SemanticTime';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

type HeaderProps = {
  title: ReactNode;
  topLabel?: ReactNode;
  bottomLabel?: ReactNode;
  rightIcon?: ReactNode;
  semanticTime?: {
    display: boolean;
    isFlexible: boolean;
    startTime: string;
  };
};
const HeaderLayout = memo(({ title, topLabel, bottomLabel, rightIcon, semanticTime }: HeaderProps) => {
  return (
    <Grid container style={{ boxSizing: 'border-box', width: '1', paddingBottom: 8 }}>
      <Grid item xs={12} container justify='flex-end'>
        <Grid item xs={rightIcon ? 10 : 12} container direction='column'>
          {topLabel}
          {semanticTime && semanticTime.display && (
            <Box display='inline-block'>
              {semanticTime.isFlexible ? (
                <Typography variant='body2' color='textSecondary'>
                  Dates may vary
                </Typography>
              ) : (
                <SemanticTime
                  dateTime={unpackToDateTime(semanticTime.startTime)}
                  typographyProps={{ variant: 'body2', color: 'textSecondary' }}
                  displayYear
                />
              )}
            </Box>
          )}
          {title}
          {bottomLabel}
        </Grid>
        {rightIcon && (
          <Grid item xs={2}>
            {rightIcon}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

type ActivityHeaderProps = {
  activityName: string;
  activityOccurrence?: string;
  activityTime?: string;
  activityId: string;
  activityAddress?: string;
  activityDayTimeIndicator?: string;
  activityDescription?: string;
  activityStatus?: ReactNode;
  isEditable: boolean;
  semanticTime?: {
    display: boolean;
    isFlexible: boolean;
    startTime: string;
  };
};

const ActivityHeader = memo(
  ({
    activityName,
    activityStatus,
    activityId,
    activityDescription,
    activityDayTimeIndicator,
    isEditable,
    semanticTime,
  }: ActivityHeaderProps) => {
    const editorState = useMemo(
      () =>
        activityDescription ? EditorState.createWithContent(convertFromRaw(JSON.parse(activityDescription))) : null,
      [activityDescription]
    );
    const { isMobile } = useCampfireTheme();
    return (
      <HeaderLayout
        title={
          <Typography variant='h6' style={{ fontWeight: 600, color: '#1B1612' }}>
            {activityName}
          </Typography>
        }
        topLabel={activityStatus}
        semanticTime={semanticTime}
        bottomLabel={
          <Box>
            <Box width={isMobile ? 1 : 0.8} style={{}}>
              {editorState ? <CampfireRichEditor editorState={editorState} readOnly /> : null}
            </Box>
            {isEditable && (
              <Box>
                <EditLink
                  linkText='Edit Activity'
                  link={`/management/activity-management/edit?activityId=${activityId}`}
                />
              </Box>
            )}
          </Box>
        }
        rightIcon={<ActivityDayTimeIndicator indicator={activityDayTimeIndicator} direction='column' />}
      />
    );
  }
);

export { ActivityHeader };
