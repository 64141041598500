import React from 'react';

import { FormControlLabel, Checkbox } from '@material-ui/core';
import { GetActivitisActivityTags_vm_activities as Activity } from '../__generated__/GetActivitisActivityTags';

export interface SelectableActivityTag extends Activity {
  checked: boolean;
}

interface Props {
  activity: SelectableActivityTag;
  onChange: (roleId: string) => void;
  style: any;
}

export function ActivityItem({ activity, onChange, style }: Props) {
  if (!activity) {
    return <div style={style} />;
  }

  return (
    <FormControlLabel
      style={{ padding: '0.5rem', marginRight: 'none', ...style }}
      control={
        <Checkbox
          checked={activity.checked}
          onChange={() => onChange(activity.activityId)}
          color='primary'
          style={{ marginRight: '10px' }}
        />
      }
      label={activity.name}
    />
  );
}
