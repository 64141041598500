import { Grid, Typography, Select, Checkbox, MenuItem, Box } from '@material-ui/core';
import { Check, ArrowDropDown } from '@material-ui/icons';
import React, { useState } from 'react';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { BorderSelectInput, useFilterStyles } from '../../../../../../../common/filter-fields/FilterClasses';
import { IncomingVolunteersSelectFieldProps, DEFAULT_TYPE_FILTER, typeFilters } from '../filters';

export const TypeSelectField = ({ selectedFilters, setSelectedFilters }: IncomingVolunteersSelectFieldProps) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const isStatusSelected = (value: string) => selectedFilters.selectedTypeFilter === value;
  const isActiveFilter = selectedFilters.selectedTypeFilter !== DEFAULT_TYPE_FILTER;

  const handleStatusChange = (event: EventTarget & any) =>
    setSelectedFilters({
      ...selectedFilters,
      selectedTypeFilter: event.target.value,
    });

  return (
    <Grid item className={classes.subheaderGridItem}>
      <Select
        displayEmpty
        open={popoverOpen}
        onOpen={() => setPopoverOpen(true)}
        onClose={() => setPopoverOpen(false)}
        input={<BorderSelectInput status={isActiveFilter ? 'active' : undefined} />}
        IconComponent={() => (
          <Box display='flex' alignItems='center' onClick={() => setPopoverOpen(true)} style={{ cursor: 'pointer' }}>
            <ArrowDropDown style={{ fill: isActiveFilter ? theme.palette.primary.main : 'rgba(0,0,0, 0.54)' }} />
          </Box>
        )}
        renderValue={() => selectedFilters.selectedTypeFilter}
        onChange={handleStatusChange}
        value={selectedFilters.selectedTypeFilter}
      >
        {typeFilters.map((filter) => (
          <MenuItem key={filter} value={filter} className={classes.menuItem}>
            <Typography
              variant='body2'
              style={isStatusSelected(filter) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
            >
              {filter}
            </Typography>
            <Checkbox
              checked={isStatusSelected(filter)}
              className={classes.checkBoxSelectField}
              checkedIcon={<Check color='primary' width={12} />}
              icon={<span className={classes.icon} />}
              disableRipple
            />
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
