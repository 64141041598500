import { gql } from '@apollo/client';

export const ActivityAttachmentsFragments = gql`
  fragment ActivitiesActivityAttachments on VOLUNTEER_ActivityAttachmentType {
    name
    url
    fileType
    activityAttachmentId
  }
`;
