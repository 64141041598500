import { TitularTooltip } from '@campfire/titular-tooltip';
import React, { memo } from 'react';
import { useRouteMatch } from 'react-router';
import { ListItem, Grid, Typography, Box } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { HoverLink } from '@campfire/hover-link';
import { Link } from 'react-router-dom';
import { ResourcesList } from './ResourcesList';
import { ResourcesListItem } from './ResourcesListItem';
import { SupportCategory } from '../__generated__/SupportCategory';
import { useSupportContext } from '../SupportContext';

interface CategoriesListItemProps {
  category: SupportCategory;
}

const CategoriesListItem = memo((props: CategoriesListItemProps) => {
  const { category } = props;
  const { isAdmin } = useSupportContext();
  const match = useRouteMatch();

  return (
    <ListItem style={{ marginTop: 16, paddingBottom: 48 }}>
      <Grid container justify='center' alignItems='center' spacing={2}>
        <Grid item container justify='center' xs>
          <HoverLink
            to={match?.url ? `${match.url}/${category.supportCategoryId}` : ''}
            color='textPrimary'
            hoverColor='textPrimary'
            disableUnderline
            data-track='resources-landingppage-category-title'
          >
            <Typography display='inline' align='center' variant='h4' style={{ fontWeight: 'bold' }}>
              {category.title}
            </Typography>
          </HoverLink>
        </Grid>

        <Grid item>
          {isAdmin ? (
            <HoverLink
              data-track='resources-landingppage-edit-category'
              to={match?.url ? `${match.url}/${category.supportCategoryId}?cedit=1` : ''}
              color='primary'
            >
              <Typography variant='body2'>{'Edit Category'}</Typography>
            </HoverLink>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          {category.supportResources.length ? (
            <ResourcesList resources={category.supportResources} truncate>
              {(sortedResources) => {
                return sortedResources.map((resource, idx) => (
                  <Link
                    data-track='resources-landingppage-resource-item'
                    key={resource.supportResourceId}
                    to={{
                      pathname:
                        match !== null
                          ? `${match.url}/${category.supportCategoryId}/${resource.supportResourceId}`
                          : '',
                      state: { returnSlug: match?.url },
                    }}
                    style={{ textDecoration: 'none', textDecorationColor: 'none', color: 'inherit', width: '100%' }}
                  >
                    <ResourcesListItem
                      resource={resource}
                      borderBottom={sortedResources.length < 2 || idx !== sortedResources.length - 1}
                      showUploadDate={false}
                    />
                  </Link>
                ));
              }}
            </ResourcesList>
          ) : (
            <Grid container justify='center' style={{ padding: 16, paddingTop: 32, paddingBottom: 48 }}>
              <TitularTooltip
                placement='right'
                title='This category is empty'
                description='No resources have been added to this category yet.'
              >
                <Typography
                  display='inline'
                  variant='body2'
                  color='textSecondary'
                >{`Nothing to show here.`}</Typography>
              </TitularTooltip>
            </Grid>
          )}
        </Grid>

        {category.supportResources.length > 5 ? (
          <Grid item>
            <HoverLink
              data-track='resources-landingppage-view-all-items'
              to={match?.url ? `${match.url}/${category.supportCategoryId}` : ''}
              color='primary'
            >
              <Box
                display='flex'
                justifyContent='center'
                alignContent='center'
                alignItems='center'
                flexDirection='column'
              >
                {`View all ${category.supportResources.length} items`}
                {/* <MenuBook style={{ fontSize: 16, marginTop: 3 }} /> */}
                <MoreHoriz style={{ marginTop: 3 }} />
              </Box>
            </HoverLink>
          </Grid>
        ) : null}
      </Grid>
    </ListItem>
  );
});

export { CategoriesListItem };
