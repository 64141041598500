import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Clear, EventAvailable, CheckCircle, Cancel, Help } from '@material-ui/icons';
import React, { useState } from 'react';
import { VolunteerDialogHoverBlock } from '../../../../volunteer-common-profile/VolunteerDialogHoverBlock';
import { ActivityTimelineFutureRosterVolunteer } from '../../__generated__/ActivityTimelineFutureRosterVolunteer';
import { useVolunteerGridItemStyles } from './activity-roster-styles';
import { ROSTER_SCROLL_DIMENSION } from './use-roster-scrolling';
import { VolunteerUnavailabilityConfirmDialog } from './VolunteerUnavailabilityConfirmDialog';

type VolunteerProfileGridItemProps = {
  activityDate: string;
  activityEnrolmentId?: string;
  activityRolesDisplayText?: string;
  activityUnavailabilityId?: string;
  runRefetch: () => void;
  volunteer: ActivityTimelineFutureRosterVolunteer;
  attending?: boolean | null;
};

interface VolunteerAvatarNameBoxProps {
  avatarUrl: string | null;
  isNew: boolean;
  lastName: string;
  preferredName: string;
  rolesText?: string;
  profileId: string;
  userId: string;
  attending?: boolean | null;
}

const VolunteerAvatarNameBox = (props: VolunteerAvatarNameBoxProps) => {
  const { preferredName, profileId, userId, lastName, avatarUrl, rolesText, isNew, attending } = props;
  return (
    <Box display='flex' alignContent='center' alignItems='center' marginY={1} width={1}>
      <Box marginLeft={1} marginRight={1}>
        {attending ? (
          <TitularTooltip description={'Attending'}>
            <CheckCircle fontSize='small' htmlColor='#7DD268' />
          </TitularTooltip>
        ) : attending === null ? (
          <TitularTooltip description={'Unconfirmed'}>
            <Help fontSize='small' htmlColor='#00000024' />
          </TitularTooltip>
        ) : (
          <TitularTooltip description={'Not attending'}>
            <Cancel fontSize='small' color='error' />
          </TitularTooltip>
        )}
      </Box>
      <Box marginLeft={1} overflow='hidden'>
        <VolunteerDialogHoverBlock
          profileId={profileId}
          userId={userId}
          preferredName={preferredName}
          lastName={lastName}
          avatarUrl={avatarUrl}
          secondaryText={
            <Typography variant='caption' color='textSecondary' component='span' noWrap style={{ display: 'block' }}>
              {isNew ? (
                <TitularTooltip description='This volunteer has yet to attend their first date for this activity'>
                  <Typography
                    component='span'
                    variant='caption'
                    gutterBottom={false}
                    style={{ color: '#2196f3', fontWeight: 'bold', fontSize: 11, cursor: 'default', marginRight: 8 }}
                  >
                    {'NEW'}
                  </Typography>
                </TitularTooltip>
              ) : null}
              {rolesText}
            </Typography>
          }
          hoverTextProps={{
            hoverColor: 'primary',
            variant: 'body2',
            disableUnderline: true,
            noWrap: true,
            style: {
              lineHeight: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

const VolunteerProfileGridItem = React.memo((props: VolunteerProfileGridItemProps) => {
  const {
    activityEnrolmentId,
    volunteer,
    activityDate,
    runRefetch,
    activityUnavailabilityId,
    activityRolesDisplayText,
    attending,
  } = props;
  const {
    profile: { profileId, userId, preferredName, lastName, avatarUrl },
    volunteerId,
    hasAttendanceForActivity,
  } = volunteer;
  const [showMakeUnavailableDialog, setShowMakeUnavailableDialog] = useState(false);
  const classes = useVolunteerGridItemStyles();

  return (
    <Box
      key={volunteerId}
      className={classes.volunteerProfileGridItem}
      display='flex'
      flex='1 0 0'
      alignContent='center'
      alignItems='center'
      justifyContent='space-between'
      width={ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH}
      minWidth={ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH}
      maxWidth={ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH}
      paddingLeft={2}
      boxSizing='border-box'
    >
      <VolunteerAvatarNameBox
        profileId={profileId}
        userId={userId}
        preferredName={preferredName}
        lastName={lastName}
        avatarUrl={avatarUrl}
        rolesText={activityRolesDisplayText}
        isNew={!!activityEnrolmentId && hasAttendanceForActivity === false}
        attending={attending}
      />

      {activityEnrolmentId ? (
        <Box className={classes.volunteerProfileAvailabilityButton}>
          <TitularTooltip enterDelay={400} title={activityUnavailabilityId ? 'Make available' : 'Remove availability'}>
            <IconButton
              id={
                activityUnavailabilityId
                  ? 'actCnl-roster-reinstall-volunteer-availability'
                  : 'actCnl-roster-remove-volunteer-availability'
              }
              onClick={() => setShowMakeUnavailableDialog(true)}
            >
              {activityUnavailabilityId ? <EventAvailable /> : <Clear fontSize='small' color='error' />}
            </IconButton>
          </TitularTooltip>
        </Box>
      ) : null}

      <VolunteerUnavailabilityConfirmDialog
        open={showMakeUnavailableDialog}
        preferredName={preferredName}
        activityDate={activityDate}
        activityEnrolmentId={activityEnrolmentId}
        activityUnavailabilityId={activityUnavailabilityId}
        runRefetch={runRefetch}
        onClose={() => setShowMakeUnavailableDialog(false)}
      />
    </Box>
  );
});

export { VolunteerProfileGridItem };
