import { Field } from '@campfire/field';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useIncidentDetailHeaderActions } from '../incident-detail-header-actions';

interface ClosingIncidentCommentsDialogProps {
  closingCommendsDialogOpen: boolean;
  handleClose: () => void;
  incidentId: string;
  getIncidentsRefetch: () => void;
}

export const ClosingIncidentCommentsDialog = (props: ClosingIncidentCommentsDialogProps) => {
  const [query, setQuery] = useQueryParams({
    incidentId: StringParam,
  });

  const { closingCommendsDialogOpen, handleClose, incidentId, getIncidentsRefetch } = props;
  const { runMoveIncidentToCompleted, runMoveIncidentToCompletedIsLoading } = useIncidentDetailHeaderActions();
  const { isMobile } = useCampfireTheme();

  return (
    <Dialog
      fullWidth
      open={closingCommendsDialogOpen}
      onClose={handleClose}
      aria-labelledby='close-incident-dialog-title'
      aria-describedby='close-incident-dialog-description'
    >
      <Formik
        initialValues={{ closingComments: '' }}
        onSubmit={(values) => {
          handleClose();
          runMoveIncidentToCompleted({
            incidentId,
            closingComments: values.closingComments,
            handleSuccess: () => {
              handleClose();
              if (getIncidentsRefetch) getIncidentsRefetch();
              if (isMobile) setQuery({ ...query, incidentId: undefined });
            },
          });
        }}
      >
        <Form>
          <DialogTitle id='close-incident-dialog-title'>Close Incident</DialogTitle>
          <DialogContent>
            <Field
              autoFocus
              name='closingComments'
              multiline
              rows={6}
              variant='outlined'
              label='Closing Comments (optional)'
              placeholder='Leave blank if there are no comments for closing this issue'
              margin='normal'
              fullWidth
              helper
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type='submit' color='primary' disabled={runMoveIncidentToCompletedIsLoading}>
              Close Incident
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};
