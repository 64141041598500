import { Grid, Switch, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';
import { ReportTypeFormValues } from './report-type-form-types';

export const ReportTypeFormStatusSection = () => {
  const formik = useFormikContext<ReportTypeFormValues>();
  return (
    <FormSectionV2
      title='Status'
      description={
        <Typography variant='caption' color='textSecondary'>
          Toggle the status of this report type
        </Typography>
      }
    >
      <Grid container spacing={2}>
        <Grid item>
          <Switch
            color='primary'
            checked={!formik.values.active || false}
            onChange={() => formik.setFieldValue('active', !formik.values.active)}
          />
        </Grid>
        <Grid item xs container direction='column'>
          <Typography variant='subtitle2' display='inline'>
            Archive this report?
          </Typography>
          <Typography variant='caption'>
            Archiving this report will hide it by default, meaning that it will not be a visible option when creating or
            editing sessions
          </Typography>
        </Grid>
      </Grid>
    </FormSectionV2>
  );
};
