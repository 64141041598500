import { Pane, PaneScrollable } from '@campfire/pane';
import { Box, Grid } from '@material-ui/core';
import React, { memo } from 'react';
import { useHistory } from 'react-router';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { ActivityTimelineActivityCancellation } from '../common/ActivityTimelineActivityCancellation';
import { LOG_WIDTH, PastSidebarItem, SidebarItem } from '../sidebar/ActivityTimelineSidebar';
import { ActivityTimelinePastReport } from './report/ActivityTimelinePastReport';
import { ActivityTimelinePastRoster } from './roster/ActivityTimelinePastRoster';

interface ActivityTimelinePastProps {
  pastSidebarItem: PastSidebarItem;
  patchSelectedSidebarItem: (partialSidebarItem: Partial<SidebarItem>) => void;
}

export const ActivityTimelinePast = memo((props: ActivityTimelinePastProps) => {
  const { pastSidebarItem, patchSelectedSidebarItem } = props;

  const onSessionReportSubmit = ({
    activityReportId,
    sessionReportId,
    sessionId,
    activitySessionIds,
  }: {
    activityReportId: string;
    sessionReportId: string;
    sessionId: string;
    activitySessionIds: string[];
  }) =>
    patchSelectedSidebarItem(
      pastSidebarItem.activityReport
        ? {
            activityReport: {
              ...pastSidebarItem.activityReport,
              sessionReports: [
                ...pastSidebarItem.activityReport.sessionReports,
                {
                  __typename: 'VOLUNTEER_SessionReportType',
                  session: {
                    __typename: 'VOLUNTEER_SessionType',
                    sessionId: sessionId,
                  },
                  sessionReportId,
                },
              ],
              cancelledSessions: pastSidebarItem.activityReport.cancelledSessions.filter(
                (x) => x.sessionId !== sessionId
              ),
            },
          }
        : {
            activityReport: {
              __typename: 'VOLUNTEER_ActivityReportType',
              activityReportId,
              activityDate: pastSidebarItem.activityDate,
              activity: {
                __typename: 'VOLUNTEER_RecurringActivityType' || 'VOLUNTEER_NonRecurringActivityType',
                sessions: activitySessionIds.map((id) => ({ __typename: 'VOLUNTEER_SessionType', sessionId: id })),
              },
              sessionReports: [
                {
                  __typename: 'VOLUNTEER_SessionReportType',
                  session: {
                    __typename: 'VOLUNTEER_SessionType',
                    sessionId: sessionId,
                  },
                  sessionReportId,
                },
              ],
              cancelledSessions: [],
            },
          }
    );

  const onSessionReportCancel = ({
    activityReportId,
    sessionId,
    activitySessionIds,
  }: {
    activityReportId: string;
    sessionId: string;
    activitySessionIds: string[];
  }) =>
    patchSelectedSidebarItem(
      pastSidebarItem.activityReport
        ? {
            activityReport: {
              ...pastSidebarItem.activityReport,
              sessionReports: pastSidebarItem.activityReport.sessionReports.filter(
                (x) => x.session.sessionId !== sessionId
              ),
              cancelledSessions: [
                ...pastSidebarItem.activityReport.cancelledSessions,
                {
                  __typename: 'VOLUNTEER_SessionType',
                  sessionId: sessionId,
                },
              ],
            },
          }
        : {
            activityReport: {
              __typename: 'VOLUNTEER_ActivityReportType',
              activityReportId: activityReportId,
              activityDate: pastSidebarItem.activityDate,
              activity: {
                __typename: 'VOLUNTEER_RecurringActivityType' || 'VOLUNTEER_NonRecurringActivityType',
                sessions: activitySessionIds.map((id) => ({ __typename: 'VOLUNTEER_SessionType', sessionId: id })),
              },
              sessionReports: [],
              cancelledSessions: [
                {
                  __typename: 'VOLUNTEER_SessionType',
                  sessionId: sessionId,
                },
              ],
            },
          }
    );

  const onCancelActivity = ({
    cancelledActivityId,
    activityDate,
  }: {
    cancelledActivityId: string;
    activityDate: string;
  }) => {
    patchSelectedSidebarItem({
      cancelledActivity: {
        __typename: 'VOLUNTEER_CancelledActivityType',
        cancelledActivityId: cancelledActivityId,
        activityDate,
      },
      activityReport: undefined,
    });
  };

  const { isXs, isSm } = useCampfireTheme();
  const compact = isXs || isSm;
  const history = useHistory();

  return pastSidebarItem.cancelledActivity ? (
    <ActivityTimelineActivityCancellation
      activityId={pastSidebarItem.activityId}
      cancelledActivity={pastSidebarItem.cancelledActivity}
      activityDate={pastSidebarItem.activityDate}
      onUncancelActivity={() =>
        patchSelectedSidebarItem({
          cancelledActivity: undefined,
        })
      }
    />
  ) : (
    <Box
      position='relative'
      display='flex'
      flex='1 1 auto'
      style={{
        height: `calc(100vh - 106px)`, // hardcoded appbar + activities tabs height
      }}
    >
      {/*
       * We toggle through sm/md and flexWrap here for small width and mobile layouts
       */}
      <Grid container style={{ overflowX: 'hidden', flexWrap: compact ? undefined : 'nowrap', height: '100%' }}>
        <Grid item sm={12} md style={{ display: 'flex', flex: '1 1 auto' }}>
          <Pane>
            <PaneScrollable>
              <ActivityTimelinePastReport
                activityId={pastSidebarItem.activityId}
                activityDate={pastSidebarItem.activityDate}
                onSessionReportSubmit={onSessionReportSubmit}
                onSessionReportCancel={onSessionReportCancel}
                onCancelActivity={onCancelActivity}
                source={'activities-console'}
                onBack={() => history.goBack()}
              />
            </PaneScrollable>
          </Pane>
        </Grid>

        <Grid item sm={12} md={4} style={{ display: 'flex', flex: '1 1 auto', width: LOG_WIDTH, minWidth: LOG_WIDTH }}>
          <Pane>
            <PaneScrollable>
              <ActivityTimelinePastRoster
                activityId={pastSidebarItem.activityId}
                activityDate={pastSidebarItem.activityDate}
                //
              />
            </PaneScrollable>
          </Pane>
        </Grid>
      </Grid>
    </Box>
  );
});
