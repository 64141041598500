import React from 'react';
import { Box, Theme, Button, IconButton } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Close } from '@material-ui/icons';
import { ActivityDescription } from './ActivityDescription';
import { ActivitySession } from './ActivitySession';
import { ActivityRosteredInformation, ActivityTeamInformation } from './ActivityTeamInformation';
import { ActivityAttachments } from './ActivityAttachments';
import { ActivityLocationInformation } from './ActivityLocationInformation';
import { ActivityUpcomingRosters } from './ActivityUpcomingRosters';
import { ActivityContextValue } from '../ActivityContext';
import { SessionActionType } from '../ActivityTypes';
import { useActivityActions } from '../useActivityActions';
import { sharedStyles } from '../ActivityStyles/shared';
import { StatusChip } from '../../../../common/chips/StatusChip';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { getRosterStatus, isActivityEnrolled, getSessionActions, isRosterEnrolled } from '../helpers';
import { ActivityRosterNotes } from './ActivityRosterNotes';
import { ActivityAttendance } from './ActivityAttendance';
import { useUser } from '../../../../global/auth/useUser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('lg')]: {
        marginLeft: -16,
        marginRight: -16,
      },
    },
    closeButton: {
      border: '1px solid',
      borderColor: theme.color.grey.neutralBrand200,
      borderRadius: '6px',
      backgroundColor: 'white',
      padding: '6px',
    },
  })
);
interface Props {
  inMyShift?: boolean;
}

export function ActivitySidebar({ inMyShift }: Props) {
  const classes = useStyles();
  const buttonClasses = sharedStyles.button();
  const { theme } = useCampfireTheme();
  const {
    activityDetails,
    vmVolunteer,
    query,
    vmVolunteerRefetch,
    enrolmentId,
    activityApplicationId,
    activityWaitlistingId,
    availabilityId,
    publishedRosteringId,
    availableSessionIds,
    rosteringSessionIds,
    setQuery,
    refetch,
  } = React.useContext(ActivityContextValue);
  const { onAction, onSessionAction } = useActivityActions(vmVolunteerRefetch);
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  const activity = activityDetails?.vm.activity;

  const attendedSessions =
    activityDetails?.vm.activity?.sessions.filter((item) =>
      item.sessionReports
        .flatMap((s) => s.CICOs)
        .some(
          (c) =>
            c.volunteer.volunteerId === volunteerId &&
            c.checkIn &&
            c.checkOut &&
            c.sessionReport.activityReport.activity.activityId === activity?.activityId &&
            c.sessionReport.activityReport.activityDate === query.activityDate
        )
    ) || [];

  const getRosterStatusByDate = React.useCallback(
    (activityDate: string) => {
      if (activityDetails?.vm.activity && vmVolunteer) {
        return getRosterStatus(activityDetails?.vm.activity, activityDate, vmVolunteer);
      }
      return 'unsure';
    },
    [vmVolunteer, activityDetails]
  );

  const isEnrolled = React.useMemo(() => {
    const activityId = activityDetails?.vm.activity?.activityId;
    if (!activityId || !query.activityDate) {
      return false;
    }
    return isRosterEnrolled(activityId, query.activityDate, vmVolunteer?.vm.volunteer?.activityEnrolments);
  }, [vmVolunteer, activityDetails, query]);

  const actionButton = React.useMemo(() => {
    if (inMyShift) {
      return (
        <IconButton
          onClick={() => setQuery({ activityDate: undefined, activityId: undefined })}
          className={classes.closeButton}
        >
          <Close />
        </IconButton>
      );
    }
    if (!vmVolunteer || !activity || !query.activityDate) {
      return null;
    }
    const status = getRosterStatus(activity, query.activityDate, vmVolunteer);

    const roster = {
      ...activity,
      status,
      activityDate: query.activityDate,
    };

    if (['waitlisted', 'applied', 'rostered'].includes(status) && !activity.isRestrictedActivity) {
      return (
        <Button
          onClick={() =>
            onAction({ enrolmentId, activityApplicationId, activityWaitlistingId, publishedRosteringId })(
              'withdraw',
              roster
            )
          }
          classes={buttonClasses}
          variant='contained'
          color='primary'
        >
          Withdraw
        </Button>
      );
    }

    if (['not on team'].includes(status)) {
      return <StatusChip status='Joined' bgcolor={theme.palette.success.main} />;
    }

    return null;
  }, [vmVolunteer, activity, query, inMyShift]);

  const getActionBySession = React.useCallback(
    (sessionId) => {
      if (activity && query.activityDate && vmVolunteer) {
        return getSessionActions(sessionId, activity, query.activityDate, vmVolunteer);
      }
      return undefined;
    },
    [activity, vmVolunteer, query]
  );

  const onActionSession = (action: SessionActionType, sessionId: string) => {
    if (activity && query.activityDate) {
      onSessionAction({ enrolmentId, availabilityId, publishedRosteringId, availableSessionIds, rosteringSessionIds })(
        action,
        {
          ...activity,
          activityDate: query.activityDate,
          status: getRosterStatus(activity, query.activityDate, vmVolunteer),
        },
        sessionId
      );
    }
  };

  if (!activityDetails || !activity) {
    return null;
  }

  return (
    <Box position='relative' className={classes.container}>
      <ActivityDescription
        {...activity}
        inMyShift={inMyShift}
        activityId={activity.activityId}
        actionButton={actionButton}
      >
        <ActivityRosterNotes
          rosterNotes={activity.publishedRoster?.rosterNotes || activity.draftRoster?.rosterNotes}
          sessionNotes={activity.publishedRoster?.sessionNotes || activity.draftRoster?.sessionNotes || []}
        />
      </ActivityDescription>
      {inMyShift ? null : <ActivityUpcomingRosters {...activity} getRosterStatusByDate={getRosterStatusByDate} />}
      <ActivityAttendance sessions={attendedSessions} refetch={refetch} />
      <ActivitySession
        {...activity}
        getActionBySession={getActionBySession}
        onActionSession={onActionSession}
        showAction={false}
        isEnrolled={isEnrolled}
        sessionRosterings={rosteringSessionIds}
      />
      {inMyShift ? (
        <ActivityRosteredInformation
          {...activity}
          isErolled={Boolean(isActivityEnrolled(activity.activityId, vmVolunteer?.vm.volunteer?.activityEnrolments))}
        />
      ) : (
        <ActivityTeamInformation
          {...activity}
          isErolled={Boolean(isActivityEnrolled(activity.activityId, vmVolunteer?.vm.volunteer?.activityEnrolments))}
        />
      )}
      <ActivityLocationInformation {...activity} key={activity.activityId} />
      <ActivityAttachments {...activity} />
    </Box>
  );
}
