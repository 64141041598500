import React, { useMemo } from 'react';
import { Box, Button, IconButton, Badge, Popover, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import EmojiIcon from '../../assets/emojis/emoji-icon.svg';
import { emojiLists, ReactionType } from './reaction-model';
import { useUser } from '../../global/auth/useUser';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

const useStyles = makeStyles(() =>
  createStyles({
    emojiButton: {
      width: '32px',
      height: '32px',
      '&:hover': {
        borderRadius: '8px',
      },
    },
  })
);

interface Props {
  reactions: ReactionType[];
  handleReaction: (symbol: string, reactionId?: string) => void;
}
export const Reaction = ({ reactions, handleReaction }: Props) => {
  const { theme } = useCampfireTheme();
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const reactionsParsed = useMemo(() => {
    return reactions.reduce((pv, av) => ({ ...pv, [av.emoji]: reactions.filter((r) => r.emoji === av.emoji) }), {});
  }, [reactions]) as { [key: string]: ReactionType[] };

  const onClickGroupReaction = (symbol: string, reaction?: ReactionType) => {
    handleReaction(symbol, reaction?.reactionId);
  };
  const onEmojiPicker = (symbol: string) => {
    const reactionsByGroup = reactionsParsed[symbol] || [];
    const volunteerReaction = reactionsByGroup.find((item) => item.volunteer.volunteerId === volunteerId);
    handleReaction(symbol, volunteerReaction?.reactionId);
    setAnchorEl(null);
  };

  return (
    <Box>
      {emojiLists.map((group) => {
        const reactionsByGroup = reactionsParsed[group.value] || [];
        const volunteerReaction = reactionsByGroup.find((item) => item.volunteer.volunteerId === volunteerId);

        return (
          <>
            {reactionsByGroup.length > 0 && (
              <Button
                startIcon={<img src={group.icon} alt='' />}
                style={{
                  boxShadow: `0 0 1px 0px ${
                    volunteerReaction ? theme.color.secondary.main900 : theme.color.grey.neutralBrand200
                  } inset, 0 0 1px 0px ${
                    volunteerReaction ? theme.color.secondary.main900 : theme.color.grey.neutralBrand200
                  }`,
                  border: `1px solid ${
                    volunteerReaction ? theme.color.secondary.main900 : theme.color.grey.neutralBrand200
                  }`,
                  borderRadius: '25px',
                  minWidth: '60px',
                  marginRight: '4px',
                }}
                onClick={() => onClickGroupReaction(group.value, volunteerReaction)}
              >
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: '13px',
                    color: volunteerReaction ? theme.color.secondary.main900 : theme.color.grey.neutral300,
                  }}
                >
                  {reactionsByGroup.length}
                </Typography>
              </Button>
            )}
          </>
        );
      })}

      <IconButton onClick={(event: any) => setAnchorEl(event.target)}>
        <Badge
          style={{ zIndex: 'unset' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <AddIcon
              fontSize='small'
              htmlColor='#646464'
              style={{ fontSize: 12, background: 'white', borderRadius: '50%', right: '7%', bottom: '7%' }}
            />
          }
        >
          <img src={EmojiIcon} alt='' />
        </Badge>
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box display='flex' alignItems='center' height='32px' p='4px 8px'>
          {emojiLists.map((emoji) => (
            <IconButton className={classes.emojiButton} onClick={() => onEmojiPicker(emoji.value)}>
              <img src={emoji.icon} alt='' />
            </IconButton>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};
