import React, { useState, useMemo } from 'react';
import { Theme, Box, IconButton, InputBase } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchIcon: {
      color: theme.color.grey.neutral400,
      fontSize: 22,
    },
    searchIconFocused: {
      color: theme.palette.action.active,
    },
    input: {
      padding: 8,
      paddingLeft: 0,
    },
    searchField: {
      boxSizing: 'border-box',
      borderRadius: 32,
      display: 'flex',
      transition: 'width 0.2s',
      overflow: 'hidden',
      width: '35px',
      height: 35,
    },
    searchFieldFocused: {
      border: `2px solid ${theme.palette.primary.main}`,
      overflow: 'visible',
      width: '100%',
    },
    searchFieldActive: {
      border: `1px solid ${theme.palette.action.disabled}`,
      overflow: 'visible',
      width: '100%',
    },
  })
);

interface Props {
  id?: string;
  placeholder?: string;
  fullWidth?: boolean;
  growLeft?: boolean;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleEnterPress?: () => void;
  height?: string | number;
}

const SearchField = (props: Props) => {
  const { onChange, value, placeholder, id, height, fullWidth, growLeft, handleEnterPress } = props;
  const classes = useStyles();
  const [focused, setFocused] = useState(false);
  const [currInput, setCurrInput] = useState<string>();
  const searchActive = useMemo(() => !!currInput || fullWidth, [currInput, fullWidth]);
  return (
    <Box
      display='flex'
      justifyContent={growLeft ? 'flex-end' : 'flex-start'}
      style={{ padding: focused && !height ? 0 : '1px' }}
      height={height}
    >
      <Box
        className={classNames(classes.searchField, {
          [classes.searchFieldFocused]: focused,
          [classes.searchFieldActive]: searchActive && !focused,
        })}
      >
        <IconButton onClick={() => setFocused(true)} style={{ padding: 8 }} disabled={focused || searchActive}>
          <SearchIcon className={classNames(classes.searchIcon, { [classes.searchIconFocused]: focused })} />
        </IconButton>
        <InputBase
          id={id}
          placeholder={placeholder}
          onChange={(e: any) => {
            setCurrInput(e.target.value);
            onChange(e);
          }}
          value={value}
          autoFocus={focused}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          inputRef={(input) => input && focused && input.focus()}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13 && handleEnterPress) {
              handleEnterPress();
            }
          }}
          fullWidth
        />
      </Box>
    </Box>
  );
};

export { SearchField };
