export const DURATION_OPTIONS = [
  'Any duration',
  'Up to 1 hour',
  'Up to 2 hours',
  'Up to 3 hours',
  'Up to 4 hours',
  'Up to 5 hours',
  'More than 5 hours',
].map((label, labelIndex) => ({
  label,
  value: labelIndex,
}));

export const LOCATION_OPTIONS = [
  {
    label: 'All activity locations',
    value: 'all',
  },
  {
    label: 'Local activities',
    value: 'local',
  },
  {
    label: 'Remote activities',
    value: 'remote',
  },
];

export const ACTIVITYTYPE_OPTIONS = [
  {
    label: 'All activites',
    value: 'all',
  },
  {
    label: 'Flexible activites',
    value: 'flexible',
  },
  {
    label: 'Regular activities',
    value: 'regular',
  },
];

export const ACTIVITY_ACTIVENESS_OPTIONS = [
  {
    label: 'All activities',
    value: 'all',
  },
  {
    label: 'Active activities',
    value: 'active',
  },
  {
    label: 'Suspended activities',
    value: 'suspended',
  },
  {
    label: 'Closed activities',
    value: 'closed',
  },
  {
    label: 'Ended activities',
    value: 'ended',
  },
];

export const ACTIVITY_STATUSES_OPTIONS = [
  {
    label: 'All activites',
    value: 'all',
  },
  {
    label: 'Applied activites',
    value: 'applied',
  },
  {
    label: 'Available activities',
    value: 'available',
  },
  {
    label: 'Cancelled activities',
    value: 'cancelled',
  },
  {
    label: 'Rostered activities',
    value: 'rostered',
  },
  {
    label: 'Unvailable activities',
    value: 'unavailable',
  },
  {
    label: 'Unspecified activities',
    value: 'unsure',
  },
  {
    label: 'Waitlisted activities',
    value: 'waitlisted',
  },
];

export const ROSTERTYPES_OPTIONS = [
  {
    label: 'All rosters',
    value: 'all',
  },
  {
    label: 'Managed rosters',
    value: 'managed',
  },
  {
    label: 'Open rosters',
    value: 'open',
  }
];

export const DAY_OPTIONS = 'MTWTFSS'.split('').map((day, dayIndex) => ({ value: dayIndex, label: day }));
