import { DraggableList, DraggableListItem, DraggableListItemHandle } from '@campfire/draggable-list';
import { ButtonBase, Typography, Box, TextField } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { FieldArrayRenderProps, useFormikContext } from 'formik';
import React, { useState } from 'react';

import { ConfirmationDialog } from '../../../../../common/dialogs/ConfirmationDialog';
import {
  ActivityCancellationReasonType,
  ActivityClosureReasonType,
  ActivityReasonType,
  generateFormContentBasedOnType,
  ReasonTypeFormValues,
} from './admin-console-operations-model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
      paddingTop: 8,
      paddingBottom: 8,
      display: 'flex',
      '&:hover': {
        background: theme.palette.grey[100],
      },
      '&:hover $deleteIcon': {
        color: theme.palette.action.disabled,
        '&:hover': {
          color: theme.palette.error.main,
        },
      },
      '&:hover $fieldItemHandle': {
        color: theme.palette.action.active,
      },
    },
    deleteIcon: {
      color: 'transparent',
    },
    fieldItemHandle: {
      paddingLeft: 4,
      paddingRight: 8,
      color: 'transparent',
    },
  })
);

interface ModifiedReasonType extends ActivityCancellationReasonType, ActivityClosureReasonType {
  index: number;
}
const ReasonListItem = (props: {
  reason: ActivityCancellationReasonType | ActivityClosureReasonType;
  index: number;
  length: number;
  arrayHelpers: FieldArrayRenderProps;
  type: ActivityReasonType;
}) => {
  const { reason, index, length, arrayHelpers, type } = props;
  const [reasonToRemove, setReasonToRemove] = useState<ModifiedReasonType>();
  const classes = useStyles();
  const formik = useFormikContext<ReasonTypeFormValues>();
  const formContent = generateFormContentBasedOnType(type);

  const name = `reasons[${index}]`;
  const [label, setLabel] = useState(reason.label);

  return (
    <>
      <DraggableListItem
        id={`${index}`}
        index={index}
        className={classes.listItem}
        style={index === length - 1 ? { borderBottom: 'none' } : undefined}
      >
        <Box display='flex' flex='1 1 auto' position='relative'>
          <Box>
            <DraggableListItemHandle className={classes.fieldItemHandle} />
          </Box>

          <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
            <Box flex='1 0 auto'>
              <TextField
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
                onBlur={() => {
                  if (label === '') {
                    setLabel(reason.label);
                    return;
                  }
                  if (label !== reason.label) {
                    formik.setFieldValue(name, { ...reason, label: label });
                    formik.submitForm();
                  }
                }}
                multiline
                fullWidth
                style={{ width: '100%' }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
              {/* <Field
                slow
                name={`${name}.label`}
                label=''
                variant='standard'
                multiline
                InputProps={{
                  disableUnderline: true,
                }}
                onBlur={() => (fieldIsUpdated ? formik.submitForm() : null)}
                style={{ width: '100%' }}
                fullWidth
              /> */}
            </Box>

            <Box>
              <ButtonBase
                style={{ flex: 1, padding: 8 }}
                onClick={() => {
                  setReasonToRemove({ ...reason, index });
                }}
              >
                <Close className={classes.deleteIcon} />
              </ButtonBase>
            </Box>
          </Box>
        </Box>
      </DraggableListItem>
      {reasonToRemove ? (
        <ConfirmationDialog
          title={formContent.removeReasonDialogTitle}
          body={
            <>
              {formContent.removeReasonDialogContent.map((content) => {
                const updatedContent = content.includes('$reasonName')
                  ? content.replace('$reasonName', reasonToRemove.label)
                  : content;
                return (
                  <Typography key={content} variant='body2' gutterBottom>
                    {updatedContent}
                  </Typography>
                );
              })}
            </>
          }
          open
          closeActionText={'Cancel'}
          confirmActionText={'Remove'}
          handleCloseClick={() => setReasonToRemove(undefined)}
          handleConfirmClick={() => {
            arrayHelpers.remove(reasonToRemove.index);
            setReasonToRemove(undefined);
            formik.submitForm();
          }}
        />
      ) : null}
    </>
  );
};

export const ReasonListFieldSection = ({
  arrayHelpers,
  type,
}: {
  arrayHelpers: FieldArrayRenderProps;
  type: ActivityReasonType;
}) => {
  const formik = useFormikContext<ReasonTypeFormValues>();
  const { reasons } = formik.values;
  return (
    <DraggableList
      onReorder={(startIdx, endIdx, reorder) => {
        if (formik) {
          formik.setFieldValue('reasons', reorder(reasons, startIdx, endIdx));
          formik.submitForm();
        }
      }}
    >
      {reasons.map((reason, index) => (
        <ReasonListItem
          key={reason.listId}
          reason={reason}
          index={index}
          length={reasons.length}
          arrayHelpers={arrayHelpers}
          type={type}
        />
      ))}
    </DraggableList>
  );
};
