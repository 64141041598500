import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useTypographyStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    fontSize: '24px',
    fontWeight: 900,
    marginTop: '2rem'
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 400,
    marginTop: '1.5rem',
    color: theme.color.grey.neutral400
  }
}));