import React from 'react';
import { Filters, initialFilters } from '../../../../common/filter-fields/filters';

export const ActivityManagementContext = React.createContext<{
  filters: {
    selectedFilters: Filters;
    setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  };
}>({
  filters: {
    selectedFilters: initialFilters,
    setSelectedFilters: () => {},
  },
});

type ProviderProps = {
  children: React.ReactElement;
};

export function ActivityManagementContextProvider({ children }: ProviderProps) {
  const [selectedFilters, setSelectedFilters] = React.useState<Filters>(initialFilters);

  return (
    <ActivityManagementContext.Provider value={{ filters: { selectedFilters, setSelectedFilters } }}>
      {children}
    </ActivityManagementContext.Provider>
  );
}
