import { HoverText } from '@campfire/hover-link';
import { Breadcrumbs, Typography, Box } from '@material-ui/core';
import React, { memo } from 'react';
import {
  IncidentCategoryGroupType,
  IncidentCategoryType,
  AllIncidentCategoryType,
} from '../../admin-console-incident-report-model';

const BreadcrumbsItem = memo(
  ({
    item,
    index,
    breadcrumbsLength,
    onClick,
    setChildToEdit,
  }: {
    item: IncidentCategoryGroupType | IncidentCategoryType;
    index: number;
    breadcrumbsLength: number;
    onClick: () => void;
    setChildToEdit: (x: AllIncidentCategoryType | undefined) => void;
  }) => {
    const isItemRoot = index === 0;
    const isLastItem = index === breadcrumbsLength - 1;

    if (breadcrumbsLength === 1) return null;

    if (isItemRoot)
      return (
        <HoverText
          //
          onClick={() => onClick()}
        >
          {'...'}
        </HoverText>
      );

    if (isLastItem)
      return (
        <Box display='flex' alignContent='center' alignItems='center'>
          <Typography
            //
            style={{ fontWeight: 600, color: 'textPrimary' }}
          >
            {item.label}
          </Typography>
          <HoverText
            onClick={() => {
              setChildToEdit(item);
            }}
            style={{ paddingLeft: 8 }}
            variant='body2'
            color='primary'
            hoverColor='primary'
          >
            {'edit'}
          </HoverText>
        </Box>
      );

    return <HoverText onClick={() => onClick()}>{item.label}</HoverText>;
  }
);
export const IncidentCategoriesBreadCrumbs = ({
  breadcrumbsArray,
  setSelectedChildId,
  setChildToEdit,
}: {
  breadcrumbsArray: Array<IncidentCategoryGroupType | IncidentCategoryType>;
  setSelectedChildId: (x: string | undefined) => void;
  setChildToEdit: (x: AllIncidentCategoryType | undefined) => void;
}) => (
  <Breadcrumbs maxItems={4}>
    {breadcrumbsArray.map((item: IncidentCategoryGroupType | IncidentCategoryType, index) => (
      <BreadcrumbsItem
        key={item.label}
        item={item}
        index={index}
        breadcrumbsLength={breadcrumbsArray.length}
        setChildToEdit={setChildToEdit}
        onClick={() =>
          index === 0
            ? setSelectedChildId(undefined)
            : item.__typename === 'VOLUNTEER_IncidentCategoryGroupType'
            ? setSelectedChildId(item.incidentCategoryGroupId)
            : setSelectedChildId(item.incidentCategoryId)
        }
      />
    ))}
  </Breadcrumbs>
);
