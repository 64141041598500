import { SettingsOutlined } from '@material-ui/icons';
import { GetRoutesConfig, RoutesMaps } from '../common/routes-map-model';

const route = 'admin';

export const getAdminRoutesMap = ({ userIdentityService }: GetRoutesConfig): RoutesMaps => ({
  route,
  heading: 'Admin',
  Icon: SettingsOutlined,
  isAuthorized: userIdentityService.isVmAdmin,
  items: [
    {
      label: 'Task Management',
      route: 'tasks',
      isAuthorized: true,
    },
    {
      label: 'Program Management',
      route: 'program-management',
      isAuthorized: true,
    },
    {
      label: 'Admin Console',
      route: 'admin-console',
      isAuthorized: true,
    },
  ],
});
