import { gql } from '@apollo/client';

export const GET_ALLOW_CICO = gql `
  query GetAllowCICO {
    vm {
      configItem(key: "allow-cico") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;
