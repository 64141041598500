import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { unpackToDateTimeTZ } from '@campfire/hot-date';
import { CommonRequiringActionWidgetProps } from '../../shared';
import { useCampfireQuery } from '../../../../../../../../global/network/useCampfireQuery';
import { GET_INCIDENT_REPORTS } from '../../../../../../../program-manager/incident-management-v2/incident-management.gql';
import {
  IncidentManagementGetIncidents,
  IncidentManagementGetIncidentsVariables,
} from '../../../../../../../program-manager/incident-management-v2/__generated__/IncidentManagementGetIncidents';
import { parseIncidentReport } from '../../../../../../../program-manager/incident-management-v2/IncidentManagementScreen';
import { WidgetTypes } from '../../../dashboard-widget-setting/dashboard-widget-model';

interface Props extends CommonRequiringActionWidgetProps {
  status: string;
}

function generateIncidentReportsColumnByStatus(status: string) {
  const baseColumns: GridColumns = [
    {
      field: 'incidentTitle',
      headerName: 'Title',
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }: any) => {
        return (
          <Tooltip title={row.incidentTitle}>
            <Typography noWrap style={{ fontSize: 'inherit' }}>
              {row.incidentTitle}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'incidentSeverity',
      headerName: 'Severity',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 50,
    },
    {
      field: 'incidentLocationType',
      headerName: 'Location Type',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'incidentDateTime',
      headerName: 'Incident Date',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: ({ row }: any) => unpackToDateTimeTZ(row.incidentDateTime).toFormat('dd/MM/y'),
    },
    {
      field: 'submissionDateTime',
      headerName: 'Date Submitted',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 180,
      renderCell: ({ row }: any) => unpackToDateTimeTZ(row.submissionDateTime).toFormat('dd/MM/y'),
    },
  ];

  if (status === 'in-review') {
    return [
      ...baseColumns,
      {
        field: 'dateWorking',
        headerName: 'Date In Review',
        flex: 1,
        disableColumnMenu: true,
        minWidth: 180,
        renderCell: ({ row }: any) => unpackToDateTimeTZ(row.dateWorking).toFormat('dd/MM/y'),
      },
    ];
  }

  if (status === 'completed') {
    return [
      ...baseColumns,
      {
        field: 'dateClosed',
        headerName: 'Date Closed',
        flex: 1,
        disableColumnMenu: true,
        minWidth: 150,
        renderCell: ({ row }: any) => unpackToDateTimeTZ(row.dateClosed).toFormat('dd/MM/y'),
      },
    ];
  }
  return baseColumns;
}

export const IncidentsTableWidget = (props: Props) => {
  const { filters, status, range } = props;

  const { programIds, activityIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };

  const { data, loading } = useCampfireQuery<IncidentManagementGetIncidents, IncidentManagementGetIncidentsVariables>(
    GET_INCIDENT_REPORTS,
    {
      options: {
        variables: {
          programIds: programIds.length > 0 && !programIds.includes('all') ? programIds : null,
          activityIds: activityIds.length > 0 && !activityIds.includes('all') ? activityIds : null,
          status: status === 'in-review' ? 'working' : status === 'completed' ? 'closed' : status,
          range: range,
          widgetType: WidgetTypes.TABLE,
        },
      },
    }
  );

  const incidents =
    data?.vm.incidentReports.map(parseIncidentReport).map((incidentReport) => {
      return {
        ...incidentReport,
        id: incidentReport.incidentId,
        incidentLocationType:
          incidentReport.incidentLocationType === 'VOLUNTEER_ActivityIncidentLocationType'
            ? 'In Activity'
            : 'Outside Activity',
      };
    }) || [];

  const columns: GridColumns = generateIncidentReportsColumnByStatus(status);

  return (
    <>
      <Box width='100%' height='100%' overflow='scroll' maxHeight='max-content'>
        <DataGrid columns={columns} rows={incidents} loading={loading} autoPageSize disableSelectionOnClick />
      </Box>
    </>
  );
};
