import { gql } from '@apollo/client';
import { REPORT_TYPE_FIELD_FRAGMENT } from '../report-types/report-types-model.gql';
import { REPORT_TYPE_ACTIVITY_FRAGMENT } from '../view-by-activities/report-types-activities.model.gql';

export const REPORT_MANAGEMENT_GET_REPORT_TYPE_FIELDS = gql`
  query ReportManagementGetReportTypeFields {
    vm {
      allReportTypes {
        ...ReportTypeFieldsReportType
      }
      allFields {
        ...ReportManagementReportTypeField
      }
    }
  }

  fragment ReportTypeFieldsReportType on VOLUNTEER_ReportTypeType {
    reportTypeId
    name
    dateDeactivated
    items {
      ...ReportTypeFieldsReportTypeItem
    }
    activities {
      ...ReportTypeActivity
    }
  }
  fragment ReportTypeFieldsReportTypeItem on VOLUNTEER_ReportTypeItemType {
    ...ReportTypeFieldsReportTypeItemField
  }

  fragment ReportTypeFieldsReportTypeItemField on VOLUNTEER_ReportTypeFieldType {
    field {
      fieldId
    }
  }
  ${REPORT_TYPE_FIELD_FRAGMENT}
  ${REPORT_TYPE_ACTIVITY_FRAGMENT}
`;
