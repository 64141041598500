import { useSnackbar } from '../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../global/network/useEndpointFetch';

export type SendInvitationDataType = {
  emails: string[];
};

const useSendInvitationFetch = () => {
  const { setSnackbar } = useSnackbar();

  const createInvitationFetch = useEndpointFetch<SendInvitationDataType>('/vm/invite/volunteer/send');

  function resetSnackbar() {
    setSnackbar(undefined);
  }

  function sendInvitationPostRequest(postInvitationData: SendInvitationDataType) {
    resetSnackbar();

    createInvitationFetch
      .run(postInvitationData)
      .then((response) => {
        if (response.ok) {
          setSnackbar({
            variant: 'success',
            message: 'Invitations have been sent',
            open: true,
          });
          return;
        }

        if (response.status === 400) {
          setSnackbar({
            variant: 'error',
            message: 'Invalid email(s) given',
            open: true,
          });
          return;
        }

        setSnackbar({
          variant: 'error',
          message: 'Unable to invite volunteers',
          open: true,
        });
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          message: 'Unable to invite volunteers',
          open: true,
        });
      });
  }

  return {
    sendInvitationPostRequest,
    isLoading: createInvitationFetch.isLoading,
    response: createInvitationFetch.response,
  };
};

export { useSendInvitationFetch };
