import { Select } from '@campfire/select';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { object as YupObject, string as YupString } from 'yup';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { arrayHead } from '../../../../common/functions/array-head';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../common/cards/alert-card/AlertCard';
import { AdminConsoleGetSingleVolunteerProfileTask_vm_task as Task } from '../../admin-console/admin-console-content-pages/admin-console-volunteer-profile/__generated__/AdminConsoleGetSingleVolunteerProfileTask';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  cakeOptions: Array<{ cakeId: string; title: string }>;
  task: Task;
  onMove: (newTask: any, handleSuccess: () => void) => void;
  refetch?: () => void;
};

const validationSchema = YupObject().shape({
  targetCakeId: YupString().required('Please select a field'),
});

export const MoveToDialog = (props: Props) => {
  const { isOpen, cakeOptions, task, onMove, handleClose } = props;
  const { lightTextColor } = getAlertCardColors('info');

  const { theme } = useCampfireTheme();

  const initialValues = useMemo(() => {
    const targetCake = arrayHead(cakeOptions);
    return {
      sourceCakeId: task.cake.cakeId,
      targetCakeId: targetCake?.cakeId,
    };
  }, [cakeOptions, task]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='merge-report-field-dialog-title'
      aria-describedby='merge-report-field-dialog-description'
      PaperProps={{
        style: { padding: '10px' },
      }}
    >
      <DialogTitle id='merge-report-field-dialog-title'>Move {task.title} ?</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any) => {
          onMove(
            {
              ...task,
              programIds: task.programs.map((p) => p.programId),
              roleIds: task.roles.map((r) => r.activityRoleId),
              activityIds: task.activities.map((a) => a.activityId),
              cakeId: values.targetCakeId,
            },
            handleClose
          );
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <DialogContent style={{ paddingBottom: '20px' }}>
              <>
                <Box mb='24px'>
                  <AlertCard title='Moving Categories' variant='info'>
                    <AlertCardBody>
                      <Typography
                        variant='subtitle2'
                        style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
                      >
                        {`Moving a task to another category will mean that this task is still available for volunteers to complete, but may appear in a different area or not be required for onboarding into Volaby.`}
                      </Typography>
                    </AlertCardBody>
                  </AlertCard>
                </Box>
                <Grid container direction='column' spacing={2} style={{ paddingTop: '1em' }}>
                  <Grid item container spacing={2} style={{ justifyContent: 'center' }}>
                    <Grid item container spacing={2} direction='column' xs={12} sm={5}>
                      <Grid item>
                        <Select
                          fullWidth
                          variant='outlined'
                          name='sourceCakeId'
                          label='Current Category'
                          items={[task.cake]}
                          renderFunction={(item) => item.title}
                          valueFunction={(item) => item.cakeId}
                          InputProps={{
                            disabled: true,
                          }}
                          SelectProps={{ IconComponent: () => null }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      spacing={2}
                      direction='column'
                      xs={12}
                      sm={2}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: theme.color.grey.neutralBrand300,
                      }}
                    >
                      <ArrowForward />
                      <Typography style={{ fontSize: '14px', fontWeight: 550, fontVariant: 'small-caps' }}>
                        move to
                      </Typography>
                    </Grid>
                    <Grid item container spacing={2} direction='column' xs={12} sm={5}>
                      <Grid item>
                        <Select
                          fullWidth
                          required
                          variant='outlined'
                          name='targetCakeId'
                          label='Target Category'
                          items={cakeOptions}
                          renderFunction={(item) => item.title}
                          valueFunction={(item) => item.cakeId}
                          onChange={(e) => {
                            setFieldValue('targetCakeId', e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </DialogContent>
            <DialogActions>
              <Box display='flex' justifyContent='right' padding={'10px'}>
                <TabletButton
                  style={{
                    alignSelf: 'right',
                    backgroundColor: 'transparent',
                    color: theme.color.grey.neutral400,
                    padding: '7px 18px',
                    minWidth: '40px',
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </TabletButton>
                <TabletButton
                  type='submit'
                  // disabled={runMergeReportFieldsLoading}
                  color='primary'
                  style={{
                    alignSelf: 'right',
                    backgroundColor: theme.color.secondary.main900,
                    color: '#ffffff',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    textTransform: 'none',
                    padding: '7px 12px',
                    minWidth: '40px',
                  }}
                >
                  Move
                </TabletButton>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
