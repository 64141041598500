import { Dialog, DialogTitle, Grid, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { memo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ReportFieldCreator } from './ReportFieldCreator';
import { EditFieldValues } from './edit-report-field-types';
import { ExistingReportFieldSelector } from './ExistingReportFieldSelector';
import { assertNever } from '../../../../../../common/functions/assert-never';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { ReportTypeItemFieldValue } from '../report-type-form-types';

const useTabClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      textTransform: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      whiteSpace: 'nowrap',
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  })
);

interface Props {
  isOpen: boolean;
  close: () => void;
  delete: () => void;
  onSubmit: (values: ReportTypeItemFieldValue) => void;
  fieldData?: ReportTypeItemFieldValue;
}

const parseField = (fieldValues: EditFieldValues): ReportTypeItemFieldValue => {
  if (fieldValues.__typename === 'VOLUNTEER_BooleanFieldType') {
    return {
      __typename: 'VOLUNTEER_ReportTypeFieldType',
      order: fieldValues.order,
      reportTypeItemId: fieldValues.reportTypeItemId,
      field: {
        __typename: 'VOLUNTEER_BooleanFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        isHero: fieldValues.isHero ?? false,
      },
      optional: fieldValues.optional,
      listId: uuidv4(),
    };
  }

  if (fieldValues.__typename === 'VOLUNTEER_CheckboxFieldType') {
    return {
      __typename: 'VOLUNTEER_ReportTypeFieldType',
      order: fieldValues.order,
      reportTypeItemId: fieldValues.reportTypeItemId,
      field: {
        __typename: 'VOLUNTEER_CheckboxFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        isHero: fieldValues.isHero ?? false,
      },
      optional: fieldValues.optional,
      listId: uuidv4(),
    };
  }

  if (fieldValues.__typename === 'VOLUNTEER_DropdownFieldType') {
    return {
      __typename: 'VOLUNTEER_ReportTypeFieldType',
      order: fieldValues.order,
      reportTypeItemId: fieldValues.reportTypeItemId,
      field: {
        __typename: 'VOLUNTEER_DropdownFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        dropdownOptions: fieldValues.dropdownOptions ?? [],
        isAllowMultiple: fieldValues.isAllowMultiple ?? false,
        isHero: fieldValues.isHero ?? false,
      },
      optional: fieldValues.optional,
      listId: uuidv4(),
    };
  }

  if (fieldValues.__typename === 'VOLUNTEER_NumericFieldType') {
    return {
      __typename: 'VOLUNTEER_ReportTypeFieldType',
      order: fieldValues.order,
      reportTypeItemId: fieldValues.reportTypeItemId,
      field: {
        __typename: 'VOLUNTEER_NumericFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        isHero: fieldValues.isHero ?? false,
      },
      optional: fieldValues.optional,
      listId: uuidv4(),
    };
  }

  if (fieldValues.__typename === 'VOLUNTEER_RatingFieldType') {
    return {
      __typename: 'VOLUNTEER_ReportTypeFieldType',
      order: fieldValues.order,
      reportTypeItemId: fieldValues.reportTypeItemId,
      field: {
        __typename: 'VOLUNTEER_RatingFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        isHero: fieldValues.isHero ?? false,
      },
      optional: fieldValues.optional,
      listId: uuidv4(),
    };
  }

  if (fieldValues.__typename === 'VOLUNTEER_TextFieldType') {
    return {
      __typename: 'VOLUNTEER_ReportTypeFieldType',
      order: fieldValues.order,
      reportTypeItemId: fieldValues.reportTypeItemId,
      field: {
        __typename: 'VOLUNTEER_TextFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        isHero: fieldValues.isHero ?? false,
        isLongText: fieldValues.isLongText ?? false,
      },
      optional: fieldValues.optional,
      listId: uuidv4(),
    };
  }

  if (fieldValues.__typename === 'VOLUNTEER_TimeFieldType') {
    return {
      __typename: 'VOLUNTEER_ReportTypeFieldType',
      order: fieldValues.order,
      reportTypeItemId: fieldValues.reportTypeItemId,
      field: {
        __typename: 'VOLUNTEER_TimeFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        isHero: fieldValues.isHero ?? false,
      },
      optional: fieldValues.optional,
      listId: uuidv4(),
    };
  }

  if (fieldValues.__typename === 'VOLUNTEER_AttachmentFieldType') {
    return {
      __typename: 'VOLUNTEER_ReportTypeFieldType',
      order: fieldValues.order,
      reportTypeItemId: fieldValues.reportTypeItemId,
      field: {
        __typename: 'VOLUNTEER_AttachmentFieldType',
        description: fieldValues.description,
        name: fieldValues.name,
        fieldId: fieldValues.fieldId,
        isHero: fieldValues.isHero ?? false,
      },
      optional: fieldValues.optional,
      listId: uuidv4(),
    };
  }

  return assertNever(fieldValues.__typename);
};

export const EditReportTypeItemFieldDialog = memo((props: Props) => {
  const { isOpen, close, fieldData, delete: deleteField, onSubmit } = props;
  const [selectedTab, setSelectedTab] = useState(0);

  const { theme } = useCampfireTheme();

  const handleClose = () => {
    setSelectedTab(0);
    close();
  };

  const classes = useTabClasses();

  return (
    <Dialog open={isOpen} maxWidth='sm' fullWidth>
      <DialogTitle style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Grid item container xs={12} direction='column' style={{ borderBottom: `1px solid ${theme.color.grey.pale}` }}>
          <Typography display='inline' variant='h6' style={{ paddingLeft: 16, paddingRight: 16 }}>
            {fieldData ? 'Edit Field' : 'Add Field'}
          </Typography>
          <Grid item container justify='center'>
            <Tabs
              indicatorColor='primary'
              value={selectedTab}
              aria-label='field editor tabs'
              variant='fullWidth'
              centered
            >
              <Tab
                classes={classes}
                disableRipple
                disableTouchRipple
                key='existing'
                label='Choose from existing'
                onClick={() => setSelectedTab(0)}
              />
              <Tab
                classes={classes}
                disableRipple
                disableTouchRipple
                key='new'
                label={fieldData ? 'Edit selected' : 'Create new'}
                onClick={() => setSelectedTab(1)}
              />
            </Tabs>
          </Grid>
        </Grid>
      </DialogTitle>
      {selectedTab === 1 ? (
        <ReportFieldCreator
          fieldData={fieldData}
          handleClose={handleClose}
          onSubmit={(fieldValues) => onSubmit(parseField(fieldValues))}
          deleteField={deleteField}
        />
      ) : (
        <ExistingReportFieldSelector
          editingField={!!fieldData}
          handleClose={handleClose}
          onSubmit={(fieldValues) => onSubmit(parseField(fieldValues))}
        />
      )}
    </Dialog>
  );
});
