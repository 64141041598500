import { encodeDate } from '@campfire/hot-date';
import { Box, Grid, Typography } from '@material-ui/core';
import { SupervisedUserCircle } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { ReactElement, useMemo } from 'react';
import { FullPageMessage } from '../../../common/FullPageMessage';
import { useUser } from '../../../global/auth/useUser';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { MyElementsUpcomingRostersCard } from '../user-profile/my-elements/upcoming-rosters-card/MyElementsUpcomingRostersCard';
import { MyActivitiesNoEnrolmentsEmptyMessage } from './messages/MyActivitiesNoEnrolmentsEmptyMessage';
import { GET_MY_ACTIVITIES } from './my-activities-model.gql';
import { GetMyActivities, GetMyActivitiesVariables } from './__generated__/GetMyActivities';

export const UpcomingRosters = () => {
  const { theme } = useCampfireTheme();
  const { getVolunteerIdentity } = useUser();
  const volunteerIdentity = getVolunteerIdentity();

  const { data: getMyActivitiesResponse, loading, refetch: getMyActivitiesReload } = useCampfireQuery<
    GetMyActivities,
    GetMyActivitiesVariables
  >(GET_MY_ACTIVITIES, {
    options: {
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
        startDate: encodeDate(DateTime.local()),
        endDate: encodeDate(DateTime.local().plus({ years: 10 })),
      },
    },
  });

  const activityEnrolments = useMemo(() => {
    return getMyActivitiesResponse?.vm.volunteer?.activityEnrolments ?? [];
  }, [getMyActivitiesResponse]);

  const activityApplications = useMemo(() => {
    return getMyActivitiesResponse?.vm.volunteer?.activityApplications ?? [];
  }, [getMyActivitiesResponse]);

  const hasActivityEnrolments = useMemo(() => {
    return !!activityEnrolments?.length ?? false;
  }, [activityEnrolments]);

  const hasActivityApplications = useMemo(() => {
    return !!activityApplications?.length ?? false;
  }, [activityApplications]);

  const hasUpcomingRosters = useMemo(() => {
    return (getMyActivitiesResponse?.vm.volunteer?.upcomingRosters ?? []).length > 0;
  }, [getMyActivitiesResponse]);

  return (
    <Box
      display='flex'
      flexWrap='wrap'
      style={{
        overflowX: 'hidden',
        paddingTop: 8,
      }}
    >
      <Box width={1} borderBottom={`1px solid ${theme.palette.grey[300]}`}>
        <Typography variant='h5' display='block' style={{ paddingLeft: 16, paddingRight: 8 }}>
          {'Your Activities'}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          display='block'
          style={{
            paddingLeft: 16,
            paddingRight: 8,
            paddingBottom: 8,
          }}
        >
          {'Manage your upcoming activities and access your volunteering history'}
        </Typography>
      </Box>
      <Box display='flex' width={1} height={1} paddingBottom={16} paddingX={{ xs: 1, sm: 3 }}>
        {loading ? null : (
          <MyActivityRostersAndNominationsOverview
            hasActivityEnrolments={hasActivityEnrolments}
            hasActivityApplications={hasActivityApplications}
            hasUpcomingRosters={hasUpcomingRosters}
            getMyActivitiesReload={getMyActivitiesReload}
          />
        )}
      </Box>
    </Box>
  );
};

const MessagesLayout = ({ children }: { children: ReactElement }) => (
  <Box marginTop={3} maxWidth={586}>
    <Grid container>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  </Box>
);

const MyActivityRostersAndNominationsOverview = ({
  hasActivityEnrolments,
  hasActivityApplications,
  hasUpcomingRosters,
}: {
  hasActivityEnrolments: boolean;
  hasActivityApplications: boolean;
  hasUpcomingRosters: boolean;
  getMyActivitiesReload?: () => void;
}) => {
  if (!hasActivityEnrolments && !hasActivityApplications && !hasUpcomingRosters)
    return (
      <MessagesLayout>
        <FullPageMessage Icon={SupervisedUserCircle}>
          <MyActivitiesNoEnrolmentsEmptyMessage />
        </FullPageMessage>
      </MessagesLayout>
    );
  return <MyElementsUpcomingRostersCard />;
};
