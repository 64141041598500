import React, { CSSProperties } from 'react';
import { Typography, Box, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

interface StyleProps extends CSSProperties {
  isRound?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1.25rem',
      paddingBottom: '10px',
    },
    color: {
      width: (props: StyleProps) => props.width,
      height: (props: StyleProps) => props.height,
      borderRadius: (props: StyleProps) => (props.isRound ? '50%' : 'unset'),
      backgroundColor: (props: StyleProps) => props.color,
      marginRight: (props: StyleProps) => props.marginRight,
    },
    text: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: theme?.color?.grey?.neutral400,
    },
  })
);

interface Props extends StyleProps {
  text?: string;
}

export function ColorLegend({ text, ...styleProps }: Props) {
  const classes = useStyles(styleProps);

  return (
    <Box className={classes.container}>
      <Box className={classes.color} />
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  );
}
