import { Typography, Box, List, ListItem, ListItemText, ListItemIcon, Checkbox } from '@material-ui/core';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from '@material-ui/icons';
import React from 'react';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { useFilterStyles } from '../../../../../../../common/filter-fields/FilterClasses';
import { IncomingVolunteersFilters, sortFilters, filterHelperTexts } from '../filters';

type SortFilterProps = {
  selectedFilters: IncomingVolunteersFilters;
  setSelectedFilters: (x: IncomingVolunteersFilters) => void;
};

const SortFilterMobile = ({ selectedFilters, setSelectedFilters }: SortFilterProps) => {
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const isSortFilterSelected = (value: string) => selectedFilters.selectedSortFilter === value;
  const handleDurationChange = (value: string) =>
    setSelectedFilters({
      ...selectedFilters,
      selectedSortFilter: value,
    });
  return (
    <Box py={2}>
      <Typography color='textSecondary' variant='body1' gutterBottom>
        {filterHelperTexts.sortHelperText}
      </Typography>
      <List disablePadding>
        {sortFilters.map((sortFilter) => (
          <ListItem
            key={sortFilter}
            onClick={() => handleDurationChange(sortFilter)}
            disableGutters
            className={classes.listItem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                checked={isSortFilterSelected(sortFilter)}
                className={classes.checkBox}
                checkedIcon={<CheckBoxIcon color='primary' />}
                size='small'
                icon={<CheckBoxOutlineBlank />}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant='body1'
                color='textSecondary'
                style={isSortFilterSelected(sortFilter) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
              >
                {sortFilter}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export { SortFilterMobile };
