import React, { useMemo, memo } from 'react';
import { List } from '@material-ui/core';
import { CategoriesListItem } from './CategoriesListItem';
import { SupportCategory } from '../__generated__/SupportCategory';

interface CategoriesListProps {
  categories: SupportCategory[];
}

const CategoriesList = memo((props: CategoriesListProps) => {
  const { categories } = props;

  const sortedCategories = useMemo(() => categories.sort((a, b) => (a.title < b.title ? -1 : 1)), [categories]);

  return (
    <List>
      {sortedCategories.map((category) => (
        <CategoriesListItem key={category.supportCategoryId} category={category} />
      ))}
    </List>
  );
});

export { CategoriesList };
