import { VolunteerCommonProfile } from '../__generated__/VolunteerCommonProfile';
import { IUserIdentityService } from '../../../../global/auth/UserIdentityService';

export const hasRuleForVolunteer = (
  volunteer: VolunteerCommonProfile,
  userIdentityService: IUserIdentityService,
  rule: string
): boolean => {
  return (
    userIdentityService.hasVmGlobalRule(rule) ||
    !!volunteer.programs.find((program) => userIdentityService.hasRuleForProgram(program.programId, rule))
  );
};
