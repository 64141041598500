import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { unpackToDateTime } from '@campfire/hot-date';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, Divider, IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon, LabelImportantRounded } from '@material-ui/icons';
import { convertFromRaw, EditorState } from 'draft-js';
import React, { memo, useEffect } from 'react';
import { isUndefined } from 'lodash';
import { useUser } from '../../../../../../global/auth/useUser';
import { useApiUrl } from '../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../global/network/useCampfireFetch';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useVolDashboardMyElementsContext } from '../../VolDashboardMyElementsContext';
import { MyNewsBulletin } from './__generated__/MyNewsBulletin';
import { CommentsList } from './CommentsLists';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { GetAllowCommentConfigItem } from './__generated__/GetAllowCommentConfigItem';
import { ALLOW_COMMENT_CONFIG_ITEM_QUERY } from './my-news-model.gql';
import { BulletinComments, BulletinCommentsVariables } from './__generated__/BulletinComments';
import { BULLETIN_COMMENT } from './bulletin-comment.gql';
import { Reaction } from '../../../../../../common/reaction/Reaction';
import { useSaveReaction } from '../../../../../../common/reaction/reaction-model';

export const MyElementsSingleNewsView = memo(
  ({
    singleNews,
    onClose,
    refetchAllNews,
  }: {
    singleNews: MyNewsBulletin;
    onClose: () => void;
    refetchAllNews?: () => void;
  }) => {
    const { title, content, dateCreated, createdBy, dateUpdated, lastUpdatedBy, pinned } = singleNews;
    const { data: vmAllowComment } = useCampfireQuery<GetAllowCommentConfigItem, {}>(
      ALLOW_COMMENT_CONFIG_ITEM_QUERY,
      {}
    );
    const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
    const authorName = lastUpdatedBy
      ? `${lastUpdatedBy.profile.preferredName} ${lastUpdatedBy.profile.lastName}`
      : createdBy && `${createdBy.profile.preferredName} ${createdBy.profile.lastName}`;
    const displayDate = dateUpdated ?? dateCreated;

    const apiUrl = useApiUrl();
    const { theme } = useCampfireTheme();
    const { run } = useCampfireFetch({ defer: true });
    const { getVolunteerIdentity } = useUser();
    const volunteerIdentity = getVolunteerIdentity();
    const { getMyUnreadNewsNumberTabDataRefetch } = useVolDashboardMyElementsContext();
    const saveReaction = useSaveReaction();

    const markBulletinAsRead = (bulletinId: string, volunteerId: string): Promise<any> => {
      return run({
        url: `${apiUrl}/vm/bulletin/mark-as-viewed`,
        method: 'post',
        data: {
          bulletinId,
          volunteerId,
        },
      }).then(() => {
        if (refetchAllNews) {
          refetchAllNews();
        }
        if (getMyUnreadNewsNumberTabDataRefetch) {
          getMyUnreadNewsNumberTabDataRefetch();
        }
      });
    };
    const { data, refetch } = useCampfireQuery<BulletinComments, BulletinCommentsVariables>(BULLETIN_COMMENT, {
      options: { variables: { bulletinId: singleNews.bulletinId } },
    });

    useEffect(() => {
      if (volunteerIdentity && !singleNews.hasVolunteerViewedBulletin) {
        markBulletinAsRead(singleNews.bulletinId, volunteerIdentity.volunteerId);
      }
    }, [volunteerIdentity, singleNews]);

    React.useEffect(() => {
      const timer = setInterval(() => {
        refetch();
      }, 15000);

      return () => {
        clearInterval(timer);
      };
    }, []);

    const isEnableComment = isUndefined(vmAllowComment?.vm.configItem?.configItemValue?.flagValue)
      ? Boolean(vmAllowComment?.vm.configItem?.defaultValue)
      : Boolean(vmAllowComment?.vm.configItem?.configItemValue?.flagValue);

    const handleReaction = (symbol: string, reactionId?: string) => {
      saveReaction({
        bulletinId: singleNews.bulletinId,
        emoji: symbol,
        reactionId,
      }).then(() => {
        if (refetch) {
          refetch();
        }
      });
    };

    const hideAllComment = !(singleNews.isCommentable && isEnableComment) && !data?.vm.bulletin.comments.length;
    const reactions = data?.vm.bulletin.reactions || [];

    return (
      <Box display='flex' justifyContent='space-between' alignItems='flex-start' width={1} marginTop='24px'>
        <Box flex={1} maxWidth='100%'>
          {pinned && (
            <TitularTooltip description='This is pinned'>
              <LabelImportantRounded style={{ color: theme.alert.yellow.light }} />
            </TitularTooltip>
          )}
          <Box>
            <Typography color='textSecondary' variant='body2' style={{ fontSize: '12px', marginBottom: '12px' }}>
              {authorName} - {unpackToDateTime(displayDate).toFormat('dd.MM.yy')}
            </Typography>
            <Typography
              variant='h5'
              style={{
                fontSize: '16px',
                fontWeight: 900,
                lineHeight: '19px',
                letterSpacing: '-0.02em',
                color: theme.color.grey.neutralBrand800,
              }}
            >
              {title}
            </Typography>
            <Box py='10px'>
              <CampfireRichEditor editorState={editorState} readOnly />
            </Box>
            <Box>
              <Reaction reactions={reactions} handleReaction={handleReaction} />
            </Box>
            {!hideAllComment && (
              <Box mt='2rem'>
                <Box mb='1rem'>
                  <Typography style={{ fontWeight: 900, fontSize: 20, color: theme.color.grey.neutralBrand900 }}>
                    Comments
                  </Typography>
                  <Divider style={{ marginTop: '1rem' }} />
                </Box>
                <CommentsList
                  comments={data?.vm.bulletin.comments || []}
                  refetch={refetch}
                  bulletinId={singleNews.bulletinId}
                  hideCommentBox={!(singleNews.isCommentable && isEnableComment)}
                />
              </Box>
            )}
          </Box>
        </Box>
        <IconButton style={{ padding: 0 }} onClick={() => onClose()}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </Box>
    );
  }
);
