import { TabletButton } from '@campfire/tablet-button';
import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { ActivityTimelineFutureRosterVolunteer } from '../../__generated__/ActivityTimelineFutureRosterVolunteer';
import { getActivityRolesText } from './activity-roster-helpers';
import { VolunteerProfileGridItem } from './ActivityRosterVolunteerProfileGridItem';
import { AddNewVolunteerConfirmDialog } from './AddNewVolunteerConfirmDialog';

interface ActivityApplicantRosterRowProps {
  volunteer: ActivityTimelineFutureRosterVolunteer;
  activityApplicationId: string;
  activityDate: string;
  runSaveDraftRoster: () => void;
  runRefetch: () => void;
  activityRoles: { activityRoleId: string; name: string }[];
  addFullRostering: (args: { volunteerId: string }) => void;
}
const ActivityApplicantRosterRow = React.memo(
  ({
    volunteer,
    activityApplicationId,
    activityDate,
    runSaveDraftRoster,
    runRefetch,
    addFullRostering,
    activityRoles,
  }: ActivityApplicantRosterRowProps) => {
    const activityRolesDisplayText = getActivityRolesText({
      activityRoles,
      volunteerActivityRoles: volunteer.activityRoles,
    });

    const [showAddNewVolunteerDialog, setShowAddNewVolunteerDialog] = useState(false);

    return (
      <Box display='flex' flex='1 1 auto' alignItems='center' alignContent='center'>
        <VolunteerProfileGridItem
          key={volunteer.volunteerId}
          volunteer={volunteer}
          activityDate={activityDate}
          runRefetch={runRefetch}
          activityRolesDisplayText={activityRolesDisplayText}
        />

        {activityApplicationId ? (
          <Box marginLeft={2}>
            <TabletButton
              size='small'
              variant='text'
              color='primary'
              onClick={() => setShowAddNewVolunteerDialog(true)}
            >
              Add volunteer to roster
            </TabletButton>
          </Box>
        ) : null}

        {activityApplicationId && addFullRostering ? (
          <AddNewVolunteerConfirmDialog
            open={showAddNewVolunteerDialog}
            volunteer={volunteer}
            preferredName={volunteer.profile.preferredName}
            activityApplicationId={activityApplicationId}
            runSaveDraftRoster={runSaveDraftRoster}
            addFullRostering={addFullRostering}
            onClose={() => setShowAddNewVolunteerDialog(false)}
          />
        ) : null}
      </Box>
    );
  }
);

export { ActivityApplicantRosterRow };
