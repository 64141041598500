import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import {
  AddCircle,
  CheckRounded,
  FilterList,
  SettingsOutlined,
  UpdateOutlined,
  RestoreOutlined,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { encodeDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { useStyles } from '../styles';
import { RosterListItem } from '../main-section/roster/roster-types';
import { SearchField } from '../../../../common/inputs/SearchField';
import { RosterMainSection } from '../main-section/RosterMainSection';
import { ActionsHeader } from '../../../../common/ActionsHeader';
import { RostersList } from '../sidebar/RostersList';
import { GetRostersSidebarData_vm_activities_VOLUNTEER_NonRecurringActivityType_rosterTemplates as RosterTemplate } from '../__generated__/GetRostersSidebarData';
import { RosterFilters } from '../main-section/filters/RosterFilters';
import { getCreateRosterTemplateLink } from '../main-section/roster/roster-functions';
import { SessionContext } from '../../../../global/auth/SessionContext';
import { NextPrevButton } from '../sidebar/RostersSideBar';

interface Props {
  selectedRoster?: RosterListItem;
  setSearchFilter: (x: string) => void;
  showFilters: boolean;
  setShowFilters: (x: boolean) => void;
  handleEditRosterTemplate: () => void;
  setShowCancelActivityDialog: (x: boolean) => void;
  rosters: RosterListItem[];
  rosterTemplate?: RosterTemplate;
  handleReactivateActivityRefetch?: () => void;
  onHistoryClick: () => void;
  onSetQuery: (arg: any) => void;
  query: any;
  isRosterCompleted: boolean;
  loadNext: () => void;
  loadPrev: () => void;
  loading: boolean;
}

export const TabletRoster = (props: Props) => {
  const {
    selectedRoster,
    setSearchFilter,
    showFilters,
    setShowFilters,
    handleEditRosterTemplate,
    setShowCancelActivityDialog,
    rosters,
    rosterTemplate,
    handleReactivateActivityRefetch,
    onHistoryClick,
    query,
    onSetQuery,
    isRosterCompleted,
    loadPrev,
    loadNext,
    loading,
  } = props;
  const classes = useStyles();
  const session = React.useContext(SessionContext);
  const isLargeOrg = session?.user?.orgInfo.isLargeOrg;
  const isInitialLoad = !query.startDate || query.startDate === encodeDate(DateTime.local());

  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'} height={'100%'} overflow={'hidden'}>
      <Box margin={'12px 30px 0px 30px'}>
        <ActionsHeader title={'Rosters'}>
          <Box display={'flex'} alignItems='center'>
            {!selectedRoster ? (
              <>
                <Box style={{ marginRight: '10px' }}>
                  <SearchField
                    data-track='rosterCnlFilter-SearchInput'
                    placeholder='Search Rosters'
                    growLeft
                    value={query.q}
                    onChange={(e) => setSearchFilter(e.target.value)}
                  />
                </Box>
                <Link to={getCreateRosterTemplateLink(query.activityId)} style={{ textDecoration: 'none' }}>
                  <Button classes={{ root: classNames(classes.button, classes.successButton) }}>
                    <AddCircle style={{ fontSize: '18px' }} />
                    <Typography className={classes.typography}>Add Roster</Typography>
                  </Button>
                </Link>
                <ToggleButton
                  classes={{
                    root: classNames(classes.button, classes.headerButton),
                    selected: classes.toggledButton,
                  }}
                  onClick={() => setShowFilters(!showFilters)}
                  selected={showFilters}
                >
                  <FilterList style={{ fontSize: '18px' }} />
                  <Typography className={classes.typography}>Filters</Typography>
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: classNames(classes.button, classes.headerButton),
                    selected: classes.toggledButton,
                  }}
                  onClick={onHistoryClick}
                  selected={query.onHistory}
                >
                  {query.onHistory ? (
                    <RestoreOutlined style={{ fontSize: '18px' }} />
                  ) : (
                    <UpdateOutlined style={{ fontSize: '18px' }} />
                  )}
                  <Typography className={classes.typography}>History</Typography>
                </ToggleButton>
              </>
            ) : (
              <>
                {rosterTemplate ? (
                  <Button
                    classes={{ root: classNames(classes.button, classes.headerButton) }}
                    onClick={handleEditRosterTemplate}
                  >
                    <SettingsOutlined style={{ fontSize: '18px' }} />
                    <Typography className={classes.typography}>Settings</Typography>
                  </Button>
                ) : null}
                <Button
                  classes={{ root: classNames(classes.button, classes.successButton) }}
                  onClick={() =>
                    onSetQuery({
                      activityId: undefined,
                      activityDate: undefined,
                    })
                  }
                >
                  <CheckRounded style={{ fontSize: '18px' }} />
                  <Typography className={classes.typography}>Done</Typography>
                </Button>
              </>
            )}
          </Box>
        </ActionsHeader>
      </Box>
      <Box
        position={'relative'}
        display={'flex'}
        flexDirection={'column'}
        overflow={'hidden'}
        height={'100%'}
        padding={'0 30px'}
      >
        {selectedRoster ? (
          <Box
            height={'100%'}
            width={'100%'}
            overflow='scroll'
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <RosterMainSection
              loading={loading}
              showFilters={false}
              rosterTemplate={rosterTemplate}
              handleEditRosterTemplate={handleEditRosterTemplate}
              hasRosters={rosters && rosters.length >= 1}
              handleReactivateActivityRefetch={handleReactivateActivityRefetch}
              onSetQuery={onSetQuery}
              query={query}
              isRosterCompleted={isRosterCompleted}
              setShowCancelActivityDialog={setShowCancelActivityDialog}
              activityStatus={selectedRoster?.activityStatus}
            />
          </Box>
        ) : (
          <Box px={3} display={'flex'} flexDirection={'column'} height={'100%'} overflow={'hidden'}>
            <Box>
              <RosterFilters showFilters={showFilters} onSetQuery={onSetQuery} query={query} />
            </Box>
            <Box width={'100%'} height={'100%'} style={{ overflow: 'scroll' }}>
              {!query.onHistory && !isInitialLoad && (
                <NextPrevButton onAction={loadPrev} variant='prev' isLargeOrg={isLargeOrg} />
              )}
              <RostersList rosters={rosters} />
              {!query.onHistory && <NextPrevButton onAction={loadNext} variant='next' isLargeOrg={isLargeOrg} />}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
