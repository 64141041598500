import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { Box, Typography } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { encodeDate, unpackToDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { object as YupObject, string as YupString, date as YupDate, ValidationError } from 'yup';

import { getTaskFieldValues } from '../../../../../../../global/applicant-shell/model/suyp-task-submission-handler';
import {
  initTaskFormFormikValues,
  TaskFormValuesType,
} from '../../../../../../../common/form/task-form/model/task-form-init';
import { TaskFormFieldList } from '../../../../../../../common/form/task-form/TaskFormFieldList';
import { dynamicValidationSchema } from '../../../../../../../common/form/task-form/model/validation-schema';
import { deserializeTaskItems } from '../../../../../../admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { GetTaskForIncomingVolunteer_vm_task as TaskType } from './__generated__/GetTaskForIncomingVolunteer';
import { TaskFormFieldValue } from '../../../../../../../common/form/task-form/model/__generated__/TaskFormFieldValue';
import { useEndpointFetch } from '../../../../../../../global/network/useEndpointFetch';
import {
  DateTaskField,
  DropdownTaskField,
  PhoneTaskField,
  ShortTextTaskField,
} from '../../../../../../../common/inputs/task-fields/TaskFields';
import { useDeepEffect } from '../../../../../../../hooks/useDeepEffect';

interface StatusIconProps {
  color: string;
  completed?: boolean;
}

export const StatusIcon = (props: StatusIconProps) =>
  props.completed ? (
    <CheckCircle
      style={{
        fontSize: '22px',
        color: props.color,
      }}
    />
  ) : (
    <Cancel
      style={{
        fontSize: '22px',
        color: props.color,
      }}
    />
  );

interface UpdatingCompletedTaskProps {
  task: TaskType;
  taskItemValues: { [key: string]: TaskFormFieldValue };
  onCancel: () => void;
  completedTaskId?: string;
  onSaveSuccess?: () => void;
  userId: string;
  profile?: ProfileType | null;
  programs?: ProgramType[];
}

type ProfileType = {
  profileId?: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  dateOfBirth: string;
  contactNumber: string | null;
  aboutMe: string | null;
  email: string | null;
  programs: {
    programId: string;
    name: string;
  }[];
};

type ProfileInputType = {
  profileId?: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  dateOfBirth: string;
  contactNumber: string | null;
  aboutMe: string | null;
  email: string | null;
};

type ProgramType = {
  programId: string;
  name: string;
  dateSuspended: string;
};

type VolunteerProfileSubmission = {
  profileId?: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  dateOfBirth: string;
  phoneNumber: string;
  aboutMe: string;
  programIds?: string[];
};

const profileValidationSchema = YupObject().shape({
  firstName: YupString().required(),
  lastName: YupString().required(),
  dateOfBirth: YupDate().required(),
  programId: YupString().required(),
});

export const UpdatingCompletedTask = ({
  task,
  taskItemValues,
  onCancel,
  completedTaskId,
  onSaveSuccess,
  userId,
  profile,
  programs,
}: UpdatingCompletedTaskProps) => {
  const saveCompletedTask = useEndpointFetch<{
    userId: string;
    completedTaskId?: string;
    taskId: string;
    taskFieldValues?: any[];
  }>('/vm/volunteer/profile/completed-task/update');
  const editProfile = useEndpointFetch<VolunteerProfileSubmission>('/vm/volunteer/profile/edit');
  const [programId, setProgramId] = React.useState(profile?.programs[0]?.programId || '');
  const [profileInput, setProfileInput] = React.useState<ProfileInputType>({
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || '',
    preferredName: profile?.preferredName || '',
    contactNumber: profile?.contactNumber || '',
    dateOfBirth: profile?.dateOfBirth || encodeDate(DateTime.local()),
    aboutMe: profile?.aboutMe || '',
    email: profile?.email || '',
    profileId: profile?.profileId || '',
  });
  const taskItems = deserializeTaskItems(task.taskItems.sort((a, b) => (a.order > b.order ? 1 : -1)));
  const [error, setError] = React.useState<ValidationError | null>(null);
  const validationSchema = dynamicValidationSchema(taskItems);
  const { theme } = useCampfireTheme();

  const initialFormikValues = useMemo(() => {
    if (task.taskItems.length === 0) return undefined;
    return initTaskFormFormikValues(task.taskItems, taskItemValues);
  }, [taskItemValues]);

  useDeepEffect(() => {
    setError(null);
  }, [profileInput, programId]);

  const isProfileTask = task.order === -1;

  return (
    <>
      <Typography variant='subtitle1' color='textSecondary'>
        {task.description}
      </Typography>
      {initialFormikValues && (
        <Box marginBottom={2}>
          {isProfileTask && (
            <Box maxWidth={720}>
              <ShortTextTaskField
                value={profileInput?.firstName || ''}
                onChange={(e) => setProfileInput({ ...profileInput, firstName: e.target.value } as ProfileType)}
                ariaId='firstName'
                ariaDescribedBy='First Name'
                title='First Name'
                required
                error={error?.path === 'firstName'}
                helperText={error?.path === 'firstName' ? error.message : ''}
              />

              <ShortTextTaskField
                value={profileInput?.lastName || ''}
                onChange={(e) => setProfileInput({ ...profileInput, lastName: e.target.value } as ProfileType)}
                ariaId='lastName'
                ariaDescribedBy='Last Name'
                title='Last Name'
                required
                error={error?.path === 'lastName'}
                helperText={error?.path === 'lastName' ? error.message : ''}
              />

              <ShortTextTaskField
                value={profileInput?.preferredName || ''}
                onChange={(e) => setProfileInput({ ...profileInput, preferredName: e.target.value } as ProfileType)}
                ariaId='preferredName'
                ariaDescribedBy='Preferred Name'
                title='Preferred Name'
              />

              <DateTaskField
                value={unpackToDate(profileInput?.dateOfBirth)}
                onChange={(date) =>
                  setProfileInput({ ...profileInput, dateOfBirth: date ? encodeDate(date) : '' } as ProfileType)
                }
                ariaId='dateOfBirth'
                ariaDescribedBy='Date of birth'
                title='Date of birth'
                required
                error={error?.path === 'dateOfBirth'}
                helperText={error?.path === 'dateOfBirth' ? error.message : ''}
              />

              <PhoneTaskField
                value={profileInput?.contactNumber || ''}
                onChange={(e) => setProfileInput({ ...profileInput, contactNumber: e.target.value } as ProfileType)}
                ariaId='contactNumber'
                ariaDescribedBy='Contact Number'
                title='Contact number'
              />
              <DropdownTaskField
                value={programId}
                onChange={(e) => setProgramId(e.target.value as string)}
                ariaId='program'
                ariaDescribedBy='select program'
                title='Program'
                required
                error={error?.path === 'programId'}
                helperText={error?.path === 'programId' ? error.message : ''}
                options={(programs || []).map((p) => ({ id: p.programId, value: p.programId, label: p.name }))}
              />
            </Box>
          )}

          <Formik
            initialValues={initialFormikValues}
            onSubmit={async (values) => {
              const taskFieldValues = await getTaskFieldValues(taskItems, values);
              const data = {
                taskId: task.taskId,
                userId: userId,
                taskFieldValues,
                completedTaskId,
              };
              if (isProfileTask) {
                const validatingProfile = { ...profileInput, programId };
                try {
                  await profileValidationSchema.validate(validatingProfile);
                  await Promise.all([
                    editProfile.run({
                      ...profileInput,
                      phoneNumber: profileInput?.contactNumber || '',
                      aboutMe: profileInput?.aboutMe || '',
                      programIds: [programId],
                    }),
                    saveCompletedTask.run(data),
                  ]).then(onSaveSuccess);
                } catch (validatedError) {
                  setError(validatedError as ValidationError);
                }
              } else {
                saveCompletedTask.run(data).then(onSaveSuccess);
              }
            }}
            validationSchema={validationSchema}
          >
            {({ values }: { values: TaskFormValuesType }) => {
              return (
                <Form>
                  <TaskFormFieldList taskItems={taskItems} values={values} />
                  <Box maxWidth={720} display='flex' justifyContent='flex-end'>
                    <TabletButton
                      variant='text'
                      style={{
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        textTransform: 'none',
                        padding: '7px 12px',
                        minWidth: '40px',
                        marginRight: '8px',
                      }}
                      onClick={onCancel}
                    >
                      Cancel
                    </TabletButton>
                    <TabletButton
                      variant='contained'
                      style={{
                        backgroundColor: theme.color.secondary.main900,
                        color: '#ffffff',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        textTransform: 'none',
                        padding: '7px 12px',
                        minWidth: '40px',
                      }}
                      type='submit'
                    >
                      Save
                    </TabletButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}
    </>
  );
};
