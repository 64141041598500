import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useSupportContext } from '../SupportContext';
import { CategoryViewer } from './CategoryViewer';
import { CategoryEditor } from './CategoryEditor';

const CategoryPage = () => {
  const { inEditCategoryMode, allCategories } = useSupportContext();
  const { categoryId } = useParams();

  const selectedCategory = useMemo(() => allCategories.find((c) => c.supportCategoryId === categoryId), [
    allCategories,
    categoryId,
  ]);

  return (
    <>
      {!inEditCategoryMode && selectedCategory ? (
        <CategoryViewer selectedCategory={selectedCategory} />
      ) : selectedCategory ? (
        <CategoryEditor selectedCategory={selectedCategory} />
      ) : null}
    </>
  );
};

export { CategoryPage };
