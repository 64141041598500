import React from 'react';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from '@campfire/draggable-list';
import { FormControlLabel, List, Radio } from '@material-ui/core';
import { TaskListItemContext } from '../../../TaskListItemsContext';
import { TaskListItem } from '../../TaskListItem';

interface DropItemTriggerProps {
  optionId: string;
  name: string;
}

export function DropItemTrigger({ optionId, name }: DropItemTriggerProps) {
  const { taskItems, draggingId } = React.useContext(TaskListItemContext);
  const { triggerItemIds = [], taskItemId } = taskItems.byTrigger[optionId] || {};

  const droppableType = taskItems.byTriggerOption[draggingId] ? 'items' : undefined;

  return (
    <Droppable droppableId={optionId} isDropDisabled={false} type={droppableType}>
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
        const style = {
          minHeight: '80px',
          maxWidth: '100%',
          borderRadius: '6px',
          color: '#979797',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '12px',
          border: '2px dashed',
          borderColor: '#979797',
          paddingLeft: '8px',
          paddingRight: '8px',
        };

        const isSelftContaining = snapshot.draggingOverWith === taskItemId;

        const updatedStyle =
          snapshot.isDraggingOver && !isSelftContaining
            ? {
                pointerEvents: 'none',
                ...style,
                borderColor: 'lightblue',
              }
            : style;

        return (
          <div ref={provided.innerRef}>
            <FormControlLabel control={<Radio disabled />} label={name} />
            {triggerItemIds && triggerItemIds.length > 0 ? (
              <List style={updatedStyle as React.CSSProperties}>
                {triggerItemIds.map((itemId, itemIndex: number) => (
                  <TaskListItem key={itemId} taskItemId={itemId} index={itemIndex} />
                ))}
                {provided.placeholder}
              </List>
            ) : (
              <div style={updatedStyle as React.CSSProperties}>
                {`Drag and drop task fields here to be shown when '${name}' is selected`}
              </div>
            )}
          </div>
        );
      }}
    </Droppable>
  );
}
