import { gql } from '@apollo/client';
import {
  COMPLETED_TASK_FRAGMENT,
  TASK_FORM_ITEM_FRAGMENT,
} from '../../../../../../../common/form/task-form/model/task-items.gql';

export const GET_TASK_FOR_INCOMING_VOLUNTEER = gql`
  query GetTaskForIncomingVolunteer(
    $completedTaskId: String!
    $taskId: String!
    $profileId: String!
    $userId: String!
  ) {
    vm {
      programs {
        programId
        name
        dateSuspended
      }
      profile(profileId: $profileId, userId: $userId) {
        profileId
        firstName
        lastName
        preferredName
        dateOfBirth
        contactNumber
        email
        aboutMe
        programs {
          programId
          name
        }
        completedTasks {
          completedTaskId
          status
          task {
            taskId
            cake {
              cakeType
            }
          }
        }
      }
      application(userId: $userId) {
        relevantTasks {
          taskId
          cake {
            cakeType
          }
        }
      }
      completedTask(completedTaskId: $completedTaskId) {
        ...CompletedTask
      }
      task(taskId: $taskId) {
        order
        dateRemoved
        allowUpdate
        taskId
        title
        description
        cake {
          title
        }
        taskItems {
          ...TaskFormItem
        }
      }
    }
  }

  ${COMPLETED_TASK_FRAGMENT}
  ${TASK_FORM_ITEM_FRAGMENT}
`;
