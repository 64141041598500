import { gql } from '@apollo/client';

export const REPORT_TYPE_ACTIVITY_FRAGMENT = gql`
  fragment ReportTypeActivity on VOLUNTEER_ActivityType {
    activityId
    name
    isSuspended
    closedActivity {
      closedActivityId
    }
    sessions {
      sessionId
      name
      reportType {
        reportTypeId
        name
      }
    }
  }
`;

export const REPORT_MANAGEMENT_GET_ACTIVITIES_FROM_REPORT_TYPE = gql`
  query ReportManagementGetActivitiesFromReportType {
    vm {
      allReportTypes {
        ...ReportTypeWithActivities
      }
    }
  }

  fragment ReportTypeWithActivities on VOLUNTEER_ReportTypeType {
    reportTypeId
    name
    activities {
      ...ReportTypeActivity
    }
  }

  ${REPORT_TYPE_ACTIVITY_FRAGMENT}
`;
