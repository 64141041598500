import React from 'react';
import { ToggleButtonProps, ToggleButton as MuiToggleButton } from '@material-ui/lab';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid',
      borderRadius: '6px',
      [theme.breakpoints.up('sm')]: {
        boxSizing: 'border-box',
        textTransform: 'none',
        marginRight: '10px',
        padding: '7px 12px',
        minWidth: '45px',
        color: theme.color.grey.neutral400,
        borderColor: '#dcdcdc',
        '& svg': {
          fontSize: '18px',
        },
        '& p': {
          marginLeft: '4px',
          fontWeight: 600,
          fontSize: '13px',
          lineHeight: '19px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },
      [theme.breakpoints.down('xs')]: {
        color: theme.color.grey.neutral300,
        padding: '0px 4px',
        border: '1px solid #9e9e9e',
        boxSizing: 'border-box',
        margin: '0 4px',
        '& svg': {
          fontSize: '26px',
        },
      },
    },
    selected: {
      color: '#FCFCFC !important',
      backgroundColor: '#646464 !important',
    },
  })
);

export function ToggleButton(props: ToggleButtonProps) {
  const presetClasses = useStyles();
  return <MuiToggleButton classes={presetClasses} {...props} />;
}
