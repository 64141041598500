import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { FullscreenDialog } from '../../../../common/dialogs/FullscreenDialog';

import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { MyTasks } from './MyTasks';

interface TasksDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}
const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: { overflow: 'visible', minHeight: '90vh' },
  })
);

const TasksDialog = (props: TasksDialogProps) => {
  const { open, setOpen } = props;
  const { isMobile } = useCampfireTheme();
  const classes = useStyles();

  function onClose() {
    setOpen(false);
  }
  return (
    <FullscreenDialog
      open={open}
      close={onClose}
      fullScreen={isMobile}
      title={'Tasks'}
      subtitle={'These tasks below have been assigned to you to complete.'}
      PaperProps={{ className: classes.dialogPaper }}
      maxWidth='sm'
      fullWidth
    >
      <Box py={'24px'}>
        <MyTasks />
      </Box>
    </FullscreenDialog>
  );
};

export { TasksDialog };
