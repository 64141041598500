import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { ShortText } from '@material-ui/icons';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useDeepEffect } from '../../../../../../hooks/useDeepEffect';

const useStyles = makeStyles(() =>
  createStyles({
    nonEditingContainer: {
      display: 'flex',
      alignItems: 'center',
      '& .title': {
        fontWeight: 700,
        fontSize: 14,
        color: '#646464',
      },
      '& .subtitle': {
        fontSize: 12,
        color: '#646464',
      },
      '& .cta': {
        color: '#7047EB',
        cursor: 'pointer',
        fontSize: 12,
        paddingTop: 2,
        fontWeight: 400,
      },
    },
    editingContainer: {
      '& .buttonsGroup': {
        marginTop: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      '& .button': {
        borderRadius: 6,
        height: 37,
        textTransform: 'none',
        marginLeft: '8px',
      },
    },
  })
);

export function CustomContentBlock({ value, onSave }: { value?: string; onSave: (content: string) => void }) {
  const [editingMode, setEditingMode] = React.useState(false);
  const [editorState, setEditorState] = React.useState(
    !value ? EditorState.createEmpty() : EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
  );

  useDeepEffect(() => {
    setEditorState(
      !value ? EditorState.createEmpty() : EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
    );
  }, [value]);

  const classes = useStyles();

  const onSaveHandler = () => {
    onSave(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    setEditingMode(false);
  };

  if (editingMode) {
    return (
      <Box className={classes.editingContainer}>
        <CampfireRichEditor
          placeholder='Set custom content block'
          editorState={editorState}
          setEditorState={setEditorState}
        />
        <Box className='buttonsGroup'>
          <Button className='button' onClick={() => setEditingMode(false)} variant='outlined'>
            Cancel
          </Button>
          <Button className='button' variant='contained' disableElevation color='primary' onClick={onSaveHandler}>
            Save
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.nonEditingContainer}>
      <ShortText />
      <Box paddingLeft='10px'>
        {value ? (
          <CampfireRichEditor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(value)))} />
        ) : (
          <>
            <Typography className='title'>Custom Content</Typography>
            <Typography className='subtitle'>Your organisation&apos;s custom content will appear here.</Typography>
          </>
        )}
        <Typography className='cta' onClick={() => setEditingMode(true)}>
          Click to edit
        </Typography>
      </Box>
    </Box>
  );
}
