import IconButton from '@material-ui/core/IconButton';
import Snackbar, { SnackbarCloseReason, SnackbarProps } from '@material-ui/core/Snackbar';
import SnackbarContent, { SnackbarContentProps } from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import React, { FC, FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';

export type SnackBarOptions =
  | { variant: 'success' | 'warning' | 'error' | 'info'; message: string; open: boolean }
  | undefined;

export const snackbarVariantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export const useSnackbarStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: theme.status.green.dark,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  info: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[900],
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[900],
  },
  warning: {
    backgroundColor: theme.status.amber.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface CampfireSnackbarProps {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: keyof typeof snackbarVariantIcon;
  Icon?: React.ReactNode;
  noAutoHide?: boolean;
}
const TemplateSnackbarContent: FC<CampfireSnackbarProps & Omit<SnackbarContentProps, 'variant'>> = (props) => {
  const { className, message, onClose, variant, Icon, ...rest } = props;
  const classes = useSnackbarStyles();
  const StandardIcon = snackbarVariantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          {Icon || <StandardIcon className={clsx(classes.icon, classes.iconVariant)} />}
          {message}
        </span>
      }
      action={
        onClose
          ? [
              <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]
          : undefined
      }
      {...rest}
    />
  );
};

export type TemplateSnackbarProps = SnackbarProps & CampfireSnackbarProps;
const TemplateSnackbar: FunctionComponent<TemplateSnackbarProps> = (props) => {
  const { open, onClose, variant, message, Icon, noAutoHide, ...rest } = props;
  const [isOpen, setIsOpen] = useState(open);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = (event?: SyntheticEvent, reason?: SnackbarCloseReason) => {
    if (!onClose) {
      setIsOpen(false);
    }

    if (onClose) onClose();

    if (!onClose || !event || !reason) return;
    onClose(event, reason);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={isOpen}
      autoHideDuration={noAutoHide ? undefined : 6000}
      onClose={handleClose}
      {...rest}
    >
      <TemplateSnackbarContent Icon={Icon} variant={variant} message={message} onClose={handleClose} />
    </Snackbar>
  );
};

export { TemplateSnackbar };
export { TemplateSnackbarContent };
