import { Box, Divider, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { unpackToDate } from '@campfire/hot-date';
import { ViewAllIncompleteReportsBlock } from './ViewIncompleteReportsBlock';

interface Props {
  incompleteActivityReports: Array<{
    activityId: string;
    name: string;
    date: string;
  }>;
}

export const PastMonthReportsBlock = (props: Props) => {
  const { incompleteActivityReports } = props;
  const history = useHistory();
  const hasRosterings = incompleteActivityReports.length > 0;

  return (
    <Box maxWidth='500px' width={1}>
      <Grid item direction='column' container alignItems='center' alignContent='center' justify='center'>
        <Grid item xs style={{ paddingTop: 64, paddingBottom: 64 }}>
          <Typography variant='h2' style={{ fontWeight: 500 }}>
            Start a report
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h6'>
            {hasRosterings ? 'Incomplete reports for the past month' : 'No incomplete reports for the past month'}
          </Typography>
        </Grid>
        {hasRosterings ? (
          <Grid item xs>
            <Typography variant='subtitle1' color='textSecondary'>
              You were rostered for the following activities this month
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      {hasRosterings ? (
        <Grid item xs>
          <List>
            {incompleteActivityReports.map((activityReport, index) => {
              const { date, activityId, name } = activityReport;
              return (
                <Fragment key={`${activityId}-${date}`}>
                  <TitularTooltip title='Click to start report' placement='right'>
                    <ListItem
                      button
                      onClick={() =>
                        history.push(`/volunteering/activity-report?activityId=${activityId}&activityDate=${date}`)
                      }
                    >
                      <ListItemText primary={`${unpackToDate(date).toFormat('EEEE, d MMMM')}`} secondary={`${name}`} />
                    </ListItem>
                  </TitularTooltip>
                  {index < incompleteActivityReports.length - 1 ? <Divider /> : null}
                </Fragment>
              );
            })}
          </List>
        </Grid>
      ) : null}
      <ViewAllIncompleteReportsBlock hasRosterings={hasRosterings} />
    </Box>
  );
};
