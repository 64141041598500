import { Field, FieldProps, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { TaskFormAttachmentField } from '../../form/task-form/model/task-form-init';
import { AddressAutoCompleteType } from '../AddressAutoComplete';
import { AddressManualFieldsType } from '../AddressManualFields';
import {
  AddressTaskField,
  AddressTaskFieldProps,
  AttachmentTaskField,
  AttachmentTaskFieldProps,
  CheckboxTaskField,
  CheckboxTaskFieldProps,
  DateTaskField,
  DateTaskFieldProps,
  DropdownTaskField,
  DropdownTaskFieldProps,
  EmailTaskField,
  LongTextTaskField,
  LongTextTaskFieldProps,
  MultipleChoiceTaskField,
  MultipleChoiceTaskFieldProps,
  NumberTaskField,
  NumberTaskFieldProps,
  PhoneTaskField,
  ShortTextTaskField,
  ShortTextTaskFieldProps,
  QuizTaskField,
  QuizTaskFieldProps,
  SignatureTaskField,
  SignatureTaskFieldProps,
} from './TaskFields';
import { useSnackbar } from '../../../global/config/useSnackbar';

type ShortTextTaskFormikFieldConfig = { name: string };

type ShortTextTaskFormikFieldProps = Omit<ShortTextTaskFieldProps, 'value' | 'onChange'> &
  ShortTextTaskFormikFieldConfig;

const ShortTextTaskFormikField = (props: ShortTextTaskFormikFieldProps) => {
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <ShortTextTaskField
          {...rest}
          {...fieldProps.field}
          helperText={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
          error={!!fieldProps.meta.error && fieldProps.meta.touched}
        />
      )}
    </Field>
  );
};

interface LongTextTaskFormikFieldConfig {
  name: string;
}

type LongTextTaskFormikFieldProps = Omit<LongTextTaskFieldProps, 'value' | 'onChange'> & LongTextTaskFormikFieldConfig;

const LongTextTaskFormikField = (props: LongTextTaskFormikFieldProps) => {
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <LongTextTaskField
          {...rest}
          {...fieldProps.field}
          helperText={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
          error={!!fieldProps.meta.error && fieldProps.meta.touched}
        />
      )}
    </Field>
  );
};
interface NumberTaskFormikFieldConfig {
  name: string;
}

type NumberTaskFormikFieldProps = Omit<NumberTaskFieldProps, 'value' | 'onChange'> & NumberTaskFormikFieldConfig;

const NumberTaskFormikField = (props: NumberTaskFormikFieldProps) => {
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <NumberTaskField
          {...rest}
          {...fieldProps.field}
          helperText={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
          error={!!fieldProps.meta.error && fieldProps.meta.touched}
        />
      )}
    </Field>
  );
};

export type TaskFormikMultipleChoiceType = {
  multipleChoiceOptionId: string;
  otherText?: string;
  otherOptionSelected: boolean;
};

interface MultipleChoiceTaskFormikFieldConfig {
  name: string;
}

type MultipleChoiceTaskFormikFieldProps = Omit<MultipleChoiceTaskFieldProps, 'value' | 'onChange'> &
  MultipleChoiceTaskFormikFieldConfig;

type MultipleChoiceErrors = {
  multipleChoiceOptionId: string;
  otherText: string;
};

const MultipleChoiceTaskFormikField = (props: MultipleChoiceTaskFormikFieldProps) => {
  const { name, values, ...rest } = props;
  const { errors } = useFormikContext<{ [key: string]: MultipleChoiceErrors }>();
  const error = errors[name]?.multipleChoiceOptionId ?? errors[name]?.otherText;

  return (
    <React.Fragment>
      <Field name={name}>
        {(fieldProps: FieldProps) => {
          return (
            <MultipleChoiceTaskField
              {...rest}
              {...fieldProps.field}
              values={values}
              onChange={(value) => fieldProps.form.setFieldValue(name, value)}
              helperText={fieldProps.meta.touched && error ? error : undefined}
              error={!!fieldProps.meta.error && fieldProps.meta.touched}
            />
          );
        }}
      </Field>
    </React.Fragment>
  );
};

export type TaskFormikQuizType = {
  quizTaskFieldOptionId: string;
  otherTaskFieldOptionSelected: boolean;
  otherText: string;
  allowRetry: boolean;
  isCorrect: boolean;
};

interface QuizTaskFormikFieldConfig {
  name: string;
}

type QuizTaskFormikFieldProps = Omit<QuizTaskFieldProps, 'value' | 'onChange'> & QuizTaskFormikFieldConfig;

type QuizErrors = {
  quizTaskFieldOptionId: string;
  isCorrect: string;
};

const QuizTaskFormikField = (props: QuizTaskFormikFieldProps) => {
  const { name, ...rest } = props;
  const { errors } = useFormikContext<{ [key: string]: QuizErrors }>();
  const error = errors[name]?.quizTaskFieldOptionId || errors[name]?.isCorrect || '';
  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <QuizTaskField
          {...rest}
          {...fieldProps.field}
          onChange={(value) => fieldProps.form.setFieldValue(name, value)}
          helperText={fieldProps.meta.touched && error ? error : undefined}
          error={!!fieldProps.meta.error && fieldProps.meta.touched}
        />
      )}
    </Field>
  );
};

interface CheckboxTaskFormikFieldConfig {
  name: string;
}

type CheckboxTaskFormikFieldProps = Omit<CheckboxTaskFieldProps, 'value' | 'onChange'> & CheckboxTaskFormikFieldConfig;

const CheckboxTaskFormikField = (props: CheckboxTaskFormikFieldProps) => {
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => {
        return (
          <CheckboxTaskField
            {...rest}
            {...fieldProps.field}
            onChange={(value) => fieldProps.form.setFieldValue(name, value)}
            helperText={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
            error={!!fieldProps.meta.error && fieldProps.meta.touched}
          />
        );
      }}
    </Field>
  );
};

interface DropdownTaskFormikFieldConfig {
  name: string;
}

type DropdownTaskFormikFieldProps = Omit<DropdownTaskFieldProps, 'value' | 'onChange'> & DropdownTaskFormikFieldConfig;

const DropdownTaskFormikField = (props: DropdownTaskFormikFieldProps) => {
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <DropdownTaskField
          {...rest}
          {...fieldProps.field}
          helperText={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
          error={!!fieldProps.meta.error && fieldProps.meta.touched}
        />
      )}
    </Field>
  );
};

interface AttachmentTaskFormikFieldConfig {
  name: string;
}

type AttachmentTaskFormikFieldProps = Omit<
  AttachmentTaskFieldProps,
  'value' | 'uploadedFiles' | 'handleFilesAdded' | 'handleFileRemoved'
> &
  AttachmentTaskFormikFieldConfig;

const AttachmentTaskFormikField = (props: AttachmentTaskFormikFieldProps) => {
  const { setSnackbar } = useSnackbar();
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<TaskFormAttachmentField>) => {
        return (
          <AttachmentTaskField
            {...rest}
            {...fieldProps.field}
            uploadedFiles={fieldProps.field.value.attachments ?? []}
            handleFilesAdded={(files) => {
              const isFileLarge = files.some((item) => item.size / 1000000 > 15);
              if (isFileLarge) {
                setSnackbar({
                  open: true,
                  message: 'File too large. 15MB limit',
                  variant: 'warning',
                });
              }
              fieldProps.form.setFieldValue(name, {
                ...fieldProps.field.value,
                addedAttachments: [...fieldProps.field.value.addedAttachments, ...files],
              });
            }}
            handleFileRemoved={(file) => {
              fieldProps.form.setFieldValue(name, {
                ...fieldProps.field.value,
                addedAttachments: fieldProps.field.value.addedAttachments.filter((existingFile) => {
                  return existingFile.id !== file.id;
                }),
                attachments: fieldProps.field.value.attachments.filter((existingFile) => {
                  return existingFile.fileId !== file.id;
                }),
                attachmentCreationTokens: fieldProps.field.value.attachmentCreationTokens.filter((token) => {
                  return token !== file.id;
                }),
                removedAttachmentIds: [...fieldProps.field.value.removedAttachmentIds, file.id],
              });
            }}
            helperText={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
            error={!!fieldProps.meta.error && fieldProps.meta.touched}
          />
        );
      }}
    </Field>
  );
};
interface DateTaskFormikFieldConfig {
  name: string;
}

type DateTaskFormikFieldProps = Omit<DateTaskFieldProps, 'value' | 'onChange'> & DateTaskFormikFieldConfig;

const DateTaskFormikField = (props: DateTaskFormikFieldProps) => {
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<DateTime | null>) => (
        <DateTaskField
          {...rest}
          {...fieldProps.field}
          onChange={(date) => {
            fieldProps.form.setFieldValue(name, date);
          }}
          helperText={fieldProps.meta.touched && fieldProps.meta.error ? 'Invalid date' : undefined}
          error={!!fieldProps.meta.error && fieldProps.meta.touched}
        />
      )}
    </Field>
  );
};

interface AddressTaskFormikFieldConfig {
  name: string;
  allowManual?: boolean;
}

type AddressTaskFormikFieldProps = Omit<
  AddressTaskFieldProps,
  'addressAutocompleteValue' | 'handleAddressAutoCompleteChange' | 'manualAddressValue' | 'handleAddressManualChange'
> &
  AddressTaskFormikFieldConfig;

export type TaskFormikFieldAddressType = {
  addressOverride: boolean;
  address: {
    placesAddress: AddressAutoCompleteType | undefined;
    manualAddress: AddressManualFieldsType;
  };
};

type AddressError = {
  [key: string]: {
    address: {
      manualAddress: {
        lineOne: string;
        suburb: string;
        state: string;
        postcode: string;
        country: string;
      };
    };
  };
};

const AddressTaskFormikField = (props: AddressTaskFormikFieldProps) => {
  const { name, allowManual, ...rest } = props;
  const { errors } = useFormikContext<AddressError>();
  const error = useMemo(
    () =>
      errors[name]?.address?.manualAddress?.lineOne ??
      errors[name]?.address?.manualAddress?.suburb ??
      errors[name]?.address?.manualAddress?.state ??
      errors[name]?.address?.manualAddress?.postcode ??
      errors[name]?.address?.manualAddress?.country ??
      '',
    [errors, name]
  );
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<TaskFormikFieldAddressType>) => (
        <AddressTaskField
          {...rest}
          {...fieldProps.field}
          allowManual={allowManual}
          addressAutocompleteValue={fieldProps.field.value.address.placesAddress}
          handleAddressAutoCompleteChange={(address) =>
            fieldProps.form.setFieldValue(name, {
              ...fieldProps.field.value,
              addressOverride: false,
              address: {
                placesAddress: address,
                manualAddress: fieldProps.field.value.address.manualAddress,
              },
            })
          }
          manualAddressValue={fieldProps.field.value.address.manualAddress}
          handleAddressManualChange={(address) =>
            fieldProps.form.setFieldValue(name, {
              ...fieldProps.field.value,
              addressOverride: true,
              address: {
                manualAddress: address,
                placesAddress: fieldProps.field.value.address.placesAddress,
              },
            })
          }
          helperText={fieldProps.meta.touched ? error : undefined}
          error={!!fieldProps.meta.error && fieldProps.meta.touched}
        />
      )}
    </Field>
  );
};

type PhoneTaskFormikFieldConfig = { name: string };

type PhoneTaskFormikFieldProps = Omit<ShortTextTaskFieldProps, 'value' | 'onChange'> & PhoneTaskFormikFieldConfig;

const PhoneTaskFormikField = (props: PhoneTaskFormikFieldProps) => {
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <PhoneTaskField
          {...rest}
          {...fieldProps.field}
          helperText={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
          error={!!fieldProps.meta.error && fieldProps.meta.touched}
        />
      )}
    </Field>
  );
};

type EmailTaskFormikFieldConfig = { name: string };

type EmailTaskFormikFieldProps = Omit<ShortTextTaskFieldProps, 'value' | 'onChange'> & EmailTaskFormikFieldConfig;

const EmailTaskFormikField = (props: EmailTaskFormikFieldProps) => {
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <EmailTaskField
          {...rest}
          {...fieldProps.field}
          helperText={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
          error={!!fieldProps.meta.error && fieldProps.meta.touched}
        />
      )}
    </Field>
  );
};

type SignatureTaskFormikFieldConfig = { name: string };

type SignatureTaskFormikFieldProps = Omit<SignatureTaskFieldProps, 'value' | 'onChangeSignature'> &
  SignatureTaskFormikFieldConfig;

const SignatureTaskFormikField = (props: SignatureTaskFormikFieldProps) => {
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <SignatureTaskField
          {...rest}
          {...fieldProps.field}
          onChangeSignature={(file) => {
            fieldProps.form.setFieldValue(name, file);
          }}
          helperText={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
          error={!!fieldProps.meta.error && fieldProps.meta.touched}
        />
      )}
    </Field>
  );
};

export {
  ShortTextTaskFormikField,
  LongTextTaskFormikField,
  NumberTaskFormikField,
  MultipleChoiceTaskFormikField,
  CheckboxTaskFormikField,
  DropdownTaskFormikField,
  AttachmentTaskFormikField,
  DateTaskFormikField,
  AddressTaskFormikField,
  PhoneTaskFormikField,
  EmailTaskFormikField,
  QuizTaskFormikField,
  SignatureTaskFormikField,
};
