import React from 'react';
import { Box } from '@material-ui/core';
import { useSaveComment } from '../../../../common/comment/Comment';
import { CommentBox, PostCommentType } from '../../../../common/comment/CommentBox';
import { CommentViewer } from '../../../../common/comment/CommentViewer';

import { IncidentManagementSingleIncident_incidentReports_comments as IncidentReportCommentType } from '../__generated__/IncidentManagementSingleIncident';

interface IncidentReportCommentsProps {
  incidentReportId: string;
  comments: IncidentReportCommentType[];
  refetch: () => void;
}

export function IncidentReportComments({ incidentReportId, comments, refetch }: IncidentReportCommentsProps) {
  const saveComment = useSaveComment();

  const onPost = (body: PostCommentType) => {
    saveComment({
      incidentReportId,
      ...body,
    }).then(() => {
      // onClear();
      if (refetch) {
        refetch();
      }
    });
  };

  const handleEdit = (body: PostCommentType) => {
    saveComment({
      ...body,
    }).then(() => {
      // onClear();
      if (refetch) {
        refetch();
      }
    });
  };

  const onReply = (parentId: string, content: string) => {
    saveComment({
      parentId,
      incidentReportId,
      content,
    }).then(() => {
      if (refetch) {
        refetch();
      }
    });
  };

  const rootComments = comments.filter((comment) => !comment.parentId);
  const nonRootComments = comments.filter((comment) => Boolean(comment.parentId));
  return (
    <React.Fragment>
      <Box>
        <CommentBox onPost={onPost} enableAttachment />
      </Box>
      <Box paddingTop={'1em'}>
        {rootComments?.map(
          (comment) =>
            !comment.removedDate && (
              <CommentViewer
                comment={comment}
                comments={nonRootComments}
                onReply={onReply}
                refetch={refetch}
                handleEdit={handleEdit}
                enableAttachment
              />
            )
        )}
      </Box>
    </React.Fragment>
  );
}
