import { formatUnitBasedOnValue } from '@campfire/hot-date';
import { HoverLink } from '@campfire/hover-link';
import { Box, Collapse, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { uniqBy } from 'lodash';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { ReportTypeStatusChip } from '../../common/ReportTypeStatusChip';
import { SectionItem } from '../../report-types/report-type-detail/ReportTypeDetail';
import { ReportType } from './ReportFieldDetail';

type Props = {
  reportTypes: Array<ReportType>;
};

const ReportTypeItem = ({ reportType }: { reportType: ReportType }) => {
  const activities = uniqBy(reportType.activities, 'activityId');
  const [displayActivities, setDisplayActivities] = useState<boolean>(false);

  const { theme } = useCampfireTheme();
  const match = useRouteMatch();

  return (
    <Box
      border={displayActivities ? `2px solid ${theme.color.grey.border}` : `1px solid ${theme.color.grey.border}`}
      bgcolor={theme.color.white.neutral50}
      style={{
        padding: '16px',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: '8px',
        marginTop: 8,
        marginBottom: 8,
      }}
    >
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <HoverLink
          to={{
            pathname: match?.url,
            search: `?tab=report-types&reportTypeId=${reportType.reportTypeId}`,
          }}
          color='textPrimary'
          hoverColor='primary'
          style={{ marginRight: 8 }}
        >
          <Typography variant='subtitle2'>{reportType.name}</Typography>
        </HoverLink>
        <ReportTypeStatusChip isDeactivated={reportType.dateDeactivated} />
      </Box>
      <Box display='flex' alignItems='center'>
        <Typography variant='caption' style={{ color: theme.color.grey.neutral300, marginRight: 8 }}>
          Used in {formatUnitBasedOnValue(activities.length, 'activities')}
        </Typography>
        {displayActivities ? (
          <ExpandLess style={{ width: 16, height: 16 }} onClick={() => setDisplayActivities(!displayActivities)} />
        ) : (
          <ExpandMore style={{ width: 16, height: 16 }} onClick={() => setDisplayActivities(!displayActivities)} />
        )}
      </Box>
      <Collapse in={displayActivities} timeout={0} unmountOnExit style={{ marginTop: '8px' }}>
        {activities.map((activity) => (
          <Box ml='16px' key={`${activity.activityId} - ${reportType.reportTypeId}`}>
            <HoverLink
              to={{
                pathname: match?.url,
                search: `?tab=activities&reportTypeId=${reportType.reportTypeId}&activityId=${activity.activityId}`,
              }}
              color='textPrimary'
              hoverColor='primary'
              style={{ marginRight: 8 }}
            >
              <Typography variant='caption'>{activity.name}</Typography>
            </HoverLink>
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};

export const ReportTypesUsingReportField = (props: Props) => {
  const { reportTypes } = props;
  const { theme } = useCampfireTheme();

  return (
    <SectionItem
      title='Used in'
      text={reportTypes.length ? `${formatUnitBasedOnValue(reportTypes.length, 'Report Types')}` : undefined}
    >
      {reportTypes.length ? (
        <>
          {reportTypes.map((reportType: ReportType) => (
            <ReportTypeItem key={reportType.reportTypeId} reportType={reportType} />
          ))}
        </>
      ) : (
        <Typography variant='caption' style={{ color: theme.color.grey.neutral300 }}>
          There are no report types currently using this report field
        </Typography>
      )}
    </SectionItem>
  );
};
