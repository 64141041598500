import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { Skeleton } from '@material-ui/lab';

import { VolunteerProfile } from '../../../../../common/AvatarName';
import { GetVolunteerInfo_vm_volunteer as VolunteerType } from '../__generated__/GetVolunteerInfo';
import { RosterCell, RosterCellFinished } from './RosterCell';
import { GetActivityInfo_vm_activity_sessions as SessionType } from '../__generated__/GetActivityInfo';
import { ParsedRoster, RosterByDateType } from '../helpers';

const useStyles = makeStyles(() =>
  createStyles({
    cell: {
      border: 0,
      textAlign: 'center',
      paddingTop: '.75rem',
      paddingBottom: '.75rem',
    },
    rowBody: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F9F9F9',
      },
      '&:first-child': {
        marginTop: '0.5rem',
      },
    },
  })
);

interface RosterRowProps {
  volunteer: VolunteerType | { volunteerId: string };
  volunteerRosters: { [activityDate: string]: RosterByDateType };
  activityDates: string[];
  sessions?: SessionType[];
  onClick: (activityDate: string, volunteerId: string, sessionId: string) => void;
  onCancelClick: (activityDate: string, volunteerId: string) => void;
  isLoading: boolean;
  handleClickRoleTasks: (activityRoleId: string, volunteer: VolunteerType) => void;
}

export function RosterRow({
  volunteer,
  volunteerRosters,
  sessions,
  onClick,
  onCancelClick,
  activityDates,
  isLoading,
  handleClickRoleTasks,
}: RosterRowProps) {
  const classes = useStyles();

  const isEnrolled = activityDates.every((activityDate) => {
    const volunteerRoster = get(
      volunteerRosters,
      [activityDate, 'volunteers', volunteer?.volunteerId],
      {}
    ) as ParsedRoster;
    return Boolean(volunteerRoster.enrolmentId);
  });
  const activityRolesParsed =
    'activityRoles' in volunteer
      ? volunteer.activityRoles.map((role) => {
          const isCompletedTask = role.tasks.every(
            (task) =>
              task.completedTasks.filter((cTask) => cTask.profile?.profileId === volunteer.profile.profileId).length > 0
          );
          return { ...role, isCompletedTask };
        })
      : [];

  return (
    <TableRow className={classes.rowBody}>
      <TableCell className={classes.cell}>
        {'profile' in volunteer ? (
          <VolunteerProfile
            profileId={volunteer.profile.profileId}
            userId={volunteer.profile.userId}
            lastName={volunteer.profile.lastName}
            preferredName={volunteer.profile.preferredName}
            name={`${volunteer.profile.preferredName} ${volunteer.profile.lastName}`}
            avatarUrl={volunteer.profile.avatarUrl}
            activityRoles={activityRolesParsed}
            tooltipAll={false}
            noTrim
            description={!isEnrolled ? 'Additional Volunteer' : undefined}
            onRoleBadgeClick={(activityRoleId) => handleClickRoleTasks(activityRoleId, volunteer)}
          />
        ) : (
          <Skeleton height={40} width={200} />
        )}
      </TableCell>
      {activityDates.map((activityDate) => {
        const volunteerRoster = get(
          volunteerRosters,
          [activityDate, 'volunteers', volunteer?.volunteerId],
          {}
        ) as ParsedRoster;
        if (!isLoading) {
          return (
            <TableCell className={classes.cell}>
              {DateTime.local().toISODate() > activityDate ? (
                <RosterCellFinished volunteerRoster={volunteerRoster} sessions={sessions} />
              ) : (
                <RosterCell
                  volunteerRoster={volunteerRoster}
                  sessions={sessions}
                  onSessionClick={(sessionId) => onClick(activityDate, volunteer.volunteerId, sessionId)}
                  onCancelClick={() => onCancelClick(activityDate, volunteer.volunteerId)}
                  disabled={Boolean(get(volunteerRosters, [activityDate, 'cancelledActivityId'], false))}
                />
              )}
            </TableCell>
          );
        }
        return (
          <TableCell className={classes.cell}>
            <Skeleton width={144} height={43} variant='rect' style={{ borderRadius: '4px', margin: 'auto' }} />
          </TableCell>
        );
      })}
    </TableRow>
  );
}
