import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

interface Props {
  startTime?: string;
  endTime?: string;
  handleChange: (filter: any) => void;
}
export const TimeFilters = (props: Props) => {
  const { startTime, endTime, handleChange } = props;
  const { isMobile } = useCampfireTheme();

  return (
    <Grid container style={{ marginTop: '10px' }}>
      <Grid
        item
        xs={isMobile ? 12 : 6}
        style={{ paddingRight: isMobile ? '0px' : '10px', marginBottom: isMobile ? '8px' : '0px' }}
      >
        <TextField
          id='start-time-filter'
          label='Start time'
          type='time'
          variant='outlined'
          fullWidth
          value={startTime ?? ''}
          color='primary'
          onChange={(e) => handleChange({ startTime: e.target.value })}
          onBlur={() => {
            if (!startTime) return;
            handleChange({ startTime: startTime });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          margin='dense'
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 6} style={{ paddingLeft: isMobile ? '0px' : '10px' }}>
        <TextField
          id='end-time-filter'
          label='End time'
          type='time'
          variant='outlined'
          fullWidth
          value={endTime ?? ''}
          color='primary'
          onChange={(e) => handleChange({ endTime: e.target.value })}
          onBlur={() => {
            if (!endTime) return;
            handleChange({ endTime: endTime });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          margin='dense'
        />
      </Grid>
    </Grid>
  );
};
