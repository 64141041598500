import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { unpackToken } from '../auth/unpack-token';
import { useSession } from '../auth/useSession';
import { useCampfireVersion } from '../config/useCampfireVersion';
import { CampfireResponse, unpackHttpResponse } from './httpResponse';
import { AxiosResponseWithOk, useAxiosFetch, UseAxiosFetchApi } from './useAxiosFetch';

export interface UseCampfireFetchOptions {
  axiosOptions?: AxiosRequestConfig;
  defer?: boolean;
  withAuth?: boolean;
  identityRefresh?: () => void;
}

export type UseCampfireFetchApi<T> = UseAxiosFetchApi<CampfireResponse<T>> & {
  status: 'initial' | 'pending' | 'fulfilled' | 'rejected';
};

const useCampfireFetchWithoutAuth = <ResponseType>({
  axiosOptions = {},
  defer = false,
  identityRefresh = undefined,
}: UseCampfireFetchOptions): UseCampfireFetchApi<ResponseType> => {
  type ResponseBundle = {
    error: Error | undefined;
    isLoading: boolean;
    response: AxiosResponseWithOk<CampfireResponse<ResponseType>> | undefined;
  };

  const [responseBundle, setResponseBundle] = useState<ResponseBundle>({
    error: undefined,
    isLoading: false,
    response: undefined,
  });

  const versionContext = useCampfireVersion();

  const activateResponse = (r: AxiosResponseWithOk<CampfireResponse<ResponseType>>) => {
    setResponseBundle({
      response: r,
      isLoading: false,
      error: undefined,
    });
  };

  const activateError = (e: Error) => {
    setResponseBundle({
      response: undefined,
      isLoading: false,
      error: e,
    });
  };

  const { run, reload, response: axiosResponse, error: axiosRequestError, ...restAxiosFetch } = useAxiosFetch<
    CampfireResponse<ResponseType>
  >({
    axiosOptions,
    defer,
    allowErrorResponse: true,
  });

  useEffect(() => {
    const handleDataResolve = async () => {
      if (axiosResponse === undefined) return;
      try {
        await unpackHttpResponse<ResponseType>(axiosResponse);
        if (axiosResponse.data.shouldRefreshIdentity && identityRefresh) {
          identityRefresh();
        }
        versionContext.setServerVersion(axiosResponse.data.campfireVersion);
        activateResponse(axiosResponse);
      } catch (e) {
        activateError(new Error('Something went wrong'));
      }
    };
    handleDataResolve();
  }, [axiosResponse]);

  useEffect(() => {
    // Catch timeouts and other really big broken situations
    if (axiosRequestError === undefined) return;
    activateError(new Error('Something went wrong'));
  }, [axiosRequestError]);

  const isResponseNotOk =
    restAxiosFetch.status !== 'pending' && responseBundle && responseBundle.response && !responseBundle.response.ok;

  const status = isResponseNotOk ? 'rejected' : restAxiosFetch.status;

  return {
    run,
    reload,
    ...responseBundle,
    ...restAxiosFetch,
    status,
  };
};

export const useCampfireFetch = <ResponseType>({
  axiosOptions,
  defer = false,
  withAuth = true,
}: UseCampfireFetchOptions): UseCampfireFetchApi<ResponseType> => {
  if (!withAuth) {
    return useCampfireFetchWithoutAuth<ResponseType>({
      axiosOptions,
      defer,
    });
  }

  const { user, reloadSession } = useSession();
  if (!user) {
    throw new Error(
      `Volaby's useFetch default behaviour requires an authorization token to be returned by useAuth. 
      If you are making a request that doesn't require auth then pass withAuth: false into the useFetch options.`
    );
  }

  /**
   * Refresh token if it is more than a day old
   */
  const currentUnixTimestamp = Math.floor(new Date().getTime() / 1000);
  const { iat } = unpackToken(user.token);
  if (currentUnixTimestamp - 86400 > iat) {
    reloadSession();
  }

  const authorizationAxiosOptions: AxiosRequestConfig = {
    ...axiosOptions,
    headers: {
      ...(axiosOptions ? axiosOptions.headers : {}),
      Authorization: `JWT ${user.token}`,
    },
  };

  return useCampfireFetchWithoutAuth<ResponseType>({
    axiosOptions: authorizationAxiosOptions,
    defer,
    identityRefresh: reloadSession,
  });
};
