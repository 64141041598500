import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import Downshift, { GetPropsCommonOptions } from 'downshift';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { PaperSearch } from '../../../../common/inputs/PaperSearch';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import {
  getVolunteerDatabaseRosterStatus,
  isStatusDeactivated,
  isStatusFlaggedAndExited,
  VolunteerDatabaseActions,
  VolunteerDatabaseVolunteer,
} from './volunteer-database-model.gql';
import { VolunteerDatabaseActionMenu } from './VolunteerDatabaseActionMenu';
import { GetVolunteerDatabaseVariables } from './__generated__/GetVolunteerDatabase';
import { CampfireButton } from '../../../../global/components';

const getBackgroundForStatus = (status: string) => {
  const { theme } = useCampfireTheme();
  if (isStatusFlaggedAndExited(status)) return fade(theme.palette.error.main, 0.2);
  if (isStatusDeactivated(status)) return theme.palette.grey[300];
  return 'none';
};

const VolunteerDatabaseInnerSearchItem: FunctionComponent<{
  volunteer: VolunteerDatabaseVolunteer;
  volunteerDatabaseActions: VolunteerDatabaseActions;
  setSelectedVolunteerId: (x: string) => void;
  reloadDatabase?: () => void;
}> = React.memo(
  ({ volunteer, volunteerDatabaseActions, setSelectedVolunteerId, reloadDatabase }) => {
    const name = `${volunteer.profile.preferredName} ${volunteer.profile.lastName}`;
    const {
      profile: { email },
    } = volunteer;
    const rosterStatus = getVolunteerDatabaseRosterStatus(volunteer);
    return (
      <ListItem
        button
        style={{ background: getBackgroundForStatus(rosterStatus) }}
        onClick={() => {
          setSelectedVolunteerId(volunteer.volunteerId);
        }}
      >
        <ListItemText
          primary={
            <>
              <Typography style={{ marginRight: 8 }} component='span'>
                {name}
              </Typography>{' '}
              <Typography component='span' color='textSecondary' variant='body2'>
                {email}
              </Typography>
            </>
          }
          secondary={rosterStatus}
        />
        <ListItemSecondaryAction style={{ right: 0 }}>
          <VolunteerDatabaseActionMenu
            volunteer={volunteer}
            volunteerDatabaseActions={volunteerDatabaseActions}
            reloadDatabase={reloadDatabase}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  },
  (prev, next) => {
    return (
      prev.volunteer.volunteerId === next.volunteer.volunteerId &&
      prev.volunteer.dateDeactivated === next.volunteer.dateDeactivated
    );
  }
);

const VolunteerDatabaseInnerSearchResult = ({
  setSelectedVolunteerId,
  volunteers,
  menuProps,
  volunteerDatabaseActions,
  reloadDatabase,
  view,
}: {
  setSelectedVolunteerId: (x: string) => void;
  volunteers: Array<VolunteerDatabaseVolunteer>;
  menuProps: GetPropsCommonOptions | undefined;
  volunteerDatabaseActions: VolunteerDatabaseActions;
  reloadDatabase?: () => void;
  view: number | 'All';
}) => {
  return (
    <Box marginLeft={2} marginRight={2} marginBottom={2}>
      <List {...menuProps}>
        {volunteers.length ? (
          <Paper elevation={2}>
            {volunteers.map((volunteer, index) => {
              if (view !== 'All' && index >= view) return null;

              return (
                <Fragment key={volunteer.volunteerId}>
                  <VolunteerDatabaseInnerSearchItem
                    setSelectedVolunteerId={setSelectedVolunteerId}
                    volunteer={volunteer}
                    volunteerDatabaseActions={volunteerDatabaseActions}
                    reloadDatabase={reloadDatabase}
                  />
                  {index < volunteers.length - 1 ? <Divider /> : null}
                </Fragment>
              );
            })}
          </Paper>
        ) : null}
      </List>
    </Box>
  );
};

export const VolunteerDatabaseSection: FunctionComponent<{
  setSelectedVolunteerId: (x: string) => void;
  volunteers: Array<VolunteerDatabaseVolunteer>;
  volunteerDatabaseActions: VolunteerDatabaseActions;
  showDisabledAndInactiveVolunteers: boolean;
  reloadDatabase?: () => void;
  updateRequestVariables: (x: GetVolunteerDatabaseVariables) => void;
  requestVariables: GetVolunteerDatabaseVariables;
  totalVolunteers: number;
}> = ({
  setSelectedVolunteerId,
  volunteers,
  volunteerDatabaseActions,
  reloadDatabase,
  requestVariables,
  updateRequestVariables,
  totalVolunteers,
}) => {
  const [searchValue, setSearchValue] = useState(requestVariables.keywordsFilter);
  const [view, setView] = useState<number | 'All'>(requestVariables.first ?? 25);

  return (
    <Box position='relative'>
      <Downshift>
        {({ getInputProps, getMenuProps }) => (
          <div>
            <Box px={2} py={1} alignItems={'center'}>
              <PaperSearch
                value={searchValue ?? ''}
                onChange={(event) => {
                  setSearchValue(event.target.value);
                }}
                clear={() => {
                  setSearchValue(null);
                  updateRequestVariables({ keywordsFilter: null });
                }}
                getInputProps={getInputProps}
                placeholder={'Search by name, email or phone number'}
              />
              <CampfireButton
                variant={'text'}
                onClick={() => (searchValue ? updateRequestVariables({ keywordsFilter: searchValue }) : undefined)}
              >
                {'Search'}
              </CampfireButton>
            </Box>
            <VolunteerDatabaseInnerSearchResult
              setSelectedVolunteerId={setSelectedVolunteerId}
              volunteers={volunteers}
              menuProps={getMenuProps()}
              volunteerDatabaseActions={volunteerDatabaseActions}
              reloadDatabase={reloadDatabase}
              view={view}
            />
            <Grid container justify='flex-end'>
              <Box px={2} width={120} marginBottom={1} marginRight={2}>
                <FormControl fullWidth variant='outlined' size='small'>
                  <InputLabel id='page-select-label'>Show</InputLabel>
                  <Select
                    labelId='page-select-label'
                    id='page-select'
                    value={view}
                    label='Show'
                    onChange={(event: any) => {
                      setView(event.target.value);
                      updateRequestVariables({ first: event.target.value === 'All' ? null : event.target.value });
                    }}
                  >
                    {[25, 50, 250, 1000, 'All'].map((value) => (
                      <MenuItem id={`${value}`} key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography align='right' display='block' variant='caption'>{`of ${totalVolunteers}`}</Typography>
              </Box>
            </Grid>
          </div>
        )}
      </Downshift>
    </Box>
  );
};
