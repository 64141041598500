import React, { memo } from 'react';
import { AddressAdditionalField } from './AddressAdditionalField';
import { AttachmentsAdditionalFields } from './AttachmentsAdditionalFields';
import { DateAdditionalFields } from './DateAdditionalFields';
import { TaskFieldTypeEnum } from '../../../../admin-console-volunteer-profile-model';
import { DraggableListField } from './DraggableListField';

interface Props {
  type: TaskFieldTypeEnum;
}

export const AdditionalFieldTypeFields = memo((props: Props) => {
  switch (props.type) {
    case 'VOLUNTEER_AddressTaskFieldType':
      return <AddressAdditionalField />;
    case 'VOLUNTEER_AttachmentTaskFieldType':
      return <AttachmentsAdditionalFields />;
    case 'VOLUNTEER_DateTaskFieldType':
      return <DateAdditionalFields />;
    case 'VOLUNTEER_CheckboxTaskFieldType':
    case 'VOLUNTEER_DropdownTaskFieldType':
    case 'VOLUNTEER_MultipleChoiceTaskFieldType':
    case 'VOLUNTEER_QuizTaskFieldType':
      return <DraggableListField />;
    default:
      return null;
  }
});
