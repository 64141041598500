import React from 'react';
import { upperFirst } from 'lodash';
import { RequiringActionWidget as IRequiringActionWidget } from '../../dashboard-widget-setting/__generated__/RequiringActionWidget';
import { WidgetContainer, WidgetContent } from '../WidgetContainer';
import { WidgetHeader } from '../WidgetHeader';
import { ActivityApplicationRAWidget } from './activities-requiring-action-widget/activity-application-ra-widget/ActivityApplicationRAWidget';
import { ActivityWaitlistingRAWidget } from './activities-requiring-action-widget/actiivty-waitlistings-ra-widget/ActivityWaitlistingRAWidget';
import { IncidentsRAWidget } from './incidents-requiring-action-widget/IncidentsRAWidget';
import { IncompleteReportsRAWidget } from './incomplete-reports-requiring-action-widget/IncompleteReportsRAWidget';
import { VolunteerApplicationsRAWidget } from './volunteers-widget/VolunteerApplicationsRAWidget';
import { TaskApprovalRAWidget } from './tasks-requiring-action-widget/TaskApprovalRAWidget';
import { ProgramApplicationRAWidget } from './programs-requiring-action-widget/ProgramApplicationRAWidget';

function getWidgetFromTagAndName(tag: string, name: string, filters: string, range: string | null) {
  switch (tag.toLowerCase()) {
    case 'volunteers':
      switch (name) {
        case 'incoming':
          return <VolunteerApplicationsRAWidget filters={filters} range={range} />;
        default:
          return null;
      }
    case 'activities':
      switch (name) {
        case 'application':
          return <ActivityApplicationRAWidget filters={filters} range={range} />;
        case 'waitlist':
          return <ActivityWaitlistingRAWidget filters={filters} range={range} />;
        default:
          return null;
      }
    case 'reporting':
      switch (name) {
        case 'incomplete':
          return <IncompleteReportsRAWidget filters={filters} range={range} />;
        default:
          return null;
      }
    case 'incidents':
      return <IncidentsRAWidget filters={filters} status={name} range={range} />;

    case 'tasks':
      switch (name) {
        case 'awaiting':
          return <TaskApprovalRAWidget />;
        default:
          return null;
      }
    case 'programs':
      switch (name) {
        case 'application':
          return <ProgramApplicationRAWidget filters={filters} range={range} />;
        default:
          return null;
      }
    default:
      return null;
  }
}

export const RequiringActionWidget = ({ widget }: { widget: IRequiringActionWidget }) => {
  const { filters, targets, range } = widget;
  const { tag, name } = JSON.parse(targets)[0] as { tag: string; name: string };
  const title = `${upperFirst(tag.toLowerCase())} ${upperFirst(name.toLowerCase())}`;

  return (
    <WidgetContainer>
      <WidgetHeader title={title} subTitle='Requiring Attention' colorTheme='green' />
      <WidgetContent>{getWidgetFromTagAndName(tag, name, filters, range)}</WidgetContent>
    </WidgetContainer>
  );
};
