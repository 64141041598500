import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { WidgetValueType, valueLists } from '../dashboard-widget-model';
import { ValueGroup } from '../SelectCreateWidgetBy';

interface Props {
  onSelect: (value: WidgetValueType) => void;
  selectedValue: WidgetValueType;
}

export function SelectValue({ onSelect, selectedValue }: Props) {
  return (
    <Grid container direction='column' style={{ width: '100%', padding: '0 16px' }}>
      <Box>
        {valueLists.map((group) => (
          <ValueGroup group={group} onSelect={onSelect} selectedValue={selectedValue} />
        ))}
      </Box>
    </Grid>
  );
}
