import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import UsingDotsGraphic from '../../../assets/tutorial-graphics/my-activities/using_dots.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Impact Dashboard',
    description: (
      <>
        The Impact Dashboard is a key tool for <strong>managers and administrators</strong> to view and export{' '}
        <strong>key organisation data</strong> for measuring <strong>impact</strong> reported through Volaby.
      </>
    ),
    image: UsingDotsGraphic,
  },
];

const ImpactDashboardTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { ImpactDashboardTutorialDialog };
