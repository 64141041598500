import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { TaskFormItem_VOLUNTEER_TaskItemFieldType_field_VOLUNTEER_DropdownTaskFieldType as DropdownFieldType } from '../../../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';
import { DropItemTrigger } from './DropItemTrigger';

interface DropdownFieldPreviewProps {
  field: DropdownFieldType;
  isDragging: boolean;
}

export function DropdownFieldPreview({ field, isDragging }: DropdownFieldPreviewProps) {
  if (!field.dynamic || isDragging) {
    return (
      <TextField
        InputProps={{
          endAdornment: <ArrowDropDown />,
          inputProps: {
            style: {
              padding: 8,
            },
          },
        }}
        variant='outlined'
        disabled
        placeholder='Select an option'
      />
    );
  }
  return (
    <Grid container direction='column'>
      {field.dropdownOptions.map((option) => {
        const optionId = option.dropdownFieldOptionId;
        return (
          <Grid item key={optionId} style={{ overflow: 'hidden', maxWidth: '100%' }}>
            <DropItemTrigger optionId={optionId} name={option.name} />
          </Grid>
        );
      })}
    </Grid>
  );
}
