import { encodeDate, unpackToDateTime } from '@campfire/hot-date';
import { Box, Dialog, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React from 'react';
import { ActivityTimelinePastReport } from '../../../../program-manager/activities/activity-timeline/past/report/ActivityTimelinePastReport';
import { MyElementsUpcomingRoster } from './__generated__/MyElementsUpcomingRoster';

interface YourActivitiesViewActivityReportDialogProps {
  open: boolean;
  setOpen: (x: boolean) => void;
  upcomingRoster: MyElementsUpcomingRoster;
}

export const YourActivitiesViewActivityReportDialog = (props: YourActivitiesViewActivityReportDialogProps) => {
  const { open, setOpen, upcomingRoster } = props;
  const activityDate = unpackToDateTime(upcomingRoster.activityDate);

  return (
    <Dialog open={open}>
      <Box paddingX={2} paddingY={2} minWidth={540}>
        <Grid container justify='space-between'>
          <Grid item xs={10}>
            <Typography variant='h5'>{upcomingRoster.activity.name}</Typography>
            <Typography>{activityDate.toFormat('cccc, d LLLL')}</Typography>
          </Grid>
          <Grid container item xs={2} justify='flex-end'>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <ActivityTimelinePastReport
        activityId={upcomingRoster.activity.activityId}
        activityDate={encodeDate(activityDate)}
        source='your-activities'
        onBack={() => setOpen(false)}
      />
    </Dialog>
  );
};
