import { Typography, Box, List, ListItem, ListItemText, ListItemIcon, Checkbox } from '@material-ui/core';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from '@material-ui/icons';
import React from 'react';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { useFilterStyles } from '../../../../../../../common/filter-fields/FilterClasses';
import { IncomingVolunteersFilters, typeFilters, filterHelperTexts } from '../filters';

type TypeFilterMobileProps = {
  selectedFilters: IncomingVolunteersFilters;
  setSelectedFilters: (x: IncomingVolunteersFilters) => void;
};

const TypeFilterMobile = ({ selectedFilters, setSelectedFilters }: TypeFilterMobileProps) => {
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const isStatusFilterSelected = (value: string) => selectedFilters.selectedTypeFilter === value;
  const handleDurationChange = (value: string) =>
    setSelectedFilters({
      ...selectedFilters,
      selectedTypeFilter: value,
    });
  return (
    <Box py={2}>
      <Typography color='textSecondary' variant='body1' gutterBottom>
        {filterHelperTexts.typeFiltersHelperText}
      </Typography>
      <List disablePadding>
        {typeFilters.map((typeFilter) => (
          <ListItem
            key={typeFilter}
            onClick={() => handleDurationChange(typeFilter)}
            disableGutters
            className={classes.listItem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                checked={isStatusFilterSelected(typeFilter)}
                className={classes.checkBox}
                checkedIcon={<CheckBoxIcon color='primary' />}
                size='small'
                icon={<CheckBoxOutlineBlank />}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant='body1'
                color='textSecondary'
                style={isStatusFilterSelected(typeFilter) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
              >
                {typeFilter}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export { TypeFilterMobile };
