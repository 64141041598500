import { Pane, PaneScrollable, PaneWrapper } from '@campfire/pane';
import { Box, Grid, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
// import { useHistory } from 'react-router';
import { BooleanParam, useQueryParams } from 'use-query-params';
import { StickyHeader } from '../../../../../common/StickyHeader';
import { SaveReportFieldValues } from '../report-field-types';
import { validationSchema } from '../report-fields-detail/report-field-detail-header/report-field-detail-header-action-dialogs/EditReportFieldDialog';
import { useReportFieldDetailHeaderActions } from '../report-fields-detail/report-field-detail-header/report-field-detail-header-actions';
import { ReportFieldFormContent } from './ReportFieldFormContent';

export const CreateReportFieldForm = () => {
  const [query, setQuery] = useQueryParams({
    createReportField: BooleanParam,
  });

  // const history = useHistory();
  const { runSaveReportField, runSaveReportFieldLoading } = useReportFieldDetailHeaderActions();

  // const handleCreateReportFieldSuccess = (newReportFieldId: string) => {
  //   history.replace(`${DEFAULT_RETURN_SLUG}?tab=report-fields&reportFieldId=${newReportFieldId}`);
  // };

  return (
    <Formik<SaveReportFieldValues>
      initialValues={{
        __typename: 'VOLUNTEER_NumericFieldType',
        name: '',
        fieldId: undefined,
        description: '',
        isHero: undefined,
        isLongText: undefined,
        isAllowMultiple: undefined,
        dropdownOptions: undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        runSaveReportField({ values, handleSuccess: () => setQuery({ ...query, createReportField: undefined }) })
      }
    >
      {() => {
        return (
          <PaneWrapper>
            <Pane>
              <Form style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', height: 'auto' }}>
                <StickyHeader
                  handleDiscard={() => setQuery({ ...query, createReportField: undefined })}
                  saveButtonName='Save Report Field'
                  closeButtonName='Cancel'
                  saveButtonProps={{
                    variant: 'contained',
                    color: 'primary',
                    disabled: runSaveReportFieldLoading,
                  }}
                />
                <PaneScrollable>
                  <Box display='flex' flex='1 1 auto' flexDirection='column' padding={4} height='auto'>
                    <Typography variant='h4' component='h1' display='inline'>
                      Report Field
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} style={{ padding: '16px 0' }}>
                        <ReportFieldFormContent />
                      </Grid>
                    </Grid>
                  </Box>
                </PaneScrollable>
              </Form>
            </Pane>
          </PaneWrapper>
        );
      }}
    </Formik>
  );
};
