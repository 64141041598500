import { Checkbox, ListItemText, MenuItem } from '@material-ui/core';
import React from 'react';

export const CheckBoxItem = ({
  label,
  value,
  checked,
  onClick,
}: {
  label: string;
  value: string;
  checked: boolean;
  onClick: () => void;
}) => {
  return (
    <MenuItem key={value} value={value} style={{ padding: '0' }} onClick={onClick}>
      <Checkbox
        value={value}
        checked={checked}
        size='small'
        style={{ color: '#F2F2F2', padding: '10px 12px' }}
        disableRipple
      />
      <ListItemText primary={label} style={{ color: '#C9C8CC', fontSize: '14px', marginRight: '90px' }} />
    </MenuItem>
  );
};
