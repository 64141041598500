import { gql } from '@apollo/client';

export const GET_INCIDENT_MANAGEMENT_INCIDENTS_DATA = gql`
  query GetIncidentManagementIncidentsData($volunteerId: String, $includeVolunteer: Boolean!) {
    vm {
      privilegedPrograms: programs(hasRule: { rule: ReadIncident }) {
        programId
        name
        incidents {
          ...IncidentManagementIncidentCompact
        }
      }
      volunteer(volunteerId: $volunteerId) @include(if: $includeVolunteer) {
        programs {
          programId
          name
          incidents {
            ...IncidentManagementIncidentCompact
          }
        }
      }
    }
  }

  fragment IncidentManagementIncidentCompact on VOLUNTEER_IncidentType {
    incidentId
    incidentStatus
    dateWorking
    dateClosed
    closingComments
    program {
      programId
      name
    }
    incidentReports {
      incidentReportId
      incidentDateTime
      submissionDateTime
      referenceNumber
      othersInvolved
      description
      furtherAction
      severity {
        incidentSeverityId
        name
        color
      }
      category {
        label
        details {
          incidentDetailId
          label
        }
      }
      detail {
        ...IncidentManagementSingleIncidentDetail
      }
      incidentLocation {
        __typename
        incidentLocationId
        ... on VOLUNTEER_ActivityIncidentLocationType {
          activity {
            name
            activityLocation {
              placesAddress {
                description
              }
            }
          }
        }
        ... on VOLUNTEER_AddressIncidentLocationType {
          address {
            suburb
            humanReadable
          }
        }
      }
      submittedBy {
        volunteerId
        profile {
          profileId
          preferredName
          lastName
          avatarUrl
          email
        }
      }
      volunteersInvolved {
        volunteerId
        profile {
          profileId
          preferredName
          lastName
          avatarUrl
        }
      }
      resources {
        imageId: incidentReportResourceId
        imageUrl: resourceUrl
      }
      histories {
        incidentReportHistoryId
        description
        updatedBy {
          volunteerId
          profile {
            profileId
            preferredName
            lastName
            avatarUrl
            email
          }
        }
        dateUpdated
      }
    }
  }

  fragment IncidentManagementSingleIncidentDetail on VOLUNTEER_IncidentDetailType {
    incidentDetailId
    label
    incidentCategory {
      ...IncidentManagementSingleIncidentIncidentCategoryGroups
    }
  }

  fragment IncidentManagementSingleIncidentIncidentCategoryGroups on VOLUNTEER_IncidentCategoryType {
    incidentCategoryId
    label
    incidentCategoryGroup {
      ...IncidentManagementSingleIncidentIncidentCategoryGroup
    }
  }

  fragment IncidentManagementSingleIncidentIncidentCategoryGroup on VOLUNTEER_IncidentCategoryGroupType {
    incidentCategoryGroupId
    label
  }
`;

export const GET_INCIDENT_MANAGEMENT_FILTER_INCIDENT_SEVERITIES = gql`
  query GetIncidentManagementFilterIncidentSeverities {
    vm {
      incidentSeverities {
        ...IncidentManagementFilterIncidentSeverity
      }
    }
  }
  fragment IncidentManagementFilterIncidentSeverity on VOLUNTEER_IncidentSeverityType {
    incidentSeverityId
    name
    order
  }
`;

export const GET_INCIDENT_MANAGEMENT_SINGLE_INCIDENT = gql`
  query GetIncidentManagementSingleIncident($incidentId: String!) {
    vm {
      incident(incidentId: $incidentId) {
        ...IncidentManagementSingleIncident
      }
    }
  }
  fragment IncidentManagementSingleIncident on VOLUNTEER_IncidentType {
    incidentId
    incidentStatus
    dateWorking
    dateClosed
    closingComments
    program {
      programId
      name
    }
    incidentReports {
      severity {
        incidentSeverityId
        name
        color
      }
      category {
        label
        details {
          incidentDetailId
          label
        }
      }
      incidentReportId
      incidentDateTime
      submissionDateTime
      referenceNumber
      othersInvolved
      description
      furtherAction
      detail {
        ...IncidentManagementSingleIncidentDetail
      }
      incidentLocation {
        __typename
        incidentLocationId
        ... on VOLUNTEER_ActivityIncidentLocationType {
          activity {
            name
            activityLocation {
              placesAddress {
                description
              }
            }
          }
        }
        ... on VOLUNTEER_AddressIncidentLocationType {
          address {
            suburb
            humanReadable
          }
        }
      }
      submittedBy {
        volunteerId
        profile {
          userId
          profileId
          preferredName
          lastName
          avatarUrl
          email
        }
      }
      volunteersInvolved {
        volunteerId
        profile {
          userId
          profileId
          preferredName
          lastName
          avatarUrl
        }
      }
      resources {
        imageId: incidentReportResourceId
        imageUrl: resourceUrl
      }
      histories {
        incidentReportHistoryId
        description
        updatedBy {
          volunteerId
          profile {
            userId
            profileId
            preferredName
            lastName
            avatarUrl
            email
          }
        }
        dateUpdated
      }
      comments {
        commentId
        parentId
        content
        submittedDate
        removedDate
        attachments {
          attachmentId
          fileType
          url
          name
        }
        volunteer {
          volunteerId
          profile {
            preferredName
            lastName
            avatarUrl
          }
        }
        reactions {
          reactionId
          emoji
          volunteer {
            volunteerId
          }
        }
      }
    }
  }
  fragment IncidentManagementSingleIncidentDetail on VOLUNTEER_IncidentDetailType {
    incidentDetailId
    label
    incidentCategory {
      ...IncidentManagementSingleIncidentIncidentCategoryGroups
    }
  }

  fragment IncidentManagementSingleIncidentIncidentCategoryGroups on VOLUNTEER_IncidentCategoryType {
    incidentCategoryId
    label
    incidentCategoryGroup {
      ...IncidentManagementSingleIncidentIncidentCategoryGroup
      parentGroup {
        ...IncidentManagementSingleIncidentIncidentCategoryGroup
        parentGroup {
          ...IncidentManagementSingleIncidentIncidentCategoryGroup
          parentGroup {
            ...IncidentManagementSingleIncidentIncidentCategoryGroup
            parentGroup {
              ...IncidentManagementSingleIncidentIncidentCategoryGroup
              parentGroup {
                ...IncidentManagementSingleIncidentIncidentCategoryGroup
                parentGroup {
                  ...IncidentManagementSingleIncidentIncidentCategoryGroup
                  parentGroup {
                    ...IncidentManagementSingleIncidentIncidentCategoryGroup
                    parentGroup {
                      ...IncidentManagementSingleIncidentIncidentCategoryGroup
                      parentGroup {
                        ...IncidentManagementSingleIncidentIncidentCategoryGroup
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment IncidentManagementSingleIncidentIncidentCategoryGroup on VOLUNTEER_IncidentCategoryGroupType {
    incidentCategoryGroupId
    label
  }
`;
