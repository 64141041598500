import React from 'react';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { DASHBOARD_WIDGET_GET_FIELDS } from './dashboard-widget-setting/my-dashboard-widget.gql';
import { DashboardWidgetGetFields } from './dashboard-widget-setting/__generated__/DashboardWidgetGetFields';

export const WidgetDisplayContext = React.createContext<{
  reportFields: { [key: string]: string };
}>({
  reportFields: {},
});

export const WidgetDisplayContextProvider = ({ children }: { children: React.ReactElement }) => {
  const [reportFields, setReportFields] = React.useState({});
  const [queryReportFields] = useCampfireLazyQuery<DashboardWidgetGetFields, {}>(DASHBOARD_WIDGET_GET_FIELDS);
  React.useEffect(() => {
    queryReportFields().then((response) => {
      setReportFields(
        response.data?.vm.allFields.reduce((acc, field) => ({ ...acc, [field.fieldId]: field.name }), {}) || {}
      );
    });
  }, []);

  return <WidgetDisplayContext.Provider value={{ reportFields }}>{children}</WidgetDisplayContext.Provider>;
};
