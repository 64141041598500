import { FileUploadGallery, FugFile } from '@campfire/file-upload-gallery';
import { TabletButton } from '@campfire/tablet-button';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';
import { useProgramManagementContext } from '../../ProgramManagementContext';
import { LockedSectionContent } from './LockedSectionContent';
import {
  ProgramManagementGetSingleProgramImage,
  ProgramManagementGetSingleProgramImageVariables,
} from '../../__generated__/ProgramManagementGetSingleProgramImage';
import { PROGRAM_MANAGEMENT_GET_SINGLE_PROGRAM_IMAGE } from '../../program-management.gql';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';

const ProgramImageUploadSectionDescription = () => (
  <Typography variant='body2' color='textSecondary' style={{ marginBottom: 8 }}>
    Upload a display image for your volunteers to see when they apply for this program.
    <br />
    <br />
    *.jpeg, .jpg, .png only.
  </Typography>
);

export const ProgramImageUploadSectionLocked = () => (
  <FormSectionV2 title='Image Attachment' description={<ProgramImageUploadSectionDescription />}>
    <LockedSectionContent text='Please save Basic Info before adding an image' />
  </FormSectionV2>
);

export const ProgramImageUploadSection = () => {
  const { runUpdateProgramImage, updateProgramImageLoading } = useProgramManagementContext();
  const [selectedProgramId] = useQueryParam('pid', StringParam);

  const { data: getProgramImageData, loading: getProgramImageLoading, refetch: refetchProgramImage } = useCampfireQuery<
    ProgramManagementGetSingleProgramImage,
    ProgramManagementGetSingleProgramImageVariables
  >(PROGRAM_MANAGEMENT_GET_SINGLE_PROGRAM_IMAGE, {
    options: {
      variables: {
        programId: selectedProgramId,
      },
    },
  });
  const selectedProgram = getProgramImageData?.vm.program;
  const imageUrl = selectedProgram?.image;
  const isLoading = getProgramImageLoading || updateProgramImageLoading;
  const uploadedFiles = imageUrl
    ? [
        {
          fileId: imageUrl,
          url: imageUrl,
          name: imageUrl,
        },
      ]
    : [];

  const initialValues = {
    images: uploadedFiles,
  };

  const handleSuccess = () => {
    if (refetchProgramImage) refetchProgramImage();
  };

  return (
    <FormSectionV2 title='Image Attachment' description={<ProgramImageUploadSectionDescription />}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(vals: any) => {
          if (selectedProgramId) {
            runUpdateProgramImage(selectedProgramId, vals.images[0], handleSuccess);
          }
        }}
      >
        {({ values, setFieldValue }) => {
          const imageUnchanged = isEqual(initialValues.images, values.images);
          return (
            <Form>
              <Grid container direction='column' spacing={2}>
                {isLoading ? (
                  <Skeleton
                    variant='rect'
                    width='85%'
                    height='62px'
                    style={{ borderRadius: '8px', marginBottom: '8px' }}
                  />
                ) : (
                  <Grid item xs={12}>
                    <FileUploadGallery
                      id='images'
                      allowMultiple={false}
                      uploadedFiles={uploadedFiles ?? []}
                      handleFilesAdded={(files: FugFile[]) => {
                        setFieldValue('images', files);
                      }}
                      handleFileRemoved={(removedFile: FugFile) => {
                        const filteredImages = values.images.filter((image: any) => {
                          if (image.fileId) return image.fileId !== removedFile.id;
                          return image.id !== removedFile.id;
                        });
                        setFieldValue('images', filteredImages);
                      }}
                    />
                  </Grid>
                )}
                <Grid item container spacing={1} xs={12} alignItems='center' justify='flex-end'>
                  <Grid item>
                    <TabletButton
                      size='medium'
                      variant='contained'
                      color='primary'
                      type='submit'
                      disabled={isLoading || imageUnchanged}
                    >
                      Save Image
                    </TabletButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </FormSectionV2>
  );
};
