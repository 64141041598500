import { TabletButton } from '@campfire/tablet-button';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import { CloseRounded, EditOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { ReactNode, CSSProperties } from 'react';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { ActionsHeader } from '../../../../../common/ActionsHeader';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { REPORT_MANAGEMENT_GET_SINGLE_REPORT_TYPE } from '../report-types-model.gql';
import {
  ReportManagementGetSingleReportType,
  ReportManagementGetSingleReportTypeVariables,
} from '../__generated__/ReportManagementGetSingleReportType';
import { ActivitiesUsingReportType } from './ActivitiesUsingReportType';
import { ReportTypeDetailFields } from './ReportTypeDetailFields';
import { ReportTypeDetailSkeleton } from './ReportTypeDetailSkeleton';
import { ReportTypeStatus } from './ReportTypeStatus';

type Props = {
  reportTypeId: string;
};

interface StyleProps extends CSSProperties {
  isMobile?: boolean;
}

export const useSectionStyles = makeStyles((theme: Theme) => {
  return {
    sectionContainer: {
      marginTop: 24,
      width: (props: StyleProps) => (props.isMobile ? '100%' : '47%'),
    },
    sectionTitle: {
      color: theme.color.grey.neutral400,
      fontWeight: 700,
    },
    sectionBody: {
      color: theme.color.grey.neutral300,
      fontSize: '13px',
    },
  };
});

export const SectionItem = ({
  title,
  sideItem,
  text,
  children,
}: {
  title: string;
  sideItem?: ReactNode;
  text?: string;
  children?: ReactNode;
}) => {
  const { isMobile } = useCampfireTheme();
  const classes = useSectionStyles(isMobile);
  return (
    <Box className={classes.sectionContainer}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography className={classes.sectionTitle}>{title}</Typography>
        {sideItem}
      </Box>
      {text && <Typography className={classes.sectionBody}>{text}</Typography>}
      {children}
    </Box>
  );
};

export const ReportTypeDetail = ({ reportTypeId }: Props) => {
  const [query, setQuery] = useQueryParams({
    reportTypeId: StringParam,
    editReportType: BooleanParam,
  });
  const { theme, isMobile } = useCampfireTheme();

  const { data: getSingleReportTypeData, loading: getSingleReportTypeLoading } = useCampfireQuery<
    ReportManagementGetSingleReportType,
    ReportManagementGetSingleReportTypeVariables
  >(REPORT_MANAGEMENT_GET_SINGLE_REPORT_TYPE, {
    options: {
      variables: {
        reportTypeId: reportTypeId,
        withForm: false,
      },
    },
  });

  if (getSingleReportTypeLoading) return <ReportTypeDetailSkeleton />;

  const reportTypeData = getSingleReportTypeData?.vm.reportType;

  return reportTypeData ? (
    <Box width='100%' display='inline-grid'>
      <ActionsHeader title={reportTypeData.name}>
        <TabletButton
          size='small'
          variant='outlined'
          color='primary'
          onClick={() => setQuery({ ...query, editReportType: true })}
          style={{
            border: '1px solid #9e9e9e',
            boxSizing: 'border-box',
            borderRadius: '4px',
            padding: '2px 12px',
            color: theme.color.grey.neutral300,
          }}
        >
          <EditOutlined style={{ marginTop: '1px', fontSize: '16px' }} />
          <Typography
            style={{
              marginLeft: '8px',
              fontWeight: 600,
              fontSize: '13px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            Edit
          </Typography>
        </TabletButton>
        {isMobile ? (
          <Button
            style={{
              border: '1px solid #FF4F0F',
              boxSizing: 'border-box',
              borderRadius: '4px',
              textTransform: 'none',
              color: '#FF4F0F',
              padding: '3px 12px',
              marginLeft: '4px',
            }}
            onClick={() => setQuery({ ...query, reportTypeId: undefined })}
          >
            <CloseRounded style={{ fontSize: '18px' }} />
          </Button>
        ) : null}
      </ActionsHeader>
      <Box display={'flex'} style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {reportTypeData.description && <SectionItem title='Description' text={reportTypeData.description} />}
        <ReportTypeStatus isDeactivated={Boolean(reportTypeData.dateDeactivated)} />
        <ReportTypeDetailFields items={reportTypeData.items} />
        <ActivitiesUsingReportType activities={reportTypeData.activities} />
      </Box>
    </Box>
  ) : null;
};
