import { TabletButton } from '@campfire/tablet-button';
import { Button, Menu, MenuItem, Box, IconButton } from '@material-ui/core';
import { CloseRounded, ExpandMore, MoreHoriz } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { ActionsHeader } from '../../../../../common/ActionsHeader';
import { assertNever } from '../../../../../common/functions/assert-never';
import PrivilegeRules from '../../../../../global/auth/PrivilegeRules';
import { useUser } from '../../../../../global/auth/useUser';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { IncidentManagementIncidentActionMenuOption, IncidentStatusType } from '../../incident-management-types';
import { actionMenuOptionLabels, incidentStatusTextForDisplay } from '../../IncidentManagementConsts';
import { useIncidentDetailHeaderActions } from './incident-detail-header-actions';
import { ArchiveIncidentDialog } from './incident-detail-header-action-dialogs/ArchiveIncidentDialog';
import { ClosingIncidentCommentsDialog } from './incident-detail-header-action-dialogs/ClosingIncidentCommentsDialog';
import { useCopyLinkAction } from '../../../../../common/CopyToClipboard';

const useStyles = makeStyles(() => {
  return {
    paper: {
      marginTop: 4,
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        background: '#282729',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  };
});

const getIncidentActionMenuOptions = (
  incidentStatus: string,
  incidentProgramId: string
): Array<IncidentManagementIncidentActionMenuOption> => {
  const actions: Array<IncidentManagementIncidentActionMenuOption> = [];

  const {
    user: { userIdentityService },
  } = useUser();

  if (incidentStatus === 'new') {
    const canWork = userIdentityService.hasRuleForProgram(incidentProgramId, PrivilegeRules.workIncident);
    if (canWork) actions.push('moveToWorking');
  }
  if (incidentStatus === 'new' || incidentStatus === 'working') {
    const canClose = userIdentityService.hasRuleForProgram(incidentProgramId, PrivilegeRules.closeIncident);
    if (canClose) actions.push('moveToClosed');
  }
  if (incidentStatus === 'new' || incidentStatus === 'working') {
    const canDelete = userIdentityService.hasRuleForProgram(incidentProgramId, PrivilegeRules.archiveIncident);
    if (canDelete) actions.push('moveToArchive');
  }

  return actions;
};

type Props = {
  incidentId: string;
  incidentStatus: IncidentStatusType;
  sidebarTitle: string;
  referenceNumber: string;
  incidentProgramId: string;
  getIncidentsRefetch: () => void;
  handleExport: () => void;
};

export const IncidentDetailHeader = ({
  incidentId,
  incidentStatus,
  incidentProgramId,
  sidebarTitle,
  referenceNumber,
  getIncidentsRefetch,
  handleExport,
}: Props) => {
  const [incidentStatusMenuAnchorEl, setIncidentStatusMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [closingCommentsDialogOpen, setClosingCommentsDialogOpen] = useState<boolean>(false);
  const [archiveIncidentDialogOpen, setArchiveIncidentDialogOpen] = useState<boolean>(false);
  const [activityEditMenuAnchorEl, setActivityEditMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [query, setQuery] = useQueryParams({
    incidentId: StringParam,
  });

  const { theme, isMobile } = useCampfireTheme();
  const classes = useStyles();
  const { runMoveIncidentToInProgress } = useIncidentDetailHeaderActions();
  const { handleCopyLinkClick } = useCopyLinkAction();

  const handleOpenIncidentStatusMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIncidentStatusMenuAnchorEl(event.currentTarget);
  };

  const handleCloseIncidentStatusMenu = () => {
    setIncidentStatusMenuAnchorEl(null);
  };

  const handleActionMenuItemClick = (option: IncidentManagementIncidentActionMenuOption) => {
    handleCloseIncidentStatusMenu();

    if (option === 'moveToWorking') {
      runMoveIncidentToInProgress({
        incidentId,
        handleSuccess: () => {
          if (getIncidentsRefetch) getIncidentsRefetch();
          if (isMobile) setQuery({ incidentId: undefined });
        },
      });
      return;
    }

    if (option === 'moveToClosed') {
      setClosingCommentsDialogOpen(true);
      return;
    }

    if (option === 'moveToArchive') {
      setArchiveIncidentDialogOpen(true);
      return;
    }
    assertNever(option);
  };

  const handleOpenActivityEditMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActivityEditMenuAnchorEl(event.currentTarget);
  };

  const handleCloseActivityEditMenu = () => {
    setActivityEditMenuAnchorEl(null);
  };

  const actionMenuOptions = getIncidentActionMenuOptions(incidentStatus, incidentProgramId);

  return (
    <>
      <ActionsHeader title={sidebarTitle} subtitle={`Reference: ${referenceNumber}`}>
        <>
          <TabletButton
            size='small'
            variant='outlined'
            color='primary'
            aria-controls='incident-status-menu'
            aria-haspopup='true'
            onClick={handleOpenIncidentStatusMenu}
            endIcon={actionMenuOptions.length > 0 ? <ExpandMore /> : null}
            disabled={actionMenuOptions.length === 0}
            style={{
              border: '1px solid #9e9e9e',
              boxSizing: 'border-box',
              borderRadius: '4px',
              padding: '2px 7px',
              color: theme.color.grey.neutral300,
              marginRight: '8px',
              marginLeft: '8px',
              minWidth: '100px',
            }}
          >
            {incidentStatusTextForDisplay[incidentStatus]}
          </TabletButton>

          <Menu
            id='incident-status-menu'
            anchorEl={incidentStatusMenuAnchorEl}
            open={Boolean(incidentStatusMenuAnchorEl)}
            onClose={handleCloseIncidentStatusMenu}
            getContentAnchorEl={null}
            PaperProps={{
              elevation: 0,
              style: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))',
                marginTop: 1.5,
                background: '#282729',
                color: '#C9C8CC',
              },
            }}
            PopoverClasses={{ paper: classes.paper }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {actionMenuOptions.map((action) => (
              <MenuItem key={action} onClick={() => handleActionMenuItemClick(action)}>
                {actionMenuOptionLabels[action]}
              </MenuItem>
            ))}
          </Menu>
          <Box
            style={{
              border: '1px solid #9e9e9e',
              boxSizing: 'border-box',
              borderRadius: '4px',
              marginRight: '10px',
            }}
          >
            <IconButton
              color='primary'
              aria-label='activity-action-menu'
              component='span'
              onClick={handleOpenActivityEditMenu}
              style={{ color: theme.color.grey.neutral300, padding: '0px 2px', fontSize: '26px', borderRadius: 'none' }}
            >
              <MoreHoriz fontSize={'inherit'} style={{ borderRadius: 'none' }} />
            </IconButton>
          </Box>
          <Menu
            id='incident-edit-menu'
            anchorEl={activityEditMenuAnchorEl}
            keepMounted
            open={Boolean(activityEditMenuAnchorEl)}
            onClose={handleCloseActivityEditMenu}
            getContentAnchorEl={null}
            PaperProps={{
              elevation: 0,
              style: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))',
                marginTop: 1.5,
                background: '#282729',
                color: '#C9C8CC',
              },
            }}
            PopoverClasses={{ paper: classes.paper }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Link
              to={`/volunteering/incident-report?incidentId=${incidentId}&pid=${incidentProgramId}`}
              style={{ textDecoration: 'none', color: '#C9C8CC' }}
            >
              <MenuItem>{'Edit'}</MenuItem>
            </Link>
            <MenuItem onClick={handleExport}>{'Export CSV'}</MenuItem>
            <MenuItem onClick={handleCopyLinkClick}>Copy Link</MenuItem>
          </Menu>
        </>
        {isMobile ? (
          <Button
            style={{
              border: '1px solid #FF4F0F',
              boxSizing: 'border-box',
              borderRadius: '4px',
              textTransform: 'none',
              color: '#FF4F0F',
              padding: '3px 12px',
            }}
            onClick={() => setQuery({ ...query, incidentId: undefined })}
          >
            <CloseRounded style={{ fontSize: '18px' }} />
          </Button>
        ) : null}
      </ActionsHeader>
      <ClosingIncidentCommentsDialog
        handleClose={() => setClosingCommentsDialogOpen(false)}
        closingCommendsDialogOpen={closingCommentsDialogOpen}
        incidentId={incidentId}
        getIncidentsRefetch={getIncidentsRefetch}
      />
      <ArchiveIncidentDialog
        handleClose={() => setArchiveIncidentDialogOpen(false)}
        archiveIncidentDialogOpen={archiveIncidentDialogOpen}
        incidentId={incidentId}
        getIncidentsRefetch={getIncidentsRefetch}
      />
    </>
  );
};
