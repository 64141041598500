import { Box, Chip, Grid } from '@material-ui/core';
import { connect } from 'formik';
import React, { Fragment } from 'react';
import { Avatar } from '../../screens/general/user-profile/components/Avatar';
import { ActivityType, Volunteer } from './person-selector-model';
import { PersonListDialog } from './PersonListDialog';

const PersonSelector = connect<{
  selectedActivity: ActivityType | undefined;
  volunteers: Array<Volunteer>;
  othersInvolved?: Array<string>;
  teamIds: Array<string>;
  showDialog: boolean;
  setShowDialog: (x: boolean) => void;
  teamMemberField: string;
  otherPersonField?: string;
}>(
  ({
    selectedActivity,
    volunteers,
    othersInvolved,
    teamIds,
    showDialog,
    setShowDialog,
    teamMemberField,
    otherPersonField,
    formik: { setFieldValue },
  }) => {
    const removeTeamMember = (teamMemberId: string) => {
      if (!teamIds.includes(teamMemberId)) return;
      setFieldValue(
        teamMemberField,
        teamIds.filter((x) => x !== teamMemberId)
      );
    };

    const removeOtherPersonInvolved = (name: string) => {
      if (othersInvolved && !othersInvolved.includes(name)) return;
      if (otherPersonField && othersInvolved)
        setFieldValue(
          otherPersonField,
          othersInvolved.filter((x) => x !== name)
        );
    };

    const personListSubmit = (volunteerIds: Array<string>) => {
      setFieldValue(teamMemberField, [...teamIds, ...volunteerIds]);
    };

    return (
      <Fragment>
        <Grid container spacing={1}>
          {teamIds.map((teamMemberId) => {
            const volunteer = volunteers.find((x) => x.volunteerId === teamMemberId);
            if (!volunteer) return null;
            return (
              <Grid item key={volunteer.volunteerId} xs={12} sm='auto'>
                <Chip
                  color='primary'
                  avatar={
                    <Avatar
                      size={32}
                      avatarUrl={volunteer.profile.avatarUrl}
                      preferredName={volunteer.profile.preferredName}
                      lastName={volunteer.profile.lastName}
                    />
                  }
                  label={`${volunteer.profile.preferredName} ${volunteer.profile.lastName}`}
                  onDelete={() => {
                    removeTeamMember(volunteer.volunteerId);
                  }}
                />
              </Grid>
            );
          })}
          {othersInvolved &&
            othersInvolved.map((otherPerson) => {
              return (
                <Grid item key={otherPerson} xs={12} sm='auto'>
                  <Chip
                    avatar={<Avatar size={32} />}
                    label={otherPerson}
                    onDelete={() => {
                      removeOtherPersonInvolved(otherPerson);
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>

        <Box marginTop={2} position='relative'>
          <PersonListDialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            selectedActivity={selectedActivity}
            volunteers={volunteers.filter((x) => !teamIds.includes(x.volunteerId))}
            onSubmit={personListSubmit}
          />
        </Box>
      </Fragment>
    );
  }
);

export { PersonSelector };
