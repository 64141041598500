import React from 'react';
import { useFormikContext } from 'formik';
import { SwitchField } from './SwitchField';
import { AddressTaskField } from '../../../../__generated__/AddressTaskField';

type AddressValues = {
  field: AddressTaskField;
};

export const AddressAdditionalField = () => {
  const { values, setFieldValue } = useFormikContext<AddressValues>();
  return (
    <SwitchField
      title='Disable manual address entry'
      description='Turn this on to disable the manual entry option for this address field'
      checked={!values.field.allowManual}
      onChange={() => setFieldValue('field.allowManual', !values.field.allowManual)}
    />
  );
};
