import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';

export interface CampfireVersionContextInterface {
  clientVersion?: string;
  serverVersion?: string;
  setServerVersion: Dispatch<SetStateAction<string | undefined>>;
  updateAvailable: boolean;
}

export const CampfireVersionContext = createContext<CampfireVersionContextInterface>({
  clientVersion: undefined,
  serverVersion: undefined,
  setServerVersion: () => {},
  updateAvailable: false,
});

export interface CampfireVersionProviderProps {
  children: React.ReactNode;
}

export const CampfireVersionProvider = ({ children }: CampfireVersionProviderProps) => {
  const [clientVersion] = useState(process.env.REACT_APP_VOLABY_VERSION);
  const [serverVersion, setServerVersion] = useState<string | undefined>();
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    setUpdateAvailable(!!clientVersion && !!serverVersion && clientVersion !== serverVersion);
  }, [clientVersion, serverVersion, updateAvailable]);

  return (
    <CampfireVersionContext.Provider value={{ clientVersion, serverVersion, setServerVersion, updateAvailable }}>
      {children}
    </CampfireVersionContext.Provider>
  );
};
