function getIsActivityEnded(activity?: { __typename: string; isActive: boolean; nextX: Array<any>; isSuspended: boolean }): boolean {
  if (!activity) return false;
  if (activity.isSuspended) return false;
  if (activity.__typename === 'VOLUNTEER_RecurringActivityType') {
    return !activity.isActive && activity.nextX.length === 0;
  }
  return !activity.isActive;
}

export { getIsActivityEnded };
