import { useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { WidgetContext } from './WidgetContext';
import { CreatWidgetFormValues } from './dashboard-widget-model';

export const useWidgetContextForm = () => {
  const { programs, activityTags, reportFields } = useContext(WidgetContext);
  const { values } = useFormikContext<CreatWidgetFormValues>();

  const activities = useMemo(() => {
    let activityOptions = [];
    if (values.programIds === null || values.programIds.length === 0) {
      activityOptions = programs.flatMap((p) => p.activities).map((a) => ({ value: a.activityId, label: a.name }));
    } else {
      activityOptions = programs
        .filter((p) => values.programIds.includes('all') || values.programIds.includes(p.programId))
        .flatMap((p) => p.activities)
        .map((a) => ({ value: a.activityId, label: a.name }));
    }

    return activityOptions.concat([
      {
        label: 'All activities',
        value: 'all',
      },
      {
        label: 'None of activities',
        value: 'none',
      },
    ]);
  }, [values.programIds, programs]);

  const programOptions = programs
    .map((p) => ({ value: p.programId, label: p.name }))
    .concat([
      {
        label: 'All programs',
        value: 'all',
      },
      {
        label: 'None of programs',
        value: 'none',
      },
    ]);

  const activityTagOptions = activityTags
    .map((at) => ({ value: at.activityTagId, label: at.name }))
    .concat([
      {
        label: 'All activity tags',
        value: 'all',
      },
      {
        label: 'None of activity tags',
        value: 'none',
      },
    ]);

  const reportFieldsOptions = reportFields
    .map((rf) => ({ value: rf.fieldId, label: rf.name }))
    .concat([
      {
        label: 'All report fields',
        value: 'all',
      },
      {
        label: 'None of report fields',
        value: 'none',
      },
    ]);

  return {
    programs: programOptions,
    activityTags: activityTagOptions,
    activities,
    reportFields: reportFieldsOptions,
  };
};
