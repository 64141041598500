import { AdminConsoleIncidentDetail } from './__generated__/AdminConsoleIncidentDetail';

export interface IncidentSeverityFormValuesType {
  severities: IncidentSeverityType[];
}

export interface IncidentSeverityType {
  incidentSeverityId?: string;
  listId?: string;
  name: string;
  description: string;
  color: string;
  order: number;
}

export interface IncidentCategoryGroupType {
  __typename: 'VOLUNTEER_IncidentCategoryGroupType';
  incidentCategoryGroupId?: string;
  label: string;
  childGroups: IncidentCategoryGroupType[];
  categories: IncidentCategoryType[];
}

export interface IncidentCategoryType {
  __typename: 'VOLUNTEER_IncidentCategoryType';
  incidentCategoryId?: string;
  label: string;
  details: IncidentDetailType[];
}

export interface IncidentDetailType extends Pick<AdminConsoleIncidentDetail, 'label' | '__typename'> {
  incidentDetailId?: string;
}
export type AllIncidentCategoryType = IncidentCategoryGroupType | IncidentCategoryType | IncidentDetailType;

export const SeveritiesFormSectionContent = {
  formTitle: 'Incident Severities',
  helperText: 'Configure incident severities for classification and reporting',
  addButtonText: 'New Incident Severity',
  addSeverityDialogTitle: 'New Incident Severity',
  editSeverityDialogTitle: 'Edit Incident Severity',
  removeSeverityDialogTitle: 'Delete Incident Severity?',
  removeSeverityDialogContent: [
    'Are you sure you want to delete $severityName incident severity?',
    'Existing incident reports will not be affected.',
  ],
};

export const CategoriesSectionContent = {
  sectionTitle: 'Incident Categories',
  helperText: 'Manage incident categories, groups, and details for incident reports',
  addButtonText: 'Add new',
  addNewModalOptions: [
    {
      label: 'Category',
      secondaryText: 'Categories can hold groups and other categories',
      disabledText: 'Categories can only be added to other categories',
    },
    {
      label: 'Group',
      secondaryText: 'Groups can hold details',
      disabledText: 'Groups can only be added to categories',
    },
    {
      label: 'Detail',
      secondaryText: 'Add a detail',
      disabledText: 'Details can only be added to groups',
    },
  ],
  addGroupDialogTitle: 'Add new Incident Category',
  addGroupDialogHelperText: 'The new category will be added to',
  addCategoryDialogTitle: 'Add new Group',
  addCategoryDialogHelperText: 'The new group will be added to',
  addDetailDialogTitle: 'Add new Incident Detail',
  addDetailDialogHelperText: 'The new detail will be added to',
  removeCategoryDialogTitle: 'Delete Item?',
  removeCategoryDialogContent: [
    `This $categoryName item, along with everything inside, will no long be available in new incident reports.`,
    'Existing incident reports will not be affected.',
  ],
};

export const incidentSeverityColorOptions = [
  { name: 'Purple', hexValue: '#968EFF' },
  { name: 'Indigo', hexValue: '#584CDA' },
  { name: 'Blue', hexValue: '#2196F3' },
  { name: 'Turquoise', hexValue: '#38C5AB' },
  { name: 'Green', hexValue: '#7DD268' },
  { name: 'Light Green', hexValue: '#B1DFA6' },
  { name: 'Yellow', hexValue: '#FFE242' },
  { name: 'Amber', hexValue: '#FABE4D' },
  { name: 'Orange', hexValue: '#FF991A' },
  { name: 'Red', hexValue: '#E55555' },
  { name: 'Light Red', hexValue: '#EC8484' },
  { name: 'Pink', hexValue: '#FFBDBD' },
];

export type ColorItemType = {
  name: string;
  hexValue: string;
};

export function findGroup(searchGroup: IncidentCategoryGroupType, groupId: string): IncidentCategoryGroupType | null {
  if (searchGroup.incidentCategoryGroupId === groupId) {
    return searchGroup;
  }

  for (let i = 0; i < searchGroup.childGroups.length; i++) {
    const foundGroup = findGroup(searchGroup.childGroups[i], groupId);
    if (foundGroup) return foundGroup;
  }

  return null;
}
export function findParentGroup(
  searchGroup: IncidentCategoryGroupType,
  groupId?: string
): IncidentCategoryGroupType | null {
  for (let i = 0; i < searchGroup.childGroups.length; i++) {
    if (searchGroup.childGroups[i].incidentCategoryGroupId === groupId) {
      return searchGroup;
    }

    const foundGroup = findParentGroup(searchGroup.childGroups[i], groupId);
    if (foundGroup) return foundGroup;
  }

  return null;
}
export function findCategory(
  searchGroup: IncidentCategoryGroupType | IncidentCategoryType,
  groupId: string
): IncidentCategoryType | null {
  if (searchGroup.__typename === 'VOLUNTEER_IncidentCategoryType' && searchGroup.incidentCategoryId === groupId) {
    return searchGroup;
  }

  if (searchGroup.__typename === 'VOLUNTEER_IncidentCategoryGroupType') {
    for (let i = 0; i < searchGroup.childGroups.length; i++) {
      const foundCategory = findCategory(searchGroup.childGroups[i], groupId);
      if (foundCategory) return foundCategory;
    }

    for (let i = 0; i < searchGroup.categories.length; i++) {
      const foundCategory = findCategory(searchGroup.categories[i], groupId);
      if (foundCategory) return foundCategory;
    }
  }

  return null;
}

export function findParentOfCategory(
  searchGroup: IncidentCategoryGroupType,
  categoryId: string
): IncidentCategoryGroupType | null {
  for (let i = 0; i < searchGroup.categories.length; i++) {
    if (searchGroup.categories[i].incidentCategoryId === categoryId) {
      return searchGroup;
    }
  }

  for (let i = 0; i < searchGroup.childGroups.length; i++) {
    const foundGroup = findParentOfCategory(searchGroup.childGroups[i], categoryId);
    if (foundGroup) return foundGroup;
  }

  return null;
}

export function findParentCategoryOfDetail(
  searchGroup: IncidentCategoryGroupType,
  detailId: string
): IncidentCategoryType | null {
  for (let i = 0; i < searchGroup.categories.length; i++) {
    const searchCategory = searchGroup.categories[i];
    for (let j = 0; j < searchCategory.details.length; j++) {
      if (searchCategory.details[j].incidentDetailId === detailId) {
        return searchCategory;
      }
    }
  }

  for (let i = 0; i < searchGroup.childGroups.length; i++) {
    const foundCategory = findParentCategoryOfDetail(searchGroup.childGroups[i], detailId);
    if (foundCategory) return foundCategory;
  }

  return null;
}
export const isOptionDisabled = (label: string, type: string) => {
  if (type === 'VOLUNTEER_IncidentCategoryGroupType') {
    if (label === 'Detail') return true;
  }

  if (type === 'VOLUNTEER_IncidentCategoryType') {
    if (label === 'Group' || label === 'Category') return true;
  }

  return false;
};

export function getBreadcrumbsForGroup(
  rootGroup: IncidentCategoryGroupType,
  groupId: string
): IncidentCategoryGroupType[] {
  const breadcrumbs: IncidentCategoryGroupType[] = [];

  const foundGroup = findGroup(rootGroup, groupId);
  if (!foundGroup) return breadcrumbs;

  breadcrumbs.push(foundGroup);
  let parentGroup = findParentGroup(rootGroup, groupId);
  while (parentGroup) {
    breadcrumbs.push(parentGroup);
    parentGroup = findParentGroup(rootGroup, parentGroup.incidentCategoryGroupId);
  }

  return breadcrumbs.reverse();
}

export function getBreadcrumbsForCategory(
  rootGroup: IncidentCategoryGroupType,
  categoryId: string
): Array<IncidentCategoryGroupType | IncidentCategoryType> {
  const breadcrumbs: Array<IncidentCategoryGroupType | IncidentCategoryType> = [];

  const foundCategory = findCategory(rootGroup, categoryId);
  if (!foundCategory) return breadcrumbs;

  breadcrumbs.push(foundCategory);
  let parentGroup = findParentOfCategory(rootGroup, categoryId);
  while (parentGroup) {
    breadcrumbs.push(parentGroup);
    parentGroup = findParentGroup(rootGroup, parentGroup.incidentCategoryGroupId);
  }

  return breadcrumbs.reverse();
}
