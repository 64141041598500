import { Box, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';

const IncomingVolunteersRightColumnWrapper: FunctionComponent = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} className={classes.grid}>
      <Box display='flex' flexDirection='column' minHeight={0} height='100%'>
        {children}
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `1px solid ${theme.color.grey.border}`,
    boxShadow: '0px 5px 8px rgba(114, 114, 114, 0.16), 0px 7px 14px rgba(214, 214, 214, 0.26)',
  },
}));

export { IncomingVolunteersRightColumnWrapper };
