import { gql } from '@apollo/client';

export const GET_VOLUNTEER_COMMON_PROFILE = gql`
  query GetVolunteerCommonProfile($profileId: String!, $userId: String!, $limit: Int, $offset: Int, $isHistorical: Boolean) {
    em {
      emails(userId: $userId) {
        ...VolunteerCommonProfileEmail
      }
    }
    vm {
      activityRoles {
        ...VolunteerCommonActivityRole
      }
      privilegedPrograms: programs(hasRule: { rule: ReadAllVolunteers }) {
        programId
      }
      volunteer(profileId: $profileId) {
        ...VolunteerCommonProfile
      }
      hasActivityRoles
      communicationTemplates {
        communicationTypeId
        name
        status
        type
        isHidden
      }
    }
  }
  fragment VolunteerCommonProfile on VOLUNTEER_VolunteerType {
    volunteerId
    volunteerStatistics {
      ...MyElementsSideBarVolunteerStatistics
    }
    profile {
      userId
      profileId
      preferredName
      firstName
      lastName
      email
      avatarUrl
      importOperation {
        importOperationId
        importedBy {
          profile {
            firstName
            lastName
          }
        }
        dateCreated
      }
      dateOfBirth
      comments
      contactNumber
      completedTasks(limit: $limit, offset: $offset) {
        completedTaskId
        dateSubmitted
        status
        task {
          taskId
          title
          adminOnly
        }
      }
      metadata {
        totalCompletedTasks
      }
      additionalVolunteerHours
    }
    lastTeamRemovalDate
    dateCreated
    dateDeactivated
    tasks {
      taskId
      title
      order
      adminOnly
      cake {
        cakeType
      }
      dateRemoved
    }
    flagging {
      dateFlagged
    }
    programs {
      programId
      name
    }
    rosterStatus
    activityRoles {
      ...VolunteerCommonActivityRole
    }
    activityEnrolments(isHistorical: $isHistorical) {
      ...VolunteerCommonProfileActivityEnrolment
    }
    activityApplications {
      ...VolunteerCommonProfileActivityApplication
    }
    activityWaitlistings {
      ...VolunteerCommonProfileActivityWaitlisting
    }
    flagging {
      dateFlagged
      flaggedReason
      flaggedBy {
        profile {
          profileId
          preferredName
          lastName
          avatarUrl
        }
      }
    }
    currentOnboardingStep {
      ...VolunteerCommonOnboardingStep
    }
    onboardingSteps {
      ...VolunteerCommonOnboardingStep
    }
    application {
      ...VolunteerCommonProfileApplication
    }
    rosterings {
      publishedRosteringId
      smsLogs {
        __typename
        smsLogId
        messageId
        sentAt
        messageData
        direction
      }
    }
  }

  fragment VolunteerCommonActivityRole on VOLUNTEER_ActivityRoleType {
    activityRoleId
    icon
    name
  }

  fragment VolunteerCommonBaseVolunteer on VOLUNTEER_VolunteerType {
    volunteerId
    profile {
      preferredName
      lastName
      email
      avatarUrl
      comments
    }
    rosterStatus
    lastTeamRemovalDate
    dateCreated
    activityEnrolments {
      activityEnrolmentId
      activity {
        name
        program {
          name
        }
      }
    }
    activityRoles {
      activityRoleId
    }
    programs {
      programId
    }
    currentOnboardingStep {
      ...VolunteerCommonOnboardingStep
    }
    onboardingSteps {
      ...VolunteerCommonOnboardingStep
    }
    dateDeactivated
    flagging {
      dateFlagged
    }
  }

  fragment VolunteerCommonOnboardingStep on VOLUNTEER_VolunteerOnboardingStepType {
    volunteerOnboardingStepId
    stepCompletionDate
    volunteerOnboardingStepType {
      volunteerOnboardingStepTypeId
      order
    }
  }
  fragment VolunteerCommonProfileActivityEnrolment on VOLUNTEER_ActivityEnrolmentType {
    activityEnrolmentId
    dateRemoved
    dateEnrolled
    enrolledBy {
      profile {
        profileId
        preferredName
        firstName
        lastName
      }
    }
    availabilities {
      ...VolunteerCommonProfileActivityAvailability
    }
    unavailabilities {
      ...VolunteerCommonProfileActivityUnavailability
    }
    activity {
      activityId
      name
      nextX(x: 8)
      ... on VOLUNTEER_NonRecurringActivityType {
        __typename
      }
      startTime
      endTime
      startDate
      endDate
      isRestrictedActivity
      isActive
      isSuspended
      sessions {
        sessionId
      }
      activityLocation {
        placesAddress {
          description
        }
      }
      program {
        programId
        name
      }
      publishedRosters {
        rosterId
        activityDate
        rosterings {
          publishedRosteringId
          volunteer {
            volunteerId
          }
        }
      }
      ... on VOLUNTEER_RecurringActivityType {
        schedule {
          recurrences {
            humanReadable
            startDate
            endDate
          }
        }
      }
      activityReports {
        activityDate
        sessionReports {
          sessionReportId
        }
      }
      cancelledActivities {
        ...VolunteerCommonCancelledActivity
      }
      closedActivity {
        ...VolunteerCommonClosedActivity
      }
      draftRosters {
        ...VolunteerCommonDraftRoster
      }
    }
  }
  fragment VolunteerCommonProfileActivityAvailability on VOLUNTEER_ActivityAvailabilityType {
    activityDate
    isActive
    activityRoles {
      activityRoleId
      name
    }
  }
  fragment VolunteerCommonProfileActivityUnavailability on VOLUNTEER_ActivityUnavailabilityType {
    activityDate
    isActive
  }
  fragment VolunteerCommonProfileActivityApplication on VOLUNTEER_ActivityApplicationType {
    activityApplicationId
    activity {
      activityId
      name
      program {
        programId
        name
      }
    }
  }
  fragment VolunteerCommonProfileActivityWaitlisting on VOLUNTEER_ActivityWaitlistingType {
    activityWaitlistingId
    activity {
      activityId
      name
      program {
        programId
        name
      }
    }
  }

  fragment VolunteerCommonProfileAvailableActivity on VOLUNTEER_ActivityType {
    activityId
    name
    startTime
    endTime
    activityLocation {
      placesAddress {
        description
      }
    }
    program {
      programId
      name
    }
  }

  fragment VolunteerCommonProfileApplication on VOLUNTEER_ApplicationType {
    applicationId
    name
    email
    dateSubmitted
    # todo: set-up-your-profile
    # documents {
    #   applicantDocumentId
    #   volunteerDocumentType {
    #     volunteerDocumentTypeId
    #     name
    #   }
    #   resourceUrl
    #   submission {
    #     ...Submission
    #   }
    # }
    # formSubmissions {
    #   applicantSubmissionId
    #   submission {
    #     ...Submission
    #   }
    # }
    screeningAnswers {
      screeningAnswerId
      answer
      isCorrect
    }
  }

  fragment VolunteerCommonProfileEmail on EMAIL_PostmarkOutboundEmailType {
    __typename
    postmarkEmailId
    userId
    toEmail
    subject
    sentAt
    tag
    postmarkClicks {
      clickedAt
    }
    postmarkBounce {
      bouncedAt
    }
    postmarkDelivery {
      deliveryAt
    }
    postmarkOpens {
      openedAt
    }
    postmarkSpamComplaint {
      spamComplaintAt
    }
    communicationTemplate {
      name
    }
  }
  fragment VolunteerCommonCancelledActivity on VOLUNTEER_CancelledActivityType {
    cancelledActivityId
    activityDate
  }
  fragment VolunteerCommonClosedActivity on VOLUNTEER_ClosedActivityType {
    closedActivityId
  }
  fragment VolunteerCommonDraftRoster on VOLUNTEER_DraftRosterType {
    draftRosterId
    activityDate
    publishedRoster {
      rosterId
      dateRemoved
    }
  }
  fragment MyElementsSideBarVolunteerStatistics on VOLUNTEER_VolunteerStatisticsType {
    attended
    sessionReportStatistics {
      ...MyElementsSideBarSessionReportStatistics
    }
  }
  fragment MyElementsSideBarSessionReportStatistics on VOLUNTEER_SessionReportStatisticsType {
    operationalHours
    activityLocations {
      activityLocationId
    }
  }
`;

export const GET_VOLUNTEER_PROFILE_TASKS = gql`
  query GetVolunteerProfileTasks($userId: String!) {
    vm {
      profile(userId: $userId) {
        profileId
        completedTasks {
          completedTaskId
          dateLastUpdated
          task {
            adminOnly
            taskId
            title
          }
        }
      }
    }
  }
`;

export const GET_VOLUNTEER_PROFILE_COMMUNICATION_TEMPLATES = gql`
  query GetVolunteerProfileCommunicationTemplates {
    vm {
      communicationTemplates {
        ...CommunicationTemplate
      }
    }
    im {
      communicationTemplates {
        ...CommunicationTemplate
      }
    }
  }
  fragment CommunicationTemplate on VOLUNTEER_CommunicationTemplateType {
    communicationTypeId
    name
    tag
  }
`;
