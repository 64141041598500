import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useVolunteerProfileActions } from '../../volunteer-profile-actions';

export const VolunteerCommonProfileReactivateDialog = ({
  open,
  onClose,
  handleSuccess,
  volunteerId,
  fullName,
  preferredName,
}: {
  open: boolean;
  onClose: () => void;
  handleSuccess: () => void;
  volunteerId: string;
  fullName: string;
  preferredName: string;
}) => {
  const { runReactivateVolunteer, runReactivateVolunteerLoading } = useVolunteerProfileActions();
  const { theme } = useCampfireTheme();

  return volunteerId ? (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='retire-volunteer-dialog-title'
      aria-describedby='retire-volunteer-dialog-description'
    >
      <DialogTitle id='retire-volunteer-dialog-title'>{`Reactivate Volunteer?`}</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Box paddingLeft={2} paddingRight={2}>
          <DialogContentText>{fullName}</DialogContentText>
          <DialogContentText id='retire-volunteer-dialog-description'>
            {`${preferredName} will be reactivated and notified via email.`}
          </DialogContentText>
        </Box>
        <LinearProgressOverlay isLoading={runReactivateVolunteerLoading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{`Cancel`}</Button>
        <Button
          onClick={() => runReactivateVolunteer({ volunteerId, handleSuccess })}
          autoFocus
          disabled={runReactivateVolunteerLoading}
          style={{
            color: theme.palette.error.main,
          }}
        >{`Reactivate Volunteer`}</Button>
      </DialogActions>
    </Dialog>
  ) : null;
};
