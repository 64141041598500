import { DashboardOutlined } from '@material-ui/icons';
import { GetRoutesConfig, RoutesMaps } from '../common/routes-map-model';

const route = 'warmth';

export const getFixedRoutesMap = ({ userIdentityService }: GetRoutesConfig): RoutesMaps => {
  const { isVolunteer, isActivityLeader, isProgramManager, isVmAdmin } = userIdentityService;

  return {
    route,
    heading: 'Dashboard',
    Icon: DashboardOutlined,
    isAuthorized: isVolunteer,
    items: [
      {
        label: 'My Elements',
        route: `my-elements`,
        isAuthorized: isVolunteer,
      },
      {
        label: 'Resources',
        route: 'resources',
        isAuthorized: true,
      },
      {
        label: 'Impact Dashboard',
        route: 'impact-dashboard',
        isAuthorized: isActivityLeader || isProgramManager || isVmAdmin,
      },
    ],
  };
};
