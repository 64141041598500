import React from 'react';
import { Grid } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { ResourcesList } from '../catalogue-landing-page/ResourcesList';
import { ResourcesListItem } from '../catalogue-landing-page/ResourcesListItem';
import { CategoryViewerHeader } from './CategoryViewerHeader';
import { SupportCategory } from '../__generated__/SupportCategory';
import { useSupportContext } from '../SupportContext';

interface Props {
  selectedCategory: SupportCategory;
}
const CategoryViewer = (props: Props) => {
  const { selectedCategory } = props;
  const { isAdmin } = useSupportContext();
  const match = useRouteMatch();
  return (
    <Grid container justify='center' alignItems='center' spacing={2} style={{ padding: 16 }}>
      <Grid item container xs={12} justify='flex-end'>
        {isAdmin ? (
          <Grid item>
            <Link
              data-track='resources-category-view-add-resource-button'
              to={{
                pathname: match?.url.replace(`${selectedCategory.supportCategoryId}`, `create-resource`) ?? '',
                search: `?catId=${selectedCategory.supportCategoryId}`,
                state: {
                  returnSlug: match?.url,
                },
              }}
              style={{
                textDecoration: 'none',
                textDecorationColor: 'none',
                color: 'inherit',
                width: '100%',
              }}
            >
              <TabletButton variant='contained' color='primary' endIcon={<AddIcon />}>
                {'Add Resource'}
              </TabletButton>
            </Link>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={12} md={8}>
        <CategoryViewerHeader categoryTitle={selectedCategory.title} />
      </Grid>
      <Grid item xs={12} md={8} style={{ marginTop: 32 }}>
        <ResourcesList resources={selectedCategory.supportResources}>
          {(sortedResources) => {
            return sortedResources.map((resource, idx) => (
              <Link
                data-track='resources-category-view-resource-item'
                key={resource.supportResourceId}
                to={{
                  pathname: match !== null ? `${match.url}/${resource.supportResourceId}` : '',
                  state: { returnSlug: match?.url },
                }}
                style={{ textDecoration: 'none', textDecorationColor: 'none', color: 'inherit', width: '100%' }}
              >
                <ResourcesListItem
                  resource={resource}
                  borderBottom={sortedResources.length < 2 || idx !== sortedResources.length - 1}
                  showUploadDate
                />
              </Link>
            ));
          }}
        </ResourcesList>
      </Grid>
    </Grid>
  );
};

export { CategoryViewer };
