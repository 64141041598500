import React, { useState, useMemo } from 'react';
import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import { Grid, IconButton, Typography, Box } from '@material-ui/core';
import { Delete as DeleteIcon, KeyboardArrowLeft, Email as EmailIcon, Redo } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { StatusChip } from '../../../../common/chips/StatusChip';
import { ConfirmationDialog } from '../../../../common/dialogs/ConfirmationDialog';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { FallbackAvatar } from '../../../general/user-profile/components/Avatar';
import { IncomingVolunteersApplicationTaskSection } from './components/incoming-volunteer-task-list-sections/IncomingVolunteersApplicationTaskSection';
import { IncomingVolunteersInviteTaskSection } from './components/incoming-volunteer-task-list-sections/IncomingVolunteersInviteTaskSection';
import { IncomingVolunteerTasksSection } from './components/incoming-volunteer-task-list-sections/IncomingVolunteerTasksSection';
import { TaskSideMenuBar } from './components/side-menu-content/TaskSideMenuBar';
import { IncomingVolunteersLeftColumnWrapper } from './components/wrappers/IncomingVolunteersLeftColumnWrapper';
import { IncomingVolunteersPageWrapper } from './components/wrappers/IncomingVolunteersPageWrapper';
import { IncomingVolunteersRightColumnWrapper } from './components/wrappers/IncomingVolunteersRightColumnWrapper';
import { useIncomingVolunteerActions } from './incoming-volunteer-action.gql';
import { GET_INCOMING_VOLUNTEER_APPLICATION_DETAIL, LeftMenuItemType } from './incoming-volunteer-model.gql';
import { useVolunteersContext } from '../VolunteersContext';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { CommentsList } from '../common/CommentsList';
import { BypassDialog } from './BypassDialog';
import {
  GetIncomingVolunteerApplicationDetail,
  GetIncomingVolunteerApplicationDetailVariables,
} from './__generated__/GetIncomingVolunteerApplicationDetail';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';

const useStyles = () => {
  const { theme, isMobile } = useCampfireTheme();
  return makeStyles({
    listItem: {
      padding: isMobile ? '16px 8px' : '16px 48px',
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    iconContainer: {
      marginRight: 8,
    },
    taskMenuBarWrapper: {
      borderLeft: `1px solid ${theme.color.grey.border}`,
      borderTop: `1px solid ${theme.color.grey.border}`,
      boxShadow: '0px 5px 8px rgba(114, 114, 114, 0.16), 0px 7px 14px rgba(214, 214, 214, 0.26)',
    },
  })();
};

const IncomingVolunteerViewApplicantScreen = ({
  selectedApplicationId,
  goBack,
  reloadList,
}: {
  selectedApplicationId: string;
  goBack: () => void;
  reloadList?: () => void;
}) => {
  const { theme, isMobile, isSm } = useCampfireTheme();
  const [leftMenuItem, setLeftMenuItem] = useState<LeftMenuItemType>();
  const [openBypass, setOpenBypass] = React.useState(false);
  const { data, loading } = useCampfireQuery<
    GetIncomingVolunteerApplicationDetail,
    GetIncomingVolunteerApplicationDetailVariables
  >(GET_INCOMING_VOLUNTEER_APPLICATION_DETAIL, {
    options: {
      variables: {
        applicationId: selectedApplicationId,
      },
    },
  });

  const application = useMemo(() => {
    return data?.vm.application ?? undefined;
  }, [data]);

  const invitation = application?.invitee;
  const isApplicationApproved = application && application?.status === 'approved';
  const accountStatus = isApplicationApproved && application?.profile?.userId ? 'Account created' : 'Pending review';
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const classes = useStyles();

  const { runRemoveApplicant, runRemoveApplicantLoading, runApproveApplication } = useIncomingVolunteerActions();
  const { setRemindDialog, setApplicationsReminder } = useVolunteersContext();

  const onClickSendReminder = () => {
    if (application) {
      setApplicationsReminder([application]);
    }
    setRemindDialog(true);
  };

  const onBypass = () => {
    if (application) {
      runApproveApplication({
        applicationId: application.applicationId,
        status: 'bypassed',
      }).then(() => {
        if (reloadList) reloadList();
        goBack();
      });
    }
  };

  const isApplicationRemoved = application?.status === 'rejected' || application?.status === 'removed';

  return (
    <IncomingVolunteersPageWrapper>
      {application && (
        <ConfirmationDialog
          title={'Confirm to remove applicant?'}
          body={
            <>
              <Typography variant='body1' gutterBottom>
                {`All onboarding progress will be removed for the applicant and their account will be deactivated.`}
              </Typography>
              <Typography variant='body1'>
                {
                  'Removed applicants will receive an email notifying them that their application has been unsuccessful.'
                }
              </Typography>
            </>
          }
          open={openConfirmation}
          closeActionText={'Cancel'}
          confirmActionText={'Remove Applicant'}
          handleCloseClick={() => setOpenConfirmation(false)}
          handleConfirmClick={() => {
            runRemoveApplicant({
              applicationId: application.applicationId,
              status: 'removed',
            }).then(() => {
              if (reloadList) reloadList();
              goBack();
            });
          }}
        />
      )}

      <CircularProgressOverlay isLoading={runRemoveApplicantLoading || loading} />
      <IncomingVolunteersLeftColumnWrapper rightColumnOpen={!!leftMenuItem}>
        {application && (
          <Grid item container direction='row' wrap='nowrap' alignItems='center' className={classes.listItem}>
            <Grid item>
              <IconButton onClick={() => goBack()}>
                <KeyboardArrowLeft fontSize={isMobile ? 'small' : 'large'} />
              </IconButton>
            </Grid>
            <Grid item className={classes.iconContainer}>
              <FallbackAvatar
                initials={application?.name
                  .split(' ')
                  .map((n) => n[0])
                  .join('')}
                size={32}
              />
            </Grid>
            <Grid
              item
              container
              direction={isMobile ? 'column' : 'row'}
              wrap={isMobile ? 'wrap' : 'nowrap'}
              spacing={isMobile ? 1 : 0}
              alignItems='center'
            >
              <Grid item container direction='column' spacing={isSm ? 1 : 0}>
                <Grid item container direction={isMobile ? 'column' : 'row'}>
                  <Grid item>
                    <Typography component='span' style={{ marginRight: 8 }}>
                      {application?.name}{' '}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography component='span' variant='body2' color='textSecondary'>
                      {application?.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container direction='row' alignItems='center' spacing={1}>
                  {isApplicationRemoved ? (
                    <Grid item>
                      <StatusChip status='Application Removed' bgcolor={theme.status.red.medium} />
                    </Grid>
                  ) : (
                    <React.Fragment>
                      <Grid item>
                        <StatusChip status={invitation ? 'Invited' : 'Applied'} bgcolor={theme.status.green.light} />
                      </Grid>
                      <Grid item>
                        <StatusChip
                          status={accountStatus}
                          bgcolor={accountStatus === 'Account created' ? '#2196F3' : theme.status.red.medium}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant='caption' color='textSecondary'>
                          {`Submitted ${formatDistance(unpackToDateTime(application?.dateSubmitted))}`}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
              {!leftMenuItem ? (
                <Grid
                  container
                  style={{
                    display: 'flex',
                    gap: '8px',
                    minWidth: isMobile ? 'auto' : '400px',
                    flexWrap: isMobile ? 'wrap' : 'nowrap',
                  }}
                  justify={isMobile ? 'flex-start' : 'flex-end'}
                >
                  {!isApplicationRemoved && (
                    <Grid item>
                      <TabletButton
                        data-track='fs-incVol-invite-volunteers-button'
                        variant='contained'
                        color='primary'
                        onClick={onClickSendReminder}
                        style={{ borderRadius: 4, padding: '0.5rem 0.5rem' }}
                      >
                        <EmailIcon color='inherit' style={{ fontSize: 14 }} />
                        <Typography style={{ fontSize: 14, marginLeft: 8, fontWeight: 500 }}>
                          {isSm ? 'Reminder' : 'Send Reminder'}
                        </Typography>
                      </TabletButton>
                    </Grid>
                  )}
                  {application?.status !== 'approved' && !isApplicationRemoved && (
                    <Grid item>
                      <TabletButton
                        data-track='fs-incVol-invite-volunteers-button'
                        variant='outlined'
                        onClick={() => setOpenBypass(true)}
                        style={{ borderRadius: 4, padding: '0.5rem 0.5rem' }}
                        disabled={isApplicationRemoved}
                      >
                        <Redo color='inherit' style={{ fontSize: 14 }} />
                        <Typography style={{ fontSize: 14, marginLeft: 8, fontWeight: 500 }}>Bypass</Typography>
                      </TabletButton>
                    </Grid>
                  )}
                  {!isApplicationRemoved && (
                    <Grid item>
                      <TabletButton
                        color='error'
                        variant='outlined'
                        onClick={() => setOpenConfirmation(true)}
                        style={{ borderRadius: 4, padding: '0.5rem 0.5rem' }}
                      >
                        <DeleteIcon color='inherit' style={{ fontSize: 14 }} />
                        <Typography style={{ fontSize: 14, marginLeft: 8, fontWeight: 500 }}>
                          {isSm ? 'Remove' : 'Remove Applicant'}
                        </Typography>
                      </TabletButton>
                    </Grid>
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        )}
        <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} flexGrow={1}>
          <Box flex={1}>
            {invitation ? (
              <IncomingVolunteersInviteTaskSection
                subText={`Invited by ${invitation.invitedBy.profile.preferredName} ${invitation.invitedBy.profile.lastName}`}
                status={`Invited ${formatDistance(unpackToDateTime(invitation.dateInvited))}`}
                onClick={() => setLeftMenuItem({ inviteeId: invitation.inviteeId, type: invitation.__typename })}
              />
            ) : null}

            {application ? (
              <IncomingVolunteersApplicationTaskSection
                status={isApplicationApproved ? 'approved' : 'pending'}
                taskRecentActivityStatus={`Submitted ${formatDistance(unpackToDateTime(application?.dateSubmitted))}`}
                onClick={() =>
                  setLeftMenuItem({
                    applicationId: application?.applicationId,
                    type: application?.__typename,
                  })
                }
              />
            ) : null}
            <Box style={{ paddingLeft: '1rem', paddingRight: '1rem' }}></Box>

            {isApplicationApproved ? (
              <IncomingVolunteerTasksSection
                setLeftMenuItem={setLeftMenuItem}
                userId={application?.profile?.userId ?? ''}
              />
            ) : null}
          </Box>
          {!leftMenuItem && application && !isMobile && (
            <CollapsibleSideBar>
              <Box width='100%'>
                <Typography
                  style={{
                    color: theme.color.grey.neutralBrand800,
                    fontWeight: 900,
                    fontSize: '1.25rem',
                  }}
                >
                  Comments
                </Typography>
                <Box
                  width='100%'
                  style={{
                    borderBottomColor: theme.color.grey.neutral200,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                  }}
                  my='1rem'
                />
                <CommentsList applicationId={application?.applicationId} />
              </Box>
            </CollapsibleSideBar>
          )}
          {!leftMenuItem && application && isMobile && (
            <Box p={2}>
              <Box width='100%'>
                <Typography style={{ color: theme.color.grey.neutralBrand800, fontWeight: 900, fontSize: '1.25rem' }}>
                  Comments
                </Typography>
                <Box
                  width='100%'
                  style={{
                    borderBottomColor: theme.color.grey.neutral200,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                  }}
                  my='1rem'
                />
                <CommentsList applicationId={application?.applicationId} />
              </Box>
            </Box>
          )}
        </Box>
      </IncomingVolunteersLeftColumnWrapper>

      {leftMenuItem ? (
        <IncomingVolunteersRightColumnWrapper>
          <TaskSideMenuBar
            onClose={() => setLeftMenuItem(undefined)}
            leftMenuItem={leftMenuItem}
            reload={reloadList}
            selectedUserId={application?.profile?.userId}
          />
        </IncomingVolunteersRightColumnWrapper>
      ) : null}
      <BypassDialog
        open={openBypass}
        onClose={() => setOpenBypass(false)}
        onCancel={() => setOpenBypass(false)}
        onBypass={onBypass}
      />
    </IncomingVolunteersPageWrapper>
  );
};

export { IncomingVolunteerViewApplicantScreen };
