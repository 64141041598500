import { DatePickerField } from '@campfire/date-picker';
import { Field } from '@campfire/field/lib';
import { encodeDate } from '@campfire/hot-date';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Pane, PaneWrapper } from '@campfire/pane';
import { Select } from '@campfire/select/lib';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Room } from '@material-ui/icons';
import { Form, Formik, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { date as YupDate, object as YupObject, string as YupString } from 'yup';
import { AutoLocationField } from '../../../../../common/auto-location-field/AutoLocationField';
import { ConfirmationDialog } from '../../../../../common/dialogs/ConfirmationDialog';
import { getDisplayTimeSchedule } from '../../../../../common/functions/activity-display-helpers';
import { arrayHead } from '../../../../../common/functions/array-head';
import { useUser } from '../../../../../global/auth/useUser';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useRosterTemplateActions } from './roster-template-actions';
import {
  CREATE_ROSTER_TEMPLATE_GET_PROGRAMS_AND_ACTIVITIES,
  CREATE_ROSTER_TEMPLATE_GET_PROGRAMS_AND_ACTIVITIES_BOOGALOO,
  ROSTER_TEMPLATE_CHECK_RECURRENCE_DATE,
} from './roster-template-model.gql';
import { RosterCancellationDialog } from './RosterCancellationDialog';
import { CreateRosterTemplateActivity } from './__generated__/CreateRosterTemplateActivity';
import { CreateRosterTemplateActivitySession } from './__generated__/CreateRosterTemplateActivitySession';
import { CreateRosterTemplateGetProgramsAndActivities } from './__generated__/CreateRosterTemplateGetProgramsAndActivities';
import {
  CreateRosterTemplateGetProgramsAndActivitiesBoogaloo,
  CreateRosterTemplateGetProgramsAndActivitiesBoogalooVariables,
} from './__generated__/CreateRosterTemplateGetProgramsAndActivitiesBoogaloo';
import { CreateRosterTemplateProgram } from './__generated__/CreateRosterTemplateProgram';
import {
  RosterTemplateCheckRecurrenceDate,
  RosterTemplateCheckRecurrenceDateVariables,
} from './__generated__/RosterTemplateCheckRecurrenceDate';

const validationSchema = YupObject().shape({
  programId: YupString().required('Please select a program'),
  activityId: YupString().required('Please select an activity'),
  description: YupString(),
  rosterDate: YupDate().required('Please select a date'),
  location: YupString().required('Please provide a location'),
});

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '24px 128px',
    },
    height: '100%',
    overflow: 'scroll',
  },
  textGap: {
    marginRight: 16,
    [theme.breakpoints.up('sm')]: {
      marginRight: 8,
    },
  },
}));

interface Location {
  description: string;
  formatted: string;
  latitude: number;
  longitude: number;
  placesId?: string;
}

export type RosterTemplateFormValues = {
  rosterTemplateId?: string;
  programId: string;
  activityId: string;
  rosterNotes?: string | null;
  rosterDate: Date;
  location: Location;
  remoteLocation: any;
};

const CreateRosterTemplateForm = ({
  activities,
  programs,
  loading,
  isSubmitting,
  publishedRoster,
  draftRoster,
  initRosterDate,
}: {
  activities: CreateRosterTemplateActivity[];
  programs: CreateRosterTemplateProgram[];
  loading: boolean;
  isSubmitting: boolean;
  publishedRoster?: PublishedRoster | null;
  draftRoster?: DraftRoster | null;
  initRosterDate?: Date;
}) => {
  const formik = useFormikContext<RosterTemplateFormValues>();
  const classes = useStyles();
  const history = useHistory();
  const { theme } = useCampfireTheme();
  const { runDeleteRosterTemplate, runSaveRosterTemplate } = useRosterTemplateActions();

  const [selectedActivityId, setSelectedActivityId] = useQueryParam('activityId', StringParam);
  const [selectedProgramId, setSelectedProgramId] = useState<string>(formik.values.programId);
  // const [showNoteSection, setShowNoteSection] = useState<boolean>(!!formik.values.rosterNotes);
  const [prevDate, setPrevDate] = useState(formik.values.rosterDate);
  const [prevLocation] = useState(formik.values.location);
  const [showRosterExistDialog, setShowRosterExistDialog] = useState(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
  const [showRosterCancellationDialog, setShowRosterCancellationDialog] = useState(false);
  const [showPromptDialog, setShowPromptDialog] = useState(false);

  const isEditMode = useMemo(() => {
    return !!formik.values.rosterTemplateId;
  }, [formik.values.rosterTemplateId]);

  // In edit mode, get the activityId from roster template and set the activity id
  useEffect(() => {
    if (isEditMode) setSelectedActivityId(formik.values.activityId);
  }, [isEditMode, formik.values.activityId]);

  const selectedActivity = useMemo(() => {
    return activities?.find((activity) => activity.activityId === selectedActivityId);
  }, [activities, selectedActivityId, isEditMode]);

  const sessionsOfSelectedActivity = useMemo(() => {
    return selectedActivity?.sessions ?? [];
  }, [selectedActivity]);

  const [
    runCheckRecurrenceDate,
    { data: checkRecurrenceDateData, loading: checkRecurrenceDateIsLoading },
  ] = useCampfireLazyQuery<RosterTemplateCheckRecurrenceDate, RosterTemplateCheckRecurrenceDateVariables>(
    ROSTER_TEMPLATE_CHECK_RECURRENCE_DATE
  );

  useEffect(() => {
    if (!selectedActivityId || !formik.values.rosterDate) return;
    runCheckRecurrenceDate({
      variables: {
        activityId: selectedActivityId,
        rosterDate: encodeDate(DateTime.fromJSDate(formik.values.rosterDate)),
      },
    });
  }, [selectedActivityId, formik.values.rosterDate]);

  // For date change on DatePickerField
  useEffect(() => {
    if (!checkRecurrenceDateIsLoading) {
      const activity = checkRecurrenceDateData?.vm?.activity;
      const rosterTemplateExistsForDate = selectedActivity?.rosterTemplates.find(
        (rt) =>
          encodeDate(DateTime.fromISO(rt.rosterDate)) ===
          encodeDate(DateTime.fromJSDate(formik.values.rosterDate as Date))
      );
      const isEditingRosterInitialDate =
        initRosterDate &&
        encodeDate(DateTime.fromJSDate(initRosterDate)) ===
          encodeDate(DateTime.fromJSDate(formik.values.rosterDate as Date));

      if (activity?.occursOn && !isEditingRosterInitialDate) {
        setShowRosterExistDialog(true);
        return;
      }

      if (rosterTemplateExistsForDate && !isEditingRosterInitialDate) {
        setShowRosterExistDialog(true);
        return;
      }
      if (
        publishedRoster &&
        encodeDate(DateTime.fromISO(publishedRoster.activityDate)) !==
          encodeDate(DateTime.fromJSDate(formik.values.rosterDate))
      ) {
        setShowRosterCancellationDialog(true);
        return;
      }
      setPrevDate(formik.values.rosterDate);
    }
  }, [checkRecurrenceDateData, publishedRoster, prevDate, checkRecurrenceDateIsLoading]);

  useEffect(() => {
    if (selectedActivity) {
      setSelectedProgramId(selectedActivity.program.programId);
      const selectedActivityLocation = selectedActivity.activityLocation;

      if (!isEditMode) {
        formik.setFieldValue('remoteLocation', {
          activityRemoteLocationId: selectedActivity.activityRemoteLocation?.activityRemoteLocationId,
          details: selectedActivity.activityRemoteLocation?.details,
        });

        formik.setFieldValue('location', {
          activityLocationId: selectedActivityLocation?.activityLocationId ?? '',
          description: selectedActivityLocation?.placesAddress.description ?? '',
          formatted: selectedActivityLocation?.placesAddress.formatted ?? '',
          latitude: selectedActivityLocation?.placesAddress.latLong.lat ?? 0,
          longitude: selectedActivityLocation?.placesAddress.latLong.long ?? 0,
          placesId: selectedActivityLocation?.placesAddress.placesId ?? '',
        });
      }
    }
  }, [selectedActivity, isEditMode]);

  useEffect(() => {
    if (selectedActivityId) {
      formik.setFieldValue('activityId', selectedActivityId);
    }
  }, [selectedActivityId]);

  useEffect(() => {
    if (selectedProgramId) {
      formik.setFieldValue('programId', selectedProgramId);
    }
  }, [selectedProgramId]);

  const activitiesOfSelectedProgram = useMemo(() => {
    return activities?.filter((activity) => activity.program.programId === selectedProgramId);
  }, [activities, selectedProgramId]);

  useEffect(() => {
    const isProgramUpdated = !activitiesOfSelectedProgram.find(
      (activity) => activity.activityId === selectedActivityId
    );
    if (isProgramUpdated) {
      const firstActivity = arrayHead(activitiesOfSelectedProgram);
      if (firstActivity) setSelectedActivityId(firstActivity.activityId);
    }
  }, [activitiesOfSelectedProgram, selectedActivityId]);

  // TODO: handle error
  const fieldsUnchanged: boolean = useMemo(() => {
    return (
      formik.values.rosterNotes === formik.initialValues.rosterNotes &&
      formik.values.rosterDate === formik.initialValues.rosterDate &&
      formik.values.location === formik.initialValues.location
    );
  }, [formik.values.rosterNotes, formik.values.rosterDate, formik.values.location]);

  const hasLocationChanged: boolean = useMemo(() => {
    return formik.values.location !== formik.initialValues.location;
  }, [formik.values.location]);

  const validateRosterDate = useCallback(
    (rosterDate: Date | undefined) => {
      if (!rosterDate || !selectedActivity?.startDate) {
        return undefined;
      }
      const isoDate = DateTime.fromJSDate(rosterDate).toISODate();

      if (isoDate < selectedActivity.startDate) {
        return "Roster cannot be earlier than the activity's start date";
      }

      if (checkRecurrenceDateData?.vm?.activity?.occursOn) {
        return 'Roster already exists for this date';
      }
      return undefined;
    },
    [selectedActivity?.startDate, checkRecurrenceDateData]
  );

  return (
    <Box className={classes.container}>
      <LinearProgressOverlay isLoading={loading || checkRecurrenceDateIsLoading} />
      <Typography variant='h6' style={{ fontWeight: 600, paddingBottom: 16 }}>
        {formik.values.rosterTemplateId ? 'Edit Roster' : 'Add New Roster'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            slow
            fullWidth
            includeEmpty
            variant='outlined'
            name='programId'
            label='Program'
            items={programs}
            disabled={!!formik.values.rosterTemplateId}
            onChange={(event) => setSelectedProgramId(event.target.value)}
            renderFunction={(program: CreateRosterTemplateProgram) => program.name}
            valueFunction={(program: CreateRosterTemplateProgram) => program.programId}
          />
        </Grid>
        <Grid item xs={12}>
          {activitiesOfSelectedProgram && (
            <Select
              slow
              fullWidth
              includeEmpty
              variant='outlined'
              name='activityId'
              label='Activity'
              items={activitiesOfSelectedProgram}
              disabled={!!formik.values.rosterTemplateId}
              onChange={(event) => setSelectedActivityId(event.target.value)}
              renderFunction={(activity: CreateRosterTemplateActivity) => activity.name}
              valueFunction={(activity: CreateRosterTemplateActivity) => activity.activityId}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <DatePickerField
            name='rosterDate'
            label='Date'
            autoOk
            fullWidth
            required
            inputVariant='outlined'
            InputLabelProps={{ shrink: true }}
            minDate={selectedActivity ? selectedActivity.startDate : undefined}
            validate={validateRosterDate}
          />
        </Grid>

        {selectedActivity?.locationType === 'local' ? (
          <Grid item xs={12}>
            <AutoLocationField name='location' label='Location' helper fullWidth variant='outlined' required />
          </Grid>
        ) : null}

        {selectedActivity?.locationType === 'remote' ? (
          <Grid item xs={12}>
            <Field
              name='remoteLocation.details'
              label='Location - Add further information (link, phone number, etc)'
              variant='outlined'
              helper
              fullWidth
              autoFocus
            />
          </Grid>
        ) : null}

        {/* <Grid item xs={12}>
          <Collapse in={showNoteSection}>
            <RichEditorFormik valueName='rosterNotes' placeholder='Notes (Optional)' />
          </Collapse>

          {!showNoteSection ? (
            <HoverText variant='body2' color='primary' onClick={() => setShowNoteSection(true)}>
              {'Add notes for this roster'}
            </HoverText>
          ) : null}
        </Grid> */}
        <Grid item xs={12}>
          <Typography variant='h6' style={{ fontWeight: 600 }}>
            Sessions
          </Typography>
          {sessionsOfSelectedActivity.length > 0
            ? sessionsOfSelectedActivity.map((session: CreateRosterTemplateActivitySession) => {
                const {
                  activityLocation: sessionLocation,
                  activity: { activityLocation },
                } = session;
                return (
                  <Box
                    key={session.sessionId}
                    borderBottom={`1px solid ${theme.color.grey.border}`}
                    px={2}
                    pb={2}
                    pt={1}
                  >
                    <Typography variant='h6'>{session.name}</Typography>
                    <Typography variant='body2' color='textSecondary'>
                      {getDisplayTimeSchedule(session.startTime, session.endTime)}
                    </Typography>
                    <Box display='flex' alignItems='center'>
                      <Typography variant='subtitle2' color='textSecondary' className={classes.textGap}>
                        {`Report`}
                      </Typography>
                      <Typography variant='body2' color='textSecondary'>
                        {session.reportType === null
                          ? session.autoReport
                            ? 'No Report Required (Impact Tracked)'
                            : 'No Report Required (No Impact)'
                          : session.reportType?.name}
                      </Typography>
                    </Box>
                    <Box display='flex'>
                      <Room />
                      <Typography variant='body2' color='textSecondary'>
                        {sessionLocation
                          ? sessionLocation.placesAddress.formatted
                          : activityLocation
                          ? activityLocation.placesAddress.formatted
                          : `This is a remote session`}
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            : null}
        </Grid>
        <Grid container justify='space-between' alignItems='center' style={{ padding: 16 }}>
          <Grid item xs={4}>
            <TabletButton
              variant='text'
              color='error'
              onClick={() => setShowDeleteConfirmationDialog(true)}
              disabled={!formik.values.rosterTemplateId || !!publishedRoster}
            >
              {'Delete'}
            </TabletButton>
          </Grid>
          <Grid item container xs={8} justify='flex-end'>
            <Grid item>
              <TabletButton onClick={() => history.push('/management/rosters')}>{'Cancel'}</TabletButton>
            </Grid>
            <Grid item>
              {isEditMode ? (
                <TabletButton
                  color='primary'
                  variant='contained'
                  disabled={fieldsUnchanged}
                  onClick={() =>
                    hasLocationChanged && publishedRoster ? setShowPromptDialog(true) : formik.submitForm()
                  }
                >
                  {'Save Changes'}
                </TabletButton>
              ) : (
                <TabletButton color='primary' variant='contained' type='submit' disabled={isSubmitting}>
                  {'Create'}
                </TabletButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        {showRosterExistDialog && (
          <ConfirmationDialog
            title={'This roster already exists'}
            body={`A roster with these settings already exist, do you want to view the roster ?`}
            open
            closeActionText={'Cancel'}
            confirmActionText={'View Roster'}
            handleCloseClick={() => {
              setShowRosterExistDialog(false);
              formik.setFieldValue('rosterDate', prevDate);
            }}
            handleConfirmClick={() => {
              history.push(
                `/management/rosters?activityDate=${encodeDate(
                  DateTime.fromJSDate(formik.values.rosterDate as Date)
                )}&activityId=${selectedActivityId}`
              );
            }}
          />
        )}
        {prevDate && selectedActivityId && (
          <RosterCancellationDialog
            open={showRosterCancellationDialog}
            handleClose={() => {
              setShowRosterCancellationDialog(false);
              formik.setFieldValue('rosterDate', prevDate);
            }}
            activityId={selectedActivityId}
            activityDate={encodeDate(DateTime.fromJSDate(prevDate as Date))}
          />
        )}
        {showDeleteConfirmationDialog && (
          <ConfirmationDialog
            title={'Delete this roster?'}
            body={
              draftRoster
                ? `There is a draft roster saved for this date, deleting this roster will remove the roster and all draft rostering attached to it from the Timeline. This action cannot be undone.`
                : `Deleting this roster will remove it from the Timeline. This action cannot be undone.`
            }
            open
            closeActionText={'Cancel'}
            confirmActionText={'Delete Roster'}
            handleCloseClick={() => {
              setShowDeleteConfirmationDialog(false);
            }}
            handleConfirmClick={() => {
              runDeleteRosterTemplate({ rosterTemplateId: formik.values.rosterTemplateId as string });
            }}
          />
        )}
        {showPromptDialog && (
          <ConfirmationDialog
            title={'Update location for this roster ?'}
            body={`The location for this roster will be updated, and rostered volunteers will be notified of this change.`}
            open
            closeActionText={'Cancel'}
            confirmActionText={'Save Changes'}
            handleCloseClick={() => {
              setShowPromptDialog(false);
              formik.setFieldValue('location', prevLocation);
            }}
            handleConfirmClick={() => {
              runSaveRosterTemplate({ values: formik.values });
            }}
          />
        )}
      </Grid>
    </Box>
  );
};
type PublishedRoster = {
  rosterId: string;
  activityDate: string;
  rosterTemplate: {
    rosterTemplateId: string;
  } | null;
};
type DraftRoster = {
  draftRosterId: string;
};
export const RosterTemplateForm = ({
  predefinedFormValues,
  publishedRoster,
  draftRoster,
}: {
  predefinedFormValues?: Partial<RosterTemplateFormValues>;
  publishedRoster?: PublishedRoster | null;
  draftRoster?: DraftRoster | null;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { runSaveRosterTemplate } = useRosterTemplateActions();

  const {
    getVolunteerIdentity,
    maybeManagerIdentity,
    user: { userIdentityService },
  } = useUser();
  const managedProgramIds = maybeManagerIdentity
    ? maybeManagerIdentity.programManagerIdentities.map((pmi) => pmi.programId)
    : [];
  const activityIds = getVolunteerIdentity()?.programIdentities.flatMap((pi) =>
    managedProgramIds.find((id) => pi.programId === id) ? pi.activityIds : pi.ledActivityIds
  );

  const { data: allData, loading: allLoading } = useCampfireQuery<
    CreateRosterTemplateGetProgramsAndActivities,
    undefined
  >(CREATE_ROSTER_TEMPLATE_GET_PROGRAMS_AND_ACTIVITIES);

  const { data: subsetData, loading: subsetLoading } = useCampfireQuery<
    CreateRosterTemplateGetProgramsAndActivitiesBoogaloo,
    CreateRosterTemplateGetProgramsAndActivitiesBoogalooVariables
  >(CREATE_ROSTER_TEMPLATE_GET_PROGRAMS_AND_ACTIVITIES_BOOGALOO, {
    options: {
      variables: {
        activityIds,
      },
    },
  });

  const activities = useMemo(() => {
    if (userIdentityService.isVmAdmin || userIdentityService.isProgramManager) {
      return allData?.vm.activities ?? [];
    }
    return subsetData?.vm.activities ?? [];
  }, [allData, subsetData]);

  const programs = useMemo(() => {
    if (userIdentityService.isVmAdmin || userIdentityService.isProgramManager) {
      return allData?.vm.programs ?? [];
    }
    return subsetData?.vm.activities.map((activity) => activity.program) ?? [];
  }, [allData, subsetData]);

  const initialValues = useMemo(
    () => ({
      rosterTemplateId: predefinedFormValues?.rosterTemplateId ?? undefined,
      programId: predefinedFormValues?.programId ?? '',
      activityId: predefinedFormValues?.activityId ?? '',
      rosterDate: predefinedFormValues?.rosterDate ?? new Date(),
      rosterNotes: predefinedFormValues?.rosterNotes ?? undefined,
      location: {
        description: predefinedFormValues?.location?.description ?? '',
        formatted: predefinedFormValues?.location?.formatted ?? '',
        latitude: predefinedFormValues?.location?.latitude ?? 0,
        longitude: predefinedFormValues?.location?.longitude ?? 0,
        placesId: predefinedFormValues?.location?.placesId ?? '',
      },
      remoteLocation: predefinedFormValues?.remoteLocation ?? {
        activityRemoteLocationId: '',
        details: '',
      },
    }),
    [predefinedFormValues]
  );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setIsSubmitting(true);
        runSaveRosterTemplate({ values }).finally(() => {
          setIsSubmitting(false);
        });
      }}
    >
      {() => (
        <PaneWrapper>
          <Pane>
            <Form style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflowY: 'hidden' }}>
              <CreateRosterTemplateForm
                activities={activities}
                programs={programs}
                loading={allLoading || subsetLoading}
                isSubmitting={isSubmitting}
                publishedRoster={publishedRoster}
                draftRoster={draftRoster}
                initRosterDate={predefinedFormValues?.rosterDate}
              />
            </Form>
          </Pane>
        </PaneWrapper>
      )}
    </Formik>
  );
};
