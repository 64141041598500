import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { encodeDate, unpackToTime } from '@campfire/hot-date';
import { Box, List, Typography } from '@material-ui/core';
import { ArrayParam, useQueryParams, StringParam } from 'use-query-params';
import { MyElementsMyActivityType } from './my-elements-my-activity-types';
import { MyElementsMyActivityListItem } from './MyElementsMyActivityListItem';
import { ShowMoreButton } from '../../../../../../common/buttons/ShowMoreButton';
import { AlertCard, getAlertCardColors } from '../../../../../../common/cards/alert-card/AlertCard';
import { useSharedActivitiesFilterUpdate } from '../my-activities-filters/MyActivitiesFilters';
import { GetVolDashboardMyElementsRosterItemStatusData as VolunteerData } from '../../__generated__/GetVolDashboardMyElementsRosterItemStatusData';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { MyActivityStatusIndicator } from './MyActivityStatusIndicator';
import { useActivityActions } from '../../../../activities-v2/useActivityActions';
import { getRosterActions } from '../../../../activities-v2/helpers';
import { ActivityAvailability } from '../../../../../../common/availability-center/ActivityAvailability/ActivityAvailability';
import { AlertFullSessionDialog } from '../../../../../../common/alert-full-session-dialog/AlertFullSessionDialog';
import { RosterTimelineItem } from '../../../../activities-v2/ActivityTypes';
import { MyActivityCICOActions } from './CICO/MyActivityCICOActions';
import { MyActivityCheckInDialog } from './CICO/MyActivityCheckInDialog';
import { useUser } from '../../../../../../global/auth/useUser';
import { MyActivityCheckOutDialog } from './CICO/MyActivityCheckOutDialog';
import { CheckOutFinishedNext } from './CICO/CheckOutFinishedNext';

export const SHOWACTIVITYINITSIZE = 6;
interface MyElementsMyActivitiesListProps {
  myActivities: RosterTimelineItem[];
  volunteerData?: VolunteerData;
  refetch?: () => void;
  refetchListRosters?: () => void;
  onLoadMore: () => void;
  isLoading: boolean;
}

export const MyElementsMyActivitiesList = (props: MyElementsMyActivitiesListProps) => {
  const [showSize, setShowSize] = useState<number>(SHOWACTIVITYINITSIZE);
  const [checkOutFinishedSessionId, setCheckOutFinishedSessionId] = useState<string>();
  const { myActivities, volunteerData, refetch, refetchListRosters, onLoadMore, isLoading } = props;
  const { darkTextColor, lightTextColor } = getAlertCardColors('info');
  const { activitiesFilterUpdated, setActivitiesFilterUpdated } = useSharedActivitiesFilterUpdate();
  const handleSizeUpdate = () => {
    const newSize = showSize + SHOWACTIVITYINITSIZE;
    setShowSize(newSize);
    if (newSize > myActivities.length) {
      onLoadMore();
    }
  };
  const [query, setQuery] = useQueryParams({
    activityStatus: ArrayParam,
    activityId: StringParam,
    activityDate: StringParam,
  });

  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();

  if (activitiesFilterUpdated) {
    handleSizeUpdate();
    setActivitiesFilterUpdated(false);
  }

  const displayingActivity = (activity: RosterTimelineItem): MyElementsMyActivityType => ({
    activityId: activity.activityId,
    activityName: activity.name,
    startTime: activity.startTime,
    endTime: activity.endTime,
    hasOpenRoster: activity.hasOpenRoster,
    numSessions: activity.sessions.length,
    activityDate: activity.activityDate,
    activityLocation: activity?.rosterTemplateAddress
      ? activity?.rosterTemplateAddress
      : activity.activityLocation
      ? activity.activityLocation.placesAddress.description
      : activity.activityRemoteLocation?.details,
  });

  const { theme } = useCampfireTheme();

  const getPublishedRostering = (activity: RosterTimelineItem) =>
    volunteerData?.vm.volunteer?.rosterings.find(
      (rostering) =>
        rostering.publishedRoster.activityDate === activity.activityDate &&
        rostering.publishedRoster.activity.activityId === activity.activityId
    );

  const filteredActivities = myActivities.filter(
    (myActivity) => !query.activityStatus || query.activityStatus.includes(myActivity.status)
  );

  const {
    onAction,
    showDialog,
    showAlertDialog,
    onCloseDialog,
    selectedActivity,
    onSubmit,
    showUpdateDialog,
    fullSessionIds,
    activityIdSubmited,
    handleAlertClose,
    activityDatesSubmited,
    onCICOAction,
    showCheckInDialog,
    showCheckOutDialog,
    CICODialogType,
    setShowCheckOutDialog,
  } = useActivityActions(refetch);

  const onActivityAction = (action: any, activity: RosterTimelineItem) => {
    const enrolmentId = volunteerData?.vm.volunteer?.activityEnrolments.find(
      (enrolment) => !enrolment.dateRemoved && enrolment.activity.activityId === activity.activityId
    )?.activityEnrolmentId;
    const publishedRosteringId = volunteerData?.vm.volunteer?.rosterings.find(
      (rostering) =>
        !rostering.dateRemoved &&
        rostering.publishedRoster.activityDate === activity.activityDate &&
        rostering.publishedRoster.activity.activityId === activity.activityId
    )?.publishedRosteringId;
    const activityApplicationId = volunteerData?.vm.volunteer?.activityApplications.find(
      (application) => !application.dateWithdrawn && application.activity.activityId === activity.activityId
    )?.activityApplicationId;
    const activityWaitlistingId = volunteerData?.vm.volunteer?.activityWaitlistings.find(
      (waitlist) => !waitlist.dateRemoved && waitlist.activity.activityId === activity.activityId
    )?.activityWaitlistingId;

    onAction({
      enrolmentId,
      activityApplicationId,
      activityWaitlistingId,
      publishedRosteringId,
    })(action, { ...activity, status: activity.status });
  };

  const onSubmitAction = () => {
    const enrolmentId = volunteerData?.vm.volunteer?.activityEnrolments.find(
      (enrolment) => !enrolment.dateRemoved && enrolment.activity.activityId === selectedActivity.current?.activityId
    )?.activityEnrolmentId;
    const availabilityId = volunteerData?.vm.volunteer?.activityEnrolments
      .find((enrolment) => enrolment.activity.activityId === selectedActivity.current?.activityId)
      ?.availabilities?.find((availability) => availability.activityDate === selectedActivity.current?.activityDate)
      ?.activityAvailabilityId;
    return onSubmit({ enrolmentId, availabilityId });
  };

  const onClickCardElement = (myActivity: RosterTimelineItem) => {
    setQuery({ activityId: myActivity.activityId, activityDate: myActivity.activityDate });
  };

  const volunteers =
    filteredActivities
      .find((item) => item.activityId === selectedActivity.current?.activityId)
      ?.publishedRoster?.activeRosterings.map((item) => ({
        ...item.volunteer,
        sessionRosterings: item.sessionRosterings,
      })) || [];

  const handleSuccessCheckout = (sessionId: string) => {
    if (!sessionId) {
      onCloseDialog();
    }
    setCheckOutFinishedSessionId(sessionId);
    if (refetch) refetch();
    if (refetchListRosters) refetchListRosters();
  };

  const handleSuccessCheckin = () => {
    if (refetch) refetch();
    if (refetchListRosters) refetchListRosters();
  };

  return (
    <>
      {filteredActivities.length ? (
        <List>
          {filteredActivities.slice(0, showSize).map((myActivity) => {
            const nowDate = encodeDate(DateTime.local());
            const activeRosterings = myActivity.publishedRoster?.activeRosterings;
            const activeRostering = activeRosterings?.find((item) => item.volunteer.volunteerId === volunteerId);
            const isCheckedIn = activeRostering?.volunteer.isCheckedIn;
            const isAttended = activeRostering?.sessionRosterings.every((item) =>
              activeRostering?.volunteer.CICOs.find(
                (cico) =>
                  cico.checkIn &&
                  cico.checkOut &&
                  cico.sessionReport.activityReport.activityDate === myActivity.activityDate &&
                  cico.sessionReport.activityReport.activity.activityId === myActivity.activityId &&
                  cico.sessionReport.session.sessionId === item.session.sessionId
              )
            );
            const checkInTimeRule =
              unpackToTime(myActivity.startTime)
                .diffNow()
                .as('hours') <= 6;
            const checkedInActivityDate =
              activeRostering?.volunteer.checkedIn?.sessionReport.activityReport.activityDate;
            const checkedInActivityId =
              activeRostering?.volunteer.checkedIn?.sessionReport.activityReport.activity.activityId;
            const isAbleCheckIn =
              nowDate === myActivity.activityDate && checkInTimeRule && myActivity.status === 'rostered';
            const isAbleCheckOut =
              myActivity.status === 'rostered' &&
              checkedInActivityDate === myActivity.activityDate &&
              checkedInActivityId === myActivity.activityId &&
              !!isCheckedIn;

            const ableCICO =
              (myActivity.allowCICO || myActivity.allowCICO === null) &&
              (myActivity.program.allowCICO || myActivity.program.allowCICO === null) &&
              volunteerData?.vm.allowCICO &&
              !isAttended &&
              (isAbleCheckIn || isAbleCheckOut);

            const cicoConflict =
              isCheckedIn &&
              (checkedInActivityDate !== myActivity.activityDate || checkedInActivityId !== myActivity.activityId);

            // const ableCICO = () => {
            //   if (!isAttended && (isAbleCheckIn || isAbleCheckOut)) {
            //     if (myActivity.allowCICO === null) {
            //       if (myActivity.program.allowCICO === null) {
            //         return volunteerData?.vm.allowCICO;
            //       }
            //       return myActivity.program.allowCICO;
            //     }
            //     return myActivity.allowCICO;
            //   }
            //   return false;
            // };

            return (
              <MyElementsMyActivityListItem
                rostering={getPublishedRostering(myActivity)}
                color={theme.color.rosters.status[myActivity.status]}
                key={`${myActivity.activityId} ${myActivity.activityDate}`}
                myActivity={displayingActivity(myActivity)}
                isSelected={
                  query.activityId === myActivity.activityId && query.activityDate === myActivity.activityDate
                }
                onClick={() => onClickCardElement(myActivity)}
                actionMenu={
                  ableCICO ? (
                    <MyActivityCICOActions
                      activity={myActivity}
                      onAction={(type) => onCICOAction(myActivity, type, isAbleCheckOut)}
                      cicoConflict={cicoConflict ?? false}
                      isCheckOut={isAbleCheckOut}
                    />
                  ) : (
                    <MyActivityStatusIndicator
                      actions={getRosterActions(myActivity.status, myActivity.hasOpenRoster)}
                      onAction={(action) => onActivityAction(action, myActivity)}
                      status={myActivity.status}
                    />
                  )
                }
              />
            );
          })}
        </List>
      ) : (
        <AlertCard variant='info' title='There are no rosters to show'>
          {volunteerData?.vm.volunteer?.activityEnrolments.length === 0 &&
          volunteerData?.vm.volunteer?.activityApplications.length === 0 &&
          volunteerData?.vm.volunteer?.activityWaitlistings.length === 0 ? (
            <Typography
              variant='subtitle2'
              style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor, paddingTop: '5px' }}
            >
              It looks like you haven&apos;t joined an activity team yet.{' '}
              <Link to={{ pathname: '/volunteering/activities' }}>
                <Typography
                  variant='body2'
                  display='inline'
                  style={{ textDecoration: 'underline', color: darkTextColor, fontWeight: 700 }}
                >
                  Click here
                </Typography>
              </Link>{' '}
              to view your organisation&apos;s activities.
            </Typography>
          ) : (
            ''
          )}
        </AlertCard>
      )}
      <Box marginTop='32px'>{!isLoading ? <ShowMoreButton onClick={handleSizeUpdate} /> : null}</Box>
      {selectedActivity.current && showDialog && (
        <ActivityAvailability
          open={showDialog}
          activityId={selectedActivity.current.activityId}
          onClose={onCloseDialog}
          selectedDate={selectedActivity.current.activityDate}
          onSubmit={onSubmitAction()}
        />
      )}
      {selectedActivity.current && showUpdateDialog && (
        <ActivityAvailability
          open={showUpdateDialog}
          activityId={selectedActivity.current.activityId}
          onClose={onCloseDialog}
          selectedDate={selectedActivity.current.activityDate}
          onSubmit={onSubmitAction()}
        />
      )}
      {showAlertDialog && (
        <AlertFullSessionDialog
          open={showAlertDialog}
          onClose={handleAlertClose}
          fullSessionIds={fullSessionIds}
          activityIdSubmited={activityIdSubmited}
          activityDatesSubmited={activityDatesSubmited}
        />
      )}

      {selectedActivity.current && showCheckInDialog && (
        <MyActivityCheckInDialog
          open={showCheckInDialog}
          type={CICODialogType}
          onClose={onCloseDialog}
          activity={selectedActivity.current}
          handleSuccess={handleSuccessCheckin}
          volunteers={volunteers}
        />
      )}

      {selectedActivity.current && showCheckOutDialog && (
        <MyActivityCheckOutDialog
          open={showCheckOutDialog}
          type={CICODialogType}
          onClose={() => setShowCheckOutDialog(false)}
          activity={selectedActivity.current}
          handleSuccess={handleSuccessCheckout}
        />
      )}
      {selectedActivity.current && checkOutFinishedSessionId && (
        <CheckOutFinishedNext
          open
          handleClose={() => setCheckOutFinishedSessionId(undefined)}
          activity={selectedActivity.current}
          checkOutFinishedSessionId={checkOutFinishedSessionId}
        />
      )}
    </>
  );
};
