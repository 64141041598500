import { TabletButton } from '@campfire/tablet-button/lib';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useMemo } from 'react';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import {
  GetActivityWaitlistingReview,
  GetActivityWaitlistingReviewVariables,
} from '../__generated__/GetActivityWaitlistingReview';
import { GET_ACTIVITY_WAITLISTING_REVIEW } from '../activity-timeline-model.gql';
import { VolunteerReviewCommon, VolunteerReviewCommonSkeletonLoading } from './VolunteerReviewCommon';

export const ActivityWaitlistingReviewDialog = ({
  handleClose,
  handleEnrol,
  activityWaitlistingId,
}: {
  handleClose: () => void;
  handleEnrol: () => void;
  activityWaitlistingId?: string;
}) => {
  const [runGetWaitlisting, { data: getWaitlistingData, loading }] = useCampfireLazyQuery<
    GetActivityWaitlistingReview,
    GetActivityWaitlistingReviewVariables
  >(GET_ACTIVITY_WAITLISTING_REVIEW);

  useEffect(() => {
    if (!activityWaitlistingId) return;
    runGetWaitlisting({
      variables: {
        activityWaitlistingId,
      },
    });
  }, [activityWaitlistingId]);

  const activityWaitlisting = useMemo(() => {
    return getWaitlistingData?.vm.activityWaitlisting;
  }, [getWaitlistingData]);

  return (
    <Dialog open={!!activityWaitlistingId} onClose={() => handleClose()}>
      <DialogTitle>{'Add volunteer to team?'}</DialogTitle>
      {loading ? (
        <DialogContent>
          <VolunteerReviewCommonSkeletonLoading />
        </DialogContent>
      ) : activityWaitlisting ? (
        <DialogContent>
          <VolunteerReviewCommon volunteer={activityWaitlisting.volunteer} />
        </DialogContent>
      ) : null}
      <DialogActions>
        <TabletButton
          data-track='actCnl-waitlist-review-close-dialog'
          disabled={!activityWaitlisting}
          onClick={() => handleClose()}
        >
          {'Go Back'}
        </TabletButton>
        <TabletButton
          data-track='actCnl-waitlist-review-add-to-team'
          disabled={!activityWaitlisting}
          onClick={() => handleEnrol()}
          color='primary'
          variant='contained'
          autoFocus
        >
          {'Add To Team'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};
