import { encodeDate, unpackToDate } from '@campfire/hot-date';
import { Box, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useBetween } from 'use-between';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { useUser } from '../../../../../../global/auth/useUser';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { GET_VOL_DASHBOARD_MY_ELEMENTS_ROSTER_ITEM_STATUS_DATA } from '../../vol-dashboard-my-elements-model.gql';
import { MyElementsMyActivitiesList } from './MyElementsMyActivitiesList';
import { MyActivitiesListSkeleton } from './MyActivitiesListSkeleton';
import {
  GetVolDashboardMyElementsRosterItemStatusData,
  GetVolDashboardMyElementsRosterItemStatusDataVariables,
} from '../../__generated__/GetVolDashboardMyElementsRosterItemStatusData';
import { ACTIVITYLEGENDSTATUSES } from './my-elements-my-activity-types';
import { useCampfireLazyQuery } from '../../../../../../global/network/useCampfireLazyQuery';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { getRosterStatus } from '../../../../activities-v2/helpers';
import { RosterTimelineItem, RosteredStatus } from '../../../../activities-v2/ActivityTypes';
import { getActivityLegend } from '../../../../../../common/constants/activity-legends';
import { GET_MY_ACTIVITIES } from '../../../../activities-v2/ActivityQueries/get-my-shifts.gql';
import {
  ActivitiesGetMyShifts,
  ActivitiesGetMyShiftsVariables,
  ActivitiesGetMyShifts_vm_volunteer_upcomingRosters as RosterType,
} from '../../../../activities-v2/ActivityQueries/__generated__/ActivitiesGetMyShifts';

const ActivityStatusColorLegendItems = ({ activityStatus }: { activityStatus: RosteredStatus }) => {
  const { theme } = useCampfireTheme();
  return (
    <Box display='flex' alignItems='center' paddingRight='20px'>
      <Box height='10px' width='10px' borderRadius='50%' bgcolor={theme.color.rosters.status[activityStatus].primary} />
      <Typography variant='caption' style={{ marginLeft: '6px', color: theme.color.grey.neutral200 }}>
        {getActivityLegend(activityStatus)}
      </Typography>
    </Box>
  );
};

const MyActivitiesStatusColorLegend = () => {
  return (
    <Box padding='16px 0px' display='flex' flexWrap='wrap'>
      {ACTIVITYLEGENDSTATUSES.map((activityLegendStatus: any) => (
        <ActivityStatusColorLegendItems key={activityLegendStatus} activityStatus={activityLegendStatus} />
      ))}
    </Box>
  );
};

const useMyActivities = () => {
  const [myActivities, setMyActivities] = useState<RosterTimelineItem[]>();

  return {
    myActivities,
    setMyActivities,
  };
};

export const useSharedMyActivities = () => useBetween(useMyActivities);

export const MyElementsMyActivitiesMainSection = () => {
  const { getVolunteerIdentity } = useUser();
  const volunteerIdentity = getVolunteerIdentity();
  const { myActivities, setMyActivities } = useSharedMyActivities();
  const loadingEndDate = React.useRef<string>();
  const willUnmount = React.useRef(false);

  const [query, setQuery] = useQueryParams({
    activityStatus: ArrayParam,
    selectedDate: StringParam,
    startDate: StringParam,
    endDate: StringParam,
  });

  const { data: volunteerData, loading: volunteerDataLoading, refetch: refetchVolunteerData } = useCampfireQuery<
    GetVolDashboardMyElementsRosterItemStatusData,
    GetVolDashboardMyElementsRosterItemStatusDataVariables
  >(GET_VOL_DASHBOARD_MY_ELEMENTS_ROSTER_ITEM_STATUS_DATA, {
    options: {
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
      },
    },
  });

  const [myActivitiesLoading, setMyActivitiesLoading] = React.useState(false);

  const [getMyElementsRostersData] = useCampfireLazyQuery<ActivitiesGetMyShifts, ActivitiesGetMyShiftsVariables>(
    GET_MY_ACTIVITIES
  );

  useEffect(() => {
    return () => {
      willUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (!query.startDate && !query.endDate) {
      setQuery({
        ...query,
        startDate: encodeDate(DateTime.local()),
        endDate: encodeDate(DateTime.local().plus({ days: 30 })),
      });
      loadingEndDate.current = encodeDate(DateTime.local().plus({ days: 30 }));
    }
  }, [query]);

  useEffect(() => {
    if (query.endDate) {
      loadingEndDate.current = encodeDate(unpackToDate(query.endDate));
    }
  }, [query.endDate]);

  const [data, setData] = React.useState<RosterType[]>([]);

  useEffect(() => {
    if (!query.startDate && !query.endDate) return;
    setMyActivitiesLoading(true);
    getMyElementsRostersData({
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
        startDate: query.startDate,
        endDate: query.endDate,
      },
    }).then((response) => {
      const upcomingRosters = response.data?.vm.volunteer?.upcomingRosters || [];
      setData(upcomingRosters);
      if (upcomingRosters.length < 6) {
        loadFirstPageMore(upcomingRosters.length);
      }
      setMyActivitiesLoading(false);
    });
  }, [query.startDate]);

  useEffect(() => {
    const allActivities = data
      .filter(({ activityDate }) =>
        query.startDate && query.endDate ? activityDate >= query.startDate && activityDate <= query.endDate : false
      )
      .map(({ activity, activityDate, publishedRoster }) => {
        const { rosterTemplates } = activity;
        const rosterTemplate = rosterTemplates.find(
          (temp) => unpackToDate(temp.rosterDate).toFormat('yyyy-MM-dd') === activityDate
        );
        return {
          ...activity,
          activityDate,
          publishedRoster,
          rosterTemplateAddress: rosterTemplate?.placesAddress?.formatted,
          status: getRosterStatus(activity, activityDate, volunteerData),
        };
      })
      .sort((a, b) => {
        if (a.activityDate < b.activityDate) {
          return -1;
        }
        if (a.activityDate === b.activityDate) {
          return 0;
        }
        return 1;
      });
    setMyActivities(allActivities);
  }, [data, volunteerData]);

  const filteredActivitiesList = useMemo(() => {
    if (!myActivities) return [];
    return myActivities
      .filter(({ activityDate }) => {
        return !query.selectedDate || activityDate === query.selectedDate;
      })
      .sort((a: RosterTimelineItem, b: RosterTimelineItem) => (a.name < b.name ? 1 : -1))
      .sort((a: RosterTimelineItem, b: RosterTimelineItem) =>
        unpackToDate(a.activityDate) > unpackToDate(b.activityDate) ? 1 : -1
      );
  }, [myActivities, query]);

  const onRefetchListRosters = () => {
    if (!query.startDate && !query.endDate) return;
    getMyElementsRostersData({
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
        startDate: query.startDate,
        endDate: query.endDate,
      },
    }).then((response) => {
      const upcomingRosters = response.data?.vm.volunteer?.upcomingRosters || [];
      setData(upcomingRosters);
      if (upcomingRosters.length < 6) {
        loadFirstPageMore(upcomingRosters.length);
      }
    });
  };

  const onLoadMore = () => {
    if (!query.endDate) {
      return;
    }
    const nextDate = unpackToDate(query.endDate)
      .plus({ months: 1 })
      .toISODate();
    const nextStartDate = unpackToDate(query.endDate)
      .plus({ days: 1 })
      .toISODate();

    setQuery({
      endDate: nextDate,
    });

    setMyActivitiesLoading(true);

    getMyElementsRostersData({
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
        startDate: nextStartDate,
        endDate: nextDate,
      },
    }).then((response) => {
      setData(data.concat(response.data?.vm.volunteer?.upcomingRosters || []));
      setMyActivitiesLoading(false);
    });
  };

  const loadFirstPageMore = (dataLength: number) => {
    if (willUnmount.current) {
      return;
    }
    if (
      !loadingEndDate.current ||
      dataLength >= 6 ||
      unpackToDate(loadingEndDate.current) > DateTime.local().plus({ months: 3 })
    ) {
      setMyActivitiesLoading(false);
      return;
    }

    const nextDate = unpackToDate(loadingEndDate.current)
      .plus({ months: 1 })
      .toISODate();
    const nextStartDate = unpackToDate(loadingEndDate.current)
      .plus({ days: 1 })
      .toISODate();

    setMyActivitiesLoading(true);

    getMyElementsRostersData({
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
        startDate: nextStartDate,
        endDate: nextDate,
      },
    }).then((response) => {
      if (!willUnmount.current) {
        const newData = response.data?.vm.volunteer?.upcomingRosters || [];
        setData((pre) => pre.concat(newData));
        loadingEndDate.current = nextDate;
        setQuery({
          endDate: nextDate,
        });
        loadFirstPageMore(dataLength + newData.length);
      }
    });
  };

  return (
    <Box padding='0 38px'>
      <MyActivitiesStatusColorLegend />
      {volunteerDataLoading && myActivitiesLoading && <MyActivitiesListSkeleton />}
      <MyElementsMyActivitiesList
        myActivities={filteredActivitiesList}
        volunteerData={volunteerData}
        refetch={refetchVolunteerData}
        refetchListRosters={onRefetchListRosters}
        onLoadMore={onLoadMore}
        isLoading={myActivitiesLoading}
      />
      {myActivitiesLoading && <MyActivitiesListSkeleton />}
    </Box>
  );
};
