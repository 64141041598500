import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import GetOutOnShift from '../../../assets/tutorial-graphics/my-activities/get_out_on_shift.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Incident Report',
    description: (
      <>
        Use the Incident Report page to <strong>alert</strong> your organisation of any <strong>issues</strong> that
        occurred in or around volunteering.
      </>
    ),
    image: GetOutOnShift,
  },
];

const IncidentReportTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { IncidentReportTutorialDialog };
