import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box } from '@material-ui/core';
import { ChevronRightRounded } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { TemplateSnackbar } from '../../../../common/snackbars/TemplateSnackbar';
import { useOnboardingContext } from '../../../../global/onboarding/useOnboardingContext';
import {
  ACTIVITY_ENROLMENT_PROMPT_COPY_GENERAL,
  ACTIVITY_ENROLMENT_PROMPT_COPY_NON_PRIVATE,
} from './activity-enrolment-prompt-copy';
import { ActivityEnrolmentPrompt } from './ActivityEnrolmentPrompt';
import { useActivityApplicationFetch } from './use-activity-application-fetch';

type Props = {
  isVolunteerEnrolled?: boolean;
  volunteerHasAvailability: boolean;
  activityId: string;
  programsLoading: boolean;
  refetch?: () => void;
};
const ActivitiesExploreNonPrivateActivityApplication = ({
  isVolunteerEnrolled,
  volunteerHasAvailability,
  activityId,
  programsLoading,
  refetch,
}: Props) => {
  const { activityApplicationPostRequest, createApplicationLoading, snackBarOptions } = useActivityApplicationFetch(
    refetch
  );
  const { currentOnboardingStep, refresh } = useOnboardingContext();

  const successHandler = () => {
    if (currentOnboardingStep === 1 || currentOnboardingStep === 2) refresh();
  };

  const enrollmentPromptProps = isVolunteerEnrolled
    ? {
        ...(volunteerHasAvailability
          ? ACTIVITY_ENROLMENT_PROMPT_COPY_GENERAL.keepUpToDate
          : ACTIVITY_ENROLMENT_PROMPT_COPY_GENERAL.chooseAvailability),
        action: (
          <Link style={{ color: 'inherit', textDecoration: 'none' }} to={`my-activities`}>
            <TabletButton
              variant='contained'
              size='medium'
              color='primary'
              endIcon={<ChevronRightRounded />}
              onClick={() =>
                activityApplicationPostRequest(
                  {
                    activityId: activityId,
                    activityDates: undefined,
                  },
                  'create',
                  successHandler
                )
              }
            >
              {'View Upcoming Dates'}
            </TabletButton>
          </Link>
        ),
        progress: volunteerHasAvailability ? undefined : 66,
      }
    : {
        ...ACTIVITY_ENROLMENT_PROMPT_COPY_NON_PRIVATE.join,
        action: (
          <TabletButton
            variant='contained'
            size='medium'
            color='primary'
            onClick={() =>
              activityApplicationPostRequest(
                {
                  activityId: activityId,
                },
                'create',
                successHandler
              )
            }
          >
            {'Join Now'}
          </TabletButton>
        ),
        progress: 0,
      };

  return (
    <>
      {snackBarOptions && (
        <TemplateSnackbar
          variant={snackBarOptions.variant}
          message={snackBarOptions.message}
          open={snackBarOptions.open}
        />
      )}

      <Box position='relative'>
        <CircularProgressOverlay isLoading={createApplicationLoading || programsLoading} />

        <ActivityEnrolmentPrompt {...enrollmentPromptProps} />
      </Box>
    </>
  );
};

export { ActivitiesExploreNonPrivateActivityApplication };
