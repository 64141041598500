import React from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { startCase, uniq } from 'lodash';
import { COLORS } from './colors';
import { GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents_widget_VOLUNTEER_ChartWidgetType as IChartWidget } from '../../dashboard-widget-setting/__generated__/GetDashboardPreference';
import { useQueryChart } from './useQueryChart';
import { DimensionEnum } from '../../dashboard-widget-setting/dashboard-widget-model';
import '../../../../../../../theme/src/calendar.css';

type DashboardBarChartProps = {
  widget: IChartWidget;
};

export function DashboardBarChart(props: DashboardBarChartProps) {
  const { widget } = props;
  const { dimension, group } = widget.configuration;

  const data = useQueryChart(widget);
  if (!data) {
    return null;
  }

  if (dimension !== DimensionEnum.BY_TIME) {
    const keys = uniq(
      data.flatMap((datum: { [key: string]: number }) => Object.keys(datum).filter((key) => key !== 'date'))
    ) as string[];
    return (
      <ResponsiveContainer width='100%' height='99%'>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 5,
            bottom: 5,
          }}
        >
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend iconType='circle' iconSize={10} />
          {keys.map((key: string, idx: number) => (
            <Bar key={key} dataKey={key} fill={COLORS[idx]} name={startCase(key)} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }

  if (!data) {
    return null;
  }

  if (group === null) {
    const keys = uniq(
      data.flatMap((datum: { [key: string]: number }) => Object.keys(datum).filter((key) => key !== 'date'))
    ) as string[];
    return (
      <ResponsiveContainer width='100%' height='99%'>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis dataKey='date' />
          <YAxis />
          <Tooltip />
          <Legend iconType='circle' iconSize={10} />
          {keys.map((key: string, idx: number) => (
            <Bar key={key} dataKey={key} fill={COLORS[idx]} name={startCase(key)} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }

  if (!data.data || data.data.length === 0) {
    return null;
  }
  const keys = uniq(
    data.data.flatMap((datum: { [key: string]: number }) => Object.keys(datum).filter((key) => key !== 'date'))
  ) as string[];
  return (
    <ResponsiveContainer width='100%' height='99%'>
      <BarChart
        data={data.data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <XAxis dataKey='date' />
        <YAxis />
        <Tooltip />
        <Legend iconType='circle' iconSize={10} />
        {keys.map((key: string, index: number) => (
          <Bar key={key} dataKey={key} fill={COLORS[index]} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
