import { unpackToDate, unpackToTime } from '@campfire/hot-date/lib';
import React, { memo, useEffect, useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { arrayHead } from '../../../../common/functions/array-head';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { EDIT_ACTIVITY_GET_ACTIVITY_V2 } from './activity-form-model-v2.gql';
import { ActivityFormV2, ActivityFormRecurrenceFrequency } from './ActivityFormV2';
import {
  EditActivityGetActivityV2,
  EditActivityGetActivityV2Variables,
} from './__generated__/EditActivityGetActivityV2';
import { NO_REPORT_NO_IMPACT, NO_REPORT_IMPACT_TRACKED } from './activity-form-actions-v2';

export const EditActivityScreenV2 = memo(() => {
  const [activityId] = useQueryParam('activityId', StringParam);

  const [run, { data }] = useCampfireLazyQuery<EditActivityGetActivityV2, EditActivityGetActivityV2Variables>(
    EDIT_ACTIVITY_GET_ACTIVITY_V2
  );

  const activity = useMemo(() => {
    return data?.vm.activity;
  }, [data]);

  const frequency = useMemo((): ActivityFormRecurrenceFrequency | undefined => {
    if (!activity) return undefined;

    const recurrence =
      activity.__typename === 'VOLUNTEER_RecurringActivityType' ? arrayHead(activity.schedule.recurrences) : null;
    if (!recurrence) return undefined;

    if (recurrence.__typename === 'DailyRecurrenceType') {
      if (recurrence.dayInterval > 1) {
        return 'custom';
      }
      return 'daily';
    }

    if (recurrence.__typename === 'WeeklyRecurrenceType') {
      if (recurrence.weekInterval === 2 && recurrence.days.length === 1) {
        return 'fortnightly';
      }
      if (recurrence.humanReadable === 'Weekdays') {
        return 'weekdays';
      }
      if (recurrence.humanReadable === 'Weekends') {
        return 'weekends';
      }
      if (recurrence.weekInterval === 1 && recurrence.days.length === 1) {
        return 'weekly';
      }
      return 'custom';
    }

    if (recurrence.__typename === 'MonthlyNthDayRecurrenceType') {
      if (recurrence.monthInterval > 1) {
        return 'custom';
      }
      return 'monthly-nth-day';
    }

    if (recurrence.__typename === 'MonthlyByMonthDayRecurrenceType') {
      if (recurrence.monthInterval > 1) {
        return 'custom';
      }
      return 'monthly-month-day';
    }

    if (recurrence.__typename === 'YearlyRecurrenceType') {
      return 'yearly';
    }

    return 'no-repeat';
  }, [activity]);

  useEffect(() => {
    if (!activityId) return;
    run({
      variables: {
        activityId: activityId,
      },
    });
  }, [activityId]);

  const endDateFromRecurrences: string | undefined =
    activity && activity.__typename === 'VOLUNTEER_RecurringActivityType'
      ? arrayHead(activity.schedule.recurrences)?.endDate
      : undefined;

  const startDate =
    activity && activity.__typename === 'VOLUNTEER_RecurringActivityType'
      ? arrayHead(activity.schedule.recurrences)?.startDate
      : activity?.startDate;

  return activity ? (
    <ActivityFormV2
      isEditing
      baseActivityId={activityId}
      attachments={activity.activityAttachments}
      activityType={activity.__typename}
      predefinedFormValues={{
        activityId: activity.activityId,
        activityName: activity.name,
        activityDescription: activity.description,
        isRestrictedActivity: activity.isRestrictedActivity,
        isHidden: activity.isHidden,
        maxTeam: activity.maxTeam,
        allowCICO: activity.allowCICO,
        hasOpenRoster: activity.hasOpenRoster,
        programId: activity.program.programId,
        recurrence:
          activity.__typename === 'VOLUNTEER_NonRecurringActivityType' ? undefined : activity.schedule.recurrences[0],
        startDate: unpackToDate(startDate).toJSDate(),
        endDate: activity.closedActivity
          ? unpackToDate(activity.closedActivity.endDate).toJSDate()
          : endDateFromRecurrences
          ? unpackToDate(endDateFromRecurrences).toJSDate()
          : undefined,
        frequency,
        locationType: activity.locationType,
        location: activity.activityLocation
          ? {
              activityLocationId: activity.activityLocation.activityLocationId,
              description: activity.activityLocation.placesAddress.description,
              formatted: activity.activityLocation.placesAddress.formatted,
              latitude: activity.activityLocation.placesAddress.latLong.lat,
              longitude: activity.activityLocation.placesAddress.latLong.lat,
              placesId: activity.activityLocation.placesAddress.placesId ?? undefined,
            }
          : undefined,
        remoteLocation: activity.activityRemoteLocation
          ? {
              remoteActivityLocationId: activity.activityRemoteLocation.activityRemoteLocationId,
              details: activity.activityRemoteLocation.details ?? '',
            }
          : undefined,
        sessions: activity.sessions.map((session) => ({
          sessionId: session.sessionId,
          name: session.name,
          description: session.description,
          minVolunteers: session.minVolunteers ?? undefined,
          maxVolunteers: session.maxVolunteers ?? undefined,
          startTime: session.startTime ? unpackToTime(session.startTime).toJSDate() : null,
          endTime: session.endTime ? unpackToTime(session.endTime).toJSDate() : null,
          reportType: session.reportType
            ? {
                name: session.reportType.name,
                reportTypeId: session.reportType.reportTypeId,
              }
            : session.autoReport
            ? {
                name: 'No Report Required (Impact Tracked)',
                reportTypeId: NO_REPORT_IMPACT_TRACKED,
              }
            : {
                name: 'No Report Required (No Impact)',
                reportTypeId: NO_REPORT_NO_IMPACT,
              },
          autoReport: Boolean(session.autoReport),
          activityLocation: session.activityLocation
            ? {
                activityLocationId: session.activityLocation.activityLocationId,
                description: session.activityLocation.placesAddress.description,
                formatted: session.activityLocation.placesAddress.description,
                latitude: session.activityLocation.placesAddress.latLong.lat,
                longitude: session.activityLocation.placesAddress.latLong.lat,
                placesId: session.activityLocation.placesAddress.placesId ?? undefined,
              }
            : undefined,
        })),
        activityTagIds: activity.activityTags.map((tag) =>
          tag &&
          activity.activityTagged.some(
            (activityTaggedItem) => activityTaggedItem.activityTag.activityTagId === tag.activityTagId
          )
            ? tag.activityTagId
            : ''
        ),
      }}
    />
  ) : null;
});
