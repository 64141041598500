import React, { useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { Box } from '@material-ui/core';

import { AdminConsoleWidgetsReportTypes } from './AdminConsoleEmbeddableWidgetsPanel';

type TableOptions = {
  filter: boolean;
  sort: boolean;
};

type TableColumns = {
  name: string;
  label: string;
  options: TableOptions;
};

interface FieldTableListProps {
  tableRows?: Array<AdminConsoleWidgetsReportTypes>;
}

const AdminConsoleFieldMUIDatatable = ({ tableRows }: FieldTableListProps) => {
  const tableData: Array<{ [key: string]: any }> = useMemo(() => {
    if (!tableRows) return [];
    return tableRows;
  }, [tableRows]);

  const fieldTableColumns: TableColumns[] = [
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'reportType',
      label: 'Report Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'fieldName',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'fieldId',
      label: 'Field ID',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options: object = {
    selectableRowsHeader: false,
    selectableRows: 'multiple',
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Box margin='40px 15px 30px 15px'>
      <MUIDataTable title={'Field IDs'} data={tableData} columns={fieldTableColumns} options={options} />
    </Box>
  );
};

export { AdminConsoleFieldMUIDatatable };
