import React, { useContext, useState, ReactNode, useCallback } from 'react';
import { signInPath } from '../public-shell/sign-in-path';

type RedirectInterface = { redirectUrl: string; setRedirectUrl: (value: string) => void; clearRedirectUrl: () => void };

export const RedirectContext = React.createContext<RedirectInterface | undefined>(undefined);

export const RedirectProvider = ({ children }: { children: ReactNode }) => {
  const [redirectUrl, setRedirectUrl] = useState<string>(signInPath);

  const clearRedirectUrl = useCallback(() => {
    setRedirectUrl(signInPath);
  }, [signInPath]);

  return (
    <RedirectContext.Provider
      value={{
        redirectUrl,
        setRedirectUrl,
        clearRedirectUrl,
      }}
    >
      {children}
    </RedirectContext.Provider>
  );
};

export const useRedirectContext = () => {
  const redirectContext = useContext(RedirectContext);
  if (!redirectContext) throw new Error('RedirectContext must be used within a RedirectProvider');
  return redirectContext;
};
