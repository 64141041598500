import React, { useState } from 'react';
import { Box, Typography, Button, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { EditorState } from 'draft-js';
import { useTypographyStyles } from '../styles/typography';
import { useButtonStyles } from '../styles/button';
import { useContainerStyles } from '../styles/container';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../../common/cards/alert-card/AlertCard';
import { useInfoStyles } from '../styles/info';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: '16px',
      marginTop: '2rem',
      fontWeight: 700,
    },
    textField: {
      marginTop: '1rem',
    },
    button: {
      marginLeft: '0.5rem',
    },
  })
);
interface Props {
  onNext: () => void;
  onBack: () => void;
  message: EditorState;
  setMessage: React.Dispatch<React.SetStateAction<EditorState>>;
}

export function SelectNotificationStep({ onNext, onBack, message, setMessage }: Props) {
  const typographyClasses = useTypographyStyles();
  const buttonClasses = useButtonStyles();
  const infoClasses = useInfoStyles();
  const { lightTextColor } = getAlertCardColors('warning');
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const { isMobile } = useCampfireTheme();
  const [checked, setChecked] = useState<boolean>(false);
  return (
    <Box className={containerClasses.root} paddingBottom='1rem'>
      <Typography className={typographyClasses.title} style={{ paddingBottom: '20px' }}>
        Consent & Disclaimer
      </Typography>
      <AlertCard variant='info' title='Importing Volunteers'>
        <Typography className={infoClasses.alertText} style={{ fontSize: '14px', paddingTop: '5px' }}>
          Imported volunteers are full users of Volaby - they will receive all communications and notifications. <br />
          <br />
          We encourage you to leave a message in the text box below, so that your volunteers are aware of the reason
          they are being imported and communicated to. <br />
          This message will be sent in an email to each successfully imported volunteer, along with their access
          details.
        </Typography>
      </AlertCard>
      <Box paddingY={'.5em'}></Box>
      <AlertCard variant='warning' title='Disclaimer'>
        <AlertCardBody>
          <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
            Please make sure you read, understand and agree to all terms of the disclaimer below.
          </Typography>
        </AlertCardBody>
      </AlertCard>
      <Box display={!isMobile ? 'flex' : 'block'} marginTop={'5px'} flexDirection={'row'}>
        <Box width={!isMobile ? '50%' : 'auto'}>
          <Box paddingRight={'30px'}>
            <Typography className={classes.label}>Include a message</Typography>
            <Box paddingY={'.5em'}></Box>
            <CampfireRichEditor
              placeholder={'Inform your volunteers about why they are being imported to Volaby...'}
              editorState={message}
              setEditorState={setMessage}
            />
          </Box>
        </Box>
        <Box width={!isMobile ? '50%' : 'auto'}>
          <Box paddingLeft={'30px'}>
            <Typography className={classes.label} style={{ marginBottom: '1em' }}>
              Disclaimer
            </Typography>
            <Box style={{ marginTop: '1em', fontSize: '14px', fontStyle: 'italic', color: '#000000b3' }}>
              By proceeding to provide us with personal information using this function, including in relation to
              volunteers, you acknowledge and agree that you have obtained all relevant consents from, and provided all
              relevant notices to, the relevant individuals to ensure that we can deal with this information in
              accordance with applicable privacy laws (including, but not limited to, providing volunteers with
              automated emails and SMS for rostering purposes).
            </Box>
            <FormGroup style={{ marginTop: '1em' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    tabIndex={-1}
                    checked={checked}
                    onClick={() => {
                      setChecked(!checked);
                    }}
                  />
                }
                label='I agree with the conditions set out in the above disclaimer'
              />
            </FormGroup>
          </Box>
        </Box>
      </Box>
      <Box display='flex' justifyContent='flex-end' marginTop='2rem'>
        <Button classes={buttonClasses} onClick={onBack} variant='outlined'>
          Back
        </Button>
        <Button
          onClick={onNext}
          disabled={!checked}
          classes={buttonClasses}
          className={classes.button}
          variant='contained'
          color='primary'
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
