import { gql } from '@apollo/client';
import {
  ActivityCommonFragment,
  RECURRENCE_FRAGMENT,
  ActivitySessionFragment,
} from '../../../common/availability-center/ActivityFragment.gql';

export const GET_ACTIVITIES = gql`
  query AvailabilityCenterGetActivities($activityIds: [String!]!, $from: Date, $until: Date) {
    orgName
    vm {
      activities(activityIds: $activityIds) {
        ...AvailabilityCenterActivityFragment
        sessions {
          ...AvailabilityCenterSession
        }
        ... on VOLUNTEER_NonRecurringActivityType {
          __typename
        }

        ... on VOLUNTEER_RecurringActivityType {
          schedule {
            recurrences {
              ...Recurrence
            }
          }
        }
        occurrencesBetween(from: $from, until: $until)
      }
    }
  }
  ${ActivitySessionFragment}
  ${ActivityCommonFragment}
  ${RECURRENCE_FRAGMENT}
`;
