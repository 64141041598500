import { TabletButton } from '@campfire/tablet-button';
import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { initTaskFormFormikValues, TaskFormValuesType } from './model/task-form-init';
import { dynamicValidationSchema } from './model/validation-schema';
import { TaskFormItem } from './model/__generated__/TaskFormItem';
import { TaskFormFieldList } from './TaskFormFieldList';
import { FormikErrorFocus } from '../FormikErrorFocus';
import { deserializeTaskItems } from '../../../screens/admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';

interface Props {
  onSubmit: (values: TaskFormValuesType) => void;
  taskItems: Array<TaskFormItem>;
}

const TaskForm = (props: Props) => {
  const { onSubmit, taskItems } = props;

  const initialFormikValues = useMemo(() => {
    if (taskItems.length === 0) return undefined;
    return initTaskFormFormikValues(taskItems, undefined);
  }, [taskItems]);

  const validationSchema = dynamicValidationSchema(deserializeTaskItems(taskItems));

  return initialFormikValues ? (
    <Formik initialValues={initialFormikValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ values }) => {
        return (
          <Form>
            <TaskFormFieldList taskItems={deserializeTaskItems(taskItems)} values={values} />
            <Box width={1} display='flex' justifyContent='center'>
              <Box width={720} display='flex' justifyContent='flex-start'>
                <TabletButton color='primary' variant='contained' type='submit' size='large' style={{ width: 256 }}>
                  Submit
                </TabletButton>
              </Box>
            </Box>
            <FormikErrorFocus />
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

export { TaskForm };
