import { gql } from '@apollo/client';

export const GET_MY_ACTIVITIES = gql`
  query GetMyActivities($volunteerId: String!, $startDate: Date!, $endDate: Date!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        activityRoles {
          activityRoleId
          name
        }
        activityEnrolments {
          activityEnrolmentId
        }
        activityApplications {
          activityApplicationId
        }
        upcomingRosters(startDate: $startDate, endDate: $endDate) {
          ... on VOLUNTEER_UpcomingRosterCancellationType {
            upcomingRosterId
          }
          ... on VOLUNTEER_UpcomingRosterAvailabilityType {
            upcomingRosterId
          }
          ... on VOLUNTEER_UpcomingRosterRosteringType {
            upcomingRosterId
          }
          ... on VOLUNTEER_UpcomingRosterUnavailabilityType {
            upcomingRosterId
          }
          ... on VOLUNTEER_UpcomingRosterUnspecifiedType {
            upcomingRosterId
          }
        }
      }
    }
  }
`;

const COMMON_ACTIVITY_FRAGMENT = gql`
  fragment CommonActivity on VOLUNTEER_ActivityType {
    activityId
    name
    ... on VOLUNTEER_NonRecurringActivityType {
      __typename
    }
    ... on VOLUNTEER_RecurringActivityType {
      __typename
      schedule {
        recurrences {
          humanReadable
        }
      }
    }
    startTime
    endTime
  }
`;

export const GET_MY_ACTIVITY_APPLICATIONS = gql`
  query GetMyActivityApplications($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        activityApplications {
          activityApplicationId
          activity {
            ...CommonActivity
          }
          dateApplied
        }
        activityWaitlistings {
          activityWaitlistingId
          activity {
            ...CommonActivity
          }
          dateAdded
        }
      }
    }
  }

  ${COMMON_ACTIVITY_FRAGMENT}
`;
