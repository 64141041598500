import { Box, ListItem, Typography } from '@material-ui/core';
import { ListItemProps } from '@material-ui/core/ListItem';
import React, { Fragment, useState } from 'react';
import { Avatar } from '../../../../../general/user-profile/components/Avatar';
import { ActivityTimelineFutureDraftRosterActivityEnrolment } from '../../__generated__/ActivityTimelineFutureDraftRosterActivityEnrolment';
import { VolunteerUnavailabilityConfirmDialog } from '../roster/VolunteerUnavailabilityConfirmDialog';

const UnavailableVolunteerListItem = (
  props: {
    activityDate: string;
    activityEnrolmentId?: string;
    activityUnavailabilityId?: string;
    activityEnrolment: ActivityTimelineFutureDraftRosterActivityEnrolment;
    runRefetch: () => void;
    primaryText: string;
    secondaryText?: string;
    statusText: string;
    style?: React.CSSProperties;
  } & ListItemProps
) => {
  const {
    activityDate,
    activityEnrolmentId,
    activityUnavailabilityId,
    activityEnrolment,
    runRefetch,
    primaryText,
    secondaryText,
    statusText,
    style,
  } = props;

  const { volunteer } = activityEnrolment;
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpenDialog() {
    setDialogOpen(true);
  }

  return (
    <Fragment key={activityEnrolment.activityEnrolmentId}>
      <ListItem divider button onClick={handleOpenDialog} style={style}>
        <Avatar
          size={32}
          preferredName={volunteer.profile.preferredName}
          lastName={volunteer.profile.lastName}
          avatarUrl={volunteer.profile.avatarUrl}
        />

        <Box
          width={1}
          display='flex'
          flex='1 1 auto'
          alignContent='center'
          alignItems='center'
          justifyContent='space-between'
          marginLeft={'8px'}
        >
          <Box display='flex' flex='1 1 auto' flexDirection='column'>
            <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
              <Typography variant='body2' gutterBottom={false}>
                {primaryText}
              </Typography>
            </Box>

            {secondaryText ? (
              <Typography color='textSecondary' variant='body2' gutterBottom={false}>
                {secondaryText}
              </Typography>
            ) : null}

            <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
              <Typography color='textSecondary' variant='body2' gutterBottom={false}>
                {statusText}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ListItem>

      {dialogOpen ? (
        <VolunteerUnavailabilityConfirmDialog
          open={dialogOpen}
          preferredName={volunteer.profile.preferredName}
          activityDate={activityDate}
          activityEnrolmentId={activityEnrolmentId}
          activityUnavailabilityId={activityUnavailabilityId}
          runRefetch={runRefetch}
          onClose={() => setDialogOpen(false)}
        />
      ) : null}
    </Fragment>
  );
};

export { UnavailableVolunteerListItem };
