import { gql } from '@apollo/client';

export const GET_EXPORTS_BY_TYPE = gql`
  query GetExportsByType($type: String) {
    vm {
      exports(type: $type) {
        exportId
        name
        error
        exportStatus
        requestedBy {
          profile {
            preferredName
            lastName
          }
        }
        completedDate
        exportType
        dateStarted
        attachment {
          url
        }
        dateStarted
        isExpired
      }
    }
  }

`