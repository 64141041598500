import { useApiUrl } from '../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../global/network/useCampfireFetch';
import { CreatWidgetFormValues, WidgetTypes } from './dashboard-widget-model';

type IPosition = {
  row: number;
  col: number;
  rowSpan: number;
  colSpan: number;
}

export const useSaveWidget = () => {
  const uri = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  return (widget: CreatWidgetFormValues, position: IPosition, dashboardComponentId?: string) => {

    if (widget.byType === WidgetTypes.NUMBER_CHART) {
      return campfireMutation.run({
        url: `${uri}/vm/preferences/dashboard-component/save`,
        method: 'POST',
        data: {
          dashboardComponentId,
          position,
          name: `chart-number-${widget.numberWidgetType}-${widget.chartWidgetType}`,
          widget: {
            widgetType: widget.byType,
            number: {
              numberType: widget.numberWidgetType,
              range: widget.highlight,
              interval: widget.interval,
              target: widget.target,
              customMultiplier: widget.customMultiplier
            },
            chart: {
              chartType: widget.chartWidgetType,
              dimension: widget.dimension,
              range: widget.range,
              interval: widget.interval,
              group: widget.group === 'none' ? null : widget.group
            }
          },
          filters: {
            activityIds: widget.activityIds,
            programIds: widget.programIds,
            activityTags: widget.tagIds,
            fieldIds: widget.fieldIds
          },
          targets: widget.byValues,
        },
      });
    }

    return campfireMutation.run({
      url: `${uri}/vm/preferences/dashboard-component/save`,
      method: 'POST',
      data: {
        dashboardComponentId,
        position,
        widgetType: widget.byType,
        name: `${widget.byType}-${widget.range}`,
        widget: {
          widgetType: widget.byType,
          numberType: widget.numberWidgetType,
          chartType: widget.chartWidgetType,
          dimension: widget.dimension,
          range: widget.range,
          interval: widget.interval,
          group: widget.group === 'none' ? null : widget.group,
          target: widget.target,
          customMultiplier: widget.customMultiplier
        },
        filters: {
          activityIds: widget.activityIds,
          programIds: widget.programIds,
          activityTags: widget.tagIds,
          fieldIds: widget.fieldIds
        },
        targets: widget.byValues,
      },
    });
  }
};


export const useSaveWidgetLayout = () => {
  const uri = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  return (dashboardComponentId: string, position: IPosition) =>
    campfireMutation.run({
      url: `${uri}/vm/preferences/dashboard-component-layout/save`,
      method: 'POST',
      data: {
        dashboardComponentId,
        position
      },
    });
}