import { List, ListItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export const MyActivitiesListItemSkeleton = () => {
  return (
    <ListItem disableGutters>
      <Skeleton variant='rect' width='100%' height='130px' style={{ borderRadius: '8px' }} />
    </ListItem>
  );
};

export const MyActivitiesListSkeleton = () => {
  return (
    <List disablePadding>
      {Array.from(Array(4)).map(() => (
        <MyActivitiesListItemSkeleton key={uuidv4()} />
      ))}
    </List>
  );
};
