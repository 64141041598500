import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { FullscreenDialog } from '../../../../common/dialogs/FullscreenDialog';

interface BypassDialogProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onBypass: () => void;
}

export function BypassDialog({ open, onClose, onCancel, onBypass }: BypassDialogProps) {
  return (
    <FullscreenDialog
      open={open}
      close={onClose}
      title='Bypass Application'
      dialogActions={() => (
        <Box display='flex' style={{ gap: '8px' }}>
          <TabletButton variant='outlined' onClick={onCancel}>
            Cancel
          </TabletButton>
          <TabletButton variant='contained' color='primary' onClick={onBypass}>
            Bypass
          </TabletButton>
        </Box>
      )}
    >
      <Typography>
        Bypassing this volunteer’s application will mean they can login to Volaby, and complete their onboarding tasks
        at a later date.
      </Typography>
    </FullscreenDialog>
  );
}
