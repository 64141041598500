import React from 'react';
import { Box, Typography, Button, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import VolunteerImportImage from '../../../../../assets/volunteer_import.svg';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useButtonStyles } from '../styles/button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '3rem',
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
      },
    },
    image: {
      marginTop: '3rem',
    },
    heading: {
      color: theme.color.grey.neutralBrand800,
      fontSize: 24,
      marginTop: '2.5rem',
    },
    subHeading: {
      color: theme.color.grey.neutral400,
      fontSize: 18,
      marginTop: '2rem',
      textAlign: 'center',
    },
    button: {
      marginTop: '3rem',
      alignSelf: 'center',
    },
  })
);

interface Props {
  onNext: () => void;
}

export function LandingStep({ onNext }: Props) {
  const { isMobile } = useCampfireTheme();
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  return (
    <Box className={classes.container}>
      <img
        src={VolunteerImportImage}
        width={isMobile ? '100%' : '30%'}
        alt='Importing Volunteers'
        className={classes.image}
      />
      <Typography className={classes.heading}>Import Volunteers</Typography>
      {isMobile ? (
        <Typography className={classes.subHeading}>
          Volaby supports importing your volunteers, and a range of associated data, directly into our platform. Press
          &quot;Import Your Volunteer&quot; to get started
        </Typography>
      ) : (
        <Typography className={classes.subHeading}>
          Volaby supports uploading your volunteers, and a range of associated data, directly into our platform.
          <br />
          Press &quot;Import Your Volunteers&quot; to get started
        </Typography>
      )}
      <Button onClick={onNext} variant='contained' color='primary' classes={buttonClasses} className={classes.button}>
        Import Your Volunteers
      </Button>
    </Box>
  );
}
