import React, { memo, useMemo } from 'react';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { unpackToDateTime } from '@campfire/hot-date';
import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FiberNew, LabelImportantRounded } from '@material-ui/icons';
import { convertFromRaw, EditorState } from 'draft-js';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { MyNewsBulletin } from './__generated__/MyNewsBulletin';

const useThumbnailStyles = () => {
  const { theme } = useCampfireTheme();
  return makeStyles({
    thumbnail: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
      padding: '16px 0px 16px 20px',
    },
    body: {
      fontSize: '1em',
      color: '#545454',
    },
  })();
};

export const MyElementsNewsThumbnail = memo(
  ({ newsSingle, onClick }: { newsSingle: MyNewsBulletin; onClick: () => void }) => {
    const {
      title,
      content,
      dateCreated,
      createdBy,
      dateUpdated,
      lastUpdatedBy,
      pinned,
      hasVolunteerViewedBulletin,
    } = newsSingle;
    const { theme } = useCampfireTheme();
    const classes = useThumbnailStyles();

    const editorState = useMemo(() => EditorState.createWithContent(convertFromRaw(JSON.parse(content))), [content]);
    const authorName = lastUpdatedBy
      ? `${lastUpdatedBy.profile.preferredName} ${lastUpdatedBy.profile.lastName}`
      : createdBy && `${createdBy.profile.preferredName} ${createdBy.profile.lastName}`;
    const displayDate = dateUpdated ?? dateCreated;

    return (
      <>
        <Box className={classes.thumbnail} onClick={onClick}>
          <Box display='flex' alignItems='center' justifyContent={'space-between'} height={40}>
            <Typography color='textSecondary' variant='body2' style={{ fontSize: '12px' }}>
              {authorName} - {unpackToDateTime(displayDate).toFormat('cccc, d LLLL yyyy')}
            </Typography>
            <Box display='flex'>
              <Box display='inline-flex' alignItems='center'>
                <Box pr={1}>{!hasVolunteerViewedBulletin && <FiberNew color='error' fontSize='large' />}</Box>
              </Box>
              {pinned && (
                <Box display='flex' component='span' alignItems='center'>
                  <LabelImportantRounded style={{ color: theme.alert.yellow.light }} />
                  <Box ml={0.5} component='span'>
                    <Typography variant='body2' color='textSecondary' style={{ fontSize: '12px' }}>
                      {'Pinned'}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Typography
            variant='h5'
            style={{
              fontSize: '1.3em',
              fontWeight: 700,
              lineHeight: '19px',
              letterSpacing: '-0.02em',
              color: theme.color.grey.neutralBrand800,
            }}
          >
            {title}
          </Typography>
          <Box height={38} my={1} overflow='hidden' className={classes.body}>
            <CampfireRichEditor editorState={editorState} readOnly />
          </Box>
        </Box>
        <Divider />
      </>
    );
  }
);
