import React from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { CircularProgress } from '@material-ui/core';
import { useApiUrl } from '../../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../../global/network/useCampfireFetch';

export function SalesforceAuthorizationCallback() {
  const campfireFetch = useCampfireFetch<{ config: { accessToken: string; server: string } }>({ defer: true });
  const apiUrl = useApiUrl();
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const [code] = useQueryParam('code', StringParam);

  React.useEffect(() => {
    setIsLoading(true);
    campfireFetch
      .run({
        url: `${apiUrl}/wm/auth/callback`,
        method: 'post',
        data: {
          app: 'salesforce',
          code,
        },
      })
      .then(() => {
        setIsLoading(false);
        history.push('/admin/admin-console/apps');
      });
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  return null;
}
