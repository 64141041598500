import React, { useState } from 'react';
import { Box, Dialog, DialogContent, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useCampfireQuery } from '../../../../../../../../global/network/useCampfireQuery';
import {
  GetTaskDashboard,
  GetTaskDashboardVariables,
} from '../../../../../../../admin/tasks/Dashboard/__generated__/GetTaskDashboard';
import { TASK_DASHBOARD } from '../../../../../../../admin/tasks/Dashboard/task-dashboard-model.gql';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';
import { Task } from '../../../../../../../admin/tasks/Task/Task';
import { VolunteerSubmit } from '../../../../../../../admin/tasks/VolunteerSubmit/VolunteerSubmit';

export const TaskApprovalRAWidget = () => {
  const { theme, isMobile } = useCampfireTheme();
  const [taskId, setTaskId] = useState<string>();

  const { data, refetch, loading } = useCampfireQuery<GetTaskDashboard, GetTaskDashboardVariables>(TASK_DASHBOARD, {
    options: {
      variables: {
        status: 'pending',
      },
    },
  });

  const reviewingTasks = data?.vm.tasks || [];

  return (
    <>
      <Box width='100%' height='100%' overflow='scroll' maxHeight='max-content'>
        <Box display='flex' flexDirection='column' style={{ gap: 12, overflowY: 'scroll' }}>
          {loading ? (
            <Skeleton variant='text' width='100%' height='65px' />
          ) : reviewingTasks.length === 0 ? (
            <Typography
              style={{ textAlign: 'center', color: theme.color.grey.neutral400, fontSize: '15px', paddingTop: '12px' }}
            >
              <span role='img' aria-label='Congratulations!'>
                🎉
              </span>{' '}
              You&#39;re all up to date!
            </Typography>
          ) : (
            reviewingTasks.map((task) => <Task key={task.taskId} task={task} onClick={() => setTaskId(task.taskId)} />)
          )}
        </Box>
      </Box>
      {taskId && (
        <Dialog
          maxWidth='xs'
          open={!!taskId}
          fullScreen={isMobile}
          PaperProps={{ style: { minWidth: isMobile ? 'auto' : '640px', maxWidth: '640px', paddingBottom: '24px' } }}
        >
          <DialogContent>
            <VolunteerSubmit key={taskId} taskId={taskId} onClose={() => setTaskId(undefined)} onUpdate={refetch} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
