import { unpackToDateTime } from '@campfire/hot-date';
import { DateTime } from 'luxon';

import { ActivityTimelineItem } from './ActivityTimeline/__generated__/ActivityTimelineItem';
import {
  ActivitiesVolunteerData,
  ActivitiesVolunteerData_vm_volunteer_CICOs as CICOType,
} from './ActivityQueries/__generated__/ActivitiesVolunteerData';
import { VolunteerEnrolments } from './ActivityQueries/__generated__/VolunteerEnrolments';
import { VolunteerRosters } from './ActivityQueries/__generated__/VolunteerRosters';
import { ActivityApplicationFragments as ActivityAplication } from './ActivityQueries/__generated__/ActivityApplicationFragments';
import { ActivityWaitlistingFragments as ActivityWaitlisting } from './ActivityQueries/__generated__/ActivityWaitlistingFragments';
import { ActivityStatus, RosteredStatus, ActionType, SessionActionType } from './ActivityTypes';

export const isActivityCancelled = (
  activity?: Pick<ActivityTimelineItem, 'cancelledActivities'>,
  activityDate?: string
) => {
  return activity?.cancelledActivities.some(
    ({ activityDate: cancelledActivityDate }) => cancelledActivityDate === activityDate
  );
};

export const isActivityRostered = (
  activityId: string,
  activityDate: string,
  activityRosterings?: VolunteerRosters[]
) => {
  return activityRosterings
    ?.filter((rostering) => !rostering.dateRemoved)
    ?.some(
      (rostering) =>
        rostering.publishedRoster.activityDate === activityDate &&
        rostering.publishedRoster.activity.activityId === activityId
    );
};

export const isActivityAttended = (
  activityId: string,
  activityDate: string,
  activityRosterings?: VolunteerRosters[],
  CICOs?: CICOType[]
) => {
  const attendCICO = CICOs?.filter(
    (cico) =>
      cico.sessionReport.activityReport.activityDate === activityDate &&
      cico.sessionReport.activityReport.activity.activityId === activityId &&
      cico.checkIn &&
      cico.checkOut
  );

  const activeRostering = activityRosterings?.find(
    (rostering) =>
      rostering.publishedRoster.activityDate === activityDate &&
      rostering.publishedRoster.activity.activityId === activityId
  );

  return (
    attendCICO &&
    activeRostering &&
    attendCICO.length > 0 &&
    activeRostering.sessionRosterings.length === attendCICO.length
  );
};

export const isActivityRostering = (activityId: string, activityRosterings?: VolunteerRosters[]) => {
  return activityRosterings
    ?.filter(
      (rostering) =>
        !rostering.dateRemoved && unpackToDateTime(rostering.publishedRoster.activityDate) > DateTime.local()
    )
    .some((rostering) => rostering.publishedRoster.activity.activityId === activityId);
};
export const isActivityApplied = (activityId: string, activityApplications?: ActivityAplication[]) => {
  return activityApplications?.some((application) => application.activity.activityId === activityId);
};

export const isActivityWaitlised = (activityId: string, activityWaitlistings?: ActivityWaitlisting[]) => {
  return activityWaitlistings?.some((waitlisting) => waitlisting.activity.activityId === activityId);
};

export const isActivityEnrolled = (activityId: string, activityEnrolments?: VolunteerEnrolments[]) => {
  return activityEnrolments
    ?.filter((enrolment) => !enrolment.dateRemoved)
    .some((enrolment) => enrolment.activity.activityId === activityId);
};

export const isRosterEnrolled = (
  activityId: string,
  activityDate: string,
  activityEnrolments?: VolunteerEnrolments[]
) => {
  return activityEnrolments?.some(
    ({ activity, availabilities }) =>
      activity.activityId === activityId &&
      availabilities.some((availability) => availability.activityDate === activityDate)
  );
};

export const isActivityAvailable = (
  activityId: string,
  activityDate: string,
  activityEnrolments?: VolunteerEnrolments[]
) => {
  return activityEnrolments
    ?.filter(({ activity }) => activity.activityId === activityId)
    .filter(({ dateRemoved }) => !dateRemoved)
    .flatMap(({ availabilities }) => availabilities)
    .filter(({ dateRemoved }) => !dateRemoved)
    .some(({ activityDate: availableDate }) => availableDate === activityDate);
};

export const isActivityUnAvailable = (
  activityId: string,
  activityDate: string,
  activityEnrolments?: VolunteerEnrolments[]
) => {
  return activityEnrolments
    ?.filter(({ activity }) => activity.activityId === activityId)
    .filter(({ dateRemoved }) => !dateRemoved)
    .flatMap(({ unavailabilities }) => unavailabilities)
    .some(({ activityDate: availableDate }) => availableDate === activityDate);
};

export function getRosterStatus(
  activity: Pick<ActivityTimelineItem, 'activityId' | 'cancelledActivities'>,
  activityDate: string,
  volunteerData?: ActivitiesVolunteerData
): RosteredStatus {
  const activityEnrolments = volunteerData?.vm.volunteer?.activityEnrolments.filter(
    (enrolment) => !enrolment.dateRemoved
  );
  const activityRosterings = volunteerData?.vm.volunteer?.rosterings.filter((rostering) => !rostering.dateRemoved);
  const CICOs = volunteerData?.vm.volunteer?.CICOs;

  if (isActivityCancelled(activity, activityDate)) {
    return 'cancelled';
  }
  if (isActivityUnAvailable(activity.activityId, activityDate, activityEnrolments)) {
    return 'unavailable';
  }
  if (isActivityAttended(activity.activityId, activityDate, activityRosterings, CICOs)) {
    return 'attended';
  }
  if (isActivityRostered(activity.activityId, activityDate, activityRosterings)) {
    return 'rostered';
  }
  if (isActivityAvailable(activity.activityId, activityDate, activityEnrolments)) {
    return 'available';
  }
  return 'unsure';
}

export function getRosterActions(rosterStatus: RosteredStatus, hasOpenRoster?: boolean): ActionType[] {
  switch (rosterStatus) {
    case 'cancelled':
      return [];
    case 'rostered':
      return hasOpenRoster ? ['update', 'unavailable'] : [];
    case 'unavailable':
      return ['available'];
    case 'available':
      return ['update', 'unavailable'];
    case 'unsure':
      return ['available', 'unavailable'];
    case 'attended':
      return [];
    default:
      return [];
  }
}

export const getActivityStatus = (activityId: string, volunteerData?: ActivitiesVolunteerData): ActivityStatus => {
  if (isActivityApplied(activityId, volunteerData?.vm.volunteer?.activityApplications)) {
    return 'applied';
  }
  if (isActivityWaitlised(activityId, volunteerData?.vm.volunteer?.activityWaitlistings)) {
    return 'waitlisted';
  }
  if (isActivityEnrolled(activityId, volunteerData?.vm.volunteer?.activityEnrolments)) {
    return 'joined';
  }
  return 'not on team';
};

export const getActivityActions = (activityStatus: ActivityStatus, isRestrictedActivity: boolean): ActionType[] => {
  switch (activityStatus) {
    case 'applied':
    case 'waitlisted':
    case 'joined':
      return isRestrictedActivity ? [] : ['withdraw'];
    case 'not on team':
      return ['apply'];
    default:
      return [];
  }
};

export const getSessionActions = (
  sessionId: string,
  activity: Pick<ActivityTimelineItem, 'activityId' | 'cancelledActivities'>,
  activityDate: string,
  volunteerData?: ActivitiesVolunteerData
): SessionActionType => {
  const activityStatus = getRosterStatus(activity, activityDate, volunteerData);
  if (['available', 'unsure'].includes(activityStatus)) {
    if (
      volunteerData?.vm.volunteer?.activityEnrolments
        .flatMap((enrolment) => enrolment.availabilities)
        .filter((available) => available.activityDate === activityDate)
        .flatMap((available) => available.sessionAvailabilities)
        .find((sessionAvailablity) => sessionAvailablity.session.sessionId === sessionId)
    ) {
      return 'unavailable';
    }
    return 'available';
  }
  if (activityStatus === 'rostered') {
    if (
      volunteerData?.vm.volunteer?.rosterings
        .filter((rostering) => !rostering.dateRemoved)
        .flatMap((rostering) => rostering.sessionRosterings)
        .find((sessionRostering) => sessionRostering.session.sessionId === sessionId)
    ) {
      return 'withdraw';
    }
    return 'roster';
  }
  return undefined;
};
