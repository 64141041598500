import { TaskFieldTypeEnum } from '../../../../screens/admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/admin-console-volunteer-profile-model';
import { arrayHead } from '../../../functions/array-head';

type VolunteerProfileHardCodedFields = {
  [key: string]: {
    optional: boolean;
    __typename: TaskFieldTypeEnum;
    name: string;
    description?: string;
  };
};

export const volunteerProfileHardCodedFields: VolunteerProfileHardCodedFields = {
  firstName: {
    optional: false,
    __typename: 'VOLUNTEER_ShortTextTaskFieldType',
    name: 'First Name',
    description: undefined,
  },
  lastName: {
    optional: false,
    __typename: 'VOLUNTEER_ShortTextTaskFieldType',
    name: 'Last Name',
    description: undefined,
  },
  preferredName: {
    optional: true,
    __typename: 'VOLUNTEER_ShortTextTaskFieldType',
    name: 'Preferred Name',
    description: `You can leave this blank if it's the same as your first name`,
  },
  dob: {
    optional: false,
    __typename: 'VOLUNTEER_DateTaskFieldType',
    name: 'Date of Birth',
    description: undefined,
  },
  contactNumber: {
    optional: false,
    __typename: 'VOLUNTEER_PhoneTaskFieldType',
    name: 'Contact Number',
    description: 'Preferably a mobile number (include your country code)',
  },
  programId: {
    optional: false,
    __typename: 'VOLUNTEER_DropdownTaskFieldType',
    name: 'Choose a Program',
    description: 'Select a program to start with',
  },
};

export function getVolunteerProfileHardcodedFieldValues(profile: {
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  dateOfBirth: string | null;
  contactNumber: string | null;
  programs: Array<{ name: string }>;
}) {
  return [
    {
      ...volunteerProfileHardCodedFields.firstName,
      value: profile?.firstName ?? '',
    },
    {
      ...volunteerProfileHardCodedFields.lastName,
      value: profile?.lastName ?? '',
    },
    {
      ...volunteerProfileHardCodedFields.preferredName,
      value: profile?.preferredName ?? '',
    },
    {
      ...volunteerProfileHardCodedFields.dob,
      value: profile?.dateOfBirth ?? '',
    },
    {
      ...volunteerProfileHardCodedFields.contactNumber,
      value: profile?.contactNumber ?? '',
    },
    {
      ...volunteerProfileHardCodedFields.programId,
      value: arrayHead(profile.programs ?? [])?.name ?? '',
    },
  ];
}
