import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  networkStatusBar: {
    backgroundColor: 'orange',
    color: '#000',
    width: '100%',
    position: 'fixed',
    textAlign: 'center',
  },
});

const NetworkStatusBar = () => {
  const [connection, setConnection] = useState(true);

  // Listen for messages from sw - This should live in the Status Bar Component
  navigator.serviceWorker.addEventListener('message', (event) => {
    // Handle updates in connection status
    if (event.data.connected !== undefined) {
      setConnection(event.data.connected);
    }
  });

  const classes = useStyles();
  return connection ? null : (
    <div className={classes.networkStatusBar}>
      <p>You are currently offline.</p>
    </div>
  );
};

export { NetworkStatusBar };
