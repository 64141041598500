import { Grid, Typography } from '@material-ui/core';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { ErrorOutline } from '@material-ui/icons';
import React from 'react';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const ActivityInSuspendedProgramWarningLabelV2 = () => {
  const { theme } = useCampfireTheme();

  return (
    <Grid container>
      <TitularTooltip
        placement='right'
        description='This activity is in a suspended program and will be suspended when created'
      >
        <Grid
          item
          style={{
            marginBottom: 8,
            padding: '2px 8px',
            backgroundColor: theme.status.amber.light,
            borderRadius: 4,
            width: 'auto',
          }}
        >
          <Typography
            variant='overline'
            align='center'
            display='block'
            style={{
              fontWeight: 'bold',
              color: theme.palette.common.white,
              cursor: 'default',
            }}
          >
            <ErrorOutline
              style={{
                fontSize: 16,
                marginRight: 8,
                marginBottom: -3,
                marginTop: 8,
              }}
            />

            {`This activity will be suspended`}
          </Typography>
        </Grid>
      </TitularTooltip>
    </Grid>
  );
};

export { ActivityInSuspendedProgramWarningLabelV2 };
