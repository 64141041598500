import { Typography } from '@material-ui/core';
import React from 'react';

export const LockedSectionContent = ({ text }: { text: string }) => {
  return (
    <Typography variant='subtitle2' color='textSecondary' style={{ paddingTop: '6px' }}>
      {text}
    </Typography>
  );
};
