import { useContext } from 'react';
import { SessionContext } from '../../global/auth/SessionContext';
import { AllRoutesMaps } from './Navigation';

export const useRoutesMap = (): AllRoutesMaps => {
  const sessionContext = useContext(SessionContext);

  if (!sessionContext) throw new Error('useRoutesMap must be initiated within an SessionContextProvider');
  if (!sessionContext.routesMaps)
    throw new Error('useRoutesMap requires a valid AllRoutesMaps to be present in the SessionContext');

  return sessionContext.routesMaps;
};
