import { ApolloQueryResult, QueryLazyOptions } from '@apollo/client';
import { encodeDate, encodeTime } from '@campfire/hot-date';
import { FugFile } from '@campfire/file-upload-gallery';
import { DateTime } from 'luxon';
import React, { useContext, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { arrayHead } from '../../../common/functions/array-head';
import { useSnackbar } from '../../../global/config/useSnackbar';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import {
  SaveProgramValues,
  SaveProgramVolunteersValues,
  useApproveBulkProgramApplications,
  useApproveProgramApplication,
  useDeleteProgramFetch,
  useSaveProgramFetch,
  useSaveProgramManagersFetch,
  useSaveProgramVolunteersFetch,
  useUpdateProgramImageFetch,
  useWithdrawBulkProgramApplications,
  useWithdrawProgramApplication,
} from './program-management-actions';
import {
  PROGRAM_MANAGEMENT_GET_ACTIVE_VOLUNTEERS,
  PROGRAM_MANAGEMENT_GET_ALL_PROGRAMS,
} from './program-management.gql';
import { ProgramManagementGetAllPrograms } from './__generated__/ProgramManagementGetAllPrograms';
import { ProgramManagementProgram } from './__generated__/ProgramManagementProgram';
import { useDeepEffect } from '../../../hooks/useDeepEffect';
import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';
import {
  ProgramManagementGetActiveVolunteers,
  ProgramManagementGetActiveVolunteersVariables,
} from './__generated__/ProgramManagementGetActiveVolunteers';
import {
  ActivityFormSession,
  ActivityFormValues,
  parseRecurrence,
} from '../../program-manager/activity-management/activity-form-v2/ActivityFormV2';
import {
  NO_REPORT_IMPACT_TRACKED,
  NO_REPORT_NO_IMPACT,
  useSaveActivityFetch,
} from '../../program-manager/activity-management/activity-form-v2/activity-form-actions-v2';

export interface RemoveProgramManagerValues {
  managerId: string;
  removedProgramManagerId: string;
}

interface AddProgramManagerValues {
  volunteerId: string;
  addedProgramId: string;
}

interface ProgramManagementContextInterface {
  programs: ProgramManagementProgram[];
  getAllProgramsIsLoading: boolean;
  selectedProgramId?: string;
  setSelectedProgramId: (x?: string) => void;
  runSaveProgram: (x: SaveProgramValues, onSuccess?: () => void) => void;
  runUpdateProgramImage: (x: string, y: FugFile, handleSuccess?: () => void) => void;
  updateProgramImageLoading: boolean;
  saveProgramIsLoading: boolean;
  runDeleteProgram: (programId: string, onSuccess?: () => void) => void;
  deleteProgramIsLoading: boolean;
  runSaveProgramVolunteers: (x: SaveProgramVolunteersValues, handleSuccess: () => void) => void;
  saveProgramVolunteersIsLoading: boolean;
  runAddProgramManager: (x: AddProgramManagerValues, handleSuccess: () => void) => void;
  runRemoveProgramManager: (x: RemoveProgramManagerValues, handleSuccess: () => void) => void;
  updateProgramManagersIsLoading: boolean;
  updateProgramManagersIsFulfilled: boolean;
  runSaveActivity: (x: ActivityFormValues, handleSuccess: () => void) => void;
  saveActivityIsLoading: boolean;
  saveActivityIsFulfilled: boolean;
  compact: boolean;
  resetProgramSelection: () => void;
  runAddToTeam: (id: string, handleSuccess?: () => void) => void;
  runWithdrawProgramApplication: (id: string, handleSuccess?: () => void) => void;
  runAddBulkVolunteersToTeam: (ids: (string | number)[], programId: string, handleSuccess?: () => void) => void;
  runWithdrawBulkApplications: (ids: (string | number)[], programId: string, handleSuccess?: () => void) => void;
  getActiveVolunteers: (options?: QueryLazyOptions<ProgramManagementGetActiveVolunteersVariables> | undefined) => void;
  getActiveVolunteersData: ProgramManagementGetActiveVolunteers | undefined;
  getActiveVolunteersLoading: boolean;
  getActiveVolunteersRefetch:
    | ((variables?: undefined) => Promise<ApolloQueryResult<ProgramManagementGetActiveVolunteers>>)
    | undefined;
  isShowAlert: boolean;
}

interface ProgramManagementProviderProps {
  children: React.ReactNode;
}

const ProgramManagementContext = React.createContext<ProgramManagementContextInterface | undefined>(undefined);

const ProgramManagementProvider = (props: ProgramManagementProviderProps) => {
  const [selectedProgramId, setSelectedProgramIdQueryParam] = useQueryParam('pid', StringParam);
  const [previousProgramIdSelection, setPreviousProgramIdSelection] = useState<undefined | string>();
  const [isShowAlert, setIsShowAlert] = useState(false);

  const { setSnackbar } = useSnackbar();
  const { isXs, isSm } = useCampfireTheme();

  const saveProgram = useSaveProgramFetch();
  const deleteProgram = useDeleteProgramFetch();
  const saveProgramVolunteers = useSaveProgramVolunteersFetch();
  const saveProgramManagers = useSaveProgramManagersFetch();
  const saveActivity = useSaveActivityFetch();
  const updateProgramImage = useUpdateProgramImageFetch();
  const approveProgramApplication = useApproveProgramApplication();
  const withdrawProgramApplication = useWithdrawProgramApplication();
  const approveBulkProgramApplications = useApproveBulkProgramApplications();
  const withdrawBulkProgramApplications = useWithdrawBulkProgramApplications();

  const setSelectedProgramId = (value: undefined | string) => {
    if ((!value || value === 'new') && selectedProgramId) {
      setPreviousProgramIdSelection(selectedProgramId);
    }
    setSelectedProgramIdQueryParam(value);
  };

  const resetProgramSelection = () => {
    setSelectedProgramIdQueryParam(programs.length > 0 ? previousProgramIdSelection : undefined);
  };

  const { data: allPrograms, loading: getAllProgramsIsLoading, refetch: refetchData } = useCampfireQuery<
    ProgramManagementGetAllPrograms,
    undefined
  >(PROGRAM_MANAGEMENT_GET_ALL_PROGRAMS);

  const [
    getActiveVolunteers,
    { data: getActiveVolunteersData, loading: getActiveVolunteersLoading, refetch: getActiveVolunteersRefetch },
  ] = useCampfireLazyQuery<ProgramManagementGetActiveVolunteers, ProgramManagementGetActiveVolunteersVariables>(
    PROGRAM_MANAGEMENT_GET_ACTIVE_VOLUNTEERS
  );

  const compact = useMemo(() => isXs || isSm, [isXs, isSm]);
  const programs = useMemo(() => allPrograms?.vm.programs.sort((a, b) => a.name.localeCompare(b.name)) ?? [], [
    allPrograms,
  ]);

  useDeepEffect(() => {
    if (compact || programs.some((program) => program.programId === selectedProgramId)) {
      return;
    }

    setSelectedProgramIdQueryParam(arrayHead(programs)?.programId);
  }, [programs]);

  const runSaveProgram = (values: SaveProgramValues, onSuccess?: () => void) => {
    saveProgram
      .run({
        programId: values.programId,
        name: values.name,
        description: values.description,
        active: values.active,
        isHidden: values.isHidden,
        isRestrictedProgram: values.isRestrictedProgram,
      })
      .then((res: any) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save program',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Program saved',
          variant: 'success',
        });
        if (refetchData) refetchData();
        if (onSuccess) onSuccess();
        if (selectedProgramId !== res.data.data.programId) setSelectedProgramIdQueryParam(res.data.data.programId);
      })
      .catch(() => setSnackbar({ open: true, message: 'Unable to save program', variant: 'error' }));
  };

  const runUpdateProgramImage = (programId: string, image: FugFile, handleSuccess?: () => void) => {
    updateProgramImage
      .run({
        programId,
        image,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            variant: 'error',
            message: 'Unable to update image',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Image updated successfully',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Failed to update image',
          variant: 'error',
        })
      );
  };

  const runDeleteProgram = (programId: string, onSuccess?: () => void) => {
    deleteProgram
      .run({
        programId: programId,
      })
      .then((res: any) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to delete program',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Program deleted',
          variant: 'success',
        });

        if (refetchData) refetchData();
        if (onSuccess) onSuccess();
        setSelectedProgramIdQueryParam(undefined);
      })
      .catch(() => setSnackbar({ open: true, message: 'Unable to delete program', variant: 'error' }));
  };

  const runSaveProgramVolunteers = (values: SaveProgramVolunteersValues, handleSuccess: () => void) => {
    saveProgramVolunteers
      .run({
        programId: values.programId,
        volunteerIds: values.volunteerIds,
      })
      .then((res: any) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save volunteers',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Volunteers saved',
          variant: 'success',
        });

        handleSuccess();
      })
      .catch(() => setSnackbar({ open: true, message: 'Unable to save volunteers', variant: 'error' }));
  };

  const runRemoveProgramManager = (values: RemoveProgramManagerValues, handleSuccess: () => void) => {
    saveProgramManagers
      .run({
        managerId: values.managerId,
        addedProgramIds: [],
        removedProgramManagerIds: [values.removedProgramManagerId],
      })
      .then((res: any) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to remove manager',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Removed manager',
          variant: 'success',
        });
        setIsShowAlert(false);
        handleSuccess();
      })
      .catch(() => setSnackbar({ open: true, message: 'Unable to remove manager', variant: 'error' }));
  };

  const runAddProgramManager = (values: AddProgramManagerValues, handleSuccess: () => void) => {
    saveProgramManagers
      .run({
        volunteerId: values.volunteerId,
        addedProgramIds: [values.addedProgramId],
        removedProgramManagerIds: [],
      })
      .then((res: any) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to add manager',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Added new manager',
          variant: 'success',
        });
        setIsShowAlert(true);
        handleSuccess();
      })
      .catch(() => setSnackbar({ open: true, message: 'Unable to add manager', variant: 'error' }));
  };

  const runSaveActivity = (values: ActivityFormValues, handleSuccess: () => void) => {
    const startDate = values.startDate ? DateTime.fromJSDate(values.startDate) : DateTime.local();
    const endDate = values.endDate ? DateTime.fromJSDate(values.endDate) : undefined;
    const recurrence =
      values.frequency === 'custom' ? values.recurrence : values.frequency ? parseRecurrence(values) : undefined;
    const activityLocation = values.location
      ? {
          activityLocationId: values.location.activityLocationId,
          placesAddress: values.location,
          timeZone: startDate.zone.name,
          comments: '',
        }
      : null;

    const sessions = values.sessions.map((session: ActivityFormSession) => ({
      sessionId: session.sessionId || undefined,
      name: session.name,
      description: session.description,
      minVolunteers: session.minVolunteers,
      maxVolunteers: session.maxVolunteers || undefined,
      startTime: session.startTime ? encodeTime(DateTime.fromJSDate(session.startTime)) : null,
      endTime: session.endTime ? encodeTime(DateTime.fromJSDate(session.endTime)) : null,
      reportTypeId:
        session.reportType.reportTypeId === NO_REPORT_IMPACT_TRACKED ||
        session.reportType.reportTypeId === NO_REPORT_NO_IMPACT
          ? null
          : session.reportType.reportTypeId,
      activityLocation: session.activityLocation
        ? {
            activityLocationId: session.activityLocation.activityLocationId,
            placesAddress: session.activityLocation,
            timeZone: startDate.zone.name,
            comments: '',
          }
        : null,
      autoReport: session.autoReport,
    }));

    saveActivity
      .run({
        activityId: values.activityId !== '' ? values.activityId : undefined,
        isRestrictedActivity: values.isRestrictedActivity,
        isHidden: values.isHidden,
        maxTeam: values.maxTeam,
        hasOpenRoster: values.hasOpenRoster,
        allowCICO: values.allowCICO,
        programId: values.programId,
        name: values.activityName,
        description: JSON.stringify(values.activityDescription),
        startDate: encodeDate(startDate),
        endDate: endDate ? encodeDate(endDate) : undefined,
        activityLocation: values.locationType === 'local' ? activityLocation : null,
        remoteLocation: values.locationType === 'remote' ? values.remoteLocation ?? undefined : undefined,
        recurrences: recurrence ? [recurrence] : undefined,
        sessions: sessions,
        addedAttachments: values.addedAttachments,
        attachmentCreationTokens: values.creationTokens,
        removedAttachmentIds: values.removedAttachmentIds,
        activityTagIds: values.activityTagIds,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save activity',
            variant: 'error',
          });

          return;
        }
        setSnackbar({
          open: true,
          message: 'Activity saved',
          variant: 'success',
        });

        handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save activity',
          variant: 'error',
        })
      );
  };

  const runAddToTeam = (id: string, handleSuccess?: () => void) => {
    approveProgramApplication
      .run({
        programApplicationId: id,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to add to team',
            variant: 'error',
          });
          return;
        }
        if (handleSuccess) {
          handleSuccess();
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: 'Added to team successfully',
        });
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to add to team',
          variant: 'error',
        })
      );
  };

  const runWithdrawProgramApplication = (programApplicationId: string, handleSuccess?: () => void) => {
    withdrawProgramApplication
      .run({ programApplicationId })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to withdraw',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Withdrawn program application successfully',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to withdraw',
          variant: 'error',
        })
      );
  };

  const runAddBulkVolunteersToTeam = (ids: (string | number)[], programId: string, handleSuccess?: () => void) => {
    approveBulkProgramApplications
      .run({
        programApplicationIds: ids,
        programId: programId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to add volunteers to team',
            variant: 'error',
          });
          return;
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: `Added ${ids.length} volunteers to team successfully`,
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to add volunteers to team',
          variant: 'error',
        })
      );
  };

  const runWithdrawBulkApplications = (ids: (string | number)[], programId: string, handleSuccess?: () => void) => {
    withdrawBulkProgramApplications
      .run({
        programApplicationIds: ids,
        programId: programId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to withdraw applications',
            variant: 'error',
          });
          return;
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: `${ids.length} applications withdrawn successfully`,
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to withdraw applications',
          variant: 'error',
        })
      );
  };

  const value = {
    programs,
    getAllProgramsIsLoading,
    selectedProgramId,
    setSelectedProgramId,
    runSaveProgram,
    saveProgramIsLoading: saveProgram.isLoading,
    runUpdateProgramImage,
    updateProgramImageLoading: updateProgramImage.isLoading,
    runDeleteProgram,
    deleteProgramIsLoading: deleteProgram.isLoading,
    runSaveProgramVolunteers,
    saveProgramVolunteersIsLoading: saveProgramVolunteers.isLoading,
    runAddProgramManager,
    runRemoveProgramManager,
    updateProgramManagersIsLoading: saveProgramManagers.isLoading,
    updateProgramManagersIsFulfilled: saveProgramManagers.isFulfilled,
    runSaveActivity,
    saveActivityIsLoading: saveActivity.isLoading,
    saveActivityIsFulfilled: saveActivity.isFulfilled,
    compact,
    resetProgramSelection,
    runAddToTeam,
    runWithdrawProgramApplication,
    runAddBulkVolunteersToTeam,
    runWithdrawBulkApplications,
    getActiveVolunteers,
    getActiveVolunteersData,
    getActiveVolunteersLoading,
    getActiveVolunteersRefetch,
    isShowAlert,
  };

  return <ProgramManagementContext.Provider value={value} {...props} />;
};

export const useProgramManagementContext = () => {
  const value = useContext(ProgramManagementContext);
  if (!value) throw new Error('Must be inside of a ProgramManagementProvider');
  return value;
};

export { ProgramManagementProvider };
