import React from 'react';
import { Typography, Box, Button, Theme } from '@material-ui/core';
import { Close, WarningRounded } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import { FullscreenDialog } from '../../../../common/dialogs/FullscreenDialog';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'capitalize',
      fontSize: '14px',
      fontWeight: 700,
      minWidth: '80px',
      alignSelf: 'stretch',
      marginLeft: '0.5rem',
    },
    cancel: {
      color: theme.color.error[900],
      padding: '1rem',
      borderColor: theme.color.error[900],
      borderRadius: '6px',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    bottomText: {
      color: theme.color.grey.neutral200,
      fontSize: '0.8em',
    },
  })
);

interface Props {
  open: boolean;
  onClose: () => void;
  onRemove: (message: string) => void;
  title: string;
  warningText: string;
  bottomText: string;
}

export function WaitlistRemovingMessage({ open, onClose, title, warningText, bottomText, onRemove }: Props) {
  const [value, setValue] = React.useState(EditorState.createEmpty());
  const { isMobile, theme } = useCampfireTheme();
  const classes = useStyles();
  return (
    <FullscreenDialog
      open={open}
      close={onClose}
      title={title}
      fullScreen={isMobile}
      dialogActions={() => (
        <Box display='flex' alignItems='center'>
          <Typography className={classes.bottomText}>{bottomText}</Typography>
          <Box display='flex' alignItems='center'>
            <Button className={classes.button} onClick={onClose}>
              Go Back
            </Button>
            <Button
              className={classes.button}
              classes={{ root: classes.cancel }}
              startIcon={<Close style={{ fontSize: '1.5rem' }} />}
              variant='outlined'
              onClick={() => onRemove(stateToHTML(value.getCurrentContent()))}
            >
              Remove
            </Button>
          </Box>
        </Box>
      )}
    >
      <Box width='100%' display='flex' alignItems='stretch'>
        <Box bgcolor={theme.alert.yellow.light} width='4px' />
        <Box flex={1} display='flex' alignItems='center' bgcolor={theme.alert.yellow.extraLight}>
          <WarningRounded style={{ color: theme.alert.yellow.light, margin: '1rem' }} />
          <Typography style={{ color: theme.alert.yellow.dark, fontSize: '.9em' }}>{warningText}</Typography>
        </Box>
      </Box>
      <Box mt='1.25rem'>
        <CampfireRichEditor
          editorState={value}
          setEditorState={setValue}
          placeholder='Include a message to the volunteer about why they have been removed'
        />
      </Box>
    </FullscreenDialog>
  );
}
