import React from 'react';
import { ClickAwayListener, IconButton, Button, Box, Theme } from '@material-ui/core';
import { ExpandMore, Delete, Edit } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { TooltipMenu } from '../tooltip-menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    availabilityButton: {
      textTransform: 'none',
      borderRadius: 6,
      fontSize: '12px',
    },
    tooltip: {
      background: '#616161',
      borderRadius: 6,
    },
    arrow: {
      '&::before': {
        background: '#616161',
      },
    },
    deleteButton: {
      color: theme.color.card.cancelled,
      marginTop: '8px',
      marginBottom: '8px',
      textTransform: 'none',
      width: '100%',
    },
    editButton: {
      color: theme.alert.blue.extraLight,
      display: 'flex',
      alignItems: 'center',
      paddingTop: '10px',
      justifyContent: 'flex-start',
      '& p': {
        fontSize: '14px',
      },
      '& svg': {
        fontSize: '16px',
      },
      textTransform: 'none',
      width: '100%',
    },
  })
);

interface Props {
  onClick: (type: string) => void;
}

export function CommentActions({ onClick }: Props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event: any) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClickAction = (event: any, type: string) => {
    event.stopPropagation();
    onClick(type);
    handleTooltipClose();
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <TooltipMenu
          open={open}
          onClose={handleTooltipClose}
          title={
            <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ padding: '0 5px' }}>
              <Button className={classes.editButton} startIcon={<Edit />} onClick={(e) => handleClickAction(e, 'edit')}>
                Edit
              </Button>
              <Button
                className={classes.deleteButton}
                startIcon={<Delete />}
                onClick={(e) => handleClickAction(e, 'remove')}
              >
                Delete
              </Button>
            </Box>
          }
        >
          <IconButton onClick={handleTooltipOpen} style={{ padding: '8px', marginLeft: '4px' }}>
            <ExpandMore />
          </IconButton>
        </TooltipMenu>
      </div>
    </ClickAwayListener>
  );
}
