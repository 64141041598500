import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { MyElementsSelectTimeFrame, myElementsSelectTimeFrameValues } from './MyElementsPersonalCard';
import { MyElementsPersonalImpactBlock } from './MyElementsPersonalImpactBlock';
import { MyElementsTeamImpactBlock } from './MyElementsTeamImpactBlock';
import { MyElementsNumericFieldStatistics } from './__generated__/MyElementsNumericFieldStatistics';

interface MyElementsImpactTabProps {
  impactLoading: boolean;
  initialLoading: boolean;
  teams: number;
  hours: number;
  attended: number;
  locations: number;
  fieldStatistics: MyElementsNumericFieldStatistics[];
  orgName: string;
  selectedTimeFrame: MyElementsSelectTimeFrame;
  setSelectedTimeFrame: (value: MyElementsSelectTimeFrame) => void;
}
const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px 16px',
    [theme.breakpoints.up('xl')]: {
      padding: '32px 128px',
    },
  },
}));

const MyElementsImpactTab = (props: MyElementsImpactTabProps) => {
  const {
    initialLoading,
    teams,
    orgName,
    impactLoading,
    selectedTimeFrame,
    setSelectedTimeFrame,
    fieldStatistics,
    hours,
    attended,
    locations,
  } = props;
  const styles = useStyles();

  return (
    <Grid item container className={styles.container}>
      <Grid item sm={12} md={10}>
        <Box marginLeft={2}>
          <MyElementsPersonalImpactBlock
            impactLoading={impactLoading}
            initialLoading={initialLoading}
            timeFrameValues={myElementsSelectTimeFrameValues}
            selectedTimeFrame={selectedTimeFrame}
            setSelectedTimeFrame={setSelectedTimeFrame}
            hours={hours}
            attended={attended}
            locations={locations}
            hoursDifference={0}
          />
        </Box>
      </Grid>

      <Grid item sm={12} md={12}>
        <Box marginLeft={2}>
          <MyElementsTeamImpactBlock
            impactLoading={impactLoading}
            initialLoading={initialLoading}
            fieldStatistics={fieldStatistics}
            teams={teams}
            orgName={orgName}
            selectedTimeFrame={selectedTimeFrame}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default MyElementsImpactTab;
