import React from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Box, Theme, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useCommonStyles } from './commonStyles';
import { App } from './AppTypes';

type Props = {
  app: App;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appItemWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    appInfoWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    appTitle: {
      fontWeight: 900,
      fontSize: '0.9375rem',
      lineHeight: '1.125rem',
      letterSpacing: '-0.02em',
    },
    image: {
      marginRight: '1.125rem',
    },
    link: {
      textDecoration: 'none',
      width: '100%',
      color: '#000',
    },
  })
);

export const AppItem = (props: Props) => {
  const { id, name, logo, description, integrationDescription, active, disabled } = props.app;
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const { theme } = useCampfireTheme();
  const match = useRouteMatch();
  const link = active ? `${match.path}/${id}/settings` : `${match.path}/${id}`;

  return (
    <Box className={classes.appItemWrapper}>
      <Box className={classes.appInfoWrapper}>
        <img src={logo} alt={`${name} logo`} className={classes.image} />
        <Box>
          <Typography className={classes.appTitle}>{name}</Typography>
          <Typography className={commonStyles.text} style={{ paddingTop: '.2em' }}>
            {description}
          </Typography>
          <Typography className={commonStyles.text} style={{ paddingTop: '.1em' }}>
            {integrationDescription}
          </Typography>
        </Box>
      </Box>
      <Box>
        {disabled ? (
          <TabletButton
            variant='contained'
            disabled
            style={{
              backgroundColor: theme.color.grey.neutral400,
              color: '#ffffff',
              boxSizing: 'border-box',
              borderRadius: '4px',
              padding: '7px 12px',
              fontWeight: 600,
              fontSize: '13px',
              whiteSpace: 'nowrap',
            }}
          >
            {'Coming Soon'}
          </TabletButton>
        ) : (
          <Link to={link} className={classes.link}>
            <TabletButton
              variant='contained'
              endIcon={<ChevronRight style={{ width: '14px', height: '14px' }} />}
              style={{
                backgroundColor: active ? theme.color.grey.neutral200 : theme.color.secondary.main900,
                color: '#ffffff',
                boxSizing: 'border-box',
                borderRadius: '4px',
                padding: '7px 12px',
                fontWeight: 600,
                fontSize: '13px',
                whiteSpace: 'nowrap',
              }}
            >
              {active ? 'Manage' : 'Setup'}
            </TabletButton>
          </Link>
        )}
      </Box>
    </Box>
  );
};
