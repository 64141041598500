import { withStyles, makeStyles, createStyles } from '@material-ui/styles';
import { InputBase } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: (props: { status?: string }) => {
      const active = props.status === 'active';
      return {
        fontSize: 14,
        fontWeight: 600,
        borderRadius: '5px',
        border: active ? `1px solid ${theme.palette.primary.main}` : '1px solid #ced4da',
        padding: '5.5px 0px 5.5px 12px',
        color: active ? theme.palette.primary.main : 'inherit',
        '&:focus, :active': {
          borderRadius: '5px',
          backgroundColor: 'transparent',
        },
      };
    },
    input: {
      padding: 0,
    },
  });

const primaryBorderStyles = (theme: Theme) =>
  createStyles({
    root: () => ({
      fontSize: 14,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      padding: '4px 3px 4px 8px',
      borderRadius: '5px',
      '& svg': {
        color: theme.palette.primary.main,
      },
    }),
  });

const PrimaryBorderInput = withStyles(primaryBorderStyles)(InputBase);
const BorderSelectInput = withStyles(styles)(InputBase);

const useFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
      minHeight: theme.appShell.subHeader.height,
      padding: '8px 8px',
      flexWrap: 'nowrap',
    },
    subheaderGridItem: {
      whiteSpace: 'nowrap',
      margin: '4px 8px',
    },
    subheaderGridItemNew: {
      whiteSpace: 'nowrap',
      marginTop: '2px',
    },
    menuItem: {
      margin: '4px 0',
      minWidth: 200,
      height: 35,
      '&.Mui-selected': {
        backgroundColor: 'transparent',
      },
    },
    listItem: {
      padding: 0,
    },
    listItemIcon: {
      minWidth: 'auto',
    },
    checkBox: {
      padding: '8px 16px 8px 0px',
    },
    checkBoxSelectField: {
      '&.Mui-checked:hover': {
        color: '#796FFF',
      },
    },
    icon: {},
    textField: {
      marginRight: 16,
    },
    divider: {
      margin: '8px 0',
    },
    roundBox: {
      borderRadius: '50%',
      color: 'textPrimary',
      background: theme.palette.grey[200],
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 4px',
      '&:hover': {
        background: theme.palette.grey[400],
        color: 'textPrimary',
      },
      [theme.breakpoints.up('xs')]: {
        width: 35,
        height: 35,
      },
      [theme.breakpoints.up('md')]: {
        width: 40,
        height: 40,
      },
    },
  })
);

export { BorderSelectInput, PrimaryBorderInput, useFilterStyles };
