import React from 'react';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box, Grid } from '@material-ui/core';
import { useProgramManagementContext } from './ProgramManagementContext';
import { ProgramManagementSideNav } from './program-management-side-nav/ProgramManagementSideNav';
import { ProgramEditor } from './program-management-editor/ProgramEditor';

const ManageProgramsPage = () => {
  const {
    getAllProgramsIsLoading,
    saveProgramIsLoading,
    updateProgramManagersIsLoading,
    deleteProgramIsLoading,
    saveActivityIsLoading,
    saveProgramVolunteersIsLoading,
    selectedProgramId,
  } = useProgramManagementContext();

  const isInitialLoading = getAllProgramsIsLoading;

  const isLoading =
    getAllProgramsIsLoading ||
    saveProgramIsLoading ||
    updateProgramManagersIsLoading ||
    deleteProgramIsLoading ||
    saveActivityIsLoading ||
    saveProgramVolunteersIsLoading;

  return (
    <Box position='relative'>
      <LinearProgressOverlay isLoading={isLoading} />
      <Grid
        container
        style={{
          overflow: 'hidden',
          flexWrap: 'nowrap',
          height: '100%',
        }}
      >
        <ProgramManagementSideNav />
        {isInitialLoading || !selectedProgramId ? null : <ProgramEditor />}
      </Grid>
    </Box>
  );
};

export { ManageProgramsPage };
