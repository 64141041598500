import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useInfoStyles = makeStyles((theme: Theme) => createStyles({
  alertContainer: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.alert.blue.extraLight,
    padding: '1rem',
    justifyContent: 'space-between',
    borderRadius: '6px'
  },
  alertText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.alert.blue.medium,
    '&>p': {
      fontSize: '14px'
    }
  },
}));
