import { TitularTooltip } from '@campfire/titular-tooltip';
import React from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { List, ListItem, Grid, IconButton, Typography } from '@material-ui/core';
import { Star as StarIcon, Clear as ClearIcon } from '@material-ui/icons';
import { unpackToDate } from '@campfire/hot-date';
import { CategoryEditorInitialValues } from './CategoryEditor';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const CategoryEditorResourcesList = () => {
  const { values } = useFormikContext<CategoryEditorInitialValues>();
  const { theme } = useCampfireTheme();
  return (
    <FieldArray
      name='categoryResources'
      render={(arrayHelpers) => (
        <List>
          {values.categoryResources.map((resource, idx) => (
            <ListItem
              key={resource.supportResourceId}
              style={{
                borderBottom:
                  idx !== values.categoryResources.length - 1 ? `1px solid ${theme.color.grey.border}` : 'unset',
                padding: '8px 0px',
              }}
            >
              <Grid container alignItems='center' spacing={1}>
                <Grid item container xs={2} md={1} justify='flex-start'>
                  <Grid item>
                    <IconButton
                      data-track='resources-category-editor-resource-list-item-favourite-button'
                      onClick={() => {
                        arrayHelpers.replace(idx, { ...resource, featured: !resource.featured });
                      }}
                    >
                      <StarIcon style={{ color: resource.featured ? theme.color.starYellow : 'inherit' }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item container direction='column' xs={8} md={10} style={{ overflow: 'hidden' }}>
                  <Grid item>
                    <Typography variant='body1'>{resource.title}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2' color='textSecondary'>{`Uploaded on ${unpackToDate(
                      resource.dateAdded
                    ).toFormat('dd MMM yyyy')}`}</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={2} md={1} justify='flex-end'>
                  <TitularTooltip description='Delete Resource'>
                    <Grid item>
                      <IconButton
                        data-track='resources-category-editor-resource-list-item-delete-button'
                        onClick={() => arrayHelpers.remove(idx)}
                      >
                        <ClearIcon color='error' />
                      </IconButton>
                    </Grid>
                  </TitularTooltip>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      )}
    />
  );
};

export { CategoryEditorResourcesList };
