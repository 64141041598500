import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { HoverLink } from '@campfire/hover-link';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { difference } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import GetOutOnShiftGraphic from '../../../../../assets/tutorial-graphics/my-activities/get_out_on_shift.png';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { MyElementsSelectTimeFrame } from './MyElementsPersonalCard';
import { MyElementsNumericFieldStatistics } from './__generated__/MyElementsNumericFieldStatistics';

interface MyElementsTeamImpactBlockProps {
  initialLoading: boolean;
  impactLoading: boolean;
  fieldStatistics: MyElementsNumericFieldStatistics[];
  teams: number;
  orgName: string;
  selectedTimeFrame: MyElementsSelectTimeFrame;
}

const MyElementsTeamImpactBlock = (props: MyElementsTeamImpactBlockProps) => {
  const { initialLoading, impactLoading, fieldStatistics, teams, orgName, selectedTimeFrame } = props;
  const { isMobile } = useCampfireTheme();

  const heroStatistics = useMemo(() => {
    return fieldStatistics.filter((x) => x.isHero);
  }, [fieldStatistics]);

  const nonHeroStatistics = useMemo(() => {
    return difference(fieldStatistics, heroStatistics);
  }, [fieldStatistics, heroStatistics]);

  const nonHeroContainerWidth = !heroStatistics || heroStatistics.length === 0 ? 12 : 6;

  return (
    <Box marginTop={2}>
      <Grid container direction='row'>
        <Grid item md={11}>
          {!initialLoading && (
            <Typography variant='body2' color='textSecondary'>
              {teams === 1 && fieldStatistics.length > 0 ? (
                `Alongside your team, here is your direct contribution to the ${orgName} mission so far`
              ) : teams === 0 ? (
                <>
                  {`You are not part of any teams yet, `}
                  <HoverLink to='/volunteering/activities/all-activities' color='primary'>
                    find a team here.
                  </HoverLink>
                </>
              ) : teams > 0 && fieldStatistics.length === 0 ? (
                `You have not attended any activities ${selectedTimeFrame === 'All-time' ? '' : 'within the'} ${
                  selectedTimeFrame !== 'All-time' ? selectedTimeFrame : 'yet'
                }. Use the Upcoming Rosters list to let your leaders know when you're available.`
              ) : (
                `Alongside your teams, here are your direct contributions to the ${orgName} mission so far`
              )}
            </Typography>
          )}
        </Grid>

        {teams === 0 ||
        impactLoading ||
        initialLoading ||
        (heroStatistics.length === 0 && nonHeroStatistics.length === 0) ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              backgroundImage: `url(${GetOutOnShiftGraphic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPositionX: 'center',
              backgroundPositionY: 'center',
              width: isMobile ? 100 : '100%',
              height: isMobile ? 220 : 350,
            }}
          >
            {impactLoading || initialLoading ? (
              <CircularProgressOverlay isLoading={impactLoading || initialLoading} />
            ) : null}
          </Grid>
        ) : null}

        {heroStatistics.length > 0 || nonHeroStatistics.length > 0 ? (
          <Grid container style={{ marginTop: 32 }} spacing={2} alignItems='flex-start' alignContent='flex-start'>
            {heroStatistics.length > 0 ? (
              <Grid item xs={12} md={6} style={{ marginTop: isMobile ? 0 : 64, marginBottom: 32 }}>
                {heroStatistics.map((item, index) => {
                  const roundedValue = Math.round(item.numericValue * 100) / 100;
                  return (
                    <Fragment key={item.fieldId}>
                      <Grid container direction='column' alignItems='center' justify='center' spacing={2}>
                        <Grid item>
                          <Typography variant='h4'>{roundedValue}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>{item.fieldName}</Typography>
                        </Grid>
                      </Grid>
                      {index !== heroStatistics.length - 1 ? (
                        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                      ) : null}
                    </Fragment>
                  );
                })}
              </Grid>
            ) : null}

            {nonHeroStatistics.length > 0 ? (
              <Grid container item xs={12} md={nonHeroContainerWidth}>
                {nonHeroStatistics.map((item) => {
                  const roundedValue = Math.round(item.numericValue * 100) / 100;
                  return (
                    <Grid item xs={12} key={item.fieldId} style={{ paddingBottom: 8 }}>
                      <Typography variant='subtitle2' display='inline' style={{ fontWeight: 'bold' }}>
                        {roundedValue}
                      </Typography>
                      <Typography variant='body2' display='inline' style={{ marginLeft: 8 }} color='textSecondary'>
                        {item.fieldName}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export { MyElementsTeamImpactBlock };
