import React from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import sanitizeHtml from 'sanitize-html';
import { CustomContentBlock } from './CustomContentBlock';
import { GetCommunicationTemplateDetails_vm_communicationTemplate_contentBlocks as ContentBlockType } from './__generated__/GetCommunicationTemplateDetails';

interface TemplateItemBodyProps {
  greeting: string;
  preferredName: string;
  logo?: string | null;
  signoff?: string;
  content?: string;
  title: string;
  contentBlocks: ContentBlockType[];
  onSaveContentBlock: (block: string, content: string, CustomContentBlockId?: string) => void;
  saveContentLoading: boolean;
  type: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& .logo': {
        height: 45,
        width: 'auto',
        display: 'block',
        alignSelf: 'center',
      },
      '& .title': {
        fontSize: 16,
        fontWeight: 700,
        marginTop: 10,
        color: '#4A5568',
      },
      '& .content': {
        marginTop: 8,
        fontSize: 14,
        fontWeight: 400,
        color: '#9E9E9E',
        '& strong': {
          fontWeight: 700,
          color: '#646464',
        },
      },
      '& .signoff': {
        marginTop: 16,
        fontSize: 14,
        fontWeight: 700,
        color: '#646464',
      },
      '& .smsHeaderContainer': {
        display: 'flex',
        backgroundColor: '#F5F5F5',
        justifyContent: 'center',
        gap: '35px',
        alignItems: 'center',
      },
      '& .smsHeader': {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '10px 0',
      },
      '& .logoSms': {
        height: 60,
        width: 'auto',
        display: 'block',
        alignSelf: 'center',
      },
      '& .message': {
        backgroundColor: '#fff',
        borderRadius: '0.25rem',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '15px',
        margin: '0 auto 1rem',
      },
      '& .messageInner': {
        borderRadius: '1.15rem',
        lineHeight: '1.25',
        maxWidth: '70%',
        padding: '0.5rem .875rem',
        position: 'relative',
        wordWrap: 'break-word',
        alignItems: 'flex-start',
        backgroundColor: '#e5e5ea',
        color: '#000',
        margin: '0.5rem 0',
        '&::before': {
          borderBottomRightRadius: '0.8rem 0.7rem',
          borderLeft: '1rem solid #e5e5ea',
          left: '-0.35rem',
          transform: 'translate(0, -0.1rem)',
          bottom: '-0.1rem',
          content: "''",
          height: '1rem',
          position: 'absolute',
        },
        '&::after': {
          backgroundColor: '#fff',
          borderBottomRightRadius: '0.5rem',
          left: '20px',
          transform: 'translate(-30px, -2px)',
          width: '10px',
          bottom: '-0.1rem',
          content: "''",
          height: '1rem',
          position: 'absolute',
        },
      },
    },
  })
);

export function TemplateItemBody({
  greeting,
  preferredName,
  logo,
  signoff,
  content,
  title,
  contentBlocks,
  onSaveContentBlock,
  saveContentLoading,
  type,
}: TemplateItemBodyProps) {
  const classes = useStyles();
  const contents = content?.split(/(\{\{CUSTOM_CONTENT\}\})/);

  if (type === 'sms') {
    return (
      <Box className={classes.root}>
        <Box className='smsHeaderContainer'>
          <Box className='smsHeader'>
            <Avatar>V</Avatar>
            <Typography style={{ fontSize: '13px', paddingTop: '5px', color: '#5a5a5a' }}>Volaby</Typography>
          </Box>
          <Box></Box>
        </Box>
        <Box className='content'>
          {contents?.map((smallerContent) => {
            if (['{{CUSTOM_CONTENT}}'].includes(smallerContent)) {
              const contentBlock = contentBlocks.find((cb) => cb.block === smallerContent);
              if (saveContentLoading) return <Skeleton variant='rect' width='100%' height='30px' />;
              return (
                <CustomContentBlock
                  value={contentBlock?.content}
                  onSave={(newContent) =>
                    onSaveContentBlock(smallerContent, newContent, contentBlock?.communicationTypeCustomContentBlockId)
                  }
                />
              );
            }
            return (
              <Box style={{ padding: '16px 40px' }}>
                <Box className='message'>
                  <div
                    className='messageInner'
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(smallerContent || '') }}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }
  return (
    <Box className={classes.root}>
      <Box display='flex' justifyContent='center' style={{ gap: 35 }}>
        <img
          src='https://campfire-static.s3-ap-southeast-2.amazonaws.com/logo/volaby-original.png'
          alt={title}
          className='logo'
        />
        {logo && <img src={logo} alt={title} className='logo' />}
      </Box>
      <Typography className='title'>
        {greeting} {preferredName},
      </Typography>
      <Box className='content'>
        {contents?.map((smallerContent) => {
          if (['{{CUSTOM_CONTENT}}'].includes(smallerContent)) {
            const contentBlock = contentBlocks.find((cb) => cb.block === smallerContent);
            if (saveContentLoading) return <Skeleton variant='rect' width='100%' height='30px' />;
            return (
              <CustomContentBlock
                value={contentBlock?.content}
                onSave={(newContent) =>
                  onSaveContentBlock(smallerContent, newContent, contentBlock?.communicationTypeCustomContentBlockId)
                }
              />
            );
          }
          // eslint-disable-next-line
          return <div className='content' dangerouslySetInnerHTML={{ __html: sanitizeHtml(smallerContent || '') }} />;
        })}
      </Box>
      <Typography className='signoff'>{signoff}</Typography>
    </Box>
  );
}
