import React from 'react';
import { TaskFormItem_VOLUNTEER_TaskItemFieldType_field as TaskItemFieldType } from '../../../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';
import { AttachmentFieldPreview } from './AttachmentFieldPreview';
import { CheckboxFieldPreview } from './CheckboxFieldPreview';
import { DropdownFieldPreview } from './DropdownFieldPreview';
import { MultipleChoiceFieldPreview } from './MultipleChoiceFieldPreview';
import { QuizFieldPreview } from './QuizFieldPreview';
import { TextFieldPreview } from './TextFieldPreview';
import { SignatureFieldPreview } from './SignatureFieldPreview';

interface TaskItemFieldProps {
  field: TaskItemFieldType;
  isDragging: boolean;
}

export function TaskItemField({ field, isDragging }: TaskItemFieldProps) {
  switch (field.__typename) {
    case 'VOLUNTEER_ShortTextTaskFieldType':
    case 'VOLUNTEER_LongTextTaskFieldType':
    case 'VOLUNTEER_NumberTaskFieldType':
    case 'VOLUNTEER_DateTaskFieldType':
    case 'VOLUNTEER_AddressTaskFieldType':
    case 'VOLUNTEER_EmailTaskFieldType':
    case 'VOLUNTEER_PhoneTaskFieldType':
      return <TextFieldPreview />;
    case 'VOLUNTEER_AttachmentTaskFieldType':
      return <AttachmentFieldPreview />;
    case 'VOLUNTEER_MultipleChoiceTaskFieldType':
      return <MultipleChoiceFieldPreview field={field} isDragging={isDragging} />;
    case 'VOLUNTEER_CheckboxTaskFieldType':
      return <CheckboxFieldPreview field={field} isDragging={isDragging} />;
    case 'VOLUNTEER_DropdownTaskFieldType':
      return <DropdownFieldPreview field={field} isDragging={isDragging} />;
    case 'VOLUNTEER_QuizTaskFieldType':
      return <QuizFieldPreview field={field} isDragging={isDragging} />;
    case 'VOLUNTEER_SignatureTaskFieldType':
      return <SignatureFieldPreview />;
    default:
      return null;
  }
}
