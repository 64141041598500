import { getDurationHoursMin, unpackToDate, unpackToTime } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button/lib';
import {
  Box,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import React, { useEffect, useMemo } from 'react';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { SectionCollapsible } from '../../../volunteer-common-profile/common/SectionCollapsible';
import { SectionHeader, SectionSubheader } from '../../../volunteer-common-profile/common/SectionHeader';
import { SectionInstruction } from '../../../volunteer-common-profile/common/SectionInstruction';
import { EnrolmentForecast } from '../../../volunteer-common-profile/profile-sections/activity-enrolment-section/EnrolmentForecast';
import { useVolunteerCommonProfileContext } from '../../../volunteer-common-profile/VolunteerCommonProfileContext';
import { GET_ACTIVITY_APPLICATION_REVIEW } from '../activity-timeline-model.gql';
import {
  GetActivityApplicationReview,
  GetActivityApplicationReviewVariables,
} from '../__generated__/GetActivityApplicationReview';
import { ActivityApplicationCommonProfileBlock } from './ActivityApplicationCommonProfileBlock';
import { VolunteerReviewCommonSkeletonLoading } from './VolunteerReviewCommon';
import { getDisplayTimeSchedule } from '../../../../../common/functions/activity-display-helpers';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';

export const ActivityApplicationReviewDialog = ({
  handleClose,
  handleApprove,
  handleWaitlist,
  activityApplicationId,
}: {
  handleClose: () => void;
  handleApprove: () => void;
  handleWaitlist: () => void;
  activityApplicationId?: string;
}) => {
  const [runGetApplication, { data: getApplicationData }] = useCampfireLazyQuery<
    GetActivityApplicationReview,
    GetActivityApplicationReviewVariables
  >(GET_ACTIVITY_APPLICATION_REVIEW);

  const { isMobile } = useCampfireTheme();

  useEffect(() => {
    if (!activityApplicationId) return;
    runGetApplication({
      variables: {
        activityApplicationId,
      },
    });
  }, [activityApplicationId]);

  const activityApplication = useMemo(() => {
    return getApplicationData?.vm.activityApplication;
  }, [getApplicationData]);

  const { setProfileId } = useVolunteerCommonProfileContext();

  useEffect(() => {
    if (!activityApplication) return;
    setProfileId(activityApplication.volunteer.profile.profileId);
  }, [activityApplication]);

  return (
    <>
      <FullscreenDialog
        open={!!activityApplicationId}
        close={() => handleClose()}
        title={'Review Application'}
        fullScreen={isMobile}
      >
        {activityApplication ? (
          <>
            <ActivityApplicationCommonProfileBlock activityApplication={activityApplication} view='application' />
            <Divider />
            <Box marginX={6} marginY={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='body2' color='textSecondary'>{`Requested to join`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    style={{ fontWeight: 'bolder' }}
                  >{`${activityApplication.activity.name}`}</Typography>
                  {activityApplication.activity.__typename === 'VOLUNTEER_RecurringActivityType' ? (
                    <Typography variant='body2' color='textSecondary'>
                      {activityApplication.activity.schedule.recurrences
                        .flatMap((recurrence) => recurrence.humanReadable)
                        .join(', ')}
                    </Typography>
                  ) : null}
                  <Typography variant='body2' color='textSecondary'>{`${getDisplayTimeSchedule(
                    activityApplication?.activity?.startTime,
                    activityApplication?.activity?.endTime
                  )} (${getDurationHoursMin(
                    unpackToTime(activityApplication.activity.startTime),
                    unpackToTime(activityApplication.activity.endTime)
                  )})`}</Typography>
                  <Typography variant='subtitle1'>{`Applied on ${unpackToTime(activityApplication.dateApplied).toFormat(
                    'd MMM y'
                  )}`}</Typography>
                </Grid>
                <Grid container item justify='flex-end' xs={12}>
                  <TabletButton
                    data-track='actCnl-volunteer-review-send-to-waitlist'
                    disabled={!activityApplication}
                    onClick={() => handleWaitlist()}
                    style={{ marginRight: 4 }}
                  >
                    {'Waitlist'}
                  </TabletButton>
                  <TabletButton
                    variant='outlined'
                    data-track='actCnl-volunteer-add-to-team'
                    disabled={!activityApplication}
                    onClick={() => {
                      handleApprove();
                    }}
                    color='primary'
                    autoFocus
                  >
                    {'Add to team'}
                  </TabletButton>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            {activityApplication.volunteer.activityEnrolments.length ? (
              <Box marginY={2}>
                <SectionCollapsible
                  idStub='activity-enrolment-collapsible'
                  sectionHeader={<SectionHeader title='Activity History' />}
                  instruction={
                    <SectionInstruction>Enrolled Activities, Pending Applications, Past Activities</SectionInstruction>
                  }
                  collapseContent={
                    <Grid container spacing={4} style={{ marginTop: 4 }}>
                      <Grid item xs={12}>
                        <SectionSubheader title={'Enrolled Activities'} />
                        <List disablePadding dense>
                          {activityApplication.volunteer.activityEnrolments.length > 0 ? (
                            activityApplication.volunteer.activityEnrolments.map((activityEnrolment, idx) => (
                              <Box key={activityEnrolment.activityEnrolmentId}>
                                <ListItem key={activityEnrolment.activityEnrolmentId} disableGutters>
                                  <ListItemText
                                    primary={`${activityEnrolment.activity.name}`}
                                    secondary={`${getDisplayTimeSchedule(
                                      activityEnrolment.activity.startTime,
                                      activityEnrolment.activity.endTime
                                    )}`}
                                    style={{ marginRight: 12 }}
                                  />

                                  <EnrolmentForecast
                                    volunteerId={activityApplication.volunteer.volunteerId}
                                    activityEnrolment={activityEnrolment}
                                  />
                                </ListItem>
                                {idx !== activityApplication.volunteer.activityEnrolments.length - 1 && (
                                  <Divider style={{ marginTop: '8px' }} />
                                )}
                              </Box>
                            ))
                          ) : (
                            <Typography variant='subtitle2'>No active enrolments to show</Typography>
                          )}
                        </List>
                      </Grid>

                      <Grid item xs={12}>
                        <SectionSubheader title={'Pending Applications'} />
                        <List disablePadding dense>
                          {activityApplication.volunteer.activityApplications.map((otherActivityApplication, idx) => (
                            <Box key={otherActivityApplication.activityApplicationId}>
                              <ListItem key={otherActivityApplication.activityApplicationId}>
                                <ListItemAvatar>
                                  <LinkIcon />
                                </ListItemAvatar>

                                <ListItemText
                                  primary={otherActivityApplication.activity.name}
                                  secondary={otherActivityApplication.activity.program.name}
                                />
                              </ListItem>
                              {idx !== activityApplication.volunteer.activityApplications.length - 1 && (
                                <Divider style={{ marginTop: '8px' }} />
                              )}
                            </Box>
                          ))}
                        </List>
                      </Grid>

                      <Grid item xs={12}>
                        <SectionSubheader title={'Past Activities'} />
                        <List disablePadding dense>
                          {activityApplication.volunteer.inactiveActivityEnrolments.length > 0 ? (
                            activityApplication.volunteer.inactiveActivityEnrolments.map(
                              (inactiveActivityEnrolment, idx) => (
                                <Box key={inactiveActivityEnrolment.activityEnrolmentId}>
                                  <ListItem key={inactiveActivityEnrolment.activityEnrolmentId}>
                                    <ListItemText
                                      primary={inactiveActivityEnrolment.activity.name}
                                      secondary={`Removed on ${unpackToDate(
                                        inactiveActivityEnrolment.dateRemoved
                                      ).toFormat('d MMM y')}`}
                                    />
                                  </ListItem>
                                  {idx !== activityApplication.volunteer.inactiveActivityEnrolments.length - 1 && (
                                    <Divider style={{ marginTop: '8px' }} />
                                  )}
                                </Box>
                              )
                            )
                          ) : (
                            <Typography variant='subtitle2'>No past activity enrolments to show</Typography>
                          )}
                        </List>
                      </Grid>
                    </Grid>
                  }
                  expandText='View Activity History'
                />
              </Box>
            ) : null}
          </>
        ) : (
          <DialogContent>
            <VolunteerReviewCommonSkeletonLoading />
          </DialogContent>
        )}
      </FullscreenDialog>
    </>
  );
};
