import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { encodeDate, unpackToDate, unpackToDateTime } from '@campfire/hot-date';
import { StringParam, useQueryParam } from 'use-query-params';
import { ManagementCalendar } from './ManagementCalendar';
import { ManagementOverview } from './management-overview/ManagementOverview';
import { MANAGEMENT_DASHBOARD_DATA, MANAGEMENT_DASHBOARD_INCOMING_VOLUNTEERS_DATA } from './management-dashboard.gql';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { ManagementDashboardData } from './__generated__/ManagementDashboardData';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { useUser } from '../../../global/auth/useUser';
import { ManagementDashboardIncomingVolunteersData } from './__generated__/ManagementDashboardIncomingVolunteersData';
import { CollapsibleSideBar } from '../../../common/CollapsibleSideBar';
import { Page } from '../../../global/components';
import { ManagementDashboardTutorialDialog } from './ManagementDashboardTutorialDialog';
import { ManagementDashboardTabHeader } from './ManagementDashboardTabHeader';
import { ManagementDashboardProvider } from './ManagementDashboardProvider';
import { DashboardWidgetsContainer } from '../../general/vol-dashboard/vol-dashboard-my-elements/management-dashboard/DashboardWidgetsContainer';
import { useOrgInfo } from '../../../global/auth/use-org-info/use-org-info';
import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';

export const ManagementDashboardScreen = () => {
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);
  const { isMd } = useCampfireTheme();
  const {
    getVolunteerIdentity,
    user: { userIdentityService },
  } = useUser();
  const userIsAdmin = userIdentityService.isVmAdmin;
  const [selectedTab, setSelectedTab] = useQueryParam('tab', StringParam);
  const org = useOrgInfo();
  const temporaryDisabled = useMemo(() => {
    return org?.tenantId === '5fbb9c2c-c282-4048-b9ca-3c651f9a4346';
  }, [org]);
  const allowInsights = useMemo(() => {
    return !org ? null : Boolean(org.packageConfig?.insights);
  }, [org]);

  React.useEffect(() => {
    if (allowInsights !== null) {
      if (allowInsights) {
        setSelectedTab(selectedTab || 'insights');
      } else {
        setSelectedTab(selectedTab || 'calendar');
      }
    }
  }, [allowInsights, selectedTab]);

  const { data: activitiesData, loading: activitiesDataIsLoading } = useCampfireQuery<ManagementDashboardData, any>(
    MANAGEMENT_DASHBOARD_DATA,
    {
      options: {
        variables: {
          volunteerId: getVolunteerIdentity().volunteerId,
          from: encodeDate(DateTime.fromJSDate(new Date('2019-01-01'))),
          until: encodeDate(DateTime.local()),
        },
      },
    }
  );

  const [
    getIncomingVolunteer,
    { data: incomingVolunteersData, loading: incomingVolunteersDataIsLoading },
  ] = useCampfireLazyQuery<ManagementDashboardIncomingVolunteersData, any>(
    MANAGEMENT_DASHBOARD_INCOMING_VOLUNTEERS_DATA
  );

  useEffect(() => {
    if (org?.tenantId && !temporaryDisabled) {
      getIncomingVolunteer();
    }
  }, [temporaryDisabled, org?.tenantId]);

  const existingActivities = useMemo(
    () => activitiesData?.vm.activities.filter((a) => !a.program.dateDeleted && !a.program.dateSuspended),
    [activitiesData]
  );
  const userIsPmOrAdmin = (programId: string) => userIsAdmin || userIdentityService.isManagerOfProgram(programId);
  const userEnrolledInActivity = (activityId: string) =>
    !!activitiesData?.vm.volunteer?.activityEnrolments.find((e) => e.activity.activityId === activityId);
  const userRosteredForActivity = (activityId: string, date: string) =>
    !!activitiesData?.vm.volunteer?.rosterings.find(
      (r) => r.publishedRoster.activity.activityId === activityId && r.publishedRoster.activityDate === date
    );

  const privilegedReportDates = useMemo(
    () =>
      existingActivities?.flatMap((a) =>
        a.occurrencesBetween
          .filter((occurrence) => {
            const activityReport = a.activityReports.find((ar) => ar.activityDate === occurrence);
            const reportIsIncomplete = !activityReport
              ? true
              : activityReport.sessionReports.length < a.sessions.length - activityReport.cancelledSessions.length;

            if (a.cancelledActivities.find((date) => date.activityDate === occurrence)) {
              return false;
            }

            if (userIsPmOrAdmin(a.program.programId) && reportIsIncomplete) return true;
            if (userEnrolledInActivity(a.activityId) && reportIsIncomplete) return true;
            return userRosteredForActivity(a.activityId, occurrence) && reportIsIncomplete;
          })
          .map((occurrence) => ({
            activityId: a.activityId,
            name: a.name,
            date: occurrence,
            startTime: a.startTime,
            endTime: a.endTime,
          }))
      ) ?? [],
    [existingActivities]
  );

  const rosteredReportDates = useMemo(
    () =>
      activitiesData?.vm.volunteer?.rosterings
        .filter((r) => {
          const { activity, activityDate } = r.publishedRoster;
          if (activity.program.dateDeleted || activity.program.dateSuspended) return false;
          const activityReport = activity.activityReports.find((ar) => ar.activityDate === activityDate);

          if (activity.cancelledActivities.find((date) => date.activityDate === activityDate)) {
            return false;
          }

          const reportIsIncomplete = !activityReport
            ? true
            : activityReport.sessionReports.length < activity.sessions.length - activityReport.cancelledSessions.length;

          const existsInPrivileged = privilegedReportDates.find(
            (pr) => pr.date === activityDate && pr.activityId === activity.activityId
          );

          return reportIsIncomplete && !existsInPrivileged;
        })
        .map((r) => ({
          activityId: r.publishedRoster.activity.activityId,
          name: r.publishedRoster.activity.name,
          date: r.publishedRoster.activityDate,
          startTime: r.publishedRoster.activity.startTime,
          endTime: r.publishedRoster.activity.endTime,
        })) ?? [],
    [activitiesData, privilegedReportDates]
  );

  const activityHasOccurred = (date: any) => unpackToDateTime(date) < DateTime.local();
  const allIncompleteReportDates = useMemo(
    () =>
      [...privilegedReportDates, ...rosteredReportDates]
        .filter((r) => activityHasOccurred(r.date))
        .sort((a, b) => (unpackToDate(a.date) < unpackToDate(b.date) ? 1 : -1))
        .slice(0, 3),
    [privilegedReportDates, rosteredReportDates]
  );

  return (
    <ManagementDashboardProvider>
      <Page
        pageHelpOptions={{
          onClick: () => setTutorialDialogOpen(true),
        }}
      >
        <Box
          position='relative'
          display='flex'
          flexDirection='column'
          height='100%'
          style={{
            overflowY: 'hidden',
          }}
        >
          <Box padding='24px 24px 0 0'>
            <ManagementDashboardTabHeader
              allowInsights={Boolean(allowInsights)}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Box>
          {selectedTab === 'insights' && <DashboardWidgetsContainer />}
          <ManagementDashboardTutorialDialog
            open={tutorialDialogOpen}
            onClose={() => {
              setTutorialDialogOpen(false);
            }}
          />
          {selectedTab === 'calendar' && (
            <Box
              width={'100%'}
              position={'relative'}
              display={isMd ? 'auto' : 'flex'}
              flexDirection={isMd ? 'column' : 'row'}
              justifyContent={'flex-end'}
              paddingLeft={'24px'}
              flex={1}
              style={{ overflowY: 'scroll' }}
            >
              <Box paddingTop={'10px'}>
                {temporaryDisabled ? (
                  <Box width='1000px' paddingTop={'25px'}>
                    <Typography style={{ color: '#777777', fontSize: '14px', textAlign: 'center', paddingTop: '25px' }}>
                      The Volaby team is working to resolve performance issues with the Management Dashboard Calendar.
                    </Typography>
                  </Box>
                ) : (
                  <ManagementCalendar />
                )}
              </Box>
              <Box flexGrow={1} width={'100%'} style={{ backgroundColor: 'rgb(249, 249, 249)' }}>
                <CollapsibleSideBar>
                  <ManagementOverview
                    temporaryDisabled={temporaryDisabled}
                    incomingVolunteers={incomingVolunteersData?.vm.incomingVolunteers?.incomingVolunteers ?? []}
                    allIncompleteReportDates={allIncompleteReportDates}
                    outstandingReportsIsLoading={activitiesDataIsLoading}
                    incomingVolunteersIsLoading={incomingVolunteersDataIsLoading}
                  />
                </CollapsibleSideBar>
              </Box>
            </Box>
          )}
        </Box>
      </Page>
    </ManagementDashboardProvider>
  );
};
