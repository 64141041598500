import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Divider, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Field } from '@campfire/field';
import { Select } from '@campfire/select/lib';
import { HoverText } from '@campfire/hover-link/lib';
import { DatePickerField } from '@campfire/date-picker/lib';
import { useFormikContext } from 'formik';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { SwitchField } from '../dialogs/field-task-item-dialog/additional-field-type-fields/SwitchField';
import {
  CakeSelectItems,
  SaveTaskValues,
  TaskFormBuilderProgram,
  TaskFormBuilderRole,
  ExpiryRecurrence,
  TaskFormBuilderActivity,
} from '../../admin-console-volunteer-profile-model';
import { ManualReviewDialog } from '../dialogs/ManualReviewDialog';
import { TaskProgramSelectionSection } from './TaskProgramSelectionSection';
import { TaskActivitySelectionSection } from './TaskActivitySelectionSection';
import { TaskPermissionLevelSelectionSection } from './TaskPermissionLevelSelectionSection';
import { AlertCard } from '../../../../../../../common/cards/alert-card/AlertCard';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';

interface Props {
  cakeSelectItems: CakeSelectItems;
  remainingManualTasks: number;
  isTaskZero: boolean;
  isNew: boolean;
  programOptions: Array<TaskFormBuilderProgram>;
  roleOptions: Array<TaskFormBuilderRole>;
  activityOptions: Array<TaskFormBuilderActivity>;
  permissionLevelOptions: Array<{ value: string; label: string }>;
}
interface FrequencyItem {
  label: string;
  value: ExpiryRecurrence;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoBody: {
      color: theme.alert.blue.dark,
      fontSize: '13px',
      fontWeight: 400,
    },
  })
);

export const TaskDetailsCard = ({
  cakeSelectItems,
  remainingManualTasks,
  isTaskZero,
  isNew,
  programOptions,
  activityOptions,
  roleOptions,
  permissionLevelOptions,
}: Props) => {
  const [showDescriptionField, setShowDescriptionField] = useState(false);
  const [showManualReviewDialog, setShowManualReviewDialog] = useState(false);
  const [isExpiryRecurrence, setIsExpiryRecurrence] = useState(false);
  const [showAdvanced, setShowAdvanced] = React.useState(false);
  const { theme } = useCampfireTheme();

  const { values, setFieldValue } = useFormikContext<SaveTaskValues>();

  const classes = useStyles();

  const frequencyItems: Array<FrequencyItem> = [
    {
      label: 'Monthly',
      value: 'monthly',
    },
    {
      label: 'Quarterly',
      value: 'quarterly',
    },
    {
      label: 'Half Annually',
      value: 'half_annually',
    },
    {
      label: 'Annually',
      value: 'annually',
    },
  ];

  const handleManualReviewChange = () => {
    if (!values.automateApproval && remainingManualTasks === 1) {
      setShowManualReviewDialog(true);
      return;
    }
    setFieldValue('automateApproval', !values.automateApproval);
  };

  const handleApplicantsOnlyChange = () => {
    setFieldValue('applicantsOnly', !values.applicantsOnly);
  };

  const handleIsHiddenChange = () => {
    setFieldValue('isHidden', !values.isHidden);
  };

  const handleShowExpiryRecurrence = () => {
    if (isExpiryRecurrence) {
      setFieldValue('frequency', undefined);
    } else {
      setFieldValue('frequency', 'monthly');
    }
    setIsExpiryRecurrence(!isExpiryRecurrence);
  };

  const handleAllowUpdateChange = () => {
    setFieldValue('allowUpdate', !values.allowUpdate);
  };

  useEffect(() => {
    if (values.description) setShowDescriptionField(true);
  }, [values.description]);

  useEffect(() => {
    if (values.frequency) setIsExpiryRecurrence(true);
  }, [values.frequency]);

  return (
    <Card>
      <CardContent style={{ padding: 48, paddingTop: 32, paddingBottom: 15 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#666666' }}>Task Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              autoFocus
              name='title'
              label={isNew ? 'This task will be named' : 'Task name'}
              required
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              slow
              label={isNew ? 'Add to' : 'This task is'}
              name='cakeId'
              variant='outlined'
              disabled={isTaskZero}
              items={cakeSelectItems}
              renderFunction={(item) => item.label}
              valueFunction={(item) => item.value}
            />
          </Grid>
          <Grid item xs={12}>
            {!showDescriptionField ? (
              <HoverText onClick={() => setShowDescriptionField(true)} color='primary'>
                {'Add description'}
              </HoverText>
            ) : null}
          </Grid>
          {showDescriptionField ? (
            <Grid item xs={12}>
              <Field fullWidth label='Description' name='description' variant='outlined' />
            </Grid>
          ) : null}

          {isTaskZero && (
            <Grid item xs={12} style={{ paddingTop: 0, marginTop: '6px' }}>
              <SwitchField
                title='Manual Review'
                description={
                  !values.automateApproval
                    ? 'Task submissions will require manual approval'
                    : 'No manual review is required for this task'
                }
                checked={!values.automateApproval}
                onChange={handleManualReviewChange}
              />
            </Grid>
          )}

          {!isTaskZero ? (
            <>
              <Grid item xs={12} style={{ padding: '25px 0' }}>
                <Divider />
              </Grid>
              <Grid item container xs={12}>
                <Typography style={{ fontSize: '15px', fontWeight: 600, color: '#666666', paddingBottom: '10px' }}>
                  Options
                </Typography>
                <Grid item xs={12}>
                  <SwitchField
                    title='Manual Review'
                    description={
                      !values.automateApproval
                        ? 'Task submissions will require manual approval'
                        : 'No manual review is required for this task'
                    }
                    checked={!values.automateApproval}
                    onChange={handleManualReviewChange}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '12px' }}>
                  <SwitchField
                    title='Task expiry'
                    description={'Set this task to expire on a selected schedule'}
                    checked={isExpiryRecurrence}
                    onChange={handleShowExpiryRecurrence}
                  />
                </Grid>
                {isExpiryRecurrence && (
                  <Box paddingTop={'15px'} paddingBottom={'15px'}>
                    <AlertCard variant='info' title='Task Expiry'>
                      <Typography className={classes.infoBody}>
                        Setting a task expiration means that volunteers are asked to recomplete a task each time the
                        start date and frequency conditions are met.
                        <br />
                        <br />
                        <i>
                          <strong>eg. </strong>A &apos;Training Task&apos; having an annual frequency, with the start
                          date of January 1st, will make the volunteer recomplete the task at the beginning of every
                          year.
                        </i>
                        <br />
                        <br />
                        Date fields within tasks can also act as a task expiry (
                        <i>
                          <b>eg.</b> Drivers Licenses
                        </i>
                        ), which can be configured via the field settings.
                      </Typography>
                    </AlertCard>
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                      <DatePickerField
                        name='startDate'
                        label='Start Date'
                        autoOk
                        fullWidth
                        inputVariant='outlined'
                        required
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                      <Select
                        fullWidth
                        slow
                        label={'Frequency'}
                        name='frequency'
                        variant='outlined'
                        items={frequencyItems}
                        renderFunction={(item) => item.label}
                        valueFunction={(item) => item.value}
                      />
                    </Grid>
                  </Box>
                )}
                <Grid item xs={12} style={{ marginTop: '12px' }}>
                  <SwitchField
                    title='Admin Only Visibility'
                    description={`Submissions will be visible only to administrator users`}
                    checked={values.adminOnly}
                    onChange={() => {
                      setFieldValue('adminOnly', !values.adminOnly);
                    }}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} style={{ paddingTop: 0, marginTop: '6px' }}>
            <SwitchField
              title='Volunteers can Update'
              description='Volunteers can edit this task at any time after submission. You will be alerted that volunteers have updated the task if manual approval is turned on, and have the option to revert to a previous state if necessary.'
              checked={values.allowUpdate}
              onChange={handleAllowUpdateChange}
            />
          </Grid>
          {!isTaskZero ? (
            <>
              <Grid item xs={12} style={{ padding: '25px 0' }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontSize: '15px', fontWeight: 700, color: '#666666' }}>Visibility</Typography>
                <Typography style={{ fontSize: '13px', fontWeight: 400, color: '#9E9E9E' }}>
                  Select programs and roles below to display the task to certain volunteers only.
                  <br />
                  Default settings will display the task to all volunteers.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TaskProgramSelectionSection programOptions={programOptions} />
              </Grid>
              <Grid item xs={12}>
                <TaskActivitySelectionSection activityOptions={activityOptions} />
              </Grid>
              <Grid item xs={12}>
                <TaskPermissionLevelSelectionSection roleOptions={roleOptions} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '0 !important',
                  paddingBottom: '0 !important',
                }}
              >
                <Button
                  variant='text'
                  endIcon={showAdvanced ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  onClick={() => setShowAdvanced(!showAdvanced)}
                  style={{
                    fontSize: 10,
                    textDecoration: 'none',
                    color: theme.color.grey.neutral300,
                    textTransform: 'none',
                    padding: 0,
                  }}
                >
                  Advanced
                </Button>
              </Grid>
              {showAdvanced ? (
                <>
                  <Grid item xs={12}>
                    <Box mt={3}>
                      <Typography
                        style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '10px' }}
                      >
                        PERMISSION LEVEL
                      </Typography>
                      <Select
                        fullWidth
                        variant='outlined'
                        name='permissionLevel'
                        placeholder='No permission level (Visible to all)'
                        label='Select permission level'
                        items={permissionLevelOptions}
                        renderFunction={(item) => item.label}
                        valueFunction={(item) => item.value}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: '10px' }}>
                    <SwitchField
                      title='Applicants Only'
                      description={
                        'This task will display only for new volunteers signing up to Volaby. Existing volunteers, who have been approved, will not be able to complete this task.'
                      }
                      checked={values.applicantsOnly}
                      onChange={handleApplicantsOnlyChange}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: '10px' }}>
                    <SwitchField
                      title='Hidden Task'
                      description={'This task will not display for volunteers who have not yet completed it.'}
                      checked={values.isHidden}
                      onChange={handleIsHiddenChange}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item style={{ paddingBottom: '25px' }}></Grid>
            </>
          ) : null}
          <ManualReviewDialog open={showManualReviewDialog} close={() => setShowManualReviewDialog(false)} />
        </Grid>
      </CardContent>
    </Card>
  );
};
