import classNames from 'classnames';
import React, { useState } from 'react';
import {
  DialogContent,
  Button,
  Box,
  Grid,
  TextField,
  IconButton,
  Theme,
  Typography,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';
import { VolunteerCommunicationParsed } from './VolunteerCommunicationsSection';
import { VolunteerCommonProfileEmail } from '../../__generated__/VolunteerCommonProfileEmail';
import { SearchField } from '../../../../../common/inputs/SearchField';
import { sharedStyles } from '../../../../general/activities-v2/ActivityStyles/shared';
import { communicationTypeOptions } from './CommunicationConsts';
import {
  filterByCommunicationType,
  filterByEndDate,
  filterByEndTime,
  filterBySearch,
  filterByStartDate,
  filterByStartTime,
  filterByTemplateTag,
} from './CommunicationHelpers';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetVolunteerProfileCommunicationTemplates } from '../../__generated__/GetVolunteerProfileCommunicationTemplates';
import { GET_VOLUNTEER_PROFILE_COMMUNICATION_TEMPLATES } from '../../volunteer-profile-model.gql';
import { ShowMoreButton } from '../../../../../common/buttons/ShowMoreButton';
import { SmsLogTypeParsed } from '../../VolunteerCommonProfileDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      width: 38,
      height: 38,
      borderRadius: 6,
      color: theme?.color?.grey?.neutral300,
      textTransform: 'capitalize',
      marginRight: '0.45rem',
    },
    selected: {
      color: 'white',
      backgroundColor: '#646464',
      '&:hover': {
        color: 'white',
        backgroundColor: '#646464',
        opacity: 0.75,
      },
    },
    label: {
      textTransform: 'uppercase',
      color: theme.color.grey.neutral300,
      fontWeight: 700,
      fontSize: '0.75rem',
    },
  })
);

interface Props {
  open: boolean;
  subtitle: string;
  communications: (VolunteerCommonProfileEmail | SmsLogTypeParsed)[];
  handleClose: () => void;
}
const LIST_SIZE = 100;

const CommunicationViewMoreDialog = ({ communications, open, subtitle, handleClose }: Props) => {
  const { isMobile } = useCampfireTheme();
  const [searchValue, setSearchValue] = useState<string>('');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [initialListSize, setInitialListSize] = useState<number>(LIST_SIZE);

  const inputClasses = sharedStyles.textField();
  const { isSm } = useCampfireTheme();
  const classes = useStyles();
  const [filters, setFilters] = React.useState({
    communicationType: 'all',
    rangeStartTime: '',
    rangeEndTime: '',
    rangeStartDate: '',
    rangeEndDate: '',
    templateTag: 'all',
  });

  const { data, loading: communicationTemplatesLoading } = useCampfireQuery<
    GetVolunteerProfileCommunicationTemplates,
    {}
  >(GET_VOLUNTEER_PROFILE_COMMUNICATION_TEMPLATES, {});

  const templates = data?.vm.communicationTemplates.concat(data?.im.communicationTemplates) || [];
  const orderedTemplateOptions = templates
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((template) => {
      return {
        value: template.tag,
        label: template.name,
      };
    });

  const onChangeSearchField = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchValue(e.target.value);
  };

  const onChangeFilter = (newFilter: any) => setFilters((currentFilters) => ({ ...currentFilters, ...newFilter }));

  const onSelect = (e: any) => {
    onChangeFilter({ [e.target.name]: e.target.value });
  };

  const handleListSizeUpdate = () => {
    setInitialListSize(initialListSize + LIST_SIZE);
  };

  const filteredCommunications = communications
    .filter(filterByCommunicationType(filters.communicationType))
    .filter(filterByTemplateTag(filters.templateTag))
    .filter(filterByStartDate(filters.rangeStartDate))
    .filter(filterByEndDate(filters.rangeEndDate))
    .filter(filterByStartTime(filters.rangeStartTime))
    .filter(filterByEndTime(filters.rangeEndTime))
    .filter(filterBySearch(searchValue));

  return (
    <FullscreenDialog
      fullWidth
      title='Communications'
      subtitle={subtitle}
      fullScreen={isMobile}
      maxWidth='sm'
      scroll='paper'
      open={open}
      close={handleClose}
      dialogHeaderOption={
        <Box display='flex' alignItems='center'>
          <SearchField placeholder='Search communications' value={searchValue} onChange={onChangeSearchField} />
          <IconButton
            onClick={() => setShowFilters(!showFilters)}
            className={classNames(classes.iconButton, {
              [classes.selected]: showFilters,
            })}
          >
            <FilterList />
          </IconButton>
        </Box>
      }
      dialogActions={() => (
        <Button
          variant='contained'
          color='primary'
          onClick={handleClose}
          style={{
            textTransform: 'capitalize',
          }}
        >
          Close
        </Button>
      )}
      hasBorderActions={false}
    >
      <DialogContent>
        <Box flex={1} justifyContent='flex-end' display='flex'></Box>
        {showFilters && (
          <Grid container spacing={isSm ? 2 : 3} style={{ marginTop: '4px', marginBottom: '8px' }}>
            <Grid item md={6} sm={6} xs={12}>
              <Typography className={classes.label}>Communication Type</Typography>
              <TextField
                name='communicationType'
                value={filters.communicationType}
                select
                variant='outlined'
                fullWidth
                onChange={onSelect}
                InputProps={{ classes: inputClasses }}
              >
                {[{ value: 'all', label: 'All' }].concat(communicationTypeOptions).map(({ value, label }) => (
                  <MenuItem key={label} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Typography className={classes.label}>Template Tags</Typography>
              <TextField
                name='templateTag'
                value={filters.templateTag}
                select
                variant='outlined'
                fullWidth
                onChange={onSelect}
                InputProps={{ classes: inputClasses }}
              >
                <MenuItem key='all' value='all'>
                  All
                </MenuItem>
                {communicationTemplatesLoading ? (
                  <Box display='flex' alignItems='center' justifyContent='center' py={1}>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  orderedTemplateOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value || ''}>
                      {option.label}
                    </MenuItem>
                  ))
                )}
              </TextField>
            </Grid>
            <Grid item container xs={12}>
              <Typography className={classes.label}>Date</Typography>
              <Grid item container spacing={isSm ? 2 : 3} style={{ marginTop: '4px' }}>
                <Grid xs={6} item>
                  <TextField
                    variant='outlined'
                    type='date'
                    label='From'
                    fullWidth
                    name='rangeStartDate'
                    onChange={onSelect}
                    value={filters.rangeStartDate}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ classes: inputClasses }}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    variant='outlined'
                    type='date'
                    label='To'
                    fullWidth
                    name='rangeEndDate'
                    onChange={onSelect}
                    value={filters.rangeEndDate}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ classes: inputClasses }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} direction='column'>
              <Typography className={classes.label}>Time</Typography>
              <Grid item container spacing={isSm ? 2 : 3} style={{ marginTop: '4px' }}>
                <Grid xs={6} item>
                  <TextField
                    variant='outlined'
                    type='time'
                    label='From'
                    fullWidth
                    name='rangeStartTime'
                    onChange={onSelect}
                    value={filters.rangeStartTime}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ classes: inputClasses }}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    variant='outlined'
                    type='time'
                    label='To'
                    fullWidth
                    name='rangeEndTime'
                    onChange={onSelect}
                    value={filters.rangeEndTime}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ classes: inputClasses }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {filteredCommunications.length ? (
          filteredCommunications
            .slice(0, initialListSize)
            .map((communication) => <VolunteerCommunicationParsed communication={communication} />)
        ) : (
          <Box alignItems='center'>
            <Typography style={{ fontSize: '14px', textAlign: 'center', marginTop: '1.5em', opacity: 0.8 }}>
              There are no communications that match your filter criteria
            </Typography>
          </Box>
        )}
        {filteredCommunications.length > initialListSize ? (
          <Box marginTop='32px'>
            <ShowMoreButton onClick={handleListSizeUpdate} />
          </Box>
        ) : null}
      </DialogContent>
    </FullscreenDialog>
  );
};

export { CommunicationViewMoreDialog };
