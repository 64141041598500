import { useRef, useState } from 'react';

export const ROSTER_SCROLL_DIMENSION = {
  PAGE_HEADER_HEIGHT: 72,
  PAGE_SUBHEADER_HEIGHT: 110,
  TABLE_HEADER_HEIGHT: 64,
  TABLE_COLUMN_FULL_WIDTH: 230,
  TABLE_COLUMN_SHRINK_WIDTH: 64,
  OTHER_VOLUNTEERS_CONTROLS_HEIGHT: 156,
  ROW_HEIGHT: 64,
  COLUMN_WIDTH: 256
};

export const useRosterScrolling = (headerHeight: number) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isVerticalScroll, setIsVerticalScroll] = useState(false);
  const [isHorizontalScroll, setIsHorizontalScroll] = useState(false);

  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const dynamicHeaderHeight = headerHeight + ROSTER_SCROLL_DIMENSION.PAGE_SUBHEADER_HEIGHT;

  const handleScroll = () => {
    if (!ref.current) return;

    if (ref.current.scrollTop > dynamicHeaderHeight && !isVerticalScroll) {
      ref.current.scrollTo({
        top: dynamicHeaderHeight + ROSTER_SCROLL_DIMENSION.TABLE_HEADER_HEIGHT + 10,
        behavior: 'auto',
      });
      setIsVerticalScroll(true);
    }
    if (ref.current.scrollTop < dynamicHeaderHeight && isVerticalScroll) {
      ref.current.scrollTo({ top: dynamicHeaderHeight - 10, behavior: 'auto' });
      setIsVerticalScroll(false);
    }
    if (
      ref.current.scrollLeft >
        ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH - ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_SHRINK_WIDTH &&
      !isHorizontalScroll
    ) {
      ref.current.scrollTo({
        left: ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH,
        behavior: 'auto',
      });
      setIsHorizontalScroll(true);
    }
    if (ref.current.scrollLeft < ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH && isHorizontalScroll) {
      ref.current.scrollTo({
        left: ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH - ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_SHRINK_WIDTH,
        behavior: 'auto',
      });
      setIsHorizontalScroll(false);
    }

    setScrollLeft(ref.current.scrollLeft);
    setScrollTop(ref.current.scrollTop);
  };

  return {
    ref,
    scrollLeft,
    scrollTop,
    isVerticalScroll,
    isHorizontalScroll,
    handleScroll,
  };
};
