import {
  GetActivitiesConsoleTeamInformation_vm_activity_activityEnrolments as IActivityEnrolment
} from './team/__generated__/GetActivitiesConsoleTeamInformation';

export const parsedTeamMember = (activityEnrolments?: IActivityEnrolment[]) => {
  if (!activityEnrolments) {
    return [];
  }

  return activityEnrolments
    .map((ae) => ({
      profileId: ae.volunteer.profile.profileId,
      userId: ae.volunteer.profile.userId,
      volunteerId: ae.volunteer.volunteerId,
      preferredName: ae.volunteer.profile.preferredName,
      lastName: ae.volunteer.profile.lastName,
      avatarUrl: ae.volunteer.profile.avatarUrl ?? undefined,
      position: ae.position,
    }))
    .sort((a, b) => a.preferredName.localeCompare(b.preferredName))
    .sort((a, b) => (a.position === 'al' && b.position === 'gv' ? -1 : 0));
}
