import { ErrorOutline } from '@material-ui/icons';
import { uniqBy } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { FullPageMessage } from '../../../../common/FullPageMessage';
import { useUser } from '../../../../global/auth/useUser';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ActivityTimelineMobile } from '../activity-timeline-mobile/ActivityTimelineMobile';
import { GET_ACTIVITY_TIMELINE_SCREEN_ACTIVITIES } from './activity-timeline-model.gql';
import { ActivityTimeline } from './ActivityTimeline';
import { ActivityTimelineProvider } from './ActivityTimelineContext';
import { PastRostersSkeletonLoadingLayout } from './past/roster/ActivityTimelinePastRoster';
import {
  GetActivityTimelineScreenActivities,
  GetActivityTimelineScreenActivitiesVariables,
} from './__generated__/GetActivityTimelineScreenActivities';

export const ManagerActivityTimeline = memo(() => {
  const routeMatch = useRouteMatch();
  const [activityId] = useQueryParam('activityId', StringParam);
  const [activityDate] = useQueryParam('activityDate', StringParam);
  const [returnSlug, setReturnSlug] = useState(routeMatch.path);
  const { maybeVolunteerIdentity } = useUser();
  const { data, error, loading } = useCampfireQuery<
    GetActivityTimelineScreenActivities,
    GetActivityTimelineScreenActivitiesVariables
  >(GET_ACTIVITY_TIMELINE_SCREEN_ACTIVITIES, {
    options: {
      variables: {
        volunteerId: maybeVolunteerIdentity?.volunteerId ?? '',
      },
    },
  });

  useEffect(() => {
    if (activityId && activityDate)
      setReturnSlug(`${routeMatch.path}?activityDate=${activityDate}&activityId=${activityId}`);
  }, [activityId, activityDate, routeMatch]);

  const activities = useMemo(() => {
    const managedActivities = data?.vm.programs.flatMap((program) => program.activities) ?? [];
    const ledActivities = data?.vm.volunteer?.activityEnrolments.map((enrolment) => enrolment.activity) ?? [];
    return uniqBy([...managedActivities, ...ledActivities], 'activityId');
  }, [data]);

  const { isMobile } = useCampfireTheme();

  return (
    <ActivityTimelineProvider>
      {error && !loading ? (
        <FullPageMessage title={'Network Error'} subtitle={'Unable to load timeline.'} Icon={ErrorOutline} />
      ) : loading ? (
        <PastRostersSkeletonLoadingLayout />
      ) : isMobile ? (
        <ActivityTimelineMobile activities={activities} returnSlug={returnSlug} />
      ) : (
        <ActivityTimeline activities={activities} returnSlug={returnSlug} />
      )}
    </ActivityTimelineProvider>
  );
});
