import React, { useState } from 'react';
import { Box, Typography, Collapse, Theme } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowUp } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { ValueGroupType, WidgetValueType, ByValueType } from './dashboard-widget-model';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '56px',
      borderRadius: '6px',
      padding: '0 16px',
      cursor: 'pointer',
      position: 'relative',
      marginTop: '.5rem',
      '&:hover': {
        backgroundColor: `${theme.color.grey.neutralBrand100} !important`,
      },
    },
  })
);

const ValueItemDetail = ({
  byValue,
  zIndex,
  handleSelectValue,
  selectedValue,
}: {
  byValue: ByValueType;
  zIndex: number;
  handleSelectValue: (value: string) => void;
  selectedValue?: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { theme } = useCampfireTheme();
  const classes = useStyles();

  const isSelected = selectedValue === byValue.value && !byValue.valueItems;

  return (
    <>
      <Box
        className={classes.rootItem}
        style={{
          marginTop: '-8px',
          backgroundColor: isSelected ? theme.color.grey.neutralBrand50 : 'white',
          border: `1px solid ${theme.color.grey.neutralBrand200}`,
          zIndex: zIndex,
        }}
        onClick={() => (!byValue.valueItems ? handleSelectValue(byValue.value) : setIsExpanded(!isExpanded))}
      >
        <Typography
          style={{
            fontSize: '15px',
            marginTop: '4px',
            paddingLeft: '5px',
            paddingTop: '2px',
            color: theme.color.grey.neutral300,
          }}
        >
          {byValue.name}
        </Typography>

        {isExpanded ? (
          <KeyboardArrowUp style={{ color: theme.color.grey.neutral200 }} />
        ) : (
          <KeyboardArrowRight style={{ color: theme.color.grey.neutral200 }} />
        )}
      </Box>

      {byValue.valueItems && (
        <Collapse in={isExpanded}>
          {byValue.valueItems.map((value, index) => (
            <Box
              className={classes.rootItem}
              style={{
                marginTop: '-8px',
                marginBottom: byValue.valueItems && index === byValue.valueItems.length - 1 ? '8px' : 0,
                backgroundColor: isSelected ? theme.color.grey.neutralBrand50 : 'white',
                border: `1px solid ${theme.color.grey.neutralBrand200}`,
                zIndex: zIndex - 1 - index,
              }}
              onClick={() => handleSelectValue(value.value)}
            >
              <Typography
                style={{
                  fontSize: '15px',
                  marginTop: '4px',
                  paddingLeft: '5px',
                  paddingTop: '2px',
                  color: theme.color.grey.neutral300,
                }}
              >
                {value.name}
              </Typography>
            </Box>
          ))}
        </Collapse>
      )}
    </>
  );
};

export const ValueGroup = ({
  group,
  onSelect,
  selectedValue,
}: {
  group: ValueGroupType;
  onSelect: (value: WidgetValueType) => void;
  selectedValue?: WidgetValueType;
}) => {
  const { theme } = useCampfireTheme();
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSelectValue = (name: string) => {
    onSelect({ tag: group.value, name });
  };

  return (
    <Box>
      <Box
        onClick={onClickExpand}
        className={classes.rootItem}
        style={{
          backgroundColor: theme.color.grey.neutralBrand100,
          border: `1px solid ${theme.color.grey.neutralBrand200}`,
          zIndex: group.values.length + 100,
        }}
      >
        <Typography
          style={{
            fontWeight: 500,
            fontSize: '16px',
            color: theme.color.grey.neutral300,
            paddingLeft: '5px',
            paddingTop: '2px',
          }}
        >
          {group.tag}
        </Typography>
        {isExpanded ? (
          <KeyboardArrowUp style={{ color: theme.color.grey.neutral200 }} />
        ) : (
          <KeyboardArrowRight style={{ color: theme.color.grey.neutral200 }} />
        )}
      </Box>
      <Collapse in={isExpanded}>
        {group.values.map((value, index) => (
          <ValueItemDetail
            byValue={value}
            zIndex={group.values.length + 99 - index}
            handleSelectValue={handleSelectValue}
            selectedValue={selectedValue?.name}
          />
        ))}
      </Collapse>
    </Box>
  );
};
// interface Props {
//   createByTab: CreateBy;
//   setCreateByTab: (tab: CreateBy) => void;
//   setSubtitle: (subtitle: Subtitles) => void;
//   isAddByValue?: boolean;
//   byValueEditing?: WidgetValueType;
//   goToStep: (step: Steps) => void;
//   setByValueEditing?: (byValue?: WidgetValueType) => void;
// }
// export const SelectCreateWidgetBy = ({
//   createByTab,
//   setCreateByTab,
//   setSubtitle,
//   isAddByValue,
//   byValueEditing,
//   goToStep,
//   setByValueEditing,
// }: Props) => {
//   const { setFieldValue, values: formValues } = useFormikContext<CreatWidgetFormValues>();

//   useEffect(() => {
//     if (createByTab === CreateBy.BY_TYPE && !formValues.byValues.length) {
//       setSubtitle(Subtitles.CreateByType);
//     }
//     if (createByTab === CreateBy.BY_TYPE && formValues.byType) {
//       setSubtitle(Subtitles.SelectType);
//     }
//     if (createByTab === CreateBy.BY_VALUE && !formValues.byValues.length) {
//       setSubtitle(Subtitles.CreateByValue);
//     }
//     if (createByTab === CreateBy.BY_VALUE && formValues.byType) {
//       setSubtitle(Subtitles.SelectValue);
//     }
//   }, [formValues, createByTab]);

//   const onClickCreateBy = (type: CreateBy) => {
//     setCreateByTab(type);
//   };

//   const onSelectWidgetType = (type: WidgetTypes) => {
//     setFieldValue('byType', type);
//   };

//   const onSelectValue = (value: WidgetValueType) => {
//     if (byValueEditing) {
//       const byValues = cloneDeep(formValues.byValues);
//       const oldValueIndex = byValues.findIndex(
//         (item) => item.name === byValueEditing.name && item.tag === byValueEditing.tag
//       );
//       byValues[oldValueIndex].name = value.name;
//       byValues[oldValueIndex].tag = value.tag;
//       setFieldValue('byValues', [...byValues]);
//       if (setByValueEditing) setByValueEditing(undefined);
//     } else {
//       const byValues = formValues.byType === WidgetTypes.CHART ? [...formValues.byValues, value] : [value];
//       setFieldValue('byValues', byValues);
//     }

//     if (formValues.byType) {
//       handleNextStep(formValues.byType);
//     } else {
//       onClickCreateBy(CreateBy.BY_TYPE);
//     }
//   };

//   const handleNextStep = (type: WidgetTypes) => {
//     if (
//       (type === WidgetTypes.NUMBER || type === WidgetTypes.CHART || type === WidgetTypes.NUMBER_CHART) &&
//       !byValueEditing &&
//       !isAddByValue
//     ) {
//       goToStep(Steps.SELECT_DETAIL_TYPE);
//     } else {
//       goToStep(Steps.WIDGET_FORM);
//     }
//   };

//   return (
//     <Grid container direction='column' style={{ width: '100%', padding: '0 16px' }}>
//       {!isAddByValue && !byValueEditing && (
//         <Box display='flex' justifyContent='center'>
//           <ButtonGroup disableElevation>
//             <Button
//               style={{
//                 textTransform: 'none',
//                 width: '140px',
//                 borderRadius: '8px',
//                 marginRight: '-12px',
//                 padding: '10px 16px',
//                 zIndex: createByTab === CreateBy.BY_TYPE ? 1 : undefined,
//               }}
//               onClick={() => onClickCreateBy(CreateBy.BY_TYPE)}
//               variant={createByTab === CreateBy.BY_TYPE ? 'contained' : 'outlined'}
//               color={createByTab === CreateBy.BY_TYPE ? 'primary' : undefined}
//             >
//               <Typography
//                 color={createByTab === CreateBy.BY_TYPE ? undefined : 'primary'}
//                 style={{ fontSize: '14px', fontWeight: 700 }}
//               >
//                 By Type
//               </Typography>
//             </Button>
//             <Button
//               style={{ textTransform: 'none', width: '140px', borderRadius: '8px', padding: '10px 16px' }}
//               onClick={() => onClickCreateBy(CreateBy.BY_VALUE)}
//               variant={createByTab === CreateBy.BY_VALUE ? 'contained' : 'outlined'}
//               color={createByTab === CreateBy.BY_VALUE ? 'primary' : undefined}
//             >
//               <Typography
//                 color={createByTab === CreateBy.BY_VALUE ? undefined : 'primary'}
//                 style={{ fontSize: '14px', fontWeight: 700 }}
//               >
//                 By Value
//               </Typography>
//             </Button>
//           </ButtonGroup>
//         </Box>
//       )}

//       <Box mt={3}>
//         {createByTab === CreateBy.BY_TYPE && !byValueEditing && !isAddByValue && (
//           <Grid container spacing={2}>
//             {widgetTypes.map((widgetType) => (
//               <Grid item sm={4}>
//                 <Box display='flex' justifyContent='center'>
//                   <ThumbnailWidgetCard
//                     {...widgetType}
//                     onClick={onSelectWidgetType}
//                     isSelected={formValues.byType === widgetType.type}
//                   />
//                 </Box>
//               </Grid>
//             ))}
//           </Grid>
//         )}

//         {(createByTab === CreateBy.BY_VALUE || byValueEditing || isAddByValue) && (
//           <Box>
//             {valueLists.map((group) => (
//               <ValueGroup group={group} onSelect={onSelectValue} />
//             ))}
//           </Box>
//         )}
//       </Box>
//     </Grid>
//   );
// };
