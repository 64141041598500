import { gql } from '@apollo/client';
import { IncidentReportFormCategory } from './__generated__/IncidentReportFormCategory';

const INCIDENT_REPORT_VOLUNTEER = gql`
  fragment IncidentReportVolunteer on VOLUNTEER_VolunteerType {
    volunteerId
    profile {
      preferredName
      lastName
      avatarUrl
    }
  }
`;

const INCIDENT_REPORT_ACTIVITY = gql`
  fragment IncidentReportActivity on VOLUNTEER_ActivityType {
    activityId
    name
    incidentLocation {
      incidentLocationId
    }
    closedActivity {
      closedActivityId
      endDate
    }
    activityEnrolments {
      volunteer {
        volunteerId
      }
    }
    startTime
    endTime
    activityLocation {
      placesAddress {
        description
      }
    }
  }
`;

const INCIDENT_REPORT_PROGRAM = gql`
  fragment IncidentReportProgram on VOLUNTEER_ProgramType {
    programId
    name
    activeVolunteers {
      ...IncidentReportVolunteer
    }
    activities {
      ...IncidentReportActivity
    }
  }

  ${INCIDENT_REPORT_ACTIVITY}
  ${INCIDENT_REPORT_VOLUNTEER}
`;

export const GET_INCIDENT_REPORT_ALL_PROGRAMS = gql`
  query GetIncidentReportAllPrograms {
    vm {
      programs {
        ...IncidentReportProgram
      }
    }
  }

  ${INCIDENT_REPORT_PROGRAM}
`;

export const GET_INCIDENT_REPORT_VOLUNTEER_PROGRAMS = gql`
  query GetIncidentReportVolunteerPrograms($userId: String!) {
    vm {
      profile(userId: $userId) {
        programs {
          ...IncidentReportProgram
        }
      }
    }
  }

  ${INCIDENT_REPORT_PROGRAM}
`;

export const GET_INCIDENT_REPORT_SCREEN = gql`
  query GetIncidentReportScreen {
    vm {
      identity {
        globalRoles {
          privilegeRoleId
          name
          rules {
            privilegeRuleId
            key
          }
        }
      }
      incidentCategorySelectionRoot {
        ...IncidentReportFormCategorySelectonRootCategories
      }
      incidentSeverities {
        ...IncidentReportIncidentSeverity
      }
    }
  }

  fragment IncidentReportIncidentSeverity on VOLUNTEER_IncidentSeverityType {
    incidentSeverityId
    name
    color
    description
    order
  }

  fragment IncidentReportFormCategorySelectonRootCategories on VOLUNTEER_IncidentCategoryGroupType {
    ...IncidentReportFormCategoryGroup
    childGroups {
      ...IncidentReportFormCategoryGroup
      childGroups {
        ...IncidentReportFormCategoryGroup
        childGroups {
          ...IncidentReportFormCategoryGroup
          childGroups {
            ...IncidentReportFormCategoryGroup
            childGroups {
              ...IncidentReportFormCategoryGroup
              childGroups {
                ...IncidentReportFormCategoryGroup
                childGroups {
                  ...IncidentReportFormCategoryGroup
                  childGroups {
                    ...IncidentReportFormCategoryGroup
                    childGroups {
                      ...IncidentReportFormCategoryGroup
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment IncidentReportFormCategoryGroup on VOLUNTEER_IncidentCategoryGroupType {
    incidentCategoryGroupId
    label
    categories {
      ...IncidentReportFormCategory
    }
  }

  fragment IncidentReportFormCategory on VOLUNTEER_IncidentCategoryType {
    incidentCategoryId
    label
    details {
      label
      incidentDetailId
    }
  }

  ${INCIDENT_REPORT_PROGRAM}
`;

export const GET_INCIDENT_REPORT_SINGLE_INCIDENT = gql`
  query GetIncidentReportSingleIncident($incidentId: String!) {
    vm {
      incident(incidentId: $incidentId) {
        ...IncidentReportSingleIncident
      }
    }
  }
  fragment IncidentReportSingleIncident on VOLUNTEER_IncidentType {
    incidentId
    incidentStatus
    dateWorking
    dateClosed
    closingComments
    program {
      programId
      name
    }
    incidentReports {
      severity {
        incidentSeverityId
        name
        color
      }
      category {
        label
        details {
          incidentDetailId
          label
        }
      }
      incidentReportId
      incidentDateTime
      submissionDateTime
      referenceNumber
      othersInvolved
      description
      furtherAction
      detail {
        ...IncidentReportSingleIncidentDetail
      }
      incidentLocation {
        __typename
        incidentLocationId
        ... on VOLUNTEER_ActivityIncidentLocationType {
          activity {
            activityId
            name
            activityLocation {
              placesAddress {
                description
              }
            }
          }
        }
        ... on VOLUNTEER_AddressIncidentLocationType {
          address {
            lineOne
            lineTwo
            suburb
            state
            postcode
            country
          }
        }
      }
      submittedBy {
        volunteerId
        profile {
          profileId
          preferredName
          lastName
          avatarUrl
          email
        }
      }
      volunteersInvolved {
        volunteerId
        profile {
          profileId
          preferredName
          lastName
          avatarUrl
        }
      }
      resources {
        imageId: incidentReportResourceId
        imageUrl: resourceUrl
      }
    }
  }

  fragment IncidentReportSingleIncidentDetail on VOLUNTEER_IncidentDetailType {
    incidentDetailId
    label
    incidentCategory {
      ...IncidentReportSingleIncidentIncidentCategoryGroups
    }
  }

  fragment IncidentReportSingleIncidentIncidentCategoryGroups on VOLUNTEER_IncidentCategoryType {
    incidentCategoryId
    label
    incidentCategoryGroup {
      ...IncidentReportSingleIncidentIncidentCategoryGroup
      parentGroup {
        ...IncidentReportSingleIncidentIncidentCategoryGroup
        parentGroup {
          ...IncidentReportSingleIncidentIncidentCategoryGroup
          parentGroup {
            ...IncidentReportSingleIncidentIncidentCategoryGroup
            parentGroup {
              ...IncidentReportSingleIncidentIncidentCategoryGroup
              parentGroup {
                ...IncidentReportSingleIncidentIncidentCategoryGroup
                parentGroup {
                  ...IncidentReportSingleIncidentIncidentCategoryGroup
                  parentGroup {
                    ...IncidentReportSingleIncidentIncidentCategoryGroup
                    parentGroup {
                      ...IncidentReportSingleIncidentIncidentCategoryGroup
                      parentGroup {
                        ...IncidentReportSingleIncidentIncidentCategoryGroup
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment IncidentReportSingleIncidentIncidentCategoryGroup on VOLUNTEER_IncidentCategoryGroupType {
    incidentCategoryGroupId
    label
  }
`;

export type IncidentReportCategoryChildGroupType = {
  incidentCategoryGroupId: string;
  label: string;
  childGroups: Array<IncidentReportCategoryChildGroupType>;
  categories: Array<IncidentReportFormCategory>;
};
