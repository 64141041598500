import React from 'react';
import { HoverLink } from '@campfire/hover-link';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Edit } from '@material-ui/icons';

const editButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 12,
      height: 12,
      marginLeft: 4,
    },
  })
);

type EditLinkProps = {
  link: string;
  linkText: string;
};

const EditLink = ({ link, linkText }: EditLinkProps) => {
  const { root: editButton } = editButtonStyles();
  return (
    <HoverLink color='primary' hoverColor='primary' to={link}>
      <Typography variant='body2' display='inline'>
        {linkText}
      </Typography>
      <Edit fontSize='small' className={editButton} />
    </HoverLink>
  );
};

export { EditLink };
