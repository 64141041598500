import React, { memo } from 'react';
import { Button, List, ListSubheader } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { SectionCollapsible } from '../../common/SectionCollapsible';
import { SectionHeader } from '../../common/SectionHeader';
import { SectionInstruction } from '../../common/SectionInstruction';
import { VolunteerCompletedTaskItem } from './VolunteerCompletedTaskItem';

export interface CompletedTask {
  completedTaskId: string;
  dateSubmitted: string;
  status: string;
  task: {
    taskId: string;
    title: string;
    adminOnly: boolean;
  };
}

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      alignItems: 'center',
      '& > span': {
        textTransform: 'none',
      },
      borderRadius: 6,
      height: 38,
    },
  })
);

interface VolunteerTasksSectionProps {
  completedTasks: Array<CompletedTask>;
  userId: string;
  profileId: string;
  hasMore: boolean;
  loadMore: () => void;
  refetch: () => void;
}

export const VolunteerTasksSection = memo((props: VolunteerTasksSectionProps) => {
  const classes = useStyles();
  const { completedTasks, userId, profileId, hasMore, loadMore, refetch } = props;

  return (
    <>
      <SectionCollapsible
        sectionHeader={<SectionHeader title='Complete Tasks' />}
        instruction={<SectionInstruction>{`Tasks submitted by or for this volunteer`}</SectionInstruction>}
        collapseContent={
          <List disablePadding dense className={classes.list}>
            <ListSubheader style={{ position: 'unset' }}>{`Tasks submitted by or for this volunteer`}</ListSubheader>
            {completedTasks.map((completedTask) => (
              <VolunteerCompletedTaskItem
                completedTask={completedTask}
                userId={userId}
                profileId={profileId}
                refetch={refetch}
              />
            ))}
            {hasMore && (
              <Button
                onClick={loadMore}
                style={{ alignSelf: 'center', marginTop: '1rem' }}
                className={classes.button}
                variant='outlined'
                disableElevation
                endIcon={<ExpandMore />}
              >
                Show more
              </Button>
            )}
          </List>
        }
        expandText='View tasks'
        idStub='tasks'
      />
    </>
  );
});
