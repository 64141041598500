import { unpackToDateTime, unpackToDate } from '@campfire/hot-date/lib';
import { HoverText } from '@campfire/hover-link/lib';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, Grid, Typography } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box';
import { ChevronLeft, ErrorOutline } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { memo, useMemo } from 'react';
import { FullPageMessage } from '../../../../../../common/FullPageMessage';
import PrivilegeRules from '../../../../../../global/auth/PrivilegeRules';
import { useUser } from '../../../../../../global/auth/useUser';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { GET_ACTIVITY_TIMELINE_PAST_REPORT } from '../../activity-timeline-model.gql';
import {
  GetActivityTimelinePastReport,
  GetActivityTimelinePastReportVariables,
} from '../../__generated__/GetActivityTimelinePastReport';
import { CancelPastActivityHoverText } from '../CancelPastActivityHoverText';
import { SessionReportCard, SessionReportSkeletonLoadingCard } from './SessionReportCard';

const useStyles = makeStyles(() =>
  createStyles({
    sessionReportList: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 8,
      paddingBottom: 96,
    },
    headingBox: {
      borderBottom: 'solid #dadada 1px',
      backgroundColor: '#ffffff',
    },
  })
);

interface ActivityTimelinePastReportProps {
  activityId: string;
  activityDate: string;
  SessionReportListBoxProps?: BoxProps;
  onSessionReportSubmit?: (res: {
    activityReportId: string;
    sessionReportId: string;
    sessionId: string;
    activitySessionIds: string[];
  }) => void;
  onSessionReportCancel?: (res: { activityReportId: string; sessionId: string; activitySessionIds: string[] }) => void;
  onCancelActivity?: (res: { cancelledActivityId: string; activityDate: string }) => void;
  source: 'activity-report' | 'activities-console' | 'your-activities';
  onBack: () => void;
}

export const ActivityTimelinePastReport = memo((props: ActivityTimelinePastReportProps) => {
  const {
    activityId,
    activityDate,
    onSessionReportSubmit,
    onSessionReportCancel,
    onCancelActivity,
    SessionReportListBoxProps,
    source,
    onBack,
  } = props;
  const { theme } = useCampfireTheme();
  const {
    user: { userIdentityService },
  } = useUser();

  const { data, error, loading, refetch } = useCampfireQuery<
    GetActivityTimelinePastReport,
    GetActivityTimelinePastReportVariables
  >(GET_ACTIVITY_TIMELINE_PAST_REPORT, {
    options: {
      variables: {
        activityId,
        activityDate,
      },
    },
  });

  const activityReport = useMemo(() => {
    if (!data) return undefined;
    return data.vm.activityReport;
  }, [data]);

  const activity = useMemo(() => {
    if (!data) return undefined;
    return data.vm.activity;
  }, [data]);

  const publishedRoster = useMemo(() => {
    if (!data) return undefined;
    return data.vm.publishedRoster;
  }, [data]);

  const canCancelActivity = useMemo(() => {
    if (!activity) return false;
    return userIdentityService.hasRuleForProgram(activity.program.programId, PrivilegeRules.cancelActivity);
  }, [userIdentityService, activity]);

  const classes = useStyles();
  const { isXs, isSm } = useCampfireTheme();
  const compact = isXs || isSm;

  return (
    <Box bgcolor={theme.palette.grey[200]} minHeight='100%'>
      {error && !loading ? (
        <FullPageMessage title={'Network Error'} subtitle={'Unable to load timeline.'} Icon={ErrorOutline} />
      ) : null}

      {activity && source !== 'your-activities' ? (
        <Box paddingX={2} paddingY={2} className={classes.headingBox}>
          <HoverText
            color='primary'
            hoverColor='primary'
            display='inline'
            variant='h5'
            onClick={onBack}
            data-track='actCnl-back-to-activity-overview'
          >
            <TitularTooltip title='Go Back' placement='right'>
              <Grid container item style={{ display: 'inline' }}>
                <ChevronLeft style={{ marginRight: 4, fontSize: 22 }} />
                <Typography variant='h5' display='inline'>
                  {activity.name}
                </Typography>
              </Grid>
            </TitularTooltip>
          </HoverText>

          <Box display='flex' alignContent='baseline' alignItems='baseline'>
            <Typography variant='body2' style={{ marginRight: 12 }}>
              {unpackToDateTime(activityDate).toFormat('cccc, d LLLL')}
            </Typography>

            {compact && activity ? (
              <CancelPastActivityHoverText
                activityId={activityId}
                activityName={activity.name}
                activityDate={activityDate}
                onCancelActivity={onCancelActivity}
              />
            ) : null}
          </Box>
        </Box>
      ) : null}

      <Box
        width={1}
        paddingX={3}
        paddingY={2}
        bgcolor='#ffffff'
        borderBottom='solid #dadada 1px'
        style={{ boxSizing: 'border-box' }}
      >
        <Box display='flex' justifyContent='space-between' alignContent='center' alignItems='center'>
          <Box>
            <Typography variant='body2'>{'Activity Report'}</Typography>
            <Typography variant='body2' color='textSecondary'>
              {activityReport && activity
                ? `${activityReport?.sessionReports.length} of ${activity?.sessions.length} reports submitted`
                : 'No reports submitted'}
            </Typography>
          </Box>

          {/*
           * We move this button further up the screen for mobile
           */}
          {!compact && activity && canCancelActivity ? (
            <CancelPastActivityHoverText
              activityId={activityId}
              activityName={activity.name}
              activityDate={activityDate}
              onCancelActivity={onCancelActivity}
            />
          ) : null}
        </Box>
      </Box>

      {loading ? (
        <Box display='flex' width={1} justifyContent='center' className={classes.sessionReportList}>
          <Box width={1} {...SessionReportListBoxProps}>
            <SessionReportSkeletonLoadingCard />
          </Box>
        </Box>
      ) : activity ? (
        <Box display='flex' justifyContent='center'>
          <Box {...SessionReportListBoxProps} component='ul' className={classes.sessionReportList}>
            {activity.allSessions
              .filter((session) =>
                !session.dateRemoved ? true : unpackToDate(session.dateRemoved) >= unpackToDate(activityDate)
              )
              .map((session) => {
                const sessionReport = activityReport?.sessionReports.find(
                  (x) => x.session.sessionId === session.sessionId
                );
                const cancelledSession = !!activityReport?.cancelledSessions.find(
                  (x) => x.sessionId === session.sessionId
                );
                return (
                  <Box
                    key={session.sessionId}
                    component='li'
                    style={{
                      listStyle: 'none',
                      margin: 0,
                      padding: 0,
                      paddingTop: 8,
                      paddingBottom: 8,
                    }}
                  >
                    <SessionReportCard
                      refetch={refetch}
                      activity={activity}
                      session={session}
                      activityDate={activityDate}
                      sessionReport={sessionReport}
                      cancelledSession={cancelledSession}
                      publishedRoster={publishedRoster ?? undefined}
                      defaultCollapsed={
                        activityReport ? activityReport.sessionReports.length < activity.sessions.length : false
                      }
                      onSessionReportSubmit={onSessionReportSubmit}
                      onSessionReportCancel={onSessionReportCancel}
                    />
                  </Box>
                );
              })}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
});
