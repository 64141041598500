import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Lock } from '@material-ui/icons';

import { useCampfireTheme } from '../../../../../../../../../theme/useCampfireTheme';

export const LockedStatus = React.memo(() => {
  const { theme } = useCampfireTheme();

  return (
    <Grid item container xs justify='flex-end'>
      <TitularTooltip
        description={'This field is required as part of your Volaby profile and cannot be edited or removed'}
      >
        <Box>
          <Grid container>
            <Grid item>
              <Typography variant='caption' style={{ color: `${theme.palette.action.disabled}` }}>
                {'Locked'}
              </Typography>
            </Grid>
            <Grid item>
              <Lock style={{ color: `${theme.palette.action.disabled}` }} fontSize='small' />
            </Grid>
          </Grid>
        </Box>
      </TitularTooltip>
    </Grid>
  );
});
