import { gql } from '@apollo/client';

export const ActivitySessionFragments = gql`
  fragment ActivitySession on VOLUNTEER_SessionType {
    sessionId
    name
    description
    startTime
    endTime
    minVolunteers
    maxVolunteers
    reportType {
      name
      description
    }
    activityLocation {
      placesAddress {
        placesId
        description
        formatted
      }
    }
    activity {
      publishedRosters {
        rosterings {
          volunteer {
            volunteerId
          }
        }
      }
    }
    sessionReports {
      CICOs {
        CICOId
        checkIn
        checkOut
        volunteer {
          volunteerId
        }
        sessionReport {
          activityReport {
            activity {
              activityId
            }
            activityDate
          }
        }
      }
    }
    autoReport
  }
`;
