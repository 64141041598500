import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import React, { createContext, useState } from 'react';
import { useApiUrl } from './useApiUrl';
import { useCampfireVersion } from './useCampfireVersion';

export interface ApolloContextInterface {
  client: ApolloClient<any>;
}

export const CampfireApolloContext = createContext<ApolloContextInterface | undefined>(undefined);

const FUSION_SLUG = 'fusion';
export const createApolloClient = (apiUrl: string, token?: string) => {
  const versionContext = useCampfireVersion();

  const fusionBaseUrl = `${apiUrl}/${FUSION_SLUG}`;

  const cache = new InMemoryCache();

  const httpLink = new HttpLink({
    uri: fusionBaseUrl,
    headers: token
      ? {
          authorization: `JWT ${token}`,
        }
      : {},
  });

  const opLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ uri = fusionBaseUrl }: { uri: string }) => ({
      uri: `${uri}?op=${operation.operationName}`,
    }));

    return forward(operation);
  });

  const versionLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response: any) => {
      versionContext.setServerVersion(response.campfireVersion);
      return response;
    });
  });

  return new ApolloClient({
    cache: cache,
    link: from([opLink, versionLink, httpLink]),
    queryDeduplication: true,
  });
};

export interface ApolloProviderProps {
  children: React.ReactNode;
  token?: string;
}

export const CampfireApolloProvider = ({ children, token }: ApolloProviderProps) => {
  const apiUrl = useApiUrl();
  const [client] = useState(createApolloClient(apiUrl, token));
  return (
    <CampfireApolloContext.Provider value={{ client }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </CampfireApolloContext.Provider>
  );
};
