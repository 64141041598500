import { HoverText } from '@campfire/hover-link';
import { Box, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useUser } from '../../../../../../global/auth/useUser';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { AccountSettingsDialog } from '../../../../user-profile/account-settings/AccountSettingsDialog';
import { Avatar } from '../../../../user-profile/components/Avatar';
import { GET_MY_ELEMENTS_SIDE_BAR_MY_PROFILE_DATA } from './side-bar-profile-model.gql';
import { SideBarProfileSectionSkeleton } from './SideBarProfileSectionSkeleton';
import {
  GetMyElementsSideBarMyProfileData,
  GetMyElementsSideBarMyProfileDataVariables,
} from './__generated__/GetMyElementsSideBarMyProfileData';

export const SideBarProfileSection = () => {
  const { getVolunteerIdentity } = useUser();
  const volunteerIdentity = getVolunteerIdentity();
  const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);

  const { theme } = useCampfireTheme();
  const {
    user: { userIdentityService },
  } = useUser();

  const { data, loading, refetch: sideBarProfileRefetch } = useCampfireQuery<
    GetMyElementsSideBarMyProfileData,
    GetMyElementsSideBarMyProfileDataVariables
  >(GET_MY_ELEMENTS_SIDE_BAR_MY_PROFILE_DATA, {
    options: {
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
      },
    },
  });
  const myProfile = data?.vm.volunteer?.profile;

  const volunteerPosition: string = useMemo(() => {
    if (!data?.vm.volunteer) {
      return 'General Volunteer';
    }

    const { isProgramManager, isVmAdmin } = userIdentityService;

    if (isVmAdmin) {
      return 'Admin';
    }

    if (isProgramManager) {
      return 'Program Manager';
    }

    const isActivityLeader = data.vm.volunteer.activityEnrolments.some(({ position }) => position === 'al');

    if (isActivityLeader) {
      return 'Activity Leader';
    }

    return 'General Volunteer';
  }, [data, userIdentityService]);

  return (
    <>
      {loading ? (
        <SideBarProfileSectionSkeleton />
      ) : myProfile ? (
        <Box padding='24px 0px' display='flex' alignItems='center'>
          <Avatar
            size={110}
            avatarUrl={myProfile.avatarUrl}
            preferredName={myProfile.preferredName}
            lastName={myProfile.lastName}
          />
          <Box marginLeft='24px'>
            <Typography
              variant='h4'
              style={{
                color: theme.color.black,
                fontSize: '20px',
                fontWeight: 800,
                whiteSpace: 'break-spaces',
                letterSpacing: '-0.02em',
              }}
            >{`${myProfile.preferredName} ${myProfile.lastName}`}</Typography>
            <Typography variant='body2'>{volunteerPosition}</Typography>
            <HoverText
              onClick={() => setOpenEditProfileDialog(true)}
              color='primary'
              invertUnderlineBehaviour
              style={{ marginTop: '18px' }}
            >
              <Typography variant='caption' component='span'>
                Edit profile
              </Typography>
            </HoverText>
          </Box>
        </Box>
      ) : null}
      <AccountSettingsDialog
        open={openEditProfileDialog}
        setOpen={setOpenEditProfileDialog}
        refetch={sideBarProfileRefetch}
      />
    </>
  );
};
