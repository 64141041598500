import React from 'react';
import { Box, SvgIcon } from '@material-ui/core';
import {
  ArrowDropDownCircleRounded,
  AssignmentTurnedIn,
  AttachFile,
  Call,
  CheckBox,
  Email,
  LocationOn,
  Notes,
  RadioButtonChecked,
  ShortText,
  Create,
} from '@material-ui/icons';
import { ReactComponent as HashtagIcon } from '../../../../assets/hash-24px.svg';
import { TaskItemsDeserialize } from '../utils';
import { TaskItemFieldHeading } from './TaskItemField/TaskItemFieldHeading';
import { TaskItemFieldType } from './TaskItemField/TaskItemFieldType';

interface TaskItemFieldProps {
  items: TaskItemsDeserialize;
  taskItemId: string;
}

export function TaskItemField({ items, taskItemId }: TaskItemFieldProps) {
  const taskItem = items.byId[taskItemId];

  if (taskItem.__typename !== 'VOLUNTEER_TaskItemFieldType') {
    return null;
  }

  switch (taskItem.field.__typename) {
    case 'VOLUNTEER_LongTextTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Long Answer Text'
            icon={<Notes style={{ color: '#0000008A', fontSize: 20 }} />}
          />
        </Box>
      );

    case 'VOLUNTEER_ShortTextTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Short Answer Text'
            icon={<ShortText style={{ color: '#0000008A', fontSize: 20 }} />}
          />
        </Box>
      );

    case 'VOLUNTEER_AddressTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Address'
            icon={<LocationOn style={{ color: '#0000008A', fontSize: 20 }} />}
          />
        </Box>
      );
    case 'VOLUNTEER_AttachmentTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Attachments'
            icon={<AttachFile style={{ color: '#0000008A', fontSize: 20 }} />}
          />
        </Box>
      );
    case 'VOLUNTEER_CheckboxTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Checkboxes'
            icon={<CheckBox style={{ color: '#0000008A', fontSize: 20 }} />}
          />
          <Box paddingLeft='30px'>
            {taskItem.field.checkboxOptions.map((option) => {
              const triggerItemIds = items.byTrigger[option.checkboxFieldOptionId]?.triggerItemIds || [];
              if (triggerItemIds.length) {
                return triggerItemIds.map((itemId) => <TaskItemField key={itemId} items={items} taskItemId={itemId} />);
              }
              return null;
            })}
          </Box>
        </Box>
      );
    case 'VOLUNTEER_DropdownTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Dropdown'
            icon={<ArrowDropDownCircleRounded style={{ color: '#0000008A', fontSize: 20 }} />}
          />
          <Box paddingLeft='30px'>
            {taskItem.field.dropdownOptions.map((option) => {
              const triggerItemIds = items.byTrigger[option.dropdownFieldOptionId]?.triggerItemIds || [];
              if (triggerItemIds.length) {
                return triggerItemIds.map((itemId) => <TaskItemField key={itemId} items={items} taskItemId={itemId} />);
              }
              return null;
            })}
          </Box>
        </Box>
      );
    case 'VOLUNTEER_EmailTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Email'
            icon={<Email style={{ color: '#0000008A', fontSize: 20 }} />}
          />
        </Box>
      );
    case 'VOLUNTEER_MultipleChoiceTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Multiple Choice'
            icon={<RadioButtonChecked style={{ color: '#0000008A', fontSize: 20 }} />}
          />
          <Box paddingLeft='30px'>
            {taskItem.field.multipleChoiceOptions.map((option) => {
              const triggerItemIds = items.byTrigger[option.multipleChoiceFieldOptionId]?.triggerItemIds || [];
              if (triggerItemIds.length) {
                return triggerItemIds.map((itemId) => <TaskItemField key={itemId} items={items} taskItemId={itemId} />);
              }
              return null;
            })}
          </Box>
        </Box>
      );
    case 'VOLUNTEER_NumberTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Number'
            icon={
              <SvgIcon style={{ color: '#0000008A', fontSize: 20 }}>
                <HashtagIcon />
              </SvgIcon>
            }
          />
        </Box>
      );
    case 'VOLUNTEER_PhoneTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Phone Number'
            icon={<Call style={{ color: '#0000008A', fontSize: 20 }} />}
          />
        </Box>
      );
    case 'VOLUNTEER_QuizTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Quiz'
            icon={<AssignmentTurnedIn style={{ color: '#0000008A', fontSize: 20 }} />}
          />
          <Box paddingLeft='30px'>
            {taskItem.field.quizTaskFieldOptions.map((option) => {
              const triggerItemIds = items.byTrigger[option.quizTaskFieldOptionId]?.triggerItemIds || [];
              if (triggerItemIds.length) {
                return triggerItemIds.map((itemId) => <TaskItemField key={itemId} items={items} taskItemId={itemId} />);
              }
              return null;
            })}
          </Box>
        </Box>
      );

    case 'VOLUNTEER_SignatureTaskFieldType':
      return (
        <Box>
          <TaskItemFieldHeading optional={taskItem.optional} heading={taskItem.field.name} />
          <TaskItemFieldType
            dynamic={false}
            type='Signature'
            icon={<Create style={{ color: '#0000008A', fontSize: 20 }} />}
          />
        </Box>
      );
    default:
      return null;
  }
}
