import { TabletButton } from '@campfire/tablet-button';
import { Box, Theme, Typography } from '@material-ui/core';
import { BorderColorOutlined, FilterList } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { SearchField } from '../../../../common/inputs/SearchField';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '4px',
      textTransform: 'none',
      marginRight: '10px',
      padding: '7px 12px',
      minWidth: '45px',
    },
    headerButton: {
      color: theme.color.grey.neutral400,
      borderColor: '#dcdcdc',
    },
    toggledButton: {
      color: '#FCFCFC !important',
      backgroundColor: '#646464 !important',
    },
    containedButton: {
      backgroundColor: theme.color.secondary.main900,
      color: '#ffffff',
      boxSizing: 'border-box',
      borderRadius: '4px',
      textTransform: 'none',
      minWidth: '40px',
    },
    buttonText: {
      marginLeft: '4px',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '19px',
      padding: '0px 7px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })
);

interface ReportManagementScreenToolsProps {
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  setSearchInput: (value: string) => void;
}

const CreateButton = ({ text, onClick }: { text: string; onClick: () => void }) => {
  const { theme, isMobile, isMd } = useCampfireTheme();

  return (
    <TabletButton
      variant='contained'
      style={{
        backgroundColor: theme.color.secondary.main900,
        color: '#ffffff',
        boxSizing: 'border-box',
        borderRadius: '4px',
        textTransform: 'none',
        padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
        minWidth: '40px',
      }}
      onClick={onClick}
    >
      <BorderColorOutlined style={{ marginTop: '1px', fontSize: '16px' }} />
      <Typography
        style={{
          marginLeft: '8px',
          fontWeight: 600,
          fontSize: '13px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          display: isMobile || isMd ? 'none' : 'inherit',
        }}
      >
        {text}
      </Typography>
    </TabletButton>
  );
};

export const ReportManagementToolbar = (props: ReportManagementScreenToolsProps) => {
  const { showFilters, setShowFilters, setSearchInput } = props;
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    createReportType: BooleanParam,
    createReportField: BooleanParam,
  });

  const { isMobile, isMd } = useCampfireTheme();
  const classes = useStyles();

  return (
    <Box flex={1} justifyContent='flex-end' display='flex' alignItems='center'>
      <Box style={{ marginRight: '10px' }}>
        <SearchField placeholder='Search' onChange={(e) => setSearchInput(e.target.value as string)} />
      </Box>
      <ToggleButton
        classes={{
          root: classNames(classes.button, classes.headerButton),
          selected: classes.toggledButton,
        }}
        value={showFilters}
        selected={showFilters}
        onClick={() => setShowFilters(!showFilters)}
      >
        <FilterList style={{ fontSize: '18px' }} />
        <Typography
          className={classes.buttonText}
          style={{
            display: isMobile || isMd ? 'none' : 'inherit',
          }}
        >
          Filters
        </Typography>
      </ToggleButton>
      {query.tab === 'report-fields' ? (
        <CreateButton text='Create Report Field' onClick={() => setQuery({ ...query, createReportField: true })} />
      ) : (
        <CreateButton text='Create Report Type' onClick={() => setQuery({ ...query, createReportType: true })} />
      )}
    </Box>
  );
};
