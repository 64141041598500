import { ReportFieldActionMenuOptionType } from './report-management-types';

export const REPORT_MANAGEMENT_TAB_OPTIONS = ['report-types', 'report-fields'];

export const CREATE_BUTTON_OPTIONS = {
  'report-types': {
    buttonText: 'Create Report Type',
    queryText: 'createReportType',
  },
  'report-fields': {
    buttonText: 'Create Report Type',
    queryText: 'createReportType',
  },
};

export const REPORT_TYPE_STATUS_OPTIONS = ['all', 'active', 'archived'];

export const INITIAL_REPORT_TYPE_FILTERS = {
  status: 'active',
};

export const REPORT_FIELD_STATUS_OPTIONS = ['all', 'active', 'archived'];

export const INITIAL_REPORT_FIELD_FILTERS = {
  status: 'active',
};

export const DEFAULT_RETURN_SLUG = '/management/report-management';

export const REPORT_FIELD_ACTION_MENU_OPTIONS: Array<ReportFieldActionMenuOptionType> = [
  'edit',
  'merge',
  'archive',
  'reactivate',
];
