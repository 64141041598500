import React from 'react';
import { DateTime } from 'luxon';
import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { unpackToDate } from '@campfire/hot-date';
import { EmptyMessageCard } from './EmptyMessageCard';
import { ActivityReportGetActivityDate_vm_activity as ActivityType } from './__generated__/ActivityReportGetActivityDate';

interface Props {
  activity?: ActivityType | null;
  selectedDate: string;
  lastScheduledDates: any[];
  setSelectedDate: (x: string) => void;
}

export const InvalidDateSelectedCard = (props: Props) => {
  const { activity, selectedDate, lastScheduledDates, setSelectedDate } = props;
  return (
    <EmptyMessageCard
      heading='Please choose a past activity date to start a report'
      subHeading={`${activity ? activity.name : 'This activity'} didn't run on ${DateTime.fromJSDate(
        new Date(selectedDate)
      ).toFormat('dd/MM/y')}.
              ${
                lastScheduledDates.length
                  ? 'Pick from one of the below dates instead, or use the Activity Date picker again.'
                  : ''
              }`}
    >
      {lastScheduledDates.length ? (
        <Box paddingY={1} paddingX={8}>
          <List>
            {lastScheduledDates.map((lastScheduledDate) => {
              return (
                <TitularTooltip
                  key={uuidv4()}
                  placement='right'
                  title={`${unpackToDate(lastScheduledDate).toFormat('cccc, d LLLL')}`}
                >
                  <ListItem button onClick={() => setSelectedDate(lastScheduledDate)} key={lastScheduledDate}>
                    <ListItemText
                      primary={`${unpackToDate(lastScheduledDate).toFormat('dd/MM/y')}`}
                      secondary={`${unpackToDate(lastScheduledDate).toFormat('cccc, d LLLL')}`}
                    />
                  </ListItem>
                </TitularTooltip>
              );
            })}
          </List>
        </Box>
      ) : null}
    </EmptyMessageCard>
  );
};
