import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, IconButton, Input, Menu, MenuItem, Select, Tab, Tabs, Theme } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import PrivilegeRules from '../../../global/auth/PrivilegeRules';
import { useUser } from '../../../global/auth/useUser';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

type ActivitiesConsoleTabValue = '/management/activities/map' | '/management/activities/timeline';

const useTabClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      minWidth: 72,
      textTransform: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  })
);

const DESKTOP_TAB_ITEMS: Array<{ value: ActivitiesConsoleTabValue; label: string }> = [
  { label: 'Map', value: '/management/activities/map' },
  { label: 'Timeline', value: '/management/activities/timeline' },
];

const MOBILE_TAB_ITEMS: Array<{ value: ActivitiesConsoleTabValue; label: string }> = [
  { label: 'List', value: '/management/activities/map' },
  { label: 'Timeline', value: '/management/activities/timeline' },
];

interface ActivitiesConsoleTabsProps {
  managementPath: string;
}

const ActivitiesConsoleTabs = (props: ActivitiesConsoleTabsProps) => {
  const { managementPath } = props;
  const [selectedProgramId] = useQueryParam('programId', StringParam);
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const { isMobile } = useCampfireTheme();
  const tabs = isMobile ? MOBILE_TAB_ITEMS : DESKTOP_TAB_ITEMS;
  const [selectedTabIndex, setSelectedTabIndex] = useState(tabs.findIndex((x) => x.value === routeMatch?.path));
  const selectedTab = tabs[selectedTabIndex] ?? tabs[0];

  const handleChange = (_unusedEvent: React.ChangeEvent<{}>, newTabIndex: number) => {
    setSelectedTabIndex(newTabIndex);
  };

  useEffect(() => {
    setSelectedTabIndex(tabs.findIndex((x) => x.value === routeMatch?.path));
  }, [tabs, routeMatch?.path]);

  useEffect(() => {
    if (history.location.pathname !== selectedTab.value) {
      history.push(selectedTab.value);
    }
  }, [selectedTab]);

  const {
    user: { userIdentityService },
  } = useUser();
  const canManageReportTypes = userIdentityService.hasVmGlobalRule(PrivilegeRules.manageReportTypes);
  const canCreateActivity =
    userIdentityService.hasVmGlobalRule(PrivilegeRules.manageActivities) || userIdentityService.isProgramManager;

  function getCreateActivityLink() {
    const programParam = selectedProgramId ? `programId=${selectedProgramId}` : '';
    const returnParam = `return=${encodeURIComponent(routeMatch?.path ?? '')}`;

    return `/management/activities/create?${returnParam}&${programParam}`;
  }

  const classes = useTabClasses();

  return selectedTab ? (
    <Box
      borderBottom='solid #dadada 1px'
      paddingLeft={3}
      paddingRight={2}
      display='flex'
      justifyContent='space-between'
      alignContent='center'
      alignItems='center'
    >
      <Tabs
        indicatorColor='primary'
        value={selectedTabIndex}
        onChange={handleChange}
        aria-label='activity console tabs'
      >
        {tabs.map((x) => (
          <Tab
            data-track={`actCnlNavigationTab-${x.label}`}
            disableRipple
            disableTouchRipple
            classes={classes}
            key={x.value}
            label={x.label}
            onClick={() => {
              if (x.value === selectedTab.value) {
                history.push(selectedTab.value);
              }
            }}
          />
        ))}
      </Tabs>
      <Box display='inline-block'>
        {canManageReportTypes ? (
          <Link
            to={{
              pathname: `${managementPath}/report-types`,
            }}
            style={{ textDecoration: 'none', margin: 4 }}
          >
            <TabletButton color='primary' variant='text'>
              {'Manage Report Types'}
            </TabletButton>
          </Link>
        ) : null}

        {canCreateActivity ? (
          <Link data-track='actCnlCreateActivityButton' to={getCreateActivityLink()} style={{ textDecoration: 'none' }}>
            <TabletButton color='primary' variant='contained'>
              {'Create Activity'}
            </TabletButton>
          </Link>
        ) : null}
      </Box>
    </Box>
  ) : null;
};

const useSelectInputStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: 13,
    },
    input: {
      backgroundColor: '#ffffff',
      position: 'relative',
      fontSize: 16,
      padding: 4,
      paddingRight: 26,
      paddingLeft: 12,
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
);

const useLinkStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
    },
  })
);

const ActivitiesConsoleTabsMobile = (props: ActivitiesConsoleTabsProps) => {
  const { managementPath } = props;

  const routeMatch = useRouteMatch();
  const history = useHistory();
  const { isMobile } = useCampfireTheme();
  const tabs = isMobile ? MOBILE_TAB_ITEMS : DESKTOP_TAB_ITEMS;
  const [value, setValue] = React.useState(routeMatch ? routeMatch.path : tabs[0].value);

  const {
    user: { userIdentityService },
  } = useUser();
  const { theme } = useCampfireTheme();
  const canManageReportTypes = userIdentityService.hasVmGlobalRule(PrivilegeRules.manageReportTypes);
  const canCreateActivity =
    userIdentityService.hasVmGlobalRule(PrivilegeRules.manageActivities) || userIdentityService.isProgramManager;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const inputStyles = useSelectInputStyles();
  const linkStyles = useLinkStyles();

  return routeMatch ? (
    <Grid
      item
      container
      alignItems='center'
      justify='space-between'
      style={{ borderBottom: `1px solid ${theme.palette.grey[300]}`, paddingLeft: 8, paddingRight: 2 }}
    >
      <Grid item>
        <Select
          MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
          value={value}
          onChange={(event) => {
            const selectedValue = event.target.value as string;
            history.push(selectedValue);
            setValue(selectedValue);
          }}
          input={<Input classes={inputStyles} />}
          renderValue={(selected) => {
            const selectedValue = selected as string;
            return tabs.find((x) => x.value === selectedValue)?.label;
          }}
        >
          {tabs.map((tabItem) => (
            <MenuItem key={tabItem.value} value={tabItem.value}>
              {tabItem.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item>
        <IconButton
          aria-controls='activity-console-action-menu'
          aria-haspopup='true'
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreVert />
        </IconButton>

        <Menu
          id='activity-console-action-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {canCreateActivity ? (
            <Link
              to={`${managementPath}/create?return=${encodeURIComponent(routeMatch?.path ?? '')}`}
              style={{ textDecoration: 'none' }}
              className={linkStyles.link}
            >
              <MenuItem onClick={handleClose}>{'Create Activity'}</MenuItem>
            </Link>
          ) : null}
          {canManageReportTypes ? (
            <Link
              to={{
                pathname: `${managementPath}/report-types`,
              }}
              style={{ textDecoration: 'none' }}
              className={linkStyles.link}
            >
              <MenuItem onClick={handleClose}>{'Manage Report Types'}</MenuItem>
            </Link>
          ) : null}
        </Menu>
      </Grid>
    </Grid>
  ) : null;
};

const MANAGEMENT_PATH = '/management/activities';
const ActivitiesConsoleTabsWrapper = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { isXs } = useCampfireTheme();

  return (
    <Box display={'flex'} flexDirection={'column'} flex={1} overflow={'hidden'}>
      {isXs ? (
        <ActivitiesConsoleTabsMobile managementPath={MANAGEMENT_PATH} />
      ) : (
        <ActivitiesConsoleTabs managementPath={MANAGEMENT_PATH} />
      )}
      {children}
    </Box>
  );
};

export { ActivitiesConsoleTabsWrapper };
