import { TabletButton } from '@campfire/tablet-button';
import { Grid, Checkbox, FormControlLabel, Box, IconButton, Typography, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import { uniq } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { FullscreenDialog } from '../../../../common/dialogs/FullscreenDialog';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';
import { useUser } from '../../../../global/auth/useUser';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import {
  VolunteerDatabaseTasks,
  VolunteerDatabaseTasks_taskItems_VOLUNTEER_TaskItemFieldType_field as TaskFieldType,
} from './__generated__/VolunteerDatabaseTasks';

const SecondCheckbox = withStyles((theme) => ({
  root: {
    color: theme.color.grey.neutral200,
  },
  colorSecondary: {
    '&.Mui-checked': {
      color: '#7570EA',
    },
  },
}))(Checkbox);

const MainCheckbox = withStyles(() => ({
  colorSecondary: {
    '&.Mui-checked': {
      color: '#7570EA',
    },
  },
}))(Checkbox);

interface UserPreferenceSpec {
  profileId: string;
  databaseTaskFields: string[];
}

interface KeyValueType {
  [key: string]: boolean;
}

interface VolunteerDatabaseSettingDialogProps {
  volunteerProfileTasks?: VolunteerDatabaseTasks[];
  databaseTaskFields?: string[];
  open: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

export const VolunteerDatabaseSettingDialog = (props: VolunteerDatabaseSettingDialogProps) => {
  const { open, handleClose, onSuccess, volunteerProfileTasks = [], databaseTaskFields = [] } = props;

  // const { sendInvitationPostRequest, isLoading, response } = useSendInvitationFetch();
  const { isMobile, theme } = useCampfireTheme();
  const [tasksSelected, setTasksSelected] = useState<KeyValueType>({});
  const [collapseTasks, setCollapseTasks] = useState<KeyValueType>({});
  const [taskFieldsSelected, setTaskFieldsSelected] = useState<string[]>([]);
  const updateUserPreferenceFetch = useEndpointFetch<UserPreferenceSpec>('/vm/volunteer/preferences/update');
  const {
    user: { profileId },
  } = useUser();
  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    const collapseTasksObj: { [key: string]: boolean } = {};
    if (volunteerProfileTasks) {
      volunteerProfileTasks.forEach((t) => {
        collapseTasksObj[t.taskId] = false;
      });
      setCollapseTasks(collapseTasksObj);
    }
  }, [volunteerProfileTasks]);

  useEffect(() => {
    setTaskFieldsSelected(databaseTaskFields);
    volunteerProfileTasks.forEach((task) => {
      const numberSelectedOfTask = task.taskItems.filter(
        (item) =>
          item.__typename === 'VOLUNTEER_TaskItemFieldType' && databaseTaskFields.includes(item.field.taskFieldId)
      );
      setTasksSelected((tasksSelectedState) => ({
        ...tasksSelectedState,
        [task.taskId]: numberSelectedOfTask.length !== 0,
      }));
    });
  }, [databaseTaskFields, volunteerProfileTasks]);

  const onClose = () => {
    handleClose();
    setTasksSelected({});
    setCollapseTasks({});
    setTaskFieldsSelected([]);
  };

  const getTaskFielType = (taskField: TaskFieldType) => {
    switch (taskField.__typename) {
      case 'VOLUNTEER_AddressTaskFieldType':
        return 'Address';
      case 'VOLUNTEER_AttachmentTaskFieldType':
        return 'Attachments';
      case 'VOLUNTEER_CheckboxTaskFieldType':
        return 'Checkboxes';
      case 'VOLUNTEER_DateTaskFieldType':
        return 'Date';
      case 'VOLUNTEER_DropdownTaskFieldType':
        return 'Dropdown';
      case 'VOLUNTEER_EmailTaskFieldType':
        return 'Email';
      case 'VOLUNTEER_LongTextTaskFieldType':
        return 'Long Answer Text';
      case 'VOLUNTEER_MultipleChoiceTaskFieldType':
        return 'Multiple Choice';
      case 'VOLUNTEER_NumberTaskFieldType':
        return 'Number';
      case 'VOLUNTEER_PhoneTaskFieldType':
        return 'Phone Number';
      case 'VOLUNTEER_ShortTextTaskFieldType':
        return 'Short Answer Text';
      default:
        return '';
    }
  };

  const handleCollapseClick = (task: VolunteerDatabaseTasks) => {
    setCollapseTasks({ ...collapseTasks, [task.taskId]: !collapseTasks[task.taskId] });
  };

  const onSelectTask = (task: VolunteerDatabaseTasks) => {
    if (tasksSelected[task.taskId]) {
      const taskFieldItemIds = task.taskItems
        .filter((taskItem) => taskItem.__typename === 'VOLUNTEER_TaskItemFieldType')
        .map((item) => (item.__typename === 'VOLUNTEER_TaskItemFieldType' ? item.field.taskFieldId : ''));
      const newTaskFieldsSelected = taskFieldsSelected.filter((item) => !taskFieldItemIds.includes(item));
      setTaskFieldsSelected(uniq([...newTaskFieldsSelected]));
    } else {
      const taskFieldItemIds = task.taskItems
        .filter((taskItem) => taskItem.__typename === 'VOLUNTEER_TaskItemFieldType')
        .map((item) => (item.__typename === 'VOLUNTEER_TaskItemFieldType' ? item.field.taskFieldId : ''));
      setTaskFieldsSelected(uniq([...taskFieldsSelected, ...taskFieldItemIds]));
    }
    setTasksSelected({ ...tasksSelected, [task.taskId]: !tasksSelected[task.taskId] });
  };

  const onSelectTaskField = (task: VolunteerDatabaseTasks, taskField: TaskFieldType) => {
    const isExist = taskFieldsSelected.includes(taskField.taskFieldId);
    let newTaskFieldsSelected = [] as string[];
    if (isExist) {
      newTaskFieldsSelected = taskFieldsSelected.filter((item) => item !== taskField.taskFieldId);
    } else {
      newTaskFieldsSelected = [...taskFieldsSelected, taskField.taskFieldId];
    }
    setTaskFieldsSelected(uniq([...newTaskFieldsSelected]));
    const numberSelectedOfTask = task.taskItems.filter(
      (item) =>
        item.__typename === 'VOLUNTEER_TaskItemFieldType' && newTaskFieldsSelected.includes(item.field.taskFieldId)
    );
    setTasksSelected({ ...tasksSelected, [task.taskId]: numberSelectedOfTask.length !== 0 });
  };

  const handleSubmit = () => {
    updateUserPreferenceFetch
      .run({
        profileId,
        databaseTaskFields: taskFieldsSelected,
      })
      .then((response) => {
        if (response && response.ok) {
          onSuccess();
          onClose();
          setSnackbar({
            message: 'Updated settings ',
            variant: 'success',
            open: true,
          });
          return;
        }
        setSnackbar({
          message: 'Unable to settings ',
          variant: 'error',
          open: true,
        });
      })
      .catch(() => {
        setSnackbar({
          message: 'Unable to settings',
          variant: 'error',
          open: true,
        });
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <FullscreenDialog
      open={open}
      close={onClose}
      title={'Customise Database Columns'}
      subtitle={`You can choose to see responses from certain tasks by default on the volunteer database.
        Select task responses below and click 'Save'.`}
      fullScreen={isMobile}
      maxWidth='md'
      scroll='paper'
      fullWidth
      hasBorderActions={false}
      dialogActions={() => (
        <Grid container item justify='flex-end' direction='row'>
          <Grid item>
            <TabletButton variant='contained' onClick={onClose}>
              Cancel
            </TabletButton>
          </Grid>
          <Grid item onClick={handleSubmit}>
            <TabletButton color='primary' type='submit' variant='contained'>
              Done
            </TabletButton>
          </Grid>
        </Grid>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {volunteerProfileTasks.map((task) => (
            <Box style={{ padding: '16px', borderBottom: `1px solid ${theme.color.grey.border}` }}>
              <Box display='flex' style={{ flex: 1 }}>
                <FormControlLabel
                  control={
                    <MainCheckbox
                      key={uuidv4()}
                      checked={tasksSelected[task.taskId]}
                      onChange={() => onSelectTask(task)}
                    />
                  }
                  label={
                    <Box ml={'16px'}>
                      <Typography variant='h6' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {task.title}
                      </Typography>
                      <Typography variant='subtitle1' color='textSecondary'>
                        {task.description}
                      </Typography>
                    </Box>
                  }
                  style={{ flex: 1 }}
                />
                <Box>
                  <IconButton onClick={() => handleCollapseClick(task)}>
                    {collapseTasks[task.taskId] ? <ExpandMoreIcon /> : <ExpandLess />}
                  </IconButton>
                </Box>
              </Box>
              <Collapse in={collapseTasks[task.taskId]}>
                <Box ml='48px' mr='20px' pl='16px' style={{ borderLeft: `2px solid ${theme.color.grey.border}` }}>
                  {task.taskItems.map(
                    (taskItem) =>
                      taskItem.__typename === 'VOLUNTEER_TaskItemFieldType' && (
                        <Box display='flex' style={{ flex: 1 }}>
                          <FormControlLabel
                            control={
                              <SecondCheckbox
                                onChange={() => onSelectTaskField(task, taskItem.field)}
                                checked={taskFieldsSelected.includes(taskItem.field.taskFieldId)}
                              />
                            }
                            label={
                              <Typography variant='subtitle1' color='textSecondary'>
                                {taskItem.field.name}
                              </Typography>
                            }
                            style={{ flex: 1 }}
                          />
                          <Box>
                            <Typography variant='subtitle1' color='textSecondary'>
                              {getTaskFielType(taskItem.field)}
                            </Typography>
                          </Box>
                        </Box>
                      )
                  )}
                </Box>
              </Collapse>
            </Box>
          ))}
        </Grid>
      </Grid>
    </FullscreenDialog>
  );
};
