import React from 'react';
import { Route, withRouter } from 'react-router';
import { CampfireSwitch } from '../../../../content-blocks/common/CampfireSwitch';
import { VolDashboardMyElementsScreen } from './VolDashboardMyElementsScreen';

export const VolDashboardMyElementsScreenRouter = withRouter(({ match }) => {
  return (
    <CampfireSwitch>
      <Route exact path={match.path} render={() => <VolDashboardMyElementsScreen />} />
    </CampfireSwitch>
  );
});
