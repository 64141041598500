import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Field } from '@campfire/field';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Lock from '@material-ui/icons/Lock';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { Check, Close } from '@material-ui/icons';
import * as Yup from 'yup';
import { withRouter, Redirect } from 'react-router';
import { useQueryParam, StringParam } from 'use-query-params';
import { CampfireButton } from '../../../global/components';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { useCampfireFetch } from '../../../global/network/useCampfireFetch';
import { signInPath } from '../../../global/public-shell/sign-in-path';
import { useApiUrl } from '../../../global/config/useApiUrl';
import { TemplateSnackbar } from '../../../common/snackbars/TemplateSnackbar';

interface ResetPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Please enter a new password')
    .min(8, 'Password must be at least 8 characters')
    .max(64, 'Password cannot be over 64 characters long')
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>?`~\\/])[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>?`~\\/]*$/,
      'Invalid password format'
    ),
  confirmPassword: Yup.string()
    .required('Please enter a new password')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

export const ResetPasswordScreenComponent = () => {
  const apiUrl = useApiUrl();
  const [resetPasswordToken] = useQueryParam('token', StringParam);
  const [errorMessage, setErrorMessage] = useState<string>();
  const confirmPasswordEl = useRef<HTMLInputElement>(null);
  const focusPasswordField = () => {
    if (confirmPasswordEl === null || confirmPasswordEl.current === null) return;
    confirmPasswordEl.current.focus();
    confirmPasswordEl.current.select();
  };
  const { run, isLoading, response, status } = useCampfireFetch({
    defer: true,
    withAuth: false,
  });

  useEffect(() => {
    if (!response) return;
    if (response.ok) return;
    const errors = ['Your password was not reset'];
    if (response.data.errors.newPassword) {
      setErrorMessage('There is a problem with your new password, please try another password or try again later');
      return;
    }
    if (response.data.errors.token) {
      errors.push('Be sure you have copied the correct link from your email');
    }
    setErrorMessage(errors.join('. '));
  }, [response]);

  useEffect(() => {
    if (status === 'fulfilled') {
      setErrorMessage(undefined);
      return;
    }
    if (status === 'rejected') {
      setErrorMessage(response ? response.data.message : 'Something went wrong');
    }
  }, [status, isLoading]);

  const initialValues: ResetPasswordForm = { newPassword: '', confirmPassword: '' };
  const redirectUrl = signInPath;

  const handleSubmit = async (values: ResetPasswordForm) => {
    run({
      url: `${apiUrl}/im/user/password/reset`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        token: resetPasswordToken,
        newPassword: values.confirmPassword,
      },
    });
  };

  const { theme, isMobile } = useCampfireTheme();
  const useStyles = makeStyles({
    screen: {
      display: 'flex',
      height: '100vh',
      width: '100vw',
      background: theme.color.darkGradientHorizontal,
      flexGrow: 1,
      flexDirection: 'column',
    },
    logo: {
      fontSize: '30px',
      fontWeight: 300,
      color: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 101,
      marginBottom: 101,
    },
    headerContent: {
      paddingLeft: 48,
      paddingRight: 48,
    },
    heading: {
      textAlign: 'center',
    },
    subHeading: {
      width: '100%',
      textAlign: 'center',
    },
    actionLink: {
      textDecoration: 'none',
    },
    actionButton: {
      marginTop: 16,
      textTransform: 'none',
    },
  });
  const classes = useStyles();
  if (!resetPasswordToken) return <Redirect to={signInPath} />;

  return (
    <Box paddingTop={isMobile ? 4 : 8} paddingBottom={isMobile ? 0 : 8}>
      {response && response.ok && !errorMessage ? (
        <Grow in={response && response.ok && !errorMessage}>
          <div className={classes.headerContent}>
            <Typography variant='h6' component='h1' className={classes.heading}>
              All Done!
            </Typography>
            <Typography variant='body2' className={classes.subHeading}>
              Your password has been reset.
            </Typography>
            <Link to={redirectUrl} className={classes.actionLink}>
              <CampfireButton disabled={isLoading} type='submit' className={classes.actionButton}>
                Go to login
              </CampfireButton>
            </Link>
          </div>
        </Grow>
      ) : (
        <Grid justify='center' container style={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={10}>
            <Typography align='center' variant='h6'>
              {'Reset your password'}
            </Typography>
            <Typography align='center' variant='subtitle1'>
              {`Choose a new password and continue to sign in.`}
            </Typography>

            <Formik initialValues={initialValues} validationSchema={ResetPasswordSchema} onSubmit={handleSubmit}>
              {({ values, isValid, dirty }) => {
                const regex = new RegExp(
                  '^(?=.*[A-Za-z])(?=.*[0-9!@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>?`~\\/:\\\\])[A-Za-z0-9!@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>?`~\\/:\\\\]*$'
                );
                const isAtLeast8c = values.newPassword && values.newPassword.length >= 8;
                const isPassRegex = regex.test(values.newPassword);

                return (
                  <Form>
                    <Box marginTop={4}>
                      <Grid container justify='center'>
                        <Grid item xs={12}>
                          <Field
                            margin='dense'
                            Icon={LockOutlined}
                            hideErrors
                            type='password'
                            required
                            fullWidth
                            name='newPassword'
                            id='newPassword'
                            label='New Password'
                            iconFocusedColor={theme.palette.secondary.main}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            margin='dense'
                            Icon={Lock}
                            inputRef={confirmPasswordEl}
                            helper
                            type='password'
                            required
                            fullWidth
                            name='confirmPassword'
                            id='confirmPassword'
                            label='Confirm Password'
                            iconFocusedColor={theme.palette.secondary.main}
                          />
                        </Grid>

                        <Grid xs={12} item container justify='flex-start'>
                          <Box mt={2}>
                            <Box
                              style={{ color: isAtLeast8c ? '#48976C' : '#D93A00' }}
                              display='flex'
                              alignItems='center'
                            >
                              {isAtLeast8c ? <Check /> : <Close />}
                              <Typography
                                style={{ fontSize: '16px', fontWeight: 500, lineHeight: '22.4px', marginLeft: '8px' }}
                              >
                                At least 8 characters
                              </Typography>
                            </Box>
                            <Box
                              style={{ color: isPassRegex ? '#48976C' : '#D93A00' }}
                              display='flex'
                              alignItems='center'
                            >
                              {isPassRegex ? <Check /> : <Close />}
                              <Typography
                                style={{ fontSize: '16px', fontWeight: 500, lineHeight: '22.4px', marginLeft: '8px' }}
                              >
                                Contains letters and a number or symbol
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid xs={12} item container justify='flex-end'>
                          <Box marginTop={2}>
                            <CampfireButton
                              fullWidth={false}
                              disabled={isLoading || !isValid || !dirty}
                              type='submit'
                              onClick={() => focusPasswordField()}
                            >
                              Reset Password
                            </CampfireButton>
                          </Box>
                        </Grid>

                        <TemplateSnackbar variant='error' message={errorMessage} open={!!errorMessage} />
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export const ResetPasswordScreen = withRouter(ResetPasswordScreenComponent);
