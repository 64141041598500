/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */

import React from 'react';
import { Box, Button, CircularProgress, MenuItem, TextField, Typography } from '@material-ui/core';
import { useCampfireFetch } from '../../../../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../../../../global/config/useApiUrl';
import { useCommonStyles, useSelectStyles } from '../../commonStyles';

type ISalesforceList = {
  Id: string;
  Name: string;
};

type ISalesforceData = {
  allAccounts: Array<ISalesforceList>;
  selectedAccountId: string | null;
};

type ISalesforceStatus = {
  salesforceStatusId: string;
  acccountId: string;
};

export function SalesforceSetups() {
  const inputClasses = useSelectStyles();
  const commonClasses = useCommonStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const campfireFetch = useCampfireFetch<ISalesforceData>({ defer: true });
  const saveManageSalesforce = useCampfireFetch<ISalesforceStatus>({ defer: true });
  const apiUrl = useApiUrl();

  const [salesforceData, setSalesforceData] = React.useState<ISalesforceData | null>(null);
  const [selectedAccountId, setSelectedAccountId] = React.useState<string | null>(null);
  const [initialAccountId, setInitialAccountId] = React.useState<string | null>(null);

  React.useEffect(() => {
    setIsLoading(true);
    campfireFetch
      .run({
        url: `${apiUrl}/wm/integrations/salesforce/manage/get`,
        method: 'get',
      })
      .then((response) => {
        setIsLoading(false);
        setSalesforceData(response.data.data);
        setSelectedAccountId(response.data.data.selectedAccountId);
        setInitialAccountId(response.data.data.selectedAccountId);
      });
  }, []);

  const onSave = () => {
    if (!selectedAccountId) {
      return;
    }
    const selectedSalesforceAccount = salesforceData?.allAccounts.find((l) => l.Id === selectedAccountId);
    if (selectedSalesforceAccount) {
      saveManageSalesforce
        .run({
          url: `${apiUrl}/wm/integrations/salesforce/manage/save`,
          method: 'post',
          data: {
            accountId: selectedAccountId,
          },
        })
        .then(() => {
          setInitialAccountId(selectedAccountId);
        });
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography className={commonClasses.label}>List subscriber</Typography>
      <Box mt='0.5rem' width={300}>
        <TextField
          select
          fullWidth
          variant='outlined'
          value={selectedAccountId}
          onChange={(event) => setSelectedAccountId(event.target.value)}
          InputProps={{ classes: inputClasses }}
        >
          {(salesforceData?.allAccounts || []).map((list) => (
            <MenuItem value={list.Id}>{list.Name}</MenuItem>
          ))}
        </TextField>
      </Box>
      <Box mt='2rem'>
        <Button
          onClick={onSave}
          className={commonClasses.saveButton}
          variant='contained'
          disableElevation
          color='primary'
          disabled={initialAccountId === selectedAccountId}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
