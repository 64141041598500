import { v4 as uuidv4 } from 'uuid';
import { boolean as YupBool, object as YupObject, string as YupString } from 'yup';
import { ManageReportTypeSingleReportType } from './__generated__/ManageReportTypeSingleReportType';

interface BooleanFieldFormValue {
  __typename: 'VOLUNTEER_BooleanFieldType';
  fieldId?: string;
  name: string;
  description: string;
  isHero: boolean;
}
interface CheckboxFieldFormValue {
  __typename: 'VOLUNTEER_CheckboxFieldType';
  fieldId?: string;
  name: string;
  description: string;
  isHero: boolean;
}
interface DropdownFieldFormValue {
  __typename: 'VOLUNTEER_DropdownFieldType';
  fieldId?: string;
  name: string;
  description: string;
  dropdownOptions: {
    dropdownFieldOptionId?: string;
    name: string;
    order: number;
  }[];
  isAllowMultiple: boolean;
  isHero: boolean;
}
interface NumericFieldFormValue {
  __typename: 'VOLUNTEER_NumericFieldType';
  fieldId?: string;
  name: string;
  description: string;
  isHero: boolean;
}
interface RatingFieldFormValue {
  __typename: 'VOLUNTEER_RatingFieldType';
  fieldId?: string;
  name: string;
  description: string;
  isHero: boolean;
}
interface TextFieldFormValue {
  __typename: 'VOLUNTEER_TextFieldType';
  fieldId?: string;
  name: string;
  description: string;
  isHero: boolean;
}
interface TimeFieldFormValue {
  __typename: 'VOLUNTEER_TimeFieldType';
  fieldId?: string;
  name: string;
  description: string;
  isHero: boolean;
}

interface AttachmentFieldFormValue {
  __typename: 'VOLUNTEER_AttachmentFieldType';
  fieldId?: string;
  name: string;
  description: string;
  isHero: boolean;
}

type FieldFormValue =
  | BooleanFieldFormValue
  | CheckboxFieldFormValue
  | DropdownFieldFormValue
  | NumericFieldFormValue
  | RatingFieldFormValue
  | TextFieldFormValue
  | TimeFieldFormValue
  | AttachmentFieldFormValue;

interface ReportTypeItemHeadingValue {
  __typename: 'VOLUNTEER_ReportTypeHeadingType';
  listId: string;
  reportTypeItemId?: string;
  order: number;
  heading: string;
}
export interface ReportTypeItemFieldValue {
  __typename: 'VOLUNTEER_ReportTypeFieldType';
  listId: string;
  reportTypeItemId?: string;
  order: number;
  field: FieldFormValue;
  optional: boolean;
}
export type ReportTypeItemValue = ReportTypeItemHeadingValue | ReportTypeItemFieldValue;

export interface ReportTypeFormValues {
  reportTypeId?: string;
  reportTypeName: string;
  reportTypeDescription?: string;
  items: ReportTypeItemValue[];
  active: boolean;
}

export const ValidationSchema = YupObject().shape({
  reportTypeId: YupString(),
  reportTypeName: YupString().required('Please provide a name'),
  active: YupBool().required(),
});

export const parseInitialValues = (
  selectedReportType?: ManageReportTypeSingleReportType
): ReportTypeFormValues => ({
  reportTypeId: selectedReportType?.reportTypeId || '',
  reportTypeName: selectedReportType?.name || '',
  reportTypeDescription: selectedReportType?.description || '',
  items:
    selectedReportType?.items
      .map((item) => {
        return {
          ...item,
          listId: uuidv4(),
        };
      })
      .sort((a, b) => a.order - b.order) || [],
  active: !selectedReportType?.dateDeactivated ?? false,
});
