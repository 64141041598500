import { SuggestionField } from '@campfire/suggestion-field';
import { TextFieldProps } from '@material-ui/core/TextField';
import { getIn, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useRegionCodes } from './use-region-codes/use-region-codes';

interface Props {
  name: string;
  helper?: boolean;
}

const AutoLocationField = (props: Props & TextFieldProps) => {
  const { name, helper, ...rest } = props;

  const { values, errors, setFieldValue } = useFormikContext();

  const [map, setMap] = useState();
  const [missingSelection, setMissingSelection] = useState(false);
  const [autoCompleteSessionToken, setAutoCompleteSessionToken] = useState();
  const [suggestions, setSuggestions] = useState<{ description: string; id: string }[]>();
  const { allRegions } = useRegionCodes();

  const [autoCompleteService, setAutoCompleteService] = useState(
    (window as any).google?.maps?.places ? new (window as any).google.maps.places.AutocompleteService() : undefined
  );

  const [placesService, setPlacesService] = useState<any>();

  const value = getIn(values, name);
  const error = getIn(errors, name);

  useEffect(() => {
    if ((window as any).google?.maps?.places) {
      setAutoCompleteService(new (window as any).google.maps.places.AutocompleteService());
      setAutoCompleteSessionToken(new (window as any).google.maps.places.AutocompleteSessionToken());
    }
    if ((window as any).google?.maps.Map) {
      setMap(new (window as any).google.maps.Map(document.createElement('div')));
    }
  }, [(window as any).google]);

  useEffect(() => {
    // For some cooked reason the only way to get place details with a placeId is using PlacesService which requires a map
    if (map && (window as any)?.google?.maps?.places)
      setPlacesService(new (window as any).google.maps.places.PlacesService(map));
  }, [map, (window as any).google]);

  const getPredictions = (query: string) => {
    if (autoCompleteService)
      autoCompleteService.getPlacePredictions(
        {
          input: query,
          componentRestrictions: { country: allRegions },
          sessionToken: autoCompleteSessionToken,
        },
        (predictions: any) => {
          if (predictions?.length)
            setSuggestions(predictions.map((pred: any) => ({ description: pred.description, id: pred.place_id })));
        }
      );
  };

  return (
    <SuggestionField
      name={name}
      label='Location'
      items={suggestions?.length ? suggestions : []}
      helper={helper}
      value={value}
      errorText={missingSelection ? 'Required' : (error as string)}
      onChange={(event: any) => {
        if (event.target.value) getPredictions(event.target.value);
        setFieldValue(name, '', true);
        setMissingSelection(true);
      }}
      placeholder='eg: 123 Example Rd'
      onSelect={(selection: any) => {
        if (!placesService) return;
        placesService.getDetails(
          {
            placeId: selection.id,
            fields: ['formatted_address', 'geometry'],
            sessionToken: autoCompleteSessionToken,
          },
          (placeResult: any) => {
            setFieldValue(
              name,
              {
                description: selection.description,
                placesId: selection.id,
                formatted: placeResult.formatted_address,
                latitude: placeResult.geometry.location.lat(),
                longitude: placeResult.geometry.location.lng(),
              },
              true
            );
            setMissingSelection(false);
            // AutoCompleteSessionToken expires after single getDetails request so need to set a new token
            if ((window as any).google?.maps?.places)
              setAutoCompleteSessionToken(new (window as any).google.maps.places.AutocompleteSessionToken());
          }
        );
      }}
      {...rest}
    />
  );
};

export { AutoLocationField };
