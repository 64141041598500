import { gql } from '@apollo/client';

export const ACTIVITY_REPORT_GET_ALL_ACTIVITIES = gql`
  query ActivityReportGetAllActivities {
    vm {
      programs {
        programId
        activities(includeInactiveActivities: true) {
          activityId
        }
      }
    }
  }
`;

export const ACTIVITY_REPORT_GET_ACTIVITIES = gql`
  query ActivityReportGetActivities($volunteerId: String!, $from: Date, $until: Date, $activityIds: [String!]) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        activityEnrolments {
            activity {
              ...ReportActivityFragment
            }
            dateRemoved
            dateEnrolled
        }
        rosterings {
          publishedRosteringId
          publishedRoster {
            activityDate
            activity {
              ...ReportActivityFragment
              startTime
              sessions {
                sessionId
              }
              activityReports {
                activityDate
                activity {
                    activityId
                }
                sessionReports {
                  sessionReportId
                }
                cancelledSessions {
                  sessionId
                }
              }
              cancelledActivities {
                activityDate
              }
            }
          }
        }
      }
      activities(includeInactiveActivities: true, activityIds: $activityIds) {
        ...ReportActivityFragment
        startTime
        endTime
        sessions {
          sessionId
        }
        cancelledActivities {
            activityDate
        }
        occurrencesBetween(from: $from, until: $until)
        historicalOccurrences(from: $from, until: $until)
        activityReports {
          activityDate
          sessionReports {
            sessionReportId
          }
          cancelledSessions {
            sessionId
          }
        }
      }
    }
  }
  fragment ReportActivityFragment on VOLUNTEER_ActivityType {
    activityId
    name
    isSuspended
    endDate
    closedActivity {
      endDate
      activity {
        activityId
      }
    }
    program {
      programId
      name
      dateDeleted
      dateSuspended
    }
  }
`

export const ACTIVITY_REPORT_GET_ACTIVITY_DATE = gql`
  query ActivityReportGetActivityDate($activityId: String!, $activityDate: Date) {
    vm {
      activity(activityId: $activityId) {
        activityId
        name
        cancelledActivities {
          cancelledActivityId
          activityDate
        }
        rosterTemplates {
          occursOn(date: $activityDate)
          rosterDate
        }
        occursOn(date: $activityDate)
        lastX(x: 4)
      }
    }
  }
`;
