/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */

import React from 'react';
import { Box, Button, CircularProgress, Link, MenuItem, TextField, Typography } from '@material-ui/core';
import { useCampfireFetch } from '../../../../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../../../../global/config/useApiUrl';
import { useCommonStyles, useSelectStyles } from '../../commonStyles';

type IMailchimpList = {
  id: string;
  web_id: string;
  name: string;
};

type IMailchimpData = {
  allLists: {
    constraints: { may_create: boolean };
    lists: Array<IMailchimpList>;
  };
  selectedListId: string | null;
};

type IMailchimAccountStatus = {
  mailchimpAccountStatusId: string;
  web_id: string;
  listId: string;
};

export function MailchimpSetups() {
  const inputClasses = useSelectStyles();
  const commonClasses = useCommonStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const campfireFetch = useCampfireFetch<IMailchimpData>({ defer: true });
  const saveManageMailchimp = useCampfireFetch<IMailchimAccountStatus>({ defer: true });
  const apiUrl = useApiUrl();

  const [mailchimpData, setMailchimData] = React.useState<IMailchimpData | null>(null);
  const [selectedListId, setSelectedListId] = React.useState<string | null>(null);
  const [initialListId, setInitialListId] = React.useState<string | null>(null);

  React.useEffect(() => {
    setIsLoading(true);
    campfireFetch
      .run({
        url: `${apiUrl}/wm/integrations/mailchimp/manage/get`,
        method: 'get',
      })
      .then((response) => {
        setIsLoading(false);
        setMailchimData(response.data.data);
        setSelectedListId(response.data.data.selectedListId);
        setInitialListId(response.data.data.selectedListId);
      });
  }, []);

  const onSave = () => {
    if (!selectedListId) {
      return;
    }
    const selectedMailchimpList = mailchimpData?.allLists.lists.find((l) => l.id === selectedListId);
    if (selectedMailchimpList) {
      saveManageMailchimp
        .run({
          url: `${apiUrl}/wm/integrations/mailchimp/manage/save`,
          method: 'post',
          data: {
            webId: selectedMailchimpList.web_id,
            listId: selectedMailchimpList.id,
          },
        })
        .then(() => {
          setInitialListId(selectedMailchimpList.id);
        });
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography className={commonClasses.label}>List subscriber</Typography>
      <Box mt='0.5rem' width={300}>
        <TextField
          select
          fullWidth
          variant='outlined'
          value={selectedListId}
          onChange={(event) => setSelectedListId(event.target.value)}
          InputProps={{ classes: inputClasses }}
        >
          {(mailchimpData?.allLists?.lists || []).map((list) => (
            <MenuItem value={list.id}>{list.name}</MenuItem>
          ))}
        </TextField>
      </Box>
      {mailchimpData?.allLists?.constraints['may_create'] && (
        <Link href='http://admin.mailchimp.com/audience/'>Create other list</Link>
      )}
      <Box mt='2rem'>
        <Button
          onClick={onSave}
          className={commonClasses.saveButton}
          variant='contained'
          disableElevation
          color='primary'
          disabled={initialListId === selectedListId}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
