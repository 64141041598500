import React, { memo, useMemo, useState } from 'react';
import { Box, Checkbox, Divider, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { ArrowDropDown, Check } from '@material-ui/icons';
import { BorderSelectInput, useFilterStyles } from '../../../../../common/filter-fields/FilterClasses';
import { DEFAULT_PROGRAM_FILTER } from '../../../../../common/filter-fields/filters';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { GetProgramActivities_vm_programs as ProgramType } from '../../../../../global/programs-sell/__generated__/GetProgramActivities';

export { ProgramType };

type ProgramFilterSelectFieldV2Props = {
  value: string;
  onChange: (newValue: string) => void;
  programs: ProgramType[];
};

const ProgramFilterSelectFieldV2 = memo(({ value, onChange, programs }: ProgramFilterSelectFieldV2Props) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);

  const selectedProgram = programs.find((program) => program.programId === value);

  const suspendedPrograms = useMemo(() => programs.filter((x) => x.dateDeleted || x.dateSuspended), [programs]);
  const activePrograms = useMemo(() => programs.filter((x) => !x.dateDeleted && !x.dateSuspended), [programs]);

  return (
    <Grid item className={classes.subheaderGridItemNew}>
      <Select
        data-track={`actCnlMapFilters-ProgramFilterSelectField`}
        open={popoverOpen}
        fullWidth
        input={
          <BorderSelectInput
            status={selectedProgram ? 'active' : undefined}
            onClick={() => setPopoverOpen(!popoverOpen)}
            style={{ cursor: 'pointer' }}
          />
        }
        IconComponent={() => (
          <Box display='flex' alignItems='center' onClick={() => setPopoverOpen(true)} style={{ cursor: 'pointer' }}>
            <ArrowDropDown style={{ fill: selectedProgram ? theme.palette.primary.main : 'rgba(0,0,0, 0.54)' }} />
          </Box>
        )}
        value={selectedProgram ? selectedProgram.programId : 'all'}
        renderValue={() => selectedProgram?.name ?? DEFAULT_PROGRAM_FILTER}
        onChange={(e) => onChange(e.target.value as string)}
        style={{ fontWeight: 'normal' }}
        margin='dense'
      >
        <MenuItem value='all' className={classes.menuItem}>
          <Grid container alignItems='center' justify='space-between'>
            <Grid item>
              <Typography
                variant='body2'
                style={!selectedProgram?.programId ? { fontWeight: 600 } : { fontWeight: 'normal' }}
              >
                {DEFAULT_PROGRAM_FILTER}
              </Typography>
            </Grid>
            <Grid item>
              <Checkbox
                checked={!selectedProgram?.programId}
                className={classes.checkBoxSelectField}
                checkedIcon={<Check color='primary' width={12} />}
                icon={<span className={classes.icon} />}
                disableRipple
              />
            </Grid>
          </Grid>
        </MenuItem>

        <Divider />

        {activePrograms.length > 0 ? (
          <Box display='flex' flex='1 1 auto' paddingLeft={2} paddingTop={1} paddingBottom={1}>
            <Typography variant='caption' style={{ fontWeight: 'bold', cursor: 'default' }} color='textSecondary'>
              Active programs:
            </Typography>
          </Box>
        ) : null}

        {activePrograms.map((program) => (
          <MenuItem key={program.programId} value={program.programId} className={classes.menuItem}>
            <Box
              display='flex'
              flex='1 0 auto'
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
            >
              <Typography
                variant='body2'
                style={
                  selectedProgram?.programId === program.programId ? { fontWeight: 600 } : { fontWeight: 'normal' }
                }
              >
                {program.name}
              </Typography>

              <Checkbox
                edge='end'
                checked={selectedProgram?.programId === program.programId}
                className={classes.checkBoxSelectField}
                checkedIcon={<Check color='primary' width={12} />}
                icon={<span className={classes.icon} />}
                disableRipple
              />
            </Box>
          </MenuItem>
        ))}

        {suspendedPrograms.length > 0 ? (
          <>
            <Divider />
            <Box display='flex' flex='1 1 auto' paddingLeft={2} paddingTop={1} paddingBottom={1}>
              <Typography variant='caption' style={{ fontWeight: 'bold', cursor: 'default' }} color='textSecondary'>
                Suspended programs:
              </Typography>
            </Box>
          </>
        ) : null}

        {suspendedPrograms.map((program) => (
          <MenuItem key={program.programId} value={program.programId} className={classes.menuItem}>
            <Box
              display='flex'
              flex='1 0 auto'
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
            >
              <Box display='flex' flex='1 0 auto' alignContent='center' alignItems='center'>
                <Typography
                  variant='body2'
                  style={
                    selectedProgram?.programId === program.programId ? { fontWeight: 600 } : { fontWeight: 'normal' }
                  }
                >
                  {program.name}
                </Typography>
              </Box>
              <Checkbox
                edge='end'
                checked={selectedProgram?.programId === program.programId}
                className={classes.checkBoxSelectField}
                checkedIcon={<Check color='primary' width={12} />}
                icon={<span className={classes.icon} />}
                disableRipple
              />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
});

export { ProgramFilterSelectFieldV2 };
