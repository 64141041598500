import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  MobileStepper,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { DialogProps } from '@material-ui/core/Dialog';
import { fade } from '@material-ui/core/styles';
import { Check, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { ReactNode, useState } from 'react';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

const WIDTH = 1280;
const DESKTOP_BUTTON_MARGIN = 32;

type ButtonProps = {
  activeStep: number;
  maxSteps?: number;
  handleClick: () => void;
  handleClose?: () => void;
};

const DesktopNextButton = (props: ButtonProps) => {
  const { activeStep, maxSteps = 1, handleClick, handleClose } = props;
  const { theme } = useCampfireTheme();
  return activeStep < maxSteps - 1 ? (
    <Tooltip title='Next'>
      <IconButton
        id='tutorialDialogNextButton'
        aria-label='next'
        style={{ marginLeft: DESKTOP_BUTTON_MARGIN, backgroundColor: 'white' }}
        onClick={handleClick}
      >
        <KeyboardArrowRight fontSize='large' />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title='Got It!'>
      <IconButton
        id='tutorialDialogGotItButton'
        aria-label='complete-tutorial'
        style={{ marginLeft: DESKTOP_BUTTON_MARGIN, backgroundColor: theme.status.green.medium }}
        onClick={handleClose}
      >
        <Check fontSize='large' style={{ color: 'white' }} />
      </IconButton>
    </Tooltip>
  );
};

const DesktopBackButton = (props: ButtonProps) => {
  const { activeStep, handleClick } = props;
  return (
    <Tooltip title='Previous'>
      <div>
        <IconButton
          id='tutorialDialogBackButton'
          aria-label='previous'
          style={{ marginRight: DESKTOP_BUTTON_MARGIN, backgroundColor: 'white', opacity: activeStep > 0 ? 1 : 0 }}
          onClick={handleClick}
          disabled={activeStep === 0}
        >
          <KeyboardArrowLeft fontSize='large' />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export type TutorialDialogStep = {
  label: string;
  description: string | ReactNode;
  image: any;
  theme?: {
    background: string;
    color: string;
  };
};

export type TutorialDialogProps = {
  steps?: Array<TutorialDialogStep>;
  enableBackdropClick?: boolean;
} & DialogProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: { opacity: 0 },
    backgroundTransition: {
      transition: theme.transitions.create('background'),
    },
  })
);

const TutorialDialog = (props: TutorialDialogProps) => {
  const { steps, open, onClose, enableBackdropClick } = props;

  if (!steps) return null;

  const { isMobile, theme } = useCampfireTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const classes = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    if (onClose) {
      onClose({}, 'escapeKeyDown');
    }
  };

  // This is a patch for tutorial dialogs who's number of steps might change before the transition has had a change to exit
  const activeStepData = activeStep < maxSteps - 1 ? steps[activeStep] : steps[maxSteps - 1];

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      BackdropProps={{ style: { backgroundColor: fade('#000000', 0.75) } }}
      PaperProps={{
        style: { margin: 16, background: 'transparent', boxShadow: 'none', width: '100%', maxWidth: WIDTH },
      }}
      onClose={enableBackdropClick ? onClose : undefined}
      TransitionProps={{
        onExited: () => {
          setActiveStep(0);
        },
      }}
    >
      <Grid
        container
        direction='row'
        alignContent='center'
        alignItems='center'
        justify='space-evenly'
        wrap='nowrap'
        style={{ width: '100%' }}
      >
        {!isMobile ? (
          <Grid item>
            <DesktopBackButton activeStep={activeStep} handleClick={handleBack} />
          </Grid>
        ) : null}
        <Grid item xs={isMobile ? 12 : 8}>
          <Paper
            className={classes.backgroundTransition}
            style={{ background: activeStepData.theme ? activeStepData.theme.background : '#fff' }}
          >
            <DialogContent style={{ padding: 0 }}>
              <Grid container style={{ paddingLeft: 32 }}>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={classes.backgroundTransition}
                  style={{
                    paddingLeft: 64,
                    backgroundImage: `url(${activeStepData.image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPositionX: 'center',
                    backgroundPositionY: isMobile ? 'center' : '90px',
                    height: isMobile ? 250 : 350,
                  }}
                ></Grid>
                <Grid item xs={12} sm={7} container alignItems='center' alignContent='center'>
                  <Box
                    paddingLeft={isMobile ? 3 : 2}
                    paddingRight={isMobile ? 3 : 8}
                    marginBottom={isMobile ? 2 : 0}
                    minHeight={96}
                    textAlign={isMobile ? 'left' : 'left'}
                  >
                    <Typography
                      variant='h6'
                      component='h1'
                      style={{
                        marginBottom: 16,
                        fontWeight: 800,
                        color: activeStepData.theme ? activeStepData.theme.color : 'inherit',
                      }}
                    >
                      {activeStepData.label}
                    </Typography>
                    <Divider
                      style={{ backgroundColor: theme.color.grey.pale, maxWidth: 64, height: 3, marginBottom: 16 }}
                    />
                    <Typography
                      style={{
                        whiteSpace: 'pre-wrap',
                        color: activeStepData.theme ? activeStepData.theme.color : 'inherit',
                      }}
                    >
                      {activeStepData.description}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>

            {isMobile ? (
              <MobileStepper
                variant={maxSteps > 1 ? 'text' : 'dots'}
                classes={{ dots: classes.hidden }}
                style={{
                  background: 'white',
                }}
                steps={maxSteps}
                position='bottom'
                activeStep={activeStep}
                backButton={
                  <Button
                    id='tutorialDialogBackButton'
                    style={{ opacity: activeStep > 0 ? 1 : 0 }}
                    size='small'
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {'Back'}
                  </Button>
                }
                nextButton={
                  activeStep < maxSteps - 1 ? (
                    <Button
                      id='tutorialDialogNextButton'
                      style={{ opacity: activeStep < maxSteps - 1 ? 1 : 0 }}
                      size='small'
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      {'Next'}
                    </Button>
                  ) : (
                    <Button id='tutorialDialogGotItButton' size='small' onClick={handleClose}>
                      {'Got It!'}
                    </Button>
                  )
                }
              />
            ) : (
              <MobileStepper
                variant='dots'
                classes={maxSteps === 1 ? { dots: classes.hidden } : undefined}
                steps={maxSteps}
                position='static'
                activeStep={activeStep}
                style={{ justifyContent: 'center', background: 'transparent', paddingTop: 32, paddingBottom: 32 }}
                nextButton={<></>}
                backButton={<></>}
              />
            )}
          </Paper>
        </Grid>

        {!isMobile ? (
          <Grid item>
            <DesktopNextButton
              activeStep={activeStep}
              maxSteps={maxSteps}
              handleClick={handleNext}
              handleClose={handleClose}
            />
          </Grid>
        ) : null}
      </Grid>
    </Dialog>
  );
};

export { TutorialDialog };
