import { Grid } from '@material-ui/core';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { ProgramManagementSingleProgramBasicInfo } from '../__generated__/ProgramManagementSingleProgramBasicInfo';
import { ProgramBasicInfoSectionForm } from './program-form-sections/ProgramBasicInfoSectionForm';
import {
  ProgramActivitiesSection,
  ProgramActivitiesSectionLocked,
} from './program-form-sections/ProgramActivitiesSection';
import { ProgramManagersSection, ProgramManagersSectionLocked } from './program-form-sections/ProgramManagersSection';
import {
  ProgramVolunteersSection,
  ProgramVolunteersSectionLocked,
} from './program-form-sections/ProgramVolunteersSection';
import {
  ProgramImageUploadSection,
  ProgramImageUploadSectionLocked,
} from './program-form-sections/ProgramImageUploadSection';
import { ProgramApplicationSection } from './program-form-sections/ProgramApplicationSection';

const EditProgramForm = ({
  programBasicInfo,
}: {
  programBasicInfo: ProgramManagementSingleProgramBasicInfo | undefined;
}) => {
  const [selectedProgramId] = useQueryParam('pid', StringParam);

  if (selectedProgramId === 'new' || !programBasicInfo)
    return (
      <Grid container>
        <ProgramBasicInfoSectionForm programBasicInfo={programBasicInfo} />
        <ProgramImageUploadSectionLocked />
        <ProgramActivitiesSectionLocked />
        <ProgramManagersSectionLocked />
        <ProgramVolunteersSectionLocked />
      </Grid>
    );
  return (
    <Grid container>
      <ProgramBasicInfoSectionForm programBasicInfo={programBasicInfo} />
      <ProgramImageUploadSection />
      <ProgramActivitiesSection programBasicInfo={programBasicInfo} />
      <ProgramManagersSection />
      <ProgramVolunteersSection />
      {programBasicInfo.isRestrictedProgram && <ProgramApplicationSection />}
    </Grid>
  );
};

export { EditProgramForm };
