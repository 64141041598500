import React from 'react';
import { Heading } from '../Heading';
import { Container } from '../Container';
import { Content } from '../Content';
import { TemplateHeader } from './TemplateHeader';
import { TemplateContent } from './TemplateContent';

export function AdminConsoleCommunicationTemplate() {
  return (
    <Container>
      <Heading title='Templates' />
      <Content>
        <TemplateHeader />
        <TemplateContent />
      </Content>
    </Container>
  );
}
