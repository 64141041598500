import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Theme,
  Box,
  TableFooter,
  TableRow,
  TablePagination,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/styles';
import { encodeDate, unpackToDate, unpackToDateTime } from '@campfire/hot-date';
import { StringParam, useQueryParam } from 'use-query-params';
import { useHistory, useLocation } from 'react-router';
import { DateTime } from 'luxon';
import { cloneDeep, get, set } from 'lodash';
import { RosterContext } from '../RosterContext';
import { RosterRow } from './RosterRow';
import { RosterDate } from './RosterDate';
import { BulkRosterRoleTasksDialog } from '../BulkRosterRoleTasksDialog';
import {
  GetVolunteerInfo_vm_volunteer as VolunteerType,
  GetVolunteerInfo_vm_volunteer_activityRoles_tasks as ActivityRoleTasks,
} from '../__generated__/GetVolunteerInfo';
import { RosterNotesDialog } from '../../common/RosterNotesDialog';
import { PAGINATION_BOUNDARY, useBulkSharedPagination } from '../../usePagination';
import { RosterRowLoading } from './RosterRowLoading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      border: 0,
      textAlign: 'center',
    },
    weekDay: {
      color: theme.color.grey.neutralBrand900,
      fontSize: '12px',
      fontWeight: 400,
    },
    monthDay: {
      color: theme.color.grey.neutralBrand900,
      fontSize: '16px',
      fontWeight: 900,
    },
    session: {
      color: theme.color.grey.neutral200,
      fontSize: '14px',
    },
    time: {
      color: theme.color.grey.neutral200,
      fontSize: '12px',
    },
    header: {
      backgroundColor: 'white',
    },
  })
);

export function RosterTable() {
  const {
    activityDates,
    activity,
    rosters,
    volunteers,
    onSessionClick,
    onUnavailableClick,
    onCancelRoster,
    onRestoreRoster,
    isLoading,
    rosterNotes,
    setRosterNotes,
  } = React.useContext(RosterContext);
  const classes = useStyles();
  const [volunteerSelected, setVolunteerSelected] = useState<VolunteerType | undefined>();
  const [openRosterRoleTasksDialog, setOpenRosterRoleTasksDialog] = useState(false);
  const [roleTasks, setRoleTasks] = React.useState<ActivityRoleTasks[]>([]);

  const rosterTemplateDates =
    activity?.rosterTemplates.map((rosterTemplate) => unpackToDateTime(rosterTemplate.rosterDate).toISODate()) || [];

  const isRosterTemplatedByDate = React.useCallback(
    (date: string) => {
      return rosterTemplateDates.includes(date);
    },
    [rosterTemplateDates]
  );

  const history = useHistory();
  const location = useLocation();

  const [view] = useQueryParam('view', StringParam);
  const viewParam = view === 'bulk' ? '&view=bulk' : '';

  const goToEditTemplate = (activityDate: string) => {
    const rosterTemplateId = activity?.rosterTemplates.find(
      (rosterTemplate) => unpackToDateTime(rosterTemplate.rosterDate).toISODate() === activityDate
    )?.rosterTemplateId;
    history.push(`${location.pathname}/edit-roster-template?rosterTemplateId=${rosterTemplateId}${viewParam}`);
  };

  const handleClickRoleTasks = (activityRoleId: string, volunteer: VolunteerType) => {
    const activityRole = volunteer.activityRoles.find((role) => role.activityRoleId === activityRoleId);
    if (!activityRole) return;
    setVolunteerSelected(volunteer);
    setRoleTasks(activityRole.tasks);
    setOpenRosterRoleTasksDialog(true);
  };

  const handleRoleTaskDialogClose = () => {
    setVolunteerSelected(undefined);
    setRoleTasks([]);
    setOpenRosterRoleTasksDialog(false);
  };

  const [rosterNotesDate, setShowRosterNotesDate] = React.useState<DateTime | undefined>();

  const hasRosterNotes = (rosterDate: DateTime) => {
    return Boolean(activity?.activityId && Boolean(get(rosterNotes, [activity.activityId, encodeDate(rosterDate)])));
  };
  const onSaveRosterNotes = (newRosterNotes: string) => {
    if (!activity?.activityId || !rosterNotesDate) {
      return;
    }
    const cloneRosterNotes = cloneDeep(rosterNotes) || {};
    setRosterNotes(set(cloneRosterNotes, [activity.activityId, encodeDate(rosterNotesDate)], newRosterNotes));
    setShowRosterNotesDate(undefined);
  };

  const { page, perPage, setPage, setPerPage } = useBulkSharedPagination();

  const handleChangePage = (_unusedEvent: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayingRosters =
    volunteers.length < PAGINATION_BOUNDARY ? volunteers : volunteers.slice(page * perPage, page * perPage + perPage);

  if (!activity || !rosters) {
    return null;
  }

  const { startTime, endTime } = activity;

  return (
    <Box>
      <Table>
        <TableHead className={classes.header}>
          <TableCell className={classes.cell} />
          {activityDates.map((activityDate) => {
            const date = unpackToDate(activityDate);
            return (
              <TableCell className={classes.cell} style={{ verticalAlign: 'top' }}>
                <RosterDate
                  date={date}
                  startTime={startTime}
                  endTime={endTime}
                  numberOfSessions={activity?.sessions?.length || 0}
                  isRosterCancelled={Boolean(rosters[activityDate]?.cancelledActivityId)}
                  onCancelRoster={() => onCancelRoster(activityDate)}
                  onRestoreRoster={() => onRestoreRoster(activityDate, rosters[activityDate]?.cancelledActivityId)}
                  isRosterTemplated={isRosterTemplatedByDate(activityDate)}
                  onEdit={() => goToEditTemplate(activityDate)}
                  onShowRosterNotes={setShowRosterNotesDate}
                  hasRosterNotes={hasRosterNotes(date)}
                  roster={rosters[activityDate]}
                />
              </TableCell>
            );
          })}
        </TableHead>
        <TableBody>
          {!isLoading
            ? displayingRosters.map((volunteer) => (
                <RosterRow
                  volunteer={volunteer}
                  volunteerRosters={rosters}
                  activityDates={activityDates}
                  sessions={activity.sessions}
                  onClick={onSessionClick}
                  onCancelClick={onUnavailableClick}
                  isLoading={isLoading}
                  handleClickRoleTasks={handleClickRoleTasks}
                />
              ))
            : [1, 2, 3].map((key) => <RosterRowLoading key={key} activityDates={activityDates} />)}
        </TableBody>
        {volunteers.length > PAGINATION_BOUNDARY && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={volunteers.length}
                rowsPerPageOptions={[PAGINATION_BOUNDARY]}
                page={page}
                rowsPerPage={perPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
      {rosterNotesDate && (
        <RosterNotesDialog
          open
          onCancel={() => setShowRosterNotesDate(undefined)}
          rosterNotes={get(rosterNotes, [activity.activityId, encodeDate(rosterNotesDate)])}
          onSave={onSaveRosterNotes}
        />
      )}
      {volunteerSelected && openRosterRoleTasksDialog && (
        <BulkRosterRoleTasksDialog
          open
          roleTasks={roleTasks}
          profile={volunteerSelected.profile}
          onClose={handleRoleTaskDialogClose}
        />
      )}
    </Box>
  );
}
