import { QueryResult } from '@apollo/react-common';
import { LazyQueryHookOptions, LazyQueryResult, QueryLazyOptions, useLazyQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { usePageVisibility } from '../page-visibility/usePageVisibility';

export type PollingLazyQueryArgs<TData, TVariables> = {
  pollInterval?: number;
  options?: Omit<LazyQueryHookOptions<TData, TVariables>, 'pollInterval'>;
  ignoreVisibility?: boolean;
} & LazyQueryHookOptions<TData, TVariables>;

type RealQueryResult<TData, TVariables> = Omit<QueryResult<TData, TVariables>, 'refetch'> &
  Partial<Pick<QueryResult<TData, TVariables>, 'refetch'>>;

export interface CampfireQueryResult<TData, TVariables> extends RealQueryResult<TData, TVariables> {
  setPollInterval: Dispatch<SetStateAction<number | undefined>>;
  refetchLoading: boolean;
  pollLoading: boolean;
}

export const useCampfireLazyQuery = <TData, TVariables>(
  query: DocumentNode,
  { pollInterval, options, ignoreVisibility = false, ...rest }: PollingLazyQueryArgs<TData, TVariables> = {
    ignoreVisibility: false,
  }
): [
  (options?: QueryLazyOptions<TVariables> | undefined) => Promise<LazyQueryResult<TData, TVariables>>,
  CampfireQueryResult<TData, TVariables>
] => {
  const [currentPollInterval, setPollInterval] = useState(pollInterval);
  const { visibility } = usePageVisibility();

  const [load, result] = useLazyQuery<TData, TVariables>(query, {
    ...options,
    pollInterval: currentPollInterval,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    ...rest,
  });

  useEffect(() => {
    if (!result.called || ignoreVisibility || !currentPollInterval) return;
    if (visibility) {
      result.startPolling(currentPollInterval);
      return;
    }

    result.stopPolling();
  }, [visibility, currentPollInterval]);

  return [
    load,
    { ...result, setPollInterval, refetchLoading: result.networkStatus === 4, pollLoading: result.networkStatus === 6 },
  ];
};
