import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { FileUploadGallery, UploadedFile } from '@campfire/file-upload-gallery';
import { Box, Grid, Typography } from '@material-ui/core';
import { ShortTextRounded } from '@material-ui/icons';
import { convertFromRaw, EditorState } from 'draft-js';
import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { TaskItemsDeserialize } from '../../../screens/admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import {
  AddressTaskFormikField,
  AttachmentTaskFormikField,
  CheckboxTaskFormikField,
  DateTaskFormikField,
  DropdownTaskFormikField,
  EmailTaskFormikField,
  LongTextTaskFormikField,
  MultipleChoiceTaskFormikField,
  NumberTaskFormikField,
  PhoneTaskFormikField,
  ShortTextTaskFormikField,
  QuizTaskFormikField,
  TaskFormikMultipleChoiceType,
  TaskFormikQuizType,
  SignatureTaskFormikField,
} from '../../inputs/task-fields/TaskFormikFields';
import { TaskFormValuesType } from './model/task-form-init';
import { TaskFormItem } from './model/__generated__/TaskFormItem';

function getFirstField(taskItems: Array<TaskFormItem>): string {
  for (let i = 0; i < taskItems.length; i++) {
    if (taskItems[i].__typename === 'VOLUNTEER_TaskItemFieldType') {
      return taskItems[i].taskItemId;
    }
  }
  return '';
}

function getFieldBreakItemIds(taskItems: Array<TaskFormItem>): { [key: string]: boolean } {
  const itemIds: { [key: string]: boolean } = {};
  for (let i = taskItems.length - 1; i >= 0; i--) {
    if (i === taskItems.length - 1) {
      itemIds[taskItems[i].taskItemId] = taskItems[i].__typename !== 'VOLUNTEER_TaskItemFieldType';
      continue;
    }
    if (
      taskItems[i].__typename === 'VOLUNTEER_TaskItemFieldType' &&
      taskItems[i + 1].__typename !== 'VOLUNTEER_TaskItemFieldType'
    ) {
      itemIds[taskItems[i + 1].taskItemId] = true;
    }
  }
  return itemIds;
}

interface TaskItemFormFieldProps {
  taskItem: TaskFormItem;
  fieldBreakItemIds: { [key: string]: boolean };
  firstFieldItemId?: string;
  taskItems: TaskItemsDeserialize;
  values: TaskFormValuesType;
}

const TaskItemFormField = ({
  taskItem,
  fieldBreakItemIds,
  firstFieldItemId,
  values,
  taskItems,
}: TaskItemFormFieldProps) => {
  if (taskItem.__typename === 'VOLUNTEER_TaskItemHeadingType') {
    return (
      <TaskItemHeader
        key={taskItem.taskItemId}
        isFieldBreak={fieldBreakItemIds[taskItem.taskItemId]}
        content={taskItem.heading}
      />
    );
  }

  if (taskItem.__typename === 'VOLUNTEER_TaskItemContentType') {
    const attachments: UploadedFile[] = taskItem.attachments.map((attachment) => {
      return { url: attachment.url, fileId: attachment.attachmentId, name: attachment.name };
    });

    return (
      <TaskItemContents
        key={taskItem.taskItemId}
        isFieldBreak={fieldBreakItemIds[taskItem.taskItemId]}
        draftJsRawContent={taskItem.content}
        attachments={attachments}
      />
    );
  }

  if (taskItem.__typename === 'VOLUNTEER_TaskItemMediaType') {
    return (
      <TaskItemMedia
        key={taskItem.taskItemId}
        isFieldBreak={fieldBreakItemIds[taskItem.taskItemId]}
        media={taskItem.media}
      />
    );
  }

  if (taskItem.__typename === 'VOLUNTEER_TaskItemFieldType') {
    const { field } = taskItem;
    const fieldType = field.__typename;
    const ariaDescribedBy = `${field.taskFieldId}-description`;

    if (fieldType === 'VOLUNTEER_ShortTextTaskFieldType') {
      return (
        <ShortTextTaskFormikField
          key={taskItem.taskItemId}
          name={field.taskFieldId}
          title={field.name}
          description={field.description}
          ariaId={field.taskFieldId}
          ariaDescribedBy={ariaDescribedBy}
          required={!taskItem.optional}
          autoComplete='no'
          outlinedInputProps={taskItem.taskItemId === firstFieldItemId ? { autoFocus: true } : undefined}
        />
      );
    }

    if (fieldType === 'VOLUNTEER_LongTextTaskFieldType') {
      return (
        <LongTextTaskFormikField
          key={taskItem.taskItemId}
          name={field.taskFieldId}
          title={field.name}
          description={field.description}
          ariaId={field.taskFieldId}
          ariaDescribedBy={ariaDescribedBy}
          required={!taskItem.optional}
          autoComplete='no'
          outlinedInputProps={taskItem.taskItemId === firstFieldItemId ? { autoFocus: true } : undefined}
        />
      );
    }
    if (fieldType === 'VOLUNTEER_NumberTaskFieldType') {
      return (
        <NumberTaskFormikField
          key={taskItem.taskItemId}
          name={field.taskFieldId}
          title={field.name}
          description={field.description}
          ariaId={field.taskFieldId}
          ariaDescribedBy={ariaDescribedBy}
          required={!taskItem.optional}
          outlinedInputProps={taskItem.taskItemId === firstFieldItemId ? { autoFocus: true } : undefined}
        />
      );
    }
    if (fieldType === 'VOLUNTEER_MultipleChoiceTaskFieldType') {
      const selectedOptionId = (values[field.taskFieldId] as TaskFormikMultipleChoiceType)?.multipleChoiceOptionId;

      return (
        <>
          <MultipleChoiceTaskFormikField
            key={taskItem.taskItemId}
            name={field.taskFieldId}
            title={field.name}
            description={field.description}
            ariaId={field.taskFieldId}
            ariaDescribedBy={ariaDescribedBy}
            values={values}
            options={
              field.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldType'
                ? field.multipleChoiceOptions
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map((option) => ({
                      id: option.multipleChoiceFieldOptionId,
                      label: option.name,
                      allowText: option.allowText,
                    }))
                : []
            }
            required={!taskItem.optional}
          />
          {(taskItems.byTrigger[selectedOptionId]?.triggerItemIds || []).map((taskItemId) => (
            <TaskItemFormField
              key={taskItemId}
              taskItem={taskItems.byId[taskItemId]}
              taskItems={taskItems}
              values={values}
              fieldBreakItemIds={fieldBreakItemIds}
              firstFieldItemId={firstFieldItemId}
            />
          ))}
        </>
      );
    }
    if (fieldType === 'VOLUNTEER_CheckboxTaskFieldType') {
      const checkedOptionIds = (values[field.taskFieldId] || []) as string[];
      return (
        <>
          <CheckboxTaskFormikField
            key={taskItem.taskItemId}
            name={field.taskFieldId}
            title={field.name}
            description={field.description}
            ariaId={field.taskFieldId}
            ariaDescribedBy={ariaDescribedBy}
            options={
              field.__typename === 'VOLUNTEER_CheckboxTaskFieldType'
                ? field.checkboxOptions.map((option) => ({
                    id: option.checkboxFieldOptionId,
                    label: option.name,
                    allowText: option.allowText,
                  }))
                : []
            }
            required={!taskItem.optional}
          />
          {checkedOptionIds.map((triggerId) =>
            (taskItems.byTrigger[triggerId].triggerItemIds || []).map((taskItemId) => (
              <TaskItemFormField
                key={taskItemId}
                taskItem={taskItems.byId[taskItemId]}
                taskItems={taskItems}
                values={values}
                fieldBreakItemIds={fieldBreakItemIds}
                firstFieldItemId={firstFieldItemId}
              />
            ))
          )}
        </>
      );
    }
    if (fieldType === 'VOLUNTEER_QuizTaskFieldType') {
      const selectedOptionId = (values[field.taskFieldId] as TaskFormikQuizType)?.quizTaskFieldOptionId;

      return (
        <>
          <QuizTaskFormikField
            key={taskItem.taskItemId}
            name={field.taskFieldId}
            title={field.name}
            description={field.description}
            ariaId={field.taskFieldId}
            ariaDescribedBy={ariaDescribedBy}
            correctOptionId={
              field.__typename === 'VOLUNTEER_QuizTaskFieldType'
                ? field.quizTaskFieldCorrect?.quizTaskFieldOption.quizTaskFieldOptionId
                : ''
            }
            options={
              field.__typename === 'VOLUNTEER_QuizTaskFieldType'
                ? field.quizTaskFieldOptions
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map((option) => ({
                      id: option.quizTaskFieldOptionId,
                      label: option.name,
                      allowText: option.allowText,
                    }))
                : []
            }
            required={!taskItem.optional}
          />
          {(taskItems.byTrigger[selectedOptionId]?.triggerItemIds || []).map((taskItemId) => (
            <TaskItemFormField
              key={taskItemId}
              taskItem={taskItems.byId[taskItemId]}
              taskItems={taskItems}
              values={values}
              fieldBreakItemIds={fieldBreakItemIds}
              firstFieldItemId={firstFieldItemId}
            />
          ))}
        </>
      );
    }
    if (fieldType === 'VOLUNTEER_DropdownTaskFieldType') {
      const selectedDropdownValue = (values[field.taskFieldId] || '') as string;
      return (
        <>
          <DropdownTaskFormikField
            key={taskItem.taskItemId}
            name={field.taskFieldId}
            title={field.name}
            description={field.description}
            ariaId={field.taskFieldId}
            ariaDescribedBy={ariaDescribedBy}
            options={
              field.__typename === 'VOLUNTEER_DropdownTaskFieldType'
                ? field.dropdownOptions
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map((option) => ({
                      id: option.dropdownFieldOptionId,
                      label: option.name,
                    }))
                : []
            }
            required={!taskItem.optional}
            selectProps={taskItem.taskItemId === firstFieldItemId ? { autoFocus: true } : undefined}
          />
          {(taskItems.byTrigger[selectedDropdownValue]?.triggerItemIds || []).map((taskItemId) => (
            <TaskItemFormField
              key={taskItemId}
              taskItem={taskItems.byId[taskItemId]}
              taskItems={taskItems}
              values={values}
              fieldBreakItemIds={fieldBreakItemIds}
              firstFieldItemId={firstFieldItemId}
            />
          ))}
        </>
      );
    }
    if (fieldType === 'VOLUNTEER_AttachmentTaskFieldType') {
      return (
        <AttachmentTaskFormikField
          key={taskItem.taskItemId}
          name={field.taskFieldId}
          title={field.name}
          description={field.description}
          ariaId={field.taskFieldId}
          ariaDescribedBy={ariaDescribedBy}
          allowMultiple={field.__typename === 'VOLUNTEER_AttachmentTaskFieldType' ? field.allowMultiple : false}
          required={!taskItem.optional}
        />
      );
    }
    if (fieldType === 'VOLUNTEER_DateTaskFieldType') {
      return (
        <DateTaskFormikField
          key={taskItem.taskItemId}
          name={field.taskFieldId}
          title={field.name}
          description={field.description}
          ariaId={field.taskFieldId}
          ariaDescribedBy={ariaDescribedBy}
          required={!taskItem.optional}
          keyboardDatePickerProps={taskItem.taskItemId === firstFieldItemId ? { autoFocus: true } : undefined}
        />
      );
    }
    if (fieldType === 'VOLUNTEER_AddressTaskFieldType') {
      return (
        <AddressTaskFormikField
          key={taskItem.taskItemId}
          name={field.taskFieldId}
          title={field.name}
          description={field.description}
          ariaId={field.taskFieldId}
          ariaDescribedBy={ariaDescribedBy}
          autoComplete='no'
          allowManual={field.__typename === 'VOLUNTEER_AddressTaskFieldType' ? field.allowManual : false}
          required={!taskItem.optional}
        />
      );
    }
    if (fieldType === 'VOLUNTEER_PhoneTaskFieldType') {
      return (
        <PhoneTaskFormikField
          key={taskItem.taskItemId}
          name={field.taskFieldId}
          title={field.name}
          description={field.description}
          ariaId={field.taskFieldId}
          ariaDescribedBy={ariaDescribedBy}
          required={!taskItem.optional}
          outlinedInputProps={taskItem.taskItemId === firstFieldItemId ? { autoFocus: true } : undefined}
        />
      );
    }
    if (fieldType === 'VOLUNTEER_EmailTaskFieldType') {
      return (
        <EmailTaskFormikField
          key={taskItem.taskItemId}
          name={field.taskFieldId}
          title={field.name}
          description={field.description}
          ariaId={field.taskFieldId}
          ariaDescribedBy={ariaDescribedBy}
          required={!taskItem.optional}
          outlinedInputProps={taskItem.taskItemId === firstFieldItemId ? { autoFocus: true } : undefined}
        />
      );
    }
    if (fieldType === 'VOLUNTEER_SignatureTaskFieldType') {
      return (
        <SignatureTaskFormikField
          key={taskItem.taskItemId}
          name={field.taskFieldId}
          title={field.name}
          description={field.description}
          ariaId={field.taskFieldId}
          ariaDescribedBy={ariaDescribedBy}
          required={!taskItem.optional}
        />
      );
    }
  }

  return null;
};

interface Props {
  taskItems: TaskItemsDeserialize;
  disableAutoFocus?: boolean;
  values: TaskFormValuesType;
}

const TaskFormFieldList = (props: Props) => {
  const { taskItems, disableAutoFocus, values } = props;
  const displayTaskItems = taskItems.allIds
    .filter((itemId) => !taskItems.byTriggerOption[itemId])
    .map((itemId) => taskItems.byId[itemId]);

  const [firstFieldItemId] = useState(disableAutoFocus ? undefined : getFirstField(displayTaskItems));
  const [fieldBreakItemIds] = useState(getFieldBreakItemIds(displayTaskItems));

  return (
    <Box display='flex' justifyContent='center' flex='1 1 auto'>
      <Box maxWidth={720} width={1}>
        {displayTaskItems.map((taskItem) => (
          <TaskItemFormField
            key={taskItem.taskItemId}
            taskItem={taskItem}
            firstFieldItemId={firstFieldItemId}
            fieldBreakItemIds={fieldBreakItemIds}
            values={values}
            taskItems={taskItems}
          />
        ))}
      </Box>
    </Box>
  );
};

interface TaskItemHeaderProps {
  isFieldBreak: boolean;
  content: string;
}

const TaskItemHeader = ({ isFieldBreak, content }: TaskItemHeaderProps) => (
  <Box display='flex' alignItems='center' alignContent='center' paddingLeft={1}>
    {isFieldBreak ? <ShortTextRounded style={{ color: '#9e9e9e', marginRight: 16 }} /> : null}
    <Typography variant='h6'>{content}</Typography>
  </Box>
);

interface TaskItemMediaProps {
  isFieldBreak: boolean;
  media: {
    __typename: string;
    name: string;
    description: string;
    videoId: string;
  };
}

const TaskItemMedia = ({ isFieldBreak, media }: TaskItemMediaProps) => {
  const videoUrl =
    media.__typename === 'VOLUNTEER_YoutubeMediaType'
      ? `https://www.youtube.com/watch?v=${media.videoId}`
      : `https://vimeo.com/${media.videoId}`;
  const { theme } = useCampfireTheme();
  return (
    <Box
      marginTop={2}
      marginBottom={3}
      paddingTop={3}
      paddingBottom={3}
      paddingLeft={4}
      paddingRight={2}
      minHeight={96}
      border={'1px solid #e9e9e9'}
      bgcolor='#ffffff'
      borderRadius={7}
    >
      <Box display='flex' alignItems='center' alignContent='center'>
        {isFieldBreak ? <ShortTextRounded style={{ color: '#9e9e9e', marginRight: 16 }} /> : null}
        <Typography style={{ fontWeight: 'bold', marginTop: '4px' }}>{media.name}</Typography>
      </Box>
      <Typography style={{ fontSize: '0.875rem', color: theme.color.grey.neutral400, marginTop: '4px' }}>
        {media.description}
      </Typography>
      <Box flex={1} mb={2}>
        <ReactPlayer url={videoUrl} width='100%' maxWidth='100%' controls />
      </Box>
    </Box>
  );
};

interface TaskItemContentsProps {
  isFieldBreak: boolean;
  draftJsRawContent: string;
  attachments: UploadedFile[];
}

const TaskItemContents = ({ isFieldBreak, draftJsRawContent, attachments }: TaskItemContentsProps) => (
  <Box display='flex' paddingLeft={1}>
    {isFieldBreak ? <ShortTextRounded style={{ color: '#9e9e9e', marginRight: 16, paddingTop: 8 }} /> : null}
    <Grid container direction='column' spacing={1}>
      <Grid item xs>
        <Typography style={{ paddingTop: 8, paddingBottom: 8 }} variant='body2' component='span' display='inline'>
          <CampfireRichEditor
            editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(draftJsRawContent)))}
          />
        </Typography>
      </Grid>
      <Grid item xs>
        {attachments.length ? <FileUploadGallery allowMultiple uploadedFiles={attachments} viewOnly /> : null}
      </Grid>
    </Grid>
  </Box>
);

function isTaskItemFieldBreak(current: TaskFormItem, prev?: TaskFormItem) {
  if (prev === undefined && current.__typename !== 'VOLUNTEER_TaskItemFieldType') return true;

  if (prev === undefined) return false;

  return prev.__typename === 'VOLUNTEER_TaskItemFieldType' && current.__typename !== 'VOLUNTEER_TaskItemFieldType';
}

export { TaskFormFieldList, TaskItemHeader, TaskItemContents, isTaskItemFieldBreak, TaskItemMedia };
