import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const SideBarProfileSectionSkeleton = () => {
  return (
    <Box padding='24px 0px' display='flex' alignItems='center'>
      <Skeleton variant='circle' width={110} height={110} />
      <Box marginLeft='24px'>
        <Skeleton variant='text' width={150} height={20} />
        <Skeleton variant='text' width={130} height={18} />
        <Skeleton variant='text' width={80} height={18} style={{ marginTop: '18px' }} />
      </Box>
    </Box>
  );
};
