import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import FindYourPerfectTeam from '../../../assets/tutorial-graphics/explore/find_your_perfect_team.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Program Management',
    description: (
      <>
        The Program Management page allows you to <strong>view and edit existing programs</strong>, as well as{' '}
        <strong>create new programs or activities</strong> within each program.
      </>
    ),
    image: FindYourPerfectTeam,
  },
  {
    label: 'Program Management',
    description: (
      <>
        This page should also be used to <strong>promote volunteers to program managers</strong> and{' '}
        <strong>add or remove volunteers</strong> from each program.
      </>
    ),
    image: FindYourPerfectTeam,
  },
];

const ProgramManagementTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { ProgramManagementTutorialDialog };
