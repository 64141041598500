import React from 'react';
import { List, RootRef } from '@material-ui/core';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  DraggableId,
} from '@campfire/draggable-list';
import { TaskListItemContext } from '../TaskListItemsContext';
import { TaskListItem } from './TaskListItem';

export function TaskItemList() {
  const { taskItems, setFieldValue, setDraggingId } = React.useContext(TaskListItemContext);
  const { allIds, byTriggerOption } = taskItems;

  const onDragEnd = (result: DropResult) => {
    setDraggingId('');
    const { droppableId: destinationItemId, index: destinationIndex = 0 } = result.destination || {};
    const { droppableId: sourceItemId, index: sourceIndex } = result.source || {};
    const { draggableId } = result;

    if (!sourceItemId || !destinationItemId) {
      return;
    }

    if (sourceItemId === 'root' && destinationItemId === 'root') {
      const itemIds = taskItems.allIds.slice();
      itemIds.splice(sourceIndex, 1);
      itemIds.splice(destinationIndex, 0, draggableId);
      setFieldValue('taskItems', {
        ...taskItems,
        allIds: itemIds,
      });
    }

    if (sourceItemId === 'root' && destinationItemId !== 'root') {
      const itemIds = (taskItems.byTrigger[destinationItemId]?.triggerItemIds ?? []).slice();
      itemIds.splice(destinationIndex, 0, draggableId);
      setFieldValue('taskItems', {
        ...taskItems,
        byTrigger: {
          ...taskItems.byTrigger,
          [destinationItemId]: {
            ...taskItems.byTrigger[destinationItemId],
            triggerItemIds: itemIds,
          },
        },
        byTriggerOption: {
          ...taskItems.byTriggerOption,
          [draggableId]: destinationItemId,
        },
      });
    }

    if (sourceItemId !== 'root' && destinationItemId !== 'root') {
      const sourceItemIds = (taskItems.byTrigger[sourceItemId]?.triggerItemIds ?? []).slice();
      sourceItemIds.splice(sourceIndex, 1);
      if (sourceItemId === destinationItemId) {
        sourceItemIds.splice(destinationIndex, 0, draggableId);
        setFieldValue('taskItems', {
          ...taskItems,
          byTrigger: {
            ...taskItems.byTrigger,
            [destinationItemId]: {
              ...taskItems.byTrigger[destinationItemId],
              triggerItemIds: sourceItemIds,
            },
          },
          byTriggerOption: {
            ...taskItems.byTriggerOption,
            [draggableId]: destinationItemId,
          },
        });
      } else {
        const destinationItemIds = (taskItems.byTrigger[destinationItemId]?.triggerItemIds ?? []).slice();
        destinationItemIds.splice(destinationIndex, 0, draggableId);
        setFieldValue('taskItems', {
          ...taskItems,
          byTrigger: {
            ...taskItems.byTrigger,
            [sourceItemId]: {
              ...taskItems.byTrigger[sourceItemId],
              triggerItemIds: sourceItemIds,
            },
            [destinationItemId]: {
              ...taskItems.byTrigger[destinationItemId],
              triggerItemIds: destinationItemIds,
            },
          },
          byTriggerOption: {
            ...taskItems.byTriggerOption,
            [draggableId]: destinationItemId,
          },
        });
      }
    }
  };
  const onBeforeCapture = ({ draggableId }: { draggableId: DraggableId }) => {
    setDraggingId(draggableId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
      <Droppable droppableId='root'>
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
          const style = { paddingBottom: '2rem' };
          const updatedStyle = snapshot.isDraggingOver ? { pointerEvents: 'none', ...style } : style;
          return (
            <RootRef rootRef={provided.innerRef}>
              <List style={updatedStyle as React.CSSProperties}>
                {allIds.map((taskItemId, index) => {
                  if (byTriggerOption[taskItemId]) {
                    return <div style={{ display: 'none' }} key={taskItemId}></div>;
                  }
                  return <TaskListItem taskItemId={taskItemId} index={index} key={taskItemId} />;
                })}
                {provided.placeholder}
              </List>
            </RootRef>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}
