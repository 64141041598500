import React, { useCallback, useEffect, useMemo } from 'react';
import { timeDurationInHours } from '@campfire/hot-date';
import { Box, ListSubheader } from '@material-ui/core';
import { DateTime, Interval } from 'luxon';
import { ArrayParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { arrayHead } from '../../../../common/functions/array-head';
import { getIsActivityEnded } from '../common-v2/is-activity-ended';
import { useSharedAttachmentState } from '../attachments/AttachmentDetail';
import { ActivityItem } from './ActivityItem';
import { ManagementActivitiesfilters } from './mangement-activities-filters/ManagementActivitiesfilters';
import { ActivitiesConsoleCardExploreActivity } from './__generated__/ActivitiesConsoleCardExploreActivity';
import { GetActivityManagementActivityTags_vm_activityTags as ActivityTagType } from '../activity-form-v2/__generated__/GetActivityManagementActivityTags';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { GetProgramActivities_vm_programs as ProgramType } from '../../../../global/programs-sell/__generated__/GetProgramActivities';
import { ExportCsvDialog } from '../../../../common/dialogs/ExportCsvDialog';
import { useSharedSidebarState } from './SideBarActivityDetail';

interface Props {
  activities?: Array<ActivitiesConsoleCardExploreActivity>;
  isLoading: boolean;
  showFilters: boolean;
  programs: ProgramType[];
  selectedSortValue: string;
  searchInput: string;
  activityTags: ActivityTagType[];
  isExportingCsv: boolean;
  handleCloseExportDialog: () => void;
  handleSaveCsv: (sortedActivities: ActivitiesConsoleCardExploreActivity[]) => void;
}

export const ActivitiesList = (props: Props) => {
  const { isMobile, isXs } = useCampfireTheme();
  const {
    activities,
    showFilters,
    programs,
    selectedSortValue,
    searchInput,
    activityTags,
    isExportingCsv,
    handleCloseExportDialog,
    handleSaveCsv,
  } = props;
  const [, setSelectedActivity] = useQueryParam('activityId', StringParam);
  const { closeAll } = useSharedSidebarState();

  const [filtersParam] = useQueryParams({
    programId: StringParam,
    duration: StringParam,
    type: StringParam,
    location: StringParam,
    status: StringParam,
    days: ArrayParam,
    startTime: StringParam,
    endTime: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    rosterType: StringParam,
    activityTagIds: ArrayParam,
    combination: StringParam,
  });

  const { setAttachments } = useSharedAttachmentState();

  const onActivitySelect = (activityId: string) => {
    setSelectedActivity(activityId);
    setAttachments(undefined);
    if (isMobile) {
      window.scrollTo(0, 0);
    }
    closeAll();
  };

  // ------------Beginning of FILTER SAGA-----------//
  const filterByProgram = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      if (!filtersParam.programId || filtersParam.programId === 'all') {
        return true;
      }
      return filtersParam.programId === activity.program.programId;
    },
    [filtersParam]
  );
  const filterByActivityType = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      if (!filtersParam.type || filtersParam.type === 'all') {
        return true;
      }
      return filtersParam.type === 'flexible'
        ? activity.__typename === 'VOLUNTEER_NonRecurringActivityType'
        : activity.__typename === 'VOLUNTEER_RecurringActivityType';
    },
    [filtersParam]
  );
  const filterByDuration = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      const activityDuration = timeDurationInHours(
        DateTime.fromISO(activity.startTime),
        DateTime.fromISO(activity.endTime)
      );
      if (!filtersParam.duration || filtersParam.duration === 'any') {
        return true;
      }

      const durationNumber = parseInt(filtersParam.duration[0], 10);

      if (filtersParam.duration.includes('plus')) {
        return durationNumber <= activityDuration.hours;
      }
      return durationNumber >= activityDuration.hours;
    },
    [filtersParam]
  );
  const filterByLocation = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      if (!filtersParam.location || filtersParam.location === 'all') {
        return true;
      }
      return filtersParam.location === 'remote'
        ? activity.activityLocation === null
        : activity.activityLocation !== null;
    },
    [filtersParam]
  );
  const filterByStatus = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      const { status } = filtersParam;
      if (status === 'all') {
        return true;
      }
      if (!status || status === 'active') {
        return activity.isActive;
      }
      if (status === 'suspended') {
        return activity.isSuspended && !activity.closedActivity;
      }
      if (status === 'closed') {
        return activity.closedActivity;
      }
      if (status === 'ended') {
        return getIsActivityEnded(activity);
      }
      return true;
    },
    [filtersParam]
  );
  const filterByRosterType = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      if (filtersParam.rosterType === 'open') {
        return activity.hasOpenRoster;
      }
      if (filtersParam.rosterType === 'managed') {
        return !activity.hasOpenRoster;
      }
      return true;
    },
    [filtersParam]
  );
  const filterByDay = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      const { days = [] } = filtersParam;
      if (
        !(days.length && days.length < 7) ||
        (activity.__typename === 'VOLUNTEER_NonRecurringActivityType' && activity.endDate === null)
      ) {
        return true;
      }
      if (activity.__typename === 'VOLUNTEER_NonRecurringActivityType' && activity.endDate !== null) {
        // If days more than 6 return true (it's got every day)
        const endDate = DateTime.fromISO(activity.endDate);
        const startDate = DateTime.fromISO(activity.startDate);
        const startDay = DateTime.fromISO(activity.startDate)
          .weekdayShort.slice(0, 2)
          .toUpperCase();

        const diffInDays = endDate.diff(startDate, 'days');
        const diffInDaysObject = diffInDays.toObject();
        const diffInDaysInt =
          diffInDaysObject && diffInDaysObject.days && diffInDaysObject.days ? diffInDaysObject.days : 0;

        if (diffInDaysInt > 6) {
          return true;
        }

        // If less than a week create an array of days and see if any of those days exist in the filter.
        // This is a bit of a ✨creative✨ solution because I couldn't find what I needed in the luxon docs. 🤷

        const allDays = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
        const daysLoop = [...allDays, ...allDays];

        const activityDays = new Array(diffInDaysInt + 1)
          .fill(null)
          .map((_unused, index) => (index === 0 ? startDay : daysLoop[daysLoop.indexOf(startDay) + index]));

        return days.some((day) => activityDays.includes(day));
      }

      return days.find((selectedDay) => {
        if (activity.__typename === 'VOLUNTEER_RecurringActivityType') {
          const activityRecurrenceDays = activity.schedule.recurrences.flatMap((recurrence: any) =>
            recurrence.__typename === 'WeeklyRecurrenceType'
              ? recurrence.days.join()
              : recurrence.__typename === 'MonthlyNthDayRecurrenceType'
              ? recurrence.day
              : recurrence.__typename === 'SingleRecurrenceType'
              ? DateTime.fromJSDate(new Date(arrayHead(activity.schedule.recurrences)?.startDate))
                  .weekdayShort.slice(0, 2)
                  .toUpperCase()
              : undefined
          );
          return activityRecurrenceDays.find(
            (recurrenceDay: any) => recurrenceDay && recurrenceDay.includes(selectedDay)
          );
        }
        return false;
      });
    },
    [filtersParam]
  );

  const filterByActivityTags = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      const { activityTagIds = [] } = filtersParam;
      if (!activityTagIds.length) {
        return true;
      }
      const activityActivityTag = activity.activityTags;
      return activityTagIds.every((selectedTagId) =>
        activityActivityTag.find((tag) => tag.activityTagId === selectedTagId)
      );
    },
    [filtersParam]
  );

  // TODO: expand this filter to be more accurate that takes into account the activities that span across multiple days
  const filterByTimePeriod = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      if (!(filtersParam.startTime && filtersParam.endTime)) {
        return true;
      }

      const selectedTimeInterval = Interval.fromDateTimes(
        DateTime.fromISO(filtersParam.startTime),
        DateTime.fromISO(filtersParam.endTime)
      );
      return (
        selectedTimeInterval.contains(DateTime.fromISO(activity.startTime)) &&
        selectedTimeInterval.contains(DateTime.fromISO(activity.endTime))
      );
    },
    [filtersParam]
  );
  const filterByDate = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      const filterStartDate = filtersParam.startDate;
      const filterEndDate = filtersParam.endDate;
      // Handle 4 cases of start and end date filter combination:
      // 1. Default i.e empty start and end date or filters cleared state
      if (!filterStartDate && !filterEndDate) {
        return true;
      }
      // 2. if there is startDate specified on filters but no endDate
      if (filterStartDate && !filterEndDate) {
        return activity.startDate >= filterStartDate;
      }
      // 3. if endDate specified but no startDate
      if (!filterStartDate && filterEndDate) {
        // Handle the case where activity doesn't have end date as endDate field is
        // optional for both recurring and non recurring activity: activity without end date should not be in the list
        if (!activity.endDate) return false;
        return activity.endDate <= filterEndDate;
      }
      // 4. If both start and end date specified
      if (filterStartDate && filterEndDate) {
        // if the user input same start date and end date, but its once off activity with only startDate,
        // it should be in the list, hence an additional if statement below to make sure this case is handled
        if (!activity.endDate && activity.startDate) return activity.startDate >= filterStartDate;
        if (activity.startDate && activity.endDate)
          return activity.startDate >= filterStartDate && activity.endDate <= filterEndDate;
        // all other cases, do not include activity in the list
        // 1. the activities that has start date but no end date or should we include this one?
        // 2. activities that do not have both
        return false;
      }
      return true;
    },
    [filtersParam]
  );
  const filterBySearch = useCallback(
    (activity: ActivitiesConsoleCardExploreActivity) => {
      const activityActivityTagNames = activity.activityTags.map((tag) => tag.name.toLocaleLowerCase());
      return searchInput
        ? activity.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase().trim()) ||
            activityActivityTagNames.includes(searchInput.toLocaleLowerCase().trim())
        : true;
    },
    [searchInput]
  );

  const filteredActivities = useMemo(() => {
    if (!activities) return [];
    const { combination } = filtersParam;

    const filterFunc = (activity: ActivitiesConsoleCardExploreActivity) => {
      if (combination === 'include') {
        return (
          filterByProgram(activity) ||
          filterByActivityType(activity) ||
          filterByDuration(activity) ||
          filterByLocation(activity) ||
          filterByStatus(activity) ||
          filterByRosterType(activity) ||
          filterByDay(activity) ||
          filterByTimePeriod(activity) ||
          filterByDate(activity) ||
          filterByActivityTags(activity)
        );
      }
      return (
        filterByProgram(activity) &&
        filterByActivityType(activity) &&
        filterByDuration(activity) &&
        filterByLocation(activity) &&
        filterByStatus(activity) &&
        filterByRosterType(activity) &&
        filterByDay(activity) &&
        filterByTimePeriod(activity) &&
        filterByDate(activity) &&
        filterByActivityTags(activity)
      );
    };

    return activities.filter(filterFunc);
  }, [filtersParam, activities]);

  const searchedActivities = useMemo(() => {
    if (!filteredActivities) return [];
    return filteredActivities.filter(filterBySearch);
  }, [filteredActivities, searchInput]);
  // ------------THE END of FILTER SAGA-----------//

  if (!activities && filteredActivities) {
    setSelectedActivity(filteredActivities[0].activityId);
  }

  // ------------Beginning of SORT SAGA-----------//
  function sortByStartTime(
    activityA: ActivitiesConsoleCardExploreActivity,
    activityB: ActivitiesConsoleCardExploreActivity
  ) {
    const occurrenceA = activityA.nextOccurrence;
    const occurrenceB = activityB.nextOccurrence;

    if (occurrenceA === occurrenceB) {
      return 0;
    }
    if (occurrenceA === null) {
      return -1;
    }
    if (occurrenceB === null) {
      return 1;
    }

    const occurrenceADate = DateTime.fromISO(occurrenceA);
    const occurrenceBDate = DateTime.fromISO(occurrenceB);

    if (occurrenceADate === occurrenceBDate) {
      return 0;
    }

    return occurrenceADate < occurrenceBDate ? -1 : 1;
  }

  function sortByDuration(
    activityOne: ActivitiesConsoleCardExploreActivity,
    activityTwo: ActivitiesConsoleCardExploreActivity
  ) {
    const durationOne = timeDurationInHours(
      DateTime.fromISO(activityOne.startTime),
      DateTime.fromISO(activityOne.endTime)
    );
    const durationTwo = timeDurationInHours(
      DateTime.fromISO(activityTwo.startTime),
      DateTime.fromISO(activityTwo.endTime)
    );
    return durationOne > durationTwo ? 1 : -1;
  }

  const sortByPriority = (
    activityOne: ActivitiesConsoleCardExploreActivity,
    activityTwo: ActivitiesConsoleCardExploreActivity
  ) => {
    if (activityOne.priority > activityTwo.priority) {
      return -1;
    }
    if (activityOne.priority === activityTwo.priority) {
      return sortByStartTime(activityOne, activityTwo);
    }
    return 1;
  };

  const sortedActivities = useMemo(() => {
    if (!searchedActivities.length || !selectedSortValue) return searchedActivities;
    if (selectedSortValue.includes('Priority')) {
      return searchedActivities.sort(sortByPriority);
    }
    if (selectedSortValue.includes('Recent')) {
      return searchedActivities.sort(sortByStartTime);
    }
    if (selectedSortValue.includes('Duration')) {
      return searchedActivities.sort(sortByDuration);
    }
    if (selectedSortValue.includes('Name')) {
      return searchedActivities.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (selectedSortValue.includes('No. of Vols')) {
      return searchedActivities.sort((a, b) => (a.activityEnrolments.length > b.activityEnrolments.length ? 1 : -1));
    }
    return searchedActivities.sort((a, b) => a.name.localeCompare(b.name));
  }, [searchedActivities, selectedSortValue]);
  // ------------THE END of SORT SAGA-----------//

  const [selectedActivityId, setSelectedActivityId] = useQueryParam('activityId', StringParam);

  const firstActivityId = sortedActivities[0]?.activityId;
  const isSelectedInActivitiesList = Boolean(
    sortedActivities.find((activity) => activity.activityId === selectedActivityId)
  );

  useEffect(() => {
    if (firstActivityId && !isMobile && !isXs && !isSelectedInActivitiesList) {
      setSelectedActivityId(firstActivityId);
    }
  }, [firstActivityId, isSelectedInActivitiesList, isMobile, isXs]);

  return (
    <>
      <Box bgcolor='#fcfcfc' marginRight={!isMobile ? '60px' : '0'}>
        <ManagementActivitiesfilters showFilters={showFilters} programs={programs} activityTags={activityTags} />
      </Box>
      <Box marginRight={!isMobile ? '60px' : '0'} marginTop={!isMobile ? '10px' : '0'}>
        <ListSubheader disableSticky disableGutters style={{ padding: '0 5px' }}>
          {`${sortedActivities.length} activities found`}
        </ListSubheader>
        {sortedActivities?.map((a) =>
          a ? (
            <Box
              onClick={() => {
                onActivitySelect(a.activityId);
              }}
              padding='15px 0px'
              key={a.activityId}
            >
              <ActivityItem activity={a} />
            </Box>
          ) : (
            ''
          )
        )}
      </Box>
      {isExportingCsv && (
        <ExportCsvDialog
          open={isExportingCsv}
          close={handleCloseExportDialog}
          handleSaveCsv={() => handleSaveCsv(sortedActivities)}
        />
      )}
    </>
  );
};
