import { Box, Typography } from '@material-ui/core';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Cancel, CheckCircle, Help } from '@material-ui/icons';
import React, { useMemo } from 'react';
import {
  GetRosterSmsConfirmationData_vm_draftRoster_publishedRoster_activeRosterings as ActiveRosterings,
  GetRosterSmsConfirmationData_vm_draftRoster_publishedRoster_rosterings as AllRosterings,
} from './__generated__/GetRosterSmsConfirmationData';

interface Props {
  activeRosterings: ActiveRosterings[];
  allRosterings: AllRosterings[];
}

export const SmsConfirmationIndicators = (props: Props) => {
  const { activeRosterings, allRosterings } = props;
  const attendingConfirmations = useMemo(
    () => activeRosterings.filter((r) => r.sessionRosterings.length > 0 && r.attending).length,
    [activeRosterings]
  );
  const notAttendingConfirmations = useMemo(
    () => activeRosterings.filter((r) => r.sessionRosterings.length > 0 && r.attending === false).length,
    [activeRosterings]
  );
  const unconfirmedAttendances = useMemo(
    () => activeRosterings.filter((r) => r.sessionRosterings.length > 0 && r.attending === null).length,
    [activeRosterings]
  );
  const removedAttendingConfirmations = useMemo(
    () =>
      allRosterings.filter(
        (r) => r.attending && !activeRosterings.find((ar) => ar.volunteer.volunteerId === r.volunteer.volunteerId)
      ).length,
    [activeRosterings, allRosterings]
  );

  return (
    <Box paddingY={1} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
      <TitularTooltip description={'Attending'} placement={'right'}>
        <Box paddingRight={0.5} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
          <Typography variant='body2' color='textSecondary'>
            {attendingConfirmations}
          </Typography>
          <CheckCircle fontSize='small' htmlColor='#7DD268' style={{ fontSize: 15, marginLeft: 4 }} />
        </Box>
      </TitularTooltip>
      <TitularTooltip description={'Not attending'} placement={'right'}>
        <Box paddingX={0.5} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
          <Typography variant='body2' color='textSecondary'>
            {notAttendingConfirmations}
          </Typography>
          <Cancel fontSize='small' color='error' style={{ fontSize: 15, marginLeft: 4 }} />
        </Box>
      </TitularTooltip>
      <TitularTooltip description={'Unconfirmed'} placement={'right'}>
        <Box paddingX={0.5} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
          <Typography variant='body2' color='textSecondary'>
            {unconfirmedAttendances}
          </Typography>
          <Help fontSize='small' htmlColor='#00000024' style={{ fontSize: 15, marginLeft: 4 }} />
        </Box>
      </TitularTooltip>
      <TitularTooltip description={'Confirmed but removed from roster'} placement={'right'}>
        <Box paddingX={0.5} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
          <Typography variant='body2' color='textSecondary'>
            {removedAttendingConfirmations}
          </Typography>
          <CheckCircle fontSize='small' htmlColor='#00000024' style={{ fontSize: 15, marginLeft: 4 }} />
        </Box>
      </TitularTooltip>
    </Box>
  );
};
