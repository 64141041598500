import { timeDurationInHours } from '@campfire/hot-date';
import { Box, Grid } from '@material-ui/core';
import { uniqBy } from 'lodash';
import { DateTime, Interval } from 'luxon';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { arrayHead } from '../../../common/functions/array-head';
import { SearchField } from '../../../common/inputs/SearchField';
import { useUser } from '../../../global/auth/useUser';
import { GET_USER_PAGE_INTERACTIONS } from '../../../global/interactions/interactions-model.gql';
import {
  GetUserPageInteractions,
  GetUserPageInteractionsVariables,
} from '../../../global/interactions/__generated__/GetUserPageInteractions';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { getIsActivityEnded } from '../../program-manager/activities/activity-timeline/is-activity-ended';
import {
  MY_ACTIVITIES_PAGE,
  UPCOMING_ROSTERS_VIEW_TUTORIAL_ACTION,
} from '../user-profile/my-elements/upcoming-rosters-card/MyElementsUpcomingRostersCard';
import { ActivitiesFilterDialogButton } from './activities-filters/activities-filters-dialog/ActivitiesFilterDialogButton';
import { DayFilterSelectField } from './activities-filters/day-filter/DayFilterSelectField';
import { useFilterStyles } from '../../../common/filter-fields/FilterClasses';
import {
  ActivityTypeFiltersSelect,
  areInitialTimeFiltersDefault,
  DAY_FILTER_URL_PARAM_KEY,
  DurationFiltersSelect,
  Filters,
  getDurationFromFilterValue,
  initialFilters,
  LocationFiltersSelect,
  RosterTypeFiltersSelect,
  StatusFiltersSelect,
  TIME_FILTER_END_TIME_URL_PARAM_KEY,
  TIME_FILTER_START_TIME_URL_PARAM_KEY,
} from '../../../common/filter-fields/filters';
import { ProgramFilterSelectField } from './activities-filters/program-filter/ProgramFilterSelectField';
import { TimeFilterSelectPopover } from './activities-filters/time-filter/TimeFilterSelectPopover';
import { ActivitiesExploreActivitiesList } from './ActivitiesExploreActivitiesList';
import { ActivitiesExploreActivityDialog } from './ActivitiesExploreActivityDialog/ActivitiesExploreActivityDialog';
import { ActivitiesExploreScreenSkeleton } from './ActivitiesExploreSkeleton';
import { ActivitiesMap } from './ActivitiesMap';
import { GET_ACTIVITIES_EXPLORE } from './activity-explore-model.gql';
import { useGetActivityEnrolmentStatus } from './useActivityEnrolmentStatus';
import { ActivitiesExploreActivity } from './__generated__/ActivitiesExploreActivity';
import { GetActivitiesExplore, GetActivitiesExploreVariables } from './__generated__/GetActivitiesExplore';

type ActivitiesExploreScreenProps = {
  isManagementView?: boolean;
  managementPath?: string;
  activityIds: string[];
};

const ActivitiesExplore = memo(({ isManagementView, activityIds }: ActivitiesExploreScreenProps) => {
  const [selectedActivityId, setSelectedActivityId] = useQueryParam('activityId', StringParam);
  const [selectedProgramId, setSelectedProgramId] = useQueryParam('programId', StringParam);
  const [selectedFilters, setSelectedFilters] = useState<Filters>(initialFilters);
  const [hoveredActivityId, setHoveredActivityId] = useState<string>();
  const [targetedActivityId, setTargetedActivityId] = useState<string>();

  const history = useHistory();

  const {
    userIdentity,
    user: { userId, userIdentityService },
    maybeVolunteerIdentity,
  } = useUser();

  const { data: myActivitiesInteractionResponse } = useCampfireQuery<
    GetUserPageInteractions,
    GetUserPageInteractionsVariables
  >(GET_USER_PAGE_INTERACTIONS, {
    options: {
      variables: {
        userId,
        page: MY_ACTIVITIES_PAGE,
      },
    },
  });

  const { theme, isMobile } = useCampfireTheme();
  const filterClasses = useFilterStyles(theme);

  const { data: programsResponse, loading: programsLoading, refetch: programsRefetch } = useCampfireQuery<
    GetActivitiesExplore,
    GetActivitiesExploreVariables
  >(GET_ACTIVITIES_EXPLORE, {
    options: {
      variables: {
        volunteerId: maybeVolunteerIdentity?.volunteerId ?? '',
        activityIds: activityIds,
      },
    },
  });

  const activities = useMemo(() => {
    return programsResponse?.vm.activities ?? [];
  }, [programsResponse]);

  const programs = useMemo(() => {
    const isAdmin = userIdentityService.isVmAdmin;
    const { isActivityLeader } = userIdentityService;

    const activityLeaderManagedProgramIds =
      userIdentity.vm?.volunteerIdentity?.programIdentities.map((y) => y.programId) ?? [];

    if (isActivityLeader) {
      return programsResponse?.vm.programs.filter((x) => activityLeaderManagedProgramIds.includes(x.programId)) ?? [];
    }

    if (isAdmin) return programsResponse?.vm.programs ?? [];

    const managedProgramIds = userIdentity.vm?.managerIdentity?.programManagerIdentities.map((y) => y.programId) ?? [];

    if (isManagementView) {
      return programsResponse?.vm.programs.filter((x) => managedProgramIds.includes(x.programId)) ?? [];
    }

    return (
      uniqBy(
        activities.map((activity) => activity.program),
        'programId'
      ) ?? []
    ).sort((a, b) => a.name.localeCompare(b.name));
  }, [programsResponse]);

  const selectedActivity = useMemo(() => {
    return activities.find((activity) => activity.activityId === selectedActivityId);
  }, [activities, selectedActivityId]);

  useEffect(() => {
    if (!selectedActivity) return;
    setSelectedProgramId(selectedActivity.program.programId);
  }, [selectedActivity]);

  const activitiesList = useMemo(() => {
    return activities.filter(
      (activity) => selectedProgramId === 'all' || activity.program.programId === selectedProgramId
    );
  }, [activities, selectedProgramId]);

  const activityApplications = useMemo(() => {
    if (!programsResponse?.vm.volunteer?.activityApplications) return [];
    return programsResponse.vm.volunteer.activityApplications;
  }, [programsResponse]);

  const volunteerActivityApplication = useMemo(() => {
    return programsResponse?.vm.volunteer?.activityApplications.find(
      (application) => application.activity.activityId === selectedActivityId
    );
  }, [programsResponse, selectedActivityId]);

  useEffect(() => {
    if (!selectedProgramId) {
      setSelectedProgramId('all');
    }
  }, [selectedProgramId]);

  const filterByDay = useCallback(
    (activity: ActivitiesExploreActivity) => {
      if (
        !(selectedFilters.selectedDayFilters.value.length && selectedFilters.selectedDayFilters.value.length < 7) ||
        (activity.__typename === 'VOLUNTEER_NonRecurringActivityType' && activity.endDate === null)
      ) {
        return true;
      }
      if (activity.__typename === 'VOLUNTEER_NonRecurringActivityType' && activity.endDate !== null) {
        // If days more than 6 return true (it's got every day)
        const endDate = DateTime.fromISO(activity.endDate);
        const startDate = DateTime.fromISO(activity.startDate);
        const startDay = DateTime.fromISO(activity.startDate)
          .weekdayShort.slice(0, 2)
          .toUpperCase();

        const diffInDays = endDate.diff(startDate, 'days');
        const diffInDaysObject = diffInDays.toObject();
        const diffInDaysInt =
          diffInDaysObject && diffInDaysObject.days && diffInDaysObject.days ? diffInDaysObject.days : 0;

        if (diffInDaysInt > 6) {
          return true;
        }

        // If less than a week create an array of days and see if any of those days exist in the filter.
        // This is a bit of a ✨creative✨ solution because I couldn't find what I needed in the luxon docs. 🤷

        const days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
        const daysLoop = [...days, ...days];

        const activityDays = new Array(diffInDaysInt + 1)
          .fill(null)
          .map((_unused, index) => (index === 0 ? startDay : daysLoop[daysLoop.indexOf(startDay) + index]));

        return selectedFilters.selectedDayFilters.value.some((day) => activityDays.includes(day));
      }

      return selectedFilters.selectedDayFilters.value.find((selectedDay) => {
        if (activity.__typename === 'VOLUNTEER_RecurringActivityType') {
          const activityRecurrenceDays = activity.schedule.recurrences.flatMap((recurrence: any) =>
            recurrence.__typename === 'WeeklyRecurrenceType'
              ? recurrence.days.join()
              : recurrence.__typename === 'MonthlyNthDayRecurrenceType'
              ? recurrence.day
              : recurrence.__typename === 'SingleRecurrenceType'
              ? DateTime.fromJSDate(new Date(arrayHead(activity.schedule.recurrences)?.startDate))
                  .weekdayShort.slice(0, 2)
                  .toUpperCase()
              : undefined
          );
          return activityRecurrenceDays.find(
            (recurrenceDay: any) => recurrenceDay && recurrenceDay.includes(selectedDay)
          );
        }
        return false;
      });
    },
    [selectedFilters]
  );

  const filterByDuration = useCallback(
    (activity: ActivitiesExploreActivity) => {
      const activityDuration = timeDurationInHours(
        DateTime.fromISO(activity.startTime),
        DateTime.fromISO(activity.endTime)
      );
      if (selectedFilters.selectedDurationFilter.label === 'Any Duration') {
        return true;
      }

      const duration = getDurationFromFilterValue(selectedFilters.selectedDurationFilter.value);

      if (!duration || selectedFilters.selectedDurationFilter.label.includes('Any')) return true;

      if (selectedFilters.selectedDurationFilter.label.includes('More than')) {
        return duration.hours <= activityDuration.hours;
      }
      return duration.hours >= activityDuration.hours;
    },
    [selectedFilters]
  );

  // TODO: expand this filter to be more accurate that takes into account the activities that span across multiple days
  const filterByTimePeriod = useCallback(
    (activity: ActivitiesExploreActivity) => {
      if (
        !(selectedFilters.selectedTimeFilter.startTime && selectedFilters.selectedTimeFilter.endTime) ||
        areInitialTimeFiltersDefault(selectedFilters.selectedTimeFilter)
      ) {
        return true;
      }

      const selectedTimeInterval = Interval.fromDateTimes(
        selectedFilters.selectedTimeFilter.startTime,
        selectedFilters.selectedTimeFilter.endTime
      );
      return (
        selectedTimeInterval.contains(DateTime.fromISO(activity.startTime)) &&
        selectedTimeInterval.contains(DateTime.fromISO(activity.endTime))
      );
    },
    [selectedFilters]
  );

  const filterByStatus = useCallback(
    (activity: ActivitiesExploreActivity) => {
      const { selectedStatusFilter } = selectedFilters;
      if (selectedStatusFilter.value === 'active') {
        return activity.isActive;
      }
      if (selectedStatusFilter.value === 'suspended') {
        return activity.isSuspended && !activity.closedActivity;
      }
      if (selectedStatusFilter.value === 'closed') {
        return activity.closedActivity;
      }
      if (selectedStatusFilter.value === 'ended') {
        return getIsActivityEnded(activity);
      }
      return activity;
    },
    [selectedFilters]
  );

  const filterBySearch = useCallback(
    (activity: ActivitiesExploreActivity) => {
      return selectedFilters.searchInput
        ? activity.name.toLocaleLowerCase().includes(selectedFilters.searchInput.toLocaleLowerCase())
        : true;
    },
    [selectedFilters]
  );

  const filterByActivityType = useCallback(
    (activity: ActivitiesExploreActivity) => {
      if (selectedFilters.selectedActivityTypeFilter.value === 'all') {
        return true;
      }
      return selectedFilters.selectedActivityTypeFilter.value === 'flexible'
        ? activity.__typename === 'VOLUNTEER_NonRecurringActivityType'
        : activity.__typename === 'VOLUNTEER_RecurringActivityType';
    },
    [selectedFilters]
  );

  const filterByRosterType = useCallback(
    (activity: ActivitiesExploreActivity) => {
      if (selectedFilters.selectedRosterTypeFilter.value === 'all') {
        return true;
      }
      if (selectedFilters.selectedRosterTypeFilter.value === 'open' && activity.hasOpenRoster) {
        return true;
      }
      if (selectedFilters.selectedRosterTypeFilter.value === 'managed' && !activity.hasOpenRoster) {
        return true;
      }
      return false;
    },
    [selectedFilters]
  );

  const filterByLocation = useCallback(
    (activity: ActivitiesExploreActivity) => {
      if (selectedFilters.selectedLocationFilter.value === 'all') {
        return true;
      }
      return selectedFilters.selectedLocationFilter.value === 'remote'
        ? activity.activityLocation === null
        : activity.activityLocation !== null;
    },
    [selectedFilters]
  );

  const getActivityEnrolmentStatus = useGetActivityEnrolmentStatus();

  const filterHiddenActivities = useCallback((activity: ActivitiesExploreActivity): boolean => {
    if (isManagementView) {
      return true;
    }
    if (!activity.isHidden) {
      return true;
    }
    const status = getActivityEnrolmentStatus(activity, activityApplications);
    if (status !== 'notEnrolled') {
      return true;
    }
    return false;
  }, []);

  const filteredActivities = useMemo(() => {
    return activitiesList
      .filter(filterByDay)
      .filter(filterByStatus)
      .filter(filterByDuration)
      .filter(filterByTimePeriod)
      .filter(filterBySearch)
      .filter(filterByActivityType)
      .filter(filterByRosterType)
      .filter(filterByLocation)
      .filter(filterHiddenActivities);
  }, [selectedFilters, activitiesList]);

  const showSkinnyList =
    selectedFilters.selectedStatusFilter.value === 'all' || selectedFilters.selectedStatusFilter.value === 'ended';

  const numberNonDefaultFilters = useMemo(
    () =>
      Object.values(selectedFilters).reduce((prev, current) => {
        /**
         * Accessing property is effectively the same as typechecking in this case.
         * We are running this due to prescrptive shape of the `Filters` type; it's not made to be iterated over but we make do.
         */
        if (!current) return prev;
        const { isActive } = current as any;
        return isActive ? prev + 1 : prev;
      }, 0) +
      (selectedProgramId !== 'all' ? 1 : 0) +
      (areInitialTimeFiltersDefault(selectedFilters.selectedTimeFilter) ? 0 : 1) +
      (selectedFilters.searchInput !== undefined && selectedFilters.searchInput !== '' ? 1 : 0),
    [selectedFilters, selectedProgramId]
  );

  function clearFilters() {
    const clearedFilterUrlParams = new URLSearchParams(history.location.search);
    Object.values(selectedFilters).forEach((filter) => {
      if (!filter) return;
      const maybeUrlParamKey = (filter as any).urlParamKey; // same as above comment
      if (maybeUrlParamKey) {
        clearedFilterUrlParams.delete(maybeUrlParamKey);
      }
    });
    clearedFilterUrlParams.delete(DAY_FILTER_URL_PARAM_KEY);
    clearedFilterUrlParams.delete(TIME_FILTER_START_TIME_URL_PARAM_KEY);
    clearedFilterUrlParams.delete(TIME_FILTER_END_TIME_URL_PARAM_KEY);
    clearedFilterUrlParams.set('programId', 'all');

    setSelectedFilters({ ...selectedFilters, searchInput: undefined });
    history.replace(`${history.location.pathname}?${clearedFilterUrlParams}`);
  }

  return (
    <Grid
      container
      style={{ display: 'flex', flex: 1, boxSizing: 'border-box', overflow: 'hidden' }}
      data-component='ActivitiesExplore'
    >
      {programsLoading ? (
        <ActivitiesExploreScreenSkeleton />
      ) : (
        <Grid
          item
          container
          direction='column'
          style={{
            boxSizing: 'border-box',
            overflow: 'hidden',
            display: 'flex',
            flex: 1,
          }}
        >
          <Grid container className={filterClasses.headerContainer} alignItems='center' style={{ overflowX: 'auto' }}>
            {isMobile && selectedProgramId ? (
              <>
                {isManagementView ? (
                  <Box display='flex' alignItems='center' justifyContent='space-between' width={1}>
                    <Box display='flex' justifyContent='flex-start'>
                      <Box className={filterClasses.subheaderGridItem} style={{ margin: 4 }}>
                        <SearchField
                          data-track='actCnlMap-SearchInput'
                          placeholder='Search Activities'
                          fullWidth
                          onChange={(e) =>
                            setSelectedFilters({
                              ...selectedFilters,
                              searchInput: (e.target.value as string) || undefined,
                            })
                          }
                        />
                      </Box>
                    </Box>
                    <ActivitiesFilterDialogButton
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                      setSelectedProgramId={setSelectedProgramId}
                      selectedProgramId={selectedProgramId}
                      programs={programs}
                      isManagementView={isManagementView}
                      clearFilters={clearFilters}
                      numberNonDefaultFilters={numberNonDefaultFilters}
                    />
                  </Box>
                ) : (
                  <Box display='flex' alignItems='center' justifyContent='flex-end' width={1}>
                    <ActivitiesFilterDialogButton
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                      setSelectedProgramId={setSelectedProgramId}
                      selectedProgramId={selectedProgramId}
                      programs={programs}
                      isManagementView={isManagementView}
                      clearFilters={clearFilters}
                      numberNonDefaultFilters={numberNonDefaultFilters}
                    />
                  </Box>
                )}
              </>
            ) : (
              <>
                <Grid item className={filterClasses.subheaderGridItem} style={{ minWidth: 224 }}>
                  <SearchField
                    data-track='actCnlMapSearchInput'
                    placeholder='Search Activities'
                    fullWidth
                    onChange={(e) =>
                      setSelectedFilters({ ...selectedFilters, searchInput: (e.target.value as string) || undefined })
                    }
                  />
                </Grid>
                <Box style={{ margin: '4px 8px' }}>
                  <DayFilterSelectField selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                </Box>

                {isManagementView && (
                  <Box style={{ margin: '4px 8px' }}>
                    <StatusFiltersSelect selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                  </Box>
                )}

                <Box style={{ margin: '4px 8px' }}>
                  <ProgramFilterSelectField
                    selectedProgramId={selectedProgramId}
                    setSelectedProgramId={setSelectedProgramId}
                    programs={programs}
                  />
                </Box>
                <Box style={{ margin: '4px 8px' }}>
                  <DurationFiltersSelect selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                </Box>
                <ActivityTypeFiltersSelect selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />

                {isManagementView ? (
                  <Box style={{ margin: '4px 8px' }}>
                    <RosterTypeFiltersSelect
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                    />
                  </Box>
                ) : null}
                <Box style={{ margin: '4px 8px' }}>
                  <LocationFiltersSelect selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                </Box>
                <Box style={{ margin: '4px 8px' }}>
                  <TimeFilterSelectPopover selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                </Box>
              </>
            )}
          </Grid>
          <Grid
            item
            container
            style={{
              display: 'flex',
              flex: 1,
              overflow: 'hidden',
              flexWrap: 'nowrap',
            }}
          >
            {isMobile ? (
              <Grid item xs={12} style={{ display: 'flex', flex: '1 1 auto', height: '100%', overflow: 'hidden' }}>
                <ActivitiesExploreActivitiesList
                  clearFilters={clearFilters}
                  numberNonDefaultFilters={numberNonDefaultFilters}
                  showSkinnyList={showSkinnyList}
                  setHoveredActivityId={setHoveredActivityId}
                  activities={filteredActivities}
                  hoveredActivityId={hoveredActivityId}
                  setSelectedActivityId={setSelectedActivityId}
                  setTargetedActivityId={setTargetedActivityId}
                  volName={programsResponse?.vm.volunteer?.profile.preferredName}
                  isManagementView={isManagementView}
                  activityApplications={activityApplications}
                  isEmptyProgram={activitiesList.length === 0}
                  programs={programs}
                  selectedProgramId={selectedProgramId}
                  orgName={programsResponse?.orgName}
                />
              </Grid>
            ) : (
              <>
                <Box
                  style={{
                    display: 'flex',
                    flex: '1 1',
                    height: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <ActivitiesExploreActivitiesList
                    clearFilters={clearFilters}
                    numberNonDefaultFilters={numberNonDefaultFilters}
                    showSkinnyList={showSkinnyList}
                    setHoveredActivityId={setHoveredActivityId}
                    activities={filteredActivities}
                    hoveredActivityId={hoveredActivityId}
                    setSelectedActivityId={setSelectedActivityId}
                    setTargetedActivityId={setTargetedActivityId}
                    volName={programsResponse?.vm.volunteer?.profile.preferredName}
                    isManagementView={isManagementView}
                    activityApplications={activityApplications}
                    isEmptyProgram={activitiesList.length === 0}
                    programs={programs}
                    selectedProgramId={selectedProgramId}
                    orgName={programsResponse?.orgName}
                  />
                </Box>
                {!filteredActivities.every((activity) => activity.activityLocation === null) && (
                  <Box
                    style={{
                      display: isMobile ? 'none' : 'flex',
                      flex: '1 1',
                      overflow: 'hidden',
                    }}
                  >
                    <ActivitiesMap
                      showSkinnyMarkers={showSkinnyList}
                      activities={filteredActivities}
                      selectedActivityId={selectedActivityId}
                      hoveredActivityId={hoveredActivityId}
                      targetedActivityId={targetedActivityId}
                      setHoveredActivityId={setHoveredActivityId}
                      setSelectedActivityId={setSelectedActivityId}
                    />
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}

      {/* TODO: Self contained ActivitiesExploreActivityDialog that takes activity id, fetch activity and so on  */}
      {selectedActivity && !isManagementView && (
        <ActivitiesExploreActivityDialog
          open
          onClose={() => {
            setSelectedActivityId(undefined);

            if (!activityApplications.length || !myActivitiesInteractionResponse) return;
            const hasViewedMyActivitiesTutorial = !!myActivitiesInteractionResponse.vm.userInteractions.find(
              (interaction) => interaction.action === UPCOMING_ROSTERS_VIEW_TUTORIAL_ACTION
            );
            if (!hasViewedMyActivitiesTutorial) {
              history.push('/volunteering/activities/my-activities');
            }
          }}
          activity={selectedActivity}
          volunteerActivityApplication={volunteerActivityApplication}
          refetch={programsRefetch}
          programsLoading={programsLoading}
        />
      )}
    </Grid>
  );
});

export { ActivitiesExplore };
