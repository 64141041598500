import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import {
  AmpStoriesOutlined,
  FilterList,
  RestoreOutlined,
  SettingsOutlined,
  Edit,
  UpdateOutlined,
} from '@material-ui/icons';
import classNames from 'classnames';
import { SearchField } from '../../../../common/inputs/SearchField';
import { ToggleButton } from '../../../../common/buttons/ToggleButton';
import { ActionsHeader } from '../../../../common/ActionsHeader';
import { useStyles } from '../styles';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface InvidualRosterToolbarProps {
  title: string;
  isFiltering: boolean;
  query: string;
  setQueryValue: (value: string) => void;
  setFiltering: () => void;
  isHistory: boolean;
  setHistory: () => void;
  onSettings: () => void;
  onCancel: () => void;
  onView: () => void;
  onTemplate: () => void;
  showTemplate: boolean;
  showCancel: boolean;
}

export function RosterToolbar(props: InvidualRosterToolbarProps) {
  const {
    title,
    isFiltering,
    query,
    setQueryValue,
    setFiltering,
    isHistory,
    setHistory,
    onSettings,
    onTemplate,
    showTemplate,
    onView,
  } = props;
  const classes = useStyles();
  const { isMd } = useCampfireTheme();
  return (
    <Box margin={'12px 60px 0px 60px'}>
      <ActionsHeader title={title}>
        <Box display={'flex'} alignItems='center'>
          <Box style={{ marginRight: '10px' }}>
            <SearchField
              data-track='rosterCnlFilter-SearchInput'
              placeholder='Search Rosters'
              growLeft
              onChange={(e) => setQueryValue(e.target.value)}
              fullWidth={!isMd}
              value={query}
            />
          </Box>
          <Button
            classes={{
              root: classNames(classes.button, classes.headerButton),
            }}
            onClick={onSettings}
          >
            <SettingsOutlined style={{ fontSize: '18px' }} />
            <Typography className={classes.typography}>Settings</Typography>
          </Button>
          <ToggleButton
            classes={{
              root: classNames(classes.button, classes.headerButton),
              selected: classes.toggledButton,
            }}
            onClick={setFiltering}
            selected={isFiltering}
          >
            <FilterList style={{ fontSize: '18px' }} />
            <Typography className={classes.typography}>Filters</Typography>
          </ToggleButton>
          <ToggleButton
            classes={{
              root: classNames(classes.button, classes.headerButton),
              selected: classes.toggledButton,
            }}
            onClick={setHistory}
            selected={isHistory}
          >
            {isHistory ? (
              <RestoreOutlined style={{ fontSize: '18px' }} />
            ) : (
              <UpdateOutlined style={{ fontSize: '18px' }} />
            )}
            <Typography className={classes.typography}>History</Typography>
          </ToggleButton>

          {showTemplate ? (
            <Button
              classes={{
                root: classNames(classes.button, classes.headerButton),
              }}
              onClick={onTemplate}
            >
              <Edit style={{ fontSize: '18px' }} />
              <Typography className={classes.typography}>Edit Roster</Typography>
            </Button>
          ) : null}
          <Button
            classes={{
              root: classNames(classes.button, classes.headerButton),
            }}
            onClick={onView}
          >
            <AmpStoriesOutlined style={{ fontSize: '18px' }} />
            <Typography className={classes.typography}>Bulk View</Typography>
          </Button>
        </Box>
      </ActionsHeader>
    </Box>
  );
}
