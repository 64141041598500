import { TabletButton, TabletButtonProps } from '@campfire/tablet-button';
import { Box, Grid } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import React, { memo, PropsWithChildren } from 'react';
import { useCampfireTheme } from '../theme/useCampfireTheme';

interface Props {
  handleDiscard?: () => void;
  saveButtonName: string;
  closeButtonName: string;
  saveButtonProps?: TabletButtonProps & ButtonProps;
  closeButtonProps?: TabletButtonProps;
}

const StickyHeader = memo(
  ({
    handleDiscard,
    children,
    saveButtonName,
    closeButtonName,
    saveButtonProps,
    closeButtonProps,
  }: PropsWithChildren<Props>) => {
    const { theme } = useCampfireTheme();

    return (
      <Box>
        <Box
          boxSizing='border-box'
          display='flex'
          justifyContent='flex-end'
          padding={2}
          borderBottom='solid 1px #e9e9e9'
        >
          {children}

          {handleDiscard ? (
            <Grid item>
              <TabletButton
                variant='text'
                style={{ borderRadius: '4px' }}
                onClick={handleDiscard}
                {...closeButtonProps}
              >
                {closeButtonName}
              </TabletButton>
            </Grid>
          ) : null}

          <Grid item style={{ marginLeft: 8 }}>
            <TabletButton
              variant='contained'
              type='submit'
              style={{
                backgroundColor: theme.color.secondary.main900,
                color: '#ffffff',
                boxSizing: 'border-box',
                borderRadius: '4px',
                textTransform: 'none',
                paddingRight: '16px',
                paddingLeft: '16px',
                fontWeight: 600,
                fontSize: '13px',
              }}
              {...saveButtonProps}
            >
              {saveButtonName}
            </TabletButton>
          </Grid>
        </Box>
      </Box>
    );
  }
);

export { StickyHeader };
