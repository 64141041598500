import React from 'react';
import { Box } from '@material-ui/core';
import qs from 'qs';
import { NumberWidgetProps, getEndDateFromRange, getStartDateFromRange, useWidgetStyles } from '../shared';
import { TotalNumberConfiguration } from '../../dashboard-widget-setting/__generated__/TotalNumberConfiguration';
import { useApiUrl } from '../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../global/network/useCampfireFetch';

type TotalNumberWidgetProps = NumberWidgetProps & {
  configuration: TotalNumberConfiguration;
};

export const TotalNumberWidget = (props: TotalNumberWidgetProps) => {
  const { tag, configuration, name, filters } = props;
  const classes = useWidgetStyles();
  const [value, setValue] = React.useState(0);
  const { range } = configuration;
  const apiUrl = useApiUrl();
  const campfireQuery = useCampfireFetch<{ count: number }>({ defer: true });

  React.useEffect(() => {
    campfireQuery
      .run({
        url: `${apiUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/total`,
        params: {
          startDate: getStartDateFromRange(range),
          endDate: getEndDateFromRange(range),
          filters: JSON.parse(filters),
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets', encode: false });
        },
      })
      .then((response) => response?.data?.data && setValue(response.data.data.count));
  }, [range, filters]);

  return <Box className={classes.valueText}>{value}</Box>;
};
