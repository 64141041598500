import React from 'react';
import { Grid, Switch, Typography } from '@material-ui/core';

interface Props {
  checked: boolean;
  onChange: Function;
  title: string;
  description: string;
}

export const SwitchField = (props: Props) => {
  const { checked, onChange, title, description } = props;
  return (
    <Grid item container>
      <Grid item>
        <Switch color='primary' checked={checked} onChange={() => onChange()} />
      </Grid>
      <Grid item xs container direction='column'>
        <Typography
          display='inline'
          style={{
            fontSize: '13px',
            color: '#000000b0',
            fontWeight: 500,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Typography>
        <Typography display='inline' style={{ fontSize: '12px', color: '#808080' }}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};
