import { PaneWrapper } from '@campfire/pane';
import { Box, Tab, Tabs, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useLayoutEffect } from 'react';
import { useQueryParam } from 'use-query-params';
import { ImpactDashboardDetailedView } from './detailed-impact-dashboard/ImpactDashboardDetailedView';
import { ImpactDashboardGeneralView } from './general-impact-dashboard/ImpactDashboardGeneralView';

type ImpactDashboardTabs = 'aggregate' | 'detailed';

const ImpactDashboard = () => {
  const [selectedTab, setSelectedTab] = useQueryParam<ImpactDashboardTabs>('view');
  const classes = useTabClasses();

  useLayoutEffect(() => {
    if (selectedTab === undefined) {
      setSelectedTab('aggregate');
    }
  }, []);

  return (
    <PaneWrapper>
      <Box borderBottom='1px solid #e9e9e9' paddingLeft={3}>
        <Tabs indicatorColor='primary' value={selectedTab === 'aggregate' ? 0 : 1} aria-label='field editor tabs'>
          <Tab
            classes={classes}
            disableRipple
            disableTouchRipple
            key={'aggregate'}
            label={'Aggregate View'}
            onClick={() => setSelectedTab('aggregate')}
          />
          <Tab
            classes={classes}
            disableRipple
            disableTouchRipple
            key={'detailed'}
            label={'Detailed View'}
            onClick={() => setSelectedTab('detailed')}
          />
        </Tabs>
      </Box>

      <Box
        position='relative'
        display='flex'
        flex='1 1 auto'
        width={1}
        overflow='auto'
        flexDirection='column'
        paddingBottom={8}
        bgcolor='#fff'
      >
        {selectedTab === 'aggregate' ? <ImpactDashboardGeneralView /> : <ImpactDashboardDetailedView />}
      </Box>
    </PaneWrapper>
  );
};

const useTabClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      textTransform: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  })
);

export { ImpactDashboard };
