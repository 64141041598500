import React from 'react';
import { IncidentManagementScreen } from '../../screens/program-manager/incident-management-v2/IncidentManagementScreen';
import MiniRoute from '../common/MiniRoute';
import { useRoutesMap } from '../common/useRoutesMaps';
import { VolunteersScreen } from '../../screens/program-manager/volunteers/VolunteersScreen';
import { ReportManagementScreen as ReportManagementScreenV2 } from '../../screens/program-manager/report-management-v2/ReportManagementScreen';
import { BulletinManagementScreen } from '../../screens/program-manager/bulletin-management/BulletinManagementScreen';
import { ManagementDashboardScreen } from '../../screens/program-manager/management-dashboard/ManagementDashboardScreen';

export const getProgramManagerSubContentBlock = () => {
  const {
    management: { route, isAuthorized },
  } = useRoutesMap();

  return [
    <MiniRoute
      //
      key='volunteers'
      path={`/${route}/volunteers`}
      component={VolunteersScreen}
      isAuthorized={isAuthorized}
    />,
    <MiniRoute
      //
      key='incident-management'
      path={`/${route}/incident-management`}
      component={IncidentManagementScreen}
      isAuthorized={isAuthorized}
    />,
    <MiniRoute
      //
      key='dashboard'
      path={`/${route}/dashboard`}
      component={ManagementDashboardScreen}
      isAuthorized={isAuthorized}
    />,
    // TODO: @Bandita fix these routes later
    <MiniRoute
      //
      key='report-management'
      path={`/${route}/report-management`}
      component={ReportManagementScreenV2}
      isAuthorized={isAuthorized}
    />,
    <MiniRoute
      //
      key='bulletins'
      path={`/${route}/bulletins`}
      component={BulletinManagementScreen}
      isAuthorized={isAuthorized}
    />,
  ];
};
