import { useApiUrl } from '../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';

export interface ApplicationReminderRequestType {
  applicationIds: string[],
  message: string
}
export interface ApplicationReminderAllRequestType {
  message: string
}

export function useCreateApplicationReminder() {
  const apiUrl = useApiUrl();
  const createApplicationReminder = useCampfireFetch({ defer: true });

  return ({ applicationIds, message }: ApplicationReminderRequestType) => createApplicationReminder.run({
    url: `${apiUrl}/vm/application/remind`,
    method: 'post',
    data: {
      applicationIds,
      message
    }
  })
}
export function useCreateApplicationReminderAll() {
  const apiUrl = useApiUrl();
  const createApplicationReminder = useCampfireFetch({ defer: true });

  return ({ message }: ApplicationReminderAllRequestType) => createApplicationReminder.run({
    url: `${apiUrl}/vm/application/remind-all`,
    method: 'post',
    data: {
      message
    }
  })
}