import { HoverText } from '@campfire/hover-link';
import { ArrowPopper } from '@campfire/popper';
import { Box, Button, Grid, Theme, Typography } from '@material-ui/core';
import { Room, Timer, Today } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { MyElementsSelectTimeFrame } from './MyElementsPersonalCard';

const useStyles = makeStyles(() =>
  createStyles({
    personalStatIcon: {
      fontSize: 17,
      padding: 8,
      borderRadius: 99,
      alignSelf: 'flex-end',
      marginRight: 12,
    },
  })
);

const PERSONAL_STATS_ICONS = (classes: any, theme: Theme) => ({
  hours: (
    <Timer
      className={classes.personalStatIcon}
      style={{
        color: theme.palette.primary.main,
        border: `solid 1px ${theme.palette.primary.main}`,
        background: 'white',
      }}
    />
  ),
  activities: (
    <Today
      className={classes.personalStatIcon}
      style={{
        color: '#E175C6',
        border: `solid 1px ${'#E175C6'}`,
        background: 'white',
      }}
    />
  ),
  locations: (
    <Room
      className={classes.personalStatIcon}
      style={{
        color: theme.palette.secondary.light,
        border: `solid 1px ${theme.palette.secondary.light}`,
        background: 'white',
      }}
    />
  ),
});

type PersonalStatProps = {
  Icon: React.ReactNode;
  value: any;
  label: any;
  difference?: number;
};

const PersonalStat = (props: PersonalStatProps) => {
  const { Icon, value, label, difference } = props;
  const isDifferencePositive = difference && difference > 0;
  const { theme } = useCampfireTheme();
  return (
    <Box
      display='flex'
      flex='1 1 auto'
      alignContent='center'
      alignItems='center'
      paddingBottom={0.75}
      paddingTop={0.75}
    >
      {Icon}
      <span>
        <Typography display='inline' variant='subtitle1' component='p' style={{ fontWeight: 'bold' }}>
          {value}
        </Typography>
        <Typography style={{ marginLeft: 8 }} display='inline' variant='body2' color='textSecondary'>
          {label}
        </Typography>

        {difference ? (
          <Typography
            component='p'
            style={{
              paddingLeft: 8,
              color: isDifferencePositive ? theme.status.green.light : theme.status.red.light,
            }}
            display='inline'
            variant='subtitle2'
          >
            {`(${isDifferencePositive ? '▴' : '▾ '}${difference}%)`}
          </Typography>
        ) : null}
      </span>
    </Box>
  );
};

type PersonalStatsListProps = {
  timeFrameValues: MyElementsSelectTimeFrame[];
  selectedTimeFrame: MyElementsSelectTimeFrame;
  setSelectedTimeFrame: (value: MyElementsSelectTimeFrame) => void;
  hours: number;
  hoursDifference?: number;
  attended: number;
  locations: number;
  initialLoading: boolean;
  impactLoading: boolean;
};

export const MyElementsPersonalImpactBlock = (props: PersonalStatsListProps) => {
  const {
    impactLoading,
    initialLoading,
    timeFrameValues,
    selectedTimeFrame,
    setSelectedTimeFrame,
    hours,
    hoursDifference,
    attended,
    locations,
  } = props;
  const { theme, isMobile } = useCampfireTheme();
  const classes = useStyles();
  const icons = PERSONAL_STATS_ICONS(classes, theme);
  const roundedHours = Math.round(hours * 100) / 100;
  const [openTimeframeSelectPopper, setTimeframeSelectPopperOpen] = useState(false);
  const [timeFrameSelectAnchorElement, setTimeFrameSelectAnchorElement] = useState();
  const firstVisitTooltipAnchorElement = useRef<HTMLDivElement>(null);
  const handleButtonClick = (e: any) => {
    setTimeFrameSelectAnchorElement(e.currentTarget);
    setTimeframeSelectPopperOpen(true);
  };

  return (
    <Grid container direction='row'>
      <Grid container item direction='column' wrap='nowrap'>
        <Grid item>
          <Typography variant='h4'>Your impact</Typography>
        </Grid>
        <Grid container item direction='row' alignItems='center'>
          <Grid item>
            <Typography variant='body2'>{`Here's a summary of your impact for`}</Typography>
          </Grid>
          <Grid item>
            <ArrowPopper
              open={openTimeframeSelectPopper}
              close={() => setTimeframeSelectPopperOpen(false)}
              color={'primary'}
              placement={'bottom'}
              anchorElement={timeFrameSelectAnchorElement}
              arrowSize='small'
              borderStyle='round'
            >
              <Box display='flex' padding={1} paddingX={2}>
                {timeFrameValues.map((value) => {
                  return (
                    <HoverText
                      key={value}
                      onClick={() => {
                        setSelectedTimeFrame(value);
                        setTimeframeSelectPopperOpen(false);
                        setSelectedTimeFrame(value as MyElementsSelectTimeFrame);
                      }}
                      style={{
                        color: 'white',
                        marginLeft: isMobile ? 8 : 16,
                        marginRight: isMobile ? 8 : 16,
                        textDecoration: value === selectedTimeFrame ? 'underline' : undefined,
                      }}
                      variant='body2'
                    >
                      {value}
                    </HoverText>
                  );
                })}
              </Box>
            </ArrowPopper>
            <Grid item>
              <Button
                variant='text'
                style={{ color: theme.palette.primary.main, textDecoration: 'underline', textTransform: 'none' }}
                onClick={(e) => handleButtonClick(e)}
              >
                {`${selectedTimeFrame} ▾`}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ marginTop: 20, minHeight: 150 }} ref={firstVisitTooltipAnchorElement}>
        {impactLoading || initialLoading ? (
          <Grid container direction='column' style={{ marginLeft: 10 }}>
            <Skeleton variant='text' width={196} height={10} />
            <Skeleton variant='text' width={160} height={10} />
            <Skeleton variant='text' width={196} height={10} />
          </Grid>
        ) : (
          <>
            <PersonalStat
              Icon={icons.hours}
              value={roundedHours}
              label={roundedHours === 1 ? 'Hour of volunteering' : 'Hours of volunteering'}
              difference={hoursDifference}
            />
            <PersonalStat
              Icon={icons.activities}
              value={attended}
              label={attended === 1 ? 'Activity attended' : 'Activities attended'}
            />
            <PersonalStat
              Icon={icons.locations}
              value={locations}
              label={locations === 1 ? 'Location visited' : 'Locations visited'}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
