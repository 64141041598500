import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Popover } from '@material-ui/core';
import { MeetingRoom as MeetingRoomIcon, Settings, EditSharp } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { Fragment, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AccountSettingsDialog } from '../../../screens/general/user-profile/account-settings/AccountSettingsDialog';
import { ProfileHoverAvatar } from '../../../screens/general/user-profile/hover-text/ProfileHoverBlock';
import { EditStatusDialog } from '../../../screens/general/user-profile/profile-status/EditStatusDialog';
import { ProfileStatusBrick } from '../../../screens/general/user-profile/profile-status/ProfileStatusBrick';
import { TasksDialog } from '../../../screens/general/user-profile/tasks/TasksDialog';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { useUser } from '../../auth/useUser';
import { usePageNotFoundContext } from '../../page-not-found/PageNotFoundContext';

const useStatusMenuItemStyles = makeStyles(() =>
  createStyles({
    container: {
      cursor: 'pointer',
      position: 'relative',
      maxWidth: '100%',
      width: '100%',
      padding: '8px 12px',
      border: '1px solid #e9e9e9',
      borderRadius: 4,
      boxSizing: 'border-box',

      '&:hover': {
        backgroundColor: '#f1f1f1',
        borderColor: '#d3d3d3',

        '&::after': {
          height: '100%',
          position: 'absolute',
          display: 'flex',
          flex: '1 1 auto',
          alignContent: 'center',
          alignItems: 'center',
          paddingRight: 16,
          paddingLeft: 16,
          bottom: '0',
          right: '0',
          backgroundColor: '#d3d3d3',
          fontSize: 15,
          color: '#282828',
          content: '"Edit status"',
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
        },
      },
    },
  })
);
interface AppBarAvatarMenuProps {
  hideTasksMenuItem?: boolean;
}
export const AppBarAvatarMenu = ({ hideTasksMenuItem }: AppBarAvatarMenuProps) => {
  const { user } = useUser();
  const { avatarUrl } = user;
  const { theme } = useCampfireTheme();
  const avatarButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const openAvatarMenu = () => setShowAvatarMenu(true);
  const closeAvatarMenu = () => setShowAvatarMenu(false);
  const { setPageNotFound } = usePageNotFoundContext();

  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [tasksDialogOpen, setTasksDialogOpen] = useState(false);

  function handleStatusDialogClose() {
    setStatusDialogOpen(false);
  }

  function handleStatusDialogOpen() {
    setStatusDialogOpen(true);
  }

  const statusItemClasses = useStatusMenuItemStyles();

  return (
    <Fragment>
      <AccountSettingsDialog open={settingsMenuOpen} setOpen={setSettingsMenuOpen} />
      <TasksDialog open={tasksDialogOpen} setOpen={setTasksDialogOpen} />
      <EditStatusDialog open={statusDialogOpen} onClose={handleStatusDialogClose} />

      <IconButton ref={avatarButtonRef} onClick={openAvatarMenu}>
        <ProfileHoverAvatar
          alwaysReload
          profileId={user.profileId}
          userId={user.userId}
          preferredName={user.preferredName}
          lastName={user.lastName}
          avatarUrl={user.avatarUrl}
          AvatarProps={{
            size: 28,
            avatarUrl: avatarUrl,
            style: {
              borderStyle: 'solid',
              borderColor: theme.palette.grey[400],
              borderWidth: 2,
            },
          }}
        />
      </IconButton>

      <Popover
        open={showAvatarMenu}
        onClose={closeAvatarMenu}
        anchorEl={avatarButtonRef ? avatarButtonRef.current : undefined}
        anchorReference={'anchorEl'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ elevation: 24 }}
      >
        <Paper>
          <Box width={320}>
            <List disablePadding>
              <ListItem>
                <ListItemText primary={`${user.preferredName} ${user.lastName}`} secondary={user.email} />
              </ListItem>

              <Box
                component='li'
                display='flex'
                flex='1 1 auto'
                justifyContent='center'
                alignContent='center'
                alignItems='center'
                padding={1}
                onClick={handleStatusDialogOpen}
              >
                <ProfileStatusBrick
                  emoji={user.status.emoji}
                  text={user.status.text}
                  className={statusItemClasses.container}
                />
              </Box>

              {hideTasksMenuItem ? null : (
                <ListItem
                  button
                  onClick={() => {
                    setTasksDialogOpen(true);
                    closeAvatarMenu();
                  }}
                >
                  <ListItemIcon>
                    <EditSharp />
                  </ListItemIcon>
                  <ListItemText primary='Tasks' />
                </ListItem>
              )}

              <ListItem
                button
                onClick={() => {
                  setSettingsMenuOpen(true);
                  closeAvatarMenu();
                }}
              >
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary='Account Settings' />
              </ListItem>

              <Link
                to='/sign-out'
                style={{ color: 'inherit', textDecoration: 'none', textDecorationColor: 'none' }}
                onClick={() => setPageNotFound(false)}
              >
                <ListItem button>
                  <ListItemIcon>
                    <MeetingRoomIcon />
                  </ListItemIcon>
                  <ListItemText primary='Sign Out' />
                </ListItem>
              </Link>
            </List>
          </Box>
        </Paper>
      </Popover>
    </Fragment>
  );
};
