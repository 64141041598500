import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: 'white',
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
      paddingLeft: '1.875rem',
      marginTop: '2.25rem',
    },
  })
);

export function RolePanel({ children }: Props) {
  const classes = useStyles();
  return <Box className={classes.container}>{children}</Box>;
}
