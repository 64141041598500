import { Grid } from '@material-ui/core';
import React, { memo } from 'react';
import { ActivityTimelineSelected } from './ActivityTimelineSelected';
import { ActivityTimelineSidebar } from './sidebar/ActivityTimelineSidebar';
import { PastRostersSkeletonLoadingLayout } from './past/roster/ActivityTimelinePastRoster';
import { useActivityTimelineContext } from './ActivityTimelineContext';

export interface ActivityTimelineProps {
  activities: {
    activityId: string;
  }[];
  returnSlug: string;
}

export const ActivityTimeline = memo(({ activities, returnSlug }: ActivityTimelineProps) => {
  const { sidebarIsLoading } = useActivityTimelineContext();
  return activities.length > 0 ? (
    <Grid container style={{ flexWrap: 'nowrap', display: 'flex', flex: 1, overflow: 'hidden' }}>
      <Grid item style={{ display: 'flex', flex: '0 1 auto' }}>
        <ActivityTimelineSidebar activities={activities} />
      </Grid>
      {sidebarIsLoading ? (
        <Grid item xs={12}>
          <PastRostersSkeletonLoadingLayout />
        </Grid>
      ) : (
        <Grid item xs style={{ display: 'flex', flex: '1 1 auto', overflowY: 'hidden', overflowX: 'hidden' }}>
          <ActivityTimelineSelected returnSlug={returnSlug} />
        </Grid>
      )}
    </Grid>
  ) : null;
});
