import { LinearProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { FullPageMessage } from '../../../common/FullPageMessage';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { PublicScreenWrapper } from '../PublicScreenWrapper';
import { GET_APPLICATION_SCREEN } from './application-screen-model.gql';
import { ApplicationScreenApprovalProcessing } from './ApplicationScreenApprovalProcessing';
import { ApplicationScreenEmailSent } from './ApplicationScreenEmailSent';
import { ApplicationScreenForm } from './ApplicationScreenForm';
import { ApplicationScreenSkeleton } from './ApplicationScreenSkeleton';
import { GetApplicationScreen, GetApplicationScreenVariables } from './__generated__/GetApplicationScreen';

const ApplyScreen = () => {
  const [inviteParam] = useQueryParam('inviteeId', StringParam);
  const [givenEmail, setGivenEmail] = useState<string | undefined>();
  const [givenName, setGivenName] = useState<string | undefined>();
  const [givenPassword, setGivenPassword] = useState<string | undefined>();
  const [approvalProcessing, setApprovalProcessing] = useState<boolean | undefined>();
  const stateController = {
    givenEmail,
    setGivenEmail,
    givenName,
    setGivenName,
    givenPassword,
    setGivenPassword,
    approvalProcessing,
    setApprovalProcessing,
  };

  const { data: getResponse, loading: isGetLoading, error: getError } = useCampfireQuery<
    GetApplicationScreen,
    GetApplicationScreenVariables
  >(GET_APPLICATION_SCREEN, {
    options: {
      variables: {
        inviteeId: inviteParam,
      },
    },
  });

  return (
    <PublicScreenWrapper floating hideHeader>
      {isGetLoading ? (
        <Box minHeight={430}>{isGetLoading ? <ApplicationScreenSkeleton /> : <LinearProgress />}</Box>
      ) : (
        <Box minHeight={200}>
          {getError ? (
            <FullPageMessage
              title='Unable to load application form'
              message='We are having trouble fetching the application form.'
            />
          ) : getResponse && approvalProcessing === false ? (
            <ApplicationScreenEmailSent orgName={getResponse.orgName} stateController={stateController} />
          ) : getResponse && approvalProcessing === true ? (
            <ApplicationScreenApprovalProcessing orgName={getResponse.orgName} stateController={stateController} />
          ) : getResponse ? (
            <ApplicationScreenForm
              getResponse={getResponse}
              stateController={stateController}
              inviteParam={inviteParam}
            />
          ) : null}
        </Box>
      )}
    </PublicScreenWrapper>
  );
};

export { ApplyScreen };
