import React from 'react';
import { unpackToDate, unpackToTime } from '@campfire/hot-date';
import { Box, Theme, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ActivityItem } from './types';

export interface ActivityCardProps extends ActivityItem {
  onSelectActivity: () => void;
  selected: boolean;
  showAction?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'stretch',
      borderRadius: 8,
      backgroundColor: theme.color.white.neutral50,
      border: ({ selected }: { selected: boolean }) => `1px solid ${selected ? 'lightgrey' : '#F4F0ED'}`,
      overflow: 'hidden',
      cursor: 'pointer',
    },
    dateContainer: {
      minWidth: 64,
      width: 120,
      paddingTop: 14,
      paddingBottom: 14,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.color.grey.neutralBrand100,
    },
    actionContainer: {
      minWidth: 48,
      width: 48,
      paddingTop: 14,
      paddingBottom: 14,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.color.grey.neutralBrand100,
    },
    date: {
      fontSize: 30,
      fontWeight: 900,
      color: theme.color.grey.neutralBrand300,
    },
    month: {
      fontSize: 14,
      fontWeight: 700,
      color: theme.color.grey.neutral200,
    },
    name: {
      fontSize: 20,
      fontWeight: 900,
      color: theme.color.grey.neutral400,
    },
    time: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.color.grey.neutral200,
    },
    iconAction: {
      color: theme.color.grey.neutral300,
    },
    infoContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 18,
      justifyContent: 'center',
    },
  })
);

export function ActivityCard(props: ActivityCardProps) {
  const { activityDate, name, startTime, endTime, onSelectActivity, selected, showAction } = props;
  const date = unpackToDate(activityDate);
  const startTimeStr = startTime ? unpackToTime(startTime).toFormat('h:mm a') : '';
  const endTimeStr = endTime ? unpackToTime(endTime).toFormat('h:mm a') : '';

  const classes = useStyles({ selected });

  return (
    <Box onClick={onSelectActivity} className={classes.container}>
      <Box className={classes.dateContainer}>
        <Typography className={classes.date}>{date.day}</Typography>
        <Typography className={classes.month}>{date.monthShort}</Typography>
      </Box>
      <Box className={classes.infoContainer}>
        <Typography className={classes.name}>{name}</Typography>
        <Typography className={classes.time}>
          {date.weekdayLong} / {startTimeStr} - {endTimeStr}
        </Typography>
      </Box>
      {showAction && (
        <Box className={classes.actionContainer}>
          <ChevronRight className={classes.iconAction} />
        </Box>
      )}
    </Box>
  );
}
