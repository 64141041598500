import React, { useContext } from 'react';
import { get, isString, lowerCase, upperFirst } from 'lodash';
import { WidgetContainer, WidgetContent } from './WidgetContainer';
import { WidgetHeader } from './WidgetHeader';
import { ChartType } from './chart-widget/Chart';
import { GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents_widget_VOLUNTEER_ChartWidgetType as IWidget } from '../dashboard-widget-setting/__generated__/GetDashboardPreference';
import { DashboardLineChart } from './chart-widget/LineChart';
import { DashboardPieChart } from './chart-widget/PieChart';
import { DashboardBarChart } from './chart-widget/BarChart';
import { DashboardAreaChart } from './chart-widget/AreaChart';
import { DashboardColumnChart } from './chart-widget/ColumnChart';
import { DimensionEnum, valueMap } from '../dashboard-widget-setting/dashboard-widget-model';
import { WidgetDisplayContext } from '../WidgetDisplayContext';

type ChartWidgetProps = {
  widget: IWidget;
  showHighlight?: boolean;
};

export function ChartWidget({ widget }: ChartWidgetProps) {
  const { targets, filters } = widget;
  const { reportFields } = useContext(WidgetDisplayContext);

  const {
    configuration: { dimension, interval, range },
  } = widget;

  const title = React.useMemo(() => {
    const parsedTargets = JSON.parse(targets);
    return parsedTargets
      .map(({ tag, name }: { tag: string; name: string }) =>
        get(valueMap, [tag.toLowerCase(), name.toLowerCase(), 'displayName'], '')
      )
      .join(' & ');
  }, [targets]);

  const isFieldValue = React.useMemo(() => {
    const parsedTargets = JSON.parse(targets);
    return parsedTargets.some(
      ({ name, tag }: { name: string; tag: string }) => tag === 'reporting' && name === 'report-field-value'
    );
  }, [targets]);

  const title2 = React.useMemo(() => {
    const { fieldIds }: { fieldIds: string[] } = JSON.parse(filters);

    if (!isFieldValue || !fieldIds || fieldIds.length === 0) {
      return '';
    }

    return fieldIds.map((fieldId) => reportFields[fieldId]).join(' & ');
  }, [filters, reportFields, isFieldValue]);

  const subTitle = React.useMemo(() => {
    const rangeString = upperFirst(lowerCase(range));
    const intervalString = isString(interval) ? `, ${lowerCase(interval)}` : '';
    const groupString =
      dimension === DimensionEnum.BY_TIME
        ? ''
        : `, per ${lowerCase(dimension)
            .split(' ')
            .slice(1)
            .map(upperFirst)
            .join()}`;
    return rangeString + intervalString + groupString;
  }, [range, dimension, interval]);

  return (
    <WidgetContainer>
      <WidgetHeader
        title={title}
        title2={title2}
        subTitle={subTitle}
        type={`Chart > ${upperFirst(lowerCase(widget.configuration.chartType))}`}
      />
      <WidgetContent>
        {(() => {
          switch (widget.configuration.chartType) {
            case ChartType.LINE:
              return <DashboardLineChart widget={widget} />;
            case ChartType.PIE:
              return <DashboardPieChart widget={widget} />;
            case ChartType.BAR:
              return <DashboardBarChart widget={widget} />;
            case ChartType.AREA:
              return <DashboardAreaChart widget={widget} />;
            // case ChartType.SCATTER:
            // return <DashboardScatterChart widget={widget} />;
            case ChartType.COLUMN:
              return <DashboardColumnChart widget={widget} />;
            default:
              break;
          }
          return null;
        })()}
      </WidgetContent>
    </WidgetContainer>
  );
}
