import React, { memo } from 'react';
import { List, ListItemText, ListItem, ListSubheader } from '@material-ui/core';
import { SectionCollapsible } from '../common/SectionCollapsible';
import { SectionHeader } from '../common/SectionHeader';
import { useVolunteerCommonProfileContext } from '../VolunteerCommonProfileContext';
import { SectionInstruction } from '../common/SectionInstruction';

export const VolunteerProgramsSection = memo(
  ({ programs }: { programs: Array<{ programId: string; name: string }> }) => {
    const {
      volunteerNameOptions: { preferredName },
    } = useVolunteerCommonProfileContext();
    return (
      <SectionCollapsible
        sectionHeader={<SectionHeader title='Programs' />}
        instruction={<SectionInstruction>{`All programs that ${preferredName} is a part of`}</SectionInstruction>}
        collapseContent={
          <List disablePadding dense>
            <ListSubheader>{`${preferredName}'s programs`}</ListSubheader>
            {programs.map((program) => (
              <ListItem key={program.programId}>
                <ListItemText primary={program.name} />
              </ListItem>
            ))}
          </List>
        }
        expandText='View programs'
        idStub='programs'
      />
    );
  }
);
