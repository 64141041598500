import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, Typography } from '@material-ui/core';
import { CheckRounded, SentimentVeryDissatisfiedRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { PublicScreenWrapper } from '../../screens/public-screens/PublicScreenWrapper';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { useSnackbar } from '../config/useSnackbar';
import { useVerifyEmailFetch } from './static-shell-actions';

export const EmailVerifiedLanding = () => {
  const [hash] = useQueryParam('h', StringParam);
  const [email] = useQueryParam('e', StringParam);
  const history = useHistory();

  const [emailVerified, setEmailVerified] = useState<undefined | boolean>(false);
  const verifyEmailFetch = useVerifyEmailFetch();
  const { setSnackbar } = useSnackbar();

  const runVerifyEmail = () => {
    if (!hash || !email) return;
    verifyEmailFetch
      .run({ hash, email })
      .then((response) => {
        if (!response.ok) {
          setEmailVerified(false);
          setSnackbar({
            open: true,
            message: 'Unable to verify email',
            variant: 'error',
          });
          return;
        }

        setEmailVerified(true);
        setSnackbar({
          open: true,
          message: 'Email verified',
          variant: 'success',
        });
      })
      .catch(() => {
        setEmailVerified(false);
        setSnackbar({
          open: true,
          message: 'Unable to verify email',
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    runVerifyEmail();
  }, []);

  const { isMobile } = useCampfireTheme();

  return (
    <PublicScreenWrapper floating>
      {verifyEmailFetch.isLoading ? (
        <Box minHeight={290}>
          <CircularProgressOverlay opacity={1} isLoading />
        </Box>
      ) : null}

      {emailVerified === true ? (
        <Box paddingTop={2} paddingBottom={isMobile ? 0 : 8}>
          <Grid justify='center' container style={{ flexGrow: 1 }}>
            <Grid item style={{ padding: 32 }}>
              <CheckRounded
                style={{
                  fontSize: 48,
                  color: '#00ED7E',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={10} style={{ textAlign: 'center' }}>
              <Typography variant='h4'>{'Your email has been verified'}</Typography>
              <Box maxWidth={400} marginTop={2} display='inline-block'>
                <Typography variant='body2' color='textSecondary'>
                  {`You can now continue your journey!`}
                </Typography>

                <Box marginTop={3}>
                  <TabletButton
                    color='primary'
                    variant='contained'
                    size='medium'
                    onClick={() => {
                      history.replace(`/sign-in?email=${email}`);
                      // eslint-disable-next-line no-undef
                      FS.event('click email veriried sign in');
                    }}
                  >
                    Sign In
                  </TabletButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : emailVerified === false && !verifyEmailFetch.isLoading ? (
        <Box paddingTop={2} paddingBottom={isMobile ? 0 : 8}>
          <Grid justify='center' container style={{ flexGrow: 1 }}>
            <Grid item style={{ padding: 32 }}>
              <SentimentVeryDissatisfiedRounded
                style={{
                  fontSize: 48,
                  color: '#ED8000',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={10} style={{ textAlign: 'center' }}>
              <Typography variant='h4'>{'Something Went Wrong'}</Typography>
              <Box maxWidth={400} marginTop={2} display='inline-block'>
                <Typography variant='body2' color='textSecondary'>
                  {`We weren't able to verify your email address at this time. Please try again later, or resend the email and try again with a new link.`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </PublicScreenWrapper>
  );
};
