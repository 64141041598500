import { DialogContentText } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useCampfireLazyQuery } from '../../../../../../../global/network/useCampfireLazyQuery';
import { GET_ADD_VOLUNTEERS_DIALOG_VOLUNTEERS } from '../../../activity-timeline-model.gql';
import {
  GetAddVolunteersDialogVolunteers,
  GetAddVolunteersDialogVolunteersVariables,
} from '../../../__generated__/GetAddVolunteersDialogVolunteers';
import { MultiSelectVolunteersDialog, MultiSelectVolunteersDialogProps } from './MultiSelectVolunteersDialog';

interface AddVolunteersDialogProps {
  open: boolean;
  onClose: (event: any) => void;
  onSubmit: MultiSelectVolunteersDialogProps['onSubmit'];
  programId: string;
  ignoreVolunteerIds: Array<string>;
  dialogContentText: string;
  maxSelected?: number | null;
}

const AddVolunteersDialog = (props: AddVolunteersDialogProps) => {
  const { programId, dialogContentText, ...rest } = props;
  const [run, query] = useCampfireLazyQuery<
    GetAddVolunteersDialogVolunteers,
    GetAddVolunteersDialogVolunteersVariables
  >(GET_ADD_VOLUNTEERS_DIALOG_VOLUNTEERS);

  useEffect(() => {
    if (programId) run({ variables: { programId: programId } });
  }, [programId]);

  return (
    <MultiSelectVolunteersDialog
      {...rest}
      title='Add Volunteers'
      isLoading={query.loading || query.refetchLoading}
      volunteers={query.data?.vm.program?.activeVolunteers ?? []}
      showSearchField
    >
      <DialogContentText>{`${dialogContentText}`}</DialogContentText>
    </MultiSelectVolunteersDialog>
  );
};

export { AddVolunteersDialog };
