import { DirectionsRun as DirectionsRunIcon, Whatshot as WhatshotIcon } from '@material-ui/icons';
import React, { ErrorInfo, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FullPageMessage } from '../../common/FullPageMessage';
import { SessionContext } from '../auth/SessionContext';
import { Page } from '../components';
import { CampfireVersionContextInterface } from '../config/CampfireVersionContext';
import { getApiUrlFromWindowLocation } from '../config/get-api-url';
import { writeErrorToLog } from '../logging/LogService';

interface State {
  error: Error | null;
  errorInfo: any | null;
}

export interface AppErrorBoundaryComponentProps extends RouteComponentProps {
  versionContext: CampfireVersionContextInterface;
}

class AppErrorBoundaryComponent extends React.Component<AppErrorBoundaryComponentProps, State> {
  constructor(props: AppErrorBoundaryComponentProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const sessionContext = this.context;
    if (sessionContext) {
      const { history, match, versionContext } = this.props;
      const url = getApiUrlFromWindowLocation();
      writeErrorToLog({
        error,
        errorInfo,
        history,
        match,
        url,
        versionContext,
        sessionContext,
      });
    }
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (!this.state.errorInfo) {
      return this.props.children;
    }

    if (this.props.versionContext.updateAvailable) {
      window.location.reload();
      return null;
    }

    return (
      <Page>
        <FullPageMessage
          Icon={() => (
            <Fragment>
              <WhatshotIcon color='secondary' />
              <DirectionsRunIcon />
            </Fragment>
          )}
          title={'This is the wrong kind of fire.'}
          subtitle={`Something has caused Volaby to stop working. We've been alerted to the issue and are looking into it ASAP.`}
        />
      </Page>
    );
  }
}

AppErrorBoundaryComponent.contextType = SessionContext;

const AppErrorBoundary = withRouter(AppErrorBoundaryComponent);

export default AppErrorBoundary;
