import {useApiUrl} from '../config/useApiUrl';
import {useCampfireFetch} from '../network/useCampfireFetch';

export const recordInteraction = (context: 'vm' | 'im') => {
  const { run, response, error } = useCampfireFetch({
    defer: true,
  });
  const apiUrl = useApiUrl();
  const postInteraction = (page: string, action: string) => {
      return run({
          url: `${apiUrl}/${context}/user/interaction`,
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
          },
          data: {
              page,
              action,
          },
      });
  };

    return {
        postInteraction,
        response,
        error,
    };
};

export const recordInteractionMaybeAuth = (context: 'vm' | 'im') => {
    const {run, response, error} = useCampfireFetch({
        defer: true,
        withAuth: false,
    });

    const apiUrl = useApiUrl();
    const postInteraction = (page: string, action: string, token: string) => {
        return run({
            url: `${apiUrl}/${context}/user/interaction`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}` ?? '',
            },
            data: {
                page,
                action,
            },
        });
    };

    return {
        postInteraction,
        response,
        error,
    };
};
