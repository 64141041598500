import EmojiClappingHands from '../../assets/emojis/emoji_clapping_hands.svg';
import EmojiCtyingFace from '../../assets/emojis/emoji_crying_face.svg';
import EmojiGrinningFace from '../../assets/emojis/emoji_grinning_face.svg';
import EmojiHeartEyes from '../../assets/emojis/emoji_heart_eyes.svg';
import EmojiOpenMouthFace from '../../assets/emojis/emoji_open_mouth_face.svg';
import EmojiRedHeart from '../../assets/emojis/emoji_red_heart.svg';
import EmojiSmilingSunglasses from '../../assets/emojis/emoji_smiling_sunglasses.svg';
import { useApiUrl } from '../../global/config/useApiUrl';
import { useCampfireFetch } from '../../global/network/useCampfireFetch';
import { VOLUNTEER_EmojiEnumType as Emoji } from '../../__generated__/globalTypes';

export const emojiLists = [
  {
    value: Emoji.clap,
    icon: EmojiClappingHands,
  },
  {
    value: Emoji.heart,
    icon: EmojiRedHeart,
  },
  {
    value: Emoji.grinning,
    icon: EmojiGrinningFace,
  },
  {
    value: Emoji.sunglasses,
    icon: EmojiSmilingSunglasses,
  },
  {
    value: Emoji.heart_eyes,
    icon: EmojiHeartEyes,
  },
  {
    value: Emoji.cry,
    icon: EmojiCtyingFace,
  },
  {
    value: Emoji.open_mouth,
    icon: EmojiOpenMouthFace,
  },
];

export interface ReactionType {
  reactionId: string;
  emoji: string;
  volunteer: {
    volunteerId: string;
  };
}

export const useSaveReaction = () => {
  const uri = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  return ({
    reactionId,
    bulletinId,
    commentId,
    emoji,
  }: {
    reactionId?: string;
    bulletinId?: string;
    commentId?: string;
    emoji: string;
  }) =>
    campfireMutation.run({
      url: `${uri}/vm/reaction/save`,
      method: 'POST',
      data: {
        reactionId,
        bulletinId,
        commentId,
        emoji,
      },
    });
};
