import { Box, Typography, Select, MenuItem, Checkbox } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import React, { memo, useState } from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import { BorderSelectInput } from '../../../../common/filter-fields/FilterClasses';
import { useUser } from '../../../../global/auth/useUser';
import { GetAllActivityActivityTags_vm_activityTags as ActivityTagType } from '../ActivityQueries/__generated__/GetAllActivityActivityTags';

const SELECT_NONE_VALUE = 'none';
const SELECT_ALL_VALUE = 'all';
const textFieldStyle = makeStyles(() =>
  createStyles({
    input: {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontSize: '14px',
      height: '19px',
    },
    root: {
      fontSize: '12px',
      borderRadius: '6px',
      borderTopColor: '#9E9E9E',
      borderRightColor: '#9E9E9E',
      borderBottomColor: '#9E9E9E',
      borderLeftColor: '#9E9E9E',
      color: '#444444',
    },
    adornedStart: {
      paddingLeft: '0.75rem',
    },
    menuItem: {
      margin: '4px 0',
      minWidth: 200,
      height: 35,
      '&.Mui-selected': {
        backgroundColor: 'transparent',
      },
    },
  })
);
type Props = {
  activityTags: ActivityTagType[];
  selectedTagIds: { [key: string]: boolean };
  onChange: (newValue: string[]) => void;
};

const ActivityTagSelect = memo(({ activityTags, selectedTagIds, onChange }: Props) => {
  const classes = textFieldStyle();
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const {
    user: { userIdentityService },
  } = useUser();
  const userIsAdmin = userIdentityService.isVmAdmin;
  const userIsProgramManager = userIdentityService.isProgramManager;
  const userIsActivityLeader = userIdentityService.isActivityLeader;

  const isTagSelected = (id: string) => Boolean(selectedTagIds[id]);
  const isShowTagHidden = userIsAdmin || userIsProgramManager || userIsActivityLeader;

  const onSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    event.stopPropagation();
    const newValues = event.target.value as string[];
    const includesSelectNone = (event.target.value as string[]).includes(SELECT_NONE_VALUE);
    const includesSelectAll = (event.target.value as string[]).includes(SELECT_ALL_VALUE);
    if (includesSelectNone) {
      onChange([]);
      return;
    }
    if (includesSelectAll) {
      onChange(activityTags.map((tag) => tag.activityTagId));
      return;
    }
    onChange(newValues);
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'row' }}>
      <Select
        name='program'
        variant='outlined'
        fullWidth
        multiple
        displayEmpty
        open={popoverOpen}
        inputProps={{ classes: classes }}
        input={
          <BorderSelectInput
            onClick={() => setPopoverOpen(!popoverOpen)}
            style={{ cursor: 'pointer', paddingTop: '6px', paddingBottom: '6px' }}
          />
        }
        IconComponent={() => (
          <Box
            display='flex'
            alignItems='center'
            mr={'4px'}
            onClick={() => setPopoverOpen(true)}
            style={{ cursor: 'pointer' }}
          >
            <ArrowDropDown
              style={{
                fill: 'rgba(0,0,0, 0.54)',
              }}
            />
          </Box>
        )}
        renderValue={() => {
          const countSelected = Object.entries(selectedTagIds).filter((a) => a[1]).length;
          if (countSelected >= activityTags.length) return 'All Activity Tags';
          if (countSelected === 0) return 'None of Activity Tag';

          return activityTags
            .filter((t) => selectedTagIds[t.activityTagId])
            .map((t) => t.name)
            .join(', ');
        }}
        onChange={onSelect}
        value={Object.entries(selectedTagIds).map((t) => t[0])}
        style={{ fontWeight: 'normal', cursor: 'pointer' }}
        margin='dense'
      >
        <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE} className={classes.menuItem}>
          <Typography variant='body2'>{'Select All'}</Typography>
        </MenuItem>
        <MenuItem key={SELECT_NONE_VALUE} value={SELECT_NONE_VALUE} className={classes.menuItem}>
          <Typography variant='body2'>{'Deselect All'}</Typography>
        </MenuItem>
        {activityTags.map(({ activityTagId, name, isHidden }) =>
          !isHidden || (isHidden && isShowTagHidden) ? (
            <MenuItem key={activityTagId} value={activityTagId} className={classes.menuItem}>
              <Checkbox
                color='primary'
                value={activityTagId}
                checked={isTagSelected(activityTagId)}
                style={{ paddingLeft: 0 }}
              />
              <Typography variant='body2'>{name}</Typography>
            </MenuItem>
          ) : null
        )}
      </Select>
    </Box>
  );
});

export { ActivityTagSelect };
