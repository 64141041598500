import { unpackToDateTimeTZ } from '@campfire/hot-date';
import { capitalize } from 'lodash';
import { IncidentType } from './incident-management-types';
import { escapeDangerousCSVCharacters, replaceDoubleQuoteInString } from '../../../common/functions/csv-utils';
import { IncidentManagementGetIncidents_vm_incidentReports as IncidentReports } from './__generated__/IncidentManagementGetIncidents';
import { IncidentManagementSingleIncidentDetail } from './__generated__/IncidentManagementSingleIncidentDetail';

export const getIncidentDetailCategoryTree = (detail: IncidentManagementSingleIncidentDetail) => {
  const incidentDetailCategoryTree = [];
  incidentDetailCategoryTree.push(detail.label);
  incidentDetailCategoryTree.push(detail.incidentCategory.label);

  if (!detail.incidentCategory.incidentCategoryGroup) return incidentDetailCategoryTree.reverse();
  incidentDetailCategoryTree.push(detail.incidentCategory.incidentCategoryGroup.label);

  let parent: any = detail.incidentCategory.incidentCategoryGroup.parentGroup;
  while (parent && parent.parentGroup) {
    incidentDetailCategoryTree.push(parent.label);
    parent = parent.parentGroup;
  }
  return incidentDetailCategoryTree.reverse();
};

export const filterByProgram = (programId: string) => (incident: any) =>
  incident.programId === programId || programId === 'all';

export const filterByActivityType = (type: string) => (incident: any) =>
  (incident.incidentLocationType === 'VOLUNTEER_ActivityIncidentLocationType' && type === 'in-activity') ||
  (incident.incidentLocationType === 'VOLUNTEER_AddressIncidentLocationType' && type === 'outside-activity') ||
  type === 'all';

export const filterBySeverity = (severityId: string) => (incident: any) =>
  incident.incidentSeverityId === severityId || severityId === 'all';

export const filterByDay = (daysSelected: number[] | undefined) => (incident: any) => {
  if (!daysSelected) return true;
  const dayOfIncident = unpackToDateTimeTZ(incident.incidentDateTime).weekday;
  return daysSelected.some((daySelected) => daySelected === dayOfIncident - 1);
};

export const filterBySearch = (value?: string) => (incident: any) => {
  if (!value) return true;
  return (
    incident.incidentTitle.toLowerCase().includes(value.toLowerCase().trim()) ||
    incident.programName.toLowerCase().includes(value.toLowerCase().trim())
  );
};

export const sortBySubmissionDateTime = (incidentOne: IncidentType, incidentTwo: IncidentType) => {
  const submissionOne = unpackToDateTimeTZ(incidentOne.submissionDateTime);
  const submissionTwo = unpackToDateTimeTZ(incidentTwo.submissionDateTime);
  return submissionOne > submissionTwo ? -1 : 1;
};

export const buildRowCsv = (incidentReport: IncidentReports, separator = ',') => {
  const {
    incidentDateTime,
    submissionDateTime,
    incidentLocation,
    submittedBy,
    severity,
    volunteersInvolved,
    othersInvolved,
    description,
    furtherAction,
    detail,
    referenceNumber,
    category,
  } = incidentReport;

  const displayAddress =
    incidentLocation?.__typename === 'VOLUNTEER_ActivityIncidentLocationType'
      ? incidentLocation.activity.activityLocation?.placesAddress.description
      : '';

  const incidentDateTimeFormated = `${unpackToDateTimeTZ(incidentDateTime).toFormat('dd/MM/y h:mm')}`;
  const { incidentStatus } = incidentReport.incident;
  const incidentGroup = detail.incidentCategory.incidentCategoryGroup.label;
  const volunteersInvolvedName = volunteersInvolved.map(
    (vol) => `${capitalize(vol.profile.preferredName)} ${capitalize(vol.profile.lastName)}`
  );
  const peopleInvolved = `${volunteersInvolvedName.join(',')}${
    volunteersInvolvedName && volunteersInvolvedName.length > 0 ? ',' : ''
  }${othersInvolved.join(',')}`;
  const submittedByName = `${capitalize(submittedBy?.profile.preferredName)} ${capitalize(
    submittedBy?.profile.lastName
  )}`;
  const submittedAt = `${unpackToDateTimeTZ(submissionDateTime).toFormat('dd/MM/y h:mm')}`;

  const arrayRowData = [
    referenceNumber ?? '',
    incidentDateTimeFormated ?? '',
    severity.name ?? '',
    incidentStatus ?? '',
    displayAddress ?? '',
    incidentGroup ?? '',
    category.label ?? '',
    detail.label ?? '',
    description ?? '',
    furtherAction ?? '',
    peopleInvolved ?? '',
    submittedByName ?? '',
    submittedAt ?? '',
  ];

  return `"${arrayRowData
    .map((columnData) => escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
    .join(`"${separator}"`)}"`;
};

export const buildCsvData = (rowsCsv: string[], separator = ',') => {
  const csvHeaderArr = [
    'Reference Number',
    'Incident Date Time',
    'Severity',
    'Status',
    'Location',
    'Incident Group',
    'Incident Category',
    'Incident Detail',
    'Incident Description',
    'Further Action',
    'People Involved',
    'Submitted By',
    'Submitted At',
  ];

  const rowHeader = `"${csvHeaderArr.join(`"${separator}"`)}"`;

  const data = [rowHeader, ...rowsCsv];
  return `${data.join('\r\n')}`;
};
