import { Form, Formik } from 'formik';
import React, { memo } from 'react';
import { useManageReportTypeContext, ManageReportTypeProvider } from './ManageReportTypeContext';
import { ValidationSchema } from './manage-report-type-model';
import { ManageReportTypePage } from './ManageReportTypePage';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ManageReportTypePageMobile } from '../manage-report-type-mobile/ManageReportTypePageMobile';

const ManageReportTypeScreen = () => {
  return (
    <ManageReportTypeProvider>
      <ManageReportTypeScreenInner />
    </ManageReportTypeProvider>
  );
};

const ManageReportTypeScreenInner = memo(() => {
  const { initialValues, runSaveReportType } = useManageReportTypeContext();
  const { isXs, isSm } = useCampfireTheme();
  const compact = isXs || isSm;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values) => runSaveReportType(values)}
    >
      <Form style={{ height: '100%', width: '100%' }}>
        {compact ? <ManageReportTypePageMobile /> : <ManageReportTypePage />}
      </Form>
    </Formik>
  );
});

export { ManageReportTypeScreen };
