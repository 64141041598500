import { gql } from '@apollo/client';
import { VolunteerEnrolmnentFragments } from './volunteerEnrolmentFragments.gql';
import { VolunteerPublishedRosterFragments } from './volunteerPublishedRostersFragments.gql';

export const ACTIVITIES_GET_VOLUNTEER_DATA = gql`
  query ActivitiesVolunteerData($volunteerId: String!) {
    vm {
      allowCICO
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        activityEnrolments {
          ...VolunteerEnrolments
        }
        rosterings {
          ...VolunteerRosters
        }
        activityApplications {
          ...ActivityApplicationFragments
        }
        activityWaitlistings {
          ...ActivityWaitlistingFragments
        }
        CICOs {
          ...VolunteerCICOsFragment
        }
      }
    }
  }
  fragment ActivityApplicationFragments on VOLUNTEER_ActivityApplicationType {
    activity {
      activityId
      name
      startTime
      endTime
    }
    activityApplicationId
    dateApplied
    dateWithdrawn
    dateActioned
  }
  fragment ActivityWaitlistingFragments on VOLUNTEER_ActivityWaitlistingType {
    activity {
      activityId
      name
      startTime
      endTime
    }
    activityWaitlistingId
    dateAdded
    dateRemoved
  }
  fragment VolunteerCICOsFragment on VOLUNTEER_CICOType {
    CICOId
    checkIn
    checkOut
    sessionReport {
      session {
        sessionId
      }
      activityReport {
        activityDate
        activity {
          activityId
        }
      }
    }
  }
  ${VolunteerEnrolmnentFragments}
  ${VolunteerPublishedRosterFragments}
`;
