import React, { FunctionComponent, ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';

const InfoBlock: FunctionComponent<{ value: ReactNode; label: ReactNode }> = ({ value, label }) => {
  return (
    <Grid item>
      <Typography variant='caption' color='textSecondary'>
        {label}
      </Typography>
      <Typography variant='body2'>{value}</Typography>
    </Grid>
  );
};

export { InfoBlock };
