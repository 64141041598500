import { Field } from '@campfire/field';
import { HoverText } from '@campfire/hover-link';
import { Collapse, Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';
import { ReportTypeFormValues } from './report-type-form-types';

export const ReportTypeFormBasicInfoSection = () => {
  const [showDescription, setShowDescription] = useState(false);
  const formik = useFormikContext<ReportTypeFormValues>();
  const hasDescription = !!formik.values?.reportTypeDescription;
  const collapseModeIn = showDescription || hasDescription;
  return (
    <FormSectionV2
      title='Basics'
      description={
        <Typography variant='body2' display='inline' color='textSecondary'>
          Choose a unique and descriptive name for the Report Type
        </Typography>
      }
    >
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12}>
          <Field required name='reportTypeName' label='Report Type Name' variant='outlined' helper fullWidth />
        </Grid>
        <Grid item>
          <Collapse in={collapseModeIn} timeout={0}>
            <Field
              name='reportTypeDescription'
              multiline
              rows={6}
              variant='outlined'
              label='Description (Optional)'
              margin='normal'
              fullWidth
              helper
            />
          </Collapse>
          {!collapseModeIn ? (
            <HoverText variant='body2' color='primary' onClick={() => setShowDescription(true)}>
              Add a description
            </HoverText>
          ) : null}
        </Grid>
      </Grid>
    </FormSectionV2>
  );
};
