import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

export const SUYPLimbo = ({ preferredName }: { preferredName?: string }) => {
  const { isMobile } = useCampfireTheme();
  return (
    <Box width={1} height={1} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
      <Box width={1} maxWidth={720} minHeight={360} paddingX={4} textAlign={isMobile ? 'center' : 'start'}>
        <Typography variant='h5' style={{ fontWeight: 'bold' }} gutterBottom>
          <span role='img' aria-label='yay' style={{ marginRight: 16 }}>
            🎉
          </span>
          {preferredName ? `Cheers, ${preferredName}` : `Volunteer profile completed`}
        </Typography>
        <Typography variant='body2'>Your volunteer profile has been received and is awaiting review!</Typography>
        <Typography variant='body2'>{`We'll email you as soon as a manager has looked at your profile.`}</Typography>
      </Box>
    </Box>
  );
};
