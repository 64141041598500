import { gql } from '@apollo/client';

export const GET_TENANT_CONFIG = gql`
  query GetTenantConfig {
    tm {
      tenant {
        name
        config {
          logo
          communication {
            signoff
            greeting
          }
        }
      }
    }
  }
`;
