import { unpackToDateTime } from '@campfire/hot-date';
import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid';
import { CommonRequiringActionWidgetProps } from '../../../shared';
import { VolunteerProfile } from '../../../../../../../../../common/AvatarName';
import { GET_WIDGET_ACTIVITY_APPLICATIONS } from './activity-application-widget-model.gql';
import { useCampfireQuery } from '../../../../../../../../../global/network/useCampfireQuery';
import {
  GetWidgetActivityApplications,
  GetWidgetActivityApplicationsVariables,
} from './__generated__/GetWidgetActivityApplications';
import { ActivityNameAndPriority } from '../../../../../../../../program-manager/volunteers/common/ActivityInfo';
import {
  ApplicationActions,
  VolunteerApplicationMenuButton,
} from '../../../../../../../../program-manager/volunteers/volunteers-applications/VolunteerApplicationsMenu';
import { useActivityApplicationActions } from './ActivityApplicationsActions';
import { ApplicationRemovingMessage } from '../../../../../../../../program-manager/volunteers/volunteers-applications/ApplicationRemovingMessage';
import { Application } from '../../../../../../../../program-manager/volunteers/volunteers-applications/VolunteersApplicationsScreen';
import { WidgetTypes } from '../../../../dashboard-widget-setting/dashboard-widget-model';

export const ActivityApplicationRAWidget = (props: CommonRequiringActionWidgetProps) => {
  const { filters, range } = props;
  const { programIds, activityIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };
  const [selectedApplication, setSelectedApplication] = React.useState<Application>();
  const [showRemoveApplicationDialog, setShowRemoveApplicationDialog] = useState<boolean>(false);
  const { onAction, onRemoveApplication } = useActivityApplicationActions();

  const { data, refetch, loading } = useCampfireQuery<
    GetWidgetActivityApplications,
    GetWidgetActivityApplicationsVariables
  >(GET_WIDGET_ACTIVITY_APPLICATIONS, {
    options: {
      variables: {
        programIds: programIds.length > 0 ? programIds : null,
        activityIds: activityIds.length > 0 ? activityIds : null,
        range: range,
        widgetType: WidgetTypes.REQUIRING_ACTION,
      },
    },
  });

  const handleActionSuccess = () => {
    if (refetch) refetch();
  };

  const applications =
    data?.vm.activityApplications
      .filter(
        (application) =>
          !application.dateWithdrawn &&
          !application.dateActioned &&
          application.activity.isActive &&
          !application.activity.program.dateDeleted &&
          !application.activity.program.dateSuspended &&
          !application.volunteer.dateDeactivated &&
          !application.volunteer.flagging?.dateFlagged
      )
      .map((application) => ({
        id: application.activityApplicationId,
        program: application.activity.program.name,
        profile: application.volunteer.profile,
        volunteerId: application.volunteer.volunteerId,
        activityName: application.activity.name,
        activityId: application.activity.activityId,
        activityPriority: application.activity.priority,
        programId: application.activity.program.programId,
        volunteerName: `${application.volunteer.profile.preferredName} ${application.volunteer.profile.lastName}`,
        dateApplied: unpackToDateTime(application.dateApplied),
      })) || [];

  const volunteerText = selectedApplication
    ? `${selectedApplication.profile.preferredName} ${selectedApplication.profile.lastName}`
    : `test`;
  const activityText = selectedApplication ? selectedApplication.activityName : `test`;
  const title = `Remove: ${volunteerText} from ${activityText}`;

  const columns: GridColumns = [
    {
      field: 'volunteerName',
      headerName: 'Volunteer',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <VolunteerProfile
            inTable
            noTrim
            {...row.profile}
            name={`${row.profile.preferredName} ${row.profile.lastName}`}
          />
        );
      },
    },
    {
      field: 'program',
      headerName: 'Program',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'activityName',
      headerName: 'Activity',
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ row }: any) => {
        return <ActivityNameAndPriority name={row.activityName} priority={row.activityPriority} />;
      },
    },
    {
      field: 'dateApplied',
      headerName: 'Date Applied',
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ row }: any) => row.dateApplied.toFormat('dd/MM/y'),
    },
    {
      field: '',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: any) => {
        return (
          <>
            <VolunteerApplicationMenuButton
              onAction={(action: ApplicationActions) => {
                if (action === 'remove') {
                  setSelectedApplication(row);
                  setShowRemoveApplicationDialog(true);
                  return;
                }
                onAction(action, row.id, () => handleActionSuccess());
              }}
              bulk={false}
            />
          </>
        );
      },
    },
  ];

  const handleRemoveSuccess = () => {
    if (refetch) refetch();
    setShowRemoveApplicationDialog(false);
  };

  return (
    <Box width='100%' height='100%'>
      <DataGrid columns={columns} rows={applications} loading={loading} autoPageSize disableSelectionOnClick />
      <ApplicationRemovingMessage
        open={showRemoveApplicationDialog}
        onClose={() => {
          setShowRemoveApplicationDialog(false);
        }}
        onRemove={() => (selectedApplication ? onRemoveApplication(selectedApplication.id, handleRemoveSuccess) : null)}
        title={title}
        warningText={`You are removing this volunteer's activity application`}
      />
    </Box>
  );
};
