import React, { memo } from 'react';
import { Typography, Box } from '@material-ui/core';
import { DateTime } from 'luxon';
import { formatDistanceDays } from '@campfire/hot-date';

const ActivityTimelineSidebarDate = memo(({ date }: { date: DateTime }) => {
  return (
    <Box display='flex' alignContent='baseline' alignItems='baseline' paddingLeft={1} marginTop={1}>
      <Typography variant='h6' style={{ marginRight: 5 }}>
        {date.toFormat('d MMM')}
      </Typography>
      <Typography>{`(${formatDistanceDays(date)})`}</Typography>
    </Box>
  );
});

export { ActivityTimelineSidebarDate };
