import { DialogContentText } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import {
  MultiSelectVolunteersDialogProps,
  MultiSelectVolunteersDialog,
} from '../../../../program-manager/activities/activity-timeline/past/report/form/MultiSelectVolunteersDialog';
import { PROGRAM_MANAGEMENT_GET_ALL_ORG_VOLUNTEERS } from '../../program-management.gql';
import { ProgramManagementGetAllOrgVolunteers } from '../../__generated__/ProgramManagementGetAllOrgVolunteers';

interface AddVolunteersDialogProps {
  open: boolean;
  onClose: (event: any) => void;
  onSubmit: MultiSelectVolunteersDialogProps['onSubmit'];
  ignoreVolunteerIds: Array<string>;
  dialogContentText: string;
}

const AddVolunteersDialog = (props: AddVolunteersDialogProps) => {
  const { dialogContentText, ...rest } = props;

  const { data: allProgramVolunteers, loading: allProgramVolunteersLoading } = useCampfireQuery<
    ProgramManagementGetAllOrgVolunteers,
    undefined
  >(PROGRAM_MANAGEMENT_GET_ALL_ORG_VOLUNTEERS);

  const allVolunteers = useMemo(() => allProgramVolunteers?.vm.volunteers ?? [], [allProgramVolunteers]);

  return (
    <MultiSelectVolunteersDialog
      {...rest}
      title='Add Volunteers'
      isLoading={allProgramVolunteersLoading}
      volunteers={allVolunteers}
      showSearchField
    >
      <DialogContentText>{`${dialogContentText}`}</DialogContentText>
    </MultiSelectVolunteersDialog>
  );
};

export { AddVolunteersDialog };
