import { gql } from '@apollo/client';

export const VOLUNTEERS_APPLICATIONS_GET_PROGRAMS = gql`
  query VolunteersApplicationGetPrograms {
    vm {
      programs {
        programId
        name
        description
        dateDeleted
        dateSuspended
      }
    }
  }
`;
