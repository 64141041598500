import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    padding: '0.5rem',
    backgroundColor: theme.color.secondary.main900,
    color: theme.color.grey.neutralBrand50,
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.75rem',
    fontWeight: 700,
    gap: '10px',
    textDecoration: 'none'
  }
}));
