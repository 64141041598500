import { Box, Typography, Theme } from '@material-ui/core';
import { DateTime } from 'luxon';
import { PersonAdd, PlayArrow } from '@material-ui/icons';
import React, { useRef } from 'react';
import { unpackToTime } from '@campfire/hot-date';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

import PersonRemoveIcon from '../../../../../assets/icons/person-remove-icon.svg';
import { GetVolunteerCommonActivityEnrolment_vm_volunteer_allActivityEnrolmentsForActivity as ActivityEnrolmentType } from './__generated__/GetVolunteerCommonActivityEnrolment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeline: {
      height: '3px',
      backgroundColor: '#CECECE',
      zIndex: 1,
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
    },
    listItem: {
      position: 'absolute',
      bottom: '44px',
      listStyle: 'none',
    },
    itemBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '72px',
      position: 'relative',
    },
    timelineDot: {
      position: 'absolute',
      bottom: '-14px',
      height: '7px',
      width: '7px',
      borderRadius: '50%',
      backgroundColor: '#9E9E9E',
    },
    timeRange: {
      fontSize: '10px',
      color: theme.color.grey.neutral300,
      textAlign: 'center',
      bottom: '-32px',
      position: 'absolute',
    },
  })
);

export const ActivityEnrolmentTimeline = ({
  loading,
  allEnrolmentsSorted,
  profileId,
}: {
  loading: boolean;
  allEnrolmentsSorted: ActivityEnrolmentType[];
  profileId: string;
}) => {
  const { theme } = useCampfireTheme();
  const classes = useStyles();
  const timelineRef = useRef<HTMLInputElement>(null);

  const timelineItems = allEnrolmentsSorted.flatMap((item) => [
    {
      type: 'join',
      date: item.dateEnrolled,
      actionBy: item.enrolledBy,
      isRestricted: item.activity.isRestrictedActivity,
    },
    {
      type: 'left',
      date: item.dateRemoved,
      actionBy: item.removedBy,
      isRestricted: item.activity.isRestrictedActivity,
    },
  ]);

  return (
    <Box style={{ position: 'relative', padding: '72px 32px 32px' }}>
      <div className={classes.timeline} style={{ opacity: loading ? 0 : 1 }} ref={timelineRef}>
        <ul>
          {timelineItems.map((item, index) => {
            const timelineWidth = timelineRef.current?.offsetWidth || 0;
            const itemDate = DateTime.fromISO(item.date);
            const preDate = index > 0 ? DateTime.fromISO(timelineItems[index - 1].date) : null;
            const nextDate = index < timelineItems.length - 1 ? DateTime.fromISO(timelineItems[index + 1].date) : null;

            const diffDaysPre = item.date
              ? Math.abs(preDate?.diff(itemDate, ['days']).toObject().days || 0)
              : Math.abs(preDate?.diff(DateTime.local(), ['days']).toObject().days || 0);

            const diffDaysNext = item.date
              ? Math.abs(nextDate?.diff(itemDate, ['days']).toObject().days || 0)
              : Math.abs(preDate?.diff(DateTime.local(), ['days']).toObject().days || 0);

            const diffPre = item.date
              ? preDate?.diff(itemDate, ['years', 'months', 'days']).toObject()
              : preDate?.diffNow(['years', 'months', 'days']).toObject();

            const withPerOne = (timelineWidth / (timelineItems.length - 1)) * index - 4;
            let rightPreWidth = timelineWidth / (timelineItems.length - 1);
            let left = 0;
            if (diffDaysPre === 0 || diffDaysNext === 0) {
              left = withPerOne;
            } else {
              left = diffDaysPre > diffDaysNext ? withPerOne + 24 : withPerOne - 24;
              rightPreWidth = diffDaysPre > diffDaysNext ? rightPreWidth + 24 : rightPreWidth - 24;
            }

            const diffPreYears = +Math.abs(diffPre?.years || 0).toFixed();
            const diffPreMonths = +Math.abs(diffPre?.months || 0).toFixed();
            const diffPreDays = +Math.abs(diffPre?.days || 0).toFixed();

            return (
              <>
                {item.date ? (
                  <li className={classes.listItem} style={{ left: left }}>
                    <Box className={classes.itemBox}>
                      {item.type === 'join' ? (
                        <PersonAdd style={{ width: '20px', height: '20px', color: '#727272' }} />
                      ) : (
                        <img src={PersonRemoveIcon} alt='' />
                      )}
                      <Typography style={{ fontWeight: 700, fontSize: '11px', color: theme.color.grey.neutral300 }}>
                        {item.type === 'join' ? 'Joined Team' : 'Left Team'}
                      </Typography>
                      <Typography style={{ fontSize: '10px', color: theme.color.grey.neutral300 }}>
                        {unpackToTime(item.date).toFormat('dd LLL yyyy')}
                      </Typography>
                      {item.actionBy || (!item.actionBy && !item.isRestricted) ? (
                        <>
                          <Typography
                            style={{ fontSize: '10px', color: theme.color.grey.neutral300, textAlign: 'center' }}
                          >
                            {(!item.actionBy && !item.isRestricted) ||
                            (item.actionBy && item.actionBy.profile.profileId === profileId)
                              ? item.type === 'join'
                                ? 'Added by themselves'
                                : 'Removed by themselves'
                              : item.actionBy && item.actionBy.profile.profileId !== profileId
                              ? item.type === 'join'
                                ? `Added by ${item.actionBy?.profile.preferredName} ${item.actionBy?.profile.lastName}`
                                : `Removed by ${item.actionBy?.profile.preferredName} ${item.actionBy?.profile.lastName}`
                              : null}
                          </Typography>
                        </>
                      ) : null}
                      <Box className={classes.timelineDot}></Box>
                      {diffPre && (
                        <Typography
                          className={classes.timeRange}
                          style={{
                            right: rightPreWidth / 2 - 16,
                            minWidth: '148px',
                            textAlign: 'center',
                          }}
                        >
                          {diffPreYears ? `${diffPreYears} years` : ''}
                          {diffPreYears && (diffPreMonths || diffPreDays) ? `, ` : ''}
                          {diffPreMonths ? `${diffPreMonths} months` : ''}
                          {diffPreMonths && diffPreDays ? `, ` : ''}
                          {diffPreDays ? `${diffPreDays} days` : ''}
                        </Typography>
                      )}
                    </Box>
                  </li>
                ) : (
                  <li className={classes.listItem} style={{ left: timelineWidth }}>
                    <Box className={classes.itemBox}>
                      <Typography style={{ fontWeight: 700, fontSize: '11px', color: theme.color.grey.neutral300 }}>
                        {'Today'}
                      </Typography>
                      <Typography style={{ fontSize: '10px', color: theme.color.grey.neutral300 }}>
                        {DateTime.local().toFormat('dd LLL yyyy')}
                      </Typography>
                      <PlayArrow
                        style={{
                          position: 'absolute',
                          bottom: '-18px',
                          height: '16px',
                          width: '16px',
                          color: '#9E9E9E',
                        }}
                      />
                      {diffPre && (
                        <Typography
                          className={classes.timeRange}
                          style={{
                            right: rightPreWidth / 2 - 16,
                            minWidth: '148px',
                            textAlign: 'center',
                          }}
                        >
                          {diffPreYears ? `${diffPreYears} years` : ''}
                          {diffPreYears && (diffPreMonths || diffPreDays) ? `, ` : ''}
                          {diffPreMonths ? `${diffPreMonths} months` : ''}
                          {diffPreMonths && diffPreDays ? `, ` : ''}
                          {diffPreDays ? `${diffPreDays} days` : ''}
                        </Typography>
                      )}
                    </Box>
                  </li>
                )}
              </>
            );
          })}
        </ul>
      </div>
    </Box>
  );
};
