import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useCommonStyles } from './commonStyles';
import { GoBack } from './GoBack';

export const AppSetup = (props: { name: string; icon?: any; children: React.ReactElement }) => {
  const commonStyles = useCommonStyles();
  const { name, children } = props;

  return (
    <Box className={commonStyles.container}>
      <GoBack />
      <Box className={commonStyles.heading}>
        <Typography variant='h2' className={commonStyles.headingTitle}>
          Integrations Setup - {name}
        </Typography>
      </Box>

      <Box className={commonStyles.containerPanel}>
        <Typography variant='h2' className={commonStyles.headingTitle}>
          Your Account
        </Typography>
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' py='3rem'>
          <Typography
            className={commonStyles.text}
          >{`Login with ${name} to begin setting up your integration`}</Typography>
          <Box marginTop='1rem'>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};
