import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { useCommonStyles } from '../commonStyles';

type AppAccountSettingsProps = {
  children: React.ReactElement;
};

export function AppAccountSettings(props: AppAccountSettingsProps) {
  const { children } = props;
  const commonStyles = useCommonStyles();
  const { isSm } = useCampfireTheme();
  return (
    <Box className={commonStyles.containerPanel}>
      <Typography variant='h2' className={commonStyles.headingTitle}>
        Account settings
      </Typography>
      <Box display='flex' flexDirection={isSm ? 'column' : 'row'} py='1rem'>
        {children}
      </Box>
    </Box>
  );
}
