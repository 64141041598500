import { useSnackbar } from '../../../../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../../../../global/network/useEndpointFetch';
import { SaveReportFieldValues } from '../../report-field-types';
import { parseReportFieldValues } from '../../ReportFieldUtils';

interface SaveReportFieldBooleanField {
  __typename: 'VOLUNTEER_BooleanFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportFieldCheckboxField {
  __typename: 'VOLUNTEER_CheckboxFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportFieldDropdownField {
  __typename: 'VOLUNTEER_DropdownFieldType';
  fieldId?: string;
  name: string;
  description: string;
  dropdownFieldOptions: {
    dropdownFieldOptionId?: string;
    name: string;
    order: number;
  }[];
  allowMultiple: boolean;
  hero: boolean;
}

interface SaveReportFieldNumericField {
  __typename: 'VOLUNTEER_NumericFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportFieldFormField {
  __typename: 'VOLUNTEER_RatingFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportFieldTextField {
  __typename: 'VOLUNTEER_TextFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
  isLongText: boolean;
}

interface SaveReportFieldTimeField {
  __typename: 'VOLUNTEER_TimeFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportFieldAttachmentField {
  __typename: 'VOLUNTEER_AttachmentFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

type ReportFieldType =
  | SaveReportFieldBooleanField
  | SaveReportFieldCheckboxField
  | SaveReportFieldDropdownField
  | SaveReportFieldNumericField
  | SaveReportFieldFormField
  | SaveReportFieldTextField
  | SaveReportFieldTimeField
  | SaveReportFieldAttachmentField;

export type SaveReportFieldType = { field: ReportFieldType };

export const useSaveReportField = () => {
  return useEndpointFetch<SaveReportFieldType>('/vm/report-type/field/save');
};

export const useMergeReportFields = () => {
  return useEndpointFetch<{ sourceFieldId: string; targetFieldId: string }>('/vm/report-type/field/merge');
};

export const useArchiveReportField = () => {
  return useEndpointFetch<{ fieldId: string }>('/vm/report-type/field/deactivate');
};

export const useReactivateReportField = () => {
  return useEndpointFetch<{ fieldId: string }>('/vm/report-type/field/reactivate');
};

export const useReportFieldDetailHeaderActions = () => {
  const saveReportField = useSaveReportField();
  const mergeReportFields = useMergeReportFields();
  const archiveReportField = useArchiveReportField();
  const reactivateReportField = useReactivateReportField();

  const { setSnackbar } = useSnackbar();

  const runSaveReportField = ({
    values,
    handleSuccess,
  }: {
    values: SaveReportFieldValues;
    handleSuccess?: () => void;
  }) => {
    saveReportField
      .run(parseReportFieldValues(values))
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to edit report field',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Report field successfully edited',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to edit report field',
          variant: 'error',
        })
      );
  };

  const runMergeReportFields = ({
    sourceFieldId,
    targetFieldId,
    handleSuccess,
  }: {
    sourceFieldId: string;
    targetFieldId: string;
    handleSuccess?: () => void;
  }) => {
    mergeReportFields
      .run({
        sourceFieldId,
        targetFieldId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to merge report fields',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Report fields successfully merged',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to merge report fields',
          variant: 'error',
        })
      );
  };

  const runArchiveReportField = ({ fieldId, handleSuccess }: { fieldId: string; handleSuccess?: () => void }) => {
    archiveReportField
      .run({
        fieldId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to archive report field',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Report field successfully archived',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to archive report field',
          variant: 'error',
        })
      );
  };

  const runReactivateReportField = ({ fieldId, handleSuccess }: { fieldId: string; handleSuccess?: () => void }) => {
    reactivateReportField
      .run({
        fieldId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to reactivate report field',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Report field successfully reactivated',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to reactivate report field',
          variant: 'error',
        })
      );
  };
  return {
    runSaveReportField,
    runSaveReportFieldLoading: saveReportField.isLoading,
    runMergeReportFields,
    runMergeReportFieldsLoading: mergeReportFields.isLoading,
    runArchiveReportField,
    runArchiveReportFieldLoading: archiveReportField.isLoading,
    runReactivateReportField,
  };
};
