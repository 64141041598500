import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const MyNewsListSkeleton = () => {
  return (
    <>
      <Box my='24px' width='100%'>
        <Skeleton variant='text' width={100} height={13} />
        <Skeleton variant='text' width={300} height={19} />
        <Skeleton variant='text' width='100%' height={60} />
      </Box>
      <Box my='24px' width='100%'>
        <Skeleton variant='text' width={100} height={13} />
        <Skeleton variant='text' width={300} height={19} />
        <Skeleton variant='text' width='100%' height={60} />
      </Box>
    </>
  );
};
