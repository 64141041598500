import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

export const ProgramEditorSkeleton = () => {
  const { isMobile, theme } = useCampfireTheme();
  return (
    <Grid container direction='column'>
      <Grid
        item
        container
        style={{
          borderBottom: `1px solid ${theme.color.grey.border}`,
          minHeight: 75,
          padding: '0 16px',
        }}
        alignItems='center'
        alignContent='center'
      >
        {isMobile ? (
          <Grid item container justify='flex-end'>
            <Skeleton width={50} height={50} style={{ margin: '0px 4px' }} />
            <Skeleton width={50} height={50} />
          </Grid>
        ) : (
          <Grid item container justify='flex-end'>
            <Grid item style={{ margin: '0px 8px' }}>
              <Skeleton width={100} height={50} />
            </Grid>
            <Grid item style={{ margin: '0px 8px' }}>
              <Skeleton width={100} height={50} />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item container direction='column' style={{ padding: 24 }}>
        <Grid item>
          <Skeleton width={200} style={{ marginBottom: 4 }} />
          <Skeleton width={70} height={10} />
        </Grid>
      </Grid>
    </Grid>
  );
};
