import React from 'react';
import { Box } from '@material-ui/core';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import {
  IVGetApplicationComments,
  IVGetApplicationCommentsVariables,
} from '../incoming-volunteer/__generated__/IVGetApplicationComments';
import { IV_APPLICATION_COMMENT } from '../incoming-volunteer/application--comment.gql';
import { useSaveComment } from '../../../../common/comment/Comment';
import { CommentBox, PostCommentType } from '../../../../common/comment/CommentBox';
import { CommentViewer } from '../../../../common/comment/CommentViewer';

interface CommentsListProps {
  applicationId: string;
}

export function CommentsList({ applicationId }: CommentsListProps) {
  const { data, refetch } = useCampfireQuery<IVGetApplicationComments, IVGetApplicationCommentsVariables>(
    IV_APPLICATION_COMMENT,
    { options: { variables: { applicationId } } }
  );

  const saveComment = useSaveComment();

  React.useEffect(() => {
    const timer = setInterval(() => {
      refetch();
    }, 15000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const onPost = (body: PostCommentType) => {
    saveComment({
      applicationId: applicationId,
      ...body,
    }).then(() => {
      // onClear();
      if (refetch) {
        refetch();
      }
    });
  };

  const handleEdit = (body: PostCommentType) => {
    saveComment({
      ...body,
    }).then(() => {
      // onClear();
      if (refetch) {
        refetch();
      }
    });
  };

  const onReply = (parentId: string, content: string) => {
    saveComment({
      parentId,
      applicationId: applicationId,
      content,
    }).then(() => {
      if (refetch) {
        refetch();
      }
    });
  };

  const rootComments = data?.vm.application?.comments.filter((comment) => !comment.parentId);
  const nonRootComments = data?.vm.application?.comments.filter((comment) => Boolean(comment.parentId));
  return (
    <React.Fragment>
      <Box>
        <CommentBox onPost={onPost} />
      </Box>
      <Box paddingTop={'1em'}>
        {rootComments?.map(
          (comment) =>
            !comment.removedDate && (
              <CommentViewer
                comment={comment}
                comments={nonRootComments}
                onReply={onReply}
                refetch={refetch}
                handleEdit={handleEdit}
              />
            )
        )}
      </Box>
    </React.Fragment>
  );
}
