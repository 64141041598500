import React, { ReactNode } from 'react';
import { Chip } from '@material-ui/core';

export type StatusType = {
  status: ReactNode;
  bgcolor: string;
};

const StatusChip = ({ status, bgcolor }: StatusType) => (
  <Chip
    size='small'
    label={status}
    style={{
      borderRadius: 5,
      color: 'white',
      background: bgcolor,
      fontWeight: 600,
      textTransform: 'uppercase',
    }}
  />
);

export { StatusChip };
