import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Typography, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GET_ALL_ADMIN_USERS } from './admin-console-admins-panel.gql';
import { GetAllAdminUsers } from './__generated__/GetAllAdminUsers';
import { AdminUsersList } from './AdminUsersList';
import { AddAdminUserDialog } from './AddAdminUserDialog';
import { SearchField } from '../../../../../common/inputs/SearchField';
import { AlertCard, getAlertCardColors } from '../../../../../common/cards/alert-card/AlertCard';

const AdminConsoleAdminsPanel = () => {
  const [openAddAdminUserDialog, setOpenAddAdminUserDialog] = useState<boolean>(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [searchAdminInput, setSearchAdminInput] = useState<string>();
  const { data: getAdminUsersResponse, loading: getAdminUsersLoading, refetch: refetchAdminUsers } = useCampfireQuery<
    GetAllAdminUsers,
    undefined
  >(GET_ALL_ADMIN_USERS);

  const { lightTextColor } = getAlertCardColors('info');

  const allAdminUsers = useMemo(() => {
    if (!getAdminUsersResponse?.vm.admins) return [];
    return getAdminUsersResponse.vm.admins;
  }, [getAdminUsersResponse]);

  const adminUsersFiltered = useMemo(() => {
    if (!allAdminUsers?.length) return [];
    return allAdminUsers
      .filter((admin) =>
        searchAdminInput
          ? admin.profile.preferredName
              .concat(` ${admin.profile.lastName}`)
              .toLowerCase()
              .includes(searchAdminInput.toLowerCase())
          : true
      )
      .sort((a, b) => (a.profile.lastName > b.profile.lastName ? 1 : -1));
  }, [allAdminUsers, searchAdminInput]);

  const allAdminUserIds = useMemo(() => {
    if (!allAdminUsers?.length) return [];
    return allAdminUsers
      .map((adminUser) => adminUser.profile.volunteer?.volunteerId)
      .filter((volunteerId) => Boolean(volunteerId)) as Array<string>;
  }, [allAdminUsers]);

  const onAddAdminSuccess = () => {
    setIsShowAlert(true);
    if (refetchAdminUsers) refetchAdminUsers();
  };

  const onRemoveAdminSuccess = () => {
    setIsShowAlert(false);
    if (refetchAdminUsers) refetchAdminUsers();
  };

  return (
    <Box position='relative' width={1} display='flex' justifyContent='center'>
      <LinearProgressOverlay isLoading={getAdminUsersLoading} />

      <Box px={8} pt={4} width={1} maxWidth={720}>
        <Grid container direction='row' style={{ paddingBottom: 16 }}>
          <Grid item xs>
            <Typography variant='h6' style={{ fontWeight: 600 }}>
              {'Admin Users'}
            </Typography>
            <Typography variant={'body2'}>{'Manage administrators'}</Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems='flex-start' justify='flex-end'>
              <TabletButton
                variant='contained'
                color='primary'
                onClick={() => {
                  setOpenAddAdminUserDialog(true);
                }}
                endIcon={<Add />}
              >
                {'Add admin'}
              </TabletButton>
            </Grid>
          </Grid>
        </Grid>
        {isShowAlert && (
          <Box pb={2}>
            <AlertCard variant='info' title='Your pricing may change'>
              <Typography
                variant='subtitle2'
                display='inline'
                style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
              >
                Adding an Admin to your account beyond your included allocation may increase your monthly subscription
                fee. Reach out to our Support team to discuss your subscription fees and inclusions.
              </Typography>
            </AlertCard>
          </Box>
        )}

        <Grid item xs style={{ paddingTop: 8 }}>
          <SearchField
            placeholder='Search Admin Users'
            onChange={(e) => setSearchAdminInput(e.target.value)}
            fullWidth
            growLeft
          />
          <AdminUsersList
            allUsers={allAdminUsers}
            filteredUsers={adminUsersFiltered}
            onRemoveAdminSuccess={onRemoveAdminSuccess}
          />
        </Grid>

        {openAddAdminUserDialog && (
          <AddAdminUserDialog
            open={openAddAdminUserDialog}
            onClose={() => setOpenAddAdminUserDialog(false)}
            allAdminUserIds={allAdminUserIds}
            onAddAdminSuccess={onAddAdminSuccess}
          />
        )}
      </Box>
    </Box>
  );
};

export { AdminConsoleAdminsPanel };
