import { List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    code: {
      fontSize: '0.875rem !important',
    },
  })
);

const AttributesTable = (props: Props) => {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Attribute</TableCell>
          <TableCell>Required</TableCell>
          <TableCell>Values</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {props.tableData.map((attribute) => (
          <TableRow key={attribute.name}>
            <TableCell>{attribute.name}</TableCell>
            <TableCell>{attribute.required}</TableCell>
            <TableCell>
              <List>
                {attribute.values.map((value) => (
                  <ListItem key={value.name}>
                    <ListItemText
                      primary={<code className={classes.code}>{value.name}</code>}
                      secondary={value.description}
                    />
                  </ListItem>
                ))}
              </List>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AttributesTable;

interface AttributeValue {
  name: string;
  description: string;
}

export interface Attribute {
  name: string;
  required: string;
  values: AttributeValue[];
}

interface Props {
  tableData: Attribute[];
}
