import React, { useState } from 'react';
import { BorderColor, DragIndicator, DeleteOutlined, VisibilityOff, Visibility, ExpandMore } from '@material-ui/icons';
import { Box, Button, Theme, Typography, List, ListItem } from '@material-ui/core';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useHistory, useLocation } from 'react-router';
import { Skeleton } from '@material-ui/lab';
import { v4 as uuidv4 } from 'uuid';
import { DraggableList } from '@campfire/draggable-list';
import { TabletButton } from '@campfire/tablet-button';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { DraggableTask, Task } from '../Task/Task';
import { TaskDetails } from '../TaskDetails/TaskDetails';
import { TaskListGetCakes, TaskListGetCakes_vm_cakes as CakeType } from './__generated__/TaskListGetCakes';
import { GET_CAKE, GET_CAKES } from './cake-model.gql';
import { useDeepEffect } from '../../../../hooks/useDeepEffect';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import {
  TaskListGetCake,
  TaskListGetCakeVariables,
  TaskListGetCake_vm_cake_allTasks as CakeTaskType,
} from './__generated__/TaskListGetCake';
import { useAdminConsoleActions, useSaveTask } from '../../admin-console/admin-console-actions';
import { EditDescriptionDialogv2 } from '../EditDescriptionDialogv2';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';
import { AddCakeDialog } from '../AddCakeDialog';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { ButtonGroupSection } from './ButtonGroupSection';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { FullscreenDialog } from '../../../../common/dialogs/FullscreenDialog';
import { DeleteCakeDialog } from './DeleteCakeDialog';
import { HiddenCakeDialog } from './HiddenCakeDialog';
import { TooltipMenu } from '../../../../common/tooltip-menu';

export enum TaskType {
  REQUIRED = 'required',
  EXTRA = 'extra',
}

const VOLUNTEER_PROFILE = 'Volunteer Profile';

const TaskHeaderSkeleton = () => {
  return (
    <List disablePadding>
      <Box display='flex' justifyContent='center' width='100%' mt='46px'>
        <Skeleton variant='rect' width='280px' height='40px' style={{ borderRadius: '8px' }} />
      </Box>
      <Box width='100%' mt='36px'>
        <Skeleton variant='rect' width='200px' height='30px' style={{ borderRadius: '4px', marginBottom: '12px' }} />
        <Skeleton variant='rect' width='100%' height='20px' style={{ borderRadius: '4px', marginBottom: '12px' }} />
        <Skeleton variant='rect' width='100%' height='20px' style={{ borderRadius: '4px', marginBottom: '12px' }} />
      </Box>
    </List>
  );
};
const TaskListSkeleton = () => {
  return (
    <List disablePadding style={{ marginTop: '12px' }}>
      {Array.from(Array(3)).map(() => (
        <ListItem disableGutters key={uuidv4()}>
          <Skeleton variant='rect' width='100%' height='130px' style={{ borderRadius: '8px' }} />
        </ListItem>
      ))}
    </List>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'stretch',
      flex: '1 1 0',
      minHeight: 0,
    },
    taskContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: (props: { isMobile: boolean }) => (props.isMobile ? '20px' : '60px'),
      overflowY: 'scroll',
      flex: 1,
    },
    badge: {
      fontSize: '.74em',
      fontWeight: 500,
      padding: 8,
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 4,
      backgroundColor: '#D9D9D9',
      color: theme.color.grey.neutral400,
      marginLeft: 16,
    },
    taskList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginTop: 25,

      flex: 1,
      paddingBottom: '2rem',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '2rem',
      justifyContent: 'space-between',
    },
    titleButton: {
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '4px',
      textTransform: 'none',
      padding: '6px 12px',
      minWidth: '45px',
    },
    cakeTitle: {
      fontWeight: 900,
      fontSize: '1.25rem',
      color: theme.color.grey.neutralBrand800,
    },
    cakeDescription: {
      fontWeight: 400,
      color: theme.color.grey.neutral200,
      fontSize: '0.9rem',
      marginTop: '6px',
    },
  })
);

export function TasksList({ refetchFormCakes }: { refetchFormCakes: () => void }) {
  const history = useHistory();
  const location = useLocation();
  const [openAddCakeDialog, setOpenAddCakeDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteCakeDialog, setOpenDeleteCakeDialog] = useState(false);
  const [openHiddenCakeDialog, setOpenHiddenCakeDialog] = useState(false);
  const [cakeId, setCakeId] = useQueryParam('cid', StringParam);
  const [taskId, setTaskId] = useQueryParam('tid', StringParam);
  const [isOrdering, setIsOrdering] = useQueryParam('isOrdering', BooleanParam);
  const [tasks, setTasks] = React.useState<CakeTaskType[]>([]);
  const [q] = useQueryParam('q', StringParam);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const { data: vmCake, refetch: cakeRefetch, loading: cakeLoading } = useCampfireQuery<TaskListGetCakes, {}>(
    GET_CAKES
  );
  const [queryCakeDetails, { data: vmCakeDetails, refetch, loading: cakeDetailLoading }] = useCampfireLazyQuery<
    TaskListGetCake,
    TaskListGetCakeVariables
  >(GET_CAKE);

  const saveTask = useSaveTask();
  const cakes = (vmCake?.vm.cakes || ([] as CakeType[])).sort((cA, cB) => cA.order - cB.order);
  const firstCakeId = cakes[0]?.cakeId;
  const selectedCake = cakes.find((cake) => cake.cakeId === cakeId);
  const isCakeIdInList = Boolean(selectedCake);
  const lastCakeOrder = cakes[cakes.length - 1]?.order;
  const selectedCakeDetail = vmCakeDetails?.vm.cake;
  const isHiddenCake = selectedCake?.isHidden;
  const removeTask = useEndpointFetch<{ taskId: string }>('/vm/task/remove');
  const removeCake = useEndpointFetch<{ cakeId: string }>('/vm/cake/remove');

  const { runSaveCake } = useAdminConsoleActions();

  React.useEffect(() => {
    if (cakeId) {
      queryCakeDetails({
        variables: {
          cakeId,
        },
      });
    }
  }, [cakeId]);

  useDeepEffect(() => {
    if (!isCakeIdInList || !cakeId) {
      setCakeId(firstCakeId);
    }
  }, [firstCakeId, cakeId, isCakeIdInList]);

  useDeepEffect(() => {
    setTasks(vmCakeDetails?.vm.cake.allTasks || []);
  }, [vmCakeDetails]);

  const onEdit = () => {
    history.push(`/admin/tasks/task-builder${location.search}`);
  };
  const onDuplicate = () => {
    history.push(`/admin/tasks/task-builder${location.search}&duplicate=1`);
  };
  const onDelete = (deletingTaskId: string) => {
    removeTask
      .run({
        taskId: deletingTaskId,
      })
      .then(() => {
        if (refetch) {
          refetch();
        }
      });
  };

  const onMove = (newTask: any, handleSuccess?: () => void) => {
    saveTask
      .run(newTask)
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to move task',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Task moved successfully',
          variant: 'success',
        });
        setTaskId(undefined);
        if (refetch) {
          refetch();
        }
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to move task',
          variant: 'error',
        })
      );
  };

  const onReorder = (startIdx: any, endIdx: any, reorder: any) => {
    setTasks(
      profileTasks.concat(
        reorder(nonProfileTasks, startIdx, endIdx).map((task: CakeTaskType, taskIndex: number) => {
          return {
            ...task,
            order: taskIndex,
          };
        })
      )
    );
  };

  const onCancel = () => {
    setIsOrdering(false);
    setTasks(vmCakeDetails?.vm.cake.allTasks || []);
  };

  const onSave = () => {
    Promise.all(
      nonProfileTasks.map((task, taskIndex) =>
        saveTask.run({
          ...task,
          programIds: task.programs.map((p) => p.programId),
          roleIds: task.roles.map((r) => r.activityRoleId),
          activityIds: task.activities.map((a) => a.activityId),
          order: taskIndex,
          cakeId,
        })
      )
    );
    setSnackbar({
      open: true,
      message: 'Tasks saved',
      variant: 'success',
    });
    setIsOrdering(false);
  };

  const onDeleteCakeClick = () => {
    setOpenDeleteCakeDialog(true);
  };
  const handleDeleteCake = () => {
    if (cakeId) {
      removeCake
        .run({
          cakeId,
        })
        .then((res) => {
          if (!res.ok) {
            setSnackbar({
              open: true,
              message: 'Unable to delete cake',
              variant: 'error',
            });
            return;
          }
          setOpenDeleteCakeDialog(false);
          setSnackbar({
            open: true,
            message: 'Cake removed successfully',
            variant: 'success',
          });
          if (cakeRefetch) {
            cakeRefetch();
          }
          if (refetchFormCakes) {
            refetchFormCakes();
          }
        })
        .catch(() =>
          setSnackbar({
            open: true,
            message: 'Unable to delete cake',
            variant: 'error',
          })
        );
    }
  };

  const handleHiddenCake = () => {
    if (selectedCakeDetail) {
      runSaveCake(
        {
          ...selectedCakeDetail,
          taskIds: selectedCakeDetail.allTasks.filter((task) => task.order !== -1).map((task) => task.taskId),
          isHidden: !selectedCakeDetail.isHidden,
        },

        () => {
          setOpenHiddenCakeDialog(false);
          if (cakeRefetch) {
            cakeRefetch();
          }
          if (refetch) {
            refetch();
          }
          if (refetchFormCakes) {
            refetchFormCakes();
          }
        }
      );
    }
  };

  const handleAddCakeSuccess = () => {
    if (cakeRefetch) {
      cakeRefetch();
    }
    if (refetchFormCakes) {
      refetchFormCakes();
    }
    setOpenAddCakeDialog(false);
  };

  const { isMobile, isMd, theme } = useCampfireTheme();
  const classes = useStyles({ isMobile });
  const filteredTasks = tasks.filter((task) => task.title.toLowerCase().includes(q?.toLowerCase() || ''));
  const profileTasks = filteredTasks.filter((task) => task.title === VOLUNTEER_PROFILE);
  const nonProfileTasks = filteredTasks
    .filter((task) => task.title !== VOLUNTEER_PROFILE)
    .sort((a, b) => (a.order > b.order ? 1 : -1));

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <>
      <div className={classes.container} ref={containerRef}>
        {!isMobile && (
          <div
            id='measure-layer'
            style={{
              display: 'inline-block',
              position: 'absolute',
              visibility: 'hidden',
              zIndex: -1,
              width: isMobile ? '100%' : 'auto',
            }}
          />
        )}
        <Box className={classes.taskContainer}>
          {cakeLoading ? (
            <TaskHeaderSkeleton />
          ) : (
            <Box display='flex' flexDirection='column'>
              <ButtonGroupSection cakes={cakes} expanded={expanded} setOpenAddCakeDialog={setOpenAddCakeDialog} />
              <Box className={classes.titleContainer}>
                <Box>
                  <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography className={classes.cakeTitle}>{`${selectedCake?.title}`} Tasks</Typography>
                    {selectedCake?.isHidden ? (
                      <Box className={classes.badge} display={'flex'} alignItems={'center'}>
                        <VisibilityOff style={{ fontSize: '15px', paddingRight: '5px' }} /> Hidden
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box marginTop='1rem'>
                    <Typography className={classes.cakeDescription}>{selectedCake?.description}</Typography>
                  </Box>
                </Box>
                <Box display='flex' style={{ gap: '1rem', alignSelf: 'flex-start' }}>
                  <TabletButton
                    variant={isOrdering ? 'contained' : 'outlined'}
                    color='primary'
                    style={{
                      boxSizing: 'border-box',
                      borderRadius: '4px',
                      textTransform: 'none',
                      padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
                      minWidth: '40px',
                    }}
                    onClick={() => setIsOrdering(!isOrdering)}
                  >
                    <DragIndicator style={{ marginTop: '1px', fontSize: '16px' }} />
                    <Typography
                      style={{
                        marginLeft: '8px',
                        fontWeight: 600,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        display: isMobile || isMd ? 'none' : 'inherit',
                      }}
                    >
                      Reorder
                    </Typography>
                  </TabletButton>
                  <TooltipMenu
                    container={containerRef.current} // Beter positioning
                    title={
                      <Box
                        display='flex'
                        flexDirection='column'
                        paddingTop={1}
                        paddingBottom={1}
                        alignItems='flex-start'
                        onClick={() => setMenuOpen(false)}
                      >
                        <TabletButton
                          variant='text'
                          style={{
                            boxSizing: 'border-box',
                            textTransform: 'none',
                            padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
                            minWidth: '40px',
                            color: theme.alert.blue.extraLight,
                          }}
                          onClick={() => setOpenEditDialog(true)}
                        >
                          <BorderColor style={{ marginTop: '1px', fontSize: '16px' }} />
                          <Typography
                            style={{
                              marginLeft: '10px',
                              fontWeight: 600,
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              display: isMobile || isMd ? 'none' : 'inherit',
                            }}
                          >
                            Edit
                          </Typography>
                        </TabletButton>

                        {selectedCake?.title !== 'Onboarding' ? (
                          <TabletButton
                            variant='text'
                            style={{
                              boxSizing: 'border-box',
                              textTransform: 'none',
                              padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
                              minWidth: '40px',
                              color: theme.alert.blue.extraLight,
                            }}
                            onClick={() => setOpenHiddenCakeDialog(true)}
                          >
                            {isHiddenCake ? (
                              <Visibility style={{ marginTop: '1px', fontSize: '16px' }} />
                            ) : (
                              <VisibilityOff style={{ marginTop: '1px', fontSize: '16px' }} />
                            )}
                            <Typography
                              style={{
                                marginLeft: '10px',
                                fontWeight: 600,
                                fontSize: '14px',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                display: isMobile || isMd ? 'none' : 'inherit',
                              }}
                            >
                              {`${isHiddenCake ? 'Show' : 'Hide'}`}
                            </Typography>
                          </TabletButton>
                        ) : (
                          ''
                        )}
                        <TabletButton
                          variant='text'
                          style={{
                            boxSizing: 'border-box',
                            textTransform: 'none',
                            padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
                            minWidth: '40px',
                            color: theme.alert.red.light,
                          }}
                          onClick={onDeleteCakeClick}
                          disabled={cakeDetailLoading}
                        >
                          <DeleteOutlined style={{ marginTop: '1px', fontSize: '16px' }} />
                          <Typography
                            style={{
                              marginLeft: '10px',
                              fontWeight: 600,
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              display: isMobile || isMd ? 'none' : 'inherit',
                            }}
                          >
                            Delete
                          </Typography>
                        </TabletButton>
                      </Box>
                    }
                    open={menuOpen}
                    onClose={() => setMenuOpen(false)}
                  >
                    <TabletButton
                      size='small'
                      variant='outlined'
                      color='primary'
                      aria-controls='options-menu'
                      aria-haspopup='true'
                      onClick={() => setMenuOpen(true)}
                      endIcon={<ExpandMore />}
                      style={{
                        height: '100%',
                        border: '1px solid #9e9e9e',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        padding: '2px 7px',
                        marginRight: '10px',
                        color: theme.color.grey.neutral300,
                      }}
                    >
                      Options
                    </TabletButton>
                  </TooltipMenu>
                </Box>
              </Box>
            </Box>
          )}
          {cakeDetailLoading ? (
            <TaskListSkeleton />
          ) : (
            <Box className={classes.taskList}>
              <Box>
                {profileTasks.map((task) => (
                  <Task key={task.taskId} task={task} onClick={() => setTaskId(task.taskId)} />
                ))}
              </Box>
              {isOrdering ? (
                <>
                  <DraggableList
                    onReorder={onReorder}
                    style={{ display: 'flex', flexDirection: 'column', gap: '1.75rem' }}
                  >
                    {nonProfileTasks.map((task, index) => (
                      <DraggableTask
                        index={index}
                        key={task.taskId}
                        task={task}
                        onClick={() => setTaskId(task.taskId)}
                      />
                    ))}
                  </DraggableList>
                  <Box display='flex' justifyContent='flex-end'>
                    <Box display='flex' style={{ gap: '1rem' }}>
                      <Button
                        variant={'text'}
                        style={{
                          boxSizing: 'border-box',
                          borderRadius: '6px',
                          textTransform: 'none',
                          padding: !isMobile || !isMd ? '14px 22px' : '14px 14px',
                          minWidth: '40px',
                        }}
                        onClick={onCancel}
                      >
                        <Typography style={{ fontSize: '13px', fontWeight: 700 }}>Cancel</Typography>
                      </Button>
                      <TabletButton
                        variant='contained'
                        style={{
                          boxSizing: 'border-box',
                          borderRadius: '6px',
                          textTransform: 'none',
                          padding: !isMobile || !isMd ? '14px 22px' : '14px 14px',
                          minWidth: '40px',
                        }}
                        color='primary'
                        onClick={onSave}
                      >
                        <Typography style={{ fontSize: '13px', fontWeight: 700 }}>Save</Typography>
                      </TabletButton>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '1.75rem' }}>
                  {nonProfileTasks.map((task) => (
                    <Task key={task.taskId} task={task} onClick={() => setTaskId(task.taskId)} />
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
        {isMobile ? (
          <FullscreenDialog
            fullWidth
            title=''
            fullScreen
            maxWidth={'xs'}
            scroll='paper'
            open={!!taskId}
            close={() => setTaskId(undefined)}
          >
            <Box>
              {taskId ? (
                <TaskDetails
                  taskId={taskId}
                  onDelete={() => onDelete(taskId)}
                  onMove={onMove}
                  onEdit={onEdit}
                  onDuplicate={onDuplicate}
                  cakes={cakes}
                />
              ) : null}
            </Box>
          </FullscreenDialog>
        ) : (
          <CollapsibleSideBar setSiblingExpanded={setExpanded}>
            <Box>
              {taskId ? (
                <TaskDetails
                  taskId={taskId}
                  onDelete={() => onDelete(taskId)}
                  onMove={onMove}
                  onEdit={onEdit}
                  onDuplicate={onDuplicate}
                  cakes={cakes}
                />
              ) : null}
            </Box>
          </CollapsibleSideBar>
        )}
      </div>
      {vmCakeDetails?.vm.cake ? (
        <EditDescriptionDialogv2
          open={openEditDialog}
          close={() => setOpenEditDialog(false)}
          cake={vmCakeDetails.vm.cake}
          refetch={cakeRefetch}
        />
      ) : null}
      {openAddCakeDialog ? (
        <AddCakeDialog
          open={openAddCakeDialog}
          order={lastCakeOrder ? lastCakeOrder + 1 : 0}
          close={() => setOpenAddCakeDialog(false)}
          handleSuccess={handleAddCakeSuccess}
        />
      ) : null}

      {openDeleteCakeDialog ? (
        <DeleteCakeDialog
          open={openDeleteCakeDialog}
          hasTasks={tasks.length > 0}
          cakeName={selectedCake?.title}
          onClose={() => setOpenDeleteCakeDialog(false)}
          onConfirm={handleDeleteCake}
        />
      ) : null}

      {openHiddenCakeDialog && selectedCake ? (
        <HiddenCakeDialog
          open={openHiddenCakeDialog}
          isHidden={selectedCake.isHidden}
          cakeName={selectedCake.title}
          onClose={() => setOpenHiddenCakeDialog(false)}
          onConfirm={handleHiddenCake}
        />
      ) : null}
    </>
  );
}
