import { object as YupObject, string as YupString } from 'yup';

export interface ReasonTypeFormValues {
  reasons: Array<ActivityCancellationReasonType | ActivityClosureReasonType>;
}

export interface ActivityCancellationReasonType {
  activityCancellationReasonId?: string;
  listId?: string;
  label: string;
}

export interface ActivityClosureReasonType {
  activityClosureReasonId?: string;
  listId?: string;
  label: string;
}

export const validationSchema = YupObject().shape({
  reason: YupString()
    .trim()
    .required('Please provide a reason'),
});

export enum ActivityReasonType {
  'cancellation',
  'closure',
}

const CancellationReasonsFormSectionContent = {
  formTitle: 'Cancellation Reasons',
  helperText: 'These options will appear when cancelling a roster for a specific date',
  addButtonText: 'Add cancellation reason',
  addReasonDialogTitle: 'Add new activity cancellation reason',
  removeReasonDialogTitle: 'Confirm Removal',
  removeReasonDialogContent: [
    `Are you sure you want  to remove $reasonName ?`,
    'This reason will no longer be an option when cancelling a roster.',
    'Existing cancellations will not be impacted.',
  ],
};

const ClosureReasonsFormSectionContent = {
  formTitle: 'Activity Closure Reasons',
  helperText: 'Options for permanently closing activities',
  addButtonText: 'Add closure reason',
  addReasonDialogTitle: 'Add new activity closure reason',
  removeReasonDialogTitle: 'Confirm Removal',
  removeReasonDialogContent: [
    `Are you sure you want to remove $reasonName ?`,
    'This reason will no longer be an option when closing an activity.',
    'Existing closures will not be impacted.',
  ],
};

export const generateFormContentBasedOnType = (type: ActivityReasonType) => {
  if (type === ActivityReasonType.cancellation) {
    return CancellationReasonsFormSectionContent;
  }
  if (type === ActivityReasonType.closure) {
    return ClosureReasonsFormSectionContent;
  }
  return {
    formTitle: '',
    helperText: '',
    addButtonText: '',
    addReasonDialogTitle: '',
    removeReasonDialogTitle: '',
    removeReasonDialogContent: [],
  };
};
