import React, { useState, ReactNode, useEffect } from 'react';
import { Box, Collapse, IconButton, Theme, Typography } from '@material-ui/core';
import { ExpandMore, ExpandLess, KeyboardArrowRight } from '@material-ui/icons';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

type ExpandableCardStatusType = 'completed' | 'new' | 'na' | 'pending';

type ExpandableCardProps = {
  children: any;
  startIcon?: ReactNode;
  status: ExpandableCardStatusType;
  cardTitle: string;
  isActionCancelled?: boolean;
  setIsActionCancelled?: (x: boolean) => void;
};

type ExpandableCardTitleProps = {
  color: string;
  title: string;
};

type ExpandButtonProps = {
  color: string;
  background: string;
};

interface CustomIconButtonProps extends ExpandButtonProps {
  children: any;
}

interface TaskStatusColorsType {
  cardBackgroundColor: string;
  textColor: string;
  buttonBackgroundColor: string;
  buttonIconColor: string;
  iconColor: string;
}

export function getStatusColors(status: ExpandableCardStatusType, theme: Theme): TaskStatusColorsType {
  if (status === 'completed')
    return {
      cardBackgroundColor: theme.alert.green.extraLight,
      textColor: theme.alert.green.dark,
      buttonBackgroundColor: theme.alert.green.mediumLight,
      buttonIconColor: theme.color.white.neutral50,
      iconColor: theme.alert.green.light,
    };
  if (status === 'na')
    return {
      cardBackgroundColor: theme.color.grey.neutralBrand100,
      textColor: theme.color.grey.neutral400,
      buttonBackgroundColor: theme.color.grey.neutral200,
      buttonIconColor: theme.color.white.neutral50,
      iconColor: theme.color.grey.neutral300,
    };
  if (status === 'pending')
    return {
      cardBackgroundColor: theme.alert.yellow.extraLight,
      textColor: theme.alert.yellow.medium,
      buttonBackgroundColor: theme.alert.yellow.light,
      buttonIconColor: theme.color.white.neutral50,
      iconColor: theme.alert.yellow.light,
    };
  // Default for status 'new'
  return {
    cardBackgroundColor: theme.alert.red.extraLight,
    textColor: theme.alert.red.dark,
    buttonBackgroundColor: theme.alert.red.mediumLight,
    buttonIconColor: theme.color.white.neutral50,
    iconColor: theme.alert.red.light,
  };
}

export const ExpandableCardTitle = (props: ExpandableCardTitleProps) => (
  <Typography variant='h4' style={{ fontSize: 'inherit', letterSpacing: '-0.02em', color: props.color }}>
    {props.title}
  </Typography>
);

export const CustomIconButton = (props: CustomIconButtonProps) => (
  <IconButton
    aria-label='expandable-card-expand-button'
    component='span'
    style={{
      background: props.background,
      color: props.color,
      padding: '0px 4px',
      fontSize: '24px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      height: '33px',
    }}
  >
    {props.children}
  </IconButton>
);

export const ExpandButton = (props: ExpandButtonProps) => (
  <CustomIconButton {...props}>
    <ExpandMore fontSize={'inherit'} />
  </CustomIconButton>
);

export const GoToButton = (props: ExpandButtonProps) => (
  <CustomIconButton {...props}>
    <KeyboardArrowRight fontSize={'inherit'} />
  </CustomIconButton>
);

export const CollapseButton = (props: ExpandButtonProps) => (
  <CustomIconButton {...props}>
    <ExpandLess fontSize={'inherit'} />
  </CustomIconButton>
);

export const ExpandableCard = (props: ExpandableCardProps) => {
  const { children, startIcon, cardTitle, status, isActionCancelled, setIsActionCancelled } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const expand = () => setIsExpanded(!isExpanded);

  const { theme } = useCampfireTheme();
  const { cardBackgroundColor, textColor, buttonBackgroundColor, buttonIconColor } = getStatusColors(status, theme);

  useEffect(() => {
    if (!isActionCancelled) return;
    setIsExpanded(false);
  }, [isActionCancelled]);

  useEffect(() => {
    if (!setIsActionCancelled) return;
    setIsActionCancelled(false);
  }, [isExpanded]);

  return (
    <Box>
      <Box
        bgcolor={cardBackgroundColor}
        p={'11px 20px'}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        onClick={expand}
      >
        <Box display='inline-flex' alignItems='center' fontSize='1rem' fontWeight={600}>
          {startIcon ? <Box pr={'12px'}>{startIcon}</Box> : null}
          {<ExpandableCardTitle color={textColor} title={cardTitle} />}
        </Box>
        {isExpanded ? (
          <CollapseButton background={buttonBackgroundColor} color={buttonIconColor} />
        ) : (
          <ExpandButton background={buttonBackgroundColor} color={buttonIconColor} />
        )}
      </Box>
      <Collapse in={isExpanded} timeout={0}>
        {children}
      </Collapse>
    </Box>
  );
};
