import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionHeading: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 900,
      fontSize: '1rem',
    },
    field: {
      marginTop: '1rem',
    },
    textField: {
      marginTop: '1.5rem',
    },
    fieldLabel: {
      marginTop: '1rem',
      fontSize: '14px',
      fontWeight: 700,
      color: theme.color.grey.neutral500,
    },
    inputText: {
      fontSize: '14px',
      borderRadius: '8px',
    },
    sectionBody: {
      fontWeight: 400,
      color: theme.color.grey.neutral200,
      fontSize: '0.875rem',
      marginTop: '6px',
      marginBottom: '8px',
    },
    infoBody: {
      color: theme.alert.blue.dark,
      fontSize: '14px',
      fontWeight: 400,
    },
    switchText: {
      fontSize: '16px',
      color: '#000000',
      opacity: 0.6,
    },
    switchHeading: {
      fontWeight: 700,
    },
    switchBody: {
      fontWeight: 400,
    },
  })
);
