import { TabletButton } from '@campfire/tablet-button';
import { FilterList } from '@material-ui/icons';
import React, { memo, useState } from 'react';
import { ActivitiesExploreProgram } from '../../__generated__/ActivitiesExploreProgram';
import { Filters } from '../../../../../common/filter-fields/filters';
import { ActivitiesFilterDialog } from './ActivitiesFilterDialog';

type ActivitiesFilterDialogButtonProps = {
  selectedFilters: Filters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setSelectedProgramId: (x: string) => void;
  selectedProgramId: string;
  programs: ActivitiesExploreProgram[];
  isManagementView?: boolean;
  clearFilters: () => void;
  numberNonDefaultFilters: number;
};

const ActivitiesFilterDialogButton = memo((props: ActivitiesFilterDialogButtonProps) => {
  const {
    selectedFilters,
    setSelectedFilters,
    setSelectedProgramId,
    selectedProgramId,
    programs,
    isManagementView,
    clearFilters,
    numberNonDefaultFilters,
  } = props;
  const [openFiltersDialog, setOpenFiltersDialog] = useState<boolean>(false);
  const hasFiltersApplied = numberNonDefaultFilters > 0;

  return (
    <>
      <TabletButton
        data-track='actCnlMap-OpenFilters'
        variant={hasFiltersApplied ? 'outlined' : 'text'}
        color={hasFiltersApplied ? 'primary' : 'default'}
        endIcon={<FilterList />}
        onClick={() => setOpenFiltersDialog(true)}
      >
        Filters
      </TabletButton>
      <ActivitiesFilterDialog
        open={openFiltersDialog}
        onClose={() => setOpenFiltersDialog(false)}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setSelectedProgramId={setSelectedProgramId}
        selectedProgramId={selectedProgramId}
        programs={programs}
        isManagementView={isManagementView}
        clearFilters={clearFilters}
      />
    </>
  );
});

export { ActivitiesFilterDialogButton };
