import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { ReactNode } from 'react';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';

export const CommonSectionSkeleton = () => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item xs={12} container justify='space-between' alignItems='center' style={{ paddingTop: '18px' }}>
        <Skeleton variant='text' width={100} height={16} />
        <Skeleton variant='text' width={150} height={16} />
      </Grid>
    </Grid>
  );
};
export const FormSectionSkeleton = ({ title, description }: { title: string; description: ReactNode }) => {
  return (
    <FormSectionV2 title={title} description={description}>
      <CommonSectionSkeleton />
    </FormSectionV2>
  );
};
