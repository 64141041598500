import React from 'react';
import { Avatar, AvatarProps } from '@material-ui/core';
import { first } from 'lodash';
import { getAvatarColour } from '../screens/general/user-profile/components/Avatar';

interface ProfileAvatarProps extends AvatarProps {
  profile: {
    lastName: string;
    preferredName: string;
    avatarUrl?: string | null;
  };
}
export function ProfileAvatar({ profile, ...remainProps }: ProfileAvatarProps) {
  const name = `${profile.preferredName} ${profile.lastName}`;
  if (profile.avatarUrl) {
    return <Avatar alt={name} src={profile.avatarUrl} {...remainProps} />;
  }
  const shortenName = `${first(profile.preferredName)}${first(profile.lastName)}`.toUpperCase();
  const initials = name
    .split(' ')
    .map((n) => n[0])
    .join('');
  const colour = getAvatarColour(initials);
  return (
    <Avatar
      {...remainProps}
      style={{
        fontSize: '1rem',
        fontWeight: 'bold',
        backgroundColor: `#${colour}`,
      }}
    >
      {shortenName}
    </Avatar>
  );
}
