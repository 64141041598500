import React from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParams } from 'use-query-params';
import { ProgramsContext } from '../programs-sell/ProgramsContext';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { SessionContext } from '../auth/SessionContext';
import { useUser } from '../auth/useUser';
import { ProgramFilters } from './ProgramFilters';

interface ProgramFiltersWrapperProps {
  children: React.ReactElement;
  managementView?: boolean;
}

export function ProgramFiltersWrapper(props: ProgramFiltersWrapperProps) {
  const session = React.useContext(SessionContext);
  const isLargeOrg = session?.user?.orgInfo.isLargeOrg;
  const [{ programId }] = useQueryParams({
    programId: StringParam,
  });

  const {
    userIdentity,
    user: { userIdentityService },
  } = useUser();

  const shouldProgramFilterOpen =
    !programId &&
    isLargeOrg &&
    (userIdentityService.isVmAdmin ||
      ((userIdentityService.isActivityLeader || userIdentityService.isProgramManager) &&
        (userIdentity.vm?.volunteerIdentity?.programIdentities || []).length >= 2));

  const [openProgramFilter, setOpenProgramFilter] = React.useState(shouldProgramFilterOpen);
  const { getAuthorizedPrograms, selectedPrograms, setSelectedPrograms } = React.useContext(ProgramsContext);
  const { isMobile } = useCampfireTheme();
  const history = useHistory();

  const programs = getAuthorizedPrograms(props.managementView) || [];

  const onClose = () => {
    setOpenProgramFilter(false);
  };

  if (openProgramFilter) {
    return (
      <ProgramFilters
        programs={programs || []}
        selectedPrograms={selectedPrograms}
        setSelectedPrograms={setSelectedPrograms}
        open={openProgramFilter}
        onClose={onClose}
        onCancel={() => history.goBack()}
        fullScreen={isMobile}
      />
    );
  }

  return props.children;
}
