import React from 'react';
import { Select } from '@campfire/select';
import { Grid, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import {
  CreatWidgetFormValues,
  intervalLabelMap,
  rangeHightlightMaping,
  rangeIntervalMaping,
  rangeLabelMap,
  rangeList,
} from '../dashboard-widget-model';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.color.grey.neutral500,
      fontWeight: 700,
      fontSize: '18px',
      marginBottom: '8px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 700,
      color: theme.color.grey.neutral500,
      paddingBottom: '6px',
    },
    valueItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '56px',
      borderRadius: '6px',
      marginTop: '-8px',
      padding: '0 16px',
      cursor: 'pointer',
      position: 'relative',

      border: `1px solid ${theme.color.grey.neutralBrand200}`,
    },
  })
);

interface TimeSettingsProps {
  showHighlight: boolean;
  showInterval: boolean;
}

export function TimeSettings({ showHighlight, showInterval }: TimeSettingsProps) {
  const classes = useStyles();
  const { values, setFieldValue, handleChange } = useFormikContext<CreatWidgetFormValues>();
  const { theme } = useCampfireTheme();

  const { range, highlight, interval } = values;

  const intervalOptions = React.useMemo(() => {
    if (range === null) {
      return [];
    }
    return rangeIntervalMaping[range];
  }, [range]);

  const hightlightOptions = React.useMemo(() => {
    if (range === null) {
      return [];
    }
    return rangeHightlightMaping[range];
  }, [range]);

  const intervalList = intervalOptions.map((option) => intervalLabelMap[option]);
  const hightlightList = hightlightOptions.map((option) => rangeLabelMap[option]);

  React.useEffect(() => {
    if (interval === null || !intervalOptions.includes(interval)) {
      setFieldValue('interval', intervalOptions[0]);
    }
    if (highlight === null || !hightlightOptions.includes(highlight)) {
      setFieldValue('highlight', hightlightOptions[0]);
    }
  }, [range, intervalOptions, hightlightOptions, highlight, interval]);

  return (
    <Grid item xs={12}>
      <Typography className={classes.title}>Range</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography className={classes.label}>Range</Typography>
          <Select
            fullWidth
            name='range'
            variant='outlined'
            items={rangeList}
            renderFunction={(item) => item.label}
            valueFunction={(item) => item.value}
            InputProps={{
              style: { borderRadius: '8px', color: theme.color.grey.neutral300 },
            }}
            onChange={handleChange}
          />
        </Grid>
        {showInterval && (
          <Grid item xs={6}>
            <Typography className={classes.label}>Interval</Typography>
            <TextField
              fullWidth
              select
              variant='outlined'
              placeholder='Interval'
              InputProps={{
                style: { borderRadius: '8px', color: theme.color.grey.neutral300 },
              }}
              value={interval}
              onChange={(e) => setFieldValue('interval', e.target.value)}
            >
              {intervalList.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        {showHighlight && (
          <Grid item xs={6}>
            <Typography className={classes.label}>Highlight</Typography>
            <TextField
              fullWidth
              select
              variant='outlined'
              placeholder='Highlight'
              InputProps={{
                style: { borderRadius: '8px', color: theme.color.grey.neutral300 },
              }}
              value={highlight}
              onChange={(e) => setFieldValue('highlight', e.target.value)}
            >
              {hightlightList.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
