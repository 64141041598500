import React from 'react';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

export function GoBack() {
  const history = useHistory();
  const onBack = () => {
    history.push('/admin/admin-console/apps');
  };
  const { theme } = useCampfireTheme();
  return (
    <ButtonBase disableRipple onClick={onBack}>
      <Box display='flex' alignItems='center' style={{ marginBottom: '1rem' }}>
        <ChevronLeft style={{ color: theme.palette.text.secondary, width: '1rem' }} />
        <Typography style={{ fontSize: '14px', lineHeight: '24px' }} color='textSecondary'>
          Back
        </Typography>
      </Box>
    </ButtonBase>
  );
}
