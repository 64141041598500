import React from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';

interface CombinationLogicProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CombinationLogic({ value, onChange }: CombinationLogicProps) {
  return (
    <RadioGroup name='combination' value={value} onChange={onChange} style={{ flexDirection: 'row' }}>
      <FormControlLabel
        value='exclude'
        control={<Radio size='medium' color='primary' />}
        label={<Typography style={{ fontSize: '14px' }}>Matches all conditions</Typography>}
      />
      <FormControlLabel
        value='include'
        control={<Radio size='medium' color='primary' />}
        label={<Typography style={{ fontSize: '14px' }}>Matches any conditions</Typography>}
      />
    </RadioGroup>
  );
}
