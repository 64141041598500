import React from 'react';
import { useFormikContext } from 'formik';
import { CreatWidgetFormValues, WidgetTypes } from '../../dashboard-widget-model';
import { BarChartWidgetForm } from './BarChartWidgetForm';
import { LineChartWidgetForm } from './LineChartWidgetForm';
import { PieChartWidgetForm } from './PieChartWidgetForm';

export function ChartWidgetForm() {
  const { values: formValues } = useFormikContext<CreatWidgetFormValues>();

  switch (formValues.chartWidgetType) {
    case WidgetTypes.BAR:
    case WidgetTypes.COLUMN:
      return <BarChartWidgetForm />;
    case WidgetTypes.LINE:
    case WidgetTypes.AREA:
      return <LineChartWidgetForm />;
    case WidgetTypes.PIE:
      return <PieChartWidgetForm />;
    default:
      return null;
  }
}
