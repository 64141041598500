import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import { useCampfireTheme } from '../../../../../../../../../theme/useCampfireTheme';
import { TaskFieldTypeEnum } from '../../../../admin-console-volunteer-profile-model';
import { FieldTaskItemTitle } from './FieldTaskItemTitle';
import { LockedStatus } from './LockedStatus';
import { TextFieldPreview } from './TextFieldPreview';

interface HardCodedFieldTaskItemProps {
  __typename: TaskFieldTypeEnum;
  name: string;
  description?: string;
  optional: boolean;
}

export const HardCodedFieldTaskItem = (props: HardCodedFieldTaskItemProps) => {
  const { __typename, name, description, optional } = props;
  const { theme } = useCampfireTheme();

  return (
    <Card
      elevation={0}
      style={{ padding: 8, marginBottom: 8, width: '100%', border: `1px solid ${theme.color.grey.border}` }}
    >
      <Grid container spacing={1} style={{ padding: 8 }}>
        <FieldTaskItemTitle __typename={__typename} name={name} optional={optional} />

        <LockedStatus />

        <Grid item container>
          <Grid item xs={12}>
            <Typography color='textSecondary' variant='body2'>
              {description}
            </Typography>
          </Grid>

          <Grid item xs={10}>
            <TextFieldPreview />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
