import { unpackToDate } from '@campfire/hot-date';

function getActivityEndDateText(endDate?: string): string | undefined {
  if (!endDate) return undefined;
  const endDateTime = unpackToDate(endDate);
  const diffInDays = endDateTime.diffNow('days').toObject().days;
  const diffInYears = Math.floor(endDateTime.diffNow('years').toObject().years ?? 0);

  if (!diffInDays) return undefined;

  const endDateStamp = endDateTime.toLocaleString(
    diffInYears !== 0
      ? {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }
      : {
          day: 'numeric',
          month: 'short',
        }
  );

  if (diffInDays < 0) {
    return `Ended ${endDateStamp}`;
  }

  return `Ending ${endDateStamp}`;
}

export { getActivityEndDateText };
