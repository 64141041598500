import React from 'react';
import { Redirect } from 'react-router';
import { ImpactDashboardScreen } from '../../screens/general/impact-dashboard/ImpactDashboardScreen';
import { SupportScreen } from '../../screens/general/support/SupportScreen';
import { VolDashboardMyElementsScreenRouter } from '../../screens/general/vol-dashboard/vol-dashboard-my-elements/VolDashboardMyElementsScreenRouter';
import { CampfireSwitch } from '../common/CampfireSwitch';
import MiniRoute from '../common/MiniRoute';
import { useRoutesMap } from '../common/useRoutesMaps';

const getLegacyRoutes = ({ route, isAuthorized }: { route: string; isAuthorized: boolean }): Array<React.ReactNode> => [
  <MiniRoute
    //
    key={`/${route}/support-and-resources`}
    path={`/${route}/support-and-resources`}
    component={() => <Redirect to={`/${route}/resources`} />}
    isAuthorized={isAuthorized}
  />,
];

export const FixedContentBlock = () => {
  const {
    fixed: { route, isAuthorized },
  } = useRoutesMap();

  return (
    <CampfireSwitch>
      {getLegacyRoutes({ route, isAuthorized })}
      <MiniRoute
        //
        path={`/${route}/my-elements`}
        component={VolDashboardMyElementsScreenRouter}
        isAuthorized={isAuthorized}
      />
      <MiniRoute
        //
        path={`/${route}/resources`}
        component={SupportScreen}
        isAuthorized={isAuthorized}
      />
      <MiniRoute
        //
        path={`/${route}/impact-dashboard`}
        component={ImpactDashboardScreen}
        isAuthorized={isAuthorized}
      />
    </CampfireSwitch>
  );
};

export default FixedContentBlock;
