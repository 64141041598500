import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { formatDistanceDays, unpackToDate, unpackToDateTime } from '@campfire/hot-date';
import { makeStyles } from '@material-ui/styles';

const useDateDividerStyles = makeStyles({
  root: { position: 'relative', padding: '24px 0' },
  pill: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '150px',
    padding: '6px 16px 4px 16px',
    backgroundColor: '#FFF',
    borderRadius: '48px',
    border: `1px solid #bdbdbd`,
  },
});

export const DateDivider = React.memo((props: { date: string }) => {
  const { date } = props;
  const { pill, root } = useDateDividerStyles();

  const dateWeAreDealingWith = unpackToDate(date);
  const distanceInDays = formatDistanceDays(unpackToDateTime(date));
  const daySubtitle = dateWeAreDealingWith.toLocaleString({
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  });
  const isToday = distanceInDays === 'Today';

  return (
    <li className={root}>
      <Divider />
      <Box className={pill}>
        <Typography variant='body2' color={isToday ? 'primary' : 'textSecondary'} align='center' noWrap>
          {`${daySubtitle} (${distanceInDays})`}
        </Typography>
      </Box>
    </li>
  );
});
