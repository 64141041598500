import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

const Logo = () => {
  const { theme } = useCampfireTheme();
  const useStyles = makeStyles({
    logo: {
      fontSize: '30px',
      fontWeight: 300,
      color: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      letterSpacing: '4px',
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.logo}>
      <p>Volaby</p>
    </div>
  );
};

export { Logo };
