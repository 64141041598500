import { HoverLink } from '@campfire/hover-link';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Typography, List, ListItemText, ListItem, ListItemIcon, Box, Divider } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import React, { memo } from 'react';
import { SectionCollapsible } from '../common/SectionCollapsible';
import { SectionHeader } from '../common/SectionHeader';
import { VolunteerCommonProfileActivityApplication } from '../__generated__/VolunteerCommonProfileActivityApplication';
import { useVolunteerCommonProfileContext } from '../VolunteerCommonProfileContext';
import { SectionInstruction } from '../common/SectionInstruction';

export const VolunteerActivityApplicationsSection = memo(
  ({ activityApplications }: { activityApplications: VolunteerCommonProfileActivityApplication[] }) => {
    const {
      volunteerNameOptions: { preferredName },
    } = useVolunteerCommonProfileContext();
    return (
      <SectionCollapsible
        sectionHeader={<SectionHeader title='Applied for activities' />}
        instruction={<SectionInstruction>{`All activities that ${preferredName} has applied for`}</SectionInstruction>}
        collapseContent={
          <>
            <Typography variant='caption'>{`Activities that ${preferredName} has applied for`}</Typography>
            <List dense>
              {activityApplications
                .filter((activityApplication) => activityApplication.activityApplicationId !== null)
                .map((activityApplication, idx) => (
                  <HoverLink
                    data-track='fs-volPrf-activity-application-item'
                    key={activityApplication.activityApplicationId}
                    hoverColor='primary'
                    to={`/management/activity-management?activityId=${activityApplication.activity.activityId}&programId=${activityApplication.activity.program.programId}`}
                    disableUnderline
                  >
                    <TitularTooltip description='Open Activity Overview'>
                      <Box key={activityApplication.activityApplicationId}>
                        <ListItem key={activityApplication.activityApplicationId} button>
                          <ListItemIcon>
                            <LinkIcon color='disabled' />
                          </ListItemIcon>
                          <ListItemText
                            primary={activityApplication.activity.name}
                            secondary={activityApplication.activity.program.name}
                          />
                        </ListItem>
                        {idx !== activityApplications.length - 1 && <Divider style={{ marginTop: '8px' }} />}
                      </Box>
                    </TitularTooltip>
                  </HoverLink>
                ))}
            </List>
          </>
        }
        expandText='View Activity Applications'
        idStub='activity-applications'
      />
    );
  }
);
