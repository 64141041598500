import { Box, SvgIconProps, Typography } from '@material-ui/core';
import { Cancel, Warning, Info, CheckCircle, Notes } from '@material-ui/icons';
import React, { ReactNode } from 'react';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

type AlertCardVariant = 'urgent' | 'warning' | 'info' | 'success' | 'notes';
interface Props {
  variant: AlertCardVariant;
  title?: string;
  children?: ReactNode;
}
interface AlertCardColors {
  backgroundColor: string;
  darkTextColor: string;
  lightTextColor: string;
  iconColor: string;
  rightIcon: React.ComponentType<SvgIconProps>;
}

export function getAlertCardColors(variant: AlertCardVariant): AlertCardColors {
  const { theme } = useCampfireTheme();
  if (variant.toLowerCase() === 'urgent')
    return {
      backgroundColor: theme.alert.red.extraLight,
      darkTextColor: theme.alert.red.dark,
      lightTextColor: theme.alert.red.medium,
      iconColor: theme.alert.red.light,
      rightIcon: Cancel,
    };
  if (variant.toLowerCase() === 'warning')
    return {
      backgroundColor: theme.alert.yellow.extraLight,
      darkTextColor: theme.alert.yellow.dark,
      lightTextColor: theme.alert.yellow.medium,
      iconColor: theme.alert.yellow.light,
      rightIcon: Warning,
    };
  if (variant.toLowerCase() === 'success') {
    return {
      backgroundColor: theme.alert.green.extraLight,
      darkTextColor: theme.alert.green.dark,
      lightTextColor: theme.alert.green.medium,
      iconColor: theme.alert.green.light,
      rightIcon: CheckCircle,
    };
  }
  if (variant.toLocaleLowerCase() === 'notes') {
    return {
      backgroundColor: '#d9d9d9',
      darkTextColor: theme.color.grey.neutralBrand800,
      lightTextColor: theme.color.grey.neutralBrand300,
      iconColor: theme.color.grey.neutralBrand300,
      rightIcon: Notes,
    };
  }
  // default colors: variant info (blue colors set)
  return {
    backgroundColor: theme.alert.blue.extraLight,
    darkTextColor: theme.alert.blue.dark,
    lightTextColor: theme.alert.blue.medium,
    iconColor: theme.alert.blue.light,
    rightIcon: Info,
  };
}
export const AlertCardBody = ({ children }: { children: ReactNode }) => <Box py='4px'>{children}</Box>;

export const AlertCard = ({ variant, title, children }: Props) => {
  const { backgroundColor, darkTextColor, iconColor, rightIcon: RightIcon } = getAlertCardColors(variant);
  return (
    <Box bgcolor={backgroundColor} display='flex' style={{ borderRadius: '6px', padding: '20px' }}>
      <RightIcon
        style={{
          height: '20px',
          paddingRight: '14px',
          color: iconColor,
        }}
      />
      <Box>
        {title ? (
          <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 700, color: darkTextColor }}>
            {title}
          </Typography>
        ) : null}
        {children ?? null}
      </Box>
    </Box>
  );
};
