import { gql } from '@apollo/client';

const SUPPORT_RESOURCE_ATTACHMENT = gql`
  fragment SupportResourceAttachment on VOLUNTEER_SupportResourceAttachmentType {
    supportResourceAttachmentId
    fileType
    url
    name
  }
`;

const SUPPORT_RESOURCE = gql`
  fragment SupportResource on VOLUNTEER_SupportResourceType {
    supportResourceId
    featured
    title
    textContent
    fixed
    dateAdded
    programs {
      programId
      name
    }
    activities {
      activityId
      name
    }
    activityRoles {
      activityRoleId
      name
    }
    activityTags {
      activityTagId
      name
    }
    permissionLevel 
    supportResourceAttachments {
      ...SupportResourceAttachment
    }
  }

  ${SUPPORT_RESOURCE_ATTACHMENT}
`;
export const GET_SUPPORT_CATEGORIES = gql`
  query GetSupportCategories {
    vm {
      supportCategories {
        ...SupportCategory
      }
    }
  }

  fragment SupportCategory on VOLUNTEER_SupportCategoryType {
    supportCategoryId
    title
    supportResources {
      ...SupportResource
    }
  }

  ${SUPPORT_RESOURCE}
`;

export const GET_SUPPORT_RESOURCE = gql`
  query GetSupportResource($resourceId: String!) {
    vm {
      supportResource(supportResourceId: $resourceId) {
        ...SupportResource
      }
    }
  }

  ${SUPPORT_RESOURCE}
`;

export const SUPPORT_RESOURCE_GET_ALL_PROGRAMS = gql`
  query SupportResourceGetAllPrograms {
    vm {
      programs {
        programId
        name
        dateSuspended
        activities {
          activityId
          name
        }
      }
    }
  }
`;


export const SUPPORT_RESOURCE_GET_ALL_ROLES = gql`
  query SupportResourceGetAllRoles {
    vm {
      activityRoles {
        activityRoleId
        name
      }
    }
  }
`;

export const SUPPORT_RESOURCE_GET_ALL_TAGS = gql`
  query SupportResourceGetAllTags {
    vm {
      activityTags {
        activityTagId
        name
      }
    }
  }
`;
