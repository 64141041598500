import React from 'react';
import { Box } from '@material-ui/core';
import { EditorState, convertFromRaw } from 'draft-js';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { ActivityHeader } from './ActivityHeader';
import { ActivityDescription as ActivityDescriptionProps } from './__generated__/ActivityDescription';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface Props {
  inMyShift?: boolean;
  isEnrolled?: boolean;
  isApplicationOrWaitlisting?: string | null;
  activityId?: string;
  applicationId?: string;
  refetch?: Function;
  actionButton: React.ReactNode;
  children: any;
}

export function ActivityDescription({ name, description, actionButton, children }: ActivityDescriptionProps & Props) {
  const editorState = React.useMemo(
    () => (description ? EditorState.createWithContent(convertFromRaw(JSON.parse(description))) : null),
    [description]
  );
  const { theme } = useCampfireTheme();

  if (!editorState) {
    return null;
  }

  return (
    <Box paddingY={1} style={{ color: theme.color.grey.neutral400, fontSize: '16px', lineHeight: '22.4px' }}>
      <ActivityHeader title={name} action={actionButton} />
      <Box>{children}</Box>
      <Box marginTop='0.75rem'>
        <CampfireRichEditor editorState={editorState} readOnly />
      </Box>
    </Box>
  );
}
