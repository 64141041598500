import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, BoxProps, ClickAwayListener, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import React, { useState } from 'react';
import { ROSTER_SCROLL_DIMENSION } from './use-roster-scrolling';

type Props = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  hideSubtitle?: boolean;
} & BoxProps;

const RosterTableTitleBlock = (props: Props) => {
  const { title, subtitle, children, hideSubtitle, ...rest } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const handleTooltipOpen = () => {
    setShowTooltip(true);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-end'
      justifyItems='flex-end'
      width={ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH}
      minWidth={ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH}
      maxWidth={ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH}
      height={ROSTER_SCROLL_DIMENSION.TABLE_HEADER_HEIGHT}
      paddingLeft={2}
      paddingBottom={1}
      boxSizing='border-box'
      overflow='hidden'
      {...rest}
    >
      <Typography noWrap variant='h6' component='span' style={{ lineHeight: 1 }} gutterBottom={false}>
        {title}

        {hideSubtitle ? (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <TitularTooltip
              open={showTooltip}
              onClose={handleTooltipClose}
              onOpen={handleTooltipOpen}
              title={title}
              description={subtitle}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <HelpOutline
                data-track='actCnl-roster-table-title-help'
                onClick={handleTooltipOpen}
                color='action'
                style={{ fontSize: 16, marginLeft: 4 }}
              />
            </TitularTooltip>
          </ClickAwayListener>
        ) : null}
      </Typography>

      {!hideSubtitle ? (
        <Typography noWrap variant='caption' color='textSecondary' display='block'>
          {subtitle}
        </Typography>
      ) : null}
      {children}
    </Box>
  );
};

export { RosterTableTitleBlock };
