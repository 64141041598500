import { Button, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { CopyBlock, ocean } from 'react-code-blocks';
import { getMapUrl } from './helpers/embeddableBaseUrl';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    tabSummary: {
      marginTop: theme.spacing(2),
    },
  })
);

const InstructionsMap = () => {
  const classes = useStyle();
  const [open, setOpen] = useState<boolean>(false);
  const baseUrl = getMapUrl();

  const iframeTag = `<iframe src="${baseUrl}/index.html/?volaby_id={your-volaby-id-here}" />`;

  return (
    <>
      <div className={classes.tabSummary}>
        <p>
          Display an interactive map populated with activity locations. A list view with additional activity information
          is also included as part of this widget. Users will be able to search, sort and filter activities. Search for
          any activity by name.
        </p>

        <p>Options to filter activities by day, Program, Activity Type, and time frame.</p>

        <p>Requiring only your Volaby ID for configuration.</p>
      </div>

      {open ? (
        <></>
      ) : (
        <Typography align='center'>
          <Button
            color='primary'
            disabled={false}
            size='medium'
            endIcon={<ExpandMoreIcon />}
            onClick={() => setOpen(true)}
          >
            <span>Read more</span>
          </Button>
        </Typography>
      )}

      {open ? (
        <>
          <hr />

          <Typography variant='h6' gutterBottom>
            How to embed the activity map widget:
          </Typography>

          <p>
            1. Embed the following tag within the <code>&lt;body&gt;</code> tag where you want the map displayed
          </p>

          <CopyBlock codeBlock showLineNumbers language='markup' theme={ocean} text={iframeTag} />

          <p>
            2. Replace <code>{'{your-volaby-id-here}'}</code> with your Volaby ID, found below.
          </p>

          <Typography align='center'>
            <Button
              color='primary'
              disabled={false}
              size='medium'
              endIcon={<CloseIcon />}
              onClick={() => setOpen(false)}
            >
              <span>Close</span>
            </Button>
          </Typography>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default InstructionsMap;
