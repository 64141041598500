import { gql } from '@apollo/client';

export const GET_ROSTER_SCREEN_ROSTER_DATA = gql`
  query GetRosterScreenRosterData($activityId: String!, $activityDate: Date!) {
    orgLogo
    vm {
      activity(activityId: $activityId) {
        ...RosterScreenActivity
      }
      draftRoster(activityId: $activityId, activityDate: $activityDate) {
        ...RosterScreenDraftRoster
      }
    }
  }

  fragment RosterScreenDraftRoster on VOLUNTEER_DraftRosterType {
    draftRosterId
    activityDate
    rosterNotes
    draftRosterings {
      ...RosterScreenDraftRostering
    }
    sessionNotes {
      session {
        sessionId
        name
      }
      notes
    }
    publishedRoster {
      rosterId
      rosterNotes
      sessionNotes {
        session {
          sessionId
          name
        }
        notes
      }
      rosterings {
        ...RosterScreenPublishedRosterRostering
      }
      activeRosterings {
        ...RosterScreenPublishedRosterRostering
      }
    }
  }

  fragment RosterScreenDraftRostering on VOLUNTEER_DraftRosteringType {
    draftRosteringId
    volunteer {
      volunteerId
      profile {
        profileId
        preferredName
        lastName
        avatarUrl
        userId
      }
    }
    sessionRosterings {
      draftSessionRosteringId
      session {
        sessionId
      }
    }
  }

  fragment RosterScreenPublishedRosterRostering on VOLUNTEER_PublishedRosteringType {
    publishedRosteringId
    attending
    volunteer {
      volunteerId
      profile {
        profileId
        preferredName
        lastName
        avatarUrl
        userId
      }
    }
    sessionRosterings {
      publishedSessionRosteringId
      session {
        sessionId
      }
    }
  }

  fragment RosterScreenActivity on VOLUNTEER_ActivityType {
    activityId
    name
    isEnrollable
    isSuspended
    isActive
    closedActivity {
      ...RosterScreenClosedActivity
    }
    sessions {
      ...RosterScreenActivitySession
    }
    activityEnrolments {
      ...RosterScreenActivityEnrolments
    }
    program {
      programId
      name
    }
    cancelledActivities {
      ...RostersScreenCancelledActivity
    }
    activityReport(activityDate: $activityDate) {
      ...RostersScreenActivityReport
    }
    publishedRoster(activityDate: $activityDate) {
      datePublished
      rosterNotes
      publishedByManager {
        profile {
          lastName
          preferredName
          avatarUrl
        }
      }
      publishedByVolunteer {
        profile {
          lastName
          preferredName
          avatarUrl
        }
      }
      rosterings {
        publishedRosteringId
        volunteer {
          volunteerId
          profile {
            profileId
            userId
            preferredName
            lastName
            avatarUrl
          }
        }
        sessionRosterings {
          publishedSessionRosteringId
          session {
            sessionId
          }
        }
      }
    }
  }

  fragment RosterScreenActivitySession on VOLUNTEER_SessionType {
    sessionId
    name
    description
    minVolunteers
    maxVolunteers
    startTime
    endTime
    activity {
      program {
        programId
      }
    }
    reportType {
      reportTypeId
      name
      items {
        ... on VOLUNTEER_ReportTypeFieldType {
          reportTypeItemId
          order
          optional

          field {
            fieldId
            name
            description
          }
        }
        ... on VOLUNTEER_ReportTypeHeadingType {
          reportTypeItemId
          order
          heading
        }
      }
    }
  }

  fragment RosterScreenClosedActivity on VOLUNTEER_ClosedActivityType {
    closedActivityId
    activityClosureReason {
      label
    }
    closedBy {
      profile {
        profileId
        userId
        preferredName
        lastName
      }
    }
    closedDate
    endDate
  }

  fragment RosterScreenActivityEnrolments on VOLUNTEER_ActivityEnrolmentType {
    activityEnrolmentId
    position
    volunteer {
      volunteerId
      profile {
        profileId
        preferredName
        lastName
        avatarUrl
        userId
      }
    }
    availability(activityDate: $activityDate) {
      sessionAvailabilities {
        session {
          sessionId
        }
      }
    }
    unavailability(activityDate: $activityDate) {
      activityUnavailabilityId
    }
  }

  fragment RostersScreenCancelledActivity on VOLUNTEER_CancelledActivityType {
    cancelledActivityId
    activityDate
    description
    activityCancellationReason {
      label
    }
    attachments {
      activityAttachmentId
      fileType
      url
      name
    }
    cancelledBy {
      preferredName
      lastName
    }
    cancelledDate
  }

  fragment RostersScreenActivityReport on VOLUNTEER_ActivityReportType {
    activityDate
    sessionReports {
      session {
        sessionId
        reportType {
          reportTypeId
          name
          items {
            ... on VOLUNTEER_ReportTypeFieldType {
              reportTypeItemId
              order
              optional

              field {
                fieldId
              }
            }
            ... on VOLUNTEER_ReportTypeHeadingType {
              reportTypeItemId
              order
              heading
            }
          }
        }
      }
      startTime
      endTime
      comments
      submissionDate
      submittedBy {
        profile {
          preferredName
          lastName
        }
      }
      attendances {
        attendanceId
        activityRoles {
          activityRoleId
        }
        ... on VOLUNTEER_VolunteerAttendanceType {
          volunteer {
            volunteerId
            profile {
              userId
              profileId
              preferredName
              lastName
              avatarUrl
            }
          }
        }
        ... on VOLUNTEER_OtherAttendanceType {
          name
        }
      }

      fieldValues {
        fieldValueId
        field {
          fieldId
          name
          description
        }

        ... on VOLUNTEER_RatingFieldValueType {
          ratingValue
        }
        ... on VOLUNTEER_BooleanFieldValueType {
          boolValue
        }

        ... on VOLUNTEER_TextFieldValueType {
          textValue
        }

        ... on VOLUNTEER_TimeFieldValueType {
          timeValue
        }

        ... on VOLUNTEER_NumericFieldValueType {
          numericValue
        }

        ... on VOLUNTEER_CheckboxFieldValueType {
          checked
        }

        ... on VOLUNTEER_DropdownFieldValueType {
          dropdownFieldOptions {
            dropdownFieldOptionId
            name
          }
        }
      }
    }
  }
`;

export const GET_ROSTER_SCREEN_PROGRAM_VOLUNTEERS_DATA = gql`
  query GetRosterScreenProgramVolunteersData($programId: String!) {
    vm {
      program(programId: $programId) {
        activeVolunteers {
          volunteerId
          profile {
            ...RosterScreenProgramVolunteerProfile
          }
        }
      }
    }
  }

  fragment RosterScreenProgramVolunteerProfile on VOLUNTEER_ProfileType {
    profileId
    preferredName
    lastName
    avatarUrl
    userId
  }
`;

export const GET_ROSTER_SCREEN_ACTIVITY_ENROLMENT_DATA = gql`
  query GetRosterScreenActivityEnrolmentData($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityEnrolments {
          volunteer {
            volunteerId
          }
          dateEnrolled
          dateRemoved
        }
      }
    }
  }
`;

export const ROSTER_VOLUNTEER_INFO = gql`
  query GetRosterVolunteerInfo($volunteerId: String) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        activityRoles {
          activityRoleId
          icon
          name
          tasks {
            taskId
            title
            completedTasks {
              completedTaskId
              status
              dateLastUpdated
              profile {
                profileId
                userId
              }
            }
          }
        }
      }
    }
  }
`;
