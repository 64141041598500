import React from 'react';
import { Route, useRouteMatch } from 'react-router';
import { CampfireSwitch } from '../../content-blocks/common/CampfireSwitch';
import { EmailVerifiedLanding } from './EmailVerifiedLanding';

const StaticShell = () => {
  const match = useRouteMatch();

  return match ? (
    <main>
      <CampfireSwitch>
        <Route path={`${match.path}/verify-email`} render={() => <EmailVerifiedLanding />} />
      </CampfireSwitch>
    </main>
  ) : null;
};

export default StaticShell;
