import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { useProgramManagementContext } from '../../ProgramManagementContext';
import { ProgramManagementSingleProgramBasicInfo } from '../../__generated__/ProgramManagementSingleProgramBasicInfo';

interface Props {
  open: boolean;
  close: () => void;
  selectedProgram: ProgramManagementSingleProgramBasicInfo;
}
const SuspendProgramDialog = (props: Props) => {
  const { open, close, selectedProgram } = props;
  const { runSaveProgram } = useProgramManagementContext();
  return (
    <Dialog open={open}>
      <DialogTitle>{`Suspend the '${selectedProgram.name}' program?`}</DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          {
            'Suspending the program means that volunteers will no longer be able to access the activities that belong to this program.'
          }
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justify='flex-end'>
          <Grid item>
            <TabletButton onClick={() => close()}>{'Cancel'}</TabletButton>
          </Grid>
          <Grid item>
            <TabletButton
              variant='outlined'
              color='primary'
              onClick={() => {
                runSaveProgram({
                  programId: selectedProgram.programId,
                  name: selectedProgram.name,
                  description: selectedProgram.description ?? '',
                  active: false,
                  isHidden: selectedProgram.isHidden ?? false,
                  isRestrictedProgram: selectedProgram.isRestrictedProgram ?? false,
                });
                close();
              }}
            >
              {'Suspend program'}
            </TabletButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export { SuspendProgramDialog };
