import React from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Cancel } from '@material-ui/icons';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { EditorState, convertFromRaw } from 'draft-js';
import { FileUploadGallery } from '@campfire/file-upload-gallery';
import { unpackToDateTime } from '@campfire/hot-date';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useUncancelActivityFetch } from '../../activity-management/activity-form-v2/activity-form-actions-v2';
import { GetRosterScreenRosterData_vm_activity_cancelledActivities as CancelledActivity } from './roster/__generated__/GetRosterScreenRosterData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      fontSize: '14px',
      color: theme.alert.red.dark,
      '&>strong': {
        fontWeight: 500,
      },
    },
  })
);
interface Props {
  cancelledRoster: CancelledActivity;
  handleReactivateActivityRefetch?: () => void;
}

export const CancelledActivityInfo = (props: Props) => {
  const { theme } = useCampfireTheme();
  const { cancelledRoster, handleReactivateActivityRefetch } = props;
  const { setSnackbar } = useSnackbar();
  const uncancelActivity = useUncancelActivityFetch();
  const { description } = cancelledRoster;
  const editorState = React.useMemo(
    () => (description ? EditorState.createWithContent(convertFromRaw(JSON.parse(description))) : null),
    [description]
  );
  const cancelledDateTime = unpackToDateTime(cancelledRoster.cancelledDate).toFormat('dd/M/yyyy ta');

  const runUncancelActivity = () => {
    uncancelActivity
      .run({
        cancelledActivityId: cancelledRoster.cancelledActivityId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Could not resume activity',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Activity resumed',
          variant: 'success',
        });

        if (handleReactivateActivityRefetch) handleReactivateActivityRefetch();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Could not resume activity',
          variant: 'error',
        })
      );
  };

  const classes = useStyles();
  const attachments =
    cancelledRoster?.attachments?.map((attachment) => ({
      name: attachment.name,
      url: attachment.url,
      fileId: attachment.activityAttachmentId,
    })) || [];

  return (
    <Box bgcolor={theme.alert.red.extraLight} display='flex'>
      <Cancel
        style={{
          fontSize: '16px',
          padding: '20px 16px',
          color: theme.alert.red.light,
        }}
      />
      <Box pt={2} pb={'36px'} pr={2}>
        <Typography variant={'subtitle2'} className={classes.typography}>
          {'This activity has been cancelled'}
        </Typography>
        <Typography className={classes.typography} style={{ marginTop: '12px' }}>
          <strong>Reason:</strong>&nbsp;{cancelledRoster.activityCancellationReason.label}
        </Typography>
        {editorState?.getCurrentContent && editorState.getCurrentContent.length > 0 ? (
          <Typography className={classes.typography} style={{ marginTop: '12px' }}>
            <strong>Details:</strong>&nbsp;{editorState && <CampfireRichEditor editorState={editorState} readOnly />}
          </Typography>
        ) : (
          ''
        )}
        <Typography className={classes.typography} style={{ marginTop: '5px', fontSize: '12px' }}>
          Cancelled by
          <strong>
            &nbsp;{cancelledRoster.cancelledBy?.preferredName}&nbsp;{cancelledRoster.cancelledBy?.lastName}
          </strong>
          &nbsp;on <strong>{cancelledDateTime}</strong>
        </Typography>
        {attachments.length > 0 && (
          <Typography className={classes.typography} style={{ marginTop: '12px' }}>
            <strong>Attachments:</strong>&nbsp;
            {editorState && <FileUploadGallery allowMultiple uploadedFiles={attachments} viewOnly />}
          </Typography>
        )}
        <Typography className={classes.typography} style={{ marginTop: '20px' }}>
          {'To reactivate this activity again, please click the Reactivate button below.'}
        </Typography>
        <TabletButton
          variant='contained'
          size='small'
          style={{
            backgroundColor: '#7047EB',
            color: '#ffffff',
            boxSizing: 'border-box',
            borderRadius: '4px',
            textTransform: 'none',
            padding: '5px 12px',
            marginTop: '20px',
          }}
          onClick={() => runUncancelActivity()}
        >
          {'Reactivate'}
        </TabletButton>
      </Box>
    </Box>
  );
};
