import React, { useEffect } from 'react';
import { Box, Tab, Tabs, Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { VisibilityOutlined } from '@material-ui/icons';
import { StringParam, useQueryParam } from 'use-query-params';
import { useVolDashboardMyElementsContext } from '../VolDashboardMyElementsContext';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useUser } from '../../../../../global/auth/useUser';
import { MyActivitiesFilters } from './my-activities-filters/MyActivitiesFilters';

export const useMyElementsTabStyles = makeStyles(() =>
  createStyles({
    tab: {
      minWidth: '90px',
      paddingBottom: '14px',
    },
  })
);

export const AdminViewAction = () => {
  const { theme } = useCampfireTheme();
  const { viewMode, setViewMode } = useVolDashboardMyElementsContext();
  return (
    <Box display='flex' flexDirection='row' width='100%' justifyContent='flex-end' alignItems='center' pb='14px'>
      <Button
        variant='outlined'
        startIcon={<VisibilityOutlined />}
        style={{
          height: 36,
          borderRadius: 6,
          border: '1px solid #DCDCDC',
          color: theme?.color?.grey?.neutral400,
          textTransform: 'capitalize',
          marginLeft: '0.75rem',
        }}
        onClick={() => setViewMode(viewMode === 'all' ? 'restricted' : 'all')}
      >
        <Typography
          style={{
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '19px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          Viewing as: {viewMode === 'all' ? 'Admin' : 'Volunteer'}
        </Typography>
      </Button>
    </Box>
  );
};

export const TabName = ({ name, alertNumber }: { name: string; alertNumber?: number }) => {
  const { theme, isSm } = useCampfireTheme();
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <Typography
        variant='h4'
        style={{
          color: theme.color.grey.neutralBrand800,
          fontSize: isSm ? '16px' : '20px',
          fontWeight: isSm ? 400 : 800,
          whiteSpace: 'break-spaces',
          letterSpacing: '-0.02em',
          textTransform: 'none',
        }}
      >
        {name}
      </Typography>
      {alertNumber && alertNumber > 0 ? (
        <Box
          bgcolor='#FF7705'
          width={18}
          height={18}
          borderRadius='50%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          mx='8px'
        >
          <Typography variant='caption' style={{ color: theme.color.white.neutral50, fontSize: 10, fontWeight: 900 }}>
            {alertNumber}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export const MyElementsTabsHeader = () => {
  const [selectedTab, setSelectedTab] = useQueryParam('tab', StringParam);
  const classes = useMyElementsTabStyles();
  const {
    getVolunteerIdentity,
    user: { userIdentityService },
  } = useUser();
  const volunteerIdentity = getVolunteerIdentity();
  const { theme } = useCampfireTheme();
  const { newTasksNumber, getMyUnreadNewsNumber, getMyUnreadNewsNumberTabData } = useVolDashboardMyElementsContext();
  const isAdmin = userIdentityService.isVmAdmin;

  useEffect(() => {
    getMyUnreadNewsNumber({
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
      },
    });
  }, []);

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab('my-activities');
    }
  }, [selectedTab]);

  const myUnreadNews =
    getMyUnreadNewsNumberTabData?.vm.bulletins.filter((news) => !news.hasVolunteerViewedBulletin) ?? [];

  return (
    <>
      <Tabs
        indicatorColor='primary'
        value={selectedTab}
        aria-label='My Elements Personal Info Section'
        variant='standard'
        style={{
          borderBottom: `1px solid ${theme.color.grey.border}`,
          backgroundColor: 'white',
          paddingTop: 24,
          marginTop: -24,
          marginLeft: -60,
          marginRight: -60,
          paddingLeft: 60,
          paddingRight: 60,
        }}
      >
        <Tab
          disableRipple
          disableTouchRipple
          key='my-activities'
          value='my-activities'
          className={classes.tab}
          label={<TabName name='My Rosters' />}
          onClick={() => setSelectedTab('my-activities')}
        />
        <Tab
          disableRipple
          disableTouchRipple
          key='news'
          value='news'
          className={classes.tab}
          label={<TabName name='News' alertNumber={myUnreadNews.length} />}
          onClick={() => setSelectedTab('news')}
        />
        <Tab
          disableRipple
          disableTouchRipple
          key='tasks'
          value='tasks'
          className={classes.tab}
          label={<TabName name='Tasks' alertNumber={newTasksNumber} />}
          onClick={() => setSelectedTab('tasks')}
        />
        {selectedTab === 'my-activities' ? <MyActivitiesFilters /> : null}
        {(selectedTab === 'news' || selectedTab === 'tasks') && isAdmin ? <AdminViewAction /> : null}
      </Tabs>
    </>
  );
};
