import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import WidgetInstallationInstructions from './components/WidgetInstallationInstructions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabSummary: {
      marginTop: theme.spacing(2),
    },
  })
);

const InstructionsActivity = () => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const activityTag = `<activity-table></activity-table>`;

  const customExample = `<activity-table
  data-activity-ids="activity-id-one,activity-id-two"
  data-number-of-weeks="4"
</activity-table>`;

  const widgetAttributes = [
    {
      name: 'data-activity-ids',
      required: 'Required',
      values: [
        {
          name: 'my-activity-id-1,my-activity-id-2',
          description: 'Comma-separated list of activity IDs from the table below',
        },
      ],
    },
    {
      name: 'data-volaby-id',
      required: 'Required',
      values: [{ name: 'your-volaby-id-here', description: 'Your unique volaby ID. This can be found below' }],
    },
    {
      name: 'data-number-of-weeks',
      required: 'Required',
      values: [{ name: '4', description: 'The number of weeks ahead to show activities for' }],
    },
  ];

  return (
    <>
      <div className={classes.tabSummary}>
        <p>
          Display your upcoming activities schedule in a table format. For each activity, the day, next data, activity
          name, address and operation times will be displayed.
        </p>

        <p>
          The table defaults to a 1 week range for upcoming activities but that can be configured to any number of
          weeks.
        </p>

        <p>Requires only the activity IDs for configuration, as listed in the Activity ID table below.</p>
      </div>

      {open ? (
        <></>
      ) : (
        <Typography align='center'>
          <Button
            color='primary'
            disabled={false}
            size='medium'
            endIcon={<ExpandMoreIcon />}
            onClick={() => setOpen(true)}
          >
            <span>Read more</span>
          </Button>
        </Typography>
      )}

      {open ? (
        <>
          <WidgetInstallationInstructions
            widgetName='activity table'
            minimumExample={activityTag}
            customExample={customExample}
            widgetAttributes={widgetAttributes}
          />

          <Typography align='center'>
            <Button
              color='primary'
              disabled={false}
              size='medium'
              endIcon={<CloseIcon />}
              onClick={() => setOpen(false)}
            >
              <span>Close</span>
            </Button>
          </Typography>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default InstructionsActivity;
