import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../../../global/network/useEndpointFetch';

export const useSuspendActivityFetch = () => {
  return useEndpointFetch<{ activityId: string }>('/vm/activity/suspend');
};
export const useUnsuspendActivityFetch = () => {
  return useEndpointFetch<{ activityId: string }>('/vm/activity/unsuspend');
};
export const useCloseActivityFetch = () => {
  return useEndpointFetch<{ activityId: string; dateEnded: string; activityClosureReasonId: string }>(
    '/vm/activity/close'
  );
};

export const useUncloseActivityFetch = () => {
  return useEndpointFetch<{ closedActivityId: string }>('/vm/activity/unclose');
};
export const useActivityHeaderActions = () => {
  const suspendActivity = useSuspendActivityFetch();
  const unsuspendActivity = useUnsuspendActivityFetch();
  const closeActivity = useCloseActivityFetch();
  const uncloseActivity = useUncloseActivityFetch();
  const { setSnackbar } = useSnackbar();

  const runSuspendActivity = ({
    activityId,
    handleSuccess,
  }: {
    activityId: string;
    handleSuccess?: () => void;
  }) => {
    suspendActivity
      .run({
        activityId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to suspend activity',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Activity suspended',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to suspend activity',
          variant: 'error',
        })
      );
  };

  const runUnsuspendActivity = ({
    activityId,
    handleSuccess,
  }: {
    activityId: string;
    handleSuccess?: () => void;
  }) => {
    unsuspendActivity
      .run({
        activityId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to reactivate activity',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Activity reactivated',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to reactivate activity',
          variant: 'error',
        })
      );
  };

  const runCloseActivity = ({
    activityId,
    activityClosureReasonId,
    dateEnded,
    handleSuccess
  }: {
    activityId: string;
    activityClosureReasonId: string;
    dateEnded: string;
    handleSuccess?: () => void;
  }) => {
    closeActivity
      .run({
        activityId,
        dateEnded,
        activityClosureReasonId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to close activity',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Activity closed',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to close activity',
          variant: 'error',
        })
      );
  };

  const runUncloseActivity = ({
    closedActivityId,
    handleSuccess
  }: {
    closedActivityId: string | null;
    handleSuccess?: () => void;
  }) => {
    if (!closedActivityId) return;
    uncloseActivity
      .run({
        closedActivityId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to reopen activity',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Activity reopened',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to reopen activity',
          variant: 'error',
        })
      );
  };

  return {
    runSuspendActivity,
    runUnsuspendActivity,
    runCloseActivity,
    runUncloseActivity
  };
}
