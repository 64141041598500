import { Box } from '@material-ui/core';
import React from 'react';
import { ReportManagementDesktopTabs } from './ReportManagementDesktopTabs';
import { ReportManagementToolbar } from './ReportManagementToolbar';

interface ReportManagementDesktopScreenHeaderProps {
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  setSearchInput: (value: string) => void;
}

export const ReportManagementDesktopScreenHeader = (props: ReportManagementDesktopScreenHeaderProps) => {
  return (
    <Box margin='12px 60px 0px 60px'>
      <ReportManagementDesktopTabs>
        <ReportManagementToolbar {...props} />
      </ReportManagementDesktopTabs>
    </Box>
  );
};
