import React, { useContext, useState, ReactNode } from 'react';

export type PageHelpOptions = { label?: string; onClick: () => void };

type PageHelpContextInterface = {
  pageHelp?: PageHelpOptions;
  setPageHelp: (value: PageHelpOptions) => void;
  clearPageHelp: () => void;
};

const PageHelpContext = React.createContext<PageHelpContextInterface>({
  pageHelp: undefined,
  setPageHelp: () => {},
  clearPageHelp: () => {},
});

const PageHelpProvider = ({ children }: { children: ReactNode }) => {
  const [pageHelp, setPageHelp] = useState<PageHelpOptions | undefined>(undefined);

  const clearPageHelp = () => {
    setPageHelp(undefined);
  };

  return (
    <PageHelpContext.Provider value={{ pageHelp, setPageHelp, clearPageHelp }}>{children}</PageHelpContext.Provider>
  );
};

const usePageHelp = () => {
  const pageHelp = useContext(PageHelpContext);
  if (!pageHelp) throw new Error('usePageHelp must be used within a PageHelpProvider');
  return pageHelp;
};

export { PageHelpContext, PageHelpProvider, usePageHelp };
