import { gql } from '@apollo/client';

export const IMPORT_VOLUNTEERS_GET_PROGRAMS = gql`
  query ImportVolunteerGetPrograms {
    vm {
      programs {
        programId
        name
      }
    }
  }
`;
