import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { get, intersection } from 'lodash';
import { SelectChartType } from '../selects/SelectChartType';
import {
  CreatWidgetFormValues,
  IWidget,
  StepsEnum,
  WidgetTypes,
  chartWidgetsMap,
  supportingWidgetTypes,
} from '../dashboard-widget-model';
import { containerStyle } from '../styles';

interface Props {
  onClose: () => void;
}

export function SelectChartTypeDialog({ onClose }: Props) {
  const { values, setFieldValue } = useFormikContext<CreatWidgetFormValues>();

  const onNext = () => {
    setFieldValue('selectStep', StepsEnum.SETTINGS);
  };

  const onBack = () => {
    if (values.byType === WidgetTypes.NUMBER_CHART) {
      setFieldValue('selectStep', StepsEnum.SELECT_NUMBER_TYPE);
    } else {
      setFieldValue('selectStep', StepsEnum.SELECT_WIDGET);
    }
  };

  const classes = containerStyle();

  const { byValues } = values;

  const supportingChartTypes = intersection(
    ...byValues.map(({ name, tag }) => {
      return get(supportingWidgetTypes, [tag.toLowerCase(), name.toLowerCase(), 'chartWidgetTypes'], []) as string[];
    })
  ).map((widgetType) => chartWidgetsMap[widgetType] as IWidget);

  return (
    <>
      <DialogTitle>
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs style={{ paddingTop: '10px' }}>
            <Typography variant='h6' style={{ fontWeight: 700, fontSize: '24px' }}>
              {values.isNew ? 'Add' : 'Edit'} Widget
            </Typography>
            <Typography variant='subtitle1' color='textSecondary'>
              Select type of chart
            </Typography>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
                <Close color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent id='dialog-content'>
        <Box className={classes.root}>
          <SelectChartType
            value={values.chartWidgetType ?? undefined}
            onSelect={(chartType) => setFieldValue('chartWidgetType', chartType)}
            supportingChartTypes={supportingChartTypes}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ marginBottom: '2rem', marginRight: '10px' }}>
        <Button
          onClick={onBack}
          style={{
            textTransform: 'none',
          }}
        >
          Back
        </Button>
        <Button
          onClick={onNext}
          color='primary'
          style={{
            textTransform: 'none',
          }}
          variant='contained'
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}
