import { RecurrenceEnum } from '../../../__generated__/globalTypes';

export enum BeginOptionsEnum {
  NA = 'NA',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  THIS_WEEK = 'THIS_WEEK',
  NEXT_WEEK = 'NEXT_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  NEXT_MONTH = 'NEXT_MONTH'
};

export enum LengthOptionEnum {
  NA = 'n/a',
  THIS_WEEK = 'this_week',
  ONE_WEEK = 'one_week',
  ONE_MONTH = 'one_month',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
  TWELVE_MONTHS = 'twelve_months'
}


export const LENGTH_OPTIONS = [
  {
    label: 'n/a',
    value: LengthOptionEnum.NA
  },
  {
    label: 'This week',
    value: LengthOptionEnum.THIS_WEEK
  },
  {
    label: '1 week',
    value: LengthOptionEnum.ONE_WEEK
  },
  {
    label: '1 month',
    value: LengthOptionEnum.ONE_MONTH
  },
  {
    label: '3 months',
    value: LengthOptionEnum.THREE_MONTHS
  },
  {
    label: '6 months',
    value: LengthOptionEnum.SIX_MONTHS
  },
  {
    label: '12 months',
    value: LengthOptionEnum.TWELVE_MONTHS
  }
];

export const YEARLY_LENGTH_OPTIONS = [
  {
    label: 'n/a',
    value: LengthOptionEnum.NA
  }
]

export const LENGTH_VALUES = {
  [LengthOptionEnum.NA]: { days: 0 },
  [LengthOptionEnum.THIS_WEEK]: { weeks: 0 },
  [LengthOptionEnum.ONE_WEEK]  : { weeks: 1 },
  [LengthOptionEnum.ONE_MONTH] : { months: 1 },
  [LengthOptionEnum.THREE_MONTHS] : { months: 3 },
  [LengthOptionEnum.SIX_MONTHS] : { months: 6 },
  [LengthOptionEnum.TWELVE_MONTHS]: { months: 12 }
};

export enum FREQUENCY_ENUM {
  JUST_THIS_ONE = 'just_this_one',
  EVERYDAY = 'everyday',
  EVERY_SECOND_DAY = 'every_second_day',
  EVERY_WEEK = 'every_week',
  EVERY_FORTNIGHT = 'every_fortnight',
  EVERY_MONTH = 'every_month',
  EVERY_SECOND_MONTH = 'every_second_month',
  EVERY_SATURDAY = 'every_saturday',
  EVERY_SUNDAY = 'every_sunday',
  CUSTOM = 'custom'
}

export const DAILY_OPTIONS = [
  {
    label: 'Just this once',
    value: FREQUENCY_ENUM.JUST_THIS_ONE
  },
  {
    label: 'Everyday',
    value: FREQUENCY_ENUM.EVERYDAY
  },
  {
    label: 'Every second day',
    value: FREQUENCY_ENUM.EVERY_SECOND_DAY
  },
  {
    label: 'Certain days',
    value: FREQUENCY_ENUM.CUSTOM
  }
];

export const WEEKLY_OPTIONS = [
  {
    label: 'Just this once',
    value: FREQUENCY_ENUM.JUST_THIS_ONE
  },
  {
    label: 'Every week (select everyday)',
    value: FREQUENCY_ENUM.EVERY_WEEK
  },
  {
    label: 'Every fortnight',
    value: FREQUENCY_ENUM.EVERY_FORTNIGHT
  },
  {
    label: 'Certain days',
    value: FREQUENCY_ENUM.CUSTOM
  }
];


export const EVERY_WEEKEND_OPTIONS = [
  {
    label: 'Just this once',
    value: FREQUENCY_ENUM.JUST_THIS_ONE
  },
  {
    label: 'Every week (select everyday)',
    value: FREQUENCY_ENUM.EVERY_WEEK
  },
  {
    label: 'Every fortnight',
    value: FREQUENCY_ENUM.EVERY_FORTNIGHT
  },
  {
    label: 'Every Saturday',
    value: FREQUENCY_ENUM.EVERY_SATURDAY
  },
  {
    label: 'Every Sunday',
    value: FREQUENCY_ENUM.EVERY_SUNDAY
  },
  {
    label: 'Certain days',
    value: FREQUENCY_ENUM.CUSTOM
  }
];

export const MONTHLY_OPTIONS = [
  {
    label: 'Just this once',
    value: FREQUENCY_ENUM.JUST_THIS_ONE
  },
  {
    label: 'Every month',
    value: FREQUENCY_ENUM.EVERY_MONTH
  },
  {
    label: 'Every second month',
    value: FREQUENCY_ENUM.EVERY_SECOND_MONTH
  },
  {
    label: 'Certain days',
    value: FREQUENCY_ENUM.CUSTOM
  }
];

export const YEARLY_OPTIONS = [
  {
    label: 'Just this once',
    value: FREQUENCY_ENUM.JUST_THIS_ONE
  }
];

export const DAILY_BEGIN_OPTIONS = [
  {
    label: 'Today',
    value: BeginOptionsEnum.TODAY
  },
  {
    label: 'Tomorrow',
    value: BeginOptionsEnum.TOMORROW
  },
  {
    label: 'This week',
    value: BeginOptionsEnum.THIS_WEEK
  },
  {
    label: 'Next week',
    value: BeginOptionsEnum.NEXT_WEEK
  }
];

export const WEEKLY_BEGIN_OPTIONS = [
  {
    label: 'This week',
    value: BeginOptionsEnum.THIS_WEEK
  },
  {
    label: 'Next week',
    value: BeginOptionsEnum.NEXT_WEEK
  }
];

export const MONTHLY_BEGIN_OPTIONS = [
  {
    label: 'This month',
    value: BeginOptionsEnum.THIS_MONTH
  },
  {
    label: 'Next month',
    value: BeginOptionsEnum.NEXT_MONTH
  }
];

export const YEARLY_BEGIN_OPTIONS = [
  {
    label: 'n/a',
    value: BeginOptionsEnum.NA
  }
];

export const DAY_OPTIONS = [
  {
    label: 'Monday',
    value: RecurrenceEnum.MO
  },
  {
    label: 'Tuesday',
    value: RecurrenceEnum.TU
  },
  {
    label: 'Wednesday',
    value: RecurrenceEnum.WE
  },
  {
    label: 'Thursday',
    value: RecurrenceEnum.TH
  },
  {
    label: 'Friday',
    value: RecurrenceEnum.FR
  },
  {
    label: 'Saturday',
    value: RecurrenceEnum.SA
  },
  {
    label: 'Sunday',
    value: RecurrenceEnum.SU
  }
];

export const WEEKDAYS = [RecurrenceEnum.MO, RecurrenceEnum.TU, RecurrenceEnum.WE, RecurrenceEnum.TH, RecurrenceEnum.FR];
export const WEEKENDS = [RecurrenceEnum.SA, RecurrenceEnum.SU];
