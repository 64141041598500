import { CircularProgressOverlay } from '@campfire/circular-progress-overlay/lib';
import { unpackToDate } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button/lib';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Typography,
  Box,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { FileUploadGallery, FugFile } from '@campfire/file-upload-gallery';
import { convertToRaw, EditorState } from 'draft-js';
import React, { useEffect, useMemo, useState } from 'react';
import { arrayHead } from '../../../../../common/functions/array-head';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { RosterTemplateActivityCancellationReasons } from './__generated__/RosterTemplateActivityCancellationReasons';
import { ROSTER_TEMPLATE_ACTIVITY_CANCELLATION_REASONS } from './roster-template-model.gql';
import { useRosterTemplateActions } from './roster-template-actions';
import { RosterTemplateFormValues } from './RosterTemplateForm';

export const RosterCancellationDialog = ({
  open,
  handleClose,
  activityId,
  activityDate,
}: {
  open: boolean;
  handleClose: () => void;
  activityId: string;
  activityDate: string;
}) => {
  const [selectedCancellationReason, setSelectedCancellationReason] = useState<{
    activityCancellationReasonId: string;
    label: string;
  }>();
  const [description, setDescription] = React.useState(EditorState.createEmpty());
  const [uploadedFiles, setUploadedFiles] = React.useState<FugFile[]>([]);
  const { runCancelActivity } = useRosterTemplateActions();
  const { runSaveRosterTemplate } = useRosterTemplateActions();
  const formik = useFormikContext<RosterTemplateFormValues>();
  const { data, loading } = useCampfireQuery<RosterTemplateActivityCancellationReasons, undefined>(
    ROSTER_TEMPLATE_ACTIVITY_CANCELLATION_REASONS
  );

  const cancellationReasons = useMemo(() => {
    return data?.vm.activityCancellationReasons;
  }, [data]);

  useEffect(() => {
    setSelectedCancellationReason(arrayHead(cancellationReasons ?? []));
  }, [cancellationReasons]);

  const activityDateTime = unpackToDate(activityDate);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle>{`Cancel activity for ${activityDateTime.toFormat('ccc d LLL, yyyy')}`}</DialogTitle>
      <DialogContent>
        <Typography>{`The roster for this date will be cancelled, rostered volunteers will be informed of this cancellation.`}</Typography>
        <Typography>{`Please select a cancellation reason.`}</Typography>
        <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
          <CircularProgressOverlay isLoading={loading} />
          {cancellationReasons ? (
            <FormControl variant='filled' fullWidth>
              <InputLabel htmlFor='select-cancellation-reason-dropdown'>Select Reason</InputLabel>
              <Select
                native
                fullWidth
                displayEmpty
                value={selectedCancellationReason?.activityCancellationReasonId ?? ''}
                onChange={(event: any) => {
                  const matchingReason = cancellationReasons.find(
                    (reason) => reason.activityCancellationReasonId === event.target.value
                  );
                  setSelectedCancellationReason(matchingReason);
                }}
                inputProps={{
                  name: 'select-cancellation-reason',
                  id: 'select-cancellation-reason-dropdown',
                }}
              >
                {cancellationReasons.map((reason) => (
                  <option key={reason.activityCancellationReasonId} value={reason.activityCancellationReasonId}>
                    {`${reason.label}`}
                  </option>
                ))}
              </Select>
              <Box mt='1.5rem' width='100%'>
                <CampfireRichEditor editorState={description} setEditorState={setDescription} />
              </Box>
              <Box></Box>
              <Box mt='2.5rem'>
                <Typography>Attachments</Typography>
                <FileUploadGallery
                  id={'addedAttachments'}
                  data-track='resources-create-activity-attachments-area'
                  allowMultiple
                  uploadedFiles={[]}
                  handleFilesAdded={(files: FugFile[]) => {
                    setUploadedFiles(uploadedFiles.concat(files));
                  }}
                />
              </Box>
            </FormControl>
          ) : null}
        </Grid>
        {formik.values.rosterDate && (
          <Typography>{`To have this activity run on a ${DateTime.fromJSDate(formik.values.rosterDate).toFormat(
            'ccc d LLL, yyyy'
          )}, simply make a new roster.`}</Typography>
        )}
      </DialogContent>

      <DialogActions style={{ padding: 16 }}>
        <TabletButton size='medium' variant='text' onClick={handleClose}>
          {'Go Back'}
        </TabletButton>
        <TabletButton
          color='error'
          size='medium'
          variant='text'
          onClick={() => {
            if (!selectedCancellationReason) return;
            runCancelActivity({
              activityCancellationReasonId: selectedCancellationReason.activityCancellationReasonId,
              description: JSON.stringify(convertToRaw(description.getCurrentContent())),
              addedAttachments: uploadedFiles,
              attachmentCreationTokens: uploadedFiles.map((file) => file.id),
              activityId,
              activityDate,
            });
            // So that these values are used to create a new roster template
            runSaveRosterTemplate({
              values: {
                ...formik.values,
                rosterTemplateId: undefined,
              },
            });
          }}
          disabled={!selectedCancellationReason}
        >
          {'Make new roster'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};
