import { ListItemProps } from '@material-ui/core/ListItem';
import React from 'react';
import { RosterGridDataRow } from '../roster/activity-roster-model';
import { BaseVolunteerListItem } from './BaseVolunteerListItem';
import { UnavailableVolunteerListItem } from './UnavailableVolunteerListItem';

type Props = {
  activityDate: string;
  updateRosterRow: (volunteerId: string, updatedRosterGridDataRow: RosterGridDataRow) => void;
  isNew: boolean;
  volunteerName: string;
  activityRolesText?: string;
  rosterGridDataRow: RosterGridDataRow;
  runRefetch: () => void;
  style?: React.CSSProperties;
} & ListItemProps;

const VolunteerRosterListItem = React.memo((props: Props) => {
  const {
    activityDate,
    updateRosterRow,
    isNew,
    volunteerName,
    activityRolesText,
    rosterGridDataRow,
    runRefetch,
    style,
  } = props;

  const hasRosterings = !!rosterGridDataRow.rosterSessions.find(
    (rosterSession) => rosterSession.activeStatus === 'publishedSelected'
  );
  const rosterUnavailabiliy = rosterGridDataRow.__typename === 'enrolled' && rosterGridDataRow.isUnavailable;
  const rosterAvailability =
    rosterGridDataRow.__typename === 'enrolled' && rosterGridDataRow.activityEnrolment.availability;

  const hasNoIndication = !hasRosterings && !rosterUnavailabiliy && !rosterAvailability;

  // Unavailable
  if (rosterGridDataRow.__typename === 'enrolled' && rosterGridDataRow.isUnavailable)
    return (
      <UnavailableVolunteerListItem
        activityDate={activityDate}
        activityEnrolment={rosterGridDataRow.activityEnrolment}
        activityEnrolmentId={rosterGridDataRow.activityEnrolment.activityEnrolmentId}
        activityUnavailabilityId={rosterGridDataRow.activityEnrolment.unavailability?.activityUnavailabilityId}
        runRefetch={runRefetch}
        primaryText={volunteerName}
        secondaryText={activityRolesText}
        statusText={'Unavailable'}
        style={style}
      />
    );

  // No Indication
  if (hasNoIndication) {
    return (
      <BaseVolunteerListItem
        activityDate={activityDate}
        runRefetch={runRefetch}
        rosterGridDataRow={rosterGridDataRow}
        updateRosterRow={updateRosterRow}
        primaryText={volunteerName}
        secondaryText={activityRolesText}
        statusText={'No Indication'}
        isNew={isNew}
        style={style}
      />
    );
  }

  // Rostered or Available
  return (
    <BaseVolunteerListItem
      activityDate={activityDate}
      runRefetch={runRefetch}
      rosterGridDataRow={rosterGridDataRow}
      updateRosterRow={updateRosterRow}
      primaryText={volunteerName}
      secondaryText={activityRolesText}
      statusText={hasRosterings ? 'Rostered' : 'Available'}
      isNew={isNew}
      style={style}
    />
  );
});

export { VolunteerRosterListItem };
