import { encodeDate, isDateInPast } from '@campfire/hot-date';
import { Box, IconButton, Theme } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { uniqBy } from 'lodash';
import { DateTime, Interval } from 'luxon';
import React from 'react';
import {
  getUpcomingRosterStatus,
  KeyValuedUpcomingRosters,
} from './my-elements/upcoming-rosters-card/my-elements-upcoming-rosters-model.gql';
import { MyElementsRosteringAvailabilityDot } from './my-elements/upcoming-rosters-card/MyElementsUpcomingRostersLegend';

const useWeeklyCalendarStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayWrapper: {
      position: 'relative',
    },
    day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: '0 2px',
      color: 'inherit',
    },
    customDayHighlight: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '2px',
      right: '2px',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%',
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
      color: '#676767',
    },
    shadow: {
      background: theme.palette.grey[300],
    },
    darkHighlight: {
      background: theme.palette.primary.dark,
      color: theme.palette.common.white,
      '&:hover': {
        color: '#000',
      },
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    firstHighlight: {
      extend: 'highlight',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    endHighlight: {
      extend: 'highlight',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
    dotWrapper: {
      minHeight: '9px',
    },
  })
);

type WeeklyDatePickerProps = {
  date: DateTime;
  setDate: (date: DateTime) => void;
  datesThisWeek: KeyValuedUpcomingRosters | undefined;
  setClickedDate: (value: DateTime | undefined) => void;
  setUpcomingRosterDayDialogOpen: (value: boolean) => void;
};

export const WeeklyDatePicker = (props: WeeklyDatePickerProps) => {
  const { date, setDate, datesThisWeek, setClickedDate, setUpcomingRosterDayDialogOpen } = props;
  const classes = useWeeklyCalendarStyles();

  function handleWeekChange(dateValue: MaterialUiPickersDate) {
    if (!dateValue || !DateTime.isDateTime(dateValue)) {
      return;
    }
    const startOfWeek = dateValue.startOf('week');
    if (startOfWeek.month !== dateValue.month) {
      setDate(dateValue.startOf('month'));
      return;
    }
    setDate(startOfWeek);
  }

  function handleMonthChange(dateValue: MaterialUiPickersDate) {
    if (!dateValue || !DateTime.isDateTime(dateValue)) {
      return;
    }
    if (date.month > dateValue.month) {
      setDate(dateValue.endOf('month'));
      return;
    }
    setDate(dateValue.startOf('month'));
  }

  function renderDots(dateValue: MaterialUiPickersDate) {
    if (!dateValue || !dateValue.isValid) {
      return <></>;
    }

    const dateString = encodeDate(dateValue) ?? '';
    if (dateString && datesThisWeek && datesThisWeek[dateString] && datesThisWeek[dateString].length > 0) {
      const uniqStatuses = uniqBy(datesThisWeek[dateString], 'status');
      return (
        <Box display='flex' flexDirection='row' justifyContent='center'>
          {uniqStatuses.map((upcomingRoster) => {
            const { activityDate } = upcomingRoster;
            const isPastDate =
              isDateInPast(activityDate) &&
              DateTime.local().toFormat('yyyy-MM-dd') !== activityDate.toFormat('yyyy-MM-dd');
            const upcomingRosterStatus = getUpcomingRosterStatus(upcomingRoster.__typename);
            return (
              <MyElementsRosteringAvailabilityDot
                key={upcomingRosterStatus}
                status={upcomingRosterStatus}
                isPastDate={isPastDate}
              />
            );
          })}
        </Box>
      );
    }
    return <></>;
  }

  function renderWrappedWeekDay(
    dateValue: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean
  ) {
    if (!dateValue || !dateValue.isValid || !selectedDate || !selectedDate.isValid) {
      return <></>;
    }
    const dateClone = dateValue as DateTime;
    const selectedDateClone = selectedDate as DateTime;

    // MUI Date Picker weeks start on Sunday and end on Saturday
    // Luxon dates begin on Monday and end on Sunday
    const start = selectedDateClone.startOf('week');
    const end = selectedDateClone.endOf('week');

    const dayIsBetween = Interval.fromDateTimes(start, end).contains(dateClone);
    const dayIsToday = dateClone.hasSame(DateTime.local(), 'day');

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
      [classes.darkHighlight]: dayIsBetween && dayIsToday,
      [classes.shadow]: !dayIsBetween && dayIsToday,
    });

    return (
      <div>
        <IconButton className={dayClassName} data-track='upcRos-calendar-day'>
          <Box display='flex' flexDirection='column'>
            <span>{dateClone.toLocaleString({ day: 'numeric' })}</span>
            <span className={classes.dotWrapper}>{renderDots(dateClone)}</span>
          </Box>
        </IconButton>
      </div>
    );
  }

  return (
    <DatePicker
      label='Week picker'
      value={date}
      onChange={handleWeekChange}
      onMonthChange={handleMonthChange}
      disableToolbar
      autoOk
      orientation='landscape'
      variant='static'
      renderDay={renderWrappedWeekDay}
      onAccept={(clickedDate: MaterialUiPickersDate) => {
        if (clickedDate) {
          setUpcomingRosterDayDialogOpen(true);
          setClickedDate(clickedDate);
        }
      }}
    />
  );
};
