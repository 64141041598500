import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

export const IncidentDetailSkeleton = () => {
  const { theme } = useCampfireTheme();

  return (
    <Grid>
      <Grid
        container
        item
        alignContent='center'
        xs={12}
        sm={12}
        style={{ minHeight: '72px', borderBottom: `1px solid ${theme.color.grey.border}` }}
      >
        <Grid item>
          <Skeleton variant='text' height={30} width={200} />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={10} style={{ marginTop: '16px' }}>
        <Skeleton variant='text' height={20} width={300} />
      </Grid>

      <Grid item xs={12} sm={10}>
        <Skeleton variant='text' height={20} width={300} />
      </Grid>

      <Grid item xs={12} sm={10}>
        <Skeleton variant='text' height={15} width={170} />
        <Skeleton variant='text' height={15} width={130} />
      </Grid>
    </Grid>
  );
};
