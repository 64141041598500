import React from 'react';
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Theme,
  Typography,
} from '@material-ui/core';
import { Cancel, CheckCircle, Close, Help, KeyboardArrowDown, PeopleOutline, CheckRounded } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import pluralize from 'pluralize';
import { isEqual } from 'lodash';

import { ParsedRoster } from '../helpers';
import { GetActivityInfo_vm_activity_sessions as SessionType } from '../__generated__/GetActivityInfo';
import { TooltipMenu } from '../../../../../common/tooltip-menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipText: {
      color: theme.color.tooltip['Copy-2'],
    },
    secondaryText: {
      fontSize: '11px',
    },
    unavailableIcon: {
      minWidth: 'unset',
      marginRight: '9px',
    },
    unavailableText: {
      color: theme.color.card.cancelled,
    },
    availableText: {
      color: theme.color.card.available,
    },
    unavailable: {
      cursor: 'pointer',
    },
    button: {
      border: '1px solid',
      borderColor: theme.color.grey.neutral200,
      borderRadius: '4px',
      color: theme.color.grey.neutral200,
      textTransform: 'unset',
      fontWeight: 400,
      fontSize: '0.75rem',
      padding: '9px 12px',
      width: '100%',
      maxWidth: '160px',
    },
    rostered: {
      color: 'white',
      backgroundColor: theme.color.rosters.rostered,
      '&:hover': {
        backgroundColor: theme.color.rosters.status.waitlisted.primary,
      },
    },
    available: {
      color: 'white',
      backgroundColor: theme.color.rosters.available,
      '&:hover': {
        backgroundColor: '#63a8df',
      },
    },
    updating: {
      backgroundColor: theme.color.rosters.selected,
      '&:hover': {
        backgroundColor: theme.color.rosters.selected,
      },
      color: 'white',
    },
    conflicting: {
      backgroundColor: '#FFAA63',
      '&:hover': {
        backgroundColor: '#FFAA63',
      },
      color: 'white',
    },
    buttonLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      textOverflow: 'ellipsis',
    },
    buttonText: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    radio: {
      color: theme.color.tooltip['Copy-2'],
    },
  })
);

interface RosterRowProps {
  volunteerRoster: ParsedRoster;
  sessions?: SessionType[];
  onSessionClick: (sessionId: string) => void;
  onCancelClick: () => void;
  disabled?: boolean;
}

export function RosterCell(props: RosterRowProps) {
  const { volunteerRoster, sessions = [], onSessionClick, onCancelClick, disabled } = props;
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const classes = useStyles();

  const {
    unavailable,
    availabilities = [],
    publishedRoster = [],
    draftRoster = [],
    initialDraftRoster = [],
    isConflicting,
  } = volunteerRoster;

  const unPublishingRoster = !isEqual(publishedRoster, draftRoster);

  const updatingRoster = !isEqual(initialDraftRoster, draftRoster);

  const buttonText = React.useMemo(() => {
    if (isConflicting) {
      return 'Conflict';
    }

    if (updatingRoster) {
      return 'To be updated';
    }

    if (unPublishingRoster) {
      return 'To be rostered';
    }

    if (publishedRoster.length === 1) {
      return sessions.find((session) => session.sessionId === publishedRoster[0])?.name;
    }

    if (publishedRoster.length > 1) {
      return pluralize('session', publishedRoster.length, true);
    }

    if (unavailable) {
      return 'Unavailable';
    }

    if (availabilities.length) {
      return 'Available';
    }
    return 'No indication';
  }, [availabilities, publishedRoster, updatingRoster, unavailable, isConflicting]);

  const getSecondaryText = (sessionId: string) => {
    if (!initialDraftRoster.includes(sessionId) && draftRoster.includes(sessionId)) {
      return 'To be updated';
    }

    if (!publishedRoster.includes(sessionId) && draftRoster.includes(sessionId)) {
      return 'To be rostered';
    }
    if (publishedRoster.includes(sessionId) && draftRoster.includes(sessionId)) {
      return 'Rostered';
    }
    if (publishedRoster.includes(sessionId) && !draftRoster.includes(sessionId)) {
      return 'To be removed';
    }
    if (unavailable) {
      return 'Unavailable';
    }
    if (availabilities.includes(sessionId)) {
      return 'Available';
    }
    return 'No indication';
  };

  const buttonClassName = React.useMemo(() => {
    if (isConflicting) {
      return `${classes.button} ${classes.conflicting}`;
    }

    if (updatingRoster) {
      return `${classes.button} ${classes.updating}`;
    }

    if (unPublishingRoster) {
      return `${classes.button} ${classes.updating}`;
    }

    if (publishedRoster.length > 0) {
      return `${classes.button} ${classes.rostered}`;
    }

    if (availabilities.length) {
      return `${classes.button} ${classes.available}`;
    }

    if (unavailable) {
      return classes.button;
    }

    return classes.button;
  }, [publishedRoster, updatingRoster, unavailable, isConflicting]);

  const isReallyUnavailable = unavailable && !updatingRoster && !unPublishingRoster && publishedRoster.length === 0;

  return (
    <TooltipMenu
      open={tooltipOpen}
      onClose={() => {
        setTooltipOpen(false);
      }}
      title={
        <List dense>
          {sessions?.map((session) => (
            <ListItem key={session.sessionId}>
              <FormControlLabel
                value={session.sessionId}
                control={
                  <Radio
                    color='primary'
                    className={classes.radio}
                    checked={draftRoster.includes(session.sessionId)}
                    onClick={() => {
                      onSessionClick(session.sessionId);
                    }}
                  />
                }
                label={
                  <ListItemText
                    classes={{
                      secondary: `${classes.tooltipText} ${classes.secondaryText}`,
                      primary: classes.tooltipText,
                    }}
                    primary={session.name}
                    secondary={getSecondaryText(session.sessionId)}
                  />
                }
              />
            </ListItem>
          ))}
          {volunteerRoster.enrolmentId &&
            (unavailable ? (
              <ListItem className={classes.unavailable} onClick={onCancelClick}>
                <ListItemIcon className={classes.unavailableIcon}>
                  <CheckRounded className={classes.unavailableText} />
                </ListItemIcon>
                <ListItemText className={classes.unavailableText}>Remove unavailability</ListItemText>
              </ListItem>
            ) : (
              <ListItem className={classes.unavailable} onClick={onCancelClick}>
                <ListItemIcon className={classes.unavailableIcon}>
                  <Close className={classes.unavailableText} />
                </ListItemIcon>
                <ListItemText className={classes.unavailableText}>Mark as unavailable</ListItemText>
              </ListItem>
            ))}
        </List>
      }
    >
      <Button
        startIcon={<PeopleOutline />}
        variant='outlined'
        endIcon={<KeyboardArrowDown />}
        disabled={disabled}
        onClick={() => setTooltipOpen(true)}
        className={buttonClassName}
        color={updatingRoster ? 'secondary' : 'default'}
        style={{ backgroundColor: isReallyUnavailable ? '#0000000d' : '', width: 160 }}
        classes={{ label: classes.buttonLabel }}
      >
        <Typography noWrap className={classes.buttonText}>
          {buttonText}
        </Typography>
      </Button>
    </TooltipMenu>
  );
}

const useEndedStyles = makeStyles((theme: Theme) =>
  createStyles({
    unrostered: {
      width: 24,
      height: 24,
      backgroundColor: theme.rosters.status.unavailable.primary,
      borderRadius: '50%',
      fontSize: '12px',
      lineHeight: '12px',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600,
    },
    unavailable: {
      width: 24,
      height: 24,
      backgroundColor: theme.rosters.status.unavailable.primary,
      borderRadius: '50%',
      lineHeight: '12px',
      fontSize: '12px',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600,
    },
    rostered: {
      width: 24,
      height: 24,
      backgroundColor: '#82BE01',
      borderRadius: '50%',
      fontSize: '12px',
      lineHeight: '12px',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600,
    },
  })
);

interface RosterCellFinishedProps {
  volunteerRoster: ParsedRoster;
  sessions?: SessionType[];
}

export function RosterCellFinished(props: RosterCellFinishedProps) {
  const { volunteerRoster, sessions = [] } = props;
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const classes = useEndedStyles();
  const commonClasses = useStyles();
  const { draftRoster = [], publishedRoster = [], availabilities = [] } = volunteerRoster;

  const getSecondaryText = (sessionId: string) => {
    if (!publishedRoster.includes(sessionId) && draftRoster.includes(sessionId)) {
      return 'To be rostered';
    }
    if (publishedRoster.includes(sessionId) && draftRoster.includes(sessionId)) {
      return 'Rostered';
    }
    if (publishedRoster.includes(sessionId) && !draftRoster.includes(sessionId)) {
      return 'To be removed';
    }
    if (availabilities.includes(sessionId)) {
      return 'Available';
    }
    return 'No indication';
  };

  if (volunteerRoster.unavailable) {
    return <Box className={classes.unavailable} />;
  }

  const isPublished = Boolean(volunteerRoster.publishedRoster?.length);

  return (
    <Box display='flex' justifyContent='center'>
      <Box>
        <Badge
          style={{ zIndex: 'unset' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={(() => {
            if (volunteerRoster.attending === true) {
              return (
                <CheckCircle
                  fontSize='small'
                  htmlColor='#7DD268'
                  style={{ fontSize: 15, background: 'white', borderRadius: '50%', right: '7%', bottom: '7%' }}
                />
              );
            }
            if (volunteerRoster.attending === false) {
              return (
                <Cancel
                  fontSize='small'
                  color='error'
                  style={{ fontSize: 15, background: 'white', borderRadius: '50%', right: '7%', bottom: '7%' }}
                />
              );
            }
            return (
              <Help
                fontSize='small'
                htmlColor='#00000024'
                style={{ fontSize: 15, background: 'white', borderRadius: '50%', right: '7%', bottom: '7%' }}
              />
            );
          })()}
          overlap='circle'
        >
          <Box className={isPublished ? classes.rostered : classes.unrostered}>{publishedRoster.length}</Box>
        </Badge>
      </Box>
      <TooltipMenu
        open={tooltipOpen}
        onClose={() => {
          setTooltipOpen(false);
        }}
        title={
          <List dense>
            {sessions?.map((session) => (
              <ListItem key={session.sessionId}>
                <FormControlLabel
                  value={session.sessionId}
                  control={
                    <Radio
                      color='primary'
                      checked={publishedRoster.includes(session.sessionId)}
                      className={commonClasses.radio}
                    />
                  }
                  label={
                    <ListItemText
                      classes={{
                        secondary: `${commonClasses.tooltipText} ${commonClasses.secondaryText}`,
                        primary: commonClasses.tooltipText,
                      }}
                      primary={session.name}
                      secondary={getSecondaryText(session.sessionId)}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
        }
      >
        <IconButton onClick={() => setTooltipOpen(true)} size='small' style={{ paddingLeft: '7px' }}>
          <KeyboardArrowDown />
        </IconButton>
      </TooltipMenu>
    </Box>
  );
}
