import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Email } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import { useCampfireLazyQuery } from '../../../../../../global/network/useCampfireLazyQuery';
import { GET_COMMUNICATION_TEMPLATE_DETAILS } from './communication-template-details-model.gql';
import { TemplateItemBody } from './TemplateItemBody';
import { TemplateItemDescription } from './TemplateItemDescription';
import { TemplateItemHeader } from './TemplateItemHeader';
import {
  GetCommunicationTemplateDetails,
  GetCommunicationTemplateDetailsVariables,
  GetCommunicationTemplateDetails_vm_communicationTemplate as CommunicationTemplateType,
} from './__generated__/GetCommunicationTemplateDetails';
import { AlertCard } from '../../../../../../common/cards/alert-card/AlertCard';
import { useEndpointFetch } from '../../../../../../global/network/useEndpointFetch';
import { useUser } from '../../../../../../global/auth/useUser';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      textTransform: 'none',
    },
  })
);

interface TenantItemContentProps {
  communicationTypeId?: string;
  greeting?: string;
  signoff?: string;
  tenantName?: string;
  isLoading: boolean;
  logo?: string | null;
}

export function TemplateItemContent({
  communicationTypeId,
  greeting = 'Hi',
  signoff = 'Thanks,',
  tenantName,
  isLoading,
  logo,
}: TenantItemContentProps) {
  const [communicationType, setCommunicationType] = React.useState<CommunicationTemplateType | null>();
  const [sendTestOpen, setSendTestOpen] = React.useState(false);
  const sendButtonRef = React.useRef<any>();
  const { user } = useUser();

  const [queryCommunicationType, { loading: templateDetailsLoading }] = useCampfireLazyQuery<
    GetCommunicationTemplateDetails,
    GetCommunicationTemplateDetailsVariables
  >(GET_COMMUNICATION_TEMPLATE_DETAILS);

  React.useEffect(() => {
    if (communicationTypeId) {
      queryCommunicationType({
        variables: {
          communicationTypeId,
        },
      }).then((data) => setCommunicationType(data.data?.vm.communicationTemplate));
    }
  }, [communicationTypeId]);
  const [email, setEmail] = React.useState('');
  const updateCommunicationTypeStatus = useEndpointFetch<{ communicationTypeId: string; enabled: boolean }>(
    '/vm/comms/communication-type/save'
  );

  const saveCommunicationTypeContentBlock = useEndpointFetch<{
    communicationTypeId: string;
    communicationTypeCustomContentBlockId?: string;
    block: string;
    content: string;
  }>('/vm/comms/communication-content/save');

  const sendSampleEmail = useEndpointFetch<{ email: string; name: string; content: string }>('/vm/comms/send-test');

  const onSaveContentBlock = (block: string, content: string, communicationTypeCustomContentBlockId?: string) => {
    if (!communicationType) {
      return;
    }
    saveCommunicationTypeContentBlock
      .run({
        communicationTypeId: communicationType?.communicationTypeId,
        communicationTypeCustomContentBlockId,
        block,
        content,
      })
      .then(() => {
        return queryCommunicationType({
          variables: {
            communicationTypeId,
          },
        });
      })
      .then((data) => setCommunicationType(data.data?.vm.communicationTemplate));
  };

  const onClose = () => setSendTestOpen(false);
  const classes = useStyles();
  const onUpdateStatus = () => {
    if (!communicationType || !communicationType.isOptional) {
      return;
    }

    const enabled = !communicationType?.status;

    updateCommunicationTypeStatus
      .run({
        communicationTypeId: communicationType?.communicationTypeId,
        enabled,
      })
      .then((res) => {
        if (res.ok) {
          setCommunicationType({
            ...communicationType,
            status: enabled,
          });
        }
      })
      .then(() => {
        return queryCommunicationType({
          variables: {
            communicationTypeId,
          },
        });
      })
      .then((data) => setCommunicationType(data.data?.vm.communicationTemplate));
  };

  const content = communicationType?.content?.content || '';
  const parsedContent = (parsingContent: string) =>
    parsingContent
      .replaceAll('{{FNAME}}', `<strong>${user?.preferredName || 'Volaby User'}</strong>`)
      .replaceAll('{{ORG_NAME}}', `<strong>${tenantName}</strong>`)
      .replaceAll('{tenantName}', `<strong>${tenantName}</strong>`)
      .replaceAll('{{ACTIVITY_NAME}}', '<strong>Activity Name</strong>')
      .replaceAll('{{PROGRAM_NAME}}', '<strong>Program Name</strong>')
      .replaceAll('{{END_DATE}}', '<strong>3rd January, 2023</strong>')
      .replaceAll(
        '{{ROSTER_DATES}}',
        '<strong><li>3rd January, 2023</li><li>5th January, 2023</li><li>7th January, 2023</li><li>8th January, 2023</li><li>10th January, 2023</li></strong>'
      )
      .replaceAll('{{DYNAMIC_CONTENT}}', '<strong>Some more details</strong>')
      .replaceAll('{{ROSTER_DATE}}', '<strong>Roster Date</strong>')
      .replaceAll('{activityDate}', '<strong>Roster Date</strong>')
      .replaceAll('{{ACTIVITY_LOCATION_ADDRESS}}', '<strong>Activity Location Address</strong>')
      .replaceAll('{{ACTIVITY_REMOTE_LOCATION}}', '<strong>Activity Remote Location</strong>')
      .replaceAll('{{SESSION_DETAILS}}', '<strong>Session details</strong>')
      .replaceAll('{{CONTENT}}', '<strong>Additional information</strong>')
      .replaceAll('{{REASON}}', '<strong>Reason information/strong>')
      .replaceAll('{{URL}}', '')
      .replaceAll('{url}', '')
      .replaceAll('{{TASK_NAME}}', '<strong>Task Name</strong>')
      .replaceAll('{{CANCELLATION_REASON}}', '<strong>Cancellation Reason</strong>')
      .replaceAll('{{MESSAGE}}', '<strong>Message from admin/manager here</strong>')
      .replaceAll('{{TASKS}}', '<strong>Incomplete tasks window</strong>')
      .replaceAll('{{ROSTER_ORDINAL_DATE}}', '<strong>Roster Date</strong>')
      .replaceAll('{{INCIDENT_INFO_TEXT}}', '<strong>Incident Information</strong>')
      .replaceAll('{{PROGRAM}}', '<strong>Program</strong>')
      .replaceAll('{{APPLICATION_URL}}', '<strong>Application URL</strong>')
      .replaceAll('{{MANAGER_NAME}}', '<strong>Manager Name</strong>')
      .replaceAll('{{ACTIVITY_RRULE_READABLE}}', '<strong>Schedule</strong>');

  const onSend = () => {
    sendSampleEmail
      .run({
        email: email,
        name: user?.preferredName || 'Volaby User',
        content: parsedContent(communicationType?.stringContentParsed || '')
          .replaceAll('{{INSTRUCTION_BLOCK_HEADER}}', '<strong>Custom type block header</strong>')
          .replaceAll('{{CUSTOM_CONTENT}}', '<strong>Custom type block content</strong>'),
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <Paper
      style={{
        padding: '30px 35px',
        height: '100%',
        margin: '16px',
        flex: '1 1 0',
        minHeight: 0,
        overflowY: 'scroll',
      }}
      elevation={0}
    >
      {isLoading || templateDetailsLoading ? (
        <Skeleton variant='rect' width='100%' height='100%' />
      ) : (
        <>
          <TemplateItemHeader
            title={communicationType?.name || ''}
            enabled={communicationType?.status || false}
            onChange={onUpdateStatus}
            onSendTest={() => setSendTestOpen(true)}
            isOptional={communicationType?.isOptional ?? true}
          />
          <Box marginTop='15px'>
            <TemplateItemDescription description={communicationType?.description || ''} />
          </Box>
          <Paper
            style={{
              marginTop: '15px',
              padding: communicationType?.type === 'sms' ? '0px' : '16px 40px',
            }}
            elevation={3}
          >
            <TemplateItemBody
              key={communicationTypeId}
              greeting={greeting}
              signoff={signoff}
              logo={logo}
              content={parsedContent(content)}
              preferredName={user?.preferredName || 'Volaby User'}
              title={communicationType?.name || ''}
              contentBlocks={communicationType?.contentBlocks || []}
              onSaveContentBlock={onSaveContentBlock}
              saveContentLoading={saveCommunicationTypeContentBlock.isLoading}
              type={communicationType?.type ?? 'email'}
            />
          </Paper>
          <Dialog open={sendTestOpen} onClose={onClose}>
            <DialogTitle>{`Send Test Email: ${communicationType?.name}`}</DialogTitle>
            <DialogContent>
              <AlertCard variant='info' title={communicationType?.description} />
              <Typography style={{ marginTop: '12px' }}>Email address:</Typography>
              <TextField
                style={{ marginTop: '8px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Email />
                    </InputAdornment>
                  ),
                  onKeyPress: (event) => {
                    const { key } = event;
                    if (key === 'Enter') {
                      sendButtonRef.current.click();
                    }
                  },
                }}
                fullWidth
                variant='outlined'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </DialogContent>
            <DialogActions style={{ padding: '20px 24px' }}>
              <Button variant='outlined' disableElevation onClick={onClose} className={classes.button}>
                Cancel
              </Button>
              <Button
                variant='contained'
                disableElevation
                color='primary'
                ref={sendButtonRef}
                className={classes.button}
                onClick={onSend}
                disabled={email.length === 0}
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Paper>
  );
}
