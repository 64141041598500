import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { getDurationHoursMin, unpackToTime } from '@campfire/hot-date/lib';
import { HoverText } from '@campfire/hover-link';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, Button, Divider, Grid, List, ListItem, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState, createContext, forwardRef } from 'react';
import { useHistory } from 'react-router';
import { FixedSizeList, FixedSizeListProps } from 'react-window';
import { SearchField } from '../../../../../../common/inputs/SearchField';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useActivityTimelineContext } from '../../ActivityTimelineContext';
import { SidebarItem } from '../../sidebar/ActivityTimelineSidebar';
import { ActivityTimelineFutureDraftRosterActivitySession } from '../../__generated__/ActivityTimelineFutureDraftRosterActivitySession';
import { ActivityApplicantRosterListItem } from '../roster-mobile/ActivityApplicantRosterListItem';
import { VolunteerRosterListItem } from '../roster-mobile/VolunteerRosterListItem';
import { ActivityRole, getActivityRolesText } from './activity-roster-helpers';
import { RosterGridData, RosterGridDataRow } from './activity-roster-model';
import { ActivityApplicantRosterRow } from './ActivityApplicantRosterRow';
import { ActivityRosterHeaderBox } from './ActivityRosterHeaderBox';
import { ActivityRosterSubheaderBox } from './ActivityRosterSubheaderBox';
import { RosterActionsFooter } from './RosterActionsFooter';
import { RosterActionsMobileBox } from './RosterActionsMobileBox';
import { RosterTableTitleBlock } from './RosterTableTitleBlock';
import { rosterLoveCount, useActivityRoster } from './use-activity-roster';
import { ROSTER_SCROLL_DIMENSION, useRosterScrolling } from './use-roster-scrolling';
import { VolunteerRosterRow } from './VolunteerRosterRow';
import { ActivityTimelineFutureDraftRoster_publishedRoster as ActivityTimelineFutureDraftRosterPublishedRoster } from '../../__generated__/ActivityTimelineFutureDraftRoster';
import { CancelActivityParams } from '../../activity-timeline-actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRow: {
      backgroundColor: 'lightpink',
      alignContent: 'flex-end',
      alignItems: 'flex-end',
    },
    headerRowCell: {
      textAlign: 'center',
      backgroundColor: 'lightgoldenrodyellow',
    },
    borderBottom: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    borderRight: {
      borderRight: `1px solid ${theme.color.grey.border}`,
    },
    borderTop: {
      borderTop: `1px solid ${theme.color.grey.border}`,
    },
  })
);

interface ActivityTimelineFutureRosterProps {
  activityId: string;
  activityDate: string;
  patchSelectedSidebarItem: (partialSidebarItem: Partial<SidebarItem>) => void;
  rosterTemplateId?: string;
}

export const timeDisplayText = (start: any, end: any) => {
  const startInvalid = !!start.invalid;
  const endInvalid = !!end.invalid;
  const bothInvalid = startInvalid && endInvalid;

  if (bothInvalid) {
    return 'No Time Specified';
  }
  if (endInvalid) {
    return `Starts at ${start.toFormat('h:mm a')}`;
  }
  if (startInvalid) {
    return `Ends at ${end.toFormat('h:mm a')}`;
  }
  return `${start.toFormat('h:mm a')} - ${end.toFormat('h:mm a')}`;
};

export const ActivityTimelineFutureRoster = memo((props: ActivityTimelineFutureRosterProps) => {
  const { activityId, activityDate, patchSelectedSidebarItem, rosterTemplateId } = props;
  const {
    activity,
    activityApplicants,
    activityLoading,
    activityRoles,
    addFullRostering,
    changedVolunteers,
    handleRosterGridSessionClick,
    hasActiveCellChanges,
    hasUnpublishedChanges,
    hasPublishedRoster,
    rosterGridData,
    runCancelActivity,
    runRefetch,
    runSaveDraftRoster,
    updateRosterRow,
    publishedRoster,
  } = useActivityRoster({
    activityId,
    activityDate,
    patchSelectedSidebarItem,
    UnableToReloadAction: (
      <Button color='secondary' variant='contained' size='medium' onClick={() => window.location.reload()}>
        {'Refresh Now'}
      </Button>
    ),
    rosterTemplateId,
  });
  const { setSelectedDate } = useActivityTimelineContext();
  const { isMobile } = useCampfireTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const activityEnrolmentsRosterGridData = useMemo(() => rosterGridData?.filter((x) => x.__typename === 'enrolled'), [
    rosterGridData,
  ]);
  const otherVolunteersRosterGridData = useMemo(() => rosterGridData?.filter((x) => x.__typename === 'volunteer'), [
    rosterGridData,
  ]);

  const hasGeneralVolunteerOnRoster = useMemo(
    () =>
      !!otherVolunteersRosterGridData?.find((x) =>
        x.rosterSessions.find((rosterSession) => rosterSession.activeStatus !== undefined)
      ),

    [otherVolunteersRosterGridData, activity]
  );

  const onPublishRosterClick = useCallback(() => {
    setIsSubmitting(true);
    return runSaveDraftRoster(true).finally(() => {
      setIsSubmitting(false);
    });
  }, [runSaveDraftRoster]);

  const onSaveDraftClick = useCallback(() => {
    setIsSubmitting(true);
    return runSaveDraftRoster().finally(() => {
      setIsSubmitting(false);
    });
  }, [runSaveDraftRoster]);

  const onDiscardChangesClick = useCallback(() => runRefetch(), [runRefetch]);
  const onActivityTitleClick = useCallback(() => setSelectedDate(undefined), [setSelectedDate]);

  const [rosterHeaderHeight, setRosterHeaderHeight] = useState(0);
  const { ref, scrollLeft, isHorizontalScroll, handleScroll } = useRosterScrolling(rosterHeaderHeight);

  if (activityLoading) {
    return <LoadingSkeleton />;
  }

  if (!activity) {
    return null;
  }

  if (isMobile) {
    return (
      <Box display='flex' flex='1 0 auto' position='relative' flexDirection='column' paddingBottom={8}>
        <CircularProgressOverlay isLoading={isSubmitting} />

        <PageHeader
          activityDate={activityDate}
          activityName={activity.name}
          hasOpenRoster={activity.hasOpenRoster}
          isMobile
          onActivityTitleClick={onActivityTitleClick}
          rosterGridData={rosterGridData}
          rosterTemplateId={rosterTemplateId}
          runCancelActivity={runCancelActivity}
          publishedRoster={publishedRoster}
          setRosterHeaderHeight={setRosterHeaderHeight}
        />

        <RosterActionsMobileBox
          changedVolunteers={changedVolunteers}
          hasActiveCellChanges={hasActiveCellChanges}
          hasPublishedRoster={hasPublishedRoster}
          isSubmitting={isSubmitting}
          onDiscardChangesClick={onDiscardChangesClick}
          onPublishRosterClick={onPublishRosterClick}
          onSaveDraftClick={onSaveDraftClick}
          show={hasActiveCellChanges || hasUnpublishedChanges}
          suppressDrafts={activity.hasOpenRoster}
        />

        <RosterTableTitleBlock hideSubtitle subtitle={'Volunteers enrolled in this activity'} title={'Activity Team'} />

        <ActivityApplicants
          activityApplicants={activityApplicants}
          activityDate={activityDate}
          activityRoles={activityRoles}
          addFullRostering={addFullRostering}
          isMobile
          runSaveDraftRoster={runSaveDraftRoster}
          runRefetch={runRefetch}
        />

        <TableBody
          activityDate={activityDate}
          activityEnrolmentsRosterGridData={activityEnrolmentsRosterGridData}
          activityRoles={activityRoles}
          handleRosterGridSessionClick={handleRosterGridSessionClick}
          hasGeneralVolunteerOnRoster={hasGeneralVolunteerOnRoster}
          isMobile
          otherVolunteersRosterGridData={otherVolunteersRosterGridData}
          runRefetch={runRefetch}
          updateRosterRow={updateRosterRow}
          sessions={activity.sessions}
        />
      </Box>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'hidden',
      }}
    >
      <CircularProgressOverlay isLoading={isSubmitting} />

      <div
        style={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}
      >
        <div
          ref={ref}
          onScroll={handleScroll}
          style={{
            overflowY: 'auto',
            overflowX: 'auto',
            position: 'relative',
            contain: 'strict',
            flex: '1 1 auto',
            display: 'block',
            minHeight: '1px',
            paddingBottom: '64px',
            marginLeft: isHorizontalScroll ? -ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_SHRINK_WIDTH : 0,
            transition: 'margin-left 0.15s linear',
          }}
        >
          <div
            style={{
              zIndex: 3,
              left: scrollLeft,
              top: 0,
              position: 'absolute',
              width: '100%',
            }}
          >
            <PageHeader
              activityDate={activityDate}
              activityName={activity.name}
              hasOpenRoster={activity.hasOpenRoster}
              isMobile={false}
              onActivityTitleClick={onActivityTitleClick}
              rosterGridData={rosterGridData}
              rosterTemplateId={rosterTemplateId}
              runCancelActivity={runCancelActivity}
              publishedRoster={publishedRoster}
              setRosterHeaderHeight={setRosterHeaderHeight}
            />
          </div>

          <div
            style={{
              height: rosterHeaderHeight + ROSTER_SCROLL_DIMENSION.PAGE_SUBHEADER_HEIGHT,
              width: 1,
            }}
          />

          <ActivityApplicants
            activityApplicants={activityApplicants}
            activityDate={activityDate}
            activityRoles={activityRoles}
            addFullRostering={addFullRostering}
            isMobile={false}
            runSaveDraftRoster={runSaveDraftRoster}
            runRefetch={runRefetch}
          />

          <Divider style={{ marginTop: activityApplicants?.length ? 0 : '16px' }} />

          <div
            style={{
              zIndex: 1,
              marginLeft: isHorizontalScroll ? ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_SHRINK_WIDTH : 0,
            }}
          >
            <TableBody
              activityDate={activityDate}
              activityEnrolmentsRosterGridData={activityEnrolmentsRosterGridData}
              activityRoles={activityRoles}
              handleRosterGridSessionClick={handleRosterGridSessionClick}
              hasGeneralVolunteerOnRoster={hasGeneralVolunteerOnRoster}
              isMobile={false}
              otherVolunteersRosterGridData={otherVolunteersRosterGridData}
              runRefetch={runRefetch}
              updateRosterRow={updateRosterRow}
              sessions={activity.sessions}
            />
          </div>
        </div>
      </div>

      <RosterActionsFooter
        changedVolunteers={changedVolunteers}
        hasActiveCellChanges={hasActiveCellChanges}
        hasPublishedRoster={hasPublishedRoster}
        isSubmitting={isSubmitting}
        onDiscardChangesClick={onDiscardChangesClick}
        onPublishRosterClick={onPublishRosterClick}
        onSaveDraftClick={onSaveDraftClick}
        open={hasActiveCellChanges || hasUnpublishedChanges}
        suppressDrafts={activity.hasOpenRoster}
      />
    </div>
  );
});

const PageHeader = React.memo(
  ({
    activityName,
    activityDate,
    onActivityTitleClick,
    rosterGridData,
    rosterTemplateId,
    hasOpenRoster,
    runCancelActivity,
    isMobile,
    publishedRoster,
    setRosterHeaderHeight,
  }: {
    activityName: string;
    activityDate: any;
    onActivityTitleClick: () => void;
    rosterGridData?: RosterGridData;
    rosterTemplateId?: string;
    hasOpenRoster: boolean;
    runCancelActivity: (params: CancelActivityParams) => void;
    isMobile: boolean;
    publishedRoster: ActivityTimelineFutureDraftRosterPublishedRoster | null;
    setRosterHeaderHeight: (height: number) => void;
  }) => {
    const history = useHistory();
    const onEditRosterClick = () => {
      if (!rosterTemplateId) return;
      history.push(`/management/rosters/edit-roster-template?rosterTemplateId=${rosterTemplateId}`);
    };

    return (
      <>
        <ActivityRosterHeaderBox
          activityDate={activityDate}
          activityName={activityName}
          onActivityTitleClick={onActivityTitleClick}
          runCancelActivity={runCancelActivity}
          onEditRosterClick={onEditRosterClick}
          rosterTemplateId={rosterTemplateId}
          setRosterHeaderHeight={setRosterHeaderHeight}
        />
        <ActivityRosterSubheaderBox
          hasOpenRoster={hasOpenRoster}
          height={isMobile ? undefined : ROSTER_SCROLL_DIMENSION.PAGE_SUBHEADER_HEIGHT}
          rosterGridData={rosterGridData}
          publishedRoster={publishedRoster}
        />
      </>
    );
  }
);

const TableHeader = React.memo(
  ({
    sessions,
    rosterGridData,
    isMobile,
    title,
    subtitle,
  }: {
    sessions: Array<ActivityTimelineFutureDraftRosterActivitySession>;
    rosterGridData?: RosterGridData;
    isMobile: boolean;
    title: string;
    subtitle: string;
  }) => {
    const classes = useStyles();
    return (
      <Box display='flex' flex='1 0 auto' height={ROSTER_SCROLL_DIMENSION.TABLE_HEADER_HEIGHT}>
        <RosterTableTitleBlock
          className={classes.borderBottom}
          hideSubtitle={isMobile}
          subtitle={subtitle}
          title={title}
        />
        {sessions.map((session) => {
          return <TableHeaderSessionCell key={session.sessionId} session={session} rosterGridData={rosterGridData} />;
        })}
      </Box>
    );
  }
);

const TableHeaderSessionCell = React.memo(
  ({
    session,
    rosterGridData,
  }: {
    session: ActivityTimelineFutureDraftRosterActivitySession;
    rosterGridData?: RosterGridData;
  }) => {
    const { startTime, endTime } = session;
    const hasTimes = startTime || endTime;
    const hasStartAndFinishTime = startTime && endTime;

    const endTimeDateTime = unpackToTime(endTime);
    const startTimeDateTime = unpackToTime(startTime);
    const durationDisplayText = getDurationHoursMin(startTimeDateTime, endTimeDateTime);

    const classes = useStyles();

    return (
      <Box
        alignItems='center'
        className={classes.borderBottom}
        display='flex'
        flex='1 0 0'
        flexDirection='column'
        justifyContent='flex-bottom'
        justifyItems='flex-bottom'
        minWidth={ROSTER_SCROLL_DIMENSION.COLUMN_WIDTH}
        textAlign='center'
      >
        <Typography variant='body2'>{session.name}</Typography>
        {hasTimes && (
          <>
            {hasStartAndFinishTime ? (
              <TitularTooltip title={durationDisplayText}>
                <Typography variant='body2' color='textSecondary'>
                  {timeDisplayText(startTimeDateTime, endTimeDateTime)}
                </Typography>
              </TitularTooltip>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                {timeDisplayText(startTimeDateTime, endTimeDateTime)}
              </Typography>
            )}
          </>
        )}
        <Typography variant='caption' color='textSecondary'>
          {rosterLoveCount(
            rosterGridData?.filter((x) =>
              x.rosterSessions.find(
                (rosterSession) =>
                  rosterSession.session.sessionId === session.sessionId &&
                  (rosterSession.activeStatus === 'selected' || rosterSession.activeStatus === 'publishedSelected')
              )
            ).length ?? 0,
            session.minVolunteers,
            session.maxVolunteers
          )}
        </Typography>
      </Box>
    );
  },
  (prevProps, nextProps) => prevProps.rosterGridData === nextProps.rosterGridData
);

const ActivityApplicants = React.memo(
  ({
    activityApplicants,
    activityDate,
    activityRoles,
    addFullRostering,
    isMobile,
    runSaveDraftRoster,
    runRefetch,
  }: {
    activityApplicants: Array<any>;
    activityDate: string;
    activityRoles: Array<{
      activityRoleId: string;
      name: string;
    }>;
    addFullRostering: (args: { volunteerId: string }) => void;
    isMobile: boolean;
    runSaveDraftRoster: () => void;
    runRefetch: () => void;
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [showActivityApplicants, setShowActivityApplicants] = useState(false);

    return (
      <div ref={ref}>
        {!!activityApplicants?.length && (
          <Box width={1} paddingY={2}>
            <Box marginLeft={2} display='flex' flex='1 0 auto'>
              <HoverText
                color='primary'
                data-track='actCnl-roster-show-new-volunteers'
                onClick={() => setShowActivityApplicants(!showActivityApplicants)}
                variant='body2'
              >
                {showActivityApplicants ? 'Hide new applicants' : 'Show new applicants'}
              </HoverText>
            </Box>

            {showActivityApplicants && (
              <Box paddingBottom={2} boxSizing='border-box'>
                <Box marginLeft={2}>
                  <Typography variant='body2' color='textSecondary'>
                    These volunteers have applied to join this activity. You can add them directly to a roster using
                    this area.
                  </Typography>
                </Box>

                {isMobile && (
                  <List dense style={{ width: '100%', paddingTop: 0 }}>
                    {activityApplicants?.map((activityApplicant) => {
                      const { volunteer } = activityApplicant;
                      return (
                        <ActivityApplicantRosterListItem
                          activityApplicationId={activityApplicant.activityApplicationId}
                          addFullRostering={addFullRostering}
                          key={activityApplicant.activityApplicationId}
                          primaryText={`${volunteer.profile.preferredName} ${volunteer.profile.lastName}`}
                          runSaveDraftRoster={runSaveDraftRoster}
                          secondaryText={getActivityRolesText({
                            activityRoles,
                            volunteerActivityRoles: volunteer.activityRoles,
                          })}
                          volunteer={volunteer}
                        />
                      );
                    })}
                  </List>
                )}
                {!isMobile && (
                  <Grid style={{ border: 'none', margin: 0 }} item xs={12} container>
                    {activityApplicants?.map((activityApplicant) => (
                      <Grid item xs={12} key={activityApplicant.activityApplicationId}>
                        <ActivityApplicantRosterRow
                          activityApplicationId={activityApplicant.activityApplicationId}
                          activityDate={activityDate}
                          activityRoles={activityRoles}
                          addFullRostering={addFullRostering}
                          runSaveDraftRoster={runSaveDraftRoster}
                          runRefetch={runRefetch}
                          volunteer={activityApplicant.volunteer}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            )}
          </Box>
        )}
      </div>
    );
  }
);

// @ts-ignore
const StickyListContext = createContext();

const ItemWrapper = ({ data, index, style }: any) => {
  const { ItemRenderer } = data;
  return <ItemRenderer index={index} style={{ ...style, top: `${parseFloat(style.top) + 64}px` }} />;
};

const innerElementType = forwardRef(({ children, ...rest }, ref) => (
  <StickyListContext.Consumer>
    {({ stickySessions }: any) => (
      // @ts-ignore
      <div ref={ref} {...rest}>
        <div
          style={{
            top: 0,
            left: 0,
            minWidth: 'fit-content',
            width: '100%',
            height: 64,
            position: 'sticky',
            zIndex: 2,
            background: '#fff',
          }}
        >
          <TableHeader
            isMobile={false}
            sessions={stickySessions ?? []}
            title={'Activity Team'}
            subtitle={'Volunteers enrolled in this activity'}
          />
        </div>
        {children}
      </div>
    )}
  </StickyListContext.Consumer>
));

const otherInnerElementType = forwardRef(({ children, ...rest }, ref) => (
  <StickyListContext.Consumer>
    {({ stickySessions }: any) => (
      // @ts-ignore
      <div ref={ref} {...rest}>
        <div
          style={{
            top: 0,
            left: 0,
            display: 'table',
            width: '100%',
            height: 64,
            position: 'sticky',
            zIndex: 2,
            background: '#fff',
          }}
        >
          <TableHeader
            isMobile={false}
            sessions={stickySessions ?? []}
            title={'Other Volunteers'}
            subtitle={'All other volunteers from this program'}
          />
        </div>
        {children}
      </div>
    )}
  </StickyListContext.Consumer>
));

interface StickyListProps extends FixedSizeListProps {
  stickySessions: Array<ActivityTimelineFutureDraftRosterActivitySession>;
}
// @ts-ignore
const StickyList = ({ children, stickySessions, ...rest }: StickyListProps) => (
  // @ts-ignore
  <StickyListContext.Provider value={{ ItemRenderer: children, stickySessions }}>
    <FixedSizeList itemData={{ ItemRenderer: children, stickySessions }} {...rest}>
      {ItemWrapper}
    </FixedSizeList>
  </StickyListContext.Provider>
);

interface TableBodyProps {
  activityDate: string;
  activityEnrolmentsRosterGridData?: RosterGridData;
  activityRoles: Array<{ activityRoleId: string; name: string }>;
  handleRosterGridSessionClick: (volunteerId: string, sessionId: string) => void;
  hasGeneralVolunteerOnRoster: boolean;
  isMobile: boolean;
  otherVolunteersRosterGridData?: RosterGridData;
  runRefetch: () => void;
  updateRosterRow: (volunteerId: string, updatedRosterGridDataRow: RosterGridDataRow) => void;
  sessions: Array<ActivityTimelineFutureDraftRosterActivitySession>;
}

const TableBody = React.memo((props: TableBodyProps) => {
  const {
    activityDate,
    activityEnrolmentsRosterGridData,
    activityRoles,
    handleRosterGridSessionClick,
    hasGeneralVolunteerOnRoster,
    isMobile,
    otherVolunteersRosterGridData,
    runRefetch,
    updateRosterRow,
    sessions,
  } = props;
  const [allVolunteersSearchFilter, setAllVolunteersSearchFilter] = useState<string>();
  const [showAllVolunteers, setShowAllVolunteers] = useState(hasGeneralVolunteerOnRoster);
  const otherVolunteers = useMemo(
    () =>
      otherVolunteersRosterGridData?.filter((rosterGridDataRow) =>
        allVolunteersSearchFilter
          ? rosterGridDataRow.volunteer.profile.preferredName
              .concat(` ${rosterGridDataRow.volunteer.profile.lastName}`)
              .toLowerCase()
              .includes(allVolunteersSearchFilter.toLowerCase())
          : true
      ),
    [otherVolunteersRosterGridData, allVolunteersSearchFilter]
  );
  useEffect(() => setShowAllVolunteers(hasGeneralVolunteerOnRoster), [hasGeneralVolunteerOnRoster]);

  return (
    <Box padding={'15px'}>
      {isMobile && activityEnrolmentsRosterGridData ? (
        <VolunteersMobileList
          gridData={activityEnrolmentsRosterGridData}
          activityDate={activityDate}
          runRefetch={runRefetch}
          updateRosterRow={updateRosterRow}
          activityRoles={activityRoles}
        />
      ) : activityEnrolmentsRosterGridData ? (
        <StickyList
          itemCount={activityEnrolmentsRosterGridData.length}
          width={'100%'}
          height={
            activityEnrolmentsRosterGridData.length + 1 >= 11
              ? 11 * 64
              : (activityEnrolmentsRosterGridData.length + 1) * 64
          }
          itemSize={64}
          innerElementType={innerElementType}
          stickySessions={sessions}
        >
          {({ index, style }) => (
            <ListItem style={style} key={index}>
              <VolunteerRosterRow
                activityDate={activityDate}
                activityRoles={activityRoles}
                handleRosterGridSessionClick={handleRosterGridSessionClick}
                key={activityEnrolmentsRosterGridData[index].volunteerId}
                rosterGridDataRow={activityEnrolmentsRosterGridData[index]}
                runRefetch={runRefetch}
              />
            </ListItem>
          )}
        </StickyList>
      ) : null}

      {!!otherVolunteers?.length && (
        <>
          <ShowAllVolunteersControls
            hasGeneralVolunteerOnRoster={hasGeneralVolunteerOnRoster}
            isMobile={isMobile}
            setAllVolunteersSearchFilter={setAllVolunteersSearchFilter}
            setShowAllVolunteers={setShowAllVolunteers}
            showAllVolunteers={showAllVolunteers}
          />

          {(showAllVolunteers || hasGeneralVolunteerOnRoster) && !!otherVolunteersRosterGridData ? (
            <>
              {isMobile ? (
                <VolunteersMobileList
                  gridData={otherVolunteersRosterGridData}
                  activityDate={activityDate}
                  runRefetch={runRefetch}
                  updateRosterRow={updateRosterRow}
                  activityRoles={activityRoles}
                />
              ) : (
                <StickyList
                  height={otherVolunteers.length + 1 >= 11 ? 11 * 64 : (otherVolunteers.length + 1) * 64}
                  width={'100%'}
                  itemSize={64}
                  itemCount={otherVolunteers.length}
                  innerElementType={otherInnerElementType}
                  stickySessions={sessions}
                >
                  {({ index, style }) => (
                    <ListItem style={style} key={otherVolunteers[index].volunteerId}>
                      <VolunteerRosterRow
                        key={otherVolunteers[index].volunteerId}
                        activityDate={activityDate}
                        activityRoles={activityRoles}
                        handleRosterGridSessionClick={handleRosterGridSessionClick}
                        rosterGridDataRow={otherVolunteers[index]}
                        runRefetch={runRefetch}
                      />
                    </ListItem>
                  )}
                </StickyList>
              )}
            </>
          ) : null}
        </>
      )}
    </Box>
  );
});

interface VolunteersMobileListProps {
  gridData: RosterGridData;
  activityDate: string;
  runRefetch: () => void;
  updateRosterRow: (volunteerId: string, updatedRosterGridDataRow: RosterGridDataRow) => void;
  activityRoles: ActivityRole[];
}

const VolunteersMobileList = (props: VolunteersMobileListProps) => {
  const { gridData, activityDate, runRefetch, updateRosterRow, activityRoles } = props;
  return (
    <FixedSizeList height={300} width={'100%'} itemSize={93} itemCount={gridData.length}>
      {({ index, style }) => {
        const v = gridData[index];
        const volunteer = v.__typename === 'enrolled' ? v.activityEnrolment?.volunteer : v.volunteer;
        return (
          <VolunteerRosterListItem
            style={style}
            key={volunteer.volunteerId}
            activityDate={activityDate}
            runRefetch={runRefetch}
            rosterGridDataRow={gridData[index]}
            updateRosterRow={updateRosterRow}
            volunteerName={`${volunteer.profile.preferredName} ${volunteer.profile.lastName}`}
            activityRolesText={getActivityRolesText({
              activityRoles,
              volunteerActivityRoles: volunteer.activityRoles,
            })}
            isNew={!volunteer.hasAttendanceForActivity}
          />
        );
      }}
    </FixedSizeList>
  );
};

const ShowAllVolunteersControls = React.memo(
  ({
    hasGeneralVolunteerOnRoster,
    isMobile,
    setAllVolunteersSearchFilter,
    setShowAllVolunteers,
    showAllVolunteers,
  }: {
    hasGeneralVolunteerOnRoster: boolean;
    isMobile: boolean;
    setAllVolunteersSearchFilter: (value?: string) => void;
    setShowAllVolunteers: (value: boolean) => void;
    showAllVolunteers: boolean;
  }) => (
    <>
      <Box paddingLeft={2} marginTop={1} marginBottom={2}>
        {!hasGeneralVolunteerOnRoster && showAllVolunteers && (
          <HoverText
            data-track='actCnl-roster-hide-all-volunteers'
            variant='body2'
            onClick={() => {
              setShowAllVolunteers(false);
              setAllVolunteersSearchFilter(undefined);
            }}
            color='primary'
          >
            {'Hide all other volunteers'}
          </HoverText>
        )}

        {!showAllVolunteers && (
          <HoverText
            data-track='actCnl-roster-show-all-volunteers'
            variant='body2'
            onClick={() => setShowAllVolunteers(true)}
            color='primary'
          >
            {'Show all other volunteers'}
          </HoverText>
        )}
      </Box>

      {showAllVolunteers && (
        <>
          <Box
            marginLeft={1}
            marginBottom={1}
            maxWidth={ROSTER_SCROLL_DIMENSION.TABLE_COLUMN_FULL_WIDTH + ROSTER_SCROLL_DIMENSION.COLUMN_WIDTH}
            paddingRight='16px'
            paddingLeft={isMobile ? 2 : 0}
          >
            <SearchField
              placeholder='Search Other Volunteers'
              fullWidth
              onChange={(e) => setAllVolunteersSearchFilter(e.target.value)}
            />
          </Box>
        </>
      )}
    </>
  )
);

const LoadingSkeleton = () => {
  const { theme } = useCampfireTheme();
  return (
    <Box display='flex' flex='1 1 auto' width={1} flexDirection='column'>
      <Box
        height={ROSTER_SCROLL_DIMENSION.PAGE_HEADER_HEIGHT}
        boxSizing='border-box'
        width={1}
        paddingX={3}
        paddingY={1}
        borderBottom={`1px solid ${theme.color.grey.border}`}
      >
        <Skeleton width={'40%'} height={15} />
        <Skeleton width={'35%'} height={10} />
      </Box>
      <Box
        height={ROSTER_SCROLL_DIMENSION.PAGE_SUBHEADER_HEIGHT + ROSTER_SCROLL_DIMENSION.TABLE_HEADER_HEIGHT}
        boxSizing='border-box'
        paddingX={3}
        paddingY={1}
        paddingBottom={2}
        borderBottom={`1px solid ${theme.color.grey.border}`}
      >
        <Skeleton width={'15%'} height={10} />

        <Box width={1} paddingY={1}>
          <Skeleton width={150} height={25} />
        </Box>
      </Box>

      <Box width={1} paddingX={6} paddingY={6}>
        <Box display='flex' alignContent='center' alignItems='center' marginBottom={2}>
          <Skeleton variant='circle' width={36} height={36} style={{ marginRight: 12 }} />
          <Skeleton width={75} height={10} />
        </Box>

        <Box display='flex' alignContent='center' alignItems='center' marginBottom={2}>
          <Skeleton variant='circle' width={36} height={36} style={{ marginRight: 12 }} />
          <Skeleton width={75} height={10} />
        </Box>

        <Box display='flex' alignContent='center' alignItems='center'>
          <Skeleton variant='circle' width={36} height={36} style={{ marginRight: 12 }} />
          <Skeleton width={75} height={10} />
        </Box>
      </Box>
    </Box>
  );
};
