import { Button, Theme } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { SelectVolunteersDialog } from '../../../../common/select-volunteers/SelectVolunteersDialog';
import { RosterContext } from './RosterContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'none',
      borderRadius: '4px',
      padding: '7px 12px',
      fontSize: '13px',
      minWidth: '45px',
      lineHeight: '19px',
      backgroundColor: theme.color.secondary.main900,
      marginTop: '1.5rem',
    },
  })
);

export function AddVolunteer() {
  const { volunteers, activity, addNewVolunteer } = React.useContext(RosterContext);
  const [openAddVolunteersDialog, setOpenAddVolunteersDialog] = React.useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Button
        startIcon={<AddCircle style={{ fontSize: '18px' }} />}
        variant='contained'
        color='primary'
        onClick={() => setOpenAddVolunteersDialog(true)}
        className={classes.button}
        disableElevation
      >
        Add New Volunteer
      </Button>
      {openAddVolunteersDialog && activity && (
        <SelectVolunteersDialog
          open={openAddVolunteersDialog}
          enableGroupByActivity={false}
          activity={activity}
          handleClose={() => setOpenAddVolunteersDialog(false)}
          handleAdd={addNewVolunteer}
          ignoredVolunteerIds={volunteers?.map((r) => r.volunteerId) ?? []}
          dialogContentText={`These volunteers aren't enrolled in this activity but you can still add them to this roster if you would like.`}
        />
      )}
    </React.Fragment>
  );
}
