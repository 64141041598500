import React from 'react';
import { Box, Theme, Grid, Typography, Divider } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Select } from '@campfire/select';
import { MultiSelect } from '@campfire/multi-select';
import { makeStyles, createStyles } from '@material-ui/styles';
import { CreatWidgetFormValues, rangeList, intervalList } from '../../dashboard-widget-model';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';
import { useWidgetContextForm } from '../../useWidgetContextForm';
import { SelectedValues } from '../SelectedValues';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.color.grey.neutral500,
      fontWeight: 700,
      fontSize: '18px',
      marginBottom: '8px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 700,
      color: theme.color.grey.neutral500,
      paddingBottom: '6px',
    },
    valueItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '56px',
      borderRadius: '6px',
      marginTop: '-8px',
      padding: '0 16px',
      cursor: 'pointer',
      position: 'relative',

      border: `1px solid ${theme.color.grey.neutralBrand200}`,
    },
  })
);

export const AverageNumberWidgetForm = () => {
  const { theme } = useCampfireTheme();
  const classes = useStyles();
  const { values, handleChange } = useFormikContext<CreatWidgetFormValues>();
  const { programs, activities, activityTags, reportFields } = useWidgetContextForm();
  const isReportFieldValue = values.byValues.some(
    ({ tag, name }) => tag === 'reporting' && name === 'report-field-value'
  );

  return (
    <Grid container direction='column' style={{ width: '100%', marginBottom: '16px' }}>
      <Grid item xs={12}>
        <Typography className={classes.title}>Filtering</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography className={classes.label}>Programs</Typography>
            <MultiSelect
              variant='outlined'
              name='programIds'
              label='Select program/s'
              placeholder='All Programs'
              items={programs}
              style={{ borderRadius: '8px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.label}>Activities</Typography>
            <MultiSelect
              variant='outlined'
              name='activityIds'
              label='Select activity/activities'
              placeholder='All Activities'
              items={activities}
              style={{ borderRadius: '8px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.label}>Activity Tags</Typography>
            <MultiSelect
              variant='outlined'
              name='tagIds'
              placeholder='All Activity Tag'
              label='Select tag/s'
              items={activityTags}
              style={{ borderRadius: '8px' }}
            />
          </Grid>
          {isReportFieldValue && (
            <Grid item xs={6}>
              <Typography className={classes.label}>Report fields</Typography>
              <MultiSelect
                variant='outlined'
                name='fieldIds'
                placeholder='All Report Field'
                label='Select fields'
                items={reportFields}
                style={{ borderRadius: '8px' }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box my={3}>
        <Divider />
      </Box>
      <Grid item xs={12}>
        <Typography className={classes.title}>Range</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography className={classes.label}>Range</Typography>
            <Select
              fullWidth
              name='range'
              variant='outlined'
              items={rangeList}
              renderFunction={(item) => item.label}
              valueFunction={(item) => item.value}
              InputProps={{
                style: { borderRadius: '8px', color: theme.color.grey.neutral300 },
              }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.label}>Interval</Typography>
            <Select
              fullWidth
              name='interval'
              variant='outlined'
              items={intervalList}
              renderFunction={(item) => item.label}
              valueFunction={(item) => item.value}
              InputProps={{
                style: { borderRadius: '8px', color: theme.color.grey.neutral300 },
              }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box my={3}>
        <Divider />
      </Box>
      <SelectedValues maxValue={1} />
    </Grid>
  );
};
