import React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 57px)',
      paddingLeft: '40px',
      paddingRight: '40px',
      backgroundColor: '#F9F9F9',
    },
  })
);

interface ContainerProps {
  children: React.ReactNode;
}

export function Container({ children }: ContainerProps) {
  const classes = useStyles();
  return <Box className={classes.root}>{children}</Box>;
}
