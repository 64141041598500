import { gql } from '@apollo/client';

export const ActivityRosterTemplate = gql`
  fragment ActivityRosterTemplate on VOLUNTEER_RosterTemplateType {
    placesAddress {
      latLong {
        lat
        long
      }
      formatted
    }
    remoteLocation {
      details
    }
  }
`;
