/* eslint-disable graphql/template-strings */

import { gql } from '@apollo/client';
import { ActivityTimelineItemFragments } from '../ActivityTimeline/ActivityTimelineItemFragments.gql';
import { ActivityScheduleFragments } from '../ActivityDialogs/ActivityScheduleFragments.gql';

export const GET_MY_ACTIVITIES = gql`
  query ActivitiesGetMyShifts($volunteerId: String!, $startDate: Date, $endDate: Date) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        upcomingRosters(startDate: $startDate, endDate: $endDate) {
          ... on VOLUNTEER_UpcomingRosterCancellationType {
            upcomingRosterId
            activity {
              ...ActivityTimelineItem
              ...ActivitySchedule
            }
            activityDate
            publishedRoster {
              ...UpcomingRosterPublishedRoster
            }
            __typename
          }

          ... on VOLUNTEER_UpcomingRosterAvailabilityType {
            upcomingRosterId
            activity {
              ...ActivityTimelineItem
              ...ActivitySchedule
            }
            activityDate
            publishedRoster {
              ...UpcomingRosterPublishedRoster
            }
            __typename
          }

          ... on VOLUNTEER_UpcomingRosterRosteringType {
            upcomingRosterId
            activity {
              ...ActivityTimelineItem
              ...ActivitySchedule
            }
            activityDate
            publishedRoster {
              ...UpcomingRosterPublishedRoster
            }
            __typename
          }

          ... on VOLUNTEER_UpcomingRosterUnavailabilityType {
            upcomingRosterId
            activity {
              ...ActivityTimelineItem
              ...ActivitySchedule
            }
            activityDate
            publishedRoster {
              ...UpcomingRosterPublishedRoster
            }
            __typename
          }

          ... on VOLUNTEER_UpcomingRosterUnspecifiedType {
            upcomingRosterId
            activity {
              ...ActivityTimelineItem
              ...ActivitySchedule
            }
            activityDate
            publishedRoster {
              ...UpcomingRosterPublishedRoster
            }
            __typename
          }
        }
      }
    }
  }
  fragment UpcomingRosterPublishedRoster on VOLUNTEER_PublishedRosterType {
    activeRosterings {
      dateRemoved
      volunteer {
        volunteerId
        profile {
          profileId
          userId
          firstName
          lastName
          preferredName
          avatarUrl
        }
        checkedIn {
          CICOId
          checkIn
          sessionReport {
            activityReport {
              activity {
                activityId
              }
              activityDate
            }
          }
        }
        CICOs {
          CICOId
          checkIn
          checkOut
          sessionReport {
            session {
              sessionId
            }
            activityReport {
              activity {
                activityId
              }
              activityDate
            }
          }
        }
        isCheckedIn
      }
      sessionRosterings {
        session {
          sessionId
          name
        }
      }
    }
  }
  ${ActivityTimelineItemFragments}
  ${ActivityScheduleFragments}
`;

export const GET_MY_PENDING_APPLICATIONS = gql`
  query ActivitiesGetMyPendingApplications($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        activityApplications {
          ...ActivityApplicationFragments
        }
      }
    }
  }
  fragment ActivityApplicationFragments on VOLUNTEER_ActivityApplicationType {
    activity {
      activityId
      name
      startTime
      endTime
    }
    activityApplicationId
    dateApplied
    dateWithdrawn
    dateActioned
  }
`;

export const GET_MY_WAITLISTING_APPLICATIONS = gql`
  query ActivitiesGetMyWaitlistings($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        activityWaitlistings {
          ...ActivityWaitlistingFragments
        }
      }
    }
  }
  fragment ActivityWaitlistingFragments on VOLUNTEER_ActivityWaitlistingType {
    activity {
      activityId
      name
      startTime
      endTime
    }
    activityWaitlistingId
    dateAdded
    dateRemoved
  }
`;
