import { TitularTooltip } from '@campfire/titular-tooltip';
import { DraggableList, DraggableListItem, DraggableListItemHandle } from '@campfire/draggable-list';
import { Select } from '@campfire/select';
import { ButtonBase, Typography, Box, Theme, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { FieldArrayRenderProps, useFormikContext } from 'formik';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { ConfirmationDialog } from '../../../../../common/dialogs/ConfirmationDialog';
import { ScreeningQuestionsFormValues, ScreeningQuestionType } from './admin-console-applicant-screening-model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
      paddingTop: 8,
      paddingBottom: 8,
      display: 'flex',
      '&:hover': {
        background: theme.palette.grey[100],
      },
      '&:hover $deleteIcon': {
        color: theme.palette.action.disabled,
        '&:hover': {
          color: theme.palette.error.main,
        },
      },
      '&:hover $fieldItemHandle': {
        color: theme.palette.action.active,
      },
    },
    deleteIcon: {
      color: 'transparent',
    },
    fieldItemHandle: {
      paddingLeft: 4,
      paddingRight: 8,
      color: 'transparent',
    },
  })
);

interface ScreeningQuestionListItemProps {
  screeningQuestion: ScreeningQuestionType;
  index: number;
  length: number;
  arrayHelpers: FieldArrayRenderProps;
}

const ScreeningQuestionListItem = React.memo(
  (props: ScreeningQuestionListItemProps) => {
    const { screeningQuestion, index, length, arrayHelpers } = props;
    const [reasonToRemove, setReasonToRemove] = useState<ScreeningQuestionType>();
    const classes = useStyles();

    const name = `screeningQuestions[${index}]`;
    const formik = useFormikContext<ScreeningQuestionsFormValues>();
    const [label, setLabel] = useState(screeningQuestion.label);

    return (
      <>
        <DraggableListItem
          id={`${index}`}
          index={index}
          className={classes.listItem}
          style={index === length - 1 ? { borderBottom: 'none' } : undefined}
        >
          <Box display='flex' flex='1 1 auto' position='relative'>
            <Box>
              <DraggableListItemHandle className={classes.fieldItemHandle} />
            </Box>

            <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
              <Box flex='1 0 auto'>
                <TextField
                  value={label}
                  onChange={(e) => {
                    setLabel(e.target.value);
                  }}
                  onBlur={() => {
                    if (label === '') {
                      setLabel(screeningQuestion.label);
                      return;
                    }
                    if (label !== screeningQuestion.label) {
                      formik.setFieldValue(name, { ...screeningQuestion, label: label });
                      formik.submitForm();
                    }
                  }}
                  multiline
                  fullWidth
                  style={{ width: '100%' }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Box>

              <TitularTooltip description='Correct Answer'>
                <Box flex='1 1 auto' maxWidth={96} paddingX={1}>
                  <Select
                    slow
                    name={`${name}.correctAnswer`}
                    variant='standard'
                    native
                    onBlur={() => formik.submitForm()}
                    onChange={() => formik.submitForm()}
                    SelectProps={{ onClose: () => formik.submitForm() }}
                    style={{ width: '100%' }}
                    valueFunction={(value) => (!value ? '' : value)}
                    renderFunction={(value) => (value === '' ? 'Either' : capitalize(value))}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    items={['', 'yes', 'no']}
                    fullWidth
                  />
                </Box>
              </TitularTooltip>
            </Box>

            <TitularTooltip description='Delete screening question'>
              <Box>
                <ButtonBase
                  style={{ flex: 1, padding: 8 }}
                  onClick={() => {
                    setReasonToRemove({ ...screeningQuestion });
                  }}
                >
                  <Close className={classes.deleteIcon} />
                </ButtonBase>
              </Box>
            </TitularTooltip>
          </Box>
        </DraggableListItem>

        {reasonToRemove ? (
          <ConfirmationDialog
            title={'Delete Screening Question?'}
            body={
              <>
                <Typography variant='body2' gutterBottom>
                  {'Past applications will not be affect.'}
                </Typography>
              </>
            }
            open
            closeActionText={'Go Back'}
            confirmActionText={'Delete'}
            handleCloseClick={() => setReasonToRemove(undefined)}
            handleConfirmClick={() => {
              arrayHelpers.remove(index);
              setReasonToRemove(undefined);
              formik.submitForm();
            }}
          />
        ) : null}
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.index === nextProps.index && prevProps.screeningQuestion === nextProps.screeningQuestion;
  }
);

export const ScreeningQuestionListFieldSection = ({ arrayHelpers }: { arrayHelpers: FieldArrayRenderProps }) => {
  const formik = useFormikContext<ScreeningQuestionsFormValues>();
  const { screeningQuestions } = formik.values;

  return (
    <>
      <ListFieldHeader />

      <DraggableList
        style={{ paddingTop: 0 }}
        onReorder={(startIdx, endIdx, reorder) => {
          if (formik) {
            formik.setFieldValue('screeningQuestions', reorder(screeningQuestions, startIdx, endIdx));
            formik.submitForm();
          }
        }}
      >
        {screeningQuestions.map((screeningQuestion, index) => (
          <ScreeningQuestionListItem
            key={screeningQuestion.screeningQuestionId}
            screeningQuestion={screeningQuestion}
            index={index}
            length={screeningQuestions.length}
            arrayHelpers={arrayHelpers}
          />
        ))}
      </DraggableList>
    </>
  );
};

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRow: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    fieldItemHandle: {
      paddingLeft: 4,
      paddingRight: 8,
      color: 'transparent',
    },
  })
);

const ListFieldHeader = () => {
  const classes = useHeaderStyles();

  return (
    <Box className={classes.headerRow} paddingY={1} display='flex' flex='1 1 auto' position='relative'>
      <Box>
        <DraggableListItemHandle className={classes.fieldItemHandle} />
      </Box>

      <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
        <Box flex='1 0 auto'>
          <Typography variant='overline' color='textPrimary'>
            {'Question'}
          </Typography>
        </Box>

        <Box flex='1 1 auto' maxWidth={96} paddingX={1}>
          <Typography variant='overline' color='textPrimary'>
            {'Answer'}
          </Typography>
        </Box>
      </Box>

      <Box width={40}></Box>
    </Box>
  );
};
