import { HoverText } from '@campfire/hover-link';
import { Box, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useQueryParam } from 'use-query-params';
import {
  areInitialTimeFiltersDefault,
  Filters,
  TIME_FILTER_END_TIME_URL_PARAM_KEY,
  TIME_FILTER_START_TIME_URL_PARAM_KEY,
} from '../../../../../common/filter-fields/filters';

type InnerTimeFilterProps = {
  isDefaultTimes?: boolean;
  start: string | undefined;
  end: string | undefined;
  setStart: (value: string | undefined) => void;
  setEnd: (value: string | undefined) => void;
  handleClearTimes: () => void;
};

const InnerTimeFilter = (props: InnerTimeFilterProps) => {
  const { start, end, setStart, setEnd, handleClearTimes, isDefaultTimes } = props;

  return (
    <Box paddingY={1}>
      <Box display='flex' flex='1 1 auto'>
        <TextField
          id='startTime'
          label='Start time'
          type='time'
          variant='outlined'
          style={{
            marginRight: 8,
            // minWidth: 196
            width: '100%',
          }}
          value={start ?? ''}
          color='primary'
          onChange={(event) => setStart(event.target.value)}
          onBlur={() => {
            if (!start) return;
            setStart(start);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />

        <TextField
          id='endTime'
          label='End time'
          type='time'
          variant='outlined'
          style={{
            // minWidth: 196
            width: '100%',
          }}
          value={end ?? ''}
          onChange={(event) => setEnd(event.target.value)}
          onBlur={() => {
            if (!end) return;
            setEnd(end);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />
      </Box>

      <Box display='flex' flex='1 0 auto' justifyContent='flex-end' marginTop={1}>
        <HoverText disabled={isDefaultTimes} color='error' variant='body2' onClick={handleClearTimes}>
          Clear time filters
        </HoverText>
      </Box>
    </Box>
  );
};

type TimeFilterProps = {
  selectedFilters: Filters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
};

const TimeFilter = ({ selectedFilters, setSelectedFilters }: TimeFilterProps) => {
  const { selectedTimeFilter } = selectedFilters;

  const isDefaultTimes = areInitialTimeFiltersDefault(selectedTimeFilter);

  const [start, setStart] = useQueryParam<string | undefined>(TIME_FILTER_START_TIME_URL_PARAM_KEY);
  const [end, setEnd] = useQueryParam<string | undefined>(TIME_FILTER_END_TIME_URL_PARAM_KEY);

  function handleClearTimes() {
    setStart(undefined);
    setEnd(undefined);
  }

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      selectedTimeFilter: {
        startTime: DateTime.fromFormat(start ?? '00:00', 'HH:mm').toJSDate(),
        endTime: DateTime.fromFormat(end ?? '23:59', 'HH:mm').toJSDate(),
      },
    }));
  }, [start, end]);

  return (
    <Formik
      initialValues={{
        ...selectedFilters.selectedTimeFilter,
      }}
      onSubmit={() => {}}
    >
      <InnerTimeFilter
        start={start}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
        isDefaultTimes={isDefaultTimes}
        handleClearTimes={handleClearTimes}
      />
    </Formik>
  );
};

export { TimeFilter };
