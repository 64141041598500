import React, { FunctionComponent } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { CheckRounded } from '@material-ui/icons';

const IncomingVolunteerConfirmationDialog: FunctionComponent<{
  title: string;
  body: string;
  id: string;
  disabled?: boolean;
  open: boolean;
  approveButtonText?: string;
  handleCloseClick: (open: boolean) => void;
  handleApproveClick: (id: string) => void;
}> = ({ title, body, open, id, handleCloseClick, approveButtonText, handleApproveClick, disabled }) => {
  return (
    <Dialog open={open} onClose={() => handleCloseClick(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>{body}</Typography>
      </DialogContent>
      <DialogActions>
        <TabletButton
          disabled={disabled}
          data-track='fs-incVol-approve-dialog-cancel-button'
          size='medium'
          onClick={() => handleCloseClick(false)}
        >
          {'Cancel'}
        </TabletButton>
        <TabletButton
          disabled={disabled}
          data-track='fs-incVol-approve-dialog-approve-button'
          size='medium'
          onClick={() => handleApproveClick(id)}
          startIcon={<CheckRounded />}
          variant='contained'
          color='primary'
          autoFocus
        >
          {approveButtonText ?? 'Approve'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { IncomingVolunteerConfirmationDialog };
