import { Select } from '@campfire/select';
import { Field } from '@campfire/field';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { GREETING_OPTIONS, SIGNOFF_OPTIONS } from '../AdminConsolePreferencesConsts';
import { useCommonStyles } from '../commonStyles';
import { PreferenceFormValues } from '../AdminConsoleCommunicationPreferences';

export const GreetingAndSignOffFormSection = ({ isLoading }: { isLoading: boolean }) => {
  const classes = useCommonStyles();
  const { values } = useFormikContext<PreferenceFormValues>();

  return (
    <>
      <Typography className={classes.sectionHeading}>Greeting & Sign-Off</Typography>
      <Typography className={classes.sectionBody}>
        You can select how volunteers are addressed at the beginning and end of emails.
      </Typography>
      <Typography className={classes.fieldLabel}>Greeting</Typography>
      {isLoading ? (
        <Skeleton variant='rect' width='85%' height='62px' style={{ borderRadius: '8px' }} className={classes.field} />
      ) : (
        <Select
          slow
          fullWidth
          variant='outlined'
          id='greeting'
          name='greeting'
          label='Choose'
          native={false}
          required={false}
          items={GREETING_OPTIONS.sort((a, b) => a.localeCompare(b)).concat('Custom')}
          renderFunction={(greeting: string) => greeting}
          valueFunction={(greeting: string) => greeting}
          className={classNames(classes.field, classes.inputText)}
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
        />
      )}
      {values.greeting === 'Custom' && (
        <Box className={classes.textField}>
          <Field
            helper
            fullWidth
            shrink
            required
            id='customGreeting'
            name='customGreeting'
            label='Custom Greeting'
            variant='outlined'
            placeholder='Type your custom greeting here'
            InputProps={{
              classes: {
                input: classes.inputText,
              },
            }}
          />
        </Box>
      )}
      <Typography className={classes.fieldLabel}>Sign-Off</Typography>
      {isLoading ? (
        <Skeleton variant='rect' width='85%' height='62px' style={{ borderRadius: '8px' }} className={classes.field} />
      ) : (
        <Select
          slow
          fullWidth
          variant='outlined'
          id='greeting'
          label='Choose'
          name='signoff'
          native={false}
          required={false}
          items={SIGNOFF_OPTIONS.sort((a, b) => a.localeCompare(b)).concat('Custom')}
          renderFunction={(signoff: string) => signoff}
          valueFunction={(signoff: string) => signoff}
          className={classes.field}
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
        />
      )}
      {values.signoff === 'Custom' && (
        <Box className={classes.textField}>
          <Field
            helper
            fullWidth
            shrink
            required
            id='customSignoff'
            name='customSignoff'
            label='Custom Sign-off'
            variant='outlined'
            placeholder='Type your custom sign-off here'
            InputProps={{
              classes: {
                input: classes.inputText,
              },
            }}
          />
        </Box>
      )}
    </>
  );
};
