import React, { useEffect, useMemo } from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { Form, Formik } from 'formik';
import { array as YupArray, date as YupDate, object as YupObject, string as YupString } from 'yup';
import { DateTime } from 'luxon';
import { useProgramManagementContext } from '../../ProgramManagementContext';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';
import { FormikErrorFocus } from '../../../../../common/form/FormikErrorFocus';
import { ProgramManagementSingleProgramBasicInfo } from '../../__generated__/ProgramManagementSingleProgramBasicInfo';
import { CreateBaseActivityFormV2 } from '../../../../program-manager/activity-management/activity-form-v2/CreateBaseActivityFormV2';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetActivityManagementActivityTags } from '../../../../program-manager/activity-management/activity-form-v2/__generated__/GetActivityManagementActivityTags';
import { GET_ACTIVITY_MANAGEMENT_ACTIVITY_TAGS } from '../../../../program-manager/activity-management/activity-form-v2/activity-form-model-v2.gql';
import { ActivityFormRecurrenceFrequency } from '../../../../program-manager/activity-management/activity-form-v2/ActivityFormV2';
import { DailyRecurrence } from '../../../../../common/recurrence/__generated__/DailyRecurrence';

const validationSchema = YupObject().shape({
  activityId: YupString(),
  activityName: YupString().required('Please provide a name'),
  activityDescription: YupString(),
  programId: YupString().required('Please select a program'),
  startDate: YupDate()
    .nullable()
    .typeError('Please select a date'),
  endDate: YupDate()
    .nullable()
    .typeError('Please select a date'),
  frequency: YupString()
    .nullable()
    .typeError('Please select one'),
  sessions: YupArray().required('At least 1 session is required'),
});

interface Props {
  open: boolean;
  close: () => void;
  selectedProgram: ProgramManagementSingleProgramBasicInfo | undefined;
  refetch: () => void;
}
const AddActivityDialog = (props: Props) => {
  const { open, close, selectedProgram, refetch } = props;
  const { runSaveActivity, saveActivityIsLoading, saveActivityIsFulfilled, compact } = useProgramManagementContext();

  const handleSuccess = () => {
    if (refetch) refetch();
  };

  const { data: tagsData } = useCampfireQuery<GetActivityManagementActivityTags, {}>(
    GET_ACTIVITY_MANAGEMENT_ACTIVITY_TAGS
  );

  const initialValues = useMemo(
    () => ({
      activityId: '',
      activityName: '',
      activityDescription: '',
      isRestrictedActivity: true,
      isHidden: false,
      maxTeam: null,
      hasOpenRoster: false,
      allowCICO: null,
      programId: selectedProgram?.programId ?? '',
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      frequency: 'daily' as ActivityFormRecurrenceFrequency,
      locationType: 'local',
      location: {
        activityLocationId: '',
        description: '',
        formatted: '',
        latitude: 0,
        longitude: 0,
        placesId: '',
      },
      remoteLocation: {
        details: '',
      },
      attachments: [],
      addedAttachments: [],
      creationTokens: [],
      removedAttachmentIds: [],
      sessions: [],
      activityTagIds: [],
      recurrence: {
        __typename: 'DailyRecurrenceType',
        dayInterval: 1,
        startDate: DateTime.local().toISODate(),
        endDate: null,
        humanReadable: '',
      } as DailyRecurrence,
    }),
    [open]
  );

  useEffect(() => {
    if (saveActivityIsFulfilled) close();
  }, [saveActivityIsFulfilled]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(vals) => {
        runSaveActivity(vals, handleSuccess);
      }}
    >
      {({ values }) => (
        <FullscreenDialog
          open={open}
          maxWidth='lg'
          close={close}
          title={'Create Activity'}
          fullScreen={compact}
          dialogActions={() => (
            <>
              {/* Button requires form id to work when inside DialogActions https://github.com/mui-org/material-ui/issues/6128 - TD 31/3/2020 */}
              <TabletButton
                size='large'
                color='primary'
                variant='contained'
                type='submit'
                form='add-activity-form'
                disabled={saveActivityIsLoading}
              >
                {'Create Activity'}
              </TabletButton>
            </>
          )}
        >
          {/* Form requires form id so submit button works when inside DialogActions https://github.com/mui-org/material-ui/issues/6128 - TD 31/3/2020 */}
          <Form id='add-activity-form'>
            <CreateBaseActivityFormV2
              sessions={values.sessions}
              programs={selectedProgram ? [selectedProgram] : []}
              attachments={values.attachments}
              activityTags={tagsData?.vm.activityTags || []}
            />
            <FormikErrorFocus />
          </Form>
        </FullscreenDialog>
      )}
    </Formik>
  );
};

export { AddActivityDialog };
