import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useUser } from '../../../global/auth/useUser';
import { useSnackbar } from '../../../global/config/useSnackbar';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { GetSupportCategories } from './__generated__/GetSupportCategories';
import { SupportCategory } from './__generated__/SupportCategory';
import {
  SaveCategoryValues,
  SaveSupportResourceValues,
  useDeleteCategoryFetch,
  useDeleteSupportResourceFetch,
  useSaveCategoryFetch,
  useSaveSupportResourceFetch,
} from './support-actions';
import { GET_SUPPORT_CATEGORIES } from './support-screen-model.gql';

interface SupportContextInterface {
  allCategories: SupportCategory[];
  categoriesIsLoading: boolean;
  inEditCategoryMode?: boolean;
  inEditResourceMode?: boolean;
  tutorialDialogOpen: boolean;
  setInEditCategoryMode: (x?: boolean) => void;
  setInEditResourceMode: (x?: boolean) => void;
  setTutorialDialogOpen: (x: boolean) => void;
  runSaveCategory: (x: SaveCategoryValues) => Promise<string | undefined>;
  saveCategoryIsLoading: boolean;
  saveCategoryIsFulfilled: boolean;
  newCategoryId?: string;
  runDeleteCategory: (supportCategoryId: string) => Promise<{ ok: boolean }>;
  deleteCategoryIsLoading: boolean;
  runSaveSupportResource: (x: SaveSupportResourceValues) => Promise<{ ok: boolean }>;
  saveSupportResourceIsLoading: boolean;
  runDeleteSupportResource: (supportResourceId: string) => Promise<{ ok: boolean }>;
  deleteSupportResourceIsLoading: boolean;
  isAdmin: boolean;
}

interface SupportProviderProps {
  children: React.ReactNode;
}

const SupportContext = React.createContext<SupportContextInterface | undefined>(undefined);

const SupportProvider = (props: SupportProviderProps) => {
  const { setSnackbar } = useSnackbar();
  const {
    user: { userIdentityService },
  } = useUser();
  const isAdmin = userIdentityService.isVmAdmin;

  const [inEditCategoryMode, setInEditCategoryMode] = useQueryParam('cedit', BooleanParam);
  const [inEditResourceMode, setInEditResourceMode] = useQueryParam('redit', BooleanParam);
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);

  const saveCategory = useSaveCategoryFetch();
  const deleteCategory = useDeleteCategoryFetch();
  const saveSupportResource = useSaveSupportResourceFetch();
  const deleteSupportResource = useDeleteSupportResourceFetch();

  const { data: response, error, loading: isLoading, refetch: refetchSupportCategories } = useCampfireQuery<
    GetSupportCategories,
    undefined
  >(GET_SUPPORT_CATEGORIES);

  const allCategories = useMemo(() => response?.vm.supportCategories ?? [], [response]);

  useEffect(() => {
    if (error)
      setSnackbar({
        open: true,
        message: `Can't connect to server`,
        variant: 'error',
      });
  }, [error]);

  const runSaveCategory = (values: SaveCategoryValues) => {
    return saveCategory
      .run({
        supportCategoryId: values.supportCategoryId,
        title: values.title,
        supportResources: values.supportResources,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save category',
            variant: 'error',
          });
          return undefined;
        }

        setSnackbar({
          open: true,
          message: 'Category saved',
          variant: 'success',
        });

        if (refetchSupportCategories) refetchSupportCategories();

        return res.data.data.supportCategoryId;
      });
  };

  const runDeleteCategory = (supportCategoryId: string) => {
    return deleteCategory.run({ supportCategoryId }).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to delete category',
          variant: 'error',
        });
        return { ok: false };
      }

      setSnackbar({
        open: true,
        message: 'Category deleted',
        variant: 'success',
      });

      if (refetchSupportCategories) refetchSupportCategories();
      return { ok: true };
    });
  };

  const runSaveSupportResource = (values: SaveSupportResourceValues) => {
    return saveSupportResource
      .run({
        supportResourceId: values.supportResourceId ? values.supportResourceId : undefined,
        featured: values.featured,
        title: values.title,
        textContent: values.textContent ? values.textContent : undefined,
        supportCategoryId: values.supportCategoryId,
        addedAttachments: values.addedAttachments,
        attachmentCreationTokens: values.attachmentCreationTokens,
        removedAttachmentIds: values.removedAttachmentIds,
        programIds: values.programIds,
        activityIds: values.activityIds,
        roleIds: values.roleIds,
        tagIds: values.tagIds,
        permissionLevel: values.permissionLevel,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to update resource',
            variant: 'error',
          });
          return { ok: false };
        }

        setSnackbar({
          open: true,
          message: 'Resource updated',
          variant: 'success',
        });

        if (refetchSupportCategories) refetchSupportCategories();

        return { ok: true };
      });
  };

  const runDeleteSupportResource = (supportResourceId: string) => {
    return deleteSupportResource.run({ supportResourceId }).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to delete resource',
          variant: 'error',
        });
        return { ok: false };
      }

      setSnackbar({
        open: true,
        message: 'Resource deleted',
        variant: 'success',
      });

      if (refetchSupportCategories) refetchSupportCategories();
      return { ok: true };
    });
  };

  const value = {
    allCategories,
    categoriesIsLoading: isLoading,
    inEditCategoryMode,
    inEditResourceMode,
    tutorialDialogOpen,
    setInEditCategoryMode,
    setInEditResourceMode,
    setTutorialDialogOpen,
    runSaveCategory,
    saveCategoryIsLoading: saveCategory.isLoading,
    saveCategoryIsFulfilled: saveCategory.isFulfilled,
    runDeleteCategory,
    deleteCategoryIsLoading: deleteCategory.isLoading,
    runSaveSupportResource,
    saveSupportResourceIsLoading: saveSupportResource.isLoading,
    runDeleteSupportResource,
    deleteSupportResourceIsLoading: deleteSupportResource.isLoading,
    deleteSupportResourceIsFulfilled: deleteSupportResource.isFulfilled,
    isAdmin,
  };

  return <SupportContext.Provider value={value} {...props} />;
};

export const useSupportContext = () => {
  const value = useContext(SupportContext);
  if (!value) throw new Error('Must be inside of a SupportProvider');
  return value;
};

export { SupportProvider };
