import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { FileUploadGallery } from '@campfire/file-upload-gallery';
import { ActivityHeader } from './ActivityHeader';
import { ActivityAttachments as ActivityAttachmentType } from './__generated__/ActivityAttachments';

interface ActivityAttachmentsProps {
  activityAttachments: ActivityAttachmentType[];
}

const useActivityAttachmentsStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      textAlign: 'center',
      color: theme.color.grey.neutral400,
      fontSize: '13px',
      paddingTop: '12px',
    },
  })
);

export function ActivityAttachments({ activityAttachments }: ActivityAttachmentsProps) {
  const attachments =
    activityAttachments?.map((attachment) => ({
      name: attachment.name,
      url: attachment.url,
      fileId: attachment.activityAttachmentId,
    })) || [];
  const classes = useActivityAttachmentsStyles();

  return (
    <Box marginTop='2rem'>
      <ActivityHeader title='Attachments' />
      <Box>
        {activityAttachments?.length === 0 ? (
          <Typography className={classes.text}>There are no attachments on this activity</Typography>
        ) : (
          <FileUploadGallery allowMultiple uploadedFiles={attachments} viewOnly />
        )}
      </Box>
    </Box>
  );
}
