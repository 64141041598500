import React from 'react';
import { unpackToDate } from '@campfire/hot-date';
import { Typography } from '@material-ui/core';
import { CompletedTask_taskFieldValues as CompletedTaskFieldValue } from '../../../../common/form/task-form/model/__generated__/CompletedTask';
import { GetAllCompletedTasks_vm_profile_completedTasks_taskFieldValues_VOLUNTEER_AddressTaskFieldValueType_address as AddressTaskFieldValue } from '../../../../common/form/task-form/model/__generated__/GetAllCompletedTasks';
import {
  AttachmentFieldValue,
  BasicFieldValue,
  CheckboxFieldValue,
  MultipleChoiceFieldValue,
  QuizFieldValue,
  SignatureFieldValue,
} from '../../../../common/form/task-form/CompletedTaskViewer';

interface SimpleCompletedTaskValueProps {
  taskFieldValue: CompletedTaskFieldValue;
}

export function SimpleCompletedTaskValue({ taskFieldValue }: SimpleCompletedTaskValueProps) {
  const getAddress = (address: AddressTaskFieldValue) => {
    if (address.placesAddress?.formatted && address.placesAddress.formatted !== '') {
      return address.placesAddress.formatted;
    }
    if (address.manualAddress?.humanReadable && address.manualAddress.humanReadable !== '') {
      return address.manualAddress.humanReadable;
    }
    return '';
  };

  if (
    taskFieldValue.__typename === 'VOLUNTEER_CheckboxTaskFieldValueType' &&
    taskFieldValue.field.__typename === 'VOLUNTEER_CheckboxTaskFieldType'
  ) {
    return (
      <>
        <CheckboxFieldValue
          options={taskFieldValue.field.checkboxOptions.map((x) => ({
            name: x.name,
            checkboxFieldOptionId: x.checkboxFieldOptionId,
            checked:
              taskFieldValue.checkboxTaskFieldOptions.findIndex(
                (y) => y.checkboxFieldOptionId === x.checkboxFieldOptionId
              ) !== -1,
          }))}
        />
      </>
    );
  }

  if (
    taskFieldValue.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldValueType' &&
    taskFieldValue.field.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldType'
  ) {
    return (
      // handle this differently so we can still display the empty options
      <>
        <MultipleChoiceFieldValue
          taskValue={taskFieldValue}
          multipleChoiceOptions={taskFieldValue.field.multipleChoiceOptions}
        />
      </>
    );
  }

  if (taskFieldValue.__typename === 'VOLUNTEER_AttachmentTaskFieldValueType') {
    return (
      <AttachmentFieldValue attachments={taskFieldValue.attachments.map((a) => ({ fileId: a.attachmentId, ...a }))} />
    );
  }

  if (taskFieldValue.__typename === 'VOLUNTEER_PhoneTaskFieldValueType') {
    return <BasicFieldValue fieldValue={taskFieldValue.contactNumber} />;
  }

  if (taskFieldValue.__typename === 'VOLUNTEER_ShortTextTaskFieldValueType') {
    return <BasicFieldValue fieldValue={taskFieldValue.textValue} />;
  }

  if (taskFieldValue.__typename === 'VOLUNTEER_DateTaskFieldValueType') {
    return <BasicFieldValue fieldValue={unpackToDate(taskFieldValue.dateValue).toFormat('dd/MM/y')} />;
  }

  if (taskFieldValue.__typename === 'VOLUNTEER_EmailTaskFieldValueType') {
    return <BasicFieldValue fieldValue={taskFieldValue.email} />;
  }
  if (taskFieldValue.__typename === 'VOLUNTEER_LongTextTaskFieldValueType') {
    return <BasicFieldValue fieldValue={taskFieldValue.textValue} />;
  }
  if (taskFieldValue.__typename === 'VOLUNTEER_NumberTaskFieldValueType') {
    return <BasicFieldValue fieldValue={`${taskFieldValue.numericValue}`} />;
  }
  if (taskFieldValue.__typename === 'VOLUNTEER_AddressTaskFieldValueType') {
    return <BasicFieldValue fieldValue={getAddress(taskFieldValue.address)} />;
  }

  if (taskFieldValue.__typename === 'VOLUNTEER_DropdownTaskFieldValueType') {
    return (
      <BasicFieldValue fieldValue={taskFieldValue.otherText ?? taskFieldValue.dropdownTaskFieldOption.name ?? ''} />
    );
  }

  if (
    taskFieldValue.field.__typename === 'VOLUNTEER_QuizTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_QuizTaskFieldValueType'
  ) {
    return (
      // handle this differently so we can still display the empty options
      <>
        <QuizFieldValue
          taskValue={taskFieldValue}
          quizTaskFieldOptions={taskFieldValue.field.quizTaskFieldOptions}
          quizTaskFieldCorrect={taskFieldValue.field.quizTaskFieldCorrect}
        />
      </>
    );
  }

  if (
    taskFieldValue.field.__typename === 'VOLUNTEER_SignatureTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_SignatureTaskFieldValueType'
  ) {
    return (
      // handle this differently so we can still display the empty options
      <>
        <SignatureFieldValue signatureUrl={taskFieldValue.signatureUrl || ''} />
      </>
    );
  }
  return (
    <Typography variant='body2' color='textSecondary'>
      No response
    </Typography>
  );
}
