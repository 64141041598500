import React, { memo, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import { Create as CreateIcon } from '@material-ui/icons';
import { RichEditorFormik } from '@campfire/campfire-rich-editor';
import { HoverText } from '@campfire/hover-link/lib';
import { ResourceEditorInitialValues } from './ResourceEditor';

const ResourceContentSection = memo(() => {
  const { values } = useFormikContext<ResourceEditorInitialValues>();
  const [showEditor, setShowEditor] = useState(!!values.editor);

  useEffect(() => {
    if (values.editor) setShowEditor(true);
  }, [values.editor]);

  return (
    <Grid item xs={12} md={8}>
      {!showEditor ? (
        <HoverText
          data-track='resources-resource-editor-add-text-button'
          color='primary'
          onClick={() => setShowEditor(true)}
        >
          {'Add Text/Write Content'}
          <CreateIcon />
        </HoverText>
      ) : (
        <div data-track='resources-resource-editor-rich-text-editor'>
          <RichEditorFormik placeholder='Add a body to this resource' />
        </div>
      )}
    </Grid>
  );
});

export { ResourceContentSection };
