import { gql } from '@apollo/client';
import { VolunteerEnrolmnentFragments } from './volunteerEnrolmentFragments.gql';
import { VolunteerPublishedRosterFragments } from './volunteerPublishedRostersFragments.gql';

export const GET_VOLUNTEER_ENROLMENTS_DATA = gql`
  query GetVolunteerEnrolmentsData($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        activityEnrolments {
          ...CommonVolunteerEnrolments
        }
        rosterings {
          ...CommonVolunteerRosters
        }
      }
    }
  }
  ${VolunteerEnrolmnentFragments}
  ${VolunteerPublishedRosterFragments}
`;
