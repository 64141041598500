import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { Email, PhoneIphone } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#D9D9D9',
      borderRadius: '3px',
      alignItems: 'center',
      padding: '6px',
      '& .icon': {
        fontSize: '15px',
        color: '#0000008A',
      },
      '& .text': {
        fontWeight: 600,
        fontSize: '10px',
        color: theme.color.grey.neutral400,
        paddingLeft: '3px',
      },
    },
  })
);

export function TemplateEmailBadget() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Email className='icon' />
      <Typography className='text'>Email</Typography>
    </Box>
  );
}

export function TemplateSMSBadget() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <PhoneIphone className='icon' />
      <Typography className='text'>SMS</Typography>
    </Box>
  );
}

interface TemplateBadgetProps {
  variant: 'sms' | 'email';
}

export function TemplateBadget({ variant }: TemplateBadgetProps) {
  switch (variant) {
    case 'email':
      return <TemplateEmailBadget />;

    default:
      return <TemplateSMSBadget />;
  }
}
