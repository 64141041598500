import { FugFile } from '@campfire/file-upload-gallery';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../../../global/network/useEndpointFetch';

const useUpdateLogo = () => {
  return useEndpointFetch<{ logo: FugFile }>(`/tm/config/logo/update`, {
    formData: true,
  });
};

const useUpdateSmsPreference = () => {
  return useEndpointFetch<{ enableSms: boolean }>(`/tm/config/enable-sms`);
};

const useUpdateGreetingSignoff = () => {
  return useEndpointFetch<{ greeting: string; signoff: string }>(`/tm/config/communication/save`);
};

export const useAdminConsolePreferencesActions = () => {
  const updateLogo = useUpdateLogo();
  const updateSmsPreference = useUpdateSmsPreference();
  const updateGreetingSignoff = useUpdateGreetingSignoff();

  const { setSnackbar } = useSnackbar();

  const runUpdateLogo = ({ logo, handleSuccess }: { logo: FugFile; handleSuccess?: () => void }) => {
    updateLogo
      .run({
        logo,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            variant: 'error',
            message: 'Unable to update preferences',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Preferences updated successfully',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Failed to update preferences',
          variant: 'error',
        })
      );
  };

  const runUpdateSmsPreference = ({ enableSms, handleSuccess }: { enableSms: boolean; handleSuccess?: () => void }) => {
    return updateSmsPreference
      .run({
        enableSms,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            variant: 'error',
            message: 'Unable to update SMS preference',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'SMS preference updated successfully',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to update SMS preference',
          variant: 'error',
        })
      );
  };

  const runUpdateGreetingSignoff = ({
    greeting,
    signoff,
    handleSuccess,
  }: {
    greeting: string;
    signoff: string;
    handleSuccess?: () => void;
  }) => {
    return updateGreetingSignoff
      .run({
        greeting,
        signoff,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            variant: 'error',
            message: 'Unable to update greeting and sign-off',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Greeting and sign-off updated successfully',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to update greeting and sign-off',
          variant: 'error',
        })
      );
  };

  return {
    runUpdateLogo,
    runUpdateLogoLoading: updateLogo.isLoading,
    runUpdateSmsPreference,
    runUpdateSmsPreferenceLoading: updateSmsPreference.isLoading,
    runUpdateGreetingSignoff,
    runUpdateGreetingSignoffLoading: updateGreetingSignoff.isLoading,
  };
};
