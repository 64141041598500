import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { DraggableListItemHandle } from '@campfire/draggable-list';
import { HoverText } from '@campfire/hover-link';
import ReactPlayer from 'react-player/lazy';

import { useFormBuilderDialogContext } from '../../FormBuilderDialogContext';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';
import { getVideoUrl } from '../../dialogs/helpers';
import { TaskFormItem_VOLUNTEER_TaskItemMediaType as TaskItemMediaType } from '../../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';

interface Props {
  data: TaskItemMediaType;
}

export const MediaTaskItemMedia = (props: Props) => {
  const { data } = props;
  const { editMedia } = useFormBuilderDialogContext();
  const { theme } = useCampfireTheme();

  return (
    <Box style={{ margin: 8, borderBottom: `2px solid ${theme.color.grey.border}` }} width='100%'>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex'>
          <DraggableListItemHandle />
          <Typography style={{ fontWeight: 'bold', marginLeft: '8px' }}>{data.media.name}</Typography>
        </Box>
        <HoverText color='primary' onClick={() => editMedia(data)}>
          {'Edit Media'}
        </HoverText>
      </Box>
      <Typography style={{ fontSize: '0.875rem', color: theme.color.grey.neutral400, marginTop: '4px' }}>
        {data.media.description}
      </Typography>
      <Box mt={2}>
        <ReactPlayer
          url={getVideoUrl(data.media.__typename)(data.media.videoId)}
          width='100%'
          maxWidth='100%'
          controls
        />
      </Box>
    </Box>
  );
};
