import { encodeTime, unpackToTime } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import {
  boolean as YupBoolean,
  number as YupNumber,
  object as YupObject,
  string as YupString,
  date as YupDate,
  DateSchema,
} from 'yup';
import { FugFile, UploadedFile } from '@campfire/file-upload-gallery';
import { assertNever } from '../../../../../../../common/functions/assert-never';
import { SaveSessionEndpointDataType, SaveSessionEndpointFieldValueType } from '../../../activity-timeline-actions';
import { ActivityTimelinePastPublishedRoster } from '../../../__generated__/ActivityTimelinePastPublishedRoster';
import {
  ActivityTimelinePastReportSession,
  ActivityTimelinePastReportSession_reportType_items_VOLUNTEER_ReportTypeFieldType_field as SessionReportFieldType,
} from '../../../__generated__/ActivityTimelinePastReportSession';
import {
  ActivityTimelinePastSessionReport,
  ActivityTimelinePastSessionReport_CICOs_volunteer as CICOVolunteerType,
} from '../../../__generated__/ActivityTimelinePastSessionReport';
import { ReportTypeField } from '../../../__generated__/ReportTypeField';

export type FormAttachmentField = {
  attachments: UploadedFile[];
  addedAttachments: FugFile[];
  addedAttachmentsBase64: string[];
  attachmentCreationTokens: string[];
  attachmentNames: string[];
  removedAttachmentIds: string[];
};

type AttachmentFieldValue = {
  __typename: 'VOLUNTEER_AttachmentFieldValue';
  fieldId: string;
  addedAttachments: string[];
  attachmentCreationTokens: string[];
  attachmentNames: string[];
  removedAttachmentIds: string[];
};

type SessionReportFormValuesFieldType =
  | { __typename: 'VOLUNTEER_BooleanFieldType'; value: string }
  | { __typename: 'VOLUNTEER_CheckboxFieldType'; value: boolean }
  | { __typename: 'VOLUNTEER_DropdownFieldType'; value: string[] }
  | { __typename: 'VOLUNTEER_NumericFieldType'; value: string }
  | { __typename: 'VOLUNTEER_RatingFieldType'; value: number | undefined }
  | { __typename: 'VOLUNTEER_TextFieldType'; value: string }
  | { __typename: 'VOLUNTEER_TimeFieldType'; value: Date | '' }
  | { __typename: 'VOLUNTEER_AttachmentFieldType'; value: FormAttachmentField };

function getInitialFieldValue(reportTypeFieldItem: ReportTypeField): SessionReportFormValuesFieldType {
  const { field } = reportTypeFieldItem;

  if (field.__typename === 'VOLUNTEER_BooleanFieldType') {
    return { __typename: 'VOLUNTEER_BooleanFieldType', value: '' };
  }

  if (field.__typename === 'VOLUNTEER_CheckboxFieldType') {
    return { __typename: 'VOLUNTEER_CheckboxFieldType', value: false };
  }

  if (field.__typename === 'VOLUNTEER_DropdownFieldType') {
    return { __typename: 'VOLUNTEER_DropdownFieldType', value: [] };
  }

  if (field.__typename === 'VOLUNTEER_NumericFieldType') {
    return { __typename: 'VOLUNTEER_NumericFieldType', value: '' };
  }

  if (field.__typename === 'VOLUNTEER_RatingFieldType') {
    return { __typename: 'VOLUNTEER_RatingFieldType', value: undefined };
  }

  if (field.__typename === 'VOLUNTEER_TextFieldType') {
    return { __typename: 'VOLUNTEER_TextFieldType', value: '' };
  }

  if (field.__typename === 'VOLUNTEER_TimeFieldType') {
    return { __typename: 'VOLUNTEER_TimeFieldType', value: '' };
  }

  if (field.__typename === 'VOLUNTEER_AttachmentFieldType') {
    return {
      __typename: 'VOLUNTEER_AttachmentFieldType',
      value: {
        attachments: [],
        addedAttachments: [],
        addedAttachmentsBase64: [],
        attachmentCreationTokens: [],
        removedAttachmentIds: [],
        attachmentNames: [],
      },
    };
  }

  return assertNever(field.__typename);
}

function getValueFromSessionReport(
  reportTypeFieldItem: ReportTypeField,
  sessionReport: ActivityTimelinePastSessionReport
): SessionReportFormValuesFieldType {
  const { field } = reportTypeFieldItem;
  const fieldValueFromExistingSessionReport = sessionReport
    ? sessionReport.fieldValues.find((x) => x.field.fieldId === field.fieldId)
    : undefined;

  if (!fieldValueFromExistingSessionReport) return getInitialFieldValue(reportTypeFieldItem);

  if (
    field.__typename === 'VOLUNTEER_BooleanFieldType' &&
    fieldValueFromExistingSessionReport.__typename === 'VOLUNTEER_BooleanFieldValueType'
  ) {
    return {
      __typename: 'VOLUNTEER_BooleanFieldType',
      value: fieldValueFromExistingSessionReport.boolValue ? 'true' : 'false',
    };
  }

  if (
    field.__typename === 'VOLUNTEER_CheckboxFieldType' &&
    fieldValueFromExistingSessionReport.__typename === 'VOLUNTEER_CheckboxFieldValueType'
  ) {
    return { __typename: 'VOLUNTEER_CheckboxFieldType', value: fieldValueFromExistingSessionReport.checked };
  }

  if (
    field.__typename === 'VOLUNTEER_DropdownFieldType' &&
    fieldValueFromExistingSessionReport.__typename === 'VOLUNTEER_DropdownFieldValueType'
  ) {
    return {
      __typename: 'VOLUNTEER_DropdownFieldType',
      value: fieldValueFromExistingSessionReport.dropdownFieldOptions.map(
        (dropdownFieldOption) => dropdownFieldOption.dropdownFieldOptionId
      ),
    };
  }

  if (
    field.__typename === 'VOLUNTEER_NumericFieldType' &&
    fieldValueFromExistingSessionReport.__typename === 'VOLUNTEER_NumericFieldValueType'
  ) {
    return {
      __typename: 'VOLUNTEER_NumericFieldType',
      value: fieldValueFromExistingSessionReport.numericValue.toString(),
    };
  }

  if (
    field.__typename === 'VOLUNTEER_RatingFieldType' &&
    fieldValueFromExistingSessionReport.__typename === 'VOLUNTEER_RatingFieldValueType'
  ) {
    return {
      __typename: 'VOLUNTEER_RatingFieldType',
      value: fieldValueFromExistingSessionReport.ratingValue,
    };
  }

  if (
    field.__typename === 'VOLUNTEER_TextFieldType' &&
    fieldValueFromExistingSessionReport.__typename === 'VOLUNTEER_TextFieldValueType'
  ) {
    return { __typename: 'VOLUNTEER_TextFieldType', value: fieldValueFromExistingSessionReport.textValue };
  }

  if (
    field.__typename === 'VOLUNTEER_TimeFieldType' &&
    fieldValueFromExistingSessionReport.__typename === 'VOLUNTEER_TimeFieldValueType'
  ) {
    return {
      __typename: 'VOLUNTEER_TimeFieldType',
      value: unpackToTime(fieldValueFromExistingSessionReport.timeValue).toJSDate(),
    };
  }

  if (
    field.__typename === 'VOLUNTEER_AttachmentFieldType' &&
    fieldValueFromExistingSessionReport.__typename === 'VOLUNTEER_AttachmentFieldValueType'
  ) {
    const uploadedFiles: UploadedFile[] = fieldValueFromExistingSessionReport.attachments.map((attachment) => {
      return { url: attachment.url, fileId: attachment.attachmentId, name: attachment.name };
    });
    const addedAttachments: FugFile[] = [];
    const addedAttachmentsBase64: string[] = [];
    const attachmentCreationTokens: string[] = [];
    const attachmentNames: string[] = [];
    const removedAttachmentIds: string[] = [];

    return {
      __typename: 'VOLUNTEER_AttachmentFieldType',
      value: {
        attachments: uploadedFiles,
        addedAttachments,
        addedAttachmentsBase64,
        attachmentCreationTokens,
        attachmentNames,
        removedAttachmentIds,
      },
    };
  }

  throw new Error(`Unhandled field.__typename when initialising form values: ${field.__typename}`);
}

const getFieldValuesFromFormValues = (
  values: SessionReportFormValuesType
): Array<SaveSessionEndpointFieldValueType> => {
  const fieldValues: Array<SaveSessionEndpointFieldValueType> = [];

  Object.keys(values.fields).forEach((fieldIdKey) => {
    const fieldData = values.fields[fieldIdKey];

    if (fieldData.__typename === 'VOLUNTEER_BooleanFieldType') {
      fieldValues.push({
        __typename: 'VOLUNTEER_BooleanFieldValue',
        fieldId: fieldIdKey,
        boolValue: fieldData.value === 'true' ?? false,
      });
      return;
    }

    if (fieldData.__typename === 'VOLUNTEER_CheckboxFieldType') {
      fieldValues.push({
        __typename: 'VOLUNTEER_CheckboxFieldValue',
        fieldId: fieldIdKey,
        checked: fieldData.value,
      });
      return;
    }

    if (fieldData.__typename === 'VOLUNTEER_DropdownFieldType') {
      if (fieldData.value)
        fieldValues.push({
          __typename: 'VOLUNTEER_DropdownFieldValue',
          fieldId: fieldIdKey,
          dropdownFieldOptionIds: typeof fieldData.value === 'string' ? [fieldData.value] : fieldData.value,
        });
      return;
    }

    if (fieldData.__typename === 'VOLUNTEER_NumericFieldType') {
      if (fieldData.value !== undefined && fieldData.value !== '')
        fieldValues.push({
          __typename: 'VOLUNTEER_NumericFieldValue',
          fieldId: fieldIdKey,
          numericValue: Number.parseFloat(fieldData.value),
        });
      return;
    }

    if (fieldData.__typename === 'VOLUNTEER_RatingFieldType') {
      if (fieldData.value && fieldData.value >= 1 && fieldData.value <= 5) {
        fieldValues.push({
          __typename: 'VOLUNTEER_RatingFieldValue',
          fieldId: fieldIdKey,
          ratingValue: parseInt(`${fieldData.value}`, 10),
        });
      }
      return;
    }

    if (fieldData.__typename === 'VOLUNTEER_TextFieldType') {
      if (fieldData.value)
        fieldValues.push({
          __typename: 'VOLUNTEER_TextFieldValue',
          fieldId: fieldIdKey,
          textValue: fieldData.value,
        });
      return;
    }

    if (fieldData.__typename === 'VOLUNTEER_TimeFieldType') {
      if (fieldData.value)
        fieldValues.push({
          __typename: 'VOLUNTEER_TimeFieldValue',
          fieldId: fieldIdKey,
          timeValue: encodeTime(DateTime.fromJSDate(fieldData.value)),
        });
      return;
    }

    if (fieldData.__typename === 'VOLUNTEER_AttachmentFieldType') {
      if (fieldData.value) {
        const attachmentFieldValue = getAttachmentValueAsync(fieldIdKey, fieldData.value);
        fieldValues.push(attachmentFieldValue);
        return;
      }
    }

    assertNever(fieldData);
  });

  return fieldValues;
};

function getAttachmentValueAsync(fieldId: string, value: FormAttachmentField): AttachmentFieldValue {
  const addedAttachments = value.addedAttachmentsBase64;
  const attachmentCreationTokens = addedAttachments.map(() => uuidv4());
  const attachmentNames = value.addedAttachments.map((fugFile) => fugFile.name);
  const { removedAttachmentIds } = value;

  return {
    __typename: 'VOLUNTEER_AttachmentFieldValue',
    fieldId,
    addedAttachments,
    attachmentCreationTokens,
    attachmentNames,
    removedAttachmentIds,
  };
}

export async function getBase64FromAttachment(file: FugFile): Promise<string> {
  const base64File = await toBase64(file);
  if (base64File) {
    return base64File.toString().substr(base64File.toString().indexOf(',') + 1);
  }
  return '';
}

function toBase64(file: FugFile): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

type SessionReportParticipant =
  | {
      type: 'volunteer';
      profile: {
        preferredName: string;
        lastName: string;
        avatarUrl: string;
      };
      volunteerId: string;
      attendanceValue: boolean;
    }
  | {
      type: 'other';
      name: string;
      attendanceValue: boolean;
    };

export type CICOType = {
  checkIn: Date | '';
  checkOut: Date | '';
  volunteer: CICOVolunteerType;
  isCICO: boolean;
};
export interface SessionReportFormValuesType {
  startTime: Date | '';
  endTime: Date | '';
  journal: string;
  attendances: Array<SessionReportParticipant>;
  CICOs: CICOType[];
  fields: {
    [key: string]: SessionReportFormValuesFieldType;
  };
}

const validators = {
  VOLUNTEER_BooleanFieldType: YupString(),
  VOLUNTEER_CheckboxFieldType: YupBoolean(),
  VOLUNTEER_DropdownFieldType: YupString(),
  VOLUNTEER_NumericFieldType: YupString(),
  VOLUNTEER_RatingFieldType: YupNumber()
    .min(1)
    .max(5),
  VOLUNTEER_TextFieldType: YupString(),
  VOLUNTEER_TimeFieldType: YupString(),
  VOLUNTEER_AttachmentFieldType: YupObject(),
};

function fieldTypeValidator(item: SessionReportFieldType) {
  return validators[item.__typename];
}

export function getValidationSchema(
  session: ActivityTimelinePastReportSession,
  sessionReport?: ActivityTimelinePastSessionReport
) {
  const reportTypeFieldItems =
    session.reportType?.items.filter((item) => item.__typename === 'VOLUNTEER_ReportTypeFieldType') ||
    ([] as Array<ReportTypeField>);
  const schema = reportTypeFieldItems.reduce((obj, item) => {
    if (item.__typename !== 'VOLUNTEER_ReportTypeFieldType') {
      return obj;
    }

    return Object.assign(obj, {
      [item.field.fieldId]: YupObject().shape({
        value: item.optional
          ? fieldTypeValidator(item.field)
          : fieldTypeValidator(item.field).required('This is a required field'),
      }),
    });
  }, {});

  return sessionReport && sessionReport.allowCICO
    ? YupObject().shape({
        fields: YupObject().shape(schema),
      })
    : YupObject().shape({
        fields: YupObject().shape(schema),
        startTime: YupDate()
          .required('required')
          .typeError('wrong-format'),
        endTime: YupDate()
          .when('startTime', (startTime: Date | undefined, timeSchema: DateSchema) => {
            if (!startTime || !DateTime.fromJSDate(startTime).isValid) {
              return timeSchema;
            }
            return timeSchema.min(startTime, 'incompatible');
          })
          .required('required')
          .typeError('wrong-format'),
      });
}

function getInitialSessionReportFormValues(
  session: ActivityTimelinePastReportSession,
  sessionReport?: ActivityTimelinePastSessionReport,
  publishedRoster?: ActivityTimelinePastPublishedRoster
): SessionReportFormValuesType {
  const reportTypeFieldItems =
    session.reportType?.items.filter((item) => item.__typename === 'VOLUNTEER_ReportTypeFieldType') ||
    ([] as Array<ReportTypeField>);
  const fieldMap = reportTypeFieldItems.reduce<{ [key: string]: SessionReportFormValuesFieldType }>((prev, item) => {
    if (item.__typename !== 'VOLUNTEER_ReportTypeFieldType') {
      return prev;
    }
    return {
      ...prev,
      [item.field.fieldId]: sessionReport ? getValueFromSessionReport(item, sessionReport) : getInitialFieldValue(item),
    };
  }, {});

  const CICOs =
    (sessionReport &&
      sessionReport.CICOs.map((CICO) => {
        return {
          checkIn: CICO.checkIn ? unpackToTime(CICO.checkIn).toJSDate() : ('' as Date | ''),
          checkOut: CICO.checkOut ? unpackToTime(CICO.checkOut).toJSDate() : ('' as Date | ''),
          volunteer: CICO.volunteer,
          isCICO: true,
        };
      })) ||
    [];

  const volunteers = publishedRoster?.rosterings
    .map((item) => item.volunteer)
    .filter((v) => !CICOs.some((c) => c.volunteer.volunteerId === v.volunteerId));

  const attendances =
    (volunteers &&
      sessionReport &&
      volunteers.map((vol) => {
        return {
          checkIn: '' as Date | '',
          checkOut: '' as Date | '',
          volunteer: vol,
          isCICO: false,
        };
      })) ||
    [];

  return {
    startTime: sessionReport ? unpackToTime(sessionReport.startTime).toJSDate() : '',
    endTime: sessionReport ? unpackToTime(sessionReport.endTime).toJSDate() : '',
    journal: sessionReport?.comments ?? '',
    attendances: getInitialAttendances(session, sessionReport, publishedRoster),
    fields: fieldMap,
    CICOs: sessionReport?.allowCICO
      ? [...CICOs, ...attendances].sort((a: any, b: any) => {
          if (a.isCICO && !b.isCICO) return -1;
          if (!a.isCICO && b.isCICO) return 1;
          return 0;
        })
      : [],
  };
}

function getInitialAttendances(
  session: ActivityTimelinePastReportSession,
  sessionReport?: ActivityTimelinePastSessionReport,
  publishedRoster?: ActivityTimelinePastPublishedRoster
): Array<SessionReportParticipant> {
  const rosteringsThatIncludeSessionRosteringForThisSession = publishedRoster?.rosterings?.filter(
    (rostering) =>
      !!rostering.sessionRosterings.find((sessionRostering) => sessionRostering.session.sessionId === session.sessionId)
  );

  const participantsFromSessionReport: Array<SessionReportParticipant> =
    sessionReport?.attendances.map((attendance) => {
      return attendance.__typename === 'VOLUNTEER_VolunteerAttendanceType'
        ? {
            type: 'volunteer',
            volunteerId: attendance.volunteer.volunteerId,
            profile: {
              preferredName: attendance.volunteer.profile.preferredName,
              lastName: attendance.volunteer.profile.lastName,
              avatarUrl: attendance.volunteer.profile.avatarUrl ?? '',
            },
            attendanceValue: true,
          }
        : {
            type: 'other',
            name: attendance.name,
            attendanceValue: true,
          };
    }) ?? [];

  const participantsFromRoster =
    rosteringsThatIncludeSessionRosteringForThisSession?.reduce<Array<SessionReportParticipant>>((prev, rostering) => {
      const existingAttendance = participantsFromSessionReport.find(
        (x) => x.type === 'volunteer' && x.volunteerId === rostering.volunteer.volunteerId
      );
      if (existingAttendance) {
        return prev;
      }
      return [
        ...prev,
        {
          type: 'volunteer',
          volunteerId: rostering.volunteer.volunteerId,
          profile: {
            preferredName: rostering.volunteer.profile.preferredName,
            lastName: rostering.volunteer.profile.lastName,
            avatarUrl: rostering.volunteer.profile.avatarUrl ?? '',
          },
          attendanceValue: false,
        },
      ];
    }, []) ?? [];

  return [...participantsFromRoster, ...participantsFromSessionReport].sort((a, b) => {
    const aName = a.type === 'volunteer' ? a.profile.preferredName : a.name;
    const bName = b.type === 'volunteer' ? b.profile.preferredName : b.name;
    return aName.localeCompare(bName);
  });
}

function getEndpointParsedSessionReport(
  session: ActivityTimelinePastReportSession,
  activityDate: string,
  values: SessionReportFormValuesType
): SaveSessionEndpointDataType {
  const fieldValues = getFieldValuesFromFormValues(values);
  return {
    sessionId: session.sessionId,
    activityDate: activityDate,
    comments: values.journal,
    startTime: values.startTime ? encodeTime(DateTime.fromJSDate(values.startTime)) : '',
    endTime: values.endTime ? encodeTime(DateTime.fromJSDate(values.endTime)) : '',
    volunteerAttendances: values.attendances.reduce<Array<string>>(
      (prev, attendance) =>
        attendance.type === 'volunteer' && attendance.attendanceValue ? [...prev, attendance.volunteerId] : prev,
      []
    ),
    otherAttendances: values.attendances.reduce<Array<string>>(
      (prev, attendance) =>
        attendance.type === 'other' && attendance.attendanceValue ? [...prev, attendance.name] : prev,
      []
    ),
    CICOs: values.CICOs.filter((item) => item.isCICO).map(({ checkIn, checkOut, volunteer }) => ({
      checkIn: checkIn ? encodeTime(DateTime.fromJSDate(checkIn)) : encodeTime(DateTime.local()),
      checkOut: checkOut ? encodeTime(DateTime.fromJSDate(checkOut)) : null,
      volunteerId: volunteer.volunteerId,
    })),
    fieldValues: fieldValues,
  };
}

export { getInitialSessionReportFormValues, getEndpointParsedSessionReport };
