import React from 'react';
import { ButtonBase, Grid, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button/lib';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

interface Props {
  taskName: string;
  taskId?: string;
  isTaskZero: boolean;
}

export const FormBuilderHeader = (props: Props) => {
  const {
    taskName,
    // taskId,
    // isTaskZero
  } = props;
  const { submitForm, resetForm, initialValues, values } = useFormikContext();
  const history = useHistory();

  const { theme, isMobile } = useCampfireTheme();

  // const handleDuplicate = () =>
  //   history.push({
  //     pathname: `${match?.path}/task-builder`,
  //     search: `?tid=${taskId}&duplicate=1`,
  //   });

  return (
    <Grid container alignItems='center' spacing={isMobile ? 1 : 2} style={{ padding: 8 }}>
      <Grid item xs>
        <ButtonBase disableRipple onClick={() => history.goBack()}>
          <Grid container style={{ paddingLeft: isMobile ? 0 : 16 }}>
            <ChevronLeft style={{ color: theme.palette.text.secondary }} />
            <Typography color='textSecondary' noWrap style={{ maxWidth: isMobile ? '160px' : 'auto' }}>
              {taskName}
            </Typography>
          </Grid>
        </ButtonBase>
      </Grid>
      <Grid item>
        <TabletButton onClick={() => resetForm()} disabled={initialValues === values}>
          {isMobile ? 'Discard' : 'Discard changes'}
        </TabletButton>
      </Grid>
      <Grid item>
        <TabletButton color='primary' variant='contained' onClick={() => submitForm()}>
          {isMobile ? 'Save' : 'Save changes'}
        </TabletButton>
      </Grid>
      {/* {!isTaskZero ? (
        <Grid item>
          <TabletButton variant='outlined' onClick={handleDuplicate} disabled={!taskId}>
            {'Duplicate'}
          </TabletButton>
        </Grid>
      ) : null} */}
    </Grid>
  );
};
