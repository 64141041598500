import { Select } from '@campfire/select';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { object as YupObject, string as YupString, ref as YupRef } from 'yup';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../../../../common/cards/alert-card/AlertCard';
import { arrayHead } from '../../../../../../../common/functions/array-head';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import {
  fieldTypes,
  MappedFieldType,
} from '../../../../common/report-type-form-sections/report-type-form-items/edit-report-field-types';
import { FieldOption, MergeReportFieldValues, ReportFieldType } from '../../../report-field-types';
import { useReportFieldDetailHeaderActions } from '../report-field-detail-header-actions';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  sourceReportFieldId: string;
  sourceReportFieldType: ReportFieldType;
  allActiveFieldOptions: Array<FieldOption>;
  refetch: () => void;
};

const validationSchema = YupObject().shape({
  sourceFieldId: YupString().required('Please select a field'),
  sourceFieldType: YupString(),
  targetFieldId: YupString().required('Please select a field'),
  targetFieldType: YupString().oneOf([YupRef('sourceFieldType'), null], 'Does not match the source type'),
});

const TargetTypeSelectField = () => {
  const { values, setFieldTouched } = useFormikContext<MergeReportFieldValues>();

  useEffect(() => {
    if (values.targetFieldType) setFieldTouched('targetFieldType', true);
  }, [values.targetFieldType]);

  return (
    <Select
      fullWidth
      variant='outlined'
      name='targetFieldType'
      label='Target Field Type'
      items={fieldTypes}
      renderFunction={(item: MappedFieldType) => item.name}
      valueFunction={(item: MappedFieldType) => item.type}
      InputProps={{
        disabled: true,
      }}
    />
  );
};

export const MergeReportFieldsDialog = (props: Props) => {
  const { isOpen, handleClose, sourceReportFieldId, sourceReportFieldType, allActiveFieldOptions, refetch } = props;
  const { runMergeReportFields, runMergeReportFieldsLoading } = useReportFieldDetailHeaderActions();
  const [query, setQuery] = useQueryParams({
    reportFieldId: StringParam,
  });

  const { theme } = useCampfireTheme();

  const filteredFieldOptions = allActiveFieldOptions.filter((field) => field.__typename === sourceReportFieldType);
  const eligibleInitialTargetFields = filteredFieldOptions.filter((field) => field.fieldId !== sourceReportFieldId);

  const initialValues = useMemo((): MergeReportFieldValues => {
    const targetField = arrayHead(eligibleInitialTargetFields);
    return {
      sourceFieldId: sourceReportFieldId,
      sourceFieldType: sourceReportFieldType,
      targetFieldId: targetField?.fieldId || '',
      targetFieldType: targetField?.__typename,
    };
  }, [sourceReportFieldId, filteredFieldOptions]);
  const { lightTextColor, darkTextColor } = getAlertCardColors('warning');
  const { lightTextColor: infoLightTextColor } = getAlertCardColors('info');

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='merge-report-field-dialog-title'
      aria-describedby='merge-report-field-dialog-description'
      PaperProps={{
        style: { padding: '10px' },
      }}
    >
      <DialogTitle id='merge-report-field-dialog-title'>Merge Report Fields?</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          runMergeReportFields({
            ...values,
            handleSuccess: () => {
              handleClose();
              setQuery({ ...query, reportFieldId: values.targetFieldId });
              if (refetch) refetch();
            },
          });
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <DialogContent style={{ paddingBottom: '20px' }}>
              {filteredFieldOptions.length === 1 ? (
                <AlertCard title='Merging Fields' variant='info'>
                  <AlertCardBody>
                    <Typography
                      variant='subtitle2'
                      style={{ fontSize: '14px', fontWeight: 400, color: infoLightTextColor }}
                    >
                      {`You need two fields of the same type in order to merge them together`}
                    </Typography>
                  </AlertCardBody>
                </AlertCard>
              ) : (
                <>
                  <Box mb='24px'>
                    <AlertCard title='Merging Fields' variant='warning'>
                      <AlertCardBody>
                        <Typography
                          variant='subtitle2'
                          style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
                        >
                          {`Merging two fields will result in all current report types, and historical activity reports being updated to reflect the target field. Your 'source' field will be archived after the merge is complete. `}
                          <br />
                          Only fields of the same type can be merged.
                          <br />
                          <br />
                          <span style={{ fontWeight: 'bold', color: darkTextColor }}>
                            Warning: This can be a destructive operation and is not reversible.
                          </span>
                        </Typography>
                      </AlertCardBody>
                    </AlertCard>
                  </Box>
                  <Grid container direction='column' spacing={2} style={{ paddingTop: '1em' }}>
                    <Grid item container spacing={2} style={{ justifyContent: 'center' }}>
                      <Grid item container spacing={2} direction='column' xs={12} sm={5}>
                        <Grid item>
                          <Select
                            fullWidth
                            variant='outlined'
                            name='sourceFieldId'
                            label='Source Field'
                            items={filteredFieldOptions}
                            renderFunction={(item) => item.fieldName}
                            valueFunction={(item) => item.fieldId}
                            InputProps={{
                              disabled: !!values.sourceFieldId,
                            }}
                            SelectProps={{ IconComponent: () => null }}
                          />
                        </Grid>
                        <Grid item>
                          <Select
                            fullWidth
                            variant='outlined'
                            name='sourceFieldType'
                            label='Source Field Type'
                            items={fieldTypes}
                            renderFunction={(item: MappedFieldType) => item.name}
                            valueFunction={(item: MappedFieldType) => item.type}
                            InputProps={{
                              disabled: !!values.sourceFieldId,
                            }}
                            SelectProps={{ IconComponent: () => null }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        spacing={2}
                        direction='column'
                        xs={12}
                        sm={2}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: theme.color.grey.neutralBrand300,
                        }}
                      >
                        <ArrowForward />
                        <Typography style={{ fontSize: '14px', fontWeight: 550, fontVariant: 'small-caps' }}>
                          merge into
                        </Typography>
                      </Grid>
                      <Grid item container spacing={2} direction='column' xs={12} sm={5}>
                        <Grid item>
                          <Select
                            fullWidth
                            required
                            variant='outlined'
                            name='targetFieldId'
                            label='Target Field'
                            items={filteredFieldOptions.filter((field) => field.fieldId !== values.sourceFieldId)}
                            renderFunction={(item) => item.fieldName}
                            valueFunction={(item) => item.fieldId}
                            onChange={(e) => {
                              setFieldValue('targetFieldId', e.target.value);
                              setFieldValue(
                                'targetFieldType',
                                filteredFieldOptions.find((option) => option.fieldId === e.target.value)?.__typename
                              );
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <TargetTypeSelectField />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Box display='flex' justifyContent='right' padding={'10px'}>
                <TabletButton
                  style={{
                    alignSelf: 'right',
                    backgroundColor: 'transparent',
                    color: theme.color.grey.neutral400,
                    padding: '7px 18px',
                    minWidth: '40px',
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </TabletButton>
                <TabletButton
                  type='submit'
                  disabled={runMergeReportFieldsLoading || filteredFieldOptions.length === 1}
                  color='primary'
                  style={{
                    alignSelf: 'right',
                    backgroundColor: theme.color.error[900],
                    display: filteredFieldOptions.length === 1 ? 'none' : 'auto',
                    color: '#ffffff',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    textTransform: 'none',
                    padding: '7px 12px',
                    minWidth: '40px',
                  }}
                >
                  Merge
                </TabletButton>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
