import { useSnackbar } from '../global/config/useSnackbar';

export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }
  throw new Error('Failed to copy');
}

export const useCopyLinkAction = () => {
  const { setSnackbar } = useSnackbar();

  const handleCopyLinkClick = () => {
    copyTextToClipboard(window.location.href)
      .then(() => {
        setSnackbar({
          open: true,
          message: 'Copied to Clipboard',
          variant: 'success',
        });
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Failed to copy',
          variant: 'error',
        });
      });
  };

  return {
    handleCopyLinkClick,
  };
};
