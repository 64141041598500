import { encodeDate, formatDistance, unpackToDate, unpackToDateTime } from '@campfire/hot-date';
import { Pane, PaneHeader, PaneScrollable, PaneWrapper } from '@campfire/pane';
import {
  Box,
  Grid,
  FormControl,
  ListItemText,
  Checkbox as MUICheckbox,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Settings } from '@material-ui/icons/';
import { fade } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableColumnOptions,
  MUIDataTableState,
  TableBody,
} from 'mui-datatables';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { volunteerProfileHardCodedFields } from '../../../../common/form/task-form/model/get-volunteer-profile-hardcoded-field-values';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import {
  getVolunteerDatabaseRosterStatus,
  isStatusDeactivated,
  isStatusFlaggedAndExited,
  VolunteerDatabaseActivityRole,
  VolunteerDatabaseState,
} from './volunteer-database-model.gql';
import { VolunteerDatabaseTableOptionsMenu } from './VolunteerDatabaseTableOptionsMenu';
import { VolunteerDatabaseBaseVolunteer_profile_completedTasks_taskFieldValues as TaskFieldValue } from './__generated__/VolunteerDatabaseBaseVolunteer';
import { VolunteerDatabaseTasks } from './__generated__/VolunteerDatabaseTasks';
import {
  GetVolunteerDatabase_vm_programs as VolunteerDbPrograms,
  GetVolunteerDatabaseVariables,
} from './__generated__/GetVolunteerDatabase';
import { SearchField } from '../../../../common/inputs/SearchField';
import { CampfireButton } from '../../../../global/components';
import { useUser } from '../../../../global/auth/useUser';
import { VolunteerDatabaseSettingDialog } from './VolunteerDatabaseSettingDialog';
import { useRequestExport } from '../../../../common/export/Export';

interface Props {
  setSelectedVolunteerId: (x: string) => void;
  volunteerDatabaseState: VolunteerDatabaseState | undefined;
  volunteerProfileTasks?: VolunteerDatabaseTasks[];
  activityRoles: Array<{ activityRoleId: string; name: string }>;
  activities: Array<{ activityId: string; name: string }>;
  programs: VolunteerDbPrograms[];
  managedProgramIds: string[];
  databaseTaskFields: string[];
  reloadDatabase?: () => void;
  refetchUserProfile?: () => void;
  pageRef: React.RefObject<HTMLDivElement>;
  handlePageChange: (pageNumber: number) => void;
  paginationState: {
    currentPage: number;
    totalVolunteers: number;
    numberOfLoadedPages: number;
  };
  updateRequestVariables: (x: GetVolunteerDatabaseVariables) => void;
  requestVariables: GetVolunteerDatabaseVariables;
  onRequestDownload: () => void;
  isLoading: boolean;
}

type DesktopDbTableProps = RouteComponentProps & Props;

enum filter {
  Checkbox = 'checkbox',
  Multiselect = 'multiselect',
  Dropdown = 'dropdown',
  TextField = 'textField',
  Custom = 'custom',
}

enum display {
  True = 'true',
  False = 'false',
}

enum selectableRows {
  None = 'none',
}

function areEqual(prevProps: DesktopDbTableProps, nextProps: DesktopDbTableProps) {
  return (
    prevProps.volunteerDatabaseState === nextProps.volunteerDatabaseState &&
    prevProps.databaseTaskFields === nextProps.databaseTaskFields &&
    prevProps.volunteerProfileTasks === nextProps.volunteerProfileTasks
  );
}

const dateFilterOptions = ['< 1 Day', '< 1 Week', '< 1 Month', '< 1 Year'];

export const VOLUNTEER_PROFILE_TASK_ORDER = -1;

const columnsBase: MUIDataTableColumn[] = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
      filterType: filter.TextField,
      filterOptions: {
        // return false to prevent filtering on front-end
        logic: () => false,
      },
      customFilterListOptions: {
        update: (filterList: any) => filterList,
      },
    },
  },
  {
    name: 'firstName',
    label: 'First Name',
    options: {
      filter: false,
      sort: true,
      display: display.False,
    },
  },
  {
    name: 'preferredName',
    label: 'Preferred Name',
    options: {
      filter: false,
      sort: true,
      display: display.False,
    },
  },
  {
    name: 'lastName',
    label: 'Last Name',
    options: {
      filter: false,
      sort: true,
      display: display.False,
    },
  },
  {
    name: 'dateOfBirth',
    label: 'Date of birth',
    options: {
      filter: false,
      sort: true,
      display: display.False,
    },
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      filter: true,
      sort: true,
      filterType: filter.TextField,
      filterOptions: {
        // return false to prevent filtering on front-end
        logic: () => false,
      },
    },
  },
  {
    name: 'contactNumber',
    label: 'Phone',
    options: {
      filter: true,
      sort: false,
      filterType: filter.TextField,
      filterOptions: {
        // return false to prevent filtering on front-end
        logic: () => false,
      },
    },
  },
  {
    name: 'privLevel',
    label: 'Privilege Level',
    options: {
      filter: true,
      sort: false,
      filterType: filter.Multiselect,
      filterOptions: {
        // return false to prevent filtering on front-end
        logic: () => false,
        names: ['Admin', 'Program Manager', 'Activity Leader', 'Volunteer', 'Deactivated'],
      },
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
      filterType: filter.Checkbox,
      filterOptions: {
        names: ['Deactivated', 'Never rostered', 'Last Rostered', 'Never joined activity'],
        // return false to prevent filtering on front-end
        logic: () => false,
      },
    },
  },
  {
    name: 'date',
    label: 'Created',
    options: {
      filter: true,
      sort: true,
      filterType: filter.Checkbox,
      filterOptions: {
        names: dateFilterOptions,
        // return false to prevent filtering on front-end
        logic: () => false,
      },
      customFilterListOptions: {
        render: (value: string[]) => {
          return value.length > 1 ? `Date added: ${value}` : null;
        },
      },
      customBodyRender: (value: string) => <>{formatDistance(unpackToDateTime(value))}</>,
    },
  },
  {
    name: 'comments',
    label: 'Comments',
    options: {
      filter: true,
      sort: false,
      filterType: filter.TextField,
      filterOptions: {
        // return false to prevent filtering on front-end
        logic: () => false,
      },
      customFilterListOptions: {
        render: (value: string) => `Comments: ${value}`,
      },
      customBodyRender: (value?: string) => {
        return value ? (
          <Box maxWidth={200} maxHeight={64} style={{ overflow: 'hidden' }}>
            {value}
          </Box>
        ) : null;
      },
    },
  },
];

const impactDateFilterOptions = [...dateFilterOptions, 'N/A'];

const impactColumns: Array<{ name: string; label: string; options: MUIDataTableColumnOptions }> = [
  {
    name: 'lastLogin',
    label: 'Last Active',
    options: {
      filter: true,
      sort: true,
      filterType: filter.Checkbox,
      filterOptions: {
        names: impactDateFilterOptions,
        logic(date: string, filterVal: any[]) {
          const convertedDate = unpackToDateTime(date);
          const diffNow = convertedDate.diffNow();
          switch (filterVal[0]) {
            case '< 1 Day':
              if (!diffNow.as('days')) return true;
              return diffNow.as('days') <= -1;
            case '< 1 Week':
              if (!diffNow.as('weeks')) return true;
              return diffNow.as('weeks') <= -1;
            case '< 1 Month':
              if (!diffNow.as('months')) return true;
              return diffNow.as('months') <= -1;
            case '< 1 Year':
              if (!diffNow.as('years')) return true;
              return diffNow.as('years') <= -1;
            case 'N/A':
              return date !== 'N/A';
            default:
              return false;
          }
        },
      },
      customFilterListOptions: {
        render: (value: string[]) => {
          return value.length > 1 ? `Last Active: ${value}` : null;
        },
      },
    },
  },
  {
    name: 'activities',
    label: 'No. of Enrolled Activities',
    options: {
      filter: true,
      sort: true,
      filterType: filter.TextField,
    },
  },
  {
    name: 'volunteerHours',
    label: 'Volunteer Hours',
    options: {
      filter: true,
      sort: true,
      filterType: filter.TextField,
    },
  },
  {
    name: 'attendances',
    label: 'Total Attendances',
    options: {
      filter: true,
      sort: true,
      filterType: filter.TextField,
    },
  },
  {
    name: 'attended',
    label: 'Last attended',
    options: {
      filter: true,
      sort: true,
      filterType: filter.TextField,
    },
  },
];

const CustomSearchField = (props: { handleSearchEnter: Function }) => {
  const { handleSearchEnter } = props;
  const [searchTextKey, setSearchTextKey] = useState<string | null>(null);
  return (
    <Grid container direction={'row'} spacing={1}>
      <Grid item xs>
        <SearchField
          placeholder='Search by name, email or phone number'
          onChange={(e) => setSearchTextKey(e.target.value === '' ? null : e.target.value)}
          handleEnterPress={() => handleSearchEnter({ keywordsFilter: searchTextKey })}
          fullWidth
          growLeft
        />
      </Grid>
      <Grid item>
        <CampfireButton variant={'text'} onClick={() => handleSearchEnter({ keywordsFilter: searchTextKey })}>
          {'Search'}
        </CampfireButton>
      </Grid>
    </Grid>
  );
};

const VolunteerDatabaseDesktopTable = withRouter(
  React.memo((props: DesktopDbTableProps) => {
    const {
      setSelectedVolunteerId,
      volunteerDatabaseState,
      activityRoles,
      programs,
      activities,
      volunteerProfileTasks,
      handlePageChange,
      paginationState,
      updateRequestVariables,
      requestVariables,
      managedProgramIds,
      databaseTaskFields,
      refetchUserProfile,
      isLoading,
    } = props;
    const { theme } = useCampfireTheme();
    const [filterList, setFilterList] = useState<string[][]>([]);
    const [searchText, setSearchText] = useState<string>(' ');
    const [tasksFilter, setTasksFilter] = useState<{ [key: string]: boolean }>({});
    const [activeVolsFilter, setActiveVolsFilter] = useState(requestVariables.activeVolsFilter ?? true);
    const [impactFilter, setImpactFilter] = useState<{ [key: string]: boolean }>({
      lastLogin: false,
      activities: false,
      volunteerHours: false,
      attendances: false,
      attended: false,
    });
    const [openSettingDialog, setOpenSettingDialog] = useState(false);
    const {
      user: { userIdentityService },
    } = useUser();
    const isAdmin = userIdentityService.isVmAdmin;

    const managedPrograms = useMemo(() => {
      return isAdmin ? programs : programs.filter((p) => managedProgramIds.find((x) => x === p.programId));
    }, [programs]).sort((a, b) => a.name.localeCompare(b.name));

    const managedActivities = useMemo(() => {
      return (
        managedPrograms.flatMap((x) => x.activities.map((a) => ({ name: a.name, activityId: a.activityId }))) ?? []
      );
    }, [managedPrograms]).sort((a, b) => a.name.localeCompare(b.name));

    const columns = useMemo(
      () => [
        ...columnsBase,
        {
          name: 'roles',
          label: 'Role(s)',
          options: {
            filter: true,
            sort: false,
            filterType: filter.Multiselect,
            filterOptions: {
              // return false to prevent filtering on front-end
              logic: () => false,
              names: activityRoles.map((r) => r.activityRoleId),
              renderValue: (v: string) => activityRoles.find((r) => r.activityRoleId === v)?.name ?? '',
            },
            customBodyRender: (value: Array<string>) => {
              if (!value) return [];
              const roles = value.map((v) => activityRoles.find((r) => r.activityRoleId === v)?.name ?? '');
              roles.sort((a, b) => {
                return a < b ? -1 : 1;
              });
              return <>{roles.join(', ')}</>;
            },
            customFilterListOptions: {
              render: (v: string) => activityRoles.find((r) => r.activityRoleId === v)?.name ?? '',
            },
          },
        },
        {
          name: 'programs',
          label: 'Program(s)',
          options: {
            filter: true,
            sort: false,
            display: true,
            filterType: filter.Custom,
            filterOptions: {
              // return false to prevent filtering on front-end
              logic: () => false,
              display: (
                filterListArr: string[][],
                onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
                index: number,
                column: MUIDataTableColumn
              ) => {
                const selectedProgramIds = filterListArr[index] || [];
                return (
                  <FormControl>
                    <InputLabel htmlFor='select-multiple-chip'>{'Program(s)'}</InputLabel>
                    <Select
                      multiple
                      name='programIds'
                      value={selectedProgramIds}
                      onChange={(event) => {
                        filterList[index] = event.target.value as string[];
                        onChange(filterList[index], index, column);
                      }}
                      renderValue={(selected) => {
                        const selectedIds = selected as Array<string>;
                        return managedPrograms
                          .filter((x: VolunteerDbPrograms) => selectedIds?.includes(x.programId))
                          .map((x: VolunteerDbPrograms) => x.name)
                          .join(', ');
                      }}
                    >
                      {managedPrograms.map((program) => (
                        <MenuItem key={program.programId} value={program.programId}>
                          <MUICheckbox color='primary' checked={selectedProgramIds?.indexOf(program.programId) > -1} />
                          <ListItemText primary={program.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              },
            },
            customFilterListOptions: {
              render: (s: string[]) => s.map((id) => programs.find((p) => p.programId === id)?.name ?? ''),
            },
            customBodyRender: (value: Array<string>) => {
              if (!value) return [];
              const programNames = value.map((v) => programs.find((p) => p.programId === v)?.name ?? '');
              programNames.sort((a, b) => {
                return a < b ? -1 : 1;
              });
              return <>{programNames.join(', ')}</>;
            },
          },
        },
        {
          name: 'teams',
          label: 'Activities',
          options: {
            filter: true,
            sort: false,
            display: true,
            filterType: filter.Custom,
            filterOptions: {
              // return false to prevent filtering on front-end
              logic: () => false,
              display: (
                filterListArr: string[][],
                onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
                index: number,
                column: MUIDataTableColumn
              ) => {
                const selectedActivityIds = filterListArr[index] || [];
                return (
                  <FormControl>
                    <InputLabel htmlFor='select-multiple-chip'>Activities</InputLabel>
                    <Select
                      multiple
                      name='activityIds'
                      value={selectedActivityIds}
                      onChange={(event) => {
                        filterList[index] = event.target.value as string[];
                        onChange(filterList[index], index, column);
                      }}
                      renderValue={(selected) => {
                        const selectedIds = selected as Array<string>;
                        return managedActivities
                          .filter((x) => selectedIds?.includes(x.activityId))
                          .map((x) => x.name)
                          .join(', ');
                      }}
                    >
                      {managedActivities.map((activity) => (
                        <MenuItem key={activity.activityId} value={activity.activityId}>
                          <MUICheckbox
                            color='primary'
                            checked={selectedActivityIds?.indexOf(activity.activityId) > -1}
                          />
                          <ListItemText primary={activity.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              },
            },
            customFilterListOptions: {
              render: (s: string[]) => s.map((id) => activities.find((p) => p.activityId === id)?.name ?? ''),
            },
            customBodyRender: (value: Array<string>) => {
              if (!value) return [];
              const activityNames = value.map((v) => activities.find((a) => a.activityId === v)?.name ?? '');
              activityNames.sort((a, b) => {
                return a < b ? -1 : 1;
              });
              return <>{activityNames.join(', ')}</>;
            },
          },
        },
      ],
      [columnsBase, activityRoles, programs, activities]
    );

    const handleTableStateChange = (action: string, tableState: MUIDataTableState) => {
      if (action === 'sort') {
        const { sortOrder } = tableState;
        updateRequestVariables({
          sortBy: sortOrder.name,
          sortDirection: sortOrder.direction.toUpperCase(),
        });
      }

      if (action === 'filterChange' && tableState.filterList !== filterList) {
        setFilterList(tableState.filterList || []);
      }
    };

    useEffect(() => {
      const target: { [key: string]: boolean } = {};
      if (volunteerProfileTasks) {
        volunteerProfileTasks.forEach((t) => {
          target[t.taskId] = false;
        });
        setTasksFilter(target);
      }
    }, [volunteerProfileTasks]);

    useEffect(() => {
      updateRequestVariables({ activeVolsFilter });
    }, [activeVolsFilter]);

    const columnsWithFilterList = useMemo(() => {
      if (!filterList || !filterList.find((filterListItem) => filterListItem.length)) {
        return columns;
      }
      return columns.map((column, idx) => {
        if (column.options && filterList) {
          return {
            ...column,
            options: {
              ...column.options,
              filterList: filterList[idx],
            },
          };
        }
        return column;
      });
    }, [columns, filterList]);

    const columnsWithFilterListAndImpact = useMemo(() => {
      const existingColumns = cloneDeep(columnsWithFilterList);
      impactColumns
        .filter((impactColumn) => (impactFilter ? impactFilter[impactColumn.name] : false))
        .forEach((impactColumn) => existingColumns.push(impactColumn));
      return existingColumns;
    }, [columnsWithFilterList, impactFilter]);

    const columnsWithFilterListAndImpactAndTasks = useMemo(() => {
      if (!volunteerProfileTasks) return columnsWithFilterListAndImpact;
      const c = cloneDeep(columnsWithFilterListAndImpact);
      volunteerProfileTasks
        .filter((ti) => (tasksFilter ? tasksFilter[ti.taskId] : false))
        .forEach((t: VolunteerDatabaseTasks) => {
          if (!c.find((col) => col.name === t.taskId)) {
            c.push({
              name: t.taskId,
              label: t.cake.cakeType === 'required' ? `*${t.title}` : t.title,
              options: {
                filter: true,
                sort: false,
                filterType: filter.TextField,
              },
            });
          }
          if (t.order === VOLUNTEER_PROFILE_TASK_ORDER) {
            Object.keys(volunteerProfileHardCodedFields).forEach((key) => {
              if (!c.find((col) => col.name === `${t.title}_${key}`)) {
                c.push({
                  name: `${t.title}_${key}`,
                  label: volunteerProfileHardCodedFields[key].name,
                  options: {
                    filter: true,
                    sort: false,
                    filterType: filter.TextField,
                  },
                });
              }
            });
          }
          t.taskItems.forEach((i) => {
            if (
              i.__typename === 'VOLUNTEER_TaskItemFieldType' &&
              !c.find((col) => col.name === `${t.title}_${i.field.name}`)
            ) {
              c.push({
                name: `${t.title}_${i.field.name}`,
                label: i.field.name,
                options: {
                  filter: true,
                  sort: false,
                  filterType: filter.TextField,
                },
              });
            }
          });
        });

      volunteerProfileTasks.forEach((t: VolunteerDatabaseTasks) => {
        const isTaskSelected = t.taskItems.some(
          (item) =>
            item.__typename === 'VOLUNTEER_TaskItemFieldType' && databaseTaskFields.includes(item.field.taskFieldId)
        );
        if (isTaskSelected && !c.find((col) => col.name === t.taskId)) {
          c.push({
            name: t.taskId,
            label: t.cake.cakeType === 'required' ? `*${t.title}` : t.title,
            options: {
              filter: true,
              sort: false,
              filterType: filter.TextField,
            },
          });
        }
        t.taskItems.forEach((i) => {
          if (
            i.__typename === 'VOLUNTEER_TaskItemFieldType' &&
            databaseTaskFields.includes(i.field.taskFieldId) &&
            !c.find((col) => col.name === `${t.title}_${i.field.name}`)
          ) {
            c.push({
              name: `${t.title}_${i.field.name}`,
              label: i.field.name,
              options: {
                filter: true,
                sort: false,
                filterType: filter.TextField,
              },
            });
          }
        });
      });

      return c;
    }, [columnsWithFilterListAndImpact, volunteerProfileTasks, tasksFilter, databaseTaskFields]);

    const emailColumnIdx = useMemo(() => columns.findIndex((column) => column.name === 'email'), [columns]);
    const nameColumnIdx = useMemo(() => columns.findIndex((column) => column.name === 'name'), [columns]);
    const phoneColumnIdx = useMemo(() => columns.findIndex((column) => column.name === 'contactNumber'), [columns]);
    const privilegeColumnIdx = useMemo(() => columns.findIndex((column) => column.name === 'privLevel'), [columns]);
    const commentsColumnIdx = useMemo(() => columns.findIndex((column) => column.name === 'comments'), [columns]);
    const programsColumnIdx = useMemo(() => columns.findIndex((column) => column.name === 'programs'), [columns]);
    const activitiesColumnIdx = useMemo(() => columns.findIndex((column) => column.name === 'teams'), [columns]);
    const statusColumnIdx = useMemo(() => columns.findIndex((column) => column.name === 'status'), [columns]);
    const dateColumnIdx = useMemo(() => columns.findIndex((column) => column.name === 'date'), [columns]);
    const rolesColumnIdx = useMemo(() => columns.findIndex((column) => column.name === 'roles'), [columns]);

    const handleRowClick = (rowData: string[]) => {
      const emailId: number = columns.findIndex((column: any) => column.name === 'email');
      if (volunteerDatabaseState) {
        const matchedVolunteerId = Object.keys(volunteerDatabaseState).find(
          (key) => volunteerDatabaseState[key].profile.email === rowData[emailId]
        );
        if (matchedVolunteerId) setSelectedVolunteerId(matchedVolunteerId);
      }
    };

    const updateFilters = (selectedFilters: Array<string>[]) => {
      const now = DateTime.local();

      const getDateCreatedFilter = (timeDiff: string) => {
        switch (timeDiff) {
          case '< 1 Day':
            return encodeDate(now.minus({ days: 1 }));
          case '< 1 Week':
            return encodeDate(now.minus({ weeks: 1 }));
          case '< 1 Month':
            return encodeDate(now.minus({ months: 1 }));
          case '< 1 Year':
            return encodeDate(now.minus({ years: 1 }));
          default:
            return null;
        }
      };

      const dateFilter =
        selectedFilters[dateColumnIdx]?.length > 0
          ? getDateCreatedFilter(selectedFilters[dateColumnIdx][selectedFilters[dateColumnIdx]?.length - 1])
          : null;

      updateRequestVariables({
        nameFilter: selectedFilters[nameColumnIdx][0] ?? null,
        emailFilter: selectedFilters[emailColumnIdx][0] ?? null,
        phoneFilter: selectedFilters[phoneColumnIdx][0] ?? null,
        privilegeFilter: selectedFilters[privilegeColumnIdx][0] ?? null,
        commentsFilter: selectedFilters[commentsColumnIdx][0] ?? null,
        rolesFilter: selectedFilters[rolesColumnIdx] ?? null,
        programsFilter: selectedFilters[programsColumnIdx] ?? null,
        activitiesFilter: selectedFilters[activitiesColumnIdx] ?? null,
        statusFilters: selectedFilters[statusColumnIdx]?.length > 0 ? selectedFilters[statusColumnIdx] : null,
        createdDateFilter: dateFilter,
        activeVolsFilter: activeVolsFilter,
      });
    };

    const handleSearchEnter = (value: GetVolunteerDatabaseVariables) => {
      setSearchText(value?.keywordsFilter ?? ' ');
      updateRequestVariables(value);
    };

    const customSearch = () => {
      return true;
    };
    const CustomToolbar = () => (
      <Tooltip title='Settings'>
        <IconButton
          onClick={() => {
            setOpenSettingDialog(true);
          }}
        >
          <Settings />
        </IconButton>
      </Tooltip>
    );

    const options = {
      filterType: filter.Checkbox,
      selectableRows: selectableRows.None,
      download: false,
      print: false,
      elevation: 0,
      resizableColumns: false,
      fixedHeader: true,
      fixedSelectColumn: true,
      // This is not ideal...
      tableBodyHeight: 'calc(100vh - 285px)',
      viewColumns: true,
      rowsPerPage: requestVariables.first ?? 25,
      rowsPerPageOptions: [25, 50, 100],
      onChangeRowsPerPage: (numberOfRows: number) => updateRequestVariables({ first: numberOfRows }),
      searchOpen: false,
      searchText: searchText,
      customSearch: customSearch,
      page: paginationState.currentPage,
      onTableChange: (action: string, tableState: MUIDataTableState) => handleTableStateChange(action, tableState),
      customSort: (datax: Array<string>) => datax,
      confirmFilters: true,
      customSearchRender: () => <CustomSearchField handleSearchEnter={handleSearchEnter} />,
      customFilterDialogFooter: (_unusedCurrentFilterList: Array<any>, applyFilters?: Function) => (
        <CampfireButton
          variant={'contained'}
          fullWidth
          style={{ marginTop: 16 }}
          onClick={() => {
            if (applyFilters) applyFilters();
          }}
        >
          {'Apply Filters'}
        </CampfireButton>
      ),
      onFilterChipClose: (_unusedIndex: number, _unusedRemovedFilter: string, currentFilterList: Array<any>) =>
        updateFilters(currentFilterList),
      onFilterChange: (_unused: any, currentFilterList: any[]) => updateFilters(currentFilterList),
      setRowProps: (row: Array<string>) => {
        const statusId: number = columns.findIndex((column: any) => column.name === 'status');
        if (isStatusFlaggedAndExited(row[statusId])) {
          return {
            style: { backgroundColor: fade(theme.palette.error.main, 0.2) },
          };
        }
        if (isStatusDeactivated(row[statusId])) {
          return {
            style: { backgroundColor: theme.palette.grey[300] },
          };
        }
        return {};
      },
      onRowClick: handleRowClick,
      onChangePage: handlePageChange,
      customToolbar: () => <CustomToolbar />,
    };

    function applyFieldValueForFieldType(fieldValue: TaskFieldValue) {
      switch (fieldValue.__typename) {
        case 'VOLUNTEER_AddressTaskFieldValueType':
          return fieldValue.address.placesAddress?.formatted ?? fieldValue.address.manualAddress?.humanReadable ?? '';
        case 'VOLUNTEER_AttachmentTaskFieldValueType':
          return `${fieldValue.attachments.length} attachment${fieldValue.attachments.length !== 1 ? 's' : ''}` ?? '';
        case 'VOLUNTEER_CheckboxTaskFieldValueType':
          return fieldValue.checkboxTaskFieldOptions.map((opt) => opt.name);
        case 'VOLUNTEER_DateTaskFieldValueType':
          return unpackToDateTime(fieldValue.dateValue).toFormat('d MMM yyyy') ?? '';
        case 'VOLUNTEER_DropdownTaskFieldValueType':
          return fieldValue.dropdownTaskFieldOption.name ?? '';
        case 'VOLUNTEER_EmailTaskFieldValueType':
          return fieldValue.email ?? '';
        case 'VOLUNTEER_LongTextTaskFieldValueType':
          return fieldValue.textValue ?? '';
        case 'VOLUNTEER_MultipleChoiceTaskFieldValueType':
          return fieldValue.multipleChoiceTaskFieldOption.name ?? '';
        case 'VOLUNTEER_NumberTaskFieldValueType':
          return fieldValue.numericValue ?? '';
        case 'VOLUNTEER_PhoneTaskFieldValueType':
          return fieldValue.contactNumber ?? '';
        case 'VOLUNTEER_ShortTextTaskFieldValueType':
          return fieldValue.textValue ?? '';
        default:
          return '';
      }
    }

    function addVolunteerProfileTasksData(
      vdbState: VolunteerDatabaseState,
      key: string,
      volProfileTasks?: VolunteerDatabaseTasks[]
    ) {
      if (!volProfileTasks) return {};
      const taskData: { [key: string]: any } = {};
      volProfileTasks.forEach((task) => {
        const completedTask = vdbState[key].profile.completedTasks.find((t) => t.task.taskId === task.taskId);
        taskData[task.taskId] = completedTask ? 'Complete' : 'Incomplete';
        if (task.order === VOLUNTEER_PROFILE_TASK_ORDER) {
          taskData[`${task.title}_firstName`] = vdbState[key].profile.firstName;
          taskData[`${task.title}_lastName`] = vdbState[key].profile.lastName;
          taskData[`${task.title}_preferredName`] = vdbState[key].profile.preferredName;
          taskData[`${task.title}_dob`] = vdbState[key].profile.dateOfBirth;
          taskData[`${task.title}_contactNumber`] = vdbState[key].profile.contactNumber;
          taskData[`${task.title}_programId`] = vdbState[key].programs.map((program) => program.name);
        }
        task.taskItems.forEach((ti) => {
          if (ti.__typename === 'VOLUNTEER_TaskItemFieldType' && completedTask) {
            const fieldValue = completedTask.taskFieldValues.find(
              (tfv) => tfv.field.taskFieldId === ti.field.taskFieldId
            );
            if (fieldValue) {
              taskData[`${task.title}_${ti.field.name}`] = applyFieldValueForFieldType(fieldValue);
            }
          }
        });
      });
      return taskData;
    }

    function addVolunteerUserPreferenceTasksData(
      vdbState: VolunteerDatabaseState,
      key: string,
      taskFieldIds: string[]
    ) {
      if (!taskFieldIds.length) return {};
      const taskData: { [key: string]: any } = {};
      if (!volunteerProfileTasks) return taskData;
      volunteerProfileTasks.forEach((task) => {
        task.taskItems.forEach((ti) => {
          if (ti.__typename === 'VOLUNTEER_TaskItemFieldType' && taskFieldIds.includes(ti.field.taskFieldId)) {
            const completedTask = vdbState[key].profile.completedTasks.find((t) => t.task.taskId === task.taskId);

            const fieldValue = completedTask?.taskFieldValues.find(
              (tfv) => tfv.field.taskFieldId === ti.field.taskFieldId
            );
            if (fieldValue) {
              taskData[`${task.title}_${ti.field.name}`] = applyFieldValueForFieldType(fieldValue);
            }
          }
        });
      });

      return taskData;
    }

    const parseVdbState = (vdbState: any) =>
      Object.keys(vdbState).map((key) => ({
        ...vdbState[key],
        privLevel: vdbState[key].privilegeLevel,
        name: `${vdbState[key].profile.preferredName} ${vdbState[key].profile.lastName}`,
        email: vdbState[key].profile.email,
        contactNumber: vdbState[key].profile.contactNumber,
        status: getVolunteerDatabaseRosterStatus(vdbState[key]),
        programs: vdbState[key].programs.map((program: any) => program.programId),
        roles: vdbState[key].activityRoles.map((r: VolunteerDatabaseActivityRole) => r.activityRoleId),
        comments:
          vdbState[key]?.profile.comments !== null
            ? JSON.parse(vdbState[key].profile.comments as string)
                .blocks.map((block: any) => block.text)
                .join(' ')
            : '',
        date: vdbState[key].dateCreated,
        teams: vdbState[key].activityEnrolments.map((enrolment: any) => enrolment.activity.activityId),
        // extra data for csv export only
        firstName: vdbState[key].profile.firstName,
        preferredName: vdbState[key].profile.preferredName,
        lastName: vdbState[key].profile.lastName,
        dateOfBirth: vdbState[key].profile.dateOfBirth,
        ...addVolunteerProfileTasksData(vdbState, key, volunteerProfileTasks),
        ...addVolunteerUserPreferenceTasksData(vdbState, key, databaseTaskFields),
        lastLogin: vdbState[key].lastLoginDate,
        activities: vdbState[key].activityEnrolments.length ?? 0,
        volunteerHours:
          Math.round(vdbState[key].volunteerStatistics.sessionReportStatistics.operationalHours * 100) / 100 +
          Number(vdbState[key].profile.additionalVolunteerHours),
        attendances: vdbState[key].volunteerStatistics?.attended,
        attended: vdbState[key].volunteerStatistics?.lastAttended
          ? unpackToDate(vdbState[key].volunteerStatistics?.lastAttended).toFormat('dd LLLL yyyy')
          : undefined,
      }));

    const placeHolderRows = (requiredRows: number) => {
      const rows = [];
      for (let j = 0; j < requiredRows; j++) {
        rows.push(null);
      }
      return rows;
    };

    const data: Array<{ [key: string]: any }> = useMemo(() => {
      if (!volunteerDatabaseState) return [];
      const requiredPlaceholderRowsCount = paginationState.totalVolunteers - Object.keys(volunteerDatabaseState).length;
      return parseVdbState(volunteerDatabaseState).concat(placeHolderRows(requiredPlaceholderRowsCount));
    }, [volunteerDatabaseState]);

    const getDownloadColumns = React.useMemo(() => {
      const generalColumns = Object.keys(
        columns
          .filter((column: any) => column.options.display !== 'false')
          .reduce((acc, { name }) => {
            if (name === 'contactNumber') {
              acc.phone = true;
              return acc;
            }
            if (name === 'teams') {
              acc.activities = true;
              return acc;
            }
            if (name === 'privLevel') {
              acc.privilegeLevel = true;
              return acc;
            }
            if (name === 'date') {
              acc.created = true;
              return acc;
            }
            acc[name] = true;
            return acc;
          }, {} as { [key: string]: boolean })
      );
      if (impactFilter.activities) {
        generalColumns.push('numberOfEnrolledActivities');
      }
      if (impactFilter.attendances) {
        generalColumns.push('totalAttendances');
      }
      if (impactFilter.attended) {
        generalColumns.push('lastAttended');
      }
      if (impactFilter.volunteerHours) {
        generalColumns.push('volunteerHours');
      }
      if (impactFilter.lastLogin) {
        generalColumns.push('lastActive');
      }
      return generalColumns;
    }, [impactFilter, columns]);

    const campfireExport = useRequestExport();

    const onRequestDownload = () =>
      campfireExport('volunteers', {
        filter: requestVariables,
        columns: getDownloadColumns,
        tasks: Object.keys(tasksFilter).filter((taskId) => tasksFilter[taskId]),
      });

    return (
      <PaneWrapper>
        <Pane>
          <PaneHeader>
            <Box paddingTop={2} bgcolor='#ffffff' width={1}>
              <VolunteerDatabaseTableOptionsMenu
                setActiveVolsFilter={setActiveVolsFilter}
                activeVolsFilter={activeVolsFilter}
                tasks={volunteerProfileTasks}
                setTasksFilter={setTasksFilter}
                tasksFilter={tasksFilter}
                impactOptions={impactColumns}
                setImpactFilter={setImpactFilter}
                impactFilter={impactFilter}
                onRequestDownload={onRequestDownload}
              />
            </Box>
          </PaneHeader>
          <PaneScrollable>
            <MUIDataTable
              title=''
              data={data || []}
              columns={columnsWithFilterListAndImpactAndTasks}
              options={options}
              components={{
                TableBody: isLoading
                  ? () => (
                      <Box component='tbody'>
                        {new Array(25)
                          .fill(null)
                          .map((_unusedValue, index) => index)
                          .map((rowIndex) => (
                            <tr key={rowIndex}>
                              {columnsWithFilterList
                                .filter((column) => column.options?.display !== 'false')
                                .map((column) => (
                                  <td key={column.name}>
                                    <Skeleton height='3rem' width='75%' />
                                  </td>
                                ))}
                            </tr>
                          ))}
                      </Box>
                    )
                  : TableBody,
              }}
            />
          </PaneScrollable>
        </Pane>
        {openSettingDialog && (
          <VolunteerDatabaseSettingDialog
            open={openSettingDialog}
            volunteerProfileTasks={volunteerProfileTasks}
            databaseTaskFields={databaseTaskFields}
            handleClose={() => {
              setOpenSettingDialog(false);
            }}
            onSuccess={() => refetchUserProfile && refetchUserProfile()}
          />
        )}
      </PaneWrapper>
    );
  }, areEqual)
);

export { VolunteerDatabaseDesktopTable };
