import { Box, FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import React from 'react';
import { TaskFormItem_VOLUNTEER_TaskItemFieldType_field_VOLUNTEER_QuizTaskFieldType as QuizFieldType } from '../../../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';
import { DropItemTrigger } from './DropItemTrigger';

interface QuizFieldPreviewProps {
  field: QuizFieldType;
  isDragging: boolean;
}

export function QuizFieldPreview({ field, isDragging }: QuizFieldPreviewProps) {
  if (!field.dynamic || isDragging) {
    return (
      <Grid container direction='column'>
        {field.quizTaskFieldOptions.map((option) => {
          const optionId = option.quizTaskFieldOptionId;
          return (
            <Grid item key={optionId} style={{ overflow: 'hidden', maxWidth: '100%' }}>
              <FormControlLabel
                control={
                  <Radio
                    disabled
                    checked={
                      option.quizTaskFieldOptionId ===
                      field.quizTaskFieldCorrect?.quizTaskFieldOption.quizTaskFieldOptionId
                    }
                  />
                }
                label={
                  <Box display='flex' alignItems='center'>
                    <Typography>{option.name}</Typography>
                    {option.quizTaskFieldOptionId ===
                      field.quizTaskFieldCorrect?.quizTaskFieldOption.quizTaskFieldOptionId && (
                      <Typography style={{ fontSize: '12px', marginLeft: '4px' }}>(Correct Answer)</Typography>
                    )}
                  </Box>
                }
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
  return (
    <Grid container direction='column'>
      {field.quizTaskFieldOptions.map((option) => {
        const optionId = option.quizTaskFieldOptionId;
        return (
          <Grid item key={optionId} style={{ overflow: 'hidden', maxWidth: '100%' }}>
            <DropItemTrigger optionId={optionId} name={option.name} />
          </Grid>
        );
      })}
    </Grid>
  );
}
