import { gql } from '@apollo/client';

export const GET_ACTIVITIES_WAITLISTED_VOLUNTEERS = gql`
query GetActivitiesConsoleWaitlistedVolunteers($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityId
        activeActivityWaitlistings {
            ...ActivityConsoleActivityWaitlistings
        } 
    }
}
}

fragment ActivityConsoleActivityWaitlistings on VOLUNTEER_ActivityWaitlistingType {
    activityWaitlistingId
    dateAdded
    volunteer {
      volunteerId
      profile {
        profileId
        userId
        preferredName
        lastName
        avatarUrl
      }
    }
  }
`;