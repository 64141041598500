import React, { useEffect, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import { Field } from '@campfire/field/lib';
import { HoverText } from '@campfire/hover-link/lib';
import { TabletButton } from '@campfire/tablet-button/lib';
import { useAdminConsoleActions } from '../admin-console/admin-console-actions';
import { useLoadingContext } from '../admin-console/admin-console-content-pages/admin-console-volunteer-profile/AdminConsoleLoadingContext';
import { TaskListGetCake_vm_cake as Cake } from './TasksList/__generated__/TaskListGetCake';

const validationSchema = YupObject().shape({
  title: YupString().required('Required'),
  description: YupString().required('Required'),
});
type DescriptionValues = {
  title: string;
  description: string;
};

interface Props {
  open: boolean;
  cake: Cake;
  close: () => void;
  refetch?: () => Promise<{ data: any }>;
}

export const EditDescriptionDialogv2 = (props: Props) => {
  const { open, close, cake, refetch } = props;
  const { runSaveCake, runSaveCakeIsLoading } = useAdminConsoleActions();
  const { setIsLoading } = useLoadingContext();

  useEffect(() => setIsLoading(runSaveCakeIsLoading), [runSaveCakeIsLoading]);

  const initialFormikValues = useMemo(() => ({ title: cake.title, description: cake.description }), [cake]);

  const handleSaveSuccess = () => {
    if (refetch)
      refetch().then((res) => {
        if (res.data) close();
      });
  };

  const handleSubmit = (vals: DescriptionValues) => {
    runSaveCake(
      {
        ...cake,
        title: vals.title,
        description: vals.description,
        taskIds: cake.allTasks.filter((task) => task.order !== -1).map((task) => task.taskId),
      },
      handleSaveSuccess
    );
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{'Edit description'}</DialogTitle>
      <Formik initialValues={initialFormikValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ handleReset, values, initialValues }) => (
          <Form style={{ width: '100%' }}>
            <DialogContent style={{ overflow: 'hidden' }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Field name='title' variant='outlined' label='Name' required fullWidth disabled={cake.required} />
                </Grid>
                <Grid item xs={12}>
                  <Field name='description' variant='outlined' label='Description' multiline required fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <HoverText color='primary' onClick={handleReset} disabled={values === initialValues}>
                    {'Reset to default'}
                  </HoverText>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container justify='flex-end' alignItems='center'>
                <TabletButton variant='text' onClick={close}>
                  {'Cancel'}
                </TabletButton>
                <TabletButton variant='contained' color='primary' type='submit' disabled={runSaveCakeIsLoading}>
                  {'Done'}
                </TabletButton>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
