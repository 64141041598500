import React from 'react';
import { NumberParam, ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { ActivityFilter } from '../../../general/activities-v2/ActivityFilter/ActivityFilter';
import * as ActivityConsts from '../../../general/activities-v2/ActivityConst';
import { ProgramsContext } from '../../../../global/programs-sell/ProgramsContext';

export function RosterFilter() {
  const [query, setQuery] = useQueryParams({
    programId: StringParam,
    activityType: StringParam,
    activityStatus: StringParam,
    days: ArrayParam,
    duration: NumberParam,
    rosterType: StringParam,
    startTime: StringParam,
    endTime: StringParam,
    location: StringParam,
  });

  const onChangeFilters = (value: any) => {
    Object.entries(value).forEach(([key, val]) => {
      switch (key) {
        case 'program':
          setQuery({ programId: val as string });
          break;
        case 'day':
          {
            const dayVals = val as { [key: string]: boolean };
            setQuery({ days: Object.keys(dayVals).filter((selectedDay) => dayVals[selectedDay]) });
          }
          break;
        default:
          setQuery({ [key]: val });
          break;
      }
    });
  };

  const { getAuthorizedPrograms } = React.useContext(ProgramsContext);
  const programs = getAuthorizedPrograms();

  const programOptions = [
    {
      value: 'all',
      label: 'All programs',
    },
  ].concat(
    programs.map((program) => ({
      value: program.programId,
      label: program.name,
    })) || []
  );

  return (
    <ActivityFilter
      values={{
        program: query.programId || 'all',
        activityType: query.activityType || 'all',
        activityStatus: query.activityStatus || 'active',
        day: query.days?.reduce((acc, day) => ({ ...acc, [parseInt(day, 10)]: true }), {}) || {},
        duration: query.duration || 0,
        rosterType: query.rosterType || 'all',
        startTime: query.startTime || '',
        endTime: query.endTime || '',
        location: query.location || 'all',
      }}
      onChange={onChangeFilters}
      programOptions={programOptions}
      durationOptions={ActivityConsts.DURATION_OPTIONS}
      activityOptions={ActivityConsts.ACTIVITYTYPE_OPTIONS}
      dayOptions={ActivityConsts.DAY_OPTIONS}
      locationOptions={ActivityConsts.LOCATION_OPTIONS}
      activityStatusesOptions={ActivityConsts.ACTIVITY_ACTIVENESS_OPTIONS}
      rosterTypesOptions={ActivityConsts.ROSTERTYPES_OPTIONS}
    />
  );
}
