import {
  array as YupArray,
  boolean as YupBoolean,
  number as YupNumber,
  object as YupObject,
  string as YupString,
} from 'yup';

export const validationSchema = YupObject().shape({
  __typename: YupString().required(),
  fieldId: YupString(),
  reportTypeItemId: YupString(),
  order: YupNumber().required(),
  name: YupString().required('Please provide a name'),
  description: YupString(),
  isHero: YupBoolean(),
  isAllowMultiple: YupBoolean(),
  dropdownOptions: YupArray()
    .of(
      YupObject().shape({
        dropdownFieldOptionId: YupString(),
        name: YupString().required(),
        order: YupNumber().required(),
        listId: YupString(),
      })
    )
    .when('__typename', {
      is: (val) => val === 'VOLUNTEER_DropdownFieldType',
      then: YupArray()
        .of(
          YupObject().shape({
            dropdownFieldOptionId: YupString(),
            name: YupString().required(),
            order: YupNumber().required(),
            listId: YupString(),
          })
        )
        .required(),
    }),
  isLongText: YupBoolean(),
});

export type FieldType =
  | 'VOLUNTEER_CheckboxFieldType'
  | 'VOLUNTEER_DropdownFieldType'
  | 'VOLUNTEER_RatingFieldType'
  | 'VOLUNTEER_BooleanFieldType'
  | 'VOLUNTEER_TimeFieldType'
  | 'VOLUNTEER_TextFieldType'
  | 'VOLUNTEER_AttachmentFieldType'
  | 'VOLUNTEER_NumericFieldType';

export type MappedFieldType = { name: string; type: FieldType };

export const fieldTypes: Array<MappedFieldType> = [
  { name: 'Checklist Item', type: 'VOLUNTEER_CheckboxFieldType' },
  { name: 'Dropdown', type: 'VOLUNTEER_DropdownFieldType' },
  { name: 'Rating', type: 'VOLUNTEER_RatingFieldType' },
  { name: 'Thumbs Up/Down', type: 'VOLUNTEER_BooleanFieldType' },
  { name: 'Attachment', type: 'VOLUNTEER_AttachmentFieldType' },
  { name: 'Time', type: 'VOLUNTEER_TimeFieldType' },
  { name: 'Text', type: 'VOLUNTEER_TextFieldType' },
  { name: 'Number', type: 'VOLUNTEER_NumericFieldType' },
];

export interface EditFieldValues {
  __typename:
    | 'VOLUNTEER_CheckboxFieldType'
    | 'VOLUNTEER_DropdownFieldType'
    | 'VOLUNTEER_RatingFieldType'
    | 'VOLUNTEER_BooleanFieldType'
    | 'VOLUNTEER_TimeFieldType'
    | 'VOLUNTEER_TextFieldType'
    | 'VOLUNTEER_NumericFieldType'
    | 'VOLUNTEER_AttachmentFieldType';
  reportTypeItemId: string;
  order: number;
  name: string;
  description: string;
  isHero?: boolean;
  isAllowMultiple?: boolean;
  isLongText?: boolean;
  dropdownOptions?: {
    dropdownFieldOptionId?: string;
    name: string;
    order: number;
    listId: string;
  }[];
  fieldId?: string;
  optional: boolean;
}
