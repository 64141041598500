import React from 'react';
import { Box, Typography, Theme, Button } from '@material-ui/core';
import { Description, Person, ExpandMore, ExpandLess, BorderColorOutlined } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { convertFromRaw, EditorState } from 'draft-js';
import { ActivityHeader } from './ActivityHeader';
import {
  ActivitySession as ActivitySessionType,
  ActivitySession_sessionReports_CICOs as CICOType,
} from './__generated__/ActivitySession';
import { sharedStyles } from '../ActivityStyles/shared';
import { getDisplayTimeSchedule } from '../../../../common/functions/activity-display-helpers';
import { useUser } from '../../../../global/auth/useUser';
import { EditActivityAttendanceDialog } from './EditActivityAttendanceDialog';
import { ActivityContextValue } from '../ActivityContext';

interface SessionItemProps {
  session: ActivitySessionType;
  detailed?: boolean;
  onEditClick: (c: CICOType, s: ActivitySessionType) => void;
}

const useSessionItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    heading: {
      fontWeight: 900,
      fontSize: '1rem',
      color: theme.color.grey.neutralBrand800,
    },
    caption: {
      fontWeight: 700,
      fontSize: '0.75rem',
      color: theme.color.grey.neutral200,
    },
    detailContainer: {
      marginTop: 4,
      fontSize: '0.75rem',
      color: theme.color.grey.neutral200,
    },
    detailReportContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 12,
    },
    detailReportItem: {
      display: 'flex',
      alignItems: 'center',
      '& .text': {
        marginLeft: 7,
        fontSize: '0.75rem',
      },
    },
    detailVolunteerContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& .icon': {
        fontSize: 16,
      },
      '& .belowText': {
        fontSize: 8,
        lineHeight: 'unset',
      },
    },
  })
);

function SessionItem({ detailed, session, onEditClick }: SessionItemProps) {
  const classes = useSessionItemStyles();
  const { query } = React.useContext(ActivityContextValue);
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  const { description, sessionReports } = session;

  const CICO = sessionReports
    .flatMap((s) => s.CICOs)
    .find(
      (c) =>
        c.volunteer.volunteerId === volunteerId &&
        c.checkIn &&
        c.checkOut &&
        c.sessionReport.activityReport.activity.activityId === query.activityId &&
        c.sessionReport.activityReport.activityDate === query.activityDate
    );
  const sessionTime = getDisplayTimeSchedule(CICO?.checkIn, CICO?.checkOut);

  const editorState = React.useMemo(
    () => (description ? EditorState.createWithContent(convertFromRaw(JSON.parse(description))) : null),
    [description]
  );

  if (!CICO) return null;

  return (
    <React.Fragment>
      <Box className={classes.container}>
        <Box>
          <Typography variant='h5' className={classes.heading}>
            {session.name}
          </Typography>
          <Typography className={classes.caption}>{sessionTime}</Typography>
        </Box>
        <TabletButton
          data-track='resources-add-new-menu-button'
          variant='text'
          color='primary'
          aria-controls='add-new'
          aria-haspopup='true'
          startIcon={<BorderColorOutlined />}
          onClick={() => onEditClick(CICO, session)}
          style={{ padding: '8px 12px', borderRadius: '4px' }}
        >
          Edit
        </TabletButton>
      </Box>
      {detailed && (
        <Box className={classes.detailContainer}>
          {editorState && <CampfireRichEditor editorState={editorState} readOnly />}
          <Box className={classes.detailReportContainer}>
            <Box className={classes.detailReportItem} flex={1}>
              <Description fontSize='small' />
              <Typography className='text'>
                {session.reportType === null
                  ? session.autoReport
                    ? 'No Report Required (Impact Tracked)'
                    : 'No Report Required (No Impact)'
                  : session.reportType?.name}
              </Typography>
            </Box>
            {session.minVolunteers && (
              <Box className={classes.detailReportItem}>
                <Box className={classes.detailVolunteerContainer}>
                  <Person className='icon' />
                  <Typography className='belowText'>MIN</Typography>
                </Box>
                <Typography className='text'>{session.minVolunteers}</Typography>
              </Box>
            )}
            {session.maxVolunteers && (
              <Box className={classes.detailReportItem} paddingLeft='1rem'>
                <Box className={classes.detailVolunteerContainer}>
                  <Person className='icon' />
                  <Typography className='belowText'>MAX</Typography>
                </Box>
                <Typography className='text'>{session.maxVolunteers}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

const useSessionStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.color.grey.neutral400,
    },
  })
);

export interface ActivityAttendanceProps {
  sessions: ActivitySessionType[];
  refetch: any;
}

export function ActivityAttendance({ sessions, refetch }: ActivityAttendanceProps) {
  const [showDetails, setShowDetails] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedCICO, setSelectedCICO] = React.useState<CICOType>();
  const [selectedSession, setSelectedSession] = React.useState<ActivitySessionType>();
  const showingSessions = showMore ? sessions : sessions?.slice(0, 3);
  const buttonClasses = sharedStyles.button();
  const classes = useSessionStyles();

  const handleEditClick = (CICO: CICOType, session: ActivitySessionType) => {
    setSelectedSession(session);
    setSelectedCICO(CICO);
    setOpenEdit(true);
  };
  const handleClose = () => {
    setSelectedCICO(undefined);
    setOpenEdit(true);
  };

  return (
    <Box marginTop='2rem'>
      <ActivityHeader
        title='Attendance'
        action={
          <HoverText
            color='primary'
            variant='body2'
            onClick={() => setShowDetails(!showDetails)}
            style={{ fontSize: '12px' }}
          >
            {showDetails ? 'Hide Details' : 'Show Details'}
          </HoverText>
        }
      />
      <Box>
        {showingSessions?.map((session: ActivitySessionType) => (
          <SessionItem key={session.sessionId} session={session} detailed={showDetails} onEditClick={handleEditClick} />
        ))}
      </Box>
      {sessions?.length > 3 && (
        <Box display='flex' justifyContent='center' marginTop='12px'>
          {showMore ? (
            <Button
              variant='outlined'
              onClick={() => setShowMore(false)}
              endIcon={<ExpandLess />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show less
            </Button>
          ) : (
            <Button
              variant='outlined'
              onClick={() => setShowMore(true)}
              endIcon={<ExpandMore />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show more
            </Button>
          )}
        </Box>
      )}
      {openEdit && selectedCICO && (
        <EditActivityAttendanceDialog
          open
          name={selectedSession?.name || 'Session'}
          handleClose={handleClose}
          CICO={selectedCICO}
          handleSuccess={refetch}
        />
      )}
    </Box>
  );
}
