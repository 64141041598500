import { TabletButton } from '@campfire/tablet-button';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import {
  getActiveStatusColor,
  getRosterSessionSessionText,
  getUpdatedRosterCellActiveStatus,
  RosterGridDataRow,
} from '../roster/activity-roster-model';
import { getUpdatedRosterGridDataRow } from '../roster/use-activity-roster';

type SessionRosteringState = 'available' | 'unavailable' | 'rostered';

export interface SessionRosterings {
  [sessionId: string]: SessionRosteringState;
}

type Props = {
  volunteerName: string;
  rosterGridDataRow: RosterGridDataRow;
  onSubmit: (value: RosterGridDataRow) => void;
  onMakeUnavailable?: () => void;
  onClose: () => void;
} & Omit<DialogProps, 'onSubmit' | 'onClose'>;

const SingleVolunteerAvailablityDialog = (props: Props) => {
  const { volunteerName, rosterGridDataRow, onSubmit, onMakeUnavailable, onClose, ...rest } = props;
  const [localRosterGridDataRow, setLocalRosterGridDataRow] = useState<RosterGridDataRow>(rosterGridDataRow);
  const [makeUnavailableChecked, setMakeUnavailableChecked] = useState(false);

  useEffect(() => {
    if (makeUnavailableChecked)
      setLocalRosterGridDataRow((prev) => ({
        ...prev,
        hasActiveChanges: !!prev.rosterSessions.find((rs) => rs.activeStatus === 'publishedSelected'),
        rosterSessions: prev.rosterSessions.map((rs) => ({
          ...rs,
          activeStatus:
            rs.activeStatus === 'selected' || rs.activeStatus === 'publishedSelected'
              ? getUpdatedRosterCellActiveStatus(rs.activeStatus)
              : rs.activeStatus,
          hasActiveChange: rs.activeStatus === 'publishedSelected',
        })),
      }));
  }, [makeUnavailableChecked]);

  useEffect(() => {
    setLocalRosterGridDataRow(rosterGridDataRow);
  }, []);

  const { theme } = useCampfireTheme();

  return (
    <Dialog {...rest} onClose={onClose}>
      <DialogTitle>{`Select sessions to roster ${volunteerName} on to`}</DialogTitle>
      <DialogContent>
        <List dense>
          {localRosterGridDataRow.rosterSessions.map((rosterSession) => {
            const sessionDisplayText = `${rosterSession.session.name}`;
            return (
              <ListItem
                disabled={rosterSession.activeStatus === undefined && rosterSession.originalStatus === 'unavailable'}
                key={rosterSession.session.sessionId}
                button
                onClick={() => {
                  setMakeUnavailableChecked(false);
                  setLocalRosterGridDataRow((prev) => {
                    return getUpdatedRosterGridDataRow(prev, rosterSession.session.sessionId) ?? prev;
                  });
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    style={{
                      color:
                        rosterSession.activeStatus === undefined
                          ? theme.palette.action.active
                          : getActiveStatusColor(rosterSession, theme),
                    }}
                    checked={
                      !makeUnavailableChecked &&
                      (rosterSession.activeStatus === 'publishedSelected' || rosterSession.activeStatus === 'selected')
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': rosterSession.session.sessionId }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={sessionDisplayText}
                  secondary={getRosterSessionSessionText(rosterSession)}
                ></ListItemText>
              </ListItem>
            );
          })}
        </List>

        {!onMakeUnavailable ? null : (
          <>
            <Divider />

            <List dense>
              <ListItem
                button
                onClick={() => {
                  setMakeUnavailableChecked((prev) => !prev);
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    checked={makeUnavailableChecked}
                    tabIndex={-1}
                    disableRipple
                    color='primary'
                    inputProps={{ 'aria-labelledby': 'make-volunteer-unavailable' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      {'Mark as '}
                      <span style={{ color: theme.status.red.light }}>{`'Unavailable'`}</span>
                      {' for all sessions'}
                    </>
                  }
                  secondary={`Volunteer will not be able to be rostered for any session once they have been marked ‘unavailable’ here`}
                ></ListItemText>
              </ListItem>
            </List>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <TabletButton variant='text' onClick={() => onClose && onClose()}>
          {'Cancel'}
        </TabletButton>
        <TabletButton
          data-track='actCnl-roster-dialog-update-roster'
          variant='text'
          color='primary'
          onClick={() => {
            if (makeUnavailableChecked && onMakeUnavailable) {
              onMakeUnavailable();
              return;
            }
            onSubmit(localRosterGridDataRow);
            onClose();
          }}
        >
          {'Update Roster'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { SingleVolunteerAvailablityDialog };
