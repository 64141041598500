import React from 'react';
import { Grid } from '@material-ui/core';
import { HoverLink } from '@campfire/hover-link';
import { Add as AddIcon } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button/lib';
import { useLocation, useRouteMatch } from 'react-router';
import { useSupportContext } from '../../SupportContext';
import { useCopyLinkAction } from '../../../../../common/CopyToClipboard';

interface Props {
  supportCategoryId?: string;
}
const AdminEditButtons = (props: Props) => {
  const { supportCategoryId } = props;
  const { isAdmin } = useSupportContext();
  const match = useRouteMatch();
  const location = useLocation();
  const { handleCopyLinkClick } = useCopyLinkAction();

  return isAdmin ? (
    <Grid item container xs={12} justify='flex-end' spacing={1}>
      <Grid item>
        <HoverLink
          data-track='resources-resource-viewer-edit-button'
          disableUnderline
          to={{
            pathname: location.pathname,
            search: '?redit=1',
          }}
        >
          <TabletButton tabIndex={-1} variant='outlined'>
            Edit
          </TabletButton>
        </HoverLink>
      </Grid>
      <Grid item>
        <HoverLink
          disableUnderline
          data-track='resources-resource-viewer-add-resource-button'
          to={{
            pathname: '/warmth/resources/create-resource',
            search: `?catId=${supportCategoryId}`,
            state: {
              returnSlug: match?.url ?? '',
            },
          }}
        >
          <TabletButton tabIndex={-1} variant='contained' color='primary' endIcon={<AddIcon />}>
            Add Resource
          </TabletButton>
        </HoverLink>
      </Grid>
      <Grid item>
        <TabletButton variant='outlined' onClick={handleCopyLinkClick}>
          Copy Link
        </TabletButton>
      </Grid>
    </Grid>
  ) : (
    <Grid item container xs={12} justify='flex-end' spacing={1}>
      <Grid item>
        <TabletButton variant='outlined' onClick={handleCopyLinkClick}>
          Copy Link
        </TabletButton>
      </Grid>
    </Grid>
  );
};

export { AdminEditButtons };
