import { gql } from '@apollo/client';
import { TASK_FORM_ITEM_FRAGMENT } from '../../../common/form/task-form/model/task-items.gql';

export const SUYP_GET_INDIVIDUAL_TASK = gql`
  query SUYPGetIndividualTask($taskId: String!) {
    vm {
      task(taskId: $taskId) {
        taskId
        title
        description
        cake {
          title
        }
        taskItems {
          ...TaskFormItem
        }
      }
    }
  }

  ${TASK_FORM_ITEM_FRAGMENT}
`;
