import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { SearchField } from '../../../../common/inputs/SearchField';
import { useProgramManagementContext } from '../ProgramManagementContext';

interface Props {
  setSearchInput: (x?: string) => void;
}

const SideNavHeader = (props: Props) => {
  const { setSearchInput } = props;
  const { setSelectedProgramId, selectedProgramId } = useProgramManagementContext();

  return (
    <Grid item container spacing={2} style={{ padding: 16, margin: 0, width: '100%' }}>
      <Grid item xs={12} style={{ paddingLeft: 0 }}>
        <SearchField
          data-track='fs-prgMan-serach-programs'
          placeholder='Search Programs'
          onChange={(e) => setSearchInput(e.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} style={{ padding: 8 }}>
        <Typography variant='body2'>What is a program?</Typography>
        <Typography variant='body2' color='textSecondary' display='inline'>
          {`A program consists of volunteers who are interested in a collection of activities. Click the 'Create program' button below to get started.`}
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ paddingLeft: 0 }}>
        <TabletButton
          data-track='fs-prgMan-create-program'
          variant='contained'
          color='primary'
          disabled={selectedProgramId === 'new'}
          onClick={() => {
            setSelectedProgramId('new');
          }}
        >
          Create Program
        </TabletButton>
      </Grid>
    </Grid>
  );
};

export { SideNavHeader };
