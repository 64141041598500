import React from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { unpackToTime } from '@campfire/hot-date';
import { ChevronRight, Close, WarningRounded } from '@material-ui/icons';
import { ConflictingRosterType } from '../useGetConflictingRoster';
import { PopUpWindow } from '../../../../common/PopUpWindow';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface ConflictingDialogProps {
  conflictRosters: ConflictingRosterType;
  onCancel: () => void;
  onContinue: () => void;
}

export function ConflictingDialog(props: ConflictingDialogProps) {
  const { conflictRosters, onCancel, onContinue } = props;
  const [revealRoster, setRevealRoster] = React.useState<number[]>([]);
  const { isMd, isMobile } = useCampfireTheme();

  const toggleRoster = (rosterIndex: number) => {
    if (revealRoster.includes(rosterIndex)) {
      setRevealRoster(revealRoster.filter((r) => r !== rosterIndex));
    } else {
      setRevealRoster(revealRoster.concat(rosterIndex));
    }
  };

  return (
    <PopUpWindow fixed transparent center>
      <Box
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 2.752269744873047px 13.761348724365234px 0px #00000029',
          width: '600px',
          borderRadius: '8px',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          padding={'20px 25px'}
          alignItems={'center'}
          borderBottom={'1px solid #e3e3e3'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <WarningRounded style={{ color: '#FBBF24' }} />
            <Typography style={{ fontWeight: 700, fontSize: '20px', paddingLeft: '15px' }}>Roster conflict</Typography>
          </Box>
          <IconButton onClick={onCancel}>
            <Close style={{ opacity: '54%' }} />
          </IconButton>
        </Box>
        <Box padding={'20px 25px'}>
          <Typography>
            There are conflicting rosters for this volunteer. See activity and session details below.
          </Typography>
          <List>
            {conflictRosters.map((roster, rosterIndex) => (
              <React.Fragment key={roster.activityId}>
                <ListItem>
                  <ListItemText>{roster.activityName}</ListItemText>
                  <ListItemIcon onClick={() => toggleRoster(rosterIndex)}>
                    <ChevronRight style={{ rotate: '270deg' }} />
                  </ListItemIcon>
                </ListItem>
                <Collapse in={revealRoster.includes(rosterIndex)} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding style={{ paddingLeft: '1rem' }}>
                    {roster.conflictingSessions.map((session) => (
                      <ListItem button key={session.sessionId} disabled style={{ opacity: 1 }}>
                        <ListItemText
                          primary={session.name}
                          secondary={`${unpackToTime(session.startTime)
                            .toFormat('h:mm a')
                            .toLowerCase()}-${unpackToTime(session.endTime)
                            .toFormat('h:mm a')
                            .toLowerCase()}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} padding={'0px 25px 30px 25px'}>
          <Button
            style={{
              boxSizing: 'border-box',
              borderRadius: '100px',
              textTransform: 'none',
              color: '#646464',
              marginRight: '10px',
              padding: !isMobile || !isMd ? '7px 12px' : '7px 3px',
              minWidth: '45px',
              width: '25%',
              backgroundColor: '#DCDCDC',
            }}
            onClick={onCancel}
          >
            <Typography
              style={{
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '19px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                padding: '5px',
                display: isMobile || isMd ? 'none' : 'inherit',
              }}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            style={{
              boxSizing: 'border-box',
              borderRadius: '100px',
              textTransform: 'none',
              color: '#ffffff',
              marginRight: '10px',
              padding: !isMobile || !isMd ? '7px 12px' : '7px 3px',
              minWidth: '45px',
              width: '65%',
              backgroundColor: '#796FFF',
            }}
            onClick={onContinue}
          >
            <Typography
              style={{
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '19px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                padding: '5px',
                display: isMobile || isMd ? 'none' : 'inherit',
              }}
            >
              Continue with conflict
            </Typography>
          </Button>
        </Box>
      </Box>
    </PopUpWindow>
  );
}
