import React, { useRef } from 'react';
import { Box, Button, IconButton, Theme, Typography, Badge } from '@material-ui/core';
import { Cancel, Check, Close, Edit, KeyboardArrowDown, PostAdd, InsertDriveFile, Print } from '@material-ui/icons';
import { unpackToTime } from '@campfire/hot-date';
import ReactToPrint from 'react-to-print';
import pluralize from 'pluralize';
import { DateTime } from 'luxon';
import { StringParam, useQueryParam } from 'use-query-params';
import { createStyles, makeStyles } from '@material-ui/styles';

import { TooltipMenu } from '../../../../../common/tooltip-menu';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { RosterTableForPrint } from '../../main-section/roster/roster-table/RosterTable';
import { RosterContext } from '../RosterContext';
import { RosterByDateType } from '../helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      border: 0,
      textAlign: 'center',
    },
    weekDay: {
      color: theme.color.grey.neutralBrand900,
      fontSize: '12px',
      fontWeight: 400,
    },
    monthDay: {
      color: theme.color.grey.neutralBrand900,
      fontSize: '16px',
      fontWeight: 900,
    },
    session: {
      color: theme.color.grey.neutralBrand300,
      fontSize: '14px',
    },
    time: {
      color: theme.color.grey.neutralBrand300,
      fontSize: '12px',
    },
    cancelledButton: {
      color: theme.color.card.cancelled,
      marginTop: '8px',
      marginBottom: '8px',
      textTransform: 'none',
    },
    cancelled: {
      color: theme.alert.red.medium,
      display: 'flex',
      alignItems: 'center',
      paddingTop: '10px',
      justifyContent: 'center',
      '& p': {
        fontSize: '14px',
      },
      '& svg': {
        fontSize: '16px',
      },
    },
    reactive: {
      color: theme.alert.blue.extraLight,
      marginTop: '8px',
      marginBottom: '8px',
      textTransform: 'none',
    },
    edit: {
      color: theme.alert.blue.extraLight,
      display: 'flex',
      alignItems: 'center',
      paddingTop: '10px',
      justifyContent: 'center',
      '& p': {
        fontSize: '14px',
      },
      '& svg': {
        fontSize: '16px',
      },
      textTransform: 'none',
    },
  })
);

interface RosterDateProps {
  date: DateTime;
  startTime: string;
  endTime: string;
  numberOfSessions: number;
  isRosterCancelled: boolean;
  isRosterTemplated?: boolean;
  onCancelRoster: () => void;
  onRestoreRoster: () => void;
  onEdit: () => void;
  onShowRosterNotes: (date: DateTime) => void;
  hasRosterNotes: boolean;
  roster: RosterByDateType;
}

export function RosterDate(props: RosterDateProps) {
  const {
    date,
    startTime,
    endTime,
    numberOfSessions,
    isRosterCancelled,
    onCancelRoster,
    onRestoreRoster,
    isRosterTemplated,
    onEdit,
    onShowRosterNotes,
    hasRosterNotes,
    roster,
  } = props;
  const [sortVolsRowBy] = useQueryParam('sortVolsRowBy', StringParam);
  const { activity, orgLogo } = React.useContext(RosterContext);

  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const printRef = useRef<HTMLDivElement>(null);
  const onCancel = () => {
    onCancelRoster();
    setTooltipOpen(false);
  };
  const onRestore = () => {
    onRestoreRoster();
    setTooltipOpen(false);
  };

  const showRosterNotes = () => {
    onShowRosterNotes(date);
    setTooltipOpen(false);
  };

  const { theme } = useCampfireTheme();

  const volunteerRosters = roster ? Object.keys(roster.volunteers).map((key) => roster.volunteers[key]) : [];

  if (!activity) {
    return null;
  }

  const sessionsData = activity.sessions.map((s) => {
    if (volunteerRosters && s.maxVolunteers) {
      const attending: any[] = volunteerRosters.flatMap((dr) => {
        const attendingLower = dr?.publishedSessionIds?.filter((ps) => {
          return ps === s.sessionId;
        });
        return attendingLower;
      });
      return {
        ...s,
        isMaxed: attending.length >= s.maxVolunteers,
      };
    }
    return s;
  });

  return (
    <Box>
      <Typography className={classes.weekDay}>{date.weekdayLong}</Typography>
      <Box display='flex' alignItems='flex-end' position='relative' justifyContent='center'>
        <Typography className={classes.monthDay}>{date.toFormat('dd LLL')}</Typography>
        <TooltipMenu
          open={tooltipOpen}
          onClose={() => {
            setTooltipOpen(false);
          }}
          title={
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              {isRosterTemplated ? (
                <Button onClick={onEdit} startIcon={<Edit />} className={classes.edit}>
                  Edit roster
                </Button>
              ) : null}
              {isRosterCancelled ? (
                <Button onClick={onRestore} className={classes.reactive} startIcon={<Check />}>
                  Reactivate
                </Button>
              ) : (
                <Button className={classes.cancelledButton} startIcon={<Close />} onClick={onCancel}>
                  Cancel Roster
                </Button>
              )}
              <Button className={classes.edit} onClick={showRosterNotes} startIcon={<PostAdd />}>
                {hasRosterNotes ? 'Edit' : 'Add'} roster note
              </Button>
              <ReactToPrint
                pageStyle='@page { size: landscape; }'
                trigger={() => (
                  <Button
                    className={classes.edit}
                    style={{ width: '100%', justifyContent: 'flex-start' }}
                    startIcon={<Print />}
                  >
                    Print
                  </Button>
                )}
                content={() => printRef?.current}
              />
            </Box>
          }
        >
          <IconButton style={{ padding: '4px', position: 'absolute', top: 0 }} onClick={() => setTooltipOpen(true)}>
            <KeyboardArrowDown />
          </IconButton>
        </TooltipMenu>
      </Box>
      {isRosterCancelled ? (
        <Box className={classes.cancelled}>
          <Cancel />
          <Typography style={{ marginLeft: '8px' }}>Cancelled</Typography>
        </Box>
      ) : (
        <React.Fragment>
          <Typography className={classes.session}>{pluralize('session', numberOfSessions, true)}</Typography>
          <Typography className={classes.time}>
            {startTime &&
              unpackToTime(startTime)
                .toFormat('h:mm a')
                .toLowerCase()}
            {endTime &&
              ` - ${unpackToTime(endTime)
                .toFormat('h:mm a')
                .toLowerCase()}`}
          </Typography>
        </React.Fragment>
      )}
      {hasRosterNotes ? (
        <Badge
          onClick={showRosterNotes}
          style={{
            backgroundColor: theme.color.grey.neutral200,
            color: '#ffffff',
            padding: '6px',
            borderRadius: '3px',
            height: 'fit-content',
            marginTop: '7px',
            cursor: 'pointer',
          }}
        >
          <InsertDriveFile style={{ fontSize: '16px' }} />
          <Typography style={{ fontSize: '12px', paddingLeft: '4px', fontWeight: 500, paddingRight: '2px' }}>
            Note Added
          </Typography>
        </Badge>
      ) : null}

      <div style={{ display: 'none' }}>
        <div ref={printRef}>
          <RosterTableForPrint
            key={`${activity.activityId}-${date.millisecond}`}
            activityName={activity.name}
            orgLogo={orgLogo}
            onChangeAvailability={() => {}}
            sessions={sessionsData}
            draftRosters={volunteerRosters}
            onClickRosterStatus={() => {}}
            isRosterCancelled={isRosterCancelled}
            isRosterCompleted={false}
            onSubmitReport={() => {}}
            publishedRoster={roster?.publishedRoster}
            activityDate={date.toFormat('yyyy-MM-dd')}
            sortBy={sortVolsRowBy}
            sortedVolunteerIdsByEnrolmentDate={[]}
            sessionNotes={[]}
            onChangeSessionNote={() => {}}
          />
        </div>
      </div>
    </Box>
  );
}
