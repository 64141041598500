import { unpackToTime } from '@campfire/hot-date';
import { DateTime } from 'luxon';

interface ActionableActivity {
  activityId: string;
  name: string;
}

interface ActionableActivityWithTime extends ActionableActivity {
  startTime: DateTime | string;
  endTime: DateTime | string;
}

export function getShortDisplayName(activity: ActionableActivity): string {
  return `${activity.name}`;
}

export function getDisplayNameWithTime(activity: ActionableActivityWithTime): string {
  const startTime = typeof activity.startTime === 'string' ? activity.startTime : activity.startTime !== null ? activity.startTime.toISODate() : null;
  const endTime = typeof activity.endTime === 'string' ? activity.endTime : activity.endTime !== null ? activity.endTime.toISODate() : null;

  return `${activity.name}, (${getDisplayTimeSchedule(startTime, endTime)})`;
}

export const getDisplayTimeSchedule = (startTime: Date | string | null, endTime: Date | string | null): string => {
  // TODO: why Date and string ?? two different types??? -BS
  if (!startTime && endTime)
    return `Ends at ${
      typeof endTime === 'string'
        ? unpackToTime(endTime)
            .toFormat('h:mm a')
            .toLowerCase()
        : DateTime.fromJSDate(endTime)
            .toFormat('h:mm a')
            .toLowerCase()
    }`;
  if (startTime && !endTime)
    return `Starts at ${
      typeof startTime === 'string'
        ? unpackToTime(startTime)
            .toFormat('h:mm a')
            .toLowerCase()
        : DateTime.fromJSDate(startTime)
            .toFormat('h:mm a')
            .toLowerCase()
    }`;
  if (startTime && endTime)
    return `${
      typeof startTime === 'string'
        ? unpackToTime(startTime)
            .toFormat('h:mm a')
            .toLowerCase()
        : DateTime.fromJSDate(startTime)
            .toFormat('h:mm a')
            .toLowerCase()
    } -  ${
      typeof endTime === 'string'
        ? unpackToTime(endTime)
            .toFormat('h:mm a')
            .toLowerCase()
        : DateTime.fromJSDate(endTime)
            .toFormat('h:mm a')
            .toLowerCase()
    }`;
  return 'No set time';
};
