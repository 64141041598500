import { History } from 'history';
import { ErrorInfo } from 'react';
import { match as ReactRouterMatch } from 'react-router';
import { SessionContextInterface } from '../auth/SessionContext';
import { CampfireVersionContextInterface } from '../config/CampfireVersionContext';

const generateLogMessage = (
  error: Error,
  errorInfo: ErrorInfo,
  history: History,
  match: ReactRouterMatch,
  versionContext: CampfireVersionContextInterface,
  sessionContext?: SessionContextInterface,
  customMessage?: string
) => {
  const errorBlock = {
    error: {
      name: error.name,
      message: error.message,
      stack: error.stack,
    },
    errorInfo: {
      componentStack: errorInfo.componentStack,
    },
    customMessage,
    context: sessionContext
      ? {
          user: {
            email: sessionContext.user && sessionContext.user.email,
            userId: sessionContext.user && sessionContext.user.userIdentity && sessionContext.user.userIdentity.userId,
          },
        }
      : undefined,
    router: {
      history: {
        length: history.length,
        location: history.location,
      },
      match: match,
    },
    version: {
      client: versionContext.clientVersion,
      server: versionContext.serverVersion,
    },
  };
  return JSON.stringify(errorBlock);
};

export type LogLevel = 'emergency' | 'alert' | 'critical' | 'error' | 'warning' | 'notice' | 'info' | 'debug';

type LoggerConfig = {
  level?: LogLevel;
  url: string;
  versionContext: CampfireVersionContextInterface;
  sessionContext?: SessionContextInterface;
  customMessage?: string;
};

const writeMessageToLog = (level: LogLevel, token: string, url: string, message: string) => {
  sendLog(url, token, { level, message });
};

type ErrorLoggerConfig = {
  error: Error;
  errorInfo: ErrorInfo;
  history: History;
  match: ReactRouterMatch;
} & LoggerConfig;

const writeErrorToLog = (options: ErrorLoggerConfig) => {
  const { level, customMessage } = options;
  const token = options.sessionContext ? options.sessionContext.user?.token : undefined;
  const message = generateLogMessage(
    options.error,
    options.errorInfo,
    options.history,
    options.match,
    options.versionContext,
    options.sessionContext,
    customMessage
  );

  if (options.versionContext.updateAvailable) {
    // Don't fire error messages if new version is available, user should refresh
    return;
  }

  if (!token) {
    sendLogWithoutAuth(options.url, { level: level || 'error', message });
    return;
  }
  sendLog(options.url, token, { level: level || 'error', message });
};

type LogBody = {
  level: LogLevel;
  message: string;
};

const sendLog = (url: string, token: string, body: LogBody) => {
  fetch(`${url}/im/log`, {
    method: 'post',
    headers: {
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(body),
  }).catch((err) => {
    console.warn(`Unable to log to server: ${err.message}`);
  });
};

const sendLogWithoutAuth = (url: string, body: LogBody) => {
  fetch(`${url}/im/log`, {
    method: 'post',
    body: JSON.stringify(body),
  }).catch((err) => {
    console.warn(`Unable to log to server: ${err.message}`);
  });
};

export { generateLogMessage };
export { writeMessageToLog };
export { writeErrorToLog };
