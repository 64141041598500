import React from 'react';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { uniq, upperFirst } from 'lodash';
import { COLORS } from './colors';
import { GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents_widget_VOLUNTEER_ChartWidgetType as IChartWidget } from '../../dashboard-widget-setting/__generated__/GetDashboardPreference';
import { useQueryChart } from './useQueryChart';

type DashboardBarChartProps = {
  widget: IChartWidget;
};

export function DashboardAreaChart(props: DashboardBarChartProps) {
  const { widget } = props;
  const { group } = widget.configuration;

  const data = useQueryChart(widget);
  if (!data) {
    return null;
  }

  if (group === null) {
    const keys = uniq(
      data.flatMap((datum: { [key: string]: number }) => Object.keys(datum).filter((key) => key !== 'date'))
    ) as string[];
    return (
      <ResponsiveContainer width='100%' height='99%'>
        <AreaChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' />
          <YAxis />
          <Tooltip />
          <Legend />
          {keys.map((key: string, index: number) => (
            <Area
              type='monotone'
              key={key}
              stackId='1'
              dataKey={key}
              name={upperFirst(key)}
              stroke={COLORS[index]}
              fill={COLORS[index]}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    );
  }

  if (!data.data) {
    return null;
  }

  const keys = uniq(
    data.data.flatMap((datum: { [key: string]: number }) => Object.keys(datum).filter((key) => key !== 'date'))
  ) as string[];
  return (
    <ResponsiveContainer width='100%' height='99%'>
      <AreaChart
        data={data.data}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <XAxis dataKey='date' />
        <YAxis />
        <Tooltip />
        <Legend />
        {keys.map((key: string, index: number) => (
          <Area
            type='monotone'
            key={key}
            stackId='1'
            dataKey={key}
            name={upperFirst(key)}
            stroke={COLORS[index]}
            fill={COLORS[index]}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}
