import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useCommonStyles } from '../commonStyles';
import { GoBack } from '../GoBack';

type AppSettingWrapperProps = {
  name: string;
  children: React.ReactElement | React.ReactElement[];
};

export function AppSettingWrapper({ children, name }: AppSettingWrapperProps) {
  const commonStyles = useCommonStyles();

  return (
    <Box className={commonStyles.container}>
      <GoBack />
      <Box className={commonStyles.heading}>
        <Typography variant='h2' className={commonStyles.headingTitle}>
          Integrations Setup - {name}
        </Typography>
      </Box>

      {children}
    </Box>
  );
}
