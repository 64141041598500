import { unpackToDate } from '@campfire/hot-date';
import { Box } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { VolunteerDatabaseActions, VolunteerDatabaseState } from './volunteer-database-model.gql';
import { VolunteerDatabaseSection } from './VolunteerDatabaseSection';
import { VolunteerDatabaseTableOptionsMenu } from './VolunteerDatabaseTableOptionsMenu';
import { GetVolunteerDatabaseVariables } from './__generated__/GetVolunteerDatabase';

interface Props {
  setSelectedVolunteerId: (x: string) => void;
  volunteerDatabaseState: VolunteerDatabaseState;
  volunteerDatabaseActions: VolunteerDatabaseActions;
  reloadDatabase?: () => void;
  updateRequestVariables: (x: GetVolunteerDatabaseVariables) => void;
  requestVariables: GetVolunteerDatabaseVariables;
  totalVolunteers: number;
}

const VolunteerDatabaseMobileTable = (props: Props) => {
  const {
    setSelectedVolunteerId,
    volunteerDatabaseState,
    volunteerDatabaseActions,
    reloadDatabase,
    updateRequestVariables,
    requestVariables,
    totalVolunteers,
  } = props;
  const [activeVolsFilter, setActiveVolsFilter] = useState(true);
  const volunteersArray = Object.keys(volunteerDatabaseState).map((key) => volunteerDatabaseState[key]);
  const volunteers = useMemo(() => {
    return volunteersArray
      .filter((volunteer) => {
        return activeVolsFilter || volunteer.dateDeactivated === null;
      })
      .sort((a, b) => {
        return unpackToDate(a.dateCreated) < unpackToDate(b.dateCreated) ? 1 : -1;
      });
  }, [volunteersArray, activeVolsFilter]);

  return (
    <Box mt={1} overflow='auto'>
      <VolunteerDatabaseTableOptionsMenu
        setActiveVolsFilter={setActiveVolsFilter}
        activeVolsFilter={activeVolsFilter}
      />
      <VolunteerDatabaseSection
        setSelectedVolunteerId={setSelectedVolunteerId}
        volunteers={volunteers}
        volunteerDatabaseActions={volunteerDatabaseActions}
        showDisabledAndInactiveVolunteers={activeVolsFilter}
        reloadDatabase={reloadDatabase}
        updateRequestVariables={updateRequestVariables}
        requestVariables={requestVariables}
        totalVolunteers={totalVolunteers}
      />
    </Box>
  );
};

export { VolunteerDatabaseMobileTable };
