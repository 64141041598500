import React from 'react';
import { List, ListItem, Grid, Typography } from '@material-ui/core';
import { CreateActivityReportTypeV2_items as CreateActivityReportTypeItem } from '../__generated__/CreateActivityReportTypeV2';

interface Props {
  fields?: Array<CreateActivityReportTypeItem>;
  optional?: boolean;
}
const FieldsListV2 = (props: Props) => {
  const { fields, optional } = props;
  return fields ? (
    <List>
      {fields.length === 0 ? (
        <ListItem>
          <Typography variant='body1' color='textSecondary' display='inline'>
            {`There are no ${optional ? 'optional' : 'required'} fields defined for this model`}
          </Typography>
        </ListItem>
      ) : null}
      {fields?.map((item) => {
        if (item.__typename !== 'VOLUNTEER_ReportTypeFieldType') return null;
        return (
          <ListItem key={item.reportTypeItemId}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant='body1' color='textSecondary' style={{ fontWeight: 'bold' }}>
                  {item.field.name}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='body1' color='textSecondary'>
                  {item.field.description}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  ) : null;
};

export { FieldsListV2 };
