import React from 'react';
import { unpackToDateTime } from '@campfire/hot-date';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid';
import { CommonRequiringActionWidgetProps } from '../../../shared';
import { useCampfireQuery } from '../../../../../../../../../global/network/useCampfireQuery';
import { VolunteerProfile } from '../../../../../../../../../common/AvatarName';
import { GET_TABLE_WIDGET_AWAITING_APPROVAL_TASKS } from './task-awaiting-approval-table-model.gql';
import {
  GetTableWidgetAwaitingApprovalTasks,
  GetTableWidgetAwaitingApprovalTasksVariables,
} from './__generated__/GetTableWidgetAwaitingApprovalTasks';
import { WidgetTypes } from '../../../../dashboard-widget-setting/dashboard-widget-model';

export const TaskAwaitingApprovalTableWidget = (props: CommonRequiringActionWidgetProps) => {
  const { filters, range } = props;
  const { programIds, activityIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };

  const { data, loading } = useCampfireQuery<
    GetTableWidgetAwaitingApprovalTasks,
    GetTableWidgetAwaitingApprovalTasksVariables
  >(GET_TABLE_WIDGET_AWAITING_APPROVAL_TASKS, {
    options: {
      variables: {
        status: 'pending',
        programIds: programIds.length > 0 ? programIds : null,
        activityIds: activityIds.length > 0 ? activityIds : null,
        range: range,
        widgetType: WidgetTypes.TABLE,
      },
    },
  });

  const tasksAwaitingApproval =
    data?.vm.completedTasks.map((completedTask) => ({
      id: completedTask.completedTaskId,
      profile: completedTask.profile,
      title: completedTask.task.title,
      cake: completedTask.task.cake.title,
      dateSubmitted: unpackToDateTime(completedTask.dateSubmitted).toFormat('dd/MM/y'),
      dateLastUpdated: completedTask.dateLastUpdated
        ? unpackToDateTime(completedTask.dateLastUpdated).toFormat('dd/MM/y')
        : null,
    })) || [];

  const columns: GridColumns = [
    {
      field: 'volunteerName',
      headerName: 'Volunteer',
      flex: 1,
      minWidth: 180,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <VolunteerProfile inTable {...row.profile} name={`${row.profile.preferredName} ${row.profile.lastName}`} />
        );
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }: any) => {
        return (
          <Tooltip title={row.title}>
            <Typography noWrap style={{ fontSize: 'inherit' }}>
              {row.title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'cake',
      headerName: 'Category',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
      renderCell: ({ row }: any) => {
        return (
          <Tooltip title={row.cake}>
            <Typography noWrap style={{ fontSize: 'inherit' }}>
              {row.cake}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'dateSubmitted',
      headerName: 'Date Submitted',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 170,
    },
    {
      field: 'dateLastUpdated',
      headerName: 'Date Last Updated',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 190,
    },
  ];

  return (
    <Box width='100%' height='100%'>
      <DataGrid columns={columns} rows={tasksAwaitingApproval} loading={loading} autoPageSize disableSelectionOnClick />
    </Box>
  );
};
