import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';
import Gradient from 'javascript-color-gradient';
import qs from 'qs';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { NumberWidgetProps, getEndDateFromRange, getStartDateFromRange } from '../shared';
import { TargetNumberConfiguration } from '../../dashboard-widget-setting/__generated__/TargetNumberConfiguration';
import { useApiUrl } from '../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../global/network/useCampfireFetch';

const gradientArray = new Gradient()
  .setColorGradient('#E55555', '#FF991A', '#FABE4D', '#FFE242', '#ABDC40', '#7DD268')
  .setMidpoint(100);

type TargetNumberWidgetProps = NumberWidgetProps & {
  configuration: TargetNumberConfiguration;
};

export const TargetNumberWidget = (props: TargetNumberWidgetProps) => {
  const { theme } = useCampfireTheme();
  const { tag, configuration, name, filters } = props;
  const [value, setValue] = React.useState(0);
  const { range, target } = configuration;
  const apiUrl = useApiUrl();
  const campfireQuery = useCampfireFetch<{ count: number }>({ defer: true });
  const startDate = React.useMemo(() => getStartDateFromRange(range), [range]);
  const endDate = React.useMemo(() => getEndDateFromRange(range), [range]);

  React.useEffect(() => {
    campfireQuery
      .run({
        url: `${apiUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/total`,
        params: {
          startDate: startDate,
          endDate: endDate,
          filters: JSON.parse(filters),
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets', encode: false });
        },
      })
      .then((response) => response?.data?.data && setValue(response.data.data.count));
  }, [startDate, endDate, filters]);

  const currentColor = gradientArray.getColor(Math.min(Math.max(Math.round((value / target) * 100), 1), 100));
  const data = [
    { name: 'Current', value: value, color: currentColor },
    { name: 'Missing', value: Math.max(target - value, 0), color: '#f2f2f2' },
  ];

  const ratio = `${Math.round((value * 100) / target)}%`;
  const [container, setContainer] = React.useState({ width: 0, height: 0 });

  const onResize = (width: number, height: number) => {
    setContainer({ width, height });
  };

  const outerRadius = Math.min(container.height, container.width / 2) - 22;
  const innerRadius = outerRadius * 0.8;
  const cy = container.height > container.width ? container.height * 0.8 : container.height * 0.9;

  return (
    <React.Fragment>
      <ResponsiveContainer width='100%' height='56%' onResize={onResize}>
        <PieChart width={container.width}>
          <Pie
            cy={cy}
            data={data}
            startAngle={180}
            endAngle={0}
            innerRadius={innerRadius}
            outerRadius={Math.min(container.height, container.width / 2) - 16}
            dataKey='value'
            stroke='none'
          >
            {data.map((entry) => (
              <Cell key={entry.color} fill={entry.color} />
            ))}
            <Label
              position='center'
              fill='#000'
              fontSize='24px'
              fontWeight={700}
              style={{ transform: 'translateY(-10%)', color: theme.color.grey.neutral500 }}
            >
              {ratio}
            </Label>
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Box display='flex' justifyContent='space-evenly' width='100%' paddingTop={'1em'}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography style={{ fontWeight: 700, color: theme.color.grey.neutral400, fontSize: '1.2em' }}>
            {value}
          </Typography>
          <Typography style={{ fontSize: '12px', color: theme.color.grey.neutral300 }}>Current</Typography>
        </Box>
        <Box>
          <Divider orientation='vertical' style={{ backgroundColor: theme.color.grey.neutralBrand200 }} />
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography style={{ fontWeight: 700, color: theme.color.grey.neutral400, fontSize: '1.2em' }}>
            {target}
          </Typography>
          <Typography style={{ fontSize: '12px', color: theme.color.grey.neutral300 }}>Target</Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
