import ReactGA from 'react-ga';
import { getApiUrlFromWindowLocation } from '../config/get-api-url';
import { isDev } from '../config/is-dev';

const trackingId = 'UA-149024912-2';
const debugMode = false;

const init = () => {
    try {
        const clientId = getApiUrlFromWindowLocation();
        ReactGA.initialize(trackingId, {
            debug: debugMode,
            gaOptions: { clientId },
            titleCase: false,
            alwaysSendToDefaultTracker: true,
        });
    } catch (err) {
        console.error(isDev ? err : '');
    }
};

const setUser = (userId: string, name: string, email: string, privLevel: string) => {
    try {
        ReactGA.set({ userId });
        // eslint-disable-next-line no-undef
        FS.identify(userId, {
            displayName: name,
            email: email,
            // eslint-disable-next-line camelcase, @typescript-eslint/camelcase
            privLevel_str: privLevel,
        });
    } catch (err) {
        console.error(isDev ? err : '');
    }

};

const clearUser = () => {};

const viewPage = (path: string) => {
    try {
        ReactGA.pageview(path);
    } catch (err) {
        console.error(isDev ? err : '');
    }

};

const viewModal = (name: string) => {
    try {
        return ReactGA.modalview(name);
    } catch (err) {
        console.error(isDev ? err : '');
        return null;
    }
}

const trackEvent = (eventArgs: Omit<ReactGA.EventArgs, 'category'>) => {
    try {
        ReactGA.event({ category: 'user', ...eventArgs });
    } catch (err) {
        console.error(isDev ? err : '');
    }
};

const voidFunction = () => {};

export const AnalyticsService = isDev
  ? {
      init: voidFunction,
      setUser: voidFunction,
      clearUser: voidFunction,
      viewPage: voidFunction,
      viewModal: voidFunction,
      trackEvent: voidFunction,
    }
  : { init, setUser, clearUser, viewPage, viewModal, trackEvent };
