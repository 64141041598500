import { unpackToDate, unpackToTime, unpackToDateTime } from '@campfire/hot-date';

import { DateTime } from 'luxon';

export const filterByQuery = (query?: string) => ({ name }: { name: string }) =>
  !query || name.toLowerCase().includes(query.toLowerCase());

export const filterByActivityType = (activityType?: string) => ({ __typename }: { __typename: string }) =>
  !activityType ||
  activityType === 'all' ||
  (activityType === 'regular' && __typename === 'VOLUNTEER_RecurringActivityType') ||
  (activityType === 'flexible' && __typename === 'VOLUNTEER_NonRecurringActivityType');

export const filterByDuration = (duration?: string) => ({
  startTime,
  endTime,
}: {
  startTime: string;
  endTime: string;
}) => {
  if (!startTime || !endTime || !duration || duration === 'any') {
    return true;
  }

  const durationNumber = parseInt(duration.split('')[0], 10);
  const activityDuration = unpackToTime(endTime).diff(unpackToTime(startTime), ['hours']).hours;

  if (duration.includes('plus')) {
    return activityDuration > durationNumber;
  }
  return activityDuration <= durationNumber;
};

export const filterByLocation = (location?: string) => ({ activityLocation }: { activityLocation: any }) => {
  if (!location || location === 'all') {
    return true;
  }

  const isRemote = !activityLocation;

  if (isRemote) {
    return location === 'remote';
  }

  return location === 'local';
};

export const filterByRosterType = (rosterType?: string) => ({ hasOpenRoster }: { hasOpenRoster: boolean }) => {
  if (!rosterType || rosterType === 'all') {
    return true;
  }

  if (hasOpenRoster) {
    return rosterType === 'open';
  }

  return rosterType === 'managed';
};

export const filterByActivityStatus = (activityStatus?: string) => ({
  endDate,
  isActive,
  isSuspended,
  closedActivity,
}: {
  endDate: string;
  isActive: boolean;
  isSuspended: boolean;
  closedActivity: any;
}) => {
  if (!activityStatus || activityStatus === 'all') {
    return true;
  }

  if (activityStatus === 'ended') {
    return endDate && unpackToDate(endDate) < DateTime.local();
  }

  if (activityStatus === 'suspended') {
    return isSuspended;
  }

  if (activityStatus === 'closed') {
    return Boolean(closedActivity);
  }

  return isActive;
};

export const filterByDays = (days?: string[]) => ({ activityDate }: { activityDate: string }) => {
  if (!days || days.length === 0) {
    return true;
  }

  return days.includes(
    unpackToDate(activityDate)
      .weekdayShort.toUpperCase()
      .slice(0, 2)
  );
};

export const filterByStartTime = (time?: string) => ({ startTime }: { startTime: string }) => {
  if (!time) return true;
  return startTime && time ? unpackToDateTime(startTime) >= unpackToDateTime(time) : false;
};

export const filterByEndTime = (time?: string) => ({ endTime }: { endTime: string }) => {
  if (!time) return true;
  return endTime && time ? unpackToDateTime(endTime) <= unpackToDateTime(time) : false;
};

export const filterActivity = (filters: any) => {
  const { activityType, duration, location, activityStatus, rosterType, days, startTime, endTime, q } = filters;

  return (activity: any) => {
    return (
      filterByQuery(q)(activity) &&
      filterByActivityType(activityType)(activity) &&
      filterByDuration(duration)(activity) &&
      filterByLocation(location)(activity) &&
      filterByActivityStatus(activityStatus)(activity) &&
      filterByRosterType(rosterType)(activity) &&
      filterByDays(days)(activity) &&
      filterByStartTime(startTime)(activity) &&
      filterByEndTime(endTime)(activity)
    );
  };
};
