import { gql } from '@apollo/client';

export const GET_ADMIN_CONSOLE_BULLETIN_SETTINGS = gql`
  query GetAdminConsoleBulletinSettings {
    vm {
      bulletinAllowManagerAction
      bulletinAllowComment
    }
  }
`;
