import { gql } from '@apollo/client';

const BASE_RECURRENCE_FRAGMENT = gql`
  fragment BaseRecurrence on RecurrenceType {
    __typename
    startDate
    endDate
    humanReadable
  }
`;

const SINGLE_RECURRENCE_FRAGMENT = gql`
  fragment SingleRecurrence on SingleRecurrenceType {
    ...BaseRecurrence
  }

  ${BASE_RECURRENCE_FRAGMENT}
`;

const DAILY_RECURRENCE_FRAGMENT = gql`
  fragment DailyRecurrence on DailyRecurrenceType {
    ...BaseRecurrence
    dayInterval
  }

  ${BASE_RECURRENCE_FRAGMENT}
`;

const WEEKLY_RECURRENCE_FRAGMENT = gql`
  fragment WeeklyRecurrence on WeeklyRecurrenceType {
    ...BaseRecurrence
    days
    weekInterval
  }

  ${BASE_RECURRENCE_FRAGMENT}
`;

const MONTHLY_NTH_DAY_RECURRENCE_FRAGMENT = gql`
  fragment MonthlyNthDayRecurrence on MonthlyNthDayRecurrenceType {
    ...BaseRecurrence
    n
    day
    monthInterval
  }

  ${BASE_RECURRENCE_FRAGMENT}
`;

const MONTHLY_BY_MONTH_DAY_RECURRENCE_FRAGMENT = gql`
  fragment MonthlyByMonthDayRecurrence on MonthlyByMonthDayRecurrenceType {
    ...BaseRecurrence
    monthDay
    monthInterval
  }

  ${BASE_RECURRENCE_FRAGMENT}
`;

const YEARLY_RECURRENCE_FRAGMENT = gql`
  fragment YearlyRecurrence on YearlyRecurrenceType {
    ...BaseRecurrence
    yearInterval
  }

  ${BASE_RECURRENCE_FRAGMENT}
`;

export const RECURRENCE_FRAGMENT = gql`
  fragment Recurrence on RecurrenceType {
    ... on SingleRecurrenceType {
      ...SingleRecurrence
    }
    ... on DailyRecurrenceType {
      ...DailyRecurrence
    }
    ... on WeeklyRecurrenceType {
      ...WeeklyRecurrence
    }
    ... on MonthlyNthDayRecurrenceType {
      ...MonthlyNthDayRecurrence
    }
    ... on MonthlyByMonthDayRecurrenceType {
      ...MonthlyByMonthDayRecurrence
    }
    ... on YearlyRecurrenceType {
      ...YearlyRecurrence
    }
  }

  ${SINGLE_RECURRENCE_FRAGMENT}
  ${DAILY_RECURRENCE_FRAGMENT}
  ${WEEKLY_RECURRENCE_FRAGMENT}
  ${MONTHLY_NTH_DAY_RECURRENCE_FRAGMENT}
  ${MONTHLY_BY_MONTH_DAY_RECURRENCE_FRAGMENT}
  ${YEARLY_RECURRENCE_FRAGMENT}
`;
