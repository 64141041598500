import { gql } from '@apollo/client';

export const BULLETIN_COMMENT = gql `
  query BulletinComments($bulletinId: String!) {
    vm {
      bulletin(bulletinId: $bulletinId) {
        reactions {
          reactionId
          emoji
          volunteer {
            volunteerId
          }
        }
        comments {
          commentId
          parentId
          content
          submittedDate
          removedDate
          attachments {
            attachmentId
            fileType
            url
            name
          }
          volunteer {
            volunteerId
            profile {
              preferredName
              lastName
              avatarUrl
            }
          }
          reactions {
            reactionId
            emoji
            volunteer {
              volunteerId
            }
          }
        }
      }
    }
  }
`
