import { encodeDate, formatDistanceDays } from '@campfire/hot-date';
import { Box, List, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useActivityApplicationFetch } from '../../../../../hooks/use-activity-application-fetch';
import {
  KeyValuedUpcomingRosters,
  MyElementsActivityReport,
  MyElementsAttendance,
} from './my-elements-upcoming-rosters-model.gql';
import { UpcomingRosterListItem } from './MyElementsUpcomingRostersList';
import { useUpcomingRostersFetch } from './useUpcomingRostersFetch';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';

interface UpcomingRostersDayDialogProps {
  date: DateTime | undefined;
  dailyUpcomingRosters: KeyValuedUpcomingRosters | undefined;
  open: boolean;
  close: () => void;
  closeEditDialog: () => void;
  refetch?: () => void;
  activityApplicationEditDialogOpen: boolean;
  setActivityApplicationEditDialogOpen: (value: boolean) => void;
  attendances: MyElementsAttendance[];
  activityReports: MyElementsActivityReport[];
}

export const UpcomingRostersDayDialog = (props: UpcomingRostersDayDialogProps) => {
  const {
    date,
    dailyUpcomingRosters,
    open,
    close,
    refetch,
    activityApplicationEditDialogOpen,
    setActivityApplicationEditDialogOpen,
    closeEditDialog,
    attendances,
    activityReports,
  } = props;
  const { createAvailability, createUnavailability, createAttendance, removeAttendance } = useUpcomingRostersFetch(
    refetch
  );
  const { isMobile } = useCampfireTheme();
  const { activityApplicationPostRequest } = useActivityApplicationFetch(refetch, closeEditDialog);

  const validRosters = useMemo(() => {
    if (!dailyUpcomingRosters || !date) return [];
    return dailyUpcomingRosters[date.toFormat('yyyy-MM-dd')] ?? [];
  }, [dailyUpcomingRosters]);

  return validRosters.length > 0 && date ? (
    <FullscreenDialog
      open={open && dailyUpcomingRosters !== undefined && date !== null}
      close={close}
      title={'Upcoming Roster'}
      onBackdropClick={close}
      fullScreen={isMobile}
    >
      <Box width={isMobile ? 'auto' : 500}>
        <Typography variant='h5'>{`${date.toFormat('cccc, d MMMM')} (${formatDistanceDays(date)})`}</Typography>
        <List>
          {validRosters.map((upcomingRoster) => {
            return (
              <UpcomingRosterListItem
                key={upcomingRoster.upcomingRosterId}
                isTooltipOpen={false}
                hideTooltip={() => console.log('Not supported')}
                upcomingRoster={upcomingRoster}
                createAvailability={createAvailability}
                createUnavailability={createUnavailability}
                createAttendance={createAttendance}
                removeAttendance={removeAttendance}
                dateStamp={encodeDate(upcomingRoster.activityDate)}
                activityApplicationPostRequest={activityApplicationPostRequest}
                activityApplicationEditDialogOpen={activityApplicationEditDialogOpen}
                setActivityApplicationEditDialogOpen={setActivityApplicationEditDialogOpen}
                attendances={attendances}
                activityReports={activityReports}
              />
            );
          })}
        </List>
      </Box>
    </FullscreenDialog>
  ) : null;
};
