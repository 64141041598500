import { DialogContent, DialogProps } from '@material-ui/core';
import React from 'react';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';
import { MyElementsNewsTabContent } from '../main-section/my-recent-news/MyElementsNewsTabContent';

type MobileMyElementsNewsDialogConfig = DialogProps & {
  handleClose: () => void;
};

export const MobileMyElementsNewsDialog = ({ open, handleClose }: MobileMyElementsNewsDialogConfig) => {
  return (
    <FullscreenDialog fullWidth title='News' fullScreen scroll='paper' open={open} close={() => handleClose()}>
      <DialogContent style={{ padding: 0, minHeight: '100%' }}>
        <MyElementsNewsTabContent />
      </DialogContent>
    </FullscreenDialog>
  );
};
