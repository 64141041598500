import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useVolunteerProfileActions } from '../../volunteer-profile-actions';

export const VolunteerCommonProfileRetireDialog = ({
  open,
  onClose,
  handleSuccess,
  volunteerId,
  fullName,
}: {
  open: boolean;
  onClose: () => void;
  handleSuccess: () => void;
  volunteerId: string;
  fullName: string;
}) => {
  const { runRetireVolunteer, runRetireVolunteerLoading } = useVolunteerProfileActions();
  const { theme } = useCampfireTheme();

  return volunteerId ? (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='retire-volunteer-dialog-title'
      aria-describedby='retire-volunteer-dialog-description'
    >
      <DialogTitle id='retire-volunteer-dialog-title'>{`Retire Volunteer?`}</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Box paddingLeft={2} paddingRight={2}>
          <DialogContentText>{fullName}</DialogContentText>
          <DialogContentText id='retire-volunteer-dialog-description'>
            {`Retired volunteers will be removed from all teams and upcoming rosters.`}
            <br />
            {`You can reactivate a retired volunteer at anytime, but they will not rejoin teams and rosters they have been removed from.`}
          </DialogContentText>
        </Box>
        <LinearProgressOverlay isLoading={runRetireVolunteerLoading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{`Cancel`}</Button>
        <Button
          onClick={() => runRetireVolunteer({ volunteerId, handleSuccess })}
          autoFocus
          disabled={runRetireVolunteerLoading}
          style={{
            color: theme.palette.error.main,
          }}
        >{`Retire Volunteer`}</Button>
      </DialogActions>
    </Dialog>
  ) : null;
};
