import React, { useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { Box } from '@material-ui/core';
import { AdminConsoleWidgetsActivityTypes } from './AdminConsoleEmbeddableWidgetsPanel';

type TableOptions = {
  filter: boolean;
  sort: boolean;
};

type TableColumns = {
  name: string;
  label: string;
  options: TableOptions;
};

interface FieldTableListProps {
  tableRows?: Array<AdminConsoleWidgetsActivityTypes>;
}

const AdminConsoleActivityMUIDatatable = ({ tableRows }: FieldTableListProps) => {
  const tableData: Array<{ [key: string]: any }> = useMemo(() => {
    if (!tableRows) return [];
    return tableRows;
  }, [tableRows]);

  const activityTableColumns: TableColumns[] = [
    {
      name: 'program',
      label: 'Program',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'activityName',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'activityID',
      label: 'Activity ID',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options: object = {
    selectableRowsHeader: false,
    selectableRows: 'multiple',
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
  };

  return (
    <Box margin='40px 15px 30px 15px'>
      <MUIDataTable title={'Activity IDs'} data={tableData} columns={activityTableColumns} options={options} />
    </Box>
  );
};

export { AdminConsoleActivityMUIDatatable };
