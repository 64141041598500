import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React, { Fragment, useRef, useState } from 'react';
import PrivilegeRules from '../../../../global/auth/PrivilegeRules';
import { useUser } from '../../../../global/auth/useUser';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { VolunteerDatabaseActions, VolunteerDatabaseVolunteer } from './volunteer-database-model.gql';
import { VolunteerDatabaseExitDialog } from './VolunteerDatabaseExitDialog';
import { VolunteerDatabaseReactivateDialog } from './VolunteerDatabaseReactivateDialog';
import { VolunteerDatabaseRetireDialog } from './VolunteerDatabaseRetireDialog';

export type VolunteerDatabaseActionMenuOption = 'retire' | 'flagAndExit' | 'reactivate';

const actionMenuOptionLabels = {
  retire: 'Retire',
  flagAndExit: 'Flag and exit',
  reactivate: 'Reactivate',
};

interface ActionableVolunteer {
  rosterStatus: string | null;
  programs: {
    programId: string;
  }[];
  dateDeactivated: any | null;
}

const getActionMenuOptions = (volunteer: ActionableVolunteer): Array<VolunteerDatabaseActionMenuOption> => {
  if (volunteer.rosterStatus === 'flagged') {
    return [];
  }

  const actions: Array<VolunteerDatabaseActionMenuOption> = [];
  const {
    user: { userIdentityService },
  } = useUser();

  const canFlag =
    userIdentityService.hasVmGlobalRule(PrivilegeRules.flagVolunteer) ||
    !!volunteer.programs.find((program) =>
      userIdentityService.hasRuleForProgram(program.programId, PrivilegeRules.flagVolunteer)
    );
  if (canFlag) actions.push('flagAndExit');

  if (!volunteer.dateDeactivated) {
    const canRetire =
      userIdentityService.hasVmGlobalRule(PrivilegeRules.retireVolunteer) ||
      !!volunteer.programs.find((program) =>
        userIdentityService.hasRuleForProgram(program.programId, PrivilegeRules.retireVolunteer)
      );
    if (canRetire) actions.push('retire');
  }

  if (volunteer.dateDeactivated) {
    const canUnretire =
      userIdentityService.hasVmGlobalRule(PrivilegeRules.unretireVolunteer) ||
      !!volunteer.programs.find((program) =>
        userIdentityService.hasRuleForProgram(program.programId, PrivilegeRules.unretireVolunteer)
      );
    if (canUnretire) actions.push('reactivate');
  }

  return actions;
};

export const VolunteerDatabaseActionMenu = ({
  volunteer,
  volunteerDatabaseActions,
  reloadDatabase,
}: {
  volunteer: VolunteerDatabaseVolunteer;
  volunteerDatabaseActions: VolunteerDatabaseActions;
  reloadDatabase?: () => void;
}) => {
  const [openActionMenu, setOpenActionMenu] = useState(false);
  const handleActionMenuOpen = () => setOpenActionMenu(true);
  const handleActionMenuClose = () => setOpenActionMenu(false);
  const actionMenuAnchorRef = useRef<HTMLButtonElement>(null);
  const [retireDialogOpen, setRetireDialogOpen] = useState(false);
  const [exitDialogOpen, setExitDialogOpen] = useState(false);
  const [reactivateDialogOpen, setReactivateDialogOpen] = useState(false);

  const actionMenuOptions = volunteer.profile.email !== 'support@volaby.org' ? getActionMenuOptions(volunteer) : [];

  const handleActionMenuItemClick = (option: VolunteerDatabaseActionMenuOption) => {
    handleActionMenuClose();
    switch (option) {
      case 'retire':
        setRetireDialogOpen(true);
        break;
      case 'flagAndExit':
        setExitDialogOpen(true);
        break;
      case 'reactivate':
        setReactivateDialogOpen(true);
        break;
      default:
        console.error('Unknown volunteer management action');
        break;
    }
  };

  const { theme } = useCampfireTheme();

  return (
    <Fragment>
      {actionMenuOptions.length ? (
        <Fragment>
          {!volunteer.dateDeactivated ? (
            <VolunteerDatabaseRetireDialog
              open={retireDialogOpen}
              onClose={() => setRetireDialogOpen(false)}
              volunteer={volunteer}
              retireVolunteer={volunteerDatabaseActions.retireVolunteer}
              reloadDatabase={reloadDatabase}
            />
          ) : null}

          <VolunteerDatabaseExitDialog
            open={exitDialogOpen}
            onClose={() => setExitDialogOpen(false)}
            volunteer={volunteer}
            exitVolunteer={volunteerDatabaseActions.exitVolunteer}
            reloadDatabase={reloadDatabase}
          />

          {volunteer.dateDeactivated ? (
            <>
              <VolunteerDatabaseReactivateDialog
                open={reactivateDialogOpen}
                onClose={() => setReactivateDialogOpen(false)}
                volunteer={volunteer}
                reactivateVolunteer={volunteerDatabaseActions.reactivateVolunteer}
                reloadDatabase={reloadDatabase}
              />
            </>
          ) : null}

          <IconButton
            color={openActionMenu ? 'primary' : undefined}
            style={{ background: openActionMenu ? theme.palette.action.hover : undefined }}
            onClick={handleActionMenuOpen}
            ref={actionMenuAnchorRef}
          >
            <MoreHoriz />
          </IconButton>

          <Modal BackdropProps={{ invisible: true }} open={openActionMenu} onClose={handleActionMenuClose}>
            <Box position='absolute' width={1} height={1}>
              <Popper
                style={{ zIndex: theme.zIndex.modal + 1 }}
                open={openActionMenu}
                anchorEl={actionMenuAnchorRef ? actionMenuAnchorRef.current : undefined}
                transition
                placement='bottom-end'
              >
                {({ TransitionProps }) => (
                  <Grow
                    appear
                    {...TransitionProps}
                    style={{
                      transformOrigin: 'top right',
                    }}
                  >
                    <Paper elevation={4}>
                      <ClickAwayListener mouseEvent={'onMouseDown'} onClickAway={handleActionMenuClose}>
                        <MenuList>
                          {actionMenuOptions.map((option) => (
                            <MenuItem key={option} onClick={() => handleActionMenuItemClick(option)}>
                              <Typography color={option === 'flagAndExit' ? 'error' : 'inherit'}>
                                {actionMenuOptionLabels[option]}
                              </Typography>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Modal>
        </Fragment>
      ) : null}
    </Fragment>
  );
};
