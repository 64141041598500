import React from 'react';
import { Typography } from '@material-ui/core';
import { RosterMultiSelectVolunteersDialog } from './RosterMultiSelectVolunteersDialog';
import { GetRosterScreenProgramVolunteersData_vm_program_activeVolunteers as ActiveVolunteerType } from './__generated__/GetRosterScreenProgramVolunteersData';

interface Props {
  open: boolean;
  close: () => void;
  onSubmit: (volunteers: Array<ActiveVolunteerType>) => void;
  volunteers?: ActiveVolunteerType[];
  ignoredVolunteerIds: string[];
}

export const AddVolunteerDialog = (props: Props) => {
  const { open, close, onSubmit, volunteers, ignoredVolunteerIds } = props;

  return (
    <RosterMultiSelectVolunteersDialog
      showSearchField
      open={open}
      title={'Add Volunteers'}
      volunteers={volunteers || []}
      ignoreVolunteerIds={ignoredVolunteerIds}
      onClose={close}
      onSubmit={onSubmit}
    >
      <Typography>{`These volunteers aren't enrolled in this activity but you can still add them to this roster if you would like.`}</Typography>
    </RosterMultiSelectVolunteersDialog>
  );
};
