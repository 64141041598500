import { useMemo } from 'react';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { GET_REGION_CODE } from './use-region-codes.gql';
import { GetRegionCode } from './__generated__/GetRegionCode';

const DEFAULT_REGION_CODE = 'au';

export const useRegionCodes = (): { primaryRegion?: string; allRegions?: Array<string> } => {
  const tenantGeoConfigQuery = useCampfireQuery<GetRegionCode, {}>(GET_REGION_CODE);

  const regionCodes = useMemo(() => {
    if (tenantGeoConfigQuery.loading) return undefined;
    return tenantGeoConfigQuery.data?.orgRegionCode ?? DEFAULT_REGION_CODE;
  }, [tenantGeoConfigQuery.data, tenantGeoConfigQuery.loading]);

  const splitRegionCodes = regionCodes ? regionCodes.replace(/\s/g, '').split(',') : undefined;

  if (!splitRegionCodes) {
    return {
      primaryRegion: undefined,
      allRegions: undefined,
    };
  }

  if (!splitRegionCodes.length) {
    return {
      primaryRegion: DEFAULT_REGION_CODE,
      allRegions: [DEFAULT_REGION_CODE],
    };
  }

  return {
    primaryRegion: splitRegionCodes[0],
    allRegions: splitRegionCodes,
  };
};
