import { ActivityTimelineItem } from '../ActivityTimeline/__generated__/ActivityTimelineItem';
import {
  GetActivitiesTimeline_vm_activities_VOLUNTEER_NonRecurringActivityType_cancelledActivities as CancelledNonRecurringActivity,
  GetActivitiesTimeline_vm_activities_VOLUNTEER_RecurringActivityType_cancelledActivities as CancelledRecurringActivity,
} from '../ActivityQueries/__generated__/GetActivitiesTimeline';
import { VolunteerEnrolments } from '../ActivityQueries/__generated__/VolunteerEnrolments';
import { VolunteerRosters } from '../ActivityQueries/__generated__/VolunteerRosters';
import { PublishedRoster_activeRosterings as PublishedRostering } from '../ActivitySidebar/__generated__/PublishedRoster';
import { ActivityTeamInformation as ActivityEnrolment } from '../ActivitySidebar/__generated__/ActivityTeamInformation';
import { ActivityApplicationFragments as ActivityAplication } from '../ActivityQueries/__generated__/ActivityApplicationFragments';
import { ActivityWaitlistingFragments as ActivityWaitlisting } from '../ActivityQueries/__generated__/ActivityWaitlistingFragments';
// import { RosteredStatus } from '../ActivityTypes';

export type ActivityGetRosterStatusType = Pick<ActivityTimelineItem, 'cancelledActivities' | 'activityId'>;

export type CancelledActivity = CancelledNonRecurringActivity & CancelledRecurringActivity;

// export function getRosterStatus(
//   activity: ActivityGetRosterStatusType,
//   activityDate: string,
//   activityEnrolments?: VolunteerEnrolments[],
//   activityRosterings?: VolunteerRosters[]
// ): RosteredStatus {
//   const { cancelledActivities } = activity;

//   if (cancelledActivities.some(({ activityDate: cancelledActivityDate }) => cancelledActivityDate === activityDate)) {
//     return 'cancelled';
//   }

//   if (
//     activityRosterings?.find(
//       (rostering) =>
//         rostering.publishedRoster.activityDate === activityDate &&
//         rostering.publishedRoster.activity.activityId === activity.activityId
//     )
//   ) {
//     return 'rostered';
//   }

//   const volunteerEnrolments =
//     activityEnrolments?.filter(({ activity: { activityId } }) => activityId === activity.activityId) || [];

//   if (
//     volunteerEnrolments
//       .flatMap(({ availabilities }) => availabilities)
//       .some(({ activityDate: availableDate }) => availableDate === activityDate)
//   ) {
//     return 'available';
//   }

//   if (
//     volunteerEnrolments
//       .flatMap(({ unavailabilities }) => unavailabilities)
//       .some(({ activityDate: unavailableDate }) => unavailableDate === activityDate)
//   ) {
//     return 'unavailable';
//   }
//   if (activityEnrolments?.every((enrolment) => enrolment.activity.activityId !== activity.activityId)) {
//     return 'not on team';
//   }
//   return 'unsure';
// }

export const getActivityApplication = (activityId: string, applications?: ActivityAplication[]) =>
  applications?.find((application) => application.activity.activityId === activityId);

export const getActivityWaitlist = (activityId: string, waitlists?: ActivityWaitlisting[]) =>
  waitlists?.find((waitlist) => waitlist.activity.activityId === activityId);

export const getActivityEnrolment = (activityId: string, enrolments?: VolunteerEnrolments[]) =>
  enrolments?.find((enrolment) => enrolment.activity.activityId === activityId);

export const getActivityEnrolmentsByActivity = (activityId: any) => (activityEnrolment: any) =>
  activityEnrolment.activity.activityId === activityId;
export const getAvailabilitiesOnDate = (activityDate: string) => (availabilityDate: any) =>
  availabilityDate.activityDate === activityDate && !availabilityDate.dateRemoved;
export const getActivityEnrolmentStatus = (
  volunteerEnrolments: VolunteerEnrolments[],
  activityId: string,
  activityDate: string
) => {
  const activityEnrolments = volunteerEnrolments.filter(
    (volunteerEnrolment) => volunteerEnrolment.activity.activityId === activityId
  );
  const unavailabilities = activityEnrolments.flatMap((activityEnrolment) =>
    activityEnrolment.unavailabilities.filter(({ activityDate: unavailableDate }) => unavailableDate === activityDate)
  );
  const availabilities = activityEnrolments.flatMap((activityEnrolment) =>
    activityEnrolment.availabilities.filter(({ activityDate: avaialableDate }) => avaialableDate === activityDate)
  );
  if (availabilities.length > 0) {
    return 'available';
  }
  if (unavailabilities.length > 0) {
    return 'unavailable';
  }
  if (
    volunteerEnrolments?.every((a) => {
      return a.activity.activityId !== activityId;
    })
  ) {
    return 'not on team';
  }
  return 'unsure';
};

export const isVolunteerRostered = (activityPublishedRosters: any, volunteerId: any, activityDate: any) => {
  return activityPublishedRosters.some(
    ({ activityDate: rosteredDate, rosterings }: { activityDate: string; rosterings: any[] }) => {
      return (
        rosteredDate === activityDate &&
        rosterings.some(({ volunteer }: { volunteer: any }) => volunteer.volunteerId === volunteerId)
      );
    }
  );
};

export const isActivityCancelled = (cancelledActivities: any, activityDate: string) =>
  cancelledActivities.some(
    ({ activityDate: cancelledActivityDate }: { activityDate: string }) => cancelledActivityDate === activityDate
  );

export const getActivityEnrolmentId = (volunteerEnrolments: VolunteerEnrolments[], activityId: string) =>
  volunteerEnrolments.find((volunteerEnrolment) => volunteerEnrolment.activity.activityId === activityId)
    ?.activityEnrolmentId;

export const getActivityAvailabilityId = (
  volunteerEnrolments: VolunteerEnrolments[],
  activityId: string,
  activityDate: string
) =>
  volunteerEnrolments
    .filter((volunteerEnrolment) => volunteerEnrolment.activity.activityId === activityId)
    .flatMap((volunteerEnrolment) => volunteerEnrolment.availabilities)
    .find((availability) => availability.activityDate === activityDate)?.activityAvailabilityId;

export const getActivityUnavailabilityId = (
  volunteerEnrolments: VolunteerEnrolments[],
  activityId: string,
  activityDate: string
) =>
  volunteerEnrolments
    .filter((volunteerEnrolment) => volunteerEnrolment.activity.activityId === activityId)
    .flatMap((volunteerEnrolment) => volunteerEnrolment.unavailabilities)
    .find((unavailability) => unavailability.activityDate === activityDate)?.activityUnavailabilityId;

export const getActivityPlublishedRosterId = (
  volunteerRosterings: VolunteerRosters[],
  activityId: string,
  activityDate: string
) =>
  volunteerRosterings.find(
    (volunteerRostering) =>
      volunteerRostering.publishedRoster.activityDate === activityDate &&
      volunteerRostering.publishedRoster.activity.activityId === activityId
  )?.publishedRosteringId;

export const getSelectedSessions = (
  volunteerEnrolments: VolunteerEnrolments[],
  activityId: string,
  activityDate: string
) =>
  volunteerEnrolments
    .filter((activityEnrolment) => activityEnrolment.activity.activityId === activityId)
    .flatMap((activityEnrolment) =>
      activityEnrolment.availabilities
        .filter((availability) => availability.activityDate === activityDate && !availability.dateRemoved)
        .flatMap((availability) =>
          availability.sessionAvailabilities
            .filter((sessionAvailability) => !sessionAvailability.dateRemoved)
            .map((sessionAvailability) => sessionAvailability.session.sessionId)
        )
    );

export const getRosteredSessions = (
  volunteerRosterings: VolunteerRosters[],
  activityId: string,
  activityDate: string
) =>
  volunteerRosterings
    .filter(
      (rostering) =>
        rostering.publishedRoster.activityDate === activityDate &&
        rostering.publishedRoster.activity.activityId === activityId
    )
    .flatMap((rostering) => rostering.sessionRosterings.map((sessionRostering) => sessionRostering.session.sessionId));

// export function getRosteredStatus(
//   cancelledActivities: CancelledActivity[],
//   volunteerRosters: VolunteerRosters[],
//   volunteerEnrolments: VolunteerEnrolments[],
//   activityId: string,
//   activityDate: string
// ): RosteredStatus {
//   if (isActivityCancelled(cancelledActivities, activityDate)) {
//     return 'cancelled';
//   }
//   if (getActivityPlublishedRosterId(volunteerRosters, activityId, activityDate)) {
//     return 'rostered';
//   }
//   return getActivityEnrolmentStatus(volunteerEnrolments, activityId, activityDate);
// }

const VOLUNTEER_LEVEL = {
  admin: 0,
  'program manager': 1,
  'activity leader': 2,
  volunteer: 3,
} as { [key: string]: number };

function getVolunteerLevel(a: PublishedRostering | ActivityEnrolment) {
  const levelNumber = VOLUNTEER_LEVEL[a.volunteer.privilegeLevel.toLowerCase()];
  if (levelNumber) {
    return levelNumber;
  }
  return 0;
}

export function sortVolunteer(a: PublishedRostering | ActivityEnrolment, b: PublishedRostering | ActivityEnrolment) {
  return getVolunteerLevel(a) - getVolunteerLevel(b);
}
