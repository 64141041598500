export const ACTIVITY_STATUS_FILTERS = [
  { label: 'Rostered', value: 'rostered' },
  { label: 'Available', value: 'available' },
  { label: 'Unavailable', value: 'unavailable' },
  { label: 'Unsure', value: 'unsure' },
  { label: 'Cancelled', value: 'cancelled' },
];

export type ActivityStatusFilterType = {
  label: string;
  value: string;
};
