import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { COLORS } from './colors';
import { GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents_widget_VOLUNTEER_ChartWidgetType as IChartWidget } from '../../dashboard-widget-setting/__generated__/GetDashboardPreference';
import { useQueryChart } from './useQueryChart';

type DashboardPieChartProps = {
  widget: IChartWidget;
};

export function DashboardPieChart(props: DashboardPieChartProps) {
  const { widget } = props;

  const data = useQueryChart(widget);

  const [container, setContainer] = React.useState({ width: 0, height: 0 });

  const onResize = (width: number, height: number) => {
    setContainer({ width, height });
  };

  const chartWidth = Math.min(container.width, container.height);

  const formattedData = (data || []).map((datum: any) => ({
    name: datum.name,
    value: datum[Object.keys(datum).filter((k) => k !== 'name')[0]],
  }));

  return (
    <ResponsiveContainer width='100%' height='99%' onResize={onResize}>
      <PieChart width={chartWidth} height={chartWidth}>
        <Pie data={formattedData} outerRadius={0.4 * chartWidth} fill='#8884d8' dataKey='value'>
          {formattedData.map((entry: any, index: number) => (
            <Cell key={entry.id} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend iconType='circle' iconSize={10} />
      </PieChart>
    </ResponsiveContainer>
  );
}
