import React from 'react';
import { upperFirst } from 'lodash';
import { TableWidget as ITableWidget } from '../../dashboard-widget-setting/__generated__/TableWidget';
import { WidgetContainer, WidgetContent } from '../WidgetContainer';
import { WidgetHeader } from '../WidgetHeader';
import { ActivityApplicationTableWidget } from './activities-table-widget/activity-application-table-widget/ActivityApplicationTableWidget';
import { ActivityWaitlistingTableWidget } from './activities-table-widget/activity-waitlisting-table-widget/ActivityWaitlistingTableWidget';
import { PrioritisedActivityTableWidget } from './activities-table-widget/prioritised-activities-table-widget/PrioritisedActivityTableWidget';
import { ProgramApplicationsTableWidget } from './programs-table-widget/program-applications-table-widget/ProgramApplicationsTableWidget';
import { VolunteerApplicationsTableWidget } from './volunteers/volunteer-applications-table-widget/VolunteerApplicationsTableWidget';
import { IncidentsTableWidget } from './incidents-table-widget/IncidentsTableWidget';
import { TaskExpiringTableWidget } from './tasks-table-widget/task-expiring-table-widget/TaskExpiringTableWidget';
import { TaskAwaitingApprovalTableWidget } from './tasks-table-widget/task-awaiting-approval-widget/TaskAwaitingApprovalWidget';
import { VolunteersAwaitingApprovalTableWidget } from './volunteers/volunteers_awaiting_approval-table-widget/VolunteersAwaitingApprovalTableWidget';
import { CancelledRostersTableWidget } from './rosters-table-widget/cancelled-rosters-table-widget/CancelledRostersTableWidget';

type TableWidgetProps = {
  widget: ITableWidget;
};

function getWidgetFromTagAndName(tag: string, name: string, filters: string, range: string | null) {
  switch (tag.toLowerCase()) {
    case 'volunteers':
      switch (name) {
        case 'incoming':
          return <VolunteerApplicationsTableWidget filters={filters} range={range} />;
        case 'awaiting':
          return <VolunteersAwaitingApprovalTableWidget filters={filters} range={range} />;
        default:
          return null;
      }
    case 'activities':
      switch (name) {
        case 'application':
          return <ActivityApplicationTableWidget filters={filters} range={range} />;
        case 'waitlist':
          return <ActivityWaitlistingTableWidget filters={filters} range={range} />;
        case 'priority':
          return <PrioritisedActivityTableWidget filters={filters} />;
        default:
          return null;
      }
    case 'incidents':
      return <IncidentsTableWidget filters={filters} status={name} range={range} />;

    case 'tasks':
      switch (name) {
        case 'awaiting':
          return <TaskAwaitingApprovalTableWidget filters={filters} range={range} />;
        case 'expiring':
          return <TaskExpiringTableWidget filters={filters} />;
        default:
          return null;
      }

    case 'programs':
      switch (name) {
        case 'application':
          return <ProgramApplicationsTableWidget filters={filters} range={range} />;
        default:
          return null;
      }
    case 'rosters':
      switch (name) {
        case 'cancellation':
          return <CancelledRostersTableWidget filters={filters} range={range} />;
        default:
          return null;
      }
    default:
      return null;
  }
}

export function TableWidget({ widget }: TableWidgetProps) {
  const { filters, targets, chartOrder, range } = widget;

  const { tag, name } = JSON.parse(targets)[0];
  const title = `${upperFirst(tag.toLowerCase())} ${upperFirst(name.toLowerCase())}`;

  return (
    <WidgetContainer>
      <WidgetHeader title={title} subTitle={chartOrder} />
      <WidgetContent>{getWidgetFromTagAndName(tag, name, filters, range)}</WidgetContent>
    </WidgetContainer>
  );
}
