import React from 'react';
import { Box } from '@material-ui/core';
import { FilterList, RestoreOutlined, UpdateOutlined } from '@material-ui/icons';
import { SearchField } from '../../../../common/inputs/SearchField';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { GetRostersSidebarData_vm_activities_VOLUNTEER_NonRecurringActivityType_rosterTemplates as RosterTemplate } from '../__generated__/GetRostersSidebarData';
import { ToggleButton } from '../../../../common/buttons/ToggleButton';

interface Props {
  showFilters: boolean;
  setShowFilters: (x: boolean) => void;
  rosterTemplate?: RosterTemplate;
  handleEditRosterTemplate: () => void;
  setSearchFilter: (x: string) => void;
  onHistory: boolean | string | undefined;
  onHistoryClick: () => void;
  searchFilter: string;
}

export const RosterListHeader = (props: Props) => {
  const { showFilters, setShowFilters, setSearchFilter, onHistory, onHistoryClick, searchFilter } = props;
  const { theme } = useCampfireTheme();
  return (
    <Box
      display={'flex'}
      height={'72px'}
      alignItems='center'
      justifyContent='flex-end'
      borderBottom={`1px solid ${theme.color.grey.border}`}
    >
      <Box display={'flex'}>
        <SearchField
          data-track='rosterCnlFilter-SearchInput'
          placeholder='Search Rosters'
          growLeft
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
        <ToggleButton
          aria-label='roster-filter-menu'
          onClick={() => setShowFilters(!showFilters)}
          selected={showFilters}
        >
          <FilterList />
        </ToggleButton>
        <ToggleButton aria-label='roster-filter-menu' onClick={onHistoryClick} selected={Boolean(onHistory)}>
          {onHistory ? <RestoreOutlined /> : <UpdateOutlined />}
        </ToggleButton>
      </Box>
    </Box>
  );
};
