import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Grid } from '@material-ui/core';
import React from 'react';
import { Page } from '../../../global/components';
import { ProgramEditor } from './program-management-editor/ProgramEditor';
import { ProgramManagementSideNav } from './program-management-side-nav/ProgramManagementSideNav';
import { useProgramManagementContext } from './ProgramManagementContext';

const PAGE_PADDING = 16;

export const ManageProgramsPageMobile = () => {
  const {
    selectedProgramId,
    getAllProgramsIsLoading,
    saveProgramIsLoading,
    updateProgramManagersIsLoading,
    deleteProgramIsLoading,
    saveActivityIsLoading,
    saveProgramVolunteersIsLoading,
  } = useProgramManagementContext();
  return (
    <Page>
      <LinearProgressOverlay
        isLoading={
          getAllProgramsIsLoading ||
          saveProgramIsLoading ||
          updateProgramManagersIsLoading ||
          deleteProgramIsLoading ||
          saveActivityIsLoading ||
          saveProgramVolunteersIsLoading
        }
      />
      <Grid container style={{ overflow: 'auto', flexWrap: 'nowrap', height: '100%', padding: PAGE_PADDING }}>
        {selectedProgramId ? <ProgramEditor /> : <ProgramManagementSideNav pagePadding={PAGE_PADDING} />}
      </Grid>
    </Page>
  );
};
