import React from 'react';
import { Box, Typography, Theme, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';

import { ActivityHeader } from './ActivityHeader';
import { VolunteerProfile } from '../../../../common/AvatarName';
import { PublishedRoster_activeRosterings as ActiveRosterings, PublishedRoster } from './__generated__/PublishedRoster';
import { ActivityTeamInformation as ActivityEnrolment } from './__generated__/ActivityTeamInformation';
import { sortVolunteer } from '../ActivityUtils';
import { sharedStyles } from '../ActivityStyles/shared';

const useActivityMemberStyles = makeStyles((theme: Theme) =>
  createStyles({
    level: {
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      fontWeight: 700,
      color: theme.color.grey.neutral200,
    },
  })
);

const useTeamStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.color.grey.neutral400,
    },
    text: {
      textAlign: 'center',
      color: theme.color.grey.neutral400,
      fontSize: '13px',
      paddingTop: '12px',
    },
  })
);

function ActivityMember(props: ActiveRosterings | ActivityEnrolment) {
  const classes = useActivityMemberStyles();
  const {
    volunteer: {
      profile: { firstName, lastName, avatarUrl, profileId, userId },
      privilegeLevel,
    },
  } = props;
  const displayName = `${firstName} ${lastName}`;
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' paddingTop='0.75rem' paddingBottom='0.75rem'>
      <VolunteerProfile
        avatarUrl={avatarUrl}
        name={displayName}
        noTrim
        profileId={profileId}
        userId={userId}
        lastName={lastName}
        preferredName={firstName}
      />
      <Typography className={classes.level}>{privilegeLevel === 'Volunteer' ? '' : privilegeLevel}</Typography>
    </Box>
  );
}
interface ActivityRosteredInformationProps {
  isErolled: boolean;
  publishedRoster: PublishedRoster | null;
}

export function ActivityRosteredInformation({ publishedRoster, isErolled }: ActivityRosteredInformationProps) {
  const teams = (publishedRoster?.activeRosterings || []).sort(sortVolunteer);

  const [showFull, setShowFull] = React.useState(false);
  const showingTeams = showFull ? teams : teams.slice(0, 3);
  const buttonClasses = sharedStyles.button();
  const classes = useTeamStyles();

  if (!isErolled) {
    return null;
  }

  if (!publishedRoster) {
    return (
      <Box marginTop='2rem'>
        <ActivityHeader title='Rostered Team' />
        <Typography className={classes.text}>No published roster for this date</Typography>
      </Box>
    );
  }

  if (teams.length === 0) {
    return (
      <Box marginTop='2rem'>
        <ActivityHeader title='Rostered Team' />
        <Typography className={classes.text}>No volunteers rostered for this date</Typography>
      </Box>
    );
  }

  return (
    <Box marginTop='2rem'>
      <ActivityHeader title='Rostered Team' />
      <Box>
        {showingTeams?.map((activityMember) => (
          <ActivityMember key={activityMember.volunteer.volunteerId} {...activityMember} />
        ))}
      </Box>
      {teams.length > 3 && (
        <Box display='flex' justifyContent='center' marginTop='12px'>
          {showFull ? (
            <Button
              variant='outlined'
              onClick={() => setShowFull(false)}
              endIcon={<ExpandLess />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show less
            </Button>
          ) : (
            <Button
              variant='outlined'
              onClick={() => setShowFull(true)}
              endIcon={<ExpandMore />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show more
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

interface ActivityTeamInformationProps {
  isErolled: boolean;
  activityEnrolments: ActivityEnrolment[];
}

export function ActivityTeamInformation({ isErolled, activityEnrolments }: ActivityTeamInformationProps) {
  const teams = (activityEnrolments || []).sort(sortVolunteer);

  const [showFull, setShowFull] = React.useState(false);
  const showingTeams = showFull ? teams : teams.slice(0, 3);
  const buttonClasses = sharedStyles.button();
  const classes = useTeamStyles();

  if (!isErolled) {
    return null;
  }

  return (
    <Box marginTop='2rem'>
      <ActivityHeader title='Team Information' />
      <Box>
        {showingTeams?.map((activityMember) => (
          <ActivityMember key={activityMember.volunteer.volunteerId} {...activityMember} />
        ))}
      </Box>
      {teams.length > 3 && (
        <Box display='flex' justifyContent='center' marginTop='12px'>
          {showFull ? (
            <Button
              variant='outlined'
              onClick={() => setShowFull(false)}
              endIcon={<ExpandLess />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show less
            </Button>
          ) : (
            <Button
              variant='outlined'
              onClick={() => setShowFull(true)}
              endIcon={<ExpandMore />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show more
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
