import React from 'react';
import { unpackToTime } from '@campfire/hot-date';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { SyncRounded } from '@material-ui/icons';
import { ActivityCard } from '../../../../../common/cards/activity-card/ActivityCard';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { RosteredStatus, TimelineItem } from '../../ActivityTypes';

import { ActivityTimelineItem as ActivityTimelineItemFragments } from '../../ActivityTimeline/__generated__/ActivityTimelineItem';

export type ActivityTimelineItemProps = ActivityTimelineItemFragments & {
  activityDate: string;
};

const customDateInfoStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayText: {
      fontSize: 40,
    },
    bodyContainer: {
      [theme.breakpoints.up('lg')]: {
        width: 'auto',
      },
      [theme.breakpoints.only('sm')]: {
        width: 252,
      },
      [theme.breakpoints.only('xs')]: {
        width: 214,
      },
      [theme.breakpoints.only('md')]: {
        width: 252,
      },
    },
  })
);

export interface TimelineItemMenuProps {
  rosteredStatus: RosteredStatus;
  onUpdateAvailability: () => void;
  onUnavailable: () => void;
  onWithdraw: () => void;
}

export function ActivityTimelineItem(props: {
  activity: TimelineItem;
  ActivityMenu: any;
  onSelect: () => void;
  isSelected: boolean;
  color: any;
}) {
  const { activity, onSelect, isSelected, ActivityMenu, color } = props;
  const { theme } = useCampfireTheme();
  const formatTime = (time: string) => unpackToTime(time).toFormat('h:mm a');
  const dateInfoClasses = customDateInfoStyles();

  const recurrence =
    activity.__typename === 'VOLUNTEER_RecurringActivityType'
      ? activity.schedule.recurrences[0].humanReadable
      : 'Flexible';

  return (
    <Box marginTop='1.875rem' onClick={onSelect}>
      <ActivityCard
        activity={activity}
        color={color}
        selected={isSelected}
        activityMenu={ActivityMenu}
        classes={dateInfoClasses}
      >
        <Box display='flex' alignItems='center'>
          <SyncRounded style={{ color: theme.color.grey.neutral200, fontSize: 16, marginRight: '6px' }} />
          <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200 }}>
            {recurrence}
            {activity.startTime && activity.endTime
              ? `, ${formatTime(activity.startTime)} - ${formatTime(activity.endTime)}`
              : ''}
          </Typography>
        </Box>
      </ActivityCard>
    </Box>
  );
}
