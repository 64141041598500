import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, Typography } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import pluralize from 'pluralize';
import React from 'react';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import {
  ProgramMainActionType,
  ProgramStatus,
  ProgramSubActionType,
  ProgramType,
} from '../../screens/general/activities-v2/AllPrograms/AllProgramTypes';
import { ProgramStatusIndicator } from '../../screens/general/activities-v2/AllPrograms/ProgramStatusIndicator';
import {
  convertToEditorState,
  getProgramActions,
} from '../../screens/general/activities-v2/AllPrograms/AllProgramsHelpers';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      width: (props: { isSm: boolean }) => (props.isSm ? '100%' : '48%'),
      height: 'fit-content',
      borderRadius: '8px',
      boxSizing: 'border-box',
      marginTop: '15px',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: `0px 0px 4px rgba(0, 0, 0, 0.25)`,
      },
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
  })
);

interface Props {
  color?: string;
  program: ProgramType;
  imageUrl?: string | null;
  selected: boolean;
  onClick: () => void;
  onAction: (action: ProgramMainActionType | ProgramSubActionType) => void;
}

export const ProgramCard = (props: Props) => {
  const { color, program, selected, onClick, onAction } = props;
  const { theme, isSm, isMd } = useCampfireTheme();
  const classes = useStyles({ isSm });

  return (
    <Box
      className={classes.card}
      border={selected ? `2px solid ${color}` : `1px solid ${theme.color.grey.border}`}
      borderBottom={selected ? `2px solid ${color}` : `1px solid ${theme.color.grey.border}`}
      borderRight={selected ? `2px solid ${color}` : `1px solid ${theme.color.grey.border}`}
      bgcolor={color ?? theme.color.grey.neutralBrand100}
      onClick={onClick}
    >
      {program.image && (
        <Box height='250px'>
          <img src={program.image} alt='resource' className={classes.image} />
        </Box>
      )}
      <Box display='flex'>
        <Box minWidth='26px' width='26px' borderRadius={program.image ? '0px 0px 0px 8px' : '0px 8px 8px 0px'}></Box>
        <Box
          display='flex'
          flexDirection='column'
          flexGrow={2}
          alignItems='flex-start'
          justifyContent='center'
          width={!isMd ? '300px' : '250px'}
          style={{ padding: '18px 18px', borderRadius: program.image ? '0px 0px 8px 0px' : '0px 8px 8px 0px' }}
          overflow='hidden'
          bgcolor={theme.color.white.neutral50}
        >
          <Box display='flex' justifyContent='space-between' alignSelf='stretch'>
            <TitularTooltip title={program.name}>
              <Box
                display='flex'
                alignItems='center'
                style={{
                  width: '100%',
                  letterSpacing: '-0.02em',
                  overflow: 'hidden',
                  maxHeight: '3.5em',
                  textOverflow: 'ellipsis',
                }}
              >
                <Typography
                  variant='h6'
                  noWrap
                  style={{
                    color: theme.color.grey.neutral400,
                    fontWeight: 800,
                    width: '100%',
                    letterSpacing: '-0.02em',
                  }}
                >
                  {program.name}
                </Typography>
              </Box>
            </TitularTooltip>
            <ProgramStatusIndicator
              status={program.status as ProgramStatus}
              actions={getProgramActions(program.status as ProgramStatus, program.isRestrictedProgram)}
              onAction={onAction}
            />
          </Box>
          {program.description && (
            <Box maxHeight={40} height={40} mt={1} overflow='hidden'>
              <Typography
                style={{
                  color: theme.color.grey.neutral300,
                  fontWeight: 400,
                  fontSize: '0.75rem',
                }}
              >
                <CampfireRichEditor editorState={convertToEditorState(program.description)} readOnly />
              </Typography>
            </Box>
          )}
          <Box display={isMd ? 'auto' : 'flex'} alignItems='center' mt='15px'>
            <Box display='flex' alignItems='center' pr={3} pt={isMd ? 1 : 0}>
              <CalendarToday style={{ color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }} />
              <Typography variant='caption' style={{ color: theme.color.grey.neutral200 }}>
                {pluralize('activities', program.activities.length, true)}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' pr={3} pt={isMd ? 1 : 0}>
              <Typography
                variant='h2'
                style={{
                  fontWeight: 600,
                  color: theme.color.grey.neutral200,
                  fontSize: 14,
                  marginRight: '6px',
                }}
              >
                {program.activeVolunteers.length}
              </Typography>
              <Typography variant='caption' style={{ color: theme.color.grey.neutral200 }}>
                {program.activeVolunteers.length > 1 ? 'active volunteers' : 'active volunteer'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
