import { Box, Typography, Grid, Chip } from '@material-ui/core';
import React from 'react';
import { capitalize } from 'lodash';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ActivitiesConsoleCardExploreActivity_VOLUNTEER_NonRecurringActivityType_activityTags as ActivityTag } from '../activities-list/__generated__/ActivitiesConsoleCardExploreActivity';

interface Props {
  currentActivityTags?: ActivityTag[];
}

export const ActivityTagsDetail = (props: Props) => {
  const { theme } = useCampfireTheme();
  const { currentActivityTags } = props;

  return (
    <Box>
      {currentActivityTags && currentActivityTags.length > 0 ? (
        <Grid container spacing={1}>
          {currentActivityTags?.map((tagItem) => (
            <Grid item key={tagItem.activityTagId}>
              <Chip
                style={{ backgroundColor: '', color: theme.color.grey.neutralBrand800 }}
                label={capitalize(tagItem.name)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box>
          <Typography
            style={{
              textAlign: 'center',
              color: theme.color.grey.neutral400,
              fontSize: '13px',
              paddingTop: '12px',
            }}
          >
            There are no tag on this activity
          </Typography>
        </Box>
      )}
    </Box>
  );
};
