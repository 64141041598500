import { gql } from '@apollo/client';

export const WAITLIST_VOLUNTEERS_GET_PROPGRAMS = gql`
  query WaitlistVolunteersGetPrograms {
    vm {
      programs {
        programId
        name
        description
        dateDeleted
        dateSuspended
      }
    }
  }
`;
