import { Box, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import PrivilegeRules from '../../../../../global/auth/PrivilegeRules';
import { useUser } from '../../../../../global/auth/useUser';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { VolunteerCommonProfile } from '../../__generated__/VolunteerCommonProfile';
import { InfoBlock } from '../../common/InfoBlock';
import { SectionHeader } from '../../common/SectionHeader';
import { hasRuleForVolunteer } from '../../common/utils';
import { VolunteerComments } from './volunteer-comments/VolunteerComments';
import { VolunteerRoles } from './VolunteerRoles';

export const VolunteerPersonalDetail = memo(
  ({
    volunteer,
    onClose,
    refetch,
  }: {
    volunteer: VolunteerCommonProfile;
    onClose: () => void;
    refetch: () => void;
  }) => {
    const {
      volunteerId,
      flagging,
      profile: { dateOfBirth, comments },
      activityRoles,
    } = volunteer;
    const { theme } = useCampfireTheme();
    const {
      user: { userIdentityService },
    } = useUser();

    const canUpdateActivityRoles =
      volunteer && hasRuleForVolunteer(volunteer, userIdentityService, PrivilegeRules.updateVolunteerActivityRoles);
    const canUpdateComments =
      volunteer && hasRuleForVolunteer(volunteer, userIdentityService, PrivilegeRules.updateVolunteerComments);

    return (
      <Box p={2}>
        <SectionHeader title='Personal Details' />
        {flagging && (
          <InfoBlock
            label='Status: flagged'
            value={
              <>
                <Typography display='block' component='span'>
                  {`Exited by ${flagging.flaggedBy.profile.preferredName} ${flagging.flaggedBy.profile.lastName}`}
                </Typography>
                <Typography
                  color='textSecondary'
                  variant='body2'
                  component='span'
                  display='block'
                >{`Reason: ${flagging.flaggedReason}`}</Typography>
              </>
            }
            bgcolor={theme.status.red.light}
            labelAsChip
          />
        )}
        {dateOfBirth && <InfoBlock label='Date of Birth' value={dateOfBirth} />}
        <VolunteerComments
          volunteerId={volunteerId}
          comments={comments}
          editable={canUpdateComments}
          onClose={onClose}
          refetch={refetch}
        />
        <VolunteerRoles
          volunteerId={volunteerId}
          volunteerActivityRoles={activityRoles}
          editable={canUpdateActivityRoles}
        />
      </Box>
    );
  }
);
