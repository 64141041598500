import { StringParam, useQueryParam } from 'use-query-params';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import React from 'react';
import Masonry from 'react-masonry-component';
import { bodyStyles } from '../ActivityStyles/shared';
import { ActivitiesTabs } from '../ActivitiesTabs';
import { ActivityContextValue } from '../ActivityContext';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ActivitiesBody } from '../ActivitiesBody';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { GET_PROGRAMS, GET_VOLUNTEER_PROGRAM_DATA } from './get-programs.gql';
import { ProgramCard } from '../../../../common/cards/ProgramCard';
import { AllProgramsColorLegend } from './AllProgramsColorLegend';
import { ProgramStatus, ProgramType } from './AllProgramTypes';
import { getProgramsStatus } from './AllProgramsHelpers';
import { useUser } from '../../../../global/auth/useUser';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { ProgramSidebar } from './ProgramSidebar';
import { useProgramActions } from './AllProgramActions';
import { GetVolunteerProgramData, GetVolunteerProgramDataVariables } from './__generated__/GetVolunteerProgramData';
import { SearchField } from '../../../../common/inputs/SearchField';
import { GetPrograms } from './__generated__/GetPrograms';
import { useDeepEffect } from '../../../../hooks/useDeepEffect';

const useStyles = makeStyles(() =>
  createStyles({
    cardContainer: {
      marginTop: '1.875rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    sidebarWrapper: {
      padding: '1rem 1rem',
    },
  })
);

export const AllProgramsScreen = () => {
  const { activeTab, setActiveTab } = React.useContext(ActivityContextValue);
  const [selectedProgram, setSelectedProgram] = React.useState<ProgramType>();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [programId, setProgramId] = useQueryParam('pid', StringParam);
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  const { theme, isMobile } = useCampfireTheme();

  const classes = bodyStyles();
  const programClasses = useStyles();

  const { data: programsData, loading: programsLoading, refetch } = useCampfireQuery<GetPrograms, {}>(GET_PROGRAMS, {});

  const {
    data: volunteerProgramData,
    loading: volunteerProgramDataLoading,
    refetch: volunteerProgramDataRefetch,
  } = useCampfireQuery<GetVolunteerProgramData, GetVolunteerProgramDataVariables>(GET_VOLUNTEER_PROGRAM_DATA, {
    options: {
      variables: {
        volunteerId,
      },
    },
  });

  const { onProgramAction } = useProgramActions(volunteerProgramDataRefetch);

  const volunteerData = volunteerProgramData?.vm.volunteer;
  const visiblePrograms = programsData?.vm.programs
    .filter(
      (program) =>
        !program.isHidden || program.activeVolunteers.some((volunteer) => volunteer.volunteerId === volunteerId)
    )
    .filter((program) => !program.dateDeleted)
    .filter((program) => !program.dateSuspended);

  const parsedPrograms = React.useMemo(
    () =>
      visiblePrograms?.map((program) => {
        return {
          ...program,
          status: getProgramsStatus(program.programId, volunteerData),
        };
      }),
    [visiblePrograms, volunteerData]
  );

  const filteredPrograms = parsedPrograms?.filter((program) =>
    program.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim())
  );

  useDeepEffect(() => {
    if (!isMobile && filteredPrograms?.length) {
      setProgramId(filteredPrograms[0].programId);
    }
  }, [filteredPrograms, isMobile]);

  useDeepEffect(() => {
    if (programId && filteredPrograms?.some((program) => program.programId !== programId)) {
      setSelectedProgram(filteredPrograms?.find((program) => program.programId === programId));
    }
  }, [filteredPrograms, programId]);

  const onChangeSearchField = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchValue(e.target.value);
  };

  const onActionClick = (action: any, program: any) => {
    const programApplicationId = program.programApplications.find(
      (application: any) => application.volunteer.volunteerId === volunteerId
    )?.programApplicationId;
    const volunteerIds = [volunteerId];
    onProgramAction({
      volunteerIds,
      programApplicationId,
    })(action, program).then(() => {
      if (refetch) {
        refetch();
      }
    });
  };

  const onClose = () => {
    setProgramId(undefined);
    setSelectedProgram(undefined);
  };

  return (
    <Box display='flex' height='100%' overflow='hidden'>
      <Box className={classes.rootContainer}>
        <ActivitiesTabs activeTab={activeTab} onSelectTab={setActiveTab}>
          <Box flex={1} justifyContent='flex-end' display='flex'>
            <SearchField placeholder='Search Programs' value={searchValue} onChange={onChangeSearchField} />
          </Box>
        </ActivitiesTabs>
        <ActivitiesBody isLoading={programsLoading || volunteerProgramDataLoading}>
          {isMobile && selectedProgram ? (
            <Box className={programClasses.sidebarWrapper}>
              <ProgramSidebar program={selectedProgram} onClose={onClose} />
            </Box>
          ) : (
            <>
              <Box className={classes.stickyBox}>
                <AllProgramsColorLegend />
              </Box>
              <Box className={programClasses.cardContainer}>
                <Masonry options={{ gutter: 20, originLeft: true }}>
                  {filteredPrograms?.map((program: ProgramType) => (
                    <ProgramCard
                      program={program}
                      selected={selectedProgram?.programId === program.programId}
                      onClick={() => setSelectedProgram(program)}
                      color={theme.color.programs.status[program.status as ProgramStatus]}
                      onAction={(action) => onActionClick(action, program)}
                    />
                  ))}
                </Masonry>
              </Box>
            </>
          )}
        </ActivitiesBody>
      </Box>
      {!isMobile && selectedProgram && (
        <CollapsibleSideBar>
          <ProgramSidebar program={selectedProgram} />
        </CollapsibleSideBar>
      )}
    </Box>
  );
};
