import React, { memo, useMemo, useState } from 'react';
import { Route, useRouteMatch } from 'react-router';
import { DateTime } from 'luxon';
import { StringParam, useQueryParam } from 'use-query-params';
import { encodeDate, unpackToDate, unpackToDateTime } from '@campfire/hot-date';
import { CampfireSwitch } from '../../../content-blocks/common/CampfireSwitch';
import { ActivityReport } from './ActivityReport';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import {
  ActivityReportGetActivities,
  ActivityReportGetActivities_vm_activities as ActivityFromReport,
  ActivityReportGetActivities_vm_volunteer_rosterings_publishedRoster_activity as ActivityFromRoster,
  ActivityReportGetActivitiesVariables,
} from './__generated__/ActivityReportGetActivities';
import { ACTIVITY_REPORT_GET_ACTIVITIES, ACTIVITY_REPORT_GET_ALL_ACTIVITIES } from './activity-report-model.gql';
import { useUser } from '../../../global/auth/useUser';
import { ActivityReportGetAllActivities } from './__generated__/ActivityReportGetAllActivities';
import { ActivityReportContent } from './ActivityReports/ActivityReportContent';
import { Page } from '../../../global/components';
import { ActivityReportTutorialDialog } from './ActivityReportTutorialDialog';

export type ReportDate = {
  activityId: string;
  name: string;
  date: string;
};

export type PrivilegedActivityType = {
  activityId: string;
  activityName: string;
  programName: string;
  status: string;
};

export type ActivityRosterType = {
  activityId: string;
  date: string;
};

export interface ActivityReportType {
  activityId: string;
  name: string;
  date: string;
  startTime: string;
  isActivitySuspended: boolean;
  cancelledSessionsNumber: number;
  sessionsNumber: number;
  completedReportSessionsNumber: number;
}

const ActivityReportScreen = memo(() => {
  const match = useRouteMatch();
  const {
    getVolunteerIdentity,
    user: { userIdentityService },
  } = useUser();
  const userIsAdmin = userIdentityService.isVmAdmin;
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);
  const [selectedTab] = useQueryParam('tab', StringParam);
  const [selectedProgramId] = useQueryParam('programId', StringParam);
  const { data: vmProgram } = useCampfireQuery<ActivityReportGetAllActivities, {}>(
    ACTIVITY_REPORT_GET_ALL_ACTIVITIES,
    {}
  );

  const getActivitiesByProgram = (programId?: string) => {
    return vmProgram?.vm.programs
      .filter((program) => program.programId === programId || !programId || programId === 'all')
      .flatMap((program) => program.activities.map((activity) => activity.activityId));
  };

  const {
    data: activitiesData,
    loading: getActivitiesIsLoading,
    refetch: refetchActivityReportActivities,
  } = useCampfireQuery<ActivityReportGetActivities, ActivityReportGetActivitiesVariables>(
    ACTIVITY_REPORT_GET_ACTIVITIES,
    {
      options: {
        variables: {
          volunteerId: getVolunteerIdentity().volunteerId,
          from: encodeDate(DateTime.fromJSDate(new Date('2019-01-01'))),
          until: encodeDate(DateTime.local()),
          activityIds: getActivitiesByProgram(selectedProgramId),
        },
      },
    }
  );

  const existingActivities = useMemo(
    () => activitiesData?.vm.activities.filter((a) => !a.program.dateDeleted && !a.program.dateSuspended),
    [activitiesData]
  );
  const userIsPmOrAdmin = (programId: string) => userIsAdmin || userIdentityService.isManagerOfProgram(programId);
  const userEnrolledInActivity = (activityId: string) =>
    !!activitiesData?.vm.volunteer?.activityEnrolments.find((e) => e.activity.activityId === activityId);
  const userRosteredForActivity = (activityId: string, date: string) =>
    !!activitiesData?.vm.volunteer?.rosterings.find(
      (r) => r.publishedRoster.activity.activityId === activityId && r.publishedRoster.activityDate === date
    );

  const getReportFromActivity = (
    activity: ActivityFromReport | ActivityFromRoster,
    occurrence: string
  ): ActivityReportType => ({
    activityId: activity.activityId,
    name: activity.name,
    date: occurrence,
    startTime: activity.startTime,
    isActivitySuspended: activity.isSuspended,
    cancelledSessionsNumber:
      activity.activityReports.find((activityReport) => activityReport.activityDate === occurrence)?.cancelledSessions
        ?.length || 0,
    sessionsNumber: activity.sessions.length,
    completedReportSessionsNumber:
      activity.activityReports.find((activityReport) => activityReport.activityDate === occurrence)?.sessionReports
        .length || 0,
  });

  const privilegedReportDates =
    useMemo(() => {
      return existingActivities?.flatMap((a) => {
        const datesArray = a.occurrencesBetween.concat(
          a.historicalOccurrences.filter((item) => a.occurrencesBetween.indexOf(item) < 0)
        );

        return datesArray
          .filter((occurrence) => {
            if (a.cancelledActivities.find((date) => date.activityDate === occurrence)) {
              return false;
            }

            if (userIsPmOrAdmin(a.program.programId)) return true;
            if (userEnrolledInActivity(a.activityId)) return true;
            return userRosteredForActivity(a.activityId, occurrence);
          })
          .map((occurrence) => getReportFromActivity(a, occurrence));
      });
    }, [existingActivities]) ?? [];

  const rosteredReportDates = useMemo(
    () =>
      activitiesData?.vm.volunteer?.rosterings
        .filter((r) => {
          const { activity, activityDate } = r.publishedRoster;
          if (activity.program.dateDeleted || activity.program.dateSuspended) return false;

          if (activity.cancelledActivities.find((ca) => ca.activityDate === activityDate)) {
            return false;
          }

          const existsInPrivileged = privilegedReportDates.find(
            (pr) => pr.date === activityDate && pr.activityId === activity.activityId
          );

          return !existsInPrivileged;
        })
        .map((r) => getReportFromActivity(r.publishedRoster.activity, r.publishedRoster.activityDate)) ?? [],
    [activitiesData, privilegedReportDates]
  );

  const activityHasOccurred = (date: any) => unpackToDateTime(date) < DateTime.local();

  const allCompletedReportDates: Array<ActivityReportType> = useMemo(
    () =>
      [...privilegedReportDates, ...rosteredReportDates]
        .filter(
          (r) =>
            r.completedReportSessionsNumber > 0 &&
            r.completedReportSessionsNumber + r.cancelledSessionsNumber === r.sessionsNumber
        )
        .sort((a, b) => (unpackToDate(a.date) < unpackToDate(b.date) ? 1 : -1)),
    [privilegedReportDates, rosteredReportDates]
  );

  const allIncompleteReportDates: Array<ActivityReportType> = useMemo(
    () =>
      [...privilegedReportDates, ...rosteredReportDates]
        .filter((r) => !r.isActivitySuspended)
        .filter(
          (r) =>
            activityHasOccurred(r.date) &&
            r.completedReportSessionsNumber + r.cancelledSessionsNumber < r.sessionsNumber
        )
        .sort((a, b) => (unpackToDate(a.date) < unpackToDate(b.date) ? 1 : -1)),
    [privilegedReportDates, rosteredReportDates]
  );

  const allOmittedReportDates: Array<ActivityReportType> = useMemo(
    () =>
      [...privilegedReportDates, ...rosteredReportDates].filter((r) => r.cancelledSessionsNumber === r.sessionsNumber),
    [privilegedReportDates, rosteredReportDates]
  );

  const reportDates = useMemo(() => {
    switch (selectedTab) {
      case 'complete':
        return allCompletedReportDates;
      case 'incomplete':
        return allIncompleteReportDates;
      case 'omitted':
        return allOmittedReportDates;
      default:
        return [];
    }
  }, [selectedTab, allCompletedReportDates, allIncompleteReportDates]);

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      <CampfireSwitch>
        <Route exact path={match?.path} render={() => <ActivityReportContent />} />
        <Route
          exact
          path={`${match?.path}/submit`}
          render={() => (
            <ActivityReport
              reportDates={reportDates}
              isLoading={getActivitiesIsLoading}
              refeshData={refetchActivityReportActivities}
              activities={activitiesData?.vm.activities ?? []}
              rosterings={activitiesData?.vm.volunteer?.rosterings ?? []}
            />
          )}
        />
      </CampfireSwitch>
      <ActivityReportTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
        }}
      />
    </Page>
  );
});

export { ActivityReportScreen };
