import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { unpackToDateTime } from '@campfire/hot-date';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, LabelImportantRounded } from '@material-ui/icons';
import { convertFromRaw, EditorState } from 'draft-js';
import React, { memo, useEffect } from 'react';
import { SemanticTime } from '../../../../../common/dates/SemanticTime';
import { scrollIntoView } from '../../../../../common/functions/scroll-functions';
import { useUser } from '../../../../../global/auth/useUser';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { MyElementsBulletin } from './__generated__/MyElementsBulletin';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px 16px',
    [theme.breakpoints.up('xl')]: {
      padding: '32px 128px',
    },
  },
}));

export const MyElementsSingleBulletinView = memo(
  ({ bulletin, onClose, refetch }: { bulletin: MyElementsBulletin; onClose: () => void; refetch?: () => void }) => {
    const { title, content, dateCreated, createdBy, dateUpdated, lastUpdatedBy, pinned } = bulletin;
    const ref: React.RefObject<HTMLDivElement> = React.createRef();
    const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
    const classes = useStyles();
    const apiUrl = useApiUrl();
    const { run } = useCampfireFetch({ defer: true });
    const { getVolunteerIdentity } = useUser();
    const volunteerIdentity = getVolunteerIdentity();
    const markBulletinAsRead = (bulletinId: string, volunteerId: string): Promise<any> => {
      return run({
        url: `${apiUrl}/vm/bulletin/mark-as-viewed`,
        method: 'post',
        data: {
          bulletinId,
          volunteerId,
        },
      }).then(() => {
        if (refetch) {
          refetch();
        }
      });
    };
    useEffect(() => {
      scrollIntoView(ref, { behavior: 'smooth' }, true);
      if (volunteerIdentity && !bulletin.hasVolunteerViewedBulletin) {
        markBulletinAsRead(bulletin.bulletinId, volunteerIdentity.volunteerId);
      }
    }, [volunteerIdentity, bulletin]);

    return (
      <Box
        className={classes.container}
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        width={1}
      >
        <Box display='flex'>
          {pinned && (
            <Box>
              <TitularTooltip description='This is pinned'>
                <LabelImportantRounded style={{ color: '#FFE242' }} />
              </TitularTooltip>
            </Box>
          )}
          <Box px={2}>
            <Typography variant='h6' style={{ lineHeight: 1.2 }} gutterBottom>
              {title}
            </Typography>
            {dateUpdated && lastUpdatedBy ? (
              <>
                <Typography color='textSecondary' variant='body2'>
                  Originally posted on{' '}
                  <SemanticTime
                    dateTime={unpackToDateTime(dateCreated)}
                    typographyProps={{ variant: 'body2', color: 'textSecondary' }}
                    displayYear
                  />
                </Typography>
                <Typography color='textSecondary' variant='body2' gutterBottom>
                  {`Updated by ${lastUpdatedBy.profile.preferredName} ${lastUpdatedBy.profile.lastName}, `}
                  <SemanticTime
                    dateTime={unpackToDateTime(dateUpdated)}
                    typographyProps={{ variant: 'body2', color: 'textSecondary' }}
                    displayYear
                  />
                </Typography>
              </>
            ) : (
              dateCreated &&
              createdBy && (
                <Typography color='textSecondary' variant='body2' gutterBottom>
                  {`Posted by ${createdBy.profile.preferredName} ${createdBy.profile.lastName}, `}
                  <SemanticTime
                    dateTime={unpackToDateTime(dateCreated)}
                    typographyProps={{ variant: 'body2', color: 'textSecondary' }}
                    displayYear
                  />
                </Typography>
              )
            )}
            <Box py={2}>
              <CampfireRichEditor editorState={editorState} readOnly />
            </Box>
          </Box>
        </Box>
        <Box>
          <IconButton style={{ padding: 0 }} onClick={() => onClose()}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
      </Box>
    );
  }
);
