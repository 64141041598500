import React from 'react';
import CsvParse from 'papaparse';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { LandingStep } from './steps/LandingStep';
import { UploadFileStep } from './steps/UploadFileStep';
import { SelectNotificationStep } from './steps/SelectNotificationStep';
import { ReviewStep } from './steps/ReviewStep';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';
import { ImportedStep } from './steps/ImportedStep';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { ImportVolunteerGetPrograms } from './queries/__generated__/ImportVolunteerGetPrograms';
import { IMPORT_VOLUNTEERS_GET_PROGRAMS } from './queries/GetProgram.gql';

type ImportVolunteerType = {
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  programId: string;
};

export function ImportingVolunteersScreen() {
  const [step, setStep] = React.useState<number>(0);
  const [uploadingFile, setUploadingFile] = React.useState<File>();
  const [fileData, setFileData] = React.useState<any>([]);
  const [importedData, setImportedData] = React.useState<any>();
  const [message, setMessage] = React.useState(EditorState.createEmpty());
  const [isImporting, setIsImporting] = React.useState(false);

  const onNext = () => setStep((currentStep) => currentStep + 1);
  const onBack = () => setStep((currentStep) => Math.max(0, currentStep - 1));

  const importVolunteer = useEndpointFetch<{ volunteers: ImportVolunteerType[]; message: string }, any>(
    '/vm/volunteer/add-bulk'
  );

  const onImport = (importData: any) => {
    setIsImporting(true);
    onNext();
    importVolunteer
      .run({
        message: stateToHTML(message.getCurrentContent()),
        volunteers: importData,
      })
      .then((response) => {
        setImportedData(response);
        setIsImporting(false);
      });
  };

  const { data: vmPrograms } = useCampfireQuery<ImportVolunteerGetPrograms, {}>(IMPORT_VOLUNTEERS_GET_PROGRAMS, {});
  React.useEffect(() => {
    if (!uploadingFile) {
      return;
    }
    CsvParse.parse(uploadingFile, {
      skipEmptyLines: true,
      complete: (csvData: any) => {
        const volunteers = csvData.data
          .filter((datum: any) => Array.isArray(datum))
          .map((datum: string[], datumIndex: number) => ({
            id: datumIndex.toString(),
            ...datum.reduce(
              (acc, cur, idx) => ({
                ...acc,
                [idx.toString()]: cur,
              }),
              {}
            ),
          }));
        setFileData(volunteers);
      },
    });
  }, [uploadingFile]);

  switch (step) {
    case 0:
      return <LandingStep onNext={onNext} />;

    case 1:
      return (
        <UploadFileStep onBack={onBack} onNext={onNext} onUpload={setUploadingFile} uploadingFile={uploadingFile} />
      );

    case 2:
      return <SelectNotificationStep onNext={onNext} onBack={onBack} message={message} setMessage={setMessage} />;

    case 3:
      return (
        <ReviewStep
          onImport={onImport}
          onBack={onBack}
          data={fileData}
          setData={setFileData}
          vmPrograms={vmPrograms}
          isImporting={isImporting}
        />
      );

    case 4:
      return (
        <ImportedStep
          data={importedData?.data?.data}
          errors={importedData?.data?.errors}
          onBack={() => setStep(3)}
          onComplete={() => setStep(0)}
          vmPrograms={vmPrograms}
          isImporting={isImporting}
        />
      );
    default:
      return null;
  }
}
