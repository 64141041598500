import { TabletButton } from '@campfire/tablet-button/lib';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';

interface Props {
  open: boolean;
  cancel: () => void;
  makeOpen: () => void;
}

export const WaitlistWarningDialogV2 = ({ open, cancel, makeOpen }: Props) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{'Make this activity open to volunteers?'}</DialogTitle>
      <DialogContent>
        {'All incoming volunteers currently pending approval will be moved to the waitlist.'}
      </DialogContent>
      <DialogActions>
        <TabletButton onClick={cancel}>{'Cancel'}</TabletButton>
        <TabletButton variant='contained' color='primary' onClick={makeOpen}>
          {'Make open'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};
