import React, { memo, useMemo, useState } from 'react';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { unpackToDateTime } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FiberNew, LabelImportantRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import { convertFromRaw, EditorState } from 'draft-js';
import { DateTime } from 'luxon';
import { SemanticTime } from '../../../../../common/dates/SemanticTime';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { MyElementsBulletin } from './__generated__/MyElementsBulletin';

type MyElementsBulletinsTabProps = {
  impactLoading: boolean;
  initialLoading: boolean;
  bulletins: MyElementsBulletin[];
  setSelectedBulletinId: (x: string) => void;
  scrollToTop: () => void;
};
export const MyElementsBulletinsTab = (props: MyElementsBulletinsTabProps) => {
  const { impactLoading, initialLoading, bulletins, setSelectedBulletinId, scrollToTop } = props;
  const { isMobile } = useCampfireTheme();

  const [showAll, setShowAll] = useState(!isMobile);

  const pinnedBulletins = useMemo(() => {
    return bulletins.filter((bulletin) => bulletin.pinned);
  }, [bulletins]);

  const otherBulletins = useMemo(() => {
    const unpinned = bulletins.filter((b) => !b.pinned);
    return showAll ? unpinned : unpinned.slice(0, 3);
  }, [bulletins, showAll]);

  const hiddenBulletinCount = bulletins.length - (pinnedBulletins.length + otherBulletins.length);

  if (impactLoading || initialLoading)
    return (
      <Box px={3} py={2}>
        <Skeleton variant='text' width={200} height={16} />
        <Skeleton variant='text' width={150} height={10} />
        <Skeleton variant='text' width={100} height={9} />
      </Box>
    );
  if (!bulletins.length)
    return (
      <Box px={3} py={2}>
        <Typography>There are currently no bulletins to view.</Typography>
      </Box>
    );
  return (
    <Box title='Bulletin List' width={1}>
      {pinnedBulletins.map((bulletin) => {
        return (
          <MyElementsBulletinThumbnail
            key={bulletin.bulletinId}
            bulletin={bulletin}
            onClick={() => {
              setSelectedBulletinId(bulletin.bulletinId);
              scrollToTop();
            }}
            borderBottom
            pinnedBackground
          />
        );
      })}
      {otherBulletins.map((bulletin, index) => {
        return (
          <MyElementsBulletinThumbnail
            key={bulletin.bulletinId}
            bulletin={bulletin}
            onClick={() => {
              setSelectedBulletinId(bulletin.bulletinId);
              scrollToTop();
            }}
            borderBottom={index < otherBulletins.length - 1}
          />
        );
      })}

      {!showAll && hiddenBulletinCount !== 0 ? (
        <>
          <Box paddingTop={2} paddingLeft='4px'>
            <HoverText
              color='primary'
              variant='body2'
              onClick={() => setShowAll(true)}
              style={{ marginLeft: 16, marginBottom: 16 }}
              disableUnderline
            >
              {`Show ${hiddenBulletinCount} more bulletin${hiddenBulletinCount !== 1 ? 's' : ''}`}
            </HoverText>
          </Box>
          <Divider />
        </>
      ) : null}
    </Box>
  );
};

const useThumbnailStyles = () => {
  const { theme } = useCampfireTheme();
  return makeStyles({
    thumbnail: {
      '&:hover': {
        cursor: 'pointer',
      },
      padding: '16px 24px',
      [theme.breakpoints.up('xl')]: {
        padding: '16px 128px',
      },
    },
    hoverBackground: {
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    pinnedBackground: {
      backgroundColor: 'rgba(255, 226, 66, 0.15)',
    },
    opened: {
      opacity: 0.8,
    },
  })();
};

const MyElementsBulletinThumbnail = memo(
  ({
    bulletin,
    onClick,
    pinnedBackground,
    borderBottom,
  }: {
    bulletin: MyElementsBulletin;
    onClick: () => void;
    pinnedBackground?: boolean;
    borderBottom: boolean;
  }) => {
    const {
      title,
      content,
      dateCreated,
      createdBy,
      dateUpdated,
      lastUpdatedBy,
      pinned,
      hasVolunteerViewedBulletin,
    } = bulletin;

    const editorState = useMemo(() => EditorState.createWithContent(convertFromRaw(JSON.parse(content))), [content]);
    const classes = useThumbnailStyles();
    return (
      <>
        <Box
          className={classNames(classes.thumbnail, {
            [classes.pinnedBackground]: pinned && pinnedBackground,
            [classes.opened]: hasVolunteerViewedBulletin,
            [classes.hoverBackground]: !pinnedBackground,
          })}
          onClick={onClick}
        >
          <Box display='flex' alignItems='center' justifyContent={pinned ? 'space-between' : 'flex-end'} height={40}>
            {pinned && (
              <Box>
                <Box
                  bgcolor='#FABE4D'
                  display='flex'
                  component='div'
                  alignItems='center'
                  color='white'
                  padding={'3px 10px 3px 6px'}
                  paddingRight={1.5}
                  borderRadius={4}
                >
                  <LabelImportantRounded />
                  <Box ml={0.5} component='span'>
                    <Typography style={{ fontSize: '0.835rem', fontWeight: 550 }}>PINNED</Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <Box display='inline-flex' alignItems='center'>
              <SemanticTime
                dateTime={DateTime.fromISO(dateCreated)}
                typographyProps={{ variant: 'body2', color: 'textSecondary' }}
                displayYear
              />
              <Box pl={1}>{!hasVolunteerViewedBulletin && <FiberNew color='error' fontSize='large' />}</Box>
            </Box>
          </Box>
          <Typography variant='h6' display='inline' style={{ lineHeight: 1.2 }} gutterBottom>
            {title}
          </Typography>
          <Box height={38} my={1} overflow='hidden'>
            <CampfireRichEditor editorState={editorState} readOnly />
          </Box>
          <Box>
            <Typography color='textSecondary' variant='body2'>
              {lastUpdatedBy
                ? `${lastUpdatedBy.profile.preferredName} ${lastUpdatedBy.profile.lastName}`
                : createdBy && `${createdBy.profile.preferredName} ${createdBy.profile.lastName}`}{' '}
              {dateUpdated && (
                <>
                  (Updated{' '}
                  <SemanticTime
                    dateTime={unpackToDateTime(dateUpdated)}
                    typographyProps={{ variant: 'body2', color: 'textSecondary' }}
                    displayYear
                  />
                  )
                </>
              )}
            </Typography>
          </Box>
        </Box>
        {borderBottom && <Divider />}
      </>
    );
  }
);
