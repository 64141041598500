import React from 'react';
import { AlertInfoDialog } from '../../../../../common/dialogs/AlertInfoDialog';

interface Props {
  open: boolean;
  close: () => void;
}

const NoDeleteLastProgramDialog = (props: Props) => {
  const { open, close } = props;

  return (
    <AlertInfoDialog
      open={open}
      onClose={close}
      title={`You can't delete your only program`}
      content={[
        'Volaby requires you to maintain at least one active program for new volunteers to join.',
        'If you still want to delete this program, please create a new one first and consider transferring some or all of your volunteers.',
      ]}
    />
  );
};

export { NoDeleteLastProgramDialog };
