import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  COLLAPSED_VIEW_COLUMN_NO,
  EXPANDED_VIEW_COLUMN_NO,
  RosterHeader,
  useSharedSessionResults,
} from './RosterHeader';
import { RosterActionsFooter } from './RosterActionsFooter';
import { CurrentRosterState, RowAction, UpdatedRostering, Volunteer } from './roster-types';
import { GetRosterScreenRosterData_vm_activity_sessions as Session } from './__generated__/GetRosterScreenRosterData';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { RosterTable, RosterTableForPrint } from './roster-table/RosterTable';
import { useSharedPromptState } from './roster-table/RosterTableSharedState';
import { getRosterAction, getRosteringSessionStatus, getSessionStatuses } from './roster-functions';
import { MaxVolunteersPrompt } from './roster-table/MaxVolunteersPrompt';
import { RosterNotesDialog } from '../../common/RosterNotesDialog';
import { RosterScreenDraftRoster_sessionNotes as SesionNoteType } from './__generated__/RosterScreenDraftRoster';
import { ConflictingDialog } from '../../bulk-roster/ConflictingDialog';
import { ConflictingRosterType, useGetConflictingRosters } from '../../useGetConflictingRoster';

export const ACTIVITY_TEAM_ROSTER = '215px';

type SettingRoster = {
  volunteerId: string;
  sessionId: string;
  roster: any;
};

interface Props {
  smsConfirmationRefetch: () => void;
  loading: boolean;
  sessions: Session[];
  draftRoster?: Volunteer[];
  updateRostering: (volunteerId: string, sessionId: string, action: RowAction) => void;
  updatedRosterings: UpdatedRostering[];
  currentRosterState: CurrentRosterState;
  hasPublishedRoster: boolean;
  handlePublishRoster: (x: boolean) => void;
  handleDiscardChanges: () => void;
  setOpenAddVolunteersDialog: (x: boolean) => void;
  isRosterCancelled: boolean;
  isRosterCompleted: boolean;
  onSubmitReport: any;
  publishedRoster: any;
  activityDate: string;
  activityName: string;
  activityStatus: string;
  activityId: string;
  onChangeAvailability: (volunteerId: string) => void;
  setShowCancelActivityDialog: (x: boolean) => void;
  handleSuccessRefetch?: () => void;
  rosterNotes?: string | null;
  onSaveRosterNotes: (rosterNotes: string) => void;
  sessionNotes: SesionNoteType[];
  onChangeSessionNote: (sessionId: string, notes: string) => void;
  componentRef: React.RefObject<HTMLDivElement>;
  orgLogo?: string | null;
}

export const Roster = (props: Props) => {
  const {
    sessionNotes,
    onChangeSessionNote,
    loading,
    setOpenAddVolunteersDialog,
    sessions,
    draftRoster,
    updateRostering,
    updatedRosterings,
    currentRosterState,
    hasPublishedRoster,
    handlePublishRoster,
    handleDiscardChanges,
    isRosterCancelled,
    isRosterCompleted,
    onSubmitReport,
    publishedRoster,
    activityDate,
    activityName,
    activityStatus,
    activityId,
    onChangeAvailability,
    setShowCancelActivityDialog,
    handleSuccessRefetch,
    rosterNotes,
    onSaveRosterNotes,
    componentRef,
    orgLogo,
  } = props;
  const [sortVolsRowBy, setSortVolsRowBy] = useQueryParam('sortVolsRowBy', StringParam);
  const [sortedVolunteerIdsByEnrolmentDate, setSortedVolunteerIdsByEnrolmentDate] = React.useState<
    string[] | undefined
  >();
  const selectedRoster = useRef<Volunteer>();
  const { theme } = useCampfireTheme();
  const sessionStatuses = getSessionStatuses(theme);
  const [showRosterNotes, setShowRosterNotes] = React.useState(false);
  const [isEditRosterNote, setIsEditRosterNote] = React.useState(false);
  const [settingRoster, setSettingRoster] = React.useState<SettingRoster | null>(null);

  const {
    sessionResultsStart,
    sessionResultsEnd,
    sessionPage,
    setSessionPage,
    columnSize,
    setColumnSize,
    isExpanded,
    totalSessionPages,
    setTotalSessionPages,
  } = useSharedSessionResults();

  const sessionsData = sessions?.map((s) => {
    if (draftRoster && s.maxVolunteers) {
      const attending: any[] = draftRoster.flatMap((dr) => {
        const attendingLower = dr.publishedSessionIds.filter((ps) => {
          return ps === s.sessionId;
        });
        return attendingLower;
      });
      return {
        ...s,
        isMaxed: attending.length >= s.maxVolunteers,
      };
    }
    return s;
  });
  const [viewMaxVolunteersPrompt, setViewMaxVolunteersPrompt] = useState<boolean>(false);
  const fetchConflictRoster = useGetConflictingRosters();
  const [conflictingRoster, setConflictingRoster] = React.useState<ConflictingRosterType>([]);
  const getConflictRoster = (volunteerId: string, sessionId: string) => {
    const session = sessionsData.find((s: any) => s?.sessionId === sessionId);
    if (!session || !session.startTime || !session.endTime) {
      return Promise.resolve({ conflictingRosters: [] });
    }

    return fetchConflictRoster
      .run({
        volunteerId,
        activityDate,
        startTime: session?.startTime,
        endTime: session?.endTime,
      })
      .then((response) => response?.data?.data);
  };
  useEffect(() => {
    if (isExpanded) {
      setColumnSize(EXPANDED_VIEW_COLUMN_NO);
      return;
    }
    if (columnSize !== COLLAPSED_VIEW_COLUMN_NO) {
      setColumnSize(COLLAPSED_VIEW_COLUMN_NO);
    }
  }, [isExpanded]);

  useEffect(() => setTotalSessionPages(Math.ceil(sessionsData.length / columnSize)), [sessionsData, columnSize]);

  useEffect(() => {
    if (totalSessionPages && sessionPage > totalSessionPages) {
      setSessionPage(sessionPage - 1);
    }
  }, [totalSessionPages]);

  const slicedSessions = useMemo(() => {
    return sessionsData.slice(sessionResultsStart, sessionResultsEnd);
  }, [sessionsData, sessionResultsStart, sessionResultsEnd]);

  const {
    promptVolunteerId,
    promptSessionId,
    setPromptVolunteerId,
    setPromptSessionId,
    hidePromptForDate,
    setHidePromptForDate,
  } = useSharedPromptState();

  useEffect(() => {
    if (activityId && !hasPublishedRoster) {
      setSortVolsRowBy('availability');
    }
  }, [hasPublishedRoster, activityId]);

  const showMaxVolunteersPrompt = (showPrompt: boolean, volunteerId: string, sessionId: string) => {
    setViewMaxVolunteersPrompt(showPrompt);
    setPromptVolunteerId(volunteerId);
    setPromptSessionId(sessionId);
  };

  const checkPromptSessionStatus = (sessionStatus: string) => {
    if (sessionStatus === 'Rostered') {
      return false;
    }
    if (sessionStatus === 'To be rostered') {
      return false;
    }
    return true;
  };

  const onClickRosterStatus = (session: any, roster: any) => {
    selectedRoster.current = roster;
    if (
      session.isMaxed &&
      !hidePromptForDate &&
      checkPromptSessionStatus(getRosteringSessionStatus(session.sessionId, roster, sessionStatuses).text)
    ) {
      showMaxVolunteersPrompt(true, roster.volunteerId, session.sessionId);
      return;
    }
    const action = getRosterAction(getRosteringSessionStatus(session.sessionId, roster, sessionStatuses).text);

    if (action === 'add') {
      getConflictRoster(roster.volunteerId, session.sessionId).then((res) => {
        if (!res) return;
        setConflictingRoster(res.conflictingRosters);
        if (res.conflictingRosters.length > 0) {
          setSettingRoster({
            sessionId: session.sessionId,
            volunteerId: roster.volunteerId,
            roster,
          });
        } else {
          updateRostering(
            roster.volunteerId,
            session.sessionId,
            getRosterAction(getRosteringSessionStatus(session.sessionId, roster, sessionStatuses).text)
          );
        }
      });
    } else {
      updateRostering(
        roster.volunteerId,
        session.sessionId,
        getRosterAction(getRosteringSessionStatus(session.sessionId, roster, sessionStatuses).text)
      );
    }
  };

  const onCancelConflict = () => {
    setSettingRoster(null);
  };

  const onContinueConflict = () => {
    if (settingRoster === null) {
      return;
    }
    updateRostering(
      settingRoster.volunteerId,
      settingRoster.sessionId,
      getRosterAction(getRosteringSessionStatus(settingRoster.sessionId, settingRoster.roster, sessionStatuses).text)
    );
    setSettingRoster(null);
  };

  const onTick = (tick: boolean) => setHidePromptForDate(tick);
  const onOk = () => {
    if (selectedRoster.current) {
      updateRostering(
        promptVolunteerId,
        promptSessionId,
        getRosterAction(getRosteringSessionStatus(promptSessionId, selectedRoster.current, sessionStatuses).text)
      );
    }
    setViewMaxVolunteersPrompt(false);
  };

  const onShowRosterNotes = (type: string) => {
    setIsEditRosterNote(type === 'edit');
    setShowRosterNotes(true);
  };

  const handleSaveRosterNotes = (newRosterNotes: string) => {
    onSaveRosterNotes(newRosterNotes);
    setShowRosterNotes(false);
  };

  return (
    <>
      {viewMaxVolunteersPrompt && (
        <MaxVolunteersPrompt onCancel={() => setViewMaxVolunteersPrompt(false)} onOK={onOk} onTick={onTick} />
      )}
      <LinearProgressOverlay isLoading={loading} />
      <Box
        marginTop='1rem'
        paddingBottom='6rem'
        style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
        minHeight={'auto'}
        position='relative'
      >
        <Box position='sticky' top={0} bgcolor='white' zIndex={'100'} paddingTop={'1em'}>
          <RosterHeader
            rosterSessions={sessionsData}
            activityName={activityName}
            activityDate={activityDate}
            activityStatus={activityStatus}
            activityId={activityId}
            setShowCancelActivityDialog={setShowCancelActivityDialog}
            handleSuccessRefetch={handleSuccessRefetch}
            hasPublishedRoster={hasPublishedRoster}
            currentRosterState={currentRosterState}
            isRosterCancelled={isRosterCancelled}
            isRosterCompleted={isRosterCompleted}
            onAddVoluteer={() => setOpenAddVolunteersDialog(true)}
            setSortedVolunteerIdsByEnrolmentDate={setSortedVolunteerIdsByEnrolmentDate}
            onShowRosterNotes={onShowRosterNotes}
            hasRosterNotes={Boolean(rosterNotes)}
            componentRef={componentRef}
          />
        </Box>
        <div style={{ display: 'none' }}>
          <div ref={componentRef}>
            <RosterTableForPrint
              key={`${activityId}-${activityDate}`}
              activityName={activityName}
              orgLogo={orgLogo}
              onChangeAvailability={onChangeAvailability}
              sessions={sessionsData}
              draftRosters={draftRoster || []}
              onClickRosterStatus={onClickRosterStatus}
              isRosterCancelled={isRosterCancelled}
              isRosterCompleted={isRosterCompleted}
              onSubmitReport={onSubmitReport}
              publishedRoster={publishedRoster}
              activityDate={activityDate}
              sortBy={sortVolsRowBy}
              sortedVolunteerIdsByEnrolmentDate={sortedVolunteerIdsByEnrolmentDate}
              sessionNotes={sessionNotes}
              onChangeSessionNote={onChangeSessionNote}
            />
          </div>
        </div>
        <RosterTable
          key={`${activityId}-${activityDate}`}
          onChangeAvailability={onChangeAvailability}
          sessions={slicedSessions}
          draftRosters={draftRoster || []}
          onClickRosterStatus={onClickRosterStatus}
          isRosterCancelled={isRosterCancelled}
          isRosterCompleted={isRosterCompleted}
          onSubmitReport={onSubmitReport}
          publishedRoster={publishedRoster}
          activityDate={activityDate}
          sortBy={sortVolsRowBy}
          sortedVolunteerIdsByEnrolmentDate={sortedVolunteerIdsByEnrolmentDate}
          sessionNotes={sessionNotes}
          onChangeSessionNote={onChangeSessionNote}
        />
      </Box>
      {showRosterNotes && (
        <RosterNotesDialog
          open
          edit={isEditRosterNote}
          rosterNotes={rosterNotes}
          onCancel={() => setShowRosterNotes(false)}
          onSave={handleSaveRosterNotes}
        />
      )}
      {!isRosterCancelled && (
        <RosterActionsFooter
          open={currentRosterState !== 'published'}
          hasUnsavedChanges={currentRosterState === 'not-saved'}
          hasPublishedRoster={hasPublishedRoster}
          changedVolunteers={updatedRosterings}
          onPublishRosterClick={() => handlePublishRoster(true)}
          onSaveDraftClick={() => handlePublishRoster(false)}
          onDiscardChangesClick={handleDiscardChanges}
          isSubmitting={false}
          suppressDrafts={false}
        />
      )}
      {settingRoster && (
        <ConflictingDialog
          onCancel={onCancelConflict}
          onContinue={onContinueConflict}
          conflictRosters={conflictingRoster}
        />
      )}
    </>
  );
};
