import { Cake, CakeActions, CakeContent, CakeHeader } from '@campfire/cake';
import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Theme, Typography } from '@material-ui/core';
import { SendRounded } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { arrayHead } from '../../common/functions/array-head';
import { TaskCompleteIcon } from '../../common/icons/TaskCompleteIcon';
import { useSession } from '../auth/useSession';
import { useUser } from '../auth/useUser';
import { useSnackbar } from '../config/useSnackbar';
import { recordInteraction } from '../interactions/recordInteraction';
import { useCampfireQuery } from '../network/useCampfireQuery';
import { useEndpointFetch } from '../network/useEndpointFetch';
import { GET_ALL_APPLICANT_TASKS } from './model/applicant-shell-query.gql';
import { GET_USER_INTERACTIONS } from './model/user-interactions-query.gql';
import { GetAllApplicantTasks, GetAllApplicantTasksVariables } from './model/__generated__/GetAllApplicantTasks';
import { GetUserInteractions, GetUserInteractionsVariables } from './model/__generated__/GetUserInteractions';
import { SUYPOverviewTasksListTask } from './model/__generated__/SUYPOverviewTasksListTask';

const CONTRACT_SUBMISSION_TOKEN_RETRY_LIMIT = 30;
const CONTRACT_SUBMISSION_TOKEN_REFRESH_INTERVAL = 5000;

type ApplicantShellTabs = 'required-tasks' | 'extra-tasks';

type ApplicantTaskListItem = {
  task: SUYPOverviewTasksListTask;
  completedDate?: string;
  status?: string;
};

const SUYPOverview = () => {
  const [selectedTab, setSelectedTab] = useQueryParam<ApplicantShellTabs>('view');
  const { reloadSession } = useSession();
  // const tabClasses = useTabClasses();
  const progressClasses = useProgressClasses();
  const { user } = useUser();
  const { userId } = user;
  const { postInteraction } = recordInteraction('vm');
  const { setSnackbar } = useSnackbar();

  useLayoutEffect(() => {
    if (selectedTab === undefined) {
      setSelectedTab('required-tasks');
    }
  }, []);

  const completeVolunteerProfile = useEndpointFetch<{ profileId: string }>('/vm/volunteer/profile/complete');

  const allApplicantTasksQuery = useCampfireQuery<GetAllApplicantTasks, GetAllApplicantTasksVariables>(
    GET_ALL_APPLICANT_TASKS,
    {
      options: {
        variables: {
          userId,
        },
      },
    }
  );
  const { data: interactionsResponse, loading: interactionsLoading } = useCampfireQuery<
    GetUserInteractions,
    GetUserInteractionsVariables
  >(GET_USER_INTERACTIONS, {
    options: {
      variables: {
        page: 'suyp',
        action: 'viewed',
      },
    },
  });

  useEffect(() => {
    if (interactionsResponse && !interactionsResponse.vm.hasUserInteraction) postInteraction('suyp', 'viewed');
  }, [interactionsResponse]);

  const profileId = useMemo(() => {
    return allApplicantTasksQuery.data?.vm.profile?.profileId;
  }, [allApplicantTasksQuery.data]);

  const preferredName = useMemo(() => {
    return allApplicantTasksQuery.data?.vm.profile?.preferredName;
  }, [allApplicantTasksQuery.data]);

  const requiredTasks: Array<ApplicantTaskListItem> = useMemo(() => {
    if (!allApplicantTasksQuery.data) return [];
    return (
      allApplicantTasksQuery.data.vm.application?.relevantTasks
        // tasks from cakeType required
        .filter((task) => task.cake.cakeType === 'required' && !task.dateRemoved)
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((task) => {
          const maybeCompletedTask = allApplicantTasksQuery.data?.vm.profile?.completedTasks.find(
            (completedTask) => completedTask.task.taskId === task.taskId && completedTask.dateRevoked === null
          );
          return {
            task,
            completedDate: maybeCompletedTask?.dateLastUpdated,
            status: maybeCompletedTask?.status,
          };
        }) ?? []
    );
  }, [allApplicantTasksQuery.data]);

  const extraTasks: Array<ApplicantTaskListItem> = useMemo(() => {
    if (!allApplicantTasksQuery.data) return [];
    return (
      allApplicantTasksQuery.data.vm.application?.relevantTasks
        // tasks from cakeType extra
        .filter((task) => task.cake.cakeType === 'extra' && !task.dateRemoved)
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((task) => {
          const maybeCompletedTask = allApplicantTasksQuery.data?.vm.profile?.completedTasks.find(
            (completedTask) => completedTask.task.taskId === task.taskId && completedTask.dateRevoked === null
          );
          return {
            task,
            completedDate: maybeCompletedTask?.dateLastUpdated,
            status: maybeCompletedTask?.status,
          };
        }) ?? []
    );
  }, [allApplicantTasksQuery.data]);

  const requiredCake = useMemo(() => {
    return arrayHead(
      (allApplicantTasksQuery?.data?.vm.application?.relevantTasks ?? []).filter(
        (task) => task.cake.cakeType === 'required'
      ) ?? []
    )?.cake;
  }, [allApplicantTasksQuery.data]);

  const extraCake = useMemo(() => {
    return arrayHead(
      (allApplicantTasksQuery?.data?.vm.application?.relevantTasks ?? []).filter(
        (task) => task.cake.cakeType === 'extra'
      ) ?? []
    )?.cake;
  }, [allApplicantTasksQuery.data]);

  const requiredTasksCompletionProgress = useMemo(() => {
    if (requiredTasks.length === 0) return 0;
    if (
      requiredTasks.length === requiredTasks.filter((task) => !!task.completedDate && task.status === 'approved').length
    ) {
      return 100;
    }
    const numberCompletedTasks = requiredTasks.reduce(
      (previous, current) => previous + (current.completedDate ? 1 : 0),
      0
    );
    return Math.round((numberCompletedTasks / requiredTasks.length) * 100);
  }, [requiredTasks]);

  const requiredTasksComplete = requiredTasksCompletionProgress === 100;

  const tasksRequiringApproval = useMemo(() => {
    return requiredTasks.filter((task) => task.status === 'pending' || task.status === 'rejected');
  }, [requiredTasks]);

  /**
   * Bunch of functionality to handle retry refresh token.
   * We spin this up if the volunteer profile successfully submits, because we're expecting there to be a resulting
   * volunteer entity within some reasonable time period.
   */
  const [refreshTokenTrys, setRefreshTokenTrys] = useState<number | undefined>();

  useEffect(() => {
    const isActive = refreshTokenTrys !== undefined && refreshTokenTrys < CONTRACT_SUBMISSION_TOKEN_RETRY_LIMIT;
    let interval: any;
    if (isActive) {
      interval = setInterval(() => {
        reloadSession();
        setRefreshTokenTrys((refreshTokenTrysPrev) => (refreshTokenTrysPrev ? refreshTokenTrysPrev + 1 : 1));
      }, CONTRACT_SUBMISSION_TOKEN_REFRESH_INTERVAL);
    } else if (!isActive && refreshTokenTrys !== undefined && interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [refreshTokenTrys]);

  const handleSubmit = () => {
    if (!profileId) {
      return;
    }
    completeVolunteerProfile.run({ profileId }).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to submit volunteer profile',
          variant: 'error',
        });
        return;
      }

      setSnackbar({
        open: true,
        message: 'Volunteer profile submitted',
        variant: 'success',
      });
      reloadSession();
      setRefreshTokenTrys(1);
    });
  };

  return (
    <Box
      overflow='hidden auto'
      bgcolor='#f1f1f1'
      display='flex'
      flex='1 1 auto'
      flexDirection='column'
      width={1}
      paddingTop={2}
      paddingBottom={8}
      paddingX={1}
      boxSizing='border-box'
      style={{
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <CircularProgressOverlay
        isLoading={
          interactionsLoading ||
          completeVolunteerProfile.isLoading ||
          (refreshTokenTrys !== undefined &&
            refreshTokenTrys > 0 &&
            refreshTokenTrys < CONTRACT_SUBMISSION_TOKEN_RETRY_LIMIT)
        }
      />

      {/* {extraCake ? (
        <Tabs
          centered
          indicatorColor='primary'
          value={selectedTab === 'required-tasks' ? 0 : 1}
          aria-label='field editor tabs'
        >
          <Tab
            classes={tabClasses}
            disableRipple
            disableTouchRipple
            key={0}
            label={'Onboarding Tasks'}
            onClick={() => setSelectedTab('required-tasks')}
          />
          <Tab
            classes={tabClasses}
            disableRipple
            disableTouchRipple
            key={1}
            label={'Other Tasks'}
            onClick={() => setSelectedTab('extra-tasks')}
          />
        </Tabs>
      ) : null} */}

      <Box>
        {selectedTab === 'required-tasks' ? (
          <>
            <Box width={1} display='flex' justifyContent='center'>
              <Box width={1} maxWidth={720} marginBottom={3}>
                <Cake>
                  <WelcomeCakeHeader
                    preferredName={preferredName}
                    hasUserInteraction={interactionsResponse?.vm.hasUserInteraction}
                    requiredTasksComplete={requiredTasksComplete}
                  />
                  {/* Only show message to people who have completed all tasks with any that require approval */}
                  {tasksRequiringApproval.length > 0 && requiredTasksComplete ? (
                    <CakeActions>
                      <VolunteerProfileAwaitingReviewMessage />
                    </CakeActions>
                  ) : requiredTasksComplete ? (
                    <CakeActions>
                      <VolunteerProfileSubmitYourProfileMessage handleSubmit={handleSubmit} />
                    </CakeActions>
                  ) : (
                    <CakeContent>
                      <Box paddingRight={1} paddingBottom={1} width={1} display='flex' justifyContent='flex-end'>
                        <Typography variant='body2'>{`${requiredTasksCompletionProgress}% complete`}</Typography>
                      </Box>
                      <Box width={1}>
                        <LinearProgress
                          value={requiredTasksCompletionProgress}
                          variant='determinate'
                          classes={progressClasses}
                        />
                      </Box>
                    </CakeContent>
                  )}
                </Cake>
              </Box>
            </Box>

            <Box width={1} display='flex' justifyContent='center'>
              <Box width={1} maxWidth={720} marginBottom={3}>
                <Cake>
                  <CakeHeader
                    heading='Complete these tasks to set up your Volaby profile'
                    description={
                      <Typography variant='body2' color='textSecondary'>
                        {requiredCake ? requiredCake.description : ''}
                      </Typography>
                    }
                    // actions={<CakeImage src='/campfire-camping-gear-001.svg' alt='Volaby camping gear' />}
                  />
                  <CakeContent style={{ padding: '8px 0px' }}>
                    <List>
                      {requiredTasks.map((task) => (
                        <TaskListItem task={task} key={task.task.taskId} />
                      ))}
                    </List>
                  </CakeContent>
                </Cake>
              </Box>
            </Box>
          </>
        ) : (
          <>
            {requiredTasksComplete ? (
              <Box width={1} display='flex' justifyContent='center'>
                <Box width={1} maxWidth={720} marginBottom={3}>
                  <Cake>
                    <WelcomeCakeHeader
                      preferredName={preferredName}
                      hasUserInteraction={interactionsResponse?.vm.hasUserInteraction}
                      requiredTasksComplete={requiredTasksComplete}
                    />

                    {/* Only show message to people who have completed all tasks with any that require approval */}
                    {tasksRequiringApproval.length > 0 && requiredTasksComplete ? (
                      <CakeActions>
                        <VolunteerProfileAwaitingReviewMessage />
                      </CakeActions>
                    ) : requiredTasksComplete ? (
                      <CakeActions>
                        <VolunteerProfileSubmitYourProfileMessage handleSubmit={handleSubmit} />
                      </CakeActions>
                    ) : null}
                  </Cake>
                </Box>
              </Box>
            ) : null}
            <Box width={1} display='flex' justifyContent='center'>
              <Box width={1} maxWidth={720} marginBottom={3}>
                <Cake>
                  <CakeHeader
                    heading='Polish off any of these tasks if they fit you.'
                    description={
                      <Typography variant='body2' color='textSecondary'>
                        {extraCake ? extraCake.description : ''}
                      </Typography>
                    }
                    // actions={<CakeImage src='/campfire-todo-list-001.svg' alt='Volaby camping gear' />}
                  />
                  <CakeContent>
                    <List>
                      {extraTasks.map((task) => (
                        <TaskListItem task={task} key={task.task.taskId} />
                      ))}
                    </List>
                  </CakeContent>
                </Cake>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

interface TaskListItemProps {
  task: ApplicantTaskListItem;
}

const TaskListItem = ({ task }: TaskListItemProps) => {
  const taskListItemClasses = useTaskListItemClasses();
  const location = useLocation();

  return (
    <Link
      to={{
        pathname: `/task/${task.task.taskId}`,
        search: task.completedDate ? `${location.search}` : location.search,
      }}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <ListItem className={taskListItemClasses.listItem}>
        <ListItemIcon>
          <TaskCompleteIcon isComplete={!!task.completedDate} />
        </ListItemIcon>
        <ListItemText primary={task.task.title} secondary={task.task.description} />
      </ListItem>
    </Link>
  );
};

interface WelcomeCakeHeaderProps {
  preferredName?: string;
  hasUserInteraction?: boolean;
  requiredTasksComplete?: boolean;
}

const WelcomeCakeHeader = ({ preferredName, hasUserInteraction, requiredTasksComplete }: WelcomeCakeHeaderProps) => (
  <CakeHeader
    heading={
      !preferredName ? '...' : hasUserInteraction ? `Welcome back, ${preferredName}` : `Welcome, ${preferredName}`
    }
    description={
      <Typography variant='body2' color='textSecondary'>
        {requiredTasksComplete ? `All done, ready when you are!` : `We're so excited to have you!`}
      </Typography>
    }
    // actions={<CakeImage src='/campfire-fireplace-001.svg' alt='Volaby Fireplace Graphic (very cute)' />}
  />
);

// const useTabClasses = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       boxSizing: 'border-box',
//       textTransform: 'none',
//       paddingTop: 0,
//       paddingBottom: 0,
//       paddingLeft: theme.spacing(2),
//       paddingRight: theme.spacing(2),
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//       '&:hover': {
//         color: theme.palette.primary.light,
//         opacity: 1,
//       },
//       '&$selected': {
//         color: theme.palette.primary.main,
//       },
//       '&:focus': {
//         color: theme.palette.primary.main,
//       },
//     },
//     selected: {},
//   })
// );

const useProgressClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 12,
      borderRadius: 16,
      backgroundColor: theme.palette.grey[200],
    },
    bar: {
      borderRadius: 16,
    },
  })
);

const useTaskListItemClasses = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
    },
    checkIcon: {
      fontSize: 29,
      color: '#00ed7e',
    },
    circleIcon: {
      fontSize: 29,
      color: theme.palette.grey[200],
    },
  })
);

const VolunteerProfileAwaitingReviewMessage = () => (
  <Box paddingRight={1} paddingBottom={1} width={1} display='flex' justifyContent='center'>
    <Box textAlign='center' paddingY={3}>
      <Typography variant='h5' style={{ fontWeight: 'bold' }} gutterBottom>
        <span role='img' aria-label='yay' style={{ marginRight: 8 }}>
          ⏳
        </span>
        Awaiting review
      </Typography>
      <Typography
        variant='body2'
        color='textSecondary'
      >{`We'll email you as soon as somebody has reviewed your tasks.`}</Typography>
    </Box>
  </Box>
);

const VolunteerProfileSubmitYourProfileMessage = ({ handleSubmit }: { handleSubmit: () => void }) => (
  <Box width={1} paddingY={2} textAlign='center' display='flex' justifyContent='center'>
    <Box>
      <Box textAlign='center' paddingBottom={1}>
        <Typography variant='h5' style={{ fontWeight: 'bold' }} gutterBottom>
          All tasks complete
        </Typography>
        <Typography variant='body2' color='textSecondary'>{`Ready to activate your volunteer profile?`}</Typography>
      </Box>
      <TabletButton
        variant='contained'
        color='primary'
        style={{ margin: 8 }}
        size='large'
        onClick={handleSubmit}
        endIcon={<SendRounded />}
      >
        {'Submit your profile'}
      </TabletButton>
    </Box>
  </Box>
);

export { SUYPOverview };
