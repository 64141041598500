import React from 'react';
import { FormGroup, Box, TextField, MenuItem, ListItemText, Checkbox } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { FixedSizeList as List } from 'react-window';
import { ActivityItem, SelectableActivityTag } from './ActivityItem';
import { FullscreenDialog } from '../../../../../../common/dialogs/FullscreenDialog';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { SearchField } from '../../../../../../common/inputs/SearchField';

const SELECT_NONE_VALUE = 'none';
const SELECT_ALL_VALUE = 'all';

const textFieldStyle = makeStyles(() =>
  createStyles({
    input: {
      paddingTop: '8px',
      paddingBottom: '8px',
      fontSize: '14px',
      height: '19px',
    },
    root: {
      borderRadius: '6px',
      borderTopColor: '#9E9E9E',
      borderRightColor: '#9E9E9E',
      borderBottomColor: '#9E9E9E',
      borderLeftColor: '#9E9E9E',
      color: '#444444',
    },
    adornedStart: {
      paddingLeft: '0.75rem',
    },
  })
);
interface Props {
  activities: SelectableActivityTag[];
  onChange: (roleId: string) => void;
  title: string;
  programOptions: {
    label: string;
    value: string;
  }[];
  onClose: () => void;
}

const Row = ({ index, style, data }: any) => {
  return <ActivityItem activity={data.activities[index]} onChange={data.onChange} style={style} />;
};

const sortActivities = (a: SelectableActivityTag, b: SelectableActivityTag): number => {
  if (a.checked && b.checked) return a.name.localeCompare(b.name);
  if (a.checked) return -1;
  if (b.checked) return 1;
  return a.name.localeCompare(b.name);
};

export function ActivitiesList({ activities, onChange, onClose, title, programOptions }: Props) {
  const inputClasses = textFieldStyle();
  const { isMobile } = useCampfireTheme();
  const [search, setSearch] = React.useState('');
  const [programs, setPrograms] = React.useState([SELECT_ALL_VALUE]);

  const showingActivity = activities
    .sort(sortActivities)
    .filter(
      (activity) =>
        activity.name.toLowerCase().includes(search.toLowerCase()) ||
        activity.name.toLowerCase().includes(search.toLowerCase())
    )
    .filter((activity) => programs[0] === SELECT_ALL_VALUE || programs.includes(activity.program.programId));

  const onSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValues = event.target.value as string[];
    if (newValues.includes(SELECT_ALL_VALUE) && !newValues.includes(SELECT_NONE_VALUE)) {
      if (programs.includes(SELECT_ALL_VALUE)) {
        setPrograms(
          programOptions.filter((program) => !newValues.includes(program.value)).map((program) => program.value)
        );
      } else {
        setPrograms([SELECT_ALL_VALUE]);
      }
    } else if (newValues.includes(SELECT_NONE_VALUE)) {
      setPrograms([]);
    } else {
      setPrograms(event.target.value as string[]);
    }
  };

  return (
    <FullscreenDialog open close={onClose} title={title} fullScreen={isMobile}>
      <Box display='flex' alignItems='center' paddingTop='0.5rem' paddingBottom='1rem'>
        <Box flex={1}>
          <SearchField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder='Type for search activity'
            fullWidth
          />
        </Box>
        <Box ml={2} width='40%'>
          <TextField
            name='program'
            fullWidth
            variant='outlined'
            select
            InputLabelProps={{ shrink: true }}
            InputProps={{ classes: inputClasses }}
            SelectProps={{
              multiple: true,
              displayEmpty: true,
              renderValue: () => {
                if (programs.includes(SELECT_ALL_VALUE)) {
                  return 'All programs';
                }
                if (programs.length === 0) {
                  return 'None of programs';
                }
                if (programs.length >= 3) {
                  return `${programs.length} programs`;
                }
                return programs
                  .map((programId) => programOptions.find((program) => program.value === programId))
                  .filter((program) => !!program)
                  .map((program) => program?.label)
                  .join(', ');
              },
            }}
            onChange={onSelect}
            value={programs}
            label='Programs'
          >
            <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
              <ListItemText primary='Select All' />
            </MenuItem>
            <MenuItem key={SELECT_NONE_VALUE} value={SELECT_NONE_VALUE}>
              <ListItemText primary='Deselect All' />
            </MenuItem>
            {programOptions
              .sort((a, b) => a.label.localeCompare(b.label))
              .map(({ value, label }) => {
                return (
                  <MenuItem key={value} value={value}>
                    <Checkbox
                      color='primary'
                      value={value}
                      checked={programs.includes(value) || programs.includes(SELECT_ALL_VALUE)}
                    />
                    <ListItemText primary={label} />
                  </MenuItem>
                );
              })}
          </TextField>
        </Box>
      </Box>

      <FormGroup>
        <List
          height={300}
          itemCount={showingActivity.length}
          itemSize={51}
          width={500}
          itemData={{ activities: showingActivity, onChange }}
          style={{
            overflowX: 'hidden',
          }}
        >
          {Row}
        </List>
      </FormGroup>
    </FullscreenDialog>
  );
}
