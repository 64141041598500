import { Typography } from '@material-ui/core';
import React from 'react';
import { CopyBlock, ocean } from 'react-code-blocks';
import { getWidgetUrl } from '../helpers/embeddableBaseUrl';
import AttributesTable, { Attribute } from './AttributesTable';

const WidgetInstallationInstructions = (props: Props) => {
  const baseUrl = getWidgetUrl();
  const scriptTag = `<script type=“module” src="${baseUrl}"></script>`;

  return (
    <>
      <hr />

      <Typography variant='h6' gutterBottom>
        How to embed the {props.widgetName} widget:
      </Typography>

      <p>
        1. Embed the following script tag in the <code>&lt;head&gt;</code> tag of your HTML document.
      </p>

      <CopyBlock codeBlock showLineNumbers language='markup' theme={ocean} text={scriptTag} />

      <p>
        2. Add the widget tag anywhere within the <code>&lt;body&gt;</code> of your webpage that you want the widget to
        appear.
      </p>

      <CopyBlock codeBlock showLineNumbers language='markup' theme={ocean} text={props.minimumExample} />

      <p>3. Modify the attributes as per the table below:</p>

      <AttributesTable tableData={props.widgetAttributes} />

      <p>Below is an example of a widget code specified with all options included.</p>

      <CopyBlock codeBlock showLineNumbers language='markup' theme={ocean} text={props.customExample} />
    </>
  );
};

export default WidgetInstallationInstructions;

interface Props {
  widgetName: string;
  minimumExample: string;
  customExample: string;
  widgetAttributes: Attribute[];
}
