import React, { Fragment } from 'react';
import { useRoutesMap } from './useRoutesMaps';
import { DrawerNavList } from '../../global/app-shell/side-nav/DrawerNavList';
import { SideNavGroupInterface, RoutesMaps } from './routes-map-model';

export type AllRoutesMaps = {
  fixed: RoutesMaps;
  volunteering: RoutesMaps;
  management: RoutesMaps;
  admin: RoutesMaps;
};

const NavigationBlock = ({
  routesMap,
  activeSideNavRoute,
  setActiveSideNavRoute,
  isDrawerOpen,
  closeDrawer,
}: {
  routesMap: SideNavGroupInterface;
  activeSideNavRoute: string;
  setActiveSideNavRoute: (value: string) => void;
  isDrawerOpen: boolean;
  closeDrawer: () => void;
}) => {
  if (!routesMap.isAuthorized) return null;
  return (
    <Fragment>
      <DrawerNavList
        activeSideNavRoute={activeSideNavRoute}
        setActiveSideNavRoute={setActiveSideNavRoute}
        sideNavGroup={routesMap}
        closeDrawer={closeDrawer}
        isDrawerOpen={isDrawerOpen}
      />
    </Fragment>
  );
};

const Navigation = (props: {
  activeSideNavRoute: string;
  setActiveSideNavRoute: (value: string) => void;
  isDrawerOpen: boolean;
  closeDrawer: () => void;
}) => {
  const routesMaps = useRoutesMap();
  return (
    <>
      <NavigationBlock routesMap={routesMaps.volunteering} {...props} />
      <NavigationBlock routesMap={routesMaps.management} {...props} />
      <NavigationBlock routesMap={routesMaps.admin} {...props} />
    </>
  );
};

export { NavigationBlock };
export { Navigation };
