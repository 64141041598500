import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import GetOutOnShift from '../../../assets/tutorial-graphics/my-activities/get_out_on_shift.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Activities',
    description: (
      <>
        The Activities page has 2 key areas, <strong>All Activities</strong> and <strong>My Rosters</strong>.
      </>
    ),
    image: GetOutOnShift,
  },
  {
    label: 'All Activities',
    description: (
      <>
        All activities gives an overview of the activities in your program with key information on each activity, use
        this section to <strong>find and view/apply for new activities</strong> that you may be interested in joining.
      </>
    ),
    image: GetOutOnShift,
  },
  {
    label: 'My Rosters',
    description: (
      <>
        The My Rosters section allows you to <strong>view your upcoming rosters</strong> and{' '}
        <strong>make changes to your availability</strong>.
      </>
    ),
    image: GetOutOnShift,
  },
];

const ActivitiesTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { ActivitiesTutorialDialog };
