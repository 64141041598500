import React from 'react';
import { Link } from 'react-router-dom';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { DateTime } from 'luxon';
import { AddCircle, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { Box, Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { encodeDate } from '@campfire/hot-date';
import { ActionsHeader } from '../../../../common/ActionsHeader';
import { getCreateRosterTemplateLink } from '../main-section/roster/roster-functions';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { RostersList } from './RostersList';
import { LARGE_ORG_DAYS_LIMIT, SMALL_ORG_DAYS_LIMIT } from '../IndividualRosterScreen';
import { SessionContext } from '../../../../global/auth/SessionContext';

interface Props {
  rosters: any[];
  loadNext: () => void;
  loadPrev: () => void;
}

export const useCommonButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      alignItems: 'center',
      '& > span': {
        textTransform: 'none',
      },
      borderRadius: 6,
      height: 38,
    },
  })
);

export const NextPrevButton = ({
  variant,
  onAction,
  isLargeOrg,
}: {
  variant: string;
  onAction: () => void;
  isLargeOrg?: boolean;
}) => {
  const classes = useCommonButtonStyles();
  const numOfDays = isLargeOrg ? LARGE_ORG_DAYS_LIMIT : SMALL_ORG_DAYS_LIMIT;

  return (
    <Box display='flex' my='1rem' justifyContent='center'>
      <Button
        onClick={onAction}
        className={classes.button}
        variant='outlined'
        disableElevation
        startIcon={variant === 'prev' ? <ArrowDropUp /> : null}
        endIcon={variant === 'next' ? <ArrowDropDown /> : null}
      >
        {`${variant === 'next' ? 'Next' : 'Previous'} ${numOfDays} Days`}
      </Button>
    </Box>
  );
};

export const RostersSideBar = (props: Props) => {
  const { isMd, isMobile, theme } = useCampfireTheme();
  const { rosters, loadNext, loadPrev } = props;
  const [query] = useQueryParams({
    activityDate: StringParam,
    activityId: StringParam,
    startDate: StringParam,
    onHistory: BooleanParam,
  });

  const session = React.useContext(SessionContext);
  const isLargeOrg = session?.user?.orgInfo.isLargeOrg;
  const isInitialLoad = !query.startDate || query.startDate === encodeDate(DateTime.local());

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <ActionsHeader title='Rosters'>
        <Link to={getCreateRosterTemplateLink(query.activityId)} style={{ textDecoration: 'none' }}>
          <Button
            style={{
              boxSizing: 'border-box',
              borderRadius: '4px',
              textTransform: 'none',
              color: '#ffffff',
              marginRight: '10px',
              padding: !isMobile || !isMd ? '7px 12px' : '7px 3px',
              minWidth: '45px',
              backgroundColor: theme.color.secondary.main900,
            }}
          >
            <AddCircle style={{ fontSize: '18px' }} />
            <Typography
              style={{
                marginLeft: '8px',
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '19px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: isMobile || isMd ? 'none' : 'inherit',
              }}
            >
              Add Roster
            </Typography>
          </Button>
        </Link>
      </ActionsHeader>
      {!query.onHistory && !isInitialLoad && (
        <NextPrevButton onAction={loadPrev} variant='prev' isLargeOrg={isLargeOrg} />
      )}
      <Box width='100%'>
        <RostersList rosters={rosters} />
      </Box>
      {!query.onHistory && <NextPrevButton onAction={loadNext} variant='next' isLargeOrg={isLargeOrg} />}
    </Box>
  );
};
