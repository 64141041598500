import React, { useRef } from 'react';
import { DateTime } from 'luxon';
import { Box, InputBaseComponentProps, TextField, Typography } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';

export const CICODateSelect = ({
  time,
  field,
  onChange,
}: {
  time: Date | '';
  field: string;
  onChange: (value: Date) => void;
}) => {
  const { theme } = useCampfireTheme();
  const inputRef = useRef<InputBaseComponentProps>(null);

  return (
    <Box mr={2} style={{ color: theme.color.secondary.main900, cursor: 'pointer' }} position='relative'>
      <Box display='flex' alignItems='center' onClick={() => inputRef.current?.showPicker()}>
        <Typography
          style={{ minWidth: 64, marginRight: '8px', fontSize: '13px', fontWeight: 600, textAlign: 'center' }}
        >
          {time ? DateTime.fromJSDate(time).toFormat('h:mm a') : 'Now'}
        </Typography>
        <KeyboardArrowDown style={{ fontSize: '14px' }} />
      </Box>
      <TextField
        onChange={(event) => onChange(DateTime.fromFormat(event.target.value, 'hh:mm').toJSDate())}
        variant='outlined'
        type='time'
        label='Start time'
        fullWidth
        name={field}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          step: 300, // 5 min
          ref: inputRef,
        }}
        onBlur={() => {
          if (!time) return;
          onChange(time);
        }}
        style={{ visibility: 'hidden', position: 'absolute', zIndex: 100, top: '-28px' }}
      />
    </Box>
  );
};
