import { PeopleAltOutlined } from '@material-ui/icons';
import { GetRoutesConfig, RoutesMaps } from '../common/routes-map-model';

const route = 'volunteering';

export const getGeneralRoutesMap = ({ userIdentityService }: GetRoutesConfig): RoutesMaps => ({
  route,
  heading: 'Volunteering',
  Icon: PeopleAltOutlined,
  isAuthorized: userIdentityService.isVolunteer,
  items: [
    {
      label: 'Activities',
      route: 'activities',
      isAuthorized: true,
    },
    {
      label: 'Activity Report',
      route: 'activity-report',
      isAuthorized: true,
    },
    {
      label: 'Incident Report',
      route: 'incident-report',
      isAuthorized: true,
    },
  ],
});
