import React, { useMemo, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { GetRosterScreenRosterData_vm_activity_sessions as Session } from '../main-section/roster/__generated__/GetRosterScreenRosterData';
import { CurrentRosterState, RowAction, UpdatedRostering, Volunteer } from '../main-section/roster/roster-types';
import {
  getRosterAction,
  getRosteringSessionStatus,
  getSessionStatuses,
} from '../main-section/roster/roster-functions';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { RosterActionsFooter } from '../main-section/roster/RosterActionsFooter';
import { RosterTableMobile } from '../main-section/roster/roster-table/RosterTable';
import { RosterScreenDraftRoster_sessionNotes as SessionNoteType } from '../main-section/roster/__generated__/RosterScreenDraftRoster';

interface Props {
  smsConfirmationRefetch: () => void;
  sessions: Session[];
  draftRoster?: Volunteer[];
  updateRostering: (volunteerId: string, sessionId: string, action: RowAction) => void;
  updatedRosterings: UpdatedRostering[];
  currentRosterState: CurrentRosterState;
  hasPublishedRoster: boolean;
  handlePublishRoster: (x: boolean) => void;
  handleDiscardChanges: () => void;
  setOpenAddVolunteersDialog: (x: boolean) => void;
  isRosterCancelled: boolean;
  isRosterCompleted: boolean;
  onSubmitReport: any;
  publishedRoster: any;
  activityDate: string;
  onChangeAvailability: (volunteerId: string) => void;
  sessionNotes: SessionNoteType[];
  onChangeSessionNote: (sessionId: string, notes: string) => void;
}

export const MobileRoster = (props: Props) => {
  const {
    setOpenAddVolunteersDialog,
    sessions,
    draftRoster,
    updateRostering,
    updatedRosterings,
    currentRosterState,
    hasPublishedRoster,
    handlePublishRoster,
    handleDiscardChanges,
    isRosterCancelled,
    onSubmitReport,
    isRosterCompleted,
    publishedRoster,
    activityDate,
    onChangeAvailability,
    sessionNotes,
    onChangeSessionNote,
  } = props;

  const { theme } = useCampfireTheme();
  const [selectedSession, setSelectedSession] = useState<any>('all');
  const sessionStatuses = getSessionStatuses(theme);

  const sessionOptions = useMemo(
    () => [{ name: 'All sessions', id: 'all' }, ...sessions?.map((s) => ({ name: s.name, id: s.sessionId }))],
    [sessions]
  );
  const filteredSessions = useMemo(
    () => (selectedSession !== 'all' ? sessions.filter((s) => s.sessionId === selectedSession) : sessions),
    [selectedSession, sessions]
  );
  const showFooter = useMemo(() => currentRosterState !== 'published', [currentRosterState]);

  return (
    <Box display={'flex'} flexDirection={'column'} marginTop={'30px'} marginBottom={showFooter ? '122px' : 0}>
      <FormControl variant='outlined' fullWidth style={{ marginTop: '8px' }}>
        <InputLabel id='rosters-session-select'>{'Choose a session'}</InputLabel>
        <Select
          fullWidth
          value={selectedSession}
          label={'Choose a session'}
          id={'rosters-session-select'}
          onChange={(e) => setSelectedSession(e.target.value)}
          variant={'outlined'}
        >
          {sessionOptions?.map((s) => (
            <MenuItem value={s.id}>{s.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <RosterTableMobile
        sessionNotes={sessionNotes}
        onChangeSessionNote={onChangeSessionNote}
        onChangeAvailability={onChangeAvailability}
        sessions={filteredSessions}
        draftRosters={draftRoster || []}
        isRosterCancelled={isRosterCancelled}
        onClickRosterStatus={(session: Session, roster: Volunteer) =>
          updateRostering(
            roster.volunteerId,
            session.sessionId,
            getRosterAction(getRosteringSessionStatus(session.sessionId, roster, sessionStatuses).text)
          )
        }
        onAddVoluteer={() => setOpenAddVolunteersDialog(true)}
        onSubmitReport={onSubmitReport}
        isRosterCompleted={isRosterCompleted}
        publishedRoster={publishedRoster}
        activityDate={activityDate}
      />
      <RosterActionsFooter
        open={showFooter}
        hasUnsavedChanges={currentRosterState === 'not-saved'}
        hasPublishedRoster={hasPublishedRoster}
        changedVolunteers={updatedRosterings}
        onPublishRosterClick={() => handlePublishRoster(true)}
        onSaveDraftClick={() => handlePublishRoster(false)}
        onDiscardChangesClick={handleDiscardChanges}
        isSubmitting={false}
        suppressDrafts={false}
      />
    </Box>
  );
};
