import { fade, Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useRosterSessionCellStyles = makeStyles(() =>
  createStyles({
    rootButtonBase: {
      display: 'flex',
      flex: '1 1 auto',
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
    },
    leftCapButtonBase: {
      paddingLeft: 12,
    },
    rightCapButtonBase: {
      paddingRight: 12,
    },
    rootBox: {
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      minHeight: 44,
      boxSizing: 'border-box',
    },
    disabled: {
      '&:hover': {
        cursor: 'default',
        backgroundColor: 'none',
      },
    },
    leftCapBox: {
      borderTopLeftRadius: 32,
      borderBottomLeftRadius: 32,
    },
    rightCapBox: {
      borderTopRightRadius: 32,
      borderBottomRightRadius: 32,
    },
  })
);

export const useRosterSessionCellStylesRostered = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: theme.color.rosters.rostered,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: fade(theme.color.rosters.rostered, 0.8),
      },
    },
  })
);

export const useRosterSessionCellStylesRemoved = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: theme.color.rosters.removed,
      color: theme.palette.common.white,
      '& p': {
        color: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: fade(theme.color.rosters.removed, 0.8),
      },
    },
  })
);

export const useRosterSessionCellStylesSelected = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: theme.color.rosters.selected,
      color: theme.palette.common.white,
      '& p': {
        color: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: fade(theme.color.rosters.selected, 0.8),
      },
    },
  })
);

export const useRosterSessionCellStylesAvailable = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: theme.color.rosters.empty,
      '&:hover': {
        '& p': {
          color: '#ffffff',
        },
        backgroundColor: fade(theme.color.rosters.selected, 0.2),
      },
    },
  })
);

export const useRosterSessionCellStylesNoIndication = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: theme.color.rosters.empty,
      '&:hover': {
        '& p': {
          color: '#ffffff',
        },
        backgroundColor: fade(theme.color.rosters.selected, 0.2),
      },
    },
  })
);

export const useRosterSessionCellStylesUnavailable = makeStyles((theme: Theme) =>
  createStyles({
    unavailableBox: {
      backgroundColor: theme.color.rosters.unavailabe,
    },
  })
);

export const useRowStyles = makeStyles(() =>
  createStyles({
    row: {
      alignContent: 'center',
      alignItems: 'center',
      borderTop: `1px solid #f2f6fc`,
    },
    rowCell: {
      height: '100%',
    },
  })
);

export const useVolunteerGridItemStyles = makeStyles(() => {
  return createStyles({
    volunteerProfileGridItem: {
      position: 'relative',
      display: 'flex',
      height: '100%',
      '&:hover': {
        '& $volunteerProfileAvailabilityButton': {
          opacity: 1,
        },
      },
    },
    volunteerProfileAvailabilityButton: {
      top: 0,
      right: 0,
      opacity: 0,
      position: 'absolute',
      background: 'rgba(255,255,255,0.9)',
      borderRadius: 8,
      height: '75%',
    },
  })
});
