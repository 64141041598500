import { encodeDate } from '@campfire/hot-date';
import { Box } from '@material-ui/core';
import { PanToolOutlined, QueryBuilderOutlined, RoomOutlined } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Statistics } from '../../../../../../common/statistics/Statistics';
import { useUser } from '../../../../../../global/auth/useUser';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { SideBarItem } from '../../../../../program-manager/activity-management/activities-list/SideBarActivityDetail';
import { GET_MY_ELEMENTS_SIDEBAR_YOUR_IMPACT_DATA } from './side-bar-your-impact.gql';
import { SideBarYourImpactSkeleton } from './SideBarYourImpactSkeleton';
import {
  GetMyElementsSideBarYourImpactData,
  GetMyElementsSideBarYourImpactDataVariables,
} from './__generated__/GetMyElementsSideBarYourImpactData';

export const SideBarYourImpact = () => {
  const { getVolunteerIdentity } = useUser();
  const volunteerIdentity = getVolunteerIdentity();
  const startDate = DateTime.fromMillis(0);
  const endDate = DateTime.local();

  const { data, loading } = useCampfireQuery<
    GetMyElementsSideBarYourImpactData,
    GetMyElementsSideBarYourImpactDataVariables
  >(GET_MY_ELEMENTS_SIDEBAR_YOUR_IMPACT_DATA, {
    options: {
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
        startDate: encodeDate(startDate),
        endDate: encodeDate(endDate),
      },
    },
  });

  const attended = useMemo(() => {
    return data?.vm.volunteer?.volunteerStatistics.attended ?? 0;
  }, [data]);

  const hours = useMemo(() => {
    const operationalHours = data?.vm.volunteer?.volunteerStatistics.sessionReportStatistics.operationalHours ?? 0;
    const additionalHours = parseFloat(`${data?.vm.volunteer?.profile.additionalVolunteerHours ?? '0'}`);
    return operationalHours + additionalHours ?? 0;
  }, [data]);

  const roundedHours = Math.round(hours * 100) / 100;

  const locations = useMemo(() => {
    return data?.vm.volunteer?.volunteerStatistics.sessionReportStatistics.activityLocations.length ?? 0;
  }, [data]);

  if (loading) return <SideBarYourImpactSkeleton />;

  return (
    <Box mt='24px'>
      <Box mb='16px'>
        <Statistics
          value={roundedHours}
          icon={QueryBuilderOutlined}
          title='Hours'
          subtitle='of volunteering'
          color='#7570EA'
        />
      </Box>
      <Box mb='16px'>
        <Statistics value={attended} icon={PanToolOutlined} title='Activities' subtitle='attended' color='#FE6F40' />
      </Box>

      <Box mb='16px'>
        <Statistics value={locations} icon={RoomOutlined} title='Locations' subtitle='visited' color='#60B386' />
      </Box>
    </Box>
  );
};

export const SideBarYourImpactSection = () => {
  return (
    <SideBarItem headerTitle='Your Impact'>
      <SideBarYourImpact />
    </SideBarItem>
  );
};
