import { Typography, Box, List, ListItem, ListItemText, ListItemIcon, Checkbox } from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { useFilterStyles } from '../../../../../common/filter-fields/FilterClasses';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { filterHelperTexts, DEFAULT_PROGRAM_FILTER } from '../../../../../common/filter-fields/filters';
import { ActivitiesExploreProgram } from '../../__generated__/ActivitiesExploreProgram';

type ProgramFilterProps = {
  setSelectedProgramId: (x: string) => void;
  selectedProgramId: string;
  programs: Array<ActivitiesExploreProgram>;
  isManagementView?: boolean;
};

const ProgramFilter = ({ setSelectedProgramId, selectedProgramId, programs, isManagementView }: ProgramFilterProps) => {
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const isProgramSelected = (value: string) => selectedProgramId === value;

  const suspendedPrograms = useMemo(() => programs.filter((x) => x.dateDeleted || x.dateSuspended), [programs]);
  const activePrograms = useMemo(() => programs.filter((x) => !x.dateDeleted && !x.dateSuspended), [programs]);

  return (
    <Box py={2}>
      <Typography color='textSecondary' variant='body1' gutterBottom>
        {isManagementView
          ? filterHelperTexts.management.programFiltersHelperText
          : filterHelperTexts.volunteer.programFiltersHelperText}
      </Typography>

      <List disablePadding>
        <ListItem
          data-track={`actCnlMapFilters-ProgramFilter-AllPrograms`}
          key={'all'}
          onClick={() => setSelectedProgramId('all')}
          disableGutters
          className={classes.listItem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <Checkbox
              checked={selectedProgramId === 'all'}
              className={classes.checkBox}
              checkedIcon={<RadioButtonChecked color='primary' />}
              size='small'
              icon={<RadioButtonUnchecked />}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant='body1'
              color='textSecondary'
              style={!selectedProgramId ? { fontWeight: 600 } : { fontWeight: 'normal' }}
            >
              {DEFAULT_PROGRAM_FILTER}
            </Typography>
          </ListItemText>
        </ListItem>

        {activePrograms.length > 0 ? (
          <Box display='flex' flex='1 1 auto' paddingTop={1} paddingBottom={1}>
            <Typography variant='caption' style={{ fontWeight: 'bold' }} color='textSecondary'>
              Active programs:
            </Typography>
          </Box>
        ) : null}

        {activePrograms.map((program) => (
          <ListItem
            data-track={`actCnlMapFilters-ProgramFilter-IndividualProgram`}
            key={program.programId}
            onClick={() => setSelectedProgramId(program.programId)}
            disableGutters
            className={classes.listItem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                checked={isProgramSelected(program.programId)}
                className={classes.checkBox}
                checkedIcon={<RadioButtonChecked color='primary' />}
                size='small'
                icon={<RadioButtonUnchecked />}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant='body1'
                color='textSecondary'
                style={isProgramSelected(program.programId) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
              >
                {program.name}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}

        {suspendedPrograms.length > 0 ? (
          <Box display='flex' flex='1 1 auto' paddingTop={1} paddingBottom={1}>
            <Typography variant='caption' style={{ fontWeight: 'bold' }} color='textSecondary'>
              Suspended programs:
            </Typography>
          </Box>
        ) : null}

        {suspendedPrograms.map((program) => (
          <ListItem
            data-track={`actCnlMapFilters-ProgramFilter-IndividualProgram`}
            key={program.programId}
            onClick={() => setSelectedProgramId(program.programId)}
            disableGutters
            className={classes.listItem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                checked={isProgramSelected(program.programId)}
                className={classes.checkBox}
                checkedIcon={<RadioButtonChecked color='primary' />}
                size='small'
                icon={<RadioButtonUnchecked />}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant='body1'
                color='textSecondary'
                style={isProgramSelected(program.programId) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
              >
                {program.name}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export { ProgramFilter };
