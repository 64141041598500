import React, { ReactNode } from 'react';
import { Switch, Route } from 'react-router-dom';
import { usePageNotFoundContext } from '../../global/page-not-found/PageNotFoundContext';

interface Props {
  children: ReactNode;
}

const CampfireSwitch = (props: Props) => {
  const { children } = props;
  const { setPageNotFound } = usePageNotFoundContext();

  return (
    <Switch>
      {children}
      <Route
        path='*'
        render={() => {
          setPageNotFound(true);
          return null;
        }}
      />
    </Switch>
  );
};

export { CampfireSwitch };
