import { unpackToDateTimeTZ } from '@campfire/hot-date';
import { Box, Collapse, List, ListItem, ListItemAvatar, ListItemText, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { capitalize } from 'lodash';
import React, { ReactNode, useState } from 'react';
import { DateTime } from 'luxon';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { VolunteerProfile } from '../../../../common/AvatarName';
import { arrayHead } from '../../../../common/functions/array-head';
import { AlertCard } from '../../../../common/cards/alert-card/AlertCard';
import { ImagePreviewList } from '../../../../common/ImagePreviewList';
import { ExportCsvDialog } from '../../../../common/dialogs/ExportCsvDialog';
import { downloadCSV } from '../../../../common/functions/csv-utils';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { Avatar } from '../../../general/user-profile/components/Avatar';
import { GET_INCIDENT_MANAGEMENT_SINGLE_INCIDENT } from '../incident-management-model.gql';
import { getIncidentDetailCategoryTree, buildRowCsv, buildCsvData } from '../IncidentManagementUtils';
import {
  GetIncidentManagementSingleIncident,
  GetIncidentManagementSingleIncidentVariables,
} from '../__generated__/GetIncidentManagementSingleIncident';
import { ClosingInfoSection } from './ClosingInfoSection';
import { IncidentDetailHeader } from './incident-detail-header/IncidentDetailHeader';
import { IncidentDetailSkeleton } from './IncidentDetailSkeleton';
import { IncidentReportComments } from './IncidentReportComments';

type Props = {
  incidentId: string;
  getIncidentsRefetch: () => void;
};

export const useSectionStyles = makeStyles((theme: Theme) => {
  return {
    sectionContainer: {
      marginTop: 24,
      marginBottom: 24,
    },
    sectionTitle: {
      color: theme.color.grey.neutral400,
      fontWeight: 700,
    },
    sectionBody: {
      color: theme.color.grey.neutral300,
      fontSize: '13px',
    },
    paper: {
      marginTop: 4,
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        background: '#282729',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  };
});

export const SectionItem = ({ title, text, children }: { title: string; text?: string; children?: ReactNode }) => {
  const classes = useSectionStyles();
  return (
    <Box className={classes.sectionContainer}>
      <Typography className={classes.sectionTitle}>{title}</Typography>
      {text && <Typography className={classes.sectionBody}>{text}</Typography>}
      {children}
    </Box>
  );
};

export const IncidentDetail = ({ incidentId, getIncidentsRefetch }: Props) => {
  const { theme } = useCampfireTheme();
  const classes = useSectionStyles();
  const [isCollapsed, setCollapsed] = useState<boolean>(false);
  const [openExportCsv, setOpenExportCsv] = useState(false);

  const {
    data: getSingleIncident,
    loading: isSingleIncidentLoading,
    refetch: refetchSingleIncident,
  } = useCampfireQuery<GetIncidentManagementSingleIncident, GetIncidentManagementSingleIncidentVariables>(
    GET_INCIDENT_MANAGEMENT_SINGLE_INCIDENT,
    {
      options: {
        variables: {
          incidentId,
        },
      },
    }
  );

  React.useEffect(() => {
    const timer = setInterval(() => {
      refetchSingleIncident();
    }, 15000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  if (isSingleIncidentLoading && !getSingleIncident) return <IncidentDetailSkeleton />;

  const incident = getSingleIncident?.vm.incident;
  const incidentReport = incident && arrayHead(incident.incidentReports);
  if (!incident || !incidentReport) return null;
  const {
    incidentReportId,
    incidentDateTime,
    submissionDateTime,
    incidentLocation,
    submittedBy,
    severity,
    othersInvolved,
    volunteersInvolved,
    description,
    furtherAction,
    resources,
    detail,
    referenceNumber,
    histories,
    comments,
  } = incidentReport;

  const displayAddress =
    incidentLocation?.__typename === 'VOLUNTEER_ActivityIncidentLocationType'
      ? incidentLocation.activity.activityLocation?.placesAddress.description
      : '';

  const sidebarTitle =
    incidentLocation?.__typename === 'VOLUNTEER_ActivityIncidentLocationType'
      ? incidentLocation.activity.name
      : incidentLocation?.__typename === 'VOLUNTEER_AddressIncidentLocationType'
      ? incidentLocation.address.humanReadable
      : '';

  const { name: severityLabel, color: severityColor } = severity;

  const incidentDetailCategoryTree = getIncidentDetailCategoryTree(detail);
  const history = histories.sort((a, b) => (a.dateUpdated > b.dateUpdated ? -1 : 1))[0];

  const handleSaveCsv = () => {
    const rowCsv = buildRowCsv({ ...incidentReport, incident });
    const incidentCsvData = buildCsvData([rowCsv]);
    const filename = `incident_report_${DateTime.local().toFormat('yyyy-MM-d')}.csv`;
    downloadCSV(incidentCsvData, filename);
  };

  const handleExport = () => {
    setOpenExportCsv(true);
  };
  const handleCloseExportCsv = () => {
    setOpenExportCsv(false);
  };

  return (
    <Box width='100%' display='inline-grid'>
      <IncidentDetailHeader
        incidentId={incident.incidentId}
        incidentStatus={incident.incidentStatus}
        incidentProgramId={incident.program.programId}
        sidebarTitle={sidebarTitle}
        referenceNumber={referenceNumber}
        getIncidentsRefetch={getIncidentsRefetch}
        handleExport={handleExport}
      />
      <Box mt={2}>
        <span>
          <Typography display='inline' style={{ color: severityColor, fontWeight: 700 }}>
            {`${capitalize(severityLabel)} Issue `}
          </Typography>
          <Typography>
            {incidentDetailCategoryTree.map((label, index) => {
              return (
                <Box display='inline-block' key={label}>
                  {index < incidentDetailCategoryTree.length && index > 0 ? (
                    <Typography style={{ marginLeft: 4, marginRight: 4 }} display='inline' color='textSecondary'>
                      {' - '}
                    </Typography>
                  ) : null}
                  <Typography display='inline' className={classes.sectionTitle}>
                    {`${capitalize(label)}`}
                  </Typography>
                </Box>
              );
            })}
          </Typography>
        </span>
        {displayAddress && <Typography className={classes.sectionBody}>{displayAddress}</Typography>}
        <Typography className={classes.sectionBody}>
          {unpackToDateTimeTZ(incidentDateTime).toFormat('h:mm a, dd/MM/y')}
        </Typography>
        {incident.dateClosed && (
          <ClosingInfoSection
            dateClosedFormatted={unpackToDateTimeTZ(incident.dateClosed).toFormat('dd/MM/y h:mm a')}
            closingComments={incident.closingComments}
          />
        )}
        {volunteersInvolved.length > 0 || othersInvolved.length > 0 ? (
          <SectionItem title='People Involved'>
            <List>
              {volunteersInvolved.map((vol) => (
                <ListItem key={vol.profile.profileId} style={{ paddingRight: 0, paddingLeft: 0 }}>
                  <VolunteerProfile
                    avatarUrl={vol.profile.avatarUrl}
                    name={`${capitalize(vol.profile.preferredName)} ${capitalize(vol.profile.lastName)}`}
                    profileId={vol.profile.profileId}
                    userId={vol.profile.userId}
                    lastName={vol.profile.lastName}
                    preferredName={vol.profile.preferredName}
                    noTrim
                  />
                </ListItem>
              ))}
              {othersInvolved.map((person) => {
                return (
                  <ListItem key={person} style={{ paddingRight: 0, paddingLeft: 0 }}>
                    <ListItemAvatar>
                      <Avatar size={40} />
                    </ListItemAvatar>
                    <ListItemText style={{ color: theme.color.grey.neutral400 }}>{capitalize(person)}</ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </SectionItem>
        ) : null}
        <SectionItem title='Description' text={description} />
        {furtherAction && <SectionItem title='Further Action' text={furtherAction} />}
        {submittedBy && (
          <SectionItem title='Submitted By'>
            <Box style={{ paddingTop: 16, paddingBottom: 16, width: '100%' }}>
              <VolunteerProfile
                noTrim
                avatarUrl={submittedBy.profile.avatarUrl}
                name={`${capitalize(submittedBy.profile.preferredName)} ${capitalize(submittedBy.profile.lastName)}`}
                profileId={submittedBy.profile.profileId}
                userId={submittedBy.profile.userId}
                lastName={submittedBy.profile.lastName}
                preferredName={submittedBy.profile.preferredName}
              />
            </Box>
          </SectionItem>
        )}

        {resources.length > 0 && (
          <SectionItem title='Photos'>
            <Box style={{ paddingTop: 16, paddingBottom: 16 }}>
              <ImagePreviewList images={resources} />
            </Box>
          </SectionItem>
        )}
        <Typography className={classes.sectionBody}>
          <b style={{ fontWeight: 600 }}>Submitted at:</b>{' '}
          {`${DateTime.fromISO(submissionDateTime)
            .toLocal()
            .toFormat('h:mm a, dd/MM/y')}`}
        </Typography>

        {history && (
          <Box mt='16px' borderLeft='4px solid #FBBF24'>
            <AlertCard variant='warning'>
              <Typography style={{ fontSize: '14px', color: '#92400E' }}>
                Last edit by
                <span
                  style={{ fontWeight: 500 }}
                >{` ${history.updatedBy.profile.preferredName} ${history.updatedBy.profile.lastName}`}</span>
                {` on ${DateTime.fromISO(history.dateUpdated)
                  .toLocal()
                  .toFormat('h:mm a, dd/MM/y')}`}
              </Typography>
              {isCollapsed ? (
                <Typography
                  style={{ fontSize: '12px', color: '#92400E', marginTop: '10px', fontWeight: 500, display: 'flex' }}
                  onClick={() => {
                    setCollapsed(!isCollapsed);
                  }}
                >
                  Hide edit history <ExpandLess style={{ fontSize: '13px', paddingTop: '2px' }} />
                </Typography>
              ) : (
                <Typography
                  style={{ fontSize: '12px', color: '#92400E', marginTop: '10px', fontWeight: 500, display: 'flex' }}
                  onClick={() => {
                    setCollapsed(!isCollapsed);
                  }}
                >
                  Show edit history <ExpandMore style={{ fontSize: '13px', paddingTop: '2px' }} />
                </Typography>
              )}
              <Collapse in={isCollapsed}>
                <Typography style={{ fontSize: '12px', color: '#92400E', marginTop: '10px', fontWeight: 400 }}>
                  {history.description}
                </Typography>
              </Collapse>
            </AlertCard>
          </Box>
        )}
        <Box pt={'1em'}></Box>
        <SectionItem title='Comments'>
          <Box style={{ borderBottom: '1px solid #bbbbbb', paddingBottom: '.5em', marginBottom: '1em' }}></Box>
          <Box py={2}>
            <IncidentReportComments
              incidentReportId={incidentReportId}
              comments={comments}
              refetch={refetchSingleIncident}
            />
          </Box>
        </SectionItem>
      </Box>
      {openExportCsv && (
        <ExportCsvDialog open={openExportCsv} close={handleCloseExportCsv} handleSaveCsv={handleSaveCsv} />
      )}
    </Box>
  );
};
