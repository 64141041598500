import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import {
  GET_ADMIN_CONSOLE_INCIDENT_SEVERITIES,
  GET_ADMIN_CONSOLE_INCIDENT_CATEGORIES,
} from './admin-console-incident-report.gql';
import { GetAdminConsoleIncidentSeverities } from './__generated__/GetAdminConsoleIncidentSeverities';
import { IncidentSeverityEditForm } from './incident-severities/IncidentSeverityEditForm';
import { GetAdminConsoleIncidentCategories } from './__generated__/GetAdminConsoleIncidentCategories';
import { IncidentCategorySection } from './incident-categories/IncidentCategorySection';

const AdminConsoleIncidentReportingPanel = () => {
  const { data: allSeveritiesResponse, loading: allSeveritiesLoading, refetch: refetchSeverities } = useCampfireQuery<
    GetAdminConsoleIncidentSeverities,
    undefined
  >(GET_ADMIN_CONSOLE_INCIDENT_SEVERITIES);

  const { data: allCategoriesResponse, loading: allCategoriesLoading, refetch: refetchCategories } = useCampfireQuery<
    GetAdminConsoleIncidentCategories,
    undefined
  >(GET_ADMIN_CONSOLE_INCIDENT_CATEGORIES);

  const initialSeveritiesValues = useMemo(() => {
    if (!allSeveritiesResponse?.vm.incidentSeverities) return { severities: [] };
    return {
      severities: allSeveritiesResponse.vm.incidentSeverities.map((severity) => {
        return {
          ...severity,
          listId: uuidv4(),
        };
      }),
    };
  }, [allSeveritiesResponse]);

  const incidentCategoriesRoot = useMemo(() => {
    if (!allCategoriesResponse?.vm.incidentCategorySelectionRoot) return undefined;
    return allCategoriesResponse.vm.incidentCategorySelectionRoot;
  }, [allCategoriesResponse]);
  return (
    <Box position='relative' width={1} display='flex' justifyContent='center'>
      <LinearProgressOverlay isLoading={allSeveritiesLoading || allCategoriesLoading} />
      <Box px={8} pt={4} width={1} maxWidth={1280}>
        <IncidentSeverityEditForm initialValues={initialSeveritiesValues} refetchSeverities={refetchSeverities} />
        {incidentCategoriesRoot && (
          <IncidentCategorySection
            incidentCategoriesRoot={incidentCategoriesRoot}
            refetchCategories={refetchCategories}
          />
        )}
      </Box>
    </Box>
  );
};

export { AdminConsoleIncidentReportingPanel };
