import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';
import UsingDotsGraphic from '../../../assets/tutorial-graphics/my-activities/using_dots.png';
import WaitingToBeRosteredGraphic from '../../../assets/tutorial-graphics/my-activities/waiting_to_be_rostered.png';
import LetThemKnowGraphic from '../../../assets/tutorial-graphics/my-activities/let_them_know.png';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Choose your availability for Upcoming Rosters',
    description: (
      <>
        {`Be sure to choose `}
        <i>Yes</i>
        {` or `}
        <i>No</i>
        {` to let managers know your preference.`}
        <br />
        <br />
        {`Selecting `}
        <i>Yes</i>
        {` will mark you as available.`}
        <br />
        {`Selecting `}
        <i>No</i>
        {` will mark you unavailable and you won't be rostered for that date.`}
      </>
    ),
    image: UsingDotsGraphic,
  },
  {
    label: 'Wait for a Manager to publish the roster',
    description: (
      <>
        {`If you're selected, we'll send you an email and you will see the indicator switch to a solid green.`}
        <br />
        <br />
        {`You can change your availability preference at any time before the roster is published.`}
      </>
    ),
    image: WaitingToBeRosteredGraphic,
  },
  {
    label: 'Let your managers know if anything changes',
    description: (
      <>
        {`If you're unable to make it to an activity contact your manager directly.`}
        <br />
        <br />
        {`You'll always receive emails when changes are made to the roster, or if the activity is cancelled.`}
      </>
    ),
    image: LetThemKnowGraphic,
  },
];

const MyActivitiesTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { MyActivitiesTutorialDialog };
