import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { encodeDate } from '@campfire/hot-date';
import { Box, MenuItem, TextField } from '@material-ui/core';
import { capitalize, uniqBy } from 'lodash';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useUser } from '../../../../global/auth/useUser';
import { VolunteerIdentity } from '../../../../global/auth/__generated__/VolunteerIdentity';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { GET_ALL_MEASURABLES } from './impact-dashboard-model.gql';
import { ImpactDashboardFooter } from './ImpactDashboardFooter';
import {
  ImpactDashboardGeneralDateFilter,
  impactDashboardGeneralInitialEndDate,
  impactDashboardGeneralInitialStartDate,
} from './ImpactDashboardGeneralDateFilter';
import { GetAllMeasurables, GetAllMeasurablesVariables } from './__generated__/GetAllMeasurables';
import { ImpactDashboardActivity } from './__generated__/ImpactDashboardActivity';
import { ImpactDashboardActivityStatistics } from './__generated__/ImpactDashboardActivityStatistics';
import { ImpactDashboardNumericFieldStatistic } from './__generated__/ImpactDashboardNumericFieldStatistic';
import { ImpactDashboardProgram } from './__generated__/ImpactDashboardProgram';
import { getDisplayTimeSchedule } from '../../../../common/functions/activity-display-helpers';
import { getActivityActivenessStatus } from '../../activities-v2/ActivityUtils/filters';
import { getChipsCustomBodyRender } from '../detailed-impact-dashboard/impact-dashboard-detailed-model';

const fixedColumnNames = {
  programStatus: 'program status',
  completed: 'completed activities',
  missed: 'missed activities',
  cancelled: 'cancelled activities',
  operation: 'operation hours',
  total: 'total volunteer hours',
  attendances: 'Attendances',
  volunteers: 'Unique Volunteers',
};

const baseColumns = Object.values(fixedColumnNames);

const column = (name: string) => {
  if (name === 'activity status')
    return {
      label: 'Activity Status',
      name: 'activityStatus',
      options: {
        sort: true,
        display: false,
        customBodyRender: getChipsCustomBodyRender(),
      },
    };

  if (name === 'program status') {
    return {
      label: 'Program Status',
      name: 'programStatus',
      options: {
        sort: true,
        display: false,
        customBodyRender: getChipsCustomBodyRender(),
      },
    };
  }
  return {
    name: name.replace(/\./g, ''),
    label: capitalize(name),
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
    },
  };
};

const getFixedBaseMeasurables = (activityStatistics: ImpactDashboardActivityStatistics) => {
  return {
    [fixedColumnNames.completed]: activityStatistics?.deliveryStatistics?.completed,
    [fixedColumnNames.missed]: activityStatistics?.deliveryStatistics?.missed,
    [fixedColumnNames.cancelled]: activityStatistics?.deliveryStatistics?.cancelled,
    [fixedColumnNames.operation]: parseFloat(
      activityStatistics?.activityReportsStatistics?.sessionReportStatistics.operationalHours?.toFixed(2)
    ),
    [fixedColumnNames.total]: parseFloat(
      activityStatistics?.activityReportsStatistics?.sessionReportStatistics.volunteeringHours?.toFixed(2)
    ),
    [fixedColumnNames.attendances]:
      activityStatistics.activityReportsStatistics.sessionReportStatistics.volunteersAttended,
    [fixedColumnNames.volunteers]: activityStatistics.uniqueVolunteers.length,
  };
};

const getDynamicMeasurables = (measurables?: ImpactDashboardNumericFieldStatistic[]) => {
  return measurables
    ? measurables.map((m) => {
        return {
          [m.fieldName.replace(/\./g, '')]: parseFloat(m.numericValue?.toFixed(2)),
        };
      })
    : undefined;
};

const getDynamicColumns = (arr: { activityStatistics: ImpactDashboardActivityStatistics; [key: string]: any }[]) => {
  return uniqBy(
    arr
      .filter(
        (x) => x.activityStatistics?.activityReportsStatistics?.sessionReportStatistics.numericFieldStatistics?.length
      )
      .flatMap((y) =>
        y.activityStatistics?.activityReportsStatistics?.sessionReportStatistics.numericFieldStatistics?.flatMap(
          (m) => {
            return {
              name: m.fieldName,
              id: m.fieldId,
            };
          }
        )
      ),
    'id'
  );
};

const getLocationFilterOptions = (maybeVolunteerIdentity: VolunteerIdentity | null) => {
  const baseFilterOptions = [
    { label: 'Organisation', value: 'org', csvKeyWord: 'total' },
    { label: 'All Programs', value: 'all_programs', csvKeyWord: 'programs' },
    { label: 'All Activities', value: 'all_activities', csvKeyWord: 'activity' },
  ];

  const volunteerFilterOptions = [
    { label: 'Your Programs', value: 'my_programs', csvKeyWord: 'my_programs' },
    { label: 'Your Activities', value: 'my_activities', csvKeyWord: 'my_activities' },
  ];

  if (!maybeVolunteerIdentity) {
    return baseFilterOptions;
  }

  return [...baseFilterOptions, ...volunteerFilterOptions];
};

const ImpactDashboardGeneralView = () => {
  const { maybeVolunteerIdentity } = useUser();
  const [rows, setRows] = useState<any>();
  const [columns, setColumns] = useState<any>();

  const { isMobile } = useCampfireTheme();
  const [location, setLocation] = useState<string>(getLocationFilterOptions(maybeVolunteerIdentity)[1].value);
  const [startDate, setStartDate] = useState(impactDashboardGeneralInitialStartDate);
  const [endDate, setEndDate] = useState(impactDashboardGeneralInitialEndDate);

  const selectedFilterOption = getLocationFilterOptions(maybeVolunteerIdentity).find(
    (option) => option.value === location
  );

  const selectedFilterCsvLabel = selectedFilterOption?.csvKeyWord;

  const [
    getAllMeasurablesData,
    { data: allMeasurablesResponse, loading: loadingAllMeasurables },
  ] = useCampfireLazyQuery<GetAllMeasurables, GetAllMeasurablesVariables>(GET_ALL_MEASURABLES);

  const handleActivitiesData = (activities: ImpactDashboardActivity[]) => {
    const rowData = activities.map((activity) => {
      const fixedMeasurables = {
        activity: `${activity.name} (${getDisplayTimeSchedule(activity.startTime, activity.endTime)})`,
        activityStatus: getActivityActivenessStatus(activity),
        program: activity.program.name,
        programStatus: activity.program.dateSuspended ? 'Suspended' : 'Active',
        ...getFixedBaseMeasurables(activity.activityStatistics),
      };

      const dynamicMeasurables = getDynamicMeasurables(
        activity?.activityStatistics?.activityReportsStatistics?.sessionReportStatistics.numericFieldStatistics
      );
      return dynamicMeasurables ? Object.assign({}, fixedMeasurables, ...dynamicMeasurables) : fixedMeasurables;
    });
    setRows(rowData);

    const dynamicColumns = getDynamicColumns(activities);
    const tableColumns = ['activity', 'activity status', 'program']
      .concat(baseColumns)
      .concat(dynamicColumns.map((x) => x.name));
    setColumns(tableColumns.map((col: any) => column(col)));
  };

  const handleProgramsData = (programs: ImpactDashboardProgram[]) => {
    const rowData = programs.map((program) => {
      const fixedMeasurables = {
        program: program.name,
        programStatus: program.dateSuspended ? 'Suspended' : 'Active',
        ...getFixedBaseMeasurables(program.activityStatistics),
      };

      const dynamicMeasurables = getDynamicMeasurables(
        program?.activityStatistics?.activityReportsStatistics?.sessionReportStatistics.numericFieldStatistics
      );
      return dynamicMeasurables ? Object.assign({}, fixedMeasurables, ...dynamicMeasurables) : fixedMeasurables;
    });
    setRows(rowData);

    const dynamicColumns = getDynamicColumns(programs);
    const tableColumns = ['program'].concat(baseColumns).concat(dynamicColumns?.map((x) => x?.name));
    setColumns(tableColumns.map((col) => column(col)));
  };

  const handleOrgData = (resp: GetAllMeasurables) => {
    const fixedMeasurables = getFixedBaseMeasurables(resp.vm.activityStatistics);
    const dynamicMeasurables = getDynamicMeasurables(
      resp.vm.activityStatistics?.activityReportsStatistics?.sessionReportStatistics.numericFieldStatistics
    );
    const rowData = dynamicMeasurables ? Object.assign({}, fixedMeasurables, ...dynamicMeasurables) : fixedMeasurables;
    setRows([rowData]);
    const dynamicColumns = getDynamicColumns([resp.vm]);
    const tableColumns = baseColumns.concat(dynamicColumns?.map((x) => x?.name));
    setColumns(tableColumns.map((col) => column(col)));
  };

  useEffect(() => {
    getAllMeasurablesData({
      variables: {
        startDate: encodeDate(startDate),
        endDate: encodeDate(endDate),
        volId: maybeVolunteerIdentity?.volunteerId ?? '',
      },
    });
  }, [maybeVolunteerIdentity, startDate, endDate]);

  useEffect(() => {
    if (!allMeasurablesResponse) return;
    switch (location) {
      case 'org':
        handleOrgData(allMeasurablesResponse);
        break;
      case 'all_programs':
        handleProgramsData(
          allMeasurablesResponse.vm.programs.map((program) => {
            if (program.dateSuspended !== null) {
              return { ...program, name: `${program.name} (Suspended)` };
            }
            return program;
          })
        );
        break;
      case 'my_programs':
        handleProgramsData(
          allMeasurablesResponse.vm.programs.filter((program) =>
            allMeasurablesResponse.vm.volunteer?.programs
              .map((_program) => _program.programId)
              .includes(program.programId)
          )
        );
        break;
      case 'all_activities':
        handleActivitiesData(allMeasurablesResponse.vm.activities);
        break;
      case 'my_activities':
        handleActivitiesData(
          allMeasurablesResponse.vm.activities.filter((activity) =>
            allMeasurablesResponse.vm.volunteer?.activityEnrolments
              .map((activityEnrolment) => activityEnrolment.activity.activityId)
              .includes(activity.activityId)
          )
        );
        break;
      default:
        break;
    }
  }, [location, allMeasurablesResponse]);

  const tableOptions: MUIDataTableOptions = {
    rowsPerPage: 10,
    elevation: 0,
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: true,
    print: false,
    downloadOptions: {
      filename: `Volaby-${allMeasurablesResponse?.orgName.replace(' ', '_').concat('-') ?? ''}${
        selectedFilterCsvLabel ? selectedFilterCsvLabel.concat('_') : ''
      }stats.csv`,
      separator: ',',
    },
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (x: any) => any,
      changePage: (x: number) => void,
      textLabels: any
    ) => {
      return (
        <ImpactDashboardFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      );
    },
  };

  return (
    <>
      <Box paddingTop={2} paddingLeft={isMobile ? 1 : 3} display='flex' zIndex={1200}>
        <ImpactDashboardGeneralDateFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />

        <Box width={196} marginLeft={2}>
          <TextField
            fullWidth
            label='Grouping'
            select
            variant='outlined'
            value={location}
            SelectProps={{ native: false }}
            onChange={(e) => setLocation(e.target.value)}
          >
            {getLocationFilterOptions(maybeVolunteerIdentity).map((filter) => (
              <MenuItem key={filter.value} value={filter.value}>
                {filter.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <MUIDataTable title='' data={rows || []} columns={columns} options={tableOptions} />

      <CircularProgressOverlay isLoading={loadingAllMeasurables} />
    </>
  );
};

export { ImpactDashboardGeneralView };
