import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { IWidget, WidgetTypes } from '../dashboard-widget-model';
import { ThumbnailWidgetCard } from '../ThumbnailWidgetCard';

interface Props {
  value?: WidgetTypes;
  onSelect: (numberType: WidgetTypes) => void;
  supportingNumberTypes: IWidget[];
}

export function SelectNumberType({ onSelect, value, supportingNumberTypes }: Props) {
  return (
    <Grid container spacing={2}>
      {supportingNumberTypes.map((widgetType) => (
        <Grid item sm={4}>
          <Box display='flex' justifyContent='center'>
            <ThumbnailWidgetCard {...widgetType} onClick={onSelect} isSelected={value === widgetType.type} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
