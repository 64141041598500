import React, { ComponentPropsWithoutRef, memo } from 'react';
import { Typography, Box } from '@material-ui/core';
import { capitalize } from 'lodash';
import Sunrise from '../../../../assets/day-time-indicators/sunrise.svg';
import Sunset from '../../../../assets/day-time-indicators/sunset.svg';
import Daytime from '../../../../assets/day-time-indicators/daytime.svg';
import Night from '../../../../assets/day-time-indicators/night.svg';

// TODO: @Bdita find out how to import svg as React Component and handle default case in function below, also strict indicator type 'morning' | 'daytime'.....
export const getDayTimeIndicatorIcon = (indicator: string | undefined) => {
  switch (indicator) {
    case 'morning':
      return Sunrise;
    case 'daytime':
      return Daytime;
    case 'evening':
      return Sunset;
    case 'night':
      return Night;
    default:
      return '';
  }
};

const ActivityDayTimeIndicatorIcon = ({
  indicator,
  ...rest
}: { indicator: string | undefined } & ComponentPropsWithoutRef<'img'>) =>
  indicator ? <img src={getDayTimeIndicatorIcon(indicator)} alt={`${indicator} icon`} {...rest} /> : null;

const ActivityDayTimeIndicator = memo(
  ({ indicator, direction }: { indicator: string | undefined; direction: 'row' | 'column' }) => {
    return (
      <Box display='flex' flexDirection={direction === 'row' ? 'row-reverse' : direction} alignItems='flex-end'>
        <Box display='flex' justifyContent='center' px={direction === 'row' ? 1 : 0}>
          <ActivityDayTimeIndicatorIcon indicator={indicator} />
        </Box>
        <Typography variant='caption' display='block'>
          {capitalize(indicator)}
        </Typography>
      </Box>
    );
  }
);

export { ActivityDayTimeIndicator, ActivityDayTimeIndicatorIcon };
