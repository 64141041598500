export const getMediaType = (url: string) => {
  const urlParser = new URL(url);

  if (urlParser.origin.includes('youtube')) {
    return 'VOLUNTEER_YoutubeMediaType';
  }
  if (urlParser.origin.includes('vimeo')) {
    return 'VOLUNTEER_VimeoMediaType';
  }
  return 'VOLUNTEER_YoutubeMediaType';
}

export const getVideoId = (typename: string) => (videoUrl: string) => {
  const url = new URL(videoUrl);
  const searchParams = new URLSearchParams(url.search);

  if (typename === 'VOLUNTEER_YoutubeMediaType') {
    return searchParams.get('v');
  }

  if (typename === 'VOLUNTEER_VimeoMediaType') {
    return url.pathname.slice(1);
  }

  return videoUrl;
}

export const getVideoUrl = (typename: string) => (videoId: string) => {
  if (typename === 'VOLUNTEER_YoutubeMediaType') {
    return `https://www.youtube.com/watch?v=${videoId}`;
  }

  if (typename === 'VOLUNTEER_VimeoMediaType') {
    return `https://vimeo.com/${videoId}`;
  }
  return videoId;
}