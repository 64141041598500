import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React from 'react';
import { TaskFormItem_VOLUNTEER_TaskItemFieldType_field_VOLUNTEER_CheckboxTaskFieldType as CheckboxFieldType } from '../../../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';
import { DropItemTrigger } from './DropItemTrigger';

interface CheckboxFieldPreviewProps {
  field: CheckboxFieldType;
  isDragging: boolean;
}

export function CheckboxFieldPreview({ field, isDragging }: CheckboxFieldPreviewProps) {
  if (!field.dynamic || isDragging) {
    return (
      <Grid container direction='column'>
        {field.checkboxOptions.map((option) => {
          const optionId = option.checkboxFieldOptionId;
          return (
            <Grid item key={optionId} style={{ overflow: 'hidden', maxWidth: '100%' }}>
              <FormControlLabel control={<Checkbox disabled />} label={option.name} />
            </Grid>
          );
        })}
      </Grid>
    );
  }
  return (
    <Grid container direction='column'>
      {field.checkboxOptions.map((option) => {
        const optionId = option.checkboxFieldOptionId;
        return (
          <Grid item key={optionId} style={{ overflow: 'hidden', maxWidth: '100%' }}>
            <DropItemTrigger optionId={optionId} name={option.name} />
          </Grid>
        );
      })}
    </Grid>
  );
}
