import { gql } from '@apollo/client';

export const GET_BULLETIN_MANAGEMENT_DATA = gql`
  query GetBulletinManagementData($programIds: [String!]) {
    vm {
      bulletins(editable: true, programIds: $programIds) {
        ...BulletinManagement
      }
    }
  }

  fragment BulletinManagement on VOLUNTEER_BulletinType {
    bulletinId
    title
    content
    pinned
    dateCreated
    isCommentable
    programs {
      programId
      name
    }
    activities {
      activityId
      name
    }
    activityRoles {
      activityRoleId
      name
    }
    permissionLevel
    createdBy {
      profile {
        profileId
        preferredName
        lastName
      }
    }
    dateUpdated
    lastUpdatedBy {
      profile {
        profileId
        preferredName
        lastName
      }
    }
  }
`;

export const MANAGEMENT_VOLUNTEER_PROFILE_BULLETIN_GET_ALL_PROGRAMS = gql`
  query ManagementVolunteerProfileBulletinGetAllPrograms {
    vm {
      programs {
        programId
        name
        dateSuspended
        dateDeleted
        activities {
          activityId
          name
        }
      }
    }
  }
`;


export const MANAGEMENT_VOLUNTEER_PROFILE_BULLETIN_GET_ALL_ROLES = gql`
  query ManagementVolunteerProfileBulletinGetAllRoles {
    vm {
      activityRoles {
        activityRoleId
        name
      }
    }
  }
`;

export const BULLETIN_IS_COMMENTABLE = gql`
  query GetBulletinIsCommentable {
    vm {
      configItem(key: "bulletin-allow-comment") {
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;