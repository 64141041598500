import React from 'react';
import { Box, Paper, Slide, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { TabletButton } from '@campfire/tablet-button';

import { RosterContext } from './RosterContext';
import { Avatar } from '../../../general/user-profile/components/Avatar';

const MAX_AVATAR = 3;

const useDrawerStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 0,
      display: 'flex',
      left: 0,
      right: 0,
      margin: 'auto',
      maxWidth: 1008,
      zIndex: 10,
    },
    paper: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      position: 'relative',
      boxSizing: 'border-box',
      width: '100%',
      paddingLeft: 32,
      paddingRight: 16,
      paddingTop: 24,
      paddingBottom: 24,
    },
    title: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
  })
);

interface DrawerProps {
  open: boolean;
  children: React.ReactElement;
}

export function Drawer({ open, children }: DrawerProps) {
  const classes = useDrawerStyles();
  return (
    <Box className={classes.root}>
      <Slide direction='up' in={open} timeout={100}>
        <Paper elevation={8} square className={classes.paper}>
          {children}
        </Paper>
      </Slide>
    </Box>
  );
}

export function SaveDrawer() {
  const classes = useDrawerStyles();
  const { onSave, onPublish, onDiscard, toBeUpdate, toBePublish, updatingVolunteers } = React.useContext(RosterContext);
  return (
    <Drawer open={toBeUpdate || toBePublish}>
      <Box>
        <Typography variant='h3' className={classes.title}>
          {`${toBeUpdate ? 'Unsaved' : 'Unpublished'} Changes`}
        </Typography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            {updatingVolunteers
              .filter((volunteer) => Boolean(volunteer))
              .slice(0, MAX_AVATAR)
              .map(({ volunteerId, profile: { preferredName, lastName, avatarUrl } }) => (
                <Avatar
                  key={volunteerId}
                  preferredName={preferredName}
                  lastName={lastName}
                  avatarUrl={avatarUrl}
                  size={22}
                  style={{ marginLeft: -2, marginRight: -2 }}
                />
              ))}
            {updatingVolunteers.length > MAX_AVATAR && (
              <Avatar
                preferredName='+'
                lastName={`${updatingVolunteers.length - MAX_AVATAR}`}
                size={22}
                style={{ marginLeft: -2, marginRight: -2 }}
              />
            )}
            <Typography>&nbsp;will be notified when you pubish this roster</Typography>
          </Box>
          <Box display='flex'>
            <TabletButton variant='outlined' onClick={onSave} disabled={!toBeUpdate}>
              Save Changes
            </TabletButton>
            <TabletButton
              variant='contained'
              color='primary'
              style={{ marginLeft: '0.5rem' }}
              onClick={onPublish}
              disabled={!toBePublish}
            >
              Publish Changes
            </TabletButton>
            <TabletButton style={{ marginLeft: '0.5rem' }} onClick={onDiscard} disabled={!toBeUpdate}>
              Discard Changes
            </TabletButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
