import React from 'react';
import { Box, Typography, Tooltip, IconButton, ClickAwayListener, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Edit, DeleteOutline, MoreHorizOutlined } from '@material-ui/icons';

interface Props {
  onAction: (action: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      padding: '0.5rem',
      borderRadius: '4px',
      cursor: 'pointer',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.75,
      },
    },
    menuItemText: {
      marginLeft: '0.75rem',
      fontSize: '14px',
    },
    tooltip: {
      borderRadius: '12px',
      paddingTop: '0.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '0.5rem',
      backgroundColor: theme.color.tooltip.background,
    },
    tooltipArrow: {
      '&::before': {
        backgroundColor: theme.color.tooltip.background,
      },
    },
  })
);

export function TagItemMenu({ onAction }: Props) {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.menuItem} onClick={() => onAction('edit')}>
        <Edit fontSize='small' />
        <Typography className={classes.menuItemText}>Edit</Typography>
      </Box>
      <Box className={classes.menuItem} onClick={() => onAction('delete')}>
        <DeleteOutline fontSize='small' />
        <Typography className={classes.menuItemText}>Delete</Typography>
      </Box>
    </Box>
  );
}

export function TagItemMenuButton({ onAction }: Props) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const onCloseMenu = () => setMenuOpen(false);
  const onOpenMenu = () => setMenuOpen(true);

  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={onCloseMenu}>
      <Tooltip
        arrow
        interactive
        title={<TagItemMenu onAction={onAction} />}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement='bottom-end'
        open={menuOpen}
        onClose={onCloseMenu}
        onOpen={onOpenMenu}
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.tooltipArrow,
        }}
      >
        <IconButton onClick={onOpenMenu} style={{ color: '#C4C4C4', padding: '0.5rem' }}>
          <MoreHorizOutlined />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}
