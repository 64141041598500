import { unpackToDate } from '@campfire/hot-date';
import { Box, Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { SmsConfirmationIndicators } from './SmsConfirmationIndicators';
import { RosteringsAvatars } from './RosteringsAvatars';
import { useUser } from '../../../../../global/auth/useUser';
import { GetRosterSmsConfirmationData_vm_draftRoster as DraftRoster } from './__generated__/GetRosterSmsConfirmationData';

const useStyles = makeStyles({
  smsInfoBox: {
    background: '#FFFBEB',
    display: 'flex',
  },
  warningIcon: {
    color: '#FBBF24',
    fontSize: '16px',
    padding: '18px 16px',
  },
});

interface Props {
  draftRoster?: DraftRoster;
}

export const SmsConfirmationInfo = (props: Props) => {
  const { draftRoster } = props;
  const classes = useStyles();
  const { user } = useUser();

  const publishedBy = useMemo(() => {
    if (draftRoster?.publishedRoster?.publishedByManager) {
      return `${draftRoster.publishedRoster.publishedByManager.profile.preferredName} ${
        draftRoster.publishedRoster.publishedByManager.profile.lastName
      } on ${unpackToDate(draftRoster.publishedRoster.datePublished).toFormat('dd MMM yyyy')}`;
    }
    if (draftRoster?.publishedRoster?.publishedByVolunteer) {
      return `${draftRoster.publishedRoster.publishedByVolunteer.profile.preferredName} ${
        draftRoster.publishedRoster.publishedByVolunteer.profile.lastName
      }  on ${unpackToDate(draftRoster.publishedRoster.datePublished).toFormat('dd MMM yyyy')}`;
    }
    return '';
  }, [draftRoster]);
  const activeRosterings = useMemo(() => draftRoster?.publishedRoster?.activeRosterings ?? [], [draftRoster]);
  const allRosterings = useMemo(() => draftRoster?.publishedRoster?.rosterings ?? [], [draftRoster]);
  const rosteredRosterings = useMemo(() => {
    return activeRosterings.filter((r) => r.sessionRosterings.length > 0) ?? [];
  }, [activeRosterings]);

  if (!user.orgInfo.smsEnabled) return null;
  return (
    <Box className={classes.smsInfoBox}>
      <WarningRounded className={classes.warningIcon} />
      <Box pt={2} pb={'36px'} pr={2}>
        <Typography
          variant={'subtitle2'}
          style={{
            color: '#92400E',
            lineHeight: '20px',
            fontSize: '14px',
          }}
        >
          {'An SMS reminder will be sent to your rostered volunteers 24-48 hours prior to this activity.'}
        </Typography>
        {publishedBy ? (
          <Typography
            style={{
              color: '#B45309',
              fontSize: '14px',
            }}
          >
            {`This activity uses managed rosters, last published by ${publishedBy}.`}
          </Typography>
        ) : null}
        <Box style={{ paddingTop: '5px', paddingBottom: '5px' }}>
          <SmsConfirmationIndicators activeRosterings={activeRosterings} allRosterings={allRosterings} />
        </Box>
        <Box width={'100%'} overflow={'hidden'}>
          <RosteringsAvatars rosterings={rosteredRosterings} />
        </Box>
      </Box>
    </Box>
  );
};
