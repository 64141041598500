import { encodeDate } from '@campfire/hot-date';
import { Box } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import React, { memo, useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { FilterItem } from '../../../../../common/filter-fields/FilterItem';
import {
  Filters,
  DATE_FILTER_END_DATE_URL_PARAM_KEY,
  DATE_FILTER_START_DATE_URL_PARAM_KEY,
} from '../../../../../common/filter-fields/filters';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

type InnerTimeFilterSelectProps = {
  start: string | undefined;
  end: string | undefined;
  setStart: (value?: string) => void;
  setEnd: (value?: string) => void;
};

const InnerDateFilterSelect = (props: InnerTimeFilterSelectProps) => {
  const { start, end, setStart, setEnd } = props;
  const { isSm, isMobile } = useCampfireTheme();
  return (
    <Box display='flex' flexDirection={isSm ? 'column' : 'row'} marginTop={2}>
      <KeyboardDatePicker
        fullWidth
        variant='inline'
        size='small'
        format='dd/MM/yyyy'
        id='start-date'
        value={start ?? null}
        label='Start Date'
        inputVariant='outlined'
        onChange={(date) => (date ? setStart(encodeDate(date)) : setStart(undefined))}
        autoOk
        placeholder='dd/mm/yyyy'
        allowKeyboardControl
        style={{
          marginRight: isSm ? 0 : 20,
          marginBottom: isSm ? 20 : 0,
          minWidth: 156,
          maxWidth: isMobile ? 'none' : isSm ? 250 : 'none',
        }}
        InputAdornmentProps={{
          position: 'start',
        }}
        KeyboardButtonProps={{ tabIndex: -1 }}
      />

      <KeyboardDatePicker
        fullWidth
        variant='inline'
        size='small'
        format='dd/MM/yyyy'
        id='end-date'
        value={end ?? null}
        label='End Date'
        inputVariant='outlined'
        onChange={(date) => (date ? setEnd(encodeDate(date)) : setEnd(undefined))}
        autoOk
        placeholder='dd/mm/yyyy'
        allowKeyboardControl
        style={{ minWidth: 156, maxWidth: isMobile ? 'none' : isSm ? 250 : 'none' }}
        InputAdornmentProps={{
          position: 'start',
        }}
        KeyboardButtonProps={{ tabIndex: -1 }}
      />
    </Box>
  );
};

type DateFilterFieldsProps = {
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  isDefaultTimes?: boolean;
  onClose?: () => void;
};

const DateFilterFields = memo(({ setSelectedFilters }: DateFilterFieldsProps) => {
  const [start, setStart] = useQueryParam(DATE_FILTER_START_DATE_URL_PARAM_KEY, StringParam);
  const [end, setEnd] = useQueryParam(DATE_FILTER_END_DATE_URL_PARAM_KEY, StringParam);

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      selectedDateFilter: {
        startDate: start ? DateTime.fromFormat(start, 'yyyy-MM-dd').toJSDate() : '',
        endDate: end ? DateTime.fromFormat(end, 'yyyy-MM-dd').toJSDate() : '',
      },
    }));
  }, [start, end]);
  return (
    <FilterItem title='DATE' underline>
      <InnerDateFilterSelect start={start} end={end} setStart={setStart} setEnd={setEnd} />
    </FilterItem>
  );
});

export { DateFilterFields, InnerDateFilterSelect };
