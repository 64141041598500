import { gql } from '@apollo/client';

const ACTIVITY_REPORT_ACTIVITY_FRAGMENT = gql`
  fragment ActivityReportFragment on VOLUNTEER_ActivityType {
    activityId
    name
    startTime
    endTime
    startDate
    endDate
    hasOpenRoster
    isActive
    isSuspended
    activityLocation {
      activityLocationId
    }
    closedActivity {
      closedActivityId
    }
  }
`;

export const ACTIVITY_REPORT_INCOMPLETE_REPORT_ACTIVITY_FRAGMENT = gql`
  fragment ActivityIncompleteReportFragment on VOLUNTEER_IncompleteActivityReportType {
    activityId
    name
    startTime
    endTime
    startDate
    endDate
    hasOpenRoster
    isActive
    isSuspended
    activityLocation {
      activityLocationId
    }
    closedActivity {
      closedActivityId
    }
  }
`;

export const ACTIVITY_REPORT_GET_ACTIVITY_REPORTS = gql`
  query ActivityReportGetAcitivityReports(
    $programId: String
    $isOmitted: Boolean
    $from: Date
    $until: Date
    $pagination: PaginationInputType
  ) {
    vm {
      activityReports(
        programId: $programId
        isOmitted: $isOmitted
        from: $from
        until: $until
        pagination: $pagination
      ) {
        activityDate
        activity {
          ...ActivityReportFragment
        }
      }
    }
  }
  ${ACTIVITY_REPORT_ACTIVITY_FRAGMENT}
`;

export const ACTIVITY_REPORT_GET_INCOMPLETE_ACTIVITY_REPORTS = gql`
  query ActivityReportIncompleteActivityReport(
    $programIds: [String!]
    $activityIds: [String!]
    $from: Date
    $until: Date
    $includeInactiveActivities: Boolean
  ) {
    vm {
      incompleteActivityReports(
        programIds: $programIds
        activityIds: $activityIds
        includeInactiveActivities: $includeInactiveActivities
      ) {
        occurrences(from: $from, until: $until)
        ...ActivityIncompleteReportFragment
      }
    }
  }
  ${ACTIVITY_REPORT_INCOMPLETE_REPORT_ACTIVITY_FRAGMENT}
`;
