import { TabletButton } from '@campfire/tablet-button';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import React, { useState } from 'react';

interface AddOtherAttendeeDialogProps {
  open: boolean;
  onClose: (event: any) => void;
  onSubmit: (attendeeName: string) => void;
}

const AddOtherAttendeeDialog = (props: AddOtherAttendeeDialogProps) => {
  const { open, onClose, onSubmit } = props;
  const [attendeeName, setAttendeeName] = useState<string>('');
  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle>{'Add Other Attendee'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          variant='outlined'
          label='Name of Attendee'
          value={attendeeName}
          onChange={(event) => setAttendeeName(event.target.value as string)}
        />
      </DialogContent>
      <DialogActions>
        <Box padding={2}>
          <TabletButton variant='text' color='error' onClick={onClose} style={{ marginRight: 8 }}>
            {'Cancel'}
          </TabletButton>
          <TabletButton
            data-track='actCnl-add-attendee'
            variant='contained'
            color='primary'
            onClick={() => {
              onSubmit(attendeeName);
              setAttendeeName('');
              onClose({});
            }}
          >
            {'Add Attendee'}
          </TabletButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { AddOtherAttendeeDialog };
