import { unpackToDateTime } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { Theme } from '@material-ui/core';
import { uniqBy } from 'lodash';
import { FugFile } from '@campfire/file-upload-gallery';
import {
  GetRosterScreenRosterData_vm_activity_activityEnrolments as ActivityEnrolment,
  GetRosterScreenRosterData_vm_draftRoster_draftRosterings as DraftRostering,
  GetRosterScreenRosterData_vm_draftRoster_publishedRoster_rosterings as PublishedRostering,
} from './__generated__/GetRosterScreenRosterData';
import { useEndpointFetch } from '../../../../../global/network/useEndpointFetch';
import {
  RosterStatus,
  RowAction,
  SessionStatus,
  SessionStatuses,
  StatusObject,
  UpdatedRostering,
  Volunteer,
  RosterListItem,
} from './roster-types';
import { GetRostersSidebarData_vm_activities as Activity } from '../../__generated__/GetRostersSidebarData';
import { GetRosterItemStatusData_vm_activities as ActivitySingle } from '../../sidebar/__generated__/GetRosterItemStatusData';
import { GetRosterScreenProgramVolunteersData_vm_program_activeVolunteers as ActiveVolunteerType } from './__generated__/GetRosterScreenProgramVolunteersData';

export const useSaveRosterNotes = () => {
  return useEndpointFetch<
    {
      activityId: string;
      activityDate: string;
      rosterNotes?: string;
    },
    { draftRosterId: string }
  >('/vm/activity/roster/save-notes');
};

export const useSaveDraftRosterFetch = () => {
  return useEndpointFetch<
    {
      activityId: string;
      activityDate: string;
      draftRosterings: {
        volunteerId: string;
        sessionIds: string[];
      }[];
      immediatePublish?: boolean;
      rosterTemplateId?: string;
      rosterNotes?: string | null;
      sessionNotes: Array<{ sessionId: string; notes: string }>;
    },
    { draftRosterId: string }
  >('/vm/activity/roster/save-draft');
};

export const useCancelActivityFetch = () => {
  return useEndpointFetch<
    {
      activityId: string;
      activityDate: string;
      activityCancellationReasonId: string;
      description: string;
      addedAttachments?: FugFile[];
      attachmentCreationTokens?: string[];
    },
    { cancelledActivityId: string }
  >('/vm/activity/cancel', { formData: true });
};

export const useUnpublishRosterFetch = () => {
  return useEndpointFetch<{ activityId: string; activityDate: string }>('/vm/activity/roster/unpublish');
};

export const parseEnrolments = (activityEnrolments: ActivityEnrolment[]): Volunteer[] => {
  return activityEnrolments.map((e) => ({
    ...e.volunteer,
    enrolled: true,
    publishedSessionIds: [],
    draftedSessionIds: [],
    unavailable: !!e.unavailability,
    availability: e.availability,
    attending: null,
    activityEnrolmentId: e.activityEnrolmentId,
  }));
};

export const parseRosterings = (
  draftRosterings: DraftRostering[],
  publishedRosterings: PublishedRostering[],
  enrolments: Volunteer[]
): Volunteer[] => {
  const parsedPublishedRosterings = publishedRosterings.map((r) => {
    const matchedDraftRostering = draftRosterings.find((dr) => dr.volunteer.volunteerId === r.volunteer.volunteerId);
    const matchedEnrolment = enrolments.find((e) => e.volunteerId === r.volunteer.volunteerId);

    return {
      ...r.volunteer,
      enrolled: !!matchedEnrolment,
      publishedSessionIds: r.sessionRosterings.map((sr) => sr.session.sessionId),
      draftedSessionIds: matchedDraftRostering
        ? matchedDraftRostering.sessionRosterings.map((sr) => sr.session.sessionId)
        : [],
      unavailable: matchedEnrolment?.unavailable ?? false,
      availability: matchedEnrolment?.availability ?? null,
      attending: r.attending,
    };
  });

  const parsedDraftRosterings = draftRosterings.map((r) => {
    const matchedEnrolment = enrolments.find((e) => e.volunteerId === r.volunteer.volunteerId);

    return {
      ...r.volunteer,
      enrolled: !!matchedEnrolment,
      publishedSessionIds: [],
      draftedSessionIds: r.sessionRosterings.map((sr) => sr.session.sessionId),
      unavailable: matchedEnrolment?.unavailable ?? false,
      availability: matchedEnrolment?.availability ?? null,
      attending: null,
    };
  });

  return uniqBy([...parsedPublishedRosterings, ...parsedDraftRosterings, ...enrolments], 'volunteerId');
};

export const parseAddedVolunteers = (addedVolunteers: ActiveVolunteerType[]): Volunteer[] => {
  return addedVolunteers.map((v) => {
    return {
      ...v,
      enrolled: false,
      publishedSessionIds: [],
      draftedSessionIds: [],
      unavailable: false,
      availability: null,
      attending: null,
    };
  });
};

const sessionIdsMatch = (draftSessionIds: string[], publishedSessionIds: string[]) => {
  const sessionLengthsMatch = draftSessionIds.length === publishedSessionIds.length;
  const sessionIdsMatchUp = draftSessionIds.every((sid) => publishedSessionIds.includes(sid));
  return sessionLengthsMatch && sessionIdsMatchUp;
};

const draftRosterNotPublished = (publishedRoster: Volunteer[]) => {
  return !publishedRoster.every((r) => sessionIdsMatch(r.draftedSessionIds, r.publishedSessionIds));
};

export const getInitRosterState = (publishedRoster: Volunteer[]) => {
  if (draftRosterNotPublished(publishedRoster)) return 'not-published';
  return 'published';
};

const changesExistBetweenRosters = (currentRoster: Volunteer[], publishedRoster: Volunteer[]) => {
  const rostersAreTheSame = publishedRoster.every((r, i) =>
    sessionIdsMatch(r.draftedSessionIds, currentRoster[i].draftedSessionIds)
  );
  return !rostersAreTheSame;
};

export const getUpdatedRosterState = (updatedRoster: Volunteer[], publishedRoster: Volunteer[]) => {
  const changesExist = changesExistBetweenRosters(updatedRoster, publishedRoster);
  if (changesExist) return 'not-saved';
  if (draftRosterNotPublished(publishedRoster)) return 'not-published';
  return 'published';
};

export const getRosteringsWithChanges = (draftRoster: Volunteer[]): UpdatedRostering[] => {
  const changedRosterings: UpdatedRostering[] = [];

  draftRoster.forEach((r) => {
    if (!sessionIdsMatch(r.draftedSessionIds, r.publishedSessionIds)) {
      changedRosterings.push({
        volunteerId: r.volunteerId,
        profile: r.profile,
      });
    }
  });

  return changedRosterings;
};

export const getRosterStatus = (activity: Activity | ActivitySingle, roster: RosterListItem): RosterStatus => {
  // roster is completed
  if (roster.activityStatus === 'completed') {
    if (activity.publishedRosters.filter(({ activityDate }) => activityDate === roster.activityDate).length === 0) {
      return 'not-published';
    }

    const sessionReports = activity.activityReports
      .filter(({ activityDate }) => activityDate === roster.activityDate)
      .flatMap((activityReport) => activityReport.sessionReports);

    if (sessionReports.length === 0) {
      return 'report-not-started';
    }

    if (sessionReports.length < activity.sessions.length) {
      return 'report-missing';
    }
  }

  // roster is cancelled
  if (activity.cancelledActivities.some((ca) => ca.activityDate === roster.activityDate)) {
    return 'cancelled';
  }
  // roster is published
  if (
    activity.draftRosters.some(
      (draft) =>
        draft.activityDate === roster.activityDate && !!draft.publishedRoster && !draft.publishedRoster.dateRemoved
    )
  ) {
    return 'published';
  }
  // roster is drafted
  if (activity.draftRosters.some((draft) => draft.activityDate === roster.activityDate)) {
    return 'draft';
  }
  // roster not started
  return 'neutral';
};

export const getActivityStatus = (activity: Activity, date: string) => {
  const isCompleted = activity.isActive && unpackToDateTime(`${date}T${activity.endTime}`) < DateTime.local();
  if (activity.isSuspended) return 'suspended';
  if (activity.cancelledActivities?.find((ca) => ca.activityDate === date)) return 'cancelled';
  if (activity.closedActivity !== null) return 'closed';
  if (unpackToDateTime(activity.endDate) < DateTime.local()) return 'ended';
  if (isCompleted) {
    return 'completed';
  }
  return 'active';
};

export const getSessionStatuses = (theme: Theme): SessionStatuses => ({
  rostered: {
    text: 'Rostered',
    color: theme.color.rosters.rostered,
  },
  toBeRemoved: {
    text: 'To be removed',
    color: theme.color.rosters.removed,
  },
  toBeRostered: {
    text: 'To be rostered',
    color: theme.color.rosters.selected,
  },
  notRostered: {
    text: 'No indication',
    color: theme.color.rosters.empty,
  },
  unavailable: {
    text: 'Unavailable',
    color: theme.color.rosters.unavailabe,
  },
  available: {
    text: 'Available',
    color: theme.color.rosters.available,
  },
});

export const getRosteringSessionStatus = (
  sessionId: string,
  rostering: Volunteer,
  sessionStatuses: SessionStatuses
): StatusObject => {
  if (rostering.publishedSessionIds?.includes(sessionId) && rostering.draftedSessionIds?.includes(sessionId)) {
    return sessionStatuses.rostered;
  }

  if (rostering.publishedSessionIds?.includes(sessionId) && !rostering.draftedSessionIds?.includes(sessionId)) {
    return sessionStatuses.toBeRemoved;
  }

  if (rostering.draftedSessionIds?.includes(sessionId)) {
    return sessionStatuses.toBeRostered;
  }

  if (rostering.unavailable) {
    return sessionStatuses.unavailable;
  }

  if (rostering.availability?.sessionAvailabilities?.find((s) => s.session.sessionId === sessionId)) {
    return sessionStatuses.available;
  }
  return sessionStatuses.notRostered;
};

export const getRosterAction = (sessionStatus: SessionStatus): RowAction => {
  if (['Rostered', 'To be rostered'].includes(sessionStatus)) return 'remove';
  return 'add';
};

export const getCreateRosterTemplateLink = (activityId?: string, view?: string) => {
  const activityParam = activityId ? `activityId=${activityId}` : '';
  const MANAGEMENT_PATH = '/management/rosters';
  const viewParam = view === 'bulk' ? '&view=bulk' : '';
  return `${MANAGEMENT_PATH}/create-roster-template?${activityParam}${viewParam}`;
};

const sortVolunteers = (aProfile: any, bProfile: any, aHasRostering: boolean, bHasRostering: boolean): number => {
  if (aHasRostering && bHasRostering) return aProfile.preferredName.localeCompare(bProfile.preferredName);
  if (aHasRostering) return -1;
  if (bHasRostering) return 1;
  return aProfile.preferredName.localeCompare(bProfile.preferredName);
};
export const sortVolunteersByRostering = (a: any, b: any, rosters?: any): number => {
  if (rosters) {
    const aHasRostering = hasRostering({ volunteer: a, rosters });
    const bHasRostering = hasRostering({ volunteer: b, rosters });
    return sortVolunteers(a.profile, b.profile, aHasRostering, bHasRostering);
  }
  const aHasRostering = a.publishedSessionIds?.length > 0;
  const bHasRostering = b.publishedSessionIds?.length > 0;
  return sortVolunteers(a.profile, b.profile, aHasRostering, bHasRostering);
};

export const sortVolunteersByAvailability = (a: any, b: any): number => {
  const aAvailability = a.availability;
  const bAvailability = b.availability;
  const aUnavailable = a.unavailable;
  const bUnavailability = b.unavailable;
  const aRostered = a.publishedSessionIds?.length > 0;
  const bRostered = b.publishedSessionIds?.length > 0;

  if (aRostered && bRostered) return a.profile.preferredName.localeCompare(b.profile.preferredName);
  if (aRostered) return -1;
  if (bRostered) return 1;

  if (aAvailability && bAvailability) return a.profile.preferredName.localeCompare(b.profile.preferredName);
  if (aAvailability) return -1;
  if (bAvailability) return 1;

  if (aUnavailable) return 1;
  if (bUnavailability) return -1;
  return a.profile.preferredName.localeCompare(b.profile.preferredName);
};

const hasRostering = (args: { volunteer: any; rosters: any }): boolean => {
  const { volunteer, rosters } = args;
  return Object.values(rosters).some(
    (roster: any) => roster.volunteers[volunteer.volunteerId]?.publishedRoster?.length > 0
  );
};

export const sortVolunteersByEnrolmentDate = (a: any, b: any, ids?: string[]): number => {
  if (!ids) return a.profile.preferredName.localeCompare(b.profile.preferredName);

  const aInSortedIds = ids.includes(a.volunteerId);
  const bInSortedIds = ids.includes(b.volunteerId);
  if (aInSortedIds && bInSortedIds) return ids.indexOf(a.volunteerId) - ids.indexOf(b.volunteerId);
  if (aInSortedIds) return -1;
  if (bInSortedIds) return 1;
  return a.profile.preferredName.localeCompare(b.profile.preferredName);
};
