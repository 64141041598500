import { DatePickerField } from '@campfire/date-picker';
import { Field, FieldProps } from '@campfire/field';
import { FileField } from '@campfire/file-field';
import { encodeDateTime, unpackToDate, unpackToTime } from '@campfire/hot-date';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { CampfireRadioGroup } from '@campfire/radio-group';
import { Select } from '@campfire/select';
import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  Grid,
  Typography,
  Zoom,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Check as CheckIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
  PersonAdd as PersonAddIcon,
} from '@material-ui/icons';
import { connect, Form, Formik, getIn } from 'formik';
import { DateTime } from 'luxon';
import React, { CSSProperties, Fragment, FunctionComponent, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { array, mixed, object, string, date } from 'yup';
import { get } from 'lodash';
import { TrafficLightCard } from '../../../common/cards/TrafficLightCard';
import { BottomErrorMessage } from '../../../common/form/BottomErrorMessage';
import { FullPageMessage } from '../../../common/FullPageMessage';
import { getDisplayNameWithTime } from '../../../common/functions/activity-display-helpers';
import { arrayHead } from '../../../common/functions/array-head';
import { PersonSelector } from '../../../common/person-selector/PersonSelector';
import { TimePicker } from '../../../common/TimePicker';
import { useUser } from '../../../global/auth/useUser';
import { DesktopWidthHack } from '../../../global/components/DesktopWidthHack';
import { useApiUrl } from '../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../global/network/useCampfireFetch';
import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import {
  GET_INCIDENT_REPORT_ALL_PROGRAMS,
  GET_INCIDENT_REPORT_SCREEN,
  GET_INCIDENT_REPORT_VOLUNTEER_PROGRAMS,
  IncidentReportCategoryChildGroupType,
  GET_INCIDENT_REPORT_SINGLE_INCIDENT,
} from './incident-report-model.gql';
import { IncidentReportAddressSection } from './IncidentReportAddressSection';
import { GetIncidentReportAllPrograms } from './__generated__/GetIncidentReportAllPrograms';
import { GetIncidentReportScreen } from './__generated__/GetIncidentReportScreen';
import {
  GetIncidentReportVolunteerPrograms,
  GetIncidentReportVolunteerProgramsVariables,
} from './__generated__/GetIncidentReportVolunteerPrograms';
import { IncidentReportActivity } from './__generated__/IncidentReportActivity';
import { IncidentReportFormCategory } from './__generated__/IncidentReportFormCategory';
import { IncidentReportIncidentSeverity } from './__generated__/IncidentReportIncidentSeverity';
import { IncidentReportProgram } from './__generated__/IncidentReportProgram';
import {
  GetIncidentReportSingleIncident,
  GetIncidentReportSingleIncidentVariables,
  GetIncidentReportSingleIncident_vm_incident as Incident,
} from './__generated__/GetIncidentReportSingleIncident';
import {
  IncidentReportSingleIncident_incidentReports_volunteersInvolved as VolunteersInvolved,
  IncidentReportSingleIncident_incidentReports_detail_incidentCategory_incidentCategoryGroup as IncidentCategoryGroup,
} from './__generated__/IncidentReportSingleIncident';

import { useSnackbar } from '../../../global/config/useSnackbar';
import { FormikErrorFocus } from '../../../common/form/FormikErrorFocus';
import { IncidentReportTutorialDialog } from './IncidentReportTutorialDialog';
import { Page } from '../../../global/components';

const Heading: FunctionComponent<any> = ({ children, ...rest }) => {
  return (
    <Typography {...rest} variant='h6'>
      {children}
    </Typography>
  );
};

const ExpansionPanel = withStyles({
  root: {
    padding: 0,
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      color: theme.palette.primary.main,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const ExpandedGroupIcon = ({ expanded }: { expanded: boolean }) => (
  <span style={{ marginRight: 8 }}>{expanded ? <IndeterminateCheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</span>
);

const CategoryGroup = connect<{ category: IncidentReportFormCategory; isExpanded: boolean }>(
  ({ category, isExpanded, formik: { values, setFieldValue } }) => {
    const selectedIncidentDetailId = getIn(values, 'incidentDetailId');

    useEffect(() => {
      return () => {
        if (!isExpanded && category.details.map((x) => x.incidentDetailId).includes(selectedIncidentDetailId)) {
          console.error('never');
          setFieldValue('incidentDetailId', '');
        }
      };
    });

    return (
      <Box width={1}>
        <CampfireRadioGroup
          required
          name='incidentDetailId'
          label=''
          options={category.details.map((detail) => {
            return {
              value: detail.incidentDetailId,
              label: <Typography>{detail.label}</Typography>,
            };
          })}
        />
      </Box>
    );
  }
);

const CategoryChildGroup = ({
  childGroup,
  selectedCategoryGroupIds = [],
}: {
  childGroup: IncidentReportCategoryChildGroupType;
  selectedCategoryGroupIds: string[];
}) => {
  const [selectedChildGroup, setSelectedChildGroup] = useState<string | undefined>();

  const handleChange = (incidentCategoryGroupId: string) => {
    setSelectedChildGroup(selectedChildGroup === incidentCategoryGroupId ? undefined : incidentCategoryGroupId);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {childGroup.childGroups.map((childGroupItem) => {
          const expanded =
            selectedChildGroup === childGroupItem.incidentCategoryGroupId ||
            selectedCategoryGroupIds.includes(childGroupItem.incidentCategoryGroupId || '');

          return (
            <ExpansionPanel
              key={childGroupItem.incidentCategoryGroupId}
              expanded={expanded}
              onChange={() => handleChange(childGroupItem.incidentCategoryGroupId)}
            >
              <ExpansionPanelSummary
                aria-controls={`${childGroupItem.label}-content`}
                id={`${childGroupItem.label}-header`}
              >
                <ExpandedGroupIcon expanded={expanded} />
                <Typography>{childGroupItem.label}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <CategoryChildGroup childGroup={childGroupItem} selectedCategoryGroupIds={selectedCategoryGroupIds} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </Grid>
      <Grid item xs={12}>
        {childGroup.categories.map((category) => {
          const expanded =
            selectedChildGroup === category.incidentCategoryId ||
            selectedCategoryGroupIds.includes(category.incidentCategoryId || '');

          return (
            <ExpansionPanel
              TransitionProps={{ mountOnEnter: true, unmountOnExit: true }} // this is here to force the useEffect to run on the CategoryGroup component
              key={category.incidentCategoryId}
              expanded={expanded}
              onChange={() => handleChange(category.incidentCategoryId)}
            >
              <ExpansionPanelSummary aria-controls={`${category.label}-content`} id={`${category.label}-header`}>
                <ExpandedGroupIcon expanded={expanded} />
                <Typography>{category.label}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <CategoryGroup category={category} isExpanded={expanded} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </Grid>
    </Grid>
  );
};

const SeverityLabel = ({
  heading,
  description,
  color,
  active,
}: {
  heading: string;
  description: string;
  color: CSSProperties['color'];
  active?: boolean;
}) => {
  const { theme } = useCampfireTheme();
  const textColor = theme.palette.getContrastText(color || 'never');

  return (
    <Box mb={1} mt={1}>
      <TrafficLightCard
        radius={4}
        color={color}
        cardProps={{
          elevation: active ? 16 : 1,
          style: { backgroundColor: active ? color : undefined },
        }}
      >
        <CardContent>
          <Box pl={2}>
            <Typography
              variant={active ? 'h5' : 'button'}
              style={{ color: active ? textColor : theme.palette.text.secondary }}
            >
              {heading}
            </Typography>
            <Typography style={{ color: active ? textColor : theme.palette.text.primary }}>{description}</Typography>
          </Box>
        </CardContent>
      </TrafficLightCard>
    </Box>
  );
};

const SeveritySelect = connect<{ incidentSeverities: IncidentReportIncidentSeverity[] }>(
  ({ formik: { values }, incidentSeverities }) => {
    const name = 'severity';
    const severityValue = getIn(values, name);
    return (
      <CampfireRadioGroup
        required
        id={name}
        name={name}
        label={''}
        options={incidentSeverities
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((incidentSeverity) => {
            return {
              value: incidentSeverity.incidentSeverityId,
              label: (
                <SeverityLabel
                  active={severityValue === incidentSeverity.incidentSeverityId}
                  color={incidentSeverity.color}
                  heading={incidentSeverity.name}
                  description={incidentSeverity.description}
                />
              ),
            };
          })}
      />
    );
  }
);

const fieldStyleProps: FieldProps = {
  name: '',
  fullWidth: true,
  helper: true,
  margin: 'dense',
  variant: 'filled',
};

const addressFieldStyleProps: FieldProps = {
  name: '',
  helper: true,
  fullWidth: true,
  variant: 'filled',
};

const getActivityDisplayName = (activity: IncidentReportActivity) => getDisplayNameWithTime(activity);

type IncidentReportForm = {
  wasIncidentOnActivity: string;
  programId: string;
  activityId: string;
  incidentDetailId: string;
  severity: string;
  date?: Date;
  time: Date | string;
  volunteersInvolvedVolunteerIds: string[];
  othersInvolved: string[];
  description: string;
  furtherActions: string;
  images: File[] | string;
  address: {
    lineOne: string;
    lineTwo: string;
    suburb: string;
    postcode: string;
    state: string;
    country: string;
  };
};

const getIncidentReportFormInitialValues = (
  initValues: {
    volunteersInvolvedVolunteerIds: string[];
    programId: string;
  },
  selectedIncident?: Incident
): IncidentReportForm => {
  const activityId = get(selectedIncident, 'incidentReports[0].incidentLocation.activity.activityId') || '';
  const incidentDetailId = get(selectedIncident, 'incidentReports[0].detail.incidentDetailId') || '';
  const incidentSeverityId = get(selectedIncident, 'incidentReports[0].severity.incidentSeverityId') || '';
  const incidentDateTime = get(selectedIncident, 'incidentReports[0].incidentDateTime') || '';
  const othersInvolved = get(selectedIncident, 'incidentReports[0].othersInvolved') || [];
  const description = get(selectedIncident, 'incidentReports[0].description') || '';
  const furtherAction = get(selectedIncident, 'incidentReports[0].furtherAction') || '';
  const address = get(selectedIncident, 'incidentReports[0].incidentLocation.address');
  const volunteersInvolved = get(selectedIncident, 'incidentReports[0].volunteersInvolved') || [];
  const volunteersInvolvedIds = volunteersInvolved.map((item: VolunteersInvolved) => item && item.volunteerId);

  return {
    activityId: activityId,
    wasIncidentOnActivity: (selectedIncident && activityId) || !selectedIncident ? 'yes' : 'no',
    incidentDetailId: incidentDetailId,
    severity: incidentSeverityId,
    date: incidentDateTime ? unpackToDate(incidentDateTime).toJSDate() : new Date(),
    time: incidentDateTime ? unpackToTime(incidentDateTime).toJSDate() : '',
    othersInvolved: othersInvolved,
    description: description,
    furtherActions: furtherAction,
    images: '',
    address: {
      lineOne: address?.lineOne || '',
      lineTwo: address?.lineTwo || '',
      suburb: address?.suburb || '',
      postcode: address?.postcode || '',
      state: address?.state || '',
      country: address?.country || '',
    },
    ...initValues,
    volunteersInvolvedVolunteerIds: selectedIncident
      ? volunteersInvolvedIds
      : initValues.volunteersInvolvedVolunteerIds,
  };
};

const incidentReportFormValidationSchema = object().shape({
  programId: string().required('Select a program'),
  wasIncidentOnActivity: string().required('Please select an option'),
  activityId: string().when('wasIncidentOnActivity', {
    is: (value) => value === 'yes',
    then: string().required('Please indicate which activity this incident occurred on'),
  }),
  address: object().when('wasIncidentOnActivity', {
    is: (value) => value === 'no',
    then: object().shape({
      lineOne: string()
        .required('Please enter at least one address line')
        .max(256, 'Must be under 256 characters'),
      lineTwo: string().max(256, 'Must be under 256 characters'),
      suburb: string()
        .required('Suburb is required')
        .max(256, 'Must be under 256 characters'),
      postcode: string()
        .required('Postcode is required')
        .max(8, 'Must be under 8 characters'),
      state: string()
        .required('Select your state/region')
        .max(256, 'Must be under 256 characters'),
      country: string()
        .required('Select your country')
        .max(256, 'Must be under 256 characters'),
    }),
  }),
  incidentDetailId: string().required('Select an option'),
  severity: string().required('Please indicate the severity of the incident'),
  date: date()
    .required('Date is required')
    .typeError('Date format is DD/MM/YYYY'),
  time: date()
    .required('Time is required')
    .typeError('Time format is HH:MM AM/PM'),
  volunteersInvolvedVolunteerIds: array().of(string()),
  othersInvolved: array().of(string()),
  description: string().required('Please describe the incident'),
  furtherActions: string(),
  images: array(mixed()),
});

export const IncidentReportScreen = () => {
  const apiUrl = useApiUrl();
  const [step, setStep] = useState<'none' | 'type' | 'volunteer' | 'other'>('none');
  const [showDialog, setShowDialog] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useQueryParam('pid', StringParam);
  const [selectedIncidentId] = useQueryParam('incidentId', StringParam);
  const [selectedActivityId, setSelectedActivityId] = useState<string | undefined>();
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);

  const user = useUser();
  const { setSnackbar } = useSnackbar();
  const volunteerIdentity = user.getVolunteerIdentity();
  const {
    user: { userIdentityService },
  } = user;
  const isAdmin = userIdentityService.privLevel === 'admin';
  const { isProgramManager } = userIdentityService;
  const { data: response, loading: isLoading, error } = useCampfireQuery<GetIncidentReportScreen, undefined>(
    GET_INCIDENT_REPORT_SCREEN
  );

  const [runGetAllPrograms, { data: allProgramsResponse, loading: isAllLoading }] = useCampfireLazyQuery<
    GetIncidentReportAllPrograms,
    undefined
  >(GET_INCIDENT_REPORT_ALL_PROGRAMS);

  const [
    runGetVolunteerPrograms,
    { data: relativeProgramsResponse, loading: isRelativeLoading },
  ] = useCampfireLazyQuery<GetIncidentReportVolunteerPrograms, GetIncidentReportVolunteerProgramsVariables>(
    GET_INCIDENT_REPORT_VOLUNTEER_PROGRAMS
  );

  const { response: responsePostForm, isLoading: isLoadingPostForm, run: runPostForm } = useCampfireFetch<any>({
    defer: true,
  });

  const [runGetSingleIncident, { data: selectedIncidentResponse }] = useCampfireLazyQuery<
    GetIncidentReportSingleIncident,
    GetIncidentReportSingleIncidentVariables
  >(GET_INCIDENT_REPORT_SINGLE_INCIDENT);

  useEffect(() => {
    if (step === 'volunteer') {
      setShowDialog(true);
    }
  }, [step]);

  useEffect(() => {
    if (!showDialog) setStep('none');
  }, [showDialog]);

  const { theme } = useCampfireTheme();

  const hasGlobalManageProgramsRule = useMemo(() => {
    const globalRoles = response?.vm?.identity?.globalRoles ?? [];
    return (
      globalRoles.filter((role) => role.rules.filter((rule) => rule.key === 'manage-programs').length > 0).length > 0
    );
  }, [response]);

  useEffect(() => {
    if (hasGlobalManageProgramsRule) {
      runGetAllPrograms();
      return;
    }
    runGetVolunteerPrograms({
      variables: {
        userId: user.user.userId,
      },
    });
  }, [hasGlobalManageProgramsRule]);

  const programs = useMemo(() => {
    if (hasGlobalManageProgramsRule) {
      return allProgramsResponse?.vm.programs ?? [];
    }
    return relativeProgramsResponse?.vm.profile?.programs ?? [];
  }, [relativeProgramsResponse, allProgramsResponse]);

  useEffect(() => {
    if (programs && !selectedProgramId) {
      const defaultProgram = arrayHead(programs);
      if (defaultProgram) {
        setSelectedProgramId(defaultProgram.programId);
      }
    }
  }, [programs, selectedProgramId]);

  useEffect(() => {
    if (selectedIncidentId) {
      runGetSingleIncident({
        variables: {
          incidentId: selectedIncidentId,
        },
      });
    }
  }, [selectedIncidentId]);

  const selectedProgram = useMemo(() => {
    if (!response) return undefined;
    return programs.find((program) => program.programId === selectedProgramId);
  }, [programs, selectedProgramId]);

  const activities = useMemo(() => {
    if (!programs.length) return [];
    const matchingProgram = programs.find((program) => program.programId === selectedProgramId);
    return matchingProgram ? matchingProgram.activities : [];
  }, [programs, selectedProgramId]);

  const selectedActivity = useMemo(() => {
    return activities.find(
      (activity: { activityId: string | undefined }) => activity.activityId === selectedActivityId
    );
  }, [selectedActivityId]);

  const selectedIncident = useMemo(() => {
    if (!selectedIncidentResponse) return undefined;
    return selectedIncidentResponse.vm.incident;
  }, [selectedIncidentResponse, selectedIncidentId]);

  const selectedCategoryGroupIds: string[] = useMemo(() => {
    if (!response) return [];
    if (selectedIncident) {
      let categoryGroupIds: string[] = [];
      let { incidentCategoryGroup } = selectedIncident.incidentReports[0].detail.incidentCategory;
      const { incidentCategoryId } = selectedIncident.incidentReports[0].detail.incidentCategory;
      while (incidentCategoryGroup.parentGroup) {
        categoryGroupIds = [...categoryGroupIds, incidentCategoryGroup.incidentCategoryGroupId];
        incidentCategoryGroup = incidentCategoryGroup.parentGroup as IncidentCategoryGroup;
      }
      categoryGroupIds = [...categoryGroupIds, incidentCategoryId];
      return categoryGroupIds;
    }
    return [];
  }, [selectedIncident, response]);

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      <Fragment>
        {
          <Box position='relative'>
            <LinearProgressOverlay isLoading={isLoading || isLoadingPostForm || isAllLoading || isRelativeLoading} />
            {responsePostForm && responsePostForm.ok ? (
              <FullPageMessage
                IconProps={{ style: { color: theme.status.green.light } }}
                Icon={CheckIcon}
                title='Incident Recorded'
                subtitle='Thank you for completing this report. Managers have been notified and will follow up if required.'
              >
                <Box marginTop={4}>
                  <Grid container spacing={0} alignContent='center' alignItems='center' justify='center'>
                    <Grid item>
                      <TabletButton
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        {`Submit Another`}
                      </TabletButton>
                      {(isProgramManager || isAdmin) && (
                        <Link style={{ color: 'inherit', textDecoration: 'none' }} to='/management/incident-management'>
                          <TabletButton variant='contained' color='primary'>
                            {`Done`}
                          </TabletButton>
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </FullPageMessage>
            ) : error ? (
              <FullPageMessage title='Unable to load incident report' />
            ) : response && response.vm.incidentCategorySelectionRoot && programs.length && selectedProgramId ? (
              <DesktopWidthHack>
                <Formik
                  validationSchema={incidentReportFormValidationSchema}
                  initialValues={getIncidentReportFormInitialValues(
                    {
                      volunteersInvolvedVolunteerIds: [volunteerIdentity.volunteerId],
                      programId: selectedProgramId ?? '',
                    },
                    selectedIncident
                  )}
                  onSubmit={(values) => {
                    const formData = new FormData();
                    let submissionURL;

                    if (selectedIncidentId) {
                      formData.append('incidentId', selectedIncidentId);
                      formData.append('incidentReportId', selectedIncident?.incidentReports[0]?.incidentReportId || '');
                    }
                    formData.append('programId', values.programId);
                    if (values.wasIncidentOnActivity === 'yes') {
                      formData.append('activityId', values.activityId);
                      submissionURL = `${apiUrl}/vm/incident/report/submit`;
                    }
                    if (values.wasIncidentOnActivity === 'no') {
                      formData.append('address', JSON.stringify(values.address));
                      submissionURL = `${apiUrl}/vm/incident/report/submit-with-address`;
                    }
                    formData.append('incidentDetailId', values.incidentDetailId);
                    formData.append('incidentSeverityId', values.severity);
                    formData.append(
                      'incidentDateTime',
                      encodeDateTime(
                        values.date ? DateTime.fromJSDate(values.date) : DateTime.local(),
                        DateTime.fromJSDate(new Date(values.time))
                      )
                    );
                    formData.append('volunteersInvolved', JSON.stringify(values.volunteersInvolvedVolunteerIds));
                    formData.append('othersInvolved', JSON.stringify(values.othersInvolved));
                    formData.append('description', values.description);
                    formData.append('furtherActions', values.furtherActions);
                    if (values.images[0]) formData.append('resourceOne', values.images[0]);
                    if (values.images[1]) formData.append('resourceTwo', values.images[1]);
                    if (values.images[2]) formData.append('resourceThree', values.images[2]);

                    if (submissionURL === undefined) {
                      return;
                    }
                    runPostForm({
                      url: submissionURL,
                      method: 'post',
                      headers: { 'Content-Type': 'multipart/form-data' },
                      data: formData,
                      timeout: -1,
                    }).then((res) => {
                      if (!res.ok) {
                        setSnackbar({
                          variant: 'error',
                          message: 'Failed to submit incident report',
                          open: true,
                        });
                      }
                    });
                  }}
                >
                  {({ values, touched, errors, setFieldValue }) => {
                    const isMultiplePrograms = programs.length > 1;
                    const noIncidentDetailSelectedError =
                      getIn(touched, 'incidentDetailId') && getIn(errors, 'incidentDetailId')
                        ? getIn(errors, 'incidentDetailId')
                        : null;

                    const addOtherPersonInvolved = (name: string) => {
                      const othersInvolved = getIn(values, 'othersInvolved');
                      if (othersInvolved.includes(name)) return;
                      setFieldValue('othersInvolved', [...othersInvolved, name]);
                    };

                    return (
                      <Form style={{ paddingBottom: 32 }}>
                        <Card elevation={0} style={{ overflow: 'visible' }}>
                          <CardHeader
                            title={
                              <Typography variant='h4' component='h1'>
                                Activity Details
                              </Typography>
                            }
                          />
                          <CardContent>
                            <Grid container spacing={4}>
                              <Grid item xs={12}>
                                <Heading>{isMultiplePrograms && `Which program did this occur in?`}</Heading>
                                <Select
                                  {...fieldStyleProps}
                                  slow
                                  required
                                  native
                                  id='programId'
                                  name='programId'
                                  label='Program'
                                  items={programs}
                                  renderFunction={(program: IncidentReportProgram) => program.name}
                                  valueFunction={(program: IncidentReportProgram) => program.programId}
                                  onChange={(e) => setSelectedProgramId(e.target.value)}
                                />

                                <Heading style={{ marginTop: 16 }}>{`Where did this incident occur?`}</Heading>
                                <CampfireRadioGroup
                                  required
                                  label=''
                                  name='wasIncidentOnActivity'
                                  options={[
                                    { value: 'yes', label: 'On an activity' },
                                    { value: 'no', label: 'Outside of an activity' },
                                  ]}
                                />
                                <Collapse
                                  appear
                                  in={getIn(values, 'wasIncidentOnActivity') === 'no'}
                                  mountOnEnter
                                  unmountOnExit
                                >
                                  <Grid container spacing={1}>
                                    <IncidentReportAddressSection
                                      heading='At what address did the incident occur?'
                                      fieldProps={addressFieldStyleProps}
                                      formikPrefix={'address'}
                                    />
                                  </Grid>
                                </Collapse>

                                <Collapse
                                  appear
                                  in={getIn(values, 'wasIncidentOnActivity') === 'yes'}
                                  mountOnEnter
                                  unmountOnExit
                                >
                                  <Heading style={{ marginTop: 16 }}>{`Which activity?`}</Heading>

                                  <Select
                                    {...fieldStyleProps}
                                    slow
                                    required
                                    native
                                    id='activityId'
                                    name='activityId'
                                    label='Activity'
                                    disabled={!selectedProgramId}
                                    includeEmpty
                                    items={activities.sort((a, b) => a.name.localeCompare(b.name))}
                                    renderFunction={(activity: IncidentReportActivity) =>
                                      getActivityDisplayName(activity)
                                    }
                                    valueFunction={(activity: IncidentReportActivity) => activity.activityId}
                                    onChange={(e) => setSelectedActivityId(e.target.value)}
                                  />
                                </Collapse>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions>
                            <Grid container />
                          </CardActions>
                        </Card>

                        <Card elevation={0}>
                          <CardHeader
                            title={
                              <Typography variant='h4' component='h1'>
                                {`Incident Details`}
                              </Typography>
                            }
                          />
                          <CardContent>
                            <Grid container spacing={4}>
                              <Grid item xs={12}>
                                <Heading>{`Incident Category`}</Heading>
                                {/* For FormikErrorFocus component to work, the element will require an id that is same as formik validation error key. Since MUI Box/Grid doesn't let us set an id, div needs to be used.
                              More info: https://stackoverflow.com/questions/59363774/how-to-set-a-html-element-id-on-a-material-ui-component */}

                                <div id='incidentDetailId'>
                                  <BottomErrorMessage
                                    in={!!noIncidentDetailSelectedError}
                                    title={`Missing categorisation`}
                                    subtitle={noIncidentDetailSelectedError}
                                  />
                                </div>
                                <Box marginTop={2}>
                                  {response.vm.incidentCategorySelectionRoot ? (
                                    <CategoryChildGroup
                                      childGroup={response.vm.incidentCategorySelectionRoot}
                                      selectedCategoryGroupIds={selectedCategoryGroupIds}
                                    />
                                  ) : null}
                                </Box>
                              </Grid>

                              <Grid item xs={12}>
                                <Heading>{`Incident Severity`}</Heading>
                                <SeveritySelect incidentSeverities={response.vm.incidentSeverities} />
                              </Grid>

                              <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                  <Heading>{`Timeline`}</Heading>
                                </Grid>
                                <Grid item xs={6}>
                                  <DatePickerField id='date' name='date' required label='Date' fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                  <TimePicker id='time' name='time' label='Time' required fullWidth />
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Heading>{`People Involved`}</Heading>
                                {(getIn(touched, 'volunteersInvolvedVolunteerIds') &&
                                  getIn(errors, 'volunteersInvolvedVolunteerIds')) ||
                                (getIn(touched, 'othersInvolved') && getIn(errors, 'othersInvolved')) ? (
                                  <Typography color='error'>
                                    {'Please indicate who was involved in this incident'}
                                  </Typography>
                                ) : null}
                                <Box marginTop={2} marginBottom={2}>
                                  <PersonSelector
                                    selectedActivity={selectedActivity}
                                    volunteers={selectedProgram ? selectedProgram.activeVolunteers : []}
                                    othersInvolved={getIn(values, 'othersInvolved')}
                                    teamIds={getIn(values, 'volunteersInvolvedVolunteerIds')}
                                    showDialog={showDialog}
                                    setShowDialog={setShowDialog}
                                    teamMemberField='volunteersInvolvedVolunteerIds'
                                    otherPersonField='othersInvolved'
                                  />

                                  <Box marginTop={2} position='relative'>
                                    <Zoom in={step === 'none'}>
                                      <Box>
                                        <TabletButton
                                          onClick={() => setStep('type')}
                                          variant='outlined'
                                          color='primary'
                                        >
                                          <PersonAddIcon style={{ marginRight: 8 }} />
                                          {`Add Person Involved`}
                                        </TabletButton>
                                      </Box>
                                    </Zoom>

                                    <Zoom in={step === 'type'} mountOnEnter unmountOnExit>
                                      <Box position='absolute' top={0} left={0} width={1}>
                                        <TabletButton onClick={() => setStep('other')}>{`Other`}</TabletButton>

                                        <TabletButton
                                          variant='outlined'
                                          color='primary'
                                          onClick={() => setStep('volunteer')}
                                        >{`Volunteer`}</TabletButton>
                                      </Box>
                                    </Zoom>

                                    <Zoom in={step === 'other'} mountOnEnter unmountOnExit>
                                      <Fragment>
                                        <Formik initialValues={{ name: '' }} onSubmit={() => {}}>
                                          {({ values: addOtherPersonValues }) => {
                                            return (
                                              <Grid
                                                container
                                                spacing={1}
                                                onKeyDownCapture={(e: KeyboardEvent<HTMLDivElement>) => {
                                                  if (e.key === 'Enter') {
                                                    if (
                                                      e.target instanceof HTMLInputElement ||
                                                      e.target instanceof HTMLSelectElement ||
                                                      (e.target instanceof HTMLButtonElement &&
                                                        e.target.type !== 'button')
                                                    ) {
                                                      e.preventDefault();
                                                      addOtherPersonInvolved(addOtherPersonValues.name);
                                                      setStep('none');
                                                    }
                                                  }
                                                }}
                                              >
                                                <Grid item xs={12}>
                                                  <Field
                                                    required
                                                    name='name'
                                                    label={`Name of person involved`}
                                                    variant='outlined'
                                                    autoFocus
                                                    fullWidth
                                                  />
                                                </Grid>
                                                <Grid item container justify='flex-end' xs={12}>
                                                  <TabletButton
                                                    onClick={() => {
                                                      setStep('none');
                                                    }}
                                                  >
                                                    {`Cancel`}
                                                  </TabletButton>
                                                  <TabletButton
                                                    onClick={() => {
                                                      addOtherPersonInvolved(addOtherPersonValues.name);
                                                      setStep('none');
                                                    }}
                                                    variant='contained'
                                                    color='primary'
                                                  >
                                                    {`Add person`}
                                                  </TabletButton>
                                                </Grid>
                                              </Grid>
                                            );
                                          }}
                                        </Formik>
                                      </Fragment>
                                    </Zoom>
                                  </Box>
                                </Box>
                              </Grid>

                              <Grid item xs={12}>
                                <Heading>{`Incident Description`}</Heading>
                                <Typography>
                                  {`Please include any relevant details from the lead up to the incident, the incident itself and the response to the incident.`}
                                </Typography>
                                <Field
                                  id='description'
                                  name={`description`}
                                  required
                                  multiline
                                  rows={6}
                                  variant='outlined'
                                  label='Incident Description'
                                  margin='normal'
                                  fullWidth
                                  helper
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <Heading>{`Further Actions`}</Heading>
                                <Field
                                  name={`furtherActions`}
                                  multiline
                                  rows={6}
                                  variant='outlined'
                                  label='Further Actions (optional)'
                                  placeholder='Leave blank if no further actions are required'
                                  margin='dense'
                                  fullWidth
                                  helper
                                />
                              </Grid>

                              <Grid item container spacing={1} xs={12}>
                                <Grid item xs={12}>
                                  <Heading>{`Photos/Images`}</Heading>
                                  <Typography>{`Please attach up to three (3) images`}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                  <FileField
                                    name='images'
                                    label='Attach Image'
                                    fileTypes={['image/jpg', 'image/jpeg', 'image/png', 'image/pdf'].join(',')}
                                    numberLimit={3}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>

                        <Box marginTop={1} display='flex' flexDirection='column' alignItems='center'>
                          <TabletButton
                            size='large'
                            type='submit'
                            color='primary'
                            variant='contained'
                            disabled={isLoadingPostForm}
                            style={{
                              backgroundColor: theme.color.secondary.main900,
                              color: '#ffffff',
                              borderRadius: '4px',
                              textTransform: 'none',
                              minWidth: 250,
                            }}
                          >
                            Submit
                          </TabletButton>
                          <Typography variant='caption' style={{ marginTop: 4 }}>
                            A manager will be notified of this incident report.
                          </Typography>
                        </Box>
                        <FormikErrorFocus />
                      </Form>
                    );
                  }}
                </Formik>
              </DesktopWidthHack>
            ) : null}
          </Box>
        }
        <IncidentReportTutorialDialog
          open={tutorialDialogOpen}
          onClose={() => {
            setTutorialDialogOpen(false);
          }}
        />
      </Fragment>
    </Page>
  );
};
