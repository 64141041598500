import React from 'react';
import { Box, Typography, Theme, Button, IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import { ArrowRightAlt, Info, CloudUpload, Close } from '@material-ui/icons';
import prettyBytes from 'pretty-bytes';

import { useTypographyStyles } from '../styles/typography';
import { useButtonStyles } from '../styles/button';
import { useInfoStyles } from '../styles/info';
import { useContainerStyles } from '../styles/container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    downloadButton: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: theme.alert.blue.medium,
    },
    button: {
      marginLeft: '0.5rem',
    },
    uploadContainer: {
      width: '50%',
      border: '2px dashed',
      borderColor: theme.color.grey.neutralBrand200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '2rem',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      paddingLeft: '1.25rem',
      [theme.breakpoints.down('xs')]: {
        alignSelf: 'stretch',
        width: 'unset',
      },
    },
    uploadTitle: {
      display: 'flex',
      color: theme.color.grey.neutral300,
      fontSize: '20px',
      fontWeight: 900,
    },
    uploadSubTitle: {
      color: theme.color.grey.neutral200,
      fontSize: '12px',
    },
    uploadWarning: {
      color: theme.color.error[900],
      fontSize: '12px',
    },
  })
);
interface Props {
  onNext: () => void;
  onUpload: (file: any) => void;
  uploadingFile: any;
  onBack: () => void;
}

export function UploadFileStep({ onNext, onUpload, uploadingFile, onBack }: Props) {
  const typographyClasses = useTypographyStyles();
  const buttonClasses = useButtonStyles();
  const infoClasses = useInfoStyles();
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone();

  const onDownload = () => {
    const link = document.createElement('a');
    link.download = `Volunteer Import Example.csv`;
    link.href = 'https://volaby-public-resources.s3.ap-southeast-2.amazonaws.com/volunteer-import.csv';
    link.click();
  };

  return (
    <Box className={containerClasses.root}>
      <Typography className={typographyClasses.title}>Upload your Volunteers</Typography>
      <Typography className={typographyClasses.subTitle} style={{ marginTop: '10px' }}>
        Click on the &apos;Upload File&apos; box below to select your CSV. <br /> Currently we only support uploading a
        maximum of 200 volunteers at a time.{' '}
      </Typography>
      <Box className={infoClasses.alertContainer}>
        <Box className={infoClasses.alertText}>
          <Info />
          <Typography style={{ marginLeft: '14px' }}>You can download an example CSV file from here</Typography>
        </Box>
        <Button className={classes.downloadButton} endIcon={<ArrowRightAlt />} onClick={onDownload}>
          Download
        </Button>
      </Box>
      {!uploadingFile ? (
        <div {...getRootProps()} className={classes.uploadContainer}>
          <input {...getInputProps()} multiple={false} onChange={(event: any) => onUpload(event.target.files[0])} />
          <CloudUpload style={{ alignSelf: 'start', marginRight: '1rem', color: '#646464' }} />
          <Box>
            <Typography className={classes.uploadTitle}>Upload File</Typography>
            <Typography className={classes.uploadSubTitle}>Upload .CSV, .XLS or .TXT file</Typography>
            <Typography className={classes.uploadWarning}>Max 15MB</Typography>
          </Box>
        </div>
      ) : (
        <div className={classes.uploadContainer}>
          <Box flex={1}>
            <Typography className={classes.uploadTitle}>{uploadingFile.name}</Typography>
            <Typography className={classes.uploadSubTitle}>{prettyBytes(uploadingFile.size)}</Typography>
          </Box>
          <IconButton onClick={() => onUpload(null)}>
            <Close />
          </IconButton>
        </div>
      )}
      <Box display='flex' justifyContent='flex-end' marginTop='3rem'>
        <Button classes={buttonClasses} className={classes.button} onClick={onBack} variant='outlined'>
          Back
        </Button>
        <Button
          classes={buttonClasses}
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={onNext}
          disabled={!uploadingFile}
        >
          Upload the file
        </Button>
      </Box>
    </Box>
  );
}
