import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import { goBackBySlashes } from '@campfire/world-wide-web';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { Check as CheckIcon, Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons';
import { capitalize } from 'lodash';
import React, { useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FullPageMessage } from '../../../../../../../common/FullPageMessage';
import { useCampfireQuery } from '../../../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { useIncomingVolunteerActions } from '../../../incoming-volunteer-action.gql';
import { GET_INCOMING_VOLUNTEER_APPLICATION_DETAIL } from '../../../incoming-volunteer-model.gql';
import {
  GetIncomingVolunteerApplicationDetail,
  GetIncomingVolunteerApplicationDetailVariables,
} from '../../../__generated__/GetIncomingVolunteerApplicationDetail';
import { IncomingVolunteerConfirmationDialog } from '../../IncomingVolunteerConfirmationDialog';
import { InfoBlock } from '../../IncomingVolunteerInfoBlock';
import { TaskMenuBarContentWrapper, TaskMenuBarHeader } from '../TaskSideMenuBar';

interface IncomingVolunteerInvitationType extends RouteComponentProps {
  onClose: () => void;
  applicationId: string;
  reload?: () => void;
}

export const IncomingVolunteerApplication = withRouter(
  ({ history, match, onClose, applicationId, reload }: IncomingVolunteerInvitationType) => {
    const [approveDialogOpen, setApproveDialogOpen] = useState(false);
    const { theme } = useCampfireTheme();
    const { data, error, loading } = useCampfireQuery<
      GetIncomingVolunteerApplicationDetail,
      GetIncomingVolunteerApplicationDetailVariables
    >(GET_INCOMING_VOLUNTEER_APPLICATION_DETAIL, {
      options: {
        variables: {
          applicationId: applicationId,
        },
      },
    });
    const application = useMemo(() => {
      return data?.vm.application ?? undefined;
    }, [data]);

    const { runApproveApplication, runApproveApplicationLoading } = useIncomingVolunteerActions();

    return (
      <Box flex={1} style={{ overflowY: 'scroll' }} display='flex' flexDirection='column'>
        <Box position='relative' height={1}>
          <CircularProgressOverlay isLoading={loading} />

          <TaskMenuBarHeader onClose={onClose}>
            {application?.status === 'pending' ? (
              <TabletButton
                variant='outlined'
                color='primary'
                onClick={() => setApproveDialogOpen(true)}
                endIcon={<CheckIcon color='primary' />}
              >
                {runApproveApplicationLoading ? <CircularProgress size={20} /> : 'Approve Application'}
              </TabletButton>
            ) : (
              <Typography variant='caption' color='textSecondary'>
                {'This application does not require approval'}
              </Typography>
            )}
          </TaskMenuBarHeader>
          {error ? (
            <FullPageMessage title='Unable to retrieve application detail. Please try again later.' />
          ) : application ? (
            <TaskMenuBarContentWrapper>
              <Typography variant='h6'>{'Application'}</Typography>
              <Typography variant='body2' color='textSecondary'>
                {`Completed ${formatDistance(unpackToDateTime(application.dateSubmitted))}`}
              </Typography>
              {application.screeningAnswers ? (
                <>
                  {application.screeningAnswers.map((answer) => (
                    <InfoBlock
                      key={answer.screeningQuestion.screeningQuestionId}
                      label={answer.screeningQuestion.label}
                      value={
                        answer.isCorrect ? (
                          <>
                            <DoneIcon style={{ color: theme.status.green.light }} /> {capitalize(answer.answer)}
                          </>
                        ) : (
                          <>
                            <CloseIcon color='error' /> {capitalize(answer.answer)}
                          </>
                        )
                      }
                    />
                  ))}
                </>
              ) : null}
            </TaskMenuBarContentWrapper>
          ) : null}
          {application && (
            <IncomingVolunteerConfirmationDialog
              title='Approve New Applicant?'
              body={`Applicant will receive an email welcoming them to Volaby. They'll be able to sign in and start completing their Volunteer Profile.`}
              open={approveDialogOpen}
              id={applicationId ?? ''}
              handleCloseClick={setApproveDialogOpen}
              handleApproveClick={() =>
                runApproveApplication({
                  applicationId: application.applicationId,
                  status: 'approved',
                }).then(() => {
                  setApproveDialogOpen(false);
                  if (reload) reload();
                  goBackBySlashes(history, match, 0);
                })
              }
            />
          )}
        </Box>
      </Box>
    );
  }
);
