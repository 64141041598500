import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ActivityStatusChip } from '../../../../../common/chips/ActivityStatusChip';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { ReportManagementReportTypeActivity } from '../__generated__/ReportManagementReportTypeActivity';
import { SectionItem } from './ReportTypeDetail';

export const ActivitiesUsingReportType = ({
  activities,
}: {
  activities: Array<ReportManagementReportTypeActivity>;
}) => {
  const { theme } = useCampfireTheme();
  return (
    <SectionItem title='Used in' text={activities.length ? `${activities.length} Activities` : undefined}>
      {activities.length ? (
        <>
          {activities.map((activity: ReportManagementReportTypeActivity) => (
            <Grid
              container
              direction='column'
              style={{ padding: 8, paddingLeft: 16, borderBottom: `1px solid ${theme.palette.grey[200]}` }}
              key={activity.activityId}
            >
              <Grid item container xs={12} spacing={2}>
                <Grid item>
                  <Typography variant='subtitle2' display='inline' style={{ color: theme.color.grey.neutral300 }}>
                    {activity.name}
                  </Typography>
                </Grid>
                {activity.isSuspended || activity.closedActivity ? (
                  <Grid item>
                    <ActivityStatusChip
                      status={activity.closedActivity ? 'CLOSED' : activity.isSuspended ? 'SUSPENDED' : undefined}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Typography variant='caption' display='inline' style={{ color: theme.color.grey.neutral300 }}>{`${
                  activity.sessions.length
                } ${activity.sessions.length === 1 ? 'session' : 'sessions'}`}</Typography>
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <Typography variant='caption' style={{ color: theme.color.grey.neutral300 }}>
          There are no activities currently using this report type
        </Typography>
      )}
    </SectionItem>
  );
};
