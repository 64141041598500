import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { EventAvailable } from '@material-ui/icons';
import { useBetween } from 'use-between';
import { DateTime } from 'luxon';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import {
  AvailabilityCenterDialog,
  EnrolmentDataType,
  ValueProps,
} from '../../../common/availability-center/AvailabilityCenterDialog';
import { useCampfireQuery } from '../../network/useCampfireQuery';
import { GET_ACTIVITYIDS } from '../../../common/models/program-activities.gql';
import { ProgramActivities, ProgramActivitiesVariables } from '../../../common/models/__generated__/ProgramActivities';
import { useUser } from '../../auth/useUser';
import { GET_ACTIVITIES } from './get-activities.gql';
import {
  AvailabilityCenterGetActivities,
  AvailabilityCenterGetActivitiesVariables,
} from './__generated__/AvailabilityCenterGetActivities';
import { GET_VOLUNTEER_ENROLMENTS_DATA } from '../../../common/models/volunteer-enrolments/volunteer-enrolments.gql';
import {
  GetVolunteerEnrolmentsData,
  GetVolunteerEnrolmentsDataVariables,
} from '../../../common/models/volunteer-enrolments/__generated__/GetVolunteerEnrolmentsData';
import { useCampfireFetch } from '../../network/useCampfireFetch';
import { useApiUrl } from '../../config/useApiUrl';
import { useSnackbar } from '../../config/useSnackbar';
import { useCampfireLazyQuery } from '../../network/useCampfireLazyQuery';
import { AlertFullSessionDialog } from '../../../common/alert-full-session-dialog/AlertFullSessionDialog';

const useAvailabilityCentreDialogState = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>();

  return {
    dialogOpen,
    setDialogOpen,
  };
};

export const useSharedAvailabilityCentreDialogState = () => useBetween(useAvailabilityCentreDialogState);

export function AvailabilityCenter() {
  const { dialogOpen, setDialogOpen } = useSharedAvailabilityCentreDialogState();
  const [value, setValue] = React.useState<ValueProps>({});
  const [fullSessionIds, setAllSessionIds] = React.useState<string[]>([]);
  const [activityDatesSubmited, setActivityDatesSubmited] = React.useState<string[]>([]);
  const [activityIdSubmited, setActivityIdSubmited] = React.useState<string>('');
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();

  const [getEnrolmentData, { data: vmVolunteerData, refetch: refetchVolunteerData }] = useCampfireLazyQuery<
    GetVolunteerEnrolmentsData,
    GetVolunteerEnrolmentsDataVariables
  >(GET_VOLUNTEER_ENROLMENTS_DATA, {
    options: {
      variables: {
        volunteerId,
      },
    },
  });

  const { data: vmPrograms } = useCampfireQuery<ProgramActivities, ProgramActivitiesVariables>(GET_ACTIVITYIDS, {
    options: {
      variables: {
        volunteerId,
      },
    },
  });

  const activityIds =
    vmPrograms?.vm.volunteer?.programs.flatMap((program) =>
      program.activities.map((activity) => activity.activityId)
    ) || [];

  const [getActivities, { data: vmActivities }] = useCampfireLazyQuery<
    AvailabilityCenterGetActivities,
    AvailabilityCenterGetActivitiesVariables
  >(GET_ACTIVITIES, {
    options: {
      variables: {
        activityIds,
        from: DateTime.local().toISODate(),
        until: DateTime.local()
          .plus({ years: 1 })
          .toISODate(),
      },
    },
  });

  React.useEffect(() => {
    if (dialogOpen) {
      getEnrolmentData({
        variables: {
          volunteerId,
        },
      });
      getActivities({
        variables: {
          activityIds,
          from: DateTime.local().toISODate(),
          until: DateTime.local()
            .plus({ years: 1 })
            .toISODate(),
        },
      });
    }
  }, [dialogOpen]);

  const onChange = (newValue: ValueProps) => {
    setValue(newValue);
  };

  const campfireMutation = useCampfireFetch({ defer: true });
  const apiUrl = useApiUrl();
  const { setSnackbar } = useSnackbar();

  const handleSubmit = (selectedValue: ValueProps) => {
    const { activityId, sessionIds, dates } = selectedValue;
    if (!activityId || !dates?.length) {
      return;
    }
    const enrolment = vmVolunteerData?.vm.volunteer?.activityEnrolments.find(
      (activityEnrolment) => activityEnrolment.activity.activityId === activityId
    );
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity/enrolment/availability/save`,
        method: 'post',
        data: {
          activityEnrolmentId: enrolment?.activityEnrolmentId,
          activityDates: dates,
          activityRoleIds: [],
          sessionIds: sessionIds ?? [],
        },
      })
      .then((response) => {
        const data = response.data.data as {
          activityAvailabilityId: string[];
          activityId: string;
          fullSessionIds: string[];
          activityDates: string[];
        };

        if (data && data.fullSessionIds?.length > 0 && data.activityId) {
          setAllSessionIds(data.fullSessionIds);
          setActivityIdSubmited(data.activityId);
          setActivityDatesSubmited(data.activityDates);
          setShowAlertDialog(true);
        }

        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Update availability successfully',
        });
        if (refetchVolunteerData) {
          setTimeout(refetchVolunteerData, 0);
        }
      });
  };

  React.useEffect(() => {
    setValue({});
  }, [dialogOpen]);

  const { isMobile } = useCampfireTheme();

  const enrolmentDataByActivityId =
    vmVolunteerData?.vm.volunteer?.activityEnrolments.reduce(
      (acc, activityEnrolment) => ({
        ...acc,
        [activityEnrolment.activity.activityId]: {
          availabilities: activityEnrolment.availabilities
            .filter((availability) => !availability.dateRemoved)
            .map((availability) => availability.activityDate),
          unavailabilities: activityEnrolment.unavailabilities
            .filter((unavailability) => unavailability.dateRemoved)
            .map((unavailability) => unavailability.activityDate),
        },
      }),
      {} as EnrolmentDataType
    ) || {};

  const enrolmentActivities = vmActivities?.vm.activities.filter(
    (activity) => enrolmentDataByActivityId[activity.activityId as string]
  );

  const handleAlertClose = () => {
    setShowAlertDialog(false);
    setAllSessionIds([]);
    setActivityDatesSubmited([]);
    setActivityIdSubmited('');
  };

  return (
    <React.Fragment>
      <Box
        display={'flex'}
        borderRadius='6px'
        alignSelf='center'
        alignItems={'center'}
        border={'1px solid #dbdbdbb3'}
        padding={'6px 10px'}
        onClick={() => setDialogOpen(true)}
        style={{ cursor: 'pointer' }}
      >
        <EventAvailable style={{ color: 'white', fontSize: '21px' }} />
        {isMobile ? '' : <Typography style={{ paddingLeft: '6px', fontSize: '13px' }}>Availability Centre</Typography>}
      </Box>
      {dialogOpen && (
        <AvailabilityCenterDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          value={value}
          onChange={onChange}
          activities={enrolmentActivities || []}
          onSubmit={handleSubmit}
          enrolmentData={enrolmentDataByActivityId}
        />
      )}
      {showAlertDialog && (
        <AlertFullSessionDialog
          open={showAlertDialog}
          onClose={handleAlertClose}
          fullSessionIds={fullSessionIds}
          activityIdSubmited={activityIdSubmited}
          activityDatesSubmited={activityDatesSubmited}
        />
      )}
    </React.Fragment>
  );
}
