import { FugFile } from '@campfire/file-upload-gallery';
import { useEndpointFetch } from '../../../global/network/useEndpointFetch';

export interface SaveCategoryResource {
  supportResourceId: string;
  featured: boolean;
  title: string;
  textContent: string | null;
}
export interface SaveCategoryValues {
  supportCategoryId?: string;
  title: string;
  supportResources?: {
    supportResourceId: string;
    featured: boolean;
  }[];
}

export const useSaveCategoryFetch = () => {
  return useEndpointFetch<
    SaveCategoryValues,
    {
      supportCategoryId: string;
    }
  >('/vm/support/category/save');
};

export const useDeleteCategoryFetch = () => {
  return useEndpointFetch<{ supportCategoryId: string }, {}>('/vm/support/category/delete');
};

export interface SaveSupportResourceValues {
  supportResourceId?: string;
  featured: boolean;
  title: string;
  textContent?: string;
  supportCategoryId: string;
  addedAttachments: FugFile[];
  attachmentCreationTokens: string[];
  removedAttachmentIds: string[];
  programIds: string[];
  activityIds: string[];
  roleIds: string[];
  tagIds: string[];
  permissionLevel?: string
}

export const useSaveSupportResourceFetch = () => {
  return useEndpointFetch<SaveSupportResourceValues, {}>('/vm/support/resource/save', { formData: true });
};

export const useDeleteSupportResourceFetch = () => {
  return useEndpointFetch<{ supportResourceId: string }, {}>('/vm/support/resource/delete');
};
