import { gql } from '@apollo/client';
import { FIELD_FRAGMENT } from '../../activity-timeline/activity-timeline-model.gql';

export const EDIT_FIELD_DIALOG_GET_EXISTING_FIELDS = gql`
  query EditFieldDialogGetExistingFields {
    vm {
      fields {
        dateCreated
        ...Field
      }
    }
  }
  ${FIELD_FRAGMENT}
`;
