import React, { createContext } from 'react';
import { getApiUrlFromWindowLocation } from './get-api-url';

export interface ApiContextInterface {
  apiUrl: string;
}

export interface ApiProviderProps {
  value?: ApiContextInterface;
  children: React.ReactNode;
}

export const ApiContext = createContext<ApiContextInterface | undefined>(undefined);

export const ApiProvider = (props: ApiProviderProps) => {
  const value: ApiContextInterface = {
    apiUrl: getApiUrlFromWindowLocation(),
  };
  return <ApiContext.Provider value={value} {...props} />;
};
