import { gql } from '@apollo/client';
import { TASK_FORM_ITEM_FRAGMENT } from '../../../common/form/task-form/model/task-items.gql';

export const GET_ALL_APPLICANT_TASKS = gql`
  query GetAllApplicantTasks($userId: String!) {
    vm {
      application(userId: $userId) {
        relevantTasks {
          ...SUYPOverviewTasksListTask
        }
      }
      profile(userId: $userId) {
        profileId
        preferredName
        completedTasks {
          completedTaskId
          task {
            taskId
          }
          dateLastUpdated
          dateRevoked
          status
        }
      }
    }
  }

  fragment SUYPOverviewTasksListTask on VOLUNTEER_ApplicantTaskType {
    taskId
    title
    description
    order
    cake {
      description
      cakeType
    }
    automateApproval
    dateRemoved
  }
`;

export const GET_APPLICANT_VOLUNTEER_PROFILE = gql`
  query GetApplicantVolunteerProfile($userId: String!) {
    vm {
      programs {
        programId
        name
        dateSuspended
      }
      profileTask {
        taskId
        title
        description
        order
        taskItems {
          ...TaskFormItem
        }
      }
      profile(userId: $userId) {
        profileId
        firstName
        lastName
        preferredName
        email
        contactNumber
        dateOfBirth
        dateLastUpdated
        comments
        avatarUrl
        completedTasks {
          completedTaskId
        }
        isCompleted
        isProfileTaskApproved
        percentageComplete
      }
    }
  }

  ${TASK_FORM_ITEM_FRAGMENT}
`;
