import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { unpackToDateTime } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { Box, Typography } from '@material-ui/core';
import { convertFromRaw, EditorState } from 'draft-js';
import React, { useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { GET_MY_ELEMENTS_SIDEBAR_RECENT_NEWS_DATA } from './side-bar-recent-news-model.gql';
import { SideBarRecentNewsSkeleton } from './SideBarRecentNewsSkeleton';
import {
  GetMyElementsSidebarRecentNewsData,
  GetMyElementsSidebarRecentNewsData_vm_mostRecentBulletin as Bulletin,
} from './__generated__/GetMyElementsSidebarRecentNewsData';
import { SideBarItem } from '../../../../../program-manager/activity-management/activities-list/SideBarActivityDetail';

export const SideBarRecentNewsThumbnail = ({
  news,
  onSingleNewsClick,
}: {
  news: Bulletin;
  onSingleNewsClick: () => void;
}) => {
  const { bulletinId, title, content, lastUpdatedBy, createdBy, dateCreated, dateUpdated } = news;
  const { theme } = useCampfireTheme();
  const [, setSelectedNewsId] = useQueryParam('newsId', StringParam);

  const editorState = useMemo(() => EditorState.createWithContent(convertFromRaw(JSON.parse(content))), [content]);
  const authorName = lastUpdatedBy
    ? `${lastUpdatedBy.profile.preferredName} ${lastUpdatedBy.profile.lastName}`
    : createdBy && `${createdBy.profile.preferredName} ${createdBy.profile.lastName}`;
  const displayDate = dateUpdated ?? dateCreated;
  return (
    <Box
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        onSingleNewsClick();
        setSelectedNewsId(bulletinId);
      }}
    >
      <Typography color='textSecondary' variant='body2' style={{ fontSize: '12px', marginBottom: '10px' }}>
        {authorName} - {unpackToDateTime(displayDate).toFormat('dd.MM.yy')}
      </Typography>
      <Typography
        variant='h5'
        style={{
          fontSize: '16px',
          fontWeight: 900,
          lineHeight: '19px',
          letterSpacing: '-0.02em',
          color: theme.color.grey.neutralBrand800,
        }}
      >
        {title}
      </Typography>
      <Box height={38} my={1} overflow='hidden'>
        <CampfireRichEditor editorState={editorState} readOnly />
      </Box>
    </Box>
  );
};

export const SideBarRecentNews = ({ onSingleNewsClick }: { onSingleNewsClick: () => void }) => {
  const { data, loading } = useCampfireQuery<GetMyElementsSidebarRecentNewsData, undefined>(
    GET_MY_ELEMENTS_SIDEBAR_RECENT_NEWS_DATA
  );

  const recentNews = useMemo(() => {
    return data?.vm.mostRecentBulletin;
  }, [data]);

  if (loading) return <SideBarRecentNewsSkeleton />;

  if (!recentNews)
    return (
      <Box mt='18px' mb='60px'>
        <Typography>There are currently no news to view.</Typography>
      </Box>
    );

  return (
    <Box mt='10px'>
      <SideBarRecentNewsThumbnail news={recentNews} onSingleNewsClick={onSingleNewsClick} />
    </Box>
  );
};

export const SideBarRecentNewsSection = ({
  onViewAllClick,
  onSingleNewsClick,
}: {
  onViewAllClick: () => void;
  onSingleNewsClick: () => void;
}) => {
  return (
    <SideBarItem
      headerTitle='Recent News'
      headerActionItems={
        <HoverText color='primary' variant='body2' style={{ fontSize: '12px' }} onClick={onViewAllClick}>
          View All
        </HoverText>
      }
    >
      <SideBarRecentNews onSingleNewsClick={onSingleNewsClick} />
    </SideBarItem>
  );
};
