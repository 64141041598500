import React from 'react';
import { AdminConsoleAdminsPanel } from '../admin-console-content-pages/admin-console-admins/AdminConsoleAdminsPanel';
import { AdminConsoleApplicantScreeningPannel } from '../admin-console-content-pages/admin-console-applicant-screening/AdminConsoleApplicantScreeningPannel';
import { AdminConsoleBulletinSettings } from '../admin-console-content-pages/admin-console-bulletin-settings/AdminConsoleBulletinSettings';
import { AdminConsoleIncidentReportingPanel } from '../admin-console-content-pages/admin-console-incident-reporting/AdminConsoleIncidentReportingPanel';
import { AdminConsoleOperationsPanel } from '../admin-console-content-pages/admin-console-operations/AdminConsoleOperationsPanel';
import { AdminConsoleFallbackPage } from '../admin-console-content-pages/AdminConsoleFallbackPage';
import { AdminConsoleRoles } from '../admin-console-content-pages/admin-console-roles/AdminConsoleRoles';
import { AdminConsoleTags } from '../admin-console-content-pages/admin-console-tags/AdminConsoleTags';
import { AdminConsoleCommunicationPreferences } from '../Communications/Preferences/AdminConsoleCommunicationPreferences';
import { AdminConsoleCommunicationTemplate } from '../Communications/Templates/AdminConsoleCommunicationTemplate';
import { AdminConsoleEmbeddableWidgetsPanel } from '../admin-console-content-pages/Integrations/EmbeddableWidgets/AdminConsoleEmbeddableWidgetsPanel';
import { AdminConsoleAppsScreen } from '../admin-console-content-pages/Integrations/Apps/AdminConsoleAppsScreen';
import { useOrgInfo } from '../../../../global/auth/use-org-info/use-org-info';
import { AdminConsoleActivityReportingPanel } from '../admin-console-content-pages/admin-console-activity-reporting/AdminConsoleActivityReportingPanel';

interface Route {
  label: string;
  route: string;
  component: React.ElementType;
  enabled?: boolean;
}
interface RouteGroups {
  heading: string;
  routeOptions: Route[];
}

interface RoutesProps {
  heading: string;
  routeGroups: RouteGroups[];
}

export const getAdminConsoleRoutesMap = (route: string,): RoutesProps => ({
  heading: 'Configurable Settings',
  routeGroups: [
    {
      heading: 'General',
      routeOptions: [
        {
          label: 'Admins',
          route: `${route}/admins`,
          component: AdminConsoleAdminsPanel,
        },
        {
          label: 'Onboarding',
          route: `${route}/onboarding`,
          component: AdminConsoleApplicantScreeningPannel,
        },
        {
          label: 'Bulletins',
          route: `${route}/bulletins`,
          component: AdminConsoleBulletinSettings,
        },
      ],
    },
    {
      heading: 'Activities & Rosters',
      routeOptions: [
        {
          label: 'Operations',
          route: `${route}/operations`,
          component: AdminConsoleOperationsPanel,
        },
        {
          label: 'Activity Reporting',
          route: `${route}/reporting`,
          component: AdminConsoleActivityReportingPanel,
        },
        {
          label: 'Activity Tags',
          route: `${route}/tags`,
          component: AdminConsoleTags,
        },
        {
          label: 'Incident Reporting',
          route: `${route}/incident-reporting`,
          component: AdminConsoleIncidentReportingPanel,
        },
        {
          label: 'Roles',
          route: `${route}/roles`,
          component: AdminConsoleRoles,
        },
      ],
    },
    {
      heading: 'Communications',
      routeOptions: [
        {
          label: 'Preferences',
          route: `${route}/preferences`,
          component: AdminConsoleCommunicationPreferences,
        },
        {
          label: 'Templates',
          route: `${route}/templates`,
          component: AdminConsoleCommunicationTemplate,
        },
      ],
    },
    {
      heading: 'Integrations',
      routeOptions: [
        {
          label: 'Apps',
          route: `${route}/apps`,
          component: AdminConsoleAppsScreen,
        },
        {
          label: 'Embeddable Widgets',
          route: `${route}/embeddable-widgets`,
          component: AdminConsoleEmbeddableWidgetsPanel,
        },
        {
          label: '',
          route: `${route}`,
          component: AdminConsoleFallbackPage,
        },
      ],
    },
  ],
});

export const useAdminConsoleRoutesMap = (route: string): RoutesProps => {
  const orgInfo = useOrgInfo();

  return {
    heading: 'Configurable Settings',
    routeGroups: [
      {
        heading: 'General',
        routeOptions: [
          {
            label: 'Admins',
            route: `${route}/admins`,
            component: AdminConsoleAdminsPanel,
          },
          {
            label: 'Onboarding',
            route: `${route}/onboarding`,
            component: AdminConsoleApplicantScreeningPannel,
          },
          {
            label: 'Bulletins',
            route: `${route}/bulletins`,
            component: AdminConsoleBulletinSettings,
          },
        ],
      },
      {
        heading: 'Activities & Rosters',
        routeOptions: [
          {
            label: 'Operations',
            route: `${route}/operations`,
            component: AdminConsoleOperationsPanel,
          },
          {
            label: 'Activity Reporting',
            route: `${route}/reporting`,
            component: AdminConsoleActivityReportingPanel,
          },
          {
            label: 'Activity Tags',
            route: `${route}/tags`,
            component: AdminConsoleTags,
          },
          {
            label: 'Incident Reporting',
            route: `${route}/incident-reporting`,
            component: AdminConsoleIncidentReportingPanel,
          },
          {
            label: 'Roles',
            route: `${route}/roles`,
            component: AdminConsoleRoles,
          },
        ],
      },
      {
        heading: 'Communications',
        routeOptions: [
          {
            label: 'Preferences',
            route: `${route}/preferences`,
            component: AdminConsoleCommunicationPreferences,
          },
          {
            label: 'Templates',
            route: `${route}/templates`,
            component: AdminConsoleCommunicationTemplate,
          },
        ],
      },
      {
        heading: 'Integrations',
        routeOptions: [
          {
            label: 'Apps',
            route: `${route}/apps`,
            component: AdminConsoleAppsScreen,
            enabled: orgInfo?.packageConfig?.integrations || false
          },
          {
            label: 'Embeddable Widgets',
            route: `${route}/embeddable-widgets`,
            component: AdminConsoleEmbeddableWidgetsPanel,
          },
          {
            label: '',
            route: `${route}`,
            component: AdminConsoleFallbackPage,
          },
        ],
      },
    ],
  }
};

export const adminConsoleFallbackPath = 'admins';
