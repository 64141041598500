import React from 'react';
import { Box } from '@material-ui/core';
import { ActivityHeader } from './ActivityHeader';

interface Props {
  children: React.ReactNode;
}

export function ActivityWaitlistings({ children }: Props) {
  return (
    <Box>
      <ActivityHeader title='Waitlisted Applications' />
      {children}
    </Box>
  );
}
