import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import React from 'react';
import { useUser } from '../../../../global/auth/useUser';
import { ActivitiesExploreActivity } from '../__generated__/ActivitiesExploreActivity';
import { ActivityEnrolmentPrompt } from './ActivityEnrolmentPrompt';
import { useActivityApplicationFetch } from './use-activity-application-fetch';

type ActivitiesExploreActivityWaitlistApplicationProps = {
  activity: ActivitiesExploreActivity;
  programsLoading: boolean;
  refetch?: () => void;
};

const ExpressionOfInterestPrompt = ({
  onClick,
  numUsersOnWaitlist,
}: {
  onClick: () => void;
  numUsersOnWaitlist: number;
}) => (
  <ActivityEnrolmentPrompt
    title={
      numUsersOnWaitlist === 0
        ? 'Join the waitlist'
        : `Join ${numUsersOnWaitlist} ${numUsersOnWaitlist === 1 ? 'other' : 'others'} on the waitlist`
    }
    description={`You may have an opportunity to participate if a free spot becomes available.`}
    action={
      <TabletButton variant='contained' size='medium' color='primary' onClick={onClick}>
        {'Join waitlist'}
      </TabletButton>
    }
    progress={0}
  />
);
const OnWaitlistPrompt = ({ onClick }: { onClick: () => void }) => (
  <ActivityEnrolmentPrompt
    title={`You're on the waitlist`}
    description={"We'll email you as soon as there are any updates!"}
    action={
      <TabletButton variant='text' size='medium' color='default' onClick={onClick}>
        {'Withdraw from waitlist'}
      </TabletButton>
    }
    progress={25}
  />
);
const ActivitiesExploreActivityWaitlistApplication = ({
  activity,
  programsLoading,
  refetch,
}: ActivitiesExploreActivityWaitlistApplicationProps) => {
  const { activityId, activeActivityWaitlistings } = activity;
  const { getVolunteerIdentity } = useUser();
  const {
    runApplyForWaitlist,
    runRemoveFromWaitlist,
    runApplyForWaitlistLoading,
    runRemoveFromWaitlistLoading,
  } = useActivityApplicationFetch(refetch);
  const volunteerIdentity = getVolunteerIdentity();
  const volunteerWaitlisting = activeActivityWaitlistings.find(
    (waitlist) => waitlist.volunteer.volunteerId === volunteerIdentity.volunteerId
  );
  const numUsersOnWaitlist = activeActivityWaitlistings.length;

  return (
    <>
      <CircularProgressOverlay
        isLoading={runApplyForWaitlistLoading || runRemoveFromWaitlistLoading || programsLoading}
      />

      {volunteerWaitlisting ? (
        <OnWaitlistPrompt
          onClick={() => runRemoveFromWaitlist({ activityWaitlistingId: volunteerWaitlisting.activityWaitlistingId })}
        />
      ) : (
        <ExpressionOfInterestPrompt
          numUsersOnWaitlist={numUsersOnWaitlist}
          onClick={() => runApplyForWaitlist({ activityId: activityId })}
        />
      )}
    </>
  );
};

export { ActivitiesExploreActivityWaitlistApplication };
