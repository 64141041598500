import { Box, Button, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React from 'react';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

export const ShowMoreLessButton = ({
  showMore,
  setShowMore,
}: {
  showMore: boolean;
  setShowMore: (x: boolean) => void;
}) => {
  const { theme } = useCampfireTheme();
  return (
    <Button
      variant='text'
      style={{
        background: theme.color.white.neutral50,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '8px 16px',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px',
        display: 'block',
      }}
      onClick={() => setShowMore(!showMore)}
    >
      {showMore ? (
        <Box display='flex'>
          <Typography
            style={{
              color: theme.color.black,
              fontSize: '14px',
              marginRight: '4px',
              textTransform: 'none',
            }}
          >
            Show Less
          </Typography>
          <KeyboardArrowUp style={{ fontSize: '20px', color: theme.color.black }} />
        </Box>
      ) : (
        <Box display='flex'>
          <Typography
            style={{
              color: theme.color.black,
              fontSize: '14px',
              marginRight: '4px',
              textTransform: 'none',
            }}
          >
            Show More
          </Typography>
          <KeyboardArrowDown style={{ fontSize: '20px', color: theme.color.black }} />
        </Box>
      )}
    </Button>
  );
};

export const ShowMoreButton = ({ onClick }: { onClick: () => void }) => {
  const { theme } = useCampfireTheme();
  return (
    <Button
      variant='text'
      style={{
        background: theme.color.white.neutral50,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '8px 16px',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px',
        display: 'block',
      }}
      onClick={onClick}
    >
      <Box display='flex'>
        <Typography
          style={{
            color: theme.color.black,
            fontSize: '14px',
            marginRight: '4px',
            textTransform: 'none',
          }}
        >
          Show More
        </Typography>
        <KeyboardArrowDown style={{ fontSize: '20px', color: theme.color.black }} />
      </Box>
    </Button>
  );
};
