import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { unpackToDate } from '@campfire/hot-date';
import {
  Box,
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { convertFromRaw, EditorState } from 'draft-js';
import { uniqBy } from 'lodash';
import React, { memo, useEffect, useMemo } from 'react';
import { getDisplayTimeSchedule } from '../../../../../common/functions/activity-display-helpers';
import { useUser } from '../../../../../global/auth/useUser';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { VolunteerDialogHoverBlock } from '../../../../program-manager/volunteer-common-profile/VolunteerDialogHoverBlock';
import { ProfileHoverBlock } from '../../hover-text/ProfileHoverBlock';
import { GET_MY_ELEMENTS_SINGLE_UPCOMING_ROSTER } from './my-elements-upcoming-rosters-model.gql';
import {
  GetMyElementsSingleUpcomingRoster,
  GetMyElementsSingleUpcomingRosterVariables,
} from './__generated__/GetMyElementsSingleUpcomingRoster';
import { MyElementsSingleUpcomingRosterProgramManagers } from './__generated__/MyElementsSingleUpcomingRosterProgramManagers';
import { MyElementsSingleUpcomingRosterPublishedRostering } from './__generated__/MyElementsSingleUpcomingRosterPublishedRostering';
import { MyElementsUpcomingRoster } from './__generated__/MyElementsUpcomingRoster';
import { MyElementsUpcomingRostersActivity } from './__generated__/MyElementsUpcomingRostersActivity';

interface MyElementsViewUpcomingRosterDialogProps {
  selectedUpcomingRoster: MyElementsUpcomingRoster | undefined;
  selectedDateStamp: string | undefined;
  setSelectedUpcomingRoster: (roster: MyElementsUpcomingRoster | undefined) => void;
  setSelectedDateStamp: (date: string | undefined) => void;
}

const MyElementsViewUpcomingRosterDialog = (props: MyElementsViewUpcomingRosterDialogProps) => {
  const { selectedUpcomingRoster, selectedDateStamp, setSelectedUpcomingRoster, setSelectedDateStamp } = props;
  const onCloseDialog = () => {
    setSelectedUpcomingRoster(undefined);
    setSelectedDateStamp(undefined);
  };

  const {
    user: { userIdentityService },
  } = useUser();
  const isAdmin = userIdentityService.privLevel === 'admin';
  const isProgramManager = userIdentityService.isManagerOfProgram(
    selectedUpcomingRoster?.activity.program.programId ?? ''
  );
  const isPrivilegedToViewProfiles = isProgramManager || isAdmin;

  const [fetchActivityRoster, { data: singleRosterQuery, loading: isLoading }] = useCampfireLazyQuery<
    GetMyElementsSingleUpcomingRoster,
    GetMyElementsSingleUpcomingRosterVariables
  >(GET_MY_ELEMENTS_SINGLE_UPCOMING_ROSTER);

  const activity = useMemo(() => {
    if (!selectedUpcomingRoster) return undefined;
    return selectedUpcomingRoster.activity;
  }, [selectedUpcomingRoster]);

  useEffect(() => {
    if (!activity || !selectedDateStamp) return;

    if (!selectedDateStamp.substr) {
      console.log('broken substr ', selectedDateStamp);
    }

    fetchActivityRoster({
      variables: {
        activityId: activity.activityId,
        activityDate: selectedDateStamp.substr(0, 10),
      },
    });
  }, [activity, selectedDateStamp]);

  const rosterings = useMemo(() => {
    if (!singleRosterQuery?.vm?.activity?.publishedRoster?.rosterings?.length) return [];
    return singleRosterQuery.vm.activity.publishedRoster.rosterings.sort((a, b) =>
      a.volunteer.profile.preferredName.localeCompare(b.volunteer.profile.preferredName)
    );
  }, [singleRosterQuery]);

  const programManagers = useMemo(() => {
    if (!singleRosterQuery?.vm?.activity?.program?.programManagers?.length) return [];
    return uniqBy(singleRosterQuery?.vm?.activity?.program?.programManagers, 'manager.userId');
  }, [singleRosterQuery]);

  if (!selectedDateStamp || !selectedUpcomingRoster) return null;

  const dateSubtitle = unpackToDate(selectedDateStamp).toLocaleString({
    weekday: 'long',
    day: 'numeric',
    month: 'short',
  });

  return (
    <Dialog open={!!selectedUpcomingRoster && !!selectedDateStamp} onClose={onCloseDialog}>
      <DialogTitle>
        <Card elevation={0}>
          {isLoading || !activity ? (
            <CardHeader
              style={{ padding: 0 }}
              title={<Skeleton variant='text' width={200} height={20} />}
              subheader={<Skeleton variant='text' width={240} height={15} />}
            />
          ) : (
            <CardHeader
              style={{ padding: 0 }}
              title={activity.name}
              subheader={`${dateSubtitle}, (${getDisplayTimeSchedule(activity.startTime, activity.endTime)})`}
              action={
                <IconButton onClick={() => setSelectedDateStamp(undefined)}>
                  <Close />
                </IconButton>
              }
            />
          )}
        </Card>
      </DialogTitle>
      <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
        {!isLoading ? (
          <ActivitySessionRosterings
            isPrivilegedToViewProfiles={isPrivilegedToViewProfiles}
            activity={activity}
            rosterings={rosterings}
            programManagers={programManagers}
          />
        ) : (
          <ListItemSkeleton />
        )}
      </DialogContent>
    </Dialog>
  );
};

interface ActivitySessionRosteringsProps {
  activity?: MyElementsUpcomingRostersActivity;
  rosterings: MyElementsSingleUpcomingRosterPublishedRostering[];
  programManagers: MyElementsSingleUpcomingRosterProgramManagers[];
  isPrivilegedToViewProfiles: boolean;
}

const ActivitySessionRosterings = memo(
  ({ activity, rosterings, programManagers, isPrivilegedToViewProfiles }: ActivitySessionRosteringsProps) => (
    <>
      {activity?.sessions.map((session, sessionIndex) => {
        const rosteringsForSession = rosterings.filter(
          (rostering) =>
            !!rostering.sessionRosterings.find(
              (sessionRostering) => sessionRostering.session.sessionId === session.sessionId
            )
        );

        const sectionHeaderTitle = session.name;

        const sessionTime = getDisplayTimeSchedule(session.startTime, session.endTime);
        const { getVolunteerIdentity } = useUser();
        const volunteerIdentity = getVolunteerIdentity();
        const isVolunteerRosteredInSession = rosteringsForSession.find(
          (ros) => ros.volunteer.volunteerId === volunteerIdentity.volunteerId
        );
        if (!isVolunteerRosteredInSession) return null;

        // programManagers here are being retrieved from the activity, so they will only
        // appear as a gv/al on rosters in other programs in which they are not a manager
        const programManagerUserIds = programManagers.map((manager) => manager.manager.profile.userId);

        const programManagerRosterings = rosteringsForSession.filter((rostering) => {
          return rostering.volunteer.profile.userId
            ? programManagerUserIds.includes(rostering.volunteer.profile.userId)
            : false;
        });

        const activityLeaderRosterings = rosteringsForSession.filter((rostering) => {
          return rostering.volunteer.profile.userId
            ? !programManagerUserIds.includes(rostering.volunteer.profile.userId) &&
                rostering.volunteer?.activityEnrolment &&
                rostering.volunteer?.activityEnrolment.position !== 'gv'
            : false;
        });
        const generalVolunteerRosterings = rosteringsForSession.filter((rostering) => {
          if (!rostering.volunteer?.activityEnrolment) return true;
          return rostering.volunteer.profile.userId
            ? !programManagerUserIds.includes(rostering.volunteer.profile.userId) &&
                rostering.volunteer?.activityEnrolment?.position === 'gv'
            : false;
        });

        const editorState = useMemo(
          () =>
            session?.description
              ? EditorState.createWithContent(convertFromRaw(JSON.parse(session.description)))
              : null,
          [session?.description]
        );

        return (
          <Box
            key={session.sessionId}
            bgcolor={sessionIndex % 2 === 0 ? '#f6f8f9' : 'none'}
            paddingTop={1}
            paddingBottom={2}
            paddingX={4}
            borderBottom='#e9e9e9 solid 1px'
          >
            <Typography display='block' variant='overline' color='textSecondary' style={{ fontWeight: 'bold' }}>
              {`Session ${sessionIndex + 1} (${session.name})`}
            </Typography>
            <span>
              <Typography display='inline' variant='body2' style={{ fontWeight: 'bold', marginRight: 8 }}>
                {sectionHeaderTitle}
              </Typography>
              <Typography gutterBottom display='inline' variant='body2'>
                {sessionTime}
              </Typography>
            </span>
            <Typography variant='body2' style={{ fontWeight: 'bold' }} gutterBottom>
              {session.activityLocation?.placesAddress.description}
            </Typography>
            {editorState ? <CampfireRichEditor editorState={editorState} readOnly /> : null}
            {rosteringsForSession.length ? (
              <>
                <Typography style={{ marginTop: 16 }} variant='h6'>
                  Team Information
                </Typography>
                {programManagerRosterings.length > 0 && (
                  <>
                    <Typography variant='caption' display='block'>
                      Program Managers
                    </Typography>
                    <List dense>
                      {programManagerRosterings.map((rostering) => {
                        return (
                          <MyElementsViewUpcomingRosterDialogListItem
                            isPrivilegedToViewProfiles={isPrivilegedToViewProfiles}
                            rostering={rostering}
                            isGv={false}
                            key={rostering.publishedRosteringId}
                          />
                        );
                      })}
                    </List>
                  </>
                )}
                {activityLeaderRosterings.length > 0 && (
                  <>
                    <Typography variant='caption' display='block'>
                      Activity Leaders
                    </Typography>
                    <List dense>
                      {activityLeaderRosterings.map((rostering) => {
                        return (
                          <MyElementsViewUpcomingRosterDialogListItem
                            isPrivilegedToViewProfiles={isPrivilegedToViewProfiles}
                            rostering={rostering}
                            isGv={false}
                            key={rostering.publishedRosteringId}
                          />
                        );
                      })}
                    </List>
                  </>
                )}
                {generalVolunteerRosterings.length > 0 && (
                  <>
                    <Typography variant='caption' display='block'>
                      Volunteers
                    </Typography>
                    <List dense>
                      {generalVolunteerRosterings.map((rostering) => {
                        return (
                          <MyElementsViewUpcomingRosterDialogListItem
                            isPrivilegedToViewProfiles={isPrivilegedToViewProfiles}
                            rostering={rostering}
                            isGv
                            key={rostering.publishedRosteringId}
                          />
                        );
                      })}
                    </List>
                  </>
                )}
              </>
            ) : (
              <Typography variant='body2' style={{ marginBottom: 15 }}>
                {'No volunteers for this session'}
              </Typography>
            )}
          </Box>
        );
      })}
    </>
  )
);

interface MyElementsViewUpcomingRosterDialogListItemProps {
  rostering: MyElementsSingleUpcomingRosterPublishedRostering;
  isGv: boolean;
  isPrivilegedToViewProfiles: boolean;
}

const MyElementsViewUpcomingRosterDialogListItem = memo((props: MyElementsViewUpcomingRosterDialogListItemProps) => {
  const { rostering, isGv, isPrivilegedToViewProfiles } = props;
  const {
    volunteer: {
      profile: { userId, profileId, preferredName, lastName, avatarUrl, email, contactNumber },
    },
    activityRoles,
    publishedRosteringId,
  } = rostering;

  const activityRolesText =
    activityRoles.length > 0 ? `\u00a0\u00a0${activityRoles.map((activityRole) => activityRole.name).join(', ')}` : '';
  const displayLastName = `${lastName}${activityRolesText}`;

  if (isPrivilegedToViewProfiles)
    return (
      <ListItem style={{ paddingLeft: 0 }} key={publishedRosteringId}>
        <VolunteerDialogHoverBlock
          avatarUrl={avatarUrl}
          profileId={profileId}
          userId={userId}
          preferredName={preferredName}
          lastName={displayLastName}
          hoverTextProps={{
            hoverColor: 'primary',
            disableUnderline: true,
          }}
          secondaryText={
            <>
              {isGv === false && (
                <Box component='span' display='block'>
                  {email === null ? '' : email}
                  {'\u00a0'}
                  {'\u00a0'}
                  {contactNumber === null ? '' : contactNumber}
                </Box>
              )}
            </>
          }
        />
      </ListItem>
    );

  return (
    <ListItem style={{ paddingLeft: 0 }} key={publishedRosteringId}>
      <ProfileHoverBlock
        avatarUrl={avatarUrl}
        profileId={profileId}
        userId={userId}
        preferredName={preferredName}
        lastName={displayLastName}
        hoverTextProps={{
          hoverColor: 'primary',
          disableUnderline: true,
        }}
        secondaryText={
          <>
            {isGv === false && (
              <Box component='span' display='block'>
                {email === null ? '' : email}
                {'\u00a0'}
                {'\u00a0'}
                {contactNumber === null ? '' : contactNumber}
              </Box>
            )}
          </>
        }
      />
    </ListItem>
  );
});

const ListItemSkeleton = () => (
  <List style={{ paddingTop: 0, paddingLeft: 16, paddingRight: 16 }}>
    <ListItem style={{ paddingLeft: 0 }}>
      <ListItemAvatar>
        <Skeleton variant='circle' width={42} height={42} />
      </ListItemAvatar>
      <Skeleton variant='text' width={100} height={10} />
    </ListItem>
    <ListItem style={{ paddingLeft: 0 }}>
      <ListItemAvatar>
        <Skeleton variant='circle' width={42} height={42} />
      </ListItemAvatar>
      <Skeleton variant='text' width={100} height={10} />
    </ListItem>
  </List>
);

export { MyElementsViewUpcomingRosterDialog };
