import React from 'react';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import { unpackToDate } from '@campfire/hot-date';
import { createStyles, makeStyles } from '@material-ui/styles';
import { KeyboardArrowRight } from '@material-ui/icons';
import { GetCompletedTasks_vm_task_pendingApprovals_profile as ProfileType } from './__generated__/GetCompletedTasks';
import { Avatar } from '../../../general/user-profile/components/Avatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '18px 16px',
      backgroundColor: 'white',
      border: '1px solid #54545440',
      borderRadius: '3px',
      cursor: 'pointer',
    },
    infoContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
    },
    textContainer: {
      '& .title': {
        fontWeight: 700,
        fontSize: 16,
        color: theme.color.grey.neutral300,
      },
      '& .date': {
        fontWeight: 400,
        fontSize: 12,
        color: theme.color.grey.neutral300,
      },
    },
    button: {
      padding: '0 !important',
      height: '27px',
      width: '30px',
      color: 'white',
      backgroundColor: '#9E9E9E',
      borderRadius: '6px',
      minWidth: 'unset',
    },
  })
);

interface VolunteerSubmitProfileProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  updatedDate: string;
  profile: ProfileType | null;
}

export function VolunteerSubmitProfile({ onClick, updatedDate, profile }: VolunteerSubmitProfileProps) {
  const classes = useStyles();

  if (!profile) {
    return null;
  }

  return (
    <Box onClick={onClick} className={classes.container}>
      <Box className={classes.infoContainer}>
        <Avatar {...profile} size={40} />
        <Box className={classes.textContainer}>
          <Typography className='title'>
            {profile.preferredName} {profile.lastName}
          </Typography>
          <Typography className='date'>Updated {unpackToDate(updatedDate).toFormat('dd LLLL yy')}</Typography>
        </Box>
      </Box>
      <Button className={classes.button}>
        <KeyboardArrowRight style={{ fontSize: '22px' }} />
      </Button>
    </Box>
  );
}
