import { getDurationHoursMin, unpackToDateTime, unpackToTime } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { Box, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { memo, useMemo, useState } from 'react';
import { FullPageMessage } from '../../../../../../common/FullPageMessage';
import { getDisplayTimeSchedule } from '../../../../../../common/functions/activity-display-helpers';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { VolunteerDialogHoverBlock } from '../../../../volunteer-common-profile/VolunteerDialogHoverBlock';
import { GET_ACTIVITY_TIMELINE_PAST_ROSTER } from '../../activity-timeline-model.gql';
import { ActivityTimelinePastPublishedRoster } from '../../__generated__/ActivityTimelinePastPublishedRoster';
import { ActivityTimelinePastRosterActivity } from '../../__generated__/ActivityTimelinePastRosterActivity';
import {
  GetActivityTimelinePastPublishedRoster,
  GetActivityTimelinePastPublishedRosterVariables,
} from '../../__generated__/GetActivityTimelinePastPublishedRoster';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRight: 'solid #dadada 1px',
      minHeight: '100%',
    },
  })
);

interface ActivityTimelinePastRosterProps {
  activityId: string;
  activityDate: string;
}

export const ActivityTimelinePastRoster = memo((props: ActivityTimelinePastRosterProps) => {
  const { activityId, activityDate } = props;

  const { data, error, loading } = useCampfireQuery<
    GetActivityTimelinePastPublishedRoster,
    GetActivityTimelinePastPublishedRosterVariables
  >(GET_ACTIVITY_TIMELINE_PAST_ROSTER, {
    options: {
      variables: {
        activityId: activityId,
        activityDate: activityDate,
      },
    },
  });

  const publishedRoster = useMemo(() => {
    if (!data) return undefined;
    return data.vm.publishedRoster;
  }, [data]);

  const activity = useMemo(() => {
    if (!data) return undefined;
    return data.vm.activity;
  }, [data]);

  let publishedByPreferredName = '';
  let publishedByLastName = '';
  let publishedByDate = '';

  if (publishedRoster?.publishedByVolunteer) {
    publishedByPreferredName = publishedRoster.publishedByVolunteer.profile.preferredName;
    publishedByLastName = publishedRoster.publishedByVolunteer.profile.lastName;
    publishedByDate = publishedRoster.datePublished;
  }
  if (publishedRoster?.publishedByManager) {
    publishedByPreferredName = publishedRoster.publishedByManager.profile.preferredName;
    publishedByLastName = publishedRoster.publishedByManager.profile.lastName;
    publishedByDate = publishedRoster.datePublished;
  }

  const publishedByProfileId = publishedRoster
    ? publishedRoster.publishedByVolunteer?.profile.profileId ?? publishedRoster.publishedByManager?.profile.profileId
    : undefined;

  const publishedByUserId = publishedRoster
    ? publishedRoster.publishedByVolunteer?.profile.userId ?? publishedRoster.publishedByManager?.profile.userId
    : undefined;

  const publishedByName = `${publishedByPreferredName} ${publishedByLastName}`;

  const classes = useStyles();

  const { isXs, isSm } = useCampfireTheme();
  const compact = isXs || isSm;

  return (
    <Box className={classes.root}>
      {error && !loading ? (
        <FullPageMessage title={'Network Error'} subtitle={'Unable to load timeline.'} Icon={ErrorOutline} />
      ) : null}

      {loading ? (
        <PastRostersSkeletonLoadingLayout />
      ) : (
        <>
          <Box paddingX={3} paddingTop={2}>
            {publishedRoster && publishedByName && publishedByDate ? (
              <>
                <Typography variant='body2' display='inline'>{`Roster published by `}</Typography>

                {publishedByProfileId && publishedByUserId ? (
                  <VolunteerDialogHoverBlock
                    profileId={publishedByProfileId}
                    userId={publishedByUserId}
                    preferredName={publishedByPreferredName}
                    lastName={publishedByLastName}
                    hoverTextProps={{
                      display: 'inline',
                      hoverColor: 'primary',
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Typography variant='body2' display='inline'>
                    {publishedByName}
                  </Typography>
                )}

                <Typography variant='body2' display='inline'>{`, ${unpackToDateTime(publishedByDate).toFormat(
                  'ccc d LLL'
                )}`}</Typography>
              </>
            ) : (
              <Typography variant='body2' display='inline'>{`No roster published`}</Typography>
            )}
          </Box>

          {!activity ? null : compact ? (
            <SessionPublishedRostersMobile activity={activity} publishedRoster={publishedRoster} />
          ) : (
            <SessionPublishedRosters activity={activity} publishedRoster={publishedRoster} />
          )}
        </>
      )}
    </Box>
  );
});

const SessionPublishedRostersMobile = (props: {
  activity: ActivityTimelinePastRosterActivity;
  publishedRoster: ActivityTimelinePastPublishedRoster | undefined | null;
}) => {
  const { activity, publishedRoster } = props;
  const [showSessionRosters, setShowSessionRosters] = useState(false);
  const operativeWord = publishedRoster ? 'roster' : 'sessions';
  const { theme } = useCampfireTheme();

  return (
    <Box
      borderBottom={showSessionRosters ? `1px solid ${theme.color.grey.border}` : undefined}
      paddingX={3}
      paddingBottom={2}
    >
      <HoverText
        display='inline'
        variant='body2'
        color='primary'
        hoverColor='primary'
        onClick={() => setShowSessionRosters(!showSessionRosters)}
      >
        {showSessionRosters ? `Hide ${operativeWord}` : `Show ${operativeWord}`}
      </HoverText>
      {showSessionRosters ? <SessionPublishedRosters activity={activity} publishedRoster={publishedRoster} /> : null}
    </Box>
  );
};

const SessionPublishedRosters = (props: {
  activity: ActivityTimelinePastRosterActivity;
  publishedRoster: ActivityTimelinePastPublishedRoster | undefined | null;
}) => {
  const { activity, publishedRoster } = props;

  return (
    <Box
      paddingX={3}
      paddingBottom={2}
      paddingTop={0}
      margin={0}
      component='ul'
      style={{
        listStyle: 'none',
      }}
    >
      {activity.sessions.map((session) => {
        const titleDisplayText = `${session.name}`;
        const { startTime, endTime } = session;
        const durationDisplayText =
          startTime && endTime ? getDurationHoursMin(unpackToTime(startTime), unpackToTime(endTime)) : null;
        const timeDisplayText = getDisplayTimeSchedule(startTime, endTime);
        const rosterings = publishedRoster?.rosterings.filter(
          (rostering) =>
            !!rostering.sessionRosterings.find(
              (sessionRostering) => sessionRostering.session.sessionId === session.sessionId
            )
        );
        return (
          <Box component='li' key={session.sessionId} marginY={4}>
            <Typography variant='h6' style={{ lineHeight: 1 }}>
              {titleDisplayText}
            </Typography>

            <Box>
              <Box display='flex'>
                <Typography variant='subtitle2' color='textSecondary' style={{ marginRight: 8 }}>
                  {timeDisplayText}
                </Typography>
                {durationDisplayText ? (
                  <Typography variant='subtitle2' color='textSecondary'>
                    {`(${durationDisplayText})`}
                  </Typography>
                ) : null}
              </Box>

              {rosterings && rosterings.length > 0 ? (
                <Box
                  margin={0}
                  component='ul'
                  style={{
                    listStyle: 'none',
                    margin: 0,
                    padding: 0,
                    paddingTop: 16,
                  }}
                >
                  {publishedRoster?.rosterings
                    .filter(
                      (rostering) =>
                        !!rostering.sessionRosterings.find(
                          (sessionRostering) => sessionRostering.session.sessionId === session.sessionId
                        )
                    )
                    .map((rostering) => {
                      const { volunteer } = rostering;
                      return (
                        <Box
                          component='li'
                          key={volunteer.volunteerId}
                          display='flex'
                          alignContent='center'
                          alignItems='center'
                          style={{
                            paddingTop: 3,
                            paddingBottom: 3,
                          }}
                        >
                          <VolunteerDialogHoverBlock
                            avatarUrl={volunteer.profile.avatarUrl}
                            profileId={volunteer.profile.profileId}
                            userId={volunteer.profile.userId}
                            preferredName={volunteer.profile.preferredName}
                            lastName={volunteer.profile.lastName}
                            hoverTextProps={{
                              hoverColor: 'primary',
                              disableUnderline: true,
                              style: { marginLeft: 8 },
                            }}
                          />
                        </Box>
                      );
                    })}
                </Box>
              ) : (
                <Box>
                  <Typography color='textSecondary' variant='body2'>
                    No volunteers were rostered for this session
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export const PastRostersSkeletonLoadingLayout = () => {
  const { theme } = useCampfireTheme();
  return (
    <>
      <Box borderBottom={`1px solid ${theme.color.grey.border}`} padding={2}>
        <Skeleton variant='text' width='35%' height={10} />
        <Skeleton variant='text' width='50%' height={10} />
      </Box>

      <Box paddingTop={4} paddingX={2}>
        <Skeleton variant='text' width='40%' height={10} />
        <Skeleton variant='text' width='25%' height={10} />

        <Box marginTop={5}>
          <Box display='flex' alignContent='center' alignItems='center'>
            <Skeleton variant='circle' width={32} height={32} style={{ marginRight: 8 }} />
            <Skeleton variant='text' width={75} height={10} />
          </Box>
          <Box display='flex' alignContent='center' alignItems='center' marginTop={1}>
            <Skeleton variant='circle' width={32} height={32} style={{ marginRight: 8 }} />
            <Skeleton variant='text' width={75} height={10} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
