import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '4px',
      textTransform: 'none',
      marginRight: '10px',
      padding: '7px 12px',
      minWidth: '45px',
    },
    headerButton: {
      color: theme.color.grey.neutral400,
      borderColor: '#dcdcdc',
    },
    dangerButton: {
      color: '#D93A00',
      borderColor: 'D93A00',
    },
    typography: {
      marginLeft: '4px',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '19px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    successButton: {
      color: '#fff',
      backgroundColor: theme.color.secondary.main900,
    },
    toggledButton: {
      color: '#FCFCFC !important',
      backgroundColor: '#646464 !important',
    },
    iconButton: {
      color: theme.color.grey.neutral300,
      padding: '0px 4px',
      fontSize: '26px',
      border: '1px solid #9e9e9e',
      boxSizing: 'border-box',
      borderRadius: '4px',
      margin: '0 4px',
    },
    cancelledButton: {
      color: theme.color.card.cancelled,
      marginTop: '8px',
      marginBottom: '8px',
      textTransform: 'none',
    },
    cancelled: {
      color: theme.alert.red.medium,
      display: 'flex',
      alignItems: 'center',
      paddingTop: '10px',
      justifyContent: 'center',
      '& p': {
        fontSize: '14px',
      },
      '& svg': {
        fontSize: '16px',
      },
    },
    edit: {
      color: theme.alert.blue.extraLight,
      display: 'flex',
      alignItems: 'center',
      paddingTop: '10px',
      justifyContent: 'center',
      '& p': {
        fontSize: '14px',
      },
      '& svg': {
        fontSize: '16px',
      },
      textTransform: 'none',
    },
  };
});
