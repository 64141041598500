import { gql } from '@apollo/client';

export const ActivityTeamInformationFragments = gql`
  fragment ActivityTeamInformation on VOLUNTEER_ActivityEnrolmentType {
    volunteer {
      volunteerId
      profile {
        profileId
        userId
        firstName
        lastName
        avatarUrl
      }
      activityRoles {
        name
      }
      privilegeLevel
    }
  }
`;
