import { Box } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      display: 'flex',
      width: '100%',
      borderRadius: '8px',
      boxSizing: 'border-box',
      overflow: 'hidden',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: `0px 0px 4px rgba(0, 0, 0, 0.25)`,
      },
    },
    cardNoHover: {
      display: 'flex',
      width: '100%',
      borderRadius: '8px',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
  })
);

interface InfoCardProps {
  color?: {
    primary: string;
    secondary: string;
    additional?: string;
  };
  children: ReactNode;
  size?: 's' | 'm' | 'l';
  selected?: boolean;
  noHover?: boolean;
  classes?: { [key: string]: string };
}

const Card = (props: InfoCardProps) => {
  const { color, children, selected, noHover, classes: customClasses = {} } = props;
  const { theme, isMobile, isMd } = useCampfireTheme();
  const classes = useStyles();

  return (
    <Box
      className={classNames(customClasses.root, { [classes.cardNoHover]: noHover, [classes.card]: !noHover })}
      border={selected && color ? `2px solid ${color?.primary}` : `1px solid ${theme.color.grey.border}`}
      borderBottom={selected ? `2px solid ${color?.primary}` : `1px solid ${theme.color.grey.border}`}
      borderRight={selected ? `2px solid ${color?.primary}` : `1px solid ${theme.color.grey.border}`}
      bgcolor={color?.primary ?? theme.color.grey.neutralBrand100}
      overflow='hidden'
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        minWidth='36px'
        width='36px'
        borderRadius='8px 0px 0px 8px'
      ></Box>
      <Box
        display='flex'
        flexDirection='column'
        flexGrow={2}
        alignItems='flex-start'
        justifyContent='center'
        style={{ padding: '18px 18px' }}
        overflow='hidden'
        width={!isMobile || !isMd ? '300px' : '250px'}
        bgcolor={theme.color.white.neutral50}
        className={customClasses.bodyContainer}
      >
        {children}
      </Box>
    </Box>
  );
};

export const InfoCard = (props: InfoCardProps) => {
  const { color, children, selected, size, noHover, classes } = props;

  return (
    <Card color={color} selected={selected} size={size} noHover={noHover} classes={classes}>
      {children}
    </Card>
  );
};
