import { useEndpointFetch } from '../../../global/network/useEndpointFetch';

export const useVerifyApplicationFetch = () => {
  return useEndpointFetch<{ name: string; email: string; password: string }>('/vm/application/verify', {
    withAuth: false,
  });
};

export const useSubmitApplicationFetch = () => {
  return useEndpointFetch<
    {
      name: string;
      email: string;
      password: string;
      screeningAnswers?: {
        screeningQuestionId: string;
        answer: string;
      }[];
      inviteeId?: string;
    },
    { applicationId: string; isApproved: boolean }
  >('/vm/application/submit', { withAuth: false });
};

export const useIsInviteeFetch = () => {
  return useEndpointFetch<{ email: string }, { email?: string; inviteeId?: string }>('/vm/invitee', {
    withAuth: false,
  });
};
