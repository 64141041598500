import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box } from '@material-ui/core';
import React, { memo } from 'react';
import { Page } from '../../../global/components';
import { Catalogue } from './Catalogue';
import { ResourcesScreenTutorialDialog } from './ResourcesScreenTutorialDialog';
import { SupportProvider, useSupportContext } from './SupportContext';

const InnerSupportScreen = memo(() => {
  const {
    categoriesIsLoading,
    saveCategoryIsLoading,
    deleteCategoryIsLoading,
    saveSupportResourceIsLoading,
    deleteSupportResourceIsLoading,
    tutorialDialogOpen,
    setTutorialDialogOpen,
  } = useSupportContext();
  const loading =
    categoriesIsLoading ||
    saveCategoryIsLoading ||
    deleteCategoryIsLoading ||
    saveSupportResourceIsLoading ||
    deleteSupportResourceIsLoading;

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      <Box position='relative' height={1}>
        <LinearProgressOverlay isLoading={loading} />
        <Catalogue />
      </Box>
      <ResourcesScreenTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
        }}
      />
    </Page>
  );
});

const SupportScreen = memo(() => {
  return (
    <SupportProvider>
      <InnerSupportScreen />
    </SupportProvider>
  );
});

export { SupportScreen };
