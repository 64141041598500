import React, { useEffect, useMemo, useState } from 'react';
import { unpackToDate } from '@campfire/hot-date';
import { PaneWrapper } from '@campfire/pane';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { DelimitedNumericArrayParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { ExpandMore } from '@material-ui/icons';

import { CollapsibleSideBar } from '../../../common/CollapsibleSideBar';
import { arrayHead } from '../../../common/functions/array-head';
import { useUser } from '../../../global/auth/useUser';
import { Page } from '../../../global/components';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { IncidentList } from './incident-list/IncidentList';
import {
  GET_INCIDENT_MANAGEMENT_FILTER_INCIDENT_SEVERITIES,
  GET_INCIDENT_MANAGEMENT_INCIDENTS_DATA,
} from './incident-management-model.gql';
import { IncidentDetail } from './incident-detail/IncidentDetail';
import { FilterOption, FilterValues, IncidentType } from './incident-management-types';
import { IncidentManagementScreenHeaderWrapper } from './IncidentManagementScreenHeaderWrapper';
import {
  GetIncidentManagementIncidentsData,
  GetIncidentManagementIncidentsDataVariables,
} from './__generated__/GetIncidentManagementIncidentsData';
import { IncidentFilters } from './incident-filters/IncidentFilters';
import { filterByDay, filterBySearch, buildRowCsv, buildCsvData } from './IncidentManagementUtils';
import { GetIncidentManagementFilterIncidentSeverities } from './__generated__/GetIncidentManagementFilterIncidentSeverities';
import { initialFilters } from './IncidentManagementConsts';
import { ExportCsvDialog } from '../../../common/dialogs/ExportCsvDialog';
import { downloadCSV } from '../../../common/functions/csv-utils';
import {
  IncidentManagementGetIncidents,
  IncidentManagementGetIncidentsVariables,
  IncidentManagementGetIncidents_vm_incidentReports as IncidentReports,
} from './__generated__/IncidentManagementGetIncidents';
import { GET_INCIDENT_REPORTS } from './incident-management.gql';
import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';
import { sharedStyles } from '../../general/activities-v2/ActivityStyles/shared';
import { IncidentManagementTutorialDialog } from './IncidentManagementTutorialDialog';
import { useDeepEffect } from '../../../hooks/useDeepEffect';

export const INCIDENT_FETCH_LIMIT = 25;

export function parseIncidentReport(incidentReport: IncidentReports) {
  const incidentTitle =
    incidentReport.incidentLocation?.__typename === 'VOLUNTEER_ActivityIncidentLocationType'
      ? incidentReport.incidentLocation.activity.name
      : incidentReport.incidentLocation?.__typename === 'VOLUNTEER_AddressIncidentLocationType'
      ? incidentReport.incidentLocation.address.humanReadable
      : '';
  return {
    incidentId: incidentReport.incident.incidentId,
    incidentStatus: incidentReport.incident.incidentStatus,
    dateWorking: incidentReport.incident.dateWorking,
    dateClosed: incidentReport.incident.dateClosed,
    closingComments: incidentReport.incident.closingComments,
    programId: incidentReport.incident.program.programId,
    programName: incidentReport.incident.program.name,
    incidentTitle,
    incidentLocationType: incidentReport.incidentLocation?.__typename,
    incidentDateTime: incidentReport.incidentDateTime,
    submissionDateTime: incidentReport.submissionDateTime,
    incidentDay: unpackToDate(incidentReport.incidentDateTime).weekday,
    incidentSeverityId: incidentReport.severity.incidentSeverityId,
    incidentSeverity: incidentReport.severity.name,
    incidentSeverityColor: incidentReport.severity.color,
  };
}

type IncidentManagementScreenProps = {
  incidents: IncidentType[];
  initialLoading: boolean;
  incidentsLoading: boolean;
  getIncidentsRefetch: () => void;
  totalIncidents: number;
  showFilters: boolean;
  filters: FilterValues;
  onChangeFilter: (arg: any) => void;
  programOptions: FilterOption[];
  severityOptions: FilterOption[];
  onLoadMore: () => void;
  isLoading: boolean;
  hasMore: boolean;
};

const IncidentManagementMobileScreen = (props: IncidentManagementScreenProps) => {
  const {
    incidents,
    initialLoading,
    incidentsLoading,
    getIncidentsRefetch,
    totalIncidents,
    showFilters,
    filters,
    onChangeFilter,
    programOptions,
    severityOptions,
    onLoadMore,
    isLoading,
    hasMore,
  } = props;

  const [query] = useQueryParams({
    incidentId: StringParam,
  });

  const buttonClasses = sharedStyles.button();

  return (
    <Box width='100%' position='relative' display='flex' flexDirection='column' height='100%'>
      <Box padding='24px'>
        {query.incidentId ? (
          <IncidentDetail incidentId={query.incidentId} getIncidentsRefetch={getIncidentsRefetch} />
        ) : (
          <>
            {showFilters && (
              <IncidentFilters
                values={filters}
                onChange={onChangeFilter}
                programOptions={programOptions}
                severityOptions={severityOptions}
              />
            )}
            <IncidentList
              incidents={incidents}
              initialLoading={initialLoading}
              incidentsLoading={incidentsLoading}
              totalIncidents={totalIncidents}
            />
          </>
        )}
      </Box>
      {isLoading ? (
        <CircularProgress style={{ alignSelf: 'center' }} />
      ) : hasMore ? (
        <Button
          variant='outlined'
          disableElevation
          onClick={onLoadMore}
          classes={buttonClasses}
          style={{ alignSelf: 'center' }}
          endIcon={<ExpandMore />}
          disabled={isLoading}
        >
          Show More
        </Button>
      ) : null}
    </Box>
  );
};

const IncidentManagementDesktopScreen = (props: IncidentManagementScreenProps) => {
  const {
    incidents,
    initialLoading,
    incidentsLoading,
    getIncidentsRefetch,
    totalIncidents,
    showFilters,
    filters,
    onChangeFilter,
    programOptions,
    severityOptions,
    onLoadMore,
    isLoading,
    hasMore,
  } = props;
  const [query] = useQueryParams({
    incidentId: StringParam,
  });

  const buttonClasses = sharedStyles.button();

  return (
    <Box width='100%' position='relative' display='flex' flexDirection='row' height='100%' overflow='hidden'>
      <Box
        flexGrow={1}
        style={{ overflowY: 'auto' }}
        padding='0 60px 24px 60px'
        display='flex'
        flexDirection='column'
        alignItems='stretch'
      >
        {showFilters && (
          <IncidentFilters
            values={filters}
            onChange={onChangeFilter}
            programOptions={programOptions}
            severityOptions={severityOptions}
          />
        )}
        <IncidentList
          incidents={incidents}
          initialLoading={initialLoading}
          incidentsLoading={incidentsLoading}
          totalIncidents={totalIncidents}
        />
        {isLoading ? (
          <CircularProgress style={{ alignSelf: 'center' }} />
        ) : hasMore ? (
          <Button
            variant='outlined'
            disableElevation
            onClick={onLoadMore}
            classes={buttonClasses}
            style={{ alignSelf: 'center' }}
            endIcon={<ExpandMore />}
            disabled={isLoading}
          >
            Show More
          </Button>
        ) : null}
      </Box>
      {query.incidentId && (
        <CollapsibleSideBar>
          <IncidentDetail incidentId={query.incidentId} getIncidentsRefetch={getIncidentsRefetch} />
        </CollapsibleSideBar>
      )}
    </Box>
  );
};

export const IncidentManagementScreen = () => {
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = React.useState<FilterValues>(initialFilters);
  const [openExportCsvBulk, setOpenExportCsvBulk] = useState(false);
  const { maybeVolunteerIdentity } = useUser();

  const [incidentReports, setIncidentReports] = React.useState<IncidentReports[]>([]);
  const isMore = React.useRef(true);

  const { isMobile } = useCampfireTheme();
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    incidentId: StringParam,
    limit: NumberParam,
    day: DelimitedNumericArrayParam,
    program: StringParam,
    severity: StringParam,
    activityType: StringParam,
    search: StringParam,
  });

  const { data: getIncidentsData, loading: getIncidentsDataLoading, refetch: getIncidentsRefetch } = useCampfireQuery<
    GetIncidentManagementIncidentsData,
    GetIncidentManagementIncidentsDataVariables
  >(GET_INCIDENT_MANAGEMENT_INCIDENTS_DATA, {
    options: {
      variables: {
        volunteerId: maybeVolunteerIdentity?.volunteerId,
        includeVolunteer: !!maybeVolunteerIdentity,
      },
    },
  });

  const [queryIncidentReport, { data, loading: isIncidentReportLoading }] = useCampfireLazyQuery<
    IncidentManagementGetIncidents,
    IncidentManagementGetIncidentsVariables
  >(GET_INCIDENT_REPORTS);
  const { tab, limit, activityType, severity: severityId, program: programId, day } = query;

  useDeepEffect(() => {
    setQuery({ limit: INCIDENT_FETCH_LIMIT, tab }, 'push');
    setFilters(initialFilters);
  }, [tab]);

  useEffect(() => {
    setFilters({
      program: programId ?? 'all',
      activityType: activityType ?? 'all',
      severity: severityId ?? 'all',
      day: query.day ?? [],
    });
  }, [programId, activityType, severityId, day]);

  // To avoid race condition in useEffect and it's side effect caused by updating limit on tab value change
  let shouldRender = true;

  React.useEffect(() => {
    if (limit && tab) {
      queryIncidentReport({
        variables: {
          pagination: {
            limit: limit,
            offset: 0,
          },
          programIds: !programId || programId === 'all' ? null : [programId],
          status: tab,
          isInActivity: !activityType || activityType === 'all' ? null : activityType === 'in-activity',
          severityIds: !severityId || severityId === 'all' ? null : [severityId],
        },
      }).then((response) => {
        const responseIncidentReports = response?.data?.vm.incidentReports;
        if (shouldRender) {
          setIncidentReports(responseIncidentReports || []);
          isMore.current = responseIncidentReports?.length && limit ? responseIncidentReports?.length >= limit : false;
        }
      });
    }
    return () => {
      shouldRender = false;
    };
  }, [tab, programId, activityType, severityId, limit]);

  const { data: getSeveritiesData, loading: getSeveritiesLoading } = useCampfireQuery<
    GetIncidentManagementFilterIncidentSeverities,
    undefined
  >(GET_INCIDENT_MANAGEMENT_FILTER_INCIDENT_SEVERITIES);

  const programs = useMemo(() => {
    const privilegedPrograms =
      getIncidentsData?.vm.privilegedPrograms.map((program) => ({
        value: program.programId,
        label: program.name,
      })) || [];
    const basePrograms =
      getIncidentsData?.vm.volunteer?.programs.map((program) => ({
        value: program.programId,
        label: program.name,
      })) || [];
    return uniqBy([...privilegedPrograms, ...basePrograms], 'value');
  }, [getIncidentsData]);

  const severities = useMemo(() => {
    return (
      getSeveritiesData?.vm.incidentSeverities.map((severity) => ({
        value: severity.incidentSeverityId,
        label: severity.name,
      })) || []
    );
  }, [getSeveritiesData]);

  useEffect(() => {
    if (!isMobile) {
      if (
        incidentReports.length > 0 &&
        !incidentReports.some((iReport) => iReport.incident.incidentId === query.incidentId)
      ) {
        setQuery({ incidentId: arrayHead(incidentReports)?.incident.incidentId });
      }
      if (data && data?.vm.incidentReports.length === 0) setQuery({ incidentId: undefined });
    }
  }, [incidentReports, data]);

  const programOptions = [
    {
      value: 'all',
      label: 'All programs',
    },
  ].concat(programs);

  const severityOptions = [
    {
      value: 'all',
      label: 'All incident severities',
    },
  ].concat(severities);

  const filteredIncidents = incidentReports
    .map(parseIncidentReport)
    .filter(filterByDay(query.day))
    .filter(filterBySearch(query.search));

  const onChangeFilter = (newFilter: any) =>
    setQuery({
      ...query,
      ...newFilter,
    });

  const handleExport = () => {
    setOpenExportCsvBulk(true);
  };
  const handleCloseExportCsvBulk = () => {
    setOpenExportCsvBulk(false);
  };

  const handleSaveCsvBulk = () => {
    const rowsCsv = incidentReports.map((incident) => buildRowCsv(incident));
    const incidentCsvData = buildCsvData(rowsCsv);
    const filename = `incident_report_${DateTime.local().toFormat('yyyy-MM-d')}.csv`;
    downloadCSV(incidentCsvData, filename);
  };

  const onLoadMore = () => {
    setQuery({ ...query, limit: query.limit ? query.limit + INCIDENT_FETCH_LIMIT : INCIDENT_FETCH_LIMIT });
  };

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      <PaneWrapper>
        <IncidentManagementScreenHeaderWrapper
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          handleExport={handleExport}
        >
          {isMobile ? (
            <IncidentManagementMobileScreen
              incidents={filteredIncidents}
              initialLoading={getIncidentsDataLoading}
              incidentsLoading={isIncidentReportLoading}
              getIncidentsRefetch={getIncidentsRefetch}
              totalIncidents={filteredIncidents.length}
              showFilters={showFilters && !getIncidentsDataLoading && !getSeveritiesLoading}
              filters={filters}
              onChangeFilter={onChangeFilter}
              programOptions={programOptions}
              severityOptions={severityOptions}
              onLoadMore={onLoadMore}
              isLoading={isIncidentReportLoading}
              hasMore={isMore.current}
            />
          ) : (
            <IncidentManagementDesktopScreen
              incidents={filteredIncidents}
              initialLoading={getIncidentsDataLoading}
              incidentsLoading={isIncidentReportLoading}
              getIncidentsRefetch={getIncidentsRefetch}
              totalIncidents={filteredIncidents.length}
              filters={filters}
              showFilters={showFilters && !getIncidentsDataLoading && !getSeveritiesLoading}
              onChangeFilter={onChangeFilter}
              programOptions={programOptions}
              severityOptions={severityOptions}
              onLoadMore={onLoadMore}
              isLoading={isIncidentReportLoading}
              hasMore={isMore.current}
            />
          )}
        </IncidentManagementScreenHeaderWrapper>
        {openExportCsvBulk && (
          <ExportCsvDialog
            open={openExportCsvBulk}
            close={handleCloseExportCsvBulk}
            handleSaveCsv={handleSaveCsvBulk}
          />
        )}
      </PaneWrapper>
      <IncidentManagementTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
        }}
      />
    </Page>
  );
};
