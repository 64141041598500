import { gql } from '@apollo/client';
import { VolunteerFragments } from '../../../../common/models/fragments/volunteer.gql';

export const ACTIVITY_INFO = gql`
  query GetActivityInfo($activityId: String, $numberOfRosters: Int) {
    orgLogo
    vm {
      activity(activityId: $activityId) {
        activityId
        isActive
        closedActivity {
          closedActivityId
        }
        isSuspended
        name
        description
        program {
          programId
          name
        }
        sessions {
          ...RosterScreenActivitySession
        }
        startDate
        endDate
        startTime
        endTime
        rosterTemplates {
          rosterTemplateId
          rosterDate
        }
        lastX(x: $numberOfRosters)
        nextX(x: $numberOfRosters)
      }
    }
  }
  fragment RosterScreenActivitySession on VOLUNTEER_SessionType {
    sessionId
    name
    description
    minVolunteers
    maxVolunteers
    startTime
    endTime
    activity {
      program {
        programId
      }
    }
    
  }
  ${VolunteerFragments}
`;

export const ROSTER_NEXT_PAGINATION = gql`
  query GetActivityNextPage($activityId: String, $from: Date, $numberOfRosters: Int) {
    vm {
      activity(activityId: $activityId) {
        nextX(x: $numberOfRosters, from: $from)
      }
    }
  }
`;

export const ROSTER_PREVIOUS_PAGINATION = gql`
  query GetActivityPreviousPage($activityId: String, $until: Date, $numberOfRosters: Int) {
    vm {
      activity(activityId: $activityId) {
        lastX(x: $numberOfRosters, until: $until)
      }
    }
  }
`;

export const VOLUNTEER_INFO = gql`
  query GetVolunteerInfo($volunteerId: String) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        profile {
          profileId
          preferredName
          lastName
          avatarUrl
          userId
        }
        activityRoles {
          activityRoleId
          icon
          name
          tasks {
            taskId
            title
            completedTasks {
              completedTaskId
              status
              dateLastUpdated
              profile {
                profileId
                userId
              }
            }
          }
        }
      }
    }
  }
  ${VolunteerFragments}
`;

export const ROSTER_INFO = gql`
  query GetRosterInfo($activityId: String, $activityDate: Date) {
    vm {
      activity(activityId: $activityId) {
        draftRoster(activityDate: $activityDate) {
          draftRosterings {
            volunteer {
              volunteerId
            }
            sessionRosterings {
              session {
                sessionId
              }
            }
          }
          rosterNotes
        }
        publishedRoster(activityDate: $activityDate) {
          rosterId
          rosterNotes
          sessionNotes {
            session {
              sessionId
              name
            }
            notes
          }
          rosterings {
            ...RosterScreenPublishedRosterRostering
          }
          activeRosterings {
            ...RosterScreenPublishedRosterRostering
          }
        }
        cancelledActivity(activityDate: $activityDate) {
          cancelledActivityId
          cancelledDate
        }
        activityEnrolments {
          activityEnrolmentId
          availability(activityDate: $activityDate) {
            sessionAvailabilities {
              session {
                sessionId
              }
              dateRemoved
            }
          }
          unavailability(activityDate: $activityDate) {
            dateRemoved
            activityUnavailabilityId
          }
          volunteer {
            volunteerId
            profile {
              profileId
              preferredName
              lastName
              avatarUrl
              userId
            }
          }
        }
      }
    }
  }
  fragment RosterScreenPublishedRosterRostering on VOLUNTEER_PublishedRosteringType {
    publishedRosteringId
    attending
    volunteer {
      volunteerId
      profile {
        profileId
        preferredName
        lastName
        avatarUrl
        userId
      }
    }
    sessionRosterings {
      publishedSessionRosteringId
      session {
        sessionId
      }
    }
  }
`;
