import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: '30px',
      color: theme.color.grey.neutralBrand900,
      fontWeight: 500,
    },
    subHeader: {
      fontSize: '20px',
      lineHeight: '36px',
      color: theme.color.grey.neutralBrand800,
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    toolBoxContainer: {
      color: theme.color.grey.neutralBrand800,
    },
  })
);

interface Props {
  header: string;
  subHeader: string;
  children: React.ReactElement;
}

export function AvailabilityHeader({ header, subHeader, children }: Props) {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.headerContainer}>
        <Typography className={classes.header}>{header}</Typography>
        <Box className={classes.toolBoxContainer}>{children}</Box>
      </Box>
      <Typography className={classes.subHeader}>{subHeader}</Typography>
    </Box>
  );
}
