import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { AlertCard, getAlertCardColors } from '../../../../common/cards/alert-card/AlertCard';
import { SectionItem, useSectionStyles } from './IncidentDetail';

type Props = {
  dateClosedFormatted: string;
  closingComments: string | null;
};

export const ClosingInfoSection = ({ dateClosedFormatted, closingComments }: Props) => {
  const { lightTextColor } = getAlertCardColors('warning');
  const classes = useSectionStyles();
  return (
    <>
      <Box pt={2}>
        <AlertCard variant='warning'>
          <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
            Closed on {dateClosedFormatted}
          </Typography>
        </AlertCard>
      </Box>
      {closingComments && (
        <SectionItem title='Closing Comments'>
          <Typography className={classes.sectionBody}>{closingComments}</Typography>
        </SectionItem>
      )}
    </>
  );
};
