import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import React, { useRef, useState } from 'react';
import { Box, Typography, TextField, MenuItem, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { DataGrid, GridColumns, GridCellParams, GridSortModel } from '@material-ui/data-grid';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { DateTime } from 'luxon';
import { TitularTooltip } from '@campfire/titular-tooltip';
import pluralize from 'pluralize';
import { uniqBy, isEqual, sortBy, groupBy } from 'lodash';
import { unpackToDateTime } from '@campfire/hot-date';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { SearchField } from '../../../../common/inputs/SearchField';
import {
  useWaitlistBulkIncomingVolunteersFetch,
  useRejectActivityApplicationFetch,
  useApproveActivityApplicationFetch,
  useApproveBulkActivityApplicationFetch,
  useWaitlistIncomingVolunteerFetch,
  useRejectBulkActivityApplicationFetch,
} from '../../activity-management/activity-form-v2/activity-form-actions-v2';
import { VolunteerProfile } from '../../../../common/AvatarName';
import { TemplateSnackbar, SnackBarOptions } from '../../../../common/snackbars/TemplateSnackbar';
import { ApplicationActions, VolunteerApplicationMenuButton } from './VolunteerApplicationsMenu';
import { GET_VOLUNTEERS_APPLICATIONS } from './VolunteersApplicationsScreen.gql';
import { VOLUNTEERS_APPLICATIONS_GET_PROGRAMS } from './VolunteersApplicationGetPrograms.gql';
import {
  GetVolunteersApplications,
  GetVolunteersApplications_vm_activityApplications_volunteer_profile as ProfileType,
} from './__generated__/GetVolunteersApplications';
import { VolunteersApplicationGetPrograms } from './__generated__/VolunteersApplicationGetPrograms';
import { ApplicationRemovingMessage } from './ApplicationRemovingMessage';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ActivityNameAndPriority } from '../common/ActivityInfo';
import { ExportCsvDialog } from '../../../../common/dialogs/ExportCsvDialog';
import {
  downloadCSV,
  escapeDangerousCSVCharacters,
  replaceDoubleQuoteInString,
} from '../../../../common/functions/csv-utils';
import {
  ConfirmAddMaxVolunteer,
  ITeam,
} from '../../activities/activity-timeline/past/report/form/ConfirmAddMaxVolunteer';

const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('xs')]: {
        height: '560px',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-selectedRowCount': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-columnHeader--moving': {
        background: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
  })
);

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      paddingTop: '6px',
      paddingBottom: '7px',
      paddingRight: '10px',
      fontSize: '15px',
    },
    root: {
      height: '100%',
      width: '200px',
      borderRadius: '6px',
      borderTopColor: '#9E9E9E',
      borderRightColor: '#9E9E9E',
      borderBottomColor: '#9E9E9E',
      borderLeftColor: '#9E9E9E',
      color: '#444444',
    },
  })
);

export interface Application {
  id: string;
  program: string;
  profile: ProfileType;
  volunteerId: string;
  activityName: string;
  activityId: string;
  programId: string;
  dateApplied: DateTime;
  volunteerName: string;
  maxTeam: number | null;
  totalEnrolments: number;
}

interface ApplicationMap {
  [key: string]: Application;
}

export function VolunteersApplicationsScreen() {
  const { data, loading: volunteerApplicationsLoading, refetch } = useCampfireQuery<GetVolunteersApplications, {}>(
    GET_VOLUNTEERS_APPLICATIONS
  );
  const { data: vmPrograms, loading: programsLoading } = useCampfireQuery<VolunteersApplicationGetPrograms, {}>(
    VOLUNTEERS_APPLICATIONS_GET_PROGRAMS
  );
  const [selectedApplications, setSelectedApplications] = React.useState<(string | number)[]>([]);

  const [filterValue, setFilterValue] = React.useState('');
  const [snackbar, setSnackbar] = React.useState<SnackBarOptions>();
  const [showRemoveDialog, setShowRemoveDialog] = React.useState<boolean>(false);
  const [openExportCsvDialog, setOpenExportCsvDialog] = React.useState<boolean>(false);
  const [selectedProgramId, setSelectedProgramId] = React.useState<string>('all');
  const [teamExceed, setTeamExceed] = React.useState(false);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'volunteerName',
      sort: 'asc',
    },
  ]);

  const applications =
    data?.vm.activityApplications
      .filter(
        (application) =>
          !application.dateWithdrawn &&
          !application.dateActioned &&
          application.activity.isActive &&
          !application.activity.program.dateDeleted &&
          !application.activity.program.dateSuspended &&
          !application.volunteer.dateDeactivated &&
          !application.volunteer.flagging?.dateFlagged
      )
      .map((application) => ({
        id: application.activityApplicationId,
        program: application.activity.program.name,
        profile: application.volunteer.profile,
        volunteerId: application.volunteer.volunteerId,
        activityName: application.activity.name,
        activityId: application.activity.activityId,
        activityPriority: application.activity.priority,
        programId: application.activity.program.programId,
        volunteerName: `${application.volunteer.profile.preferredName} ${application.volunteer.profile.lastName}`,
        dateApplied: unpackToDateTime(application.dateApplied),
        maxTeam: application.activity.maxTeam,
        totalEnrolments: application.activity.totalEnrolments,
      })) || [];

  const showingApplications = applications
    ?.filter((application) =>
      `${application.profile.lastName} ${application.profile.preferredName} ${application.profile.preferredName} ${application.profile.lastName} ${application.profile.email} ${application.profile.contactNumber}`
        .toLowerCase()
        .includes(filterValue.toLowerCase())
    )
    .filter((application) => selectedProgramId === 'all' || application.programId === selectedProgramId);

  const programs =
    vmPrograms?.vm.programs
      .filter((program) => !program.dateDeleted && !program.dateSuspended)
      .sort((a, b) => a.name.localeCompare(b.name)) || [];
  const programOptions = React.useMemo(() => [{ programId: 'all', name: 'All Programs' }].concat(programs), [programs]);
  const onSelectProgram = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProgramId(event.target.value);
  };

  const moveToWaitlist = useWaitlistIncomingVolunteerFetch();
  const moveAllToWaitlist = useWaitlistBulkIncomingVolunteersFetch();
  const approveActivityApplication = useApproveActivityApplicationFetch();
  const approveBulkActivityApplication = useApproveBulkActivityApplicationFetch();
  const removeApplication = useRejectActivityApplicationFetch();
  const removeAllApplication = useRejectBulkActivityApplicationFetch();

  const applicationMap: ApplicationMap = applications.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur,
    }),
    {}
  );

  const selectedVolunteers = uniqBy(
    selectedApplications.map((id) => applicationMap[id]),
    (application) => application?.volunteerId
  );
  const selectedActivities = uniqBy(
    selectedApplications.map((id) => applicationMap[id]),
    (application) => application?.activityId
  );

  const volunteerText =
    selectedVolunteers.length === 1
      ? `${selectedVolunteers[0].profile.preferredName} ${selectedVolunteers[0].profile.lastName}`
      : `${selectedVolunteers.length} volunteers`;
  const activityText =
    selectedActivities.length === 1 ? selectedActivities[0].activityName : `${selectedActivities.length} activities`;

  const title = `Remove: ${volunteerText} from ${activityText}`;

  const maxTeamConfirm = useRef<any>();
  const maxTeamCancel = useRef<any>();

  const [exceedingTeams, setExceedingTeams] = useState<ITeam>();

  const addToTeam = (id: string) => {
    approveActivityApplication
      .run({
        activityApplicationId: id,
      })
      .then(() => {
        setSelectedApplications((currentSelectings) =>
          currentSelectings.filter((selectedApplicationId) => selectedApplicationId !== id)
        );
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: 'Volunteer added to team successfully',
        });
      });
  };

  const bulkAddToTeam = () => {
    approveBulkActivityApplication
      .run({
        activityApplicationIds: selectedApplications as string[],
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
          setSelectedApplications([]);
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: `Added ${selectedApplications.length} volunteers to team successfully`,
        });
      });
  };

  const onAddToTeam = (id: string) => {
    const selectedApplication = applications.find((a) => a.id === id);
    if (!selectedApplication) {
      return;
    }

    if (selectedApplication.maxTeam && selectedApplication.totalEnrolments >= selectedApplication.maxTeam) {
      setTeamExceed(true);
      maxTeamConfirm.current = () => {
        addToTeam(id);
        setTeamExceed(false);
      };
      maxTeamCancel.current = () => {
        setTeamExceed(false);
      };
    } else {
      addToTeam(id);
    }
  };

  const addBulk = () => {
    const applicationsGroupByActivity = groupBy(
      selectedApplications.map((applicationId) => applicationMap[applicationId]),
      (a) => a.activityId
    );

    const teamsExceed = Object.keys(applicationsGroupByActivity).filter((activityId) => {
      const applicationsByActivity = applicationsGroupByActivity[activityId];
      if (!applicationsByActivity[0]) {
        return false;
      }
      if (
        applicationsByActivity[0].maxTeam &&
        applicationsByActivity[0].maxTeam < applicationsByActivity.length + applicationsByActivity[0].totalEnrolments
      ) {
        return true;
      }
      return false;
    });

    setExceedingTeams(
      teamsExceed.reduce(
        (acc, activityId) => ({
          ...acc,
          [activityId]: applicationsGroupByActivity[activityId],
        }),
        {}
      )
    );

    if (teamsExceed.length > 0) {
      setTeamExceed(true);
      maxTeamConfirm.current = () => {
        bulkAddToTeam();
        setTeamExceed(false);
        setExceedingTeams(undefined);
      };
      maxTeamCancel.current = () => {
        setTeamExceed(false);
      };
    } else {
      bulkAddToTeam();
    }
  };

  const onRemove = () => {
    if (selectedApplications.length === 1) {
      removeApplication
        .run({
          activityApplicationId: selectedApplications[0] as string,
        })
        .then(() => {
          if (refetch) {
            setTimeout(refetch, 0);
          }
          setShowRemoveDialog(false);
          setSelectedApplications([]);
          setSnackbar({
            open: true,
            variant: 'success',
            message: 'Application removed',
          });
        });
    } else {
      removeAllApplication
        .run({
          activityApplicationIds: selectedApplications as string[],
        })
        .then(() => {
          if (refetch) {
            setTimeout(refetch, 0);
          }
          setShowRemoveDialog(false);
          setSelectedApplications([]);
          setSnackbar({
            open: true,
            variant: 'success',
            message: `Removed ${selectedApplications.length} applications successfully`,
          });
        });
    }
  };

  const onMovetoWaitlist = (id: string) => {
    moveToWaitlist
      .run({
        activityApplicationId: id,
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSelectedApplications([]);
        setSnackbar({
          open: true,
          variant: 'success',
          message: 'Waitlisted application successfully',
        });
      });
  };

  const onMoveBulkToWaitlist = () => {
    moveAllToWaitlist
      .run({
        activityApplicationIds: selectedApplications as string[],
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSelectedApplications([]);
        setSnackbar({
          open: true,
          variant: 'success',
          message: `Waitlisted ${selectedApplications.length} applications successfully`,
        });
      });
  };

  const onActionBulk = (action: ApplicationActions) => {
    switch (action) {
      case 'add':
        addBulk();
        break;

      case 'remove':
        setShowRemoveDialog(true);
        break;

      case 'moveToWaitlist':
        onMoveBulkToWaitlist();
        break;

      default:
        break;
    }
  };

  const onAction = (action: ApplicationActions, id: string) => {
    switch (action) {
      case 'add':
        onAddToTeam(id);
        break;

      case 'remove':
        setSelectedApplications([id]);
        setShowRemoveDialog(true);
        break;

      case 'moveToWaitlist':
        onMovetoWaitlist(id);
        break;

      default:
        break;
    }
  };

  const columns: GridColumns = [
    {
      field: 'volunteerName',
      headerName: 'Volunteer',
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <VolunteerProfile
            inTable
            noTrim
            {...row.profile}
            name={`${row.profile.preferredName} ${row.profile.lastName}`}
          />
        );
      },
    },
    {
      field: 'program',
      headerName: 'Program',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 200,
    },
    {
      field: 'activityName',
      headerName: 'Activity',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 200,
      renderCell: ({ row }: any) => {
        return <ActivityNameAndPriority name={row.activityName} priority={row.activityPriority} />;
      },
    },
    {
      field: 'dateApplied',
      headerName: 'Date Applied',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 200,
      renderCell: ({ row }: any) => row.dateApplied.toFormat('dd/MM/y'),
    },
    {
      field: '',
      disableColumnMenu: true,
      headerName: '',
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <VolunteerApplicationMenuButton
            onAction={(action: ApplicationActions) => onAction(action, row.id)}
            bulk={false}
          />
        );
      },
    },
  ];

  const onSortChange = (model: GridSortModel) => {
    if (!isEqual(model, sortModel)) {
      setSortModel(model);
    }
  };

  const exportCsvClick = () => {
    setOpenExportCsvDialog(true);
  };

  const handleSaveCsv = () => {
    const sortedWaitlists = sortBy(showingApplications, 'volunteerName');
    const rowsCsv = sortedWaitlists.map((application) => buildRowCsv(application));
    const incidentCsvData = buildCsvData(rowsCsv);
    const filename = `volunteers_applications_${DateTime.local().toFormat('yyyy-MM-d')}.csv`;
    downloadCSV(incidentCsvData, filename);
  };

  const buildRowCsv = (application: Application, separator = ',') => {
    if (!application) return '';
    const parsedApplication = { ...application, dateApplied: application.dateApplied.toFormat('dd/MM/y') };
    const { volunteerName, program, activityName, dateApplied } = parsedApplication;
    const arrayRowData = [volunteerName, program, activityName, dateApplied];
    return `"${arrayRowData
      .map((columnData) => escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
      .join(`"${separator}"`)}"`;
  };

  const buildCsvData = (rowsCsv: string[], separator = ',') => {
    const csvHeaderArr = ['Volunteer', 'Program', 'Activity', 'Date Applied'];
    const rowHeader = `"${csvHeaderArr.join(`"${separator}"`)}"`;
    const dataCsv = [rowHeader, ...rowsCsv];
    return `${dataCsv.join('\r\n')}`;
  };

  const tableClasses = useTableStyles();
  const classes = useStyles();
  const { isMobile } = useCampfireTheme();

  return volunteerApplicationsLoading || programsLoading ? (
    <Box position='relative' display='flex' flex='1 1 auto' height={1}>
      <CircularProgressOverlay isLoading />
    </Box>
  ) : (
    <Box
      flex={1}
      display='flex'
      flexDirection='column'
      paddingLeft={isMobile ? '16px' : '32px'}
      paddingRight='16px'
      paddingBottom='5px'
    >
      <Box
        display={isMobile ? 'block' : 'flex'}
        alignItems='center'
        paddingTop='16px'
        paddingBottom='16px'
        justifyContent='space-between'
      >
        <Typography style={{ fontSize: '15px', marginBottom: isMobile ? '8px' : '' }}>
          You have <strong>{applications.length}</strong> activity applications
        </Typography>
        <Box display='flex' alignItems='center'>
          {selectedApplications.length > 0 && (
            <Box display='flex' alignItems='center' paddingRight='15px'>
              <Typography style={{ fontSize: '15px', paddingRight: '3px' }}>
                <strong>{selectedApplications.length}</strong> selected
              </Typography>
              <VolunteerApplicationMenuButton onAction={onActionBulk} bulk />
            </Box>
          )}
          <Box mr={2}>
            <TitularTooltip title='Download current view as a CSV file'>
              <TabletButton
                variant='outlined'
                color='primary'
                startIcon={<DownloadIcon color='inherit' />}
                onClick={exportCsvClick}
              >
                {`Download CSV`}
              </TabletButton>
            </TitularTooltip>
          </Box>
          <Box display='flex' alignItems='stretch' paddingRight='16px'>
            <TextField
              select
              value={selectedProgramId}
              onChange={onSelectProgram}
              variant='outlined'
              InputProps={{
                classes: classes,
              }}
              fullWidth
            >
              {programOptions.map((program) => (
                <MenuItem key={program.programId} value={program.programId}>
                  {program.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box display='flex' alignItems='stretch'>
            <SearchField
              fullWidth
              value={filterValue}
              onChange={(e: any) => setFilterValue(e.target.value)}
              placeholder={isMobile ? 'Search' : 'Search applications'}
            />
          </Box>
        </Box>
      </Box>
      <Box flex={1} display='flex' height='100%'>
        <DataGrid
          columns={columns}
          rows={showingApplications}
          classes={tableClasses}
          onSelectionModelChange={setSelectedApplications}
          checkboxSelection
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(model) => onSortChange(model)}
        />
      </Box>
      {snackbar && <TemplateSnackbar {...snackbar} onClose={() => setSnackbar(undefined)} />}
      <ApplicationRemovingMessage
        open={selectedApplications.length > 0 && showRemoveDialog}
        onClose={() => {
          setShowRemoveDialog(false);
        }}
        onRemove={onRemove}
        title={title}
        warningText={`You are removing ${pluralize('this', selectedVolunteers.length)} volunteer's activity ${pluralize(
          'application',
          selectedVolunteers.length
        )}`}
      />
      {openExportCsvDialog && (
        <ExportCsvDialog
          open={openExportCsvDialog}
          close={() => setOpenExportCsvDialog(false)}
          handleSaveCsv={handleSaveCsv}
        />
      )}
      {teamExceed && (
        <ConfirmAddMaxVolunteer onCancel={maxTeamCancel.current} onOK={maxTeamConfirm.current} teams={exceedingTeams} />
      )}
    </Box>
  );
}
