import React from 'react';
import { Divider, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

interface HeadingProps {
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: '20px',
      fontWeight: 900,
      color: theme.color.grey.neutralBrand900,
      paddingLeft: 8,
    },
  })
);

export function Heading({ title }: HeadingProps) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant='h4' className={classes.heading} style={{ marginTop: 24 }}>
        {title}
      </Typography>
      <Divider style={{ marginTop: 14 }} />
    </React.Fragment>
  );
}
