import { isDev } from '../../../../../../../global/config/is-dev';
import { isStaging } from '../../../../../../../global/config/is-staging';

export const getWidgetUrl = () => {
  if (isDev) {
    return 'http://localhost:3000';
  }

  if (isStaging) {
    return 'https://d2q1ayrqnphme2.cloudfront.net';
  }

  return 'https://d233pacn3ziq6i.cloudfront.net';
};

export const getMapUrl = () => {
  if (isDev) {
    return 'http://localhost:3000';
  }

  if (isStaging) {
    return 'https://d2q1ayrqnphme2.cloudfront.net';
  }

  return 'https://d233pacn3ziq6i.cloudfront.net';
};
