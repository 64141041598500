import { Box } from '@material-ui/core';
import React from 'react';
import { IncidentManagementDesktopTabs } from './IncidentManagementDesktopTabs';
import { IncidentManagementToolbar } from './IncidentManagementToolbar';

interface IncidentManagementDesktopScreenHeaderProps {
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  handleExport: () => void;
}

export const IncidentManagementDesktopScreenHeader = (props: IncidentManagementDesktopScreenHeaderProps) => {
  return (
    <Box margin='12px 60px 0px 60px'>
      <IncidentManagementDesktopTabs>
        <IncidentManagementToolbar {...props} />
      </IncidentManagementDesktopTabs>
    </Box>
  );
};
