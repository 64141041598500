import { Typography } from '@material-ui/core';
import React, { memo, useMemo } from 'react';
import { getDisplayTimeSchedule } from '../../../../../common/functions/activity-display-helpers';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { FutureSidebarItem } from './ActivityTimelineSidebar';
import {
  ActivityTimelineSidebarItemBaseProps,
  ActivityTimelineSidebarItemButtonBase,
} from './sidebar-items/ActivityTimelineSidebarItemButtonBase';
import { ActivityTimelineSidebarItemStatusLabel } from './sidebar-items/ActivityTimelineSidebarItemStatusLabel';

type Props = {
  futureSidebarItem: FutureSidebarItem;
} & ActivityTimelineSidebarItemBaseProps;

const ActivityTimelineFutureSidebarItem = memo((props: Props) => {
  const {
    futureSidebarItem,
    setSelectedActivityId,
    setSelectedDate,
    selected,
    listItemRef,
    onSidebarItemClick,
    setSelectedRosterTemplateId,
  } = props;
  const { theme } = useCampfireTheme();
  const timeScheduleFormatted = useMemo(() => {
    return getDisplayTimeSchedule(futureSidebarItem.startTime, futureSidebarItem.endTime);
  }, [futureSidebarItem]);

  const statusMessage = useMemo(() => {
    return futureSidebarItem.futureStatus === 'cancelled'
      ? 'Activity cancelled'
      : futureSidebarItem.futureStatus === 'not-started'
      ? 'Roster is not started'
      : futureSidebarItem.futureStatus === 'not-published'
      ? 'Roster is not published'
      : futureSidebarItem.futureStatus === 'published'
      ? 'Roster is published'
      : 'Roster has unpublished changes';
  }, [futureSidebarItem.futureStatus]);

  const statusColour = useMemo(() => {
    return futureSidebarItem.futureStatus === 'cancelled'
      ? '#323232'
      : futureSidebarItem.futureStatus === 'not-started'
      ? theme.status.red.light
      : futureSidebarItem.futureStatus === 'published'
      ? theme.status.green.light
      : theme.status.amber.light;
  }, [futureSidebarItem.futureStatus]);

  return (
    <li ref={listItemRef}>
      <ActivityTimelineSidebarItemButtonBase
        activityId={futureSidebarItem.activityId}
        selected={selected}
        handleClick={() => {
          if (onSidebarItemClick) {
            onSidebarItemClick();
          }
          setSelectedActivityId(futureSidebarItem.activityId);
          setSelectedDate(futureSidebarItem.activityDate);
          setSelectedRosterTemplateId(futureSidebarItem.rosterTemplateId ?? '');
        }}
      >
        <Typography>{futureSidebarItem.activityName}</Typography>
        <Typography variant='body2' display='block'>
          {`${futureSidebarItem.programName}`}
        </Typography>
        <Typography variant='body2' display='block'>
          {timeScheduleFormatted}
        </Typography>
        <Typography variant='caption' display='block'>
          {`${futureSidebarItem.humanReadableRecurrence}`}
        </Typography>
        <ActivityTimelineSidebarItemStatusLabel color={statusColour} message={`${statusMessage}`} />
      </ActivityTimelineSidebarItemButtonBase>
    </li>
  );
});

export { ActivityTimelineFutureSidebarItem };
