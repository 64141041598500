import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import { FileUploadGallery, FugFile, UploadedFile } from '@campfire/file-upload-gallery/lib';
import { EditorState, convertFromRaw } from 'draft-js';
import { createStyles, makeStyles } from '@material-ui/styles';
import { FileTypeEnumType } from '../../__generated__/globalTypes';
import { ProfileAvatar } from '../ProfileAvatar';
import { useUser } from '../../global/auth/useUser';
import { IComment } from './types';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'start',
    },
    button: {
      fontSize: '0.75rem',
      fontWeight: 500,
      textTransform: 'none',
    },
  })
);

export const isDraftJsContent = (content?: string | null) => content?.startsWith('{"blocks":');

export interface CommentAttachmentType {
  attachmentId?: string;
  url: string;
  name: string;
  fileType: FileTypeEnumType;
}

export interface PostCommentType {
  content: string;
  attachments?: CommentAttachmentType[];
  commentId?: string;
}
interface CommentBoxProps {
  enableAttachment?: boolean;
  onPost: (body: PostCommentType) => void;
  onClear?: () => void;
  comment?: IComment;
}

export function CommentBox({ onPost, onClear, enableAttachment = false, comment }: CommentBoxProps) {
  const { user } = useUser();
  const [content, setContent] = React.useState<string>('');
  const [attachments, setAttachments] = React.useState<CommentAttachmentType[]>([]);
  const classes = useStyles();
  const { isMd } = useCampfireTheme();

  useEffect(() => {
    if (comment) {
      if (isDraftJsContent(comment.content)) {
        setContent(
          EditorState.createWithContent(convertFromRaw(JSON.parse(comment.content || '{}')))
            .getCurrentContent()
            .getPlainText()
        );
      } else {
        setContent(comment.content || '');
      }

      const pulledAttachments = (comment.attachments ?? []).map((attachment: any) => ({
        attachmentId: attachment.attachmentId,
        url: attachment.objectUrl || attachment.url,
        name: attachment.name,
        fileType: attachment.fileType,
      }));
      setAttachments(pulledAttachments);
    }
  }, [comment]);

  const uploadedFiles: Array<UploadedFile> = useMemo(() => {
    if (comment) {
      return (comment.attachments ?? []).map((attachment: any) => ({
        url: attachment.objectUrl || attachment.url,
        fileId: attachment.attachmentId,
        name: attachment.name,
      }));
    }
    return [];
  }, [comment]);

  const handleClear = () => {
    setContent('');
    if (onClear) {
      onClear();
    }
  };

  const handlePost = () => {
    if (enableAttachment) {
      onPost({ content: content.trim(), attachments, commentId: comment?.commentId });
    } else {
      onPost({ content: content.trim(), commentId: comment?.commentId });
    }
    setTimeout(() => {
      handleClear();
    }, 100);
  };

  return (
    <Box>
      <Box className={classes.container}>
        {!comment && <ProfileAvatar profile={user} />}
        <Box marginLeft={isMd ? '0.5rem' : '1rem'} flex={1}>
          <TextField
            value={content}
            onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => setContent(ev.target.value)}
            multiline
            variant='outlined'
            fullWidth
            rows={5}
          />
          {enableAttachment && (
            <Box mt={2}>
              <Typography variant='body2' color='textSecondary' style={{ fontWeight: 'bold' }}>
                {'Attachments'}
              </Typography>
              <FileUploadGallery
                data-track='comment-attachments-area'
                allowMultiple
                uploadedFiles={uploadedFiles}
                handleFilesAdded={(files: FugFile[]) => {
                  Promise.all(
                    files.map((file) => {
                      return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          const { result } = reader;
                          if (result) {
                            resolve(result.toString().substr(result.toString().indexOf(',') + 1));
                          } else {
                            resolve('');
                          }
                        };
                        reader.onerror = (error) => reject(error);
                      });
                    })
                  ).then((results: any[]) => {
                    setAttachments([
                      ...attachments,
                      ...results.map((result, resultIndex) => ({
                        name: files[resultIndex].name,
                        fileType: files[resultIndex].type as FileTypeEnumType,
                        url: result as string,
                      })),
                    ]);
                  });
                }}
                handleFileRemoved={(removedFile: FugFile) => {
                  setAttachments(attachments.filter((attachment) => attachment.attachmentId !== removedFile.id));
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box display='flex' justifyContent='flex-end' mt='0.5rem'>
        <Button onClick={handleClear} className={classes.button}>
          {comment ? 'Cancel' : 'Clear'}
        </Button>
        <Button onClick={() => handlePost()} className={classes.button} color='primary' variant='contained'>
          {comment ? 'Save' : 'Post'}
        </Button>
      </Box>
    </Box>
  );
}
