import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useContainerStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingLeft: '60px',
    paddingRight: '60px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingBottom: '2rem'
    },
    display: 'flex',
    flexDirection: 'column'
  }
}));
