import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { unpackToDateTimeTZ } from '@campfire/hot-date';
import { AlertCard } from '../../../../common/cards/alert-card/AlertCard';
import { DateInfoCard } from '../../../../common/cards/date-info-card/DateInfoCard';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { IncidentType } from '../incident-management-types';
import { IncidentListSkeleton } from './IncidentListSkeleton';

type IncidentListProps = {
  incidents: IncidentType[];
  initialLoading: boolean;
  incidentsLoading: boolean;
  totalIncidents: number;
};

export const IncidentListItem = ({
  incident,
  onSelectIncident,
}: {
  incident: IncidentType;
  onSelectIncident: () => void;
}) => {
  const { theme } = useCampfireTheme();
  const [query] = useQueryParams({
    incidentId: StringParam,
  });

  return (
    <Box my='12px' onClick={onSelectIncident}>
      <DateInfoCard
        date={incident.incidentDateTime}
        color={{
          primary: incident.incidentSeverityColor,
          secondary: '#fff',
        }}
        selected={query?.incidentId === incident.incidentId}
        size='m'
        showYear
      >
        <Typography
          variant='h6'
          noWrap
          style={{
            color: theme.color.grey.neutral400,
            width: '100%',
            fontWeight: 800,
            letterSpacing: '-0.02em',
            lineHeight: '1em',
            paddingTop: '5px',
          }}
        >
          {incident.incidentTitle}
          {' / '}
        </Typography>
        <Typography
          variant='h6'
          noWrap
          style={{
            color: theme.color.grey.neutral200,
            width: '100%',
            fontWeight: 800,
            letterSpacing: '-0.02em',
          }}
        >
          {incident.programName}
        </Typography>
        <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200 }}>
          {unpackToDateTimeTZ(incident.incidentDateTime).toLocaleString({
            day: 'numeric',
            weekday: 'short',
            month: 'long',
            hour: '2-digit',
            minute: 'numeric',
            hour12: true,
          })}
        </Typography>
      </DateInfoCard>
    </Box>
  );
};

export const IncidentList = ({ incidents, incidentsLoading, totalIncidents }: IncidentListProps) => {
  const [query, setQuery] = useQueryParams({
    incidentId: StringParam,
  });

  if (incidentsLoading) return <IncidentListSkeleton />;

  if (!incidentsLoading && incidents.length === 0)
    return (
      <Box py={2}>
        <AlertCard variant='info' title='There are no incidents to show' />
      </Box>
    );

  return (
    <Box py={1}>
      {incidentsLoading ? (
        <Skeleton variant='text' width={75} height={20} style={{ marginTop: 20, marginBottom: 20 }} />
      ) : (
        <Typography display='block' variant='caption' color='textSecondary' style={{ marginTop: 20, marginBottom: 20 }}>
          Total <span style={{ fontWeight: 700 }}>{totalIncidents}</span> incident reports
        </Typography>
      )}
      {incidents.map((incident: IncidentType) => (
        <IncidentListItem
          incident={incident}
          onSelectIncident={() => setQuery({ ...query, incidentId: incident.incidentId })}
        />
      ))}
    </Box>
  );
};
