import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  ListItemAvatar,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core';
import { EmailRounded, FilterList, MoreVert, Email as EmailIcon, GetAppOutlined } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { memo, ReactNode, useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { StatusChip } from '../../../../common/chips/StatusChip';
import { SearchField } from '../../../../common/inputs/SearchField';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { InvitationDialog } from '../../../admin/invitation/InvitationDialog';
import { FallbackAvatar } from '../../../general/user-profile/components/Avatar';
import { useVolunteersContext } from '../VolunteersContext';
import { DEFAULT_FILTERS, IncomingVolunteersFilters } from './components/filters/filters';
import { MobileFiltersDialog } from './components/filters/MobileFiltersDialog';
import { ProgramFilterSelectField } from './components/filters/program-filters/ProgramFilterSelectField';
import { SortSelectField } from './components/filters/sort-filters/SortSelectField';
import { StatusSelectField } from './components/filters/status-filters/StatusSelectField';
import { TypeSelectField } from './components/filters/type-filters/TypeSelectField';
import { IncomingVolunteersPaginationDropdown } from './components/IncomingVolunteersPaginationDropdown';
import { IncomingVolsFilterProgram } from './__generated__/IncomingVolsFilterProgram';
import { IncomingVolunteerProfile_application_mostRecentReminder as ReminderType } from './__generated__/IncomingVolunteerProfile';
import { IncomingVolunteerProfileProgram } from './__generated__/IncomingVolunteerProfileProgram';
import { GetIncomingVolunteers_vm_incomingVolunteers_incomingVolunteers as IncomingVolunteerType } from './__generated__/GetIncomingVolunteers';
import { ExportsList } from '../../../../common/export/ExportsList';

const useStyles = () => {
  const { theme } = useCampfireTheme();
  return makeStyles({
    listItem: {
      padding: 8,
      borderBottom: `1px solid ${theme.color.grey.border}`,
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
    },
    applied: {
      color: theme.status.green.medium,
    },
    downloadBtn: {
      textDecoration: 'none',
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
    },
    downloadBtnWrapper: {
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
      },
    },
  })();
};

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.color.primary,
  },
}))(LinearProgress);

interface CustomListItemProps {
  name?: string;
  email: string | null;
  avatar: ReactNode;
  entryStatus?: string;
  accountStatus?: string;
  profilePercentageComplete?: number;
  profileCompletionStatus?: string;
  userActivityStatus?: string;
  programs?: IncomingVolunteerProfileProgram[];
  mostRecentReminder?: ReminderType | null;
  onClick?: () => void;
  isAccountRemoved?: boolean;
}

export const CustomListItem = (props: CustomListItemProps) => {
  const {
    name,
    email,
    avatar,
    entryStatus,
    accountStatus,
    profilePercentageComplete,
    profileCompletionStatus,
    userActivityStatus,
    programs,
    onClick,
    mostRecentReminder,
    isAccountRemoved,
  } = props;
  const { theme, isMobile, isMd } = useCampfireTheme();
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction='row'
      wrap='nowrap'
      alignItems='center'
      className={classes.listItem}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <Grid item>{avatar}</Grid>
      <Grid item container direction='column' alignContent='center' style={{ flex: 4 }} spacing={1}>
        <Grid item>
          {name && (
            <Typography component='span' style={{ marginRight: 8 }}>
              {name}
            </Typography>
          )}
          <Typography component='span' variant='body2' color='textSecondary'>
            {email}
          </Typography>
          {programs
            ? programs.map((program) => (
                <Typography
                  component={isMd ? 'div' : 'span'}
                  variant='body2'
                  color='textSecondary'
                  style={{ marginLeft: isMd ? 0 : 8, marginRight: isMd ? 8 : 0 }}
                >
                  {program.name}
                </Typography>
              ))
            : null}
        </Grid>
        <Grid item container direction='row' alignItems='center' spacing={2}>
          {entryStatus && !isAccountRemoved && (
            <Grid item>
              <StatusChip status={entryStatus} bgcolor={theme.status.green.light} />
            </Grid>
          )}
          {isAccountRemoved && (
            <Grid item>
              <StatusChip status='Application Removed' bgcolor={theme.status.red.medium} />
            </Grid>
          )}
          {accountStatus && (
            <Grid>
              <StatusChip
                status={accountStatus}
                bgcolor={accountStatus === 'Account created' ? '#2196F3' : theme.status.red.medium}
              />
            </Grid>
          )}
          {!!profilePercentageComplete && (
            <Grid item style={{ width: 250 }}>
              <BorderLinearProgress variant='determinate' value={profilePercentageComplete * 100} />
            </Grid>
          )}
          {profileCompletionStatus && (
            <Grid item>
              <StatusChip
                status={profileCompletionStatus}
                bgcolor={
                  profileCompletionStatus === 'Profile completed' ? theme.palette.primary.main : theme.status.red.medium
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item style={{ flex: 1, display: isMobile ? 'none' : 'block' }}>
        <Typography variant='caption' color='textSecondary'>
          {userActivityStatus}
        </Typography>
        {mostRecentReminder && (
          <Box>
            <Typography variant='caption' color='textSecondary'>
              Last reminded {unpackToDateTime(mostRecentReminder.remindedAt).toRelative()}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

function getQueryParamForRow(volunteerRowContent: IncomingVolunteerType): string | undefined {
  if (volunteerRowContent.source === 'Invitee') {
    return `inviteeId=${volunteerRowContent.incomingVolunteerId}`;
  }
  if (volunteerRowContent.source === 'Application') {
    return `applicationId=${volunteerRowContent.incomingVolunteerId}`;
  }
  if (volunteerRowContent.source === 'Profile') {
    return `volunteerProfileId=${volunteerRowContent.incomingVolunteerId}`;
  }

  return undefined;
}

export const IncomingVolunteersListItem = memo(
  ({ volunteerRowContent }: { volunteerRowContent: IncomingVolunteerType }) => {
    const linkToSearch = getQueryParamForRow(volunteerRowContent);
    const location = useLocation();
    const isApplicationDeleted = ['rejected', 'removed'].includes(volunteerRowContent.application?.status || '');
    const isProfileRemoved = Boolean(volunteerRowContent.profile?.dateRemoved);
    const isInviteeRemoved = Boolean(volunteerRowContent.invitee?.dateRevoked);
    const isAccountRemoved = isApplicationDeleted || isProfileRemoved || isInviteeRemoved;
    const isAccountCreated =
      volunteerRowContent.application?.status === 'approved' && volunteerRowContent.profile?.userId;

    const hasPendingTasks = !!volunteerRowContent.profile?.completedTasks.find(
      (completedTask) =>
        completedTask.task.cake.cakeType === 'required' &&
        completedTask.status === 'pending' &&
        !completedTask.task.dateRemoved
    );
    const isProfilePending = hasPendingTasks;
    const isProfileApproved =
      volunteerRowContent.profile?.isCompleted && volunteerRowContent.profile?.isProfileTaskApproved;
    const profileCompletionStatus = isProfilePending
      ? 'Pending review'
      : isProfileApproved
      ? 'Profile completed'
      : undefined;
    const prepend =
      volunteerRowContent.source === 'Invitee'
        ? 'Invited'
        : volunteerRowContent.source === 'Application'
        ? 'Submitted'
        : 'Updated';
    return (
      <Link
        to={{ pathname: location.pathname, search: linkToSearch }}
        style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
      >
        <CustomListItem
          name={volunteerRowContent.name || ''}
          email={volunteerRowContent.email}
          avatar={
            volunteerRowContent.name ? (
              <ListItemAvatar>
                <FallbackAvatar
                  initials={volunteerRowContent.name
                    .split(' ')
                    .map((n) => n[0])
                    .join('')}
                  size={32}
                />
              </ListItemAvatar>
            ) : (
              <ListItemAvatar>
                <EmailRounded style={{ marginLeft: 3 }} color='action' />
              </ListItemAvatar>
            )
          }
          isAccountRemoved={isAccountRemoved}
          entryStatus={volunteerRowContent.isInvited ? 'Invited' : 'Applied'}
          accountStatus={isAccountCreated ? 'Account created' : undefined}
          profilePercentageComplete={volunteerRowContent.profile?.percentageComplete}
          profileCompletionStatus={profileCompletionStatus}
          userActivityStatus={`${prepend} ${formatDistance(unpackToDateTime(volunteerRowContent.lastUpdated))}`}
          programs={volunteerRowContent.profile?.programs}
          mostRecentReminder={volunteerRowContent.application?.mostRecentReminder}
        />
      </Link>
    );
  }
);

interface IncomingVolunteersFilterActionsProps {
  selectedFilters: IncomingVolunteersFilters;
  setSelectedFilters: (x: IncomingVolunteersFilters) => void;
  setSearchIncomingVolunteersInput: (x: string) => void;
  filteredVolunteers: any;
  programs?: IncomingVolsFilterProgram[];
  onClickSendReminder: () => void;
  handleExport: () => void;
}

type FilteredVolunteer = {
  __typename: string;
  invitationId: string;
  applicationId: string;
  profileId: string;
  volunteerProfileId: string;
  profilePercentageComplete?: number;
  relevantTasks?: number;
  completedTasks?: number;
  programs?: IncomingVolsFilterProgram[];
  application?: {};
};

const IncomingVolunteersFilterActions = (props: IncomingVolunteersFilterActionsProps) => {
  const { selectedFilters, setSelectedFilters, programs, onClickSendReminder, handleExport } = props;
  const classes = useStyles();
  const { isMd, theme, isMobile } = useCampfireTheme();
  const programsSorted = programs?.sort((a, b) => a.name.localeCompare(b.name));

  const [exportTarget, setExportTarget] = React.useState<HTMLElement | null>(null);

  const onShowExport = (e: React.MouseEvent<HTMLElement>) => {
    setExportTarget(e.currentTarget);
  };

  return (
    <Grid item container>
      <Box
        display='flex'
        flexWrap='nowrap'
        justifyContent='space-between'
        width={1}
        padding={1}
        alignContent='center'
        alignItems='center'
      >
        <Box display='flex' alignContent='center' alignItems='center'>
          <TypeSelectField selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />

          <StatusSelectField selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />

          <SortSelectField selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />

          <ProgramFilterSelectField
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            programs={programsSorted}
          />

          {selectedFilters.selectedSortFilter !== DEFAULT_FILTERS.selectedSortFilter ||
          selectedFilters.selectedStatusFilter !== DEFAULT_FILTERS.selectedStatusFilter ||
          selectedFilters.selectedTypeFilter !== DEFAULT_FILTERS.selectedTypeFilter ||
          selectedFilters.selectedProgramFilter !== DEFAULT_FILTERS.selectedProgramFilter ? (
            <HoverText
              color='textSecondary'
              hoverColor='textSecondary'
              onClick={() => setSelectedFilters(DEFAULT_FILTERS)}
              variant='body2'
            >
              Clear filters
            </HoverText>
          ) : null}
        </Box>

        <Box display='flex' alignItems='center'>
          <Box ml={1} className={classes.downloadBtnWrapper}>
            <div>
              <TabletButton
                variant='outlined'
                style={{
                  color: theme.color.grey.neutral400,
                  boxSizing: 'border-box',
                  borderColor: '#dcdcdc',
                  borderRadius: '4px',
                  textTransform: 'none',
                  padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
                  minWidth: '40px',
                  marginRight: '10px',
                }}
                onClick={onShowExport}
              >
                <GetAppOutlined style={{ marginTop: '1px', fontSize: '20px' }} />
                <Typography
                  style={{
                    marginLeft: '4px',
                    fontWeight: 600,
                    fontSize: '13px',
                    lineHeight: '19px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: isMobile || isMd ? 'none' : 'inherit',
                  }}
                >
                  Export
                </Typography>
              </TabletButton>
              <Popover
                open={Boolean(exportTarget)}
                anchorEl={exportTarget}
                onClose={() => setExportTarget(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <ExportsList exportType='incoming volunteers' onCreate={handleExport} />
              </Popover>
            </div>
          </Box>
          <Box ml={1} display='flex' alignContent='center' alignItems='center'>
            <TabletButton
              data-track='fs-incVol-invite-volunteers-button'
              variant='contained'
              color='primary'
              onClick={onClickSendReminder}
              startIcon={<EmailIcon color='inherit' />}
            >
              Send Reminder
            </TabletButton>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

interface IncomingVolunteersFilterDialogButtonProps {
  selectedFilters: IncomingVolunteersFilters;
  setSelectedFilters: (x: IncomingVolunteersFilters) => void;
  programs?: IncomingVolsFilterProgram[];
}

const IncomingVolunteersFilterDialogButton = memo((props: IncomingVolunteersFilterDialogButtonProps) => {
  const { selectedFilters, setSelectedFilters, programs } = props;
  const [openFiltersDialog, setOpenFiltersDialog] = useState<boolean>(false);
  return (
    <Grid item>
      <IconButton aria-controls='incoming-volunteers-filters-menu' onClick={() => setOpenFiltersDialog(true)}>
        <FilterList />
      </IconButton>
      {openFiltersDialog && (
        <MobileFiltersDialog
          open
          onClose={() => setOpenFiltersDialog(false)}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          programs={programs}
        />
      )}
    </Grid>
  );
});

interface IncomingVolunteersFilterActionsMobileProps {
  selectedFilters: IncomingVolunteersFilters;
  setSelectedFilters: (x: IncomingVolunteersFilters) => void;
  onInviteClick: () => void;
  setSearchIncomingVolunteersInput: (x: string) => void;
  filteredVolunteers: any;
  programs?: IncomingVolsFilterProgram[];
  onClickSendReminder: () => void;
  handleExport: () => void;
}

const IncomingVolunteersFilterActionsMobile = (props: IncomingVolunteersFilterActionsMobileProps) => {
  const {
    selectedFilters,
    setSelectedFilters,
    setSearchIncomingVolunteersInput,
    onInviteClick,
    handleExport,
    programs,
    onClickSendReminder,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { isMobile, theme, isMd } = useCampfireTheme();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <Grid
      item
      container
      spacing={2}
      direction='row'
      wrap='nowrap'
      alignItems='center'
      style={{ marginTop: 8, marginBottom: 8, marginLeft: 16, marginRight: 16 }}
    >
      <Grid item>
        <SearchField
          placeholder='Search'
          onChange={(e) => setSearchIncomingVolunteersInput(e.target.value)}
          fullWidth
          growLeft
        />
      </Grid>
      <IncomingVolunteersFilterDialogButton
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        programs={programs}
      />
      <Grid item>
        <IconButton
          aria-controls='incoming-volunteers-action-menu'
          aria-haspopup='true'
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreVert />
        </IconButton>

        <Menu
          id='activity-console-action-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              onInviteClick();
              handleClose();
            }}
          >
            {'Invite Volunteers'}
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClickSendReminder();
              handleClose();
            }}
          >
            Send Reminder
          </MenuItem>
          <MenuItem>
            <Box ml={1} className={classes.downloadBtnWrapper}>
              <TabletButton
                variant='outlined'
                style={{
                  color: theme.color.grey.neutral400,
                  boxSizing: 'border-box',
                  borderColor: '#dcdcdc',
                  borderRadius: '4px',
                  textTransform: 'none',
                  padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
                  minWidth: '40px',
                  marginRight: '10px',
                }}
                onClick={handleExport}
              >
                <GetAppOutlined style={{ marginTop: '1px', fontSize: '20px' }} />
                <Typography
                  style={{
                    marginLeft: '4px',
                    fontWeight: 600,
                    fontSize: '13px',
                    lineHeight: '19px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: isMobile || isMd ? 'none' : 'inherit',
                  }}
                >
                  Export
                </Typography>
              </TabletButton>
            </Box>
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};

interface IncomingVolunteersListProps {
  incomingVolunteers: IncomingVolunteerType[];
  reload?: () => void;
  selectedFilters: IncomingVolunteersFilters;
  setSelectedFilters: (value: IncomingVolunteersFilters) => void;
  programs?: IncomingVolsFilterProgram[];
  view: number;
  page: number;
  setView: (view: number) => void;
  setPage: (page: number) => void;
  pagination?: {
    total: number;
    offset: number;
    limit: number;
  };
  handleExport: () => void;
}

export const IncomingVolunteersList = memo((props: IncomingVolunteersListProps) => {
  const {
    incomingVolunteers,
    reload,
    selectedFilters,
    setSelectedFilters,
    programs,
    view,
    page,
    setView,
    setPage,
    pagination,
    handleExport,
  } = props;
  const { searchIncomingVolunteersInput, setSearchIncomingVolunteersInput } = useVolunteersContext();
  const { isMobile } = useCampfireTheme();
  const { inviteDialog, setInviteDialog, setRemindAllDialog } = useVolunteersContext();
  const classes = useStyles();

  const isListFiltered = useMemo(() => {
    return (
      selectedFilters.selectedStatusFilter !== DEFAULT_FILTERS.selectedStatusFilter ||
      selectedFilters.selectedTypeFilter !== DEFAULT_FILTERS.selectedTypeFilter ||
      (!!searchIncomingVolunteersInput && searchIncomingVolunteersInput.length >= 3) ||
      selectedFilters.selectedProgramFilter !== DEFAULT_FILTERS.selectedProgramFilter
    );
  }, [selectedFilters, searchIncomingVolunteersInput]);

  useEffect(() => {}, [selectedFilters, searchIncomingVolunteersInput, incomingVolunteers]);

  const onClickSendReminder = () => {
    setRemindAllDialog(true);
  };

  const onPrev = () => {
    setPage(Math.max(page - 1, 0));
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const isNextDisabled = pagination?.total === (pagination?.offset || 0) + incomingVolunteers.length;

  return (
    <>
      <Box display='flex' flexDirection='column' minHeight={0}>
        <Box>
          <Box width={1}>
            {isMobile ? (
              <IncomingVolunteersFilterActionsMobile
                selectedFilters={selectedFilters}
                filteredVolunteers={incomingVolunteers}
                setSelectedFilters={setSelectedFilters}
                setSearchIncomingVolunteersInput={setSearchIncomingVolunteersInput}
                onInviteClick={() => setInviteDialog(true)}
                programs={programs}
                onClickSendReminder={onClickSendReminder}
                handleExport={handleExport}
              />
            ) : (
              <IncomingVolunteersFilterActions
                selectedFilters={selectedFilters}
                filteredVolunteers={incomingVolunteers}
                setSelectedFilters={setSelectedFilters}
                setSearchIncomingVolunteersInput={setSearchIncomingVolunteersInput}
                programs={programs}
                onClickSendReminder={onClickSendReminder}
                handleExport={handleExport}
              />
            )}
            <Divider />
          </Box>
        </Box>
        <Box flex={1} style={{ overflowY: 'scroll' }} display='flex'>
          <Box
            style={{
              paddingLeft: isMobile ? 16 : 64,
              paddingRight: isMobile ? 16 : 64,
              paddingTop: 16,
              paddingBottom: 16,
              width: '100%',
            }}
          >
            <IncomingVolunteersPaginationDropdown
              fullstoryId='fs-incVol-pending-view-number-dropdown'
              view={view}
              setView={setView}
              page={page}
              setPage={setPage}
              from={pagination?.offset || 0}
              to={(pagination?.offset || 0) + incomingVolunteers.length}
              total={pagination?.total || 0}
              isNextDisabled={isNextDisabled}
              isListFiltered={isListFiltered}
              filteredListLength={incomingVolunteers.length}
            />
            <Grid item container direction='row' wrap='nowrap' alignItems='center' className={classes.listItem}>
              <Grid item style={{ width: '56px' }}></Grid>
              <Grid item container direction='column' alignContent='center' style={{ flex: 4 }} spacing={1}></Grid>
              <Grid item style={{ flex: 1, display: isMobile ? 'none' : 'block' }}>
                <Typography variant='subtitle2'>Last Updated</Typography>
              </Grid>
            </Grid>
            {incomingVolunteers.map((listItem) =>
              listItem ? (
                <IncomingVolunteersListItem key={listItem.incomingVolunteerId} volunteerRowContent={listItem} />
              ) : null
            )}
            {incomingVolunteers.length < 10 ? (
              <Box
                height={1}
                width={1}
                paddingTop={4}
                paddingBottom={3}
                display='flex'
                justifyContent='center'
                alignContent='center'
                alignItems='center'
              >
                <Box maxWidth={480} textAlign='center'>
                  <Typography variant='body2' color='textSecondary'>
                    {`Can't find who you're looking for?`}
                    <br />
                    {`They might have finished their profile already,`}
                    <br />
                    {`try the Volunteer Database`}
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box display='flex' justifyContent='end' style={{ gap: '1rem' }} paddingRight='1rem'>
          <TabletButton size='medium' variant='outlined' onClick={onPrev} disabled={page === 1}>
            {'Previous'}
          </TabletButton>
          <TabletButton size='medium' variant='outlined' onClick={onNext} disabled={isNextDisabled}>
            {'Next'}
          </TabletButton>
        </Box>
      </Box>
      <InvitationDialog
        open={inviteDialog}
        handleClose={() => setInviteDialog(false)}
        onSuccess={() => {
          setInviteDialog(false);
          if (reload) reload();
        }}
      />
    </>
  );
});
