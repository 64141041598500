import React from 'react';
import { DraggableListItem } from '@campfire/draggable-list';
import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { TaskListItemContext } from '../TaskListItemsContext';
import { TaskItem } from './task-items/TaskItem';

interface Props {
  taskItemId: string;
  index: number;
}

export const TaskListItem = (props: Props) => {
  const { taskItemId, index } = props;
  const { taskItems, setFieldValue, draggingId } = React.useContext(TaskListItemContext);
  const data = taskItems.byId[taskItemId];
  const isInsideOption = Boolean(taskItems.byTriggerOption[taskItemId]);
  const isDragging = taskItemId === draggingId;
  const onRemoveTaskTriggerItem = (itemId: string) => {
    const optionId = taskItems.byTriggerOption[itemId];

    setFieldValue('taskItems', {
      ...taskItems,
      byTrigger: {
        ...taskItems.byTrigger,
        [optionId]: {
          ...taskItems.byTrigger[optionId],
          triggerItemIds: taskItems.byTrigger[optionId].triggerItemIds.filter(
            (triggerItemId) => triggerItemId !== itemId
          ),
        },
      },
      byTriggerOption: {
        ...taskItems.byTriggerOption,
        [itemId]: undefined,
      },
    });
  };

  return (
    <DraggableListItem id={taskItemId} index={index} style={isDragging ? { height: '2rem', maxWidth: '100%' } : {}}>
      <Box display='flex' width='100%' alignItems='center'>
        <TaskItem data={data} />
        {isInsideOption && (
          <IconButton onClick={() => onRemoveTaskTriggerItem(taskItemId)}>
            <Close />
          </IconButton>
        )}
      </Box>
    </DraggableListItem>
  );
};
