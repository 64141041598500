import React, { useState } from 'react';
import { Box, Checkbox, Tabs, IconButton, Menu, MenuItem, Icon, Typography } from '@material-ui/core';
import { AccessTime, FilterList as FilterIcon, PeopleOutline } from '@material-ui/icons';
import { useQueryParam, StringParam, encodeDate } from 'use-query-params';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '../../../theme/src/calendar.css';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';
import {
  ManagementDashboardCalendarData,
  ManagementDashboardCalendarDataVariables,
} from './__generated__/ManagementDashboardCalendarData';
import { MANAGEMENT_DASHBOARD_CALENDAR_DATA } from './management-dashboard-calendar.gql';
import { useSharedCalendar } from './useCalendar';

const ACTIVITY_PATH = '/management/rosters';

export const ManagementCalendar = () => {
  const { theme, isMobile, isSm } = useCampfireTheme();
  const [getActivities, { data: getDashboardData }] = useCampfireLazyQuery<
    ManagementDashboardCalendarData,
    ManagementDashboardCalendarDataVariables
  >(MANAGEMENT_DASHBOARD_CALENDAR_DATA);

  const fetchGQL = (dateInfo: any) => {
    let viewDateStart;
    let viewDateEnd;

    if (dateInfo.view.type === 'timeGridDay') {
      viewDateStart = ((date) => date.setDate(date.getDate()) && date)(new Date(dateInfo.start));
      viewDateEnd = ((date) => date.setDate(date.getDate()) && date)(new Date(dateInfo.end));
    }
    if (dateInfo.view.type === 'timeGridWeek') {
      viewDateStart = ((date) => date.setDate(date.getDate() - 1) && date)(new Date(dateInfo.start));
      viewDateEnd = ((date) => date.setDate(date.getDate() + 1) && date)(new Date(dateInfo.end));
    }
    if (dateInfo.view.type === 'dayGridMonth') {
      viewDateStart = ((date) => date.setDate(date.getDate() - 7) && date)(new Date(dateInfo.start));
      viewDateEnd = ((date) => date.setDate(date.getDate() + 7) && date)(new Date(dateInfo.end));
    }
    const eventsDateStart = encodeDate(new Date(viewDateStart || ''));
    const eventsDateEnd = encodeDate(new Date(viewDateEnd || ''));

    getActivities({
      variables: {
        dateFrom: eventsDateStart,
        dateUntil: eventsDateEnd,
      },
    });
  };

  const renderVolunteerIcon = () => {
    return (
      <Icon>
        <PeopleOutline fontSize='small' />
      </Icon>
    );
  };

  const renderRosterIcon = () => {
    return (
      <Icon>
        <AccessTime fontSize='small' />
      </Icon>
    );
  };

  const activities = getDashboardData?.vm.activities ?? [];

  const filteredActivities = activities.map((a) => ({
    ...a,
    occurrencesBetween: a.occurrencesBetween.filter(
      (occurrence) => !a.cancelledActivities.find((ca) => ca.activityDate === occurrence)
    ),
  }));

  const renderEventContentDay = (eventInfo: any) => {
    return (
      <Box overflow={'hidden'} textOverflow={'ellipsis'} padding={'10px'} display={'flex'}>
        <Box alignItems='center'>{eventInfo.event.extendedProps.icon}</Box>
        <Box alignItems='center' paddingLeft='5px'>
          <Typography style={{ fontWeight: 'bold', fontSize: '13px' }}>{eventInfo.event.title}</Typography>
          <Box>
            <Typography style={{ fontSize: '13px', paddingTop: '2px' }}>
              {eventInfo.event.extendedProps.description}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderEventContentWeek = (eventInfo: any) => {
    return (
      <Box overflow={'hidden'} textOverflow={'ellipsis'} padding={'10px'}>
        <Typography style={{ display: 'inline-block', fontWeight: 'bold', fontSize: '13px' }}>
          {eventInfo.event.title}
        </Typography>
        <Typography style={{ fontSize: '13px', paddingTop: '2px' }}>
          {eventInfo.event.extendedProps.description}
        </Typography>
      </Box>
    );
  };

  const renderEventContentMonth = (eventInfo: any) => {
    return (
      <Box overflow={'hidden'} textOverflow={'ellipsis'} padding={'8px 10px'} display={'flex'}>
        <Typography style={{ display: 'inline-block', fontWeight: 'normal', fontSize: '13px', overflowX: 'hidden' }}>
          {eventInfo.event.title}
        </Typography>
        <Typography style={{ paddingLeft: '7px', fontSize: '13px', fontWeight: 'bold' }}>
          {eventInfo.event.extendedProps.volsNeeded}
        </Typography>
      </Box>
    );
  };

  const { selectedActionFilters } = useSharedCalendar();

  const selectedFilterActivities = filteredActivities.map((a) => ({
    ...a,
    occurrencesBetween: a.occurrencesBetween.filter((occurrence) =>
      selectedActionFilters.rostersNeeded && selectedActionFilters.notEnoughVolunteers
        ? !a.publishedRosters.find((pr) => pr.activityDate === occurrence) ||
          a.minVolunteers - a.activityEnrolments?.filter((e) => e.dateRemoved === null).length > 0
        : selectedActionFilters.rostersNeeded
        ? !a.publishedRosters.find((pr) => pr.activityDate === occurrence)
        : selectedActionFilters.notEnoughVolunteers
        ? a.minVolunteers - a.activityEnrolments?.filter((e) => e.dateRemoved === null).length > 0
        : occurrence && selectedActionFilters.allActivities
        ? occurrence
        : null
    ),
  }));

  const formatRequiredVolunteersMessage = (required: number) => {
    switch (required) {
      case 0:
        return null;
      case 1:
        return '1 volunteer needed';
      default:
        return `${required} volunteers needed`;
    }
  };

  const eventArray = selectedFilterActivities.flatMap((a) => {
    const activeEnrolments = a.activityEnrolments?.filter((e) => e.dateRemoved === null).length;
    const requiredVolunteers = a.minVolunteers - activeEnrolments;
    if (a.__typename === 'VOLUNTEER_RecurringActivityType') {
      const actionRequired = true;
      if (a.isActive && !a.isSuspended && !a.program.dateDeleted && !a.program.dateSuspended && actionRequired) {
        return a.occurrencesBetween.map((date) => ({
          title: a.name,
          url: `${ACTIVITY_PATH}${'?activityDate='}${date}${'&activityId='}${a.activityId}`,
          start: `${date} ${a.startTime}`,
          end: `${date} ${a.endTime}`,
          display: 'block',
          backgroundColor: !a.publishedRosters.find((pr) => pr.activityDate === date)
            ? theme.color.activities.rostersNeededBackground
            : requiredVolunteers > 0
            ? theme.color.activities.volunteersNeededBackground
            : theme.color.activities.activityBackground,
          textColor: !a.publishedRosters.find((pr) => pr.activityDate === date)
            ? theme.color.activities.rostersNeededForeground
            : requiredVolunteers > 0
            ? theme.color.activities.volunteersNeededForeground
            : theme.color.activities.activityForeground,
          extendedProps: {
            icon: !a.publishedRosters.find((pr) => pr.activityDate === date)
              ? renderRosterIcon()
              : requiredVolunteers > 0
              ? renderVolunteerIcon()
              : null,
            description: requiredVolunteers > 0 ? formatRequiredVolunteersMessage(requiredVolunteers) : null,
            volsNeeded: requiredVolunteers > 0 ? requiredVolunteers : null,
          },
        }));
      }
    }
    if (a.__typename === 'VOLUNTEER_NonRecurringActivityType') {
      const actionRequired = true;
      if (a.isActive && !a.isSuspended && !a.program.dateDeleted && !a.program.dateSuspended && actionRequired) {
        return {
          title: a.name,
          url: `${ACTIVITY_PATH}${'?activityDate='}${a.startDate}${'&activityId='}${a.activityId}`,
          start: `${a.startDate} ${a.startTime}`,
          end: `${a.startDate} ${a.endTime}`,
          display: 'block',
          backgroundColor: '#FFEDF7',
          textColor: '#B5557D',
          extendedProps: {
            icon: !a.publishedRosters.find((pr) => pr.activityDate === a.startDate)
              ? renderRosterIcon()
              : requiredVolunteers > 0
              ? renderVolunteerIcon()
              : null,
            description: formatRequiredVolunteersMessage(requiredVolunteers),
            volsNeeded: requiredVolunteers > 0 ? requiredVolunteers : null,
          },
        };
      }
    }
    return {};
  });

  return (
    <Box flexGrow={1} paddingTop={'25px'}>
      <FullCalendar
        initialView='timeGridDay'
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
        allDaySlot={false}
        nowIndicator
        height='auto'
        eventBorderColor={'rgba(255,255,255,0'}
        headerToolbar={{
          start: isMobile ? 'prev' : isSm ? 'prev,next' : 'prev,next today',
          center: 'title',
          end: isMobile ? 'next' : 'timeGridDay,timeGridWeek,dayGridMonth',
        }}
        handleWindowResize
        events={eventArray}
        datesSet={fetchGQL}
        locale='au'
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
        }}
        views={{
          day: {
            eventContent: renderEventContentDay,
          },
          week: {
            eventContent: renderEventContentWeek,
          },
          month: {
            eventContent: renderEventContentMonth,
            dayMaxEvents: 2,
          },
        }}
      />
    </Box>
  );
};

export function ManagementCalendarTab() {
  const { theme } = useCampfireTheme();
  const [selectedTab, setSelectedTab] = useQueryParam('calendarTab', StringParam);

  const { selectedActionFilters, setSelectedActionFilters } = useSharedCalendar();

  const handleSelect = (filter: { [key: string]: boolean }) =>
    setSelectedActionFilters({ ...selectedActionFilters, ...filter });
  const [menuAnchor, setMenuAnchor] = useState(null);

  return (
    <Tabs
      indicatorColor='primary'
      value={selectedTab === 'all' ? 1 : 0}
      aria-label='management dashboard tabs'
      style={{
        borderBottom: `1px solid ${theme.color.grey.border}`,
      }}
    >
      {/* <Tab
          disableRipple
          disableTouchRipple
          key={'required'}
          label={'Action Required'}
          onClick={() => {
            setSelectedTab('required');
            selectedActionFilters.rostersNeeded = true;
            selectedActionFilters.notEnoughVolunteers = true;
            selectedActionFilters.allActivities = false;
          }}
        />
        <Tab
          disableRipple
          disableTouchRipple
          key={'all'}
          label={'All Activities'}
          onClick={() => {
            setSelectedTab('all');
            selectedActionFilters.rostersNeeded = false;
            selectedActionFilters.notEnoughVolunteers = false;
            selectedActionFilters.allActivities = true;
          }}
        /> */}
      <Box display={'flex'} justifyContent={'flex-end'} flex={1}>
        <IconButton onClick={(e: any) => setMenuAnchor(e.currentTarget)}>
          <FilterIcon />
        </IconButton>
        <Menu
          id={'filter-menu'}
          anchorEl={menuAnchor}
          keepMounted
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
        >
          <MenuItem
            onClick={() =>
              handleSelect({
                rostersNeeded: !selectedActionFilters.rostersNeeded,
              })
            }
            disabled={selectedActionFilters.allActivities}
          >
            <Checkbox
              color='primary'
              checked={selectedActionFilters.allActivities ? false : selectedActionFilters.rostersNeeded}
            />
            {'Rosters needed'}
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleSelect({
                notEnoughVolunteers: !selectedActionFilters.notEnoughVolunteers,
              })
            }
            disabled={selectedActionFilters.allActivities}
          >
            <Checkbox
              color='primary'
              checked={selectedActionFilters.allActivities ? false : selectedActionFilters.notEnoughVolunteers}
            />
            {'Not enough volunteers'}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedTab(!selectedActionFilters.allActivities ? 'all' : 'required');
              selectedActionFilters.rostersNeeded = !!selectedActionFilters.allActivities;
              selectedActionFilters.notEnoughVolunteers = !!selectedActionFilters.allActivities;
              selectedActionFilters.allActivities = !selectedActionFilters.allActivities;
            }}
          >
            <Checkbox color='primary' checked={selectedActionFilters.allActivities} />
            {'All Activities'}
          </MenuItem>
        </Menu>
      </Box>
    </Tabs>
  );
}
