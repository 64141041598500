import { HoverText } from '@campfire/hover-link';
import { Box, Grid, Popover, TextField, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { memo, useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useFilterStyles } from '../../../../../common/filter-fields/FilterClasses';
import {
  areInitialTimeFiltersDefault,
  Filters,
  TIME_FILTER_END_TIME_URL_PARAM_KEY,
  TIME_FILTER_START_TIME_URL_PARAM_KEY,
} from '../../../../../common/filter-fields/filters';

type InnerTimeFilterSelectPopoverProps = {
  isDefaultTimes?: boolean;
  onClose?: () => void;
  start: string | undefined;
  end: string | undefined;
  setStart: (value: string | undefined) => void;
  setEnd: (value: string | undefined) => void;
  handleClearTimes: () => void;
};

const InnerTimeFilterSelectPopover = (props: InnerTimeFilterSelectPopoverProps) => {
  const { start, end, setStart, setEnd, handleClearTimes, isDefaultTimes } = props;

  return (
    <Box paddingY={1}>
      <Box display='flex' flex='1 1 auto'>
        <TextField
          id='startTime'
          label='Start time'
          type='time'
          variant='outlined'
          style={{ marginRight: 8, minWidth: 196 }}
          value={start ?? ''}
          color='primary'
          onChange={(event) => setStart(event.target.value)}
          onBlur={() => {
            if (!start) return;
            setStart(start);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />

        <TextField
          id='endTime'
          label='End time'
          type='time'
          variant='outlined'
          style={{ minWidth: 196 }}
          value={end ?? ''}
          onChange={(event) => setEnd(event.target.value)}
          onBlur={() => {
            if (!end) return;
            setEnd(end);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />
      </Box>

      <Box display='flex' flex='1 0 auto' justifyContent='flex-end' marginTop={1}>
        <HoverText disabled={isDefaultTimes} color='error' variant='body2' onClick={handleClearTimes}>
          Clear time filters
        </HoverText>
      </Box>
    </Box>
  );
};

type TimeFilterSelectPopoverProps = {
  selectedFilters: Filters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  isDefaultTimes?: boolean;
  onClose?: () => void;
};

const TimeFilterSelectPopover = memo(({ selectedFilters, setSelectedFilters }: TimeFilterSelectPopoverProps) => {
  const [anchorElTimePeriod, setAnchorElTimePeriod] = useState<(EventTarget & any) | null>(null);
  const [displayTimePeriodPopover, toggleDisplayTimePeriodPopover] = useState(false);
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const { selectedTimeFilter } = selectedFilters;

  const fromTimeFormatted = DateTime.fromJSDate(selectedTimeFilter.startTime)
    .toFormat('h:mm a')
    .toLowerCase();
  const toTimeFormatted = DateTime.fromJSDate(selectedTimeFilter.endTime)
    .toFormat('h:mm a')
    .toLowerCase();

  const isDefaultTimes = areInitialTimeFiltersDefault(selectedTimeFilter);

  const timeFilterDisplayText = isDefaultTimes ? 'Any time' : `${fromTimeFormatted} - ${toTimeFormatted}`;

  function handleOnClose() {
    toggleDisplayTimePeriodPopover(false);
  }

  const [start, setStart] = useQueryParam<string | undefined>(TIME_FILTER_START_TIME_URL_PARAM_KEY);
  const [end, setEnd] = useQueryParam<string | undefined>(TIME_FILTER_END_TIME_URL_PARAM_KEY);

  function handleClearTimes() {
    setStart(undefined);
    setEnd(undefined);
  }

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      selectedTimeFilter: {
        startTime: DateTime.fromFormat(start ?? '00:00', 'HH:mm').toJSDate(),
        endTime: DateTime.fromFormat(end ?? '23:59', 'HH:mm').toJSDate(),
      },
    }));
  }, [start, end]);

  return (
    <Grid item className={classes.subheaderGridItemNew}>
      <Box
        data-track={`actCnlMapFilters-TimeFilterSelectPopover`}
        borderRadius='5px'
        border={isDefaultTimes ? '1px solid #ced4da' : `1px solid ${theme.palette.primary.main}`}
        padding='5.5px 0px 5.5px 12px'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        onClick={(e) => {
          setAnchorElTimePeriod(e.currentTarget);
          toggleDisplayTimePeriodPopover(true);
        }}
      >
        <Typography
          variant='body2'
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: isDefaultTimes ? 'rgba(0,0,0, 0.87)' : theme.palette.primary.main,
          }}
        >
          {timeFilterDisplayText}
        </Typography>
        <ArrowDropDown style={{ fill: isDefaultTimes ? 'rgba(0,0,0, 0.54)' : theme.palette.primary.main }} />
      </Box>
      {anchorElTimePeriod !== null && (
        <Popover
          open={displayTimePeriodPopover}
          anchorEl={anchorElTimePeriod}
          onClose={handleOnClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box padding={2}>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <InnerTimeFilterSelectPopover
                start={start}
                end={end}
                setStart={setStart}
                setEnd={setEnd}
                isDefaultTimes={isDefaultTimes}
                onClose={handleOnClose}
                handleClearTimes={handleClearTimes}
              />
            </Box>
          </Box>
        </Popover>
      )}
    </Grid>
  );
});

export { TimeFilterSelectPopover };
