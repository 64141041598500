import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button/lib';
import { useHistory, useRouteMatch } from 'react-router';
import { goBackBySlashes } from '@campfire/world-wide-web/lib';
import { useSupportContext } from '../../SupportContext';

interface Props {
  supportResourceId: string;
  open: boolean;
  close: () => void;
}

const DeleteResourceDialog = (props: Props) => {
  const { supportResourceId, open, close } = props;
  const { runDeleteSupportResource, deleteSupportResourceIsLoading } = useSupportContext();
  const match = useRouteMatch();
  const history = useHistory();

  const handleDelete = () => {
    runDeleteSupportResource(supportResourceId).then((res) => {
      if (res.ok && match) {
        goBackBySlashes(history, match);
        close();
      }
    });
  };

  return (
    <Dialog open={open} fullWidth maxWidth='sm'>
      <DialogTitle>{'Delete this resource?'}</DialogTitle>
      <DialogContent>
        <Typography variant='body2'>{'Once deleted, this resource will not be retrievable.'}</Typography>
      </DialogContent>

      <DialogActions>
        <TabletButton data-track='resources-delete-resource-dialog-go-back-button' onClick={close}>
          {'Go Back'}
        </TabletButton>
        <TabletButton
          data-track='resources-delete-resource-dialog-delete-button'
          variant='outlined'
          color='error'
          disabled={deleteSupportResourceIsLoading}
          onClick={handleDelete}
        >
          {'Delete Resource'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { DeleteResourceDialog };
