import React, { useMemo } from 'react';
import { Typography, Box, Table, TableBody, TableRow, TableCell, Grid, Button, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { ThumbUp, ThumbDown, Add, Remove } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import { DateTime } from 'luxon';
import { unpackToTime } from '@campfire/hot-date';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { FileUploadGallery, UploadedFile } from '@campfire/file-upload-gallery/lib';
import { AvatarName, VolunteerProfile } from '../../../../../../common/AvatarName';
import { sharedStyles } from '../../../../../general/activities-v2/ActivityStyles/shared';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

const useSessionStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.color.grey.neutral400,
      border: 'none',
      textTransform: 'uppercase',
      fontSize: '12px',
      fontWeight: 500,
    },
  })
);

function ReportDescription({ report }: { report: any }) {
  const { theme } = useCampfireTheme();

  const firstCheckIn = useMemo(() => {
    if (!report || !report.CICOs || report.CICOs.length === 0) return null;
    const CICOsSorted = report.CICOs.sort((a: any, b: any) =>
      DateTime.fromFormat(a.checkIn, 'hh:mm:ss') < DateTime.fromFormat(b.checkIn, 'hh:mm:ss') ? -1 : 1
    );
    return CICOsSorted[0].checkIn;
  }, [report]);

  const lastCheckOut = useMemo(() => {
    if (!report || !report.CICOs || report.CICOs.length === 0) return null;
    const CICOsSorted = report.CICOs.sort((a: any, b: any) =>
      DateTime.fromFormat(a.checkOut, 'hh:mm:ss') < DateTime.fromFormat(b.checkOut, 'hh:mm:ss') ? -1 : 1
    );
    return CICOsSorted[CICOsSorted.length - 1].checkOut;
  }, [report]);

  return (
    <Box>
      <Box
        style={{
          borderBottom: `1px solid ${theme.color.grey.neutralBrand200}`,
          padding: '0px 8px 20px',
          color: theme.color.grey.neutral500,
        }}
      >
        <Grid container justify={'space-between'} alignItems='center'>
          <Grid item xs>
            <Box display='flex' py={1} style={{ borderRight: `1px solid ${theme.color.grey.neutralBrand200}` }}>
              <Typography style={{ marginRight: '24px', fontSize: 14, fontWeight: 600 }}>Time</Typography>
              <Typography style={{ fontSize: 14 }}>
                {report?.allowCICO && firstCheckIn && lastCheckOut
                  ? `${unpackToTime(firstCheckIn).toFormat('h:mm a')} - ${unpackToTime(lastCheckOut).toFormat(
                      'h:mm a'
                    )}`
                  : report.startTime &&
                    report.endTime &&
                    `${unpackToTime(report.startTime).toFormat('h:mm a')} - ${unpackToTime(report.endTime).toFormat(
                      'h:mm a'
                    )}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box display='flex' pl={2}>
              <Typography style={{ marginRight: '24px', fontSize: 14, fontWeight: 600 }}>Operation Hours</Typography>
              <Typography style={{ fontSize: 14 }}>
                {report?.allowCICO && firstCheckIn && lastCheckOut
                  ? Math.abs(unpackToTime(firstCheckIn).diff(unpackToTime(lastCheckOut), 'hours').hours).toFixed(2)
                  : report.startTime &&
                    report.endTime &&
                    Math.abs(
                      DateTime.fromFormat(report.startTime, 'hh:mm:ss').diff(
                        DateTime.fromFormat(report.endTime, 'hh:mm:ss'),
                        'hours'
                      ).hours
                    ).toFixed(2)}
              </Typography>
            </Box>
            {/* <Box display='flex' pl={2}>
              <Typography style={{ marginRight: '24px', fontSize: 14, fontWeight: 600 }}>Volunteer Hours</Typography>
              <Typography style={{ fontSize: 14 }}>
                {report?.allowCICO && firstCheckIn && lastCheckOut
                  ? Math.abs(unpackToTime(firstCheckIn).diff(unpackToTime(lastCheckOut), 'hours').hours).toFixed(2)
                  : report.startTime &&
                    report.endTime &&
                    Math.abs(
                      DateTime.fromFormat(report.startTime, 'hh:mm:ss').diff(
                        DateTime.fromFormat(report.endTime, 'hh:mm:ss'),
                        'hours'
                      ).hours
                    ).toFixed(2)}
              </Typography>
            </Box> */}
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          borderBottom: `1px solid ${theme.color.grey.neutralBrand200}`,
          padding: '0px 8px 20px',
          color: theme.color.grey.neutral500,
          marginTop: 16,
        }}
      >
        <Typography style={{ fontSize: 14, fontWeight: 600 }}>Attendances</Typography>
        <Box>
          {report?.allowCICO && report?.CICOs?.length ? (
            report?.CICOs?.map((cico: any) => (
              <Box marginTop='1rem' display='flex' alignItems='center'>
                <Box minWidth={'40%'}>
                  <VolunteerProfile
                    noTrim
                    profileId={cico.volunteer.profile.profileId}
                    userId={cico.volunteer.profile.userId}
                    lastName={cico.volunteer.profile.lastName}
                    preferredName={cico.volunteer.profile.preferredName}
                    name={`${cico.volunteer.profile.preferredName} ${cico.volunteer.profile.lastName}`}
                    avatarUrl={cico.volunteer.profile.avatarUrl}
                  />
                </Box>
                <Typography style={{ marginLeft: '24px', fontSize: '14px' }}>{`${DateTime.fromFormat(
                  cico.checkIn,
                  'hh:mm:ss'
                ).toFormat('h:mm a')} - ${DateTime.fromFormat(cico.checkOut, 'hh:mm:ss').toFormat(
                  'h:mm a'
                )} (${Math.abs(
                  DateTime.fromFormat(cico.checkIn, 'hh:mm:ss').diff(
                    DateTime.fromFormat(cico.checkOut, 'hh:mm:ss'),
                    'hours'
                  ).hours
                ).toFixed(2)})`}</Typography>
              </Box>
            ))
          ) : !report?.allowCICO && report?.attendances?.length ? (
            report?.attendances?.map((attendance: any) => (
              <Box marginTop='1rem' display='flex' alignItems='center'>
                <Box minWidth={'40%'}>
                  {attendance.__typename === 'VOLUNTEER_VolunteerAttendanceType' ? (
                    <VolunteerProfile
                      noTrim
                      profileId={attendance.volunteer.profile.profileId}
                      userId={attendance.volunteer.profile.userId}
                      lastName={attendance.volunteer.profile.lastName}
                      preferredName={attendance.volunteer.profile.preferredName}
                      name={`${attendance.volunteer.profile.preferredName} ${attendance.volunteer.profile.lastName}`}
                      avatarUrl={attendance.volunteer.profile.avatarUrl}
                    />
                  ) : (
                    <AvatarName name={attendance.name} />
                  )}
                </Box>
                <Typography style={{ marginLeft: '24px', fontSize: '14px' }}>{`${unpackToTime(
                  report.startTime
                ).toFormat('h:mm a')} - ${unpackToTime(report.endTime).toFormat('h:mm a')}`}</Typography>
              </Box>
            ))
          ) : (
            <Typography>No attendance</Typography>
          )}
        </Box>
      </Box>
      <Box
        style={{
          borderBottom: `1px solid ${theme.color.grey.neutralBrand200}`,
          padding: '0px 8px 20px',
          color: theme.color.grey.neutral500,
          marginTop: 16,
        }}
      >
        <Typography style={{ fontSize: 14, fontWeight: 600 }}>Journal</Typography>
        <Typography style={{ fontSize: 14, marginTop: 16 }}>{report.comments}</Typography>
      </Box>
    </Box>
  );
}

function ReportValuesTable({ values }: { values: any[] }) {
  const { theme } = useCampfireTheme();

  return (
    <Table style={{ marginTop: '1rem' }}>
      <TableBody>
        {values.map((value: any) => {
          if (value?.heading) {
            return (
              <TableRow>
                <TableCell colSpan={2} style={{ padding: '16px 8px 0', borderBottom: 'none' }}>
                  <Typography style={{ fontSize: 14, fontWeight: 600, color: theme.color.grey.neutral500 }}>
                    {value?.heading}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          }
          return (
            <TableRow key={value?.fieldValueId}>
              <TableCell style={{ padding: '16px 8px' }}>
                <TitularTooltip title={value?.field?.name} description={value?.field?.description}>
                  <span>{value?.field?.name}</span>
                </TitularTooltip>
              </TableCell>
              <TableCell>
                {(() => {
                  switch (value.__typename) {
                    case 'VOLUNTEER_BooleanFieldValueType':
                      return value.boolValue ? <ThumbUp /> : <ThumbDown />;
                    case 'VOLUNTEER_NumericFieldValueType':
                      return value.numericValue;
                    case 'VOLUNTEER_TextFieldValueType':
                    case 'VOLUNTEER_TimeFieldValueType':
                      return value.textValue || value.timeValue;
                    case 'VOLUNTEER_RatingFieldValueType':
                      return <Rating value={value.ratingValue} readOnly />;
                    case 'VOLUNTEER_AttachmentFieldValueType':
                      return <AttachmentFieldValue attachments={value.attachments} />;
                    case 'VOLUNTEER_DropdownFieldValueType':
                      return value.dropdownFieldOptions?.map((option: any) => option.name).join(', ');
                    default:
                      return null;
                  }
                })()}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

const AttachmentFieldValue = ({ attachments }: { attachments: UploadedFile[] }) => {
  return (
    <Grid container alignItems='center'>
      <FileUploadGallery allowMultiple viewOnly uploadedFiles={attachments} />
    </Grid>
  );
};

export function ActivityReport({ session }: { session: any }) {
  const classes = useSessionStyles();
  const { theme } = useCampfireTheme();
  const buttonClasses = sharedStyles.button();
  const [showMore, setShowMore] = React.useState(false);

  return (
    <Box>
      {session?.reports?.map((report: any) => {
        const reportFieldValuesMap = report?.fieldValues?.reduce(
          (acc: any, fieldValue: any) => ({
            ...acc,
            [fieldValue?.field?.fieldId]: fieldValue,
          }),
          {}
        );

        const reportValues =
          report.session?.reportType === null
            ? session.report
              ? [...report.field]
              : []
            : [...report?.session?.reportType?.items]
                .sort((a, b) => a.order - b.order)
                .filter(
                  (item) =>
                    item.__typename !== 'VOLUNTEER_ReportTypeFieldType' || reportFieldValuesMap[item?.field?.fieldId]
                )
                .map((item) => {
                  if (item.__typename === 'VOLUNTEER_ReportTypeFieldType') {
                    return reportFieldValuesMap[item?.field?.fieldId];
                  }
                  return item;
                });

        const oldReportValues =
          report?.fieldValues?.filter((fieldValue: any) => {
            return !report?.session?.reportType?.items?.find(
              (item: any) => item?.field?.fieldId === fieldValue?.field?.fieldId
            );
          }) || [];

        return (
          <Box>
            <Box>
              <ReportDescription report={report} />
            </Box>
            <Box>
              <ReportValuesTable values={reportValues} />
            </Box>
            {oldReportValues.length > 0 && (
              <Box marginTop={'2em'} marginBottom={'-.5em'}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: showMore ? 'flex-start' : 'center',
                  }}
                >
                  <Box style={{ paddingLeft: '1rem' }}>
                    <Typography
                      variant='h4'
                      style={{ fontSize: '14px', fontWeight: 500, color: theme.color.grey.neutral400 }}
                    >
                      Removed Report Fields
                    </Typography>
                    {showMore ? (
                      <Typography
                        style={{
                          fontSize: '12px',
                          color: theme.color.grey.neutral300,
                          paddingTop: '3px',
                          width: '300px',
                        }}
                      >
                        These report fields were submitted as part of the initial report, but{' '}
                        {oldReportValues.length >= 1 ? 'has' : 'have'} since either been removed or the report type has
                        changed for this session.
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                  {showMore ? (
                    <Button
                      variant='outlined'
                      onClick={() => setShowMore(false)}
                      endIcon={<Remove style={{ fontSize: '16px' }} />}
                      classes={buttonClasses}
                      className={classes.button}
                    >
                      Hide
                    </Button>
                  ) : (
                    <Button
                      variant='outlined'
                      onClick={() => setShowMore(true)}
                      endIcon={<Add style={{ fontSize: '16px' }} />}
                      classes={buttonClasses}
                      className={classes.button}
                    >
                      Show
                    </Button>
                  )}
                </Box>
                {showMore ? <ReportValuesTable values={oldReportValues} /> : ''}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
