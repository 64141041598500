import { gql } from '@apollo/client';

export const PROGRAM_MANAGEMENT_GET_ALL_PROGRAMS = gql`
  query ProgramManagementGetAllPrograms {
    vm {
      programs {
        ...ProgramManagementProgram
      }
    }
  }
  fragment ProgramManagementProgram on VOLUNTEER_ProgramType {
    programId
    name
    isNew
    dateSuspended
  }
`;

export const PROGRAM_MANAGEMENT_GET_ALL_ORG_VOLUNTEERS = gql`
  query ProgramManagementGetAllOrgVolunteers {
    vm {
      volunteers {
        volunteerId
        profile {
          profileId
          userId
          preferredName
          lastName
          avatarUrl
        }
      }
    }
  }
`;

export const PROGRAM_MANAGEMENT_GET_SINGLE_PROGRAM_BASIC_INFO = gql`
  query ProgramManagementGetSingleProgramBasicInfo($programId: String) {
    vm {
      program(programId: $programId) {
        ...ProgramManagementSingleProgramBasicInfo
      }
    }
  }
  fragment ProgramManagementSingleProgramBasicInfo on VOLUNTEER_ProgramType {
    programId
    name
    description
    isHidden
    isRestrictedProgram
    dateSuspended
    activities(includeInactiveActivities: true) {
      activityId
    }
  }
`;

export const PROGRAM_MANAGEMENT_GET_SINGLE_PROGRAM_IMAGE = gql`
  query ProgramManagementGetSingleProgramImage($programId: String) {
    vm {
      program(programId: $programId) {
        programId
        image
      }
    }
  }
`;

export const PROGRAM_MANAGEMENT_GET_PROGRAM_ACTIVITIES = gql`
  query ProgramManagementGetProgramActivities($programId: String) {
    vm {
      program(programId: $programId) {
        programId
        activities(includeInactiveActivities: true) {
          ...ProgramManagementProgramActivity
        }
      }
    }
  }
  fragment ProgramManagementProgramActivity on VOLUNTEER_ActivityType {
    activityId
    name
    startTime
    endTime
    isSuspended
    closedActivity {
      closedActivityId
    }
    ... on VOLUNTEER_RecurringActivityType {
      schedule {
        recurrences {
          humanReadable
        }
      }
    }
  }
`;

export const PROGRAM_MANAGEMENT_GET_MANAGERS_OF_PROGRAM = gql`
  query ProgramManagementGetManagersOfProgram($programId: String) {
    vm {
      program(programId: $programId) {
        programId
        name
        programManagers {
          ...ProgramManagementProgramManager
        }
        activeVolunteers {
          volunteerId
        }
      }
    }
  }
  fragment ProgramManagementProgramManager on VOLUNTEER_ProgramManagerType {
    programManagerId
    dateActivated
    manager {
      managerId
      profile {
        profileId
        userId
        preferredName
        lastName
        avatarUrl
      }
    }
    program {
      programId
      name
    }
  }
`;

export const PROGRAM_MANAGEMENT_GET_ACTIVE_VOLUNTEERS = gql`
  query ProgramManagementGetActiveVolunteers($programId: String) {
    vm {
      program(programId: $programId) {
        programId
        name
        activeVolunteers {
          ...ProgramManagementVolunteer
        }
      }
    }
  }

  fragment ProgramManagementVolunteer on VOLUNTEER_VolunteerType {
    volunteerId
    profile {
      profileId
      userId
      preferredName
      lastName
      avatarUrl
      email
      contactNumber
    }
  }
`;

export const PROGRAM_MANAGEMENT_GET_VOLUNTEER_APPLICATIONS = gql`
  query ProgramManagementGetVolunteerApplications($programId: String) {
    vm {
      program(programId: $programId) {
        programId
        name
        programApplications {
          ...ProgramApplication
        }
      }
    }
  }

  fragment ProgramApplication on VOLUNTEER_ProgramApplicationType {
    programApplicationId
    volunteer {
      volunteerId
      profile {
        profileId
        preferredName
        firstName
        lastName
        avatarUrl
      }
      dateDeactivated
      flagging {
        dateFlagged
      }
    }
    dateApplied
    dateWithdrawn
    dateActioned
  }
`;
