import { gql } from '@apollo/client';

export const GET_COMMUNICATION_TEMPLATE_DETAILS = gql`
  query GetCommunicationTemplateDetails($communicationTypeId: String) {
    vm {
      communicationTemplate(communicationTypeId: $communicationTypeId) {
        communicationTypeId
        name
        description
        status
        content {
          communicationTypeContentId
          content
        }
        stringContent
        stringContentParsed
        contentBlocks {
          communicationTypeCustomContentBlockId
          content
          block
        }
        isHidden
        isOptional
        type
      }
    }
  }
`