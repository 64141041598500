import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import YourInControl from '../../../assets/tutorial-graphics/welcome/youre_in_control.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Incident Management',
    description: (
      <>
        Incident Management provides <strong>access to all incidents</strong> within your organisation.
      </>
    ),
    image: YourInControl,
  },
  {
    label: 'Incident Management',
    description: (
      <>
        <strong>Edit</strong> or <strong>export</strong> incidents according to your organisational needs and use the{' '}
        <strong>status classifications</strong> to track the handling of incidents within your organisation.
      </>
    ),
    image: YourInControl,
  },
];

const IncidentManagementTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { IncidentManagementTutorialDialog };
