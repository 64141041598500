import React, { useEffect } from 'react';
import { useSession } from '../../../global/auth/useSession';
import { Page } from '../../../global/components';

export const SignOutScreen = () => {
  const { logout } = useSession();
  useEffect(() => {
    logout();
  }, []);

  return <Page />;
};
