import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

const ActivitiesExploreScreenSkeleton = () => {
  const { theme, isMobile } = useCampfireTheme();
  const subHeaderHeight = theme.appShell.subHeader.height;
  return (
    <Grid container direction='column'>
      <Grid
        item
        container
        style={{
          borderBottom: `1px solid ${theme.color.grey.border}`,
          minHeight: isMobile ? 'auto' : subHeaderHeight,
          padding: '0 16px',
        }}
        alignItems='center'
        alignContent='center'
      >
        {isMobile ? (
          <Grid item container style={{ margin: '0px 8px' }} justify='flex-end'>
            <Skeleton width={100} height={40} />
          </Grid>
        ) : (
          <>
            <Grid item style={{ margin: '0px 8px' }}>
              <Skeleton width={200} height={35} style={{ borderRadius: 32 }} />
            </Grid>
            <Grid item style={{ margin: '0px 8px' }}>
              <Skeleton width={100} height={40} />
            </Grid>
            <Grid item style={{ margin: '0px 8px' }}>
              <Skeleton width={100} height={40} />
            </Grid>
            <Grid item style={{ margin: '0px 8px' }}>
              <Skeleton width={100} height={40} />
            </Grid>
            <Grid item style={{ margin: '0px 8px' }}>
              <Skeleton width={100} height={40} />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container direction='column' style={{ padding: 24 }}>
        <Grid item>
          <Skeleton width={200} style={{ marginBottom: 4 }} />
          <Skeleton width={70} height={10} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { ActivitiesExploreScreenSkeleton };
