import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Dialog, DialogActions, DialogContent, Divider, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useFilterStyles } from '../../../../../../common/filter-fields/FilterClasses';
import { IncomingVolunteersFilters } from './filters';
import { SortFilterMobile } from './sort-filters/SortFilterMobile';
import { StatusFilterMobile } from './status-filters/StatusFilterMobile';
import { TypeFilterMobile } from './type-filters/TypeFilterMobile';
import { ProgramFilterMobile } from './program-filters/ProgramFilterMobile';
import { IncomingVolsFilterProgram } from '../../__generated__/IncomingVolsFilterProgram';

type MobileFiltersDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedFilters: IncomingVolunteersFilters;
  setSelectedFilters: (x: IncomingVolunteersFilters) => void;
  programs?: IncomingVolsFilterProgram[];
};

const MobileFiltersDialog = ({
  onClose,
  open,
  selectedFilters,
  setSelectedFilters,
  programs,
}: MobileFiltersDialogProps) => {
  const [selectedFiltersLocal, setSelectedFiltersLocal] = useState<IncomingVolunteersFilters>(selectedFilters);
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogContent>
        <Box width={1} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='body1' style={{ fontWeight: 600 }}>
            {'Filters'}
          </Typography>
          <HoverText
            style={{ fontSize: 12, fontWeight: 600 }}
            onClick={() => {
              setSelectedFilters(selectedFilters);
              setSelectedFiltersLocal(selectedFilters);
            }}
          >
            Clear filters
          </HoverText>
        </Box>
        <TypeFilterMobile selectedFilters={selectedFiltersLocal} setSelectedFilters={setSelectedFiltersLocal} />
        <Divider className={classes.divider} />
        <StatusFilterMobile selectedFilters={selectedFiltersLocal} setSelectedFilters={setSelectedFiltersLocal} />
        <Divider className={classes.divider} />
        <SortFilterMobile selectedFilters={selectedFiltersLocal} setSelectedFilters={setSelectedFiltersLocal} />
        <Divider className={classes.divider} />
        <ProgramFilterMobile
          selectedFilters={selectedFiltersLocal}
          setSelectedFilters={setSelectedFiltersLocal}
          programs={programs}
        />
      </DialogContent>
      <DialogActions
        style={{
          borderTop: `1px solid ${theme.palette.grey[100]}`,
          boxShadow: `0 8px 6px -6px ${theme.palette.grey[200]}`,
          padding: 24,
        }}
      >
        <TabletButton
          data-track={`actCnlMapFilters-CancelFilterDialog`}
          variant='text'
          onClick={() => {
            setSelectedFilters(selectedFilters);
            onClose();
          }}
        >
          Cancel
        </TabletButton>
        <TabletButton
          data-track={`actCnlMapFilters-ApplyFilterDialog`}
          variant='contained'
          color='primary'
          onClick={() => {
            setSelectedFilters(selectedFiltersLocal);
            onClose();
          }}
        >
          Apply
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { MobileFiltersDialog };
