import { Box } from '@material-ui/core';
import React from 'react';
import { SwitchField } from '../admin-console-volunteer-profile/form-builder/dialogs/field-task-item-dialog/additional-field-type-fields/SwitchField';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { GetAllowCICO } from './__generated__/GetAllowCICO';
import { GET_ALLOW_CICO } from './allow-cico.gql';

export function AllowCICO() {
  const [queryAllowCICO] = useCampfireLazyQuery<GetAllowCICO, {}>(GET_ALLOW_CICO);
  const [allowCICO, setAllowCICO] = React.useState<boolean | undefined>();
  const { setSnackbar } = useSnackbar();

  React.useEffect(() => {
    queryAllowCICO({}).then((response) =>
      setAllowCICO(
        response.data?.vm.configItem?.defaultValue || response.data?.vm.configItem?.configItemValue?.flagValue
      )
    );
  }, []);

  const saveAllowCICO = useCampfireFetch({ defer: true });

  const apiUrl = useApiUrl();
  const onSave = () => {
    saveAllowCICO
      .run({
        url: `${apiUrl}/vm/admin-console/operations/allow-cico`,
        method: 'post',
        data: {
          value: !allowCICO,
        },
      })
      .then(() => {
        setSnackbar({
          open: true,
          message: 'Successfully updated',
          variant: 'success',
        });
      });
    setAllowCICO(!allowCICO);
  };

  return (
    <Box marginBottom='1rem'>
      <SwitchField
        title='Check In/Check Out'
        description={
          'Volunteers will be able to check in to and out of rostered sessions, allowing recording of more accurate impact hours.'
        }
        checked={Boolean(allowCICO)}
        onChange={onSave}
      />
    </Box>
  );
}
