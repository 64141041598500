import { Button, ButtonGroup, Menu, MenuItem } from '@material-ui/core';
import { Add, ArrowDropDown, VisibilityOff } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import React, { MouseEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { useDeepEffect } from '../../../../hooks/useDeepEffect';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

type Props = {
  cakes: Array<{ cakeId: string; title: string }>;
  expanded: boolean;
  setOpenAddCakeDialog: (x: boolean) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    buttonGroup: {
      alignSelf: 'center',
      marginTop: 46,
    },
    button: {
      textTransform: 'none',
      minWidth: '120px',
    },
    plusButton: {
      textTransform: 'none',
      minWidth: '80px',
    },
  })
);

export const ButtonGroupSectionForPortal = ({ cakes }: { cakes: Array<{ cakeId: string; title: string }> }) => {
  const classes = useStyles();

  return (
    <ButtonGroup disableElevation className={classes.buttonGroup}>
      {cakes?.map((cake) => (
        <Button key={cake.cakeId} type='button' variant='outlined' className={classes.button} color='primary'>
          {cake.title}
        </Button>
      ))}
      {cakes.length < 5 ? (
        <Button type='button' variant='outlined'>
          <Add />
        </Button>
      ) : null}
    </ButtonGroup>
  );
};

const cakeRowPortal = (element: any, layerId = 'measure-layer') => {
  const measureLayer = document.getElementById(layerId);
  // @ts-ignore
  const renderedElement = ReactDOM.createPortal(element, measureLayer);
  return renderedElement;
};

export const ButtonGroupSection = (props: Props) => {
  const { cakes, expanded, setOpenAddCakeDialog } = props;
  const [containerWidth, setContainerWidth] = useState<number>();
  const [slicedCakes, setSlicedCakes] = useState<any[]>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [cakeId, setCakeId] = useQueryParam('cid', StringParam);

  const classes = useStyles();
  const { isMobile } = useCampfireTheme();
  const numberOfCakes = isMobile ? 1 : 2;
  const buttonGroupRef = useRef<HTMLDivElement | null>(null);
  const buttonGroupContainerRef = useRef<HTMLDivElement | null>(null);

  const handleResize = () => {
    if (buttonGroupContainerRef.current) {
      const newWidth = buttonGroupContainerRef.current.clientWidth;
      setContainerWidth(newWidth);
    }
  };
  const getTaskCakeRowView = () => <ButtonGroupSectionForPortal cakes={cakes} />;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    handleResize();
  }, []);

  useDeepEffect(() => {
    handleResize();
  }, [expanded]);

  useEffect(() => {
    if (isMobile) {
      return setSlicedCakes(cakes.slice(0, numberOfCakes));
    }
    const measureLayer = document.getElementById('measure-layer');
    // @ts-ignore
    const width = measureLayer.clientWidth;

    if (containerWidth && width) {
      return setSlicedCakes(width > containerWidth ? cakes.slice(0, numberOfCakes) : cakes);
    }
    return setSlicedCakes(cakes);
  }, [containerWidth]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  return (
    <div ref={buttonGroupContainerRef}>
      {!isMobile && cakeRowPortal(getTaskCakeRowView())}
      <ButtonGroup
        disableElevation
        className={classes.buttonGroup}
        ref={buttonGroupRef}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {slicedCakes?.map((cake) => (
          <Button
            key={cake.cakeId}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              setCakeId(cake.cakeId);
            }}
            variant={cake.cakeId === cakeId ? 'contained' : 'outlined'}
            className={classes.button}
            color={cake.cakeId === cakeId ? 'primary' : undefined}
          >
            {cake.isHidden && <VisibilityOff style={{ marginTop: '1px', marginRight: '8px', fontSize: '16px' }} />}
            {cake.title}
          </Button>
        ))}
        {slicedCakes?.length && slicedCakes?.length < cakes.length ? (
          <Button
            onClick={handleClick}
            variant={cakes.slice(numberOfCakes).find((cake) => cake.cakeId === cakeId) ? 'contained' : 'outlined'}
            className={classes.plusButton}
            endIcon={<ArrowDropDown />}
            color={cakes.slice(numberOfCakes).find((cake) => cake.cakeId === cakeId) ? 'primary' : undefined}
          >
            {isMobile ? `+ ${cakes.length - slicedCakes?.length}` : `${cakes.length - slicedCakes?.length} more`}
          </Button>
        ) : null}

        {cakes.length < 5 ? (
          <Button onClick={() => setOpenAddCakeDialog(true)} variant='outlined'>
            <Add style={{ fontSize: '1.35em' }} />
          </Button>
        ) : null}
      </ButtonGroup>
      <Menu
        id='task-category-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {cakes.slice(numberOfCakes).map((option) => (
          <MenuItem
            key={option.cakeId}
            onClick={() => {
              setCakeId(option.cakeId);
              setAnchorEl(null);
            }}
            selected={cakeId === option.cakeId}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
