import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AlertCard, getAlertCardColors } from '../../../../../../../common/cards/alert-card/AlertCard';
import { ActivityStatusChip } from '../../../../../../../common/chips/ActivityStatusChip';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { SaveTaskValues, TaskFormBuilderProgram } from '../../admin-console-volunteer-profile-model';

type Props = {
  programOptions: Array<TaskFormBuilderProgram>;
};

const SELECT_ALL_VALUE = 'all';
const SELECT_NONE_VALUE = 'none';

export const TaskProgramSelectionSection = ({ programOptions }: Props) => {
  const [selectedProgramIds, setSelectedProgramIds] = useState<string[]>([SELECT_ALL_VALUE]);

  const { isSm } = useCampfireTheme();
  const { values, errors, setFieldValue } = useFormikContext<SaveTaskValues>();
  const { lightTextColor, darkTextColor } = getAlertCardColors('warning');
  const hasError = !!errors.programIds;

  useEffect(() => {
    if (values.programIds) {
      if (values.programIds.includes(SELECT_ALL_VALUE)) {
        setSelectedProgramIds(programOptions.flatMap((program) => program.programId));
      } else {
        setSelectedProgramIds(values.programIds);
      }
    } else {
      setSelectedProgramIds([]);
    }
  }, [values.programIds]);

  const handleProgramIdChange = (event: any) => {
    const {
      target: { value },
    } = event;
    if (value.includes(SELECT_NONE_VALUE)) {
      setFieldValue('programIds', null);
      setFieldValue('activityIds', []);
      return;
    }
    setFieldValue('programIds', value);
  };

  return (
    <>
      <Typography style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '20px' }}>
        PROGRAMS
      </Typography>
      {programOptions.length ? (
        <FormControl fullWidth variant='outlined' error={hasError} required>
          <InputLabel variant='outlined' id='program-multi-select-dropdown' shrink style={{ minWidth: 400 }}>
            {isSm ? 'Select program/s' : 'Select program/s'}
          </InputLabel>
          <Select
            multiple
            name='programIds'
            value={selectedProgramIds}
            onChange={handleProgramIdChange}
            input={<OutlinedInput notched label={isSm ? 'Select program/s' : 'Select program/s'} />}
            renderValue={(selected) => {
              const selectedIds = selected as Array<string>;
              if (programOptions.length > 0 && selectedProgramIds?.length === programOptions.length)
                return 'All programs';
              return programOptions
                .filter((x: TaskFormBuilderProgram) => selectedIds?.includes(x.programId))
                .map((x: TaskFormBuilderProgram) => x.name)
                .join(', ');
            }}
          >
            <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
              <ListItemText primary={'Select All'} />
            </MenuItem>

            <MenuItem key={SELECT_NONE_VALUE} value={SELECT_NONE_VALUE}>
              <ListItemText primary={'Deselect All'} />
            </MenuItem>
            {programOptions.map((program: TaskFormBuilderProgram) => (
              <MenuItem key={program.programId} value={program.programId}>
                <ListItemIcon>
                  <Checkbox color='primary' checked={selectedProgramIds?.indexOf(program.programId) > -1} />
                </ListItemIcon>
                <ListItemText
                  primary={program.name}
                  primaryTypographyProps={{ style: { whiteSpace: 'normal', paddingRight: '8px' } }}
                />
                {program.dateSuspended ? <ActivityStatusChip status='SUSPENDED' /> : null}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Link
          to={{
            pathname: '/admin/program-management/',
            search: '?pid=new',
          }}
          style={{ textDecoration: 'none' }}
        >
          <AlertCard variant='warning'>
            <Typography
              variant='subtitle2'
              display='inline'
              style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
            >
              {
                'This option is unavailable because you have not yet created a program in Volaby. If you would like to create a program,  '
              }
            </Typography>

            <Typography
              variant='body2'
              display='inline'
              style={{ textDecoration: 'underline', color: darkTextColor, fontWeight: 700 }}
            >
              click here
            </Typography>
          </AlertCard>
        </Link>
      )}
    </>
  );
};
