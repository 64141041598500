import { gql } from '@apollo/client';

const ADMIN_CONSOLE_TAG_FRAGMENT =  gql`
  fragment AdminConsoleTag on VOLUNTEER_ActivityTagType {
    activityTagId
    name
    description
    isHidden
    isPublic
  }
`;

export const ADMIN_CONSOLE_TAG_QUERY = gql `
  query GetAdminConsoleTags {
    vm {
      activityTags {
        ...AdminConsoleTag
      }
    }
  }
  ${ADMIN_CONSOLE_TAG_FRAGMENT}
`
