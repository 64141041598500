import { Pane, PaneHeader, PaneScrollable } from '@campfire/pane';
import { TabletButton } from '@campfire/tablet-button/lib';
import { Box, IconButton } from '@material-ui/core';
import { Add, Clear, ChevronLeft } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Page } from '../../../../global/components';
import { ReportTypeFormValues } from '../manage-report-type/manage-report-type-model';
import { ReportTypeForm } from '../manage-report-type/ReportTypeForm';
import { ReportTypeSideNav } from '../manage-report-type/ReportTypeSideNav';
import { useManageReportTypeContext } from '../manage-report-type/ManageReportTypeContext';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const ManageReportTypePageMobile = () => {
  const {
    reportTypeIsLoading,
    selectedReportTypeId,
    setSelectedReportTypeId,
    reportTypes,
    reportTypesListIsLoading,
    creationModeOn,
    setCreationModeOn,
    saveReportIsLoading,
  } = useManageReportTypeContext();

  const formik = useFormikContext<ReportTypeFormValues>();
  const { theme } = useCampfireTheme();

  const [hasChanges, setHasChanges] = useState(false);
  useEffect(() => {
    if (hasChanges) return;
    setHasChanges(formik.values !== formik.initialValues);
  }, [formik.values, formik.initialValues]);

  function handleThrowAway() {
    setSelectedReportTypeId(undefined);
    if (creationModeOn) {
      formik.resetForm();
      setCreationModeOn(false);
    }
  }

  return (
    <Page>
      {reportTypesListIsLoading ? (
        <ManageReportTypeSkeletonLoading />
      ) : (
        <Box display='flex' flex='1 1 auto' height={1}>
          {!selectedReportTypeId && !creationModeOn ? (
            <Pane>
              <PaneHeader>
                <Box
                  paddingY={1}
                  paddingX={2}
                  display='flex'
                  justifyContent='space-between'
                  style={{ boxSizing: 'border-box' }}
                >
                  {/* Sidebar Header */}
                  <TabletButton
                    endIcon={<Add />}
                    color='primary'
                    variant='text'
                    onClick={() => {
                      setSelectedReportTypeId(undefined);
                      setCreationModeOn(true);
                    }}
                  >
                    {'New Report Type'}
                  </TabletButton>

                  <Link to='/management/activities/timeline' style={{ textDecoration: 'none' }}>
                    <IconButton>
                      <Clear />
                    </IconButton>
                  </Link>
                </Box>
              </PaneHeader>
              <PaneScrollable>
                <Box>
                  <ReportTypeSideNav />
                </Box>
              </PaneScrollable>
            </Pane>
          ) : (
            <Pane>
              <PaneHeader>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignContent='center'
                  alignItems='center'
                  paddingX={2}
                  paddingY={1}
                  borderBottom={`1px solid ${theme.color.grey.border}`}
                  style={{ boxSizing: 'border-box' }}
                >
                  <IconButton onClick={() => handleThrowAway()}>
                    <ChevronLeft />
                  </IconButton>

                  <Box display='inline-block'>
                    <TabletButton
                      type='submit'
                      variant='contained'
                      color='primary'
                      style={{ marginRight: 4 }}
                      disabled={saveReportIsLoading}
                    >
                      {'Save'}
                    </TabletButton>
                    {hasChanges && formik.values.reportTypeId ? (
                      <TabletButton variant='text' onClick={() => handleThrowAway()}>
                        {'Discard'}
                      </TabletButton>
                    ) : null}
                  </Box>
                </Box>
              </PaneHeader>
              <PaneScrollable>
                <Box position='relative' display='flex' flex='1 1 auto' height={1}>
                  {reportTypeIsLoading ? (
                    <Box paddingX={3} paddingY={2}>
                      <Skeleton variant='text' width={350} height={25} />
                      <Skeleton variant='text' width={200} height={15} />
                    </Box>
                  ) : (
                    <ReportTypeForm
                      selectedReportType={reportTypes.find(
                        (reportType) => reportType.reportTypeId === selectedReportTypeId
                      )}
                    />
                  )}
                </Box>
              </PaneScrollable>
            </Pane>
          )}
        </Box>
      )}
    </Page>
  );
};

const ManageReportTypeSkeletonLoading = () => {
  const { theme } = useCampfireTheme();
  return (
    <Box display='flex' flex='1 1 auto' height={1} width={1}>
      <Box display='flex' flex='1 1 auto' borderLeft={`1px solid ${theme.color.grey.border}`} width={1}>
        <Box width={1}>
          <Box paddingX={3} paddingY={1} borderBottom={`1px solid ${theme.color.grey.border}`} display='flex'>
            <Skeleton variant='text' width={150} height={25} style={{ marginRight: 16 }} />
            <Skeleton variant='text' width={150} height={25} style={{ marginRight: 16 }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { ManageReportTypePageMobile };
