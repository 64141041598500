import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import classNames from 'classnames';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface Props {
  text: string;
  isBackBtn?: boolean;
  toggle?: (x: boolean) => void;
}

const SectionHeading = (props: Props) => {
  const { text, isBackBtn, toggle } = props;
  const { theme } = useCampfireTheme();
  const classes = makeStyles({
    sectionHeading: {
      fontWeight: 500,
      marginTop: '15px',
    },
    divider: {
      border: 'none',
      height: '1px',
      background: theme.palette.grey[200],
    },
    button: {
      justifyContent: 'left',
      paddingLeft: '0px',
    },
  })();

  if (isBackBtn && toggle) {
    return (
      <div className={classes.sectionHeading}>
        <Button
          fullWidth
          onClick={() => toggle(false)}
          classes={{ root: classNames(classes.button, classes.sectionHeading) }}
        >
          <ChevronLeft color='inherit' />
          <Typography variant='button' color='inherit'>
            {text}
          </Typography>
        </Button>
        <hr className={classes.divider} />
      </div>
    );
  }
  return (
    <div>
      <Typography variant='h6' className={classes.sectionHeading}>
        {text}
      </Typography>
      <hr className={classes.divider} />
    </div>
  );
};

export { SectionHeading };
