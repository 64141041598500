import React, { useState } from 'react';
import { Grid, ButtonBase, Dialog } from '@material-ui/core';

type Props = {
  images: {
    imageId: string;
    imageUrl: string;
  }[];
};
const ImagePreviewList = (props: Props) => {
  const { images } = props;

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  return (
    <Grid item xs={12} container>
      {images.length > 0 && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {images.map((image, idx) => {
                return (
                  <Grid item key={image.imageId}>
                    <ButtonBase
                      onClick={() => {
                        setImageUrl(image.imageUrl);
                        setImageDialogOpen(true);
                      }}
                    >
                      <img
                        height='100px'
                        width='100px'
                        src={image.imageUrl}
                        alt={`Attached resource for incident (${idx})`}
                      />
                    </ButtonBase>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </>
      )}

      <Dialog open={imageDialogOpen} onClose={() => setImageDialogOpen(false)}>
        <img src={imageUrl} width='100%' alt='Resource photo' />
      </Dialog>
    </Grid>
  );
};

export { ImagePreviewList };
