import { gql } from '@apollo/client';

export const GET_ALL_ADMIN_USERS = gql`
  query GetAllAdminUsers {
    vm {
      admins {
        ...AllAdminUsersAdminUser
      }
    }
  }
  fragment AllAdminUsersAdminUser on VOLUNTEER_ManagerType {
    managerId
    profile {
      profileId
      userId
      preferredName
      lastName
      email
      avatarUrl
      lastLogin {
        ipAddress
        occurredAt
      }
      volunteer {
        volunteerId
      }
    }
  }
`;

export const GET_ALL_VOLUNTEERS = gql`
  query GetAllVolunteers($q: String, $limit: Int, $offset: Int, $exceptVolunteerIds: [String!]) {
    vm {
      searchVolunteers(q: $q, limit: $limit, offset: $offset, exceptVolunteerIds: $exceptVolunteerIds) {
        ...AllAdminUsersVolunteer
      }
    }
  }
  fragment AllAdminUsersVolunteer on VOLUNTEER_VolunteerType {
    volunteerId
    profile {
      userId
      preferredName
      lastName
      avatarUrl
      lastLogin {
        ipAddress
        occurredAt
      }
    }
  }
`;
