import React, { createContext, useState } from 'react';

export interface ScreenControlContextInterface {
  screenInUse: boolean;
  setScreenInUse: (value: boolean) => void;
}

export interface ScreenControlProviderProps {
  value?: ScreenControlContextInterface;
  children: React.ReactNode;
}

export const ScreenControlContext = createContext<ScreenControlContextInterface | undefined>(undefined);

export const ScreenControlProvider = (props: ScreenControlProviderProps) => {
  const [screenInUse, setScreenInUse] = useState(false);
  const value: ScreenControlContextInterface = {
    screenInUse,
    setScreenInUse,
  };
  return <ScreenControlContext.Provider value={value} {...props} />;
};
