import React from 'react';
import { Box } from '@material-ui/core';
import { useSaveComment } from '../../../../../../common/comment/Comment';
import { CommentBox, PostCommentType } from '../../../../../../common/comment/CommentBox';
import { CommentViewer } from '../../../../../../common/comment/CommentViewer';
import { BulletinComments_vm_bulletin_comments as IBulletinComment } from './__generated__/BulletinComments';

interface CommentsListProps {
  bulletinId: string;
  hideCommentBox: boolean;
  comments: IBulletinComment[];
  refetch: () => void | undefined;
}

export function CommentsList({ bulletinId, hideCommentBox, comments, refetch }: CommentsListProps) {
  const saveComment = useSaveComment();

  const onPost = (body: PostCommentType) => {
    saveComment({
      bulletinId: bulletinId,
      ...body,
    }).then(() => {
      // onClear();
      if (refetch) {
        refetch();
      }
    });
  };

  const handleEdit = (body: PostCommentType) => {
    saveComment({
      ...body,
    }).then(() => {
      // onClear();
      if (refetch) {
        refetch();
      }
    });
  };

  const onReply = (parentId: string, content: string) => {
    saveComment({
      parentId,
      bulletinId: bulletinId,
      content,
    }).then(() => {
      if (refetch) {
        refetch();
      }
    });
  };

  const rootComments = comments.filter((comment) => !comment.parentId);
  const nonRootComments = comments.filter((comment) => Boolean(comment.parentId));
  return (
    <React.Fragment>
      {!hideCommentBox && (
        <Box>
          <CommentBox onPost={onPost} />
        </Box>
      )}
      <Box paddingTop={'1em'}>
        {rootComments?.map(
          (comment) =>
            !comment.removedDate && (
              <CommentViewer
                comment={comment}
                comments={nonRootComments}
                onReply={onReply}
                handleEdit={handleEdit}
                hideCommentBox={hideCommentBox}
                refetch={refetch}
              />
            )
        )}
      </Box>
    </React.Fragment>
  );
}
