import React, { FunctionComponent, useEffect } from 'react';
import { PageHelpOptions, usePageHelp } from '../page-help/PageHelpContext';

type Props = {
  pageHelpOptions?: PageHelpOptions;
  bgcolor?: string;
  ref?: React.Ref<HTMLDivElement>;
};

const Page: FunctionComponent<Props> = React.memo(
  React.forwardRef((props, ref: React.Ref<HTMLDivElement>) => {
    const { children, pageHelpOptions } = props;
    const { setPageHelp, clearPageHelp } = usePageHelp();

    useEffect(() => {
      if (!pageHelpOptions) {
        clearPageHelp();
        return;
      }
      setPageHelp(pageHelpOptions);
    }, [pageHelpOptions]);

    return (
      <div ref={ref} style={{ height: '100%' }}>
        {children}
      </div>
    );
  })
);

export { Page };
