import { Box, Button, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { sharedStyles } from '../../../general/activities-v2/ActivityStyles/shared';
import { useFilterStyles } from '../common/FilterStyles';
import { ReportTypeFilterValues } from '../report-management-types';
import { INITIAL_REPORT_TYPE_FILTERS, REPORT_FIELD_STATUS_OPTIONS } from '../ReportManagementConsts';

type Props = {
  values: ReportTypeFilterValues;
  onChange: (arg: any) => void;
};

export const ReportFieldFilters = (props: Props) => {
  const { values, onChange } = props;
  const [filters, setFilters] = useState(values);
  const classes = useFilterStyles();
  const { isSm } = useCampfireTheme();

  const onSelect = (e: any) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onClear = () => {
    setFilters(INITIAL_REPORT_TYPE_FILTERS);
    onChange(INITIAL_REPORT_TYPE_FILTERS);
  };

  const onSubmit = () => {
    onChange({
      status: filters.status,
    });
  };

  const inputClasses = sharedStyles.textField();

  return (
    <Box flexGrow={1} className={classes.container}>
      <Grid container spacing={isSm ? 2 : 3} style={{ margin: 0, width: '100%' }}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography className={classes.label}>Report Field Status</Typography>
          <TextField
            name='status'
            value={filters.status}
            select
            variant='outlined'
            fullWidth
            onChange={onSelect}
            InputProps={{ classes: inputClasses }}
          >
            {REPORT_FIELD_STATUS_OPTIONS.map((option) => (
              <MenuItem key={option} value={option}>
                {capitalize(option)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Box className={classes.actionContainer}>
        <Button
          onClick={onSubmit}
          className={`${classes.button} ${classes.showButton}`}
          variant='contained'
          disableElevation
        >
          Show the results
        </Button>
        <Button
          onClick={onClear}
          className={`${classes.button} ${classes.clearButton}`}
          variant='contained'
          disableElevation
        >
          Clear
        </Button>
      </Box>
    </Box>
  );
};
