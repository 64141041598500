import React from 'react';
import { TextField } from '@material-ui/core';

export const TextFieldPreview = React.memo(() => (
  <TextField
    inputProps={{ style: { padding: 8 } }}
    variant='outlined'
    disabled
    fullWidth
    placeholder='Write your answer here'
  />
));
