import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { PublishedRoster_sessionNotes as PublishedRosterSessionNoteType } from './__generated__/PublishedRoster';
import { AlertCard, getAlertCardColors } from '../../../../common/cards/alert-card/AlertCard';
import { DraftRoster_sessionNotes as DraftRosterSessionNotesType } from './__generated__/DraftRoster';

interface ActivityRosterNotesProps {
  rosterNotes?: string | null;
  sessionNotes: Array<PublishedRosterSessionNoteType | DraftRosterSessionNotesType>;
}

export function RosterSessionNote({
  sessionNote,
}: {
  sessionNote: PublishedRosterSessionNoteType | DraftRosterSessionNotesType;
}) {
  const { darkTextColor } = getAlertCardColors('notes');

  const { notes, session } = sessionNote;

  const content = React.useMemo(() => {
    if (!notes) {
      return EditorState.createEmpty();
    }
    const blocksFromHTML = convertFromHTML(notes);
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
    );
  }, [notes]);

  if (!notes) {
    return null;
  }

  return (
    <Box>
      <Typography
        variant='subtitle2'
        style={{ fontSize: '14px', fontWeight: 700, color: darkTextColor, paddingTop: '10px' }}
      >
        Session Note: <em>{session.name}</em>
      </Typography>
      <Typography style={{ fontSize: '14px', fontWeight: 400, color: darkTextColor }}>
        <CampfireRichEditor editorState={content} readOnly />
      </Typography>
    </Box>
  );
}

export function ActivityRosterNotes({ rosterNotes, sessionNotes = [] }: ActivityRosterNotesProps) {
  const content = React.useMemo(() => {
    if (!rosterNotes) {
      return EditorState.createEmpty();
    }
    const blocksFromHTML = convertFromHTML(rosterNotes);
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
    );
  }, [rosterNotes]);

  const { darkTextColor } = getAlertCardColors('notes');

  if (!(rosterNotes || sessionNotes.length > 0)) {
    return null;
  }

  return (
    <Box marginTop={'.5em'}>
      <AlertCard variant='notes' title='Roster Note'>
        <Typography style={{ fontSize: '14px', fontWeight: 400, color: darkTextColor }}>
          <CampfireRichEditor editorState={content} readOnly />
        </Typography>
        {sessionNotes.map((sessionNote) => (
          <RosterSessionNote sessionNote={sessionNote} key={sessionNote.session.sessionId} />
        ))}
      </AlertCard>
    </Box>
  );
}
