import { makeStyles, createStyles } from '@material-ui/styles';

export const useButtonStyles = makeStyles(() => createStyles({
  root: {
    textTransform: 'capitalize',
    minWidth: '80px',
    height: '38px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '6px'
  },
  startIcon: {
    marginLeft: 0,
    marginRight: '14px'
  }
}))