import React from 'react';
import { unpackToDateTime } from '@campfire/hot-date';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid';
import { WidgetTypes } from '../../../../dashboard-widget-setting/dashboard-widget-model';
import { GET_TABLE_WIDGET_PROGRAM_APPLICATIONS } from './program-application-table-widget-model.gql';
import {
  GetTableWidgetProgramApplications,
  GetTableWidgetProgramApplicationsVariables,
} from './__generated__/GetTableWidgetProgramApplications';
import { CommonRequiringActionWidgetProps } from '../../../shared';
import { useCampfireQuery } from '../../../../../../../../../global/network/useCampfireQuery';
import { VolunteerProfile } from '../../../../../../../../../common/AvatarName';

export const ProgramApplicationsTableWidget = (props: CommonRequiringActionWidgetProps) => {
  const { filters, range } = props;
  const { programIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };

  const { data: getProgramApplicationsData, loading } = useCampfireQuery<
    GetTableWidgetProgramApplications,
    GetTableWidgetProgramApplicationsVariables
  >(GET_TABLE_WIDGET_PROGRAM_APPLICATIONS, {
    options: {
      variables: {
        programIds: programIds.length > 0 ? programIds : null,
        range: range,
        widgetType: WidgetTypes.TABLE,
      },
    },
  });

  const programApplications =
    getProgramApplicationsData?.vm.programApplications
      .filter(
        (application) =>
          !application.volunteer.dateDeactivated &&
          !application.volunteer.flagging?.dateFlagged &&
          !application.program.dateDeleted &&
          !application.program.dateSuspended
      )
      .map((application) => ({
        id: application.programApplicationId,
        program: application.program.name,
        profile: application.volunteer.profile,
        dateApplied: unpackToDateTime(application.dateApplied).toFormat('dd/MM/y'),
        dateActioned: application.dateActioned ? unpackToDateTime(application.dateActioned).toFormat('dd/MM/y') : null,
        dateWithdrawn: application.dateWithdrawn
          ? unpackToDateTime(application.dateWithdrawn).toFormat('dd/MM/y')
          : null,
      })) || [];

  const columns: GridColumns = [
    {
      field: 'volunteerName',
      headerName: 'Volunteer',
      flex: 1,
      minWidth: 180,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <VolunteerProfile inTable {...row.profile} name={`${row.profile.preferredName} ${row.profile.lastName}`} />
        );
      },
    },
    {
      field: 'program',
      headerName: 'Program',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: ({ row }: any) => {
        return (
          <Tooltip title={row.program}>
            <Typography noWrap style={{ fontSize: 'inherit' }}>
              {row.program}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'dateApplied',
      headerName: 'Date Applied',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'dateActioned',
      headerName: 'Date Actioned',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'dateWithdrawn',
      headerName: 'Date Withdrawn',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 170,
    },
  ];

  return (
    <Box width='100%' height='100%'>
      <DataGrid columns={columns} rows={programApplications} loading={loading} autoPageSize disableSelectionOnClick />
    </Box>
  );
};
