import { useContext, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { get, keyBy, merge, values } from 'lodash';
import QueryString from 'qs';
import { useApiUrl } from '../../../../../../../global/config/useApiUrl'
import { useCampfireFetch } from '../../../../../../../global/network/useCampfireFetch';
import { GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents_widget_VOLUNTEER_ChartWidgetType as IWidget } from '../../dashboard-widget-setting/__generated__/GetDashboardPreference';
import { DimensionEnum } from '../../dashboard-widget-setting/dashboard-widget-model';
import { WidgetDisplayContext } from '../../WidgetDisplayContext';

type ITarget = {
  name: string;
  tag: string;
}

export const useQueryChart = (widget: IWidget) => {
  const {
    filters,
    targets,
    configuration: { chartType, dimension, interval, range, group },
  } = widget;

  const baseUrl = useApiUrl();
  const query = useCampfireFetch({ defer: true });
  const [data, setData] = useState<any>();

  const startDate = useMemo(() => {
    switch (range) {
      case 'TODAY':
        return DateTime.local().minus({ days: 1 }).startOf('day');
      case 'THIS_WEEK':
        return DateTime.local().minus({ weeks: 1 }).startOf('day');
      case 'THIS_MONTH':
        return DateTime.local().minus({ months: 1 }).startOf('day');
      case 'THIS_YEAR':
        return DateTime.local().minus({ years: 1 }).startOf('day');
      case 'ALL_TIME':
        return DateTime.local().minus({ years: 10 }).startOf('day');
      default:
        return DateTime.local().minus({ year: 5 }).startOf('day');
    }
  }, []);

  const { reportFields } = useContext(WidgetDisplayContext);

  useEffect(() => {
    const parsedTargets = JSON.parse(targets) as ITarget[];
    const parsedFilters = JSON.parse(filters);
    let mergedData = {};
    const getData = async () => {
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < parsedTargets.length; i ++) {
        const { name, tag } = parsedTargets[i];
        const parsedName = (tag.toLowerCase() === 'reporting' && name.toLowerCase() === 'report-field-value') ? get(reportFields, [get(parsedFilters, ['fieldIds', 0])], '') : name;
        const url = `${baseUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/chart`;
        const response = await query.run({
          url,
          params: {
            interval,
            dimension,
            startDate: startDate.toISODate(),
            endDate: DateTime.local().toISODate(),
            group,
            filters: parsedFilters
          },
          paramsSerializer: (params) => {
            return QueryString.stringify(params, { arrayFormat: 'brackets', encode: false, skipNulls: true });
          },
        });
        await new Promise((resolve) => setTimeout(resolve, 200));
        if (dimension !== DimensionEnum.BY_TIME) {
          mergedData = merge(
            mergedData,
            keyBy((response.data.data as any[] || []).map((datum) => ({[parsedName]: datum.total, name: datum.name})), 'name')
          );
        }

        if (dimension === DimensionEnum.BY_TIME && group === null) {
          mergedData = merge(
            mergedData,
            keyBy((response.data.data as any[] || []).map((datum) => ({[parsedName]: datum.total, date: datum.date})), 'date')
          );
        }
        if (dimension === DimensionEnum.BY_TIME && group !== null) {
          return get(response, 'data.data') as any;
        }
      }
      return values(mergedData);
    }

    getData().then(setData);
  }, [dimension, interval, startDate, chartType, targets, group]);

  return data;
}
