import { gql } from '@apollo/client';

export const IV_APPLICATION_COMMENT = gql`
  query IVGetApplicationComments($applicationId: String!) {
    vm {
      application(applicationId: $applicationId) {
        comments {
          commentId
          parentId
          content
          submittedDate
          removedDate
          attachments {
            attachmentId
            fileType
            url
            name
          }
          volunteer {
            volunteerId
            profile {
              preferredName
              lastName
              avatarUrl
            }
          }
          reactions {
            reactionId
            emoji
            volunteer {
              volunteerId
            }
          }
        }
      }
    }
  }
`;
