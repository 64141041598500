import React from 'react';
import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { unpackToDate } from '@campfire/hot-date';
import { EmptyMessageCard } from './EmptyMessageCard';

interface Props {
  activity: any;
  nonTeamMemberAllowedDates: any[];
  setSelectedDate: (x: string) => void;
}

export const ChooseAnAllowedDateBlock = (props: Props) => {
  const { activity, nonTeamMemberAllowedDates, setSelectedDate } = props;
  return (
    <EmptyMessageCard
      heading='Please choose a date you were rostered on'
      subHeading={`Since you aren't on the permanent ${
        activity ? activity.name : ' activity'
      } team, you can only create reports for dates that you were rostered on.
            ${
              nonTeamMemberAllowedDates.length
                ? 'Pick from one of the below dates instead, or use the Activity Date picker again.'
                : ''
            }`}
    >
      {nonTeamMemberAllowedDates.length ? (
        <Box paddingY={1} paddingX={8}>
          <List>
            {nonTeamMemberAllowedDates.map((nonTeamMemberAllowedDate) => {
              return (
                <TitularTooltip
                  key={uuidv4()}
                  placement='right'
                  title={`${unpackToDate(nonTeamMemberAllowedDate).toFormat('cccc, d LLLL')}`}
                >
                  <ListItem
                    button
                    onClick={() => setSelectedDate(nonTeamMemberAllowedDate)}
                    key={nonTeamMemberAllowedDate}
                  >
                    <ListItemText
                      primary={`${unpackToDate(nonTeamMemberAllowedDate).toFormat('dd/MM/y')}`}
                      secondary={`${unpackToDate(nonTeamMemberAllowedDate).toFormat('cccc, d LLLL')}`}
                    />
                  </ListItem>
                </TitularTooltip>
              );
            })}
          </List>
        </Box>
      ) : null}
    </EmptyMessageCard>
  );
};
