import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { convertFromRaw, EditorState } from 'draft-js';
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { CloseRounded, ExpandMore, MoreHoriz } from '@material-ui/icons';
import { useQueryParam, StringParam } from 'use-query-params';
import React, { useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useActivityHeaderActions } from './activities-header-actions';
import { ActionsHeader } from '../../../../../common/ActionsHeader';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { ActivitiesConsoleCardExploreActivity } from '../__generated__/ActivitiesConsoleCardExploreActivity';
import { SelectActivityClosureReasonDialogV2 } from '../../common-v2/SelectActivityClosureReasonDialogV2';
import { getIsActivityEnded } from '../../common-v2/is-activity-ended';
import { useCopyLinkAction } from '../../../../../common/CopyToClipboard';

interface Props {
  activityInfo: ActivitiesConsoleCardExploreActivity;
  getActivitiesCardDataRefetch?: () => Promise<{ data: any }>;
  getActivitiesCardDataLoading: boolean;
}

export const ActivityHeaderDetail = (props: Props) => {
  const { theme, isMobile, isMd } = useCampfireTheme();
  const { activityInfo, getActivitiesCardDataRefetch } = props;
  const routeMatch = useRouteMatch();
  const returnSlug = routeMatch.path;
  const isActivityEnded = getIsActivityEnded(activityInfo);
  const { runSuspendActivity, runUnsuspendActivity, runCloseActivity, runUncloseActivity } = useActivityHeaderActions();
  const [activityStatusMenuAnchorEl, setActivityStatusMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activityEditMenuAnchorEl, setActivityEditMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showClosureReasonDialog, setShowClosureReasonDialog] = useState(false);
  const [, setSelectedActivityId] = useQueryParam('activityId', StringParam);
  const { handleCopyLinkClick } = useCopyLinkAction();

  const handleOpenActivityStatusMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActivityStatusMenuAnchorEl(event.currentTarget);
  };

  const handleCloseActivityStatusMenu = () => {
    setActivityStatusMenuAnchorEl(null);
  };
  const handleOpenActivityEditMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActivityEditMenuAnchorEl(event.currentTarget);
  };

  const handleCloseActivityEditMenu = () => {
    setActivityEditMenuAnchorEl(null);
  };
  const editorState = useMemo(
    () =>
      activityInfo.description
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(activityInfo.description)))
        : null,
    [activityInfo]
  );
  const handleActivityStatusUpdateSuccess = () => {
    handleCloseActivityStatusMenu();
    if (getActivitiesCardDataRefetch) getActivitiesCardDataRefetch();
  };

  return (
    <>
      <ActionsHeader title={activityInfo.name}>
        {isActivityEnded ? (
          <TabletButton
            size='small'
            variant='contained'
            disabled
            aria-controls='activity-status-menu'
            aria-haspopup='true'
            style={{
              border: '1px solid #9e9e9e',
              boxSizing: 'border-box',
              borderRadius: '4px',
              padding: '2px 7px',
              color: theme.color.grey.neutral200,
              marginRight: '8px',
            }}
          >
            {'Ended'}
          </TabletButton>
        ) : (
          <Box display={'inline-block'}>
            <TabletButton
              // data-track='actCnl-overview-status-dropdown'
              size='small'
              variant={activityInfo.closedActivity ? 'contained' : 'outlined'}
              color={activityInfo.closedActivity ? 'error' : 'primary'}
              aria-controls='activity-status-menu'
              aria-haspopup='true'
              onClick={handleOpenActivityStatusMenu}
              endIcon={<ExpandMore />}
              style={{
                border: '1px solid #9e9e9e',
                boxSizing: 'border-box',
                borderRadius: '4px',
                padding: '2px 7px',
                color: theme.color.grey.neutral300,
                marginRight: '8px',
              }}
            >
              {activityInfo.closedActivity ? 'Closed' : activityInfo.isSuspended ? 'Suspended' : 'Active'}
            </TabletButton>

            <Menu
              id='activity-status-menu'
              anchorEl={activityStatusMenuAnchorEl}
              keepMounted
              open={Boolean(activityStatusMenuAnchorEl)}
              onClose={handleCloseActivityStatusMenu}
            >
              {activityInfo.closedActivity ? null : activityInfo.isSuspended ? (
                <TitularTooltip
                  placement='right'
                  enterDelay={0}
                  title='Activate restricted'
                  description='Activities that belong to a suspended program cannot be active.'
                  disableHoverListener={!activityInfo.program.dateSuspended}
                  disableFocusListener={!activityInfo.program.dateSuspended}
                  disableTouchListener={!activityInfo.program.dateSuspended}
                >
                  <Box>
                    <MenuItem
                      // data-track='actCnl-overview-status-dropdown-item-activate'
                      disabled={activityInfo.program.dateSuspended}
                      onClick={() => {
                        runUnsuspendActivity({
                          activityId: activityInfo.activityId,
                          handleSuccess: handleActivityStatusUpdateSuccess,
                        });
                      }}
                    >
                      {'Activate'}
                    </MenuItem>
                  </Box>
                </TitularTooltip>
              ) : (
                <MenuItem
                  // data-track='actCnl-overview-status-dropdown-item-suspend'
                  onClick={() => {
                    runSuspendActivity({
                      activityId: activityInfo.activityId,
                      handleSuccess: handleActivityStatusUpdateSuccess,
                    });
                  }}
                >
                  {'Suspend'}
                </MenuItem>
              )}
              {activityInfo.closedActivity ? (
                <MenuItem
                  // data-track='actCnl-overview-status-dropdown-item-reopen'
                  onClick={() => {
                    runUncloseActivity({
                      closedActivityId: activityInfo.closedActivity
                        ? activityInfo.closedActivity.closedActivityId
                        : null,
                      handleSuccess: handleActivityStatusUpdateSuccess,
                    });
                  }}
                >
                  {'Reopen'}
                </MenuItem>
              ) : (
                <MenuItem
                  // data-track='actCnl-overview-status-dropdown-item-close'
                  onClick={() => {
                    handleCloseActivityStatusMenu();
                    setShowClosureReasonDialog(true);
                  }}
                >
                  {'Close'}
                </MenuItem>
              )}
            </Menu>
          </Box>
        )}
        <Box
          style={{
            border: '1px solid #9e9e9e',
            boxSizing: 'border-box',
            borderRadius: '4px',
            marginRight: '10px',
          }}
        >
          <IconButton
            color='primary'
            aria-label='activity-action-menu'
            component='span'
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleOpenActivityEditMenu(event)}
            style={{ color: theme.color.grey.neutral300, padding: '0px 2px', fontSize: '26px', borderRadius: 'none' }}
          >
            <MoreHoriz fontSize={'inherit'} style={{ borderRadius: 'none' }} />
          </IconButton>
        </Box>
        <Menu
          id='activity-edit-menu'
          anchorEl={activityEditMenuAnchorEl}
          keepMounted
          open={Boolean(activityEditMenuAnchorEl)}
          onClose={handleCloseActivityEditMenu}
        >
          <MenuItem
            component={Link}
            to={`/management/activity-management/edit?activityId=${activityInfo.activityId}${
              returnSlug ? `&return=${encodeURIComponent(returnSlug)}` : ''
            }`}
          >
            {'Edit'}
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/management/activity-management/duplicate?activityId=${activityInfo.activityId}${
              returnSlug ? `&return=${encodeURIComponent(returnSlug)}` : ''
            }`}
          >
            {'Duplicate'}
          </MenuItem>
          <MenuItem onClick={handleCopyLinkClick}>Copy Link</MenuItem>
        </Menu>
        {isMobile ? (
          <Button
            style={{
              border: '1px solid #FF4F0F',
              boxSizing: 'border-box',
              borderRadius: '4px',
              textTransform: 'none',
              color: '#FF4F0F',
              padding: isMobile || isMd ? '3px 12px' : '7px 12px',
            }}
            onClick={() => setSelectedActivityId(null)}
          >
            <CloseRounded style={{ fontSize: '18px' }} />
            <Typography
              style={{
                marginLeft: '4px',
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '19px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'inherit',
                marginBottom: '1px',
              }}
            >
              Close
            </Typography>
          </Button>
        ) : (
          ''
        )}
      </ActionsHeader>
      {editorState ? (
        <Box paddingY={1} style={{ color: theme.color.grey.neutral400, fontSize: '16px', lineHeight: '22.4px' }}>
          <CampfireRichEditor editorState={editorState} readOnly />
        </Box>
      ) : null}
      <SelectActivityClosureReasonDialogV2
        open={showClosureReasonDialog}
        handleClose={() => setShowClosureReasonDialog(false)}
        handleSelection={({ activityClosureReasonId, dateEnded }) => {
          runCloseActivity({
            activityId: activityInfo.activityId,
            activityClosureReasonId,
            dateEnded,
            handleSuccess: handleActivityStatusUpdateSuccess,
          });
          setShowClosureReasonDialog(false);
        }}
        activityName={activityInfo.name}
      />
    </>
  );
};
