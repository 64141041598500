import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { useCommonStyles } from '../commonStyles';
import { AppStatusSection } from './AppStatusSection';

type AppAccountProps = {
  accountDescription: string;
  lastUpdated: string;
  name: string;
};

export function AppAuthSetting(props: AppAccountProps) {
  const { accountDescription, name, lastUpdated } = props;
  const commonStyles = useCommonStyles();
  const { isSm } = useCampfireTheme();
  return (
    <Box className={commonStyles.containerPanel}>
      <Typography variant='h2' className={commonStyles.headingTitle}>
        Your Account
      </Typography>
      <Box display='flex' flexDirection={isSm ? 'column' : 'row'} py='1rem'>
        <Box flex={1} borderRight={isSm ? 'none' : '1px solid #dfdfdf'} mr='1rem'>
          <Typography className={commonStyles.sectionHeading}>Status</Typography>
          <AppStatusSection accountDescription={accountDescription} lastUpdated={lastUpdated} name={name} />
        </Box>
        <Box flex={1}>
          <Typography className={commonStyles.sectionHeading}>Authorisations</Typography>
        </Box>
      </Box>
    </Box>
  );
}
