import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

export const sharedStyles = {
  button: makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: '5px 12px',
        fontSize: '14px',
        textTransform: 'capitalize',
        borderRadius: '6px',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.75rem',
        },
      },
      contained: {
        boxShadow: 'unset',
        '&:hover': {
          boxShadow: 'unset',
        },
      },
      selected: {
        color: 'white',
        backgroundColor: '#646464',
        '&:hover': {
          color: 'white',
          backgroundColor: '#646464',
          opacity: 0.75,
        },
      },
    })
  ),
  textField: makeStyles(() =>
    createStyles({
      input: {
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '12px',
        height: '19px',
      },
      root: {
        borderRadius: '6px',
        borderTopColor: '#9E9E9E',
        borderRightColor: '#9E9E9E',
        borderBottomColor: '#9E9E9E',
        borderLeftColor: '#9E9E9E',
        color: '#444444',
      },
      adornedStart: {
        paddingLeft: '0.75rem',
      },
    })
  ),
};

export const rootContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      flex: 1,
      paddingLeft: '60px',
      paddingRight: '60px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '1.5rem',
      [theme.breakpoints.only('xs')]: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },
  })
);

export const bodyStyles = makeStyles((theme: Theme) =>
  createStyles({
    stickyBox: {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      paddingTop: '1rem',
      zIndex: 1,
    },
    rootContainer: {
      flex: 1,
      paddingLeft: '60px',
      paddingRight: '60px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '1.5rem',
      [theme.breakpoints.only('xs')]: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '60px',
        paddingRight: '60px',
        minWidth: 'calc(100% - 640px)',
      },
    },
    button: {
      alignItems: 'center',
      '& > span': {
        textTransform: 'none',
      },
      borderRadius: 6,
      height: 38,
    },
  })
);

export const tooltipStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      borderRadius: 16,
    },
  })
);
