import { gql } from '@apollo/client';

export const GET_VOL_DASHBOARD_MY_ELEMENTS_ROSTER_ITEM_STATUS_DATA = gql`
  query GetVolDashboardMyElementsRosterItemStatusData($volunteerId: String!) {
    vm {
      allowCICO
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        activityEnrolments {
          ...MyActivityEnrolment
        }
        activityApplications {
          ...MyActivityApplications
        }
        activityWaitlistings {
          ...MyActivityWaitlist
        }
        rosterings {
          ...MyActivityRosterings
        }
        CICOs {
          ...VolunteerCICOsFragment
        }
      }
    }
  }
  fragment MyActivityEnrolment on VOLUNTEER_ActivityEnrolmentType {
    activityEnrolmentId
    dateRemoved
    dateEnrolled
    availabilities {
      dateAdded
      dateRemoved
      activityAvailabilityId
      activityDate
      sessionAvailabilities {
        dateMarked
        dateRemoved
        session {
          sessionId
        }
      }
    }
    unavailabilities {
      activityUnavailabilityId
      activityDate
      dateRemoved
    }
    activity {
      activityId
    }
  }
  fragment MyActivityApplications on VOLUNTEER_ActivityApplicationType {
    activityApplicationId
    dateApplied
    dateWithdrawn
    dateActioned
    activity {
      activityId
      name
      startTime
      endTime
    }
  }
  fragment MyActivityWaitlist on VOLUNTEER_ActivityWaitlistingType {
    activityWaitlistingId
    dateAdded
    dateRemoved
    activity {
      activityId
      name
      startTime
      endTime
    }
  }
  fragment MyActivityRosterings on VOLUNTEER_PublishedRosteringType {
    publishedRosteringId
    dateRemoved
    publishedRoster {
      activityDate
      activity {
        activityId
      }
    }
    sessionRosterings {
      session {
        sessionId
        name
      }
    }
  }
  fragment VolunteerCICOsFragment on VOLUNTEER_CICOType {
    CICOId
    checkIn
    checkOut
    sessionReport {
      activityReport {
        activityDate
        activity {
          activityId
        }
      }
    }
  }
`;
