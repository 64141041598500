import { AntSwitch } from '@campfire/ant-switch';
import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import { List as ListIcon } from '@material-ui/icons';
import { mapValues } from 'lodash';
import React, { useRef, useState, useMemo } from 'react';

interface Props {
  impactOptions: Array<{ name: string; label: string }>;
  setImpactFilter?: (value: any) => void;
  impactFilter?: { [key: string]: boolean };
}

export const VolunteerDatabaseImpactFilter = (props: Props) => {
  const { impactOptions, setImpactFilter, impactFilter } = props;
  const impactButtonRef = useRef<HTMLButtonElement | null>(null);
  const [impactMenu, setImpactMenu] = useState(false);

  const handleSelectAll = () => {
    const newFilters = mapValues(impactFilter, () => true);
    if (setImpactFilter) setImpactFilter(newFilters);
  };

  const selectedImpactCount = useMemo(
    () =>
      impactFilter ? Object.keys(impactFilter).reduce((total, curr) => (impactFilter[curr] ? total + 1 : total), 0) : 0,
    [impactFilter]
  );

  return (
    <Box>
      <TabletButton
        variant='outlined'
        ref={impactButtonRef}
        onClick={() => setImpactMenu(true)}
        startIcon={<ListIcon fontSize='small' />}
      >
        Impact
      </TabletButton>
      <Menu
        id='impact-filter-menu'
        anchorEl={impactButtonRef ? impactButtonRef.current : undefined}
        keepMounted
        open={impactMenu}
        onClose={() => setImpactMenu(false)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleSelectAll}>
          <Box display='flex' flex={1} flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Typography variant='caption' color='textSecondary'>{`${selectedImpactCount} selected`}</Typography>
            <HoverText variant='body2'>{'Select All'}</HoverText>
          </Box>
        </MenuItem>
        {impactOptions.map((opt) => (
          <MenuItem
            key={opt.name}
            onClick={() => {
              if (setImpactFilter)
                setImpactFilter((prev: any) => ({
                  ...prev,
                  [opt.name]: !prev[opt.name],
                }));
            }}
          >
            <Box display='flex' flex={1} flexDirection='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='body2'>{opt.label}</Typography>
              <Box display='flex' flexDirection='row' alignItems='center' marginLeft='16px' justifyContent='flex-end'>
                <AntSwitch checked={impactFilter ? impactFilter[opt.name] : false} color='primary' />
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
