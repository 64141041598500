import { TabletButton } from '@campfire/tablet-button';
import { Box, Theme, Typography } from '@material-ui/core';
import { BorderColorOutlined, FilterList, GetAppOutlined } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { SearchField } from '../../../../common/inputs/SearchField';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '4px',
      textTransform: 'none',
      marginRight: '10px',
      padding: '7px 12px',
      minWidth: '45px',
    },
    headerButton: {
      color: theme.color.grey.neutral400,
      borderColor: '#dcdcdc',
    },
    toggledButton: {
      color: '#FCFCFC !important',
      backgroundColor: '#646464 !important',
    },
    containedButton: {
      backgroundColor: theme.color.secondary.main900,
      color: '#ffffff',
      boxSizing: 'border-box',
      borderRadius: '4px',
      textTransform: 'none',
      minWidth: '40px',
    },
    buttonText: {
      marginLeft: '4px',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '19px',
      padding: '0px 7px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })
);

interface IncidentManagementScreenToolsProps {
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  handleExport?: () => void;
}

export const IncidentManagementToolbar = (props: IncidentManagementScreenToolsProps) => {
  const { showFilters, setShowFilters, handleExport } = props;
  const { theme, isMobile, isMd } = useCampfireTheme();
  const classes = useStyles();
  const [query, setQuery] = useQueryParams({
    search: StringParam,
  });

  return (
    <Box flex={1} justifyContent='flex-end' display='flex' alignItems='center'>
      <Box style={{ marginRight: '10px' }}>
        <SearchField
          placeholder='Search Activities'
          value={query.search}
          onChange={(e) => setQuery({ ...query, search: e.target.value as string })}
        />
      </Box>
      <ToggleButton
        classes={{
          root: classNames(classes.button, classes.headerButton),
          selected: classes.toggledButton,
        }}
        selected={showFilters}
        onClick={() => setShowFilters(!showFilters)}
      >
        <FilterList style={{ fontSize: '18px' }} />
        <Typography
          className={classes.buttonText}
          style={{
            display: isMobile || isMd ? 'none' : 'inherit',
          }}
        >
          Filters
        </Typography>
      </ToggleButton>
      <TabletButton
        variant='outlined'
        style={{
          color: theme.color.grey.neutral400,
          boxSizing: 'border-box',
          borderColor: '#dcdcdc',
          borderRadius: '4px',
          textTransform: 'none',
          padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
          minWidth: '40px',
          marginRight: '10px',
        }}
        onClick={handleExport}
      >
        <GetAppOutlined style={{ marginTop: '1px', fontSize: '20px' }} />
        <Typography className={classes.buttonText}>Export</Typography>
      </TabletButton>
      <Link to={'/volunteering/incident-report'} style={{ textDecoration: 'none' }}>
        <TabletButton
          variant='contained'
          style={{
            backgroundColor: theme.color.secondary.main900,
            color: '#ffffff',
            boxSizing: 'border-box',
            borderRadius: '4px',
            textTransform: 'none',
            padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
            minWidth: '40px',
          }}
        >
          <BorderColorOutlined style={{ marginTop: '1px', fontSize: '16px' }} />
          <Typography
            className={classes.buttonText}
            style={{
              display: isMobile || isMd ? 'none' : 'inherit',
            }}
          >
            Add New Report
          </Typography>
        </TabletButton>
      </Link>
    </Box>
  );
};
