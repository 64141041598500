import React from 'react';
import { Box, Typography } from '@material-ui/core';
import QueryString from 'qs';
import { GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents_widget_VOLUNTEER_NumberWidgetType as INumberWidget } from '../../dashboard-widget-setting/__generated__/GetDashboardPreference';
import { intervalChangeParsed, intervalParsed, rangeParsed } from '../number-widget/NumberWidget';
import { useApiUrl } from '../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../global/network/useCampfireFetch';
import { NumberWidgetProps, getEndDateFromRange, getStartDateFromRange } from '../shared';
import { TotalNumberConfiguration } from '../../dashboard-widget-setting/__generated__/TotalNumberConfiguration';
import { AverageNumberConfiguration } from '../../dashboard-widget-setting/__generated__/AverageNumberConfiguration';
import { TrackingNumberConfiguration } from '../../dashboard-widget-setting/__generated__/TrackingNumberConfiguration';

interface Props {
  widget: INumberWidget;
}

export function Highlight({ widget }: Props) {
  const { filters, targets } = widget;
  const { tag, name } = JSON.parse(targets)[0] as { tag: string; name: string };

  const title = React.useMemo(() => {
    switch (widget.configuration.__typename) {
      case 'VOLUNTEER_TotalNumberConfigurationType':
        return 'Totals';
      case 'VOLUNTEER_AverageNumberConfigurationType':
        return 'Average';
      case 'VOLUNTEER_TrackingNumberConfigurationType':
        return 'Totals';
      case 'VOLUNTEER_CustomMultiplationNumberConfigurationType':
        return 'Custom Calculation';
      case 'VOLUNTEER_TargetNumberConfigurationType':
        return 'Totals';
      default:
        return '';
    }
  }, [widget]);

  return (
    <Box>
      <Typography style={{ fontWeight: 900, fontSize: 10, color: '#444444' }}>{title}</Typography>
      <Box marginTop='2rem'>
        {(() => {
          if (widget.configuration.__typename === 'VOLUNTEER_TotalNumberConfigurationType') {
            return (
              <HighlightTotalNumber
                tag={tag}
                name={name}
                configuration={widget.configuration as TotalNumberConfiguration}
                filters={filters}
              />
            );
          }

          if (widget.configuration.__typename === 'VOLUNTEER_AverageNumberConfigurationType') {
            return (
              <HighlightAverageNumber
                tag={tag}
                name={name}
                configuration={widget.configuration as AverageNumberConfiguration}
                filters={filters}
              />
            );
          }

          if (widget.configuration.__typename === 'VOLUNTEER_TrackingNumberConfigurationType') {
            return (
              <HighlightTrackingNumber
                tag={tag}
                name={name}
                configuration={widget.configuration as TrackingNumberConfiguration}
                filters={filters}
              />
            );
          }
          return null;
        })()}
      </Box>
    </Box>
  );
}

type HighlightTotalNumberProps = NumberWidgetProps & {
  configuration: TotalNumberConfiguration;
};

export function HighlightTotalNumber(props: HighlightTotalNumberProps) {
  const { tag, configuration, name, filters } = props;
  const [value, setValue] = React.useState(0);
  const { range } = configuration;
  const apiUrl = useApiUrl();
  const campfireQuery = useCampfireFetch<{ count: number }>({ defer: true });

  React.useEffect(() => {
    campfireQuery
      .run({
        url: `${apiUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/total`,
        params: {
          startDate: getStartDateFromRange(range),
          endDate: getEndDateFromRange(range),
          filters: JSON.parse(filters),
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, { arrayFormat: 'brackets', encode: false });
        },
      })
      .then((response) => response?.data?.data && setValue(response.data.data.count));
  }, [range, filters]);

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' style={{ gap: 10 }}>
      <Typography style={{ fontSize: 9, fontWeight: 900, color: '#7570EA' }}>This {rangeParsed(range)}</Typography>
      <Typography style={{ fontSize: 12, fontWeight: 400, color: '#646464' }}>{value}</Typography>
    </Box>
  );
}

type HighlightAverageNumberProps = NumberWidgetProps & {
  configuration: AverageNumberConfiguration;
};

export function HighlightAverageNumber(props: HighlightAverageNumberProps) {
  const { tag, configuration, name, filters } = props;
  const [value, setValue] = React.useState('0');
  const { range, interval } = configuration;
  const apiUrl = useApiUrl();
  const campfireQuery = useCampfireFetch<{ average: number }>({ defer: true });

  React.useEffect(() => {
    campfireQuery
      .run({
        url: `${apiUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/average`,
        params: {
          range: interval,
          filters: JSON.parse(filters),
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, { arrayFormat: 'brackets', encode: false });
        },
      })
      .then((response) => {
        if (response.data?.data) {
          setValue(response.data.data.average.toFixed(1));
        }
      });
  }, [range, filters]);

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' style={{ gap: 10 }}>
      <Typography style={{ fontSize: 9, fontWeight: 900, color: '#7570EA' }}>
        {intervalParsed(interval)} This {rangeParsed(range)}
      </Typography>
      <Typography style={{ fontSize: 12, fontWeight: 400, color: '#646464' }}>{value}</Typography>
    </Box>
  );
}

type HighlightTrackingNumberProps = NumberWidgetProps & {
  configuration: TrackingNumberConfiguration;
};

export function HighlightTrackingNumber(props: HighlightTrackingNumberProps) {
  const { tag, configuration, name, filters } = props;
  const [value, setValue] = React.useState({ current: 0, last: 0 });
  const { interval } = configuration;
  const apiUrl = useApiUrl();
  const campfireQuery = useCampfireFetch<{ current: number; last: number }>({ defer: true });

  React.useEffect(() => {
    campfireQuery
      .run({
        url: `${apiUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/tracking`,
        params: {
          range: interval,
          filters: JSON.parse(filters),
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, { arrayFormat: 'brackets', encode: false });
        },
      })
      .then((response) => response?.data?.data && setValue(response.data.data));
  }, [filters, interval]);

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' style={{ gap: 10 }}>
        <Typography style={{ fontSize: 9, fontWeight: 900, color: '#7570EA' }}>
          This {intervalChangeParsed(interval)}
        </Typography>
        <Typography style={{ fontSize: 12, fontWeight: 400, color: '#646464' }}>{value.current}</Typography>
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='center' style={{ gap: 10 }} marginTop='1rem'>
        <Typography style={{ fontSize: 9, fontWeight: 900, color: '#7570EA' }}>
          Last {intervalChangeParsed(interval)}
        </Typography>
        <Typography style={{ fontSize: 12, fontWeight: 400, color: '#646464' }}>{value.last}</Typography>
      </Box>
    </>
  );
}
