import React, { useMemo } from 'react';
import { Dialog } from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  CreatWidgetFormValues,
  getWidgetType,
  getInterval,
  getNumberWidgetType,
  getChartWidgetType,
  getDimensionWidgetType,
  getRangeWidgetType,
  getGroup,
  getTarget,
  getCustomMultiplier,
  StepsEnum,
  DimensionEnum,
  getHighlight,
  RANGE_ENUM,
  INTERVAL_ENUM,
} from './dashboard-widget-model';
import { GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents as IComponent } from './__generated__/GetDashboardPreference';
import { WidgetForm } from './dialogs/WidgetForm';
import { WidgetContextProvider } from './WidgetContext';

interface Props {
  open: boolean;
  selectedWidget?: IComponent;
  onClose: () => void;
  handleSubmit: (values: CreatWidgetFormValues) => void;
}
export const CreateWidgetDialog = ({ selectedWidget, open, onClose, handleSubmit }: Props) => {
  const initialValues: CreatWidgetFormValues = useMemo(() => {
    const filters = selectedWidget
      ? JSON.parse(selectedWidget.widget.filters)
      : { programIds: [], activityIds: [], activityTags: [] };
    return {
      byValues: selectedWidget ? JSON.parse(selectedWidget.widget.targets) : [],
      byType: selectedWidget ? getWidgetType(selectedWidget.widget.__typename) : null,
      programIds: filters.programIds || ([] as string[]),
      activityIds: filters.activityIds || ([] as string[]),
      tagIds: filters.tagIds || ([] as string[]),
      interval: selectedWidget ? getInterval(selectedWidget) : INTERVAL_ENUM.MONTHLY,
      highlight: selectedWidget ? getHighlight(selectedWidget) : RANGE_ENUM.THIS_MONTH,
      numberWidgetType: selectedWidget ? getNumberWidgetType(selectedWidget) : null,
      chartWidgetType: selectedWidget ? getChartWidgetType(selectedWidget) : null,
      dimension: selectedWidget ? getDimensionWidgetType(selectedWidget) : DimensionEnum.BY_TIME,
      range: selectedWidget ? getRangeWidgetType(selectedWidget) : RANGE_ENUM.THIS_YEAR,
      order: null,
      target: selectedWidget ? getTarget(selectedWidget) : null,
      customMultiplier: selectedWidget ? getCustomMultiplier(selectedWidget) : null,
      group: getGroup(selectedWidget) ?? 'none',
      selectStep: !selectedWidget ? StepsEnum.SELECT_WIDGET : StepsEnum.SETTINGS,
      isNew: !selectedWidget,
      fieldIds: filters.fieldIds || ([] as string[]),
    };
  }, [selectedWidget]);

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { minWidth: '720px', maxWidth: '760px' } }} fullWidth>
      <WidgetContextProvider>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(formValues) => {
            handleSubmit({ ...formValues, range: formValues.range === 'all' ? null : formValues.range });
          }}
        >
          <Form>
            <WidgetForm onClose={onClose} />
          </Form>
        </Formik>
      </WidgetContextProvider>
    </Dialog>
  );
};
