import { AccessTime as TimeIcon } from '@material-ui/icons';
import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';
import { Field as FormikField, getIn, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';

/*
  Why material-ui/pickers KeyboardTimePicker Sucks
  - It doesn't support luxon very well. Need to provide the mask prop when using luxon otherwise won't allow you to properly edit the field
  - Also requires manual formatting of the textfield with the labelFunc prop
  - The AM/PM moves as you backspace through the current time

  Why formik p!$$e$ me off:
   - Even though it would make more sense for the field value to be a DateTime, it needs to be a string because of a weird case where
   the field is touched but the value is set to undefined, for some reason formik will then reset the fields touched object when submit is clicked and thus showError
   won't evaluate to true ie errors that were being displayed will no longer be shown. https://github.com/jaredpalmer/formik/issues/445#issuecomment-396534560
*/

interface Props {
  name: string;
  label: string;
  inputVariant?: 'standard' | 'outlined' | 'filled';
  mask?: string;
  required?: boolean;
  fullWidth?: boolean;
}

const TimePicker = (props: Omit<KeyboardTimePickerProps, 'onChange' | 'value'> & Props) => {
  const { name, inputVariant, mask, required, fullWidth, ...rest } = props;
  const formik = useFormikContext();

  return (
    <FormikField name={name}>
      {({ form: { errors, touched, setFieldValue, setFieldTouched } }: any) => {
        const fieldError = getIn(errors, name);
        const showError = getIn(touched, name) && !!fieldError;
        return (
          <KeyboardTimePicker
            {...rest}
            autoOk
            variant='inline'
            error={showError}
            helperText={showError ? fieldError : null}
            inputVariant={inputVariant || 'filled'}
            required={required}
            InputLabelProps={{ shrink: true }}
            placeholder={mask || '__:__ _M'}
            mask={mask || '__:__ _M'}
            keyboardIcon={<TimeIcon />}
            label={props.label}
            onChange={(baseTime: DateTime | null, rawValue: string | null | undefined) => {
              const autoParsedTime = baseTime && baseTime.isValid ? baseTime : null;

              const twelveHourParsedTime =
                !autoParsedTime && rawValue ? DateTime.fromFormat(rawValue, 'hh:mm a') : null;

              const timeToUse = autoParsedTime ?? twelveHourParsedTime;

              if (!timeToUse) {
                return setFieldValue(name, '');
              }

              return setFieldValue(name, timeToUse.set({ second: 0, millisecond: 0 }).toJSDate());
            }}
            // if value is set to undefined it will auto set to your current time, null will display placeholder, anything else displays Invalid DateTime
            value={getIn(formik.values, name) ? DateTime.fromJSDate(getIn(formik.values, name)) : null}
            labelFunc={(date) => {
              // This must return empty string if date is undefined otherwise masking won't work
              return date ? date.toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: true }) : '';
            }}
            fullWidth={fullWidth}
            onBlur={() => setFieldTouched(name, true)}
          />
        );
      }}
    </FormikField>
  );
};

export { TimePicker };
