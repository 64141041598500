import { Box, Typography } from '@material-ui/core';
import { DoneAll, LabelImportantRounded } from '@material-ui/icons';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { AlertCard } from '../../../../common/cards/alert-card/AlertCard';
import { InfoCard } from '../../../../common/cards/date-info-card/InfoCard';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ReportTypeStatusChip } from '../common/ReportTypeStatusChip';
import { getFieldTypeLabelAndIcon } from '../common/FieldTypeLabelAndIcon';
import { ReportTypeListSkeleton } from '../report-types/report-types-list/ReportTypeListSkeleton';
import { ReportManagementReportTypeField } from '../report-types/__generated__/ReportManagementReportTypeField';

interface ActivityListItemProps {
  field: ReportManagementReportTypeField;
}

const ReportFieldListEmptyMessage = () => (
  <Box py={2}>
    <AlertCard variant='info' title='There are no fields to show' />
  </Box>
);

const ReportFieldListItem = (props: ActivityListItemProps) => {
  const [query, setQuery] = useQueryParams({
    reportFieldId: StringParam,
  });
  const { field } = props;
  const { theme, isSm } = useCampfireTheme();
  const isFeatured = field.isHero;
  const isArchived = field.dateDeactivated;

  return (
    <Box my='12px' onClick={() => setQuery({ reportFieldId: field.fieldId })}>
      <InfoCard
        color={
          isFeatured
            ? theme.color.activities.status.pinned
            : isArchived
            ? theme.color.activities.status.ended
            : theme.color.activities.status.default
        }
        selected={query.reportFieldId === field.fieldId}
        size='m'
      >
        <Box display='flex' justifyContent='space-between' width={1}>
          <Typography
            variant='h6'
            noWrap
            style={{
              color: theme.color.grey.neutral400,
              width: '100%',
              fontWeight: 800,
              letterSpacing: '-0.02em',
              lineHeight: '1em',
            }}
          >
            {field.name}
          </Typography>
          <Box>
            <ReportTypeStatusChip isDeactivated={field.dateDeactivated} />
            {isFeatured && (
              <Box display='flex' component='span' alignItems='center'>
                <LabelImportantRounded style={{ color: theme.alert.yellow.light }} />
                <Box ml={0.5} component='span'>
                  <Typography variant='body2' color='textSecondary' style={{ fontSize: '12px' }}>
                    Pinned
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box display={isSm ? 'auto' : 'flex'} alignItems='center' mt='20px'>
          <Box style={{ marginRight: '24px' }}>{getFieldTypeLabelAndIcon(field.__typename)}</Box>
          {field.__typename === 'VOLUNTEER_DropdownFieldType' && field.isAllowMultiple ? (
            <Box display='flex' alignItems='center'>
              <DoneAll style={{ marginRight: 8, width: 16, height: 16, color: theme.color.grey.neutral300 }} />
              <Typography variant='caption' style={{ color: theme.color.grey.neutral300 }}>
                Multiple selection allowed
              </Typography>
            </Box>
          ) : null}
        </Box>
      </InfoCard>
    </Box>
  );
};

type ReportFieldsListProps = {
  fields: Array<ReportManagementReportTypeField | undefined>;
  reportFieldsLoading: boolean;
};

export const ReportFieldsList = (props: ReportFieldsListProps) => {
  const { fields, reportFieldsLoading } = props;

  if (reportFieldsLoading) return <ReportTypeListSkeleton />;

  if (fields.length === 0) return <ReportFieldListEmptyMessage />;

  const orderedFields = fields.sort((a) => {
    return a?.isHero ? -1 : 1;
  });

  return (
    <>
      <Typography display='block' variant='caption' color='textSecondary' style={{ marginTop: 20, marginBottom: 20 }}>
        Total <span style={{ fontWeight: 700 }}>{orderedFields.length} </span>{' '}
        {orderedFields.length === 1 ? 'Report Field' : 'Report Fields'}
      </Typography>
      {orderedFields.map((field) => field && <ReportFieldListItem key={field.fieldId} field={field} />)}
    </>
  );
};
