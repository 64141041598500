import { PaneWrapper } from '@campfire/pane';
import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { Page } from '../../../global/components';
import { CreateReportFieldForm } from './report-fields/report-field-form/CreateReportFieldForm';
import { ReportFields } from './report-fields/ReportFields';
import { CreateReportTypeForm } from './report-types/report-type-form/CreateReportTypeForm';
import { EditReportTypeForm } from './report-types/report-type-form/EditReportTypeForm';
import { ReportTypes } from './report-types/ReportTypes';
import { ReportManagementScreenHeaderWrapper } from './screen-header/ReportManagementScreenHeaderWrapper';

type ReportManagementMainSectionProps = {
  showFilters: boolean;
  searchInput: string;
};

const ReportManagementMainSection = (props: ReportManagementMainSectionProps) => {
  const [query] = useQueryParams({
    tab: StringParam,
    reportTypeId: StringParam,
  });

  return (
    <Box width='100%' position='relative' display='flex' flexDirection='row' height='100%' overflow='hidden'>
      {query.tab === 'report-fields' ? <ReportFields {...props} /> : <ReportTypes {...props} />}
    </Box>
  );
};

export const ReportManagementScreen = () => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');

  const [query] = useQueryParams({
    tab: StringParam,
    reportTypeId: StringParam,
    createReportType: BooleanParam,
    createReportField: BooleanParam,
    editReportType: BooleanParam,
  });

  return query.createReportType ? (
    <CreateReportTypeForm />
  ) : query.createReportField ? (
    <CreateReportFieldForm />
  ) : query.editReportType && query.reportTypeId ? (
    <EditReportTypeForm reportTypeId={query.reportTypeId} />
  ) : (
    <Page>
      <PaneWrapper>
        <ReportManagementScreenHeaderWrapper
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          setSearchInput={setSearchInput}
        >
          <ReportManagementMainSection showFilters={showFilters} searchInput={searchInput} />
        </ReportManagementScreenHeaderWrapper>
      </PaneWrapper>
    </Page>
  );
};
