import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { StringParam, useQueryParam, useQueryParams, ArrayParam, NumberParam } from 'use-query-params';

import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { GET_ROSTER_SIDEBAR_ACTIVITIES } from './roster-sidebar-activities.gql';
import {
  GetRosterSidebarActivities,
  GetRosterSidebarActivitiesVariables,
} from './__generated__/GetRosterSidebarActivities';
import { ActivityCard } from './ActivityCard';
import { RosterContext } from '../bulk-roster/RosterContext';
import {
  filterBySearch,
  filterByProgram,
  filterByLocation,
  filterByActivity,
  filterByStartTime,
  filterByEndTime,
  filterByDuration,
  filterByDay,
  filterByActivityActivenessStatus,
  filterByRosterType,
  getActivityActivenessStatus,
} from '../../../general/activities-v2/ActivityUtils/filters';
import { AlertCard } from '../../../../common/cards/alert-card/AlertCard';

interface RosterActivitiesProps {
  activityIds: string[];
}

export function RosterActivities(props: RosterActivitiesProps) {
  const { activityIds } = props;
  const { searchValue } = React.useContext(RosterContext);
  const [activityId, setActivityId] = useQueryParam('activityId', StringParam);
  const [query] = useQueryParams({
    programId: StringParam,
    activityType: StringParam,
    activityStatus: StringParam,
    days: ArrayParam,
    duration: NumberParam,
    rosterType: StringParam,
    startTime: StringParam,
    endTime: StringParam,
    location: StringParam,
  });
  const { data: vmActivities, loading } = useCampfireQuery<
    GetRosterSidebarActivities,
    GetRosterSidebarActivitiesVariables
  >(GET_ROSTER_SIDEBAR_ACTIVITIES, {
    options: {
      variables: {
        activityIds,
      },
    },
  });

  const parsedActivities = (vmActivities?.vm.activities || []).map((activity) => ({
    ...activity,
    activityDate: activity.nextX[0] || activity.startDate,
    activityStatus: getActivityActivenessStatus(activity),
  }));

  const filterActivities = parsedActivities
    .filter(filterByProgram(query.programId || 'all'))
    .filter(filterByLocation(query.location || 'all'))
    .filter(filterByActivity(query.activityType || 'all'))
    .filter(filterByDuration(query.duration || 0))
    .filter(filterByStartTime(query.startTime || ''))
    .filter(filterByEndTime(query.endTime || ''))
    .filter(filterByDay(query.days?.reduce((acc, day) => ({ ...acc, [parseInt(day, 10)]: true }), {}) || {}))
    .filter(filterBySearch(searchValue))
    .filter(filterByActivityActivenessStatus(query.activityStatus || 'all'))
    .filter(filterByRosterType(query.rosterType || 'all'));

  if (loading) {
    return (
      <Box display={'flex'} paddingTop={'20px'} justifyContent={'center'}>
        <CircularProgress />
      </Box>
    );
  }

  if (parsedActivities.length === 0) {
    return <AlertCard variant='info' title='There are no activities to show' />;
  }

  if (filterActivities.length === 0) {
    return <AlertCard variant='info' title='There are no activities that match your filter criteria' />;
  }

  return (
    <React.Fragment>
      {filterActivities.map((activity) => (
        <ActivityCard
          activity={activity}
          onClick={() => setActivityId(activity.activityId)}
          isSelected={activity.activityId === activityId}
          color={activity.activityStatus}
        />
      ))}
    </React.Fragment>
  );
}
