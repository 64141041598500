import { Box, Button, Grid, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { DaySelectArrayType } from '../../../general/activities-v2/ActivityFilter/DaySelect';
import { sharedStyles } from '../../../general/activities-v2/ActivityStyles/shared';
import { FilterOption, FilterValues } from '../incident-management-types';
import { activityTypeOptions, DAY_OPTIONS, initialFilters } from '../IncidentManagementConsts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.color.white.neutral50,
      borderRadius: '6px',
      paddingBottom: 18,
    },
    daySelectContainer: {
      width: 265,
      flex: 1,
      marginTop: '12px',
      [theme.breakpoints.down('sm')]: {
        width: 'unset',
      },
    },
    daySelectRoot: {
      alignSelf: 'start',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'unset',
      },
    },
    label: {
      textTransform: 'uppercase',
      color: theme.color.grey.neutral300,
      fontWeight: 700,
      fontSize: '0.75rem',
      marginBottom: '8px',
    },
    timeBorder: {
      borderBottom: 'solid 1px',
      borderBottomColor: theme.color.grey.neutralBrand200,
    },
    actionContainer: {
      display: 'flex',
      marginTop: 22,
      marginLeft: 16,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        justifyContent: 'center',
      },
    },
    button: {
      fontSize: 13,
      fontWeight: 500,
      borderRadius: 6,
    },
    showButton: {
      backgroundColor: theme.color.secondary.main900,
      color: 'white',
      '&:hover': {
        opacity: 0.8,
      },
    },
    clearButton: {
      backgroundColor: theme.color.secondary.main50,
      color: theme.color.secondary.main700,
      marginLeft: 8,
      '&:hover': {
        opacity: 0.8,
      },
    },
  })
);

type Props = {
  values: FilterValues;
  onChange: (arg: any) => void;
  programOptions: FilterOption[];
  severityOptions: FilterOption[];
};

export const IncidentFilters = (props: Props) => {
  const { values, onChange, programOptions, severityOptions } = props;
  const [filters, setFilters] = useState(values);
  const classes = useStyles();
  const { isSm } = useCampfireTheme();

  useEffect(() => {
    setFilters(values);
  }, [values]);

  const onSelect = (e: any) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectDay = (dayValue: number) =>
    setFilters({
      ...filters,
      day: filters.day.includes(dayValue) ? filters.day.filter((day) => day !== dayValue) : [...filters.day, dayValue],
    });

  const onClear = () => {
    setFilters(initialFilters);
    onChange(initialFilters);
  };

  const onSubmit = () => {
    onChange({
      program: filters.program,
      activityType: filters.activityType,
      severity: filters.severity,
      day: filters.day,
    });
  };
  const inputClasses = sharedStyles.textField();

  return (
    <Box className={classes.container}>
      <Grid container spacing={isSm ? 2 : 3} style={{ width: 'unset', margin: 0 }}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography className={classes.label}>Programs</Typography>
          <TextField
            name='program'
            value={filters.program}
            select
            variant='outlined'
            fullWidth
            onChange={onSelect}
            InputProps={{ classes: inputClasses }}
          >
            {programOptions
              .sort((a, b) => a.label.localeCompare(b.label))
              .map(({ value, label }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography className={classes.label}>Activity Types</Typography>
          <TextField
            name='activityType'
            value={filters.activityType}
            select
            variant='outlined'
            fullWidth
            onChange={onSelect}
            InputProps={{ classes: inputClasses }}
          >
            {activityTypeOptions.map(({ value, label }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography className={classes.label}>SEVERITY</Typography>
          <TextField
            name='severity'
            value={filters.severity}
            select
            variant='outlined'
            fullWidth
            onChange={onSelect}
            InputProps={{ classes: inputClasses }}
          >
            {severityOptions
              .sort((a, b) => a.label.localeCompare(b.label))
              .map(({ value, label }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <Box className={classes.daySelectRoot}>
            <Box className={classes.timeBorder}>
              <Typography className={classes.label}>Day</Typography>
            </Box>
            <DaySelectArrayType
              days={DAY_OPTIONS}
              daysSelected={filters.day}
              onSelectDay={onSelectDay}
              className={classes.daySelectContainer}
            />
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.actionContainer}>
        <Button
          onClick={onSubmit}
          className={`${classes.button} ${classes.showButton}`}
          variant='contained'
          disableElevation
        >
          Show the results
        </Button>
        <Button
          onClick={onClear}
          className={`${classes.button} ${classes.clearButton}`}
          variant='contained'
          disableElevation
        >
          Clear
        </Button>
      </Box>
    </Box>
  );
};
