import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { ArrowDropDown, Check } from '@material-ui/icons';
import { useQueryParam } from 'use-query-params';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { BorderSelectInput, useFilterStyles } from './FilterClasses';
import { FilterDataType, Filters, StandardTypeFilterType } from './filters';

function getThisFilter(selectedFilters: Filters, urlParamKey: string): undefined | StandardTypeFilterType {
  if (urlParamKey === 'status') return selectedFilters.selectedStatusFilter;
  if (urlParamKey === 'duration') return selectedFilters.selectedDurationFilter;
  if (urlParamKey === 'type') return selectedFilters.selectedActivityTypeFilter;
  if (urlParamKey === 'roster') return selectedFilters.selectedRosterTypeFilter;
  if (urlParamKey === 'location') return selectedFilters.selectedLocationFilter;

  return undefined;
}

type StandardFilterSelectFieldProps = {
  selectedFilters: Filters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  filterData: FilterDataType;
};

const StandardFilterSelectField = (props: StandardFilterSelectFieldProps) => {
  const { selectedFilters, filterData } = props;

  const thisFilter = getThisFilter(selectedFilters, filterData.urlParamKey);

  if (!thisFilter) return null;

  return <StandardFilterSelectFieldInner {...props} thisFilter={thisFilter} />;
};

type StandardFilterSelectFieldInnerProps = {
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  thisFilter: StandardTypeFilterType;
  filterData: FilterDataType;
};

const StandardFilterSelectFieldInner = (props: StandardFilterSelectFieldInnerProps) => {
  const { setSelectedFilters, filterData, thisFilter } = props;
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const { filterName, options, defaultOption, urlParamKey } = filterData;
  const [urlParam, setUrlParam] = useQueryParam<string>(urlParamKey);

  const isActiveFilter = thisFilter.value !== options[defaultOption].value;
  const isFilterSelected = (value: string) => thisFilter.value === value;

  const handleFilterChange = (value: string) => {
    const isDefault = value === options[defaultOption].value;
    setSelectedFilters((prev) => ({
      ...prev,
      [`selected${filterName}Filter`]: {
        ...thisFilter,
        value: value,
        label: filterData.options.find((x) => x.value === value)?.label ?? '',
        urlParamKey,
        isActive: !isDefault,
      },
    }));
  };

  useEffect(() => {
    if (!urlParam) {
      handleFilterChange(options[defaultOption].value);
      return;
    }
    handleFilterChange(urlParam);
  }, [urlParam]);

  return (
    <Grid item className={classes.subheaderGridItemNew}>
      <Select
        data-track={`actCnlMapFilters-${filterName}FilterSelectField`}
        displayEmpty
        open={popoverOpen}
        fullWidth
        input={
          <BorderSelectInput
            status={isActiveFilter ? 'active' : undefined}
            onClick={() => setPopoverOpen(!popoverOpen)}
            style={{ cursor: 'pointer' }}
          />
        }
        IconComponent={() => (
          <Box display='flex' alignItems='center' onClick={() => setPopoverOpen(true)} style={{ cursor: 'pointer' }}>
            <ArrowDropDown style={{ fill: isActiveFilter ? theme.palette.primary.main : 'rgba(0,0,0, 0.54)' }} />
          </Box>
        )}
        renderValue={() => thisFilter.label}
        value={urlParam ?? thisFilter.value}
      >
        {options.map((filter) => (
          <MenuItem
            key={filter.value}
            value={filter.value}
            className={classes.menuItem}
            onClick={() => setUrlParam(filter.value)}
          >
            <Typography
              variant='body2'
              style={isFilterSelected(filter.label) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
            >
              {filter.label}
            </Typography>
            <Checkbox
              checked={isFilterSelected(filter.label)}
              className={classes.checkBoxSelectField}
              checkedIcon={<Check color='primary' width={12} />}
              icon={<span className={classes.icon} />}
              disableRipple
            />
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

type FilterSelectFieldProps = {
  filterData: FilterDataType;
  value: string;
  onChange: (newValue: string) => void;
};

const FilterSelectField = (props: FilterSelectFieldProps) => {
  const { onChange, filterData, value } = props;
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const { filterName, options, defaultOption } = filterData;

  const isActiveFilter = value !== options[defaultOption].value;
  const isFilterSelected = (filteredValue: string) => value === filteredValue;
  return (
    <Grid item className={classes.subheaderGridItemNew}>
      <Select
        data-track={`actCnlMapFilters-${filterName}FilterSelectField`}
        displayEmpty
        open={popoverOpen}
        fullWidth
        input={
          <BorderSelectInput
            status={isActiveFilter ? 'active' : undefined}
            onClick={() => setPopoverOpen(!popoverOpen)}
            style={{ cursor: 'pointer' }}
          />
        }
        IconComponent={() => (
          <Box display='flex' alignItems='center' onClick={() => setPopoverOpen(true)} style={{ cursor: 'pointer' }}>
            <ArrowDropDown style={{ fill: isActiveFilter ? theme.palette.primary.main : 'rgba(0,0,0, 0.54)' }} />
          </Box>
        )}
        renderValue={() => options.find((item) => item.value === value)?.label}
        value={value}
      >
        {options.map((filter) => (
          <MenuItem
            key={filter.value}
            value={filter.value}
            className={classes.menuItem}
            onClick={() => onChange(filter.value)}
          >
            <Typography
              variant='body2'
              style={isFilterSelected(filter.label) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
            >
              {filter.label}
            </Typography>
            <Checkbox
              checked={isFilterSelected(filter.label)}
              className={classes.checkBoxSelectField}
              checkedIcon={<Check color='primary' width={12} />}
              icon={<span className={classes.icon} />}
              disableRipple
            />
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export { StandardFilterSelectField, FilterSelectField };
