import React, { useEffect, useMemo } from 'react';
import { Box, Theme, Grid, Typography, Divider } from '@material-ui/core';
import { Select } from '@campfire/select';
import { MultiSelect } from '@campfire/multi-select';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import { CreatWidgetFormValues, rangeList, secondaryRangeList } from '../../dashboard-widget-model';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';
import { useWidgetContextForm } from '../../useWidgetContextForm';
import { SelectedValues } from '../SelectedValues';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.color.grey.neutral500,
      fontWeight: 700,
      fontSize: '18px',
      marginBottom: '8px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 700,
      color: theme.color.grey.neutral500,
      paddingBottom: '6px',
    },
    valueItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '56px',
      borderRadius: '6px',
      marginTop: '-8px',
      padding: '0 16px',
      cursor: 'pointer',
      position: 'relative',

      border: `1px solid ${theme.color.grey.neutralBrand200}`,
    },
  })
);

export const TableWidgetForm = () => {
  const { theme } = useCampfireTheme();
  const classes = useStyles();
  const { programs, activities, activityTags } = useWidgetContextForm();

  const { values, setFieldValue } = useFormikContext<CreatWidgetFormValues>();

  const isRangeDefaultPrimary = values.range === 'THIS_YEAR';
  const isRangeDefaultSecondary = values.range === 'all';

  const isRosterCancellationTable = useMemo(() => {
    return values.byValues.some(
      (value) => value.tag.toLowerCase() === 'rosters' && value.name.toLowerCase() === 'cancellation'
    );
  }, [values.byValues]);

  useEffect(() => {
    if (isRangeDefaultPrimary && !isRosterCancellationTable) setFieldValue('range', 'all');
    if (isRangeDefaultSecondary && isRosterCancellationTable) setFieldValue('range', 'THIS_YEAR');
  }, [isRangeDefaultPrimary, isRangeDefaultSecondary, isRosterCancellationTable]);

  return (
    <Grid container direction='column' style={{ width: '100%', marginBottom: '16px' }}>
      <Grid item xs={12}>
        <Typography className={classes.title}>Filtering</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography className={classes.label}>Programs</Typography>
            <MultiSelect
              variant='outlined'
              name='programIds'
              label='Select program/s'
              placeholder='All Programs'
              items={programs}
              style={{ borderRadius: '8px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.label}>Activities</Typography>
            <MultiSelect
              variant='outlined'
              name='activityIds'
              label='Select activity/activities'
              placeholder='All Activities'
              items={activities}
              style={{ borderRadius: '8px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.label}>Activity Tags</Typography>
            <MultiSelect
              variant='outlined'
              name='tagIds'
              placeholder='All Activity Tag'
              label='Select tag/s'
              items={activityTags}
              style={{ borderRadius: '8px' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box my={3}>
        <Divider />
      </Box>
      <Grid item xs={12}>
        <Typography className={classes.title}>Range</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography className={classes.label}>Range</Typography>
            <Select
              fullWidth
              name='range'
              variant='outlined'
              items={isRosterCancellationTable ? rangeList : secondaryRangeList}
              renderFunction={(item) => item.label}
              valueFunction={(item) => item.value}
              InputProps={{
                style: { borderRadius: '8px', color: theme.color.grey.neutral300 },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box my={3}>
        <Divider />
      </Box>
      <SelectedValues maxValue={1} />
    </Grid>
  );
};
