import { AntSwitch } from '@campfire/ant-switch';
import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import { List as ListIcon } from '@material-ui/icons';
import { mapValues, orderBy } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { VOLUNTEER_PROFILE_TASK_ORDER } from './VolunteerDatabaseDesktopTable';
import { VolunteerDatabaseTasks } from './__generated__/VolunteerDatabaseTasks';

interface Props {
  tasks: VolunteerDatabaseTasks[];
  setTasksFilter?: (x: any) => void;
  tasksFilter?: { [key: string]: boolean };
}

export const VolunteerDatabaseTasksFilter = (props: Props) => {
  const { tasks, setTasksFilter, tasksFilter } = props;
  const tasksButtonRef = useRef<HTMLButtonElement | null>(null);
  const [tasksMenu, setTasksMenu] = useState(false);

  const handleSelectAll = () => {
    const newFilters = mapValues(tasksFilter, () => true);
    if (setTasksFilter) setTasksFilter(newFilters);
  };

  const selectedTasksCount = useMemo(
    () =>
      tasksFilter ? Object.keys(tasksFilter).reduce((total, curr) => (tasksFilter[curr] ? total + 1 : total), 0) : 0,
    [tasksFilter]
  );

  const onClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, t: VolunteerDatabaseTasks) => {
    e.stopPropagation();
    if (setTasksFilter && tasksFilter)
      setTasksFilter({
        ...tasksFilter,
        [t.taskId]: !tasksFilter[t.taskId],
      });
  };

  return (
    <Box>
      <TabletButton
        variant='outlined'
        ref={tasksButtonRef}
        onClick={() => setTasksMenu(true)}
        startIcon={<ListIcon fontSize='small' />}
      >
        {'Tasks'}
      </TabletButton>
      <Menu
        id='tasks-filter-menu'
        anchorEl={tasksButtonRef ? tasksButtonRef.current : null}
        keepMounted
        open={tasksMenu}
        onClose={() => setTasksMenu(false)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleSelectAll}>
          <Box display='flex' flex={1} flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Typography variant='caption' color='textSecondary'>{`${selectedTasksCount} task${
              selectedTasksCount === 1 ? '' : 's'
            } selected`}</Typography>
            <HoverText variant='body2'>{'Select All'}</HoverText>
          </Box>
        </MenuItem>
        {orderBy(tasks, (task) => task.order).map((t) => {
          const relevantTaskItems =
            t.taskItems.filter((ti) => ti.__typename === 'VOLUNTEER_TaskItemFieldType').length +
            (t.order === VOLUNTEER_PROFILE_TASK_ORDER ? 6 : 0);

          return (
            <MenuItem key={t.taskId} onClick={(e) => onClick(e, t)}>
              <Box display='flex' flex={1} flexDirection='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='body2'>{t.title}</Typography>
                <Box display='flex' flexDirection='row' alignItems='center' marginLeft='16px' justifyContent='flex-end'>
                  <Typography display='inline' variant='caption' color='textSecondary'>{`${relevantTaskItems} field${
                    relevantTaskItems !== 1 ? 's' : ''
                  }`}</Typography>
                  <AntSwitch key={uuidv4()} checked={tasksFilter ? tasksFilter[t.taskId] : false} color='primary' />
                </Box>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
