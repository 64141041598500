import React from 'react';
import { TaskCompleteIcon } from '../../../../../../common/icons/TaskCompleteIcon';
import { TaskPendingReviewIcon } from '../../../../../../common/icons/TaskPendingReviewIcon';
import { IncomingVolunteerTaskList } from '../IncomingVolunteerTaskList';

interface Props {
  status: 'none' | 'pending' | 'approved';
  taskRecentActivityStatus: string;
  onClick?: () => void;
}

const IncomingVolunteersApplicationTaskSection = ({ status, taskRecentActivityStatus, onClick }: Props) => {
  return (
    <IncomingVolunteerTaskList
      header='Application'
      locked={!onClick}
      items={[
        {
          taskId: 'application-task-id-spoof',
          icon:
            status === 'pending' ? <TaskPendingReviewIcon /> : <TaskCompleteIcon isComplete={status === 'approved'} />,
          taskTitle: 'Complete Sign Up',
          taskSubText: status === 'pending' ? 'Pending review' : undefined,
          taskRecentActivityStatus: taskRecentActivityStatus,
          onClick,
          adminOnly: false,
        },
      ]}
    />
  );
};

export { IncomingVolunteersApplicationTaskSection };
