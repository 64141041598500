import { Box } from '@material-ui/core';
import React from 'react';
import { IncidentManagementStatusMobileMenu } from './IncidentManagementStatusMobileMenu';
import { IncidentManagementToolbar } from './IncidentManagementToolbar';

interface IncidentManagementMobileScreenHeaderProps {
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  hide: boolean;
}

export const IncidentManagementMobileScreenHeader = (props: IncidentManagementMobileScreenHeaderProps) => {
  const { showFilters, setShowFilters } = props;

  return (
    <Box
      margin='20px 20px 0px 20px'
      display={props.hide ? 'none' : 'flex'}
      alignItems='center'
      style={{ paddingBottom: '10px', borderBottom: '2px solid #EFEFEF' }}
    >
      <IncidentManagementStatusMobileMenu />
      <IncidentManagementToolbar showFilters={showFilters} setShowFilters={setShowFilters} />
    </Box>
  );
};
