import React from 'react';
import { SelectActivityCancellationReasonDialog } from '../../activities/activity-timeline/common/SelectActivityCancellationReasonDialog';
import { RosterContext } from './RosterContext';

export function ActivityCancelDialog() {
  const {
    showCancellationReasonDialog,
    setShowCancellationReasonDialog,
    runCancelActivity,
    activity,
    cancelActivityDate: activityDate,
  } = React.useContext(RosterContext);

  if (!activity || !activityDate) {
    return null;
  }

  return (
    <SelectActivityCancellationReasonDialog
      open={showCancellationReasonDialog}
      handleClose={() => setShowCancellationReasonDialog(false)}
      handleSelection={runCancelActivity}
      activityName={activity?.name || ''}
      activityDate={activityDate}
    />
  );
}
