import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { Page } from '../../../global/components';
import { PageHelpOptions } from '../../../global/page-help/PageHelpContext';
import { MyActivitiesTutorialDialog } from '../my-activities/MyActivitiesTutorialDialog';
import { MyElements } from './my-elements/MyElements';

const UserProfileScreen = withRouter(() => {
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState(false);

  const pageHelpOptions = useMemo<PageHelpOptions>(() => {
    return {
      onClick: () => {
        setTutorialDialogOpen(true);
      },
    };
  }, []);

  return (
    <>
      <MyActivitiesTutorialDialog open={tutorialDialogOpen} onClose={() => setTutorialDialogOpen(false)} />
      <Page pageHelpOptions={pageHelpOptions}>
        <MyElements />
      </Page>
    </>
  );
});

export { UserProfileScreen };
