import React, { CSSProperties, RefObject } from 'react';
import Icon from '@mdi/react';
import {
  mdiAccount,
  mdiCardAccountDetails,
  mdiBadgeAccountHorizontal,
  mdiAccountSchool,
  mdiAccountHardHat,
  mdiAccountCowboyHat,
  mdiAccountKey,
  mdiAccountTieHat,
  mdiSubwayVariant,
  mdiBus,
  mdiFerry,
  mdiAirplane,
  mdiAmbulance,
  mdiBicycle,
  mdiSailBoat,
  mdiAtv,
  mdiCar,
  mdiForklift,
  mdiHelicopter,
  mdiVanUtility,
  mdiTruck,
  mdiSchool,
  mdiPalette,
  mdiCamera,
  mdiEmail,
  mdiPhone,
  mdiPhoneIncoming,
  mdiPhoneOutgoing,
  mdiVideoVintage,
  mdiBullhorn,
  mdiTea,
  mdiKeyVariant,
  mdiBugle,
  mdiController,
  mdiDatabase,
  mdiTshirtCrew,
  mdiDelete,
  mdiFireExtinguisher,
  mdiMovieOpen,
  mdiNeedle,
  mdiPoliceBadge,
  mdiRoutes,
  mdiRun,
  mdiHumanDolly,
  mdiHumanMaleBoardPoll,
  mdiSki,
  mdiBiathlon,
  mdiSkateboarding,
  mdiBed,
  mdiBathtub,
  mdiSwim,
  mdiSurfing,
  mdiHorseHuman,
  mdiKayaking,
  mdiBike,
  mdiWalk,
  mdiWheelchairAccessibility,
  mdiDogSide,
  mdiDolphin,
  mdiAlien,
  mdiCow,
  mdiFishbowl,
  mdiHorse,
  mdiRabbit,
  mdiShark,
  mdiCommentText,
  mdiStar,
  mdiChat,
  mdiDramaMasks,
  mdiFire,
  mdiHeart,
  mdiHome,
  mdiPowerPlug,
  mdiRadioactive,
  mdiRecycle,
  mdiSpeaker,
  mdiTrafficLight,
  mdiWheelchair,
  mdiBeach,
  mdiGlassWine,
  mdiGlassMugVariant,
  mdiFood,
  mdiFoodApple,
  mdiSilverwareForkKnife,
  mdiSilverwareVariant,
  mdiSnowflake,
  mdiWater,
  mdiWhiteBalanceSunny,
  mdiAccountInjury,
  mdiAccountWrench,
  mdiBabyBuggy,
  mdiBalloon,
  mdiBarley,
  mdiCarWrench,
  mdiCrown,
  mdiEarth,
  mdiPartyPopper,
  mdiEmoticonCoolOutline,
  mdiFilm,
  mdiFish,
  mdiFridge,
  mdiHomeFlood,
  mdiHomeGroup,
  mdiHook,
  mdiHorseVariant,
  mdiWashingMachine,
  mdiWrench,
} from '@mdi/js';

export const iconList = {
  account: mdiAccount,
  'card-account-details': mdiCardAccountDetails,
  'badge-account-horizontal': mdiBadgeAccountHorizontal,
  'account-school': mdiAccountSchool,
  'account-hard-hat': mdiAccountCowboyHat,
  'account-cowboy-hat': mdiAccountHardHat,
  'account-key': mdiAccountKey,
  'account-tie-hat': mdiAccountTieHat,
  'subway-variant': mdiSubwayVariant,
  bus: mdiBus,
  ferry: mdiFerry,
  airplane: mdiAirplane,
  ambulance: mdiAmbulance,
  bicycle: mdiBicycle,
  'sail-boat': mdiSailBoat,
  atv: mdiAtv,
  car: mdiCar,
  forklift: mdiForklift,
  helicopter: mdiHelicopter,
  'van-utility': mdiVanUtility,
  truck: mdiTruck,
  school: mdiSchool,
  palette: mdiPalette,
  camera: mdiCamera,
  email: mdiEmail,
  phone: mdiPhone,
  'phone-incoming': mdiPhoneIncoming,
  'phone-outgoing': mdiPhoneOutgoing,
  'video-vintage': mdiVideoVintage,
  bullhorn: mdiBullhorn,
  tea: mdiTea,
  'key-variant': mdiKeyVariant,
  bugle: mdiBugle,
  controller: mdiController,
  database: mdiDatabase,
  't-shirt-crew': mdiTshirtCrew,
  delete: mdiDelete,
  'fire-extinguisher': mdiFireExtinguisher,
  'move-open': mdiMovieOpen,
  needle: mdiNeedle,
  'police-badge': mdiPoliceBadge,
  routes: mdiRoutes,
  run: mdiRun,
  'humman-dolly': mdiHumanDolly,
  'humman-male-board-poll': mdiHumanMaleBoardPoll,
  ski: mdiSki,
  biathlon: mdiBiathlon,
  sketaboaring: mdiSkateboarding,
  bed: mdiBed,
  bathtub: mdiBathtub,
  swim: mdiSwim,
  surfing: mdiSurfing,
  'horse-human': mdiHorseHuman,
  kayaking: mdiKayaking,
  bike: mdiBike,
  walk: mdiWalk,
  'wheelchair-accessibility': mdiWheelchairAccessibility,
  'dog-side': mdiDogSide,
  dolphin: mdiDolphin,
  alien: mdiAlien,
  cow: mdiCow,
  fishbowl: mdiFishbowl,
  horse: mdiHorse,
  rabbit: mdiRabbit,
  shark: mdiShark,
  'comment-text': mdiCommentText,
  star: mdiStar,
  chat: mdiChat,
  'drama-marks': mdiDramaMasks,
  fire: mdiFire,
  heart: mdiHeart,
  home: mdiHome,
  'power-plug': mdiPowerPlug,
  radioactive: mdiRadioactive,
  recycle: mdiRecycle,
  speaker: mdiSpeaker,
  'traffic-light': mdiTrafficLight,
  wheelchair: mdiWheelchair,
  beach: mdiBeach,
  'glass-wine': mdiGlassWine,
  'glass-mug-variant': mdiGlassMugVariant,
  food: mdiFood,
  'food-apple': mdiFoodApple,
  'sliverware-fork-knife': mdiSilverwareForkKnife,
  'sliverware-variant': mdiSilverwareVariant,
  snowflake: mdiSnowflake,
  water: mdiWater,
  'white-balance-sunny': mdiWhiteBalanceSunny,
  'account-injury': mdiAccountInjury,
  'account-wrench': mdiAccountWrench,
  'baby-buggy': mdiBabyBuggy,
  balloon: mdiBalloon,
  barley: mdiBarley,
  'car-wrench': mdiCarWrench,
  crown: mdiCrown,
  earth: mdiEarth,
  'party-popper': mdiPartyPopper,
  'emoticon-cool-outline': mdiEmoticonCoolOutline,
  film: mdiFilm,
  fish: mdiFish,
  fridge: mdiFridge,
  'home-flood': mdiHomeFlood,
  'home-group': mdiHomeGroup,
  hook: mdiHook,
  'horse-variant': mdiHorseVariant,
  'washing-machine': mdiWashingMachine,
  wrench: mdiWrench,
};

interface RoleIconProps {
  iconName: string;
  id?: string;
  ref?: RefObject<SVGSVGElement>;
  title?: string | null;
  description?: string | null;
  size?: number | string | null;
  color?: string | null;
  horizontal?: boolean;
  vertical?: boolean;
  rotate?: number;
  spin?: boolean | number;
  style?: CSSProperties;
  inStack?: boolean;
}

export const RoleIcon = ({ iconName, ...otherProps }: RoleIconProps) => (
  <Icon path={iconList[iconName as keyof typeof iconList]} {...otherProps} />
);
