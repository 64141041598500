import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box } from '@material-ui/core';
import { ChevronRightRounded } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { TemplateSnackbar } from '../../../../common/snackbars/TemplateSnackbar';
import { useOnboardingContext } from '../../../../global/onboarding/useOnboardingContext';
import {
  ACTIVITY_ENROLMENT_PROMPT_COPY_GENERAL,
  ACTIVITY_ENROLMENT_PROMPT_COPY_PRIVATE,
} from './activity-enrolment-prompt-copy';
import { ActivityEnrolmentPrompt } from './ActivityEnrolmentPrompt';
import { useActivityApplicationFetch } from './use-activity-application-fetch';

type Props = {
  isVolunteerEnrolled?: boolean;
  isWaitingToBeEnrolled: boolean;
  volunteerHasAvailability: boolean;
  activityId: string;
  activityApplicationId?: string;
  programsLoading: boolean;
  refetch?: () => void;
};
const ActivitiesExplorePrivateActivityApplication = ({
  isVolunteerEnrolled,
  isWaitingToBeEnrolled,
  volunteerHasAvailability,
  activityId,
  activityApplicationId,
  programsLoading,
  refetch,
}: Props) => {
  const {
    activityApplicationPostRequest,
    withdrawApplicationLoading,
    createApplicationLoading,
    snackBarOptions,
  } = useActivityApplicationFetch(refetch);
  const { currentOnboardingStep, refresh } = useOnboardingContext();

  const successHandler = () => {
    if (currentOnboardingStep === 1) refresh();
  };

  const enrollmentPromptProps = isVolunteerEnrolled
    ? {
        ...(volunteerHasAvailability
          ? ACTIVITY_ENROLMENT_PROMPT_COPY_GENERAL.keepUpToDate
          : ACTIVITY_ENROLMENT_PROMPT_COPY_GENERAL.chooseAvailability),
        action: (
          <Link style={{ color: 'inherit', textDecoration: 'none' }} to={`my-activities`}>
            <TabletButton
              variant='contained'
              size='medium'
              color='primary'
              endIcon={<ChevronRightRounded />}
              onClick={() =>
                activityApplicationPostRequest(
                  {
                    activityId: activityId,
                    activityDates: undefined,
                  },
                  'create',
                  successHandler
                )
              }
            >
              {'View Upcoming Dates'}
            </TabletButton>
          </Link>
        ),
        progress: volunteerHasAvailability ? undefined : 66,
      }
    : isWaitingToBeEnrolled
    ? {
        ...ACTIVITY_ENROLMENT_PROMPT_COPY_PRIVATE.waiting,
        action: (
          <TabletButton
            variant='text'
            size='medium'
            color='default'
            onClick={() =>
              activityApplicationPostRequest(
                {
                  activityApplicationId: activityApplicationId,
                },
                'withdraw'
              )
            }
          >
            {'Withdraw'}
          </TabletButton>
        ),
        progress: 33,
      }
    : {
        ...ACTIVITY_ENROLMENT_PROMPT_COPY_PRIVATE.apply,
        action: (
          <TabletButton
            variant='contained'
            size='medium'
            color='primary'
            onClick={() =>
              activityApplicationPostRequest(
                {
                  activityId: activityId,
                  activityDates: undefined,
                },
                'create',
                successHandler
              )
            }
          >
            {'Apply Now'}
          </TabletButton>
        ),
        progress: 0,
      };

  return (
    <>
      {snackBarOptions && (
        <TemplateSnackbar
          variant={snackBarOptions.variant}
          message={snackBarOptions.message}
          open={snackBarOptions.open}
        />
      )}

      <Box position='relative'>
        <CircularProgressOverlay
          isLoading={createApplicationLoading || withdrawApplicationLoading || programsLoading}
        />

        <ActivityEnrolmentPrompt {...enrollmentPromptProps} />
      </Box>
    </>
  );
};

export { ActivitiesExplorePrivateActivityApplication };
