import { gql } from '@apollo/client';

export const GET_WIDGET_PROGRAM_APPLICATIONS = gql`
  query GetWidgetProgramApplications($programIds: [String!], $range: String, $widgetType: String) {
    vm {
      programApplications(programIds: $programIds, range: $range, widgetType: $widgetType) {
        programApplicationId
        volunteer {
          volunteerId
          profile {
            profileId
            userId
            preferredName
            firstName
            lastName
            avatarUrl
          }
          dateDeactivated
          flagging {
            dateFlagged
          }
        }
        program {
          name
          programId
          dateSuspended
          dateDeleted
        }
        dateApplied
        dateWithdrawn
        dateActioned
      }
    }
  }
`;
