import { Pane, PaneScrollable } from '@campfire/pane';
import React, { memo } from 'react';
import { ActivityTimelineNoSelection } from './ActivityTimelineNoSelection';
import { ActivityTimelineFuture } from './future/ActivityTimelineFuture';
import { ActivityTimelineManagement } from './management/ActivityTimelineManagement';
import { ActivityTimelinePast } from './past/ActivityTimelinePast';
import { PastRostersSkeletonLoadingLayout } from './past/roster/ActivityTimelinePastRoster';
import { useActivityTimelineContext } from './ActivityTimelineContext';

export const ActivityTimelineSelected = memo((props: { returnSlug: string }) => {
  const { returnSlug } = props;

  const {
    selectedActivityId,
    selectedDate,
    selectedSidebarItem,
    patchSelectedSidebarItem,
  } = useActivityTimelineContext();

  return selectedActivityId ? (
    <>
      {selectedDate ? (
        selectedSidebarItem ? (
          selectedSidebarItem.type === 'past' ? (
            <Pane>
              <PaneScrollable>
                <ActivityTimelinePast
                  pastSidebarItem={selectedSidebarItem}
                  patchSelectedSidebarItem={patchSelectedSidebarItem}
                />
              </PaneScrollable>
            </Pane>
          ) : (
            <ActivityTimelineFuture
              futureSidebarItem={selectedSidebarItem}
              patchSelectedSidebarItem={patchSelectedSidebarItem}
            />
          )
        ) : (
          <PastRostersSkeletonLoadingLayout />
        )
      ) : (
        <ActivityTimelineManagement activityId={selectedActivityId} returnSlug={returnSlug} />
      )}
    </>
  ) : (
    <ActivityTimelineNoSelection />
  );
});
