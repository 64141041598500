import { Grid, Tab, Tabs, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import InstructionsActivity from './InstructionsActivityTable';
import InstructionsImpact from './InstructionsImpact';
import InstructionsMap from './InstructionsMap';

interface Props {
  id: string;
}

const InfoView = (props: Props) => {
  let data: any;

  switch (props.id) {
    case 'impact':
      data = <InstructionsImpact />;
      break;
    case 'activity':
      data = <InstructionsActivity />;
      break;
    case 'map':
      data = <InstructionsMap />;
      break;
    default:
      data = <InstructionsImpact />;
  }
  return <>{data}</>;
};

const useTabClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      textTransform: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  })
);

export const EmbeddableWidgetInfoTabs = () => {
  const { theme } = useCampfireTheme();
  const [selectedTab, setSelectedTab] = useQueryParam('tab', StringParam);
  const tabClasses = useTabClasses();

  const handleChange = (_unusedEvent: React.ChangeEvent<{}>, value: string) => setSelectedTab(value);

  return (
    <Grid item xs={12} style={{ paddingBottom: 30, backgroundColor: theme.palette.grey[100] }}>
      <Grid container justify='center'>
        <Grid item xs={10}>
          <Tabs
            centered
            value={selectedTab ?? 'impact'}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            aria-label='task-tabs'
          >
            <Tab
              classes={tabClasses}
              disableRipple
              disableTouchRipple
              key={0}
              label={'Impact Statistics'}
              value='impact'
            />
            <Tab
              classes={tabClasses}
              disableRipple
              disableTouchRipple
              key={1}
              label={'Activity Schedule'}
              value='activity'
            />
          </Tabs>

          <InfoView id={`${selectedTab}`} />
        </Grid>
      </Grid>
    </Grid>
  );
};
