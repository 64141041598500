import React from 'react';

import { Box, Typography } from '@material-ui/core';

export function Legend({ color, label }: { color: string; label: string }) {
  return (
    <Box display='flex' alignItems='center'>
      <Box bgcolor={color} width='10px' height='10px' borderRadius='50%' />
      <Typography style={{ fontSize: 10, fontWeight: 500, marginLeft: '0.5rem' }}>{label}</Typography>
    </Box>
  );
}
