import { Grid, Grow, Paper, Typography } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Error as ErrorIcon } from '@material-ui/icons';
import React, { ComponentType, FunctionComponent } from 'react';

interface BottomErrorMessageConfig {
  in: boolean;
  title?: string;
  subtitle?: string;
  messages?: Array<string>;
  Icon?: FunctionComponent | ComponentType<SvgIconProps>;
}

type BottomErrorMessageProps = BottomErrorMessageConfig;

export const BottomErrorMessage = ({
  in: errorMessageIn,
  title = 'Error',
  subtitle,
  messages = [],
  Icon,
}: BottomErrorMessageProps) => {
  return (
    <Grow in={errorMessageIn} mountOnEnter unmountOnExit>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          square
          style={{
            padding: 8,
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          <Paper
            style={{
              borderRadius: 4,
            }}
          >
            <Grid
              container
              spacing={0}
              alignContent='center'
              alignItems='center'
              style={{
                background: '#fc3232',
                color: 'white',
                borderRadius: 4,
              }}
            >
              {Icon ? (
                <Icon />
              ) : (
                <ErrorIcon
                  style={{
                    padding: 14,
                    borderRadius: 4,
                  }}
                />
              )}
              <Grid item xs>
                <Grid container direction='column' justify='flex-start' spacing={0}>
                  <Typography variant='h6' align='justify'>
                    {title}
                  </Typography>
                  {subtitle ? (
                    <Typography
                      variant='body1'
                      style={{
                        lineHeight: 'inherit',
                        marginTop: -2,
                        marginBottom: 8,
                        paddingRight: 8,
                      }}
                    >
                      {subtitle}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <div
            style={{
              marginTop: 8,
            }}
          >
            {messages.map((message) => (
              <Typography
                key={btoa(message)}
                color='error'
                style={{ whiteSpace: 'pre-wrap', padding: 4, background: 'white' }}
                variant='body1'
              >
                {message}
              </Typography>
            ))}
          </div>
        </Paper>
      </Grid>
    </Grow>
  );
};
