import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { TabletButton } from '@campfire/tablet-button';

const SessionCancellationAlertDialog = (props: { sessionName: string; onOmitClick: () => void } & DialogProps) => {
  const { sessionName, onOmitClick } = props;

  return (
    <Dialog {...props} maxWidth='sm' fullWidth>
      <DialogTitle>{`Omit ${sessionName} from Activity Report?`}</DialogTitle>
      <DialogContent>{`Omit a session from the Activity Report to indicate that the session didn't run, or that there is nothing to report from it.`}</DialogContent>
      <DialogActions>
        <TabletButton
          variant='text'
          onClick={(event) => (props.onClose ? props.onClose(event, 'escapeKeyDown') : undefined)}
        >
          {'Go Back'}
        </TabletButton>
        <TabletButton variant='text' color='error' onClick={onOmitClick}>
          {'Omit Session Report'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { SessionCancellationAlertDialog };
