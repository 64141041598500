import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React, { Fragment, useRef, useState } from 'react';
import PrivilegeRules from '../../../../../global/auth/PrivilegeRules';
import { useUser } from '../../../../../global/auth/useUser';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useVolunteerCommonProfileContext } from '../../VolunteerCommonProfileContext';
import { VolunteerCommonProfile } from '../../__generated__/VolunteerCommonProfile';
import { VolunteerCommonProfileExitDialog } from './VolunteerCommonProfileExitDialog';
import { VolunteerCommonProfileReactivateDialog } from './VolunteerCommonProfileReactivateDialog';
import { VolunteerCommonProfileRetireDialog } from './VolunteerCommonProfileRetireDialog';

export type VolunteerCommonProfileActionMenuOption = 'retire' | 'flagAndExit' | 'reactivate';

const actionMenuOptionLabels = {
  retire: 'Retire',
  flagAndExit: 'Flag and exit',
  reactivate: 'Reactivate',
};

const getActionMenuOptions = (volunteer: VolunteerCommonProfile): Array<VolunteerCommonProfileActionMenuOption> => {
  if (volunteer.rosterStatus === 'flagged') {
    return [];
  }

  const actions: Array<VolunteerCommonProfileActionMenuOption> = [];
  const {
    user: { userIdentityService },
  } = useUser();

  const canFlag =
    userIdentityService.hasVmGlobalRule(PrivilegeRules.flagVolunteer) ||
    !!volunteer.programs.find((program) =>
      userIdentityService.hasRuleForProgram(program.programId, PrivilegeRules.flagVolunteer)
    );
  if (canFlag) actions.push('flagAndExit');

  if (!volunteer.dateDeactivated) {
    const canRetire =
      userIdentityService.hasVmGlobalRule(PrivilegeRules.retireVolunteer) ||
      !!volunteer.programs.find((program) =>
        userIdentityService.hasRuleForProgram(program.programId, PrivilegeRules.retireVolunteer)
      );
    if (canRetire) actions.push('retire');
  }

  if (volunteer.dateDeactivated) {
    const canUnretire =
      userIdentityService.hasVmGlobalRule(PrivilegeRules.unretireVolunteer) ||
      !!volunteer.programs.find((program) =>
        userIdentityService.hasRuleForProgram(program.programId, PrivilegeRules.unretireVolunteer)
      );
    if (canUnretire) actions.push('reactivate');
  }

  return actions;
};

export const VolunteerCommonProfileActionMenu = ({
  volunteer,
  reloadDialog,
}: {
  volunteer: VolunteerCommonProfile;
  reloadDialog?: () => void;
}) => {
  const [openActionMenu, setOpenActionMenu] = useState(false);
  const handleActionMenuOpen = () => setOpenActionMenu(true);
  const handleActionMenuClose = () => setOpenActionMenu(false);
  const actionMenuAnchorRef = useRef<HTMLButtonElement>(null);
  const [retireDialogOpen, setRetireDialogOpen] = useState(false);
  const [exitDialogOpen, setExitDialogOpen] = useState(false);
  const [reactivateDialogOpen, setReactivateDialogOpen] = useState(false);
  const volunteerFullName = `${volunteer.profile.firstName} ${volunteer.profile.lastName}`;

  const actionMenuOptions = volunteer.profile.email !== 'support@volaby.org' ? getActionMenuOptions(volunteer) : [];

  const handleActionMenuItemClick = (option: VolunteerCommonProfileActionMenuOption) => {
    handleActionMenuClose();
    switch (option) {
      case 'retire':
        setRetireDialogOpen(true);
        break;
      case 'flagAndExit':
        setExitDialogOpen(true);
        break;
      case 'reactivate':
        setReactivateDialogOpen(true);
        break;
      default:
        console.error('Unknown volunteer management action');
        break;
    }
  };

  const { theme } = useCampfireTheme();
  const { updateParentVolunteerActions } = useVolunteerCommonProfileContext();

  const handleExitSuccess = () => {
    if (updateParentVolunteerActions) {
      const { updateVolunteerAsExited } = updateParentVolunteerActions;
      if (updateVolunteerAsExited) updateVolunteerAsExited();
    }
    setExitDialogOpen(false);
    if (reloadDialog) reloadDialog();
  };
  const handleRetireSuccess = () => {
    if (updateParentVolunteerActions) {
      const { updateVolunteerAsRetired } = updateParentVolunteerActions;
      if (updateVolunteerAsRetired) updateVolunteerAsRetired();
    }
    setRetireDialogOpen(false);
    if (reloadDialog) reloadDialog();
  };
  const handleReactivateSuccess = () => {
    if (updateParentVolunteerActions) {
      const { updateVolunteerAsReactivated } = updateParentVolunteerActions;
      if (updateVolunteerAsReactivated) updateVolunteerAsReactivated();
    }
    setReactivateDialogOpen(false);
    if (reloadDialog) reloadDialog();
  };
  return (
    <Fragment>
      {actionMenuOptions.length ? (
        <Fragment>
          {!volunteer.dateDeactivated ? (
            <VolunteerCommonProfileRetireDialog
              volunteerId={volunteer.volunteerId}
              fullName={volunteerFullName}
              open={retireDialogOpen}
              onClose={() => setRetireDialogOpen(false)}
              handleSuccess={handleRetireSuccess}
            />
          ) : null}

          <VolunteerCommonProfileExitDialog
            volunteerId={volunteer.volunteerId}
            fullName={volunteerFullName}
            preferredName={volunteer.profile.preferredName}
            open={exitDialogOpen}
            onClose={() => setExitDialogOpen(false)}
            handleSuccess={handleExitSuccess}
          />

          {volunteer.dateDeactivated ? (
            <>
              <VolunteerCommonProfileReactivateDialog
                volunteerId={volunteer.volunteerId}
                fullName={volunteerFullName}
                preferredName={volunteer.profile.preferredName}
                open={reactivateDialogOpen}
                onClose={() => setReactivateDialogOpen(false)}
                handleSuccess={handleReactivateSuccess}
              />
            </>
          ) : null}

          <IconButton
            data-track='fs-volPrf-menu-action-menu-button'
            color={openActionMenu ? 'primary' : undefined}
            style={{ background: openActionMenu ? theme.palette.action.hover : undefined }}
            onClick={handleActionMenuOpen}
            ref={actionMenuAnchorRef}
          >
            <MoreHoriz />
          </IconButton>

          <Modal BackdropProps={{ invisible: true }} open={openActionMenu} onClose={handleActionMenuClose}>
            <Box position='absolute' width={1} height={1}>
              <Popper
                style={{ zIndex: theme.zIndex.modal + 1 }}
                open={openActionMenu}
                anchorEl={actionMenuAnchorRef ? actionMenuAnchorRef.current : undefined}
                transition
                placement='bottom-end'
              >
                {({ TransitionProps }) => (
                  <Grow
                    appear
                    {...TransitionProps}
                    style={{
                      transformOrigin: 'top right',
                    }}
                  >
                    <Paper elevation={4}>
                      <ClickAwayListener mouseEvent={'onMouseDown'} onClickAway={handleActionMenuClose}>
                        <MenuList>
                          {actionMenuOptions.map((option) => (
                            <MenuItem
                              data-track='fs-volPrf-menu-action-menu-item'
                              key={option}
                              onClick={() => handleActionMenuItemClick(option)}
                            >
                              <Typography color={option === 'flagAndExit' ? 'error' : 'inherit'}>
                                {actionMenuOptionLabels[option]}
                              </Typography>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Modal>
        </Fragment>
      ) : null}
    </Fragment>
  );
};
