import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { SessionReportVisibility } from './SessionReportVisibility';
import { AllowCICO } from './AllowCICO';

const AdminConsoleActivityReportingPanel = () => {
  return (
    <Box position='relative' width={1} display='flex' justifyContent='center'>
      <Box px={8} pt={4} width={1} maxWidth={720}>
        <Typography variant='h6' style={{ fontWeight: 600, paddingBottom: '.5em' }}>
          Session Reporting
        </Typography>
        <SessionReportVisibility />
        <Box pt={2}>
          <Typography variant='h6' style={{ fontWeight: 600, paddingBottom: '.5em' }}>
            Check In & Check Out
          </Typography>
          <AllowCICO />
          {/* <AllowMultipleCICO /> */}
        </Box>
      </Box>
    </Box>
  );
};

export { AdminConsoleActivityReportingPanel };
