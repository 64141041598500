import { useEndpointFetch } from '../../../../../../../global/network/useEndpointFetch';

type SaveCheckInType = {
  activityDate: string;
  checkInItems: {
    sessionId: string;
    volunteerId: string;
    checkIn: string;
  }[];
};

type SaveCheckOutType = {
  checkOutItems: { CICOId: string; checkOut: string }[];
};

export const useSaveCheckInFetch = () => {
  return useEndpointFetch<SaveCheckInType>('/vm/session/cico/checkin');
};

export const useSaveCheckOutFetch = () => {
  return useEndpointFetch<SaveCheckOutType>('/vm/session/cico/checkout');
};
