import React, { useState, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { unpackToDate } from '@campfire/hot-date';
import {
  Cancel,
  CheckCircle,
  Help,
  RemoveCircle,
  Warning,
  Close,
  Edit,
  ExpandMore,
  VisibilityOff,
} from '@material-ui/icons';
import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Dialog, Grid, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { CompletedTask } from './VolunteerTasksSection';
import { getStatusColors, GoToButton } from '../../../../../common/cards/ExpandableCard';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { VolunteerSubmitValues } from '../../../../admin/tasks/VolunteerSubmit/VolunteerSubmitValues';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../../common/cards/alert-card/AlertCard';
import { CompletedTaskFormFieldList } from '../../../../../common/form/task-form/CompletedTaskViewer';
import { getVolunteerProfileHardcodedFieldValues } from '../../../../../common/form/task-form/model/get-volunteer-profile-hardcoded-field-values';
import { initCompletedTaskFormFormikValues } from '../../../../../common/form/task-form/model/task-form-init';
import { TaskFormFieldList } from '../../../../../common/form/task-form/TaskFormFieldList';
import { getTaskFieldValues } from '../../../../../global/applicant-shell/model/suyp-task-submission-handler';
import { BypassTaskSubmissionData, CompletedTaskSubmissionData } from '../../../../../global/applicant-shell/SUYPTask';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useEndpointFetch } from '../../../../../global/network/useEndpointFetch';
import { deserializeTaskItems } from '../../../../admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';
import { GET_VOLUNTEER_TASKS_SECTION_TASK } from './volunteer-tasks-section-model.gql';
import { useSharedVolunteerAdditionalTasksSectionState } from './VolunteerAdditionalTasksSection';
import {
  GetVolunteerTasksSectionTask,
  GetVolunteerTasksSectionTaskVariables,
  GetVolunteerTasksSectionTask_vm_completedTask_taskFieldValues as TaskFieldValue,
} from './__generated__/GetVolunteerTasksSectionTask';
import { RemovedTaskItems } from '../../../../general/user-profile/tasks/MyTasks';
import { useUser } from '../../../../../global/auth/useUser';

interface Props {
  completedTask: CompletedTask;
  userId: string;
  profileId: string;
  refetch: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      fontSize: '12px',
      fontWeight: 500,
      padding: 0,
      paddingTop: 8,
      borderRadius: 4,
      color: theme.color.grey.neutral400,
    },
  })
);

export const VolunteerCompletedTaskItem = (props: Props) => {
  const { completedTask, userId, profileId, refetch } = props;
  const { theme } = useCampfireTheme();
  const [selectedCompletedTask, setSelectedCompletedTask] = useState<CompletedTask | undefined>();
  const [selectedCompletedTaskId, setSelectedCompletedTaskId] = useState<string>('');
  const classes = useStyles();

  const {
    user: {
      userIdentityService: { isVmAdmin },
    },
  } = useUser();

  const isCompleted = (status: string) => {
    if (status === 'pending') {
      return 'pending';
    }
    if (status === 'approved' || status === 'admin-approved') {
      return 'completed';
    }
    if (status === 'not-applicable') {
      return 'na';
    }
    return 'new';
  };
  const statusColors = getStatusColors(isCompleted(completedTask.status), theme);

  const taskIcon = (status: string) => {
    if (status === 'completed') {
      return <CheckCircle style={{ fontSize: '22px', color: statusColors.iconColor }} />;
    }
    if (status === 'pending') {
      return <Warning style={{ fontSize: '22px', color: statusColors.iconColor }} />;
    }
    if (status === 'na') {
      return <RemoveCircle style={{ fontSize: '22px', color: statusColors.iconColor }} />;
    }
    if (status === 'new') {
      return <Cancel style={{ fontSize: '22px', color: statusColors.iconColor }} />;
    }
    return <Help />;
  };

  const adminOnlyCheck = () => {
    return !isVmAdmin && completedTask.task.adminOnly;
  };

  const handleSelectTask = () => {
    if (adminOnlyCheck()) {
      return;
    }
    setSelectedCompletedTask(completedTask);
    setSelectedCompletedTaskId(completedTask.completedTaskId);
  };

  const onSuccess = (newCompletedTaskId: string) => {
    refetch();
    setSelectedCompletedTaskId(newCompletedTaskId);
  };

  return (
    <>
      <Box
        onClick={handleSelectTask}
        key={completedTask.completedTaskId}
        display={'flex'}
        p={'15px 20px'}
        marginBottom={'8px'}
        justifyContent={'space-between'}
        bgcolor={statusColors.cardBackgroundColor}
        style={{
          cursor: adminOnlyCheck() ? 'cursor' : 'pointer',
        }}
      >
        <Box
          display='inline-flex'
          align-items='center'
          fontSize='1rem'
          fontWeight={600}
          style={{ alignItems: 'center' }}
        >
          {taskIcon(isCompleted(completedTask.status))}
          <Box paddingLeft={'10px'}>
            <Typography style={{ color: statusColors.textColor, fontWeight: 600 }}>
              {completedTask.task.title}
            </Typography>
            <Typography style={{ color: statusColors.textColor, fontSize: '12px', lineHeight: '10px' }}>
              {completedTask.status === 'approved'
                ? 'Completed'
                : completedTask.status === 'pending'
                ? 'Pending approval, submitted'
                : completedTask.status !== 'approved'
                ? 'Bypassed'
                : 'Completed'}{' '}
              on {unpackToDate(completedTask.dateSubmitted).toFormat('d LLLL y')}
            </Typography>
            <Box
              className={classes.badge}
              alignItems={'center'}
              display={!adminOnlyCheck() ? 'none' : 'flex'}
              style={{ color: statusColors.textColor }}
            >
              <VisibilityOff style={{ fontSize: '15px', paddingRight: '5px' }} />
              <Typography style={{ fontSize: '12px' }}>Restricted Visibility</Typography>
            </Box>
          </Box>
        </Box>
        <Box display={adminOnlyCheck() ? 'none' : 'inherit'}>
          <GoToButton background={statusColors.buttonBackgroundColor} color={statusColors.buttonIconColor} />
        </Box>
      </Box>
      {selectedCompletedTask && selectedCompletedTask.status === 'pending' ? (
        <VolunteerSubmitValues
          completedTaskId={selectedCompletedTask.completedTaskId}
          onClose={() => setSelectedCompletedTask(undefined)}
          onUpdate={refetch}
        />
      ) : selectedCompletedTask && selectedCompletedTask.status === 'approved' ? (
        <VolunteerTaskViewDialog
          open
          onClose={() => {
            setSelectedCompletedTask(undefined);
            refetch();
          }}
          completedTaskId={selectedCompletedTaskId}
          taskId={selectedCompletedTask.task.taskId}
          status={selectedCompletedTask.status}
          userId={userId}
          profileId={profileId}
          onSuccess={onSuccess}
        />
      ) : selectedCompletedTask && selectedCompletedTask.status !== 'approved' ? (
        <VolunteerTaskViewDialog
          open
          onClose={() => {
            setSelectedCompletedTask(undefined);
            refetch();
          }}
          completedTaskId={selectedCompletedTaskId}
          taskId={selectedCompletedTask.task.taskId}
          status={selectedCompletedTask.status}
          userId={userId}
          profileId={profileId}
          onSuccess={onSuccess}
        />
      ) : null}
    </>
  );
};

interface RevokeCompletedTaskSubmissionData {
  completedTaskId: string;
}

interface VolunteerTaskViewDialogProps {
  completedTaskId: string;
  taskId: string;
  userId: string;
  profileId: string;
  status: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: (id: string) => void;
}

export const VolunteerTaskViewDialog = (props: VolunteerTaskViewDialogProps) => {
  const { completedTaskId, taskId, profileId, open, onClose, onSuccess } = props;
  const [editMode, setEditMode] = useState(false);
  const { setSnackbar } = useSnackbar();
  const { theme, isSm } = useCampfireTheme();
  const [taskStatusMenuAnchorEl, setTaskStatusMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const { setRefetchAdditionalTasks } = useSharedVolunteerAdditionalTasksSectionState();

  const handleCloseTaskStatusMenu = () => {
    setTaskStatusMenuAnchorEl(null);
  };

  const handleOpenTaskStatusMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTaskStatusMenuAnchorEl(event.currentTarget);
  };

  const tasksQuery = useCampfireQuery<GetVolunteerTasksSectionTask, GetVolunteerTasksSectionTaskVariables>(
    GET_VOLUNTEER_TASKS_SECTION_TASK,
    {
      options: {
        variables: {
          completedTaskId,
          taskId,
          profileId,
        },
      },
    }
  );

  const saveCompletedTask = useEndpointFetch<CompletedTaskSubmissionData, { completedTaskId: string }>(
    '/vm/volunteer/profile/completed-task/save'
  );
  const bypassCompletedTask = useEndpointFetch<BypassTaskSubmissionData>('/vm/volunteer/profile/completed-task/bypass');
  const revokeCompletedTask = useEndpointFetch<RevokeCompletedTaskSubmissionData>(
    '/vm/volunteer/profile/completed-task/revoke'
  );

  const profile = useMemo(() => {
    return tasksQuery.data?.vm.profile ?? undefined;
  }, [tasksQuery.data]);

  const taskItems = useMemo(() => {
    if (!tasksQuery.data) return [];
    return tasksQuery.data.vm.task?.taskItems ?? [];
  }, [tasksQuery.data]);

  const completedTask = useMemo(() => {
    if (!tasksQuery.data) return undefined;
    return tasksQuery.data.vm.completedTask;
  }, [tasksQuery.data]);

  const taskItemValues: TaskFieldValue[] = useMemo(() => {
    if (!completedTask) return [];
    return completedTask.taskFieldValues;
  }, [completedTask]);

  const keyTaskItemValues = useMemo(() => {
    if (!completedTask) return [];
    return completedTask.taskFieldValues.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.field.taskFieldId]: curr,
      }),
      {}
    );
  }, [completedTask]);

  const task = useMemo(() => {
    if (!tasksQuery.data) return undefined;
    return tasksQuery.data.vm.task;
  }, [tasksQuery.data]);

  const volunteerProfileHardCodedFieldValues = useMemo(() => {
    if (tasksQuery.data?.vm.completedTask?.task.order !== -1 || !tasksQuery.data.vm.profile) {
      return undefined;
    }
    return getVolunteerProfileHardcodedFieldValues(tasksQuery.data.vm.profile);
  }, [tasksQuery.data]);

  const isTaskZero = useMemo(() => {
    return !!volunteerProfileHardCodedFieldValues;
  }, [volunteerProfileHardCodedFieldValues]);

  const submitTaskForm = (data: CompletedTaskSubmissionData) => {
    saveCompletedTask.run(data).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to save task',
          variant: 'error',
        });
        return;
      }

      setSnackbar({
        open: true,
        message: 'Task saved',
        variant: 'success',
      });
      if (onSuccess) {
        onSuccess(res.data.data.completedTaskId);
        onClose();
      }
      if (!onSuccess && tasksQuery.refetch) tasksQuery.refetch();
    });
  };

  const bypassTask = (data: BypassTaskSubmissionData) => {
    bypassCompletedTask.run(data).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to bypass task',
          variant: 'error',
        });
        return;
      }

      setSnackbar({
        open: true,
        message: 'Task successfully bypassed',
        variant: 'success',
      });
      if (tasksQuery.refetch) tasksQuery.refetch();
      setEditMode(false);
      setRefetchAdditionalTasks(true);
    });
  };

  const revokeTask = (data: RevokeCompletedTaskSubmissionData) => {
    revokeCompletedTask.run(data).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to revoke task',
          variant: 'error',
        });
        return;
      }

      setSnackbar({
        open: true,
        message: 'Task successfully revoked',
        variant: 'success',
      });
      if (tasksQuery.refetch) tasksQuery.refetch();
      setEditMode(false);
      setRefetchAdditionalTasks(true);
    });
  };

  const initialFormikValues = useMemo(() => {
    if (taskItems.length === 0) return undefined;
    return initCompletedTaskFormFormikValues(taskItems, taskItemValues);
  }, [taskItems, taskItemValues]);

  const { darkTextColor, lightTextColor } = getAlertCardColors('info');
  const parsedTaskItems = deserializeTaskItems(taskItems);

  const removedTaskFieldValues =
    completedTask?.taskFieldValues.filter((taskFieldValue) => {
      return !taskItems.find((taskItem) => {
        if (taskItem.__typename === 'VOLUNTEER_TaskItemFieldType') {
          return taskItem.field.taskFieldId === taskFieldValue.field.taskFieldId;
        }
        return false;
      });
    }) || [];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { minWidth: isSm ? '290px' : '720px', maxWidth: '720px' } }}
    >
      <Box
        paddingX={2}
        paddingY={2}
        bgcolor='#f6f8f9'
        maxWidth={850}
        flex='1 1 auto'
        minHeight={480}
        position='relative'
      >
        <CircularProgressOverlay isLoading={tasksQuery.loading} />
        {task ? (
          <>
            <Grid container>
              <Grid item xs={7} style={{ marginBottom: '15px' }}>
                <Typography variant='h5' style={{ fontWeight: 'bolder' }}>
                  {task.title}
                </Typography>
                <Typography variant='subtitle1' color='textSecondary'>
                  {task.description}
                </Typography>
                {completedTask?.dateManagerUpdated && completedTask?.managerUpdatedBy ? (
                  <Typography variant='subtitle2' color='textSecondary'>
                    {`Updated on ${unpackToDate(completedTask.dateManagerUpdated).toFormat('d LLLL y')} by ${
                      completedTask.managerUpdatedBy.profile.preferredName
                    } ${completedTask.managerUpdatedBy.profile.lastName}`}
                  </Typography>
                ) : (
                  <Typography variant='subtitle2' color='textSecondary'>
                    {`Submitted on ${unpackToDate(completedTask?.dateSubmitted).toFormat('d LLLL y')} by ${
                      profile?.preferredName
                    } ${profile?.lastName}`}
                  </Typography>
                )}
              </Grid>
              <Grid container item xs={5} justify='flex-end'>
                {!editMode ? (
                  <Grid item>
                    {/* We cannot use the other component because it just wont work (fuck you react) */}
                    <Box>
                      <TabletButton
                        // data-track='actCnl-overview-status-dropdown'
                        size='small'
                        variant='outlined'
                        color='primary'
                        aria-controls='task-status-menu'
                        aria-haspopup='true'
                        onClick={() => setEditMode(true)}
                        startIcon={<Edit />}
                        style={{
                          border: '1px solid #9e9e9e',
                          boxSizing: 'border-box',
                          borderRadius: '4px',
                          padding: '2px 7px',
                          color: theme.color.grey.neutral300,
                          marginRight: '8px',
                          marginTop: '5px',
                        }}
                      >
                        {'Edit Task'}
                      </TabletButton>
                      <TabletButton
                        // data-track='actCnl-overview-status-dropdown'
                        size='small'
                        variant='outlined'
                        color='primary'
                        aria-controls='task-status-menu'
                        aria-haspopup='true'
                        onClick={handleOpenTaskStatusMenu}
                        endIcon={<ExpandMore />}
                        style={{
                          border: '1px solid #9e9e9e',
                          boxSizing: 'border-box',
                          borderRadius: '4px',
                          padding: '2px 7px',
                          color: theme.color.grey.neutral300,
                          marginRight: '8px',
                          marginTop: '5px',
                        }}
                      >
                        {'Options'}
                      </TabletButton>
                      <TabletButton
                        // data-track='actCnl-overview-status-dropdown'
                        size='small'
                        variant='outlined'
                        color='primary'
                        aria-controls='task-status-menu'
                        aria-haspopup='true'
                        onClick={() => onClose()}
                        startIcon={<Close />}
                        style={{
                          border: '1px solid #d93a00',
                          boxSizing: 'border-box',
                          borderRadius: '4px',
                          padding: '2px 7px',
                          color: theme.color.error[900],
                          marginRight: '8px',
                          marginTop: '5px',
                        }}
                      >
                        {'Close'}
                      </TabletButton>

                      <Menu
                        id='task-status-menu'
                        anchorEl={taskStatusMenuAnchorEl}
                        keepMounted
                        open={Boolean(taskStatusMenuAnchorEl)}
                        onClose={handleCloseTaskStatusMenu}
                      >
                        {completedTask?.status !== 'not-applicable' ? (
                          <MenuItem
                            // data-track='actCnl-overview-status-dropdown-item-suspend'
                            onClick={() => {
                              bypassTask({
                                profileId: profileId,
                                taskId: task.taskId,
                                status: 'not-applicable',
                              });
                            }}
                          >
                            {'Mark as Not Applicable'}
                          </MenuItem>
                        ) : null}
                        {completedTask?.status === 'not-applicable' ? (
                          <MenuItem
                            // data-track='actCnl-overview-status-dropdown-item-suspend'
                            onClick={() => {
                              bypassTask({
                                profileId: profileId,
                                taskId: task.taskId,
                                status: 'admin-approved',
                              });
                            }}
                          >
                            {'Mark as Completed'}
                          </MenuItem>
                        ) : null}
                        <MenuItem
                          // data-track='actCnl-overview-status-dropdown-item-close'
                          onClick={() => {
                            revokeTask({
                              completedTaskId: completedTaskId,
                            });
                          }}
                        >
                          {'Revoke Task'}
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
              {completedTask?.status === 'not-applicable' || completedTask?.status === 'admin-approved' ? (
                <AlertCard title='Task bypassed' variant='info'>
                  <AlertCardBody>
                    <Typography
                      variant='subtitle2'
                      style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
                    >
                      This task was bypassed and marked as{' '}
                      <span style={{ fontWeight: 'bold', color: darkTextColor }}>
                        {completedTask.status === 'not-applicable' ? 'not applicable' : 'completed'}
                      </span>{' '}
                      for this volunteer by{' '}
                      <span style={{ fontWeight: 'bold', color: darkTextColor }}>
                        {completedTask.managerUpdatedBy?.profile.preferredName}{' '}
                        {completedTask.managerUpdatedBy?.profile.lastName}
                      </span>
                    </Typography>
                  </AlertCardBody>
                </AlertCard>
              ) : (
                ''
              )}
            </Grid>
          </>
        ) : null}

        <br />

        {editMode && initialFormikValues ? (
          <Box marginBottom={2}>
            <Formik
              initialValues={initialFormikValues}
              onSubmit={async (values) => {
                const taskFieldValues = await getTaskFieldValues(parsedTaskItems, values);
                const data = {
                  taskId,
                  userId: profile?.userId ?? '',
                  completedTaskId: completedTask?.completedTaskId ?? '',
                  taskFieldValues,
                };

                submitTaskForm(data);
              }}
            >
              {({ values }) => (
                <Form>
                  <TaskFormFieldList taskItems={parsedTaskItems} values={values} />
                  <Box width={1} display='flex' justifyContent='flex-end'>
                    <Box width={720} display='flex' justifyContent='flex-end'>
                      <TabletButton variant='text' onClick={() => setEditMode(false)}>
                        Cancel
                      </TabletButton>
                      <TabletButton color='primary' variant='contained' type='submit'>
                        Submit
                      </TabletButton>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        ) : (
          <CompletedTaskFormFieldList
            taskItems={parsedTaskItems}
            taskItemValues={keyTaskItemValues}
            isTaskZero={isTaskZero}
            volunteerProfileValues={volunteerProfileHardCodedFieldValues}
          />
        )}
        <RemovedTaskItems taskFieldValues={removedTaskFieldValues} />
      </Box>
    </Dialog>
  );
};
