import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClearRounded } from '@material-ui/icons';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';

interface SUYPTaskScaffoldProps {
  taskHeaderDetails?: {
    title: string;
    description: string;
  };
  children: ReactNode;
  allowBack: boolean;
}

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '16px',
    },
  },
}));

export const SUYPTaskScaffold = (props: SUYPTaskScaffoldProps) => {
  const { allowBack, taskHeaderDetails, children } = props;

  const history = useHistory();
  const classes = useStyles();
  return (
    <Box
      bgcolor='#f1f1f1'
      display='flex'
      flex='1 1 auto'
      flexDirection='column'
      width={1}
      paddingTop={2}
      paddingBottom={8}
      paddingX={1}
      boxSizing='border-box'
      style={{
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <Box>
        <Box
          width={1}
          paddingTop={1}
          paddingBottom={3}
          display='flex'
          justifyContent='center'
          alignContent='flex-start'
          alignItems='flex-start'
        >
          {taskHeaderDetails ? (
            <Box
              width={1}
              maxWidth={720}
              display='flex'
              justifyContent='space-between'
              className={classes.headerContainer}
            >
              <Box width={1}>
                <Typography variant='h5' style={{ fontWeight: 'bolder' }}>
                  {taskHeaderDetails.title}
                </Typography>
                <Typography variant='subtitle1' color='textSecondary'>
                  {taskHeaderDetails.description}
                </Typography>
              </Box>

              {allowBack ? (
                <Box>
                  <IconButton onClick={() => history.push({ pathname: '/', search: history.location.search })}>
                    <ClearRounded fontSize='large' />
                  </IconButton>
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Box>
      {children}
    </Box>
  );
};
