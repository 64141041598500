import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { Loop } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { unpackToTime } from '@campfire/hot-date';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { upperFirst } from 'lodash';

import { GetRosterSidebarActivities_vm_activities as ActivityType } from './__generated__/GetRosterSidebarActivities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      borderRadius: '8px',
      alignItems: 'stretch',
      backgroundColor: 'white',
      marginTop: 14,
      overflow: 'hidden',
      height: 80,
      cursor: 'pointer',
      border: ({ isSelected, color }: ActivityCardProps) =>
        isSelected
          ? `1px solid ${
              color !== 'active'
                ? theme.color.activities.status[color || 'default'].primary
                : theme.color.activities.status.default.primary
            }`
          : 'none',
      boxShadow: '1px 3px 4px #afafaf2e',
    },
    date: {
      backgroundColor: ({ color }) =>
        color !== 'active'
          ? theme.color.activities.status[color || 'default'].primary
          : theme.color.activities.status.default.primary,
      width: 20,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: 18,
    },
    title: {
      color: theme.color.grey.neutral400,
      fontWeight: 900,
      fontSize: 20,
    },
    timeContainer: {
      display: 'flex',
      marginTop: '12px',
      alignItems: 'center',
      color: theme.color.grey.neutral200,
      fontSize: '10px',
    },
    timeInfo: {
      fontSize: 10,
    },
  })
);

interface ActivityCardProps {
  activity: ActivityType;
  onClick: () => void;
  isSelected?: boolean;
  color?: 'active' | 'suspended' | 'closed' | 'ended';
}

export function ActivityCard(props: ActivityCardProps) {
  const { activity, onClick, color = 'active' } = props;
  const classes = useStyles(props);

  return (
    <TitularTooltip title={`${upperFirst(color)} activity`} placement='left'>
      <Box onClick={onClick} className={classes.container}>
        <Box className={classes.date} />
        <Box className={classes.content}>
          <Typography variant='h3' className={classes.title}>
            {activity.name}
          </Typography>
          <Box className={classes.timeContainer}>
            {'recurrences' in activity ? (
              <React.Fragment>
                <Loop style={{ fontSize: 16 }} />
                &nbsp;
                <Typography className={classes.timeInfo}>{activity.recurrences[0].humanReadable}</Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Loop style={{ fontSize: 16 }} />
                &nbsp;
                <Typography className={classes.timeInfo}>Flexible</Typography>
              </React.Fragment>
            )}
            <Typography className={classes.timeInfo}>
              {activity.startTime && `, ${unpackToTime(activity.startTime).toFormat('h:mm a')}`}
              {activity.endTime && ` - ${unpackToTime(activity.endTime).toFormat('h:mm a')}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </TitularTooltip>
  );
}
