import { Box, BoxProps, Typography } from '@material-ui/core';
import { SentimentSatisfiedRounded } from '@material-ui/icons';
import { Emoji, EmojiData } from 'emoji-mart';
import React from 'react';

type Props = { emoji?: string | EmojiData; text?: string } & BoxProps;

const ProfileStatusBrick = (props: Props) => {
  const { emoji, text } = props;
  const displayText = text ?? (emoji ? 'Add a message' : 'Add a status');
  return (
    <Box {...props}>
      <Box display='flex' flex='1 1 auto' alignItems='center' alignContent='center' overflow='hidden'>
        <Box marginRight={'8px'} width={21} height={21}>
          {emoji ? (
            <Emoji emoji={emoji} set='apple' size={21} />
          ) : (
            <SentimentSatisfiedRounded fontSize='small' color='action' />
          )}
        </Box>
        <Typography noWrap variant='subtitle2' color='textSecondary'>
          {displayText}
        </Typography>
      </Box>
    </Box>
  );
};

export { ProfileStatusBrick };
