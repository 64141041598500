import { gql } from '@apollo/client';

export const GET_PROGRAM_ACTIVITIES = gql`
  query GetProgramActivities($rule: VOLUNTEER_VolunteerManagementPrivilegeRulesEnumType) {
    vm {
      programs(hasRule: { rule: $rule }) {
        programId
        name
        dateDeleted
        dateSuspended
      }
    }
  }
`;