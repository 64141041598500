import { Field } from '@campfire/field';
import { TabletButton } from '@campfire/tablet-button';
import { cloneDeep } from 'lodash';
import { object as YupObject, string as YupString } from 'yup';
import { Formik, Form } from 'formik';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogActions,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import React from 'react';
import {
  CategoriesSectionContent,
  IncidentCategoryGroupType,
  IncidentCategoryType,
  findCategory,
} from '../../admin-console-incident-report-model';
import { useAdminConsoleActions } from '../../../../admin-console-actions';

type Props = {
  open: boolean;
  onClose: () => void;
  incidentCategoriesRoot: IncidentCategoryGroupType;
  parentCategory?: IncidentCategoryType;
  refetchCategories?: () => void;
};

const validationSchema = YupObject().shape({
  label: YupString()
    .trim()
    .required('Please provide a detail name'),
});
export const AddCategoryDetailDialog = ({
  open,
  onClose,
  incidentCategoriesRoot,
  parentCategory,
  refetchCategories,
}: Props) => {
  const { runSaveIncidentCategories, runSaveIncidentCategoriesLoading } = useAdminConsoleActions();
  const { sectionTitle, addDetailDialogTitle, addDetailDialogHelperText } = CategoriesSectionContent;
  const handleSuccess = () => {
    onClose();
    if (refetchCategories) refetchCategories();
  };

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid item container xs={12} direction='column' style={{}}>
          <Typography variant='h6' gutterBottom>
            {addDetailDialogTitle}
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {addDetailDialogHelperText}
          </Typography>
          <Box display='flex' alignItems='center'>
            <Typography variant='body1'>{sectionTitle}</Typography>
            {parentCategory && (
              <>
                <ArrowForwardIos style={{ fontSize: 18, paddingRight: 8, paddingLeft: 8 }} />
                <Typography variant='body1' display='inline'>
                  {parentCategory.label}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </DialogTitle>
      <Formik
        initialValues={{
          label: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(vals) => {
          if (parentCategory?.incidentCategoryId) {
            const clonedRoot = cloneDeep(incidentCategoriesRoot);
            const foundCategoryForAddition = findCategory(clonedRoot, parentCategory?.incidentCategoryId);
            if (foundCategoryForAddition)
              foundCategoryForAddition.details.push({
                __typename: 'VOLUNTEER_IncidentDetailType',
                label: vals.label,
              });
            runSaveIncidentCategories({ root: clonedRoot, handleSuccess });
          }
        }}
      >
        {() => (
          <Form>
            <DialogContent>
              <Field required autoFocus name='label' label='Detail Name' variant='outlined' margin='normal' fullWidth />
            </DialogContent>
            <DialogActions>
              <TabletButton variant='text' onClick={() => onClose()}>
                {'Cancel'}
              </TabletButton>
              <TabletButton variant='contained' color='primary' type='submit'>
                {runSaveIncidentCategoriesLoading ? <CircularProgress size={20} /> : 'Done'}
              </TabletButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
