import { Box } from '@material-ui/core';
import { stringify } from 'query-string';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { SideBarProfileSection } from './my-profile/SideBarProfileSection';
import { SideBarIncompleteReports } from './incomplete-reports/SideBarIncompleteReports';
import { SideBarNewTasks } from './new-tasks/SideBarNewTasks';
import { SideBarAvailabilityAlert } from './availability-alert/SideBarAvailabilityAlert';
import { SideBarRecentNewsSection } from './recent-news/SideBarRecentNewsSection';
import { SideBarYourImpactSection } from './your-impact/SideBarYourImpactSection';

interface MyElementsSideBarProps {
  style: React.CSSProperties;
}

export const MyElementsSideBar = ({ style }: MyElementsSideBarProps) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [query] = useQueryParams({
    activityStatus: ArrayParam,
    days: ArrayParam,
    startTime: StringParam,
    endTime: StringParam,
    startDate: StringParam,
    endDate: StringParam,
  });

  const handleViewAllClick = () => {
    history.replace(`${match.path}/?${stringify(query)}&tab=news`);
  };

  return (
    <Box width='100%' display='inline-grid' style={style}>
      <SideBarProfileSection />
      <SideBarIncompleteReports />
      <SideBarNewTasks />
      <SideBarAvailabilityAlert />
      <SideBarRecentNewsSection onViewAllClick={handleViewAllClick} onSingleNewsClick={handleViewAllClick} />
      <SideBarYourImpactSection />
    </Box>
  );
};
