import { gql } from '@apollo/client';

export const GET_VOLUNTEER_COMMON_ACTIVITY_ENROLMENT = gql`
  query GetVolunteerCommonActivityEnrolment($volunteerId: String!, $activityId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        allActivityEnrolmentsForActivity(activityId: $activityId) {
          dateRemoved
          dateEnrolled
          enrolledBy {
            profile {
              profileId
              preferredName
              firstName
              lastName
              volunteer {
                privilegeLevel
              }
            }
          }
          removedBy {
            profile {
              profileId
              preferredName
              firstName
              lastName
               volunteer {
                privilegeLevel
              }
            }
          }
          activity {
            isRestrictedActivity
          }
        }
      }
    }
  }
`;
