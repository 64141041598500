import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { unpackToDateTime } from '@campfire/hot-date';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ProfileAvatar } from '../ProfileAvatar';
import { CommentActions } from './CommentActions';
import { useUser } from '../../global/auth/useUser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    info: {
      marginLeft: '1rem',
      '& .name': {
        fontWeight: 700,
        fontSize: '1rem',
        color: theme.color.grey.neutral300,
      },
      '& .time': {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: theme.color.grey.neutral200,
      },
    },
    actionIcon: {
      color: theme.color.grey.neutral300,
    },
  })
);

interface CommentHeaderProps {
  volunteerId: string;
  profile: {
    lastName: string;
    preferredName: string;
    avatarUrl?: string | null;
  };
  time: string;
  showCommentAction: boolean;
  handleAction: (type: string) => void;
}

export function CommentHeader({ volunteerId, profile, time, showCommentAction, handleAction }: CommentHeaderProps) {
  const classes = useStyles();
  const { getVolunteerIdentity } = useUser();
  const { volunteerId: volunteerIdentityId } = getVolunteerIdentity();
  const name = `${profile.preferredName} ${profile.lastName}`;

  return (
    <Box className={classes.container}>
      <Box>
        <ProfileAvatar profile={profile} />
      </Box>
      <Box className={classes.info}>
        <Typography className='name'>{name}</Typography>
        <Typography className='time'>{unpackToDateTime(time).toFormat('hh:mm a, LLLL dd yyyy')}</Typography>
      </Box>
      {showCommentAction && volunteerId === volunteerIdentityId && (
        <Box>
          <CommentActions onClick={handleAction} />
        </Box>
      )}
    </Box>
  );
}
