import React, { useContext, useState } from 'react';
import { ApolloQueryResult, QueryLazyOptions } from '@apollo/client';

import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { GET_MY_UNREAD_NEWS_NUMBER_TAB_DATA } from './main-section/my-recent-news/my-news-model.gql';
import {
  GetMyUnreadNewsNumberTabData,
  GetMyUnreadNewsNumberTabDataVariables,
} from './main-section/my-recent-news/__generated__/GetMyUnreadNewsNumberTabData';
import { GET_MY_ELEMENTS_SIDEBAR_MY_TASKS_DATA } from './sidebar/new-tasks/side-bar-new-tasks-model.gql';
import {
  GetMyElementsSideBarMyTasksData,
  GetMyElementsSideBarMyTasksDataVariables,
} from './sidebar/new-tasks/__generated__/GetMyElementsSideBarMyTasksData';
import {
  GetMyElementsSideBarUpcomingData,
  GetMyElementsSideBarUpcomingDataVariables,
} from './sidebar/availability-alert/__generated__/GetMyElementsSideBarUpcomingData';
import { GET_MY_ELEMENTS_SIDEBAR_UPCOMING_ROSTER_DATA } from './sidebar/availability-alert/side-bar-availability-alert-model.gql';

interface VolDashboardMyElementsContextInterface {
  newTasksNumber: number;
  setNewTasksNumber: (x: number) => void;
  upcomingRosterNumber: number;
  setUpcomingRosterNumber: (x: number) => void;
  selectedNewsId?: string;
  setSelectedNewsId: (x?: string) => void;
  getMyUnreadNewsNumber: (options?: QueryLazyOptions<GetMyElementsSideBarMyTasksDataVariables> | undefined) => void;
  getMyUnreadNewsNumberTabData: GetMyUnreadNewsNumberTabData | undefined;
  getMyUnreadNewsNumberTabDataRefetch:
    | ((
        variables?: GetMyElementsSideBarMyTasksDataVariables | undefined
      ) => Promise<ApolloQueryResult<GetMyUnreadNewsNumberTabData>>)
    | undefined;
  getSideBarMyTasks: (options?: QueryLazyOptions<GetMyElementsSideBarMyTasksDataVariables> | undefined) => void;
  getSideBarMyTasksData: GetMyElementsSideBarMyTasksData | undefined;
  getSideBarMyTasksDataLoading: boolean;
  getSideBarMyTasksDataRefetch:
    | ((
        variables?: GetMyElementsSideBarMyTasksDataVariables | undefined
      ) => Promise<ApolloQueryResult<GetMyElementsSideBarMyTasksData>>)
    | undefined;

  getSideBarUpcomingRoster: (options?: QueryLazyOptions<GetMyElementsSideBarUpcomingDataVariables> | undefined) => void;
  getSideBarUpcomingRosterData: GetMyElementsSideBarUpcomingData | undefined;
  getSideBarUpcomingRosterDataLoading: boolean;
  getSideBarUpcomingRosterDataRefetch:
    | ((
        variables?: GetMyElementsSideBarUpcomingDataVariables | undefined
      ) => Promise<ApolloQueryResult<GetMyElementsSideBarUpcomingData>>)
    | undefined;
  viewMode: string;
  setViewMode: (x: string) => void;
}

interface VolDashboardMyElementsProviderProps {
  children: React.ReactNode;
}

const VolDashboardMyElementsContext = React.createContext<VolDashboardMyElementsContextInterface | undefined>(
  undefined
);

const VolDashboardMyElementsProvider = (props: VolDashboardMyElementsProviderProps) => {
  const [newTasksNumber, setNewTasksNumber] = useState<number>(0);
  const [selectedNewsId, setSelectedNewsId] = useState<string>();
  const [viewMode, setViewMode] = useState<string>('all');
  const [upcomingRosterNumber, setUpcomingRosterNumber] = useState<number>(0);

  const [
    getSideBarMyTasks,
    { data: getSideBarMyTasksData, loading: getSideBarMyTasksDataLoading, refetch: getSideBarMyTasksDataRefetch },
  ] = useCampfireLazyQuery<GetMyElementsSideBarMyTasksData, GetMyElementsSideBarMyTasksDataVariables>(
    GET_MY_ELEMENTS_SIDEBAR_MY_TASKS_DATA
  );

  const [
    getSideBarUpcomingRoster,
    {
      data: getSideBarUpcomingRosterData,
      loading: getSideBarUpcomingRosterDataLoading,
      refetch: getSideBarUpcomingRosterDataRefetch,
    },
  ] = useCampfireLazyQuery<GetMyElementsSideBarUpcomingData, GetMyElementsSideBarUpcomingDataVariables>(
    GET_MY_ELEMENTS_SIDEBAR_UPCOMING_ROSTER_DATA
  );

  const [
    getMyUnreadNewsNumber,
    { data: getMyUnreadNewsNumberTabData, refetch: getMyUnreadNewsNumberTabDataRefetch },
  ] = useCampfireLazyQuery<GetMyUnreadNewsNumberTabData, GetMyUnreadNewsNumberTabDataVariables>(
    GET_MY_UNREAD_NEWS_NUMBER_TAB_DATA
  );

  const value = {
    newTasksNumber,
    setNewTasksNumber,
    selectedNewsId,
    setSelectedNewsId,
    getMyUnreadNewsNumber,
    getMyUnreadNewsNumberTabData,
    getMyUnreadNewsNumberTabDataRefetch,
    getSideBarMyTasks,
    getSideBarMyTasksData,
    getSideBarMyTasksDataLoading,
    getSideBarMyTasksDataRefetch,
    upcomingRosterNumber,
    setUpcomingRosterNumber,
    getSideBarUpcomingRoster,
    getSideBarUpcomingRosterData,
    getSideBarUpcomingRosterDataLoading,
    getSideBarUpcomingRosterDataRefetch,
    viewMode,
    setViewMode,
  };

  return (
    <VolDashboardMyElementsContext.Provider value={value} {...props}>
      {props.children}
    </VolDashboardMyElementsContext.Provider>
  );
};

export const useVolDashboardMyElementsContext = () => {
  const value = useContext(VolDashboardMyElementsContext);
  if (!value) throw new Error('Must be inside of a VolDashboardMyElementsProvider');
  return value;
};

export { VolDashboardMyElementsProvider };
