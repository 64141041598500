import React, { FunctionComponent } from 'react';
import { Typography, Grid, Box, ButtonBase, IconButton } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Help from '@material-ui/icons/Help';
import { withRouter, RouteComponentProps } from 'react-router';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { getBreadCrumbs } from '../../content-blocks/common/BreadCrumbs';
import { usePageHelp } from '../page-help/PageHelpContext';

type CampfireHeaderCompProps = Props & RouteComponentProps;

const SmallBreadCrumbs = ({ breadCrumbs }: { breadCrumbs: Array<string> }) => {
  const filteredBreadCrumbs = breadCrumbs.filter((x) => !!x);

  return (
    <Typography data-testid='breadcrumbs' component='span' display='inline' variant='body1' color='inherit'>
      {filteredBreadCrumbs.map((value, index) => {
        return (
          <span key={value}>
            {index === 0 || filteredBreadCrumbs.length === 1 ? <strong>{value}</strong> : `${value}`}
            {index === breadCrumbs.length - 1 || filteredBreadCrumbs.length === 1 ? '' : ' / '}
          </span>
        );
      })}
    </Typography>
  );
};

const Heading: FunctionComponent<{ heading: string }> = (props) => {
  const { heading } = props;
  return (
    <Typography data-testid='heading' color='inherit' variant='h5' component='h1' style={{ marginTop: -4 }}>
      {heading}
    </Typography>
  );
};

type Props = {
  children?: React.ReactNode;
  condensed?: boolean;
  extraBreadcrumbs?: Array<string>;
};

const CampfireHeaderComp = React.memo(
  (props: CampfireHeaderCompProps) => {
    const { children, extraBreadcrumbs, condensed } = props;
    const breadCrumbs = extraBreadcrumbs
      ? [...getBreadCrumbs(props.location), ...extraBreadcrumbs]
      : getBreadCrumbs(props.location);

    const { isMobile } = useCampfireTheme();
    const { pageHelp } = usePageHelp();

    return (
      <Box
        className='HeadingContainer'
        // bgcolor={theme.color.darkGradientHorizontal}
        // color={'white'}
      >
        {(!condensed && isMobile) || condensed ? (
          <>
            <SmallBreadCrumbs breadCrumbs={breadCrumbs} />
            {pageHelp ? (
              <IconButton onClick={pageHelp.onClick} style={{ marginLeft: 2, marginTop: -3 }}>
                <Help fontSize='small' style={{ color: 'rgba(255,255,255,0.4)' }} />
              </IconButton>
            ) : null}
          </>
        ) : (
          <Grid container justify='space-between'>
            <Grid item xs>
              <SmallBreadCrumbs breadCrumbs={[...breadCrumbs.slice(0, breadCrumbs.length - 1)]} />
              <Heading heading={breadCrumbs.pop() || ''} />
            </Grid>
            {pageHelp ? (
              <Grid item xs={1} container justify='flex-end' alignContent='flex-end' alignItems='flex-end'>
                <Box borderRadius={2} bgcolor='#fff' marginBottom={-3} padding={1} paddingBottom={2}>
                  <ButtonBase onClick={pageHelp.onClick}>
                    <HelpOutline color='action' />
                  </ButtonBase>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        )}
        {children}
      </Box>
    );
  },
  (prev, next) => {
    return prev.location.pathname === next.location.pathname;
  }
);

export const CampfireHeader = withRouter(CampfireHeaderComp);
