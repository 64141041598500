import { Field } from '@campfire/field';
import { Select } from '@campfire/select';
import { TabletButton } from '@campfire/tablet-button';
import { Formik, Form, useFormikContext } from 'formik';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogActions,
  CircularProgress,
  Box,
} from '@material-ui/core';
import React from 'react';
import { object as YupObject, string as YupString } from 'yup';
import {
  IncidentSeverityFormValuesType,
  incidentSeverityColorOptions,
  ColorItemType,
  IncidentSeverityType,
} from '../admin-console-incident-report-model';
import { useAdminConsoleActions } from '../../../admin-console-actions';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  severityToEdit?: IncidentSeverityType;
  refetchSeverities?: () => void;
};

const validationSchema = YupObject().shape({
  name: YupString()
    .trim()
    .required('Please provide a name'),
  description: YupString()
    .trim()
    .required('Please provide a description'),
  color: YupString().required('Please provide a color'),
});
export const EditSeverityDialog = ({ open, onClose, title, severityToEdit, refetchSeverities }: Props) => {
  const formik = useFormikContext<IncidentSeverityFormValuesType>();
  const { severities } = formik.values;
  const { runSaveIncidentSeverities, runSaveIncidentSeveritiesLoading } = useAdminConsoleActions();
  const handleSuccess = () => {
    onClose();
    if (refetchSeverities) refetchSeverities();
  };

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid item container xs={12} direction='column' style={{}}>
          <Typography display='inline' variant='h6'>
            {title}
          </Typography>
        </Grid>
      </DialogTitle>
      <Formik
        initialValues={{
          incidentSeverityId: severityToEdit?.incidentSeverityId ?? undefined,
          name: severityToEdit?.name ?? '',
          description: severityToEdit?.description ?? '',
          color: severityToEdit?.color ?? '',
          order: severityToEdit?.order ?? severities.length,
        }}
        validationSchema={validationSchema}
        onSubmit={(vals) => {
          severities[vals.order] = vals;
          runSaveIncidentSeverities({
            severities,
            handleSuccess,
          });
        }}
      >
        {() => (
          <Form>
            <DialogContent style={{ paddingTop: 0 }}>
              <Grid xs container spacing={1} alignItems='center'>
                <Grid item xs={8}>
                  <Field required autoFocus name='name' label='Name' variant='outlined' margin='normal' fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    variant='outlined'
                    margin='normal'
                    helper
                    slow
                    required
                    shrink
                    fullWidth
                    name='color'
                    label='Color'
                    items={incidentSeverityColorOptions}
                    renderFunction={(color: ColorItemType) => (
                      <Box display='flex' alignItems='center'>
                        <Box width='10px' height='10px' borderRadius='5px' bgcolor={color.hexValue} mr={1} />
                        <Typography>{color.name}</Typography>
                      </Box>
                    )}
                    valueFunction={(color: ColorItemType) => color.hexValue}
                  />
                </Grid>
              </Grid>
              <Field required name='description' label='Description' variant='outlined' margin='normal' fullWidth />
            </DialogContent>
            <DialogActions>
              <TabletButton size='medium' variant='text' onClick={() => onClose()}>
                {'Cancel'}
              </TabletButton>
              <TabletButton size='medium' variant='contained' color='primary' type='submit'>
                {runSaveIncidentSeveritiesLoading ? <CircularProgress size={20} /> : 'Done'}
              </TabletButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
