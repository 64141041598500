import { gql } from '@apollo/client';

export const VolunteerPublishedRosterFragments = gql`
  fragment VolunteerRosters on VOLUNTEER_PublishedRosteringType {
    publishedRosteringId
    dateRemoved
    sessionRosterings {
      session {
        name
        sessionId
      }
    }
    publishedRoster {
      activityDate
      activity {
        activityId
      }
    }
  }
`;
