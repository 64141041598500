import { Field } from '@campfire/field/lib';
import { HoverText } from '@campfire/hover-link/lib';
import { TabletButton } from '@campfire/tablet-button/lib';
import {
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { FieldArrayRenderProps, Form, Formik, useFormikContext } from 'formik';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  array as YupArray,
  ArraySchema,
  boolean as YupBool,
  number as YupNumber,
  object as YupObject,
  string as YupString,
} from 'yup';
import { noop } from 'lodash';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';
import { FormikTaskBuilderValues } from '../../../admin-console-volunteer-profile-model';
import { TaskItemField_field as TaskFieldType } from '../../../__generated__/TaskItemField';
import { AdditionalFieldTypeFields } from './additional-field-type-fields/AdditionalFieldTypeFields';
import { SwitchField } from './additional-field-type-fields/SwitchField';
import { FieldIcon } from './FieldIcon';
import { FormikErrorFocus } from '../../../../../../../../common/form/FormikErrorFocus';
import { TaskFormItem_VOLUNTEER_TaskItemFieldType as TaskItemFieldType } from '../../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';

const validationSchema = YupObject().shape({
  optional: YupBool().required('Required'),
  order: YupNumber().required(),
  name: YupString().required('Name required'),
  description: YupString(),
  field: YupObject().shape({
    __typename: YupString(),
    allowManual: YupBool(),
    allowMultiple: YupBool(),
    checkboxOptions: YupArray()
      .of(
        YupObject().shape({
          name: YupString().required('Required'),
        })
      )
      .when('__typename', (__typename: string, schema: ArraySchema<object>) => {
        return __typename === 'VOLUNTEER_CheckboxTaskFieldType' ? schema.required('Required') : schema;
      }),
    dropdownOptions: YupArray()
      .of(
        YupObject().shape({
          name: YupString().required('Required'),
        })
      )
      .when('__typename', (__typename: string, schema: ArraySchema<object>) => {
        return __typename === 'VOLUNTEER_DropdownTaskFieldType' ? schema.required('Required') : schema;
      }),
    multipleChoiceOptions: YupArray()
      .of(
        YupObject().shape({
          name: YupString().required('Required'),
        })
      )
      .when('__typename', (__typename: string, schema: ArraySchema<object>) => {
        return __typename === 'VOLUNTEER_MultipleChoiceTaskFieldType' ? schema.required('Required') : schema;
      }),
    quizTaskFieldOptions: YupArray()
      .of(
        YupObject().shape({
          name: YupString().required('Required'),
        })
      )
      .when('__typename', (__typename: string, schema: ArraySchema<object>) => {
        return __typename === 'VOLUNTEER_QuizTaskFieldType' ? schema.required('Required') : schema;
      }),
  }),
});

type MappedTaskFieldType = { name: string; type: TaskFieldType };

const baseTaskField = {
  taskFieldId: '',
  name: '',
  description: '',
  optional: false,
};

const initialTaskFields: { [key: string]: MappedTaskFieldType } = {
  VOLUNTEER_ShortTextTaskFieldType: {
    name: 'Short Answer Text',
    type: {
      __typename: 'VOLUNTEER_ShortTextTaskFieldType',
      ...baseTaskField,
    },
  },
  VOLUNTEER_LongTextTaskFieldType: {
    name: 'Long Answer Text',
    type: {
      __typename: 'VOLUNTEER_LongTextTaskFieldType',
      ...baseTaskField,
    },
  },
  VOLUNTEER_NumberTaskFieldType: {
    name: 'Number',
    type: {
      __typename: 'VOLUNTEER_NumberTaskFieldType',
      ...baseTaskField,
    },
  },
  VOLUNTEER_MultipleChoiceTaskFieldType: {
    name: 'Multiple Choice',
    type: {
      __typename: 'VOLUNTEER_MultipleChoiceTaskFieldType',
      multipleChoiceOptions: [],
      dynamic: false,
      ...baseTaskField,
    },
  },
  VOLUNTEER_QuizTaskFieldType: {
    name: 'Quiz',
    type: {
      __typename: 'VOLUNTEER_QuizTaskFieldType',
      quizTaskFieldOptions: [],
      quizTaskFieldCorrect: null,
      allowRetry: true,
      dynamic: false,
      ...baseTaskField,
    },
  },
  VOLUNTEER_CheckboxTaskFieldType: {
    name: 'Checkboxes',
    type: {
      __typename: 'VOLUNTEER_CheckboxTaskFieldType',
      checkboxOptions: [],
      dynamic: false,
      ...baseTaskField,
    },
  },
  VOLUNTEER_DropdownTaskFieldType: {
    name: 'Dropdown',
    type: {
      __typename: 'VOLUNTEER_DropdownTaskFieldType',
      dropdownOptions: [],
      dynamic: false,
      ...baseTaskField,
    },
  },
  VOLUNTEER_AttachmentTaskFieldType: {
    name: 'Attachments',
    type: {
      __typename: 'VOLUNTEER_AttachmentTaskFieldType',
      allowMultiple: false,
      ...baseTaskField,
    },
  },
  VOLUNTEER_DateTaskFieldType: {
    name: 'Date',
    type: {
      __typename: 'VOLUNTEER_DateTaskFieldType',
      isExpiry: false,
      ...baseTaskField,
    },
  },
  VOLUNTEER_AddressTaskFieldType: {
    name: 'Address',
    type: {
      __typename: 'VOLUNTEER_AddressTaskFieldType',
      allowManual: false,
      ...baseTaskField,
    },
  },
  VOLUNTEER_PhoneTaskFieldType: {
    name: 'Phone Number',
    type: {
      __typename: 'VOLUNTEER_PhoneTaskFieldType',
      ...baseTaskField,
    },
  },
  VOLUNTEER_EmailTaskFieldType: {
    name: 'Email',
    type: {
      __typename: 'VOLUNTEER_EmailTaskFieldType',
      ...baseTaskField,
    },
  },
  VOLUNTEER_SignatureTaskFieldType: {
    name: 'Signature',
    type: {
      __typename: 'VOLUNTEER_SignatureTaskFieldType',
      ...baseTaskField,
    },
  },
};

interface Props {
  open: boolean;
  close: () => void;
  arrayHelpers: FieldArrayRenderProps;
  data: TaskItemFieldType | null;
}

export type FieldValues = {
  field: TaskFieldType;
  name: string;
  description?: string;
  taskItemId: string;
  order: number;
  optional: boolean;
  allowRetry?: boolean;
  dynamic?: boolean;
};

export const FieldTaskItemDialog = memo((props: Props) => {
  const { open, close, data } = props;
  const [showDescription, setShowDescription] = useState(false);
  const formikContext = useFormikContext<FormikTaskBuilderValues>();
  const {
    values: { taskItems },
  } = formikContext;
  const { theme } = useCampfireTheme();

  const [showDeleteFieldConfirm, setShowDeleteFieldConfirm] = useState(false);

  useEffect(() => {
    setShowDescription(!!data?.field.description);
  }, [open]);

  const title = useMemo(() => (data ? 'Edit Field' : 'Add New Field'), [data]);

  const initialValues: FieldValues = useMemo(
    () => ({
      name: data?.field.name ?? '',
      description: data?.field.description ?? '',
      field: data?.field ?? initialTaskFields.VOLUNTEER_ShortTextTaskFieldType.type,
      order: data?.order ?? formikContext.values.taskItems.allIds.length,
      optional: data?.optional ?? false,
      allowRetry: true,
      taskItemId: data?.taskItemId ?? `new-${uuidv4()}`,
      __typename: 'VOLUNTEER_TaskItemFieldType',
      dynamic:
        data?.__typename === 'VOLUNTEER_TaskItemFieldType' && 'dynamic' in data.field ? data.field.dynamic : false,
    }),
    [data, open]
  );

  const handleSubmit = (vals: FieldValues) => {
    const { field } = vals;
    if (field.__typename === 'VOLUNTEER_QuizTaskFieldType' && !field.quizTaskFieldCorrect) {
      return noop();
    }
    return data ? editField(vals) : addField(vals);
  };

  const addField = (vals: FieldValues) => {
    const { name, description, field, allowRetry, dynamic, ...rest } = vals;
    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      allIds: taskItems.allIds.concat(vals.taskItemId),
      byId: {
        ...taskItems.byId,
        [vals.taskItemId]: {
          ...rest,
          ...data,
          field: {
            ...field,
            name,
            description,
            allowRetry,
            taskFieldId: undefined,
            dynamic,
          },
        },
      },
    });
    close();
  };

  const editField = (vals: FieldValues) => {
    const { name, description, field, allowRetry, dynamic, optional, ...rest } = vals;

    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      byId: {
        ...taskItems.byId,
        [vals.taskItemId]: {
          ...rest,
          ...data,
          optional,
          field: {
            ...data?.field,
            ...field,
            name,
            description,
            allowRetry,
            dynamic,
          },
        },
      },
    });
    close();
  };

  const deleteField = () => {
    const optionId = taskItems.byTriggerOption[initialValues.taskItemId];
    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      allIds: taskItems.allIds.filter((itemId) => itemId !== initialValues.taskItemId),
      byTrigger: optionId
        ? taskItems.byTrigger[optionId].triggerItemIds.filter((itemId) => itemId !== initialValues.taskItemId)
        : taskItems.byTrigger,
    });
    close();
  };

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, setFieldValue, values }) => {
          return (
            <Form>
              <Box position='relative'>
                <DialogTitle>
                  <Typography variant='h6'>{title}</Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid container direction='column' spacing={2}>
                    <Grid item container spacing={2}>
                      <Grid item xs>
                        <Field
                          slow
                          name='name'
                          label='Field Name'
                          required
                          fullWidth
                          variant='outlined'
                          helper
                          autoFocus
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          select
                          variant='outlined'
                          label='Field Type'
                          disabled={!!data}
                          value={values.field.__typename}
                          onChange={(e: any) => setFieldValue('field', initialTaskFields[e.target.value].type)}
                        >
                          {Object.values(initialTaskFields).map((fieldType) => (
                            <MenuItem key={fieldType.type.__typename} value={fieldType.type.__typename}>
                              <Grid container alignItems='center' spacing={1}>
                                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                  <FieldIcon
                                    fieldType={fieldType.type.__typename}
                                    style={{ color: theme.palette.text.secondary }}
                                    fontSize='small'
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography>{fieldType.name}</Typography>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Collapse in={showDescription}>
                        <Field
                          slow
                          name='description'
                          label='Field Description'
                          multiline
                          variant='outlined'
                          fullWidth
                        />
                      </Collapse>
                      {!showDescription ? (
                        <HoverText variant='body2' color='primary' onClick={() => setShowDescription(true)}>
                          {'Add description'}
                        </HoverText>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <AdditionalFieldTypeFields type={values.field.__typename} />
                    </Grid>
                    <Grid item xs={12}>
                      <SwitchField
                        title='Optional field'
                        description={`Optional fields don't require a response to be given.`}
                        checked={values.optional ?? false}
                        onChange={() => setFieldValue('optional', !values.optional)}
                      />
                    </Grid>
                    {values.field.__typename === 'VOLUNTEER_QuizTaskFieldType' && (
                      <Grid item xs={12}>
                        <SwitchField
                          title='Allow retry'
                          description={`Volunteers can submit their answer multiple times.`}
                          checked={values.allowRetry ?? true}
                          onChange={() => setFieldValue('allowRetry', !values.allowRetry)}
                        />
                      </Grid>
                    )}
                    {[
                      'VOLUNTEER_MultipleChoiceTaskFieldType',
                      'VOLUNTEER_QuizTaskFieldType',
                      'VOLUNTEER_CheckboxTaskFieldType',
                      'VOLUNTEER_DropdownTaskFieldType',
                    ].includes(values.field.__typename) && (
                      <Grid item xs={12}>
                        <SwitchField
                          title='Dynamic field'
                          description={`Allows other task items to be shown when a certain option is selected.`}
                          checked={values.dynamic ?? false}
                          onChange={() => setFieldValue('dynamic', !values.dynamic)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Grid container justify='flex-end'>
                    {data ? (
                      <Grid item xs>
                        <TabletButton variant='outlined' color='error' onClick={() => setShowDeleteFieldConfirm(true)}>
                          {'Delete Field'}
                        </TabletButton>
                      </Grid>
                    ) : null}
                    <Grid item>
                      <TabletButton onClick={close}>{'Cancel'}</TabletButton>
                    </Grid>
                    <Grid item>
                      <TabletButton variant='contained' color='primary' type='button' onClick={submitForm}>
                        {'Done'}
                      </TabletButton>
                    </Grid>
                  </Grid>
                </DialogActions>

                {showDeleteFieldConfirm ? (
                  <Box
                    zIndex={theme.zIndex.modal + 1}
                    bgcolor='#f6f8f9'
                    position='absolute'
                    top={0}
                    left={0}
                    width={1}
                    height={1}
                    justifyContent='center'
                    alignItems='center'
                    alignContent='center'
                    display='flex'
                    flexDirection='column'
                    border={`2px solid ${theme.palette.error.light}`}
                    boxSizing='border-box'
                  >
                    <Box paddingX={3} paddingY={3} boxSizing='border-box' textAlign='center'>
                      <Typography gutterBottom variant='h6'>
                        Delete Field?
                      </Typography>
                      <Typography variant='body2'>
                        We do not recommend deleting fields that have already been used.
                      </Typography>
                      <Typography variant='body2'>This cannot be undone.</Typography>
                    </Box>

                    <Box display='flex' width={1} justifyContent='center' paddingX={3} boxSizing='border-box'>
                      <TabletButton
                        autoFocus
                        style={{ marginRight: 16 }}
                        onClick={() => setShowDeleteFieldConfirm(false)}
                      >
                        Go back
                      </TabletButton>

                      <TabletButton color='error' onClick={deleteField}>
                        Delete Field
                      </TabletButton>
                    </Box>
                  </Box>
                ) : null}
              </Box>
              <FormikErrorFocus />
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
});
