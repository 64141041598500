import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { SearchField } from '../../../../../common/inputs/SearchField';
import { Avatar } from '../../../../general/user-profile/components/Avatar';

import { GetRosterScreenProgramVolunteersData_vm_program_activeVolunteers as ActiveVolunteerType } from './__generated__/GetRosterScreenProgramVolunteersData';

export interface RosterMultiSelectVolunteersDialogProps {
  open: boolean;
  title: string;
  volunteers: Array<ActiveVolunteerType>;
  isLoading?: boolean;
  showSearchField?: boolean;
  children: React.ReactNode;
  ignoreVolunteerIds: string[];
  onClose: (event: any) => void;
  onSubmit: (volunteers: Array<ActiveVolunteerType>) => void;
}

const RosterMultiSelectVolunteersDialog = (props: RosterMultiSelectVolunteersDialogProps) => {
  const {
    open,
    onClose,
    title,
    children,
    onSubmit,
    volunteers,
    isLoading,
    showSearchField,
    ignoreVolunteerIds,
  } = props;

  const [selectedVolunteers, setSelectedVolunteers] = useState<Array<ActiveVolunteerType>>([]);
  const [searchFilter, setSearchFilter] = useState<string>();

  const filteredVolunteers = useMemo(
    () =>
      volunteers
        .sort((a, b) => a.profile.preferredName.localeCompare(b.profile.preferredName))
        .filter((x) => !ignoreVolunteerIds.includes(x.volunteerId)) ?? [],
    [volunteers, ignoreVolunteerIds]
  );

  function handleToggle(volunteer: ActiveVolunteerType) {
    const selectedVolunteer = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
    if (!selectedVolunteer) {
      setSelectedVolunteers([...selectedVolunteers, volunteer]);
      return;
    }
    setSelectedVolunteers(selectedVolunteers.filter((x) => x.volunteerId !== volunteer.volunteerId));
  }

  function handleClose(event: any) {
    onClose(event);
    setSearchFilter(undefined);
  }

  useEffect(() => forceCheck(), [searchFilter]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth style={{ zIndex: 1400 }}>
      <DialogTitle>
        <Grid container justify='space-between'>
          <Grid item>{title}</Grid>
          {showSearchField ? (
            <Grid item>
              <SearchField placeholder='Search Volunteers' growLeft onChange={(e) => setSearchFilter(e.target.value)} />
            </Grid>
          ) : null}
        </Grid>
      </DialogTitle>
      <DialogContent dividers style={{ paddingLeft: 0, paddingRight: 0 }} id='dialog-content'>
        {isLoading ? (
          <Box px={2}>{'Loading...'}</Box>
        ) : filteredVolunteers.length ? (
          <div>
            {children ? <Box paddingX={4}>{children}</Box> : null}
            <Box flex='1 1 auto' display='flex' justifyContent='center'>
              <List style={{ width: '100%' }}>
                {filteredVolunteers
                  .filter((volunteer) =>
                    searchFilter
                      ? volunteer.profile.preferredName
                          .concat(` ${volunteer.profile.lastName}`)
                          .toLowerCase()
                          .includes(searchFilter.toLowerCase())
                      : true
                  )
                  .map((volunteer) => {
                    const isSelected = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
                    return (
                      <LazyLoad
                        key={volunteer.volunteerId}
                        scrollContainer={'#dialog-content'}
                        height={66}
                        offset={400}
                        overflow
                      >
                        <VolunteerListItem
                          isSelected={!!isSelected}
                          volunteer={volunteer}
                          handleToggle={handleToggle}
                        />
                      </LazyLoad>
                    );
                  })}
              </List>
            </Box>
          </div>
        ) : (
          <Box>{'No Volunteers...'}</Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box padding={2}>
          <TabletButton variant='text' color='error' onClick={handleClose} style={{ marginRight: 8 }}>
            {'Cancel'}
          </TabletButton>
          <TabletButton
            data-track='actCnl-add-volunteers'
            variant='contained'
            color='primary'
            onClick={() => {
              onSubmit(selectedVolunteers);
              setSelectedVolunteers([]);
              handleClose({});
            }}
          >
            {'Add Volunteers'}
          </TabletButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const VolunteerListItem = React.memo(
  (props: {
    volunteer: ActiveVolunteerType;
    isSelected: boolean;
    handleToggle: (volunteer: ActiveVolunteerType) => void;
  }) => {
    const { isSelected, volunteer, handleToggle } = props;
    const {
      profile: { preferredName, lastName, avatarUrl },
    } = volunteer;
    const volunteerName = `${preferredName} ${lastName}`;
    return (
      <ListItem
        button
        onClick={() => handleToggle(volunteer)}
        style={{
          paddingLeft: '20%',
          paddingRight: 32,
        }}
      >
        <Box
          component='li'
          display='flex'
          alignContent='center'
          alignItems='center'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <FormControlLabel label='' control={<Checkbox checked={isSelected} color='primary' />} />
          <Avatar preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} size={32} />
          <Typography style={{ marginLeft: 8 }}>{volunteerName}</Typography>
        </Box>
      </ListItem>
    );
  }
);

export { RosterMultiSelectVolunteersDialog };
