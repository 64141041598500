import React, { Dispatch, SetStateAction } from 'react';
import { cloneDeep, get, set, union, uniq, xor } from 'lodash';
import { unpackToDate } from '@campfire/hot-date';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';

import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { useDeepEffect } from '../../../../hooks/useDeepEffect';
import {
  ACTIVITY_INFO,
  ROSTER_INFO,
  ROSTER_NEXT_PAGINATION,
  ROSTER_PREVIOUS_PAGINATION,
  VOLUNTEER_INFO,
} from './activity-info.gql';
import { parseRosterByVolunteerId, RosterByDateType } from './helpers';
import {
  GetActivityInfo,
  GetActivityInfoVariables,
  GetActivityInfo_vm_activity as ActivityType,
} from './__generated__/GetActivityInfo';
import { GetActivityNextPage, GetActivityNextPageVariables } from './__generated__/GetActivityNextPage';
import { GetActivityPreviousPage, GetActivityPreviousPageVariables } from './__generated__/GetActivityPreviousPage';
import { GetRosterInfo, GetRosterInfoVariables } from './__generated__/GetRosterInfo';
import {
  GetVolunteerInfo,
  GetVolunteerInfoVariables,
  GetVolunteerInfo_vm_volunteer as VolunteerType,
} from './__generated__/GetVolunteerInfo';
import {
  CancelActivityParams,
  useSaveDraftRosterFetch,
  useUncancelActivityFetch,
  useWithdrawUnavailabilityFetch,
} from '../../activities/activity-timeline/activity-timeline-actions';
import { useCreateUnAvailability } from '../../../general/activities-v2/ActivityQueries/create-unavailability';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useCancelActivityFetch } from '../roster-templates-screen/roster-template-actions';
import { MultiSelectVolunteersDialogVolunteerType } from '../../activities/activity-timeline/past/report/form/MultiSelectVolunteersDialog';
import {
  getCreateRosterTemplateLink,
  sortVolunteersByEnrolmentDate,
  sortVolunteersByRostering,
} from '../main-section/roster/roster-functions';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import {
  BulkRosterGetPrograms,
  BulkRosterGetPrograms_vm_programs as ProgramType,
} from './__generated__/BulkRosterGetPrograms';
import { GET_PROGRAMS } from './program-list.gql';
import { ConflictingDialog } from './ConflictingDialog';
import { ConflictingRosterType, useGetConflictingRosters } from '../useGetConflictingRoster';

type RosterNotesByDate = {
  [activityDate: string]: string | null | undefined;
};

type RosterNotesByActivityAndDate = {
  [activityId: string]: RosterNotesByDate;
};

interface ActivityRoster {
  activity?: ActivityType | null;
  rosters: {
    [activityDate: string]: RosterByDateType;
  };
  volunteers: VolunteerType[];
  activityDates: string[];
  onSessionClick: (activityDate: string, volunteerId: string, sessionId: string) => void;
  onUnavailableClick: (activityDate: string, volunteerId: string) => void;
  onNext: () => void;
  onBack: () => void;
  onSkipNext: () => void;
  onSkipBack: () => void;
  onSave: () => void;
  onPublish: () => void;
  onDiscard: () => void;
  toBeUpdate: boolean;
  toBePublish: boolean;
  updatingVolunteers: VolunteerType[];
  showCancellationReasonDialog: boolean;
  setShowCancellationReasonDialog: (show: boolean) => void;
  cancelActivityDate?: string;
  runCancelActivity: (args: CancelActivityParams) => void;
  onCancelRoster: (activityDate: string) => void;
  onRestoreRoster: (activityDate: string, cancelledActivityId?: string) => void;
  addNewVolunteer: (addedVolunteers: MultiSelectVolunteersDialogVolunteerType[]) => void;
  isLoading: boolean;
  onAddRoster: () => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  programs: ProgramType[];
  sortedVolunteerIdsByEnrolmentDate?: string[];
  setSortedVolunteerIdsByEnrolmentDate: (x: string[] | undefined) => void;
  rosterNotes?: RosterNotesByActivityAndDate;
  setRosterNotes: Dispatch<SetStateAction<RosterNotesByActivityAndDate | undefined>>;
  setExpanded: (x: boolean) => void;
  orgLogo: string | null;
}

export const RosterContext = React.createContext<ActivityRoster>({
  rosters: {},
  volunteers: [],
  activityDates: [],
  onSessionClick: () => {},
  onUnavailableClick: () => {},
  onNext: () => {},
  onBack: () => {},
  onSkipNext: () => {},
  onSkipBack: () => {},
  onSave: () => {},
  onPublish: () => {},
  onDiscard: () => {},
  toBePublish: false,
  toBeUpdate: false,
  updatingVolunteers: [],
  showCancellationReasonDialog: false,
  setShowCancellationReasonDialog: () => {},
  runCancelActivity: () => {},
  onCancelRoster: () => {},
  onRestoreRoster: () => {},
  addNewVolunteer: () => {},
  isLoading: false,
  onAddRoster: () => {},
  searchValue: '',
  setSearchValue: () => {},
  programs: [],
  setSortedVolunteerIdsByEnrolmentDate: () => {},
  rosterNotes: {},
  setRosterNotes: () => {},
  setExpanded: () => {},
  orgLogo: null,
});

interface RosterContextProviderProps {
  children: React.ReactElement;
  activityId: string;
}

type SettingRoster = {
  volunteerId: string;
  sessionId: string;
  activityId: string;
  activityDate: string;
};

const EXPANDED_VIEW_COLUMN_NO = 5;
const STANDARD_VIEW_COLUMN_NO = 3;

export const RosterContextProvider = ({ activityId, children }: RosterContextProviderProps) => {
  const [queryActivityInfo, { loading: actiivtyInfoLoading }] = useCampfireLazyQuery<
    GetActivityInfo,
    GetActivityInfoVariables
  >(ACTIVITY_INFO);
  const [queryRosterNextPage] = useCampfireLazyQuery<GetActivityNextPage, GetActivityNextPageVariables>(
    ROSTER_NEXT_PAGINATION
  );
  const [queryRosterPreviousPage] = useCampfireLazyQuery<GetActivityPreviousPage, GetActivityPreviousPageVariables>(
    ROSTER_PREVIOUS_PAGINATION
  );
  const { data: vmPrograms } = useCampfireQuery<BulkRosterGetPrograms, {}>(GET_PROGRAMS, {
    options: {},
  });
  const [activity, setActivity] = React.useState<ActivityType | null>(null);
  const [orgLogo, setOrgLogo] = React.useState<string | null>(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [sortedVolunteerIdsByEnrolmentDate, setSortedVolunteerIdsByEnrolmentDate] = React.useState<string[]>();
  const [addingVolunteerIds, setAddingVolunteerIds] = React.useState<string[]>([]);
  const [rosterNotes, setRosterNotes] = React.useState<RosterNotesByActivityAndDate | undefined>();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [settingRoster, setSettingRoster] = React.useState<SettingRoster | null>(null);
  const [conflictingRoster, setConflictingRoster] = React.useState<ConflictingRosterType>([]);
  const fetchConflictRoster = useGetConflictingRosters();
  const getConflictRoster = (volunteerId: string, activityDate: string, sessionId: string) => {
    const session = activity?.sessions.find((s) => s.sessionId === sessionId);
    if (!session || !session.startTime || !session.endTime) {
      return Promise.resolve({ conflictingRosters: [] as ConflictingRosterType });
    }
    return fetchConflictRoster
      .run({
        volunteerId,
        activityDate,
        startTime: session?.startTime,
        endTime: session?.endTime,
      })
      .then((response) => response?.data?.data);
  };

  const { setSnackbar } = useSnackbar();
  React.useEffect(() => {
    queryActivityInfo({ variables: { activityId, numberOfRosters: STANDARD_VIEW_COLUMN_NO } })
      .then((response) => response.data)
      .then((vmData) => {
        setActivity(vmData?.vm.activity ?? null);
        setOrgLogo(vmData?.orgLogo ?? null);
        if (vmData?.vm.activity?.__typename === 'VOLUNTEER_NonRecurringActivityType') {
          const allLastNext = uniq(
            Array.from(vmData?.vm.activity?.lastX ?? [])
              .concat(vmData?.vm.activity?.startDate)
              .concat(vmData?.vm.activity?.nextX ?? [])
          ).sort((a, b) => (a > b ? 1 : -1));
          setActivityDates(allLastNext.slice(-STANDARD_VIEW_COLUMN_NO));
        } else {
          const allLastNext = Array.from(vmData?.vm.activity?.lastX ?? [])
            .reverse()
            .concat(vmData?.vm.activity?.nextX ?? []);
          setActivityDates(allLastNext.slice(-STANDARD_VIEW_COLUMN_NO));
        }
      });
  }, [activityId]);

  React.useEffect(() => {
    if (expanded) {
      queryRosterNextPage({
        variables: {
          activityId,
          from: unpackToDate(activityDates[activityDates.length - 1])
            .plus({ days: 1 })
            .toISODate(),
          numberOfRosters: EXPANDED_VIEW_COLUMN_NO - STANDARD_VIEW_COLUMN_NO,
        },
      })
        .then((response) => response.data)
        .then((vmData) => vmData?.vm.activity?.nextX || [])
        .then((nextX) => {
          if (nextX.length > 0) {
            setActivityDates(activityDates.concat(nextX));
          }
        });
    }
    if (activityDates.length === EXPANDED_VIEW_COLUMN_NO) {
      setActivityDates(activityDates.slice(0, STANDARD_VIEW_COLUMN_NO));
    }
  }, [expanded]);

  const [rosters, setRosters] = React.useState<{
    [activityId: string]: {
      [actiivtyDate: string]: RosterByDateType;
    };
  }>({});
  const [volunteers, setVolunteers] = React.useState<{ [volunteerId: string]: VolunteerType }>({});
  const [activityDates, setActivityDates] = React.useState<string[]>([]);

  const [queryRosterInfo, { loading: isRosterLoading }] = useCampfireLazyQuery<GetRosterInfo, GetRosterInfoVariables>(
    ROSTER_INFO
  );

  const [queryVolunteerInfo] = useCampfireLazyQuery<GetVolunteerInfo, GetVolunteerInfoVariables>(VOLUNTEER_INFO);

  const [cancelActivityDate, setCancelActivityDate] = React.useState<string>('');
  const cancelActivity = useCancelActivityFetch();

  const runCancelActivity = React.useCallback(
    ({
      activityCancellationReasonId,
      description,
      addedAttachments,
      attachmentCreationTokens,
    }: CancelActivityParams) => {
      cancelActivity
        .run({
          activityId,
          activityDate: cancelActivityDate,
          activityCancellationReasonId,
          description: JSON.stringify(description),
          addedAttachments,
          attachmentCreationTokens,
        })
        .then(({ data }) => {
          const rostersCopy = cloneDeep(rosters);
          set(rostersCopy, [activityId, cancelActivityDate, 'cancelledActivityId'], data.data.cancelledActivityId);
          setRosters(rostersCopy);
        });
      setShowCancellationReasonDialog(false);
    },
    [activityId, cancelActivityDate]
  );

  useDeepEffect(() => {
    if (activityDates.length > 0) {
      Promise.all(activityDates.map((activityDate) => queryRosterInfo({ variables: { activityDate, activityId } })))
        .then((responses) => responses.map((response) => response.data))
        .then((vmData) => {
          setRosterNotes({
            ...rosterNotes,
            [activityId]: {
              ...(rosterNotes || {})[activityId],
              ...vmData.reduce(
                (acc, cur, curIndex) => ({
                  ...acc,
                  [activityDates[curIndex]]:
                    cur?.vm.activity?.draftRoster?.rosterNotes || cur?.vm.activity?.publishedRoster?.rosterNotes,
                }),
                {}
              ),
            },
          });
          setRosters({
            ...rosters,
            [activityId]: {
              ...rosters[activityId],
              ...vmData.reduce(
                (acc, cur, curIndex) => ({
                  ...acc,
                  [activityDates[curIndex]]: {
                    volunteers: parseRosterByVolunteerId(cur?.vm.activity),
                    isPublished: Boolean(cur?.vm.activity?.publishedRoster),
                    publishedRoster: cur?.vm.activity?.publishedRoster,
                    cancelledActivityId: cur?.vm.activity?.cancelledActivity?.cancelledActivityId,
                    activityDate: activityDates[curIndex],
                    rosterNotes:
                      cur?.vm.activity?.draftRoster?.rosterNotes || cur?.vm.activity?.publishedRoster?.rosterNotes,
                  },
                }),
                {}
              ),
            },
          });
        });
    }
  }, [activityDates]);

  React.useEffect(() => {
    setAddingVolunteerIds([]);
  }, [activityId]);

  const volunteerIds = union(
    Object.values(rosters[activityId] ?? {}).flatMap((roster) => Object.keys(roster.volunteers))
  );

  const allVolunteerIds = union(volunteerIds, addingVolunteerIds);

  const fetchingVolunteerIds = React.useMemo(() => allVolunteerIds.filter((vId) => !volunteers[vId]), [
    volunteerIds,
    addingVolunteerIds,
    volunteers,
  ]);

  useDeepEffect(() => {
    Promise.all(fetchingVolunteerIds.map((volunteerId) => queryVolunteerInfo({ variables: { volunteerId } })))
      .then((responses) => responses.map((response) => response.data?.vm.volunteer))
      .then((vs: Array<VolunteerType | null | undefined>) =>
        vs.reduce((acc, cur) => {
          if (!cur) {
            return acc;
          }
          return {
            ...acc,
            [cur.volunteerId]: cur,
          };
        }, {})
      )
      .then((data) => setVolunteers({ ...volunteers, ...data }));
  }, [fetchingVolunteerIds]);

  const onNext = React.useCallback(() => {
    queryRosterNextPage({
      variables: {
        activityId,
        from: unpackToDate(activityDates[activityDates.length - 1])
          .plus({ days: 1 })
          .toISODate(),
        numberOfRosters: 1,
      },
    })
      .then((response) => response.data)
      .then((vmData) => vmData?.vm.activity?.nextX || [])
      .then((nextX) => {
        if (nextX.length > 0) {
          setActivityDates(activityDates.slice(1).concat(nextX));
        }
      });
  }, [activityDates]);

  const onBack = React.useCallback(() => {
    queryRosterPreviousPage({
      variables: {
        activityId,
        until: unpackToDate(activityDates[0])
          .minus({ days: 1 })
          .toISODate(),
        numberOfRosters: 1,
      },
    })
      .then((response) => response.data)
      .then((vmData) => vmData?.vm.activity?.lastX || [])
      .then((lastX) => {
        if (lastX.length > 0) {
          setActivityDates(lastX.concat(activityDates.slice(0, -1)));
        }
      });
  }, [activityDates]);

  const onSkipNext = React.useCallback(() => {
    queryRosterNextPage({
      variables: {
        activityId,
        from: unpackToDate(activityDates[activityDates.length - 1])
          .plus({ days: 1 })
          .toISODate(),
        numberOfRosters: expanded ? EXPANDED_VIEW_COLUMN_NO : STANDARD_VIEW_COLUMN_NO,
      },
    })
      .then((response) => response.data)
      .then((vmData) => vmData?.vm.activity?.nextX || [])
      .then((nextX) => {
        if (nextX.length > 0) {
          setActivityDates(nextX);
        }
      });
  }, [activityDates]);

  const onSkipBack = React.useCallback(() => {
    queryRosterPreviousPage({
      variables: {
        activityId,
        until: unpackToDate(activityDates[0])
          .minus({ days: 1 })
          .toISODate(),
        numberOfRosters: expanded ? EXPANDED_VIEW_COLUMN_NO : STANDARD_VIEW_COLUMN_NO,
      },
    })
      .then((response) => response.data)
      .then((vmData) => vmData?.vm.activity?.lastX || [])
      .then((lastX) => {
        if (lastX.length > 0) {
          setActivityDates(lastX.reverse());
        }
      });
  }, [activityDates]);

  const onSessionClick = (activityDate: string, volunteerId: string, sessionId: string) => {
    const rostersCopy = cloneDeep(rosters);
    const path = [activityId, activityDate, 'volunteers', volunteerId, 'draftRoster'];
    const draftRosters = get(rosters, path, []) as string[];

    const isRostered = draftRosters.includes(sessionId);

    if (isRostered) {
      set(rostersCopy, path, draftRosters.filter((draftRosterSessionId) => draftRosterSessionId !== sessionId).sort());
    } else {
      getConflictRoster(volunteerId, activityDate, sessionId).then((response) => {
        if (response && response.conflictingRosters.length > 0) {
          set(rostersCopy, [activityId, activityDate, 'volunteers', volunteerId, 'isConflicting'], true);
          setSettingRoster({ volunteerId, activityId, activityDate, sessionId });
          setConflictingRoster(response.conflictingRosters);
        } else {
          set(rostersCopy, path, draftRosters.concat(sessionId).sort());
        }
      });
    }
    setRosters(rostersCopy);
  };

  const onCancelConflict = () => {
    if (settingRoster === null) {
      return;
    }
    const rostersCopy = cloneDeep(rosters);
    const { activityDate, volunteerId } = settingRoster;
    set(rostersCopy, [activityId, activityDate, 'volunteers', volunteerId, 'isConflicting'], false);
    setSettingRoster(null);
    setRosters(rostersCopy);
    setConflictingRoster([]);
  };

  const onContinueConflict = () => {
    if (settingRoster === null) {
      return;
    }

    const { activityDate, volunteerId, sessionId } = settingRoster;
    const rostersCopy = cloneDeep(rosters);
    const path = [activityId, activityDate, 'volunteers', volunteerId, 'draftRoster'];
    const draftRosters = get(rosters, path, []) as string[];

    set(rostersCopy, path, draftRosters.concat(sessionId).sort());
    setRosters(rostersCopy);
    setSettingRoster(null);
    setConflictingRoster([]);
  };

  const createUnavailability = useCreateUnAvailability();
  const removeUnavailability = useWithdrawUnavailabilityFetch();

  const onUnavailableClick = (activityDate: string, volunteerId: string) => {
    const rostersCopy = cloneDeep(rosters);
    if (get(rosters, [activityId, activityDate, 'volunteers', volunteerId, 'unavailable'])) {
      const activityUnavailabilityId = get(rostersCopy, [
        activityId,
        activityDate,
        'volunteers',
        volunteerId,
        'activityUnavailabilityId',
      ]);
      removeUnavailability.run({ activityUnavailabilityId }).then(() => {
        setSnackbar({
          open: true,
          message: 'Set availability success',
          variant: 'success',
        });
        set(rostersCopy, [activityId, activityDate, 'volunteers', volunteerId, 'unavailable'], false);
        set(rostersCopy, [activityId, activityDate, 'volunteers', volunteerId, 'activityUnavailabilityId'], undefined);
        setRosters(rostersCopy);
      });
    } else {
      createUnavailability({
        activityDate,
        activityEnrolmentId: get(rosters, [activityId, activityDate, 'volunteers', volunteerId, 'enrolmentId']),
      }).then((response) => {
        setSnackbar({
          variant: 'success',
          message: 'Set unavailability successfully',
          open: true,
        });

        set(rostersCopy, [activityId, activityDate, 'volunteers', volunteerId, 'unavailable'], true);
        set(
          rostersCopy,
          [activityId, activityDate, 'volunteers', volunteerId, 'activityUnavailabilityId'],
          response.data.data.activityUnavailabilityId
        );
        set(rostersCopy, [activityId, activityDate, 'volunteers', volunteerId, 'publishedRoster'], []);
        set(rostersCopy, [activityId, activityDate, 'volunteers', volunteerId, 'draftRoster'], []);
        set(rostersCopy, [activityId, activityDate, 'volunteers', volunteerId, 'initialDraftRoster'], []);
        setRosters(rostersCopy);
      });
    }
  };

  const updatingRosters = React.useMemo(() => {
    return activityDates.filter((activityDate) => {
      const rosterByDate = get(rosters, [activityId, activityDate]);
      if (!rosterByDate) {
        return false;
      }
      if (get(rosterNotes, [activityId, activityDate]) !== rosterByDate.rosterNotes) {
        return true;
      }
      return allVolunteerIds
        .map((vId) => rosterByDate.volunteers[vId])
        .filter((rosterByVolunteer) => {
          return rosterByVolunteer && rosterByVolunteer.draftRoster;
        })
        .some((rosterByVolunteer) => {
          return xor(rosterByVolunteer.draftRoster, rosterByVolunteer.initialDraftRoster).length > 0;
        });
    });
  }, [rosters, rosterNotes, allVolunteerIds, activityId, activityDates]);

  const publishingRosters = React.useMemo(() => {
    return activityDates.filter((activityDate) => {
      const rosterByDate = get(rosters, [activityId, activityDate]);
      if (!rosterByDate) {
        return false;
      }
      if (get(rosterNotes, [activityId, activityDate]) !== rosterByDate.rosterNotes) {
        return true;
      }
      return allVolunteerIds
        .map((vId) => rosterByDate.volunteers[vId])
        .filter((rosterByVolunteer) => rosterByVolunteer && rosterByVolunteer.draftRoster)
        .some((rosterByVolunteer) => xor(rosterByVolunteer.draftRoster, rosterByVolunteer.publishedRoster).length > 0);
    });
  }, [rosters, rosterNotes, allVolunteerIds, activityDates]);

  const saveDraftRoster = useSaveDraftRosterFetch();

  const onSaveDraftRoster = (published?: boolean) =>
    Promise.all(
      (published ? publishingRosters : updatingRosters).map((updatingDate) =>
        saveDraftRoster.run({
          activityId,
          activityDate: updatingDate,
          draftRosterings: allVolunteerIds
            .filter((vId) => get(rosters, [activityId, updatingDate, 'volunteers', vId, 'draftRoster'], []).length > 0)
            .map((vId) => ({
              volunteerId: vId,
              sessionIds: get(rosters, [activityId, updatingDate, 'volunteers', vId, 'draftRoster'], []),
            })),
          immediatePublish: published,
          rosterNotes: get(rosterNotes, [activityId, updatingDate]),
        })
      )
    );

  const onSave = () => {
    onSaveDraftRoster().then(() => {
      setSnackbar({
        variant: 'success',
        message: 'Save successfully',
        open: true,
      });
      const rostersCopy = cloneDeep(rosters);
      updatingRosters.forEach((updatingDate) => {
        allVolunteerIds.forEach((vId) => {
          const path = [activityId, updatingDate, 'volunteers', vId];
          set(rostersCopy, path.concat('initialDraftRoster'), get(rosters, path.concat('draftRoster')));
          set(rostersCopy, [activityId, updatingDate, 'rosterNotes'], get(rosterNotes, [activityId, updatingDate]));
        });
      });
      setRosters(rostersCopy);
    });
  };

  const onPublish = () => {
    onSaveDraftRoster(true).then(() => {
      setSnackbar({
        variant: 'success',
        message: 'Publish successfully',
        open: true,
      });
      const rostersCopy = cloneDeep(rosters);
      publishingRosters.forEach((updatingDate) => {
        allVolunteerIds.forEach((volunteerId) => {
          const path = [activityId, updatingDate, 'volunteers', volunteerId];
          set(rostersCopy, path.concat('initialDraftRoster'), get(rosters, path.concat('draftRoster')));
          set(rostersCopy, path.concat('publishedRoster'), get(rosters, path.concat('draftRoster')));
          set(rostersCopy, [activityId, updatingDate, 'rosterNotes'], get(rosterNotes, [activityId, updatingDate]));
        });
      });
      setRosters(rostersCopy);
    });
  };

  const onDiscard = () => {
    const rostersCopy = cloneDeep(rosters);
    updatingRosters.forEach((activityDate) => {
      allVolunteerIds.forEach((volunteerId) => {
        const path = [activityId, activityDate, 'volunteers', volunteerId];
        set(rostersCopy, path.concat('draftRoster'), get(rosters, path.concat('initialDraftRoster')));
      });
    });
    setRosters(rostersCopy);
  };

  const [showCancellationReasonDialog, setShowCancellationReasonDialog] = React.useState(false);
  const updatingVolunteers = uniq(
    publishingRosters.flatMap((activityDate) =>
      allVolunteerIds.filter(
        (vId) =>
          xor(
            get(rosters, [activityId, activityDate, 'volunteers', vId, 'draftRoster'], []),
            get(rosters, [activityId, activityDate, 'volunteers', vId, 'publishedRoster'], [])
          ).length > 0
      )
    )
  );
  const unCancelActivity = useUncancelActivityFetch();

  const onRestoreRoster = (activityDate: string, cancelledActivityId?: string) => {
    if (cancelledActivityId) {
      unCancelActivity.run({ cancelledActivityId }).then(() => {
        const rostersCopy = cloneDeep(rosters);
        set(rostersCopy, [activityId, activityDate, 'cancelledActivityId'], undefined);
        setRosters(rostersCopy);
        setSnackbar({
          variant: 'success',
          message: 'Restore activity successfully',
          open: true,
        });
      });
    }
  };
  const onCancelRoster = (activityDate: string) => {
    setCancelActivityDate(activityDate);
    setShowCancellationReasonDialog(true);
  };

  const addNewVolunteer = (addedVolunteers: MultiSelectVolunteersDialogVolunteerType[]) => {
    setAddingVolunteerIds(addingVolunteerIds.concat(addedVolunteers.map((v) => v.volunteerId)));
  };

  const history = useHistory();

  const onAddRoster = () => {
    history.push(getCreateRosterTemplateLink(activityId, 'bulk'));
  };

  const [sortVolsRowBy] = useQueryParam('sortVolsRowBy', StringParam);

  const rosterByActivity = rosters[activityId];

  const sortedVolunteers = React.useMemo(() => {
    return uniq(volunteerIds.concat(addingVolunteerIds))
      .map((vId) => volunteers[vId] || { volunteerId: vId })
      .sort((a, b) => {
        if (!a?.profile || !b?.profile) {
          return 0;
        }
        return sortVolsRowBy === 'alphabetical'
          ? a.profile.preferredName.localeCompare(b.profile.preferredName)
          : sortVolsRowBy === 'newest' && sortedVolunteerIdsByEnrolmentDate
          ? sortVolunteersByEnrolmentDate(a, b, sortedVolunteerIdsByEnrolmentDate)
          : sortVolunteersByRostering(a, b, rosterByActivity);
      }) as VolunteerType[];
  }, [
    sortVolsRowBy,
    volunteers,
    volunteerIds,
    addingVolunteerIds,
    sortedVolunteerIdsByEnrolmentDate,
    rosterByActivity,
  ]);

  return (
    <RosterContext.Provider
      value={{
        activity,
        rosters: rosterByActivity,
        volunteers: sortedVolunteers,
        activityDates,
        onNext,
        onBack,
        onSkipNext,
        onSkipBack,
        onSessionClick,
        onUnavailableClick,
        onSave,
        onPublish,
        onDiscard,
        toBeUpdate: updatingRosters.length > 0,
        toBePublish: publishingRosters.length > 0,
        updatingVolunteers: updatingVolunteers.map((volunteerId) => get(volunteers, [activityId, volunteerId])),
        showCancellationReasonDialog,
        setShowCancellationReasonDialog,
        cancelActivityDate: cancelActivityDate,
        runCancelActivity,
        onCancelRoster,
        onRestoreRoster,
        addNewVolunteer,
        isLoading: isRosterLoading || actiivtyInfoLoading,
        onAddRoster,
        searchValue,
        setSearchValue,
        programs: vmPrograms?.vm.programs || [],
        sortedVolunteerIdsByEnrolmentDate: sortedVolunteerIdsByEnrolmentDate,
        setSortedVolunteerIdsByEnrolmentDate: setSortedVolunteerIdsByEnrolmentDate,
        rosterNotes,
        setRosterNotes,
        setExpanded,
        orgLogo,
      }}
    >
      {children}
      {settingRoster && (
        <ConflictingDialog
          onCancel={onCancelConflict}
          onContinue={onContinueConflict}
          conflictRosters={conflictingRoster}
        />
      )}
    </RosterContext.Provider>
  );
};
