import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { HoverText } from '@campfire/hover-link';
import { Link } from 'react-router-dom';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface Props {
  heading: String;
  viewAllPath: string;
}

export const ManagementOverviewHeading = (props: Props) => {
  const { heading, viewAllPath } = props;
  const { theme } = useCampfireTheme();
  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} paddingBottom={'6px'}>
        <Typography
          variant='h6'
          style={{
            color: theme.color.grey.neutralBrand800,
            fontSize: '20px',
            fontWeight: 800,
            letterSpacing: '-0.02em',
          }}
        >
          {heading}
        </Typography>
        <Link
          to={{
            pathname: `${viewAllPath}`,
            search: '&tab=incomplete',
          }}
          style={{ textDecoration: 'none', margin: 4 }}
        >
          <HoverText variant={'caption'} color={'primary'}>
            {'View All'}
          </HoverText>
        </Link>
      </Box>
      <Divider />
    </Box>
  );
};
