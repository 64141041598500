import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { AdminConsoleEmbeddableWidgetsTableContainer } from './AdminConsoleEmbeddableWidgetsTableContainer';
import {
  EMBEDDED_WIDGET_GET_REPORT_TYPES,
  EMBEDDED_WIDGET_GET_ACTIVITY_TYPES,
} from './admin-console-embeddable-widgets.gql';

// Usable Field Report Table Data
export interface AdminConsoleWidgetsReportTypes {
  type?: string | undefined;
  reportType?: string | undefined;
  reportTypeID?: string | undefined;
  fieldName?: string | undefined;
  fieldId?: string | undefined;
}

// Usable Field Report Table Data
export interface AdminConsoleWidgetsActivityTypes {
  program?: string;
  activityName?: string;
  activityID?: string;
}

const AdminConsoleEmbeddableWidgetsPanel = () => {
  // Get Data from FieldID Data
  const { data: fieldQuery, loading: fieldsLoading } = useCampfireQuery<any, {}>(EMBEDDED_WIDGET_GET_REPORT_TYPES);

  // Get Fields from Fields Data
  const fieldRows = useMemo(() => fieldQuery?.vm.reportTypes ?? [], [fieldQuery]);

  // Get Data from ActivityId Data
  const { data: activityQuery, loading: activitiesLoading } = useCampfireQuery<any, {}>(
    EMBEDDED_WIDGET_GET_ACTIVITY_TYPES
  );

  // Get Activities from Fields Data
  const activityRows = useMemo(() => activityQuery?.vm.activities ?? [], [activityQuery]);

  // Configuring new object to populate field table
  const usefulFieldRows: Array<AdminConsoleWidgetsReportTypes> = [];

  fieldRows
    .filter((reportType: any) => reportType.items !== 'undefined' && reportType.items.length > 0)
    .filter((reportType: any) => !reportType.dateDeactivated)
    .forEach((reportType: any) =>
      reportType.items
        .filter((item: any) => item.dateAdded && !item.dateRemoved)
        .sort((a: any, b: any) =>
          a.__typename === 'VOLUNTEER_ReportTypeFieldType' && b.__typename === 'VOLUNTEER_ReportTypeFieldType'
            ? a.field.name < b.field.name
              ? -1
              : 1
            : 0
        )
        .forEach((item: any) => {
          if (
            item.__typename === 'VOLUNTEER_ReportTypeFieldType' &&
            item.field.__typename === 'VOLUNTEER_NumericFieldType'
          ) {
            usefulFieldRows.push({
              type: 'Numeric',
              reportTypeID: reportType.reportTypeId,
              reportType: reportType.name,
              fieldId: item.field.fieldId,
              fieldName: item.field.name,
            });
          }
        })
    );

  // Configuring new object to populate field table
  const usefulActivityRows: Array<AdminConsoleWidgetsActivityTypes> = [];

  activityRows
    .sort((a: any, b: any) =>
      a.__typename === 'VOLUNTEER_RecurringActivityType' && b.__typename === 'VOLUNTEER_RecurringActivityType'
        ? a.name < b.name
          ? -1
          : 1
        : 0
    )
    .filter((activityType: any) => !activityType.program.dateSuspended && !activityType.program.dateDeleted)
    .forEach((activityType: any) => {
      if (activityType.program.__typename === 'VOLUNTEER_ProgramType') {
        usefulActivityRows.push({
          program: activityType.program.name,
          activityName: activityType.name,
          activityID: activityType.activityId,
        });
      }
    });

  return (
    <Box position='relative' width={1} display='flex' justifyContent='center'>
      <LinearProgressOverlay isLoading={fieldsLoading || activitiesLoading} />
      <Box px={8} pt={4} width={1} maxWidth={720}>
        <AdminConsoleEmbeddableWidgetsTableContainer
          usableFieldTableRows={usefulFieldRows}
          usableActivityTableRows={usefulActivityRows}
        />
      </Box>
    </Box>
  );
};

export { AdminConsoleEmbeddableWidgetsPanel };
