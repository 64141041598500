import React from 'react';
import { Redirect } from 'react-router';
import { ActivitiesV2Screen } from '../../screens/general/activities-v2/ActivitiesV2Screen';
import { ActivityReportScreen } from '../../screens/general/activity-report/ActivityReportScreen';
import { IncidentReportScreen } from '../../screens/general/incident-report/IncidentReportScreen';
import { CampfireSwitch } from '../common/CampfireSwitch';
import MiniRoute from '../common/MiniRoute';
import { useRoutesMap } from '../common/useRoutesMaps';

const getLegacyRoutes = ({ route, isAuthorized }: { route: string; isAuthorized: boolean }) => [
  <MiniRoute
    //
    key={`/${route}/explore`}
    path={`/${route}/explore`}
    component={() => <Redirect to={`/${route}/activities/all-activities`} />}
    isAuthorized={isAuthorized}
  />,
  <MiniRoute
    //
    key={`/${route}/my-activities`}
    path={`/${route}/my-activities`}
    component={() => <Redirect to={`/${route}/activities/my-activities`} />}
    isAuthorized={isAuthorized}
  />,
];

export const VolunteeringContentBlock = () => {
  const {
    volunteering: { route, isAuthorized },
  } = useRoutesMap();

  return (
    <CampfireSwitch>
      {getLegacyRoutes({ route, isAuthorized })}
      <MiniRoute
        //
        path={`/${route}/activities`}
        component={ActivitiesV2Screen}
        isAuthorized={isAuthorized}
      />
      <MiniRoute
        //
        path={`/${route}/activity-report`}
        component={ActivityReportScreen}
        isAuthorized={isAuthorized}
      />
      <MiniRoute
        //
        path={`/${route}/incident-report`}
        component={IncidentReportScreen}
        isAuthorized={isAuthorized}
      />
    </CampfireSwitch>
  );
};

export default VolunteeringContentBlock;
