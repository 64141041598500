import { ContentState, EditorState, convertFromRaw } from 'draft-js';
import { ProgramActionType, ProgramStatus } from './AllProgramTypes';

export const getProgramsStatus = (programId: String, volunteerData: any): ProgramStatus => {
  const isActiveVolunteer = volunteerData?.programs.some(
    (volunteerProgram: any) => programId === volunteerProgram.programId
  );
  if (isActiveVolunteer) return 'joined';
  const hasPendingApplication = volunteerData?.programApplications?.some(
    (application: any) =>
      application.program.programId === programId && !application.dateWithdrawn && !application.dateActioned
  );
  if (hasPendingApplication) return 'pending';
  return 'not on team';
};

export const getProgramActions = (status: ProgramStatus, restrictedProgram?: boolean): ProgramActionType => {
  switch (status) {
    case 'not on team':
      return restrictedProgram ? { mainAction: 'apply', subActions: [] } : { mainAction: 'join', subActions: [] };

    case 'pending':
      return { mainAction: '', subActions: ['withdraw'] };

    case 'joined':
      return { mainAction: '', subActions: [] };

    default:
      return { mainAction: '', subActions: [] };
  }
};

const isRichText = (text: string) => text.substring(0, 10) === '{"blocks":';

export const convertToEditorState = (text: string) => {
  if (isRichText(text)) return EditorState.createWithContent(convertFromRaw(JSON.parse(text)));
  // Since database can contain a mix of string and rich text string (rich text support was added later), this little hack to check if the string is a plain string or rich text string is needed. Alternative: db migration to convert plain text string to rich text format.
  return EditorState.createWithContent(ContentState.createFromText(text));
};
