import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { ActivitiesExploreProgram } from '../../__generated__/ActivitiesExploreProgram';
import { DayFilter } from '../day-filter/DayFilter';
import { useFilterStyles } from '../../../../../common/filter-fields/FilterClasses';
import {
  ActivityTypeFiltersRadio,
  DurationFiltersRadio,
  Filters,
  LocationFiltersRadio,
  RosterTypeFiltersRadio,
  StatusFiltersRadio,
} from '../../../../../common/filter-fields/filters';
import { ProgramFilter } from '../program-filter/ProgramFilter';
import { TimeFilter } from '../time-filter/TimeFilter';

type ActivitiesFilterDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedFilters: Filters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setSelectedProgramId: (x: string) => void;
  selectedProgramId: string;
  programs: Array<ActivitiesExploreProgram>;
  isManagementView?: boolean;
  clearFilters: () => void;
};

const ActivitiesFilterDialog = (props: ActivitiesFilterDialogProps) => {
  const {
    onClose,
    open,
    selectedFilters,
    setSelectedFilters,
    setSelectedProgramId,
    selectedProgramId,
    programs,
    isManagementView,
    clearFilters,
  } = props;
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);

  return (
    <FullscreenDialog
      keepMounted
      open={open}
      close={onClose}
      title={'Filters'}
      fullScreen
      dialogActions={() => (
        <TabletButton
          data-track={`actCnlMapFilters-ApplyFilterDialog`}
          variant='contained'
          color='primary'
          onClick={onClose}
        >
          Apply
        </TabletButton>
      )}
    >
      <Box width={1} display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='body1' style={{ fontWeight: 600 }}>
          {'Filters'}
        </Typography>
        <HoverText
          data-track='actCnlMapFilters-ClearFilters'
          style={{ fontSize: 12, fontWeight: 600 }}
          onClick={clearFilters}
        >
          Clear filters
        </HoverText>
      </Box>
      <DayFilter setSelectedFilters={setSelectedFilters} isManagementView={isManagementView} />
      <Divider className={classes.divider} />
      {isManagementView && (
        <>
          <StatusFiltersRadio
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            isManagementView={isManagementView}
          />
          <Divider className={classes.divider} />
        </>
      )}
      <ProgramFilter
        setSelectedProgramId={setSelectedProgramId}
        selectedProgramId={selectedProgramId}
        programs={programs}
        isManagementView={isManagementView}
      />
      <Divider className={classes.divider} />
      <TimeFilter selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
      <Divider className={classes.divider} />
      <DurationFiltersRadio
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        isManagementView={isManagementView || false}
      />
      <Divider className={classes.divider} />
      <ActivityTypeFiltersRadio
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        isManagementView={isManagementView || false}
      />
      {isManagementView ? (
        <>
          <Divider className={classes.divider} />
          <RosterTypeFiltersRadio
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            isManagementView={isManagementView || false}
          />
        </>
      ) : null}
      <Divider className={classes.divider} />
      <LocationFiltersRadio
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        isManagementView={isManagementView || false}
      />
    </FullscreenDialog>
  );
};

export { ActivitiesFilterDialog };
