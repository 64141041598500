import React, { useMemo } from 'react';
import Icon from '@mdi/react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { iconList } from '../../../../../common/icons/RoleIconList';

export interface ValueType {
  name: string;
  icon: any;
  description: any;
}

export interface Props {
  value: ValueType;
  onChange: (value: ValueType) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    badge: {
      minWidth: '100px',
      marginRight: '16px',
    },
    select: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20px',
    },
    list: {
      '& li': {
        display: 'inline-block',
      },
    },
    paper: {
      width: '400px',
      height: '300px',
      overfolowY: 'auto',
    },
  })
);

export function RoleForm({ value, onChange }: Props) {
  const classes = useStyles();
  const iconListOption = useMemo(() => {
    return Object.entries(iconList).map(([key, valueObj]) => ({ key, value: valueObj }));
  }, [iconList]);

  return (
    <>
      <Box display='flex'>
        <FormControl variant='outlined' className={classes.badge}>
          <InputLabel htmlFor='input-badge'>Badge</InputLabel>
          <Select
            value={value.icon}
            onChange={(e) => onChange({ ...value, icon: e.target.value })}
            label='Age'
            inputProps={{
              name: 'badge',
              id: 'input-badge',
            }}
            classes={{
              select: classes.select,
            }}
            MenuProps={{
              classes: {
                list: classes.list,
                paper: classes.paper,
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              getContentAnchorEl: null,
            }}
          >
            {iconListOption.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Icon path={item.value} size={1} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          variant='outlined'
          label='Role Title'
          name='name'
          value={value.name}
          onChange={(e) => onChange({ ...value, name: e.target.value })}
        />
      </Box>
      <Box marginTop='1.25rem'>
        <CampfireRichEditor
          placeholder='Add a description for this role (eg. duties, expectations, etc.)…'
          editorState={value.description}
          setEditorState={(eState) => {
            onChange({ ...value, description: eState });
          }}
        />
      </Box>
    </>
  );
}
