import { gql } from '@apollo/client';

const BASE_FIELD_FRAGMENT = gql`
  fragment ReportTypeBaseField on VOLUNTEER_FieldType {
    __typename
    fieldId
    name
    description
    dateCreated
    dateDeactivated
    isHero
  }
`;

const DROPDOWN_FIELD_FRAGMENT = gql`
  fragment ReportTypeDropdownField on VOLUNTEER_DropdownFieldType {
    dropdownOptions {
      ...ReportTypeDropdownOption
    }
    isAllowMultiple
  }

  fragment ReportTypeDropdownOption on VOLUNTEER_DropdownFieldOptionType {
    dropdownFieldOptionId
    name
    order
  }
`;

const TEXT_FIELD_FRAGMENT = gql`
  fragment ReportTypeTextField on VOLUNTEER_TextFieldType {
    isLongText
  }
`;

export const REPORT_TYPE_FIELD_FRAGMENT = gql`
  fragment ReportManagementReportTypeField on VOLUNTEER_FieldType {
    ...ReportTypeBaseField
    ... on VOLUNTEER_DropdownFieldType {
      ...ReportTypeDropdownField
    }
    ... on VOLUNTEER_TextFieldType {
      ...ReportTypeTextField
    }
  }

  ${BASE_FIELD_FRAGMENT}
  ${DROPDOWN_FIELD_FRAGMENT}
  ${TEXT_FIELD_FRAGMENT}
`;

export const REPORT_MANAGEMENT_GET_REPORT_TYPES = gql`
  query ReportManagementGetReportTypes {
    vm {
      allReportTypes {
        ...ReportManagementReportType
      }
    }
  }

  fragment ReportManagementReportType on VOLUNTEER_ReportTypeType {
    reportTypeId
    name
    items {
      ... on VOLUNTEER_ReportTypeFieldType {
        reportTypeItemId
      }
    }
    activities {
      ...ReportManagementReportTypeActivity
    }
    dateDeactivated
  }

  fragment ReportManagementReportTypeActivity on VOLUNTEER_ActivityType {
    activityId
  }
`;

export const REPORT_MANAGEMENT_GET_SINGLE_REPORT_TYPE = gql`
  query ReportManagementGetSingleReportType($reportTypeId: String!, $withForm: Boolean!) {
    vm {
      reportType(reportTypeId: $reportTypeId) {
        ...ReportManagementSingleReportType
      }
    }
  }

  fragment ReportManagementSingleReportType on VOLUNTEER_ReportTypeType {
    reportTypeId
    name
    description
    items {
      ...ReportManagementReportTypeItem
    }
    dateDeactivated
    activities @skip(if: $withForm) {
      ...ReportManagementReportTypeActivity
    }
  }

  fragment ReportManagementReportTypeItem on VOLUNTEER_ReportTypeItemType {
    ...ReportManagementReportTypeItemField
    ...ReportManagementReportTypeHeading
  }

  fragment ReportManagementReportTypeItemField on VOLUNTEER_ReportTypeFieldType {
    reportTypeItemId
    order
    field {
      ...ReportManagementReportTypeField
    }
    optional
    dateRemoved
  }

  fragment ReportManagementReportTypeHeading on VOLUNTEER_ReportTypeHeadingType {
    reportTypeItemId
    order
    heading
  }

  fragment ReportManagementReportTypeActivity on VOLUNTEER_ActivityType {
    activityId
    isSuspended
    closedActivity {
      closedActivityId
    }
    name
    sessions {
      sessionId
      name
      reportType {
        reportTypeId
        name
      }
    }
  }

  ${REPORT_TYPE_FIELD_FRAGMENT}
`;
