import { useState } from 'react';

const useMouseEnter = (initialValue: boolean, delay?: number) => {
  const [open, setOpen] = useState(initialValue);
  const [delayHandler, setDelayHandler] = useState<null | NodeJS.Timeout>(null);

  const handleMouseEnter = () => {
    setDelayHandler(
      setTimeout(() => {
        setOpen(true);
      }, delay || 500)
    );
  };

  const handleMouseLeave = () => {
    setOpen(false);
    if (delayHandler) {
      clearTimeout(delayHandler);
    }
  };

  return {
    open,
    handleMouseEnter,
    handleMouseLeave,
  };
};

export { useMouseEnter };
