import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { DatePicker as MuiDatePicker, DatePickerProps } from '@material-ui/pickers';

const useStyles = makeStyles(() =>
  createStyles({
    dot: {
      position: 'absolute',
      width: 8,
      height: 8,
      borderRadius: '50%',
      bottom: 3,
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
  })
);

interface Props extends DatePickerProps {
  mapRosterByDate: { [key: string]: string };
}

export function DatePicker({ mapRosterByDate, ...otherProps }: Props) {
  const classes = useStyles();
  return (
    <MuiDatePicker
      {...otherProps}
      renderDay={(day, _unusedSelectedDate, _unusedDayInCurrentMonth, dayComponent) => {
        if (!day) {
          return <></>;
        }
        if (!mapRosterByDate[day.toISODate()]) {
          return dayComponent;
        }
        return (
          <Box position='relative'>
            {dayComponent}
            <Box className={classes.dot} bgcolor={mapRosterByDate[day.toISODate()]} />
          </Box>
        );
      }}
    />
  );
}
