import { gql } from '@apollo/client';

export const GET_VOLUNTEERS_APPLICATIONS = gql`
  query GetVolunteersApplications {
    vm {
      activityApplications {
        activityApplicationId
        dateApplied
        dateWithdrawn
        dateActioned
        activity {
          activityId
          name
          isActive
          priority
          program {
            name
            programId
            dateSuspended
            dateDeleted
          }
          maxTeam
          totalEnrolments
        }
        volunteer {
          volunteerId
          profile {
            profileId
            userId
            firstName
            lastName
            avatarUrl
            preferredName
            email
            contactNumber
          }
          dateDeactivated
          flagging {
            dateFlagged
          }
        }
      }
    }
  }
`;
