import { isDateInPast, unpackToDateTime } from '@campfire/hot-date';
import { Box } from '@material-ui/core';
import { AccessTime, Person, Room, Schedule, Sync } from '@material-ui/icons';
import React, { memo, ReactNode } from 'react';
import { IconsTextRowLayout } from '../../../../common/icons/IconsTextRowLayout';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ActivitiesExploreActivityResource } from '../__generated__/ActivitiesExploreActivityResource';

type IconsTextRowLayoutProps = {
  rightIcon: ReactNode;
  text?: string;
  leftIcon?: ReactNode;
};

type ActivityBodyProps = {
  activityDuration?: string;
  activityTime?: string;
  activityLocationAddress?: ReactNode;
  activityIsEnrollable?: boolean;
  activityOccurence?: string;
  activityResources?: Array<ActivitiesExploreActivityResource>;
  activityEndDate?: string;
  activityStartDate?: string;
  isActivitySingularlyRecurring?: any;
};

const ActivityBody = memo(
  ({
    activityTime,
    activityEndDate,
    activityStartDate,
    activityLocationAddress,
    activityIsEnrollable,
    activityOccurence,
    isActivitySingularlyRecurring,
  }: ActivityBodyProps) => {
    const { isMobile } = useCampfireTheme();

    const hasStartDatePassed = isDateInPast(unpackToDateTime(String(activityStartDate)));
    const startEndDateFormatted = hasStartDatePassed
      ? new Date(String(activityEndDate)).toLocaleDateString('default', { day: 'numeric', month: 'long' })
      : new Date(String(activityStartDate)).toLocaleDateString('default', { day: 'numeric', month: 'long' });

    const activityFrequency =
      isActivitySingularlyRecurring === true
        ? activityOccurence
        : activityOccurence +
          (hasStartDatePassed
            ? activityEndDate
              ? `, ending on ${startEndDateFormatted}`
              : ''
            : activityStartDate
            ? `, starting on ${startEndDateFormatted}`
            : '');
    return (
      <Box width={isMobile ? 1 : 0.8}>
        <Box>
          {typeof activityLocationAddress === 'string' ? (
            <IconsTextRowLayout leftIcon={<Room color='action' />} text={activityLocationAddress as string} />
          ) : (
            activityLocationAddress
          )}
          {activityOccurence && <IconsTextRowLayout leftIcon={<Sync color='action' />} text={activityFrequency} />}
          {activityTime && <IconsTextRowLayout leftIcon={<Schedule color='action' />} text={activityTime} />}
          {activityIsEnrollable && (
            <IconsTextRowLayout
              leftIcon={activityIsEnrollable ? <Person color='action' /> : <AccessTime color='action' />}
              text={activityIsEnrollable ? 'Has vacancy' : 'Apply for Waitlist'}
            />
          )}
        </Box>
      </Box>
    );
  }
);

export { ActivityBody };
