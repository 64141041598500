import { gql } from '@apollo/client';

export const GET_ONBOARDING_STEP = gql`
  query GetOnboardingStep($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        currentOnboardingStep {
          volunteerOnboardingStepType {
            order
          }
        }
      }
    }
  }
`;
