import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Typography, Theme, Box } from '@material-ui/core';
import { RolePanel } from '../RolePanel';
import { RoleItem, ExtendRole } from './RoleItem';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { SearchField } from '../../../../../../common/inputs/SearchField';

interface Props {
  roles: ExtendRole[];
  isLoading: boolean;
  onEdit: (role: ExtendRole) => void;
  onDelete: (role: ExtendRole) => void;
  onShowVolunteers: (roleId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 900,
      fontSize: '1.25rem',
      color: theme.color.grey.neutralBrand900,
    },
    body: {
      fontWeight: 400,
      color: theme.color.grey.neutral200,
      fontSize: '0.875rem',
      marginTop: '6px',
    },
  })
);

export function RolesList({ roles, onEdit, onDelete, onShowVolunteers, isLoading }: Props) {
  const classes = useStyles();
  const { isSm } = useCampfireTheme();
  const [searchInput, setSearchInput] = React.useState<string>();

  const filteredRoles = React.useMemo(
    () => roles.filter((role) => (searchInput ? role.name.toLowerCase().includes(searchInput.toLowerCase()) : true)),
    [roles, searchInput]
  );

  return (
    <>
      <RolePanel>
        <Box
          display='flex'
          flexDirection={isSm ? 'column' : 'row'}
          alignItems={isSm ? 'left' : 'center'}
          justifyContent='space-between'
          pr='1.25rem'
          pt={isSm ? '1rem' : 0}
        >
          <Box>
            <Typography className={classes.heading} variant='h4'>
              Roles
            </Typography>
            <Typography className={classes.body}>
              You have {roles.length} roles for your volunteers. If you need to create more roles click the ‘
              <strong>Add New Role</strong>’ button top right.
            </Typography>
          </Box>
          <Box style={{ marginLeft: isSm ? 0 : '0.75rem', paddingTop: isSm ? '1rem' : 0 }}>
            <SearchField
              placeholder='Search Tags'
              onChange={(e: any) => setSearchInput(e.target.value)}
              fullWidth={isSm}
            />
          </Box>
        </Box>
        {filteredRoles.map((role: ExtendRole) => (
          <RoleItem
            role={role}
            onEdit={onEdit}
            onDelete={onDelete}
            onShowVolunteers={onShowVolunteers}
            isLoading={isLoading}
          />
        ))}
      </RolePanel>
    </>
  );
}
