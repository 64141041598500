import { useEffect, useState } from 'react';
import { kebabCase } from 'lodash';
import { useApiUrl } from '../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../global/network/useCampfireFetch';

export type IIntegrationConfiguration = {
  addVolunteers: boolean;
  removeVolunteers: boolean;
  programSegments: boolean;
  activityTags: boolean;
};

export const useGetIntegrationConfiguration = (id: string) => {
  const [configuration, setConfiguration] = useState<IIntegrationConfiguration>();
  const apiUrl = useApiUrl();
  const configurationGet = useCampfireFetch<IIntegrationConfiguration>({ defer: true });
  useEffect(() => {
    configurationGet
      .run({
        url: `${apiUrl}/wm/integrations/${kebabCase(id)}/configuration/get`,
        method: 'get',
      })
      .then((response) => setConfiguration(response.data.data));
  }, []);

  return { configuration };
};

export const useSaveIntegrationConfiguration = (id: string) => {
  const apiUrl = useApiUrl();
  const configurationGet = useCampfireFetch<IIntegrationConfiguration>({ defer: true });
  return (data: IIntegrationConfiguration) => {
    configurationGet.run({
      url: `${apiUrl}/wm/integrations/${kebabCase(id)}/configuration/save`,
      method: 'post',
      data,
    });
  };
};
