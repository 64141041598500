import React, { memo, useMemo } from 'react';
import { TabletButton } from '@campfire/tablet-button/lib';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  ListItemIcon,
  MenuItem,
  SvgIcon,
} from '@material-ui/core';
import { YouTube } from '@material-ui/icons';
import { FieldArrayRenderProps, Form, Formik, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { object as YupObject, string as YupString } from 'yup';
import { Field } from '@campfire/field/lib';
import ReactPlayer from 'react-player/lazy';
import { ReactComponent as Vimeo } from '../../../../../../../assets/icons/vimeo.svg';

import { FormikTaskBuilderValues } from '../../admin-console-volunteer-profile-model';
import { TaskFormItem_VOLUNTEER_TaskItemMediaType as TaskItemMedia } from '../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';
import { getVideoId, getMediaType, getVideoUrl } from './helpers';

const validationSchema = YupObject().shape({
  media: YupObject().shape({
    name: YupString().required('Please provide a media name'),
    videoId: YupString().required('Please provide a media url'),
  }),
});

type MediaValues = Omit<TaskItemMedia, 'triggerItem'>;
const mediaTypes = [
  {
    text: 'Youtube',
    __typename: 'VOLUNTEER_YoutubeMediaType',
    icon: YouTube,
  },
  {
    text: 'Vimeo',
    __typename: 'VOLUNTEER_VimeoMediaType',
    icon: Vimeo,
  },
];

interface Props {
  open: boolean;
  close: () => void;
  arrayHelpers: FieldArrayRenderProps;
  data: TaskItemMedia | null;
}

export const MediaTaskItemDialog = memo((props: Props) => {
  const { open, close, data } = props;
  const formikContext = useFormikContext<FormikTaskBuilderValues>();

  const {
    values: { taskItems },
  } = formikContext;

  const title = useMemo(() => (data ? 'Edit Media' : 'Add Media'), [data]);

  const initialValues: MediaValues = useMemo(
    () => ({
      taskItemId: data?.taskItemId ?? `new-${uuidv4()}`,
      __typename: 'VOLUNTEER_TaskItemMediaType',
      order: data?.order ?? formikContext.values.taskItems.allIds.length,
      media: {
        name: data?.media.name || '',
        description: data?.media.description || '',
        __typename: data?.media.__typename || 'VOLUNTEER_YoutubeMediaType',
        videoId: getVideoUrl(data?.media.__typename ?? '')(data?.media.videoId ?? ''),
      },
    }),
    [data, open]
  );

  const handleSubmit = (vals: MediaValues) => {
    return data ? editMedia(vals) : addMedia(vals);
  };

  const addMedia = (vals: MediaValues) => {
    try {
      formikContext.setFieldValue('taskItems', {
        ...taskItems,
        allIds: taskItems.allIds.concat(vals.taskItemId),
        byId: {
          ...taskItems.byId,
          [vals.taskItemId]: {
            ...vals,
            media: {
              ...vals.media,
              videoId: getVideoId(vals.media.__typename)(vals.media.videoId),
            },
          },
        },
      });
    } catch (error) {
      console.log(error);
    }

    close();
  };

  const editMedia = (vals: MediaValues) => {
    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      byId: {
        ...taskItems.byId,
        [vals.taskItemId]: {
          ...vals,
          media: {
            ...vals.media,
            videoId: getVideoId(vals.media.__typename)(vals.media.videoId),
          },
        },
      },
    });
    close();
  };

  const removeMedia = () => {
    const optionId = taskItems.byTriggerOption[initialValues.taskItemId];
    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      allIds: taskItems.allIds.filter((itemId) => itemId !== initialValues.taskItemId),
      byTrigger: optionId
        ? taskItems.byTrigger[optionId].triggerItemIds.filter((itemId) => itemId !== initialValues.taskItemId)
        : taskItems.byTrigger,
    });
    close();
  };

  return (
    <Dialog open={open}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ submitForm, values, setFieldValue }) => {
          return (
            <Form>
              <DialogTitle>
                <Typography variant='h6'>{title}</Typography>
                <Typography>{'Use media for adding task information'}</Typography>
              </DialogTitle>
              <DialogContent>
                <Box display='flex'>
                  <Box>
                    <Field
                      slow
                      name='media.videoId'
                      label='Url'
                      required
                      fullWidth
                      variant='outlined'
                      helper
                      autoFocus
                      onChange={(event) => {
                        const videoUrl = event.target.value;
                        if (videoUrl) {
                          setFieldValue('media.__typename', getMediaType(videoUrl));
                        }
                      }}
                    />
                  </Box>
                  <Box ml={1}>
                    <TextField
                      select
                      name='media.__typename'
                      fullWidth
                      variant='outlined'
                      label='Media type'
                      onChange={(event) => setFieldValue('media.__typename', event.target.value)}
                      SelectProps={{
                        value: values.media.__typename,
                        renderValue: () => {
                          const media = mediaTypes.find(
                            (mediaType) => mediaType.__typename === values.media.__typename
                          );
                          if (media) {
                            const Icon = media?.icon;
                            return (
                              <Box display='flex' alignItems='center' height='19px'>
                                <SvgIcon viewBox='0 0 16 16' fontSize='small'>
                                  <Icon />
                                </SvgIcon>
                                <Typography style={{ marginLeft: 4 }}>{media.text}</Typography>
                              </Box>
                            );
                          }
                          return null;
                        },
                      }}
                    >
                      {mediaTypes.map(({ text, __typename, icon: Icon }) => (
                        <MenuItem key={text} value={__typename} style={{ display: 'flex' }}>
                          <ListItemIcon>
                            <SvgIcon viewBox='0 0 16 16' fontSize='small'>
                              <Icon />
                            </SvgIcon>
                          </ListItemIcon>
                          <Typography>{text}</Typography>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </Box>
                <Box mt={2}>
                  <Field
                    slow
                    name='media.name'
                    label='Media Name'
                    required
                    fullWidth
                    variant='outlined'
                    helper
                    autoFocus
                  />
                </Box>
                <Box mt={2}>
                  <Field
                    slow
                    name='media.description'
                    label='Media description'
                    fullWidth
                    variant='outlined'
                    helper
                    autoFocus
                  />
                </Box>
                {values.media.videoId && (
                  <Box mt={2}>
                    <ReactPlayer url={values.media.videoId} width='100%' maxWidth='100%' controls height='200px' />
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Grid container justify='flex-end'>
                  {data ? (
                    <Grid item xs>
                      <TabletButton variant='outlined' color='error' onClick={removeMedia}>
                        {'Delete'}
                      </TabletButton>
                    </Grid>
                  ) : null}
                  <Grid item>
                    <TabletButton onClick={close}>{'Cancel'}</TabletButton>
                  </Grid>
                  <Grid item>
                    <TabletButton type='button' color='primary' variant='contained' onClick={submitForm}>
                      {'Done'}
                    </TabletButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
});
