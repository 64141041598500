import { ActivitySessionFragments } from './ActivitySessionFragments.gql';
import { ActivityTeamInformationFragments } from './ActivityTeamInformationFragments.gql';
import { ActivityUpcomingRostersFragments } from './ActivityUpcomingRostersFragments.gql';
import { ActivityLocationInformationFragments } from './ActivityLocationInfomationFragments.gql';
import { ActivityAttachmentsFragments } from './ActivityAttachmentsFragments.gql';
import { ActivityDescriptionFragments } from './ActivityDescriptionFragments.gql';
import { PublishedRosterFragment } from './PublishedRosterFramgment.gql';
import { CancelledActivityFragments } from './CancelledActivityFragments.gql';
import { ActivityRosterTemplate } from './ActivityRosterTemplate.gql';
import { DraftRosterFragment } from './DraftRosterFramgment.gql';

export const AcitivityDetailsFragments = {
  session: ActivitySessionFragments,
  team: ActivityTeamInformationFragments,
  upcomingRoster: ActivityUpcomingRostersFragments,
  location: ActivityLocationInformationFragments,
  attachment: ActivityAttachmentsFragments,
  description: ActivityDescriptionFragments,
  publishedRoster: PublishedRosterFragment,
  cancelledActivities: CancelledActivityFragments,
  rosterTemplate: ActivityRosterTemplate,
  draftRoster: DraftRosterFragment,
};
