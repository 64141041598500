import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { memo, ReactElement } from 'react';
import {
  ArrowDropDownCircle,
  AttachFile,
  CalendarToday,
  Call,
  CheckBox,
  Email,
  LocationOn,
  Notes,
  RadioButtonChecked,
  ShortText,
  AssignmentTurnedIn,
  Create,
} from '@material-ui/icons';
import { TaskFieldTypeEnum } from '../../../admin-console-volunteer-profile-model';
import { ReactComponent as HashtagIcon } from '../../../../../../../../assets/hash-24px.svg';

interface Props extends SvgIconProps {
  fieldType: TaskFieldTypeEnum;
}

export const FieldIcon = memo((props: Props) => {
  const { fieldType, ...rest } = props;

  const mappedFieldIcon: { [key: string]: ReactElement } = {
    VOLUNTEER_ShortTextTaskFieldType: <ShortText {...rest} />,
    VOLUNTEER_LongTextTaskFieldType: <Notes {...rest} />,
    VOLUNTEER_NumberTaskFieldType: (
      <SvgIcon {...rest}>
        <HashtagIcon />
      </SvgIcon>
    ),
    VOLUNTEER_MultipleChoiceTaskFieldType: <RadioButtonChecked {...rest} />,
    VOLUNTEER_CheckboxTaskFieldType: <CheckBox {...rest} />,
    VOLUNTEER_DropdownTaskFieldType: <ArrowDropDownCircle {...rest} />,
    VOLUNTEER_AttachmentTaskFieldType: <AttachFile {...rest} />,
    VOLUNTEER_DateTaskFieldType: <CalendarToday {...rest} />,
    VOLUNTEER_AddressTaskFieldType: <LocationOn {...rest} />,
    VOLUNTEER_PhoneTaskFieldType: <Call {...rest} />,
    VOLUNTEER_EmailTaskFieldType: <Email {...rest} />,
    VOLUNTEER_QuizTaskFieldType: <AssignmentTurnedIn {...rest} />,
    VOLUNTEER_SignatureTaskFieldType: <Create {...rest} />,
  };
  return mappedFieldIcon[fieldType];
});
