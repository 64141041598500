import { PaneWrapper } from '@campfire/pane';
import { Box } from '@material-ui/core';
import React, { ReactNode, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { MyTasksTabContent } from './main-section/my-tasks/MyTasksTabContent';
import { MyElementsNewsTabContent } from './main-section/my-recent-news/MyElementsNewsTabContent';
import { MyElementsMyActivitiesMainSection } from './main-section/my-activities/MyElementsMyActivitiesMainSection';
import { MyElementsTabsHeader } from './main-section/MyElementsTabsHeader';
import { MyElementsSideBar } from './sidebar/MyElementsSideBar';
import { VolDashboardMyElementsProvider } from './VolDashboardMyElementsContext';
import { MyElementsBottomBar } from './sidebar/MyElementsBottomBar';
import { MyElementsSmallScreenAlerts } from './sidebar/MyElementsSmallScreenAlerts';
import { Page } from '../../../../global/components';
import { PageHelpOptions } from '../../../../global/page-help/PageHelpContext';
import { VolDashboardMyElementsTutorialDialog } from './VolDashboardMyElementsTutorialDialog';
import { ActivitiesSidebar } from '../../activities-v2/ActivitiesSidebar';
import { ActivityContext } from '../../activities-v2/ActivityContext';
import { MyElementsTabsHeaderMobileMenu } from './main-section/MyElementsTabsHeaderMobileMenu';

export interface ScreenHeaderProps {
  title: string;
  children?: ReactNode;
}
const MyElementsTabletScreen = () => {
  const [selectedTab] = useQueryParam('tab', StringParam);
  const [activityId] = useQueryParam('activityId', StringParam);
  const [activityDate] = useQueryParam('activityDate', StringParam);

  return (
    <Box>
      {activityId && activityDate ? (
        <Box padding='32px 48px'>
          <ActivitiesSidebar inMyShift />
        </Box>
      ) : (
        <Box width='100%' position='relative' display='flex' flexDirection='column' height='100%'>
          <Box padding='24px'>
            <MyElementsSmallScreenAlerts />
            <MyElementsTabsHeader />
            {selectedTab === 'news' ? (
              <MyElementsNewsTabContent />
            ) : selectedTab === 'tasks' ? (
              <MyTasksTabContent />
            ) : (
              <MyElementsMyActivitiesMainSection />
            )}
          </Box>
          <MyElementsBottomBar />
        </Box>
      )}
    </Box>
  );
};

const MyElementsMobileScreen = () => {
  const [selectedTab] = useQueryParam('tab', StringParam);
  const [activityId] = useQueryParam('activityId', StringParam);
  const [activityDate] = useQueryParam('activityDate', StringParam);

  return (
    <Box>
      {activityId && activityDate ? (
        <Box padding='16px 32px'>
          <ActivitiesSidebar inMyShift />
        </Box>
      ) : (
        <Box width='100%' position='relative' display='flex' flexDirection='column' height='100%'>
          <Box padding='24px'>
            <MyElementsTabsHeaderMobileMenu />
            <MyElementsSmallScreenAlerts />
            {selectedTab === 'tasks' ? <MyTasksTabContent /> : <MyElementsMyActivitiesMainSection />}
          </Box>
          <MyElementsBottomBar />
        </Box>
      )}
    </Box>
  );
};

const MyElementsDesktopsScreen = () => {
  const [selectedTab] = useQueryParam('tab', StringParam);
  const [activityId] = useQueryParam('activityId', StringParam);

  return (
    <Box width='100%' position='relative' display='flex' flexDirection='row' height='100%' overflow='hidden'>
      <Box flexGrow={1} style={{ overflowY: 'auto', overflowX: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Box padding='24px 24px 0'>
          <MyElementsTabsHeader />
        </Box>
        <Box flexGrow={1}>
          {selectedTab === 'news' ? (
            <Box padding='0 38px'>
              <MyElementsNewsTabContent />
            </Box>
          ) : selectedTab === 'tasks' ? (
            <MyTasksTabContent />
          ) : (
            <MyElementsMyActivitiesMainSection />
          )}
        </Box>
      </Box>

      <CollapsibleSideBar>
        {activityId && <ActivitiesSidebar inMyShift />}
        <MyElementsSideBar style={{ display: activityId ? 'none' : undefined }} />
      </CollapsibleSideBar>
    </Box>
  );
};

export const VolDashboardMyElementsScreen = () => {
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState(false);
  const { isMd, isMobile } = useCampfireTheme();

  const pageHelpOptions = useMemo<PageHelpOptions>(() => {
    return {
      onClick: () => {
        setTutorialDialogOpen(true);
      },
    };
  }, []);

  return (
    <>
      <VolDashboardMyElementsTutorialDialog open={tutorialDialogOpen} onClose={() => setTutorialDialogOpen(false)} />
      <Page pageHelpOptions={pageHelpOptions}>
        <PaneWrapper>
          <VolDashboardMyElementsProvider>
            <ActivityContext>
              {isMobile ? <MyElementsMobileScreen /> : isMd ? <MyElementsTabletScreen /> : <MyElementsDesktopsScreen />}
            </ActivityContext>
          </VolDashboardMyElementsProvider>
        </PaneWrapper>
      </Page>
    </>
  );
};
