import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import React from 'react';
import { Box, IconButton, Theme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { ActivityHeader } from '../ActivitySidebar/ActivityHeader';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ProgramType } from './AllProgramTypes';
import { convertToEditorState } from './AllProgramsHelpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      border: '1px solid',
      borderColor: theme.color.grey.neutralBrand200,
      borderRadius: '6px',
      backgroundColor: 'white',
      padding: '6px',
    },
    headerWrapper: {
      marginTop: '0.75rem',
    },
    description: {
      color: theme.color.grey.neutral400,
      fontWeight: 400,
      marginTop: '0.75rem',
      fontSize: '0.875rem',
    },
  })
);

export const ProgramSidebar = ({ program, onClose }: { program: ProgramType; onClose?: () => void }) => {
  const { isMobile } = useCampfireTheme();
  const classes = useStyles();

  return (
    <Box position='relative'>
      <Box paddingY={1}>
        {program.image && <img src={program.image} alt='resource' width='100%' />}
        <Box className={classes.headerWrapper}>
          <ActivityHeader
            title={program.name}
            action={
              isMobile ? (
                <IconButton onClick={onClose} className={classes.closeButton}>
                  <Close />
                </IconButton>
              ) : null
            }
          />
        </Box>
        {program.description && (
          <Box className={classes.description}>
            <CampfireRichEditor editorState={convertToEditorState(program.description)} readOnly />
          </Box>
        )}
      </Box>
    </Box>
  );
};
