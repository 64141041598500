import { gql } from '@apollo/client';

export const GET_PROFILE_PANEL = gql`
  query GetProfilePanel($profileId: String!) {
    vm {
      profile(profileId: $profileId) {
        profileId
        firstName
        lastName
        preferredName
        dateOfBirth
        email
        contactNumber
        aboutMe
        dateLastUpdated
      }
    }
  }
`;
