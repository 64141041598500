import { FugFile } from '@campfire/file-upload-gallery/lib';
import { Recurrence } from '../../../../common/recurrence/__generated__/Recurrence';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';

export const useSuspendActivityFetch = () => {
  return useEndpointFetch<{ activityId: string }>('/vm/activity/suspend');
};

export const useUnsuspendActivityFetch = () => {
  return useEndpointFetch<{ activityId: string }>('/vm/activity/unsuspend');
};

export const useCloseActivityFetch = () => {
  return useEndpointFetch<{ activityId: string; dateEnded: string; activityClosureReasonId: string }>(
    '/vm/activity/close'
  );
};

export const useUncloseActivityFetch = () => {
  return useEndpointFetch<{ closedActivityId: string }>('/vm/activity/unclose');
};

export type CancelActivityParams = {
  activityCancellationReasonId: string;
  description: string;
  addedAttachments?: FugFile[];
  attachmentCreationTokens?: string[];
};
export const useCancelActivityFetch = () => {
  return useEndpointFetch<
    {
      activityId: string;
      activityDate: string;
      activityCancellationReasonId: string;
      description: string;
      addedAttachments?: FugFile[];
      attachmentCreationTokens?: string[];
    },
    { cancelledActivityId: string }
  >('/vm/activity/cancel', { formData: true });
};
export const useUncancelActivityFetch = () => {
  return useEndpointFetch<{ cancelledActivityId: string }>('/vm/activity/uncancel');
};

export const useSaveActivityTeamFetch = () => {
  return useEndpointFetch<{ activityId: string; teamMembers: { volunteerId: string; position: string }[] }>(
    '/vm/activity/team/save'
  );
};

export const useApproveIncomingVolunteerFetch = () => {
  return useEndpointFetch<{ activityApplicationId: string }, { activityEnrolmentId: string }>(
    '/vm/activity/incoming/approve'
  );
};

export const useWithdrawApplication = () => {
  return useEndpointFetch<{ activityApplicationId: string; activityId: string }, { activityApplicationId: string }>(
    '/vm/activity/incoming/reject'
  );
};

export const useApproveBulkIncomingVolunteersFetch = () => {
  return useEndpointFetch<{ activityApplicationIds: string[] }, { activityEnrolmentIds: string[] }>(
    '/vm/activity/incoming/approve-bulk'
  );
};

export const useWaitlistIncomingVolunteerFetch = () => {
  return useEndpointFetch<{ activityApplicationId: string }, { activityWaitlistingId: string }>(
    '/vm/activity/incoming/waitlist'
  );
};

export const useWaitlistBulkIncomingVolunteersFetch = () => {
  return useEndpointFetch<{ activityApplicationIds: string[] }, { activityWaitlistingIds: string[] }>(
    '/vm/activity/incoming/waitlist-bulk'
  );
};

export const useRejectIncomingVolunteerFetch = () => {
  return useEndpointFetch<{ activityApplicationId: string }>('/vm/activity/incoming/reject');
};

export const useEnrolWaitlistingFetch = () => {
  return useEndpointFetch<{ activityWaitlistingId: string }, { activityEnrolmentId: string }>(
    '/vm/activity/waitlist/enrol'
  );
};

export const useAddWaitlistingFetch = () => {
  return useEndpointFetch<{ activityId: string }, { activityWaitlistingId: string }>('/vm/activity/waitlist/add');
};

export const useRemoveWaitlistingFetch = () => {
  return useEndpointFetch<{ activityWaitlistingId: string }>('/vm/activity/waitlist/remove');
};

interface PlacesAddress {
  description: string;
  formatted: string;
  placesId?: string;
  latitude: number;
  longitude: number;
}

export interface SaveActivityFetchActivityLocationVariables {
  activityLocationId?: string;
  placesAddress: PlacesAddress;
  timeZone: string;
  comments: string;
}

export interface SaveActivityFetchSessionVariables {
  sessionId?: string;
  name: string;
  description: string;
  minVolunteers?: number | null;
  maxVolunteers?: number | null;
  activityLocation?: SaveActivityFetchActivityLocationVariables | null;
  startTime: string | null;
  endTime: string | null;
  reportTypeId: string;
}

export interface SaveActivityFetchVariables {
  activityId?: string;
  isRestrictedActivity: boolean;
  isHidden: boolean;
  maxTeam: number | null;
  hasOpenRoster: boolean;
  programId: string;
  name: string;
  description: string;
  startDate: string;
  endDate?: string;
  enrollable?: boolean;
  activityLocation: SaveActivityFetchActivityLocationVariables | null;
  remoteLocation?: {
    details?: string;
  };
  recurrences?: Omit<Recurrence, 'humanReadable'>[];
  sessions: SaveActivityFetchSessionVariables[];
  addedAttachments?: FugFile[];
  attachmentCreationTokens?: string[];
  removedAttachmentIds?: string[];
}

export const useSaveActivityFetch = () => {
  return useEndpointFetch<SaveActivityFetchVariables, { activityId: string }>('/vm/activity/save', { formData: true });
};

export const useSaveUnavailabilityFetch = () => {
  return useEndpointFetch<{ activityEnrolmentId: string; activityDate: string }>(
    '/vm/activity/enrolment/unavailability/save'
  );
};

export const useWithdrawUnavailabilityFetch = () => {
  return useEndpointFetch<{ activityUnavailabilityId: string }>('/vm/activity/enrolment/unavailability/withdraw');
};

export const useSaveDraftRosterFetch = () => {
  return useEndpointFetch<
    {
      activityId: string;
      activityDate: string;
      draftRosterings: {
        volunteerId: string;
        sessionIds: string[];
      }[];
      immediatePublish?: boolean;
      rosterTemplateId?: string;
      rosterNotes?: string | null;
    },
    { draftRosterId: string }
  >('/vm/activity/roster/save-draft');
};

export const useCancelSessionReportFetch = () => {
  return useEndpointFetch<{ sessionId: string; activityDate: string }, { activityReportId: string }>(
    '/vm/session/report/cancel'
  );
};

export const useUnOmitSessionReportFetch = () => {
  return useEndpointFetch<{ sessionId: string; activityDate: string }, { activityReportId: string }>(
    '/vm/session/report/unomit'
  );
};

interface BaseFieldValue {
  fieldId: string;
}

interface BooleanFieldValue extends BaseFieldValue {
  __typename: 'VOLUNTEER_BooleanFieldValue';
  boolValue: boolean;
}

interface CheckboxFieldValue extends BaseFieldValue {
  __typename: 'VOLUNTEER_CheckboxFieldValue';
  checked: boolean;
}

interface DropdownFieldValue extends BaseFieldValue {
  __typename: 'VOLUNTEER_DropdownFieldValue';
  dropdownFieldOptionIds: string[];
}

interface NumericFieldValue extends BaseFieldValue {
  __typename: 'VOLUNTEER_NumericFieldValue';
  numericValue: number;
}

interface RatingFieldValue extends BaseFieldValue {
  __typename: 'VOLUNTEER_RatingFieldValue';
  ratingValue: number;
}

interface TextFieldValue extends BaseFieldValue {
  __typename: 'VOLUNTEER_TextFieldValue';
  textValue: string;
}

interface TimeFieldValue extends BaseFieldValue {
  __typename: 'VOLUNTEER_TimeFieldValue';
  timeValue: string;
}

interface AttachmentFieldValue extends BaseFieldValue {
  __typename: 'VOLUNTEER_AttachmentFieldValue';
  addedAttachments: string[];
  attachmentCreationTokens: string[];
  attachmentNames: string[];
  removedAttachmentIds: string[];
}

export type SaveSessionEndpointFieldValueType =
  | BooleanFieldValue
  | CheckboxFieldValue
  | DropdownFieldValue
  | NumericFieldValue
  | RatingFieldValue
  | TextFieldValue
  | TimeFieldValue
  | AttachmentFieldValue;

export interface SaveSessionEndpointDataType {
  sessionId: string;
  activityDate: string;
  startTime: string;
  endTime: string;
  volunteerAttendances: Array<string>;
  otherAttendances: Array<string>;
  CICOs: {
    volunteerId: string;
    checkIn: string;
    checkOut: string | null;
  }[];
  comments: string;
  fieldValues: SaveSessionEndpointFieldValueType[];
}

export const useSaveSessionReportFetch = () => {
  return useEndpointFetch<SaveSessionEndpointDataType, { activityReportId: string; sessionReportId: string }>(
    '/vm/session/report/save'
  );
};

interface SaveReportTypeBooleanField {
  __typename: 'VOLUNTEER_BooleanFieldType';
  fieldId?: string;
  name: string;
  description: string;
}

interface SaveReportTypeCheckboxField {
  __typename: 'VOLUNTEER_CheckboxFieldType';
  fieldId?: string;
  name: string;
  description: string;
}

interface SaveReportTypeDropdownField {
  __typename: 'VOLUNTEER_DropdownFieldType';
  fieldId?: string;
  name: string;
  description: string;
  dropdownFieldOptions: {
    dropdownFieldOptionId?: string;
    name: string;
    order: number;
  }[];
  allowMultiple: boolean;
}

interface SaveReportTypeNumericField {
  __typename: 'VOLUNTEER_NumericFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportTypeFormField {
  __typename: 'VOLUNTEER_RatingFieldType';
  fieldId?: string;
  name: string;
  description: string;
}

interface SaveReportTypeTextField {
  __typename: 'VOLUNTEER_TextFieldType';
  fieldId?: string;
  name: string;
  description: string;
}

interface SaveReportTypeTimeField {
  __typename: 'VOLUNTEER_TimeFieldType';
  fieldId?: string;
  name: string;
  description: string;
}

interface SaveReportTypeAttachmenteField {
  __typename: 'VOLUNTEER_AttachmentFieldType';
  fieldId?: string;
  name: string;
  description: string;
}

type SaveReportTypeField =
  | SaveReportTypeBooleanField
  | SaveReportTypeCheckboxField
  | SaveReportTypeDropdownField
  | SaveReportTypeNumericField
  | SaveReportTypeFormField
  | SaveReportTypeTextField
  | SaveReportTypeTimeField
  | SaveReportTypeAttachmenteField;

interface SaveReportTypeItemHeading {
  __typename: 'VOLUNTEER_ReportTypeHeadingType';
  reportTypeItemId?: string;
  order: number;
  heading: string;
}

export interface SaveReportTypeItemField {
  __typename: 'VOLUNTEER_ReportTypeFieldType';
  reportTypeItemId?: string;
  order: number;
  field: SaveReportTypeField;
  optional: boolean;
}

export type SaveReportTypeItem = SaveReportTypeItemHeading | SaveReportTypeItemField;

export interface SaveReportType {
  reportTypeId?: string;
  name: string;
  description: string | null;
  items: SaveReportTypeItem[];
  active: boolean;
}

export const useSaveReportTypeFetch = () => {
  return useEndpointFetch<SaveReportType, { reportTypeId: string }>('/vm/report-type/save');
};
