import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { UpcomingRosters } from '../../my-activities/UpcomingRosters';
import { MyElementsPersonalCard } from './personal-card/MyElementsPersonalCard';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const useStyles = makeStyles((theme) => ({
  myElementsWrapper: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  rosterWrapper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '800px',
      minHeight: 'calc(100vh - 60px)',
      maxWidth: '50%',
    },
  },
}));

export const MyElements = () => {
  const { theme: campfireTheme } = useCampfireTheme();
  const {
    palette: { grey },
  } = campfireTheme;
  const styles = useStyles();

  return (
    <Box display='flex' className={styles.myElementsWrapper}>
      <Box flex='1' style={{ borderRight: `1px solid ${grey[300]}` }}>
        <MyElementsPersonalCard />
      </Box>
      <Box className={styles.rosterWrapper}>
        <UpcomingRosters />
      </Box>
    </Box>
  );
};
