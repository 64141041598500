import React, { useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { Field } from '@campfire/field';
import { HoverLink } from '@campfire/hover-link/lib';
import { useRouteMatch } from 'react-router';
import { TabletButton } from '@campfire/tablet-button';
import { object as YupObject, string as YupString } from 'yup';
import { CategoryEditorResourcesList } from './CategoryEditorResourcesList';
import { DeleteCategoryDialog } from './DeleteCategoryDialog';
import { useSupportContext } from '../SupportContext';
import { SupportCategory } from '../__generated__/SupportCategory';
import { SupportResource } from '../__generated__/SupportResource';

const validationSchema = YupObject().shape({
  categoryTitle: YupString().required('Please provide a name'),
});

interface Props {
  selectedCategory: SupportCategory;
}

export interface CategoryEditorInitialValues {
  categoryTitle: string;
  categoryResources: SupportResource[];
}

const CategoryEditor = (props: Props) => {
  const { selectedCategory } = props;
  const { setInEditCategoryMode, runSaveCategory } = useSupportContext();
  const match = useRouteMatch();

  const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] = useState(false);

  const initialValues: CategoryEditorInitialValues = useMemo(
    () => ({ categoryTitle: selectedCategory.title, categoryResources: selectedCategory.supportResources }),
    [selectedCategory]
  );

  const handleSubmit = (vals: CategoryEditorInitialValues) => {
    runSaveCategory({
      supportCategoryId: selectedCategory.supportCategoryId,
      title: vals.categoryTitle,
      supportResources: vals.categoryResources.map((resource) => ({
        supportResourceId: resource.supportResourceId,
        featured: resource.featured,
      })),
    }).then((cid) => {
      if (cid) setInEditCategoryMode(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(vals) => handleSubmit(vals)}
    >
      <Form>
        <Grid container justify='center' spacing={2} style={{ padding: 16 }}>
          <Grid item container xs={12} justify='flex-end' spacing={1}>
            <Grid item>
              <TabletButton
                data-track='resources-category-editor-cancel-button'
                onClick={() => setInEditCategoryMode(false)}
              >
                {'Cancel'}
              </TabletButton>
            </Grid>
            <Grid item>
              <TabletButton
                data-track='resources-category-editor-save-button'
                variant='contained'
                type='submit'
                color='primary'
              >
                {'Save Category'}
              </TabletButton>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Field
              data-track='resources-category-editor-category-title-input'
              name='categoryTitle'
              label='Category Name'
              variant='outlined'
              fullWidth
            />
          </Grid>

          <Grid item container justify='space-between' xs={12} md={8}>
            <Grid item>
              <Typography
                variant='body2'
                color='textSecondary'
              >{`${selectedCategory.supportResources.length} resources in this Category`}</Typography>
            </Grid>
            <Grid item>
              <HoverLink
                data-track='resources-category-editor-add-new-resource-button'
                to={{
                  pathname: match?.url.replace(`${selectedCategory.supportCategoryId}`, `create-resource`) ?? '',
                  search: `?catId=${selectedCategory.supportCategoryId}&cedit=1`,
                  state: {
                    returnSlug: match?.url,
                  },
                }}
                color={'primary'}
              >
                <Typography variant='body2'>{'Add new Resource'}</Typography>
              </HoverLink>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <CategoryEditorResourcesList />
          </Grid>
          <Grid item container justify='center' xs={12}>
            <Grid item>
              <TabletButton
                data-track='resources-category-editor-delete-category-button'
                color='error'
                variant='outlined'
                onClick={() => setOpenDeleteCategoryDialog(true)}
              >
                {'Delete this Category'}
              </TabletButton>
            </Grid>
          </Grid>
        </Grid>
        <DeleteCategoryDialog
          open={openDeleteCategoryDialog}
          close={() => setOpenDeleteCategoryDialog(false)}
          supportCategoryId={selectedCategory.supportCategoryId}
        />
      </Form>
    </Formik>
  );
};

export { CategoryEditor };
