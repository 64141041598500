import React, { memo, ReactNode } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const FormSection = memo(
  ({ title, description, children }: { title: string; children: ReactNode; description?: ReactNode }) => {
    const { isMobile } = useCampfireTheme();
    return (
      <Grid item container xs={12} style={{ padding: isMobile ? 0 : 16 }} spacing={2} justify='space-between'>
        <Grid item container direction='column' sm={5} xs={12}>
          <Typography variant='h6' display='inline'>
            {title}
          </Typography>
          {description}
        </Grid>
        <Grid item sm={7} xs={12}>
          {children}
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Divider />
        </Grid>
      </Grid>
    );
  }
);

export { FormSection };
