import { useSnackbar } from '../../../../../../../../../global/config/useSnackbar';
import { useEnrolWaitlistingFetch } from '../../../../../../../../program-manager/activities/activity-timeline/activity-timeline-actions';
import { useUnenrolWaitlist } from '../../../../../../../../program-manager/activity-management/activity-form-v2/activity-form-actions-v2';

export type WaitlistActions = 'add' | 'withdraw';

export const useActivityWaitlistingActions = () => {
  const { setSnackbar } = useSnackbar();
  const enrolWaitlist = useEnrolWaitlistingFetch();
  const unenrolWaitlist = useUnenrolWaitlist();

  const onAddToTeam = (id: string, handleSuccess?: () => void) => {
    enrolWaitlist
      .run({
        activityWaitlistingId: id,
      })
      .then(() => {
        if (handleSuccess) {
          setTimeout(handleSuccess, 0);
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: 'Volunteer added to team successfully',
        });
      });
  };

  const onRemoveWaitlist = (id: string, reason: string, handleSuccess?: () => void) => {
    unenrolWaitlist
      .run({
        activityWaitlistingId: id,
        removalReason: reason,
      })
      .then(() => {
        if (handleSuccess) {
          setTimeout(handleSuccess, 0);
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: 'Waitlisting removed',
        });
      });
  };

  const onAction = (action: WaitlistActions, id: string, handleSuccess?: () => void) => {
    switch (action) {
      case 'add':
        onAddToTeam(id, handleSuccess);
        break;

      default:
        break;
    }
  };

  return {
    onAction,
    onRemoveWaitlist,
  };
};
