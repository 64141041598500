import React from 'react';
import { Theme, Box, BoxProps } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

interface Props extends BoxProps {
  isSelected?: boolean;
  variant?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '2rem',
      height: '2rem',
      borderRadius: '50%',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: ({ isSelected, variant }: Props) => {
        if (!isSelected) {
          return theme.color.grey.neutralBrand200;
        }
        if (variant === 'primary') {
          return theme.color.secondary.main700;
        }
        return theme.color.grey.neutralBrand300;
      },
      color: ({ isSelected }: Props) => (isSelected ? 'white' : theme.color.grey.neutralBrand300),
      [theme.breakpoints.down('sm')]: {
        width: '20px',
        height: '20px',
      },
    },
  })
);

export function DayItem(props: Props) {
  const classes = useStyles(props);
  const { isSelected: _unused, ...rest } = props;
  return <Box className={classes.container} {...rest} />;
}

export interface DayOptions {
  label: string;
  value: number;
}

export interface DaySelectProps extends BoxProps {
  days: DayOptions[];
  daysSelected: { [key: number]: boolean };
  onSelectDay: (dayValue: number) => void;
  variant?: string;
}

export function DaySelect({ days, daysSelected, onSelectDay, variant, ...cssProps }: DaySelectProps) {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' {...cssProps}>
      {days.map(({ label, value }) => (
        <DayItem
          key={value}
          onClick={() => onSelectDay(value)}
          isSelected={Boolean(daysSelected[value])}
          variant={variant}
        >
          {label}
        </DayItem>
      ))}
    </Box>
  );
}
