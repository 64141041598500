import React from 'react';
import { Dialog, DialogTitle, DialogActions, Typography } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../common/cards/alert-card/AlertCard';

const HiddenCakeDialog = ({
  open,
  isHidden,
  cakeName,
  onClose,
  onConfirm,
}: {
  open: boolean;
  isHidden: boolean;
  cakeName?: string;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const { lightTextColor } = getAlertCardColors('info');
  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>
        {`${isHidden ? 'Show:' : 'Hide:'} ${cakeName} Tasks`}
        <Typography style={{ fontSize: 14, paddingBottom: '16px' }} color='textSecondary'>{`${
          isHidden ? 'Show' : 'Hide'
        } this category ${isHidden ? 'to' : 'from'} your users `}</Typography>
        {!isHidden ? (
          <AlertCard variant='info' title='Hide Task Category'>
            <AlertCardBody>
              <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                Your volunteers will be unable to this see category or any of the tasks within it once hidden. Completed
                tasks will be visible to privileged users, but volunteers will be unable to edit or update their
                responses.
              </Typography>
            </AlertCardBody>
          </AlertCard>
        ) : (
          <AlertCard variant='info' title='Show Task Category'>
            <AlertCardBody>
              <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                Your volunteers will be able to this see category and the tasks within in once shown. Users will be able
                to submit and update tasks, and privileged users able to see responses.
              </Typography>
            </AlertCardBody>
          </AlertCard>
        )}
      </DialogTitle>
      <DialogActions>
        <TabletButton variant='text' onClick={onClose}>
          {'Cancel'}
        </TabletButton>
        <TabletButton variant='contained' color='primary' onClick={onConfirm} autoFocus>
          {isHidden ? 'Show' : 'Hide'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { HiddenCakeDialog };
