import { Box, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import { getActivityRolesText } from './activity-roster-helpers';
import { RosterGridDataRow } from './activity-roster-model';
import { useRowStyles } from './activity-roster-styles';
import { VolunteerProfileGridItem } from './ActivityRosterVolunteerProfileGridItem';
import { RosterSessionCell } from './roster-cells/RosterSessionCell';
import { ROSTER_SCROLL_DIMENSION } from './use-roster-scrolling';

interface AvailableVolunteerProps {
  activityDate: string;
  activityRoles: { activityRoleId: string; name: string }[];
  handleRosterGridSessionClick: (volunteerId: string, sessionId: string) => void;
  rosterGridDataRow: RosterGridDataRow;
  runRefetch: () => void;
}

const VolunteerRosterRow = memo((props: AvailableVolunteerProps) => {
  const { activityDate, rosterGridDataRow, handleRosterGridSessionClick, runRefetch, activityRoles } = props;
  const activityEnrolmentId =
    rosterGridDataRow.__typename === 'enrolled' ? rosterGridDataRow.activityEnrolment.activityEnrolmentId : undefined;

  const classes = useRowStyles();
  const volunteer =
    rosterGridDataRow.__typename === 'enrolled'
      ? rosterGridDataRow.activityEnrolment.volunteer
      : rosterGridDataRow.volunteer;

  const activityRolesDisplayText = getActivityRolesText({
    activityRoles,
    volunteerActivityRoles: volunteer.activityRoles,
  });

  return (
    <Box
      minWidth={1}
      display='inline-flex'
      flex='1 0 auto'
      className={classes.row}
      key={volunteer.volunteerId}
      height={ROSTER_SCROLL_DIMENSION.ROW_HEIGHT}
    >
      <VolunteerProfileGridItem
        volunteer={volunteer}
        activityDate={activityDate}
        runRefetch={runRefetch}
        activityEnrolmentId={activityEnrolmentId}
        activityUnavailabilityId={
          rosterGridDataRow.__typename === 'enrolled'
            ? rosterGridDataRow.activityEnrolment.unavailability?.activityUnavailabilityId
            : undefined
        }
        activityRolesDisplayText={activityRolesDisplayText}
        attending={rosterGridDataRow.attending}
      />

      {rosterGridDataRow.__typename === 'enrolled' && rosterGridDataRow.isUnavailable
        ? rosterGridDataRow.rosterSessions.map((rosterSession, index) => (
            <Box
              key={`${rosterSession.session.sessionId}-${rosterGridDataRow.volunteerId}`}
              minWidth={ROSTER_SCROLL_DIMENSION.COLUMN_WIDTH}
              display='flex'
              flex='1 0 0'
              justifyContent='center'
              alignItems='center'
              alignContent='center'
            >
              {index === 0 ? (
                <Typography style={{ paddingLeft: 8 }} variant='body2' color='textSecondary'>
                  Unavailable for this date
                </Typography>
              ) : null}
            </Box>
          ))
        : rosterGridDataRow.rosterSessions.map((rosterSession) => {
            return (
              <Box
                key={`${rosterSession.session.sessionId}-${rosterGridDataRow.volunteerId}`}
                className={classes.rowCell}
                minWidth={ROSTER_SCROLL_DIMENSION.COLUMN_WIDTH}
                display='flex'
                flex='1 0 0'
              >
                {!rosterSession.activeStatus ? (
                  <RosterSessionCell
                    variant={
                      rosterSession.originalStatus === 'unavailable'
                        ? 'unavailable'
                        : rosterSession.originalStatus === 'available'
                        ? 'available'
                        : 'noIndication'
                    }
                    leftCap={rosterSession.first}
                    rightCap={rosterSession.last}
                    onClick={() => handleRosterGridSessionClick(volunteer.volunteerId, rosterSession.session.sessionId)}
                    //
                  />
                ) : (
                  <RosterSessionCell
                    variant={
                      rosterSession.activeStatus === 'publishedSelected'
                        ? 'rostered'
                        : rosterSession.activeStatus === 'selected'
                        ? 'selected'
                        : 'removed'
                    }
                    leftCap={rosterSession.first}
                    rightCap={rosterSession.last}
                    onClick={() => handleRosterGridSessionClick(volunteer.volunteerId, rosterSession.session.sessionId)}
                    //
                  />
                )}
              </Box>
            );
          })}
    </Box>
  );
});

export { VolunteerRosterRow };
