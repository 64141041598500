import { FileUploadGallery, FugFile, UploadedFile } from '@campfire/file-upload-gallery';
import { HoverText } from '@campfire/hover-link';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  Radio,
  RadioGroup,
  Select,
  SelectProps,
} from '@material-ui/core';
import { AlternateEmailRounded, PhoneIphoneRounded } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useEffect, useMemo, useState } from 'react';
import { TaskFormValuesType } from '../../form/task-form/model/task-form-init';
import { AddressAutoComplete, AddressAutoCompleteType } from '../AddressAutoComplete';
import { AddressManualFields, AddressManualFieldsType } from '../AddressManualFields';
import { TaskFieldContainer, TaskFieldContainerProps } from '../containers/TaskFieldContainer';
import { TaskFormikMultipleChoiceType, TaskFormikQuizType } from './TaskFormikFields';
import { SignaturePad } from '../SignaturePad';

const MAX_FIELD_WIDTH = 540;
const MAX_FIELD_WIDTH_SMALL = 360;

type TaskFieldCommonConfig = {
  required?: boolean;
  autoComplete?: string;
  error?: boolean;
  ariaId: string;
  ariaDescribedBy: string;
};

type ShortTextTaskFieldConfig = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  outlinedInputProps?: Omit<OutlinedInputProps, 'onChange' | 'value'>;
} & TaskFieldCommonConfig;

export type ShortTextTaskFieldProps = ShortTextTaskFieldConfig & TaskFieldContainerProps;

const ShortTextTaskField = (props: ShortTextTaskFieldProps) => {
  const {
    error,
    ariaId,
    ariaDescribedBy,
    value,
    onChange,
    required,
    autoComplete,
    outlinedInputProps,
    ...rest
  } = props;
  return (
    <TaskFieldContainer required={required} error={error} ariaId={ariaId} ariaDescribedBy={ariaDescribedBy} {...rest}>
      <OutlinedInput
        {...outlinedInputProps}
        fullWidth
        style={{ maxWidth: MAX_FIELD_WIDTH }}
        id={ariaId}
        aria-describedby={ariaDescribedBy}
        placeholder='Write your answer here'
        value={value}
        onChange={onChange}
        required={required}
        autoComplete={autoComplete}
      />
    </TaskFieldContainer>
  );
};

type LongTextTaskFieldConfig = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  outlinedInputProps?: Omit<OutlinedInputProps, 'onChange' | 'value'>;
} & TaskFieldCommonConfig;

export type LongTextTaskFieldProps = LongTextTaskFieldConfig & TaskFieldContainerProps;

const LongTextTaskField = (props: LongTextTaskFieldProps) => {
  const { error, ariaId, ariaDescribedBy, value, onChange, required, outlinedInputProps, ...rest } = props;
  return (
    <TaskFieldContainer required={required} error={error} ariaId={ariaId} ariaDescribedBy={ariaDescribedBy} {...rest}>
      <OutlinedInput
        {...outlinedInputProps}
        multiline
        rows={3}
        fullWidth
        style={{ maxWidth: MAX_FIELD_WIDTH }}
        id={ariaId}
        aria-describedby={ariaDescribedBy}
        placeholder='Write your answer here'
        value={value}
        onChange={onChange}
        required={required}
      />
    </TaskFieldContainer>
  );
};

type NumberTaskFieldConfig = {
  value: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  outlinedInputProps?: Omit<OutlinedInputProps, 'onChange' | 'value'>;
} & TaskFieldCommonConfig;

export type NumberTaskFieldProps = NumberTaskFieldConfig & TaskFieldContainerProps;

const NumberTaskField = (props: NumberTaskFieldProps) => {
  const { error, ariaId, ariaDescribedBy, value, onChange, required, outlinedInputProps, ...rest } = props;
  return (
    <TaskFieldContainer required={required} error={error} ariaId={ariaId} ariaDescribedBy={ariaDescribedBy} {...rest}>
      <OutlinedInput
        {...outlinedInputProps}
        fullWidth
        type='number'
        style={{ maxWidth: MAX_FIELD_WIDTH_SMALL }}
        id={ariaId}
        aria-describedby={ariaDescribedBy}
        placeholder='Write your answer here'
        value={value}
        onChange={onChange}
        required={required}
      />
    </TaskFieldContainer>
  );
};

type MultipleChoiceTaskFieldConfig = {
  options: Array<{
    id: string;
    label: string;
    allowText: boolean;
  }>;
  value: TaskFormikMultipleChoiceType;
  onChange: (event: TaskFormikMultipleChoiceType) => void;
  values: TaskFormValuesType;
} & TaskFieldCommonConfig;

export type MultipleChoiceTaskFieldProps = MultipleChoiceTaskFieldConfig & TaskFieldContainerProps;

const MultipleChoiceTaskField = (props: MultipleChoiceTaskFieldProps) => {
  const { title, error, ariaId, ariaDescribedBy, options, value, onChange, required, ...rest } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    value.multipleChoiceOptionId = event.target.value;
    onChange(value);
  };

  const toggleOptionalText = (otherSelected: boolean) => {
    value.otherOptionSelected = otherSelected;
  };

  function updateOtherText(event: React.ChangeEvent<HTMLInputElement>) {
    value.otherText = event.target.value;
    onChange(value);
  }

  return (
    <TaskFieldContainer
      title={title}
      error={error}
      ariaId={ariaId}
      ariaDescribedBy={ariaDescribedBy}
      required={required}
      {...rest}
    >
      <>
        <RadioGroup
          id={ariaId}
          name={ariaId}
          aria-describedby={ariaDescribedBy}
          aria-label={title}
          value={value.multipleChoiceOptionId}
          onChange={handleChange}
        >
          {options.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                value={option.id}
                control={
                  <Radio required={required} color='primary' onClick={() => toggleOptionalText(option.allowText)} />
                }
                label={option.label}
              />
            );
          })}
        </RadioGroup>
        {value.otherOptionSelected ? (
          <OutlinedInput
            fullWidth
            style={{ maxWidth: MAX_FIELD_WIDTH_SMALL, marginLeft: 16 }}
            id={ariaId}
            aria-describedby={ariaDescribedBy}
            placeholder='Write your answer here'
            value={value.otherText}
            onChange={updateOtherText}
            required
          />
        ) : null}
      </>
    </TaskFieldContainer>
  );
};

type QuizTaskFieldConfig = {
  options: Array<{
    id: string;
    label: string;
    allowText: boolean;
  }>;
  correctOptionId?: string;
  value: TaskFormikQuizType;
  onChange: (event: TaskFormikQuizType) => void;
} & TaskFieldCommonConfig;

export type QuizTaskFieldProps = QuizTaskFieldConfig & TaskFieldContainerProps;

const QuizTaskField = (props: QuizTaskFieldProps) => {
  const { title, error, ariaId, ariaDescribedBy, options, value, onChange, required, correctOptionId, ...rest } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    value.quizTaskFieldOptionId = event.target.value;
    value.isCorrect = correctOptionId === event.target.value;
    onChange(value);
  };

  const onSelectOption = (otherSelected: boolean) => {
    value.otherTaskFieldOptionSelected = otherSelected;
  };

  function updateOtherText(event: React.ChangeEvent<HTMLInputElement>) {
    value.otherText = event.target.value;
    onChange(value);
  }

  return (
    <TaskFieldContainer
      title={title}
      error={error}
      ariaId={ariaId}
      ariaDescribedBy={ariaDescribedBy}
      required={required}
      {...rest}
    >
      <>
        <RadioGroup
          id={ariaId}
          name={ariaId}
          aria-describedby={ariaDescribedBy}
          aria-label={title}
          value={value.quizTaskFieldOptionId}
          onChange={handleChange}
        >
          {options.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                value={option.id}
                control={<Radio required={required} color='primary' onClick={() => onSelectOption(option.allowText)} />}
                label={option.label}
              />
            );
          })}
        </RadioGroup>
        {value.otherTaskFieldOptionSelected ? (
          <OutlinedInput
            fullWidth
            style={{ maxWidth: MAX_FIELD_WIDTH_SMALL, marginLeft: 16 }}
            id={ariaId}
            aria-describedby={ariaDescribedBy}
            placeholder='Write your answer here'
            value={value.otherText}
            onChange={updateOtherText}
            required
          />
        ) : null}
      </>
    </TaskFieldContainer>
  );
};

type CheckboxTaskFieldConfig = {
  options: Array<{
    id: string;
    label: string;
  }>;
  value: string[];
  onChange: (event: string[]) => void;
} & TaskFieldCommonConfig;

export type CheckboxTaskFieldProps = CheckboxTaskFieldConfig & TaskFieldContainerProps;

const CheckboxTaskField = (props: CheckboxTaskFieldProps) => {
  const { title, error, ariaId, ariaDescribedBy, options, value, onChange, required, ...rest } = props;

  // const addOptionId = (optionId: string) => {
  //   value.push(optionId);
  //   // onChange(value);
  // };
  //
  // const removeOptionId = (optionId: string) => {
  //   value = value.filter((val) => val !== optionId);
  //   // onChange(value);
  // };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      // addOptionId(event.target.name);
      value.push(event.target.name);
      onChange(value);
      return;
    }

    // removeOptionId(event.target.name);

    onChange(value.filter((val) => val !== event.target.name));
  }

  return (
    <TaskFieldContainer
      title={title}
      error={error}
      ariaId={ariaId}
      ariaDescribedBy={ariaDescribedBy}
      required={required}
      {...rest}
    >
      <FormControl component='fieldset' required={required}>
        <FormGroup id={ariaId} aria-describedby={ariaDescribedBy} aria-label={title}>
          {options.map((option) => {
            const checked = value.find((val) => val === option.id);
            return (
              <FormControlLabel
                key={option.id}
                control={<Checkbox checked={!!checked} onChange={handleChange} color='primary' name={option.id} />}
                label={option.label}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </TaskFieldContainer>
  );
};

type DropdownTaskFieldConfig = {
  options: Array<{
    id: string;
    label: string;
    // allowText: boolean;
  }>;
  value: string;
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  selectProps?: SelectProps;
} & TaskFieldCommonConfig;

export type DropdownTaskFieldProps = DropdownTaskFieldConfig & TaskFieldContainerProps;

const DropdownTaskField = (props: DropdownTaskFieldProps) => {
  const { title, error, ariaId, ariaDescribedBy, value, onChange, options, required, selectProps, ...rest } = props;
  return (
    <TaskFieldContainer
      title={title}
      error={error}
      ariaId={ariaId}
      ariaDescribedBy={ariaDescribedBy}
      required={required}
      {...rest}
    >
      <Select
        {...selectProps}
        variant='outlined'
        native
        value={value}
        onChange={onChange}
        fullWidth
        style={{ maxWidth: MAX_FIELD_WIDTH_SMALL }}
        inputProps={{
          name: ariaId,
          id: ariaId,
        }}
        required={required}
      >
        <option aria-label='None' value=''>
          Select an answer
        </option>
        {options.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          );
        })}
      </Select>
    </TaskFieldContainer>
  );
};

type AttachmentTaskFieldConfig = {
  allowMultiple: boolean;
  uploadedFiles: Array<UploadedFile>;
  handleFilesAdded: (files: FugFile[]) => void;
  handleFileRemoved: (file: FugFile) => void;
} & TaskFieldCommonConfig;

export type AttachmentTaskFieldProps = AttachmentTaskFieldConfig & TaskFieldContainerProps;

const AttachmentTaskField = (props: AttachmentTaskFieldProps) => {
  const {
    error,
    ariaId,
    ariaDescribedBy,
    allowMultiple,
    uploadedFiles,
    handleFilesAdded,
    handleFileRemoved,
    required,
    ...rest
  } = props;

  return (
    <TaskFieldContainer required={required} error={error} ariaId={ariaId} ariaDescribedBy={ariaDescribedBy} {...rest}>
      <FileUploadGallery
        allowMultiple={allowMultiple}
        uploadedFiles={uploadedFiles}
        handleFilesAdded={handleFilesAdded}
        handleFileRemoved={handleFileRemoved}
        // required={required}
      />
    </TaskFieldContainer>
  );
};

type DateTaskFieldConfig = {
  value: ParsableDate;
  onChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
  keyboardDatePickerProps?: Omit<KeyboardDatePickerProps, 'onChange' | 'value'>;
} & TaskFieldCommonConfig;

export type DateTaskFieldProps = DateTaskFieldConfig & TaskFieldContainerProps;

const DateTaskField = (props: DateTaskFieldProps) => {
  const { error, ariaId, ariaDescribedBy, value, onChange, required, keyboardDatePickerProps, ...rest } = props;
  return (
    <TaskFieldContainer required={required} error={error} ariaId={ariaId} ariaDescribedBy={ariaDescribedBy} {...rest}>
      <KeyboardDatePicker
        {...keyboardDatePickerProps}
        autoOk
        variant='inline'
        inputVariant='outlined'
        format='dd/MM/yyyy'
        placeholder='dd/mm/yyyy'
        InputAdornmentProps={{
          position: 'start',
        }}
        KeyboardButtonProps={{ tabIndex: -1 }}
        value={value}
        onChange={onChange}
        required={required}
        openTo='year'
        maxDateMessage=''
        minDateMessage=''
        invalidDateMessage='Date format must be: dd/mm/yyyy'
      />
    </TaskFieldContainer>
  );
};

type AddressTaskFieldConfig = {
  allowManual: boolean;
  addressAutocompleteValue?: AddressAutoCompleteType;
  handleAddressAutoCompleteChange: (address: AddressAutoCompleteType) => void;
  manualAddressValue?: AddressManualFieldsType;
  handleAddressManualChange?: (address: AddressManualFieldsType) => void;
} & TaskFieldCommonConfig;

export type AddressTaskFieldProps = AddressTaskFieldConfig & TaskFieldContainerProps;

const AddressTaskField = (props: AddressTaskFieldProps) => {
  const {
    error,
    ariaId,
    ariaDescribedBy,
    autoComplete,
    allowManual,
    addressAutocompleteValue,
    handleAddressAutoCompleteChange,
    manualAddressValue,
    handleAddressManualChange,
    helperText,
    required,
    ...rest
  } = props;
  const [showManual, setShowManual] = useState(false);

  useEffect(() => {
    if (showManual && handleAddressManualChange && manualAddressValue) handleAddressManualChange(manualAddressValue);
    if (!showManual && handleAddressAutoCompleteChange && addressAutocompleteValue)
      handleAddressAutoCompleteChange(addressAutocompleteValue);
  }, [showManual]);

  const errorMsg = useMemo(() => (showManual ? helperText : error ? 'Required' : ''), [showManual, helperText, error]);

  if (!allowManual) {
    return (
      <TaskFieldContainer
        error={error}
        ariaId={ariaId}
        ariaDescribedBy={ariaDescribedBy}
        helperText={errorMsg}
        required={required}
        {...rest}
      >
        <AddressAutoComplete
          id={ariaId}
          value={addressAutocompleteValue}
          onChange={handleAddressAutoCompleteChange}
          required={required}
          autoComplete={autoComplete}
        />
      </TaskFieldContainer>
    );
  }

  if (handleAddressManualChange === undefined) {
    throw new Error('AddressTaskField requires a handleAddressManualChange if allowManual is true');
  }

  if (manualAddressValue === undefined) {
    throw new Error('AddressTaskField requires a manualAddressValue if allowManual is true');
  }

  return (
    <TaskFieldContainer
      error={error}
      ariaId={ariaId}
      ariaDescribedBy={ariaDescribedBy}
      helperText={errorMsg}
      required={required}
      {...rest}
    >
      <Box maxWidth={MAX_FIELD_WIDTH}>
        {showManual ? (
          <>
            <AddressManualFields value={manualAddressValue} onChange={handleAddressManualChange} required={required} />

            <HoverText style={{ marginTop: 12 }} color='primary' variant='body2' onClick={() => setShowManual(false)}>
              Back to address search
            </HoverText>
          </>
        ) : (
          <>
            <AddressAutoComplete
              id={ariaId}
              value={addressAutocompleteValue}
              autoComplete={autoComplete}
              onChange={handleAddressAutoCompleteChange}
            />

            <HoverText style={{ marginTop: 12 }} color='primary' variant='body2' onClick={() => setShowManual(true)}>
              {`Can't find your address?`}
            </HoverText>
          </>
        )}
      </Box>
    </TaskFieldContainer>
  );
};

type PhoneFieldConfig = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  outlinedInputProps?: Omit<OutlinedInputProps, 'onChange' | 'value'>;
} & TaskFieldCommonConfig;

export type PhoneTaskFieldProps = PhoneFieldConfig & TaskFieldContainerProps;

const PhoneTaskField = (props: PhoneTaskFieldProps) => {
  const { error, ariaId, ariaDescribedBy, value, onChange, required, outlinedInputProps, ...rest } = props;
  return (
    <TaskFieldContainer required={required} error={error} ariaId={ariaId} ariaDescribedBy={ariaDescribedBy} {...rest}>
      <OutlinedInput
        {...outlinedInputProps}
        startAdornment={
          <InputAdornment disablePointerEvents position='start'>
            <PhoneIphoneRounded color='action' />
          </InputAdornment>
        }
        type='tel'
        fullWidth
        style={{ maxWidth: MAX_FIELD_WIDTH }}
        id={ariaId}
        aria-describedby={ariaDescribedBy}
        placeholder='Write your answer here'
        value={value}
        onChange={onChange}
        required={required}
      />
    </TaskFieldContainer>
  );
};

type EmailTaskFieldConfig = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  outlinedInputProps?: Omit<OutlinedInputProps, 'onChange' | 'value'>;
} & TaskFieldCommonConfig;

export type EmailTaskFieldProps = EmailTaskFieldConfig & TaskFieldContainerProps;

const EmailTaskField = (props: EmailTaskFieldProps) => {
  const { error, ariaId, ariaDescribedBy, value, onChange, required, outlinedInputProps, ...rest } = props;
  return (
    <TaskFieldContainer required={required} error={error} ariaId={ariaId} ariaDescribedBy={ariaDescribedBy} {...rest}>
      <OutlinedInput
        {...outlinedInputProps}
        startAdornment={
          <InputAdornment disablePointerEvents position='start'>
            <AlternateEmailRounded color='action' />
          </InputAdornment>
        }
        type='email'
        fullWidth
        style={{ maxWidth: MAX_FIELD_WIDTH }}
        id={ariaId}
        aria-describedby={ariaDescribedBy}
        placeholder='Write your answer here'
        value={value}
        onChange={onChange}
        required={required}
      />
    </TaskFieldContainer>
  );
};

type SignatureTaskFieldConfig = {
  value: string;
  onChangeSignature: (file: string) => void;
} & TaskFieldCommonConfig;

export type SignatureTaskFieldProps = SignatureTaskFieldConfig & TaskFieldContainerProps;

const SignatureTaskField = (props: SignatureTaskFieldProps) => {
  const { error, ariaId, ariaDescribedBy, value, onChangeSignature, required, ...rest } = props;

  return (
    <TaskFieldContainer
      required={required}
      error={error}
      ariaId={ariaId}
      ariaDescribedBy={ariaDescribedBy}
      pl={2}
      {...rest}
    >
      <SignaturePad value={value} onChange={onChangeSignature} />
    </TaskFieldContainer>
  );
};

export {
  ShortTextTaskField,
  LongTextTaskField,
  NumberTaskField,
  MultipleChoiceTaskField,
  CheckboxTaskField,
  DropdownTaskField,
  AttachmentTaskField,
  DateTaskField,
  AddressTaskField,
  PhoneTaskField,
  EmailTaskField,
  QuizTaskField,
  SignatureTaskField,
};
