import { gql } from '@apollo/client';

export const GET_INCIDENT_REPORTS = gql`
  query IncidentManagementGetIncidents(
    $pagination: PaginationInputType
    $status: String
    $programIds: [String!]
    $activityIds: [String!]
    $severityIds: [String!]
    $isInActivity: Boolean
    $range: String
    $widgetType: String
  ) {
    vm {
      incidentReports(
        pagination: $pagination
        status: $status
        programIds: $programIds
        activityIds: $activityIds
        incidentSeverityIds: $severityIds
        isInActivity: $isInActivity
        range: $range
        widgetType: $widgetType
      ) {
        incident {
          incidentId
          incidentStatus
          dateWorking
          dateClosed
          closingComments
          program {
            programId
            name
          }
        }
        incidentReportId
        incidentDateTime
        submissionDateTime
        referenceNumber
        othersInvolved
        description
        furtherAction
        severity {
          incidentSeverityId
          name
          color
        }
        category {
          label
          details {
            incidentDetailId
            label
          }
        }
        detail {
          ...IncidentManagementSingleIncidentDetail
        }
        incidentLocation {
          __typename
          incidentLocationId
          ... on VOLUNTEER_ActivityIncidentLocationType {
            activity {
              name
              activityLocation {
                placesAddress {
                  description
                }
              }
            }
          }
          ... on VOLUNTEER_AddressIncidentLocationType {
            address {
              suburb
              humanReadable
            }
          }
        }
        submittedBy {
          volunteerId
          profile {
            profileId
            preferredName
            lastName
            avatarUrl
            email
          }
        }
        volunteersInvolved {
          volunteerId
          profile {
            profileId
            preferredName
            lastName
            avatarUrl
          }
        }
        resources {
          imageId: incidentReportResourceId
          imageUrl: resourceUrl
        }
        histories {
          incidentReportHistoryId
          description
          updatedBy {
            volunteerId
            profile {
              profileId
              preferredName
              lastName
              avatarUrl
              email
            }
          }
          dateUpdated
        }
      }
    }
  }
  fragment IncidentManagementSingleIncidentDetail on VOLUNTEER_IncidentDetailType {
    incidentDetailId
    label
    incidentCategory {
      ...IncidentManagementSingleIncidentIncidentCategoryGroups
    }
  }
  fragment IncidentManagementSingleIncidentIncidentCategoryGroups on VOLUNTEER_IncidentCategoryType {
    incidentCategoryId
    label
    incidentCategoryGroup {
      ...IncidentManagementSingleIncidentIncidentCategoryGroup
      parentGroup {
        ...IncidentManagementSingleIncidentIncidentCategoryGroup
        parentGroup {
          ...IncidentManagementSingleIncidentIncidentCategoryGroup
          parentGroup {
            ...IncidentManagementSingleIncidentIncidentCategoryGroup
            parentGroup {
              ...IncidentManagementSingleIncidentIncidentCategoryGroup
              parentGroup {
                ...IncidentManagementSingleIncidentIncidentCategoryGroup
                parentGroup {
                  ...IncidentManagementSingleIncidentIncidentCategoryGroup
                  parentGroup {
                    ...IncidentManagementSingleIncidentIncidentCategoryGroup
                    parentGroup {
                      ...IncidentManagementSingleIncidentIncidentCategoryGroup
                      parentGroup {
                        ...IncidentManagementSingleIncidentIncidentCategoryGroup
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment IncidentManagementSingleIncidentIncidentCategoryGroup on VOLUNTEER_IncidentCategoryGroupType {
    incidentCategoryGroupId
    label
  }
`;
