import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { encodeDate } from '@campfire/hot-date';
import { Box, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useMemo, useState } from 'react';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { ImpactDashboardFooter } from '../general-impact-dashboard/ImpactDashboardFooter';
import { getTableColumns, getTableData } from './impact-dashboard-detailed-model';
import { GET_IMPACT_DASHBOARD_DETAILED_VIEW } from './impact-dashboard-detailed-view-model.gql';
import {
  ImpactDashboardDetailedDateFilter,
  impactDashboardDetailedInitialEndDate,
  impactDashboardDetailedInitialStartDate,
} from './ImpactDashboardDetailedDateFilter';
import { ImpactDashboardDetailedFilters } from './ImpactDashboardDetailedFilters';
import {
  GetImpactDashboardDetailedView,
  GetImpactDashboardDetailedViewVariables,
} from './__generated__/GetImpactDashboardDetailedView';

const defaultMuiDatatableTextLabels = {
  body: {
    noMatch: 'Sorry, no matching records found',
    toolTip: 'Sort',
    columnHeaderTooltip: (column: any) => `Sort for ${column.label}`,
  },
  pagination: {
    next: 'Next Page',
    previous: 'Previous Page',
    rowsPerPage: 'Rows per page:',
    displayRows: 'of',
  },
  toolbar: {
    search: 'Search',
    downloadCsv: 'Download CSV',
    print: 'Print',
    viewColumns: 'View Columns',
    filterTable: 'Filter Table',
  },
  filter: {
    all: 'All',
    title: 'FILTERS',
    reset: 'RESET',
  },
  viewColumns: {
    title: 'Show Columns',
    titleAria: 'Show/Hide Table Columns',
  },
  selectedRows: {
    text: 'row(s) selected',
    delete: 'Delete',
    deleteAria: 'Delete Selected Rows',
  },
};

const ImpactDashboardDetailedView = () => {
  const [startDate, setStartDate] = useState<DateTime>(impactDashboardDetailedInitialStartDate);
  const [endDate, setEndDate] = useState<DateTime>(impactDashboardDetailedInitialEndDate);

  const query = useCampfireQuery<GetImpactDashboardDetailedView, GetImpactDashboardDetailedViewVariables>(
    GET_IMPACT_DASHBOARD_DETAILED_VIEW,
    {
      options: {
        variables: {
          startDate: encodeDate(impactDashboardDetailedInitialStartDate),
          endDate: encodeDate(impactDashboardDetailedInitialEndDate),
        },
      },
    }
  );

  useEffect(() => {
    if (!query || !query.refetch) return;
    query.refetch({
      startDate: encodeDate(startDate),
      endDate: encodeDate(endDate),
    });
  }, [startDate, endDate]);

  const filterData = useMemo(() => {
    if (!query.data || !query.data.vm) {
      return {
        programs: [],
        activities: [],
        reportTypes: [],
      };
    }

    const activities = query.data.vm.programs.flatMap((program) =>
      program.activities.map((activity) => ({
        ...activity,
        programId: activity.program.programId,
        sessionReportTypeIds: activity.sessions.flatMap((session) => session.reportType?.reportTypeId ?? ''),
      }))
    );

    const programs = query.data.vm.programs.map((program) => ({
      ...program,
      name: program.dateSuspended === null ? program.name : `${program.name} (Suspended)`,
      activityIds: program.activities.map((activity) => activity.activityId),
      sessionReportTypeIds: program.activities.flatMap((activity) =>
        activity.sessions.map((session) => session.reportType?.reportTypeId ?? '')
      ),
    }));

    const relevantReportTypeIds = activities.flatMap((activity) =>
      activity.activityReports.flatMap((activityReport) =>
        activityReport.sessionReports.flatMap((sessionReport) => sessionReport.session.reportType?.reportTypeId ?? '')
      )
    );
    const relevantReportTypes = query.data.vm.reportTypes.filter((reportType) =>
      relevantReportTypeIds.includes(reportType.reportTypeId)
    );

    const relevantReportTypesSorted = relevantReportTypes.sort((a, b) => a.name.localeCompare(b.name));

    const reportTypeOptions = relevantReportTypeIds.includes('')
      ? relevantReportTypesSorted.concat([
          {
            __typename: 'VOLUNTEER_ReportTypeType',
            reportTypeId: '',
            name: 'Nulled Report Type.',
            items: [],
          },
        ])
      : relevantReportTypesSorted;

    return {
      programs: programs.sort((a, b) => a.name.localeCompare(b.name)),
      activities: activities.sort((a, b) => a.name.localeCompare(b.name)),
      reportTypes: reportTypeOptions,
    };
  }, [query.data]);

  const [selectedActivityIds, setSelectedActivityIds] = useState<Array<string>>([]);
  const [selectedProgramIds, setSelectedProgramIds] = useState<Array<string>>([]);
  const [selectedReportTypeIds, setSelectedReportTypeIds] = useState<Array<string>>([]);

  const data = useMemo(() => getTableData(query?.data), [query.data]);
  const columns = useMemo(() => getTableColumns(selectedReportTypeIds, query?.data), [
    query.data,
    selectedReportTypeIds,
  ]);

  const filteredTableData = useMemo(() => {
    return data
      ?.filter(
        (x) =>
          selectedProgramIds.includes(x.programId as string) &&
          selectedActivityIds.includes(x.activityId as string) &&
          selectedReportTypeIds.includes(x.reportTypeId as string)
      )
      .sort((a, b) => (a.activityDate > b.activityDate ? -1 : 1));
  }, [data, selectedProgramIds, selectedActivityIds, selectedReportTypeIds]);

  const tableOptions: MUIDataTableOptions = useMemo(
    () => ({
      elevation: 0,
      print: false,
      responsive: 'standard',
      rowHover: false,
      rowsPerPage: 25,
      rowsPerPageOptions: [25, 50, 100, 250],
      selectableRows: 'none',
      textLabels: {
        ...defaultMuiDatatableTextLabels,
        body: {
          ...defaultMuiDatatableTextLabels.body,
          noMatch: 'No results - Select a Report Type to get started',
        },
      },
      downloadOptions: {
        filename: `Volaby-${query?.data?.orgName.replace(' ', '_').concat('-') ?? ''}detailed-stats.csv`,
        separator: ',',
      },
      customFooter: (
        count: number,
        page: number,
        rowsPerPage: number,
        changeRowsPerPage: (x: any) => any,
        changePage: (x: number) => void,
        textLabels: any
      ) => {
        return (
          <ImpactDashboardFooter
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            changeRowsPerPage={changeRowsPerPage}
            changePage={changePage}
            textLabels={textLabels}
          />
        );
      },
    }),
    [query?.data?.orgName]
  );

  return (
    <>
      <Box
        paddingTop={3}
        paddingLeft={2}
        paddingRight={2}
        display='flex'
        justifyContent='space-between'
        flexWrap='wrap'
        minHeight={128}
      >
        <ImpactDashboardDetailedDateFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />

        <Box display='flex' flexDirection='column' alignContent='flex-end' alignItems='flex-end'>
          <ImpactDashboardDetailedFilters
            activities={filterData.activities}
            programs={filterData.programs}
            reportTypes={filterData.reportTypes}
            selectedActivityIds={selectedActivityIds}
            selectedProgramIds={selectedProgramIds}
            selectedReportTypeIds={selectedReportTypeIds}
            setSelectedActivityIds={setSelectedActivityIds}
            setSelectedProgramIds={setSelectedProgramIds}
            setSelectedReportTypeIds={setSelectedReportTypeIds}
          />

          <Typography style={{ paddingTop: 4, paddingRight: 8 }} variant='body2' color='textSecondary'>
            {`${filteredTableData?.length} results (excluding extra filters)`}
          </Typography>
        </Box>
      </Box>

      {filteredTableData && filteredTableData.length > 0 ? (
        <MUIDataTable options={tableOptions} title='' columns={columns} data={filteredTableData} />
      ) : (
        <Box width={1} height={360} display='flex' alignItems='center' alignContent='center' justifyContent='center'>
          <Box textAlign='center'>
            <Typography variant='h5' color='textSecondary'>
              {'No Results'}
            </Typography>
            <Typography variant='body1' color='textSecondary'>
              {selectedReportTypeIds.length === 0 && filterData.reportTypes.length > 0
                ? 'Select a Report type to get started'
                : 'Try a different time period'}
            </Typography>
          </Box>
        </Box>
      )}

      <CircularProgressOverlay isLoading={query.loading} />
    </>
  );
};

export { ImpactDashboardDetailedView };
