import { unpackToDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { DateObject } from 'react-multi-date-picker';
import { RecurrenceEnum } from '../../../__generated__/globalTypes';
import { DailyRecurrence } from '../../recurrence/__generated__/DailyRecurrence';
import { MonthlyByMonthDayRecurrence } from '../../recurrence/__generated__/MonthlyByMonthDayRecurrence';
import { MonthlyNthDayRecurrence } from '../../recurrence/__generated__/MonthlyNthDayRecurrence';
import { SingleRecurrence } from '../../recurrence/__generated__/SingleRecurrence';
import { WeeklyRecurrence } from '../../recurrence/__generated__/WeeklyRecurrence';
import { YearlyRecurrence } from '../../recurrence/__generated__/YearlyRecurrence';
import { AvailabilityCenterActivityType_VOLUNTEER_RecurringActivityType_schedule_recurrences as RecurrenceType } from '../__generated__/AvailabilityCenterActivityType';
import { BeginOptionsEnum, FREQUENCY_ENUM, LengthOptionEnum, LENGTH_VALUES } from './consts';

export const DATE_FORMAT = 'YYYY-MM-DD';

const inRange = (recurrence: RecurrenceType) => (date: DateObject) => {
  const laterThanStart = recurrence.startDate <= date.format(DATE_FORMAT);
  if (!recurrence.endDate) {
    return laterThanStart;
  }

  return laterThanStart && date.format(DATE_FORMAT) <= recurrence.endDate;
};

export const singleRecurrenceCheck = (recurrence: SingleRecurrence) => (date: DateObject) => {
  return date.format(DATE_FORMAT) === recurrence.startDate;
};

export const dailyRecurrenceCheck = (recurrence: DailyRecurrence) => (date: DateObject) => {
  if (!inRange(recurrence)(date)) {
    return false;
  }
  const dayDiff = unpackToDate(date.format(DATE_FORMAT)).diff(unpackToDate(recurrence.startDate), 'days').days;
  return dayDiff % recurrence.dayInterval === 0;
};

export const weeklyRecurrenceCheck = (recurrence: WeeklyRecurrence) => (date: DateObject) => {
  if (!inRange(recurrence)(date)) {
    return false;
  }
  const weekDiff = unpackToDate(date.format(DATE_FORMAT)).diff(unpackToDate(recurrence.startDate), 'weeks').weeks;
  const isSameDay = recurrence.days.includes(
    unpackToDate(date.format(DATE_FORMAT)).weekdayShort.slice(0, 2).toUpperCase() as RecurrenceEnum
  );
  return isSameDay && weekDiff % recurrence.weekInterval === 0;
};

export const monthlyByMonthdayRecurrenceCheck = (recurrence: MonthlyByMonthDayRecurrence) => (date: DateObject) => {
  if (!inRange(recurrence)(date)) {
    return false;
  }
  return recurrence.monthDay === unpackToDate(date.format(DATE_FORMAT)).day;
};

export const monthlyByNthDayRecurrenceCheck = (recurrence: MonthlyNthDayRecurrence) => (date: DateObject) => {
  if (!inRange(recurrence)(date)) {
    return false;
  }
  const dateDay = unpackToDate(date.format(DATE_FORMAT)).day;
  const dateWeek = dateDay % 7 === 0 ? dateDay / 7 : Math.floor(dateDay / 7) + 1;
  const isSameWeekDay =
    (unpackToDate(date.format(DATE_FORMAT)).weekdayShort.slice(0, 2).toUpperCase() as RecurrenceEnum) ===
    recurrence.day;
  return isSameWeekDay && dateWeek === recurrence.n;
};

export const yearlyRecurrenceCheck = (recurrence: YearlyRecurrence) => (date: DateObject) => {
  if (!inRange(recurrence)(date)) {
    return false;
  }
  const unpackStartDate = unpackToDate(recurrence.startDate);
  const unpackDate = unpackToDate(date.format(DATE_FORMAT));
  return unpackDate.day === unpackStartDate.day && unpackDate.month === unpackStartDate.month;
};

export const isDateEnable = (recurrence: RecurrenceType) => {
  switch (recurrence.__typename) {
    case 'SingleRecurrenceType':
      return singleRecurrenceCheck(recurrence);
    case 'DailyRecurrenceType':
      return dailyRecurrenceCheck(recurrence);
    case 'WeeklyRecurrenceType':
      return weeklyRecurrenceCheck(recurrence);
    case 'MonthlyByMonthDayRecurrenceType':
      return monthlyByMonthdayRecurrenceCheck(recurrence);
    case 'MonthlyNthDayRecurrenceType':
      return monthlyByNthDayRecurrenceCheck(recurrence);
    case 'YearlyRecurrenceType':
      return yearlyRecurrenceCheck(recurrence);
    default:
      return () => false;
  }
};

export const filterInRange = (rangeValue: LengthOptionEnum) => {
  return (occurrence: string) => {
    if (rangeValue === LengthOptionEnum.NA) {
      return true;
    }
    let startDate = DateTime.local();
    let endDate = DateTime.local();

    if (rangeValue === LengthOptionEnum.THIS_WEEK) {
      startDate = DateTime.local().startOf('week');
      endDate = DateTime.local().endOf('week');
    } else {
      const range = LENGTH_VALUES[rangeValue];
      endDate = startDate.plus(range);
    }


    return occurrence <= endDate.toISODate() && occurrence >= startDate.toISODate();
  };
}


export const filterByDay = (days: RecurrenceEnum[]) => {
  return (occurrence: string) => {
    if (days.length === 0) {
      return true;
    }

    const date = unpackToDate(occurrence);
    const day = date.weekdayLong.toUpperCase().slice(0, 2);
    return days.includes(day as RecurrenceEnum);
  }
}

export const filterByBegin = (beginValue?: BeginOptionsEnum) => {
  return (occurrence: string) => {
    if (beginValue === BeginOptionsEnum.NA || !beginValue) {
      return true;
    }

    if ([BeginOptionsEnum.TODAY, BeginOptionsEnum.THIS_WEEK, BeginOptionsEnum.THIS_MONTH].includes(beginValue)) {
      return occurrence >= DateTime.local().toISODate();
    }

    if (beginValue === BeginOptionsEnum.TOMORROW) {
      return occurrence >= DateTime.local().plus({ days: 1 }).toISODate();
    }

    if (beginValue === BeginOptionsEnum.NEXT_WEEK) {
      return occurrence >= DateTime.local().plus({ weeks: 1 }).startOf('week').toISODate();
    }

    if (beginValue === BeginOptionsEnum.NEXT_MONTH) {
      return occurrence >= DateTime.local().plus({ months: 1 }).startOf('month').toISODate();
    }

    return false;
  }
}

export const filterByFrequence = (frequencyValue: FREQUENCY_ENUM) => {
  return (occurrence: string, occurrenceIndex: number, occurrences: string[]) => {
    if (frequencyValue === FREQUENCY_ENUM.CUSTOM) {
      return false;
    }

    if (frequencyValue === FREQUENCY_ENUM.JUST_THIS_ONE) {
      return occurrenceIndex === 0;
    }

    if ([
      FREQUENCY_ENUM.EVERYDAY,
      FREQUENCY_ENUM.EVERY_MONTH,
      FREQUENCY_ENUM.EVERY_WEEK,
    ].includes(frequencyValue)) {
      return true;
    }

    const startDate = occurrences[0];
    if (!startDate) {
      return false;
    }

    const luxonStartDate = unpackToDate(startDate);
    const luxonOccurrence = unpackToDate(occurrence);

    if (frequencyValue === FREQUENCY_ENUM.EVERY_SECOND_DAY) {
      return occurrenceIndex % 2 === 0;
    }

    if (frequencyValue === FREQUENCY_ENUM.EVERY_FORTNIGHT) {
      const weekDiff = (luxonOccurrence.year === luxonStartDate.year) ? (
        luxonOccurrence.weekNumber - luxonStartDate.weekNumber
      ) : (
        luxonOccurrence.weekNumber + luxonStartDate.weeksInWeekYear - luxonStartDate.weekNumber
      );

      return weekDiff % 2 === 0;
    }

    if (frequencyValue === FREQUENCY_ENUM.EVERY_SECOND_MONTH) {
      const monthDiff = (luxonOccurrence.year === luxonStartDate.year) ? (
        luxonOccurrence.month - luxonStartDate.month
      ) : (
        luxonOccurrence.month + 12 - luxonStartDate.month
      );

      return monthDiff % 2 === 0;
    }

    if (frequencyValue === FREQUENCY_ENUM.EVERY_SATURDAY) {
      return unpackToDate(occurrence).weekdayLong.toUpperCase().slice(0, 2) === 'SA';
    }

    if (frequencyValue === FREQUENCY_ENUM.EVERY_SUNDAY) {
      return unpackToDate(occurrence).weekdayLong.toUpperCase().slice(0, 2) === 'SU';
    }

    return true;
  }
}