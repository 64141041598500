import { gql } from '@apollo/client';

export const DASHBOARD_WIDGET_GET_PROGRAMS = gql`
  query DashBoardWidgetGetPrograms {
    vm {
      programs {
        programId
        name
        dateSuspended
        activities {
          activityId
          name
        }
      }
    }
  }
`;

export const DASHBOARD_WIDGET_GET_TAGS = gql`
  query DashBoardWidgetGetTags {
    vm {
      activityTags {
        activityTagId
        name
      }
    }
  }
`;

export const DASHBOARD_WIDGET_GET_FIELDS = gql`
  query DashboardWidgetGetFields {
    vm {
      allFields(type: "numeric") {
        fieldId
        name
      }
    }
  }
`;
