import React, { useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import { StringParam, useQueryParam } from 'use-query-params';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { PROGRAM_MANAGEMENT_GET_SINGLE_PROGRAM_BASIC_INFO } from '../program-management.gql';
import { useProgramManagementContext } from '../ProgramManagementContext';
import {
  ProgramManagementGetSingleProgramBasicInfo,
  ProgramManagementGetSingleProgramBasicInfoVariables,
} from '../__generated__/ProgramManagementGetSingleProgramBasicInfo';
import { EditProgramForm } from './EditProgramForm';
import { ProgramEditorSkeleton } from './ProgramEditorSkeleton';
import { ProgramFormHeader } from './ProgramFormHeader';

const ProgramEditor = () => {
  const [selectedProgramId] = useQueryParam('pid', StringParam);

  const { theme } = useCampfireTheme();
  const { compact } = useProgramManagementContext();

  const { data: getSingleProgramBasicInfoData, loading: getSingleProgramBasicInfoLoading } = useCampfireQuery<
    ProgramManagementGetSingleProgramBasicInfo,
    ProgramManagementGetSingleProgramBasicInfoVariables
  >(PROGRAM_MANAGEMENT_GET_SINGLE_PROGRAM_BASIC_INFO, {
    options: {
      variables: {
        programId: selectedProgramId,
      },
    },
  });

  const selectedProgramBasicInfo = useMemo(() => getSingleProgramBasicInfoData?.vm.program || undefined, [
    getSingleProgramBasicInfoData,
  ]);

  return (
    <Grid
      item
      xs
      style={{
        display: 'flex',
        flex: '1 1 auto',
        borderLeft: !compact ? `1px solid ${theme.color.grey.border}` : 'none',
      }}
    >
      {getSingleProgramBasicInfoLoading ? (
        <ProgramEditorSkeleton />
      ) : (
        <Box>
          <Box>
            <ProgramFormHeader selectedProgram={selectedProgramBasicInfo} />
          </Box>
          <Box>
            <EditProgramForm programBasicInfo={selectedProgramBasicInfo} />
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export { ProgramEditor };
