import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { unpackToDateTime } from '@campfire/hot-date';
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid';
import { CommonRequiringActionWidgetProps } from '../../../shared';
import { VolunteerProfile } from '../../../../../../../../../common/AvatarName';
import { GET_WIDGET_ACTIVITY_WAITLISTINGS } from './activity-waitlisting-widget-model.gql';
import { useCampfireQuery } from '../../../../../../../../../global/network/useCampfireQuery';
import { ActivityNameAndPriority } from '../../../../../../../../program-manager/volunteers/common/ActivityInfo';
import { useActivityWaitlistingActions, WaitlistActions } from './ActivityWaitlistingActions';
import { Application } from '../../../../../../../../program-manager/volunteers/volunteers-applications/VolunteersApplicationsScreen';
import {
  GetWidgetActivityWaitlistings,
  GetWidgetActivityWaitlistingsVariables,
} from './__generated__/GetWidgetActivityWaitlistings';
import { WaitlistVolunteerMenuButton } from '../../../../../../../../program-manager/volunteers/waitlist-volunteers/WaitlistVolunteerMenu';
import { WaitlistRemovingMessage } from '../../../../../../../../program-manager/volunteers/waitlist-volunteers/WaitlistRemovingMessage';
import { WidgetTypes } from '../../../../dashboard-widget-setting/dashboard-widget-model';

export const ActivityWaitlistingRAWidget = (props: CommonRequiringActionWidgetProps) => {
  const { filters, range } = props;
  const { programIds, activityIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };
  const [selectedWaitlisting, setSelectedWaitlisting] = React.useState<Application>();
  const [showRemoveWaitlistDialog, setShowRemoveWaitlistDialog] = useState<boolean>(false);
  const { onAction, onRemoveWaitlist } = useActivityWaitlistingActions();

  const { data, refetch, loading } = useCampfireQuery<
    GetWidgetActivityWaitlistings,
    GetWidgetActivityWaitlistingsVariables
  >(GET_WIDGET_ACTIVITY_WAITLISTINGS, {
    options: {
      variables: {
        programIds: programIds.length > 0 ? programIds : null,
        activityIds: activityIds.length > 0 ? activityIds : null,
        range: range,
        widgetType: WidgetTypes.REQUIRING_ACTION,
      },
    },
  });

  const handleActionSuccess = () => {
    if (refetch) refetch();
  };

  const waitlists =
    data?.vm.activityWaitlistings
      .filter(
        (waitlist) =>
          !waitlist.dateRemoved &&
          waitlist.activity.isActive &&
          !waitlist.activity.program.dateDeleted &&
          !waitlist.activity.program.dateSuspended &&
          !waitlist.volunteer.dateDeactivated &&
          !waitlist.volunteer.flagging?.dateFlagged
      )
      .map((waitlist) => ({
        id: waitlist.activityWaitlistingId,
        program: waitlist.activity.program.name,
        profile: waitlist.volunteer.profile,
        volunteerId: waitlist.volunteer.volunteerId,
        activityName: waitlist.activity.name,
        activityId: waitlist.activity.activityId,
        activityPriority: waitlist.activity.priority,
        programId: waitlist.activity.program.programId,
        volunteerName: `${waitlist.volunteer.profile.preferredName} ${waitlist.volunteer.profile.lastName}`,
        dateAdded: unpackToDateTime(waitlist.dateAdded),
      })) || [];

  const volunteerText = selectedWaitlisting
    ? `${selectedWaitlisting.profile.preferredName} ${selectedWaitlisting.profile.lastName}`
    : `test`;
  const activityText = selectedWaitlisting ? selectedWaitlisting.activityName : `test`;
  const title = `Remove: ${volunteerText} from ${activityText}`;

  const columns: GridColumns = [
    {
      field: 'volunteerName',
      headerName: 'Volunteer',
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <VolunteerProfile
            inTable
            noTrim
            {...row.profile}
            name={`${row.profile.preferredName} ${row.profile.lastName}`}
          />
        );
      },
    },
    {
      field: 'program',
      headerName: 'Program',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 200,
    },
    {
      field: 'activityName',
      headerName: 'Activity',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 200,
      renderCell: ({ row }: any) => {
        return <ActivityNameAndPriority name={row.activityName} priority={row.activityPriority} />;
      },
    },
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 200,
      renderCell: ({ row }: any) => row.dateAdded.toFormat('dd/MM/y'),
    },
    {
      field: '',
      disableColumnMenu: true,
      headerName: '',
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <WaitlistVolunteerMenuButton
            onAction={(action: WaitlistActions) => {
              if (action === 'withdraw') {
                setSelectedWaitlisting(row);
                setShowRemoveWaitlistDialog(true);
                return;
              }
              onAction(action, row.id, () => handleActionSuccess());
              onAction(action, row.id, () => handleActionSuccess());
            }}
            bulk={false}
          />
        );
      },
    },
  ];

  const handleRemoveSuccess = () => {
    if (refetch) refetch();
    setShowRemoveWaitlistDialog(false);
  };

  return (
    <Box width='100%' height='100%'>
      <DataGrid columns={columns} rows={waitlists} loading={loading} autoPageSize disableSelectionOnClick />
      <WaitlistRemovingMessage
        open={showRemoveWaitlistDialog}
        onClose={() => {
          setShowRemoveWaitlistDialog(false);
        }}
        onRemove={(message: string) =>
          selectedWaitlisting ? onRemoveWaitlist(selectedWaitlisting.id, message, handleRemoveSuccess) : null
        }
        title={title}
        bottomText={`The reason for removal will be sent to the volunteer after clicking the ’Remove’ button.`}
        warningText='You are removing this person from the waitlist'
      />
    </Box>
  );
};
