import LuxonUtils from '@date-io/luxon';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import 'emoji-mart/css/emoji-mart.css';
import jss from 'jss';
import preset from 'jss-preset-default';
import React from 'react';
import ReactDOM from 'react-dom';
import { JssProvider, SheetsRegistry } from 'react-jss';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { App } from './App';
import { AnalyticsService } from './global/analytics/AnalyticsService';
import { campfireTheme, CampfireThemeContextProvider } from './theme/src';

const styles = {
  '@global': {
    body: {
      margin: '0',
      padding: '0',
      fontFamily: `'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    code: {
      fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`,
    },
    'input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    // '*::-webkit-scrollbar': {
    //   width: '0.5em',
    // },
    // '*::-webkit-scrollbar-track': {
    //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    // },
    // '*::-webkit-scrollbar-thumb': {
    //   backgroundColor: 'rgba(0, 0, 0, 0.3)',
    //   outline: '1px solid rgba(0, 0, 0, 0.3)',
    //   borderRadius: '0.25em',
    // },
  },
};

const setupJss = () => {
  jss.setup(preset());
  const sheetsRegistry = new SheetsRegistry();
  const globalStyleSheet = jss.createStyleSheet(styles).attach();
  sheetsRegistry.add(globalStyleSheet);
  return sheetsRegistry;
};
const sheets = setupJss();
document.addEventListener('wheel', () => {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
});
AnalyticsService.init();

console.log(`Running Volaby Version: ${process.env.REACT_APP_VOLABY_VERSION}`);

ReactDOM.render(
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <JssProvider registry={sheets}>
        <CampfireThemeContextProvider value={campfireTheme}>
          <ThemeProvider theme={campfireTheme}>
            <MuiThemeProvider theme={campfireTheme}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <App />
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </ThemeProvider>
        </CampfireThemeContextProvider>
      </JssProvider>
    </QueryParamProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
