import { gql } from '@apollo/client';

export const GET_ALL_MEASURABLES = gql`
  query GetAllMeasurables($startDate: Date!, $endDate: Date!, $volId: String!) {
    orgName
    vm {
      volunteer(volunteerId: $volId) {
        programs {
          programId
        }
        activityEnrolments {
          activity {
            activityId
          }
        }
      }
      activityStatistics(startDate: $startDate, endDate: $endDate) {
        ...ImpactDashboardActivityStatistics
      }
      programs {
        ...ImpactDashboardProgram
      }
      activities(includeInactiveActivities: true) {
        ...ImpactDashboardActivity
      }
    }
  }

  fragment ImpactDashboardProgram on VOLUNTEER_ProgramType {
    programId
    name
    activityStatistics(startDate: $startDate, endDate: $endDate) {
      ...ImpactDashboardActivityStatistics
    }
    dateSuspended
  }

  fragment ImpactDashboardActivity on VOLUNTEER_ActivityType {
    activityId
    name
    startTime
    endTime
    endDate
    isActive
    isSuspended
    closedActivity {
      closedActivityId
    }
    program {
      programId
      name
      dateSuspended
    }
    activityStatistics(startDate: $startDate, endDate: $endDate) {
      ...ImpactDashboardActivityStatistics
    }
  }

  fragment ImpactDashboardActivityStatistics on VOLUNTEER_ActivityStatisticsType {
    deliveryStatistics {
      ...ImpactDashboardDeliveryStatistic
    }
    activityReportsStatistics {
      ...ImpactDashboardActivityReportStatistic
    }
    uniqueVolunteers {
      volunteerId
    }
  }

  fragment ImpactDashboardDeliveryStatistic on VOLUNTEER_ActivityDeliveryStatisticsType {
    missed
    completed
    cancelled
  }

  fragment ImpactDashboardActivityReportStatistic on VOLUNTEER_ActivityReportsStatisticsType {
    startDate
    endDate
    sessionReportStatistics {
      ...ImpactDashboardSessionReportStatistic
    }
  }

  fragment ImpactDashboardSessionReportStatistic on VOLUNTEER_SessionReportStatisticsType {
    startDate
    endDate
    operationalHours
    volunteeringHours
    volunteersAttended
    numericFieldStatistics {
      ...ImpactDashboardNumericFieldStatistic
    }
  }

  fragment ImpactDashboardNumericFieldStatistic on VOLUNTEER_SessionReportNumericFieldStatisticType {
    fieldId
    fieldName
    numericValue
    isHero
  }
`;
