import { FormControlLabel, Grid, Radio } from '@material-ui/core';
import React from 'react';
import { TaskFormItem_VOLUNTEER_TaskItemFieldType_field_VOLUNTEER_MultipleChoiceTaskFieldType as MultiplechoiceFieldType } from '../../../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';
import { DropItemTrigger } from './DropItemTrigger';

interface MultipleChoiceFieldPreviewProps {
  field: MultiplechoiceFieldType;
  isDragging: boolean;
}

export function MultipleChoiceFieldPreview({ field, isDragging }: MultipleChoiceFieldPreviewProps) {
  if (!field.dynamic || isDragging) {
    return (
      <Grid container direction='column'>
        {field.multipleChoiceOptions.map((option) => {
          const optionId = option.multipleChoiceFieldOptionId;
          return (
            <Grid item key={optionId} style={{ overflow: 'hidden', maxWidth: '100%' }}>
              <FormControlLabel control={<Radio disabled />} label={option.name} />
            </Grid>
          );
        })}
      </Grid>
    );
  }
  return (
    <Grid container direction='column'>
      {field.multipleChoiceOptions.map((option) => {
        const optionId = option.multipleChoiceFieldOptionId;
        return (
          <Grid item key={optionId} style={{ overflow: 'hidden', maxWidth: '100%' }}>
            <DropItemTrigger optionId={optionId} name={option.name} />
          </Grid>
        );
      })}
    </Grid>
  );
}
