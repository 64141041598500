import { useSnackbar } from '../../../../../../../../../global/config/useSnackbar';
import {
  useApproveActivityApplicationFetch,
  useRejectActivityApplicationFetch,
  useWaitlistIncomingVolunteerFetch,
} from '../../../../../../../../program-manager/activity-management/activity-form-v2/activity-form-actions-v2';

export type ApplicationActions = 'add' | 'remove' | 'moveToWaitlist';

export const useActivityApplicationActions = () => {
  const { setSnackbar } = useSnackbar();
  const moveToWaitlist = useWaitlistIncomingVolunteerFetch();
  const approveActivityApplication = useApproveActivityApplicationFetch();
  const removeApplication = useRejectActivityApplicationFetch();
  const onAddToTeam = (id: string, handleSuccess?: () => void) => {
    approveActivityApplication
      .run({
        activityApplicationId: id,
      })
      .then(() => {
        if (handleSuccess) {
          setTimeout(handleSuccess, 0);
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: 'Volunteer added to team successfully',
        });
      });
  };

  const onRemoveApplication = (selectedApplication: string, handleSuccess?: () => void) => {
    removeApplication
      .run({
        activityApplicationId: selectedApplication,
      })
      .then(() => {
        if (handleSuccess) {
          setTimeout(handleSuccess, 0);
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: 'Application removed',
        });
      });
  };

  const onMovetoWaitlist = (id: string, handleSuccess?: () => void) => {
    moveToWaitlist
      .run({
        activityApplicationId: id,
      })
      .then(() => {
        if (handleSuccess) {
          setTimeout(handleSuccess, 0);
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: 'Waitlisted application successfully',
        });
      });
  };

  const onAction = (action: ApplicationActions, id: string, handleSuccess?: () => void) => {
    switch (action) {
      case 'add':
        onAddToTeam(id, handleSuccess);
        break;

      case 'moveToWaitlist':
        onMovetoWaitlist(id, handleSuccess);
        break;

      default:
        break;
    }
  };
  return {
    onAction,
    onRemoveApplication,
  };
};
