import { DraggableList, DraggableListItem, DraggableListItemHandle } from '@campfire/draggable-list';
import { Field } from '@campfire/field';
import { ButtonBase, Grid, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Close, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { FieldArrayRenderProps, useFormikContext } from 'formik';
import React, { memo } from 'react';

import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ReportTypeFormValues, ReportTypeItemFieldValue, ReportTypeItemValue } from './manage-report-type-model';
import { getFieldTypeLabel } from './common/FieldTypeLabel';

interface ReportTypeFieldsEditorProps {
  arrayHelpers: FieldArrayRenderProps;
  setOpenEditFieldDialog: (x: boolean) => void;
  setSelectedField: (field: ReportTypeItemFieldValue) => void;
}

const useStyles = () => {
  const { theme, isMobile } = useCampfireTheme();
  return makeStyles({
    listItem: {
      border: `solid rgba(255, 255, 255, 0) 1px`,
      borderBottom: `1px solid ${theme.color.grey.border}`,
      padding: 0,
      display: 'flex',
      '&:hover': {
        border: `solid ${theme.palette.grey[400]} 1px`,
        borderRadius: 5,
      },
      '&:hover $listItemName': {
        background: fade(theme.palette.primary.light, 0.1),
      },
      '&:hover $editIcon': {
        visibility: 'visible',
      },
      '&:hover $deleteIcon': {
        visibility: 'visible',
      },
      '&:hover $fieldItemHandle': {
        visibility: 'visible',
        '&:hover': {
          color: theme.palette.action.active,
        },
      },
      '&:hover $headingItemHandle': {
        color: theme.palette.action.active,
      },
    },
    listItemName: {
      margin: 0,
      display: 'flex',
    },
    editIcon: {
      color: theme.palette.action.active,
      visibility: isMobile ? undefined : 'hidden',
    },
    deleteIcon: {
      color: theme.palette.error.main,
      visibility: isMobile ? undefined : 'hidden',
    },
    fieldItemHandle: {
      color: theme.palette.action.hover,
      visibility: isMobile ? undefined : 'hidden',
    },
    headingItemHandle: {
      color: theme.palette.action.hover,
    },
    headingInput: {
      ...theme.typography.h6,
      color: '#000',
    },
  })();
};

const ReportTypeSingleFieldEditor = ({
  item,
  index,
  arrayHelpers,
  setOpenEditFieldDialog,
  setSelectedField,
}: {
  item: ReportTypeItemValue;
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  setOpenEditFieldDialog: (x: boolean) => void;
  setSelectedField: (field: ReportTypeItemFieldValue) => void;
}) => {
  const classes = useStyles();
  const { theme } = useCampfireTheme();

  return (
    <DraggableListItem id={item.listId} index={index} key={item.listId} className={classes.listItem}>
      <Grid container style={{ padding: 0, display: 'flex' }}>
        <Grid item container spacing={2} xs style={{ margin: 0, padding: 0, flex: 1, display: 'flex' }}>
          <Grid
            item
            container
            direction='column'
            justify='center'
            style={{ padding: 0, width: 'auto', borderRight: `1px solid ${theme.color.grey.border}` }}
          >
            <DraggableListItemHandle
              className={
                item.__typename === 'VOLUNTEER_ReportTypeHeadingType'
                  ? classes.headingItemHandle
                  : classes.fieldItemHandle
              }
            />
          </Grid>
          <Grid
            item
            xs
            container
            justify='space-between'
            className={classes.listItemName}
            style={{ padding: 0, flex: 1 }}
          >
            <Grid item container xs direction='column' justify='center' style={{ padding: 8 }}>
              <Grid item style={{ minWidth: 150 }}>
                {item.__typename === 'VOLUNTEER_ReportTypeHeadingType' ? (
                  <Field
                    slow
                    name={`items[${index}].heading`}
                    label=''
                    variant='standard'
                    multiline
                    InputProps={{
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                ) : (
                  <Typography variant={'subtitle2'}>{item.field.name}</Typography>
                )}
              </Grid>
            </Grid>

            {item.__typename !== 'VOLUNTEER_ReportTypeHeadingType' ? getFieldTypeLabel(item.field.__typename) : null}
          </Grid>
        </Grid>
        <Grid item container direction='column' justify='center' style={{ padding: 0, width: 'auto', display: 'flex' }}>
          {item.__typename === 'VOLUNTEER_ReportTypeHeadingType' ? (
            <ButtonBase style={{ flex: 1, padding: 8 }} onClick={() => arrayHelpers.remove(index)}>
              <Close className={classes.deleteIcon} />
            </ButtonBase>
          ) : (
            <ButtonBase
              style={{ flex: 1, padding: 8 }}
              onClick={() => {
                setSelectedField(item);
                setOpenEditFieldDialog(true);
              }}
            >
              <Edit className={classes.editIcon} />
            </ButtonBase>
          )}
        </Grid>
      </Grid>
    </DraggableListItem>
  );
};

const ReportTypeFieldsEditor = memo((props: ReportTypeFieldsEditorProps) => {
  const { setOpenEditFieldDialog, arrayHelpers, setSelectedField } = props;
  const formik = useFormikContext<ReportTypeFormValues>();
  const { items } = formik.values;

  return (
    <DraggableList
      onReorder={(startIdx, endIdx, reorder) => {
        if (formik) {
          formik.setFieldValue('items', reorder(items, startIdx, endIdx));
        }
      }}
    >
      {items.map((item, index) => {
        return (
          <ReportTypeSingleFieldEditor
            key={item.listId}
            item={item}
            index={index}
            arrayHelpers={arrayHelpers}
            setOpenEditFieldDialog={setOpenEditFieldDialog}
            setSelectedField={setSelectedField}
          />
        );
      })}
    </DraggableList>
  );
});

export { ReportTypeFieldsEditor };
