import React, { useEffect, useState } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { MyShiftScreen } from './MyShifts/MyShiftScreen';
import { AllActivitiesScreen } from './AllActivities/AllActivitiesScreen';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { useUser } from '../../../global/auth/useUser';
import {
  GetVolunteerEnrolmentsCount,
  GetVolunteerEnrolmentsCountVariables,
} from './ActivityQueries/__generated__/GetVolunteerEnrolmentsCount';
import { GET_VOLUNTEER_ENROLMENTS_COUNT } from './ActivityQueries/get-volunteer-enrolments.gql';
import { CommonScreenSkeleton } from '../../../common/CommonScreenSkeleton';
import { ActivityContext } from './ActivityContext';
import { Page } from '../../../global/components';
import { ActivitiesTutorialDialog } from './ActivitiesTutorialDialog';
import { AllProgramsScreen } from './AllPrograms/AllProgramsScreen';

export const ActivitiesInnerScreen = () => {
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();

  const { data: activityEnrolments, loading } = useCampfireQuery<
    GetVolunteerEnrolmentsCount,
    GetVolunteerEnrolmentsCountVariables
  >(GET_VOLUNTEER_ENROLMENTS_COUNT, {
    options: {
      variables: {
        volunteerId,
      },
    },
  });

  const hasActivityEnrolments =
    activityEnrolments?.vm.volunteer?.activityEnrolments &&
    activityEnrolments?.vm.volunteer?.activityEnrolments.length > 0;

  useEffect(() => {
    if (hasActivityEnrolments && !query.tab) setQuery({ tab: 'mine' });
  }, [hasActivityEnrolments, query]);

  if (loading) {
    return <CommonScreenSkeleton />;
  }

  if (query.tab === 'programs') {
    return (
      <ActivityContext>
        <AllProgramsScreen />
      </ActivityContext>
    );
  }

  if (query.tab === 'mine') {
    return (
      <ActivityContext>
        <MyShiftScreen />
      </ActivityContext>
    );
  }

  return (
    <ActivityContext>
      <AllActivitiesScreen />
    </ActivityContext>
  );
};

export const ActivitiesV2Screen = () => {
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      <ActivitiesInnerScreen />
      <ActivitiesTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
        }}
      />
    </Page>
  );
};
