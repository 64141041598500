import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, FormControl, InputLabel, Select, OutlinedInput, MenuItem, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import React, { useState, useEffect } from 'react';

export const impactDashboardDetailedInitialStartDate = DateTime.local().minus({ days: 7 });
export const impactDashboardDetailedInitialEndDate = DateTime.local();

const ImpactDashboardDatePicker = ({
  value,
  onChange,
  label,
  id,
}: {
  value: DateTime;
  onChange: (value: DateTime) => void;
  label: string;
  id: string;
}) => {
  return (
    <DatePicker
      style={{ marginTop: 0, marginBottom: 0 }}
      variant='inline'
      format='dd/MM/yyyy'
      margin='normal'
      value={value}
      id={id}
      label={label}
      inputVariant='outlined'
      onChange={(date) => date && onChange(date)}
    />
  );
};

type Period = 'all-time' | 'today' | 'yesterday' | 'past-week' | 'past-month' | 'past-year' | 'custom';

const PERIOD_OPTIONS: Array<{ label: string; value: Period }> = [
  { label: 'All time', value: 'all-time' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Past week', value: 'past-week' },
  { label: 'Past month', value: 'past-month' },
  { label: 'Past year', value: 'past-year' },
  { label: 'Custom', value: 'custom' },
];

interface Props {
  endDate: DateTime;
  setEndDate: (value: DateTime) => void;
  setStartDate: (value: DateTime) => void;
  startDate: DateTime;
}

const ImpactDashboardDetailedDateFilter = (props: Props) => {
  const { endDate, setEndDate, setStartDate, startDate } = props;
  const [period, setPeriod] = useState<Period>(PERIOD_OPTIONS[3].value);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPeriod(event.target.value as Period);
  };

  useEffect(() => {
    if (period === 'all-time') {
      setStartDate(DateTime.local(2005));
      setEndDate(DateTime.local());
      return;
    }

    if (period === 'today') {
      setStartDate(DateTime.local().startOf('day'));
      setEndDate(DateTime.local());
      return;
    }

    if (period === 'yesterday') {
      setStartDate(
        DateTime.local()
          .minus({ day: 1 })
          .startOf('day')
      );
      setEndDate(
        DateTime.local()
          .minus({ day: 1 })
          .endOf('day')
      );
      return;
    }

    if (period === 'past-week') {
      setStartDate(
        DateTime.local()
          .minus({ days: 7 })
          .startOf('day')
      );
      setEndDate(DateTime.local());
      return;
    }

    if (period === 'past-month') {
      setStartDate(
        DateTime.local()
          .minus({ days: 31 })
          .startOf('day')
      );
      setEndDate(DateTime.local());
      return;
    }

    if (period === 'past-year') {
      setStartDate(
        DateTime.local()
          .minus({ days: 365 })
          .startOf('day')
      );
      setEndDate(DateTime.local());
    }
  }, [period]);

  return (
    <Box display='flex'>
      {period === 'custom' ? (
        <Box display='flex' alignContent='center' alignItems='center' marginTop={'-24px'}>
          <Box marginRight={1}>
            <ImpactDashboardDatePicker label='Start Date' id='start-date' value={startDate} onChange={setStartDate} />
          </Box>
          <Box marginRight={1}>
            <ImpactDashboardDatePicker label='End Date' id='end-date' value={endDate} onChange={setEndDate} />
          </Box>
          <Box marginRight={1}>
            <TitularTooltip description='Back to past month'>
              <IconButton onClick={() => setPeriod('past-month')}>
                <Clear />
              </IconButton>
            </TitularTooltip>
          </Box>
        </Box>
      ) : (
        <Box width={196}>
          <FormControl fullWidth variant='outlined'>
            <InputLabel id='impact-dashboard-date-range-select-label'>{'Period'}</InputLabel>
            <Select
              labelId='impact-dashboard-date-range-select-label'
              id='impact-dashboard-date-range-select'
              value={period}
              onChange={handleChange}
              input={
                <OutlinedInput
                  labelWidth={48}
                  name='impact-dashboard-date-range'
                  id='impact-dashboard-date-range-select'
                />
              }
            >
              {PERIOD_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export { ImpactDashboardDetailedDateFilter };
