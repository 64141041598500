import React, { ReactElement, memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, SvgIcon } from '@material-ui/core';
import {
  AccessTime,
  ArrowDropDownCircle,
  AttachFile,
  CheckBox,
  StarBorder,
  ThumbsUpDown,
  Title,
} from '@material-ui/icons';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ReactComponent as HashtagIcon } from '../../../../assets/hash-24px.svg';
import { FieldType } from './report-type-form-sections/report-type-form-types';

function getFieldTypeLabelAndIcon(type: FieldType) {
  const fieldTypeLabels = {
    VOLUNTEER_CheckboxFieldType: <FieldTypeLabelAndIcon name='Checklist Item' Icon={<CheckBox />} />,
    VOLUNTEER_DropdownFieldType: <FieldTypeLabelAndIcon name='Dropdown' Icon={<ArrowDropDownCircle />} />,
    VOLUNTEER_RatingFieldType: <FieldTypeLabelAndIcon name='Rating' Icon={<StarBorder />} />,
    VOLUNTEER_BooleanFieldType: <FieldTypeLabelAndIcon name='Thumbs Up/Down' Icon={<ThumbsUpDown />} />,
    VOLUNTEER_TimeFieldType: <FieldTypeLabelAndIcon name='Time' Icon={<AccessTime />} />,
    VOLUNTEER_TextFieldType: <FieldTypeLabelAndIcon name='Text' Icon={<Title />} />,
    VOLUNTEER_AttachmentFieldType: <FieldTypeLabelAndIcon name='Attachment' Icon={<AttachFile />} />,
    VOLUNTEER_NumericFieldType: (
      <FieldTypeLabelAndIcon
        name='Number'
        Icon={
          <SvgIcon>
            <HashtagIcon />
          </SvgIcon>
        }
      />
    ),
  };
  return fieldTypeLabels[type];
}

interface FieldTypeLabelProps {
  name: string;
  Icon: ReactElement;
}

const FieldTypeLabelAndIcon = memo((props: FieldTypeLabelProps) => {
  const { name, Icon } = props;
  const { theme } = useCampfireTheme();
  const classes = makeStyles({
    field: {
      margin: 0,
      width: 'auto',
      minWidth: 200,
    },
    icon: {
      color: theme.color.grey.neutral300,
      width: 16,
      height: 16,
    },
  })();

  return (
    <Grid item container justify='flex-start' alignItems='center' className={classes.field}>
      <Grid item style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}>
        {React.cloneElement(Icon, { className: classes.icon })}
      </Grid>
      <Grid item>
        <Typography variant='caption' color='inherit' style={{ color: theme.color.grey.neutral300 }}>
          {name}
        </Typography>
      </Grid>
    </Grid>
  );
});

export { FieldTypeLabelAndIcon, getFieldTypeLabelAndIcon };
