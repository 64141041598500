import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router';
import AppErrorBoundary from '../../global/app-shell/AppErrorBoundary';
import { CampfireVersionContext } from '../../global/config/CampfireVersionContext';
import { signInPath } from '../../global/public-shell/sign-in-path';

type Props = {
  isAuthorized: boolean;
};

const MiniRoute = ({ path, component: Component, isAuthorized, ...rest }: RouteProps & Props) => {
  return (
    <Route
      {...rest}
      path={path}
      render={(props) =>
        isAuthorized ? (
          <CampfireVersionContext.Consumer>
            {(versionContext) => (
              <AppErrorBoundary versionContext={versionContext}>
                {Component ? <Component {...props} /> : null}
              </AppErrorBoundary>
            )}
          </CampfireVersionContext.Consumer>
        ) : (
          <Redirect to={signInPath} />
        )
      }
    />
  );
};

export default MiniRoute;
