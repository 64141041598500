import { Box, Grid, Input, MenuItem, Select, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { PieChartOutlined as PieChartOutlinedIcon, Notifications as NotificationIcon } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

const useTabsClasses = makeStyles(() =>
  createStyles({
    indicator: {
      bottom: 15,
    },
  })
);
const useTabClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      minWidth: 72,
      textTransform: 'none',
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      '& [class*="MuiSvgIcon-root"]': {
        marginRight: 4,
        marginBottom: '0 !important',
      },
    },
  })
);

export type MyElementsTabOption = 'Bulletin' | 'Impact';
export const TAB_ITEMS: MyElementsTabOption[] = ['Bulletin', 'Impact'];

interface MyElementsTabsProps {
  selectedTabIndex: number;
  setSelectedTabIndex: (value: number) => void;
  bulletinAlerts?: number;
}
const MyElementsTabs = (props: MyElementsTabsProps) => {
  const { selectedTabIndex, setSelectedTabIndex, bulletinAlerts } = props;
  const tabs = TAB_ITEMS;
  const selectedTab = tabs[selectedTabIndex] ?? tabs[0];
  const {
    theme: {
      status: { red },
      palette: { primary },
    },
  } = useCampfireTheme();
  const handleChange = (_unusedEvent: React.ChangeEvent<{}>, newTabIndex: number) => {
    setSelectedTabIndex(newTabIndex);
  };

  const classes = useTabClasses();
  const tabsClasses = useTabsClasses();
  return selectedTab ? (
    <Box
      paddingLeft={3}
      paddingRight={2}
      paddingTop={2}
      display='flex'
      justifyContent='space-between'
      alignContent='center'
      alignItems='center'
    >
      <Tabs
        indicatorColor='primary'
        value={selectedTabIndex}
        onChange={handleChange}
        aria-label='my elements tabs'
        classes={tabsClasses}
      >
        {tabs.map((tabName) => (
          <Tab
            id={`myEleNavigationTab-${tabName}`}
            disableRipple
            disableTouchRipple
            classes={classes}
            label={
              tabName === 'Bulletin' && bulletinAlerts ? (
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Box mr={1}>{tabName}</Box>
                  <Box
                    bgcolor={red.medium}
                    width={20}
                    height={20}
                    borderRadius={10}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Typography
                      variant='caption'
                      style={{ color: primary.contrastText, fontSize: 10, fontWeight: 600 }}
                    >
                      {bulletinAlerts}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                tabName
              )
            }
            key={tabName}
            icon={
              tabName === 'Bulletin' ? (
                <NotificationIcon fontSize='small' />
              ) : tabName === 'Impact' ? (
                <PieChartOutlinedIcon fontSize='small' />
              ) : (
                <></>
              )
            }
          />
        ))}
      </Tabs>
    </Box>
  ) : null;
};

const useSelectInputStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: 13,
    },
    input: {
      backgroundColor: '#ffffff',
      position: 'relative',
      fontSize: 16,
      padding: 4,
      paddingRight: 26,
      paddingLeft: 12,
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
);

const MyElementsTabsMobile = (props: MyElementsTabsProps) => {
  const { selectedTabIndex, setSelectedTabIndex, bulletinAlerts } = props;
  const { theme } = useCampfireTheme();
  const inputStyles = useSelectInputStyles();
  const tabs = useMemo(() => {
    return TAB_ITEMS;
  }, [TAB_ITEMS.length]);
  const [value, setValue] = useState(tabs[selectedTabIndex]);

  useEffect(() => {
    setSelectedTabIndex(tabs.findIndex((tab) => tab === value) ?? 0);
  }, [value, tabs]);

  useEffect(() => {
    setValue(tabs[selectedTabIndex ?? 0]);
  }, [selectedTabIndex, TAB_ITEMS.length]);

  return (
    <Grid
      item
      container
      alignItems='center'
      justify='space-between'
      style={{ minHeight: 50, borderBottom: `1px solid ${theme.palette.grey[300]}`, paddingLeft: 8, paddingRight: 2 }}
    >
      <Grid item>
        <Select
          MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
          value={value}
          onChange={(event) => {
            const selectedValue = event.target.value as MyElementsTabOption;
            setValue(selectedValue);
          }}
          input={<Input classes={inputStyles} />}
          renderValue={(selected) => {
            const selectedValue = selected as MyElementsTabOption;
            const selectedTab = tabs.find((x) => x === selectedValue);
            return selectedTab === 'Bulletin' && bulletinAlerts
              ? `${selectedTab} (${bulletinAlerts} unread)`
              : selectedTab;
          }}
        >
          {tabs.map((tabItem) => (
            <MenuItem key={tabItem} value={tabItem}>
              {tabItem}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

interface MyElementsPersonalCardTabsWrapper {
  children: ReactNode;
  selectedTabIndex: number;
  setSelectedTabIndex: (value: number) => void;
  unreadBulletins?: number;
}

export const MyElementsPersonalCardTabsWrapper = (props: MyElementsPersonalCardTabsWrapper) => {
  const { children, selectedTabIndex, setSelectedTabIndex, unreadBulletins } = props;
  const { isXs } = useCampfireTheme();

  return (
    <>
      <Grid container item xs={12} justify='center'>
        {isXs ? (
          <MyElementsTabsMobile
            bulletinAlerts={unreadBulletins}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
        ) : (
          <MyElementsTabs
            bulletinAlerts={unreadBulletins}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
        )}
      </Grid>
      {children}
    </>
  );
};
