import React, { FunctionComponent } from 'react';
import { Box, Card } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';

export const TrafficLightCard: FunctionComponent<{ radius?: number; color?: string; cardProps?: CardProps }> = ({
  radius,
  children,
  color,
  cardProps,
}) => {
  const borderRadius = radius || 13;
  return (
    <Box position='relative'>
      <Card elevation={2} {...cardProps} style={{ borderRadius, ...(cardProps && cardProps.style) }}>
        <Box
          position='absolute'
          borderRadius={`${borderRadius}px 0 0 ${borderRadius}px`}
          top={0}
          left={0}
          width={13}
          height={1}
          style={{ backgroundColor: color }}
        />

        <Box paddingLeft={`${borderRadius}px`}>{children}</Box>
      </Card>
    </Box>
  );
};
