import React from 'react';

const hasDocument = typeof document !== 'undefined';
const vendorEvents = [
  {
    hidden: 'hidden',
    event: 'visibilitychange',
    state: 'visibilityState',
  },
  {
    hidden: 'webkitHidden',
    event: 'webkitvisibilitychange',
    state: 'webkitVisibilityState',
  },
  {
    hidden: 'mozHidden',
    event: 'mozvisibilitychange',
    state: 'mozVisibilityState',
  },
  {
    hidden: 'msHidden',
    event: 'msvisibilitychange',
    state: 'msVisibilityState',
  },
  {
    hidden: 'oHidden',
    event: 'ovisibilitychange',
    state: 'oVisibilityState',
  },
];

export const isSupported = hasDocument && Boolean(document.addEventListener);

export const visibility = (() => {
  if (!isSupported) {
    return null;
  }
  for (let i = 0; i < vendorEvents.length; i++) {
    const event = vendorEvents[i];
    if (event.hidden in document) {
      return event;
    }
  }
  return null;
})();

const getHandlerArgs = () => {
  // @ts-ignore
  const { hidden, state } = visibility;
  // @ts-ignore
  return [!document[hidden], document[state]];
};

interface PageVisibilityProps {
  onChange: (isVisible: boolean, visibilityState: string) => void;
}

interface PageVisibilityState {
  isSupported: boolean | null | { hidden: string; event: string; state: string };
}

// This component has been pulled from the npm package react-page-visibility
// The only change I made was to call handleVisibilityChange() on mount and some typescript stuff for the state and props
class PageVisibility extends React.Component<PageVisibilityProps, PageVisibilityState> {
  constructor(props: PageVisibilityProps) {
    super(props);

    this.state = {
      isSupported: isSupported && visibility,
    };
  }

  componentDidMount() {
    if (!this.state.isSupported) {
      return;
    }

    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.handleVisibilityChange(); // I added this here because the onChange callback was not being called on initial load
    if (visibility !== null) document.addEventListener(visibility.event, this.handleVisibilityChange);
  }

  componentWillUnmount() {
    if (!this.state.isSupported) {
      return;
    }
    if (visibility !== null) document.removeEventListener(visibility.event, this.handleVisibilityChange);
  }

  handleVisibilityChange() {
    if (typeof this.props.onChange === 'function') {
      // @ts-ignore
      this.props.onChange(...getHandlerArgs());
    }
    if (typeof this.props.children === 'function') {
      this.forceUpdate();
    }
  }

  render() {
    if (!this.props.children) {
      return null;
    }
    if (typeof this.props.children === 'function') {
      if (!this.state.isSupported) {
        return this.props.children();
      }
      return this.props.children(...getHandlerArgs());
    }

    return React.Children.only(this.props.children);
  }
}

// @ts-ignore
PageVisibility.displayName = 'PageVisibility';

export default PageVisibility;
