import { DraggableList, DraggableListItem, DraggableListItemHandle } from '@campfire/draggable-list';
import { Field } from '@campfire/field';
import { HoverText } from '@campfire/hover-link';
import { Select } from '@campfire/select';
import { ButtonBase, Collapse, Grid, Switch, Typography } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  fieldTypes,
  MappedFieldType,
} from '../../common/report-type-form-sections/report-type-form-items/edit-report-field-types';
import { SaveReportFieldValues } from '../report-field-types';

const fixedWidthSelectStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '180px',
      '& div': {
        width: '180px',
      },
    },
  })
);

export const ReportFieldFormContent = () => {
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<SaveReportFieldValues>();

  const { root: fixedWidthSelect } = fixedWidthSelectStyles();

  useEffect(() => {
    if (values.description) setShowDescription(true);
  }, [values]);

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm>
          <Field required autoFocus name='name' label='Field Name' variant='outlined' fullWidth />
        </Grid>
        <Grid item>
          <Select
            variant='outlined'
            name='__typename'
            label='Field Type'
            items={fieldTypes}
            renderFunction={(item: MappedFieldType) => item.name}
            valueFunction={(item: MappedFieldType) => item.type}
            InputProps={{
              disabled: !!values.fieldId,
            }}
            className={fixedWidthSelect}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showDescription}>
          <Field name='description' label='Field Description' multiline variant='outlined' fullWidth />
        </Collapse>
        {!showDescription ? (
          <HoverText variant='body2' color='primary' onClick={() => setShowDescription(true)}>
            Add description
          </HoverText>
        ) : null}
      </Grid>
      {values.__typename === 'VOLUNTEER_DropdownFieldType' ? (
        <>
          <FieldArray
            name='dropdownOptions'
            render={(arrayHelpers) => {
              if (!values?.dropdownOptions?.length)
                arrayHelpers.push({
                  name: 'New Option',
                  order: 0,
                  listId: uuidv4(),
                });
              return (
                <Grid item container direction='column'>
                  <Grid item xs>
                    {values?.dropdownOptions?.length ? (
                      <DraggableList
                        onReorder={(startIdx, endIdx, reorder) => {
                          setFieldValue('dropdownOptions', reorder(values?.dropdownOptions ?? [], startIdx, endIdx));
                        }}
                      >
                        {values.dropdownOptions.map((option, index) => (
                          <DraggableListItem
                            id={option.listId}
                            key={option.listId}
                            index={index}
                            style={{ border: '1px solid #000', padding: 9, borderRadius: 5, marginBottom: 8 }}
                          >
                            <Grid container alignItems='center' justify='space-between'>
                              <Grid item>
                                <DraggableListItemHandle />
                              </Grid>
                              <Grid item>
                                <Field
                                  slow
                                  name={`dropdownOptions[${index}].name`}
                                  label=''
                                  variant='standard'
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item>
                                {values?.dropdownOptions && values.dropdownOptions.length > 1 ? (
                                  <ButtonBase onClick={() => arrayHelpers.remove(index)}>
                                    <DeleteIcon color='secondary' />
                                  </ButtonBase>
                                ) : null}
                              </Grid>
                            </Grid>
                          </DraggableListItem>
                        ))}
                      </DraggableList>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <HoverText
                      variant='caption'
                      color='primary'
                      onClick={() =>
                        arrayHelpers.unshift({
                          name: 'New Option',
                          order: values?.dropdownOptions?.length ?? 0,
                          listId: uuidv4(),
                        })
                      }
                    >
                      Add dropdown option
                    </HoverText>
                  </Grid>
                </Grid>
              );
            }}
          />
          <Grid item container>
            <Grid item>
              <Switch
                color='primary'
                checked={values.isAllowMultiple || false}
                onChange={() => setFieldValue('isAllowMultiple', !values.isAllowMultiple)}
              />
            </Grid>
            <Grid item xs container direction='column'>
              <Typography display='inline' variant='subtitle2'>
                Allow multiple selections
              </Typography>
              <Typography display='inline' variant='caption'>
                This will allow volunteers to select multiple options for this dropdown.
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : null}
      <Grid item container style={{ paddingTop: '15px' }}>
        <Grid item>
          <Switch
            color='primary'
            checked={values.isHero || false}
            onChange={() => setFieldValue('isHero', !values.isHero)}
          />
        </Grid>
        <Grid item xs container direction='column'>
          <Typography display='inline' variant='subtitle2'>
            Assign as a Pinned Field
          </Typography>
          <Typography display='inline' variant='caption'>
            {`Pinned Fields are given more exposure on your volunteers' 'My Elements' page. Turn this on for a
          maximum of 3 fields that you want to highlight.`}
          </Typography>
        </Grid>
      </Grid>
      {values.__typename === 'VOLUNTEER_TextFieldType' ? (
        <Grid item container style={{ paddingTop: '15px' }}>
          <Grid item>
            <Switch
              color='primary'
              checked={values.isLongText || false}
              onChange={() => setFieldValue('isLongText', !values.isLongText)}
            />
          </Grid>
          <Grid item xs container direction='column'>
            <Typography display='inline' variant='subtitle2'>
              Assign as a Long Text field
            </Typography>
            <Typography display='inline' variant='caption'>
              {`Long Text fields will give volunteers extra space to input information`}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
