import { HoverText, HoverLink } from '@campfire/hover-link';
import { Grid, Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import React from 'react';
import { useParams, useLocation } from 'react-router';
import { useSupportContext } from '../SupportContext';

const CategoryViewerHeader = ({ categoryTitle }: { categoryTitle: string }) => {
  const { setInEditCategoryMode, isAdmin } = useSupportContext();
  const { categoryId } = useParams();
  const location = useLocation();

  return (
    <Grid container alignItems='center' spacing={2}>
      <Grid item container xs={12} md={10}>
        <HoverLink
          to={categoryId ? location.pathname.replace(`/${categoryId}`, '') : location.pathname}
          color='textPrimary'
          hoverColor='textPrimary'
          disableUnderline
        >
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>
            <KeyboardArrowLeft fontSize='large' style={{ fontWeight: 'bold', marginBottom: -6 }} />
            {categoryTitle}
          </Typography>
        </HoverLink>
      </Grid>

      <Grid item container justify='flex-end' xs={12} md={2}>
        <Grid item>
          {isAdmin ? (
            <HoverText
              data-track='resources-category-view-edit-category-button'
              variant='body2'
              color='primary'
              onClick={() => setInEditCategoryMode(true)}
            >
              {'Edit Category'}
            </HoverText>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { CategoryViewerHeader };
