import { Box } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { arrayHead } from '../../../../common/functions/array-head';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { INITIAL_REPORT_FIELD_FILTERS } from '../ReportManagementConsts';
import { ReportFieldDetail } from './report-fields-detail/ReportFieldDetail';
import { REPORT_MANAGEMENT_GET_REPORT_TYPE_FIELDS } from './report-fields-model.gql';
import { ReportFieldFilters } from './ReportFieldFilters';
import { ReportFieldsList } from './ReportFieldsList';
import { filterBySearch, filterByStatus, getReportTypesUsingField } from './ReportFieldUtils';
import { ReportManagementGetReportTypeFields } from './__generated__/ReportManagementGetReportTypeFields';

type ReportFieldsProps = {
  showFilters: boolean;
  searchInput: string;
};

export const ReportFields = (props: ReportFieldsProps) => {
  const { showFilters, searchInput } = props;
  const [filters, setFilters] = useState(INITIAL_REPORT_FIELD_FILTERS);

  const [query, setQuery] = useQueryParams({
    reportFieldId: StringParam,
  });

  const { isMobile } = useCampfireTheme();

  const {
    data: getReportTypeFieldsData,
    loading: getReportTypeFieldsDataLoading,
    refetch: getReportTypeFieldsRefetch,
  } = useCampfireQuery<ReportManagementGetReportTypeFields, undefined>(REPORT_MANAGEMENT_GET_REPORT_TYPE_FIELDS);

  const allReportTypes = getReportTypeFieldsData?.vm.allReportTypes || [];

  const reportFields = getReportTypeFieldsData?.vm.allFields || [];

  const reportFieldsWithReportTypes = useMemo(() => {
    return reportFields.map((field) => ({
      ...field,
      reportTypes: getReportTypesUsingField(allReportTypes, field.fieldId),
    }));
  }, [reportFields, allReportTypes]);

  const filteredReportFields = reportFieldsWithReportTypes
    .filter(filterByStatus(filters.status))
    .filter(filterBySearch(searchInput))
    .sort((a, b) => a.name.localeCompare(b.name));

  const selectedReportField = useMemo(() => {
    if (query.reportFieldId && filteredReportFields.length > 0)
      return filteredReportFields.find((field) => field?.fieldId === query.reportFieldId);
    return undefined;
  }, [filteredReportFields, query.reportFieldId]);

  const allActiveFieldOptions = useMemo(() => {
    return reportFields
      .filter((field) => !field.dateDeactivated)
      .map((field) => ({
        fieldId: field.fieldId,
        fieldName: field.name,
        __typename: field.__typename,
      }));
  }, [reportFields]);

  useEffect(() => {
    if (!isMobile) {
      if (filteredReportFields.length > 0 && !query.reportFieldId)
        setQuery({ reportFieldId: arrayHead(filteredReportFields)?.fieldId });
      if (
        filteredReportFields.length > 0 &&
        query.reportFieldId &&
        !filteredReportFields.some((reportField) => reportField.fieldId === query.reportFieldId)
      )
        setQuery({ reportFieldId: arrayHead(filteredReportFields)?.fieldId });
      if (reportFields.length && filteredReportFields.length === 0 && query.reportFieldId)
        setQuery({ reportFieldId: undefined });
    }
  }, [filteredReportFields, reportFields]);

  const onChangeFilter = (newFilter: any) => setFilters((currentFilters) => ({ ...currentFilters, ...newFilter }));

  if (isMobile)
    return (
      <Box width='100%' position='relative' display='flex' flexDirection='column' height='100%'>
        <Box padding='24px'>
          {query.reportFieldId && selectedReportField ? (
            <ReportFieldDetail
              reportField={selectedReportField}
              allActiveFieldOptions={allActiveFieldOptions}
              getReportFieldsRefetch={getReportTypeFieldsRefetch}
            />
          ) : (
            <>
              {showFilters && <ReportFieldFilters values={filters} onChange={onChangeFilter} />}
              <ReportFieldsList fields={filteredReportFields} reportFieldsLoading={getReportTypeFieldsDataLoading} />
            </>
          )}
        </Box>
      </Box>
    );

  return (
    <Box width='100%' position='relative' display='flex' flexDirection='row' height='100%' overflow='hidden'>
      <Box flexGrow={1} style={{ overflowY: 'auto' }} padding='0 60px 24px 60px'>
        {showFilters && <ReportFieldFilters values={filters} onChange={onChangeFilter} />}
        <ReportFieldsList fields={filteredReportFields} reportFieldsLoading={getReportTypeFieldsDataLoading} />
      </Box>
      {query.reportFieldId && selectedReportField && (
        <CollapsibleSideBar>
          <ReportFieldDetail
            reportField={selectedReportField}
            allActiveFieldOptions={allActiveFieldOptions}
            getReportFieldsRefetch={getReportTypeFieldsRefetch}
          />
        </CollapsibleSideBar>
      )}
    </Box>
  );
};
