import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Collapse, Fade, Grid, IconButton, ListItem, Typography } from '@material-ui/core';
import { Add, Check, Clear, Edit } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { StringParam, useQueryParam } from 'use-query-params';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';
import { SearchField } from '../../../../../common/inputs/SearchField';
import { ProfileHoverBlock } from '../../../../general/user-profile/hover-text/ProfileHoverBlock';
import { MultiSelectVolunteersDialogVolunteerType } from '../../../../program-manager/activities/activity-timeline/past/report/form/MultiSelectVolunteersDialog';
import { VolunteerDialogHoverBlock } from '../../../../program-manager/volunteer-common-profile/VolunteerDialogHoverBlock';
import { useProgramManagementContext } from '../../ProgramManagementContext';
import { AddVolunteersDialog } from '../dialogs/AddVolunteersDialog';
import { FormSectionSkeleton } from './FormSectionSkeleton';
import { LockedSectionContent } from './LockedSectionContent';

export interface ProgramManagementVol {
  volunteerId: string;
  profile: {
    profileId: string;
    userId: string;
    preferredName: string;
    lastName: string;
    avatarUrl: string | null;
  };
}
const ProgramVolunteersSectionDescription = () => (
  <Typography variant='body2' color='textSecondary' style={{ marginBottom: 8 }}>
    All Volunteers who are enrolled in this Program
  </Typography>
);

export const ProgramVolunteersSectionLocked = () => (
  <FormSectionV2 title='Volunteers' description={<ProgramVolunteersSectionDescription />}>
    <LockedSectionContent text='Please save Basic Info before adding volunteers' />
  </FormSectionV2>
);

export const ProgramVolunteersSection = () => {
  const {
    getActiveVolunteers,
    getActiveVolunteersData,
    getActiveVolunteersLoading,
    getActiveVolunteersRefetch,
    runSaveProgramVolunteers,
    saveProgramVolunteersIsLoading,
    compact,
  } = useProgramManagementContext();
  const [selectedProgramId] = useQueryParam('pid', StringParam);
  const [searchInput, setSearchInput] = useState<string>();
  const [editedTeam, setEditedTeam] = useState<ProgramManagementVol[]>();
  const [expandVolunteersSection, setExpandVolunteersSection] = useState<boolean>(false);

  const [inEditMode, setInEditMode] = useState(false);
  const [showAddVolunteerDialog, setShowAddVolunteerDialog] = useState(false);

  useEffect(() => {
    getActiveVolunteers({
      variables: {
        programId: selectedProgramId,
      },
    });
  }, [selectedProgramId]);

  const program = getActiveVolunteersData?.vm.program || undefined;

  const volunteers = useMemo(
    () => program?.activeVolunteers.sort((a, b) => (a.profile.lastName > b.profile.lastName ? 1 : -1)) || [],
    [program]
  );

  const volunteersList = useMemo(() => (inEditMode ? editedTeam : volunteers), [inEditMode, volunteers, editedTeam]);
  const filteredVolunteersList = useMemo(
    () =>
      volunteersList
        ?.filter((vol) =>
          searchInput
            ? vol.profile.preferredName
                .concat(` ${vol.profile.lastName}`)
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : true
        )
        .sort((a, b) => a.profile.preferredName.localeCompare(b.profile.preferredName)),
    [searchInput, volunteersList]
  );

  useEffect(() => setEditedTeam(volunteers), [volunteers]);

  const handleSuccess = () => {
    setInEditMode(false);
    if (getActiveVolunteersRefetch) getActiveVolunteersRefetch();
  };

  if (getActiveVolunteersLoading)
    return <FormSectionSkeleton title='Volunteers' description={<ProgramVolunteersSectionDescription />} />;

  return (
    <FormSectionV2
      title='Volunteers'
      description={<ProgramVolunteersSectionDescription />}
      search={
        <SearchField
          data-track='fs-prgMan-search-volunteers'
          placeholder='Search Volunteers'
          onChange={(e) => setSearchInput(e.target.value)}
          fullWidth={compact}
        />
      }
    >
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12} container justify='space-between' style={{ marginTop: '8px' }}>
          <Typography variant='subtitle2' color='textSecondary' style={{ paddingBottom: '4px' }}>
            {`${volunteersList?.length} ${volunteersList?.length === 1 ? 'volunteer' : 'volunteers'} ${
              compact ? '' : ' enrolled in this program'
            }`}
          </Typography>

          {!inEditMode ? (
            <HoverText
              data-track='fs-prgMan-edit-volunteers'
              variant='body2'
              color='primary'
              onClick={() => {
                setInEditMode(true);
                setExpandVolunteersSection(true);
              }}
              invertUnderlineBehaviour
            >
              Edit Volunteers
              <Edit style={{ fontSize: 12, marginLeft: 4 }} />
            </HoverText>
          ) : null}
        </Grid>
        {volunteers.length > 0 ? (
          <Grid item style={{ marginTop: '-12px' }}>
            {expandVolunteersSection ? (
              <HoverText
                variant='body2'
                color='primary'
                onClick={() => {
                  setInEditMode(false);
                  setExpandVolunteersSection(false);
                }}
              >
                Hide all volunteers
              </HoverText>
            ) : (
              <HoverText variant='body2' color='primary' onClick={() => setExpandVolunteersSection(true)}>
                Show all volunteers
              </HoverText>
            )}
          </Grid>
        ) : null}
        {inEditMode ? (
          <Grid item container direction='row' spacing={2} alignItems='center' style={{ margin: 0 }}>
            <Grid item>
              <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>
                Editing Volunteers:
              </Typography>
            </Grid>
            <Grid item>
              <TabletButton
                data-track='fs-prgMan-edit-volunteers-cancel'
                color='error'
                onClick={() => setInEditMode(false)}
              >
                Cancel
              </TabletButton>
            </Grid>
            <Grid item>
              <TabletButton
                data-track='fs-prgMan-edit-volunteers-done'
                color='primary'
                variant='contained'
                disabled={saveProgramVolunteersIsLoading}
                endIcon={<Check />}
                onClick={() => {
                  runSaveProgramVolunteers(
                    {
                      programId: program?.programId ?? '',
                      volunteerIds: editedTeam ? editedTeam.map((v) => v.volunteerId) : [],
                    },
                    handleSuccess
                  );
                }}
              >
                Done
              </TabletButton>
            </Grid>
          </Grid>
        ) : null}
        <Collapse in={expandVolunteersSection}>
          <Grid item xs={12}>
            {filteredVolunteersList ? (
              <FixedSizeList height={400} width='100%' itemSize={48} itemCount={filteredVolunteersList.length}>
                {({ index, style }) => (
                  <ListItem key={filteredVolunteersList[index]?.volunteerId} style={style}>
                    <Grid container alignItems='center' spacing={2}>
                      {inEditMode ? (
                        <IconButton
                          data-track='fs-prgMan-edit-volunteers-remove-volunteer'
                          onClick={() => {
                            setEditedTeam((prevTeam) => {
                              if (!prevTeam) return prevTeam;
                              return prevTeam.filter(
                                (vol) => vol.volunteerId !== filteredVolunteersList[index]?.volunteerId
                              );
                            });
                          }}
                        >
                          <Clear color='error' />
                        </IconButton>
                      ) : null}

                      {inEditMode ? (
                        <ProfileHoverBlock
                          avatarUrl={filteredVolunteersList[index]?.profile.avatarUrl}
                          profileId={filteredVolunteersList[index]?.profile.profileId}
                          userId={filteredVolunteersList[index]?.profile.userId}
                          preferredName={filteredVolunteersList[index]?.profile.preferredName}
                          lastName={filteredVolunteersList[index]?.profile.lastName}
                          hoverTextProps={{
                            hoverColor: 'primary',
                            disableUnderline: true,
                          }}
                        />
                      ) : (
                        <VolunteerDialogHoverBlock
                          avatarUrl={filteredVolunteersList[index]?.profile.avatarUrl}
                          profileId={filteredVolunteersList[index]?.profile.profileId}
                          userId={filteredVolunteersList[index]?.profile.userId}
                          preferredName={filteredVolunteersList[index]?.profile.preferredName}
                          lastName={filteredVolunteersList[index]?.profile.lastName}
                          hoverTextProps={{
                            hoverColor: 'primary',
                            disableUnderline: true,
                          }}
                        />
                      )}
                    </Grid>
                  </ListItem>
                )}
              </FixedSizeList>
            ) : null}
          </Grid>
        </Collapse>
        <Fade in={inEditMode}>
          <Grid item xs={12}>
            <TabletButton
              data-track='fs-prgMan-edit-volunteers-add-volunteers'
              variant='contained'
              onClick={() => {
                setShowAddVolunteerDialog(true);
              }}
              endIcon={<Add />}
            >
              Add Volunteers
            </TabletButton>
          </Grid>
        </Fade>
      </Grid>
      {/* @TODO: Move MultiSelectVolunteersDialog to common section */}
      {showAddVolunteerDialog && (
        <AddVolunteersDialog
          open={showAddVolunteerDialog}
          onClose={() => setShowAddVolunteerDialog(false)}
          onSubmit={(vols: MultiSelectVolunteersDialogVolunteerType[]) => {
            setEditedTeam((prevTeam) => {
              return prevTeam ? [...prevTeam, ...vols] : vols;
            });
          }}
          ignoreVolunteerIds={editedTeam ? editedTeam?.map((vol) => vol.volunteerId) : []}
          dialogContentText={`Select one or more volunteers to add to the program. Hit 'Cancel' to close this window.`}
        />
      )}
    </FormSectionV2>
  );
};
