import { capitalize } from 'lodash';
import { Box } from '@material-ui/core';
import React from 'react';
import { ColorLegend } from '../../../../common/ColorLegend';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ProgramStatus } from './AllProgramTypes';

export function AllProgramsColorLegend() {
  const { theme } = useCampfireTheme();
  const { programs } = theme.color;

  return (
    <Box display='flex' flexWrap='wrap'>
      {(['not on team', 'pending', 'joined'] as ProgramStatus[]).map((status) => (
        <ColorLegend
          color={programs.status[status]}
          text={capitalize(status)}
          width={10}
          height={10}
          isRound
          marginRight='6px'
        />
      ))}
    </Box>
  );
}
