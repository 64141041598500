import { gql } from '@apollo/client';

export const GET_MY_ELEMENTS_SIDE_BAR_MY_PROFILE_DATA = gql`
  query GetMyElementsSideBarMyProfileData($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        profile {
          preferredName
          lastName
          avatarUrl
        }
        activityEnrolments {
          position
        }
      }
    }
  }
`;
