import React, { ComponentType, ReactNode } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

export interface FullPageMessageProps {
  loading?: boolean;
  title?: string;
  subtitle?: string | ReactNode;
  message?: string | ReactNode;
  Icon?: React.FunctionComponent | ComponentType<SvgIconProps>;
  IconProps?: SvgIconProps;
  children?: ReactNode;
  compact?: boolean;
}

export const FullPageMessage = ({
  title,
  subtitle,
  message,
  Icon,
  IconProps,
  children,
  loading,
  compact,
}: FullPageMessageProps) => {
  return (
    <Paper
      elevation={0}
      style={{
        paddingLeft: 32,
        paddingRight: 32,
        marginTop: compact ? 32 : 128,
        marginBottom: 128,
      }}
    >
      <Grid container alignContent='center' justify='center' style={{ flexGrow: 1, height: '65%' }}>
        <Grid
          item
          style={{
            maxWidth: 1280,
          }}
          container
          justify='center'
        >
          <Grid item xs={12} style={{ textAlign: 'center', marginBottom: 8 }}>
            {loading ? (
              <CircularProgress />
            ) : Icon ? (
              <Icon
                style={{
                  ...(IconProps && IconProps.style),
                  fontSize: 48,
                  textAlign: 'center',
                }}
                {...IconProps}
              />
            ) : (
              <ErrorOutline style={{ fontSize: 48, textAlign: 'center' }} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography align='center' variant='h6' component='h1'>
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={8} md={7} lg={6} xl={4}>
            <Typography align='center' variant='subtitle1' component='h2'>
              {subtitle}
            </Typography>
          </Grid>

          {message ? (
            <Grid item xs={12}>
              <Typography align='center' variant='body1' component='p'>
                {message}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12} />
          {children && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
