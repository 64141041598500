import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface RosterRowLoadingProps {
  activityDates: string[];
}

const tableCellStyles = {
  paddingTop: '0.75rem',
  paddingBottom: '0.75rem',
  textAlign: 'center',
} as React.CSSProperties;

export function RosterRowLoading({ activityDates }: RosterRowLoadingProps) {
  return (
    <TableRow>
      <TableCell style={{ ...tableCellStyles }}>
        <Skeleton height={40} width={200} />
      </TableCell>
      {activityDates.map((date) => (
        <TableCell key={date} style={tableCellStyles}>
          <Skeleton height={40} width={80} style={{ margin: 'auto' }} />
        </TableCell>
      ))}
    </TableRow>
  );
}
