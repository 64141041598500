import React from 'react';
import { Redirect } from 'react-router';
import { useUser } from '../../global/auth/useUser';
import { CampfireSwitch } from '../common/CampfireSwitch';
import MiniRoute from '../common/MiniRoute';
import { useRoutesMap } from '../common/useRoutesMaps';
import { getActivityLeaderSubContentBlock } from './ActivityLeaderSubContentBlock';
import { getProgramManagerSubContentBlock } from './ProgramManagerSubContentBlock';

const getLegacyRoutes = ({ route, isAuthorized }: { route: string; isAuthorized: boolean }): Array<React.ReactNode> => [
  <MiniRoute
    //
    key={`/${route}/completed-shifts`}
    path={`/${route}/completed-shifts`}
    component={() => <Redirect to={`/${route}/activities`} />}
    isAuthorized={isAuthorized}
  />,
];

export const ManagementContentBlock = () => {
  const {
    management: { route, isAuthorized },
  } = useRoutesMap();
  const {
    user: { userIdentityService },
  } = useUser();
  const { isActivityLeader, isProgramManager, isVmAdmin } = userIdentityService;
  const shouldSeeActivitySubcontentBlock = isActivityLeader || isProgramManager || isVmAdmin;
  const shouldSeeAdminSubcontentBlock = isProgramManager || isVmAdmin;

  return isAuthorized ? (
    <CampfireSwitch>
      {getLegacyRoutes({ route, isAuthorized })}

      {shouldSeeActivitySubcontentBlock ? getActivityLeaderSubContentBlock() : null}
      {shouldSeeAdminSubcontentBlock ? getProgramManagerSubContentBlock() : null}
    </CampfireSwitch>
  ) : null;
};

export default ManagementContentBlock;
