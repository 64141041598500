import React from 'react';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ActivitiesTabs } from '../ActivitiesTabs';
import { ActivitiesToolbar } from '../ActivitiesToolbar';
import { ActivitiesSidebar } from '../ActivitiesSidebar';
import { ActivitiesBody } from '../ActivitiesBody';
import { SearchField } from '../../../../common/inputs/SearchField';
import { ActivityToolValue } from '../ActivityTypes';
import { ActivityContextValue } from '../ActivityContext';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import {
  GET_ACTIVITIES_TIMELINE,
  GET_ACTIVITIES,
  GET_ALL_ACTIVITY_ACTIVITY_TAGS,
} from '../ActivityQueries/get-activities-timeline.gql';
import {
  GetActivitiesTimeline,
  GetActivitiesTimelineVariables,
} from '../ActivityQueries/__generated__/GetActivitiesTimeline';
import { GetActivities, GetActivitiesVariables } from '../ActivityQueries/__generated__/GetActivities';
import { GetAllActivityActivityTags } from '../ActivityQueries/__generated__/GetAllActivityActivityTags.d';

import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

import { AllActivitiesTimeline } from './AllActivitiesTimeline/AllActivitiesTimeline';
import { AllActivitiesColorLegend } from './AllActivitiesColorLegend';
import { ActivityFilter } from '../ActivityFilter/ActivityFilter';
import * as ActivityConsts from '../ActivityConst';
import { bodyStyles } from '../ActivityStyles/shared';
import {
  filterBySearch,
  filterByProgram,
  filterByLocation,
  filterByActivity,
  filterByStartTime,
  filterByEndTime,
  filterByDuration,
  filterByDay,
  sortByActivityDate,
  sortByPriority,
  sortByName,
  sortByDuration,
  filterByActivityStatus,
  filterByRosterType,
  filterByActivityTag,
} from '../ActivityUtils/filters';
import { useDeepEffect } from '../../../../hooks/useDeepEffect';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { AlertCard, getAlertCardColors } from '../../../../common/cards/alert-card/AlertCard';
import { useActivityActions } from '../useActivityActions';
import { getActivityStatus } from '../helpers';
import { ActivityAvailability } from '../../../../common/availability-center/ActivityAvailability/ActivityAvailability';
import { ActivitiesMap } from '../../activities-explore/ActivitiesMap';

export const AllActivitiesScreen = () => {
  const [searchValue, setSearchValue] = React.useState('');
  const [sortBy, setSortBy] = React.useState('priority');
  const [showFilters, setShowFilters] = React.useState(false);
  const [sortByElement, setSortByElement] = React.useState(null);
  const onCloseSortBy = () => setSortByElement(null);
  const onSelectSortBy = (value: string) => {
    setSortBy(value);
    onCloseSortBy();
  };
  const classes = bodyStyles();
  const { darkTextColor, lightTextColor } = getAlertCardColors('info');

  const {
    vmVolunteer,
    activeTab,
    setActiveTab,
    showType,
    query,
    setQuery,
    volunteerId,
    endDate,
    startDate,
    vmVolunteerRefetch,
  } = React.useContext(ActivityContextValue);

  const tools = (() => {
    if (showType === 'list') {
      return [
        {
          name: 'map' as ActivityToolValue,
          onClick: () => {
            setQuery({ show: 'map' });
          },
        },
        {
          name: 'sort' as ActivityToolValue,
          onClick: (e: any) => setSortByElement(e.target),
          hideOnMd: true,
          hideOnMobile: true,
        },
        {
          name: 'filter' as ActivityToolValue,
          selected: showFilters,
          onClick: () => {
            setShowFilters(!showFilters);
          },
        },
      ];
    }
    return [
      {
        name: 'list' as ActivityToolValue,
        onClick: () => {
          setQuery({ show: 'list' });
        },
      },
      {
        name: 'sort' as ActivityToolValue,
        onClick: (e: any) => setSortByElement(e.target),
        hideOnMd: true,
        hideOnMobile: true,
      },
      {
        name: 'filter' as ActivityToolValue,
        selected: showFilters,
        onClick: () => {
          setShowFilters(!showFilters);
        },
      },
    ];
  })();

  const onChangeSearchField = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchValue(e.target.value);
  };

  const { data: activities, loading: activitiesLoading } = useCampfireQuery<GetActivities, GetActivitiesVariables>(
    GET_ACTIVITIES,
    {
      options: {
        variables: {
          volunteerId,
        },
      },
    }
  );

  const activityIds =
    React.useMemo(
      () =>
        activities?.vm.volunteer?.programs
          .flatMap(({ activities: programActivities }) => programActivities)
          .map(({ activityId }) => activityId),
      [activities]
    ) || [];

  const { data: activityTimeline, loading: timelineLoading } = useCampfireQuery<
    GetActivitiesTimeline,
    GetActivitiesTimelineVariables
  >(GET_ACTIVITIES_TIMELINE, {
    options: {
      variables: {
        volunteerId,
        activityIds,
        endDate,
        startDate,
      },
    },
  });

  const visibleActivities =
    activityTimeline?.vm.activities
      .filter((activity) => !activity.isHidden)
      .map((activity) => {
        const activityDate = activity.nextX[0] || activity.startDate;
        return {
          ...activity,
          activityDate,
          status: getActivityStatus(activity.activityId, vmVolunteer),
        };
      }) || [];

  const { data: tagsData } = useCampfireQuery<GetAllActivityActivityTags, {}>(GET_ALL_ACTIVITY_ACTIVITY_TAGS);

  const activityTagOptions = React.useMemo(() => {
    return tagsData?.vm.activityTags ?? [];
  }, [tagsData]).sort((a, b) => a.name.localeCompare(b.name));

  const [filters, setFilters] = React.useState({
    day: {},
    program: 'all',
    activityType: 'all',
    duration: 0,
    location: 'all',
    startTime: '',
    endTime: '',
    activityStatus: 'all',
    rosterType: 'all',
    activityTag: {},
  });
  const onChangeFilter = (newFilter: any) => setFilters((currentFilters) => ({ ...currentFilters, ...newFilter }));

  const programOptions = [
    {
      value: 'all',
      label: 'All programs',
    },
  ].concat(
    activities?.vm.volunteer?.programs
      .map((program) => ({
        value: program.programId,
        label: program.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || []
  );

  const { onAction, onSubmit, selectedActivity, showDialog, onCloseDialog } = useActivityActions(vmVolunteerRefetch);
  const { isMd, isMobile } = useCampfireTheme();

  const filteredActivities = visibleActivities
    .filter(filterByProgram(filters.program))
    .filter(filterByLocation(filters.location))
    .filter(filterByActivity(filters.activityType))
    .filter(filterByDuration(filters.duration))
    .filter(filterByStartTime(filters.startTime))
    .filter(filterByEndTime(filters.endTime))
    .filter(filterByDay(filters.day))
    .filter(filterBySearch(searchValue))
    .filter(filterByActivityStatus(filters.activityStatus))
    .filter(filterByRosterType(filters.rosterType))
    .filter(filterByActivityTag(filters.activityTag));

  const showingActivities = filteredActivities.sort((a, b) => {
    switch (sortBy) {
      case 'name':
        return sortByName(a, b);
      case 'duration':
        return sortByDuration(a, b);
      case 'activityDate':
        return sortByActivityDate(a, b);
      default:
        return sortByPriority(a, b);
    }
  });

  const activitiesMap = filteredActivities.map((activity) => ({
    activityId: activity.activityId,
    name: activity.name,
    startTime: activity.startTime,
    endTime: activity.endTime,
    isSuspended: activity.isSuspended,
    isActive: activity.isActive,
    activityLocation: activity.activityLocation || null,
  }));

  const [hoveredActivityId, setHoveredActivityId] = React.useState('');

  const { activityId: currentActivityId } = query;

  useDeepEffect(() => {
    if (
      !isMobile &&
      showingActivities?.length &&
      showingActivities.every((roster) => roster.activityId !== currentActivityId)
    ) {
      setQuery({
        activityId: showingActivities[0].activityId,
        activityDate: showingActivities[0].activityDate,
      });
    }
  }, [currentActivityId, showingActivities, isMobile]);

  const isActivitiesLoading = timelineLoading || activitiesLoading;
  const onActivityAction = (action: any, activity: any) => {
    const enrolmentId = vmVolunteer?.vm.volunteer?.activityEnrolments.find(
      (enrolment) => !enrolment.dateRemoved && enrolment.activity.activityId === activity.activityId
    )?.activityEnrolmentId;
    const publishedRosteringId = vmVolunteer?.vm.volunteer?.rosterings.find(
      (rostering) =>
        !rostering.dateRemoved &&
        rostering.publishedRoster.activityDate === activity.activityDate &&
        rostering.publishedRoster.activity.activityId === activity.activityId
    )?.publishedRosteringId;
    const activityApplicationId = vmVolunteer?.vm.volunteer?.activityApplications.find(
      (application) => !application.dateWithdrawn && application.activity.activityId === activity.activityId
    )?.activityApplicationId;
    const activityWaitlistingId = vmVolunteer?.vm.volunteer?.activityWaitlistings.find(
      (waitlist) => !waitlist.dateRemoved && waitlist.activity.activityId === activity.activityId
    )?.activityWaitlistingId;
    onAction({
      enrolmentId,
      activityApplicationId,
      activityWaitlistingId,
      publishedRosteringId,
    })(action, activity);
  };

  const onSubmitActivity = () => {
    const enrolmentId = vmVolunteer?.vm.volunteer?.activityEnrolments.find(
      (enrolment) => !enrolment.dateRemoved && enrolment.activity.activityId === selectedActivity.current?.activityId
    )?.activityEnrolmentId;
    const availabilityId = vmVolunteer?.vm.volunteer?.activityEnrolments
      .find((enrolment) => enrolment.activity.activityId === selectedActivity.current?.activityId)
      ?.availabilities?.find((availability) => availability.activityDate === selectedActivity.current?.activityDate)
      ?.activityAvailabilityId;
    return onSubmit({ enrolmentId, availabilityId });
  };

  return (
    <Box display='flex' height='100%' overflow='hidden'>
      <Box className={classes.rootContainer}>
        <ActivitiesTabs activeTab={activeTab} onSelectTab={setActiveTab}>
          <Box flex={1} justifyContent='flex-end' display='flex'>
            {!isMd && (
              <SearchField placeholder='Search activities' value={searchValue} onChange={onChangeSearchField} />
            )}
            <ActivitiesToolbar tools={tools} />
          </Box>
        </ActivitiesTabs>
        {
          <ActivitiesBody isLoading={isActivitiesLoading}>
            {!(isMobile && query.activityId) ? (
              <React.Fragment>
                <Box className={classes.stickyBox}>
                  <AllActivitiesColorLegend />
                  {showFilters && (
                    <ActivityFilter
                      values={filters}
                      onChange={onChangeFilter}
                      programOptions={programOptions}
                      durationOptions={ActivityConsts.DURATION_OPTIONS}
                      activityOptions={ActivityConsts.ACTIVITYTYPE_OPTIONS}
                      dayOptions={ActivityConsts.DAY_OPTIONS}
                      locationOptions={ActivityConsts.LOCATION_OPTIONS}
                      activityStatusesOptions={ActivityConsts.ACTIVITY_STATUSES_OPTIONS}
                      rosterTypesOptions={ActivityConsts.ROSTERTYPES_OPTIONS}
                      activityTagOptions={activityTagOptions}
                    />
                  )}
                </Box>
                {showType === 'list' && (
                  <AllActivitiesTimeline
                    activities={showingActivities}
                    onSelect={(activityId, activityDate) => setQuery({ activityId, activityDate })}
                    selectedActivityId={query.activityId}
                    onAction={onActivityAction}
                  />
                )}
                {showType === 'map' && (
                  <ActivitiesMap
                    showSkinnyMarkers={false}
                    activities={activitiesMap}
                    selectedActivityId={query.activityId}
                    hoveredActivityId={hoveredActivityId}
                    setHoveredActivityId={setHoveredActivityId}
                    setSelectedActivityId={(activityId) => setQuery({ activityId })}
                  />
                )}
              </React.Fragment>
            ) : (
              <Box padding='1rem 1rem'>
                <ActivitiesSidebar />
              </Box>
            )}

            {(!visibleActivities.length || !showingActivities.length) && !isActivitiesLoading ? (
              <AlertCard variant='info' title='There are no activities to show'>
                <Typography
                  variant='subtitle2'
                  style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor, paddingTop: '5px' }}
                >
                  {visibleActivities.length && !showingActivities.length ? (
                    'There are no activities that match your filter criteria'
                  ) : !visibleActivities.length ? (
                    <>
                      If you haven&apos;t joined a program team yet,&nbsp;
                      <Link to={{ pathname: '/volunteering/activities', search: 'tab=programs' }}>
                        <Typography
                          variant='body2'
                          display='inline'
                          style={{ textDecoration: 'underline', color: darkTextColor, fontWeight: 700 }}
                        >
                          click here to see available programs.
                        </Typography>
                      </Link>
                    </>
                  ) : null}
                </Typography>
              </AlertCard>
            ) : null}
          </ActivitiesBody>
        }
      </Box>
      {!isMobile && query.activityId && (
        <CollapsibleSideBar>
          <ActivitiesSidebar />
        </CollapsibleSideBar>
      )}
      <Menu open={Boolean(sortByElement)} anchorEl={sortByElement} onClose={onCloseSortBy}>
        <MenuItem onClick={() => onSelectSortBy('priority')}>Priority</MenuItem>
        <MenuItem onClick={() => onSelectSortBy('activityDate')}>Upcoming</MenuItem>
        <MenuItem onClick={() => onSelectSortBy('duration')}>Duration (shortest first)</MenuItem>
        <MenuItem onClick={() => onSelectSortBy('name')}>Name</MenuItem>
      </Menu>
      {showDialog && selectedActivity.current && (
        <ActivityAvailability
          open={showDialog}
          activityId={selectedActivity.current.activityId}
          onClose={onCloseDialog}
          selectedDate={selectedActivity.current.activityDate}
          onSubmit={onSubmitActivity()}
        />
      )}
    </Box>
  );
};
