import { useState, useEffect } from 'react';
import loadImage from 'blueimp-load-image';

export const useFilePhoto = (photo: any) => {
  const [src, setSrc] = useState();

  useEffect(() => {
    const reader = new FileReader();
    if (!photo) {
      setSrc(undefined);
    }
    if (photo) {
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          loadImage(
            reader.result,
            (img: any) => {
              if (!img.toDataURL) {
                return;
              }
              setSrc(img.toDataURL('image/png'));
            },
            { orientation: true, canvas: true }
          );
        }
      };
      reader.readAsDataURL(photo);
    }
    return () => {
      reader.abort();
    };
  }, [photo]);

  return src;
};
