import { useApiUrl } from '../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';

export function useCreateUnAvailability() {
  const apiUrl = useApiUrl();
  const updateRosteredStatus = useCampfireFetch<{ activityUnavailabilityId: string }>({ defer: true });

  return ({ activityEnrolmentId, activityDate }: any) =>
    updateRosteredStatus.run({
      url: `${apiUrl}/vm/activity/enrolment/unavailability/save`,
      method: 'post',
      data: {
        activityEnrolmentId,
        activityDate,
      },
    });
}
