import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Formik, Form } from 'formik';
import { Field } from '@campfire/field';
import { VolunteerDatabaseActions, VolunteerDatabaseVolunteer } from './volunteer-database-model.gql';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useApiUrl } from '../../../../global/config/useApiUrl';

export const VolunteerDatabaseExitDialog = ({
  open,
  onClose,
  volunteer,
  exitVolunteer,
  reloadDatabase,
}: {
  open: boolean;
  onClose: () => void;
  volunteer: VolunteerDatabaseVolunteer;
  exitVolunteer: VolunteerDatabaseActions['exitVolunteer'];
  reloadDatabase?: () => void;
}) => {
  const apiUrl = useApiUrl();
  const { isLoading, response, run } = useCampfireFetch({ defer: true });

  const retireVolunteer = (reason: string) => {
    run({
      url: `${apiUrl}/vm/volunteer/manage/flag`,
      method: 'post',
      data: {
        volunteerId: volunteer.volunteerId,
        reason,
      },
    });
  };

  useEffect(() => {
    if (response && response.ok) {
      exitVolunteer(volunteer);
      if (reloadDatabase) reloadDatabase();
      onClose();
    }
  }, [response]);

  const { theme } = useCampfireTheme();

  return (
    <Formik
      initialValues={{ reason: '' }}
      onSubmit={(values) => {
        retireVolunteer(values.reason);
      }}
    >
      {() => (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby='flag-and-exit-volunteer-dialog-title'
          aria-describedby='flag-and-exit-volunteer-dialog-description'
        >
          <Form>
            <DialogTitle id='flag-and-exit-volunteer-dialog-title'>{`Exit Volunteer?`}</DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              {isLoading ? <LinearProgressOverlay isLoading /> : null}
              <Box paddingLeft={2} paddingRight={2}>
                <DialogContentText>{`${volunteer.profile.preferredName} ${volunteer.profile.lastName}`}</DialogContentText>
                <DialogContentText>{`THIS ACTION CANNOT BE UNDONE`}</DialogContentText>
                <DialogContentText id='flag-and-exit-volunteer-dialog-description'>
                  {`${volunteer.profile.preferredName} will be flagged and exited. They will lose all access to Volaby and cannot be reactivated.`}
                </DialogContentText>
                <Field
                  required
                  autoFocus
                  name='reason'
                  multiline
                  rows={6}
                  variant='outlined'
                  label='Reasons for exit'
                  placeholder={`Provide a short statement on why ${volunteer.profile.preferredName} is being exited (required)`}
                  margin='normal'
                  fullWidth
                  helper
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>{`Cancel`}</Button>
              <Button
                disabled={isLoading}
                type='submit'
                style={{
                  color: theme.palette.error.main,
                }}
              >{`Exit Volunteer`}</Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
