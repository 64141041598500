import { Box, Typography } from '@material-ui/core';
import { Sort } from '@material-ui/icons';
import React from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { getFieldTypeLabelAndIcon } from '../../common/FieldTypeLabelAndIcon';
import { ReportManagementReportTypeItem } from '../__generated__/ReportManagementReportTypeItem';
import { SectionItem } from './ReportTypeDetail';

export const ReportTypeDetailFields = ({ items }: { items: Array<ReportManagementReportTypeItem> }) => {
  const { theme } = useCampfireTheme();
  return (
    <SectionItem title='Fields'>
      {items.length ? (
        items
          .sort((a, b) => a.order - b.order)
          .map((item) => (
            <Box
              bgcolor='white'
              key={item.reportTypeItemId}
              style={{
                padding: 8,
                paddingLeft: 16,
                border: `1px solid ${theme.color.grey.border}`,
                borderRadius: '4px',
                marginTop: 8,
                marginBottom: 8,
              }}
            >
              {item.__typename === 'VOLUNTEER_ReportTypeHeadingType' ? (
                <Box display='flex'>
                  <Sort
                    style={{
                      color: theme.color.grey.neutral300,
                      marginRight: '4px',
                      fontSize: '18px',
                      marginTop: '3px',
                    }}
                  />
                  <Typography variant='subtitle2' style={{ color: theme.color.grey.neutral300 }}>
                    {item.heading}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography variant='subtitle2' style={{ color: theme.color.grey.neutral300 }}>
                    {item.field.name}
                  </Typography>
                  {getFieldTypeLabelAndIcon(item.field.__typename)}
                </>
              )}
            </Box>
          ))
      ) : (
        <Typography variant='caption' style={{ color: theme.color.grey.neutral300 }}>
          There are no fields for this report type.
        </Typography>
      )}
    </SectionItem>
  );
};
