import { unpackToDateTime } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  MenuItem,
  Menu,
} from '@material-ui/core';
import { Add, Clear, Edit, Remove, ExpandMore } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { memo, useMemo, useState } from 'react';
import PrivilegeRules from '../../../../../../global/auth/PrivilegeRules';
import { useUser } from '../../../../../../global/auth/useUser';
import { useSnackbar } from '../../../../../../global/config/useSnackbar';
import { ActivityTimelinePastPublishedRoster } from '../../__generated__/ActivityTimelinePastPublishedRoster';
import { ActivityTimelinePastReportActivity } from '../../__generated__/ActivityTimelinePastReportActivity';
import { ActivityTimelinePastReportSession } from '../../__generated__/ActivityTimelinePastReportSession';
import { ActivityTimelinePastSessionReport } from '../../__generated__/ActivityTimelinePastSessionReport';
import { useCancelSessionReportFetch, useUnOmitSessionReportFetch } from '../../activity-timeline-actions';
import { SessionCancellationAlertDialog } from '../../common/SessionCancellationAlertDialog';
import { SessionReportForm } from './form/SessionReportForm';
import { SessionReportSubmission } from './submission/SessionReportSubmission';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

const useSessionReportCardStyles = makeStyles(() =>
  createStyles({
    sessionReportCard: {
      border: 'solid #dadada 1px',
      position: 'relative',
    },
  })
);

type SessionReportCardProps = {
  refetch?: () => Promise<any>;
  activity: ActivityTimelinePastReportActivity;
  session: ActivityTimelinePastReportSession;
  activityDate: string;
  sessionReport?: ActivityTimelinePastSessionReport;
  cancelledSession: boolean;
  publishedRoster?: ActivityTimelinePastPublishedRoster;
  defaultCollapsed?: boolean;
  onSessionReportSubmit?: (res: {
    activityReportId: string;
    sessionReportId: string;
    sessionId: string;
    activitySessionIds: string[];
  }) => void;
  onSessionReportCancel?: (res: { activityReportId: string; sessionId: string; activitySessionIds: string[] }) => void;
};

const SessionReportCard = memo((props: SessionReportCardProps) => {
  const {
    activity,
    session,
    activityDate,
    sessionReport,
    cancelledSession,
    publishedRoster,
    defaultCollapsed,
    refetch,
    onSessionReportSubmit,
    onSessionReportCancel,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(!sessionReport ? false : defaultCollapsed ?? false);
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useSessionReportCardStyles();
  const submittedByText = sessionReport
    ? `Submitted by ${sessionReport.submittedBy.profile.preferredName} ${
        sessionReport.submittedBy.profile.lastName
      }, ${unpackToDateTime(sessionReport.submissionDate).toFormat('h:mm a, ccc d LLL')}`
    : undefined;

  const cancelSessionReport = useCancelSessionReportFetch();
  const unOmitSessionReport = useUnOmitSessionReportFetch();

  const { setSnackbar } = useSnackbar();
  const {
    user: { userIdentityService },
  } = useUser();

  const canCancelSessionReport = useMemo(() => {
    if (!activity) return false;
    return userIdentityService.hasRuleForProgram(activity.program.programId, PrivilegeRules.cancelSessionReport);
  }, [userIdentityService, activity]);

  const canEditSessionReport = useMemo(() => {
    if (!activity) return false;
    return (
      userIdentityService.isLeaderOfActivity(activity.activityId) ||
      userIdentityService.hasRuleForProgram(activity.program.programId, PrivilegeRules.editSessionReport)
    );
  }, [userIdentityService, activity]);

  const onSubmitSuccess = ({
    activityReportId,
    sessionReportId,
  }: {
    activityReportId: string;
    sessionReportId: string;
  }) => {
    setIsEditing(false);
    setIsCollapsed(true);
    if (onSessionReportSubmit) {
      onSessionReportSubmit({
        activityReportId,
        sessionReportId,
        sessionId: session.sessionId,
        activitySessionIds: activity.sessions.map((x) => x.sessionId),
      });
    }

    if (!refetch) return;
    refetch().catch(() =>
      setSnackbar({
        open: true,
        message: 'Unable to reload',
        variant: 'error',
        action: (
          <Button color='secondary' variant='contained' size='medium' onClick={() => window.location.reload()}>
            {'Refresh Now'}
          </Button>
        ),
      })
    );
  };

  const runCancelSessionReport = () => {
    cancelSessionReport
      .run({
        sessionId: session.sessionId,
        activityDate,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to omit session report',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Omitted session report',
          variant: 'success',
        });

        if (onSessionReportCancel) {
          onSessionReportCancel({
            activityReportId: res.data.data.activityReportId,
            sessionId: session.sessionId,
            activitySessionIds: activity.sessions.map((x) => x.sessionId),
          });
        }

        if (!refetch) return;
        refetch().catch(() =>
          setSnackbar({
            open: true,
            message: 'Unable to reload',
            variant: 'error',
            action: (
              <Button color='secondary' variant='contained' size='medium' onClick={() => window.location.reload()}>
                {'Refresh Now'}
              </Button>
            ),
          })
        );
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to cancel session report',
          variant: 'error',
        })
      );
  };
  const runUncancelSessionReport = () => {
    unOmitSessionReport
      .run({
        sessionId: session.sessionId,
        activityDate,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to remove session report omission',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Un-Omitted session report',
          variant: 'success',
        });

        if (!refetch) return;
        refetch().catch(() =>
          setSnackbar({
            open: true,
            message: 'Unable to reload',
            variant: 'error',
            action: (
              <Button color='secondary' variant='contained' size='medium' onClick={() => window.location.reload()}>
                {'Refresh Now'}
              </Button>
            ),
          })
        );
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to cancel session report',
          variant: 'error',
        })
      );
  };

  const handleUnomittedClick = () => {
    runUncancelSessionReport();
  };

  const showMinimizeReportAction = !isCollapsed && sessionReport && !isEditing;
  const { theme } = useCampfireTheme();

  return (
    <Card elevation={0} className={classes.sessionReportCard}>
      <CardHeader
        style={{
          paddingLeft: 40,
          paddingBottom: 56,
          paddingRight: showMinimizeReportAction ? undefined : 32,
        }}
        title={
          <Box display='flex' justifyContent='space-between' alignItems='center' mt='16px' mb='12px'>
            <Typography variant='h4' style={{ fontSize: '30px', fontWeight: 700, marginTop: '2px' }}>
              {session.name}
            </Typography>
            {!isEditing && (
              <Box display='flex' alignItems='center'>
                <TabletButton
                  size='small'
                  variant='outlined'
                  color='primary'
                  aria-controls='activity-status-menu'
                  aria-haspopup='true'
                  onClick={() => setIsEditing(true)}
                  startIcon={<Edit style={{ fontSize: 12, marginLeft: 4 }} />}
                  style={{
                    border: '1px solid #9e9e9e',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    padding: '1px 7px',
                    color: theme.color.grey.neutral300,
                    marginLeft: '8px',
                  }}
                >
                  {'Edit report'}
                </TabletButton>
                {cancelledSession && (
                  <TabletButton
                    size='small'
                    variant='outlined'
                    color='primary'
                    aria-controls='activity-status-menu'
                    aria-haspopup='true'
                    onClick={(e) => setOptionsMenuAnchorEl(e.currentTarget)}
                    endIcon={<ExpandMore />}
                    style={{
                      border: '1px solid #9e9e9e',
                      boxSizing: 'border-box',
                      borderRadius: '4px',
                      padding: '1px 7px',
                      color: theme.color.grey.neutral300,
                      marginLeft: '8px',
                    }}
                  >
                    {'Options'}
                  </TabletButton>
                )}

                {cancelledSession && (
                  <Menu
                    id='activity-status-menu'
                    anchorEl={optionsMenuAnchorEl}
                    keepMounted
                    open={Boolean(optionsMenuAnchorEl)}
                    onClose={() => setOptionsMenuAnchorEl(null)}
                  >
                    <MenuItem onClick={handleUnomittedClick}>{'Change to Incomplete'}</MenuItem>
                  </Menu>
                )}
              </Box>
            )}
          </Box>
        }
        subheader={
          <>
            {sessionReport ? (
              <Typography variant='body2'>{submittedByText}</Typography>
            ) : cancelledSession ? (
              <>
                <Typography variant='body2'>Session has been omitted</Typography>
              </>
            ) : (
              <Typography variant='body2'>No Report Submitted</Typography>
            )}

            {isEditing ? (
              <HoverText
                style={{ paddingRight: 8 }}
                variant='body2'
                color='error'
                hoverColor='error'
                onClick={() => setIsEditing(false)}
                data-track='actCnl-report-card-cancel-edit-small'
              >
                {'Cancel edit'}
                <Clear style={{ fontSize: 12, marginLeft: 4 }} />
              </HoverText>
            ) : sessionReport && canEditSessionReport ? (
              <HoverText
                data-track='actCnl-report-card-header-edit-report'
                color='primary'
                hoverColor='primary'
                variant='body2'
                onClick={() => setIsEditing(true)}
              >
                {'Edit report'}
                <Edit style={{ fontSize: 12, marginLeft: 4 }} />
              </HoverText>
            ) : null}
          </>
        }
        action={
          showMinimizeReportAction ? (
            <IconButton
              data-track='actCnl-report-card-header-minmise-report'
              onClick={() => {
                setIsCollapsed(true);
              }}
            >
              <TitularTooltip title='Hide Report'>
                <Remove color='action' />
              </TitularTooltip>
            </IconButton>
          ) : (
            undefined
          )
        }
      />
      <Divider />

      {isEditing ? (
        <SessionReportForm
          onCancelClick={() => setIsEditing(false)}
          onSubmitSuccess={onSubmitSuccess}
          session={session}
          activityDate={activityDate}
          publishedRoster={publishedRoster}
          sessionReport={sessionReport}
        />
      ) : sessionReport ? (
        <>
          {isCollapsed ? (
            <SessionReportCardHoverTextFooter
              data-track='actCnl-report-card-footer-show-report'
              label={'Click here to show report'}
              handleClick={() => setIsCollapsed(false)}
            />
          ) : (
            <>
              <SessionReportSubmission
                activityId={activity.activityId}
                programId={activity.program.programId}
                sessionReport={sessionReport}
              />
              <SessionReportCardHoverTextFooter
                data-track='actCnl-report-card-footer-hide-report'
                label={'Hide report'}
                handleClick={() => setIsCollapsed(true)}
              />
            </>
          )}
        </>
      ) : cancelledSession ? null : (
        <StartNewSessionReportActions
          sessionName={session.name}
          setIsEditing={setIsEditing}
          runCancelSessionReport={runCancelSessionReport}
          canCancelSessionReport={canCancelSessionReport}
        />
      )}
    </Card>
  );
});

const SessionReportCardHoverTextFooter = (props: { id?: string; label: string; handleClick: () => void }) => {
  const { id, label, handleClick } = props;
  return (
    <>
      <CardActions>
        <Box
          display='flex'
          flex='1 1 auto'
          justifyContent='center'
          alignContent='center'
          alignItems='center'
          padding={3}
        >
          <HoverText id={id} variant='body2' color='primary' onClick={handleClick}>
            {label}
          </HoverText>
        </Box>
      </CardActions>
    </>
  );
};

const StartNewSessionReportActions = (props: {
  sessionName: string;
  setIsEditing: (value: boolean) => void;
  runCancelSessionReport: () => void;
  canCancelSessionReport: boolean;
}) => {
  const { sessionName, setIsEditing, runCancelSessionReport, canCancelSessionReport } = props;
  const [isOmitSessionReportDialogOpen, setOmitSessionReportDialogOpen] = useState(false);

  return (
    <>
      <CardActions>
        <Box padding={2}>
          <Typography variant='body2' color='textSecondary'>
            {`Click 'New Report' to open a new submission for this session`}
          </Typography>
          <Box display='flex' alignContent='center' alignItems='center' paddingTop={1}>
            <TabletButton
              data-track='actCnl-report-card-new-report'
              variant='contained'
              color='primary'
              size='medium'
              endIcon={<Add />}
              style={{ marginRight: 16 }}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              {'New Report'}
            </TabletButton>
            {canCancelSessionReport ? (
              <TitularTooltip description={`Omit a session to indicate that no report is expected to be submitted.`}>
                <Box>
                  <HoverText
                    data-track='actCnl-report-card-omit-session'
                    hoverColor='error'
                    color='error'
                    variant='body2'
                    onClick={() => {
                      setOmitSessionReportDialogOpen(true);
                    }}
                  >
                    {'No Report For Session'}
                  </HoverText>
                </Box>
              </TitularTooltip>
            ) : null}
          </Box>
        </Box>
      </CardActions>

      <SessionCancellationAlertDialog
        sessionName={sessionName}
        open={isOmitSessionReportDialogOpen}
        onClose={() => {
          setOmitSessionReportDialogOpen(false);
        }}
        onOmitClick={() => runCancelSessionReport()}
      />
    </>
  );
};

const SessionReportSkeletonLoadingCard = () => {
  const classes = useSessionReportCardStyles();
  const { theme } = useCampfireTheme();
  return (
    <Card elevation={0} className={classes.sessionReportCard} style={{ marginTop: 8, marginBottom: 8 }}>
      <CardHeader
        style={{
          paddingLeft: 64,
          paddingBottom: 64,
        }}
        title={<Skeleton style={{ marginTop: 64 }} variant='text' width='40%' height={20} />}
        subheader={<Skeleton variant='text' width='50%' height={10} />}
      />
      <CardActions>
        <Box
          borderTop={`1px solid ${theme.color.grey.border}`}
          width={1}
          padding={4}
          display='flex'
          flex='1 1 auto'
          alignContent='center'
          alignItems='center'
          justifyContent='center'
        >
          <Skeleton variant='text' width='40%' height={10} />
        </Box>
      </CardActions>
    </Card>
  );
};

export { SessionReportCard };
export { SessionReportSkeletonLoadingCard };
