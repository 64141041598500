import { gql } from '@apollo/client';

export const GET_MY_ELEMENTS_PERSONAL_PROFILE = gql`
  query GetMyElementsPersonalProfile($volunteerId: String!, $startDate: Date!, $endDate: Date!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        ...MyElementsPersonaProfile
      }
      tasks {
        ...MyElementsTaskType
      }
      bulletins {
        ...MyElementsBulletin
      }
    }
  }

  fragment MyElementsPersonaProfile on VOLUNTEER_VolunteerType {
    volunteerStatistics(startDate: $startDate, endDate: $endDate) {
      ...MyElementsVolunteerStatistics
    }
    profile {
      additionalVolunteerHours
      completedTasks {
        ...MyElementsCompletedTaskType
      }
    }
  }

  fragment MyElementsCompletedTaskType on VOLUNTEER_CompletedTaskType {
    completedTaskId
    task {
      ...MyElementsPermissionlessTaskType
    }
    taskFieldValues {
      ...MyElementsTaskFieldValueType
    }
    dateRevoked
    dateLastUpdated
    status
  }

  fragment MyElementsTaskFieldValueType on VOLUNTEER_TaskFieldValueType {
    ... on VOLUNTEER_AddressTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
    ... on VOLUNTEER_AttachmentTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
    ... on VOLUNTEER_CheckboxTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
    ... on VOLUNTEER_DateTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
    ... on VOLUNTEER_DropdownTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
    ... on VOLUNTEER_EmailTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
    ... on VOLUNTEER_LongTextTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
    ... on VOLUNTEER_MultipleChoiceTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
    ... on VOLUNTEER_NumberTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
    ... on VOLUNTEER_PhoneTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
    ... on VOLUNTEER_ShortTextTaskFieldValueType {
      dateRevoked
      taskFieldValueId
      dateLastUpdated
      field {
        taskFieldId
      }
    }
  }

  fragment MyElementsTaskType on VOLUNTEER_TaskType {
    taskId
    title
    description
    order
    cake {
      cakeId
      cakeType
    }
    taskItems {
      ... on VOLUNTEER_TaskItemHeadingType {
        taskItemId
      }
      ... on VOLUNTEER_TaskItemContentType {
        taskItemId
      }
      ... on VOLUNTEER_TaskItemFieldType {
        taskItemId
        optional
        field {
          taskFieldId
        }
      }
    }
    dateRemoved
    dateLastUpdated
  }

  fragment MyElementsPermissionlessTaskType on VOLUNTEER_PermissionlessTaskType {
    taskId
    title
    description
    order
    cake {
      cakeId
      cakeType
    }
    taskItems {
      ... on VOLUNTEER_TaskItemHeadingType {
        taskItemId
      }
      ... on VOLUNTEER_TaskItemContentType {
        taskItemId
      }
      ... on VOLUNTEER_TaskItemFieldType {
        taskItemId
        optional
        field {
          taskFieldId
        }
      }
    }
    dateRemoved
    dateLastUpdated
  }

  fragment MyElementsActivityStatistics on VOLUNTEER_ActivityStatisticsType {
    startDate
    endDate
  }

  fragment MyElementsVolunteerStatistics on VOLUNTEER_VolunteerStatisticsType {
    missed
    attended
    startDate
    endDate
    sessionReportStatistics {
      ...MyElementsSessionReportStatistics
    }
  }

  fragment MyElementsSessionReportStatistics on VOLUNTEER_SessionReportStatisticsType {
    startDate
    endDate
    volunteeringHours
    operationalHours
    activityLocations {
      activityLocationId
    }
    numericFieldStatistics {
      ...MyElementsNumericFieldStatistics
    }
  }

  fragment MyElementsNumericFieldStatistics on VOLUNTEER_SessionReportNumericFieldStatisticType {
    fieldId
    fieldName
    isHero
    numericValue
  }
  fragment MyElementsBulletin on VOLUNTEER_BulletinType {
    bulletinId
    title
    content
    pinned
    dateCreated
    createdBy {
      profile {
        profileId
        preferredName
        lastName
      }
    }
    dateUpdated
    lastUpdatedBy {
      profile {
        profileId
        preferredName
        lastName
      }
    }
    hasVolunteerViewedBulletin(volunteerId: $volunteerId)
  }
`;

export const GET_MY_ELEMENTS_VOLUNTEER_PROFILE = gql`
  query GetMyElementsVolunteerProfile($volunteerId: String!) {
    orgName
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        profile {
          preferredName
          lastName
          avatarUrl
        }
        activityEnrolments {
          activityEnrolmentId
          position
        }
      }
    }
  }
`;
