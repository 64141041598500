import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { ProgramMainActionType, ProgramSubActionType, ProgramType } from './AllProgramTypes';

const useApplyToProgram = () => {
  return useEndpointFetch<{ programId: string }>('/vm/program/application/save');
};

const useWithdrawProgramApplication = () => {
  return useEndpointFetch<{ programApplicationId: string }>('/vm/program/application/withdraw');
};

export const useProgramActions = (volunteerDataRefetch?: any) => {
  const applyToProgram = useApplyToProgram();
  const withdrawProgramApplication = useWithdrawProgramApplication();

  const { setSnackbar } = useSnackbar();

  const runJoinProgram = (programId: string) => {
    applyToProgram
      .run({
        programId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to join program',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Program Joined Successfully',
          variant: 'success',
        });
        if (volunteerDataRefetch) volunteerDataRefetch();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to join program',
          variant: 'error',
        })
      );
  };

  const runApplyToProgram = (programId: string) => {
    applyToProgram
      .run({ programId })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to apply',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Applied to program successfully',
          variant: 'success',
        });
        if (volunteerDataRefetch) volunteerDataRefetch();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to apply',
          variant: 'error',
        })
      );
  };

  const runWithdrawProgramApplication = (programApplicationId: string) => {
    withdrawProgramApplication
      .run({ programApplicationId })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to withdraw',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Withdrawn program application successfully',
          variant: 'success',
        });
        if (volunteerDataRefetch) volunteerDataRefetch();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to withdraw',
          variant: 'error',
        })
      );
  };

  const onProgramAction = ({
    programApplicationId,
  }: {
    volunteerIds: string[];
    programApplicationId: string;
  }) => async (action: ProgramMainActionType | ProgramSubActionType, program: ProgramType) => {
    switch (action) {
      case 'join':
        runJoinProgram(program.programId);
        return;
      case 'apply':
        runApplyToProgram(program.programId);
        return;

      case 'withdraw': {
        if (programApplicationId) {
          runWithdrawProgramApplication(programApplicationId);
        }
        break;
      }
      default:
        break;
    }
  };

  return {
    onProgramAction,
  };
};
