import React, { memo, Fragment } from 'react';
import { List, ButtonBase, ListItem, Grid, Typography, Divider, Box } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { ProgramManagementProgram } from '../__generated__/ProgramManagementProgram';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useProgramManagementContext } from '../ProgramManagementContext';

interface Props {
  programs?: ProgramManagementProgram[];
}
const ProgramsList = memo((props: Props) => {
  const { programs } = props;
  const { theme } = useCampfireTheme();
  const { selectedProgramId, setSelectedProgramId } = useProgramManagementContext();

  return programs && programs.length >= 1 ? (
    <List style={{ padding: 0 }}>
      {programs.map((program, index) => (
        <Fragment key={program.programId}>
          <ButtonBase
            data-track='fs-prgMan-program-list-item'
            onClick={() => setSelectedProgramId(program.programId)}
            style={{ width: '100%' }}
          >
            <ListItem
              style={{ backgroundColor: selectedProgramId === program.programId ? theme.color.grey.border : undefined }}
            >
              <Grid container direction='column' style={{ paddingLeft: 24, paddingTop: 8, paddingBottom: 8 }}>
                <Grid item container alignItems='center' spacing={2}>
                  <Grid item style={{ padding: '4px 8px' }}>
                    <Typography variant='subtitle2' display='inline' style={{ fontWeight: 'bold' }}>
                      {program.name}
                    </Typography>
                  </Grid>
                  {program.isNew ? (
                    <Grid item style={{ padding: '4px 8px' }}>
                      <Box
                        style={{
                          backgroundColor: fade(theme.palette.primary.light, 0.4),
                          borderRadius: 2,
                          padding: '0px 4px',
                        }}
                      >
                        <Typography variant='caption' color='primary'>
                          {'New'}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </ListItem>
          </ButtonBase>
          {index < programs.length - 1 ? <Divider /> : null}
        </Fragment>
      ))}
    </List>
  ) : null;
});

export { ProgramsList };
