import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  LinearProgress,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { AlertCard, getAlertCardColors } from '../cards/alert-card/AlertCard';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 16,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.color.primary,
  },
}))(LinearProgress);

interface Props {
  open: boolean;
  close: () => void;
  handleSaveCsv: () => void;
}

const ExportCsvDialog = ({ open, close, handleSaveCsv }: Props) => {
  const [progress, setProgres] = useState(0);
  const { lightTextColor } = getAlertCardColors('info');

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgres((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (progress >= 100) {
      handleSaveCsv();
      close();
    }
  }, [progress]);

  return (
    <Dialog open={open} onClose={close} fullWidth style={{ padding: '32px' }}>
      <Box padding={'16px'}>
        <DialogTitle>
          <Grid container>
            <Typography variant={'h4'}>{'Exporting CSV'}</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <AlertCard variant='info'>
            <Typography style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
              Your CSV is now preparing for automatic download, please don’t close this window. Depending on the size of
              the download, this may take some time.
            </Typography>
          </AlertCard>
          <Box mt={4} mb={1}>
            <BorderLinearProgress variant='determinate' value={progress < 100 ? progress : 100} />
          </Box>
        </DialogContent>
        <DialogActions style={{ margin: '12px 20px' }}>
          <Button variant='outlined' onClick={() => close()}>
            {'Cancel'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export { ExportCsvDialog };
