import React from 'react';
import { encodeDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { withRouter, RouteComponentProps, Route } from 'react-router';

import { CampfireSwitch } from '../../../../../../content-blocks/common/CampfireSwitch';
import { ReactComponent as MailchimpLogo } from '../../../../../../assets/apps/mailchimp_logo.svg';
import { ReactComponent as CampaignMonitorLogo } from '../../../../../../assets/apps/campaign_monitor_logo.svg';
import { ReactComponent as SalesforceLogo } from '../../../../../../assets/apps/salesforce_logo.svg';
import { AdminConsoleApps } from './AdminConsoleApps';
import { AdminConsoleApp } from './AdminConsoleApp';
import { MailchimpAuthorizationRequest } from './Setups/Mailchimp/MailchimpAuthorizationRequest';
import { MailchimpAuthorizationCallback } from './Setups/Mailchimp/MailchimpAuthorizationCallback';
import { AppSetup } from './AppSetup';
import { CampaignMonitorAuthorizationRequest } from './Setups/CampaignMonitor/CampaignMonitorAuthorizationRequest';
import { CampaignMonitorAuthorizationCallback } from './Setups/CampaignMonitor/CampaignMonitorAuthorizationCallback';
import { SalesforceAuthorizationRequest } from './Setups/Salesforce/SalesforceAuthorizationRequest';
import { SalesforceAuthorizationCallback } from './Setups/Salesforce/SalesforceAuthorizationCallback';
import { MailchimpSetups } from './Setups/Mailchimp/MailchimpSetups';
import { CampaignMonitorSetups } from './Setups/CampaignMonitor/CampaignMonitorSetups';
import { AppSettingWrapper } from './AppSetting/AppSettingWrapper';
import { AppAuthSetting } from './AppSetting/AppAuthSetting';
import { AppAccountSettings } from './AppSetting/AppAccountSettings';
import { AppConfigSettings } from './AppSetting/AppConfigSettings';
import { SalesforceSetups } from './Setups/Salesforce/SalesforceSetups';

const mockAppInfo = {
  mailchimp: {
    accountDescription: 'orgemail@org.com.au',
    lastUpdated: encodeDate(DateTime.local().minus({ minutes: 10 })),
    authorisation:
      '<p>Volaby is authorised, on behalf of your Mailchimp account, to:</p><ul><li>View the name, email, and associated data of your contacts</li><li>View information about your account</li><li> Add, update and remove contacts</li><li> Add, update and remove data associated with contacts</li></ul><p>Volaby will not edit data associated with contacts that are not volunteers within your organisation&apos;s Volaby account</p>',
  },
  salesforce: {
    accountDescription: 'orgemail@org.com.au',
    lastUpdated: encodeDate(DateTime.local().minus({ minutes: 10 })),
    authorisation:
      '<div><p>Volaby is authorised, on behalf of your Salesforce account, to:</p><ul><li>View the name, email, and associated data of your contacts</li><li>View information about your account</li><li> Add, update and remove contacts</li><li> Add, update and remove data associated with contacts</li></ul><p>Volaby will not edit data associated with contacts that are not volunteers within your organisation&apos;s Volaby account</p></div>',
  },
  campaignMonitor: {
    accountDescription: 'API Key: 567886447bdhs',
    lastUpdated: encodeDate(DateTime.local().minus({ minutes: 10 })),
    authorisation:
      '<div><p>Volaby is authorised, on behalf of your Campaign Monitor account, to:</p><ul><li>View the name, email, and associated data of your contacts</li><li>View information about your account</li><li> Add, update and remove contacts</li><li> Add, update and remove data associated with contacts</li></ul><p>Volaby will not edit data associated with contacts that are not volunteers within your organisation&apos;s Volaby account</p></div>',
  },
};

export const AdminConsoleAppsScreen = withRouter(({ match }: RouteComponentProps) => {
  return (
    <React.Fragment>
      <CampfireSwitch>
        <Route exact path={match?.path} render={() => <AdminConsoleApps />} />
        <Route exact path={`${match?.path}/mailchimp`}>
          <AppSetup name='Mailchimp' icon={MailchimpLogo}>
            <MailchimpAuthorizationRequest />
          </AppSetup>
        </Route>
        <Route exact path={`${match?.path}/mailchimp/auth/callback`}>
          <AppSetup name='Mailchimp' icon={MailchimpLogo}>
            <MailchimpAuthorizationCallback />
          </AppSetup>
        </Route>
        <Route exact path={`${match?.path}/campaign-monitor`}>
          <AppSetup name='Campaign Monitor' icon={CampaignMonitorLogo}>
            <CampaignMonitorAuthorizationRequest />
          </AppSetup>
        </Route>
        <Route exact path={`${match?.path}/campaign-monitor/auth/callback`}>
          <AppSetup name='Campaign Monitor' icon={CampaignMonitorLogo}>
            <CampaignMonitorAuthorizationCallback />
          </AppSetup>
        </Route>
        <Route exact path={`${match?.path}/campaign-monitor/settings`}>
          <AppSettingWrapper name='Campaign Monitor'>
            <AppAuthSetting {...mockAppInfo.campaignMonitor} name='Campaign Monitor' />
            <AppAccountSettings>
              <CampaignMonitorSetups />
            </AppAccountSettings>
            <AppConfigSettings id='campaignMonitor' />
          </AppSettingWrapper>
        </Route>
        <Route exact path={`${match?.path}/salesforce`}>
          <AppSetup name='Salesforce' icon={SalesforceLogo}>
            <SalesforceAuthorizationRequest />
          </AppSetup>
        </Route>
        <Route exact path={`${match?.path}/salesforce/auth/callback`}>
          <AppSetup name='Salesforce' icon={SalesforceLogo}>
            <SalesforceAuthorizationCallback />
          </AppSetup>
        </Route>
        <Route exact path={`${match?.path}/salesforce/settings`}>
          <AppSettingWrapper name='Salesforce'>
            <AppAuthSetting {...mockAppInfo.salesforce} name='Salesforce' />
            <AppAccountSettings>
              <SalesforceSetups />
            </AppAccountSettings>
            <AppConfigSettings id='salesforce' />
          </AppSettingWrapper>
        </Route>
        <Route exact path={`${match?.path}/mailchimp/settings`}>
          <AppSettingWrapper name='Mailchimp'>
            <AppAuthSetting {...mockAppInfo.mailchimp} name='Mailchimp' />
            <AppAccountSettings>
              <MailchimpSetups />
            </AppAccountSettings>
            <AppConfigSettings id='mailchimp' />
          </AppSettingWrapper>
        </Route>
        <Route path={`${match?.path}/setup/:app`}>
          <AdminConsoleApp />
        </Route>
      </CampfireSwitch>
    </React.Fragment>
  );
});
