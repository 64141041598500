import { gql } from '@apollo/client';

const CICO_VOLUNTEER_FRAGMENT = gql`
  fragment CICOVolunteerFragment on VOLUNTEER_VolunteerType {
    volunteerId
    profile {
      profileId
      userId
      firstName
      lastName
      preferredName
      avatarUrl
    }
    isCheckedIn
  }
`;

export const CICO_GET_VOLUNTEERS_BY_ACTIVITY = gql`
  query CICOGetVolunteersByActivity($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityId
        name
        activityEnrolments {
          volunteer {
            ...CICOVolunteerFragment
          }
        }
      }
    }
  }
  ${CICO_VOLUNTEER_FRAGMENT}
`;

export const CICO_GET_VOLUNTEERS_BY_PROGRAM = gql`
  query CICOGetVolunteersByProgram($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityId
        name
        program {
          activeVolunteers {
            ...CICOVolunteerFragment
          }
        }
      }
    }
  }
  ${CICO_VOLUNTEER_FRAGMENT}
`;

export const CICO_GET_VOLUNTEERS_BY_ORG = gql`
  query CICOGetVolunteersByOrg($keywordsFilter: String!, $startDate: Date, $endDate: Date) {
    vm {
      programActiveVolunteersConnection(keywordsFilter: $keywordsFilter, startDate: $startDate, endDate: $endDate) {
        totalCount
        edges {
          node {
            ...CICOVolunteerFragment
          }
          cursor
        }
      }
    }
  }
  ${CICO_VOLUNTEER_FRAGMENT}
`;
