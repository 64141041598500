import { Interval, DateTime } from 'luxon';
import { ActivitiesExploreActivityCancelledActivity } from '../__generated__/ActivitiesExploreActivityCancelledActivity';

const timeBreakPoints = [
  {
    indicator: 'morning',
    interval: Interval.fromDateTimes(DateTime.fromISO('04:00:00'), DateTime.fromISO('09:59:59')),
  },
  {
    indicator: 'daytime',
    interval: Interval.fromDateTimes(DateTime.fromISO('10:00:00'), DateTime.fromISO('16:59:59')),
  },
  {
    indicator: 'evening',
    interval: Interval.fromDateTimes(DateTime.fromISO('17:00:00'), DateTime.fromISO('18:59:59')),
  },
  {
    indicator: 'night',
    interval: Interval.fromDateTimes(DateTime.fromISO('19:00:00'), DateTime.fromISO('24:00:00')).union(
      Interval.fromDateTimes(DateTime.fromISO('00:00:01'), DateTime.fromISO('03:59:00'))
    ),
  },
];
export const getDayTimeIndicator = (startTime: string) => {
  return timeBreakPoints.find((breakpoint) => breakpoint.interval.contains(DateTime.fromISO(startTime)))?.indicator;
};

export const isRosterCancelled = (
  cancelledActivities: Array<ActivitiesExploreActivityCancelledActivity>,
  rosterDate: string
) => {
  return !!cancelledActivities.find((cancelledActivity) => cancelledActivity.activityDate === rosterDate);
};
