import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import WidgetInstallationInstructions from './components/WidgetInstallationInstructions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabSummary: {
      marginTop: theme.spacing(2),
    },
  })
);

const InstructionsImpact = () => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  const impactTag = `<impact-widget></impact-widget>`;

  const customExample = `<impact-widget 
  data-bg-color="#584cda"
  data-text-color="#fcfcfc"
  data-label="Books given last month"
  data-widget-type="custom"
  data-volaby-id="your-volaby-id-goes-here"
  data-field-id="your-field-id-goes-here"
></impact-widget >
`;

  const widgetAttributes = [
    {
      name: 'data-widget-type',
      required: 'Required',
      values: [
        { name: 'activities', description: 'Total number of activities last month' },
        { name: 'volunteers', description: 'A count of active volunteers' },
        { name: 'hours', description: 'A count of total volunteering hours' },
        { name: 'custom', description: 'Total count of a custom field containing numeric data' },
      ],
    },
    {
      name: 'data-volaby-id',
      required: 'Required',
      values: [{ name: 'your-volaby-id-here', description: 'Your unique volaby ID. This can be found below' }],
    },
    {
      name: 'data-field-id',
      required: 'Only for "custom" widget types',
      values: [{ name: 'your-field-id-here', description: 'The ID of the custom field' }],
    },
    {
      name: 'data-bg-color',
      required: 'Optional',
      values: [{ name: '#CA431A', description: 'The hex value of the background colour. Defaults to Volaby Orange' }],
    },
    {
      name: 'data-text-color',
      required: 'Optional',
      values: [{ name: '#FCFCFC', description: 'The hex value of the text colour. Defaults to white' }],
    },
    {
      name: 'data-label',
      required: 'Optional',
      values: [{ name: 'Your Label', description: 'The label that will be shown underneath the count' }],
    },
  ];

  return (
    <>
      <div className={classes.tabSummary}>
        <p>Display your real-time impact data in singular metric blocks.</p>

        <p>Customise background and text colours.</p>

        <p>Customise the metric label, as an alternative to the current field label in Volaby.</p>
      </div>

      {open ? (
        <></>
      ) : (
        <>
          <Typography align='center'>
            <Button
              color='primary'
              disabled={false}
              size='medium'
              endIcon={<ExpandMoreIcon />}
              onClick={() => setOpen(true)}
            >
              <span>Read more</span>
            </Button>
          </Typography>
        </>
      )}
      {open ? (
        <>
          <WidgetInstallationInstructions
            widgetName='impact statistics'
            minimumExample={impactTag}
            customExample={customExample}
            widgetAttributes={widgetAttributes}
          />

          <Typography align='center'>
            <Button
              color='primary'
              disabled={false}
              size='medium'
              endIcon={<CloseIcon />}
              onClick={() => setOpen(false)}
            >
              <span> Close</span>
            </Button>
          </Typography>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default InstructionsImpact;
