import { encodeDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router';
import { FugFile } from '@campfire/file-upload-gallery';
import { StringParam, useQueryParam } from 'use-query-params';
import { RosterTemplateFormValues } from './RosterTemplateForm';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';
import { useSnackbar } from '../../../../global/config/useSnackbar';

interface PlacesAddress {
  description: string;
  formatted: string;
  placesId?: string;
  latitude: number;
  longitude: number;
}

export interface SaveRosterTemplateFetchLocationVariables {
  activityLocationId?: string;
  placesAddress: PlacesAddress;
  timeZone: string;
  comments: string;
}

export interface SaveRosterTemplateFetchVariables {
  rosterTemplateId?: string;
  rosterNotes?: string | null;
  rosterDate: string;
  location: PlacesAddress | null;
  activityId?: string;
  remoteLocation: {
    activityRemoteLocationId?: string;
    details?: string;
  } | null;
}

export const useSaveRosterTemplate = () => {
  return useEndpointFetch<SaveRosterTemplateFetchVariables, { activityId: string }>(
    '/vm/activity/roster-template/save',
    {
      formData: true,
    }
  );
};
export const useCancelActivityFetch = () => {
  return useEndpointFetch<
    {
      activityId: string;
      activityDate: string;
      activityCancellationReasonId: string;
      description: string;
      addedAttachments?: FugFile[];
      attachmentCreationTokens?: string[];
    },
    { cancelledActivityId: string }
  >('/vm/activity/cancel', { formData: true });
};
export const useDeleteRosterTemplate = () => {
  return useEndpointFetch<{ rosterTemplateId: string }>('/vm/activity/roster-template/delete');
};

export const useRosterTemplateActions = () => {
  const saveRosterTemplate = useSaveRosterTemplate();
  const deleteRosterTemplate = useDeleteRosterTemplate();
  const cancelActivity = useCancelActivityFetch();
  const history = useHistory();
  const [view] = useQueryParam('view', StringParam);
  const viewParam = view === 'bulk' ? '&view=bulk' : '';
  const { setSnackbar } = useSnackbar();

  const runSaveRosterTemplate = ({ values }: { values: RosterTemplateFormValues }) => {
    if (!values.rosterDate) {
      return Promise.resolve();
    }
    const date = DateTime.fromJSDate(values.rosterDate || new Date());
    const handleSaveRosterTemplateSuccess = () =>
      history.push(
        `/management/rosters?activityDate=${encodeDate(DateTime.fromJSDate(values.rosterDate as Date))}&activityId=${
          values.activityId
        }${viewParam}`
      );

    return saveRosterTemplate
      .run({
        rosterTemplateId: values.rosterTemplateId,
        activityId: values.activityId,
        rosterNotes: values.rosterNotes,
        rosterDate: encodeDate(date),
        location: values.location.description ? values.location : null,
        remoteLocation: values.remoteLocation ?? null,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save roster',
            variant: 'error',
          });

          return;
        }
        setSnackbar({
          open: true,
          message: 'Roster saved',
          variant: 'success',
        });
        handleSaveRosterTemplateSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save roster',
          variant: 'error',
        })
      );
  };

  const runDeleteRosterTemplate = ({ rosterTemplateId }: { rosterTemplateId: string }) => {
    const handleDeleteRosterTemplateSuccess = () => history.push(`/management/rosters`);
    deleteRosterTemplate
      .run({
        rosterTemplateId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to delete roster',
            variant: 'error',
          });

          return;
        }
        setSnackbar({
          open: true,
          message: 'Roster removed',
          variant: 'success',
        });
        handleDeleteRosterTemplateSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to delete roster',
          variant: 'error',
        })
      );
  };
  const runCancelActivity = ({
    activityCancellationReasonId,
    activityId,
    activityDate,
    description,
    addedAttachments,
    attachmentCreationTokens,
    handleSuccess,
  }: {
    activityCancellationReasonId: string;
    activityId: string;
    activityDate: string;
    description: string;
    addedAttachments?: FugFile[];
    attachmentCreationTokens?: string[];
    handleSuccess?: () => void;
  }) => {
    cancelActivity
      .run({
        activityId,
        activityDate,
        activityCancellationReasonId,
        description,
        addedAttachments,
        attachmentCreationTokens,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to cancel activity',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Activity cancelled',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to cancel activity',
          variant: 'error',
        })
      );
  };
  return {
    runSaveRosterTemplate,
    runSaveRosterTemplateLoading: saveRosterTemplate.isLoading,
    runDeleteRosterTemplate,
    runDeleteRosterTemplateLoading: deleteRosterTemplate.isLoading,
    runCancelActivity,
  };
};
