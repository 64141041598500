import React from 'react';
import { useQueryParams, StringParam, SetQuery, QueryParamConfig } from 'use-query-params';
import { DateTime } from 'luxon';

import { useUser } from '../../../global/auth/useUser';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { ACTIVITIES_GET_VOLUNTEER_DATA } from './ActivityQueries/activities-get-volunteer-data.gql';
import {
  ActivitiesVolunteerData,
  ActivitiesVolunteerDataVariables,
} from './ActivityQueries/__generated__/ActivitiesVolunteerData';
import { GET_ACTIVITY_DETAILS } from './ActivityQueries/get-activity-details.gql';
import { GetActivityDetails, GetActivityDetailsVariables } from './ActivityQueries/__generated__/GetActivityDetails';

import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';
import { ActivityTabValue } from './ActivityTypes';

type QueryStringParams = string | undefined;

type ActivityContextType = {
  query: {
    activityId: QueryStringParams;
    activityDate: QueryStringParams;
    tab: QueryStringParams;
    show: QueryStringParams;
  };
  setQuery: SetQuery<{
    activityId: QueryParamConfig<any, any>;
    activityDate: QueryParamConfig<any, any>;
    tab: QueryParamConfig<any, any>;
    show: QueryParamConfig<any, any>;
  }>;
  refetch?: any;
  vmVolunteer?: ActivitiesVolunteerData;
  vmVolunteerRefetch?: any;
  vmLoading?: boolean;
  activityDetails?: GetActivityDetails;
  activityLoading?: boolean;
  activeTab: ActivityTabValue;
  setActiveTab: (newTab: string) => void;
  showType: string;
  volunteerId: string;
  startDate: string;
  endDate: string;
  enrolmentId?: string;
  activityApplicationId?: string;
  activityWaitlistingId?: string;
  publishedRosteringId?: string;
  availabilityId?: string;
  availableSessionIds?: string[];
  rosteringSessionIds?: string[];
};

export const ActivityContextValue = React.createContext<ActivityContextType>({
  query: {
    activityId: '',
    activityDate: '',
    tab: 'all',
    show: 'list',
  },
  setQuery: () => {},
  activeTab: 'all',
  setActiveTab: () => {},
  showType: 'list',
  volunteerId: '',
  startDate: '',
  endDate: '',
});

interface Props {
  children: React.ReactElement;
}

export function ActivityContext({ children }: Props) {
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  const startDate = DateTime.local().toISODate();
  const endDate = DateTime.local()
    .endOf('month')
    .toISODate();
  const [query, setQuery] = useQueryParams({
    activityId: StringParam,
    activityDate: StringParam,
    tab: StringParam,
    show: StringParam,
  });
  const { data: vmVolunteer, refetch: vmVolunteerRefetch, loading: vmLoading } = useCampfireQuery<
    ActivitiesVolunteerData,
    ActivitiesVolunteerDataVariables
  >(ACTIVITIES_GET_VOLUNTEER_DATA, {
    options: {
      variables: {
        volunteerId,
      },
    },
  });
  const setActiveTab = (newTab: string) => {
    if (newTab === 'mine') {
      setQuery({ tab: newTab, activityDate: undefined, activityId: undefined });
    } else {
      setQuery({ tab: newTab });
    }
  };
  const activeTab = (query.tab || 'all') as ActivityTabValue;

  const [activityDetails, setActivityDetails] = React.useState<GetActivityDetails | undefined>();

  const [getActivityDetails, { loading: activityLoading, refetch }] = useCampfireLazyQuery<
    GetActivityDetails,
    GetActivityDetailsVariables
  >(GET_ACTIVITY_DETAILS, {
    onCompleted: setActivityDetails,
  });

  const { activityId, activityDate, show } = query;

  React.useEffect(() => {
    if (activityId && activityDate) {
      getActivityDetails({
        variables: {
          activityId,
          activityDate,
          startDate,
          endDate,
        },
      });
    }
  }, [activityId, activityDate]);

  const enrolmentId = React.useMemo(() => {
    return vmVolunteer?.vm.volunteer?.activityEnrolments.find(
      (enrolment) => enrolment.activity.activityId === activityId
    )?.activityEnrolmentId;
  }, [activityId, vmVolunteer]);

  const availabilityId = React.useMemo(() => {
    return vmVolunteer?.vm.volunteer?.activityEnrolments
      .find((enrolment) => enrolment.activity.activityId === activityId)
      ?.availabilities.find((availability) => availability.activityDate === activityDate)?.activityAvailabilityId;
  }, [activityId, vmVolunteer, activityDate]);

  const activityApplicationId = React.useMemo(() => {
    return vmVolunteer?.vm.volunteer?.activityApplications.find(
      (application) => application.activity.activityId === activityId
    )?.activityApplicationId;
  }, [activityId, vmVolunteer]);

  const activityWaitlistingId = React.useMemo(() => {
    return vmVolunteer?.vm.volunteer?.activityWaitlistings.find(
      (waitlist) => waitlist.activity.activityId === activityId
    )?.activityWaitlistingId;
  }, [activityId, vmVolunteer]);

  const publishedRosteringId = React.useMemo(() => {
    return vmVolunteer?.vm.volunteer?.rosterings.find(
      (rostering) =>
        rostering.publishedRoster.activity.activityId === activityId &&
        rostering.publishedRoster.activityDate === activityDate
    )?.publishedRosteringId;
  }, [activityId, activityDate, vmVolunteer]);

  const availableSessionIds = React.useMemo(() => {
    return vmVolunteer?.vm.volunteer?.activityEnrolments
      .flatMap((enrolment) => enrolment.availabilities)
      .find((availability) => availability.activityAvailabilityId === availabilityId)
      ?.sessionAvailabilities.map((sessionAvailablity) => sessionAvailablity.session.sessionId);
  }, [vmVolunteer, availabilityId]);

  const rosteringSessionIds = React.useMemo(() => {
    return vmVolunteer?.vm.volunteer?.rosterings
      .find((rostering) => rostering.publishedRosteringId === publishedRosteringId)
      ?.sessionRosterings.map((sessionRostering) => sessionRostering.session.sessionId);
  }, [vmVolunteer, publishedRosteringId]);

  return (
    <ActivityContextValue.Provider
      value={{
        query,
        setQuery,
        refetch,
        vmVolunteer,
        vmVolunteerRefetch,
        vmLoading,
        activityDetails,
        activityLoading,
        activeTab,
        setActiveTab,
        showType: show || 'list',
        volunteerId,
        startDate,
        endDate,
        enrolmentId,
        availabilityId,
        activityApplicationId,
        activityWaitlistingId,
        publishedRosteringId,
        availableSessionIds,
        rosteringSessionIds,
      }}
    >
      {children}
    </ActivityContextValue.Provider>
  );
}
