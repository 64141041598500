import { gql } from '@apollo/client';

export const GET_ROSTER_ITEM_STATUS_DATA = gql`
  query GetRosterItemStatusData($activityIds: [String!]) {
    vm {
      activities(activityIds: $activityIds) {
        ...RosterActivityItemStatus
      }
    }
  }

  fragment RosterActivityItemStatus on VOLUNTEER_ActivityType {
    draftRosters {
      draftRosterId
      activityDate
      publishedRoster {
        rosterId
        dateRemoved
      }
    }
    cancelledActivities {
      cancelledActivityId
      activityDate
    }
    publishedRosters {
      activityDate
    }
    activityReports {
      activityDate
      sessionReports {
        sessionReportId
      }
    }
    sessions {
      sessionId
    }
  }

`;