import { makeStyles, createStyles } from '@material-ui/styles';

export const useTableStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '500px',
      border: 'none',
      '& .MuiDataGrid-columnsContainer': {
        justifyContent: 'center',
        backgroundColor: '#F9F9F9',
        borderBottom: 'none',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-selectedRowCount': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-columnHeader--moving': {
        background: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: '#F9F9F9',
      },
    },
    cell: {
      borderBottom: 'none !important',
    },
  })
);