import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useCampfireTheme } from '../theme/useCampfireTheme';

export interface ActionsHeaderProps {
  title: string;
  subtitle?: string;
  children?: ReactNode;
}

export const ActionsHeader = (props: ActionsHeaderProps) => {
  const { theme, isMd, isMobile } = useCampfireTheme();
  const { title, subtitle, children } = props;
  return !isMobile || !isMd ? (
    <Box
      display={'flex'}
      alignItems='center'
      justifyContent='space-between'
      borderBottom={`1px solid #bbbbbb`}
      style={{ minHeight: '72px' }}
    >
      <Box>
        <Typography
          variant='h6'
          style={{
            color: theme.color.grey.neutralBrand800,
            fontSize: '20px',
            fontWeight: 800,
            whiteSpace: 'break-spaces',
            letterSpacing: '-0.02em',
            lineHeight: 1.2,
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant='h6'
            style={{
              color: theme.color.grey.neutralBrand800,
              fontSize: '10px',
              fontWeight: 400,
              whiteSpace: 'break-spaces',
              letterSpacing: '-0.02em',
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box display='flex'>{children}</Box>
    </Box>
  ) : (
    <Box alignItems='center' justifyContent='flex-end' borderBottom={`1px solid ${theme.color.grey.border}`}>
      <Box display='flex' justifyContent={isMobile ? 'flex-end' : 'normal'}>
        {children}
      </Box>
      <Typography
        variant='h6'
        style={{
          color: theme.color.grey.neutralBrand800,
          fontSize: '20px',
          fontWeight: 900,
          marginTop: '20px',
          marginBottom: '5px',
          whiteSpace: 'break-spaces',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
