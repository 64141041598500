import { gql } from '@apollo/client';

export const GET_PROGRAMS = gql`
  query GetPrograms {
    vm {
      programs {
        programId
        name
        description
        image
        dateSuspended
        dateDeleted
        isHidden
        isRestrictedProgram
        activities {
          activityId
        }
        activeVolunteers {
          volunteerId
        }
        programApplications {
          programApplicationId
          volunteer {
            volunteerId
          }
        }
      }
    }
  }
`;

export const GET_VOLUNTEER_PROGRAM_DATA = gql`
  query GetVolunteerProgramData($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        programs {
          programId
        }
        programApplications {
          programApplicationId
          program {
            programId
          }
          dateWithdrawn
        }
      }
    }
  }
`;
