import { encodeDate } from '@campfire/hot-date';
import { useState } from 'react';
import { SnackBarOptions } from '../../../../../common/snackbars/TemplateSnackbar';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { MyElementsUpcomingRoster } from './__generated__/MyElementsUpcomingRoster';
import { AxiosResponseWithOk } from '../../../../../global/network/useAxiosFetch';
import { useSharedSessionFullPopupState } from './MyElementsUpcomingRostersList';

export const useUpcomingRostersFetch = (refetch?: () => void) => {
  const apiUrl = useApiUrl();
  const [snackBarOptions, setSnackBarOptions] = useState<SnackBarOptions>();
  const { setPopUpWindowOptions } = useSharedSessionFullPopupState();

  const updateRosteredStatus = useCampfireFetch({ defer: true });

  function resetSnackbar() {
    setSnackBarOptions(undefined);
  }

  function handleResponse(
    res: AxiosResponseWithOk<any>,
    successMsg: string,
    variant?: 'warning' | 'success' | 'error' | 'info'
  ) {
    if (refetch) refetch()
    if (res.ok) { 
      if (res.data.data && res.data.data.rosters && res.data.data.rosters.sessionsRemoved.length) {
        setPopUpWindowOptions({
          data: res.data.data.rosters,
          open: true
        })
      }
      else {
        setSnackBarOptions({
          variant: variant ?? 'success',
          message: successMsg,
          open: true
        })
      }
    }
  }

  function createAvailability(upcomingRoster: MyElementsUpcomingRoster, selectedDates: string[], sessionIds?: string[]) {
    // TODO: make sure this isn't called for rosters without activity enrolments
    if (
      upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterUnspecifiedType' ||
      upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterUnavailabilityType' ||
      upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterAvailabilityType'
    ) {
      resetSnackbar();
      updateRosteredStatus.run({
        url: `${apiUrl}/vm/activity/enrolment/availability/save`,
        method: 'post',
        data: {
          activityAvailabilityId:
            upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterAvailabilityType'
              ? upcomingRoster.activityAvailability.activityAvailabilityId
              : undefined,

          activityEnrolmentId: upcomingRoster.activityEnrolment.activityEnrolmentId,
          activityDates: selectedDates,
          // todo: fuck this
          activityRoleIds: [],
          sessionIds: sessionIds ?? [],
        },
      }).then((res) => handleResponse(res, 'Added availability'));
    }
  }

  function createUnavailability(upcomingRoster: MyElementsUpcomingRoster) {
    if (
      upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterCancellationType'
    ) {
      return;
    }

    const activityEnrolmentId = upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterRosteringType'
      ? upcomingRoster.nullableActivityEnrolment?.activityEnrolmentId
      : upcomingRoster.activityEnrolment.activityEnrolmentId

    resetSnackbar();
    updateRosteredStatus.run({
      url: `${apiUrl}/vm/activity/enrolment/unavailability/save`,
      method: 'post',
      data: {
        activityEnrolmentId: activityEnrolmentId,
        activityDate: encodeDate(upcomingRoster.activityDate),
      },
    }).then((res) => handleResponse(res, 'Marked unavailable date', 'info'));
  }

  function createAttendance(upcomingRoster: MyElementsUpcomingRoster, selectedDates: string[], sessionIds?: string[],) {
    if (
      upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterCancellationType'
    ) {
      return;
    }

    const activityEnrolmentId = upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterRosteringType'
    ? upcomingRoster.nullableActivityEnrolment?.activityEnrolmentId
    : upcomingRoster.activityEnrolment.activityEnrolmentId

    const activityAvailabilityId = upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterAvailabilityType'
    ? upcomingRoster.activityAvailability.activityAvailabilityId
    : null
   
    resetSnackbar();
    updateRosteredStatus.run({
      url: `${apiUrl}/vm/activity/enrolment/attendance/add`,
      method: 'post',
      data: {
        activityId: upcomingRoster.activity.activityId,
        activityEnrolmentId: activityEnrolmentId,
        activityAvailabilityId: activityAvailabilityId,
        activityDates: selectedDates,
        sessionIds: sessionIds ?? []
      },
    }).then((res) => handleResponse(res, 'Added Attendance'))
  }

  function removeAttendance(upcomingRoster: MyElementsUpcomingRoster) {
    if (upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterRosteringType') {

      resetSnackbar();
      updateRosteredStatus.run({
        url: `${apiUrl}/vm/activity/enrolment/attendance/remove`,
        method: 'post',
        data: {
          publishedRosteringId: upcomingRoster.rostering.publishedRosteringId
        }
      }).then((res) => {
        if (res.ok && upcomingRoster.nullableActivityEnrolment) {
          createUnavailability(upcomingRoster);
        } else {
          handleResponse(res, 'Attendance removed')
        }
      })
    }
  }

  return {
    snackBarOptions,
    createAvailability,
    createUnavailability,
    createAttendance,
    removeAttendance
  };
};
