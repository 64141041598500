import { Box } from '@material-ui/core';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ReportManagementDesktopScreenHeader } from './ReportManagementDesktopScreenHeaderWrapper';
import { ReportManagementMobileScreenHeader } from './ReportManagementMobileScreenHeader';

interface ReportManagementScreenHeaderWrapperProps {
  children: React.ReactNode;
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  setSearchInput: (value: string) => void;
}
const ReportManagementScreenHeaderWrapper = (props: ReportManagementScreenHeaderWrapperProps) => {
  const { children } = props;

  const [query] = useQueryParams({
    reportTypeId: StringParam,
    reportFieldId: StringParam,
  });

  const { isMobile } = useCampfireTheme();

  return (
    <Box display='flex' flexDirection='column' flex={1} overflow='hidden'>
      {isMobile ? (
        <ReportManagementMobileScreenHeader {...props} hide={Boolean(query?.reportTypeId || query?.reportFieldId)} />
      ) : (
        <ReportManagementDesktopScreenHeader {...props} />
      )}
      {children}
    </Box>
  );
};

export { ReportManagementScreenHeaderWrapper };
