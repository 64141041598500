import React, { useState } from 'react';
import { ProgramManagementProvider, useProgramManagementContext } from './ProgramManagementContext';
import { ManageProgramsPage } from './ManageProgramsPage';
import { ManageProgramsPageMobile } from './ManageProgramsPageMobile';
import { Page } from '../../../global/components';
import { ProgramManagementTutorialDialog } from './ProgramManagementTutorialDialog';

const ProgramManagementScreen = () => {
  return (
    <ProgramManagementProvider>
      <ProgramManagersInnerScreen />
    </ProgramManagementProvider>
  );
};

const ProgramManagersInnerScreen = () => {
  const { compact } = useProgramManagementContext();
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      {compact ? <ManageProgramsPageMobile /> : <ManageProgramsPage />}
      <ProgramManagementTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
        }}
      />
    </Page>
  );
};

export { ProgramManagementScreen };
