import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  TableBody,
  Button,
  Typography,
  Box,
  TableCellProps,
  TableFooter,
  TablePagination,
  Divider,
} from '@material-ui/core';
import { unpackToTime, unpackToDateTime } from '@campfire/hot-date';
import classNames from 'classnames';
import { chunk } from 'lodash';
import { DateTime } from 'luxon';
import { RosterCompletedStatus, RosterStatus } from './RosterStatus';
import { MaxWarning } from './MaxWarning';
import { useRosterTableStyles } from './RosterTableStyles';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import {
  getRosteringSessionStatus,
  getSessionStatuses,
  sortVolunteersByEnrolmentDate,
  sortVolunteersByRostering,
  sortVolunteersByAvailability,
} from '../roster-functions';
import { Volunteer } from '../roster-types';
import {
  GetRosterScreenRosterData_vm_activity_sessions as Session,
  GetRosterScreenRosterData_vm_draftRoster_draftRosterings_volunteer_profile as DraftRosteringProfile,
  GetRosterScreenRosterData_vm_draftRoster_publishedRoster_rosterings_volunteer_profile as PublishedRosteringProfile,
} from '../__generated__/GetRosterScreenRosterData';
import { GetRosterVolunteerInfo_vm_volunteer_activityRoles_tasks as ActivityRoleTasks } from '../__generated__/GetRosterVolunteerInfo';
import { FullscreenDialog } from '../../../../../../common/dialogs/FullscreenDialog';
import MobileWordmark from '../../../../../../assets/branding/logo/volaby-original.svg';
import { ActivityReport } from './ActivityReport';
import { SessionReportForm } from '../../../../activities/activity-timeline/past/report/form/SessionReportForm';
import { RosterRoleTasksDialog } from '../RosterRoleTasksDialog';
import { VolunteerCell } from './VolunteerCell';
import { PAGINATION_BOUNDARY, useSharedPagination } from '../../../usePagination';
import { SessionHeader } from './SessionHeader';
import { RosterNotesDialog } from '../../../common/RosterNotesDialog';
import { RosterScreenDraftRoster_sessionNotes as SessionNoteType } from '../__generated__/RosterScreenDraftRoster';

interface CellProps extends TableCellProps {
  isMobile?: boolean;
}

function TableCell(props: CellProps) {
  const { isMobile = false } = props;
  const classes = useRosterTableStyles({ isMobile });

  return <MuiTableCell classes={{ root: classes.cell }} {...props} />;
}

interface ComputedSession extends Session {
  isMaxed?: boolean;
}
interface Props {
  sessions: ComputedSession[];
  draftRosters: Volunteer[];
  onClickRosterStatus: (session: Session, roster: Volunteer) => void;
  isRosterCancelled?: boolean;
  isRosterCompleted?: boolean;
  onSubmitReport: any;
  onAddVoluteer?: () => void;
  isMobile?: boolean;
  publishedRoster: any;
  activityDate: string;
  onChangeAvailability: (volunteerId: string) => void;
  sortBy?: string;
  sortedVolunteerIdsByEnrolmentDate?: string[];
  sessionNotes: SessionNoteType[];
  onChangeSessionNote: (sessionId: string, notes: string) => void;
}

interface TableForPrintProps extends Props {
  activityName: string;
  activityDate: string;
  orgLogo?: string | null;
}

export const displayTimeFunc = (session: { startTime?: string; endTime?: string }) => {
  const timeStringFunc = (time?: string) => (time ? unpackToTime(time).toFormat('h:mm a') : 'Unset time');
  return `${timeStringFunc(session.startTime)} - ${timeStringFunc(session.endTime)}`;
};

export const sessionAttendingCount = (rosters: Volunteer[]) => (session: Session) =>
  (session.minVolunteers || 0) -
  rosters.filter(({ draftedSessionIds }) => draftedSessionIds && draftedSessionIds.includes(session.sessionId)).length;

function getSessionGroupVolRows(sessionGroup: Session[], draftRosters: Volunteer[]) {
  const volsAttendanceBySession = sessionGroup.reduce((acc: any, session) => {
    const { sessionId } = session;
    const volsRostered = draftRosters.filter(
      (roster) => roster.publishedSessionIds && roster.publishedSessionIds.includes(sessionId)
    );
    return { ...acc, [sessionId]: [...(acc[sessionId] || []), ...volsRostered] };
  }, {});

  const numberOfAttendacesPerSession = Object.values(volsAttendanceBySession).map(
    (volsAttendance: any) => volsAttendance.length
  );
  const totalRows: number = Math.max(...numberOfAttendacesPerSession);

  return totalRows > 0
    ? Array.from(Array(totalRows).keys()).map((a) =>
        sessionGroup.map((session) => volsAttendanceBySession[session.sessionId][a])
      )
    : [];
}

export function RosterTableForPrint({
  sessionNotes,
  sessions,
  draftRosters,
  isRosterCompleted,
  isRosterCancelled,
  isMobile = false,
  sortBy,
  sortedVolunteerIdsByEnrolmentDate,
  activityName,
  activityDate,
  orgLogo,
}: TableForPrintProps) {
  const classes = useRosterTableStyles({ isMobile });
  const { theme } = useCampfireTheme();

  const showVolunteerRow =
    sessions.some((session) => session.isMaxed || sessionAttendingCount(draftRosters)(session) > 0) &&
    !isMobile &&
    !isRosterCancelled &&
    !isRosterCompleted;
  const sortedDraftRosters = React.useMemo(() => {
    return draftRosters.sort((a, b) => {
      if (!a?.profile || !b?.profile) {
        return 0;
      }
      return sortBy === 'alphabetical'
        ? a.profile.preferredName.localeCompare(b.profile.preferredName)
        : sortBy === 'newest' && sortedVolunteerIdsByEnrolmentDate
        ? sortVolunteersByEnrolmentDate(a, b, sortedVolunteerIdsByEnrolmentDate)
        : sortBy === 'availability'
        ? sortVolunteersByAvailability(a, b)
        : sortVolunteersByRostering(a, b);
    });
  }, [sortBy, draftRosters, sortedVolunteerIdsByEnrolmentDate]);

  const fiveSessionGroups = chunk(sessions, 5);
  const getPrintPageStyle = () => {
    return `@media print {
    body {-webkit-print-color-adjust: exact !important;  color-adjust: exact !important; print-color-adjust: exact !important;}
    }, @page { size: landscape !important; -webkit-print-color-adjust: exact !important;  color-adjust: exact !important; print-color-adjust: exact !important;
    } `;
  };

  return (
    <React.Fragment>
      <style type='text/css' media='print'>
        {getPrintPageStyle()}
      </style>
      <div
        className='page-header'
        style={{
          position: 'fixed',
          top: '0mm',
          width: '100%',
          height: '100px',
          pageBreakAfter: 'always',
        }}
      >
        <Box
          width='100%'
          display='flex'
          justifyContent='space-between'
          borderBottom='1px solid #dcdcdc'
          padding='10px 0'
          alignItems='center'
        >
          <Box style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}>
            <Box>
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: '20px',
                  color: theme.color.grey.neutralBrand800,
                }}
              >
                {activityName}
              </Typography>
              <Typography
                style={{
                  fontSize: '13px',
                  color: theme.color.grey.neutralBrand800,
                }}
              >
                {DateTime.fromISO(activityDate).toFormat('d')} {DateTime.fromISO(activityDate).toFormat('LLLL y')}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' alignItems='center' padding='0 10px'>
            <img src={MobileWordmark} width={100} alt='volaby-logo' />
            <Divider
              orientation='vertical'
              variant='middle'
              style={{ marginLeft: '10px', marginRight: '10px' }}
              flexItem
            />
            {orgLogo && <img src={orgLogo} width={100} alt='organisation-logo' />}
          </Box>
        </Box>
      </div>

      {fiveSessionGroups.map((sessionGroup) => (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className='page-header-space' style={{ height: '100px' }} />
                </TableCell>
              </TableRow>
              <TableRow
                style={{
                  pageBreakInside: 'avoid',
                }}
              >
                {sessionGroup.map((session: any) => (
                  <TableCell key={session.id} className={classes.rowName} style={{ verticalAlign: 'top' }}>
                    <SessionHeader
                      session={session}
                      draftRosters={draftRosters}
                      showVolunteerRow={showVolunteerRow}
                      hasSessionNotes={Boolean(
                        sessionNotes.find((sessionNote) => sessionNote.session.sessionId === session.sessionId)
                      )}
                      printOnly
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getSessionGroupVolRows(sessionGroup, sortedDraftRosters).map((row) => (
                <TableRow
                  classes={{ root: classes.rowBody }}
                  style={{
                    pageBreakInside: 'avoid',
                  }}
                >
                  {row.map((roster: any) =>
                    roster ? (
                      <TableCell key={roster.id}>
                        <VolunteerCell roster={roster} printOnly />
                      </TableCell>
                    ) : (
                      <TableCell />
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className='page-break' style={{ pageBreakAfter: 'always' }} />
        </>
      ))}
    </React.Fragment>
  );
}

export function RosterTable({
  sessionNotes,
  onChangeSessionNote,
  sessions,
  draftRosters,
  onClickRosterStatus,
  isRosterCompleted,
  isRosterCancelled,
  isMobile = false,
  publishedRoster,
  activityDate,
  onSubmitReport,
  onChangeAvailability,
  sortBy,
  sortedVolunteerIdsByEnrolmentDate,
}: Props) {
  const classes = useRosterTableStyles({ isMobile });
  const { theme } = useCampfireTheme();
  const sessionStatusTheme = getSessionStatuses(theme);
  const isAttendingRoster = (roster: any, session: any) => {
    return roster.publishedSessionIds.includes(session.sessionId);
  };
  const showVolunteerRow =
    sessions.some((session) => session.isMaxed || sessionAttendingCount(draftRosters)(session) > 0) &&
    !isMobile &&
    !isRosterCancelled &&
    !isRosterCompleted;

  const isReportCompleted = (session: any) => session?.reports?.length > 0;
  const [currentSession, setCurrentSession] = React.useState<any>();
  const [isEdittingReport, setIsEdittingReport] = React.useState<boolean>(false);
  const [openRosterRoleTasksDialog, setOpenRosterRoleTasksDialog] = React.useState(false);
  const [roleTasks, setRoleTasks] = React.useState<ActivityRoleTasks[]>([]);
  const [roleTasksProfile, setRoleTasksProfile] = React.useState<DraftRosteringProfile | PublishedRosteringProfile>();
  const onReportClick = (session: any) => {
    setCurrentSession(session);
  };
  const getName = (profile: any) => `${profile?.preferredName} ${profile?.lastName}`;
  const isPublished = Boolean(publishedRoster);
  const publishedBy = publishedRoster?.publishedByManager || publishedRoster?.publishedByVolunteer;
  const datePublished = publishedRoster?.datePublished;
  const onDialogClose = () => {
    setCurrentSession(undefined);
    setIsEdittingReport(false);
  };
  const onSubmitSuccess = () => {
    onSubmitReport();
    onDialogClose();
    setIsEdittingReport(false);
  };

  const sortedDraftRosters = React.useMemo(() => {
    return draftRosters.sort((a, b) => {
      if (!a?.profile || !b?.profile) {
        return 0;
      }
      return sortBy === 'alphabetical'
        ? a.profile.preferredName.localeCompare(b.profile.preferredName)
        : sortBy === 'newest' && sortedVolunteerIdsByEnrolmentDate
        ? sortVolunteersByEnrolmentDate(a, b, sortedVolunteerIdsByEnrolmentDate)
        : sortBy === 'availability'
        ? sortVolunteersByAvailability(a, b)
        : sortVolunteersByRostering(a, b);
    });
  }, [sortBy, draftRosters, sortedVolunteerIdsByEnrolmentDate]);

  const handleRoleBadgeClick = (
    rosterRoleTasks: ActivityRoleTasks[],
    profile: DraftRosteringProfile | PublishedRosteringProfile
  ) => {
    setRoleTasks(rosterRoleTasks);
    setRoleTasksProfile(profile);
    setOpenRosterRoleTasksDialog(true);
  };

  const handleRoleTaskDialogClose = () => {
    setRoleTasks([]);
    setRoleTasksProfile(undefined);
    setOpenRosterRoleTasksDialog(false);
  };

  const { page, setPage, perPage, setPerPage } = useSharedPagination();

  const handleChangePage = (_unusedEvent: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayingRosters =
    isMobile || sortedDraftRosters.length < PAGINATION_BOUNDARY
      ? sortedDraftRosters
      : sortedDraftRosters.slice(page * perPage, page * perPage + perPage);

  const [selectedSessionId, setSelectedSessionId] = React.useState<string | undefined>();

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          {isMobile ? (
            <>
              <TableRow>
                <TableCell
                  key={sessions[0].sessionId}
                  className={classes.mobileHeaderName}
                  style={{
                    fontWeight: 700,
                    paddingTop: '10px',
                    paddingBottom: '2px !important',
                    fontSize: '18px',
                    color: theme.color.grey.neutralBrand800,
                  }}
                  isMobile={isMobile}
                >
                  {sessions[0].name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  key={sessions[0].sessionId}
                  className={classes.rowTime}
                  style={{
                    paddingLeft: 0,
                    textAlign: 'left',
                    paddingTop: '0px',
                    fontSize: '13px',
                    paddingBottom: '5px',
                    color: theme.color.grey.neutralBrand800,
                  }}
                >
                  <Typography className={classes.rowTime}>{displayTimeFunc(sessions[0])}</Typography>
                  <Box>
                    {sessions[0].isMaxed ? (
                      <MaxWarning />
                    ) : (
                      sessionAttendingCount(draftRosters)(sessions[0]) > 0 && (
                        <Typography className={classes.rowTime}>
                          Need <strong>{sessionAttendingCount(draftRosters)(sessions[0])}</strong> volunteers
                        </Typography>
                      )
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </>
          ) : (
            <React.Fragment>
              <TableRow>
                <TableCell className={classes.nameColumn}></TableCell>
                {sessions.map((session: any) => (
                  <TableCell key={session.id} className={classes.rowName} style={{ verticalAlign: 'top' }}>
                    <SessionHeader
                      onClick={() => {
                        setSelectedSessionId(session.sessionId);
                      }}
                      session={session}
                      draftRosters={draftRosters}
                      showVolunteerRow={showVolunteerRow}
                      hasSessionNotes={Boolean(
                        sessionNotes.find((sessionNote) => sessionNote.session.sessionId === session.sessionId)
                      )}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </React.Fragment>
          )}
          {isMobile
            ? isPublished && (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    style={{ paddingTop: 0, paddingBottom: '1.5rem', paddingLeft: 0, textAlign: 'left' }}
                  >
                    <Typography style={{ color: '#646464', fontSize: '0.75rem' }}>
                      This roster published by <strong>{getName(publishedBy?.profile)}</strong> on{' '}
                      {unpackToDateTime(datePublished).toFormat('dd.MM.yy')}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            : isPublished && (
                <TableRow>
                  <TableCell />
                  <TableCell colSpan={sessions.length} style={{ paddingTop: 0, paddingBottom: '1.5rem' }}>
                    <Typography style={{ color: '#646464', fontSize: '0.75rem' }}>
                      This roster published by <strong>{getName(publishedBy?.profile)}</strong> on{' '}
                      {unpackToDateTime(datePublished).toFormat('dd.MM.yy')}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
          {isRosterCompleted && (
            <React.Fragment>
              <TableRow>
                {!isMobile && <TableCell className={classes.rowTime} />}
                {sessions.map((session: any) => (
                  <TableCell
                    key={session.id}
                    colSpan={isMobile ? 2 : 1}
                    style={isMobile ? { paddingRight: '0.5rem', paddingLeft: '0.5rem' } : {}}
                    className={classes.rowTime}
                  >
                    <Button
                      classes={{
                        root: classNames(classes.reviewButton, {
                          [classes.completeReport]: isReportCompleted(session),
                          [classes.inCompleteReport]: !isReportCompleted(session),
                        }),
                        disabled: classes.inCompleteReport,
                      }}
                      onClick={() => onReportClick(session)}
                      style={isMobile ? { width: '100%', height: 33 } : {}}
                    >
                      {isReportCompleted(session) ? 'See Activity Report' : 'Incomplete Report'}
                    </Button>
                  </TableCell>
                ))}
              </TableRow>
            </React.Fragment>
          )}
          {isMobile && (
            <TableRow>
              <TableCell style={{ borderBottom: '1px solid lightgray', height: 0, padding: 0 }} />
              <TableCell style={{ borderBottom: '1px solid lightgray', height: 0, padding: 0 }} />
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {displayingRosters.map((roster: any) => (
            <TableRow key={roster.id} classes={isMobile ? {} : { root: classes.rowBody }}>
              <TableCell isMobile={isMobile}>
                <VolunteerCell
                  roster={roster}
                  handleRoleBadgeClick={handleRoleBadgeClick}
                  isRosterCompleted={isRosterCompleted}
                  onChangeAvailability={onChangeAvailability}
                  hideActions={isMobile}
                />
              </TableCell>
              {sessions.map((session: any) => (
                <TableCell key={session.id} isMobile={isMobile}>
                  {isRosterCompleted ? (
                    <RosterCompletedStatus color={isAttendingRoster(roster, session) ? '#ABDC40' : '#788B9D'} />
                  ) : (
                    <RosterStatus
                      onClick={() => onClickRosterStatus(session, roster)}
                      text={getRosteringSessionStatus(session.sessionId, roster, sessionStatusTheme).text}
                      disabled={isRosterCancelled}
                      color={getRosteringSessionStatus(session.sessionId, roster, sessionStatusTheme).color}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {isMobile && (
          <>
            <TableRow style={{ height: '25px' }}>
              <TableCell style={{ borderBottom: '1px solid lightgray', height: 0, padding: 0 }} />
              <TableCell style={{ borderBottom: '1px solid lightgray', height: 0, padding: 0 }} />
            </TableRow>
            <TableRow style={{ height: '25px' }}></TableRow>
          </>
        )}
        {!isMobile && sortedDraftRosters.length > PAGINATION_BOUNDARY && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={sortedDraftRosters.length}
                rowsPerPageOptions={[PAGINATION_BOUNDARY]}
                page={page}
                rowsPerPage={perPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
      {Boolean(currentSession) && (
        <FullscreenDialog title='Session report' fullScreen={isMobile} open close={onDialogClose}>
          {isReportCompleted(currentSession) && !isEdittingReport ? (
            <ActivityReport session={currentSession} />
          ) : (
            <Box>
              {
                <SessionReportForm
                  session={currentSession}
                  publishedRoster={publishedRoster}
                  activityDate={activityDate}
                  onCancelClick={onDialogClose}
                  onSubmitSuccess={onSubmitSuccess}
                  sessionReport={currentSession.reports?.length > 0 ? currentSession.reports[0] : undefined}
                />
              }
            </Box>
          )}
        </FullscreenDialog>
      )}
      {roleTasksProfile && openRosterRoleTasksDialog && (
        <RosterRoleTasksDialog
          open
          roleTasks={roleTasks}
          profile={roleTasksProfile}
          onSubmitReport={onSubmitReport}
          onClose={handleRoleTaskDialogClose}
        />
      )}
      {selectedSessionId && (
        <RosterNotesDialog
          open
          onCancel={() => setSelectedSessionId(undefined)}
          rosterNotes={sessionNotes.find((sessionNote) => sessionNote.session.sessionId === selectedSessionId)?.notes}
          onSave={(notes) => {
            onChangeSessionNote(selectedSessionId, notes);
            setSelectedSessionId(undefined);
          }}
          title='Session notes'
          subject='session'
        />
      )}
    </React.Fragment>
  );
}

export function RosterTableMobile({
  sessions,
  draftRosters,
  onClickRosterStatus,
  isRosterCompleted,
  isRosterCancelled,
  onAddVoluteer,
  publishedRoster,
  onSubmitReport,
  activityDate,
  onChangeAvailability,
  sessionNotes,
  onChangeSessionNote,
}: Props) {
  return (
    <React.Fragment>
      {sessions.map((session) => (
        <RosterTable
          sessionNotes={sessionNotes}
          onChangeSessionNote={onChangeSessionNote}
          onChangeAvailability={onChangeAvailability}
          key={session.sessionId}
          sessions={[session]}
          draftRosters={draftRosters}
          onClickRosterStatus={onClickRosterStatus}
          isRosterCompleted={isRosterCompleted}
          isRosterCancelled={isRosterCancelled}
          isMobile
          onAddVoluteer={onAddVoluteer}
          publishedRoster={publishedRoster}
          onSubmitReport={onSubmitReport}
          activityDate={activityDate}
        />
      ))}
    </React.Fragment>
  );
}
