import React from 'react';
import { DateTime } from 'luxon';
import { Box, MenuItem, TextField, Typography, Button, Theme } from '@material-ui/core';
import { unpackToDate } from '@campfire/hot-date';
import { makeStyles, createStyles } from '@material-ui/styles';

import { DaySelect } from '../../../../common/DaySelect';
import { FullscreenDialog } from '../../../../common/dialogs/FullscreenDialog';
import { Recurrence } from '../../../../common/recurrence/__generated__/Recurrence';
import { RecurrenceEnum } from '../../../../__generated__/globalTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    label: {
      fontWeight: 700,
      fontSize: 12,
    },
    cancelButton: {
      color: theme.color.secondary.main700,
      backgroundColor: theme.color.primary.extraLight,
      borderRadius: 6,
      fontSize: 14,
    },
  })
);

interface ActivityRecurrenceProps {
  open: boolean;
  onClose: () => void;
  fullScreen: boolean;
  value: Recurrence;
  onChange: (newValue: Recurrence) => void;
}

type RecurrentType =
  | 'DailyRecurrenceType'
  | 'WeeklyRecurrenceType'
  | 'MonthlyByMonthDayRecurrenceType'
  | 'MonthlyNthDayRecurrenceType'
  | 'YearlyRecurrenceType'
  | 'SingleRecurrenceType';

const PERIODS = [
  {
    value: 'DailyRecurrenceType',
    label: 'Days',
  },
  {
    value: 'WeeklyRecurrenceType',
    label: 'Weeks',
  },
  {
    value: 'MonthlyNthDayRecurrenceType',
    label: 'Months (By weekday)',
  },
  {
    value: 'MonthlyByMonthDayRecurrenceType',
    label: 'Months (By monthday)',
  },
  {
    value: 'YearlyRecurrenceType',
    label: 'Years',
  },
];

const DAYVALUES = {
  MO: 1,
  TU: 2,
  WE: 3,
  TH: 4,
  FR: 5,
  SA: 6,
  SU: 7,
};

const DAYKEYS = Object.keys(DAYVALUES) as RecurrenceEnum[];

const DAYOPTIONS = 'MTWTFSS'.split('').map((day, dayIndex) => ({
  value: dayIndex,
  label: day,
}));

const parseValue = (value: Recurrence) => {
  switch (value.__typename) {
    case 'DailyRecurrenceType':
      return { type: value.__typename, interval: value.dayInterval, days: [] };
    case 'WeeklyRecurrenceType':
      return { type: value.__typename, interval: value.weekInterval, days: value.days };
    case 'MonthlyByMonthDayRecurrenceType':
      return { type: value.__typename, interval: value.monthInterval, days: [] };
    case 'MonthlyNthDayRecurrenceType':
      return { type: value.__typename, interval: value.monthInterval, days: [value.day] };
    case 'YearlyRecurrenceType':
      return { type: value.__typename, interval: value.yearInterval, days: [] };
    default:
      return { type: value.__typename, interval: 1, days: [] };
  }
};

const convertValue = (type: RecurrentType, interval: number, days: RecurrenceEnum[], startDate?: string) => {
  const packStartDate = startDate ? unpackToDate(startDate) : DateTime.local();

  switch (type) {
    case 'DailyRecurrenceType':
      return { __typename: type, dayInterval: interval };
    case 'WeeklyRecurrenceType':
      return { __typename: type, weekInterval: interval, days };
    case 'MonthlyByMonthDayRecurrenceType':
      return {
        __typename: type,
        monthInterval: interval,
        monthDay: packStartDate.day,
      };
    case 'MonthlyNthDayRecurrenceType':
      return {
        __typename: type,
        monthInterval: interval,
        day: DAYKEYS[packStartDate.weekday - 1] as RecurrenceEnum,
        n: Math.round(packStartDate.day / 7) + 1,
      };
    case 'YearlyRecurrenceType':
      return { __typename: type, yearInterval: interval };
    default:
      return { __typename: type, interval };
  }
};

type DaysSelected = {
  [key: number]: boolean;
};

export function SimpleActivityRecurrence({ open, onClose, fullScreen, value, onChange }: ActivityRecurrenceProps) {
  const { type, interval, days } = parseValue(value);
  const [activityType, setActivityType] = React.useState(type);
  const [activityInterval, setActivityInterval] = React.useState(interval);
  const [activityDays, setActivityDays] = React.useState<DaysSelected>(
    (days as RecurrenceEnum[]).reduce(
      (acc, cur) => ({
        ...acc,
        [DAYVALUES[cur]]: true,
      }),
      {} as DaysSelected
    )
  );

  const classes = useStyles();

  const onDone = () => {
    onChange({
      ...value,
      ...convertValue(
        activityType,
        activityInterval,
        Object.keys(activityDays)
          .map((activityKey) => parseInt(activityKey, 10))
          .filter((activityKey) => activityDays[activityKey])
          .map((activityKey) => DAYKEYS[activityKey])
      ),
    });
    onClose();
  };

  return (
    <FullscreenDialog
      title='Custom Recurrence'
      open={open}
      close={onClose}
      fullScreen={fullScreen}
      dialogActions={() => (
        <React.Fragment>
          <Button variant='contained' className={classes.cancelButton} disableElevation onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={onDone}
            variant='contained'
            color='primary'
            style={{ flex: 1, borderRadius: '6px', fontSize: 14 }}
            disableElevation
          >
            Done
          </Button>
        </React.Fragment>
      )}
    >
      <Box>
        <Box display='flex' alignItems='center' marginTop='30px'>
          <Typography className={classes.label}>REPEAT EVERY</Typography>
          <TextField
            type='number'
            variant='outlined'
            size='small'
            name='interval'
            value={activityInterval}
            onChange={(event: any) => setActivityInterval(event.target.value)}
            style={{ marginLeft: 12, maxWidth: '5rem' }}
          />
          <TextField
            select
            variant='outlined'
            size='small'
            style={{ marginLeft: 12, minWidth: '6rem' }}
            name='__typename'
            onChange={(event: any) => setActivityType(event.target.value)}
            value={activityType}
          >
            {PERIODS.map(({ value: period, label }) => (
              <MenuItem key={period} value={period}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {['WeeklyRecurrenceType'].includes(activityType) && (
          <Box marginTop='30px'>
            <Typography
              className={classes.label}
              style={{ borderBottom: '1px solid #9E9E9E', marginBottom: '12px', paddingBottom: '3px' }}
            >
              DAYS
            </Typography>
            <DaySelect
              variant='primary'
              days={DAYOPTIONS}
              daysSelected={activityDays}
              onSelectDay={(dayValue) => setActivityDays({ ...activityDays, [dayValue]: !activityDays[dayValue] })}
            />
          </Box>
        )}
      </Box>
    </FullscreenDialog>
  );
}
