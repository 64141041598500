import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Collapse, Grid, IconButton, List, ListItem, Typography, Box } from '@material-ui/core';
import { Add, Clear, Edit } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { StringParam, useQueryParam } from 'use-query-params';
import { SemanticTime } from '../../../../../common/dates/SemanticTime';
import { ConfirmationDialog } from '../../../../../common/dialogs/ConfirmationDialog';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';
import { SearchField } from '../../../../../common/inputs/SearchField';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { ProfileHoverBlock } from '../../../../general/user-profile/hover-text/ProfileHoverBlock';
import { PROGRAM_MANAGEMENT_GET_MANAGERS_OF_PROGRAM } from '../../program-management.gql';
import { useProgramManagementContext } from '../../ProgramManagementContext';
import {
  ProgramManagementGetManagersOfProgram,
  ProgramManagementGetManagersOfProgramVariables,
} from '../../__generated__/ProgramManagementGetManagersOfProgram';
import { ProgramManagementProgramManager } from '../../__generated__/ProgramManagementProgramManager';
import { AddManagerDialog } from '../dialogs/AddManagerDialog';
import { FormSectionSkeleton } from './FormSectionSkeleton';
import { LockedSectionContent } from './LockedSectionContent';
import { AlertCard, getAlertCardColors } from '../../../../../common/cards/alert-card/AlertCard';

const ProgramManagerFormSectionDescription = () => (
  <Typography variant='body2' color='textSecondary' style={{ marginBottom: 8 }}>
    All Managers on this Program
  </Typography>
);

export const ProgramManagersSectionLocked = () => (
  <FormSectionV2 title='Managers' description={<ProgramManagerFormSectionDescription />}>
    <LockedSectionContent text='Please save Basic Info before adding managers' />
  </FormSectionV2>
);

export const ProgramManagersSection = () => {
  const {
    runRemoveProgramManager,
    updateProgramManagersIsFulfilled,
    updateProgramManagersIsLoading,
    compact,
    isShowAlert,
  } = useProgramManagementContext();
  const [selectedProgramId] = useQueryParam('pid', StringParam);
  const [searchInput, setSearchInput] = useState<string>();
  const [inEditMode, setInEditMode] = useState(false);
  const [showAddManagerDialog, setShowAddManagerDialog] = useState(false);
  const [expandManagersSection, setExpandManagersSection] = useState(false);
  const [selectedProgramManager, setSelectedProgramManager] = useState<ProgramManagementProgramManager>();

  const { lightTextColor } = getAlertCardColors('info');

  const {
    data: getProgramManagersData,
    loading: getProgramManagersLoading,
    refetch: refetchProgramManagersData,
  } = useCampfireQuery<ProgramManagementGetManagersOfProgram, ProgramManagementGetManagersOfProgramVariables>(
    PROGRAM_MANAGEMENT_GET_MANAGERS_OF_PROGRAM,
    {
      options: {
        variables: {
          programId: selectedProgramId,
        },
      },
    }
  );

  const program = getProgramManagersData?.vm.program || undefined;

  const allProgramManagers = useMemo(() => program?.programManagers || [], [program]);

  const filteredProgramManagers = useMemo(
    () =>
      allProgramManagers
        .filter((pm) =>
          searchInput
            ? pm.manager.profile.preferredName
                .concat(` ${pm.manager.profile.lastName}`)
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : true
        )
        .sort((a, b) => a.manager.profile.preferredName.localeCompare(b.manager.profile.preferredName)),
    [allProgramManagers, searchInput]
  );

  const missingVolunteers = useMemo(() => program && !(program?.activeVolunteers?.length >= 1), [program]);

  useEffect(() => setInEditMode(false), [program]);
  useEffect(() => {
    if (updateProgramManagersIsFulfilled) setSelectedProgramManager(undefined);
  }, [updateProgramManagersIsFulfilled]);

  const handleSuccess = () => {
    if (refetchProgramManagersData) refetchProgramManagersData();
  };

  if (getProgramManagersLoading)
    return <FormSectionSkeleton title='Managers' description={<ProgramManagerFormSectionDescription />} />;

  if (missingVolunteers) {
    return (
      <FormSectionV2 title='Managers' description={<ProgramManagerFormSectionDescription />}>
        <LockedSectionContent text='You must add volunteers before you can assign managers.' />
      </FormSectionV2>
    );
  }

  return (
    <FormSectionV2
      title='Managers'
      description={<ProgramManagerFormSectionDescription />}
      search={
        <SearchField
          data-track='fs-prgMan-search-managers'
          placeholder='Search Managers'
          onChange={(e) => setSearchInput(e.target.value)}
          fullWidth={compact}
        />
      }
    >
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12} container justify='space-between' style={{ marginTop: '8px' }}>
          <Typography variant='subtitle2' color='textSecondary'>
            {`${allProgramManagers.length} ${allProgramManagers.length === 1 ? 'manager' : 'managers'} ${
              compact ? '' : ' assigned to this program'
            }`}
          </Typography>
          {!inEditMode ? (
            <HoverText
              data-track='fs-prgMan-edit-managers'
              variant='body2'
              color='primary'
              disabled={!program?.name || !(program?.activeVolunteers.length >= 1)}
              onClick={() => {
                setInEditMode(true);
                setExpandManagersSection(true);
              }}
              invertUnderlineBehaviour
            >
              Edit Managers
              <Edit style={{ fontSize: 12, marginLeft: 4 }} />
            </HoverText>
          ) : null}
        </Grid>
        {allProgramManagers.length > 0 ? (
          <Grid item style={{ marginTop: '-12px' }}>
            {expandManagersSection ? (
              <HoverText
                variant='body2'
                color='primary'
                onClick={() => {
                  setInEditMode(false);
                  setExpandManagersSection(false);
                }}
              >
                Hide all managers
              </HoverText>
            ) : (
              <HoverText variant='body2' color='primary' onClick={() => setExpandManagersSection(true)}>
                Show all managers
              </HoverText>
            )}
          </Grid>
        ) : null}
        {isShowAlert && (
          <Box p={2}>
            <AlertCard variant='info' title='Your pricing may change'>
              <Typography
                variant='subtitle2'
                display='inline'
                style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
              >
                Adding a Program Manager to your account beyond your included allocation may increase your monthly
                subscription fee. Reach out to our Support team to discuss your subscription fees and inclusions.
              </Typography>
            </AlertCard>
          </Box>
        )}
        {inEditMode ? (
          <Grid item container direction='row' spacing={2} alignItems='center' style={{ margin: 0 }}>
            <Grid item>
              <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>
                Editing Managers:
              </Typography>
            </Grid>
            <Grid item>
              <TabletButton
                data-track='fs-prgMan-edit-managers-cancel'
                color='error'
                onClick={() => setInEditMode(false)}
              >
                Cancel
              </TabletButton>
            </Grid>
          </Grid>
        ) : null}
        <Collapse in={expandManagersSection}>
          <Grid item xs={12}>
            <List>
              {filteredProgramManagers ? (
                <FixedSizeList itemSize={60} height={400} width='100%' itemCount={filteredProgramManagers.length}>
                  {({ index, style }) => (
                    <ListItem
                      key={filteredProgramManagers[index].programManagerId}
                      style={{ marginBottom: '15px', ...style }}
                    >
                      <Grid container alignItems='center' spacing={2}>
                        {inEditMode ? (
                          <IconButton
                            data-track='fs-prgMan-edit-managers-remove-manager'
                            onClick={() => {
                              setSelectedProgramManager(filteredProgramManagers[index]);
                            }}
                          >
                            <Clear color='error' />
                          </IconButton>
                        ) : null}

                        <ProfileHoverBlock
                          profileId={filteredProgramManagers[index].manager.profile.profileId}
                          userId={filteredProgramManagers[index].manager.profile.userId}
                          preferredName={filteredProgramManagers[index].manager.profile.preferredName}
                          lastName={filteredProgramManagers[index].manager.profile.lastName}
                          avatarUrl={filteredProgramManagers[index].manager.profile.avatarUrl}
                          secondaryText={
                            filteredProgramManagers[index].dateActivated !== null ? (
                              <Typography variant='caption' color='textSecondary'>
                                {'Added '}
                                <SemanticTime
                                  dateTime={DateTime.fromISO(filteredProgramManagers[index].dateActivated)}
                                  typographyProps={{ variant: 'caption', color: 'textSecondary' }}
                                />
                              </Typography>
                            ) : null
                          }
                          hoverTextProps={{
                            hoverColor: 'primary',
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                    </ListItem>
                  )}
                </FixedSizeList>
              ) : null}
            </List>
          </Grid>
        </Collapse>

        {inEditMode ? (
          <Grid item xs={12}>
            <TabletButton
              data-track='fs-prgMan-edit-managers-add-manager'
              variant='contained'
              onClick={() => {
                setShowAddManagerDialog(true);
              }}
              endIcon={<Add />}
            >
              Add Manager
            </TabletButton>
          </Grid>
        ) : null}

        {showAddManagerDialog && program && (
          <AddManagerDialog
            open={showAddManagerDialog}
            closeDialog={() => setShowAddManagerDialog(false)}
            programName={program.name}
            programManagers={allProgramManagers}
            refetch={refetchProgramManagersData}
          />
        )}
        {selectedProgramManager && (
          <ConfirmationDialog
            title={'Confirm Removal'}
            body={`Are you sure you wish to remove ${selectedProgramManager.manager.profile.preferredName} ${selectedProgramManager.manager.profile.lastName} from ${selectedProgramManager.program.name}?`}
            open={!!selectedProgramManager}
            closeActionText={'Cancel'}
            confirmActionText={'Remove'}
            handleCloseClick={() => setSelectedProgramManager(undefined)}
            handleConfirmClick={() => {
              if (!selectedProgramManager || !program?.programId) return;
              runRemoveProgramManager(
                {
                  managerId: selectedProgramManager.manager.managerId,
                  removedProgramManagerId: selectedProgramManager.programManagerId,
                },
                handleSuccess
              );
            }}
            disableConfirm={updateProgramManagersIsLoading}
          />
        )}
      </Grid>
    </FormSectionV2>
  );
};
