import { gql } from '@apollo/client';

export const GET_MY_ELEMENTS_SIDEBAR_UPCOMING_ROSTER_DATA = gql`
  query GetMyElementsSideBarUpcomingData($volunteerId: String!, $startDate: Date, $endDate: Date) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        upcomingRosters(startDate: $startDate, endDate: $endDate) {
          ... on VOLUNTEER_UpcomingRosterCancellationType {
            upcomingRosterId
            __typename
          }

          ... on VOLUNTEER_UpcomingRosterAvailabilityType {
            upcomingRosterId
            __typename
          }

          ... on VOLUNTEER_UpcomingRosterRosteringType {
            upcomingRosterId
            __typename
          }

          ... on VOLUNTEER_UpcomingRosterUnavailabilityType {
            upcomingRosterId
            __typename
          }

          ... on VOLUNTEER_UpcomingRosterUnspecifiedType {
            upcomingRosterId
            __typename
          }
        }
      }
    }
  }
`;
