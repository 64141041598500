import { gql } from '@apollo/client';

export const VolunteerEnrolmnentFragments = gql`
  fragment VolunteerEnrolments on VOLUNTEER_ActivityEnrolmentType {
    activityEnrolmentId
    activity {
      activityId
    }
    dateRemoved
    dateEnrolled
    availabilities {
      activityAvailabilityId
      activityDate
      sessionAvailabilities {
        session {
          sessionId
        }
        dateMarked
        dateRemoved
      }
      dateAdded
      dateRemoved
    }
    unavailabilities {
      activityUnavailabilityId
      activityDate
      dateRemoved
    }
  }
`;
