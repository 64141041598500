import React, { ReactNode } from 'react';
import { Avatar as MuiAvatar, Box, Typography, Badge, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import { FallbackAvatar } from '../screens/general/user-profile/components/Avatar';
import { useCampfireTheme } from '../theme/useCampfireTheme';
import { useCampfireLazyQuery } from '../global/network/useCampfireLazyQuery';
import { GET_PROFILE_HOVER_CARD } from '../screens/general/user-profile/hover-text/profile-hover-card-model.gql';
import {
  GetProfileHoverCard,
  GetProfileHoverCardVariables,
} from '../screens/general/user-profile/hover-text/__generated__/GetProfileHoverCard';
import { useUser } from '../global/auth/useUser';
import { HoverCardTooltip } from '../screens/general/user-profile/hover-text/ProfileHoverBlock';
import { VolunteerCommonProfileDialog } from '../screens/program-manager/volunteer-common-profile/VolunteerCommonProfileDialog';
import { RoleIcon } from './icons/RoleIconList';

interface ActivityRoles {
  activityRoleId: string;
  icon: string | null;
  name: string;
  isCompletedTask?: boolean;
}
interface Props {
  avatarUrl?: string | null;
  name: string;
  description?: string;
  noTrim?: boolean;
  avatarColor?: string;
  statusIndicator?: ReactNode;
  width?: string;
  inTable?: boolean;
  tooltipAll?: boolean;
  activityRoles?: ActivityRoles[];
  onRoleBadgeClick?: (activityRoleId: string) => void;
}
export const AvatarName = (props: Props) => {
  const { avatarUrl, name, avatarColor, statusIndicator, tooltipAll = true, ...otherProps } = props;
  const { theme } = useCampfireTheme();
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box
        style={{
          border: `2px solid ${avatarColor ?? theme.palette.primary.main}`,
          borderRadius: '50%',
          padding: '2px',
          marginRight: '16px',
          position: 'relative',
        }}
      >
        {avatarUrl ? (
          <MuiAvatar src={avatarUrl} alt='avatar' sizes={'32px'} style={{ width: '32px', height: '32px' }} />
        ) : (
          <FallbackAvatar
            initials={
              name
                .split(' ')
                .map((n) => n[0])
                .join('') ?? '?'
            }
            size={32}
          />
        )}
        <Box style={{ display: 'block', position: 'absolute', bottom: '-4px', right: '-4px' }}>{statusIndicator}</Box>
      </Box>
      {/* <TitularTooltip title={name}> */}
      {tooltipAll && <InfoProfile name={name} {...otherProps} />}
      {/* </TitularTooltip> */}
    </Box>
  );
};

interface InfoProfileProps {
  name: string;
  description?: string;
  noTrim?: boolean;
  width?: string;
  inTable?: boolean;
  activityRoles?: ActivityRoles[];
  onRoleBadgeClick?: (activityRoleId: string) => void;
}

const InfoProfile = ({
  name,
  noTrim,
  width,
  inTable,
  description,
  activityRoles,
  onRoleBadgeClick,
}: InfoProfileProps) => {
  const { theme } = useCampfireTheme();
  const inTableStyle: React.CSSProperties = {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: 'inherit',
    width: noTrim ? 'auto' : '102px',
  };
  return (
    <Box style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: width ?? 'auto', textAlign: 'left' }}>
      <Typography
        variant={'body1'}
        noWrap
        style={
          inTable
            ? inTableStyle
            : {
                fontSize: '16px',
                color: theme.color.grey.neutral300,
                width: noTrim ? 'auto' : width ?? '102px',
                lineHeight: '16px',
              }
        }
      >
        {name}
      </Typography>
      {description && (
        <Typography
          variant='caption'
          noWrap
          style={
            inTable
              ? inTableStyle
              : {
                  fontSize: '11px',
                  color: theme.color.grey.neutral300,
                  width: noTrim ? 'auto' : '102px',
                  lineHeight: '11px',
                }
          }
        >
          {description}
        </Typography>
      )}
      {activityRoles && <RoleListIcon availableRoles={activityRoles} onRoleBadgeClick={onRoleBadgeClick} />}
    </Box>
  );
};

const BagdeTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#D9D9D9',
    color: '#545454',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: '#D9D9D9',
  },
}))(Tooltip);

const RoleListIcon = ({
  availableRoles,
  onRoleBadgeClick,
}: {
  availableRoles: ActivityRoles[];
  onRoleBadgeClick?: (activityRoleId: string) => void;
}) => {
  return (
    <>
      {availableRoles && availableRoles.length > 0 && (
        <Box display='flex' alignItems='center'>
          {availableRoles.map(
            (role) =>
              role.icon && (
                <BagdeTooltip
                  title={
                    <Box display='flex' alignItems='center' style={{ backgroundColor: '#D9D9D9' }}>
                      <Box mr='4px'>
                        <Box width='18px' height='18px'>
                          <RoleIcon iconName={role.icon} color='#646464' />
                        </Box>
                      </Box>
                      <Box mr='4px'>{role.name}</Box>
                      {role.isCompletedTask && (
                        <Box display='flex' alignItems='center'>
                          <CheckCircleIcon
                            style={{
                              width: '13px',
                              height: '13px',
                              color: '#60B386',
                              backgroundColor: '#ffffff',
                              borderRadius: '50%',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  }
                  arrow
                >
                  <Box
                    marginRight='12px'
                    marginTop='4px'
                    paddingBottom='4px'
                    style={{ cursor: 'pointer' }}
                    onClick={() => onRoleBadgeClick && onRoleBadgeClick(role.activityRoleId)}
                  >
                    <Badge
                      overlap='circle'
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={
                        role.isCompletedTask && (
                          <CheckCircleIcon
                            style={{
                              width: '12px',
                              height: '12px',
                              color: '#60B386',
                              backgroundColor: '#ffffff',
                              borderRadius: '50%',
                            }}
                          />
                        )
                      }
                    >
                      <Box width='18px' height='18px' display='flex' alignItems='center'>
                        <RoleIcon iconName={role.icon} color='#646464' />
                      </Box>
                    </Badge>
                  </Box>
                </BagdeTooltip>
              )
          )}
        </Box>
      )}
    </>
  );
};

interface ProfileProps extends Props {
  profileId: string;
  userId: string;
  lastName: string;
  preferredName: string;
  tooltipAll?: boolean;
}

export function VolunteerProfile({
  profileId,
  userId,
  lastName,
  preferredName,
  tooltipAll = true,
  ...otherProps
}: ProfileProps) {
  const {
    user: { userIdentityService },
  } = useUser();
  const isAdmin = userIdentityService.privLevel === 'admin';
  const { isProgramManager, isLeaderOfActivity } = userIdentityService;

  const [runHoverCardQuery, hoverCardQuery] = useCampfireLazyQuery<GetProfileHoverCard, GetProfileHoverCardVariables>(
    GET_PROFILE_HOVER_CARD
  );

  function runQuery() {
    if (profileId) {
      runHoverCardQuery({ variables: { profileId } });
    }
  }

  const [openProfileDialog, setOpenProfileDialog] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const toggleDialog = () => setOpenProfileDialog((currentProfileDialog) => !currentProfileDialog);

  if (isAdmin || isProgramManager || isLeaderOfActivity) {
    return (
      <>
        <Box display='flex' alignItems='center'>
          <HoverCardTooltip
            isLoading={hoverCardQuery.loading}
            isCalled={hoverCardQuery.called}
            data={hoverCardQuery.data}
            runQuery={runQuery}
            profileId={profileId}
            userId={userId}
            lastName={lastName}
            preferredName={preferredName}
            avatarUrl={otherProps.avatarUrl}
            open={showTooltip}
            onOpen={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)}
          >
            <Box
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                if (isProgramManager || isAdmin) {
                  e.stopPropagation();
                  toggleDialog();
                } else {
                  setShowTooltip(!showTooltip);
                }
              }}
            >
              <AvatarName tooltipAll={tooltipAll} {...otherProps} />
            </Box>
          </HoverCardTooltip>
          {!tooltipAll && <InfoProfile {...otherProps} />}
        </Box>
        {openProfileDialog && (
          <VolunteerCommonProfileDialog
            key={profileId}
            profileId={profileId}
            userId={userId}
            open
            onClose={toggleDialog}
          />
        )}
      </>
    );
  }

  return <AvatarName tooltipAll={tooltipAll} {...otherProps} />;
}
