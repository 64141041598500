import { unpackToDate } from '@campfire/hot-date';
import { Pane, PaneHeader, PaneScrollable, PaneFooter } from '@campfire/pane';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Divider } from '@material-ui/core';
import { Add, ErrorOutline } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { createRef, memo, RefObject, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FullPageMessage } from '../../../../../common/FullPageMessage';
import { arrayHead } from '../../../../../common/functions/array-head';
import { scrollIntoView } from '../../../../../common/functions/scroll-functions';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { ActivityTimelineSidebarActivityReport } from '../__generated__/ActivityTimelineSidebarActivityReport';
import { ActivityTimelineSidebarCancelledActivity } from '../__generated__/ActivityTimelineSidebarCancelledActivity';
import { ActivityTimelineSidebarClosedActivity } from '../__generated__/ActivityTimelineSidebarClosedActivity';
import { ActivityTimelineSidebarDraftRoster } from '../__generated__/ActivityTimelineSidebarDraftRoster';
import {
  GetActivityTimelineSidebarData,
  GetActivityTimelineSidebarDataVariables,
} from '../__generated__/GetActivityTimelineSidebarData';
import { GET_ACTIVITY_TIMELINE_SIDEBAR_DATA } from '../activity-timeline-model.gql';
import { useActivityTimelineContext } from '../ActivityTimelineContext';
import { ActivityTimelineFutureSidebarItem } from './ActivityTimelineFutureSidebarItem';
import { ActivityTimelinePastSidebarItem } from './ActivityTimelinePastSidebarItem';
import { ActivityTimelineSidebarDate } from './ActivityTimelineSidebarDate';
import { ActivityTimelineSidebarHeader, SidebarTabKeys } from './ActivityTimelineSidebarHeader';
import { parseSidebarTabs, SelectedSidebarTab } from './sidebar-items-parser';

export const LOG_WIDTH = 280;

interface BaseSidebarItem {
  activityId: string;
  programId: string;
  programName: string;
  activityName: string;
  activityDate: string;
  startTime: string;
  endTime: string;
  draftRoster?: ActivityTimelineSidebarDraftRoster;
  cancelledActivity?: ActivityTimelineSidebarCancelledActivity;
  closedActivity?: ActivityTimelineSidebarClosedActivity;
  humanReadableRecurrence: string;
  isSuspended: boolean;
  rosterTemplateId?: string;
}

export type PastSidebarItemStatus = 'cancelled' | 'complete' | 'not-started' | 'incomplete';
export interface PastSidebarItem extends BaseSidebarItem {
  type: 'past';
  activityReport?: ActivityTimelineSidebarActivityReport;
  pastStatus: PastSidebarItemStatus;
}

export type FutureSidebarItemStatus = 'cancelled' | 'not-started' | 'not-published' | 'published' | 'unpublished';
export interface FutureSidebarItem extends BaseSidebarItem {
  type: 'future';
  futureStatus: FutureSidebarItemStatus;
}

export type SidebarItem = PastSidebarItem | FutureSidebarItem;

export interface SidebarFilters {
  activityIds: string[];
  programIds: string[];
  pastStatuses: PastSidebarItemStatus[];
  futureStatuses: FutureSidebarItemStatus[];
  searchInput?: string;
}
type SidebarItemsRefsMap = { [key: string]: RefObject<HTMLLIElement> };

const itemMatchesFilters = (sidebarFilters: SidebarFilters, sidebarItem: SidebarItem): boolean => {
  return (
    sidebarFilters.activityIds.includes(sidebarItem.activityId) &&
    sidebarFilters.programIds.includes(sidebarItem.programId) &&
    ((sidebarItem.type === 'past' && sidebarFilters.pastStatuses.includes(sidebarItem.pastStatus)) ||
      (sidebarItem.type === 'future' && sidebarFilters.futureStatuses.includes(sidebarItem.futureStatus))) &&
    (!sidebarFilters.searchInput ||
      sidebarItem.activityName.toLowerCase().includes(sidebarFilters.searchInput.toLowerCase()))
  );
};

interface ActivityTimelineSidebarProps {
  activities: {
    activityId: string;
  }[];
  onSidebarItemClick?: () => void;
}

export const ActivityTimelineSidebar = memo((props: ActivityTimelineSidebarProps) => {
  const { activities, onSidebarItemClick } = props;
  const {
    selectedActivityId,
    setSelectedActivityId,
    selectedDate,
    setSelectedDate,
    setSelectedSidebarItem,
    parsedSidebarItems,
    setParsedSidebarItems,
    setSidebarIsLoading,
    setSelectedRosterTemplateId,
  } = useActivityTimelineContext();

  const currentDate = useMemo(() => DateTime.local(), []);
  const defaultDate = useMemo(() => (selectedDate ? unpackToDate(selectedDate) : currentDate), []);
  const [sidebarItemsRef, setSidebarItemsRef] = useState<SidebarItemsRefsMap>({});
  const [startDate, setStartDate] = useState(defaultDate.startOf('month').toISODate());
  const [endDate, setEndDate] = useState(defaultDate.endOf('month').toISODate());
  const setDates = (newStartDate: string, newEndDate: string) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };
  const [selectedTab, setSelectedTab] = useState<SidebarTabKeys>(
    (selectedDate ?? endDate) < currentDate.toISODate() ? 'past' : 'future'
  );

  const activityIds = useMemo(() => {
    return activities.flatMap((activity) => activity.activityId);
  }, [activities]);

  const [sidebarFilters, setSidebarFilters] = useState<SidebarFilters>({
    activityIds: [],
    programIds: [],
    pastStatuses: ['cancelled', 'complete', 'not-started', 'incomplete'],
    futureStatuses: ['cancelled', 'not-started', 'not-published', 'published', 'unpublished'],
  });
  const {
    data: sidebarData,
    error: sidebarError,
    loading: sidebarLoading,
    refetch: sidebarRefetch,
    refetchLoading: sidebarRefetchLoading,
  } = useCampfireQuery<GetActivityTimelineSidebarData, GetActivityTimelineSidebarDataVariables>(
    GET_ACTIVITY_TIMELINE_SIDEBAR_DATA,
    {
      options: {
        variables: {
          activityIds: activityIds,
          startDate: startDate,
          endDate: endDate,
        },
      },
    }
  );

  const sidebarActivities = useMemo(() => {
    const scheduledActivities =
      sidebarData?.vm.activities.filter((activity) => activity.occurrencesBetween.length > 0) ?? [];

    scheduledActivities.sort((a, b) => a.name.localeCompare(b.name));
    return uniqBy(scheduledActivities, (activity) => activity.activityId);
  }, [sidebarData]);

  const sidebarPrograms = useMemo(() => {
    return sidebarActivities
      ? uniqBy(
          sidebarActivities?.map((activity) => activity.program),
          'programId'
        ).sort((a, b) => a.name.localeCompare(b.name))
      : undefined;
  }, [sidebarActivities]);

  const selectedSidebarItems: SelectedSidebarTab | undefined = useMemo(() => {
    if (!parsedSidebarItems) return undefined;
    const tabItems = parsedSidebarItems[selectedTab];
    return Object.keys(tabItems).reduce((items, date) => {
      const itemsForDate = tabItems[date] as SidebarItem[];
      const filteredItemsForDate = itemsForDate.filter(
        (item) =>
          itemMatchesFilters(sidebarFilters, item) &&
          unpackToDate(startDate) <= unpackToDate(item.activityDate) &&
          unpackToDate(endDate) >= unpackToDate(item.activityDate)
      );
      if (filteredItemsForDate.length) {
        return {
          ...items,
          [date]: filteredItemsForDate,
        };
      }
      return items;
    }, {});
  }, [parsedSidebarItems, selectedTab, sidebarFilters]);

  const defaultSidebarItem = useMemo(() => {
    if (!selectedSidebarItems) return undefined;
    const keys = Object.keys(selectedSidebarItems);
    const defaultKey = arrayHead(keys);
    if (!defaultKey) return undefined;
    const items = selectedSidebarItems[defaultKey] as SidebarItem[];
    return arrayHead(items);
  }, [selectedSidebarItems]);

  const memoizedSidebarItem = useMemo(() => {
    if (!selectedDate && !selectedActivityId) return defaultSidebarItem;
    if (!selectedDate || !selectedSidebarItems) return undefined;

    const selectedDateItems = (selectedSidebarItems[selectedDate] ?? []) as SidebarItem[];
    const matchingSidebarItem = selectedDateItems.find((dateItem) => dateItem.activityId === selectedActivityId);

    return matchingSidebarItem ?? defaultSidebarItem;
  }, [selectedSidebarItems, defaultSidebarItem, selectedActivityId, selectedDate]);

  const disablePast = useMemo(() => {
    return startDate > currentDate.toISODate();
  }, [currentDate, startDate]);

  const disableFuture = useMemo(() => {
    return endDate < currentDate.toISODate();
  }, [currentDate, endDate]);

  useEffect(() => {
    setSidebarFilters((prevFilters) => ({
      ...prevFilters,
      activityIds:
        sidebarActivities
          ?.map((activity) => activity.activityId)
          .filter((a) =>
            prevFilters.activityIds.length > 0
              ? activityIds.find((curr) => curr === a) && prevFilters.activityIds.find((curr) => curr === a)
              : activityIds.find((curr) => curr === a)
          ) ?? [],
    }));
  }, [sidebarActivities, activityIds]);

  useEffect(() => {
    setSidebarFilters((prevFilters) => ({
      ...prevFilters,
      programIds:
        sidebarPrograms
          ?.map((program) => program.programId)
          .filter((a) =>
            prevFilters.programIds.length > 0 ? prevFilters.programIds.find((curr) => curr === a) : true
          ) ?? [],
    }));
  }, [sidebarPrograms]);

  useEffect(() => {
    if (!sidebarRefetch) return;
    sidebarRefetch({
      activityIds: activityIds,
      startDate: startDate,
      endDate: endDate,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate > currentDate.toISODate()) setSelectedTab('future');
    if (endDate < currentDate.toISODate()) setSelectedTab('past');
  }, [startDate, endDate]);

  useEffect(() => {
    if (!sidebarData) {
      setParsedSidebarItems(undefined);
      return;
    }

    setParsedSidebarItems(parseSidebarTabs(sidebarData));
  }, [sidebarData]);

  useEffect(() => {
    setSelectedSidebarItem(memoizedSidebarItem);
    if (memoizedSidebarItem) setSelectedDate(memoizedSidebarItem.activityDate);
    if (memoizedSidebarItem && memoizedSidebarItem.activityId !== selectedActivityId) {
      setSelectedActivityId(memoizedSidebarItem.activityId);
    }
    if (memoizedSidebarItem && memoizedSidebarItem.rosterTemplateId) {
      setSelectedRosterTemplateId(memoizedSidebarItem.rosterTemplateId);
    }
    if (!memoizedSidebarItem && parsedSidebarItems) {
      setSelectedDate(undefined);
    }
  }, [memoizedSidebarItem, parsedSidebarItems]);

  useEffect(() => {
    if (selectedSidebarItems && selectedDate) {
      const sidebarItems = selectedSidebarItems[selectedDate] as SidebarItem[];
      if (sidebarItems) {
        const refs = sidebarItems.reduce<SidebarItemsRefsMap>((acc, value) => {
          acc[`${value.activityId}-${value.activityDate}`] = createRef();
          return acc;
        }, {});
        setSidebarItemsRef(refs);
      }
    }
  }, [selectedSidebarItems, selectedDate]);

  const selectedListItemRef =
    selectedActivityId && selectedDate ? sidebarItemsRef[`${selectedActivityId}-${selectedDate}`] : undefined;

  useEffect(() => {
    if (selectedListItemRef && selectedListItemRef.current) {
      scrollIntoView(selectedListItemRef, { behavior: 'auto', block: 'nearest', inline: 'nearest' }, true);
    }
  }, [selectedListItemRef]);

  const isLoading = sidebarLoading || sidebarRefetchLoading;

  useEffect(() => {
    setSidebarIsLoading(isLoading);
  }, [isLoading]);

  const { isMobile, theme } = useCampfireTheme();
  function getCreateRosterTemplateLink() {
    const activityParam = selectedActivityId ? `activityId=${selectedActivityId}` : '';
    const MANAGEMENT_PATH = '/management/rosters';

    return `${MANAGEMENT_PATH}/create-roster-template?${activityParam}`;
  }

  return (
    <Pane
      style={{
        backgroundColor: theme.color.grey.border,
        width: isMobile ? undefined : LOG_WIDTH,
        maxWidth: isMobile ? undefined : LOG_WIDTH,
        minWidth: isMobile ? undefined : LOG_WIDTH,
      }}
    >
      <PaneHeader>
        <ActivityTimelineSidebarHeader
          isLoading={isLoading}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          setDates={setDates}
          disablePast={disablePast}
          disableFuture={disableFuture}
          sidebarActivities={sidebarActivities ?? []}
          sidebarPrograms={sidebarPrograms ?? []}
          sidebarFilters={sidebarFilters}
          setSidebarFilters={setSidebarFilters}
        />
      </PaneHeader>

      {sidebarError && !isLoading ? (
        <FullPageMessage title={'Network Error'} subtitle={'Unable to load timeline.'} Icon={ErrorOutline} />
      ) : isLoading ? (
        <TimelineSidebarSkeletonLoadingItems />
      ) : parsedSidebarItems ? (
        <PaneScrollable>
          <Box paddingBottom={8} style={{ boxSizing: 'border-box' }}>
            {selectedSidebarItems
              ? Object.keys(selectedSidebarItems).map((date, dateIndex) => {
                  const sidebarItems = selectedSidebarItems[date] as SidebarItem[];
                  return (
                    <div key={date}>
                      {dateIndex > 0 ? <Divider style={{ width: '100%' }} /> : null}
                      <ActivityTimelineSidebarDate date={unpackToDate(date)} />
                      <Box component='ol' style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                        {sidebarItems.map((sidebarItem) => {
                          const selected =
                            selectedActivityId === sidebarItem.activityId && selectedDate === sidebarItem.activityDate;
                          return sidebarItem.type === 'past' ? (
                            <ActivityTimelinePastSidebarItem
                              pastSidebarItem={sidebarItem}
                              setSelectedActivityId={setSelectedActivityId}
                              setSelectedDate={setSelectedDate}
                              key={sidebarItem.activityId}
                              selected={selected}
                              listItemRef={sidebarItemsRef[`${sidebarItem.activityId}-${sidebarItem.activityDate}`]}
                              onSidebarItemClick={onSidebarItemClick}
                              setSelectedRosterTemplateId={setSelectedRosterTemplateId}
                            />
                          ) : (
                            <ActivityTimelineFutureSidebarItem
                              futureSidebarItem={sidebarItem}
                              setSelectedActivityId={setSelectedActivityId}
                              setSelectedDate={setSelectedDate}
                              key={sidebarItem.activityId}
                              selected={selected}
                              listItemRef={sidebarItemsRef[`${sidebarItem.activityId}-${sidebarItem.activityDate}`]}
                              onSidebarItemClick={onSidebarItemClick}
                              setSelectedRosterTemplateId={setSelectedRosterTemplateId}
                            />
                          );
                        })}
                      </Box>
                    </div>
                  );
                })
              : null}
          </Box>
        </PaneScrollable>
      ) : null}
      {!isLoading && (
        <PaneFooter>
          <Box padding={2} bgcolor='white' width={1}>
            <Link to={getCreateRosterTemplateLink()} style={{ textDecoration: 'none' }}>
              <TabletButton color='primary' variant='outlined' endIcon={<Add />}>
                Add Roster
              </TabletButton>
            </Link>
          </Box>
        </PaneFooter>
      )}
    </Pane>
  );
});

const TimelineSidebarSkeletonLoadingItems = () => (
  <>
    <Box paddingTop={3} paddingLeft={4}>
      <Box paddingY={2}>
        <Skeleton variant='text' height={12} width='65%' />
        <Skeleton variant='text' height={9} width='35%' />
        <Skeleton variant='text' height={9} width='35%' />
      </Box>

      <Box paddingY={2}>
        <Skeleton variant='text' height={12} width='65%' />
        <Skeleton variant='text' height={9} width='35%' />
        <Skeleton variant='text' height={9} width='35%' />
      </Box>

      <Box paddingY={2}>
        <Skeleton variant='text' height={12} width='65%' />
        <Skeleton variant='text' height={9} width='35%' />
        <Skeleton variant='text' height={9} width='35%' />
      </Box>
    </Box>
  </>
);
