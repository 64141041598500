import { CampfireRichEditor } from '@campfire/campfire-rich-editor/lib';
import { FileUploadGallery, UploadedFile } from '@campfire/file-upload-gallery';
import { Grid, Typography } from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';
import { convertFromRaw, EditorState } from 'draft-js';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useSupportContext } from '../../SupportContext';
import { SupportResource } from '../../__generated__/SupportResource';
import { AdminEditButtons } from './AdminEditButtons';
import { ResourceViewerTitle } from './ResourceViewerTitle';
import { ViewMoreResourcesSection } from './ViewMoreResourcesSection';

interface Props {
  selectedResource: SupportResource;
  returnSlug?: string;
}

const ResourceViewer = (props: Props) => {
  const { selectedResource, returnSlug } = props;
  const { categoryId, resourceId } = useParams();
  const { allCategories } = useSupportContext();

  const selectedCategory = useMemo(() => allCategories.find((c) => c.supportCategoryId === categoryId), [
    categoryId,
    allCategories,
  ]);

  const resources = useMemo(
    () => selectedCategory?.supportResources.filter((r) => r.supportResourceId !== resourceId) ?? [],
    [selectedCategory, resourceId]
  );

  const uploadedFiles: Array<UploadedFile> = useMemo(
    () =>
      selectedResource.supportResourceAttachments.map((attachment) => ({
        url: attachment.url,
        fileId: attachment.supportResourceAttachmentId,
        name: attachment.name,
      })),
    [selectedResource]
  );

  const draftJsContent = selectedResource.textContent
    ? convertFromRaw(JSON.parse(selectedResource.textContent))
    : undefined;

  const contentGridItemProps: GridProps = {
    xs: 12,
    sm: 11,
    md: 8,
  };

  return (
    <Grid
      container
      direction='column'
      justify='space-between'
      style={{ minHeight: '100%', boxSizing: 'border-box' }}
      //
    >
      <Grid item xs={12} container justify='center' spacing={1} style={{ padding: 16 }}>
        <AdminEditButtons supportCategoryId={selectedCategory?.supportCategoryId} />
        <Grid item {...contentGridItemProps}>
          <ResourceViewerTitle returnSlug={returnSlug} title={selectedResource.title} />
        </Grid>

        {draftJsContent && draftJsContent.hasText() ? (
          <Grid item {...contentGridItemProps} style={{ overflow: 'hidden', marginTop: 32, marginBottom: 16 }}>
            {selectedResource.textContent ? (
              <CampfireRichEditor editorState={EditorState.createWithContent(draftJsContent)} />
            ) : null}
          </Grid>
        ) : null}

        {uploadedFiles.length > 0 ? (
          <Grid item {...contentGridItemProps}>
            <Typography style={{ fontWeight: 'bold', marginTop: 32 }}>{'Attachments'}</Typography>

            <FileUploadGallery allowMultiple uploadedFiles={uploadedFiles} viewOnly />
          </Grid>
        ) : null}
      </Grid>

      <Grid
        item
        container
        xs={12}
        direction='column'
        justify='center'
        alignContent='center'
        alignItems='center'
        style={{
          marginTop: 32,
          paddingTop: 32,
          paddingBottom: 32,
          backgroundColor: '#f6f8f9',
          borderTop: 'solid 1px #e9e9e9',
        }}
      >
        <Typography variant='overline' color='textSecondary' style={{ fontWeight: 'bold' }}>
          {`More from ${selectedCategory?.title}`}
        </Typography>
        <ViewMoreResourcesSection selectedCategory={selectedCategory} resources={resources} returnSlug={returnSlug} />
      </Grid>
    </Grid>
  );
};

export { ResourceViewer };
