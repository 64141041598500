import { gql } from '@apollo/client';
import { FIELD_FRAGMENT } from '../activity-timeline/activity-timeline-model.gql';

export const MANAGE_REPORT_TYPE_GET_SINGLE_REPORT_TYPE = gql`
  query ManageReportTypeGetSingleReportType($reportTypeId: String!) {
    vm {
      reportType(reportTypeId: $reportTypeId) {
        ...ManageReportTypeSingleReportType
      }
    }
  }

  fragment ManageReportTypeSingleReportType on VOLUNTEER_ReportTypeType {
    reportTypeId
    name
    description
    items {
      ...ManageReportTypeItem
    }
    dateDeactivated
  }

  fragment ManageReportTypeItem on VOLUNTEER_ReportTypeItemType {
    ...ManageReportTypeField
    ...ManageReportTypeHeading
  }

  fragment ManageReportTypeField on VOLUNTEER_ReportTypeFieldType {
    reportTypeItemId
    order
    field {
      ...Field
    }
    optional
  }

  fragment ManageReportTypeHeading on VOLUNTEER_ReportTypeHeadingType {
    reportTypeItemId
    order
    heading
  }

  ${FIELD_FRAGMENT}
`;

export const MANAGE_REPORT_TYPE_GET_REPORT_TYPES = gql`
  query ManageReportTypeGetReportTypes {
    vm {
      allReportTypes {
        ...ManageReportTypeBaseReportType
      }
    }
  }

  fragment ManageReportTypeBaseReportType on VOLUNTEER_ReportTypeType {
    reportTypeId
    name
    description
    dateCreated
    activities {
      ...ManageReportTypeActivity
    }
  }

  fragment ManageReportTypeActivity on VOLUNTEER_ActivityType {
    activityId
    isSuspended
    closedActivity {
      closedActivityId
    }
    name
    sessions {
      sessionId
      name
      reportType {
        reportTypeId
        name
      }
    }
  }
`;
