import { Tooltip, Typography } from '@material-ui/core';
import { Flag as FlagIcon } from '@material-ui/icons';
import React from 'react';

export const ActivityNameAndPriority = ({ name, priority }: { name: string; priority: boolean }) => (
  <>
    <Tooltip title={name}>
      <Typography noWrap style={{ fontSize: 'inherit' }}>
        {name}
      </Typography>
    </Tooltip>
    {priority && <FlagIcon style={{ margin: '0 6px 4px', color: '#EB7612', fontSize: '20px' }} />}
  </>
);
