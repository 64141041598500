import { Box, TextField } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { memo, useEffect } from 'react';
import { useQueryParam } from 'use-query-params';
import { FilterItem } from '../../../../../common/filter-fields/FilterItem';
import {
  Filters,
  TIME_FILTER_END_TIME_URL_PARAM_KEY,
  TIME_FILTER_START_TIME_URL_PARAM_KEY,
} from '../../../../../common/filter-fields/filters';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

type InnerTimeFilterSelectProps = {
  start: string | undefined;
  end: string | undefined;
  setStart: (value: string | undefined) => void;
  setEnd: (value: string | undefined) => void;
};

const InnerTimeFilterSelect = (props: InnerTimeFilterSelectProps) => {
  const { start, end, setStart, setEnd } = props;
  const { isSm, isMobile } = useCampfireTheme();

  return (
    <Box width={'100%'} marginTop={2}>
      <Box display='flex' flex='1 1 auto' flexDirection={isSm ? 'column' : 'row'}>
        <TextField
          id='startTimeField'
          label='Start time'
          type='time'
          variant='outlined'
          size='small'
          style={{
            marginRight: isSm ? 0 : 20,
            marginBottom: isSm ? 20 : 0,
            minWidth: 156,
            maxWidth: isMobile ? 'none' : isSm ? 250 : 'none',
          }}
          value={start ?? ''}
          color='primary'
          onChange={(event) => setStart(event.target.value)}
          onBlur={() => {
            if (!start) return;
            setStart(start);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          fullWidth
        />

        <TextField
          id='endTimeField'
          label='End time'
          type='time'
          variant='outlined'
          size='small'
          style={{ minWidth: 156, maxWidth: isMobile ? 'none' : isSm ? 250 : 'none' }}
          value={end ?? ''}
          onChange={(event) => setEnd(event.target.value)}
          onBlur={() => {
            if (!end) return;
            setEnd(end);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          fullWidth
        />
      </Box>
    </Box>
  );
};

type TimeFilterFieldsProps = {
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  isDefaultTimes?: boolean;
  onClose?: () => void;
};

const TimeFilterFields = memo(({ setSelectedFilters }: TimeFilterFieldsProps) => {
  const [start, setStart] = useQueryParam<string | undefined>(TIME_FILTER_START_TIME_URL_PARAM_KEY);
  const [end, setEnd] = useQueryParam<string | undefined>(TIME_FILTER_END_TIME_URL_PARAM_KEY);

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      selectedTimeFilter: {
        startTime: DateTime.fromFormat(start ?? '00:00', 'HH:mm').toJSDate(),
        endTime: DateTime.fromFormat(end ?? '23:59', 'HH:mm').toJSDate(),
      },
    }));
  }, [start, end]);

  return (
    <FilterItem title='TIME' underline>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <InnerTimeFilterSelect start={start} end={end} setStart={setStart} setEnd={setEnd} />
      </Box>
    </FilterItem>
  );
});

export { TimeFilterFields, InnerTimeFilterSelect };
