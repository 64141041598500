import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { ActivityTimelineItemSkeleton } from './ActivitySkeleton/ActivityTimelineItemSkeleton';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      flex: 1,
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
      paddingBottom: '3rem',
    },
  })
);

interface Props {
  children?: React.ReactNode;
  isLoading?: boolean;
}

export function ActivitiesBody({ children, isLoading }: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {children}
      {isLoading && (
        <Box>
          <ActivityTimelineItemSkeleton />
          <ActivityTimelineItemSkeleton />
          <ActivityTimelineItemSkeleton />
        </Box>
      )}
    </Box>
  );
}
