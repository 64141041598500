import { unpackToDateTime } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useCampfireQuery } from '../../network/useCampfireQuery';
import { GET_ORG_INFO } from './use-org-info.gql';
import { GetOrgInfo, GetOrgInfo_tm_tenant_packages as packageConfigInterface } from './__generated__/GetOrgInfo';

export type OrgInfo = {
  name: string;
  tenantId: string;
  dateCreated: DateTime;
  dateDeactivated?: DateTime;
  isDeactivated: boolean;
  smsEnabled: boolean;
  isLargeOrg: boolean;
  packageConfig?: packageConfigInterface;
};

export const useOrgInfo = (): undefined | OrgInfo => {
  const query = useCampfireQuery<GetOrgInfo, {}>(GET_ORG_INFO);

  const gear = useMemo(() => {
    if (!query.data) return undefined;

    const dateDeactivated = query.data.orgDateDeactivated ? unpackToDateTime(query.data.orgDateDeactivated) : undefined;
    const isDeactivated = dateDeactivated === undefined ? false : dateDeactivated.diffNow('hours').hours < 0;

    return {
      name: query.data.orgName,
      tenantId: query.data.orgId,
      dateCreated: unpackToDateTime(query.data.orgDateCreated),
      dateDeactivated,
      isDeactivated,
      smsEnabled: query.data.smsEnabled,
      isLargeOrg: query.data.isLargeOrg,
      packageConfig: query.data.tm.tenant.packages ?? undefined
    };
  }, [query.data]);

  return gear;
};
