import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ColorLegend } from '../../../../common/ColorLegend';
import { RosteredStatus } from '../ActivityTypes';
import { getActivityLegend } from '../../../../common/constants/activity-legends';

const useStyles = makeStyles(() =>
  createStyles({
    stickyBox: {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      paddingTop: '1rem',
      zIndex: 1,
    },
  })
);

export function MyShiftColorLegend() {
  const classes = useStyles();
  const { theme } = useCampfireTheme();
  const { rosters } = theme.color;
  return (
    <Box className={classes.stickyBox}>
      <Box display='flex' flexWrap='wrap'>
        {(['unavailable', 'available', 'rostered', 'unsure', 'cancelled'] as RosteredStatus[]).map((status) => (
          <ColorLegend
            color={rosters.status[status].primary}
            text={getActivityLegend(status)}
            width={10}
            height={10}
            isRound
            marginRight='6px'
          />
        ))}
      </Box>
    </Box>
  );
}
