import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Downshift, { GetPropsCommonOptions } from 'downshift';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { transitionOut } from '../../global/app-shell/AppTransitions';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { ActivityType, Volunteer } from './person-selector-model';

const InnerTeamList = ({
  selectedActivity,
  volunteers,
  inputValue,
  menuProps,
  addedVolunteerIds,
  addVolunteerToAddedList,
  removeVolunteerFromAddedList,
}: {
  selectedActivity: ActivityType | undefined;
  volunteers: Array<Volunteer>;
  inputValue?: string | null;
  menuProps: GetPropsCommonOptions | undefined;
  addedVolunteerIds: Array<string>;
  addVolunteerToAddedList: (volunteer: Volunteer) => void;
  removeVolunteerFromAddedList: (volunteer: Volunteer) => void;
}) => {
  const volunteerFilter = useCallback(
    (volunteer: Volunteer): boolean => {
      if (!inputValue) return true;
      return (
        volunteer.profile.preferredName.toLowerCase().includes(inputValue.toLowerCase()) ||
        volunteer.profile.lastName.toLowerCase().includes(inputValue.toLowerCase())
      );
    },
    [inputValue]
  );
  const filteredVolunteerIds = useMemo(() => volunteers.filter(volunteerFilter).map((x) => x.volunteerId), [
    inputValue,
  ]);

  const volunteersOnSelectedActivityVolunteerIds = selectedActivity
    ? selectedActivity.activityEnrolments.map((activityEnrolment) => activityEnrolment.volunteer.volunteerId)
    : [];

  const volunteersOnSelectedActivity = volunteersOnSelectedActivityVolunteerIds.length
    ? volunteers.filter((volunteer) => volunteersOnSelectedActivityVolunteerIds.includes(volunteer.volunteerId))
    : [];

  const volunteerListItem = (volunteer: Volunteer) => {
    const checked = addedVolunteerIds.includes(volunteer.volunteerId);
    return (
      <Box key={volunteer.volunteerId} hidden={!filteredVolunteerIds.includes(volunteer.volunteerId)}>
        <ListItem
          button
          onClick={() => (checked ? removeVolunteerFromAddedList(volunteer) : addVolunteerToAddedList(volunteer))}
        >
          <ListItemIcon>
            <Checkbox color='primary' tabIndex={-1} checked={checked} />
          </ListItemIcon>
          <ListItemText primary={`${volunteer.profile.preferredName} ${volunteer.profile.lastName}`} />
        </ListItem>
      </Box>
    );
  };

  return (
    <List {...menuProps}>
      {selectedActivity && volunteersOnSelectedActivity.length ? (
        <Fragment>
          <ListSubheader style={{ backgroundColor: 'white' }}>{`From this activity:`}</ListSubheader>
          {volunteersOnSelectedActivity.map(volunteerListItem)}
          <Divider />
        </Fragment>
      ) : null}
      <ListSubheader style={{ backgroundColor: 'white' }}>{'All volunteers:'}</ListSubheader>
      {volunteers.map(volunteerListItem)}
    </List>
  );
};

export const PersonListDialog = ({
  onClose,
  open,
  volunteers,
  selectedActivity,
  onSubmit,
}: {
  onClose: () => void;
  open: boolean;
  volunteers: Array<Volunteer>;
  selectedActivity: ActivityType | undefined;
  onSubmit: (volunteerIds: Array<string>) => void;
}) => {
  const [addedVolunteerIds, setAddedVolunteerIds] = useState<Array<string>>([]);
  useEffect(() => {
    setAddedVolunteerIds([]);
  }, [volunteers]);
  const changesMade = addedVolunteerIds.length > 0;

  const addVolunteerToAddedList = (volunteer: Volunteer) => {
    setAddedVolunteerIds([...addedVolunteerIds, volunteer.volunteerId]);
  };
  const removeVolunteerFromAddedList = (volunteer: Volunteer) => {
    setAddedVolunteerIds(addedVolunteerIds.filter((x) => x !== volunteer.volunteerId));
  };

  const { theme } = useCampfireTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const classes = makeStyles({
    paper: {
      width: '100%',
      display: 'flex',
      transition: transitionOut('background', theme),
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.08)',
      },
    },
    input: {
      marginLeft: 8,
      flex: 1,
      flexGrow: 1,
    },
  })();

  return (
    <Dialog
      scroll='paper'
      open={open}
      onClose={onClose}
      fullWidth
      disableEscapeKeyDown
      TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
    >
      <DialogContent style={{ padding: 0 }}>
        <Downshift>
          {({ getInputProps, getMenuProps, inputValue }) => (
            <div>
              <Box paddingTop={2} paddingLeft={2} paddingRight={2}>
                <Paper className={classes.paper} elevation={focused ? 2 : 0}>
                  <IconButton disabled tabIndex={-1}>
                    <Search color={focused ? undefined : 'action'} />
                  </IconButton>
                  <InputBase
                    autoFocus
                    inputRef={inputRef}
                    className={classes.input}
                    {...getInputProps({
                      onChange: () => {},
                      ref: inputRef,
                      placeholder: `Search by name...`,
                      defaultValue: undefined,
                      onBlur: () => {
                        setFocused(false);
                      },
                      color: undefined,
                    })}
                    onFocus={() => {
                      setFocused(true);
                    }}
                    inputProps={{ 'aria-label': 'Search volunteers by name' }}
                  />
                </Paper>
              </Box>
              <InnerTeamList
                selectedActivity={selectedActivity}
                volunteers={volunteers}
                inputValue={inputValue}
                menuProps={getMenuProps()}
                addedVolunteerIds={addedVolunteerIds}
                addVolunteerToAddedList={addVolunteerToAddedList}
                removeVolunteerFromAddedList={removeVolunteerFromAddedList}
              />
            </div>
          )}
        </Downshift>
      </DialogContent>

      <DialogActions>
        <Grid container justify='flex-end' alignItems='center' alignContent='center'>
          <TabletButton onClick={onClose}>{`Cancel`}</TabletButton>
          <TabletButton
            onClick={() => {
              onSubmit(addedVolunteerIds);
              onClose();
            }}
            variant='contained'
            color='primary'
            autoFocus
            disabled={!changesMade}
          >
            {`Add Volunteers`}
          </TabletButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
