import React, { useMemo } from 'react';
import { snakeCase } from 'lodash';
import { Box, Typography, Button, Popover, IconButton, MenuList, MenuItem, Theme, TextField } from '@material-ui/core';
import { DataGrid, GridColumns, GridCellParams } from '@material-ui/data-grid';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ExpandMore, Info } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { useTypographyStyles } from '../styles/typography';
import { useButtonStyles } from '../styles/button';
import { useTextFieldClasses } from '../styles/textField';
import { useContainerStyles } from '../styles/container';
import { useInfoStyles } from '../styles/info';
import { ImportVolunteerGetPrograms } from '../queries/__generated__/ImportVolunteerGetPrograms';

interface Props {
  onImport: (importData: any) => void;
  onBack: () => void;
  vmPrograms?: ImportVolunteerGetPrograms;
  setData: React.Dispatch<any>;
  data: any[];
  isImporting: boolean;
}

const useTableStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '500px',
      border: 'none',
      '& .MuiDataGrid-columnsContainer': {
        justifyContent: 'center',
        backgroundColor: '#F9F9F9',
        borderBottom: 'none',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-selectedRowCount': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-columnHeader--moving': {
        background: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: '#F9F9F9',
      },
    },
    cell: {
      borderBottom: 'none !important',
    },
  })
);

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '16px',
      fontWeight: 900,
      color: theme.color.grey.neutralBrand900,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
      alignSelf: 'center',
    },
  })
);

const useMenuButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid',
      borderColor: theme.color.grey.neutralBrand200,
      borderRadius: '6px',
      padding: '0.25rem',
      backgroundColor: 'white',
    },
  })
);

type HeaderType = {
  value: string;
  label: string;
};

interface HeaderProps {
  value: string;
  onChange: (value: string) => void;
  headerOptions: HeaderType[];
  isDisabled?: boolean;
}

function HeaderMenu({ value, onChange, headerOptions, isDisabled }: HeaderProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => setAnchorEl(event.target);
  const handleClose = () => setAnchorEl(null);
  const classes = useHeaderStyles();
  const menuButtonClasses = useMenuButtonStyles();

  const handleChangeHeader = (header: string) => (event: any) => {
    event.stopPropagation();
    onChange(header);
    setAnchorEl(null);
  };

  const headerLabel = headerOptions.find((header) => header.value === value)?.label;

  return (
    <>
      <Box className={classes.container}>
        <Typography className={classes.title}>{headerLabel}</Typography>
        {!isDisabled && (
          <IconButton onClick={handleClick} classes={menuButtonClasses}>
            <ExpandMore />
          </IconButton>
        )}
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList>
          {headerOptions.map((header) => (
            <MenuItem onClick={handleChangeHeader(header.value)}>{header.label}</MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      marginLeft: '0.5rem',
    },
    cell: {
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    cellInvalid: {
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: 'red',
    },
  })
);

const HEADERS = [
  {
    value: 'id',
    label: '#',
  },
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'contactNumber',
    label: 'Contact Number',
  },
  {
    value: 'program',
    label: 'Program',
  },
  {
    value: 'dob',
    label: 'DOB',
  },
  {
    value: 'dateCreated',
    label: 'Date Created',
  },
  {
    value: 'additionalVolunteerHours',
    label: 'Additional Volunteer Hours ',
  },
  {
    value: '_ignore',
    label: 'Ignore',
  },
];

const ACCEPTABLE_FORMATS = ['dd/MM/yyyy', 'dd-MM-yyyy', 'd-MM-yyyy', 'd/MM/yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd'];
const ACCEPTED_FORMAT = 'yyyy-MM-dd';

export function ReviewStep({ onImport, onBack, vmPrograms, data, setData, isImporting }: Props) {
  const typographyClasses = useTypographyStyles();
  const buttonClasses = useButtonStyles();
  const containerClasses = useContainerStyles();
  const textFieldClasses = useTextFieldClasses();
  const classes = useStyles();
  const isFirstRowHeader = React.useMemo(() => {
    if (!data || !data[0]) {
      return false;
    }
    return Object.values(data[0]).some((firstRowValue) =>
      HEADERS.findIndex((header) => header.label === firstRowValue || header.value === firstRowValue)
    );
  }, [data]);
  const originalHeader = React.useMemo(() => {
    if (isFirstRowHeader) {
      const headers: string[] = Object.values(data[0]);
      return headers.map((header) => {
        if (header.toLowerCase() === 'id') {
          return 'id';
        }
        const indexHeader = HEADERS.find(
          ({ value, label }) =>
            header.toLowerCase() === value.toLocaleLowerCase() || header.toLowerCase() === label.toLowerCase()
        );
        if (indexHeader) {
          return indexHeader.value;
        }
        return '_ignore';
      });
    }
    return [
      'id',
      'firstName',
      'lastName',
      'email',
      'contactNumber',
      'program',
      'dob',
      'dateCreated',
      'additionalVolunteerHours',
      '_ignore',
    ];
  }, [data, isFirstRowHeader]);
  const [headerOptions, setHeaderOptions] = React.useState(originalHeader);

  const onChangeHeader = (header: string, headerIndex: number) => {
    const assigneToValuableColumn = headerOptions[headerIndex] === '_ignore' && !headerOptions.includes(header);

    if (header === '_ignore' || assigneToValuableColumn) {
      setHeaderOptions((currentHeaders) =>
        currentHeaders.map((currentHeader: string, currentHeaderIndex: number) => {
          if (currentHeaderIndex === headerIndex) {
            return header;
          }
          return currentHeader;
        })
      );
      return;
    }

    setHeaderOptions((currentHeaders) => {
      const currentHeadersData = Array.from(currentHeaders);
      const currentValueIndex = currentHeaders.findIndex((value) => value === header);
      currentHeadersData[currentValueIndex] = currentHeaders[headerIndex];
      currentHeadersData[headerIndex] = header;
      return currentHeadersData;
    });
  };

  const columns: GridColumns = useMemo(() => {
    if (!data || !data[0]) {
      return [];
    }

    const dataColumns = Object.keys(data[0])
      .filter((key) => key !== 'id')
      .map((key: string, keyIndex) => ({
        field: key,
        headerName: key,
        disableColumnMenu: true,
        sortable: false,
        flex: headerOptions[keyIndex] === 'id' ? 0 : 1,
        minWidth: headerOptions[keyIndex] === 'id' ? 50 : 200,
        renderHeader: () => (
          <HeaderMenu
            value={headerOptions[keyIndex] || '_ignore'}
            headerOptions={HEADERS}
            onChange={(header) => onChangeHeader(header, keyIndex)}
          />
        ),
        renderCell: (params: GridCellParams) => {
          const { row, field, id } = params;
          if (headerOptions[keyIndex] === 'program') {
            const isError = vmPrograms?.vm.programs.findIndex((program) => program.name === row[field]) === -1;
            return (
              <TextField
                fullWidth
                variant='outlined'
                select
                InputProps={{ classes: textFieldClasses }}
                value={row[field]}
                error={isError}
                label={isError ? 'Invalid program' : undefined}
                onChange={(event) => {
                  setData((currentData: any) =>
                    currentData.map((datum: any) => {
                      if (datum.id !== id) {
                        return datum;
                      }
                      return {
                        ...datum,
                        [field]: event.target.value,
                      };
                    })
                  );
                }}
              >
                {vmPrograms?.vm.programs.map(({ programId, name }) => (
                  <MenuItem key={programId} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            );
          }

          if (headerOptions[keyIndex] === 'dob') {
            if (ACCEPTABLE_FORMATS.some((acceptedFormat) => DateTime.fromFormat(row[field], acceptedFormat).isValid)) {
              return <p className={classes.cell}>{row[field]}</p>;
            }
            return (
              <TitularTooltip title='Invalid format'>
                <p className={classes.cellInvalid}>{row[field]}</p>
              </TitularTooltip>
            );
          }

          if (headerOptions[keyIndex] === 'id') {
            return <p className={classes.cell}>{row[field] || row.id}</p>;
          }

          return <p className={classes.cell}>{row[field]}</p>;
        },
      }));

    return dataColumns;
  }, [data, headerOptions, vmPrograms]);

  const tableClasses = useTableStyles();
  const infoClasses = useInfoStyles();
  const menuButtonClasses = useMenuButtonStyles();

  const uploadData = useMemo(
    () =>
      (isFirstRowHeader ? data.slice(1) : data).map((datum: any) => {
        return headerOptions.reduce((acc, cur, keyIndex) => {
          if (cur === '_ignore' || cur === 'id') {
            return acc;
          }
          if (cur === 'program') {
            const programId = vmPrograms?.vm.programs.find(({ name }) => name === datum[keyIndex])?.programId;
            return {
              ...acc,
              programId,
              [snakeCase('programId')]: programId,
            };
          }
          if (cur === 'dob') {
            const validFormat = ACCEPTABLE_FORMATS.find(
              (acceptedFormat) => DateTime.fromFormat(datum[keyIndex], acceptedFormat).isValid
            );
            if (validFormat) {
              return {
                ...acc,
                dob: DateTime.fromFormat(datum[keyIndex], validFormat).toFormat(ACCEPTED_FORMAT),
              };
            }
            return {
              ...acc,
              dob: datum[keyIndex],
            };
          }
          return {
            ...acc,
            [snakeCase(cur)]: datum[keyIndex],
          };
        }, {});
      }),
    [data, headerOptions, vmPrograms]
  );

  const isValid = uploadData.every((datum: any) => {
    return Object.entries(datum).every(([key, value]) => {
      return (
        key === 'date_created' ||
        key === 'additional_volunteer_hours' ||
        (key === 'dob' && DateTime.fromFormat(value as string, ACCEPTED_FORMAT).isValid) ||
        Boolean(value)
      );
    });
  });

  const onImportClick = () => {
    onImport(uploadData);
  };

  return (
    <Box className={containerClasses.root}>
      <Typography className={typographyClasses.title}>Match columns to contact information</Typography>
      <Typography className={typographyClasses.subTitle}>
        <strong color='#000'>{data.length}</strong> volunteers were recognized in this file
      </Typography>
      <Box className={infoClasses.alertContainer}>
        <Box className={infoClasses.alertText}>
          <Info />
          <Typography style={{ marginLeft: '14px' }}>You can define the columns by clicking the</Typography>&nbsp;
          <IconButton classes={menuButtonClasses}>
            <ExpandMore />
          </IconButton>
          &nbsp;<Typography>icons</Typography>
        </Box>
      </Box>
      <Box display='flex' height='500px' marginTop='3rem'>
        <DataGrid
          rows={isFirstRowHeader ? data.slice(1) : data}
          columns={columns}
          classes={tableClasses}
          disableSelectionOnClick
        />
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <Button classes={buttonClasses} onClick={onBack} variant='outlined'>
          Back
        </Button>
        <Button
          onClick={onImportClick}
          classes={buttonClasses}
          className={classes.button}
          variant='contained'
          color='primary'
          disabled={!isValid || isImporting}
        >
          Begin Import
        </Button>
      </Box>
    </Box>
  );
}
