import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { getFirstName } from './get-first-name';

interface ApplicationScreenEmailSentProps {
  orgName: string;
  stateController: {
    givenName: string | undefined;
    givenEmail: string | undefined;
  };
}

export const ApplicationScreenEmailSent = (props: ApplicationScreenEmailSentProps) => {
  const { orgName, stateController } = props;
  const { givenName: name } = stateController;
  const { isMobile } = useCampfireTheme();
  const maybeFirstName = name ? getFirstName(name) : undefined;

  return (
    <Box paddingTop={isMobile ? 4 : 8} paddingBottom={isMobile ? 0 : 8}>
      <Grid justify='center' container style={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={10} style={{ textAlign: 'center' }}>
          <Typography variant='h4'>{maybeFirstName ? `Thank you, ${maybeFirstName}` : 'Thank You'}</Typography>
          <Typography variant='h6'>
            {orgName
              ? `Your application to join ${orgName} is pending verification.`
              : `Your application to is pending verification.`}
          </Typography>

          <Typography variant='body2' color='textSecondary' style={{ marginTop: 16 }}>
            {`Check your inbox for more instructions.`}
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {`You will have received an email from `}
            <b>
              <i>{`noreply@volaby.org`}</i>
            </b>
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {`Don't forget to look in your spam/junk mail.`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
