import React from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import { unpackToTime } from '@campfire/hot-date';
import { ExpandMore, Person, People } from '@material-ui/icons';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import UserCheckinLightIcon from '../../../../../../../assets/icons/user-checkin-light.svg';
import UserCheckinIcon from '../../../../../../../assets/icons/user-checkin.svg';
import { RosterTimelineItem } from '../../../../../activities-v2/ActivityTypes';
import { useUser } from '../../../../../../../global/auth/useUser';

type CICOTypeType = 'myself' | 'multiple';

export function MyActivityCICOActions({
  activity,
  onAction,
  cicoConflict,
  isCheckOut = false,
}: {
  activity: RosterTimelineItem;
  onAction: (type: 'myself' | 'multiple') => void;
  cicoConflict: boolean;
  isCheckOut: boolean;
}) {
  const { theme } = useCampfireTheme();
  const [anchorEl, setAnchorEl] = React.useState();
  const {
    user: { userIdentityService },
  } = useUser();

  const userIsAdmin = userIdentityService.isVmAdmin;
  const userIsActivityLeader = userIdentityService.isLeaderOfActivity(activity.activityId);
  const userIsPm = userIdentityService.isManagerOfProgram(activity.program.programId);
  const isAbleMultipleCheckin = userIsAdmin || userIsActivityLeader || userIsPm;

  const onOpenMenu = (event: any) => {
    event.stopPropagation();
    if (isAbleMultipleCheckin) {
      setAnchorEl(event.currentTarget);
    } else {
      onAction('myself');
    }
  };

  const handleCICO = (event: any, type: CICOTypeType) => {
    event.stopPropagation();
    onAction(type);
    setAnchorEl(undefined);
  };

  const isCheckInHightLight =
    activity.startTime &&
    unpackToTime(activity.startTime)
      .diffNow()
      .as('hours') >= 1;

  return (
    <Box>
      <TabletButton
        data-track='resources-add-new-menu-button'
        variant={isCheckInHightLight ? 'outlined' : 'contained'}
        color='primary'
        aria-controls='add-new'
        aria-haspopup='true'
        onClick={onOpenMenu}
        disabled={cicoConflict}
        endIcon={
          isAbleMultipleCheckin && (
            <ExpandMore
              style={{
                color: isCheckInHightLight ? theme.color.secondary.main700 : theme.color.grey.neutralBrand100,
                marginLeft: '-2px',
              }}
            />
          )
        }
        startIcon={
          <img
            style={{ width: '12px', height: '12px', paddingLeft: '4px' }}
            src={isCheckInHightLight ? UserCheckinIcon : UserCheckinLightIcon}
            alt=''
          />
        }
        style={{ padding: '8px 12px', border: `1px solid #DCDCDC`, whiteSpace: 'nowrap', textTransform: 'uppercase' }}
      >
        <Typography
          variant='caption'
          style={{
            fontSize: '12px',
            fontWeight: 700,
            color: isCheckInHightLight ? theme.color.secondary.main700 : theme.color.grey.neutralBrand100,
            textTransform: 'uppercase',
            lineHeight: '14px',
            marginTop: '1px',
          }}
        >
          {isCheckOut ? 'Check Out' : 'Check In'}
        </Typography>
      </TabletButton>
      {isAbleMultipleCheckin && (
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          keepMounted
          onClose={(e: any) => {
            e.stopPropagation();
            setAnchorEl(undefined);
          }}
          getContentAnchorEl={null}
          PaperProps={{ style: { border: 'solid 1px #e9e9e9' } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={(e) => handleCICO(e, 'myself')}
            style={{
              textTransform: 'uppercase',
              fontSize: '12px',
              fontWeight: 700,
              color: theme.color.grey.neutral500,
            }}
          >
            <Person fontSize='small' style={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }} />
            Myself
          </MenuItem>
          <MenuItem
            onClick={(e) => handleCICO(e, 'multiple')}
            style={{
              textTransform: 'uppercase',
              fontSize: '12px',
              fontWeight: 700,
              color: theme.color.grey.neutral500,
            }}
          >
            <People fontSize='small' style={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }} />
            Multiple
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
}
