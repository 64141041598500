import { uniqBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { arrayHead } from '../../../../common/functions/array-head';
import { assertNever } from '../../../../common/functions/assert-never';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useSaveReportTypeFetch } from '../activity-timeline/activity-timeline-actions';
import { ManageReportTypeActivity } from './__generated__/ManageReportTypeActivity';
import { ManageReportTypeBaseReportType } from './__generated__/ManageReportTypeBaseReportType';
import { ManageReportTypeGetReportTypes } from './__generated__/ManageReportTypeGetReportTypes';
import {
  ManageReportTypeGetSingleReportType,
  ManageReportTypeGetSingleReportTypeVariables,
} from './__generated__/ManageReportTypeGetSingleReportType';
import { parseInitialValues, ReportTypeFormValues } from './manage-report-type-model';
import {
  MANAGE_REPORT_TYPE_GET_REPORT_TYPES,
  MANAGE_REPORT_TYPE_GET_SINGLE_REPORT_TYPE,
} from './manage-report-type.gql';

interface ManageReportTypeContextInterface {
  initialValues: ReportTypeFormValues;
  reportTypeIsLoading: boolean;
  selectedReportTypeId?: string;
  setSelectedReportTypeId: (newValue?: string) => void;
  reportTypes: Array<ManageReportTypeBaseReportType>;
  reportTypesListIsLoading: boolean;
  runSaveReportType: (values: ReportTypeFormValues) => void;
  activities: Array<ManageReportTypeActivity>;
  creationModeOn: boolean;
  setCreationModeOn: (newValue: boolean) => void;
  saveReportIsLoading: boolean;
}

interface ManageReportTypeProviderProps {
  children: React.ReactNode;
}

const ManageReportTypeContext = React.createContext<ManageReportTypeContextInterface | undefined>(undefined);

const ManageReportTypeProvider = (props: ManageReportTypeProviderProps) => {
  const [selectedReportTypeId, setSelectedReportTypeId] = useQueryParam('rmid', StringParam);
  const { isMobile } = useCampfireTheme();
  const saveReportType = useSaveReportTypeFetch();
  const { setSnackbar } = useSnackbar();

  const [reportTypes, setReportTypes] = useState<ManageReportTypeBaseReportType[]>([]);
  const [creationModeOn, setCreationModeOn] = useState<boolean>(false);
  const { data: allReportTypes, loading: reportTypesListIsLoading, refetch: refetchData } = useCampfireQuery<
    ManageReportTypeGetReportTypes,
    undefined
  >(MANAGE_REPORT_TYPE_GET_REPORT_TYPES);

  const [getReportType, { data: reportTypeData, loading: reportTypeIsLoading }] = useCampfireLazyQuery<
    ManageReportTypeGetSingleReportType,
    ManageReportTypeGetSingleReportTypeVariables
  >(MANAGE_REPORT_TYPE_GET_SINGLE_REPORT_TYPE);

  const activities = useMemo(() => {
    return uniqBy(allReportTypes?.vm.allReportTypes.flatMap((reportType) => reportType.activities) ?? [], 'activityId');
  }, [allReportTypes]);

  const initialValues = useMemo(() => {
    if (!selectedReportTypeId) return parseInitialValues();
    return parseInitialValues(reportTypeData?.vm?.reportType ?? undefined);
  }, [reportTypeData, selectedReportTypeId]);

  // Only set a default if desktop
  useEffect(() => {
    if (!selectedReportTypeId && allReportTypes?.vm?.allReportTypes?.length && !isMobile)
      setSelectedReportTypeId(arrayHead(allReportTypes.vm.allReportTypes)?.reportTypeId);
  }, [allReportTypes]);

  useEffect(() => {
    if (selectedReportTypeId)
      getReportType({
        variables: {
          reportTypeId: selectedReportTypeId,
        },
      });
  }, [selectedReportTypeId]);

  useEffect(() => {
    if (selectedReportTypeId) {
      setCreationModeOn(false);
    }
  }, [selectedReportTypeId]);

  useEffect(() => {
    setReportTypes(
      allReportTypes?.vm.allReportTypes.map((reportType) => ({
        ...reportType,
        activities: reportType.activities.map((activity) => ({
          ...activity,
          sessions: activity.sessions.filter((session) => session.reportType?.reportTypeId === reportType.reportTypeId),
        })),
      })) ?? []
    );
  }, [allReportTypes]);

  const runSaveReportType = (values: ReportTypeFormValues) => {
    saveReportType
      .run({
        reportTypeId: values.reportTypeId || undefined,
        name: values.reportTypeName,
        description: values.reportTypeDescription ?? null,
        active: values.active,
        items: values.items.map((item, order) => {
          if (item.__typename === 'VOLUNTEER_ReportTypeHeadingType') {
            return { ...item, reportTypeItemId: item.reportTypeItemId || undefined, order };
          }

          if (
            item.field.__typename === 'VOLUNTEER_BooleanFieldType' ||
            item.field.__typename === 'VOLUNTEER_CheckboxFieldType' ||
            item.field.__typename === 'VOLUNTEER_RatingFieldType' ||
            item.field.__typename === 'VOLUNTEER_TextFieldType' ||
            item.field.__typename === 'VOLUNTEER_TimeFieldType' ||
            item.field.__typename === 'VOLUNTEER_AttachmentFieldType'
          ) {
            return {
              ...item,
              reportTypeItemId: item.reportTypeItemId || undefined,
              field: { ...item.field, fieldId: item.field.fieldId || undefined },
              order,
            };
          }

          if (item.field.__typename === 'VOLUNTEER_DropdownFieldType') {
            return {
              ...item,
              reportTypeItemId: item.reportTypeItemId || undefined,
              field: {
                ...item.field,
                fieldId: item.field.fieldId || undefined,
                dropdownFieldOptions: item.field.dropdownOptions.map((option, optionOrder) => {
                  return {
                    ...option,
                    order: optionOrder,
                  };
                }),
                allowMultiple: item.field.isAllowMultiple,
              },
              order,
            };
          }

          if (item.field.__typename === 'VOLUNTEER_NumericFieldType') {
            return {
              ...item,
              reportTypeItemId: item.reportTypeItemId || undefined,
              field: { ...item.field, fieldId: item.field.fieldId || undefined, hero: item.field.isHero },
              order,
            };
          }

          return assertNever(item.field);
        }),
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save report type',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Report type saved',
          variant: 'success',
        });

        // Refetch on new report type to get it in sidebar
        if (!values.reportTypeId && refetchData) {
          refetchData();
        }

        setReportTypes((prevReportTypes) => {
          const existingReportTypeIdx = prevReportTypes.findIndex((x) => x.reportTypeId === res.data.data.reportTypeId);

          const newReportTypes = prevReportTypes;
          newReportTypes[existingReportTypeIdx] = {
            ...prevReportTypes[existingReportTypeIdx],
            name: values.reportTypeName,
            description: values.reportTypeDescription ?? '',
          };

          return newReportTypes;
        });

        // Unset the selected report so that Mobile returns to the list
        if (isMobile) {
          setSelectedReportTypeId(undefined);
        }
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save report type',
          variant: 'error',
        })
      );
  };

  const value = {
    initialValues,
    reportTypeIsLoading,
    selectedReportTypeId,
    setSelectedReportTypeId,
    reportTypes,
    reportTypesListIsLoading,
    runSaveReportType,
    activities,
    creationModeOn,
    setCreationModeOn,
    saveReportIsLoading: saveReportType.isLoading,
  };

  return <ManageReportTypeContext.Provider value={value} {...props} />;
};

export const useManageReportTypeContext = () => {
  const value = useContext(ManageReportTypeContext);

  if (!value) throw new Error('Gotta be inside of a ManageReportTypeContext.Provider, homie');

  return value;
};

export { ManageReportTypeProvider };
