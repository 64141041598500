import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../../../../common/recurrence/recurrence-model.gql';

export const GET_ACTIVITY_TIMELINE_SCREEN_ACTIVITIES = gql`
  query GetActivityTimelineScreenActivities($volunteerId: String!) {
    vm {
      programs(hasRule: { rule: CreateRoster }) {
        activities(includeInactiveActivities: true) {
          activityId
        }
      }
      volunteer(volunteerId: $volunteerId) {
        activityEnrolments(position: al) {
          activity {
            activityId
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITY_TIMELINE_SIDEBAR_DATA = gql`
  query GetActivityTimelineSidebarData($activityIds: [String!]!, $startDate: Date, $endDate: Date) {
    vm {
      activities(activityIds: $activityIds) {
        ...ActivityTimelineSidebarActivity
      }
    }
  }

  fragment ActivityTimelineSidebarActivity on VOLUNTEER_ActivityType {
    activityId
    name
    startTime
    endTime
    isHidden
    occurrencesBetween(from: $startDate, until: $endDate)
    ... on VOLUNTEER_NonRecurringActivityType {
      __typename
    }
    ... on VOLUNTEER_RecurringActivityType {
      __typename
      schedule {
        recurrences {
          humanReadable
        }
      }
    }
    draftRosters(startDate: $startDate, endDate: $endDate) {
      ...ActivityTimelineSidebarDraftRoster
    }
    activityReports(startDate: $startDate, endDate: $endDate) {
      ...ActivityTimelineSidebarActivityReport
    }
    cancelledActivities(startDate: $startDate, endDate: $endDate) {
      ...ActivityTimelineSidebarCancelledActivity
    }
    closedActivity {
      ...ActivityTimelineSidebarClosedActivity
    }
    isSuspended
    sessions {
      sessionId
    }
    program {
      programId
      name
    }
    rosterTemplates {
      rosterTemplateId
      rosterNotes
      rosterDate
    }
  }

  fragment ActivityTimelineSidebarDraftRoster on VOLUNTEER_DraftRosterType {
    draftRosterId
    dateLastUpdated
    activityDate
    publishedRoster {
      datePublished
      rosterId
    }
    hasUnpublishedChanges
  }

  fragment ActivityTimelineSidebarActivityReport on VOLUNTEER_ActivityReportType {
    activityReportId
    activityDate
    activity {
      sessions {
        sessionId
      }
    }
    sessionReports {
      sessionReportId
      session {
        sessionId
      }
    }
    cancelledSessions {
      sessionId
    }
  }

  fragment ActivityTimelineSidebarCancelledActivity on VOLUNTEER_CancelledActivityType {
    cancelledActivityId
    activityDate
  }

  fragment ActivityTimelineSidebarClosedActivity on VOLUNTEER_ClosedActivityType {
    closedActivityId
  }
`;

export const GET_ACTIVITY_TIMELINE_ACTIVITY = gql`
  query GetActivityTimelineActivity($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        ...ActivityTimelineActivity
      }
    }
  }

  fragment ActivityTimelineActivity on VOLUNTEER_ActivityType {
    activityId
    program {
      programId
      name
      dateSuspended
    }
    name
    description
    isRestrictedActivity
    isHidden
    maxTeam
    hasOpenRoster
    startDate
    startTime
    endTime
    isEnrollable
    isSuspended
    isActive
    nextX(x: 1)
    closedActivity {
      ...ActivityTimelineClosedActivity
    }
    activityLocation {
      ...ActivityTimelineActivityLocation
    }
    activityRemoteLocation {
      details
    }
    ... on VOLUNTEER_NonRecurringActivityType {
      __typename
    }
    ... on VOLUNTEER_RecurringActivityType {
      schedule {
        recurrences {
          ...Recurrence
        }
      }
    }
    sessions {
      ...ActivityTimelineActivitySession
    }
    activeActivityApplications {
      ...ActivityTimelineActivityApplications
    }
    activeActivityWaitlistings {
      ...ActivityTimelineActivityWaitlistings
    }
    activityEnrolments {
      ...ActivityTimelineActivityEnrolments
    }
    activityAttachments {
      ...ActivityAttachment
    }
  }

  fragment ActivityAttachment on VOLUNTEER_ActivityAttachmentType {
    activityAttachmentId
    fileType
    url
    name
  }

  fragment ActivityTimelineActivitySession on VOLUNTEER_SessionType {
    sessionId
    name
    description
    minVolunteers
    maxVolunteers
    activityLocation {
      ...ActivityTimelineActivityLocation
    }
    startTime
    endTime
    reportType {
      reportTypeId
      name
    }
    autoReport
  }

  fragment ActivityTimelineActivityLocation on VOLUNTEER_ActivityLocationType {
    activityLocationId
    timeZone
    comments
    placesAddress {
      description
      formatted
      placesId
      latLong {
        lat
        long
      }
    }
  }

  fragment ActivityTimelineClosedActivity on VOLUNTEER_ClosedActivityType {
    closedActivityId
    activityClosureReason {
      label
    }
    closedBy {
      profile {
        profileId
        preferredName
        lastName
      }
    }
    closedDate
  }

  fragment ActivityTimelineActivityApplications on VOLUNTEER_ActivityApplicationType {
    activityApplicationId
    dateApplied
    volunteer {
      volunteerId
      profile {
        userId
        profileId
        preferredName
        lastName
        avatarUrl
      }
    }
  }

  fragment ActivityTimelineActivityWaitlistings on VOLUNTEER_ActivityWaitlistingType {
    activityWaitlistingId
    dateAdded
    volunteer {
      volunteerId
      profile {
        userId
        profileId
        preferredName
        lastName
        avatarUrl
      }
    }
  }

  fragment ActivityTimelineActivityEnrolments on VOLUNTEER_ActivityEnrolmentType {
    activityEnrolmentId
    position
    volunteer {
      volunteerId
      profile {
        userId
        profileId
        preferredName
        lastName
        avatarUrl
      }
    }
  }

  ${RECURRENCE_FRAGMENT}
`;

const ACTIVITY_TIMELINE_PAST_PUBLISHED_ROSTER_FRAGMENT = gql`
  fragment ActivityTimelinePastPublishedRoster on VOLUNTEER_PublishedRosterType {
    rosterId
    datePublished
    publishedByManager {
      profile {
        profileId
        userId
        preferredName
        lastName
      }
    }
    publishedByVolunteer {
      volunteerId
      profile {
        profileId
        userId
        preferredName
        lastName
      }
    }
    rosterings {
      volunteer {
        volunteerId
        profile {
          userId
          profileId
          preferredName
          lastName
          avatarUrl
        }
      }
      publishedRosteringId
      sessionRosterings {
        publishedSessionRosteringId
        session {
          sessionId
        }
      }
    }
    activityDate
  }
`;

export const GET_ACTIVITY_TIMELINE_PAST_ROSTER = gql`
  query GetActivityTimelinePastPublishedRoster($activityId: String!, $activityDate: Date!) {
    vm {
      publishedRoster(activityId: $activityId, activityDate: $activityDate) {
        ...ActivityTimelinePastPublishedRoster
      }
      activity(activityId: $activityId) {
        ...ActivityTimelinePastRosterActivity
      }
    }
  }

  fragment ActivityTimelinePastRosterActivity on VOLUNTEER_ActivityType {
    activityId
    name
    sessions {
      sessionId
      name
      startTime
      endTime
    }
  }

  ${ACTIVITY_TIMELINE_PAST_PUBLISHED_ROSTER_FRAGMENT}
`;

// ----------------------------------------------------------------------------
// Report Type Field Fragments
// ----------------------------------------------------------------------------
const BASE_FIELD_FRAGMENT = gql`
  fragment BaseField on VOLUNTEER_FieldType {
    __typename
    fieldId
    name
    description
    isHero
  }
`;

const DROPDOWN_FIELD_FRAGMENT = gql`
  fragment DropdownField on VOLUNTEER_DropdownFieldType {
    dropdownOptions {
      ...DropdownOption
    }
    isAllowMultiple
  }

  fragment DropdownOption on VOLUNTEER_DropdownFieldOptionType {
    dropdownFieldOptionId
    name
    order
  }
`;

const TEXT_FIELD_FRAGMENT = gql`
  fragment TextField on VOLUNTEER_TextFieldType {
    isLongText
  }
`;

export const FIELD_FRAGMENT = gql`
  fragment Field on VOLUNTEER_FieldType {
    ...BaseField
    ... on VOLUNTEER_DropdownFieldType {
      ...DropdownField
    }
    ... on VOLUNTEER_TextFieldType {
      ...TextField
    }
  }

  ${BASE_FIELD_FRAGMENT}
  ${DROPDOWN_FIELD_FRAGMENT}
  ${TEXT_FIELD_FRAGMENT}
`;

// ----------------------------------------------------------------------------
// Report Type Item Fragments
// ----------------------------------------------------------------------------

const REPORT_TYPE_FIELD_FRAGMENT = gql`
  fragment ReportTypeField on VOLUNTEER_ReportTypeFieldType {
    reportTypeItemId
    order
    field {
      ...Field
    }
    optional
  }

  ${FIELD_FRAGMENT}
`;

const REPORT_TYPE_HEADING_FRAGMENT = gql`
  fragment ReportTypeHeading on VOLUNTEER_ReportTypeHeadingType {
    reportTypeItemId
    order
    heading
  }
`;

const REPORT_TYPE_ITEM_FRAGMENT = gql`
  fragment ReportTypeItem on VOLUNTEER_ReportTypeItemType {
    ... on VOLUNTEER_ReportTypeFieldType {
      ...ReportTypeField
    }
    ... on VOLUNTEER_ReportTypeHeadingType {
      ...ReportTypeHeading
    }
  }

  ${REPORT_TYPE_FIELD_FRAGMENT}
  ${REPORT_TYPE_HEADING_FRAGMENT}
`;

// ----------------------------------------------------------------------------
// Field Value Fragments
// ----------------------------------------------------------------------------

const BASE_FIELD_VALUE_FRAGMENT = gql`
  fragment BaseFieldValue on VOLUNTEER_FieldValueType {
    __typename
    fieldValueId
    field {
      fieldId
    }
  }
`;

const BOOLEAN_FIELD_VALUE_FRAGMENT = gql`
  fragment BooleanFieldValue on VOLUNTEER_BooleanFieldValueType {
    boolValue
  }
`;

const CHECKBOX_FIELD_VALUE_FRAGMENT = gql`
  fragment CheckboxFieldValue on VOLUNTEER_CheckboxFieldValueType {
    checked
  }
`;

const DROPDOWN_FIELD_VALUE_FRAGMENT = gql`
  fragment DropdownFieldValue on VOLUNTEER_DropdownFieldValueType {
    dropdownFieldOptions {
      dropdownFieldOptionId
      name
    }
  }
`;

const NUMERIC_FIELD_VALUE_FRAGMENT = gql`
  fragment NumericFieldValue on VOLUNTEER_NumericFieldValueType {
    numericValue
  }
`;

const RATING_FIELD_VALUE_FRAGMENT = gql`
  fragment RatingFieldValue on VOLUNTEER_RatingFieldValueType {
    ratingValue
  }
`;

const TEXT_FIELD_VALUE_FRAGMENT = gql`
  fragment TextFieldValue on VOLUNTEER_TextFieldValueType {
    textValue
  }
`;

const TIME_FIELD_VALUE_FRAGMENT = gql`
  fragment TimeFieldValue on VOLUNTEER_TimeFieldValueType {
    timeValue
  }
`;
const ATTACHMENT_FIELD_VALUE_FRAGMENT = gql`
  fragment AttachmentFieldValue on VOLUNTEER_AttachmentFieldValueType {
    attachments {
      attachmentId
      fileType
      url
      name
    }
  }
`;

const FIELD_VALUE_FRAGMENT = gql`
  fragment FieldValue on VOLUNTEER_FieldValueType {
    ...BaseFieldValue
    ... on VOLUNTEER_BooleanFieldValueType {
      ...BooleanFieldValue
    }
    ... on VOLUNTEER_CheckboxFieldValueType {
      ...CheckboxFieldValue
    }
    ... on VOLUNTEER_DropdownFieldValueType {
      ...DropdownFieldValue
    }
    ... on VOLUNTEER_NumericFieldValueType {
      ...NumericFieldValue
    }
    ... on VOLUNTEER_RatingFieldValueType {
      ...RatingFieldValue
    }
    ... on VOLUNTEER_TextFieldValueType {
      ...TextFieldValue
    }
    ... on VOLUNTEER_TimeFieldValueType {
      ...TimeFieldValue
    }
    ... on VOLUNTEER_AttachmentFieldValueType {
      ...AttachmentFieldValue
    }
  }

  ${BASE_FIELD_VALUE_FRAGMENT}
  ${BOOLEAN_FIELD_VALUE_FRAGMENT}
  ${CHECKBOX_FIELD_VALUE_FRAGMENT}
  ${DROPDOWN_FIELD_VALUE_FRAGMENT}
  ${NUMERIC_FIELD_VALUE_FRAGMENT}
  ${RATING_FIELD_VALUE_FRAGMENT}
  ${TEXT_FIELD_VALUE_FRAGMENT}
  ${TIME_FIELD_VALUE_FRAGMENT}
  ${ATTACHMENT_FIELD_VALUE_FRAGMENT}
`;

export const GET_ACTIVITY_TIMELINE_PAST_REPORT = gql`
  query GetActivityTimelinePastReport($activityId: String!, $activityDate: Date!) {
    vm {
      activityReport(activityId: $activityId, activityDate: $activityDate) {
        ...ActivityTimelinePastReport
      }
      activity(activityId: $activityId) {
        ...ActivityTimelinePastReportActivity
      }
      publishedRoster(activityId: $activityId, activityDate: $activityDate) {
        ...ActivityTimelinePastPublishedRoster
      }
    }
  }

  fragment ActivityTimelinePastReportActivity on VOLUNTEER_ActivityType {
    activityId
    name
    sessions {
      ...ActivityTimelinePastReportSession
    }
    allSessions {
      ...ActivityTimelinePastReportSession
    }
    program {
      programId
    }
  }

  ${ACTIVITY_TIMELINE_PAST_PUBLISHED_ROSTER_FRAGMENT}

  fragment ActivityTimelinePastReportSession on VOLUNTEER_SessionType {
    sessionId
    name
    startTime
    endTime
    dateRemoved
    reportType {
      reportTypeId
      name
      items {
        ...ReportTypeItem
      }
    }
    activity {
      activityId
      name
      allowCICO
      program {
        programId
        name
      }
    }
  }

  ${REPORT_TYPE_ITEM_FRAGMENT}

  fragment ActivityTimelinePastReport on VOLUNTEER_ActivityReportType {
    activityReportId
    sessionReports {
      ...ActivityTimelinePastSessionReport
    }
    cancelledSessions {
      sessionId
    }
  }

  fragment ActivityTimelinePastSessionReport on VOLUNTEER_SessionReportType {
    sessionReportId
    submissionDate
    startTime
    endTime
    allowCICO
    CICOs {
      checkIn
      checkOut
      volunteer {
        volunteerId
        profile {
          profileId
          preferredName
          lastName
          avatarUrl
        }
      }
    }
    attendances {
      attendanceId
      activityRoles {
        activityRoleId
      }
      ... on VOLUNTEER_VolunteerAttendanceType {
        volunteer {
          volunteerId
          profile {
            userId
            profileId
            preferredName
            lastName
            avatarUrl
          }
        }
      }
      ... on VOLUNTEER_OtherAttendanceType {
        name
      }
    }
    comments
    submittedBy {
      profile {
        profileId
        preferredName
        lastName
      }
    }
    session {
      sessionId
      reportType {
        reportTypeId
        name
        items {
          ...ReportTypeItem
        }
      }
    }
    fieldValues {
      ...FieldValue
    }
  }

  ${FIELD_VALUE_FRAGMENT}
`;

export const GET_ACTIVITY_TIMELINE_FUTURE_ROSTER = gql`
  query GetActivityTimelineFutureDraftRoster($activityId: String!, $activityDate: Date!) {
    vm {
      activityRoles {
        activityRoleId
        name
      }
      draftRoster(activityId: $activityId, activityDate: $activityDate) {
        ...ActivityTimelineFutureDraftRoster
      }
      activity(activityId: $activityId) {
        ...ActivityTimelineFutureDraftRosterActivity
      }
    }
  }

  fragment ActivityTimelineFutureDraftRosterActivity on VOLUNTEER_ActivityType {
    name
    hasOpenRoster
    sessions {
      ...ActivityTimelineFutureDraftRosterActivitySession
    }
    activityEnrolments {
      ...ActivityTimelineFutureDraftRosterActivityEnrolment
    }
    activeActivityApplications {
      ...ActivityTimelineFutureDraftRosterActivityApplication
    }
    program {
      activeVolunteers {
        ...ActivityTimelineFutureRosterVolunteer
      }
    }
  }

  fragment ActivityTimelineFutureDraftRosterActivitySession on VOLUNTEER_SessionType {
    sessionId
    name
    startTime
    endTime
    minVolunteers
    maxVolunteers
  }

  fragment ActivityTimelineFutureDraftRosterActivityEnrolment on VOLUNTEER_ActivityEnrolmentType {
    activityEnrolmentId
    volunteer {
      ...ActivityTimelineFutureRosterVolunteer
      hasAttendanceForActivity(activityId: $activityId)
    }
    availability(activityDate: $activityDate) {
      sessionAvailabilities {
        session {
          sessionId
        }
      }
    }
    unavailability(activityDate: $activityDate) {
      activityUnavailabilityId
      isActive
    }
  }

  fragment ActivityTimelineFutureDraftRosterActivityApplication on VOLUNTEER_ActivityApplicationType {
    activityApplicationId
    volunteer {
      ...ActivityTimelineFutureRosterVolunteer
    }
  }

  fragment ActivityTimelineFutureDraftRoster on VOLUNTEER_DraftRosterType {
    activity {
      activityId
    }
    activityDate
    draftRosterId
    dateLastUpdated
    lastUpdatedByManager {
      profile {
        profileId
        userId
        preferredName
        lastName
      }
    }
    lastUpdatedByVolunteer {
      profile {
        profileId
        userId
        preferredName
        lastName
      }
    }
    draftRosterings {
      ...ActivityTimelineFutureDraftRosterDraftRostering
    }
    publishedRoster {
      ...ActivityTimelineFutureDraftRosterPublishedRoster
    }
    hasUnpublishedChanges
  }

  fragment ActivityTimelineFutureDraftRosterPublishedRoster on VOLUNTEER_PublishedRosterType {
    rosterId
    datePublished
    publishedByManager {
      profile {
        profileId
        userId
        preferredName
        lastName
      }
    }
    publishedByVolunteer {
      volunteerId
      profile {
        profileId
        userId
        preferredName
        lastName
      }
    }
    activeRosterings {
      ...ActivityTimelineFutureDraftRosterPublishedRostering
    }
    rosterings {
      volunteer {
        volunteerId
      }
      attending
      smsLogs {
        direction
      }
    }
  }

  fragment ActivityTimelineFutureDraftRosterDraftRostering on VOLUNTEER_DraftRosteringType {
    volunteer {
      ...ActivityTimelineFutureRosterVolunteer
    }
    draftRosteringId
    sessionRosterings {
      draftSessionRosteringId
      session {
        sessionId
      }
    }
  }

  fragment ActivityTimelineFutureDraftRosterPublishedRostering on VOLUNTEER_PublishedRosteringType {
    volunteer {
      volunteerId
    }
    attending
    sessionRosterings {
      session {
        sessionId
      }
    }
  }

  fragment ActivityTimelineFutureRosterVolunteer on VOLUNTEER_VolunteerType {
    volunteerId
    profile {
      profileId
      userId
      preferredName
      lastName
      avatarUrl
    }
    activityRoles {
      activityRoleId
    }
    hasAttendanceForActivity(activityId: $activityId)
  }
`;

const VOLUNTEER_REVIEW_COMMON_DETAILS_FRAGMENT = gql`
  fragment VolunteerReviewCommonDetails on VOLUNTEER_VolunteerType {
    volunteerId
    profile {
      profileId
      preferredName
      lastName
      email
      avatarUrl
    }
    dateCreated
    activityApplications {
      activityApplicationId
      activity {
        activityId
        name
        program {
          name
        }
      }
      dateApplied
      dateWithdrawn
    }
    lastAttendance {
      attendanceId
      sessionReport {
        activityReport {
          activityDate
          activity {
            name
          }
        }
      }
    }
    inactiveActivityEnrolments {
      activityEnrolmentId
      dateRemoved
      activity {
        name
        program {
          name
        }
      }
    }
    activityEnrolments {
      activityEnrolmentId
      dateEnrolled
      dateRemoved
      dateEnrolled
      enrolledBy {
        profile {
          profileId
          preferredName
          firstName
          lastName
        }
      }
      activity {
        activityId
        name
        nextX(x: 8)
        ... on VOLUNTEER_NonRecurringActivityType {
          __typename
        }
        startTime
        endTime
        startDate
        endDate
        isActive
        isSuspended
        isRestrictedActivity
        sessions {
          sessionId
        }
        activityLocation {
          placesAddress {
            description
          }
        }
        program {
          programId
          name
        }
        publishedRosters {
          rosterId
          activityDate
          rosterings {
            publishedRosteringId
            volunteer {
              volunteerId
            }
          }
        }
        ... on VOLUNTEER_RecurringActivityType {
          schedule {
            recurrences {
              humanReadable
              startDate
              endDate
            }
          }
        }
        activityReports {
          activityDate
          sessionReports {
            sessionReportId
          }
        }
        cancelledActivities {
          ...VolunteerCommonCancelledActivity
        }
        closedActivity {
          ...VolunteerCommonClosedActivity
        }
        draftRosters {
          ...VolunteerCommonDraftRoster
        }
      }
      availabilities {
        activityAvailabilityId
        activityDate
        isActive
        activityRoles {
          activityRoleId
          name
        }
      }
      unavailabilities {
        activityUnavailabilityId
        activityDate
        isActive
      }
    }
  }
  fragment VolunteerCommonCancelledActivity on VOLUNTEER_CancelledActivityType {
    cancelledActivityId
    activityDate
  }
  fragment VolunteerCommonClosedActivity on VOLUNTEER_ClosedActivityType {
    closedActivityId
  }
  fragment VolunteerCommonDraftRoster on VOLUNTEER_DraftRosterType {
    draftRosterId
    activityDate
    publishedRoster {
      rosterId
      dateRemoved
    }
  }
`;

export const GET_ACTIVITY_APPLICATION_REVIEW = gql`
  query GetActivityApplicationReview($activityApplicationId: String!) {
    vm {
      activityApplication(activityApplicationId: $activityApplicationId) {
        ...ActivityApplicationReview
      }
    }
  }

  fragment ActivityApplicationReview on VOLUNTEER_ActivityApplicationType {
    activityApplicationId
    volunteer {
      ...VolunteerReviewCommonDetails
    }
    dateApplied
    activity {
      activityId
      name
      startTime
      endTime
      ... on VOLUNTEER_NonRecurringActivityType {
        __typename
      }
      ... on VOLUNTEER_RecurringActivityType {
        __typename
        schedule {
          recurrences {
            humanReadable
          }
        }
      }
    }
  }

  ${VOLUNTEER_REVIEW_COMMON_DETAILS_FRAGMENT}
`;

export const GET_ACTIVITY_WAITLISTING_REVIEW = gql`
  query GetActivityWaitlistingReview($activityWaitlistingId: String!) {
    vm {
      activityWaitlisting(activityWaitlistingId: $activityWaitlistingId) {
        activityWaitlistingId
        volunteer {
          ...VolunteerReviewCommonDetails
        }
      }
    }
  }

  ${VOLUNTEER_REVIEW_COMMON_DETAILS_FRAGMENT}
`;

export const GET_ACTIVITY_TIMELINE_ACTIVITY_CANCELLATION = gql`
  query GetActivityTimelineActivityCancellation($cancelledActivityId: String!) {
    vm {
      activity(activityId: $cancelledActivityId) {
        activityId
        name
        program {
          programId
        }
        cancelledActivities {
          activityCancellationReason {
            activityCancellationReasonId
            label
          }
          activityDate
          cancelledDate
          cancelledBy {
            profileId
            preferredName
            lastName
            avatarUrl
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITY_TIMELINE_ACTIVITY_CANCELLATION_REASONS = gql`
  query GetActivityTimelineActivityCancellationReasons {
    vm {
      activityCancellationReasons {
        activityCancellationReasonId
        label
      }
    }
  }
`;

export const GET_ACTIVITY_TIMELINE_ACTIVITY_CLOSURE_REASONS = gql`
  query GetActivityTimelineActivityClosureReasons {
    vm {
      activityClosureReasons {
        activityClosureReasonId
        label
      }
    }
  }
`;

export const GET_ADD_VOLUNTEERS_DIALOG_VOLUNTEERS = gql`
  query GetAddVolunteersDialogVolunteers($programId: String!) {
    vm {
      program(programId: $programId) {
        activeVolunteers {
          volunteerId
          profile {
            userId
            profileId
            preferredName
            lastName
            avatarUrl
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITY_MAP_ACTIVITIES = gql`
  query GetActivityMapActivities($volunteerId: String!) {
    vm {
      programs(hasRule: { rule: CreateRoster }) {
        activities(includeInactiveActivities: true) {
          activityId
        }
      }
      volunteer(volunteerId: $volunteerId) {
        activityEnrolments(position: al) {
          activity {
            activityId
          }
        }
      }
    }
  }
`;