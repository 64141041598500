import { TabletButton } from '@campfire/tablet-button';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  OutlinedInput,
} from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { ImpactDashboardDetailedViewReportType } from './__generated__/ImpactDashboardDetailedViewReportType';

const SELECT_NONE_VALUE = 'none';
const SELECT_ALL_VALUE = 'all';

interface Activity {
  activityId: string;
  name: string;
  programId: string;
  sessionReportTypeIds: Array<string>;
}

interface Program {
  programId: string;
  name: string;
  activityIds: Array<string>;
  sessionReportTypeIds: Array<string>;
}

interface Props {
  activities: Array<Activity>;
  programs: Array<Program>;
  reportTypes: Array<ImpactDashboardDetailedViewReportType>;
  selectedActivityIds: Array<string>;
  selectedProgramIds: Array<string>;
  selectedReportTypeIds: Array<string>;
  setSelectedActivityIds: (value: Array<string>) => void;
  setSelectedProgramIds: (value: Array<string>) => void;
  setSelectedReportTypeIds: (value: Array<string>) => void;
}

const ImpactDashboardDetailedFilters = (props: Props) => {
  const {
    activities,
    programs,
    reportTypes,
    selectedActivityIds,
    selectedProgramIds,
    selectedReportTypeIds,
    setSelectedActivityIds,
    setSelectedProgramIds,
    setSelectedReportTypeIds,
  } = props;

  useEffect(() => {
    if (selectedProgramIds.length === 0) {
      setSelectedProgramIds(programs.map((program) => program.programId));
    }
  }, [programs]);

  useEffect(() => {
    if (selectedActivityIds.length === 0) {
      setSelectedActivityIds(activities.map((activity) => activity.activityId));
    }
  }, [activities]);

  const relevantActivities = useMemo(
    () =>
      activities.filter(
        (activity) =>
          selectedProgramIds.includes(activity.programId) &&
          (selectedReportTypeIds.length > 0
            ? activity.sessionReportTypeIds.reduce<boolean>(
                (previousValue, currentValue) => previousValue || selectedReportTypeIds.includes(currentValue),
                false
              )
            : true)
      ),
    [activities, selectedProgramIds, selectedReportTypeIds]
  );

  const relevantPrograms = useMemo(
    () =>
      programs.filter((program) =>
        program.activityIds.reduce<boolean>(
          (previousValue, currentValue) =>
            previousValue || !!relevantActivities.filter((activity) => activity.activityId === currentValue),
          false
        )
      ),
    [programs, relevantActivities]
  );

  const allProgramsSelected =
    relevantPrograms.length === programs.length && selectedProgramIds.length >= relevantPrograms.length;

  const allActivitiesSelected =
    relevantActivities.length === activities.length && selectedActivityIds.length >= relevantActivities.length;

  return (
    <>
      <Box display='flex' alignContent='center' alignItems='center' flexWrap='wrap'>
        <Box width={196}>
          <FormControl fullWidth variant='outlined' error={selectedReportTypeIds.length === 0}>
            <InputLabel id='report-type-select-filter'>
              {reportTypes.length > 0 ? 'Select Report type' : 'No Report types'}
            </InputLabel>
            <Select
              disabled={reportTypes.length === 0}
              labelId='report-type-select-filter'
              id='report-type-filter-multiselect'
              multiple
              value={selectedReportTypeIds}
              input={<OutlinedInput labelWidth={140} name='age' id='outlined-age-simple' />}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                const includesSelectNone = (event.target.value as string[]).includes(SELECT_NONE_VALUE);
                const includesSelectAll = (event.target.value as string[]).includes(SELECT_ALL_VALUE);

                if (includesSelectNone) {
                  setSelectedReportTypeIds([]);
                  return;
                }

                if (includesSelectAll) {
                  setSelectedReportTypeIds(reportTypes.map((x) => x.reportTypeId));
                  return;
                }

                setSelectedReportTypeIds(event.target.value as string[]);
              }}
              renderValue={(selected) => {
                const selectedIds = selected as Array<string>;
                if (selectedIds.length >= reportTypes.length) return 'All Report types';
                return reportTypes
                  .filter((x) => selectedIds.includes(x.reportTypeId))
                  .map((x) => x.name)
                  .join(', ');
              }}
            >
              {reportTypes.map((reportType) => (
                <MenuItem key={reportType.reportTypeId} value={reportType.reportTypeId}>
                  <Checkbox color='primary' checked={selectedReportTypeIds.indexOf(reportType.reportTypeId) > -1} />
                  <ListItemText primary={reportType.name} />
                </MenuItem>
              ))}

              <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
                <ListItemText primary={'Select All'} />
              </MenuItem>

              <MenuItem key={SELECT_NONE_VALUE} value={SELECT_NONE_VALUE}>
                <ListItemText primary={'Deselect All'} />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box width={196} marginLeft={2}>
          <FormControl fullWidth variant='outlined'>
            <InputLabel variant='outlined' id='program-select-filter'>
              {'Program'}
            </InputLabel>
            <Select
              labelId='program-select-filter'
              id='program-filter-multiselect'
              multiple
              value={selectedProgramIds}
              input={<OutlinedInput labelWidth={66} name='age' id='outlined-age-simple' />}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                const includesSelectNone = (event.target.value as string[]).includes(SELECT_NONE_VALUE);
                const includesSelectAll = (event.target.value as string[]).includes(SELECT_ALL_VALUE);

                if (includesSelectNone) {
                  setSelectedProgramIds([]);
                  return;
                }

                if (includesSelectAll) {
                  setSelectedProgramIds(programs.map((x) => x.programId));
                  return;
                }

                setSelectedProgramIds(event.target.value as string[]);
              }}
              renderValue={(selected) => {
                const selectedIds = selected as Array<string>;
                if (relevantPrograms.length === programs.length && selectedIds.length >= relevantPrograms.length)
                  return 'All Programs';
                return relevantPrograms
                  .filter((x) => selectedIds.includes(x.programId))
                  .map((x) => x.name)
                  .join(', ');
              }}
            >
              {relevantPrograms.map((program) => (
                <MenuItem key={program.programId} value={program.programId}>
                  <Checkbox color='primary' checked={selectedProgramIds.indexOf(program.programId) > -1} />
                  <ListItemText primary={program.name} />
                </MenuItem>
              ))}
              <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
                <ListItemText primary={'Select All'} />
              </MenuItem>

              <MenuItem key={SELECT_NONE_VALUE} value={SELECT_NONE_VALUE}>
                <ListItemText primary={'Deselect All'} />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box width={196} marginLeft={2}>
          <FormControl fullWidth variant='outlined'>
            <InputLabel variant='outlined' id='activity-select-filter'>
              {'Activity'}
            </InputLabel>
            <Select
              labelId='activity-select-filter'
              id='activity-filter-multiselect'
              multiple
              value={selectedActivityIds}
              input={<OutlinedInput labelWidth={56} name='age' id='outlined-age-simple' />}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                const includesSelectNone = (event.target.value as string[]).includes(SELECT_NONE_VALUE);
                const includesSelectAll = (event.target.value as string[]).includes(SELECT_ALL_VALUE);

                if (includesSelectNone) {
                  setSelectedActivityIds([]);
                  return;
                }

                if (includesSelectAll) {
                  setSelectedActivityIds(activities.map((x) => x.activityId));
                  return;
                }

                setSelectedActivityIds(event.target.value as string[]);
              }}
              renderValue={(selected) => {
                const selectedIds = selected as Array<string>;
                if (relevantActivities.length === activities.length && selectedIds.length >= relevantActivities.length)
                  return 'All Activities';
                return relevantActivities
                  .filter((x) => selectedIds.includes(x.activityId))
                  .map((x) => x.name)
                  .join(', ');
              }}
            >
              {relevantActivities.map((activity) => (
                <MenuItem key={activity.activityId} value={activity.activityId}>
                  <Checkbox color='primary' checked={selectedActivityIds.indexOf(activity.activityId) > -1} />
                  <ListItemText primary={activity.name} />
                </MenuItem>
              ))}

              <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
                <ListItemText primary={'Select All'} />
              </MenuItem>

              <MenuItem key={SELECT_NONE_VALUE} value={SELECT_NONE_VALUE}>
                <ListItemText primary={'Deselect All'} />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {selectedReportTypeIds.length === 0 && allProgramsSelected && allActivitiesSelected ? null : (
        <Box marginLeft={1} marginTop={1}>
          <TabletButton
            size='small'
            color='primary'
            variant='outlined'
            onClick={() => {
              setSelectedReportTypeIds([]);
              setSelectedProgramIds(programs.map((x) => x.programId));
              setSelectedActivityIds(activities.map((x) => x.activityId));
            }}
          >
            {'Reset View'}
          </TabletButton>
        </Box>
      )}
    </>
  );
};

export { ImpactDashboardDetailedFilters };
