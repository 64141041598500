import React, { useMemo } from 'react';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import { EditorState, convertFromRaw } from 'draft-js';
import { Message } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { FileUploadGallery, UploadedFile } from '@campfire/file-upload-gallery/lib';

import { CommentHeader } from './CommentHeader';
import { ProfileAvatar } from '../ProfileAvatar';
import { CommentBox, PostCommentType, isDraftJsContent } from './CommentBox';
import { useRemoveComment } from './Comment';
import { IComment } from './types';
import { Reaction } from '../reaction/Reaction';
import { useSaveReaction } from '../reaction/reaction-model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.color.secondary.main700,
      textTransform: 'none',
    },
  })
);

interface CommentViewerProps {
  comment: IComment;
  comments?: Array<IComment>;
  onReply: (parentId: string, reply: string) => void;
  handleEdit?: (body: PostCommentType) => void;
  nested?: number;
  hideCommentBox?: boolean;
  refetch?: () => void;
  enableAttachment?: boolean;
}

export function CommentViewer({
  comment,
  comments,
  onReply,
  nested,
  hideCommentBox,
  handleEdit,
  refetch,
  enableAttachment,
}: CommentViewerProps) {
  const saveReaction = useSaveReaction();
  const removeComment = useRemoveComment();

  const content = React.useMemo(() => {
    if (!comment.content) {
      return '';
    }
    if (isDraftJsContent(comment.content)) {
      return EditorState.createWithContent(convertFromRaw(JSON.parse(comment.content)))
        .getCurrentContent()
        .getPlainText();
    }
    return comment.content;
  }, [comment]);

  const classes = useStyles();
  const [showCommentBox, onShowCommentBox] = React.useState(false);
  const [showCommentAction, setShowCommentAction] = React.useState(false);
  const [editComment, setEditComment] = React.useState(false);
  const nest = nested ?? 1;

  const uploadedFiles: Array<UploadedFile> = useMemo(() => {
    const pulledAttachments = (comment.attachments ?? []).map((attachment: any) => ({
      url: attachment.objectUrl || attachment.url,
      fileId: attachment.attachmentId,
      name: attachment.name,
    }));
    return pulledAttachments;
  }, [comment.attachments]);

  const onClear = () => {
    onShowCommentBox(false);
  };

  const onCancelEdit = () => {
    setEditComment(false);
  };

  const onPost = (body: PostCommentType) => {
    onReply(comment.commentId, body.content);
    onShowCommentBox(false);
  };

  const onEdit = (body: PostCommentType) => {
    if (handleEdit) {
      handleEdit(body);
    }
    setTimeout(() => {
      setEditComment(false);
    }, 200);
  };

  const handleReaction = (symbol: string, reactionId?: string) => {
    saveReaction({
      commentId: comment.commentId,
      emoji: symbol,
      reactionId,
    }).then(() => {
      if (refetch) {
        refetch();
      }
    });
  };

  const handleAction = (type: string) => {
    if (type === 'edit') {
      setEditComment(true);
    }
    if (type === 'remove') {
      removeComment({
        commentId: comment.commentId,
      }).then(() => {
        if (refetch) {
          refetch();
        }
      });
    }
  };

  const showingCommentBox = showCommentBox && !hideCommentBox;

  return (
    <Box pt={'1em'} onMouseEnter={() => setShowCommentAction(true)} onMouseLeave={() => setShowCommentAction(false)}>
      <CommentHeader
        volunteerId={comment.volunteer.volunteerId}
        profile={comment.volunteer.profile}
        time={comment.submittedDate}
        showCommentAction={showCommentAction}
        handleAction={handleAction}
      />
      <Box mt='0.5rem' display='flex'>
        <Box style={{ opacity: 0 }}>
          <ProfileAvatar profile={comment.volunteer.profile} />
        </Box>
        <Box ml={editComment ? 0 : '1rem'} flex={1} minWidth={0}>
          {!editComment && (
            <Box style={{ fontSize: '14px', color: '#4e4e4e' }}>
              <Typography style={{ wordWrap: 'break-word', whiteSpace: 'initial' }}>{content}</Typography>
              {enableAttachment && uploadedFiles.length ? (
                <Box>
                  <Typography variant='body2' color='textSecondary' style={{ fontWeight: 'bold' }}>
                    {'Attachments'}
                  </Typography>
                  <Box minHeight='76px'>
                    <FileUploadGallery allowMultiple uploadedFiles={uploadedFiles ?? []} viewOnly />
                  </Box>
                </Box>
              ) : null}
            </Box>
          )}

          {showingCommentBox && (
            <Box mt='1rem'>
              <CommentBox onClear={onClear} onPost={onPost} />
            </Box>
          )}
          {editComment && (
            <Box mt='1rem'>
              <CommentBox
                onClear={onCancelEdit}
                onPost={onEdit}
                comment={comment}
                enableAttachment={Boolean(enableAttachment)}
              />
            </Box>
          )}
          {!showCommentBox && nest <= 2 && !hideCommentBox && !editComment && (
            <Box display='flex' alignItems='center' mt={1}>
              <Reaction reactions={comment.reactions} handleReaction={handleReaction} />
              <Button
                startIcon={<Message />}
                variant='text'
                className={classes.button}
                onClick={() => onShowCommentBox(true)}
                style={{ marginTop: '4px', marginLeft: '4px' }}
              >
                Reply
              </Button>
            </Box>
          )}
          {comments
            ?.filter((child) => child.parentId === comment.commentId && !child.removedDate)
            .map((child) => (
              <CommentViewer
                comment={child}
                comments={comments}
                onReply={onReply}
                handleEdit={handleEdit}
                nested={nest + 1}
                hideCommentBox={hideCommentBox}
                refetch={refetch}
              />
            ))}
        </Box>
      </Box>
    </Box>
  );
}
