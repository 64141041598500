import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Grid } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { useProgramManagementContext } from '../../ProgramManagementContext';
import { ProgramManagementSingleProgramBasicInfo } from '../../__generated__/ProgramManagementSingleProgramBasicInfo';

interface Props {
  open: boolean;
  close: () => void;
  selectedProgram: ProgramManagementSingleProgramBasicInfo;
}

const DeleteProgramDialog = (props: Props) => {
  const { open, close, selectedProgram } = props;
  const { runDeleteProgram, deleteProgramIsLoading } = useProgramManagementContext();

  return (
    <Dialog open={open}>
      <DialogTitle>{`Delete ${selectedProgram.name}?`}</DialogTitle>
      <DialogContent>
        <Typography>{`${selectedProgram.activities.length} activities will be deleted along with the ${selectedProgram.name} program.`}</Typography>
        <Typography>{'This cannot be undone.'}</Typography>
      </DialogContent>

      <DialogContent>
        <Typography>{`Volunteers who are not in any other program will be hidden in the database. `}</Typography>
        <Typography>{`They will appear again after they're added to another active program.`}</Typography>
      </DialogContent>

      <DialogActions>
        <Grid container justify='flex-end' spacing={1}>
          <Grid item>
            <TabletButton disabled={deleteProgramIsLoading} onClick={() => close()}>
              {'Cancel'}
            </TabletButton>
          </Grid>
          <Grid item>
            <TabletButton
              variant='outlined'
              color='error'
              disabled={deleteProgramIsLoading}
              onClick={() => runDeleteProgram(selectedProgram.programId, close)}
            >
              {'Delete Program'}
            </TabletButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export { DeleteProgramDialog };
