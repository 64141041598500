import React, { FunctionComponent } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

const DesktopWidthHack: FunctionComponent = (props) => {
  const { isMobile } = useCampfireTheme();
  const { children } = props;
  return (
    <Box marginTop={3} paddingLeft={isMobile ? 0 : 3}>
      <Grid container>
        <Grid item xs={12} md={8} lg={6} xl={4}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
export { DesktopWidthHack };
