import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, Collapse, Typography } from '@material-ui/core';
import React from 'react';
import { Avatar } from '../../../../../general/user-profile/components/Avatar';

interface Props {
  show: boolean;
  hasActiveCellChanges: boolean;
  hasPublishedRoster: boolean;
  changedVolunteers: Array<{
    volunteerId: string;
    profile: { preferredName: string; lastName: string; avatarUrl: string | null };
  }>;
  onPublishRosterClick: () => Promise<any>;
  onSaveDraftClick: () => Promise<any>;
  onDiscardChangesClick: () => void;
  isSubmitting: boolean;
  suppressDrafts: boolean;
}

const RosterActionsMobileBox = (props: Props) => {
  const {
    show,
    hasActiveCellChanges,
    hasPublishedRoster,
    changedVolunteers,
    onPublishRosterClick,
    onDiscardChangesClick,
    onSaveDraftClick,
    isSubmitting,
    suppressDrafts,
  } = props;

  return (
    <Collapse in={show}>
      <Box borderTop='1px solid #ececec' borderBottom='1px solid #ececec' paddingY={2} position='relative'>
        {isSubmitting ? <LinearProgressOverlay isLoading={isSubmitting} /> : null}

        <Box paddingLeft={2} paddingRight={1}>
          <Typography variant='h6' style={{ fontWeight: 'bold' }} align='center'>
            {hasActiveCellChanges && !suppressDrafts ? 'Unsaved Changes' : 'Unpublished Changes'}
          </Typography>

          <Box display='flex' justifyContent='center'>
            {changedVolunteers.length > 0 ? (
              <Box display='flex'>
                <TitularTooltip description={''}>
                  <Box display='flex' marginRight={1}>
                    {changedVolunteers.map((x) => (
                      <Avatar
                        key={x.volunteerId}
                        preferredName={x.profile.preferredName}
                        lastName={x.profile.lastName}
                        avatarUrl={x.profile.avatarUrl}
                        size={22}
                        style={{ marginLeft: -2, marginRight: -2 }}
                      />
                    ))}
                  </Box>
                </TitularTooltip>

                <Typography variant='body2'>will be notified</Typography>
              </Box>
            ) : (
              <Typography variant='body2'>Changes have been made to the draft roster</Typography>
            )}
          </Box>
        </Box>

        <Box paddingTop={2} paddingX={2}>
          {changedVolunteers.length > 0 ? (
            <TabletButton
              fullWidth
              data-track='actCnl-roster-header-publish-roster'
              size='medium'
              variant='contained'
              color='primary'
              disabled={isSubmitting}
              onClick={onPublishRosterClick}
            >
              {hasPublishedRoster ? 'Publish Changes' : 'Publish Roster'}
            </TabletButton>
          ) : null}
        </Box>

        <Box display='flex' flex='1 1 auto' justifyContent='center' marginTop={1}>
          <TabletButton
            data-track='actCnl-roster-header-discard-changes'
            size='small'
            disabled={!hasActiveCellChanges || isSubmitting}
            onClick={onDiscardChangesClick}
          >
            {'Discard Changes'}
          </TabletButton>

          {suppressDrafts ? null : (
            <Box display='flex'>
              <TabletButton
                data-track='actCnl-roster-header-save-draft'
                size='small'
                color='primary'
                disabled={!hasActiveCellChanges || isSubmitting}
                onClick={onSaveDraftClick}
              >
                {'Save Changes'}
              </TabletButton>
            </Box>
          )}
        </Box>
      </Box>
    </Collapse>
  );
};

export { RosterActionsMobileBox };
