import { gql } from '@apollo/client';

export const ACTIVITIES_LIST = gql`
  query GetAllActivities($programIds: [String!], $isActive: Boolean) {
    vm {
      programs(programIds: $programIds) {
        activities(activityLeaderCanManage: true, includeInactiveActivities: $isActive) {
          activityId
        }
      }
    }
  }
`;
