import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import UsingDotsGraphic from '../../../assets/tutorial-graphics/my-activities/using_dots.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Resources',
    description: (
      <>Using the Resources page, you can explore and access important documents provided by your organisation.</>
    ),
    image: UsingDotsGraphic,
  },
];

const ResourcesScreenTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { ResourcesScreenTutorialDialog };
