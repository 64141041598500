import React, { FunctionComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Box from '@material-ui/core/Box';
import { SignInScreen, ApplyScreen, ForgotPasswordScreen, ResetPasswordScreen } from '../../screens';
import { useRedirectContext } from '../app-shell/RedirectContext';
import { signInPath } from './sign-in-path';
import { PublicAppBar } from './public-app-bar/PublicAppBar';
import { PublicScreenWrapper } from '../../screens/public-screens/PublicScreenWrapper';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

const RedirectToSignIn: FunctionComponent<{ redirectUrl: string | undefined }> = ({ redirectUrl }) => {
  const { setRedirectUrl } = useRedirectContext();
  if (redirectUrl && redirectUrl !== signInPath && redirectUrl !== '/sign-out') {
    setRedirectUrl(redirectUrl);
  }
  return <Redirect to={signInPath} />;
};

const PublicShell: FunctionComponent<{ redirectUrl?: string }> = ({ redirectUrl }) => {
  const { theme } = useCampfireTheme();
  return (
    <>
      <PublicAppBar />
      <main>
        <Box
          marginTop={`${theme.appShell.menu.height}px`}
          height={window.innerHeight - theme.appShell.menu.height}
          className='Page'
          display='flex'
          flex='1 1 auto'
          flexDirection='column'
          borderRadius={4}
          position='relative'
          style={{
            overflowX: 'visible',
            overflowY: 'auto',
            backgroundColor: '#f6f8f9',
          }}
        >
          <Switch>
            <Route exact path='/' render={() => <RedirectToSignIn redirectUrl={redirectUrl} />} />
            <Route exact path='/apply' render={() => <ApplyScreen />} />
            <Route
              exact
              path={signInPath}
              render={() => (
                <PublicScreenWrapper floating>
                  <SignInScreen />
                </PublicScreenWrapper>
              )}
            />
            <Route
              exact
              path='/forgot-password'
              render={() => (
                <PublicScreenWrapper floating>
                  <ForgotPasswordScreen />
                </PublicScreenWrapper>
              )}
            />
            <Route
              exact
              path='/reset-password'
              render={() => (
                <PublicScreenWrapper floating>
                  <ResetPasswordScreen />
                </PublicScreenWrapper>
              )}
            />
            <Route path='/' render={() => <RedirectToSignIn redirectUrl={redirectUrl} />} />
          </Switch>
        </Box>
      </main>
    </>
  );
};

export default PublicShell;
