import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { AvailabilityCenterActivityType } from '../__generated__/AvailabilityCenterActivityType';
import { ActivityItem } from '../../../screens/program-manager/activity-management/activities-list/ActivityItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderColor: ({ isSelected }: { isSelected?: boolean }) =>
        isSelected ? theme.color.secondary.main900 : theme.color.grey.neutral200,
      '&:first-child': {},
      cursor: 'pointer',
      marginBottom: '25px',
    },
    title: {
      fontSize: 20,
      fontWeight: 900,
      color: theme.color.grey.neutralBrand800,
    },
    subTitleContainer: {
      display: 'flex',
      color: theme.color.grey.neutral200,
      fontSize: 12,
      marginTop: 20,
    },
    icon: {
      fontWeight: 500,
      marginRight: 8,
    },
    subTitle: {
      display: 'flex',
      marginRight: 20,
    },
  })
);

interface Props {
  isSelected?: boolean;
  onSelectActivity: (activityId: string) => void;
  activity: AvailabilityCenterActivityType;
}

export function AvailabilitiesActivityItem({ activity, onSelectActivity, isSelected }: Props) {
  const classes = useStyles({ isSelected });
  return (
    <Box onClick={() => onSelectActivity(activity.activityId)} className={classes.container}>
      <ActivityItem activity={activity} selected={isSelected} availabilityCenterItem />
    </Box>
  );
}
