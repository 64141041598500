import React, { useState } from 'react';
import { TabletButton } from '@campfire/tablet-button/lib';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  OutlinedInput,
  Grid,
  Chip,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import { HoverLink } from '@campfire/hover-link/lib';
import { capitalize } from 'lodash';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../common/cards/alert-card/AlertCard';
import { GetActivityManagementActivityTags_vm_activityTags as ActivityTagType } from '../activity-form-v2/__generated__/GetActivityManagementActivityTags';

export const EditActivityTagsDialog = ({
  open,
  activityTags,
  currentActivityTagIds,
  handleClose,
  handleSaveActivityTags,
}: {
  open: boolean;
  activityTags: ActivityTagType[];
  currentActivityTagIds: string[];
  handleClose: () => void;
  handleSaveActivityTags: (selectedActivityTagIds: string[]) => void;
}) => {
  const [tagsSelectOpen, setTagsSelectOpen] = useState(false);
  const [selectedActivityTagIds, setSelectedActivityTagIds] = useState<string[]>(currentActivityTagIds);
  const { lightTextColor, darkTextColor } = getAlertCardColors('info');

  const handleSave = () => {
    handleSaveActivityTags(selectedActivityTagIds);
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth='sm' fullWidth>
      <DialogTitle>{'Edit Activity Tags?'}</DialogTitle>
      <DialogContent>
        <Typography variant='body2'>
          {
            'Activity tags are a great way to sort and categorise your activities by a whole range of dimensions - locations, types of activities, asset tracking, etc.'
          }
        </Typography>
        <Box my={3}>
          {activityTags.length > 0 ? (
            <FormControl fullWidth>
              <InputLabel
                htmlFor='select-volunteer-roles'
                style={{ left: '14px', top: '-4px', backgroundColor: '#FFFFFF', zIndex: 1 }}
              >{`Activity Tags`}</InputLabel>
              <MuiSelect
                fullWidth
                open={tagsSelectOpen}
                onOpen={() => {
                  setTagsSelectOpen(true);
                }}
                onClose={() => {
                  setTagsSelectOpen(false);
                }}
                multiple
                variant='filled'
                value={selectedActivityTagIds}
                onChange={(event) => {
                  const selectedTags = (event.target.value as string[]).filter((x) => !!x);
                  setSelectedActivityTagIds(selectedTags);
                }}
                input={<OutlinedInput id='select-activity-tags' label='Chip' />}
                renderValue={(tagIds) => (
                  <Grid container spacing={1}>
                    {(tagIds as Array<string>).map((tagId) => {
                      const selectedTag = activityTags.find((x) => x.activityTagId === tagId);
                      return selectedTag ? (
                        <Grid item key={selectedTag.activityTagId}>
                          <Chip color='primary' label={capitalize(selectedTag.name)} />
                        </Grid>
                      ) : null;
                    })}
                  </Grid>
                )}
              >
                {activityTags
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((tag) => {
                    return (
                      <MenuItem key={tag.activityTagId} value={tag.activityTagId}>
                        <Checkbox
                          color='primary'
                          checked={!!selectedActivityTagIds.find((tagId) => tagId === tag.activityTagId)}
                        />
                        {tag.name}
                      </MenuItem>
                    );
                  })}
              </MuiSelect>
            </FormControl>
          ) : (
            <AlertCard variant='info' title='No Activity Tags'>
              <AlertCardBody>
                <Typography style={{ color: lightTextColor, fontSize: '14px' }}>
                  You haven&apos;t created any activity tags yet.{' '}
                  <HoverLink
                    target={'_blank'}
                    style={{ textDecoration: 'underline', color: darkTextColor }}
                    to={'/admin/admin-console/tags'}
                  >
                    Click here to create your first
                  </HoverLink>
                </Typography>
              </AlertCardBody>
            </AlertCard>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display='flex' m={2}>
          <TabletButton data-track='actCnl-waitlist-review-close-dialog' onClick={() => handleClose()}>
            {'Cancel'}
          </TabletButton>
          <TabletButton
            data-track='actCnl-waitlist-review-add-to-team'
            onClick={handleSave}
            color='primary'
            variant='contained'
            autoFocus
          >
            {'Save'}
          </TabletButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
