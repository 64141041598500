import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import { TutorialDialog, TutorialDialogStep } from '../../../../common/dialogs/TutorialDialog';
import UsingDotsGraphic from '../../../../assets/tutorial-graphics/my-activities/using_dots.png';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'My Elements',
    description: (
      <>
        This is a quick view for everything <strong>volunteering</strong>. See when you are next{' '}
        <strong>rostered on</strong>, any <strong>news</strong> for the organisation and any <strong>tasks</strong> you
        have to update.
        <br />
        <br />
        You can also use this screen to update your <strong>profile</strong> information.
      </>
    ),
    image: UsingDotsGraphic,
  },
];

const VolDashboardMyElementsTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { VolDashboardMyElementsTutorialDialog };
