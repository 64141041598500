import React from 'react';
import { Box } from '@material-ui/core';
import { StringParam, useQueryParams } from 'use-query-params';

import { ActivityCard } from './ActivityCard';
import { ActivityItem } from './types';

interface ActivityReportListProps {
  activities: ActivityItem[];
}

export function ActivityReportsList(props: ActivityReportListProps) {
  const { activities } = props;
  const [{ activityDate, activityId }, setActivityParams] = useQueryParams({
    activityDate: StringParam,
    activityId: StringParam,
  });

  const onSelectActivity = (selectedActivityId: string, selectedActivityDate: string) => {
    setActivityParams({
      activityDate: selectedActivityDate,
      activityId: selectedActivityId,
    });
  };

  return (
    <Box display='flex' flexDirection='column' style={{ gap: 12 }}>
      {activities.map((activity) => (
        <ActivityCard
          {...activity}
          selected={activity.activityDate === activityDate && activity.activityId === activityId}
          onSelectActivity={() => onSelectActivity(activity.activityId, activity.activityDate)}
        />
      ))}
    </Box>
  );
}
