import { unpackToDate } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, BoxProps, Divider, Grid, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { SelectActivityCancellationReasonDialog } from '../../common/SelectActivityCancellationReasonDialog';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useUser } from '../../../../../../global/auth/useUser';
import { CancelActivityParams } from '../../activity-timeline-actions';

const smsReminderText = 'An SMS reminder will be sent to your rostered volunteers 24-48 hours prior to this activity.';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageHeaderBox: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
  })
);

type Props = {
  activityName: string;
  activityDate: string;
  onActivityTitleClick: () => void;
  runCancelActivity: (params: CancelActivityParams) => void;
  onEditRosterClick: () => void;
  rosterTemplateId?: string;
  setRosterHeaderHeight: (height: number) => void;
} & BoxProps;

const ActivityRosterHeaderBox = (props: Props) => {
  const {
    activityName,
    activityDate,
    onActivityTitleClick,
    runCancelActivity,
    setRosterHeaderHeight,
    rosterTemplateId,
    onEditRosterClick,
    ...rest
  } = props;
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const readableDate = useMemo(() => {
    return unpackToDate(activityDate).toFormat('cccc, d LLLL');
  }, [activityDate]);
  const { theme } = useCampfireTheme();
  const { user } = useUser();

  const handleResize = () => setRosterHeaderHeight(ref.current?.clientHeight ?? 0);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [showCancellationReasonDialog, setShowCancellationReasonDialog] = useState(false);

  const onCancelActivityClick = () => setShowCancellationReasonDialog(true);

  return (
    <div ref={ref}>
      <Box
        className={classes.pageHeaderBox}
        paddingX={2}
        display='flex'
        justifyContent={'space-between'}
        flexDirection={'row'}
        alignContent={'flex-start'}
        flexWrap='wrap'
        alignItems={'center'}
        {...rest}
      >
        <Box display='flex' flex={1}>
          <Box marginRight={2}>
            <span>
              <HoverText
                variant='h5'
                display='inline'
                color='primary'
                hoverColor='primary'
                onClick={onActivityTitleClick}
                data-track='actCnl-roster-header-title'
              >
                <Grid container alignItems='center'>
                  <Typography variant='h5' display='inline' noWrap>
                    {activityName}
                  </Typography>
                </Grid>
              </HoverText>
            </span>

            <Box display='flex'>
              <Typography variant='h6' style={{ marginRight: 12 }} noWrap>
                {`${readableDate}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display='flex' height='auto' alignItems='center' padding='4px'>
          <TitularTooltip description='Cancel activity for this date'>
            <Box display='inline-block' paddingRight={2}>
              <HoverText
                data-track='actCnl-roster-header-cancel-activity'
                variant='body2'
                color='error'
                hoverColor='error'
                onClick={onCancelActivityClick}
              >
                {'Cancel Activity'}
              </HoverText>
            </Box>
          </TitularTooltip>

          <Box>
            <TabletButton
              data-track='actCnl-roster-header-edit-roster-template'
              style={{ marginRight: 8 }}
              size='small'
              variant='outlined'
              color='primary'
              onClick={onEditRosterClick}
              disabled={!rosterTemplateId}
            >
              {'Roster Settings'}
            </TabletButton>
          </Box>
        </Box>

        <SelectActivityCancellationReasonDialog
          open={showCancellationReasonDialog}
          handleClose={() => setShowCancellationReasonDialog(false)}
          handleSelection={runCancelActivity}
          activityName={activityName}
          activityDate={activityDate}
        />
      </Box>

      {user.orgInfo.smsEnabled ? (
        <>
          <Box display={'flex'} alignItems={'center'} paddingLeft='16px' paddingTop='8px' paddingBottom='8px'>
            <InfoIcon style={{ paddingRight: '4px', color: theme.color.campfireBlue }} />
            <Typography variant='body2'>{smsReminderText}</Typography>
          </Box>

          <Divider />
        </>
      ) : null}
    </div>
  );
};

export { ActivityRosterHeaderBox };
