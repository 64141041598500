import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../../../../common/recurrence/recurrence-model.gql';

const ACTIVITY_ATTACHMENT_V2 = gql`
  fragment ActivityAttachmentV2 on VOLUNTEER_ActivityAttachmentType {
    activityAttachmentId
    fileType
    url
    name
  }
`;

export const CREATE_ACTIVITY_GET_REPORT_TYPES_V2 = gql`
  query CreateActivityGetReportTypesV2 {
    vm {
      reportTypes {
        ...CreateActivityReportTypeV2
      }
    }
  }

  fragment CreateActivityReportTypeV2 on VOLUNTEER_ReportTypeType {
    reportTypeId
    name
    items {
      ... on VOLUNTEER_ReportTypeFieldType {
        reportTypeItemId
        order
        optional
        field {
          fieldId
          name
          description
        }
      }
      ... on VOLUNTEER_ReportTypeHeadingType {
        reportTypeItemId
        order
      }
    }
  }
`;

export const CREATE_ACTIVITY_GET_PROGRAMS_V2 = gql`
  query CreateActivityGetProgramsV2($activityId: String!) {
    vm {
      programs(hasRule: { rule: ManageActivities }) {
        ...CreateActivityProgramV2
      }
      activity(activityId: $activityId) {
        activityId
        program {
          ...CreateActivityProgramV2
        }
      }
    }
  }

  fragment CreateActivityProgramV2 on VOLUNTEER_ProgramType {
    programId
    name
    dateSuspended
  }
`;

export const EDIT_ACTIVITY_GET_ACTIVITY_V2 = gql`
  query EditActivityGetActivityV2($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityId
        name
        description
        isRestrictedActivity
        isHidden
        maxTeam
        hasOpenRoster
        allowCICO
        program {
          programId
        }
        startDate
        closedActivity {
          endDate
        }
        startTime
        endTime
        ... on VOLUNTEER_RecurringActivityType {
          __typename
          schedule {
            recurrences {
              ...Recurrence
            }
          }
        }
        locationType
        activityLocation {
          activityLocationId
          placesAddress {
            description
            formatted
            latLong {
              lat
              long
            }
            placesId
          }
        }
        activityRemoteLocation {
          activityRemoteLocationId
          details
        }
        sessions {
          sessionId
          name
          description
          minVolunteers
          maxVolunteers
          startTime
          endTime
          reportType {
            name
            reportTypeId
          }
          activityLocation {
            activityLocationId
            placesAddress {
              description
              formatted
              latLong {
                lat
                long
              }
              placesId
            }
          }
          autoReport
        }
        activityAttachments {
          ...ActivityAttachmentV2
        }
        activityTags {
          activityTagId
          name
          description
        }
        activityTagged {
          activityTaggedId
          dateRemoved
          activityTag {
            activityTagId
          }
        }
      }
    }
  }

  fragment ActivityAttachmentV2 on VOLUNTEER_ActivityAttachmentType {
    activityAttachmentId
    fileType
    url
    name
  }

  ${RECURRENCE_FRAGMENT}
`;

export const DUPLICATE_ACTIVITY_GET_ACTIVITY_V2 = gql`
  query DuplicateActivityGetActivityV2($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        name
        description
        hasOpenRoster
        program {
          programId
        }
        startDate
        closedActivity {
          endDate
        }
        startTime
        endTime
        ... on VOLUNTEER_RecurringActivityType {
          schedule {
            recurrences {
              endDate
              __typename
              ... on WeeklyRecurrenceType {
                weekInterval
              }
            }
          }
        }

        activityLocation {
          activityLocationId
          placesAddress {
            description
            formatted
            latLong {
              lat
              long
            }
            placesId
          }
        }
        sessions {
          name
          description
          minVolunteers
          maxVolunteers
          startTime
          endTime
          reportType {
            name
            reportTypeId
          }
          activityLocation {
            activityLocationId
            placesAddress {
              description
              formatted
              latLong {
                lat
                long
              }
              placesId
            }
          }
          autoReport
        }
        activityAttachments {
          ...ActivityAttachmentV2
        }
      }
    }
  }
  ${ACTIVITY_ATTACHMENT_V2}
`;

export const GET_ACTIVITY_CLOSURE_REASONS_V2 = gql`
  query GetActivityClosureReasonsV2 {
    vm {
      activityClosureReasons {
        activityClosureReasonId
        label
      }
    }
  }
`;

export const GET_ACTIVITY_MANAGEMENT_ACTIVITY_TAGS = gql`
  query GetActivityManagementActivityTags {
    vm {
      activityTags {
        ...ActivityTag
      }
    }
  }
  fragment ActivityTag on VOLUNTEER_ActivityTagType {
    activityTagId
    name
    description
    isHidden
  }
`;
