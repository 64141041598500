export const ACTIVITY_ENROLMENT_PROMPT_COPY_PRIVATE = {
  apply: {
    title: 'Apply for this activity',
    description:
      "Applications for this activity require approval from a manager. Hit 'Apply Now ' to submit an application to join this activity.",
  },
  waiting: {
    title: 'Awaiting approval from a manager',
    description: "You'll receive an email as soon as there are any updates for this application!",
  },
};

export const ACTIVITY_ENROLMENT_PROMPT_COPY_NON_PRIVATE = {
  join: {
    title: 'Join this activity',
    description:
      "This activity can be joined immediately. Hit 'Join Now' to be part of this activity and start applying for upcoming rosters.",
  },
};

export const ACTIVITY_ENROLMENT_PROMPT_COPY_GENERAL = {
  chooseAvailability: {
    title: 'Choose your availability',
    description: "Almost done! The next step is to select the dates that you'd like to attend",
  },
  keepUpToDate: {
    title: 'Keep your availabilities up to date',
    description: "Don't forget to keep your availabilities for this activity updated.",
  },
};
