import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import YourInControl from '../../../assets/tutorial-graphics/welcome/youre_in_control.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Management Dashboard',
    description: (
      <>
        The Management Dashboard provides an overview of everything you need to <strong>know</strong> or{' '}
        <strong>action</strong>. At a glance view which <strong>rosters</strong> require action,{' '}
        <strong>incoming volunteer applications</strong> and <strong>outstanding reports</strong>.
      </>
    ),
    image: YourInControl,
  },
];

const ManagementDashboardTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { ManagementDashboardTutorialDialog };
