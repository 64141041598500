import React, { useState, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { signInPath } from '../sign-in-path';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

const MobilePublicAppBar = () => {
  const { theme } = useCampfireTheme();

  const [showMenu, setOpenMenu] = useState(false);
  const openMenu = () => setOpenMenu(true);
  const closeMenu = () => setOpenMenu(false);
  const ref = useRef<HTMLButtonElement | null>(null);

  return (
    <AppBar
      style={{
        background: 'white',
        color: '#242424',
        boxShadow: 'none',
        // height: theme.appShell.menu.height,
      }}
    >
      <Box paddingY={1} paddingX={1}>
        <Grid container justify='flex-end'>
          <Grid item>
            <IconButton style={{ color: '#242424' }} ref={ref} onClick={openMenu}>
              <MenuIcon />
            </IconButton>

            <Popover
              open={showMenu}
              onClose={closeMenu}
              anchorEl={ref ? ref.current : undefined}
              anchorReference={'anchorEl'}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Paper>
                <Box minWidth={128}>
                  <List disablePadding>
                    <Link
                      to={signInPath}
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        textDecorationColor: 'none',
                      }}
                    >
                      <ListItem button onClick={closeMenu}>
                        <ListItemText primary='Sign In' style={{ textAlign: 'end' }} />
                      </ListItem>
                    </Link>

                    <Link
                      to='/apply'
                      style={{
                        // color: 'inherit',
                        textDecoration: 'none',
                        textDecorationColor: 'none',
                        color: theme.palette.primary.main,
                      }}
                    >
                      <ListItem button onClick={closeMenu}>
                        <ListItemText primary='Make an account' style={{ textAlign: 'end' }} />
                      </ListItem>
                    </Link>
                  </List>
                </Box>
              </Paper>
            </Popover>
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
};

export default MobilePublicAppBar;
