import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { useCommonStyles } from '../commonStyles';
import { FormSegment } from '../AppTypes';

type SettingFormSegmentProps = {
  editMode: boolean;
  groupTitle: string;
  checkBoxGroup: FormSegment[];
  isFirstColumn: boolean;
  onClick: (label: string, value: boolean) => void;
};

export const SettingGroupFormSegment = (props: SettingFormSegmentProps) => {
  const { editMode, groupTitle, checkBoxGroup, isFirstColumn, onClick } = props;
  const commonStyles = useCommonStyles();
  const { isSm } = useCampfireTheme();
  const { values } = useFormikContext<any>();

  return (
    <Box
      flex={1}
      borderRight={isSm || !isFirstColumn ? 'none' : '1px solid #dfdfdf'}
      style={{ marginLeft: !isFirstColumn ? '1em' : '0' }}
    >
      <Typography className={commonStyles.sectionHeading}>{groupTitle}</Typography>
      {checkBoxGroup.map((group: FormSegment) => (
        <FormGroup>
          <FormControlLabel
            style={{
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              marginRight: 'none',
              letterSpacing: '0.02px',
              alignItems: 'flex-start',
            }}
            control={
              <Checkbox
                checked={values[group.initValueLabel]}
                onChange={() => onClick(group.initValueLabel, !values[group.initValueLabel])}
                color='primary'
                disabled={!editMode}
              />
            }
            label={
              <>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    opacity: '1',
                  }}
                >
                  {group.label}
                </Typography>
                <Typography className={commonStyles.text} style={{ opacity: '1' }}>
                  {group.description}
                </Typography>
              </>
            }
          />
        </FormGroup>
      ))}
    </Box>
  );
};
