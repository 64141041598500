import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import YourInControl from '../../../assets/tutorial-graphics/welcome/youre_in_control.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Volunteers',
    description: (
      <>
        The Volunteers page is a <strong>database of volunteers</strong> allowing you to <strong>view</strong> and{' '}
        <strong>access</strong> their information, documents, and tasks.
      </>
    ),
    image: YourInControl,
  },
  {
    label: 'Volunteers',
    description: (
      <>
        You can track existing volunteers&apos; impact individually or track progress of incoming volunteers and approve
        applications.
      </>
    ),
    image: YourInControl,
  },
  {
    label: 'Volunteers',
    description: (
      <>You can also import volunteers manually if the onboarding and application process is not required.</>
    ),
    image: YourInControl,
  },
];

const VolunteersTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { VolunteersTutorialDialog };
