import React, { useState } from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { Menu, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { CreateCategoryDialog } from './CreateCategoryDialog';

const AddNewButton = () => {
  const [addNewMenuAnchorEl, setAddNewMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openCreateCategoryDialog, setOpenCategoryDialog] = useState(false);
  const match = useRouteMatch();

  return (
    <>
      <TabletButton
        data-track='resources-add-new-menu-button'
        variant='outlined'
        color='primary'
        aria-controls='add-new'
        aria-haspopup='true'
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAddNewMenuAnchorEl(e.currentTarget)}
        endIcon={<ExpandMore />}
      >
        {'Add New'}
      </TabletButton>
      <Menu
        id='add-new'
        anchorEl={addNewMenuAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ style: { border: 'solid 1px #e9e9e9' } }}
        keepMounted
        open={addNewMenuAnchorEl !== null}
        onClose={() => setAddNewMenuAnchorEl(null)}
      >
        <MenuItem
          data-track='resources-add-new-menu-category-option'
          onClick={() => {
            setAddNewMenuAnchorEl(null);
            setOpenCategoryDialog(true);
          }}
        >
          {'New Category'}
        </MenuItem>
        <Link
          to={{ pathname: match?.url ? `${match.url}/create-resource` : '', state: { returnSlug: match?.url ?? '' } }}
          style={{ textDecoration: 'none', textDecorationColor: 'none', color: 'inherit', width: '100%' }}
        >
          <MenuItem
            data-track='resources-add-new-menu-resource-option'
            onClick={() => {
              setAddNewMenuAnchorEl(null);
            }}
          >
            {'New Resource'}
          </MenuItem>
        </Link>
      </Menu>
      <CreateCategoryDialog open={openCreateCategoryDialog} close={() => setOpenCategoryDialog(false)} />
    </>
  );
};

export { AddNewButton };
