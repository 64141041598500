import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../common/cards/alert-card/AlertCard';

const DeleteCakeDialog = ({
  open,
  hasTasks,
  cakeName,
  onClose,
  onConfirm,
}: {
  open: boolean;
  hasTasks: boolean;
  cakeName?: string;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const { lightTextColor } = getAlertCardColors('urgent');

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>{`Delete ${cakeName} Tasks`}</DialogTitle>
      <DialogContent>
        <Typography>
          {hasTasks ? (
            <AlertCard variant='urgent' title='Tasks must be removed'>
              <AlertCardBody>
                <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                  All tasks within this category must be moved or deleted before this task category can be removed.
                </Typography>
              </AlertCardBody>
            </AlertCard>
          ) : (
            `Are you sure you want to delete this category? Deleted task categories are not recoverable.`
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <TabletButton variant='text' onClick={onClose}>
          {hasTasks ? 'Close' : 'Cancel'}
        </TabletButton>
        {!hasTasks && (
          <TabletButton variant='text' color='error' onClick={onConfirm} autoFocus>
            {'Delete'}
          </TabletButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export { DeleteCakeDialog };
