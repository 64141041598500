import { Avatar as MuiAvatar, Box, CircularProgress, Typography } from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useFilePhoto } from '../../../../common/useFilePhoto';

const defaultColour = 'ececec';
const defaultSize = 82;
const fallbackColours = ['F8CBC8', 'F97B6A', '90A9D0', '035185', 'FADD39', '96DCDE', '9896A3', 'DF4831', '76C453'];

export const getAvatarColour = (initials: string | null): string => {
  if (!initials) return defaultColour;
  return fallbackColours[initials[0].charCodeAt(0) % 8];
};

const getStyles = (size: number) => ({
  userAvatar: {
    width: `${size}px`,
    height: `${size}px`,
    color: 'white',
    backgroundColor: 'white',
  },
  typography: {
    fontSize: Math.round(size / 2.5),
    fontWeight: 700,
  },
});

export const FallbackAvatar = ({
  size = defaultSize,
  style,
  initials,
}: {
  initials: string | null;
  size?: number;
  style?: React.CSSProperties;
}) => {
  const classes = makeStyles(getStyles(size))();
  const colour = getAvatarColour(initials);

  return (
    <MuiAvatar
      alt='avatar'
      className={classes.userAvatar}
      style={{ background: `#${colour}`, ...style }}
      sizes={`${size}px`}
    >
      {initials ? (
        <Box style={{ textAlign: 'center' }}>
          <Typography className={classes.typography} style={{ letterSpacing: 0 }}>
            {initials.substring(0, 2).toLocaleUpperCase()}
          </Typography>
        </Box>
      ) : (
        <PersonIcon
          style={{
            width: `${size * 0.6}px`,
            height: `${size * 0.6}px`,
          }}
        />
      )}
    </MuiAvatar>
  );
};

export const LoadingAvatar = ({ size = defaultSize }: { size?: number }) => {
  const classes = makeStyles(getStyles(size))();
  return (
    <MuiAvatar alt='avatar' className={classes.userAvatar} sizes={`${size}px`}>
      <CircularProgress size={size} thickness={2} />
    </MuiAvatar>
  );
};

export interface AvatarProps {
  preferredName?: string;
  lastName?: string;
  avatarFile?: File;
  avatarUrl?: string | null;
  style?: React.CSSProperties;
  size?: number;
}

const Avatar = ({ preferredName, lastName, avatarFile, avatarUrl, style, size = defaultSize }: AvatarProps) => {
  const classes = makeStyles(getStyles(size))();
  const file = useFilePhoto(avatarFile);
  const initials = preferredName && lastName ? `${preferredName[0]}${lastName[0]}`.toUpperCase() : null;

  return (
    <>
      {!avatarUrl && !avatarFile ? (
        <FallbackAvatar initials={initials} size={size} style={{ ...style }} />
      ) : avatarUrl ? (
        <MuiAvatar
          src={avatarUrl}
          alt='avatar'
          className={classes.userAvatar}
          sizes={`${size}px`}
          style={{ ...style }}
        />
      ) : file ? (
        <MuiAvatar src={file} alt='avatar' className={classes.userAvatar} sizes={`${size}px`} style={{ ...style }} />
      ) : (
        <LoadingAvatar size={size} />
      )}
    </>
  );
};

export { Avatar };
