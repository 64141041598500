import React, { ReactNode } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

interface Props {
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      height: theme.appShell.menu.height,
      padding: 0,
      boxSizing: 'border-box',
    },
  })
);
export const AdminConsoleVolunteerProfileHeader = (props: Props) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.header} justify='center'>
      <Grid item xs={12} sm={10}>
        {props.children}
      </Grid>
    </Grid>
  );
};
