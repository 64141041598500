import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import { DistributiveOmit } from '../../../../../common/types/DistributiveOmit';
import { MyElementsUpcomingRoster } from './__generated__/MyElementsUpcomingRoster';
import { MyElementsUpcomingRostersActivity } from './__generated__/MyElementsUpcomingRostersActivity';

export interface KeyValuedUpcomingRosters {
  [key: string]: MyElementsUpcomingRosterUnpackedDate[];
}

export type MyElementsUpcomingRosterUnpackedDate = DistributiveOmit<MyElementsUpcomingRoster, 'activityDate'> & {
  activityDate: DateTime;
};

export interface MyElementsAttendance {
  activityId: string;
  activityDate: DateTime;
}

export interface MyElementsActivityReport {
  activityId: string;
  activityDate: DateTime;
  completed: boolean;
}

export function getUpcomingRosterStatus(upcomingRosterTypeName: string): string {
  return upcomingRosterTypeName === 'VOLUNTEER_UpcomingRosterRosteringType'
    ? 'rostered'
    : upcomingRosterTypeName === 'VOLUNTEER_UpcomingRosterCancellationType'
    ? 'cancelled'
    : upcomingRosterTypeName === 'VOLUNTEER_UpcomingRosterUnspecifiedType'
    ? 'unspecified'
    : upcomingRosterTypeName === 'VOLUNTEER_UpcomingRosterUnavailabilityType'
    ? 'unavailable'
    : upcomingRosterTypeName === 'VOLUNTEER_UpcomingRosterAvailabilityType'
    ? 'available'
    : '';
}

export function getSessionsMessage(activity: MyElementsUpcomingRostersActivity): string {
  const sessionCount = activity.sessions.length;
  if (sessionCount === 0) {
    return 'No sessions available';
  }

  if (sessionCount === 1) {
    return '1 session available';
  }

  return `${sessionCount} sessions available`;
}

export function getStatusMessage(upcomingRoster: MyElementsUpcomingRoster): string {
  const { activity } = upcomingRoster;

  if (upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterRosteringType') {
    return activity.hasOpenRoster ? 'You are attending this activity' : 'You are rostered for this activity';
  }
  if (upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterCancellationType') {
    return 'This activity is cancelled';
  }
  if (upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterUnspecifiedType') {
    return activity.hasOpenRoster
      ? 'You have not indicated your attendance'
      : 'You have not indicated your availability';
  }
  if (upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterUnavailabilityType') {
    return activity.hasOpenRoster ? 'You are not attending this activity' : 'You are unavailable for this activity';
  }
  if (upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterAvailabilityType') {
    const isPublished = !!upcomingRoster.publishedRoster;
    if (isPublished) {
      return 'You are not rostered for this activity';
    }
    return activity.hasOpenRoster ? 'You have not indicated your attendance' : 'Waiting to be rostered';
  }
  return '';
}

export const GET_MY_ELEMENTS_UPCOMING_ROSTERS = gql`
  query GetMyElementsUpcomingRosters($volunteerId: String!, $dateBegin: Date!, $dateEnd: Date!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        upcomingRosters(startDate: $dateBegin, endDate: $dateEnd) {
          ...MyElementsUpcomingRoster
        }
        attendances {
          attendanceId
          sessionReport {
            activityReport {
              activityDate
              activity {
                activityId
              }
            }
          }
        }
        rosterings {
          publishedRosteringId
          publishedRoster {
            activityDate
            activity {
              sessions {
                sessionId
              }
              activityId
              activityReports {
                activityDate
                sessionReports {
                  sessionReportId
                }
                cancelledSessions {
                  sessionReports {
                    sessionReportId
                  }
                }
              }
            }
          }
        }
        activityEnrolments {
          activity {
            sessions {
              sessionId
            }
            activityId
            activityReports {
              sessionReports {
                sessionReportId
              }
              activityDate
              cancelledSessions {
                sessionReports {
                  sessionReportId
                }
              }
            }
          }
        }
      }
    }
  }

  fragment MyElementsUpcomingRoster on VOLUNTEER_UpcomingRosterType {
    ... on VOLUNTEER_UpcomingRosterCancellationType {
      upcomingRosterId
      activity {
        ...MyElementsUpcomingRostersActivity
      }
      activityDate
      __typename
      cancelledActivity {
        cancelledActivityId
        cancelledDate
        activityCancellationReason {
          activityCancellationReasonId
          label
        }
      }
      publishedRoster {
        datePublished
      }
    }

    ... on VOLUNTEER_UpcomingRosterAvailabilityType {
      upcomingRosterId
      activity {
        ...MyElementsUpcomingRostersActivity
      }
      activityDate
      __typename
      activityAvailability {
        ...MyElementsUpcomingRostersActivityAvailability
      }
      activityEnrolment {
        activityEnrolmentId
        availabilities {
          activityDate
          sessionAvailabilities {
            session {
              sessionId
            }
          }
        }
      }
      publishedRoster {
        datePublished
      }
    }

    ... on VOLUNTEER_UpcomingRosterRosteringType {
      upcomingRosterId
      activity {
        ...MyElementsUpcomingRostersActivity
      }
      activityDate
      __typename
      rostering {
        ...MyElementsUpcomingRostersPublishedRostering
      }
      nullableActivityEnrolment: activityEnrolment {
        activityEnrolmentId
      }
      publishedRoster {
        datePublished
      }
    }

    ... on VOLUNTEER_UpcomingRosterUnavailabilityType {
      upcomingRosterId
      activity {
        ...MyElementsUpcomingRostersActivity
      }
      activityDate
      activityUnavailability {
        ...MyElementsUpcomingRostersActivityUnavailability
      }
      activityEnrolment {
        activityEnrolmentId
      }
      publishedRoster {
        datePublished
      }
    }

    ... on VOLUNTEER_UpcomingRosterUnspecifiedType {
      upcomingRosterId
      activity {
        ...MyElementsUpcomingRostersActivity
      }
      activityDate
      activityEnrolment {
        activityEnrolmentId
      }
      publishedRoster {
        datePublished
      }
    }
  }

  fragment MyElementsUpcomingRostersActivityAvailability on VOLUNTEER_ActivityAvailabilityType {
    activityAvailabilityId
    activityDate
  }

  fragment MyElementsUpcomingRostersActivityUnavailability on VOLUNTEER_ActivityUnavailabilityType {
    activityUnavailabilityId
    activityDate
  }

  fragment MyElementsUpcomingRostersPublishedRostering on VOLUNTEER_PublishedRosteringType {
    publishedRosteringId
    publishedRoster {
      datePublished
      rosterTemplate {
        detailsChanged
        rosterTemplateId
      }
    }
    sessionRosterings {
      session {
        sessionId
      }
    }
  }

  fragment MyElementsUpcomingRostersActivity on VOLUNTEER_ActivityType {
    __typename
    activityId
    startTime
    endTime
    name
    hasOpenRoster
    activityRemoteLocation {
      activityRemoteLocationId
    }
    activityLocation {
      placesAddress {
        placesId
        description
        formatted
      }
    }
    program {
      name
      programId
    }
    sessions {
      sessionId
      name
      startTime
      endTime
      description
      activityLocation {
        placesAddress {
          placesId
          description
          formatted
        }
      }
    }
  }
`;

export const GET_MY_ELEMENTS_ROSTER_OCCURRENCES = gql`
query GetMyElementsRosterOccurrences($activityId: String!, $endDate: Date!) {
  vm {
    activity(activityId: $activityId) {
      __typename
      nextUntil(until: $endDate)
      ... on VOLUNTEER_RecurringActivityType {
        recurrences {
          ... on DailyRecurrenceType {
            __typename
            dayInterval
          }
          ... on WeeklyRecurrenceType {
            __typename
            weekInterval
          }
          ... on MonthlyByMonthDayRecurrenceType {
            __typename
          }
          ... on MonthlyNthDayRecurrenceType {
            __typename
          }
          ... on YearlyRecurrenceType {
            __typename
          }
          ... on SingleRecurrenceType {
            __typename
          }
        }
      }
      activityEnrolments {
        volunteer {
          volunteerId
        }
        availabilities {
          activityAvailabilityId
          activityDate
          isActive
        }
      }
    }
  }
}
`;

export const GET_MY_ELEMENTS_SINGLE_UPCOMING_ROSTER = gql`
  query GetMyElementsSingleUpcomingRoster($activityId: String!, $activityDate: Date!) {
    vm {
      activity(activityId: $activityId) {
        name
        startTime
        endTime
        publishedRoster(activityDate: $activityDate) {
          rosterId
          rosterings {
            ...MyElementsSingleUpcomingRosterPublishedRostering
          }
        }
        program {
          programId
          programManagers {
            ...MyElementsSingleUpcomingRosterProgramManagers
          }
        }
      }
    }
  }

  fragment MyElementsSingleUpcomingRosterPublishedRostering on VOLUNTEER_PublishedRosteringType {
    publishedRosteringId
    volunteer {
      volunteerId
      profile {
        userId
        profileId
        preferredName
        lastName
        email
        avatarUrl
        contactNumber
      }
      activityEnrolment(activityId: $activityId) {
        ...MyElementsSingleUpcomingRosterActivityEnrolment
      }
    }
    sessionRosterings {
      publishedSessionRosteringId
      session {
        sessionId
      }
    }
    activityRoles {
      activityRoleId
      name
    }
  }

  fragment MyElementsSingleUpcomingRosterActivityEnrolment on VOLUNTEER_ActivityEnrolmentType {
    activityEnrolmentId
    position
  }

  fragment MyElementsSingleUpcomingRosterProgramManagers on VOLUNTEER_ProgramManagerType {
    programManagerId
    manager {
      profile {
        profileId
        userId
        preferredName
        lastName
      }
    }
  }
`;
