import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { WidgetTypes } from './dashboard-widget-model';

interface Props {
  icon: string;
  title: string;
  subtitle: string;
  type: WidgetTypes;
  isSelected: boolean;
  onClick: (type: WidgetTypes) => void;
}
export const ThumbnailWidgetCard = ({ icon, title, subtitle, type, isSelected, onClick }: Props) => {
  const { theme } = useCampfireTheme();
  return (
    <Box
      style={{
        background: isSelected ? theme.color.grey.neutralBrand100 : theme.color.white.neutral50,
        border: '2px solid #DCDCDC',
        borderRadius: '6px',
        width: '180px',
        height: '230px',
        padding: '16px',
        cursor: 'pointer',
      }}
      onClick={() => onClick(type)}
    >
      <Box display='flex' justifyContent='center' my={4}>
        <img src={icon} alt='' style={{ height: '63px' }} />
      </Box>
      <Box display='flex' justifyContent='center' my={1}>
        <Typography
          style={{ fontWeight: 500, fontSize: '19px', lineHeight: '24px', color: theme.color.grey.neutral300 }}
        >
          {title}
        </Typography>
      </Box>
      <Box display='flex' justifyContent='center'>
        <Typography
          style={{ fontSize: '15px', lineHeight: '20px', color: theme.color.grey.neutral300, textAlign: 'center' }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};
