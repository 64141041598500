import React from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { Tabs, Tab, Theme, Box, Typography, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { StringParam, useQueryParams } from 'use-query-params';
import { BorderColorOutlined, ExpandMore } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { SearchField } from '../../../common/inputs/SearchField';

export enum TasksTabEum {
  DASHBOARD = 'Dashboard',
  TASKS = 'Tasks',
}

const tabs = ['Dashboard', 'Tasks'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabRoot: {
      color: theme.color.grey.neutralBrand800,
      textTransform: 'capitalize',
      fontWeight: 800,
      fontSize: '20px',
      letterSpacing: '-0.02em',
      minWidth: 'unset',
      opacity: 0.7,
    },
    selected: {
      color: `${theme.color.grey.neutralBrand900} !important`,
      opacity: 1,
    },
    tabsFlexContainer: {
      alignContent: 'space-between',
      borderBottom: '2px solid',
      borderBottomColor: '#EFEFEF',
    },
    root: {
      flex: 1,
    },
  })
);

const TasksMenuToolbar = () => {
  const [{ q, cid }, setQuery] = useQueryParams({
    q: StringParam,
    cid: StringParam,
  });
  const history = useHistory();
  const { theme, isMobile, isMd } = useCampfireTheme();

  const onCreate = () => {
    history.push(`/admin/tasks/task-builder?cid=${cid}`);
  };

  return (
    <Box display='flex' justifyContent='flex-end' flex={1} alignItems='center'>
      <Box display='flex' alignItems='center'>
        <Box style={{ marginRight: '10px' }}>
          <SearchField
            value={q || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              e.stopPropagation();
              setQuery({ q: e.target.value });
            }}
          />
        </Box>
        <TabletButton
          variant='contained'
          style={{
            backgroundColor: theme.color.secondary.main900,
            color: '#ffffff',
            boxSizing: 'border-box',
            borderRadius: '4px',
            textTransform: 'none',
            padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
            minWidth: '40px',
          }}
          onClick={() => {
            onCreate();
          }}
        >
          <BorderColorOutlined style={{ marginTop: '1px', fontSize: '16px' }} />
          <Typography
            style={{
              marginLeft: '8px',
              fontWeight: 600,
              fontSize: '13px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: isMobile || isMd ? 'none' : 'inherit',
            }}
          >
            Create Task
          </Typography>
        </TabletButton>
      </Box>
    </Box>
  );
};

const TasksMenubarMobile = ({
  selectedTab,
  rootPathname,
  hide,
}: {
  selectedTab: string;
  rootPathname: string;
  hide?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();

  const handleOpenDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display={hide ? 'none' : 'flex'}
      alignItems='center'
      style={{ paddingBottom: '10px', borderBottom: '2px solid #EFEFEF' }}
    >
      <TabletButton
        size='small'
        variant='outlined'
        color='primary'
        aria-controls='task-menu-dropdown-mobile'
        aria-haspopup='true'
        onClick={handleOpenDropdown}
        endIcon={<ExpandMore />}
        style={{
          border: '1px solid #9e9e9e',
          boxSizing: 'border-box',
          borderRadius: '4px',
          padding: '7px 12px',
          color: '#444444',
          marginRight: '8px',
          minWidth: '138px',
          fontSize: '16px',
          fontWeight: 900,
        }}
      >
        {selectedTab}
      </TabletButton>
      <Menu
        id='task-menu-dropdown-mobile'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {tabs.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              if (option === tabs[0]) {
                history.push(`${rootPathname}`);
              } else {
                history.push(`${rootPathname}/${option}`);
              }
              handleCloseDropdown();
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <TasksMenuToolbar />
    </Box>
  );
};

export const TasksMenubar = () => {
  const classes = useStyles();
  const rootPathname = '/admin/tasks';

  const location = useLocation();
  const history = useHistory();

  const { isMobile } = useCampfireTheme();

  const selectedTab = location.pathname.split('/')[3] || tabs[0];

  const handleChange = (_unused: any, newValue: TasksTabEum) => {
    if (newValue === tabs[0]) {
      history.push(`${rootPathname}`);
    } else {
      history.push(`${rootPathname}/${newValue}`);
    }
  };

  if (isMobile) {
    return <TasksMenubarMobile selectedTab={selectedTab} rootPathname={rootPathname} />;
  }

  return (
    <Tabs
      value={selectedTab || TasksTabEum.DASHBOARD}
      onChange={handleChange}
      indicatorColor='primary'
      textColor='primary'
      aria-label='icon tabs example'
      classes={{
        flexContainer: classes.tabsFlexContainer,
      }}
    >
      <Tab
        label='Dashboard'
        classes={{ selected: classes.selected, root: classes.tabRoot }}
        value={TasksTabEum.DASHBOARD}
      />
      <Tab label='Tasks' classes={{ selected: classes.selected, root: classes.tabRoot }} value={TasksTabEum.TASKS} />
      <TasksMenuToolbar />
    </Tabs>
  );
};
