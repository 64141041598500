import { createStyles, makeStyles } from '@material-ui/styles';

export const containerStyle = makeStyles(() => createStyles({
  root: {
    height: 600,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar ': {
      display: 'none'
    }
  }
}))
