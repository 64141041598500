import { FugFile } from '@campfire/file-upload-gallery';
import { useEndpointFetch } from '../../../global/network/useEndpointFetch';

export interface SaveProgramVolunteersValues {
  programId: string;
  volunteerIds: string[];
}

export const useSaveProgramVolunteersFetch = () => {
  return useEndpointFetch<SaveProgramVolunteersValues, {}>('/vm/program/volunteers/save');
};

export interface SaveProgramManagersValues {
  volunteerId?: string;
  managerId?: string;
  addedProgramIds: string[];
  removedProgramManagerIds: string[];
}

export const useSaveProgramManagersFetch = () => {
  return useEndpointFetch<SaveProgramManagersValues, {}>('/vm/program-manager/edit');
};

export interface SaveProgramValues {
  programId?: string;
  name: string;
  description?: string;
  active: boolean;
  isHidden: boolean;
  isRestrictedProgram: boolean;
}
export const useSaveProgramFetch = () => {
  return useEndpointFetch<SaveProgramValues, { programId: string }>('/vm/program/save');
};

export const useUpdateProgramImageFetch = () => {
  return useEndpointFetch<{ programId: string; image: FugFile }>(`/vm/program/image/update`, {
    formData: true,
  });
};

export interface DeleteProgramValues {
  programId: string;
}

export const useDeleteProgramFetch = () => {
  return useEndpointFetch<DeleteProgramValues, {}>('/vm/program/delete');
};

export const useApproveProgramApplication = () => {
  return useEndpointFetch<{ programApplicationId: string }>('/vm/program/incoming/approve');
};

export const useWithdrawProgramApplication = () => {
  return useEndpointFetch<{ programApplicationId: string }>('/vm/program/incoming/reject');
};

export const useApproveBulkProgramApplications = () => {
  return useEndpointFetch<{ programApplicationIds: (string | number)[]; programId: string }>(
    '/vm/program/incoming/approve-bulk'
  );
};

export const useWithdrawBulkProgramApplications = () => {
  return useEndpointFetch<{ programApplicationIds: (string | number)[]; programId: string }>(
    '/vm/program/incoming/reject-bulk'
  );
};
