import React from 'react';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { GridCloseIcon } from '@material-ui/data-grid';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';
import { IncidentDetail } from '../../../../../../../program-manager/incident-management-v2/incident-detail/IncidentDetail';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedIncidentId: string;
  handleSuccess: () => void;
}

export const IncidentDetailRAWidgetDialog = (props: Props) => {
  const { open, onClose, selectedIncidentId, handleSuccess } = props;
  const { isMobile } = useCampfireTheme();

  return (
    <Dialog
      scroll='paper'
      open={open}
      onClose={onClose}
      title=''
      fullScreen={isMobile}
      PaperProps={{ style: { minWidth: isMobile ? 'auto' : '640px', maxWidth: '640px', paddingBottom: '24px' } }}
    >
      <DialogContent>
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 0 }}
        >
          <GridCloseIcon color='action' />
        </IconButton>
        <IncidentDetail incidentId={selectedIncidentId} getIncidentsRefetch={handleSuccess} />
      </DialogContent>
    </Dialog>
  );
};
