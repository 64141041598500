import { gql } from '@apollo/client';

export const GET_EXPORT_BY_ID = gql`
  query GetExportById($exportId: String) {
    vm {
      export(exportId: $exportId) {
        exportId
        name
        exportStatus
        requestedBy {
          profile {
            preferredName
            lastName
          }
        }
        completedDate
        exportType
        dateStarted
        attachment {
          url
        }
        isExpired
      }
    }
  }
`;
