import React from 'react';
import { Tabs, Tab, Theme, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { ActivityTabValue } from './ActivityTypes';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { ActivitiesMobileMenu } from './ActivitiesMobileMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabRoot: {
      textTransform: 'capitalize',
      fontWeight: 800,
      fontSize: '20px',
      paddingBottom: '14px',
      minWidth: 'unset',
    },
    selected: {
      color: `${theme.color.grey.neutralBrand800} !important`,
    },
    tabsFlexContainer: {
      alignContent: 'space-between',
      borderBottom: '2px solid',
      borderBottomColor: '#EFEFEF',
      height: 'calc(100% - 2px)',
    },
    root: {
      flex: 1,
      marginLeft: 60,
      marginRight: 60,
      marginTop: '1rem',
    },
  })
);

interface Props {
  activeTab: ActivityTabValue;
  onSelectTab: (value: ActivityTabValue) => void;
  children?: React.ReactNode;
}

export const ActivitiesTabs = ({ activeTab = 'all', onSelectTab, children }: Props) => {
  const classes = useStyles();
  const { isMobile } = useCampfireTheme();

  const handleChange = (_unused: any, newValue: ActivityTabValue) => {
    onSelectTab(newValue);
  };

  return isMobile ? (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <ActivitiesMobileMenu />
      {children}
    </Box>
  ) : (
    <Tabs
      value={activeTab}
      onChange={handleChange}
      indicatorColor='primary'
      textColor='primary'
      aria-label='icon tabs example'
      classes={{
        flexContainer: classes.tabsFlexContainer,
      }}
    >
      <Tab label='All programs' classes={{ selected: classes.selected, root: classes.tabRoot }} value='programs' />
      <Tab label='All activities' classes={{ selected: classes.selected, root: classes.tabRoot }} value='all' />
      <Tab label='My Rosters' classes={{ selected: classes.selected, root: classes.tabRoot }} value='mine' />
      {children}
    </Tabs>
  );
};
