import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { uniq } from 'lodash';
import React, { useState } from 'react';
import { CommonRequiringActionWidgetProps } from '../../shared';
import { GET_WIDGET_INCOMPLETE_ACTIVITY_REPORTS } from './incomplete-report-ra-widget-model.gql';
import { useCampfireQuery } from '../../../../../../../../global/network/useCampfireQuery';
import {
  GetWidgetIncompleteActivityReports,
  GetWidgetIncompleteActivityReportsVariables,
} from './__generated__/GetWidgetIncompleteActivityReports';
import { ActivityCard } from '../../../../../../activity-report/ActivityReports/ActivityCard';

import { IncompleteReportRAWidgetDialog } from './IncompleteReportRAWidgetDialog';

export interface ActivityReportType {
  activityId: string;
  activityDate: string;
  name: string;
  startTime?: string | null;
  endTime?: string | null;
}

export const IncompleteReportsRAWidget = (props: CommonRequiringActionWidgetProps) => {
  const { filters, range } = props;
  const { programIds, activityIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };
  const [selectedActivityReport, setSelectedActivityReport] = useState<ActivityReportType>();

  const onSelectActivityReport = (activityReport: ActivityReportType) => {
    setSelectedActivityReport(activityReport);
  };

  const { data, loading, refetch } = useCampfireQuery<
    GetWidgetIncompleteActivityReports,
    GetWidgetIncompleteActivityReportsVariables
  >(GET_WIDGET_INCOMPLETE_ACTIVITY_REPORTS, {
    options: {
      variables: {
        programIds: programIds.length > 0 && !programIds.includes('all') ? programIds : null,
        activityIds: activityIds.length > 0 && !activityIds.includes('all') ? activityIds : null,
        includeInactiveActivities: true,
        range: range ?? 'all',
      },
    },
  });

  const incompleteReports =
    data?.vm.incompleteActivityReports.flatMap((activity) => {
      return uniq(activity.occurrences).map((date) => ({
        ...activity,
        activityDate: date,
      }));
    }) || [];

  const sortedIncompleteReports = incompleteReports?.sort((a, b) => (a.activityDate > b.activityDate ? -1 : 1));

  const onDialogClose = () => {
    setSelectedActivityReport(undefined);
  };

  return (
    <>
      <Box width='100%' height='100%' overflow='scroll' maxHeight='max-content'>
        <Box display='flex' flexDirection='column' style={{ gap: 12, overflowY: 'scroll' }}>
          {loading ? (
            <Skeleton variant='text' width='100%' height='65px' />
          ) : (
            sortedIncompleteReports.map((activityReport) => (
              <ActivityCard
                {...activityReport}
                selected={false}
                onSelectActivity={() => onSelectActivityReport(activityReport)}
              />
            ))
          )}
        </Box>
      </Box>
      {selectedActivityReport && (
        <IncompleteReportRAWidgetDialog
          open={!!selectedActivityReport}
          onClose={onDialogClose}
          selectedReport={selectedActivityReport}
          refetchMainList={refetch}
        />
      )}
    </>
  );
};
