import React from 'react';
import { Box, Theme, Typography, List, ListItem, Dialog, DialogContent } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { StringParam, useQueryParam } from 'use-query-params';
import { Skeleton } from '@material-ui/lab';
import { v4 as uuidv4 } from 'uuid';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { GetTaskDashboard, GetTaskDashboardVariables } from './__generated__/GetTaskDashboard';
import { TASK_DASHBOARD } from './task-dashboard-model.gql';
import { Task } from '../Task/Task';
import { VolunteerSubmit } from '../VolunteerSubmit/VolunteerSubmit';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'stretch',
      flex: '1 1 0',
      minHeight: 0,
    },
    title: {
      fontWeight: 900,
      fontSize: '1.25rem',
      color: theme.color.grey.neutralBrand800,
      marginTop: 36,
    },
    subTitle: {
      fontWeight: 400,
      color: theme.color.grey.neutral200,
      fontSize: '0.9rem',
      marginTop: '6px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      paddingRight: (props: { isMobile: boolean }) => (props.isMobile ? '20px' : '60px'),
      overflowY: 'scroll',
    },
    taskContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px',
      paddingBottom: '5rem',
      gap: '28px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
  })
);

export function Dashboard() {
  const { data, refetch, loading } = useCampfireQuery<GetTaskDashboard, GetTaskDashboardVariables>(TASK_DASHBOARD, {
    options: {
      variables: {
        status: 'pending',
      },
    },
  });
  const [taskId, setTaskId] = useQueryParam('taskId', StringParam);
  const [q] = useQueryParam('q', StringParam);
  const { theme, isMobile } = useCampfireTheme();
  const classes = useStyles({ isMobile });
  const reviewingTasks = data?.vm.tasks || [];
  const focusingTaskId: string | undefined = React.useMemo(() => {
    if (reviewingTasks.length === 0 || isMobile) {
      return undefined;
    }
    const isSelectedInList = reviewingTasks.findIndex((reviewingTask) => reviewingTask.taskId === taskId) > -1;
    if (isSelectedInList) {
      return taskId;
    }
    return reviewingTasks[0].taskId;
  }, [taskId, reviewingTasks]);

  React.useEffect(() => {
    setTaskId(focusingTaskId);
  }, [focusingTaskId]);

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Typography variant='h3' className={classes.title}>
          Requiring Approval
        </Typography>
        <Typography className={classes.subTitle}>
          These tasks have been updated by volunteers and require your approval.
        </Typography>
        <Box className={classes.taskContainer}>
          {loading ? (
            <List disablePadding>
              {Array.from(Array(4)).map(() => (
                <ListItem disableGutters key={uuidv4()}>
                  <Skeleton variant='rect' width='100%' height='130px' style={{ borderRadius: '8px' }} />
                </ListItem>
              ))}
            </List>
          ) : reviewingTasks.length === 0 ? (
            <Typography
              style={{ textAlign: 'center', color: theme.color.grey.neutral400, fontSize: '15px', paddingTop: '12px' }}
            >
              <span role='img' aria-label='Congratulations!'>
                🎉
              </span>{' '}
              You&#39;re all up to date!
            </Typography>
          ) : (
            reviewingTasks
              .filter((task) => task.title.toLowerCase().includes(q?.toLowerCase() || ''))
              .map((task) => <Task key={task.taskId} task={task} onClick={() => setTaskId(task.taskId)} />)
          )}
        </Box>
      </Box>
      {isMobile ? (
        taskId ? (
          <Dialog fullWidth fullScreen maxWidth='xs' open={!!taskId}>
            <DialogContent>
              <VolunteerSubmit key={taskId} taskId={taskId} onClose={() => setTaskId(undefined)} onUpdate={refetch} />
            </DialogContent>
          </Dialog>
        ) : null
      ) : (
        <CollapsibleSideBar>
          {taskId ? (
            <VolunteerSubmit key={taskId} taskId={taskId} onClose={() => setTaskId(undefined)} onUpdate={refetch} />
          ) : null}
        </CollapsibleSideBar>
      )}
    </Box>
  );
}
