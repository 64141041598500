import { TabletButton } from '@campfire/tablet-button';
import { Box, Dialog, DialogActions, DialogContent, Slide, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { ReactNode } from 'react';

export interface AlertInfoDialogProps {
  open: boolean;
  onClose: () => void;
  title: string | ReactNode;
  content: Array<string>;
}

const AlertInfoDialog = (props: AlertInfoDialogProps) => {
  const { open, onClose, title, content } = props;

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
        <Box padding={2}>
          <Box width={1} style={{ textAlign: 'center', boxSizing: 'border-box' }} padding={2}>
            <Typography display='inline' variant='h5'>
              {title}
            </Typography>
          </Box>

          {content.map((x) => (
            <DialogContent key={x.substr(0, 12)}>
              <Typography variant='body2'>{x}</Typography>
            </DialogContent>
          ))}
        </Box>

        <DialogActions style={{ justifyContent: 'center', paddingBottom: 16 }}>
          <TabletButton size='large' onClick={onClose}>
            {'Got it!'}
          </TabletButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export { AlertInfoDialog };
