import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { TabletButton } from '@campfire/tablet-button';
import { useRunSaveUnavailability } from './use-run-save-unavailability';
import { useRunWithdrawUnavailability } from './use-run-withdraw-unavailability';

const VolunteerUnavailabilityConfirmDialog = (
  props: {
    preferredName: string;
    activityDate: string;
    activityEnrolmentId?: string;
    activityUnavailabilityId?: string;
    runRefetch: () => void;
    onClose: () => void;
  } & Omit<DialogProps, 'onClose'>
) => {
  const {
    open,
    preferredName,
    activityDate,
    activityEnrolmentId,
    activityUnavailabilityId,
    runRefetch,
    onClose,
  } = props;

  const runSaveUnavailability = useRunSaveUnavailability(runRefetch);
  const runWithdrawUnavailability = useRunWithdrawUnavailability(runRefetch);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle>
        {activityUnavailabilityId ? `Make ${preferredName} Available?` : `Remove ${preferredName}'s Availability?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {activityUnavailabilityId
            ? 'Volunteer will be moved to the activity volunteer list.'
            : 'Volunteer will be moved to the unavailable list. This can be undone at any time.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TabletButton variant='text' onClick={() => onClose && onClose()} style={{ marginRight: 8 }}>
          {'Go Back'}
        </TabletButton>
        {activityUnavailabilityId ? (
          <TabletButton
            variant='text'
            color='primary'
            onClick={() => {
              runWithdrawUnavailability(activityUnavailabilityId, () => onClose && onClose());
            }}
          >
            {'Make Available'}
          </TabletButton>
        ) : (
          <TabletButton
            variant='text'
            color='error'
            onClick={() => {
              if (!activityEnrolmentId) return;
              runSaveUnavailability(activityEnrolmentId, activityDate);
            }}
          >
            {'Remove Availability'}
          </TabletButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export { VolunteerUnavailabilityConfirmDialog };
