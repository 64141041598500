import { TabletButton, TabletButtonProps } from '@campfire/tablet-button';
import { Box, Grid } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import React, { memo, PropsWithChildren } from 'react';

interface Props {
  handleDiscard?: () => void;
  saveButtonName: string;
  closeButtonName: string;
  saveButtonProps?: TabletButtonProps & ButtonProps;
  closeButtonProps?: TabletButtonProps;
}

const StickyHeaderV2 = memo(
  ({
    handleDiscard,
    children,
    saveButtonName,
    closeButtonName,
    saveButtonProps,
    closeButtonProps,
  }: PropsWithChildren<Props>) => {
    return (
      <Box>
        <Box
          boxSizing='border-box'
          display='flex'
          justifyContent='flex-end'
          paddingY={1}
          paddingX={2}
          borderBottom='solid 1px #e9e9e9'
        >
          {children}

          {handleDiscard ? (
            <Grid item>
              <TabletButton size='medium' onClick={handleDiscard} {...closeButtonProps}>
                {closeButtonName}
              </TabletButton>
            </Grid>
          ) : null}

          <Grid item style={{ marginLeft: 8 }}>
            <TabletButton size='medium' type='submit' {...saveButtonProps}>
              {saveButtonName}
            </TabletButton>
          </Grid>
        </Box>
      </Box>
    );
  }
);

export { StickyHeaderV2 };
