import { TabletButton } from '@campfire/tablet-button';
import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { AppIdType, SettingGroup } from '../AppTypes';
import { SettingGroupFormSegment } from './SettingGroupFormSegment';
import { sliceIntoChunks } from '../AppHelpers';
import { IIntegrationConfiguration, useSaveIntegrationConfiguration } from './useGetIntegrationConfiguration';

const APP_SETTING_GROUP = {
  mailchimp: [
    {
      groupTitle: 'Subscribers',
      checkBoxGroup: [
        {
          label: 'Add & Update Volunteers',
          description:
            'New volunteers will be added as subscribers and updated if their email address in Volaby changes.',
          initValueLabel: 'addVolunteers',
        },
        {
          label: 'Remove Volunteers',
          description: 'Retired volunteers will be removed as subscribers',
          initValueLabel: 'removeVolunteers',
        },
      ],
    },
    {
      groupTitle: 'Segments & Tags',
      checkBoxGroup: [
        {
          label: 'Program Segments',
          description:
            'Subscribers will be segmented by program. Adding to and removing programs from a volunteer will be reflected in Mailchimp',
          initValueLabel: 'segmentByProgram',
        },
        {
          label: 'Activity Tags',
          description:
            'Subscribers will have tags for each activity they are enrolled in as a volunteer. Updating activity enrolments will be reflected in Mailchimp.',
          initValueLabel: 'activityTags',
        },
      ],
    },
  ],
  salesforce: [
    {
      groupTitle: 'Contacts',
      checkBoxGroup: [
        {
          label: 'Add & Update Volunteers',
          description:
            'New volunteers will be added as subscribers and updated if their email address in Volaby changes.',
          initValueLabel: 'addVolunteers',
        },
      ],
    },
    {
      groupTitle: 'Additional Data',
      checkBoxGroup: [
        {
          label: 'Program Segments',
          description:
            'Subscribers will be segmented by program. Adding to and removing programs from a volunteer will be reflected in Mailchimp',
          initValueLabel: 'segmentByProgram',
        },
      ],
    },
  ],
  campaignMonitor: [
    {
      groupTitle: 'Subscribers',
      checkBoxGroup: [
        {
          label: 'Add & Update Volunteers',
          description:
            'New volunteers will be added as subscribers and updated if their email address in Volaby changes.',
          initValueLabel: 'addVolunteers',
        },
      ],
    },
    {
      groupTitle: 'Segments & Tags',
      checkBoxGroup: [
        {
          label: 'Program Segments',
          description:
            'Subscribers will be segmented by program. Adding to and removing programs from a volunteer will be reflected in Campaign Monitor',
          initValueLabel: 'segmentByProgram',
        },
      ],
    },
  ],
};

export const AppSettingForm = ({
  editMode,
  id,
  initialValues,
}: {
  editMode: boolean;
  id: string;
  initialValues: any;
}) => {
  const { isSm } = useCampfireTheme();
  const { theme } = useCampfireTheme();

  const settingGroup: SettingGroup[] = APP_SETTING_GROUP[id as AppIdType];
  const settingGroupSetOfTwo = sliceIntoChunks(settingGroup, 2);
  const saveConfiguration = useSaveIntegrationConfiguration(id);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: IIntegrationConfiguration) => {
        saveConfiguration(values);
      }}
    >
      {({ setFieldValue }) => {
        return (
          <Form style={{ height: '100%' }}>
            {settingGroupSetOfTwo.map((gp) => (
              <Box
                display='flex'
                flexDirection={isSm ? 'column' : 'row'}
                py='1rem'
                marginBottom={'1em'}
                width={gp.length === 1 ? '50%' : '100%'}
              >
                {gp.map((g: SettingGroup, index) => (
                  <SettingGroupFormSegment
                    groupTitle={g.groupTitle}
                    checkBoxGroup={g.checkBoxGroup}
                    editMode={editMode}
                    isFirstColumn={index === 0}
                    onClick={(label, value) => setFieldValue(label, value)}
                  />
                ))}
              </Box>
            ))}
            {editMode && (
              <Box display='flex' justifyContent='flex-end'>
                <TabletButton
                  type='submit'
                  color='primary'
                  variant='contained'
                  style={{
                    backgroundColor: theme.color.secondary.main900,
                    color: '#ffffff',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    padding: '7px 12px',
                    fontWeight: 600,
                    fontSize: '13px',
                  }}
                >
                  Save
                </TabletButton>
              </Box>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
