import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

interface Props {
  heading?: string;
  subHeading?: string;
  children?: React.ReactNode;
}

export const EmptyMessageCard = (props: Props) => {
  const { heading, subHeading, children } = props;
  const { theme } = useCampfireTheme();

  return (
    <Card
      elevation={0}
      style={{ border: `solid ${theme.color.grey.border} 1px`, marginTop: 16, marginLeft: 16, marginRight: 16 }}
    >
      <CardContent style={{ padding: 0, paddingTop: 64, paddingBottom: 64, maxWidth: 596 }}>
        <Box paddingX={8} paddingBottom={2}>
          <Typography variant='h6'>{heading}</Typography>
          <Typography variant='body2' color='textSecondary'>
            {subHeading}
          </Typography>
        </Box>
        {children}
      </CardContent>
    </Card>
  );
};
