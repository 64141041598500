import React, { memo, useEffect, useRef, useState } from 'react';
import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FixedSizeList } from 'react-window';
import { Waypoint } from 'react-waypoint';
import { debounce } from 'lodash';

import { SearchField } from '../../../../../common/inputs/SearchField';
import { Avatar } from '../../../../general/user-profile/components/Avatar';
import { useAdminConsoleActions } from '../../admin-console-actions';
import { GET_ALL_VOLUNTEERS } from './admin-console-admins-panel.gql';
import { AllAdminUsersVolunteer } from './__generated__/AllAdminUsersVolunteer';
import {
  GetAllVolunteers,
  GetAllVolunteersVariables,
  GetAllVolunteers_vm_searchVolunteers as IVolunteerType,
} from './__generated__/GetAllVolunteers';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

type Props = {
  open: boolean;
  onClose: () => void;
  allAdminUserIds: string[];
  onAddAdminSuccess: () => void;
};

export const VolunteerListItem = memo(
  ({
    volunteer,
    volunteerIdToAdd,
    setVolunteerIdToAdd,
    style,
  }: {
    volunteer: AllAdminUsersVolunteer;
    volunteerIdToAdd: string | undefined;
    setVolunteerIdToAdd: (x: string) => void;
    style: React.CSSProperties;
  }) => {
    const {
      volunteerId,
      profile: { preferredName, lastName, avatarUrl },
    } = volunteer;
    const checked = volunteerIdToAdd === volunteerId;
    return (
      <>
        <ListItem button onClick={() => setVolunteerIdToAdd(volunteerId)} style={style}>
          <Avatar size={32} preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} />
          <ListItemText style={{ marginLeft: 8 }} primary={`${preferredName} ${lastName}`} />
          {checked && (
            <ListItemIcon>
              <Checkbox color='primary' tabIndex={-1} checked={checked} />
            </ListItemIcon>
          )}
        </ListItem>
      </>
    );
  }
);

const Row = ({ index, style, data }: any) => {
  const { loading, onLoadMore, volunteers, hasMore, isEmpty } = data;
  const volunteer = volunteers[index];
  const { theme } = useCampfireTheme();

  if (isEmpty && !loading) {
    return (
      <Box marginTop={'2rem'} textAlign={'center'}>
        <Typography style={{ color: theme.color.grey.neutral400, fontSize: '14px' }}>
          There are no volunteers matching your search
        </Typography>
      </Box>
    );
  }

  if (!volunteer) {
    return (
      <Box style={style}>
        {loading && (
          <Box style={{ alignItems: 'center', display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}
        {hasMore && <Waypoint onEnter={onLoadMore} />}
      </Box>
    );
  }

  return (
    <VolunteerListItem
      volunteer={data.volunteers[index]}
      setVolunteerIdToAdd={data.setVolunteerIdToAdd}
      style={style}
      volunteerIdToAdd={data.volunteerIdToAdd}
    />
  );
};

export const AddAdminUserDialog = ({ open, onClose, allAdminUserIds, onAddAdminSuccess }: Props) => {
  const [volunteerIdToAdd, setVolunteerIdToAdd] = useState<string>();
  const [searchVolunteerInput, setSearchVolunteerInput] = useState<string>('');
  const [page, setPage] = useState(0);
  const [queryDebounced, setQueryDebounced] = useState<string>('');
  const [volunteers, setVolunteers] = useState<IVolunteerType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const setDebounced = React.useRef(debounce(setQueryDebounced, 500)).current;
  const initialLoaded = useRef(true);
  useEffect(() => {
    setDebounced(searchVolunteerInput);
  }, [searchVolunteerInput]);

  const { runAddAdminVolunteer, runAddAdminVolunteerLoading } = useAdminConsoleActions();
  const [fetchVolunteers, { loading }] = useCampfireLazyQuery<GetAllVolunteers, GetAllVolunteersVariables>(
    GET_ALL_VOLUNTEERS
  );

  useEffect(() => {
    setPage(0);
    setVolunteers([]);
    fetchVolunteers({
      variables: {
        q: queryDebounced,
        limit: 25,
        offset: 0,
        exceptVolunteerIds: allAdminUserIds,
      },
    }).then((response) => {
      const data = response.data?.vm.searchVolunteers || [];
      setVolunteers(data);
      setHasMore(data.length > 0);
      initialLoaded.current = false;
    });
  }, [queryDebounced]);

  const handleSuccess = () => {
    onClose();
    setVolunteerIdToAdd(undefined);
    onAddAdminSuccess();
  };

  const onLoadMore = () => {
    if (loading) {
      return;
    }
    const nextPage = page + 1;

    fetchVolunteers({
      variables: {
        q: queryDebounced,
        limit: 25,
        offset: nextPage * 25,
        exceptVolunteerIds: allAdminUserIds,
      },
    }).then((response) => {
      const data = response.data?.vm.searchVolunteers || [];
      setVolunteers(volunteers.concat(data));
      setPage(nextPage);
      setHasMore(data.length > 0);
    });
  };

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid item container xs={12} direction='column' style={{}}>
          <Typography display='inline' variant='h6' gutterBottom>
            {'Add existing users as Admins'}
          </Typography>
          <Typography variant='body2'>
            {"Click on a user's name to make them an admin. Select 'Done' when you have finished adding new admins."}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {loading && initialLoaded.current ? (
          <VolunteerListSkeleton />
        ) : (
          <>
            <SearchField
              placeholder='Search Volunteers'
              onChange={(e) => setSearchVolunteerInput(e.target.value)}
              fullWidth={false}
              growLeft
            />
            <FixedSizeList
              height={300}
              itemCount={volunteers.length + 1}
              itemSize={51}
              width={550}
              itemData={{
                volunteers: volunteers,
                setVolunteerIdToAdd,
                volunteerIdToAdd,
                loading,
                onLoadMore,
                hasMore,
                isEmpty: volunteers.length === 0,
              }}
              style={{
                overflowX: 'hidden',
                marginTop: '1em',
              }}
            >
              {Row}
            </FixedSizeList>
          </>
        )}
      </DialogContent>
      <DialogActions style={{ marginRight: '15px', marginBottom: '8px' }}>
        <TabletButton
          variant='text'
          onClick={() => {
            setSearchVolunteerInput('');
            onClose();
          }}
        >
          {'Cancel'}
        </TabletButton>
        <TabletButton
          variant='contained'
          color='primary'
          disabled={!volunteerIdToAdd}
          onClick={() =>
            volunteerIdToAdd ? runAddAdminVolunteer({ volunteerId: volunteerIdToAdd, handleSuccess }) : null
          }
        >
          {runAddAdminVolunteerLoading ? <CircularProgress size={20} /> : 'Done'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

const VolunteerListSkeleton = () => (
  <Grid container spacing={1}>
    <Grid item container alignContent='center' alignItems='center' direction='row' spacing={1}>
      <Grid item>
        <Skeleton variant='circle' width={36} height={36} />
      </Grid>
      <Grid item>
        <Skeleton variant='text' width={200} height={12} />
      </Grid>
    </Grid>
    <Grid item container alignContent='center' alignItems='center' direction='row' spacing={1}>
      <Grid item>
        <Skeleton variant='circle' width={36} height={36} />
      </Grid>
      <Grid item>
        <Skeleton variant='text' width={200} height={12} />
      </Grid>
    </Grid>
    <Grid item container alignContent='center' alignItems='center' direction='row' spacing={1}>
      <Grid item>
        <Skeleton variant='circle' width={36} height={36} />
      </Grid>
      <Grid item>
        <Skeleton variant='text' width={200} height={12} />
      </Grid>
    </Grid>
    <Grid item container alignContent='center' alignItems='center' direction='row' spacing={1}>
      <Grid item>
        <Skeleton variant='circle' width={36} height={36} />
      </Grid>
      <Grid item>
        <Skeleton variant='text' width={200} height={12} />
      </Grid>
    </Grid>
  </Grid>
);
