import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import { Avatar, Grid, IconButton, Typography } from '@material-ui/core';
import { Email as EmailIcon, KeyboardArrowLeft } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useMemo } from 'react';
import { StatusChip } from '../../../../common/chips/StatusChip';
import { ConfirmationDialog } from '../../../../common/dialogs/ConfirmationDialog';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { IncomingVolunteersApplicationTaskSection } from './components/incoming-volunteer-task-list-sections/IncomingVolunteersApplicationTaskSection';
import { IncomingVolunteersInviteTaskSection } from './components/incoming-volunteer-task-list-sections/IncomingVolunteersInviteTaskSection';
import { TaskSideMenuBar } from './components/side-menu-content/TaskSideMenuBar';
import { IncomingVolunteersLeftColumnWrapper } from './components/wrappers/IncomingVolunteersLeftColumnWrapper';
import { IncomingVolunteersPageWrapper } from './components/wrappers/IncomingVolunteersPageWrapper';
import { IncomingVolunteersRightColumnWrapper } from './components/wrappers/IncomingVolunteersRightColumnWrapper';
import { useIncomingVolunteerActions } from './incoming-volunteer-action.gql';
import { GET_INCOMING_VOLUNTEER_INVITEE_DETAIL, LeftMenuItemType } from './incoming-volunteer-model.gql';
import {
  GetIncomingVolunteerInviteeDetail,
  GetIncomingVolunteerInviteeDetailVariables,
} from './__generated__/GetIncomingVolunteerInviteeDetail';

const useStyles = () => {
  const { theme, isMobile } = useCampfireTheme();
  return makeStyles({
    listItem: {
      padding: isMobile ? '16px' : '16px 48px',
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    iconContainer: {
      marginRight: 8,
    },
    taskMenuBarWrapper: {
      borderLeft: `1px solid ${theme.color.grey.border}`,
      borderTop: `1px solid ${theme.color.grey.border}`,
      boxShadow: '0px 5px 8px rgba(114, 114, 114, 0.16), 0px 7px 14px rgba(214, 214, 214, 0.26)',
    },
  })();
};

const IncomingVolunteerViewInviteeScreen = ({
  inviteeId,
  goBack,
  reloadList,
}: {
  inviteeId: string;
  goBack: () => void;
  reloadList?: () => void;
}) => {
  const [leftMenuItem, setLeftMenuItem] = useState<LeftMenuItemType>();
  const { data, loading } = useCampfireQuery<
    GetIncomingVolunteerInviteeDetail,
    GetIncomingVolunteerInviteeDetailVariables
  >(GET_INCOMING_VOLUNTEER_INVITEE_DETAIL, {
    options: {
      variables: {
        inviteeId: inviteeId,
      },
    },
  });
  const { theme, isMobile } = useCampfireTheme();
  const classes = useStyles();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);

  const invitee = useMemo(() => {
    return data?.vm.invitee ?? undefined;
  }, [data]);

  const {
    runResendInviteEmail,
    runResendInviteEmailLoading,
    runRevokeInvite,
    runRevokeInviteLoading,
  } = useIncomingVolunteerActions();

  return (
    <IncomingVolunteersPageWrapper>
      <CircularProgressOverlay isLoading={runResendInviteEmailLoading || runRevokeInviteLoading || loading} />
      {openConfirmationDialog && invitee && (
        <ConfirmationDialog
          title={'Revoke invite?'}
          body={'This record will be deleted, and the invite link will be disabled.'}
          open
          closeActionText={'Go Back'}
          confirmActionText={'Revoke Invite'}
          handleCloseClick={() => setOpenConfirmationDialog(false)}
          handleConfirmClick={() =>
            runRevokeInvite({
              inviteeId: invitee.inviteeId,
            }).then(() => {
              if (reloadList) reloadList();
              goBack();
            })
          }
        />
      )}
      <IncomingVolunteersLeftColumnWrapper rightColumnOpen={!!leftMenuItem}>
        <Grid item container direction='row' wrap='nowrap' alignItems='center' className={classes.listItem}>
          <Grid item>
            <IconButton onClick={() => goBack()}>
              <KeyboardArrowLeft />
            </IconButton>
          </Grid>
          <Grid item className={classes.iconContainer}>
            <Avatar>
              <EmailIcon width={20} height={20} />
            </Avatar>
          </Grid>
          <Grid item container direction='column'>
            <Grid item>
              <Typography component='span' variant='body2' color='textSecondary'>
                {invitee?.email}
              </Typography>
            </Grid>
            <Grid item container direction='row' alignItems='center' spacing={2}>
              <Grid item>
                <StatusChip status={'Invited'} bgcolor={theme.status.green.light} />
              </Grid>
              <Grid item>
                <Typography variant='caption' color='textSecondary'>
                  {`Invited ${formatDistance(unpackToDateTime(invitee?.dateInvited))}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!leftMenuItem && invitee ? (
            <Grid container direction='row' spacing={1} justify='flex-end'>
              <Grid item>
                <TabletButton
                  variant='outlined'
                  color='primary'
                  onClick={() =>
                    runResendInviteEmail({
                      inviteeId: invitee.inviteeId,
                    })
                  }
                >
                  {isMobile ? 'Resend' : 'Resend email'}
                </TabletButton>
              </Grid>
              {!invitee.dateRevoked && (
                <Grid item>
                  <TabletButton variant='text' color='error' onClick={() => setOpenConfirmationDialog(true)}>
                    {isMobile ? 'Revoke' : 'Revoke Invite'}
                  </TabletButton>
                </Grid>
              )}
            </Grid>
          ) : null}
        </Grid>
        {invitee && (
          <IncomingVolunteersInviteTaskSection
            subText={`Invited by ${invitee.invitedBy.profile.preferredName} ${invitee.invitedBy.profile.lastName}`}
            status={`Invited ${formatDistance(unpackToDateTime(invitee.dateInvited))}`}
            onClick={() => setLeftMenuItem({ inviteeId: inviteeId, type: invitee.__typename })}
          />
        )}

        <IncomingVolunteersApplicationTaskSection status={'none'} taskRecentActivityStatus={'Not viewed'} />
      </IncomingVolunteersLeftColumnWrapper>

      {leftMenuItem ? (
        <IncomingVolunteersRightColumnWrapper>
          <TaskSideMenuBar onClose={() => setLeftMenuItem(undefined)} leftMenuItem={leftMenuItem} reload={reloadList} />
        </IncomingVolunteersRightColumnWrapper>
      ) : null}
    </IncomingVolunteersPageWrapper>
  );
};

export { IncomingVolunteerViewInviteeScreen };
