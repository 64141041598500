import { CampfireRichEditor } from '@campfire/campfire-rich-editor/lib';
import { FileUploadGallery, FugFile, UploadedFile } from '@campfire/file-upload-gallery';
import { TabletButton } from '@campfire/tablet-button/lib';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { FieldArrayRenderProps, Form, Formik, useFormikContext } from 'formik';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { object as YupObject, string as YupString } from 'yup';
import { TaskFormItem_VOLUNTEER_TaskItemContentType as TaskItemContentType } from '../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';
import { FileTypeEnumType } from '../../../../../../../__generated__/globalTypes';
import { FormikTaskBuilderValues } from '../../admin-console-volunteer-profile-model';
import { TaskItemContentAttachment } from '../../__generated__/TaskItemContentAttachment';

const validationSchema = YupObject().shape({
  content: YupString().required('Please provide some content'),
});

interface Props {
  open: boolean;
  close: () => void;
  arrayHelpers: FieldArrayRenderProps;
  data: TaskItemContentType | null;
}

export type ContentValues = {
  content: string;
  order: number;
  attachments: TaskItemContentAttachment[];
  taskItemId: string;
};

export const ContentTaskItemDialog = memo((props: Props) => {
  const { open, close, data } = props;
  const formikContext = useFormikContext<FormikTaskBuilderValues>();
  const { values } = formikContext;
  const { taskItems } = values;

  const editorContent = useMemo(() => (data?.content ? convertFromRaw(JSON.parse(data.content)) : undefined), [data]);
  const editorContentCreator = useMemo(
    () => (editorContent ? EditorState.createWithContent(editorContent) : EditorState.createEmpty()),
    [editorContent]
  );
  const [editorState, setEditorState] = useState(editorContentCreator);

  useEffect(() => {
    setEditorState(editorContentCreator);
  }, [open]);
  const hasRichContent = useMemo(() => editorState.getCurrentContent().hasText(), [editorState]);

  const title = useMemo(() => (data ? 'Edit Content' : 'Add Content'), [data]);

  const initialValues: ContentValues = useMemo(() => {
    return {
      taskItemId: data?.taskItemId ?? `new-${uuidv4()}`,
      content: data?.content ?? '',
      order: data?.order ?? formikContext.values.taskItems.allIds.length,
      attachments: data?.attachments ?? [],
      __typename: 'VOLUNTEER_TaskItemContentType',
    };
  }, [data, open]);

  const handleSubmit = (vals: ContentValues) => {
    return data ? editContent(vals) : addContent(vals);
  };

  const uploadedFiles: Array<UploadedFile> | undefined = useMemo(() => {
    const pulledAttachments = (data?.attachments ?? []).map((attachment: any) => ({
      url: attachment.objectUrl || attachment.url,
      fileId: attachment.attachmentId,
      name: attachment.name,
    }));
    return pulledAttachments;
  }, [data?.attachments]);
  const addContent = (vals: ContentValues) => {
    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      allIds: taskItems.allIds.concat(vals.taskItemId),
      byId: {
        ...taskItems.byId,
        [vals.taskItemId]: vals,
      },
    });
    close();
  };

  const editContent = (vals: ContentValues) => {
    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      byId: {
        ...taskItems.byId,
        [vals.taskItemId]: vals,
      },
    });
    close();
  };

  const removeContent = () => {
    const optionId = taskItems.byTriggerOption[initialValues.taskItemId];
    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      allIds: taskItems.allIds.filter((itemId) => itemId !== initialValues.taskItemId),
      byTrigger: optionId
        ? taskItems.byTrigger[optionId].triggerItemIds.filter((itemId) => itemId !== initialValues.taskItemId)
        : taskItems.byTrigger,
    });
    close();
  };

  return (
    <Dialog open={open} keepMounted={false}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, setFieldValue }) => (
          <Form>
            <DialogTitle>
              <Typography variant='h6'>{title}</Typography>
              <Typography variant='body2'>{'Use content to break your task up into smaller sections'}</Typography>
            </DialogTitle>
            <DialogContent>
              <CampfireRichEditor
                placeholder='Write down your text here'
                editorState={editorState}
                setEditorState={(eState) => {
                  setEditorState(eState);
                  setFieldValue('content', JSON.stringify(convertToRaw(eState.getCurrentContent())));
                }}
              />
              <Box pt={2}>
                <Typography variant='body2' color='textSecondary' style={{ fontWeight: 'bold' }}>
                  {'Attachments'}
                </Typography>
                <FileUploadGallery
                  data-track='resources-create-activity-attachments-area'
                  allowMultiple
                  uploadedFiles={uploadedFiles ?? []}
                  handleFilesAdded={(files: FugFile[]) => {
                    Promise.all(
                      files.map((file) => {
                        return new Promise((resolve, reject) => {
                          const reader = new FileReader();
                          reader.readAsDataURL(file);
                          reader.onload = () => {
                            const { result } = reader;
                            if (result) {
                              resolve(result.toString().substr(result.toString().indexOf(',') + 1));
                            } else {
                              resolve('');
                            }
                          };
                          reader.onerror = (error) => reject(error);
                        });
                      })
                    ).then((results: any[]) => {
                      setFieldValue(
                        'attachments',
                        (data?.attachments || []).concat(
                          results.map((result, resultIndex) => ({
                            __typename: 'VOLUNTEER_TaskItemContentAttachmentType',
                            attachmentId: `new-${uuidv4()}`,
                            name: files[resultIndex].name,
                            fileType: files[resultIndex].type as FileTypeEnumType,
                            url: result as string,
                            objectUrl: files[resultIndex].objectUrl,
                          }))
                        )
                      );
                    });
                  }}
                  handleFileRemoved={(removedFile: FugFile) => {
                    setFieldValue(
                      'attachments',
                      (data?.attachments || []).filter((attachment) => attachment.attachmentId !== removedFile.id)
                    );
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Grid container justify='flex-end'>
                {data ? (
                  <Grid item xs>
                    <TabletButton variant='outlined' color='error' onClick={removeContent}>
                      {'Delete'}
                    </TabletButton>
                  </Grid>
                ) : null}
                <Grid item>
                  <TabletButton onClick={close}>{'Cancel'}</TabletButton>
                </Grid>
                <Grid item>
                  <TabletButton
                    type='button'
                    color='primary'
                    variant='contained'
                    disabled={!hasRichContent}
                    onClick={submitForm}
                  >
                    {'Done'}
                  </TabletButton>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
});
