import { Box, ButtonBase, Collapse, Grid, ListSubheader, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { useProgramManagementContext } from '../ProgramManagementContext';
import { SideNavHeader } from './ProgramManagementSideNavHeader';
import { ProgramsList } from './ProgramsList';

const SIDE_NAV_WIDTH = 320;

interface Props {
  pagePadding?: number;
}

const ProgramManagementSideNav = (props: Props) => {
  const { pagePadding } = props;
  const { programs, compact } = useProgramManagementContext();

  const [searchInput, setSearchInput] = useState<string>();
  const [showActivePrograms, setShowActivePrograms] = useState(true);
  const [showSuspendedPrograms, setShowSuspendedPrograms] = useState(false);

  const activePrograms = useMemo(
    () =>
      programs.filter(
        (program) =>
          program.dateSuspended === null &&
          (searchInput ? program.name.toLowerCase().includes(searchInput.toLowerCase()) : true)
      ),
    [programs, searchInput]
  );

  const suspendedPrograms = useMemo(
    () =>
      programs.filter(
        (program) =>
          program.dateSuspended !== null &&
          (searchInput ? program.name.toLowerCase().includes(searchInput.toLowerCase()) : true)
      ),
    [programs, searchInput]
  );

  return (
    <Grid
      item
      container
      style={{
        overflow: 'hidden auto',
        display: 'flex',
        minWidth: pagePadding ? SIDE_NAV_WIDTH - 2 * pagePadding : SIDE_NAV_WIDTH,
        maxWidth: compact ? 'none' : SIDE_NAV_WIDTH,
        flexDirection: 'column',
      }}
    >
      <SideNavHeader setSearchInput={setSearchInput} />

      <Grid item style={{ display: 'flex', flex: 1, width: '100%', overflow: 'hidden auto' }}>
        <Box style={{ width: '100%', paddingBottom: '16px' }}>
          <Grid container direction='column' spacing={1} style={{ width: '100%', margin: 0 }}>
            <ButtonBase
              data-track='fs-prgMan-active-programs-expand-collapse'
              onClick={() => setShowActivePrograms(!showActivePrograms)}
              style={{ height: 50 }}
            >
              <Grid
                item
                container
                alignItems='center'
                justify='space-between'
                spacing={1}
                xs={12}
                style={{ paddingLeft: 8, paddingRight: 8 }}
              >
                <ListSubheader>Active Programs</ListSubheader>
                {showActivePrograms ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </Grid>
            </ButtonBase>
            {activePrograms.length >= 1 ? (
              <Grid item xs={12} style={{ padding: 0 }}>
                <Collapse in={showActivePrograms}>
                  <ProgramsList programs={activePrograms} />
                </Collapse>
              </Grid>
            ) : activePrograms.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  boxSizing: 'border-box',
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
              >
                <Collapse in={showActivePrograms}>
                  <Typography
                    align='center'
                    variant='body2'
                    color='textSecondary'
                  >{`0 active programs. Hit 'Create program' to add a new program.`}</Typography>
                </Collapse>
              </Grid>
            ) : null}
            <ButtonBase
              data-track='fs-prgMan-suspended-programs-expand-collapse'
              onClick={() => setShowSuspendedPrograms(!showSuspendedPrograms)}
              style={{ height: 50 }}
            >
              <Grid
                item
                container
                alignItems='center'
                justify='space-between'
                spacing={1}
                xs={12}
                style={{ paddingLeft: 8, paddingRight: 8 }}
              >
                <ListSubheader>Suspended programs</ListSubheader>
                {showSuspendedPrograms ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </Grid>
            </ButtonBase>
            {suspendedPrograms.length >= 1 ? (
              <Grid item xs={12} style={{ padding: 0 }}>
                <Collapse in={showSuspendedPrograms}>
                  <ProgramsList programs={suspendedPrograms} />
                </Collapse>
              </Grid>
            ) : suspendedPrograms.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  boxSizing: 'border-box',
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
              >
                <Collapse in={showSuspendedPrograms}>
                  <Typography align='center' variant='body2' color='textSecondary'>
                    0 suspended programs
                  </Typography>
                </Collapse>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export { ProgramManagementSideNav };
