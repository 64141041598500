import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ArrayParam, useQueryParam } from 'use-query-params';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useFilterStyles } from '../../../../../common/filter-fields/FilterClasses';
import {
  dayFilters,
  DAY_FILTER_URL_PARAM_KEY,
  filterHelperTexts,
  Filters,
} from '../../../../../common/filter-fields/filters';

type DayFilterProps = {
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  isManagementView?: boolean;
};

const DayFilter = ({ setSelectedFilters, isManagementView }: DayFilterProps) => {
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const isDaySelected = (value: string) => (urlParam ? urlParam.indexOf(value) > -1 : false);
  const [urlParam, setUrlParam] = useQueryParam<Array<string> | undefined>(DAY_FILTER_URL_PARAM_KEY, ArrayParam);

  const handleChangeDays = (value: Array<string>) => {
    setSelectedFilters((prev) => ({
      ...prev,
      selectedDayFilters: {
        isActive: value.length > 0,
        urlParamKey: DAY_FILTER_URL_PARAM_KEY,
        value,
      },
    }));
  };

  useEffect(() => {
    if (!urlParam) {
      handleChangeDays([]);
      return;
    }
    handleChangeDays(urlParam);
  }, [urlParam]);

  const handleOnClick = (value: string) => {
    if (!urlParam) {
      setUrlParam([value]);
      return;
    }
    setUrlParam(isDaySelected(value) ? urlParam.filter((dayFilter) => dayFilter !== value) : [...urlParam, value]);
  };

  return (
    <Box py={2}>
      <Typography color='textSecondary' variant='body1' gutterBottom>
        {isManagementView
          ? filterHelperTexts.management.dayFiltersHelperText
          : filterHelperTexts.volunteer.dayFiltersHelperText}
      </Typography>
      <Box display='flex' alignItems='center' py={0.5}>
        {dayFilters.map((dayFilter) => (
          <Box
            data-track={`actCnlMapFilters-DayFilter-${dayFilter.value}`}
            key={dayFilter.value}
            className={classes.roundBox}
            boxShadow={0.5}
            style={{
              background: isDaySelected(dayFilter.value) ? '#0288d1' : theme.palette.grey[200],
              color: isDaySelected(dayFilter.value) ? 'white' : 'inherit',
            }}
            onClick={() => handleOnClick(dayFilter.value)}
          >
            <Typography variant='body2' style={{ fontWeight: 600 }}>
              {dayFilter.label.charAt(0)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export { DayFilter };
