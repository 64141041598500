import { gql } from '@apollo/client';
import { VolunteerEnrolmnentFragments } from './volunteerEnrolmentFragments.gql';

export const GET_VOLUNTEER_ENROLMENTS = gql`
  query GetVolunteerEnrolments($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        activityEnrolments {
          ...VolunteerEnrolments
        }
      }
    }
  }
  ${VolunteerEnrolmnentFragments}
`;

export const GET_VOLUNTEER_ENROLMENTS_COUNT = gql`
  query GetVolunteerEnrolmentsCount($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        activityEnrolments(pagination: { limit: 1 }) {
          activityEnrolmentId
        }
      }
    }
  }
`;
