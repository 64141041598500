import React from 'react';
import { encodeDate } from '@campfire/hot-date';
import { goBackBySlashes } from '@campfire/world-wide-web';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { useHistory, useParams, useRouteMatch } from 'react-router';

import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useCommonStyles } from './commonStyles';
import { APP_OPTIONS } from './AdminConsoleApps';
import { AppSetting } from './AppSetting/AppSetting';
import { App, AppIdType, ParsedAppType } from './AppTypes';

const mockAppInfo = {
  mailchimp: {
    accountDescription: 'orgemail@org.com.au',
    lastUpdated: encodeDate(DateTime.local().minus({ minutes: 10 })),
    authorisation:
      '<p>Volaby is authorised, on behalf of your Mailchimp account, to:</p><ul><li>View the name, email, and associated data of your contacts</li><li>View information about your account</li><li> Add, update and remove contacts</li><li> Add, update and remove data associated with contacts</li></ul><p>Volaby will not edit data associated with contacts that are not volunteers within your organisation&apos;s Volaby account</p>',
  },
  salesforce: {
    accountDescription: 'orgemail@org.com.au',
    lastUpdated: encodeDate(DateTime.local().minus({ minutes: 10 })),
    authorisation:
      '<div><p>Volaby is authorised, on behalf of your Salesforce account, to:</p><ul><li>View the name, email, and associated data of your contacts</li><li>View information about your account</li><li> Add, update and remove contacts</li><li> Add, update and remove data associated with contacts</li></ul><p>Volaby will not edit data associated with contacts that are not volunteers within your organisation&apos;s Volaby account</p></div>',
  },
  campaignMonitor: {
    accountDescription: 'API Key: 567886447bdhs',
    lastUpdated: encodeDate(DateTime.local().minus({ minutes: 10 })),
    authorisation:
      '<div><p>Volaby is authorised, on behalf of your Campaign Monitor account, to:</p><ul><li>View the name, email, and associated data of your contacts</li><li>View information about your account</li><li> Add, update and remove contacts</li><li> Add, update and remove data associated with contacts</li></ul><p>Volaby will not edit data associated with contacts that are not volunteers within your organisation&apos;s Volaby account</p></div>',
  },
};

export const AdminConsoleAppDetail = ({ app }: { app: string }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { theme } = useCampfireTheme();
  const commonStyles = useCommonStyles();

  const selectedApp = APP_OPTIONS.find((option) => option.id === app) || {};

  const app1 = mockAppInfo[app as AppIdType] || {};
  const appInfo: ParsedAppType = { ...app1, ...(selectedApp as App) };

  return (
    <Box className={commonStyles.container}>
      <ButtonBase disableRipple onClick={() => goBackBySlashes(history, match)}>
        <Box display='flex' alignItems='center' style={{ marginBottom: '1rem' }}>
          <ChevronLeft style={{ color: theme.palette.text.secondary, width: '1rem' }} />
          <Typography style={{ fontSize: '14px', lineHeight: '24px' }} color='textSecondary'>
            Back
          </Typography>
        </Box>
      </ButtonBase>

      <AppSetting
        id={appInfo.id}
        accountDescription={appInfo.accountDescription}
        lastUpdated={appInfo.lastUpdated}
        name={appInfo.name}
        authorisation={appInfo.authorisation}
      />
    </Box>
  );
};

export const AdminConsoleApp = () => {
  const { app } = useParams<{ app: string }>();
  if (!app) return null;
  return <AdminConsoleAppDetail app={app} />;
};
