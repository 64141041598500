import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { HoverText, LinkColor } from '@campfire/hover-link';

type ExpandableListProps = {
  children: any;
  expandOnly?: boolean;
  height?: number | string;
  openText?: string;
  closeText?: string;
  linkColor?: LinkColor;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    background: 'linear-gradient( to bottom, transparent 0%, #ffffff 50%)',
    height: '50px',
    marginTop: (isExpanded: boolean) => (isExpanded ? '0px' : '-50px'),
    position: 'relative',
    zIndex: 1,
    transition: 'all 0.3s ease-in-out',
  },
});

const createClosedStyles = (height: number | string) => ({
  height,
  overflow: 'hidden',
  transition: 'height 0.3s ease-in-out',
});

const createOpenStyles = (height: number | string) => ({
  height,
  overflow: 'auto',
  transition: 'height 0.3s ease-in-out',
});

export const ExpandableSection = (props: ExpandableListProps) => {
  const {
    children,
    expandOnly = false,
    height = 300,
    openText = 'Show all',
    closeText = 'Collapse items',
    linkColor = 'primary',
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [itemHeight, setItemHeight] = useState<any>();
  const ref = useRef<HTMLInputElement>(null);

  const expandSection = () => setIsExpanded(true);
  const closeSection = () => setIsExpanded(false);

  useLayoutEffect(() => {
    setItemHeight(ref?.current?.clientHeight);
    closeSection();
  }, []);

  useEffect(() => {
    if (itemHeight === 0 || (itemHeight && itemHeight < height)) {
      setIsExpanded(true);
    }
  }, [itemHeight]);

  const closedStyles = createClosedStyles(height);

  // In case the itemHeight is incorrectly calculated from the ref let's fallback to 'auto'.
  const openStyles = createOpenStyles(itemHeight || 'auto');
  const expandTextClasses = useStyles(isExpanded);

  return (
    <>
      <div ref={ref} style={isExpanded ? openStyles : closedStyles}>
        {children}
      </div>
      {!isExpanded && (
        <div className={expandTextClasses.root}>
          <HoverText variant='body2' color={linkColor} onClick={expandSection}>
            {openText}
          </HoverText>
        </div>
      )}
      {isExpanded && !expandOnly && (
        <div className={expandTextClasses.root}>
          <HoverText variant='body2' color={linkColor} onClick={closeSection}>
            {closeText}
          </HoverText>
        </div>
      )}
    </>
  );
};
