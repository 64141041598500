import { gql } from '@apollo/client';

export const GET_IMPACT_DASHBOARD_DETAILED_VIEW = gql`
  query GetImpactDashboardDetailedView($startDate: Date!, $endDate: Date!) {
    orgName
    vm {
      programs {
        programId
        name
        dateSuspended
        activities(includeInactiveActivities: true) {
          activityId
          name
          endDate
          isActive
          isSuspended
          closedActivity {
            closedActivityId
          }
          program {
            programId
          }
          sessions {
            sessionId
            reportType {
              reportTypeId
            }
          }
          publishedRosters {
            rosterId
            activityDate
            rosterings {
              publishedRosteringId
              volunteer {
                volunteerId
                profile {
                  preferredName
                  lastName
                }
              }
            }
          }
          activityReports(startDate: $startDate, endDate: $endDate) {
            activityReportId
            activityDate
            sessionReports {
              sessionReportId
              session {
                sessionId
                reportType {
                  reportTypeId
                  name
                }
                autoReport
              }
              startTime
              endTime
              allowCICO
              CICOs {
                volunteer {
                  profile {
                    preferredName
                    lastName
                  }
                }
              }
              minCICOCheckIn
              maxCICOCheckOut
              submissionDate
              attendances {
                ... on VOLUNTEER_VolunteerAttendanceType {
                  attendanceId
                  volunteer {
                    volunteerId
                    profile {
                      preferredName
                      lastName
                    }
                  }
                }
                ... on VOLUNTEER_OtherAttendanceType {
                  name
                }
              }
              submittedBy {
                volunteerId
                profile {
                  preferredName
                  lastName
                }
              }
              fieldValues {
                fieldValueId
                field {
                  fieldId
                  name
                }
                ... on VOLUNTEER_CheckboxFieldValueType {
                  checked
                }
                ... on VOLUNTEER_DropdownFieldValueType {
                  dropdownFieldOptions {
                    dropdownFieldOptionId
                    name
                    order
                  }
                }
                ... on VOLUNTEER_RatingFieldValueType {
                  ratingValue
                }
                ... on VOLUNTEER_BooleanFieldValueType {
                  boolValue
                }
                ... on VOLUNTEER_TimeFieldValueType {
                  timeValue
                }
                ... on VOLUNTEER_TextFieldValueType {
                  textValue
                }
                ... on VOLUNTEER_NumericFieldValueType {
                  numericValue
                }
              }
              comments
            }
          }
        }
      }
      reportTypes {
        ...ImpactDashboardDetailedViewReportType
      }
    }
  }

  fragment ImpactDashboardDetailedViewReportType on VOLUNTEER_ReportTypeType {
    reportTypeId
    name
    items {
      ... on VOLUNTEER_ReportTypeFieldType {
        ...ImpactDashboardDetailedViewReportTypeField
      }
    }
  }

  fragment ImpactDashboardDetailedViewReportTypeField on VOLUNTEER_ReportTypeFieldType {
    order
    field {
      ...ImpactDashboardDetailedViewReportTypeFieldField
    }
  }

  fragment ImpactDashboardDetailedViewReportTypeFieldField on VOLUNTEER_FieldType {
    __typename
    fieldId
    name
  }
`;
