import { Box } from '@material-ui/core';
import { stringify } from 'query-string';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useVolDashboardMyElementsContext } from '../VolDashboardMyElementsContext';
import { MobileMyElementsNewsDialog } from './MobileMyElementsNewsDialog';
import { SideBarRecentNewsSection } from './recent-news/SideBarRecentNewsSection';
import { SideBarYourImpactSection } from './your-impact/SideBarYourImpactSection';

export const MyElementsBottomBar = () => {
  const { isXs, theme } = useCampfireTheme();
  const [showNewsDialog, setShowNewsDialog] = useState<boolean>(false);
  const history = useHistory();
  const match = useRouteMatch();
  const [query] = useQueryParams({
    activityStatus: ArrayParam,
    days: ArrayParam,
    startTime: StringParam,
    endTime: StringParam,
    startDate: StringParam,
    endDate: StringParam,
  });
  const { setSelectedNewsId } = useVolDashboardMyElementsContext();

  const handleViewAllClickSetDialog = () => {
    setShowNewsDialog(true);
  };
  const handleViewAllClickHistoryPush = () => {
    history.replace(`${match.path}/?${stringify(query)}&tab=news`);
  };

  return (
    <>
      <Box
        width='100%'
        height='100%'
        display='flex'
        flexDirection={isXs ? 'column' : 'row'}
        bgcolor={theme.color.grey.background}
      >
        <Box flexGrow={1} width={isXs ? 'auto' : '100%'} paddingX='24px'>
          <SideBarRecentNewsSection
            onViewAllClick={isXs ? handleViewAllClickSetDialog : handleViewAllClickHistoryPush}
            onSingleNewsClick={() =>
              isXs ? setShowNewsDialog(true) : history.replace(`${match.path}/?${stringify(query)}&tab=news`)
            }
          />
        </Box>
        <Box flexGrow={1} width={isXs ? 'auto' : '100%'} paddingX='24px'>
          <SideBarYourImpactSection />
        </Box>
      </Box>
      {showNewsDialog && (
        <MobileMyElementsNewsDialog
          open={showNewsDialog}
          handleClose={() => {
            setShowNewsDialog(false);
            setSelectedNewsId(undefined);
          }}
        />
      )}
    </>
  );
};
