import React from 'react';
import { Box } from '@material-ui/core';
import { get, isString, lowerCase, upperFirst } from 'lodash';
import { ChartNumberWidget as IChartNumberWidget } from '../../dashboard-widget-setting/__generated__/ChartNumberWidget';
import { DimensionEnum, valueMap } from '../../dashboard-widget-setting/dashboard-widget-model';
import { WidgetContainer, WidgetContent } from '../WidgetContainer';
import { WidgetHeader } from '../WidgetHeader';
import { ChartType } from '../chart-widget/Chart';
import { DashboardLineChart } from '../chart-widget/LineChart';
import { DashboardPieChart } from '../chart-widget/PieChart';
import { DashboardBarChart } from '../chart-widget/BarChart';
import { DashboardAreaChart } from '../chart-widget/AreaChart';
import { DashboardColumnChart } from '../chart-widget/ColumnChart';
import { Highlight } from './Highlight';

type ChartNumberWidgetProps = {
  widget: IChartNumberWidget;
};

export function ChartNumberWidget({ widget }: ChartNumberWidgetProps) {
  const { targets } = widget;

  const { chartConfiguration, numberConfiguration } = widget;

  const { range, interval, dimension } = chartConfiguration;

  const title = React.useMemo(() => {
    const parsedTargets = JSON.parse(targets);
    return parsedTargets
      .map(({ tag, name }: { tag: string; name: string }) =>
        get(valueMap, [tag.toLowerCase(), name.toLowerCase(), 'displayName'], '')
      )
      .join(' & ');
  }, [targets]);

  const subTitle = React.useMemo(() => {
    const rangeString = upperFirst(lowerCase(range));
    const intervalString = isString(interval) ? `, ${lowerCase(interval)}` : '';
    const groupString =
      dimension === DimensionEnum.BY_TIME
        ? ''
        : `, per ${lowerCase(dimension)
            .split(' ')
            .slice(1)
            .map(upperFirst)
            .join()}`;
    return rangeString + intervalString + groupString;
  }, [range, dimension, interval]);

  return (
    <WidgetContainer>
      <WidgetHeader
        title={title}
        subTitle={subTitle}
        type={`Chart > ${upperFirst(lowerCase(chartConfiguration.chartType))}`}
      />
      <WidgetContent>
        <Box display='flex' maxWidth='100%' alignItems='stretch' height='100%' width='100%'>
          <Highlight
            widget={{ ...widget, configuration: numberConfiguration, __typename: 'VOLUNTEER_NumberWidgetType' }}
          />
          <Box flexBasis='80%' height='100%' flex={1}>
            {(() => {
              switch (chartConfiguration.chartType) {
                case ChartType.LINE:
                  return (
                    <DashboardLineChart
                      widget={{ ...widget, configuration: chartConfiguration, __typename: 'VOLUNTEER_ChartWidgetType' }}
                    />
                  );
                case ChartType.PIE:
                  return (
                    <DashboardPieChart
                      widget={{ ...widget, configuration: chartConfiguration, __typename: 'VOLUNTEER_ChartWidgetType' }}
                    />
                  );
                case ChartType.BAR:
                  return (
                    <DashboardBarChart
                      widget={{ ...widget, configuration: chartConfiguration, __typename: 'VOLUNTEER_ChartWidgetType' }}
                    />
                  );
                case ChartType.AREA:
                  return (
                    <DashboardAreaChart
                      widget={{ ...widget, configuration: chartConfiguration, __typename: 'VOLUNTEER_ChartWidgetType' }}
                    />
                  );
                // case ChartType.SCATTER:
                // return <DashboardScatterChart widget={widget} />;
                case ChartType.COLUMN:
                  return (
                    <DashboardColumnChart
                      widget={{ ...widget, configuration: chartConfiguration, __typename: 'VOLUNTEER_ChartWidgetType' }}
                    />
                  );
                default:
                  break;
              }
              return null;
            })()}
          </Box>
        </Box>
      </WidgetContent>
    </WidgetContainer>
  );
}
