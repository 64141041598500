import React from 'react';
import { FormGroup, Box } from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import { VolunteerItem, SelectableVolunteerRole } from './VolunteerItem';
import { FullscreenDialog } from '../../../../../../common/dialogs/FullscreenDialog';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { SearchField } from '../../../../../../common/inputs/SearchField';

interface Props {
  volunteers: SelectableVolunteerRole[];
  onChange: (roleId: string) => void;
  title: string;
  onClose: () => void;
}

const Row = ({ index, style, data }: any) => {
  return <VolunteerItem volunteer={data.volunteers[index]} onChange={data.onChange} style={style} />;
};

const sortVolunteers = (a: SelectableVolunteerRole, b: SelectableVolunteerRole): number => {
  if (a.checked && b.checked) return a.profile.preferredName.localeCompare(b.profile.preferredName);
  if (a.checked) return -1;
  if (b.checked) return 1;
  return a.profile.preferredName.localeCompare(b.profile.preferredName);
};

export function VolunteersList({ volunteers, onChange, onClose, title }: Props) {
  const { isMobile } = useCampfireTheme();
  const [search, setSearch] = React.useState('');

  const showingVoluntees = volunteers
    .sort(sortVolunteers)
    .filter(
      (volunteer) =>
        volunteer.profile.lastName.toLowerCase().includes(search.toLowerCase()) ||
        volunteer.profile.preferredName.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <FullscreenDialog open close={onClose} title={title} fullScreen={isMobile}>
      <Box paddingTop='1rem' paddingBottom='1rem'>
        <SearchField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Type for search volunteer'
          fullWidth
        />
      </Box>
      <FormGroup>
        <List
          height={300}
          itemCount={showingVoluntees.length}
          itemSize={51}
          width={500}
          itemData={{ volunteers: showingVoluntees, onChange }}
          style={{
            overflowX: 'hidden',
          }}
        >
          {Row}
        </List>
      </FormGroup>
    </FullscreenDialog>
  );
}
