import React, { useMemo, useState, useEffect, memo, ReactElement } from 'react';
import { List } from '@material-ui/core';
import { unpackToDate } from '@campfire/hot-date';
import { SupportResource } from '../__generated__/SupportResource';

interface ResourcesListProps {
  resources: SupportResource[];
  truncate?: boolean;
  children: (resources: SupportResource[]) => ReactElement[];
}

const ResourcesList = memo((props: ResourcesListProps) => {
  const { resources, children, truncate } = props;

  const [nonFeaturedResources, setNonFeaturedResources] = useState<SupportResource[]>([]);
  const [featuredResources, setFeaturedResources] = useState<SupportResource[]>([]);

  useEffect(() => {
    setFeaturedResources([]);
    setNonFeaturedResources([]);
    resources.forEach((resource) => {
      if (resource.featured) setFeaturedResources((curr) => [...curr, resource]);
      if (!resource.featured) setNonFeaturedResources((curr) => [...curr, resource]);
    });
  }, [resources]);

  const sortedNonFeaturedResources = useMemo(
    () => nonFeaturedResources.sort((a, b) => (unpackToDate(a.dateAdded) < unpackToDate(b.dateAdded) ? 1 : -1)),
    [nonFeaturedResources]
  );

  const truncatedNonFeaturedResourcesList = useMemo(
    () =>
      truncate
        ? sortedNonFeaturedResources.slice(0, featuredResources.length < 5 ? 5 - featuredResources.length : 0)
        : sortedNonFeaturedResources,
    [sortedNonFeaturedResources, truncate]
  );

  const resourcesList = useMemo(() => featuredResources.concat(truncatedNonFeaturedResourcesList), [
    truncatedNonFeaturedResourcesList,
    featuredResources,
  ]);

  return <List>{children(resourcesList)}</List>;
});

export { ResourcesList };
