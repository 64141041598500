import React, { useMemo, useState } from 'react';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, Button, ListItem, Typography, IconButton } from '@material-ui/core';
import { AddCircle, KeyboardArrowDown, KeyboardArrowUp, Cancel } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { VolunteerProfile } from '../../../../common/AvatarName';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { WaitlistedVolunteersSkeleton } from '../waitlisted-volunteers/WaitlistedVolunteersSkeleton';
import { GetActivitiesConsoleNewVolunteers_vm_activity_activeActivityApplications as IApplicationType } from './__generated__/GetActivitiesConsoleNewVolunteers';

const useStyles = makeStyles(() =>
  createStyles({
    itemContainer: {
      '& .itemAction': {
        display: 'none',
      },
      '&:hover': {
        '& .itemAction': {
          display: 'flex',
        },
      },
    },
  })
);
interface Props {
  activityId?: string;
  programId: string;
  searchTerm: string;
  applications: IApplicationType[];
  onRemove: (applicationId: string) => void;
  onAdd: (applicationId: string) => void;
  isLoading?: boolean;
}

interface TeamMemberItemProps {
  teamMember: IApplicationType;
}

const TeamMemberItem = (props: TeamMemberItemProps) => {
  const { teamMember } = props;
  const { profile } = teamMember.volunteer;
  const displayName = `${profile.preferredName} ${profile.lastName}`;

  return (
    <Box style={{ display: 'inline-block' }}>
      <Box
        component='li'
        key={teamMember.activityApplicationId}
        display='flex'
        style={{
          marginTop: 8,
          marginBottom: 8,
        }}
        flex={1}
      >
        <VolunteerProfile
          avatarUrl={profile.avatarUrl}
          name={displayName}
          profileId={profile.profileId}
          userId={profile.userId}
          lastName={profile.lastName}
          preferredName={profile.preferredName}
          noTrim
        />
      </Box>
    </Box>
  );
};

export const NewVolunteersDetail = (props: Props) => {
  const { theme } = useCampfireTheme();
  const { searchTerm, applications, onAdd, onRemove, isLoading } = props;
  const [showMore, setShowMore] = useState<boolean>(false);

  const filteredApplications = applications.filter((application) => {
    const fullName = `${application.volunteer.profile.preferredName}${application.volunteer.profile.lastName}`;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const displayingApplications = useMemo(() => {
    if (showMore) {
      return filteredApplications;
    }

    return filteredApplications.slice(0, 3);
  }, [filteredApplications, showMore]);

  const classes = useStyles();

  return (
    <Box>
      {isLoading
        ? Array.from(Array(5)).map(() => (
            <ListItem>
              <WaitlistedVolunteersSkeleton />
            </ListItem>
          ))
        : displayingApplications.map((application) => (
            <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.itemContainer}>
              <TeamMemberItem teamMember={application} />
              <Box display='flex' className='itemAction'>
                <TitularTooltip title='Remove'>
                  <IconButton onClick={() => onRemove(application.activityApplicationId)}>
                    <Cancel style={{ marginTop: '2px', color: theme.color.error[900] }} />
                  </IconButton>
                </TitularTooltip>
                <TitularTooltip title='Add to team'>
                  <IconButton onClick={() => onAdd(application.activityApplicationId)}>
                    <AddCircle style={{ marginTop: '2px', color: theme.color.secondary.main700 }} />
                  </IconButton>
                </TitularTooltip>
              </Box>
            </Box>
          ))}

      <Box style={{ width: '100%', paddingTop: '12px' }}>
        {displayingApplications.length > 3 ? (
          <Button
            variant='text'
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '8px 12px',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '8px',
              display: 'block',
            }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <Box display='flex'>
                <Typography
                  style={{
                    color: theme.color.grey.neutral400,
                    fontSize: '14px',
                    textTransform: 'none',
                    marginRight: '4px',
                  }}
                >
                  Show Less
                </Typography>
                <KeyboardArrowUp style={{ fontSize: '20px', color: theme.color.grey.neutral400 }} />
              </Box>
            ) : (
              <Box display='flex'>
                <Typography
                  style={{
                    color: theme.color.grey.neutral400,
                    fontSize: '14px',
                    textTransform: 'none',
                    marginRight: '4px',
                  }}
                >
                  Show More
                </Typography>
                <KeyboardArrowDown style={{ fontSize: '20px', color: theme.color.grey.neutral400 }} />
              </Box>
            )}
          </Button>
        ) : (
          ''
        )}
      </Box>
      {!applications.length && !isLoading ? (
        <Box textAlign='center' fontSize='13px' paddingTop='10px' color={theme.color.grey.neutral400}>
          <Typography style={{ fontSize: '13px' }}>There are no new volunteers for this activity</Typography>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};
