import React from 'react';
import { Box } from '@material-ui/core';

import { DateSelect } from './DateSelect';
import { FrequencySelect, FrequencyValueType } from './FrequencySelect';
import { AvailabilityCenterActivityType_VOLUNTEER_RecurringActivityType_schedule_recurrences as ActivityRecurrenceType } from '../__generated__/AvailabilityCenterActivityType';
import {
  BeginOptionsEnum,
  DAILY_BEGIN_OPTIONS,
  DAILY_OPTIONS,
  DAY_OPTIONS,
  EVERY_WEEKEND_OPTIONS,
  FREQUENCY_ENUM,
  LengthOptionEnum,
  LENGTH_OPTIONS,
  MONTHLY_BEGIN_OPTIONS,
  MONTHLY_OPTIONS,
  WEEKDAYS,
  WEEKENDS,
  WEEKLY_BEGIN_OPTIONS,
  WEEKLY_OPTIONS,
  YEARLY_BEGIN_OPTIONS,
  YEARLY_LENGTH_OPTIONS,
  YEARLY_OPTIONS,
} from './consts';
import { RecurrenceEnum } from '../../../__generated__/globalTypes';
import { filterByBegin, filterByDay, filterByFrequence, filterInRange } from './helper';
import { Legend } from './Legend';
import { useDeepEffect } from '../../../hooks/useDeepEffect';

interface Props {
  startDate: string;
  value: string[];
  onChange: (newValue: string[]) => void;
  recurrences: ActivityRecurrenceType[];
  occurrences: string[];
  availabilities: string[];
  unavailabilities: string[];
}

export function DateAndFrequencySelect({
  startDate,
  value,
  onChange,
  recurrences,
  occurrences,
  availabilities,
  unavailabilities,
}: Props) {
  const { __typename: recurrenceType } = recurrences[0];

  const isWeekDay = React.useMemo(() => {
    const recurrence = recurrences[0];
    if (recurrence.__typename !== 'WeeklyRecurrenceType') {
      return false;
    }

    return recurrence.days.length === WEEKDAYS.length && recurrence.days.every((day) => WEEKDAYS.includes(day));
  }, [recurrences]);

  const isWeekend = React.useMemo(() => {
    const recurrence = recurrences[0];
    if (recurrence.__typename !== 'WeeklyRecurrenceType') {
      return false;
    }

    return recurrence.days.length === WEEKENDS.length && recurrence.days.every((day) => WEEKDAYS.includes(day));
  }, [recurrences]);

  const frequencyOptions = React.useMemo(() => {
    if (recurrenceType === 'DailyRecurrenceType') {
      return DAILY_OPTIONS;
    }

    if (recurrenceType === 'WeeklyRecurrenceType') {
      if (isWeekend) {
        return EVERY_WEEKEND_OPTIONS;
      }
      return WEEKLY_OPTIONS;
    }

    if (recurrenceType === 'YearlyRecurrenceType') {
      return YEARLY_OPTIONS;
    }

    if (recurrenceType === 'SingleRecurrenceType') {
      return YEARLY_OPTIONS;
    }

    return MONTHLY_OPTIONS;
  }, [recurrenceType, isWeekDay, isWeekend]);

  const lengthOptions = React.useMemo(() => {
    if (recurrenceType === 'YearlyRecurrenceType' || recurrenceType === 'SingleRecurrenceType') {
      return YEARLY_LENGTH_OPTIONS;
    }

    return LENGTH_OPTIONS;
  }, [recurrenceType]);

  const beginOptions = React.useMemo(() => {
    if (recurrenceType === 'YearlyRecurrenceType' || recurrenceType === 'SingleRecurrenceType') {
      return YEARLY_BEGIN_OPTIONS;
    }

    if (recurrenceType === 'DailyRecurrenceType') {
      return DAILY_BEGIN_OPTIONS;
    }

    if (recurrenceType === 'WeeklyRecurrenceType') {
      return WEEKLY_BEGIN_OPTIONS;
    }

    return MONTHLY_BEGIN_OPTIONS;
  }, [recurrenceType]);

  const [frequencyValue, setFrequencyValue] = React.useState<FREQUENCY_ENUM>(frequencyOptions[0].value);
  const [lengthValue, setLengthValue] = React.useState<LengthOptionEnum>(LengthOptionEnum.NA);
  const [dayFilterValue, setDayFilterValue] = React.useState<RecurrenceEnum[]>([]);
  const [beginFilterValue, setBeginFilterValue] = React.useState<BeginOptionsEnum>(beginOptions[0].value);

  const onChangeFrequency = (newValue: FrequencyValueType) => {
    setFrequencyValue(newValue.frequency);
    setLengthValue(newValue.length);
    setDayFilterValue(newValue.day);
    setBeginFilterValue(newValue.begin);
  };

  React.useEffect(() => {
    if (frequencyValue === FREQUENCY_ENUM.CUSTOM) {
      setLengthValue(LengthOptionEnum.NA);
    }
  }, [frequencyValue]);

  useDeepEffect(() => {
    const filteredOccurrences = occurrences.filter(filterByDay(dayFilterValue)).filter(filterByBegin(beginFilterValue));

    const preSelectedDates = filteredOccurrences
      .filter(filterByFrequence(frequencyValue))
      .filter(filterInRange(lengthValue));

    onChange(preSelectedDates);
  }, [frequencyValue, lengthValue, dayFilterValue, beginFilterValue]);

  React.useEffect(() => {
    if (value.length === 0 && occurrences.length > 0) {
      onChange(occurrences.slice(0, 1));
    }
  }, []);

  return (
    <Box>
      <FrequencySelect
        frequencyOptions={frequencyOptions}
        lengthOptions={lengthOptions}
        dayOptions={DAY_OPTIONS}
        beginOptions={beginOptions}
        value={{
          frequency: frequencyValue,
          length: lengthValue,
          day: dayFilterValue,
          begin: beginFilterValue,
        }}
        onChange={onChangeFrequency}
      />
      <Box display='flex'>
        <Box display='flex' alignItems='center' style={{ gap: '1rem' }}>
          <Legend color='#0374D5' label='Available' />
          <Legend color='#0374D533' label='Existing Availability' />
          <Legend color='#D7DDE2' label='Unavailable' />
        </Box>
      </Box>
      <Box marginTop={2} width='100%'>
        <DateSelect
          startDate={startDate}
          value={value}
          onChange={onChange}
          multiple
          occurrences={occurrences}
          availabilities={availabilities}
          unavailabilities={unavailabilities}
        />
      </Box>
    </Box>
  );
}
