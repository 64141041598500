import { unpackToTime, unpackToDate } from '@campfire/hot-date';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, TableCell, Typography, TableSortLabel, Chip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { uniqBy } from 'lodash';
import { MUIDataTableColumnDef, MUIDataTableCustomHeadRenderer } from 'mui-datatables';
import React from 'react';
import { GetImpactDashboardDetailedView } from './__generated__/GetImpactDashboardDetailedView';
import { ImpactDashboardDetailedViewReportTypeFieldField } from './__generated__/ImpactDashboardDetailedViewReportTypeFieldField';
import { getActivityActivenessStatus } from '../../activities-v2/ActivityUtils/filters';

type FieldValueType = GetImpactDashboardDetailedView['vm']['programs'][0]['activities'][0]['activityReports'][0]['sessionReports'][0]['fieldValues'][0];

const getBasicCustomHeadRender = (props: { tooltipDescription?: string; maxWidth?: number; minWidth?: number }) => (
  { label, index, sort }: MUIDataTableCustomHeadRenderer,
  sortColumn: (index: number) => any
) => {
  const { tooltipDescription, minWidth, maxWidth } = props;
  return (
    <TableCell key={index}>
      <TitularTooltip title={tooltipDescription ? label : undefined} description={tooltipDescription ?? label}>
        <TableSortLabel
          active={sort !== undefined}
          direction={sort ? 'asc' : sort === false ? 'desc' : undefined}
          onClick={() => sortColumn(index)}
          style={{ minWidth, maxWidth }}
        >
          <Typography noWrap>{label}</Typography>
        </TableSortLabel>
      </TitularTooltip>
    </TableCell>
  );
};

const useCustomBodyRenderClasses = makeStyles(() =>
  createStyles({
    textFieldBody: {
      '&:hover': {
        color: 'rgba(0,0,0,0.5)',
      },
    },
  })
);

const getTruncateCustomBodyRender = ({
  name,
  minWidth,
  maxWidth,
}: {
  name: string;
  minWidth?: number;
  maxWidth?: number;
}) => (value: string) => (
  <TitularTooltip enterDelay={175} title={name} description={value} maxWidth={360}>
    <Box minWidth={minWidth} maxWidth={maxWidth} style={{ overflowY: 'hidden', cursor: 'default' }}>
      <Typography variant='body2' noWrap>
        {value}
      </Typography>
    </Box>
  </TitularTooltip>
);

export const getChipsCustomBodyRender = () => (value: string) => (
  <Chip
    size='small'
    label={value}
    style={{
      borderRadius: 5,
      color: '#545454',
      background: '#d9d9d9',
      fontWeight: 600,
      textTransform: 'capitalize',
      minWidth: '87px',
    }}
  />
);

interface CustomBodyComponentProps {
  value: string;
  fieldName: string;
  fieldType: ImpactDashboardDetailedViewReportTypeFieldField['__typename'];
}

const CustomBodyComponent = (props: CustomBodyComponentProps) => {
  const { value, fieldName, fieldType } = props;
  const classes = useCustomBodyRenderClasses();

  if (fieldType === 'VOLUNTEER_TextFieldType' || fieldType === 'VOLUNTEER_DropdownFieldType') {
    return (
      <TitularTooltip enterDelay={175} title={fieldName} description={value} maxWidth={360}>
        <Box
          // minWidth={240}
          // maxWidth={480}
          maxHeight={64}
          style={{ overflowY: 'hidden', cursor: 'default' }}
        >
          <Typography
            className={classes.textFieldBody}
            variant='body2'
            // noWrap
          >
            {value}
          </Typography>
        </Box>
      </TitularTooltip>
    );
  }

  return <>{value}</>;
};

interface BasicBodyComponentProps {
  value: string;
  fieldName: string;
}

const BasicBodyComponent = (props: BasicBodyComponentProps) => {
  const { value, fieldName } = props;
  const classes = useCustomBodyRenderClasses();

  return (
    <TitularTooltip enterDelay={175} title={fieldName} description={value} maxWidth={360} placement='bottom-start'>
      <Box maxHeight={64} style={{ overflowY: 'hidden', cursor: 'default' }}>
        <Typography className={classes.textFieldBody} variant='body2'>
          {value}
        </Typography>
      </Box>
    </TitularTooltip>
  );
};

const getCustomBodyRender = (
  fieldName: string,
  fieldType: ImpactDashboardDetailedViewReportTypeFieldField['__typename']
) => (
  value: string
  // { columnIndex }: MUIDataTableMeta
) =>
  fieldType === 'VOLUNTEER_TextFieldType' || fieldType === 'VOLUNTEER_DropdownFieldType' ? (
    <CustomBodyComponent value={value} fieldName={fieldName} fieldType={fieldType} />
  ) : (
    <BasicBodyComponent value={value} fieldName={fieldName} />
  );

const getFieldValueCustomHeadRender = (fieldType: ImpactDashboardDetailedViewReportTypeFieldField['__typename']) => (
  { label, index, sort }: MUIDataTableCustomHeadRenderer,
  sortColumn: (index: number) => any
) => {
  const style: React.CSSProperties | undefined =
    fieldType === 'VOLUNTEER_TextFieldType' || fieldType === 'VOLUNTEER_DropdownFieldType'
      ? { width: 240 }
      : { maxWidth: 96 };

  return (
    <TableCell key={index}>
      <TitularTooltip description={label}>
        <TableSortLabel
          active={sort !== undefined}
          direction={sort ? 'asc' : sort === false ? 'desc' : undefined}
          style={style}
          onClick={() => sortColumn(index)}
        >
          <Typography noWrap>{label}</Typography>
        </TableSortLabel>
      </TitularTooltip>
    </TableCell>
  );
};

function getFieldDisplayValue(fieldValue: FieldValueType): string | number {
  if (fieldValue.__typename === 'VOLUNTEER_CheckboxFieldValueType') {
    return fieldValue.checked ? 'Yes' : 'No';
  }

  if (fieldValue.__typename === 'VOLUNTEER_DropdownFieldValueType') {
    return fieldValue.dropdownFieldOptions.map((x) => x.name).join(', ');
  }

  if (fieldValue.__typename === 'VOLUNTEER_RatingFieldValueType') {
    return `${fieldValue.ratingValue}`;
  }

  if (fieldValue.__typename === 'VOLUNTEER_BooleanFieldValueType') {
    return fieldValue.boolValue ? 'Yes' : 'No';
  }

  if (fieldValue.__typename === 'VOLUNTEER_TimeFieldValueType') {
    return unpackToTime(fieldValue.timeValue)
      .toFormat('HHmm')
      .toLowerCase();
  }

  if (fieldValue.__typename === 'VOLUNTEER_TextFieldValueType') {
    return fieldValue.textValue;
  }

  if (fieldValue.__typename === 'VOLUNTEER_NumericFieldValueType') {
    return fieldValue.numericValue;
  }

  return '';
}

const BASE_COLUMNS: Array<MUIDataTableColumnDef> = [
  {
    label: 'Report Type',
    name: 'reportTypeName',
    options: {
      filter: false,
      display: 'false',
      customHeadRender: getBasicCustomHeadRender({}),
      customBodyRender: getTruncateCustomBodyRender({ name: 'Report Type', maxWidth: 128 }),
    },
  },
  {
    label: 'Program',
    name: 'programName',
    options: {
      filter: false,
      display: 'false',
      customHeadRender: getBasicCustomHeadRender({}),
      customBodyRender: getTruncateCustomBodyRender({ name: 'Program', maxWidth: 128 }),
    },
  },
  {
    label: 'Program Status',
    name: 'programStatus',
    options: {
      filter: false,
      display: 'false',
      customHeadRender: getBasicCustomHeadRender({}),
      customBodyRender: getChipsCustomBodyRender(),
    },
  },
  {
    label: 'Activity',
    name: 'activityName',
    options: {
      filter: false,
      customHeadRender: getBasicCustomHeadRender({}),
      customBodyRender: getTruncateCustomBodyRender({ name: 'Activity', maxWidth: 280 }),
    },
  },
  {
    label: 'Activity Status',
    name: 'activityStatus',
    options: {
      filter: false,
      display: 'false',
      customHeadRender: getBasicCustomHeadRender({}),
      customBodyRender: getChipsCustomBodyRender(),
    },
  },
  {
    label: 'Activity Date',
    name: 'activityDate',
    options: {
      filterType: 'textField',
      customHeadRender: getBasicCustomHeadRender({ tooltipDescription: 'The date of the activity' }),
    },
  },
  {
    label: 'Submission Date',
    name: 'submissionDate',
    options: {
      display: 'false',
      filterType: 'textField',
      customHeadRender: getBasicCustomHeadRender({ tooltipDescription: 'The date that the report was submitted' }),
    },
  },
  {
    label: 'Submitted By',
    name: 'submittedBy',
    options: {
      display: 'false',
      filterType: 'textField',
      customHeadRender: getBasicCustomHeadRender({ tooltipDescription: 'Who the report was submitted by' }),
    },
  },
  {
    label: 'Attendees',
    name: 'attendees',
    options: {
      filterType: 'textField',
      customHeadRender: getBasicCustomHeadRender({
        tooltipDescription: 'Volunteers who were marked as attending on the report',
      }),
      customBodyRender: getTruncateCustomBodyRender({ name: 'Attendees', maxWidth: 196 }),
    },
  },
  {
    label: 'Roster',
    name: 'rosterees',
    options: {
      display: 'false',
      filterType: 'textField',
      customHeadRender: getBasicCustomHeadRender({
        tooltipDescription: 'Volunteers who were inclued on the latest published roster',
      }),
      customBodyRender: getTruncateCustomBodyRender({ name: 'Roster', maxWidth: 196 }),
    },
  },
  {
    label: 'Start Time',
    name: 'startTime',
    options: {
      filterType: 'textField',
      customHeadRender: getBasicCustomHeadRender({
        tooltipDescription: 'The reported start time (24 hour time)',
        maxWidth: 72,
      }),
      customBodyRender: getTruncateCustomBodyRender({ name: 'Start Time' }),
    },
  },
  {
    label: 'End Time',
    name: 'endTime',
    options: {
      filterType: 'textField',
      customHeadRender: getBasicCustomHeadRender({
        tooltipDescription: 'The reported end time (24 hour time)',
        maxWidth: 72,
      }),
      customBodyRender: getTruncateCustomBodyRender({ name: 'End Time' }),
    },
  },
  {
    label: 'Length',
    name: 'duration',
    options: {
      filterType: 'textField',
      customHeadRender: getBasicCustomHeadRender({ tooltipDescription: 'The duration of the report (hours:minutes)' }),
      customBodyRender: getTruncateCustomBodyRender({ name: 'Length' }),
    },
  },
  {
    label: 'Journal Entry',
    name: 'comments',
    options: {
      filterType: 'textField',
      customHeadRender: getBasicCustomHeadRender({ tooltipDescription: 'Journal entry' }),
      customBodyRender: getTruncateCustomBodyRender({ name: 'Journal Entry' }),
    },
  },
];

export function getTableData(data?: GetImpactDashboardDetailedView) {
  if (!data || !data.vm) {
    return undefined;
  }

  const { vm } = data;
  const flatActivities = vm.programs.flatMap((program) =>
    program.activities.flatMap((activity) => ({
      ...activity,
      program: {
        programId: program.programId,
        name: program.name,
        dateSuspended: program.dateSuspended,
      },
    }))
  );

  const flatSessionReports = flatActivities.flatMap((activity) =>
    activity.activityReports.flatMap((activityReport) =>
      activityReport.sessionReports.map((sessionReport) => ({
        ...sessionReport,
        activity,
        activityReport: {
          activityDate: activityReport.activityDate,
        },
        publishedRoster: activity.publishedRosters.find(
          (publishedRoster) => publishedRoster.activityDate === activityReport.activityDate
        ),
      }))
    )
  );

  const usefulData = flatSessionReports.map((sessionReport) => {
    const submittedBy = `${sessionReport.submittedBy.profile.preferredName} ${sessionReport.submittedBy.profile.lastName}`;
    const attendees = sessionReport.allowCICO
      ? sessionReport.CICOs.map(
          (cico) => `${cico.volunteer.profile.preferredName} ${cico.volunteer.profile.lastName}`
        ).join(', ')
      : sessionReport.attendances
          .map((attendance) =>
            attendance.__typename === 'VOLUNTEER_VolunteerAttendanceType'
              ? `${attendance.volunteer.profile.preferredName} ${attendance.volunteer.profile.lastName}`
              : attendance.__typename === 'VOLUNTEER_OtherAttendanceType'
              ? attendance.name
              : ''
          )
          .join(', ');

    const rosterees = sessionReport.publishedRoster?.rosterings
      .map((rostering) => `${rostering.volunteer.profile.preferredName} ${rostering.volunteer.profile.lastName}`)
      .join(', ');

    const startTime = unpackToTime(sessionReport.allowCICO ? sessionReport.minCICOCheckIn : sessionReport.startTime);
    const startTimeFormatted = startTime.toFormat('HH:mm').toLowerCase();

    const endTime = unpackToTime(sessionReport.allowCICO ? sessionReport.maxCICOCheckOut : sessionReport.endTime);
    const endTimeFormatted = endTime.toFormat('HH:mm').toLowerCase();

    const timeDiff = endTime.diff(startTime, ['hours', 'minutes']);
    const duration =
      timeDiff.hours && timeDiff.minutes
        ? `${timeDiff.hours} hour ${Math.trunc(timeDiff.minutes)} mins`
        : timeDiff.hours
        ? `${timeDiff.hours} hours`
        : `${Math.trunc(timeDiff.minutes)} minutes`;

    const baseData: { [key: string]: string | number } = {
      sessionReportId: sessionReport.sessionReportId,
      reportTypeName: sessionReport.session.reportType
        ? sessionReport.session.reportType?.name
        : sessionReport.session.autoReport
        ? 'No Report Required (Impact Tracked)'
        : 'No Report Required (No Impact)',
      reportTypeId: sessionReport.session.reportType?.reportTypeId ?? '',
      programName: sessionReport.activity.program.name,
      programStatus: sessionReport.activity.program.dateSuspended ? 'Suspended' : 'Active',
      programId: sessionReport.activity.program.programId,
      activityName: sessionReport.activity.name,
      activityStatus: getActivityActivenessStatus(sessionReport.activity),
      activityId: sessionReport.activity.activityId,
      activityDate: unpackToDate(sessionReport.activityReport.activityDate).toFormat('dd/MM/y'),
      submissionDate: unpackToDate(sessionReport.submissionDate).toFormat('dd/MM/y'),
      submittedBy,
      startTime: startTimeFormatted,
      endTime: endTimeFormatted,
      duration,
      rosterees: rosterees ?? '',
      attendees,
      comments: sessionReport.comments ?? '',
    };

    sessionReport.fieldValues.forEach((fieldValue) => {
      baseData[fieldValue.field.fieldId] = getFieldDisplayValue(fieldValue);
    });

    return baseData;
  });

  return usefulData;
}

function getFilterTypeForFieldType(
  fieldType: ImpactDashboardDetailedViewReportTypeFieldField['__typename']
): 'dropdown' | 'checkbox' | 'multiselect' | 'textField' {
  return fieldType === 'VOLUNTEER_BooleanFieldType' || fieldType === 'VOLUNTEER_CheckboxFieldType'
    ? 'dropdown'
    : fieldType === 'VOLUNTEER_RatingFieldType' || fieldType === 'VOLUNTEER_DropdownFieldType'
    ? 'multiselect'
    : 'textField';
}

export function getTableColumns(selectedReportTypeIds: Array<string>, data?: GetImpactDashboardDetailedView) {
  if (!data || !data.vm) {
    return [];
  }
  const { vm } = data;
  const usefulColumns: Array<MUIDataTableColumnDef> = [];

  vm.reportTypes
    .filter((reportType) => selectedReportTypeIds.includes(reportType.reportTypeId))
    .forEach((reportType) =>
      reportType.items
        .sort((a, b) =>
          a.__typename === 'VOLUNTEER_ReportTypeFieldType' && b.__typename === 'VOLUNTEER_ReportTypeFieldType'
            ? a.order < b.order
              ? -1
              : 1
            : 0
        )
        .forEach((item) => {
          if (item.__typename === 'VOLUNTEER_ReportTypeFieldType') {
            usefulColumns.push({
              name: item.field.fieldId,
              label: item.field.name,
              options: {
                customHeadRender: getFieldValueCustomHeadRender(item.field.__typename),
                customBodyRender: getCustomBodyRender(item.field.name, item.field.__typename),
                filterType: getFilterTypeForFieldType(item.field.__typename),
              },
            });
          }
        })
    );

  const columnsWithFields = [...BASE_COLUMNS, ...uniqBy(usefulColumns, 'name')];

  return columnsWithFields;
}
