import React, { useState } from 'react';
import { HoverText } from '@campfire/hover-link';
import { Box, Typography } from '@material-ui/core';
import { useCommonStyles } from '../commonStyles';
import { AppSettingForm } from './AppSettingForm';
import { useGetIntegrationConfiguration } from './useGetIntegrationConfiguration';

type AppConfigSettingsProps = {
  id: string;
};

export function AppConfigSettings({ id }: AppConfigSettingsProps) {
  const [editMode, setEditMode] = useState<boolean>(false);
  const commonStyles = useCommonStyles();

  const { configuration } = useGetIntegrationConfiguration(id);

  return (
    <Box className={commonStyles.containerPanel}>
      <Box display='flex' alignItems='center'>
        <Typography variant='h2' className={commonStyles.headingTitle}>
          Settings
        </Typography>
        <HoverText
          color={editMode ? 'error' : 'textSecondary'}
          variant='body2'
          invertUnderlineBehaviour
          onClick={() => setEditMode(!editMode)}
          style={{
            display: 'inline-block',
            alignItems: 'center',
            fontSize: '12px',
            margin: '0 16px',
            paddingBottom: '0.5rem',
          }}
        >
          {editMode ? 'Cancel' : 'Edit'}
        </HoverText>
      </Box>
      {configuration && <AppSettingForm editMode={editMode} id={id} initialValues={configuration || {}} />}
    </Box>
  );
}
