import { Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Theme, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { memo, useState } from 'react';
import { AlertInfoDialog } from '../../../../../common/dialogs/AlertInfoDialog';
import { ConfirmationDialog } from '../../../../../common/dialogs/ConfirmationDialog';
import { Avatar } from '../../../../general/user-profile/components/Avatar';
import { useAdminConsoleActions } from '../../admin-console-actions';
import { AllAdminUsersAdminUser } from './__generated__/AllAdminUsersAdminUser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
      '&:hover': {
        background: theme.palette.grey[100],
      },
      '&:hover $deleteIcon': {
        color: theme.palette.action.disabled,
        '&:hover': {
          color: theme.palette.error.main,
        },
      },
    },
    deleteIcon: {
      color: 'transparent',
    },
  })
);

export const AdminUsersListItem = memo(
  ({
    user,
    onRemoveAdminSuccess,
    lengthAdminUsers,
  }: {
    user: AllAdminUsersAdminUser;
    onRemoveAdminSuccess: () => void;
    lengthAdminUsers: number;
  }) => {
    const [adminToRemove, setAdminToRemove] = useState<AllAdminUsersAdminUser>();
    const classes = useStyles();
    const { preferredName, lastName, email, avatarUrl, lastLogin } = user.profile;
    const lastLoginIpAddress = lastLogin?.ipAddress ? lastLogin.ipAddress : false;
    const lastLoginDate = lastLogin?.occurredAt
      ? new Date(lastLogin?.occurredAt).toLocaleString('en-au', {
          hour: 'numeric',
          minute: 'numeric',
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
      : false;

    const { runRemoveAdminVolunteer } = useAdminConsoleActions();

    const handleRemoveSuccess = () => {
      setAdminToRemove(undefined);
      onRemoveAdminSuccess();
    };
    return (
      <>
        <ListItem className={classes.listItem}>
          <ListItemAvatar>
            <Avatar size={32} preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} />
          </ListItemAvatar>

          <ListItemText
            style={{ marginLeft: 8 }}
            primary={`${preferredName} ${lastName}`}
            secondary={
              <>
                {email}
                <p style={{ margin: 0, fontSize: '.85em', paddingTop: '.5em' }}>
                  {lastLoginDate && `Last Login: ${lastLoginDate} (${lastLoginIpAddress})`}
                </p>
              </>
            }
          />

          <Box>
            <IconButton className={classes.deleteIcon} onClick={() => setAdminToRemove(user)}>
              <Close />
            </IconButton>
          </Box>
        </ListItem>

        {adminToRemove && lengthAdminUsers < 2 ? (
          <AlertInfoDialog
            open
            onClose={() => setAdminToRemove(undefined)}
            title={`You can't remove the last admin`}
            content={['Volaby needs at least one active admin user at all times.']}
          />
        ) : adminToRemove ? (
          <ConfirmationDialog
            title={'Confirm Removal'}
            body={
              <>
                <Typography variant='body1' gutterBottom>
                  {`Are you sure you want to remove ${adminToRemove.profile.preferredName} ${adminToRemove.profile.lastName} as a Volaby administrator?`}
                </Typography>
                <Typography variant='body1'>{'This user will still be able to use Volaby as a volunteer.'}</Typography>
              </>
            }
            open
            closeActionText={'Cancel'}
            confirmActionText={'Remove'}
            handleCloseClick={() => setAdminToRemove(undefined)}
            handleConfirmClick={() => {
              runRemoveAdminVolunteer({ managerId: adminToRemove.managerId, handleSuccess: handleRemoveSuccess });
            }}
          />
        ) : null}
      </>
    );
  }
);
export const AdminUsersList = memo(
  ({
    allUsers,
    filteredUsers,
    onRemoveAdminSuccess,
  }: {
    allUsers: AllAdminUsersAdminUser[];
    filteredUsers: AllAdminUsersAdminUser[];
    onRemoveAdminSuccess: () => void;
  }) => {
    return (
      <List style={{ padding: 16 }}>
        {filteredUsers.map((user) => (
          <AdminUsersListItem
            key={user.managerId}
            user={user}
            lengthAdminUsers={allUsers.length}
            onRemoveAdminSuccess={onRemoveAdminSuccess}
          />
        ))}
      </List>
    );
  }
);
