import { unpackToDate, unpackToTime } from '@campfire/hot-date/lib';
import React, { memo, useEffect, useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { arrayHead } from '../../../../common/functions/array-head';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import {
  DuplicateActivityGetActivityV2,
  DuplicateActivityGetActivityV2Variables,
} from './__generated__/DuplicateActivityGetActivityV2';
import { DUPLICATE_ACTIVITY_GET_ACTIVITY_V2 } from './activity-form-model-v2.gql';
import { ActivityFormV2, ActivityFormRecurrenceFrequency } from './ActivityFormV2';
import { NO_REPORT_NO_IMPACT, NO_REPORT_IMPACT_TRACKED } from './activity-form-actions-v2';

export const DuplicateActivityScreenV2 = memo(() => {
  const [activityId] = useQueryParam('activityId', StringParam);

  const [run, { data }] = useCampfireLazyQuery<DuplicateActivityGetActivityV2, DuplicateActivityGetActivityV2Variables>(
    DUPLICATE_ACTIVITY_GET_ACTIVITY_V2
  );

  const activity = useMemo(() => {
    return data?.vm.activity;
  }, [data]);

  const frequency = useMemo((): ActivityFormRecurrenceFrequency | undefined => {
    if (!activity) return undefined;

    const recurrence =
      activity.__typename === 'VOLUNTEER_RecurringActivityType' ? arrayHead(activity.schedule.recurrences) : null;
    if (!recurrence) return undefined;

    if (recurrence.__typename === 'DailyRecurrenceType') {
      return 'daily';
    }

    if (recurrence.__typename === 'WeeklyRecurrenceType') {
      return recurrence.weekInterval === 2 ? 'fortnightly' : 'weekly';
    }

    if (recurrence.__typename === 'MonthlyNthDayRecurrenceType') {
      return 'monthly-nth-day';
    }

    if (recurrence.__typename === 'MonthlyByMonthDayRecurrenceType') {
      return 'monthly-month-day';
    }

    if (recurrence.__typename === 'YearlyRecurrenceType') {
      return 'yearly';
    }

    return 'no-repeat';
  }, [activity]);

  useEffect(() => {
    if (!activityId) return;
    run({
      variables: {
        activityId: activityId,
      },
    });
  }, [activityId]);

  return activity ? (
    <ActivityFormV2
      baseActivityId={activityId}
      attachments={activity.activityAttachments}
      activityType={activity.__typename}
      predefinedFormValues={{
        activityName: `Copy of ${activity.name}`,
        activityDescription: activity.description,
        programId: activity.program.programId,
        hasOpenRoster: activity.hasOpenRoster,
        startDate: unpackToDate(activity.startDate).toJSDate(),
        endDate: activity.closedActivity
          ? unpackToDate(activity.closedActivity.endDate).toJSDate()
          : activity.__typename === 'VOLUNTEER_RecurringActivityType'
          ? arrayHead(activity.schedule.recurrences)?.endDate
          : undefined,
        frequency,
        location: activity.activityLocation
          ? {
              activityLocationId: activity.activityLocation.activityLocationId,
              description: activity.activityLocation.placesAddress.description,
              formatted: activity.activityLocation.placesAddress.formatted,
              latitude: activity.activityLocation.placesAddress.latLong.lat,
              longitude: activity.activityLocation.placesAddress.latLong.lat,
              placesId: activity.activityLocation.placesAddress.placesId ?? undefined,
            }
          : undefined,
        sessions: activity.sessions.map((session) => ({
          name: session.name,
          description: session.description,
          minVolunteers: session.minVolunteers ?? undefined,
          maxVolunteers: session.maxVolunteers ?? undefined,
          startTime: session.startTime ? unpackToTime(session.startTime).toJSDate() : null,
          endTime: session.endTime ? unpackToTime(session.endTime).toJSDate() : null,
          reportType: session.reportType
            ? {
                name: session.reportType.name,
                reportTypeId: session.reportType.reportTypeId,
              }
            : {
                name: session.autoReport ? 'No Report Required (Impact Tracked)' : 'No Report Required (No Impact)',
                reportTypeId: session.autoReport ? NO_REPORT_IMPACT_TRACKED : NO_REPORT_NO_IMPACT,
              },
          activityLocation: session.activityLocation
            ? {
                activityLocationId: session.activityLocation.activityLocationId,
                description: session.activityLocation.placesAddress.description,
                formatted: session.activityLocation.placesAddress.description,
                latitude: session.activityLocation.placesAddress.latLong.lat,
                longitude: session.activityLocation.placesAddress.latLong.lat,
                placesId: session.activityLocation.placesAddress.placesId ?? undefined,
              }
            : undefined,
        })),
      }}
    />
  ) : null;
});
