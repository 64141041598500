import { useContext } from 'react';
import { ScreenControlContext, ScreenControlContextInterface } from './ScreenControlContext';

const useScreenControl = (): ScreenControlContextInterface => {
  const screenControlContext = useContext(ScreenControlContext);

  if (!screenControlContext) throw new Error('useScreenControl must be used within a ScreenControlProvider');

  return screenControlContext;
};

export { useScreenControl };
