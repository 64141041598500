import React from 'react';
import { Box, Grid, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { TabletButton } from '@campfire/tablet-button';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';

import { FilterItem } from '../../../../common/filter-fields/FilterItem';
import {
  ProgramFilterSelectFieldV2,
  ProgramType,
} from '../../../program-manager/activity-management/activities-list/mangement-activities-filters/ProgramFilterSelectFieldV2';
import { FilterSelectField } from '../../../../common/filter-fields/StandardFilterSelectField';
import { DayFilterSelectFieldV3 } from '../../../program-manager/activity-management/activities-list/mangement-activities-filters/DayFilterSelectFieldV2';
import { InnerTimeFilterSelect } from '../../../program-manager/activity-management/activities-list/mangement-activities-filters/TimeFilterFields';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { InnerDateFilterSelect } from '../../../program-manager/activity-management/activities-list/mangement-activities-filters/DateFilterFields';
import {
  activityTypeFilterData,
  durationFilterData,
  locationFilterData,
  rosterTypeFilterData,
  statusFilterData,
} from '../../../../common/filter-fields/filters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.color.white.neutral50,
      borderRadius: '6px',
    },
    daySelectContainer: {
      width: 265,
      flex: 1,
      marginTop: '12px',
      [theme.breakpoints.down('sm')]: {
        width: 'unset',
      },
    },
    daySelectRoot: {
      alignSelf: 'start',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'unset',
      },
    },
    label: {
      textTransform: 'uppercase',
      color: theme.color.grey.neutral300,
      fontWeight: 700,
      fontSize: '0.75rem',
    },
    itemWithBorder: {
      borderTop: 'solid 1px',
      borderTopColor: theme.color.grey.neutralBrand200,
    },
    timeBorder: {
      borderBottom: 'solid 1px',
      borderBottomColor: theme.color.grey.neutralBrand200,
    },
  })
);

export type ProgramQueryType = {
  program: string;
};

export type ActivityTypeQueryType = {
  activityType: string;
};

export type DurationQueryType = {
  duration: string;
};

export type LocationQueryType = {
  location: string;
};

export type ActivityStatusQueryType = {
  activityStatus: string;
};

export type RosterTypeQueryType = {
  rosterType: string;
};

export type DaysQueryType = {
  days: string[];
};

export type StartDateQueryType = {
  startDate?: string;
};

export type EndDateQueryType = {
  endDate?: string;
};

export type StartTimeQueryType = {
  startTime?: string;
};

export type EndTimeQueryType = {
  endTime?: string;
};

export type QueryType =
  | ProgramQueryType
  | ActivityTypeQueryType
  | DurationQueryType
  | LocationQueryType
  | ActivityStatusQueryType
  | RosterTypeQueryType
  | DaysQueryType
  | StartDateQueryType
  | EndDateQueryType
  | StartTimeQueryType
  | EndTimeQueryType;

const initialFilters = {
  program: 'all',
  activityType: 'all',
  duration: 'any',
  location: 'all',
  activityStatus: 'all',
  rosterType: 'all',
  days: [],
  startDate: undefined,
  endDate: undefined,
  startTime: undefined,
  endTime: undefined,
};

export interface FilterProps {
  programs: ProgramType[];
}

export function Filter({ programs }: FilterProps) {
  const { theme, isMobile } = useCampfireTheme();
  const [query, setQuery] = useQueryParams({
    program: StringParam,
    activityType: StringParam,
    duration: StringParam,
    location: StringParam,
    activityStatus: StringParam,
    rosterType: StringParam,
    days: ArrayParam,
    startDate: StringParam,
    endDate: StringParam,
    startTime: StringParam,
    endTime: StringParam,
  });

  const [filters, setFilters] = React.useState({
    program: query.program || 'all',
    activityType: query.activityType || 'all',
    duration: query.duration || 'any',
    location: query.location || 'all',
    activityStatus: query.activityStatus || 'all',
    rosterType: query.rosterType || 'all',
    days: query.days || [],
    startDate: query.startDate,
    endDate: query.endDate,
    startTime: query.startTime,
    endTime: query.endTime,
  });

  const classes = useStyles();

  const handleChange = (newValue: QueryType) => {
    setFilters((prev) => ({ ...prev, ...newValue }));
  };

  const programsSorted = programs.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box flexGrow={1} className={classes.container}>
      <Grid container style={{ width: 'unset', margin: 0 }}>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FilterItem title='PROGRAM'>
            <ProgramFilterSelectFieldV2
              value={filters.program}
              programs={programsSorted}
              onChange={(newProgramId) => handleChange({ program: newProgramId })}
            />
          </FilterItem>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FilterItem title='ACTIVITY TYPES'>
            <FilterSelectField
              value={filters.activityType}
              filterData={activityTypeFilterData}
              onChange={(newActivityType) => handleChange({ activityType: newActivityType })}
            />
          </FilterItem>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FilterItem title='DURATION'>
            <FilterSelectField
              value={filters.duration}
              filterData={durationFilterData}
              onChange={(newDuration) => handleChange({ duration: newDuration })}
            />
          </FilterItem>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FilterItem title='LOCATIONS'>
            <FilterSelectField
              value={filters.location}
              filterData={locationFilterData}
              onChange={(newLocation) => handleChange({ location: newLocation })}
            />
          </FilterItem>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FilterItem title='ACTIVITY STATUS'>
            <FilterSelectField
              value={filters.activityStatus}
              filterData={statusFilterData}
              onChange={(newStatus) => handleChange({ activityStatus: newStatus })}
            />
          </FilterItem>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FilterItem title='ROSTER TYPES'>
            <FilterSelectField
              value={filters.rosterType}
              filterData={rosterTypeFilterData}
              onChange={(newRosterType) => handleChange({ rosterType: newRosterType })}
            />
          </FilterItem>
        </Grid>
        <Grid item md={6} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <FilterItem title='DAY'>
            <DayFilterSelectFieldV3
              value={filters.days || []}
              onChange={(newDays) => handleChange({ days: newDays })}
            />
          </FilterItem>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <FilterItem title='DATE' underline>
            <InnerDateFilterSelect
              start={filters.startDate}
              end={filters.endDate}
              setStart={(startDate?: string) => handleChange({ startDate })}
              setEnd={(endDate?: string) => handleChange({ endDate })}
            />
          </FilterItem>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <FilterItem title='TIME' underline>
            <InnerTimeFilterSelect
              start={filters.startTime}
              end={filters.endTime}
              setStart={(startTime) => handleChange({ startTime })}
              setEnd={(endTime) => handleChange({ endTime })}
            />
          </FilterItem>
        </Grid>
      </Grid>
      <Box display='flex' justifyContent={isMobile ? 'center' : 'flex-start'} px={'10px'}>
        <TabletButton
          variant='contained'
          size='small'
          style={{
            backgroundColor: '#7047EB',
            color: '#ffffff',
            boxSizing: 'border-box',
            borderRadius: '4px',
            textTransform: 'none',
            marginRight: '8px',
            minWidth: '40px',
            padding: '7px 12px',
          }}
          onClick={() => setQuery(filters)}
        >
          {'SHOW THE RESULTS'}
        </TabletButton>
        <TabletButton
          variant='contained'
          style={{
            backgroundColor: '#e6e5fc',
            color: theme.color.secondary.main900,
            boxSizing: 'border-box',
            borderRadius: '4px',
            textTransform: 'none',
            minWidth: '40px',
            padding: '7px 12px',
          }}
          onClick={() => setFilters(initialFilters)}
        >
          {'CLEAR'}
        </TabletButton>
      </Box>
    </Box>
  );
}
