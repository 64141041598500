import React from 'react';
import { unpackToDateTime } from '@campfire/hot-date';
import { Box, Typography } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid';
import { useCampfireQuery } from '../../../../../../../../global/network/useCampfireQuery';
import { CommonRequiringActionWidgetProps } from '../../shared';
import { VolunteerProfile } from '../../../../../../../../common/AvatarName';
import { ApplicationActionMenuButton } from '../../../../../../../admin/program-management/program-management-editor/program-form-sections/ProgramApplicationSection';
import { ProgramApplicationActions, useProgramApplicationActions } from './ProgramApplicationActions';

import { useTableStyles } from '../../../../../../../program-manager/volunteers/volunteers-importing/styles/table';
import { GET_WIDGET_PROGRAM_APPLICATIONS } from './program-application-ra-widget-model.gql';
import {
  GetWidgetProgramApplications,
  GetWidgetProgramApplicationsVariables,
} from './__generated__/GetWidgetProgramApplications';
import { WidgetTypes } from '../../../dashboard-widget-setting/dashboard-widget-model';

export const ProgramApplicationRAWidget = (props: CommonRequiringActionWidgetProps) => {
  const { filters, range } = props;
  const { programIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };
  const { onAction } = useProgramApplicationActions();
  const tableClasses = useTableStyles();

  const { data: getProgramApplicationsData, refetch, loading } = useCampfireQuery<
    GetWidgetProgramApplications,
    GetWidgetProgramApplicationsVariables
  >(GET_WIDGET_PROGRAM_APPLICATIONS, {
    options: {
      variables: {
        programIds: programIds.length > 0 ? programIds : null,
        range: range,
        widgetType: WidgetTypes.REQUIRING_ACTION,
      },
    },
  });

  const programApplications =
    getProgramApplicationsData?.vm.programApplications
      .filter(
        (application) =>
          !application.dateActioned &&
          !application.dateWithdrawn &&
          !application.volunteer.dateDeactivated &&
          !application.volunteer.flagging?.dateFlagged &&
          !application.program.dateDeleted &&
          !application.program.dateSuspended
      )
      .sort((a, b) => (a.volunteer.profile.lastName > b.volunteer.profile.lastName ? 1 : -1))
      .map((application) => ({
        id: application.programApplicationId,
        program: application.program.name,
        profile: application.volunteer.profile,
        dateApplied: unpackToDateTime(application.dateApplied),
      })) || [];

  const handleActionSuccess = () => {
    if (refetch) refetch();
  };

  const columns: GridColumns = [
    {
      field: 'volunteerName',
      headerName: 'Volunteer',
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <VolunteerProfile
            inTable
            noTrim
            {...row.profile}
            name={`${row.profile.preferredName} ${row.profile.lastName}`}
          />
        );
      },
    },
    {
      field: 'program',
      headerName: 'Program',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'dateApplied',
      headerName: 'Date Applied',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: ({ row }: any) => row.dateApplied.toFormat('dd/MM/y'),
    },
    {
      field: '',
      disableColumnMenu: true,
      headerName: '',
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <ApplicationActionMenuButton
            onAction={(action: ProgramApplicationActions) => onAction(action, row.id, () => handleActionSuccess())}
            bulk={false}
          />
        );
      },
    },
  ];

  return (
    <Box width='100%' height='100%'>
      {programApplications.length === 0 ? (
        <Typography variant='subtitle2' color='textSecondary' style={{ paddingTop: '6px' }}>
          No applications
        </Typography>
      ) : (
        <>
          <DataGrid
            columns={columns}
            rows={programApplications}
            classes={tableClasses}
            loading={loading}
            autoPageSize
            disableSelectionOnClick
          />
        </>
      )}
    </Box>
  );
};
