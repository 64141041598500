import { Box, Switch, Typography } from '@material-ui/core';
import React from 'react';

interface AutoVerificationEditFormProps {
  isLoading: boolean;
  autoVerification?: boolean;
  setAutoVerification: (value: boolean) => void;
}

export const AutoVerificationEditForm = (props: AutoVerificationEditFormProps) => {
  const { isLoading, autoVerification, setAutoVerification } = props;
  return (
    <Box>
      <>
        <Typography gutterBottom variant='h6' style={{ fontWeight: 600 }}>
          {'Auto-approve Applications'}
        </Typography>
        {isLoading ? null : autoVerification !== undefined ? (
          <Box display='flex' flex='1 1 auto'>
            <Box style={{ paddingTop: 4, paddingRight: 8 }}>
              <Switch
                name='auto-verification-toggle'
                color='primary'
                checked={autoVerification}
                onChange={(e) => setAutoVerification(e.target.checked)}
                inputProps={{ 'aria-label': 'verification checkbox' }}
              />
            </Box>
            <Box>
              <Box ml={1}>
                <Typography
                  style={{
                    fontSize: '18px',
                    color: '#000000b0',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    fontVariant: 'all-small-caps',
                  }}
                >
                  {`Auto-approve is ${autoVerification === false ? 'off' : 'on'}`}
                </Typography>
                <Typography style={{ fontSize: '13px', color: '#808080' }}>
                  {autoVerification === false
                    ? 'Applications will always require manual review.'
                    : 'Applications will pass automatically if all screening questions are answered correctly.'}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : null}
      </>
    </Box>
  );
};
