import React, { useState, useRef, useLayoutEffect, ChangeEvent, FunctionComponent } from 'react';
import { Box, Paper, IconButton, InputBase } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Search as SearchIcon, Clear } from '@material-ui/icons';
import { GetInputPropsOptions } from 'downshift';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      display: 'flex',
      '&:hover': {
        boxShadow: theme.shadows[3],
      },
    },
    input: {
      marginLeft: 8,
      flex: 1,
      flexGrow: 1,
    },
  })
);

export const PaperSearch: FunctionComponent<{
  getInputProps: (options?: GetInputPropsOptions) => any;
  placeholder: string;
  autoFocus?: boolean;
  clearOnBlur?: boolean;
  value?: string;
  onChange?: (event: ChangeEvent<any>) => void;
  clear?: () => void;
}> = ({ getInputProps, placeholder, autoFocus, clearOnBlur, value, onChange, clear }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const classes = useStyles();

  useLayoutEffect(() => {
    if (inputRef && inputRef.current && focused) {
      inputRef.current.focus();
    }
  }, [focused]);
  return (
    <Box width={1}>
      <Paper className={classes.paper} elevation={focused ? 2 : 1}>
        <span
          onClick={() => {
            setFocused(true);
          }}
        >
          <IconButton disabled tabIndex={-1}>
            <SearchIcon color={focused ? 'action' : 'disabled'} />
          </IconButton>
        </span>
        <InputBase
          autoFocus={autoFocus}
          inputRef={inputRef}
          className={classes.input}
          {...getInputProps({
            onChange: (event) => {
              if (onChange) {
                onChange(event);
              }
            },
            ref: undefined,
            placeholder,
            value,
            onBlur: () => {
              if (clearOnBlur) {
                setFocused(false);
              }
            },
          })}
          onFocus={() => {
            setFocused(true);
          }}
          inputProps={{ 'aria-label': 'Search volunteers by name' }}
        />
        {value && clear ? (
          <IconButton onClick={clear}>
            <Clear />
          </IconButton>
        ) : null}
      </Paper>
    </Box>
  );
};
