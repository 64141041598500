import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { Box, Button, Typography } from '@material-ui/core';
import { Description, KeyboardArrowDown, KeyboardArrowUp, Person } from '@material-ui/icons';
import { convertFromRaw, EditorState } from 'draft-js';
import React, { useMemo, useState } from 'react';
import { getDisplayTimeSchedule } from '../../../../common/functions/activity-display-helpers';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface Props {
  sessions?: Array<any>;
  showMoreSessionInfo?: boolean;
}

interface SessionProps {
  session?: any;
  showMoreSessionInfo?: boolean;
}

const SessionItem = (props: SessionProps) => {
  const { session, showMoreSessionInfo } = props;
  const { theme } = useCampfireTheme();
  const sessionTime = getDisplayTimeSchedule(session.startTime, session.endTime);

  const editorState = useMemo(
    () =>
      session?.description ? EditorState.createWithContent(convertFromRaw(JSON.parse(session.description))) : null,
    [session?.description]
  );

  return (
    <>
      <Box marginTop={'10px 0'} padding={'10px 0'}>
        <Box>
          <Typography
            style={{
              fontWeight: 600,
              color: theme.color.grey.neutralBrand800,
              fontSize: '16px',
            }}
          >
            {session.name}
          </Typography>
          <Typography
            style={{
              fontWeight: 500,
              color: theme.color.grey.neutral200,
              fontSize: '12px',
            }}
          >
            {sessionTime}
          </Typography>
        </Box>
        {showMoreSessionInfo ? (
          <Box>
            <Typography
              style={{
                fontWeight: 400,
                color: theme.color.grey.neutral200,
                fontSize: '12px',
                marginTop: '4px',
              }}
            >
              {editorState ? <CampfireRichEditor editorState={editorState} readOnly /> : null}
            </Typography>
            <Box display={'flex'} marginTop={'12px'} justifyContent={'space-between'}>
              <Box display={'flex'}>
                <Description fontSize='small' style={{ color: theme.color.grey.neutral200 }} />
                <Typography
                  style={{
                    fontWeight: 500,
                    color: theme.color.grey.neutral200,
                    fontSize: '12px',
                    paddingLeft: '5px',
                    marginTop: '2px',
                  }}
                >
                  {session.reportType === null
                    ? session.autoReport
                      ? 'No Report Required (Impact Tracked)'
                      : 'No Report Required (No Impact)'
                    : session.reportType?.name}
                </Typography>
              </Box>
              <Box display={'flex'}>
                {session.minVolunteers ? (
                  <Box display={'flex'} alignItems={'center'}>
                    <Box display={'flex'} flexDirection={'column'}>
                      <Person style={{ color: theme.color.grey.neutral200, fontSize: '16px' }} />
                      <Typography
                        style={{
                          fontWeight: 700,
                          color: theme.color.grey.neutral200,
                          fontSize: '8px',
                          paddingLeft: '1px',
                          marginTop: '-3px',
                        }}
                      >
                        MIN
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        fontWeight: 500,
                        color: theme.color.grey.neutral200,
                        fontSize: '14px',
                        paddingLeft: '7px',
                      }}
                    >
                      {session.minVolunteers}
                    </Typography>
                  </Box>
                ) : (
                  ''
                )}
                {session.maxVolunteers ? (
                  <Box display={'flex'} alignItems={'center'} paddingLeft={'20px'}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                      <Person style={{ color: theme.color.grey.neutral200, fontSize: '16px' }} />
                      <Typography
                        style={{
                          fontWeight: 700,
                          color: theme.color.grey.neutral200,
                          fontSize: '8px',
                          paddingLeft: '1px',
                          marginTop: '-3px',
                        }}
                      >
                        MAX
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        fontWeight: 500,
                        color: theme.color.grey.neutral200,
                        fontSize: '14px',
                        paddingLeft: '7px',
                      }}
                    >
                      {session.maxVolunteers}
                    </Typography>
                  </Box>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </>
  );
};

export const SessionDetail = (props: Props) => {
  const { sessions, showMoreSessionInfo } = props;
  const { theme } = useCampfireTheme();
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <Box>
      {sessions
        ?.slice(0, showMore ? undefined : 3)
        .map((s) => (s ? <SessionItem showMoreSessionInfo={showMoreSessionInfo} session={s} /> : ''))}
      <Box style={{ width: '100%', paddingTop: '12px' }}>
        {sessions && sessions.length > 3 ? (
          <Button
            variant='text'
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '8px 12px',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '8px',
              display: 'block',
            }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <Box display='flex'>
                <Typography
                  style={{
                    color: theme.color.grey.neutral400,
                    fontSize: '14px',
                    textTransform: 'none',
                    marginRight: '4px',
                  }}
                >
                  Show Less
                </Typography>
                <KeyboardArrowUp style={{ fontSize: '20px', color: theme.color.grey.neutral400 }} />
              </Box>
            ) : (
              <Box display='flex'>
                <Typography
                  style={{
                    color: theme.color.grey.neutral400,
                    fontSize: '14px',
                    textTransform: 'none',
                    marginRight: '4px',
                  }}
                >
                  Show More
                </Typography>
                <KeyboardArrowDown style={{ fontSize: '20px', color: theme.color.grey.neutral400 }} />
              </Box>
            )}
          </Button>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};
