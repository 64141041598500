import { gql } from '@apollo/client';

export const GET_ADMIN_CONSOLE_INCIDENT_SEVERITIES = gql`
  query GetAdminConsoleIncidentSeverities {
    vm {
      incidentSeverities {
        ...AdminConsoleIncidentSeverity
      }
    }
  }
  fragment AdminConsoleIncidentSeverity on VOLUNTEER_IncidentSeverityType {
    incidentSeverityId
    name
    description
    color
    order
  }
`;

export const GET_ADMIN_CONSOLE_INCIDENT_CATEGORIES = gql`
  query GetAdminConsoleIncidentCategories {
    vm {
      incidentCategorySelectionRoot {
        ...AdminConsoleIncidentCategorySelectionRootCategories
      }
    }
  }
  fragment AdminConsoleIncidentCategorySelectionRootCategories on VOLUNTEER_IncidentCategoryGroupType {
    ...AdminConsoleIncidentCategoryGroup
    childGroups {
      ...AdminConsoleIncidentCategoryGroup
      childGroups {
        ...AdminConsoleIncidentCategoryGroup
        childGroups {
          ...AdminConsoleIncidentCategoryGroup
          childGroups {
            ...AdminConsoleIncidentCategoryGroup
            childGroups {
              ...AdminConsoleIncidentCategoryGroup
              childGroups {
                ...AdminConsoleIncidentCategoryGroup
                childGroups {
                  ...AdminConsoleIncidentCategoryGroup
                  childGroups {
                    ...AdminConsoleIncidentCategoryGroup
                    childGroups {
                      ...AdminConsoleIncidentCategoryGroup
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment AdminConsoleIncidentCategoryGroup on VOLUNTEER_IncidentCategoryGroupType {
    incidentCategoryGroupId
    label
    categories {
      ...AdminConsoleIncidentCategory
    }
  }

  fragment AdminConsoleIncidentCategory on VOLUNTEER_IncidentCategoryType {
    incidentCategoryId
    label
    details {
      ...AdminConsoleIncidentDetail
    }
  }
  fragment AdminConsoleIncidentDetail on VOLUNTEER_IncidentDetailType {
    incidentDetailId
    label
  }
`;
