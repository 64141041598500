import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { StringParam, useQueryParam } from 'use-query-params';
import { createStyles, makeStyles } from '@material-ui/styles';

import { SearchField } from '../../../../../common/inputs/SearchField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > h5': {
        flex: 1,
        fontWeight: 900,
        fontSize: 20,
      },
    },
    headingRight: {
      display: 'flex',
      alignItems: 'center',
    },
    subHeading: {
      fontWeight: 400,
      fontSize: 14,
      color: theme.color.grey.neutral200,
    },
  })
);

export function TemplateHeader() {
  const [q, setQ] = useQueryParam('q', StringParam);

  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.heading}>
        <Typography variant='h5'>Communications</Typography>
        <Box className={classes.headingRight}>
          <SearchField value={q} onChange={(event) => setQ(event.target.value)} />
        </Box>
      </Box>
      <Typography className={classes.subHeading}>
        You can view, edit, enable and disable certain communications to users of Volaby in your organisation. Some
        communications cannot be disabled.
      </Typography>
    </Box>
  );
}
