import { encodeDate, unpackToDate } from '@campfire/hot-date/lib';
import { DateTime } from 'luxon';
import { ActivityTimelineSidebarActivity } from '../__generated__/ActivityTimelineSidebarActivity';
import { ActivityTimelineSidebarActivityReport } from '../__generated__/ActivityTimelineSidebarActivityReport';
import { ActivityTimelineSidebarCancelledActivity } from '../__generated__/ActivityTimelineSidebarCancelledActivity';
import { ActivityTimelineSidebarDraftRoster } from '../__generated__/ActivityTimelineSidebarDraftRoster';
import { GetActivityTimelineSidebarData } from '../__generated__/GetActivityTimelineSidebarData';
import { FutureSidebarItem, PastSidebarItem, SidebarItem } from './ActivityTimelineSidebar';

export interface SidebarTabs {
  past: {
    [key: string]: PastSidebarItem[];
  };
  future: {
    [key: string]: FutureSidebarItem[];
  };
}

export type SelectedSidebarTab =
  | {
      [key: string]: PastSidebarItem[];
    }
  | {
      [key: string]: FutureSidebarItem[];
    };

type SidebarItemActivity = ActivityTimelineSidebarActivity;

const getDraftRosterForDate = (
  activity: SidebarItemActivity,
  activityDate: string
): ActivityTimelineSidebarDraftRoster | undefined => {
  return activity.draftRosters.find((draftRoster) => draftRoster.activityDate === activityDate);
};

const getActivityReportForDate = (
  activity: SidebarItemActivity,
  activityDate: string
): ActivityTimelineSidebarActivityReport | undefined => {
  return activity.activityReports.find((activityReport) => activityReport.activityDate === activityDate);
};

const getCancelledActivityForDate = (
  activity: SidebarItemActivity,
  activityDate: string
): ActivityTimelineSidebarCancelledActivity | undefined => {
  return activity.cancelledActivities.find((cancelledActivity) => cancelledActivity.activityDate === activityDate);
};

export const getFutureSidebarItemStatus = ({
  draftRoster,
  cancelledActivity,
}: {
  draftRoster: ActivityTimelineSidebarDraftRoster | undefined;
  cancelledActivity: ActivityTimelineSidebarCancelledActivity | undefined;
}) => {
  return cancelledActivity
    ? 'cancelled'
    : !draftRoster
    ? 'not-started'
    : draftRoster.publishedRoster
    ? draftRoster.hasUnpublishedChanges
      ? 'unpublished'
      : 'published'
    : 'not-published';
};

const createFutureSidebarItem = (
  activity: SidebarItemActivity,
  activityDate: string,
  rosterTemplateId: string | undefined
): FutureSidebarItem => {
  const draftRoster = getDraftRosterForDate(activity, activityDate);
  const cancelledActivity = getCancelledActivityForDate(activity, activityDate);
  const futureStatus = getFutureSidebarItemStatus({ draftRoster, cancelledActivity });

  // TODO: Rethink for flexible activities
  return {
    type: 'future',
    activityId: activity.activityId,
    programId: activity.program.programId,
    programName: activity.program.name,
    activityName: activity.name,
    activityDate: activityDate,
    startTime: activity.startTime,
    endTime: activity.endTime,
    draftRoster,
    cancelledActivity,
    closedActivity: activity.closedActivity ?? undefined,
    isSuspended: activity.isSuspended,
    futureStatus,
    humanReadableRecurrence:
      activity.__typename === 'VOLUNTEER_RecurringActivityType'
        ? activity.schedule.recurrences.map((x) => x.humanReadable).join(' & ')
        : 'Flexible',
    rosterTemplateId,
  };
};

export const getPastSidebarItemStatus = ({
  cancelledActivity,
  activityReport,
}: {
  cancelledActivity: ActivityTimelineSidebarCancelledActivity | undefined;
  activityReport: ActivityTimelineSidebarActivityReport | undefined;
}) => {
  const completedSessionReportIds = activityReport?.sessionReports.map((x) => x.session.sessionId) ?? [];
  const cancelledSessionReportIds = activityReport?.cancelledSessions.map((x) => x.sessionId) ?? [];
  const actionedSessionIds = new Set([...completedSessionReportIds, ...cancelledSessionReportIds]);

  return cancelledActivity
    ? 'cancelled'
    : !activityReport
    ? 'not-started'
    : actionedSessionIds.size === activityReport?.activity.sessions.length
    ? 'complete'
    : 'incomplete';
};

const createPastSidebarItem = (
  activity: SidebarItemActivity,
  activityDate: string,
  rosterTemplateId: string | undefined
): PastSidebarItem => {
  const draftRoster = getDraftRosterForDate(activity, activityDate);
  const cancelledActivity = getCancelledActivityForDate(activity, activityDate);
  const activityReport = getActivityReportForDate(activity, activityDate);
  const pastStatus = getPastSidebarItemStatus({ cancelledActivity, activityReport });

  // TODO: Rethink for flexible activities
  return {
    type: 'past',
    activityId: activity.activityId,
    programId: activity.program.programId,
    programName: activity.program.name,
    activityName: activity.name,
    activityDate: activityDate,
    startTime: activity.startTime,
    endTime: activity.endTime,
    draftRoster,
    cancelledActivity,
    activityReport,
    closedActivity: activity.closedActivity ?? undefined,
    isSuspended: activity.isSuspended,
    pastStatus,
    humanReadableRecurrence:
      activity.__typename === 'VOLUNTEER_RecurringActivityType'
        ? activity.schedule.recurrences.map((x) => x.humanReadable).join(' & ')
        : 'Flexible',
    rosterTemplateId,
  };
};

type SortOrder = 'asc' | 'desc';
const sortSidebarTabKeys = <I extends SidebarItem>(
  sidebarTab: { [key: string]: I[] },
  sortOrder: SortOrder
): { [key: string]: I[] } => {
  const sortedItems: { [key: string]: I[] } = {};
  const keys = Object.keys(sidebarTab);
  const orderedKeys = sortOrder === 'asc' ? keys.sort() : keys.sort().reverse();
  orderedKeys.forEach((date) => {
    sortedItems[date] = sidebarTab[date];
  });
  return sortedItems;
};

const SortSidebarItemDate = <I extends SidebarItem>(sidebarItems: I[], sortOrder: SortOrder): I[] => {
  return sidebarItems.sort((a, b) => {
    const startTimeCompare = a.startTime ? a.startTime.localeCompare(b.startTime) : 0;
    if (startTimeCompare !== 0) return sortOrder === 'asc' ? startTimeCompare : -startTimeCompare;

    const endTimeCompare = a.endTime ? a.endTime.localeCompare(b.endTime) : 0;
    if (endTimeCompare !== 0) return sortOrder === 'asc' ? endTimeCompare : -endTimeCompare;

    const nameCompare = a.activityName.localeCompare(b.activityName);
    return sortOrder === 'asc' ? nameCompare : -nameCompare;
  });
};

export const parseSidebarTabs = (sidebarData: GetActivityTimelineSidebarData): SidebarTabs => {
  const nowDate = encodeDate(DateTime.local());
  const sidebarTabs: SidebarTabs = {
    past: {},
    future: {},
  };

  sidebarData.vm.activities.forEach((activity) => {
    activity.occurrencesBetween.forEach((occurrenceDate) => {
      const maybeRosterTemplate = activity.rosterTemplates.find((rosterTemplate) =>
        unpackToDate(rosterTemplate.rosterDate).equals(unpackToDate(occurrenceDate))
      );

      if (occurrenceDate <= nowDate) {
        const pastSidebarItem = createPastSidebarItem(activity, occurrenceDate, maybeRosterTemplate?.rosterTemplateId);
        sidebarTabs.past[occurrenceDate] = sidebarTabs.past[occurrenceDate] ?? [];
        sidebarTabs.past[occurrenceDate].push(pastSidebarItem);
      }

      if (occurrenceDate >= nowDate) {
        const futureSidebarItem = createFutureSidebarItem(
          activity,
          occurrenceDate,
          maybeRosterTemplate?.rosterTemplateId
        );
        sidebarTabs.future[occurrenceDate] = sidebarTabs.future[occurrenceDate] ?? [];
        sidebarTabs.future[occurrenceDate].push(futureSidebarItem);
      }
    });
  });

  sidebarTabs.past = sortSidebarTabKeys(sidebarTabs.past, 'desc');
  sidebarTabs.future = sortSidebarTabKeys(sidebarTabs.future, 'asc');

  Object.keys(sidebarTabs.past).forEach((date) => {
    sidebarTabs.past[date] = SortSidebarItemDate(sidebarTabs.past[date], 'desc');
  });

  Object.keys(sidebarTabs.future).forEach((date) => {
    sidebarTabs.future[date] = SortSidebarItemDate(sidebarTabs.future[date], 'asc');
  });

  return sidebarTabs;
};
