import { gql } from '@apollo/client';

export const GET_PROGRAMS = gql`
  query BulkRosterGetPrograms {
    vm {
      programs {
        programId
        name
        dateDeleted
        dateSuspended
      }
    }
  }
`;
