import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { stringify } from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { AlertCard, getAlertCardColors } from '../../../../../../common/cards/alert-card/AlertCard';
import { useUser } from '../../../../../../global/auth/useUser';
import { useVolDashboardMyElementsContext } from '../../VolDashboardMyElementsContext';

export const SideBarNewTasks = () => {
  const { getVolunteerIdentity } = useUser();
  const volunteerIdentity = getVolunteerIdentity();
  const history = useHistory();
  const match = useRouteMatch();
  const [query] = useQueryParams({
    activityStatus: ArrayParam,
    days: ArrayParam,
    startTime: StringParam,
    endTime: StringParam,
    startDate: StringParam,
    endDate: StringParam,
  });
  const { getSideBarMyTasks, getSideBarMyTasksData, getSideBarMyTasksDataLoading } = useVolDashboardMyElementsContext();
  const { darkTextColor, lightTextColor } = getAlertCardColors('warning');
  const { newTasksNumber, setNewTasksNumber } = useVolDashboardMyElementsContext();

  useEffect(() => {
    getSideBarMyTasks({
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
      },
    });
  }, []);

  useEffect(() => {
    const myIncompleteTasks =
      getSideBarMyTasksData?.vm?.tasks
        ?.filter(
          (task) =>
            !getSideBarMyTasksData?.vm.volunteer?.profile?.completedTasks.find(
              (ct) => ct.task.taskId === task.taskId
            ) && task.cake.cakeType === 'required'
        )
        .filter((task) => !task.dateRemoved) ?? [];
    setNewTasksNumber(myIncompleteTasks.length);
  }, [getSideBarMyTasksData]);

  const orgName = getSideBarMyTasksData?.orgName;

  return getSideBarMyTasksDataLoading ? (
    <Skeleton variant='text' width='100%' height={105} style={{ paddingBottom: '24px' }} />
  ) : newTasksNumber > 0 && orgName ? (
    <Box
      paddingBottom={3}
      width='100%'
      onClick={() => {
        history.replace(`${match.path}/?${stringify(query)}&tab=tasks`);
      }}
    >
      <AlertCard title='New Task' variant='warning'>
        <Typography
          variant='subtitle2'
          display='inline'
          style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
        >
          <span style={{ fontWeight: 'bold', color: darkTextColor }}>{newTasksNumber}</span>{' '}
          {`new tasks added by ${orgName} to complete. `}
        </Typography>

        <Typography
          variant='body2'
          display='inline'
          style={{ textDecoration: 'underline', color: darkTextColor, fontWeight: 700 }}
        >
          Click here
        </Typography>
        <Typography
          variant='subtitle2'
          display='inline'
          style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
        >
          {' to see.'}
        </Typography>
      </AlertCard>
    </Box>
  ) : null;
};
