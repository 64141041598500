import React from 'react';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { FileUploadGallery, FugFile, UploadedFile } from '@campfire/file-upload-gallery';
import { Box, Typography } from '@material-ui/core';
import { EditorState } from 'draft-js';
import { useApiUrl } from '../../global/config/useApiUrl';
import { useCampfireFetch } from '../../global/network/useCampfireFetch';
import { FileTypeEnumType } from '../../__generated__/globalTypes';

type CommentProps = {
  content: EditorState;
  setContent: (editorState: EditorState) => void;
  uploadedFiles: UploadedFile[];
  handleFileAdded: (files: FileProcessed[]) => void;
  handleFileRemoved: (fileId: string) => void;
};

export type FileProcessed = {
  id: string;
  name: string;
  fileType: FileTypeEnumType;
  url: string;
  objectUrl: string;
};

export const filePreprocessor = (files: FugFile[]) => {
  return Promise.all(
    files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const { result } = reader;
          if (result) {
            resolve(result.toString().substr(result.toString().indexOf(',') + 1));
          } else {
            resolve('');
          }
        };
        reader.onerror = (error) => reject(error);
      });
    })
  ).then((results: any[]) => {
    return results.map((result, resultIndex) => ({
      id: files[resultIndex].id,
      name: files[resultIndex].name,
      fileType: files[resultIndex].type as FileTypeEnumType,
      url: result as string,
      objectUrl: files[resultIndex].objectUrl,
    }));
  });
};

export function useComment() {
  const [content, setContent] = React.useState(EditorState.createEmpty());
  const [uploadingFiles, setUploadingFiles] = React.useState<FileProcessed[]>([]);
  // const saveComment = useSaveComment();
  // const onSubmit = () => {
  //   const attachments = uploadingFiles.map((uploadingFile) => ({
  //     url: uploadingFile.url,
  //     fileType: uploadingFile.fileType,
  //     name: uploadingFile.name,
  //   }));

  //   saveComment({
  //     applicationId: application.applicationId,
  //     content: JSON.stringify(convertToRaw(content.getCurrentContent())),
  //     attachments,
  //   });
  // };

  const handleFileRemoved = (fileId: string) => {
    setUploadingFiles(uploadingFiles.filter(({ id }) => id !== fileId));
  };

  return {
    content,
    setContent,
    handleFileRemoved,
  };
}

export function Comment({ content, setContent, handleFileAdded, uploadedFiles, handleFileRemoved }: CommentProps) {
  return (
    <Box>
      <Box mt='1.5rem' width='100%'>
        <CampfireRichEditor editorState={content} setEditorState={setContent} />
      </Box>
      <Box></Box>
      <Box mt='2.5rem'>
        <Typography>Attachments</Typography>
        <FileUploadGallery
          id={'addedAttachments'}
          data-track='resources-create-activity-attachments-area'
          allowMultiple
          uploadedFiles={uploadedFiles}
          handleFilesAdded={(files: FugFile[]) => filePreprocessor(files).then(handleFileAdded)}
          handleFileRemoved={(file) => {
            handleFileRemoved(file.id);
          }}
        />
      </Box>
    </Box>
  );
}

export const useSaveComment = () => {
  const uri = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  return ({
    content,
    applicationId,
    bulletinId,
    incidentReportId,
    commentId,
    parentId,
    attachments,
  }: {
    content: string;
    attachments?: Array<{
      attachmentId?: string;
      url: string;
      name: string;
      fileType: FileTypeEnumType;
    }>;
    applicationId?: string;
    bulletinId?: string;
    incidentReportId?: string;
    commentId?: string;
    parentId?: string;
  }) =>
    campfireMutation.run({
      url: `${uri}/vm/comment/save`,
      method: 'POST',
      data: {
        content,
        attachments,
        applicationId,
        bulletinId,
        incidentReportId,
        commentId,
        parentId,
      },
    });
};

export const useRemoveComment = () => {
  const uri = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  return ({ commentId }: { commentId: string }) =>
    campfireMutation.run({
      url: `${uri}/vm/comment/remove`,
      method: 'POST',
      data: {
        commentId,
      },
    });
};
