import { Field, FieldProps } from '@campfire/field';
import { HoverLink, HoverText } from '@campfire/hover-link';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import { ErrorOutlineRounded, Check, Close } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { StringParam, useQueryParam } from 'use-query-params';
import { array as YupArray, object, string } from 'yup';
import { AutoFormikButton } from '../../../common/form/AutoFormik';
import { PasswordField } from '../../../common/password-field/PasswordField';
import { Await } from '../../../common/types/Await';
import { AnalyticsService } from '../../../global/analytics/AnalyticsService';
import { useSnackbar } from '../../../global/config/useSnackbar';
import { signInPath } from '../../../global/public-shell/sign-in-path';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { useIsInviteeFetch, useSubmitApplicationFetch, useVerifyApplicationFetch } from './application-screen-actions';
import { ApplicationFormFormik } from './application-screen-model.gql';
import { GetApplicationScreen } from './__generated__/GetApplicationScreen';

const fieldStyleProps: FieldProps = {
  name: '',
  margin: 'normal',
  fullWidth: true,
  variant: 'outlined',
};

const defaultInitialValues = {
  name: '',
  email: '',
  password: '',
  screeningAnswers: [],
};

const useDisabledFieldStyles = makeStyles(() =>
  createStyles({
    root: {
      '& fieldset': {
        borderColor: 'green',
        border: 'none',
      },
    },
  })
);

const applicationFormSchema = object().shape({
  name: string().required('Please enter your name'),
  email: string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  password: string()
    .min(8, 'Please choose a password of at least 8 characters')
    .max(64, 'Please choose a password shorter than 64 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>?`~\\/])[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>?`~\\/]*$/,
      'Invalid password format'
    )
    .required('Please choose a password'),
  screeningQuestions: YupArray().of(
    object().shape({
      screeningQuestionId: string(),
      answer: string(),
    })
  ),
});

interface ApplicationFormProps {
  getResponse: GetApplicationScreen;
  stateController: {
    givenName: string | undefined;
    setGivenName: (value: string | undefined) => void;
    givenEmail: string | undefined;
    setGivenEmail: (value: string | undefined) => void;
    givenPassword: string | undefined;
    setGivenPassword: (value: string | undefined) => void;
    approvalProcessing: boolean | undefined;
    setApprovalProcessing: (value: boolean | undefined) => void;
  };
  inviteParam: string | undefined;
}

type ApplicationScreenFormPage = 'info' | 'screening';

export const ApplicationScreenForm = memo(({ getResponse, stateController, inviteParam }: ApplicationFormProps) => {
  const [recaptchaRef] = useState<React.RefObject<any>>(React.createRef());
  const [page, setPage] = useState<ApplicationScreenFormPage>('info');
  const [emailAlreadyInUse, setEmailAlreadyInUse] = useState(false);
  const { setGivenName, setGivenEmail, setGivenPassword, setApprovalProcessing } = stateController;
  const { setSnackbar } = useSnackbar();
  const emailFieldRef = useRef<HTMLInputElement>(null);
  const [emailField, setEmailField] = useState('');
  const [emailOverride, setEmailOverride] = useState(false);
  const [encodedEmail] = useQueryParam('e', StringParam);

  const decodedEmail = useMemo(() => {
    if (encodedEmail === undefined) return undefined;
    return atob(encodedEmail);
  }, [encodedEmail]);

  useEffect(() => {
    if (emailOverride && emailFieldRef && emailFieldRef.current) {
      emailFieldRef.current.focus();
      emailFieldRef.current.select();
      return;
    }
    if (decodedEmail !== undefined) {
      setEmailField(decodedEmail);
    }
  }, [decodedEmail, emailOverride]);

  const verifyApplicationFetch = useVerifyApplicationFetch();
  const submitApplicationFetch = useSubmitApplicationFetch();
  const isInviteeFetch = useIsInviteeFetch();

  const useStyles = makeStyles({
    radioGroupHorizontal: {
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      flexGrow: 1,
    },
  });

  const classes = useStyles();
  const { isMobile } = useCampfireTheme();

  const hasScreening = useMemo(() => {
    return getResponse.vm.screeningQuestions && getResponse.vm.screeningQuestions.length > 0;
  }, [getResponse]);

  const initialValues = useMemo(() => {
    return {
      ...defaultInitialValues,
      email: getResponse.vm.invitee?.email ?? '',
    };
  }, [getResponse]);

  const isLoading = verifyApplicationFetch.isLoading || submitApplicationFetch.isLoading || isInviteeFetch.isLoading;

  const handleSubmitApplicationWithScreeningQuestions = (values: ApplicationFormFormik) => {
    setGivenName(values.name);
    setGivenEmail(values.email);
    setGivenPassword(values.password);

    const answers = values.screeningAnswers.filter((x) => x);
    const questions = getResponse.vm.screeningQuestions;
    const questionsWithAnswers = getResponse.vm.screeningQuestions.filter(
      (question) => answers.find((answer) => question.screeningQuestionId === answer.screeningQuestionId) !== undefined
    );

    if (questions.length !== questionsWithAnswers.length) {
      setSnackbar({
        open: true,
        message: 'Please ensure you complete all questions',
        variant: 'error',
      });
      return;
    }

    submitApplicationValues(values);
  };

  const handleSubmitApplicationWithoutScreeningQuestions = (values: ApplicationFormFormik) => {
    setGivenName(values.name);
    setGivenEmail(values.email);
    setGivenPassword(values.password);

    submitApplicationValues(values);
  };

  const submitApplicationValues = (values: ApplicationFormFormik) =>
    submitApplicationFetch
      .run({
        name: values.name,
        email: values.email,
        password: values.password,
        screeningAnswers: values.screeningAnswers,
      })
      .then((response) => {
        if (!response.ok) {
          setSnackbar({
            open: true,
            message: 'Network error, please try again later',
            variant: 'error',
          });
        }

        setApprovalProcessing(response.data.data.isApproved);
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Network error, please try again later',
          variant: 'error',
        });
      });

  const submitApplicationForm = async (values: ApplicationFormFormik) => {
    const token = await recaptchaRef.current.executeAsync();

    if (!token) {
      return;
    }

    recaptchaRef.current.reset();

    // All prereq checks have already been performed if we are on page 2
    if (page === 'screening') {
      handleSubmitApplicationWithScreeningQuestions(values);
      return;
    }

    Promise.all<Await<ReturnType<typeof verifyApplicationFetch.run>>, Await<ReturnType<typeof isInviteeFetch.run>>>([
      verifyApplicationFetch.run({
        name: values.name,
        email: values.email,
        password: values.password,
      }),
      isInviteeFetch.run({
        email: values.email,
      }),
    ])
      .then(([verifyApplicationResponse, isInviteeResponse]) => {
        if (verifyApplicationResponse.status === 400) {
          setSnackbar({
            open: true,
            message: 'Application form contains errors',
            variant: 'error',
          });
          return;
        }

        if (verifyApplicationResponse.status === 409) {
          AnalyticsService.trackEvent({ action: 'Failed', label: 'sign up due to email already in use' });
          setEmailAlreadyInUse(true);
          setSnackbar({
            open: true,
            message: 'Email address already in use',
            variant: 'error',
          });
          return;
        }

        // Halt all errors
        if (!verifyApplicationResponse.ok || !isInviteeResponse.ok) {
          setSnackbar({
            open: true,
            message: 'Network error, please try again later',
            variant: 'error',
          });
          return;
        }

        setEmailAlreadyInUse(false);

        const isInvitee =
          getResponse.vm.invitee !== null ||
          !!(
            isInviteeResponse.data.data.inviteeId &&
            isInviteeResponse.data.data.email &&
            isInviteeResponse.data.data.email === values.email
          );

        if (!hasScreening || isInvitee) {
          handleSubmitApplicationWithoutScreeningQuestions(values);
          return;
        }

        setPage('screening');
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Network error, please try again later',
          variant: 'error',
        });
      });
  };

  const hasDecodedEmail = decodedEmail !== undefined;
  const disabledFieldClasses = useDisabledFieldStyles();
  const disableEmailField = hasDecodedEmail && !emailOverride;

  return (
    <>
      <LinearProgressOverlay isLoading={isLoading} />
      <Box marginTop={isMobile ? 3 : 7} marginBottom={8} marginLeft={2} marginRight={2}>
        <Grid container justify='center'>
          <Grid item xs={12} sm={10} style={{ display: 'flex', justifyContent: 'center' }}>
            {getResponse && getResponse.orgLogo !== null ? (
              <img
                src={getResponse.orgLogo}
                alt={getResponse?.orgName}
                style={{
                  height: 65,
                  width: 'auto',
                  display: 'block',
                  alignSelf: 'center',
                  paddingBottom: '15px',
                }}
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={10} style={{ marginBottom: 15 }}>
            <Typography variant='h5' style={{ fontWeight: 'bold' }} align='left'>
              {`You're signing up with ${getResponse.orgName}`}
            </Typography>

            {page === 'info' ? (
              <Typography variant='body2' color='textSecondary'>
                {`Let's create an account`}
              </Typography>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                {'Almost there!'}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={10}>
            <Formik
              initialValues={initialValues}
              validationSchema={applicationFormSchema}
              onSubmit={submitApplicationForm}
              enableReinitialize
            >
              {({ setFieldValue, isValid, dirty, values }) => {
                const regex = new RegExp(
                  '^(?=.*[A-Za-z])(?=.*[0-9!@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>?`~\\/:\\\\])[A-Za-z0-9!@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>?`~\\/:\\\\]*$'
                );
                const isAtLeast8c = values.password && values.password.length >= 8;
                const isPassRegex = regex.test(values.password);

                return (
                  <Form noValidate>
                    <div style={{ display: page === 'info' ? 'block' : 'none' }}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size='invisible'
                        sitekey='6Lez7NsUAAAAAOTVaiFrSnXTvl4XPX7ivvVXzruA'
                      />
                      <Grid container justify='center'>
                        <Grid item xs={12}>
                          <Field
                            {...fieldStyleProps}
                            required
                            autoFocus={inviteParam !== undefined}
                            name='name'
                            label='Name'
                            placeholder='e.g. John Smith'
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Box
                            width={1}
                            display='flex'
                            justifyContent='space-between'
                            alignContent='flex-end'
                            alignItems='flex-end'
                            marginTop={1}
                          >
                            <Box display='inline-block' width={1}>
                              <Field
                                {...fieldStyleProps}
                                slow
                                inputRef={emailFieldRef}
                                classes={disableEmailField ? disabledFieldClasses : undefined}
                                disabled={disableEmailField}
                                value={emailField}
                                error={emailAlreadyInUse}
                                required
                                type='email'
                                name='email'
                                label='Email Address'
                                placeholder='example@example.com'
                                onChange={(event) => setEmailField(event.target.value)}
                              />

                              {emailAlreadyInUse ? (
                                <Box
                                  paddingTop={1}
                                  paddingBottom={2}
                                  display='flex'
                                  alignContent='center'
                                  alignItems='center'
                                >
                                  <ErrorOutlineRounded fontSize='large' color='error'></ErrorOutlineRounded>

                                  <Box paddingLeft={1}>
                                    <Typography variant='body2' color='error'>
                                      This email is already in use.
                                      <br />
                                    </Typography>

                                    <Typography variant='body2' color='error'>
                                      Please sign in to your account{' '}
                                      <HoverLink
                                        invertUnderlineBehaviour
                                        color='textSecondary'
                                        hoverColor='textSecondary'
                                        to={{
                                          pathname: signInPath,
                                          search: `email=${btoa(emailField)}`,
                                        }}
                                      >
                                        here.
                                      </HoverLink>
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : null}
                            </Box>

                            {hasDecodedEmail && disableEmailField && !emailAlreadyInUse ? (
                              <Box display='flex' justifyContent='flex-end' marginBottom={2} minWidth={120}>
                                <HoverText
                                  display='inline'
                                  color='primary'
                                  hoverColor='primary'
                                  variant='body2'
                                  onClick={() => setEmailOverride(true)}
                                >
                                  {'Edit email address'}
                                </HoverText>
                              </Box>
                            ) : null}
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <PasswordField
                            {...fieldStyleProps}
                            name='password'
                            label='Choose a Password'
                            placeholder='Password'
                          />
                          <Box mt={1}>
                            <Box
                              style={{ color: isAtLeast8c ? '#48976C' : '#D93A00' }}
                              display='flex'
                              alignItems='center'
                            >
                              {isAtLeast8c ? <Check /> : <Close />}
                              <Typography
                                style={{ fontSize: '16px', fontWeight: 500, lineHeight: '22.4px', marginLeft: '8px' }}
                              >
                                At least 8 characters
                              </Typography>
                            </Box>
                            <Box
                              style={{ color: isPassRegex ? '#48976C' : '#D93A00' }}
                              display='flex'
                              alignItems='center'
                            >
                              {isPassRegex ? <Check /> : <Close />}
                              <Typography
                                style={{ fontSize: '16px', fontWeight: 500, lineHeight: '22.4px', marginLeft: '8px' }}
                              >
                                Contains letters and a number or symbol
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>

                    <div style={{ display: page === 'screening' ? 'block' : 'none' }}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography variant='h5'>
                            {getResponse.vm.screeningQuestions.length === 1
                              ? `A quick question for you:`
                              : getResponse.vm.screeningQuestions.length === 2
                              ? `A couple of questions for you:`
                              : `A few quick questions:`}
                          </Typography>
                        </Grid>

                        <Grid item container spacing={1} direction='column'>
                          <Grid item>
                            {getResponse.vm.screeningQuestions
                              .sort((a, b) => (a.order < b.order ? -1 : 1))
                              .map((question, questionIndex) => {
                                return (
                                  <Grid
                                    container
                                    key={question.screeningQuestionId}
                                    direction='row'
                                    alignItems='center'
                                    alignContent='center'
                                    justify='center'
                                    style={{ paddingBottom: 16, paddingTop: 16 }}
                                  >
                                    <Grid item xs={6}>
                                      {question.label}
                                    </Grid>
                                    <Grid container item xs={6} justify='flex-end'>
                                      <RadioGroup
                                        className={classes.radioGroupHorizontal}
                                        name={`screeningAnswers[${questionIndex}]`}
                                        onChange={(e) => {
                                          setFieldValue(`screeningAnswers[${questionIndex}]`, {
                                            screeningQuestionId: question.screeningQuestionId,
                                            answer: e.target.value,
                                          });
                                        }}
                                      >
                                        <>
                                          <FormControlLabel
                                            name={`screeningAnswers[${questionIndex}].answer`}
                                            classes={{ label: classes.label }}
                                            value='yes'
                                            control={<Radio size='small' color='primary' />}
                                            label='Yes'
                                            labelPlacement='end'
                                          />
                                          <FormControlLabel
                                            name={`screeningAnswers[${questionIndex}].answer`}
                                            classes={{ label: classes.label }}
                                            value='no'
                                            control={<Radio size='small' color='primary' />}
                                            label='No'
                                            labelPlacement='end'
                                          />
                                        </>
                                      </RadioGroup>
                                    </Grid>
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>

                    <Grid item xs={12} container alignItems='center' justify='center'></Grid>

                    <Grid
                      container
                      alignItems='center'
                      justify={page === 'info' ? 'space-between' : 'flex-end'}
                      style={{ marginTop: 25 }}
                    >
                      <div style={{ display: page === 'info' ? 'block' : 'none' }}>
                        <Grid item>
                          <Typography color='textSecondary' variant='subtitle2'>
                            {'Already have an account? '}
                            {isMobile ? <br /> : null}
                            <HoverLink color='primary' to={signInPath}>
                              {'Sign in'}
                            </HoverLink>
                          </Typography>
                        </Grid>
                      </div>

                      <Grid container item justify='flex-end' style={{ marginTop: 16 }}>
                        <div style={{ display: page === 'screening' ? 'block' : 'none', marginRight: 8 }}>
                          <Grid item>
                            <TabletButton variant='text' size='large' onClick={() => setPage('info')}>
                              Go back
                            </TabletButton>
                          </Grid>
                        </div>

                        <AutoFormikButton setSnackbar={setSnackbar}>
                          <TabletButton
                            id='applicationFormSubmitButton'
                            onClick={() => {
                              AnalyticsService.trackEvent({ action: 'click', label: 'Application form submit' });
                              // eslint-disable-next-line no-undef
                              FS.event('click Application form submit');
                            }}
                            color='primary'
                            variant='contained'
                            size='large'
                            type='submit'
                            disabled={!isValid || !dirty}
                            style={{
                              paddingLeft: 48,
                              paddingRight: 48,
                            }}
                          >
                            {page === 'info' ? `Create Account` : `Submit`}
                          </TabletButton>
                        </AutoFormikButton>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
