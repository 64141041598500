import React from 'react';
import { Box, Switch, Typography, FormControlLabel, Button, Theme } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      flex: 1,
      color: theme.color.grey.neutral400,
      fontWeight: 700,
      fontSize: '18px',
    },
    switch: {
      fontSize: '12px',
      fontWeight: 700,
      color: '#545454',
      paddingTop: '3px',
      paddingLeft: '3px',
      marginRight: '5px',
    },
    button: {
      height: '28px',
      paddingLeft: '12px',
      paddingRight: '12px',
      paddingTop: '10px',
      paddingBottom: '10px',
      alignItems: 'center',
      '& span': {
        textTransform: 'none',
        fontSize: '13px',
        fontWeight: 600,
      },
    },
  })
);

interface TemplateItemHeaderProps {
  title: string;
  enabled: boolean;
  onChange: () => void;
  onSendTest: () => void;
  isOptional: boolean;
}

export function TemplateItemHeader({ title, enabled, onChange, onSendTest, isOptional }: TemplateItemHeaderProps) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant='h5' className={classes.title}>
        {title}
      </Typography>
      <Box>
        <FormControlLabel
          classes={{ label: classes.switch }}
          label={enabled ? `ENABLED` : 'DISABLED'}
          control={
            <Switch checked={enabled} onChange={onChange} color={'primary'} size='small' disabled={!isOptional} />
          }
          disabled={!isOptional}
        />
        <Button
          startIcon={<Email />}
          onClick={onSendTest}
          variant='contained'
          color='primary'
          disableElevation
          disabled={!isOptional}
          className={classes.button}
        >
          Send Test
        </Button>
      </Box>
    </Box>
  );
}
