import { Box, LinearProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { ReactNode } from 'react';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

interface Props {
  title: string;
  description: string | ReactNode;
  action: ReactNode;
  progress?: number;
}

const ActivityEnrolmentPrompt = ({ title, description, action, progress }: Props) => {
  const { isMobile } = useCampfireTheme();
  return (
    <Box
      padding={3}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      paddingBottom={isMobile ? '96px' : 3}
    >
      <Typography align='center' variant='h5' style={{ fontWeight: 'bold' }} gutterBottom>
        {title}
      </Typography>

      {progress ? (
        <Box width={0.8} paddingY={1}>
          <BorderLinearProgress variant='determinate' value={progress} />
        </Box>
      ) : null}

      {typeof description === 'string' ? (
        <Typography variant='body2' color='textSecondary' align='center' gutterBottom>
          {description}
        </Typography>
      ) : (
        description
      )}

      <Box pt={2}>{action}</Box>
    </Box>
  );
};

export { ActivityEnrolmentPrompt };
