import React, { ChangeEvent } from 'react';
import { SessionItem } from './SessionItem';
import { AvailabilityCenterSession } from './__generated__/AvailabilityCenterSession';

interface Props {
  sessions: AvailabilityCenterSession[];
  selectedSessionIds: string[];
  onSelectSession: (sessionIds: string[]) => void;
}

export function SessionSelect({ sessions, selectedSessionIds, onSelectSession }: Props) {
  const handleSelectSession = (event: ChangeEvent<HTMLInputElement>) => {
    const sessionId = event.target.name;
    if (selectedSessionIds.includes(sessionId)) {
      onSelectSession(selectedSessionIds.filter((curentSessionId) => curentSessionId !== sessionId));
    } else {
      onSelectSession(selectedSessionIds.concat(sessionId));
    }
  };

  return (
    <>
      {sessions.map((session) => (
        <SessionItem
          key={session.sessionId}
          session={session}
          isSelected={selectedSessionIds.includes(session.sessionId)}
          onSelect={handleSelectSession}
        />
      ))}
    </>
  );
}
