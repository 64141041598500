import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AppItem } from './AppItem';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import MailchimpLogo from '../../../../../../assets/apps/mailchimp_logo.svg';
import { ReactComponent as MailchimpIcon } from '../../../../../../assets/apps/mailchimp_icon.svg';
import SalesforceLogo from '../../../../../../assets/apps/salesforce_logo.svg';
import { ReactComponent as SalesforceIcon } from '../../../../../../assets/apps/salesforce_icon.svg';
import { ReactComponent as CampaignMonitorIcon } from '../../../../../../assets/apps/campaign_monitor.svg';
import CampaignMonitorLogo from '../../../../../../assets/apps/campaign_monitor_logo.svg';
import { useCommonStyles } from './commonStyles';
import { INTEGRATION_APPS } from './admin-console-apps.gql';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { GetIntegrationAppsType } from './__generated__/GetIntegrationAppsType';
import { App } from './AppTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontWeight: 400,
      color: theme.color.grey.neutral200,
      fontSize: '0.875rem',
    },
    footer: {
      padding: '2.375rem',
    },
  })
);

export type APP_OPTIONS_TYPE = {
  id: string;
  name: string;
  logo: string;
  description: string;
  integrationDescription: string;
  disabled?: boolean;
  icon: React.SFC<React.SVGProps<SVGSVGElement>>;
};

export const APP_OPTIONS = [
  {
    id: 'mailchimp',
    name: 'Mailchimp',
    logo: MailchimpLogo,
    description:
      'Mailchimp is an all-in-one marketing platform that helps you manage and talk to your clients, customers, and other interested parties.',
    integrationDescription:
      'Integrating with Mailchimp will allow Volaby to import and update volunteers into your audience, with options to segment audiences by programs and activities.',
    icon: MailchimpIcon,
  },
  {
    id: 'salesforce',
    name: 'Salesforce',
    logo: SalesforceLogo,
    description: `You haven't setup any integrations yet.`,
    integrationDescription: '',
    icon: SalesforceIcon,
    disabled: true,
  },
  {
    id: 'campaign-monitor',
    name: 'Campaign Monitor',
    logo: CampaignMonitorLogo,
    description: `You haven't setup any integrations yet.`,
    integrationDescription: '',
    icon: CampaignMonitorIcon,
  },
];

const APP_OPTIONS_MAP = {
  mailchimp: {
    id: 'mailchimp',
    name: 'Mailchimp',
    logo: MailchimpLogo,
    description:
      'Mailchimp is an all-in-one marketing platform that helps you manage and talk to your clients, customers, and other interested parties.',
    integrationDescription:
      'Integrating with Mailchimp will allow Volaby to import and update volunteers into your audience, with options to segment audiences by programs and activities.',
    icon: MailchimpIcon,
  },
  salesforce: {
    id: 'salesforce',
    name: 'Salesforce',
    logo: SalesforceLogo,
    description: `You haven't setup any integrations yet.`,
    integrationDescription: '',
    icon: SalesforceIcon,
    disabled: true,
  },
  // eslint-disable-next-line
  campaign_monitor: {
    id: 'campaign-monitor',
    name: 'Campaign Monitor',
    logo: CampaignMonitorLogo,
    description: `You haven't setup any integrations yet.`,
    integrationDescription: '',
    icon: CampaignMonitorIcon,
  },
} as { [app: string]: APP_OPTIONS_TYPE };

const NoIntegrationPrompt = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.text}>You haven&apos;t setup any integrations yet.</Typography>
      <Typography className={classes.text}>
        Integrations setup by your organisation will appear here. Get started below.
      </Typography>
    </>
  );
};

export const AdminConsoleApps = () => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();

  const { data } = useCampfireQuery<GetIntegrationAppsType, undefined>(INTEGRATION_APPS);
  const integrationApps = data?.wm?.integrationApps || [];

  const { theme } = useCampfireTheme();
  const integrationAppsMap = integrationApps.reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {});
  const apps = (data?.wm?.availableApps || []).map(
    (app) => ({ ...APP_OPTIONS_MAP[app], active: app in integrationAppsMap } as App)
  );
  const activeApps = apps.filter((app) => app.active);
  const hasNoIntegrations = activeApps.length === 0;

  return (
    <Box className={commonStyles.container}>
      <Box className={commonStyles.heading}>
        <Typography variant='h2' className={commonStyles.headingTitle}>
          Integrations
        </Typography>
      </Box>
      <Box className={commonStyles.containerPanel}>
        <Typography variant='h2' className={commonStyles.headingTitle}>
          Your Apps
        </Typography>
        {hasNoIntegrations ? <NoIntegrationPrompt /> : activeApps.map((app) => <AppItem app={app} key={app.id} />)}
      </Box>
      <Box className={commonStyles.containerPanel}>
        <Typography variant='h2' className={commonStyles.headingTitle}>
          Available Apps
        </Typography>

        <Typography
          className={classes.text}
          style={{ paddingBottom: '1.75rem', borderBottom: `1px solid ${theme.color.grey.border}` }}
        >
          {hasNoIntegrations
            ? `You haven't setup any integrations yet. Click 'Setup' below to get started.`
            : 'Volaby offers integration with the following applications'}
        </Typography>
        {apps
          .filter((app) => !app.active)
          .map((app) => (
            <AppItem app={app} />
          ))}
        <Box className={classes.footer}>
          <Typography className={classes.text} style={{ textAlign: 'center', paddingTop: '1em' }}>
            There&apos;s more to come! We&apos;ll be adding integration support for more apps in the near future. Get in
            contact if there&apos;s something you&apos;d like to see.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
