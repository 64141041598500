import React from 'react';
import { Box, Icon, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

interface EmptyWidgetProps {
  onClick: () => void;
}

export function EmptyWidget({ onClick }: EmptyWidgetProps) {
  return (
    <Box
      onClick={onClick}
      borderRadius={6}
      border='1px dashed #979797'
      display='flex'
      justifyContent='center'
      alignItems='center'
      style={{ cursor: 'pointer' }}
      marginTop='2rem'
    >
      <Box display='flex' alignItems='center' paddingTop='1rem' paddingBottom='1rem'>
        <Icon>
          <Add />
        </Icon>
        <Typography style={{ fontSize: '0.75rem', color: 'background: #00000040' }}>Add new widget</Typography>
      </Box>
    </Box>
  );
}
