import { AxiosResponse } from 'axios';

export interface CampfireResponse<T> {
  data: T;
  message: string;
  errors: any;
  shouldRefreshIdentity: boolean | undefined;
  campfireVersion: string;
}

export const unpackHttpResponse = async <T>(
  httpResponse: AxiosResponse<CampfireResponse<T>>
): Promise<CampfireResponse<T>> => {
  const { data: responseData } = httpResponse;
  const { data, message, errors, shouldRefreshIdentity, campfireVersion } = responseData;

  if (data === undefined || message === undefined || errors === undefined) {
    throw new Error('Out of shape');
  }

  return {
    data,
    message,
    errors,
    shouldRefreshIdentity: shouldRefreshIdentity || false,
    campfireVersion
  };
};
