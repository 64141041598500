import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
  },
});

export const AttachmentFieldPreview = React.memo(() => {
  const classes = useStyles();
  return (
    <TextField
      disabled
      placeholder='Attach a file from your computer'
      fullWidth
      InputProps={{
        startAdornment: <Add fontSize='small' />,
        disableUnderline: true,
        classes: {
          root: classes.root,
        },
      }}
    />
  );
});
