import { unpackToDateTime } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { Box, Divider, IconButton, Typography } from '@material-ui/core';
import { GetApp, MoreHoriz } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { capitalize } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { FullscreenDialog } from '../../../common/dialogs/FullscreenDialog';
import { FullPageMessage } from '../../../common/FullPageMessage';
import { useUser } from '../../../global/auth/useUser';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { Avatar } from '../../general/user-profile/components/Avatar';
import { VolunteerCommonProfileActionMenu } from './profile-sections/action-menu/VolunteerCommonProfileActionMenu';
import { VolunteerActivityEnrolmentsSection } from './profile-sections/activity-enrolment-section/VolunteerActivityEnrolmentsSection';
import { VolunteerPersonalDetail } from './profile-sections/personal-detail-section/VolunteerPersonalDetail';
import { VolunteerAdditionalTasksSection } from './profile-sections/tasks-section/VolunteerAdditionalTasksSection';
import { VolunteerTasksSection } from './profile-sections/tasks-section/VolunteerTasksSection';
import { VolunteerActivityApplicationsSection } from './profile-sections/VolunteerActivityApplicationsSection';
import { VolunteerActivityWaitlistingsSection } from './profile-sections/VolunteerActivityWaitlistingsSection';
import { VolunteerProgramsSection } from './profile-sections/VolunteerProgramsSection';
import { VolunteerCommunicationsSection } from './profile-sections/communications-section/VolunteerCommunicationsSection';
import { VolunteerProfileActions } from './volunteer-profile-actions';
import { VolunteerCommonProfileProvider, useVolunteerCommonProfileContext } from './VolunteerCommonProfileContext';
import { GetVolunteerCommonProfile_vm_volunteer_rosterings_smsLogs as SmsLogType } from './__generated__/GetVolunteerCommonProfile';
import { VolunteerCommonProfile } from './__generated__/VolunteerCommonProfile';
import { VolunteerImpactSection } from './profile-sections/VolunteerImpactSection';

type VolunteerCommonProfileDialogProps = {
  open: boolean;
  onClose: () => void;
  profileId: string;
  userId: string;
  updateVolunteerOnProfileActions?: VolunteerProfileActions;
};

export interface SmsLogTypeParsed extends SmsLogType {
  status: string;
}

const AVATAR_RADIUS_DESKTOP = 108;
const AVATAR_RADIUS_MOBILE = 88;

export const VolunteerCommonProfileDialogInner = ({
  open,
  onClose,
  profileId,
  updateVolunteerOnProfileActions,
}: VolunteerCommonProfileDialogProps) => {
  const { isMobile, isSm } = useCampfireTheme();
  const {
    unsavedComments,
    setShowUnsavedCommentsDialog,
    setProfileId,
    setUserId,
    setVolunteerNameOptions,
    setUpdateParentVolunteerActions,
    emails,
    smsLogs,
    hasMore,
    completedTasks,
    volunteer,
    reloadNewData,
    onLoadMore,
    error,
  } = useVolunteerCommonProfileContext();

  useEffect(() => {
    if (!volunteer) return;
    setProfileId(volunteer.profile.profileId);
    setUserId(volunteer.profile.userId);
    setVolunteerNameOptions({
      fullName: `${volunteer.profile.preferredName} ${volunteer.profile.lastName}`,
      preferredName: volunteer.profile.preferredName,
    });
  }, [volunteer]);

  useEffect(() => {
    if (!updateVolunteerOnProfileActions) return;
    setUpdateParentVolunteerActions(updateVolunteerOnProfileActions);
  }, [updateVolunteerOnProfileActions]);

  const handleClose = () => {
    if (unsavedComments) {
      setShowUnsavedCommentsDialog(true);
      return;
    }
    onClose();
  };

  const {
    user: { userIdentityService },
  } = useUser();

  const activityEnrolments = useMemo(() => {
    if (!volunteer) return [];
    if (
      userIdentityService.isActivityLeader &&
      (!userIdentityService.isProgramManager || userIdentityService.isVmAdmin)
    ) {
      return volunteer.activityEnrolments.filter((activityEnrolment) =>
        userIdentityService.isLeaderOfActivity(activityEnrolment.activity.activityId)
      );
    }
    return volunteer.activityEnrolments;
  }, [userIdentityService, volunteer, profileId]);

  const filteredAdditionalTasks = volunteer?.tasks.filter(
    (task) => !volunteer?.profile.completedTasks.find((ct) => ct.task.taskId === task.taskId) && !task.dateRemoved
  );

  const isLoading = !volunteer;

  return (
    <FullscreenDialog
      scroll='paper'
      open={open}
      close={handleClose}
      title='Volunteer Details'
      fullScreen={isMobile}
      PaperProps={
        isLoading ? { style: { height: '100%' } } : { style: { minWidth: isSm ? 'auto' : '640px', maxWidth: '640px' } }
      }
    >
      {error ? (
        <FullPageMessage title='Network Error' subtitle='Unable to load volunteer profile.' />
      ) : isLoading ? (
        <VolunteerCommonProfileDialogSkeleton />
      ) : (
        <>
          {volunteer && (
            <>
              <Box display='flex' alignItems='center' justifyContent='flex-end'>
                {volunteer && <VolunteerCommonProfileActionMenu volunteer={volunteer} />}
              </Box>
              <VolunteerAvatarInfo volunteer={volunteer} />
              <Divider />
              <Box paddingX={2}>
                <VolunteerPersonalDetail volunteer={volunteer} onClose={onClose} refetch={reloadNewData} />
              </Box>
              <Divider />
              {userIdentityService.isProgramManager || userIdentityService.isVmAdmin ? (
                <VolunteerTasksSection
                  completedTasks={completedTasks}
                  userId={volunteer.profile.userId ?? ''}
                  profileId={volunteer.profile.profileId}
                  hasMore={hasMore}
                  loadMore={onLoadMore}
                  refetch={reloadNewData}
                />
              ) : null}
              {userIdentityService.isProgramManager || userIdentityService.isVmAdmin ? (
                <VolunteerAdditionalTasksSection
                  tasks={filteredAdditionalTasks ?? []}
                  userId={volunteer.profile.userId ?? ''}
                  profileId={volunteer.profile.profileId}
                  refetch={reloadNewData}
                />
              ) : null}
              {volunteer.programs.length ? <VolunteerProgramsSection programs={volunteer.programs} /> : null}
              {volunteer.activityApplications.length ? (
                <VolunteerActivityApplicationsSection activityApplications={volunteer.activityApplications} />
              ) : null}
              {volunteer.activityWaitlistings.length ? (
                <VolunteerActivityWaitlistingsSection activityWaitlistings={volunteer.activityWaitlistings} />
              ) : null}
              {activityEnrolments.length ? (
                <VolunteerActivityEnrolmentsSection
                  activityEnrolments={activityEnrolments}
                  volunteerId={volunteer.volunteerId}
                  profileId={volunteer.profile.profileId}
                />
              ) : null}
              {emails.length || smsLogs.length ? (
                <VolunteerCommunicationsSection emails={emails} smsLogs={smsLogs} />
              ) : null}
              <VolunteerImpactSection />
            </>
          )}
        </>
      )}
    </FullscreenDialog>
  );
};

export const VolunteerCommonProfileDialog = (props: VolunteerCommonProfileDialogProps) => (
  <VolunteerCommonProfileProvider userId={props.userId} profileId={props.profileId}>
    <VolunteerCommonProfileDialogInner {...props} />
  </VolunteerCommonProfileProvider>
);

const VolunteerAvatarInfo = ({ volunteer }: { volunteer: VolunteerCommonProfile }) => {
  const {
    profile: { firstName, lastName, preferredName, email, avatarUrl, contactNumber, importOperation },
  } = volunteer;

  const { isMobile } = useCampfireTheme();

  const managerName = importOperation
    ? `${importOperation.importedBy.profile.firstName} ${importOperation.importedBy.profile.lastName}`
    : null;
  const importDate = importOperation
    ? unpackToDateTime(importOperation.dateCreated).toLocaleString({
        year: 'numeric',
        weekday: 'long',
        month: 'long',
        day: '2-digit',
      })
    : null;

  return (
    <Box
      display='flex'
      flexDirection='row'
      alignContent='center'
      alignItems='center'
      justifyContent='center'
      px={2}
      paddingBottom={isMobile ? '16px' : '32px'}
    >
      <Avatar
        preferredName={preferredName}
        lastName={lastName}
        size={isMobile ? AVATAR_RADIUS_MOBILE : AVATAR_RADIUS_DESKTOP}
        avatarUrl={avatarUrl}
      />
      <Box px={2}>
        <Typography variant='body1' style={{ fontWeight: 600 }}>{`${capitalize(firstName)} ${capitalize(
          lastName
        )} (${capitalize(preferredName)})`}</Typography>
        {importOperation && (
          <HoverText title={`Imported by ${managerName} on ${importDate}`} disableUnderline>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: '#0000008a',
                alignItems: 'center',
                paddingBottom: '8px',
              }}
            >
              <GetApp style={{ fontSize: '12px' }}></GetApp>
              <Typography style={{ fontSize: '11px', paddingLeft: '2px' }}>Imported Volunteer</Typography>
            </Box>
          </HoverText>
        )}
        {email && <Typography variant='body2'>{email}</Typography>}

        {contactNumber && <Typography variant='body2'>{contactNumber}</Typography>}
      </Box>
    </Box>
  );
};

const VolunteerCommonProfileDialogSkeleton = () => {
  const { isMobile } = useCampfireTheme();

  return (
    <Box minWidth={!isMobile ? 500 : null} minHeight={1}>
      <Box display='flex' justifyContent='flex-end'>
        <IconButton disabled>
          <MoreHoriz />
        </IconButton>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        alignContent='center'
        alignItems='center'
        justifyContent='center'
        px={2}
        paddingBottom='16px'
      >
        <Box px={1}>
          <Skeleton
            variant='circle'
            width={isMobile ? AVATAR_RADIUS_MOBILE : AVATAR_RADIUS_DESKTOP}
            height={isMobile ? AVATAR_RADIUS_MOBILE : AVATAR_RADIUS_DESKTOP}
          />
        </Box>
        <Box px={2} width='50%'>
          <Skeleton variant='text' width='100%' height={10} />
          <Skeleton variant='text' width='80%' height={7} />
          <Skeleton variant='text' width='60%' height={7} />
        </Box>
      </Box>

      <Box paddingLeft={3}>
        <Box p={2}>
          <Skeleton variant='text' width='30%' height={15} />
        </Box>
        <Box px={2} py={0.5}>
          <Skeleton variant='text' width='50%' height={7} />
          <Skeleton variant='text' width='45%' height={12} />
        </Box>
        <Box px={2} py={0.5}>
          <Skeleton variant='text' width='70%' height={7} />
          <Skeleton variant='text' width='65%' height={12} />
        </Box>
        <Box px={2} py={0.5}>
          <Skeleton variant='text' width='50%' height={7} />
          <Skeleton variant='text' width='45%' height={12} />
        </Box>
        <Box px={2} py={0.5}>
          <Skeleton variant='text' width='30%' height={7} />
          <Skeleton variant='text' width='25%' height={12} />
        </Box>
      </Box>
    </Box>
  );
};
