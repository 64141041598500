import React from 'react';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

const MobilePublicAppBar = React.lazy(() => import('./MobilePublicAppBar'));
const DesktopPublicAppBar = React.lazy(() => import('./DesktopPublicAppBar'));

const PublicAppBar = () => {
  const { isMobile } = useCampfireTheme();
  return isMobile ? <MobilePublicAppBar /> : <DesktopPublicAppBar />;
};

export { PublicAppBar };
