import { Box, Switch, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import React from 'react';
import { PreferenceFormValues } from '../AdminConsoleCommunicationPreferences';

export const SmsConfigSwitch = ({ isLoading }: { isLoading: boolean }) => {
  const { values, setFieldValue } = useFormikContext<PreferenceFormValues>();

  return (
    <Box display='flex'>
      {isLoading ? (
        <Skeleton
          variant='rect'
          width='55px'
          height='15px'
          style={{ borderRadius: '30%', marginRight: '12px', marginLeft: '12px' }}
        />
      ) : (
        <>
          <Switch
            checked={values.enableSms}
            onChange={() => setFieldValue('enableSms', !values.enableSms)}
            color='primary'
            name='pinned'
            inputProps={{ 'aria-label': 'sms communication preference switch' }}
          />
          <Box ml={1}>
            <Typography
              style={{
                fontSize: '19px',
                color: '#000000b0',
                fontWeight: 500,
                textTransform: 'uppercase',
                fontVariant: 'all-small-caps',
              }}
            >
              SMS
            </Typography>
            <Typography style={{ fontSize: '13px', color: '#808080' }}>
              Volaby communicates with volunteers via SMS to confirm roster attendances
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
