import { useMemo } from 'react';
import { User } from './SessionContext';
import { useSession } from './useSession';
import { UserIdentity } from './User';
import { ManagerIdentity } from './__generated__/ManagerIdentity';
import { VolunteerIdentity } from './__generated__/VolunteerIdentity';

interface UseUserApi {
  user: User;
  userIdentity: UserIdentity;
  getManagerIdentity: () => ManagerIdentity;
  getVolunteerIdentity: () => VolunteerIdentity;
  maybeManagerIdentity: ManagerIdentity | null;
  maybeVolunteerIdentity: VolunteerIdentity | null;
}

export const useUser = (): UseUserApi => {
  const { user } = useSession();

  if (!user) {
    throw new Error('Attempted to access non-existent User');
  }

  if (!user.userIdentity) {
    throw new Error('Attempted to access non-existent User Identity');
  }

  const { userIdentity } = user;

  const getManagerIdentity = () => {
    const managerIdentity = getMaybeManagerIdentity();
    if (!managerIdentity) throw new Error('Failed to optimistically getManagerIdentity');
    return managerIdentity;
  };
  const getVolunteerIdentity = () => {
    const volunteerIdentity = getMaybeVolunteerIdentity();
    if (!volunteerIdentity) throw new Error('Failed to optimistically getVolunteerIdentity');
    return volunteerIdentity;
  };
  const getMaybeManagerIdentity = () => {
    return userIdentity.vm ? userIdentity.vm.managerIdentity : null;
  };
  const getMaybeVolunteerIdentity = () => {
    return userIdentity.vm ? userIdentity.vm.volunteerIdentity : null;
  };

  return useMemo(
    () => ({
      user,
      userIdentity,
      getManagerIdentity: getManagerIdentity,
      getVolunteerIdentity: getVolunteerIdentity,
      maybeManagerIdentity: getMaybeManagerIdentity(),
      maybeVolunteerIdentity: getMaybeVolunteerIdentity(),
    }),
    [user, userIdentity]
  );
};
