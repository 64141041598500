import { gql } from '@apollo/client';

export const GET_MY_ROSTER_CHECKOUT = gql`
  query GetMyRosterCheckoutVolunteers($activityId: String!, $activityDate: Date!) {
    vm {
      activityReport(activityId: $activityId, activityDate: $activityDate) {
        activity {
          sessions {
            sessionId
            name
            reportType {
              reportTypeId
            }
            autoReport
          }
        }
        sessionReports {
          CICOs {
            checkIn
            checkOut
            volunteer {
              volunteerId
              profile {
                profileId
                userId
                firstName
                lastName
                preferredName
                avatarUrl
              }
              checkedIn {
                CICOId
                checkIn
                sessionReport {
                  activityReport {
                    activityDate
                  }
                  session {
                    sessionId
                    name
                    reportType {
                      reportTypeId
                    }
                    autoReport
                  }
                }
              }
              isCheckedIn
            }
          }
        }
      }
    }
  }
`;
