import { FugFile } from '@campfire/file-upload-gallery/lib';
import { encodeDate, encodeTime } from '@campfire/hot-date';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box, DialogContent, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { memo, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { array as YupArray, date as YupDate, object as YupObject, string as YupString } from 'yup';
import { FormikErrorFocus } from '../../../../common/form/FormikErrorFocus';
import { arrayHead } from '../../../../common/functions/array-head';
import { assertNever } from '../../../../common/functions/assert-never';
import { Recurrence } from '../../../../common/recurrence/__generated__/Recurrence';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { RecurrenceEnum } from '../../../../__generated__/globalTypes';
import { useSaveActivityFetch, NO_REPORT_NO_IMPACT, NO_REPORT_IMPACT_TRACKED } from './activity-form-actions-v2';
import { ActivityAttachmentV2 } from './__generated__/ActivityAttachmentV2';
import { CREATE_ACTIVITY_GET_PROGRAMS_V2, GET_ACTIVITY_MANAGEMENT_ACTIVITY_TAGS } from './activity-form-model-v2.gql';
import { ConfirmEditActivityDialogV2 } from './ConfirmEditActivityDialogV2';
import { CreateBaseActivityFormV2 } from './CreateBaseActivityFormV2';
import {
  CreateActivityGetProgramsV2,
  CreateActivityGetProgramsV2Variables,
} from './__generated__/CreateActivityGetProgramsV2';
import { StickyHeaderV2 } from '../common-v2/StickyHeaderV2';
import { DailyRecurrence } from '../../../../common/recurrence/__generated__/DailyRecurrence';
import { GetActivityManagementActivityTags } from './__generated__/GetActivityManagementActivityTags';

const validateFileSize = (files?: FugFile[]) => {
  let valid = true;
  if (files)
    files.forEach((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 10) valid = false;
      return 0;
    });
  return valid;
};

const validationSchema = YupObject().shape({
  activityId: YupString(),
  activityName: YupString().required('Please provide a name'),
  activityDescription: YupString(),
  programId: YupString().required('Please select a program'),
  startDate: YupDate()
    .nullable()
    .typeError('Please select a date'),
  endDate: YupDate()
    .nullable()
    .typeError('Please select a date'),
  frequency: YupString()
    .nullable()
    .typeError('Please select one'),
  addedAttachments: YupArray().test('valid-file-size', 'Max file size 10MB', validateFileSize),
  sessions: YupArray().required('At least 1 session is required'),
});

export interface ActivityFormSession {
  sessionId?: string;
  name: string;
  description: string;
  minVolunteers?: number;
  maxVolunteers?: number;
  startTime: Date | null;
  endTime: Date | null;
  reportType: {
    name: string;
    reportTypeId: string | null;
  };
  activityLocation?: ActivityFormActivityLocation;
  autoReport?: boolean;
}

interface ActivityFormActivityLocation {
  activityLocationId?: string;
  description: string;
  formatted: string;
  latitude: number;
  longitude: number;
  placesId?: string;
}

export interface ActivityFormValues {
  activityId?: string;
  activityName: string;
  activityDescription: string;
  isRestrictedActivity: boolean;
  isHidden: boolean;
  hasOpenRoster: boolean;
  maxTeam: number | null;
  allowCICO: boolean | null;
  programId: string;
  startDate: Date | null;
  endDate: Date | null;
  frequency: ActivityFormRecurrenceFrequency;
  locationType: string;
  remoteLocation: {
    remoteActivityLocationId?: string;
    details: string;
  } | null;
  location: ActivityFormActivityLocation | null;
  addedAttachments: FugFile[];
  creationTokens: string[];
  removedAttachmentIds: string[];
  sessions: ActivityFormSession[];
  activityTagIds: string[];
  recurrence: Recurrence;
}

export type RecurrenceFrequency =
  | 'no-repeat'
  | 'daily'
  | 'weekly'
  | 'weekdays'
  | 'weekends'
  | 'fortnightly'
  | 'monthly-month-day'
  | 'monthly-nth-day'
  | 'yearly'
  | 'custom';

export type ActivityFormRecurrenceFrequency = RecurrenceFrequency | undefined;

const WEEKDAYS = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
export const parseRecurrence = (values: ActivityFormValues): Recurrence | undefined => {
  const startDate = values.startDate ? DateTime.fromJSDate(values.startDate) : DateTime.local();
  const endDate = values.endDate ? DateTime.fromJSDate(values.endDate) : null;

  const baseRecurrence = {
    startDate: encodeDate(startDate),
    endDate: endDate ? encodeDate(endDate) : null,
    humanReadable: '', // This is a dummy so we can reuse autogenerated type
  };
  if (!values.frequency) return undefined;

  if (values.frequency === 'no-repeat') {
    return {
      ...baseRecurrence,
      __typename: 'SingleRecurrenceType',
    };
  }

  if (values.frequency === 'daily') {
    return {
      ...baseRecurrence,
      __typename: 'DailyRecurrenceType',
      dayInterval: 1,
    };
  }

  if (values.frequency === 'weekly') {
    const weekday = WEEKDAYS[startDate.weekday - 1] as RecurrenceEnum;
    return {
      ...baseRecurrence,
      __typename: 'WeeklyRecurrenceType',
      days: [weekday],
      weekInterval: 1,
    };
  }

  if (values.frequency === 'weekdays') {
    const weekdays = WEEKDAYS.slice(0, 5) as RecurrenceEnum[];
    return {
      ...baseRecurrence,
      __typename: 'WeeklyRecurrenceType',
      days: weekdays,
      weekInterval: 1,
    };
  }

  if (values.frequency === 'weekends') {
    const weekdays = WEEKDAYS.slice(5, 7) as RecurrenceEnum[];
    return {
      ...baseRecurrence,
      __typename: 'WeeklyRecurrenceType',
      days: weekdays,
      weekInterval: 1,
    };
  }

  if (values.frequency === 'fortnightly') {
    const weekday = WEEKDAYS[startDate.weekday - 1] as RecurrenceEnum;
    return {
      ...baseRecurrence,
      __typename: 'WeeklyRecurrenceType',
      days: [weekday],
      weekInterval: 2,
    };
  }

  if (values.frequency === 'monthly-month-day') {
    const monthDay = startDate.day;
    return {
      ...baseRecurrence,
      __typename: 'MonthlyByMonthDayRecurrenceType',
      monthDay,
      monthInterval: 1,
    };
  }

  if (values.frequency === 'monthly-nth-day') {
    const n = Math.ceil(startDate.day / 7);
    const weekday = WEEKDAYS[startDate.weekday - 1] as RecurrenceEnum;
    return {
      ...baseRecurrence,
      __typename: 'MonthlyNthDayRecurrenceType',
      n,
      day: weekday,
      monthInterval: 1,
    };
  }

  if (values.frequency === 'yearly') {
    return {
      ...baseRecurrence,
      __typename: 'YearlyRecurrenceType',
      yearInterval: 1,
    };
  }

  if (values.frequency === 'custom') {
    return values.recurrence;
  }

  return assertNever(values.frequency);
};

const DEFAULT_RETURN_SLUG = '/management/activity-management';

const ActivityFormV2 = memo(
  ({
    predefinedFormValues,
    baseActivityId,
    isEditing,
    attachments,
    activityType,
  }: {
    baseActivityId?: string;
    predefinedFormValues?: Partial<ActivityFormValues>;
    isEditing?: boolean;
    attachments?: ActivityAttachmentV2[];
    activityType?: string;
  }) => {
    const [returnSlug] = useQueryParam('return', StringParam);
    const [selectedProgramId] = useQueryParam('programId', StringParam);
    const history = useHistory();
    const location = useLocation();
    const { setSnackbar } = useSnackbar();

    const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);
    const { data: programsData, loading: programsDataLoading } = useCampfireQuery<
      CreateActivityGetProgramsV2,
      CreateActivityGetProgramsV2Variables
    >(CREATE_ACTIVITY_GET_PROGRAMS_V2, { options: { variables: { activityId: baseActivityId ?? '' } } });

    const { data: tagsData } = useCampfireQuery<GetActivityManagementActivityTags, {}>(
      GET_ACTIVITY_MANAGEMENT_ACTIVITY_TAGS
    );
    const getReturnPath = (givenActivityId?: string) => {
      const base = returnSlug ?? DEFAULT_RETURN_SLUG;
      const activityId = givenActivityId ?? baseActivityId;
      const alreadyHasParams = base.includes('?');

      const activityIdParam = activityId ? `activityId=${activityId}` : '';
      const programIdParam = selectedProgramId ? `programId=${selectedProgramId}` : '';

      const extraParams = alreadyHasParams
        ? `&${activityIdParam}&${programIdParam}`
        : `?${activityIdParam}&${programIdParam}`;

      return `${base}${extraParams}`;
    };

    const programs = useMemo(() => {
      if (baseActivityId !== undefined && !location.pathname.includes('duplicate')) {
        const program = programsData?.vm?.activity?.program;
        return program !== null && program !== undefined ? [program] : [];
      }
      return programsData?.vm.programs ?? [];
    }, [programsData, baseActivityId]);

    const activityTags = useMemo(() => {
      return tagsData?.vm.activityTags ?? [];
    }, [tagsData]);

    const saveActivity = useSaveActivityFetch();
    const runSaveActivity = ({ values }: { values: ActivityFormValues }) => {
      const startDate = values.startDate ? DateTime.fromJSDate(values.startDate) : DateTime.local();
      const endDate = values.endDate ? DateTime.fromJSDate(values.endDate) : undefined;
      const recurrence =
        values.frequency === 'custom' ? values.recurrence : values.frequency ? parseRecurrence(values) : undefined;
      const activityLocation = values.location
        ? {
            activityLocationId: values.location.activityLocationId,
            placesAddress: values.location,
            timeZone: startDate.zone.name,
            comments: '',
          }
        : null;

      const sessions = values.sessions.map((session: ActivityFormSession) => ({
        sessionId: session.sessionId || undefined,
        name: session.name,
        description: session.description,
        minVolunteers: session.minVolunteers,
        maxVolunteers: session.maxVolunteers || undefined,
        startTime: session.startTime ? encodeTime(DateTime.fromJSDate(session.startTime)) : null,
        endTime: session.endTime ? encodeTime(DateTime.fromJSDate(session.endTime)) : null,
        reportTypeId:
          session.reportType.reportTypeId === NO_REPORT_IMPACT_TRACKED ||
          session.reportType.reportTypeId === NO_REPORT_NO_IMPACT
            ? null
            : session.reportType.reportTypeId,
        activityLocation: session.activityLocation
          ? {
              activityLocationId: session.activityLocation.activityLocationId,
              placesAddress: session.activityLocation,
              timeZone: startDate.zone.name,
              comments: '',
            }
          : null,
        autoReport: Boolean(session.autoReport),
      }));
      saveActivity
        .run({
          activityId: values.activityId !== '' ? values.activityId : undefined,
          isRestrictedActivity: values.isRestrictedActivity,
          isHidden: values.isHidden,
          hasOpenRoster: values.hasOpenRoster,
          maxTeam: values.maxTeam,
          allowCICO: values.allowCICO,
          programId: values.programId,
          name: values.activityName,
          description: JSON.stringify(values.activityDescription),
          startDate: encodeDate(startDate),
          endDate: endDate ? encodeDate(endDate) : undefined,
          activityLocation: values.locationType === 'local' ? activityLocation : null,
          remoteLocation: values.locationType === 'remote' ? values.remoteLocation ?? undefined : undefined,
          recurrences: recurrence ? [recurrence] : undefined,
          sessions: sessions,
          addedAttachments: values.addedAttachments,
          attachmentCreationTokens: values.creationTokens,
          removedAttachmentIds: values.removedAttachmentIds,
          activityTagIds: values.activityTagIds,
        })
        .then((res) => {
          if (res.ok) {
            history.replace(getReturnPath(res.data.data.activityId));

            setSnackbar({
              open: true,
              message: 'Activity saved',
              variant: 'success',
            });

            return;
          }

          setSnackbar({
            open: true,
            message: 'Unable to save activity',
            variant: 'error',
          });
        })
        .catch(() =>
          setSnackbar({
            open: true,
            message: 'Unable to save activity',
            variant: 'error',
          })
        );
    };

    return programsData ? (
      <Formik
        initialValues={{
          activityId: predefinedFormValues?.activityId ?? '',
          activityName: predefinedFormValues?.activityName ?? '',
          activityDescription: predefinedFormValues?.activityDescription ?? '',
          isRestrictedActivity: predefinedFormValues?.isRestrictedActivity ?? true,
          isHidden: predefinedFormValues?.isHidden ?? false,
          maxTeam: predefinedFormValues?.maxTeam ?? null,
          hasOpenRoster: predefinedFormValues?.hasOpenRoster ?? false,
          allowCICO: predefinedFormValues?.allowCICO ?? null,
          programId:
            selectedProgramId && selectedProgramId !== 'all'
              ? selectedProgramId
              : predefinedFormValues?.programId ??
                (programsData?.vm.programs ? arrayHead(programsData.vm.programs)?.programId ?? '' : ''),
          startDate: predefinedFormValues?.startDate ?? null,
          endDate: predefinedFormValues?.endDate ? predefinedFormValues.endDate : null,
          frequency: predefinedFormValues?.frequency,
          locationType: predefinedFormValues?.locationType ?? 'local',
          remoteLocation: {
            remoteLocationId: predefinedFormValues?.remoteLocation?.remoteActivityLocationId ?? undefined,
            details: predefinedFormValues?.remoteLocation?.details ?? '',
          },
          location: {
            activityLocationId: predefinedFormValues?.location?.activityLocationId ?? '',
            description: predefinedFormValues?.location?.description ?? '',
            formatted: predefinedFormValues?.location?.formatted ?? '',
            latitude: predefinedFormValues?.location?.latitude ?? 0,
            longitude: predefinedFormValues?.location?.longitude ?? 0,
            placesId: predefinedFormValues?.location?.placesId ?? '',
          },
          addedAttachments: [],
          creationTokens: [],
          removedAttachmentIds: [],
          sessions: predefinedFormValues?.sessions ?? [],
          recurrence:
            predefinedFormValues?.recurrence ??
            ({
              __typename: 'DailyRecurrenceType',
              dayInterval: 1,
              startDate: predefinedFormValues?.startDate ?? DateTime.local().toISODate(),
              endDate: predefinedFormValues?.endDate ?? null,
              humanReadable: '',
            } as DailyRecurrence),
          activityTagIds: predefinedFormValues?.activityTagIds ?? [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // const allowCICOParsed = values.allowCICO === 'true' ? true : values.allowCICO === 'false' ? false : null;
          if (isEditing) {
            const frequencyChanged =
              predefinedFormValues?.frequency &&
              values.frequency &&
              predefinedFormValues?.frequency !== values.frequency;
            const startDateChanged =
              predefinedFormValues?.startDate &&
              values.startDate &&
              DateTime.fromJSDate(predefinedFormValues?.startDate).toISODate() !==
                DateTime.fromJSDate(values.startDate).toISODate();
            const willDestroyFutureRosters = frequencyChanged || startDateChanged;

            if (willDestroyFutureRosters) {
              setWarningDialogOpen(true);
              return;
            }
          }
          runSaveActivity({
            values: {
              ...values,
              recurrence: {
                ...values.recurrence,
                startDate: values.startDate ? DateTime.fromJSDate(values.startDate).toISODate() : null,
                endDate: values.endDate ? DateTime.fromJSDate(values.endDate).toISODate() : null,
              },
            },
          });
        }}
      >
        {({ values }) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <StickyHeaderV2
              handleDiscard={() => history.replace(getReturnPath())}
              saveButtonName={isEditing ? 'Save Changes' : 'Save Activity'}
              closeButtonName={isEditing ? 'Cancel' : 'Cancel'}
              saveButtonProps={{
                variant: 'contained',
                color: 'primary',
                disabled: saveActivity.isLoading,
              }}
              closeButtonProps={!isEditing ? { color: 'error' } : undefined}
            />

            <LinearProgressOverlay isLoading={saveActivity.isLoading || programsDataLoading} />

            <Box
              style={{
                position: 'relative',
                flex: 1,
                overflow: 'scroll',
                paddingBottom: '3rem',
              }}
            >
              <CreateBaseActivityFormV2
                sessions={values.sessions}
                programs={programs}
                attachments={attachments}
                activityType={activityType}
                activityTags={activityTags}
              />
            </Box>

            <ConfirmEditActivityDialogV2
              open={warningDialogOpen}
              cancel={() => setWarningDialogOpen(false)}
              proceed={() => runSaveActivity({ values })}
              isPending={saveActivity.isPending}
              title='Update activity dates?'
            >
              <>
                <DialogContent>
                  <Typography>
                    Changing an activity start date or frequency will destroy all upcoming rosters and all volunteer
                    availability preferences.
                  </Typography>
                </DialogContent>
                <DialogContent>
                  <Typography>Volunteers will be notified of this via email.</Typography>
                </DialogContent>
              </>
            </ConfirmEditActivityDialogV2>
            <FormikErrorFocus />
          </Form>
        )}
      </Formik>
    ) : null;
  }
);

export { ActivityFormV2 };
