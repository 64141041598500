import React from 'react';
import { Box } from '@material-ui/core';
import { ColorLegend } from '../../../../common/ColorLegend';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { getActivityLegend } from '../../../../common/constants/activity-legends';
import { ActivityStatus } from '../ActivityTypes';

export function AllActivitiesColorLegend() {
  const { theme } = useCampfireTheme();
  const { rosters } = theme.color;

  return (
    <Box display='flex' flexWrap='wrap'>
      {(['not on team', 'waitlisted', 'applied', 'joined'] as ActivityStatus[]).map((status) => (
        <ColorLegend
          color={rosters.status[status].primary}
          text={getActivityLegend(status)}
          width={10}
          height={10}
          isRound
          marginRight='6px'
        />
      ))}
    </Box>
  );
}
