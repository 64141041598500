import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const SideBarRecentNewsSkeleton = () => {
  return (
    <Box mt='18px' mb='26px'>
      <Skeleton variant='text' width={100} height={13} />
      <Skeleton variant='text' width={300} height={19} />
      <Skeleton variant='text' width={338} height={60} />
    </Box>
  );
};
