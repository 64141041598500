import { gql } from '@apollo/client';

export const GET_COMMUNICATION_TEMPLATE = gql`
  query GetCommunicationTemplate {
    vm {
      communicationTemplates {
        communicationTypeId
        name
        status
        type
        isHidden
      }
    }
    im {
      communicationTemplates {
        communicationTypeId
        name
        status
        type
        isHidden
      }
    }
  }
`