import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { isNumber } from 'lodash';

import { CreatWidgetFormValues, StepsEnum, WidgetValueType } from '../dashboard-widget-model';
import { SelectValue } from '../selects/SelectValue';
import { containerStyle } from '../styles';

interface Props {
  onClose: () => void;
}

export function SelectValueDialog({ onClose }: Props) {
  const { values, setFieldValue } = useFormikContext<CreatWidgetFormValues>();

  const onNext = () => {
    setFieldValue('selectStep', StepsEnum.SETTINGS);
  };

  const onSelect = (value: WidgetValueType) => {
    if (values.updatingValueIndex === -1) {
      setFieldValue('byValues', values.byValues.concat(value));
    }

    if (isNumber(values.updatingValueIndex)) {
      setFieldValue(
        'byValues',
        values.byValues
          .slice(0, values.updatingValueIndex)
          .concat(value)
          .concat(values.byValues.slice(values.updatingValueIndex + 1))
      );
    }

    onNext();
  };

  const onCancel = () => {
    setFieldValue('selectStep', StepsEnum.SETTINGS);
  };

  const classes = containerStyle();

  return (
    <>
      <DialogTitle>
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs style={{ paddingTop: '10px' }}>
            <Typography variant='h6' style={{ fontWeight: 700, fontSize: '24px' }}>
              {values.isNew ? 'Add' : 'Edit'} Widget
            </Typography>
            <Typography variant='subtitle1' color='textSecondary'>
              Select value
            </Typography>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
                <Close color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent id='dialog-content'>
        <Box className={classes.root}>
          <SelectValue
            onSelect={onSelect}
            selectedValue={values.byValues[Math.max(values.updatingValueIndex || 0, 0)]}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ marginBottom: '2rem', marginRight: '10px' }}>
        <Button
          onClick={onCancel}
          style={{
            textTransform: 'none',
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}
