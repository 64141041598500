import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../../../global/network/useEndpointFetch';

export const useMoveIncidentToInProgress = () => {
  return useEndpointFetch<{ incidentId: string }>('/vm/incident/move/working');
};

export const useMoveIncidentToCompleted = () => {
  return useEndpointFetch<{ incidentId: string; closingComments: string }>('/vm/incident/move/closed');
};

export const useMoveIncidentToArchive = () => {
  return useEndpointFetch<{ incidentId: string }>('/vm/incident/move/archived');
};

export const useIncidentDetailHeaderActions = () => {
  const moveIncidentToInProgress = useMoveIncidentToInProgress();
  const moveIncidentToCompleted = useMoveIncidentToCompleted();
  const moveIncidentToArchive = useMoveIncidentToArchive();

  const { setSnackbar } = useSnackbar();

  const runMoveIncidentToInProgress = ({
    incidentId,
    handleSuccess,
  }: {
    incidentId: string;
    handleSuccess?: () => void;
  }) => {
    moveIncidentToInProgress
      .run({
        incidentId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to move incident to in review',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Moved incident to in review',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to move incident to in review',
          variant: 'error',
        })
      );
  };

  const runMoveIncidentToCompleted = ({
    incidentId,
    closingComments,
    handleSuccess,
  }: {
    incidentId: string;
    closingComments: string;
    handleSuccess?: () => void;
  }) => {
    moveIncidentToCompleted
      .run({
        incidentId,
        closingComments,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to move incident to completed',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Moved incident to completed',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to move incident to completed',
          variant: 'error',
        })
      );
  };

  const runMoveIncidentToArchive = ({
    incidentId,
    handleSuccess,
  }: {
    incidentId: string;
    handleSuccess?: () => void;
  }) => {
    moveIncidentToArchive
      .run({
        incidentId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to move incident to archive',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Moved incident to archive',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to move incident to archive',
          variant: 'error',
        })
      );
  };
  return {
    runMoveIncidentToInProgress,
    runMoveIncidentToCompleted,
    runMoveIncidentToCompletedIsLoading: moveIncidentToCompleted.isLoading,
    runMoveIncidentToArchive,
    runMoveIncidentToArchiveIsLoading: moveIncidentToArchive.isLoading,
  };
};
