import { formatUnitBasedOnValue, getDurationHoursMin, timeDurationInHours } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { Pane, PaneScrollable } from '@campfire/pane';
import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip';
import {
  Box,
  ButtonBase,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import {
  AccessTime,
  Check,
  ClearRounded,
  ErrorOutline,
  LocationSearching,
  Person,
  Public,
  VisibilityOff,
} from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { memo, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { ReactComponent as PendingActionsIcon } from '../../../assets/pending_actions_24px.svg';
import { ActivityStatusChip } from '../../../common/chips/ActivityStatusChip';
import { getDisplayTimeSchedule } from '../../../common/functions/activity-display-helpers';
import PrivilegeRules from '../../../global/auth/PrivilegeRules';
import { useUser } from '../../../global/auth/useUser';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { useFilterStyles } from '../../../common/filter-fields/FilterClasses';
import { getDayTimeIndicator } from './Activity/activity-display-utils';
import { ActivityHeader } from './Activity/ActivityHeader';
import { getActivityEndDateText } from './get-activity-end-date-text';
import { getActivityRecurrenceText } from './get-activity-recurrence-text';
import { useActivityEnrolmentStatus } from './useActivityEnrolmentStatus';
import { ActivitiesExploreActivity } from './__generated__/ActivitiesExploreActivity';
import { ActivitiesExploreActivityApplication } from './__generated__/ActivitiesExploreActivityApplication';

export const createActivityAddress = (activityLocation: any) => {
  if (activityLocation) {
    return activityLocation.placesAddress.description;
  }
  return 'This activity is remote.';
};

interface SortMenuProps {
  selectedSortValue: string;
  sortValues: string[];
  setSelectedSortValue: (x: string) => void;
}
const SortMenu = memo((props: SortMenuProps) => {
  const { selectedSortValue, sortValues, setSelectedSortValue } = props;
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);

  return (
    <Select
      data-track={`actCnlMapList-SortBy`}
      renderValue={() => (
        <Typography variant='caption' style={{ fontWeight: 600, paddingLeft: 8 }}>
          {selectedSortValue}
        </Typography>
      )}
      onChange={(e) => setSelectedSortValue(e.target.value as string)}
      value={selectedSortValue}
    >
      {sortValues.map((filter) => {
        return (
          <MenuItem key={filter} value={filter} className={classes.menuItem} style={{ minWidth: 230 }}>
            <Typography
              variant='body2'
              style={selectedSortValue === filter ? { fontWeight: 600 } : { fontWeight: 'normal' }}
            >
              {filter}
            </Typography>
            <Checkbox
              checked={selectedSortValue === filter}
              className={classes.checkBoxSelectField}
              checkedIcon={<Check color='primary' width={12} />}
              icon={<span className={classes.icon} />}
              disableRipple
            />
          </MenuItem>
        );
      })}
    </Select>
  );
});

type ExploreActivitiesListItemStatusType = 'activity-leader' | 'enrolled' | 'applied' | 'waitlist' | 'notEnrolled';

const ExploreActivitiesListItemStatusIndicator = (props: { status: ExploreActivitiesListItemStatusType }) => {
  const { theme } = useCampfireTheme();
  const { status } = props;

  if (status === 'notEnrolled') {
    return null;
  }

  const contentDependentOnStatus = {
    'activity-leader': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: 'initial',
      displayText: 'Activity Leader',
      descriptionText: `You're a leader of this Activity.`,
    },
    enrolled: {
      color: 'white',
      borderColor: theme.status.green.light,
      backgroundColor: theme.status.green.light,
      displayText: 'Enrolled in this activity',
      descriptionText: 'Manage your upcoming rosters using My Activities',
    },
    applied: {
      color: 'white',
      borderColor: theme.status.amber.light,
      backgroundColor: theme.status.amber.light,
      displayText: 'Application pending',
      descriptionText: `Pending approval from a manager. We'll email you as soon as there are any updates`,
    },
    waitlist: {
      color: 'white',
      borderColor: theme.status.red.light,
      backgroundColor: theme.status.red.light,
      displayText: 'On Waitlist',
      descriptionText: `You're now on the waitlist. We'll notify you as soon as there are any updates.`,
    },
  };

  const { color, backgroundColor, borderColor, displayText, descriptionText } = contentDependentOnStatus[status];

  return (
    <TitularTooltip enterDelay={0} placement='right' description={descriptionText}>
      <Box
        color={color}
        style={{ width: 'max-content', backgroundColor }}
        pr={1}
        pl={1}
        border={1}
        borderColor={borderColor}
      >
        <Typography variant='caption' style={{ marginLeft: 2, marginRight: 2, fontWeight: 'bold' }}>
          {displayText}
        </Typography>
      </Box>
    </TitularTooltip>
  );
};

interface ExploreActivitiesListItemProps {
  sorting: string;
  activity: ActivitiesExploreActivity;
  isManagementView?: boolean;
  isHovered: boolean;
  setHoveredActivityId: (id?: string) => void;
  setSelectedActivityId: (id: string) => void;
  setTargetedActivityId: (id: string) => void;
  activityApplications: ActivitiesExploreActivityApplication[];
  recurenceText?: string;
}

function compareExporeActivitiesListItemProps(
  prev: ExploreActivitiesListItemProps,
  next: ExploreActivitiesListItemProps
) {
  return prev.activity.activityId === next.activity.activityId && prev.isHovered === next.isHovered;
}

const useSkinnyListItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemContainer: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 24, // aligns with filter dropdown
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
    },
  })
);

const ExploreActivitiesSkinnyListItem = memo((props: ExploreActivitiesListItemProps) => {
  const { activity, setSelectedActivityId, recurenceText } = props;
  const { theme } = useCampfireTheme();
  const classes = useSkinnyListItemStyles(theme);

  const { startTime, endTime } = activity;
  const activityTime = getDisplayTimeSchedule(startTime, endTime);
  const activityLocation = createActivityAddress(activity.activityLocation);

  return (
    <ListItem className={classes.listItemContainer} onClick={() => setSelectedActivityId(activity.activityId)}>
      <ListItemText
        primary={activity.name}
        primaryTypographyProps={{ noWrap: true, variant: 'body1', style: { fontWeight: 'bold' } }}
        secondary={
          <>
            <Typography component='span' variant='body2' color='textPrimary' noWrap>
              {recurenceText}
            </Typography>
            <br />
            <Typography component='span' variant='body2' color='textPrimary' noWrap>
              {activityTime}
            </Typography>
            <br />
            <Typography component='span' variant='body2' color='textSecondary' noWrap>
              {activityLocation}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
});

type RowProps = ListChildComponentProps & { data: Array<ExploreActivitiesListItemProps> };

const Row = (props: RowProps) => {
  const { data, index, style } = props;
  const item = data[index];

  return (
    <div style={style} key={index}>
      <ExploreActivitiesSkinnyListItem {...item} />
    </div>
  );
};

const useListItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    locationSearchIcon: {
      color: theme.palette.common.white,
      height: '100%',
      padding: '8px',
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[500],
      },
    },
    listItemContainer: {
      padding: 0,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
        '& $locationSearchIcon': {
          color: theme.palette.grey[500],
        },
      },
    },
  })
);

const ExploreActivitiesListItem = memo((props: ExploreActivitiesListItemProps) => {
  const {
    sorting,
    activity,
    isManagementView,
    isHovered,
    setHoveredActivityId,
    setSelectedActivityId,
    setTargetedActivityId,
    activityApplications,
  } = props;
  const { startTime, endTime } = activity;
  const { theme, isMobile } = useCampfireTheme();
  const classes = useListItemStyles(theme);
  const numUsersOnWaitlist = activity.activeActivityWaitlistings.length;

  const status = useActivityEnrolmentStatus(activity, activityApplications);

  const activityOccurence =
    activity.__typename === 'VOLUNTEER_RecurringActivityType'
      ? `${activity.schedule.recurrences.flatMap((recurrence) => recurrence.humanReadable).join(', ')}`
      : 'Flexible Activity';

  const activityTime = getDisplayTimeSchedule(startTime, endTime);
  const activityDuration =
    startTime && endTime ? getDurationHoursMin(DateTime.fromISO(startTime), DateTime.fromISO(endTime)) : undefined;
  return (
    <ListItem
      className={classes.listItemContainer}
      style={
        isHovered && activity.activityLocation
          ? {
              background: theme.palette.grey[100],
            }
          : !activity.activityLocation
          ? { paddingRight: '40px' }
          : undefined
      }
      onMouseOver={() => setHoveredActivityId(activity.activityId)}
      onFocus={() => setHoveredActivityId(activity.activityId)}
    >
      <Box display='flex' flex={1}>
        <Box
          display='flex'
          flexDirection='column'
          flex={1}
          width={1}
          mx={3}
          py={2}
          data-track={`actCnlMapList-ActivityItem`}
          onClick={() => setSelectedActivityId(activity.activityId)}
        >
          {!isManagementView && (
            <Box mb={1}>
              <ExploreActivitiesListItemStatusIndicator status={status} />
            </Box>
          )}
          <ActivityHeader
            activityName={activity.name}
            activityOccurrence={activityOccurence}
            activityTime={activityDuration ? `${activityTime} (${activityDuration})` : activityTime}
            activityId={activity.activityId}
            isEditable={false}
            activityAddress={createActivityAddress(activity.activityLocation)}
            activityDayTimeIndicator={activity.endTime ? getDayTimeIndicator(activity.startTime) : undefined}
            semanticTime={{
              display: sorting === 'Recent and upcoming',
              isFlexible: !activity.nextOccurrence,
              startTime: activity.nextOccurrence,
            }}
            activityStatus={
              isManagementView && (
                <Box p={0} display='flex' alignItems='center' justifyContent='flex-start'>
                  <ActivityStatusChip
                    status={activity.closedActivity ? 'CLOSED' : activity.isSuspended ? 'SUSPENDED' : undefined}
                  />
                  <Typography
                    variant='caption'
                    style={{ paddingLeft: activity.isActive ? 0 : 8, fontWeight: 600 }}
                    color='textSecondary'
                  >
                    {`${activity.activityEnrolments.length ?? 'No'} volunteers`}
                    {activity.activeActivityApplications.length > 0
                      ? ` (${activity.activeActivityApplications.length ?? 'No'} new)`
                      : null}
                  </Typography>
                </Box>
              )
            }
          />
          {isManagementView && (
            <Box display='flex' alignItems='center'>
              <Typography variant='body2'>{`${formatUnitBasedOnValue(
                activity.sessions.length,
                'sessions'
              )}`}</Typography>
            </Box>
          )}

          <Box display='flex' alignItems='center' justifyContent='space-between'>
            {(status === 'notEnrolled' || status === 'waitlist' || isManagementView) && (
              <Box display='inline-flex' alignItems='center'>
                {activity.isEnrollable ? (
                  <Person color='disabled' style={{ fontSize: 15 }} />
                ) : (
                  <AccessTime color='disabled' style={{ fontSize: 15 }} />
                )}
                <Typography variant='body2' style={{ paddingLeft: 8 }}>
                  {activity.isEnrollable && 'Has Vacancy'}
                  {!activity.isEnrollable && isManagementView && `Waitlist (${numUsersOnWaitlist} users)`}
                  {!activity.isEnrollable &&
                    !isManagementView &&
                    `Waitlist ${
                      status === 'waitlist'
                        ? `(You and ${numUsersOnWaitlist - 1} ${numUsersOnWaitlist - 1 > 1 ? 'others' : 'other'})`
                        : `(Join ${numUsersOnWaitlist} ${numUsersOnWaitlist - 1 > 1 ? 'others' : 'other'})`
                    }`}
                </Typography>
              </Box>
            )}
            <Box display='flex' flex={1} alignItems='center' justifyContent='flex-end'>
              <Typography variant='subtitle2' color='textSecondary'>
                {activity.hasOpenRoster ? 'Open Rosters' : 'Managed Rosters'}
              </Typography>
              <Box mx={1}>
                <Typography color='textSecondary'>|</Typography>
              </Box>

              {isManagementView && activity.isHidden && (
                <TitularTooltip enterDelay={0} placement='right' description='Activity Hidden'>
                  <Typography variant='subtitle2' color='textSecondary' style={{ display: 'flex' }}>
                    <VisibilityOff />
                  </Typography>
                </TitularTooltip>
              )}
              {activity.isRestrictedActivity ? (
                <TitularTooltip enterDelay={0} placement='right' description='Approval required'>
                  <Typography variant='subtitle2' color='textSecondary' style={{ display: 'flex' }}>
                    <SvgIcon>
                      <PendingActionsIcon />
                    </SvgIcon>
                  </Typography>
                </TitularTooltip>
              ) : (
                <TitularTooltip enterDelay={0} placement='right' description='Anyone can join'>
                  <Typography variant='subtitle2' color='textSecondary' style={{ display: 'flex' }}>
                    <Public />
                  </Typography>
                </TitularTooltip>
              )}
            </Box>
          </Box>
        </Box>
        {!isMobile && activity.activityLocation ? (
          <TitularTooltip description='Zoom on map' placement='left'>
            <Box>
              <ButtonBase
                data-track={`actCnlMapList-ActivityItem-MapTarget`}
                onClick={() => setTargetedActivityId(activity.activityId)}
                style={
                  isHovered
                    ? {
                        color: theme.palette.grey[500],
                      }
                    : undefined
                }
                className={classes.locationSearchIcon}
              >
                <LocationSearching />
              </ButtonBase>
            </Box>
          </TitularTooltip>
        ) : null}
      </Box>
    </ListItem>
  );
}, compareExporeActivitiesListItemProps);

type ActivitiesExploreActivitiesListProps = {
  clearFilters: () => void;
  numberNonDefaultFilters: number;
  showSkinnyList: boolean;
  hoveredActivityId?: string;
  setHoveredActivityId: (x?: string) => void;
  setSelectedActivityId: (x: string) => void;
  setTargetedActivityId: (x: string) => void;
  activities: ActivitiesExploreActivity[];
  volName?: string;
  isManagementView?: boolean;
  activityApplications: ActivitiesExploreActivityApplication[];
  isEmptyProgram: boolean;
  selectedProgramId?: string;
  programs: any[];
  orgName?: string;
};

const MANAGER_SORT_FILTERS = [
  'Recent and upcoming',
  'Duration (Shortest first)',
  'Name (A - Z)',
  'No. of Vols (Less first)',
];
const VOL_SORT_FILTERS = ['Recent and upcoming', 'Duration (Shortest first)', 'Name (A - Z)'];

const ActivitiesExploreActivitiesList = memo((props: ActivitiesExploreActivitiesListProps) => {
  const {
    clearFilters,
    numberNonDefaultFilters,
    showSkinnyList,
    hoveredActivityId,
    setHoveredActivityId,
    setSelectedActivityId,
    setTargetedActivityId,
    activities,
    volName,
    isManagementView,
    activityApplications,
    isEmptyProgram,
    selectedProgramId,
    programs,
    orgName,
  } = props;
  const sortValues = isManagementView ? MANAGER_SORT_FILTERS : VOL_SORT_FILTERS;
  const [selectedSortValue, setSelectedSortValue] = useState<string>(sortValues[0]);

  function sortByStartTime(activityA: ActivitiesExploreActivity, activityB: ActivitiesExploreActivity) {
    const occurrenceA = activityA.nextOccurrence;
    const occurrenceB = activityB.nextOccurrence;

    if (occurrenceA === occurrenceB) {
      return 0;
    }
    if (occurrenceA === null) {
      return -1;
    }
    if (occurrenceB === null) {
      return 1;
    }

    const occurrenceADate = DateTime.fromISO(occurrenceA);
    const occurrenceBDate = DateTime.fromISO(occurrenceB);

    if (occurrenceADate === occurrenceBDate) {
      return 0;
    }

    return occurrenceADate < occurrenceBDate ? -1 : 1;
  }

  function sortByDuration(activityOne: ActivitiesExploreActivity, activityTwo: ActivitiesExploreActivity) {
    const durationOne = timeDurationInHours(
      DateTime.fromISO(activityOne.startTime),
      DateTime.fromISO(activityOne.endTime)
    );
    const durationTwo = timeDurationInHours(
      DateTime.fromISO(activityTwo.startTime),
      DateTime.fromISO(activityTwo.endTime)
    );
    return durationOne > durationTwo ? 1 : -1;
  }

  const sortedActivities = useMemo(() => {
    if (!activities.length || !selectedSortValue) return activities;
    if (selectedSortValue.includes('Recent')) {
      return activities.sort(sortByStartTime);
    }
    if (selectedSortValue.includes('Duration')) {
      return activities.sort(sortByDuration);
    }
    if (selectedSortValue.includes('Name')) {
      return activities.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (selectedSortValue.includes('No. of Vols')) {
      return activities.sort((a, b) => (a.activityEnrolments.length > b.activityEnrolments.length ? 1 : -1));
    }
    return activities.sort((a, b) => a.name.localeCompare(b.name));
  }, [activities, selectedSortValue]);

  const currentProgram = programs.find((program) => program.programId === selectedProgramId);

  const {
    user: { userIdentityService },
  } = useUser();
  const canCreateActivity =
    userIdentityService.hasVmGlobalRule(PrivilegeRules.manageActivities) || userIdentityService.isProgramManager;

  const virtualisedListActivities: Array<ExploreActivitiesListItemProps> = useMemo(
    () =>
      sortedActivities.map((x) => ({
        sorting: selectedSortValue,
        key: x.activityId,
        activity: x,
        isManagementView: isManagementView,
        isHovered: x.activityId === hoveredActivityId,
        setHoveredActivityId: setHoveredActivityId,
        setSelectedActivityId: setSelectedActivityId,
        setTargetedActivityId: setTargetedActivityId,
        activityApplications: activityApplications,
        recurenceText:
          x.__typename === 'VOLUNTEER_RecurringActivityType'
            ? getActivityRecurrenceText(x)
            : getActivityEndDateText(x.endDate),
      })),
    [sortedActivities, selectedSortValue]
  );

  return (
    <Pane>
      <PaneScrollable>
        {volName && !isManagementView && (
          <Box px={3} pt={2}>
            <Typography variant='h6' gutterBottom>
              {`Hi ${volName}, let's make an impact!`}
            </Typography>
            <Divider />
          </Box>
        )}

        {isEmptyProgram && !isManagementView && (
          <Box px={3} pt={6} textAlign='center'>
            <Box mb={3}>
              <Typography variant='h5'>{orgName} has not added any available activities for this program.</Typography>
              <Typography variant='subtitle1' color='textSecondary'>
                Newly added activities will appear here as soon as they are created.
              </Typography>
            </Box>
          </Box>
        )}

        {isEmptyProgram && isManagementView && (
          <Box px={3} pt={6} textAlign='center'>
            <Box mb={3}>
              <ErrorOutline style={{ fontSize: '65px' }} />
            </Box>
            <Box mb={3}>
              <Typography variant='h5'>There are no activities created for this program.</Typography>
              <Typography
                variant='subtitle1'
                color='textSecondary'
              >{`Get started now by clicking 'Create Activity'`}</Typography>
            </Box>
            {canCreateActivity && (
              <Link
                to={`/management/activity-management/create?programId=${currentProgram?.programId}`}
                style={{ color: 'inherit', textDecoration: 'none', textDecorationColor: 'none' }}
              >
                <TabletButton color='primary' variant='contained' size='large'>
                  Create Activity
                </TabletButton>
              </Link>
            )}
          </Box>
        )}

        {isEmptyProgram ? null : showSkinnyList ? (
          <>
            <Box
              height={98} // we use this number in the AutoSizer below to compensate with an offset
            >
              <ListControls
                sortMenuProps={{
                  selectedSortValue,
                  sortValues,
                  setSelectedSortValue,
                }}
                numberNonDefaultFilters={numberNonDefaultFilters}
                sortedActivitiesLength={sortedActivities.length}
                clearFilters={clearFilters}
              />

              <Divider />
            </Box>

            <AutoSizer>
              {({ height, width }: { height: number; width: number }) => (
                <FixedSizeList
                  height={height - 98} // offset height of 'fixed' controls above the list
                  width={width}
                  itemCount={virtualisedListActivities.length}
                  itemSize={129}
                  itemData={virtualisedListActivities}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          </>
        ) : (
          <List disablePadding>
            <ListControls
              sortMenuProps={{
                selectedSortValue,
                sortValues,
                setSelectedSortValue,
              }}
              numberNonDefaultFilters={numberNonDefaultFilters}
              sortedActivitiesLength={sortedActivities.length}
              clearFilters={clearFilters}
            />

            {virtualisedListActivities.map((data) => (
              <ExploreActivitiesListItem {...data} />
            ))}
          </List>
        )}
      </PaneScrollable>
    </Pane>
  );
});

type ListControlProps = {
  sortMenuProps: SortMenuProps;
  numberNonDefaultFilters: number;
  sortedActivitiesLength: number;
  clearFilters: () => void;
};

const ListControls = (props: ListControlProps) => {
  const { sortMenuProps, numberNonDefaultFilters, sortedActivitiesLength, clearFilters } = props;
  return (
    <>
      <Box px={3} pt={2} display='flex' justifyContent='justify-content' alignContent='center' alignItems='center'>
        <Box display='flex' flex='1 1 auto' alignItems='center' alignContent='center'>
          <Typography variant='body2' style={{ fontWeight: 600, paddingRight: 8 }}>
            {'Sort by'}
          </Typography>
          <SortMenu {...sortMenuProps} />
        </Box>

        {sortedActivitiesLength === 0 && numberNonDefaultFilters > 0 ? (
          <TabletButton
            onClick={clearFilters}
            size='large'
            color='primary'
            variant='contained'
            startIcon={<ClearRounded />}
          >
            Clear filters
          </TabletButton>
        ) : (
          <HoverText
            disabled={numberNonDefaultFilters < 1}
            variant='body2'
            color={numberNonDefaultFilters > 0 ? 'primary' : 'textPrimary'}
            onClick={clearFilters}
          >
            {numberNonDefaultFilters > 0 ? `Clear filters (${numberNonDefaultFilters})` : 'No filters active'}
          </HoverText>
        )}
      </Box>

      <ListSubheader disableSticky disableGutters style={{ padding: '0 24px' }}>
        {sortedActivitiesLength === 1 ? '1 activity found' : `${sortedActivitiesLength} activities found`}
      </ListSubheader>
    </>
  );
};

export { ActivitiesExploreActivitiesList };
