import { gql } from '@apollo/client';

const INCOMING_INVITEE_FRAGMENT = gql`
  fragment IncomingInvitee on VOLUNTEER_VolunteerInviteeType {
    __typename
    inviteeId
    dateInvited
    inviteeEmail: email
    dateRevoked
    invitedBy {
      managerId
      profile {
        profileId
        preferredName
        lastName
      }
    }
    profile {
      programs {
        ...IncomingVolunteerProfileProgram
      }
    }
  }
`;

export const INCOMING_APPLICANT_FRAGMENT = gql`
  fragment IncomingApplication on VOLUNTEER_ApplicationType {
    __typename
    applicationId
    profile {
      profileId
      applicantUserId: userId
      programs {
        ...IncomingVolunteerProfileProgram
      }
    }
    mostRecentReminder {
      applicationReminderId
      remindedAt
    }
    applicantEmail: email
    dateSubmitted
    status
    name
    invitee {
      ...IncomingInvitee
    }
    relevantTasks {
      taskId
      cake {
        cakeType
      }
    }
  }
  ${INCOMING_INVITEE_FRAGMENT}
`;

export const GET_INCOMING_VOLUNTEERS = gql`
  query GetIncomingVolunteers(
    $limit: Int
    $offset: Int
    $entry: String
    $status: String
    $program: String
    $query: String
    $sort: String
  ) {
    vm {
      applicationAutoVerification
      incomingVolunteers(
        offset: $offset
        limit: $limit
        entry: $entry
        program: $program
        status: $status
        query: $query
        sort: $sort
      ) {
        incomingVolunteers {
          incomingVolunteerId
          isInvited
          source
          name
          email
          lastUpdated
          invitee {
            __typename
            inviteeId
            dateRevoked
          }
          application {
            __typename
            applicationId
            status
            mostRecentReminder {
              applicationReminderId
              remindedAt
            }
          }

          profile {
            __typename
            profileId
            userId
            isCompleted
            isProfileTaskApproved
            completedTasks {
              task {
                cake {
                  cakeType
                }
                dateRemoved
              }
              status
            }
            dateRemoved
            percentageComplete
            programs {
              ...IncomingVolunteerProfileProgram
            }
          }
        }

        pagination {
          offset
          limit
          total
        }
      }
    }
  }
  fragment IncomingVolunteerProfileProgram on VOLUNTEER_ProgramType {
    programId
    name
  }
`;

export const GET_PROGRAMS_FOR_INCOMING_VOLS_FILTER = gql`
  query GetProgramsForIncomingVolsFilter {
    vm {
      programs {
        ...IncomingVolsFilterProgram
      }
    }
  }
  fragment IncomingVolsFilterProgram on VOLUNTEER_ProgramType {
    programId
    name
  }
`;
