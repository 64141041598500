import { ApolloQueryResult, LazyQueryResult, QueryLazyOptions } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';
import { RemindVolunteersDialog } from './incoming-volunteers/components/RemindVolunteersDialog';
import { RemindAllVolunteersDialog } from './incoming-volunteers/components/RemindAllVolunteersDialog';
import { GET_INCOMING_VOLUNTEERS } from './incoming-volunteers/incoming-volunteers-model.gql';
import {
  GetIncomingVolunteers,
  GetIncomingVolunteersVariables,
  GetIncomingVolunteers_vm_incomingVolunteers_incomingVolunteers_application as ApplicationType,
} from './incoming-volunteers/__generated__/GetIncomingVolunteers';
import {
  useCreateApplicationReminder,
  useCreateApplicationReminderAll,
} from './mutations/useCreateApplicationReminder';

interface VolunteersContextInterface {
  searchIncomingVolunteersInput?: string;
  setSearchIncomingVolunteersInput: (x: string | undefined) => void;
  inviteDialog: boolean;
  setInviteDialog: (x: boolean) => void;
  remindDialog: boolean;
  setRemindDialog: (x: boolean) => void;
  remindAllDialog: boolean;
  setRemindAllDialog: (x: boolean) => void;
  applicationsReminder: (ApplicationType | null)[];
  setApplicationsReminder: (application: (ApplicationType | null)[]) => void;
  getIncomingVolunteers: (
    options?: QueryLazyOptions<GetIncomingVolunteersVariables> | undefined
  ) => Promise<LazyQueryResult<GetIncomingVolunteers, GetIncomingVolunteersVariables>>;
  getIncomingVolunteersData: GetIncomingVolunteers | undefined;
  getIncomingVolunteersLoading: boolean;
  getIncomingVolunteersRefetch:
    | ((variables?: undefined) => Promise<ApolloQueryResult<GetIncomingVolunteers>>)
    | undefined;
}

interface VolunteersProviderProps {
  children: React.ReactNode;
}

const VolunteersContext = React.createContext<VolunteersContextInterface | undefined>(undefined);

const VolunteersProvider = (props: VolunteersProviderProps) => {
  const [searchIncomingVolunteersInput, setSearchIncomingVolunteersInput] = useState<string | undefined>();
  const [inviteDialog, setInviteDialog] = useState<boolean>(false);
  const [remindDialog, setRemindDialog] = useState<boolean>(false);
  const [remindAllDialog, setRemindAllDialog] = useState<boolean>(false);
  const [applicationsReminder, setApplicationsReminder] = useState<(ApplicationType | null)[]>([]);
  const [
    getIncomingVolunteers,
    { data: getIncomingVolunteersData, loading: getIncomingVolunteersLoading, refetch: getIncomingVolunteersRefetch },
  ] = useCampfireLazyQuery<GetIncomingVolunteers, GetIncomingVolunteersVariables>(GET_INCOMING_VOLUNTEERS);

  const createApplicationReminder = useCreateApplicationReminder();
  const createApplicationReminderAll = useCreateApplicationReminderAll();

  const value = {
    searchIncomingVolunteersInput,
    setSearchIncomingVolunteersInput,
    inviteDialog,
    setInviteDialog,
    remindDialog,
    setRemindDialog,
    applicationsReminder,
    setApplicationsReminder,
    getIncomingVolunteers,
    getIncomingVolunteersData,
    getIncomingVolunteersLoading,
    getIncomingVolunteersRefetch,
    remindAllDialog,
    setRemindAllDialog,
  };

  const handleSubmit = ({ message }: { message: string }) => {
    createApplicationReminder({
      applicationIds: applicationsReminder.map((application) => application?.applicationId || ''),
      message,
    }).then(() => {
      if (getIncomingVolunteersRefetch) {
        setTimeout(getIncomingVolunteersRefetch, 0);
      }
    });
  };
  const handleSubmitRemindAll = ({ message }: { message: string }) => {
    createApplicationReminderAll({
      message,
    }).then(() => {
      if (getIncomingVolunteersRefetch) {
        setTimeout(getIncomingVolunteersRefetch, 0);
      }
    });
  };

  const handleCloseRemindDialog = () => {
    setApplicationsReminder([]);
    setRemindDialog(false);
  };

  return (
    <VolunteersContext.Provider value={value} {...props}>
      {remindDialog && (
        <RemindVolunteersDialog
          volunteers={applicationsReminder}
          open={remindDialog}
          handleClose={handleCloseRemindDialog}
          onSubmit={({ message }: { message: string }) => {
            handleSubmit({ message });
            handleCloseRemindDialog();
          }}
        />
      )}
      {remindAllDialog && (
        <RemindAllVolunteersDialog
          open={remindAllDialog}
          handleClose={() => setRemindAllDialog(false)}
          onSubmit={({ message }: { message: string }) => {
            handleSubmitRemindAll({ message });
            setRemindAllDialog(false);
          }}
        />
      )}
      {props.children}
    </VolunteersContext.Provider>
  );
};

export const useVolunteersContext = () => {
  const value = useContext(VolunteersContext);
  if (!value) throw new Error('Must be inside of a VolunteersProvider');
  return value;
};

export { VolunteersProvider };
