import { useEffect, useState } from 'react';
import { SnackBarOptions } from '../../../../common/snackbars/TemplateSnackbar';
import { useApiUrl } from '../../../../global/config/useApiUrl';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';
import {
  useAddWaitlistingFetch,
  useRemoveWaitlistingFetch,
} from '../../../program-manager/activities/activity-timeline/activity-timeline-actions';

export type PostApplicationDataType = {
  activityApplicationId?: string;
  activityId?: string;
  activityDates?: Array<{ activityDate: string; sessionIds: Array<string> }>;
};

export type ActivityApplicationEndpointActions = 'create' | 'withdraw' | 'update';

export const useActivityApplicationFetch = (refetch?: () => void, closeAction?: () => void) => {
  const apiUrl = useApiUrl();
  const [snackBarOptions, setSnackBarOptions] = useState<SnackBarOptions>();

  const createApplicationFetch = useCampfireFetch({
    defer: true,
  });

  const withdrawApplicationFetch = useCampfireFetch({
    defer: true,
  });

  const updateApplicationFetch = useCampfireFetch({
    defer: true,
  });

  function resetSnackbar() {
    setSnackBarOptions(undefined);
  }

  function activityApplicationPostRequest(
    postApplicationData: PostApplicationDataType,
    actionType: ActivityApplicationEndpointActions,
    onSuccess?: () => void
  ) {
    resetSnackbar();
    if (actionType === 'create') {
      createApplicationFetch
        .run({
          url: `${apiUrl}/vm/activity/application/save`,
          method: 'post',
          data: postApplicationData,
        })
        .then(() => {
          if (onSuccess) onSuccess();
        });
    }
    if (actionType === 'withdraw') {
      withdrawApplicationFetch.run({
        url: `${apiUrl}/vm/activity/application/withdraw`,
        method: 'post',
        data: postApplicationData,
      });
    }
    if (actionType === 'update') {
      updateApplicationFetch.run({
        url: `${apiUrl}/vm/activity/application/save`,
        method: 'post',
        data: postApplicationData,
      });
    }
  }

  // TODO: BS - all of these effects can be moved into Promises returned from .run
  useEffect(() => {
    if (createApplicationFetch.status === 'fulfilled') {
      if (refetch) refetch();
      if (closeAction) closeAction();
      setSnackBarOptions({
        variant: 'success',
        message: 'Successfully applied',
        open: true,
      });
    }
    if (createApplicationFetch.status === 'rejected') {
      if (refetch) refetch();
      setSnackBarOptions({
        variant: 'error',
        message: 'Application unsuccessful. Try again later.',
        open: true,
      });
    }
  }, [createApplicationFetch.status]);

  useEffect(() => {
    if (withdrawApplicationFetch.status === 'fulfilled') {
      if (refetch) refetch();
      if (closeAction) closeAction();
      setSnackBarOptions({
        variant: 'success',
        message: 'Successfully withdrawn',
        open: true,
      });
    }
    if (withdrawApplicationFetch.status === 'rejected') {
      if (refetch) refetch();
      setSnackBarOptions({
        variant: 'error',
        message: 'Application withdraw unsuccessful. Try again later.',
        open: true,
      });
    }
  }, [withdrawApplicationFetch.status]);

  useEffect(() => {
    if (updateApplicationFetch.status === 'fulfilled') {
      if (refetch) refetch();
      if (closeAction) closeAction();
      setSnackBarOptions({
        variant: 'success',
        message: 'Successfully updated',
        open: true,
      });
    }
    if (updateApplicationFetch.status === 'rejected') {
      if (refetch) refetch();
      setSnackBarOptions({
        variant: 'error',
        message: 'Update unsuccessful. Try again later.',
        open: true,
      });
    }
  }, [updateApplicationFetch.status]);

  // new pattern
  const { setSnackbar } = useSnackbar();
  const addWaitlisting = useAddWaitlistingFetch();
  const removeWaitlisting = useRemoveWaitlistingFetch();

  const runApplyForWaitlist = ({ activityId }: { activityId: string }) => {
    addWaitlisting
      .run({
        activityId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to add to waitlist',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Added to waitlist',
          variant: 'success',
        });
        if (refetch) refetch();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to add to waitlist',
          variant: 'error',
        })
      );
  };

  const runRemoveFromWaitlist = ({ activityWaitlistingId }: { activityWaitlistingId: string }) => {
    removeWaitlisting
      .run({
        activityWaitlistingId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to withdraw from waitlist',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Withdrawn from waitlist',
          variant: 'success',
        });
        if (refetch) refetch();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to withdraw from waitlist',
          variant: 'error',
        })
      );
  };
  return {
    snackBarOptions,
    activityApplicationPostRequest,
    runApplyForWaitlist,
    runRemoveFromWaitlist,
    createApplicationLoading: createApplicationFetch.isLoading,
    withdrawApplicationLoading: withdrawApplicationFetch.isLoading,
    runApplyForWaitlistLoading: addWaitlisting.isLoading,
    runRemoveFromWaitlistLoading: removeWaitlisting.isLoading,
    withdrawApplicationResponse: withdrawApplicationFetch.response,
    removeWaitlistingResponse: removeWaitlisting.response,
  };
};
