import { formatUnitBasedOnValue } from '@campfire/hot-date';
import { Box, TextField, Typography, IconButton } from '@material-ui/core';
import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import React from 'react';

interface IncomingVolunteersPaginationDropdownProps {
  from: number;
  to: number;
  view: number;
  setView: (value: number) => void;
  total: number;
  isListFiltered: boolean;
  filteredListLength: number;
  fullstoryId: string;
  page: number;
  setPage: (value: number) => void;
  isNextDisabled: boolean;
}

const IncomingVolunteersPaginationDropdown = (props: IncomingVolunteersPaginationDropdownProps) => {
  const {
    page,
    setPage,
    view,
    setView,
    total,
    isListFiltered,
    filteredListLength = 0,
    fullstoryId,
    isNextDisabled,
    from,
    to,
  } = props;

  return (
    <Box display='flex' alignItems='center'>
      <Box display='flex' flexDirection='column' alignItems='flex-end'>
        <Typography
          display='block'
          variant='caption'
          color='textSecondary'
          style={{ marginRight: 16 }}
        >{`Total ${total} incoming volunteers`}</Typography>
        {isListFiltered && (
          <Typography display='block' variant='caption' color='textSecondary' style={{ marginRight: 16 }}>
            {filteredListLength === 0
              ? `Filtered to ${filteredListLength} results`
              : `Filtered to ${formatUnitBasedOnValue(filteredListLength, 'results')}`}
          </Typography>
        )}
      </Box>
      <TextField
        data-track={fullstoryId}
        select
        label='Show'
        variant='outlined'
        margin='dense'
        SelectProps={{ native: true }}
        value={view}
        onChange={(event: any) => {
          setPage(1);
          setView(event.target.value);
        }}
      >
        {[10, 25, 50, 100].map((value) => (
          <option id={`${value}`} key={value} value={value}>
            {value}
          </option>
        ))}
      </TextField>
      <Box ml={1} display='flex' alignItems='center'>
        <Typography variant='caption' color='textSecondary' style={{ padding: '0 4px' }}>
          {from + 1}-{to} of {total}
        </Typography>
        <IconButton aria-label='back' disabled={page <= 1} onClick={() => setPage(page - 1)}>
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton aria-label='next' disabled={isNextDisabled} onClick={() => setPage(page + 1)}>
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export { IncomingVolunteersPaginationDropdown };
