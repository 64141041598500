import React from 'react';
import { Box, Button, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Theme } from '@material-ui/core/styles';
import { AccessTime, LocationOnRounded } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useCampfireQuery } from '../../global/network/useCampfireQuery';
import { DateInfoCard } from '../cards/date-info-card/DateInfoCard';

import { useCampfireTheme } from '../../theme/useCampfireTheme';

import { GetAlertFullSessions, GetAlertFullSessionsVariables } from './__generated__/GetAlertFullSessions';

import { GET_ALERT_FULL_SESSIONS_DIALOG } from './alert-full-session-dialog-model.gql';
import { FullscreenDialog } from '../dialogs/FullscreenDialog';
import { useButtonStyles } from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-roles/AdminConsoleRoleStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerWrapper: {
      display: (props: { isMobile: boolean; isMd: boolean }) => (props.isMobile || props.isMd ? 'auto' : 'flex'),
      alignItems: 'center',
      marginTop: '40px',
    },
    footerItem: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '24px',
      paddingTop: (props: { isMobile: boolean; isMd: boolean }) => (props.isMobile || props.isMd ? '8px' : '0px'),
    },
    footerItemIcon: {
      color: theme.color.grey.neutral200,
      fontSize: 14,
      marginRight: '6px',
    },
    footerItemLargeNumber: {
      fontWeight: 600,
      color: theme.color.grey.neutral200,
      fontSize: 14,
      marginRight: '6px',
    },
    footerItemText: {
      color: theme.color.grey.neutral200,
      marginTop: '1px',
    },
  })
);

export const AlertFullSessionDialog = ({
  onClose,
  fullSessionIds,
  activityIdSubmited,
  activityDatesSubmited,
}: {
  open: boolean;
  onClose: () => void;
  fullSessionIds: string[];
  activityIdSubmited: string;
  activityDatesSubmited: string[];
}) => {
  const { isMobile, isMd, theme } = useCampfireTheme();
  const classes = useStyles({ isMobile, isMd });
  const buttonClasses = useButtonStyles();
  const { data, loading: isLoading } = useCampfireQuery<GetAlertFullSessions, GetAlertFullSessionsVariables>(
    GET_ALERT_FULL_SESSIONS_DIALOG,
    {
      options: {
        variables: {
          activityId: activityIdSubmited,
        },
      },
    }
  );

  const activity = data?.vm.activity;
  const fullSessions = activity?.sessions.filter((s) => fullSessionIds.includes(s.sessionId)) || [];

  return (
    <FullscreenDialog open close={onClose} title='Full Sessions' maxWidth='md' fullWidth fullScreen={isMobile}>
      <Typography style={{ marginTop: '8px', fontWeight: 400, color: theme.color.grey.neutral300 }}>
        {`Some of the sessions you have tried to be rostered on for are full. 
        We've marked you as available, so you'll be rostered on if another volunteer pulls out or a manager rosters you on.`}
      </Typography>
      <DialogContent style={{ marginTop: '1em', padding: 0 }}>
        {activity && !isLoading ? (
          <>
            {activityDatesSubmited.map((activityDate) => (
              <DateInfoCard date={activityDate} color={theme.color.rosters.status.available} size='l'>
                <Box display='flex' flexDirection='column' width='100%' minHeight={'80px'}>
                  <Box display='flex' justifyContent='space-between' alignSelf='stretch'>
                    <TitularTooltip title={activity.name}>
                      <Typography
                        variant={'h6'}
                        noWrap={!isMobile}
                        style={
                          isMobile
                            ? {
                                color: theme.color.grey.neutral400,
                                fontWeight: 800,
                                width: '100%',
                                letterSpacing: '-0.02em',
                                overflow: 'hidden',
                                maxHeight: '3.5em',
                                textOverflow: 'ellipsis',
                              }
                            : {
                                color: theme.color.grey.neutral400,
                                fontWeight: 800,
                                width: '100%',
                                letterSpacing: '-0.02em',
                              }
                        }
                      >
                        {activity.name}
                      </Typography>
                    </TitularTooltip>
                  </Box>

                  <Box>
                    <Typography variant='subtitle2' style={{ fontWeight: 600, color: theme.color.grey.neutral400 }}>
                      {'Full sessions'}
                    </Typography>
                    {fullSessions.map((item) => (
                      <Typography style={{ color: theme.color.grey.neutral200, fontSize: '14px' }}>
                        {item.name}
                      </Typography>
                    ))}
                  </Box>

                  <Box className={classes.footerWrapper}>
                    <Box className={classes.footerItem}>
                      <LocationOnRounded className={classes.footerItemIcon} />
                      <Typography variant='caption' className={classes.footerItemText}>
                        {activity.activityLocation?.placesAddress.description}
                      </Typography>
                    </Box>
                    <Box className={classes.footerItem}>
                      <AccessTime className={classes.footerItemIcon} />
                      <Typography variant='caption' className={classes.footerItemText}>
                        {activity.hasOpenRoster ? 'Open Roster' : 'Managed Roster'}
                      </Typography>
                    </Box>
                    <Box className={classes.footerItem}>
                      <Typography variant='h2' className={classes.footerItemLargeNumber}>
                        {activity.sessions.length}
                      </Typography>
                      <Typography variant='caption' className={classes.footerItemText}>
                        {activity.sessions.length > 1 ? 'sessions available' : 'session available'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </DateInfoCard>
            ))}
          </>
        ) : (
          <Box>
            <Skeleton variant='rect' width={'100%'} height={140} style={{ borderRadius: '8px' }} />
          </Box>
        )}
      </DialogContent>
      <DialogActions style={{}}>
        <Button classes={buttonClasses} variant='contained' type='submit' color='primary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </FullscreenDialog>
  );
};
