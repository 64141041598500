import { createStyles, makeStyles } from '@material-ui/styles';

export const useButtonStyles = makeStyles(() => createStyles({
  root: {
    borderRadius: '6px',
    height: '38px',
    fontSize: '14px',
    textTransform: 'capitalize',
    alignSelf: 'start'
  }
}));
