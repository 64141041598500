import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { unpackToDateTime } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, CircularProgress, Divider, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import pluralize from 'pluralize';
import { BorderColorOutlined, LabelImportantRounded } from '@material-ui/icons';
import { convertFromRaw, EditorState } from 'draft-js';
import React, { SyntheticEvent, useMemo } from 'react';
import { SemanticTime } from '../../../common/dates/SemanticTime';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

import {
  BulletinManagement,
  BulletinManagement_activityRoles as BulletinRoles,
  BulletinManagement_programs as BulletinPrograms,
  BulletinManagement_activities as BulletinActivities,
} from './__generated__/BulletinManagement';
import { useContainerStyles, useThumbnailStyles } from './bulletinStyles';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { GetAdminConsoleBulletinSettings } from '../../admin/admin-console/admin-console-content-pages/admin-console-bulletin-settings/__generated__/GetAdminConsoleBulletinSettings';
import { GET_ADMIN_CONSOLE_BULLETIN_SETTINGS } from '../../admin/admin-console/admin-console-content-pages/admin-console-bulletin-settings/admin-console-bulletin-settings.gql';
import { useUser } from '../../../global/auth/useUser';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../common/cards/alert-card/AlertCard';

interface BulletinsListProps {
  addBulletin: () => void;
  bulletins?: Array<BulletinManagement>;
  setActiveBulletin: (bulletin: BulletinManagement) => void;
  togglePinned: (bulletin: BulletinManagement) => Promise<any>;
  isPinningId: string;
}

interface BulletinThumbnailProps {
  bulletin: BulletinManagement;
  setActiveBulletin: (bulletin: BulletinManagement) => void;
  togglePinned: (bulletin: BulletinManagement) => Promise<any>;
  highlighted: boolean;
  isPinningId: string;
  canUserAddEdit: boolean;
}

const useListStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '4px',
      textTransform: 'none',
      marginRight: '10px',
      padding: '7px 12px',
      minWidth: '45px',
    },
    headerButton: {
      color: theme.color.grey.neutral400,
      borderColor: '#dcdcdc',
    },
    toggledButton: {
      color: '#FCFCFC !important',
      backgroundColor: '#646464 !important',
    },
    containedButton: {
      backgroundColor: theme.color.secondary.main900,
      color: '#ffffff',
      boxSizing: 'border-box',
      borderRadius: '4px',
      textTransform: 'none',
      minWidth: '40px',
    },
    buttonText: {
      marginLeft: '4px',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '19px',
      padding: '0px 7px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })
);

const useStyles = makeStyles({
  pinIcon: {
    '&:hover': {
      color: '#FABE4D !important',
    },
  },
  pin: {
    display: 'none',
    '&:hover': {
      '& #pinIcon': {
        color: '#FABE4D !important',
      },
    },
  },
  thumbnail: {
    backgroundColor: (props: any) => (props.highlighted ? '#FFFBE3' : 'initial'),
    '&:hover': {
      backgroundColor: (props: any) => (props.highlighted ? '#FFFBE3' : '#f6f6f6'),
      cursor: 'pointer',
      '& #pin': {
        display: 'inline-block',
      },
    },
  },
});

interface SegmentationProps {
  segmentation: (BulletinPrograms | BulletinRoles | BulletinActivities)[];
  segmentationType: string;
}

const Segmentation = ({ segmentation, segmentationType }: SegmentationProps) => {
  const thumbnailStyles = useThumbnailStyles();

  if (segmentation.length === 1) {
    return (
      <Box className={thumbnailStyles.segmentationItem}>
        <strong>{segmentationType}:</strong>&nbsp;{segmentation[0].name}
      </Box>
    );
  }
  if (segmentation.length === 0) {
    return <Box className={thumbnailStyles.segmentationItem}>All {pluralize(segmentationType, 2)}</Box>;
  }
  return (
    <Tooltip
      arrow
      title={
        <List>
          {segmentation.map((segment) => (
            <ListItem key={segment.name}>{segment.name}</ListItem>
          ))}
        </List>
      }
    >
      <Box className={thumbnailStyles.segmentationItem}>
        {segmentation.length} {pluralize(segmentationType, segmentation.length)}
      </Box>
    </Tooltip>
  );
};

const BulletinThumbnail = ({
  bulletin,
  setActiveBulletin,
  togglePinned,
  highlighted,
  isPinningId,
  canUserAddEdit,
}: BulletinThumbnailProps) => {
  const { title, content, pinned } = bulletin;
  const styles = useStyles({ highlighted });
  const containerStyles = useContainerStyles();
  const editorState = useMemo(() => EditorState.createWithContent(convertFromRaw(JSON.parse(content))), [content]);
  const togglePinStatus = (event: SyntheticEvent) => {
    event.stopPropagation();
    togglePinned(bulletin);
  };

  const isCurrentlyPinning = bulletin.bulletinId === isPinningId;
  const thumbnailStyles = useThumbnailStyles();

  return (
    <>
      <Box
        key={title}
        pt={4}
        className={styles.thumbnail}
        role='button'
        title='Edit Bulletin'
        onClick={() => (canUserAddEdit ? setActiveBulletin(bulletin) : undefined)}
      >
        <Box
          mb={1}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          className={`${containerStyles.container} ${thumbnailStyles.date}`}
          minHeight={'30px'}
        >
          {bulletin.dateCreated && (
            <Typography color='textSecondary' variant='body2' style={{ fontSize: 'inherit' }}>
              Published{' '}
              <SemanticTime
                dateTime={unpackToDateTime(bulletin.dateCreated)}
                typographyProps={{
                  variant: 'body2',
                  color: 'textSecondary',
                  style: { fontSize: 'inherit' },
                }}
                displayYear
              />
            </Typography>
          )}
          {pinned && !isCurrentlyPinning && (
            <TitularTooltip description='Click to unpin'>
              <Box>
                <Box
                  bgcolor='#FABE4D'
                  display='flex'
                  component='div'
                  alignItems='center'
                  color='white'
                  padding={'3px 10px 3px 6px'}
                  paddingRight={1.5}
                  borderRadius={4}
                >
                  <LabelImportantRounded />
                  <Box ml={0.5} component='span'>
                    <Typography style={{ fontSize: '0.835rem', fontWeight: 550 }}>PINNED</Typography>
                  </Box>
                </Box>
              </Box>
            </TitularTooltip>
          )}
          {!pinned && !isCurrentlyPinning && (
            <TitularTooltip description='Pin to top'>
              <Box id='pin' className={styles.pin}>
                <HoverText color='textSecondary' onClick={togglePinStatus} hoverColor='textSecondary' disableUnderline>
                  <Box display='flex' alignItems='center' component='span'>
                    <LabelImportantRounded id='pinIcon' />
                    <Box ml={0.5} component='span'>
                      <Typography style={{ fontSize: '0.835em', fontWeight: 550 }}>PIN BULLETIN</Typography>
                    </Box>
                  </Box>
                </HoverText>
              </Box>
            </TitularTooltip>
          )}
          {isCurrentlyPinning && <CircularProgress size={30} />}
        </Box>
        <Box className={containerStyles.container} pt={1.3}>
          <Typography variant='h5' className={thumbnailStyles.header}>
            {title}
          </Typography>
          <Box maxHeight={40} mt={1} mb={2} overflow='hidden' className={thumbnailStyles.body}>
            <CampfireRichEditor editorState={editorState} readOnly />
          </Box>
          <Typography color='textSecondary' variant='body2' className={thumbnailStyles.date}>
            {bulletin.lastUpdatedBy
              ? `${bulletin.lastUpdatedBy.profile.preferredName} ${bulletin.lastUpdatedBy.profile.lastName}`
              : `${bulletin.createdBy?.profile.preferredName} ${bulletin.createdBy?.profile.lastName}`}{' '}
            {bulletin.dateUpdated && (
              <>
                (Updated{' '}
                <SemanticTime
                  dateTime={unpackToDateTime(bulletin.dateUpdated)}
                  typographyProps={{ variant: 'body2', color: 'textSecondary', style: { fontSize: 'inherit' } }}
                  displayYear
                />
                )
              </>
            )}
          </Typography>
          <Box className={thumbnailStyles.segmentationContainer} mt={2}>
            <Segmentation segmentationType='Program' segmentation={bulletin.programs} />
            <Segmentation segmentationType='Activity' segmentation={bulletin.activities} />
            <Segmentation segmentationType='Role' segmentation={bulletin.activityRoles} />
          </Box>
          <Box pt={4}>
            <Divider />
          </Box>
        </Box>
      </Box>
    </>
  );
};

const BulletinManagementList = ({
  addBulletin,
  setActiveBulletin,
  bulletins,
  togglePinned,
  isPinningId,
}: BulletinsListProps) => {
  const { theme, isMobile, isMd } = useCampfireTheme();
  const classes = useListStyles();
  const pinnedBulletins = useMemo(
    () => (bulletins && bulletins.length > 0 ? bulletins.filter((bulletin) => bulletin.pinned) : []),
    [bulletins]
  );
  const unpinnedBulletins = useMemo(() => (bulletins || []).filter((b) => !b.pinned), [bulletins]);
  const containerStyles = useContainerStyles();

  const { data: bulletinSettings } = useCampfireQuery<GetAdminConsoleBulletinSettings, undefined>(
    GET_ADMIN_CONSOLE_BULLETIN_SETTINGS
  );

  const {
    user: { userIdentityService },
  } = useUser();

  const canUserAddEdit =
    userIdentityService.isVmAdmin ||
    (userIdentityService.isProgramManager && bulletinSettings?.vm.bulletinAllowManagerAction);

  const { lightTextColor } = getAlertCardColors('info');

  return (
    <Box pt={2}>
      <Box display='flex' alignItems='flex-end' justifyContent='space-between' className={containerStyles.container}>
        <Typography
          variant='h4'
          display='inline'
          style={{ fontSize: '20px', fontWeight: 800, color: '#3E3229', lineHeight: 0.7 }}
        >
          Bulletin Board
        </Typography>
        <TabletButton
          variant='contained'
          style={{
            backgroundColor: canUserAddEdit ? theme.color.secondary.main900 : theme.color.grey.light,
            opacity: canUserAddEdit ? 1 : 0.5,
            color: '#ffffff',
            boxSizing: 'border-box',
            borderRadius: '4px',
            textTransform: 'none',
            padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
            minWidth: '40px',
          }}
          onClick={addBulletin}
          disabled={!canUserAddEdit}
        >
          <BorderColorOutlined style={{ marginTop: '1px', fontSize: '16px' }} />
          <Typography
            className={classes.buttonText}
            style={{
              display: isMobile || isMd ? 'none' : 'inherit',
            }}
          >
            Add New Bulletin
          </Typography>
        </TabletButton>
      </Box>
      <Box pt={2}>
        <Divider />
      </Box>
      <Box className={containerStyles.container} pt={2}>
        <Box mt={1} mb={2}>
          <Typography>
            Bulletins are useful for contacting your entire volunteer base at once. Use them for announcements, updates,
            and other important information that you want to have recorded.
          </Typography>
        </Box>
        {!canUserAddEdit ? (
          <Box px={2}>
            <AlertCard variant='info' title={'Adding & Editing Not Available'}>
              <AlertCardBody>
                <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                  Your organisation has turned off your ability to create and edit bulletins. Get in contact with one of
                  your admins if you think this is a mistake.
                </Typography>
              </AlertCardBody>
            </AlertCard>
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Box mt={2}>
        {pinnedBulletins.length > 0 &&
          pinnedBulletins
            ?.sort((a, b) => {
              return unpackToDateTime(b.dateCreated).valueOf() - unpackToDateTime(a.dateCreated).valueOf();
            })
            .map((bulletin) => (
              <BulletinThumbnail
                highlighted
                bulletin={bulletin}
                key={bulletin.bulletinId}
                setActiveBulletin={setActiveBulletin}
                togglePinned={togglePinned}
                isPinningId={isPinningId}
                canUserAddEdit={canUserAddEdit ?? false}
              />
            ))}

        {unpinnedBulletins
          ?.sort((a, b) => {
            return unpackToDateTime(b.dateCreated).valueOf() - unpackToDateTime(a.dateCreated).valueOf();
          })
          .map((bulletin) => (
            <BulletinThumbnail
              highlighted={false}
              bulletin={bulletin}
              key={bulletin.title}
              setActiveBulletin={setActiveBulletin}
              togglePinned={togglePinned}
              isPinningId={isPinningId}
              canUserAddEdit={canUserAddEdit ?? false}
            />
          ))}
      </Box>
    </Box>
  );
};

export { BulletinManagementList };
