import React from 'react';
import { Box, Button, ButtonGroup, Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import {
  CreatWidgetFormValues,
  WidgetTypes,
  WidgetValueType,
  supportingWidgetTypes,
  valueLists,
  widgetTypes,
} from '../dashboard-widget-model';
import { ValueGroup } from '../SelectCreateWidgetBy';
import { SelectWidgetType } from './SelectWidgetType';
import { containerStyle } from '../styles';

export enum Tabs {
  TYPE = 'TYPE',
  VALUE = 'VALUE',
}

interface Props {
  activeTab: Tabs;
  onSetTab: (tab: Tabs) => void;
}

export function WidgetSelect({ activeTab, onSetTab }: Props) {
  const { values, setFieldValue } = useFormikContext<CreatWidgetFormValues>();

  const onSelectType = (value: WidgetTypes) => {
    setFieldValue('byType', value);
  };

  const onSelectValue = (value: WidgetValueType) => {
    setFieldValue('byValues', [value]);
  };

  const classes = containerStyle();

  const valueItems = React.useMemo(() => {
    if (values.byType === WidgetTypes.REQUIRING_ACTION) {
      return valueLists
        .map((tag) => ({
          tag,
          values: tag.values.filter((value) =>
            get(supportingWidgetTypes, [tag.value, value.value, 'requiringActionWidgetType'], false)
          ),
        }))
        .filter(({ values: tagValues }) => tagValues.length > 0)
        .map(({ tag, values: tagValues }) => ({ ...tag, values: tagValues }));
    }

    if (values.byType === WidgetTypes.TABLE) {
      return valueLists
        .map((tag) => ({
          tag,
          values: tag.values.filter((value) =>
            get(supportingWidgetTypes, [tag.value, value.value, 'tableWidgetType'], false)
          ),
        }))
        .filter(({ values: tagValues }) => tagValues.length > 0)
        .map(({ tag, values: tagValues }) => ({ ...tag, values: tagValues }));
    }

    return valueLists;
  }, [values.byType]);

  const availableWidgets = React.useMemo(() => {
    if (values.byValues.length !== 0) {
      return widgetTypes.filter(({ type }) => {
        if (type !== WidgetTypes.REQUIRING_ACTION) {
          return true;
        }
        return values.byValues.every(({ tag, name }) =>
          get(supportingWidgetTypes, [tag.toLowerCase(), name.toLowerCase(), 'requiringActionWidgetType'], false)
        );
      });
    }
    return widgetTypes;
  }, [values.byValues]);

  return (
    <Grid container direction='column' style={{ width: '100%' }}>
      <Box display='flex' justifyContent='center'>
        <ButtonGroup disableElevation variant='contained'>
          <Button
            onClick={() => onSetTab(Tabs.TYPE)}
            style={{
              textTransform: 'none',
              borderRadius: 8,
              paddingLeft: 40,
              paddingRight: 40,
              marginRight: -5,
            }}
            color='primary'
            variant={activeTab === Tabs.TYPE ? 'contained' : 'outlined'}
          >
            By Type
          </Button>
          <Button
            style={{
              textTransform: 'none',
              borderRadius: 8,
              paddingLeft: 40,
              paddingRight: 40,
              marginLeft: -5,
            }}
            onClick={() => onSetTab(Tabs.VALUE)}
            color='primary'
            variant={activeTab === Tabs.VALUE ? 'contained' : 'outlined'}
          >
            By Value
          </Button>
        </ButtonGroup>
      </Box>
      <Box className={classes.root} style={{ paddingTop: '1rem' }}>
        {activeTab === Tabs.TYPE && (
          <SelectWidgetType
            availableWidgets={availableWidgets}
            value={values.byType ?? undefined}
            onSelect={onSelectType}
          />
        )}
        {activeTab === Tabs.VALUE && (
          <Box>
            {valueItems.map((group) => (
              <ValueGroup group={group} onSelect={onSelectValue} selectedValue={values.byValues[0]} />
            ))}
          </Box>
        )}
      </Box>
    </Grid>
  );
}
