import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { StringParam, useQueryParam } from 'use-query-params';
import { Skeleton } from '@material-ui/lab';
import { TemplateItem } from './TemplateItem';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetCommunicationTemplate } from './__generated__/GetCommunicationTemplate';
import { GET_COMMUNICATION_TEMPLATE } from './communication-template-model.gql';
import { TemplateItemContent } from './TemplateItemContent/TemplateItemContent';
import { useDeepEffect } from '../../../../../hooks/useDeepEffect';
import { GET_TENANT_CONFIG } from './tenant-config-model.gql';
import { GetTenantConfig } from './__generated__/GetTenantConfig';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: '1 1 0',
      overflowY: 'scroll',
      display: 'flex',
      alignItems: 'stretch',
      marginTop: '20px',
    },
    list: {
      flexBasis: '330px',
      overflowY: 'scroll',
    },
    content: {
      flex: 1,
      backgroundColor: '#54545440',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export function TemplateContent() {
  const classes = useStyles();

  const { data, loading: communicationTemplateLoading } = useCampfireQuery<GetCommunicationTemplate, {}>(
    GET_COMMUNICATION_TEMPLATE,
    {}
  );
  const { data: tenantData, loading: tenantDataLoading } = useCampfireQuery<GetTenantConfig, {}>(GET_TENANT_CONFIG, {});

  const [templateId, setTemplateId] = React.useState<string>();
  const [q] = useQueryParam('q', StringParam);

  const templates = data?.vm.communicationTemplates.concat(data?.im.communicationTemplates) || [];
  const orderedTemplates = templates.filter((temp) => !temp.isHidden).sort((a, b) => a.name.localeCompare(b.name));
  const filteredTemplates = useMemo(
    () => orderedTemplates.filter((temp) => (q ? temp.name.toLowerCase().includes(q.toLowerCase()) : true)),
    [q, templates]
  );

  useDeepEffect(() => {
    setTemplateId(orderedTemplates[0]?.communicationTypeId);
  }, [orderedTemplates]);

  return (
    <Box className={classes.root}>
      <Box className={classes.list}>
        {communicationTemplateLoading ? (
          <Skeleton variant='rect' width='100%' height='100%' />
        ) : (
          filteredTemplates.map((template) => (
            <TemplateItem
              title={template.name}
              key={template.communicationTypeId}
              type={template.type}
              enabled={Boolean(template.status)}
              lastUpdated=''
              onSelect={() => setTemplateId(template.communicationTypeId)}
              isActive={template.communicationTypeId === templateId}
            />
          ))
        )}
      </Box>
      <Box className={classes.content}>
        <TemplateItemContent
          communicationTypeId={templateId}
          greeting={tenantData?.tm.tenant.config?.communication?.greeting}
          signoff={tenantData?.tm.tenant.config?.communication?.signoff}
          logo={tenantData?.tm.tenant.config?.logo}
          tenantName={tenantData?.tm.tenant.name}
          isLoading={tenantDataLoading}
        />
      </Box>
    </Box>
  );
}
