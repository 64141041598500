import React, { useMemo } from 'react';
import { List, ListSubheader, Box } from '@material-ui/core';
import { QueryBuilderOutlined, PanToolOutlined, RoomOutlined } from '@material-ui/icons';
import { SectionCollapsible } from '../common/SectionCollapsible';
import { SectionHeader } from '../common/SectionHeader';
import { useVolunteerCommonProfileContext } from '../VolunteerCommonProfileContext';
import { SectionInstruction } from '../common/SectionInstruction';
import { Statistics } from '../../../../common/statistics/Statistics';

export const VolunteerImpactSection = () => {
  const {
    volunteer,
    volunteerNameOptions: { preferredName },
  } = useVolunteerCommonProfileContext();

  const attended = useMemo(() => {
    return volunteer?.volunteerStatistics.attended ?? 0;
  }, [volunteer]);

  const hours = useMemo(() => {
    const operationalHours = volunteer?.volunteerStatistics.sessionReportStatistics.operationalHours ?? 0;
    const additionalHours = parseFloat(`${volunteer?.profile.additionalVolunteerHours ?? '0'}`);
    return operationalHours + additionalHours ?? 0;
  }, [volunteer]);

  const roundedHours = Math.round(hours * 100) / 100;

  const locations = useMemo(() => {
    return volunteer?.volunteerStatistics.sessionReportStatistics.activityLocations.length ?? 0;
  }, [volunteer]);

  return (
    <SectionCollapsible
      sectionHeader={<SectionHeader title='Impact' />}
      instruction={<SectionInstruction>{`${preferredName}'s Volunteering Impact`}</SectionInstruction>}
      collapseContent={
        <List disablePadding dense>
          <ListSubheader disableSticky>{`${preferredName}'s volunteering impact`}</ListSubheader>
          {volunteer && (
            <Box mt='24px'>
              <Box mb='16px'>
                <Statistics
                  value={roundedHours}
                  icon={QueryBuilderOutlined}
                  title='Hours'
                  subtitle='of volunteering'
                  color='#7570EA'
                />
              </Box>
              <Box mb='16px'>
                <Statistics
                  value={attended}
                  icon={PanToolOutlined}
                  title='Activities'
                  subtitle='attended'
                  color='#FE6F40'
                />
              </Box>

              <Box mb='16px'>
                <Statistics
                  value={locations}
                  icon={RoomOutlined}
                  title='Locations'
                  subtitle='visited'
                  color='#60B386'
                />
              </Box>
            </Box>
          )}
        </List>
      }
      expandText='View impact'
      idStub='impact'
    />
  );
};
