import classNames from 'classnames';
import React from 'react';
import List from '@material-ui/core/List';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { DrawerNavListItem } from './DrawerNavListItem';
import { SideNavGroupInterface } from '../../../content-blocks/common/routes-map-model';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

interface Props {
  sideNavGroup: SideNavGroupInterface;
  closeDrawer: () => void;
  isDrawerOpen: boolean;
  activeSideNavRoute: string;
  setActiveSideNavRoute: any;
}
const useDesktopStyles = makeStyles(() =>
  createStyles({
    listItemStyle: {
      padding: '8px 25px',
    },
    listItemTextStyle: {
      color: '#9E9E9E',
      backgroundColor: '#282828',
    },
    listItemTextActiveStyle: {
      color: '#fcfcfc',
    },
    listItemIconStyle: {
      color: 'rgba(255,255,255,0.5)',
      minWidth: 34,
    },
    listItemIconActiveStyle: {
      color: '#fcfcfc',
    },
  })
);

const useMobileStyles = makeStyles(() =>
  createStyles({
    listItemStyle: {
      padding: '8px 16px',
    },
    listItemTextStyle: {
      color: 'rgba(0,0,0,0.6)',
      backgroundColor: 'white',
    },
    listItemTextActiveStyle: {
      color: '#000',
    },
    listItemIconStyle: {
      color: 'rgba(0,0,0,0.6)',
      minWidth: 34,
    },
    listItemIconActiveStyle: {
      color: '#000',
    },
  })
);
const DrawerNavList = ({
  sideNavGroup,
  closeDrawer,
  isDrawerOpen,
  activeSideNavRoute,
  setActiveSideNavRoute,
}: Props) => {
  const { isMobile } = useCampfireTheme();
  const { heading, Icon } = sideNavGroup;
  const classes = isMobile ? useMobileStyles() : useDesktopStyles();
  const isActive =
    activeSideNavRoute.includes('warmth') || activeSideNavRoute.includes('beta')
      ? sideNavGroup.items.some((item) => activeSideNavRoute.split('/')[2] === item.route)
      : sideNavGroup.heading.toLowerCase() === activeSideNavRoute.split('/')[1];

  return (
    <List>
      {sideNavGroup.heading ? (
        <ListItem className={classes.listItemStyle}>
          <ListItemIcon
            className={classNames(classes.listItemIconStyle, { [classes.listItemIconActiveStyle]: isActive })}
          >
            <Icon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                className={classNames(classes.listItemTextStyle, { [classes.listItemTextActiveStyle]: isActive })}
                style={{ whiteSpace: isDrawerOpen ? 'normal' : 'nowrap', fontWeight: 'bold' }}
                variant='body1'
              >
                {heading}
              </Typography>
            }
          />
        </ListItem>
      ) : null}
      {sideNavGroup.items.map((sideNavItem) => {
        if (!sideNavItem.isAuthorized) return null;
        const urlPath = `/${sideNavGroup.route}/${sideNavItem.route}`;

        return (
          <DrawerNavListItem
            key={urlPath}
            closeDrawer={closeDrawer}
            isDrawerOpen={isDrawerOpen}
            urlPath={urlPath}
            activeSideNavRoute={activeSideNavRoute}
            setActiveSideNavRoute={setActiveSideNavRoute}
            subListItem={sideNavItem}
          />
        );
      })}
    </List>
  );
};

export { DrawerNavList };
