import React, { FunctionComponent } from 'react';
import { FabChecklist } from '@campfire/fab-checklist';
import { AppBar as MuiAppBar, Box } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { AppBarAvatarMenu } from './AppBarAvatarMenu';
import { CampfireHeader } from '../../components';
import { useOnboardingContext } from '../../onboarding/useOnboardingContext';
import { AvailabilityCenter } from '../../components/AvailabilityCenter/AvailabilityCenter';

interface Props {
  isDrawerOpen: boolean;
  toggleDrawerOpen: () => void;
}

const CampfireAppBar: FunctionComponent<Props> = ({ toggleDrawerOpen, isDrawerOpen }) => {
  const { theme, isMobile } = useCampfireTheme();

  const FabChecklistWrapper = () => {
    const { currentOnboardingStep, finishedOnboarding } = useOnboardingContext();
    return finishedOnboarding === false ? (
      <FabChecklist isMobile={isMobile} currentStep={currentOnboardingStep} />
    ) : null;
  };

  return (
    <MuiAppBar
      position='relative'
      style={{
        backgroundColor: '#423951',
        color: '#ffffff',
        boxShadow: 'none',
      }}
    >
      <Toolbar style={{ paddingLeft: 24, paddingRight: 8, minHeight: theme.appShell.menu.height }}>
        {isMobile || !isDrawerOpen ? (
          <IconButton
            color='inherit'
            onClick={() => {
              toggleDrawerOpen();
            }}
            style={{ marginLeft: '-16px' }}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        <CampfireHeader condensed />

        <div style={{ flex: 1 }} />

        <FabChecklistWrapper />
        <Box display='flex' pl='12px'>
          <AvailabilityCenter />
          <AppBarAvatarMenu />
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export const AppBar = CampfireAppBar;
