type ScrollIntoViewOptions = {
  behavior?: 'auto' | 'smooth';
  block?: 'start' | 'center' | 'end' | 'nearest';
  inline?: 'start' | 'center' | 'end' | 'nearest';
};

export function scrollIntoView(
  ref: React.RefObject<HTMLDivElement> | React.RefObject<HTMLLIElement>,
  { behavior = 'auto', block = 'start', inline = 'nearest' }: ScrollIntoViewOptions,
  alignTop: boolean
) {
  try {
    if (ref.current) ref.current.scrollIntoView({ behavior, block, inline });
  } catch (err) {
    // Fallback for unsupported browsers - Edge, IE, Safari & Safari IOS
    if (ref.current) ref.current.scrollIntoView(alignTop);
  }
}

type ScrollToOptions = {
  top?: number;
  left?: number;
  behavior?: 'smooth' | 'auto';
};
export function scrollTo(
  ref: React.RefObject<HTMLDivElement>,
  { top = 0, left = 0, behavior = 'auto' }: ScrollToOptions
) {
  try {
    if (ref.current) ref.current.scrollTo({ top, left, behavior });
  } catch (err) {
    // Fallback for unsupported browsers - Edge, IE, Safari & Safari IOS
    if (ref.current) ref.current.scrollTo(left, top);
  }
}
