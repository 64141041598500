import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { AdminConsoleSideNav } from './admin-console-side-nav/AdminConsoleSideNav';
import { AdminConsoleContentBlock } from './admin-console-content-pages/AdminConsoleContentBlock';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { AdminConsoleTutorialDialog } from './AdminConsoleTutorialDialog';
import { Page } from '../../../global/components';

export const SIDE_NAV_WIDTH = 280;

export const AdminConsoleScreen = withRouter(({ match }: RouteComponentProps) => {
  const { isMobile, theme } = useCampfireTheme();
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      {isMobile ? (
        <Box
          style={{ boxSizing: 'border-box' }}
          height={'66%'}
          width={1}
          display='flex'
          alignContent='center'
          alignItems='center'
          justifyContent='center'
          paddingX={3}
        >
          <Typography align='center' variant='h5' color='textSecondary'>
            {'Please switch to a desktop to use Admin Console'}
          </Typography>
        </Box>
      ) : (
        <>
          <Box display='flex'>
            <Box
              width={SIDE_NAV_WIDTH}
              style={{ backgroundColor: '#ffffff', borderRight: `1px solid ${theme.color.grey.border}` }}
            >
              <AdminConsoleSideNav />
            </Box>
            <Box
              style={{
                overflow: 'auto',
                width: '100%',
              }}
            >
              <AdminConsoleContentBlock route={match.path} />
            </Box>
          </Box>
        </>
      )}
      <AdminConsoleTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
        }}
      />
    </Page>
  );
});
