import { encodeDate, formatDistanceDays, unpackToDate, unpackToDateTime } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { ArrowPopper } from '@campfire/popper';
import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip';
import {
  Box,
  Chip,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import { Close, ExpandMore, InfoRounded, KeyboardArrowUp } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { DateTime } from 'luxon';
import React, { Fragment, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useBetween } from 'use-between';
import { useQueryParam } from 'use-query-params';
import { DateInfoCard } from '../../../../../common/cards/date-info-card/DateInfoCard';
import { getDisplayTimeSchedule, getShortDisplayName } from '../../../../../common/functions/activity-display-helpers';
import { PopUpWindow } from '../../../../../common/PopUpWindow';
import { TemplateSnackbar } from '../../../../../common/snackbars/TemplateSnackbar';
import { useOnboardingContext } from '../../../../../global/onboarding/useOnboardingContext';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import {
  ActivityApplicationEndpointActions,
  PostApplicationDataType,
  useActivityApplicationFetch,
} from '../../../../../hooks/use-activity-application-fetch';
import { ActivitySessionsSelectDialog } from './ActivitySessionsSelectDialog';
import { ActivitySessionsSelectUpdateDialog } from './ActivitySessionsSelectUpdateDialog';
import { DateDivider } from './DateDivider';
import {
  getSessionsMessage,
  getStatusMessage,
  getUpcomingRosterStatus,
  KeyValuedUpcomingRosters,
  MyElementsActivityReport,
  MyElementsAttendance,
  MyElementsUpcomingRosterUnpackedDate,
} from './my-elements-upcoming-rosters-model.gql';
import {
  DatePublishedMessage,
  MyElementsRosteringAvailabilityIcon,
  OpenRosterMessage,
} from './MyElementsUpcomingRostersLegend';
import { MyElementsViewUpcomingRosterDialog } from './MyElementsViewUpcomingRosterDialog';
import { UpcomingRostersDayDialog } from './UpcomingRostersDayDialog';
import { useUpcomingRostersFetch } from './useUpcomingRostersFetch';
import { YourActivitiesViewActivityReportDialog } from './YourActivitiesViewActivityReportDialog';
import { MyElementsUpcomingRoster } from './__generated__/MyElementsUpcomingRoster';

interface MyElementsUpcomingRostersListProps {
  loading: boolean;
  datesThisWeek: KeyValuedUpcomingRosters | undefined;
  refetch?: () => void;
  date: DateTime;
  goToNextDatePeriod: () => void;
  goToPreviousDatePeriod: () => void;
  activityView?: boolean;
  activityApplicationEditDialogOpen: boolean;
  setActivityApplicationEditDialogOpen: (value: boolean) => void;
  upcomingRosterDayDialogOpen: boolean;
  handleDayDotDialogClose: () => void;
  clickedDate: DateTime | undefined;
  attendances: MyElementsAttendance[];
  activityReports: MyElementsActivityReport[];
}

interface PastDateListItemActionProps {
  isReportComplete: boolean;
  hasActivityReport: boolean;
  upcomingRoster: MyElementsUpcomingRoster;
  setReportDialogOpen: (x: boolean) => void;
  setSelectedUpcomingRoster: (upcomingRoster: MyElementsUpcomingRoster) => void;
}

interface UpcomingRosterDateGrouping {
  upcomingRosters: MyElementsUpcomingRosterUnpackedDate[];
  dateStamp: string;
}

interface UpcomingRosterListGroupingProps {
  upcomingRostersGroup: UpcomingRosterDateGrouping[];
  createAvailability: (upcomingRoster: MyElementsUpcomingRoster, selectedDates: string[]) => void;
  createUnavailability: (upcomingRoster: MyElementsUpcomingRoster) => void;
  createAttendance: (upcomingRoster: MyElementsUpcomingRoster, selectedDates: string[], sessionIds?: string[]) => void;
  removeAttendance: (upcomingRoster: MyElementsUpcomingRoster) => void;
  activityApplicationPostRequest: (
    postApplicationData: PostApplicationDataType,
    actionType: ActivityApplicationEndpointActions,
    onSuccess?: (() => void) | undefined
  ) => void;
  activityApplicationEditDialogOpen: boolean;
  isTooltipOpen: boolean;
  setIsTooltipOpen: (x: boolean) => void;
  setTooltipHasBeenClosed: (x: boolean) => void;
  setActivityApplicationEditDialogOpen: (x: boolean) => void;
  attendances: MyElementsAttendance[];
  activityReports: MyElementsActivityReport[];
}

type UpcomingRosterListItemProps = {
  isTooltipOpen: boolean;
  hideTooltip: () => void;
  upcomingRoster: MyElementsUpcomingRosterUnpackedDate;
  createAvailability: (x: MyElementsUpcomingRosterUnpackedDate, y: string[], z?: string[]) => void;
  createUnavailability: (x: MyElementsUpcomingRosterUnpackedDate) => void;
  createAttendance: (x: MyElementsUpcomingRoster, y: string[], z?: string[]) => void;
  removeAttendance: (upcomingRoster: MyElementsUpcomingRoster) => void;
  dateStamp: string;
  activityApplicationPostRequest: (
    postApplicationData: PostApplicationDataType,
    actionType: ActivityApplicationEndpointActions
  ) => void;
  activityApplicationEditDialogOpen: boolean;
  setActivityApplicationEditDialogOpen: (value: boolean) => void;
  attendances: MyElementsAttendance[];
  activityReports: MyElementsActivityReport[];
};

type UpcomingRosterListItemActionProps = {
  upcomingRoster: MyElementsUpcomingRosterUnpackedDate;
  setOpenSessionSelectDialog: (x: boolean) => void;
  setOpenSessionSelectUpdateDialog: (x: boolean) => void;
  dateStamp: string;
  setSelectedDateStamp: (x: string) => void;
  setReportDialogOpen: (x: boolean) => void;
  setSelectedUpcomingRoster: (upcomingRoster: MyElementsUpcomingRoster) => void;
  createUnavailability: (upcomingRoster: MyElementsUpcomingRoster) => void;
  removeAttendance: (upcomingRoster: MyElementsUpcomingRoster) => void;
  attended: boolean;
  isPastDate: boolean;
  hasActivityReport: boolean;
  isReportComplete: boolean;
};

type ReturnedDataSessionsRemoved = {
  sessionId: string;
  sessionName: string;
};

type ReturnedDataSessionDatesRemoved = {
  activityDate: string;
  sessionsRemoved: Array<ReturnedDataSessionsRemoved> | undefined;
};

type PopUpWindowOptions =
  | {
      data: {
        addedRosterIds: Array<string>;
        sessionsRemoved: Array<ReturnedDataSessionDatesRemoved>;
      };
      open: boolean;
    }
  | undefined;

// Mui makes it way too hard to do this, but slapping isMobile everywhere really really hurts us
const useUpcomingRosterListItemActionWrapperStyles = makeStyles((theme: Theme) => ({
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      paddingBottom: theme.spacing(1),
      paddingLeft: '82px',
    },
  },
}));

const UpcomingRosterListItemActionWrapper = (props: { children?: ReactNode }) => {
  const { actionWrapper } = useUpcomingRosterListItemActionWrapperStyles();
  return <Box className={actionWrapper}>{props.children}</Box>;
};

const useSessionFullPopupState = () => {
  const [popUpWindowOptions, setPopUpWindowOptions] = useState<PopUpWindowOptions>();

  return {
    popUpWindowOptions,
    setPopUpWindowOptions,
  };
};

export const useSharedSessionFullPopupState = () => useBetween(useSessionFullPopupState);

const PastDateListItemAction = (props: PastDateListItemActionProps) => {
  const { isReportComplete, hasActivityReport, setReportDialogOpen, setSelectedUpcomingRoster, upcomingRoster } = props;
  const history = useHistory();
  const { activityDate, activity } = upcomingRoster;
  const { activityId } = activity;

  if (isReportComplete) {
    return (
      <HoverText
        color='primary'
        variant='body2'
        disableUnderline
        onClick={() => {
          setReportDialogOpen(true);
          setSelectedUpcomingRoster(upcomingRoster);
        }}
      >
        View report
      </HoverText>
    );
  }

  if (hasActivityReport && !isReportComplete) {
    return (
      <TabletButton
        variant='outlined'
        color='primary'
        onClick={() =>
          history.push(
            `/volunteering/activity-report?activityId=${activityId}&activityDate=${encodeDate(activityDate)}`
          )
        }
      >
        Complete report
      </TabletButton>
    );
  }

  if (!hasActivityReport) {
    return (
      <TabletButton
        variant='outlined'
        color='primary'
        onClick={() =>
          history.push(
            `/volunteering/activity-report?activityId=${activityId}&activityDate=${encodeDate(activityDate)}`
          )
        }
      >
        Start a report
      </TabletButton>
    );
  }

  return null;
};

const UpcomingRosterUnspecifiedActions = ({
  hasOpenRoster,
  createUnavailability,
  removeAttendance,
  setOpenSessionSelectDialog,
}: {
  hasOpenRoster: boolean;
  createUnavailability: Function;
  removeAttendance: Function;
  setOpenSessionSelectDialog: Function;
}) => {
  return (
    <>
      <Box paddingRight={'2px'}>
        <TabletButton
          data-track='upcRos-activity-open-nil-no'
          variant='outlined'
          color={hasOpenRoster ? 'error' : 'primary'}
          onClick={() => {
            createUnavailability();
            removeAttendance();
          }}
        >
          {hasOpenRoster ? 'Withdraw' : 'Not\u00A0Available'}
        </TabletButton>
      </Box>
      <Box paddingLeft={'2px'}>
        <TabletButton
          variant={hasOpenRoster ? 'outlined' : 'contained'}
          data-track='upcRos-activity-nil-yes'
          onClick={() => setOpenSessionSelectDialog()}
        >
          {hasOpenRoster ? 'Update' : 'Available'}
        </TabletButton>
      </Box>
    </>
  );
};

const UpcomingRosterUnavailableActions = ({
  hasOpenRoster,
  setOpenSessionSelectDialog,
}: {
  hasOpenRoster: boolean;
  setOpenSessionSelectDialog: Function;
}) => {
  return (
    <TabletButton
      data-track='upcRos-activity-unavail-apply'
      variant='contained'
      color='primary'
      onClick={() => setOpenSessionSelectDialog()}
    >
      {hasOpenRoster ? 'Attend' : 'Available'}
    </TabletButton>
  );
};

const UpcomingRosterAvailableActions = ({
  createUnavailability,
  setOpenSessionSelectUpdateDialog,
}: {
  createUnavailability: Function;
  setOpenSessionSelectUpdateDialog: Function;
}) => {
  return (
    <>
      <Box paddingRight={'2px'}>
        <TabletButton
          data-track='upcRos-activity-avail-withdraw'
          variant='outlined'
          color='error'
          onClick={() => createUnavailability()}
        >
          Withdraw
        </TabletButton>
      </Box>
      <Box paddingLeft={'2px'}>
        <TabletButton
          data-track='upcRos-activity-avail-edit'
          variant='outlined'
          onClick={() => setOpenSessionSelectUpdateDialog()}
        >
          Update
        </TabletButton>
      </Box>
    </>
  );
};

const UpcomingRosterRosteredActions = ({
  hasOpenRoster,
  handleViewRoster,
  setOpenSessionSelectUpdateDialog,
  removeAttendance,
}: {
  hasOpenRoster: boolean;
  handleViewRoster: Function;
  setOpenSessionSelectUpdateDialog: Function;
  removeAttendance: Function;
}) => {
  const [openMoreOptionsMenu, setOpenMoreOptionsMenu] = useState(false);
  const moreOptionsMenuAnchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Box paddingRight='2px'>
        <TabletButton
          data-track='upcRos-activity-rostered-view-open-roster'
          variant='outlined'
          color={'primary'}
          onClick={() => handleViewRoster()}
        >
          View&nbsp;Roster
        </TabletButton>
      </Box>
      {!hasOpenRoster ? null : (
        <Box paddingLeft='2px'>
          <TabletButton
            data-track='upcRos-activity-rostered-open-roster-more-actions'
            variant='outlined'
            onClick={() => setOpenMoreOptionsMenu(true)}
            endIcon={<ExpandMore />}
            aria-controls='open-roster-more-options-menu'
            aria-haspopup='true'
            ref={moreOptionsMenuAnchorRef}
          >
            {'More'}
          </TabletButton>
          <Menu
            id='more-options-menu'
            anchorEl={moreOptionsMenuAnchorRef ? moreOptionsMenuAnchorRef.current : undefined}
            keepMounted
            open={openMoreOptionsMenu}
            onBackdropClick={() => setOpenMoreOptionsMenu(false)}
          >
            <MenuItem
              data-track='upcRos-activity-rostered-open-roster-update'
              onClick={() => {
                setOpenSessionSelectUpdateDialog();
              }}
            >
              {'Update'}
            </MenuItem>
            <MenuItem
              data-track='upcRos-activity-rostered-open-roster-withdraw'
              onClick={() => {
                removeAttendance();
              }}
            >
              <Typography color='error'>{'Withdraw'}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      )}
    </>
  );
};

const UpcomingRosterListItemAction = (props: UpcomingRosterListItemActionProps) => {
  const {
    upcomingRoster,
    setOpenSessionSelectDialog,
    setOpenSessionSelectUpdateDialog,
    dateStamp,
    setSelectedDateStamp,
    setReportDialogOpen,
    setSelectedUpcomingRoster,
    createUnavailability,
    removeAttendance,
    isPastDate,
    hasActivityReport,
    isReportComplete,
  } = props;

  const { activity, __typename } = upcomingRoster;

  const rosteredStatusUnspecified =
    __typename === 'VOLUNTEER_UpcomingRosterUnspecifiedType' ||
    (activity.hasOpenRoster && __typename === 'VOLUNTEER_UpcomingRosterAvailabilityType');
  const rosteredStatusRostered = __typename === 'VOLUNTEER_UpcomingRosterRosteringType';
  const rosteredStatusAvailable = __typename === 'VOLUNTEER_UpcomingRosterAvailabilityType' && !activity.hasOpenRoster;
  const rosteredStatusUnavailable = __typename === 'VOLUNTEER_UpcomingRosterUnavailabilityType';

  if (upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterCancellationType') {
    return (
      <UpcomingRosterListItemActionWrapper>
        <Typography variant='body2'>Activity cancelled</Typography>
      </UpcomingRosterListItemActionWrapper>
    );
  }

  if (isPastDate) {
    return (
      <UpcomingRosterListItemActionWrapper>
        <PastDateListItemAction
          isReportComplete={isReportComplete}
          hasActivityReport={hasActivityReport}
          upcomingRoster={upcomingRoster}
          setReportDialogOpen={setReportDialogOpen}
          setSelectedUpcomingRoster={setSelectedUpcomingRoster}
        />
      </UpcomingRosterListItemActionWrapper>
    );
  }

  if (rosteredStatusUnspecified) {
    return (
      <UpcomingRosterListItemActionWrapper>
        <UpcomingRosterUnspecifiedActions
          hasOpenRoster={activity.hasOpenRoster}
          createUnavailability={() => createUnavailability(upcomingRoster)}
          removeAttendance={() => removeAttendance(upcomingRoster)}
          setOpenSessionSelectDialog={() => setOpenSessionSelectDialog(true)}
        />
      </UpcomingRosterListItemActionWrapper>
    );
  }

  if (rosteredStatusUnavailable) {
    return (
      <UpcomingRosterListItemActionWrapper>
        <UpcomingRosterUnavailableActions
          hasOpenRoster={activity.hasOpenRoster}
          setOpenSessionSelectDialog={() => setOpenSessionSelectDialog(true)}
        />
      </UpcomingRosterListItemActionWrapper>
    );
  }

  if (rosteredStatusAvailable) {
    return (
      <UpcomingRosterListItemActionWrapper>
        <UpcomingRosterAvailableActions
          createUnavailability={() => createUnavailability(upcomingRoster)}
          setOpenSessionSelectUpdateDialog={() => setOpenSessionSelectUpdateDialog(true)}
        />
      </UpcomingRosterListItemActionWrapper>
    );
  }

  if (rosteredStatusRostered) {
    return (
      <UpcomingRosterListItemActionWrapper>
        <UpcomingRosterRosteredActions
          hasOpenRoster={activity.hasOpenRoster}
          handleViewRoster={() => {
            setSelectedUpcomingRoster(upcomingRoster);
            setSelectedDateStamp(dateStamp);
          }}
          setOpenSessionSelectUpdateDialog={() => setOpenSessionSelectUpdateDialog(true)}
          removeAttendance={() => removeAttendance(upcomingRoster)}
        />
      </UpcomingRosterListItemActionWrapper>
    );
  }

  return <UpcomingRosterListItemActionWrapper />;
};

export const UpcomingRosterListItem = (props: UpcomingRosterListItemProps) => {
  const {
    isTooltipOpen,
    hideTooltip,
    upcomingRoster,
    createAvailability,
    createUnavailability,
    createAttendance,
    removeAttendance,
    dateStamp,
    attendances,
    activityReports,
  } = props;

  // const { postInteraction } = recordInteraction('vm');
  const { currentOnboardingStep, setPollingInterval, refresh: refreshPoll } = useOnboardingContext();
  const ref = useRef<HTMLDivElement>(null);

  const [selectedUpcomingRoster, setSelectedUpcomingRoster] = useState<MyElementsUpcomingRoster | undefined>(undefined);
  const [selectedDateStamp, setSelectedDateStamp] = useState<string | undefined>(undefined);
  // const [selectedActivityId, setSelectedActivityId] = useState<string | undefined>(undefined);
  const [openSessionSelectDialog, setOpenSessionSelectDialog] = useState(false);
  const [openSessionSelectUpdateDialog, setOpenSessionSelectUpdateDialog] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  const [_unused, setActivityDate] = useQueryParam('date');

  const { activity } = upcomingRoster;
  const humanReadableTime = getDisplayTimeSchedule(activity.startTime, activity.endTime);

  const status = useMemo(() => getUpcomingRosterStatus(upcomingRoster.__typename), [upcomingRoster.__typename]);

  const sessionsMessage = getSessionsMessage(activity);

  const statusMessage = getStatusMessage(upcomingRoster);

  const dateRosterPublished =
    upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterRosteringType'
      ? upcomingRoster.rostering.publishedRoster.datePublished
      : null;

  const isBushWack =
    upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterRosteringType' &&
    upcomingRoster.rostering.publishedRoster.rosterTemplate !== null;

  const detailsChanged =
    upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterRosteringType' &&
    upcomingRoster.rostering.publishedRoster.rosterTemplate?.detailsChanged;

  const isPastDate = useMemo(
    () => encodeDate(DateTime.local()).substr(0, 10) > encodeDate(upcomingRoster.activityDate).substr(0, 10),
    [upcomingRoster.activityDate]
  );

  const attended = useMemo(
    () =>
      isPastDate &&
      attendances.find((attendance) => {
        return (
          attendance.activityId === upcomingRoster.activity.activityId &&
          encodeDate(attendance.activityDate).substr(0, 10) === encodeDate(upcomingRoster.activityDate).substr(0, 10)
        );
      }) !== undefined,
    [isPastDate, attendances, upcomingRoster.activity.activityId]
  );

  const hasActivityReport = useMemo(
    () =>
      isPastDate &&
      activityReports.find(
        (activityReport) =>
          activityReport.activityId === upcomingRoster.activity.activityId &&
          encodeDate(activityReport.activityDate).substr(0, 10) ===
            encodeDate(upcomingRoster.activityDate).substr(0, 10)
      ) !== undefined,
    [isPastDate, activityReports, upcomingRoster.activity.activityId]
  );

  const isReportComplete = useMemo(
    () =>
      activityReports.find(
        (activityReport) =>
          activityReport.activityId === upcomingRoster.activity.activityId &&
          encodeDate(activityReport.activityDate).substr(0, 10) ===
            encodeDate(upcomingRoster.activityDate).substr(0, 10) &&
          activityReport.completed
      ) !== undefined,
    [activityReports, upcomingRoster.activity.activityId]
  );

  return (
    <>
      <ListItem dense style={{ minHeight: 65 }}>
        <Grid container direction='row' justify='space-between' alignItems='center'>
          <Grid item xs={12} sm={8}>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
              <Box paddingRight={2}>
                <MyElementsRosteringAvailabilityIcon status={status} size={64} isPastDate={isPastDate} />
              </Box>
              <ListItemText
                primary={
                  <>
                    {activity.hasOpenRoster ? (
                      <OpenRosterMessage />
                    ) : !isPastDate ? (
                      <DatePublishedMessage status={status} upcomingRoster={upcomingRoster} />
                    ) : null}
                    <TitularTooltip description='View activity information' enterDelay={400}>
                      <span
                        data-track='upcRos-activity-item-name'
                        ref={ref}
                        onClick={(e) => {
                          e.stopPropagation();
                          hideTooltip();
                          // setSelectedActivityId(activity.activityId);
                          setActivityDate(upcomingRoster.activityDate.toFormat('yyyy-MM-dd'));
                        }}
                      >
                        <Box display='flex' alignItems='center' mb={1}>
                          {detailsChanged ? (
                            <Chip
                              label='Details Changed'
                              size='small'
                              style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
                            />
                          ) : null}
                          {isBushWack && dateRosterPublished ? (
                            <Box ml={1}>
                              <Typography variant='body2' color='textSecondary'>{`Added ${formatDistanceDays(
                                unpackToDate(dateRosterPublished)
                              )}`}</Typography>
                            </Box>
                          ) : null}
                        </Box>

                        <HoverText style={{ display: 'inline-block' }}>{getShortDisplayName(activity)}</HoverText>
                      </span>
                    </TitularTooltip>
                  </>
                }
                secondary={
                  <Box component='span' paddingTop={'4px'}>
                    <Box component='span' paddingY={'2px'}>
                      <Typography component='span' variant='body2' color='textPrimary'>
                        {humanReadableTime}
                      </Typography>
                    </Box>

                    <br />

                    {!isPastDate ? (
                      <>
                        <Box component='span' paddingY={'2px'}>
                          <Typography component='span' variant='body2'>
                            {sessionsMessage}
                          </Typography>
                        </Box>

                        <br />

                        <Box component='span' paddingY={'2px'}>
                          <Typography component='span' variant='body2'>
                            {statusMessage}
                          </Typography>
                        </Box>
                      </>
                    ) : null}
                  </Box>
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <UpcomingRosterListItemAction
              upcomingRoster={upcomingRoster}
              setSelectedUpcomingRoster={setSelectedUpcomingRoster}
              setSelectedDateStamp={setSelectedDateStamp}
              createUnavailability={createUnavailability}
              removeAttendance={removeAttendance}
              setOpenSessionSelectDialog={setOpenSessionSelectDialog}
              setOpenSessionSelectUpdateDialog={setOpenSessionSelectUpdateDialog}
              setReportDialogOpen={setReportDialogOpen}
              dateStamp={dateStamp}
              isPastDate={isPastDate}
              attended={attended}
              hasActivityReport={hasActivityReport}
              isReportComplete={isReportComplete}
            />
          </Grid>
        </Grid>
      </ListItem>

      {selectedDateStamp && selectedUpcomingRoster ? (
        <MyElementsViewUpcomingRosterDialog
          selectedUpcomingRoster={selectedUpcomingRoster}
          setSelectedUpcomingRoster={setSelectedUpcomingRoster}
          selectedDateStamp={selectedDateStamp}
          setSelectedDateStamp={setSelectedDateStamp}
        />
      ) : null}

      {selectedUpcomingRoster && (
        <YourActivitiesViewActivityReportDialog
          open={reportDialogOpen}
          setOpen={setReportDialogOpen}
          upcomingRoster={selectedUpcomingRoster}
        />
      )}

      {/* {selectedActivityId ? (
        <ActivityInformationDialog
          open
          handleClose={() => {
            setSelectedActivityId(undefined);
            if (currentOnboardingStep === 4) {
              postInteraction('my-activities', 'completed-onboarding');
              setPollingInterval(200);
              refreshPoll();
            }
          }}
          activityId={selectedActivityId}
        />
      ) : null} */}

      <ArrowPopper
        open={isTooltipOpen}
        close={() => {
          hideTooltip();
        }}
        color='hint'
        anchorElement={ref.current}
        placement='right'
      >
        <Box maxWidth={300} padding={2}>
          <Typography variant='body2'>{'Click here to view activity info'}</Typography>
        </Box>
      </ArrowPopper>

      <ActivitySessionsSelectDialog
        activity={activity}
        dateOfRoster={upcomingRoster.activityDate}
        open={openSessionSelectDialog}
        onClose={() => setOpenSessionSelectDialog(false)}
        sessions={activity.sessions}
        selectedSessions={
          upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterAvailabilityType'
            ? upcomingRoster.activityEnrolment.availabilities
                .filter((availability) => availability.activityDate === dateStamp)
                .flatMap((availability) => availability.sessionAvailabilities.flatMap((s) => s.session.sessionId))
            : upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterRosteringType'
            ? upcomingRoster.rostering.sessionRosterings.map((sr) => sr.session.sessionId)
            : []
        }
        onSubmit={(selectedDates: number[], sessionIds: string[]) => {
          if (activity.hasOpenRoster) {
            createAttendance(
              upcomingRoster,
              selectedDates.map((sd) => {
                return encodeDate(DateTime.fromJSDate(new Date(sd)));
              }),
              sessionIds
            );
          } else {
            createAvailability(
              upcomingRoster,
              selectedDates.map((sd) => {
                return encodeDate(DateTime.fromJSDate(new Date(sd)));
              }),
              sessionIds
            );
          }
          if (currentOnboardingStep === 3) {
            setPollingInterval(200);
            refreshPoll();
          }
          setOpenSessionSelectDialog(false);
        }}
      />
      <ActivitySessionsSelectUpdateDialog
        activity={activity}
        dateOfRoster={upcomingRoster.activityDate}
        open={openSessionSelectUpdateDialog}
        onClose={() => setOpenSessionSelectUpdateDialog(false)}
        sessions={activity.sessions}
        selectedSessions={
          upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterAvailabilityType'
            ? upcomingRoster.activityEnrolment.availabilities
                .filter((availability) => availability.activityDate === dateStamp)
                .flatMap((availability) => availability.sessionAvailabilities.flatMap((s) => s.session.sessionId))
            : upcomingRoster.__typename === 'VOLUNTEER_UpcomingRosterRosteringType'
            ? upcomingRoster.rostering.sessionRosterings.map((sr) => sr.session.sessionId)
            : []
        }
        onSubmit={(selectedDates: number[], sessionIds: string[]) => {
          if (activity.hasOpenRoster) {
            createAttendance(
              upcomingRoster,
              selectedDates.map((sd) => {
                return encodeDate(DateTime.fromJSDate(new Date(sd)));
              }),
              sessionIds
            );
          } else {
            createAvailability(
              upcomingRoster,
              selectedDates.map((sd) => {
                return encodeDate(DateTime.fromJSDate(new Date(sd)));
              }),
              sessionIds
            );
          }
          if (currentOnboardingStep === 3) {
            setPollingInterval(200);
            refreshPoll();
          }
          setOpenSessionSelectUpdateDialog(false);
        }}
      />
    </>
  );
};

export const MyElementsUpcomingRostersList = (props: MyElementsUpcomingRostersListProps) => {
  const {
    datesThisWeek,
    refetch,
    loading,
    date,
    goToNextDatePeriod,
    goToPreviousDatePeriod,
    activityApplicationEditDialogOpen,
    setActivityApplicationEditDialogOpen,
    upcomingRosterDayDialogOpen,
    handleDayDotDialogClose,
    clickedDate,
    attendances,
    activityReports,
  } = props;
  const { currentOnboardingStep, totalOnboardingStepsCount } = useOnboardingContext();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipHasBeenClosed, setTooltipHasBeenClosed] = useState(false);
  const [showPast, setShowPast] = useState(false);
  const history = useHistory();
  const { isMobile } = useCampfireTheme();
  const {
    snackBarOptions,
    createAvailability,
    createUnavailability,
    createAttendance,
    removeAttendance,
  } = useUpcomingRostersFetch(refetch);
  const { activityApplicationPostRequest } = useActivityApplicationFetch(refetch);

  const { popUpWindowOptions, setPopUpWindowOptions } = useSharedSessionFullPopupState();

  const [sessionFullExpanded, setSessionFullExpanded] = useState<boolean>(true);

  useEffect(() => {
    if (refetch) refetch();
  }, [currentOnboardingStep]);

  useEffect(() => {
    if (currentOnboardingStep === totalOnboardingStepsCount - 1 && !tooltipHasBeenClosed) setIsTooltipOpen(true);
  }, [currentOnboardingStep, totalOnboardingStepsCount, tooltipHasBeenClosed]);

  const upcomingRostersGroup: UpcomingRosterDateGrouping[] = useMemo(() => {
    if (datesThisWeek === undefined) return [];
    return Object.keys(datesThisWeek)
      .filter((dateStamp) => {
        if (encodeDate(date.startOf('month')) > dateStamp.substr(0, 10)) return false;
        if (encodeDate(date.endOf('month')) < dateStamp.substr(0, 10)) return false;

        return true;
      })
      .map((dateStamp) => {
        const upcomingRosters = datesThisWeek[dateStamp];
        if (upcomingRosters.length === 0) return null;
        return { upcomingRosters: datesThisWeek[dateStamp], dateStamp: dateStamp };
      })
      .filter((x): x is UpcomingRosterDateGrouping => x !== null);
  }, [datesThisWeek, date]);

  const pastUpcomingRostersGroup = useMemo(() => {
    return (
      upcomingRostersGroup
        .filter((x): x is UpcomingRosterDateGrouping => x !== null)
        .filter((upcomingRosterGroup) => {
          return DateTime.local().minus({ days: 1 }) >= unpackToDateTime(upcomingRosterGroup.dateStamp);
        }) ?? []
    );
  }, [upcomingRostersGroup]);

  const futureUpcomingRostersGroup = useMemo(() => {
    return (
      upcomingRostersGroup.filter((upcomingRosterGroup) => {
        return DateTime.local().minus({ days: 1 }) < unpackToDateTime(upcomingRosterGroup.dateStamp);
      }) ?? []
    );
  }, [upcomingRostersGroup]);

  const hasRosteringsThisPeriod = useMemo(() => {
    for (let i = 0; i < upcomingRostersGroup.length; i++) {
      if (upcomingRostersGroup[i] !== null) {
        return true;
      }
    }
    return false;
  }, [upcomingRostersGroup]);

  const isCurrentMonth = useMemo(() => {
    const now = DateTime.local();
    return now.month === date.month && now.year === date.year;
  }, [date]);

  const isPreviousMonth = useMemo(() => {
    const now = DateTime.local();
    return now.month > date.month && date.year >= now.year;
  }, [date]);

  return (
    <>
      {snackBarOptions && (
        <TemplateSnackbar
          variant={snackBarOptions.variant}
          message={snackBarOptions.message}
          open={snackBarOptions.open}
        />
      )}
      {popUpWindowOptions?.open && (
        <PopUpWindow transparent center>
          <Box
            style={{
              backgroundColor: '#fff',
              boxShadow: '0px 2.752269744873047px 13.761348724365234px 0px #00000029',
              width: '600px',
              borderRadius: '8px',
            }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              padding={'20px 25px'}
              alignItems={'center'}
              borderBottom={'1px solid #e3e3e3'}
            >
              <Box display={'flex'} alignItems={'center'}>
                <InfoRounded style={{ color: '#2196F3' }} />
                <Typography style={{ fontWeight: 700, fontSize: '20px', paddingLeft: '15px' }}>
                  Sessions already full
                </Typography>
              </Box>
              <IconButton
                onClick={() => {
                  setPopUpWindowOptions({ data: popUpWindowOptions.data, open: false });
                }}
              >
                <Close style={{ opacity: '54%' }} />
              </IconButton>
            </Box>
            <Box padding={'20px 25px'} textAlign={'center'}>
              <Typography>
                Some of the dates you have selected have full sessions. You have been marked as &#39;Available&#39; on
                these sessions, and can be added to the roster if another volunteer is to withdraw.
              </Typography>
            </Box>
            <Box padding={'10px 25px 30px 25px'} display={isMobile ? 'hidden' : 'auto'}>
              <ExpansionPanel
                style={{
                  boxShadow: 'none',
                  textAlign: 'center',
                }}
                expanded={sessionFullExpanded}
                // eslint-disable-next-line
                onChange={(e, expanded) => {
                  setSessionFullExpanded(expanded);
                }}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMore style={{ color: '#000000', fontSize: '15px' }} />}
                  aria-controls='panel1a-content'
                  style={{ display: 'inline-flex', justifyContent: 'center', flexGrow: 'unset', textAlign: 'center' }}
                >
                  <Typography style={{ fontWeight: 600, fontSize: '13px', textTransform: 'uppercase' }}>
                    {sessionFullExpanded ? 'Hide Full Sessions' : 'Show Full Sessions'}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: 'block' }}>
                  <Box
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 220px)',
                      gridTemplateRows: 'repeat(2, auto)',
                      gap: '20px 11%',
                      overflowX: 'hidden',
                      overflowY: 'scroll',
                      maxHeight: '330px',
                    }}
                  >
                    {popUpWindowOptions.data.sessionsRemoved
                      ? popUpWindowOptions.data.sessionsRemoved.map((removedDate) => {
                          return (
                            <Box style={{ width: '100%' }}>
                              <DateInfoCard date={removedDate.activityDate} noHover>
                                <Typography
                                  style={{
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    color: '#9e9e9e',
                                    textTransform: 'uppercase',
                                    paddingBottom: '3px',
                                  }}
                                >
                                  Sessions
                                </Typography>
                                {removedDate.sessionsRemoved
                                  ? removedDate.sessionsRemoved.map((removedSession) => {
                                      return (
                                        <Typography
                                          style={{
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            width: '100%',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            textAlign: 'left',
                                          }}
                                        >
                                          {removedSession.sessionName}
                                        </Typography>
                                      );
                                    })
                                  : ''}
                              </DateInfoCard>
                            </Box>
                          );
                        })
                      : ''}
                  </Box>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Box>
          </Box>
        </PopUpWindow>
      )}

      <UpcomingRostersDayDialog
        date={clickedDate}
        dailyUpcomingRosters={datesThisWeek}
        open={upcomingRosterDayDialogOpen}
        close={() => {
          handleDayDotDialogClose();
        }}
        closeEditDialog={() => {
          setActivityApplicationEditDialogOpen(false);
        }}
        refetch={refetch}
        activityApplicationEditDialogOpen={activityApplicationEditDialogOpen}
        setActivityApplicationEditDialogOpen={setActivityApplicationEditDialogOpen}
        attendances={attendances}
        activityReports={activityReports}
      />

      <Box style={{ width: '100%' }}>
        {Object.keys(datesThisWeek ?? {}).length === 0 && !loading ? (
          <Grid container justify='center' alignContent='center' alignItems='center' style={{ minHeight: 220 }}>
            <Typography>{`You haven't joined a team yet`}</Typography>
            <Grid item xs={12} container justify='center'>
              <TabletButton
                color='primary'
                data-track='upcRos-empty-find-a-team-button'
                onClick={() => {
                  history.push('/volunteering/activities/all-activities');
                }}
              >
                {`Find a team`}
              </TabletButton>
            </Grid>
          </Grid>
        ) : !hasRosteringsThisPeriod && !loading ? (
          <Grid
            container
            direction='column'
            justify='center'
            alignContent='center'
            alignItems='center'
            style={{ minHeight: 220 }}
          >
            <Typography display='block' color='textSecondary'>
              {`No activities this month`}
            </Typography>
            <HoverText
              data-track='upcRos-go-to-next-period-message-button'
              display='block'
              variant='body2'
              color='primary'
              hoverColor='primary'
              onClick={() => {
                goToNextDatePeriod();
              }}
            >
              {`Go to next month`}
            </HoverText>
          </Grid>
        ) : loading ? (
          <Grid container direction='column' style={{ marginTop: 10, marginLeft: 20, minHeight: 220 }}>
            <Grid item>
              <Skeleton variant='text' height={15} width={120} />
            </Grid>
            <Grid container item style={{ marginLeft: 10 }}>
              <Grid item>
                <Skeleton variant='text' height={15} width={100} />
                <Skeleton variant='text' height={15} width={80} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <List>
            {pastUpcomingRostersGroup && pastUpcomingRostersGroup.length >= 0 ? (
              <>
                {showPast || isPreviousMonth ? (
                  <>
                    <UpcomingRosterListGrouping
                      upcomingRostersGroup={pastUpcomingRostersGroup}
                      createAvailability={createAvailability}
                      createUnavailability={createUnavailability}
                      createAttendance={createAttendance}
                      removeAttendance={removeAttendance}
                      activityApplicationPostRequest={activityApplicationPostRequest}
                      isTooltipOpen={isTooltipOpen}
                      setIsTooltipOpen={setIsTooltipOpen}
                      activityApplicationEditDialogOpen={activityApplicationEditDialogOpen}
                      setTooltipHasBeenClosed={setTooltipHasBeenClosed}
                      setActivityApplicationEditDialogOpen={setActivityApplicationEditDialogOpen}
                      attendances={attendances}
                      activityReports={activityReports}
                    />
                    {isCurrentMonth && (
                      <>
                        <Box
                          display='flex'
                          // alignContent='center'
                          alignItems='center'
                          // justifyItems='center'
                          // justifyContent='center'
                          style={{ marginLeft: 16, marginBottom: 16, marginTop: 24 }}
                        >
                          <HoverText
                            color='primary'
                            variant='body2'
                            onClick={() => setShowPast(false)}
                            disableUnderline
                          >
                            {`Hide past dates`}
                          </HoverText>
                          <KeyboardArrowUp color='primary' onClick={() => setShowPast(false)} />
                        </Box>
                      </>
                    )}
                  </>
                ) : isCurrentMonth ? (
                  <>
                    <TitularTooltip title='Click to show past activities' placement='right-start'>
                      <Box display='inline-block'>
                        <HoverText
                          color='primary'
                          variant='body2'
                          onClick={() => setShowPast(true)}
                          style={{ marginLeft: 16, marginBottom: 16 }}
                          disableUnderline
                          disabled={pastUpcomingRostersGroup.length === 0}
                        >
                          {`View all past activities for this month ${
                            pastUpcomingRostersGroup.length ? `(${pastUpcomingRostersGroup.length})` : ''
                          }`}
                        </HoverText>
                      </Box>
                    </TitularTooltip>
                  </>
                ) : null}
              </>
            ) : null}
            {futureUpcomingRostersGroup && futureUpcomingRostersGroup.length ? (
              <UpcomingRosterListGrouping
                upcomingRostersGroup={futureUpcomingRostersGroup}
                createAvailability={createAvailability}
                createUnavailability={createUnavailability}
                createAttendance={createAttendance}
                removeAttendance={removeAttendance}
                activityApplicationPostRequest={activityApplicationPostRequest}
                isTooltipOpen={isTooltipOpen}
                setIsTooltipOpen={setIsTooltipOpen}
                activityApplicationEditDialogOpen={activityApplicationEditDialogOpen}
                setTooltipHasBeenClosed={setTooltipHasBeenClosed}
                setActivityApplicationEditDialogOpen={setActivityApplicationEditDialogOpen}
                attendances={attendances}
                activityReports={activityReports}
              />
            ) : null}
          </List>
        )}
        <Grid container direction='row' justify='center' spacing={4} style={{ marginTop: 8 }}>
          <Grid item>
            <HoverText
              data-track='upcRos-prev-footer-button'
              color='primary'
              hoverColor='primary'
              onClick={() => {
                goToPreviousDatePeriod();
              }}
            >
              {'Previous'}
            </HoverText>
          </Grid>
          <Grid item>
            <HoverText
              data-track='upcRos-next-footer-button'
              color='primary'
              hoverColor='primary'
              onClick={() => {
                goToNextDatePeriod();
              }}
            >
              {'Next'}
            </HoverText>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const UpcomingRosterListGrouping = (props: UpcomingRosterListGroupingProps) => {
  const {
    upcomingRostersGroup,
    createAvailability,
    createUnavailability,
    createAttendance,
    removeAttendance,
    activityApplicationPostRequest,
    activityApplicationEditDialogOpen,
    isTooltipOpen,
    setIsTooltipOpen,
    setTooltipHasBeenClosed,
    setActivityApplicationEditDialogOpen,
    attendances,
    activityReports,
  } = props;

  return (
    <>
      {upcomingRostersGroup.map((upcomingRosterGroup) => {
        const { dateStamp, upcomingRosters } = upcomingRosterGroup;

        return (
          <Fragment key={dateStamp}>
            <DateDivider date={dateStamp} />
            {upcomingRosters.map((upcomingRoster, upcomingRosterIndex) => {
              return (
                <Fragment key={upcomingRoster.upcomingRosterId}>
                  {upcomingRosterIndex !== 0 ? <Divider style={{ margin: '8px 0' }} /> : null}
                  <UpcomingRosterListItem
                    key={upcomingRoster.upcomingRosterId}
                    isTooltipOpen={upcomingRosterIndex === 0 && isTooltipOpen}
                    hideTooltip={() => {
                      setIsTooltipOpen(false);
                      setTooltipHasBeenClosed(true);
                    }}
                    upcomingRoster={upcomingRoster}
                    createAvailability={createAvailability}
                    createUnavailability={createUnavailability}
                    createAttendance={createAttendance}
                    removeAttendance={removeAttendance}
                    dateStamp={dateStamp}
                    activityApplicationPostRequest={activityApplicationPostRequest}
                    activityApplicationEditDialogOpen={activityApplicationEditDialogOpen}
                    setActivityApplicationEditDialogOpen={setActivityApplicationEditDialogOpen}
                    attendances={attendances}
                    activityReports={activityReports}
                  />
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};
