import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const WaitlistedVolunteersSkeleton = () => (
  <Box display={'flex'} alignItems={'center'} flexGrow={1}>
    <Skeleton variant={'circle'} width={'28px'} height={'28px'} style={{ marginRight: '12px' }} />
    <Skeleton variant={'text'} width={'80%'} height={'30px'} />
  </Box>
);
