import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CommonRequiringActionWidgetProps } from '../../shared';
import { useCampfireQuery } from '../../../../../../../../global/network/useCampfireQuery';
import { GET_INCIDENT_REPORTS } from '../../../../../../../program-manager/incident-management-v2/incident-management.gql';
import {
  IncidentManagementGetIncidents,
  IncidentManagementGetIncidentsVariables,
} from '../../../../../../../program-manager/incident-management-v2/__generated__/IncidentManagementGetIncidents';
import { parseIncidentReport } from '../../../../../../../program-manager/incident-management-v2/IncidentManagementScreen';

import { IncidentListItem } from '../../../../../../../program-manager/incident-management-v2/incident-list/IncidentList';
import { IncidentDetailRAWidgetDialog } from './IncidentDetailRAWidgetDialog';
import { WidgetTypes } from '../../../dashboard-widget-setting/dashboard-widget-model';

interface Props extends CommonRequiringActionWidgetProps {
  status: string;
}
export const IncidentsRAWidget = (props: Props) => {
  const { filters, status, range } = props;

  const { programIds, activityIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };

  const [selectedIncidentId, setSelectedIncidentId] = useState<string>();

  const { data, loading, refetch } = useCampfireQuery<
    IncidentManagementGetIncidents,
    IncidentManagementGetIncidentsVariables
  >(GET_INCIDENT_REPORTS, {
    options: {
      variables: {
        programIds: programIds.length > 0 && !programIds.includes('all') ? programIds : null,
        activityIds: activityIds.length > 0 && !activityIds.includes('all') ? activityIds : null,
        status: status === 'in-review' ? 'working' : status,
        range: range,
        widgetType: WidgetTypes.REQUIRING_ACTION,
      },
    },
  });

  const incidents = data?.vm.incidentReports.map(parseIncidentReport) || [];

  const onSelectIncident = (id: string) => {
    setSelectedIncidentId(id);
  };

  const onDialogClose = () => {
    setSelectedIncidentId(undefined);
  };

  const handleSuccess = () => {
    if (refetch) refetch();
    onDialogClose();
  };

  return (
    <>
      <Box width='100%' height='100%' overflow='scroll' maxHeight='max-content'>
        <Box display='flex' flexDirection='column' style={{ gap: 12, overflowY: 'scroll' }}>
          {loading ? (
            <Skeleton variant='text' width='100%' height='65px' />
          ) : (
            incidents.map((incident) => (
              <IncidentListItem incident={incident} onSelectIncident={() => onSelectIncident(incident.incidentId)} />
            ))
          )}
        </Box>
      </Box>
      {selectedIncidentId && (
        <IncidentDetailRAWidgetDialog
          open={!!selectedIncidentId}
          onClose={onDialogClose}
          selectedIncidentId={selectedIncidentId}
          handleSuccess={handleSuccess}
        />
      )}
    </>
  );
};
