import { QueryResult } from '@apollo/react-common';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { usePageVisibility } from '../page-visibility/usePageVisibility';

export interface PollingQueryArgs<TData, TVariables> {
  pollInterval?: number;
  options?: Omit<QueryHookOptions<TData, TVariables>, 'pollInterval' | 'fetchPolicy' | 'notifyOnNetworkStatusChange'>;
  ignoreVisibility?: boolean;
}

type RealQueryResult<TData, TVariables> = Omit<QueryResult<TData, TVariables>, 'refetch'> &
  Partial<Pick<QueryResult<TData, TVariables>, 'refetch'>>;
interface CampfireQueryResult<TData, TVariables> extends RealQueryResult<TData, TVariables> {
  setPollInterval: Dispatch<SetStateAction<number | undefined>>;
  refetchLoading: boolean;
  pollLoading: boolean;
}
export const useCampfireQuery = <TData, TVariables>(
  query: DocumentNode,
  { pollInterval, options, ignoreVisibility = false }: PollingQueryArgs<TData, TVariables> = {
    ignoreVisibility: false,
  }
): CampfireQueryResult<TData, TVariables> => {
  const [currentPollInterval, setPollInterval] = useState(pollInterval);
  const { visibility } = usePageVisibility();

  const result = useQuery<TData, TVariables>(query, {
    ...options,
    pollInterval: ignoreVisibility ? currentPollInterval : undefined,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (ignoreVisibility || !currentPollInterval) return;
    if (visibility) {
      result.startPolling(currentPollInterval);
      return;
    }

    result.stopPolling();
  }, [visibility, currentPollInterval]);

  return {
    ...result,
    setPollInterval,
    refetchLoading: result.networkStatus === 4,
    pollLoading: result.networkStatus === 6,
  };
};
