import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useUser } from '../auth/useUser';
import { useSnackbar } from '../config/useSnackbar';
import { useCampfireLazyQuery } from '../network/useCampfireLazyQuery';
import { GetOnboardingStep, GetOnboardingStepVariables } from './__generated__/GetOnboardingStep';
import { GET_ONBOARDING_STEP } from './onboarding-context.gql';

export interface OnboardingInterface {
  finishedOnboarding?: boolean;
  currentOnboardingStep: number;
  totalOnboardingStepsCount: number;
  refresh: () => void;
  setPollingInterval: (x: number) => void;
}

export const OnboardingContext = createContext<OnboardingInterface | undefined>(undefined);

const TOTAL_ONBOARDING_STEPS_COUNT = 5;
export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const [prevOnboardingStep, setPrevOnboardingStep] = useState<number | undefined>();
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState<number | undefined>();
  const [pollInterval, setPollInterval] = useState(600000);
  const [pollCounter, setPollCounter] = useState(0);
  const { maybeVolunteerIdentity } = useUser();
  const { setSnackbar } = useSnackbar();

  const [
    start,
    { stopPolling: stop, refetch: refreshPoll, data: response, setPollInterval: setQueryPollInterval, called },
  ] = useCampfireLazyQuery<GetOnboardingStep, GetOnboardingStepVariables>(GET_ONBOARDING_STEP, {
    pollInterval: pollInterval,
  });

  const finishedOnboarding = currentOnboardingStep ? currentOnboardingStep === TOTAL_ONBOARDING_STEPS_COUNT : undefined;

  useEffect(() => {
    if (maybeVolunteerIdentity && finishedOnboarding === undefined) {
      start({
        variables: {
          volunteerId: maybeVolunteerIdentity.volunteerId,
        },
      });
    }
  }, [maybeVolunteerIdentity, finishedOnboarding]);

  useEffect(() => {
    if (pollInterval !== 600000) {
      setPollCounter(pollCounter + 1);
    }

    if (response) {
      const { volunteer } = response.vm;
      const order =
        volunteer && volunteer.currentOnboardingStep
          ? volunteer.currentOnboardingStep.volunteerOnboardingStepType.order
          : undefined;
      setCurrentOnboardingStep(order);
    }
  }, [response]);

  useEffect(() => {
    if (prevOnboardingStep !== currentOnboardingStep) setPrevOnboardingStep(currentOnboardingStep);
    if (finishedOnboarding && stop) stop();
    if (finishedOnboarding && prevOnboardingStep && prevOnboardingStep !== currentOnboardingStep) {
      setSnackbar({
        open: true,
        message: 'Onboarding complete!',
        variant: 'success',
        Icon: '🎉 ',
      });
    }
  }, [prevOnboardingStep, currentOnboardingStep, finishedOnboarding]);

  useEffect(() => {
    if (pollCounter >= 30) {
      setPollInterval(300000);
      setPollCounter(0);
      refresh();
    }
  }, [pollCounter]);

  useEffect(() => {
    setQueryPollInterval(pollInterval);
  }, [pollInterval]);

  const refresh = () => {
    if (called && maybeVolunteerIdentity && finishedOnboarding === false) {
      if (refreshPoll) refreshPoll();
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        currentOnboardingStep: currentOnboardingStep ?? TOTAL_ONBOARDING_STEPS_COUNT,
        refresh,
        setPollingInterval: setPollInterval,
        finishedOnboarding,
        totalOnboardingStepsCount: TOTAL_ONBOARDING_STEPS_COUNT,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
