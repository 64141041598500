import { gql } from '@apollo/client';
import { REPORT_TYPE_FIELD_FRAGMENT } from '../../../report-types/report-types-model.gql';

export const EDIT_REPORT_FIELD_DIALOG_GET_EXISTING_FIELDS = gql`
  query EditReportFieldDialogGetExistingFields {
    vm {
      fields {
        dateCreated
        ...ReportManagementReportTypeField
      }
    }
  }
  ${REPORT_TYPE_FIELD_FRAGMENT}
`;
