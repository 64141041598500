import { unpackToDate, unpackToDateTime } from '@campfire/hot-date';
import { gql } from '@apollo/client';
import { maxBy } from 'lodash';
import { DateTime } from 'luxon';
import { VolunteerDatabaseBaseVolunteer } from './__generated__/VolunteerDatabaseBaseVolunteer';
import { ExpensiveVolunteerStatistics } from './__generated__/ExpensiveVolunteerStatistics';

export const GET_VOLUNTEER_DATABASE = gql`
  query GetVolunteerDatabase(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $sortFilter: String
    $sortBy: String
    $sortDirection: String
    $keywordsFilter: String
    $nameFilter: String
    $emailFilter: String
    $phoneFilter: String
    $privilegeFilter: String
    $commentsFilter: String
    $programsFilter: [String!]
    $activitiesFilter: [String!]
    $statusFilters: [String!]
    $createdDateFilter: Date
    $rolesFilter: [String!]
    $activeVolsFilter: Boolean
    $startDate: Date
    $endDate: Date
  ) {
    vm {
      activityRoles {
        activityRoleId
        name
      }
      programs {
        programId
        name
        activities {
          activityId
          name
        }
      }
      programActiveVolunteersConnection(
        before: $before
        after: $after
        first: $first
        last: $last
        sortFilter: $sortFilter
        sortBy: $sortBy
        sortDirection: $sortDirection
        keywordsFilter: $keywordsFilter
        nameFilter: $nameFilter
        emailFilter: $emailFilter
        phoneFilter: $phoneFilter
        privilegeFilter: $privilegeFilter
        commentsFilter: $commentsFilter
        programsFilter: $programsFilter
        activitiesFilter: $activitiesFilter
        statusFilters: $statusFilters
        createdDateFilter: $createdDateFilter
        rolesFilter: $rolesFilter
        activeVolsFilter: $activeVolsFilter
        startDate: $startDate
        endDate: $endDate
      ) {
        totalCount
        edges {
          node {
            ...VolunteerDatabaseBaseVolunteer
          }
          cursor
        }
      }
    }
  }

  fragment VolunteerDatabaseProgram on VOLUNTEER_ProgramType {
    programId
    activities {
      ...VolunteerDatabaseActiveActivity
    }
    programActiveVolunteersConnection(before: $before, after: $after, first: $first, last: $last) {
      totalCount
      edges {
        node {
          ...VolunteerDatabaseBaseVolunteer
        }
        cursor
      }
    }
  }

  fragment VolunteerDatabaseActiveActivity on VOLUNTEER_ActivityType {
    activityId
    startTime
    endTime
  }

  fragment VolunteerDatabaseBaseVolunteer on VOLUNTEER_VolunteerType {
    volunteerId
    privilegeLevel
    profile {
      profileId
      userId
      firstName
      preferredName
      lastName
      email
      comments
      contactNumber
      dateOfBirth
      additionalVolunteerHours
      completedTasks {
        task {
          taskId
          order
        }
        taskFieldValues {
          taskFieldValueId
          field {
            taskFieldId
          }
          ... on VOLUNTEER_AddressTaskFieldValueType {
            taskFieldValueId
            address {
              placesAddress {
                formatted
              }
              manualAddress {
                humanReadable
              }
            }
          }
          ... on VOLUNTEER_AttachmentTaskFieldValueType {
            taskFieldValueId
            attachments {
              attachmentId
            }
          }
          ... on VOLUNTEER_CheckboxTaskFieldValueType {
            taskFieldValueId
            checkboxTaskFieldOptions {
              name
            }
            otherText
          }
          ... on VOLUNTEER_DropdownTaskFieldValueType {
            taskFieldValueId
            field {
              name
            }
            dropdownTaskFieldOption {
              name
            }
            otherText
          }
          ... on VOLUNTEER_DateTaskFieldValueType {
            taskFieldValueId
            dateValue
          }
          ... on VOLUNTEER_EmailTaskFieldValueType {
            taskFieldValueId
            email
          }
          ... on VOLUNTEER_LongTextTaskFieldValueType {
            taskFieldValueId
            textValue
          }
          ... on VOLUNTEER_MultipleChoiceTaskFieldValueType {
            taskFieldValueId
            multipleChoiceTaskFieldOption {
              name
            }
            otherText
          }
          ... on VOLUNTEER_NumberTaskFieldValueType {
            taskFieldValueId
            numericValue
          }
          ... on VOLUNTEER_PhoneTaskFieldValueType {
            taskFieldValueId
            contactNumber
          }
          ... on VOLUNTEER_ShortTextTaskFieldValueType {
            taskFieldValueId
            textValue
          }
        }
      }
    }
    rosterStatus
    lastTeamRemovalDate
    activityEnrolments {
      ...VolunteerDatabaseActivityEnrolment
    }
    dateCreated
    activityRoles {
      activityRoleId
    }
    programs {
      programId
      name
    }
    currentOnboardingStep {
      ...VolunteerDatabaseOnboardingStep
    }
    onboardingSteps {
      ...VolunteerDatabaseOnboardingStep
    }
    dateDeactivated
    flagging {
      dateFlagged
    }
    rosterings {
      publishedRoster {
        activityDate
        datePublished
      }
    }
    lastLoginDate
    volunteerStatistics(startDate: $startDate, endDate: $endDate) {
      ...VolunteerStatistics
    }
  }

  fragment VolunteerStatistics on VOLUNTEER_VolunteerStatisticsType {
    lastAttended
    attended
    sessionReportStatistics {
      operationalHours
    }
  }
  fragment VolunteerDatabaseActivityEnrolment on VOLUNTEER_ActivityEnrolmentType {
    activityEnrolmentId
    activity {
      activityId
      name
      nextX(x: 8)
      ... on VOLUNTEER_NonRecurringActivityType {
        __typename
      }
      ... on VOLUNTEER_RecurringActivityType {
        __typename
      }
      startTime
      endTime
      activityLocation {
        placesAddress {
          description
        }
      }
      program {
        programId
        name
      }
    }
  }

  fragment VolunteerDatabaseOnboardingStep on VOLUNTEER_VolunteerOnboardingStepType {
    volunteerOnboardingStepId
    stepCompletionDate
    volunteerOnboardingStepType {
      volunteerOnboardingStepTypeId
      order
    }
  }
`;

export const GET_PROFILE_SETTING_VOLUNTEER_DATABASE = gql`
  query GetProfileSettingVolunteerDatabase($profileId: String!) {
    vm {
      profile(profileId: $profileId) {
        userPreference {
          userPreferenceId
          databaseTaskFields
        }
      }
    }
  }
`;

export const GET_VOLUNTEER_DATABASE_TASKS = gql`
  query GetVolunteerDatabaseTasks {
    vm {
      tasks {
        ...VolunteerDatabaseTasks
      }
    }
  }
  fragment VolunteerDatabaseTasks on VOLUNTEER_TaskType {
    taskId
    title
    description
    order
    cake {
      cakeType
    }
    taskItems {
      ... on VOLUNTEER_TaskItemFieldType {
        field {
          taskFieldId
          name
          description
        }
      }
    }
  }
`;

export type VolunteerDatabaseVolunteer = VolunteerDatabaseBaseVolunteer & {
  privilegeLevel?: string;
  lastLoginDate?: any;
  volunteerStatistics?: ExpensiveVolunteerStatistics;
};

export type VolunteerDatabaseActivityRole = {
  activityRoleId: string;
  name: string;
};

export type VdbState = {
  allVolunteers: any;
  tasks: any;
};
export type VolunteerDatabaseState = { [volunteerId: string]: VolunteerDatabaseVolunteer };

export type VolunteerDatabaseActions = {
  retireVolunteer: (volunteer: VolunteerDatabaseVolunteer) => void;
  exitVolunteer: (volunteer: VolunteerDatabaseVolunteer) => void;
  reactivateVolunteer: (volunteer: VolunteerDatabaseVolunteer) => void;
};

export const getVolunteerDatabaseRosterStatus = (volunteer: VolunteerDatabaseVolunteer) => {
  if (volunteer.dateDeactivated) {
    return `Deactivated: ${unpackToDate(volunteer.dateDeactivated).toLocaleString(DateTime.DATE_MED)}`;
  }

  const pastRosterings = volunteer.rosterings.filter(
    (r) => unpackToDateTime(r.publishedRoster.activityDate).diffNow('days').days <= 0
  );
  const lastRostering = maxBy(pastRosterings, (rostering) => rostering.publishedRoster.activityDate);
  if (lastRostering) {
    return `Last Rostered: ${unpackToDate(lastRostering.publishedRoster.activityDate).toLocaleString(
      DateTime.DATE_MED
    )}`;
  }
  if (volunteer.activityEnrolments.length === 0) {
    return 'Never joined activity';
  }

  return 'Never rostered';
};

export function isStatusFlaggedAndExited(status: string): boolean {
  return status?.substr(0, 18) === 'Deactivated';
}

export function isStatusDeactivated(status: string): boolean {
  return status?.substr(0, 11) === 'Deactivated';
}
