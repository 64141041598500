import { Field } from '@campfire/field';
import { HoverText } from '@campfire/hover-link';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Select } from '@campfire/select';
import { TabletButton } from '@campfire/tablet-button';
import { TimePicker } from '@campfire/time-picker';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { RichEditorFormik } from '@campfire/campfire-rich-editor';
import { Box, Collapse, Fade, Grid, Typography, Divider } from '@material-ui/core';
import { Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { number as YupNumber, object as YupObject, string as YupString } from 'yup';
import { DateTime } from 'luxon';
import { AutoLocationField } from '../../../../../common/auto-location-field/AutoLocationField';
import { arrayHead } from '../../../../../common/functions/array-head';
import { assertNever } from '../../../../../common/functions/assert-never';
import { scrollIntoView } from '../../../../../common/functions/scroll-functions';
import PrivilegeRules from '../../../../../global/auth/PrivilegeRules';
import { useUser } from '../../../../../global/auth/useUser';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';
import { CreateActivityGetReportTypesV2 } from '../__generated__/CreateActivityGetReportTypesV2';
import { CREATE_ACTIVITY_GET_REPORT_TYPES_V2 } from '../activity-form-model-v2.gql';
import { ActivityFormSession, ActivityFormValues } from '../ActivityFormV2';
import { CreateReportTypeFormV2 } from './CreateReportTypeFormV2';
import { FieldsListV2 } from './FieldsListV2';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';
import { FormikErrorFocus } from '../../../../../common/form/FormikErrorFocus';
import { NO_REPORT_TYPES, NO_REPORT_IMPACT_TRACKED, NO_REPORT_NO_IMPACT } from '../activity-form-actions-v2';
import { AlertCard, getAlertCardColors } from '../../../../../common/cards/alert-card/AlertCard';
import { useSaveReportTypeFetch } from '../../../report-management-v2/report-types/report-type-form/report-type-form-actions';
import { ReportTypeFormValues } from '../../../report-management-v2/common/report-type-form-sections/report-type-form-types';
import { parseInitialValues } from '../../../activities/manage-report-type/manage-report-type-model';

const ValidationSchema = YupObject().shape({
  sessionName: YupString().required('Please indicate a session name'),
  sessionDescription: YupString(),
  sessionStartTime: YupString().nullable(),
  sessionEndTime: YupString().nullable(),
  minVolunteers: YupNumber().required('Please indicate the minimum volunteers required'),
  maxVolunteers: YupNumber().when('minVolunteers', (minVolunteers: number, schema: any) => {
    return schema.min(minVolunteers);
  }),
  selectedReportTypeId: YupString().required('Please indicate the report type'),
  sessionLocation: YupString().nullable(),
});

interface Props {
  session?: ActivityFormSession;
  idx?: number;
  open: boolean;
  close: () => void;
}

const NoReportTypeAlertCard = ({ reportTypeId }: { reportTypeId: string }) => {
  const noReportType = NO_REPORT_TYPES.find((reportType) => reportType.reportTypeId === reportTypeId);
  const title = noReportType?.name || '';
  const description = noReportType?.description || '';
  const { darkTextColor } = getAlertCardColors('info');
  return (
    <AlertCard variant='info' title={title}>
      <Typography style={{ fontSize: '14px', fontWeight: 400, color: darkTextColor }}>{description}</Typography>
    </AlertCard>
  );
};

const AddNewSessionDialogV2 = (props: Props) => {
  const { session, open, close, idx } = props;
  const { setSnackbar } = useSnackbar();
  const { isMobile } = useCampfireTheme();
  const { isXs } = useCampfireTheme();
  const {
    user: { userIdentityService },
  } = useUser();
  const parentFormik = useFormikContext<ActivityFormValues>();
  const saveReportType = useSaveReportTypeFetch();

  const { data, loading, refetch } = useCampfireQuery<CreateActivityGetReportTypesV2, null>(
    CREATE_ACTIVITY_GET_REPORT_TYPES_V2
  );

  const [showAddReportType, setShowAddReportType] = useState(false);
  const [showDescriptionField, setShowDescriptionField] = useState(false);
  const [showMaxVolunteersField, setShowMaxVolunteersField] = useState(false);
  const [showInfo, setShowInfo] = useState(!isXs);
  const [showLocationField, setShowLocationField] = useState(false);

  const canManageReportTypes = userIdentityService.hasVmGlobalRule(PrivilegeRules.manageReportTypes);
  const reportTypeInitialValues = parseInitialValues();
  const createReportTypeRef = React.useRef(null);
  const initialValues = useMemo(() => {
    const defaultReportType = session?.reportType ?? arrayHead(data?.vm?.reportTypes || []);
    return {
      sessionId: session?.sessionId || '',
      sessionName: session?.name || `Session ${parentFormik.values.sessions.length + 1}`,
      sessionDescription: session?.description || '',
      sessionStartTime: session?.startTime || null,
      sessionEndTime: session?.endTime || null,
      minVolunteers: session?.minVolunteers?.toString() || '1',
      maxVolunteers: session?.maxVolunteers?.toString() || '',
      selectedReportTypeId: defaultReportType ? defaultReportType.reportTypeId : '',
      sessionLocation: session?.activityLocation || null,
      ...reportTypeInitialValues,
    };
  }, [session, data, open]);

  useEffect(() => {
    if (session?.activityLocation) setShowLocationField(true);
  }, [session?.activityLocation]);

  useEffect(() => {
    if (session?.description) setShowDescriptionField(true);
  }, [session?.description]);

  useEffect(() => {
    if (showAddReportType && createReportTypeRef.current) {
      scrollIntoView(createReportTypeRef, { behavior: 'smooth' }, false);
    }
  }, [showAddReportType, createReportTypeRef]);

  const handleClose = () => {
    setShowAddReportType(false);
    setShowDescriptionField(false);
    setShowMaxVolunteersField(false);
    setShowLocationField(false);
    close();
  };

  const reportTypeList = data
    ? data.vm.reportTypes
        .map((reportType) => ({
          reportTypeId: reportType.reportTypeId,
          name: reportType.name,
        }))
        .concat(NO_REPORT_TYPES)
    : NO_REPORT_TYPES;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        if (!parentFormik) return;

        const matchingReportType = data?.vm.reportTypes.find(
          (reportType) => reportType.reportTypeId === values.selectedReportTypeId
        );

        // if (!matchingReportType) return;

        const updatedSessionsArray = parentFormik.values.sessions;
        const updatedSession: ActivityFormSession = {
          name: values.sessionName,
          description: values.sessionDescription,
          startTime: values.sessionStartTime,
          endTime: values.sessionEndTime,
          minVolunteers: values.minVolunteers ? parseInt(values.minVolunteers, 10) : undefined,
          maxVolunteers: values.maxVolunteers ? parseInt(values.maxVolunteers, 10) : undefined,
          reportType: {
            name:
              matchingReportType?.name ||
              NO_REPORT_TYPES.find((item) => item.reportTypeId === values.selectedReportTypeId)?.name ||
              '',
            reportTypeId: matchingReportType?.reportTypeId || null,
          },
          activityLocation: values.sessionLocation ?? undefined,
          sessionId: values.sessionId || '',
          autoReport: values.selectedReportTypeId === NO_REPORT_IMPACT_TRACKED,
        };

        const startIndex = idx ?? 0;
        const deleteCount = session ? 1 : 0;
        updatedSessionsArray.splice(startIndex, deleteCount, updatedSession);
        parentFormik.setFieldValue('sessions', updatedSessionsArray);

        handleClose();
      }}
    >
      {({ values, setFieldValue }) => {
        const validEndTimeFromStart = useMemo(() => {
          return (
            !values.sessionEndTime ||
            !values.sessionStartTime ||
            DateTime.fromJSDate(values.sessionEndTime).diff(DateTime.fromJSDate(values.sessionStartTime), ['minutes'])
              .minutes >= 1
          );
        }, [values]);

        const selectedReportType = useMemo(() => {
          return data?.vm.reportTypes.find((reportType) => reportType.reportTypeId === values.selectedReportTypeId);
        }, [values.selectedReportTypeId]);
        const selectedReportTypeOptionalFields = useMemo(
          () =>
            selectedReportType?.items.filter(
              (item) => item.__typename === 'VOLUNTEER_ReportTypeFieldType' && item.optional
            ),
          [selectedReportType]
        );

        const selectedReportTypeRequiredFields = useMemo(
          () =>
            selectedReportType?.items.filter(
              (item) => item.__typename === 'VOLUNTEER_ReportTypeFieldType' && !item.optional
            ),
          [selectedReportType]
        );

        const isNoReportRequiredTypeSelected =
          values.selectedReportTypeId === NO_REPORT_NO_IMPACT ||
          values.selectedReportTypeId === NO_REPORT_IMPACT_TRACKED;

        const runSaveReportType = (reportTypeValues: ReportTypeFormValues) => {
          return saveReportType
            .run({
              reportTypeId: reportTypeValues.reportTypeId || undefined,
              name: reportTypeValues.reportTypeName,
              description: reportTypeValues.reportTypeDescription ?? null,
              active: reportTypeValues.active,
              items: reportTypeValues.items.map((item, order) => {
                if (item.__typename === 'VOLUNTEER_ReportTypeHeadingType') {
                  return { ...item, reportTypeItemId: item.reportTypeItemId || undefined, order };
                }

                if (
                  item.field.__typename === 'VOLUNTEER_BooleanFieldType' ||
                  item.field.__typename === 'VOLUNTEER_CheckboxFieldType' ||
                  item.field.__typename === 'VOLUNTEER_RatingFieldType' ||
                  item.field.__typename === 'VOLUNTEER_TextFieldType' ||
                  item.field.__typename === 'VOLUNTEER_TimeFieldType' ||
                  item.field.__typename === 'VOLUNTEER_AttachmentFieldType'
                ) {
                  return {
                    ...item,
                    reportTypeItemId: item.reportTypeItemId || undefined,
                    field: { ...item.field, fieldId: item.field.fieldId || undefined, hero: item.field.isHero },
                    order,
                  };
                }

                if (item.field.__typename === 'VOLUNTEER_DropdownFieldType') {
                  return {
                    ...item,
                    reportTypeItemId: item.reportTypeItemId || undefined,
                    field: {
                      ...item.field,
                      fieldId: item.field.fieldId || undefined,
                      dropdownFieldOptions: item.field.dropdownOptions.map((option, optionOrder) => {
                        return {
                          ...option,
                          order: optionOrder,
                        };
                      }),
                      allowMultiple: item.field.isAllowMultiple,
                      hero: item.field.isHero,
                    },
                    order,
                  };
                }

                if (item.field.__typename === 'VOLUNTEER_NumericFieldType') {
                  return {
                    ...item,
                    reportTypeItemId: item.reportTypeItemId || undefined,
                    field: { ...item.field, fieldId: item.field.fieldId || undefined, hero: item.field.isHero },
                    order,
                  };
                }

                return assertNever(item.field);
              }),
            })
            .then((res) => {
              if (!res.ok) {
                setSnackbar({
                  open: true,
                  message: 'Unable to save report type',
                  variant: 'error',
                });
                return;
              }

              setSnackbar({
                open: true,
                message: 'Report type saved',
                variant: 'success',
              });

              if (refetch) {
                refetch().then(() => {
                  setFieldValue('selectedReportTypeId', res.data.data.reportTypeId);
                });
              }
            })
            .catch(() =>
              setSnackbar({
                open: true,
                message: 'Unable to save report type',
                variant: 'error',
              })
            );
        };

        return (
          <FullscreenDialog
            open={open}
            close={handleClose}
            fullScreen={isMobile}
            maxWidth='md'
            fullWidth
            title={'Add new session'}
            dialogActions={() => (
              <TitularTooltip
                disableFocusListener={!showAddReportType}
                disableHoverListener={!showAddReportType}
                disableTouchListener={!showAddReportType}
                description='Finish or discard the new Report Type before continuing'
              >
                <Grid item>
                  {/* Button requires form id to work when inside DialogActions https://github.com/mui-org/material-ui/issues/6128 - TD 31/3/2020 */}
                  <TabletButton
                    onClick={() => {
                      setShowMaxVolunteersField(false);
                    }}
                    color='primary'
                    variant='contained'
                    type='submit'
                    form='activity-session-form'
                    disabled={showAddReportType || !validEndTimeFromStart}
                    style={
                      showAddReportType
                        ? {
                            pointerEvents: 'none',
                          }
                        : undefined
                    }
                  >
                    {session ? 'Update Session' : 'Add Session'}
                  </TabletButton>
                </Grid>
              </TitularTooltip>
            )}
          >
            <LinearProgressOverlay isLoading={loading} />
            <Grid container alignItems='flex-start' alignContent='flex-start'>
              <Grid item xs>
                <Typography variant='h6'>{'What is a session?'}</Typography>

                <Box maxWidth={640}>
                  <Typography variant='body2' display='block' color='textSecondary'>
                    {`Every activity must have at least one session and every session includes a report type. `}
                  </Typography>
                  {showInfo ? (
                    <>
                      <Typography variant='body2' display='block' color='textSecondary' style={{ marginTop: 8 }}>
                        {`A report type is a customised set of questions that can be built to capture the impact data that is most relevant to the type of activity. Breaking an activity into multiple sessions is an easy way to allow volunteers to capture different data.`}
                      </Typography>

                      <HoverText
                        color='primary'
                        variant='body2'
                        onClick={() => setShowInfo(false)}
                        style={{ marginTop: 8 }}
                      >
                        {'Less info'}
                      </HoverText>
                    </>
                  ) : (
                    <HoverText
                      color='primary'
                      variant='body2'
                      onClick={() => setShowInfo(true)}
                      style={{ marginTop: 8 }}
                    >
                      {'More info'}
                    </HoverText>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 8, paddingBottom: 8 }}>
              <Divider />
            </Grid>
            <Grid container direction='column'>
              <>
                {/* Form requires form id so submit button works when inside DialogActions https://github.com/mui-org/material-ui/issues/6128 - TD 31/3/2020 */}
                <Form id='activity-session-form'>
                  <FormSectionV2
                    title='Basics'
                    description={
                      <Typography variant='body2' color='textSecondary' display='block'>
                        {'Name your session (e.g. Setup)'}
                      </Typography>
                    }
                  >
                    <Grid container direction='column' spacing={2}>
                      <Grid item xs>
                        <Field name='sessionName' label='Session name' fullWidth variant='outlined' required />
                      </Grid>
                      <Grid item xs>
                        <Collapse in={showDescriptionField}>
                          <RichEditorFormik valueName='sessionDescription' placeholder='Description (Optional)' />
                        </Collapse>

                        {!showDescriptionField ? (
                          <HoverText variant='body2' color='primary' onClick={() => setShowDescriptionField(true)}>
                            {'Add a description'}
                          </HoverText>
                        ) : null}
                      </Grid>
                      <Grid item xs>
                        <Collapse in={showLocationField}>
                          <AutoLocationField
                            name='sessionLocation'
                            label='Location'
                            helper
                            fullWidth
                            variant='outlined'
                          />
                        </Collapse>

                        {!showLocationField ? (
                          <HoverText variant='body2' color='primary' onClick={() => setShowLocationField(true)}>
                            {'Add a different location'}
                          </HoverText>
                        ) : null}
                      </Grid>
                    </Grid>
                  </FormSectionV2>

                  <FormSectionV2
                    title='Time'
                    description={
                      <Typography variant='body2' color='textSecondary' display='block'>
                        {
                          'Session start and end times are optional. These times affect when the activity will start and end.'
                        }
                      </Typography>
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TimePicker name='sessionStartTime' label='Start time' inputVariant='outlined' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TimePicker name='sessionEndTime' label='End time' inputVariant='outlined' />
                        {!validEndTimeFromStart ? (
                          <Typography display='block' variant='caption' color='error'>
                            {'End time must be later than start time'}
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                  </FormSectionV2>

                  <FormSectionV2
                    title='Volunteers'
                    description={
                      <Typography variant='body2' color='textSecondary' display='block'>
                        {'Requirements are shown when creating rosters.'}
                      </Typography>
                    }
                  >
                    <Grid container direction='column' spacing={2}>
                      <Grid item container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name='minVolunteers'
                            label='Min volunteers'
                            variant='outlined'
                            fullWidth
                            shrink
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {!showMaxVolunteersField && !values.maxVolunteers ? (
                            <HoverText color='primary' variant='body2' onClick={() => setShowMaxVolunteersField(true)}>
                              {'Add maximum number of volunteers'}
                            </HoverText>
                          ) : (
                            <Fade in={showMaxVolunteersField || !!values.maxVolunteers}>
                              <Field
                                slow
                                name='maxVolunteers'
                                label='Max volunteers (optional)'
                                variant='outlined'
                                fullWidth
                                shrink
                              />
                            </Fade>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </FormSectionV2>

                  <FormSectionV2
                    title='Report type'
                    description={
                      <Typography variant='body2' color='textSecondary' display='block'>
                        {'Choose a type for reports to be submitted each time one of these sessions is completed.'}
                      </Typography>
                    }
                  >
                    <Grid container direction='column' spacing={2}>
                      <Grid item xs={12}>
                        <Select
                          fullWidth
                          variant='outlined'
                          name='selectedReportTypeId'
                          label='Report type'
                          helper
                          items={reportTypeList}
                          renderFunction={(item: { name: string; reportTypeId: string }) => item.name}
                          valueFunction={(item: { name: string; reportTypeId: string }) => item.reportTypeId}
                        />
                      </Grid>
                      <Grid item container xs={12} justify='space-between' alignItems='center'>
                        {!isNoReportRequiredTypeSelected && (
                          <Typography variant='h6' display='inline'>{`Fields`}</Typography>
                        )}
                        {canManageReportTypes ? (
                          <HoverText variant='body2' color='primary' onClick={() => setShowAddReportType(true)}>
                            {'Create new report type'}
                          </HoverText>
                        ) : null}
                      </Grid>
                      <Grid item container direction='column' xs={12} style={{ paddingLeft: 24 }}>
                        {isNoReportRequiredTypeSelected && values.selectedReportTypeId ? (
                          <NoReportTypeAlertCard reportTypeId={values.selectedReportTypeId} />
                        ) : (
                          <>
                            <Typography
                              variant='subtitle2'
                              color='textSecondary'
                              display='inline'
                            >{`Required`}</Typography>
                            <FieldsListV2 fields={selectedReportTypeRequiredFields} />
                            <Typography
                              variant='subtitle2'
                              color='textSecondary'
                              display='inline'
                            >{`Optional`}</Typography>
                            <FieldsListV2 fields={selectedReportTypeOptionalFields} optional />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </FormSectionV2>
                  <FormikErrorFocus />
                </Form>
                {showAddReportType ? (
                  <div ref={createReportTypeRef}>
                    <CreateReportTypeFormV2
                      onSubmit={(reportTypeVals) =>
                        runSaveReportType(reportTypeVals).then(() => {
                          setShowAddReportType(false);
                        })
                      }
                      isLoading={saveReportType.isLoading}
                      cancel={() => setShowAddReportType(false)}
                    />
                  </div>
                ) : null}
              </>
            </Grid>
          </FullscreenDialog>
        );
      }}
    </Formik>
  );
};

export { AddNewSessionDialogV2 };
