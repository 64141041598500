import React, { CSSProperties } from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { unpackToDate } from '@campfire/hot-date';

interface DateIconProps extends CSSProperties {
  date: string;
  dateColor?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 64,
      height: 80,
      borderRadius: 8,
      backgroundColor: (props: DateIconProps) => props.backgroundColor,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 1,
      paddingRight: 1,
    },
    date: {
      color: (props: DateIconProps) => (!props.dateColor ? theme.color.grey.neutralBrand50 : props.dateColor),
      fontWeight: 900,
      fontSize: '1.875rem',
      lineHeight: 'unset',
    },
    month: {
      fontWeight: 700,
      fontSize: '0.875rem',
      color: (props: DateIconProps) => props.color,
      lineHeight: 'unset',
      marginTop: '5px',
    },
  })
);

export function DateIcon(props: DateIconProps) {
  const { date } = props;
  const classes = useStyles(props);
  const { day } = unpackToDate(date);
  const monthYear = unpackToDate(date).toFormat('LLL ‘yy');
  return (
    <Box className={classes.container}>
      <Typography className={classes.date}>{day}</Typography>
      <Typography className={classes.month}>{monthYear}</Typography>
    </Box>
  );
}
