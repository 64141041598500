import { v4 as uuidv4 } from 'uuid';
import { ReportTypeFormValues } from '../common/report-type-form-sections/report-type-form-types';
import { ReportManagementSingleReportType } from './__generated__/ReportManagementSingleReportType';

export const parseReportTypeInitialValues = (reportType?: ReportManagementSingleReportType): ReportTypeFormValues => ({
  reportTypeId: reportType?.reportTypeId || '',
  reportTypeName: reportType?.name || '',
  reportTypeDescription: reportType?.description || '',
  items:
    reportType?.items
      .map((item) => {
        return {
          ...item,
          listId: uuidv4(),
        };
      })
      .sort((a, b) => a.order - b.order) || [],
  active: !reportType?.dateDeactivated ?? false,
});

export const filterByStatus = (status: string) => (reportType: any) =>
  (reportType.dateDeactivated && status === 'archived') ||
  (!reportType.dateDeactivated && status === 'active') ||
  status === 'all';

export const filterBySearch = (value: string) => (reportType: any) =>
  reportType.name.toLowerCase().includes(value.toLowerCase().trim());
