import React from 'react';
import { Box, Typography, Tooltip, IconButton, ClickAwayListener } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Edit, DeleteOutline, MoreVertOutlined } from '@material-ui/icons';
import { tooltipStyles } from '../../../../common/styles/Tooltip';

const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      display: 'flex',
      padding: '0.5rem',
      borderRadius: '4px',
      cursor: 'pointer',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.75,
      },
    },
    menuItemText: {
      marginLeft: '0.75rem',
      fontSize: '14px',
    },
  })
);

export type WaitlistActions = 'add' | 'withdraw';

interface Props {
  onAction: (action: WaitlistActions) => void;
  bulk: boolean;
}

export function WaitlistVolunteerMenu({ onAction, bulk }: Props) {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.menuItem} onClick={() => onAction('add')}>
        <Edit fontSize='small' />
        <Typography className={classes.menuItemText}>{bulk ? 'Add all to team' : 'Add to team'}</Typography>
      </Box>
      <Box className={classes.menuItem} onClick={() => onAction('withdraw')}>
        <DeleteOutline fontSize='small' />
        <Typography className={classes.menuItemText}>{bulk ? 'Withdraw all' : 'Withdraw'}</Typography>
      </Box>
    </Box>
  );
}

export function WaitlistVolunteerMenuButton({ onAction, bulk }: Props) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const onCloseMenu = (e: any) => {
    e.stopPropagation();
    setMenuOpen(false);
  };
  const onOpenMenu = (e: any) => {
    e.stopPropagation();
    setMenuOpen(true);
  };

  const classes = tooltipStyles();

  return (
    <ClickAwayListener onClickAway={onCloseMenu}>
      <Tooltip
        arrow
        interactive
        title={<WaitlistVolunteerMenu onAction={onAction} bulk={bulk} />}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement='bottom-end'
        open={menuOpen}
        onClose={onCloseMenu}
        onOpen={onOpenMenu}
        classes={classes}
      >
        <IconButton onClick={onOpenMenu} style={{ color: '#0000008A', padding: '0.5rem' }}>
          <MoreVertOutlined />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}
