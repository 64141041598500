import { gql } from '@apollo/client';

export const GET_ACTIVITY_ATTACHMENTS_SECTION = gql`
  query GetActivityAttachmentsSection($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityId
        activityAttachments {
          activityAttachmentId
          fileType
          url
          name
        }
      }
    }
  }
`;
