import { TabletButton } from '@campfire/tablet-button';
import { ButtonBase, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { ChevronLeft, DeleteOutline, ExpandMore } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useProgramManagementContext } from '../ProgramManagementContext';
import { ProgramManagementSingleProgramBasicInfo } from '../__generated__/ProgramManagementSingleProgramBasicInfo';
import { DeleteProgramDialog } from './dialogs/DeleteProgramDialog';
import { NoDeleteLastProgramDialog } from './dialogs/NoDeleteLastProgramDialog';
import { SuspendProgramDialog } from './dialogs/SuspendProgramDialog';

const ProgramFormHeader = ({
  selectedProgram,
}: {
  selectedProgram: ProgramManagementSingleProgramBasicInfo | undefined;
}) => {
  const {
    runSaveProgram,
    saveProgramIsLoading,
    compact,
    setSelectedProgramId,
    resetProgramSelection,
    programs,
  } = useProgramManagementContext();
  const { theme, isMobile } = useCampfireTheme();

  const [showSuspendProgramDialog, setShowSuspendProgramDialog] = useState(false);
  const [showDeleteProgramDialog, setShowDeleteProgramDialog] = useState(false);
  const [showNoDeleteLastProgramDialog, setShowNoDeleteLastProgramDialog] = useState(false);

  const [programStatusMenuAnchorEl, setProgramStatusMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const programActive = useMemo(() => (selectedProgram ? selectedProgram.dateSuspended === null : false), [
    selectedProgram,
  ]);

  function handleOpenActivityStatusMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setProgramStatusMenuAnchorEl(event.currentTarget);
  }

  function handleCloseActivityStatusMenu() {
    setProgramStatusMenuAnchorEl(null);
  }

  return (
    <Grid
      container
      alignItems='center'
      style={{ borderBottom: `1px solid ${theme.color.grey.border}`, padding: isMobile ? 4 : 16 }}
    >
      <Grid item>
        <Grid container alignItems='center'>
          {compact ? (
            <Grid item>
              <ButtonBase
                onClick={() => {
                  setSelectedProgramId(undefined);
                }}
              >
                <ChevronLeft />
              </ButtonBase>
            </Grid>
          ) : null}
          <Grid item xs>
            <Typography variant='h4'>{selectedProgram?.name ?? 'New Program'}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {selectedProgram ? (
        <Grid item container xs justify='flex-end' spacing={1}>
          <Grid item>
            <TabletButton
              data-track='fs-prgMan-status-menu-button'
              variant='outlined'
              color='primary'
              aria-controls='activity-status-menu'
              aria-haspopup='true'
              onClick={handleOpenActivityStatusMenu}
              endIcon={<ExpandMore />}
              disabled={saveProgramIsLoading}
            >
              {selectedProgram?.dateSuspended === null ? 'Active' : 'Suspended'}
            </TabletButton>
            <Menu
              id='activity-status-menu'
              anchorEl={programStatusMenuAnchorEl}
              keepMounted
              open={programStatusMenuAnchorEl !== null}
              onClose={handleCloseActivityStatusMenu}
            >
              <MenuItem
                data-track='actCnl-overview-status-dropdown-item-activate'
                disabled={programActive}
                onClick={() => {
                  if (!programActive)
                    runSaveProgram({
                      programId: selectedProgram.programId,
                      name: selectedProgram.name,
                      description: selectedProgram.description ?? '',
                      active: true,
                      isHidden: selectedProgram.isHidden,
                      isRestrictedProgram: selectedProgram.isRestrictedProgram,
                    });
                  setProgramStatusMenuAnchorEl(null);
                }}
              >
                {'Activate'}
              </MenuItem>
              <MenuItem
                data-track='actCnl-overview-status-dropdown-item-suspend'
                disabled={!programActive}
                onClick={() => {
                  if (programActive) setShowSuspendProgramDialog(true);
                  setProgramStatusMenuAnchorEl(null);
                }}
              >
                {'Suspend'}
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item>
            <TabletButton
              data-track='fs-prgMan-delete-program-button'
              variant='outlined'
              color='error'
              onClick={() => {
                if (programs?.length === 1) {
                  setShowNoDeleteLastProgramDialog(true);
                  return;
                }
                setShowDeleteProgramDialog(true);
              }}
              endIcon={<DeleteOutline />}
            >
              <Typography variant='inherit' color='inherit' noWrap>
                {'Delete Program'}
              </Typography>
            </TabletButton>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs container justify='flex-end' spacing={1}>
          <Grid item>
            <TabletButton onClick={resetProgramSelection}>{'Discard New Program'}</TabletButton>
          </Grid>
        </Grid>
      )}
      {selectedProgram ? (
        <>
          <SuspendProgramDialog
            open={showSuspendProgramDialog}
            close={() => setShowSuspendProgramDialog(false)}
            selectedProgram={selectedProgram}
          />
          <DeleteProgramDialog
            open={showDeleteProgramDialog}
            close={() => setShowDeleteProgramDialog(false)}
            selectedProgram={selectedProgram}
          />
          <NoDeleteLastProgramDialog
            open={showNoDeleteLastProgramDialog}
            close={() => setShowNoDeleteLastProgramDialog(false)}
          />
        </>
      ) : null}
    </Grid>
  );
};

export { ProgramFormHeader };
