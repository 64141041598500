import { gql } from '@apollo/client';

export const GET_ADMIN_CONSOLE_APPLICANT_SCREENING_PANEL = gql`
  query GetAdminConsoleApplicantScreeningPanel {
    vm {
      screeningQuestions {
        screeningQuestionId
        label
        order
        correctAnswer
      }
    }
  }
`;

export const GET_APPLICATION_AUTO_VERIFICATION = gql`
  query GetApplicationAutoVerification {
    vm {
      applicationAutoVerification
    }
  }
`;
