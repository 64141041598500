import { gql } from '@apollo/client';

export const GET_TABLE_WIDGET_AWAITING_APPROVAL_TASKS = gql`
  query GetTableWidgetAwaitingApprovalTasks(
    $status: String
    $programIds: [String!]
    $activityIds: [String!]
    $range: String
    $widgetType: String
  ) {
    vm {
      completedTasks(
        status: $status
        programIds: $programIds
        activityIds: $activityIds
        range: $range
        widgetType: $widgetType
      ) {
        completedTaskId
        task {
          title
          cake {
            title
          }
        }
        profile {
          profileId
          userId
          preferredName
          firstName
          lastName
          avatarUrl
        }
        dateSubmitted
        dateLastUpdated
      }
    }
  }
`;
