import { Field } from '@campfire/field';
import { HoverLink } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { TemplateSnackbar } from '../../../common/snackbars/TemplateSnackbar';
import { AnalyticsService } from '../../../global/analytics/AnalyticsService';
import { useApiUrl } from '../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../global/network/useCampfireFetch';
import { signInPath } from '../../../global/public-shell/sign-in-path';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

interface ForgetPasswordForm {
  email: string;
}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter your email address')
    .email('Please enter a valid email address'),
});

export const ForgotPasswordScreen = () => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { run, isLoading, response, error, status } = useCampfireFetch({
    withAuth: false,
    defer: true,
  });

  useEffect(() => {
    if (!response) return;
    if (!response.ok) {
      setErrorMessage('Something went wrong, please try again later');
    }
    setErrorMessage(undefined);
  }, [response]);

  useEffect(() => {
    if (!error) return;
    setErrorMessage('Something went wrong, please try again later');
  }, [error]);

  const apiUrl = useApiUrl();
  const initialValues: ForgetPasswordForm = { email: '' };
  const redirectUrl = signInPath;
  const handleSubmit = async (values: ForgetPasswordForm) => {
    run({
      url: `${apiUrl}/im/user/password/forgot`,
      timeout: 5000,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        ...values,
      },
    });
  };

  const { theme, isMobile } = useCampfireTheme();

  return (
    <Box marginTop={isMobile ? 3 : 7} marginBottom={8} marginLeft={2} marginRight={2}>
      {response && response.ok && !errorMessage ? (
        <Grid container justify='center'>
          <Grid container item xs={12} sm={10} justify='center'>
            <Grid item xs={12}>
              <Typography variant='h6'>{'All Done!'}</Typography>
              <Typography variant='body2'>{'We have sent you a link to reset your password.'}</Typography>
              <Box marginTop={3}>
                <HoverLink color='primary' hoverColor='primary' to={redirectUrl} disabled={isLoading}>
                  {'Back to Sign In'}
                </HoverLink>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container justify='center' spacing={3}>
          <Grid item xs={12} sm={10}>
            <Typography variant='h5' style={{ fontWeight: 'bold' }} align='left'>
              {'Forgot your password?'}
            </Typography>
            <Typography variant='body2' align='left'>
              {'Please enter your email address below'}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={10}>
            <Formik initialValues={initialValues} validationSchema={ForgotPasswordSchema} onSubmit={handleSubmit}>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      variant='outlined'
                      type='email'
                      required
                      fullWidth
                      name='email'
                      id='email'
                      label='Email'
                      iconFocusedColor={theme.palette.secondary.main}
                      hideErrors
                    />
                  </Grid>

                  <Grid container item xs={12} direction='row' justify='flex-end' style={{ marginTop: 15 }}>
                    <Grid item>
                      <TabletButton variant='text' fullWidth size='large'>
                        <HoverLink
                          color='textSecondary'
                          onClick={isLoading ? (e: SyntheticEvent) => e.preventDefault : undefined}
                          to={redirectUrl}
                        >
                          {'Go Back'}
                        </HoverLink>
                      </TabletButton>
                    </Grid>

                    <Grid item xs={3}>
                      <TabletButton
                        fullWidth
                        disabled={isLoading}
                        color='primary'
                        variant='contained'
                        size='large'
                        type='submit'
                        onClick={() => {
                          AnalyticsService.trackEvent({ action: 'click', label: 'Forgot password submit' });
                        }}
                      >
                        {'Submit'}
                      </TabletButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Grid>

          <TemplateSnackbar variant='error' message='Something went wrong' open={status === 'rejected'} />
        </Grid>
      )}
    </Box>
  );
};
