import React, { useState } from 'react';
import { Box, ListItemAvatar } from '@material-ui/core';
import { EmailRounded } from '@material-ui/icons';
import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import { Skeleton } from '@material-ui/lab';
import { GET_WIDGET_VOLUNTEER_APPLICATIONS } from './incoming-volunteers-ra-widget.gql';
import { CommonRequiringActionWidgetProps } from '../../shared';
import {
  GetWidgetVolunteerApplications,
  GetWidgetVolunteerApplicationsVariables,
} from './__generated__/GetWidgetVolunteerApplications';
import { useCampfireQuery } from '../../../../../../../../global/network/useCampfireQuery';
import { CustomListItem } from '../../../../../../../program-manager/volunteers/incoming-volunteers/IncomingVolunteersList';
import { FallbackAvatar } from '../../../../../../user-profile/components/Avatar';
import { VolunteerApplicationsRAWidgetDialog } from './VolunteerApplicationRAWidgetDialog';
import { IncomingApplication } from '../../../../../../../program-manager/volunteers/incoming-volunteers/__generated__/IncomingApplication';
import { VolunteersProvider } from '../../../../../../../program-manager/volunteers/VolunteersContext';
import { WidgetTypes } from '../../../dashboard-widget-setting/dashboard-widget-model';

export const VolunteerApplicationsRAWidget = (props: CommonRequiringActionWidgetProps) => {
  const { range } = props;
  const [selectedApplication, setSelectedApplication] = useState<IncomingApplication>();

  const { data, loading, refetch } = useCampfireQuery<
    GetWidgetVolunteerApplications,
    GetWidgetVolunteerApplicationsVariables
  >(GET_WIDGET_VOLUNTEER_APPLICATIONS, {
    options: {
      variables: {
        type: 'application',
        status: 'pending',
        range: range,
        widgetType: WidgetTypes.REQUIRING_ACTION,
      },
    },
  });

  const incomingVolunteers = data?.vm?.incomingVolunteers?.incomingVolunteers || [];

  const onDialogClose = () => {
    setSelectedApplication(undefined);
  };

  return (
    <>
      <Box width='100%' height='100%' overflow='scroll' maxHeight='max-content'>
        <Box display='flex' flexDirection='column' style={{ gap: 12, overflowY: 'scroll' }}>
          {loading ? (
            <Skeleton variant='text' width='100%' height='65px' />
          ) : (
            incomingVolunteers.map((application) => (
              <CustomListItem
                name={application.name || ''}
                email={application.email}
                avatar={
                  application.name ? (
                    <ListItemAvatar>
                      <FallbackAvatar
                        initials={application.name
                          .split(' ')
                          .map((n) => n[0])
                          .join('')}
                        size={32}
                      />
                    </ListItemAvatar>
                  ) : (
                    <ListItemAvatar>
                      <EmailRounded style={{ marginLeft: 3 }} color='action' />
                    </ListItemAvatar>
                  )
                }
                entryStatus={application.isInvited ? 'Invited' : 'Applied'}
                accountStatus={application.application?.status === 'approved' ? 'Account created' : undefined}
                userActivityStatus={`${
                  application.source === 'Invitee'
                    ? 'Invited'
                    : application.source === 'Application'
                    ? 'Submitted'
                    : 'Updated'
                } ${formatDistance(unpackToDateTime(application.lastUpdated))}`}
                mostRecentReminder={application.application?.mostRecentReminder}
                onClick={() => application.application && setSelectedApplication(application.application)}
              />
            ))
          )}
        </Box>
      </Box>
      {selectedApplication && (
        <VolunteersProvider>
          <VolunteerApplicationsRAWidgetDialog
            open={!!selectedApplication}
            onClose={onDialogClose}
            selectedApplication={selectedApplication}
            refetch={refetch}
          />
        </VolunteersProvider>
      )}
    </>
  );
};
