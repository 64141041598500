import { HoverText } from '@campfire/hover-link';
import { Box, Divider, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

interface FilterItemProps {
  title: string;
  children: ReactNode;
  underline?: boolean;
  showClear?: boolean;
  clearDisabled?: boolean;
  onClear?: () => void;
}
export const FilterItem = (props: FilterItemProps) => {
  const { title, children, underline, showClear, onClear } = props;
  const { theme } = useCampfireTheme();
  return (
    <Box p='15px 10px'>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography
          variant='subtitle2'
          style={{
            color: theme.color.grey.neutral400,
            fontSize: '12px',
            fontWeight: 700,
            paddingBottom: '4px',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Typography>
        {showClear && (
          <HoverText variant='body2' color='primary' onClick={onClear}>
            Clear
          </HoverText>
        )}
      </Box>
      {underline && <Divider style={{ marginBottom: 5 }} />}
      {children}
    </Box>
  );
};
