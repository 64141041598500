import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { FilterList } from '@material-ui/icons';
import { unpackToDate } from '@campfire/hot-date';
import { difference, min, uniqBy } from 'lodash';

import { AvailabilityHeader } from './AvailabilityHeader';
import { SearchField } from '../inputs/SearchField';
import { ActivitySelect } from './ActivitySelect';
import { SessionSelect } from './SessionSelect';
import { DateAndFrequencySelect } from './DateSelect';
import { ActivityFilter, FilterValueType } from './ActivitySelect/ActivityFilter';
import { AvailabilityCenterGetActivities_vm_activities as ActivityType } from '../../global/components/AvailabilityCenter/__generated__/AvailabilityCenterGetActivities';
import { DateSelect } from './DateSelect/DateSelect';

enum AvailaibiltyStepEnum {
  SELECT_ACTIVITY,
  SELECT_SESSIONS,
  SELECT_DATES,
}

export type ValueProps = {
  activityId?: string;
  sessionIds?: string[];
  dates?: string[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      paddingTop: '20px',
      paddingBottom: '30px',
      paddingRight: '22px',
    },
    toolbarButton: {
      borderRadius: '8px',
    },
    toolbarButtonSelected: {
      backgroundColor: theme.color.grey.neutral200,
      color: 'white',
      '&:hover': {
        opacity: 0.8,
        backgroundColor: theme.color.grey.neutral200,
      },
    },
    actionButton: {
      textTransform: 'capitalize',
    },
  })
);

export type EnrolmentDataType = {
  [activityId: string]: {
    availabilities: string[];
    unavailabilities: string[];
  };
};

interface Props {
  value: ValueProps;
  onChange: (newValue: ValueProps) => void;
  activities: ActivityType[];
  open: boolean;
  onClose: () => void;
  onSubmit: (value: ValueProps) => void;
  fullScreen?: boolean;
  enrolmentData: EnrolmentDataType;
}

const SUB_HEADERS = ['Select Activity', 'Select Session/s', 'Select Dates'];

export function AvailabilityCenterDialog({
  value,
  onChange,
  activities,
  onSubmit,
  open,
  onClose,
  fullScreen,
  enrolmentData,
}: Props) {
  const classes = useStyles();
  const [step, setStep] = React.useState(AvailaibiltyStepEnum.SELECT_ACTIVITY);
  const [filter, setFilter] = React.useState<FilterValueType>({ program: 'all', day: {} });
  const [searchValue, setSearchValue] = React.useState('');
  const handleNext = () => {
    if (step === AvailaibiltyStepEnum.SELECT_DATES) {
      onSubmit(value);
      onClose();
    } else {
      setStep(Math.min(step + 1, AvailaibiltyStepEnum.SELECT_DATES));
    }
  };

  const onSelectActivity = (selectedActivityId: string) => {
    onChange({
      ...value,
      activityId: selectedActivityId,
    });
  };

  const onSelectSession = (selectedSessionIds: string[]) => {
    onChange({
      ...value,
      sessionIds: selectedSessionIds,
    });
  };

  const onSelectDates = (selectedDates: string[]) => {
    onChange({
      ...value,
      dates: selectedDates,
    });
  };

  const selectedActivity = activities.find((activity) => activity.activityId === value.activityId);

  const recurrences = React.useMemo(() => {
    switch (selectedActivity?.__typename) {
      case 'VOLUNTEER_RecurringActivityType':
        return selectedActivity.schedule.recurrences;
      default:
        return [];
    }
  }, [selectedActivity]);

  const [showFilter, setShowFilter] = React.useState(false);
  const onToggleFilter = () => setShowFilter(!showFilter);

  const handleCancel = () => {
    onClose();
  };

  const filterActivities = activities
    .filter((activity) => {
      return filter.program === 'all' || activity.program.programId === filter.program;
    })
    .filter((activity) => {
      return (
        Object.values(filter.day).every((selectedDay) => selectedDay) ||
        Object.values(filter.day).every((selectedDay) => !selectedDay) ||
        Boolean(filter.day[unpackToDate(activity.startDate).weekNumber])
      );
    })
    .filter((activity) => activity.name.toLowerCase().includes(searchValue.toLowerCase()));

  const filterSessions = (selectedActivity?.sessions || []).filter((session) =>
    session.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const onChangeFilter = (newValue: FilterValueType) => {
    setFilter({ ...newValue });
  };

  const isNextEnabled = (() => {
    if (step === AvailaibiltyStepEnum.SELECT_ACTIVITY) {
      return selectedActivity;
    }
    if (step === AvailaibiltyStepEnum.SELECT_SESSIONS) {
      return Boolean(value.sessionIds?.length);
    }
    return Boolean(value.dates?.length);
  })();

  React.useEffect(() => {
    setSearchValue('');
  }, [step]);

  const programOptions = uniqBy(
    [{ label: 'All programs', value: 'all' }].concat(
      activities.map((activity) => ({ value: activity.program.programId, label: activity.program.name }))
    ),
    'value'
  );

  const onBack = () => setStep(Math.max(step - 1, 0));
  const occurrences = selectedActivity?.occurrencesBetween || [];

  const selectedActivityId = selectedActivity?.activityId;

  React.useEffect(() => {
    onChange({
      activityId: selectedActivityId,
      dates: [],
      sessionIds: [],
    });
  }, [selectedActivityId]);

  const dateAvailabilities = enrolmentData[selectedActivity?.activityId as string]?.availabilities || [];
  const dateUnavailabilities = enrolmentData[selectedActivity?.activityId as string]?.unavailabilities || [];
  const selectableOccurrences = difference(occurrences, dateAvailabilities);

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth='md'>
      <DialogTitle>
        <AvailabilityHeader header='Availability Center' subHeader={SUB_HEADERS[step]}>
          <Box display={'flex'} alignItems='center'>
            {[AvailaibiltyStepEnum.SELECT_ACTIVITY, AvailaibiltyStepEnum.SELECT_SESSIONS].includes(step) && (
              <SearchField
                value={searchValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setSearchValue(e.target.value)
                }
              />
            )}
            {step === AvailaibiltyStepEnum.SELECT_ACTIVITY && (
              <IconButton
                className={classNames(classes.toolbarButton, { [classes.toolbarButtonSelected]: showFilter })}
                onClick={onToggleFilter}
              >
                <FilterList />
              </IconButton>
            )}
          </Box>
        </AvailabilityHeader>
        {showFilter && step === AvailaibiltyStepEnum.SELECT_ACTIVITY && (
          <ActivityFilter programOptions={programOptions} value={filter} onChange={onChangeFilter} />
        )}
      </DialogTitle>
      <DialogContent>
        <Box>
          {(() => {
            switch (step) {
              case AvailaibiltyStepEnum.SELECT_ACTIVITY:
                return (
                  <ActivitySelect
                    activities={filterActivities}
                    onSelectActivity={onSelectActivity}
                    selectedActivityId={value.activityId}
                    onClose={onClose}
                  />
                );
              case AvailaibiltyStepEnum.SELECT_SESSIONS:
                return (
                  <SessionSelect
                    selectedSessionIds={value.sessionIds || []}
                    sessions={filterSessions}
                    onSelectSession={onSelectSession}
                  />
                );
              case AvailaibiltyStepEnum.SELECT_DATES:
                return recurrences.length > 0 ? (
                  <DateAndFrequencySelect
                    startDate={min([selectedActivity?.startDate, selectableOccurrences[0]])}
                    occurrences={selectableOccurrences}
                    recurrences={recurrences}
                    value={value.dates || []}
                    onChange={onSelectDates}
                    key={selectedActivity?.activityId}
                    availabilities={dateAvailabilities}
                    unavailabilities={dateUnavailabilities}
                  />
                ) : (
                  <DateSelect
                    multiple
                    value={value.dates || []}
                    occurrences={selectableOccurrences}
                    availabilities={dateAvailabilities}
                    unavailabilities={dateUnavailabilities}
                    startDate={min([selectedActivity?.startDate, selectableOccurrences[0]])}
                    onChange={onSelectDates}
                  />
                );
              default:
                return null;
            }
          })()}
        </Box>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        {step === AvailaibiltyStepEnum.SELECT_ACTIVITY ? (
          <Button onClick={handleCancel} disableElevation className={classes.actionButton}>
            Cancel
          </Button>
        ) : (
          <Button onClick={onBack} disableElevation className={classes.actionButton}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          disableElevation
          variant='contained'
          color='primary'
          disabled={!isNextEnabled}
          className={classes.actionButton}
        >
          {step === AvailaibiltyStepEnum.SELECT_DATES ? 'Submit' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
