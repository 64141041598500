import { encodeDate, unpackToDate } from '@campfire/hot-date/lib';
import { HoverText } from '@campfire/hover-link';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box, Grid, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { memo, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { useUser } from '../../../global/auth/useUser';
import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { ActivityTimelineActivityCancellation } from '../../program-manager/activities/activity-timeline/common/ActivityTimelineActivityCancellation';
import { ActivityTimelinePastReport } from '../../program-manager/activities/activity-timeline/past/report/ActivityTimelinePastReport';
import { MyActivitiesNoEnrolmentsEmptyMessage } from '../my-activities/messages/MyActivitiesNoEnrolmentsEmptyMessage';
import { ACTIVITY_REPORT_GET_ACTIVITY_DATE } from './activity-report-model.gql';
import {
  ActivityReportGetActivities_vm_activities as ActivitiesType,
  ActivityReportGetActivities_vm_volunteer_rosterings as RosteringsType,
} from './__generated__/ActivityReportGetActivities';
import {
  ActivityReportGetActivityDate,
  ActivityReportGetActivityDateVariables,
} from './__generated__/ActivityReportGetActivityDate';
import { EmptyMessageCard } from './EmptyMessageCard';
import { InvalidDateSelectedCard } from './InvalidDateSelectedCard';
import { ReportsAllCompleteBlock } from './ReportsAllCompleteBlock';
import { PastMonthReportsBlock } from './PastMonthReportsBlock';
import { ChooseAnAllowedDateBlock } from './ChooseAnAllowedDateBlock';
import { ReportDate } from './ActivityReportScreen';

const ACTIVITIES_TO_SHOW = 3;

interface Props {
  reportDates: ReportDate[];
  isLoading: boolean;
  activities: ActivitiesType[];
  rosterings: RosteringsType[];
  refeshData: () => void;
}

export const ActivityReport = memo((props: Props) => {
  const { reportDates, isLoading, activities, rosterings, refeshData } = props;
  const [selectedActivityId] = useQueryParam('activityId', StringParam);
  const [selectedDate, setSelectedDate] = useQueryParam('activityDate', StringParam);
  const currentDateTime = encodeDate(DateTime.local());
  const history = useHistory();
  const {
    user: { userIdentityService },
  } = useUser();

  useEffect(() => {
    if (!selectedActivityId || !selectedDate) return;
    runGetActivityDateData({
      variables: {
        activityId: selectedActivityId,
        activityDate: selectedDate,
      },
    });
  }, [selectedActivityId, selectedDate]);

  const [
    runGetActivityDateData,
    {
      data: activityDateData,
      refetch: refetchActivityDateData,
      loading: activityDateIsLoading,
      refetchLoading: activityDateRefetchIsLoading,
    },
  ] = useCampfireLazyQuery<ActivityReportGetActivityDate, ActivityReportGetActivityDateVariables>(
    ACTIVITY_REPORT_GET_ACTIVITY_DATE
  );

  const lastMonthsIncompleteReports = useMemo(
    () =>
      reportDates.filter(
        (ir) =>
          unpackToDate(ir.date)
            .diffNow()
            .as('months') >= -1.5
      ),
    [reportDates]
  );

  const activity = useMemo(() => {
    return activityDateData?.vm.activity;
  }, [activityDateData]);

  const validDate = useMemo(() => {
    return true;
  }, [activity]);

  const volunteerAllowedDate = useMemo(() => {
    if (!activity) return false;
    return !!reportDates.find((ir) => ir.activityId === activity.activityId && ir.date === selectedDate);
  }, [activity, selectedDate, reportDates]);

  const lastScheduledDates = useMemo(() => (activity?.lastX ?? []).sort((a, b) => a.localeCompare(b)).reverse(), [
    activity,
    currentDateTime,
  ]);

  const futureDate = useMemo(() => {
    if (!selectedDate) return false;
    return (
      unpackToDate(selectedDate)
        .diffNow()
        .as('days') > 0
    );
  }, [selectedDate]);

  const cancelledActivity = useMemo(() => {
    if (!selectedDate) return undefined;
    return activity?.cancelledActivities.find((x) => x.activityDate === selectedDate);
  }, [activity, selectedDate]);

  const nonTeamMemberAllowedDates = useMemo(() => {
    if (!activity) return [];

    if (activities.find((privilegedActivity) => privilegedActivity.activityId === activity.activityId)) {
      return [];
    }

    return (
      rosterings
        .filter((rostering) => rostering.publishedRoster.activity.activityId === activity.activityId)
        .map((rostering) => rostering.publishedRoster.activityDate) ?? []
    );
  }, [activities, rosterings, activity, selectedDate]);

  const handleBack = () => {
    refeshData();
    history.goBack();
  }

  const isInvalidDateSelected = futureDate || (activity && !validDate);
  const hasActivities = lastMonthsIncompleteReports.length > 0;

  return (
    <Box position='relative'>
      <Grid container>
        <LinearProgressOverlay isLoading={isLoading || activityDateIsLoading || activityDateRefetchIsLoading} />
        <Grid container justify='center'>
          {!hasActivities ? (
            <EmptyMessageCard heading={''} subHeading=''>
              <Box padding={6}>
                <MyActivitiesNoEnrolmentsEmptyMessage />
              </Box>
            </EmptyMessageCard>
          ) : !lastMonthsIncompleteReports.length && !selectedDate ? (
            <ReportsAllCompleteBlock hasRosterings={lastMonthsIncompleteReports.length > 0} />
          ) : !selectedDate ? (
            <PastMonthReportsBlock
              incompleteActivityReports={lastMonthsIncompleteReports.slice(0, ACTIVITIES_TO_SHOW)}
            />
          ) : !volunteerAllowedDate ? (
            <ChooseAnAllowedDateBlock
              activity={activity}
              nonTeamMemberAllowedDates={nonTeamMemberAllowedDates}
              setSelectedDate={(sd) => setSelectedDate(sd)}
            />
          ) : isInvalidDateSelected ? (
            <InvalidDateSelectedCard
              activity={activity}
              selectedDate={selectedDate}
              lastScheduledDates={lastScheduledDates}
              setSelectedDate={(sd) => setSelectedDate(sd)}
            />
          ) : activity ? (
            <Grid item xs={12}>
              {cancelledActivity ? (
                <ActivityTimelineActivityCancellation
                  activityId={activity.activityId}
                  cancelledActivity={cancelledActivity}
                  activityDate={selectedDate}
                  onUncancelActivity={() => (refetchActivityDateData ? refetchActivityDateData() : null)}
                />
              ) : (
                <ActivityTimelinePastReport
                  activityId={activity.activityId}
                  activityDate={selectedDate}
                  SessionReportListBoxProps={{
                    style: {
                      maxWidth: 720,
                      minWidth: 560,
                    },
                  }}
                  onBack={handleBack}
                  onCancelActivity={() => (refetchActivityDateData ? refetchActivityDateData() : null)}
                  source={'activity-report'}
                />
              )}
            </Grid>
          ) : null}

          {(userIdentityService.isProgramManager || userIdentityService.isVmAdmin) && !selectedDate ? (
            <ManagerActivitiesConsoleRedirection />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
});

const ManagerActivitiesConsoleRedirection = () => {
  const { isMobile } = useCampfireTheme();
  const history = useHistory();

  return (
    <Grid item style={isMobile ? { marginTop: 20 } : { position: 'fixed', bottom: 0, paddingBottom: 16 }}>
      <Typography variant='body2' color='textSecondary'>
        To access all activity reports
      </Typography>
      <HoverText color='primary' onClick={() => history.push('/management/rosters')}>
        View in Activities Console
      </HoverText>
    </Grid>
  );
};
