import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import qs from 'qs';
import {
  NumberWidgetProps,
  getStartDateFromInterval,
  getEndDateFromInterval,
  getLastEndDateFromInterval,
  getLastStartDateFromInterval,
  useWidgetStyles,
} from '../shared';
import { AverageNumberConfiguration } from '../../dashboard-widget-setting/__generated__/AverageNumberConfiguration';
import { useApiUrl } from '../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../global/network/useCampfireFetch';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { intervalChangeParsed } from './NumberWidget';

type AverageNumberWidgetProps = NumberWidgetProps & {
  configuration: AverageNumberConfiguration;
};

export const AverageNumberWidget = ({ configuration, tag, name, filters }: AverageNumberWidgetProps) => {
  const classes = useWidgetStyles();
  const { theme } = useCampfireTheme();
  const { interval, range } = configuration;
  const [value, setValue] = React.useState('0');
  const [totalThis, setTotalThis] = React.useState('0');
  const [totalLast, setTotalLast] = React.useState('0');

  const apiUrl = useApiUrl();
  const campfireQuery = useCampfireFetch<{ average: number }>({ defer: true });
  const campfireTotalQuery = useCampfireFetch<{ count: number }>({ defer: true });
  const campfireTotalQuery2 = useCampfireFetch<{ count: number }>({ defer: true });

  React.useEffect(() => {
    campfireQuery
      .run({
        url: `${apiUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/average`,
        params: {
          range: interval,
          filters: JSON.parse(filters),
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets', encode: false });
        },
      })
      .then((response) => {
        if (response.data?.data) {
          setValue(response.data.data.average.toFixed(1));
        }
      });
  }, [range, filters]);

  React.useEffect(() => {
    campfireTotalQuery
      .run({
        url: `${apiUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/total`,
        params: {
          startDate: getStartDateFromInterval(interval),
          endDate: getEndDateFromInterval(interval),
          filters: JSON.parse(filters),
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets', encode: false });
        },
      })
      .then((response) => {
        if (response.data?.data) {
          setTotalThis(response.data.data.count.toString());
        }
      });
  }, []);

  React.useEffect(() => {
    campfireTotalQuery2
      .run({
        url: `${apiUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/total`,
        params: {
          startDate: getLastStartDateFromInterval(interval),
          endDate: getLastEndDateFromInterval(interval),
          filters: JSON.parse(filters),
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets', encode: false });
        },
      })
      .then((response) => {
        if (response.data?.data) {
          setTotalLast(response.data.data.count.toString());
        }
      });
  }, []);

  const crossText = React.useMemo(() => {
    switch (range) {
      case 'TODAY':
        return '1 day';
      case 'THIS_WEEK':
        return 'this week';
      case 'THIS_MONTH':
        return 'this month';
      case 'THIS_YEAR':
        return 'this year';
      default:
        return 'all time';
    }
  }, [range]);

  return (
    <>
      <Box className={classes.valueText}>{value}</Box>
      <Box mt='12px' mb={2} display='flex' justifyContent='center'>
        <Typography style={{ fontSize: '12px', color: theme.color.grey.neutral300 }}>
          Averaged across {crossText}
        </Typography>
      </Box>

      <Box display='flex' justifyContent='space-evenly' width='100%' paddingTop={'1em'}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography style={{ fontWeight: 700, color: theme.color.grey.neutral400, fontSize: '1.2em' }}>
            {totalLast}
          </Typography>
          <Typography style={{ fontSize: '12px', color: theme.color.grey.neutral300 }}>
            Total last {intervalChangeParsed(interval)}
          </Typography>
        </Box>
        <Box>
          <Divider orientation='vertical' style={{ backgroundColor: theme.color.grey.neutralBrand200 }} />
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography style={{ fontWeight: 700, color: theme.color.grey.neutral400, fontSize: '1.2em' }}>
            {totalThis}
          </Typography>
          <Typography style={{ fontSize: '12px', color: theme.color.grey.neutral300 }}>
            Total this {intervalChangeParsed(interval)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
