import { HoverText } from '@campfire/hover-link';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { useCommonStyles } from '../commonStyles';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { AppStatusSection } from './AppStatusSection';
import { AppSettingForm } from './AppSettingForm';

export const useStyles = makeStyles(() =>
  createStyles({
    content: {
      marginTop: 8,
      fontSize: 14,
      fontWeight: 400,
      opacity: '60%',
      lineHeight: '18px',
      letterSpacing: '0.2px',
    },
  })
);

const mockSettingData = {
  mailchimp: {
    addUpdateVolunteers: false,
    removeVolunteers: false,
    segmentByProgram: false,
    activityTags: false,
  },
  salesforce: {
    checkBoxGroupOne: false,
    checkBoxGroupTwo: false,
  },
  campaignMonitor: {
    addUpdateVolunteers: false,
    removeVolunteers: false,
    segmentByProgram: false,
    activityTags: false,
  },
};

export const AppSetting = ({
  id,
  accountDescription,
  lastUpdated,
  name,
  authorisation,
}: {
  id: string;
  accountDescription: string;
  lastUpdated: string;
  name: string;
  authorisation: string;
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const commonStyles = useCommonStyles();
  const classes = useStyles();
  const { isSm } = useCampfireTheme();
  const appMockSettingData = (mockSettingData as any)[id];

  return (
    <>
      <Box className={commonStyles.heading}>
        <Typography variant='h2' className={commonStyles.headingTitle}>
          Integrations Setting - {name}
        </Typography>
      </Box>
      <Box className={commonStyles.containerPanel}>
        <Typography variant='h2' className={commonStyles.headingTitle}>
          Your Account
        </Typography>
        <Box display='flex' flexDirection={isSm ? 'column' : 'row'} py='1rem'>
          <Box flex={1} borderRight={isSm ? 'none' : '1px solid #dfdfdf'} mr='1rem'>
            <Typography className={commonStyles.sectionHeading}>Status</Typography>
            <AppStatusSection accountDescription={accountDescription} lastUpdated={lastUpdated} name={name} />
          </Box>
          <Box flex={1}>
            <Typography className={commonStyles.sectionHeading}>Authorisations</Typography>
            <div
              className={classes.content}
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(authorisation || '') }}
            />
          </Box>
        </Box>
      </Box>
      <Box className={commonStyles.containerPanel}>
        <Box display='flex' alignItems='center'>
          <Typography variant='h2' className={commonStyles.headingTitle}>
            Settings
          </Typography>
          <HoverText
            color={editMode ? 'error' : 'textSecondary'}
            variant='body2'
            invertUnderlineBehaviour
            onClick={() => setEditMode(!editMode)}
            style={{
              display: 'inline-block',
              alignItems: 'center',
              fontSize: '12px',
              margin: '0 16px',
              paddingBottom: '0.5rem',
            }}
          >
            {editMode ? 'Cancel' : 'Edit'}
          </HoverText>
        </Box>
        <AppSettingForm editMode={editMode} id={id} initialValues={appMockSettingData} />
      </Box>
    </>
  );
};
