import { useFormikContext } from 'formik';
import React from 'react';
import { CreatWidgetFormValues, WidgetTypes } from '../../dashboard-widget-model';
import { TotalNumberWidgetForm } from './TotalNumberWidgetForm';
import { AverageNumberWidgetForm } from './AverageNumberWidgetForm';
import { CustomMultiplierNumberWidgetForm } from './CustomMultiplierNumberWidgetForm';
import { TargetNumberWidgetForm } from './TargetNumberWidgetForm';
import { TrackingNumberWidgetForm } from './TrackingNumberWidgetForm';

export function NumberWidgetForm() {
  const { values: formValues } = useFormikContext<CreatWidgetFormValues>();

  switch (formValues.numberWidgetType) {
    case WidgetTypes.TOTAL:
      return <TotalNumberWidgetForm />;
    case WidgetTypes.AVERAGE:
      return <AverageNumberWidgetForm />;
    case WidgetTypes.CUSTOM_MULTIPLIER:
      return <CustomMultiplierNumberWidgetForm />;
    case WidgetTypes.TARGET:
      return <TargetNumberWidgetForm />;
    case WidgetTypes.TRACKING:
      return <TrackingNumberWidgetForm />;
    default:
      return null;
  }
}
