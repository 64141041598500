import React from 'react';
import { useFormikContext } from 'formik';
import { CreatWidgetFormValues, WidgetTypes } from './dashboard-widget-model';
import { ChartWidgetForm } from './forms/ChartWidgets/ChartWidgetForm';
import { NumberWidgetForm } from './forms/NumberWidgets/NumberWidgetForm';
import { TableWidgetForm } from './forms/TableWidgets/TableWidgetForm';
import { RequiringActionWidgetForm } from './forms/RequiringActionWidgets/RequiringActionWidgetForm';
import { NumberChartWidgetForm } from './forms/ChartWidgets/NumberChartWidgetForm';

export const WidgetForm = () => {
  const { values } = useFormikContext<CreatWidgetFormValues>();

  switch (values.byType) {
    case WidgetTypes.CHART:
      return <ChartWidgetForm />;
    case WidgetTypes.NUMBER:
      return <NumberWidgetForm />;
    case WidgetTypes.TABLE:
      return <TableWidgetForm />;
    case WidgetTypes.REQUIRING_ACTION:
      return <RequiringActionWidgetForm />;
    case WidgetTypes.NUMBER_CHART:
      return <NumberChartWidgetForm />;
    default:
      return null;
  }
};
