import React, { memo } from 'react';
import { Dialog, DialogContent, Grid, Typography, DialogActions, Button } from '@material-ui/core';

export const UnsavedCommentsDialog = memo(
  ({ open, close, closeProfileDialog }: { open: boolean; close: Function; closeProfileDialog: Function }) => {
    return (
      <Dialog open={open}>
        <DialogContent>
          <Grid container direction='row' style={{ padding: 8 }}>
            <Grid item xs={12}>
              <Typography align='center'>You have unsaved comments.</Typography>
              <Typography align='center'>Are you sure you want to continue?</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()}>Go back</Button>
          <Button variant='text' color='secondary' onClick={() => closeProfileDialog()}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
