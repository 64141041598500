import React from 'react';
import { Box, Grid, Theme, Typography } from '@material-ui/core';
import { AddCircle, KeyboardArrowRight } from '@material-ui/icons';
import { capitalize } from 'lodash';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';

import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { CreatWidgetFormValues, StepsEnum } from '../dashboard-widget-model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.color.grey.neutral500,
      fontWeight: 700,
      fontSize: '18px',
      marginBottom: '8px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 700,
      color: theme.color.grey.neutral500,
      paddingBottom: '6px',
    },
    valueItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '56px',
      borderRadius: '6px',
      marginTop: '-8px',
      padding: '0 16px',
      cursor: 'pointer',
      position: 'relative',

      border: `1px solid ${theme.color.grey.neutralBrand200}`,
    },
  })
);

interface SelectedValuesProps {
  maxValue?: number;
}

export function SelectedValues({ maxValue = 1 }: SelectedValuesProps) {
  const { theme } = useCampfireTheme();
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<CreatWidgetFormValues>();

  const onUpdateValue = (index: number) => {
    setFieldValue('selectStep', StepsEnum.ADD_VALUE);
    setFieldValue('updatingValueIndex', index);
  };

  const onAddValue = () => {
    setFieldValue('selectStep', StepsEnum.ADD_VALUE);
    setFieldValue('updatingValueIndex', -1);
  };

  return (
    <Grid item xs={12}>
      <Typography className={classes.title}>Values</Typography>
      <Box mt={2}>
        {values.byValues.map((item, index) => (
          <Box
            className={classes.valueItem}
            onClick={() => onUpdateValue(index)}
            style={{
              backgroundColor: theme.color.grey.neutralBrand100,
              zIndex: maxValue - index,
            }}
          >
            <Typography style={{ marginTop: index && '8px' }}>
              <span style={{ fontWeight: 700 }}>{capitalize(item.tag)}</span>
              {` > ${capitalize(item.name)}`}
            </Typography>
            <KeyboardArrowRight style={{ marginTop: '4px', color: theme.color.grey.neutral200 }} />
          </Box>
        ))}
        {values.byValues.length < maxValue && (
          <Box className={classes.valueItem} onClick={onAddValue}>
            <Box
              display='flex'
              style={{
                marginTop: values.byValues.length && '8px',
              }}
            >
              <AddCircle color='primary' />
              <Typography color='primary' style={{ fontWeight: 700, marginLeft: '8px' }}>
                Add Series/Value
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
