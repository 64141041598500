import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { unpackToDateTime } from '@campfire/hot-date';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { capitalize } from 'lodash';
import {
  GetTableWidgetVolunteerApplications,
  GetTableWidgetVolunteerApplicationsVariables,
} from './__generated__/GetTableWidgetVolunteerApplications';
import { useCampfireQuery } from '../../../../../../../../../global/network/useCampfireQuery';
import { GET_TABLE_WIDGET_VOLUNTEER_APPLICATIONS } from './volunteer-applications-table-widget-model.gql';
import { WidgetTypes } from '../../../../dashboard-widget-setting/dashboard-widget-model';
import { CommonRequiringActionWidgetProps } from '../../../shared';

export const VolunteerApplicationsTableWidget = (props: CommonRequiringActionWidgetProps) => {
  const { range } = props;

  const { data, loading } = useCampfireQuery<
    GetTableWidgetVolunteerApplications,
    GetTableWidgetVolunteerApplicationsVariables
  >(GET_TABLE_WIDGET_VOLUNTEER_APPLICATIONS, {
    options: {
      variables: {
        type: 'application',
        range: range,
        widgetType: WidgetTypes.TABLE,
      },
    },
  });

  const incomingVolunteerApplications =
    data?.vm?.incomingVolunteers?.incomingVolunteers.map((incomingVolunteer) => ({
      id: incomingVolunteer.application?.applicationId,
      volunteer: incomingVolunteer.name,
      email: incomingVolunteer.email,
      dateSubmitted: unpackToDateTime(incomingVolunteer.application?.dateSubmitted).toFormat('dd/MM/y'),
      status: capitalize(incomingVolunteer.application?.status),
    })) || [];

  const columns: GridColumns = [
    {
      field: 'volunteer',
      headerName: 'Volunteer',
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }: any) => {
        return (
          <Tooltip title={row.volunteer}>
            <Typography noWrap style={{ fontSize: 'inherit' }}>
              {row.volunteer}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      disableColumnMenu: true,
      minWidth: 180,
      renderCell: ({ row }: any) => {
        return (
          <Tooltip title={row.email}>
            <Typography noWrap style={{ fontSize: 'inherit' }}>
              {row.email}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'dateSubmitted',
      headerName: 'Date Submitted',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 180,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
  ];

  return (
    <Box width='100%' height='100%'>
      <>
        <DataGrid
          columns={columns}
          rows={incomingVolunteerApplications}
          loading={loading}
          autoPageSize
          disableSelectionOnClick
        />
      </>
    </Box>
  );
};
