import React, { useEffect, useState } from 'react';
import { useUser } from '../../../../global/auth/useUser';
import { GET_USER_PAGE_INTERACTIONS } from '../../../../global/interactions/interactions-model.gql';
import { recordInteraction } from '../../../../global/interactions/recordInteraction';
import {
  GetUserPageInteractions,
  GetUserPageInteractionsVariables,
} from '../../../../global/interactions/__generated__/GetUserPageInteractions';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { GET_VOLUNTEER_DATE_CREATED } from './welcome-dialogs-model.gql';
import { GetVolunteerDateCreated, GetVolunteerDateCreatedVariables } from './__generated__/GetVolunteerDateCreated';

const WelcomeToCampfireTutorialDialog = React.lazy(() => import('./WelcomeToCampfireTutorialDialog'));

const interaction = {
  page: 'login',
  action: 'oct-release',
};

const WelcomeDialogsInner = () => {
  const [isFirstPageVisit, setIsFirstPageVisit] = useState(false);
  const { userIdentity, getVolunteerIdentity } = useUser();

  const { postInteraction } = recordInteraction('vm');

  const { data: volunteerDateCreatedResponse } = useCampfireQuery<
    GetVolunteerDateCreated,
    GetVolunteerDateCreatedVariables
  >(GET_VOLUNTEER_DATE_CREATED, {
    options: {
      variables: {
        volunteerId: getVolunteerIdentity().volunteerId,
      },
    },
  });

  const { data: firstPageVisitInteractionResponse } = useCampfireQuery<
    GetUserPageInteractions,
    GetUserPageInteractionsVariables
  >(GET_USER_PAGE_INTERACTIONS, {
    options: {
      variables: {
        userId: userIdentity.userId,
        page: interaction.page,
      },
    },
  });

  useEffect(() => {
    if (firstPageVisitInteractionResponse) {
      const { userInteractions } = firstPageVisitInteractionResponse.vm;
      if (!userInteractions.find(({ action }) => action === interaction.action)) {
        setIsFirstPageVisit(true);
      }
    }
  }, [firstPageVisitInteractionResponse]);

  function handleClose() {
    postInteraction(interaction.page, interaction.action);
    setIsFirstPageVisit(false);
  }

  return (
    <>
      {volunteerDateCreatedResponse &&
      volunteerDateCreatedResponse.vm.volunteer &&
      volunteerDateCreatedResponse.vm.volunteer.currentOnboardingStep ? (
        <>
          <WelcomeToCampfireTutorialDialog open={isFirstPageVisit} onClose={handleClose} />
        </>
      ) : null}
    </>
  );
};

const WelcomeDialogs = () => {
  const { maybeVolunteerIdentity } = useUser();
  if (!maybeVolunteerIdentity) return null;
  return <WelcomeDialogsInner />;
};

export { WelcomeDialogs };
