import React from 'react';
import { Dialog } from '@material-ui/core';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';
import { IncomingVolunteerViewApplicantScreen } from '../../../../../../../program-manager/volunteers/incoming-volunteer/IncomingVolunteerViewApplicantScreen';
import { IncomingApplication } from '../../../../../../../program-manager/volunteers/incoming-volunteers/__generated__/IncomingApplication';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedApplication: IncomingApplication;
  refetch: () => void;
}

export const VolunteerApplicationsRAWidgetDialog = (props: Props) => {
  const { open, onClose, selectedApplication, refetch } = props;
  const { isMobile } = useCampfireTheme();

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile} PaperProps={{ style: { maxWidth: 'unset' } }}>
      <IncomingVolunteerViewApplicantScreen
        selectedApplicationId={selectedApplication.applicationId}
        goBack={onClose}
        reloadList={refetch}
      />
    </Dialog>
  );
};
