import { InputAdornment, TextField, TextFieldProps, Theme } from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Field } from 'formik';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hideShowPasswordIcon: {
      cursor: 'pointer',
      padding: 4,
      color: theme.palette.action.disabledBackground,
      '&:hover': {
        color: theme.palette.action.disabled,
      },
    },
    hideShowPasswordIconSelected: {
      color: theme.palette.common.white,
      borderRadius: 32,
      padding: 4,
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
    },
  })
);

type PasswordFieldProps = {
  name: string;
} & TextFieldProps;

export const PasswordField = (props: PasswordFieldProps) => {
  const { name: fieldName, label } = props;
  const [passwordFieldHidden, setPasswordFieldHidden] = useState(true);
  const classes = useStyles();

  return (
    <>
      <Field
        {...props}
        as={TextField}
        name={fieldName}
        label={label ?? 'Password'}
        type={passwordFieldHidden ? 'password' : 'text'}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position='end'
              onClick={() => {
                setPasswordFieldHidden(!passwordFieldHidden);
              }}
              title='Toggle password visibility'
            >
              <VisibilityIcon
                className={classNames({
                  [classes.hideShowPasswordIcon]: passwordFieldHidden,
                  [classes.hideShowPasswordIconSelected]: !passwordFieldHidden,
                })}
                fontSize='default'
                color='disabled'
              />
            </InputAdornment>
          ),
          ...props.InputProps,
        }}
      />
    </>
  );
};
