import { unpackToDateTimeTZ } from '@campfire/hot-date';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { StatusChip } from '../../../../../common/chips/StatusChip';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { getFieldTypeLabelAndIcon } from '../../common/FieldTypeLabelAndIcon';
import { SectionItem, useSectionStyles } from '../../report-types/report-type-detail/ReportTypeDetail';
import { ReportManagementReportTypeField } from '../../report-types/__generated__/ReportManagementReportTypeField';
import { FieldOption } from '../report-field-types';
import { ReportTypeFieldsReportType } from '../__generated__/ReportTypeFieldsReportType';
import { ReportFieldDetailHeader } from './report-field-detail-header/ReportFieldDetailHeader';
import { ReportTypesUsingReportField } from './ReportTypesUsingReportField';

export type ReportType = Omit<ReportTypeFieldsReportType, 'items' | '__typename'>;
export type ReportField = ReportManagementReportTypeField & { reportTypes: Array<ReportType> };
type Props = {
  reportField: ReportField;
  allActiveFieldOptions: Array<FieldOption>;
  getReportFieldsRefetch: () => void;
};

export const ReportFieldDetail = ({ reportField, allActiveFieldOptions, getReportFieldsRefetch }: Props) => {
  const classes = useSectionStyles();
  const { theme } = useCampfireTheme();

  return (
    <Box width='100%' display='inline-grid'>
      <ReportFieldDetailHeader
        reportField={reportField}
        allActiveFieldOptions={allActiveFieldOptions}
        getReportFieldsRefetch={getReportFieldsRefetch}
      />
      {reportField.description && <SectionItem title='Description' text={reportField.description} />}
      <SectionItem title='Field Type'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          {getFieldTypeLabelAndIcon(reportField.__typename)}
          {reportField.__typename === 'VOLUNTEER_DropdownFieldType' && reportField.isAllowMultiple ? (
            <StatusChip status='Multiple' bgcolor={theme.color.grey.neutral300} />
          ) : reportField.__typename === 'VOLUNTEER_NumericFieldType' && reportField.isHero ? (
            <StatusChip status='Pinned' bgcolor={theme.color.grey.neutral300} />
          ) : null}
        </Box>
        {reportField.__typename === 'VOLUNTEER_DropdownFieldType' &&
          reportField.dropdownOptions
            .sort((a, b) => a.order - b.order)
            .map((option) => (
              <Box ml='24px'>
                <Typography variant='caption' style={{ color: theme.color.grey.neutral300 }}>
                  {option.name}
                </Typography>
              </Box>
            ))}
      </SectionItem>
      <ReportTypesUsingReportField reportTypes={reportField.reportTypes} />
      <Box style={{ paddingTop: 16, paddingBottom: 16 }}>
        <Typography className={classes.sectionBody}>
          <b style={{ fontWeight: 600 }}>Created at:</b>{' '}
          {`${unpackToDateTimeTZ(reportField.dateCreated).toFormat('h:mm a, dd/MM/y')}`}
        </Typography>
        {reportField.dateDeactivated && (
          <Typography className={classes.sectionBody}>
            <b style={{ fontWeight: 600 }}>Archived at:</b>{' '}
            {`${unpackToDateTimeTZ(reportField.dateDeactivated).toFormat('h:mm a, dd/MM/y')}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
