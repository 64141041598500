import { TabletButton } from '@campfire/tablet-button';
import {
  Badge,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { ErrorRounded, WarningRounded } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { SearchField } from '../../../../../../../common/inputs/SearchField';
import { Avatar } from '../../../../../../general/user-profile/components/Avatar';
import { ConfirmAddMaxVolunteer } from './ConfirmAddMaxVolunteer';

export interface MultiSelectVolunteersDialogVolunteerType {
  volunteerId: string;
  profile: {
    __typename: 'VOLUNTEER_ProfileType';
    profileId: string;
    userId: string;
    preferredName: string;
    lastName: string;
    avatarUrl: string | null;
  };
}

export interface MultiSelectVolunteersDialogProps {
  open: boolean;
  title: string;
  volunteers: Array<MultiSelectVolunteersDialogVolunteerType>;
  isLoading?: boolean;
  showSearchField?: boolean;
  children: React.ReactNode;
  ignoreVolunteerIds: string[];
  maxSelected?: number | null;
  onClose: (event: any) => void;
  onSubmit: (volunteers: Array<MultiSelectVolunteersDialogVolunteerType>) => void;
}

const MultiSelectVolunteersDialog = (props: MultiSelectVolunteersDialogProps) => {
  const {
    open,
    onClose,
    title,
    children,
    onSubmit,
    volunteers,
    isLoading,
    showSearchField,
    ignoreVolunteerIds,
    maxSelected,
  } = props;

  const [selectedVolunteers, setSelectedVolunteers] = useState<Array<MultiSelectVolunteersDialogVolunteerType>>([]);
  const [searchFilter, setSearchFilter] = useState<string>();
  const [openConfirm, setOpenConfirm] = useState(false);

  const filteredVolunteers = useMemo(
    () =>
      volunteers
        .sort((a, b) => a.profile.preferredName.localeCompare(b.profile.preferredName))
        .filter((x) => !ignoreVolunteerIds.includes(x.volunteerId)) ?? [],
    [volunteers, ignoreVolunteerIds]
  );

  function handleToggle(volunteer: MultiSelectVolunteersDialogVolunteerType) {
    const selectedVolunteer = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
    if (!selectedVolunteer) {
      setSelectedVolunteers([...selectedVolunteers, volunteer]);
      return;
    }
    setSelectedVolunteers(selectedVolunteers.filter((x) => x.volunteerId !== volunteer.volunteerId));
  }

  function handleClose(event: any) {
    onClose(event);
    setSearchFilter(undefined);
    setOpenConfirm(false);
  }

  const handleAddSubmit = () => {
    if (isExceeded) {
      setOpenConfirm(true);
    } else {
      onSubmit(selectedVolunteers);
      setSelectedVolunteers([]);
      handleClose({});
    }
  };

  useEffect(() => forceCheck(), [searchFilter]);

  const hasValidMaxSelected = maxSelected !== null && maxSelected !== undefined;
  const isWarning = hasValidMaxSelected && selectedVolunteers.length === maxSelected;
  const isExceeded = hasValidMaxSelected && selectedVolunteers.length > maxSelected!;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth style={{ zIndex: 1400 }}>
      <DialogTitle>
        <Grid container justify='space-between'>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <Typography style={{ fontWeight: 500, marginRight: '16px', lineHeight: '30px' }}>{title}</Typography>
              {isWarning ? <MaxWarning warning /> : null}
              {isExceeded ? <MaxWarning exceeded /> : null}
            </Box>
          </Grid>
          {showSearchField ? (
            <Grid item>
              <SearchField placeholder='Search Volunteers' growLeft onChange={(e) => setSearchFilter(e.target.value)} />
            </Grid>
          ) : null}
        </Grid>
      </DialogTitle>
      <DialogContent dividers style={{ paddingLeft: 0, paddingRight: 0 }} id='dialog-content'>
        {isLoading ? (
          <Box px={2}>{'Loading...'}</Box>
        ) : filteredVolunteers.length ? (
          <div>
            {children ? <Box paddingX={4}>{children}</Box> : null}
            <Box flex='1 1 auto' display='flex' justifyContent='center'>
              <List style={{ width: '100%' }}>
                {filteredVolunteers
                  .filter((volunteer) =>
                    searchFilter
                      ? volunteer.profile.preferredName
                          .concat(` ${volunteer.profile.lastName}`)
                          .toLowerCase()
                          .includes(searchFilter.toLowerCase())
                      : true
                  )
                  .map((volunteer) => {
                    const isSelected = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
                    return (
                      <LazyLoad
                        key={volunteer.volunteerId}
                        scrollContainer={'#dialog-content'}
                        height={66}
                        offset={400}
                        overflow
                      >
                        <VolunteerListItem
                          isSelected={!!isSelected}
                          volunteer={volunteer}
                          handleToggle={handleToggle}
                        />
                      </LazyLoad>
                    );
                  })}
              </List>
            </Box>
          </div>
        ) : (
          <Box>{'No Volunteers...'}</Box>
        )}
        {openConfirm && (
          <ConfirmAddMaxVolunteer
            onCancel={() => setOpenConfirm(false)}
            onOK={() => {
              onSubmit(selectedVolunteers);
              setSelectedVolunteers([]);
              handleClose({});
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box padding={2}>
          <TabletButton variant='text' color='error' onClick={handleClose} style={{ marginRight: 8 }}>
            {'Cancel'}
          </TabletButton>
          <TabletButton
            data-track='actCnl-add-volunteers'
            variant='contained'
            color='primary'
            onClick={handleAddSubmit}
          >
            {'Add Volunteers'}
          </TabletButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const VolunteerListItem = React.memo(
  (props: {
    volunteer: MultiSelectVolunteersDialogVolunteerType;
    isSelected: boolean;
    handleToggle: (volunteer: MultiSelectVolunteersDialogVolunteerType) => void;
  }) => {
    const { isSelected, volunteer, handleToggle } = props;
    const {
      profile: { preferredName, lastName, avatarUrl },
    } = volunteer;
    const volunteerName = `${preferredName} ${lastName}`;
    return (
      <ListItem
        button
        onClick={() => handleToggle(volunteer)}
        style={{
          paddingLeft: '20%',
          paddingRight: 32,
        }}
      >
        <Box
          component='li'
          display='flex'
          alignContent='center'
          alignItems='center'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <FormControlLabel label='' control={<Checkbox checked={isSelected} color='primary' />} />
          <Avatar preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} size={32} />
          <Typography style={{ marginLeft: 8 }}>{volunteerName}</Typography>
        </Box>
      </ListItem>
    );
  }
);

const MaxWarning = ({ warning, exceeded }: { warning?: boolean; exceeded?: boolean }) => {
  const backgroundColor = warning ? '#efb31a' : exceeded ? '#D93A00' : '';
  const message = warning ? 'Max Reached' : exceeded ? 'Max Exceeded' : '';
  return (
    <Badge
      style={{
        backgroundColor: backgroundColor,
        color: '#ffffff',
        padding: '6px',
        borderRadius: '3px',
        height: 'fit-content',
      }}
    >
      {warning ? (
        <WarningRounded style={{ fontSize: '16px' }} />
      ) : exceeded ? (
        <ErrorRounded style={{ fontSize: '16px' }} />
      ) : (
        ''
      )}
      <Typography style={{ fontSize: '12px', paddingLeft: '4px', fontWeight: 500, paddingRight: '2px' }}>
        {message}
      </Typography>
    </Badge>
  );
};

export { MultiSelectVolunteersDialog };
