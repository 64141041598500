import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import YourInControl from '../../../assets/tutorial-graphics/welcome/youre_in_control.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Rosters',
    description: (
      <>
        The Rosters page is primarily for the <strong>rostering of volunteers</strong> on a specific date.
        <br />
        <br />
        If there are additional volunteers that are not within the normal team attending that date, you can add them to
        that specific roster.
      </>
    ),
    image: YourInControl,
  },
  {
    label: 'Rosters',
    description: (
      <>
        You may also need to <strong>cancel an activity</strong> if it cannot go ahead on that date.
      </>
    ),
    image: YourInControl,
  },
];

const RostersTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { RostersTutorialDialog };
