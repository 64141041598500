import { Box } from '@material-ui/core';
import React from 'react';
import { SideBarIncompleteReports } from './incomplete-reports/SideBarIncompleteReports';
import { SideBarNewTasks } from './new-tasks/SideBarNewTasks';
import { SideBarAvailabilityAlert } from './availability-alert/SideBarAvailabilityAlert';

export const MyElementsSmallScreenAlerts = () => {
  return (
    <Box>
      <SideBarIncompleteReports />
      <SideBarNewTasks />
      <SideBarAvailabilityAlert />
    </Box>
  );
};
