export const incidentStatusTextForDisplay = {
  new: 'New',
  working: 'In Review',
  closed: 'Completed',
  archived: 'Archived',
};

export const actionMenuOptionLabels = {
  moveToWorking: 'Move to Review',
  moveToClosed: 'Move to Completed',
  moveToArchive: 'Move to Archive',
};

export const incidentStatusOptions = [
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'In Review',
    value: 'working',
  },
  {
    label: 'Completed',
    value: 'closed',
  },
  {
    label: 'Archive',
    value: 'archived',
  },
];

export const activityTypeOptions = [
  {
    label: 'All incidents',
    value: 'all',
  },
  {
    value: 'in-activity',
    label: 'In an activity',
  },
  {
    value: 'outside-activity',
    label: 'Outside an activity',
  },
];

export const DAY_OPTIONS = 'MTWTFSS'.split('').map((day, dayIndex) => ({ value: dayIndex, label: day }));

export const initialFilters = {
  program: 'all',
  activityType: 'all',
  severity: 'all',
  day: [],
};
