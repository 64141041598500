import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const keyify = (obj: any, prefix: string = ''): any =>
  Object.keys(obj).reduce((res: string[], el: string) => {
    if (Array.isArray(obj[el])) {
      return [...res, `${prefix}${el}[${Object.keys(obj[el])[0]}].name`];
    }
    if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyify(obj[el], `${prefix}${el}.`)];
    }
    return [...res, prefix + el];
  }, []);

export const FormikErrorFocus = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    const keys = keyify(errors);
    if (!keys) return;
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errEl = document.getElementById(keys[0]);
      if (errEl) {
        errEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        errEl.focus();
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};
