import { gql } from '@apollo/client';

export const CancelledActivityFragments = gql`
  fragment CancelledActivities on VOLUNTEER_CancelledActivityType {
    activityDate
    activity {
      activityId
    }
  }
`;
