import { useMemo } from 'react';
import { Location } from 'history';
import { getIn } from 'formik';
import { useRoutesMap } from './useRoutesMaps';
import { RoutesMaps } from './routes-map-model';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

export const getBreadCrumbs = (location: Location): Array<string> => {
  const routesMaps = useRoutesMap();
  const { isMobile } = useCampfireTheme();
  const [_unused, primaryPath, secondaryPath, ...rest] = location.pathname.split('/');
  const upperRoute: RoutesMaps | undefined = useMemo(() => {
    let maybeUpperRoute;
    const routesMapsKeys = Object.keys(routesMaps);
    for (let i = 0; i < routesMapsKeys.length; i++) {
      const routeMap: RoutesMaps = getIn(routesMaps, routesMapsKeys[i]);
      if (routeMap.route === primaryPath) {
        maybeUpperRoute = routeMap;
      }
    }
    return maybeUpperRoute;
  }, [primaryPath]);

  const secondaryBreadcrumb: string = useMemo(() => {
    if (!upperRoute) {
      return '';
    }
    const lowerRouteFiltered = upperRoute.items.find((lower) => lower.route === secondaryPath);
    if (lowerRouteFiltered) {
      return lowerRouteFiltered.label;
    }

    for (let i = rest.length; i >= 0; i--) {
      const trailingPathSlice = [secondaryPath, ...rest.slice(0, i + 1)].join('/');
      const trailingRouteFiltered = upperRoute.items.find((lower) => lower.route === trailingPathSlice);
      if (trailingRouteFiltered) {
        return trailingRouteFiltered.label;
      }
    }
    return '..';
  }, [location.pathname]);
  const customTextForScreens = (route: string) => (isMobile ? `${route.substring(0, 3)}..` : route);
  const primaryBreadcrumb = upperRoute && upperRoute.heading ? customTextForScreens(upperRoute.heading) : '';
  return [primaryBreadcrumb, secondaryBreadcrumb];
};
