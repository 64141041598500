import React from 'react';
import classNames from 'classnames';
import { Box, Collapse, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { StringParam, useQueryParams } from 'use-query-params';
import { FilterList } from '@material-ui/icons';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { ActivityReportTabHeader, ActivityReportEnum } from './ActivityReportTabHeader';
import { SearchField } from '../../../../common/inputs/SearchField';
import { ActivityReport } from './ActivityReport';
import { IncompleteReport } from './IncompleteReport';
import { CompletedReport } from './CompletedReport';
import { Filter } from './Filter';
import { ProgramsContext } from '../../../../global/programs-sell/ProgramsContext';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ToggleButton } from '../../../../common/buttons/ToggleButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 'calc(100vh - 57px)',
      overflowY: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    subTitle: {
      marginTop: 20,
      marginBottom: 20,
      fontSize: 14,
      fontWeight: 400,
      color: theme.color.grey.neutral200,
    },
    button: {
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '4px',
      textTransform: 'none',
      marginRight: '10px',
      padding: '7px 12px',
    },
    headerButton: {
      color: theme.color.grey.neutral400,
      borderColor: '#dcdcdc',
    },
    toggledButton: {
      color: '#FCFCFC !important',
      backgroundColor: '#646464 !important',
    },
    typography: {
      marginLeft: '4px',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '19px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    headerContainer: {
      [theme.breakpoints.up('md')]: {
        margin: '16px 60px 0',
      },
      backgroundColor: 'white',
    },
  })
);

export function ActivityReportContent() {
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    q: StringParam,
    activityDate: StringParam,
    activityId: StringParam,
  });

  const { tab: selectedTab, q = '', activityId, activityDate } = query;

  const { isMd, isMobile } = useCampfireTheme();

  const [showFilters, setShowFilters] = React.useState(false);
  const classes = useStyles();

  const { getAuthorizedPrograms } = React.useContext(ProgramsContext);
  const programs = getAuthorizedPrograms();

  const showSessionReport = React.useMemo(() => {
    return !isMd && activityId && activityDate;
  }, [isMd, activityId, activityDate]);

  // const buttonClasses = sharedStyles.button();

  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <ActivityReportTabHeader>
          <Box flexBasis='50%' display='flex' justifyContent='flex-end' paddingRight='10px'>
            <SearchField
              value={q}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                e.stopPropagation();
                setQuery({ ...query, q: e.target.value });
              }}
            />
          </Box>
          <ToggleButton
            classes={{
              root: classNames(classes.button, classes.headerButton),
              selected: classes.toggledButton,
            }}
            onClick={() => setShowFilters(!showFilters)}
            selected={showFilters}
          >
            <FilterList style={{ fontSize: '18px' }} />
            <Typography className={classes.typography}>Filters</Typography>
          </ToggleButton>
        </ActivityReportTabHeader>
      </Box>
      <Box
        display='flex'
        alignItems='stretch'
        flex={1}
        style={{ overflowY: 'scroll', paddingLeft: !isMobile ? '60px' : '20px' }}
      >
        <Box style={{ marginTop: '10px', marginRight: !isMobile ? '60px' : '20px' }}>
          <Collapse in={showFilters}>
            <Filter programs={programs} />
          </Collapse>
          {(() => {
            if (isMd && activityId && activityDate) {
              return <ActivityReport />;
            }
            switch (selectedTab) {
              case ActivityReportEnum.COMPLETED:
                return <CompletedReport isOmitted={false} />;

              case ActivityReportEnum.OMITTED:
                return <CompletedReport isOmitted />;

              default:
                return <IncompleteReport />;
            }
          })()}
        </Box>
        {showSessionReport && (
          <CollapsibleSideBar>
            <ActivityReport />
          </CollapsibleSideBar>
        )}
      </Box>
    </Box>
  );
}
