import { unpackToDate, getShortDate } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, SvgIcon, Typography } from '@material-ui/core';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import WarningIcon from '@material-ui/icons/Warning';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { MyElementsUpcomingRosterUnpackedDate } from './my-elements-upcoming-rosters-model.gql';

type MyElementsRosteringAvailabilityDotProps = {
  status: string;
  size?: number;
  isPastDate: boolean;
};

const useIconStyles = makeStyles(() => ({
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  svg: {
    height: '100%',
    width: '100%',
  },
}));

const useVariableRosteringAvailabilityIcon = (status: string) => {
  const { theme } = useCampfireTheme();
  if (status === 'unspecified') {
    return {
      icon: <WarningIcon />,
      bgColor: theme.status.amber.pale,
      iconColor: theme.status.amber.light,
      dotColor: 'white',
      calendarDotColor: 'white',
    };
  }

  if (status === 'unavailable') {
    return {
      icon: <EventBusyIcon />,
      bgColor: theme.status.red.pale,
      iconColor: theme.status.red.light,
      dotColor: theme.status.red.light,
      calendarDotColor: theme.status.red.light,
    };
  }

  if (status === 'available') {
    return {
      icon: <DateRangeIcon />,
      bgColor: theme.status.amber.pale,
      iconColor: theme.status.amber.light,
      dotColor: theme.status.amber.light,
      calendarDotColor: theme.status.amber.light,
    };
  }

  if (status === 'rostered') {
    return {
      icon: <HowToRegIcon />,
      bgColor: theme.status.green.pale,
      iconColor: theme.status.green.light,
      dotColor: theme.status.green.light,
      calendarDotColor: theme.status.green.light,
    };
  }
  if (status === 'cancelled') {
    return {
      icon: <EventBusyIcon />,
      bgColor: theme.color.grey.pale,
      iconColor: theme.color.grey.light,
      dotColor: null,
      calendarDotColor: theme.color.grey.light,
    };
  }

  return {
    icon: null,
    bgColor: 'white',
    iconColor: 'white',
    dotColor: null,
    calendarDotColor: 'white',
  };
};

export const MyElementsRosteringAvailabilityIcon = React.memo((props: MyElementsRosteringAvailabilityDotProps) => {
  const { status, size, isPastDate } = props;

  const { centered, svg } = useIconStyles();

  const iconSize = size ? size * 0.5 : 36;
  const dotSize = size ? size * 0.16 : 12;

  const { icon, bgColor, iconColor, dotColor } = useVariableRosteringAvailabilityIcon(status);

  return (
    <Box
      marginTop={'1px'}
      marginLeft={'2px'}
      height={size ?? 4}
      width={size ?? 4}
      borderRadius={'90%'}
      bgcolor={bgColor}
      style={{ opacity: isPastDate ? 0.6 : 1 }}
      position={'relative'}
    >
      {icon ? (
        <Box className={centered} width={iconSize} height={iconSize}>
          <SvgIcon className={svg} htmlColor={iconColor}>
            {icon}
          </SvgIcon>
        </Box>
      ) : null}
      {dotColor ? (
        <Box
          position='absolute'
          top='0'
          right='0'
          width={dotSize}
          height={dotSize}
          borderRadius={'90%'}
          bgcolor={dotColor}
          border={size ? `${dotSize * 0.33}px solid ${iconColor}` : `2px solid ${iconColor}`}
        />
      ) : null}
    </Box>
  );
});

export const MyElementsRosteringAvailabilityDot = (props: MyElementsRosteringAvailabilityDotProps) => {
  const { status, size, isPastDate } = props;

  const { iconColor, calendarDotColor } = useVariableRosteringAvailabilityIcon(status);

  return (
    <Box
      marginTop={'1px'}
      height={size ?? 4}
      minHeight={size ?? 4}
      width={size ?? 4}
      minWidth={size ?? 4}
      border={size ? `${size * 0.25}px solid ${iconColor}` : `2px solid ${iconColor}`}
      borderRadius={'90%'}
      bgcolor={calendarDotColor}
      style={{ opacity: isPastDate ? 0.6 : 1 }}
    />
  );
};

type DatePublishedMessageProps = {
  status: string;
  upcomingRoster: MyElementsUpcomingRosterUnpackedDate;
};

export function OpenRosterMessage() {
  const { theme } = useCampfireTheme();

  return (
    <TitularTooltip description='You can add and remove yourself freely from open rosters' enterDelay={400}>
      <Box color='white' bgcolor={theme.color.rosters.selected} pr={1} pl={1} maxWidth='max-content'>
        <Typography variant='caption' style={{ marginLeft: 2, marginRight: 2, fontWeight: 'bold' }}>
          {'Open Roster'}
        </Typography>
      </Box>
    </TitularTooltip>
  );
}

export function DatePublishedMessage(props: DatePublishedMessageProps) {
  const { status, upcomingRoster } = props;
  const { theme } = useCampfireTheme();

  if (!upcomingRoster.publishedRoster) {
    return null;
  }

  if (status !== 'rostered' && status !== 'unspecified' && status !== 'available') {
    return null;
  }

  const date = upcomingRoster.publishedRoster.datePublished
    ? getShortDate(unpackToDate(upcomingRoster.publishedRoster.datePublished))
    : null;
  const bgColor = status === 'rostered' ? theme.status.green.light : 'black';

  return (
    <Box color='white' bgcolor={bgColor} pr={1} pl={1} maxWidth='max-content'>
      <Typography variant='caption' style={{ marginLeft: 2, marginRight: 2, fontWeight: 'bold' }}>
        Roster published {date ? `on ${date}` : null}
      </Typography>
    </Box>
  );
}

const legendItems = [
  {
    name: 'Unavailable',
    description: 'You are unavailable for this activity.',
    status: 'unavailable',
  },
  {
    name: 'Available',
    description: 'You are available for the activity and would like to be rostered.',
    status: 'available',
  },
  {
    name: 'Rostered',
    description: 'You are rostered to attend the activity for this date.',
    status: 'rostered',
  },
  {
    name: 'Unsure',
    description: 'You have not indicated your availability. You might still be selected for a roster on this date.',
    status: 'unspecified',
  },
  {
    name: 'Cancelled',
    description: 'The activity running on this date has been cancelled.',
    status: 'cancelled',
  },
];

const MyElementsUpcomingRostersLegend = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function openDialog() {
    setDialogOpen(true);
  }

  function closeDialog() {
    setDialogOpen(false);
  }

  return (
    <Box marginLeft={4} marginTop={1} marginBottom={2} display='flex' flex='1 1 auto' justifyContent='flex-start'>
      <Box>
        <TitularTooltip
          enterDelay={400}
          title='Legend'
          description='See more info about the status dots that appear on the calendar.'
        >
          <TabletButton
            data-track='upcRos-legend-button'
            variant='contained'
            endIcon={<FormatListBulleted />}
            onClick={openDialog}
          >
            Legend
          </TabletButton>
        </TitularTooltip>
      </Box>

      <Dialog onClose={closeDialog} open={dialogOpen} maxWidth='xs'>
        <DialogTitle>Rosters Legend</DialogTitle>
        <DialogContent>
          {legendItems.map((item) => (
            <Box
              key={item.status}
              display='flex'
              flex='1 1 auto'
              marginTop={2}
              marginBottom={2}
              alignContent='center'
              alignItems='center'
            >
              <MyElementsRosteringAvailabilityDot status={item.status} size={16} isPastDate={false} />
              <Box marginLeft={3}>
                <Typography variant='body2' style={{ fontWeight: 500 }}>
                  {item.name}
                </Typography>
                <Typography variant='caption' color='textSecondary'>
                  {item.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <TabletButton variant='text' color='primary' size='large' onClick={closeDialog}>
            Got It
          </TabletButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export { MyElementsUpcomingRostersLegend };
