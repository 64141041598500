import classNames from 'classnames';
import { Grid, List, ListItem, ListItemIcon, ListItemText, ButtonBase } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { FolderOpenOutlined, TocOutlined } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import React, { Fragment } from 'react';
import { IncidentCategoryGroupType } from '../../admin-console-incident-report-model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIcon: {
      minWidth: 32,
    },
    activeListItem: {
      color: theme.palette.primary.main,
    },
    listItemText: {
      fontWeight: 600,
    },
  })
);

const ListItemWithIcon = ({
  selected,
  label,
  type,
  padding,
  handleChange,
}: {
  selected: boolean;
  label: string;
  type: string;
  padding: number;
  handleChange: () => void;
}) => {
  const classes = useStyles();
  const zoneClasses = useZoneStyles();
  return (
    <ListItem
      dense
      button
      onClick={() => {
        handleChange();
      }}
      style={{ paddingLeft: padding, paddingTop: 4, paddingBottom: 4 }}
      disableGutters
      className={zoneClasses.zone}
    >
      {type === 'VOLUNTEER_IncidentCategoryGroupType' ? (
        <ListItemIcon className={classes.listItemIcon}>
          <FolderOpenOutlined fontSize='small' className={classNames({ [classes.activeListItem]: selected })} />
        </ListItemIcon>
      ) : type === 'VOLUNTEER_IncidentCategoryType' ? (
        <ListItemIcon className={classes.listItemIcon}>
          <TocOutlined fontSize='small' className={classNames({ [classes.activeListItem]: selected })} />
        </ListItemIcon>
      ) : null}
      <ListItemText
        primary={label}
        className={classNames(classes.listItemText, { [classes.activeListItem]: selected })}
        primaryTypographyProps={{ display: 'block', noWrap: true }}
      />
    </ListItem>
  );
};

export const CategoriesSideMenuChildGroup = ({
  childGroup,
  padding = 16,
  selectedChildId,
  setSelectedChildId,
}: {
  childGroup: IncidentCategoryGroupType;
  padding?: number;
  selectedChildId?: string;
  setSelectedChildId: (x: string) => void;
}) => {
  const haveChildGroups = childGroup.childGroups && !!childGroup.childGroups.length;
  const haveCategories = childGroup.categories && !!childGroup.categories.length;

  const classes = useZoneStyles();

  return (
    <>
      {haveChildGroups && (
        <List component='div' disablePadding>
          {childGroup.childGroups.map((childGroupItem) => {
            const selected = selectedChildId === childGroupItem.incidentCategoryGroupId;
            return (
              <Fragment key={`${childGroupItem.incidentCategoryGroupId}-menu`}>
                <ListItemWithIcon
                  label={childGroupItem.label}
                  type={childGroupItem.__typename}
                  selected={selected}
                  padding={padding}
                  handleChange={() =>
                    childGroupItem.incidentCategoryGroupId
                      ? setSelectedChildId(childGroupItem.incidentCategoryGroupId)
                      : null
                  }
                />
                <CategoriesSideMenuChildGroup
                  childGroup={childGroupItem}
                  padding={padding + 16}
                  selectedChildId={selectedChildId}
                  setSelectedChildId={setSelectedChildId}
                />
              </Fragment>
            );
          })}
        </List>
      )}
      <Grid item xs={12}>
        {haveCategories &&
          childGroup.categories.map((category) => {
            const selected = selectedChildId === category.incidentCategoryId;
            return (
              <ButtonBase
                className={classNames(classes.zone, classes.buttonBase, {
                  [classes.buttonSelected]: selected,
                  // [classes.zone]: !selected,
                })}
                key={`${category.incidentCategoryId}-menu`}
                onClick={() => (category.incidentCategoryId ? setSelectedChildId(category.incidentCategoryId) : null)}
              >
                <ListItemWithIcon
                  selected={selected}
                  type={category.__typename}
                  label={category.label}
                  padding={padding}
                  handleChange={() =>
                    category.incidentCategoryId ? setSelectedChildId(category.incidentCategoryId) : null
                  }
                />
                {category.details.map((detail) => (
                  <ListItemWithIcon
                    selected={false}
                    key={`${detail.incidentDetailId}-menu`}
                    type={''}
                    label={detail.label}
                    padding={padding + 16}
                    handleChange={() => {}}
                  />
                ))}
              </ButtonBase>
            );
          })}
      </Grid>
    </>
  );
};

const useZoneStyles = makeStyles((theme: Theme) =>
  createStyles({
    zone: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    },
    buttonBase: {
      display: 'inline-block',
      width: '100%',
    },
    buttonSelected: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    },
  })
);
