import { Task_taskItems as TaskItemType } from '../__generated__/Task';

export type TaskItemsMapById = { [key: string]: TaskItemType };
export type TaskItemsByTrigger = {
  [key: string]: {
    taskItemId: string,
    triggerItemIds: string[],
    taskFieldId: string,
    optionType: string;
  }
};

export type TaskItemByTriggerOption = {
  [key: string]: string;
}

export type TaskItemsDeserialize = {
  allIds: string[],
  byId: TaskItemsMapById,
  byTrigger: TaskItemsByTrigger,
  byTriggerOption: TaskItemByTriggerOption
};


export function deserializeTaskItems(taskItems?: TaskItemType[]) : TaskItemsDeserialize {
  if (!taskItems || taskItems.length === 0) {
    return {
      allIds: [],
      byId: {},
      byTrigger: {},
      byTriggerOption: {}
    }
  }

  return {
    allIds: taskItems.map((taskItem) => taskItem.taskItemId),
    byId: taskItems.reduce((acc, taskItem) => ({
      ...acc,
      [taskItem.taskItemId]: taskItem
    }), {} as TaskItemsMapById),
    byTrigger: taskItems.reduce((acc, taskItem) => {
      if (taskItem.__typename !== 'VOLUNTEER_TaskItemFieldType') {
        return acc;
      }

      let newTriggerMap = {} as TaskItemsByTrigger;

      if (taskItem.field.__typename === 'VOLUNTEER_CheckboxTaskFieldType') {
        newTriggerMap = taskItem.field.checkboxOptions.reduce((triggerMap, checkboxOption) => ({
          ...triggerMap,
          [checkboxOption.checkboxFieldOptionId]: {
            triggerItemIds: checkboxOption.trigger?.items.map((item) => item.item?.taskItemId),
            taskItemId: taskItem.taskItemId,
            taskFieldId: taskItem.field.taskFieldId,
            optionType: 'checkbox'
          }
        }), {});
      }

      if (taskItem.field.__typename === 'VOLUNTEER_DropdownTaskFieldType') {
        newTriggerMap = taskItem.field.dropdownOptions.reduce((triggerMap, dropdownOption) => ({
          ...triggerMap,
          [dropdownOption.dropdownFieldOptionId]: {
            triggerItemIds: dropdownOption.trigger?.items.map((item) => item.item?.taskItemId),
            taskItemId: taskItem.taskItemId,
            taskFieldId: taskItem.field.taskFieldId,
            optionType: 'dropdown'
          }
        }), {});
      }

      if (taskItem.field.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldType') {
        newTriggerMap = taskItem.field.multipleChoiceOptions.reduce((triggerMap, multipleChoiceOption) => ({
          ...triggerMap,
          [multipleChoiceOption.multipleChoiceFieldOptionId]: {
            triggerItemIds: multipleChoiceOption.trigger?.items.map((item) => item.item?.taskItemId),
            taskItemId: taskItem.taskItemId,
            taskFieldId: taskItem.field.taskFieldId,
            optionType: 'multipleChoice'
          }
        }), {});
      }

      if (taskItem.field.__typename === 'VOLUNTEER_QuizTaskFieldType') {
        newTriggerMap = taskItem.field.quizTaskFieldOptions.reduce((triggerMap, quizOption) => ({
          ...triggerMap,
          [quizOption.quizTaskFieldOptionId]: {
            triggerItemIds: quizOption.trigger?.items.map((item) => item.item?.taskItemId),
            taskItemId: taskItem.taskItemId,
            taskFieldId: taskItem.field.taskFieldId,
            optionType: 'quiz'
          }
        }), {});
      }

      return { ...acc, ...newTriggerMap };
    }, {} as TaskItemsByTrigger),
    byTriggerOption: taskItems.reduce((acc, taskItem) => {
      const triggerOption : any = taskItem.triggerItem?.trigger?.triggerOption;

      if (!triggerOption) {
        return acc
      }

      const optionId = triggerOption.quizTaskFieldOptionId || triggerOption.dropdownFieldOptionId || triggerOption.checkboxFieldOptionId || triggerOption.multipleChoiceFieldOptionId;
      return {
        ...acc,
        [taskItem.taskItemId]: optionId
      }
    }, {} as TaskItemByTriggerOption)
  }
}