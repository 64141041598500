import React from 'react';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { DashboardToolbar } from '../../general/vol-dashboard/vol-dashboard-my-elements/management-dashboard/DashboardToolbar';
import { ManagementCalendarTab } from './ManagementCalendar';

export const useMyElementsTabStyles = makeStyles(() =>
  createStyles({
    tab: {
      minWidth: '90px',
      paddingBottom: '14px',
    },
  })
);

export const TabName = ({ name, alertNumber }: { name: string; alertNumber?: number }) => {
  const { theme, isSm } = useCampfireTheme();
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <Typography
        variant='h4'
        style={{
          color: theme.color.grey.neutralBrand800,
          fontSize: isSm ? '16px' : '20px',
          fontWeight: isSm ? 400 : 800,
          whiteSpace: 'break-spaces',
          letterSpacing: '-0.02em',
          textTransform: 'none',
        }}
      >
        {name}
      </Typography>
      {alertNumber && alertNumber > 0 ? (
        <Box
          bgcolor='#FF7705'
          width={18}
          height={18}
          borderRadius='50%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          mx='8px'
        >
          <Typography variant='caption' style={{ color: theme.color.white.neutral50, fontSize: 10, fontWeight: 900 }}>
            {alertNumber}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

interface ManagementDashboardTabHeaderProps {
  selectedTab: string | undefined;
  setSelectedTab: (selectedTab: string) => void;
  allowInsights: boolean;
}

export const ManagementDashboardTabHeader = ({
  selectedTab,
  setSelectedTab,
  allowInsights,
}: ManagementDashboardTabHeaderProps) => {
  const classes = useMyElementsTabStyles();
  const { theme } = useCampfireTheme();

  return (
    <>
      <Tabs
        indicatorColor='primary'
        value={selectedTab}
        aria-label='Management dashboard screen'
        variant='standard'
        style={{
          borderBottom: `1px solid ${theme.color.grey.border}`,
          backgroundColor: 'white',
          paddingTop: 24,
          marginTop: -24,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        {allowInsights ? (
          <Tab
            disableRipple
            disableTouchRipple
            key='insights'
            value='insights'
            className={classes.tab}
            label={<TabName name='Insights' />}
            onClick={() => setSelectedTab('insights')}
          />
        ) : null}
        <Tab
          disableRipple
          disableTouchRipple
          key='calendar'
          value='calendar'
          className={classes.tab}
          label={<TabName name='Calendar' />}
          onClick={() => setSelectedTab('calendar')}
        />
        <Box flex={1} />
        {selectedTab === 'insights' && <DashboardToolbar />}
        {selectedTab === 'calendar' && <ManagementCalendarTab />}
      </Tabs>
    </>
  );
};
