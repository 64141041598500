import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { Tabs, WidgetSelect } from '../selects/WidgetSelect';
import { CreatWidgetFormValues, StepsEnum, WidgetTypes } from '../dashboard-widget-model';

interface Props {
  onClose: () => void;
}

export function SelectWidgetDialog({ onClose }: Props) {
  const { values, setFieldValue } = useFormikContext<CreatWidgetFormValues>();
  const [activeTab, setActiveTab] = React.useState(Tabs.TYPE);
  const onNext = () => {
    if (activeTab === Tabs.TYPE && values.byValues.length === 0) {
      setActiveTab(Tabs.VALUE);
      return;
    }

    if (activeTab === Tabs.VALUE && !values.byType) {
      setActiveTab(Tabs.TYPE);
      return;
    }

    if (values.byType === WidgetTypes.CHART) {
      setFieldValue('selectStep', StepsEnum.SELECT_CHART_TYPE);
    }
    if (values.byType === WidgetTypes.NUMBER) {
      setFieldValue('selectStep', StepsEnum.SELECT_NUMBER_TYPE);
    }
    if (values.byType === WidgetTypes.NUMBER_CHART) {
      setFieldValue('selectStep', StepsEnum.SELECT_NUMBER_TYPE);
    }

    if (values.byType === WidgetTypes.TABLE || values.byType === WidgetTypes.REQUIRING_ACTION) {
      setFieldValue('selectStep', StepsEnum.SETTINGS);
    }
  };

  const subTitle = React.useMemo(() => {
    if (activeTab === Tabs.TYPE && values.byValues.length === 0) {
      return 'Select a widget type';
    }

    if (activeTab === Tabs.TYPE && values.byValues.length !== 0) {
      return 'Select one of the relevant widget types';
    }

    if (activeTab === Tabs.VALUE && values.byValues.length === 0) {
      return 'Select one of the below value types';
    }

    if (activeTab === Tabs.VALUE && values.byValues.length !== 0) {
      return 'Select one of the relevant value types for the selected widget';
    }

    return '';
  }, [activeTab, values.byType, values.byValues]);

  return (
    <>
      <DialogTitle>
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs style={{ paddingTop: '10px' }}>
            <Typography variant='h6' style={{ fontWeight: 700, fontSize: '24px' }}>
              {values.isNew ? 'Add' : 'Edit'} Widget
            </Typography>
            <Typography variant='subtitle1' color='textSecondary'>
              {subTitle}
            </Typography>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
                <Close color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent id='dialog-content'>
        <WidgetSelect activeTab={activeTab} onSetTab={setActiveTab} />
      </DialogContent>
      <DialogActions style={{ marginBottom: '2rem', marginRight: '12px' }}>
        <Button
          onClick={onClose}
          style={{
            textTransform: 'none',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onNext}
          color='primary'
          style={{
            textTransform: 'none',
          }}
          variant='contained'
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}
