import { unpackToDate } from '@campfire/hot-date';
import React, { useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { EDIT_ROSTER_TEMPLATE_SCREEN_GET_ROSTER_TEMPLATE } from './roster-template-model.gql';
import { RosterTemplateForm } from './RosterTemplateForm';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import {
  EditRosterTemplateScreenGetRosterTemplate,
  EditRosterTemplateScreenGetRosterTemplateVariables,
} from './__generated__/EditRosterTemplateScreenGetRosterTemplate';

const EditRosterTemplateForm = ({ rosterTemplateId }: { rosterTemplateId: string }) => {
  const { data } = useCampfireQuery<
    EditRosterTemplateScreenGetRosterTemplate,
    EditRosterTemplateScreenGetRosterTemplateVariables
  >(EDIT_ROSTER_TEMPLATE_SCREEN_GET_ROSTER_TEMPLATE, {
    options: {
      variables: {
        rosterTemplateId: rosterTemplateId,
      },
    },
  });

  const rosterTemplate = useMemo(() => {
    return data?.vm.rosterTemplate;
  }, [data]);

  const publishedRoster = useMemo(() => {
    return rosterTemplate?.publishedRoster;
  }, [rosterTemplate]);
  const draftRoster = useMemo(() => {
    return rosterTemplate?.draftRoster;
  }, [rosterTemplate]);
  return rosterTemplate ? (
    <RosterTemplateForm
      publishedRoster={publishedRoster}
      draftRoster={draftRoster}
      predefinedFormValues={{
        rosterTemplateId: rosterTemplate.rosterTemplateId,
        activityId: rosterTemplate.activity.activityId,
        programId: rosterTemplate.activity.program.programId,
        rosterNotes: rosterTemplate.rosterNotes,
        rosterDate: unpackToDate(rosterTemplate.rosterDate).toJSDate(),
        location: {
          description: rosterTemplate?.placesAddress?.description ?? '',
          formatted: rosterTemplate?.placesAddress?.formatted ?? '',
          latitude: rosterTemplate?.placesAddress?.latLong.lat ?? 0,
          longitude: rosterTemplate?.placesAddress?.latLong.long ?? 0,
          placesId: rosterTemplate?.placesAddress?.placesId ?? undefined,
        },
        remoteLocation: rosterTemplate.remoteLocation,
      }}
    />
  ) : null;
};
export const EditRosterTemplateScreen = () => {
  const [rosterTemplateId] = useQueryParam('rosterTemplateId', StringParam);
  return rosterTemplateId ? <EditRosterTemplateForm rosterTemplateId={rosterTemplateId} /> : null;
};
