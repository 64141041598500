import { DescriptionOutlined } from '@material-ui/icons';
import { GetRoutesConfig, RoutesMaps } from '../common/routes-map-model';

const route = 'management';

export const getManagerRoutesMap = ({ userIdentityService }: GetRoutesConfig): RoutesMaps => {
  const { isActivityLeader, isProgramManager, isVmAdmin } = userIdentityService;

  return {
    route,
    heading: 'Management',
    Icon: DescriptionOutlined,
    isAuthorized: isActivityLeader || isProgramManager || isVmAdmin,
    items: [
      {
        label: 'Dashboard',
        route: 'dashboard',
        isAuthorized: isProgramManager || isVmAdmin,
      },
      {
        label: 'Activity Management',
        route: 'activity-management',
        isAuthorized: isActivityLeader || isProgramManager || isVmAdmin,
      },
      {
        label: 'Rosters',
        route: 'rosters',
        isAuthorized: isActivityLeader || isProgramManager || isVmAdmin,
      },
      {
        label: 'Volunteers',
        route: 'volunteers',
        isAuthorized: isProgramManager || isVmAdmin,
      },
      {
        label: 'Bulletins',
        route: 'bulletins',
        isAuthorized: isProgramManager || isVmAdmin,
      },
      {
        label: 'Report Management',
        route: 'report-management',
        isAuthorized: isActivityLeader || isProgramManager || isVmAdmin,
      },
      {
        label: 'Incident Management',
        route: 'incident-management',
        isAuthorized: isProgramManager || isVmAdmin,
      },
    ],
  };
};
