import { TabletButton } from '@campfire/tablet-button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import { useSnackbar } from '../../../../../../global/config/useSnackbar';
import { useApproveIncomingVolunteerFetch } from '../../activity-timeline-actions';
import { ActivityTimelineFutureRosterVolunteer } from '../../__generated__/ActivityTimelineFutureRosterVolunteer';

const AddNewVolunteerConfirmDialog = (
  props: {
    activityApplicationId: string;
    addFullRostering: (args: { volunteerId: string; immediatePublish?: boolean }) => void;
    onClose: () => void;
    preferredName: string;
    runSaveDraftRoster: (immediatePublish: boolean) => void;
    volunteer: ActivityTimelineFutureRosterVolunteer;
  } & Omit<DialogProps, 'onClose'>
) => {
  const { open, volunteer, preferredName, activityApplicationId, addFullRostering, onClose } = props;
  const { volunteerId } = volunteer;
  const { setSnackbar } = useSnackbar();

  const approveIncomingVolunteerFetch = useApproveIncomingVolunteerFetch();

  const runApproveIncomingVolunteerFetch = () => {
    approveIncomingVolunteerFetch
      .run({ activityApplicationId })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to add volunteer',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Volunteer added to roster',
          variant: 'success',
        });

        addFullRostering({ volunteerId, immediatePublish: true });
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Unable to add volunteer',
          variant: 'error',
        });

        if (onClose) onClose();
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle>{`Add ${preferredName} to roster?`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {'Volunteer will be automatically added to the team and rostered for all sessions on this date.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TabletButton variant='text' onClick={() => onClose && onClose()} style={{ marginRight: 8 }}>
          {'Go back'}
        </TabletButton>
        <TabletButton
          variant='text'
          color='primary'
          onClick={() => {
            runApproveIncomingVolunteerFetch();
          }}
        >
          {'Add to roster'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { AddNewVolunteerConfirmDialog };
