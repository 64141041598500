import { useCallback, useMemo } from 'react';
import { useUser } from '../../../global/auth/useUser';
import { ActivitiesExploreActivity } from './__generated__/ActivitiesExploreActivity';
import { ActivitiesExploreActivityApplication } from './__generated__/ActivitiesExploreActivityApplication';

type ActivityEnrolmentStatus = 'applied' | 'activity-leader' | 'waitlist' | 'enrolled' | 'notEnrolled';

const useActivityEnrolmentStatus = (
  activity: ActivitiesExploreActivity,
  activityApplications: ActivitiesExploreActivityApplication[]
): ActivityEnrolmentStatus => {
  const {
    user: { userIdentityService },
    maybeVolunteerIdentity,
  } = useUser();
  const volunteerId = maybeVolunteerIdentity?.volunteerId;

  const status = useMemo(() => {
    if (activityApplications.find((x) => x.activity.activityId === activity.activityId)) {
      return 'applied';
    }
    if (userIdentityService.isLeaderOfActivity(activity.activityId)) {
      return 'activity-leader';
    }
    if (userIdentityService.isOnWaitlist(activity, volunteerId)) {
      return 'waitlist';
    }
    if (userIdentityService.isInActivity(activity.activityId)) {
      return 'enrolled';
    }
    return 'notEnrolled';
  }, [activity, activityApplications, userIdentityService, volunteerId]);

  return status;
};

const useGetActivityEnrolmentStatus = () => {
  const {
    user: { userIdentityService },
    maybeVolunteerIdentity,
  } = useUser();
  const volunteerId = maybeVolunteerIdentity?.volunteerId;

  return useCallback(
    (activity: ActivitiesExploreActivity, activityApplications: ActivitiesExploreActivityApplication[]) => {
      if (activityApplications.find((x) => x.activity.activityId === activity.activityId)) {
        return 'applied';
      }
      if (userIdentityService.isLeaderOfActivity(activity.activityId)) {
        return 'activity-leader';
      }
      if (userIdentityService.isOnWaitlist(activity, volunteerId)) {
        return 'waitlist';
      }
      if (userIdentityService.isInActivity(activity.activityId)) {
        return 'enrolled';
      }
      return 'notEnrolled';
    },
    [userIdentityService, volunteerId]
  );
};

export { useGetActivityEnrolmentStatus, useActivityEnrolmentStatus };
