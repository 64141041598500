import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  ButtonBase,
  Collapse,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  List,
  ListItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { fade, Theme } from '@material-ui/core/styles';
import { Search as SearchIcon, Sort as SortIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFormikContext } from 'formik';
import { EditFieldValues } from './edit-report-field-types';
import { EDIT_REPORT_FIELD_DIALOG_GET_EXISTING_FIELDS } from './edit-report-field-model.gql';
import {
  EditReportFieldDialogGetExistingFields,
  EditReportFieldDialogGetExistingFields_vm_fields as ExistingReportFieldType,
} from './__generated__/EditReportFieldDialogGetExistingFields';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { getFieldTypeLabelAndIcon } from '../../FieldTypeLabelAndIcon';
import { ReportTypeFormValues } from '../report-type-form-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchField: {
      '& .Mui-focused $searchIcon': {
        color: theme.palette.action.active,
      },
    },
    searchIcon: {
      color: theme.palette.action.disabled,
    },
  })
);

const ItemSkeleton = () => (
  <Box display='flex' width={1} alignItems='center' alignContent='center' marginTop={1} marginBottom={1}>
    <Box display='flex' alignContent='center' alignItems='center' style={{ paddingLeft: 32 }}>
      <Skeleton variant='circle' width={25} height={25} style={{ marginRight: 16 }} />
      <Skeleton variant='text' width={100} height={25} />
    </Box>

    <Box flexGrow={1} display='flex' flexDirection='column' style={{ paddingLeft: 64 }}>
      <Skeleton style={{ margin: 0, marginBottom: 8 }} variant='text' width={'25%'} height={10} />
      <Skeleton style={{ margin: 0, marginBottom: 8 }} variant='text' width={'100%'} height={10} />
      <Skeleton style={{ margin: 0, marginBottom: 8 }} variant='text' width={'75%'} height={10} />
    </Box>
  </Box>
);

const ListSkeleton = () => (
  <Box width={1}>
    <Box display='flex' justifyContent='space-between' width={1}>
      <Skeleton variant='text' width={250} height={10} />
      <Skeleton variant='text' width={100} height={32} />
    </Box>

    <Box width={1} marginTop={2}>
      <ItemSkeleton />
      <ItemSkeleton />
    </Box>
  </Box>
);

const selectOptions = [
  { name: 'Checklist Item', type: 'VOLUNTEER_CheckboxFieldType' },
  { name: 'Dropdown', type: 'VOLUNTEER_DropdownFieldType' },
  { name: 'Rating', type: 'VOLUNTEER_RatingFieldType' },
  { name: 'Thumbs Up/Down', type: 'VOLUNTEER_BooleanFieldType' },
  { name: 'Time', type: 'VOLUNTEER_TimeFieldType' },
  { name: 'Text', type: 'VOLUNTEER_TextFieldType' },
  { name: 'Number', type: 'VOLUNTEER_NumericFieldType' },
  { name: 'Attachment', type: 'VOLUNTEER_AttachmentFieldType' },
  { name: 'Any', type: 'ANY_Type' },
];

const sortOptions = [
  { name: 'Sort: Alphabetical', value: 'alphabetical_sort' },
  { name: 'Sort: Date created', value: 'date_sort' },
];

interface FieldListItemProps {
  field: ExistingReportFieldType;
  selectedFieldId?: string;
  onClick: (field: ExistingReportFieldType) => void;
  fieldOptional: boolean;
  setFieldOptional: (x: boolean) => void;
}
const FieldListItem = (props: FieldListItemProps) => {
  const { field, onClick, selectedFieldId, fieldOptional, setFieldOptional } = props;
  const { theme } = useCampfireTheme();
  return (
    <ButtonBase onClick={() => onClick(field)} style={{ width: '100%' }} disableRipple>
      <Grid
        container
        spacing={2}
        style={{
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          background: selectedFieldId === field.fieldId ? fade(theme.palette.primary.light, 0.4) : 'none',
        }}
      >
        {getFieldTypeLabelAndIcon(field.__typename)}
        <Grid item xs={12} sm container direction='column' justify='flex-start' alignItems='flex-start'>
          <Typography variant='subtitle2' align='left' display='inline' style={{ fontWeight: 'bold' }}>
            {field.name}
          </Typography>
          <Typography variant='body2' align='left' display='inline'>
            {field.description}
          </Typography>
        </Grid>

        <Collapse in={field.fieldId === selectedFieldId}>
          <Grid container style={{ paddingBottom: 16 }}>
            <Grid item>
              <Switch color='primary' checked={fieldOptional} onChange={() => setFieldOptional(!fieldOptional)} />
            </Grid>
            <Grid item xs container direction='column'>
              <Typography display='inline' variant='subtitle2' align='left'>
                Make field optional
              </Typography>
              <Typography display='inline' variant='caption' align='left'>
                Values for optional fields do not have to be included when submitting a report
              </Typography>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </ButtonBase>
  );
};

interface Props {
  editingField: boolean;
  handleClose: () => void;
  onSubmit: (values: EditFieldValues) => void;
}

export const ExistingReportFieldSelector = (props: Props) => {
  const { handleClose, onSubmit, editingField } = props;
  const [searchFilter, setSearchFilter] = useState<string>();
  const [fieldTypeFilter, setFieldTypeFilter] = useState<string>('ANY_Type');
  const [sortFilter, setSortFilter] = useState<string>('alphabetical_sort');
  const [selectedFieldId, setSelectedFieldId] = useState<string>();
  const [selectedField, setSelectedField] = useState<EditFieldValues>();
  const [fieldOptional, setFieldOptional] = useState(false);
  const formik = useFormikContext<ReportTypeFormValues>();
  const { items } = formik.values;

  const { isMobile } = useCampfireTheme();
  const { data: response, loading: isLoading } = useCampfireQuery<EditReportFieldDialogGetExistingFields, null>(
    EDIT_REPORT_FIELD_DIALOG_GET_EXISTING_FIELDS
  );

  const existingItemIds = items.flatMap((item) =>
    item.__typename === 'VOLUNTEER_ReportTypeFieldType' ? [item.field.fieldId] : []
  );

  const activeFields =
    useMemo(() => response?.vm.fields.filter((field) => !field.dateDeactivated), [response?.vm.fields]) || [];

  const filteredFields = useMemo(
    () =>
      activeFields
        .filter((field) =>
          fieldTypeFilter && fieldTypeFilter !== 'ANY_Type' ? field.__typename === fieldTypeFilter : true
        )
        .filter(({ fieldId }) => !existingItemIds.includes(fieldId))
        .filter((field) => (searchFilter ? field.name.toLowerCase().includes(searchFilter.toLowerCase()) : true))
        .sort((a, b) => {
          if (a.name && b.name) {
            if (sortFilter === 'alphabetical_sort') return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            if (sortFilter === 'date_sort') return a.dateCreated < b.dateCreated ? -1 : 1;
          }
          return 0;
        }),
    [activeFields, sortFilter, fieldTypeFilter, searchFilter]
  );

  const classes = useStyles();

  return (
    <>
      <DialogContent style={{ minHeight: isMobile ? undefined : 400 }}>
        <Grid container direction='column' spacing={2}>
          <Grid item container spacing={2} alignItems='center'>
            <Grid item xs>
              <TextField
                className={classes.searchField}
                autoFocus
                label=''
                placeholder='Search by field name'
                fullWidth
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' disablePointerEvents>
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                select
                label='Field Type'
                variant='outlined'
                value={fieldTypeFilter}
                onChange={(fieldType: any) => setFieldTypeFilter(fieldType.target.value)}
                SelectProps={{ native: true }}
              >
                {selectOptions.map((option) => (
                  <option key={option.type} value={option.type}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>

          {isLoading ? (
            <ListSkeleton />
          ) : filteredFields ? (
            <Grid item container direction='column'>
              <Grid item container alignItems='center' justify='space-between'>
                <Grid item xs>
                  <Typography variant='h6'>{`${filteredFields.length} Fields available`}</Typography>
                </Grid>
                <Grid item xs container alignItems='center' justify='flex-end'>
                  <Grid item style={{ marginRight: 4 }}>
                    <SortIcon color='primary' />
                  </Grid>
                  <Grid item>
                    <TextField
                      InputProps={{
                        disableUnderline: true,
                      }}
                      select
                      label=''
                      variant='standard'
                      value={sortFilter}
                      onChange={(sortType: any) => setSortFilter(sortType.target.value)}
                      SelectProps={{ native: true }}
                    >
                      {sortOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item style={{ maxHeight: '50vh', overflowY: 'scroll' }}>
                <List>
                  {filteredFields.map((field) => (
                    <ListItem key={field.fieldId}>
                      <FieldListItem
                        onClick={(f) => {
                          setFieldOptional(false);
                          setSelectedFieldId(f.fieldId);
                          setSelectedField({
                            __typename: f.__typename,
                            fieldId: f.fieldId,
                            reportTypeItemId: '',
                            order: 0,
                            name: f.name,
                            description: f.description,
                            optional: false,
                            isHero: f.__typename === 'VOLUNTEER_NumericFieldType' ? f.isHero : undefined,
                            isAllowMultiple:
                              f.__typename === 'VOLUNTEER_DropdownFieldType' ? f.isAllowMultiple : undefined,
                            dropdownOptions:
                              f.__typename === 'VOLUNTEER_DropdownFieldType'
                                ? f.dropdownOptions.map((option) => ({ ...option, listId: uuidv4() }))
                                : undefined,
                          });
                        }}
                        field={field}
                        selectedFieldId={selectedFieldId}
                        fieldOptional={fieldOptional}
                        setFieldOptional={setFieldOptional}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item container justify='space-between' alignItems='center' style={{ padding: 8 }}>
          <Grid item xs container justify='flex-end'>
            <TabletButton
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </TabletButton>

            <TabletButton
              color='primary'
              variant='contained'
              disabled={!selectedField}
              onClick={() => {
                if (selectedField) {
                  selectedField.optional = fieldOptional;
                  onSubmit(selectedField);
                  handleClose();
                }
              }}
            >
              {editingField ? 'Update Field' : 'Add Field'}
            </TabletButton>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
