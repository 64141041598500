import React, { memo } from 'react';
import { ActivityTimelineActivityCancellation } from '../common/ActivityTimelineActivityCancellation';
import { FutureSidebarItem, SidebarItem } from '../sidebar/ActivityTimelineSidebar';
import { ActivityTimelineFutureRoster } from './roster/ActivityTimelineFutureRoster';

interface ActivityTimelineFutureProps {
  futureSidebarItem: FutureSidebarItem;
  patchSelectedSidebarItem: (partialSidebarItem: Partial<SidebarItem>) => void;
}

export const ActivityTimelineFuture = memo((props: ActivityTimelineFutureProps) => {
  const { futureSidebarItem, patchSelectedSidebarItem } = props;
  return futureSidebarItem.cancelledActivity ? (
    <ActivityTimelineActivityCancellation
      activityId={futureSidebarItem.activityId}
      cancelledActivity={futureSidebarItem.cancelledActivity}
      activityDate={futureSidebarItem.activityDate}
      onUncancelActivity={() =>
        patchSelectedSidebarItem({
          cancelledActivity: undefined,
        })
      }
    />
  ) : (
    <ActivityTimelineFutureRoster
      activityId={futureSidebarItem.activityId}
      activityDate={futureSidebarItem.activityDate}
      patchSelectedSidebarItem={patchSelectedSidebarItem}
      rosterTemplateId={futureSidebarItem.rosterTemplateId}
    />
  );
});
