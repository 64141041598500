import { Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import { CampfireThemeContext } from './src';

type CustomStyles = 'xsOnly' | 'notXs';

interface UseCampfireThemeApi {
  styles: Record<CustomStyles, string>;
  theme: Theme;
  isMobile: boolean;
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isLandscape: boolean;
}

const useStyles = makeStyles((theme) => ({
  xsOnly: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inherit',
    },
  },
  notXs: {
    display: 'inherit',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export const useCampfireTheme = (): UseCampfireThemeApi => {
  const theme = useContext(CampfireThemeContext);

  if (!theme) throw new Error('useCampfireTheme must be initiated within an CampfireThemeContextProvider');

  const isXs = !useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const isSm = !useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const isMd = !useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const isLg = !useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
  const isLandscape = window.innerHeight < 600 && isSm;

  const styles = useStyles();

  return {
    styles,
    theme,
    isMobile: isXs,
    isXs,
    isSm,
    isMd,
    isLg,
    isLandscape
  };
};
