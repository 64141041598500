import { useContext } from 'react';
import { ApiContext } from './ApiContext';

const useApiUrl = (): string => {
  const apiContext = useContext(ApiContext);

  if (!apiContext) throw new Error('UseConfig must be initiated within an SessionContextProvider');

  return apiContext.apiUrl;
};

export { useApiUrl };
