import { CancelPresentation } from '@material-ui/icons';
import React from 'react';
import { FullPageMessage } from '../../../common/FullPageMessage';
import { Page } from '../../../global/components';

export const FourOhFourScreen = () => {
  return (
    <Page>
      <FullPageMessage Icon={CancelPresentation} title='404' subtitle='Not Found' message={`👩‍💻`} />
    </Page>
  );
};
