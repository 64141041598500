import { TabletButton } from '@campfire/tablet-button';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  boolean as YupBoolean,
  object as YupObject,
  string as YupString,
  array as YupArray,
  number as YupNumber,
} from 'yup';
import { FormikErrorFocus } from '../../../../../../../common/form/FormikErrorFocus';
import { ReportManagementReportTypeField } from '../../../../report-types/__generated__/ReportManagementReportTypeField';
import { ReportFieldFormContent } from '../../../report-field-form/ReportFieldFormContent';
import { SaveReportFieldValues } from '../../../report-field-types';
import { useReportFieldDetailHeaderActions } from '../report-field-detail-header-actions';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  reportField: ReportManagementReportTypeField;
  refetch: () => void;
};

export const validationSchema = YupObject().shape({
  __typename: YupString().required(),
  fieldId: YupString(),
  name: YupString().required('Please provide a name'),
  description: YupString(),
  isHero: YupBoolean(),
  isLongText: YupBoolean(),
  isAllowMultiple: YupBoolean(),
  dropdownOptions: YupArray()
    .of(
      YupObject().shape({
        dropdownFieldOptionId: YupString(),
        name: YupString().required(),
        order: YupNumber().required(),
        listId: YupString(),
      })
    )
    .when('__typename', {
      is: (val) => val === 'VOLUNTEER_DropdownFieldType',
      then: YupArray()
        .of(
          YupObject().shape({
            dropdownFieldOptionId: YupString(),
            name: YupString().required(),
            order: YupNumber().required(),
            listId: YupString(),
          })
        )
        .required(),
    }),
});
export const EditReportFieldDialog = (props: Props) => {
  const { isOpen, handleClose, reportField, refetch } = props;
  const { runSaveReportField } = useReportFieldDetailHeaderActions();

  const initialValues = useMemo((): SaveReportFieldValues => {
    return {
      __typename: reportField?.__typename || 'VOLUNTEER_NumericFieldType',
      fieldId: reportField?.fieldId || '',
      name: reportField?.name || '',
      description: reportField?.description || '',
      isHero: reportField?.isHero ?? false,
      isLongText: reportField?.__typename === 'VOLUNTEER_TextFieldType' ? reportField?.isLongText : undefined,
      isAllowMultiple:
        reportField?.__typename === 'VOLUNTEER_DropdownFieldType' ? reportField?.isAllowMultiple : undefined,
      dropdownOptions:
        reportField?.__typename === 'VOLUNTEER_DropdownFieldType'
          ? reportField?.dropdownOptions.map((option) => {
              return {
                ...option,
                listId: uuidv4(),
              };
            })
          : undefined,
    };
  }, [reportField]);

  const handleSuccess = () => {
    handleClose();
    if (refetch) refetch();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle style={{ paddingLeft: 0, paddingRight: 0 }}>
        <DialogTitle id='edit-report-field-dialog-title'>Edit Report Field</DialogTitle>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => runSaveReportField({ values, handleSuccess: handleSuccess })}
      >
        {() => (
          <Form>
            <DialogContent>
              <ReportFieldFormContent />
            </DialogContent>
            <DialogActions>
              <Grid item container justify='space-between' alignItems='center' style={{ padding: 8 }}>
                <Grid item xs container justify='flex-end'>
                  <TabletButton
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </TabletButton>

                  <TabletButton color='primary' variant='contained' type='submit'>
                    Save Changes
                  </TabletButton>
                </Grid>
              </Grid>
            </DialogActions>
            <FormikErrorFocus />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
