import React from 'react';
import { Dialog, DialogTitle, Typography, Box, DialogContent, DialogActions } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { object as YupObject, date as YupDate } from 'yup';
import { TabletButton } from '@campfire/tablet-button';
import { DateTime } from 'luxon';
import { encodeTime } from '@campfire/hot-date';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { CICODateSelect } from '../../vol-dashboard/vol-dashboard-my-elements/main-section/my-activities/CICO/CICODateSelect';
import { ActivitySession_sessionReports_CICOs as CICOType } from './__generated__/ActivitySession';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../global/config/useApiUrl';
import { useSnackbar } from '../../../../global/config/useSnackbar';

const validationSchema = YupObject().shape({
  checkIn: YupDate().required('required'),
  checkOut: YupDate().required('required'),
});

export const EditActivityAttendanceDialog = ({
  open,
  name,
  CICO,
  handleClose,
  handleSuccess,
}: {
  open: boolean;
  name: string;
  CICO: CICOType;
  handleClose: () => void;
  handleSuccess: any;
}) => {
  const { theme } = useCampfireTheme();
  const campfireFetch = useCampfireFetch({ defer: true });
  const apiUrl = useApiUrl();
  const { setSnackbar } = useSnackbar();

  const updateCICO = ({ checkIn, checkOut }: { checkIn: Date; checkOut: Date }) => {
    return campfireFetch
      .run({
        url: `${apiUrl}/vm/session/cico/update`,
        method: 'post',
        data: {
          CICOId: CICO.CICOId,
          checkIn: encodeTime(DateTime.fromJSDate(checkIn)),
          checkOut: encodeTime(DateTime.fromJSDate(checkOut)),
        },
      })
      .then(() => {
        if (handleSuccess) handleSuccess();
        setSnackbar({
          variant: 'success',
          message: 'Update attendance successfully',
          open: true,
        });
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          message: 'Cannot be update attendance',
          open: true,
        });
      });
  };

  return (
    <Formik
      initialValues={{
        checkIn: DateTime.fromFormat(CICO.checkIn, 'hh:mm:ss').toJSDate(),
        checkOut: DateTime.fromFormat(CICO.checkOut, 'hh:mm:ss').toJSDate(),
      }}
      validationSchema={validationSchema}
      onSubmit={updateCICO}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Form>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
              <DialogTitle>
                <Typography
                  style={{ fontSize: '24px', fontWeight: 500, color: theme.color.grey.neutral500 }}
                >{`Attendance: ${name}`}</Typography>

                <Typography
                  style={{ fontSize: 14 }}
                  color='textSecondary'
                >{`Edit and submit your check in and out details.`}</Typography>
              </DialogTitle>
              <DialogContent dividers style={{ paddingLeft: 0, paddingRight: 0 }} id='dialog-content'>
                <Box px={3}>
                  <Box display={'flex'} alignItems={'center'} mb={2}>
                    <Typography
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: theme.color.grey.neutral500,
                        marginRight: '24px',
                        minWidth: 100,
                      }}
                    >{`Start time`}</Typography>
                    <CICODateSelect
                      onChange={(value) => setFieldValue('checkIn', value)}
                      field={'checkIn'}
                      time={values.checkIn}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} mb={2}>
                    <Typography
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: theme.color.grey.neutral500,
                        marginRight: '24px',
                        minWidth: 100,
                      }}
                    >{`End time`}</Typography>
                    <CICODateSelect
                      onChange={(value) => setFieldValue('checkOut', value)}
                      field={'checkOut'}
                      time={values.checkOut}
                    />
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Box padding={2}>
                  <TabletButton variant='text' onClick={handleClose} style={{ marginRight: 8 }}>
                    {'Close'}
                  </TabletButton>
                  <TabletButton
                    color='primary'
                    variant='contained'
                    onClick={submitForm}
                    type='submit'
                    style={{ marginRight: 8 }}
                  >
                    {'Update'}
                  </TabletButton>
                </Box>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};
