import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { HoverLink } from '@campfire/hover-link';
import { Check, Close } from '@material-ui/icons';
import qs from 'qs';
import { useCampfireLazyQuery } from '../../global/network/useCampfireLazyQuery';
import { useCampfireFetch } from '../../global/network/useCampfireFetch';
import { GET_EXPORT_BY_ID } from './export-model.gql';
import { useApiUrl } from '../../global/config/useApiUrl';
import { GetExportById, GetExportByIdVariables } from './__generated__/GetExportById';

export const useRequestExport = () => {
  const uri = useApiUrl();
  const campfireMutation = useCampfireFetch<{ exportId: string }>({ defer: true });
  return (exportType: string, args?: object) => {
    const queryString = args ? qs.stringify(args, { arrayFormat: 'brackets', skipNulls: true }) : '';
    return campfireMutation.run({
      url: `${uri}\\vm\\exports\\${exportType}?${queryString}`,
      method: 'GET',
    });
  };
};

export const useQueryExport = (exportId: string) => {
  const [queryExport, { data: vmExport }] = useCampfireLazyQuery<GetExportById, GetExportByIdVariables>(
    GET_EXPORT_BY_ID
  );
  const requestInterval = React.useRef<number | null>(null);
  React.useEffect(() => {
    requestInterval.current = window.setInterval(() => queryExport({ variables: { exportId } }), 1000);
  }, []);
  const currentExportStatus = vmExport?.vm.export.exportStatus;
  React.useEffect(() => {
    if (currentExportStatus && currentExportStatus !== 'pending' && requestInterval.current) {
      window.clearInterval(requestInterval.current);
    }
  }, [currentExportStatus]);
  return vmExport?.vm.export;
};

interface ExportProps {
  exportId: string;
}

export function Export({ exportId }: ExportProps) {
  const currentExport = useQueryExport(exportId);
  const exportStatus = currentExport?.exportStatus || 'pending';
  if (currentExport?.isExpired) {
    return (
      <Box display='flex'>
        <Typography>Error generating export</Typography>
        <Typography>Please try again</Typography>
      </Box>
    );
  }

  if (exportStatus === 'pending') {
    return (
      <Box display='flex' alignItems='center'>
        <CircularProgress color='primary' />
        <Typography>Exporting: {currentExport?.name || ''}</Typography>
      </Box>
    );
  }

  if (exportStatus === 'cancelled') {
    return (
      <Box display='flex' alignItems='center'>
        <Close color='secondary' />
        <Typography>Cancelled: {currentExport?.name || ''}</Typography>
      </Box>
    );
  }

  if (exportStatus === 'succeed' && currentExport?.attachment?.url) {
    return (
      <Box display='flex' alignItems='center'>
        <Check color='primary' />
        <Typography>{currentExport?.name || ''}</Typography>
        <HoverLink
          external
          color='primary'
          to={currentExport?.attachment?.url}
          target='_blank'
          rel='noopener noreferrer'
          download={currentExport.name}
        >
          Download
        </HoverLink>
      </Box>
    );
  }

  return <Box>Export</Box>;
}
