import { Button, Menu, SvgIconProps, Tooltip } from '@material-ui/core';
import { CalendarToday, FilterList } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

export const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      marginTop: 4,
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        background: '#282729',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  })
);

type ToolBarItemVariantType = 'filter' | 'calendar';

interface ToolBarItemIconType {
  icon: React.ComponentType<SvgIconProps>;
  tooltipTitle: string;
}

interface ToolBarItemType {
  variant: ToolBarItemVariantType;
  withMarginRight?: boolean;
  withMarginLeft?: boolean;
  onClick: (() => void) | ((e: any) => void);
}

interface OverflowMenuType {
  variant: ToolBarItemVariantType;
  withMarginRight?: boolean;
  withMarginLeft?: boolean;
  children: any;
}

function getToolBarItemIcon(variant: ToolBarItemVariantType): ToolBarItemIconType {
  if (variant === 'calendar') {
    return {
      icon: CalendarToday,
      tooltipTitle: 'Calendar',
    };
  }
  // Default is filter
  return {
    icon: FilterList,
    tooltipTitle: 'Filter',
  };
}
export const OverflowMenu = ({ variant, withMarginRight, withMarginLeft, children }: OverflowMenuType) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState();
  const classes = useStyles();

  const handleButtonClick = (e: any) => {
    setMenuAnchorElement(e.currentTarget);
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <ToolBarItem
        variant={variant}
        onClick={(e: any) => handleButtonClick(e)}
        withMarginRight={withMarginRight}
        withMarginLeft={withMarginLeft}
      />
      <Menu
        open={openMenu}
        anchorEl={menuAnchorElement}
        keepMounted
        onClose={() => setOpenMenu(false)}
        getContentAnchorEl={null}
        PaperProps={{
          elevation: 0,
          style: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))',
            marginTop: 1.5,
            background: '#282729',
            color: '#C9C8CC',
          },
        }}
        PopoverClasses={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export const ToolBarItem = ({ variant, withMarginRight, withMarginLeft, onClick }: ToolBarItemType) => {
  const { theme } = useCampfireTheme();
  const { icon: ToolBarItemIcon, tooltipTitle } = getToolBarItemIcon(variant);

  return (
    <Tooltip title={tooltipTitle}>
      <Button
        style={{
          background: theme.color.white.neutral50,
          border: '1px solid #dcdcdc',
          borderRadius: '4px',
          color: theme.color.grey.neutral400,
          padding: '7px 12px',
          minWidth: '45px',
          marginRight: withMarginRight ? '10px' : '0px',
          marginLeft: withMarginLeft ? '10px' : '0px',
        }}
        onClick={onClick}
      >
        <ToolBarItemIcon style={{ fontSize: '18px' }} />
      </Button>
    </Tooltip>
  );
};
