import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
  },
});

export const SignatureFieldPreview = React.memo(() => {
  const classes = useStyles();
  return (
    <TextField
      disabled
      placeholder='Sign here'
      fullWidth
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.root,
        },
      }}
    />
  );
});
