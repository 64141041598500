import React from 'react';

import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      fontWeight: 600,
      fontSize: 16,
      color: 'rgb(62, 50, 41)',
      '& .asterisk': {
        color: '#D11111',
        paddingLeft: '3px',
      },
    },
  })
);

export function TaskItemFieldHeading({ heading, optional }: { heading: string; optional: boolean }) {
  const classes = useStyles();
  return (
    <Typography className={classes.heading}>
      {heading}
      <span className='asterisk'>{!optional ? '*' : ''}</span>
    </Typography>
  );
}
