import { DraggableList, DraggableListItem, DraggableListItemHandle } from '@campfire/draggable-list';
import { HoverText } from '@campfire/hover-link';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { useFormikContext, FieldArrayRenderProps } from 'formik';
import { Typography, Box, IconButton } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { ConfirmationDialog } from '../../../../../../common/dialogs/ConfirmationDialog';
import {
  IncidentSeverityFormValuesType,
  IncidentSeverityType,
  SeveritiesFormSectionContent,
} from '../admin-console-incident-report-model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
      paddingTop: 8,
      paddingBottom: 8,
      display: 'flex',
      '&:hover': {
        background: theme.palette.grey[100],
      },
      '&:hover $deleteIcon': {
        color: theme.palette.action.disabled,
        '&:hover': {
          color: theme.palette.error.main,
        },
      },
      '&:hover $fieldItemHandle': {
        color: theme.palette.action.active,
      },
    },
    deleteIcon: {
      marginLeft: 8,
      color: 'transparent',
    },
    fieldItemHandle: {
      paddingLeft: 8,
      paddingRight: 16,
      color: 'transparent',
    },
  })
);

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldItemHandle: {
      paddingLeft: 8,
      paddingRight: 16,
      color: 'transparent',
    },
    headerRow: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
  })
);

interface ModifiedSeverityType extends IncidentSeverityType {
  index: number;
}

const ListFieldHeader = () => {
  const classes = useHeaderStyles();

  return (
    <Box
      className={classes.headerRow}
      paddingY={1}
      display='flex'
      flex='1 1 auto'
      position='relative'
      alignItems='center'
      alignContent='center'
    >
      <Box>
        <DraggableListItemHandle className={classes.fieldItemHandle} />
      </Box>

      <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
        <Box flex='8'>
          <Typography variant='overline' color='textSecondary'>
            {'Name and description'}
          </Typography>
        </Box>
        <Box display='inline-flex' flex='3'>
          <Typography variant='overline' color='textSecondary'>
            {'Colour'}
          </Typography>
        </Box>
        <Box display='inline-flex' flex='2'>
          <Typography variant='overline' color='textSecondary'>
            {'Edit/Remove'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const SeverityListItem = (props: {
  severity: IncidentSeverityType;
  index: number;
  length: number;
  arrayHelpers: FieldArrayRenderProps;
  onEditIconClick: (x: IncidentSeverityType) => void;
}) => {
  const { severity, index, length, arrayHelpers, onEditIconClick } = props;
  const [severityToRemove, setSeverityToRemove] = useState<ModifiedSeverityType>();
  const classes = useStyles();
  const formik = useFormikContext<IncidentSeverityFormValuesType>();
  const formContent = SeveritiesFormSectionContent;

  return (
    <>
      <DraggableListItem
        id={`${index}`}
        index={index}
        className={classes.listItem}
        style={index === length - 1 ? { borderBottom: 'none' } : undefined}
      >
        <Box paddingY={1} display='flex' flex='1 1 auto' position='relative' alignItems='center' alignContent='center'>
          <Box>
            <DraggableListItemHandle className={classes.fieldItemHandle} />
          </Box>

          <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
            <Box flex='8'>
              <Typography variant='body2'>{severity.name}</Typography>
              <Typography variant='body2' color='textSecondary'>
                {severity.description}
              </Typography>
            </Box>

            <Box display='inline-flex' alignItems='center' alignContent='center' flex='3'>
              <Box borderRadius={5} width={64} height={8} bgcolor={severity.color} />
            </Box>

            <Box display='inline-flex' flex='2' justifyContent='flex-end' alignItems='center' alignContent='center'>
              <Box>
                <TitularTooltip enterDelay={500} description='Edit Incident Severity'>
                  <span>
                    <HoverText
                      color='textSecondary'
                      hoverColor='primary'
                      variant='body2'
                      onClick={() => onEditIconClick({ ...severity, order: index })}
                      style={{ paddingLeft: 12, paddingRight: 12 }}
                    >
                      {'edit'}
                    </HoverText>
                  </span>
                </TitularTooltip>
              </Box>

              <TitularTooltip description='Delete Incident Severity' style={{ marginRight: '16px' }}>
                <IconButton onClick={() => setSeverityToRemove({ ...severity, index })} className={classes.deleteIcon}>
                  <Close fontSize='small' />
                </IconButton>
              </TitularTooltip>
            </Box>
          </Box>
        </Box>
      </DraggableListItem>

      {severityToRemove ? (
        <ConfirmationDialog
          title={formContent.removeSeverityDialogTitle}
          body={
            <>
              {formContent.removeSeverityDialogContent.map((content) => {
                const updatedContent = content.includes('$severityName')
                  ? content.replace('$severityName', severityToRemove.name)
                  : content;
                return (
                  <Typography key={content} variant='body2' gutterBottom>
                    {updatedContent}
                  </Typography>
                );
              })}
            </>
          }
          open
          closeActionText={'Go Back'}
          confirmActionText={'Delete Severity'}
          handleCloseClick={() => setSeverityToRemove(undefined)}
          handleConfirmClick={() => {
            arrayHelpers.remove(severityToRemove.index);
            setSeverityToRemove(undefined);
            formik.submitForm();
          }}
        />
      ) : null}
    </>
  );
};

export const SeverityListFieldSection = ({
  arrayHelpers,
  onEditIconClick,
}: {
  arrayHelpers: FieldArrayRenderProps;
  onEditIconClick: (x: IncidentSeverityType) => void;
}) => {
  const formik = useFormikContext<IncidentSeverityFormValuesType>();
  const { severities } = formik.values;
  return (
    <>
      <ListFieldHeader />
      <DraggableList
        onReorder={(startIdx, endIdx, reorder) => {
          if (formik) {
            formik.setFieldValue('severities', reorder(severities, startIdx, endIdx));
            formik.submitForm();
          }
        }}
        style={{ paddingTop: 0 }}
      >
        {severities.map((severity, index) => (
          <SeverityListItem
            key={severity.listId}
            severity={severity}
            index={index}
            length={severities.length}
            arrayHelpers={arrayHelpers}
            onEditIconClick={onEditIconClick}
          />
        ))}
      </DraggableList>
    </>
  );
};
