import { gql } from '@apollo/client';
import {
    COMPLETED_TASK_FRAGMENT,
    TASK_FORM_ITEM_FRAGMENT,
} from '../../../../../common/form/task-form/model/task-items.gql';

export const GET_VOLUNTEER_ADD_TASKS_SECTION_TASK = gql`
  query GetVolunteerAddTasksSectionTask(
    $taskId: String!
    $userId: String!
    $profileId: String!
  ) {
    vm {
      profile(profileId: $profileId) {
        userId
        preferredName
        firstName
        lastName
        contactNumber
        dateOfBirth
        programs {
          name
        }
        completedTasks {
          completedTaskId
          task {
            taskId
          }
        }
      }
      application(userId: $userId) {
        relevantTasks {
          cake {
            cakeType
          }
        }
      }
      task(taskId: $taskId) {
        taskId
        title
        description
        cake {
          title
        }
        taskItems {
          ...TaskFormItem
        }
        order
      }
    }
  }

  ${COMPLETED_TASK_FRAGMENT}
  ${TASK_FORM_ITEM_FRAGMENT}
`;
