import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { HoverText } from '@campfire/hover-link';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ChevronLeft } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { PublicScreenWrapper } from '../../screens/public-screens/PublicScreenWrapper';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { useRedirectContext } from '../app-shell/RedirectContext';
import { useSession } from '../auth/useSession';
import { useSnackbar } from '../config/useSnackbar';
import { usePageVisibility } from '../page-visibility/usePageVisibility';
import { useResendVerifyEmailFetch } from './public-shell-actions';

const VerifyEmailShell = () => {
  const { isMobile } = useCampfireTheme();
  const { user, logout, isLoading: isAuthLoading, reloadSession } = useSession();
  const { clearRedirectUrl } = useRedirectContext();
  const maybeUserFirstName = user ? user.firstName : undefined;
  const { visibility } = usePageVisibility();
  const [hasClickedBack, setHasClickedBack] = useState(false);

  const resendVerifyEmailFetch = useResendVerifyEmailFetch();
  const { setSnackbar } = useSnackbar();

  function signOutToSignInScreen() {
    clearRedirectUrl();
    logout();
  }

  const runResendVerifyEmail = () => {
    resendVerifyEmailFetch
      .run({ email: user?.email })
      .then((response) => {
        if (!response.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to resend email',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Email sent',
          variant: 'success',
        });
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Unable to resend email',
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    if (visibility) {
      reloadSession();
    }
  }, [visibility]);

  const isLoading = isAuthLoading || resendVerifyEmailFetch.isLoading;

  return (
    <PublicScreenWrapper floating>
      <LinearProgressOverlay isLoading={isLoading} opacity={0} />
      <Box paddingTop={8} paddingBottom={isMobile ? 0 : 8} position='relative'>
        {!hasClickedBack ? (
          <>
            <TitularTooltip description='Back to sign in'>
              <Box position='absolute' top={12} left={12}>
                <IconButton
                  disabled={isLoading}
                  onClick={() => {
                    reloadSession();
                    setHasClickedBack(true);
                    // eslint-disable-next-line no-undef
                    FS.event('click verify email back');
                  }}
                  data-track='verify-email-shell-back-to-sign-in'
                >
                  <ChevronLeft color='action' />
                </IconButton>
              </Box>
            </TitularTooltip>
            <Grid justify='center' container style={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={10} style={{ textAlign: 'center', paddingLeft: 8, paddingRight: 8 }}>
                <Typography variant='h4'>{`Please verify your email`}</Typography>
                <Typography variant='h6' gutterBottom>
                  {maybeUserFirstName ? `Last step, ${maybeUserFirstName}!` : 'Nearly there'}
                </Typography>
                <Typography component='span' variant='body2' color='textSecondary' style={{ marginTop: 16 }}>
                  {`Check your inbox for an email from `}
                  <b>
                    <i>{`postman@volaby.org`}</i>
                  </b>
                  {`. If you can't find anything in your inbox or junk, you can `}
                  <HoverText
                    data-track='verify-email-resend'
                    display='inline'
                    variant='body2'
                    color='primary'
                    hoverColor='primary'
                    onClick={() => runResendVerifyEmail()}
                  >
                    {'click here to resend.'}
                  </HoverText>
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          // This is designed to catch people who might be confused AFTER verifying their email address
          // if they return to this screen. We will let the auth context retry the token and user,
          // and if it's successful then they will not even make it back to this screen. If the user
          // truely hasn't verified their email then they can hit 'Sign out' after that.
          <Grid justify='center' container style={{ flexGrow: 1 }}>
            <CircularProgressOverlay isLoading={!!user?.emailVerified} />

            <Grid item xs={12} sm={10} style={{ textAlign: 'center', paddingLeft: 8, paddingRight: 8 }}>
              <Typography variant='h4' gutterBottom>
                Sign out?
              </Typography>
              <Typography component='span' variant='body2' color='textSecondary' style={{ marginTop: 16 }}>
                {`This will take you back to the sign in screen. Are you sure you didn't miss the 'Verify Your Volaby Email Address' email?`}
              </Typography>

              <Box marginTop={3}>
                <HoverText
                  color='primary'
                  variant='body2'
                  onClick={() => {
                    signOutToSignInScreen();
                    // eslint-disable-next-line no-undef
                    FS.event('click verify email sign out');
                  }}
                >
                  Sign out
                </HoverText>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </PublicScreenWrapper>
  );
};

export { VerifyEmailShell };
