import { Box, Typography } from '@material-ui/core';
import React, { memo, ReactNode } from 'react';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

type IconsTextRowLayoutProps = {
  leftIcon: ReactNode;
  text?: string | undefined;
  rightIcon?: ReactNode;
};

export const IconsTextRowLayout = memo(({ leftIcon, text, rightIcon }: IconsTextRowLayoutProps) => {
  const { theme } = useCampfireTheme();
  return (
    <Box display='flex' alignItems='center' alignContent='center'>
      <Box display='flex' marginTop={1} alignItems='center' alignContent='center'>
        <Box paddingRight={1} borderRight={`1px solid ${theme.palette.grey[300]}`}>
          {leftIcon}
        </Box>
        <Box paddingLeft={1}>{text && <Typography variant='body2'>{text}</Typography>}</Box>
      </Box>
      {rightIcon && <Box paddingLeft={1}>{rightIcon}</Box>}
    </Box>
  );
});
