import React from 'react';
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import { MyLocation } from '@material-ui/icons';

interface MapProps {
  lat: number;
  long: number;
}

// Marker requires the lat lng props to fix the marker component to that position
const Marker = ({ lat, lng }: { lat: number; lng: number }) => (
  <MyLocation color='secondary' titleAccess={`Marker at latitude: ${lat} longitude: ${lng}`} />
);

const MAP_EDGE_OFFSET = 0.15;

const SimpleMap = (props: { locations: Array<MapProps> }) => {
  const { locations } = props;

  const containerRef = React.createRef<HTMLDivElement>();
  const [size, setSize] = React.useState<{ width: number; height: number }>({ width: 0, height: 0 });

  React.useEffect(() => {
    setSize(containerRef.current?.getBoundingClientRect() || { height: 0, width: 0 });
  }, []);

  const { center, zoom } = React.useMemo(() => {
    const lats = locations.map((location) => location.lat);
    const lngs = locations.map((location) => location.long);

    const maxLat = Math.max(...lats);
    const minLat = Math.min(...lats);
    const maxLng = Math.max(...lngs);
    const minLng = Math.min(...lngs);
    return fitBounds(
      {
        nw: { lat: maxLat - MAP_EDGE_OFFSET, lng: minLng - MAP_EDGE_OFFSET },
        se: { lat: minLat + MAP_EDGE_OFFSET, lng: maxLng + MAP_EDGE_OFFSET },
      },
      size
    );
  }, [locations, size]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAtLoNynuMVQAXXvaaxXG5PFB2IXja6f2E' }}
        center={center}
        zoom={zoom}
        // defaultCenter={{ lat, lng: long }}
        // todo also don't forget to lock down the Maps API for prod
        // todo initial map position would be good to include the program + activity location, with whatever zoom is needed for that (i.e. nearzoom if activity is innner city, far zoom if it's in the suburbs)
        // defaultZoom={5}
      >
        {locations.map((location) => (
          <Marker lat={location.lat} lng={location.long} key={`${location.lat}${location.long}`} />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export { SimpleMap };
