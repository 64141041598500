import { gql } from '@apollo/client';
import { CONFIG_ITEM_FRAGMENT } from './config-model.gql';

export const GET_USER_IDENTITY = gql`
  query GetUserIdentity($userId: String!) {
    im {
      user(userId: $userId) {
        userId
        emailVerified
        emailAddress
        dateCreated
      }
      identity {
        ...IdentityManagementIdentity
      }
      configItems(keys: []) {
        ...ConfigItem
      }
    }
    vm {
      profile(userId: $userId) {
        profileId
        firstName
        lastName
        preferredName
        avatarUrl
        statusEmoji
        statusText
        userPreference {
          userPreferenceId
          rosterView
        }
        volunteer {
          activityEnrolments {
            activityEnrolmentId
            position
          }
        }
      }
      identity {
        ...VolunteerManagementIdentity
      }
      configItems(keys: []) {
        ...ConfigItem
      }
      # todo: set-up-your-profile ???
      application(userId: $userId) {
        applicationId
        # hasContractPrereqs
      }
    }
  }

  fragment IdentityManagementIdentity on IDENTITY_IdentityManagementIdentityType {
    userId
    globalRoles {
      ...PrivilegeRole
    }
  }

  fragment VolunteerManagementIdentity on VOLUNTEER_VolunteerManagementIdentityType {
    userId
    managerIdentity {
      ...ManagerIdentity
    }
    volunteerIdentity {
      ...VolunteerIdentity
    }
    applicantIdentity {
      ...ApplicantIdentity
    }
    globalRoles {
      ...PrivilegeRole
    }
  }

  fragment ManagerIdentity on VOLUNTEER_ManagerIdentityType {
    managerId
    programManagerIdentities {
      programId
      role {
        ...PrivilegeRole
      }
    }
  }

  fragment VolunteerIdentity on VOLUNTEER_VolunteerIdentityType {
    volunteerId
    programIdentities {
      ...ProgramIdentity
    }
    dateCreated
  }

  fragment ProgramIdentity on VOLUNTEER_ProgramIdentityType {
    programId
    activityIds
    ledActivityIds
  }

  fragment ApplicantIdentity on VOLUNTEER_ApplicantIdentityType {
    applicationId
  }

  fragment PrivilegeRole on PrivilegeRoleType {
    privilegeRoleId
    name
    ruleKeys
  }

  ${CONFIG_ITEM_FRAGMENT}
`;
