import { FileUploadGallery, FugFile, UploadedFile } from '@campfire/file-upload-gallery';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { PreferenceFormValues } from '../AdminConsoleCommunicationPreferences';
import { useCommonStyles } from '../commonStyles';

type Props = {
  isLoading: boolean;
  uploadedFiles: Array<UploadedFile>;
};

export const LogoFormSection = (props: Props) => {
  const { uploadedFiles, isLoading } = props;
  const { theme } = useCampfireTheme();
  const { setFieldValue, errors, values } = useFormikContext<PreferenceFormValues>();
  const classes = useCommonStyles();
  const fieldError = getIn(errors, 'logoAttachments');

  return (
    <>
      <Typography className={classes.sectionHeading}>Logo</Typography>
      <Typography className={classes.sectionBody}>
        Your logo will appear on all email communications with volunteers.
      </Typography>
      {fieldError && (
        <Typography variant='caption' style={{ color: theme.color.error[900], marginTop: '8px', marginBottom: '8px' }}>
          {fieldError}
        </Typography>
      )}
      {isLoading ? (
        <Skeleton variant='rect' width='85%' height='62px' style={{ borderRadius: '8px' }} />
      ) : (
        <FileUploadGallery
          id='logoAttachments'
          allowMultiple={false}
          uploadedFiles={uploadedFiles ?? []}
          handleFilesAdded={(files: FugFile[]) => {
            setFieldValue('logoAttachments', files);
          }}
          handleFileRemoved={(removedFile: FugFile) => {
            setFieldValue(
              'logoAttachments',
              values.logoAttachments.filter((logo: any) => logo.fileId !== removedFile.id && logo.id !== removedFile.id)
            );
          }}
        />
      )}
    </>
  );
};
