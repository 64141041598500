import { cloneDeep } from 'lodash';
import { Typography } from '@material-ui/core';
import React from 'react';
import { ConfirmationDialog } from '../../../../../../../common/dialogs/ConfirmationDialog';
import {
  CategoriesSectionContent,
  AllIncidentCategoryType,
  findParentGroup,
  IncidentCategoryGroupType,
  findParentOfCategory,
  findParentCategoryOfDetail,
} from '../../admin-console-incident-report-model';
import { useAdminConsoleActions } from '../../../../admin-console-actions';

export const RemoveCategoryConfirmationDialog = ({
  childToRemove,
  handleSuccess,
  onClose,
  incidentCategoriesRoot,
}: {
  childToRemove: AllIncidentCategoryType;
  handleSuccess: () => void;
  onClose: () => void;
  incidentCategoriesRoot: IncidentCategoryGroupType;
}) => {
  const { removeCategoryDialogTitle, removeCategoryDialogContent } = CategoriesSectionContent;
  const { runSaveIncidentCategories } = useAdminConsoleActions();

  return (
    <ConfirmationDialog
      title={removeCategoryDialogTitle}
      body={
        <>
          {removeCategoryDialogContent.map((content) => {
            const updatedContent = content.includes('$categoryName')
              ? content.replace('$categoryName', childToRemove.label)
              : content;
            return (
              <Typography key={content} variant='body2' gutterBottom>
                {updatedContent}
              </Typography>
            );
          })}
        </>
      }
      open
      closeActionText={'Cancel'}
      confirmActionText={'Remove'}
      handleCloseClick={() => onClose()}
      handleConfirmClick={() => {
        if (
          childToRemove.__typename === 'VOLUNTEER_IncidentCategoryGroupType' &&
          childToRemove.incidentCategoryGroupId
        ) {
          const clonedRoot = cloneDeep(incidentCategoriesRoot);
          const foundParentGroup = findParentGroup(clonedRoot, childToRemove.incidentCategoryGroupId);
          if (foundParentGroup)
            foundParentGroup.childGroups.splice(
              foundParentGroup.childGroups.findIndex(
                (childGroup) => childGroup.incidentCategoryGroupId === childToRemove.incidentCategoryGroupId
              ),
              1
            );
          runSaveIncidentCategories({ root: clonedRoot, handleSuccess });
        }
        if (childToRemove.__typename === 'VOLUNTEER_IncidentCategoryType' && childToRemove.incidentCategoryId) {
          const clonedRoot = cloneDeep(incidentCategoriesRoot);
          const foundParentOfCategory = findParentOfCategory(clonedRoot, childToRemove.incidentCategoryId);
          if (foundParentOfCategory)
            foundParentOfCategory.categories.splice(
              foundParentOfCategory.categories.findIndex(
                (category) => category.incidentCategoryId === childToRemove.incidentCategoryId
              ),
              1
            );
          runSaveIncidentCategories({ root: clonedRoot, handleSuccess });
        }
        if (childToRemove.__typename === 'VOLUNTEER_IncidentDetailType' && childToRemove.incidentDetailId) {
          const clonedRoot = cloneDeep(incidentCategoriesRoot);
          const foundParentCategoryOfDetail = findParentCategoryOfDetail(clonedRoot, childToRemove.incidentDetailId);
          if (foundParentCategoryOfDetail)
            foundParentCategoryOfDetail.details.splice(
              foundParentCategoryOfDetail.details.findIndex(
                (detail) => detail.incidentDetailId === childToRemove.incidentDetailId
              ),
              1
            );
          runSaveIncidentCategories({ root: clonedRoot, handleSuccess });
        }
      }}
    />
  );
};
