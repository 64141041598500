import { useWithdrawUnavailabilityFetch } from '../../activity-timeline-actions';
import { useSnackbar } from '../../../../../../global/config/useSnackbar';

export const useRunWithdrawUnavailability = (runRefetch: () => void) => {
  const withdrawUnavailability = useWithdrawUnavailabilityFetch();
  const { setSnackbar } = useSnackbar();

  return (activityUnavailabilityId: string, onFinally?: () => void) => {
    if (!activityUnavailabilityId) return;
    withdrawUnavailability
      .run({
        activityUnavailabilityId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to make available',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Made available',
          variant: 'success',
        });
        runRefetch();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to make available',
          variant: 'error',
        })
      )
      .finally(() => onFinally && onFinally());
  };
};
