import { TabletButton } from '@campfire/tablet-button';
import { ToggleButton } from '@material-ui/lab';
import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import {
  BorderColorOutlined,
  FilterList,
  ListRounded,
  RoomOutlined,
  SwapVertRounded,
  GetAppOutlined,
} from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam, StringParam, BooleanParam } from 'use-query-params';
import classNames from 'classnames';
import { useStyles } from './activities-styles';
import { SearchField } from '../../../common/inputs/SearchField';
import PrivilegeRules from '../../../global/auth/PrivilegeRules';
import { useUser } from '../../../global/auth/useUser';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

export const MANAGER_SORT_FILTERS = [
  'Priority',
  'Recent and upcoming',
  'Duration (Shortest first)',
  'Name (A - Z)',
  'No. of Vols (Less first)',
];

interface SortMenuProps {
  selectedSortValue: string;
  sortValues: string[];
  setSelectedSortValue: (x: string) => void;
}

export const SortMenu = (props: SortMenuProps) => {
  const sortButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showSortMenu, setShowSortMenu] = useState(false);
  const { selectedSortValue, sortValues, setSelectedSortValue } = props;
  const { theme, isMobile, isMd } = useCampfireTheme();

  return (
    <>
      <Button
        style={{
          border: '1px solid #dcdcdc',
          boxSizing: 'border-box',
          borderRadius: '4px',
          textTransform: 'none',
          color: theme.color.grey.neutral400,
          marginRight: '10px',
          padding: isMd ? '7px 3px' : '7px 12px',
          display: isMobile ? 'none' : 'inherit',
          minWidth: '40px',
        }}
        ref={sortButtonRef}
        onClick={() => setShowSortMenu(true)}
      >
        <SwapVertRounded style={{ fontSize: '18px' }} />
        <Typography
          style={{
            marginLeft: '4px',
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '19px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: isMobile ? 'none' : 'inherit',
            paddingRight: '10px',
          }}
        >
          Sort By
        </Typography>
      </Button>
      <Menu
        id='activities-sort-menu'
        anchorEl={sortButtonRef ? sortButtonRef.current : undefined}
        keepMounted
        open={showSortMenu}
        onClose={() => setShowSortMenu(false)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {sortValues.map((opt) => (
          <MenuItem
            key={opt}
            onClick={() => {
              setShowSortMenu(!showSortMenu);
              setSelectedSortValue(opt);
            }}
            selected={selectedSortValue === opt}
          >
            <Box display='flex' flex={1} flexDirection='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='body2'>{opt}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface ActivitiesManagementNavItemsProps {
  managementPath: string;
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  selectedSortValue: string;
  setSelectedSortValue: (value: string) => void;
  setSearchInput: (value: string) => void;
  handleExport: () => void;
}

const ActivitiesManagementNavItems = (props: ActivitiesManagementNavItemsProps) => {
  const {
    managementPath,
    showFilters,
    setShowFilters,
    selectedSortValue,
    setSelectedSortValue,
    setSearchInput,
    handleExport,
  } = props;
  const [selectedProgramId] = useQueryParam('programId', StringParam);
  const [showMap, setShowMap] = useQueryParam('map', BooleanParam);
  const routeMatch = useRouteMatch();
  const { theme, isMobile, isMd } = useCampfireTheme();

  const {
    user: { userIdentityService },
  } = useUser();
  const canCreateActivity =
    userIdentityService.hasVmGlobalRule(PrivilegeRules.manageActivities) || userIdentityService.isProgramManager;

  function getCreateActivityLink() {
    const programParam = selectedProgramId ? `programId=${selectedProgramId}` : '';
    const returnParam = `return=${encodeURIComponent(routeMatch?.path ?? '')}`;

    return `${managementPath}/create?${returnParam}&${programParam}`;
  }

  const classes = useStyles();

  return (
    <Box
      borderBottom='solid #dadada 1px'
      py={1}
      display='flex'
      justifyContent='space-between'
      alignContent='center'
      alignItems='center'
      margin={!isMobile ? '12px 60px 0px 60px' : '10px 20px 0px 20px'}
    >
      <Box display='flex' alignItems='center'>
        <Typography
          variant='h6'
          noWrap
          style={{ color: theme.color.grey.neutralBrand800, fontSize: '20px', fontWeight: 800, paddingRight: '7px' }}
        >
          {'Activities'}
        </Typography>
      </Box>

      <Box display='flex' alignItems='center'>
        {!showMap ? (
          <Box style={{ marginRight: '10px' }}>
            <SearchField
              data-track='actCnlFilter-SearchInput'
              placeholder='Search Activities'
              onChange={(e) => setSearchInput(e.target.value as string)}
            />
          </Box>
        ) : null}

        <TabletButton
          variant='outlined'
          style={{
            color: theme.color.grey.neutral400,
            boxSizing: 'border-box',
            borderColor: '#dcdcdc',
            borderRadius: '4px',
            textTransform: 'none',
            padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
            minWidth: '40px',
            marginRight: '10px',
          }}
          onClick={handleExport}
        >
          <GetAppOutlined style={{ marginTop: '1px', fontSize: '20px' }} />
          <Typography
            style={{
              marginLeft: '4px',
              fontWeight: 600,
              fontSize: '13px',
              lineHeight: '19px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: isMobile || isMd ? 'none' : 'inherit',
            }}
          >
            Export
          </Typography>
        </TabletButton>

        <Button
          style={{
            border: '1px solid #dcdcdc',
            boxSizing: 'border-box',
            borderRadius: '4px',
            textTransform: 'none',
            color: theme.color.grey.neutral400,
            marginRight: '10px',
            padding: !isMobile || !isMd ? '7px 12px' : '7px 3px',
            display: isMobile ? 'none' : 'inherit',
            minWidth: '40px',
          }}
          onClick={() => setShowMap(!showMap)}
        >
          {!showMap ? <RoomOutlined style={{ fontSize: '18px' }} /> : <ListRounded style={{ fontSize: '18px' }} />}
          <Typography
            style={{
              marginLeft: '4px',
              fontWeight: 600,
              fontSize: '13px',
              lineHeight: '19px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: isMobile || isMd ? 'none' : 'inherit',
            }}
          >
            {!showMap ? 'Map' : 'List'}
          </Typography>
        </Button>
        {!showMap ? (
          <SortMenu
            sortValues={MANAGER_SORT_FILTERS}
            selectedSortValue={selectedSortValue}
            setSelectedSortValue={setSelectedSortValue}
          />
        ) : null}
        {!showMap ? (
          <ToggleButton
            classes={{
              root: classNames(classes.button, classes.headerButton),
              selected: classes.toggledButton,
            }}
            selected={showFilters}
            onClick={() => setShowFilters(!showFilters)}
          >
            <FilterList style={{ fontSize: '18px' }} />
            <Typography
              style={{
                marginLeft: '4px',
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '19px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: isMobile || isMd ? 'none' : 'inherit',
              }}
            >
              Filters
            </Typography>
          </ToggleButton>
        ) : null}
        {canCreateActivity ? (
          <Link data-track='actCnlCreateActivityButton' to={getCreateActivityLink()} style={{ textDecoration: 'none' }}>
            <TabletButton
              variant='contained'
              style={{
                backgroundColor: theme.color.secondary.main900,
                color: '#ffffff',
                boxSizing: 'border-box',
                borderRadius: '4px',
                textTransform: 'none',
                padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
                minWidth: '40px',
              }}
            >
              <BorderColorOutlined style={{ marginTop: '1px', fontSize: '16px' }} />
              <Typography
                style={{
                  marginLeft: '8px',
                  fontWeight: 600,
                  fontSize: '13px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: isMobile || isMd ? 'none' : 'inherit',
                }}
              >
                Create Activity
              </Typography>
            </TabletButton>
          </Link>
        ) : null}
      </Box>
    </Box>
  );
};

const MANAGEMENT_PATH = '/management/activity-management';
interface ActivitiesManagementNavItemsWrapperProps {
  children: React.ReactNode;
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  selectedSortValue: string;
  setSelectedSortValue: (value: string) => void;
  setSearchInput: (value: string) => void;
  handleExport: () => void;
}
const ActivitiesManagementNavItemsWrapper = (props: ActivitiesManagementNavItemsWrapperProps) => {
  const { children } = props;

  return (
    <Box display={'flex'} flexDirection={'column'} flex={1} overflow={'hidden'}>
      <ActivitiesManagementNavItems managementPath={MANAGEMENT_PATH} {...props} />
      {children}
    </Box>
  );
};

export { ActivitiesManagementNavItemsWrapper };
