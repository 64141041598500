import { object as YupObject, string as YupString } from 'yup';

export interface ScreeningQuestionsFormValues {
  screeningQuestions: ScreeningQuestionType[];
}

export interface ScreeningQuestionType {
  screeningQuestionId?: string;
  order: number;
  label: string;
  correctAnswer?: string | null;
}

export const validationSchema = YupObject().shape({
  label: YupString()
    .trim()
    .required('Please provide a question'),
});
