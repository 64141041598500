import { gql } from '@apollo/client';

export const GET_ORG_COMM_PREFERENCES = gql`
  query GetOrgCommPreferences {
    smsEnabled
    tm {
      tenant {
        config {
          logo
          communication {
            greeting
            signoff
          }
        }
      }
    }
  }
`;
