import { gql } from '@apollo/client';

export const GET_ORG_INFO = gql`
  query GetOrgInfo {
    orgName
    orgId
    orgDateCreated
    orgDateDeactivated
    smsEnabled
    isLargeOrg
    tm {
      tenant {
        packages {
          integrations
          insights
          rostering
          bulletins
          incidents
          attendanceBackup
        }
      }
    }
  }
`;
