import React, { memo, ReactNode } from 'react';
import { Grid, Typography, Divider, Box } from '@material-ui/core';
import { useCampfireTheme } from '../theme/useCampfireTheme';

const FormSectionV2 = memo(
  ({
    title,
    search,
    description,
    children,
  }: {
    title: string;
    search?: ReactNode;
    children: ReactNode;
    description?: ReactNode;
  }) => {
    const { isMobile, isSm } = useCampfireTheme();
    return (
      <Grid item container xs={12} style={{ padding: isMobile ? 0 : 16 }} spacing={2} justify='space-between'>
        <Grid item container sm={5} xs={12} direction='row' justify-content='space-between' alignItems='flex-start'>
          <Box display={isSm ? 'block' : 'flex'} justifyContent='space-between' alignItems='center' width='100%'>
            <Box>
              <Typography variant='h6'>{title}</Typography>
              {description}
            </Box>
            {search && (
              <Box
                display={isSm ? 'block' : 'flex'}
                justifyContent='flex-end'
                alignItems='flex-end'
                pl={isSm ? 0 : '4px'}
              >
                {search}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item sm={7} xs={12}>
          {children}
        </Grid>
        <Grid item xs={12} style={{ paddingTop: isMobile ? 16 : 8, paddingBottom: isMobile ? 16 : 8 }}>
          <Divider />
        </Grid>
      </Grid>
    );
  }
);

export { FormSectionV2 };
