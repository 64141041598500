import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const transitionIn = (cssProperty: string | string[], theme: Theme): string => {
  return theme.transitions.create(cssProperty, {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.shortest,
  });
};

export const transitionOut = (cssProperty: string | string[], theme: Theme): string => {
  return theme.transitions.create(cssProperty, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  });
};
