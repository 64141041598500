import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { arrayHead } from '../../../../../common/functions/array-head';
import { ProgramManagementVolunteer } from '../../__generated__/ProgramManagementVolunteer';
import { useProgramManagementContext } from '../../ProgramManagementContext';
import { PROGRAM_MANAGEMENT_GET_ACTIVE_VOLUNTEERS } from '../../program-management.gql';
import { ProgramManagementProgramManager } from '../../__generated__/ProgramManagementProgramManager';
import {
  ProgramManagementGetActiveVolunteers,
  ProgramManagementGetActiveVolunteersVariables,
} from '../../__generated__/ProgramManagementGetActiveVolunteers';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { SearchField } from '../../../../../common/inputs/SearchField';

interface Props {
  open: boolean;
  closeDialog: () => void;
  programName: string;
  programManagers: Array<ProgramManagementProgramManager>;
  refetch: () => void;
}

const isManagerOfProgram = (userId: string, programManagers: Array<ProgramManagementProgramManager>) => {
  return programManagers.some((programManager) => programManager.manager.profile.userId === userId);
};

const AddManagerDialog = (props: Props) => {
  const { open, closeDialog, programName, programManagers, refetch } = props;
  const { runAddProgramManager, updateProgramManagersIsLoading } = useProgramManagementContext();
  const [selectedProgramId] = useQueryParam('pid', StringParam);
  const [selectedVolunteer, setSelectedVolunteer] = useState<ProgramManagementVolunteer>();

  const { data: getProgramActiveVolunteersData, loading: getProgramActiveVolunteersLoading } = useCampfireQuery<
    ProgramManagementGetActiveVolunteers,
    ProgramManagementGetActiveVolunteersVariables
  >(PROGRAM_MANAGEMENT_GET_ACTIVE_VOLUNTEERS, {
    options: {
      variables: {
        programId: selectedProgramId,
      },
    },
  });

  const program = getProgramActiveVolunteersData?.vm.program || undefined;

  const potentialProgramManagers = useMemo(
    () =>
      program?.activeVolunteers
        .filter((volunteer) => !isManagerOfProgram(volunteer.profile.userId, programManagers))
        .sort((a: ProgramManagementVolunteer, b: ProgramManagementVolunteer) => {
          return a.profile.lastName.toUpperCase() > b.profile.lastName.toUpperCase() ? 1 : -1;
        }) || [],
    [program]
  );

  const [searchValue, setSearchValue] = React.useState('');

  const trimmedSearchValue = React.useMemo(() => searchValue.trim().toLowerCase(), [searchValue]);

  const filteredPotentialProgramManagers = React.useMemo(() => {
    return potentialProgramManagers.filter((ppm) => {
      if (
        ppm.profile.lastName.toLowerCase().includes(trimmedSearchValue) ||
        ppm.profile.preferredName.toLowerCase().includes(trimmedSearchValue)
      ) {
        return true;
      }
      const name = `${ppm.profile.preferredName.toLowerCase()} ${ppm.profile.lastName.toLowerCase()}`;
      if (name.includes(trimmedSearchValue)) {
        return true;
      }

      if (ppm.profile.email?.includes(trimmedSearchValue)) {
        return true;
      }

      if (ppm.profile.contactNumber?.includes(trimmedSearchValue)) {
        return true;
      }

      return false;
    });
  }, [trimmedSearchValue, potentialProgramManagers]);

  useEffect(() => {
    const defaultSelectedVolunteer = arrayHead(filteredPotentialProgramManagers);
    setSelectedVolunteer(defaultSelectedVolunteer);
  }, [filteredPotentialProgramManagers]);

  const handleSuccess = () => {
    closeDialog();
    if (refetch) refetch();
  };

  return (
    <Dialog open={open} fullWidth onClose={() => closeDialog()}>
      <DialogTitle>{`Add Management for ${programName}`}</DialogTitle>
      <DialogContent>
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
          <CircularProgressOverlay isLoading={updateProgramManagersIsLoading || getProgramActiveVolunteersLoading} />
          <SearchField
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            fullWidth
            placeholder='Search by name, email or contact number'
          />
          {selectedVolunteer ? (
            <FormControl variant='filled' fullWidth style={{ marginTop: '1rem' }}>
              <InputLabel htmlFor='select-volunteer-dropdown'>{'Select Volunteer'}</InputLabel>
              <Select
                native
                fullWidth
                displayEmpty
                value={selectedVolunteer.volunteerId}
                onChange={(event: any) => {
                  const matchingVolunteer = potentialProgramManagers.find(
                    (potentialProgramManager) => potentialProgramManager.volunteerId === event.target.value
                  );
                  setSelectedVolunteer(matchingVolunteer);
                }}
                inputProps={{
                  name: 'select-volunteer',
                  id: 'select-volunteer-dropdown',
                }}
              >
                {filteredPotentialProgramManagers.map((potentialProgramManager) => (
                  <option key={potentialProgramManager.volunteerId} value={potentialProgramManager.volunteerId}>
                    {`${potentialProgramManager.profile.preferredName} ${potentialProgramManager.profile.lastName}`}
                  </option>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </Grid>
      </DialogContent>

      <DialogActions>
        <TabletButton size='medium' variant='text' onClick={() => closeDialog()}>
          Cancel
        </TabletButton>
        <TabletButton
          color='primary'
          size='medium'
          variant='contained'
          onClick={() => {
            if (!selectedVolunteer || !program) return;
            runAddProgramManager(
              {
                volunteerId: selectedVolunteer.volunteerId,
                addedProgramId: program.programId,
              },
              handleSuccess
            );
          }}
          disabled={getProgramActiveVolunteersLoading}
        >
          Add Manager
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { AddManagerDialog };
