import { formatDistanceDays } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { LocationOnRounded } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { getDisplayTimeSchedule } from '../../../../../common/functions/activity-display-helpers';
import { IconsTextRowLayout } from '../../../../../common/icons/IconsTextRowLayout';
import { MyElementsUpcomingRostersActivity } from './__generated__/MyElementsUpcomingRostersActivity';

type Session = {
  sessionId: string;
  startTime: string;
  endTime: string;
  name: string;
  activityLocation?: {
    placesAddress?: {
      placesId: string;
      description: string;
    };
  };
};

interface SessionListItemProps {
  session: Session;
  activityLocationPlacesId?: string | null;
  checked: boolean;
  disabled?: boolean;
  handleClick: (sessionId: string) => void;
}

const SessionListItem = ({
  session,
  checked,
  handleClick,
  disabled,
  activityLocationPlacesId,
}: SessionListItemProps) => {
  const sessionHasPlacesId = !!session.activityLocation?.placesAddress?.placesId;

  return (
    <ListItem
      key={session.sessionId}
      onClick={() => (disabled ? undefined : handleClick(session.sessionId))}
      button={(!disabled as true | undefined) as true} // take the piss, MUI https://github.com/mui-org/material-ui/issues/14971#issuecomment-581402174
    >
      <ListItemIcon>
        <Checkbox color='primary' tabIndex={-1} checked={checked} />
      </ListItemIcon>

      <Box paddingY={1}>
        <Typography variant='body1' color='textPrimary'>{`${session.name}`}</Typography>
        <Typography variant='body2' color='textSecondary'>
          {getDisplayTimeSchedule(session.startTime, session.endTime)}
        </Typography>

        {activityLocationPlacesId &&
        sessionHasPlacesId &&
        session.activityLocation?.placesAddress?.placesId !== activityLocationPlacesId ? (
          <IconsTextRowLayout
            leftIcon={<LocationOnRounded color='disabled' />}
            text={session.activityLocation?.placesAddress?.description}
          />
        ) : null}
      </Box>
    </ListItem>
  );
};

interface Props {
  activity: MyElementsUpcomingRostersActivity;
  dateOfRoster: DateTime;
  sessions: Array<{ sessionId: string; name: string; startTime: string; endTime: string }>;
  selectedSessions: Array<string>;
  open: boolean;
  onClose: () => void;
  onSubmit: (x: number[], y: string[]) => void;
}

const InnerActivitySessionsSelectUpdateDialog = (props: Props) => {
  const { activity, dateOfRoster, sessions, onClose, onSubmit, selectedSessions } = props;
  const [selectedSessionIds, setSelectedSessionIds] = useState<string[]>(selectedSessions);
  const [availabilityDates, setAvailabilityDates] = useState<any>();
  const [errorMsg, setErrorMsg] = useState<string>();
  const isSingleSession = sessions.length === 1;

  useEffect(() => {
    if (isSingleSession && selectedSessionIds.length === 0) setSelectedSessionIds(sessions.map((x) => x.sessionId));
  }, [sessions]);

  useEffect(() => {
    if (selectedSessionIds.length >= 1) setErrorMsg(undefined);
  }, [selectedSessionIds]);

  const handleClick = (sessionId: string) => {
    setSelectedSessionIds(
      selectedSessionIds.includes(sessionId)
        ? selectedSessionIds.filter((selectedSessionId) => selectedSessionId !== sessionId)
        : [...selectedSessionIds, sessionId]
    );
  };

  const handleSubmit = () => {
    if (selectedSessionIds.length < 1) {
      setErrorMsg('Please select one or more sessions');
      return 0;
    }
    onSubmit(availabilityDates, selectedSessionIds);
    return 0;
  };

  useEffect(() => {
    if (!availabilityDates) {
      setAvailabilityDates([dateOfRoster]);
    }
  });

  const distanceInDays = formatDistanceDays(dateOfRoster);
  const daySubtitle = dateOfRoster.toLocaleString({
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  });

  return (
    <>
      <DialogContent dividers>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box paddingRight={2}>
            <Typography component='h2' variant='body2'>
              {activity.name}
            </Typography>
            <Typography component='h3' variant='h6'>
              {`${daySubtitle} (${distanceInDays})`}
            </Typography>

            <Box paddingTop={0.5}>
              {activity.activityLocation?.placesAddress ? (
                <IconsTextRowLayout
                  leftIcon={<LocationOnRounded color='action' />}
                  text={activity.activityLocation.placesAddress.description}
                />
              ) : activity.activityRemoteLocation?.activityRemoteLocationId ? (
                <IconsTextRowLayout leftIcon={<LocationOnRounded color='action' />} text='This activity is remote.' />
              ) : null}
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogContent>
        <Typography component='h2' variant='h6'>
          {`All Sessions`}
        </Typography>

        <Typography variant='body2' color='textSecondary'>
          {`Select the sessions that you're available for`}
        </Typography>

        <List>
          {sessions.map((session) => (
            <SessionListItem
              disabled={isSingleSession}
              key={session.sessionId}
              session={session}
              activityLocationPlacesId={activity.activityLocation?.placesAddress.placesId}
              checked={selectedSessionIds?.includes(session.sessionId)}
              handleClick={handleClick}
            />
          ))}
        </List>
        {errorMsg ? (
          <Typography color='error' variant='caption' gutterBottom style={{ paddingLeft: 24 }}>
            {errorMsg}
          </Typography>
        ) : null}
      </DialogContent>

      <DialogActions>
        <TabletButton size='medium' variant='text' onClick={onClose}>
          {'Cancel'}
        </TabletButton>
        <TabletButton size='medium' variant='contained' color='primary' onClick={() => handleSubmit()}>
          {'Done'}
        </TabletButton>
      </DialogActions>
    </>
  );
};

const ActivitySessionsSelectUpdateDialog = (props: Props) => {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <InnerActivitySessionsSelectUpdateDialog {...props} />
    </Dialog>
  );
};

export { ActivitySessionsSelectUpdateDialog };
