import { Box, Typography } from '@material-ui/core';
import { encodeDate } from '@campfire/hot-date';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { AlertCard, getAlertCardColors } from '../../../../../../common/cards/alert-card/AlertCard';
import { useUser } from '../../../../../../global/auth/useUser';
import { useVolDashboardMyElementsContext } from '../../VolDashboardMyElementsContext';
import { useSharedAvailabilityCentreDialogState } from '../../../../../../global/components/AvailabilityCenter/AvailabilityCenter';

export const SideBarAvailabilityAlert = () => {
  const { getVolunteerIdentity } = useUser();
  const volunteerIdentity = getVolunteerIdentity();
  const { dialogOpen, setDialogOpen } = useSharedAvailabilityCentreDialogState();
  const {
    getSideBarUpcomingRoster,
    getSideBarUpcomingRosterData,
    getSideBarUpcomingRosterDataLoading,
  } = useVolDashboardMyElementsContext();
  const { darkTextColor, lightTextColor } = getAlertCardColors('info');
  const { upcomingRosterNumber, setUpcomingRosterNumber } = useVolDashboardMyElementsContext();

  useEffect(() => {
    getSideBarUpcomingRoster({
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
        startDate: encodeDate(DateTime.local().plus({ month: 1 })),
        endDate: encodeDate(DateTime.local().plus({ month: 6 })),
      },
    });
  }, []);

  useEffect(() => {
    if (getSideBarUpcomingRosterData) {
      const upcomingRoster = getSideBarUpcomingRosterData?.vm.volunteer?.upcomingRosters || [];
      const upcomingRosterFilter = upcomingRoster.filter(
        (item) =>
          item &&
          (item.__typename === 'VOLUNTEER_UpcomingRosterAvailabilityType' ||
            item.__typename === 'VOLUNTEER_UpcomingRosterUnavailabilityType')
      );
      setUpcomingRosterNumber(upcomingRosterFilter.length);
    }
  }, [getSideBarUpcomingRosterData]);

  return getSideBarUpcomingRosterDataLoading ? (
    <Skeleton variant='text' width='100%' height={105} style={{ paddingBottom: '24px' }} />
  ) : !upcomingRosterNumber || upcomingRosterNumber <= 0 ? (
    <Box paddingBottom={3} width='100%'>
      <AlertCard title='Availabilities' variant='info'>
        <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
          {`It looks like you haven't set your availability for any volunteering activities in a while.`}
        </Typography>
        <Box>
          <Link
            to={{
              pathname: '/',
            }}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              setDialogOpen(!dialogOpen);
            }}
          >
            <Typography
              variant='body2'
              display='inline'
              style={{ textDecoration: 'underline', color: darkTextColor, fontWeight: 700 }}
            >
              Click here
            </Typography>
          </Link>
          <Typography
            variant='subtitle2'
            display='inline'
            style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
          >
            {' to update your availability, or use the Availabilities Centre in your navigation bar.'}
          </Typography>
        </Box>
      </AlertCard>
    </Box>
  ) : null;
};
