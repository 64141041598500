import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '4px',
      textTransform: 'none',
      marginRight: '10px',
      padding: '7px 12px',
      minWidth: '45px',
    },
    headerButton: {
      color: theme.color.grey.neutral400,
      borderColor: '#dcdcdc',
    },
    toggledButton: {
      color: '#FCFCFC !important',
      backgroundColor: '#646464 !important',
    },
    iconButton: {
      color: theme.color.grey.neutral300,
      padding: '0px 4px',
      fontSize: '26px',
      border: '1px solid #9e9e9e',
      boxSizing: 'border-box',
      borderRadius: '4px',
      margin: '0 4px',
    },
  };
});
