import { gql } from '@apollo/client';
import { ActivityTimelineItemFragments } from '../ActivityTimeline/ActivityTimelineItemFragments.gql';
import { ActivityScheduleFragments } from '../ActivityDialogs/ActivityScheduleFragments.gql';

export const GET_ACTIVITIES = gql`
  query GetActivities($volunteerId: String!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        programs {
          programId
          name
          activities(includeInactiveActivities: false) {
            activityId
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITIES_TIMELINE = gql`
  query GetActivitiesTimeline($volunteerId: String!, $activityIds: [String!]!, $startDate: Date, $endDate: Date) {
    orgName
    vm {
      volunteer(volunteerId: $volunteerId) {
        volunteerId
        profile {
          preferredName
        }
      }
      programs {
        name
        programId
        dateSuspended
        dateDeleted
        activities {
          activityId
          isSuspended
        }
      }
      activities(activityIds: $activityIds) {
        __typename
        ...ActivityTimelineItem
        occurrencesBetween(from: $startDate, until: $endDate)
        ... on VOLUNTEER_RecurringActivityType {
          recurrences {
            humanReadable
          }
        }
      }
    }
  }
  ${ActivityTimelineItemFragments}
  ${ActivityScheduleFragments}
`;

export const GET_ALL_ACTIVITY_ACTIVITY_TAGS = gql`
  query GetAllActivityActivityTags {
    vm {
      activityTags {
        ...ActivityTag
      }
    }
  }
  fragment ActivityTag on VOLUNTEER_ActivityTagType {
    activityTagId
    name
    description
    isHidden
  }
`;
