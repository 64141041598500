import { RichEditorFormik } from '@campfire/campfire-rich-editor';
import { DatePickerField } from '@campfire/date-picker/lib';
import { Field } from '@campfire/field/lib';
import { FileUploadGallery, FugFile, UploadedFile } from '@campfire/file-upload-gallery/lib';
import { HoverLink, HoverText } from '@campfire/hover-link/lib';
import { Select } from '@campfire/select/lib';
import { TabletButton } from '@campfire/tablet-button/lib';
import { TitularTooltip } from '@campfire/titular-tooltip';
import {
  Box,
  Checkbox,
  Collapse,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  InputLabel,
  Chip,
  MenuItem,
  TextField,
  Select as MuiSelect,
  OutlinedInput,
} from '@material-ui/core';
import { AddRounded, Close, Public as PublicIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { FieldArray, getIn, useField, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { capitalize } from 'lodash';
import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { ReactComponent as PendingActionsIcon } from '../../../../assets/pending_actions_24px.svg';
import { AutoLocationField } from '../../../../common/auto-location-field/AutoLocationField';
import { ConfirmationDialog } from '../../../../common/dialogs/ConfirmationDialog';
import { getDisplayTimeSchedule } from '../../../../common/functions/activity-display-helpers';
import { arrayHead } from '../../../../common/functions/array-head';
import { getOrdinal } from '../../../../common/functions/ordinal-number';
import { scrollIntoView } from '../../../../common/functions/scroll-functions';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { WaitlistWarningDialogV2 } from './WaitlistWarningDialogV2';
import { FormSectionV2 } from '../../../../common/FormSectionV2';
import { ActivityFormSession, ActivityFormValues, RecurrenceFrequency } from './ActivityFormV2';
import { ActivityInSuspendedProgramWarningLabelV2 } from './ActivityInSuspendedProgramWarningLabelV2';
import { AddNewSessionDialogV2 } from './add-new-session-dialog-v2/AddNewSessionDialogV2';
import { ActivityAttachmentV2 } from './__generated__/ActivityAttachmentV2';
import { CreateActivityProgramV2 } from './__generated__/CreateActivityProgramV2';
import { ActivityTag } from './__generated__/ActivityTag';
import { Recurrence } from '../../../../common/recurrence/__generated__/Recurrence';
import { SimpleActivityRecurrence } from './SimpleActivityRecurrence';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../common/cards/alert-card/AlertCard';

const BasicInfoSection = memo(({ programs }: { programs: CreateActivityProgramV2[] }) => {
  const { values } = useFormikContext<ActivityFormValues>();
  const [showDescription, setShowDescription] = useState(!!values.activityDescription);

  const isEditMode = !!values.activityId;

  return (
    <FormSectionV2
      title='Basics'
      description={
        <Typography variant='body2' display='block' color='textSecondary'>
          {`Give your activity a unique, memorable name. Volunteers will see this when browsing activities or managing their availability.`}
        </Typography>
      }
    >
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12}>
          <Field name='activityName' label='Activity name' variant='outlined' helper fullWidth required />
        </Grid>
        <Grid item xs={12}>
          <Select
            slow
            fullWidth
            includeEmpty
            variant='outlined'
            name='programId'
            label='Programs'
            items={programs}
            renderFunction={(program: CreateActivityProgramV2) => program.name}
            valueFunction={(program: CreateActivityProgramV2) => program.programId}
            disabled={isEditMode}
          />
        </Grid>
        <Grid item data-track='create-activity-add-description-container'>
          <Collapse in={showDescription}>
            <RichEditorFormik valueName='activityDescription' placeholder='Description (Optional)' />
          </Collapse>
          {!showDescription ? (
            <HoverText variant='body2' color='primary' onClick={() => setShowDescription(true)}>
              {'Add a description'}
            </HoverText>
          ) : null}
        </Grid>
      </Grid>
    </FormSectionV2>
  );
});

function getPodiumPositionReadable(value: number): string {
  switch (value) {
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
    case 5:
      return 'fifth';
    default:
      return 'last';
  }
}

interface FrequencyItem {
  label: string;
  value: RecurrenceFrequency;
}
const activityRadioitems = [
  {
    value: 'regular',
    label: 'Regular',
    instruction:
      'Suited for activities that run according to a set frequency, with repeating rosters. Regular activities are the recommended option for recurring engagements such as weekly outreach services, monthly meetings, or standalone events that with no stops that use the same roster structure every day.',
  },
  {
    value: 'flexible',
    label: 'Flexible',
    instruction:
      "Flexible activities don't run according to any set frequency. Rosters are created manually as needed. We recommend this option for activities that run irregularly, such as corporate volunteering, ad-hoc volunteering, sporadic warehouse/packing activities, open projects, or any other kind of engagement that has no predictable daily, weekly, or monthly frequency.",
  },
];

const CICORadioitems = [
  {
    value: null,
    label: 'Inherit',
    instruction: 'Inherit your check-in and check-out settings from your program or organisation-level preference',
  },
  {
    value: true,
    label: 'Allow',
    instruction:
      'Allow volunteers to check-in and out of their sessions, no matter the program or organisation-level preference',
  },
  {
    value: false,
    label: 'Prevent',
    instruction:
      'Prevent volunteers from checking in and out, and instead record volunteer hours and attendance by setting a start and end time',
  },
];

const RadioGroupItem = ({
  showRadioItem,
  value,
  label,
  instruction,
  showChangeButton,
  onChangeButtonClick,
}: {
  showRadioItem: boolean;
  value: string;
  label: string;
  instruction?: string;
  showChangeButton: boolean;
  onChangeButtonClick?: () => void;
}) => {
  return (
    <Box display={showRadioItem ? 'block' : 'none'}>
      <FormControlLabel value={value} control={<Radio color='primary' />} label={label} />
      <Box px={4}>
        {showChangeButton && (
          <HoverText
            variant='body2'
            color='primary'
            onClick={onChangeButtonClick}
            style={{ marginTop: '-12px' }}
            gutterBottom
          >
            {'Click here to change activity type'}
          </HoverText>
        )}
        {instruction && (
          <Typography variant='body2' color='textSecondary'>
            {instruction}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const ActivityTypeSection = ({ activityType }: { activityType?: string }) => {
  const formik = useFormikContext<ActivityFormValues>();
  const [showStartDate, setShowStartDate] = useState(!!formik.values.startDate);
  const [showEndDate, setShowEndDate] = useState(!!formik.values.endDate);
  const [showingActivityRecurrence, setShowingActivityRecurrence] = useState<boolean>(false);
  const { frequency: activityFrequency } = formik.values;

  const onClose = () => {
    setShowingActivityRecurrence(false);
  };

  const onChange = (value: Recurrence) => {
    formik.setFieldValue('recurrence', value);
  };

  const [selectedActivityType, setSelectedActivityType] = useState<string | undefined>(
    activityType === 'VOLUNTEER_RecurringActivityType'
      ? 'regular'
      : activityType === 'VOLUNTEER_NonRecurringActivityType'
      ? 'flexible'
      : undefined
  );

  useEffect(() => {
    if (formik.values.frequency === 'no-repeat') {
      setShowEndDate(false);
    }
  }, [formik.values.frequency]);

  const startDateTime: DateTime | undefined = useMemo(
    () => (formik.values.startDate ? DateTime.fromJSDate(formik.values.startDate) : undefined),
    [formik.values.startDate]
  );

  const frequencyItems: Array<FrequencyItem> = useMemo(() => {
    const numWeekdayInMonth = startDateTime
      ? Math.ceil(Number.parseInt(startDateTime.toFormat('d'), 10) / 7)
      : undefined;
    const numWeekdayPodiumPosition = getPodiumPositionReadable(numWeekdayInMonth ?? 1);
    const dayReadable = startDateTime ? startDateTime.toFormat('cccc') : undefined;

    return [
      { label: `Once off`, value: 'no-repeat' },
      { label: 'Daily', value: 'daily' },
      { label: dayReadable ? `Weekly on ${dayReadable}` : 'Weekly', value: 'weekly' },
      { label: 'Weekdays (Monday - Friday)', value: 'weekdays' },
      { label: 'Weekends (Saturday & Sunday)', value: 'weekends' },
      {
        label: startDateTime && dayReadable ? `Fortnightly on ${dayReadable}` : 'Fortnightly',
        value: 'fortnightly',
      },
      {
        label: startDateTime ? `Monthly on the ${getOrdinal(startDateTime.day)}` : 'Monthly',
        value: 'monthly-month-day',
      },
      {
        label: startDateTime ? `Monthly on the ${numWeekdayPodiumPosition} ${dayReadable}` : 'Monthly',
        value: 'monthly-nth-day',
      },
      {
        label: 'Yearly',
        value: 'yearly',
      },
      {
        label: 'Custom',
        value: 'custom',
      },
    ];
  }, [startDateTime]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedActivityType(event.target.value);
    if (!formik.values.endDate) setShowEndDate(false);
    // if the user switches from recurring to non-recurring, set the frequency to undefined
    if (event.target.value === 'flexible') formik.setFieldValue('frequency', undefined);

    // if the user switches from non-recurring to recurring and if there is no frequency , set the frequency to default i.e. daily
    if (event.target.value === 'regular' && !formik.values.frequency) formik.setFieldValue('frequency', 'daily');
  };

  return (
    <FormSectionV2
      title='Activity type'
      description={
        <>
          <Typography variant='body2' display='block' color='textSecondary'>
            {`Choose between regular and flexible activity types. These settings concern the dates and frequencies of rosters and reports.`}
          </Typography>
        </>
      }
    >
      {showingActivityRecurrence && (
        <SimpleActivityRecurrence
          open
          onClose={onClose}
          onChange={onChange}
          fullScreen={false}
          value={formik.values.recurrence}
        />
      )}
      <RadioGroup
        data-track='create-activity-activity-type-container'
        value={selectedActivityType || 'none'}
        onChange={handleChange}
      >
        {activityRadioitems.map((activityRadioItem) => (
          <RadioGroupItem
            key={activityRadioItem.value}
            showRadioItem={!selectedActivityType || selectedActivityType === activityRadioItem.value}
            value={activityRadioItem.value}
            label={activityRadioItem.label}
            instruction={!selectedActivityType ? activityRadioItem.instruction : undefined}
            showChangeButton={selectedActivityType === activityRadioItem.value}
            onChangeButtonClick={() => setSelectedActivityType(undefined)}
          />
        ))}
      </RadioGroup>
      {selectedActivityType === 'flexible' ? (
        <Box pl={4}>
          <Typography variant='body2' gutterBottom>
            You can create rosters at any time
          </Typography>
          <Grid container direction='column' spacing={2} style={{ paddingTop: 8 }}>
            <Grid item xs={12} container spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6}>
                {!showStartDate && !formik.values.startDate ? (
                  <HoverText variant='body2' color='primary' onClick={() => setShowStartDate(true)}>
                    {'Need to add start and end dates?'}
                  </HoverText>
                ) : (
                  <DatePickerField
                    name='startDate'
                    label='Start Date (Optional)'
                    autoOk
                    fullWidth
                    inputVariant='outlined'
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6}>
                {(formik.values.startDate || showStartDate) && !showEndDate ? (
                  <HoverText variant='body2' color='primary' onClick={() => setShowEndDate(true)}>
                    {'Add an end date'}
                  </HoverText>
                ) : (
                  <Fade in={showEndDate}>
                    <DatePickerField
                      name='endDate'
                      label='End Date (Optional)'
                      autoOk
                      fullWidth
                      inputVariant='outlined'
                      InputLabelProps={{ shrink: true }}
                    />
                  </Fade>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : selectedActivityType === 'regular' ? (
        <>
          <Grid container direction='column' spacing={2} style={{ paddingTop: 8 }}>
            <Grid item xs={12} container spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6}>
                <DatePickerField
                  name='startDate'
                  label='Start Date'
                  autoOk
                  fullWidth
                  inputVariant='outlined'
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {!showEndDate ? (
                  <HoverText
                    variant='body2'
                    color='primary'
                    onClick={() => setShowEndDate(true)}
                    disabled={formik.values.frequency === 'no-repeat'}
                  >
                    {'Add an end date'}
                  </HoverText>
                ) : (
                  <Fade in={showEndDate}>
                    <DatePickerField
                      name='endDate'
                      label='End Date (Optional)'
                      autoOk
                      fullWidth
                      inputVariant='outlined'
                      disabled={formik.values.frequency === 'no-repeat'}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Fade>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2} alignItems='center'>
              <TitularTooltip
                disableFocusListener={!!startDateTime}
                disableHoverListener={!!startDateTime}
                disableTouchListener={!!startDateTime}
                description={`Select a Start Date before choosing a frequency. `}
              >
                <Grid item xs={6}>
                  <Select
                    style={
                      !startDateTime
                        ? {
                            pointerEvents: 'none',
                          }
                        : undefined
                    }
                    disabled={!startDateTime}
                    variant='outlined'
                    margin='normal'
                    helper
                    slow
                    required
                    native
                    shrink
                    fullWidth
                    name='frequency'
                    label='Frequency'
                    items={frequencyItems}
                    renderFunction={(frequency: FrequencyItem) => frequency.label}
                    valueFunction={(frequency: FrequencyItem) => frequency.value}
                  />
                </Grid>
              </TitularTooltip>
              <Grid item xs={12} sm={6}>
                {activityFrequency === 'custom' && (
                  <HoverText color='primary' variant='body2' onClick={() => setShowingActivityRecurrence(true)}>
                    Edit
                  </HoverText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}
    </FormSectionV2>
  );
};

// const AddressManual = () => {
//   return (
//     <>
//       <Grid container spacing={2} style={{ paddingTop: 16 }}>
//         <Grid item xs={12}>
//           <TextField variant='outlined' label='Address Line 1' fullWidth required />
//         </Grid>

//         <Grid item xs={12}>
//           <TextField variant='outlined' label='Address Line 2' fullWidth />
//         </Grid>

//         <Grid item xs={12}>
//           <TextField variant='outlined' label='Suburb' fullWidth required />
//         </Grid>

//         <Grid item sm={6}>
//           <TextField variant='outlined' label='State' fullWidth required />
//         </Grid>

//         <Grid item sm={6}>
//           <TextField variant='outlined' label='Postcode' fullWidth required />
//         </Grid>

//         <Grid item xs={12}>
//           <TextField variant='outlined' label='Country' fullWidth required />
//         </Grid>
//       </Grid>
//     </>
//   );
// };

const LocationSection = memo(() => {
  const formik = useFormikContext<ActivityFormValues>();

  const [{ value: locationType }, _unused, { setValue: setLocationType }] = useField('locationType');

  const locationOptions = [
    {
      value: 'local',
      label: 'Activity with specified location',
    },
    {
      value: 'remote',
      label: 'Remote activity',
    },
  ];

  const handleRemoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLocationType(value);
    if (value === 'remote') {
      formik.setFieldValue('location', null);
      formik.setFieldValue('remoteLocation', { details: '' });
    }
    if (value === 'local') {
      formik.setFieldValue('remoteLocation', null);
    }
  };

  return (
    <FormSectionV2
      title='Location'
      description={
        <Typography variant='body2' display='block' color='textSecondary'>
          {`Start typing to search for an address or place, then select from the list to set the location for this Activity.`}
        </Typography>
      }
    >
      <FormControl component='fieldset' data-track='create-activity-location-control'>
        <RadioGroup
          data-track='create-activity-location-radio-group'
          aria-label='Remote activity type'
          name='remoteActivityType'
          value={locationType}
          onChange={handleRemoteChange}
        >
          {locationOptions.map(({ value, label }) => (
            <FormControlLabel key={value} value={value} control={<Radio color='primary' key={value} />} label={label} />
          ))}
        </RadioGroup>
      </FormControl>
      {locationType === 'local' && (
        <Box mt={2}>
          <AutoLocationField name='location' label='Location' helper fullWidth variant='outlined' required />
        </Box>
      )}
      {locationType === 'remote' && (
        <Box mt={2}>
          <Field
            name='remoteLocation.details'
            label='Add additional info (URL, phone number, etc.)'
            variant='outlined'
            helper
            fullWidth
            autoFocus
          />
          <Typography variant={'body2'}>
            This information will only be visible to volunteers who are on the team
          </Typography>
        </Box>
      )}
    </FormSectionV2>
  );
});

const VisibilitySection = () => {
  const [showWaitlistWarningDialog, setShowWaitlistWarningDialog] = useState(false);
  const { values, setFieldValue } = useFormikContext<ActivityFormValues>();
  const { theme } = useCampfireTheme();
  const toggleBooleanValue = (valueName: string, bool: boolean) => {
    setFieldValue(valueName, !bool);
  };

  const isRestrictedActivityOptions = [
    {
      value: true,
      label: 'Require approval',
      instruction: 'Volunteers will require manager approval when applying to join the team',
    },
    {
      value: false,
      label: 'Open activity',
      instruction: 'Any volunteer can join the team instantly',
    },
  ];

  return (
    <>
      <FormSectionV2
        title='Privacy settings'
        description={
          <Typography variant='body2' display='block' color='textSecondary'>
            {`Choose the privacy settings for your activity. Consider how you want volunteers to find the activity and join the team.`}
          </Typography>
        }
      >
        <Box display='flex' mt={2} mb={1}>
          <Box
            data-track='create-activity-joining-policy-container'
            display='inline-flex'
            flexDirection='column'
            pl={1}
          >
            <Typography variant='h6' gutterBottom>
              Joining policy
            </Typography>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label='Restricted activity radio'
                name='isRestrictedActivity'
                value={values.isRestrictedActivity}
                onChange={() => toggleBooleanValue('isRestrictedActivity', values.isRestrictedActivity)}
              >
                {isRestrictedActivityOptions.map(({ value, label, instruction }) => (
                  <Fragment key={label}>
                    <FormControlLabel
                      value={value}
                      control={<Radio color='primary' key={value.toString()} />}
                      label={
                        <Box display='inline-flex' flexDirection='row' alignItems='center'>
                          <Typography variant='body1'>{label}</Typography>
                          {value ? (
                            <PendingActionsIcon style={{ paddingLeft: 8, fill: theme.palette.grey[500] }} />
                          ) : (
                            <PublicIcon style={{ paddingLeft: 8, fill: theme.palette.grey[500] }} />
                          )}
                        </Box>
                      }
                    />
                    <Box px={4} mb={1} style={{ marginTop: '-10px' }}>
                      <Typography variant='body2' color='textSecondary'>
                        {instruction}
                      </Typography>
                    </Box>
                  </Fragment>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box mt={2} mb={1} pl={1} data-track='create-activity-hide-activity-container'>
          <Typography variant='body1' style={{ fontWeight: 600 }} gutterBottom>
            {'Hide activity?'}
          </Typography>
          <Box display='flex' flexDirection='column'>
            <FormControlLabel
              data-track='create-activity-hide-activity-form-control'
              control={
                <Checkbox
                  checked={values.isHidden}
                  color='primary'
                  onChange={() => toggleBooleanValue('isHidden', values.isHidden)}
                  name='isHidden'
                />
              }
              label='Keep this activity hidden from volunteers who aren’t on the team'
            />
          </Box>
        </Box>

        <Box mt={2} mb={1} pl={1} data-track='create-activity-max-team-size-container'>
          <Typography variant='body1' style={{ fontWeight: 600 }} gutterBottom>
            {'Maximum Team Size?'}
          </Typography>
          <Typography style={{ fontSize: '14px', color: '#0000008a' }}>
            Setting a maximum team size will prevent volunteers from being able to automatically join once exceeded.
            <br /> New volunteers will instead be added to this activities waitlist.
          </Typography>
          <Box display='flex' flexDirection='column' ml={1} mt={2}>
            <FormControlLabel
              data-track='create-activity-max-team-size-form-control'
              control={
                <TextField
                  type='number'
                  variant='outlined'
                  color='primary'
                  onChange={(e) =>
                    setFieldValue('maxTeam', e.target.value === '' ? null : parseInt(e.target.value, 10))
                  }
                  value={values.maxTeam}
                  name='maxTeam'
                  label='Enter a Number (Optional)'
                  inputProps={{ step: '1', min: '1' }}
                />
              }
              label=''
            />
          </Box>
        </Box>
      </FormSectionV2>
      <WaitlistWarningDialogV2
        open={showWaitlistWarningDialog}
        cancel={() => setShowWaitlistWarningDialog(false)}
        makeOpen={() => {
          setFieldValue('isRestrictedActivity', false);
          setShowWaitlistWarningDialog(false);
        }}
      />
    </>
  );
};

const rosterTypeSettings = [
  {
    value: false,
    label: 'Managed',
    instruction:
      'Volunteers indicate availability, managers or activity leaders are responsible for selecting the team and publishing the roster',
  },
  {
    value: true,
    label: 'Open',
    instruction: 'Volunteers can add and remove themselves from the roster freely',
  },
];

const RosterSettingsSection = () => {
  const { values, setFieldValue } = useFormikContext<ActivityFormValues>();

  return useMemo(
    () => (
      <FormSectionV2
        title='Roster settings'
        description={
          <Typography variant='body2' display='block' color='textSecondary'>
            {`Choose the roster settings for your activity. Consider how you want volunteers to apply for and join rosters.`}
          </Typography>
        }
      >
        <Box display='flex' mt={2} mb={1}>
          <Box data-track='' display='inline-flex' flexDirection='column' pl={1}>
            <Typography variant='h6' gutterBottom>
              {'Roster type'}
            </Typography>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label='Managed'
                name='hasOpenRoster'
                value={values.hasOpenRoster}
                onChange={() => setFieldValue('hasOpenRoster', !values.hasOpenRoster)}
              >
                {rosterTypeSettings.map(({ value, label, instruction }) => (
                  <Fragment key={label}>
                    <FormControlLabel
                      value={value}
                      control={<Radio color='primary' key={value.toString()} />}
                      label={
                        <Box display='inline-flex' flexDirection='row' alignItems='center'>
                          <Typography variant='body1'>{label}</Typography>
                        </Box>
                      }
                    />
                    <Box px={4} mb={1} style={{ marginTop: '-10px' }}>
                      <Typography variant='body2' color='textSecondary'>
                        {instruction}
                      </Typography>
                    </Box>
                  </Fragment>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </FormSectionV2>
    ),
    [values.hasOpenRoster]
  );
};

const ReportingSection = () => {
  const { values, setFieldValue } = useFormikContext<ActivityFormValues>();
  const onChangeAllowCICO: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    if (value === 'true') {
      return setFieldValue('allowCICO', true);
    }

    if (value === 'false') {
      return setFieldValue('allowCICO', false);
    }

    return setFieldValue('allowCICO', null);
  };

  return useMemo(
    () => (
      <FormSectionV2
        title='Reporting'
        description={
          <Typography variant='body2' display='block' color='textSecondary'>
            {`Choose the reporting settings for your activity. Consider how you want volunteers attendance and hours to be recorded.`}
          </Typography>
        }
      >
        <Box display='flex' mt={2} mb={1}>
          <Box data-track='' display='inline-flex' flexDirection='column' pl={1}>
            <Typography variant='h6' gutterBottom>
              {'Check In/Check Out'}
            </Typography>
            <FormControl component='fieldset'>
              <RadioGroup name='allowCICO' value={values.allowCICO} onChange={onChangeAllowCICO}>
                {CICORadioitems.map((CICORadioItem) => (
                  <Fragment key={CICORadioItem.label}>
                    <FormControlLabel
                      value={CICORadioItem.value}
                      control={<Radio color='primary' key={CICORadioItem.label} />}
                      label={
                        <Box display='inline-flex' flexDirection='row' alignItems='center'>
                          <Typography variant='body1'>{CICORadioItem.label}</Typography>
                        </Box>
                      }
                    />
                    <Box px={4} mb={1} style={{ marginTop: '-10px' }}>
                      <Typography variant='body2' color='textSecondary'>
                        {CICORadioItem.instruction}
                      </Typography>
                    </Box>
                  </Fragment>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </FormSectionV2>
    ),
    [values.allowCICO]
  );
};

const AttachmentsSection = memo(({ attachments }: { attachments?: ActivityAttachmentV2[] }) => {
  const { setFieldValue, errors, values } = useFormikContext<ActivityFormValues>();
  const uploadedFiles: Array<UploadedFile> | undefined = useMemo(
    () =>
      attachments?.map((attachment) => ({
        url: attachment.url,
        fileId: attachment.activityAttachmentId,
        name: attachment.name,
      })),
    [attachments]
  );
  return (
    <FormSectionV2
      title='Attachments'
      description={
        <Typography variant='body2' display='inline' color='textSecondary'>
          {
            'Files can be uploaded to an activity. Volunteers will be able to see all attached files when they are viewing the activity.'
          }
        </Typography>
      }
    >
      <FileUploadGallery
        id={'addedAttachments'}
        data-track='resources-create-activity-attachments-area'
        allowMultiple
        uploadedFiles={uploadedFiles ?? []}
        handleFilesAdded={(files: FugFile[]) => {
          const newAddedFiles = values.addedAttachments.concat(files);
          setFieldValue('addedAttachments', newAddedFiles);
          setFieldValue(
            'creationTokens',
            newAddedFiles.map((file) => file.id)
          );
        }}
        handleFileRemoved={(removedFile: FugFile) => {
          setFieldValue(
            'addedAttachments',
            values.addedAttachments.filter((file) => file.id !== removedFile.id)
          );
          setFieldValue(
            'creationTokens',
            values.creationTokens.filter((token) => token !== removedFile.id)
          );
          setFieldValue('removedAttachmentIds', [...values.removedAttachmentIds, removedFile.id]);
        }}
      />
      <Typography variant={'body2'} color='textSecondary'>
        These attachments will be visible to everyone.
      </Typography>
      <Typography variant={'subtitle2'} color='error'>
        {errors.addedAttachments}
      </Typography>
    </FormSectionV2>
  );
});

interface SessionProps {
  session: ActivityFormSession;
  idx: number;
  setOpenAddNewSessionDialog: (x: { status: boolean; idx?: number }) => void;
  setSelectedSession: (x: ActivityFormSession) => void;
}
const Session = memo((props: SessionProps) => {
  const { session, setSelectedSession, idx, setOpenAddNewSessionDialog } = props;
  const { name, reportType, endTime, startTime } = session;
  const { isMobile, theme } = useCampfireTheme();

  return (
    <Grid
      item
      container
      xs={12}
      style={{ padding: isMobile ? 0 : 16 }}
      spacing={2}
      alignItems='center'
      justify='space-between'
    >
      <Grid item xs={12} md={4}>
        <HoverText
          color='primary'
          variant='h6'
          onClick={() => {
            setSelectedSession(session);
            setOpenAddNewSessionDialog({ status: true, idx: idx });
          }}
        >
          {`${name}`}
        </HoverText>
      </Grid>

      <Grid
        item
        container
        direction='column'
        xs={12}
        md={8}
        style={{
          marginLeft: isMobile ? 16 : 0,
          borderLeft: `3px solid ${theme.palette.primary.main}`,
          padding: 0,
        }}
      >
        <Grid
          item
          container
          xs={12}
          spacing={isMobile ? 1 : 2}
          alignItems='center'
          style={{ marginLeft: isMobile ? 8 : 0 }}
        >
          <Grid item style={{ width: 52 }}>
            <Typography variant='subtitle2'>{`Report`}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='body2'>{`${reportType.name}`}</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={isMobile ? 1 : 2} style={{ marginLeft: isMobile ? 8 : 0 }}>
          <Grid item style={{ width: 52 }}>
            <Typography variant='subtitle2'>{`Time`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2'>{getDisplayTimeSchedule(startTime, endTime)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

interface ScheduleProps {
  sessions: ActivityFormSession[];
  setOpenAddNewSessionDialog: (x: { status: boolean; idx?: number }) => void;
  setSelectedSession: (x: ActivityFormSession) => void;
}
const ScheduleSection = memo((props: ScheduleProps) => {
  const { sessions, setOpenAddNewSessionDialog, setSelectedSession } = props;
  const [sessionToRemove, setSessionToRemove] = useState<{ name: string; idx: number }>();
  const { isMobile, theme } = useCampfireTheme();
  const formik = useFormikContext<ActivityFormValues>();
  const error = getIn(formik.errors, 'sessions');
  const touched = getIn(formik.touched, 'sessions');
  const ref: React.RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    if (formik.isSubmitting) {
      formik.setFieldTouched('sessions', true, true);
      if (Object.keys(formik.errors).length === 1 && arrayHead(Object.keys(formik.errors)) === 'sessions') {
        scrollIntoView(ref, { behavior: 'smooth' }, true);
      }
    }
  }, [formik.isSubmitting]);

  const classes = makeStyles({
    deleteIcon: {
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error.main,
      },
    },
  })();
  return (
    <div ref={ref}>
      <FormSectionV2
        title='Sessions'
        description={
          <Typography variant='body2' display='inline' color='textSecondary'>
            {'Add sessions to your activity'}
          </Typography>
        }
      >
        <FieldArray
          name='sessions'
          render={(arrayHelpers) => (
            <Fragment>
              {sessions.length ? (
                <Grid container alignItems='center'>
                  {sessions.map((session, idx) => {
                    return (
                      <Grid item container xs={12} alignItems='center' key={session.sessionId || session.name}>
                        <Grid item xs={11} style={{ marginBottom: isMobile ? 16 : 0 }}>
                          <Session
                            idx={idx}
                            session={session}
                            setOpenAddNewSessionDialog={setOpenAddNewSessionDialog}
                            setSelectedSession={setSelectedSession}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => setSessionToRemove({ name: session.name, idx: idx })}
                            className={classes.deleteIcon}
                          >
                            <Close />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : null}
              {sessionToRemove && (
                <ConfirmationDialog
                  title={'Confirm Removal'}
                  body={`Are you sure you wish to remove ${sessionToRemove.name}?`}
                  open
                  closeActionText={'Cancel'}
                  confirmActionText={'Remove'}
                  handleCloseClick={() => setSessionToRemove(undefined)}
                  handleConfirmClick={() => {
                    arrayHelpers.remove(sessionToRemove.idx);
                    setSessionToRemove(undefined);
                  }}
                />
              )}
            </Fragment>
          )}
        />
        <Grid data-track='create-activity-add-session-container' item container spacing={2} alignItems='center'>
          {sessions.length === 0 ? (
            <Grid item xs>
              <TabletButton
                startIcon={<AddRounded />}
                fullWidth
                color={error && touched ? 'error' : 'primary'}
                variant='contained'
                size='large'
                onClick={() => setOpenAddNewSessionDialog({ status: true })}
                style={{ textTransform: 'none', maxWidth: 360 }}
              >
                {'Add session'}
              </TabletButton>
            </Grid>
          ) : (
            <Grid item style={{ paddingTop: 24 }}>
              <TabletButton
                startIcon={<AddRounded />}
                color='primary'
                variant='contained'
                onClick={() => setOpenAddNewSessionDialog({ status: true })}
              >
                {'Add another session'}
              </TabletButton>
            </Grid>
          )}
        </Grid>
      </FormSectionV2>
    </div>
  );
});

const TagsSection = memo(({ activityTags }: { activityTags: ActivityTag[] }) => {
  const [tagsSelectOpen, setTagsSelectOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<ActivityFormValues>();
  const { lightTextColor, darkTextColor } = getAlertCardColors('info');

  return (
    <FormSectionV2
      title='Tags (Optional)'
      description={
        <Typography variant='body2' display='block' color='textSecondary'>
          {`Activity tags are a great way to sort and categorise your activities by a whole range of dimensions - locations, types of activities, asset tracking, etc.`}
        </Typography>
      }
    >
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12}>
          <Box py={1}>
            {activityTags.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel
                  htmlFor='select-volunteer-roles'
                  style={{ left: '14px', top: '-4px', backgroundColor: '#FFFFFF', zIndex: 1 }}
                >{`Activity Tags`}</InputLabel>
                <MuiSelect
                  fullWidth
                  open={tagsSelectOpen}
                  onOpen={() => {
                    setTagsSelectOpen(true);
                  }}
                  onClose={() => {
                    setTagsSelectOpen(false);
                  }}
                  multiple
                  variant='filled'
                  value={values.activityTagIds}
                  onChange={(event) => {
                    const selectedTags = (event.target.value as string[]).filter((x) => !!x);
                    setFieldValue('activityTagIds', selectedTags);
                  }}
                  input={<OutlinedInput id='select-activity-tags' label='Chip' />}
                  renderValue={(tagIds) => (
                    <Grid container spacing={1}>
                      {(tagIds as Array<string>).map((tagId) => {
                        const selectedTag = activityTags.find((x) => x.activityTagId === tagId);
                        return selectedTag ? (
                          <Grid item key={selectedTag.activityTagId}>
                            <Chip color='primary' label={capitalize(selectedTag.name)} />
                          </Grid>
                        ) : null;
                      })}
                    </Grid>
                  )}
                >
                  {activityTags
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((tag) => {
                      return (
                        <MenuItem key={tag.activityTagId} value={tag.activityTagId}>
                          <Checkbox
                            color='primary'
                            checked={!!values.activityTagIds.find((tagId) => tagId === tag.activityTagId)}
                          />
                          {tag.name}
                        </MenuItem>
                      );
                    })}
                </MuiSelect>
              </FormControl>
            ) : (
              <AlertCard variant='info' title='No Activity Tags'>
                <AlertCardBody>
                  <Typography style={{ color: lightTextColor, fontSize: '14px' }}>
                    You haven&apos;t created any activity tags yet.{' '}
                    <HoverLink
                      target={'_blank'}
                      style={{ textDecoration: 'underline', color: darkTextColor }}
                      to={'/admin/admin-console/tags'}
                    >
                      Click here to create your first
                    </HoverLink>
                  </Typography>
                </AlertCardBody>
              </AlertCard>
            )}
          </Box>
        </Grid>
        <Grid item data-track='create-activity-add-tag-container'></Grid>
      </Grid>
    </FormSectionV2>
  );
});

export const CreateBaseActivityFormV2 = memo(
  ({
    sessions,
    programs,
    activityTags,
    attachments,
    activityType,
  }: {
    sessions: ActivityFormSession[];
    programs: CreateActivityProgramV2[];
    activityTags: ActivityTag[];
    attachments?: ActivityAttachmentV2[];
    activityType?: string;
  }) => {
    const [selectedSession, setSelectedSession] = useState<ActivityFormSession>();
    const [openAddNewSessionDialog, setOpenAddNewSessionDialog] = useState<{ status: boolean; idx?: number }>({
      status: false,
      idx: undefined,
    });

    const { values } = useFormikContext<ActivityFormValues>();

    const programSuspended = useMemo(
      () => programs.find((p) => p.programId === values.programId)?.dateSuspended !== null ?? false,
      [programs, values.programId]
    );
    return (
      <Box display='flex' flex='1 1 auto' flexDirection='column' padding={4} height={1}>
        {programSuspended ? <ActivityInSuspendedProgramWarningLabelV2 /> : null}

        <Typography variant='h4' component='h1' display='inline'>
          {'Activity'}
        </Typography>

        <BasicInfoSection programs={programs} />
        <VisibilitySection />
        <ActivityTypeSection activityType={activityType} />
        <RosterSettingsSection />
        <ReportingSection />
        <ScheduleSection
          sessions={sessions}
          setOpenAddNewSessionDialog={setOpenAddNewSessionDialog}
          setSelectedSession={setSelectedSession}
        />
        <AddNewSessionDialogV2
          open={openAddNewSessionDialog.status}
          idx={openAddNewSessionDialog.idx}
          session={selectedSession}
          close={() => {
            setOpenAddNewSessionDialog({ status: false });
            setSelectedSession(undefined);
          }}
        />
        <LocationSection />
        <TagsSection activityTags={activityTags} />
        <AttachmentsSection attachments={attachments} />
      </Box>
    );
  }
);
