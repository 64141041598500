import { gql } from '@apollo/client';



export const EMBEDDED_WIDGET_GET_REPORT_TYPES = gql`
  query AdminConsoleWidgetsGetReportTypes {
    vm {
      reportTypes {
        ...AdminConsoleWidgetsReportType
      }
    }   
  }

  fragment AdminConsoleWidgetsReportType on VOLUNTEER_ReportTypeType {
    reportTypeId
    name
    dateDeactivated
    items {
      ... on VOLUNTEER_ReportTypeFieldType {
        reportTypeItemId
        order
        optional
        dateRemoved
        dateAdded
        field { 
            ... on VOLUNTEER_NumericFieldType {
            fieldId
            name
            description
            isHero
            }
        }
      }
    }
  }
`;


export const EMBEDDED_WIDGET_GET_ACTIVITY_TYPES = gql`
  query AdminConsoleWidgetsGetActivityTypes {
    vm {
      activities {
        activityId
        name
        program {
            ... on VOLUNTEER_ProgramType {
                programId
                name
                dateCreated
                dateSuspended
                dateDeleted
                }
            }   
        }
    }
}
`;








