import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';

export type CompletedTaskSubmissionData = {
  completedTaskId?: string;
  taskId: string;
  userId: string;
  taskFieldValues?: any[];
};

const useSaveCompletedTask = () => {
  return useEndpointFetch<CompletedTaskSubmissionData>('/vm/volunteer/profile/completed-task/save');
};

export const useMyTasksActions = () => {
  const saveCompletedTask = useSaveCompletedTask();
  const { setSnackbar } = useSnackbar();

  const runSaveCompletedTask = ({
    completedTaskId,
    taskId,
    userId,
    taskFieldValues,
    handleSuccess,
  }: {
    completedTaskId?: string;
    taskId: string;
    userId: string;
    taskFieldValues?: any[];
    handleSuccess: () => void;
  }) => {
    saveCompletedTask
      .run({
        completedTaskId,
        userId,
        taskId,
        taskFieldValues,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save task',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Task saved',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save task',
          variant: 'error',
        })
      );
  };

  return {
    runSaveCompletedTask
  };
};
