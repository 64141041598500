import { FugFile, UploadedFile } from '@campfire/file-upload-gallery';
import { unpackToDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import {
  TaskFormikFieldAddressType,
  TaskFormikMultipleChoiceType,
  TaskFormikQuizType,
} from '../../../inputs/task-fields/TaskFormikFields';
import { TaskFormField } from './__generated__/TaskFormField';
import {
  TaskFormFieldValue,
  TaskFormFieldValue_VOLUNTEER_CheckboxTaskFieldValueType as CheckboxTaskFieldValue,
  TaskFormFieldValue_VOLUNTEER_DropdownTaskFieldValueType as DropdownTaskFieldValue,
  TaskFormFieldValue_VOLUNTEER_AttachmentTaskFieldValueType as ActtachmentTaskFieldValue,
  TaskFormFieldValue_VOLUNTEER_MultipleChoiceTaskFieldValueType as MultipleChoiceTaskFieldValue,
  TaskFormFieldValue_VOLUNTEER_QuizTaskFieldValueType as QuizTaskFieldValue,
  TaskFormFieldValue_VOLUNTEER_AddressTaskFieldValueType as AddressTaskFieldValue,
  TaskFormFieldValue_VOLUNTEER_DateTaskFieldValueType as DateTaskFieldValue,
  TaskFormFieldValue_VOLUNTEER_ShortTextTaskFieldValueType as ShortTextTaskFieldValueType,
  TaskFormFieldValue_VOLUNTEER_LongTextTaskFieldValueType as LongTextTaskFieldValueType,
  TaskFormFieldValue_VOLUNTEER_EmailTaskFieldValueType as EmailTaskFieldValueType,
  TaskFormFieldValue_VOLUNTEER_NumberTaskFieldValueType as NumberTaskFieldValueType,
  TaskFormFieldValue_VOLUNTEER_PhoneTaskFieldValueType as PhoneTaskFieldValueType,
  TaskFormFieldValue_VOLUNTEER_SignatureTaskFieldValueType as SignatureTaskFieldValueType,
} from './__generated__/TaskFormFieldValue';
import { TaskFormItem } from './__generated__/TaskFormItem';

export type TaskFormAttachmentField = {
  attachments: UploadedFile[];
  addedAttachments: FugFile[];
  attachmentCreationTokens: string[];
  attachmentNames: string[];
  removedAttachmentIds: string[];
};

export type TaskFormFieldValueType =
  | null
  | string
  | Array<string>
  | Array<UploadedFile>
  | Array<FugFile>
  | TaskFormikFieldAddressType
  | TaskFormikMultipleChoiceType
  | TaskFormikQuizType
  | number
  | DateTime
  | File
  | TaskFormAttachmentField;

export type TaskFormValuesType = {
  [fieldItemId: string]: TaskFormFieldValueType;
};

function getInitialFormikValueForField(
  field: TaskFormField,
  initialValue?: TaskFormFieldValue
): TaskFormFieldValueType {
  if (field.__typename === 'VOLUNTEER_AttachmentTaskFieldType') {
    const attachments = initialValue
      ? (initialValue as ActtachmentTaskFieldValue).attachments.map((attachment) => {
          return { url: attachment.url, fileId: attachment.attachmentId, name: attachment.name };
        })
      : [];
    return {
      attachments,
      addedAttachments: [],
      attachmentCreationTokens: [],
      removedAttachmentIds: [],
      attachmentNames: [],
    };
  }
  if (field.__typename === 'VOLUNTEER_DropdownTaskFieldType') {
    return initialValue ? (initialValue as DropdownTaskFieldValue).dropdownTaskFieldOption.dropdownFieldOptionId : '';
  }
  if (field.__typename === 'VOLUNTEER_CheckboxTaskFieldType') {
    return initialValue
      ? (initialValue as CheckboxTaskFieldValue).checkboxTaskFieldOptions.map((option) => option.checkboxFieldOptionId)
      : [];
  }
  if (field.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldType') {
    if (initialValue) {
      return {
        multipleChoiceOptionId: (initialValue as MultipleChoiceTaskFieldValue).multipleChoiceTaskFieldOption
          .multipleChoiceFieldOptionId,
        otherText: (initialValue as MultipleChoiceTaskFieldValue).otherText || '',
        otherOptionSelected: (initialValue as MultipleChoiceTaskFieldValue).multipleChoiceTaskFieldOption.allowText,
      };
    }
    return {
      multipleChoiceOptionId: '',
      otherText: '',
      otherOptionSelected: false,
    };
  }
  if (field.__typename === 'VOLUNTEER_QuizTaskFieldType') {
    if (!initialValue) {
      return {
        quizTaskFieldOptionId: '',
        otherText: '',
        otherTaskFieldOptionSelected: false,
        allowRetry: field.allowRetry,
        isCorrect: false,
      };
    }
    const value = initialValue as QuizTaskFieldValue;
    return {
      otherText: value.otherText || '',
      quizTaskFieldOptionId: value.quizTaskFieldOption.quizTaskFieldOptionId,
      otherTaskFieldOptionSelected: value.quizTaskFieldOption.allowText,
      allowRetry: field.allowRetry,
      isCorrect:
        field.quizTaskFieldCorrect?.quizTaskFieldOption.quizTaskFieldOptionId ===
        value.quizTaskFieldOption.quizTaskFieldOptionId,
    };
  }
  if (field.__typename === 'VOLUNTEER_AddressTaskFieldType') {
    if (!initialValue) {
      return {
        addressOverride: false,
        address: {
          placesAddress: undefined,
          manualAddress: {
            lineOne: '',
            lineTwo: '',
            suburb: '',
            state: '',
            postcode: '',
            country: '',
          },
        },
      };
    }
    const value = initialValue as AddressTaskFieldValue;
    return {
      addressOverride: false,
      address: {
        placesAddress: value.address.placesAddress
          ? {
              ...value.address.placesAddress,
              latitude: value.address.placesAddress.latLong.lat,
              longitude: value.address.placesAddress.latLong.long,
            }
          : undefined,
        manualAddress: {
          lineOne: value.address.manualAddress?.lineOne || '',
          lineTwo: value.address.manualAddress?.lineTwo || '',
          suburb: value.address.manualAddress?.suburb || '',
          state: value.address.manualAddress?.state || '',
          postcode: value.address.manualAddress?.postcode || '',
          country: value.address.manualAddress?.country || '',
        },
      },
    };
  }
  if (field.__typename === 'VOLUNTEER_DateTaskFieldType') {
    return initialValue ? unpackToDate((initialValue as DateTaskFieldValue).dateValue) : null;
  }
  if (field.__typename === 'VOLUNTEER_ShortTextTaskFieldType') {
    return initialValue ? (initialValue as ShortTextTaskFieldValueType).textValue : '';
  }
  if (field.__typename === 'VOLUNTEER_LongTextTaskFieldType') {
    return initialValue ? (initialValue as LongTextTaskFieldValueType).textValue : '';
  }
  if (field.__typename === 'VOLUNTEER_EmailTaskFieldType') {
    return initialValue ? (initialValue as EmailTaskFieldValueType).email : '';
  }
  if (field.__typename === 'VOLUNTEER_NumberTaskFieldType') {
    return initialValue ? (initialValue as NumberTaskFieldValueType).numericValue : '';
  }
  if (field.__typename === 'VOLUNTEER_PhoneTaskFieldType') {
    return initialValue ? (initialValue as PhoneTaskFieldValueType).contactNumber : '';
  }
  if (field.__typename === 'VOLUNTEER_SignatureTaskFieldType') {
    return (initialValue as SignatureTaskFieldValueType)?.signatureUrl;
  }
  return '';
}

function getInitialFormikFieldValueForFieldValuePair(
  field: TaskFormField,
  taskFieldValue: TaskFormFieldValue
): TaskFormFieldValueType {
  if (
    field.__typename === 'VOLUNTEER_DropdownTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_DropdownTaskFieldValueType'
  ) {
    return taskFieldValue.dropdownTaskFieldOption.dropdownFieldOptionId;
  }

  if (
    field.__typename === 'VOLUNTEER_CheckboxTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_CheckboxTaskFieldValueType'
  ) {
    return taskFieldValue.checkboxTaskFieldOptions.map((checkbox) => checkbox.checkboxFieldOptionId);
  }

  if (
    field.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldValueType'
  ) {
    return {
      multipleChoiceOptionId: taskFieldValue.multipleChoiceTaskFieldOption.multipleChoiceFieldOptionId,
      otherText: taskFieldValue.otherText ?? '',
      otherOptionSelected: false,
    };
  }
  if (
    field.__typename === 'VOLUNTEER_QuizTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_QuizTaskFieldValueType'
  ) {
    return {
      quizTaskFieldOptionId: taskFieldValue.quizTaskFieldOption.quizTaskFieldOptionId,
      otherText: taskFieldValue.otherText ?? '',
      otherTaskFieldOptionSelected: false,
      allowRetry: field.allowRetry,
      isCorrect: false,
    };
  }

  if (
    field.__typename === 'VOLUNTEER_AttachmentTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_AttachmentTaskFieldValueType'
  ) {
    const uploadedFiles: UploadedFile[] = taskFieldValue.attachments.map((attachment) => {
      return { url: attachment.url, fileId: attachment.attachmentId, name: attachment.name };
    });
    const addedAttachments: FugFile[] = [];
    const attachmentCreationTokens: string[] = [];
    const attachmentNames: string[] = [];
    const removedAttachmentIds: string[] = [];

    return {
      attachments: uploadedFiles,
      addedAttachments,
      attachmentCreationTokens,
      attachmentNames,
      removedAttachmentIds,
    };
  }

  if (
    field.__typename === 'VOLUNTEER_AddressTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_AddressTaskFieldValueType'
  ) {
    return {
      address: {
        manualAddress: taskFieldValue.address.manualAddress
          ? { ...taskFieldValue.address.manualAddress, lineTwo: taskFieldValue.address.manualAddress.lineTwo ?? '' }
          : { lineOne: '', lineTwo: '', suburb: '', state: '', postcode: '', country: '' },
        placesAddress: taskFieldValue.address.placesAddress
          ? {
              formatted: taskFieldValue.address.placesAddress.formatted,
              description: taskFieldValue.address.placesAddress.description,
              placesId: taskFieldValue.address.placesAddress.placesId ?? '',
              latitude: (taskFieldValue.address.placesAddress.latLong.lat as unknown) as string,
              longitude: (taskFieldValue.address.placesAddress.latLong.long as unknown) as string,
            }
          : undefined,
      },
      addressOverride: taskFieldValue.address.manualAddress !== null,
    };
  }

  if (
    field.__typename === 'VOLUNTEER_DateTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_DateTaskFieldValueType'
  ) {
    return unpackToDate(taskFieldValue.dateValue);
  }

  if (
    field.__typename === 'VOLUNTEER_EmailTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_EmailTaskFieldValueType'
  ) {
    return taskFieldValue.email;
  }

  if (
    field.__typename === 'VOLUNTEER_NumberTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_NumberTaskFieldValueType'
  ) {
    return taskFieldValue.numericValue;
  }

  if (
    field.__typename === 'VOLUNTEER_PhoneTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_PhoneTaskFieldValueType'
  ) {
    return taskFieldValue.contactNumber;
  }

  if (
    (field.__typename === 'VOLUNTEER_LongTextTaskFieldType' &&
      taskFieldValue.__typename === 'VOLUNTEER_LongTextTaskFieldValueType') ||
    (field.__typename === 'VOLUNTEER_ShortTextTaskFieldType' &&
      taskFieldValue.__typename === 'VOLUNTEER_ShortTextTaskFieldValueType')
  ) {
    return taskFieldValue.textValue;
  }

  if (
    field.__typename === 'VOLUNTEER_SignatureTaskFieldType' &&
    taskFieldValue.__typename === 'VOLUNTEER_SignatureTaskFieldValueType'
  ) {
    return taskFieldValue.signatureUrl;
  }

  throw Error('nah');
}

export function initTaskFormFormikValues(
  taskItems: Array<TaskFormItem>,
  taskItemValues?: { [key: string]: TaskFormFieldValue }
) {
  const values: TaskFormValuesType = {};
  taskItems.forEach((taskItem) => {
    if (taskItem.__typename === 'VOLUNTEER_TaskItemFieldType') {
      values[taskItem.field.taskFieldId] = getInitialFormikValueForField(
        taskItem.field,
        taskItemValues ? taskItemValues[taskItem.field.taskFieldId] : undefined
      );
    }
  });
  return values;
}

export function initCompletedTaskFormFormikValues(
  taskItems: Array<TaskFormItem>,
  taskFieldValues: Array<TaskFormFieldValue>
) {
  const values: TaskFormValuesType = {};
  taskItems.forEach((taskItem) => {
    if (taskItem.__typename !== 'VOLUNTEER_TaskItemFieldType') return;

    const taskFieldValue = taskFieldValues.find((x) => x.field.taskFieldId === taskItem.field.taskFieldId);

    /**
     * DateTaskField needs to be forced to initialise to `null` in the case that there is no FieldValue.
     * If we leave it as undefined, the internals of DateTaskField (down at the KeyboardDatePicker level) will initialise the date
     * to TODAYs date within the input but that won't be reflected in the Formik.values so submission breaks.
     */
    if (taskItem.field.__typename === 'VOLUNTEER_DateTaskFieldType') {
      values[taskItem.field.taskFieldId] = taskFieldValue
        ? getInitialFormikFieldValueForFieldValuePair(taskItem.field, taskFieldValue)
        : null;
      return;
    }

    /**
     * Checkbox needs to be an empty array to begin with due to the behaviour of the CheckboxTaskField component
     */
    if (taskItem.field.__typename === 'VOLUNTEER_CheckboxTaskFieldType') {
      values[taskItem.field.taskFieldId] = taskFieldValue
        ? getInitialFormikFieldValueForFieldValuePair(taskItem.field, taskFieldValue)
        : [];
      return;
    }

    /**
     * Multiple choice field needs to initialise as an object of the correct shape
     */
    if (taskItem.field.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldType') {
      values[taskItem.field.taskFieldId] = taskFieldValue
        ? getInitialFormikFieldValueForFieldValuePair(taskItem.field, taskFieldValue)
        : {
            multipleChoiceOptionId: '',
            otherText: '',
            otherOptionSelected: false,
          };
    }

    /**
     * Quiz field needs to initialise as an object of the correct shape
     */
    if (taskItem.field.__typename === 'VOLUNTEER_QuizTaskFieldType') {
      values[taskItem.field.taskFieldId] = taskFieldValue
        ? getInitialFormikFieldValueForFieldValuePair(taskItem.field, taskFieldValue)
        : {
            quizTaskFieldOptionId: '',
            otherText: '',
            otherTaskFieldOptionSelected: false,
            allowRetry: taskItem.field.allowRetry,
            isCorrect: false,
          };
    }

    /**
     * Attachments field must be initialised as empty arrays
     */
    if (taskItem.field.__typename === 'VOLUNTEER_AttachmentTaskFieldType') {
      values[taskItem.field.taskFieldId] = taskFieldValue
        ? getInitialFormikFieldValueForFieldValuePair(taskItem.field, taskFieldValue)
        : {
            attachments: [],
            addedAttachments: [],
            attachmentCreationTokens: [],
            attachmentNames: [],
            removedAttachmentIds: [],
          };
    }

    /**
     * Address field must be initialised
     */
    if (taskItem.field.__typename === 'VOLUNTEER_AddressTaskFieldType') {
      values[taskItem.field.taskFieldId] = taskFieldValue
        ? getInitialFormikFieldValueForFieldValuePair(taskItem.field, taskFieldValue)
        : {
            address: {
              manualAddress: { lineOne: '', lineTwo: '', suburb: '', state: '', postcode: '', country: '' },
              placesAddress: undefined,
            },
            addressOverride: false,
          };
    }

    if (!taskFieldValue) return;

    values[taskItem.field.taskFieldId] = getInitialFormikFieldValueForFieldValuePair(taskItem.field, taskFieldValue);
  });
  return values;
}
