import classNames from 'classnames';
import { HoverText } from '@campfire/hover-link';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, Typography, ButtonBase } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Close, ExpandMore, FolderOpenOutlined, TocOutlined } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import React, { useState, useRef, useMemo } from 'react';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useAdminConsoleActions } from '../../../admin-console-actions';
import {
  IncidentCategoryGroupType,
  IncidentCategoryType,
  findGroup,
  findCategory,
  getBreadcrumbsForGroup,
  getBreadcrumbsForCategory,
  CategoriesSectionContent,
  IncidentDetailType,
  AllIncidentCategoryType,
} from '../admin-console-incident-report-model';
import { AddCategoryGroupDialog } from './dialogs/AddCategoryGroupDialog';
import { AddCategoryDialog } from './dialogs/AddCategoryDialog';
import { AddCategoryDetailDialog } from './dialogs/AddCategoryDetailDialog';
import { CategoriesSideMenuChildGroup } from './components/CategoriesSideMenuChildGroup';
import { AddNewOptionsModal } from './dialogs/AddNewOptionsModal';
import { IncidentCategoriesBreadCrumbs } from './components/IncidentCategoriesBreadcrumbs';
import { RemoveCategoryConfirmationDialog } from './dialogs/RemoveCategoryConfirmationDialog';
import { EditCategoryDialog } from './dialogs/EditCategoryDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollableGrid: {
      paddingTop: 16,
      paddingBottom: 16,
      minHeight: 480,
      maxHeight: 560,
      overflowY: 'scroll',
    },
    greyBackground: {
      background: '#f4f7f8',
    },
    editRow: {
      paddingLeft: 56,
      paddingRight: 56,
      paddingTop: 8,
      paddingBottom: 8,
      borderTop: `1px solid ${theme.color.grey.border}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:hover $deleteIcon': {
        color: theme.palette.action.disabled,
        '&:hover': {
          color: theme.palette.error.main,
        },
      },
    },
    deleteIcon: {
      color: 'transparent',
    },
    hoverText: {
      fontWeight: 600,
    },
  })
);
type IncidentCategorySectionType = {
  incidentCategoriesRoot: IncidentCategoryGroupType;
  refetchCategories?: () => void;
};

const RowItemActions = ({ onRemoveIconClick }: { onRemoveIconClick?: () => void }) => {
  const classes = useStyles();
  return (
    <Grid item container xs={2} direction='row' justify='flex-end' style={{ padding: 0 }}>
      <ButtonBase
        style={{ padding: 8 }}
        onClick={() => {
          if (onRemoveIconClick) onRemoveIconClick();
        }}
      >
        <Close fontSize='small' className={classes.deleteIcon} />
      </ButtonBase>
    </Grid>
  );
};
const IncidentCategoryGroupRow = ({
  childGroup,
  setSelectedChildId,
  setChildToRemove,
}: {
  childGroup: IncidentCategoryGroupType;
  setSelectedChildId: (x: string | undefined) => void;
  setChildToRemove: (x: AllIncidentCategoryType | undefined) => void;
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} container className={classes.editRow}>
      <Grid item xs={10}>
        <HoverText onClick={() => setSelectedChildId(childGroup.incidentCategoryGroupId)} className={classes.hoverText}>
          <Box component='span' display='flex' alignContent='center' alignItems='center'>
            <FolderOpenOutlined style={{ marginRight: 8 }} fontSize='small' color='disabled' />
            {`${childGroup.label} (${childGroup.childGroups.length + childGroup.categories.length} options)`}
          </Box>
        </HoverText>
      </Grid>
      <RowItemActions onRemoveIconClick={() => setChildToRemove(childGroup)} />
    </Grid>
  );
};
const IncidentCategoryRow = ({
  category,
  setSelectedChildId,
  setChildToRemove,
}: {
  category: IncidentCategoryType;
  setSelectedChildId: (x: string | undefined) => void;
  setChildToRemove: (x: AllIncidentCategoryType | undefined) => void;
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} container className={classes.editRow}>
      <Grid item xs={10}>
        <HoverText onClick={() => setSelectedChildId(category.incidentCategoryId)} className={classes.hoverText}>
          <Box component='span' display='flex' alignContent='center' alignItems='center'>
            <TocOutlined style={{ marginRight: 8 }} fontSize='small' color='disabled' />
            {`${category.label} (${category.details.length} options)`}
          </Box>
        </HoverText>
      </Grid>
      <RowItemActions onRemoveIconClick={() => setChildToRemove(category)} />
    </Grid>
  );
};

const IncidentDetailRow = (props: {
  detail: IncidentDetailType;
  setChildToRemove: (x: AllIncidentCategoryType | undefined) => void;
  setChildToEdit: (x: AllIncidentCategoryType | undefined) => void;
}) => {
  const { detail, setChildToRemove, setChildToEdit } = props;
  const classes = useStyles();
  return (
    <Grid item xs={12} container className={classes.editRow}>
      <Grid item xs={10}>
        <HoverText
          color='textPrimary'
          hoverColor='primary'
          onClick={() => {
            setChildToEdit(detail);
          }}
          disableUnderline
        >
          {`${detail.label}`}
        </HoverText>
      </Grid>
      <RowItemActions onRemoveIconClick={() => setChildToRemove(detail)} />
    </Grid>
  );
};
const CategoriesSelectedChildRows = (props: {
  selectedChild: IncidentCategoryType | IncidentCategoryGroupType;
  setSelectedChildId: (x: string | undefined) => void;
  setChildToRemove: (x: AllIncidentCategoryType | undefined) => void;
  setChildToEdit: (x: AllIncidentCategoryType | undefined) => void;
}) => {
  const { selectedChild, setSelectedChildId, setChildToRemove, setChildToEdit } = props;

  return selectedChild.__typename === 'VOLUNTEER_IncidentCategoryGroupType' ? (
    <>
      {selectedChild.childGroups.map((childGroup) => (
        <IncidentCategoryGroupRow
          key={childGroup.incidentCategoryGroupId}
          childGroup={childGroup}
          setSelectedChildId={setSelectedChildId}
          setChildToRemove={setChildToRemove}
        />
      ))}
      {selectedChild.categories.map((category) => (
        <IncidentCategoryRow
          key={category.incidentCategoryId}
          category={category}
          setSelectedChildId={setSelectedChildId}
          setChildToRemove={setChildToRemove}
        />
      ))}
    </>
  ) : (
    <>
      {selectedChild.details.map((detail) => (
        <IncidentDetailRow
          setChildToEdit={setChildToEdit}
          key={detail.incidentDetailId}
          detail={detail}
          setChildToRemove={setChildToRemove}
        />
      ))}
    </>
  );
};
export const IncidentCategorySection = ({ incidentCategoriesRoot, refetchCategories }: IncidentCategorySectionType) => {
  const [selectedChildId, setSelectedChildId] = useState<string>();
  const [childToRemove, setChildToRemove] = useState<AllIncidentCategoryType>();
  const [childToEdit, setChildToEdit] = useState<AllIncidentCategoryType>();
  const [openAddActionMenu, setOpenAddActionMenu] = useState<boolean>(false);
  const [openAddCategoryGroupDialog, setOpenAddCategoryGroupDialog] = useState<boolean>(false);
  const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState<boolean>(false);
  const [openAddCategoryDetailDialog, setOpenAddCategoryDetailDialog] = useState<boolean>(false);
  const { runSaveIncidentCategoriesLoading } = useAdminConsoleActions();
  const { theme } = useCampfireTheme();
  const actionMenuAnchorRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const sectionContent = CategoriesSectionContent;

  const selectedChild = useMemo(() => {
    if (!selectedChildId) return undefined;
    const foundGroup = findGroup(incidentCategoriesRoot, selectedChildId);
    if (foundGroup) return foundGroup;
    const foundCategory = findCategory(incidentCategoriesRoot, selectedChildId);
    if (foundCategory) return foundCategory;
    return undefined;
  }, [selectedChildId, incidentCategoriesRoot]);

  const breadcrumbsArray = useMemo(() => {
    if (!selectedChildId) return [incidentCategoriesRoot];
    if (selectedChild?.__typename === 'VOLUNTEER_IncidentCategoryGroupType')
      return getBreadcrumbsForGroup(incidentCategoriesRoot, selectedChildId);
    return getBreadcrumbsForCategory(incidentCategoriesRoot, selectedChildId);
  }, [selectedChildId, selectedChild, incidentCategoriesRoot]);

  return (
    <Box paddingTop={4} paddingBottom={16}>
      <LinearProgressOverlay isLoading={runSaveIncidentCategoriesLoading} />
      <Grid container direction='column'>
        <Grid item xs style={{ paddingBottom: 16 }}>
          <Typography variant='h6' style={{ fontWeight: 600 }}>
            {sectionContent.sectionTitle}
          </Typography>
          <Typography variant={'body2'}>{sectionContent.helperText}</Typography>
        </Grid>

        <Grid item container xs style={{ border: `1px solid ${theme.color.grey.border}` }}>
          <Grid item className={classNames(classes.scrollableGrid, classes.greyBackground)} style={{ width: 300 }}>
            <CategoriesSideMenuChildGroup
              childGroup={incidentCategoriesRoot}
              selectedChildId={selectedChildId}
              setSelectedChildId={setSelectedChildId}
            />
          </Grid>

          <Grid
            item
            container
            xs
            direction='column'
            className={classes.scrollableGrid}
            style={{
              borderLeft: `1px solid ${theme.color.grey.border}`,
            }}
            wrap='nowrap'
          >
            <Grid
              item
              xs
              container
              direction='row'
              style={{
                flex: 0,
                paddingTop: 8,
                paddingBottom: 32,
                paddingLeft: 32,
                paddingRight: 32,
                borderBottom: `1px solid ${theme.color.grey.border}`,
              }}
              justify='space-between'
            >
              <Grid item container xs={8} alignItems='center'>
                <IncidentCategoriesBreadCrumbs
                  breadcrumbsArray={breadcrumbsArray}
                  setSelectedChildId={setSelectedChildId}
                  setChildToEdit={setChildToEdit}
                />
              </Grid>
              <Grid item xs={4} container alignItems='center' justify='flex-end'>
                <TabletButton
                  variant='outlined'
                  color='primary'
                  onClick={() => {
                    setOpenAddActionMenu(true);
                  }}
                  size='small'
                  ref={actionMenuAnchorRef}
                  endIcon={<ExpandMore />}
                >
                  {sectionContent.addButtonText}
                </TabletButton>
                <AddNewOptionsModal
                  open={openAddActionMenu}
                  onClose={() => setOpenAddActionMenu(false)}
                  anchorRef={actionMenuAnchorRef}
                  selectedChildType={selectedChild?.__typename || 'VOLUNTEER_IncidentCategoryGroupType'}
                  openAdddCategoryGroupDialog={() => setOpenAddCategoryGroupDialog(true)}
                  openAddCategoryDialog={() => setOpenAddCategoryDialog(true)}
                  openAddCategoryDetailDialog={() => setOpenAddCategoryDetailDialog(true)}
                />
              </Grid>
            </Grid>

            <Grid item container>
              <CategoriesSelectedChildRows
                selectedChild={selectedChild || incidentCategoriesRoot}
                setSelectedChildId={setSelectedChildId}
                setChildToRemove={setChildToRemove}
                setChildToEdit={setChildToEdit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {openAddCategoryGroupDialog && (
        <AddCategoryGroupDialog
          open={openAddCategoryGroupDialog}
          onClose={() => setOpenAddCategoryGroupDialog(false)}
          incidentCategoriesRoot={incidentCategoriesRoot}
          refetchCategories={refetchCategories}
          parentGroup={(selectedChild as IncidentCategoryGroupType) || incidentCategoriesRoot}
        />
      )}

      {openAddCategoryDialog && (
        <AddCategoryDialog
          open={openAddCategoryDialog}
          onClose={() => setOpenAddCategoryDialog(false)}
          incidentCategoriesRoot={incidentCategoriesRoot}
          refetchCategories={refetchCategories}
          parentGroup={(selectedChild as IncidentCategoryGroupType) || incidentCategoriesRoot}
        />
      )}

      {openAddCategoryDetailDialog && (
        <AddCategoryDetailDialog
          open={openAddCategoryDetailDialog}
          onClose={() => setOpenAddCategoryDetailDialog(false)}
          incidentCategoriesRoot={incidentCategoriesRoot}
          refetchCategories={refetchCategories}
          parentCategory={selectedChild as IncidentCategoryType}
        />
      )}

      {childToRemove ? (
        <RemoveCategoryConfirmationDialog
          childToRemove={childToRemove}
          handleSuccess={() => {
            setChildToRemove(undefined);
            if (refetchCategories) refetchCategories();
          }}
          onClose={() => setChildToRemove(undefined)}
          incidentCategoriesRoot={incidentCategoriesRoot}
        />
      ) : null}

      {childToEdit ? (
        <EditCategoryDialog
          childToEdit={childToEdit}
          onClose={() => setChildToEdit(undefined)}
          incidentCategoriesRoot={incidentCategoriesRoot}
          handleSuccess={() => {
            setChildToEdit(undefined);
            if (refetchCategories) refetchCategories();
          }}
        />
      ) : null}
    </Box>
  );
};
