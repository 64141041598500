import { Box, Grid, Theme, Typography, Switch } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { TemplateSnackbar, SnackBarOptions } from '../../../../../common/snackbars/TemplateSnackbar';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { GET_ADMIN_CONSOLE_BULLETIN_SETTINGS } from './admin-console-bulletin-settings.gql';
import { GetAdminConsoleBulletinSettings } from './__generated__/GetAdminConsoleBulletinSettings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      backgroundColor: '#F9F9F9',
      minHeight: 'calc(100% - 20px)',
    },
    containerPanel: {
      background: 'white',
      paddingTop: '1em',
      paddingBottom: '1.25em',
    },
    heading: {
      paddingBottom: '8px',
      borderBottom: '1px solid #efefef',
    },
    headingTitle: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 900,
      fontSize: 20,
      marginBottom: '1.5rem',
    },
    sectionContainer: {
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
    },
    infoBody: {
      color: theme.alert.blue.dark,
      fontSize: '14px',
      fontWeight: 400,
    },
  })
);

export const AdminConsoleBulletinSettings = () => {
  const [snackbar, setSnackBar] = React.useState<SnackBarOptions>();
  const [bulletinAllowComment, setBulletinAllowComment] = React.useState(false);
  const [bulletinAllowManagerAction, setBulletinAllowManagerAction] = React.useState(false);

  const classes = useStyles();
  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });

  const { data: bulletinSettings, refetch, loading } = useCampfireQuery<GetAdminConsoleBulletinSettings, undefined>(
    GET_ADMIN_CONSOLE_BULLETIN_SETTINGS
  );

  const bulletinAllowCommentRes = bulletinSettings?.vm.bulletinAllowComment;
  const bulletinAllowManagerActionRes = bulletinSettings?.vm.bulletinAllowManagerAction;

  useEffect(() => {
    setBulletinAllowComment(Boolean(bulletinAllowCommentRes));
    setBulletinAllowManagerAction(Boolean(bulletinAllowManagerActionRes));
  }, [bulletinAllowCommentRes, bulletinAllowManagerActionRes]);

  const onChangeAllowManagerAction = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/bulletin/config/allow-manager-action`,
        method: 'post',
        data: {
          value: !bulletinAllowManagerAction,
        },
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'Bulletin settings updated!',
        });
      });

    setBulletinAllowManagerAction(!bulletinAllowManagerAction);
  };
  const onChangeAllowComment = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/bulletin/config/allow-comment`,
        method: 'post',
        data: {
          value: !bulletinAllowComment,
        },
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'Bulletin settings updated!',
        });
      });

    setBulletinAllowComment(!bulletinAllowComment);
  };

  return (
    <Box position='relative' width={1} display='flex' justifyContent='center'>
      <LinearProgressOverlay isLoading={loading} />
      <Box px={8} pt={4} width={1} maxWidth={720}>
        <Box className={classes.heading} py={4}>
          <Typography variant='h2' className={classes.headingTitle}>
            Bulletin Settings
          </Typography>
        </Box>
        <Box className={classes.containerPanel}>
          <Grid container>
            <Grid item sm={6} className={classes.sectionContainer}>
              <Box display='flex'>
                <Switch
                  checked={bulletinAllowManagerAction}
                  onChange={onChangeAllowManagerAction}
                  color='primary'
                  inputProps={{ 'aria-label': 'allow program managers to create, edit bulletins' }}
                />
                <Box ml={1}>
                  <Typography
                    style={{
                      fontSize: '18px',
                      color: '#000000b0',
                      fontWeight: 500,
                      textTransform: 'uppercase',
                      fontVariant: 'all-small-caps',
                    }}
                  >
                    Program Managers can Create, Edit
                  </Typography>
                  <Typography style={{ fontSize: '13px', color: '#808080' }}>
                    Program Managers will be able to create and edit bulletins that are segmented to their program.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={6} className={classes.sectionContainer}>
              <Box display='flex'>
                <Switch
                  checked={bulletinAllowComment}
                  onChange={onChangeAllowComment}
                  color='primary'
                  inputProps={{ 'aria-label': 'allow comments' }}
                />
                <Box ml={1}>
                  <Typography
                    style={{
                      fontSize: '18px',
                      color: '#000000b0',
                      fontWeight: 500,
                      textTransform: 'uppercase',
                      fontVariant: 'all-small-caps',
                    }}
                  >
                    Allow Comments
                  </Typography>
                  <Typography style={{ fontSize: '13px', color: '#808080' }}>
                    Your volunteers will be able to comment and reply to your bulletins, and will be visible to all
                    other volunteers.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {snackbar && <TemplateSnackbar {...snackbar} onClose={() => setSnackBar(undefined)} />}
      </Box>
    </Box>
  );
};
