import { Box, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/styles';
import React, { memo, useEffect } from 'react';
import { ArrayParam, useQueryParam } from 'use-query-params';
import { dayFilters, DAY_FILTER_URL_PARAM_KEY, Filters } from '../../../../../common/filter-fields/filters';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

type DayFilterSelectFieldProps = {
  selectedFilters: Filters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    roundBox: {
      borderRadius: '50%',
      color: 'textPrimary',
      background: theme.palette.grey[200],
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '4px',
      '&:hover': {
        background: theme.palette.grey[400],
        color: 'textPrimary',
      },
      [theme.breakpoints.up('xs')]: {
        width: 35,
        height: 35,
      },
      [theme.breakpoints.up('md')]: {
        width: 40,
        height: 40,
      },
    },
  })
);

const DayFilterSelectFieldV2 = memo(({ selectedFilters, setSelectedFilters }: DayFilterSelectFieldProps) => {
  const { theme } = useCampfireTheme();
  const classes = useStyles(theme);
  const { selectedDayFilters } = selectedFilters;
  const [urlParam, setUrlParam] = useQueryParam<Array<string> | undefined>(DAY_FILTER_URL_PARAM_KEY, ArrayParam);
  const isDaySelected = (value: string) => selectedDayFilters.value.indexOf(value) > -1;

  const handleChangeDays = (value: Array<string>) => {
    setSelectedFilters((prev) => ({
      ...prev,
      selectedDayFilters: {
        isActive: value.length > 0,
        urlParamKey: DAY_FILTER_URL_PARAM_KEY,
        value,
      },
    }));
  };

  useEffect(() => {
    if (!urlParam) {
      handleChangeDays([]);
      return;
    }
    handleChangeDays(urlParam);
  }, [urlParam]);

  return (
    <Box style={{ display: 'flex', flexDirection: 'row' }}>
      {dayFilters.map((filter) => (
        <Box
          key={filter.value}
          className={classes.roundBox}
          style={{
            background: isDaySelected(filter.value) ? theme.color.grey.neutral300 : theme.palette.grey[200],
            color: isDaySelected(filter.value) ? 'white' : 'inherit',
          }}
          onClick={() => {
            if (!urlParam) {
              setUrlParam([filter.value]);
              return;
            }
            if (urlParam?.includes(filter.value)) {
              setUrlParam(urlParam.filter((val) => val !== filter.value));
            } else {
              setUrlParam([...urlParam, filter.value]);
            }
          }}
        >
          <Typography
            variant='body2'
            style={isDaySelected(filter.value) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
          >
            {filter.label.charAt(0)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
});

type DayFilterSelectFieldV3Props = {
  value: string[];
  onChange: (newValue: string[]) => void;
};

const DayFilterSelectFieldV3 = memo(({ value, onChange }: DayFilterSelectFieldV3Props) => {
  const { theme } = useCampfireTheme();
  const classes = useStyles(theme);
  const isDaySelected = (day: string) => value.indexOf(day) > -1;

  const handleChangeDay = (day: string) => {
    if (value.includes(day)) {
      onChange(value.filter((dayValue) => dayValue !== day));
    } else {
      onChange(value.concat(day));
    }
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'row' }}>
      {dayFilters.map((filter) => (
        <Box
          key={filter.value}
          className={classes.roundBox}
          style={{
            background: isDaySelected(filter.value) ? theme.color.grey.neutral300 : theme.palette.grey[200],
            color: isDaySelected(filter.value) ? 'white' : 'inherit',
          }}
          onClick={() => handleChangeDay(filter.value)}
        >
          <Typography
            variant='body2'
            style={isDaySelected(filter.value) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
          >
            {filter.label.charAt(0)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
});

export { DayFilterSelectFieldV2, DayFilterSelectFieldV3 };
