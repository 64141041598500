import {
  Box,
  ButtonBase,
  Collapse,
  Grid,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp, Search as SearchIcon } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { useFormikContext } from 'formik';
import { uniqBy } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ManageReportTypeActivity } from './__generated__/ManageReportTypeActivity';
import { ManageReportTypeBaseReportType } from './__generated__/ManageReportTypeBaseReportType';
import { ReportTypeFormValues } from './manage-report-type-model';
import { useManageReportTypeContext } from './ManageReportTypeContext';

const useInputStyles = makeStyles(() =>
  createStyles({
    input: {
      padding: 9,
    },
  })
);

const useStyles = () => {
  const { theme } = useCampfireTheme();
  return makeStyles({
    selectedItem: {
      padding: 8,
      backgroundColor: fade(theme.palette.grey[400], 0.7),
    },
    listItem: {
      padding: 8,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: fade(theme.palette.grey[400], 0.4),
      },
    },
    searchField: {
      [`& fieldset`]: {
        borderRadius: 32,
      },
      '& .Mui-focused $searchIcon': {
        color: theme.palette.action.active,
      },
    },
    searchIcon: {
      color: theme.palette.action.disabled,
      fontSize: 19,
    },
    sortBtn: {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    sortBtnSelected: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    sortBtnLeft: {
      borderBottomLeftRadius: 5,
      borderTopLeftRadius: 5,
    },
    sortBtnRight: {
      borderBottomRightRadius: 5,
      borderTopRightRadius: 5,
    },
    activityListItem: {
      padding: 8,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      paddingLeft: 16,
      paddingRight: 16,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    activityReportTypeList: {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    activityReportTypeListItem: {
      padding: 4,
      paddingLeft: 16,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: fade(theme.palette.grey[400], 0.4),
      },
    },
  })();
};

const LiveModelListItem = () => {
  const classes = useStyles();
  const formik = useFormikContext<ReportTypeFormValues>();
  const name = formik.values?.reportTypeName || 'Report Type Name';
  return (
    <ListItem style={{ padding: 0 }}>
      <Box width={1} className={classes.selectedItem}>
        <Box width={1} paddingX={3}>
          <Typography variant='subtitle1' display='inline'>
            {name}
          </Typography>
          <Typography variant='subtitle2' color='textSecondary'>
            {`New report type`}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};
interface ActivityListItemProps {
  activity: ManageReportTypeActivity;
  selectedReportTypeId?: string;
  setSelectedReportTypeId: (x: string) => void;
}
const ActivityListItem = memo((props: ActivityListItemProps) => {
  const { activity, selectedReportTypeId, setSelectedReportTypeId } = props;
  const { theme } = useCampfireTheme();
  const [displayReportTypes, setDisplayReportTypes] = useState(false);

  const classes = useStyles();
  return (
    <ListItem key={activity.activityId} style={{ padding: 0 }}>
      <Grid container direction='column'>
        <Grid
          item
          container
          alignItems='center'
          justify='space-between'
          xs={12}
          onClick={() => setDisplayReportTypes(!displayReportTypes)}
          className={classes.activityListItem}
        >
          <Typography variant='subtitle1' display='inline'>
            {activity.name}
          </Typography>
          {displayReportTypes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Grid>
        <Grid item xs={12} className={classes.activityReportTypeList}>
          <Collapse in={displayReportTypes} timeout={0}>
            {uniqBy(
              activity.sessions.map((session) => session.reportType),
              'reportTypeId'
            ).map((reportType) => (
              <Grid
                item
                xs={12}
                className={classes.activityReportTypeListItem}
                onClick={() => setSelectedReportTypeId(reportType?.reportTypeId || '')}
                key={reportType?.reportTypeId}
              >
                <Typography
                  variant='caption'
                  style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                    color:
                      selectedReportTypeId === reportType?.reportTypeId
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                  }}
                  display='inline'
                >
                  {reportType?.name}
                </Typography>
              </Grid>
            ))}
          </Collapse>
        </Grid>
      </Grid>
    </ListItem>
  );
});

interface ReportTypeListItemProps {
  reportType: ManageReportTypeBaseReportType;
  selectedReportTypeId?: string;
  setSelectedReportTypeId: (x: string) => void;
}
const ReportTypeListItem = memo((props: ReportTypeListItemProps) => {
  const { reportType, selectedReportTypeId, setSelectedReportTypeId } = props;
  const classes = useStyles();
  return (
    <ListItem
      key={reportType.reportTypeId}
      onClick={() => setSelectedReportTypeId(reportType.reportTypeId)}
      style={{ padding: 0 }}
    >
      <Box
        width={1}
        className={selectedReportTypeId === reportType?.reportTypeId ? classes.selectedItem : classes.listItem}
      >
        <Box width={1} paddingX={3}>
          <Typography variant='subtitle1' display='inline'>
            {reportType.name}
          </Typography>
          <Typography variant='subtitle2' color='textSecondary'>
            {`Used in ${reportType.activities.length} activities`}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
});

const ReportTypeSideNav = () => {
  const {
    selectedReportTypeId,
    setSelectedReportTypeId,
    activities,
    creationModeOn,
    reportTypes,
  } = useManageReportTypeContext();

  const [searchInput, setSearchInput] = useState<string>();
  const [sortVariant, setSortVariant] = useState<'report-type' | 'activity'>('report-type');
  const inputClasses = useInputStyles();
  const classes = useStyles();
  useEffect(() => {
    if (creationModeOn) setSortVariant('report-type');
  }, [creationModeOn]);

  const filteredActivitiesBySearchInput = useMemo(() => {
    return activities.filter((activity) =>
      searchInput ? activity.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()) : true
    );
  }, [activities, searchInput]);

  const filteredReportTypesBySearchInput = useMemo(() => {
    return reportTypes.filter((reportType) =>
      searchInput ? reportType.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()) : true
    );
  }, [reportTypes, searchInput]);

  return (
    <Grid container direction='column' spacing={1} style={{ flex: 1 }}>
      <Grid container style={{ padding: 24 }} spacing={2}>
        <Grid item xs={12}>
          <TextField
            className={classes.searchField}
            variant='outlined'
            placeholder={`Search by ${sortVariant === 'activity' ? 'activity' : 'report type'}`}
            InputProps={{
              classes: inputClasses,
              endAdornment: (
                <InputAdornment position='end' disablePointerEvents>
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchInput(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid
            item
            container
            justify='center'
            xs={6}
            className={classnames(
              classes.sortBtnLeft,
              sortVariant === 'report-type' ? classes.sortBtnSelected : classes.sortBtn
            )}
          >
            <ButtonBase style={{ padding: 4 }} onClick={() => setSortVariant('report-type')}>
              <Typography variant='caption' color='inherit'>
                {'View by report type'}
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid
            item
            container
            justify='center'
            xs={6}
            className={classnames(
              classes.sortBtnRight,
              sortVariant === 'activity' ? classes.sortBtnSelected : classes.sortBtn
            )}
          >
            <ButtonBase
              style={{ padding: 4 }}
              onClick={() => {
                setSortVariant('activity');
              }}
            >
              <Typography variant='caption' color='inherit'>
                {'View by activity'}
              </Typography>
            </ButtonBase>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2' color='textSecondary'>
            {sortVariant === 'report-type'
              ? `${filteredReportTypesBySearchInput.length} report types found`
              : `${filteredActivitiesBySearchInput.length} activities found`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {sortVariant && (
          <List>
            {sortVariant === 'report-type' ? (
              <>
                {creationModeOn && <LiveModelListItem />}
                {reportTypes
                  .filter((reportType) => (searchInput ? reportType.name.includes(searchInput) : true))
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((reportType) => (
                    <ReportTypeListItem
                      key={reportType.reportTypeId}
                      reportType={reportType}
                      selectedReportTypeId={selectedReportTypeId}
                      setSelectedReportTypeId={setSelectedReportTypeId}
                    />
                  ))}
              </>
            ) : sortVariant === 'activity' ? (
              filteredActivitiesBySearchInput.map((activity) => (
                <ActivityListItem
                  key={activity.activityId}
                  activity={activity}
                  selectedReportTypeId={selectedReportTypeId}
                  setSelectedReportTypeId={setSelectedReportTypeId}
                />
              ))
            ) : null}
          </List>
        )}
      </Grid>
    </Grid>
  );
};

export { ReportTypeSideNav };
