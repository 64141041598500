import { gql } from '@apollo/client';
import { ActivitySessionFragment } from './SessionSelect/SessionFragment.gql';
import { RECURRENCE_FRAGMENT } from '../recurrence/recurrence-model.gql';

export const ActivityCommonFragment = gql`
  fragment AvailabilityCenterActivityFragment on VOLUNTEER_ActivityType {
    activityId
    name
    description
    startTime
    endTime
    startDate
    endDate
    isActive
    isHidden
    program {
      programId
      name
    }
    cancelledActivities {
      activityDate
    }
    activityLocation {
      placesAddress {
        description
        formatted
        latLong {
          lat
          long
        }
      }
    }
    nextX(x: 1)
  }
`;

export const ActivityRecurringFragment = gql`
  fragment AvailablityCenterRecurringFragment on VOLUNTEER_RecurringActivityType {
    schedule {
      recurrences {
        ...Recurrence
      }
    }
  }
  ${RECURRENCE_FRAGMENT}
`;

export const ActivityFragment = gql`
  fragment AvailabilityCenterActivityType on VOLUNTEER_ActivityType {
    ...AvailabilityCenterActivityFragment
    sessions {
      ...AvailabilityCenterSession
    }
    ... on VOLUNTEER_NonRecurringActivityType {
      __typename
    }

    ... on VOLUNTEER_RecurringActivityType {
      ...AvailablityCenterRecurringFragment
    }
  }
  ${ActivitySessionFragment}
  ${ActivityRecurringFragment}
`;

export { 
  ActivitySessionFragment,
  RECURRENCE_FRAGMENT
}
