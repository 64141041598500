import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { PasswordField } from '../../../../common/password-field/PasswordField';
import { useApiUrl } from '../../../../global/config/useApiUrl';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';

interface PasswordUpdate {
  oldPassword: string;
  newPassword: string;
}

const PasswordUpdateSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Enter your old password'),
  newPassword: Yup.string().required('Please choose a new password'),
});

interface Props {
  onClose: () => void;
}

const ChangePasswordPanel = (props: Props) => {
  const { onClose } = props;
  const apiUrl = useApiUrl();
  const { setSnackbar } = useSnackbar();
  const [success, setSuccess] = useState(false);
  const { run, isLoading } = useCampfireFetch({
    defer: true,
  });

  function somethingWentWrong() {
    setSnackbar({
      message: 'Unable to update password',
      variant: 'error',
      open: true,
    });
  }

  function greatSuccess() {
    setSnackbar({
      message: 'Password changed',
      variant: 'success',
      open: true,
    });
    setSuccess(true);
  }

  const sendPost = (values: PasswordUpdate) => {
    run({
      method: 'post',
      url: `${apiUrl}/im/user/password/update`,
      data: {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      },
    })
      .then((res: any) => {
        if (res.ok) {
          greatSuccess();
          return;
        }
        somethingWentWrong();
      })
      .catch(() => {
        somethingWentWrong();
      });
  };

  if (success) {
    return (
      <Box
        display='flex'
        flex='1 1 auto'
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        paddingY={4}
        paddingX={3}
      >
        <Box display='flex' flexDirection='column' alignItems='center' alignContent='center'>
          <Typography variant='h6' gutterBottom>
            Password updated
          </Typography>
          <Typography variant='body2' color='textSecondary' gutterBottom>
            Your new password is ready to use the next time you sign in.
          </Typography>

          <Box marginTop={2}>
            <TabletButton
              variant='outlined'
              size='medium'
              onClick={() => {
                onClose();
              }}
            >
              Close this dialog
            </TabletButton>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      direction='column'
      style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 32, paddingBottom: 16, position: 'relative' }}
      alignContent='center'
      alignItems='center'
    >
      <Grid item xs={12} style={{ maxWidth: 480 }}>
        <Formik
          initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
          validationSchema={PasswordUpdateSchema}
          onSubmit={(values) => sendPost(values)}
        >
          <Form>
            <CircularProgressOverlay isLoading={isLoading} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  What is your current password?
                </Typography>
                <PasswordField
                  disabled={isLoading}
                  required
                  fullWidth
                  variant='outlined'
                  name='oldPassword'
                  label='Current Password'
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Choose a new password
                </Typography>
                <PasswordField
                  disabled={isLoading}
                  required
                  fullWidth
                  variant='outlined'
                  name='newPassword'
                  label='New Password'
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid container item xs={12} justify='center' style={{ marginTop: 16 }}>
                <TabletButton size='large' color='primary' variant='contained' type='submit'>
                  Change my password
                </TabletButton>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </Grid>
  );
};

export { ChangePasswordPanel };
