import React from 'react';
import { Box, Typography, ListItem, List, IconButton, Avatar as MuiAvatar, Theme } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { ManagementOverviewHeading } from './ManagementOverviewHeading';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { BorderLinearProgress } from '../../volunteers/incoming-volunteers/IncomingVolunteersList';
import { FallbackAvatar } from '../../../general/user-profile/components/Avatar';
import { ManagementDashboardIncomingVolunteersData_vm_incomingVolunteers_incomingVolunteers as IncomingVolunteerType } from '../__generated__/ManagementDashboardIncomingVolunteersData';

const INCOMING_VOLUNTEERS_PATH = '/management/volunteers/incoming-volunteers';

interface Props {
  volunteers: IncomingVolunteerType[];
  isLoading: boolean;
}

export const IncomingVolunteersList = (props: Props) => {
  const { volunteers, isLoading } = props;
  const { theme } = useCampfireTheme();

  const incomingVolunteers = volunteers
    .map((v) => {
      if (v.source === 'Profile') {
        return {
          queryParam: 'volunteerProfileId',
          id: v.incomingVolunteerId,
          name: `${v.profile?.preferredName} ${v.profile?.lastName}`,
          avatar: v.profile?.avatarUrl || null,
          progress: (v.profile?.percentageComplete || 0) * 100,
        };
      }
      if (v.source === 'Application') {
        return {
          queryParam: 'applicationId',
          id: v.incomingVolunteerId,
          name: v.name,
          avatar: '',
          progress: 0,
        };
      }
      return null;
    })
    .filter((i) => i);

  return (
    <Box display={'grid'} style={{ paddingBottom: '60px' }}>
      <ManagementOverviewHeading heading={'Incoming Volunteers'} viewAllPath={INCOMING_VOLUNTEERS_PATH} />
      <List style={{ width: '100%' }}>
        {isLoading ? (
          Array.from(Array(5)).map(() => (
            <ListItem key={uuidv4()}>
              <IncomingVolunteerListItemSkeleton />
            </ListItem>
          ))
        ) : !isEmpty(incomingVolunteers) ? (
          incomingVolunteers.map((v) =>
            v ? (
              <ListItem key={v.id} disableGutters>
                <IncomingVolunteerListItem
                  volunteerId={v.id}
                  theme={theme}
                  avatarUrl={v.avatar}
                  name={v.name || ''}
                  progress={v.progress}
                  queryParam={v.queryParam}
                />
              </ListItem>
            ) : null
          )
        ) : (
          <Typography
            style={{ textAlign: 'center', color: theme.color.grey.neutral400, fontSize: '15px', paddingTop: '12px' }}
          >
            <span role='img' aria-label='Congratulations!'>
              🎉
            </span>{' '}
            You&#39;re all up to date!
          </Typography>
        )}
      </List>
    </Box>
  );
};

interface IncomingVolunteerListItemProps {
  volunteerId: string;
  theme: Theme;
  avatarUrl: string | null;
  name: string;
  progress: number;
  queryParam: string;
}

const IncomingVolunteerListItem = ({
  volunteerId,
  theme,
  avatarUrl,
  name,
  progress,
  queryParam,
}: IncomingVolunteerListItemProps) => (
  <Box display={'flex'} justifyContent={'space-between'} alignItems='center' flexGrow={1}>
    <Box display={'flex'} alignItems={'center'}>
      <Box
        style={{
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '50%',
          padding: '2px',
          marginRight: '16px',
        }}
      >
        {avatarUrl ? (
          <MuiAvatar src={avatarUrl} alt='avatar' />
        ) : (
          <FallbackAvatar
            initials={
              name
                .split(' ')
                .map((n) => n[0])
                .join('') ?? '?'
            }
            size={32}
          />
        )}
      </Box>
      <TitularTooltip title={name}>
        <Typography
          variant={'body1'}
          noWrap
          style={{ fontSize: '16px', color: theme.color.grey.neutral300, width: '102px' }}
        >
          {name}
        </Typography>
      </TitularTooltip>
    </Box>
    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingRight={'32px'}>
        <Typography variant={'caption'} color={'primary'}>{`${Math.round(progress)}%`}</Typography>
        <BorderLinearProgress variant={'determinate'} value={progress} style={{ width: '80px', height: '8px' }} />
      </Box>
      <Link
        to={{
          pathname: `${INCOMING_VOLUNTEERS_PATH}`,
          search: `?${queryParam}=${volunteerId}`,
        }}
        style={{ textDecoration: 'none' }}
      >
        <IconButton
          style={{
            width: '32px',
            height: '32px',
            border: `1px solid #7570EA`,
            background: theme.color.primary.extraLight,
            borderRadius: '6px',
          }}
        >
          <ChevronRight style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Link>
    </Box>
  </Box>
);

const IncomingVolunteerListItemSkeleton = () => (
  <Box display={'flex'} alignItems={'center'} flexGrow={1}>
    <Skeleton variant={'circle'} width={'40px'} height={'40px'} style={{ marginRight: '12px' }} />
    <Skeleton variant={'text'} width={'100%'} height={'56px'} />
  </Box>
);
