import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Wordmark from '../../assets/branding/logo/volaby-original.svg';
import { AppBarAvatarMenu } from '../app-shell/app-bar/AppBarAvatarMenu';

export const APPLICANT_SHELL_APP_BAR_HEIGHT = 73;

const ApplicantShellAppBar = () => {
  const classes = useStyles();
  return (
    <Box>
      <Box
        display='flex'
        justifyContent='space-between'
        alignContent='center'
        alignItems='center'
        paddingY={1}
        paddingX={3}
        height={APPLICANT_SHELL_APP_BAR_HEIGHT}
        bgcolor='#ffffff'
        borderBottom='solid 1px #e9e9e9'
        boxSizing='border-box'
      >
        <Box paddingLeft={4} display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
          <img src={Wordmark} width={128} alt='volaby-logo' />

          <Typography
            className={classes.appBarMessage}
            style={{ marginLeft: 24, color: '#282828' }}
            variant='h5'
            color='textSecondary'
          >
            {`Let's get your profile set up`}
          </Typography>
        </Box>

        <AppBarAvatarMenu hideTasksMenuItem />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  appBarMessage: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    display: 'inherit',
  },
}));

export { ApplicantShellAppBar };
