import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { ReportTypeStatusChip } from '../../common/ReportTypeStatusChip';
import { SectionItem, useSectionStyles } from './ReportTypeDetail';

export const ReportTypeStatus = ({ isDeactivated }: { isDeactivated: boolean }) => {
  const classes = useSectionStyles();

  return (
    <SectionItem title='Status' sideItem={<ReportTypeStatusChip isDeactivated={isDeactivated} />}>
      <Box pt={1}>
        <Typography className={classes.sectionBody}>
          {isDeactivated
            ? `This report type has been disabled, meaning that it won't be a visible option when creating sessions`
            : 'This report type is active by default, meaning that it will be a visible option when creating sessions'}
        </Typography>
      </Box>
    </SectionItem>
  );
};
