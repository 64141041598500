import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import YourInControl from '../../../assets/tutorial-graphics/welcome/youre_in_control.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Activity Management',
    description: (
      <>
        The Activity Management page allows you to <strong>view and manage activities</strong>.
      </>
    ),
    image: YourInControl,
  },
  {
    label: 'Activity Management',
    description: (
      <>
        Promote volunteers to Activity Leader, track and approve incoming volunteer applications, as well as the
        waitlist of volunteers that want to join a specific activity.
      </>
    ),
    image: YourInControl,
  },
];

const ActivityManagementTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { ActivityManagementTutorialDialog };
