import { Box, Divider, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { CopyBlock, ocean } from 'react-code-blocks';
import { useOrgInfo } from '../../../../../../global/auth/use-org-info/use-org-info';
import { AdminConsoleActivityMUIDatatable } from './AdminConsoleActivityMUIDatatable';
import { AdminConsoleWidgetsActivityTypes, AdminConsoleWidgetsReportTypes } from './AdminConsoleEmbeddableWidgetsPanel';
import { AdminConsoleFieldMUIDatatable } from './AdminConsoleFieldMUIDatatable';
import { EmbeddableWidgetInfoTabs } from './EmbeddableWidgetInfoTabs';

interface TableContainerProps {
  usableFieldTableRows?: Array<AdminConsoleWidgetsReportTypes>;
  usableActivityTableRows?: Array<AdminConsoleWidgetsActivityTypes>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '0 auto',
      width: 'calc(100% - 32px)',
      [theme.breakpoints.up('lg')]: {
        width: '700px',
      },
      [theme.breakpoints.up('xl')]: {
        width: '800px',
      },
    },
    tenantId: {
      margin: '0 auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textAlign: 'center',
      fontSize: '1.2rem !important',
      [theme.breakpoints.up('sm')]: {
        width: '75%',
      },
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

const AdminConsoleEmbeddableWidgetsTableContainer = ({
  usableFieldTableRows,
  usableActivityTableRows,
}: TableContainerProps) => {
  const classes = useStyles();
  const orgInfo = useOrgInfo();

  return (
    <>
      <Box mt={6}>
        <Typography variant='h4'>Embeddable Widgets</Typography>

        <Divider variant='middle' />

        <p>
          Widgets are a useful way for you to display your impact data and activity schedule outside of Volaby. They can
          be embedded on your website easily with minimal configuration. Refer to the instruction panel below for more
          information about each widget&#39;s features.
        </p>

        <EmbeddableWidgetInfoTabs />

        <Divider variant='middle' className={classes.divider} />

        <Typography variant='h5'>Volaby ID</Typography>

        <p>Below is your Volaby ID. This can be used in configuring the Activity Map and Impact Statistic Widgets.</p>

        <div className={classes.tenantId}>
          <CopyBlock theme={ocean} language='markdown' text={orgInfo?.tenantId} />
        </div>

        <Typography variant='h5'>Custom Field IDs</Typography>

        <p>
          Below are your current numerical custom fields from your impact dashboard. These can be used to display Impact
          Statistics via the Impact Widgets.
        </p>

        <AdminConsoleFieldMUIDatatable tableRows={usableFieldTableRows} />

        <Typography variant='h5'>Activity IDs</Typography>

        <p>
          Below are your current Activities and their corresponding IDs, these can be used to display activity data via
          the Fixed Location Activity Table widget.
        </p>

        <AdminConsoleActivityMUIDatatable tableRows={usableActivityTableRows} />
      </Box>
    </>
  );
};

export { AdminConsoleEmbeddableWidgetsTableContainer };
