import { Field } from '@campfire/field';
import { FileUploadGallery, FugFile, UploadedFile } from '@campfire/file-upload-gallery/lib';
import { HoverLink, HoverText } from '@campfire/hover-link';
import { Select } from '@campfire/select';
import { MultiSelect } from '@campfire/multi-select';
import { TabletButton } from '@campfire/tablet-button';
import { Grid, Switch, Typography, Divider, Box, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import React, { memo, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { boolean as YupBoolean, object as YupObject, string as YupString } from 'yup';
import { arrayHead } from '../../../../../common/functions/array-head';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { CreateCategoryDialog } from '../../catalogue-landing-page/CreateCategoryDialog';
import { useSupportContext } from '../../SupportContext';
import { SupportCategory } from '../../__generated__/SupportCategory';
import { SupportResource } from '../../__generated__/SupportResource';
import { SupportResourceGetAllPrograms } from '../../__generated__/SupportResourceGetAllPrograms';
import { SupportResourceGetAllRoles } from '../../__generated__/SupportResourceGetAllRoles';
import { SupportResourceGetAllTags } from '../../__generated__/SupportResourceGetAllTags';
import { DeleteResourceDialog } from './DeleteResourceDialog';
import { ResourceContentSection } from './ResourceContentSection';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';

import {
  SUPPORT_RESOURCE_GET_ALL_PROGRAMS,
  SUPPORT_RESOURCE_GET_ALL_ROLES,
  SUPPORT_RESOURCE_GET_ALL_TAGS,
} from '../../support-screen-model.gql';

const ValidationSchema = YupObject().shape({
  resourceTitle: YupString().required('Please provide a name'),
  featured: YupBoolean().required(),
  editor: YupString(),
  resourceCategoryId: YupString().required('Please select a category'),
});

interface Props {
  selectedResource?: SupportResource;
}

export interface ResourceEditorInitialValues {
  resourceTitle?: string;
  resourceCategoryId?: string;
  featured: boolean;
  editor?: string;
  addedAttachments: FugFile[];
  attachmentCreationTokens: string[];
  removedAttachmentIds: string[];
  programIds: string[];
  activityIds: string[];
  roleIds: string[];
  tagIds: string[];
  permissionLevel?: string;
}

const ResourceEditor = memo((props: Props) => {
  const { selectedResource } = props;
  const {
    allCategories,
    inEditResourceMode,
    setInEditResourceMode,
    runSaveSupportResource,
    inEditCategoryMode,
  } = useSupportContext();
  const { categoryId, resourceId } = useParams();
  const { isMobile, theme } = useCampfireTheme();
  const { saveSupportResourceIsLoading } = useSupportContext();

  const location = useLocation<{ returnSlug: string }>();
  const history = useHistory();
  const [catId] = useQueryParam('catId', StringParam);

  const [openCreateCategoryDialog, setOpenCreateCategoryDialog] = useState(false);
  const [openDeleteResourceDialog, setOpenDeleteResourceDialog] = useState(false);
  const [showAdvanced, setShowAdvanced] = React.useState(false);

  const { data: vmProgram } = useCampfireQuery<SupportResourceGetAllPrograms, {}>(SUPPORT_RESOURCE_GET_ALL_PROGRAMS);

  const { data: vmActivityRole } = useCampfireQuery<SupportResourceGetAllRoles, {}>(SUPPORT_RESOURCE_GET_ALL_ROLES);

  const { data: vmActivityTag } = useCampfireQuery<SupportResourceGetAllTags, {}>(SUPPORT_RESOURCE_GET_ALL_TAGS);

  const uploadedFiles: Array<UploadedFile> | undefined = useMemo(
    () =>
      selectedResource?.supportResourceAttachments.map((attachment) => ({
        url: attachment.url,
        fileId: attachment.supportResourceAttachmentId,
        name: attachment.name,
      })),
    [selectedResource]
  );

  const initialValues: ResourceEditorInitialValues = useMemo(
    () => ({
      resourceTitle: selectedResource?.title ?? '',
      resourceCategoryId: categoryId ?? catId ?? arrayHead(allCategories)?.supportCategoryId ?? '',
      featured: selectedResource?.featured ?? false,
      editor: selectedResource?.textContent ?? '',
      addedAttachments: [],
      attachmentCreationTokens: [],
      removedAttachmentIds: [],
      programIds: selectedResource?.programs.map((program) => program.programId) ?? [],
      roleIds: selectedResource?.activityRoles.map((role) => role.activityRoleId) ?? [],
      activityIds: selectedResource?.activities.map((activity) => activity.activityId) ?? [],
      tagIds: selectedResource?.activityTags.map((tag) => tag.activityTagId) ?? [],
      permissionLevel: selectedResource?.permissionLevel ?? 'none',
    }),
    [selectedResource, allCategories]
  );

  const returnPathObject = {
    pathname: location.state?.returnSlug ?? location.pathname,
    search: inEditCategoryMode ? '?cedit=1' : '',
  };

  const allItems = (key: string) => [
    {
      label: `All ${key}`,
      value: 'all',
    },
    {
      label: `None of ${key}`,
      value: 'none',
    },
  ];

  const permissionLevelOptions = [
    { label: 'All Permission Levels', value: 'none' },
    { label: 'General Volunteer', value: 'gv' },
    { label: 'Activity Leader', value: 'al' },
    { label: 'Program Manager', value: 'pm' },
    { label: 'Admin', value: 'admin' },
  ];

  const handleSubmit = (values: {
    featured: boolean;
    resourceTitle?: string;
    editor?: string;
    resourceCategoryId?: string;
    addedAttachments?: FugFile[];
    attachmentCreationTokens?: string[];
    removedAttachmentIds?: string[];
    programIds: string[];
    activityIds: string[];
    roleIds: string[];
    tagIds: string[];
    permissionLevel?: string;
  }) => {
    if (values.resourceTitle && values.resourceCategoryId) {
      const programIds = values.programIds.includes('all') ? [] : values.programIds;
      const activityIds = values.activityIds.includes('all') ? [] : values.activityIds;
      const roleIds = values.roleIds.includes('all')
        ? (vmActivityRole?.vm.activityRoles || []).map((role) => role.activityRoleId)
        : values.roleIds;
      const tagIds = values.tagIds.includes('all')
        ? (vmActivityTag?.vm.activityTags || []).map((tag) => tag.activityTagId)
        : values.tagIds;
      const permissionLevel = values.permissionLevel === 'none' ? undefined : values.permissionLevel;

      runSaveSupportResource({
        supportResourceId: selectedResource?.supportResourceId ?? '',
        featured: values.featured,
        title: values.resourceTitle,
        textContent: values.editor ?? '',
        supportCategoryId: values.resourceCategoryId,
        addedAttachments: values.addedAttachments ?? [],
        attachmentCreationTokens: values.attachmentCreationTokens ?? [],
        removedAttachmentIds: values.removedAttachmentIds ?? [],
        programIds,
        activityIds,
        roleIds,
        tagIds,
        permissionLevel,
      }).then((res) => {
        if (res.ok) {
          if (inEditResourceMode) {
            setInEditResourceMode(false);
            return;
          }
          history.push(returnPathObject);
        }
      });
    }
  };

  const classes = useStyles();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(vals) => handleSubmit(vals)}
    >
      {({ setFieldValue, values }) => {
        const activityOptions =
          vmProgram?.vm.programs
            .filter((program) => values.programIds.includes(program.programId))
            .flatMap((program) =>
              program.activities.map((activity) => ({
                label: activity.name,
                value: activity.activityId,
              }))
            ) || [];

        const programOptions =
          vmProgram?.vm.programs.map((program) => ({ label: program.name, value: program.programId })) || [];
        const roleOptions =
          vmActivityRole?.vm.activityRoles.map((activityRole) => ({
            label: activityRole.name,
            value: activityRole.activityRoleId,
          })) || [];

        const tagOptions =
          vmActivityTag?.vm.activityTags.map((activityTag) => ({
            label: activityTag.name,
            value: activityTag.activityTagId,
          })) || [];

        const onChangeDisplay = (newValues: string[], field: 'programIds' | 'activityIds' | 'roleIds' | 'tagIds') => {
          const options =
            field === 'programIds'
              ? programOptions
              : field === 'activityIds'
              ? activityOptions
              : field === 'roleIds'
              ? roleOptions
              : field === 'tagIds'
              ? tagOptions
              : [];

          if (newValues.includes('none')) {
            setFieldValue(field, []);
            return;
          }
          if (newValues.includes('all')) {
            if (values[field].includes('all')) {
              setFieldValue(
                field,
                options.filter((program) => !newValues.includes(program.value)).map((program) => program.value)
              );
            } else {
              setFieldValue(field, ['all']);
            }
            return;
          }

          setFieldValue(field, newValues);
        };

        const onPermissionLevelChange = (pl: string) => {
          setFieldValue('permissionLevel', pl);
        };

        return (
          <Form className={classes.form}>
            <Grid container justify='center' spacing={2} style={{ padding: 16 }}>
              <Grid item xs={12} md={8}>
                <Typography gutterBottom variant='h4' style={{ fontWeight: 'bold' }}>
                  {values.resourceTitle === '' ? 'Untitled Resource' : values.resourceTitle}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Field
                  data-track='resources-resource-editor-name-input'
                  name='resourceTitle'
                  label='Resource Name'
                  variant='outlined'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Select
                  data-track='resources-resource-editor-category-select'
                  variant='outlined'
                  shrink
                  fullWidth
                  slow
                  required
                  native
                  name='resourceCategoryId'
                  label='Category'
                  items={allCategories}
                  renderFunction={(category: SupportCategory) => category.title}
                  valueFunction={(category: SupportCategory) => category.supportCategoryId}
                />
              </Grid>
              <Grid item container justify='flex-end' xs={12} md={8}>
                <Grid item>
                  <HoverText
                    data-track='resources-resource-editor-new-category-button'
                    variant='body2'
                    color='primary'
                    onClick={() => setOpenCreateCategoryDialog(true)}
                  >
                    {'New Category'}
                  </HoverText>
                </Grid>
              </Grid>
              <Grid item container xs={12} md={8}>
                <Grid item>
                  <Switch
                    data-track='resources-resource-editor-favourite-switch'
                    color='primary'
                    checked={values.featured}
                    onChange={() => setFieldValue('featured', !values.featured)}
                  />
                </Grid>
                <Grid item container direction={'column'} xs style={{ overflow: 'hidden' }}>
                  <Grid item>
                    <Typography style={{ fontWeight: 'bold' }}>{'Featured Resource'}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                    >{`Important resources can be set as 'featured' and will appear at the top of the category list. Featured resources are visible to all volunteers.`}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <ResourceContentSection />

              <Grid item xs={12} md={8}>
                <Typography style={{ fontWeight: 'bold', marginTop: 32 }}>{'Attachments'}</Typography>

                <FileUploadGallery
                  data-track='resources-resource-editor-attachments-area'
                  allowMultiple
                  uploadedFiles={uploadedFiles ?? []}
                  handleFilesAdded={(files: FugFile[]) => {
                    const newAddedFiles = values.addedAttachments.concat(files);
                    setFieldValue('addedAttachments', newAddedFiles);
                    setFieldValue(
                      'attachmentCreationTokens',
                      newAddedFiles.map((file) => file.id)
                    );
                  }}
                  handleFileRemoved={(removedFile: FugFile) => {
                    setFieldValue(
                      'addedAttachments',
                      values.addedAttachments.filter((file) => file.id !== removedFile.id)
                    );
                    setFieldValue(
                      'attachmentCreationTokens',
                      values.attachmentCreationTokens.filter((token) => token !== removedFile.id)
                    );
                    setFieldValue('removedAttachmentIds', [...values.removedAttachmentIds, removedFile.id]);
                  }}
                />
              </Grid>
              <Grid item container xs={12} md={8}>
                <Grid item xs={12} style={{ padding: '30px 0' }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: '16px', fontWeight: 700, color: '#666666' }}>
                    Who can see this resource?
                  </Typography>
                  <Typography style={{ fontSize: '14px', fontWeight: 400, color: '#9E9E9E', paddingBottom: '5px' }}>
                    Select programs, activities, roles and tags below to segment and display the resource to certain
                    volunteers only. <br />
                    Default settings will display the resource to all volunteers.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={4}>
                    <Typography
                      style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '10px' }}
                    >
                      PROGRAMS
                    </Typography>
                    <MultiSelect
                      variant='outlined'
                      name='programIds'
                      label='Select program/s'
                      placeholder='All programs'
                      items={allItems('Programs').concat(programOptions)}
                      onChange={(e) => onChangeDisplay(e.target.value as string[], 'programIds')}
                    />
                  </Box>
                  <Box mt={4}>
                    <Typography
                      style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '10px' }}
                    >
                      ACTIVITIES
                    </Typography>
                    <MultiSelect
                      variant='outlined'
                      name='activityIds'
                      label='Select activity/activities'
                      placeholder='All activities'
                      items={allItems('Activities').concat(activityOptions)}
                      onChange={(e) => onChangeDisplay(e.target.value as string[], 'activityIds')}
                    />
                  </Box>
                  <Box mt={4}>
                    <Typography
                      style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '10px' }}
                    >
                      ACTIVITY ROLES
                    </Typography>
                    <MultiSelect
                      variant='outlined'
                      name='roleIds'
                      placeholder='No roles (Visible to all)'
                      label='Select role/s'
                      items={allItems('Activity Roles').concat(roleOptions)}
                      onChange={(e) => onChangeDisplay(e.target.value as string[], 'roleIds')}
                    />
                  </Box>
                  <Box mt={4}>
                    <Typography
                      style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '10px' }}
                    >
                      ACTIVITY TAGS
                    </Typography>
                    <MultiSelect
                      variant='outlined'
                      name='tagIds'
                      placeholder='No tags (Visible to all)'
                      label='Select tag/s'
                      items={allItems('Activity Tags').concat(tagOptions)}
                      onChange={(e) => onChangeDisplay(e.target.value as string[], 'tagIds')}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingTop: '0 !important',
                    paddingBottom: '0 !important',
                    marginTop: '16px',
                  }}
                >
                  <Button
                    variant='text'
                    endIcon={showAdvanced ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    style={{
                      fontSize: 10,
                      textDecoration: 'none',
                      color: theme.color.grey.neutral300,
                      textTransform: 'none',
                      padding: 0,
                    }}
                  >
                    Advanced
                  </Button>
                </Grid>
                {showAdvanced ? (
                  <Grid item xs={12}>
                    <Box mt={3}>
                      <Typography
                        style={{ fontSize: '12px', fontWeight: 500, color: '#000000b0', paddingBottom: '10px' }}
                      >
                        PERMISSION LEVEL
                      </Typography>
                      <Select
                        fullWidth
                        variant='outlined'
                        name='permissionLevel'
                        placeholder='No permission level (Visible to all)'
                        label='Select permission level'
                        items={permissionLevelOptions}
                        onChange={(e) => onPermissionLevelChange(e.target.value as string)}
                        renderFunction={(item) => item.label}
                        valueFunction={(item) => item.value}
                      />
                    </Box>
                  </Grid>
                ) : null}
              </Grid>

              <Grid item container xs={12} md={9} justify='space-between'>
                {resourceId ? (
                  <Grid item>
                    <HoverText
                      data-track='resources-resource-editor-delete-button'
                      variant='body2'
                      color='error'
                      hoverColor='error'
                      onClick={() => setOpenDeleteResourceDialog(true)}
                    >
                      {isMobile ? 'Delete' : 'Delete this resource'}
                    </HoverText>
                  </Grid>
                ) : null}
                <Grid item container xs justify='flex-end' alignItems='center' alignContent='center'>
                  <Grid item style={{ marginRight: 12 }}>
                    <HoverLink
                      data-track='resources-resource-editor-cancel-button'
                      disableUnderline
                      to={returnPathObject}
                      onClick={() => {
                        if (inEditResourceMode) setInEditResourceMode(false);
                      }}
                      color='textPrimary'
                      hoverColor='textPrimary'
                    >
                      <Typography variant='body2'>{'Cancel'}</Typography>
                    </HoverLink>
                  </Grid>
                  <Grid item>
                    <TabletButton
                      data-track='resources-resource-editor-save-button'
                      variant='contained'
                      color='primary'
                      type='submit'
                      disabled={saveSupportResourceIsLoading}
                    >
                      {isMobile ? 'Save' : 'Save Changes'}
                    </TabletButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <CreateCategoryDialog
              open={openCreateCategoryDialog}
              close={() => setOpenCreateCategoryDialog(false)}
              handleNewCategoryId={(cid) => setFieldValue('resourceCategoryId', cid)}
            />
            <DeleteResourceDialog
              supportResourceId={resourceId ?? ''}
              open={openDeleteResourceDialog}
              close={() => setOpenDeleteResourceDialog(false)}
            />
          </Form>
        );
      }}
    </Formik>
  );
});

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      padding: 16,
      backgroundColor: '#f6f8f9',
      borderBottom: 'solid 1px #e9e9e9',
      minHeight: '100%',
      boxSizing: 'border-box',
      '& input,select': {
        backgroundColor: '#ffffff',
      },
    },
  })
);

export { ResourceEditor };
