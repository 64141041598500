import { gql } from '@apollo/client';

export const ActivityLocationInformationFragments = gql`
  fragment ActivityLocation on VOLUNTEER_ActivityLocationType {
    placesAddress {
      placesId
      description
      formatted
      latLong {
        lat
        long
      }
    }
  }

  fragment ActivityRemoteLocation on VOLUNTEER_ActivityRemoteLocationType {
    details
    activityRemoteLocationId
  }
`;
