import { encodeDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { useSnackbar } from '../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../global/network/useEndpointFetch';
import { useVolunteerCommonProfileContext } from './VolunteerCommonProfileContext';
import { VolunteerDatabaseVolunteer } from '../volunteers/volunteer-database/volunteer-database-model.gql';

const useSaveCommentsFetch = () => {
  return useEndpointFetch<{ volunteerId: string; comments: string }>('/vm/volunteer/manage/comments');
};

export const useSaveRolesFetch = () => {
  return useEndpointFetch<{ volunteerId: string; activityRoleIds: string[] }>(
    '/vm/volunteer/manage/update-activity-roles'
  );
};
export const useFlagVolunteerFetch = () => {
  return useEndpointFetch<{ volunteerId: string; reason: string }>('/vm/volunteer/manage/flag');
};
export const useRetireVolunteerFetch = () => {
  return useEndpointFetch<{ volunteerId: string }>('/vm/volunteer/manage/retire');
};
export const useReactivateVolunteerFetch = () => {
  return useEndpointFetch<{ volunteerId: string }>('/vm/volunteer/manage/unretire');
};

export const useVolunteerProfileActions = () => {
  const saveComments = useSaveCommentsFetch();
  const saveRoles = useSaveRolesFetch();
  const flagVolunteer = useFlagVolunteerFetch();
  const retireVolunteer = useRetireVolunteerFetch();
  const reactivateVolunteer = useReactivateVolunteerFetch();
  const { updateParentVolunteerActions } = useVolunteerCommonProfileContext();
  const { setSnackbar } = useSnackbar();
  const runSaveComments = ({
    volunteerId,
    comments,
    handleSuccess,
  }: {
    volunteerId: string;
    comments: string;
    handleSuccess?: () => void;
  }) => {
    saveComments
      .run({
        volunteerId,
        comments,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save comment',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Comment saved',
          variant: 'success',
        });
        if (updateParentVolunteerActions) {
          const { updateVolunteerWithSavedComment } = updateParentVolunteerActions;
          if (updateVolunteerWithSavedComment) updateVolunteerWithSavedComment(comments);
        }
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save comment',
          variant: 'error',
        })
      );
  };

  const runSaveRoles = ({
    volunteerId,
    activityRoleIds,
    handleSuccess,
  }: {
    volunteerId: string;
    activityRoleIds: string[];
    handleSuccess?: (roleIds: string[]) => void;
  }) => {
    saveRoles
      .run({
        volunteerId,
        activityRoleIds,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save role',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Roles saved',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess(activityRoleIds);
      });
  };
  const runFlagVolunteer = ({
    volunteerId,
    reason,
    handleSuccess,
  }: {
    volunteerId: string;
    reason: string;
    handleSuccess?: () => void;
  }) => {
    flagVolunteer
      .run({
        volunteerId,
        reason,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to flag volunteer',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Volunteer flagged',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to flag volunteer',
          variant: 'error',
        })
      );
  };
  const runRetireVolunteer = ({ volunteerId, handleSuccess }: { volunteerId: string; handleSuccess?: () => void }) => {
    retireVolunteer
      .run({
        volunteerId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to retire volunteer',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Volunteer retired',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to retire volunteer',
          variant: 'error',
        })
      );
  };
  const runReactivateVolunteer = ({
    volunteerId,
    handleSuccess,
  }: {
    volunteerId: string;
    handleSuccess?: () => void;
  }) => {
    reactivateVolunteer
      .run({
        volunteerId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to reactivate volunteer',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Volunteer reactivated',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to reactivate volunteer',
          variant: 'error',
        })
      );
  };

  return {
    runSaveComments,
    runSaveCommentsLoading: saveComments.isLoading,
    runSaveCommentsError: saveComments.error,
    runSaveRoles,
    runSaveRolesLoading: saveRoles.isLoading,
    runSaveRolesError: saveRoles.error,
    runFlagVolunteer,
    runFlagVolunteerLoading: flagVolunteer.isLoading,
    runFlagVolunteerError: flagVolunteer.error,
    runRetireVolunteer,
    runRetireVolunteerLoading: retireVolunteer.isLoading,
    runReactivateVolunteer,
    runReactivateVolunteerLoading: reactivateVolunteer.isLoading,
  };
};

export type VolunteerProfileActions = {
  updateVolunteerAsRetired?: () => void;
  updateVolunteerAsExited?: () => void;
  updateVolunteerAsReactivated?: () => void;
  updateVolunteerWithSavedComment?: (comments: string) => void;
  updateVolunteerWithSavedActivityRoles?: (activityRoleIds: string[]) => void;
};

export const getUpdateVolunteerActions = (
  updateVolunteer: (volunteerId: string, updatedVolunteer: VolunteerDatabaseVolunteer) => void,
  volunteer: VolunteerDatabaseVolunteer
) => {
  const updateVolunteerAsRetired = () => {
    updateVolunteer(volunteer.volunteerId, {
      ...volunteer,
      rosterStatus: 'retired',
      dateDeactivated: encodeDate(DateTime.local()) || null,
      flagging: null,
    });
  };

  const updateVolunteerAsExited = () => {
    const date = DateTime.local();

    updateVolunteer(volunteer.volunteerId, {
      ...volunteer,
      rosterStatus: 'flagged',
      flagging: { __typename: 'VOLUNTEER_VolunteerFlaggingType', dateFlagged: encodeDate(date) },
      dateDeactivated: encodeDate(date),
    });
  };

  const updateVolunteerAsReactivated = () => {
    updateVolunteer(volunteer.volunteerId, {
      ...volunteer,
      rosterStatus: 'previously',
    });
  };
  const updateVolunteerWithSavedComment = (newComment: string) => {
    updateVolunteer(volunteer.volunteerId, {
      ...volunteer,
      profile: {
        ...volunteer.profile,
        comments: newComment,
      },
    });
  };
  const updateVolunteerWithSavedActivityRoles = (activityRoleIds: string[]) => {
    updateVolunteer(volunteer.volunteerId, {
      ...volunteer,
      activityRoles: activityRoleIds.map((roleId) => {
        return {
          activityRoleId: roleId,
          __typename: 'VOLUNTEER_ActivityRoleType',
        };
      }),
    });
  };
  return {
    updateVolunteerAsRetired,
    updateVolunteerAsExited,
    updateVolunteerAsReactivated,
    updateVolunteerWithSavedComment,
    updateVolunteerWithSavedActivityRoles,
  };
};
