import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../../../../common/cards/alert-card/AlertCard';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { useReportFieldDetailHeaderActions } from '../report-field-detail-header-actions';

type Props = {
  isOpen: boolean;
  isArchivable: boolean;
  handleClose: () => void;
  reportFieldId: string;
  name: string;
  refetch: () => void;
};

export const ArchiveReportFieldDialog = (props: Props) => {
  const [query, setQuery] = useQueryParams({
    reportFieldId: StringParam,
  });
  const { isOpen, isArchivable, handleClose, reportFieldId, name, refetch } = props;
  const { runArchiveReportField, runArchiveReportFieldLoading } = useReportFieldDetailHeaderActions();
  const { theme, isMobile } = useCampfireTheme();
  const { lightTextColor, darkTextColor } = getAlertCardColors('info');

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='archive-report-field-dialog-title'
      aria-describedby='archive-report-field-dialog-description'
    >
      <DialogTitle id='archive-report-field-dialog-title'>{`Archive ${name}?`}</DialogTitle>
      <DialogContent>
        {isArchivable ? (
          <AlertCard title='Archiving Field' variant='info'>
            <AlertCardBody>
              <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                {`Archiving a field will mean that it is not accessible when creating new report types, nor immediately
              visible when on the 'Fields' tab of Report Management. Archived fields can be found and reactivated using
              the `}
                <span style={{ fontWeight: 'bold', color: darkTextColor }}>filters</span> on this page.
              </Typography>
            </AlertCardBody>
          </AlertCard>
        ) : (
          <AlertCard title='Archiving Field used in report types is not allowed' variant='urgent'>
            {/* <AlertCardBody>
              <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                {`Archiving a field will mean that it is not accessible when creating new report types, nor immediately
              visible when on the 'Fields' tab of Report Management. Archived fields can be found and reactivated using
              the `}
                <span style={{ fontWeight: 'bold', color: darkTextColor }}>filters</span> on this page.
              </Typography>
            </AlertCardBody> */}
          </AlertCard>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {isArchivable && (
          <Button
            disabled={runArchiveReportFieldLoading}
            onClick={() => {
              runArchiveReportField({
                fieldId: reportFieldId,
                handleSuccess: () => {
                  handleClose();
                  if (refetch) refetch();
                  if (isMobile) setQuery({ ...query, reportFieldId: undefined });
                },
              });
            }}
            style={{
              color: theme.palette.error.main,
            }}
            autoFocus
          >
            Archive
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
