import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';

export type NumberWidgetProps = {
  tag: string;
  name: string;
  filters: string;
};

export type CommonRequiringActionWidgetProps = {
  filters: string;
  range?: string | null;
};

export const useWidgetStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '20px',
      fontWeight: 700,
      color: '#343434',
      marginTop: '-1px',
    },
    subtitle: {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.color.grey.neutral300,
      marginTop: '-3px',
    },
    type: {
      fontSize: '11px',
      fontWeight: 300,
      color: theme.color.grey.neutral300,
    },
    valueText: {
      fontWeight: 900,
      fontSize: '80px',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
      color: '#343434',
      lineHeight: '86px',
    },
    variablesBox: {
      background: '#F2F2F2',
      borderRadius: '8px',
      width: '86px',
      height: '48px',
      fontSize: '20px',
      color: theme.color.grey.neutral400,
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export const getStartDateFromRange = (range: string) => {
  if (range === 'ALL_TIME') {
    return undefined;
  }

  if (range === 'TODAY') {
    return DateTime.local()
      .startOf('day')
      .toISODate();
  }

  if (range === 'THIS_WEEK') {
    return DateTime.local()
      .startOf('week')
      .startOf('day')
      .toISODate();
  }

  if (range === 'THIS_MONTH') {
    return DateTime.local()
      .startOf('month')
      .startOf('day')
      .toISODate();
  }

  if (range === 'THIS_YEAR') {
    return DateTime.local()
      .startOf('year')
      .startOf('day')
      .toISODate();
  }

  return undefined;
};

export const getEndDateFromRange = (range: string) => {
  if (range === 'ALL_TIME') {
    return undefined;
  }

  if (range === 'TODAY') {
    return DateTime.local()
      .endOf('day')
      .toISODate();
  }

  if (range === 'THIS_WEEK') {
    return DateTime.local()
      .endOf('week')
      .endOf('day')
      .toISODate();
  }

  if (range === 'THIS_MONTH') {
    return DateTime.local()
      .endOf('month')
      .endOf('day')
      .toISODate();
  }

  if (range === 'THIS_YEAR') {
    return DateTime.local()
      .endOf('year')
      .endOf('day')
      .toISODate();
  }

  return undefined;
};

export const getLastStartDateFromRange = (range: string) => {
  if (range === 'ALL_TIME') {
    return undefined;
  }

  if (range === 'TODAY') {
    return DateTime.local()
      .minus({ days: 1 })
      .startOf('day')
      .toISODate();
  }

  if (range === 'THIS_WEEK') {
    return DateTime.local()
      .startOf('week')
      .startOf('day')
      .minus({ weeks: 1 })
      .toISODate();
  }

  if (range === 'THIS_MONTH') {
    return DateTime.local()
      .startOf('month')
      .minus({ months: 1 })
      .startOf('day')
      .toISODate();
  }

  if (range === 'THIS_YEAR') {
    return DateTime.local()
      .startOf('year')
      .minus({ years: 1 })
      .startOf('day')
      .toISODate();
  }

  return undefined;
};

export const getLastEndDateFromRange = (range: string) => {
  if (range === 'ALL_TIME') {
    return undefined;
  }

  if (range === 'TODAY') {
    return DateTime.local()
      .minus({ days: 1 })
      .endOf('day')
      .toISODate();
  }

  if (range === 'THIS_WEEK') {
    return DateTime.local()
      .endOf('week')
      .minus({ weeks: 1 })
      .endOf('day')
      .toISODate();
  }

  if (range === 'THIS_MONTH') {
    return DateTime.local()
      .endOf('month')
      .minus({ months: 1 })
      .endOf('day')
      .toISODate();
  }

  if (range === 'THIS_YEAR') {
    return DateTime.local()
      .endOf('year')
      .minus({ years: 1 })
      .endOf('day')
      .toISODate();
  }

  return undefined;
};

export const getStartDateFromInterval = (range: string) => {
  if (range === 'DAILY') {
    return DateTime.local()
      .startOf('day')
      .toISODate();
  }

  if (range === 'WEEKLY') {
    return DateTime.local()
      .startOf('week')
      .startOf('day')
      .toISODate();
  }

  if (range === 'MONTHLY') {
    return DateTime.local()
      .startOf('month')
      .startOf('day')
      .toISODate();
  }

  if (range === 'YEARLY') {
    return DateTime.local()
      .startOf('year')
      .startOf('day')
      .toISODate();
  }

  return undefined;
};

export const getEndDateFromInterval = (range: string) => {
  if (range === 'DAILY') {
    return DateTime.local()
      .endOf('day')
      .toISODate();
  }

  if (range === 'WEEKLY') {
    return DateTime.local()
      .endOf('week')
      .endOf('day')
      .toISODate();
  }

  if (range === 'MONTHLY') {
    return DateTime.local()
      .endOf('month')
      .endOf('day')
      .toISODate();
  }

  if (range === 'YEARLY') {
    return DateTime.local()
      .endOf('year')
      .endOf('day')
      .toISODate();
  }

  return undefined;
};

export const getLastStartDateFromInterval = (range: string) => {
  if (range === 'DAILY') {
    return DateTime.local()
      .minus({ days: 1 })
      .startOf('day')
      .toISODate();
  }

  if (range === 'WEEKLY') {
    return DateTime.local()
      .startOf('week')
      .minus({ weeks: 1 })
      .startOf('day')
      .toISODate();
  }

  if (range === 'MONTHLY') {
    return DateTime.local()
      .startOf('month')
      .minus({ months: 1 })
      .startOf('day')
      .toISODate();
  }

  if (range === 'YEARLY') {
    return DateTime.local()
      .startOf('year')
      .minus({ years: 1 })
      .startOf('day')
      .toISODate();
  }

  return undefined;
};

export const getLastEndDateFromInterval = (range: string) => {
  if (range === 'DAILY') {
    return DateTime.local()
      .minus({ days: 1 })
      .endOf('day')
      .toISODate();
  }

  if (range === 'WEEKLY') {
    return DateTime.local()
      .endOf('week')
      .minus({ weeks: 1 })
      .endOf('day')
      .toISODate();
  }

  if (range === 'MONTHLY') {
    return DateTime.local()
      .endOf('month')
      .minus({ months: 1 })
      .endOf('day')
      .toISODate();
  }

  if (range === 'YEARLY') {
    return DateTime.local()
      .endOf('year')
      .minus({ years: 1 })
      .endOf('day')
      .toISODate();
  }

  return undefined;
};
