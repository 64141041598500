import React, { memo, useEffect } from 'react';
import { DraggableList, DraggableListItem, DraggableListItemHandle } from '@campfire/draggable-list';
import { Field } from '@campfire/field/lib';
import { HoverText } from '@campfire/hover-link';
import { Box, Grid, IconButton, Typography, Checkbox } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash';
import { TitularTooltip } from '@campfire/titular-tooltip/lib';
import { v4 as uuidv4 } from 'uuid';
import { useCampfireTheme } from '../../../../../../../../../theme/useCampfireTheme';
import {
  TaskItemField_field as FieldTaskItemType,
  TaskItemField_field_VOLUNTEER_DropdownTaskFieldType_dropdownOptions as DropdownOption,
  TaskItemField_field_VOLUNTEER_CheckboxTaskFieldType_checkboxOptions as CheckboxOption,
  TaskItemField_field_VOLUNTEER_QuizTaskFieldType_quizTaskFieldOptions as QuizFieldOption,
  TaskItemField_field_VOLUNTEER_MultipleChoiceTaskFieldType_multipleChoiceOptions as MultipleChoiceOption,
} from '../../../../__generated__/TaskItemField';

type TaskItemFieldOption = DropdownOption | CheckboxOption | QuizFieldOption | MultipleChoiceOption;

type FormikContextValues = {
  field: FieldTaskItemType;
};

export const DraggableListField = memo(() => {
  const { values, setFieldValue } = useFormikContext<FormikContextValues>();
  const { __typename: fieldType } = values.field;

  const optionsKey = React.useMemo(() => {
    switch (fieldType) {
      case 'VOLUNTEER_QuizTaskFieldType':
        return 'quizTaskFieldOptions';
      case 'VOLUNTEER_MultipleChoiceTaskFieldType':
        return 'multipleChoiceOptions';
      case 'VOLUNTEER_DropdownTaskFieldType':
        return 'dropdownOptions';
      case 'VOLUNTEER_CheckboxTaskFieldType':
        return 'checkboxOptions';
      default:
        return '';
    }
  }, [fieldType]);

  const currentOptions = get(values.field, [optionsKey], []);
  const otherOptionIncluded = !!(currentOptions as any[]).find((option: any) => option.allowText);
  const { theme } = useCampfireTheme();

  const addOption = (name = 'New Option') => {
    switch (values.field.__typename) {
      case 'VOLUNTEER_QuizTaskFieldType':
        return setFieldValue(
          'field.quizTaskFieldOptions',
          currentOptions.concat([
            {
              __typename: 'VOLUNTEER_QuizTaskFieldOptionType',
              quizTaskFieldOptionId: `new-${uuidv4()}`,
              name,
              order: currentOptions.length,
              allowText: name === 'Other',
            },
          ])
        );
      case 'VOLUNTEER_MultipleChoiceTaskFieldType':
        return setFieldValue(
          'field.multipleChoiceOptions',
          currentOptions.concat([
            {
              __typename: 'VOLUNTEER_MultipleChoiceTaskFieldOptionType',
              multipleChoiceFieldOptionId: `new-${uuidv4()}`,
              name,
              order: currentOptions.length,
              allowText: name === 'Other',
            },
          ])
        );
      case 'VOLUNTEER_DropdownTaskFieldType':
        return setFieldValue(
          'field.dropdownOptions',
          currentOptions.concat([
            {
              __typename: 'VOLUNTEER_DropdownTaskFieldOptionType',
              dropdownFieldOptionId: `new-${uuidv4()}`,
              name,
              order: currentOptions.length,
              allowText: name === 'Other',
            },
          ])
        );
      case 'VOLUNTEER_CheckboxTaskFieldType':
        return setFieldValue(
          'field.checkboxOptions',
          currentOptions.concat([
            {
              __typename: 'VOLUNTEER_CheckboxTaskFieldOptionType',
              checkboxFieldOptionId: `new-${uuidv4()}`,
              name,
              order: currentOptions.length,
              allowText: name === 'Other',
            },
          ])
        );
      default:
        return [];
    }
  };

  useEffect(() => {
    if (currentOptions.length < 1) {
      addOption();
    }
  }, [optionsKey]);

  const onSelectCorrect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target?.value;
      if (values.field.__typename !== 'VOLUNTEER_QuizTaskFieldType') return;
      setFieldValue('field.quizTaskFieldCorrect.quizTaskFieldOption.quizTaskFieldOptionId', value);
      const { quizTaskFieldOptions } = values.field;
      quizTaskFieldOptions.forEach((fieldOption, optionIndex) => {
        setFieldValue(
          `field.quizTaskFieldOptions[${optionIndex}].isCorrect`,
          fieldOption.quizTaskFieldOptionId === value
        );
      });
    },
    [values]
  );

  const onRemoveOption = (optionId: string) => {
    switch (values.field.__typename) {
      case 'VOLUNTEER_QuizTaskFieldType':
        return setFieldValue(
          'field.quizTaskFieldOptions',
          values.field.quizTaskFieldOptions
            .filter((option) => option.quizTaskFieldOptionId !== optionId)
            .map((option, optionIndex) => ({ ...option, order: optionIndex }))
        );
      case 'VOLUNTEER_MultipleChoiceTaskFieldType':
        return setFieldValue(
          'field.multipleChoiceOptions',
          values.field.multipleChoiceOptions
            .filter((option) => option.multipleChoiceFieldOptionId !== optionId)
            .map((option, optionIndex) => ({ ...option, order: optionIndex }))
        );
      case 'VOLUNTEER_DropdownTaskFieldType':
        return setFieldValue(
          'field.dropdownOptions',
          values.field.dropdownOptions
            .filter((option) => option.dropdownFieldOptionId !== optionId)
            .map((option, optionIndex) => ({ ...option, order: optionIndex }))
        );
      case 'VOLUNTEER_CheckboxTaskFieldType':
        return setFieldValue(
          'field.checkboxOptions',
          values.field.checkboxOptions
            .filter((option) => option.checkboxFieldOptionId !== optionId)
            .map((option, optionIndex) => ({ ...option, order: optionIndex }))
        );
      default:
        return [];
    }
  };

  return (
    <>
      <FieldArray name={`field.${optionsKey}`} validateOnChange={false}>
        {(arrayHelpers) => (
          <>
            {optionsKey === 'quizTaskFieldOptions' ? (
              <Grid container>
                <Grid item>
                  <Typography color='textSecondary' style={{ fontSize: '12px' }}>
                    Correct
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            <DraggableList
              onReorder={(startIdx, endIdx, reorder) => {
                setFieldValue(`field.${optionsKey}`, reorder(currentOptions, startIdx, endIdx));
              }}
            >
              {(currentOptions as any[]).map((option: TaskItemFieldOption, optionIndex) => {
                const listId = (() => {
                  switch (option.__typename) {
                    case 'VOLUNTEER_QuizTaskFieldOptionType':
                      return option.quizTaskFieldOptionId;
                    case 'VOLUNTEER_MultipleChoiceTaskFieldOptionType':
                      return option.multipleChoiceFieldOptionId;
                    case 'VOLUNTEER_DropdownTaskFieldOptionType':
                      return option.dropdownFieldOptionId;
                    case 'VOLUNTEER_CheckboxTaskFieldOptionType':
                      return option.checkboxFieldOptionId;
                    default:
                      return '';
                  }
                })();

                return (
                  <Box key={listId}>
                    <DraggableListItem id={listId} index={optionIndex} key={listId}>
                      <Grid container>
                        <Grid item>
                          <DraggableListItemHandle />
                        </Grid>
                        {option.__typename === 'VOLUNTEER_QuizTaskFieldOptionType' &&
                          values.field.__typename === 'VOLUNTEER_QuizTaskFieldType' && (
                            <Grid item>
                              <Checkbox
                                inputProps={{ 'aria-label': 'correct checkbox' }}
                                checked={
                                  option.quizTaskFieldOptionId ===
                                  values.field.quizTaskFieldCorrect?.quizTaskFieldOption.quizTaskFieldOptionId
                                }
                                value={listId}
                                onChange={onSelectCorrect}
                              />
                            </Grid>
                          )}

                        <Grid item xs>
                          <Field slow fullWidth variant='standard' name={`field.${optionsKey}[${optionIndex}].name`} />
                        </Grid>
                        <Grid item>
                          <IconButton onClick={() => onRemoveOption(listId)}>
                            <Close />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </DraggableListItem>
                  </Box>
                );
              })}
            </DraggableList>
            {values.field.__typename === 'VOLUNTEER_QuizTaskFieldType' &&
              !values.field.quizTaskFieldCorrect?.quizTaskFieldOption.quizTaskFieldOptionId && (
                <Typography variant='body2' color='error' style={{ marginBottom: '4px' }}>
                  {'Please select a correct option.'}
                </Typography>
              )}
            {otherOptionIncluded ? (
              <Grid container alignItems='center'>
                <Grid item xs>
                  <Typography>Other</Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => {
                      arrayHelpers.remove(currentOptions.length - 1);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            ) : null}

            <Grid container spacing={1}>
              {currentOptions.length < 1 ? (
                <Grid item container justify='center' style={{ marginBottom: 8 }}>
                  <Grid
                    item
                    xs={10}
                    style={{
                      border: `1px solid ${theme.status.red.medium}`,
                      display: 'flex',
                      justifyContent: 'center',
                      padding: 4,
                      borderRadius: 4,
                    }}
                  >
                    <Typography variant='body2' color='error'>
                      {'This field type requires at least one option.'}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              <Grid item>
                <HoverText color='primary' onClick={() => addOption()}>
                  {'Add option'}
                </HoverText>
              </Grid>

              {optionsKey === 'multipleChoiceOptions' ? (
                <>
                  <Grid item>
                    <Typography>{'or'}</Typography>
                  </Grid>
                  <TitularTooltip
                    description={`Click to add the 'Other' option. A text input will be opened when this option is selected.`}
                  >
                    <Grid item>
                      <HoverText
                        color='primary'
                        disabled={otherOptionIncluded}
                        onClick={() => {
                          addOption('Other');
                        }}
                      >
                        {`add "Other"`}
                      </HoverText>
                    </Grid>
                  </TitularTooltip>
                </>
              ) : null}
            </Grid>
          </>
        )}
      </FieldArray>
    </>
  );
});
