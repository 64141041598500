import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { FileUploadGallery, UploadedFile } from '@campfire/file-upload-gallery/lib';
import { getDurationHoursMin, unpackToDate, unpackToDateTime, unpackToTime } from '@campfire/hot-date';
import { HoverLink, HoverText } from '@campfire/hover-link';
import { Pane, PaneScrollable } from '@campfire/pane';
import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip/lib';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Switch,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Add,
  Check,
  ChevronLeft,
  ChevronRightRounded,
  Clear,
  Close,
  Edit,
  ErrorOutline,
  ExpandMore,
  FileCopyOutlined,
  MoreVert,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { convertFromRaw, EditorState } from 'draft-js';
import { DateTime } from 'luxon';
import React, { memo, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { StatusChip } from '../../../../../common/chips/StatusChip';
import { SemanticTime } from '../../../../../common/dates/SemanticTime';
import { FullPageMessage } from '../../../../../common/FullPageMessage';
import { getDisplayTimeSchedule } from '../../../../../common/functions/activity-display-helpers';
import { SearchField } from '../../../../../common/inputs/SearchField';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { CampfireResponse } from '../../../../../global/network/httpResponse';
import { AxiosResponseWithOk } from '../../../../../global/network/useAxiosFetch';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { VOLUNTEER_ActivityEnrolmentPositionEnumType } from '../../../../../__generated__/globalTypes';
import { Avatar } from '../../../../general/user-profile/components/Avatar';
import { ProfileHoverAvatar } from '../../../../general/user-profile/hover-text/ProfileHoverBlock';
import { VolunteerCommonProfileProvider } from '../../../volunteer-common-profile/VolunteerCommonProfileContext';
import { VolunteerDialogHoverBlock } from '../../../volunteer-common-profile/VolunteerDialogHoverBlock';
import {
  SaveActivityFetchActivityLocationVariables,
  SaveActivityFetchSessionVariables,
  SaveActivityFetchVariables,
  useApproveBulkIncomingVolunteersFetch,
  useApproveIncomingVolunteerFetch,
  useCloseActivityFetch,
  useEnrolWaitlistingFetch,
  useSaveActivityFetch,
  useSaveActivityTeamFetch,
  useSuspendActivityFetch,
  useUncloseActivityFetch,
  useUnsuspendActivityFetch,
  useWaitlistBulkIncomingVolunteersFetch,
  useWaitlistIncomingVolunteerFetch,
} from '../activity-timeline-actions';
import { GET_ACTIVITY_TIMELINE_ACTIVITY } from '../activity-timeline-model.gql';
import { SelectActivityClosureReasonDialog } from '../common/SelectActivityClosureReasonDialog';
import { getIsActivityEnded } from '../is-activity-ended';
import { AddVolunteersDialog } from '../past/report/form/AddVolunteersDialog';
import { LOG_WIDTH } from '../sidebar/ActivityTimelineSidebar';
import { ActivityTimelineActivity } from '../__generated__/ActivityTimelineActivity';
import { ActivityTimelineActivityApplications } from '../__generated__/ActivityTimelineActivityApplications';
import { ActivityTimelineActivityEnrolments } from '../__generated__/ActivityTimelineActivityEnrolments';
import { ActivityTimelineActivityLocation } from '../__generated__/ActivityTimelineActivityLocation';
import { ActivityTimelineActivitySession } from '../__generated__/ActivityTimelineActivitySession';
import { ActivityTimelineActivityWaitlistings } from '../__generated__/ActivityTimelineActivityWaitlistings';
import {
  GetActivityTimelineActivity,
  GetActivityTimelineActivityVariables,
} from '../__generated__/GetActivityTimelineActivity';
import { ActivityApplicationReviewDialog } from './ActivityApplicationReviewDialog';
import { ActivityWaitlistingReviewDialog } from './ActivityWaitlistingReviewDialog';

function parseActivityLocation(
  activityLocation: ActivityTimelineActivityLocation
): SaveActivityFetchActivityLocationVariables {
  return {
    ...activityLocation,
    comments: activityLocation.comments ?? '',
    placesAddress: {
      ...activityLocation.placesAddress,
      placesId: activityLocation.placesAddress.placesId ?? undefined,
      latitude: activityLocation.placesAddress.latLong.lat,
      longitude: activityLocation.placesAddress.latLong.long,
    },
  };
}

function parseSession(session: ActivityTimelineActivitySession): SaveActivityFetchSessionVariables {
  return {
    ...session,
    reportTypeId: session.reportType?.reportTypeId ?? '',
    activityLocation: session.activityLocation ? parseActivityLocation(session.activityLocation) : null,
  };
}

function parseActivity(activity: ActivityTimelineActivity): SaveActivityFetchVariables {
  return {
    activityId: activity.activityId,
    isRestrictedActivity: activity.isRestrictedActivity,
    isHidden: activity.isHidden,
    maxTeam: activity.maxTeam,
    hasOpenRoster: activity.hasOpenRoster,
    programId: activity.program.programId,
    name: activity.name,
    description: JSON.stringify(activity.description),
    startDate: activity.startDate,
    enrollable: activity.isEnrollable,
    activityLocation: activity.activityLocation ? parseActivityLocation(activity.activityLocation) : null,
    remoteLocation: {
      details: activity?.activityRemoteLocation?.details ?? undefined,
    },
    recurrences: activity.__typename === 'VOLUNTEER_RecurringActivityType' ? activity.schedule.recurrences : [],
    sessions: activity.sessions.map(parseSession),
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContent: {
      borderRight: `1px solid ${theme.color.grey.border}`,
    },
    headerSection: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
    contentRow: {
      paddingLeft: 16,
      paddingRight: 16,
      borderTop: `1px solid ${theme.color.grey.border}`,
      paddingTop: 24,
      paddingBottom: 24,
    },
    actionButton: {
      marginRight: 8,
      marginTop: 4,
    },
    volunteerListBox: {
      margin: 0,
      padding: 0,
      marginTop: 16,
    },
    volunteerListItemBox: {
      listStyle: 'none',
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
    },
    volunteerListItemText: {
      marginLeft: 12,
      lineHeight: '1',
    },
    volunteerListItemDate: {
      marginLeft: 12,
      fontSize: '12px',
      fontWeight: 300,
    },
    editingTeamActionIconButton: {
      marginRight: 8,
    },
    waitlistVolunteerListItemBox: {
      textAlign: 'left',
      justifyContent: 'flex-start',
      listStyle: 'none',
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 16,
      paddingRight: 8,
      '&:hover': {
        cursor: 'pointer',
        background: 'rgba(0,0,0,0.1)',
      },
    },
  })
);

interface SessionListItemProps {
  session: ActivityTimelineActivitySession;
  showMoreSessionInfo: boolean;
}

const SessionListItem = (props: SessionListItemProps) => {
  const { session, showMoreSessionInfo } = props;

  const editorState = useMemo(
    () =>
      session?.description ? EditorState.createWithContent(convertFromRaw(JSON.parse(session.description))) : null,
    [session?.description]
  );

  return (
    <ListItem>
      <Box>
        <Typography>{session.name}</Typography>

        <Box display='flex' alignContent='center' alignItems='center'>
          <Typography style={{ marginRight: 8 }} variant='body2' color='textPrimary'>
            {getDisplayTimeSchedule(session.startTime, session.endTime)}
          </Typography>

          {showMoreSessionInfo && session.startTime && session.endTime ? (
            <Typography variant='body2' color='textSecondary'>
              {`(${getDurationHoursMin(unpackToTime(session.startTime), unpackToTime(session.endTime))})`}
            </Typography>
          ) : null}
        </Box>

        {showMoreSessionInfo ? (
          <Box paddingBottom={2}>
            <Typography variant='body2' style={{ marginTop: 6 }}>
              {editorState ? <CampfireRichEditor editorState={editorState} readOnly /> : null}
            </Typography>
            <Typography variant='body2' color='textSecondary' style={{ marginTop: 6 }}>
              {session.reportType === null
                ? session.autoReport
                  ? 'No Report Required (Impact Tracked)'
                  : 'No Report Required (No Impact)'
                : session.reportType?.name}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </ListItem>
  );
};

interface EditedTeam {
  activityId: string;
  teamMembers: {
    profileId: string;
    userId: string;
    volunteerId: string;
    preferredName: string;
    lastName: string;
    avatarUrl?: string;
    position: string;
  }[];
}
const sortEditedTeam = (editedTeam: EditedTeam): EditedTeam => {
  return {
    ...editedTeam,
    teamMembers: editedTeam.teamMembers
      .sort((a, b) => a.preferredName.localeCompare(b.preferredName))
      .sort((a, b) => (a.position === 'al' && b.position === 'gv' ? -1 : 0)),
  };
};
interface ActivityTimelineManagementProps {
  activityId: string;
  returnSlug: string;
}
export const ActivityTimelineManagement = memo(({ activityId, returnSlug }: ActivityTimelineManagementProps) => {
  const [selectedActivityApplication, setSelectedActivityApplication] = useState<
    ActivityTimelineActivityApplications
  >();
  const [selectedActivityWaitlisting, setSelectedActivityWaitlisting] = useState<
    ActivityTimelineActivityWaitlistings
  >();
  const [showAddVolunteerToTeamDialog, setShowAddVolunteerToTeamDialog] = useState(false);
  const [showClosureReasonDialog, setShowClosureReasonDialog] = useState(false);
  const [activity, setActivity] = useState<ActivityTimelineActivity>();

  const [teamSearchFilter, setTeamSearchFilter] = useState<string>();
  const [rightSidebarSearchFilter, setRightSidebarSearchFilter] = useState<string>();
  const { setSnackbar } = useSnackbar();

  const suspendActivity = useSuspendActivityFetch();
  const unsuspendActivity = useUnsuspendActivityFetch();
  const closeActivity = useCloseActivityFetch();
  const uncloseActivity = useUncloseActivityFetch();
  const approveBulkIncomingVolunteers = useApproveBulkIncomingVolunteersFetch();
  const waitlistBulkIncomingVolunteers = useWaitlistBulkIncomingVolunteersFetch();
  const approveIncomingVolunteer = useApproveIncomingVolunteerFetch();
  const waitlistIncomingVolunteer = useWaitlistIncomingVolunteerFetch();
  const enrolWaitlisting = useEnrolWaitlistingFetch();
  const saveActivityTeam = useSaveActivityTeamFetch();
  const saveActivity = useSaveActivityFetch();

  const uploadedFiles: Array<UploadedFile> | [] = useMemo(
    () =>
      activity?.activityAttachments.map((attachment) => ({
        fileId: attachment.activityAttachmentId,
        url: attachment.url,
        name: attachment.name,
      })) ?? [],
    [activity]
  );

  const [editedTeam, setEditedTeam] = useState<EditedTeam>();

  const { data, error, loading, refetch, refetchLoading } = useCampfireQuery<
    GetActivityTimelineActivity,
    GetActivityTimelineActivityVariables
  >(GET_ACTIVITY_TIMELINE_ACTIVITY, {
    options: {
      variables: {
        activityId: activityId,
      },
    },
  });

  useEffect(() => {
    setActivity(data?.vm.activity ?? undefined);
  }, [data]);

  const activityApplications = useMemo(() => {
    return activity?.activeActivityApplications ?? [];
  }, [activity]);

  const activityWaitlistings = useMemo(() => {
    return activity?.activeActivityWaitlistings ?? [];
  }, [activity]);

  const handleActionPromise = <Res extends unknown = unknown>({
    promise,
    successMessage,
    errorMessage,
    onSuccess,
    refetchOnSuccess = true,
  }: {
    promise: Promise<AxiosResponseWithOk<CampfireResponse<Res>>>;
    successMessage: string;
    errorMessage: string;
    onSuccess?: (res: Res) => void;
    refetchOnSuccess?: boolean;
  }) => {
    promise
      .then((res) => {
        if (res.ok) {
          if (onSuccess) onSuccess(res.data.data);

          setSnackbar({
            open: true,
            message: successMessage,
            variant: 'success',
          });

          if (refetchOnSuccess && refetch) {
            refetch().catch(() =>
              setSnackbar({
                open: true,
                message: 'Unable to reload',
                variant: 'error',
                action: (
                  <Button color='secondary' variant='contained' size='medium' onClick={() => window.location.reload()}>
                    Refresh Now
                  </Button>
                ),
              })
            );
          }
          return;
        }

        setSnackbar({
          open: true,
          message: errorMessage,
          variant: 'error',
        });
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: errorMessage,
          variant: 'error',
        })
      );
  };

  const runSuspendActivity = () => {
    if (!activity || activity.isSuspended) return;
    handleActionPromise({
      promise: suspendActivity.run({
        activityId: activity.activityId,
      }),
      successMessage: 'Activity suspended',
      errorMessage: 'Unable to suspend activity',
      refetchOnSuccess: false,
      onSuccess: () => {
        setActivity((prevActivity) => {
          if (!prevActivity) return prevActivity;
          return {
            ...prevActivity,
            isSuspended: true,
          };
        });
      },
    });
  };

  const runUnsuspendActivity = () => {
    if (!activity || !activity.isSuspended) return;
    handleActionPromise({
      promise: unsuspendActivity.run({
        activityId: activity.activityId,
      }),
      successMessage: 'Activity unsuspended',
      errorMessage: 'Unable to unsuspend activity',
      refetchOnSuccess: true,
    });
  };

  const runCloseActivity = ({
    activityClosureReasonId,
    dateEnded,
  }: {
    activityClosureReasonId: string;
    dateEnded: string;
  }) => {
    if (!activity || activity.closedActivity) return;
    handleActionPromise({
      promise: closeActivity.run({
        activityId: activity.activityId,
        dateEnded,
        activityClosureReasonId,
      }),
      successMessage: 'Activity closed',
      errorMessage: 'Unable to close activity',
    });
  };

  const runUncloseActivity = () => {
    if (!activity || !activity.closedActivity) return;
    handleActionPromise({
      promise: uncloseActivity.run({
        closedActivityId: activity.closedActivity.closedActivityId,
      }),
      successMessage: 'Activity reopened',
      errorMessage: 'Unable to unclose activity',
      refetchOnSuccess: true,
    });
  };

  const runUpdateEnrollable = (enrollableValue: boolean) => () => {
    if (!activity) return;
    handleActionPromise({
      promise: saveActivity.run(parseActivity({ ...activity, isEnrollable: enrollableValue })),
      successMessage: 'Activity waitlist status updated',
      errorMessage: 'Unable to update activity waitlist status',
      refetchOnSuccess: false,
      onSuccess: () => {
        setActivity((prevActivity) => {
          if (!prevActivity) return prevActivity;
          return {
            ...prevActivity,
            isEnrollable: enrollableValue,
          };
        });
      },
    });
  };

  const runApproveAllIncomingVolunteers = () => {
    if (!activity) return;
    handleActionPromise<{ activityEnrolmentIds: string[] }>({
      promise: approveBulkIncomingVolunteers.run({
        activityApplicationIds: activityApplications.map((application) => application.activityApplicationId),
      }),
      successMessage: 'Approved all new volunteers',
      errorMessage: 'Unable to approve new volunteers',
      refetchOnSuccess: false,
      onSuccess: ({ activityEnrolmentIds }) => {
        setActivity((prevActivity) => {
          if (!prevActivity) return prevActivity;
          return {
            ...prevActivity,
            activeActivityApplications: [],
            activityEnrolments: [
              ...prevActivity.activityEnrolments,
              ...activityApplications.map(
                (application, idx): ActivityTimelineActivityEnrolments => ({
                  __typename: 'VOLUNTEER_ActivityEnrolmentType',
                  activityEnrolmentId: activityEnrolmentIds[idx],
                  position: VOLUNTEER_ActivityEnrolmentPositionEnumType.gv,
                  volunteer: application.volunteer,
                })
              ),
            ],
          };
        });

        if (!editedTeam) return;
        setEditedTeam((prevEditedTeam) => {
          if (!prevEditedTeam) return prevEditedTeam;
          return sortEditedTeam({
            ...prevEditedTeam,
            teamMembers: [
              ...prevEditedTeam.teamMembers,
              ...activityApplications.map((application) => ({
                profileId: application.volunteer.profile.profileId,
                userId: application.volunteer.profile.userId,
                volunteerId: application.volunteer.volunteerId,
                preferredName: application.volunteer.profile.preferredName,
                lastName: application.volunteer.profile.lastName,
                avatarUrl: application.volunteer.profile.avatarUrl ?? undefined,
                position: 'gv',
              })),
            ],
          });
        });
      },
    });
  };

  const runWaitlistAllIncomingVolunteers = () => {
    if (!activity) return;
    handleActionPromise<{ activityWaitlistingIds: string[] }>({
      promise: waitlistBulkIncomingVolunteers.run({
        activityApplicationIds: activityApplications.map((application) => application.activityApplicationId),
      }),
      successMessage: 'Waitlisted all new volunteers',
      errorMessage: 'Unable to waitlist new volunteers',
      refetchOnSuccess: false,
      onSuccess: ({ activityWaitlistingIds }) => {
        setActivity((prevActivity) => {
          if (!prevActivity) return prevActivity;
          return {
            ...prevActivity,
            activeActivityApplications: [],
            activeActivityWaitlistings: [
              ...prevActivity.activeActivityWaitlistings,
              ...activityApplications.map(
                (application, idx): ActivityTimelineActivityWaitlistings => ({
                  __typename: 'VOLUNTEER_ActivityWaitlistingType',
                  activityWaitlistingId: activityWaitlistingIds[idx],
                  volunteer: application.volunteer,
                  dateAdded: DateTime.local(),
                })
              ),
            ],
          };
        });
      },
    });
  };

  const runApproveIncomingVolunteer = (activityApplication: ActivityTimelineActivityApplications) => {
    if (!activity) return;
    handleActionPromise<{ activityEnrolmentId: string }>({
      promise: approveIncomingVolunteer.run({
        activityApplicationId: activityApplication.activityApplicationId,
      }),
      successMessage: 'Added volunteer to team',
      errorMessage: 'Unable to add volunteer to team',
      refetchOnSuccess: false,
      onSuccess: ({ activityEnrolmentId }) => {
        setActivity((prevActivity) => {
          if (!prevActivity) return prevActivity;
          return {
            ...prevActivity,
            activeActivityApplications: prevActivity.activeActivityApplications.filter(
              (application) => application.activityApplicationId !== activityApplication.activityApplicationId
            ),
            activityEnrolments: [
              ...prevActivity.activityEnrolments,
              {
                __typename: 'VOLUNTEER_ActivityEnrolmentType',
                activityEnrolmentId,
                position: VOLUNTEER_ActivityEnrolmentPositionEnumType.gv,
                volunteer: activityApplication.volunteer,
              },
            ],
          };
        });

        if (!editedTeam) return;
        setEditedTeam((prevEditedTeam) => {
          if (!prevEditedTeam) return prevEditedTeam;
          return sortEditedTeam({
            ...prevEditedTeam,
            teamMembers: [
              ...prevEditedTeam.teamMembers,
              {
                profileId: activityApplication.volunteer.profile.profileId,
                userId: activityApplication.volunteer.profile.userId,
                volunteerId: activityApplication.volunteer.volunteerId,
                preferredName: activityApplication.volunteer.profile.preferredName,
                lastName: activityApplication.volunteer.profile.lastName,
                avatarUrl: activityApplication.volunteer.profile.avatarUrl ?? undefined,
                position: 'gv',
              },
            ],
          });
        });
      },
    });
  };

  const runWaitlistIncomingVolunteer = (activityApplication: ActivityTimelineActivityApplications) => {
    if (!activity) return;
    handleActionPromise<{ activityWaitlistingId: string }>({
      promise: waitlistIncomingVolunteer.run({
        activityApplicationId: activityApplication.activityApplicationId,
      }),
      successMessage: 'Moved volunteer to waitlist',
      errorMessage: 'Unable to move volunteer to waitlist',
      refetchOnSuccess: false,
      onSuccess: ({ activityWaitlistingId }) => {
        setActivity((prevActivity) => {
          if (!prevActivity) return prevActivity;
          return {
            ...prevActivity,
            activeActivityApplications: prevActivity.activeActivityApplications.filter(
              (application) => application.activityApplicationId !== activityApplication.activityApplicationId
            ),
            activeActivityWaitlistings: [
              ...prevActivity.activeActivityWaitlistings,
              {
                __typename: 'VOLUNTEER_ActivityWaitlistingType',
                activityWaitlistingId,
                volunteer: activityApplication.volunteer,
                dateAdded: DateTime.local(),
              },
            ],
          };
        });
      },
    });
  };

  const runEnrolWaitlisting = (activityWaitlisting: ActivityTimelineActivityWaitlistings) => {
    if (!activity) return;
    handleActionPromise<{ activityEnrolmentId: string }>({
      promise: enrolWaitlisting.run({
        activityWaitlistingId: activityWaitlisting.activityWaitlistingId,
      }),
      successMessage: 'Added volunteer to team',
      errorMessage: 'Unable to add volunteer to team',
      refetchOnSuccess: false,
      onSuccess: ({ activityEnrolmentId }) => {
        setActivity((prevActivity) => {
          if (!prevActivity) return prevActivity;
          return {
            ...prevActivity,
            activeActivityWaitlistings: prevActivity.activeActivityWaitlistings.filter(
              (waitlisting) => waitlisting.activityWaitlistingId !== activityWaitlisting.activityWaitlistingId
            ),
            activityEnrolments: [
              ...prevActivity.activityEnrolments,
              {
                __typename: 'VOLUNTEER_ActivityEnrolmentType',
                activityEnrolmentId,
                position: VOLUNTEER_ActivityEnrolmentPositionEnumType.gv,
                volunteer: activityWaitlisting.volunteer,
              },
            ],
          };
        });

        if (!editedTeam) return;
        setEditedTeam((prevEditedTeam) => {
          if (!prevEditedTeam) return prevEditedTeam;
          return sortEditedTeam({
            ...prevEditedTeam,
            teamMembers: [
              ...prevEditedTeam.teamMembers,
              {
                profileId: activityWaitlisting.volunteer.profile.profileId,
                userId: activityWaitlisting.volunteer.profile.userId,
                volunteerId: activityWaitlisting.volunteer.volunteerId,
                preferredName: activityWaitlisting.volunteer.profile.preferredName,
                lastName: activityWaitlisting.volunteer.profile.lastName,
                avatarUrl: activityWaitlisting.volunteer.profile.avatarUrl ?? undefined,
                position: 'gv',
              },
            ],
          });
        });
      },
    });
  };

  const runSaveActivityTeam = () => {
    if (!editedTeam) return;
    handleActionPromise({
      promise: saveActivityTeam.run(editedTeam),
      successMessage: 'Team saved',
      errorMessage: 'Unable to save team',
      onSuccess: () => {
        setEditedTeam(undefined);
        setTeamSearchFilter(undefined);
      },
    });
  };

  const [activityStatusMenuAnchorEl, setActivityStatusMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  function handleOpenActivityStatusMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setActivityStatusMenuAnchorEl(event.currentTarget);
  }

  function handleCloseActivityStatusMenu() {
    setActivityStatusMenuAnchorEl(null);
  }
  const [
    incomingVolunteersActionMenuAnchorEl,
    setIncomingVolunteersActionMenuAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  function handleOpenIncomingVolunteersActionMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setIncomingVolunteersActionMenuAnchorEl(event.currentTarget);
  }

  function handleCloseIncomingVolunteersActionMenu() {
    setIncomingVolunteersActionMenuAnchorEl(null);
  }

  const { isXs, isSm, isMobile, theme } = useCampfireTheme();
  const classes = useStyles();
  const [showMoreSessionInfo, setShowMoreSessionInfo] = useState(false);
  const isLoading = loading || refetchLoading;
  const compact = isXs || isSm;

  const editorState = useMemo(
    () =>
      activity?.description ? EditorState.createWithContent(convertFromRaw(JSON.parse(activity.description))) : null,
    [activity?.description]
  );

  const [showNewVolunteers, setShowNewVolunteers] = useState(!compact);
  const newVolunteerAndWaitlistCount = activityApplications.length + activityWaitlistings.length;
  const hasNewVolunteers =
    (activity?.isRestrictedActivity && !!activityApplications.length) || !!activityWaitlistings.length;

  useLayoutEffect(() => {
    if (newVolunteerAndWaitlistCount === 0) {
      setShowNewVolunteers(() => false);
      return;
    }
    setShowNewVolunteers(() => !compact);
  }, [newVolunteerAndWaitlistCount]);

  const isActivityEnded = getIsActivityEnded(activity);

  return (
    <Box
      position='relative'
      display='flex'
      flex='1 1 auto'
      style={{
        height: `calc(100vh - 106px)`, // hardcoded appbar + activities tabs height
      }}
    >
      {error && !loading ? (
        <FullPageMessage title={'Network Error'} subtitle={'Unable to load timeline.'} Icon={ErrorOutline} />
      ) : isLoading ? (
        <ActivityTimelineManagementSkeletonLoading />
      ) : activity ? (
        <>
          <Grid container style={{ overflowX: 'hidden', flexWrap: compact ? undefined : 'nowrap', height: '100%' }}>
            {compact && showNewVolunteers ? null : (
              <Grid item sm={12} md style={{ display: 'flex', flex: '1 1 auto' }}>
                <Pane>
                  <PaneScrollable>
                    <Box display='flex' flex='1 1 auto' className={classes.mainContent}>
                      <Grid container>
                        <Grid item xs={12} container className={classes.headerSection} alignItems='center'>
                          <Grid item xs style={{ minWidth: 288 }}>
                            <HoverLink
                              color='primary'
                              hoverColor='primary'
                              to={returnSlug}
                              data-track='actCnl-activity-overview'
                            >
                              <Grid container alignItems='center' wrap='nowrap'>
                                <ChevronLeft style={{ marginRight: 4, fontSize: 22 }} />
                                <Typography variant='h5' display='inline'>
                                  {activity.name}
                                </Typography>
                              </Grid>
                            </HoverLink>
                          </Grid>

                          <Grid item>
                            {isActivityEnded ? (
                              <TitularTooltip title='Activity ended'>
                                <Box display={compact ? 'block' : 'inline-block'}>
                                  <Box
                                    display='flex'
                                    component='span'
                                    alignContent='center'
                                    alignItems='center'
                                    paddingX={3}
                                    marginRight={1}
                                  >
                                    <Typography variant='body2' style={{ cursor: 'default' }}>
                                      This activity has ended
                                    </Typography>
                                  </Box>
                                </Box>
                              </TitularTooltip>
                            ) : (
                              <Box display={'inline-block'}>
                                <TabletButton
                                  data-track='actCnl-overview-status-dropdown'
                                  className={classes.actionButton}
                                  variant={activity.closedActivity || activity.isSuspended ? 'contained' : 'outlined'}
                                  color={
                                    activity.closedActivity ? 'error' : activity.isSuspended ? 'secondary' : 'primary'
                                  }
                                  aria-controls='activity-status-menu'
                                  aria-haspopup='true'
                                  onClick={handleOpenActivityStatusMenu}
                                  endIcon={<ExpandMore />}
                                >
                                  {activity.closedActivity ? 'Closed' : activity.isSuspended ? 'Suspended' : 'Active'}
                                </TabletButton>

                                <Menu
                                  id='activity-status-menu'
                                  anchorEl={activityStatusMenuAnchorEl}
                                  keepMounted
                                  open={Boolean(activityStatusMenuAnchorEl)}
                                  onClose={handleCloseActivityStatusMenu}
                                >
                                  {activity.closedActivity ? null : activity.isSuspended ? (
                                    <TitularTooltip
                                      placement='right'
                                      enterDelay={0}
                                      title='Activate restricted'
                                      description='Activities that belong to a suspended program cannot be active.'
                                      disableHoverListener={!activity.program.dateSuspended}
                                      disableFocusListener={!activity.program.dateSuspended}
                                      disableTouchListener={!activity.program.dateSuspended}
                                    >
                                      <Box>
                                        <MenuItem
                                          data-track='actCnl-overview-status-dropdown-item-activate'
                                          disabled={activity.program.dateSuspended}
                                          onClick={() => {
                                            runUnsuspendActivity();
                                            handleCloseActivityStatusMenu();
                                          }}
                                        >
                                          {'Activate'}
                                        </MenuItem>
                                      </Box>
                                    </TitularTooltip>
                                  ) : (
                                    <MenuItem
                                      data-track='actCnl-overview-status-dropdown-item-suspend'
                                      onClick={() => {
                                        runSuspendActivity();
                                        handleCloseActivityStatusMenu();
                                      }}
                                    >
                                      {'Suspend'}
                                    </MenuItem>
                                  )}
                                  {activity.closedActivity ? (
                                    <MenuItem
                                      data-track='actCnl-overview-status-dropdown-item-reopen'
                                      onClick={() => {
                                        runUncloseActivity();
                                        handleCloseActivityStatusMenu();
                                      }}
                                    >
                                      {'Reopen'}
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      data-track='actCnl-overview-status-dropdown-item-close'
                                      onClick={() => {
                                        handleCloseActivityStatusMenu();
                                        setShowClosureReasonDialog(true);
                                      }}
                                    >
                                      {'Close'}
                                    </MenuItem>
                                  )}
                                </Menu>
                              </Box>
                            )}

                            <Box
                              display={'inline-block'}
                              paddingX={isMobile && isActivityEnded ? 3 : 0}
                              paddingY={isMobile && isActivityEnded ? '4px' : 0}
                            >
                              <Link
                                to={`/management/activity-management/edit?activityId=${activityId}${
                                  returnSlug ? `&return=${encodeURIComponent(returnSlug)}` : ''
                                }`}
                                style={{ textDecoration: 'none' }}
                                data-track='actCnl-overview-edit-activity'
                              >
                                <TabletButton
                                  className={classes.actionButton}
                                  variant='outlined'
                                  endIcon={isMobile ? null : <Edit />}
                                >
                                  {'Edit'}
                                </TabletButton>
                              </Link>

                              <Link
                                to={`/management/activity-management/duplicate?activityId=${activityId}${
                                  returnSlug ? `&return=${encodeURIComponent(returnSlug)}` : ''
                                }`}
                                style={{ textDecoration: 'none' }}
                                data-track='actCnl-overview-duplicate-activity'
                              >
                                <TabletButton
                                  className={classes.actionButton}
                                  variant='outlined'
                                  endIcon={isMobile ? null : <FileCopyOutlined />}
                                >
                                  {'Duplicate'}
                                </TabletButton>
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.contentRow}>
                          {editorState ? (
                            <Box paddingY={1}>
                              <CampfireRichEditor editorState={editorState} readOnly />
                            </Box>
                          ) : null}

                          <Grid item container spacing={2}>
                            <Grid item>
                              <Typography variant='body1'>{`${activity.program.name} (${
                                activity.isRestrictedActivity ? 'Requires Approval' : 'Open Activity'
                              })`}</Typography>
                            </Grid>
                            <Grid item>
                              {activity.program.dateSuspended ? (
                                <StatusChip status='Suspended' bgcolor={theme.status.amber.light} />
                              ) : null}
                            </Grid>
                          </Grid>

                          <Box paddingY={1}>
                            <Typography style={{ marginRight: 8 }} variant='body2' color='textSecondary'>
                              {activity.activityLocation
                                ? activity.activityLocation.placesAddress.description
                                : `Remote location${activity.activityRemoteLocation?.details ? ': ' : ''}${
                                    activity.activityRemoteLocation?.details
                                  }`}
                            </Typography>

                            <Typography style={{ marginRight: 8 }} variant='body2' color='textSecondary'>
                              {activity.hasOpenRoster
                                ? 'This activity uses open rosters'
                                : 'This activity uses managed rosters'}
                            </Typography>

                            <Typography style={{ marginRight: 8 }} variant='body2' color='textSecondary'>
                              {getDisplayTimeSchedule(activity.startTime, activity.endTime) === 'No set time'
                                ? 'This activity does not have a set start and end time'
                                : getDisplayTimeSchedule(activity.startTime, activity.endTime)}
                            </Typography>
                            {activity.__typename === 'VOLUNTEER_RecurringActivityType'
                              ? activity.schedule.recurrences.map((recurrence) => (
                                  <Typography variant='body2' color='textSecondary' key={recurrence.humanReadable}>
                                    {recurrence.endDate
                                      ? `${recurrence.humanReadable} (${
                                          isActivityEnded ? 'Ended ' : 'Ending '
                                        } on ${unpackToDate(recurrence.endDate).toLocaleString({
                                          day: 'numeric',
                                          month: 'long',
                                          year: 'numeric',
                                        })})`
                                      : `${recurrence.humanReadable}`}
                                  </Typography>
                                ))
                              : null}
                          </Box>
                        </Grid>

                        <Grid item xs={12} className={classes.contentRow}>
                          <Box display='flex' alignContent='baseline' alignItems='baseline'>
                            <Typography variant='h6' style={{ marginRight: 16 }}>
                              {'Sessions'}
                            </Typography>

                            <HoverText
                              color='primary'
                              variant='body2'
                              invertUnderlineBehaviour
                              onClick={() => setShowMoreSessionInfo(!showMoreSessionInfo)}
                              id={
                                showMoreSessionInfo
                                  ? 'actCnl-overview-hide-session-details'
                                  : 'actCnl-overview-show-session-details'
                              }
                            >
                              {showMoreSessionInfo ? (
                                <>
                                  {'Hide Details'}
                                  <VisibilityOff
                                    style={{
                                      fontSize: 12,
                                      marginLeft: 8,
                                      marginBottom: -2,
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  {'Show Details'}
                                  <Visibility
                                    style={{
                                      fontSize: 12,
                                      marginLeft: 8,
                                      marginBottom: -2,
                                    }}
                                  />
                                </>
                              )}
                            </HoverText>
                          </Box>
                          <List>
                            {activity.sessions.map((session) => {
                              return (
                                <SessionListItem
                                  showMoreSessionInfo={showMoreSessionInfo}
                                  session={session}
                                  key={session.sessionId}
                                />
                              );
                            })}
                          </List>
                        </Grid>

                        {compact ? (
                          <Grid item xs={12} className={classes.contentRow}>
                            <Box>
                              <Typography
                                color={newVolunteerAndWaitlistCount === 0 ? 'textSecondary' : 'textPrimary'}
                                // color={'textSecondary'}
                                variant='h6'
                                style={{
                                  paddingRight: 32,
                                  // fontWeight: 'bold'
                                }}
                              >
                                {`Applicants & Waitlist (${newVolunteerAndWaitlistCount})`}
                              </Typography>

                              <Box
                                display='flex'
                                // justifyContent='flex-end'
                                alignItems='center'
                                alignContent='center'
                                paddingTop={1}
                              >
                                <TabletButton
                                  variant='contained'
                                  color='primary'
                                  size='medium'
                                  onClick={() => setShowNewVolunteers(true)}
                                  style={{ whiteSpace: 'nowrap' }}
                                  disabled={newVolunteerAndWaitlistCount === 0}
                                  endIcon={<ChevronRightRounded />}
                                >
                                  {/* ({newVolunteerAndWaitlistCount})  */}
                                  View new applicants and waitlist
                                </TabletButton>
                              </Box>
                            </Box>
                          </Grid>
                        ) : null}

                        <Grid item xs={12} className={classes.contentRow}>
                          <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <Box display='flex' alignContent='baseline' alignItems='baseline'>
                              <Typography variant='h6' style={{ marginRight: 16 }}>
                                {editedTeam ? 'Editing Team: ' : 'Team'}
                              </Typography>

                              {editedTeam ? (
                                <TabletButton
                                  data-track='actCnl-overview-cancel-editing-team'
                                  style={{
                                    marginRight: 8,
                                  }}
                                  onClick={() => setEditedTeam(undefined)}
                                  color='error'
                                >
                                  {'Cancel'}
                                </TabletButton>
                              ) : null}

                              {editedTeam ? (
                                <TabletButton
                                  data-track='actCnl-overview-done-editing-team'
                                  variant='contained'
                                  color='primary'
                                  onClick={() => {
                                    runSaveActivityTeam();
                                  }}
                                  endIcon={<Check />}
                                >
                                  {'Done'}
                                </TabletButton>
                              ) : !activity.closedActivity ? (
                                <HoverText
                                  data-track='actCnl-overview-edit-team'
                                  color='primary'
                                  variant='body2'
                                  invertUnderlineBehaviour
                                  onClick={() => {
                                    setEditedTeam(
                                      sortEditedTeam({
                                        activityId: activity.activityId,
                                        teamMembers: activity.activityEnrolments.map((enrolment) => ({
                                          profileId: enrolment.volunteer.profile.profileId,
                                          userId: enrolment.volunteer.profile.userId,
                                          volunteerId: enrolment.volunteer.volunteerId,
                                          preferredName: enrolment.volunteer.profile.preferredName,
                                          lastName: enrolment.volunteer.profile.lastName,
                                          avatarUrl: enrolment.volunteer.profile.avatarUrl ?? undefined,
                                          position: enrolment.position,
                                        })),
                                      })
                                    );
                                  }}
                                >
                                  {'Edit Team'}
                                  <Edit style={{ fontSize: 12, marginLeft: 4 }} />
                                </HoverText>
                              ) : null}
                            </Box>
                            <Box display='flex'>
                              <SearchField
                                placeholder='Search Team'
                                growLeft
                                onChange={(e) => setTeamSearchFilter(e.target.value)}
                              />
                            </Box>
                          </Box>

                          {editedTeam ? (
                            <Box maxWidth={480} marginTop={2} marginLeft={2}>
                              <Typography
                                variant='caption'
                                style={{ whiteSpace: 'pre-line' }}
                              >{`Click on a volunteer's name to make them an Activity Leader.
                        Click the cross to remove them from the team.
                        Hit 'Done' to save your changes, or 'Cancel' to discard all changes.`}</Typography>
                            </Box>
                          ) : null}

                          {editedTeam ? (
                            <>
                              <Box component='ul' className={classes.volunteerListBox}>
                                {editedTeam.teamMembers
                                  .filter((teamMember) =>
                                    teamSearchFilter
                                      ? teamMember.preferredName
                                          .concat(` ${teamMember.lastName}`)
                                          .toLowerCase()
                                          .includes(teamSearchFilter.toLowerCase())
                                      : true
                                  )
                                  .map((teamMember) => {
                                    const displayText = `${teamMember.preferredName} ${teamMember.lastName}${
                                      teamMember.position === 'al' ? ' (Activity Leader)' : ''
                                    }`;
                                    return (
                                      <Box
                                        component='li'
                                        key={teamMember.volunteerId}
                                        display='flex'
                                        className={classes.volunteerListItemBox}
                                        style={
                                          editedTeam
                                            ? undefined
                                            : {
                                                marginLeft: 32,
                                                marginTop: 12,
                                                marginBottom: 12,
                                              }
                                        }
                                      >
                                        <Tooltip title='Remove from activity' arrow placement='bottom'>
                                          <div>
                                            <IconButton
                                              className={classes.editingTeamActionIconButton}
                                              onClick={() => {
                                                setEditedTeam((prevTeam) => {
                                                  if (!prevTeam) return prevTeam;
                                                  return {
                                                    ...prevTeam,
                                                    teamMembers: prevTeam.teamMembers.filter(
                                                      (x) => teamMember.volunteerId !== x.volunteerId
                                                    ),
                                                  };
                                                });
                                              }}
                                            >
                                              <Clear color='error' />
                                            </IconButton>
                                          </div>
                                        </Tooltip>

                                        <ProfileHoverAvatar
                                          profileId={teamMember.profileId}
                                          userId={teamMember.userId}
                                          preferredName={teamMember.preferredName}
                                          lastName={teamMember.lastName}
                                          avatarUrl={teamMember.avatarUrl}
                                        />

                                        <Tooltip title='Click to toggle activity leader' arrow placement='bottom'>
                                          <div>
                                            <HoverText
                                              color='textPrimary'
                                              className={classes.volunteerListItemText}
                                              onClick={() => {
                                                setEditedTeam((prevTeam) => {
                                                  if (!prevTeam) return prevTeam;
                                                  const newPosition = teamMember.position === 'gv' ? 'al' : 'gv';
                                                  return {
                                                    ...prevTeam,
                                                    teamMembers: prevTeam.teamMembers.map((x) => {
                                                      return {
                                                        ...x,
                                                        position:
                                                          x.volunteerId === teamMember.volunteerId
                                                            ? newPosition
                                                            : x.position,
                                                      };
                                                    }),
                                                  };
                                                });
                                              }}
                                            >
                                              {displayText}
                                            </HoverText>
                                          </div>
                                        </Tooltip>
                                      </Box>
                                    );
                                  })}
                              </Box>

                              <TabletButton
                                data-track='actCnl-overview-add-team-members'
                                variant='contained'
                                onClick={() => {
                                  setShowAddVolunteerToTeamDialog(true);
                                }}
                                endIcon={<Add />}
                              >
                                {'Add Team Members'}
                              </TabletButton>
                            </>
                          ) : !activity.closedActivity ? (
                            <Box component='ul' className={classes.volunteerListBox}>
                              {activity.activityEnrolments
                                .filter((enrolment) =>
                                  teamSearchFilter
                                    ? enrolment.volunteer.profile.preferredName
                                        .concat(` ${enrolment.volunteer.profile.lastName}`)
                                        .toLowerCase()
                                        .includes(teamSearchFilter.toLowerCase())
                                    : true
                                )
                                .sort((a, b) =>
                                  a.volunteer.profile.preferredName.localeCompare(b.volunteer.profile.preferredName)
                                )
                                .sort((a, b) => (a.position === 'al' && b.position === 'gv' ? -1 : 0))
                                .map((activityEnrolment) => {
                                  const { volunteer } = activityEnrolment;
                                  const lastName = `${volunteer.profile.lastName}${
                                    activityEnrolment.position === 'al' ? ' (Activity Leader)' : ''
                                  }`;
                                  return (
                                    <Box
                                      component='li'
                                      key={activityEnrolment.activityEnrolmentId}
                                      display='flex'
                                      className={classes.volunteerListItemBox}
                                      style={
                                        editedTeam
                                          ? undefined
                                          : {
                                              marginLeft: 32,
                                              marginTop: 12,
                                              marginBottom: 12,
                                            }
                                      }
                                    >
                                      <VolunteerDialogHoverBlock
                                        profileId={volunteer.profile.profileId}
                                        userId={volunteer.profile.userId}
                                        preferredName={volunteer.profile.preferredName}
                                        lastName={lastName}
                                        avatarUrl={volunteer.profile.avatarUrl}
                                        hoverTextProps={{
                                          hoverColor: 'primary',
                                          disableUnderline: true,
                                          className: classes.volunteerListItemText,
                                        }}
                                      />
                                    </Box>
                                  );
                                })}
                            </Box>
                          ) : (
                            <Box
                              padding={3}
                              width={1}
                              display='flex'
                              flexDirection='column'
                              justifyContent='center'
                              alignContent='center'
                              alignItems='center'
                              textAlign='center'
                            >
                              <Typography align='center' variant='h6' color='textSecondary' gutterBottom>
                                {'Activity Closed'}
                              </Typography>

                              <Typography align='center' variant='body2'>
                                {`This team was disbanded when the activity was closed on ${unpackToDate(
                                  activity.closedActivity.closedDate
                                ).toFormat('ccc d LLL')}`}
                              </Typography>
                            </Box>
                          )}
                        </Grid>

                        <Grid item container xs={12} className={classes.contentRow}>
                          <Grid item xs={12}>
                            <Typography variant='h6' style={{ marginRight: 16 }}>
                              {'Attachments'}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Box paddingLeft={2}>
                              <FileUploadGallery allowMultiple uploadedFiles={uploadedFiles} viewOnly />
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.contentRow}>
                          <Box pb={8}>
                            <Typography variant='h6' style={{ marginRight: 16 }}>
                              {'Volunteer Waitlist'}
                            </Typography>
                            <Box paddingLeft={2}>
                              <FormControlLabel
                                data-track='actCnl-overview-volunteer-waitlist-switch'
                                control={
                                  <Switch
                                    checked={!activity.isEnrollable}
                                    onChange={runUpdateEnrollable(!activity.isEnrollable)}
                                    color='primary'
                                  />
                                }
                                label='Default new volunteers to waitlist'
                              />
                              <Typography
                                variant='body2'
                                color='textSecondary'
                                style={{ marginLeft: 50, maxWidth: 480 }}
                              >
                                {`If your activity doesn't have the capacity for more volunteer intake, turn on the waitlist so
                        that volunteers know that they might not be able to join this activity immediately but are still
                        able to express their interest.`}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </PaneScrollable>
                </Pane>
              </Grid>
            )}
            {showNewVolunteers && hasNewVolunteers && (
              <Grid
                item
                sm={12}
                md={4}
                style={
                  compact // make this bad boy behave like a fullscreen dialog on mobile
                    ? {
                        display: 'flex',
                        flex: '1 1 auto',
                        overflowY: 'hidden',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        height: '100vh',
                        width: '100vw',
                        zIndex: theme.zIndex.modal,
                        backgroundColor: theme.palette.common.white,
                      }
                    : {
                        display: 'flex',
                        flex: '1 1 auto',
                        width: LOG_WIDTH,
                        minWidth: LOG_WIDTH,
                        overflowY: 'hidden',
                      }
                }
              >
                <Pane>
                  {compact ? (
                    <>
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        paddingY={1}
                        paddingX={2}
                        alignItems='center'
                        alignContent='center'
                      >
                        <Typography variant='h5'>New people</Typography>

                        <IconButton
                          key='close'
                          aria-label='Close'
                          color='inherit'
                          onClick={() => setShowNewVolunteers(false)}
                        >
                          <Close color='action' />
                        </IconButton>
                      </Box>

                      <Divider />
                    </>
                  ) : null}

                  <PaneScrollable>
                    {activity?.isRestrictedActivity && (
                      <>
                        <Box paddingLeft={2} paddingRight={'2px'}>
                          <Box display='flex' alignContent='center' alignItems='center' justifyContent='space-between'>
                            <Box width={1}>
                              <Typography>
                                {`New
                    ${activityApplications.length ? ` (${activityApplications.length})` : ''}
                    `}
                              </Typography>
                            </Box>
                            <Box width={1} justifyContent='flex-end'>
                              <SearchField
                                placeholder='Search People'
                                growLeft
                                onChange={(e) => setRightSidebarSearchFilter(e.target.value)}
                              />
                            </Box>
                            <IconButton
                              data-track='actCnl-overview-new-volunteers-actions'
                              aria-controls='incoming-volunteers-action-menu'
                              aria-haspopup='true'
                              onClick={handleOpenIncomingVolunteersActionMenu}
                            >
                              <MoreVert />
                            </IconButton>

                            <Menu
                              id='incoming-volunteers-action-menu'
                              anchorEl={incomingVolunteersActionMenuAnchorEl}
                              keepMounted
                              open={Boolean(incomingVolunteersActionMenuAnchorEl)}
                              onClose={handleCloseIncomingVolunteersActionMenu}
                            >
                              <MenuItem
                                data-track='actCnl-overview-new-volunteers-actions-approve-all'
                                disabled={!activityApplications.length}
                                onClick={() => {
                                  runApproveAllIncomingVolunteers();
                                  handleCloseIncomingVolunteersActionMenu();
                                }}
                              >
                                {'Approve all new'}
                              </MenuItem>
                              <MenuItem
                                data-track='actCnl-overview-new-volunteers-actions-waitlist-all'
                                disabled={!activityApplications.length}
                                onClick={() => {
                                  runWaitlistAllIncomingVolunteers();
                                  handleCloseIncomingVolunteersActionMenu();
                                }}
                              >
                                {'Move all to waitlist'}
                              </MenuItem>
                            </Menu>
                          </Box>

                          <Box width={1}>
                            <Typography variant='caption'>
                              {
                                'Click on a volunteer to open the review window. Use the review window to add them to the team, or move them to the waitlist.'
                              }
                            </Typography>
                          </Box>
                        </Box>

                        <Box component='ul' className={classes.volunteerListBox}>
                          {activityApplications
                            .filter((applicant) =>
                              rightSidebarSearchFilter
                                ? applicant.volunteer.profile.preferredName
                                    .concat(` ${applicant.volunteer.profile.lastName}`)
                                    .toLowerCase()
                                    .includes(rightSidebarSearchFilter.toLowerCase())
                                : true
                            )
                            .sort((a, b) => {
                              return a.dateApplied < b.dateApplied ? -1 : 1;
                            })
                            .map((activityApplication) => {
                              const { volunteer } = activityApplication;
                              const displayText = `${volunteer.profile.preferredName} ${volunteer.profile.lastName}`;
                              return (
                                <ButtonBase
                                  data-track='actCnl-overview-new-volunteers-list-item'
                                  component='li'
                                  key={activityApplication.activityApplicationId}
                                  className={classes.waitlistVolunteerListItemBox}
                                  onClick={() => setSelectedActivityApplication(activityApplication)}
                                >
                                  <Avatar
                                    preferredName={volunteer.profile.preferredName}
                                    lastName={volunteer.profile.lastName}
                                    avatarUrl={volunteer.profile.avatarUrl}
                                    size={28}
                                  />
                                  <Box>
                                    <Typography className={classes.volunteerListItemText}>{displayText}</Typography>
                                    <SemanticTime
                                      dateTime={unpackToDateTime(activityApplication.dateApplied)}
                                      typographyProps={{ className: classes.volunteerListItemDate }}
                                    />
                                  </Box>
                                </ButtonBase>
                              );
                            })}
                        </Box>
                      </>
                    )}
                    {!!activityWaitlistings.length && (
                      <>
                        <Box marginTop={3} paddingLeft={2} paddingRight={1}>
                          <Box display='flex' alignContent='center' alignItems='center' justifyContent='space-between'>
                            <Typography>
                              {`Waitlist
                    ${activityWaitlistings.length ? ` (${activityWaitlistings.length})` : ''}
                      `}
                            </Typography>
                          </Box>
                          <Box width={1}>
                            <Typography variant='caption'>
                              {'Volunteers who have been waitlisted after applying for this activity. '}
                            </Typography>
                          </Box>
                        </Box>

                        <Box component='ul' className={classes.volunteerListBox}>
                          {activityWaitlistings
                            .filter((waiting) =>
                              rightSidebarSearchFilter
                                ? waiting.volunteer.profile.preferredName
                                    .concat(` ${waiting.volunteer.profile.lastName}`)
                                    .toLowerCase()
                                    .includes(rightSidebarSearchFilter.toLowerCase())
                                : true
                            )
                            .sort((a, b) => {
                              return a.dateAdded < b.dateAdded ? -1 : 1;
                            })
                            .map((activityWaitlisting) => {
                              const { volunteer } = activityWaitlisting;
                              const displayText = `${volunteer.profile.preferredName} ${volunteer.profile.lastName}`;
                              return (
                                <ButtonBase
                                  data-track='actCnl-overview-waitlist-volunteers-list-item'
                                  component='li'
                                  key={activityWaitlisting.activityWaitlistingId}
                                  className={classes.waitlistVolunteerListItemBox}
                                  onClick={() => setSelectedActivityWaitlisting(activityWaitlisting)}
                                >
                                  <Avatar
                                    preferredName={volunteer.profile.preferredName}
                                    lastName={volunteer.profile.lastName}
                                    avatarUrl={volunteer.profile.avatarUrl}
                                    size={28}
                                  />
                                  <Box>
                                    <Typography className={classes.volunteerListItemText}>{displayText}</Typography>
                                    <SemanticTime
                                      dateTime={unpackToDateTime(activityWaitlisting.dateAdded)}
                                      typographyProps={{ className: classes.volunteerListItemDate }}
                                    />
                                  </Box>
                                </ButtonBase>
                              );
                            })}
                        </Box>
                      </>
                    )}
                  </PaneScrollable>
                </Pane>
              </Grid>
            )}
          </Grid>

          <AddVolunteersDialog
            open={showAddVolunteerToTeamDialog}
            onClose={() => setShowAddVolunteerToTeamDialog(false)}
            onSubmit={(volunteers) => {
              setEditedTeam((prevTeam) => {
                if (!prevTeam) return prevTeam;
                return sortEditedTeam({
                  ...prevTeam,
                  teamMembers: [
                    ...prevTeam.teamMembers,
                    ...volunteers.map((volunteer) => ({
                      profileId: volunteer.profile.profileId,
                      userId: volunteer.profile.userId,
                      volunteerId: volunteer.volunteerId,
                      preferredName: volunteer.profile.preferredName,
                      lastName: volunteer.profile.lastName,
                      avatarUrl: volunteer.profile.avatarUrl ?? undefined,
                      position: 'gv',
                    })),
                  ],
                });
              });
            }}
            programId={activity.program.programId}
            ignoreVolunteerIds={[
              ...(editedTeam?.teamMembers.map((x) => x.volunteerId) ?? []),
              ...activityApplications.map((application) => application.volunteer.volunteerId),
              ...activityWaitlistings.map((waitlisting) => waitlisting.volunteer.volunteerId),
            ]}
            dialogContentText={`Select one or more volunteers to add to the team. Hit 'Cancel' to close this window.`}
          />

          <SelectActivityClosureReasonDialog
            open={showClosureReasonDialog}
            handleClose={() => setShowClosureReasonDialog(false)}
            handleSelection={({ activityClosureReasonId, dateEnded }) => {
              runCloseActivity({ activityClosureReasonId, dateEnded });
              setShowClosureReasonDialog(false);
            }}
            activityName={activity.name}
          />
        </>
      ) : null}

      <VolunteerCommonProfileProvider>
        <ActivityApplicationReviewDialog
          handleClose={() => setSelectedActivityApplication(undefined)}
          handleApprove={() => {
            if (!selectedActivityApplication) return;
            runApproveIncomingVolunteer(selectedActivityApplication);
            setSelectedActivityApplication(undefined);
          }}
          handleWaitlist={() => {
            if (!selectedActivityApplication) return;
            runWaitlistIncomingVolunteer(selectedActivityApplication);
            setSelectedActivityApplication(undefined);
          }}
          activityApplicationId={selectedActivityApplication?.activityApplicationId}
        />
      </VolunteerCommonProfileProvider>

      <ActivityWaitlistingReviewDialog
        handleClose={() => setSelectedActivityWaitlisting(undefined)}
        handleEnrol={() => {
          if (!selectedActivityWaitlisting) return;
          runEnrolWaitlisting(selectedActivityWaitlisting);
          setSelectedActivityWaitlisting(undefined);
        }}
        activityWaitlistingId={selectedActivityWaitlisting?.activityWaitlistingId}
      />
    </Box>
  );
});

const ActivityTimelineManagementSkeletonLoading = () => {
  const { theme } = useCampfireTheme();
  return (
    <Grid container>
      <Grid item xs style={{ borderRight: `1px solid ${theme.color.grey.border}` }}>
        <Box
          padding={2}
          display='flex'
          style={{ boxSizing: 'border-box' }}
          justifyContent='space-between'
          width={1}
          borderBottom={`1px solid ${theme.color.grey.border}`}
        >
          <Skeleton variant='text' height={15} width='35%' />
          <Box display='flex'>
            <Skeleton variant='text' height={20} width={75} style={{ marginRight: 8 }} />
            <Skeleton variant='text' height={20} width={75} style={{ marginRight: 8 }} />
            <Skeleton variant='text' height={20} width={75} style={{ marginRight: 8 }} />
          </Box>
        </Box>

        <Box
          padding={2}
          paddingBottom={4}
          width={1}
          style={{ boxSizing: 'border-box' }}
          borderBottom={`1px solid ${theme.color.grey.border}`}
        >
          <Skeleton variant='text' height={15} width='45%' />
          <Skeleton variant='text' height={10} width='25%' />
          <Skeleton variant='text' height={10} width='25%' />
        </Box>

        <Box padding={2} width={1}>
          <Skeleton variant='text' height={15} width='65%' />
        </Box>
      </Grid>
      <Grid item style={{ width: 260, maxWidth: 260 }}>
        <Box padding={3}>
          <Skeleton variant='text' height={15} width='75%' />
          <Box display='flex' alignContent='center' alignItems='center' marginY={2}>
            <Skeleton variant='circle' width={28} height={28} style={{ marginRight: 6 }} />
            <Skeleton variant='text' height={10} width={75} />
          </Box>
          <Box display='flex' alignContent='center' alignItems='center' marginY={2}>
            <Skeleton variant='circle' width={28} height={28} style={{ marginRight: 6 }} />
            <Skeleton variant='text' height={10} width={75} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
