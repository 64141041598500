import React, { useState } from 'react';
import { HoverText } from '@campfire/hover-link';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box } from '@material-ui/core';
import { useCancelActivityFetch, CancelActivityParams } from '../activity-timeline-actions';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { SelectActivityCancellationReasonDialog } from '../common/SelectActivityCancellationReasonDialog';

const CancelPastActivityHoverText = (props: {
  activityId: string;
  activityName: string;
  activityDate: string;
  onCancelActivity?: (res: { cancelledActivityId: string; activityDate: string }) => void;
}) => {
  const { activityId, activityName, activityDate, onCancelActivity } = props;
  const [showCancellationReasonDialog, setShowCancellationReasonDialog] = useState(false);
  const cancelActivity = useCancelActivityFetch();
  const { setSnackbar } = useSnackbar();

  const runCancelActivity = ({
    activityCancellationReasonId,
    description,
    addedAttachments,
    attachmentCreationTokens,
  }: CancelActivityParams) => {
    cancelActivity
      .run({
        activityId,
        activityDate,
        activityCancellationReasonId,
        description: JSON.stringify(description),
        addedAttachments,
        attachmentCreationTokens,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to cancel activity',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Activity cancelled',
          variant: 'success',
        });

        setShowCancellationReasonDialog(false);

        if (onCancelActivity) {
          onCancelActivity({ cancelledActivityId: res.data.data.cancelledActivityId, activityDate });
        }
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to cancel activity',
          variant: 'error',
        })
      );
  };

  return (
    <>
      <TitularTooltip
        description={`Cancel this activity to indicate that it didn't run. You'll be asked to confirm this cancellation.`}
      >
        <Box>
          <HoverText
            data-track='actCnl-cancel-past-activity'
            variant='body2'
            color='error'
            hoverColor='error'
            onClick={() => setShowCancellationReasonDialog(true)}
          >
            {'Cancel Activity'}
          </HoverText>
        </Box>
      </TitularTooltip>

      <SelectActivityCancellationReasonDialog
        open={showCancellationReasonDialog}
        handleClose={() => setShowCancellationReasonDialog(false)}
        handleSelection={runCancelActivity}
        activityName={activityName}
        activityDate={activityDate}
      />
    </>
  );
};

export { CancelPastActivityHoverText };
