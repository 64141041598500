import { HoverLink } from '@campfire/hover-link';
import { Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import React from 'react';
import { useLocation, useParams } from 'react-router';

interface Props {
  returnSlug?: string;
  title: string;
}

const ResourceViewerTitle = (props: Props) => {
  const { returnSlug, title } = props;
  const { resourceId } = useParams();
  const location = useLocation();

  return (
    <HoverLink
      data-track='resources-resource-viewer-title'
      to={returnSlug ?? (resourceId ? location.pathname.replace(`/${resourceId}`, '') : location.pathname)}
      color='textPrimary'
      hoverColor='textPrimary'
      disableUnderline
    >
      <Typography variant='h4' style={{ fontWeight: 'bold' }}>
        <KeyboardArrowLeft fontSize='large' style={{ fontWeight: 'bold', marginBottom: -6 }} />
        {title}
      </Typography>
    </HoverLink>
  );
};

export { ResourceViewerTitle };
