import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { withRouter, RouteComponentProps, Route } from 'react-router';
import { CampfireSwitch } from '../../../content-blocks/common/CampfireSwitch';
import { Dashboard } from './Dashboard/Dashboard';
import { TasksList } from './TasksList/TasksList';
import { TasksMenubar } from './TasksMenubar';
import { TaskFormBuilder } from '../admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/TaskFormBuilder';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { ADMIN_CONSOLE_GET_VOLUNTEER_PROFILE_TASKS } from '../admin-console/admin-console-content-pages/admin-console-volunteer-profile/admin-console-volunteer-profile.gql';
import { AdminConsoleGetVolunteerProfileTasks } from '../admin-console/admin-console-content-pages/admin-console-volunteer-profile/__generated__/AdminConsoleGetVolunteerProfileTasks';
import { LoadingContextProvider } from '../admin-console/admin-console-content-pages/admin-console-volunteer-profile/AdminConsoleLoadingContext';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 'calc(100vh - 57px)',
      overflowY: 'hidden',
      paddingLeft: (props: { isMobile: boolean }) => (props.isMobile ? '20px' : '60px'),
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export const TasksScreenInner = withRouter(({ match }: RouteComponentProps) => {
  const { isMobile } = useCampfireTheme();
  const classes = useStyles({ isMobile });
  const { data, refetch } = useCampfireQuery<AdminConsoleGetVolunteerProfileTasks, undefined>(
    ADMIN_CONSOLE_GET_VOLUNTEER_PROFILE_TASKS
  );
  const cakeSelectItems = useMemo(
    () =>
      data?.vm.cakes.map((cake) => ({
        label: cake.title,
        value: cake.cakeId,
      })) ?? [],
    [data]
  );

  const remainingManualTasks = useMemo(
    () => data?.vm.cakes.flatMap((cake) => cake.allTasks.filter((task) => !task.automateApproval)).length ?? 0,
    [data]
  );

  return (
    <React.Fragment>
      <CampfireSwitch>
        <Route
          path={`${match?.path}/task-builder`}
          render={() => (
            <TaskFormBuilder
              cakeSelectItems={cakeSelectItems}
              remainingManualTasks={remainingManualTasks}
              refetch={refetch}
            />
          )}
        />

        <Route
          exact
          path={match?.path}
          render={() => (
            <Box className={classes.container}>
              <Box paddingTop='24px' paddingRight={isMobile ? '20px' : '60px'}>
                <TasksMenubar />
              </Box>
              <Dashboard />
            </Box>
          )}
        />
        <Route
          exact
          path={`${match?.path}/tasks`}
          render={() => (
            <Box className={classes.container}>
              <Box paddingTop='24px' paddingRight={isMobile ? '20px' : '60px'}>
                <TasksMenubar />
              </Box>
              <TasksList refetchFormCakes={refetch} />
            </Box>
          )}
        />
      </CampfireSwitch>
    </React.Fragment>
  );
});

export const TasksScreen = () => (
  <LoadingContextProvider>
    <TasksScreenInner />
  </LoadingContextProvider>
);
