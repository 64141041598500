import React from 'react';
import { Grid, Typography, Card } from '@material-ui/core';
import { HoverText } from '@campfire/hover-link';
import { TitularTooltip } from '@campfire/titular-tooltip/lib';

import { useFormBuilderDialogContext } from '../../FormBuilderDialogContext';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';

import { Task_taskItems_VOLUNTEER_TaskItemFieldType as FieldTaskItemType } from '../../../__generated__/Task';
import { FieldTaskItemTitle } from './task-item-fields/FieldTaskItemTitle';
import { TaskItemField } from './task-item-fields/TaskItemField';
import { TaskListItemContext } from '../../TaskListItemsContext';

interface Props {
  data: FieldTaskItemType;
}

export const FieldTaskItem = (props: Props) => {
  const { data } = props;
  const { editField } = useFormBuilderDialogContext();
  const { theme } = useCampfireTheme();
  const { draggingId } = React.useContext(TaskListItemContext);

  return (
    <Card
      elevation={0}
      style={{
        padding: 16,
        marginBottom: 8,
        width: '100%',
        border: `1px solid ${theme.color.grey.border}`,
      }}
    >
      <Grid container spacing={1} style={{ padding: 8 }}>
        <FieldTaskItemTitle __typename={data.field.__typename} name={data.field.name} optional={data.optional} />

        <HoverText color='primary' onClick={() => editField(data)}>
          {'Edit Field'}
        </HoverText>

        <TitularTooltip description={'Press and hold to reorder this field'}>
          <Grid item container>
            <Grid item xs={12}>
              <Typography color='textSecondary' variant='body2'>
                {data.field.description}
              </Typography>
            </Grid>

            <Grid item xs={10}>
              <TaskItemField field={data.field} isDragging={data.taskItemId === draggingId} />
            </Grid>
          </Grid>
        </TitularTooltip>
      </Grid>
    </Card>
  );
};
