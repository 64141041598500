import { TabletButton } from '@campfire/tablet-button/lib';
import { Snackbar, SnackbarContent, Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useCampfireVersion } from './useCampfireVersion';

export const CampfireVersionSnackbar = () => {
  const { updateAvailable } = useCampfireVersion();

  return (
    <Snackbar open={updateAvailable} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <SnackbarContent
        aria-describedby='version-snackbar'
        message={
          <Box>
            <Grid container alignItems='center' alignContent='center'>
              <Grid item>
                <Typography variant='subtitle2'>{'New version available'}</Typography>
                <Typography variant='caption'>
                  {'Please click to refresh your browser and get the latest version of Volaby'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        }
        action={
          <TabletButton color='primary' variant='contained' size='medium' onClick={() => window.location.reload()}>
            Click to Refresh
          </TabletButton>
        }
      />
    </Snackbar>
  );
};
