import { gql } from '@apollo/client';

export const CREATE_ROSTER_TEMPLATE_PROGRAM = gql`
  fragment CreateRosterTemplateProgram on VOLUNTEER_ProgramType {
    programId
    name
  }
`;

export const CREATE_ROSTER_TEMPLATE_ACTIVITY = gql`
  fragment CreateRosterTemplateActivity on VOLUNTEER_ActivityType {
    activityId
    name
    nextX(x: 8)
    ... on VOLUNTEER_NonRecurringActivityType {
      __typename
    }
    ... on VOLUNTEER_RecurringActivityType {
      __typename
    }
    startDate
    locationType
    activityLocation {
      activityLocationId
      placesAddress {
        placesId
        description
        formatted
        latLong {
          lat
          long
        }
      }
    }
    program {
      programId
      name
    }
    activityRemoteLocation {
      activityRemoteLocationId
      details
    }
    program {
      programId
    }
    sessions {
      ...CreateRosterTemplateActivitySession
    }
    rosterTemplates {
      rosterTemplateId
      rosterDate
    }
  }

  fragment CreateRosterTemplateActivitySession on VOLUNTEER_SessionType {
    sessionId
    name
    startTime
    endTime
    minVolunteers
    maxVolunteers
    reportType {
      reportTypeId
      name
    }
    activityLocation {
      placesAddress {
        formatted
      }
    }
    activity {
      activityLocation {
        placesAddress {
          formatted
        }
      }
      activityRemoteLocation {
        details
      }
    }
    autoReport
  }
`;

export const CREATE_ROSTER_TEMPLATE_GET_PROGRAMS_AND_ACTIVITIES = gql`
  query CreateRosterTemplateGetProgramsAndActivities {
    vm {
      programs(hasRule: { rule: ManageActivities }) {
        ...CreateRosterTemplateProgram
      }
      activities(hasRule: { rule: ManageActivities }) {
        ...CreateRosterTemplateActivity
      }
    }
  }

  ${CREATE_ROSTER_TEMPLATE_PROGRAM},
  ${CREATE_ROSTER_TEMPLATE_ACTIVITY}
`;

export const CREATE_ROSTER_TEMPLATE_GET_PROGRAMS_AND_ACTIVITIES_BOOGALOO = gql`
  query CreateRosterTemplateGetProgramsAndActivitiesBoogaloo($activityIds: [String!]!) {
    vm {
      activities(activityIds: $activityIds) {
        ...CreateRosterTemplateActivity
      }
    }
  }

  ${CREATE_ROSTER_TEMPLATE_PROGRAM},
  ${CREATE_ROSTER_TEMPLATE_ACTIVITY}
`;

export const EDIT_ROSTER_TEMPLATE_GET_ROSTER_TEMPLATE = gql`
  query EditRosterTemplateGetRosterTemplate($rosterTemplateId: String!) {
    vm {
      rosterTemplate(rosterTemplateId: $rosterTemplateId) {
        ...EditRosterTemplateRosterTemplate
      }
    }
  }
  fragment EditRosterTemplateRosterTemplate on VOLUNTEER_RosterTemplateType {
    rosterTemplateId
    rosterNotes
    activity {
      activityId
      name
      program {
        programId
      }
      activityLocation {
        placesAddress {
          ...EditRosterTemplatePlacesAddress
        }
      }
    }
    rosterDate
    placesAddress {
      ...EditRosterTemplatePlacesAddress
    }
    remoteLocation {
      ...EditRosterTemplateRemoteLocation
    }
    publishedRoster {
      rosterId
      activityDate
      rosterTemplate {
        rosterTemplateId
      }
    }
    draftRoster {
      draftRosterId
    }
  }

  fragment EditRosterTemplatePlacesAddress on PlacesAddressType {
    placesId
    description
    formatted
    latLong {
      lat
      long
    }
  }

  fragment EditRosterTemplateRemoteLocation on VOLUNTEER_ActivityRemoteLocationType {
    activityRemoteLocationId
    details
  }
`;

export const ROSTER_TEMPLATE_CHECK_RECURRENCE_DATE = gql`
  query RosterTemplateCheckRecurrenceDate($activityId: String!, $rosterDate: Date) {
    vm {
      activity(activityId: $activityId) {
        activityId
        startDate
        occursOn(date: $rosterDate)
      }
    }
  }
`;

export const ROSTER_TEMPLATE_ACTIVITY_CANCELLATION_REASONS = gql`
  query RosterTemplateActivityCancellationReasons {
    vm {
      activityCancellationReasons {
        activityCancellationReasonId
        label
      }
    }
  }
`;
