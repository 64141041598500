import React from 'react';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import {
  DASHBOARD_WIDGET_GET_FIELDS,
  DASHBOARD_WIDGET_GET_PROGRAMS,
  DASHBOARD_WIDGET_GET_TAGS,
} from './my-dashboard-widget.gql';
import {
  DashBoardWidgetGetPrograms,
  DashBoardWidgetGetPrograms_vm_programs as IProgram,
} from './__generated__/DashBoardWidgetGetPrograms';
import {
  DashBoardWidgetGetTags,
  DashBoardWidgetGetTags_vm_activityTags as IActivityTag,
} from './__generated__/DashBoardWidgetGetTags';
import {
  DashboardWidgetGetFields,
  DashboardWidgetGetFields_vm_allFields as IReportField,
} from './__generated__/DashboardWidgetGetFields';

export type WidgetOptions = {
  programs: IProgram[];
  activityTags: IActivityTag[];
  reportFields: IReportField[];
};

export const WidgetContext = React.createContext<WidgetOptions>({
  programs: [],
  activityTags: [],
  reportFields: [],
});

export const WidgetContextProvider = ({ children }: { children: React.ReactElement }) => {
  const { data: vmProgram } = useCampfireQuery<DashBoardWidgetGetPrograms, {}>(DASHBOARD_WIDGET_GET_PROGRAMS);
  const { data: vmActivityTag } = useCampfireQuery<DashBoardWidgetGetTags, {}>(DASHBOARD_WIDGET_GET_TAGS);
  const { data: vmNumericFields } = useCampfireQuery<DashboardWidgetGetFields, {}>(DASHBOARD_WIDGET_GET_FIELDS);
  return (
    <WidgetContext.Provider
      value={{
        programs: vmProgram?.vm.programs || [],
        activityTags: vmActivityTag?.vm.activityTags || [],
        reportFields: vmNumericFields?.vm.allFields || [],
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};
