import { unpackToDate } from '@campfire/hot-date/lib';
import { HoverLink } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  Input,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  Theme,
} from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { fade } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight, ExpandMore } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import React, { Dispatch, memo, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useMouseEnter } from '../../common/mouse-enter-delay';
import { FutureSidebarItemStatus, PastSidebarItemStatus, SidebarFilters } from './ActivityTimelineSidebar';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useActivityTimelineContext } from '../ActivityTimelineContext';
import { SearchField } from '../../../../../common/inputs/SearchField';

export type SidebarTabKeys = 'past' | 'future';

type Props = {
  isLoading: boolean;
  setSelectedTab: (tab: SidebarTabKeys) => void;
  selectedTab: SidebarTabKeys;
  setDates: (startDate: string, endDate: string) => void;
  disableFuture: boolean;
  disablePast: boolean;
  sidebarActivities: {
    activityId: string;
    name: string;
    program: {
      programId: string;
    };
  }[];
  sidebarPrograms: {
    programId: string;
    name: string;
  }[];
  sidebarFilters: SidebarFilters;
  setSidebarFilters: Dispatch<SetStateAction<SidebarFilters>>;
};

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const PAST_STATUS_ITEMS = [
  { value: 'complete', label: 'Complete' },
  { value: 'incomplete', label: 'Incomplete' },
  { value: 'not-started', label: 'Not Started' },
  { value: 'cancelled', label: 'Cancelled' },
];

const UPCOMING_STATUS_ITEMS = [
  { value: 'published', label: 'Published' },
  { value: 'unpublished', label: 'Unpublished Changes' },
  { value: 'not-published', label: 'Not Published' },
  { value: 'not-started', label: 'Not Started' },
  { value: 'cancelled', label: 'Cancelled' },
];

const useSelectStyles = makeStyles(() =>
  createStyles({
    icon: {
      marginTop: 4,
      marginRight: -5,
      fontSize: 16,
    },
  })
);

const useInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      backgroundColor: '#ffffff',
      position: 'relative',
      fontSize: 11,
      padding: 0,
      paddingLeft: 4,
      paddingRight: 18,
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.2),
      },
    },
  })
);

const useCheckboxStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
  })
);

const ActivityTimelineFilters = ({
  sidebarActivities,
  sidebarPrograms,
  sidebarFilters,
  setSidebarFilters,
  selectedTab,
}: {
  sidebarActivities: {
    activityId: string;
    name: string;
    program: {
      programId: string;
    };
  }[];
  sidebarPrograms: {
    programId: string;
    name: string;
  }[];
  sidebarFilters: SidebarFilters;
  setSidebarFilters: Dispatch<SetStateAction<SidebarFilters>>;
  selectedTab: SidebarTabKeys;
}) => {
  const selectClasses = useSelectStyles();
  const inputClasses = useInputStyles();
  const checkboxClasses = useCheckboxStyles();
  const programFilterTooltip = useMouseEnter(false, 500);
  const activityFilterTooltip = useMouseEnter(false, 500);
  const statusFilterTooltip = useMouseEnter(false, 500);

  const SelectPropsValue: SelectProps = {
    MenuProps: { PaperProps: { style: { maxHeight: 300 } } },
    multiple: true,
    classes: selectClasses,
    IconComponent: ExpandMore,
    input: <Input disableUnderline classes={inputClasses} />,
  };

  const sidebarActivitiesForSelectedPrograms = useMemo(() => {
    return sidebarActivities.filter((activity) => sidebarFilters.programIds.includes(activity.program.programId));
  }, [sidebarActivities, sidebarFilters.programIds]);

  return (
    <Box display='flex'>
      <FormControl variant='outlined'>
        <TitularTooltip
          open={programFilterTooltip.open}
          enterDelay={500}
          title='Program Filter'
          description='Show/hide programs from the timeline.'
        >
          <Select
            {...SelectPropsValue}
            data-track='actCnl-program-filter-select'
            displayEmpty
            onMouseEnter={programFilterTooltip.handleMouseEnter}
            onMouseLeave={programFilterTooltip.handleMouseLeave}
            onClick={programFilterTooltip.handleMouseLeave}
            value={sidebarFilters.programIds}
            onChange={(event) => {
              const selectValues = event.target.value as string[];
              setSidebarFilters((prevFilters) => {
                const isUncheckingAllProgramsItem =
                  prevFilters.programIds.length === selectValues.length - 1 && selectValues.includes('all');
                if (isUncheckingAllProgramsItem && prevFilters.programIds.length === sidebarPrograms.length) {
                  return {
                    ...prevFilters,
                    programIds: [],
                    activityIds: [],
                  };
                }

                const programIds = selectValues.includes('all')
                  ? sidebarPrograms.map((x) => x.programId)
                  : selectValues;
                return {
                  ...prevFilters,
                  programIds: programIds,
                  activityIds: sidebarActivities
                    .filter((activity) => programIds.includes(activity.program.programId))
                    .map((x) => x.activityId),
                };
              });
            }}
            renderValue={() => {
              const { length } = sidebarFilters.programIds;
              if (!length) return 'Programs';
              if (length === sidebarPrograms.length) return 'All Programs';
              return `${sidebarFilters.programIds.length} Programs`;
            }}
          >
            <MenuItem value={'all'}>
              <Checkbox
                checked={sidebarFilters.programIds.length === sidebarPrograms.length}
                className={checkboxClasses.checkbox}
              />
              <ListItemText primary={'All Programs'} />
            </MenuItem>
            {sidebarPrograms.map((sidebarProgram) => (
              <MenuItem key={sidebarProgram.programId} value={sidebarProgram.programId}>
                <Checkbox
                  checked={sidebarFilters.programIds.includes(sidebarProgram.programId)}
                  className={checkboxClasses.checkbox}
                />
                <ListItemText primary={sidebarProgram.name} />
              </MenuItem>
            ))}
          </Select>
        </TitularTooltip>
      </FormControl>

      <FormControl variant='outlined'>
        <TitularTooltip
          open={activityFilterTooltip.open}
          enterDelay={500}
          title='Activity Filter'
          description='Show/hide activities from the timeline.'
        >
          <Select
            {...SelectPropsValue}
            data-track='actCnl-activity-filter-select'
            displayEmpty
            onMouseEnter={activityFilterTooltip.handleMouseEnter}
            onMouseLeave={activityFilterTooltip.handleMouseLeave}
            onClick={activityFilterTooltip.handleMouseLeave}
            value={sidebarFilters.activityIds}
            onChange={(event) => {
              const selectValues = event.target.value as string[];
              setSidebarFilters((prevFilters) => {
                const isUncheckingAllActivitiesItem =
                  prevFilters.activityIds.length === selectValues.length - 1 && selectValues.includes('all');
                if (
                  isUncheckingAllActivitiesItem &&
                  prevFilters.activityIds.length === sidebarActivitiesForSelectedPrograms.length
                ) {
                  return {
                    ...prevFilters,
                    activityIds: [],
                  };
                }
                return {
                  ...prevFilters,
                  activityIds: selectValues.includes('all')
                    ? sidebarActivitiesForSelectedPrograms.map((x) => x.activityId)
                    : selectValues,
                };
              });
            }}
            renderValue={() => {
              const { length } = sidebarFilters.activityIds;
              if (!length) return 'Activities';
              if (length === sidebarActivitiesForSelectedPrograms.length) return 'All Activities';
              return `${sidebarFilters.activityIds.length} Activities`;
            }}
          >
            <MenuItem value={'all'}>
              <Checkbox
                checked={sidebarFilters.activityIds.length === sidebarActivitiesForSelectedPrograms.length}
                className={checkboxClasses.checkbox}
              />
              <ListItemText primary={'All Activities'} />
            </MenuItem>
            {sidebarActivitiesForSelectedPrograms.map((sidebarActivity) => (
              <MenuItem key={sidebarActivity.activityId} value={sidebarActivity.activityId}>
                <Checkbox
                  checked={sidebarFilters.activityIds.includes(sidebarActivity.activityId)}
                  className={checkboxClasses.checkbox}
                />
                <ListItemText primary={sidebarActivity.name} />
              </MenuItem>
            ))}
          </Select>
        </TitularTooltip>
      </FormControl>

      {selectedTab === 'past' ? (
        <TitularTooltip
          open={statusFilterTooltip.open}
          enterDelay={500}
          title='Status Filter'
          description='Filter the past activities based on report status.'
        >
          <FormControl variant='outlined'>
            <Select
              {...SelectPropsValue}
              data-track='actCnl-status-filter-select-past'
              displayEmpty
              onMouseEnter={statusFilterTooltip.handleMouseEnter}
              onMouseLeave={statusFilterTooltip.handleMouseLeave}
              onClick={statusFilterTooltip.handleMouseLeave}
              value={sidebarFilters.pastStatuses}
              onChange={(event) =>
                setSidebarFilters((prevFilters) => ({
                  ...prevFilters,
                  pastStatuses: event.target.value as PastSidebarItemStatus[],
                }))
              }
              renderValue={(selected) => {
                const { length } = selected as string[];
                if (!length) return 'Statuses';
                if (length === PAST_STATUS_ITEMS.length) return 'Any Status';
                return `${(selected as string[]).length} Statuses`;
              }}
            >
              {PAST_STATUS_ITEMS.map((pastStatus) => (
                <MenuItem key={pastStatus.value} value={pastStatus.value}>
                  <Checkbox
                    checked={sidebarFilters.pastStatuses.indexOf(pastStatus.value as PastSidebarItemStatus) > -1}
                    className={checkboxClasses.checkbox}
                  />
                  <ListItemText primary={pastStatus.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TitularTooltip>
      ) : (
        <TitularTooltip
          open={statusFilterTooltip.open}
          enterDelay={500}
          title='Status Filter'
          description='Filter the upcoming activities based on roster status.'
        >
          <FormControl variant='outlined'>
            <Select
              {...SelectPropsValue}
              data-track='actCnl-status-filter-select-upcoming'
              displayEmpty
              onMouseEnter={statusFilterTooltip.handleMouseEnter}
              onMouseLeave={statusFilterTooltip.handleMouseLeave}
              onClick={statusFilterTooltip.handleMouseLeave}
              value={sidebarFilters.futureStatuses}
              onChange={(event) =>
                setSidebarFilters((prevFilters) => ({
                  ...prevFilters,
                  futureStatuses: event.target.value as FutureSidebarItemStatus[],
                }))
              }
              renderValue={(selected) => {
                const { length } = selected as string[];
                if (!length) return 'Select Statuses';
                if (length === UPCOMING_STATUS_ITEMS.length) return 'Any Status';
                return `${(selected as string[]).length} Statuses`;
              }}
            >
              {UPCOMING_STATUS_ITEMS.map((futureStatus) => (
                <MenuItem key={futureStatus.value} value={futureStatus.value}>
                  <Checkbox
                    checked={sidebarFilters.futureStatuses.indexOf(futureStatus.value as FutureSidebarItemStatus) > -1}
                    className={checkboxClasses.checkbox}
                  />
                  <ListItemText primary={futureStatus.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TitularTooltip>
      )}
    </Box>
  );
};

const ActivityTimelineSidebarHeader = memo((props: Props) => {
  const {
    isLoading,
    setSelectedTab,
    selectedTab,
    setDates,
    disablePast,
    disableFuture,
    sidebarActivities,
    sidebarPrograms,
    sidebarFilters,
    setSidebarFilters,
  } = props;

  const { selectedDate } = useActivityTimelineContext();
  const routeMatch = useRouteMatch();
  const defaultDate = selectedDate ? unpackToDate(selectedDate) : DateTime.local();
  const [month, setMonth] = useState(defaultDate.month);
  const [year, setYear] = useState(defaultDate.year);

  useEffect(() => {
    const dateTime = DateTime.local(year, month);
    setDates(dateTime.startOf('month').toISODate(), dateTime.endOf('month').toISODate());
  }, [month, year]);

  const nextYear = () => {
    setYear(year + 1);
  };
  const previousYear = () => {
    setYear(year - 1);
  };
  const nextMonth = () => {
    const newMonth = month >= 12 ? 1 : month + 1;
    setMonth(newMonth);
    if (newMonth === 1) nextYear();
  };
  const previousMonth = () => {
    const newMonth = month <= 1 ? 12 : month - 1;
    setMonth(newMonth);
    if (newMonth === 12) previousYear();
  };

  const monthName = useMemo(() => {
    return MONTHS[(month - 1) % 12];
  }, [month]);

  const { isMobile, theme } = useCampfireTheme();

  return (
    <Box
      width={1}
      display='flex'
      flexDirection='column'
      alignContent='center'
      alignItems='center'
      justifyContent='center'
      bgcolor='#fff'
      borderRadius='0 0 4px 0'
      borderRight='solid rgba(0,0,0,0.2) 1px'
      borderBottom='solid rgba(0,0,0,0.2) 1px'
      style={{ boxSizing: 'border-box' }}
    >
      <Box paddingTop={1} paddingLeft={2} paddingRight={isMobile ? 8 : 2} width={1} style={{ boxSizing: 'border-box' }}>
        <SearchField
          data-track='actCnl-activities-directory-search-input'
          placeholder='Search Activities'
          onChange={(e) =>
            setSidebarFilters({
              ...sidebarFilters,
              searchInput: (e.target.value as string) || undefined,
            })
          }
          fullWidth
        />
      </Box>

      <Box
        className='YearMonthPickerContainer'
        width={1}
        style={{ boxSizing: 'content-box' }}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        alignContent='center'
      >
        <Box marginTop={1} display='flex' justifyContent='center' width={1} style={{ boxSizing: 'border-box' }}>
          <Box display='flex' alignContent='center' alignItems='center'>
            <TitularTooltip enterDelay={500} title='Previous year'>
              <IconButton
                data-track='actCnl-previous-year'
                size='small'
                onClick={() => previousYear()}
                style={{ marginLeft: 10 }}
              >
                <ChevronLeft fontSize='small' />
              </IconButton>
            </TitularTooltip>

            <Typography variant='body2'>{`${year}`}</Typography>

            <TitularTooltip enterDelay={500} title='Next year' data-track='actCnl-next-year'>
              <IconButton
                data-track='actCnl-activities-directory-previous-year'
                size='small'
                onClick={() => nextYear()}
              >
                <ChevronRight fontSize='small' />
              </IconButton>
            </TitularTooltip>
          </Box>

          <Box display='flex' alignContent='center' alignItems='center' style={{ marginTop: -8 }}>
            <TitularTooltip enterDelay={500} title='Previous month'>
              <IconButton data-track='actCnl-previous-month' onClick={() => previousMonth()}>
                <ChevronLeft />
              </IconButton>
            </TitularTooltip>

            <Typography variant='h5'>{`${monthName}`}</Typography>

            <TitularTooltip enterDelay={500} title='Next month'>
              <IconButton data-track='actCnl-next-month' onClick={() => nextMonth()}>
                <ChevronRight />
              </IconButton>
            </TitularTooltip>
          </Box>
        </Box>
      </Box>

      <TitularTooltip
        enterDelay={500}
        title='Timeline'
        description='Switch between viewing past (history) and future (upcoming) activity dates within the selected month.'
      >
        <Box display='flex' bgcolor={theme.color.grey.border} borderRadius={36}>
          <TabletButton
            size='small'
            color='primary'
            variant={selectedTab === 'past' ? 'contained' : 'text'}
            disabled={disablePast}
            onClick={() => setSelectedTab('past')}
            data-track='actCnl-history'
          >
            {'History'}
          </TabletButton>
          <TabletButton
            size='small'
            color='primary'
            variant={selectedTab === 'future' ? 'contained' : 'text'}
            disabled={disableFuture}
            onClick={() => setSelectedTab('future')}
            data-track='actCnl-upcoming'
          >
            {'Upcoming'}
          </TabletButton>
        </Box>
      </TitularTooltip>

      <Box paddingY={1}>
        <ActivityTimelineFilters
          sidebarActivities={sidebarActivities}
          sidebarPrograms={sidebarPrograms}
          sidebarFilters={sidebarFilters}
          setSidebarFilters={setSidebarFilters}
          selectedTab={selectedTab}
        />
      </Box>
      {isLoading || routeMatch?.path !== '/management/activities/map' ? null : (
        <Box padding={2} bgcolor='white' textAlign='center'>
          <HoverLink data-track='actCnl-go-to-timeline' to='/management/rosters' color='primary' hoverColor='primary'>
            <Typography variant='body2'>{'Click here to see all Activities'}</Typography>
          </HoverLink>
        </Box>
      )}
    </Box>
  );
});

export { ActivityTimelineSidebarHeader };
