import { Box, ButtonBase, Typography } from '@material-ui/core';
import { AddCircleRounded, DoneRounded, RemoveCircleRounded } from '@material-ui/icons';
import classNames from 'classnames';
import React from 'react';
import { ROSTER_SESSION_STATUS_TEXT } from '../activity-roster-model';
import {
  useRosterSessionCellStyles,
  useRosterSessionCellStylesAvailable,
  useRosterSessionCellStylesNoIndication,
  useRosterSessionCellStylesRemoved,
  useRosterSessionCellStylesRostered,
  useRosterSessionCellStylesSelected,
  useRosterSessionCellStylesUnavailable,
} from '../activity-roster-styles';

export type RosterSessionCellStatus =
  | 'rostered'
  | 'available'
  | 'noIndication'
  | 'unavailable'
  | 'selected'
  | 'removed';

interface Props {
  variant: RosterSessionCellStatus;
  onClick?: () => void;
  leftCap?: boolean;
  rightCap?: boolean;
}

const RosterSessionCell = React.memo((props: Props) => {
  const { variant } = props;

  if (variant === 'rostered') return <RosteredCell {...props} />;
  if (variant === 'available') return <AvailableCell {...props} />;
  if (variant === 'unavailable') return <UnavailableCell {...props} />;
  if (variant === 'selected') return <SelectedCell {...props} />;
  if (variant === 'removed') return <RemovedCell {...props} />;
  return <NoIndicationCell {...props} />;
});

const RosteredCell = (props: Omit<Props, 'variant'>) => {
  const { leftCap, rightCap, onClick } = props;
  const classes = useRosterSessionCellStyles();
  const rosteredClasses = useRosterSessionCellStylesRostered();

  return (
    <ButtonBase
      className={classNames(classes.rootButtonBase, {
        [classes.leftCapButtonBase]: leftCap,
        [classes.rightCapButtonBase]: rightCap,
      })}
      onClick={onClick}
    >
      <Box
        className={classNames(classes.rootBox, rosteredClasses.box, {
          [classes.leftCapBox]: leftCap,
          [classes.rightCapBox]: rightCap,
        })}
      >
        <DoneRounded fontSize='small' style={{ marginRight: 8 }} />

        <Typography variant='body2'>{ROSTER_SESSION_STATUS_TEXT.publishedSelected}</Typography>
      </Box>
    </ButtonBase>
  );
};

const AvailableCell = (props: Omit<Props, 'variant'>) => {
  const { leftCap, rightCap, onClick } = props;
  const classes = useRosterSessionCellStyles();
  const availableClasses = useRosterSessionCellStylesAvailable();

  return (
    <ButtonBase
      className={classNames(classes.rootButtonBase, {
        [classes.leftCapButtonBase]: leftCap,
        [classes.rightCapButtonBase]: rightCap,
      })}
      onClick={onClick}
    >
      <Box
        className={classNames(classes.rootBox, availableClasses.box, {
          [classes.leftCapBox]: leftCap,
          [classes.rightCapBox]: rightCap,
        })}
      >
        <Typography variant='body2'>{ROSTER_SESSION_STATUS_TEXT.available}</Typography>
      </Box>
    </ButtonBase>
  );
};

const NoIndicationCell = (props: Omit<Props, 'variant'>) => {
  const { leftCap, rightCap, onClick } = props;
  const classes = useRosterSessionCellStyles();
  const noIndicationClasses = useRosterSessionCellStylesNoIndication();

  return (
    <ButtonBase
      className={classNames(classes.rootButtonBase, {
        [classes.leftCapButtonBase]: leftCap,
        [classes.rightCapButtonBase]: rightCap,
      })}
      onClick={onClick}
    >
      <Box
        className={classNames(classes.rootBox, noIndicationClasses.box, {
          [classes.leftCapBox]: leftCap,
          [classes.rightCapBox]: rightCap,
        })}
      >
        <Typography variant='body2'>{ROSTER_SESSION_STATUS_TEXT.noIndication}</Typography>
      </Box>
    </ButtonBase>
  );
};

const UnavailableCell = (props: Omit<Props, 'variant'>) => {
  const { leftCap, rightCap, onClick } = props;
  const classes = useRosterSessionCellStyles();
  const unavailableClasses = useRosterSessionCellStylesUnavailable();

  return (
    <ButtonBase
      disabled
      className={classNames(classes.rootButtonBase, {
        [classes.leftCapButtonBase]: leftCap,
        [classes.rightCapButtonBase]: rightCap,
      })}
      onClick={onClick}
    >
      <Box
        className={classNames(classes.rootBox, unavailableClasses.unavailableBox, {
          [classes.leftCapBox]: leftCap,
          [classes.rightCapBox]: rightCap,
        })}
      >
        <Typography variant='body2' color='textSecondary'>
          {ROSTER_SESSION_STATUS_TEXT.unavailable}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

const SelectedCell = (props: Omit<Props, 'variant'>) => {
  const { leftCap, rightCap, onClick } = props;
  const classes = useRosterSessionCellStyles();
  const selectedClasses = useRosterSessionCellStylesSelected();

  return (
    <ButtonBase
      className={classNames(classes.rootButtonBase, {
        [classes.leftCapButtonBase]: leftCap,
        [classes.rightCapButtonBase]: rightCap,
      })}
      onClick={onClick}
    >
      <Box
        className={classNames(classes.rootBox, selectedClasses.box, {
          [classes.leftCapBox]: leftCap,
          [classes.rightCapBox]: rightCap,
        })}
      >
        <AddCircleRounded fontSize='small' style={{ marginRight: 8 }} />
        <Typography variant='body2' color='textSecondary'>
          {ROSTER_SESSION_STATUS_TEXT.selected}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

const RemovedCell = (props: Omit<Props, 'variant'>) => {
  const { leftCap, rightCap, onClick } = props;
  const classes = useRosterSessionCellStyles();
  const removedClasses = useRosterSessionCellStylesRemoved();

  return (
    <ButtonBase
      className={classNames(classes.rootButtonBase, {
        [classes.leftCapButtonBase]: leftCap,
        [classes.rightCapButtonBase]: rightCap,
      })}
      onClick={onClick}
    >
      <Box
        className={classNames(classes.rootBox, removedClasses.box, {
          [classes.leftCapBox]: leftCap,
          [classes.rightCapBox]: rightCap,
        })}
      >
        <RemoveCircleRounded fontSize='small' style={{ marginRight: 8 }} />
        <Typography variant='body2' color='textSecondary'>
          {ROSTER_SESSION_STATUS_TEXT.removed}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

export { RosterSessionCell };
