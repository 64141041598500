import React from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { ActivityHeader } from '../ActivitySidebar/ActivityHeader';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

export const ActivityDetailsSkeleton = ({ inMyShift }: { inMyShift?: boolean }) => {
  const { isMobile } = useCampfireTheme();

  return (
    <Box paddingY='1rem' width={isMobile ? 'calc(100vw - 4rem)' : 'unset'}>
      <Skeleton height='2rem' />
      <Skeleton height='4rem' style={{ marginBottom: '1rem' }} />
      {inMyShift ? null : (
        <>
          <ActivityHeader title='Upcoming Rosters' />
          <Skeleton height='5rem' style={{ marginBottom: '1rem' }} />
        </>
      )}
      <ActivityHeader title='Sessions' />
      <Skeleton height='3rem' variant='rect' style={{ marginTop: '1rem' }} />
      <Skeleton height='3rem' variant='rect' style={{ marginTop: '1rem' }} />
      <Skeleton height='3rem' variant='rect' style={{ marginTop: '1rem', marginBottom: '1rem' }} />
      <ActivityHeader title='Team Information' />
      <Box display='flex' marginTop='1rem' marginBottom='1rem'>
        <Skeleton variant='circle' width='40px' height='40px' />
        <Skeleton variant='text' width='120px' style={{ marginLeft: '1rem' }} />
      </Box>
      <Box display='flex' marginTop='1rem' marginBottom='1rem'>
        <Skeleton variant='circle' width='40px' height='40px' />
        <Skeleton variant='text' width='120px' style={{ marginLeft: '1rem' }} />
      </Box>
      <Box display='flex' marginTop='1rem' marginBottom='1rem'>
        <Skeleton variant='circle' width='40px' height='40px' />
        <Skeleton variant='text' width='120px' style={{ marginLeft: '1rem' }} />
      </Box>
      <ActivityHeader title='Attachments' />
      <Box display='flex' marginBottom='1rem'>
        <Skeleton width='112px' height='96px' style={{ marginRight: '2rem' }} />
        <Skeleton width='112px' height='96px' style={{ marginRight: '2rem' }} />
        <Skeleton width='112px' height='96px' style={{ marginRight: '2rem' }} />
      </Box>
      <ActivityHeader title='Location Information' />
      <Skeleton height='245px' variant='rect' />
    </Box>
  );
};
