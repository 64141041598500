import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Dialog, DialogTitle, Typography, Box, DialogContent, DialogActions } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { useCampfireQuery } from '../../../../../../../global/network/useCampfireQuery';
import { ActivityActionType } from '../../../../../activities-v2/useActivityActions';
import { AlertCard, getAlertCardColors } from '../../../../../../../common/cards/alert-card/AlertCard';
import { ActivityCard } from '../../../../../activity-report/ActivityReports/ActivityCard';
import { GET_CICO_ACTIVITY } from './cico-get-activity.gql';
import { GetCICOActivity, GetCICOActivityVariables } from './__generated__/GetCICOActivity';
import { ActivityReportDetailDialog } from '../../../../../activity-report/ActivityReports/SessionReport';

export const CheckOutFinishedNext = ({
  open,
  checkOutFinishedSessionId,
  activity,
  handleClose,
}: {
  open: boolean;
  checkOutFinishedSessionId: string;
  activity: ActivityActionType;
  handleClose: () => void;
}) => {
  const { theme } = useCampfireTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditting, setIsEditting] = React.useState(false);

  // const { setSnackbar } = useSnackbar();
  const history = useHistory();

  const { data: activityData } = useCampfireQuery<GetCICOActivity, GetCICOActivityVariables>(GET_CICO_ACTIVITY, {
    options: {
      variables: {
        activityId: activity.activityId,
        activityDate: activity.activityDate,
      },
    },
  });

  const session = activityData?.vm.activity?.allSessions.find((s) => s.sessionId === checkOutFinishedSessionId);
  const publishedRoster = activityData?.vm.activity?.publishedRoster as any;
  const sessionReport = activityData?.vm.activity?.activityReport?.sessionReports.find(
    (sr) => sr.session.sessionId === session?.sessionId
  );

  const onClickCard = () => {
    setDialogOpen(true);
    setIsEditting(true);
  };

  const onRedirect = () => {
    history.push(
      `/volunteering/activity-report?activityDate=${activity.activityDate}&activityId=${activity.activityId}&tab=incomplete`
    );
  };

  const { lightTextColor } = getAlertCardColors('success');

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Typography
          style={{ fontSize: '24px', fontWeight: 500, color: theme.color.grey.neutral500 }}
        >{`Check Out`}</Typography>
      </DialogTitle>
      <DialogContent dividers style={{ paddingLeft: 0, paddingRight: 0 }} id='dialog-content'>
        <Box px={3}>
          <AlertCard variant='success'>
            <Typography
              variant='subtitle2'
              display='inline'
              style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
            >
              Success! Volunteers have been checked out.
            </Typography>
          </AlertCard>
          <Box mt={3} mb={2}>
            <Typography
              style={{ fontSize: '21px', fontWeight: 500, color: theme.color.grey.neutral500, marginBottom: '8px' }}
            >{`What's Next?`}</Typography>
            <Typography style={{ fontSize: '16px', color: theme.color.grey.neutral500 }}>
              If the session or activity has concluded, you can click below to begin completing the activity report.
            </Typography>
          </Box>
          <Box>
            <ActivityCard {...activity} selected onSelectActivity={onClickCard} showAction />
          </Box>
        </Box>
        {session && dialogOpen && (
          <ActivityReportDetailDialog
            session={session}
            activityName={activity.name}
            activityDate={activity.activityDate}
            publishedRoster={publishedRoster}
            sessionReport={sessionReport}
            isEditting={isEditting}
            setIsEditting={setIsEditting}
            onEdit={onRedirect}
            dialogOpen={dialogOpen}
            onDialogClose={() => setDialogOpen(false)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box padding={2}>
          <TabletButton variant='text' onClick={handleClose} style={{ marginRight: 8 }}>
            {'Close'}
          </TabletButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
