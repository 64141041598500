import { TabletButton } from '@campfire/tablet-button';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { ButtonProps } from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import Wordmark from '../../../assets/branding/logo/volaby-original.svg';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { signInPath } from '../sign-in-path';

const BASE_MENU_BUTTON_STYLES: ButtonProps['style'] = {
  textTransform: 'none',
  paddingRight: 24,
  paddingLeft: 24,
  marginLeft: 2,
  marginRight: 2,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoButton: {
      height: theme.appShell.menu.height,
    },
  })
);

const DesktopPublicAppBar = () => {
  const { theme } = useCampfireTheme();
  const classes = useStyles();
  return (
    <AppBar style={{ boxShadow: 'none', borderBottom: 'solid 1px #e9e9e9' }}>
      <Paper elevation={0} square>
        <Box paddingX={1}>
          <Grid container justify='center'>
            <Grid item container xs={12} sm={10} md={9} lg={8} xl={6} justify='space-between'>
              <Grid item xs container alignContent='center' alignItems='center'>
                <Link to={signInPath}>
                  <ButtonBase centerRipple focusRipple className={classes.logoButton}>
                    <img src={Wordmark} height={theme.appShell.menu.height / 2} alt='campfire logo' />
                  </ButtonBase>
                </Link>
              </Grid>
              <Grid item xs container justify='flex-end' alignContent='center' alignItems='center'>
                <Box paddingY={1}>
                  <Link to={signInPath} style={{ textDecoration: 'none' }}>
                    <TabletButton style={{ ...BASE_MENU_BUTTON_STYLES }} size='medium'>
                      {'Sign In'}
                    </TabletButton>
                  </Link>
                  <Link to={'/apply'} style={{ textDecoration: 'none' }}>
                    <TabletButton
                      style={{
                        ...BASE_MENU_BUTTON_STYLES,
                        boxShadow: 'none',
                      }}
                      variant='contained'
                      color='primary'
                      size='medium'
                    >
                      {'Make an Account'}
                    </TabletButton>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </AppBar>
  );
};
export default DesktopPublicAppBar;
