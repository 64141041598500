import React, { ReactNode, useEffect, useState } from 'react';
import PageVisibility from './PageVisibility';

export interface PageVisibilityInterface {
  visibility: boolean | undefined;
  visibilityText: string | undefined;
  hiddenTime: number;
}

export const PageVisibilityContext = React.createContext<PageVisibilityInterface | undefined>(undefined);

export const PageVisibilityProvider = ({ children }: { children: ReactNode }) => {
  const [visibility, setVisibility] = useState<boolean | undefined>(true);
  const [visibilityText, setVisibilityText] = useState<string | undefined>();
  const [startTime, setStartTime] = useState(0);
  const [hiddenTime, setHiddenTime] = useState(0);

  useEffect(() => {
    if (visibility) {
      const diff = startTime === 0 ? 0 : new Date().getTime() - startTime;
      setHiddenTime(diff);
      setStartTime(0);
    } else {
      setStartTime(new Date().getTime());
    }
  }, [visibility]);

  const update = (isVisible: boolean, visibilityState: string) => {
    setVisibility(isVisible);
    setVisibilityText(visibilityState);
  };

  return (
    <PageVisibility onChange={update}>
      <PageVisibilityContext.Provider value={{ visibility, visibilityText, hiddenTime }}>
        {children}
      </PageVisibilityContext.Provider>
    </PageVisibility>
  );
};
