import React from 'react';
import { unpackToDateTime } from '@campfire/hot-date';
import { Box } from '@material-ui/core';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { CommonRequiringActionWidgetProps } from '../../../shared';
import { useCampfireQuery } from '../../../../../../../../../global/network/useCampfireQuery';
import { GET_TABLE_WIDGET_EXPIRING_TASKS } from './task-expiring-table-model.gql';
import {
  GetTableWidgetExpiringTasks,
  GetTableWidgetExpiringTasksVariables,
} from './__generated__/GetTableWidgetExpiringTasks';

export const TaskExpiringTableWidget = (props: CommonRequiringActionWidgetProps) => {
  const { filters } = props;
  const { programIds, activityIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };

  const { data, loading } = useCampfireQuery<GetTableWidgetExpiringTasks, GetTableWidgetExpiringTasksVariables>(
    GET_TABLE_WIDGET_EXPIRING_TASKS,
    {
      options: {
        variables: {
          status: 'expiring',
          programIds: programIds.length > 0 ? programIds : null,
          activityIds: activityIds.length > 0 ? activityIds : null,
        },
      },
    }
  );

  const tasksExpiring =
    data?.vm.tasks.map((task) => ({
      id: task.taskId,
      title: task.title,
      cake: task.cake.title,
      expiryDate: unpackToDateTime(task.expiryDate).toFormat('dd/MM/y'),
    })) || [];

  const columns: GridColumns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'cake',
      headerName: 'Category',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry Date',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
  ];

  return (
    <Box width='100%' height='100%'>
      <DataGrid columns={columns} rows={tasksExpiring} loading={loading} autoPageSize disableSelectionOnClick />
    </Box>
  );
};
