import React, { useContext } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { BorderColorOutlined, AddCircle } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';

import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { ManagementDashboardContext } from '../../../../program-manager/management-dashboard/ManagementDashboardProvider';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      height: 38,
      borderRadius: 6,
      padding: '6px 12px',
      marginLeft: '0.75rem',
      textTransform: 'capitalize',
    },
    text: {
      marginLeft: '4px',
      fontWeight: 600,
      fontSize: '13px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })
);

export const DashboardToolbar = () => {
  const { onCancelChangeLayout, onSubmitLayout, editLayout, setEditLayout, setShowEditWidget } = useContext(
    ManagementDashboardContext
  );
  const { theme, isSm } = useCampfireTheme();
  const classes = useStyles();
  return (
    <Box
      display='flex'
      flexDirection='row'
      width='100%'
      justifyContent='flex-end'
      alignItems='center'
      pb={isSm ? 0 : '14px'}
    >
      {editLayout ? (
        <React.Fragment>
          <Button
            variant={'outlined'}
            className={classes.button}
            style={{
              backgroundColor: '#FCFCFC',
              border: '1px solid #DCDCDC',
              color: theme.color.grey.neutral300,
            }}
            onClick={onCancelChangeLayout}
          >
            <Typography className={classes.text}>Cancel</Typography>
          </Button>
          <Button
            variant={'contained'}
            className={classes.button}
            style={{
              backgroundColor: theme.color.secondary.main900,
              border: '1px solid transparent',
              color: '#FCFCFC',
            }}
            onClick={onSubmitLayout}
          >
            <Typography className={classes.text}>Save</Typography>
          </Button>
        </React.Fragment>
      ) : (
        <Button
          variant={'outlined'}
          startIcon={<BorderColorOutlined style={{ marginTop: '1px', fontSize: '16px' }} />}
          className={classes.button}
          style={{
            backgroundColor: '#FCFCFC',
            border: '1px solid #DCDCDC',
            color: theme.color.grey.neutral300,
          }}
          onClick={() => setEditLayout(!editLayout)}
        >
          <Typography className={classes.text}>Edit</Typography>
        </Button>
      )}
      <Button
        startIcon={<AddCircle style={{ marginTop: '-1px', fontSize: '16px' }} />}
        className={classes.button}
        style={{
          backgroundColor: theme.color.secondary.main900,
          color: 'white',
        }}
        onClick={() => setShowEditWidget(true)}
      >
        <Typography className={classes.text}>Add Widget</Typography>
      </Button>
    </Box>
  );
};
