import React, { useEffect } from 'react';
import { Tabs, Tab, Theme, Box, TextField, MenuItem, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { Close } from '@material-ui/icons';

import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { sharedStyles } from '../../activities-v2/ActivityStyles/shared';

export enum ActivityReportEnum {
  INCOMPLETE = 'incomplete',
  COMPLETED = 'completed',
  OMITTED = 'omitted',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabRoot: {
      textTransform: 'capitalize',
      fontWeight: 900,
      fontSize: '20px',
      minWidth: 'unset',
    },
    selected: {
      color: `${theme.color.grey.neutralBrand900} !important`,
    },
    tabsFlexContainer: {
      alignContent: 'space-between',
      borderBottom: '2px solid',
      borderBottomColor: '#EFEFEF',
      height: 'calc(100% - 2px)',
    },
    root: {
      flex: 1,
      marginLeft: 60,
      marginRight: 60,
      marginTop: '1rem',
    },
  })
);

interface Props {
  children?: React.ReactNode;
}

export const ActivityReportTabHeader = ({ children }: Props) => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useQueryParam('tab', StringParam);
  const [{ activityId, activityDate }, setQuery] = useQueryParams({
    activityId: StringParam,
    activityDate: StringParam,
  });
  const tabs = ['incomplete', 'completed', 'omitted'];

  const { isSm } = useCampfireTheme();

  useEffect(() => {
    if (!selectedTab || tabs.findIndex((tabKey) => tabKey === selectedTab) === -1) {
      setSelectedTab(tabs[0]);
    }
  }, [selectedTab, tabs]);

  const handleChange = (_unused: any, newValue: ActivityReportEnum) => {
    setSelectedTab(newValue);
  };

  const textFieldClasses = sharedStyles.textField();
  const buttonClasses = sharedStyles.button();

  if (isSm) {
    return (
      <Box
        display='flex'
        alignItems='center'
        paddingLeft='1rem'
        paddingRight='1rem'
        justifyContent={activityDate && activityId ? 'flex-end' : 'center'}
        borderBottom='1px solid lightgrey'
        paddingBottom='1rem'
        paddingTop='15px'
      >
        {activityDate && activityId ? (
          <Button
            onClick={() => setQuery({ activityDate: undefined, activityId: undefined })}
            classes={buttonClasses}
            variant='outlined'
            startIcon={<Close />}
            style={{
              color: '#D93A00',
              borderColor: '#D93A00',
            }}
          >
            Close
          </Button>
        ) : (
          <React.Fragment>
            <TextField
              select
              value={selectedTab}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(true, event.target.value as ActivityReportEnum)
              }
              variant='outlined'
              InputProps={{ classes: textFieldClasses }}
              style={{ minWidth: '180px' }}
            >
              {[
                {
                  label: 'Incomplete reports',
                  value: ActivityReportEnum.INCOMPLETE,
                },
                {
                  label: 'Completed reports',
                  value: ActivityReportEnum.COMPLETED,
                },
                {
                  label: 'Omitted reports',
                  value: ActivityReportEnum.OMITTED,
                },
              ].map(({ label, value }) => (
                <MenuItem value={value}>{label}</MenuItem>
              ))}
            </TextField>
            {children}
          </React.Fragment>
        )}
      </Box>
    );
  }

  return (
    <Tabs
      value={selectedTab || ActivityReportEnum.INCOMPLETE}
      onChange={handleChange}
      indicatorColor='primary'
      textColor='primary'
      aria-label='icon tabs example'
      classes={{
        flexContainer: classes.tabsFlexContainer,
      }}
    >
      <Tab
        label='Incomplete Reports'
        classes={{ selected: classes.selected, root: classes.tabRoot }}
        value={ActivityReportEnum.INCOMPLETE}
      />
      <Tab
        label='Completed Reports'
        classes={{ selected: classes.selected, root: classes.tabRoot }}
        value={ActivityReportEnum.COMPLETED}
      />
      <Tab
        label='Omitted Reports'
        classes={{ selected: classes.selected, root: classes.tabRoot }}
        value={ActivityReportEnum.OMITTED}
      />
      <Box display='flex' justifyContent='flex-end' flex={1} alignItems='center'>
        {children}
      </Box>
    </Tabs>
  );
};
