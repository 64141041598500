import React, { ChangeEvent } from 'react';
import { Box, Theme, Grid, Typography, Divider, TextField, MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Select } from '@campfire/select';
import { MultiSelect } from '@campfire/multi-select';
import { makeStyles, createStyles } from '@material-ui/styles';
import { CreatWidgetFormValues, DimensionEnum, dimensionLabels, WidgetTypes } from '../../dashboard-widget-model';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';
import { useWidgetContextForm } from '../../useWidgetContextForm';
import { SelectedValues } from '../SelectedValues';
import { TimeSettings } from '../TimeSettings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.color.grey.neutral500,
      fontWeight: 700,
      fontSize: '18px',
      marginBottom: '8px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 700,
      color: theme.color.grey.neutral500,
      paddingBottom: '6px',
    },
    valueItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '56px',
      borderRadius: '6px',
      marginTop: '-8px',
      padding: '0 16px',
      cursor: 'pointer',
      position: 'relative',

      border: `1px solid ${theme.color.grey.neutralBrand200}`,
    },
  })
);

interface Props {
  maxValue?: number;
}

const dimensionOptions = [DimensionEnum.BY_PROGRAM, DimensionEnum.BY_ACTIVITY, DimensionEnum.BY_ACTIVITY_TAG];
const incidentOptions = [
  DimensionEnum.BY_PROGRAM,
  DimensionEnum.BY_INCIDENT_CATEGORY,
  DimensionEnum.BY_INCIDENT_DETAIL,
  DimensionEnum.BY_INCIDENT_GROUP,
  DimensionEnum.BY_INCIDENT_LOCATION,
  DimensionEnum.BY_INCIDENT_SEVERITY,
];

const volunteerOptions = [DimensionEnum.BY_PROGRAM];

export const LineChartWidgetForm = ({ maxValue = 5 }: Props) => {
  const { theme } = useCampfireTheme();
  const classes = useStyles();
  const { values, setFieldValue, handleChange } = useFormikContext<CreatWidgetFormValues>();
  const programOption = values.byValues.some(({ tag }) => ['programs'].includes(tag));
  const incidentOption = values.byValues.some(({ tag }) => tag === 'incidents');
  const volunteerOption = values.byValues.some(({ tag }) => tag === 'volunteers');
  const fieldValueOption = values.byValues.some(
    ({ name, tag }) => tag === 'reporting' && name === 'report-field-value'
  );
  const dimensionItems = [DimensionEnum.BY_TIME].map((value) => ({
    value,
    label: dimensionLabels[value],
  }));

  const groups = React.useMemo(() => {
    if (programOption) {
      return [];
    }

    if (incidentOption) {
      return incidentOptions;
    }

    if (volunteerOption) {
      return volunteerOptions;
    }

    return dimensionOptions;
  }, [programOption, incidentOption, volunteerOption, fieldValueOption]);

  const groupItems = [
    {
      label: 'None (time only)',
      value: 'none',
    },
  ].concat(
    groups.map((value) => ({
      value,
      label: dimensionLabels[value],
    }))
  );

  const handleChangeDimension = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('dimension', e.target.value);
    setFieldValue('programIds', []);
    setFieldValue('activityIds', []);
    setFieldValue('tagIds', []);
  };
  const showInterval = values.dimension === DimensionEnum.BY_TIME;
  const showHighlight = values.byType === WidgetTypes.NUMBER_CHART;

  const { programs, activities, activityTags, reportFields } = useWidgetContextForm();
  const [fieldId, setFieldId] = React.useState(values.fieldIds[0] || reportFields[0]?.value);

  React.useEffect(() => {
    if (fieldValueOption) {
      setFieldValue('fieldIds', [fieldId]);
    }
  }, [fieldId, fieldValueOption]);

  React.useEffect(() => {
    setFieldValue('dimension', DimensionEnum.BY_TIME);
  }, []);

  return (
    <Grid container direction='column' style={{ width: '100%', marginBottom: '16px' }}>
      <Grid item xs={12}>
        <Typography className={classes.title}>Filtering</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography className={classes.label}>Dimension</Typography>
            <Select
              fullWidth
              name='dimension'
              variant='outlined'
              items={dimensionItems}
              renderFunction={(item) => item.label}
              valueFunction={(item) => item.value}
              InputProps={{
                style: { borderRadius: '8px', color: theme.color.grey.neutral300 },
              }}
              onChange={handleChangeDimension}
            />
          </Grid>
          {values.dimension === DimensionEnum.BY_TIME && (
            <Grid item xs={6}>
              <Typography className={classes.label}>Group by</Typography>
              <Select
                fullWidth
                variant='outlined'
                name='group'
                value={values.group}
                items={groupItems}
                InputProps={{
                  style: { borderRadius: '8px', color: theme.color.grey.neutral300 },
                }}
                renderFunction={(item) => item.label}
                valueFunction={(item) => item.value}
                onChange={handleChange}
              />
            </Grid>
          )}
          {values.dimension === DimensionEnum.BY_PROGRAM && (
            <Grid item xs={6}>
              <Typography className={classes.label}>Programs</Typography>
              <MultiSelect
                variant='outlined'
                name='programIds'
                label='Select program/s'
                placeholder='All Programs'
                items={programs}
                style={{ borderRadius: '8px' }}
              />
            </Grid>
          )}
          {values.dimension === DimensionEnum.BY_ACTIVITY && (
            <Grid item xs={6}>
              <Typography className={classes.label}>Activities</Typography>
              <MultiSelect
                variant='outlined'
                name='activityIds'
                label='Select activity/activities'
                placeholder='All Activities'
                items={activities}
                style={{ borderRadius: '8px' }}
              />
            </Grid>
          )}
          {values.dimension === DimensionEnum.BY_ACTIVITY_TAG && (
            <Grid item xs={6}>
              <Typography className={classes.label}>Activity Tags</Typography>
              <MultiSelect
                variant='outlined'
                name='tagIds'
                placeholder='All Activity Tag'
                label='Select tag/s'
                items={activityTags}
                style={{ borderRadius: '8px' }}
              />
            </Grid>
          )}
          {fieldValueOption && (
            <Grid item xs={6}>
              <Typography className={classes.label}>Report Fields</Typography>
              <TextField
                fullWidth
                select
                variant='outlined'
                placeholder='All Report Field'
                InputProps={{
                  style: { borderRadius: '8px', color: theme.color.grey.neutral300 },
                }}
                defaultValue={fieldId}
                value={fieldId}
                onChange={(e) => setFieldId(e.target.value)}
              >
                {reportFields.slice(0, reportFields.length - 2).map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box my={3}>
        <Divider />
      </Box>
      <TimeSettings showHighlight={showHighlight} showInterval={showInterval} />
      <Box my={3}>
        <Divider />
      </Box>
      <SelectedValues maxValue={maxValue} />
    </Grid>
  );
};
