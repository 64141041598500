import { Field } from '@campfire/field';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useVolunteerProfileActions } from '../../volunteer-profile-actions';

export const VolunteerCommonProfileExitDialog = ({
  open,
  onClose,
  handleSuccess,
  volunteerId,
  fullName,
  preferredName,
}: {
  open: boolean;
  onClose: () => void;
  handleSuccess: () => void;
  volunteerId: string;
  fullName: string;
  preferredName: string;
}) => {
  const { runFlagVolunteer, runFlagVolunteerLoading } = useVolunteerProfileActions();
  const { theme } = useCampfireTheme();

  return volunteerId ? (
    <Formik
      initialValues={{ reason: '' }}
      onSubmit={(values) => {
        runFlagVolunteer({ volunteerId, reason: values.reason, handleSuccess });
      }}
    >
      {() => (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby='flag-and-exit-volunteer-dialog-title'
          aria-describedby='flag-and-exit-volunteer-dialog-description'
        >
          <Form>
            <DialogTitle id='flag-and-exit-volunteer-dialog-title'>{`Exit Volunteer?`}</DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <LinearProgressOverlay isLoading={runFlagVolunteerLoading} />
              <Box paddingLeft={2} paddingRight={2}>
                <DialogContentText>{fullName}</DialogContentText>
                <DialogContentText>{`THIS ACTION CANNOT BE UNDONE`}</DialogContentText>
                <DialogContentText id='flag-and-exit-volunteer-dialog-description'>
                  {`${preferredName} will be flagged and exited. They will lose all access to Volaby and cannot be reactivated.`}
                </DialogContentText>
                <Field
                  required
                  autoFocus
                  name='reason'
                  multiline
                  rows={6}
                  variant='outlined'
                  label='Reasons for exit'
                  placeholder={`Provide a short statement on why ${preferredName} is being exited (required)`}
                  margin='normal'
                  fullWidth
                  helper
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>{`Cancel`}</Button>
              <Button
                type='submit'
                disabled={runFlagVolunteerLoading}
                style={{
                  color: theme.palette.error.main,
                }}
              >{`Exit Volunteer`}</Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  ) : null;
};
