import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import React, { useState } from 'react';
import { Box, Typography, TextField, MenuItem, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { DataGrid, GridColumns, GridCellParams, GridSortModel } from '@material-ui/data-grid';
import pluralize from 'pluralize';
import { uniqBy, isEqual, sortBy, groupBy } from 'lodash';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { DateTime } from 'luxon';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { unpackToDateTime } from '@campfire/hot-date';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { SearchField } from '../../../../common/inputs/SearchField';
import { GET_WAITLIST_VOLUNTEERS } from './GetWaitlistVolunteers.gql';
import {
  GetWaitlistVolunteers,
  GetWaitlistVolunteers_vm_activityWaitlistings_volunteer_profile as ProfileType,
} from './__generated__/GetWaitlistVolunteers';
import { WAITLIST_VOLUNTEERS_GET_PROPGRAMS } from './WaitlistVolunteersGetPrograms.gql';
import { WaitlistVolunteersGetPrograms } from './__generated__/WaitlistVolunteersGetPrograms';
import { WaitlistVolunteerMenuButton, WaitlistActions } from './WaitlistVolunteerMenu';
import {
  useUnenrolWaitlist,
  useEnrolWaitlistingFetch,
  useEnrolBulkWaitlist,
  useUnenrolBulkWaitlist,
} from '../../activity-management/activity-form-v2/activity-form-actions-v2';
import { VolunteerProfile } from '../../../../common/AvatarName';
import { TemplateSnackbar, SnackBarOptions } from '../../../../common/snackbars/TemplateSnackbar';
import { WaitlistRemovingMessage } from './WaitlistRemovingMessage';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ActivityNameAndPriority } from '../common/ActivityInfo';
import { ExportCsvDialog } from '../../../../common/dialogs/ExportCsvDialog';
import {
  downloadCSV,
  escapeDangerousCSVCharacters,
  replaceDoubleQuoteInString,
} from '../../../../common/functions/csv-utils';
import {
  ConfirmAddMaxVolunteer,
  ITeam,
} from '../../activities/activity-timeline/past/report/form/ConfirmAddMaxVolunteer';

const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('xs')]: {
        height: '560px',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-selectedRowCount': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-columnHeader--moving': {
        background: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
  })
);

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      paddingTop: '6px',
      paddingBottom: '7px',
      paddingRight: '10px',
      fontSize: '15px',
    },
    root: {
      height: '100%',
      width: '200px',
      borderRadius: '6px',
      borderTopColor: '#9E9E9E',
      borderRightColor: '#9E9E9E',
      borderBottomColor: '#9E9E9E',
      borderLeftColor: '#9E9E9E',
      color: '#444444',
    },
  })
);

interface Waitlist {
  id: string;
  program: string;
  profile: ProfileType;
  volunteerId: string;
  activityName: string;
  activityId: string;
  programId: string;
  dateAdded: DateTime;
  volunteerName: string;
  maxTeam: number | null;
  totalEnrolments: number;
}

interface WaitlistMap {
  [key: string]: Waitlist;
}

export function WaitlistVolunteersScreen() {
  const { data, loading: waitlistVolunteersLoading, refetch } = useCampfireQuery<GetWaitlistVolunteers, {}>(
    GET_WAITLIST_VOLUNTEERS
  );
  const { data: vmPrograms, loading: programsLoading } = useCampfireQuery<WaitlistVolunteersGetPrograms, {}>(
    WAITLIST_VOLUNTEERS_GET_PROPGRAMS
  );
  const [selectedWaitlistings, setSelectedWaitlistings] = React.useState<(string | number)[]>([]);
  const [openExportCsvDialog, setOpenExportCsvDialog] = React.useState<boolean>(false);
  const [filterValue, setFilterValue] = React.useState('');
  const [snackbar, setSnackbar] = React.useState<SnackBarOptions>();
  const [showRemoveDialog, setShowRemoveDialog] = React.useState<boolean>(false);
  const [selectedProgramId, setSelectedProgramId] = React.useState<string>('all');

  const [teamExceed, setTeamExceed] = React.useState(false);
  const [exceedingTeams, setExceedingTeams] = useState<ITeam>();

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'volunteerName',
      sort: 'asc',
    },
  ]);

  const waitlists: Waitlist[] =
    data?.vm.activityWaitlistings
      .filter(
        (waitlist) =>
          !waitlist.dateRemoved &&
          waitlist.activity.isActive &&
          !waitlist.activity.program.dateDeleted &&
          !waitlist.activity.program.dateSuspended &&
          !waitlist.volunteer.dateDeactivated &&
          !waitlist.volunteer.flagging?.dateFlagged
      )
      .map((waitlist) => ({
        id: waitlist.activityWaitlistingId,
        program: waitlist.activity.program.name,
        profile: waitlist.volunteer.profile,
        volunteerId: waitlist.volunteer.volunteerId,
        activityName: waitlist.activity.name,
        activityId: waitlist.activity.activityId,
        activityPriority: waitlist.activity.priority,
        programId: waitlist.activity.program.programId,
        volunteerName: `${waitlist.volunteer.profile.preferredName} ${waitlist.volunteer.profile.lastName}`,
        dateAdded: unpackToDateTime(waitlist.dateAdded),
        maxTeam: waitlist.activity.maxTeam,
        totalEnrolments: waitlist.activity.totalEnrolments,
      })) || [];

  const programs =
    vmPrograms?.vm.programs
      .filter((program) => !program.dateDeleted && !program.dateSuspended)
      .sort((a, b) => a.name.localeCompare(b.name)) || [];
  const programOptions = React.useMemo(() => [{ programId: 'all', name: 'All Programs' }].concat(programs), [programs]);
  const onSelectProgram = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProgramId(event.target.value);
  };

  const waitlistMap: WaitlistMap = waitlists.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur,
    }),
    {}
  );

  const showingWaitlists = waitlists
    .filter((waitlistDatum) =>
      `${waitlistDatum.profile.lastName} ${waitlistDatum.profile.preferredName} ${waitlistDatum.profile.preferredName} ${waitlistDatum.profile.lastName} ${waitlistDatum.profile.email} ${waitlistDatum.profile.contactNumber}`
        .toLowerCase()
        .includes(filterValue.toLowerCase())
    )
    .filter((waitlist) => selectedProgramId === 'all' || waitlist.programId === selectedProgramId);

  const enrolWaitlisting = useEnrolWaitlistingFetch();
  const unenrolWaitlisting = useUnenrolWaitlist();
  const enrolBulkWaitlisting = useEnrolBulkWaitlist();
  const unenrolBulkWaitlisting = useUnenrolBulkWaitlist();

  const selectedVolunteers = uniqBy(
    selectedWaitlistings.map((id) => waitlistMap[id]),
    (waitlist) => waitlist?.volunteerId
  );
  const selectedActivities = uniqBy(
    selectedWaitlistings.map((id) => waitlistMap[id]),
    (waitlist) => waitlist?.activityId
  );

  const volunteerText =
    selectedVolunteers.length === 1
      ? `${selectedVolunteers[0].profile.preferredName} ${selectedVolunteers[0].profile.lastName}`
      : `${selectedVolunteers.length} volunteers`;
  const activityText =
    selectedActivities.length === 1 ? selectedActivities[0].activityName : `${selectedActivities.length} activities`;

  const title = `Remove: ${volunteerText} from ${activityText}`;

  const maxTeamConfirm = React.useRef<any>();
  const maxTeamCancel = React.useRef<any>();

  const addToTeam = (id: string) => {
    enrolWaitlisting
      .run({
        activityWaitlistingId: id,
      })
      .then(() => {
        setSelectedWaitlistings((currentSelectings) =>
          currentSelectings.filter((selectedWaitlistingId) => selectedWaitlistingId !== id)
        );
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: 'Add waitlisting to team successfully',
        });
      });
  };

  const bulkAddToTeam = () => {
    enrolBulkWaitlisting
      .run({
        activityWaitlistingIds: selectedWaitlistings as string[],
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
          setSelectedWaitlistings([]);
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: `Add ${selectedWaitlistings.length} waitlisting to team successfully`,
        });
      });
  };

  const onAddToTeam = (id: string) => {
    const selectedWaitlist = waitlistMap[id];

    if (!selectedWaitlist) {
      return;
    }

    if (selectedWaitlist.maxTeam && selectedWaitlist.maxTeam <= selectedWaitlist.totalEnrolments) {
      setTeamExceed(true);
      maxTeamConfirm.current = () => {
        addToTeam(id);
        setTeamExceed(false);
      };
      maxTeamCancel.current = () => {
        setTeamExceed(false);
      };
    } else {
      addToTeam(id);
    }
  };

  const onWithdraw = (message: string) => {
    if (selectedWaitlistings.length === 1) {
      unenrolWaitlisting
        .run({
          activityWaitlistingId: selectedWaitlistings[0] as string,
          removalReason: message,
        })
        .then(() => {
          if (refetch) {
            setTimeout(refetch, 0);
          }
          setShowRemoveDialog(false);
          setSelectedWaitlistings([]);
          setSnackbar({
            open: true,
            variant: 'success',
            message: 'Remove waitlisting successfully',
          });
        });
    } else {
      unenrolBulkWaitlisting
        .run({
          activityWaitlistingIds: selectedWaitlistings as string[],
          removalReason: message,
        })
        .then(() => {
          if (refetch) {
            setTimeout(refetch, 0);
          }
          setShowRemoveDialog(false);
          setSelectedWaitlistings([]);
          setSnackbar({
            open: true,
            variant: 'success',
            message: `Remove ${selectedWaitlistings.length} waitlisting successfully`,
          });
        });
    }
  };

  const addBulk = () => {
    const waitlistsGroupByActivity = groupBy(
      selectedWaitlistings.map((waitlistId) => waitlistMap[waitlistId]),
      (a) => a.activityId
    );

    const teamsExceed = Object.keys(waitlistsGroupByActivity).filter((activityId) => {
      const waitlistsByActivity = waitlistsGroupByActivity[activityId];
      if (!waitlistsByActivity[0]) {
        return false;
      }
      if (
        waitlistsByActivity[0].maxTeam &&
        waitlistsByActivity[0].maxTeam < waitlistsByActivity.length + waitlistsByActivity[0].totalEnrolments
      ) {
        return true;
      }
      return false;
    });

    setExceedingTeams(
      teamsExceed.reduce(
        (acc, activityId) => ({
          ...acc,
          [activityId]: waitlistsGroupByActivity[activityId],
        }),
        {}
      )
    );

    if (teamsExceed.length > 0) {
      setTeamExceed(true);
      maxTeamConfirm.current = () => {
        bulkAddToTeam();
        setTeamExceed(false);
        setExceedingTeams(undefined);
      };
      maxTeamCancel.current = () => {
        setTeamExceed(false);
      };
    } else {
      bulkAddToTeam();
    }
  };

  const onActionBulk = (action: WaitlistActions) => {
    switch (action) {
      case 'add':
        addBulk();
        break;

      case 'withdraw':
        setShowRemoveDialog(true);
        break;

      default:
        break;
    }
  };

  const onAction = (action: WaitlistActions, id: string) => {
    switch (action) {
      case 'add':
        onAddToTeam(id);
        break;

      case 'withdraw':
        setSelectedWaitlistings([id]);
        setShowRemoveDialog(true);
        break;

      default:
        break;
    }
  };

  const columns: GridColumns = [
    {
      field: 'volunteerName',
      headerName: 'Volunteer',
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <VolunteerProfile
            inTable
            noTrim
            {...row.profile}
            name={`${row.profile.preferredName} ${row.profile.lastName}`}
          />
        );
      },
    },
    {
      field: 'program',
      headerName: 'Program',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 200,
    },
    {
      field: 'activityName',
      headerName: 'Activity',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 200,
      renderCell: ({ row }: any) => {
        return <ActivityNameAndPriority name={row.activityName} priority={row.activityPriority} />;
      },
    },
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 200,
      renderCell: ({ row }: any) => row.dateAdded.toFormat('dd/MM/y'),
    },
    {
      field: '',
      disableColumnMenu: true,
      headerName: '',
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <WaitlistVolunteerMenuButton onAction={(action: WaitlistActions) => onAction(action, row.id)} bulk={false} />
        );
      },
    },
  ];

  const onSortChange = (model: GridSortModel) => {
    if (!isEqual(model, sortModel)) {
      setSortModel(model);
    }
  };

  const exportCsvClick = () => {
    setOpenExportCsvDialog(true);
  };

  const handleSaveCsv = () => {
    const sortedWaitlists = sortBy(showingWaitlists, 'volunteerName');
    const rowsCsv = sortedWaitlists.map((waitlist) => buildRowCsv(waitlist));
    const incidentCsvData = buildCsvData(rowsCsv);
    const filename = `volunteers_waitlists_${DateTime.local().toFormat('yyyy-MM-d')}.csv`;
    downloadCSV(incidentCsvData, filename);
  };

  const buildRowCsv = (waitlist: Waitlist, separator = ',') => {
    if (!waitlist) return '';
    const parsedWaitlist = { ...waitlist, dateAdded: waitlist.dateAdded.toFormat('dd/MM/y') };
    const { volunteerName, program, activityName, dateAdded } = parsedWaitlist;
    const arrayRowData = [volunteerName, program, activityName, dateAdded];
    return `"${arrayRowData
      .map((columnData) => escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
      .join(`"${separator}"`)}"`;
  };

  const buildCsvData = (rowsCsv: string[], separator = ',') => {
    const csvHeaderArr = ['Volunteer', 'Program', 'Activity', 'Date Added'];
    const rowHeader = `"${csvHeaderArr.join(`"${separator}"`)}"`;
    const dataCsv = [rowHeader, ...rowsCsv];
    return `${dataCsv.join('\r\n')}`;
  };

  const tableClasses = useTableStyles();
  const classes = useStyles();
  const { isMobile } = useCampfireTheme();

  return waitlistVolunteersLoading || programsLoading ? (
    <Box position='relative' display='flex' flex='1 1 auto' height={1}>
      <CircularProgressOverlay isLoading />
    </Box>
  ) : (
    <Box
      flex={1}
      display='flex'
      flexDirection='column'
      paddingLeft={isMobile ? '16px' : '32px'}
      paddingRight='16px'
      paddingBottom='5px'
    >
      <Box
        display={isMobile ? 'block' : 'flex'}
        alignItems='center'
        paddingTop='16px'
        paddingBottom='16px'
        justifyContent='space-between'
      >
        <Typography style={{ fontSize: '15px', marginBottom: isMobile ? '8px' : '' }}>
          You have <strong>{waitlists.length}</strong> activity waitlistings
        </Typography>
        <Box display='flex'>
          {selectedWaitlistings.length > 0 && (
            <Box display='flex' alignItems='center' paddingRight='15px'>
              <Typography style={{ fontSize: '15px', paddingRight: '3px' }}>
                <strong>{selectedWaitlistings.length}</strong> selected
              </Typography>
              <WaitlistVolunteerMenuButton onAction={onActionBulk} bulk />
            </Box>
          )}
          <Box mr={2}>
            <TitularTooltip title='Download current view as a CSV file'>
              <TabletButton
                variant='outlined'
                color='primary'
                startIcon={<DownloadIcon color='inherit' />}
                onClick={exportCsvClick}
              >
                {`Download CSV`}
              </TabletButton>
            </TitularTooltip>
          </Box>
          <Box display='flex' alignItems='stretch' paddingRight='16px'>
            <TextField
              select
              value={selectedProgramId}
              onChange={onSelectProgram}
              variant='outlined'
              InputProps={{
                classes: classes,
              }}
              fullWidth
            >
              {programOptions.map((program) => (
                <MenuItem key={program.programId} value={program.programId}>
                  {program.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box display='flex' alignItems='stretch'>
            <SearchField
              fullWidth
              value={filterValue}
              onChange={(e: any) => setFilterValue(e.target.value)}
              placeholder={isMobile ? 'Search' : 'Search waitlist'}
            />
          </Box>
        </Box>
      </Box>
      <Box flex={1}>
        <DataGrid
          columns={columns}
          rows={showingWaitlists}
          classes={tableClasses}
          onSelectionModelChange={setSelectedWaitlistings}
          checkboxSelection
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(model) => onSortChange(model)}
        />
      </Box>
      {snackbar && <TemplateSnackbar {...snackbar} />}
      <WaitlistRemovingMessage
        open={selectedWaitlistings.length > 0 && showRemoveDialog}
        onClose={() => {
          setShowRemoveDialog(false);
        }}
        onRemove={(message) => onWithdraw(message)}
        title={title}
        bottomText={`The reason for removal will be sent to the ${pluralize(
          'volunteer',
          selectedVolunteers.length
        )} after clicking the ’Remove’ button.`}
        warningText={`You are removing ${pluralize('this', selectedVolunteers.length)} ${pluralize(
          'person',
          selectedVolunteers.length
        )} from the waitlist`}
      />
      {openExportCsvDialog && (
        <ExportCsvDialog
          open={openExportCsvDialog}
          close={() => setOpenExportCsvDialog(false)}
          handleSaveCsv={handleSaveCsv}
        />
      )}
      {teamExceed && (
        <ConfirmAddMaxVolunteer onCancel={maxTeamCancel.current} onOK={maxTeamConfirm.current} teams={exceedingTeams} />
      )}
    </Box>
  );
}
