import React, { useState, useMemo, memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSupportContext } from '../SupportContext';
import { SearchField } from '../../../../common/inputs/SearchField';
import { CategoriesList } from './CategoriesList';
import { AddNewButton } from './AddNewButton';

const CatalogueLandingPage = memo(() => {
  const { allCategories, isAdmin } = useSupportContext();

  const [searchInput, setSearchInput] = useState<string>();

  const categories = useMemo(
    () =>
      searchInput
        ? allCategories
            .filter(
              (c) =>
                c.title.toLowerCase().includes(searchInput.toLowerCase()) ||
                c.supportResources.find((r) => r.title.toLowerCase().includes(searchInput.toLowerCase()))
            )
            .map((c) => ({
              ...c,
              resources: c.supportResources.filter((r) => r.title.toLowerCase().includes(searchInput.toLowerCase())),
            }))
        : allCategories,
    [searchInput, allCategories]
  );

  return (
    <Grid container direction='column' alignItems='center' justify='center' style={{ padding: 16 }}>
      <Grid item container xs={12} justify='flex-end'>
        {isAdmin ? (
          <Grid item>
            <AddNewButton />
          </Grid>
        ) : null}
      </Grid>

      <Grid item container xs={12} md={4} justify='center'>
        <Typography variant='h5' style={{ fontWeight: 'bold' }}>
          {'Search by keyword'}
        </Typography>
      </Grid>

      <Grid item container xs={12} justify='center'>
        <Grid item xs={10} md={8}>
          <SearchField
            data-track='resources-search-input'
            fullWidth
            placeholder='Search'
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={8}>
        <CategoriesList categories={categories} />
      </Grid>
    </Grid>
  );
});

export { CatalogueLandingPage };
