import { Field } from '@campfire/field';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Grid, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { partition, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { useSendInvitationFetch } from './useSendInvitationFetch';
import { FullscreenDialog } from '../../../common/dialogs/FullscreenDialog';

const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const initialValues = {
  emails: '',
};

interface InvitationDialogProps {
  open: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

export const InvitationDialog = (props: InvitationDialogProps) => {
  const { open, handleClose, onSuccess } = props;
  const { sendInvitationPostRequest, isLoading, response } = useSendInvitationFetch();
  const { isMobile } = useCampfireTheme();
  const [textContent, setTextContent] = useState('');
  const [invalidEmails, setInvalidEmails] = useState('');

  useEffect(() => {
    if (response && response.ok && onSuccess) {
      onSuccess();
      setTextContent('');
      setInvalidEmails('');
    }
  }, [response]);

  return (
    <FullscreenDialog
      open={open}
      close={handleClose}
      title={'Invite Volunteers'}
      subtitle={'Invite your volunteers to join you on Volaby'}
      fullScreen={isMobile}
    >
      {isLoading && <LinearProgressOverlay isLoading={isLoading} />}

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const uniqEmails = uniq(values.emails.replace(/\s/g, '').split(',')).filter((value) => !!value);
          const [validEmails, checkedInvalidEmails] = partition(uniqEmails, (email) => EMAIL_REGEX.test(email));

          if (checkedInvalidEmails.length > 0) {
            // TODO: feed this back to user
            setInvalidEmails(checkedInvalidEmails.join(', '));
            return;
          }

          if (validEmails.length === 0) {
            return;
          }

          sendInvitationPostRequest({ emails: validEmails });
        }}
      >
        {() => {
          return (
            <Form style={{ padding: 6 }}>
              <Grid container spacing={2} style={{ paddingBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>{'Invitee Email Addresses'}</Typography>
                  <Field
                    name='emails'
                    slow
                    required
                    fullWidth
                    variant='outlined'
                    multiline
                    rows={isMobile ? 20 : 10}
                    placeholder='name@example.com, name@example.com, etc.'
                    value={textContent}
                    onChange={(event) => setTextContent(event.target.value)}
                  />

                  <Typography variant='body2' color='textSecondary' style={{ marginTop: 16, marginBottom: 16 }}>
                    {`Enter one or more email addresses, comma separated.
          Each invitee will receive an email with a link that will allow them to choose a password and continue straight into the onboarding steps.
          All invitees will appear in the Pending Approval section of the Incoming Volunteers page.
          You can revoke an invite at any time before it has been opened by the invitee.`}
                  </Typography>
                </Grid>

                {invalidEmails !== '' && (
                  <Grid item>
                    <Typography variant='subtitle2' color='error'>{`Invalid emails: ${invalidEmails}`}</Typography>
                  </Grid>
                )}

                <Grid container item justify='flex-end' direction='row'>
                  <Grid item>
                    <TabletButton color='primary' type='submit' variant='contained' size='large'>
                      Send invitation(s)
                    </TabletButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </FullscreenDialog>
  );
};
