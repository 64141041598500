import React, { useContext, useReducer } from 'react';

import {
  Task_taskItems_VOLUNTEER_TaskItemFieldType as FieldTaskItemType,
  Task_taskItems_VOLUNTEER_TaskItemContentType as ContentTaskItemType,
  Task_taskItems_VOLUNTEER_TaskItemHeadingType as HeadingTaskItemType,
  Task_taskItems_VOLUNTEER_TaskItemMediaType as MediaTaskItemType,
} from '../__generated__/Task';

type DialogState = {
  open: boolean;
};

interface HeadingDialogState extends DialogState {
  data: HeadingTaskItemType | null;
}

interface ContentDialogState extends DialogState {
  data: ContentTaskItemType | null;
}

interface FieldDialogState extends DialogState {
  data: FieldTaskItemType | null;
}

interface MediaDialogState extends DialogState {
  data: MediaTaskItemType | null;
}

interface FormBuilderDialogContextInterface {
  openHeadingDialogState: HeadingDialogState;
  addHeading: () => void;
  editHeading: (x: HeadingTaskItemType) => void;
  closeHeadingDialog: () => void;
  openContentDialogState: ContentDialogState;
  addContent: () => void;
  editContent: (x: ContentTaskItemType) => void;
  closeContentDialog: () => void;
  openFieldDialogState: FieldDialogState;
  addField: () => void;
  editField: (x: FieldTaskItemType) => void;
  closeFieldDialog: () => void;
  openMediaDialogState: MediaDialogState;
  addMedia: () => void;
  editMedia: (x: MediaTaskItemType) => void;
  closeMediaDialog: () => void;
}

interface FormBuilderDialogProviderProps {
  children: React.ReactNode;
}

type HeadingAction = { type: 'add' } | { type: 'edit'; data: HeadingTaskItemType } | { type: 'close' };
type ContentAction = { type: 'add' } | { type: 'edit'; data: ContentTaskItemType } | { type: 'close' };
type FieldAction = { type: 'add' } | { type: 'edit'; data: FieldTaskItemType } | { type: 'close' };
type MediaAction = { type: 'add' } | { type: 'edit'; data: MediaTaskItemType } | { type: 'close' };

const headingDialogReducer = (state: HeadingDialogState, action: HeadingAction): HeadingDialogState => {
  switch (action.type) {
    case 'add':
      return { open: true, data: null };
    case 'edit':
      return { open: true, data: action.data };
    case 'close':
      return { open: false, data: null };
    default:
      return state;
  }
};

const ContentDialogReducer = (state: ContentDialogState, action: ContentAction): ContentDialogState => {
  switch (action.type) {
    case 'add':
      return { open: true, data: null };
    case 'edit':
      return { open: true, data: action.data };
    case 'close':
      return { open: false, data: null };
    default:
      return state;
  }
};

const fieldDialogReducer = (state: FieldDialogState, action: FieldAction): FieldDialogState => {
  switch (action.type) {
    case 'add':
      return { open: true, data: null };
    case 'edit':
      return { open: true, data: action.data };
    case 'close':
      return { open: false, data: null };
    default:
      return state;
  }
};

const mediaDialogReducer = (state: MediaDialogState, action: MediaAction): MediaDialogState => {
  switch (action.type) {
    case 'add':
      return { open: true, data: null };
    case 'edit':
      return { open: true, data: action.data };
    case 'close':
      return { open: false, data: null };
    default:
      return state;
  }
};

const FormBuilderDialogContext = React.createContext<FormBuilderDialogContextInterface | undefined>(undefined);

export const FormBuilderDialogProvider = (props: FormBuilderDialogProviderProps) => {
  const [openHeadingDialogState, dispatchHeadingDialog] = useReducer(headingDialogReducer, { open: false, data: null });
  const [openContentDialogState, dispatchContentDialog] = useReducer(ContentDialogReducer, {
    open: false,
    data: null,
  });
  const [openFieldDialogState, dispatchFieldDialog] = useReducer(fieldDialogReducer, { open: false, data: null });
  const [openMediaDialogState, dispatchMediaDialog] = useReducer(mediaDialogReducer, { open: false, data: null });

  const addHeading = () => dispatchHeadingDialog({ type: 'add' });
  const editHeading = (headingData: HeadingTaskItemType) =>
    dispatchHeadingDialog({
      type: 'edit',
      data: headingData,
    });
  const closeHeadingDialog = () => dispatchHeadingDialog({ type: 'close' });

  const addContent = () => dispatchContentDialog({ type: 'add' });
  const editContent = (contentData: ContentTaskItemType) =>
    dispatchContentDialog({
      type: 'edit',
      data: contentData,
    });
  const closeContentDialog = () => dispatchContentDialog({ type: 'close' });

  const addField = () => dispatchFieldDialog({ type: 'add' });
  const editField = (fieldData: FieldTaskItemType) => dispatchFieldDialog({ type: 'edit', data: fieldData });
  const closeFieldDialog = () => dispatchFieldDialog({ type: 'close' });

  const addMedia = () => dispatchMediaDialog({ type: 'add' });
  const editMedia = (fieldData: MediaTaskItemType) => dispatchMediaDialog({ type: 'edit', data: fieldData });
  const closeMediaDialog = () => dispatchMediaDialog({ type: 'close' });

  const value = {
    openHeadingDialogState,
    addHeading,
    editHeading,
    closeHeadingDialog,
    openContentDialogState,
    addContent,
    editContent,
    closeContentDialog,
    openFieldDialogState,
    addField,
    editField,
    closeFieldDialog,
    openMediaDialogState,
    addMedia,
    editMedia,
    closeMediaDialog,
  };

  return <FormBuilderDialogContext.Provider value={value} {...props} />;
};

export const useFormBuilderDialogContext = () => {
  const value = useContext(FormBuilderDialogContext);
  if (!value) throw new Error('useFormBuilderDialogContext must be inside of a FormBuilderDialogProvider');
  return value;
};
