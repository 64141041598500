import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, Typography } from '@material-ui/core';
import { FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useAdminConsoleActions } from '../../admin-console-actions';
import { AddScreeningQuestionDialog } from './AddScreeningQuestionDialog';
import { ScreeningQuestionsFormValues } from './admin-console-applicant-screening-model';
import { ScreeningQuestionListFieldSection } from './ScreeningQuestionListFieldSection';

type ScreeningQuestionEditFormType = {
  initialValues: ScreeningQuestionsFormValues;
  refetchQuestions?: () => void;
};

export const ScreeningQuestionEditForm = ({ initialValues, refetchQuestions }: ScreeningQuestionEditFormType) => {
  const [openAddReasonDialog, setOpenAddReasonDialog] = useState<boolean>(false);
  const { runSaveScreeningQuestions } = useAdminConsoleActions();

  return (
    <Box py={4}>
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          screeningQuestions: initialValues.screeningQuestions.sort((a, b) => (a.order < b.order ? -1 : 1)),
        }}
        onSubmit={(values) => {
          runSaveScreeningQuestions(values);
        }}
      >
        <Form>
          <FieldArray name='screeningQuestions'>
            {(arrayHelpers) => (
              <>
                <Grid item xs container direction='row' style={{ paddingBottom: 16 }}>
                  <Grid item xs>
                    <Typography variant='h6' style={{ fontWeight: 600 }}>
                      {'Screening Questions'}
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      {'New applicants will see screening questions before their account is created'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems='flex-start' justify='flex-end'>
                      <TabletButton
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          setOpenAddReasonDialog(true);
                        }}
                      >
                        {'Add question'}
                      </TabletButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs>
                  <ScreeningQuestionListFieldSection arrayHelpers={arrayHelpers} />
                </Grid>

                <AddScreeningQuestionDialog
                  open={openAddReasonDialog}
                  onClose={() => setOpenAddReasonDialog(false)}
                  refetchReasons={refetchQuestions}
                />
              </>
            )}
          </FieldArray>
        </Form>
      </Formik>
    </Box>
  );
};
