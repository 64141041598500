import React from 'react';
import { PaneWrapper } from '@campfire/pane';
import { Box, Typography } from '@material-ui/core';
import { Redirect, Route, withRouter } from 'react-router';
import { CampfireSwitch } from '../../../content-blocks/common/CampfireSwitch';
import { ActivitiesConsoleTabsWrapper } from './ActivitiesConsoleNavTabs';
import { ManagerActivityTimeline } from './activity-timeline/ManagerActivityTimeline';
import { ManageReportTypeScreen } from './manage-report-type/ManageReportTypeScreen';
import { ManagerActivityMap } from './ManagerActivityMap';
import { RosterTemplateScreen } from './activity-timeline/roster-template/RosterTemplateScreen';
import { EditRosterTemplateScreen } from './activity-timeline/roster-template/EditRosterTemplateScreen';
import { AlertCard, getAlertCardColors } from '../../../common/cards/alert-card/AlertCard';
import { CreateActivityScreenV2 } from '../activity-management/activity-form-v2/CreateActivityScreenV2';
import { EditActivityScreenV2 } from '../activity-management/activity-form-v2/EditActivityScreenV2';
import { DuplicateActivityScreenV2 } from '../activity-management/activity-form-v2/DuplicateActivityScreenV2';

const ActivitiesConsoleScreen = withRouter(({ match }) => {
  const { darkTextColor, lightTextColor } = getAlertCardColors('info');
  return (
    <>
      <Box padding='2em'>
        <AlertCard title="We're ending support for the Old Activities Console" variant='info'>
          <Typography
            variant='subtitle2'
            style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor, paddingTop: '5px' }}
          >
            On <b style={{ color: darkTextColor }}>December 1st 2022</b> access to the Old Activities Console will be
            disabled. <br />
            We&apos;ve been working hard over the last 12 months to deliver significant functionality, usability and
            speed improvements through our new Activity Management and Rosters pages, and want you to experience Volaby
            at its best. <br />
            Please get in contact with our support team if you have any questions or concerns.
          </Typography>
        </AlertCard>
      </Box>
      <CampfireSwitch>
        <Route exact path={match.path} render={() => <Redirect to={`${match.path}/map`} />} />
        <Route
          exact
          path={`${match.path}/map`}
          render={() => (
            <PaneWrapper>
              <ActivitiesConsoleTabsWrapper>
                <ManagerActivityMap managementPath={match.path} />
              </ActivitiesConsoleTabsWrapper>
            </PaneWrapper>
          )}
        />
        <Route
          exact
          path={`${match.path}/timeline`}
          render={() => (
            <PaneWrapper>
              <ActivitiesConsoleTabsWrapper>
                <ManagerActivityTimeline />
              </ActivitiesConsoleTabsWrapper>
            </PaneWrapper>
          )}
        />
        <Route exact path={`${match.path}/create`} render={() => <CreateActivityScreenV2 />} />
        <Route exact path={`${match.path}/edit`} render={() => <EditActivityScreenV2 />} />
        <Route exact path={`${match.path}/duplicate`} render={() => <DuplicateActivityScreenV2 />} />
        <Route exact path={`${match.path}/report-types`} render={() => <ManageReportTypeScreen />} />
        <Route exact path={`${match.path}/create-roster-template`} render={() => <RosterTemplateScreen />} />
        <Route exact path={`${match.path}/edit-roster-template`} render={() => <EditRosterTemplateScreen />} />
      </CampfireSwitch>
    </>
  );
});

export default ActivitiesConsoleScreen;
