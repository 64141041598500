import React from 'react';
import { WelcomeDialogs } from '../../screens/general/my-activities/welcome-tutorials/WelcomeDialogs';
import { PageHelpProvider } from '../page-help/PageHelpContext';
import { DrawerContent } from './app-drawer/DrawerContent';
import { LoginAgainDialog } from './LoginAgainDialog';

interface Props {
  isTokenExpired: boolean;
}

const CampfireAppShellComp = ({ isTokenExpired }: Props) => (
  <PageHelpProvider>
    <WelcomeDialogs />
    <DrawerContent />
    <LoginAgainDialog open={isTokenExpired} />
  </PageHelpProvider>
);

export default CampfireAppShellComp;
