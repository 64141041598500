import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { HoverText } from '@campfire/hover-link/lib';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { goBackBySlashes } from '@campfire/world-wide-web/lib';
import { ResourcesList } from '../../catalogue-landing-page/ResourcesList';
import { ResourcesListItem } from '../../catalogue-landing-page/ResourcesListItem';
import { SupportCategory } from '../../__generated__/SupportCategory';
import { SupportResource } from '../../__generated__/SupportResource';

const SLICE_LENGTH = 2;

interface Props {
  selectedCategory?: SupportCategory;
  resources: SupportResource[];
  returnSlug?: string;
}
const ViewMoreResourcesSection = (props: Props) => {
  const { resources, returnSlug } = props;
  const { resourceId } = useParams();
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <Fragment>
      <Grid item xs={12} md={8}>
        <ResourcesList resources={resources} truncate>
          {(sortedResources) =>
            sortedResources.slice(0, SLICE_LENGTH).map((resource, idx) => (
              <Link
                data-track='resources-resource-viewer-see-more-list-item'
                key={resource.supportResourceId}
                to={{
                  pathname: match?.url.replace(`${resourceId}`, `${resource.supportResourceId}`) ?? '',
                  state: { returnSlug },
                }}
                style={{
                  textDecoration: 'none',
                  textDecorationColor: 'none',
                  color: 'inherit',
                  width: '100%',
                }}
              >
                <ResourcesListItem
                  resource={resource}
                  borderBottom={sortedResources.length < 2 || idx !== SLICE_LENGTH - 1}
                  showUploadDate
                />
              </Link>
            ))
          }
        </ResourcesList>
      </Grid>
      <Grid item container xs={12} md={8} justify='center'>
        {resources.length > 5 ? (
          <Grid item>
            <HoverText
              data-track='resources-resource-viewer-see-more-view-all-button'
              onClick={() => (match !== null ? goBackBySlashes(history, match) : null)}
              color='primary'
            >
              {`View all ${resources.length} items`}
            </HoverText>
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

export { ViewMoreResourcesSection };
