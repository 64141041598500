import React from 'react';
import { Button, Theme, IconButton } from '@material-ui/core';
import {
  RoomOutlined,
  ImportExportOutlined,
  FilterListOutlined,
  CalendarTodayOutlined,
  AssignmentOutlined,
} from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

import { ActivityToolValue } from './ActivityTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      height: 38,
      borderRadius: 6,
      border: '1px solid #DCDCDC',
      color: theme?.color?.grey?.neutral400,
      textTransform: 'capitalize',
      marginLeft: '0.75rem',
    },
    iconButton: {
      width: 38,
      height: 38,
      borderRadius: 6,
      border: '1px solid #DCDCDC',
      color: theme?.color?.grey?.neutral400,
      textTransform: 'capitalize',
      marginLeft: '0.75rem',
    },
    selected: {
      color: 'white',
      backgroundColor: '#646464',
      '&:hover': {
        color: 'white',
        backgroundColor: '#646464',
        opacity: 0.75,
      },
    },
    hide: {
      display: 'none',
    },
  })
);

const toolbarButtons = {
  map: {
    text: 'Map',
    icon: <RoomOutlined />,
  },
  sort: {
    text: 'Sort By',
    icon: <ImportExportOutlined />,
  },
  filter: {
    text: 'Filter',
    icon: <FilterListOutlined />,
  },
  calendar: {
    text: 'Calendar',
    icon: <CalendarTodayOutlined />,
  },
  list: {
    text: 'List View',
    icon: <AssignmentOutlined />,
  },
};

interface Tool {
  name: ActivityToolValue;
  onClick: (arg?: any) => void;
  selected?: boolean;
  hideOnMd?: boolean;
  hideOnMobile?: boolean;
}

interface Props {
  tools: Tool[];
}

export function ActivitiesToolbar({ tools }: Props) {
  const classes = useStyles();
  const { isMd, isMobile, isSm } = useCampfireTheme();

  return (
    <React.Fragment>
      {tools.map(({ name, onClick, selected, hideOnMd, hideOnMobile }) => {
        const { text, icon } = toolbarButtons[name];
        if (!isMd) {
          return (
            <Button
              key={name}
              startIcon={icon}
              onClick={onClick}
              variant='outlined'
              className={classNames(classes.buttonRoot, { [classes.selected]: selected })}
            >
              {text}
            </Button>
          );
        }
        return (
          <IconButton
            onClick={onClick}
            className={classNames(classes.iconButton, {
              [classes.selected]: selected,
              [classes.hide]: (hideOnMd && isMd) || (hideOnMd && isSm) || (hideOnMobile && isMobile),
            })}
          >
            {React.cloneElement(icon, { fontSize: 'small' })}
          </IconButton>
        );
      })}
    </React.Fragment>
  );
}
