import React, { useContext, useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';
import { VolunteerProfileActions } from './volunteer-profile-actions';
import { GET_VOLUNTEER_COMMON_PROFILE } from './volunteer-profile-model.gql';
import {
  GetVolunteerCommonProfile,
  GetVolunteerCommonProfileVariables,
  GetVolunteerCommonProfile_vm_volunteer as VolunteerType,
  GetVolunteerCommonProfile_vm_volunteer_profile_completedTasks as CompletedTaskType,
  GetVolunteerCommonProfile_em_emails as EmailType,
  GetVolunteerCommonProfile_vm_volunteer_rosterings_smsLogs as SmsLogType,
} from './__generated__/GetVolunteerCommonProfile';
import { VolunteerCommonActivityRole } from './__generated__/VolunteerCommonActivityRole';

interface VolunteerNameOptions {
  fullName: string;
  preferredName: string;
}

export interface SmsLogTypeParsed extends SmsLogType {
  status: string;
}
interface VolunteerCommonProfileContextInterface {
  profileId?: string;
  setProfileId: (x: string) => void;
  userId?: string;
  setUserId: (x: string) => void;
  volunteerNameOptions: VolunteerNameOptions;
  setVolunteerNameOptions: (x: VolunteerNameOptions) => void;
  unsavedComments: boolean;
  setUnsavedComments: (x: boolean) => void;
  showUnsavedCommentsDialog: boolean;
  setShowUnsavedCommentsDialog: (x: boolean) => void;
  availableRoles: VolunteerCommonActivityRole[];
  setAvailableRoles: (x: VolunteerCommonActivityRole[]) => void;
  updateParentVolunteerActions?: VolunteerProfileActions;
  setUpdateParentVolunteerActions: (x: VolunteerProfileActions) => void;
  volunteer?: VolunteerType;
  isHistoricalActivityEnrolment: boolean;
  setIsHistoricalActivityEnrolment: (x: boolean) => void;
  emails: EmailType[];
  smsLogs: SmsLogTypeParsed[];
  hasMore: boolean;
  completedTasks: CompletedTaskType[];
  onLoadMore: () => void;
  reloadNewData: () => void;
  error: any;
}

const LIMIT = 45;

interface VolunteerCommonProfileProviderProps {
  children: React.ReactNode;
  profileId?: string;
  userId?: string;
}

const VolunteerCommonProfileContext = React.createContext<VolunteerCommonProfileContextInterface | undefined>(
  undefined
);

const VolunteerCommonProfileProvider = (props: VolunteerCommonProfileProviderProps) => {
  const [profileId, setProfileId] = useState<string>();
  const [userId, setUserId] = useState<string>();
  const [volunteerNameOptions, setVolunteerNameOptions] = useState<VolunteerNameOptions>({
    fullName: '',
    preferredName: '',
  });
  const [unsavedComments, setUnsavedComments] = useState<boolean>(false);
  const [showUnsavedCommentsDialog, setShowUnsavedCommentsDialog] = useState<boolean>(false);
  const [isHistoricalActivityEnrolment, setIsHistoricalActivityEnrolment] = useState<boolean>(false);
  const [availableRoles, setAvailableRoles] = useState<VolunteerCommonActivityRole[]>([]);
  const [updateParentVolunteerActions, setUpdateParentVolunteerActions] = useState<VolunteerProfileActions>();
  const [volunteer, setVolunteer] = useState<VolunteerType>();
  const [completedTasks, setCompletedTasks] = React.useState<CompletedTaskType[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [emails, setEmails] = React.useState<EmailType[]>([]);
  const [smsLogs, setSmsLogs] = React.useState<SmsLogTypeParsed[]>([]);

  const [getVolunteerCommonProfile, { error }] = useCampfireLazyQuery<
    GetVolunteerCommonProfile,
    GetVolunteerCommonProfileVariables
  >(GET_VOLUNTEER_COMMON_PROFILE);

  useEffect(() => {
    setProfileId(props.profileId);
    setUserId(props.userId);
  }, [props.profileId, props.userId]);

  useEffect(() => {
    if (profileId && userId) {
      getVolunteerCommonProfile({
        variables: {
          profileId,
          userId,
          offset,
          limit: LIMIT,
          isHistorical: isHistoricalActivityEnrolment,
        },
      }).then(({ data }) => {
        if (!data || !data.vm.volunteer) {
          return;
        }
        setVolunteerNameOptions({
          fullName: `${data.vm.volunteer.profile.preferredName} ${data.vm.volunteer.profile.lastName}`,
          preferredName: data.vm.volunteer.profile.preferredName || '',
        });
        setAvailableRoles(data.vm.activityRoles);
        setVolunteer(data.vm.volunteer);
        setCompletedTasks((c) => c.concat(data?.vm.volunteer?.profile.completedTasks || []));
        setHasMore(data?.vm.volunteer?.profile.completedTasks.length === LIMIT);
        setEmails(data?.em.emails || []);
        const smsLogsParsed = data ? getSmsLogsParsed(data) : [];
        setSmsLogs(smsLogsParsed);
      });
    }
  }, [profileId, offset, isHistoricalActivityEnrolment]);

  const onLoadMore = () => {
    setOffset(completedTasks.length);
  };

  const reloadNewData = () => {
    if (userId && profileId) {
      getVolunteerCommonProfile({
        variables: {
          userId,
          profileId,
          offset: 0,
          limit: LIMIT,
        },
      }).then(({ data }) => {
        if (!data || !data.vm.volunteer) {
          return;
        }
        setVolunteerNameOptions({
          fullName: `${data.vm.volunteer.profile.preferredName} ${data.vm.volunteer.profile.lastName}`,
          preferredName: data.vm.volunteer.profile.preferredName || '',
        });
        setVolunteer(data.vm.volunteer);
        setAvailableRoles(data.vm.activityRoles || []);
        setCompletedTasks(data.vm.volunteer.profile.completedTasks || []);
        setHasMore(data.vm.volunteer.profile.completedTasks.length === LIMIT);
        setEmails(data.em.emails || []);
        const smsLogsParsed = data ? getSmsLogsParsed(data) : [];
        setSmsLogs(smsLogsParsed);
      });
    }
  };

  const getSmsLogsParsed = (data: GetVolunteerCommonProfile) => {
    const rosterSmslogs = data?.vm.volunteer?.rosterings.flatMap((item) => item.smsLogs);
    const groupedSmsLogs = groupBy(rosterSmslogs, 'messageId');
    return Object.values(groupedSmsLogs).map((item) => {
      const reply = item.find((i) => i.direction);
      if (item.length > 1 && reply) {
        return {
          ...reply,
          status: 'replied',
        };
      }
      return {
        ...item[0],
        status: 'sent',
      };
    });
  };

  const value = {
    profileId,
    setProfileId,
    userId,
    setUserId,
    volunteerNameOptions,
    setVolunteerNameOptions,
    unsavedComments,
    setUnsavedComments,
    showUnsavedCommentsDialog,
    setShowUnsavedCommentsDialog,
    availableRoles,
    setAvailableRoles,
    updateParentVolunteerActions,
    setUpdateParentVolunteerActions,
    volunteer,
    isHistoricalActivityEnrolment,
    setIsHistoricalActivityEnrolment,
    completedTasks,
    hasMore,
    emails,
    smsLogs,
    onLoadMore,
    reloadNewData,
    error,
  };

  return <VolunteerCommonProfileContext.Provider value={value} {...props} />;
};

export const useVolunteerCommonProfileContext = () => {
  const value = useContext(VolunteerCommonProfileContext);

  if (!value) throw new Error('Gotta be inside of a VolunteerCommonProfileContext.Provider, homie');

  return value;
};

export { VolunteerCommonProfileProvider };
