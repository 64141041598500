import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button/lib';
import { useFormikContext } from 'formik';

interface Props {
  open: boolean;
  close: () => void;
}

const ManualReviewDialog = (props: Props) => {
  const { open, close } = props;
  const { setFieldValue } = useFormikContext();

  const handleTurnOffManualReview = () => {
    setFieldValue('automateApproval', true);
    close();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{'Turn off manual review?'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>{'This is the only remaining task that requires manual review.'}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {
                'If this setting is switched off all future Volunteer Profiles will be approved automatically without requiring any review from managers/admins'
              }
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify='flex-end'>
          <TabletButton onClick={close}>{'Cancel'}</TabletButton>
          <TabletButton variant='contained' color='primary' onClick={handleTurnOffManualReview}>
            {'Turn off manual review'}
          </TabletButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export { ManualReviewDialog };
