import { FugFile, UploadedFile } from '@campfire/file-upload-gallery';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { object as YupObject, string as YupString, array as YupArray } from 'yup';
import { AlertCard } from '../../../../../common/cards/alert-card/AlertCard';
// import { EmailConfigSwitch } from './preference-form-sections/EmailConfigSwitch';
import { GreetingAndSignOffFormSection } from './preference-form-sections/GreetingAndSignOffFormSection';
import { LogoFormSection } from './preference-form-sections/LogoFormSection';
import { SmsConfigSwitch } from './preference-form-sections/SmsConfigSwitch';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GET_ORG_COMM_PREFERENCES } from './admin-console-preferences-model.gql';
import { useAdminConsolePreferencesActions } from './admin-console-preferences-actions';
import { GREETING_OPTIONS, SIGNOFF_OPTIONS } from './AdminConsolePreferencesConsts';
import { FormikErrorFocus } from '../../../../../common/form/FormikErrorFocus';
import { GetOrgCommPreferences } from './__generated__/GetOrgCommPreferences';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

export type PreferenceFormValues = {
  logoAttachments: Array<FugFile>;
  greeting: string;
  customGreeting: string;
  signoff: string;
  customSignOff: string;
  emailNotification: boolean;
  enableSms: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      backgroundColor: '#F9F9F9',
      minHeight: 'calc(100% - 20px)',
    },
    containerPanel: {
      background: 'white',
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
      paddingLeft: '1.875rem',
      paddingRight: '1.875rem',
      marginTop: '2.25rem',
    },
    heading: {
      paddingBottom: '8px',
      borderBottom: '1px solid #efefef',
    },
    headingTitle: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 900,
      fontSize: 20,
      marginBottom: '1.5rem',
    },
    sectionContainer: {
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
    },
    infoBody: {
      color: theme.alert.blue.dark,
      fontSize: '14px',
      fontWeight: 400,
    },
  })
);

const isCustomOption = (type: 'greeting' | 'signoff', value: string): boolean => {
  if (type === 'greeting') return !GREETING_OPTIONS.includes(value);
  return !SIGNOFF_OPTIONS.includes(value);
};

const validationSchema = YupObject().shape({
  logoAttachments: YupArray(),
  greeting: YupString().oneOf(GREETING_OPTIONS.concat('Custom'), 'Choose'),
  customGreeting: YupString().when('greeting', {
    is: 'Custom',
    then: YupString().required('Must enter custom greeting'),
  }),
  signoff: YupString().oneOf(SIGNOFF_OPTIONS.concat('Custom'), 'Choose'),
  customSignoff: YupString().when('signoff', {
    is: 'Custom',
    then: YupString().required('Must enter custom signoff'),
  }),
});

export const AdminConsoleCommunicationPreferences = () => {
  const [uploadedFiles, setUploadedFiles] = useState<Array<UploadedFile>>([]);

  const classes = useStyles();
  const { theme } = useCampfireTheme();

  const {
    runUpdateLogo,
    runUpdateLogoLoading,
    runUpdateSmsPreference,
    runUpdateSmsPreferenceLoading,
    runUpdateGreetingSignoff,
    runUpdateGreetingSignoffLoading,
  } = useAdminConsolePreferencesActions();

  const { data: orgCommPreferenceData, loading: orgCommPreferenceLoading } = useCampfireQuery<
    GetOrgCommPreferences,
    undefined
  >(GET_ORG_COMM_PREFERENCES);

  const logo = useMemo(() => orgCommPreferenceData?.tm.tenant.config?.logo, [orgCommPreferenceData]);

  const greeting = useMemo(() => orgCommPreferenceData?.tm.tenant.config?.communication?.greeting, [
    orgCommPreferenceData,
  ]);

  const signoff = useMemo(() => orgCommPreferenceData?.tm.tenant.config?.communication?.signoff, [
    orgCommPreferenceData,
  ]);

  useEffect(() => {
    // hack to make FileUploadGallery work for logo's use case as fileId, and name is required
    setUploadedFiles(
      logo
        ? [
            {
              fileId: logo,
              url: logo,
              name: logo,
            },
          ]
        : []
    );
  }, [logo]);

  const initialPreferences = useMemo(() => {
    return {
      logoAttachments: uploadedFiles,
      greeting: greeting && isCustomOption('greeting', greeting) ? 'Custom' : greeting || '',
      customGreeting: greeting && isCustomOption('greeting', greeting) ? greeting : '',
      signoff: signoff && isCustomOption('signoff', signoff) ? 'Custom' : signoff || '',
      customSignoff: signoff && isCustomOption('signoff', signoff) ? signoff : '',
      enableSms: orgCommPreferenceData?.smsEnabled || false,
    };
  }, [uploadedFiles, orgCommPreferenceData]);

  const hasInputChanged = (prevValue: any, value: any) => {
    return !isEqual(prevValue, value);
  };

  const hasCommPrefInputChanged = (prevValue: string, customPrevValue: string, value: string, customValue: string) => {
    return prevValue !== value || customPrevValue !== customValue;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialPreferences}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        if (hasInputChanged(initialPreferences.logoAttachments, values.logoAttachments))
          runUpdateLogo({ logo: values.logoAttachments[0] });
        if (hasInputChanged(initialPreferences.enableSms, values.enableSms))
          runUpdateSmsPreference({ enableSms: values.enableSms });
        if (
          hasCommPrefInputChanged(
            initialPreferences.greeting,
            initialPreferences.customGreeting,
            values.greeting,
            values.customGreeting
          ) ||
          hasCommPrefInputChanged(
            initialPreferences.signoff,
            initialPreferences.customSignoff,
            values.signoff,
            values.customSignoff
          )
        )
          runUpdateGreetingSignoff({
            greeting: values.greeting === 'Custom' ? values.customGreeting : values.greeting,
            signoff: values.signoff === 'Custom' ? values.customSignoff : values.signoff,
          });
      }}
    >
      {({ values }) => {
        return (
          <Form style={{ height: '100%' }}>
            <Box className={classes.container}>
              <Box className={classes.heading}>
                <Typography variant='h2' className={classes.headingTitle}>
                  Preferences
                </Typography>
              </Box>
              <Box className={classes.containerPanel}>
                <Typography variant='h2' className={classes.headingTitle}>
                  Your Organisation
                </Typography>
                <Box>
                  <AlertCard variant='info'>
                    <Typography className={classes.infoBody}>
                      Define your preferences to personalise the communications volunteers receive from you.
                    </Typography>
                  </AlertCard>
                </Box>
                <Grid container>
                  <Grid item sm={12} md={6} className={classes.sectionContainer}>
                    <LogoFormSection uploadedFiles={uploadedFiles} isLoading={orgCommPreferenceLoading} />
                  </Grid>
                  <Grid item sm={12} md={6} className={classes.sectionContainer}>
                    <GreetingAndSignOffFormSection isLoading={orgCommPreferenceLoading} />
                  </Grid>
                </Grid>
              </Box>
              <Box marginBottom='1.5rem' className={classes.containerPanel}>
                <Typography variant='h2' className={classes.headingTitle}>
                  Communications
                </Typography>
                <Grid container>
                  {/* <Grid item sm={12} md={6}>
                    <EmailConfigSwitch />
                  </Grid> */}
                  <Grid item sm={12} md={6}>
                    <SmsConfigSwitch isLoading={orgCommPreferenceLoading} />
                  </Grid>
                </Grid>
              </Box>
              {hasInputChanged(initialPreferences, values) ? (
                <Box display='flex' justifyContent='right' pb={4}>
                  <TabletButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{
                      alignSelf: 'right',
                      backgroundColor: theme.color.secondary.main900,
                      color: '#ffffff',
                      boxSizing: 'border-box',
                      borderRadius: '4px',
                      textTransform: 'none',
                      padding: '7px 12px',
                      minWidth: '40px',
                    }}
                    disabled={runUpdateLogoLoading || runUpdateGreetingSignoffLoading || runUpdateSmsPreferenceLoading}
                  >
                    Save
                  </TabletButton>
                </Box>
              ) : null}
            </Box>
            <FormikErrorFocus />
          </Form>
        );
      }}
    </Formik>
  );
};
