import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { unpackToDateTime } from '@campfire/hot-date';
import { Cancel } from '@material-ui/icons';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { GetRosterScreenRosterData_vm_activity_closedActivity as ClosedActivity } from './roster/__generated__/GetRosterScreenRosterData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      fontSize: '14px',
      color: theme.alert.red.dark,
      '&>strong': {
        fontWeight: 500,
      },
    },
  })
);
interface Props {
  closedActivity: ClosedActivity;
  activityName: string;
}

export const ClosedActivityInfo = (props: Props) => {
  const { theme } = useCampfireTheme();
  const { closedActivity, activityName } = props;
  const cancelledDateTime = unpackToDateTime(closedActivity.closedDate).toFormat('dd/M/yyyy ta');
  const classes = useStyles();

  return (
    <Box bgcolor={theme.alert.red.extraLight} display='flex' marginRight={'30px'} marginTop={'10px'}>
      <Cancel
        style={{
          fontSize: '16px',
          padding: '20px 16px',
          color: theme.alert.red.light,
        }}
      />
      <Box pt={2} pb={'36px'} pr={2}>
        <Typography variant={'subtitle2'} className={classes.typography}>
          {'This activity has been closed'}
        </Typography>
        <Typography className={classes.typography} style={{ marginTop: '12px' }}>
          <strong>{activityName}</strong> has been closed. Any rosters that were published after the closure date have
          been unpublished, and will appear as drafts. To perform actions on these rosters, like publishing and
          cancelling, you will need to reactivate the activity from the &apos;Activity Management&apos; page.
        </Typography>
        <Typography className={classes.typography} style={{ marginTop: '12px' }}>
          <strong>Reason:</strong>&nbsp;{closedActivity.activityClosureReason.label}
        </Typography>
        <Typography className={classes.typography} style={{ marginTop: '5px', fontSize: '12px' }}>
          Closed by
          <strong>
            &nbsp;{closedActivity.closedBy?.profile.preferredName}&nbsp;{closedActivity.closedBy?.profile.lastName}
          </strong>
          &nbsp;on <strong>{cancelledDateTime}</strong>
        </Typography>
      </Box>
    </Box>
  );
};
