import { Box, ButtonBase } from '@material-ui/core';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { memo, RefObject } from 'react';

export type ActivityTimelineSidebarItemBaseProps = {
  setSelectedActivityId: (selectedActivityId: string) => void;
  setSelectedDate: (selectedDate: string | undefined) => void;
  selected: boolean;
  listItemRef: RefObject<HTMLLIElement>;
  onSidebarItemClick?: () => void;
  setSelectedRosterTemplateId: (rtid: string) => void;
};

type Props = ButtonBaseProps & {
  handleClick: () => void;
  selected: boolean;
  activityId: string;
};

const ActivityTimelineSidebarItemButtonBase = memo((props: Props) => {
  const { children, handleClick, selected, activityId } = props;

  const baseBackgroundColor = selected ? '#fff' : undefined;

  const classes = makeStyles(() =>
    createStyles({
      button: {
        '&:hover': {
          background: 'rgba(255,255,255,0.5)',
        },
      },
    })
  )();

  // const rippleClasses = makeStyles((theme: Theme) =>
  //   createStyles({
  //     child: {
  //       backgroundColor: 'white',
  //     },
  //   })
  // )();

  return (
    <ButtonBase
      data-track='actCnl-directory-list-item'
      className={classes.button}
      onClick={handleClick}
      style={{
        textAlign: 'start',
        width: '100%',
        position: 'relative',
      }}
      disableRipple
      disableTouchRipple
      // TouchRippleProps={{ classes: rippleClasses }}
    >
      {selected ? (
        <Box
          position='absolute'
          top={-64}
          right={0}
          width={32}
          height={64}
          bgcolor='transparent'
          borderRadius={'0 0 32px 0'}
          boxShadow='0 32px 0 0 white'
        />
      ) : null}

      {selected ? (
        <Box
          position='absolute'
          bottom={-64}
          right={0}
          width={32}
          height={64}
          bgcolor='transparent'
          borderRadius={'0 32px 0 0'}
          boxShadow='0 -32px 0 0 white'
        />
      ) : null}

      <Box
        position='relative'
        key={activityId}
        bgcolor={baseBackgroundColor}
        width={1}
        paddingLeft={3}
        paddingRight={1}
        paddingY={3}
        // {...(selected
        //   ? {
        //       borderTop: 'solid rgba(0,0,0,0.2) 1px',
        //       // borderLeft: 'solid rgba(0,0,0,0.2) 1px',
        //       borderBottom: 'solid rgba(0,0,0,0.2) 1px',
        //       style: { boxSizing: 'border-box' },
        //     }
        //   : {
        //       borderRight: 'solid rgba(0,0,0,0.2) 1px',
        //       style: { boxSizing: 'border-box' },
        //     })}
      >
        {children}
      </Box>
    </ButtonBase>
  );
});

export { ActivityTimelineSidebarItemButtonBase };
