import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { arrayHead } from '../../../../common/functions/array-head';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { REPORT_MANAGEMENT_GET_REPORT_TYPES } from './report-types-model.gql';
import { ReportTypeDetail } from './report-type-detail/ReportTypeDetail';
import { ReportTypesList } from './report-types-list/ReportTypesList';
import { ReportManagementGetReportTypes } from './__generated__/ReportManagementGetReportTypes';
import { ReportTypeFilters } from './ReportTypeFilters';
import { INITIAL_REPORT_TYPE_FILTERS } from '../ReportManagementConsts';
import { filterBySearch, filterByStatus } from './ReportTypeUtils';
import { CollapsibleSection } from '../../../../common/CollapsibleSection';

type ReportTypesProps = {
  showFilters: boolean;
  searchInput: string;
};

export const ReportTypes = (props: ReportTypesProps) => {
  const { showFilters, searchInput } = props;
  const [filters, setFilters] = useState(INITIAL_REPORT_TYPE_FILTERS);

  const [query, setQuery] = useQueryParams({
    reportTypeId: StringParam,
  });

  const { isMobile } = useCampfireTheme();

  const { data: getReportTypesData, loading: getReportTypesLoading } = useCampfireQuery<
    ReportManagementGetReportTypes,
    undefined
  >(REPORT_MANAGEMENT_GET_REPORT_TYPES);

  const reportTypes = getReportTypesData?.vm.allReportTypes.sort((a, b) => a.name.localeCompare(b.name)) || [];
  const filteredReportTypes = reportTypes.filter(filterByStatus(filters.status)).filter(filterBySearch(searchInput));

  useEffect(() => {
    if (!isMobile) {
      if (filteredReportTypes.length > 0 && !query.reportTypeId)
        setQuery({ reportTypeId: arrayHead(filteredReportTypes)?.reportTypeId });
      if (
        filteredReportTypes.length > 0 &&
        query.reportTypeId &&
        !filteredReportTypes.some((reportType) => reportType.reportTypeId === query.reportTypeId)
      )
        setQuery({ reportTypeId: arrayHead(filteredReportTypes)?.reportTypeId });
      if (reportTypes.length && filteredReportTypes.length === 0 && query.reportTypeId)
        setQuery({ reportTypeId: undefined });
    }
  }, [filteredReportTypes, reportTypes]);

  const onChangeFilter = (newFilter: any) => setFilters((currentFilters) => ({ ...currentFilters, ...newFilter }));

  if (isMobile)
    return (
      <Box width='100%' position='relative' display='flex' flexDirection='column' height='100%'>
        <Box padding='24px'>
          {query.reportTypeId ? (
            <ReportTypeDetail reportTypeId={query.reportTypeId} />
          ) : (
            <>
              {showFilters && <ReportTypeFilters values={filters} onChange={onChangeFilter} />}
              <ReportTypesList reportTypes={filteredReportTypes} reportTypesLoading={getReportTypesLoading} />
            </>
          )}
        </Box>
      </Box>
    );

  return (
    <Box width='100%' position='relative' display='flex' flexDirection='row' height='100%' overflow='hidden'>
      <Box flexGrow={1} style={{ overflowY: 'auto' }} padding='0 60px 24px 60px'>
        {showFilters && <ReportTypeFilters values={filters} onChange={onChangeFilter} />}
        <ReportTypesList reportTypes={filteredReportTypes} reportTypesLoading={getReportTypesLoading} />
      </Box>
      {query.reportTypeId && (
        <CollapsibleSection>
          <ReportTypeDetail reportTypeId={query.reportTypeId} />
        </CollapsibleSection>
      )}
    </Box>
  );
};
