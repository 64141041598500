import React, { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { BulkRosterScreen } from './bulk-roster/BulkRosterScreen';
import { IndividualRosterScreen } from './IndividualRosterScreen';
import { useSession } from '../../../global/auth/useSession';

export const RostersScreen = () => {
  const { user } = useSession();
  const [query, setQuery] = useQueryParams({
    view: StringParam,
    sortVolsRowBy: StringParam,
  });

  useEffect(() => {
    const rosterView = user?.userPreference?.rosterView;
    if (!query.view && rosterView) {
      setQuery({ view: rosterView });
    }
    if (!query.sortVolsRowBy) {
      setQuery({ sortVolsRowBy: 'rostered' });
    }
  }, []);

  return <React.Fragment>{query.view === 'bulk' ? <BulkRosterScreen /> : <IndividualRosterScreen />}</React.Fragment>;
};
