import React from 'react';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents as IComponent } from './dashboard-widget-setting/__generated__/GetDashboardPreference';
import { ChartWidget } from './widgets/ChartWidget';
import { NumberWidget } from './widgets/number-widget/NumberWidget';
import { ChartWidget as IChartWidget } from './dashboard-widget-setting/__generated__/ChartWidget';
import { NumberWidget as INumberWidget } from './dashboard-widget-setting/__generated__/NumberWidget';
// import { RequiringActionWidget as IRequiringActionWidget } from './dashboard-widget-setting/__generated__/RequiringActionWidget';
// import { RequiringActionWidget } from './widgets/requiring-action-widget/RequiringActionWidget';
import { ChartNumberWidget as IChartNumberWidget } from './dashboard-widget-setting/__generated__/ChartNumberWidget';
import { ChartNumberWidget } from './widgets/number-chart-widget/NumberChartWidget';
import { TableWidget } from './widgets/table-widget/TableWidget';
import { TableWidget as ITableWidget } from './dashboard-widget-setting/__generated__/TableWidget';
import { RequiringActionWidget } from './widgets/requiring-action-widget/RequiringActionWidget';
import { RequiringActionWidget as IRequiringActionWidget } from './dashboard-widget-setting/__generated__/RequiringActionWidget';

interface WidgetItemProps {
  component: IComponent;
  onDelete: (dashboardComponentId: string) => void;
  onEdit: (dashboardComponent: IComponent) => void;
}

export function WidgetItem({ component, onDelete, onEdit }: WidgetItemProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteHandler = () => {
    onDelete(component.dashboardComponentId);
    handleClose();
  };

  const onEditHandler = () => {
    onEdit(component);
    handleClose();
  };

  const widget = React.useMemo(() => {
    return component.widget;
  }, [component]);

  return (
    <Box
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <IconButton
        onClick={handleClick}
        style={{ position: 'absolute', top: 0, right: 0, padding: '8px', marginTop: '4px' }}
      >
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={onEditHandler}>Edit</MenuItem>
        <MenuItem onClick={onDeleteHandler}>Delete</MenuItem>
      </Menu>
      {component.widget.__typename === 'VOLUNTEER_ChartWidgetType' && (
        <ChartWidget widget={component.widget as IChartWidget} showHighlight={component.position.colSpan > 1} />
      )}
      {component.widget.__typename === 'VOLUNTEER_NumberWidgetType' && (
        <NumberWidget widget={widget as INumberWidget} />
      )}
      {component.widget.__typename === 'VOLUNTEER_ChartNumberWidgetType' && (
        <ChartNumberWidget widget={widget as IChartNumberWidget} />
      )}
      {component.widget.__typename === 'VOLUNTEER_TableWidgetType' && <TableWidget widget={widget as ITableWidget} />}
      {component.widget.__typename === 'VOLUNTEER_RequiringActionWidgetType' && (
        <RequiringActionWidget widget={widget as IRequiringActionWidget} />
      )}
    </Box>
  );
}
