import React, { ChangeEvent } from 'react';
import { MenuItem, TextField, Typography, Theme, Grid, Button, Collapse, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { RecurrenceEnum } from '../../../__generated__/globalTypes';
import { BeginOptionsEnum, FREQUENCY_ENUM, LengthOptionEnum } from './consts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '12px',
      fontWeight: 700,
      color: theme.color.grey.neutral400,
      marginBottom: 6,
    },
    button: {
      fontSize: 10,
      textDecoration: 'none',
      color: theme.color.grey.neutral300,
      textTransform: 'none',
      paddingRight: 0,
      paddingLeft: 0,
    },
    advandedContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const selectStyles = makeStyles(() =>
  createStyles({
    input: {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontSize: '12px',
      height: '19px',
    },
    root: {
      borderRadius: '6px',
      borderTopColor: '#9E9E9E',
      borderRightColor: '#9E9E9E',
      borderBottomColor: '#9E9E9E',
      borderLeftColor: '#9E9E9E',
      color: '#444444',
    },
    adornedStart: {
      paddingLeft: '0.75rem',
    },
  })
);

export type FrequencyOptions = {
  label: string;
  value?: FREQUENCY_ENUM;
};

export type LengthOptions = {
  label: string;
  value: LengthOptionEnum;
};

export type DayOptions = {
  label: string;
  value: RecurrenceEnum;
};

export type BeginOptions = {
  label: string;
  value?: BeginOptionsEnum;
};

export type FrequencyValueType = {
  frequency: FREQUENCY_ENUM;
  length: LengthOptionEnum;
  day: RecurrenceEnum[];
  begin: BeginOptionsEnum;
};

interface Props {
  frequencyOptions: FrequencyOptions[];
  lengthOptions: LengthOptions[];
  dayOptions: DayOptions[];
  beginOptions: BeginOptions[];
  value: FrequencyValueType;
  onChange: (value: FrequencyValueType) => void;
}

export function FrequencySelect({ frequencyOptions, lengthOptions, dayOptions, beginOptions, value, onChange }: Props) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value: eventValue } = event.target;
    onChange({
      ...value,
      [name]: eventValue,
    });
  };
  const [showAdvanced, setShowAdvanced] = React.useState(false);

  const { frequency: frequencyValue } = value;

  React.useEffect(() => {
    if (frequencyValue === FREQUENCY_ENUM.CUSTOM) {
      setShowAdvanced(true);
    }
  }, [frequencyValue]);

  const selectFieldClasses = selectStyles();
  const classes = useStyles();
  return (
    <Grid container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.label}>Frequency</Typography>
          <TextField
            select
            name='frequency'
            value={value.frequency}
            onChange={handleChange}
            variant='outlined'
            fullWidth
            InputProps={{ classes: selectFieldClasses }}
          >
            {frequencyOptions.map(({ label, value: itemValue }) => (
              <MenuItem value={itemValue} key={itemValue}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.label}>Length</Typography>
          <TextField
            select
            value={value.length}
            name='length'
            variant='outlined'
            onChange={handleChange}
            fullWidth
            InputProps={{ classes: selectFieldClasses }}
          >
            {lengthOptions.map(({ label, value: itemValue }) => (
              <MenuItem value={itemValue} key={itemValue}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} className={classes.advandedContainer}>
        <Button
          variant='text'
          endIcon={showAdvanced ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          onClick={() => setShowAdvanced(!showAdvanced)}
          className={classes.button}
        >
          Advanced
        </Button>
      </Grid>
      <Collapse in={showAdvanced} style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
            <Typography className={classes.label}>Day/s</Typography>
            <TextField
              SelectProps={{
                multiple: true,
                displayEmpty: true,
                renderValue: (selectedDays) => {
                  const days = selectedDays as string[];
                  if (days.length === 0) {
                    return 'All days';
                  }

                  return days
                    .map((weekday) => dayOptions.find((dayOption) => dayOption.value === weekday)?.label)
                    .join(', ');
                },
              }}
              select
              name='day'
              value={value.day}
              onChange={handleChange}
              variant='outlined'
              fullWidth
              InputProps={{ classes: selectFieldClasses }}
            >
              {dayOptions.map(({ label, value: itemValue }) => (
                <MenuItem value={itemValue} key={itemValue}>
                  {value.day.includes(itemValue) ? (
                    <Box className={classes.menuItem}>
                      <CheckBoxRounded style={{ marginRight: '1rem' }} color='primary' />
                      {label}
                    </Box>
                  ) : (
                    <Box className={classes.menuItem}>
                      <CheckBoxOutlineBlankRounded style={{ marginRight: '1rem' }} color='primary' />
                      {label}
                    </Box>
                  )}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
            <Typography className={classes.label}>Begin</Typography>
            <TextField
              select
              value={value.begin}
              name='begin'
              variant='outlined'
              onChange={handleChange}
              fullWidth
              InputProps={{ classes: selectFieldClasses }}
            >
              {beginOptions.map(({ label, value: itemValue }) => (
                <MenuItem value={itemValue} key={itemValue}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
}
