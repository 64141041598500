import { Divider, Theme, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { SideNavItemInterface } from '../../../content-blocks/common/routes-map-model';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { usePageNotFoundContext } from '../../page-not-found/PageNotFoundContext';

interface Props {
  closeDrawer: () => void;
  isDrawerOpen: boolean;
  activeSideNavRoute: string;
  setActiveSideNavRoute: (route: string) => void;
  subListItem: SideNavItemInterface;
  urlPath: string;
}

type DrawerNavListProps = Props & RouteComponentProps;

const useDesktopStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemStyle: {
      '&:hover': {
        background: 'transparent',
      },
      padding: '0 25px',
    },
    listItemTextContainerStyle: {
      borderRadius: theme.appShell.drawer.sideNav.itemBorderRadius,
      margin: 0,
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.15)',
      },
      '&:hover p': {
        color: '#fcfcfc',
      },
    },
    listItemTextContainerActiveStyle: {
      background: 'rgba(255, 255, 255, 0.15)',
      color: '#fcfcfc',
    },
    listItemTextStyle: {
      fontSize: '0.9rem',
      color: 'rgba(255,255,255,0.5)',
    },
    listItemTextActiveStyle: {
      color: '#fcfcfc',
    },
  })
);

const useMobileStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemStyle: {
      padding: '0 16px',
    },
    listItemTextStyle: {},
    listItemTextActiveStyle: {
      color: 'white',
      margin: 0,
    },
    listItemTextContainerStyle: {
      borderRadius: theme.appShell.drawer.sideNav.itemBorderRadius,
    },
    listItemTextContainerActiveStyle: {
      background: '#242424',
    },
  })
);

type DrawerListItemProps = {
  isActive: boolean;
  closeDrawer: () => void;
  isDrawerOpen: boolean;
  label: string;
  id: string;
  onClick?: () => void;
};

const DrawerListItem = (props: DrawerListItemProps) => {
  const { isActive, isDrawerOpen, label, id, closeDrawer, onClick } = props;
  const { isMobile } = useCampfireTheme();
  const classes = isMobile ? useMobileStyles() : useDesktopStyles();

  return (
    <ListItem
      component='nav'
      button
      onClick={() => {
        if (onClick) {
          onClick();
        }
        setTimeout(() => {
          if (isMobile) {
            closeDrawer();
          }
        }, 150);
      }}
      key={id}
      className={classNames(classes.listItemStyle)}
      disableRipple
      disableTouchRipple
    >
      <Divider orientation='vertical' flexItem style={{ background: '#444444', margin: '0 11px' }} />
      <ListItemText
        className={classNames(classes.listItemTextContainerStyle, {
          [classes.listItemTextContainerActiveStyle]: isActive,
        })}
        style={{ position: 'relative', padding: '8px 12px' }}
        primary={
          <Typography
            className={classNames(classes.listItemTextStyle, { [classes.listItemTextActiveStyle]: isActive })}
            style={{ whiteSpace: isDrawerOpen ? 'normal' : 'nowrap' }}
            variant='body1'
          >
            {label}
          </Typography>
        }
      />
    </ListItem>
  );
};

const DrawerNavListItem = withRouter(
  ({
    closeDrawer,
    isDrawerOpen,
    activeSideNavRoute,
    setActiveSideNavRoute,
    subListItem,
    urlPath,
    location,
  }: DrawerNavListProps) => {
    useEffect(() => {
      if (urlPath && location.pathname.startsWith(urlPath)) {
        setActiveSideNavRoute(urlPath);
      }
    }, [urlPath, location.pathname]);

    const isActive = activeSideNavRoute === urlPath;

    const { setPageNotFound } = usePageNotFoundContext();
    return (
      <Link
        to={urlPath}
        style={{ textDecoration: 'none', textDecorationColor: 'none', color: 'inherit' }}
        onClick={() => setPageNotFound(false)}
      >
        <DrawerListItem
          isActive={isActive}
          closeDrawer={closeDrawer}
          isDrawerOpen={isDrawerOpen}
          id={urlPath}
          label={subListItem.label}
        />
      </Link>
    );
  }
);

export { DrawerNavListItem, DrawerListItem };
