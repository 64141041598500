import { User } from '../../auth/SessionContext';
import { OrgInfo } from '../../auth/use-org-info/use-org-info';

export class Intercom {
  init = (user?: User, org?: OrgInfo) => {
    if (user && org && org.tenantId !== '5fbb9c2c-c282-4048-b9ca-3c651f9a4346') {
      window.analytics.identify(user.userId, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        organisation: user.orgInfo.name,
        Privilege: user.userIdentityService.privLevel,
        createdAt: user.dateCreated,
      });
    }

    window.analytics.page();
  };
}
