import { gql } from '@apollo/client';

export const GET_MY_ELEMENTS_SIDEBAR_RECENT_NEWS_DATA = gql`
  query GetMyElementsSidebarRecentNewsData {
    vm {
      mostRecentBulletin {
        bulletinId
        title
        content
        dateCreated
        createdBy {
          profile {
            preferredName
            lastName
          }
        }
        dateUpdated
        lastUpdatedBy {
          profile {
            preferredName
            lastName
          }
        }
      }
    }
  }
`;
