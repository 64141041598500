import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { useCampfireTheme } from '../theme/useCampfireTheme';

interface Props {
  children: ReactNode;
  fixed?: boolean;
  transparent?: boolean;
  center?: boolean;
}

export const PopUpWindow = (props: Props) => {
  const { children, fixed, transparent, center } = props;
  const { theme, isMd, isSm, isMobile } = useCampfireTheme();

  return (
    <Box
      width={'100%'}
      height={'100%'}
      bgcolor={transparent ? '#5f5f5f2e' : theme.color.grey.background}
      position={fixed ? 'fixed' : 'absolute'}
      top={'0px'}
      bottom={'0'}
      left={'0'}
      right={'0'}
      boxSizing={'border-box'}
      display={'flex'}
      flexDirection={isMobile ? 'column' : isMd ? 'row' : 'column'}
      justifyContent={center ? 'center' : isMobile ? 'flex-start' : isSm ? 'space-between' : 'flex-start'}
      alignItems={center ? 'center' : 'normal'}
      zIndex={'1000'}
    >
      <Box
        style={{ overflowX: 'hidden' }}
        flexGrow={1}
        height={'100%'}
        padding={isMd ? '40px' : '40px 60px'}
        boxSizing={'border-box'}
        display={'flex'}
        flexDirection={isMobile ? 'column' : isMd ? 'row' : 'column'}
        justifyContent={center ? 'center' : isMobile ? 'flex-start' : isSm ? 'space-between' : 'flex-start'}
      >
        {children}
      </Box>
    </Box>
  );
};
