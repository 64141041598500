import { Box } from '@material-ui/core';
import React from 'react';

import { ActivityHeader } from './ActivityHeader';
import { DateIcon } from '../../../../common/DateIcon';
import { RosteredStatus } from '../ActivityTypes';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface Props {
  occurrencesBetween: string[];
  getRosterStatusByDate: (activityDate: string) => RosteredStatus;
}

interface extendedColorTheme {
  primary: string;
  secondary: string;
  additional: string;
}

export function ActivityUpcomingRosters({ occurrencesBetween, getRosterStatusByDate }: Props) {
  const { theme } = useCampfireTheme();
  const { rosteredStatuses } = theme.color;

  if (!occurrencesBetween?.length) {
    return null;
  }

  return (
    <Box marginTop='2rem'>
      <ActivityHeader title='Upcoming Rosters' />
      <Box display='flex' alignContent='flex-start' flexWrap='wrap'>
        {occurrencesBetween?.map((date) => (
          <Box key={date} marginTop='0.75rem' marginRight='1.25rem'>
            <DateIcon
              date={date}
              backgroundColor={rosteredStatuses[getRosterStatusByDate(date)].primary}
              color={rosteredStatuses[getRosterStatusByDate(date)].secondary}
              dateColor={(rosteredStatuses[getRosterStatusByDate(date)] as extendedColorTheme).additional}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
