import { gql } from '@apollo/client';
import { ACTIVITY_REPORT_INCOMPLETE_REPORT_ACTIVITY_FRAGMENT } from '../../../../../../activity-report/activity-reports-model.gql';

export const GET_WIDGET_INCOMPLETE_ACTIVITY_REPORTS = gql`
  query GetWidgetIncompleteActivityReports(
    $programIds: [String!]
    $activityIds: [String!]
    $from: Date
    $until: Date
    $includeInactiveActivities: Boolean
    $range: String
  ) {
    vm {
      incompleteActivityReports(
        programIds: $programIds
        activityIds: $activityIds
        includeInactiveActivities: $includeInactiveActivities
      ) {
        occurrences(from: $from, until: $until, range: $range)
        ...ActivityIncompleteReportFragment
      }
    }
  }
  ${ACTIVITY_REPORT_INCOMPLETE_REPORT_ACTIVITY_FRAGMENT}
`;
