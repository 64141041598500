import { gql } from '@apollo/client';

const ACTIVITY_TAG_LIST_FRAGMENT = gql`
  fragment ActivityTags on VOLUNTEER_ActivityType {
    activityId
    activityTags {
      activityTagId
    }
  }
`;

const ACTIVITY_TAGGED_LIST_FRAGMENT = gql`
  fragment ActivityTagged on VOLUNTEER_ActivityType {
    activityId
    activityTagged {
      activityTaggedId
      activityTag {
        activityTagId
      }
      dateAdded
      dateRemoved
    }
  }
`;

export const ACTIVITY_TAGS_QUERY = gql`
  query GetActivitisActivityTags {
    vm {
      activities {
        activityId
        name
        description
        program {
          programId
          name
        }
        ...ActivityTags
        ...ActivityTagged
      }
    }
  }
  ${ACTIVITY_TAG_LIST_FRAGMENT}
  ${ACTIVITY_TAGGED_LIST_FRAGMENT}
`;
