import { unpackToDate } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { Box, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { memo, useMemo } from 'react';
import { useHistory } from 'react-router';
import PrivilegeRules from '../../../../../global/auth/PrivilegeRules';
import { useUser } from '../../../../../global/auth/useUser';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useUncancelActivityFetch } from '../activity-timeline-actions';
import { GET_ACTIVITY_TIMELINE_ACTIVITY_CANCELLATION } from '../activity-timeline-model.gql';
import { ActivityTimelineSidebarCancelledActivity } from '../__generated__/ActivityTimelineSidebarCancelledActivity';
import {
  GetActivityTimelineActivityCancellation,
  GetActivityTimelineActivityCancellationVariables,
} from '../__generated__/GetActivityTimelineActivityCancellation';

interface ActivityTimelineActivityCancellationProps {
  activityId: string;
  activityDate: string;
  cancelledActivity: ActivityTimelineSidebarCancelledActivity;
  onUncancelActivity: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    headingBox: {
      borderBottom: 'solid #dadada 1px',
      backgroundColor: '#ffffff',
    },
  })
);

export const ActivityTimelineActivityCancellation = memo(
  ({ activityId, activityDate, cancelledActivity, onUncancelActivity }: ActivityTimelineActivityCancellationProps) => {
    const uncancelActivity = useUncancelActivityFetch();
    const classes = useStyles();
    const history = useHistory();

    function goBack() {
      const urlParams = new URLSearchParams(history.location.search);
      urlParams.delete('activityDate');
      history.replace(`${history.location.pathname}?${urlParams}`);
    }

    const { data, loading } = useCampfireQuery<
      GetActivityTimelineActivityCancellation,
      GetActivityTimelineActivityCancellationVariables
    >(GET_ACTIVITY_TIMELINE_ACTIVITY_CANCELLATION, {
      options: {
        variables: {
          cancelledActivityId: activityId,
          // cancelledDate: activityDate
        },
      },
    });
    const { setSnackbar } = useSnackbar();
    const {
      user: { userIdentityService },
    } = useUser();
    const { theme } = useCampfireTheme();

    const runUncancelActivity = () => {
      uncancelActivity
        .run({
          cancelledActivityId: cancelledActivity.cancelledActivityId,
        })
        .then((res) => {
          if (!res.ok) {
            setSnackbar({
              open: true,
              message: 'Could not resume activity',
              variant: 'error',
            });
            return;
          }

          setSnackbar({
            open: true,
            message: 'Activity resumed',
            variant: 'success',
          });

          onUncancelActivity();
        })
        .catch(() =>
          setSnackbar({
            open: true,
            message: 'Could not resume activity',
            variant: 'error',
          })
        );
    };

    const activity = useMemo(() => {
      return data?.vm.activity;
    }, [data]);

    const cancelledActivityData = useMemo(() => {
      return data?.vm.activity?.cancelledActivities.find((x) => x.activityDate === activityDate);
    }, [data]);

    const canUncancelActivity = useMemo(() => {
      if (!activity) return false;
      return userIdentityService.hasRuleForProgram(activity.program.programId, PrivilegeRules.cancelActivity);
    }, [userIdentityService, activity]);

    const activityDateTime = unpackToDate(activityDate);

    if (loading)
      return (
        <Box padding={3} borderBottom={`1px solid ${theme.color.grey.border}`} width={1}>
          <Skeleton variant='text' height={25} width='30%' />
          <Skeleton variant='text' height={15} width='25%' />
        </Box>
      );

    return activity && cancelledActivityData ? (
      <Box width={1}>
        <Box padding={3} className={classes.headingBox}>
          <HoverText
            color='primary'
            hoverColor='primary'
            display='inline'
            variant='h5'
            onClick={goBack}
            data-track='actCnl-back-to-activity-overview'
          >
            <Grid container item style={{ display: 'inline' }}>
              <Typography variant='h5' display='inline'>
                {activity.name}
              </Typography>
            </Grid>
          </HoverText>

          <Box display='flex' alignContent='baseline' alignItems='baseline'>
            <Typography variant='body2' style={{ marginRight: 12 }}>
              {activityDateTime.toFormat('cccc, d LLLL')}
            </Typography>
          </Box>
        </Box>

        <Box paddingLeft={3} paddingTop={1}>
          <Typography variant='h5' color='error'>
            {'Cancelled'}
          </Typography>
        </Box>

        <Box padding={3} paddingTop={1} maxWidth={480}>
          {/* <Typography>
            {`The ${activityDateTime.toFormat('cccc, d LLLL')} ${activity.name} activity was cancelled by ${
              !cancelledActivityData.cancelledBy ?? cancelledActivityData.cancelledBy.preferredName
            } ${cancelledActivityData.cancelledBy.lastName} on ${unpackToDate(
              cancelledActivityData.cancelledDate
            ).toFormat('ccc d LLL')}.`}
          </Typography> */}
          <Typography>{`The reason provided was '${cancelledActivityData.activityCancellationReason.label}'`}</Typography>
        </Box>

        {canUncancelActivity ? (
          <Box padding={3} paddingTop={1}>
            <HoverText
              data-track='actCnl-uncancel-activity-button'
              variant='body2'
              color='primary'
              hoverColor='primary'
              onClick={() => runUncancelActivity()}
            >
              {'Click here to resume this activity'}
            </HoverText>
          </Box>
        ) : null}
      </Box>
    ) : null;
  }
);
