import Snackbar, { SnackbarCloseReason, SnackbarProps } from '@material-ui/core/Snackbar';
import SnackbarContent, { SnackbarContentProps } from '@material-ui/core/SnackbarContent';
import clsx from 'clsx';
import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { snackbarVariantIcon, useSnackbarStyles } from '../../common/snackbars/TemplateSnackbar';

interface GlobalSnackbarProps {
  className?: string;
  message?: string;
  variant: keyof typeof snackbarVariantIcon;
  Icon?: React.ReactNode;
  autoHideDuration?: number;
  action?: React.ReactNode;
}

export type SnackbarContextProps = GlobalSnackbarProps & SnackbarProps;
export interface SnackbarContextInterface {
  setSnackbar: Dispatch<SetStateAction<SnackbarContextProps | undefined>>;
}

export const SnackbarContext = createContext<SnackbarContextInterface | undefined>(undefined);

export interface SnackbarProviderProps {
  children: React.ReactNode;
}

type GlobalSnackbarContentProps = GlobalSnackbarProps & Omit<SnackbarContentProps, 'variant'>;
const GlobalSnackbarContent = (props: GlobalSnackbarContentProps) => {
  const { className, message, variant, Icon, action, ...rest } = props;
  const classes = useSnackbarStyles();
  const StandardIcon = snackbarVariantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby='global-snackbar'
      message={
        <span id='global-snackbar' className={classes.message}>
          {Icon || <StandardIcon className={clsx(classes.icon, classes.iconVariant)} />}
          {message}
        </span>
      }
      action={action ?? undefined}
      {...rest}
    />
  );
};

const DEFAULT_AUTO_HIDE = 3000;
const DEFAULT_AUTO_HIDE_WITH_ACTION = 6000;
const GlobalSnackbar = ({
  open,
  setSnackbar,
  variant,
  message,
  Icon,
  onClose,
  autoHideDuration,
  action,
  ...rest
}: SnackbarContextProps & SnackbarContextInterface) => {
  const handleClose = (event: React.SyntheticEvent<any>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') return;
    if (onClose) onClose(event, reason);
    setSnackbar((prevSnackbar) => {
      if (!prevSnackbar) return prevSnackbar;
      return {
        ...prevSnackbar,
        open: false,
      };
    });
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={autoHideDuration ?? (action ? DEFAULT_AUTO_HIDE_WITH_ACTION : DEFAULT_AUTO_HIDE)}
      onClose={handleClose}
      {...rest}
    >
      <GlobalSnackbarContent Icon={Icon} variant={variant} message={message} action={action} {...rest} />
    </Snackbar>
  );
};

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbar, setSnackbar] = useState<GlobalSnackbarProps & SnackbarProps>();

  return (
    <SnackbarContext.Provider value={{ setSnackbar }}>
      {children}
      {snackbar ? <GlobalSnackbar {...snackbar} setSnackbar={setSnackbar} /> : null}
    </SnackbarContext.Provider>
  );
};
