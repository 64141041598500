import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { withResizeDetector } from 'react-resize-detector';
import { Responsive } from 'react-grid-layout';
import '../../../../../theme/src/rechart.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { WidgetItem } from './WidgetItem';
import { EmptyWidget } from './dashboard-widget-setting/EmptyWidget';
import { WidgetDisplayContextProvider } from './WidgetDisplayContext';
import { ManagementDashboardContext } from '../../../../program-manager/management-dashboard/ManagementDashboardProvider';

const ResponsiveGridLayout = withResizeDetector(Responsive);

export const DashboardWidgetsContainer = () => {
  const {
    editLayout,
    onLayoutChange,
    components,
    layouts,
    onDeleteWidget,
    onEditWidget,
    loading,
    setShowEditWidget,
  } = useContext(ManagementDashboardContext);

  return (
    <WidgetDisplayContextProvider>
      <Box style={{ padding: '0 16px', background: '#f1f5f5', height: '100%', overflowY: 'scroll' }}>
        <ResponsiveGridLayout
          layouts={{ lg: layouts, md: layouts }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 5, md: 4, sm: 4, xs: 1, xxs: 1 }}
          rowHeight={375}
          margin={[16, 16]}
          isResizable={editLayout}
          isDraggable={editLayout}
          compactType='horizontal'
          onLayoutChange={onLayoutChange}
        >
          {components.map((component) => (
            <Box
              key={component.dashboardComponentId}
              style={{
                background: 'white',
                borderRadius: 6,
                margin: 6,
              }}
            >
              <WidgetItem component={component} onDelete={onDeleteWidget} onEdit={onEditWidget} />
            </Box>
          ))}
        </ResponsiveGridLayout>
        {components.length === 0 && !loading && <EmptyWidget onClick={() => setShowEditWidget(true)} />}
      </Box>
    </WidgetDisplayContextProvider>
  );
};
