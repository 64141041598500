import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { SimpleMap } from '../../../../common/SimpleMap';
import { ActivityHeader } from './ActivityHeader';
import { ActivityLocation as ActivityLocationType } from './__generated__/ActivityLocation';
import { ActivityRosterTemplate as RosterTemplateType } from './__generated__/ActivityRosterTemplate';

const useActivityLocationInformationStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      fontSize: '1rem',
      fontWeight: 900,
      marginTop: '0.75rem',
      color: theme.color.grey.neutralBrand800,
    },
    paragraph: {
      fontWeight: 400,
      fontSize: '1rem',
      color: theme.color.grey.neutral300,
      marginTop: '0.675rem',
    },
  })
);
interface ActivityLocationInformationProps {
  activityLocation: ActivityLocationType | null;
  rosterTemplate: RosterTemplateType | null;
}

export function ActivityLocationInformation({ activityLocation, rosterTemplate }: ActivityLocationInformationProps) {
  const { lat, long } = activityLocation?.placesAddress?.latLong || {};

  const locations = [
    rosterTemplate?.placesAddress?.latLong ?? activityLocation?.placesAddress.latLong,
  ].filter((location) => Boolean(location)) as Array<{ lat: number; long: number }>;

  const classes = useActivityLocationInformationStyles();

  return activityLocation ? (
    <Box marginTop='2rem'>
      <ActivityHeader title='Location Information' />
      {lat !== undefined && long !== undefined && (
        <Box width='100%' height='250px' marginTop='1rem'>
          <SimpleMap locations={locations} />
        </Box>
      )}
      <React.Fragment>
        <Typography className={classes.head}>Address</Typography>
        <Typography className={classes.paragraph}>
          {rosterTemplate?.placesAddress?.formatted ?? activityLocation?.placesAddress?.formatted}
        </Typography>
      </React.Fragment>
    </Box>
  ) : null;
}
