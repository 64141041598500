import React, { Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

type LoadingContextType = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};
const LoadingContext = React.createContext<LoadingContextType | undefined>(undefined);

interface Props {
  children: ReactNode;
}

export const LoadingContextProvider = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  return <LoadingContext.Provider value={{ isLoading, setIsLoading }} {...props} />;
};

export const useLoadingContext = () => {
  const value = useContext(LoadingContext);
  if (!value) throw new Error('useLoadingContext must be inside of a LoadingContextProvider');
  return value;
};
