import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import React, { useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { useAdminConsoleActions } from '../../../admin-console-actions';
import {
  IncidentSeverityFormValuesType,
  SeveritiesFormSectionContent,
  IncidentSeverityType,
} from '../admin-console-incident-report-model';
import { SeverityListFieldSection } from './SeverityListFieldSection';
import { EditSeverityDialog } from './EditSeverityDialog';

type IncidentSeverityEditFormType = {
  initialValues: IncidentSeverityFormValuesType;
  refetchSeverities?: () => void;
};
export const IncidentSeverityEditForm = ({ initialValues, refetchSeverities }: IncidentSeverityEditFormType) => {
  const [editSeverityDialog, setEditSeverityDialog] = useState<boolean>(false);
  const [severityToEdit, setSeverityToEdit] = useState<IncidentSeverityType>();
  const { runSaveIncidentSeverities, runSaveIncidentSeveritiesLoading } = useAdminConsoleActions();
  const formContent = SeveritiesFormSectionContent;

  return (
    <Box py={4}>
      <LinearProgressOverlay isLoading={runSaveIncidentSeveritiesLoading} />
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          severities: initialValues.severities.sort((a, b) => (a.order < b.order ? -1 : 1)),
        }}
        onSubmit={(values) => {
          runSaveIncidentSeverities(values);
        }}
      >
        <Form>
          <FieldArray name='severities'>
            {(arrayHelpers) => (
              <>
                <Grid container direction='row' style={{ paddingBottom: 16 }}>
                  <Grid item xs={8}>
                    <Typography variant='h6' style={{ fontWeight: 600 }}>
                      {formContent.formTitle}
                    </Typography>
                    <Typography variant={'body2'}>{formContent.helperText}</Typography>
                  </Grid>
                  <Grid item xs={4} container alignItems='flex-start' justify='flex-end'>
                    <TabletButton
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setSeverityToEdit(undefined);
                        setEditSeverityDialog(true);
                      }}
                    >
                      {formContent.addButtonText}
                    </TabletButton>
                  </Grid>
                </Grid>

                <Grid item xs>
                  <SeverityListFieldSection
                    arrayHelpers={arrayHelpers}
                    onEditIconClick={(selectedSeverity: IncidentSeverityType) => {
                      setSeverityToEdit(selectedSeverity);
                      setEditSeverityDialog(true);
                    }}
                  />
                </Grid>

                <EditSeverityDialog
                  open={editSeverityDialog}
                  onClose={() => setEditSeverityDialog(false)}
                  title={severityToEdit ? formContent.editSeverityDialogTitle : formContent.addSeverityDialogTitle}
                  refetchSeverities={refetchSeverities}
                  severityToEdit={severityToEdit}
                />
              </>
            )}
          </FieldArray>
        </Form>
      </Formik>
    </Box>
  );
};
