import { FileUploadGallery } from '@campfire/file-upload-gallery';
import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useBetween } from 'use-between';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { GET_ACTIVITIES_ATTACHMENTS } from './activity-attachments-model.gql';
import { GetActivitiesConsoleAttachments } from './__generated__/GetActivitiesConsoleAttachments';

interface Props {
  activityId?: string;
}

const attachmentState = () => {
  const [attachments, setAttachments] = useState<Array<any>>();
  return {
    attachments,
    setAttachments,
  };
};

export const useSharedAttachmentState = () => useBetween(attachmentState);

export const AttachmentDetail = (props: Props) => {
  const { theme } = useCampfireTheme();
  const { activityId } = props;
  const { attachments, setAttachments } = useSharedAttachmentState();
  const { data: getAttachments, loading: getAttachmentsIsLoading, refetch } = useCampfireQuery<
    GetActivitiesConsoleAttachments,
    any
  >(GET_ACTIVITIES_ATTACHMENTS, {
    options: {
      variables: {
        activityId: activityId,
      },
    },
  });

  const activityAttachments = getAttachments?.vm.activity?.activityAttachments;

  useEffect(() => {
    setAttachments(activityAttachments);
  }, [activityAttachments]);

  if (!getAttachmentsIsLoading && attachments === undefined && refetch) {
    refetch();
  }

  return (
    <Box>
      {!getAttachmentsIsLoading && attachments ? (
        <Box>
          {attachments.length ? (
            <FileUploadGallery allowMultiple uploadedFiles={attachments} viewOnly />
          ) : (
            <Box>
              <Typography
                style={{
                  textAlign: 'center',
                  color: theme.color.grey.neutral400,
                  fontSize: '13px',
                  paddingTop: '12px',
                }}
              >
                There are no attachments on this activity
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};
