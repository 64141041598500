import React from 'react';
import { get, upperFirst } from 'lodash';
import { TotalNumberWidget } from './TotalNumberWidget';
import { AverageNumberWidget } from './AverageNumberWidget';
import { TrackingNumberWidget } from './TrackingNumberWidget';
import { TargetNumberWidget } from './TargetNumberWidget';
import { CustomMultiplationNumberWidget, CustomMultiplationHelpInfo } from './CustomMultiplationNumberWidget';
import { GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents_widget_VOLUNTEER_NumberWidgetType as INumberWidget } from '../../dashboard-widget-setting/__generated__/GetDashboardPreference';
import { WidgetContainer, WidgetContent } from '../WidgetContainer';
import { WidgetHeader } from '../WidgetHeader';
import { TotalNumberConfiguration } from '../../dashboard-widget-setting/__generated__/TotalNumberConfiguration';
import { AverageNumberConfiguration } from '../../dashboard-widget-setting/__generated__/AverageNumberConfiguration';
import { TrackingNumberConfiguration } from '../../dashboard-widget-setting/__generated__/TrackingNumberConfiguration';
import { TargetNumberConfiguration } from '../../dashboard-widget-setting/__generated__/TargetNumberConfiguration';
import { CustomMultiplationNumberConfiguration } from '../../dashboard-widget-setting/__generated__/CustomMultiplationNumberConfiguration';
import { valueMap } from '../../dashboard-widget-setting/dashboard-widget-model';

export const rangeParsed = (range: string, type?: string) => {
  switch (type) {
    case 'VOLUNTEER_TargetNumberConfigurationType':
      switch (range) {
        case 'TODAY':
          return 'daily';

        case 'THIS_WEEK':
          return 'weekly';

        case 'THIS_MONTH':
          return 'monthly';

        case 'THIS_YEAR':
          return 'yearly';

        case 'ALL_TIME':
          return 'all time';

        default:
          return '';
      }
    default:
      switch (range) {
        case 'TODAY':
          return 'today';

        case 'THIS_WEEK':
          return 'week';

        case 'THIS_MONTH':
          return 'month';

        case 'THIS_YEAR':
          return 'year';

        case 'ALL_TIME':
          return 'all time';

        default:
          return '';
      }
  }
};

export const intervalParsed = (interval: string) => {
  switch (interval) {
    case 'DAILY':
      return 'aily';

    case 'WEEKLY':
      return 'weekly';

    case 'MONTHLY':
      return 'monthly';

    case 'YEARLY':
      return 'yearly';

    default:
      return '';
  }
};

export const intervalChangeParsed = (interval: string) => {
  switch (interval) {
    case 'DAILY':
      return 'day';

    case 'WEEKLY':
      return 'week';

    case 'MONTHLY':
      return 'month';

    case 'YEARLY':
      return 'year';

    default:
      return '';
  }
};

export const NumberWidget = ({ widget }: { widget: INumberWidget }) => {
  const { filters, targets } = widget;
  const { tag, name } = JSON.parse(targets)[0] as { tag: string; name: string };

  const subTitle = React.useMemo(() => {
    switch (widget.configuration.__typename) {
      case 'VOLUNTEER_TotalNumberConfigurationType':
        return `Total this ${rangeParsed(widget.configuration.range, widget.configuration.__typename)}`;
      case 'VOLUNTEER_AverageNumberConfigurationType':
        return `Average each ${intervalChangeParsed(widget.configuration.interval)}`;
      case 'VOLUNTEER_TrackingNumberConfigurationType':
        return `Tracking: ${intervalParsed(widget.configuration.interval)}`;
      case 'VOLUNTEER_CustomMultiplationNumberConfigurationType':
        return 'Custom Calculation';
      case 'VOLUNTEER_TargetNumberConfigurationType':
        return `${upperFirst(rangeParsed(widget.configuration.range, widget.configuration.__typename))} Target`;
      default:
        return '';
    }
  }, [widget]);

  const typeLabel = React.useMemo(() => {
    switch (widget.configuration.__typename) {
      case 'VOLUNTEER_TotalNumberConfigurationType':
        return `Total`;
      case 'VOLUNTEER_AverageNumberConfigurationType':
        return `Average`;
      case 'VOLUNTEER_TrackingNumberConfigurationType':
        return `Tracking`;
      case 'VOLUNTEER_CustomMultiplationNumberConfigurationType':
        return 'Custom';
      case 'VOLUNTEER_TargetNumberConfigurationType':
        return `Target`;
      default:
        return '';
    }
  }, [widget]);

  const title = get(valueMap, [tag.toLowerCase(), name.toLowerCase(), 'displayName'], '');
  const type = `Number > ${typeLabel}`;

  return (
    <WidgetContainer>
      <WidgetHeader title={title} subTitle={subTitle} type={type}>
        {widget.configuration.__typename === 'VOLUNTEER_CustomMultiplationNumberConfigurationType' && (
          <CustomMultiplationHelpInfo
            metricName={name}
            range={widget.configuration.range}
            multiplier={widget.configuration.multiplier}
          />
        )}
      </WidgetHeader>
      <WidgetContent>
        {(() => {
          if (widget.configuration.__typename === 'VOLUNTEER_TotalNumberConfigurationType') {
            return (
              <TotalNumberWidget
                tag={tag}
                name={name}
                configuration={widget.configuration as TotalNumberConfiguration}
                filters={filters}
              />
            );
          }
          if (widget.configuration.__typename === 'VOLUNTEER_AverageNumberConfigurationType') {
            return (
              <AverageNumberWidget
                tag={tag}
                name={name}
                configuration={widget.configuration as AverageNumberConfiguration}
                filters={filters}
              />
            );
          }
          if (widget.configuration.__typename === 'VOLUNTEER_TrackingNumberConfigurationType') {
            return (
              <TrackingNumberWidget
                tag={tag}
                name={name}
                configuration={widget.configuration as TrackingNumberConfiguration}
                filters={filters}
              />
            );
          }

          if (widget.configuration.__typename === 'VOLUNTEER_TargetNumberConfigurationType') {
            return (
              <TargetNumberWidget
                tag={tag}
                name={name}
                configuration={widget.configuration as TargetNumberConfiguration}
                filters={filters}
              />
            );
          }

          if (widget.configuration.__typename === 'VOLUNTEER_CustomMultiplationNumberConfigurationType') {
            return (
              <CustomMultiplationNumberWidget
                tag={tag}
                name={name}
                configuration={widget.configuration as CustomMultiplationNumberConfiguration}
                filters={filters}
              />
            );
          }
          return null;
        })()}
      </WidgetContent>
    </WidgetContainer>
  );
};
