import { unpackToDate } from '@campfire/hot-date';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Avatar } from '../../../../general/user-profile/components/Avatar';
import { VolunteerReviewCommonDetails } from '../__generated__/VolunteerReviewCommonDetails';

export const VolunteerReviewCommon = (props: {
  activityApplicationId?: string;
  volunteer: VolunteerReviewCommonDetails;
}) => {
  const { activityApplicationId, volunteer } = props;
  const currentActivityApplication = volunteer.activityApplications.find(
    (x) => x.activityApplicationId === activityApplicationId
  );
  const enrolledActivityIds = volunteer.activityEnrolments.map((x) => x.activity.activityId);

  const otherActivityApplications = volunteer.activityApplications.filter(
    (x) => x.activityApplicationId !== activityApplicationId && !enrolledActivityIds.includes(x.activity.activityId)
  );

  return (
    <Box paddingX={3} paddingY={3}>
      <Box display='flex' alignContent='center' alignItems='center'>
        <Avatar
          size={48}
          avatarUrl={volunteer.profile.avatarUrl}
          preferredName={volunteer.profile.preferredName}
          lastName={volunteer.profile.lastName}
        />
        <Box paddingLeft={2}>
          <Typography>{`${volunteer.profile.preferredName} ${volunteer.profile.lastName}`}</Typography>
          <Typography variant='body2'>{volunteer.profile.email}</Typography>
        </Box>
      </Box>

      {currentActivityApplication ? (
        <Box marginTop={2}>
          <Typography>{`Applied to join ${currentActivityApplication?.activity.name}`}</Typography>
          <Typography variant='body2' color='textSecondary'>
            {unpackToDate(currentActivityApplication.dateApplied).toFormat('cccc, d LLLL')}
          </Typography>

          {/* Insert applied-for dates */}
        </Box>
      ) : null}

      {enrolledActivityIds.length > 0 ? (
        <Box marginTop={3}>
          <Typography>{'Team member on'}</Typography>
          <Box component='ul' style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            {volunteer.activityEnrolments.map((activityEnrolment) => {
              return (
                <Box component='li' key={activityEnrolment.activityEnrolmentId} paddingLeft={1}>
                  <Typography variant='body2' color='textSecondary'>
                    {activityEnrolment.activity.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : null}

      {otherActivityApplications.length > 0 ? (
        <Box marginTop={3}>
          <Typography>{'Also applied for'}</Typography>
          <Box component='ul' style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            {otherActivityApplications.map((activityApplication) => {
              return (
                <Box component='li' key={activityApplication.activityApplicationId} paddingLeft={1}>
                  <Typography variant='body2' color='textSecondary'>
                    {activityApplication.activity.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export const VolunteerReviewCommonSkeletonLoading = () => {
  return (
    <Box paddingX={3} paddingY={2}>
      <Box display='flex' alignContent='center' alignItems='center'>
        <Skeleton variant='circle' width={48} height={48} />
        <Box paddingLeft={3}>
          <Skeleton variant='text' width={200} height={15} />
          <Skeleton variant='text' width={175} height={10} />
        </Box>
      </Box>

      <Box marginTop={4}>
        <Skeleton variant='text' width={'85%'} height={10} />
        <Skeleton variant='text' width={'45%'} height={10} />
      </Box>
    </Box>
  );
};
