import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CheckCircle } from '@material-ui/icons';
import React, { useState } from 'react';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { ConfirmationDialog } from '../../../../../../../common/dialogs/ConfirmationDialog';

type Props = {
  accountDescription: string;
  lastUpdated: string;
  name: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingRight: '1rem',
    },
    infoWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    title: {
      fontWeight: 700,
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      letterSpacing: '0.008em',
    },
    subtitle: {
      fontWeight: 400,
      fontSize: '0.813rem',
      lineHeight: '1.125rem',
      letterSpacing: '0.008em',
      opacity: '60%',
    },
    caption: {
      fontWeight: 400,
      fontStyle: 'italic',
      fontSize: '0.75rem',
      lineHeight: '1.375rem',
      letterSpacing: '0.008em',
      opacity: '60%',
    },
  })
);

export const AppStatusSection = (props: Props) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const classes = useStyles();
  const { theme } = useCampfireTheme();
  const { accountDescription, lastUpdated } = props;

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.infoWrapper}>
        <CheckCircle fontSize='small' style={{ color: theme.alert.green.light }} />
        <Box pl='0.5rem'>
          <Typography className={classes.title}>Active & Logged In</Typography>
          <Typography className={classes.subtitle}>{accountDescription}</Typography>
          <Typography className={classes.caption}>{`Last updated ${formatDistance(
            unpackToDateTime(lastUpdated)
          ).toLocaleLowerCase()}`}</Typography>
        </Box>
      </Box>
      <TabletButton
        variant='contained'
        size='small'
        style={{
          backgroundColor: '#A0AEC0',
          color: '#ffffff',
          boxSizing: 'border-box',
          borderRadius: '4px',
          paddingLeft: '8px',
          paddingRight: '8px',
          fontWeight: 600,
          fontSize: '13px',
          whiteSpace: 'nowrap',
        }}
        onClick={() => setShowConfirmationDialog(true)}
      >
        Sign Out
      </TabletButton>
      {showConfirmationDialog && (
        <ConfirmationDialog
          title='Confirm Sign Out'
          body={
            <>
              <Typography variant='body1' gutterBottom>
                {`Are you sure you want to Sign Out?`}
              </Typography>
              <Typography variant='body1'>Signing out wil bla bla</Typography>
            </>
          }
          open
          closeActionText='Cancel'
          confirmActionText='Sign Out'
          handleCloseClick={() => setShowConfirmationDialog(false)}
          handleConfirmClick={() => {
            console.log('confirmed');
          }}
        />
      )}
    </Box>
  );
};
