import React, { memo } from 'react';
import { useRouteMatch, Route } from 'react-router';
import { CampfireSwitch } from '../../../content-blocks/common/CampfireSwitch';
import { CategoryPage } from './category-page/CategoryPage';
import { ResourcePage } from './resource-page/ResourcePage';
import { CatalogueLandingPage } from './catalogue-landing-page/CatalogueLandingPage';
import { ResourceEditor } from './resource-page/resource-editor/ResourceEditor';

const Catalogue = memo(() => {
  const match = useRouteMatch();

  return (
    <CampfireSwitch>
      <Route exact path={match?.path} render={() => <CatalogueLandingPage />} />
      <Route exact path={`${match?.path}/create-resource`} render={() => <ResourceEditor />} />
      <Route exact path={`${match?.path}/:categoryId`} render={() => <CategoryPage />} />
      <Route exact path={`${match?.path}/:categoryId/:resourceId`} render={() => <ResourcePage />} />
    </CampfireSwitch>
  );
});

export { Catalogue };
