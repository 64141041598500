import React from 'react';
import { useFormikContext } from 'formik';
import { SwitchField } from './SwitchField';
import { AttachmentTaskField } from '../../../../__generated__/AttachmentTaskField';

type AttachmentValues = {
  field: AttachmentTaskField;
};

export const AttachmentsAdditionalFields = () => {
  const { values, setFieldValue } = useFormikContext<AttachmentValues>();
  return (
    <SwitchField
      title='Single Upload'
      description='Turning on Single Upload will only allow users to attach one document for this field.'
      checked={values.field.allowMultiple}
      onChange={() => setFieldValue('field.allowMultiple', !values.field.allowMultiple)}
    />
  );
};
