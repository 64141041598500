import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import FindYourPerfectTeam from '../../../assets/tutorial-graphics/explore/find_your_perfect_team.png';
import { TutorialDialog, TutorialDialogStep } from '../../../common/dialogs/TutorialDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: 'Admin Console',
    description: (
      <>
        The Admin Console is the key to your organisations platform.
        <br />
        <br />
        Here you can <strong>promote staff to administrators</strong>,{' '}
        <strong>build key onboarding/reporting processes</strong> and{' '}
        <strong>explore Volaby&apos;s additional features</strong> using our configurable settings.
      </>
    ),
    image: FindYourPerfectTeam,
  },
];

const AdminConsoleTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <TutorialDialog open={open} onClose={onClose} steps={steps} />;
};

export { AdminConsoleTutorialDialog };
