import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

const CampfireButton: React.FunctionComponent<ButtonProps> = ({ children, ...rest }: ButtonProps) => {
  return (
    <Button size='large' fullWidth variant='contained' color='primary' {...rest}>
      {children}
    </Button>
  );
};

export { CampfireButton };
