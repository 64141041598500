import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useContainerStyles = makeStyles(() => ({
  container: {
    margin: '0 56px',
    paddingTop: '1em',
  }
}));

export const useThumbnailStyles =  makeStyles((theme: Theme) => ({
  header: {
    fontSize: '1.3rem',
    color: '#3E3229',
    fontWeight: 550
  },
  body: {
    fontSize: '1em',
    color: '#545454'
  },
  date: {
    fontSize: '0.8rem'
  },
  segmentationContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  segmentationItem: {
    display: 'flex',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.color.grey.neutralBrand800,
    backgroundColor: theme.color.grey.border,
    padding: '8px',
    paddingLeft: '12px',
    paddingRight: '12px',
    marginLeft: '8px',
    borderRadius: '4px',
    '&:first-child': {
      marginLeft: 0
    }
  }
}));
