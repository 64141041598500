import React from 'react';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { StringParam, useQueryParam } from 'use-query-params';
import { Close, ExpandMore } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { debounce } from 'lodash';
import { TASK_GET_COMPLETED_TASKS } from './task-completed-tasks.gql';
import {
  GetCompletedTasks,
  GetCompletedTasksVariables,
  GetCompletedTasks_vm_task as TaskType,
  GetCompletedTasks_vm_task_pendingApprovals as CompletedTaskType,
} from './__generated__/GetCompletedTasks';
import { VolunteerSubmitProfile } from './VolunteerSubmitProfile';
import { AlertCard } from '../../../../common/cards/alert-card/AlertCard';
import { VolunteerSubmitValues } from './VolunteerSubmitValues';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { SearchField } from '../../../../common/inputs/SearchField';
import { useDeepEffect } from '../../../../hooks/useDeepEffect';

const LIMIT = 25;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
      overflowY: 'hidden',
    },
    field: {
      '& .header': {
        display: 'flex',
        borderBottom: `1px solid ${theme.color.grey.neutralBrand200}`,
        paddingBottom: '12px',
      },
      '& .title': {
        fontWeight: 900,
        fontSize: '20px',
        color: theme.color.grey.neutralBrand900,
        flex: 1,
      },
      '& .subTitle': {
        fontWeight: 400,
        fontSize: '16px',
        color: theme.color.grey.neutral400,
        marginTop: '12px',
      },
      '& .button': {
        textTransform: 'none',
        fontWeight: 700,
        padding: '8px',
        minWidth: 'unset',
        minHeight: 'unset',
        height: '34px',
        width: '34px',
        color: theme.color.error[900],
        border: `1px solid ${theme.color.error[900]}`,
        borderRsadius: '6px',
      },
    },
    profileContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    button: {
      alignItems: 'center',
      '& > span': {
        textTransform: 'none',
      },
      borderRadius: 6,
      height: 38,
    },
  })
);

interface VolunteerSubmitProps {
  taskId: string;
  onClose: () => void;
  onUpdate?: () => void;
}
export function VolunteerSubmit({ taskId, onClose, onUpdate }: VolunteerSubmitProps) {
  const [task, setTask] = React.useState<TaskType | null>();
  const [pendingApprovals, setPendingApprovals] = React.useState<CompletedTaskType[]>([]);
  const [hasMore, setHasMore] = React.useState(true);

  const [completedTaskQuery] = useCampfireLazyQuery<GetCompletedTasks, GetCompletedTasksVariables>(
    TASK_GET_COMPLETED_TASKS
  );
  const [completedTaskId, setCompletedTaskId] = useQueryParam('completedTaskId', StringParam);
  const [query, setQuery] = React.useState<string>('');
  const [queryDebounced, setQueryDebounced] = React.useState<string>('');
  const setDebounced = React.useRef(debounce(setQueryDebounced, 500)).current;

  const classes = useStyles();

  React.useEffect(() => {
    completedTaskQuery({
      variables: {
        taskId,
        limit: LIMIT,
        offset: 0,
        query: '',
      },
    }).then((response) => {
      const pendingApprovalsData = response.data?.vm.task?.pendingApprovals || [];
      setTask(response.data?.vm.task);
      setPendingApprovals((p) => p.concat(pendingApprovalsData));
      setHasMore(pendingApprovalsData.length === LIMIT);
    });
  }, [taskId]);

  React.useEffect(() => {
    setDebounced(query);
  }, [query]);

  const onLoadMore = () => {
    completedTaskQuery({
      variables: {
        taskId,
        limit: LIMIT,
        offset: pendingApprovals.length || 0,
        query: queryDebounced,
      },
    }).then((response) => {
      const pendingApprovalsData = response.data?.vm.task?.pendingApprovals || [];
      setPendingApprovals((p) => p.concat(pendingApprovalsData));
      setHasMore(pendingApprovalsData.length === LIMIT);
    });
  };

  useDeepEffect(() => {
    completedTaskQuery({
      variables: {
        taskId,
        limit: LIMIT,
        offset: 0,
        query: queryDebounced,
      },
    }).then((response) => {
      const pendingApprovalsData = response.data?.vm.task?.pendingApprovals || [];
      setPendingApprovals(pendingApprovalsData);
      setHasMore(pendingApprovalsData.length === LIMIT);
    });
  }, [queryDebounced]);

  const refetchAll = () => {
    completedTaskQuery({
      variables: {
        taskId,
        limit: LIMIT,
        offset: 0,
        query: queryDebounced,
      },
    }).then((response) => {
      const pendingApprovalsData = response.data?.vm.task?.pendingApprovals || [];
      setPendingApprovals(pendingApprovalsData);
      setHasMore(pendingApprovalsData.length === LIMIT);
      if (pendingApprovalsData.length === 0 && onUpdate) {
        onUpdate();
      }
    });
  };

  const isLoading = !task;

  return (
    <Box className={classes.container}>
      <Box className={classes.field}>
        <Box className='header' style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography className='title'>{isLoading ? <Skeleton width='10rem' /> : task?.title}</Typography>
          <Box display='flex' maxWidth='15rem' style={{ gap: '10px' }}>
            <SearchField value={query} onChange={(event) => setQuery(event.target.value)} />
            <Button variant='outlined' disableElevation className='button' onClick={onClose}>
              <Close />
            </Button>
          </Box>
        </Box>
      </Box>
      <AlertCard
        variant='info'
        title='The volunteers below have updated this task and require your approval. Click a volunteers name to see their task submission.'
      />
      <Box className={classes.profileContainer}>
        {isLoading ? (
          <React.Fragment>
            <Skeleton height='5rem' />
            <Skeleton height='5rem' />
            <Skeleton height='5rem' />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {pendingApprovals.map((completedTask) => (
              <VolunteerSubmitProfile
                key={completedTask.completedTaskId}
                updatedDate={completedTask.dateLastUpdated}
                profile={completedTask.profile}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  event.stopPropagation();
                  setCompletedTaskId(completedTask.completedTaskId);
                }}
              />
            ))}
            {hasMore && (
              <Button
                onClick={onLoadMore}
                style={{ alignSelf: 'center', marginTop: '1rem' }}
                className={classes.button}
                variant='outlined'
                disableElevation
                endIcon={<ExpandMore />}
              >
                Show more
              </Button>
            )}
          </React.Fragment>
        )}
      </Box>
      {completedTaskId && (
        <VolunteerSubmitValues
          completedTaskId={completedTaskId}
          onClose={() => {
            setCompletedTaskId(undefined);
          }}
          onUpdate={refetchAll}
        />
      )}
    </Box>
  );
}
