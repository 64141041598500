import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DesktopMac from '@material-ui/icons/DesktopMac';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import LaptopChromebook from '@material-ui/icons/LaptopChromebook';
import PhoneAndroid from '@material-ui/icons/PhoneAndroid';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import React, { ErrorInfo, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FullPageMessage } from './common/FullPageMessage';
import { CampfireVersionContextInterface } from './global/config/CampfireVersionContext';
import { getApiUrlFromWindowLocation } from './global/config/get-api-url';
import { writeErrorToLog } from './global/logging/LogService';

interface State {
  error: Error | null;
  errorInfo: any | null;
}

export interface LegacyErrorBoundaryComponentProps extends RouteComponentProps {
  versionContext: CampfireVersionContextInterface;
}

class LegacyErrorBoundaryComponent extends React.Component<LegacyErrorBoundaryComponentProps, State> {
  constructor(props: LegacyErrorBoundaryComponentProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { history, match, versionContext } = this.props;
    const url = getApiUrlFromWindowLocation();
    writeErrorToLog({
      error,
      errorInfo,
      history,
      match,
      url,
      versionContext,
    });
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Fetch undefined error indicates an outdated version of Javascript, so we assume they are running an old browser
      const isFetchUndefinedError = `${this.state.error}` === `ReferenceError: 'fetch' is undefined`;

      return isFetchUndefinedError ? (
        <FullPageMessage compact title={`We can't load Volaby`}>
          <Box mt={2} width={1} textAlign='center'>
            <Box display='inline-block'>
              <DesktopMac fontSize='large' />
              <LaptopChromebook />
            </Box>
            <Typography>
              {`To minimise compatibility issues please ensure that you're using an up to date version of Google Chrome, Microsoft Edge, Firefox, Safari, or other modern web browser.`}
            </Typography>
          </Box>
          <Box mt={2} width={1} textAlign='center'>
            <Box display='inline-block'>
              <PhoneIphone fontSize='large' />
              <PhoneAndroid style={{ marginLeft: -8 }} />
            </Box>
            <Typography>{'Volaby works great on mobile too.'}</Typography>
          </Box>
        </FullPageMessage>
      ) : (
        <FullPageMessage
          Icon={() => (
            <Fragment>
              <WhatshotIcon color='secondary' />
              <DirectionsRunIcon />
            </Fragment>
          )}
          title='This was very unexpected'
          subtitle='Volaby has crashed, hard. Please refresh this window.'
        />
      );
    }
    return this.props.children;
  }
}

const LegacyErrorBoundary = withRouter(LegacyErrorBoundaryComponent);

export default LegacyErrorBoundary;
