import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from '../../../../../../global/config/useSnackbar';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { useCancelActivityFetch, useSaveDraftRosterFetch, CancelActivityParams } from '../../activity-timeline-actions';
import { GET_ACTIVITY_TIMELINE_FUTURE_ROSTER } from '../../activity-timeline-model.gql';
import { SidebarItem } from '../../sidebar/ActivityTimelineSidebar';
import { ActivityTimelineFutureDraftRoster } from '../../__generated__/ActivityTimelineFutureDraftRoster';
import { ActivityTimelineFutureRosterVolunteer } from '../../__generated__/ActivityTimelineFutureRosterVolunteer';
import {
  GetActivityTimelineFutureDraftRoster,
  GetActivityTimelineFutureDraftRosterVariables,
} from '../../__generated__/GetActivityTimelineFutureDraftRoster';
import { capRosterGridDataRow, replaceArrayItem } from './activity-roster-helpers';
import {
  getRosterGridData,
  getUpdatedRosterCellActiveStatus,
  RosterGridData,
  RosterGridDataRow,
  ROSTER_CELL_ACTIVE_STATUS,
} from './activity-roster-model';

export const useActivityRoster = (options: {
  activityId: string;
  activityDate: string;
  patchSelectedSidebarItem: (partialSidebarItem: Partial<SidebarItem>) => void;
  UnableToReloadAction: React.ReactNode;
  rosterTemplateId?: string;
}) => {
  const { activityId, activityDate, patchSelectedSidebarItem, UnableToReloadAction, rosterTemplateId } = options;

  const [rosterGridData, setRosterGridData] = useState<RosterGridData>();
  const [storedRosterGridData, setStoredRosterGridData] = useState<RosterGridData>();
  const [changedVolunteers, setChangedVolunteers] = useState<Array<ActivityTimelineFutureRosterVolunteer>>([]);
  const [hasActiveCellChanges, setHasActiveCellChanges] = useState(false);

  const cancelActivity = useCancelActivityFetch();
  const saveDraftRoster = useSaveDraftRosterFetch();
  const { setSnackbar } = useSnackbar();

  const { data: activityData, loading: activityLoading, refetch: activityRefetch } = useCampfireQuery<
    GetActivityTimelineFutureDraftRoster,
    GetActivityTimelineFutureDraftRosterVariables
  >(GET_ACTIVITY_TIMELINE_FUTURE_ROSTER, {
    options: {
      variables: {
        activityId: activityId,
        activityDate: activityDate,
      },
    },
  });

  useEffect(() => {
    if (!activityData || activityLoading) return;
    const initialRosterGridData = getRosterGridData(activityData);
    const volunteersWithChanges: Array<ActivityTimelineFutureRosterVolunteer> = [];
    initialRosterGridData.forEach((x) => {
      if (x.hasActiveChanges) volunteersWithChanges.push(x.volunteer);
    });
    setRosterGridData(initialRosterGridData);
    setStoredRosterGridData(initialRosterGridData);
    setChangedVolunteers(volunteersWithChanges);
  }, [activityData, activityLoading]);

  useEffect(() => {
    if (!rosterGridData || !storedRosterGridData) return;
    let hasChanges = false;
    rosterGridData.forEach((x, xIndex) => {
      x.rosterSessions.forEach((y, yIndex) => {
        if (y.hasActiveChange !== storedRosterGridData[xIndex].rosterSessions[yIndex].hasActiveChange) {
          hasChanges = true;
        }
      });
    });
    setHasActiveCellChanges(hasChanges);
  }, [rosterGridData]);

  const draftRoster = activityData?.vm.draftRoster;
  const activity = activityData?.vm.activity;

  const hasUnpublishedChanges = useMemo(() => {
    return draftRoster?.hasUnpublishedChanges ?? false;
  }, [draftRoster]);

  const publishedRoster = draftRoster?.publishedRoster ?? null;
  const hasPublishedRoster = !!publishedRoster;

  const activityApplicants = useMemo(() => {
    return (
      activityData?.vm.activity?.activeActivityApplications.sort((a, b) =>
        sortVolunteersByRostering(a.volunteer, b.volunteer, activityData?.vm.draftRoster)
      ) ?? []
    );
  }, [activityData, activityDate]);

  const activityRoles = useMemo(() => {
    return activityData?.vm.activityRoles ?? [];
  }, [activityData]);

  const handleRosterGridSessionClick = useCallback(
    (volunteerId: string, sessionId: string) => {
      if (!rosterGridData) return;
      const rowDataIndex = rosterGridData.findIndex((x) => x.volunteerId === volunteerId);
      const rosterGridDataRow = rosterGridData[rowDataIndex];
      if (!rosterGridDataRow) return;

      const updatedRosterGridDataRow = getUpdatedRosterGridDataRow(rosterGridDataRow, sessionId);
      if (!updatedRosterGridDataRow) return;

      setRosterGridDataRow(rowDataIndex, updatedRosterGridDataRow);
    },
    [rosterGridData]
  );

  const setRosterGridDataRow = (rowDataIndex: number, updatedRosterGridDataRow: RosterGridDataRow) => {
    if (!rosterGridData) return;
    const rosterGridDataRow = rosterGridData[rowDataIndex];
    handleChangedVolunteerIds(rosterGridDataRow, updatedRosterGridDataRow);
    setRosterGridData(replaceArrayItem(rosterGridData, rowDataIndex, updatedRosterGridDataRow));
  };

  const updateRosterRow = useCallback(
    (volunteerId: string, updatedRosterGridDataRow: RosterGridDataRow) => {
      if (!rosterGridData) return;
      const rowDataIndex = rosterGridData.findIndex((x) => x.volunteerId === volunteerId);
      setRosterGridDataRow(rowDataIndex, updatedRosterGridDataRow);
    },
    [rosterGridData]
  );

  const handleChangedVolunteerIds = (original: RosterGridDataRow, updated: RosterGridDataRow) => {
    if (original.hasActiveChanges === updated.hasActiveChanges) return;

    if (updated.hasActiveChanges) {
      setChangedVolunteers((prev) => [...prev, updated.volunteer]);
      return;
    }
    setChangedVolunteers((prev) => prev.filter((x) => x !== updated.volunteer));
  };

  const addFullRostering = useCallback(
    ({ volunteerId, immediatePublish = false }: { volunteerId: string; immediatePublish?: boolean }) => {
      setRosterGridData((prev) => {
        if (!prev) return prev;
        const rowDataIndex = prev.findIndex((x) => x.volunteerId === volunteerId);
        const rosterGridDataRow = prev[rowDataIndex];
        if (!rosterGridDataRow) return prev;

        const { rosterSessions } = rosterGridDataRow;
        const updatedRosterSessions = rosterSessions.map((rosterSession) => {
          return {
            ...rosterSession,
            activeStatus: ROSTER_CELL_ACTIVE_STATUS.selected,
            hasActiveChange: true,
          };
        });
        const updatedRosterGridDataRow = capRosterGridDataRow({
          ...rosterGridDataRow,
          hasActiveChanges: true,
          rosterSessions: updatedRosterSessions,
        });

        if (!updatedRosterGridDataRow) return prev;

        handleChangedVolunteerIds(rosterGridDataRow, updatedRosterGridDataRow);
        const updatedRosterGridData = replaceArrayItem(prev, rowDataIndex, updatedRosterGridDataRow);
        runSaveDraftRoster(immediatePublish, undefined, updatedRosterGridData);

        return updatedRosterGridData;
      });
    },
    [setRosterGridData]
  );

  const runRefetch = useCallback(() => {
    if (!activityRefetch) return;
    activityRefetch()
      .then((res: { data: { vm: { draftRoster: any; }; }; }) => {
        patchSelectedSidebarItem({
          draftRoster: res.data.vm.draftRoster ?? undefined,
        });
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to reload',
          variant: 'error',
          action: UnableToReloadAction,
        })
      );
  }, [activityRefetch]);

  const runCancelActivity = useCallback(
    ({ 
      activityCancellationReasonId,
      description,
      addedAttachments,
      attachmentCreationTokens,
    }: CancelActivityParams) => {
      cancelActivity
        .run({
          activityId,
          activityDate,
          activityCancellationReasonId,
          description: JSON.stringify(description),
          addedAttachments,
          attachmentCreationTokens,
        })
        .then((res) => {
          if (!res.ok) {
            setSnackbar({
              open: true,
              message: 'Unable to cancel activity',
              variant: 'error',
            });
            return;
          }

          setSnackbar({
            open: true,
            message: 'Activity cancelled',
            variant: 'success',
          });

          patchSelectedSidebarItem({
            cancelledActivity: {
              __typename: 'VOLUNTEER_CancelledActivityType',
              cancelledActivityId: res.data.data.cancelledActivityId,
              activityDate,
            },
            activityReport: undefined,
          });
        })
        .catch(() =>
          setSnackbar({
            open: true,
            message: 'Unable to cancel activity',
            variant: 'error',
          })
        );
    },
    [activityId, activityDate]
  );

  const runSaveDraftRoster = useCallback(
    (immediatePublish: boolean = false, removedVolunteerId?: string, rosterGridDataOverride?: RosterGridData) => {
      if (activity?.hasOpenRoster && !immediatePublish) {
        setSnackbar({
          open: true,
          message: 'Drafts are not allowed for open rosters',
          variant: 'error',
        });
        return Promise.resolve();
      }

      const submitRosterGridData = rosterGridDataOverride || rosterGridData;
      if (!submitRosterGridData) return Promise.resolve();

      const draftRosterings: Array<{
        volunteerId: string;
        sessionIds: Array<string>;
      }> = [];

      submitRosterGridData.forEach((x) => {
        if (x.volunteerId === removedVolunteerId) return 1;
        const selectedSessions = x.rosterSessions.filter(
          (rosterSession) =>
            rosterSession.activeStatus === 'selected' || rosterSession.activeStatus === 'publishedSelected'
        );
        if (selectedSessions.length === 0) return 1;
        draftRosterings.push({
          volunteerId: x.volunteerId,
          sessionIds: selectedSessions.map((y) => y.session.sessionId),
        });
        return 0;
      });

      return saveDraftRoster
        .run({
          activityId,
          activityDate,
          draftRosterings,
          immediatePublish,
          rosterTemplateId,
        })
        .then((res) => {
          if (!res.ok) {
            setSnackbar({
              open: true,
              message: immediatePublish ? 'Unable to publish roster' : 'Unable to save draft',
              variant: 'error',
            });
            return;
          }

          setSnackbar({
            open: true,
            message: immediatePublish ? 'Roster published' : 'Draft roster saved',
            variant: 'success',
          });

          runRefetch();
        })
        .catch(() =>
          setSnackbar({
            open: true,
            message: immediatePublish ? 'Unable to publish roster' : 'Unable to save draft',
            variant: 'error',
          })
        );
    },
    [rosterGridData, runRefetch, rosterTemplateId, activityDate]
  );

  return {
    activity,
    activityApplicants,
    activityLoading,
    activityRoles,
    addFullRostering,
    changedVolunteers,
    handleRosterGridSessionClick,
    hasActiveCellChanges,
    hasUnpublishedChanges,
    hasPublishedRoster,
    rosterGridData,
    runCancelActivity,
    runRefetch,
    runSaveDraftRoster,
    updateRosterRow,
    publishedRoster,
  };
};

export const rosterLoveCount = (currentRostered: number, min: number | null, max: number | null) => {
  if (min === null && max === null) return 'No volunteer requirements';
  if (min !== null && currentRostered < min) return `Needs ${min - currentRostered} more volunteers`;
  if (
    (min !== null && max === null && currentRostered >= min) ||
    (min === null && max !== null && currentRostered <= max) ||
    (min !== null && max !== null && currentRostered >= min && currentRostered <= max)
  )
    return 'Meets volunteer requirements';
  if (max !== null && currentRostered > max) return `${currentRostered - max} too many volunteers`;
  return null;
};

export const getUpdatedRosterGridDataRow = (
  rosterGridDataRow: RosterGridDataRow,
  sessionId: string
): RosterGridDataRow | undefined => {
  const { rosterSessions } = rosterGridDataRow;
  const rosterSessionIndex = rosterSessions.findIndex((x) => x.session.sessionId === sessionId);
  const rosterSession = rosterSessions[rosterSessionIndex];

  if (!rosterSession) return undefined;

  const { activeStatus, hasActiveChange } = rosterSession;
  const updatedStatus = getUpdatedRosterCellActiveStatus(activeStatus);
  const updatedRosterSessions = replaceArrayItem(rosterSessions, rosterSessionIndex, {
    ...rosterSession,
    activeStatus: updatedStatus,
    hasActiveChange: !hasActiveChange,
  });
  const updatedHasActiveChanges = !!updatedRosterSessions.find((x) => x.hasActiveChange === true);

  return capRosterGridDataRow({
    ...rosterGridDataRow,
    hasActiveChanges: updatedHasActiveChanges,
    rosterSessions: updatedRosterSessions,
  });
};

const sortVolunteersByRostering = (
  a: ActivityTimelineFutureRosterVolunteer,
  b: ActivityTimelineFutureRosterVolunteer,
  draftRoster?: ActivityTimelineFutureDraftRoster | null
): number => {
  if (!draftRoster) return a.profile.preferredName.localeCompare(b.profile.preferredName);

  const aHasRostering = hasRostering({ volunteer: a, draftRoster });
  const bHasRostering = hasRostering({ volunteer: b, draftRoster });

  if (aHasRostering && bHasRostering) return a.profile.preferredName.localeCompare(b.profile.preferredName);
  if (aHasRostering) return -1;
  if (bHasRostering) return 1;
  return a.profile.preferredName.localeCompare(b.profile.preferredName);
};

const hasRostering = (args: {
  volunteer: ActivityTimelineFutureRosterVolunteer;
  draftRoster: ActivityTimelineFutureDraftRoster;
}): boolean => {
  const { volunteer, draftRoster } = args;
  return !!draftRoster.draftRosterings.find(
    (rostering) => rostering.volunteer.profile.userId === volunteer.profile.userId
  );
};
