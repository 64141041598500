import { TitularTooltip } from '@campfire/titular-tooltip';
import React, { memo } from 'react';
import { Star as StarIcon } from '@material-ui/icons';
import { ListItem, Grid, Typography, Box, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { unpackToDate } from '@campfire/hot-date';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { SupportResource } from '../__generated__/SupportResource';

interface ResourcesListItemProps {
  resource: SupportResource;
  borderBottom: boolean;
  showUploadDate: boolean;
}

const ResourcesListItem = memo((props: ResourcesListItemProps) => {
  const { resource, borderBottom, showUploadDate } = props;
  const { theme } = useCampfireTheme();

  const classes = useStyles();

  return (
    <Box className={classes.listItemRoot} display='flex' alignItems='center' alignContent='center'>
      <TitularTooltip
        placement='left'
        enterDelay={400}
        title='Featured Resource'
        description='Your team has indicated that this is an important item.'
      >
        <StarIcon
          style={{
            fontSize: 14,
            backgroundColor: theme.color.starYellow,
            color: theme.palette.secondary.contrastText,
            visibility: resource.featured ? 'visible' : 'hidden',
            padding: 5,
            borderRadius: 99,
            marginRight: 5,
          }}
        />
      </TitularTooltip>

      <ListItem
        key={resource.supportResourceId}
        style={{
          borderBottom: borderBottom ? `1px solid ${theme.color.grey.border}` : 'none',
          paddingBottom: 16,
          paddingTop: 16,
          paddingLeft: 12,
        }}
      >
        <Grid container wrap='nowrap' alignItems='center'>
          <Grid item xs zeroMinWidth>
            <Typography noWrap variant='body1'>
              {resource.title}
            </Typography>
            {showUploadDate ? (
              <Typography noWrap variant='body2' color='textSecondary'>{`Uploaded on ${unpackToDate(
                resource.dateAdded
              ).toFormat('dd MMM yyyy')}`}</Typography>
            ) : null}
          </Grid>

          {resource.supportResourceAttachments.length >= 1 ? (
            <Grid item>
              <Typography display='inline' variant='body2' color='textSecondary' style={{ paddingLeft: 16 }}>{`${
                resource.supportResourceAttachments.length
              } ${resource.supportResourceAttachments.length > 1 ? 'Attachments' : 'Attachment'}`}</Typography>
            </Grid>
          ) : null}
        </Grid>
      </ListItem>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemRoot: {
      '& :hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
  })
);

export { ResourcesListItem };
