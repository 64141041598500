import { gql } from '@apollo/client';

export const GET_TABLE_WIDGET_VOLUNTEER_APPLICATIONS = gql`
  query GetTableWidgetVolunteerApplications(
    $status: String
    $program: String
    $type: String
    $range: String
    $widgetType: String
  ) {
    vm {
      incomingVolunteers(program: $program, status: $status, type: $type, range: $range, widgetType: $widgetType) {
        incomingVolunteers {
          incomingVolunteerId
          name
          email
          application {
            applicationId
            dateSubmitted
            status
          }
        }
      }
    }
  }
`;
