import { Field, FieldProps } from '@campfire/field';
import { Grid } from '@material-ui/core';
import { connect } from 'formik';
import React from 'react';
import { SectionHeading } from '../user-profile/components/SectionHeading';

interface Props {
  fieldProps: FieldProps;
  formikPrefix: string;
  heading: string;
}

export const IncidentReportAddressSection = connect<Props>(({ fieldProps, formikPrefix, heading }) => {
  return (
    <>
      <Grid item xs={12}>
        <SectionHeading text={heading} />
      </Grid>

      <Grid container xs={12}>
        <Grid item xs={12}>
          <Field {...fieldProps} required name={`${formikPrefix}.lineOne`} label='Address Line 1' />
        </Grid>

        <Grid item xs={12}>
          <Field {...fieldProps} name={`${formikPrefix}.lineTwo`} label='Address Line 2 (optional)' />
        </Grid>

        <Grid item xs={6}>
          <Field {...fieldProps} required name={`${formikPrefix}.suburb`} label='Suburb' />
        </Grid>

        <Grid item xs={6}>
          <Field {...fieldProps} required name={`${formikPrefix}.postcode`} label='Postcode' />
        </Grid>

        <Grid item xs={6}>
          <Field {...fieldProps} required name={`${formikPrefix}.country`} label='Country' />
        </Grid>

        <Grid item xs={6} data-testid='selectField'>
          <Field {...fieldProps} required name={`${formikPrefix}.state`} label='State/Territory' />
        </Grid>
      </Grid>
    </>
  );
});
