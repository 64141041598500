import { Box } from '@material-ui/core';
import { AddRounded, RemoveRounded } from '@material-ui/icons';
import React from 'react';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { getActiveStatusColor, getOriginalStatusColor, RosterGridDataSession } from '../roster/activity-roster-model';

interface Props {
  session: RosterGridDataSession;
}

const VolunteerRosterSessionStatusDot = ({ session }: Props) => {
  const { theme } = useCampfireTheme();
  return (
    <Box
      display='flex'
      alignContent='center'
      alignItems='center'
      justifyContent='center'
      bgcolor={
        session.activeStatus === undefined ? getOriginalStatusColor(session) : getActiveStatusColor(session, theme)
      }
      borderRadius={8}
      width={11}
      height={11}
      style={{ marginRight: '-3px' }}
      border='2px solid white'
    >
      {session.activeStatus === 'selected' ? (
        <AddRounded style={{ margin: 0, padding: 0, fontSize: 11, color: 'white' }} />
      ) : session.activeStatus === 'removed' ? (
        <RemoveRounded style={{ margin: 0, padding: 0, fontSize: 11, color: 'white' }} />
      ) : null}
    </Box>
  );
};

export { VolunteerRosterSessionStatusDot };
