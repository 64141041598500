import { Typography } from '@material-ui/core';
import React from 'react';
import { AlertCard, AlertCardBody, getAlertCardColors } from '../../../../../../common/cards/alert-card/AlertCard';

export function TemplateItemDescription({ description }: { description: string }) {
  const { lightTextColor } = getAlertCardColors('info');

  return (
    <AlertCard variant='info' title='When and why does Volaby send this communication?'>
      <AlertCardBody>
        <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
          {description}
        </Typography>
      </AlertCardBody>
    </AlertCard>
  );
}
