import React, { memo, useState } from 'react';
import { Box, Typography, MenuItem, Checkbox, Select } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { BorderSelectInput, useFilterStyles } from '../../../../../common/filter-fields/FilterClasses';
import { GetActivityManagementActivityTags_vm_activityTags as ActivityTagType } from '../../activity-form-v2/__generated__/GetActivityManagementActivityTags';

const SELECT_NONE_VALUE = 'none';
const SELECT_ALL_VALUE = 'all';
type Props = {
  activityTags: ActivityTagType[];
  selectedTagIds: string[];
  onChange: (newValue: string[]) => void;
};

const TagFilterFields = memo(({ activityTags, selectedTagIds, onChange }: Props) => {
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const onSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    event.stopPropagation();
    const newValues = event.target.value as string[];
    const includesSelectNone = (event.target.value as string[]).includes(SELECT_NONE_VALUE);
    const includesSelectAll = (event.target.value as string[]).includes(SELECT_ALL_VALUE);
    if (includesSelectNone) {
      onChange([]);
      return;
    }
    if (includesSelectAll) {
      onChange(activityTags.map((tag) => tag.activityTagId));
      return;
    }
    onChange(newValues);
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'row' }}>
      <Select
        name='program'
        variant='outlined'
        fullWidth
        multiple
        displayEmpty
        open={popoverOpen}
        input={
          <BorderSelectInput
            status={selectedTagIds && selectedTagIds.length > 0 ? 'active' : undefined}
            onClick={() => setPopoverOpen(!popoverOpen)}
            style={{ cursor: 'pointer' }}
          />
        }
        IconComponent={() => (
          <Box display='flex' alignItems='center' onClick={() => setPopoverOpen(true)} style={{ cursor: 'pointer' }}>
            <ArrowDropDown
              style={{
                fill: selectedTagIds && selectedTagIds.length > 0 ? theme.palette.primary.main : 'rgba(0,0,0, 0.54)',
              }}
            />
          </Box>
        )}
        renderValue={() => {
          if (selectedTagIds.length >= activityTags.length) return 'All Activity Tags';
          if (selectedTagIds.length === 0) return 'None of Activity Tag';
          return activityTags
            .filter((t) => selectedTagIds.includes(t.activityTagId))
            .map((t) => t.name)
            .join(', ');
        }}
        onChange={onSelect}
        value={selectedTagIds}
        style={{ fontWeight: 'normal', cursor: 'pointer' }}
        margin='dense'
      >
        <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE} className={classes.menuItem}>
          <Typography variant='body2'>{'Select All'}</Typography>
        </MenuItem>
        <MenuItem key={SELECT_NONE_VALUE} value={SELECT_NONE_VALUE} className={classes.menuItem}>
          <Typography variant='body2'>{'Deselect All'}</Typography>
        </MenuItem>
        {activityTags
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ activityTagId, name }) => {
            return (
              <MenuItem key={activityTagId} value={activityTagId} className={classes.menuItem}>
                <Checkbox
                  color='primary'
                  value={activityTagId}
                  checked={selectedTagIds.includes(activityTagId)}
                  style={{ paddingLeft: 0 }}
                />
                <Typography variant='body2'>{name}</Typography>
              </MenuItem>
            );
          })}
      </Select>
    </Box>
  );
});

export { TagFilterFields };
