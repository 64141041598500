import React from 'react';
import { Typography, Theme, Box } from '@material-ui/core';

import { makeStyles, createStyles } from '@material-ui/styles';

interface Props {
  title: string | React.ReactNode;
  children?: React.ReactNode;
  action?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      borderBottom: '1px solid #DCDCDC',
      paddingBottom: '1rem',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      color: theme.color.grey.neutralBrand800,
      fontWeight: 900,
      fontSize: '1.25rem',
    },
  })
);

export function ActivityHeader({ title, children, action }: Props) {
  const classes = useStyles();

  return (
    <Box display='flex' width='100%'>
      <Box className={classes.header}>
        <Typography className={classes.title}>{title}</Typography>
        {action}
      </Box>
      {children}
    </Box>
  );
}
