import { gql } from '@apollo/client';

export const GET_ACTIVITIES_ATTACHMENTS = gql`
query GetActivitiesConsoleAttachments($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityAttachments {
            activityAttachmentId
            fileType
            url
            name
        }
      }
    }
}
`;