import { Box, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';

interface Props {
  rightColumnOpen: boolean;
}

const IncomingVolunteersLeftColumnWrapper: FunctionComponent<Props> = ({ children, rightColumnOpen }) => {
  const classes = useStyles({ rightColumnOpen });
  return (
    <Grid item container xs={rightColumnOpen ? 6 : 12} className={classes.grid}>
      <Box display='flex' flexDirection='column' minHeight={0} height='100%'>
        <Box flex={1} style={{ overflowY: 'scroll' }} display='flex' flexDirection='column'>
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: (props: { rightColumnOpen: boolean }) => (props.rightColumnOpen ? 'none' : 'flex'),
    },
  },
}));

export { IncomingVolunteersLeftColumnWrapper };
