import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Add, Description } from '@material-ui/icons';
import { HoverLink } from '@campfire/hover-link';

import { GET_EXPORTS_BY_TYPE } from './exports-model.gql';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { GetExportsByType, GetExportsByTypeVariables } from './__generated__/GetExportsByType';
import { GetExportById_vm_export as IExport } from './__generated__/GetExportById';
import { useCampfireLazyQuery } from '../../global/network/useCampfireLazyQuery';
import { EmptyExport } from './EmptyExports';

const useListStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTop: '1px solid lightgrey',
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      '& .title': {
        fontSize: 16,
        color: theme.color.grey.neutral500,
        fontWeight: 500,
      },
      '& .subTitle': {
        fontSize: 14,
        color: theme.color.grey.neutral500,
        fontWeight: 500,
      },
      '& .caption': {
        fontSize: 12,
        color: theme.color.grey.neutral500,
        fontWeight: 500,
      },
    },
    icon: {
      minWidth: 'unset',
      marginRight: '1.5rem',
    },
    downloadButton: {
      display: 'flex',
      alignItems: 'center',
      '& .text': {
        marginLeft: 10,
        fontSize: 16,
        fontWeight: 500,
        color: theme.color.secondary.main900,
      },
    },
  })
);

interface CompletedExportProps {
  exportItem: IExport;
}

const CompletedExport = ({ exportItem }: CompletedExportProps) => {
  const classes = useListStyles();
  return (
    <ListItem className={classes.root}>
      <ListItemIcon className={classes.icon}>
        <Description />
      </ListItemIcon>
      <ListItemText>
        <Box className={classes.text}>
          <Typography className='title'>{exportItem.name}</Typography>
          <Typography className='subTitle'>
            Generated by {exportItem.requestedBy.profile.preferredName} {exportItem.requestedBy.profile.lastName}
          </Typography>
        </Box>
      </ListItemText>
      <Box marginLeft='2rem'>
        {exportItem.attachment?.url && (
          <HoverLink external to={exportItem.attachment.url} className={classes.downloadButton}>
            <DownloadIcon />
            <Typography className='text'>Download</Typography>
          </HoverLink>
        )}
      </Box>
    </ListItem>
  );
};

const ExpiredExport = () => {
  const classes = useListStyles();
  return (
    <ListItem className={classes.root}>
      <ListItemText>
        <Box className={classes.text}>
          <Typography className='title'>Error generating export</Typography>
          <Typography className='caption'>Please try again</Typography>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const InprogressExport = () => {
  const classes = useListStyles();
  return (
    <ListItem className={classes.root}>
      <ListItemIcon className={classes.icon}>
        <CircularProgress size={24} />
      </ListItemIcon>
      <ListItemText>
        <Box className={classes.text}>
          <Typography className='title'>Generating...</Typography>
          <Typography className='caption'>
            Your download will appear here when it&apos;s ready, even if you leave the page.
          </Typography>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const useNewExportStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 20,
      fontWeight: 500,
      color: theme.color.grey.neutralBrand500,
    },
    button: {
      textTransform: 'none',
      color: theme.color.secondary.main900,
      fontSize: '1rem',
    },
  })
);

interface ExportsListProps {
  exportType: string;
  onCreate: () => void;
}

export function ExportsList({ exportType, onCreate }: ExportsListProps) {
  const [queryExports, { data, loading, called }] = useCampfireLazyQuery<GetExportsByType, GetExportsByTypeVariables>(
    GET_EXPORTS_BY_TYPE
  );
  const requestInterval = React.useRef<number | null>(null);
  React.useEffect(() => {
    requestInterval.current = window.setInterval(() => queryExports({ variables: { type: exportType } }), 1000);
    return () => {
      if (requestInterval.current) {
        window.clearInterval(requestInterval.current);
      }
    };
  }, []);

  const classes = useNewExportStyles();
  const allExports = [...(data?.vm.exports || [])];

  return (
    <List style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem', maxHeight: 300, overflowY: 'scroll', width: 700 }}>
      <ListItem>
        <ListItemText>
          <Typography className={classes.title}>Exports</Typography>
        </ListItemText>
        <Button startIcon={<Add />} className={classes.button} onClick={onCreate}>
          Create New
        </Button>
      </ListItem>
      {allExports.length === 0 && called && <EmptyExport />}
      {allExports.length === 0 && loading && called && (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <CircularProgress />
        </Box>
      )}
      {allExports
        .sort((a, b) => (a.dateStarted < b.dateStarted ? 1 : -1))
        .map((e) => {
          if (e.isExpired) {
            return <ExpiredExport key={e.exportId} />;
          }
          if (e.exportStatus === 'pending') {
            return <InprogressExport key={e.exportId} />;
          }
          return <CompletedExport exportItem={e} key={e.exportId} />;
        })}
    </List>
  );
}
