import { SuggestionField } from '@campfire/suggestion-field';
import { InputAdornment } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { LocationOnRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useRegionCodes } from '../auto-location-field/use-region-codes/use-region-codes';

export interface AddressAutoCompleteType {
  description: string;
  placesId: string | null;
  formatted: string;
  latitude: string | number;
  longitude: string | number;
}

interface AddressAutoCompleteConfig {
  id: string;
  value?: AddressAutoCompleteType;
  autoComplete?: string;
  onChange: (value: AddressAutoCompleteType) => void;
}

type AddressAutoCompleteProps = AddressAutoCompleteConfig & Omit<TextFieldProps, 'value' | 'onChange'>;

const AddressAutoComplete = (props: AddressAutoCompleteProps) => {
  const { id, value, autoComplete, onChange, ...rest } = props;

  const [map, setMap] = useState();
  const [autoCompleteSessionToken, setAutoCompleteSessionToken] = useState();
  const [suggestions, setSuggestions] = useState<{ description: string; id: string }[]>();
  const { allRegions } = useRegionCodes();

  const [autoCompleteService, setAutoCompleteService] = useState(
    (window as any).google?.maps?.places ? new (window as any).google.maps.places.AutocompleteService() : undefined
  );

  const [placesService, setPlacesService] = useState<any>();

  useEffect(() => {
    if ((window as any).google?.maps?.places) {
      setAutoCompleteService(new (window as any).google.maps.places.AutocompleteService());
      setAutoCompleteSessionToken(new (window as any).google.maps.places.AutocompleteSessionToken());
    }
    if ((window as any).google?.maps.Map) {
      setMap(new (window as any).google.maps.Map(document.createElement('div')));
    }
  }, [(window as any).google]);

  useEffect(() => {
    // For some cooked reason the only way to get place details with a placeId is using PlacesService which requires a map
    if (map && (window as any)?.google?.maps?.places)
      setPlacesService(new (window as any).google.maps.places.PlacesService(map));
  }, [map, (window as any).google]);

  const getPredictions = (query: string) => {
    if (autoCompleteService)
      autoCompleteService.getPlacePredictions(
        {
          input: query,
          componentRestrictions: { country: allRegions },
          sessionToken: autoCompleteSessionToken,
        },
        (predictions: any) => {
          if (predictions?.length)
            setSuggestions(predictions.map((pred: any) => ({ description: pred.description, id: pred.place_id })));
        }
      );
  };

  return (
    <SuggestionField
      name={id}
      autoComplete={autoComplete}
      placeholder='Start typing an address...'
      items={suggestions?.length ? suggestions : []}
      value={value}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment disablePointerEvents position='start'>
            <LocationOnRounded color='action' />
          </InputAdornment>
        ),
      }}
      variant='outlined'
      // helper={helper}
      // errorText={error as string}
      onChange={(event: any) => {
        if (event.target.value) getPredictions(event.target.value);
        // setFieldValue(name, '', true);
      }}
      onSelect={(selection: any) => {
        if (!placesService) return;
        placesService.getDetails(
          {
            placeId: selection.id,
            fields: ['formatted_address', 'geometry'],
            sessionToken: autoCompleteSessionToken,
          },
          (placeResult: any) => {
            onChange({
              description: selection.description,
              placesId: selection.id,
              formatted: placeResult.formatted_address,
              latitude: placeResult.geometry.location.lat(),
              longitude: placeResult.geometry.location.lng(),
            });
            // AutoCompleteSessionToken expires after single getDetails request so need to set a new token
            if ((window as any).google?.maps?.places)
              setAutoCompleteSessionToken(new (window as any).google.maps.places.AutocompleteSessionToken());
          }
        );
      }}
      {...rest}
    />
  );
};

export { AddressAutoComplete };
