import { gql } from '@apollo/client';

export const GET_TABLE_WIDGET_PRIORITISED_ACTIVITIES = gql`
  query GetTableWidgetPrioritisedActivities($programIds: [String!], $prioritised: Boolean) {
    vm {
      activities(programIds: $programIds, prioritised: $prioritised) {
        activityId
        name
        program {
          name
        }
      }
    }
  }
`;
