import { gql } from '@apollo/client';

export const CREATE_ROSTER_TEMPLATE_SCREEN_PROGRAM = gql`
  fragment CreateRosterTemplateScreenProgram on VOLUNTEER_ProgramType {
    programId
    name
  }
`;

export const CREATE_ROSTER_TEMPLATE_SCREEN_ACTIVITY = gql`
  fragment CreateRosterTemplateScreenActivity on VOLUNTEER_ActivityType {
    activityId
    name
    nextX(x: 8)
    ... on VOLUNTEER_NonRecurringActivityType {
      __typename
    }
    ... on VOLUNTEER_RecurringActivityType {
      __typename
    }
    startDate
    locationType
    activityLocation {
      activityLocationId
      placesAddress {
        placesId
        description
        formatted
        latLong {
          lat
          long
        }
      }
    }
    program {
      programId
      name
    }
    activityRemoteLocation {
      activityRemoteLocationId
      details
    }
    program {
      programId
    }
    sessions {
      ...CreateRosterTemplateScreenActivitySession
    }
    rosterTemplates {
      rosterTemplateId
      rosterDate
    }
  }

  fragment CreateRosterTemplateScreenActivitySession on VOLUNTEER_SessionType {
    sessionId
    name
    startTime
    endTime
    minVolunteers
    maxVolunteers
    reportType {
      reportTypeId
      name
    }
    activityLocation {
      placesAddress {
        formatted
      }
    }
    activity {
      activityLocation {
        placesAddress {
          formatted
        }
      }
      activityRemoteLocation {
        details
      }
    }
    autoReport
  }
`;

export const CREATE_ROSTER_TEMPLATE_SCREEN_GET_PROGRAMS_AND_ACTIVITIES = gql`
  query CreateRosterTemplateScreenGetProgramsAndActivities {
    vm {
      programs(hasRule: { rule: ManageActivities }) {
        ...CreateRosterTemplateScreenProgram
      }
      activities(hasRule: { rule: ManageActivities }) {
        ...CreateRosterTemplateScreenActivity
      }
    }
  }

  ${CREATE_ROSTER_TEMPLATE_SCREEN_PROGRAM},
  ${CREATE_ROSTER_TEMPLATE_SCREEN_ACTIVITY}
`;

export const CREATE_ROSTER_TEMPLATE_SCREEN_GET_ACTIVITIES = gql`
  query CreateRosterTemplateScreenGetActivities($activityIds: [String!]!) {
    vm {
      activities(activityIds: $activityIds) {
        ...CreateRosterTemplateScreenActivity
      }
    }
  }
  ${CREATE_ROSTER_TEMPLATE_SCREEN_ACTIVITY}
`;

export const EDIT_ROSTER_TEMPLATE_SCREEN_GET_ROSTER_TEMPLATE = gql`
  query EditRosterTemplateScreenGetRosterTemplate($rosterTemplateId: String!) {
    vm {
      rosterTemplate(rosterTemplateId: $rosterTemplateId) {
        ...EditRosterTemplateScreenRosterTemplate
      }
    }
  }
  fragment EditRosterTemplateScreenRosterTemplate on VOLUNTEER_RosterTemplateType {
    rosterTemplateId
    rosterNotes
    activity {
      activityId
      name
      program {
        programId
      }
      activityLocation {
        placesAddress {
          ...EditRosterTemplateScreenPlacesAddress
        }
      }
    }
    rosterDate
    placesAddress {
      ...EditRosterTemplateScreenPlacesAddress
    }
    remoteLocation {
      ...EditRosterTemplateScreenRemoteLocation
    }
    publishedRoster {
      rosterId
      activityDate
      rosterTemplate {
        rosterTemplateId
      }
    }
    draftRoster {
      draftRosterId
    }
  }

  fragment EditRosterTemplateScreenPlacesAddress on PlacesAddressType {
    placesId
    description
    formatted
    latLong {
      lat
      long
    }
  }

  fragment EditRosterTemplateScreenRemoteLocation on VOLUNTEER_ActivityRemoteLocationType {
    activityRemoteLocationId
    details
  }
`;

export const ROSTER_TEMPLATE_SCREEN_CHECK_RECURRENCE_DATE = gql`
  query RosterTemplateScreenCheckRecurrenceDate($activityId: String!, $rosterDate: Date) {
    vm {
      activity(activityId: $activityId) {
        activityId
        startDate
        occursOn(date: $rosterDate)
      }
    }
  }
`;

export const ROSTER_TEMPLATE_SCREEN_ACTIVITY_CANCELLATION_REASONS = gql`
  query RosterTemplateScreenActivityCancellationReasons {
    vm {
      activityCancellationReasons {
        activityCancellationReasonId
        label
      }
    }
  }
`;
