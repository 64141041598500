import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useWidgetStyles } from './shared';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

type WidgetHeaderProps = {
  title: string;
  subTitle: string;
  type?: string;
  children?: ReactNode;
  colorTheme?: 'green' | 'red' | 'yellow';
  title2?: string;
};

export function WidgetHeader(props: WidgetHeaderProps) {
  const { title, subTitle, type, children, colorTheme, title2 } = props;
  const classes = useWidgetStyles();
  const { theme } = useCampfireTheme();

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      style={{
        padding: '18px 24px 18px 24px',
        background: colorTheme ? theme.alert[colorTheme].extraLight : 'auto',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
      }}
    >
      <Box>
        <Typography className={classes.type} style={{ color: colorTheme ? theme.alert[colorTheme].medium : 'auto' }}>
          {type}
        </Typography>
        <Typography className={classes.title} style={{ color: colorTheme ? theme.alert[colorTheme].dark : 'auto' }}>
          {title}
        </Typography>
        {title2 && (
          <Typography
            className={classes.subtitle}
            style={{ color: colorTheme ? theme.alert[colorTheme].dark : 'auto' }}
          >
            {title2}
          </Typography>
        )}
        <Typography
          className={classes.subtitle}
          style={{ color: colorTheme ? theme.alert[colorTheme].medium : 'auto' }}
        >
          {subTitle}
        </Typography>
      </Box>
      {children}
    </Box>
  );
}
