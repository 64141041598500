import React, { useEffect, useMemo, useState } from 'react';
import { unpackToDateTime } from '@campfire/hot-date';
import { StringParam, useQueryParam } from 'use-query-params';
import { Box, Typography } from '@material-ui/core';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { useUser } from '../../../../../../global/auth/useUser';
import { GetMyNewsData, GetMyNewsDataVariables } from './__generated__/GetMyNewsData';
import { MyNewsListSkeleton } from './MyNewsListSkeleton';
import { GET_MY_NEWS_DATA } from './my-news-model.gql';
import { ShowMoreButton } from '../../../../../../common/buttons/ShowMoreButton';
import { MyElementsNewsThumbnail } from './MyElementsNewsThumbnail';
import { MyElementsSingleNewsView } from './MyElementsSingleNewsView';
import { useVolDashboardMyElementsContext } from '../../VolDashboardMyElementsContext';

export const MyElementsNewsTabContent = () => {
  const { viewMode } = useVolDashboardMyElementsContext();
  const { getVolunteerIdentity } = useUser();
  const volunteerIdentity = getVolunteerIdentity();

  const [initialListSize, setInitialListSize] = useState<number>(3);

  const [selectedNewsId, setSelectedNewsId] = useQueryParam('newsId', StringParam);

  const { data, loading, refetch: refetchAllNews } = useCampfireQuery<GetMyNewsData, GetMyNewsDataVariables>(
    GET_MY_NEWS_DATA,
    {
      options: {
        variables: {
          volunteerId: volunteerIdentity.volunteerId,
          bulletinView: viewMode,
        },
      },
    }
  );

  const news = useMemo(() => {
    return (
      data?.vm.bulletins.sort((a, b) => (unpackToDateTime(a.dateCreated) > unpackToDateTime(b.dateCreated) ? -1 : 1)) ??
      []
    );
  }, [data]);

  const pinnedNews = useMemo(() => {
    return news.filter((n) => n.pinned);
  }, [news]);

  useEffect(() => {
    if (pinnedNews.length > 4) setInitialListSize(0);
  }, [pinnedNews]);

  const otherNews = useMemo(() => {
    return news.filter((b) => !b.pinned);
  }, [news]);

  const selectedNews = useMemo(() => {
    if (!selectedNewsId || !news.length) return undefined;
    return news.find((n) => n.bulletinId === selectedNewsId);
  }, [selectedNewsId, news]);

  const handleSizeUpdate = () => {
    setInitialListSize(initialListSize + 6);
  };

  if (selectedNews)
    return (
      <MyElementsSingleNewsView
        singleNews={selectedNews}
        onClose={() => setSelectedNewsId(undefined)}
        refetchAllNews={refetchAllNews}
      />
    );

  if (loading) return <MyNewsListSkeleton />;

  if (!news.length)
    return (
      <Box my='24px'>
        <Typography>There are currently no news to view.</Typography>
      </Box>
    );

  return (
    <Box title='News List' width={1}>
      {pinnedNews.map((pn) => {
        return (
          <MyElementsNewsThumbnail
            key={pn.bulletinId}
            newsSingle={pn}
            onClick={() => {
              setSelectedNewsId(pn.bulletinId);
            }}
          />
        );
      })}
      {otherNews.slice(0, initialListSize).map((on) => {
        return (
          <MyElementsNewsThumbnail
            key={on.bulletinId}
            newsSingle={on}
            onClick={() => {
              setSelectedNewsId(on.bulletinId);
            }}
          />
        );
      })}
      {otherNews.length > initialListSize ? (
        <Box marginTop='32px'>
          <ShowMoreButton onClick={handleSizeUpdate} />
        </Box>
      ) : null}
    </Box>
  );
};
