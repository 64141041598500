import React from 'react';
import { Menu, MenuItem as MuiMenuItem, SvgIcon, Typography, ClickAwayListener, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { TabletButton } from '@campfire/tablet-button';
import { ExpandMore } from '@material-ui/icons';
import { capitalize } from 'lodash';
import { ProgramActionType, ProgramMainActionType, ProgramStatus, ProgramSubActionType } from './AllProgramTypes';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ReactComponent as LogoutIcon } from '../../../../assets/logout-light.svg';

interface Props {
  status: ProgramStatus;
  actions: ProgramActionType;
  onAction: (action: ProgramMainActionType | ProgramSubActionType) => void;
}

const useMenuItemStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: '1rem',
      height: '1rem',
      marginRight: '1rem',
    },
  })
);

const MENU_ITEMS_MAP = {
  withdraw: {
    text: 'Withdraw',
    icon: LogoutIcon,
  },
};

function MenuItem({
  action,
  onAction,
}: {
  action: ProgramSubActionType;
  onAction: (action: ProgramSubActionType) => void;
}) {
  const { text, icon: Icon } = MENU_ITEMS_MAP[action];
  const onMenuItemClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onAction(action);
  };
  const classes = useMenuItemStyles();

  return (
    <MuiMenuItem onClick={onMenuItemClick} className={classes.container}>
      <SvgIcon viewBox='0 0 16 16' fontSize='small' className={classes.icon}>
        <Icon />
      </SvgIcon>
      <Typography>{text}</Typography>
    </MuiMenuItem>
  );
}

export const ProgramStatusButtonContent = ({ programStatus }: { programStatus: ProgramStatus }) => {
  const { theme } = useCampfireTheme();

  return (
    <Box display='flex' alignItems='center'>
      <Box
        height='10px'
        width='10px'
        borderRadius='50%'
        marginRight='8px'
        bgcolor={theme.color.programs.status[programStatus]}
      />
      <Typography
        variant='caption'
        style={{
          fontSize: '12px',
          fontWeight: 700,
          color: theme.color.grey.neutral400,
          textTransform: 'uppercase',
          lineHeight: '14px',
        }}
      >
        {capitalize(programStatus)}
      </Typography>
    </Box>
  );
};

export function ProgramStatusIndicator({ status, actions, onAction }: Props) {
  const { theme } = useCampfireTheme();
  const actionRequired = status === 'not on team';
  const [anchorEl, setAnchorEl] = React.useState();

  const onOpenMenu = (event: any) => {
    if (actions.subActions.length > 0) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  return actionRequired ? (
    <TabletButton
      variant='contained'
      color='primary'
      aria-controls='program-status'
      aria-haspopup='true'
      size='small'
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onAction(actions.mainAction);
      }}
      style={{
        padding: '7px 12px',
        minWidth: '60px',
        textTransform: 'uppercase',
      }}
    >
      <Typography
        style={{
          fontWeight: 700,
          fontSize: '10px',
        }}
      >
        {capitalize(actions.mainAction)}
      </Typography>
    </TabletButton>
  ) : (
    <ClickAwayListener onClickAway={() => setAnchorEl(undefined)}>
      <div>
        <TabletButton
          variant='outlined'
          color='primary'
          aria-controls='program-status'
          aria-haspopup='true'
          onClick={onOpenMenu}
          endIcon={
            actions.subActions &&
            actions.subActions.length > 0 && (
              <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />
            )
          }
          style={{ padding: '7px 12px', border: `1px solid #DCDCDC`, whiteSpace: 'nowrap' }}
        >
          <ProgramStatusButtonContent programStatus={status} />
        </TabletButton>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          keepMounted
          onClose={(e: any) => {
            e.stopPropagation();
            setAnchorEl(undefined);
          }}
          getContentAnchorEl={null}
          PaperProps={{ style: { border: 'solid 1px #e9e9e9' } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {actions.subActions.map((action) => (
            <MenuItem action={action} onAction={onAction} />
          ))}
        </Menu>
      </div>
    </ClickAwayListener>
  );
}
