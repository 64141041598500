import React from 'react';
import { Box, Typography, Theme, Button, LinearProgress } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import pluralize from 'pluralize';
import { DataGrid, GridColumns, GridValueGetterParams } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { useButtonStyles } from '../styles/button';
import { useContainerStyles } from '../styles/container';
import { useTypographyStyles } from '../styles/typography';
import { useTableStyles } from '../styles/table';
import { AlertCard } from '../../../../../common/cards/alert-card/AlertCard';
import { ImportVolunteerGetPrograms } from '../queries/__generated__/ImportVolunteerGetPrograms';

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '1rem',
      borderRadius: '0.5rem',
      border: '1px solid',
      borderColor: theme.color.grey.border,
    },
    colorPrimary: {
      backgroundColor: 'white',
    },
    bar: {
      borderRadius: '0.5rem',
      backgroundColor: theme.color.campfireBlue,
    },
  })
)(LinearProgress);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    successSubtitle: {
      fontSize: 14,
      color: theme.alert.green.medium,
      marginTop: '0.5rem',
    },
    successFooter: {
      fontSize: 14,
      color: theme.alert.green.dark,
      cursor: 'pointer',
      marginTop: '0.625rem',
      fontWeight: 500,
    },
    warningSubtitle: {
      fontSize: 14,
      color: theme.alert.yellow.medium,
      marginTop: '0.5rem',
    },
    errorSubtitle: {
      fontSize: 14,
      color: theme.alert.red.medium,
      marginTop: '0.5rem',
    },
    errorContainer: {
      paddingLeft: '1rem',
    },
    button: {
      marginLeft: '0.5rem',
    },
  })
);

interface Props {
  data: {
    importedVolunteers: any[];
    duplicateVolunteers: any[];
  };
  errors?: string[];
  onBack: () => void;
  onComplete: () => void;
  vmPrograms?: ImportVolunteerGetPrograms;
  isImporting: boolean;
}

export function ImportedStep(props: Props) {
  const containerClasses = useContainerStyles();
  const typographyClasses = useTypographyStyles();
  const classes = useStyles();
  const { data, errors, onComplete, vmPrograms, isImporting } = props;
  const tableClasses = useTableStyles();
  const buttonClasses = useButtonStyles();

  if (isImporting) {
    return (
      <Box className={containerClasses.root}>
        <Typography className={typographyClasses.title}>Imported Volunteers</Typography>
        <Box marginTop='1.5rem'>
          <BorderLinearProgress variant='indeterminate' />
        </Box>
      </Box>
    );
  }

  if (!data?.importedVolunteers || !data?.duplicateVolunteers || (errors?.length || 0) > 0) {
    return (
      <Box className={containerClasses.root}>
        <Typography className={typographyClasses.title}>Imported Volunteers</Typography>
        <Box marginTop='1.5rem' />
        <AlertCard variant='urgent' title='Failed to import'>
          <Typography className={classes.errorSubtitle}>Wrong data format.</Typography>
          <ul className={classes.errorContainer}>
            {errors?.map((error) => (
              <li className={classes.errorSubtitle} key={error}>
                {error}
              </li>
            ))}
          </ul>
        </AlertCard>
      </Box>
    );
  }

  const importVolunteersCount = data.importedVolunteers.length;
  const duplicateVolunteersCount = data.duplicateVolunteers.length;

  const columns: GridColumns = [
    {
      field: 'email',
      headerName: 'Email Address',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'contactNumber',
      headerName: 'Phone Number',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'programId',
      headerName: 'Program',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams) => {
        const { field, row } = params;
        const programId = row[field];
        return vmPrograms?.vm.programs.find((program) => program.programId === programId)?.name || programId;
      },
    },
  ];

  const duplicateData = data?.duplicateVolunteers.map((datum: any) => ({
    id: datum.volunteerId,
    ...datum,
  }));

  return (
    <Box className={containerClasses.root}>
      <Typography className={typographyClasses.title}>Imported Volunteers</Typography>
      {importVolunteersCount > 0 && duplicateVolunteersCount === 0 && (
        <Box marginTop='1rem'>
          <AlertCard
            variant='success'
            title={`${importVolunteersCount} ${pluralize('volunteers', importVolunteersCount)} imported`}
          >
            <Typography className={classes.successSubtitle}>
              <strong>{importVolunteersCount}</strong> {pluralize('volunteer', importVolunteersCount)}{' '}
              {pluralize('has', importVolunteersCount)} been successfully imported and can be viewed in the Volunteer
              Database.
            </Typography>
            <Link to='volunteers-database'>
              <Typography className={classes.successFooter}>View Volunteer Database</Typography>
            </Link>
          </AlertCard>
        </Box>
      )}
      {duplicateVolunteersCount > 0 && importVolunteersCount > 0 && (
        <Box marginTop='1rem'>
          <AlertCard
            variant='warning'
            title={`${duplicateVolunteersCount} ${pluralize('duplicates', duplicateVolunteersCount)} failed to import`}
          >
            <Typography className={classes.warningSubtitle}>
              <strong>{importVolunteersCount}</strong> {pluralize('volunteer', importVolunteersCount)}{' '}
              {pluralize('was', importVolunteersCount)} successfully imported, but we removed{' '}
              <strong>{duplicateVolunteersCount}</strong> {pluralize('volunteer', duplicateVolunteersCount)} with email
              addresses that already exist.
              <br />
              Successfully imported volunteers can be viewed in the{' '}
              <Link to='volunteers-database'>
                <Typography className={classes.successFooter}>View Volunteer Database</Typography>
              </Link>
              .
            </Typography>
          </AlertCard>
        </Box>
      )}
      {importVolunteersCount === 0 && duplicateVolunteersCount > 0 && (
        <Box marginTop='1rem'>
          <AlertCard variant='urgent' title='All volunteers failed to import'>
            <Typography className={classes.errorSubtitle}>
              All email addresses associated with imported volunteers already exist.
            </Typography>
          </AlertCard>
        </Box>
      )}
      {duplicateVolunteersCount > 0 && (
        <Box flex height='600px' marginTop='0'>
          <Box>
            <Typography className={typographyClasses.title} style={{ fontSize: '20px' }}>
              Duplicate Volunteers
            </Typography>
            <Typography className={typographyClasses.subTitle} style={{ paddingBottom: '20px', marginTop: '10px' }}>
              The following volunteers have failed to import as their email addresses are linked to an existing
              volunteer.
            </Typography>
          </Box>
          <DataGrid columns={columns} rows={duplicateData} classes={tableClasses} disableSelectionOnClick />
        </Box>
      )}
      <Box display='flex' justifyContent='flex-end' marginTop='1rem'>
        {/* {duplicateVolunteersCount > 0 || errors?.length ? (
          <Button classes={buttonClasses} onClick={onBack} variant='outlined'>
            Review
          </Button>
        ) : null} */}
        <Button
          onClick={onComplete}
          classes={buttonClasses}
          className={classes.button}
          variant='contained'
          color='primary'
        >
          Complete
        </Button>
      </Box>
    </Box>
  );
}
