import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { CancelRounded, SentimentSatisfiedRounded } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Emoji, NimblePicker } from 'emoji-mart';
import emojiMartAppleData from 'emoji-mart/data/apple.json';
import React, { FormEventHandler, useRef, useState } from 'react';
import { useSession } from '../../../../global/auth/useSession';
import { useUser } from '../../../../global/auth/useUser';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';

interface UpdateEmojiEndpointSpec {
  profileId: string;
  emoji: string | null;
  text: string | null;
}

const useEditStatusDialogStyles = makeStyles(() =>
  createStyles({
    emojiPopperButton: {
      marginRight: 8,
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      cursor: 'pointer',
    },
  })
);

const InnerDialog = (props: DialogProps) => {
  const {
    user: { profileId, status },
  } = useUser();
  const { updateStatus } = useSession();
  const { onClose } = props;
  const [emoji, setEmoji] = useState<string | undefined>(status.emoji ?? undefined);
  const [statusText, setStatusText] = useState<string>(status.text ?? '');
  const ref = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const classes = useEditStatusDialogStyles();

  function handleCloseDialog() {
    return onClose && onClose({}, 'backdropClick');
  }

  const updateEmojiFetch = useEndpointFetch<UpdateEmojiEndpointSpec>('/vm/volunteer/profile/status/update');
  const { setSnackbar } = useSnackbar();

  function somethingWentWrong() {
    setSnackbar({
      message: 'Unable to update status',
      variant: 'error',
      open: true,
    });
  }

  function greatSuccess() {
    setSnackbar({
      message: 'Updated status',
      variant: 'success',
      open: true,
    });
  }

  const handleSubmit: FormEventHandler = (event) => {
    const values: UpdateEmojiEndpointSpec = {
      profileId,
      emoji: emoji ?? null,
      text: statusText === '' ? null : statusText,
    };
    updateEmojiFetch
      .run(values)
      .then((response) => {
        if (!response || !response.ok) {
          somethingWentWrong();
          return;
        }
        greatSuccess();
        updateStatus({ emoji: values.emoji ?? undefined, text: values.text ?? undefined });
        handleCloseDialog();
      })
      .catch(() => {
        somethingWentWrong();
      });
    event.preventDefault();
  };

  function handleOpenEmojiPicker() {
    setAnchorEl(ref.current);
  }

  function handleCloseEmojiPicker() {
    setAnchorEl(null);
  }

  function handleClearStatus() {
    setEmoji(undefined);
    setStatusText('');
  }

  const hasStatus = emoji || statusText;
  const saveButtonDisabled = updateEmojiFetch.isLoading || (emoji === status.emoji && statusText === status.text);

  return (
    <form onSubmit={handleSubmit} style={{ position: 'relative' }}>
      <CircularProgressOverlay isLoading={updateEmojiFetch.isLoading} />

      <DialogTitle disableTypography>
        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
          Update your status
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box display='flex' flex='1 1 auto' alignItems='center' alignContent='center' overflow='hidden'>
          <TextField
            value={statusText}
            onChange={(event) => setStatusText(event.target.value)}
            label=''
            placeholder={`What's your status?`}
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <div className={classes.emojiPopperButton} ref={ref} onClick={handleOpenEmojiPicker}>
                    {emoji ? (
                      <Box marginTop={'8px'}>
                        <Emoji emoji={emoji} set='apple' size={24} />
                      </Box>
                    ) : (
                      <SentimentSatisfiedRounded />
                    )}
                  </div>
                </InputAdornment>
              ),
              endAdornment: !hasStatus ? null : (
                <InputAdornment position='end'>
                  <Tooltip arrow placement='top' title='Clear status'>
                    <IconButton onClick={handleClearStatus}>
                      <CancelRounded fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Box>

        <Box marginTop={1} marginLeft={1}>
          <Typography variant='body2' color='textSecondary'>
            Other volunteers can see your status when they view your name on rosters
          </Typography>
        </Box>

        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseEmojiPicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <NimblePicker
            color='#796fff'
            set='apple'
            data={emojiMartAppleData as any}
            skinEmoji='hand'
            emoji={emoji}
            title='Select an emoji'
            perLine={9}
            onSelect={(value) => {
              setEmoji(value?.colons);
              handleCloseEmojiPicker();
            }}
          />
        </Popover>
      </DialogContent>

      <DialogActions style={{ padding: 16 }}>
        <TabletButton size='medium' variant='text' onClick={handleCloseDialog}>
          Cancel
        </TabletButton>
        <TabletButton disabled={saveButtonDisabled} size='medium' type='submit' color='primary' variant='contained'>
          Save
        </TabletButton>
      </DialogActions>
    </form>
  );
};

const EditStatusDialog = (props: DialogProps) => {
  return (
    <Dialog {...props} maxWidth='xs' fullWidth keepMounted={false}>
      <InnerDialog {...props} />
    </Dialog>
  );
};

export { EditStatusDialog };
