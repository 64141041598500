import React, { useState } from 'react';
import { unpackToDateTime } from '@campfire/hot-date';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography, IconButton } from '@material-ui/core';
import { Cancel, CheckCircle, Close } from '@material-ui/icons';
import { getStatusColors } from '../../../../../common/cards/ExpandableCard';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import {
  GetRosterScreenRosterData_vm_draftRoster_draftRosterings_volunteer_profile as DraftRosteringProfile,
  GetRosterScreenRosterData_vm_draftRoster_publishedRoster_rosterings_volunteer_profile as PublishedRosteringProfile,
} from './__generated__/GetRosterScreenRosterData';

import {
  GetRosterVolunteerInfo_vm_volunteer_activityRoles_tasks as ActivityRoleTasks,
  GetRosterVolunteerInfo_vm_volunteer_activityRoles_tasks_completedTasks as CompletedTasks,
} from './__generated__/GetRosterVolunteerInfo';
import { VolunteerTaskViewDialog } from '../../../volunteer-common-profile/profile-sections/tasks-section/VolunteerCompletedTaskItem';
import { VolunteerTaskAddDialog } from '../../../volunteer-common-profile/profile-sections/tasks-section/VolunteerTaskAddDialog';

export interface RosterRoleTasksDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmitReport: any;
  roleTasks: ActivityRoleTasks[];
  profile: DraftRosteringProfile | PublishedRosteringProfile;
}

export const RosterRoleTasksDialog = (props: RosterRoleTasksDialogProps) => {
  const { open, roleTasks, profile, onClose, onSubmitReport } = props;
  const { theme } = useCampfireTheme();
  const [selectedTask, setSelectedTask] = useState<ActivityRoleTasks | undefined>();
  const [selectedCompletedTask, setSelectedCompletedTask] = useState<CompletedTasks | undefined>();
  const [selectedCompletedTaskId, setSelectedCompletedTaskId] = useState<string | undefined>();

  const isCompleted = (task: ActivityRoleTasks) => {
    const completeTask = task.completedTasks.find((cTask) => cTask.profile?.profileId === profile.profileId);
    const status = completeTask && completeTask.status;
    if (status === 'approved' || status === 'pending' || status === 'admin-approved') {
      return 'completed';
    }
    if (status === 'not-applicable') {
      return 'na';
    }
    return 'new';
  };

  const handleClickTask = (task: ActivityRoleTasks) => {
    const completeStatus = isCompleted(task);
    if (completeStatus === 'completed' || completeStatus === 'na') {
      const completeTasks = task.completedTasks.filter((cTask) => cTask.profile?.profileId === profile.profileId);
      const completeTasksSorted = completeTasks.sort((a, b) =>
        unpackToDateTime(a.dateLastUpdated) > unpackToDateTime(b.dateLastUpdated) ? -1 : 1
      );
      setSelectedCompletedTask(completeTasksSorted[0]);
      setSelectedCompletedTaskId(completeTasksSorted[0]?.completedTaskId);
    }
    setSelectedTask(task);
  };

  const taskIcon = (status: string, iconColor: string) => {
    if (status === 'completed') {
      return <CheckCircle style={{ fontSize: '22px', color: iconColor }} />;
    }
    if (status === 'new') {
      return <Cancel style={{ fontSize: '22px', color: iconColor }} />;
    }
    return null;
  };

  const handleCloseTaskDialog = () => {
    setSelectedTask(undefined);
    setSelectedCompletedTask(undefined);
    setSelectedCompletedTaskId(undefined);
    onSubmitReport();
  };

  const onSuccess = (newCompletedTaskId: string) => {
    setSelectedCompletedTaskId(newCompletedTaskId);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle
        style={{
          borderBottom: `1px solid ${theme.color.grey.border}`,
          padding: `8px 24px 8px 24px`,
        }}
      >
        <Grid container alignItems='flex-start' justify={'space-between'}>
          <Grid item xs>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              {`Roster Tasks`}
            </Typography>
            <Typography variant='subtitle1' color='textSecondary' style={{ lineHeight: 1.3 }}>
              {`This volunteer's role requires the tasks below to be completed.`}
            </Typography>
            <Typography variant='subtitle1' color='textSecondary' style={{ lineHeight: 1.3 }}>
              {`When all role tasks requirements are completed, a check will appear beside the role badge.`}
            </Typography>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
                <Close color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: '24px 0' }} id='dialog-content'>
        <Grid container direction='column' style={{ width: '100%', padding: '0 24px' }}>
          {roleTasks.map((task) => {
            const statusColors = getStatusColors(isCompleted(task), theme);
            return (
              <Grid item>
                <Box
                  onClick={() => handleClickTask(task)}
                  key={task.taskId}
                  display={'flex'}
                  padding='16px'
                  marginBottom={'8px'}
                  justifyContent={'space-between'}
                  bgcolor={statusColors.cardBackgroundColor}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Box display='inline-flex' align-items='center' fontSize='1rem' fontWeight={600}>
                    <Box minWidth={'22px'}>{taskIcon(isCompleted(task), statusColors.iconColor)}</Box>
                    <Box paddingLeft={'10px'}>
                      <Typography style={{ color: statusColors.textColor, fontWeight: 600 }}>{task.title}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {selectedCompletedTask && selectedCompletedTaskId && (
          <VolunteerTaskViewDialog
            open
            onClose={handleCloseTaskDialog}
            completedTaskId={selectedCompletedTaskId}
            taskId={selectedTask?.taskId || ''}
            status={selectedCompletedTask.status}
            userId={profile.userId}
            profileId={profile.profileId}
            onSuccess={onSuccess}
          />
        )}
        {!selectedCompletedTask && selectedTask ? (
          <VolunteerTaskAddDialog
            open
            onClose={handleCloseTaskDialog}
            taskId={selectedTask?.taskId}
            userId={profile.userId}
            profileId={profile.profileId}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
