import { gql } from '@apollo/client';

const GET_SELECT_VOLUNTEER_FRAGMENT = gql`
  fragment GetSelectVolunteerFragment on VOLUNTEER_VolunteerType {
    volunteerId
    profile {
      profileId
      userId
      firstName
      lastName
      preferredName
      avatarUrl
    }
  }
`;

export const GET_SELECT_VOLUNTEERS_BY_ACTIVITY = gql`
  query GetSelectVolunteersByActivity($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityId
        name
        activityEnrolments {
          volunteer {
            ...GetSelectVolunteerFragment
          }
        }
      }
    }
  }
  ${GET_SELECT_VOLUNTEER_FRAGMENT}
`;

export const GET_SELECT_VOLUNTEERS_BY_PROGRAM = gql`
  query GetSelectVolunteersByProgram($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityId
        name
        program {
          activeVolunteers {
            ...GetSelectVolunteerFragment
          }
        }
      }
    }
  }
  ${GET_SELECT_VOLUNTEER_FRAGMENT}
`;

export const GET_SELECT_VOLUNTEERS_BY_ORG = gql`
  query GetSelectVolunteersByOrg($keywordsFilter: String!, $startDate: Date, $endDate: Date) {
    vm {
      programActiveVolunteersConnection(keywordsFilter: $keywordsFilter, startDate: $startDate, endDate: $endDate) {
        totalCount
        edges {
          node {
            ...GetSelectVolunteerFragment
          }
          cursor
        }
      }
    }
  }
  ${GET_SELECT_VOLUNTEER_FRAGMENT}
`;
