import React from 'react';
import { Box, Button, MenuItem, TextField, Typography, Grid, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { DaySelect } from '../../DaySelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginBottom: '12px',
    },
    label: {
      fontSize: '12px',
      fontWeight: 500,
      color: theme.color.grey.neutral400,
    },
    clearLabel: {
      fontSize: '12px',
      fontWeight: 400,
      color: theme.color.secondary.main700,
      cursor: 'pointer',
    },
    actionContainer: {
      display: 'flex',
      marginTop: 22,
    },
    button: {
      fontSize: 13,
      fontWeight: 500,
      borderRadius: 6,
    },
    showButton: {
      backgroundColor: theme.color.secondary.main900,
      color: 'white',
      '&:hover': {
        opacity: 0.8,
      },
    },
    clearButton: {
      backgroundColor: theme.color.secondary.main50,
      color: theme.color.secondary.main700,
      marginLeft: 8,
      '&:hover': {
        opacity: 0.8,
      },
    },
  })
);

const selectStyles = makeStyles(() =>
  createStyles({
    input: {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontSize: '12px',
      height: '19px',
    },
    root: {
      borderRadius: '6px',
      borderTopColor: '#9E9E9E',
      borderRightColor: '#9E9E9E',
      borderBottomColor: '#9E9E9E',
      borderLeftColor: '#9E9E9E',
      color: '#444444',
    },
    adornedStart: {
      paddingLeft: '0.75rem',
    },
  })
);

export type FilterValueType = {
  program: string;
  day: { [key: number]: boolean };
};
interface Props {
  value: FilterValueType;
  programOptions: {
    label: string;
    value: string;
  }[];
  onChange: (newValue: FilterValueType) => void;
}

export function ActivityFilter({ value, programOptions, onChange }: Props) {
  const classes = useStyles();
  const inputClasses = selectStyles();

  const [selectedProgram, setSelectedProgram] = React.useState(value.program);
  const [selectedDays, setSelectedDays] = React.useState(value.day);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProgram(event.target.value);
  };

  const handleDayChange = (dayValue: number) => {
    setSelectedDays((currentSelectedDay) => ({
      ...currentSelectedDay,
      [dayValue]: !currentSelectedDay[dayValue],
    }));
  };

  const dayOptions = React.useMemo(() => {
    return 'MTWTFSS'.split('').map((day, dayIndex) => ({
      value: dayIndex,
      label: day,
    }));
  }, []);

  const onClearDay = () => {
    setSelectedDays(value.day);
  };

  const onClearProgram = () => {
    setSelectedProgram(value.program);
  };

  const onClear = () => {
    onClearDay();
    onClearProgram();
  };

  const onSubmit = () => {
    onChange({
      program: selectedProgram,
      day: selectedDays,
    });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className={classes.labelContainer}>
            <Typography className={classes.label}>PROGRAMS</Typography>
            <Typography onClick={onClearProgram} className={classes.clearLabel}>
              Clear
            </Typography>
          </Box>
          <TextField
            name='program'
            fullWidth
            variant='outlined'
            select
            value={selectedProgram}
            onChange={handleChange}
            InputProps={{ classes: inputClasses }}
          >
            {programOptions.map(({ label, value: itemValue }) => (
              <MenuItem key={itemValue} value={itemValue}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className={classes.labelContainer}>
            <Typography className={classes.label}>DAYS</Typography>
            <Typography onClick={onClearDay} className={classes.clearLabel}>
              Clear
            </Typography>
          </Box>
          <DaySelect days={dayOptions} onSelectDay={handleDayChange} daysSelected={selectedDays} maxWidth={240} />
        </Grid>
      </Grid>
      <Box className={classes.actionContainer}>
        <Button
          onClick={onSubmit}
          className={`${classes.button} ${classes.showButton}`}
          variant='contained'
          disableElevation
        >
          Show the results
        </Button>
        <Button
          onClick={onClear}
          className={`${classes.button} ${classes.clearButton}`}
          variant='contained'
          disableElevation
        >
          Clear
        </Button>
      </Box>
    </Box>
  );
}
