import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSession } from '../auth/useSession';
import { useUser } from '../auth/useUser';
import { useCampfireQuery } from '../network/useCampfireQuery';
import { ApplicantShellAppBar, APPLICANT_SHELL_APP_BAR_HEIGHT } from './ApplicantShellAppBar';
import { GET_APPLICANT_VOLUNTEER_PROFILE } from './model/applicant-shell-query.gql';
import {
  GetApplicantVolunteerProfile,
  GetApplicantVolunteerProfileVariables,
} from './model/__generated__/GetApplicantVolunteerProfile';
import { SUYPLimbo } from './SUYPLimbo';
import { SUYPOverview } from './SUYPOverview';
import { SUYPTask } from './SUYPTask';
import { SUYPVolunteerProfile } from './SUYPVolunteerProfile';

const ApplicantShell = () => {
  const { user } = useUser();
  const { userId } = user;

  const { data, loading, refetch } = useCampfireQuery<
    GetApplicantVolunteerProfile,
    GetApplicantVolunteerProfileVariables
  >(GET_APPLICANT_VOLUNTEER_PROFILE, {
    options: {
      variables: {
        userId,
      },
    },
  });

  const hasApprovedProfile = useMemo(() => data?.vm.profile?.isProfileTaskApproved, [data]);
  const profileExists = useMemo(() => !!data?.vm.profile?.contactNumber, [data]);

  if (loading) return <CircularProgressOverlay isLoading />;

  return (
    <>
      <ApplicantShellAppBar />

      <Box
        style={{
          minHeight: `calc(100vh - ${APPLICANT_SHELL_APP_BAR_HEIGHT}px)`,
        }}
        bgcolor='#f1f1f1'
      >
        {!data?.vm.programs.length ? (
          <NoProgramsMessage />
        ) : (
          <Switch>
            <Route path='/' exact>
              {hasApprovedProfile ? (
                <SUYPOverview />
              ) : !hasApprovedProfile && profileExists ? (
                <SUYPLimbo preferredName={data.vm.profile?.preferredName} />
              ) : (
                <SUYPVolunteerProfile
                  task={data.vm.profileTask}
                  taskItems={data.vm.profileTask.taskItems ?? []}
                  loading={loading}
                  refetch={refetch}
                  programs={data.vm.programs.filter((program) => program.dateSuspended === null) ?? []}
                />
              )}
            </Route>

            {hasApprovedProfile ? (
              <Route path='/task/:taskId'>
                <SUYPTask />
              </Route>
            ) : null}

            <Route path='/sign-out'>
              <SignOut />
            </Route>

            <Route>
              <Redirect to='/' />
            </Route>
          </Switch>
        )}
      </Box>
    </>
  );
};

const NoProgramsMessage = () => (
  <Box width={1} height={1} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
    <Box width={1} maxWidth={720} minHeight={360}>
      <Typography variant='h5' style={{ fontWeight: 'bold' }}>
        No Programs
      </Typography>
      <Typography variant='body2'>
        There are no programs to choose from yet. Please come back later and try again.
      </Typography>
    </Box>
  </Box>
);

const SignOut = () => {
  const { logout } = useSession();
  logout();
  return null;
};

export default ApplicantShell;
