import { HoverText } from '@campfire/hover-link';
import React from 'react';

export const ClearFilterItem = ({ onClick, marginLeft }: { onClick: () => void; marginLeft: string }) => {
  return (
    <HoverText
      color='secondary'
      hoverColor='secondary'
      disableUnderline
      onClick={onClick}
      style={{ fontSize: '12px', marginLeft, width: 80 }}
    >
      {'>'} Clear Filter
    </HoverText>
  );
};
