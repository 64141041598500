import React from 'react';
import { Typography } from '@material-ui/core';

const SessionReportFormHeading = React.memo(({ heading }: { heading: string }) => (
  <>
    <Typography display='inline' variant='h3'>
      {heading.substr(0, 1).toUpperCase()}
    </Typography>
    <Typography display='inline' variant='h4'>
      {heading.slice(1, heading.length)}
    </Typography>
  </>
));

export { SessionReportFormHeading };
