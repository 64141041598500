import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../ActivityFragment.gql';

export const ACTIVITY_AVAILABILITY_ACTIVITY = gql`
  query ActivityAvailabilityGetActivity($activityId: String, $from: Date, $until: Date) {
    vm {
      activity(activityId: $activityId) {
        activityId
        name
        startDate,
        endDate
        occurrencesBetween(from: $from, until: $until)
        cancelledActivities {
          activityDate
        }
        sessions {
          sessionId
          name
          minVolunteers
          maxVolunteers
          startTime
          endTime
          dateRemoved
        }
        ... on VOLUNTEER_NonRecurringActivityType {
          __typename
        }

        ... on VOLUNTEER_RecurringActivityType {
          schedule {
            recurrences {
              ...Recurrence
            }
          }
        }
      }
    }
  }
  ${RECURRENCE_FRAGMENT}
`;