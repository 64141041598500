import { unpackToDate, unpackToTime } from '@campfire/hot-date';

export const filterByCommunicationType = (value: string) => (communication: any) => {
  if (value === 'all') return true;
  return (
    (communication.__typename === 'VOLUNTEER_SmsLogType' && value === 'sms') ||
    (communication.__typename === 'EMAIL_PostmarkOutboundEmailType' && value === 'email')
  );
};

export const filterByTemplateTag = (value: string) => (communication: any) => {
  if (value === 'all') return true;
  return (
    communication.__typename === 'EMAIL_PostmarkOutboundEmailType' &&
    communication.tag.toLocaleLowerCase() === value.toLocaleLowerCase()
  );
};

export const filterBySearch = (value: string) => (communication: any) => {
  return value ? communication.subject?.toLocaleLowerCase().includes(value.toLocaleLowerCase().trim()) : true;
};

export const filterByStartTime = (startTime: string) => (communication: any) => {
  if (!startTime) {
    return true;
  }
  const sentTimeOnlyFormatted = unpackToTime(communication.sentAt).toFormat('HH:mm');
  const sentTime = unpackToTime(sentTimeOnlyFormatted);
  const selectedStartTime = unpackToTime(startTime);
  return selectedStartTime <= sentTime;
};

export const filterByEndTime = (endTime: string) => (communication: any) => {
  if (!endTime) {
    return true;
  }
  const sentTimeOnlyFormatted = unpackToTime(communication.sentAt).toFormat('HH:mm');
  const sentTime = unpackToTime(sentTimeOnlyFormatted);
  const selectedEndTime = unpackToTime(endTime);
  return selectedEndTime >= sentTime;
};

export const filterByStartDate = (startDate: string) => (communication: any) => {
  if (!startDate) {
    return true;
  }
  const sentDate = unpackToDate(communication.sentAt);
  const selectedStartDate = unpackToDate(startDate);
  return selectedStartDate <= sentDate;
};

export const filterByEndDate = (endDate: string) => (communication: any) => {
  if (!endDate) {
    return true;
  }
  const sentDate = unpackToDate(communication.sentAt);
  const selectedEndDate = unpackToDate(endDate);
  return selectedEndDate >= sentDate;
};
