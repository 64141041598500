import { encodeDate, unpackToDate } from '@campfire/hot-date';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { AlertCard, getAlertCardColors } from '../../../../../../common/cards/alert-card/AlertCard';
import { useUser } from '../../../../../../global/auth/useUser';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { GET_MY_ELEMENTS_SIDEBAR_INCOMPLETE_REPORTS_DATA } from './side-bar-incomplete-reports-model.gql';
import {
  GetMyElementsSideBarIncompleteReportsData,
  GetMyElementsSideBarIncompleteReportsDataVariables,
} from './__generated__/GetMyElementsSideBarIncompleteReportsData';
import { MyActivityCheckOutDialog } from '../../main-section/my-activities/CICO/MyActivityCheckOutDialog';
import { RosteredStatus } from '../../../../activities-v2/ActivityTypes';

export const SideBarIncompleteReports = () => {
  const {
    getVolunteerIdentity,
    user: { userIdentityService },
  } = useUser();
  const volunteerIdentity = getVolunteerIdentity();

  const { darkTextColor, lightTextColor } = getAlertCardColors('urgent');

  const { data, loading, refetch } = useCampfireQuery<
    GetMyElementsSideBarIncompleteReportsData,
    GetMyElementsSideBarIncompleteReportsDataVariables
  >(GET_MY_ELEMENTS_SIDEBAR_INCOMPLETE_REPORTS_DATA, {
    options: {
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
        from: encodeDate(DateTime.fromJSDate(new Date('2019-01-01'))),
        until: encodeDate(DateTime.local()),
      },
    },
  });

  const pastPrivilegedReportDatesAsAl = useMemo(
    () =>
      data?.vm.volunteer?.activityEnrolments
        ?.filter((ae) => userIdentityService.isLeaderOfActivity(ae.activity.activityId))
        .flatMap((ae) =>
          ae.activity.occurrencesBetween
            .filter((occurrence) => unpackToDate(occurrence) < DateTime.local())
            .filter((occurrence) => {
              const activityReport = ae.activity.activityReports.find((ar) => ar.activityDate === occurrence);
              const reportIsIncomplete = !activityReport
                ? true
                : activityReport.sessionReports.length <
                  ae.activity.sessions.length - activityReport.cancelledSessions.length;

              if (ae.activity.cancelledActivities.find((date) => date.activityDate === occurrence)) {
                return false;
              }
              return reportIsIncomplete;
            })
            .map((occurrence) => ({
              activityId: ae.activity.activityId,
              date: occurrence,
            }))
        ) ?? [],
    [data]
  );

  const pastRosteredReportDates = useMemo(
    () =>
      data?.vm.volunteer?.rosterings
        .filter((r) => unpackToDate(r.publishedRoster.activityDate) < DateTime.local())
        .filter((r) => {
          const { activity, activityDate } = r.publishedRoster;
          if (activity.program.dateDeleted || activity.program.dateSuspended) return false;
          const activityReport = activity.activityReports.find((ar) => ar.activityDate === activityDate);

          if (activity.cancelledActivities.find((date) => date.activityDate === activityDate)) {
            return false;
          }

          const reportIsIncomplete = !activityReport
            ? true
            : activityReport.sessionReports.length < activity.sessions.length - activityReport.cancelledSessions.length;
          const existsInPrivileged = pastPrivilegedReportDatesAsAl.find(
            (pr) => pr.date === activityDate && pr.activityId === activity.activityId
          );

          return reportIsIncomplete && !existsInPrivileged;
        })
        .map((r) => ({
          activityId: r.publishedRoster.activity.activityId,
          date: r.publishedRoster.activityDate,
        })) ?? [],
    [data]
  );

  const incompleteReportsNum = useMemo(() => {
    return [...pastRosteredReportDates, ...pastPrivilegedReportDatesAsAl].length;
  }, [data]);

  const cicoConflict =
    data?.vm.volunteer?.isCheckedIn &&
    unpackToDate(data.vm.volunteer.checkedIn?.sessionReport.activityReport.activityDate) < DateTime.local();
  const [cicoOpen, setCICOOpen] = useState<boolean>();

  const cicoActivity = data?.vm.volunteer?.checkedIn?.sessionReport.activityReport.activity;
  const tempStatus: RosteredStatus = 'rostered';

  const formattedCICOActivity = useMemo(() => {
    return (
      cicoActivity && {
        ...cicoActivity,
        activityDate: data?.vm.volunteer?.checkedIn?.sessionReport.activityReport.activityDate,
        status: tempStatus,
        isEnrollable: true,
      }
    );
  }, [cicoActivity]);

  const handleSuccessCheckout = () => {
    if (refetch) refetch();
  };

  return (
    <>
      {cicoOpen && formattedCICOActivity !== undefined ? (
        <MyActivityCheckOutDialog
          open={cicoOpen}
          type={'myself'}
          onClose={() => setCICOOpen(false)}
          activity={formattedCICOActivity}
          handleSuccess={handleSuccessCheckout}
        />
      ) : null}
      {loading ? (
        <Skeleton variant='text' width='100%' height={105} style={{ paddingBottom: '24px' }} />
      ) : cicoConflict ? (
        <Box paddingBottom={3} width='100%' onClick={() => setCICOOpen(true)}>
          <AlertCard title='Outstanding Check Out' variant='urgent'>
            <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
              You have an outstanding check-out. <br />
              You will be unable to check-in to another session until you&apos;ve checked out.
            </Typography>

            <Typography
              variant='body2'
              display='inline'
              style={{ textDecoration: 'underline', color: darkTextColor, fontWeight: 700 }}
            >
              Click here
            </Typography>
            <Typography
              variant='subtitle2'
              display='inline'
              style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
            >
              {' to complete.'}
            </Typography>
          </AlertCard>
        </Box>
      ) : incompleteReportsNum > 0 ? (
        <Link
          to={{
            pathname: '/volunteering/activity-report',
            search: '?&tab=incomplete',
          }}
          style={{ textDecoration: 'none' }}
        >
          <Box paddingBottom={3} width='100%'>
            <AlertCard title='Outstanding Activity Report' variant='urgent'>
              <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                You have <span style={{ fontWeight: 'bold', color: darkTextColor }}>{incompleteReportsNum}</span>{' '}
                outstanding reports.
              </Typography>

              <Typography
                variant='body2'
                display='inline'
                style={{ textDecoration: 'underline', color: darkTextColor, fontWeight: 700 }}
              >
                Click here
              </Typography>
              <Typography
                variant='subtitle2'
                display='inline'
                style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
              >
                {' to complete.'}
              </Typography>
            </AlertCard>
          </Box>
        </Link>
      ) : null}
    </>
  );
};
