import { Box, Typography } from '@material-ui/core';
import React, { createRef, RefObject, useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { scrollIntoView } from '../../../../../common/functions/scroll-functions';
import { InfoCard } from '../../../../../common/cards/date-info-card/InfoCard';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { ReportManagementReportType } from '../__generated__/ReportManagementReportType';
import { AlertCard } from '../../../../../common/cards/alert-card/AlertCard';
import { ReportTypeListSkeleton } from './ReportTypeListSkeleton';
import { ReportTypeStatusChip } from '../../common/ReportTypeStatusChip';

type listItemsRefMap = { [key: string]: RefObject<HTMLDivElement> };

const ReportTypeListEmptyMessage = () => (
  <Box py={2}>
    <AlertCard variant='info' title='There are no report types to show' />
  </Box>
);

interface ReportTypeListItemProps {
  reportTypeId: string;
  title: string;
  subtitle: string;
  isDeactivated: boolean;
}
const ReportTypeListItem = (props: ReportTypeListItemProps) => {
  const { reportTypeId, title, subtitle, isDeactivated } = props;
  const [query, setQuery] = useQueryParams({
    reportTypeId: StringParam,
  });

  const { theme } = useCampfireTheme();

  return (
    <Box my='12px' onClick={() => setQuery({ ...query, reportTypeId })}>
      <InfoCard
        color={isDeactivated ? theme.color.activities.status.ended : theme.color.activities.status.default}
        selected={query?.reportTypeId === reportTypeId}
        size='m'
      >
        <Box display='flex' justifyContent='space-between' width={1}>
          <Typography
            variant='h6'
            noWrap
            style={{
              color: theme.color.grey.neutral400,
              width: '100%',
              fontWeight: 800,
              letterSpacing: '-0.02em',
              lineHeight: '1em',
              paddingTop: '5px',
            }}
          >
            {title}
          </Typography>

          <ReportTypeStatusChip isDeactivated={isDeactivated} />
        </Box>
        <Typography variant='caption' style={{ color: theme.color.grey.neutral200, paddingTop: '.3em' }}>
          {subtitle}
        </Typography>
      </InfoCard>
    </Box>
  );
};

interface ReportTypeListProps {
  reportTypes: Array<ReportManagementReportType>;
  reportTypesLoading: boolean;
}

export const ReportTypesList = (props: ReportTypeListProps) => {
  const { reportTypes, reportTypesLoading } = props;
  const [listItemsRef, setListItemsRef] = useState<listItemsRefMap>({});

  const [query] = useQueryParams({
    reportTypeId: StringParam,
  });

  useEffect(() => {
    const refs = reportTypes.reduce<listItemsRefMap>((acc, value) => {
      acc[`${value.reportTypeId}`] = createRef();
      return acc;
    }, {});
    setListItemsRef(refs);
  }, [reportTypes]);

  const selectedListItemRef = query.reportTypeId ? listItemsRef[query.reportTypeId] : undefined;

  useEffect(() => {
    if (selectedListItemRef && selectedListItemRef.current) {
      scrollIntoView(selectedListItemRef, { behavior: 'auto', block: 'nearest', inline: 'nearest' }, true);
    }
  }, [selectedListItemRef]);

  if (reportTypesLoading) return <ReportTypeListSkeleton />;

  if (reportTypes.length === 0) return <ReportTypeListEmptyMessage />;

  return (
    <>
      <Typography display='block' variant='caption' color='textSecondary' style={{ marginTop: 20, marginBottom: 20 }}>
        Total <span style={{ fontWeight: 700 }}>{reportTypes.length} </span>{' '}
        {reportTypes.length === 1 ? 'Report Type' : 'Report Types'}
      </Typography>
      {reportTypes.map((reportType) => (
        <div ref={listItemsRef[reportType.reportTypeId]} key={reportType.reportTypeId}>
          <ReportTypeListItem
            reportTypeId={reportType.reportTypeId}
            title={reportType.name}
            subtitle={`${reportType.items.length > 0 ? `${reportType.items.length} Fields | ` : ''} Used in ${
              reportType.activities.length
            } activities`}
            isDeactivated={Boolean(reportType.dateDeactivated)}
          />
        </div>
      ))}
    </>
  );
};
