import { gql } from '@apollo/client';

export const GET_ACTIVITY_CANCELLATION_CLOSURE_REASONS = gql`
  query GetActivityCancellationAndClosureReasons {
    vm {
      activityCancellationReasons {
        activityCancellationReasonId
        label
      }
      activityClosureReasons {
        activityClosureReasonId
        label
      }
    }
  }
`;
