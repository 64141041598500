import { Box, Typography } from '@material-ui/core';
import { Cancel, Help } from '@material-ui/icons';
import React from 'react';
import { getStatusColors, GoToButton } from '../../../../../common/cards/ExpandableCard';
import { useUser } from '../../../../../global/auth/useUser';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { AdditionalTask } from './VolunteerAdditionalTasksSection';

interface Props {
  task: AdditionalTask;
  onClick: () => void;
}

export const VolunteerTaskItem = (props: Props) => {
  const { task, onClick } = props;
  const { theme } = useCampfireTheme();
  const {
    user: {
      userIdentityService: { isVmAdmin },
    },
  } = useUser();
  const statusColors = getStatusColors('new', theme);

  const taskIcon = (status: string) => {
    if (status === 'new') {
      return <Cancel style={{ fontSize: '22px', color: statusColors.iconColor }} />;
    }
    return <Help />;
  };

  const handleClick = () => {
    if (!isVmAdmin && task.adminOnly) {
      return;
    }
    onClick();
  };

  return (
    <>
      <Box
        onClick={handleClick}
        key={task.taskId}
        display={'flex'}
        p={'15px 20px'}
        marginBottom={'8px'}
        justifyContent={'space-between'}
        bgcolor={statusColors.cardBackgroundColor}
        style={{
          cursor: !isVmAdmin && task.adminOnly ? 'default' : 'pointer',
        }}
      >
        <Box display='inline-flex' alignItems='center' fontSize='1rem' fontWeight={600}>
          {taskIcon('new')}
          <Box paddingLeft={'10px'}>
            <Typography style={{ color: statusColors.textColor, fontWeight: 600 }}>{task.title}</Typography>
          </Box>
        </Box>
        <Box>
          <GoToButton background={statusColors.buttonBackgroundColor} color={statusColors.buttonIconColor} />
        </Box>
      </Box>
    </>
  );
};
