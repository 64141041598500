import { Tab, Tabs, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { FullscreenDialog } from '../../../../common/dialogs/FullscreenDialog';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ChangePasswordPanel } from './ChangePasswordPanel';
import { EditProfilePanel } from './EditProfilePanel';

interface AccountSettingsDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  refetch?: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: { overflow: 'visible' },
  })
);

const useTabStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      minWidth: 72,
      textTransform: 'none',
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      '& [class*="MuiSvgIcon-root"]': {
        marginRight: 4,
        marginBottom: '0 !important',
      },
    },
  })
);

const TABS = ['Edit profile', 'Change password'];

const AccountSettingsDialog = (props: AccountSettingsDialogProps) => {
  const { open, setOpen, refetch } = props;
  const { isMobile } = useCampfireTheme();
  const classes = useStyles();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  function onClose() {
    setOpen(false);
  }

  function handleChange(_unusedEvent: React.ChangeEvent<{}>, value: number) {
    setSelectedTabIndex(value);
  }

  const tabClasses = useTabStyles();

  return (
    <FullscreenDialog
      open={open}
      close={onClose}
      fullScreen={isMobile}
      title={'Account Settings'}
      PaperProps={{ className: classes.dialogPaper }}
      maxWidth='sm'
      fullWidth
      dialogHeaderContent={
        <Tabs indicatorColor='primary' value={selectedTabIndex} onChange={handleChange} aria-label='my elements tabs'>
          {TABS.map((tabName) => (
            <Tab
              id={`accountSettings-${tabName}-tab`}
              disableRipple
              disableTouchRipple
              classes={tabClasses}
              label={tabName}
              key={tabName}
            />
          ))}
        </Tabs>
      }
    >
      {selectedTabIndex === 0 ? <EditProfilePanel refetch={refetch} /> : <ChangePasswordPanel onClose={onClose} />}
    </FullscreenDialog>
  );
};

export { AccountSettingsDialog };
