import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../../../../common/recurrence/recurrence-model.gql';

export const GET_ROSTER_SIDEBAR_ACTIVITIES = gql`
  query GetRosterSidebarActivities($activityIds: [String!]!) {
    vm {
      activities(activityIds: $activityIds, includeInactiveActivities: false) {
        activityId
        name
        startTime
        endTime
        startDate
        endDate
        isActive
        isSuspended
        closedActivity {
          closedActivityId
        }
        hasOpenRoster
        program {
          programId
        }
        activityLocation {
          activityLocationId
        }
        activityRemoteLocation {
          activityRemoteLocationId
        }
        nextX(x: 1)
        ... on VOLUNTEER_NonRecurringActivityType {
          __typename
        }
        ... on VOLUNTEER_RecurringActivityType {
          __typename
          recurrences {
            ...Recurrence
          }
        }
      }
    }
  }
  ${RECURRENCE_FRAGMENT}
`;
