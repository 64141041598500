import { TabletButton } from '@campfire/tablet-button';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { FunctionComponent, ReactNode } from 'react';

const ConfirmationDialog: FunctionComponent<{
  title: string;
  body: string | ReactNode;
  open: boolean;
  closeActionText: string;
  confirmActionText: string;
  handleCloseClick: (open: boolean) => void;
  handleConfirmClick: () => void;
  disableConfirm?: boolean;
}> = ({
  title,
  body,
  open,
  closeActionText,
  confirmActionText,
  handleCloseClick,
  handleConfirmClick,
  disableConfirm,
}) => {
  return (
    <Dialog open={open} onClose={() => handleCloseClick(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{typeof body === 'string' ? <Typography gutterBottom>{body}</Typography> : body}</DialogContent>
      <DialogActions>
        <TabletButton onClick={() => handleCloseClick(false)}>{closeActionText}</TabletButton>
        <TabletButton onClick={() => handleConfirmClick()} disabled={disableConfirm} color='error' autoFocus>
          {confirmActionText}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmationDialog };
