import { useSnackbar } from '../../../../../../../../global/config/useSnackbar';
import {
  useApproveProgramApplication,
  useWithdrawProgramApplication,
} from '../../../../../../../admin/program-management/program-management-actions';

export type ProgramApplicationActions = 'add' | 'withdraw';

export const useProgramApplicationActions = () => {
  const { setSnackbar } = useSnackbar();
  const approveProgramApplication = useApproveProgramApplication();
  const withdrawProgramApplication = useWithdrawProgramApplication();

  const runAddToTeam = (id: string, handleSuccess?: () => void) => {
    approveProgramApplication
      .run({
        programApplicationId: id,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to add to team',
            variant: 'error',
          });
          return;
        }
        if (handleSuccess) {
          handleSuccess();
        }
        setSnackbar({
          open: true,
          variant: 'success',
          message: 'Added to team successfully',
        });
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to add to team',
          variant: 'error',
        })
      );
  };

  const runWithdrawProgramApplication = (programApplicationId: string, handleSuccess?: () => void) => {
    withdrawProgramApplication
      .run({ programApplicationId })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to withdraw',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Withdrawn program application successfully',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to withdraw',
          variant: 'error',
        })
      );
  };

  const onAction = (action: ProgramApplicationActions, id: string, handleSuccess?: () => void) => {
    switch (action) {
      case 'add':
        runAddToTeam(id, handleSuccess);
        break;

      case 'withdraw':
        runWithdrawProgramApplication(id, handleSuccess);
        break;

      default:
        break;
    }
  };
  return {
    onAction,
  };
};
