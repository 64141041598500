import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import {
  GET_ADMIN_CONSOLE_APPLICANT_SCREENING_PANEL,
  GET_APPLICATION_AUTO_VERIFICATION,
} from './admin-console-applicant-screening.gql';
import { AutoVerificationEditForm } from './AutoVerificationEditForm';
import { ScreeningQuestionEditForm } from './ScreeningQuestionEditForm';
import { GetAdminConsoleApplicantScreeningPanel } from './__generated__/GetAdminConsoleApplicantScreeningPanel';
import { GetApplicationAutoVerification } from './__generated__/GetApplicationAutoVerification';

const AdminConsoleApplicantScreeningPannel = () => {
  const apiUrl = useApiUrl();
  const [autoVerification, setAutoVerification] = useState<boolean>();

  const { data, loading: questionsLoading, refetch: refetchQuestions } = useCampfireQuery<
    GetAdminConsoleApplicantScreeningPanel,
    undefined
  >(GET_ADMIN_CONSOLE_APPLICANT_SCREENING_PANEL);

  const initialScreeningQuestions = useMemo(() => {
    if (!data || !data.vm.screeningQuestions) return { screeningQuestions: [] };
    return {
      screeningQuestions: data.vm.screeningQuestions.map((screeningQuestion) => {
        return {
          screeningQuestionId: screeningQuestion.screeningQuestionId,
          correctAnswer: screeningQuestion.correctAnswer !== null ? screeningQuestion.correctAnswer : '',
          label: screeningQuestion.label,
          order: screeningQuestion.order,
        };
      }),
    };
  }, [data]);

  const { data: response, loading: verificationLoading } = useCampfireQuery<GetApplicationAutoVerification, undefined>(
    GET_APPLICATION_AUTO_VERIFICATION
  );

  const { run } = useCampfireFetch({ defer: true });

  useEffect(() => {
    if (response?.vm.applicationAutoVerification === undefined) return;
    setAutoVerification(response.vm.applicationAutoVerification);
  }, [response]);

  useEffect(() => {
    if (autoVerification === undefined) return;

    run({
      url: `${apiUrl}/vm/application/auto-verification/update`,
      method: 'post',
      data: {
        value: autoVerification,
      },
    });
  }, [autoVerification]);

  return (
    <Box position='relative' width={1} display='flex' justifyContent='center'>
      <LinearProgressOverlay isLoading={questionsLoading} />
      <Box px={8} pt={4} width={1} maxWidth={720}>
        <ScreeningQuestionEditForm initialValues={initialScreeningQuestions} refetchQuestions={refetchQuestions} />
        <AutoVerificationEditForm
          autoVerification={autoVerification}
          setAutoVerification={setAutoVerification}
          isLoading={verificationLoading}
        />
      </Box>
    </Box>
  );
};

export { AdminConsoleApplicantScreeningPannel };
