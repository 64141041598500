import { gql } from '@apollo/client';

export const INTEGRATION_APPS = gql`
query GetIntegrationAppsType {
    wm {
      integrationApps {
        dateCreated
        dateDeactivated
        name
      }
      availableApps
    }
  }
`;
