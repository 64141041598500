import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../../../../common/recurrence/recurrence-model.gql';

export const ActivityTimelineItemFragments = gql`
  fragment ActivityTimelineItem on VOLUNTEER_ActivityType {
    activityId
    isActive
    isEnrollable
    isRestrictedActivity
    name
    startTime
    endTime
    startDate
    endDate
    isSuspended
    isHidden
    hasOpenRoster
    priority
    allowCICO
    program {
      programId
      name
      allowCICO
    }
    sessions {
      sessionId
      name
      description
      startTime
      endTime
      activityLocation {
        placesAddress {
          placesId
          description
          formatted
          latLong {
            lat
            long
          }
        }
      }
    }
    activityTags {
      activityTagId
      name
      description
    }
    activityLocation {
      activityLocationId
      placesAddress {
        placesId
        description
        formatted
        latLong {
          lat
          long
        }
      }
    }
    activityRemoteLocation {
      activityRemoteLocationId
      details
    }
    publishedRosters {
      activityDate
      rosterings {
        publishedRosteringId
        volunteer {
          volunteerId
        }
        dateRemoved
      }
    }
    maxVolunteers
    cancelledActivities(startDate: $startDate, endDate: $endDate) {
      activityDate
    }
    nextX(x: 1)
    occurrencesBetween(from: $startDate, until: $endDate)
    ... on VOLUNTEER_RecurringActivityType {
      schedule {
        recurrences {
          ...Recurrence
        }
      }
    }
    rosterTemplates {
      rosterDate
      placesAddress {
        formatted
      }
    }
  }
  ${RECURRENCE_FRAGMENT}
`;
