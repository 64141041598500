import { Box, Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useQueryParam } from 'use-query-params';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { useFilterStyles } from './FilterClasses';
import { FilterDataType, Filters, StandardTypeFilterType } from './filters';

function getThisFilter(selectedFilters: Filters, urlParamKey: string): undefined | StandardTypeFilterType {
  if (urlParamKey === 'status') return selectedFilters.selectedStatusFilter;
  if (urlParamKey === 'duration') return selectedFilters.selectedDurationFilter;
  if (urlParamKey === 'type') return selectedFilters.selectedActivityTypeFilter;
  if (urlParamKey === 'roster') return selectedFilters.selectedRosterTypeFilter;
  if (urlParamKey === 'location') return selectedFilters.selectedLocationFilter;

  return undefined;
}

type StandardFilterRadioSelectionProps = {
  selectedFilters: Filters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  filterData: FilterDataType;
  isManagementView: boolean;
};

const StandardFilterRadioSelection = (props: StandardFilterRadioSelectionProps) => {
  const { selectedFilters, filterData } = props;

  const thisFilter = getThisFilter(selectedFilters, filterData.urlParamKey);

  if (!thisFilter) return null;

  return <StandardFilterRadioSelectionInner {...props} thisFilter={thisFilter} />;
};

type StandardFilterRadioSelectionInnerProps = {
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  filterData: FilterDataType;
  isManagementView: boolean;
  thisFilter: StandardTypeFilterType;
};

const StandardFilterRadioSelectionInner = (props: StandardFilterRadioSelectionInnerProps) => {
  const { setSelectedFilters, filterData, isManagementView, thisFilter } = props;
  const { theme } = useCampfireTheme();
  const classes = useFilterStyles(theme);

  const { filterName, options, defaultOption, helperText, urlParamKey } = filterData;
  const [urlParam, setUrlParam] = useQueryParam<string>(urlParamKey);

  const isFilterSelected = (value: string) => thisFilter.value === value;

  const handleFilterChange = (value: string) => {
    const isDefault = value === options[defaultOption].value;
    setSelectedFilters((prev) => ({
      ...prev,
      [`selected${filterName}Filter`]: {
        ...thisFilter,
        value: value,
        label: filterData.options.find((x) => x.value === value)?.label ?? '',
        urlParamKey,
        isActive: !isDefault,
      },
    }));
  };

  useEffect(() => {
    if (!urlParam) {
      handleFilterChange(options[defaultOption].value);
      return;
    }
    handleFilterChange(urlParam);
  }, [urlParam]);

  return (
    <Box py={2}>
      <Typography color='textSecondary' variant='body1' gutterBottom>
        {isManagementView ? helperText.manager : helperText.volunteer}
      </Typography>
      <List disablePadding>
        {options.map((filter: any) => (
          <ListItem
            data-track={`actCnlMapFilters-DurationFilter-${filterName}`}
            key={filter.value}
            onClick={() => setUrlParam(filter.value)}
            disableGutters
            className={classes.listItem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                checked={isFilterSelected(filter.value)}
                className={classes.checkBox}
                checkedIcon={<RadioButtonChecked color='primary' />}
                size='small'
                icon={<RadioButtonUnchecked />}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant='body1'
                color='textSecondary'
                style={isFilterSelected(filter.value) ? { fontWeight: 600 } : { fontWeight: 'normal' }}
              >
                {filter.label}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export { StandardFilterRadioSelection };
