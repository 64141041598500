import React from 'react';
import { Badge, Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';

export function MaxWarning() {
  return (
    <Badge
      style={{
        backgroundColor: '#efb31a',
        color: '#ffffff',
        padding: '6px',
        borderRadius: '3px',
        height: 'fit-content',
        marginTop: '7px',
      }}
    >
      <WarningRounded style={{ fontSize: '16px' }} />
      <Typography style={{ fontSize: '12px', paddingLeft: '4px', fontWeight: 500, paddingRight: '2px' }}>
        Max Reached
      </Typography>
    </Badge>
  );
}
