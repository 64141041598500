import { unpackToDate } from '@campfire/hot-date';
import { Box, Grid, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';
import { Avatar } from '../../../../general/user-profile/components/Avatar';
import { ActivityApplicationReview } from '../__generated__/ActivityApplicationReview';

interface ActivityApplicationCommonProfileBlockProps {
  activityApplication: ActivityApplicationReview;
  view: 'application' | 'roster';
}

export const ActivityApplicationCommonProfileBlock = (props: ActivityApplicationCommonProfileBlockProps) => {
  const { activityApplication, view } = props;
  return (
    <Box marginX={4} marginY={3}>
      <Grid container direction='row' justify='space-between'>
        <Grid item>
          <Box display='flex' alignContent='center' alignItems='center'>
            <Avatar
              size={48}
              avatarUrl={activityApplication.volunteer.profile.avatarUrl}
              preferredName={activityApplication.volunteer.profile.preferredName}
              lastName={activityApplication.volunteer.profile.lastName}
            />
            <Box paddingLeft={2}>
              <Typography variant='subtitle2'>{`${activityApplication.volunteer.profile.preferredName} ${activityApplication.volunteer.profile.lastName}`}</Typography>
              <Typography variant='caption' color='textSecondary'>
                {activityApplication.volunteer.profile.email}
              </Typography>
              <br />
              <Typography variant='caption' color='textSecondary'>
                {view === 'application'
                  ? activityApplication.volunteer.lastAttendance
                    ? `Last volunteered on ${unpackToDate(
                        activityApplication.volunteer.lastAttendance.sessionReport.activityReport.activityDate
                      ).toFormat('d MMM')} at ${
                        activityApplication.volunteer.lastAttendance.sessionReport.activityReport.activity.name
                      }`
                    : 'Never volunteered'
                  : `Joined on ${DateTime.local().toFormat('d MMM')}`}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
