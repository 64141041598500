import { getDurationHoursMin } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { ButtonBase, Collapse, Grid, ListItem, Typography } from '@material-ui/core';
import { BorderColorOutlined } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { ExpandableSection } from '../../../../../common/cards/ExpandableSection';
import { ActivityStatusChip } from '../../../../../common/chips/ActivityStatusChip';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';
import { getDisplayTimeSchedule } from '../../../../../common/functions/activity-display-helpers';
import { arrayHead } from '../../../../../common/functions/array-head';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { PROGRAM_MANAGEMENT_GET_PROGRAM_ACTIVITIES } from '../../program-management.gql';
import { useProgramManagementContext } from '../../ProgramManagementContext';
import {
  ProgramManagementGetProgramActivities,
  ProgramManagementGetProgramActivitiesVariables,
} from '../../__generated__/ProgramManagementGetProgramActivities';
import { ProgramManagementSingleProgramBasicInfo } from '../../__generated__/ProgramManagementSingleProgramBasicInfo';
import { AddActivityDialog } from '../dialogs/AddActivityDialog';
import { FormSectionSkeleton } from './FormSectionSkeleton';
import { LockedSectionContent } from './LockedSectionContent';

const ProgramActivitiesFormSectionDescription = () => {
  const { compact } = useProgramManagementContext();

  return (
    <>
      <Typography variant='body2' color='textSecondary'>
        List of activities that belong to this program
      </Typography>
      {compact ? (
        <Typography variant='body2' color='textSecondary' style={{ fontWeight: 'bold' }}>
          Click any activity to view it in the activity console
        </Typography>
      ) : null}
    </>
  );
};
export const ProgramActivitiesSectionLocked = () => {
  return (
    <FormSectionV2 title='Activities' description={<ProgramActivitiesFormSectionDescription />}>
      <LockedSectionContent text='Please save Basic Info before adding activities' />
    </FormSectionV2>
  );
};

export const ProgramActivitiesSection = ({
  programBasicInfo,
}: {
  programBasicInfo: ProgramManagementSingleProgramBasicInfo | undefined;
}) => {
  const { theme } = useCampfireTheme();
  const [showAddActivityDialog, setShowAddActivityDialog] = useState(false);
  const [expandActivitiesSection, setExpandActivitiesSection] = useState(false);
  const [selectedProgramId] = useQueryParam('pid', StringParam);
  const history = useHistory();

  const ACTIVITIES_CONSOLE_SLUG = '/management/activity-management';

  const {
    data: getProgramActivitiesData,
    loading: getProgramActivitiesLoading,
    refetch: refetchProgramActivitiesData,
  } = useCampfireQuery<ProgramManagementGetProgramActivities, ProgramManagementGetProgramActivitiesVariables>(
    PROGRAM_MANAGEMENT_GET_PROGRAM_ACTIVITIES,
    {
      options: {
        variables: {
          programId: selectedProgramId,
        },
      },
    }
  );

  const program = getProgramActivitiesData?.vm.program || undefined;

  const activities = useMemo(() => {
    if (!program || !program.activities) return [];
    const alphaSortedActivities = program.activities.sort((a, b) => a.name.localeCompare(b.name));
    // const closedActivities = alphaSortedActivities.filter((x) => !!x.closedActivity);
    // const suspendedActivities = alphaSortedActivities.filter((x) => !x.closedActivity && x.isSuspended);
    const leftoverActivities = alphaSortedActivities.filter((x) => !x.closedActivity && !x.isSuspended);
    return [...leftoverActivities];
  }, [program]);

  if (getProgramActivitiesLoading)
    return <FormSectionSkeleton title='Activities' description={<ProgramActivitiesFormSectionDescription />} />;

  if (activities.length === 0) {
    return (
      <FormSectionV2 title='Activities' description={<ProgramActivitiesFormSectionDescription />}>
        <>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item>
              <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>
                0 activities
              </Typography>
            </Grid>
            <Grid item>
              <TabletButton
                variant='contained'
                style={{
                  backgroundColor: theme.color.secondary.main900,
                  color: '#ffffff',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                  textTransform: 'none',
                  padding: '7px 12px',
                  minWidth: '40px',
                }}
                onClick={() => setShowAddActivityDialog(true)}
              >
                <BorderColorOutlined style={{ marginTop: '1px', fontSize: '16px' }} />
                <Typography
                  style={{
                    marginLeft: '8px',
                    fontWeight: 600,
                    fontSize: '13px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Create Activity
                </Typography>
              </TabletButton>
            </Grid>
          </Grid>
          <AddActivityDialog
            open={showAddActivityDialog}
            close={() => setShowAddActivityDialog(false)}
            selectedProgram={programBasicInfo}
            refetch={refetchProgramActivitiesData}
          />
        </>
      </FormSectionV2>
    );
  }
  return (
    <FormSectionV2 title='Activities' description={<ProgramActivitiesFormSectionDescription />}>
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12} container justify='space-between' alignItems='center'>
          <Grid item style={{ paddingTop: '8px' }}>
            <Typography variant='subtitle2' style={{ fontWeight: 'bold', paddingBottom: '4px' }}>{`${
              activities.length
            } ${activities.length === 1 ? 'activity' : 'activities'}`}</Typography>
            {expandActivitiesSection ? (
              <HoverText variant='body2' color='primary' onClick={() => setExpandActivitiesSection(false)}>
                Hide all activities
              </HoverText>
            ) : (
              <HoverText variant='body2' color='primary' onClick={() => setExpandActivitiesSection(true)}>
                Show all activities
              </HoverText>
            )}
          </Grid>
          <Grid item>
            <TabletButton
              variant='contained'
              style={{
                backgroundColor: theme.color.secondary.main900,
                color: '#ffffff',
                boxSizing: 'border-box',
                borderRadius: '4px',
                textTransform: 'none',
                padding: '7px 12px',
                minWidth: '40px',
              }}
              onClick={() => setShowAddActivityDialog(true)}
            >
              <BorderColorOutlined style={{ marginTop: '1px', fontSize: '16px' }} />
              <Typography
                style={{
                  marginLeft: '8px',
                  fontWeight: 600,
                  fontSize: '13px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                Create Activity
              </Typography>
            </TabletButton>
          </Grid>
        </Grid>
        <Collapse in={expandActivitiesSection}>
          <Grid item>
            <ExpandableSection expandOnly openText='Show all activities'>
              {activities.map((activity, idx) => (
                <ButtonBase
                  key={activity.activityId}
                  onClick={() => history.push(`${ACTIVITIES_CONSOLE_SLUG}?activityId=${activity.activityId}`)}
                  style={{ width: '100%' }}
                >
                  <TitularTooltip enterDelay={400} placement='left' description={`Open in Activty Console`}>
                    <ListItem
                      style={{
                        borderBottom: activities.length - 1 !== idx ? `1px solid ${theme.color.grey.border}` : 'none',
                      }}
                    >
                      <Grid container direction='column'>
                        <Grid item container alignItems='center' spacing={2}>
                          <Grid item>
                            <Typography variant='subtitle2'>{activity.name}</Typography>
                          </Grid>
                          {activity.isSuspended || activity.closedActivity ? (
                            <Grid item>
                              <ActivityStatusChip
                                status={
                                  activity.closedActivity ? 'CLOSED' : activity.isSuspended ? 'SUSPENDED' : undefined
                                }
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                        {activity.__typename === 'VOLUNTEER_RecurringActivityType' ? (
                          <Typography variant='caption' color='textSecondary'>
                            {arrayHead(activity.schedule.recurrences)?.humanReadable}
                          </Typography>
                        ) : null}
                        <Typography variant='caption' color='textSecondary'>
                          <span>{`${getDisplayTimeSchedule(activity.startTime, activity.endTime)} `}</span>
                          {activity.startTime && activity.endTime ? (
                            <span>{`(${getDurationHoursMin(
                              DateTime.fromISO(activity.startTime),
                              DateTime.fromISO(activity.endTime)
                            )})`}</span>
                          ) : null}
                        </Typography>
                      </Grid>
                    </ListItem>
                  </TitularTooltip>
                </ButtonBase>
              ))}
            </ExpandableSection>
          </Grid>
        </Collapse>
      </Grid>
      <AddActivityDialog
        open={showAddActivityDialog}
        close={() => setShowAddActivityDialog(false)}
        selectedProgram={programBasicInfo}
        refetch={refetchProgramActivitiesData}
      />
    </FormSectionV2>
  );
};
