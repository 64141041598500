import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useQueryParam } from 'use-query-params';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { SaveBulletinManagement } from './SaveBulletinManagement';
import { BulletinManagementList } from './BulletinManagementList';
import { GET_BULLETIN_MANAGEMENT_DATA } from './admin-console-bulletins-model.gql';
import {
  GetBulletinManagementData,
  GetBulletinManagementDataVariables,
} from './__generated__/GetBulletinManagementData';
import { BulletinManagement as IBulletin } from './__generated__/BulletinManagement';

import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { useApiUrl } from '../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../global/network/useCampfireFetch';
import { scrollIntoView } from '../../../common/functions/scroll-functions';
import { useSnackbar } from '../../../global/config/useSnackbar';

export interface valuesToSubmit {
  bulletinId?: string;
  title: string;
  content: string;
  pinned: boolean;
  notifyVolunteers?: string | boolean;
  programIds: string[];
  activityIds: string[];
  roleIds: string[];
  permissionLevel?: string | null;
}

interface DeleteBulletin {
  bulletinId: string;
}

const BulletinManagementScreen = () => {
  const [screen, setScreen] = useState<'home' | 'addEdit'>('home');
  const [activeBulletin, setActiveBulletin] = useState<IBulletin | null>(null);
  const [isPinningId, setIsPinningId] = useState<string>('');
  const [bulletinQueryId, setBulletinQueryId] = useQueryParam('id');
  const apiUrl = useApiUrl();
  const { setSnackbar } = useSnackbar();

  // Get Raw Bulletin Data
  const { data: bulletinQuery, loading: bulletinsLoading, refetch } = useCampfireQuery<
    GetBulletinManagementData,
    GetBulletinManagementDataVariables
  >(GET_BULLETIN_MANAGEMENT_DATA, {
    options: {
      variables: {
        programIds: [],
      },
    },
  });

  // Get Bulletins from Bulletin Data
  const bulletins = bulletinQuery?.vm.bulletins ?? [];
  // const bulletins = useMemo(() => bulletinQuery?.vm.bulletins ?? [], [bulletinQuery]);

  // Get bulletin from query param
  const activeBulletinFromQuery = useMemo(() => {
    return bulletins.find((bulletin) => bulletin.bulletinId === bulletinQueryId);
  }, [bulletins, bulletinQueryId]);

  // If there's a query param make that bulletin the active bulletin.
  useEffect(() => {
    if (bulletinQueryId && activeBulletinFromQuery) {
      setActiveBulletin(activeBulletinFromQuery);
    }
  }, [bulletins, bulletinQueryId]);

  // Whenever there is an active bulletin go to the addEdit screen
  useEffect(() => {
    setScreen(activeBulletin === null ? 'home' : 'addEdit');
  }, [activeBulletin]);

  const goToAddBulletinScreen = () => setScreen('addEdit');
  const cancelAddEditBulletin = () => {
    setActiveBulletin(null);
    setBulletinQueryId(undefined);
    setScreen('home');
  };

  const { isLoading, run } = useCampfireFetch({ defer: true });

  const hitBulletinEndpoint = (endpoint: string, data: DeleteBulletin | valuesToSubmit): Promise<any> => {
    return run({
      url: `${apiUrl}/vm/bulletin/${endpoint}`,
      method: 'post',
      data: data,
    })
      .then(() => {
        cancelAddEditBulletin();
        if (refetch) {
          refetch();
        }
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: `Error ${endpoint === 'delete' ? 'deleting' : 'adding/editing'} bulletin`,
          variant: 'error',
        });
      });
  };

  const deleteBulletin = (bulletinId: string): Promise<any> => {
    return hitBulletinEndpoint('delete', { bulletinId: bulletinId });
  };

  const submitBulletin = (bulletin: valuesToSubmit): Promise<any> => {
    return hitBulletinEndpoint('save', { ...bulletin });
  };

  const setActiveBulletinFn = (bulletin: IBulletin) => {
    setActiveBulletin(bulletin);
    setBulletinQueryId(bulletin.bulletinId);
  };

  const togglePinned = (bulletin: IBulletin): Promise<any> => {
    setIsPinningId(bulletin.bulletinId || '');
    const dataToSubmit = {
      ...bulletin,
      notifyVolunteers: false,
      pinned: !bulletin.pinned,
      programIds: bulletin.programs.map((program) => program.programId),
      roleIds: bulletin.activityRoles.map((role) => role.activityRoleId),
      activityIds: bulletin.activities.map((activity) => activity.activityId),
      permissionLevel: bulletin.permissionLevel,
      contentHtml: '',
    };
    return submitBulletin(dataToSubmit).then(() => {
      if (!bulletin.pinned && topOfPage && topOfPage.current) {
        scrollIntoView(topOfPage, { behavior: 'smooth' }, true);
      }
      setIsPinningId('');
    });
  };

  const topOfPage = useRef<HTMLDivElement>(null);

  return (
    <div ref={topOfPage} style={{ position: 'relative' }}>
      <LinearProgressOverlay isLoading={bulletinsLoading} />
      {screen === 'home' && (
        <BulletinManagementList
          bulletins={bulletins}
          addBulletin={goToAddBulletinScreen}
          togglePinned={togglePinned}
          setActiveBulletin={setActiveBulletinFn}
          isPinningId={isPinningId}
        />
      )}
      {screen === 'addEdit' && (
        <SaveBulletinManagement
          cancel={cancelAddEditBulletin}
          submitBulletin={submitBulletin}
          deleteBulletin={deleteBulletin}
          activeBulletin={activeBulletin}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export { BulletinManagementScreen };
