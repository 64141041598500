import { Box } from '@material-ui/core';
import React from 'react';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { encodeDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { RostersList } from './sidebar/RostersList';
import { RosterMainSection } from './main-section/RosterMainSection';
import { RosterListItem } from './main-section/roster/roster-types';
import { GetRostersSidebarData_vm_activities_VOLUNTEER_NonRecurringActivityType_rosterTemplates as RosterTemplate } from './__generated__/GetRostersSidebarData';
import { RosterFilters } from './main-section/filters/RosterFilters';
import { RosterListHeader } from './mobile-roster/RosterListHeader';
import { SessionContext } from '../../../global/auth/SessionContext';
import { NextPrevButton } from './sidebar/RostersSideBar';

interface Props {
  selectedRoster?: RosterListItem;
  loading: boolean;
  rosterTemplate?: RosterTemplate;
  rosters: RosterListItem[];
  handleEditRosterTemplate: () => void;
  cancelActivity: () => void;
  setSearchFilter: (x: string) => void;
  handleReactivateActivityRefetch?: () => void;
  query: any;
  onSetQuery: (arg: any) => void;
  isRosterCompleted: boolean;
  onHistoryClick: () => void;
  showFilters: boolean;
  setShowFilters: () => void;
  loadNext: () => void;
  loadPrev: () => void;
}
export const MobileIndividualRosterScreen = (props: Props) => {
  const {
    loading,
    handleEditRosterTemplate,
    rosters,
    selectedRoster,
    rosterTemplate,
    cancelActivity,
    setSearchFilter,
    handleReactivateActivityRefetch,
    query,
    onSetQuery,
    isRosterCompleted,
    onHistoryClick,
    showFilters,
    setShowFilters,
    loadPrev,
    loadNext,
  } = props;
  const session = React.useContext(SessionContext);
  const isLargeOrg = session?.user?.orgInfo.isLargeOrg;
  const isInitialLoad = !query.startDate || query.startDate === encodeDate(DateTime.local());

  return selectedRoster ? (
    <Box width='100%' display='flex' flexGrow={1} flexBasis='20px' overflow='hidden'>
      <LinearProgressOverlay isLoading={loading} />
      <RosterMainSection
        loading={loading}
        showFilters={false}
        rosterTemplate={rosterTemplate}
        handleEditRosterTemplate={handleEditRosterTemplate}
        cancelActivity={cancelActivity}
        hasRosters={rosters.length >= 1}
        handleReactivateActivityRefetch={handleReactivateActivityRefetch}
        query={query}
        onSetQuery={onSetQuery}
        isRosterCompleted={isRosterCompleted}
      />
    </Box>
  ) : (
    <Box px={3}>
      <LinearProgressOverlay isLoading={loading} />
      <RosterListHeader
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        handleEditRosterTemplate={handleEditRosterTemplate}
        setSearchFilter={setSearchFilter}
        onHistory={query.onHistory}
        onHistoryClick={onHistoryClick}
        searchFilter={query.q}
      />
      <RosterFilters showFilters={showFilters} query={query} onSetQuery={onSetQuery} />

      {!query.onHistory && !isInitialLoad && (
        <NextPrevButton onAction={loadPrev} variant='prev' isLargeOrg={isLargeOrg} />
      )}
      <RostersList rosters={rosters} />
      {!query.onHistory && <NextPrevButton onAction={loadNext} variant='next' isLargeOrg={isLargeOrg} />}
    </Box>
  );
};
