import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

const ApplicationScreenSkeleton = () => {
  const { isMobile } = useCampfireTheme();

  return (
    <Box marginTop={isMobile ? 3 : 7} marginBottom={8} marginLeft={isMobile ? 3 : 7} marginRight={isMobile ? 3 : 7}>
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12} sm={10}>
          <Skeleton variant='text' width={200} height={20} />
        </Grid>
        <Grid item>
          <Skeleton variant='text' width={300} height={40} />
        </Grid>
        <Grid item>
          <Skeleton variant='text' width={300} height={40} />
        </Grid>
        <Grid item>
          <Skeleton variant='text' width={300} height={40} />
        </Grid>
        <Grid item>
          <Skeleton variant='text' width={200} height={20} />
        </Grid>
      </Grid>
    </Box>
  );
};

export { ApplicationScreenSkeleton };
