import React from 'react';
import { Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

import { RolePanel } from './RolePanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 900,
      fontSize: '1.25rem',
      color: theme.color.grey.neutralBrand900,
    },
    body: {
      fontWeight: 400,
      color: theme.color.grey.neutral200,
      fontSize: '0.875rem',
      marginTop: '6px',
    },
    button: {
      marginTop: '1.25rem',
    },
  })
);

export function EmptyRoles() {
  const classes = useStyles();
  return (
    <RolePanel>
      <Typography className={classes.heading} variant='h4'>
        Create Your First Role
      </Typography>
      <Typography className={classes.body}>
        You haven’t created any roles yet. Click the add role button top right to add your first.
      </Typography>
    </RolePanel>
  );
}
