import React from 'react';
import { ActivityTimelineItem } from './ActivityTimelineItem';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { MyActivityStatusIndicator } from '../../../vol-dashboard/vol-dashboard-my-elements/main-section/my-activities/MyActivityStatusIndicator';
import { ActivityTimelineItem as ActivityTimelineItemType, ActionType } from '../../ActivityTypes';
import { getActivityActions } from '../../helpers';

interface Props {
  activities: ActivityTimelineItemType[];
  onAction: (action: ActionType, activity: ActivityTimelineItemType) => void;
  onSelect: (activityId: string, activityDate: string) => void;
  selectedActivityId?: string;
}

export function AllActivitiesTimeline({ activities, onAction, onSelect, selectedActivityId }: Props) {
  const { theme } = useCampfireTheme();
  return (
    <React.Fragment>
      {activities.map((activity: ActivityTimelineItemType) => (
        <ActivityTimelineItem
          activity={activity}
          ActivityMenu={
            <MyActivityStatusIndicator
              actions={getActivityActions(activity.status, activity.isRestrictedActivity)}
              onAction={(action) => onAction(action, activity)}
              status={activity.status}
            />
          }
          isSelected={selectedActivityId === activity.activityId}
          onSelect={() => onSelect(activity.activityId, activity.activityDate)}
          color={theme.color.rosters.status[activity.status]}
        />
      ))}
    </React.Fragment>
  );
}
