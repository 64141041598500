import { gql } from '@apollo/client';

const AverageNumberConfigurationFragment = gql`
  fragment AverageNumberConfiguration on VOLUNTEER_AverageNumberConfigurationType {
    range
    interval
  }
`;

const TotalNumberConfigurationFragment = gql`
  fragment TotalNumberConfiguration on VOLUNTEER_TotalNumberConfigurationType {
    range
  }
`;

const TrackingNumberConfigurationFragment = gql`
  fragment TrackingNumberConfiguration on VOLUNTEER_TrackingNumberConfigurationType {
    interval
  }
`;

const TargetNumberConfigurationFragment = gql`
  fragment TargetNumberConfiguration on VOLUNTEER_TargetNumberConfigurationType {
    range
    target
  }
`;

const CustomMultiplationNumberConfigurationFragment = gql`
  fragment CustomMultiplationNumberConfiguration on VOLUNTEER_CustomMultiplationNumberConfigurationType {
    range
    multiplier
  }
`;

const ChartConfigurationFragment = gql`
  fragment ChartConfiguration on VOLUNTEER_ChartConfigurationType {
    dimension
    range
    interval
    chartType
    group
  }
`;

const ChartWidgetFragment = gql`
  fragment ChartWidget on VOLUNTEER_ChartWidgetType {
    widgetId
    targets
    filters
    name
    configuration {
      ...ChartConfiguration
    }
  }
  ${ChartConfigurationFragment}
`;

const TableWidgetFragment = gql`
  fragment TableWidget on VOLUNTEER_TableWidgetType {
    targets
    filters
    widgetId
    range
    chartOrder
    name
  }
`;

const RequiringActionWidgetFragment = gql`
  fragment RequiringActionWidget on VOLUNTEER_RequiringActionWidgetType {
    targets
    filters
    widgetId
    range
    name
  }
`;

const ChartNumberWidgetFragment = gql`
  fragment ChartNumberWidget on VOLUNTEER_ChartNumberWidgetType {
    widgetId
    targets
    filters
    name
    chartConfiguration {
      ...ChartConfiguration
    }

    numberConfiguration {
      ...AverageNumberConfiguration
      ...TotalNumberConfiguration
      ...TrackingNumberConfiguration
      ...TargetNumberConfiguration
      ...CustomMultiplationNumberConfiguration
    }
  }
  ${AverageNumberConfigurationFragment}
  ${TotalNumberConfigurationFragment}
  ${TargetNumberConfigurationFragment}
  ${TrackingNumberConfigurationFragment}
  ${CustomMultiplationNumberConfigurationFragment}
  ${ChartConfigurationFragment}
`;

const NumberWidgetFragment = gql`
  fragment NumberWidget on VOLUNTEER_NumberWidgetType {
    widgetId
    targets
    filters
    name
    configuration {
      ...AverageNumberConfiguration
      ...TotalNumberConfiguration
      ...TrackingNumberConfiguration
      ...TargetNumberConfiguration
      ...CustomMultiplationNumberConfiguration
    }
  }
  ${AverageNumberConfigurationFragment}
  ${TotalNumberConfigurationFragment}
  ${TargetNumberConfigurationFragment}
  ${TrackingNumberConfigurationFragment}
  ${CustomMultiplationNumberConfigurationFragment}
`;

export const DASHBOARD_PREFERENCE_MODEL = gql`
  query GetDashboardPreference($userId: String) {
    vm {
      profile(userId: $userId) {
        dashboardPreference {
          dashboardPreferenceId
          dashboardComponents {
            dashboardComponentId
            position {
              row
              col
              rowSpan
              colSpan
            }
            widget {
              ...ChartWidget
              ...TableWidget
              ...RequiringActionWidget
              ...ChartNumberWidget
              ...NumberWidget
            }
          }
        }
      }
    }
  }
  ${ChartWidgetFragment}
  ${NumberWidgetFragment}
  ${ChartNumberWidgetFragment}
  ${RequiringActionWidgetFragment}
  ${TableWidgetFragment}
`;
