import { assertNever } from '../../../../../common/functions/assert-never';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../../../global/network/useEndpointFetch';
import { ReportTypeFormValues } from '../../common/report-type-form-sections/report-type-form-types';

interface SaveReportTypeBooleanField {
  __typename: 'VOLUNTEER_BooleanFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportTypeCheckboxField {
  __typename: 'VOLUNTEER_CheckboxFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportTypeDropdownField {
  __typename: 'VOLUNTEER_DropdownFieldType';
  fieldId?: string;
  name: string;
  description: string;
  dropdownFieldOptions: {
    dropdownFieldOptionId?: string;
    name: string;
    order: number;
  }[];
  allowMultiple: boolean;
  hero: boolean;
}

interface SaveReportTypeNumericField {
  __typename: 'VOLUNTEER_NumericFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportTypeFormField {
  __typename: 'VOLUNTEER_RatingFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportTypeTextField {
  __typename: 'VOLUNTEER_TextFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
  isLongText: boolean;
}

interface SaveReportTypeTimeField {
  __typename: 'VOLUNTEER_TimeFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

interface SaveReportTypeAttachmentField {
  __typename: 'VOLUNTEER_AttachmentFieldType';
  fieldId?: string;
  name: string;
  description: string;
  hero: boolean;
}

type SaveReportTypeField =
  | SaveReportTypeBooleanField
  | SaveReportTypeCheckboxField
  | SaveReportTypeDropdownField
  | SaveReportTypeNumericField
  | SaveReportTypeFormField
  | SaveReportTypeTextField
  | SaveReportTypeTimeField
  | SaveReportTypeAttachmentField;

interface SaveReportTypeItemHeading {
  __typename: 'VOLUNTEER_ReportTypeHeadingType';
  reportTypeItemId?: string;
  order: number;
  heading: string;
}

export interface SaveReportTypeItemField {
  __typename: 'VOLUNTEER_ReportTypeFieldType';
  reportTypeItemId?: string;
  order: number;
  field: SaveReportTypeField;
  optional: boolean;
}

export type SaveReportTypeItem = SaveReportTypeItemHeading | SaveReportTypeItemField;

export interface SaveReportType {
  reportTypeId?: string;
  name: string;
  description: string | null;
  items: SaveReportTypeItem[];
  active: boolean;
}

export const useSaveReportTypeFetch = () => {
  return useEndpointFetch<SaveReportType, { reportTypeId: string }>('/vm/report-type/save');
};

export const useReportTypeActions = () => {
  const saveReportType = useSaveReportTypeFetch();
  const { setSnackbar } = useSnackbar();

  const runSaveReportType = ({
    values,
    handleSuccess,
  }: {
    values: ReportTypeFormValues;
    handleSuccess?: (x: string) => void;
  }) => {
    saveReportType
      .run({
        reportTypeId: values.reportTypeId || undefined,
        name: values.reportTypeName,
        description: values.reportTypeDescription ?? null,
        active: values.active,
        items: values.items.map((item, order) => {
          if (item.__typename === 'VOLUNTEER_ReportTypeHeadingType') {
            return { ...item, reportTypeItemId: item.reportTypeItemId || undefined, order };
          }

          if (
            item.field.__typename === 'VOLUNTEER_BooleanFieldType' ||
            item.field.__typename === 'VOLUNTEER_CheckboxFieldType' ||
            item.field.__typename === 'VOLUNTEER_RatingFieldType' ||
            item.field.__typename === 'VOLUNTEER_TimeFieldType' ||
            item.field.__typename === 'VOLUNTEER_AttachmentFieldType'
          ) {
            return {
              ...item,
              reportTypeItemId: item.reportTypeItemId || undefined,
              field: { ...item.field, fieldId: item.field.fieldId || undefined, hero: item.field.isHero },
              order,
            };
          }

          if (item.field.__typename === 'VOLUNTEER_DropdownFieldType') {
            return {
              ...item,
              reportTypeItemId: item.reportTypeItemId || undefined,
              field: {
                ...item.field,
                fieldId: item.field.fieldId || undefined,
                dropdownFieldOptions: item.field.dropdownOptions.map((option, optionOrder) => {
                  return {
                    ...option,
                    order: optionOrder,
                  };
                }),
                allowMultiple: item.field.isAllowMultiple,
                hero: item.field.isHero,
              },
              order,
            };
          }

          if (item.field.__typename === 'VOLUNTEER_NumericFieldType') {
            return {
              ...item,
              reportTypeItemId: item.reportTypeItemId || undefined,
              field: { ...item.field, fieldId: item.field.fieldId || undefined, hero: item.field.isHero },
              order,
            };
          }

          if (item.field.__typename === 'VOLUNTEER_TextFieldType') {
            return {
              ...item,
              reportTypeItemId: item.reportTypeItemId || undefined,
              field: { ...item.field, fieldId: item.field.fieldId || undefined, hero: item.field.isHero },
              order,
              isLongText: item.field.isLongText,
            };
          }

          return assertNever(item.field);
        }),
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save report type',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Report type saved',
          variant: 'success',
        });

        if (handleSuccess) {
          handleSuccess(res.data.data.reportTypeId);
        }
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save report type',
          variant: 'error',
        })
      );
  };

  return {
    runSaveReportType,
    runSaveReportTypeLoading: saveReportType.isLoading,
  };
};
