import { Typography } from '@material-ui/core';
import React, { memo, useMemo } from 'react';
import { getDisplayTimeSchedule } from '../../../../../common/functions/activity-display-helpers';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { PastSidebarItem } from './ActivityTimelineSidebar';
import {
  ActivityTimelineSidebarItemBaseProps,
  ActivityTimelineSidebarItemButtonBase,
} from './sidebar-items/ActivityTimelineSidebarItemButtonBase';
import { ActivityTimelineSidebarItemStatusLabel } from './sidebar-items/ActivityTimelineSidebarItemStatusLabel';

type Props = {
  pastSidebarItem: PastSidebarItem;
} & ActivityTimelineSidebarItemBaseProps;

const ActivityTimelinePastSidebarItem = memo((props: Props) => {
  const { theme } = useCampfireTheme();
  const { pastSidebarItem, setSelectedActivityId, setSelectedDate, selected, listItemRef, onSidebarItemClick } = props;
  const timeScheduleFormatted = useMemo(() => {
    return getDisplayTimeSchedule(pastSidebarItem.startTime, pastSidebarItem.endTime);
  }, [pastSidebarItem]);
  const isRosterPublished = useMemo(() => {
    return !!pastSidebarItem.draftRoster?.publishedRoster;
  }, [pastSidebarItem]);

  const reportStatusMessage = useMemo(() => {
    return pastSidebarItem.pastStatus === 'cancelled'
      ? 'Activity cancelled'
      : pastSidebarItem.pastStatus === 'complete'
      ? 'Report complete'
      : pastSidebarItem.pastStatus === 'incomplete'
      ? 'Missing session report(s)'
      : 'Report not started';
  }, [pastSidebarItem.pastStatus]);

  const reportStatusColor = useMemo(() => {
    return pastSidebarItem.pastStatus === 'cancelled'
      ? '#323232'
      : pastSidebarItem.pastStatus === 'complete'
      ? theme.status.green.light
      : pastSidebarItem.pastStatus === 'incomplete'
      ? theme.status.amber.light
      : theme.status.red.light;
  }, [pastSidebarItem.pastStatus]);

  return (
    <li ref={listItemRef}>
      <ActivityTimelineSidebarItemButtonBase
        activityId={pastSidebarItem.activityId}
        selected={selected}
        handleClick={() => {
          setSelectedActivityId(pastSidebarItem.activityId);
          setSelectedDate(pastSidebarItem.activityDate);
          if (onSidebarItemClick) {
            onSidebarItemClick();
          }
        }}
      >
        <Typography>{pastSidebarItem.activityName}</Typography>
        <Typography variant='body2' display='block'>
          {`${pastSidebarItem.programName}`}
        </Typography>
        <Typography variant='body2' display='block'>
          {timeScheduleFormatted}
        </Typography>
        <Typography variant='caption' display='block'>
          {`${pastSidebarItem.humanReadableRecurrence}`}
        </Typography>
        {isRosterPublished ? (
          <Typography variant='caption' display='block'>
            {'Roster published'}
          </Typography>
        ) : null}
        <ActivityTimelineSidebarItemStatusLabel color={reportStatusColor} message={reportStatusMessage} />
      </ActivityTimelineSidebarItemButtonBase>
    </li>
  );
});

export { ActivityTimelinePastSidebarItem };
