export type IncomingVolunteersFilters = {
  selectedTypeFilter: string;
  selectedStatusFilter: INCOMING_VOLUNTEER_STATUS_FILTER_ENUM;
  selectedSortFilter: string;
  selectedProgramFilter: string;
};
export const DEFAULT_TYPE_FILTER = 'Show Any';
export type TypeFilterOption = 'Show Invitees' | 'Show Applicants' | 'Show Any';
export enum INCOMING_VOLUNTEER_STATUS_FILTER_ENUM {
  PENDING_REVIEW = 'Show Pending Review',
  ALL = 'Show All',
  ACTIVE = 'Show Active',
  INACTIVE = 'Show Removed'
};
export type StatusFilterOption = 'Show Pending Review' | 'Show All' | 'Show Active' | 'Show Removed';
export const DEFAULT_STATUS_FILTER = INCOMING_VOLUNTEER_STATUS_FILTER_ENUM.ACTIVE;
export const typeFilters: TypeFilterOption[] = [DEFAULT_TYPE_FILTER, 'Show Invitees', 'Show Applicants'];
export const statusFilters = [
  INCOMING_VOLUNTEER_STATUS_FILTER_ENUM.PENDING_REVIEW,
  INCOMING_VOLUNTEER_STATUS_FILTER_ENUM.ALL,
  INCOMING_VOLUNTEER_STATUS_FILTER_ENUM.ACTIVE,
  INCOMING_VOLUNTEER_STATUS_FILTER_ENUM.INACTIVE
];
export const DEFAULT_SORT_FILTER = 'Sort by recent activity';
export type SortFilterOption = 'Sort by recent activity' | 'Sort by progress';
export const sortFilters: SortFilterOption[] = [DEFAULT_SORT_FILTER, 'Sort by progress'];
export const DEFAULT_PROGRAM_FILTER = 'All programs'
export const DEFAULT_FILTERS = {
  selectedTypeFilter: DEFAULT_TYPE_FILTER,
  selectedStatusFilter: DEFAULT_STATUS_FILTER as INCOMING_VOLUNTEER_STATUS_FILTER_ENUM,
  selectedSortFilter: DEFAULT_SORT_FILTER,
  selectedProgramFilter: DEFAULT_PROGRAM_FILTER
};
export type IncomingVolunteersSelectFieldProps = {
  selectedFilters: IncomingVolunteersFilters;
  setSelectedFilters: (x: IncomingVolunteersFilters) => void;
};

export const filterHelperTexts = {
  typeFiltersHelperText: 'Select a type',
  statusFiltersHelperText: 'Select a status',
  sortHelperText: 'Select a sorting order',
  programFilterHelperText: 'Select a program',
};
