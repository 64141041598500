import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { encodeDate } from '@campfire/hot-date';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { string as YupString } from 'yup';
import { FormikErrorFocus } from '../../common/form/FormikErrorFocus';
import { volunteerProfileHardCodedFields } from '../../common/form/task-form/model/get-volunteer-profile-hardcoded-field-values';
import { initTaskFormFormikValues, TaskFormValuesType } from '../../common/form/task-form/model/task-form-init';
import {
  dateValidator,
  dynamicValidationSchema,
  phoneNumberValidator,
} from '../../common/form/task-form/model/validation-schema';
import { TaskFormItem } from '../../common/form/task-form/model/__generated__/TaskFormItem';
import { TaskFormFieldList } from '../../common/form/task-form/TaskFormFieldList';
import {
  DateTaskFormikField,
  DropdownTaskFormikField,
  PhoneTaskFormikField,
  ShortTextTaskFormikField,
} from '../../common/inputs/task-fields/TaskFormikFields';
import { deserializeTaskItems } from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';
import { useUser } from '../auth/useUser';
import { useSnackbar } from '../config/useSnackbar';
import { useEndpointFetch } from '../network/useEndpointFetch';
import { getTaskFieldValues, TaskFieldValueType } from './model/suyp-task-submission-handler';
import { SUYPTaskScaffold } from './SUYPTaskScaffold';

const volProfileValidationSchema = {
  firstName: YupString().required('Required'),
  lastName: YupString().required('Required'),
  preferredName: YupString().nullable(),
  dob: dateValidator(false),
  contactNumber: phoneNumberValidator(false),
  programId: YupString(),
  taskId: YupString().required('Required'),
};

type VolProfileInitialValues = {
  firstName: string;
  lastName: string;
  preferredName: string;
  dob: null | DateTime;
  contactNumber: string;
  programId: string;
  [key: string]: any;
};

type VolunteerProfileSubmission = {
  firstName: string;
  lastName: string;
  preferredName: string;
  dob: string;
  contactNumber: string;
  programId: string | null;
  taskId: string;
  taskFieldValues: TaskFieldValueType[];
  userId: string;
};

interface SUYPVolunteerProfileProps {
  task?: {
    taskId: string;
  };
  taskItems: TaskFormItem[];
  programs: {
    programId: string;
    name: string;
    dateSuspended: any | null;
  }[];
  refetch?: () => void;
  loading: boolean;
}

export const SUYPVolunteerProfile = (props: SUYPVolunteerProfileProps) => {
  const { taskItems, task, refetch, loading, programs } = props;
  const { setSnackbar } = useSnackbar();
  const { user } = useUser();

  const submitVolunteerProfile = useEndpointFetch<VolunteerProfileSubmission>(
    '/vm/volunteer/profile/completed-task/save-with-profile'
  );

  const initialFormikValues = useMemo(() => {
    return {
      ...initTaskFormFormikValues(taskItems, undefined),
      taskId: task?.taskId,
      firstName: user.firstName,
      lastName: user.lastName,
      preferredName: '',
      dob: null,
      contactNumber: '',
      programId: '',
    };
  }, [taskItems]);

  const parsedTaskItems = deserializeTaskItems(taskItems);

  const validationSchema = dynamicValidationSchema(parsedTaskItems, volProfileValidationSchema);

  const handleSubmit = async (values: VolProfileInitialValues) => {
    const taskFieldValues = await getTaskFieldValues(parsedTaskItems, values);

    submitVolunteerProfile
      .run({
        ...values,
        dob: encodeDate(values.dob as DateTime),
        preferredName: values.preferredName !== '' ? values.preferredName : values.firstName,
        taskFieldValues,
        taskId: task?.taskId ?? '',
        userId: user.userId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save volunteer profile',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Volunteer profile saved',
          variant: 'success',
        });
        if (refetch) refetch();
      })
      .catch(() => console.log('react broke'));
  };

  const orderedProgramItems = useMemo(
    () =>
      programs
        .filter((program) => program.dateSuspended === null)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((program) => {
          return {
            id: program.programId,
            label: program.name,
          };
        }),
    [programs]
  );

  return loading ? (
    <CircularProgressOverlay isLoading />
  ) : (
    <Box bgcolor='#f1f1f1' display='flex' flex='1 1 auto' flexDirection='column' width={1} paddingTop={2}>
      <LinearProgressOverlay isLoading={submitVolunteerProfile.isLoading} />

      <SUYPTaskScaffold
        allowBack={false}
        taskHeaderDetails={{ title: `Hey, ${user.preferredName}`, description: `Let's get your profile started` }}
      >
        <Formik initialValues={initialFormikValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({
            values: {
              taskId: _unusedTaskId,
              firstName: _unusedFirstTime,
              lastName: _unusedLastTime,
              preferredName: _unusedPreferredName,
              dob: _unusedDOB,
              contactNumber: _unusedContactNumber,
              programId: _unusedProgramId,
              ...values
            },
          }) => (
            <Form>
              <Box display='flex' justifyContent='center' flex='1 1 auto'>
                <Box maxWidth={720} width={1}>
                  <ShortTextTaskFormikField
                    name='firstName'
                    ariaId='firstName'
                    title={volunteerProfileHardCodedFields.firstName.name}
                    ariaDescribedBy='First name'
                    required
                    outlinedInputProps={{ autoFocus: true }}
                    autoComplete='no'
                  />
                  <ShortTextTaskFormikField
                    name='lastName'
                    ariaId='lastName'
                    title={volunteerProfileHardCodedFields.lastName.name}
                    ariaDescribedBy='Last Name'
                    required
                    autoComplete='no'
                  />
                  <ShortTextTaskFormikField
                    name='preferredName'
                    ariaId='preferredName'
                    title={volunteerProfileHardCodedFields.preferredName.name}
                    description={volunteerProfileHardCodedFields.preferredName.description}
                    ariaDescribedBy='Preferred Name'
                    autoComplete='no'
                  />
                  <DateTaskFormikField
                    name='dob'
                    ariaId='dob'
                    title={volunteerProfileHardCodedFields.dob.name}
                    ariaDescribedBy='Date of Birth'
                    required
                  />
                  <PhoneTaskFormikField
                    name='contactNumber'
                    ariaId='contactNumber'
                    title={volunteerProfileHardCodedFields.contactNumber.name}
                    description={volunteerProfileHardCodedFields.contactNumber.description}
                    ariaDescribedBy='Phone number'
                    required
                  />
                  <DropdownTaskFormikField
                    name='programId'
                    title={volunteerProfileHardCodedFields.programId.name}
                    description={volunteerProfileHardCodedFields.programId.description}
                    ariaId='programId'
                    ariaDescribedBy={'Program'}
                    options={orderedProgramItems}
                    required
                  />

                  <TaskFormFieldList
                    taskItems={deserializeTaskItems(taskItems)}
                    disableAutoFocus
                    values={values as TaskFormValuesType}
                  />

                  <TabletButton
                    aria-label='submit'
                    color='primary'
                    variant='contained'
                    type='submit'
                    size='large'
                    style={{ width: 256 }}
                  >
                    Submit
                  </TabletButton>
                </Box>
              </Box>
              <FormikErrorFocus />
            </Form>
          )}
        </Formik>
      </SUYPTaskScaffold>
    </Box>
  );
};
