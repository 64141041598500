import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useRosterStatusStyles = makeStyles(() => ({
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 150,
    minWidth: 150,
    overflowX: 'hidden',
    margin: 'auto',
    color: (props: { color: string }) => props.color === 'Unavailable' ?  props.color : props.color ? '#FFFFFF' : '#9e9e9e',
    backgroundColor: (props: { color: string }) => props.color ?? '#9e9e9e',
    borderColor: (props: { color: string }) => props.color ? '' : '#9e9e9e',
    fontSize: '0.75rem',
    textTransform: 'none',
    padding: '7px 12px',
    height: '40px',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: (props: { color: string }) => props.color ?? 'rgb(169, 166, 255)',
      opacity: '.7',
    }
  },
  statusContainerMobile: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 150,
    minWidth: 150,
    overflowX: 'hidden',
    margin: 'auto',
    color: (props: { color: string }) => props.color ? '#FFFFFF' : '#9e9e9e',
    backgroundColor: (props: { color: string }) => props.color ?? 'rgb(169, 166, 255)',
    borderColor: (props: { color: string }) => props.color ? '' : '#9e9e9e',
    fontSize: '0.75rem',
    textTransform: 'none',
    padding: '7px 12px',
    height: '40px',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: (props: { color: string }) => props.color ?? 'rgb(169, 166, 255)',
    }
  },
}));

export const useRosterTableStyles = makeStyles((theme: Theme) => ({
  rowBody: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9F9F9',
    },
    '&:first-child': {
      marginTop: '0.5rem',
    },
  },
  rowName: {
    color: theme?.color?.grey?.neutralBrand800,
    fontWeight: 700,
    fontSize: '1rem',
    paddingTop: '1.5rem !important',
    paddingBottom: '0.5rem !important',
  },
  rowTime: {
    color: theme?.color?.grey?.neutral300,
    fontSize: '0.75rem',
    fontWeight: 400,
    paddingTop: '0 !important',
    verticalAlign: 'top !important' as 'top',
  },
  rowVolunteer: {
    color: theme?.color?.grey?.neutral300,
    fontSize: '0.75rem',
    fontWeight: 400,
    paddingTop: '0 !important',
  },
  reviewButton: {
    color: 'white',
    width: 150,
    fontSize: '0.875rem',
    fontWeight: 700,
    textTransform: 'capitalize'
  },
  footer: {
    textAlign: () => 'start !important' as 'start',
  },
  nameColumn: {
    width: 220,
  },
  cell: {
    borderBottom: 'none',
    textAlign: 'center',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    paddingLeft: (props: { isMobile: boolean }) => (props.isMobile ? 0 : '1rem'),
    paddingRight: (props: { isMobile: boolean }) => (props.isMobile ? 0 : '1rem'),
  },
  mobileHeaderName: {
    textAlign: 'start !important' as 'start',
    fontSize: '1.5rem',
    fontWeight: 400,
    paddingLeft: 0,
    width: 160,
  },
  addVolunteerButton: {
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    boxSizing: 'border-box',
    textTransform: 'none',
    color: '#ffffff',
    minWidth: '45px',
    backgroundColor: '#7047EB',
    fontWeight: 400,
    fontSize: 13,
    '&:hover': {
      backgroundColor: '#7047EB',
      opacity: 0.8,
    },
    height: 33,
  },
  completeReport: {
    backgroundColor: theme.color.success[500],
    '&:hover': {
      backgroundColor: theme.color.success[500],
      opacity: 0.8,
    },
  },
  inCompleteReport: {
    backgroundColor: theme.color.error[900],
    '&:hover': {
      backgroundColor: theme.color.error[900],
      opacity: 0.8,
    },
    color: 'white !important'
  }
}));
