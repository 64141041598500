import { TableCell, TableFooter, TablePagination, TableRow } from '@material-ui/core';
import React from 'react';

interface ImpactDashboardFooterProps {
  count: number;
  textLabels: any;
  rowsPerPage: number;
  page: number;
  changeRowsPerPage: (x: any) => any;
  changePage: (x: number) => void;
}

export const ImpactDashboardFooter = (props: ImpactDashboardFooterProps) => {
  const { count: count1, textLabels, rowsPerPage, page: page1, changeRowsPerPage, changePage } = props;
  return (
    <TableFooter>
      <TableRow>
        <TableCell style={{ display: 'flex', justifyContent: 'space-between', padding: '0px' }}>
          <TablePagination
            component='div'
            count={count1}
            rowsPerPage={rowsPerPage}
            page={page1}
            labelRowsPerPage='Rows:'
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${textLabels.displayRows} ${count}`}
            backIconButtonProps={{
              'aria-label': textLabels.previous,
            }}
            nextIconButtonProps={{
              'aria-label': textLabels.next,
            }}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onChangePage={(_unused, page) => changePage(page)}
            onChangeRowsPerPage={(e) => changeRowsPerPage(e.target.value)}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};
