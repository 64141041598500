import { Field } from '@campfire/field/lib';
import { TabletButton } from '@campfire/tablet-button/lib';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { FieldArrayRenderProps, Form, Formik, useFormikContext } from 'formik';
import React, { memo, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { object as YupObject, string as YupString } from 'yup';
import { FormikTaskBuilderValues } from '../../admin-console-volunteer-profile-model';
import { Task_taskItems_VOLUNTEER_TaskItemHeadingType as HeadingTaskItemType } from '../../__generated__/Task';

const validationSchema = YupObject().shape({
  heading: YupString().required('Please provide a heading name'),
});

interface Props {
  open: boolean;
  close: () => void;
  arrayHelpers: FieldArrayRenderProps;
  data: HeadingTaskItemType | null;
}

type HeadingValues = {
  taskItemId: string;
  heading: string;
  order: number;
};

export const HeadingTaskItemDialog = memo((props: Props) => {
  const { open, close, data } = props;
  const formikContext = useFormikContext<FormikTaskBuilderValues>();
  const { values } = formikContext;
  const { taskItems } = values;

  const title = useMemo(() => (data ? 'Edit Heading' : 'Add Heading'), [data]);

  const initialValues: HeadingValues = useMemo(
    () => ({
      heading: data?.heading ?? '',
      order: data?.order ?? formikContext.values.taskItems.allIds.length,
      taskItemId: data?.taskItemId ?? `new-${uuidv4()}`,
      __typename: 'VOLUNTEER_TaskItemHeadingType',
    }),
    [data, open]
  );

  const handleSubmit = (vals: HeadingValues) => {
    return data ? editHeading(vals) : addHeading(vals);
  };

  const addHeading = (vals: HeadingValues) => {
    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      allIds: taskItems.allIds.concat(vals.taskItemId),
      byId: {
        ...taskItems.byId,
        [vals.taskItemId]: vals,
      },
    });
    close();
  };

  const editHeading = (vals: HeadingValues) => {
    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      byId: {
        ...taskItems.byId,
        [vals.taskItemId]: vals,
      },
    });
    close();
  };

  const removeHeading = () => {
    const optionId = taskItems.byTriggerOption[initialValues.taskItemId];
    formikContext.setFieldValue('taskItems', {
      ...taskItems,
      allIds: taskItems.allIds.filter((itemId) => itemId !== initialValues.taskItemId),
      byTrigger: optionId
        ? taskItems.byTrigger[optionId].triggerItemIds.filter((itemId) => itemId !== initialValues.taskItemId)
        : taskItems.byTrigger,
    });
    close();
  };

  return (
    <Dialog open={open}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ submitForm }) => (
          <Form>
            <DialogTitle>
              <Typography variant='h6'>{title}</Typography>
              <Typography>{'Use headings to break your task up into smaller sections'}</Typography>
            </DialogTitle>
            <DialogContent>
              <Field name='heading' label='Heading name' required fullWidth variant='outlined' helper autoFocus />
            </DialogContent>
            <DialogActions>
              <Grid container justify='flex-end'>
                {data ? (
                  <Grid item xs>
                    <TabletButton variant='outlined' color='error' onClick={removeHeading}>
                      {'Delete'}
                    </TabletButton>
                  </Grid>
                ) : null}
                <Grid item>
                  <TabletButton onClick={close}>{'Cancel'}</TabletButton>
                </Grid>
                <Grid item>
                  <TabletButton type='button' color='primary' variant='contained' onClick={submitForm}>
                    {'Done'}
                  </TabletButton>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
});
