import { unpackToDate, unpackToDateTime } from '@campfire/hot-date';
import { RosterListItem } from '../roster/roster-types';

export const rostersFilteredBySearch = (rosters: RosterListItem[], searchFilter?: string) => {
  if (!searchFilter) return rosters;
  return rosters.filter((r) => r.activityName.toLowerCase().includes(searchFilter.toLowerCase()));
};

export const rostersFilteredByProgram = (rosters: RosterListItem[], programId?: string) => {
  if (!programId || programId === 'all') return rosters;
  return rosters.filter((r) => r.programId === programId);
};

export const rostersFilteredByActivity = (rosters: RosterListItem[], activityId?: string) => {
  if (!activityId || activityId === 'all') return rosters;
  return rosters.filter((r) => r.activityId === activityId);
};

export const rostersFilteredByType = (rosters: RosterListItem[], activityType?: string) => {
  if (!activityType || activityType === 'all') return rosters;
  return rosters.filter((r) => r.activityType === activityType);
};

export const isRosterCompleted = (roster: RosterListItem | undefined) => {
  if (!roster) {
    return false;
  }
  return ['completed', 'not-published', 'report-not-started', 'report-missing', 'completed-cancelled', 'closed', 'suspended'].includes(
    roster?.activityStatus
  );
};

export const rostersFilteredByStatus = (rosters: RosterListItem[], activityStatus?: string) => {
  if (!activityStatus || activityStatus === 'all') return rosters;
  if (activityStatus === 'completed') {
    return rosters.filter((r) => isRosterCompleted(r));
  }
  return rosters.filter((r) => r.activityStatus === activityStatus);
};

export const rostersFilteredByStartTime = (rosters: RosterListItem[], startTime?: string) => {
  if (!startTime) return rosters;
  return rosters.filter((r) =>
    r.startTime && startTime ? unpackToDateTime(r.startTime) >= unpackToDateTime(startTime) : false
  );
};

export const rostersFilteredByEndTime = (rosters: RosterListItem[], endTime?: string) => {
  if (!endTime) return rosters;
  return rosters.filter((r) =>
    r.endTime && endTime ? unpackToDateTime(r.endTime) <= unpackToDateTime(endTime) : false
  );
};

export const rostersFilteredByStartDate = (rosters: RosterListItem[], startDate?: string) => {
  if (!startDate) return rosters;
  return rosters.filter((r) => (startDate ? unpackToDate(r.activityDate) >= unpackToDate(startDate) : false));
};

export const rostersFilteredByEndDate = (rosters: RosterListItem[], endDate?: string) => {
  if (!endDate) return rosters;
  return rosters.filter((r) => (endDate ? unpackToDate(r.activityDate) <= unpackToDate(endDate) : false));
};

export const rostersFilteredByVolunteersNeeded = (rosters: RosterListItem[], volunteersNeeded?: number) => {
  if (!volunteersNeeded || volunteersNeeded <= 0) return rosters;
  return rosters.filter((r) => r.volsNeeded === volunteersNeeded);
};

export const rosterFilteredByTag = (rosters: RosterListItem[], tag: string[]) => {
  return rosters.filter(
    (roster) => tag.length === 0 || roster.activityTags.some(({ activityTagId }) => tag.includes(activityTagId))
  );
};

export const rosterFilteredByDay = (rosters: RosterListItem[], day: string[]) => {
  return rosters.filter(
    (roster) =>
      day.length === 0 ||
      day.map((dayString) => parseInt(dayString, 10)).includes(unpackToDate(roster.activityDate).weekday - 1)
  );
};

export const rosterFilteredByRosterTypes = (rosters: RosterListItem[], rosterType: string) => {
  return rosters.filter(
    (roster) =>
      rosterType === 'all' ||
      (rosterType === 'open' && roster.hasOpenRoster) ||
      (rosterType === 'managed' && !roster.hasOpenRoster)
  );
};
