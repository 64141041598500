import React from 'react';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { encodeDate } from '@campfire/hot-date';

import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

interface Props {
  startDate?: Date;
  endDate?: Date;
  handleChange: (filter: any) => void;
}
export const DateFilters = (props: Props) => {
  const { startDate, endDate, handleChange } = props;
  const { isMobile } = useCampfireTheme();

  return (
    <Grid container style={{ marginTop: '10px' }}>
      <Grid
        item
        xs={isMobile ? 12 : 6}
        style={{ paddingRight: isMobile ? '0px' : '10px', marginBottom: isMobile ? '8px' : '0px' }}
      >
        <KeyboardDatePicker
          fullWidth
          variant='inline'
          format='dd/MM/yyyy'
          id='start-date'
          value={startDate ?? null}
          label='Start Date'
          inputVariant='outlined'
          onChange={(date) => handleChange({ startDate: date ? encodeDate(date) : undefined })}
          autoOk
          placeholder='dd/mm/yyyy'
          allowKeyboardControl
          InputAdornmentProps={{
            position: 'start',
          }}
          KeyboardButtonProps={{ tabIndex: -1 }}
          margin='dense'
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 6} style={{ paddingLeft: isMobile ? '0px' : '10px' }}>
        <KeyboardDatePicker
          fullWidth
          variant='inline'
          format='dd/MM/yyyy'
          id='end-date'
          value={endDate ?? null}
          label='End Date'
          inputVariant='outlined'
          onChange={(date) => handleChange({ endDate: date ? encodeDate(date) : undefined })}
          autoOk
          placeholder='dd/mm/yyyy'
          allowKeyboardControl
          InputAdornmentProps={{
            position: 'start',
          }}
          KeyboardButtonProps={{ tabIndex: -1 }}
          margin='dense'
        />
      </Grid>
    </Grid>
  );
};
