import React from 'react';

import { FormControlLabel, Checkbox } from '@material-ui/core';
import { AvatarName } from '../../../../../../common/AvatarName';
import { VolunteerRole } from '../VolunteerRoleListQuery/__generated__/VolunteerRole';

export interface SelectableVolunteerRole extends VolunteerRole {
  checked: boolean;
}

interface Props {
  volunteer: SelectableVolunteerRole;
  onChange: (roleId: string) => void;
  style: any;
}

export function VolunteerItem({ volunteer, onChange, style }: Props) {
  if (!volunteer) {
    return <div style={style} />;
  }

  const name = `${volunteer.profile.preferredName} ${volunteer.profile.lastName}`;

  return (
    <FormControlLabel
      style={{ padding: '0.5rem', marginRight: 'none', ...style }}
      control={
        <Checkbox
          checked={volunteer.checked}
          onChange={() => onChange(volunteer.volunteerId)}
          color='primary'
          style={{ marginRight: '10px' }}
        />
      }
      label={<AvatarName avatarUrl={volunteer.profile.avatarUrl} name={name} noTrim />}
    />
  );
}
