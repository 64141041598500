import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { CommonRequiringActionWidgetProps } from '../../../shared';
import { useCampfireQuery } from '../../../../../../../../../global/network/useCampfireQuery';
import { GET_TABLE_WIDGET_PRIORITISED_ACTIVITIES } from './prioritised-activity-table-widget-model.gql';
import {
  GetTableWidgetPrioritisedActivities,
  GetTableWidgetPrioritisedActivitiesVariables,
} from './__generated__/GetTableWidgetPrioritisedActivities';

export const PrioritisedActivityTableWidget = (props: CommonRequiringActionWidgetProps) => {
  const { filters } = props;
  const { programIds } = JSON.parse(filters) as { programIds: [string]; activityIds: [string] };

  const { data, loading } = useCampfireQuery<
    GetTableWidgetPrioritisedActivities,
    GetTableWidgetPrioritisedActivitiesVariables
  >(GET_TABLE_WIDGET_PRIORITISED_ACTIVITIES, {
    options: {
      variables: {
        programIds: programIds.length > 0 ? programIds : null,
        prioritised: true,
      },
    },
  });

  const prioritisedActivities =
    data?.vm.activities.map((activity) => ({
      id: activity.activityId,
      activityName: activity.name,
      program: activity.program.name,
    })) || [];

  const columns: GridColumns = [
    {
      field: 'activityName',
      headerName: 'Activity',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: ({ row }: any) => {
        return (
          <Tooltip title={row.activityName}>
            <Typography noWrap style={{ fontSize: 'inherit' }}>
              {row.activityName}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'program',
      headerName: 'Program',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: ({ row }: any) => {
        return (
          <Tooltip title={row.program}>
            <Typography noWrap style={{ fontSize: 'inherit' }}>
              {row.program}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Box width='100%' height='100%'>
      <DataGrid columns={columns} rows={prioritisedActivities} loading={loading} autoPageSize disableSelectionOnClick />
    </Box>
  );
};
