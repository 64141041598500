import React from 'react';
import { Box, Popover, Button } from '@material-ui/core';
import { useQueryParams, StringParam } from 'use-query-params';
import { DateTime } from 'luxon';
import { ExpandMore } from '@material-ui/icons';
import { unpackToDate } from '@campfire/hot-date';
import { DatePicker } from './DatePicker';
import {
  ActivitiesGetMyShifts,
  ActivitiesGetMyShiftsVariables,
  ActivitiesGetMyShifts_vm_volunteer_upcomingRosters as RosterType,
} from '../ActivityQueries/__generated__/ActivitiesGetMyShifts';
import { GET_MY_ACTIVITIES } from '../ActivityQueries/get-my-shifts.gql';
import { ActivitiesTabs } from '../ActivitiesTabs';
import { ActivitiesToolbar } from '../ActivitiesToolbar';
import { ActivitiesSidebar } from '../ActivitiesSidebar';
import { ActivitiesBody } from '../ActivitiesBody';
import { SearchField } from '../../../../common/inputs/SearchField';
import { ActivityTabValue, ActivityToolValue, RosteredStatus, RosterTimelineItem, ActionType } from '../ActivityTypes';
import { useUser } from '../../../../global/auth/useUser';
import { ActivityMyshiftSidebar, ActivityMyApplications } from '../ActivityMyShiftSidebar';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { sharedStyles, bodyStyles } from '../ActivityStyles/shared';
import { MyShiftColorLegend } from './MyShiftColorLegend';
import { MyShiftTimeline } from './MyShiftTimeline/MyShiftTimeline';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { AlertCard } from '../../../../common/cards/alert-card/AlertCard';
import { ActivityContextValue } from '../ActivityContext';
import { useActivityActions } from '../useActivityActions';
import { getRosterStatus, getRosterActions } from '../helpers';
import { ActivityAvailability } from '../../../../common/availability-center/ActivityAvailability/ActivityAvailability';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { AlertFullSessionDialog } from '../../../../common/alert-full-session-dialog/AlertFullSessionDialog';
import { CheckOutFinishedNext } from '../../vol-dashboard/vol-dashboard-my-elements/main-section/my-activities/CICO/CheckOutFinishedNext';
import { MyActivityCheckInDialog } from '../../vol-dashboard/vol-dashboard-my-elements/main-section/my-activities/CICO/MyActivityCheckInDialog';
import { MyActivityCheckOutDialog } from '../../vol-dashboard/vol-dashboard-my-elements/main-section/my-activities/CICO/MyActivityCheckOutDialog';

export const MyShiftScreen = () => {
  const [[startDate, endDate], setDate] = React.useState([
    DateTime.local().toISODate(),
    DateTime.local()
      .plus({ days: 30 })
      .toISODate(),
  ]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  const [query, setQuery] = useQueryParams({
    activityId: StringParam,
    activityDate: StringParam,
    tab: StringParam,
    show: StringParam,
    selectedDate: StringParam,
  });
  const [searchValue, setSearchValue] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checkOutFinishedSessionId, setCheckOutFinishedSessionId] = React.useState<string>();

  const setActiveTab = (newTab: string) => {
    if (newTab) {
      setQuery({ tab: newTab });
    }
  };
  const activeTab = (query.tab || 'all') as ActivityTabValue;

  const tools = [
    {
      name: 'calendar' as ActivityToolValue,
      onClick: (e: any) => {
        setAnchorEl(e.target);
      },
      selected: Boolean(query.selectedDate),
    },
  ];

  const onChangeSearchField = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchValue(e.target.value);
  };

  const { vmVolunteer, vmVolunteerRefetch } = React.useContext(ActivityContextValue);

  const [queryActivities] = useCampfireLazyQuery<ActivitiesGetMyShifts, ActivitiesGetMyShiftsVariables>(
    GET_MY_ACTIVITIES
  );

  const [data, setData] = React.useState<RosterType[]>([]);

  React.useEffect(() => {
    setIsLoading(true);
    queryActivities({
      variables: {
        volunteerId,
        endDate,
        startDate,
      },
    }).then((response) => {
      setData(response.data?.vm.volunteer?.upcomingRosters || []);
      setIsLoading(false);
    });
  }, []);

  const refetchListRosters = () => {
    queryActivities({
      variables: {
        volunteerId,
        endDate,
        startDate,
      },
    }).then((response) => {
      setData(response.data?.vm.volunteer?.upcomingRosters || []);
      setIsLoading(false);
    });
  };

  const { selectedDate } = query;
  const setSelectedDate = (date: string | undefined) =>
    setQuery({
      selectedDate: date,
    });

  const rosters = data
    .filter(
      ({ activityDate, activity }) =>
        activityDate >= startDate &&
        activityDate <= endDate &&
        activity.name.toLowerCase().includes(searchValue.toLowerCase())
    )
    .map(({ activity, activityDate, publishedRoster }) => {
      const { rosterTemplates } = activity;
      const rosterTemplate = rosterTemplates.find(
        (temp) => unpackToDate(temp.rosterDate).toFormat('yyyy-MM-dd') === activityDate
      );
      return {
        ...activity,
        activityDate,
        publishedRoster,
        rosterTemplateAddress: rosterTemplate?.placesAddress?.formatted,
        status: getRosterStatus(activity, activityDate, vmVolunteer),
      };
    })
    .sort((a, b) => {
      if (a.activityDate < b.activityDate) {
        return -1;
      }
      if (a.activityDate === b.activityDate) {
        return 0;
      }
      return 1;
    });

  const showingRosters = (rosters || []).filter(({ activityDate }) => {
    return !selectedDate || activityDate === selectedDate;
  });

  const { theme, isSm, isMobile } = useCampfireTheme();

  const mapRosterByDate = rosters?.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.activityDate]: theme.color.rosteredStatuses[cur.status].primary,
    }),
    {}
  );

  const buttonClasses = sharedStyles.button();

  const classes = bodyStyles();
  const emptyMessage =
    rosters?.length === 0
      ? 'There are no rosters to show'
      : showingRosters.length === 0
      ? 'There are no activities that match your filter criteria'
      : '';

  const {
    onAction,
    showDialog,
    onSubmit,
    onCloseDialog,
    selectedActivity,
    showUpdateDialog,
    showAlertDialog,
    activityIdSubmited,
    fullSessionIds,
    handleAlertClose,
    activityDatesSubmited,
    onCICOAction,
    showCheckInDialog,
    showCheckOutDialog,
    CICODialogType,
    setShowCheckOutDialog,
  } = useActivityActions(vmVolunteerRefetch);

  const onActivityAction = React.useCallback(
    (action: ActionType, activity: RosterTimelineItem) => {
      const enrolmentId = vmVolunteer?.vm.volunteer?.activityEnrolments.find(
        (enrolment) => enrolment.activity.activityId === activity.activityId
      )?.activityEnrolmentId;
      const publishedRosteringId = vmVolunteer?.vm.volunteer?.rosterings.find(
        (rostering) =>
          !rostering.dateRemoved &&
          rostering.publishedRoster.activityDate === activity.activityDate &&
          rostering.publishedRoster.activity.activityId === activity.activityId
      )?.publishedRosteringId;
      const activityApplicationId = vmVolunteer?.vm.volunteer?.activityApplications.find(
        (application) => !application.dateWithdrawn && application.activity.activityId === activity.activityId
      )?.activityApplicationId;
      const activityWaitlistingId = vmVolunteer?.vm.volunteer?.activityWaitlistings.find(
        (waitlist) => !waitlist.dateRemoved && waitlist.activity.activityId === activity.activityId
      )?.activityWaitlistingId;

      onAction({
        enrolmentId,
        activityApplicationId,
        activityWaitlistingId,
        publishedRosteringId,
      })(action, { ...activity, status: activity.status });
    },
    [vmVolunteer]
  );

  const onSubmitAction = React.useCallback(() => {
    const enrolmentId = vmVolunteer?.vm.volunteer?.activityEnrolments.find(
      (enrolment) => !enrolment.dateRemoved && enrolment.activity.activityId === selectedActivity.current?.activityId
    )?.activityEnrolmentId;
    const availabilityId = vmVolunteer?.vm.volunteer?.activityEnrolments
      .find((enrolment) => enrolment.activity.activityId === selectedActivity.current?.activityId)
      ?.availabilities?.find((availability) => availability.activityDate === selectedActivity.current?.activityDate)
      ?.activityAvailabilityId;
    const publishedRosteringId = vmVolunteer?.vm.volunteer?.rosterings.find(
      (rostering) =>
        !rostering.dateRemoved &&
        rostering.publishedRoster.activityDate === selectedActivity.current?.activityDate &&
        rostering.publishedRoster.activity.activityId === selectedActivity.current?.activityId
    )?.publishedRosteringId;
    return onSubmit({ enrolmentId, availabilityId, publishedRosteringId });
  }, [vmVolunteer]);

  const getActions = React.useCallback(
    (activity: RosterTimelineItem) => {
      return getRosterActions(activity.status, activity.hasOpenRoster);
    },
    [vmVolunteer]
  );

  const onLoadMore = () => {
    const nextEndDate = unpackToDate(endDate)
      .plus({ months: 1 })
      .toISODate();
    const nextStartDate = unpackToDate(endDate)
      .plus({ days: 1 })
      .toISODate();

    setDate([startDate, nextEndDate]);

    setIsLoading(true);
    queryActivities({
      variables: {
        volunteerId,
        endDate: nextEndDate,
        startDate: nextStartDate,
      },
    }).then((response) => {
      setIsLoading(false);
      setData([...data, ...(response.data?.vm.volunteer?.upcomingRosters || [])]);
    });
  };

  const volunteers =
    showingRosters
      .find((item) => item.activityId === selectedActivity.current?.activityId)
      ?.publishedRoster?.activeRosterings.map((item) => ({
        ...item.volunteer,
        sessionRosterings: item.sessionRosterings,
      })) || [];

  const handleSuccessCheckout = (sessionId: string) => {
    if (!sessionId) {
      onCloseDialog();
    }
    setCheckOutFinishedSessionId(sessionId);
    if (vmVolunteerRefetch) vmVolunteerRefetch();
    if (refetchListRosters) refetchListRosters();
  };

  const handleSuccessCheckin = () => {
    if (vmVolunteerRefetch) vmVolunteerRefetch();
    if (refetchListRosters) refetchListRosters();
  };

  return (
    <Box display='flex' height='100%' overflow='hidden'>
      <Box className={classes.rootContainer}>
        <ActivitiesTabs activeTab={activeTab} onSelectTab={setActiveTab}>
          <Box flex={1} justifyContent='flex-end' display='flex'>
            {!isSm && (
              <SearchField placeholder='Search activities' value={searchValue} onChange={onChangeSearchField} />
            )}
            <ActivitiesToolbar tools={tools} />
          </Box>
        </ActivitiesTabs>
        {emptyMessage.length !== 0 && !isLoading ? (
          <Box marginTop='1rem'>
            <AlertCard variant='info' title={emptyMessage} />
          </Box>
        ) : (
          <ActivitiesBody isLoading={isLoading}>
            {isMobile && query.activityId && query.activityDate ? (
              <Box padding='1rem 1rem'>
                <ActivitiesSidebar inMyShift />
              </Box>
            ) : (
              <React.Fragment>
                <MyShiftColorLegend />
                <MyShiftTimeline
                  onAction={onActivityAction}
                  activities={showingRosters}
                  onSelect={(activityId, activityDate) => setQuery({ activityId, activityDate })}
                  selectedActivityId={query.activityId}
                  selectedActivityDate={query.activityDate}
                  getColor={(status: RosteredStatus) => theme.color.rosteredStatuses[status]}
                  getRosterActions={getActions}
                  volunteerRosterings={vmVolunteer?.vm.volunteer?.rosterings}
                  onCICOAction={onCICOAction}
                  vmAllowCICO={Boolean(vmVolunteer?.vm.allowCICO)}
                />
                {!isLoading && (
                  <Box display='flex' justifyContent='center'>
                    <Button
                      onClick={onLoadMore}
                      style={{ alignSelf: 'center', marginTop: '1rem' }}
                      className={classes.button}
                      variant='outlined'
                      disableElevation
                      endIcon={<ExpandMore />}
                    >
                      Show more
                    </Button>
                  </Box>
                )}
                {isMobile && <ActivityMyApplications volunteerId={volunteerId} />}
              </React.Fragment>
            )}
          </ActivitiesBody>
        )}
      </Box>
      {query.activityId && query.activityDate && !isMobile && (
        <CollapsibleSideBar>
          <ActivitiesSidebar inMyShift />
        </CollapsibleSideBar>
      )}
      {!query.activityId && !isMobile && (
        <ActivityMyshiftSidebar>
          <ActivityMyApplications volunteerId={volunteerId} />
        </ActivityMyshiftSidebar>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 50,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DatePicker
          mapRosterByDate={mapRosterByDate || {}}
          value={selectedDate}
          onChange={(date) => setSelectedDate(date?.toISODate())}
          orientation='landscape'
          variant='static'
          disableToolbar
          onMonthChange={(date) => {
            setDate([date?.startOf('month').toISODate() || startDate, date?.endOf('month').toISODate() || endDate]);
          }}
          autoOk
          initialFocusedDate={startDate}
        />
        <Box display='flex' justifyContent='flex-end' marginBottom='1rem' marginRight='1rem'>
          <Button
            variant='outlined'
            onClick={() => setQuery({ selectedDate: undefined })}
            classes={{ root: buttonClasses.root }}
          >
            Clear
          </Button>
        </Box>
      </Popover>
      {showDialog && selectedActivity.current && (
        <ActivityAvailability
          open={showDialog}
          activityId={selectedActivity.current.activityId}
          onClose={onCloseDialog}
          selectedDate={selectedActivity.current.activityDate}
          onSubmit={onSubmitAction()}
        />
      )}
      {showUpdateDialog && selectedActivity.current && (
        <ActivityAvailability
          open={showUpdateDialog}
          activityId={selectedActivity.current.activityId}
          onClose={onCloseDialog}
          selectedDate={selectedActivity.current.activityDate}
          onSubmit={onSubmitAction()}
        />
      )}
      {showAlertDialog && (
        <AlertFullSessionDialog
          open={showAlertDialog}
          onClose={handleAlertClose}
          fullSessionIds={fullSessionIds}
          activityIdSubmited={activityIdSubmited}
          activityDatesSubmited={activityDatesSubmited}
        />
      )}

      {selectedActivity.current && showCheckInDialog && (
        <MyActivityCheckInDialog
          open={showCheckInDialog}
          type={CICODialogType}
          onClose={onCloseDialog}
          activity={selectedActivity.current}
          handleSuccess={handleSuccessCheckin}
          volunteers={volunteers}
        />
      )}

      {selectedActivity.current && showCheckOutDialog && (
        <MyActivityCheckOutDialog
          open={showCheckOutDialog}
          type={CICODialogType}
          onClose={() => setShowCheckOutDialog(false)}
          activity={selectedActivity.current}
          handleSuccess={handleSuccessCheckout}
        />
      )}
      {selectedActivity.current && checkOutFinishedSessionId && (
        <CheckOutFinishedNext
          open
          handleClose={() => setCheckOutFinishedSessionId(undefined)}
          activity={selectedActivity.current}
          checkOutFinishedSessionId={checkOutFinishedSessionId}
        />
      )}
    </Box>
  );
};
