import React from 'react';
import { TaskCompleteIcon } from '../../../../../../common/icons/TaskCompleteIcon';
import { IncomingVolunteerTaskList } from '../IncomingVolunteerTaskList';

interface Props {
  status: string;
  subText: string;
  onClick?: () => void;
}

const IncomingVolunteersInviteTaskSection = ({ subText, status, onClick }: Props) => {
  return (
    <IncomingVolunteerTaskList
      header='Email Invite'
      items={[
        {
          taskId: 'invite-task-id-spoof',
          icon: <TaskCompleteIcon isComplete />,
          taskTitle: 'Invitation sent',
          taskSubText: subText,
          taskRecentActivityStatus: status,
          onClick: onClick,
          adminOnly: false,
        },
      ]}
    />
  );
};

export { IncomingVolunteersInviteTaskSection };
