import { objectToFormData } from 'object-to-formdata';
import { useApiUrl } from '../config/useApiUrl';
import { CampfireResponse } from './httpResponse';
import { AxiosResponseWithOk } from './useAxiosFetch';
import { useCampfireFetch, UseCampfireFetchApi } from './useCampfireFetch';

type FetchMethod = 'post' | 'get';

export interface UseEndpointFetch<Req, Res = unknown> extends Omit<UseCampfireFetchApi<Res>, 'run'> {
  run: (data: Req) => Promise<AxiosResponseWithOk<CampfireResponse<Res>>>;
}

export interface EndpointFetchOptions {
  withAuth?: boolean;
  method?: FetchMethod;
  formData?: boolean;
}
export const useEndpointFetch = <Req extends object, Res = unknown>(
  slug: string,
  options?: EndpointFetchOptions
): UseEndpointFetch<Req, Res> => {
  const apiUrl = useApiUrl();
  const fetch = useCampfireFetch<Res>({ defer: true, withAuth: options?.withAuth ?? true });

  const endpointRun = (data: Req) => {
    const transformedData = options?.formData
      ? objectToFormData<Req>(data, { indices: true, nullsAsUndefineds: true, booleansAsIntegers: true })
      : data;

    return fetch.run({
      method: options?.method ?? 'post',
      url: `${apiUrl}${slug}`,
      data: transformedData
    });
  };

  return {
    ...fetch,
    run: endpointRun,
  };
};
