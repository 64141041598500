import React from 'react';
import { Box, Button, CircularProgress, MenuItem, TextField, Typography } from '@material-ui/core';
import { useCampfireFetch } from '../../../../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../../../../global/config/useApiUrl';
import { useCommonStyles, useSelectStyles } from '../../commonStyles';

type ICampaignMonitorClient = {
  client: { ClientID: string; Name: string };
  lists: Array<{ ListID: string; Name: string }>;
};

type ICampaignMonitorData = {
  clients: Array<ICampaignMonitorClient>;
  selectedClientId: string | null;
  selectedListId: string | null;
};

type ICampaignMonitorStatus = {
  campaignMonitorStatusId: string;
  clientId: string;
  listId: string;
};

export function CampaignMonitorSetups() {
  const inputClasses = useSelectStyles();
  const commonClasses = useCommonStyles();
  const campfireFetch = useCampfireFetch<ICampaignMonitorData>({ defer: true });
  const saveManageCampaignMonitor = useCampfireFetch<ICampaignMonitorStatus>({ defer: true });
  const apiUrl = useApiUrl();

  const [isLoading, setIsLoading] = React.useState(false);
  const [campaignMonitorData, setCampaignMonitorData] = React.useState<ICampaignMonitorData | null>(null);
  const [selectedListId, setSelectedListId] = React.useState<string | null>(null);
  const [selectedClientId, setSelectedClientId] = React.useState<string | null>(null);
  const [initialListId, setInitialListId] = React.useState<string | null>(null);

  React.useEffect(() => {
    setIsLoading(true);
    campfireFetch
      .run({
        url: `${apiUrl}/wm/integrations/campaign-monitor/manage/get`,
        method: 'get',
      })
      .then((response) => {
        setCampaignMonitorData(response.data.data);
        setSelectedListId(response.data.data.selectedListId);
        setSelectedClientId(response.data.data.selectedClientId);
        setIsLoading(false);
        setInitialListId(response.data.data.selectedListId);
      });
  }, []);

  const selectedClient = (campaignMonitorData?.clients || []).find(
    (client) => client.client.ClientID === selectedClientId
  );

  const onSave = () => {
    if (!selectedClientId || !selectedListId) {
      return;
    }
    if (selectedClient) {
      saveManageCampaignMonitor
        .run({
          url: `${apiUrl}/wm/integrations/campaign-monitor/manage/save`,
          method: 'post',
          data: {
            clientId: selectedClientId,
            listId: selectedListId,
          },
        })
        .then(() => {
          setInitialListId(selectedListId);
        });
    }
  };

  const isSavingDisabled = React.useMemo(() => {
    if (!selectedClientId || !selectedListId) {
      return false;
    }
    return initialListId === selectedListId;
  }, [initialListId, selectedClientId, selectedListId]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography className={commonClasses.label}>Select client</Typography>
      <Box mt='0.5rem' width={300}>
        <TextField
          select
          fullWidth
          InputProps={{ classes: inputClasses }}
          variant='outlined'
          value={selectedClientId}
          onChange={(event) => setSelectedClientId(event.target.value)}
        >
          {(campaignMonitorData?.clients || []).map((client) => (
            <MenuItem value={client.client.ClientID}>{client.client.Name}</MenuItem>
          ))}
        </TextField>
      </Box>
      {selectedClient && (
        <Box mt='1rem' width={300}>
          <Typography className={commonClasses.label}>Select list</Typography>
          <TextField
            select
            fullWidth
            InputProps={{ classes: inputClasses }}
            variant='outlined'
            value={selectedListId}
            onChange={(event) => setSelectedListId(event.target.value)}
          >
            {selectedClient.lists.map((list) => (
              <MenuItem value={list.ListID}>{list.Name}</MenuItem>
            ))}
          </TextField>
        </Box>
      )}
      <Box mt='2rem'>
        <Button
          onClick={onSave}
          className={commonClasses.saveButton}
          variant='contained'
          disableElevation
          color='primary'
          disabled={isSavingDisabled}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
