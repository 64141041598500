import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import { TutorialDialogStep } from '../../../../common/dialogs/TutorialDialog';
import WelcomeGraphic from '../../../../assets/tutorial-graphics/welcome/welcome.png';
import HelpIsAroundTheCornerGraphic from '../../../../assets/tutorial-graphics/welcome/help_is_just_around_the_corner.png';
import LetsStepThroughThisGraphic from '../../../../assets/tutorial-graphics/welcome/lets_step_through_this_together.png';
import { WelcomeDialog } from '../../../../common/dialogs/WelcomeDialog';

const steps: Array<TutorialDialogStep> = [
  {
    label: `Welcome to Volaby`,
    description: `Volaby is a platform that your organisation is using to take their impact to the next level. We've prepared these guides to help get you started. Hit next to continue.`,
    image: WelcomeGraphic,
  },
  {
    label: `Help is just around the corner (of your screen)`,
    description: (
      <>
        {`Some pages have support built in. If a page has quick-start guides you can access them through the `}
        <b>(?)</b>
        {` button at top of the screen.`}
      </>
    ),
    image: HelpIsAroundTheCornerGraphic,
  },
  {
    label: `Let's step through this, together`,
    description: `Use the Onboarding percentage-bar checklist in the top-right of your screen to get things underway. We'll take you through everything you need to begin your volunteering journey.`,
    image: LetsStepThroughThisGraphic,
    theme: {
      background: '#2196f3',
      color: '#fff',
    },
  },
];

const WelcomeToCampfireTutorialDialog = (props: DialogProps) => {
  const { open, onClose } = props;
  return <WelcomeDialog open={open} onClose={onClose} steps={steps} />;
};

export default WelcomeToCampfireTutorialDialog;
