import React from 'react';
import { Box, Button, Typography, Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/styles';
import { unpackToTime } from '@campfire/hot-date';

import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { DateInfoCard } from '../../../../common/cards/date-info-card/DateInfoCard';
import { sharedStyles } from '../ActivityStyles/shared';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: 'white',
      backgroundColor: theme.color.background?.primary?.dark,
      '&:hover': {
        backgroundColor: fade(theme.color.background?.primary?.dark, 0.8),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '0.75rem',
      },
    },
    name: {
      color: theme.color.grey.neutral400,
      fontWeight: 800,
      width: '100%',
      letterSpacing: '-0.02em',
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
  })
);

const customDateInfoStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayContainer: {
      [theme.breakpoints.down('md')]: {
        width: 50,
        minWidth: 50,
      },
    },
    bodyContainer: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
      },
    },
  })
);

interface Props {
  name: string;
  activityDate: string;
  startTime: string;
  endTime: string;
  onWithdraw: () => void;
}

export function ActivityApplication({ name, activityDate, startTime, endTime, onWithdraw }: Props) {
  const { theme } = useCampfireTheme();
  const formatTime = (time: string) => unpackToTime(time).toFormat('h:mm a');

  const buttonClasses = sharedStyles.button();
  const classes = useStyles();
  const dateInfoClasses = customDateInfoStyles();

  return (
    <Box marginTop='10px'>
      <DateInfoCard date={activityDate} size='s' classes={dateInfoClasses}>
        <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
          <Box flex='1'>
            <Typography variant={'h6'} noWrap className={classes.name}>
              {name}
            </Typography>
            <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200 }}>
              {startTime && endTime ? `${formatTime(startTime)} - ${formatTime(endTime)}` : ''}
            </Typography>
          </Box>
          <Button
            onClick={onWithdraw}
            classes={buttonClasses}
            variant='contained'
            color='primary'
            className={classes.button}
          >
            Withdraw
          </Button>
        </Box>
      </DateInfoCard>
    </Box>
  );
}
