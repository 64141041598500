import { gql } from '@apollo/client';

const VOLUNTEER_ROLE_LIST_FRAGMENT =  gql`
  fragment VolunteerRole on VOLUNTEER_VolunteerType {
    volunteerId
    activityRoles {
      activityRoleId
    }
    profile {
      profileId
      lastName
      preferredName
      avatarUrl
    }
  }
`;

export const VOLUNTEER_ROLES_QUERY = gql `
  query GetVolunteerRoles {
    vm {
      volunteers {
        ...VolunteerRole
      }
    }
  }
  ${VOLUNTEER_ROLE_LIST_FRAGMENT}
`;
