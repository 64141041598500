import { Dialog, Fade, Grid, Paper, Typography } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import LockOpen from '@material-ui/icons/LockOpen';
import React, { FunctionComponent } from 'react';
import { useSession } from '../auth/useSession';
import { CampfireButton } from '../components';

export const LoginAgainDialog: FunctionComponent<DialogProps> = ({ open }) => {
  const { logout } = useSession();

  return (
    <Dialog
      PaperProps={{ style: { background: 'rgba(255, 255, 255, 0.4)' } }}
      fullScreen
      open={open}
      TransitionComponent={Fade}
      transitionDuration={{ enter: 1000 }}
    >
      <Grid container style={{ height: '100vh' }} justify='center' alignContent='center' alignItems='center'>
        <Grid item xs={11} sm={6} md={5} lg={4}>
          <Paper
            style={{
              padding: 32,
            }}
          >
            <Grid container alignContent='center' alignItems='center' style={{ marginBottom: 8 }}>
              <LockOpen style={{ marginRight: 8 }} />
              <Typography variant='h6'>{`You need to sign in`}</Typography>
            </Grid>

            <Typography variant='body2' style={{ marginBottom: 32 }}>
              {`Hey, it's been a while.`}
              <br />
              {`You'll land back on your current page after you've signed in.`}
            </Typography>

            <CampfireButton
              onClick={() => {
                logout();
              }}
            >
              {`Sign In`}
            </CampfireButton>
          </Paper>
        </Grid>
      </Grid>
    </Dialog>
  );
};
