import { TabletButton } from '@campfire/tablet-button';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { ImportExportOutlined } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

const SORT_BY_OPTIONS = [
  {
    value: 'alphabetical',
    label: 'Alphabetical',
  },
  {
    value: 'rostered',
    label: 'Already Rostered',
  },
  {
    value: 'newest',
    label: 'Newest',
  },
  {
    value: 'availability',
    label: 'Availability',
  },
];

export const SortVolsRowButton = ({ loading }: { loading: boolean }) => {
  const [sortByElement, setSortByElement] = React.useState<null | HTMLElement>(null);
  const [sortVolsRowBy, setSortVolsRowBy] = useQueryParam('sortVolsRowBy', StringParam);

  const { theme } = useCampfireTheme();

  const onCloseSortBy = () => setSortByElement(null);
  const onSelectSortBy = (value: string) => {
    setSortVolsRowBy(value);
    onCloseSortBy();
  };

  return loading ? (
    <Skeleton width='50px' height='50px' style={{ marginRight: '10px' }} />
  ) : (
    <Box display='flex'>
      <TabletButton
        size='small'
        variant='outlined'
        color='primary'
        aria-controls='sort-menu'
        aria-haspopup='true'
        onClick={(event: any) => setSortByElement(event.currentTarget)}
        startIcon={<ImportExportOutlined />}
        style={{
          border: '1px solid #9e9e9e',
          boxSizing: 'border-box',
          borderRadius: '4px',
          padding: '2px 7px',
          paddingRight: '12px',
          color: theme.color.grey.neutral300,
        }}
      >
        Sort
      </TabletButton>

      <Menu id='sort-menu' anchorEl={sortByElement} keepMounted open={Boolean(sortByElement)} onClose={onCloseSortBy}>
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem onClick={() => onSelectSortBy(option.value)} selected={sortVolsRowBy === option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
