import { Box } from '@material-ui/core';
import React from 'react';
import { ReportManagementMobileTabMenu } from './ReportManagementMobileTabMenu';
import { ReportManagementToolbar } from './ReportManagementToolbar';

interface ReportManagementMobileScreenHeaderProps {
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  setSearchInput: (value: string) => void;
  hide: boolean;
}

export const ReportManagementMobileScreenHeader = (props: ReportManagementMobileScreenHeaderProps) => {
  const { showFilters, setShowFilters, setSearchInput } = props;

  return (
    <Box
      margin='20px 20px 0px 20px'
      display={props.hide ? 'none' : 'flex'}
      alignItems='center'
      style={{ paddingBottom: '10px', borderBottom: '2px solid #EFEFEF' }}
    >
      <ReportManagementMobileTabMenu />
      <ReportManagementToolbar
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        setSearchInput={setSearchInput}
      />
    </Box>
  );
};
