import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { Box } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { debounce } from 'lodash';

import { useUser } from '../../../../global/auth/useUser';
import { Page } from '../../../../global/components';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { IncomingVolunteerViewApplicantScreen } from '../incoming-volunteer/IncomingVolunteerViewApplicantScreen';
import { IncomingVolunteerViewInviteeScreen } from '../incoming-volunteer/IncomingVolunteerViewInviteeScreen';
import { IncomingVolunteerViewProfileScreen } from '../incoming-volunteer/IncomingVolunteerViewProfileScreen';
import {
  DEFAULT_FILTERS,
  INCOMING_VOLUNTEER_STATUS_FILTER_ENUM,
  IncomingVolunteersFilters,
} from './components/filters/filters';
import { GET_PROGRAMS_FOR_INCOMING_VOLS_FILTER } from './incoming-volunteers-model.gql';
import { IncomingVolunteersList } from './IncomingVolunteersList';
import { GetProgramsForIncomingVolsFilter } from './__generated__/GetProgramsForIncomingVolsFilter';
import { useVolunteersContext } from '../VolunteersContext';

import { useRequestExport } from '../../../../common/export/Export';

const IncomingVolunteersScreen = () => {
  const [selectedInviteeId, setSelectedInviteeId] = useQueryParam('inviteeId', StringParam);
  const [selectedApplicationId, setSelectedApplicationId] = useQueryParam('applicationId', StringParam);
  const [selectedVolunteerProfileId, setSelectedVolunteerProfileId] = useQueryParam('volunteerProfileId', StringParam);
  const [selectedFilters, setSelectedFilters] = useState<IncomingVolunteersFilters>(DEFAULT_FILTERS);
  const campfireExport = useRequestExport();
  const {
    getIncomingVolunteers,
    getIncomingVolunteersData,
    getIncomingVolunteersLoading,
    getIncomingVolunteersRefetch,
    searchIncomingVolunteersInput,
  } = useVolunteersContext();
  const { setSnackbar } = useSnackbar();
  const {
    user: { userIdentityService },
  } = useUser();

  const { data: programsData, loading: programsLoading } = useCampfireQuery<
    GetProgramsForIncomingVolsFilter,
    undefined
  >(GET_PROGRAMS_FOR_INCOMING_VOLS_FILTER);

  const [view, setView] = useState(25);
  const [page, setPage] = useState(1);

  const entry = React.useMemo(() => {
    return selectedFilters.selectedTypeFilter.includes('Invitees')
      ? 'invitees'
      : selectedFilters.selectedTypeFilter.includes('Applicants')
      ? 'applicants'
      : undefined;
  }, [selectedFilters.selectedTypeFilter]);

  const status = React.useMemo(() => {
    switch (selectedFilters.selectedStatusFilter) {
      case INCOMING_VOLUNTEER_STATUS_FILTER_ENUM.PENDING_REVIEW:
        return 'pending';
      case INCOMING_VOLUNTEER_STATUS_FILTER_ENUM.ACTIVE:
        return 'active';
      case INCOMING_VOLUNTEER_STATUS_FILTER_ENUM.INACTIVE:
        return 'inactive';
      default:
        return undefined;
    }
  }, [selectedFilters.selectedStatusFilter]);

  const program = React.useMemo(() => {
    return selectedFilters.selectedProgramFilter.includes('All') ? undefined : selectedFilters.selectedProgramFilter;
  }, [selectedFilters.selectedProgramFilter]);

  const sort = React.useMemo(() => {
    return selectedFilters.selectedSortFilter === 'Sort by progress' ? 'progress' : 'lastUpdated';
  }, [selectedFilters.selectedSortFilter]);

  useEffect(() => {
    setPage(1);
  }, [entry, status, program, searchIncomingVolunteersInput, sort]);

  const [query, setQuery] = React.useState(searchIncomingVolunteersInput);

  const debounceSetQuery = React.useRef(debounce(setQuery, 500)).current;

  React.useEffect(() => {
    debounceSetQuery(searchIncomingVolunteersInput);
  }, [searchIncomingVolunteersInput]);

  useEffect(() => {
    getIncomingVolunteers({
      variables: {
        offset: (page - 1) * view,
        limit: view,
        entry,
        status,
        program,
        query,
        sort,
      },
    });
  }, [view, page, entry, status, program, query, sort]);

  const incomingVolunteers = useMemo(() => {
    return getIncomingVolunteersData?.vm?.incomingVolunteers?.incomingVolunteers || [];
  }, [getIncomingVolunteersData]);

  const userIsAdmin = userIdentityService.isVmAdmin;
  const userIsPmOrAdmin = (programId: string) => userIsAdmin || userIdentityService.isManagerOfProgram(programId);
  const programs = useMemo(
    () =>
      programsData?.vm.programs
        ? programsData?.vm.programs
            .filter((a) => userIsPmOrAdmin(a.programId))
            .sort((a, b) => (a.name < b.name ? 1 : 0))
        : [],
    [programsData]
  );

  const handleExport = () => {
    campfireExport('incoming-volunteers', {
      program,
      entry,
      status,
      query,
    }).then((response) => response.data.data);
  };

  if (
    (getIncomingVolunteersLoading || programsLoading) &&
    !(selectedInviteeId || selectedApplicationId || selectedVolunteerProfileId)
  ) {
    return (
      <Page>
        <Box position='relative' display='flex' flex='1 1 auto' height={1}>
          <CircularProgressOverlay isLoading />
        </Box>
      </Page>
    );
  }

  if (selectedInviteeId) {
    return (
      <IncomingVolunteerViewInviteeScreen
        inviteeId={selectedInviteeId}
        goBack={() => {
          setSelectedInviteeId(undefined);
        }}
        reloadList={getIncomingVolunteersRefetch}
      />
    );
  }

  if (selectedApplicationId) {
    return (
      <IncomingVolunteerViewApplicantScreen
        selectedApplicationId={selectedApplicationId}
        goBack={() => {
          setSelectedApplicationId(undefined);
        }}
        reloadList={getIncomingVolunteersRefetch}
      />
    );
  }

  if (selectedVolunteerProfileId) {
    return (
      <IncomingVolunteerViewProfileScreen
        profileId={selectedVolunteerProfileId}
        goBack={() => {
          setSelectedVolunteerProfileId(undefined);
        }}
        reloadList={getIncomingVolunteersRefetch}
        setSnackbar={setSnackbar}
      />
    );
  }

  return (
    <React.Fragment>
      <IncomingVolunteersList
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        incomingVolunteers={incomingVolunteers}
        reload={getIncomingVolunteersRefetch}
        programs={programs}
        view={view}
        page={page}
        setView={setView}
        setPage={setPage}
        pagination={getIncomingVolunteersData?.vm?.incomingVolunteers?.pagination}
        handleExport={handleExport}
      />
    </React.Fragment>
  );
};

export { IncomingVolunteersScreen };
