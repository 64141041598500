import { useEndpointFetch } from '../../../global/network/useEndpointFetch';

export type ConflictingRosterType = Array<{
  activityId: string;
  activityDate: string;
  programId: string;
  activityName: string;
  conflictingSessions: Array<{
    sessionId: string;
    startTime: string;
    endTime: string;
    name: string;
  }>;
}>;

export const useGetConflictingRosters = () => {
  return useEndpointFetch<
    {
      volunteerId: string;
      activityDate: string;
      startTime: string;
      endTime: string;
    },
    { conflictingRosters: ConflictingRosterType }
  >('/vm/activity/roster/conflicting-rosters', { formData: true });
};
