import { gql } from '@apollo/client';
import { INCOMING_APPLICANT_FRAGMENT } from '../../../../../../../program-manager/volunteers/incoming-volunteers/incoming-volunteers-model.gql';

export const GET_WIDGET_VOLUNTEER_APPLICATIONS = gql`
  query GetWidgetVolunteerApplications(
    $status: String
    $program: String
    $type: String
    $range: String
    $widgetType: String
  ) {
    vm {
      incomingVolunteers(program: $program, status: $status, type: $type, range: $range, widgetType: $widgetType) {
        incomingVolunteers {
          incomingVolunteerId
          isInvited
          source
          incomingVolunteerId
          name
          email
          lastUpdated
          application {
            ...IncomingApplication
          }
        }
      }
    }
  }
  ${INCOMING_APPLICANT_FRAGMENT}
`;
