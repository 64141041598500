import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Close, Edit } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface RosterNotesDialogProps {
  rosterNotes?: string | null;
  open: boolean;
  edit?: boolean;
  onCancel: () => void;
  onSave: (rosterNotes: string) => void;
  title?: string;
  subject?: string;
}

export function RosterNotesDialog({
  rosterNotes,
  open,
  edit,
  onCancel,
  onSave: saveHandler,
  title,
  subject,
}: RosterNotesDialogProps) {
  const [state, setState] = React.useState(EditorState.createEmpty());
  const [isEditing, setEditing] = useState<boolean>();
  const { theme } = useCampfireTheme();

  React.useEffect(() => {
    if (!rosterNotes) {
      setState(EditorState.createEmpty());
      setEditing(true);
    } else {
      const blocksFromHTML = convertFromHTML(rosterNotes);
      const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      setState(EditorState.createWithContent(content));
      if (isEditing === undefined) {
        setEditing(!!edit);
      }
    }
  }, [rosterNotes]);

  const onSave = () => {
    saveHandler(stateToHTML(state.getCurrentContent()));
  };

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onCancel} style={{ zIndex: 2000 }}>
      <DialogTitle style={{ padding: '1.6em 2.5em 0.15em 2.5em' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display={'flex'}>
            <Typography variant='h3' style={{ fontWeight: 500, fontSize: '1.3em' }}>
              {title || 'Roster Note'}
            </Typography>
            {!isEditing ? (
              <TabletButton
                // data-track='actCnl-overview-status-dropdown'
                size='small'
                variant='outlined'
                color='primary'
                aria-controls='task-status-menu'
                aria-haspopup='true'
                onClick={() => setEditing(true)}
                startIcon={<Edit />}
                style={{
                  border: '1px solid #9e9e9e',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                  padding: '2px 7px',
                  color: theme.color.grey.neutral300,
                  marginLeft: '12px',
                }}
              >
                {'Edit'}
              </TabletButton>
            ) : null}
          </Box>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ padding: '0 2.5em 2.5em 2.5em' }}>
        <Typography style={{ color: '#00000099', fontWeight: 400 }}>
          {isEditing
            ? `You can include additional information for volunteers about this ${subject ||
                'roster'}. Only volunteers who are rostered on
          for this date will be able to see this information.`
            : `The following note has been included on this ${subject || 'roster'}`}
        </Typography>
        <Box marginTop='2rem'>
          <CampfireRichEditor editorState={state} setEditorState={setState} readOnly={!isEditing} />
        </Box>
      </DialogContent>
      {isEditing ? (
        <DialogActions style={{ padding: '0 2.5rem 2.5rem 2.5rem' }}>
          <Button variant='text' onClick={onCancel} style={{ textTransform: 'none' }}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={onSave}
            style={{ textTransform: 'none' }}
          >
            Save
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
