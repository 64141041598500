import { useContext } from 'react';
import { ProgramsContextInterface, ProgramsContext} from './ProgramsContext';

const useProgramsContext = (): ProgramsContextInterface => {
  const programsContext = useContext(ProgramsContext);

  if (!programsContext) throw new Error('useProgramsContext must be initiated within an ProgramsContextProvider');
  return programsContext;
};

export { useProgramsContext };
