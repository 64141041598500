import { gql } from '@apollo/client';

export const GET_PROFILE_SETTING = gql`
  query GetProfileSetting($profileId: String!) {
    vm {
      profile(profileId: $profileId) {
        profileId
        firstName
        lastName
        preferredName
        dateOfBirth
        email
        contactNumber
        aboutMe
        dateLastUpdated
        userPreference {
          userPreferenceId
          rosterView
        }
      }
    }
  }
`;
