import { TabletButton } from '@campfire/tablet-button';
import { Box, Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import { CloseRounded, MoreHoriz } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { ActionsHeader } from '../../../../../../common/ActionsHeader';
import { assertNever } from '../../../../../../common/functions/assert-never';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { ReportFieldActionMenuOptionType } from '../../../report-management-types';
import { REPORT_FIELD_ACTION_MENU_OPTIONS } from '../../../ReportManagementConsts';
import { FieldOption } from '../../report-field-types';
import { ReportField } from '../ReportFieldDetail';
import { ArchiveReportFieldDialog } from './report-field-detail-header-action-dialogs/ArchiveReportFieldDialog';
import { EditReportFieldDialog } from './report-field-detail-header-action-dialogs/EditReportFieldDialog';
import { MergeReportFieldsDialog } from './report-field-detail-header-action-dialogs/MergeReportFieldsDialog';
import { useReportFieldDetailHeaderActions } from './report-field-detail-header-actions';

const useStyles = makeStyles(() => {
  return {
    paper: {
      marginTop: 4,
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        background: '#282729',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  };
});

type Props = {
  reportField: ReportField;
  allActiveFieldOptions: Array<FieldOption>;
  getReportFieldsRefetch: () => void;
};

const getReportFieldActionMenuOptions = (archived: boolean): Array<ReportFieldActionMenuOptionType> => {
  if (archived) return REPORT_FIELD_ACTION_MENU_OPTIONS.filter((option) => option === 'reactivate');

  return REPORT_FIELD_ACTION_MENU_OPTIONS.filter((option) => option !== 'reactivate');
};

export const ReportFieldDetailHeader = (props: Props) => {
  const { reportField, allActiveFieldOptions, getReportFieldsRefetch } = props;
  const [reportFieldActionMenuAnchorEl, setReportFieldActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [editReportFieldDialogOpen, setEditReportFieldDialogOpen] = useState<boolean>(false);
  const [mergeReportFieldDialogOpen, setMergeReportFieldDialogOpen] = useState<boolean>(false);
  const [archiveReportFieldDialogOpen, setArchiveReportFieldDialogOpen] = useState<boolean>(false);

  const [query, setQuery] = useQueryParams({
    reportFieldId: StringParam,
  });

  const { theme, isMobile } = useCampfireTheme();
  const classes = useStyles();
  const { runReactivateReportField } = useReportFieldDetailHeaderActions();

  const handleOpenReportFieldActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReportFieldActionMenuAnchorEl(event.currentTarget);
  };

  const handleCloseReportFieldActionMenu = () => {
    setReportFieldActionMenuAnchorEl(null);
  };

  const handleActionMenuItemClick = (option: ReportFieldActionMenuOptionType) => {
    handleCloseReportFieldActionMenu();

    if (option === 'edit') {
      setEditReportFieldDialogOpen(true);
      return;
    }

    if (option === 'merge') {
      setMergeReportFieldDialogOpen(true);
      return;
    }

    if (option === 'archive') {
      setArchiveReportFieldDialogOpen(true);
      return;
    }

    if (option === 'reactivate') {
      runReactivateReportField({
        fieldId: reportField.fieldId,
        handleSuccess: () => {
          if (getReportFieldsRefetch) getReportFieldsRefetch();
          if (isMobile) setQuery({ ...query, reportFieldId: undefined });
        },
      });
      return;
    }
    assertNever(option);
  };

  const reportFieldMenuOptions = getReportFieldActionMenuOptions(Boolean(reportField.dateDeactivated));
  const isArchivable = !reportField.reportTypes.length || reportField.reportTypes.every((item) => item.dateDeactivated);

  return (
    <>
      <ActionsHeader title={reportField.name}>
        <>
          {reportField.dateDeactivated && (
            <TabletButton
              size='small'
              variant='outlined'
              color='primary'
              aria-controls='report-field-archive-status'
              aria-haspopup='true'
              disabled
              style={{
                border: '1px solid #9e9e9e',
                boxSizing: 'border-box',
                borderRadius: '4px',
                padding: '2px 7px',
                color: theme.color.grey.neutral300,
                marginRight: '8px',
                marginLeft: '8px',
                minWidth: '100px',
              }}
            >
              Archived
            </TabletButton>
          )}
          <Box
            style={{
              border: '1px solid #9e9e9e',
              boxSizing: 'border-box',
              borderRadius: '4px',
              marginRight: '10px',
            }}
          >
            <IconButton
              color='primary'
              aria-label='report-field-action-menu-button'
              component='span'
              onClick={handleOpenReportFieldActionMenu}
              style={{
                color: theme.color.grey.neutral300,
                padding: '0px 2px',
                fontSize: '26px',
                borderRadius: 'none',
              }}
            >
              <MoreHoriz fontSize='inherit' style={{ borderRadius: 'none' }} />
            </IconButton>
          </Box>
          <Menu
            id='report-field-action-menu'
            anchorEl={reportFieldActionMenuAnchorEl}
            keepMounted
            open={Boolean(reportFieldActionMenuAnchorEl)}
            onClose={handleCloseReportFieldActionMenu}
            getContentAnchorEl={null}
            PaperProps={{
              elevation: 0,
              style: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))',
                marginTop: 1.5,
                background: '#282729',
                color: '#C9C8CC',
              },
            }}
            PopoverClasses={{ paper: classes.paper }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {reportFieldMenuOptions.map((option: ReportFieldActionMenuOptionType) => (
              <MenuItem onClick={() => handleActionMenuItemClick(option)} key={option}>
                {capitalize(option)}
              </MenuItem>
            ))}
          </Menu>
        </>
        {isMobile ? (
          <Button
            style={{
              border: '1px solid #FF4F0F',
              boxSizing: 'border-box',
              borderRadius: '4px',
              textTransform: 'none',
              color: '#FF4F0F',
              padding: '3px 12px',
              marginLeft: '4px',
            }}
            onClick={() => setQuery({ ...query, reportFieldId: undefined })}
          >
            <CloseRounded style={{ fontSize: '18px' }} />
          </Button>
        ) : null}
      </ActionsHeader>
      {editReportFieldDialogOpen && (
        <EditReportFieldDialog
          isOpen={editReportFieldDialogOpen}
          reportField={reportField}
          refetch={getReportFieldsRefetch}
          handleClose={() => setEditReportFieldDialogOpen(false)}
        />
      )}
      {archiveReportFieldDialogOpen && (
        <ArchiveReportFieldDialog
          isOpen={archiveReportFieldDialogOpen}
          isArchivable={isArchivable}
          reportFieldId={reportField.fieldId}
          name={reportField.name}
          refetch={getReportFieldsRefetch}
          handleClose={() => setArchiveReportFieldDialogOpen(false)}
        />
      )}
      {mergeReportFieldDialogOpen && (
        <MergeReportFieldsDialog
          isOpen={mergeReportFieldDialogOpen}
          sourceReportFieldId={reportField.fieldId}
          sourceReportFieldType={reportField.__typename}
          allActiveFieldOptions={allActiveFieldOptions}
          refetch={getReportFieldsRefetch}
          handleClose={() => setMergeReportFieldDialogOpen(false)}
        />
      )}
    </>
  );
};
