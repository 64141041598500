import React, { useEffect } from 'react';
import { object as YupObject, string as YupString } from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { TabletButton } from '@campfire/tablet-button';
import { Field } from '@campfire/field';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useSupportContext } from '../SupportContext';

const validationSchema = YupObject().shape({
  categoryTitle: YupString().required('Please provide a name for the category'),
});

interface Props {
  open: boolean;
  close: () => void;
  handleNewCategoryId?: (categoryId: string) => void;
}
const CreateCategoryDialog = (props: Props) => {
  const { open, close, handleNewCategoryId } = props;
  const { isMobile } = useCampfireTheme();
  const { runSaveCategory, saveCategoryIsFulfilled } = useSupportContext();

  useEffect(() => {
    if (saveCategoryIsFulfilled) close();
  }, [saveCategoryIsFulfilled]);

  return (
    <Dialog open={open} fullWidth maxWidth='xs'>
      <Formik
        initialValues={{ categoryTitle: '' }}
        validationSchema={validationSchema}
        onSubmit={async (vals) => {
          const catId = await runSaveCategory({ title: vals.categoryTitle });
          if (handleNewCategoryId && catId) handleNewCategoryId(catId);
        }}
      >
        <Form>
          <DialogTitle style={{ paddingRight: isMobile ? 0 : 128 }}>{'Create a new Category'}</DialogTitle>
          <DialogContent>
            <Field
              data-track='resources-create-category-dialog-category-name-input'
              name='categoryTitle'
              label='Category Name'
              variant='outlined'
              helper
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <TabletButton data-track='resources-create-category-dialog-cancel' onClick={close}>
              {'Cancel'}
            </TabletButton>
            <TabletButton
              data-track='resources-create-category-dialog-submit-button'
              type='submit'
              variant='contained'
              color='primary'
            >
              {'Create Category'}
            </TabletButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export { CreateCategoryDialog };
