import React, { ReactNode, useState } from 'react';
import { Collapse, Divider, Box } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { HoverText } from '@campfire/hover-link';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

export const SectionCollapsible = ({
  sectionHeader,
  sectionActions,
  instruction,
  collapseContent,
  expandText,
  idStub,
}: {
  sectionHeader: ReactNode;
  sectionActions?: ReactNode;
  instruction?: ReactNode;
  collapseContent?: ReactNode;
  expandText: string;
  idStub: string;
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const { theme } = useCampfireTheme();

  return (
    <>
      <Box px={3} py={2}>
        <Box display='flex' justifyContent='space-between' alignItems='flex-start' width='100%'>
          {sectionHeader}
          {expand && sectionActions}
        </Box>
        {expand ? null : instruction}
        <Collapse in={expand}>{collapseContent}</Collapse>
      </Box>
      {expand ? (
        <Box borderBottom={`1px solid ${theme.palette.grey[200]}`} py={1} mt={1} mb={2} textAlign='center'>
          <HoverText
            data-track={`fs-volPrf-section-collapse-${idStub}`}
            color='primary'
            hoverColor='primary'
            onClick={() => setExpand(false)}
            variant='body2'
          >
            {'Hide Section'}
            <KeyboardArrowUp fontSize='small' color='primary' style={{ marginLeft: 8, verticalAlign: 'top' }} />
          </HoverText>
        </Box>
      ) : (
        <Box mb={3}>
          <Box display='flex' alignItems='center' justifyContent='center' mt={1}>
            <TabletButton
              data-track={`fs-volPrf-section-expand-${idStub}`}
              color='primary'
              style={{
                background: '#fff',
                boxShadow: '0 2px 5px 0 rgba(189,189,189,0.26), 0 2px 10px 0 rgba(65,65,65,0.16)',
              }}
              endIcon={<KeyboardArrowDown />}
              onClick={() => setExpand(true)}
            >
              {expandText}
            </TabletButton>
          </Box>
          <Divider style={{ marginTop: -14 }} />
        </Box>
      )}
    </>
  );
};
