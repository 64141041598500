import { encodeDate, unpackToDateTime } from '@campfire/hot-date';
import { Divider, Grid } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { scrollIntoView } from '../../../../../common/functions/scroll-functions';
import { useUser } from '../../../../../global/auth/useUser';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import {
  GET_MY_ELEMENTS_PERSONAL_PROFILE,
  GET_MY_ELEMENTS_VOLUNTEER_PROFILE,
} from './my-elements-personal-profile-model.gql';
import { MyElementsBulletinsTab } from './MyElementsBulletinsTab';
import { MyElementsPersonalCardTabsWrapper, TAB_ITEMS } from './MyElementsPersonalCardTabsWrapper';
import { MyElementsSingleBulletinView } from './MyElementsSingleBulletinView';
import { MyElementsVolunteerProfileBlock } from './MyElementsVolunteerProfileBlock';
import {
  GetMyElementsPersonalProfile,
  GetMyElementsPersonalProfileVariables,
} from './__generated__/GetMyElementsPersonalProfile';
import {
  GetMyElementsVolunteerProfile,
  GetMyElementsVolunteerProfileVariables,
} from './__generated__/GetMyElementsVolunteerProfile';

const MyElementsImpactTab = React.lazy(() => import('./MyElementsImpactTab'));

export interface Task {
  taskId: string;
  title: string;
  description: string;
  order: number;
  cake: {
    cakeId: string;
    cakeType: 'extra' | 'required';
  };
  dateLastUpdated: string;
  taskItems: {
    __typename: 'VOLUNTEER_TaskItemHeadingType' | 'VOLUNTEER_TaskItemContentType' | 'VOLUNTEER_TaskItemFieldType';
    taskItemId: string;
    optional?: boolean;
    field?: {
      taskFieldId: string;
    };
  }[];
}

export interface CompletedTask {
  completedTaskId: string;
  task: Task;
  dateLastUpdated: DateTime;
  taskFieldValues: {
    taskFieldValueId: string;
    dateLastUpdated: DateTime;
    dateRevoked: DateTime;
    field: {
      taskFieldId: string;
    };
  }[];
}

// Impact Options
export type MyElementsSelectTimeFrame = 'Last week' | 'Last month' | 'All-time';
export const myElementsSelectTimeFrameValues: MyElementsSelectTimeFrame[] = ['Last week', 'Last month', 'All-time'];

export const MyElementsPersonalCard = () => {
  const { isXs } = useCampfireTheme();
  const { getVolunteerIdentity } = useUser();
  const volunteerIdentity = getVolunteerIdentity();
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<MyElementsSelectTimeFrame>('All-time');
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(TAB_ITEMS.indexOf('Bulletin'));
  const [selectedBulletinId, setSelectedBulletinId] = useQueryParam('bulletinId', StringParam);
  const ref: React.RefObject<HTMLDivElement> = React.createRef();

  const [fetchPersonalProfile, { data: volunteerProfileQuery, loading: impactLoading, refetch }] = useCampfireLazyQuery<
    GetMyElementsPersonalProfile,
    GetMyElementsPersonalProfileVariables
  >(GET_MY_ELEMENTS_PERSONAL_PROFILE);

  const startDate = useMemo(() => {
    const now = DateTime.local();

    if (selectedTimeFrame === 'Last week') {
      return now.minus({ days: 7 });
    }

    if (selectedTimeFrame === 'Last month') {
      return now.minus({ months: 1 });
    }

    return DateTime.fromMillis(0);
  }, [selectedTimeFrame]);

  const endDate = useMemo(() => {
    return DateTime.local();
  }, [selectedTimeFrame]);

  useEffect(() => {
    fetchPersonalProfile({
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
        startDate: encodeDate(startDate),
        endDate: encodeDate(endDate),
      },
    });
  }, [startDate, endDate]);

  // Variables
  const attended = useMemo(() => {
    return volunteerProfileQuery?.vm.volunteer?.volunteerStatistics.attended ?? 0;
  }, [volunteerProfileQuery]);

  const hours = useMemo(() => {
    const operationalHours =
      volunteerProfileQuery?.vm.volunteer?.volunteerStatistics.sessionReportStatistics.operationalHours ?? 0;
    const additionalHours = parseFloat(
      `${volunteerProfileQuery?.vm.volunteer?.profile.additionalVolunteerHours ?? '0'}`
    );
    return operationalHours + additionalHours ?? 0;
  }, [volunteerProfileQuery]);

  const locations = useMemo(() => {
    return (
      volunteerProfileQuery?.vm.volunteer?.volunteerStatistics.sessionReportStatistics.activityLocations.length ?? 0
    );
  }, [volunteerProfileQuery]);

  const fieldStatistics = useMemo(() => {
    return (
      volunteerProfileQuery?.vm.volunteer?.volunteerStatistics.sessionReportStatistics.numericFieldStatistics ?? []
    );
  }, [volunteerProfileQuery]);

  const bulletins = useMemo(() => {
    return (
      volunteerProfileQuery?.vm.bulletins.sort((a, b) =>
        unpackToDateTime(a.dateCreated) > unpackToDateTime(b.dateCreated) ? -1 : 1
      ) ?? []
    );
  }, [volunteerProfileQuery]);

  const unreadBulletins = useMemo(() => {
    return bulletins.filter((bulletin) => !bulletin.hasVolunteerViewedBulletin).length;
  }, [bulletins]);

  const selectedBulletin = useMemo(() => {
    if (!selectedBulletinId || !bulletins.length) return undefined;
    return bulletins.find((bulletin) => bulletin.bulletinId === selectedBulletinId);
  }, [selectedBulletinId, bulletins]);

  const { data: volunteerInfo, loading: initialLoading } = useCampfireQuery<
    GetMyElementsVolunteerProfile,
    GetMyElementsVolunteerProfileVariables
  >(GET_MY_ELEMENTS_VOLUNTEER_PROFILE, {
    options: {
      variables: {
        volunteerId: volunteerIdentity.volunteerId,
      },
    },
  });

  const {
    user: { userIdentityService },
  } = useUser();

  const volunteerPosition: string = useMemo(() => {
    if (!volunteerInfo?.vm.volunteer) {
      return 'General Volunteer';
    }

    const { isProgramManager, isVmAdmin } = userIdentityService;

    if (isVmAdmin) {
      return 'Admin';
    }

    if (isProgramManager) {
      return 'Program Manager';
    }

    const isActivityLeader = volunteerInfo.vm.volunteer.activityEnrolments.some(({ position }) => position === 'al');

    if (isActivityLeader) {
      return 'Activity Leader';
    }

    return 'General Volunteer';
  }, [volunteerInfo, userIdentityService]);

  return (
    <>
      <div ref={ref} />
      <MyElementsVolunteerProfileBlock
        position={volunteerPosition ?? ''}
        initialLoading={initialLoading}
        avatarUrl={volunteerInfo?.vm?.volunteer?.profile.avatarUrl ?? ''}
        preferredName={volunteerInfo?.vm.volunteer?.profile.preferredName}
        lastName={volunteerInfo?.vm.volunteer?.profile.lastName}
      />
      {!isXs ? <Divider /> : null}

      <Grid container direction='row'>
        {selectedBulletin ? (
          <MyElementsSingleBulletinView
            bulletin={selectedBulletin}
            onClose={() => setSelectedBulletinId(undefined)}
            refetch={refetch}
          />
        ) : (
          <MyElementsPersonalCardTabsWrapper
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            unreadBulletins={unreadBulletins}
          >
            {selectedTabIndex === 0 && (
              <MyElementsBulletinsTab
                initialLoading={initialLoading}
                impactLoading={impactLoading}
                bulletins={bulletins}
                setSelectedBulletinId={setSelectedBulletinId}
                scrollToTop={() => scrollIntoView(ref, { behavior: 'smooth' }, true)}
              />
            )}
            {selectedTabIndex === 1 && (
              <MyElementsImpactTab
                initialLoading={initialLoading}
                impactLoading={impactLoading}
                teams={volunteerInfo?.vm.volunteer?.activityEnrolments.length ?? 0}
                orgName={volunteerInfo?.orgName ?? ''}
                selectedTimeFrame={selectedTimeFrame}
                setSelectedTimeFrame={setSelectedTimeFrame}
                hours={hours}
                attended={attended}
                locations={locations}
                fieldStatistics={fieldStatistics}
              />
            )}
          </MyElementsPersonalCardTabsWrapper>
        )}
      </Grid>
    </>
  );
};
