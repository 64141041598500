import { Box } from '@material-ui/core';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { IncidentManagementDesktopScreenHeader } from './screen-header/IncidentManagementDesktopScreenHeader';
import { IncidentManagementMobileScreenHeader } from './screen-header/IncidentManagementMobileScreenHeader';

interface IncidentManagementScreenHeaderWrapperProps {
  children: React.ReactNode;
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  handleExport: () => void;
}
const IncidentManagementScreenHeaderWrapper = (props: IncidentManagementScreenHeaderWrapperProps) => {
  const [query] = useQueryParams({
    incidentId: StringParam,
  });

  const { children } = props;
  const { isMobile } = useCampfireTheme();

  return (
    <Box display='flex' flexDirection='column' flex={1} overflow='hidden'>
      {isMobile ? (
        <IncidentManagementMobileScreenHeader {...props} hide={Boolean(query?.incidentId)} />
      ) : (
        <IncidentManagementDesktopScreenHeader {...props} />
      )}
      {children}
    </Box>
  );
};

export { IncidentManagementScreenHeaderWrapper };
