import { useState } from 'react';
import { useBetween } from 'use-between';

const usePromptState = () => {
  const [promptVolunteerId, setPromptVolunteerId] = useState<string>('');
  const [promptSessionId, setPromptSessionId] = useState<string>('');
  const [hidePromptForDate, setHidePromptForDate] = useState<boolean>(false);

  return {
    promptVolunteerId,
    setPromptVolunteerId,
    promptSessionId,
    setPromptSessionId,
    hidePromptForDate,
    setHidePromptForDate,
  };
};

export const useSharedPromptState = () => useBetween(usePromptState);
