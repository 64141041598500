import { CampfireRichEditor } from '@campfire/campfire-rich-editor/lib';
import { FileUploadGallery, UploadedFile } from '@campfire/file-upload-gallery';
import { HoverText } from '@campfire/hover-link/lib';
import { Grid, Typography } from '@material-ui/core';
import { convertFromRaw, EditorState } from 'draft-js';
import React, { useMemo } from 'react';
import { TaskFormItem_VOLUNTEER_TaskItemContentType as TaskItemContentType } from '../../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';
import { useFormBuilderDialogContext } from '../../FormBuilderDialogContext';

interface Props {
  data: TaskItemContentType;
}

export const ContentTaskItem = (props: Props) => {
  const { data } = props;
  const { editContent } = useFormBuilderDialogContext();
  const content = useMemo(() => EditorState.createWithContent(convertFromRaw(JSON.parse(data.content))), [
    data.content,
  ]);

  const hasRichContent = content.getCurrentContent().hasText();
  const uploadedFiles: Array<UploadedFile> | undefined = useMemo(() => {
    const pulledAttachments = (data?.attachments ?? [])
      // .filter((attachment) =>
      //   data?.removedAttachmentIds ? !data?.removedAttachmentIds.includes(attachment.attachmentId) : true
      // )
      .map((attachment: any) => ({
        url: attachment.objectUrl || attachment.url,
        fileId: attachment.attachmentId,
        name: attachment.name,
      }));
    // const uploadedAttachments = (data?.addedAttachments ?? []).map((attachment) => {
    //   return {
    //     url: attachment.objectUrl,
    //     fileId: attachment.id,
    //     name: attachment.name,
    //   };
    // });
    return pulledAttachments;
  }, [data.attachments]);

  return (
    <Grid container spacing={1} style={{ padding: 24 }}>
      <Grid item xs>
        <Typography variant='body2' color='textSecondary'>
          {'Content'}
        </Typography>
      </Grid>
      <Grid item>
        <HoverText color='primary' onClick={() => editContent(data)}>
          {'Edit content'}
        </HoverText>
      </Grid>
      <Grid item xs={12}>
        {hasRichContent && <CampfireRichEditor editorState={content} />}
      </Grid>
      <Grid item xs>
        {uploadedFiles?.length ? (
          <>
            <Typography variant='body2' color='textSecondary' style={{ fontWeight: 'bold' }}>
              {'Attachments'}
            </Typography>
            <FileUploadGallery allowMultiple uploadedFiles={uploadedFiles ?? []} viewOnly />
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};
