import React, { useState, useMemo, useEffect, useRef } from 'react';
import { cloneDeep, noop } from 'lodash';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import {
  CloseRounded,
  ExpandMore,
  FileCopy,
  InsertDriveFile,
  PersonAdd,
  PostAdd,
  Settings,
  SettingsBackupRestore,
} from '@material-ui/icons';
import { Badge, Box, Button, Divider, IconButton, Paper, Typography } from '@material-ui/core';
import { useBetween } from 'use-between';
import { unpackToDateTime } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { TabletButton } from '@campfire/tablet-button';
import { SmsConfirmationInfo } from './sms-confirmation-info/SmsConfirmationInfo';
import { Roster } from './roster/Roster';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import {
  GetRosterSmsConfirmationData,
  GetRosterSmsConfirmationDataVariables,
} from './sms-confirmation-info/__generated__/GetRosterSmsConfirmationData';
import { GET_ROSTER_SMS_CONFIRMATION_DATA } from './sms-confirmation-info/roster-sms-confirmation-model.gql';
import { RosterFilters } from './filters/RosterFilters';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { CurrentRosterState, RowAction, UpdatedRostering, Volunteer } from './roster/roster-types';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import {
  getInitRosterState,
  getRosteringsWithChanges,
  getUpdatedRosterState,
  parseAddedVolunteers,
  parseEnrolments,
  parseRosterings,
  useSaveDraftRosterFetch,
  useSaveRosterNotes,
  useUnpublishRosterFetch,
} from './roster/roster-functions';
import {
  GetRosterScreenRosterData,
  GetRosterScreenRosterDataVariables,
} from './roster/__generated__/GetRosterScreenRosterData';
import { GET_ROSTER_SCREEN_PROGRAM_VOLUNTEERS_DATA, GET_ROSTER_SCREEN_ROSTER_DATA } from './roster/rosters-model.gql';
import {
  GetRosterScreenProgramVolunteersData,
  GetRosterScreenProgramVolunteersDataVariables,
  GetRosterScreenProgramVolunteersData_vm_program_activeVolunteers as ActiveVolunteerType,
} from './roster/__generated__/GetRosterScreenProgramVolunteersData';
import { MobileRoster } from '../mobile-roster/MobileRoster';
import { AddVolunteerDialog } from './roster/AddVolunteerDialog';
import { GetRostersSidebarData_vm_activities_VOLUNTEER_NonRecurringActivityType_rosterTemplates as RosterTemplate } from '../__generated__/GetRostersSidebarData';
import { CancelledActivityInfo } from './CancelledActivityInfo';
import {
  useSaveUnavailabilityFetch,
  useWithdrawUnavailabilityFetch,
} from '../../activities/activity-timeline/activity-timeline-actions';
import { ClosedActivityInfo } from './ClosedActivityInfo';
import { useDeepEffect } from '../../../../hooks/useDeepEffect';
import { TooltipMenu } from '../../../../common/tooltip-menu';
import { useStyles } from '../styles';
import { useSharedPagination } from '../usePagination';
import { RosterScreenDraftRoster_sessionNotes as SessionNoteType } from './roster/__generated__/RosterScreenDraftRoster';
import { RosterNotesDialog } from '../common/RosterNotesDialog';
import { useCopyLinkAction } from '../../../../common/CopyToClipboard';
import { SelectVolunteersDialog } from '../../../../common/select-volunteers/SelectVolunteersDialog';

interface Props {
  showFilters: boolean;
  rosterTemplate?: RosterTemplate;
  handleEditRosterTemplate: () => void;
  cancelActivity?: () => void;
  hasRosters: boolean;
  handleReactivateActivityRefetch?: () => void;
  onSetQuery: (arg: any) => void;
  query: any;
  isRosterCompleted: boolean;
  activityStatus?: string;
  setShowCancelActivityDialog?: (x: boolean) => void;
  loading: boolean;
}

const useGlobalRosterStatusRefetch = () => {
  const [globalRosterStatusRefetch, setGlobalRosterStatusRefetch] = useState<any>();
  return {
    globalRosterStatusRefetch,
    setGlobalRosterStatusRefetch,
  };
};

export const useSharedGlobalRefetch = () => useBetween(useGlobalRosterStatusRefetch);

export const RosterMainSection = (props: Props) => {
  const {
    showFilters,
    rosterTemplate,
    handleEditRosterTemplate,
    cancelActivity,
    hasRosters,
    handleReactivateActivityRefetch,
    onSetQuery,
    query,
    isRosterCompleted,
    activityStatus,
    setShowCancelActivityDialog = noop,
    loading: sideBarLoading,
  } = props;
  const [publishedRoster, setPublishedRoster] = useState<Volunteer[] | undefined>();
  const [draftRoster, setDraftRoster] = useState<Volunteer[] | undefined>();
  const [currentRosterState, setCurrentRosterState] = useState<CurrentRosterState>('published');
  const [updatedRosterings, setUpdatedRosterings] = useState<UpdatedRostering[]>([]);
  const [openAddVolunteersDialog, setOpenAddVolunteersDialog] = useState(false);
  const { isMobile, isMd, theme } = useCampfireTheme();
  const { setSnackbar } = useSnackbar();
  const saveDraftRoster = useSaveDraftRosterFetch();
  const saveRosterNotes = useSaveRosterNotes();
  const { setGlobalRosterStatusRefetch } = useSharedGlobalRefetch();
  const [rosterNotes, setRosterNotes] = React.useState<string | null | undefined>();
  const [sessionNotes, setSessionNotes] = React.useState<Array<SessionNoteType>>([]);
  const [showRosterNotes, setShowRosterNotes] = React.useState(false);
  const { setPage } = useSharedPagination();
  const { handleCopyLinkClick } = useCopyLinkAction();
  const componentRef = useRef<HTMLDivElement>(null);

  const [getRosterData, { data: rosterData, loading, refetch }] = useCampfireLazyQuery<
    GetRosterScreenRosterData,
    GetRosterScreenRosterDataVariables
  >(GET_ROSTER_SCREEN_ROSTER_DATA);

  const [getProgramVolunteersData, { data: programVolunteersData }] = useCampfireLazyQuery<
    GetRosterScreenProgramVolunteersData,
    GetRosterScreenProgramVolunteersDataVariables
  >(GET_ROSTER_SCREEN_PROGRAM_VOLUNTEERS_DATA);

  const [getRosterSmsConfirmationData, { data: smsData, refetch: refetchSmsConfirmationData }] = useCampfireLazyQuery<
    GetRosterSmsConfirmationData,
    GetRosterSmsConfirmationDataVariables
  >(GET_ROSTER_SMS_CONFIRMATION_DATA);

  const createUnavailability = useSaveUnavailabilityFetch();
  const removeUnavailability = useWithdrawUnavailabilityFetch();

  const sessions = useMemo(
    () =>
      rosterData?.vm.activity?.sessions?.map((session) => ({
        ...session,
        reports: rosterData?.vm.activity?.activityReport?.sessionReports?.filter(
          (sessionReport) => sessionReport.session.sessionId === session.sessionId
        ),
      })) ?? [],
    [rosterData]
  );

  useDeepEffect(() => {
    setSessionNotes(rosterData?.vm.draftRoster?.sessionNotes || []);
  }, [rosterData]);

  const onChangeSessionNote = (sessionId: string, notes: string) => {
    setCurrentRosterState('not-saved');
    if (!sessionNotes) {
      setSessionNotes([{ session: { sessionId }, notes }] as Array<SessionNoteType>);
    }
    if (sessionNotes?.some((sessionNote) => sessionNote.session.sessionId === sessionId)) {
      setSessionNotes(
        sessionNotes.map((sessionNote) => {
          if (sessionNote.session.sessionId === sessionId) {
            return {
              ...sessionNote,
              notes,
            };
          }
          return sessionNote;
        })
      );
    } else {
      setSessionNotes(sessionNotes?.concat({ session: { sessionId }, notes } as SessionNoteType));
    }
  };

  const draftRosterings = useMemo(() => rosterData?.vm.draftRoster?.draftRosterings ?? [], [rosterData]);
  const publishedRosterings = useMemo(() => rosterData?.vm.draftRoster?.publishedRoster?.activeRosterings ?? [], [
    rosterData,
  ]);
  const enrolments = useMemo(() => {
    return rosterData?.vm.activity?.activityEnrolments
      ? parseEnrolments(rosterData.vm.activity.activityEnrolments)
      : [];
  }, [rosterData]);
  const programVolunteers = useMemo(() => programVolunteersData?.vm.program?.activeVolunteers ?? [], [
    programVolunteersData,
  ]);
  const orgLogo = rosterData?.orgLogo;

  React.useEffect(() => {
    setPage(0);
  }, [query.activity, query.activityDate]);

  useEffect(() => requestData(), [query]);
  useEffect(() => getProgramVolunteers(rosterData?.vm.activity?.program?.programId), [rosterData]);
  useEffect(() => initialiseRosters(), [enrolments, draftRosterings, publishedRosterings]);
  useEffect(() => initialiseCurrentRosterState(), [publishedRoster]);
  useEffect(() => handleUpdatedRosteringsList(), [draftRoster]);

  useDeepEffect(() => {
    setRosterNotes(rosterData?.vm.draftRoster?.rosterNotes || rosterData?.vm.activity?.publishedRoster?.rosterNotes);
  }, [rosterData]);

  const requestData = () => {
    if (!query.activityDate || !query.activityId) return;
    getRosterSmsConfirmationData({
      variables: {
        activityId: query.activityId,
        activityDate: query.activityDate,
      },
    });

    getRosterData({
      variables: {
        activityId: query.activityId,
        activityDate: query.activityDate,
      },
    });
  };

  const getProgramVolunteers = (programId?: string) => {
    if (!programId) return;
    getProgramVolunteersData({
      variables: {
        programId: programId,
      },
    });
  };

  const initialiseRosters = () => {
    const initRoster = parseRosterings(draftRosterings, publishedRosterings, enrolments);
    setPublishedRoster(cloneDeep(initRoster));
    setDraftRoster(cloneDeep(initRoster));
  };

  const initialiseCurrentRosterState = () => {
    if (!publishedRoster) return;
    setCurrentRosterState(getInitRosterState(publishedRoster));
  };

  const cancelledRoster = useMemo(() => {
    return rosterData?.vm?.activity?.cancelledActivities?.find((ca) => ca.activityDate === query.activityDate);
  }, [rosterData]);

  const updateRostering = (volunteerId: string, sessionId: string, action: RowAction) => {
    if (!draftRoster || !publishedRoster) return;

    const rosteringIndex = draftRoster.findIndex((r) => r.volunteerId === volunteerId);
    if (rosteringIndex === -1) return;

    const tempRoster = [...draftRoster];
    const targetRostering = tempRoster[rosteringIndex];

    if (action === 'add') {
      targetRostering.draftedSessionIds.push(sessionId);
      setDraftRoster(tempRoster);
    }
    if (action === 'remove') {
      targetRostering.draftedSessionIds = targetRostering.draftedSessionIds.filter((sid) => sid !== sessionId);
      setDraftRoster(tempRoster);
    }
    setCurrentRosterState(getUpdatedRosterState(draftRoster, publishedRoster));
  };

  const handleUpdatedRosteringsList = () => {
    if (!draftRoster) return;
    setUpdatedRosterings(getRosteringsWithChanges(draftRoster));
  };

  const handlePublishRoster = (immediatePublish: boolean) => {
    if (!draftRoster || !query?.activityId || !query?.activityDate) return;

    const filteredRoster = draftRoster.filter((d) => d.draftedSessionIds.length);

    const newRoster = {
      activityId: query.activityId,
      activityDate: query.activityDate,
      draftRosterings: filteredRoster?.map((r) => ({
        volunteerId: r.volunteerId,
        sessionIds: r.draftedSessionIds,
      })),
      immediatePublish: immediatePublish,
      rosterNotes,
      sessionNotes: sessionNotes.map((sessionNote) => ({
        sessionId: sessionNote.session.sessionId,
        notes: sessionNote.notes || '',
      })),
    };

    saveDraftRoster
      .run({ ...newRoster })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: immediatePublish ? 'Unable to publish roster' : 'Unable to save draft',
            variant: 'error',
          });
          return;
        }
        setGlobalRosterStatusRefetch(true);
        setSnackbar({
          open: true,
          message: immediatePublish ? 'Roster published' : 'Draft roster saved',
          variant: 'success',
        });
        if (refetch && query.activityId && query.activityDate) {
          refetch();
        }
        setGlobalRosterStatusRefetch(false);
        handleRefetchSmsConfirmationData();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: immediatePublish ? 'Unable to publish roster' : 'Unable to save draft',
          variant: 'error',
        })
      );
  };

  const onSaveRosterNotes = (newRosterNotes: string) => {
    if (rosterData?.vm.draftRoster === null) {
      setRosterNotes(newRosterNotes);
      setCurrentRosterState('not-saved');
    } else {
      saveRosterNotes
        .run({
          activityId: query.activityId,
          activityDate: query.activityDate,
          rosterNotes: newRosterNotes,
        })
        .then(() => setRosterNotes(newRosterNotes));
    }
  };

  const handleChangeAvailability = (volunteerId: string) => {
    const activityEnrolmentId = rosterData?.vm.activity?.activityEnrolments.find(
      (enrolment) => enrolment.volunteer.volunteerId === volunteerId
    )?.activityEnrolmentId;
    if (!activityEnrolmentId) {
      return;
    }
    const isCurrentlyUnAvailable = draftRoster?.find((dRoster) => dRoster.volunteerId === volunteerId)?.unavailable;
    const activityUnavailabilityId = rosterData?.vm.activity?.activityEnrolments.find(
      (enrolment) => enrolment.volunteer.volunteerId === volunteerId
    )?.unavailability?.activityUnavailabilityId;
    if (isCurrentlyUnAvailable && activityUnavailabilityId) {
      removeUnavailability.run({ activityUnavailabilityId }).then(() => {
        setSnackbar({
          open: true,
          message: 'Set availability success',
          variant: 'success',
        });
        setGlobalRosterStatusRefetch(true);
        if (refetch && query.activityId && query.activityDate) {
          refetch();
        }
        setGlobalRosterStatusRefetch(false);
      });
    }
    if (!isCurrentlyUnAvailable) {
      createUnavailability.run({ activityEnrolmentId, activityDate: query.activityDate }).then(() => {
        if (refetch && query.activityId && query.activityDate) {
          refetch();
        }
        setSnackbar({
          open: true,
          message: 'Set unavailability success',
          variant: 'success',
        });
        setGlobalRosterStatusRefetch(true);
        if (refetch && query.activityId && query.activityDate) {
          refetch();
        }
        setGlobalRosterStatusRefetch(false);
      });
    }
  };

  const handleDiscardChanges = () => {
    setDraftRoster(cloneDeep(publishedRoster));
    initialiseCurrentRosterState();
    setRosterNotes(rosterData?.vm.draftRoster?.rosterNotes || '');
    setSessionNotes(rosterData?.vm.draftRoster?.sessionNotes || []);
  };

  const handleAddedVolunteers = (addedVolunteers: ActiveVolunteerType[]) => {
    if (!draftRoster || !publishedRoster) return;
    setDraftRoster([...draftRoster, ...parseAddedVolunteers(addedVolunteers)]);
    setPublishedRoster([...publishedRoster, ...parseAddedVolunteers(addedVolunteers)]);
  };

  const handleRefetchSmsConfirmationData = () => {
    if (!refetchSmsConfirmationData || !query.activityId || !query.activityDate) return;
    refetchSmsConfirmationData();
  };

  const handleSuccessRefetch = () => {
    if (handleReactivateActivityRefetch) handleReactivateActivityRefetch();
  };

  const displayedSMSInfo = !(cancelledRoster || isRosterCompleted);
  const afterActivityClosedDate = () => {
    if (query.activityDate) {
      return (
        unpackToDateTime(rosterData?.vm.activity?.closedActivity?.endDate) <=
        DateTime.fromFormat(query.activityDate, 'yyyy-MM-dd')
      );
    }
    return false;
  };

  const isRosterCancelled = !!cancelledRoster || afterActivityClosedDate();

  const showAddVolunteer = !isRosterCancelled && !isRosterCompleted;
  const [optionOpen, setOptionOpen] = useState(false);

  const runUnpublishRoster = useUnpublishRosterFetch();
  const { activityId, activityDate } = query;

  const classes = useStyles();

  const showRosterNotesHandler = () => {
    setOptionOpen(false);
    setShowRosterNotes(true);
  };

  const handleSaveRosterNotes = (newRosterNotes: string) => {
    onSaveRosterNotes(newRosterNotes);
    setShowRosterNotes(false);
  };

  const runUnpublishActivity = () => {
    runUnpublishRoster
      .run({
        activityId,
        activityDate,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to unpublish roster',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Unpublished roster',
          variant: 'success',
        });
        if (handleSuccessRefetch) handleSuccessRefetch();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to unpublish roster',
          variant: 'error',
        })
      );
  };

  return isMobile ? (
    <Box
      position={'relative'}
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      overflow={'scroll'}
      padding={'30px'}
    >
      <LinearProgressOverlay isLoading={loading || sideBarLoading} />
      <Box display={'flex'} flexDirection={'column'} padding={'8px'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          {rosterTemplate ? (
            <IconButton
              aria-label='roster-setting-menu'
              component='span'
              onClick={handleEditRosterTemplate}
              style={{
                color: theme.color.grey.neutral300,
                padding: '0px 4px',
                fontSize: '26px',
                border: '1px solid #9e9e9e',
                boxSizing: 'border-box',
                borderRadius: '4px',
                marginLeft: '4px',
              }}
            >
              <Settings fontSize={'inherit'} />
            </IconButton>
          ) : null}
          {/* <Box
            display='flex'
            bgcolor={theme.palette.primary.main}
            style={{
              border: `1px solid ${theme.palette.primary.main}`,
              boxSizing: 'border-box',
              borderRadius: '4px',
              marginLeft: '4px',
              backgroundColor: theme.color.secondary.main900,
            }}
          >
            <Link to={getCreateRosterTemplateLink(query.activityId)} style={{ textDecoration: 'none' }}>
              <IconButton
                style={{ color: theme.palette.primary.main, padding: '4px', fontSize: '26px', borderRadius: 'none' }}
              >
                <ControlPoint fontSize={'inherit'} style={{ color: '#fff' }} />
              </IconButton>
            </Link>
          </Box> */}
          {(!!rosterData?.vm.draftRoster?.publishedRoster || showAddVolunteer) && (
            <Box display='flex'>
              <TooltipMenu
                open={optionOpen}
                onClose={() => {
                  setOptionOpen(false);
                }}
                title={
                  <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ padding: '0 5px' }}>
                    {!cancelledRoster ? (
                      <Button onClick={cancelActivity} className={classes.cancelledButton} startIcon={<CloseRounded />}>
                        Cancel Activity
                      </Button>
                    ) : null}
                    {!!rosterData?.vm.draftRoster?.publishedRoster && currentRosterState === 'published' && (
                      <Button
                        className={classes.cancelledButton}
                        startIcon={<SettingsBackupRestore />}
                        onClick={runUnpublishActivity}
                      >
                        Unpublish
                      </Button>
                    )}
                    {showAddVolunteer && (
                      <Button
                        className={classes.edit}
                        startIcon={<PersonAdd />}
                        onClick={() => setOpenAddVolunteersDialog(true)}
                      >
                        Add new volunteer
                      </Button>
                    )}
                    <Button className={classes.edit} onClick={showRosterNotesHandler} startIcon={<PostAdd />}>
                      {rosterNotes ? 'Edit' : 'Add'} roster note
                    </Button>
                    <Button className={classes.edit} onClick={handleCopyLinkClick} startIcon={<FileCopy />}>
                      Copy Link
                    </Button>
                  </Box>
                }
              >
                <TabletButton
                  size='small'
                  variant='outlined'
                  color='primary'
                  aria-controls='options-menu'
                  aria-haspopup='true'
                  onClick={() => setOptionOpen(true)}
                  endIcon={<ExpandMore />}
                  style={{
                    height: '100%',
                    border: '1px solid #9e9e9e',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    padding: '2px 7px',
                    marginRight: '10px',
                    color: theme.color.grey.neutral300,
                  }}
                >
                  Options
                </TabletButton>
              </TooltipMenu>
            </Box>
          )}

          <Button
            style={{
              border: '1px solid #FF4F0F',
              boxSizing: 'border-box',
              borderRadius: '4px',
              textTransform: 'none',
              color: '#FF4F0F',
              padding: isMobile || isMd ? '3px 12px' : '7px 12px',
            }}
            onClick={() => onSetQuery({ activityDate: undefined, activityId: undefined })}
          >
            <CloseRounded style={{ fontSize: '18px' }} />
            <Typography
              style={{
                marginLeft: '4px',
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '19px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'inherit',
                marginBottom: '1px',
              }}
            >
              Close
            </Typography>
          </Button>
        </Box>
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '20px',
            paddingTop: '20px',
            color: theme.color.grey.neutralBrand800,
          }}
        >
          {rosterData?.vm.activity?.name}
        </Typography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography
            style={{
              fontSize: '14px',
              paddingTop: '2px',
              paddingBottom: '5px',
              color: theme.color.grey.neutralBrand800,
            }}
          >
            {DateTime.fromISO(query.activityDate).toFormat('d LLLL y')}
          </Typography>
          {rosterNotes ? (
            <Badge
              onClick={() => setShowRosterNotes(true)}
              style={{
                backgroundColor: theme.color.grey.neutral200,
                color: '#ffffff',
                padding: '6px',
                marginLeft: '15px',
                borderRadius: '3px',
                height: 'fit-content',
                cursor: 'pointer',
              }}
            >
              <InsertDriveFile style={{ fontSize: '16px' }} />
              <Typography style={{ fontSize: '12px', paddingLeft: '4px', fontWeight: 500, paddingRight: '2px' }}>
                Note Added
              </Typography>
            </Badge>
          ) : null}
        </Box>
      </Box>
      <Divider />
      {cancelledRoster && (
        <CancelledActivityInfo
          cancelledRoster={cancelledRoster}
          handleReactivateActivityRefetch={handleReactivateActivityRefetch}
        />
      )}
      {displayedSMSInfo && <SmsConfirmationInfo draftRoster={smsData?.vm.draftRoster ?? undefined} />}
      <MobileRoster
        smsConfirmationRefetch={() => handleRefetchSmsConfirmationData()}
        sessions={sessions}
        draftRoster={draftRoster}
        updateRostering={updateRostering}
        updatedRosterings={updatedRosterings}
        currentRosterState={currentRosterState}
        hasPublishedRoster={!!rosterData?.vm.draftRoster?.publishedRoster}
        handlePublishRoster={handlePublishRoster}
        handleDiscardChanges={handleDiscardChanges}
        setOpenAddVolunteersDialog={setOpenAddVolunteersDialog}
        isRosterCancelled={!!cancelledRoster || afterActivityClosedDate()}
        isRosterCompleted={isRosterCompleted}
        onSubmitReport={refetch}
        publishedRoster={rosterData?.vm.activity?.publishedRoster}
        activityDate={query.activityDate}
        onChangeAvailability={handleChangeAvailability}
        sessionNotes={sessionNotes}
        onChangeSessionNote={onChangeSessionNote}
      />
      <AddVolunteerDialog
        open={openAddVolunteersDialog}
        close={() => setOpenAddVolunteersDialog(false)}
        onSubmit={handleAddedVolunteers}
        volunteers={programVolunteers}
        ignoredVolunteerIds={draftRoster?.map((r) => r.volunteerId) ?? []}
      />
      {showRosterNotes && (
        <RosterNotesDialog
          open
          rosterNotes={rosterNotes}
          onCancel={() => setShowRosterNotes(false)}
          onSave={handleSaveRosterNotes}
        />
      )}
    </Box>
  ) : (
    <Box display='flex' flex={1} flexDirection='column' style={{ overflow: 'auto' }}>
      <RosterFilters showFilters={showFilters} onSetQuery={onSetQuery} query={query} />
      {hasRosters || sideBarLoading ? (
        <Box flex={1} display='flex' flexDirection='column' minHeight={0}>
          {cancelledRoster && (
            <CancelledActivityInfo
              cancelledRoster={cancelledRoster}
              handleReactivateActivityRefetch={handleReactivateActivityRefetch}
            />
          )}
          {rosterData?.vm.activity?.closedActivity && afterActivityClosedDate() && (
            <ClosedActivityInfo
              closedActivity={rosterData.vm.activity.closedActivity}
              activityName={rosterData.vm.activity.name}
            />
          )}
          {displayedSMSInfo && <SmsConfirmationInfo draftRoster={smsData?.vm.draftRoster ?? undefined} />}
          <Roster
            onChangeAvailability={handleChangeAvailability}
            smsConfirmationRefetch={() => handleRefetchSmsConfirmationData()}
            loading={loading || sideBarLoading}
            sessions={sessions}
            draftRoster={draftRoster}
            updateRostering={updateRostering}
            updatedRosterings={updatedRosterings}
            currentRosterState={currentRosterState}
            hasPublishedRoster={!!rosterData?.vm.draftRoster?.publishedRoster}
            handlePublishRoster={handlePublishRoster}
            handleDiscardChanges={handleDiscardChanges}
            setOpenAddVolunteersDialog={setOpenAddVolunteersDialog}
            isRosterCancelled={!!cancelledRoster || afterActivityClosedDate()}
            isRosterCompleted={isRosterCompleted}
            onSubmitReport={refetch}
            publishedRoster={rosterData?.vm.activity?.publishedRoster}
            rosterNotes={rosterNotes}
            onSaveRosterNotes={onSaveRosterNotes}
            activityDate={query.activityDate}
            activityName={rosterData?.vm.activity?.name ?? ''}
            activityStatus={activityStatus ?? ''}
            activityId={rosterData?.vm.activity?.activityId ?? ''}
            setShowCancelActivityDialog={setShowCancelActivityDialog}
            handleSuccessRefetch={handleSuccessRefetch}
            sessionNotes={sessionNotes}
            onChangeSessionNote={onChangeSessionNote}
            componentRef={componentRef}
            orgLogo={orgLogo}
          />
        </Box>
      ) : (
        <Paper
          elevation={1}
          style={{
            height: '44px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.warning.light,
          }}
        >
          <Typography variant={'body1'}>
            {
              'There are no rosters for the selected time period. You can change the start and end dates using the filters.'
            }
          </Typography>
        </Paper>
      )}

      {openAddVolunteersDialog && rosterData?.vm.activity && (
        <SelectVolunteersDialog
          open={openAddVolunteersDialog}
          enableGroupByActivity={false}
          activity={rosterData.vm.activity}
          handleClose={() => setOpenAddVolunteersDialog(false)}
          handleAdd={handleAddedVolunteers}
          ignoredVolunteerIds={draftRoster?.map((r) => r.volunteerId) ?? []}
          dialogContentText={`These volunteers aren't enrolled in this activity but you can still add them to this roster if you would like.`}
        />
      )}
    </Box>
  );
};
