import React from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import {
  activityTypeFilterData,
  durationFilterData,
  locationFilterData,
  rosterTypeFilterData,
  statusFilterData,
} from '../../../../../common/filter-fields/filters';
import { InnerTimeFilterSelect } from './TimeFilterFields';
import { ProgramFilterSelectFieldV2 } from './ProgramFilterSelectFieldV2';
import { TagFilterFields } from './TagFilterFields';
import { DayFilterSelectFieldV3 } from './DayFilterSelectFieldV2';
import { InnerDateFilterSelect } from './DateFilterFields';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { FilterItem } from '../../../../../common/filter-fields/FilterItem';
import { GetProgramActivities_vm_programs as ProgramType } from '../../../../../global/programs-sell/__generated__/GetProgramActivities';
import { FilterSelectField } from '../../../../../common/filter-fields/StandardFilterSelectField';
import { GetActivityManagementActivityTags_vm_activityTags as ActivityTagType } from '../../activity-form-v2/__generated__/GetActivityManagementActivityTags';
import { CombinationLogic } from '../../../../../common/CombinationLogic';

const initialFilters = {
  programId: 'all',
  duration: 'any',
  type: 'all',
  location: 'all',
  status: 'all',
  days: [] as string[],
  startTime: undefined,
  endTime: undefined,
  startDate: undefined,
  endDate: undefined,
  rosterType: 'all',
  activityTagIds: [] as string[],
  combination: 'include',
};

interface ManagementActivitiesFiltersProps {
  programs: ProgramType[];
  showFilters: boolean;
  activityTags: ActivityTagType[];
}

export const ManagementActivitiesfilters = (props: ManagementActivitiesFiltersProps) => {
  const { programs, showFilters, activityTags } = props;
  const { theme, isMobile } = useCampfireTheme();
  // This media query below is a quick hack to fix the filters overlapping layout for laptop screen size.
  // For long term, we might have to handle these breakpoints like others or do a dynamic width calculation of the parent container
  // and adjust layout accordingly
  const isXl = !useMediaQuery('(max-width:1565px)');

  const [filtersParam, setFiltersParams] = useQueryParams({
    programId: StringParam,
    duration: StringParam,
    type: StringParam,
    location: StringParam,
    status: StringParam,
    days: ArrayParam,
    startTime: StringParam,
    endTime: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    rosterType: StringParam,
    activityTagIds: ArrayParam,
    combination: StringParam,
  });

  const [filters, setFilters] = React.useState(filtersParam);

  const handleChange = (value: string | undefined, key: string) =>
    setFilters({
      ...filters,
      [key]: value,
    });

  const [showAdvanced, setShowAdvanced] = React.useState(false);

  return showFilters ? (
    <Box paddingBottom={2} px={'10px'}>
      <Box display='flex' flexWrap={'wrap'}>
        <Box width={isMobile ? '100%' : isXl ? '25%' : '50%'}>
          <FilterItem title='PROGRAM'>
            <ProgramFilterSelectFieldV2
              value={filters.programId || 'all'}
              programs={Array.from(programs).sort((a, b) => (a.name < b.name ? -1 : 1))}
              onChange={(newProgramId) => handleChange(newProgramId, 'programId')}
            />
          </FilterItem>
        </Box>
        <Box width={isMobile ? '100%' : isXl ? '25%' : '50%'}>
          <FilterItem title='ACTIVITY TYPES'>
            <FilterSelectField
              value={filters.type || 'all'}
              filterData={activityTypeFilterData}
              onChange={(newActivityType) => handleChange(newActivityType, 'type')}
            />
          </FilterItem>
        </Box>
        <Box width={isMobile ? '100%' : isXl ? '25%' : '50%'}>
          <FilterItem title='DURATION'>
            <FilterSelectField
              value={filters.duration || 'any'}
              filterData={durationFilterData}
              onChange={(newDuration) => handleChange(newDuration, 'duration')}
            />
          </FilterItem>
        </Box>
        <Box width={isMobile ? '100%' : isXl ? '25%' : '50%'}>
          <FilterItem title='LOCATIONS'>
            <FilterSelectField
              value={filters.location || 'all'}
              filterData={locationFilterData}
              onChange={(newLocation) => handleChange(newLocation, 'location')}
            />
          </FilterItem>
        </Box>
        <Box width={isMobile ? '100%' : isXl ? '25%' : '50%'}>
          <FilterItem title='ACTIVITY STATUS'>
            <FilterSelectField
              value={filters.status || 'active'}
              filterData={statusFilterData}
              onChange={(newStatus) => handleChange(newStatus, 'status')}
            />
          </FilterItem>
        </Box>
        <Box width={isMobile ? '100%' : isXl ? '25%' : '50%'}>
          <FilterItem title='ROSTER TYPES'>
            <FilterSelectField
              value={filters.rosterType || 'all'}
              filterData={rosterTypeFilterData}
              onChange={(newRosterType) => handleChange(newRosterType, 'rosterType')}
            />
          </FilterItem>
        </Box>
        <Box width={isXl ? '50%' : '100%'}>
          <FilterItem title='DAY'>
            <DayFilterSelectFieldV3
              value={filters.days || []}
              onChange={(newDays) => setFilters({ ...filters, days: newDays })}
            />
          </FilterItem>
        </Box>
        <Box width={isXl ? '50%' : '100%'}>
          <FilterItem title='DATE' underline>
            <InnerDateFilterSelect
              start={filters.startDate}
              end={filters.endDate}
              setStart={(startDate?: string) => handleChange(startDate, 'startDate')}
              setEnd={(endDate?: string) => handleChange(endDate, 'endDate')}
            />
          </FilterItem>
        </Box>
        <Box width={isXl ? '50%' : '100%'}>
          <FilterItem title='TIME' underline>
            <InnerTimeFilterSelect
              start={filters.startTime}
              end={filters.endTime}
              setStart={(startTime) => handleChange(startTime, 'startTime')}
              setEnd={(endTime) => handleChange(endTime, 'endTime')}
            />
          </FilterItem>
        </Box>
        <Box width={isMobile ? '100%' : isXl ? '50%' : '100%'}>
          <FilterItem title='ACTIVITY TAGS'>
            <TagFilterFields
              selectedTagIds={filters.activityTagIds || []}
              activityTags={activityTags}
              onChange={(newTags) => setFilters({ ...filters, activityTagIds: newTags })}
            />
          </FilterItem>
        </Box>
        <Box width={isXl ? '50%' : '100%'} display='flex'>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: '0 !important',
              paddingBottom: '0 !important',
            }}
          >
            {showAdvanced ? (
              <Box style={{ flexGrow: 2 }}>
                <FilterItem title='Filtering Logic'>
                  <Typography style={{ color: theme.color.grey.neutral400, fontSize: '11px' }}>
                    Results can either be filtered by matching all conditions, or matching any conditions.
                  </Typography>
                  <CombinationLogic
                    value={filters.combination || 'exclude'}
                    onChange={(event) => handleChange(event.target.value, 'combination')}
                  />
                </FilterItem>
              </Box>
            ) : (
              ''
            )}
            <Box>
              <Button
                variant='text'
                endIcon={showAdvanced ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                onClick={() => setShowAdvanced(!showAdvanced)}
                style={{
                  fontSize: 10,
                  textDecoration: 'none',
                  color: theme.color.grey.neutral300,
                  textTransform: 'none',
                  paddingRight: 0,
                  paddingLeft: 0,
                }}
              >
                Advanced
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Box display='flex' justifyContent={isMobile ? 'center' : 'flex-start'} px={'10px'}>
        <TabletButton
          variant='contained'
          size='small'
          style={{
            backgroundColor: '#7047EB',
            color: '#ffffff',
            boxSizing: 'border-box',
            borderRadius: '4px',
            textTransform: 'none',
            marginRight: '8px',
            minWidth: '40px',
            padding: '7px 12px',
          }}
          onClick={() => setFiltersParams(filters)}
        >
          {'SHOW THE RESULTS'}
        </TabletButton>
        <TabletButton
          variant='contained'
          style={{
            backgroundColor: '#e6e5fc',
            color: theme.color.secondary.main900,
            boxSizing: 'border-box',
            borderRadius: '4px',
            textTransform: 'none',
            minWidth: '40px',
            padding: '7px 12px',
          }}
          onClick={() => setFilters(initialFilters)}
        >
          {'CLEAR'}
        </TabletButton>
      </Box>
    </Box>
  ) : null;
};
