import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../../../../common/recurrence/recurrence-model.gql';

export const GET_CAKES = gql`
  query TaskListGetCakes {
    vm {
      cakes {
        cakeId
        title
        description
        order
        isHidden
        cakeType
      }
    }
  }
`;

export const GET_CAKE = gql`
  query TaskListGetCake($cakeId: String) {
    vm {
      cake(cakeId: $cakeId) {
        cakeId
        title
        description
        required
        cakeType
        order
        isHidden
        allTasks {
          taskId
          title
          order
          description
          automateApproval
          allowUpdate
          isHidden
          adminOnly
          applicantsOnly
          hasRules
          recurrences {
            ...Recurrence
          }
          programs {
            programId
          }
          roles {
            activityRoleId
          }
          activities {
            activityId
          }
        }
      }
    }
  }
  ${RECURRENCE_FRAGMENT}
`;
