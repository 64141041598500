import { useContext } from 'react';
import { CampfireVersionContext, CampfireVersionContextInterface } from './CampfireVersionContext';

const useCampfireVersion = (): CampfireVersionContextInterface => {
  const campfireVersionContext = useContext(CampfireVersionContext);

  if (!campfireVersionContext) throw new Error('useCampfireVersion must be used within a CampfireVersionProvider');

  return campfireVersionContext;
};

export { useCampfireVersion };
