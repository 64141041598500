import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  Box,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogActions,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  Button,
} from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { encodeDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { ChevronRight, Close as CloseIcon } from '@material-ui/icons';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { useOrgInfo } from '../../../../../../../global/auth/use-org-info/use-org-info';
import { useCampfireLazyQuery } from '../../../../../../../global/network/useCampfireLazyQuery';
import { Avatar } from '../../../../../user-profile/components/Avatar';
import {
  CICO_GET_VOLUNTEERS_BY_ACTIVITY,
  CICO_GET_VOLUNTEERS_BY_ORG,
  CICO_GET_VOLUNTEERS_BY_PROGRAM,
} from './cico-get-other-volunteers.gql';
import {
  CICOGetVolunteersByActivity,
  CICOGetVolunteersByActivityVariables,
} from './__generated__/CICOGetVolunteersByActivity';
import {
  CICOGetVolunteersByProgram,
  CICOGetVolunteersByProgramVariables,
} from './__generated__/CICOGetVolunteersByProgram';
import { CICOGetVolunteersByOrg, CICOGetVolunteersByOrgVariables } from './__generated__/CICOGetVolunteersByOrg';
import { CICOVolunteerFragment } from './__generated__/CICOVolunteerFragment';
import { VolunteerType } from './MyActivityCheckInDialog';
import { SearchField } from '../../../../../../../common/inputs/SearchField';

const SELECT_GROUP = 1;
const ADD_VOLUNTEER = 2;
type GroupType = 'activity' | 'program' | 'org';

type ActivityActionType = {
  activityId: string;
  name: string;
  program: {
    programId: string;
    name: string;
  };
};

type CICOVolunteerType = {
  volunteerId: string;
};

export const AddOtherVolunteersDialog = ({
  open,
  activity,
  currentVolunteerIds,
  handleClose,
  handleAddOther,
}: {
  open: boolean;
  currentVolunteerIds: string[];
  activity: ActivityActionType;
  handleClose: () => void;
  handleAddOther: (vols: VolunteerType[]) => void;
}) => {
  const { theme } = useCampfireTheme();
  const { name } = useOrgInfo() || {};
  const [step, setStep] = useState(SELECT_GROUP);
  const [selectedGroup, setSelectedGroup] = useState<GroupType>('activity');
  const [searchFilter, setSearchFilter] = useState<string>();
  const [selectedVolunteers, setSelectedVolunteers] = useState<Array<CICOVolunteerFragment>>([]);

  const handleSelectGroup = (group: GroupType) => {
    setSelectedGroup(group);
    setStep(ADD_VOLUNTEER);
  };

  const onClickAddOther = () => {
    const mappedVols = selectedVolunteers.map((v) => ({
      ...v,
      sessionRosterings: [],
      CICOs: [],
    }));
    handleAddOther(mappedVols);
    onClose();
  };

  const onClose = () => {
    setStep(SELECT_GROUP);
    setSearchFilter(undefined);
    handleClose();
  };

  const onBack = () => {
    setSearchFilter('');
    setSelectedGroup('activity');
    setStep(SELECT_GROUP);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid container justify='space-between'>
          <Grid item>
            <Typography
              style={{ fontSize: '24px', fontWeight: 500, color: theme.color.grey.neutral500 }}
            >{`Add Other Volunteers`}</Typography>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              {step === ADD_VOLUNTEER && selectedGroup !== 'org' && (
                <SearchField
                  placeholder='Search Volunteers'
                  growLeft
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              )}
              <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
                <CloseIcon color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box pr={6}>
          <Typography
            style={{ fontSize: 14 }}
            color='textSecondary'
          >{`Select one or more volunteers to add. Hit 'Cancel' to close this window.`}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers id='dialog-content' style={{ padding: 0 }}>
        {step === SELECT_GROUP && (
          <Box>
            <List
              dense
              style={{
                width: '100%',
              }}
            >
              <ListItem
                button
                onClick={() => handleSelectGroup('activity')}
                style={{ paddingTop: '12px', paddingBottom: '12px' }}
              >
                <ListItemText
                  primary={<Typography style={{ fontWeight: 500 }}>{activity.name}</Typography>}
                  secondary={'Activity Team'}
                  style={{ color: theme.color.grey.neutral500, padding: '0px 18px' }}
                />
                <ListItemSecondaryAction>
                  <ChevronRight style={{ color: theme.color.grey.neutral300, paddingRight: '18px' }} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider component='li' style={{ margin: '0px 16px' }} />
              <ListItem
                button
                onClick={() => handleSelectGroup('program')}
                style={{ paddingTop: '12px', paddingBottom: '12px' }}
              >
                <ListItemText
                  primary={<Typography style={{ fontWeight: 500 }}>{activity.program.name}</Typography>}
                  secondary={'Program Volunteers'}
                  style={{ color: theme.color.grey.neutral500, padding: '0px 18px' }}
                />
                <ListItemSecondaryAction>
                  <ChevronRight style={{ color: theme.color.grey.neutral300, paddingRight: '18px' }} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider component='li' style={{ margin: '0 16px' }} />
              <ListItem
                button
                onClick={() => handleSelectGroup('org')}
                style={{ paddingTop: '12px', paddingBottom: '12px' }}
              >
                <ListItemText
                  primary={<Typography style={{ fontWeight: 500 }}>{name}</Typography>}
                  secondary={'Organisation Volunteers'}
                  style={{ color: theme.color.grey.neutral500, padding: '0px 18px' }}
                />
                <ListItemSecondaryAction>
                  <ChevronRight style={{ color: theme.color.grey.neutral300, paddingRight: '18px' }} />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Box>
        )}

        {step === ADD_VOLUNTEER && (
          <SelectOtherVolunterByGroup
            group={selectedGroup}
            activityId={activity.activityId}
            searchFilter={searchFilter}
            currentVolunteerIds={currentVolunteerIds}
            selectedVolunteers={selectedVolunteers}
            setSelectedVolunteers={setSelectedVolunteers}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box padding={2}>
          {step === SELECT_GROUP ? (
            <TabletButton variant='text' color='error' onClick={onClose} style={{ marginRight: 8 }}>
              {'Cancel'}
            </TabletButton>
          ) : (
            <TabletButton variant='text' color='error' onClick={onBack} style={{ marginRight: 8 }}>
              {'Back'}
            </TabletButton>
          )}
          <TabletButton
            data-track='actCnl-add-volunteers'
            variant='contained'
            disabled={step === SELECT_GROUP}
            color='primary'
            type='submit'
            onClick={onClickAddOther}
          >
            {'Add'}
          </TabletButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const SelectOtherVolunterByGroup = ({
  group,
  activityId,
  searchFilter,
  currentVolunteerIds,
  selectedVolunteers,
  setSelectedVolunteers,
}: {
  group: GroupType;
  activityId: string;
  currentVolunteerIds: string[];
  searchFilter?: string;
  selectedVolunteers: CICOVolunteerFragment[];
  setSelectedVolunteers: (vols: CICOVolunteerFragment[]) => void;
}) => {
  const [searchVolunteerInput, setSearchVolunteerInput] = useState<string>();
  const [getVolunteersByActivity, { loading: loadingGetVolunteersByActivity }] = useCampfireLazyQuery<
    CICOGetVolunteersByActivity,
    CICOGetVolunteersByActivityVariables
  >(CICO_GET_VOLUNTEERS_BY_ACTIVITY);

  const [getVolunteersByProgram, { loading: loadingGetVolunteersByProgram }] = useCampfireLazyQuery<
    CICOGetVolunteersByProgram,
    CICOGetVolunteersByProgramVariables
  >(CICO_GET_VOLUNTEERS_BY_PROGRAM);

  const [getVolunteersByOrg, { loading: loadingGetVolunteersByOrg }] = useCampfireLazyQuery<
    CICOGetVolunteersByOrg,
    CICOGetVolunteersByOrgVariables
  >(CICO_GET_VOLUNTEERS_BY_ORG);

  const [volunteers, setVolunteers] = useState<CICOVolunteerFragment[]>([]);

  useEffect(() => forceCheck(), [searchFilter]);

  useEffect(() => {
    if (group === 'activity') {
      getVolunteersByActivity({
        variables: {
          activityId,
        },
      }).then((response) => {
        const activityEnrolments = response.data?.vm.activity?.activityEnrolments;
        setVolunteers(
          activityEnrolments
            ?.map((item) => item.volunteer)
            ?.filter((v) => !currentVolunteerIds.includes(v.volunteerId)) || []
        );
      });
    }
    if (group === 'program') {
      getVolunteersByProgram({
        variables: {
          activityId,
        },
      }).then((response) => {
        const activeVolunteers = response.data?.vm.activity?.program.activeVolunteers;
        setVolunteers(activeVolunteers?.filter((v) => !currentVolunteerIds.includes(v.volunteerId)) || []);
      });
    }
  }, [group]);

  function handleToggle(volunteer: CICOVolunteerFragment) {
    if (volunteer.isCheckedIn) return;
    const selectedVolunteer = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
    if (!selectedVolunteer) {
      setSelectedVolunteers([...selectedVolunteers, volunteer]);
      return;
    }
    setSelectedVolunteers(selectedVolunteers.filter((x) => x.volunteerId !== volunteer.volunteerId));
  }

  const handleSearchOrg = () => {
    if (group === 'org') {
      if (searchVolunteerInput) {
        getVolunteersByOrg({
          variables: {
            keywordsFilter: searchVolunteerInput,
            startDate: encodeDate(DateTime.fromObject({ year: 1970, month: 1, day: 1 })),
            endDate: encodeDate(DateTime.local()),
          },
        }).then((response) => {
          setVolunteers(
            response.data?.vm.programActiveVolunteersConnection.edges
              .map((edge) => edge.node)
              .filter((v) => !currentVolunteerIds.includes(v.volunteerId)) || []
          );
        });
      } else {
        setVolunteers([]);
      }
    }
  };
  const isLoading =
    (group === 'activity' && loadingGetVolunteersByActivity) ||
    (group === 'program' && loadingGetVolunteersByProgram) ||
    (group === 'org' && loadingGetVolunteersByOrg);

  const filteredVolunteers = volunteers.filter((volunteer) =>
    searchFilter
      ? volunteer.profile.preferredName
          .concat(` ${volunteer.profile.lastName}`)
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
      : true
  );

  return (
    <Box>
      {group === 'org' && (
        <Box px={3} mt={2} display='flex' alignItems='center'>
          <Box width={'280px'}>
            <SearchField
              placeholder='Search by name, email'
              onChange={(e) => setSearchVolunteerInput(e.target.value)}
              handleEnterPress={handleSearchOrg}
              fullWidth
              growLeft
            />
          </Box>
          <Button variant={'text'} color='primary' onClick={handleSearchOrg}>
            Search
          </Button>
        </Box>
      )}
      {isLoading ? (
        <Box p={4}>{'Loading...'}</Box>
      ) : filteredVolunteers.length > 0 ? (
        <List style={{ width: '100%' }}>
          {filteredVolunteers.map((volunteer) => {
            const isSelected = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
            return (
              <LazyLoad
                key={volunteer.volunteerId}
                scrollContainer={'#dialog-content'}
                height={66}
                offset={400}
                overflow
              >
                <VolunteerListItem isSelected={!!isSelected} volunteer={volunteer} handleToggle={handleToggle} />
              </LazyLoad>
            );
          })}
        </List>
      ) : (
        <Box p={4}>{group === 'org' ? '' : 'No volunteers.'}</Box>
      )}
    </Box>
  );
};

const VolunteerListItem = React.memo(
  (props: {
    volunteer: CICOVolunteerFragment;
    isSelected: boolean;
    handleToggle: (volunteer: CICOVolunteerFragment) => void;
  }) => {
    const { isSelected, volunteer, handleToggle } = props;
    const {
      profile: { preferredName, lastName, avatarUrl },
    } = volunteer;
    const volunteerName = `${preferredName} ${lastName}`;
    return (
      <ListItem
        button
        onClick={() => handleToggle(volunteer)}
        style={{
          paddingLeft: 24,
          paddingRight: 32,
        }}
      >
        <Box
          component='li'
          display='flex'
          alignContent='center'
          alignItems='center'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <FormControlLabel
            label=''
            disabled={volunteer.isCheckedIn}
            control={<Checkbox checked={isSelected} color='primary' />}
          />
          <Avatar preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} size={32} />
          <Typography style={{ marginLeft: 8 }}>{volunteerName}</Typography>
        </Box>
      </ListItem>
    );
  }
);
