import { Box, Button, Popover } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useBetween } from 'use-between';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { OverflowMenu } from '../../../../../../common/toolbar/Toolbar';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { ActivitiesToolbar } from '../../../../activities-v2/ActivitiesToolbar';
import { sharedStyles } from '../../../../activities-v2/ActivityStyles/shared';
import { ActivityToolValue } from '../../../../activities-v2/ActivityTypes';
import { DatePicker } from '../../../../activities-v2/MyShifts/DatePicker';
import { useSharedMyActivities } from '../my-activities/MyElementsMyActivitiesMainSection';
import { CheckBoxItem } from './CheckBoxItem';
import { ClearFilterItem } from './ClearFilterItem';
import { ACTIVITY_STATUS_FILTERS, ActivityStatusFilterType } from './filters';

const useActivitiesFilterUpdate = () => {
  const [activitiesFilterUpdated, setActivitiesFilterUpdated] = useState<boolean>(false);

  return {
    activitiesFilterUpdated,
    setActivitiesFilterUpdated,
  };
};

export const useSharedActivitiesFilterUpdate = () => useBetween(useActivitiesFilterUpdate);

export const MyActivitiesFilters = () => {
  const [query, setQuery] = useQueryParams({
    activityStatus: ArrayParam,
    selectedDate: StringParam,
    startDate: StringParam,
    endDate: StringParam,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const buttonClasses = sharedStyles.button();
  const { theme, isSm } = useCampfireTheme();

  const tools = [
    {
      name: 'calendar' as ActivityToolValue,
      onClick: (e: any) => {
        setAnchorEl(e.target);
      },
      selected: Boolean(query.selectedDate),
    },
  ];

  const { setActivitiesFilterUpdated } = useSharedActivitiesFilterUpdate();
  const { myActivities } = useSharedMyActivities();
  const setStatusQuery = (value: string) => {
    setActivitiesFilterUpdated(true);
    if (query?.activityStatus?.length) {
      const { activityStatus } = query;
      if (activityStatus.includes(value)) {
        const filteredStatus = activityStatus.filter((val) => val !== value);
        setQuery({ ...query, activityStatus: filteredStatus });
      } else {
        setQuery({ ...query, activityStatus: [...activityStatus, value] });
      }
    } else {
      setQuery({ ...query, activityStatus: [value] });
    }
  };

  const clearStatusFilters = () => {
    setQuery({ ...query, activityStatus: undefined });
  };

  const mapMyActivitiesByDate = useMemo(
    () =>
      myActivities?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.activityDate]: theme.color.rosteredStatuses[cur.status].primary,
        }),
        {}
      ),
    [myActivities]
  );

  return (
    <Box
      display='flex'
      flexDirection='row'
      width='100%'
      justifyContent='flex-end'
      alignItems='center'
      pb={isSm ? 0 : '14px'}
    >
      <ActivitiesToolbar tools={tools} />
      <OverflowMenu variant='filter' withMarginLeft>
        {ACTIVITY_STATUS_FILTERS.map((activityStatusFilter: ActivityStatusFilterType) => (
          <CheckBoxItem
            key={activityStatusFilter.value}
            label={activityStatusFilter.label}
            value={activityStatusFilter.value}
            checked={query?.activityStatus?.includes(activityStatusFilter.value) ?? false}
            onClick={() => setStatusQuery(activityStatusFilter.value)}
          />
        ))}
        <ClearFilterItem onClick={() => clearStatusFilters()} marginLeft='14px' />
      </OverflowMenu>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 50,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DatePicker
          mapRosterByDate={mapMyActivitiesByDate || {}}
          value={query.selectedDate}
          onChange={(date) => setQuery({ selectedDate: date?.toISODate() })}
          orientation='landscape'
          variant='static'
          disableToolbar
          onMonthChange={(date) => {
            setQuery({ startDate: date?.startOf('month').toISODate(), endDate: date?.endOf('month').toISODate() });
          }}
          autoOk
          initialFocusedDate={query.startDate}
        />
        <Box display='flex' justifyContent='flex-end' marginBottom='1rem' marginRight='1rem'>
          <Button
            variant='outlined'
            onClick={() => setQuery({ selectedDate: undefined })}
            classes={{ root: buttonClasses.root }}
          >
            Clear
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};
