import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      backgroundColor: '#F9F9F9',
      minHeight: 'calc(100% - 20px)',
    },
    containerPanel: {
      background: 'white',
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
      paddingLeft: '1.875rem',
      paddingRight: '1.875rem',
      marginTop: '2.25rem',
    },
    heading: {
      paddingBottom: '0.5rem',
      borderBottom: '1px solid #efefef',
    },
    headingTitle: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 900,
      fontSize: 20,
      paddingBottom: '0.5rem',
    },
    text: {
      fontWeight: 400,
      fontSize: '0.875rem',
      opacity: '0.6',
    },
    label: {
      fontSize: '12px',
      fontWeight: 500,
      color: theme.color.grey.neutral400,
    },
    saveButton: {
      '&:hover': {
        opacity: 0.8,
      },
      fontSize: 13,
      fontWeight: 500,
      borderRadius: 6,
      textDecoration: 'none',
      textTransform: 'none'
    },
    sectionHeading: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 900,
      fontSize: '1rem',
      lineHeight: '1.188rem',
      letterSpacing: '-0.02rem',
    },
  })
);

export const useSelectStyles = makeStyles(() =>
  createStyles({
    input: {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontSize: '12px',
      height: '19px',
    },
    root: {
      borderRadius: '6px',
      borderTopColor: '#9E9E9E',
      borderRightColor: '#9E9E9E',
      borderBottomColor: '#9E9E9E',
      borderLeftColor: '#9E9E9E',
      color: '#444444',
    },
    adornedStart: {
      paddingLeft: '0.75rem',
    },
  })
);