import { unpackToDateTimeTZ } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { getAdminRoutesMap } from '../../content-blocks/admin/admin-routes-map';
import { AllRoutesMaps } from '../../content-blocks/common/Navigation';
import { getFixedRoutesMap } from '../../content-blocks/fixed/fixed-routes-map';
import { getManagerRoutesMap } from '../../content-blocks/management/management-routes-map';
import { getGeneralRoutesMap } from '../../content-blocks/volunteering/volunteering-routes-map';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { getApiUrlFromWindowLocation } from '../config/get-api-url';
import { recordInteractionMaybeAuth } from '../interactions/recordInteraction';
import { useCampfireFetch } from '../network/useCampfireFetch';
import { useCampfireLazyQuery } from '../network/useCampfireLazyQuery';
import { GET_USER_IDENTITY } from './app-context.gql';
import { GlobalConfigItems } from './config-model.gql';
import { getTokenFromStorage, updateToken } from './tokenStorage';
import { unpackToken } from './unpack-token';
import { OrgInfo, useOrgInfo } from './use-org-info/use-org-info';
import { UserIdentity } from './User';
import { getUserIdentityService, IUserIdentityService } from './UserIdentityService';
import {
  GetUserIdentity,
  GetUserIdentityVariables,
  GetUserIdentity_vm_profile_userPreference as UserPreference,
} from './__generated__/GetUserIdentity';

interface Status {
  emoji?: string;
  text?: string;
}

export interface User {
  userId: string;
  userIdentity: UserIdentity;
  userIdentityService: IUserIdentityService;
  avatarUrl: string | null;
  email: string;
  emailVerified: boolean;
  dateCreated: DateTime;
  profileId: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  configItems: GlobalConfigItems;
  orgInfo: OrgInfo;
  token: string;
  status: Status;
  userPreference: UserPreference | null;
}

export interface SessionContextInterface {
  user: User | undefined;
  routesMaps: AllRoutesMaps | undefined;
  login: (email: string, password: string) => void;
  logout: () => void;
  isLoading: boolean;
  isLoadingFromStorage: boolean;
  reloadSession: () => void;
  error: string | undefined;
  updateStatus: (status: Status) => void;
  updateAvatarUrl: (avatarUrl: string | null) => void;
  updateUserPreference: (userPreference: UserPreference | null) => void;
}

interface TokenResponse {
  jwt: string;
}

const parseConfigItems = (_unusedConfigItemsResponse: GetUserIdentity): GlobalConfigItems | undefined => {
  return {
    im: {},
    vm: {},
  };
};

const parseUserIdentity = (userResponse: GetUserIdentity): UserIdentity | null => {
  if (!userResponse.im.user) return null;

  return {
    userId: userResponse.im.user.userId,
    im: userResponse.im.identity,
    vm: userResponse.vm.identity,
  };
};

const axiosOptions = {
  method: 'post',
  headers: {
    'Content-Type': 'application/json',
  },
};

const getErrorMessageFromStatusCode = (status: number) => {
  if (status === 401) return 'Invalid email/password';
  if (status === 500) return `We're having trouble logging you in, please try again in a minute`;
  return 'Something went wrong';
};

export const SessionContext = React.createContext<SessionContextInterface | undefined>(undefined);

enum LoadingType {
  FromStorage,
  FromSignIn,
  None,
}

interface AuthState {
  isLoading: LoadingType;
  error?: string;
  password?: string;
}

interface SessionProviderProps {
  value?: SessionContextInterface;
  children: React.ReactNode;
}

export const SessionProvider = (props: SessionProviderProps) => {
  const apiUrl = getApiUrlFromWindowLocation();
  const { theme } = useCampfireTheme();
  const [user, setUser] = useState<User | undefined>();
  const [routesMaps, setRoutesMaps] = useState<AllRoutesMaps | undefined>();
  const [authState, setAuthState] = useState<AuthState>({
    isLoading: LoadingType.FromStorage,
    error: undefined,
    password: undefined,
  });

  const [tempToken, setTempToken] = useState<string>();
  const org = useOrgInfo();

  function clearAuthState() {
    setAuthState((prev) => ({
      ...prev,
      isLoading: LoadingType.None,
      error: undefined,
    }));
  }

  function setErrorAuthState(status?: number) {
    setAuthState({
      ...authState,
      isLoading: LoadingType.None,
      error: status ? getErrorMessageFromStatusCode(status) : 'Something went wrong, please try again later',
    });
  }

  // INTERACTIONS
  const { postInteraction } = recordInteractionMaybeAuth('vm');

  // USER
  const [loadIdentity] = useCampfireLazyQuery<GetUserIdentity, GetUserIdentityVariables>(GET_USER_IDENTITY, {
    onCompleted: setAllData,
  });

  // LOGIN
  const loginFetch = useCampfireFetch<TokenResponse>({
    defer: true,
    withAuth: false,
  });

  // REFRESH TOKEN
  const refreshTokenFetch = useCampfireFetch<TokenResponse>({
    defer: true,
    withAuth: false,
  });

  // Attempt to load token from storage on first mount
  useEffect(() => {
    getTokenFromStorage().then((tokenFromStorage) => {
      if (!tokenFromStorage) {
        clearAuthState();
        return;
      }
      refreshTokenAndGetSession(tokenFromStorage);
    });
  }, []);

  function setAllData(data: GetUserIdentity) {
    if (!data || !data.im.user || !data.vm.profile || !tempToken || !org) {
      clearAuthState();
      return;
    }
    const userIdentity = parseUserIdentity(data);
    const configItems = parseConfigItems(data);
    const { userId, emailAddress, emailVerified, dateCreated } = data.im.user;

    if (!userIdentity || !configItems) {
      clearAuthState();
      return;
    }
    const {
      profileId,
      avatarUrl,
      firstName,
      lastName,
      preferredName,
      statusEmoji,
      statusText,
      userPreference,
    } = data.vm.profile;
    const userIdentityService = getUserIdentityService(userIdentity);
    const liveUser: User = {
      userId,
      userIdentity,
      userIdentityService,
      avatarUrl,
      email: emailAddress,
      emailVerified,
      dateCreated: unpackToDateTimeTZ(dateCreated),
      profileId,
      firstName,
      lastName,
      preferredName,
      configItems,
      orgInfo: org,
      token: tempToken,
      status: {
        emoji: statusEmoji ?? undefined,
        text: statusText ?? undefined,
      },
      userPreference,
    };

    const liveRoutesMaps: AllRoutesMaps = {
      fixed: getFixedRoutesMap({
        userIdentityService,
        configItems,
        theme,
      }),
      volunteering: getGeneralRoutesMap({ userIdentityService, configItems, theme }),
      management: getManagerRoutesMap({ userIdentityService, configItems, theme }),
      admin: getAdminRoutesMap({ userIdentityService, configItems, theme }),
    };

    setRoutesMaps(liveRoutesMaps);
    setUser(liveUser);
    updateToken(tempToken);
    clearAuthState();
  }

  function getSessionFromToken(liveToken: string) {
    const { userId } = unpackToken(liveToken).data;
    setTempToken(liveToken);
    loadIdentity({
      variables: {
        userId,
      },
      context: {
        headers: {
          Authorization: `JWT ${liveToken}`,
        },
      },
    });
  }

  function login(email: string, password: string) {
    setAuthState({
      password,
      isLoading: LoadingType.FromSignIn,
      error: undefined,
    });

    loginFetch
      .run({
        ...axiosOptions,
        url: `${apiUrl}/im/auth/user`,
        data: {
          email,
          password,
        },
      })
      .then((response) => {
        if (!response) return;
        if (!response.ok) {
          setErrorAuthState(response.status);
          return;
        }
        getSessionFromToken(response.data.data.jwt);
        postInteraction('agnostic', 'login', response.data.data.jwt);
      });
  }

  function logout() {
    updateToken(undefined).finally(() => {
      setUser(undefined);
    });
  }

  function refreshTokenAndGetSession(existingToken: string) {
    refreshTokenFetch
      .run({
        ...axiosOptions,
        url: `${apiUrl}/im/auth/refresh`,
        headers: {
          Authorization: `JWT ${existingToken}`,
        },
        data: {
          token: existingToken,
        },
      })
      .then((response) => {
        if (!response) return;
        if (!response.ok) {
          logout();
          return;
        }
        getSessionFromToken(response.data.data.jwt);
      });
  }

  function reloadSession() {
    if (!user) {
      logout();
      return;
    }
    refreshTokenAndGetSession(user.token);
  }

  function updateStatus(status: Status) {
    setUser((prev) => (prev ? { ...prev, status } : undefined));
  }

  function updateAvatarUrl(avatarUrl: string | null) {
    setUser((prev) => (prev ? { ...prev, avatarUrl } : undefined));
  }

  function updateUserPreference(userPreference: UserPreference | null) {
    setUser((prev) => (prev ? { ...prev, userPreference } : undefined));
  }

  // SET CONTEXT
  const value: SessionContextInterface = useMemo(() => {
    return {
      user,
      routesMaps,
      login,
      logout,
      reloadSession,
      isLoading: authState.isLoading !== LoadingType.None,
      isLoadingFromStorage: authState.isLoading === LoadingType.FromStorage,
      error: authState.error,
      updateStatus,
      updateAvatarUrl,
      updateUserPreference,
    };
  }, [user, authState]);

  return <SessionContext.Provider value={value} {...props} />;
};
