import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { HoverLink } from '@campfire/hover-link';
import { useRoutesMap } from '../../../../content-blocks/common/useRoutesMaps';

const MyActivitiesNoEnrolmentsEmptyMessage = () => {
  const { volunteering } = useRoutesMap();

  return (
    <Box height={1} p={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
      <Typography variant='h6'>{`You haven't joined an activity yet`}</Typography>
      <Typography color='textSecondary' component='span'>
        {`Visit `}
        <HoverLink color='primary' to={`/${volunteering.route}/activities/all-activities`}>
          {'All Activities'}
        </HoverLink>
        {` to search and apply to join.`}
      </Typography>
    </Box>
  );
};
export { MyActivitiesNoEnrolmentsEmptyMessage };
