import { unpackToDate } from '@campfire/hot-date';
import React from 'react';
import { Box, Button, IconButton, Typography, Theme, Divider } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { AddCircle, FiberManualRecord, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { RosterContext } from '../RosterContext';
import { GET_ROSTER_SCREEN_ACTIVITY_ENROLMENT_DATA } from '../../main-section/roster/rosters-model.gql';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import {
  GetRosterScreenActivityEnrolmentData,
  GetRosterScreenActivityEnrolmentDataVariables,
} from '../../main-section/roster/__generated__/GetRosterScreenActivityEnrolmentData';
import { SortVolsRowButton } from '../../main-section/roster/SortVolsRowButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      borderBottomColor: theme.color.grey.neutralBrand200,
      paddingBottom: '1rem',
      paddingTop: '1rem',
      marginRight: '40px',
      alignItems: 'center',
    },
    subContainer: {
      display: 'flex',
    },
    name: {
      color: theme.color.grey.neutralBrand800,
      fontSize: '20px',
      fontWeight: 900,
    },
    addButton: {
      backgroundColor: theme.color.secondary.main900,
      borderRadius: '4px',
      textTransform: 'unset',
      padding: '7px 12px',
      minWidth: '45px',
      fontSize: '13px',
      lineHeight: '19px',
      marginRight: '10px',
    },
    date: {
      fontSize: '14px',
      color: theme.color.grey.neutralBrand900,
      marginRight: '1rem',
      alignSelf: 'center',
    },
    divider: {
      margin: '1rem',
    },
    buttonIcon: {
      border: '1px solid',
      borderColor: theme.color.grey.neutralBrand200,
      borderRadius: '4px',
      padding: '3px',
      color: theme.color.secondary.main700,
    },
    dotContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 10px',
    },
    group: {
      display: 'flex',
      alignItems: 'center',
    },
    dot: {
      fontSize: '14px',
      color: theme.color.card['3dots'],
    },
  })
);

export function RosterMenu() {
  const {
    activity,
    onNext,
    onBack,
    onSkipNext,
    onSkipBack,
    activityDates,
    onAddRoster,
    setSortedVolunteerIdsByEnrolmentDate,
  } = React.useContext(RosterContext);
  const classes = useStyles();

  const [getActivityEnrolments, { loading: activityEnrolmentsLoading }] = useCampfireLazyQuery<
    GetRosterScreenActivityEnrolmentData,
    GetRosterScreenActivityEnrolmentDataVariables
  >(GET_ROSTER_SCREEN_ACTIVITY_ENROLMENT_DATA, {
    onCompleted: (result) => {
      setSortedVolunteerIdsByEnrolmentDate(
        result.vm.activity?.activityEnrolments.length
          ? result.vm.activity?.activityEnrolments
              .filter((enrolment) => !enrolment.dateRemoved)
              .sort((a, b) => (unpackToDate(a.dateEnrolled) < unpackToDate(b.dateEnrolled) ? 1 : -1))
              .flatMap((enrolment) => enrolment.volunteer.volunteerId)
          : undefined
      );
    },
  });

  React.useEffect(() => {
    if (activity?.activityId)
      getActivityEnrolments({
        variables: {
          activityId: activity.activityId,
        },
      });
  }, [activity?.activityId]);

  const isNextEnabled = React.useMemo(() => {
    if (!activity?.endDate || activityDates.length === 0) {
      return true;
    }
    return activity.endDate > activityDates[activityDates.length - 1];
  }, [activity, activityDates]);

  const isBackEnabled = React.useMemo(() => {
    if (!activity?.startDate || activityDates.length === 0) {
      return true;
    }
    return activity.startDate < activityDates[0];
  }, [activity, activityDates]);

  return (
    <Box className={classes.container}>
      <Typography className={classes.name}>{activity?.name}</Typography>
      <Box className={classes.subContainer}>
        <SortVolsRowButton loading={activityEnrolmentsLoading} />
        <Box style={{ margin: '0 10px', borderLeft: '1px solid rgb(239, 239, 239)' }}></Box>
        <Button
          variant='contained'
          startIcon={<AddCircle style={{ fontSize: '18px' }} />}
          color='primary'
          disableElevation
          className={classes.addButton}
          onClick={onAddRoster}
        >
          Add roster
        </Button>
        <Divider orientation='vertical' className={classes.divider} />
        <Typography className={classes.date}>Dates</Typography>
        <Box className={classes.group}>
          <IconButton
            onClick={onSkipBack}
            className={classes.buttonIcon}
            disabled={!isBackEnabled}
            style={{ transform: 'scaleX(-1)', marginRight: '3px' }}
          >
            <KeyboardArrowRight />
            <KeyboardArrowRight style={{ marginLeft: '-18px' }} />
          </IconButton>
          <IconButton onClick={onBack} className={classes.buttonIcon} disabled={!isBackEnabled}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box className={classes.dotContainer}>
            <FiberManualRecord className={classes.dot} />
            <FiberManualRecord className={classes.dot} />
            <FiberManualRecord className={classes.dot} />
          </Box>
          <IconButton onClick={onNext} className={classes.buttonIcon} disabled={!isNextEnabled}>
            <KeyboardArrowRight />
          </IconButton>
          <IconButton
            onClick={onSkipNext}
            className={classes.buttonIcon}
            disabled={!isBackEnabled}
            style={{ marginLeft: '3px' }}
          >
            <KeyboardArrowRight />
            <KeyboardArrowRight style={{ marginLeft: '-18px' }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
