import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { CheckCircle, RemoveCircle } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { TemplateBadget } from './TemplateBadget';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '14px',
      paddingRight: '14px',
      paddingTop: '14px',
      paddingBottom: '14px',
      border: '1px solid #54545440',
      backgroundColor: '#FCFCFC',
      cursor: 'pointer',
    },
    title: {
      fontWeight: 600,
      fontSize: 14,
      color: theme.color.grey.neutral300,
    },
    subTitle: {
      fontWeight: 400,
      fontSize: 10,
      color: theme.color.grey.neutral300,
    },
    enabled: {
      color: '#34D399',
    },
    active: {
      color: '#7570EA !important',
    },
  })
);

interface TemplateItemProps {
  type: string;
  title: string;
  lastUpdated: string;
  enabled: boolean;
  onSelect: () => void;
  isActive: boolean;
}

export function TemplateItem({ type, title, lastUpdated, enabled, onSelect, isActive }: TemplateItemProps) {
  const classes = useStyles();

  return (
    <Box className={classNames(classes.root, { [classes.active]: isActive })} onClick={onSelect}>
      {enabled ? (
        <CheckCircle
          style={{ fontSize: '16px' }}
          className={classNames({ [classes.active]: isActive, [classes.enabled]: enabled })}
        />
      ) : (
        <RemoveCircle style={{ fontSize: '16px' }} />
      )}
      <Box style={{ flex: 1, paddingLeft: '12px' }}>
        <Typography className={classNames(classes.title, { [classes.active]: isActive })}>{title}</Typography>
        <Typography className={classNames(classes.subTitle, { [classes.active]: isActive })}>{lastUpdated}</Typography>
      </Box>
      <TemplateBadget variant={type as 'sms' | 'email'} />
    </Box>
  );
}
