import { gql } from '@apollo/client';

export const GET_USER_PAGE_INTERACTIONS = gql`
  query GetUserPageInteractions($userId: String!, $page: String!) {
    vm {
      userInteractions(userId: $userId, page: $page) {
        ...UserInteraction
      }
    }
  }

  fragment UserInteraction on UserInteractionType {
    page
    action
    occurredAt
  }
`;
