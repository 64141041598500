import { IconButton, Dialog, Box, Fab } from '@material-ui/core';
import { Clear, VerticalSplit } from '@material-ui/icons';
import React, { memo, useState } from 'react';
import { ActivityTimelineSidebar } from '../activity-timeline/sidebar/ActivityTimelineSidebar';
import { PastRostersSkeletonLoadingLayout } from '../activity-timeline/past/roster/ActivityTimelinePastRoster';
import { ActivityTimelineSelected } from '../activity-timeline/ActivityTimelineSelected';
import { ActivityTimelineProps } from '../activity-timeline/ActivityTimeline';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useActivityTimelineContext } from '../activity-timeline/ActivityTimelineContext';

export const ActivityTimelineMobile = memo(({ activities, returnSlug }: ActivityTimelineProps) => {
  const { sidebarIsLoading } = useActivityTimelineContext();

  const [showLog, setShowLog] = useState(false);
  const { theme } = useCampfireTheme();

  return activities.length > 0 ? (
    <>
      {!showLog ? (
        <Box display='flex' justifyContent='flex-end' alignContent='center' alignItems='center' paddingX={1}>
          <Fab
            data-track='actCnl-activities-directory-fab-button'
            variant='extended'
            style={{
              marginTop: 4,
              position: 'fixed',
              bottom: 8,
              left: 8,
              textTransform: 'none',
              zIndex: theme.zIndex.speedDial,
            }}
            color='primary'
            aria-label='open-timeline'
            onClick={() => {
              setShowLog(true);
            }}
          >
            {'Directory'}
            <VerticalSplit style={{ marginLeft: 8 }} />
          </Fab>
        </Box>
      ) : null}

      <Dialog open={showLog} fullScreen keepMounted>
        <Box position='relative' display='flex' flex='1 1 auto' paddingTop={3} height='100%' overflow='hidden'>
          <IconButton style={{ position: 'absolute', top: 8, right: 8 }} onClick={() => setShowLog(false)}>
            <Clear />
          </IconButton>

          <ActivityTimelineSidebar activities={activities} onSidebarItemClick={() => setShowLog(false)} />
        </Box>
      </Dialog>

      {sidebarIsLoading ? <PastRostersSkeletonLoadingLayout /> : <ActivityTimelineSelected returnSlug={returnSlug} />}
    </>
  ) : null;
});
