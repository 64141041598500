import { unpackToDateTime } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { RosterStatus } from '../../../rosters/main-section/roster/roster-types';

import { VolunteerCommonProfileActivityEnrolment_activity as ActivityType } from '../../__generated__/VolunteerCommonProfileActivityEnrolment';

const getActivityStatus = (activity: ActivityType, date: string) => {
  const isCompleted = activity.isActive && unpackToDateTime(`${date}T${activity.endTime}`) < DateTime.local();
  if (activity.isSuspended) return 'suspended';
  if (activity.cancelledActivities?.find((ca) => ca.activityDate === date)) return 'cancelled';
  if (activity.closedActivity !== null) return 'closed';
  if (unpackToDateTime(activity.endDate) < DateTime.local()) return 'ended';
  if (isCompleted) {
    return 'completed';
  }
  return 'active';
};

export const getRosterStatus = (activity: ActivityType, dateX: string): RosterStatus => {
  const activityStatus = getActivityStatus(activity, dateX);
  // roster is completed
  if (activityStatus === 'completed') {
    if (activity.publishedRosters.filter(({ activityDate }) => activityDate === dateX).length === 0) {
      return 'not-published';
    }

    const sessionReports = activity.activityReports
      .filter(({ activityDate }) => activityDate === dateX)
      .flatMap((activityReport) => activityReport.sessionReports);

    if (sessionReports.length === 0) {
      return 'report-not-started';
    }

    if (sessionReports.length < activity.sessions.length) {
      return 'report-missing';
    }
  }

  // roster is cancelled
  if (activity.cancelledActivities.some((ca) => ca.activityDate === dateX)) {
    return 'cancelled';
  }
  // roster is published
  if (
    activity.draftRosters.some(
      (draft) => draft.activityDate === dateX && !!draft.publishedRoster && !draft.publishedRoster.dateRemoved
    )
  ) {
    return 'published';
  }
  // roster is drafted
  if (activity.draftRosters.some((draft) => draft.activityDate === dateX)) {
    return 'draft';
  }
  // roster not started
  return 'neutral';
};
