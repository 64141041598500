import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useCampfireTheme } from '../../../../../../../../../theme/useCampfireTheme';
import { TaskFieldTypeEnum } from '../../../../admin-console-volunteer-profile-model';
import { FieldIcon } from '../../../dialogs/field-task-item-dialog/FieldIcon';

interface FieldTaskItemTitleProps {
  __typename: TaskFieldTypeEnum;
  optional: boolean;
  name: string;
}

export const FieldTaskItemTitle = ({ __typename, optional, name }: FieldTaskItemTitleProps) => {
  const { theme } = useCampfireTheme();
  return (
    <Grid item container spacing={1} xs alignItems='center'>
      <Grid item>
        <FieldIcon fieldType={__typename} style={{ color: `${theme.palette.text.secondary}` }} fontSize='small' />
      </Grid>
      <Grid item container xs>
        <Typography display='inline' style={{ fontWeight: 'bold' }}>
          {name}
          {!optional ? <span style={{ color: theme.status.red.dark, marginLeft: 2 }}>{'*'}</span> : null}
        </Typography>
      </Grid>
    </Grid>
  );
};
