import { gql } from '@apollo/client';

export const DraftRosterFragment = gql`
  fragment DraftRoster on VOLUNTEER_DraftRosterType {
    activityDate
    rosterNotes
    sessionNotes {
      session {
        sessionId
        name
      }
      notes
    }
  }
`;
