import { gql } from '@apollo/client';

export const GET_ALERT_FULL_SESSIONS_DIALOG = gql`
  query GetAlertFullSessions($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityId
        name
        hasOpenRoster
        sessions {
          sessionId
          name
        }
        activityLocation {
          placesAddress {
            description
          }
        }
      }
    }
  }
`;
