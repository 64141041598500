import {
  Modal,
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { FolderOpenOutlined, TocOutlined, RadioButtonCheckedOutlined } from '@material-ui/icons';
import React from 'react';
import { CategoriesSectionContent, isOptionDisabled } from '../../admin-console-incident-report-model';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';

type AddNewOptionsModalTypes = {
  open: boolean;
  onClose: () => void;
  anchorRef: React.RefObject<HTMLButtonElement>;
  selectedChildType: string;
  openAdddCategoryGroupDialog: () => void;
  openAddCategoryDialog: () => void;
  openAddCategoryDetailDialog: () => void;
};

export const AddNewOptionsModal = ({
  open,
  onClose,
  anchorRef,
  selectedChildType,
  openAdddCategoryGroupDialog,
  openAddCategoryDialog,
  openAddCategoryDetailDialog,
}: AddNewOptionsModalTypes) => {
  const { theme } = useCampfireTheme();
  const { addNewModalOptions } = CategoriesSectionContent;

  return (
    <Modal BackdropProps={{ invisible: true }} open={open} onClose={() => onClose()}>
      <Box position='absolute' width={1} height={1}>
        <Popper
          style={{ zIndex: theme.zIndex.modal + 1 }}
          disablePortal={false}
          open={open}
          anchorEl={anchorRef ? anchorRef.current : undefined}
          transition
          placement='bottom-end'
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'top right',
              }}
            >
              <Paper elevation={4}>
                <ClickAwayListener onClickAway={() => onClose()}>
                  <MenuList>
                    {addNewModalOptions.map((option) => {
                      const disabled = isOptionDisabled(option.label, selectedChildType);

                      return (
                        <ListItem
                          dense
                          button
                          disabled={disabled}
                          key={option.label}
                          onClick={() => {
                            onClose();
                            if (option.label === 'Category') openAdddCategoryGroupDialog();
                            if (option.label === 'Group') openAddCategoryDialog();
                            if (option.label === 'Detail') openAddCategoryDetailDialog();
                          }}
                        >
                          <Box marginRight='16px'>
                            {option.label === 'Category' ? (
                              <FolderOpenOutlined fontSize='small' />
                            ) : option.label === 'Group' ? (
                              <TocOutlined fontSize='small' />
                            ) : (
                              <RadioButtonCheckedOutlined fontSize='small' />
                            )}
                          </Box>

                          <ListItemText
                            primary={option.label}
                            secondary={disabled ? option.disabledText : option.secondaryText}
                          ></ListItemText>
                        </ListItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Modal>
  );
};
