import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/styles';
import pluralize from 'pluralize';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { convertFromRaw, EditorState } from 'draft-js';
import { RoleItemMenuButton } from './RoleItemMenu';

import { AdminConsoleRole } from '../AdminConsoleRolesQuery/__generated__/AdminConsoleRole';
import { RoleIcon } from '../../../../../../common/icons/RoleIconList';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

export interface ExtendRole extends AdminConsoleRole {
  volunteerCount: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '&:not(:first-child)': {
        marginTop: '1.25rem',
      },
      '&:not(:last-child)': {
        borderBottomColor: '#EFEFEF',
        borderBottom: '1px solid',
        paddingBottom: '1.25rem',
      },
      paddingRight: '1rem',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerNameWrapper: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    name: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 900,
      fontSize: '1rem',
      textAlign: 'center',
    },
    iconWrapper: {
      width: '1.125rem',
      height: '1.125rem',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '0.5rem',
    },
    parenthese: {
      fontWeight: 400,
      color: theme.color.grey.neutralBrand900,
      marginLeft: '0.75rem',
    },
    volunteer: {
      fontWeight: 400,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
        color: lighten(theme.palette.primary.main, 0.3),
      },
    },
    description: {
      fontSize: '0.875rem',
      color: theme.color.grey.neutralBrand200,
    },
  })
);

interface Props {
  role: ExtendRole;
  isLoading: boolean;
  onEdit: (role: ExtendRole) => void;
  onDelete: (role: ExtendRole) => void;
  onShowVolunteers: (roleId: string) => void;
}

export function RoleItem({ role, onEdit, onDelete, onShowVolunteers, isLoading = false }: Props) {
  const classes = useStyles();
  const { name, description, icon, volunteerCount } = role;
  const { theme } = useCampfireTheme();
  const editorState = React.useMemo(
    () => (description ? EditorState.createWithContent(convertFromRaw(JSON.parse(description))) : null),
    [role.description]
  );

  const onAction = (action: string) => {
    switch (action) {
      case 'edit':
        onEdit(role);
        break;
      case 'delete':
        onDelete(role);
        break;
      default:
        break;
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box className={classes.headerNameWrapper}>
          <Typography variant='h5' className={classes.name}>
            {name}
          </Typography>
          {icon && (
            <Box className={classes.iconWrapper}>
              <RoleIcon iconName={icon} color={theme.color.grey.neutral300} />
            </Box>
          )}
          {!isLoading && (
            <Typography component='span' className={classes.parenthese}>
              (&nbsp;
              <Typography
                component='span'
                className={classes.volunteer}
                onClick={() => onShowVolunteers(role.activityRoleId)}
              >
                {pluralize('Volunteer', volunteerCount, true)}
              </Typography>
              &nbsp;)
            </Typography>
          )}
        </Box>

        <RoleItemMenuButton onAction={onAction} />
      </Box>
      {editorState && <CampfireRichEditor readOnly editorState={editorState} />}
    </Box>
  );
}
