import { getActivityEndDateText } from './get-activity-end-date-text';

function getActivityRecurrenceText(activity: {
  schedule: { recurrences: Array<{ humanReadable: string; __typename: string; endDate?: string }> };
}): string {
  const recurrenceStrings = activity.schedule.recurrences.map((recurrence) => {
    if (recurrence.__typename === 'SingleRecurrenceType') {
      return recurrence.humanReadable.replace('On ', '');
    }

    const endDateText = getActivityEndDateText(recurrence.endDate);

    if (!endDateText) {
      return recurrence.humanReadable;
    }

    return `${recurrence.humanReadable}, ${endDateText}`;
  });

  return recurrenceStrings.join(' + ');
}

export { getActivityRecurrenceText };
