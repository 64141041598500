import { gql } from '@apollo/client';

export const GET_TABLE_WIDGET_EXPIRING_TASKS = gql`
  query GetTableWidgetExpiringTasks($status: String, $programIds: [String!], $activityIds: [String!]) {
    vm {
      tasks(status: $status, programIds: $programIds, activityIds: $activityIds) {
        taskId
        title
        cake {
          title
        }
        expiryDate
      }
    }
  }
`;
