import React from 'react';
import { Box, DialogContent, DialogActions } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { TabletButton } from '@campfire/tablet-button';
import { Check } from '@material-ui/icons';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { ReportTypeFormSections } from '../../../report-management-v2/common/report-type-form-sections/ReportTypeFormSections';
import { ReportTypeFormValues } from '../../../report-management-v2/common/report-type-form-sections/report-type-form-types';

interface CreateReportTypeFormProps {
  onSubmit: (x: ReportTypeFormValues) => void;
  isLoading: boolean;
  cancel: () => void;
}
const CreateReportTypeFormV2 = (props: CreateReportTypeFormProps) => {
  const { onSubmit, isLoading, cancel } = props;
  const { theme } = useCampfireTheme();
  return (
    <Box
      paddingY={2}
      paddingRight={2}
      marginY={2}
      bgcolor={'aliceblue'}
      border={`1px solid ${theme.color.grey.border}`}
      borderRadius={8}
    >
      <Formik<ReportTypeFormValues>
        initialValues={{
          reportTypeId: '',
          reportTypeName: '',
          reportTypeDescription: '',
          items: [],
          active: true,
        }}
        onSubmit={onSubmit}
      >
        <Form>
          <DialogContent>
            <ReportTypeFormSections forceActive />
          </DialogContent>
          <DialogActions>
            <TabletButton size='medium' onClick={cancel}>
              {'Cancel'}
            </TabletButton>
            <TabletButton
              size='medium'
              type='submit'
              color='primary'
              variant='contained'
              disabled={isLoading}
              endIcon={<Check />}
            >
              {'Create Report Type'}
            </TabletButton>
          </DialogActions>
        </Form>
      </Formik>
    </Box>
  );
};

export { CreateReportTypeFormV2 };
