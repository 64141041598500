import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import React, { useMemo } from 'react';
import { useParams, useLocation } from 'react-router';
import { useSupportContext } from '../SupportContext';
import { ResourceViewer } from './resource-viewer/ResourceViewer';
import { ResourceEditor } from './resource-editor/ResourceEditor';

const ResourcePage = () => {
  const { inEditResourceMode, allCategories } = useSupportContext();
  const { resourceId, categoryId } = useParams();
  const location = useLocation<{ returnSlug: string }>();

  const selectedCategory = useMemo(() => allCategories.find((c) => c.supportCategoryId === categoryId), [
    allCategories,
    categoryId,
  ]);
  const selectedResource = useMemo(
    () => selectedCategory?.supportResources.find((r) => r.supportResourceId === resourceId),
    [selectedCategory, resourceId]
  );

  // This isn't an ideal loading condition, it'll do for now
  const ghettoIsLoading = !selectedCategory || selectedCategory.supportResources.length === 0;

  return (
    <>
      {!inEditResourceMode && selectedResource ? (
        <ResourceViewer selectedResource={selectedResource} returnSlug={location.state?.returnSlug ?? undefined} />
      ) : ghettoIsLoading ? (
        <CircularProgressOverlay transitionDelay={250} isLoading />
      ) : (
        <ResourceEditor selectedResource={selectedResource} />
      )}
    </>
  );
};

export { ResourcePage };
