import { gql } from '@apollo/client';

export const VolunteerFragments = gql`
  fragment VolunteerType on VOLUNTEER_VolunteerType {
    volunteerId
    profile {
      profileId
      preferredName
      lastName
      avatarUrl
    }
  }
`;