import { gql } from '@apollo/client';

export const ACTIVITY_AVAILABILITY_ACTIVITY_ENROLMENT = gql`
  query ActivityAvailabilityGetActivityEnrolment($volunteerId: String, $activityId: String) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        activityEnrolment(activityId: $activityId) {
          activityEnrolmentId
          availabilities {
            activityDate
          }
          unavailabilities {
            activityDate
          }
          dateRemoved
          dateEnrolled
        }
      }
    }
  }
`;
