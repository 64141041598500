import { DraggableList, DraggableListItem, DraggableListItemHandle } from '@campfire/draggable-list';
import { Field } from '@campfire/field';
import { HoverText } from '@campfire/hover-link';
import { Select } from '@campfire/select';
import { TabletButton } from '@campfire/tablet-button';
import { ButtonBase, Collapse, DialogActions, DialogContent, Grid, Switch, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ReportTypeItemFieldValue } from '../manage-report-type-model';
import { EditFieldValues, fieldTypes, MappedFieldType, validationSchema } from './edit-field-dialog-model';
import { FormikErrorFocus } from '../../../../../common/form/FormikErrorFocus';

const fixedWidthSelectStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '180px',
      '& div': {
        width: '180px',
      },
    },
  })
);

interface Props {
  fieldData?: ReportTypeItemFieldValue;
  handleClose: () => void;
  onSubmit: (values: EditFieldValues) => void;
  deleteField: () => void;
}
const FieldCreator = (props: Props) => {
  const { handleClose, onSubmit, fieldData, deleteField } = props;
  const [showDescription, setShowDescription] = useState(false);

  const { root: fixedWidthSelect } = fixedWidthSelectStyles();

  const initialValues = useMemo((): EditFieldValues => {
    return {
      __typename: fieldData?.field.__typename || 'VOLUNTEER_NumericFieldType',
      fieldId: fieldData?.field.fieldId || '',
      reportTypeItemId: fieldData?.reportTypeItemId || uuidv4(),
      order: fieldData?.order || -1,
      name: fieldData?.field.name || '',
      description: fieldData?.field.description || '',
      optional: fieldData?.optional || false,
      isHero: fieldData?.field.__typename === 'VOLUNTEER_NumericFieldType' ? fieldData?.field.isHero : undefined,
      isAllowMultiple:
        fieldData?.field.__typename === 'VOLUNTEER_DropdownFieldType' ? fieldData?.field.isAllowMultiple : undefined,
      dropdownOptions:
        fieldData?.field.__typename === 'VOLUNTEER_DropdownFieldType'
          ? fieldData?.field.dropdownOptions.map((option) => {
              return {
                ...option,
                listId: uuidv4(),
              };
            })
          : undefined,
    };
  }, [fieldData]);

  useEffect(() => {
    if (fieldData?.field.description) setShowDescription(true);
  }, [fieldData?.field.description]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(vals) => {
        onSubmit(vals);
        handleClose();
      }}
    >
      {({ submitForm, values, setFieldValue }) => (
        <Form>
          <DialogContent>
            <Grid container direction='column' spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs>
                  <Field required autoFocus name='name' label='Field Name' variant='outlined' fullWidth />
                </Grid>
                <Grid item>
                  <Select
                    variant='outlined'
                    name='__typename'
                    label='Field Type'
                    items={fieldTypes}
                    renderFunction={(item: MappedFieldType) => item.name}
                    valueFunction={(item: MappedFieldType) => item.type}
                    InputProps={{
                      disabled: !!values.fieldId,
                    }}
                    className={fixedWidthSelect}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={showDescription}>
                  <Field name='description' label='Field Description' multiline variant='outlined' fullWidth />
                </Collapse>
                {!showDescription ? (
                  <HoverText variant='body2' color='primary' onClick={() => setShowDescription(true)}>
                    {'Add description'}
                  </HoverText>
                ) : null}
              </Grid>
              {values.__typename === 'VOLUNTEER_DropdownFieldType' ? (
                <FieldArray
                  name='dropdownOptions'
                  render={(arrayHelpers) => {
                    if (!values?.dropdownOptions?.length)
                      arrayHelpers.push({
                        name: 'New Option',
                        order: 0,
                        listId: uuidv4(),
                      });
                    return (
                      <Grid item container direction='column'>
                        <Grid item xs>
                          {values?.dropdownOptions?.length ? (
                            <DraggableList>
                              {values.dropdownOptions.map((option, index) => (
                                <DraggableListItem
                                  id={option.listId}
                                  key={option.listId}
                                  index={index}
                                  style={{ border: '1px solid #000', padding: 9, borderRadius: 5 }}
                                >
                                  <Grid container alignItems='center' justify='space-between'>
                                    <Grid item>
                                      <DraggableListItemHandle />
                                    </Grid>
                                    <Grid item>
                                      <Field
                                        slow
                                        name={`dropdownOptions[${index}].name`}
                                        label=''
                                        variant='standard'
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item>
                                      {values?.dropdownOptions && values.dropdownOptions.length > 1 ? (
                                        <ButtonBase onClick={() => arrayHelpers.remove(index)}>
                                          <DeleteIcon color='secondary' />
                                        </ButtonBase>
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                </DraggableListItem>
                              ))}
                            </DraggableList>
                          ) : null}
                        </Grid>
                        <Grid item>
                          <HoverText
                            variant='caption'
                            color='primary'
                            onClick={() =>
                              arrayHelpers.unshift({
                                name: 'New Option',
                                order: values?.dropdownOptions?.length ?? 0,
                                listId: uuidv4(),
                              })
                            }
                          >
                            {`Add dropdown option`}
                          </HoverText>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
              ) : null}
              {values.__typename === 'VOLUNTEER_DropdownFieldType' ? (
                <Grid item container>
                  <Grid item>
                    <Switch
                      color='primary'
                      checked={values.isAllowMultiple || false}
                      onChange={() => setFieldValue('isAllowMultiple', !values.isAllowMultiple)}
                    />
                  </Grid>
                  <Grid item xs container direction='column'>
                    <Typography display='inline' variant='subtitle2'>
                      {`Allow multiple selections`}
                    </Typography>
                    <Typography display='inline' variant='caption'>
                      {`This will allow volunteers to select multiple options for this dropdown.`}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {values.__typename === 'VOLUNTEER_NumericFieldType' ? (
                <Grid item container>
                  <Grid item>
                    <Switch
                      color='primary'
                      checked={values.isHero || false}
                      onChange={() => setFieldValue('isHero', !values.isHero)}
                    />
                  </Grid>
                  <Grid item xs container direction='column'>
                    <Typography display='inline' variant='subtitle2'>
                      {`Assign as a Pinned Field`}
                    </Typography>
                    <Typography display='inline' variant='caption'>
                      {`Pinned Fields are given more exposure on your volunteers' 'My Elements' page. Turn this on for a
                        maximum of 3 fields that you want to highlight.`}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item container>
                <Grid item>
                  <Switch
                    color='primary'
                    checked={values.optional || false}
                    onChange={() => setFieldValue('optional', !values.optional)}
                  />
                </Grid>
                <Grid item xs container direction='column'>
                  <Typography display='inline' variant='subtitle2'>
                    {`Make field optional`}
                  </Typography>
                  <Typography display='inline' variant='caption'>
                    {`Values for optional fields do not have to be included when submitting a report`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item container justify='space-between' alignItems='center' style={{ padding: 8 }}>
              {fieldData ? (
                <Grid item>
                  <TabletButton
                    color='error'
                    variant='outlined'
                    onClick={() => {
                      deleteField();
                      handleClose();
                    }}
                  >{`Delete Field`}</TabletButton>
                </Grid>
              ) : null}
              <Grid item xs container justify='flex-end'>
                <TabletButton
                  onClick={() => {
                    handleClose();
                  }}
                >{`Cancel`}</TabletButton>

                <TabletButton color='primary' variant='contained' onClick={() => submitForm()}>
                  {fieldData ? `Save Changes` : 'Add Field'}
                </TabletButton>
              </Grid>
            </Grid>
          </DialogActions>
          <FormikErrorFocus />
        </Form>
      )}
    </Formik>
  );
};

export { FieldCreator };
