import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { get, intersection } from 'lodash';
import {
  CreatWidgetFormValues,
  IWidget,
  StepsEnum,
  WidgetTypes,
  numberWidgetMap,
  supportingWidgetTypes,
} from '../dashboard-widget-model';
import { SelectNumberType } from '../selects/SelectNumberType';

interface Props {
  onClose: () => void;
}

export function SelectNumberTypeDialog({ onClose }: Props) {
  const { values, setFieldValue } = useFormikContext<CreatWidgetFormValues>();

  const onNext = () => {
    if (values.byType === WidgetTypes.NUMBER_CHART) {
      setFieldValue('selectStep', StepsEnum.SELECT_CHART_TYPE);
    } else {
      setFieldValue('selectStep', StepsEnum.SETTINGS);
    }
  };

  const onBack = () => {
    setFieldValue('selectStep', StepsEnum.SELECT_WIDGET);
  };

  const { byValues } = values;

  const supportingNumberTypes = intersection(
    ...byValues.map(({ name, tag }) => {
      return get(supportingWidgetTypes, [tag.toLowerCase(), name.toLowerCase(), 'numberWidgetTypes'], []) as string[];
    })
  ).map((widgetType) => numberWidgetMap[widgetType] as IWidget);

  return (
    <>
      <DialogTitle>
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs style={{ paddingTop: '10px' }}>
            <Typography variant='h6' style={{ fontWeight: 700, fontSize: '24px' }}>
              {values.isNew ? 'Add' : 'Edit'} Widget
            </Typography>
            <Typography variant='subtitle1' color='textSecondary'>
              Select a number type
            </Typography>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
                <Close color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent id='dialog-content'>
        <SelectNumberType
          value={values.numberWidgetType ?? undefined}
          onSelect={(numberType) => setFieldValue('numberWidgetType', numberType)}
          supportingNumberTypes={supportingNumberTypes}
        />
      </DialogContent>
      <DialogActions style={{ marginBottom: '2rem', marginRight: '10px' }}>
        <Button
          onClick={onBack}
          style={{
            textTransform: 'none',
          }}
        >
          Back
        </Button>
        <Button
          onClick={onNext}
          color='primary'
          style={{
            textTransform: 'none',
          }}
          variant='contained'
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}
