import React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
    },
    content: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '0px 16px 12px 16px',
      overflowY: 'hidden',
    },
  })
);

type WidgetContainerProps = {
  children: React.ReactElement | null | Array<React.ReactElement | null>;
};

export function WidgetContainer(props: WidgetContainerProps) {
  const { children } = props;

  const classes = useStyles();

  return <Box className={classes.container}>{children}</Box>;
}

export function WidgetContent(props: WidgetContainerProps) {
  const { children } = props;

  const classes = useStyles();

  return <Box className={`${classes.content} unselectable`}>{children}</Box>;
}
