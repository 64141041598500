import React from 'react';
import { ClickAwayListener, IconButton, Tooltip, Button } from '@material-ui/core';
import { ExpandMore, Close, Restore } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    availabilityButton: {
      textTransform: 'none',
      borderRadius: 6,
      fontSize: '12px',
    },
    tooltip: {
      background: '#616161',
      borderRadius: 6,
    },
    arrow: {
      '&::before': {
        background: '#616161',
      },
    },
  })
);

interface Props {
  onClick: () => void;
  unavailable: boolean;
}

export function AvailabilityTrigger({ onClick, unavailable }: Props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const title = unavailable ? 'Mark as unavailable' : 'Remove unavailability';
  const color = unavailable ? '#ff9c9c' : '#FFFFFF';

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event: any) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    onClick();
    handleTooltipClose();
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          interactive
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          open={open}
          onClose={handleTooltipClose}
          title={
            <Button
              onClick={handleClick}
              className={classes.availabilityButton}
              style={{ color: color }}
              startIcon={unavailable ? <Close style={{ fontSize: 14 }} /> : <Restore style={{ fontSize: 14 }} />}
            >
              {title}
            </Button>
          }
        >
          <IconButton onClick={handleTooltipOpen}>
            <ExpandMore />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
