import { unpackToDateTime, unpackToTime } from '@campfire/hot-date';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FullPageMessage } from '../../../../../../../common/FullPageMessage';
import { useCampfireQuery } from '../../../../../../../global/network/useCampfireQuery';
import { GET_INCOMING_VOLUNTEER_INVITEE_DETAIL } from '../../../incoming-volunteer-model.gql';
import {
  GetIncomingVolunteerInviteeDetail,
  GetIncomingVolunteerInviteeDetailVariables,
} from '../../../__generated__/GetIncomingVolunteerInviteeDetail';
import { InfoBlock } from '../../IncomingVolunteerInfoBlock';
import { TaskMenuBarContentWrapper, TaskMenuBarHeader } from '../TaskSideMenuBar';

interface IncomingVolunteerInvitationType extends RouteComponentProps {
  onClose: () => void;
  inviteeId: string;
  reload?: () => void;
}

export const IncomingVolunteerInvitation = withRouter(({ onClose, inviteeId }: IncomingVolunteerInvitationType) => {
  const { data, error, loading } = useCampfireQuery<
    GetIncomingVolunteerInviteeDetail,
    GetIncomingVolunteerInviteeDetailVariables
  >(GET_INCOMING_VOLUNTEER_INVITEE_DETAIL, {
    options: {
      variables: {
        inviteeId: inviteeId,
      },
    },
  });
  const invitee = useMemo(() => {
    return data?.vm.invitee ?? undefined;
  }, [data]);

  return (
    <Box flex={1} style={{ overflowY: 'scroll' }} display='flex' flexDirection='column'>
      <Box position='relative'>
        <LinearProgressOverlay isLoading={loading} />
        <TaskMenuBarHeader onClose={onClose}>
          {invitee?.dateAccepted ? (
            <Typography variant='caption' color='textSecondary'>
              {'An account for this email has been created.'}
            </Typography>
          ) : null}
        </TaskMenuBarHeader>
        {error ? (
          <FullPageMessage title='Unable to retrieve invitation detail. Please try again later.' />
        ) : invitee ? (
          <TaskMenuBarContentWrapper>
            <Typography variant='h6'>{'Invitation'}</Typography>
            <InfoBlock
              label='Invited by'
              value={`${invitee.invitedBy.profile.preferredName} ${invitee.invitedBy.profile.lastName} (${invitee.invitedBy.profile.email})`}
            />
            <InfoBlock
              label='Invite details'
              value={`Sent on ${unpackToDateTime(invitee.dateInvited).toLocaleString(
                DateTime.DATE_FULL
              )}, ${unpackToTime(invitee.dateInvited)
                .toFormat('h:mm a')
                .toLowerCase()}`}
            />
          </TaskMenuBarContentWrapper>
        ) : null}
      </Box>
    </Box>
  );
});
