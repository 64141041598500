import { TabletButton } from '@campfire/tablet-button';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useUser } from '../../../../../global/auth/useUser';
import { useVolDashboardMyElementsContext } from '../VolDashboardMyElementsContext';
import { TabName } from './MyElementsTabsHeader';
import { MyActivitiesFilters } from './my-activities-filters/MyActivitiesFilters';

const MY_ELEMENTS_MOBILE_TAB_OPTIONS = [
  {
    label: 'My Rosters',
    value: 'my-activities',
  },
  {
    label: 'Tasks',
    value: 'tasks',
  },
];

export const MyElementsTabsHeaderMobileMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedTab, setSelectedTab] = useQueryParam('tab', StringParam);
  const { newTasksNumber } = useVolDashboardMyElementsContext();
  const {
    user: { userIdentityService },
  } = useUser();
  const userIsPmOrAdmin = userIdentityService.isVmAdmin || userIdentityService.isProgramManager;
  const menuOptions = userIsPmOrAdmin ? MY_ELEMENTS_MOBILE_TAB_OPTIONS : MY_ELEMENTS_MOBILE_TAB_OPTIONS.slice(1);

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab('my-activities');
    }
  }, [selectedTab]);

  const handleOpenMenuDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <Box width='100%' alignItems='center' style={{ paddingBottom: '16px', borderBottom: '2px solid #EFEFEF' }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <TabletButton
          size='small'
          variant='outlined'
          color='primary'
          aria-controls='my-elements-header-menu'
          aria-haspopup='true'
          onClick={handleOpenMenuDropdown}
          endIcon={<ExpandMore />}
          style={{
            border: '1px solid #9e9e9e',
            boxSizing: 'border-box',
            borderRadius: '4px',
            padding: '7px 12px',
            color: '#444444',
            marginRight: '8px',
            minWidth: '150px',
            fontSize: '16px',
            fontWeight: 900,
          }}
        >
          {selectedTab ? MY_ELEMENTS_MOBILE_TAB_OPTIONS.find((option) => option.value === selectedTab)?.label : null}
        </TabletButton>
        {selectedTab === 'my-activities' ? <MyActivitiesFilters /> : null}
      </Box>
      <Menu
        id='my-elements-header-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenuDropdown}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              setSelectedTab(option.value);
              handleCloseMenuDropdown();
            }}
          >
            <TabName name={option.label} alertNumber={option.value === 'tasks' ? newTasksNumber : undefined} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
