import { gql } from '@apollo/client';

export const GET_MY_ELEMENTS_SIDEBAR_MY_TASKS_DATA = gql`
  query GetMyElementsSideBarMyTasksData($volunteerId: String!) {
    orgName
    vm {
      volunteer(volunteerId: $volunteerId) {
        profile {
          ...MyElementsSideBarMyTasksProfile
        }
      }
      tasks {
        ...MyElementsSidebarMyTask
      }
    }
  }
  fragment MyElementsSideBarMyTasksProfile on VOLUNTEER_ProfileType {
    profileId
    completedTasks {
      completedTaskId
      task {
        taskId
      }
    }
  }
  fragment MyElementsSidebarMyTask on VOLUNTEER_TaskType {
    taskId
    cake {
      cakeType
    }
    dateRemoved
  }
`;
