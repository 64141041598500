import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Typography } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';
import { FieldArray, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormSectionV2 } from '../../../../../common/FormSectionV2';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { EditReportTypeItemFieldDialog } from './report-type-form-items/EditReporTypeItemFieldDialog';
import { ReportTypeFormItemsDraggableList } from './report-type-form-items/ReportTypeFormItemsDraggableList';
import { ReportTypeFormValues, ReportTypeItemFieldValue } from './report-type-form-types';

export const ReportTypeFormItemsSection = () => {
  const { theme } = useCampfireTheme();
  const formik = useFormikContext<ReportTypeFormValues>();
  const [openEditFieldDialog, setOpenEditFieldDialog] = useState(false);
  const [selectedField, setSelectedField] = useState<ReportTypeItemFieldValue>();

  return (
    <FieldArray name='items'>
      {(arrayHelpers) => (
        <FormSectionV2
          title='Fields'
          description={
            <>
              <Typography variant='body2' display='block' color='textSecondary'>
                Customise the fields for this Report Type. Click and drag the tabs on the left to reorder the fields and
                headers.
              </Typography>
            </>
          }
        >
          <ReportTypeFormItemsDraggableList
            setOpenEditFieldDialog={setOpenEditFieldDialog}
            setSelectedField={setSelectedField}
            arrayHelpers={arrayHelpers}
          />

          <Box marginTop={3} display='flex' alignItems='center'>
            <TabletButton
              variant='contained'
              color='primary'
              size='medium'
              endIcon={<PostAdd />}
              style={{
                backgroundColor: theme.color.secondary.main900,
                color: '#ffffff',
                borderRadius: '4px',
                textTransform: 'none',
                paddingRight: '16px',
                paddingLeft: '16px',
                fontWeight: 600,
                fontSize: '13px',
              }}
              onClick={() => {
                setSelectedField(undefined);
                setOpenEditFieldDialog(true);
              }}
            >
              Add Field
            </TabletButton>
            <HoverText
              style={{ marginLeft: 16 }}
              variant='body2'
              color='primary'
              onClick={() => {
                const updatedItems = formik.values.items;
                updatedItems.push({
                  __typename: 'VOLUNTEER_ReportTypeHeadingType',
                  heading: 'New Heading',
                  order: 0,
                  listId: uuidv4(),
                });
                formik.setFieldValue('items', updatedItems);
              }}
            >
              Add Heading
            </HoverText>
          </Box>

          <EditReportTypeItemFieldDialog
            isOpen={openEditFieldDialog}
            close={() => setOpenEditFieldDialog(false)}
            fieldData={selectedField}
            delete={() => {
              arrayHelpers.remove(
                formik.values.items.findIndex((item) => item.reportTypeItemId === selectedField?.reportTypeItemId)
              );
            }}
            onSubmit={(values) => {
              if (selectedField?.reportTypeItemId) {
                arrayHelpers.replace(
                  formik.values.items.findIndex((item) => item.reportTypeItemId === selectedField.reportTypeItemId),
                  values
                );
                setSelectedField(undefined);
                return;
              }

              arrayHelpers.push(values);
              setSelectedField(undefined);
            }}
          />
        </FormSectionV2>
      )}
    </FieldArray>
  );
};
