import { gql } from '@apollo/client';

export const GET_ACTIVITIES_TEAM_INFORMATION = gql`
query GetActivitiesConsoleTeamInformation($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        maxTeam
        activityEnrolments {
            position
            volunteer {
                volunteerId
                profile {
                    profileId
                    userId
                    preferredName
                    lastName
                    avatarUrl
                }
            }
        }
      }
    }
}
`;