import React, { useEffect } from 'react';
import { Tabs, Tab, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { incidentStatusOptions } from '../IncidentManagementConsts';
import { IncidentTabValue } from '../incident-management-types';
import { INCIDENT_FETCH_LIMIT } from '../IncidentManagementScreen';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabRoot: {
      color: theme.color.grey.neutralBrand800,
      textTransform: 'capitalize',
      fontWeight: 800,
      fontSize: '20px',
      letterSpacing: '-0.02em',
      minWidth: 'unset',
    },
    selected: {
      color: `${theme.color.grey.neutralBrand800} !important`,
    },
    tabsFlexContainer: {
      alignContent: 'space-between',
      borderBottom: '2px solid',
      borderBottomColor: '#EFEFEF',
      height: 'calc(100% - 2px)',
    },
    root: {
      flex: 1,
      marginLeft: 60,
      marginRight: 60,
      marginTop: '1rem',
    },
  })
);

interface Props {
  children?: React.ReactNode;
}

export const IncidentManagementDesktopTabs = ({ children }: Props) => {
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    incidentId: StringParam,
    limit: NumberParam,
  });

  const classes = useStyles();

  useEffect(() => {
    if (!query.tab) setQuery({ ...query, tab: 'new' });
    if (!query.limit) setQuery({ ...query, limit: INCIDENT_FETCH_LIMIT });
  }, [query]);

  const handleChange = (_unused: any, newValue: IncidentTabValue) => {
    setQuery({ ...query, tab: newValue });
  };

  return (
    <Tabs
      value={query.tab}
      onChange={handleChange}
      indicatorColor='primary'
      variant='standard'
      aria-label='incident tabs'
      classes={{
        flexContainer: classes.tabsFlexContainer,
      }}
    >
      {incidentStatusOptions.map((option) => (
        <Tab
          key={option.label}
          disableRipple
          disableTouchRipple
          label={option.label}
          classes={{ selected: classes.selected, root: classes.tabRoot }}
          value={option.value}
        />
      ))}
      {children}
    </Tabs>
  );
};
