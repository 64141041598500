import React from 'react';
import { Badge, Box, Button, IconButton, Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { InsertDriveFile, KeyboardArrowDown, PostAdd } from '@material-ui/icons';
import { TooltipMenu } from '../../../../../../common/tooltip-menu';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { displayTimeFunc, sessionAttendingCount } from './RosterTable';
import { useRosterTableStyles } from './RosterTableStyles';
import { MaxWarning } from './MaxWarning';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    edit: {
      color: theme.alert.blue.extraLight,
      display: 'flex',
      alignItems: 'center',
      paddingTop: '10px',
      justifyContent: 'center',
      '& p': {
        fontSize: '14px',
      },
      '& svg': {
        fontSize: '16px',
      },
      textTransform: 'none',
    },
  })
);

type SessionHeaderProps = {
  session: any;
  draftRosters: any;
  hasSessionNotes?: boolean;
  showVolunteerRow: boolean;
  onClick?: () => void;
  printOnly?: boolean;
};

export function SessionHeader({
  session,
  draftRosters,
  hasSessionNotes,
  showVolunteerRow,
  onClick,
  printOnly,
}: SessionHeaderProps) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const { theme, isMobile } = useCampfireTheme();
  const classes = useStyles();
  const rosterClasses = useRosterTableStyles({ isMobile });
  return (
    <Box minWidth={printOnly ? '200px' : 'unset'}>
      <Box display='flex' alignItems='center' position='relative' justifyContent='center'>
        <Box display={'flex'} flexDirection={'column'}>
          {session.name}
          <Typography classes={{ root: rosterClasses.rowTime }}>{displayTimeFunc(session)}</Typography>
        </Box>
        <TooltipMenu
          open={tooltipOpen}
          onClose={() => {
            setTooltipOpen(false);
          }}
          title={
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Button startIcon={<PostAdd />} className={classes.edit} onClick={onClick}>
                {hasSessionNotes ? 'Edit' : 'Add'} session notes
              </Button>
            </Box>
          }
        >
          <IconButton onClick={() => setTooltipOpen(true)} size='small' style={{ marginLeft: '1rem' }}>
            <KeyboardArrowDown />
          </IconButton>
        </TooltipMenu>
      </Box>
      <Box paddingTop={'15px'}>
        {hasSessionNotes && (
          <Badge
            onClick={onClick}
            style={{
              backgroundColor: theme.color.grey.neutral200,
              color: '#ffffff',
              padding: '6px',
              borderRadius: '3px',
              height: 'fit-content',
              marginTop: '7px',
              cursor: 'pointer',
            }}
          >
            <InsertDriveFile style={{ fontSize: '16px' }} />
            <Typography style={{ fontSize: '12px', paddingLeft: '4px', fontWeight: 500, paddingRight: '2px' }}>
              Note Added
            </Typography>
          </Badge>
        )}
        {showVolunteerRow && (
          <Box>
            {session.isMaxed ? (
              <MaxWarning />
            ) : (
              sessionAttendingCount(draftRosters)(session) > 0 && (
                <Typography className={rosterClasses.rowVolunteer} style={{ marginTop: '10px' }}>
                  Need <strong>{sessionAttendingCount(draftRosters)(session)}</strong> volunteers
                </Typography>
              )
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
