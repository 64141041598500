import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { DraggableListItemHandle } from '@campfire/draggable-list';
import { HoverText } from '@campfire/hover-link';
import { useFormBuilderDialogContext } from '../../FormBuilderDialogContext';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';
import { TaskFormItem_VOLUNTEER_TaskItemHeadingType as TaskItemHeadingType } from '../../../../../../../../common/form/task-form/model/__generated__/TaskFormItem';

interface Props {
  data: TaskItemHeadingType;
}

export const HeadingTaskItem = (props: Props) => {
  const { data } = props;
  const { editHeading } = useFormBuilderDialogContext();
  const { theme } = useCampfireTheme();

  return (
    <Grid container spacing={2} style={{ margin: 8, borderBottom: `2px solid ${theme.color.grey.border}` }}>
      <Grid item>
        <DraggableListItemHandle />
      </Grid>

      <Grid item>
        <Typography style={{ fontWeight: 'bold' }}>{data.heading}</Typography>
      </Grid>

      <Grid item container xs justify='flex-end'>
        <HoverText color='primary' onClick={() => editHeading(data)}>
          {'Edit Heading'}
        </HoverText>
      </Grid>
    </Grid>
  );
};
