import React, { useState, memo } from 'react';
import { ProfileHoverBlock, ProfileHoverBlockProps } from '../../general/user-profile/hover-text/ProfileHoverBlock';
import { VolunteerProfileActions } from './volunteer-profile-actions';
import { VolunteerCommonProfileProvider } from './VolunteerCommonProfileContext';
import { VolunteerCommonProfileDialog } from './VolunteerCommonProfileDialog';

type Props = {
  updateVolunteerOnProfileActions?: VolunteerProfileActions;
} & ProfileHoverBlockProps;

const VolunteerDialogHoverBlock = memo((props: Props) => {
  const { updateVolunteerOnProfileActions, profileId, userId } = props;
  const [open, setOpen] = useState(false);

  function handleOpenDialog() {
    setOpen(true);
  }

  function handleCloseDialog() {
    setOpen(false);
  }

  return (
    <VolunteerCommonProfileProvider>
      <ProfileHoverBlock {...props} onClick={handleOpenDialog} />

      {open ? (
        <VolunteerCommonProfileDialog
          open={open}
          profileId={profileId}
          userId={userId}
          onClose={handleCloseDialog}
          updateVolunteerOnProfileActions={updateVolunteerOnProfileActions}
        />
      ) : null}
    </VolunteerCommonProfileProvider>
  );
});

export { VolunteerDialogHoverBlock };
