import React from 'react';
import { Redirect } from 'react-router';
import { AdminConsoleScreen } from '../../screens/admin/admin-console/AdminConsoleScreen';
import { ProgramManagementScreen } from '../../screens/admin/program-management/ProgramManagementScreen';
import { TasksScreen } from '../../screens/admin/tasks/TasksScreen';
import { CampfireSwitch } from '../common/CampfireSwitch';
import MiniRoute from '../common/MiniRoute';
import { useRoutesMap } from '../common/useRoutesMaps';

const getLegacyRoutes = ({ route, isAuthorized }: { route: string; isAuthorized: boolean }): Array<React.ReactNode> => [
  <MiniRoute
    //
    key={`/${route}/volunteer-profile`}
    path={`/${route}/volunteer-profile`}
    component={() => <Redirect to={`/${route}/admin-console/volunteer-profile`} />}
    isAuthorized={isAuthorized}
  />,
];

export const AdminContentBlock = () => {
  const {
    admin: { route, isAuthorized },
  } = useRoutesMap();

  return (
    <CampfireSwitch>
      {getLegacyRoutes({ route, isAuthorized })}

      <MiniRoute
        //
        path={`/${route}/program-management`}
        component={ProgramManagementScreen}
        isAuthorized={isAuthorized}
      />

      <MiniRoute
        //
        path={`/${route}/admin-console`}
        component={AdminConsoleScreen}
        isAuthorized={isAuthorized}
      />

      <MiniRoute
        //
        path={`/${route}/tasks`}
        component={TasksScreen}
        isAuthorized={isAuthorized}
      />
    </CampfireSwitch>
  );
};

export default AdminContentBlock;
