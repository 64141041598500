import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Remove } from '@material-ui/icons';
import QueryString from 'qs';
import { intervalChangeParsed } from './NumberWidget';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { NumberWidgetProps, useWidgetStyles } from '../shared';
import { TrackingNumberConfiguration } from '../../dashboard-widget-setting/__generated__/TrackingNumberConfiguration';
import { useApiUrl } from '../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../global/network/useCampfireFetch';

type TrackingNumberWidgetProps = NumberWidgetProps & {
  configuration: TrackingNumberConfiguration;
};

export const TrackingNumberWidget = (props: TrackingNumberWidgetProps) => {
  const { theme } = useCampfireTheme();
  const classes = useWidgetStyles();
  const { tag, configuration, name, filters } = props;
  const [value, setValue] = React.useState({ current: 0, last: 0 });
  const { interval } = configuration;
  const apiUrl = useApiUrl();
  const campfireQuery = useCampfireFetch<{ current: number; last: number }>({ defer: true });
  React.useEffect(() => {
    campfireQuery
      .run({
        url: `${apiUrl}/vm/dashboard/management/${tag.toLowerCase()}/${name.toLowerCase()}/tracking`,
        params: {
          range: interval,
          filters: JSON.parse(filters),
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, { arrayFormat: 'brackets', encode: false });
        },
      })
      .then((response) => response?.data?.data && setValue(response.data.data));
  }, [interval, filters]);

  const change = value.current - value.last;
  const changePercent =
    value.last === 0
      ? value.current === 0
        ? '0%'
        : '100%'
      : `${(((value.current - value.last) * 100) / value.last).toFixed(1)}%`;

  return (
    <Box display='flex' alignItems='center' flexDirection='column'>
      <Box className={classes.valueText}>{value.current}</Box>
      <Box mt='12px' mb={2} display='flex' flexDirection='column' alignItems='center'>
        <Box
          style={{
            display: 'flex',
            color: change > 0 ? theme.color.success[500] : change < 0 ? theme.color.error[900] : '#343434',
          }}
        >
          {change > 0 ? <ArrowDropUp /> : change < 0 ? <ArrowDropDown /> : <Remove />}
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 700,
            }}
          >{`${change} (${changePercent})`}</Typography>
        </Box>
        <Typography style={{ fontSize: '13px', color: theme.color.grey.neutral300 }}>
          vs last {intervalChangeParsed(interval)}
        </Typography>
      </Box>
    </Box>
  );
};
