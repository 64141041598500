import React from 'react';
import { Typography } from '@material-ui/core';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

export function EmptyExport() {
  const { theme } = useCampfireTheme();
  return (
    <Typography
      style={{
        color: theme.color.grey.neutralBrand800,
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center',
        paddingTop: '1.5em',
        paddingBottom: '1.5em',
      }}
    >
      No exports yet. click <strong>Create new</strong> button for new export
    </Typography>
  );
}
