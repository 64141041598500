import { ListItem, ListItemText } from '@material-ui/core';
import { ListItemProps } from '@material-ui/core/ListItem';
import React, { Fragment, useState } from 'react';
import { Avatar } from '../../../../../general/user-profile/components/Avatar';
import { ActivityTimelineFutureRosterVolunteer } from '../../__generated__/ActivityTimelineFutureRosterVolunteer';
import { AddNewVolunteerConfirmDialog } from '../roster/AddNewVolunteerConfirmDialog';

const ActivityApplicantRosterListItem = (
  props: {
    volunteer: ActivityTimelineFutureRosterVolunteer;
    activityApplicationId: string;
    primaryText: string;
    secondaryText: string;
    runSaveDraftRoster: () => void;
    addFullRostering: (args: { volunteerId: string }) => void;
  } & ListItemProps
) => {
  const { volunteer, activityApplicationId, primaryText, secondaryText, runSaveDraftRoster, addFullRostering } = props;
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpenDialog() {
    setDialogOpen(true);
  }

  return (
    <Fragment key={volunteer.volunteerId}>
      <ListItem button onClick={handleOpenDialog}>
        <Avatar
          size={32}
          preferredName={volunteer.profile.preferredName}
          lastName={volunteer.profile.lastName}
          avatarUrl={volunteer.profile.avatarUrl}
        />
        <ListItemText style={{ marginLeft: 8 }} primary={primaryText} secondary={secondaryText} />
      </ListItem>

      {dialogOpen ? (
        <AddNewVolunteerConfirmDialog
          volunteer={volunteer}
          preferredName={volunteer.profile.preferredName}
          activityApplicationId={activityApplicationId}
          open={dialogOpen}
          runSaveDraftRoster={runSaveDraftRoster}
          addFullRostering={addFullRostering}
          onClose={() => {
            setDialogOpen(false);
          }}
        />
      ) : null}
    </Fragment>
  );
};

export { ActivityApplicantRosterListItem };
