import { unpackToDateTimeTZ, unpackToDateTime } from '@campfire/hot-date';
import { EditorState, convertFromRaw } from 'draft-js';
import { DateTime } from 'luxon';
import { arrayHead } from '../../../../common/functions/array-head';
import { escapeDangerousCSVCharacters, replaceDoubleQuoteInString } from '../../../../common/functions/csv-utils';
import { ActivitiesConsoleCardExploreActivity } from '../activities-list/__generated__/ActivitiesConsoleCardExploreActivity';

export const buildRowCsv = (activity: ActivitiesConsoleCardExploreActivity, separator = ',') => {
  if (!activity) return '';

  const {
    name,
    description,
    startDate,
    endDate,
    activityLocation,
    activityRemoteLocation,
    sessions,
    priority,
  } = activity;

  const recurrences = getRecuttences(activity);
  const status = getActivityStatus(activity);
  const startDateFormated = startDate ? `${unpackToDateTimeTZ(startDate).toFormat('dd/MM/y h:mm')}` : '';
  const endDateFormated = endDate ? `${unpackToDateTimeTZ(endDate).toFormat('dd/MM/y h:mm')}` : '';
  const address = activityLocation ? activityLocation.placesAddress.description : activityRemoteLocation?.details;
  const sessionsString = sessions.map((item) => item.name).join(',');
  const descriptionText = EditorState.createWithContent(convertFromRaw(JSON.parse(description)))
    .getCurrentContent()
    .getPlainText();
  const priorityString = priority ? '1' : '0';

  const arrayRowData = [
    name ?? '',
    descriptionText ?? '',
    recurrences ?? '',
    status ?? '',
    startDateFormated ?? '',
    endDateFormated ?? '',
    address ?? '',
    sessionsString ?? '',
    priorityString ?? '0',
  ];

  return `"${arrayRowData
    .map((columnData) => escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
    .join(`"${separator}"`)}"`;
};

export const buildCsvData = (rowsCsv: string[], separator = ',') => {
  const csvHeaderArr = [
    'Activity Name',
    'Description',
    'Recurrence',
    'Status',
    'Start Date',
    'End Date',
    'Address',
    'Sessions',
    'Priority',
  ];

  const rowHeader = `"${csvHeaderArr.join(`"${separator}"`)}"`;
  const data = [rowHeader, ...rowsCsv];
  return `${data.join('\r\n')}`;
};

const getActivityStatus = (activity: ActivitiesConsoleCardExploreActivity) => {
  if (activity.isSuspended) return 'suspended';
  if (activity.closedActivity !== null) return 'closed';
  if (unpackToDateTime(activity.endDate) < DateTime.local()) return 'ended';
  return 'active';
};

const getRecuttences = (activity: ActivitiesConsoleCardExploreActivity) => {
  if (activity.__typename === 'VOLUNTEER_RecurringActivityType') {
    return arrayHead(activity.recurrences)?.humanReadable;
  }
  return '';
};
