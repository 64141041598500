import { unpackToTime, unpackToDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';

export enum ActivityActivenessEnum {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  CLOSED = 'closed',
  ENDED = 'ended',
}

interface ActivityTag {
  activityTagId: string;
  name: string;
}
interface Activity {
  name: string;
  activityTags: ActivityTag[];
}

export const filterBySearch = (value: string) => (activity: any) => {
  const activityActivityTagNames = activity.activityTags?.map((tag: ActivityTag) => tag.name.toLocaleLowerCase()) || [];
  return value
    ? activity.name.toLocaleLowerCase().includes(value.toLocaleLowerCase().trim()) ||
        activityActivityTagNames.includes(value.toLocaleLowerCase().trim())
    : true;
};

export const filterByProgram = (programId: string) => (activity: any) =>
  activity.program.programId === programId || programId === 'all';

export const filterByLocation = (locationType: string) => (activity: any) => {
  if (locationType === 'all') {
    return true;
  }
  if (locationType === 'remote') {
    return Boolean(activity.activityRemoteLocation);
  }
  return Boolean(activity.activityLocation);
};

export const filterByActivity = (activityType: string) => (activity: any) => {
  if (activityType === 'all') {
    return true;
  }
  if (activityType === 'regular') {
    return activity.__typename === 'VOLUNTEER_RecurringActivityType';
  }
  return activity.__typename === 'VOLUNTEER_NonRecurringActivityType';
};

export const getActivityActivenessStatus = (activity: any): ActivityActivenessEnum => {
  if (activity.isActive) {
    return ActivityActivenessEnum.ACTIVE;
  }
  if (activity.isSuspended && !activity.closedActivity) {
    return ActivityActivenessEnum.SUSPENDED;
  }
  if (activity.closedActivity) {
    return ActivityActivenessEnum.CLOSED;
  }
  if (activity.endDate && activity.endDate < DateTime.local().toISODate()) {
    return ActivityActivenessEnum.ENDED;
  }
  return ActivityActivenessEnum.ACTIVE;
};

export const filterByActivityActivenessStatus = (activityStatus: string) => (activity: any) => {
  return activityStatus === 'all' || activity.activityStatus === activityStatus;
};

export const filterByActivityStatus = (activityStatus: string) => (activity: any) => {
  if (activityStatus === 'all') {
    return true;
  }
  return activity.status === activityStatus;
};

export const filterByRosterType = (rosterType: string) => (activity: any) => {
  if (rosterType === 'all') {
    return true;
  }
  return activity.hasOpenRoster || rosterType === 'managed';
};

export const filterByStartTime = (startTime: string) => (activity: any) => {
  if (!startTime) {
    return true;
  }
  const activityStartTime = unpackToTime(activity.startTime);
  const past30Min = activityStartTime.minus({ minutes: 30 });
  const next30Min = activityStartTime.plus({ minutes: 30 });
  const selectedStartTime = unpackToTime(startTime);
  return selectedStartTime >= past30Min && selectedStartTime <= next30Min;
};

export const filterByEndTime = (endTime: string) => (activity: any) => {
  if (!endTime) {
    return true;
  }
  const activityEndTime = unpackToTime(activity.endTime);
  const past30Min = activityEndTime.minus({ minutes: 30 });
  const next30Min = activityEndTime.plus({ minutes: 30 });
  const selectedEndTime = unpackToTime(endTime);
  return selectedEndTime >= past30Min && selectedEndTime <= next30Min;
};

export const filterByDuration = (duration: number) => (activity: any) => {
  if (duration === 0) {
    return true;
  }

  return unpackToTime(activity.endTime).diff(unpackToTime(activity.startTime), ['hours']).hours <= duration;
};

export const filterByDay = (daysSelected: { [key: number]: boolean }) => (activity: any) => {
  if (Object.values(daysSelected).every((selectedDay) => !selectedDay)) {
    return true;
  }

  const dayOfActivityDate = unpackToDate(activity.activityDate).weekday;
  return daysSelected[dayOfActivityDate - 1];
};

export const filterByActivityTag = (tagsSelected: { [key: string]: boolean }) => (activity: Activity) => {
  if (Object.values(tagsSelected).every((selectedTag) => !selectedTag)) {
    return true;
  }
  const activityActivityTag = activity.activityTags;
  return Object.entries(tagsSelected)
    .filter((a) => a[1])
    .every((tagKeyValue) => activityActivityTag.find((tag) => tag.activityTagId === tagKeyValue[0]));
};

export const sortByActivityDate = (a: any, b: any) => {
  if (a.activityDate < b.activityDate) {
    return -1;
  }
  if (a.activityDate === b.activityDate) {
    return 0;
  }
  return 1;
};

export const sortByName = (a: any, b: any) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name === b.name) {
    return 0;
  }
  return 1;
};

export const sortByDuration = (a: any, b: any) => {
  const durationFunc = (activity: any) =>
    unpackToTime(activity.endTime).diff(unpackToTime(activity.startTime), ['hours']).hours;
  if (durationFunc(a) < durationFunc(b)) {
    return -1;
  }

  if (durationFunc(a) === durationFunc(b)) {
    return 0;
  }
  return 1;
};

export const sortByPriority = (a: any, b: any) => {
  if (a.priority > b.priority) {
    return -1;
  }
  if (a.priority === b.priority) {
    return sortByActivityDate(a, b);
  }
  return 1;
};
