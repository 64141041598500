import { useContext } from 'react';
import { PageVisibilityContext, PageVisibilityInterface } from './PageVisibilityContext';

const usePageVisibility = (): PageVisibilityInterface => {
  const pageVisibilityContext = useContext(PageVisibilityContext);

  if (!pageVisibilityContext) throw new Error('usePageVisibility must be initiated within an PageVisibilityProvider');

  return pageVisibilityContext;
};

export { usePageVisibility };
