import React from 'react';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { GridCloseIcon } from '@material-ui/data-grid';
import { useCampfireTheme } from '../../../../../../../../theme/useCampfireTheme';
import { ActivityReportType } from './IncompleteReportsRAWidget';
import { ActivityReport } from '../../../../../../activity-report/ActivityReports/ActivityReport';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedReport: ActivityReportType;
  refetchMainList: () => void;
}

export const IncompleteReportRAWidgetDialog = (props: Props) => {
  const { open, onClose, selectedReport, refetchMainList } = props;
  const { isMobile } = useCampfireTheme();
  return (
    <Dialog
      scroll='paper'
      open={open}
      onClose={onClose}
      title=''
      fullScreen={isMobile}
      PaperProps={{ style: { minWidth: isMobile ? 'auto' : '640px', maxWidth: '640px', paddingBottom: '24px' } }}
    >
      <DialogContent>
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 20 }}
        >
          <GridCloseIcon color='action' />
        </IconButton>
        <ActivityReport
          selectedActivityId={selectedReport.activityId}
          selectedActivityDate={selectedReport.activityDate}
          refetchMainList={refetchMainList}
          displayOnWidget
        />
      </DialogContent>
    </Dialog>
  );
};
