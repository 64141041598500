import { unpackToDate, unpackToDateTime } from '@campfire/hot-date';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      display: 'flex',
      width: '100%',
      borderRadius: '8px',
      boxSizing: 'border-box',
      overflow: 'hidden',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: `0px 0px 4px rgba(0, 0, 0, 0.25)`,
      },
    },
    cardNoHover: {
      display: 'flex',
      width: '100%',
      borderRadius: '8px',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
  })
);

interface LinkInfo {
  pathname: string;
  search: string;
}

interface DateInfoCardProps {
  date: string;
  color?: {
    primary: string;
    secondary: string;
    additional?: string;
  };
  children: ReactNode;
  size?: 's' | 'm' | 'l';
  selected?: boolean;
  noHover?: boolean;
  showYear?: boolean;
  classes?: { [key: string]: string };
}
interface Props extends DateInfoCardProps {
  linkInfo?: LinkInfo;
}

const Card = (props: DateInfoCardProps) => {
  const { date, color, children, selected, size, noHover, showYear, classes: customClasses = {} } = props;
  const { theme, isMobile, isMd } = useCampfireTheme();
  const classes = useStyles();

  const year = showYear
    ? unpackToDateTime(date)
        .year.toString()
        .substring(2, 4)
    : '';
  return (
    <Box
      className={classNames(customClasses.root, { [classes.cardNoHover]: noHover, [classes.card]: !noHover })}
      border={selected ? `2px solid ${color?.primary}` : `1px solid ${theme.color.grey.border}`}
      borderBottom={selected ? `2px solid ${color?.primary}` : `1px solid ${theme.color.grey.border}`}
      borderRight={selected ? `2px solid ${color?.primary}` : `1px solid ${theme.color.grey.border}`}
      bgcolor={color?.primary ?? theme.color.grey.neutralBrand100}
      overflow={'hidden'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        minWidth={'64px'}
        padding={''}
        width={!isMobile ? (size === 's' ? '64px' : size === 'm' ? '90px' : '120px') : '80px'}
        borderRadius={'8px 0px 0px 8px'}
        className={customClasses.dayContainer}
      >
        <Typography
          variant={'h5'}
          style={{
            color: color && color.additional ? color.additional : color ? 'white' : theme.color.grey.neutral300,
            fontWeight: 900,
            fontSize: '30px',
          }}
          className={customClasses.dayText}
        >
          {unpackToDate(date).day}
        </Typography>
        <Typography
          className={customClasses.monthText}
          variant={'body2'}
          style={{ color: color?.secondary ?? theme.color.grey.neutral200, fontSize: '14px', fontWeight: 'bold' }}
        >
          {unpackToDate(date).monthShort} {year}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        flexGrow={2}
        alignItems={'flex-start'}
        justifyContent={'center'}
        style={{ padding: '18px 18px' }}
        overflow={'hidden'}
        width={!isMobile || !isMd ? '300px' : '250px'}
        bgcolor={theme.color.white.neutral50}
        className={customClasses.bodyContainer}
      >
        {children}
      </Box>
    </Box>
  );
};

export const DateInfoCard = (props: Props) => {
  const { date, linkInfo, color, children, selected, size, noHover, showYear, classes } = props;
  if (linkInfo) {
    const { pathname, search } = linkInfo;
    return (
      <Link
        to={{
          pathname,
          search,
        }}
        style={{ textDecoration: 'none', width: '100%', color: '#000' }}
      >
        <Card
          date={date}
          color={color}
          selected={selected}
          size={size}
          noHover={noHover}
          classes={classes}
          showYear={showYear}
        >
          {children}
        </Card>
      </Link>
    );
  }
  return (
    <Card
      date={date}
      color={color}
      selected={selected}
      size={size}
      noHover={noHover}
      classes={classes}
      showYear={showYear}
    >
      {children}
    </Card>
  );
};
