import { useState } from 'react';
import { useBetween } from 'use-between';
import { GetRostersSidebarData } from '../__generated__/GetRostersSidebarData';

const useRosterData = () => {
  const [rosterData, setRosterData] = useState<GetRostersSidebarData>();
  return {
    rosterData,
    setRosterData,
  };
};

export const useSharedRosterData = () => useBetween(useRosterData);
