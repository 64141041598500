import { FileUploadGallery, UploadedFile } from '@campfire/file-upload-gallery';
import { Box, DialogContent, Divider, Typography } from '@material-ui/core';
import React, { memo, useMemo, ReactNode } from 'react';
import { useUser } from '../../../../global/auth/useUser';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ActivityInfo } from '../Activity/ActivityInfo';
import { ActivitiesExploreActivity } from '../__generated__/ActivitiesExploreActivity';
import { ActivitiesExploreActivityApplication } from '../__generated__/ActivitiesExploreActivityApplication';
import { GET_ACTIVITY_ATTACHMENTS_SECTION } from './activities-explore-activity-dialog-attachments-model.gql';
import { ActivitiesExploreActivityWaitlistApplication } from './ActivitiesExploreActivityWaitlistApplication';
import { ActivitiesExploreNonPrivateActivityApplication } from './ActivitiesExploreNonPrivateActivityApplication';
import { ActivitiesExplorePrivateActivityApplication } from './ActivitiesExplorePrivateActivityApplication';
import {
  GetActivityAttachmentsSection,
  GetActivityAttachmentsSectionVariables,
} from './__generated__/GetActivityAttachmentsSection';
import { FullscreenDialog } from '../../../../common/dialogs/FullscreenDialog';

export const ActivityEnrollmentPrompt = ({
  title,
  description,
  action,
}: {
  title: string;
  description: string | ReactNode;
  action: ReactNode;
}) => (
  <Box p={3} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
    <Typography variant='h6' gutterBottom>
      {title}
    </Typography>
    {typeof description === 'string' ? (
      <Typography variant='body2' color='textSecondary' align='center' gutterBottom>
        {description}
      </Typography>
    ) : (
      description
    )}

    <Box pt={2}>{action}</Box>
  </Box>
);

type ActivitiesExploreActivityDialogProps = {
  open: boolean;
  onClose: () => void;
  activity: ActivitiesExploreActivity;
  volunteerActivityApplication?: ActivitiesExploreActivityApplication;
  programsLoading: boolean;
  refetch?: () => void;
};
const ActivitiesExploreActivityDialog = memo(
  ({
    open,
    onClose,
    activity,
    volunteerActivityApplication,
    programsLoading,
    refetch,
  }: ActivitiesExploreActivityDialogProps) => {
    const { activityId, activityEnrolments, isEnrollable, isRestrictedActivity } = activity;
    const { isMobile } = useCampfireTheme();
    const {
      getVolunteerIdentity,
      user: { userIdentityService },
    } = useUser();
    const volunteerIdentity = getVolunteerIdentity();
    const isVolunteerEnrolled = activityEnrolments.find(
      (enrolment) => enrolment.volunteer.volunteerId === volunteerIdentity.volunteerId
    );
    const isOnWaitlist = userIdentityService.isOnWaitlist(activity, volunteerIdentity.volunteerId);
    const isWaitingToBeEnrolled = !!volunteerActivityApplication && !volunteerActivityApplication.dateActioned;
    const volunteerHasAvailability = useMemo(
      () =>
        !!isVolunteerEnrolled?.availabilities.length ||
        !!isVolunteerEnrolled?.volunteer.rosterings.find(
          (rostering) => rostering.publishedRoster.activity.activityId === activityId
        ),
      [isVolunteerEnrolled]
    );

    return (
      <FullscreenDialog scroll='paper' open={open} close={onClose} fullScreen={isMobile} title={'Activity Information'}>
        <DialogContent
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            minWidth: isMobile ? 290 : 400,
          }}
        >
          <Box px={3} display='flex'>
            <ActivityInfo
              bodyDisplayState={isVolunteerEnrolled ? 'withMinActivityDetail' : 'withAllActivityDetail'}
              activity={activity}
            />
          </Box>

          <Box px={3} py={2}>
            <AttachmentsSection activityId={activityId} />
          </Box>
          <Divider />
          {(isEnrollable && !isOnWaitlist) || (isWaitingToBeEnrolled && !isOnWaitlist) || isVolunteerEnrolled ? (
            isRestrictedActivity ? (
              <ActivitiesExplorePrivateActivityApplication
                isVolunteerEnrolled={!!isVolunteerEnrolled}
                volunteerHasAvailability={volunteerHasAvailability}
                isWaitingToBeEnrolled={isWaitingToBeEnrolled}
                activityApplicationId={volunteerActivityApplication?.activityApplicationId}
                activityId={activity.activityId}
                programsLoading={programsLoading}
                refetch={refetch}
              />
            ) : (
              <ActivitiesExploreNonPrivateActivityApplication
                isVolunteerEnrolled={!!isVolunteerEnrolled}
                volunteerHasAvailability={volunteerHasAvailability}
                activityId={activity.activityId}
                programsLoading={programsLoading}
                refetch={refetch}
              />
            )
          ) : (
            <ActivitiesExploreActivityWaitlistApplication
              activity={activity}
              programsLoading={programsLoading}
              refetch={refetch}
            />
          )}
        </DialogContent>
      </FullscreenDialog>
    );
  }
);

const AttachmentsSection = (props: { activityId: string }) => {
  const { activityId } = props;

  const { data: response } = useCampfireQuery<GetActivityAttachmentsSection, GetActivityAttachmentsSectionVariables>(
    GET_ACTIVITY_ATTACHMENTS_SECTION,
    {
      options: {
        variables: {
          activityId: activityId,
        },
      },
    }
  );

  const activity = useMemo(() => {
    if (!response?.vm.activity) return undefined;
    return response.vm.activity;
  }, [response]);

  const uploadedFiles: Array<UploadedFile> | undefined = useMemo(
    () =>
      activity?.activityAttachments.map((attachment) => ({
        url: attachment.url,
        fileId: attachment.activityAttachmentId,
        name: attachment.name,
      })),
    [activity]
  );

  return uploadedFiles ? (
    <>
      <Typography variant='body2' color='textSecondary' style={{ fontWeight: 'bold' }}>
        {'Attachments'}
      </Typography>
      <FileUploadGallery allowMultiple uploadedFiles={uploadedFiles} viewOnly />
    </>
  ) : null;
};

export { ActivitiesExploreActivityDialog };
