import React, { useContext, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { DASHBOARD_PREFERENCE_MODEL } from '../../general/vol-dashboard/vol-dashboard-my-elements/management-dashboard/dashboard-widget-setting/dashboard-preference-model.gql';
import {
  GetDashboardPreference,
  GetDashboardPreferenceVariables,
  GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents as IDashboardComponent,
} from '../../general/vol-dashboard/vol-dashboard-my-elements/management-dashboard/dashboard-widget-setting/__generated__/GetDashboardPreference';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { useUser } from '../../../global/auth/useUser';
import {
  useSaveWidget,
  useSaveWidgetLayout,
} from '../../general/vol-dashboard/vol-dashboard-my-elements/management-dashboard/dashboard-widget-setting/useSaveWidget';
import { useDeleteWidget } from '../../general/vol-dashboard/vol-dashboard-my-elements/management-dashboard/dashboard-widget-setting/useDeleteWidget';
import { CreatWidgetFormValues } from '../../general/vol-dashboard/vol-dashboard-my-elements/management-dashboard/dashboard-widget-setting/dashboard-widget-model';
import { useDeepEffect } from '../../../hooks/useDeepEffect';
import { CreateWidgetDialog } from '../../general/vol-dashboard/vol-dashboard-my-elements/management-dashboard/dashboard-widget-setting/CreateWidgetDialog';

interface ManagementDashboardContextInterface {
  showEditWidget: boolean;
  setShowEditWidget: React.Dispatch<React.SetStateAction<boolean>>;
  components: IDashboardComponent[];
  layouts: Layout[];
  onLayoutChange: (values: Layout[]) => void;
  onSubmitLayout: () => void;
  onCancelChangeLayout: () => void;
  onDeleteWidget: (id: string) => void;
  onEditWidget: (c: IDashboardComponent) => void;
  editLayout: boolean;
  setEditLayout: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  selectedWidget?: IDashboardComponent;
  setSelectedWidget: (c: IDashboardComponent) => void;
}

interface ManagementDashboardProviderProps {
  children: React.ReactNode;
}

export const ManagementDashboardContext = React.createContext<ManagementDashboardContextInterface>({
  showEditWidget: false,
  setShowEditWidget: () => {},
  components: [] as IDashboardComponent[],
  layouts: [] as Layout[],
  onLayoutChange: () => {},
  onSubmitLayout: () => {},
  onCancelChangeLayout: () => {},
  onDeleteWidget: () => {},
  onEditWidget: () => {},
  editLayout: false,
  setEditLayout: () => {},
  loading: false,
  selectedWidget: undefined,
  setSelectedWidget: () => {},
});

const ManagementDashboardProvider = (props: ManagementDashboardProviderProps) => {
  const [showEditWidget, setShowEditWidget] = useState<boolean>(false);
  const [editLayout, setEditLayout] = useState<boolean>(false);
  const [selectedWidget, setSelectedWidget] = useState<IDashboardComponent>();

  const { user } = useUser();
  const { userId } = user;

  const { data: vmProfile, refetch: refetchProfile, loading } = useCampfireQuery<
    GetDashboardPreference,
    GetDashboardPreferenceVariables
  >(DASHBOARD_PREFERENCE_MODEL, { options: { variables: { userId } } });

  const components = vmProfile?.vm.profile?.dashboardPreference?.dashboardComponents || [];

  const [layouts, setLayouts] = React.useState<Layout[]>(
    components.map((dashBoardComponent) => ({
      i: dashBoardComponent.dashboardComponentId,
      x: dashBoardComponent.position.row,
      y: dashBoardComponent.position.col,
      h: dashBoardComponent.position.rowSpan,
      w: dashBoardComponent.position.colSpan,
    }))
  );

  const saveWidget = useSaveWidget();
  const saveWidgetLayout = useSaveWidgetLayout();
  const removeWidget = useDeleteWidget();

  const onUpdateWidget = (values: CreatWidgetFormValues) => {
    const maxY = Math.max(...layouts.map(({ y }) => y));
    const lastItem = layouts.filter((l) => l.y === maxY).sort((a, b) => (a.x > b.x ? -1 : 1))[0];
    const nextItem = lastItem
      ? lastItem.x + lastItem.w < 4
        ? { x: lastItem.x + lastItem.w, y: maxY }
        : { x: 0, y: maxY + lastItem.h }
      : { x: 0, y: 0 };

    saveWidget(
      values,
      {
        row: nextItem.x,
        col: nextItem.y,
        rowSpan: 1,
        colSpan: 1,
      },
      selectedWidget?.dashboardComponentId
    ).then(() => {
      if (refetchProfile) {
        refetchProfile();
      }
      handleCloseEditDialog();
    });
  };

  const onLayoutChange = (newLayouts: Layout[]) => {
    if (editLayout) {
      setLayouts(newLayouts);
    }
  };

  useDeepEffect(() => {
    setLayouts(
      components.map((dashBoardComponent) => ({
        i: dashBoardComponent.dashboardComponentId,
        x: dashBoardComponent.position.row,
        y: dashBoardComponent.position.col,
        h: dashBoardComponent.position.rowSpan,
        w: dashBoardComponent.position.colSpan,
      }))
    );
  }, [components]);

  const onCancelChangeLayout = () => {
    setLayouts(
      components.map((dashBoardComponent) => ({
        i: dashBoardComponent.dashboardComponentId,
        x: dashBoardComponent.position.row,
        y: dashBoardComponent.position.col,
        h: dashBoardComponent.position.rowSpan,
        w: dashBoardComponent.position.colSpan,
      }))
    );
    setEditLayout(false);
  };

  const onSubmitLayout = () => {
    Promise.all(
      layouts.map((layout) => {
        return saveWidgetLayout(layout.i, {
          row: layout.x,
          col: layout.y,
          rowSpan: layout.h,
          colSpan: layout.w,
        });
      })
    ).then(() => {
      if (refetchProfile) {
        refetchProfile();
      }
      setEditLayout(false);
    });
  };

  const onEditWidget = (widget: IDashboardComponent) => {
    setSelectedWidget(widget);
    setShowEditWidget(true);
  };

  const onDeleteWidget = (dashboardComponentId: string) => {
    removeWidget({ dashboardComponentId }).then(() => {
      if (refetchProfile) {
        refetchProfile();
      }
    });
  };

  const handleCloseEditDialog = () => {
    setShowEditWidget(false);
    setSelectedWidget(undefined);
  };

  const value = {
    showEditWidget,
    setShowEditWidget,
    layouts: layouts,
    onLayoutChange,
    components,
    onCancelChangeLayout,
    onSubmitLayout,
    onDeleteWidget,
    onEditWidget,
    editLayout,
    setEditLayout,
    loading,
    selectedWidget,
    setSelectedWidget,
  };

  return (
    <ManagementDashboardContext.Provider value={value} {...props}>
      {props.children}
      {showEditWidget && (
        <CreateWidgetDialog
          selectedWidget={selectedWidget}
          open={showEditWidget}
          onClose={handleCloseEditDialog}
          handleSubmit={onUpdateWidget}
        />
      )}
    </ManagementDashboardContext.Provider>
  );
};

export const useManagementDashboardContext = () => {
  const value = useContext(ManagementDashboardContext);
  if (!value) throw new Error('Must be inside of a ManagementDashboardProvider');
  return value;
};

export { ManagementDashboardProvider };
