import { formatDistanceDays } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown, LocationOnRounded } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { Calendar, DateObject } from 'react-multi-date-picker';
import React, { useEffect, useState } from 'react';
import { encodeDate } from 'use-query-params';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { getDisplayTimeSchedule } from '../../../../../common/functions/activity-display-helpers';
import { IconsTextRowLayout } from '../../../../../common/icons/IconsTextRowLayout';
import { MyElementsUpcomingRostersActivity } from './__generated__/MyElementsUpcomingRostersActivity';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import {
  GetMyElementsRosterOccurrences,
  GetMyElementsRosterOccurrencesVariables,
} from './__generated__/GetMyElementsRosterOccurrences';
import { GET_MY_ELEMENTS_ROSTER_OCCURRENCES } from './my-elements-upcoming-rosters-model.gql';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import '../../../../../theme/src/multi-date-picker.css';
import { BorderSelectInput, useFilterStyles } from '../../../../../common/filter-fields/FilterClasses';
import { useUser } from '../../../../../global/auth/useUser';

type Session = {
  sessionId: string;
  startTime: string;
  endTime: string;
  name: string;
  activityLocation?: {
    placesAddress?: {
      placesId: string;
      description: string;
    };
  };
};

interface SessionListItemProps {
  session: Session;
  activityLocationPlacesId?: string | null;
  checked: boolean;
  disabled?: boolean;
  handleClick: (sessionId: string) => void;
}

const SessionListItem = ({
  session,
  checked,
  handleClick,
  disabled,
  activityLocationPlacesId,
}: SessionListItemProps) => {
  const sessionHasPlacesId = !!session.activityLocation?.placesAddress?.placesId;

  return (
    <ListItem
      key={session.sessionId}
      onClick={() => (disabled ? undefined : handleClick(session.sessionId))}
      button={(!disabled as true | undefined) as true} // take the piss, MUI https://github.com/mui-org/material-ui/issues/14971#issuecomment-581402174
    >
      <ListItemIcon>
        <Checkbox color='primary' tabIndex={-1} checked={checked} />
      </ListItemIcon>

      <Box paddingY={1}>
        <Typography variant='body1' color='textPrimary'>{`${session.name}`}</Typography>
        <Typography variant='body2' color='textSecondary'>
          {getDisplayTimeSchedule(session.startTime, session.endTime)}
        </Typography>

        {activityLocationPlacesId &&
        sessionHasPlacesId &&
        session.activityLocation?.placesAddress?.placesId !== activityLocationPlacesId ? (
          <IconsTextRowLayout
            leftIcon={<LocationOnRounded color='disabled' />}
            text={session.activityLocation?.placesAddress?.description}
          />
        ) : null}
      </Box>
    </ListItem>
  );
};

interface Props {
  activity: MyElementsUpcomingRostersActivity;
  dateOfRoster: DateTime;
  sessions: Array<{ sessionId: string; name: string; startTime: string; endTime: string }>;
  selectedSessions: Array<string>;
  open: boolean;
  onClose: () => void;
  onSubmit: (x: number[], y: string[]) => void;
}

const InnerActivitySessionsSelectDialog = (props: Props) => {
  const { activity, dateOfRoster, sessions, onClose, onSubmit, selectedSessions } = props;
  const { theme, isXs } = useCampfireTheme();
  const classes = useFilterStyles(theme);
  const [selectedSessionIds, setSelectedSessionIds] = useState<string[]>(selectedSessions);
  const [errorMsg, setErrorMsg] = useState<string>();
  const isSingleSession = sessions.length === 1;
  const [popoverOpenFrequency, setPopoverOpenFrequency] = useState<boolean>(false);
  const [popoverOpenPeriod, setPopoverOpenPeriod] = useState<boolean>(false);
  const [availabilityDates, setAvailabilityDates] = useState<any>();
  const [frequencySelectionValue, setFrequencySelectionValue] = useState<any>('once');
  const [lengthSelectionValue, setLengthSelectionValue] = useState<any>('un');
  const currentDate = new Date();
  const viewStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const viewEndDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth() + 1, 0);

  const { data, loading } = useCampfireQuery<GetMyElementsRosterOccurrences, GetMyElementsRosterOccurrencesVariables>(
    GET_MY_ELEMENTS_ROSTER_OCCURRENCES,
    {
      options: {
        variables: {
          activityId: activity.activityId,
          endDate: encodeDate(viewEndDate),
        },
      },
    }
  );

  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();

  const activityRecurrenceType =
    data?.vm.activity?.__typename === 'VOLUNTEER_RecurringActivityType'
      ? data.vm.activity.recurrences[0].__typename
      : 'SingleRecurrenceType';
  const activityOccurrences =
    data?.vm.activity?.nextUntil.map((ao) => {
      return Date.parse(ao);
    }) ?? [];

  const [occurrencesAlreadyAttending, setOccurencesAlreadyAttending] = useState<Array<any>>();

  useEffect(() => {
    const mappedOccurrences = data?.vm.activity?.activityEnrolments.flatMap((o) => {
      return o.volunteer.volunteerId === volunteerId
        ? o.availabilities.flatMap((oa) => {
            return oa.isActive ? oa.activityDate : null;
          })
        : null;
    });
    if (!occurrencesAlreadyAttending) {
      setOccurencesAlreadyAttending(mappedOccurrences?.flat(1));
    }
  });

  useEffect(() => {
    if (isSingleSession && selectedSessionIds.length === 0) setSelectedSessionIds(sessions.map((x) => x.sessionId));
  }, [sessions]);

  useEffect(() => {
    if (selectedSessionIds.length >= 1) setErrorMsg(undefined);
  }, [selectedSessionIds]);

  const handleClick = (sessionId: string) => {
    setSelectedSessionIds(
      selectedSessionIds.includes(sessionId)
        ? selectedSessionIds.filter((selectedSessionId) => selectedSessionId !== sessionId)
        : [...selectedSessionIds, sessionId]
    );
  };

  const handleSubmit = () => {
    if (selectedSessionIds.length < 1) {
      setErrorMsg('Please select one or more sessions');
      setAvailabilityStep(0);
      return 0;
    }
    onSubmit(availabilityDates, selectedSessionIds);
    return 0;
  };

  const setAvailabilityDatesFromDateObject = (dateObject: DateObject[] | null) => {
    setAvailabilityDates(
      dateObject
        ? dateObject.map((ao) => {
            return Date.parse(ao.format('YYYY-MM-DD'));
          })
        : []
    );
  };

  const distanceInDays = formatDistanceDays(dateOfRoster);
  const daySubtitle = dateOfRoster.toLocaleString({
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  });

  const [availabilityStep, setAvailabilityStep] = useState(0);

  const onNext = () => {
    setAvailabilityStep(availabilityStep + 1);
  };

  const onPrev = () => {
    setAvailabilityStep(availabilityStep - 1);
  };

  const recurrenceTypeReadable = () => {
    if (
      data?.vm.activity?.__typename === 'VOLUNTEER_RecurringActivityType' &&
      data.vm.activity.recurrences[0].__typename === 'DailyRecurrenceType'
    ) {
      return data.vm.activity.recurrences[0].dayInterval === 1 ? 'day' : 'occurrence';
    }
    if (
      data?.vm.activity?.__typename === 'VOLUNTEER_RecurringActivityType' &&
      data.vm.activity.recurrences[0].__typename === 'WeeklyRecurrenceType'
    ) {
      return data.vm.activity.recurrences[0].weekInterval === 2 ? 'fortnight' : 'week';
    }
    if (activityRecurrenceType === 'MonthlyNthDayRecurrenceType') {
      return 'month';
    }
    if (activityRecurrenceType === 'MonthlyByMonthDayRecurrenceType') {
      return 'month';
    }
    if (activityRecurrenceType === 'YearlyRecurrenceType') {
      return 'year';
    }
    return '';
  };

  const everyNth = (arr: Array<any>, nth: any) => arr.filter((e, i) => (e ? i % nth === nth - 2 : null));

  const filteredActivityOccurrences = activityOccurrences.filter((o: number) => {
    return !occurrencesAlreadyAttending?.find((oa) => {
      const date = new Date(o);

      if (encodeDate(date) === oa) {
        return date;
      }
      return null;
    });
  });

  useEffect(() => {
    if (!loading && data && !availabilityDates) {
      updateSelection('once');
    }
    if (!loading && data && lengthSelectionValue === 'un') {
      if (frequencySelectionValue === 'once') {
        setLengthSelectionValue('na');
      }
    }
  });

  const updateSelection = (selection: any, filter?: any) => {
    setFrequencySelectionValue(selection);

    if (filter) {
      setLengthSelectionValue(filter);
    }
    const filterTime = filter ?? lengthSelectionValue;

    const filterSelection = (occurrences: Array<any>, filterBy: any) => {
      if (filterBy === '1w') {
        const filteredOccurrences = occurrences.filter((o: number) => {
          const date = new Date(o);
          const compareDate = new Date(new Date().setDate(currentDate.getDate() + 7));
          return date <= compareDate;
        });
        setAvailabilityDates(filteredOccurrences);
      }
      if (filterBy === '1m') {
        const filteredOccurrences = occurrences.filter((o: number) => {
          const date = new Date(o);
          // const compareDate = new Date(new Date().setDate(currentDate.getMonth()));
          return currentDate.getMonth() === date.getMonth() && currentDate.getFullYear() === date.getFullYear();
        });
        setAvailabilityDates(filteredOccurrences);
      }
      if (filterBy === '3m') {
        const filteredOccurrences = occurrences.filter((o: number) => {
          const date = new Date(o);
          const compareDate = currentDate.getMonth() + 3;
          const remainderDate = compareDate - 13;
          // const compareDate = new Date(new Date().setDate(currentDate.getDate() + 90));

          return remainderDate > 0
            ? date.getMonth() <= remainderDate ||
                (date.getFullYear() === currentDate.getFullYear() && date.getMonth() <= currentDate.getMonth() + 3)
            : date.getFullYear() === currentDate.getFullYear() && date.getMonth() <= currentDate.getMonth() + 3;
        });
        setAvailabilityDates(filteredOccurrences);
      }
      if (filterBy === '6m') {
        const filteredOccurrences = occurrences.filter((o: number) => {
          const date = new Date(o);
          const compareDate = currentDate.getMonth() + 6;
          const remainderDate = compareDate - 13;
          // const compareDate = new Date(new Date().setDate(currentDate.getDate() + 90));

          return remainderDate > 0
            ? date.getMonth() <= remainderDate ||
                (date.getFullYear() === currentDate.getFullYear() && date.getMonth() <= currentDate.getMonth() + 6)
            : date.getFullYear() === currentDate.getFullYear() && date.getMonth() <= currentDate.getMonth() + 6;
        });
        setAvailabilityDates(filteredOccurrences);
      }
      if (filterBy === '12m') {
        setAvailabilityDates(occurrences);
      }
    };

    if (selection === 'once') {
      setAvailabilityDates([dateOfRoster.toJSDate()]);
    }
    if (selection === 'all') {
      filterSelection(filteredActivityOccurrences, filterTime);
    }
    if (selection === 'even') {
      filterSelection(everyNth(filteredActivityOccurrences, 2), filterTime);
    }
    if (selection === 'custom') {
      setAvailabilityDates(availabilityDates);
    }
  };

  const isSelected = (x: any, y: any) => {
    return x === y;
  };

  const isSingleRecurrenceType = () => {
    return activityRecurrenceType === 'SingleRecurrenceType';
  };

  const isSingleSelectedOccurrence = () => {
    return frequencySelectionValue === 'once';
  };

  const isCustomSelectedOccurrence = () => {
    return frequencySelectionValue === 'custom';
  };

  const availabilityStep1 = (
    <DialogContent>
      <Typography component='h2' variant='h6'>
        {`All Sessions`}
      </Typography>

      <Typography variant='body2' color='textSecondary'>
        {`Select the sessions that you're available for`}
      </Typography>

      <List>
        {sessions.map((session) => (
          <SessionListItem
            disabled={isSingleSession}
            key={session.sessionId}
            session={session}
            activityLocationPlacesId={activity.activityLocation?.placesAddress.placesId}
            checked={selectedSessionIds?.includes(session.sessionId)}
            handleClick={handleClick}
          />
        ))}
      </List>
      {errorMsg ? (
        <Typography color='error' variant='caption' gutterBottom style={{ paddingLeft: 24 }}>
          {errorMsg}
        </Typography>
      ) : null}
    </DialogContent>
  );

  const availabilityStep2 = (
    <DialogContent>
      <Typography component='h2' variant='h6'>
        {`Available Dates`}
      </Typography>

      <Typography variant='body2' color='textSecondary'>
        {`Select how often you are available`}
      </Typography>
      <Box paddingTop={'20px'} display={isXs ? 'block' : 'flex'} justifyContent='space-between'>
        <Box width={isXs ? '100%' : '50%'}>
          <FormControl component='fieldset' style={{ minWidth: '95%' }}>
            <Grid item className={classes.subheaderGridItemNew} style={{ minWidth: '95%' }}>
              <Typography
                style={{ fontSize: '14px', color: theme.color.grey.neutral300, fontWeight: 600, paddingBottom: '5px' }}
              >
                Choose the frequency
              </Typography>
              <Select
                value={frequencySelectionValue}
                onChange={(e) => {
                  updateSelection(e.target.value);
                }}
                displayEmpty
                fullWidth
                open={popoverOpenFrequency}
                style={{
                  borderColor: '#9e9e9e',
                  color: theme.color.grey.neutral400,
                  width: '100%',
                }}
                input={
                  <BorderSelectInput
                    status={'active'}
                    onClick={() => setPopoverOpenFrequency(!popoverOpenFrequency)}
                    style={{ cursor: 'pointer' }}
                  />
                }
                IconComponent={() => (
                  <Box
                    display='flex'
                    alignItems='center'
                    onClick={() => setPopoverOpenFrequency(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    <ArrowDropDown style={{ fill: '#9e9e9e' }} />
                  </Box>
                )}
              >
                <MenuItem
                  className={classes.menuItem}
                  style={isSelected('once', frequencySelectionValue) ? { fontWeight: 600 } : {}}
                  value='once'
                >
                  Just this once ({daySubtitle})
                </MenuItem>

                <MenuItem
                  className={classes.menuItem}
                  style={
                    isSelected('all', frequencySelectionValue)
                      ? { fontWeight: 600 }
                      : { display: isSingleRecurrenceType() ? 'none' : 'inherit' }
                  }
                  value='all'
                >
                  Every {recurrenceTypeReadable()}
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  style={
                    isSelected('even', frequencySelectionValue)
                      ? { fontWeight: 600 }
                      : { display: isSingleRecurrenceType() ? 'none' : 'inherit' }
                  }
                  value='even'
                >
                  Every {recurrenceTypeReadable() === 'week' ? 'fortnight' : `second ${recurrenceTypeReadable()}`}
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  style={
                    isSelected('custom', frequencySelectionValue)
                      ? { fontWeight: 600 }
                      : { display: isSingleRecurrenceType() ? 'none' : 'inherit' }
                  }
                  value='custom'
                >
                  Custom
                </MenuItem>
              </Select>
            </Grid>
          </FormControl>
        </Box>
        <Box paddingTop={isXs ? '10px' : '0'} width={isXs ? '100%' : '50%'}>
          <FormControl component='fieldset' style={{ minWidth: '95%' }}>
            <Grid item className={classes.subheaderGridItemNew} style={{ minWidth: '95%' }}>
              <Typography
                style={{ fontSize: '14px', color: theme.color.grey.neutral300, fontWeight: 600, paddingBottom: '5px' }}
              >
                Choose the length of time
              </Typography>
              <Select
                value={
                  frequencySelectionValue === 'once' || frequencySelectionValue === 'custom'
                    ? 'na'
                    : lengthSelectionValue
                }
                onChange={(e) => {
                  updateSelection(frequencySelectionValue, e.target.value);
                }}
                displayEmpty
                fullWidth
                style={{
                  borderColor: '#9e9e9e',
                  color: theme.color.grey.neutral400,
                  width: '100%',
                }}
                open={popoverOpenPeriod}
                input={
                  <BorderSelectInput
                    status={'active'}
                    onClick={() =>
                      frequencySelectionValue !== 'once' || frequencySelectionValue !== 'custom'
                        ? setPopoverOpenPeriod(!popoverOpenPeriod)
                        : ''
                    }
                    style={{ cursor: 'pointer' }}
                  />
                }
                IconComponent={() => (
                  <Box
                    display='flex'
                    alignItems='center'
                    onClick={() =>
                      frequencySelectionValue !== 'once' || frequencySelectionValue !== 'custom'
                        ? setPopoverOpenPeriod(true)
                        : ''
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <ArrowDropDown
                      style={{
                        fill: '#9e9e9e',
                      }}
                    />
                  </Box>
                )}
              >
                {frequencySelectionValue === 'once' || frequencySelectionValue === 'custom' ? (
                  <MenuItem value='na'>N/A</MenuItem>
                ) : null}
                <MenuItem
                  className={classes.menuItem}
                  style={isSelected('1w', lengthSelectionValue) ? { fontWeight: 600 } : {}}
                  value='1w'
                  disabled={isSingleRecurrenceType() || isSingleSelectedOccurrence()}
                >
                  1 Week
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  style={isSelected('1m', lengthSelectionValue) ? { fontWeight: 600 } : {}}
                  value='1m'
                  disabled={isSingleRecurrenceType() || isSingleSelectedOccurrence() || isCustomSelectedOccurrence()}
                >
                  1 Month
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  style={isSelected('3m', lengthSelectionValue) ? { fontWeight: 600 } : {}}
                  value='3m'
                  disabled={isSingleRecurrenceType() || isSingleSelectedOccurrence() || isCustomSelectedOccurrence()}
                >
                  3 Months
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  style={isSelected('6m', lengthSelectionValue) ? { fontWeight: 600 } : {}}
                  value='6m'
                  disabled={isSingleRecurrenceType() || isSingleSelectedOccurrence() || isCustomSelectedOccurrence()}
                >
                  6 Months
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  style={isSelected('12m', lengthSelectionValue) ? { fontWeight: 600 } : {}}
                  value='12m'
                  disabled={isSingleSelectedOccurrence() || isSingleRecurrenceType() || isCustomSelectedOccurrence()}
                >
                  12 Months
                </MenuItem>
              </Select>
            </Grid>
          </FormControl>
        </Box>
      </Box>
      <Box width={'100%'} paddingTop={'35px'}>
        <Calendar
          value={availabilityDates}
          onChange={(dateObject: Array<any>) => {
            setAvailabilityDatesFromDateObject(dateObject);
          }}
          multiple
          minDate={viewStartDate}
          maxDate={viewEndDate}
          format={'DD MMM YY'}
          className='multi-date-picker'
          mapDays={({ date }) => {
            const isActivityOccurrence = filteredActivityOccurrences
              ? filteredActivityOccurrences.includes(Date.parse(date.format('YYYY-MM-DD')))
              : null;

            return {
              disabled: !isActivityOccurrence,
            };
          }}
          plugins={[<DatePanel position='right' sort='date' />]}
        />
      </Box>
    </DialogContent>
  );

  return (
    <>
      <Box>
        <DialogContent dividers>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            <Box paddingRight={2}>
              <Typography component='h2' variant='body2'>
                {activity.name}
              </Typography>
              <Typography component='h3' variant='h6'>
                {`${daySubtitle} (${distanceInDays})`}
              </Typography>

              <Box paddingTop={0.5}>
                {activity.activityLocation?.placesAddress ? (
                  <IconsTextRowLayout
                    leftIcon={<LocationOnRounded color='action' />}
                    text={activity.activityLocation.placesAddress.description}
                  />
                ) : activity.activityRemoteLocation?.activityRemoteLocationId ? (
                  <IconsTextRowLayout leftIcon={<LocationOnRounded color='action' />} text='This activity is remote.' />
                ) : null}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        {availabilityStep === 1 ? availabilityStep2 : availabilityStep1}

        <DialogActions>
          {availabilityStep < 1 ? (
            <TabletButton size='medium' variant='text' onClick={onClose}>
              {'Cancel'}
            </TabletButton>
          ) : null}
          {availabilityStep > 0 ? (
            <TabletButton size='medium' variant='text' onClick={onPrev}>
              {'Previous'}
            </TabletButton>
          ) : null}
          {availabilityStep < 1 ? (
            <TabletButton size='medium' variant='contained' color='primary' onClick={onNext}>
              {'Next'}
            </TabletButton>
          ) : null}
          {availabilityStep === 1 ? (
            <TabletButton size='medium' variant='contained' color='primary' onClick={() => handleSubmit()}>
              {'Done'}
            </TabletButton>
          ) : null}
        </DialogActions>
      </Box>
    </>
  );
};

const ActivitySessionsSelectDialog = (props: Props) => {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <InnerActivitySessionsSelectDialog {...props} />
    </Dialog>
  );
};

export { ActivitySessionsSelectDialog };
