import { gql } from '@apollo/client';

export const GET_MY_ELEMENTS_SIDEBAR_YOUR_IMPACT_DATA = gql`
  query GetMyElementsSideBarYourImpactData($volunteerId: String!, $startDate: Date!, $endDate: Date!) {
    vm {
      volunteer(volunteerId: $volunteerId) {
        profile {
          additionalVolunteerHours
        }
        volunteerStatistics(startDate: $startDate, endDate: $endDate) {
          ...MyElementsSideBarVolunteerStatistics
        }
      }
    }
  }
  fragment MyElementsSideBarVolunteerStatistics on VOLUNTEER_VolunteerStatisticsType {
    attended
    sessionReportStatistics {
      ...MyElementsSideBarSessionReportStatistics
    }
  }
  fragment MyElementsSideBarSessionReportStatistics on VOLUNTEER_SessionReportStatisticsType {
    operationalHours
    activityLocations {
      activityLocationId
    }
  }
`;
