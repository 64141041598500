import { CircularProgressOverlay } from '@campfire/circular-progress-overlay/lib';
import { encodeDate } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button/lib';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { arrayHead } from '../../../../../common/functions/array-head';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetActivityTimelineActivityClosureReasons } from '../__generated__/GetActivityTimelineActivityClosureReasons';
import { GET_ACTIVITY_TIMELINE_ACTIVITY_CLOSURE_REASONS } from '../activity-timeline-model.gql';

export const SelectActivityClosureReasonDialog = ({
  open,
  handleClose,
  handleSelection,
  activityName,
}: {
  open: boolean;
  handleClose: () => void;
  handleSelection: (selection: { activityClosureReasonId: string; label: string; dateEnded: string }) => void;
  activityName: string;
}) => {
  const [selectedClosureReason, setSelectedClosureReason] = useState<{
    activityClosureReasonId: string;
    label: string;
  }>();
  const [dateEnded, setDateEnded] = useState<DateTime | null>(null);
  const [page, setPage] = useState(0);

  const { data, loading } = useCampfireQuery<GetActivityTimelineActivityClosureReasons, undefined>(
    GET_ACTIVITY_TIMELINE_ACTIVITY_CLOSURE_REASONS
  );

  const closureReasons = useMemo(() => {
    return data?.vm.activityClosureReasons;
  }, [data]);

  useEffect(() => {
    setSelectedClosureReason(arrayHead(closureReasons ?? []));
  }, [closureReasons]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      {page === 0 ? (
        <>
          <DialogTitle>{`Closing ${activityName}`}</DialogTitle>
          <DialogContent>
            <Typography variant='body2' gutterBottom>
              {`Closing an activity will result in the team being disbanded and all future rosters being cleared. This should only be done if you are very unlikely to use this activity again.`}
            </Typography>

            <Typography variant='body2' gutterBottom>
              {`'Suspend' is more suited for temporary pauses in an activities operation.`}
            </Typography>

            <Typography variant='body2'>
              {`Would you still like to continue with the activity closure procedure?`}
            </Typography>
          </DialogContent>

          <DialogActions style={{ padding: 16 }}>
            <TabletButton
              data-track='actCnl-close-activity-dialog-go-back'
              size='medium'
              variant='text'
              onClick={handleClose}
            >
              {'Go Back'}
            </TabletButton>
            <TabletButton
              data-track='actCnl-close-activity-dialog-submit'
              size='medium'
              variant='text'
              onClick={() => setPage(1)}
            >
              {'Continue'}
            </TabletButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>{`Closing ${activityName}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>{`Close the ${activityName} activity for all future dates?`}</DialogContentText>
            <Grid container>
              <CircularProgressOverlay isLoading={loading} />
              {closureReasons ? (
                <>
                  <FormControl variant='filled' fullWidth>
                    <InputLabel htmlFor='select-closure-reason-dropdown'>Select Reason</InputLabel>
                    <Select
                      native
                      fullWidth
                      displayEmpty
                      value={selectedClosureReason?.activityClosureReasonId ?? ''}
                      onChange={(event: any) => {
                        const matchingReason = closureReasons.find(
                          (reason) => reason.activityClosureReasonId === event.target.value
                        );
                        setSelectedClosureReason(matchingReason);
                      }}
                      inputProps={{
                        name: 'select-closure-reason',
                        id: 'select-closure-reason-dropdown',
                      }}
                    >
                      {closureReasons.map((reason) => (
                        <option key={reason.activityClosureReasonId} value={reason.activityClosureReasonId}>
                          {`${reason.label}`}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <DatePicker
                    autoOk
                    variant='inline'
                    format='MM/dd/yyyy'
                    margin='normal'
                    id='date-ended'
                    value={dateEnded}
                    label='Final Activity Date'
                    inputVariant='standard'
                    onChange={(date) => setDateEnded(date)}
                  />
                </>
              ) : null}
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: 16 }}>
            <TabletButton
              data-track='actCnl-close-activity-dialog-go-back'
              size='medium'
              variant='text'
              onClick={handleClose}
            >
              {'Go Back'}
            </TabletButton>
            <TabletButton
              data-track='actCnl-close-activity-dialog-submit'
              color='error'
              size='medium'
              variant='text'
              onClick={() => {
                if (!selectedClosureReason || !dateEnded) return;
                handleSelection({ ...selectedClosureReason, dateEnded: encodeDate(dateEnded) });
              }}
              disabled={!selectedClosureReason || !dateEnded}
            >
              {'Close Activity'}
            </TabletButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
