import { useRouteMatch } from 'react-router';
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { TabletButton } from '@campfire/tablet-button';

export const ViewAllIncompleteReportsBlock = (props: { hasRosterings: boolean }) => {
  const { hasRosterings } = props;
  const match = useRouteMatch();

  return (
    <Grid item container direction='column' alignItems='center' alignContent='center' justify='center'>
      {hasRosterings ? (
        <Grid item xs style={{ paddingTop: 18, paddingBottom: 18 }}>
          <Typography variant='h6'>OR</Typography>
        </Grid>
      ) : null}
      <Grid item xs style={{ paddingTop: 8 }}>
        <TitularTooltip
          title={`View all incomplete reports for activities you're part of or were rostered on for`}
          placement='right'
        >
          <Link to={`${match.path}/view-all-incomplete`} style={{ textDecoration: 'none' }}>
            <TabletButton variant='outlined' color='primary'>
              View All Incomplete Reports
            </TabletButton>
          </Link>
        </TitularTooltip>
      </Grid>
    </Grid>
  );
};
