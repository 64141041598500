import React, { useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import { Field } from '@campfire/field/lib';
import { TabletButton } from '@campfire/tablet-button/lib';
import { useAdminConsoleActions } from '../admin-console/admin-console-actions';
import { useLoadingContext } from '../admin-console/admin-console-content-pages/admin-console-volunteer-profile/AdminConsoleLoadingContext';
import { VOLUNTEER_CakeTypeEnumType } from '../../../__generated__/globalTypes';

const validationSchema = YupObject().shape({
  title: YupString().required('Required'),
  description: YupString().required('Required'),
});
type FormValues = {
  title: string;
  description: string;
};

interface Props {
  open: boolean;
  close: () => void;
  order: number;
  handleSuccess: () => void;
}

export const AddCakeDialog = (props: Props) => {
  const { open, close, order, handleSuccess } = props;
  const { runSaveCake, runSaveCakeIsLoading } = useAdminConsoleActions();
  const defaultCakeType = 'extra' as VOLUNTEER_CakeTypeEnumType;
  const { setIsLoading } = useLoadingContext();

  useEffect(() => setIsLoading(runSaveCakeIsLoading), [runSaveCakeIsLoading]);

  const initialFormikValues = { title: '', description: '' };

  const handleSubmit = (vals: FormValues) => {
    runSaveCake(
      {
        title: vals.title,
        description: vals.description,
        required: false,
        order: order,
        cakeType: defaultCakeType,
        taskIds: [],
      },
      handleSuccess
    );
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Add Task Category</DialogTitle>
      <Formik initialValues={initialFormikValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {() => (
          <Form style={{ width: '100%' }}>
            <DialogContent style={{ overflow: 'hidden' }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Field name='title' variant='outlined' label='Name' required fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Field name='description' variant='outlined' label='Description' multiline required fullWidth />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ paddingTop: '18px', paddingBottom: '18px' }}>
              <Grid container justify='flex-end' alignItems='center'>
                <TabletButton variant='text' onClick={close}>
                  Cancel
                </TabletButton>
                <TabletButton variant='contained' color='primary' type='submit' disabled={runSaveCakeIsLoading}>
                  Save
                </TabletButton>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
