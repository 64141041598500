import { HoverLink } from '@campfire/hover-link';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { PublicAppBar } from '../public-shell/public-app-bar/PublicAppBar';

export const TenantUndefinedShell = () => {
  const { theme } = useCampfireTheme();

  return (
    <>
      <PublicAppBar />
      <Box
        width={1}
        marginTop={`${theme.appShell.menu.height}px`}
        height={window.innerHeight - theme.appShell.menu.height}
        display='flex'
        flex='1 1 auto'
        justifyContent='center'
        alignContent='center'
        alignItems='center'
      >
        <Box>
          <Typography variant='h3' style={{ fontWeight: 500, paddingBottom: '25px' }}>
            {`Organisation Does Not Exist`}
          </Typography>
          <Typography variant='h6' style={{ fontWeight: 350 }}>
            This web address does not link to a Volaby account.
          </Typography>
          <Typography variant='h6' style={{ fontWeight: 350, display: 'inline-block', marginTop: '5px' }}>
            Please double check that the URL you have entered is correct.
          </Typography>
          <br />
          <Typography variant='h6' style={{ fontWeight: 350, display: 'inline-block', marginTop: '20px' }}>
            If this doesn&apos;t seem right or you would like to speak with one of our team,&nbsp;
          </Typography>
          <HoverLink external to='https://volaby.org/contact' rel='noopener' target='_blank' hoverColor='primary'>
            <Typography
              variant='h6'
              style={{
                fontWeight: 350,
                display: 'inline-block',
                textDecoration: 'underline',
                textDecorationThickness: '1px',
              }}
            >
              please click here to contact us.
            </Typography>
          </HoverLink>
        </Box>
      </Box>
    </>
  );
};
