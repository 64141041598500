import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { useFormBuilderDialogContext } from './FormBuilderDialogContext';

export const TaskControlsContent = () => {
  const { addHeading, addContent, addField, addMedia } = useFormBuilderDialogContext();

  return (
    <Box display='flex' flexDirection='column' justifyContent='center'>
      <Box textAlign='center' paddingY={1} paddingX={1}>
        <Typography variant='body1' style={{ fontWeight: 'bold' }}>
          Adding items to your task
        </Typography>

        <Typography variant='body2' color='textSecondary'>
          Create and customise fields and headings
        </Typography>
      </Box>

      <Box textAlign='center' marginTop={1} paddingBottom={2} paddingX={1}>
        <TabletButton
          fullWidth
          color='primary'
          variant='contained'
          size='medium'
          onClick={addField}
          style={{ marginBottom: 16 }}
        >
          Add field
        </TabletButton>

        <HoverText color='primary' variant='body2' onClick={addHeading} style={{ marginBottom: 16 }}>
          Add heading
        </HoverText>

        <HoverText color='primary' variant='body2' onClick={addContent} style={{ marginBottom: 16 }}>
          Add content
        </HoverText>

        <HoverText color='primary' variant='body2' onClick={addMedia}>
          Add media
        </HoverText>
      </Box>
    </Box>
  );
};
export const TaskControlsCard = () => {
  return (
    <Card>
      <CardContent>
        <TaskControlsContent />
      </CardContent>
    </Card>
  );
};
