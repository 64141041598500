import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSession } from '../../../global/auth/useSession';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

const LOGIN_RETRY_LIMIT = 5;
const LOGIN_RETRY_INTERVAL = 4000;

interface ApplicationScreenApprovalProcessingProps {
  orgName: string;
  stateController: {
    givenName: string | undefined;
    givenEmail: string | undefined;
    givenPassword: string | undefined;
    setApprovalProcessing: (value: boolean | undefined) => void;
  };
}

export const ApplicationScreenApprovalProcessing = (props: ApplicationScreenApprovalProcessingProps) => {
  const { stateController } = props;
  const { givenEmail: email, givenPassword: password, setApprovalProcessing } = stateController;
  const { isMobile } = useCampfireTheme();
  const [loginAttempts, setLoginAttempts] = useState(0);
  const { login, user } = useSession();

  useEffect(() => {
    if (!email || !password) return () => {};
    const interval = window.setInterval(() => {
      if (user) return;
      login(email, password);
      setLoginAttempts((prevLoginAttempts) => prevLoginAttempts + 1);
    }, LOGIN_RETRY_INTERVAL);

    return () => window.clearInterval(interval);
  }, []);

  useEffect(() => {
    if (loginAttempts === LOGIN_RETRY_LIMIT) {
      setApprovalProcessing(false);
    }
  }, [loginAttempts]);

  return (
    <Box paddingTop={isMobile ? 4 : 8} paddingBottom={isMobile ? 0 : 8}>
      <LinearProgressOverlay isLoading opacity={0} />
      <Grid justify='center' container style={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={10} style={{ textAlign: 'center' }}>
          <Typography variant='h4'>{'Hold Tight'}</Typography>
          <Typography variant='h6'>{`We're creating your account`}</Typography>

          <Typography variant='body2' color='textSecondary' style={{ marginTop: 16 }}>
            {`This page will refresh in a couple of seconds.`}
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {`Don't close or nagivate away from this page.`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
