import { Theme } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';

const TaskPendingReviewIcon = () => {
  const classes = useStyles();
  return <WarningRounded className={classes.icon} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 29,
      color: theme.palette.error.light,
    },
  })
);

export { TaskPendingReviewIcon };
