import React from 'react';
import { useFormikContext } from 'formik';
import { SwitchField } from './SwitchField';
import { DateTaskField } from '../../../../__generated__/DateTaskField';

type DateFieldValues = {
  field: DateTaskField;
};

export const DateAdditionalFields = () => {
  const { values, setFieldValue } = useFormikContext<DateFieldValues>();
  return (
    <SwitchField
      title='Expiry Field'
      description='The date field will function as an expiry field, and make the volunteer re-complete the task on or around the expiry date.'
      checked={values.field.isExpiry}
      onChange={() => setFieldValue('field.isExpiry', !values.field.isExpiry)}
    />
  );
};
