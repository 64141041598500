import React from 'react';
import { Menu, MenuItem as MuiMenuItem, SvgIcon, Typography, ClickAwayListener, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { TabletButton } from '@campfire/tablet-button';
import { ExpandMore } from '@material-ui/icons';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { ReactComponent as LogoutIcon } from '../../../../../../assets/logout-light.svg';
import { ReactComponent as UnavailableIcon } from '../../../../../../assets/x-square-light.svg';
import { ReactComponent as AvailableIcon } from '../../../../../../assets/check-square-light.svg';
import { RosteredStatus, ActivityStatus } from '../../../../activities-v2/ActivityTypes';
import { getActivityLegend } from '../../../../../../common/constants/activity-legends';

const MENU_ITEMS_MAP = {
  apply: {
    text: 'Apply',
    icon: LogoutIcon,
  },
  update: {
    text: 'Update availabilities',
    icon: AvailableIcon,
  },
  available: {
    text: 'Available',
    icon: AvailableIcon,
  },
  unavailable: {
    text: 'Unavailable',
    icon: UnavailableIcon,
  },
  withdraw: {
    text: 'Withdraw',
    icon: LogoutIcon,
  },
};

const useMenuItemStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: '1rem',
      height: '1rem',
      marginRight: '1rem',
    },
  })
);

export type ActionType = 'apply' | 'update' | 'available' | 'unavailable' | 'withdraw';

function MenuItem({ action, onAction }: { action: ActionType; onAction: (action: ActionType) => void }) {
  const { text, icon: Icon } = MENU_ITEMS_MAP[action];
  const onMenuItemClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onAction(action);
  };
  const classes = useMenuItemStyles();

  return (
    <MuiMenuItem onClick={onMenuItemClick} className={classes.container}>
      <SvgIcon viewBox='0 0 16 16' fontSize='small' className={classes.icon}>
        <Icon />
      </SvgIcon>
      <Typography>{text}</Typography>
    </MuiMenuItem>
  );
}

interface Props {
  actions: ActionType[];
  onAction: (action: ActionType) => void;
  status: ActivityStatus | RosteredStatus;
}

export const ActivityStatusColorLegendItemMenu = ({
  activityStatus,
}: {
  activityStatus: ActivityStatus | RosteredStatus;
}) => {
  const { theme } = useCampfireTheme();
  return (
    <Box display='flex' alignItems='center'>
      <Box
        height='10px'
        width='10px'
        borderRadius='50%'
        marginRight='8px'
        bgcolor={theme.color.rosters.status[activityStatus].primary}
      />
      <Typography
        variant='caption'
        style={{
          fontSize: '12px',
          fontWeight: 700,
          color: theme.color.grey.neutral400,
          textTransform: 'uppercase',
          lineHeight: '14px',
          marginTop: '1px',
        }}
      >
        {getActivityLegend(activityStatus)}
      </Typography>
    </Box>
  );
};

export function MyActivityStatusIndicator({ actions, status, onAction }: Props) {
  const { theme } = useCampfireTheme();
  const [anchorEl, setAnchorEl] = React.useState();

  // if (actions.length === 0) {
  //   return <StatusChip status={status} bgcolor={theme.color.rosters.status[status].primary} />;
  // }

  const onOpenMenu = (event: any) => {
    if (actions.length > 0) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(undefined)}>
      <div>
        <TabletButton
          data-track='resources-add-new-menu-button'
          variant='outlined'
          color='primary'
          aria-controls='add-new'
          aria-haspopup='true'
          onClick={onOpenMenu}
          endIcon={
            actions &&
            actions.length > 0 && <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />
          }
          style={{ padding: '8px 12px', border: `1px solid #DCDCDC`, whiteSpace: 'nowrap' }}
        >
          <ActivityStatusColorLegendItemMenu activityStatus={status} />
        </TabletButton>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          keepMounted
          onClose={(e: any) => {
            e.stopPropagation();
            setAnchorEl(undefined);
          }}
          getContentAnchorEl={null}
          PaperProps={{ style: { border: 'solid 1px #e9e9e9' } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {actions.map((action) => (
            <MenuItem action={action} onAction={onAction} />
          ))}
        </Menu>
      </div>
    </ClickAwayListener>
  );
}
