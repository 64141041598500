import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box } from '@material-ui/core';
import { CheckRounded, Edit } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { getVolunteerProfileHardcodedFieldValues } from '../../../../../../../common/form/task-form/model/get-volunteer-profile-hardcoded-field-values';
import { TaskCompleteIcon } from '../../../../../../../common/icons/TaskCompleteIcon';
import { SnackbarContextProps } from '../../../../../../../global/config/SnackbarContext';
import { useCampfireQuery } from '../../../../../../../global/network/useCampfireQuery';
import { useEndpointFetch } from '../../../../../../../global/network/useEndpointFetch';
import { IncomingVolunteerConfirmationDialog } from '../../IncomingVolunteerConfirmationDialog';
import { TaskMenuBarHeader } from '../TaskSideMenuBar';
import { GET_TASK_FOR_INCOMING_VOLUNTEER } from './invoming-volunteer-task-view-model.gql';
import { UpdatingCompletedTask } from './UpdatingCompletedTask';
import {
  GetTaskForIncomingVolunteer,
  GetTaskForIncomingVolunteerVariables,
} from './__generated__/GetTaskForIncomingVolunteer';
import { CompletedTaskFormFieldList } from '../../../../../../../common/form/task-form/CompletedTaskViewer';
import { deserializeTaskItems } from '../../../../../../admin/tasks/utils';

interface SubmissionData {
  completedTaskId: string;
  status: 'approved' | 'pending' | 'rejected';
}

interface IncomingVolunteerTaskViewProps {
  onClose: () => void;
  taskId: string;
  completedTaskId?: string;
  reload?: () => void;
  reloadList?: () => void;
  selectedUserId: string;
  setSnackbar?: (props: SnackbarContextProps | undefined) => void;
}

export const IncomingVolunteerTaskView = ({
  onClose,
  taskId,
  completedTaskId,
  reload,
  selectedUserId,
}: IncomingVolunteerTaskViewProps) => {
  const [openApproveTaskZeroDialog, setOpenApproveTaskZeroDialog] = useState(false);
  const [selectedVolunteerProfileId] = useQueryParam('volunteerProfileId', StringParam);
  const updateCompletedTaskStatus = useEndpointFetch<SubmissionData>(
    '/vm/volunteer/manage/update-completed-task-status'
  );

  const tasksQuery = useCampfireQuery<GetTaskForIncomingVolunteer, GetTaskForIncomingVolunteerVariables>(
    GET_TASK_FOR_INCOMING_VOLUNTEER,
    {
      options: {
        variables: {
          taskId,
          completedTaskId: completedTaskId ?? '',
          profileId: selectedVolunteerProfileId ?? '',
          userId: selectedUserId,
        },
      },
    }
  );

  const handleApproveTask = () => {
    if (completedTaskId) {
      updateCompletedTaskStatus
        .run({
          completedTaskId,
          status: 'approved',
        })
        .then(() => {
          if (reload) {
            reload();
          }
          onClose();
        });
    }
  };

  const onSaveTaskSuccess = () => {
    if (reload) {
      reload();
    }
    if (tasksQuery.refetch) {
      tasksQuery.refetch();
    }
    setIsEditting(false);
  };

  const task = useMemo(() => {
    if (!tasksQuery.data) return null;
    return { ...tasksQuery.data.vm.task, __typename: 'VOLUNTEER_TaskType' as any };
  }, [tasksQuery.data]);

  const taskItems = useMemo(() => {
    return tasksQuery.data?.vm.task?.taskItems;
  }, [tasksQuery.data]);

  const completedTask = useMemo(() => {
    if (!tasksQuery.data) return undefined;
    return tasksQuery.data.vm.completedTask;
  }, [tasksQuery.data]);

  const taskItemValues = useMemo(() => {
    if (!completedTask) return [];
    return completedTask.taskFieldValues.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.field.taskFieldId]: curr,
      }),
      {}
    );
  }, [completedTask]);

  const taskHeaderDetails = useMemo(() => {
    if (!tasksQuery.data?.vm.task) return undefined;
    return {
      title: tasksQuery.data.vm.task.title,
      description: tasksQuery.data.vm.task.description,
      cake: tasksQuery.data.vm.task.cake.title,
    };
  }, [tasksQuery.data]);

  const volunteerProfileHardCodedFieldValues = useMemo(() => {
    if (tasksQuery.data?.vm.completedTask?.task.order !== -1 || !tasksQuery.data.vm.profile) {
      return undefined;
    }
    return getVolunteerProfileHardcodedFieldValues(tasksQuery.data.vm.profile);
  }, [tasksQuery.data]);

  const showingEditButton = Boolean(completedTaskId);
  const [isEditting, setIsEditting] = React.useState(!completedTaskId);

  return (
    <>
      <Box>
        <TaskMenuBarHeader onClose={onClose}>
          {!completedTaskId ? null : tasksQuery.loading ? (
            <TabletButton disabled variant='outlined' color='primary'>
              Loading...
            </TabletButton>
          ) : completedTask?.status === 'approved' ? (
            <TabletButton disabled variant='outlined' color='primary' endIcon={<TaskCompleteIcon isComplete />}>
              Task approved
            </TabletButton>
          ) : volunteerProfileHardCodedFieldValues ? (
            <TabletButton
              variant='outlined'
              color='primary'
              onClick={() => setOpenApproveTaskZeroDialog(true)}
              disabled={updateCompletedTaskStatus.isLoading}
              startIcon={<CheckRounded color='primary' />}
            >
              Approve task
            </TabletButton>
          ) : (
            <TabletButton
              variant='outlined'
              color='primary'
              onClick={handleApproveTask}
              disabled={updateCompletedTaskStatus.isLoading}
              startIcon={<CheckRounded color='primary' />}
            >
              Approve task
            </TabletButton>
          )}
          {showingEditButton ? (
            <TabletButton
              variant='outlined'
              onClick={() => setIsEditting(!isEditting)}
              startIcon={<Edit />}
              style={{ borderRadius: '4px', paddingLeft: '4px', paddingRight: '4px' }}
            >
              Edit
            </TabletButton>
          ) : null}
        </TaskMenuBarHeader>
      </Box>

      <Box flex={1} style={{ overflowY: 'scroll', backgroundColor: '#f6f8f9' }} display='flex'>
        <Box position='relative' display='flex' flex='1 1 auto' height={'auto'} minHeight={1}>
          <CircularProgressOverlay isLoading={tasksQuery.loading || updateCompletedTaskStatus.isLoading} />
          {taskHeaderDetails ? (
            <Box paddingX={2} paddingY={2} flex='1 1 auto'>
              {task && isEditting && (
                <UpdatingCompletedTask
                  task={task as any}
                  taskItemValues={taskItemValues}
                  onCancel={onClose}
                  completedTaskId={completedTask?.completedTaskId}
                  onSaveSuccess={onSaveTaskSuccess}
                  userId={selectedUserId}
                  profile={tasksQuery.data?.vm.profile}
                  programs={tasksQuery.data?.vm.programs.filter((p) => !p.dateSuspended)}
                />
              )}
              {task && !isEditting && (
                <CompletedTaskFormFieldList
                  taskItems={deserializeTaskItems(taskItems)}
                  taskItemValues={taskItemValues}
                  isTaskZero={!!volunteerProfileHardCodedFieldValues}
                  volunteerProfileValues={volunteerProfileHardCodedFieldValues}
                />
              )}
            </Box>
          ) : null}
        </Box>
      </Box>

      <IncomingVolunteerConfirmationDialog
        title='Approve initial Volunteer Profile?'
        body={`This volunteer will gain access to all other onboarding and general tasks, and will be able to complete the rest of their onboarding. We'll send them an email letting them know that they've been approved.`}
        open={openApproveTaskZeroDialog}
        id={selectedVolunteerProfileId ?? ''}
        disabled={updateCompletedTaskStatus.isLoading}
        handleCloseClick={() => setOpenApproveTaskZeroDialog(false)}
        approveButtonText='Approve initial Volunteer Profile'
        handleApproveClick={() => {
          if (completedTaskId) {
            updateCompletedTaskStatus
              .run({
                completedTaskId,
                status: 'approved',
              })
              .then(() => {
                if (reload) {
                  reload();
                }
                onClose();
              });
          }
        }}
      />
    </>
  );
};
