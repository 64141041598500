import { gql } from '@apollo/client';

export const GET_ACTIVITIES_NEW_VOLUNTEERS = gql`
query GetActivitiesConsoleNewVolunteers($activityId: String!) {
    vm {
      activity(activityId: $activityId) {
        activityId
        activeActivityApplications {
            activityApplicationId
            dateApplied
            dateWithdrawn
            volunteer {
                volunteerId
                profile {
                    profileId
                    userId
                    preferredName
                    lastName
                    avatarUrl
                }
            }
        } 
    }
}
}
`;