import { Box, Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import Wordmark from '../../assets/branding/logo/volaby-original.svg';
import { FloatingPage } from '../../global/components/FloatingPage';
import { useCampfireTheme } from '../../theme/useCampfireTheme';

interface Props {
  children: React.ReactNode;
  floating?: boolean;
  hideHeader?: boolean;
  shrink?: boolean | number;
}

const Header = () => {
  const { isMobile } = useCampfireTheme();
  return (
    <Box width={1} justifyContent='center' display='flex' marginTop={isMobile ? 8 : 0} marginBottom={isMobile ? 0 : 2}>
      <Box>
        <img src={Wordmark} width={256} alt='Campfire logo' style={{ zIndex: 2, opacity: 0.9 }} />
      </Box>
    </Box>
  );
};

const ShrinkCard = (props: { shrink: number; children: ReactNode }) => {
  const { shrink, children } = props;
  const { isMobile } = useCampfireTheme();
  const borderTopRadius = isMobile ? 16 : 4;
  const borderBottomRadius = isMobile ? 0 : 4;

  return (
    <Grid container justify='center' style={{ flexGrow: 1 }}>
      <Grid
        item
        style={{
          position: 'relative',
          width: shrink,
          backgroundColor: '#ffffff',
          borderRadius: `${borderTopRadius}px ${borderTopRadius}px ${borderBottomRadius}px ${borderBottomRadius}px`,
          zIndex: 1,
          boxShadow: 'rgba(35, 63, 77, 0.1) 0px 8px 24px 8px',
          margin: '32px 0',
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

const PublicScreenWrapper = (props: Props) => {
  const { children, floating, hideHeader, shrink = true } = props;
  const { theme, isMobile } = useCampfireTheme();
  const shrinkNumber = shrink && shrink !== true ? shrink : theme.breakpoints.values.sm - theme.spacing(6) * 2;

  return floating && !isMobile ? (
    <FloatingPage>
      <ShrinkCard shrink={shrinkNumber}>{children}</ShrinkCard>
    </FloatingPage>
  ) : (
    <Box paddingY={isMobile ? 0 : 4} display='flex' flex='1 0 auto' flexDirection='column'>
      {!hideHeader ? <Header /> : null}
      <ShrinkCard shrink={shrinkNumber}>{children}</ShrinkCard>
    </Box>
  );
};

export { PublicScreenWrapper };
