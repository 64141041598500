import React, { useState, useContext } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { PastSidebarItem, FutureSidebarItem, SidebarItem } from './sidebar/ActivityTimelineSidebar';
import { SidebarTabs, getPastSidebarItemStatus, getFutureSidebarItemStatus } from './sidebar/sidebar-items-parser';

interface ActivityTimelineContextInterface {
  selectedActivityId: string | undefined;
  setSelectedActivityId: (newValue: string | undefined) => void;
  selectedDate: string | undefined;
  setSelectedDate: (newValue: string | undefined) => void;
  selectedSidebarItem: PastSidebarItem | FutureSidebarItem | undefined;
  setSelectedSidebarItem: (newValue: PastSidebarItem | FutureSidebarItem | undefined) => void;
  parsedSidebarItems: SidebarTabs | undefined;
  setParsedSidebarItems: (newValue: SidebarTabs | undefined) => void;
  sidebarIsLoading: boolean;
  setSidebarIsLoading: (newValue: boolean) => void;
  patchSelectedSidebarItem: (partialSidebarItem: Partial<PastSidebarItem> | Partial<FutureSidebarItem>) => void;
  selectedRosterTemplateId?: string;
  setSelectedRosterTemplateId: (rtid: string) => void;
}

interface ActivityTimelineProviderProps {
  children: React.ReactNode;
}

const ActivityTimelineContext = React.createContext<ActivityTimelineContextInterface | undefined>(undefined);

const ActivityTimelineProvider = (props: ActivityTimelineProviderProps) => {
  const [selectedActivityId, setSelectedActivityId] = useQueryParam('activityId', StringParam);
  const [selectedDate, setSelectedDate] = useQueryParam('activityDate', StringParam);
  const [selectedRosterTemplateId, setSelectedRosterTemplateId] = useQueryParam('rtid', StringParam);
  const [selectedSidebarItem, setSelectedSidebarItem] = useState<SidebarItem | undefined>();
  const [parsedSidebarItems, setParsedSidebarItems] = useState<SidebarTabs | undefined>();
  const [sidebarIsLoading, setSidebarIsLoading] = useState(false);

  const patchSelectedSidebarItem = (partialSidebarItem: Partial<SidebarItem>) => {
    if (!selectedSidebarItem) return;

    const resultantSidebarItem =
      selectedSidebarItem.type === 'past'
        ? {
            ...selectedSidebarItem,
            ...partialSidebarItem,
            pastStatus: getPastSidebarItemStatus({
              cancelledActivity:
                'cancelledActivity' in partialSidebarItem
                  ? partialSidebarItem.cancelledActivity
                  : selectedSidebarItem.cancelledActivity,
              activityReport:
                'activityReport' in partialSidebarItem
                  ? partialSidebarItem.activityReport
                  : selectedSidebarItem.activityReport,
            }),
          }
        : {
            ...selectedSidebarItem,
            ...partialSidebarItem,
            futureStatus: getFutureSidebarItemStatus({
              cancelledActivity:
                'cancelledActivity' in partialSidebarItem
                  ? partialSidebarItem.cancelledActivity
                  : selectedSidebarItem.cancelledActivity,
              draftRoster:
                'draftRoster' in partialSidebarItem ? partialSidebarItem.draftRoster : selectedSidebarItem.draftRoster,
            }),
          };

    setParsedSidebarItems((prevState) => {
      if (!prevState) return prevState;
      const selectedSidebarItems = prevState[resultantSidebarItem.type];
      const dateItems = selectedSidebarItems[resultantSidebarItem.activityDate] as SidebarItem[];
      const existingIndex = dateItems.findIndex((sidebarItem) => sidebarItem === selectedSidebarItem);
      dateItems[existingIndex] = resultantSidebarItem as SidebarItem;

      // Handle the case where activity for date is in future and past (Today)
      const otherSelectedSidebarItems = prevState[resultantSidebarItem.type === 'past' ? 'future' : 'past'];
      const otherDateItems = otherSelectedSidebarItems[resultantSidebarItem.activityDate] as SidebarItem[] | undefined;
      const otherExistingIndex = otherDateItems?.findIndex(
        (sidebarItem) =>
          sidebarItem.activityId === selectedSidebarItem.activityId &&
          sidebarItem.activityDate === selectedSidebarItem.activityDate
      );

      if (!otherDateItems || !otherExistingIndex || otherExistingIndex < 0) {
        return {
          ...prevState,
          [resultantSidebarItem.type]: {
            ...selectedSidebarItems,
            [resultantSidebarItem.activityDate]: dateItems,
          },
        };
      }

      const otherExistingSidebarItem = otherDateItems[otherExistingIndex] as SidebarItem;
      const otherResultantSidebarItem =
        otherExistingSidebarItem.type === 'past'
          ? {
              ...otherExistingSidebarItem,
              ...partialSidebarItem,
              pastStatus: getPastSidebarItemStatus({
                cancelledActivity:
                  'cancelledActivity' in partialSidebarItem
                    ? partialSidebarItem.cancelledActivity
                    : otherExistingSidebarItem.cancelledActivity,
                activityReport:
                  'activityReport' in partialSidebarItem
                    ? partialSidebarItem.activityReport
                    : otherExistingSidebarItem.activityReport,
              }),
            }
          : {
              ...otherExistingSidebarItem,
              ...partialSidebarItem,
              futureStatus: getFutureSidebarItemStatus({
                cancelledActivity:
                  'cancelledActivity' in partialSidebarItem
                    ? partialSidebarItem.cancelledActivity
                    : otherExistingSidebarItem.cancelledActivity,
                draftRoster:
                  'draftRoster' in partialSidebarItem
                    ? partialSidebarItem.draftRoster
                    : otherExistingSidebarItem.draftRoster,
              }),
            };
      otherDateItems[otherExistingIndex] = otherResultantSidebarItem as SidebarItem;

      return {
        ...prevState,
        [resultantSidebarItem.type]: {
          ...selectedSidebarItems,
          [resultantSidebarItem.activityDate]: dateItems,
        },
        [otherResultantSidebarItem.type]: {
          ...otherSelectedSidebarItems,
          [otherResultantSidebarItem.activityDate]: otherDateItems,
        },
      };
    });
  };

  const value = {
    selectedActivityId,
    setSelectedActivityId,
    selectedDate,
    setSelectedDate,
    selectedSidebarItem,
    setSelectedSidebarItem,
    parsedSidebarItems,
    setParsedSidebarItems,
    sidebarIsLoading,
    setSidebarIsLoading,
    patchSelectedSidebarItem,
    selectedRosterTemplateId,
    setSelectedRosterTemplateId,
  };

  return <ActivityTimelineContext.Provider value={value} {...props} />;
};

export const useActivityTimelineContext = () => {
  const value = useContext(ActivityTimelineContext);

  if (!value) throw new Error('Gotta be inside of a ActivityTimelineContext.Provider, homie');

  return value;
};

export { ActivityTimelineProvider };
