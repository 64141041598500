import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgressOverlay } from '@campfire/circular-progress-overlay/lib';
import { unpackToDate } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button/lib';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { EditorState, convertToRaw } from 'draft-js';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Typography,
  Box,
  Theme,
} from '@material-ui/core';
import { FileUploadGallery, FugFile } from '@campfire/file-upload-gallery';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Close } from '@material-ui/icons';

import { arrayHead } from '../../../../../common/functions/array-head';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetActivityTimelineActivityCancellationReasons } from '../__generated__/GetActivityTimelineActivityCancellationReasons';
import { GET_ACTIVITY_TIMELINE_ACTIVITY_CANCELLATION_REASONS } from '../activity-timeline-model.gql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      color: theme.color.grey.neutral300,
      fontSize: '16px',
    },
    textFieldContainer: {
      minWidth: '424px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        mintWidth: 'unset',
      },
    },
  })
);

const useButtonClasses = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: '6px !important',
      padding: '12px !important',
      fontWeight: 700,
    },
  })
);

export const SelectActivityCancellationReasonDialog = ({
  open,
  handleClose,
  handleSelection,
  activityName,
  activityDate,
}: {
  open: boolean;
  handleClose: () => void;
  handleSelection: (selection: {
    activityCancellationReasonId: string;
    label: string;
    description: string;
    addedAttachments: FugFile[];
    attachmentCreationTokens: string[];
  }) => void;
  activityName: string;
  activityDate: string;
}) => {
  const [selectedCancellationReason, setSelectedCancellationReason] = useState<{
    activityCancellationReasonId: string;
    label: string;
  }>();
  const { data, loading } = useCampfireQuery<GetActivityTimelineActivityCancellationReasons, undefined>(
    GET_ACTIVITY_TIMELINE_ACTIVITY_CANCELLATION_REASONS
  );

  const cancellationReasons = useMemo(() => {
    return data?.vm.activityCancellationReasons;
  }, [data]);

  const activityDateTime = unpackToDate(activityDate);

  useEffect(() => {
    setSelectedCancellationReason(arrayHead(cancellationReasons ?? []));
  }, [cancellationReasons]);

  const onSelectReason = (event: any) => {
    const selectingCancelReason = cancellationReasons?.find(
      (reason) => reason.activityCancellationReasonId === event.target.value
    );
    setSelectedCancellationReason(selectingCancelReason);
  };

  const [description, setDescription] = React.useState(EditorState.createEmpty());
  const [uploadedFiles, setUploadedFiles] = React.useState<FugFile[]>([]);

  const classes = useStyles();
  const buttonClasses = useButtonClasses();

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle>{`Cancelling ${activityName}, ${activityDateTime.toFormat('ccc d LLL')}`}</DialogTitle>
      <DialogContent>
        <Typography className={classes.typography}>
          Do you want to cancel {activityName} for <strong>{activityDateTime.toFormat('cccc d LLLL, yyyy')}</strong>?
        </Typography>
        <Typography className={classes.typography}>{`This is not permanent and can be undone later.`}</Typography>
        <Typography
          className={classes.typography}
        >{`However, any existing reports, rosters and availabilities for this date may be unrecoverable.`}</Typography>
        <CircularProgressOverlay isLoading={loading} />
        <Box mt='2rem' className={classes.textFieldContainer}>
          {cancellationReasons && (
            <TextField
              select
              variant='outlined'
              fullWidth
              onChange={onSelectReason}
              label='Select reason'
              value={selectedCancellationReason?.activityCancellationReasonId}
            >
              {cancellationReasons.map((reason) => (
                <MenuItem key={reason.activityCancellationReasonId} value={reason.activityCancellationReasonId}>
                  {`${reason.label}`}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
        <Box mt='1.5rem' width='100%'>
          <CampfireRichEditor editorState={description} setEditorState={setDescription} />
        </Box>
        <Box></Box>
        <Box mt='2.5rem'>
          <Typography className={classes.typography}>Attachments</Typography>
          <FileUploadGallery
            id={'addedAttachments'}
            data-track='resources-create-activity-attachments-area'
            allowMultiple
            uploadedFiles={[]}
            handleFilesAdded={(files: FugFile[]) => {
              setUploadedFiles(uploadedFiles.concat(files));
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions style={{ padding: 16 }}>
        <TabletButton size='medium' variant='text' onClick={handleClose}>
          {'Go Back'}
        </TabletButton>
        <TabletButton
          color='error'
          size='medium'
          variant='outlined'
          startIcon={<Close style={{ fontSize: '20px' }} />}
          onClick={() => {
            if (!selectedCancellationReason) return;
            handleSelection({
              ...selectedCancellationReason,
              description: JSON.stringify(convertToRaw(description.getCurrentContent())),
              addedAttachments: uploadedFiles,
              attachmentCreationTokens: uploadedFiles.map((file) => file.id),
            });
          }}
          disabled={!selectedCancellationReason}
          classes={buttonClasses}
        >
          {'Cancel Activity'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};
