import React, { useState } from 'react';
import { Box, Button, Collapse, IconButton, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Close, ExpandLess, ExpandMore, WarningRounded } from '@material-ui/icons';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { PopUpWindow } from '../PopUpWindow';

type IVolunteer = {
  activityId: string;
  activityName: string;
  volunteerId: string;
  volunteerName: string;
};

export type ITeam = {
  [key: string]: Array<IVolunteer>;
};
interface Props {
  onCancel: () => void;
  onOK: () => void;
  teams?: ITeam;
}

const MaxVolunteerTeamItem = ({ volunteers }: { volunteers: IVolunteer[] }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Box>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={volunteers[0]?.activityName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {volunteers.map((v) => (
            <ListItem button key={v.volunteerId}>
              <ListItemText primary={v.volunteerName} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

export const ConfirmSelectMaxVolunteer = (props: Props) => {
  const { onCancel, onOK, teams } = props;
  const { theme } = useCampfireTheme();

  return (
    <PopUpWindow fixed transparent center>
      <Box
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 2.752269744873047px 13.761348724365234px 0px #00000029',
          width: '600px',
          borderRadius: '8px',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          padding={'20px 25px'}
          alignItems={'center'}
          borderBottom={'1px solid #e3e3e3'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <WarningRounded style={{ color: '#FBBF24' }} />
            <Typography style={{ fontWeight: 700, fontSize: '20px', paddingLeft: '15px' }}>Maximum Exceeded</Typography>
          </Box>
          <IconButton onClick={onCancel}>
            <Close style={{ opacity: '54%' }} />
          </IconButton>
        </Box>
        <Box padding={'20px 35px'}>
          <Typography style={{ color: theme.color.grey.neutral500 }}>
            The amount of volunteers selected exceeds the maximum set for this activities team. This maximum can be
            changed through editing the activity.
          </Typography>
          {teams && (
            <List>
              {Object.values(teams).map((team) => (
                <MaxVolunteerTeamItem volunteers={team} key={team[0].activityId} />
              ))}
            </List>
          )}
          <Typography style={{ color: theme.color.grey.neutral500, marginTop: '24px' }}>
            You can choose to continue, or go back and select less volunteers.
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'} padding={'0px 25px 30px 25px'}>
          <Button onClick={onCancel} style={{ textTransform: 'none', marginRight: '16px' }}>
            Back
          </Button>
          <Button color='primary' variant='contained' onClick={onOK} style={{ textTransform: 'none' }}>
            Continue
          </Button>
        </Box>
      </Box>
    </PopUpWindow>
  );
};
