import React, { ReactElement } from 'react';
import { Dialog, DialogTitle, DialogActions, Grid } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';

interface Props {
  open: boolean;
  proceed: () => any;
  cancel: () => void;
  title: string;
  children: ReactElement | string;
  isPending: boolean;
}

const ConfirmEditActivityDialogV2 = (props: Props) => {
  const { open, proceed, cancel, title, children, isPending } = props;

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      {children}
      <DialogActions>
        <Grid container justify='flex-end' spacing={1}>
          <Grid item>
            <TabletButton disabled={isPending} onClick={cancel}>
              {'Cancel'}
            </TabletButton>
          </Grid>
          <Grid item>
            <TabletButton variant='contained' color='primary' disabled={isPending} onClick={proceed}>
              {'Update activity'}
            </TabletButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmEditActivityDialogV2 };
