import NumberWidgetIcon from '../../../../../../assets/icons/number_widget_icon.svg';
import AlertWidgetIcon from '../../../../../../assets/icons/alert_widget_icon.svg';
import ChartWidgetIcon from '../../../../../../assets/icons/chart_widget_icon.svg';
import TableWidgetIcon from '../../../../../../assets/icons/table_widget_icon.svg';
import NumberChartWidgetIcon from '../../../../../../assets/icons/num_chart_widget_icon.svg';
import NumberAverageWidgetIcon from '../../../../../../assets/icons/average_widget_icon.svg';
import NumberTrackingWidgetIcon from '../../../../../../assets/icons/tracking_widget_icon.svg';
import NumberTargetWidgetIcon from '../../../../../../assets/icons/target_widget_icon.svg';
import NumberCustomMultiplierWidgetIcon from '../../../../../../assets/icons/custom_multiplier_widget_icon.svg';
import ColumnChartWidgetIcon from '../../../../../../assets/icons/column_chart_icon.svg';
import LineChartWidgetIcon from '../../../../../../assets/icons/line_chart_icon.svg';
import ScatterChartWidgetIcon from '../../../../../../assets/icons/scatter_chart_icon.svg';
import AreaChartWidgetIcon from '../../../../../../assets/icons/area_chart_icon.svg';
import PieChartWidgetIcon from '../../../../../../assets/icons/pie_chart_icon.svg';
import { GetDashboardPreference_vm_profile_dashboardPreference_dashboardComponents as IComponent } from './__generated__/GetDashboardPreference';

export enum CreateBy {
  BY_TYPE = 'by-type',
  BY_VALUE = 'by-value',
}

export enum WidgetTypes {
  NUMBER = 'NUMBER',
  CHART = 'CHART',
  NUMBER_CHART = 'NUMBER_CHART',
  TABLE = 'TABLE',
  REQUIRING_ACTION = 'REQUIRING_ACTION',
  TOTAL = 'TOTAL',
  AVERAGE = 'AVERAGE',
  TRACKING = 'TRACKING',
  TARGET = 'TARGET',
  CUSTOM_MULTIPLIER = 'CUSTOM_MULTIPLATION',
  COLUMN = 'COLUMN',
  BAR = 'BAR',
  LINE = 'LINE',
  SCATTER = 'SCATTER',
  AREA = 'AREA',
  PIE = 'PIE',
}

export enum NumberWidgetTypes {
  TOTAL = 'TOTAL',
  AVERAGE = 'AVERAGE',
  TRACKING = 'TRACKING',
  TARGET = 'TARGET',
  CUSTOM_MULTIPLIER = 'CUSTOM_MULTIPLATION',
}

export enum ChartWidgetTypes {
  COLUMN = 'COLUMN',
  BAR = 'BAR',
  LINE = 'LINE',
  SCATTER = 'SCATTER',
  AREA = 'AREA',
  PIE = 'PIE',
}

export type IWidget = {
  title: string;
  subtitle: string;
  icon: string;
  type: WidgetTypes;
};

export const widgetTypes = [
  {
    title: 'Number',
    subtitle: 'Display, track and calculate important numeric values',
    icon: NumberWidgetIcon,
    type: WidgetTypes.NUMBER,
  },
  {
    title: 'Chart',
    subtitle: 'Select from a range of charts, time periods and dimensions',
    icon: ChartWidgetIcon,
    type: WidgetTypes.CHART,
  },
  {
    title: 'Number & Chart',
    subtitle: 'Single widget with both a chart and number',
    icon: NumberChartWidgetIcon,
    type: WidgetTypes.NUMBER_CHART,
    numberWidgetTypes: [WidgetTypes.TOTAL, WidgetTypes.AVERAGE, WidgetTypes.CUSTOM_MULTIPLIER],
  },
  {
    title: 'Table',
    subtitle: 'Display an expandable list of a range of values.',
    icon: TableWidgetIcon,
    type: WidgetTypes.TABLE,
  },
  {
    title: 'Requiring Action',
    subtitle: 'Display a value by a range of times, dimensions and types',
    icon: AlertWidgetIcon,
    type: WidgetTypes.REQUIRING_ACTION,
  },
];

export const numberWidgetTypes = [
  {
    title: 'Total',
    subtitle: 'Total value over a selected time range',
    icon: NumberWidgetIcon,
    type: WidgetTypes.TOTAL,
  },
  {
    title: 'Average',
    subtitle: 'Average value over a selected time range',
    icon: NumberAverageWidgetIcon,
    type: WidgetTypes.AVERAGE,
  },
  {
    title: 'Tracking',
    subtitle: 'Track the total value over a selected time interval',
    icon: NumberTrackingWidgetIcon,
    type: WidgetTypes.TRACKING,
  },
  {
    title: 'Target',
    subtitle: 'Track a value for a selected time period against a pre-defined target',
    icon: NumberTargetWidgetIcon,
    type: WidgetTypes.TARGET,
  },
  {
    title: 'Custom Multiplier',
    subtitle: 'Calculate a custom value',
    icon: NumberCustomMultiplierWidgetIcon,
    type: WidgetTypes.CUSTOM_MULTIPLIER,
  },
];

export const numberWidgetMap = {
  [WidgetTypes.TOTAL]: {
    title: 'Total',
    subtitle: 'Total value over a selected time range',
    icon: NumberWidgetIcon,
    type: WidgetTypes.TOTAL,
  },
  [WidgetTypes.AVERAGE]: {
    title: 'Average',
    subtitle: 'Average value over a selected time range',
    icon: NumberAverageWidgetIcon,
    type: WidgetTypes.AVERAGE,
  },
  [WidgetTypes.TRACKING]: {
    title: 'Tracking',
    subtitle: 'Track the total value over a selected time interval',
    icon: NumberTrackingWidgetIcon,
    type: WidgetTypes.TRACKING,
  },
  [WidgetTypes.TARGET]: {
    title: 'Target',
    subtitle: 'Track a value for a selected time period against a pre-defined target',
    icon: NumberTargetWidgetIcon,
    type: WidgetTypes.TARGET,
  },
  [WidgetTypes.CUSTOM_MULTIPLIER]: {
    title: 'Custom Multiplier',
    subtitle: 'Calculate a custom value',
    icon: NumberCustomMultiplierWidgetIcon,
    type: WidgetTypes.CUSTOM_MULTIPLIER,
  },
} as { [widgetType: string]: IWidget };

export const chartWidgetTypes = [
  {
    title: 'Column',
    subtitle: 'Display a value by a range of times, dimensions and types',
    icon: ColumnChartWidgetIcon,
    type: WidgetTypes.COLUMN,
  },
  {
    title: 'Bar',
    subtitle: 'Select from a range of charts, time periods and dimensions',
    icon: ChartWidgetIcon,
    type: WidgetTypes.BAR,
  },
  {
    title: 'Line',
    subtitle: 'Single widget with both a chart and number',
    icon: LineChartWidgetIcon,
    type: WidgetTypes.LINE,
  },
  {
    title: 'Scatter',
    subtitle: 'Display an expandable list of a range of values.',
    icon: ScatterChartWidgetIcon,
    type: WidgetTypes.SCATTER,
  },
  {
    title: 'Area',
    subtitle: 'Display a value by a range of times, dimensions and types',
    icon: AreaChartWidgetIcon,
    type: WidgetTypes.AREA,
  },
  {
    title: 'Pie',
    subtitle: 'Display a value by a range of times, dimensions and types',
    icon: PieChartWidgetIcon,
    type: WidgetTypes.PIE,
  },
];

export const chartWidgetsMap = {
  [WidgetTypes.COLUMN]: {
    title: 'Column',
    subtitle: 'Display a value by a range of times, dimensions and types',
    icon: ColumnChartWidgetIcon,
    type: WidgetTypes.COLUMN,
  },
  [WidgetTypes.BAR]: {
    title: 'Bar',
    subtitle: 'Select from a range of charts, time periods and dimensions',
    icon: ChartWidgetIcon,
    type: WidgetTypes.BAR,
  },
  [WidgetTypes.LINE]: {
    title: 'Line',
    subtitle: 'Single widget with both a chart and number',
    icon: LineChartWidgetIcon,
    type: WidgetTypes.LINE,
  },
  [WidgetTypes.SCATTER]: {
    title: 'Scatter',
    subtitle: 'Display an expandable list of a range of values.',
    icon: ScatterChartWidgetIcon,
    type: WidgetTypes.SCATTER,
  },
  [WidgetTypes.AREA]: {
    title: 'Area',
    subtitle: 'Display a value by a range of times, dimensions and types',
    icon: AreaChartWidgetIcon,
    type: WidgetTypes.AREA,
  },
  [WidgetTypes.PIE]: {
    title: 'Pie',
    subtitle: 'Display a value by a range of times, dimensions and types',
    icon: PieChartWidgetIcon,
    type: WidgetTypes.PIE,
  },
} as { [type: string]: IWidget };

export interface ByValueType {
  name: string;
  value: string;
  valueItems?: { name: string; value: string }[];
  displayName: string;
}

export enum DimensionEnum {
  BY_TIME = 'TIME',
  BY_PROGRAM = 'PROGRAM',
  BY_ACTIVITY = 'ACTIVITY',
  BY_ACTIVITY_TAG = 'TAG',
  BY_INCIDENT_SEVERITY = 'INCIDENT_SEVERITY',
  BY_INCIDENT_GROUP = 'INCIDENT_GROUP',
  BY_INCIDENT_CATEGORY = 'INCIDENT_CATEGORY',
  BY_INCIDENT_DETAIL = 'INCIDENT_DETAIL',
  BY_INCIDENT_LOCATION = 'INCIDENT_LOCATION',
}

export interface ValueGroupType {
  tag: string;
  values: ByValueType[];
  value: string;
}

export const valueMap = {
  activities: {
    application: {
      name: 'Applications',
      displayName: 'Activity Applications',
      value: 'application',
    },
    waitlist: {
      name: 'Waitlistings',
      displayName: 'Activity Waitlistings',
      value: 'waitlist',
    },
    priority: {
      name: 'Prioritised',
      displayName: 'Prioritised Activities',
      value: 'priority',
    },
    started: {
      name: 'Started',
      displayName: 'Activities Started',
      value: 'started',
    },
    ended: {
      name: 'Ended',
      displayName: 'Activities Ended',
      value: 'ended',
    },
    active: {
      name: 'Active',
      displayName: 'Active Activities',
      value: 'active',
    },
    closed: {
      name: 'Closed',
      displayName: 'Closed Activities',
      value: 'closed',
    },
    suspended: {
      name: 'Suspended',
      displayName: 'Suspended Activities',
      value: 'suspended',
    },
    total: {
      name: 'Activities (Total)',
      displayName: 'Total Activities',
      value: 'total',
    },
  },
  rosters: {
    published: {
      name: 'Published',
      displayName: 'Published Rosters',
      value: 'published',
    },
    running: {
      name: 'Running',
      displayName: 'Rosters Running',
      value: 'running',
    },
    cancellation: {
      name: 'Cancellations',
      displayName: 'Cancelled Rosters',
      value: 'cancellation',
    },
    volunteer: {
      name: 'Rostered Volunteers',
      displayName: 'Rostered Volunteers',
      value: 'volunteer',
    },
    availability: {
      name: 'Available Volunteers',
      displayName: 'Volunteer Availabilities',
      value: 'availability',
    },
    unavailability: {
      name: 'Unavailable Volunteers',
      displayName: 'Volunteer Unavailabilities',
      value: 'unavailability',
    },
  },
  programs: {
    application: {
      name: 'Applications',
      displayName: 'Program Applications',
      value: 'application',
    },
    total: {
      name: 'Programs (Total)',
      displayName: 'Total Programs',
      value: 'total',
    },
  },
  volunteers: {
    total: {
      name: 'Volunteers (Total)',
      displayName: 'Total Volunteers',
      value: 'total',
    },
    incoming: {
      name: 'Incoming Applications',
      displayName: 'Incoming Volunteer Applications',
      value: 'incoming',
    },
    awaiting: {
      name: 'Applications Awaiting Approval',
      displayName: 'Applications Awaiting Approval',
      value: 'awaiting',
    },
    export: {
      name: 'Database Exports',
      displayName: 'Volunteer Database Exports',
      value: 'export',
    },
    'time-to': {
      name: 'Time To Approval',
      displayName: 'Time To Approval',
      value: 'time-to',
    },
  },
  impact: {
    'volunteer-hours': {
      name: 'Volunteer Hours',
      displayName: 'Volunteer Hours',
      value: 'volunteer-hours',
    },
    'operation-hours': {
      name: 'Operation Hours',
      displayName: 'Operation Hours',
      value: 'operation-hours',
    },
    attendance: {
      name: 'Attendances',
      displayName: 'Volunteer Attendances',
      value: 'attendance',
    },
  },
  reporting: {
    incomplete: {
      name: 'Incomplete',
      displayName: 'Incomplete Reports',
      value: 'incomplete',
    },
    completed: {
      name: 'Complete',
      displayName: 'Complete Reports',
      value: 'completed',
    },
    omitted: {
      name: 'Omitted',
      displayName: 'Omitted Reports',
      value: 'omitted',
    },
    'report-field-value': {
      name: 'Report Field Values',
      displayName: 'Report Field Value',
      value: 'report-field-value',
    },
  },
  tasks: {
    task: {
      name: 'Tasks (Total)',
      displayName: 'Total Tasks',
      value: 'task',
    },
    incomplete: {
      name: 'Incomplete',
      displayName: 'Incomplete Tasks',
      value: 'incomplete',
    },
    expiring: {
      name: 'Expiring',
      displayName: 'Tasks Expiring',
      value: 'expiring',
    },
    hidden: {
      name: 'Hidden',
      displayName: 'Hidden Tasks',
      value: 'hidden',
    },
    awaiting: {
      name: 'Awaiting Approval',
      displayName: 'Tasks Awaiting Approval',
      value: 'awaiting',
    },
    'time-to-approval': {
      name: 'Time to Approval',
      displayName: 'Time to Task Approval',
      value: 'time-to-approval',
    },
    field: {
      name: 'Fields (Total)',
      displayName: 'Total Task Fields',
      value: 'field',
    },
  },
  incidents: {
    new: {
      name: 'New',
      displayName: 'New Incidents',
      value: 'new',
    },
    'in-review': {
      name: 'In Review',
      displayName: 'Incidents In Review',
      value: 'in-review',
    },
    completed: {
      name: 'Completed',
      displayName: 'Completed Incidents',
      value: 'completed',
    },
    archived: {
      name: 'Archived',
      displayName: 'Archived Incidents',
      value: 'archived',
    },
  },
};

export const valueLists = [
  {
    tag: 'Activities',
    value: 'activities',
    values: [
      {
        name: 'Applications',
        displayName: 'Activity Applications',
        value: 'application',
      },
      {
        name: 'Waitlistings',
        displayName: 'Activity Waitlistings',
        value: 'waitlist',
      },
      {
        name: 'Prioritised',
        displayName: 'Prioritised Activities',
        value: 'priority',
      },
      {
        name: 'Started',
        displayName: 'Activities Started',
        value: 'started',
      },
      {
        name: 'Ended',
        displayName: 'Activities Ended',
        value: 'ended',
      },
      {
        name: 'Active',
        displayName: 'Active Activities',
        value: 'active',
      },
      {
        name: 'Closed',
        displayName: 'Closed Activities',
        value: 'closed',
      },
      {
        name: 'Suspended',
        displayName: 'Suspended Activities',
        value: 'suspended',
      },
      {
        name: 'Activities (Total)',
        displayName: 'Total Activities',
        value: 'total',
      },
    ],
  },
  {
    tag: 'Rosters',
    value: 'rosters',
    values: [
      {
        name: 'Published',
        displayName: 'Published Rosters',
        value: 'published',
      },
      {
        name: 'Running',
        displayName: 'Rosters Running',
        value: 'running',
      },
      {
        name: 'Cancellations',
        displayName: 'Cancelled Rosters',
        value: 'cancellation',
      },
      {
        name: 'Rostered Volunteers',
        displayName: 'Rostered Volunteers',
        value: 'volunteer',
      },
      {
        name: 'Available Volunteers',
        displayName: 'Volunteer Availabilities',
        value: 'availability',
      },
      {
        name: 'Unavailable Volunteers',
        displayName: 'Volunteer Unavailabilities',
        value: 'unavailability',
      },
    ],
  },
  {
    tag: 'Programs',
    value: 'programs',
    values: [
      {
        name: 'Applications',
        displayName: 'Program Applications',
        value: 'application',
      },
      {
        name: 'Programs (Total)',
        displayName: 'Total Programs',
        value: 'total',
      },
      // {
      //   name: 'Volunteers',
      //   displayName: 'Program Volunteers',
      //   value: 'volunteer',
      //   numberWidgetTypes: [WidgetTypes.TOTAL, WidgetTypes.TRACKING, WidgetTypes.TARGET, WidgetTypes.CUSTOM_MULTIPLIER],
      // },
      // {
      //   name: 'Activities',
      //   displayName: 'Program Activities',
      //   value: 'activity',
      //   numberWidgetTypes: [WidgetTypes.TOTAL, WidgetTypes.AVERAGE, WidgetTypes.TRACKING, WidgetTypes.TARGET, WidgetTypes.CUSTOM_MULTIPLIER],
      // },
    ],
  },
  {
    tag: 'Volunteers',
    value: 'volunteers',
    values: [
      {
        name: 'Volunteers (Total)',
        displayName: 'Total Volunteers',
        value: 'total',
      },
      {
        name: 'Incoming Applications',
        displayName: 'Incoming Volunteer Applications',
        value: 'incoming',
      },
      {
        name: 'Applications Awaiting Approval',
        displayName: 'Applications Awaiting Approval',
        value: 'awaiting',
      },
      {
        name: 'Database Exports',
        displayName: 'Volunteer Database Exports',
        value: 'export',
      },
      {
        name: 'Time To Approval',
        displayName: 'Time To Approval',
        value: 'time-to',
      },
    ],
  },
  {
    tag: 'Impact',
    value: 'impact',
    values: [
      {
        name: 'Volunteer Hours',
        displayName: 'Volunteer Hours',
        value: 'volunteer-hours',
      },
      {
        name: 'Operation Hours',
        displayName: 'Operation Hours',
        value: 'operation-hours',
      },
      {
        name: 'Attendances',
        displayName: 'Volunteer Attendances',
        value: 'attendance',
      },
    ],
  },
  {
    tag: 'Reporting',
    value: 'reporting',
    values: [
      {
        name: 'Incomplete',
        displayName: 'Incomplete Reports',
        value: 'incomplete',
      },
      {
        name: 'Complete',
        displayName: 'Complete Reports',
        value: 'completed',
      },
      {
        name: 'Omitted',
        displayName: 'Omitted Reports',
        value: 'omitted',
      },
      {
        name: 'Report Field Values',
        displayName: 'Report Field Value',
        value: 'report-field-value',
        // valueItems: [
        //   {
        //     name: 'Number',
        //     value: 'number',
        //   },
        //   {
        //     name: 'Checkbox',
        //     value: 'checkbox',
        //   },
        //   {
        //     name: 'Dropdown',
        //     value: 'dropdown',
        //   },
        //   {
        //     name: 'Rating',
        //     value: 'rating',
        //   },
        //   {
        //     name: 'Boolean',
        //     value: 'boolean',
        //   },
        //   {
        //     name: 'Time',
        //     value: 'time',
        //   },
        //   {
        //     name: 'Text',
        //     value: 'text',
        //   },
        //   {
        //     name: 'Attachment',
        //     value: 'attachment',
        //   },
        // ],
      },
      // {
      //   name: 'Report Types (Total)',
      //   displayName: 'Total Report Types',
      //   value: 'report-type',
      // },
      // {
      //   name: 'Report Fields (Total)',
      //   displayName: 'Total Report Fields',
      //   value: 'report-field',
      // },
    ],
  },
  {
    tag: 'Tasks',
    value: 'tasks',
    values: [
      {
        name: 'Tasks (Total)',
        displayName: 'Total Tasks',
        value: 'task',
      },
      {
        name: 'Incomplete',
        displayName: 'Incomplete Tasks',
        value: 'incomplete',
      },
      {
        name: 'Expiring',
        displayName: 'Tasks Expiring',
        value: 'expiring',
      },
      {
        name: 'Hidden',
        displayName: 'Hidden Tasks',
        value: 'hidden',
      },
      {
        name: 'Awaiting Approval',
        displayName: 'Tasks Awaiting Approval',
        value: 'awaiting',
      },
      {
        name: 'Time to Approval',
        displayName: 'Time to Task Approval',
        value: 'time-to-approval',
      },
      {
        name: 'Fields (Total)',
        displayName: 'Total Task Fields',
        value: 'field',
      },
    ],
  },
  {
    tag: 'Incidents',
    value: 'incidents',
    values: [
      {
        name: 'New',
        displayName: 'New Incidents',
        value: 'new',
      },
      {
        name: 'In Review',
        displayName: 'Incidents In Review',
        value: 'in-review',
      },
      {
        name: 'Completed',
        displayName: 'Completed Incidents',
        value: 'completed',
      },
      {
        name: 'Archived',
        displayName: 'Archived Incidents',
        value: 'archived',
      },
      // {
      //   name: 'Per Severity',
      //   displayName: 'Incidents Per Severity',
      //   value: 'per-severity',
      // },
      // {
      //   name: 'Per Category',
      //   displayName: 'Incidents Per Category',
      //   value: 'per-category',
      // },
      // {
      //   name: 'Per Group',
      //   displayName: 'Incidents Per Group',
      //   value: 'per-group',
      // },
      // {
      //   name: 'Per Detail',
      //   displayName: 'Incidents Per Detail',
      //   value: 'per-detail',
      // },
    ],
  },
  //       displayName: 'Emails Sent',
  //       value: 'email',
  //     },
  //     {
  //       name: 'SMS Sent',
  //       displayName: 'SMS sent',
  //       value: 'sms',
  //     },
  //   ],
  // },
] as ValueGroupType[];

export const supportingWidgetTypes = {
  activities: {
    application: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      requiringActionWidgetType: true,
      tableWidgetType: true,
    },
    waitlist: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      requiringActionWidgetType: true,
      tableWidgetType: true,
    },
    priority: {
      numberWidgetTypes: [WidgetTypes.TOTAL, WidgetTypes.TARGET, WidgetTypes.CUSTOM_MULTIPLIER],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.PIE],
      tableWidgetType: true,
    },
    started: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    ended: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    active: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    closed: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    suspended: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    total: {
      numberWidgetTypes: [WidgetTypes.TOTAL, WidgetTypes.CUSTOM_MULTIPLIER, WidgetTypes.TARGET],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.PIE],
    },
  },
  rosters: {
    published: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    running: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    cancellation: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      tableWidgetType: true,
    },
    volunteer: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    availability: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    unavailability: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
  },
  programs: {
    application: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      requiringActionWidgetType: true,
      tableWidgetType: true,
    },
    total: {
      numberWidgetTypes: [WidgetTypes.TOTAL, WidgetTypes.CUSTOM_MULTIPLIER, WidgetTypes.TARGET],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE], // Restrict Column/Bar Program/Activity Dimensions - Time ONLY
    },
  },
  impact: {
    'volunteer-hours': {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    'operation-hours': {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    attendance: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
  },
  volunteers: {
    total: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    incoming: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      requiringActionWidgetType: true,
      tableWidgetType: true,
    },
    awaiting: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      tableWidgetType: true,
    },
    export: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE],
    },
    'time-to': {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE],
    },
  },
  tasks: {
    task: {
      numberWidgetTypes: [WidgetTypes.TOTAL, WidgetTypes.TARGET, WidgetTypes.CUSTOM_MULTIPLIER],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE],
    },
    incomplete: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    expiring: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA],
      tableWidgetType: true,
    },
    hidden: {
      numberWidgetTypes: [WidgetTypes.TOTAL, WidgetTypes.TARGET, WidgetTypes.CUSTOM_MULTIPLIER],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.PIE],
    },
    awaiting: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      tableWidgetType: true,
    },
    'time-to-approval': {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA],
    },
    field: {
      numberWidgetTypes: [WidgetTypes.TOTAL, WidgetTypes.TARGET, WidgetTypes.CUSTOM_MULTIPLIER],
    },
  },
  reporting: {
    incomplete: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    completed: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    omitted: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    'report-field-value': {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
  },
  incidents: {
    new: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      requiringActionWidgetType: true,
      tableWidgetType: true,
    },
    'in-review': {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      requiringActionWidgetType: true,
      tableWidgetType: true,
    },
    completed: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      tableWidgetType: true,
    },
    archived: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
      tableWidgetType: true,
    },
    'per-severity': {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    'per-category': {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    'per-group': {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
    'per-detail': {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE, WidgetTypes.AREA, WidgetTypes.PIE],
    },
  },
  communications: {
    email: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE],
    },
    sms: {
      numberWidgetTypes: [
        WidgetTypes.TOTAL,
        WidgetTypes.AVERAGE,
        WidgetTypes.TRACKING,
        WidgetTypes.TARGET,
        WidgetTypes.CUSTOM_MULTIPLIER,
      ],
      chartWidgetTypes: [WidgetTypes.COLUMN, WidgetTypes.BAR, WidgetTypes.LINE],
    },
  },
};

export enum Steps {
  SELECT_CREATE_BY = 'SELECT_CREATE_BY',
  SELECT_DETAIL_TYPE = 'SELECT_DETAIL_TYPE',
  WIDGET_FORM = 'WIDGET_FORM',
  EDIT_BY_VALUE = 'EDIT_BY_VALUE',
  ADD_BY_VALUE = 'ADD_BY_VALUE',
}

export interface WidgetValueType {
  tag: string;
  name: string;
}

export enum StepsEnum {
  SELECT_WIDGET = 'SELECT WIDGET',
  SELECT_CHART_TYPE = 'SELECT CHART TYPE',
  SELECT_NUMBER_TYPE = 'SELECT NUMBER TYPE',
  SETTINGS = 'SETTINGS',
  ADD_VALUE = 'ADD VALUE',
}
export interface CreatWidgetFormValues {
  byValues: WidgetValueType[];
  byType: WidgetTypes | null;
  numberWidgetType: WidgetTypes | null;
  chartWidgetType: WidgetTypes | null;
  programIds: string[];
  activityIds: string[];
  tagIds: string[];
  interval: string | null;
  highlight: string | null;
  range: string | null;
  order: string | null;
  dimension: DimensionEnum | null;
  group: string | null;
  target: number | null;
  customMultiplier: number | null;
  selectStep: StepsEnum;
  isNew: boolean;
  updatingValueIndex?: number;
  fieldIds: string[];
}

// export const SELECTION_FLOWS = {
//   [WidgetTypes.NUMBER]: [StepsEnum.SELECT_NUMBER_TYPE, StepsEnum.SELECT_VALUE],
//   [WidgetTypes.CHART]: [StepsEnum.SELECT_CHART_TYPE, StepsEnum.SELECT_VALUE],
//   [WidgetTypes.NUMBER_CHART]: [StepsEnum.SELECT_NUMBER_TYPE, StepsEnum.SELECT_CHART_TYPE, StepsEnum.SELECT_VALUE],
//   [WidgetTypes.TABLE]: [StepsEnum.SELECT_VALUE],
//   [WidgetTypes.REQUIRING_ACTION]: [StepsEnum.SELECT_VALUE],
// }

export enum Subtitles {
  CreateByType = 'Select a widget type',
  CreateByValue = 'Select one of the below value types',
  SelectType = 'Select one of the relevant value types for the selected widget',
  SelectValue = 'Select one of the relevant widget types',
  SelectNumber = 'Select a number type',
  SelectChart = 'Select a chart type',
  FormWidget = 'Select and define your widget configuration',
}

export enum INTERVAL_ENUM {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum RANGE_ENUM {
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  ALL_TIME = 'ALL_TIME',
}

export const rangeIntervalMaping = {
  [RANGE_ENUM.TODAY]: [INTERVAL_ENUM.DAILY],
  [RANGE_ENUM.THIS_WEEK]: [INTERVAL_ENUM.DAILY, INTERVAL_ENUM.WEEKLY],
  [RANGE_ENUM.THIS_MONTH]: [INTERVAL_ENUM.DAILY, INTERVAL_ENUM.WEEKLY, INTERVAL_ENUM.MONTHLY],
  [RANGE_ENUM.THIS_YEAR]: [INTERVAL_ENUM.DAILY, INTERVAL_ENUM.WEEKLY, INTERVAL_ENUM.MONTHLY, INTERVAL_ENUM.YEARLY],
  [RANGE_ENUM.ALL_TIME]: [INTERVAL_ENUM.MONTHLY, INTERVAL_ENUM.YEARLY],
} as { [key: string]: Array<string> };

export const rangeHightlightMaping = {
  [RANGE_ENUM.TODAY]: [RANGE_ENUM.TODAY],
  [RANGE_ENUM.THIS_WEEK]: [RANGE_ENUM.TODAY, RANGE_ENUM.THIS_WEEK],
  [RANGE_ENUM.THIS_MONTH]: [RANGE_ENUM.TODAY, RANGE_ENUM.THIS_WEEK, RANGE_ENUM.THIS_MONTH],
  [RANGE_ENUM.THIS_YEAR]: [RANGE_ENUM.TODAY, RANGE_ENUM.THIS_WEEK, RANGE_ENUM.THIS_MONTH, RANGE_ENUM.THIS_WEEK],
  [RANGE_ENUM.ALL_TIME]: [RANGE_ENUM.TODAY, RANGE_ENUM.THIS_WEEK, RANGE_ENUM.THIS_MONTH, RANGE_ENUM.THIS_WEEK],
} as { [key: string]: Array<string> };

export const intervalLabelMap = {
  [INTERVAL_ENUM.DAILY]: { label: 'Daily', value: 'DAILY' },
  [INTERVAL_ENUM.WEEKLY]: { label: 'Weekly', value: 'WEEKLY' },
  [INTERVAL_ENUM.MONTHLY]: { label: 'Monthly', value: 'MONTHLY' },
  [INTERVAL_ENUM.YEARLY]: { label: 'Yearly', value: 'YEARLY' },
} as { [key: string]: { label: string; value: string } };

export const rangeLabelMap = {
  [RANGE_ENUM.TODAY]: { label: 'Today', value: 'TODAY' },
  [RANGE_ENUM.THIS_WEEK]: { label: 'This week', value: 'THIS_WEEK' },
  [RANGE_ENUM.THIS_MONTH]: { label: 'This month', value: 'THIS_MONTH' },
  [RANGE_ENUM.THIS_YEAR]: { label: 'This year', value: 'THIS_YEAR' },
  [RANGE_ENUM.ALL_TIME]: { label: 'All time', value: 'ALL_TIME' },
} as { [key: string]: { label: string; value: string } };

export const intervalList = [
  { label: 'Daily', value: 'DAILY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Yearly', value: 'YEARLY' },
];

export const rangeList = [
  { label: 'Today', value: 'TODAY' },
  { label: 'This week', value: 'THIS_WEEK' },
  { label: 'This month', value: 'THIS_MONTH' },
  { label: 'This year', value: 'THIS_YEAR' },
  { label: 'All time', value: 'ALL_TIME' },
];

export const hightlightList = [
  { label: 'Today', value: 'TODAY' },
  { label: 'This week', value: 'THIS_WEEK' },
  { label: 'This month', value: 'THIS_MONTH' },
  { label: 'This year', value: 'THIS_YEAR' },
];

export const secondaryRangeList = [
  { label: 'All', value: 'all' },
  { label: '7 days', value: '7 days ago' },
  { label: '15 days', value: '15 days ago' },
  { label: '1 month', value: '1 month ago' },
  { label: '3 months', value: '3 months ago' },
];

export const dimensionLabels = {
  [DimensionEnum.BY_TIME]: 'By time',
  [DimensionEnum.BY_ACTIVITY]: 'By activity',
  [DimensionEnum.BY_PROGRAM]: 'By program',
  [DimensionEnum.BY_ACTIVITY_TAG]: 'By activity tag',
  [DimensionEnum.BY_INCIDENT_CATEGORY]: 'By incident category',
  [DimensionEnum.BY_INCIDENT_DETAIL]: 'By incident detail',
  [DimensionEnum.BY_INCIDENT_GROUP]: 'By incident group',
  [DimensionEnum.BY_INCIDENT_SEVERITY]: 'By incident severity',
  [DimensionEnum.BY_INCIDENT_LOCATION]: 'By incident location',
} as { [key: string]: string };

export const dimensionOptions = [
  { label: 'By Time', value: DimensionEnum.BY_TIME },
  { label: 'By Program', value: DimensionEnum.BY_PROGRAM },
  { label: 'By Activity', value: DimensionEnum.BY_ACTIVITY },
  { label: 'By Activity tag', value: DimensionEnum.BY_ACTIVITY_TAG },
];

export const groupOptions = [
  { label: 'None', value: null },
  { label: 'By Program', value: DimensionEnum.BY_PROGRAM },
  { label: 'By Activity', value: DimensionEnum.BY_ACTIVITY },
  { label: 'By Activity tag', value: DimensionEnum.BY_ACTIVITY_TAG },
];

export const getWidgetType = (type: string) => {
  switch (type) {
    case 'VOLUNTEER_ChartWidgetType':
      return WidgetTypes.CHART;

    case 'VOLUNTEER_TableWidgetType':
      return WidgetTypes.TABLE;

    case 'VOLUNTEER_RequiringActionWidgetType':
      return WidgetTypes.REQUIRING_ACTION;

    case 'VOLUNTEER_ChartNumberWidgetType':
      return WidgetTypes.NUMBER_CHART;

    case 'VOLUNTEER_NumberWidgetType':
      return WidgetTypes.NUMBER;

    default:
      return null;
  }
};

export const getRangeWidgetType = (widgetComponent: IComponent) => {
  if (!widgetComponent) return null;
  const { widget } = widgetComponent;

  if (widget.__typename === 'VOLUNTEER_ChartWidgetType') {
    return widget.configuration.range;
  }

  if (widget.__typename === 'VOLUNTEER_NumberWidgetType') {
    if (
      widget.configuration.__typename === 'VOLUNTEER_AverageNumberConfigurationType' ||
      widget.configuration.__typename === 'VOLUNTEER_CustomMultiplationNumberConfigurationType' ||
      widget.configuration.__typename === 'VOLUNTEER_TargetNumberConfigurationType' ||
      widget.configuration.__typename === 'VOLUNTEER_TotalNumberConfigurationType'
    ) {
      return widget.configuration.range;
    }
  }

  if (widget.__typename === 'VOLUNTEER_ChartNumberWidgetType') {
    return widget.chartConfiguration.range;
  }

  if (widget.__typename === 'VOLUNTEER_TableWidgetType') {
    return widget.range ?? 'all';
  }

  if (widget.__typename === 'VOLUNTEER_RequiringActionWidgetType') {
    return widget.range ?? 'all';
  }

  return null;
};

export const getTarget = (widgetComponent: IComponent) => {
  if (!widgetComponent) return null;
  const { widget } = widgetComponent;

  if (widget.__typename === 'VOLUNTEER_NumberWidgetType') {
    if (widget.configuration.__typename === 'VOLUNTEER_TargetNumberConfigurationType') {
      return widget.configuration.target;
    }
  }

  return null;
};

export const getCustomMultiplier = (widgetComponent: IComponent) => {
  if (!widgetComponent) return null;
  const { widget } = widgetComponent;

  if (widget.__typename === 'VOLUNTEER_NumberWidgetType') {
    if (widget.configuration.__typename === 'VOLUNTEER_CustomMultiplationNumberConfigurationType') {
      return widget.configuration.multiplier;
    }
  }

  return null;
};

export const getDimensionWidgetType: (widgetComponent: IComponent) => DimensionEnum | null = (
  widgetComponent: IComponent
) => {
  if (!widgetComponent) return null;
  const { widget } = widgetComponent;

  if (widget.__typename === 'VOLUNTEER_ChartWidgetType') {
    return widget.configuration.dimension as DimensionEnum;
  }

  if (widget.__typename === 'VOLUNTEER_ChartNumberWidgetType') {
    return widget.chartConfiguration.dimension as DimensionEnum;
  }
  return null;
};

export const getChartWidgetType = (widgetComponent: IComponent) => {
  if (!widgetComponent) return null;
  const { widget } = widgetComponent;

  if (widget.__typename === 'VOLUNTEER_ChartWidgetType') {
    return mappingChartWidgetType(widget.configuration.chartType);
  }

  if (widget.__typename === 'VOLUNTEER_ChartNumberWidgetType') {
    return mappingChartWidgetType(widget.chartConfiguration.chartType);
  }
  return null;
};

export const getNumberWidgetType = (widgetComponent: IComponent) => {
  if (!widgetComponent) return null;
  const { widget } = widgetComponent;

  if (widget.__typename === 'VOLUNTEER_NumberWidgetType') {
    return mappingNumberWidgetType(widget.configuration.__typename);
  }

  if (widget.__typename === 'VOLUNTEER_ChartNumberWidgetType') {
    return mappingNumberWidgetType(widget.numberConfiguration.__typename);
  }
  return null;
};

export const getGroup = (widgetComponent?: IComponent) => {
  if (!widgetComponent) return null;
  const { widget } = widgetComponent;

  if (widget.__typename === 'VOLUNTEER_ChartNumberWidgetType') {
    return widget.chartConfiguration.group;
  }

  if (widget.__typename === 'VOLUNTEER_ChartWidgetType') {
    return widget.configuration.group;
  }

  return null;
};

const mappingChartWidgetType = (type: string) => {
  switch (type) {
    case 'COLUMN':
      return WidgetTypes.COLUMN;

    case 'BAR':
      return WidgetTypes.BAR;

    case 'LINE':
      return WidgetTypes.LINE;

    case 'SCATTER':
      return WidgetTypes.SCATTER;

    case 'AREA':
      return WidgetTypes.AREA;

    case 'PIE':
      return WidgetTypes.PIE;

    default:
      return null;
  }
};

const mappingNumberWidgetType = (type: string) => {
  switch (type) {
    case 'VOLUNTEER_TotalNumberConfigurationType':
      return WidgetTypes.TOTAL;

    case 'VOLUNTEER_AverageNumberConfigurationType':
      return WidgetTypes.AVERAGE;

    case 'VOLUNTEER_TrackingNumberConfigurationType':
      return WidgetTypes.TRACKING;

    case 'VOLUNTEER_TargetNumberConfigurationType':
      return WidgetTypes.TARGET;

    case 'VOLUNTEER_CustomMultiplationNumberConfigurationType':
      return WidgetTypes.CUSTOM_MULTIPLIER;

    default:
      return null;
  }
};

export const getInterval = (widgetComponent: IComponent) => {
  if (!widgetComponent) return null;
  const { widget } = widgetComponent;

  if (widget.__typename === 'VOLUNTEER_ChartWidgetType') {
    return widget.configuration.interval;
  }

  if (widget.__typename === 'VOLUNTEER_NumberWidgetType') {
    if (widget.configuration.__typename === 'VOLUNTEER_AverageNumberConfigurationType') {
      return widget.configuration.interval;
    }

    if (widget.configuration.__typename === 'VOLUNTEER_TrackingNumberConfigurationType') {
      return widget.configuration.interval;
    }

    return null;
  }

  if (widget.__typename === 'VOLUNTEER_ChartNumberWidgetType') {
    return widget.chartConfiguration.interval;
  }

  return null;
};

export const getHighlight = (widgetComponent: IComponent) => {
  if (!widgetComponent) return null;

  const { widget } = widgetComponent;

  if (widget.__typename === 'VOLUNTEER_ChartNumberWidgetType') {
    if (widget.numberConfiguration.__typename === 'VOLUNTEER_AverageNumberConfigurationType') {
      return widget.numberConfiguration.range;
    }
    if (widget.numberConfiguration.__typename === 'VOLUNTEER_TargetNumberConfigurationType') {
      return widget.numberConfiguration.range;
    }

    if (widget.numberConfiguration.__typename === 'VOLUNTEER_TotalNumberConfigurationType') {
      return widget.numberConfiguration.range;
    }

    if (widget.numberConfiguration.__typename === 'VOLUNTEER_CustomMultiplationNumberConfigurationType') {
      return widget.numberConfiguration.range;
    }

    return null;
  }

  return null;
};
