import React from 'react';
import { ActivitySidebar } from './ActivitySidebar/ActivitySidebar';
import { ActivityDetailsSkeleton } from './ActivitySkeleton/ActivityDetailsSkeleton';
import { ActivityContextValue } from './ActivityContext';

interface Props {
  inMyShift?: boolean;
}

export function ActivitiesSidebar({ inMyShift }: Props) {
  const { activityLoading } = React.useContext(ActivityContextValue);
  if (activityLoading) {
    return <ActivityDetailsSkeleton inMyShift={inMyShift} />;
  }

  return <ActivitySidebar inMyShift={inMyShift} />;
}
