import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  MobileStepper,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { Check, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { TutorialDialogProps } from './TutorialDialog';
import { useScreenControl } from '../../global/screen-control/useScreenControlContext';

const WIDTH = 1280;
const DESKTOP_BUTTON_MARGIN = 32;

type ButtonProps = {
  activeStep: number;
  maxSteps?: number;
  handleClick: () => void;
  handleClose?: () => void;
};

const DesktopNextButton = (props: ButtonProps) => {
  const { activeStep, maxSteps = 1, handleClick, handleClose } = props;
  const { theme } = useCampfireTheme();
  return activeStep < maxSteps - 1 ? (
    <Tooltip title='Next'>
      <IconButton
        aria-label='next'
        style={{ marginLeft: DESKTOP_BUTTON_MARGIN, backgroundColor: 'white' }}
        onClick={handleClick}
      >
        <KeyboardArrowRight fontSize='large' />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title='Got It!'>
      <IconButton
        aria-label='complete-tutorial'
        style={{ marginLeft: DESKTOP_BUTTON_MARGIN, backgroundColor: theme.status.green.medium }}
        onClick={handleClose}
      >
        <Check fontSize='large' style={{ color: 'white' }} />
      </IconButton>
    </Tooltip>
  );
};

const DesktopBackButton = (props: ButtonProps) => {
  const { activeStep, handleClick } = props;
  return (
    <Tooltip title='Previous'>
      <div>
        <IconButton
          aria-label='previous'
          style={{ marginRight: DESKTOP_BUTTON_MARGIN, backgroundColor: 'white', opacity: activeStep > 0 ? 1 : 0 }}
          onClick={handleClick}
          disabled={activeStep === 0}
        >
          <KeyboardArrowLeft fontSize='large' />
        </IconButton>
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: { opacity: 0 },
    backgroundTransition: {
      transition: theme.transitions.create('background'),
    },
  })
);

const WelcomeDialog = (props: TutorialDialogProps) => {
  const { steps, open, onClose, enableBackdropClick } = props;
  const { setScreenInUse } = useScreenControl();

  useEffect(() => {
    if (open) {
      setScreenInUse(open);
    }
    return () => {
      setScreenInUse(false);
    };
  }, [open]);

  if (!steps) return null;

  const { isMobile } = useCampfireTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const classes = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    if (onClose) {
      onClose({}, 'escapeKeyDown');
    }
  };

  // This is a patch for tutorial dialogs who's number of steps might change before the transition has had a change to exit
  const activeStepData = activeStep < maxSteps - 1 ? steps[activeStep] : steps[maxSteps - 1];

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      PaperProps={{
        style: { margin: 16, background: 'transparent', boxShadow: 'none', width: '100%', maxWidth: WIDTH },
      }}
      onClose={enableBackdropClick ? onClose : undefined}
      TransitionProps={{
        onExited: () => {
          setActiveStep(0);
        },
      }}
    >
      <Grid
        container
        direction='row'
        alignContent='center'
        alignItems='center'
        justify='space-evenly'
        wrap='nowrap'
        style={{ width: '100%' }}
      >
        {!isMobile ? (
          <Grid item>
            <DesktopBackButton activeStep={activeStep} handleClick={handleBack} />
          </Grid>
        ) : null}
        <Grid item xs={isMobile ? 12 : 8}>
          <Paper elevation={0} style={{ background: activeStepData.theme ? activeStepData.theme.background : '#fff' }}>
            <DialogContent style={{ padding: 0 }}>
              {isMobile ? (
                <MobileStepper
                  variant={maxSteps > 1 ? 'text' : 'dots'}
                  classes={{ dots: classes.hidden }}
                  style={{
                    background: 'transparent',
                  }}
                  steps={maxSteps}
                  position='static'
                  activeStep={activeStep}
                  backButton={
                    <Button
                      style={{ opacity: activeStep > 0 ? 1 : 0 }}
                      size='small'
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {'Back'}
                    </Button>
                  }
                  nextButton={
                    activeStep < maxSteps - 1 ? (
                      <Button
                        style={{ opacity: activeStep < maxSteps - 1 ? 1 : 0 }}
                        size='small'
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                      >
                        {'Next'}
                      </Button>
                    ) : (
                      <Button size='small' onClick={handleClose}>
                        {'Got It!'}
                      </Button>
                    )
                  }
                />
              ) : (
                <MobileStepper
                  variant='dots'
                  classes={maxSteps === 1 ? { dots: classes.hidden } : undefined}
                  steps={maxSteps}
                  position='static'
                  activeStep={activeStep}
                  style={{ justifyContent: 'center', background: 'transparent', paddingBottom: 16, paddingTop: 16 }}
                  nextButton={<></>}
                  backButton={<></>}
                />
              )}
              <Box
                className={classes.backgroundTransition}
                height={isMobile ? 250 : 350}
                style={{
                  backgroundImage: `url(${activeStepData.image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPositionX: 'center',
                  backgroundPositionY: 'center',
                }}
              />

              <Box
                paddingLeft={isMobile ? 3 : 8}
                paddingRight={isMobile ? 3 : 8}
                marginBottom={isMobile ? 3 : 4}
                minHeight={96}
                textAlign='center'
              >
                <Typography
                  variant='h6'
                  component='h1'
                  style={{
                    fontWeight: 700,
                    marginBottom: 16,
                    color: activeStepData.theme ? activeStepData.theme.color : 'inherit',
                  }}
                >
                  {activeStepData.label}
                </Typography>
                <Typography
                  style={{
                    whiteSpace: 'pre-wrap',
                    color: activeStepData.theme ? activeStepData.theme.color : 'inherit',
                  }}
                >
                  {activeStepData.description}
                </Typography>
              </Box>
            </DialogContent>
          </Paper>
        </Grid>

        {!isMobile ? (
          <Grid item>
            <DesktopNextButton
              activeStep={activeStep}
              maxSteps={maxSteps}
              handleClick={handleNext}
              handleClose={handleClose}
            />
          </Grid>
        ) : null}
      </Grid>
    </Dialog>
  );
};

export { WelcomeDialog };
