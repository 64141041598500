import React, { useState, useRef, useEffect } from 'react';
import Signature from 'signature_pad';
import { Box } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';

import { useCampfireTheme } from '../../theme/useCampfireTheme';

const WIDTH = 500;
const HEIGHT = 200;
const WIDTH_MOBILE = 270;
const HEIGHT_MOBILE = 108;

export const SignaturePad = ({ value, onChange }: { value: string; onChange: (file: string) => void }) => {
  const { theme, isMobile } = useCampfireTheme();
  const [signaturePad, setSignaturePad] = useState<Signature>();
  const canvasRef = useRef() as React.MutableRefObject<any>;
  const withCanvas = isMobile ? WIDTH_MOBILE : WIDTH;
  const heightCanvas = isMobile ? HEIGHT_MOBILE : HEIGHT;

  const initPad = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.width = withCanvas;
    canvas.height = heightCanvas;
    const canvasContext = canvas.getContext('2d');
    if (canvasContext) {
      canvasContext.scale(1, 1);
      const tempSignaturePad = new Signature(canvasRef.current, {
        backgroundColor: 'rgb(255, 255, 255)',
        maxWidth: isMobile ? 1 : 2,
      });
      tempSignaturePad.addEventListener('endStroke', () => {
        const dataUrl = tempSignaturePad.toDataURL();
        onChange(dataUrl.split(',')[1]);
      });

      setSignaturePad(tempSignaturePad);
    }
  };

  useEffect(() => {
    initPad();
  }, []);

  useEffect(() => {
    if (value && signaturePad) {
      signaturePad.clear();
      if (value.includes('http')) {
        const fetchImg = async () => {
          const data = (await resolveFile(value)) as any;
          if (data) {
            signaturePad.fromDataURL(data?.target?.result, { width: withCanvas, height: heightCanvas }).then(() => {
              const dataUrl = signaturePad.toDataURL();
              onChange(dataUrl.split(',')[1]);
            });
          }
        };
        fetchImg();
      } else {
        signaturePad.fromDataURL(`data:image/png;base64,${value}`, { width: withCanvas, height: heightCanvas });
      }
    }
  }, [value, signaturePad]);

  const handleClear = () => {
    if (!signaturePad) return;
    signaturePad.clear();
  };

  const blobToDataUrl = (blob: Blob) => {
    return new Promise((r) => {
      const a = new FileReader();
      a.onload = r;
      a.readAsDataURL(blob);
    });
  };

  async function resolveFile(url: string) {
    const res = await fetch(url);
    const blob = await res.blob();
    return blobToDataUrl(blob);
  }

  return (
    <Box width='100%' p={isMobile ? 0 : 2} display='flex' justifyContent='center'>
      <Box pr={isMobile ? 0 : 7}>
        <Box
          display='flex'
          alignItems={isMobile ? 'flex-start' : 'center'}
          justifyContent='flex-start'
          flexDirection={isMobile && 'column'}
        >
          <Create
            style={{
              padding: isMobile ? '0 0 8px 0' : '16px',
              color: theme.color.grey.neutralBrand300,
              fontSize: isMobile ? '14px' : '24px',
            }}
          />
          <canvas
            ref={canvasRef}
            style={{ padding: '4px', border: `1px solid ${theme.color.grey.neutralBrand200}`, borderRadius: '8px' }}
          ></canvas>
        </Box>
        <Box display='flex' justifyContent='flex-end' mt={1}>
          <TabletButton
            variant='text'
            style={{
              boxSizing: 'border-box',
              borderRadius: '4px',
              textTransform: 'none',
              padding: '7px 12px',
              color: theme.color.grey.neutralBrand300,
            }}
            onClick={handleClear}
          >
            Reset
          </TabletButton>
        </Box>
      </Box>
    </Box>
  );
};
