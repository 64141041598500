import { gql } from '@apollo/client';

export const ActivityScheduleFragments = gql`
  fragment ActivitySchedule on VOLUNTEER_RecurringActivityType {
    schedule {
      recurrences {
        startDate
        endDate
        humanReadable
      }
    }
  }

  fragment DialogActivityDetails on VOLUNTEER_ActivityType {
    activityId
    name
    occurrencesBetween(from: $startDate, until: $endDate)
    activityLocation {
      placesAddress {
        description
        formatted
      }
    }
    activityRemoteLocation {
      details
    }
    sessions {
      sessionId
      startTime
      endTime
    }
  }
`;
