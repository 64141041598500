import React from 'react';
import { Box, Button, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { RoleForm, Props as FormProps } from './RoleForm';
import { useButtonStyles } from './AdminConsoleRoleStyles';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 900,
      fontSize: '1.25rem',
      color: theme.color.grey.neutralBrand900,
    },
    body: {
      fontWeight: 400,
      color: theme.color.grey.neutral200,
      fontSize: '0.875rem',
      marginTop: '6px',
    },
    form: {
      marginTop: '1.25rem',
    },
    button: {
      marginTop: '1.25rem',
    },
  })
);

interface Props extends FormProps {
  close: () => void;
  onCancel: () => void;
  onSave: (event: any) => void;
}

export function NewRole({  close, value,  onChange, onCancel, onSave }: Props) {
  const buttonClasses = useButtonStyles();
  const classes = useStyles();
  const { isMobile } = useCampfireTheme();

  return (
    <FullscreenDialog
      open
      close={close}
      title='Add Role'
      maxWidth='xs'
      fullWidth
      fullScreen={isMobile}
    >
      <form className={classes.form} onSubmit={onSave}>
        <RoleForm value={value} onChange={onChange} />
        <Box display='flex' marginTop='1.25rem' width='100%' justifyContent='flex-end'>
          <Button classes={buttonClasses} onClick={onCancel} style={{marginRight: '8px'}}>
            Cancel
          </Button>
          <Button classes={buttonClasses} variant='contained' type='submit' color='primary' onClick={onSave}>
            Done
          </Button>
        </Box>
      </form>
    </FullscreenDialog>
  );
}

export { ValueType } from './RoleForm';
