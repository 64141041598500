import React from 'react';
import { Box, Typography } from '@material-ui/core';

export interface TaskFieldContainerProps {
  title: string;
  description?: string;
  helperText?: string;
  error?: boolean;
  ariaId: string;
  ariaDescribedBy: string;
  required?: boolean;
  pl?: number;
}

const TaskFieldContainer: React.FC<TaskFieldContainerProps> = (props) => {
  const { title, description, helperText, error, ariaId, ariaDescribedBy, required, children, pl } = props;

  return (
    <Box
      id={ariaId}
      marginTop={2}
      marginBottom={3}
      paddingTop={3}
      paddingBottom={3}
      paddingLeft={pl ?? 4}
      paddingRight={2}
      minHeight={96}
      border={'1px solid #e9e9e9'}
      bgcolor='#ffffff'
      borderRadius={7}
    >
      <span>
        <Typography variant='body1' display='inline' component='label' htmlFor={ariaId}>
          {title}
        </Typography>
        {required ? (
          <Typography
            variant='body1'
            display='inline'
            color='error'
            component='span'
            style={{ fontWeight: 'bolder', marginLeft: 4 }}
          >
            {'*'}
          </Typography>
        ) : null}
      </span>
      <Typography style={{ marginBottom: 16 }} variant='body2' color='textSecondary' id={ariaDescribedBy}>
        {description}
      </Typography>

      {children}

      <Typography color={error ? 'error' : 'textSecondary'} variant='body2'>
        {helperText}
      </Typography>
    </Box>
  );
};

export { TaskFieldContainer };
